import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccCurrentMonthPreviousPayrollCycleOutput = {
  __typename?: 'AccCurrentMonthPreviousPayrollCycleOutput';
  EmployeeID: Scalars['String'];
  TotalExemptNormalRemunerationAmount: Scalars['Float'];
  TotalExemptAdditionalRemunerationAmount: Scalars['Float'];
  TotalChargableTax1: Scalars['Float'];
  TotalChargableTax2: Scalars['Float'];
  TotalChargableEPF: Scalars['Float'];
  TotalChargableAdditionalRemunerationEPF: Scalars['Float'];
  TotalChargableNormalRemunerationEPF: Scalars['Float'];
  EPFPaidByNormalRemunerationPaid: Scalars['Float'];
  EPFPaidByAdditionalRemuneration: Scalars['Float'];
  TotalChargableEPFVE: Scalars['Float'];
  TotalChargableSocso: Scalars['Float'];
  TotalChargableEIS: Scalars['Float'];
  TotalChargableHRDF: Scalars['Float'];
  EmployeeEPF: Scalars['Float'];
  EmployerEPF: Scalars['Float'];
  EmployeeEPFVE: Scalars['Float'];
  EmployerEPFVE: Scalars['Float'];
  TotalChargableTax: Scalars['Float'];
  TotalChargableAddRemuneration: Scalars['Float'];
  Tax: Scalars['Float'];
  Zakat: Scalars['Float'];
  EmployeeEIS: Scalars['Float'];
  EmployerEIS: Scalars['Float'];
  EmployeeSocso: Scalars['Float'];
  EmployerSocso: Scalars['Float'];
};

export type AcceptedPreHiringInput = {
  org_unit_id: Scalars['String'];
  rec_applicant_id: Scalars['String'];
  job_designation_id: Scalars['String'];
  superior_id?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id: Scalars['String'];
  acceptance_date: Scalars['DateTime'];
  duty_date: Scalars['DateTime'];
  prehiring_status: PrehiringStatus;
  data_update_status?: Maybe<DataUpdateStatus>;
  data_update_status_log?: Maybe<Array<Scalars['JSON']>>;
  remark?: Maybe<Scalars['String']>;
};

export type AccessControl = {
  __typename?: 'AccessControl';
  ID: Scalars['Float'];
};

export type AccessControlInput = {
  ID: Scalars['Float'];
};

export type AccumulatedPreviousMonthOutput = {
  __typename?: 'AccumulatedPreviousMonthOutput';
  AccEPFPaid: Scalars['Float'];
  AccEPFVEPaid: Scalars['Float'];
  AccNormalRemunerationEPFPaid: Scalars['Float'];
  AccAdditionalRemunerationEPFPaid: Scalars['Float'];
  AccPCBPaid: Scalars['Float'];
  AccZakatPaid: Scalars['Float'];
  AccGrossTaxablePaid: Scalars['Float'];
  AccSocsoPaid: Scalars['Float'];
  AccEISPaid: Scalars['Float'];
  AccTaxPaid: Scalars['Float'];
  AccTaxRelief: Scalars['Float'];
};

export type ActivityListJson = {
  __typename?: 'ActivityListJson';
  Description?: Maybe<Scalars['String']>;
};

export type ActivityListJsonInput = {
  Description?: Maybe<Scalars['String']>;
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type Address2 = {
  __typename?: 'Address2';
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput2 = {
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AdhocSetupDetails = {
  EmployeeIDs: Array<Scalars['String']>;
  SuperiorReviewFormTemplateID: Scalars['String'];
  SuperiorSelfReviewFormTemplateID: Scalars['String'];
  PeerReviewFormTemplateID: Scalars['String'];
  SubordinateReviewFormTemplateID: Scalars['String'];
};

export type AdminExpenseFilterInput = {
  FromAmount?: Maybe<Scalars['Float']>;
  ToAmount?: Maybe<Scalars['Float']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  TrainingCourse?: Maybe<Array<Scalars['JSON']>>;
};

export type AdminRecruitmentSummaryOutput = {
  __typename?: 'AdminRecruitmentSummaryOutput';
  ApprovalPolicyLastUpdated?: Maybe<Scalars['DateTime']>;
  ExpenseTotal?: Maybe<Scalars['Float']>;
  JobPortalTotal?: Maybe<Scalars['Float']>;
  EApplicationFormSetupLastUpdated?: Maybe<Scalars['DateTime']>;
  PrehiringFormSetupLastUpdated?: Maybe<Scalars['DateTime']>;
  OfferChecklistTotal?: Maybe<Scalars['Float']>;
  ELetterLastUpdated?: Maybe<Scalars['DateTime']>;
  AdditionalFieldsTotal?: Maybe<Scalars['Float']>;
  DeclineReasonTotal?: Maybe<Scalars['Float']>;
};

export type AdvancePolicyAssignmentEntity = InterfaceBaseV2 & {
  __typename?: 'AdvancePolicyAssignmentEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvancePolicyAssignmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  AdvancePolicy: AdvancePolicyEntity;
};

export type AdvancePolicyConditionEntity = InterfaceBaseV2 & {
  __typename?: 'AdvancePolicyConditionEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvancePolicyConditionID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  ConditionList: Scalars['JSON'];
  AdvancePolicy: AdvancePolicyEntity;
  Sequence: Scalars['Float'];
};

export type AdvancePolicyEntity = InterfaceBaseV2 & {
  __typename?: 'AdvancePolicyEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  Title: Scalars['String'];
  PaymentItemID: Scalars['String'];
  PaymentCycleID: Scalars['String'];
  DeductionItemID: Scalars['String'];
  AllowInstallment: Scalars['Boolean'];
  InstallmentMonth?: Maybe<Scalars['Float']>;
  DeductionPeriod: MonthPeriod;
  DeductionCycleID: Scalars['String'];
  IsExcludeNew: Scalars['Boolean'];
  IsExcludeOffboard: Scalars['Boolean'];
  AdvancePolicyCondition?: Maybe<Array<AdvancePolicyConditionEntity>>;
  InUse: Scalars['Boolean'];
  PaymentItem: PayItemEntity;
  PaymentCycle: PayrollCycleEntity;
  DeductionItem: PayItemEntity;
  DeductionCycle: PayrollCycleEntity;
};

export type AdvancePolicyInput = {
  AdvancePolicyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  PaymentItemID: Scalars['String'];
  PaymentCycleID: Scalars['String'];
  DeductionItemID: Scalars['String'];
  AllowInstallment: Scalars['Boolean'];
  InstallmentMonth?: Maybe<Scalars['Float']>;
  DeductionPeriod: MonthPeriod;
  DeductionCycleID: Scalars['String'];
  IsExcludeNew: Scalars['Boolean'];
  IsExcludeOffboard: Scalars['Boolean'];
  ConditionList: Array<PayrollConditionListInput>;
  UserLogs: Array<UserLogInput>;
};

export type AdvanceProcessingDetailEntity = InterfaceBaseV2 & {
  __typename?: 'AdvanceProcessingDetailEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvanceProcessingDetailID: Scalars['String'];
  AdvanceProcessingID: Scalars['String'];
  PaymentType: PayItemType;
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayItemID: Scalars['String'];
  Amount: Scalars['Float'];
  OneTimePayID?: Maybe<Scalars['String']>;
  AdvanceProcessing: AdvanceProcessingEntity;
  PeriodMonth: Scalars['String'];
  PayrollCycle: PayrollCycleEntity;
  PayItem: PayItemEntity;
  IsModified?: Maybe<Scalars['JSON']>;
};

export type AdvanceProcessingEntity = InterfaceBaseV2 & {
  __typename?: 'AdvanceProcessingEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  AdvanceProcessingID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  EmployeeID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  IsPosted?: Maybe<Scalars['Boolean']>;
  AdvanceProcessingDetail?: Maybe<Array<AdvanceProcessingDetailEntity>>;
  Employee: EmployeeEntity;
  AdvancePolicyName: Scalars['String'];
  IsProcessed: Scalars['Boolean'];
};

export type AllowanceBenefitInput = {
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AllowanceBenfitEntity = InterfaceBase & {
  __typename?: 'AllowanceBenfitEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceBenefitID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  Company: CompanyEntity;
};

export type AllowanceDeductionInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Name?: Maybe<Scalars['String']>;
};

export type AllowanceDeductionRoundingEntity = InterfaceBase & {
  __typename?: 'AllowanceDeductionRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceDeductionID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  DailyAllowance?: Maybe<Array<DailyAllowancePolicyEntity>>;
  MonthlyAllowance?: Maybe<Array<MonthlyAllowancePolicyEntity>>;
  PeriodicDeduction?: Maybe<Array<PeriodicDeductionPolicyEntity>>;
};

export enum AllowanceFrequency {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Lifetime = 'Lifetime'
}

export type AllowanceItemEntity = InterfaceBase & {
  __typename?: 'AllowanceItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceItemID: Scalars['String'];
  AllowanceBenefitID: Scalars['String'];
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  AllowanceFrequency: AllowanceFrequency;
  TotalAmount: Scalars['Float'];
  AllowanceBenefit: AllowanceBenfitEntity;
};

export type AllowanceItemInput = {
  AllowanceBenefitID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  AllowanceFrequency?: Maybe<AllowanceFrequency>;
  TotalAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AnnouncementDashboardCardOutput = {
  __typename?: 'AnnouncementDashboardCardOutput';
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type ApplicantStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<RecruitmentApplicationStage>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};

export type AppraisalCategoryEntity = InterfaceBase & {
  __typename?: 'AppraisalCategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalCategoryID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AppraisalType?: Maybe<Scalars['String']>;
  IsInUse?: Maybe<Scalars['Boolean']>;
  AppraisalCategoryItems?: Maybe<Array<AppraisalCategoryItemEntity>>;
  AppraisalTempleteCategory?: Maybe<Array<AppraisalTempleteCategoryEntity>>;
};

export type AppraisalCategoryInput = {
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AppraisalType?: Maybe<AppraisalType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalCategoryItemEntity = InterfaceBase & {
  __typename?: 'AppraisalCategoryItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID: Scalars['String'];
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AppraisalItemType?: Maybe<Scalars['String']>;
  QuantitativeType?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IsInUse?: Maybe<Scalars['Boolean']>;
  AppraisalCategory?: Maybe<AppraisalCategoryEntity>;
  AppraisalTempleteCategoryItems?: Maybe<Array<AppraisalTempleteCategoryItemEntity>>;
};

export type AppraisalCategoryItemInput = {
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AppraisalItemType?: Maybe<KpiType>;
  QuantitativeType?: Maybe<QuantitativeType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalCompanySettingEntity = InterfaceBase & {
  __typename?: 'AppraisalCompanySettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalPolicyID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalApprovalProcessID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalPolicy?: Maybe<AppraisalPolicyEntity>;
  AppraisalTempleteSetting?: Maybe<AppraisalTemplateHeaderEntity>;
};

export type AppraisalCompanySettingInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalPolicyID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalApprovalProcessID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalEmployeeLisitngOutput = InterfaceBase & {
  __typename?: 'AppraisalEmployeeLisitngOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ShopeeEmployeeID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<EmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  AttendanceRecordPaired?: Maybe<Array<AttendanceRecordPairedEntity>>;
  TargetEmployees?: Maybe<Array<DelegationEntity>>;
  SubmitterEmployees?: Maybe<Array<DelegationEntity>>;
  Subordinate?: Maybe<Array<EmployeeEntity>>;
  SubordinateDetail?: Maybe<Array<RequisitionEntity>>;
  EmployeeContract?: Maybe<Array<ContractInfoEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<EmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  KpiExercise?: Maybe<Array<KpiExerciseEntity>>;
  IsInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  IsFromInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  InterCompanyTransferDate?: Maybe<Scalars['DateTime']>;
  HelpdeskTicket?: Maybe<Array<HelpdeskTicketEntity>>;
  ProjectTimesheetApproverAssignment?: Maybe<ProjectTimesheetApproverAssignmentEntity>;
  isDailyEntryFilled?: Maybe<Scalars['Boolean']>;
  BatchOvertimes?: Maybe<Array<BatchOvertimeEntity>>;
  TrainingRequest?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  IsProbationExtended?: Maybe<Scalars['Boolean']>;
  EmployeeCurrentSalary: Scalars['String'];
  DesignationWithTemplete?: Maybe<JobDesignationEntity>;
  EmployeeDepartment?: Maybe<DepartmentEntity>;
  GeneralDocCount?: Maybe<Scalars['Float']>;
  GeneralDocTotalSize?: Maybe<Scalars['Float']>;
  PersonalDocCount: Scalars['Float'];
  PersonalDocTotalSize: Scalars['Float'];
  ContractInfoDocCount: Scalars['Float'];
  ContractInfoDocTotalSize: Scalars['Float'];
  PaySlipDocCount: Scalars['Float'];
  PaySlipDocTotalSize: Scalars['Float'];
  EAFormDocCount: Scalars['Float'];
  EAFormDocTotalSize: Scalars['Float'];
  TotalLeave: Scalars['Float'];
  TotalAnnualLeave: Scalars['Float'];
  TotalSickLeave: Scalars['Float'];
  TotalUnpaidLeave: Scalars['Float'];
  TotalClaim: Scalars['Float'];
  TotalClaimApproved: Scalars['Float'];
  TotalClaimAmountApproved: Scalars['Float'];
  TotalTaxRelief: Scalars['Float'];
  TotalRequisition: Scalars['Float'];
  TotalTravelRequest: Scalars['Float'];
  TotalPurchaseRequisition: Scalars['Float'];
  TotalCashAdvanceRequisition: Scalars['Float'];
  TotalOtherRequisition: Scalars['Float'];
  TotalUnreadNews: Scalars['Float'];
  LastUpdatedDates: LastUpdatedDatesOutput;
  MyTodo: Scalars['Float'];
  MyNotification: Scalars['Float'];
  EmployeeSubordinates: Array<EmployeeEntity>;
  TotalEducation: Scalars['Float'];
  TotalJobHistory: Scalars['Float'];
  TotalCertificate: Scalars['Float'];
  TotalPersonalDocument: Scalars['Float'];
  ShiftGroupEmployeeAssignmentLogList: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  TotalKpiRecord: Scalars['Float'];
  TotalReviewRecord: Scalars['Float'];
  EmpOrgUnitDesc: Scalars['String'];
  AppraisalStatus?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  SubordinateAppraisalHeaderID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  AppraisalScheduleDetailID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
};

export type AppraisalPolicyEntity = InterfaceBase & {
  __typename?: 'AppraisalPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalPolicyID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  ScaleRatingHeader?: Maybe<ScaleRatingHeaderEntity>;
  FinalRatingHeader?: Maybe<FinalRatingHeaderEntity>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type AppraisalPolicyInput = {
  AppraisalPolicyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalScheduleDetailEntity = InterfaceBase & {
  __typename?: 'AppraisalScheduleDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalScheduleDetailID: Scalars['String'];
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeader?: Maybe<AppraisalScheduleHeaderEntity>;
};

export type AppraisalScheduleDetailInput = {
  AppraisalScheduleDetailID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalScheduleHeaderEntity = InterfaceBase & {
  __typename?: 'AppraisalScheduleHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['Float']>;
  AppraisalScheduleDetails?: Maybe<Array<AppraisalScheduleDetailEntity>>;
  AppraisalTempleteByJobPosition?: Maybe<Array<AppraisalTempleteByJobPositionEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type AppraisalScheduleHeaderInput = {
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<AppraisalScheduleDetailInput>>;
};

export type AppraisalTemplateHeaderEntity = InterfaceBase & {
  __typename?: 'AppraisalTemplateHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTemplateHeaderID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteByJobPosition?: Maybe<Array<AppraisalTempleteByJobPositionEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type AppraisalTempleteByJobPositionEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteByJobPositionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteByJobPositionID: Scalars['String'];
  AppraisalTemplateHeaderID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  KPITempleteID?: Maybe<Scalars['String']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  CompetencyTempleteID?: Maybe<Scalars['String']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalSchedule?: Maybe<AppraisalScheduleHeaderEntity>;
  KPITemplete?: Maybe<AppraisalTempleteEntity>;
  CompetencyTemplete?: Maybe<AppraisalTempleteEntity>;
  OthersTemplete?: Maybe<AppraisalTempleteEntity>;
  AppraisalTemplateHeader?: Maybe<AppraisalTemplateHeaderEntity>;
};

export type AppraisalTempleteByJobPositionHeaderInput = {
  AppraisalTemplateHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTemplateByJobPositionList?: Maybe<Array<AppraisalTempleteByJobPositionInput>>;
};

export type AppraisalTempleteByJobPositionInput = {
  AppraisalTempleteByJobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTemplateHeaderID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  KPITempleteID?: Maybe<Scalars['String']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  CompetencyTempleteID?: Maybe<Scalars['String']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalTempleteCategoryEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteCategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteCategoryID: Scalars['String'];
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTemplete?: Maybe<AppraisalTempleteEntity>;
  AppraisalCategory?: Maybe<AppraisalCategoryEntity>;
  AppraisalTempleteCategoryItems?: Maybe<Array<AppraisalTempleteCategoryItemEntity>>;
};

export type AppraisalTempleteCategoryInput = {
  AppraisalTempleteCategoryID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Items?: Maybe<Array<AppraisalTempleteCategoryItemInput>>;
};

export type AppraisalTempleteCategoryItemEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteCategoryItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteCategoryItemID: Scalars['String'];
  AppraisalTempleteCategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteCategory?: Maybe<AppraisalTempleteCategoryEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
};

export type AppraisalTempleteCategoryItemInput = {
  AppraisalTempleteCategoryItemID?: Maybe<Scalars['String']>;
  AppraisalTempleteCategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AppraisalTempleteEntity = InterfaceBase & {
  __typename?: 'AppraisalTempleteEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AppraisalTempleteID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TempleteType?: Maybe<Scalars['String']>;
  AppraisalTempleteCategory?: Maybe<Array<AppraisalTempleteCategoryEntity>>;
};

export type AppraisalTempleteInput = {
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TempleteType?: Maybe<AppraisalType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Categories?: Maybe<Array<AppraisalTempleteCategoryInput>>;
};

export enum AppraisalType {
  Kpi = 'KPI',
  Competency = 'Competency',
  Others = 'Others'
}

export enum AppraiseeType {
  Self = 'Self',
  Superior = 'Superior',
  Subordinate = 'Subordinate',
  Peer = 'Peer',
  SelfAssesment = 'Self_Assesment',
  Employee = 'Employee'
}

export type ApprovalClaimVerificationInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export type ApprovalList = {
  __typename?: 'ApprovalList';
  RoleName: AppraiseeType;
  Level: Scalars['Float'];
};

export type ApprovalList1 = {
  PersonnelManagement?: Maybe<Array<Scalars['JSON']>>;
  LeaveManagement?: Maybe<Array<Scalars['JSON']>>;
  ClaimManagement?: Maybe<Array<Scalars['JSON']>>;
  ProjectExpenseManagement?: Maybe<Array<Scalars['JSON']>>;
  RequisitionManagement?: Maybe<Array<Scalars['JSON']>>;
  TimeAttendance?: Maybe<Array<Scalars['JSON']>>;
  PerformanceManagement?: Maybe<Array<Scalars['JSON']>>;
};

export type ApprovalListInput = {
  RoleName: AppraiseeType;
  Level: Scalars['Float'];
};

export type ApprovalListJson = {
  __typename?: 'ApprovalListJson';
  ID: Scalars['String'];
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate: Scalars['String'];
  Amount: Scalars['Float'];
  RoleName?: Maybe<Scalars['String']>;
  EndIf?: Maybe<Scalars['String']>;
  Skip?: Maybe<Scalars['String']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  reporting_group_name?: Maybe<Scalars['String']>;
};

export type ApprovalListJsonInput = {
  ID: Scalars['String'];
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate: Scalars['String'];
  Amount: Scalars['Float'];
  RoleName?: Maybe<Scalars['String']>;
  EndIf?: Maybe<Scalars['String']>;
  Skip?: Maybe<Scalars['String']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  reporting_group_name?: Maybe<Scalars['String']>;
};

export type ApprovalLog = {
  ApprovalStepNo?: Maybe<Scalars['Float']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ClientName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Status?: Maybe<WorkflowLogStatus>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentEntity = InterfaceBase & {
  __typename?: 'ApprovalPolicyAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ApprovalPolicy?: Maybe<ApprovalPolicyEntity>;
  Description?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Array<Scalars['JSON']>>;
};

export type ApprovalPolicyAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['String']>;
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Array<Scalars['JSON']>>;
  SubscriptionAccountID: Scalars['String'];
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyEntity = InterfaceBase & {
  __typename?: 'ApprovalPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Category: Scalars['String'];
  ApprovalList: Array<ApprovalListJson>;
  ApprovalPolicyAssignment: Array<ApprovalPolicyAssignmentEntity>;
  InUse: Scalars['Boolean'];
};

export type ApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyMatrixEntity = NewInterfaceBase1 & {
  __typename?: 'ApprovalPolicyMatrixEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  description: Scalars['String'];
  subscription_account_id: Scalars['String'];
  approval_policy_type: Scalars['String'];
  condition_list: Array<Scalars['JSON']>;
  category?: Maybe<Scalars['String']>;
  approval_policy_id: Scalars['String'];
  ApprovalPolicy?: Maybe<ApprovalPolicyEntity>;
};

export type ApprovalPolicyMatrixInput = {
  approval_policy_matrix_id?: Maybe<Scalars['String']>;
  approval_policy_id?: Maybe<Scalars['String']>;
  approval_policy_type?: Maybe<Scalars['String']>;
  condition_list?: Maybe<Array<Scalars['JSON']>>;
  description?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type ApprovalProjectExpenseClaimVerificationInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ProjectExpenseTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export enum ApprovalStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Withdraw = 'Withdraw',
  Withdrawn = 'Withdrawn',
  Submitted = 'Submitted'
}

export type ApproveClaimRecordsInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  ApproverID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ClaimTrx?: Maybe<Array<TrxIDs>>;
};

export type ApproveClaimVerificationInput = {
  CompanyID?: Maybe<Scalars['String']>;
};

export type ApproveClaimVerificationOutput = {
  __typename?: 'ApproveClaimVerificationOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  HeaderTotalAmt?: Maybe<Scalars['String']>;
  HeaderTotalClaim?: Maybe<Scalars['String']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  CheckComplete?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
};

export type ApproveProjectExpenseClaimRecordsInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  ApproverID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ProjectExpenseClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimTrx?: Maybe<Array<TrxIDs>>;
};

export type ApprovedClaimDetail = {
  __typename?: 'ApprovedClaimDetail';
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  ApprovalByID?: Maybe<Scalars['String']>;
  ApprovalByName?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type ApprovedClaimVerificationDetailsOutput = {
  __typename?: 'ApprovedClaimVerificationDetailsOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<ApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimDuplicateList?: Maybe<Array<ClaimDuplicateList>>;
};

export type ApprovedProjectExpenseClaimVerificationDetailsOutput = {
  __typename?: 'ApprovedProjectExpenseClaimVerificationDetailsOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<PeApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimDuplicateList?: Maybe<Array<PeClaimDuplicateList>>;
};

export type AttachmentInput = {
  Index?: Maybe<Scalars['Float']>;
  Filename?: Maybe<Scalars['String']>;
  Format?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
};

export type AttachmentOutput = {
  __typename?: 'AttachmentOutput';
  Index?: Maybe<Scalars['Float']>;
  Info?: Maybe<Scalars['String']>;
};

export type AttendanceAnalysisTemplateEntity = InterfaceBase & {
  __typename?: 'AttendanceAnalysisTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AttendanceAnalysisTemplate?: Maybe<Array<Scalars['JSON']>>;
};

export type AttendanceAnalysisTemplateInput = {
  AttendanceAnalysisID?: Maybe<Scalars['String']>;
  AttendanceAnalysisTemplate?: Maybe<Array<Scalars['JSON']>>;
};

export type AttendanceCalculationEntity = InterfaceBase & {
  __typename?: 'AttendanceCalculationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCalculationID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  Value?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  ShiftDayName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  MonthlyAdjustedValue?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
  ShiftDay?: Maybe<ShiftDayEntity>;
  PayItem?: Maybe<PayItemEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  PeriodicCycleStartDate?: Maybe<Scalars['DateTime']>;
  PeriodicCycleEndDate?: Maybe<Scalars['DateTime']>;
  OTAmount?: Maybe<Scalars['Float']>;
};

export type AttendanceCalculationInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AttendanceCalculationID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  PostStatus?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
};

export type AttendanceCorrectionDetail = {
  __typename?: 'AttendanceCorrectionDetail';
  date?: Maybe<Scalars['String']>;
  approval_status?: Maybe<Scalars['String']>;
  attendance_correction_header_id?: Maybe<Scalars['String']>;
};

export type AttendanceCorrectionDetailInput = {
  attendance_correction_id?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['String']>;
  integration_clock_type?: Maybe<Scalars['String']>;
  check_in_method?: Maybe<Scalars['String']>;
};

export type AttendanceCorrectionEntity = NewInterfaceBase & {
  __typename?: 'AttendanceCorrectionEntity';
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogs>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  attendance_correction_id?: Maybe<Scalars['String']>;
  attendance_correction_header_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['DateTime']>;
  integration_clock_type?: Maybe<IntegrationClockType>;
  check_in_method?: Maybe<CheckInMethod>;
};

export type AttendanceCorrectionHeaderEntity = NewInterfaceBase & {
  __typename?: 'AttendanceCorrectionHeaderEntity';
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogs>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  attendance_correction_header_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  voice_document_id?: Maybe<Scalars['String']>;
  voiceDocument?: Maybe<DocumentsEntity>;
  document_id?: Maybe<Array<Scalars['JSON']>>;
  approval_status?: Maybe<ApprovalStatus>;
  remarks?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['JSON']>;
  approver_id?: Maybe<Scalars['String']>;
  approver?: Maybe<Scalars['String']>;
  approval_dt?: Maybe<Scalars['DateTime']>;
  Employee?: Maybe<EmployeeEntity>;
  AttendanceCorrection?: Maybe<Array<AttendanceCorrectionEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  documents?: Maybe<Array<DocumentsEntity>>;
};

export type AttendanceCorrectionInput = {
  attendance_correction_header_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  snapshot?: Maybe<Scalars['JSON']>;
  voice_document_input?: Maybe<DocumentsInput>;
  document_input?: Maybe<Array<DocumentsInput>>;
  attendance_correction_detail?: Maybe<Array<AttendanceCorrectionDetailInput>>;
};

export type AttendanceCorrectionRequestUpdateInput = {
  AttendanceCorrectionHeaderID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrxStatus?: Maybe<Scalars['String']>;
};

export type AttendanceCutOffPeriodEntity = InterfaceBase & {
  __typename?: 'AttendanceCutOffPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCutOffPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  Company?: Maybe<CompanyEntity>;
};

export type AttendanceCutOffPeriodInput = {
  AttendanceCutOffPeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AttendanceDashboardCardOutput = {
  __typename?: 'AttendanceDashboardCardOutput';
  CompanyID: Scalars['String'];
  CompanyName: Scalars['String'];
  EmployeeID: Scalars['String'];
  FullName: Scalars['String'];
  EmployeeNo: Scalars['String'];
  sortType: Scalars['String'];
  description: Scalars['String'];
  ClockingRecord?: Maybe<Scalars['JSON']>;
};

export type AttendanceDetailOutput = {
  __typename?: 'AttendanceDetailOutput';
  Date?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<Scalars['String']>;
  ShiftName?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  ShiftTimeIn?: Maybe<Scalars['String']>;
  ShiftTimeOut?: Maybe<Scalars['String']>;
  ActualIn?: Maybe<Scalars['String']>;
  ActualOut?: Maybe<Scalars['String']>;
  PreOT?: Maybe<Scalars['Float']>;
  PostOT?: Maybe<Scalars['Float']>;
  ShiftHour?: Maybe<Scalars['Float']>;
  ShiftPlanDay?: Maybe<Scalars['Float']>;
  WorkHour?: Maybe<Scalars['Float']>;
  ActualWorkHour?: Maybe<Scalars['Float']>;
  ActualWorkDay?: Maybe<Scalars['Float']>;
  RawOTHours?: Maybe<Scalars['Float']>;
  RawOTDay?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  EarlyOut?: Maybe<Scalars['Float']>;
  LateInDay?: Maybe<Scalars['Float']>;
  EarlyOutDay?: Maybe<Scalars['Float']>;
  Absenteeism?: Maybe<Scalars['String']>;
  AbsentDay?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  ApprovedOTHours?: Maybe<Scalars['Float']>;
  Leave?: Maybe<Scalars['String']>;
  TravelTime?: Maybe<Scalars['Float']>;
  UnproductiveTime?: Maybe<Scalars['Float']>;
  Irregular?: Maybe<Scalars['String']>;
  IncompleteClocking?: Maybe<Scalars['String']>;
  FixedHours?: Maybe<Scalars['Float']>;
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  Location?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['String']>;
  Long?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type AttendancePostInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  EmployeePayItemIDPairs?: Maybe<Array<AttendanceCalculationInput>>;
  CompanyID?: Maybe<Scalars['String']>;
  isSelectAll?: Maybe<Scalars['Boolean']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
};

export type AttendancePostingOutput = InterfaceBase & {
  __typename?: 'AttendancePostingOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCalculationID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  Value?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  ShiftDayName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  MonthlyAdjustedValue?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
  ShiftDay?: Maybe<ShiftDayEntity>;
  PayItem?: Maybe<PayItemEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  PeriodicCycleStartDate?: Maybe<Scalars['DateTime']>;
  PeriodicCycleEndDate?: Maybe<Scalars['DateTime']>;
  OTAmount?: Maybe<Scalars['Float']>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
  AttendanceCalculationIDs?: Maybe<Array<Scalars['String']>>;
};

export type AttendanceProcessingBatchEntity = {
  __typename?: 'AttendanceProcessingBatchEntity';
  AttendanceProcessingBatchID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  Errorlog?: Maybe<Array<Scalars['JSON']>>;
  CompletedTime?: Maybe<Scalars['DateTime']>;
};

export type AttendanceProcessingOutput = {
  __typename?: 'AttendanceProcessingOutput';
  Date?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ShiftName?: Maybe<Scalars['String']>;
  ShiftPatternName?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupName?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  ShiftTimeIn?: Maybe<Scalars['String']>;
  ShiftTimeOut?: Maybe<Scalars['String']>;
  ActualIn?: Maybe<Scalars['String']>;
  ActualOut?: Maybe<Scalars['String']>;
  PreOT?: Maybe<Scalars['Float']>;
  PostOT?: Maybe<Scalars['Float']>;
  ShiftHour?: Maybe<Scalars['Float']>;
  ShiftPlanDay?: Maybe<Scalars['Float']>;
  WorkHour?: Maybe<Scalars['Float']>;
  ActualWorkDay?: Maybe<Scalars['Float']>;
  ActualWorkHour?: Maybe<Scalars['Float']>;
  RawOTHours?: Maybe<Scalars['Float']>;
  RawOTDay?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  LateInDay?: Maybe<Scalars['Float']>;
  EarlyOut?: Maybe<Scalars['Float']>;
  EarlyOutDay?: Maybe<Scalars['Float']>;
  Absenteeism?: Maybe<Scalars['String']>;
  AbsentDay?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  ApprovedOTHours?: Maybe<Scalars['Float']>;
  Leave?: Maybe<Scalars['String']>;
  TravelTime?: Maybe<Scalars['Float']>;
  UnproductiveTime?: Maybe<Scalars['Float']>;
  Irregular?: Maybe<Scalars['String']>;
  IncompleteClocking?: Maybe<Scalars['String']>;
  FixedHours?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  AbsentOccurence?: Maybe<Scalars['Float']>;
  LateInOccurence?: Maybe<Scalars['Float']>;
  EarlyOutOccurence?: Maybe<Scalars['Float']>;
  ImcompleteClockingOccurence?: Maybe<Scalars['Float']>;
  LeaveCount?: Maybe<Scalars['Float']>;
  SickLeaveCount?: Maybe<Scalars['Float']>;
  UnpaidLeaveCount?: Maybe<Scalars['Float']>;
};

export type AttendanceRecordEntity = InterfaceBase & {
  __typename?: 'AttendanceRecordEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceRecordID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IntegrationClockType?: Maybe<IntegrationClockType>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
  WearMask?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  DateTime?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  CheckInMethod?: Maybe<CheckInMethod>;
};

export type AttendanceRecordHikVisionEntity = {
  __typename?: 'AttendanceRecordHikVisionEntity';
  ID?: Maybe<Scalars['Float']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  DateTime?: Maybe<Scalars['String']>;
  AccessDate?: Maybe<Scalars['String']>;
  AcessTime?: Maybe<Scalars['String']>;
  DeviceName?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  Direction?: Maybe<Scalars['String']>;
  AcessDT?: Maybe<Scalars['String']>;
  Temperature?: Maybe<Scalars['Float']>;
  TemperatureStatus?: Maybe<Scalars['String']>;
  WearMask?: Maybe<Scalars['String']>;
  DeviceSerial?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type AttendanceRecordInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AttendanceRecordID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IntegrationClockType?: Maybe<IntegrationClockType>;
  DateTime?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
  CheckInMethod?: Maybe<CheckInMethod>;
};

export type AttendanceRecordOutput = {
  __typename?: 'AttendanceRecordOutput';
  Date?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
};

export type AttendanceRecordPairedEntity = InterfaceBase & {
  __typename?: 'AttendanceRecordPairedEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceRecordPairedID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  DutyRoster?: Maybe<Array<Scalars['JSON']>>;
  ClockingRecord?: Maybe<Array<Scalars['JSON']>>;
  TimeOffRecord?: Maybe<Array<Scalars['JSON']>>;
  ApprovedOTRecord?: Maybe<Array<Scalars['JSON']>>;
  LeaveTakenRecord?: Maybe<Array<Scalars['JSON']>>;
  Date?: Maybe<Scalars['DateTime']>;
  ActualIn?: Maybe<Scalars['DateTime']>;
  ActualOut?: Maybe<Scalars['DateTime']>;
  ShiftTimeTotal?: Maybe<Scalars['Float']>;
  WorkTimeTotal?: Maybe<Scalars['Float']>;
  OTTimeTotal?: Maybe<Scalars['Float']>;
  ApprovedOTTimeTotal?: Maybe<Scalars['Float']>;
  TimeOffTotal?: Maybe<Scalars['Float']>;
  UnproductiveTimeTotal?: Maybe<Scalars['Float']>;
  AnalysisData?: Maybe<Array<Scalars['JSON']>>;
  Absenteeism?: Maybe<Scalars['Boolean']>;
  Irregular?: Maybe<Scalars['Boolean']>;
  IncompleteClocking?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  isFinal?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type AttendanceRecordPairedInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AttendanceRecordPairedID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  DutyRoster?: Maybe<Scalars['JSON']>;
  ClockingRecord?: Maybe<Scalars['JSON']>;
  TimeOffRecord?: Maybe<Scalars['JSON']>;
  ApprovedOTRecord?: Maybe<Scalars['JSON']>;
  LeaveTakenRecord?: Maybe<Scalars['JSON']>;
  Date?: Maybe<Scalars['DateTime']>;
  ActualIn?: Maybe<Scalars['DateTime']>;
  ActualOut?: Maybe<Scalars['DateTime']>;
  ShiftTimeTotal?: Maybe<Scalars['Float']>;
  WorkTimeTotal?: Maybe<Scalars['Float']>;
  OTTimeTotal?: Maybe<Scalars['Float']>;
  TimeOffTotal?: Maybe<Scalars['Float']>;
  ApprovedOTTimeTotal?: Maybe<Scalars['Float']>;
  UnproductiveTimeTotal?: Maybe<Scalars['Float']>;
  AnalysisData?: Maybe<Scalars['JSON']>;
  Absenteeism?: Maybe<Scalars['Boolean']>;
  Irregular?: Maybe<Scalars['Boolean']>;
  IncompleteClocking?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  isFinal?: Maybe<Scalars['Boolean']>;
};

export type AttendanceRecordPairedObj = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<Scalars['String']>;
  OrgunitID?: Maybe<Scalars['String']>;
  PairedData?: Maybe<Array<Scalars['JSON']>>;
};

export enum AttendanceTransactionType {
  Daily = 'Daily',
  Periodic = 'Periodic'
}

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AuthorisedTrainingProviderEntity = InterfaceBase & {
  __typename?: 'AuthorisedTrainingProviderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsExternal?: Maybe<Scalars['Boolean']>;
  ContactID?: Maybe<Scalars['String']>;
  ApprovedCourses?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type AuthorisedTrainingProviderInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsExternal?: Maybe<Scalars['Boolean']>;
  ContactID?: Maybe<Scalars['String']>;
  ApprovedCourses?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactInput>;
};

export type AutomatedClockDataImportEntity = {
  __typename?: 'AutomatedClockDataImportEntity';
  AutomatedClockDataImportID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  ImportTime: Array<Scalars['JSON']>;
  FileLocation: Scalars['String'];
  FileType: Scalars['String'];
  TotalColumn: Scalars['Float'];
  EmployeeNo: Scalars['Float'];
  DateTimeCombine: Scalars['Boolean'];
  Date: Scalars['Float'];
  Time: Scalars['Float'];
  DateFormat?: Maybe<Scalars['String']>;
  TimeFormat?: Maybe<Scalars['String']>;
  DateTimeFormat?: Maybe<Scalars['String']>;
  ClockType?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['Float']>;
  IgnoreRow?: Maybe<Scalars['String']>;
  IgnoreDoubleQuote?: Maybe<Scalars['Boolean']>;
  IgnoreHeaderRow?: Maybe<Scalars['Boolean']>;
  Delimiter?: Maybe<Scalars['String']>;
  ColumnSetup?: Maybe<Scalars['JSON']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AutomatedClockDataImportInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  AutomatedClockDataImportID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  ImportTime: Array<Scalars['JSON']>;
  FileLocation: Scalars['String'];
  FileType: Scalars['String'];
  TotalColumn: Scalars['Float'];
  EmployeeNo: Scalars['Float'];
  DateTimeCombine: Scalars['Boolean'];
  Date: Scalars['Float'];
  Time: Scalars['Float'];
  DateFormat?: Maybe<Scalars['String']>;
  TimeFormat?: Maybe<Scalars['String']>;
  DateTimeFormat?: Maybe<Scalars['String']>;
  ClockType?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['Float']>;
  IgnoreRow?: Maybe<Scalars['String']>;
  IgnoreDoubleQuote?: Maybe<Scalars['Boolean']>;
  IgnoreHeaderRow?: Maybe<Scalars['Boolean']>;
  Delimiter?: Maybe<Scalars['String']>;
  ColumnSetup?: Maybe<Scalars['JSON']>;
};

export enum AvailableBasis {
  AnnualEntitlement = 'Annual_Entitlement',
  EarnBasis = 'Earn_Basis',
  NoProration = 'No_Proration'
}

export enum BackdatedOtMethod {
  CalendarDay = 'CALENDAR_DAY',
  WorkingDay = 'WORKING_DAY'
}

export enum Bank {
  AffinBankBerhad = 'Affin_Bank_Berhad',
  AffinIslamicBankBerhad = 'Affin_Islamic_Bank_Berhad',
  AlRajhiBankingInvestmentCorporationMalaysiaBerhad = 'Al_Rajhi_Banking_Investment_Corporation_Malaysia_Berhad',
  AllianceBankMalaysiaBerhad = 'Alliance_Bank_Malaysia_Berhad',
  AllianceIslamicBankBerhad = 'Alliance_Islamic_Bank_Berhad',
  AmBankMBerhad = 'Am_Bank_M_Berhad',
  AmBankIslamicBerhad = 'Am_Bank_Islamic_Berhad',
  BangkokBankBerhad = 'Bangkok_Bank_Berhad',
  BankIslamMalaysiaBerhad = 'Bank_Islam_Malaysia_Berhad',
  BankMuamalatMalaysiaBerhad = 'Bank_Muamalat_Malaysia_Berhad',
  BankOfAmericaMalaysiaBerhad = 'Bank_of_America_Malaysia_Berhad',
  BankOfChinaMalaysiaBerhad = 'Bank_of_China_Malaysia_Berhad',
  BankRakyat = 'Bank_Rakyat',
  BankSimpananNasional = 'Bank_Simpanan_Nasional',
  BankPertanianMalaysiaBerhadAgroBank = 'Bank_Pertanian_Malaysia_Berhad_Agro_Bank',
  BnpParibasMalaysiaBerhad = 'BNP_Paribas_Malaysia_Berhad',
  ChinaConstructionBankMalaysiaBerhad = 'China_Construction_Bank_Malaysia_Berhad',
  CimbBankBerhad = 'CIMB_Bank_Berhad',
  CimbIslamicBankBerhad = 'CIMB_Islamic_Bank_Berhad',
  CitibankBerhad = 'Citibank_Berhad',
  DeutscheBankMalaysiaBerhad = 'Deutsche_Bank_Malaysia_Berhad',
  HongLeongBankBerhad = 'Hong_Leong_Bank_Berhad',
  HongLeongIslamicBankBerhad = 'Hong_Leong_Islamic_Bank_Berhad',
  HsbcAmanahMalaysiaBerhad = 'HSBC_Amanah_Malaysia_Berhad',
  HsbcBankMalaysiaBerhad = 'HSBC_Bank_Malaysia_Berhad',
  IndiaInternationalBankMalaysiaBerhad = 'India_International_Bank_Malaysia_Berhad',
  IndustrialAndCommercialBankOfChinaMalaysiaBerhad = 'Industrial_and_Commercial_Bank_of_China_Malaysia_Berhad',
  JpMorganChaseBank = 'JP_Morgan_Chase_Bank',
  KuwaitFinanceHouseMalaysiaBerhad = 'Kuwait_Finance_House_Malaysia_Berhad',
  MalayanBankingBerhad = 'Malayan_Banking_Berhad',
  MaybankIslamicBerhad = 'Maybank_Islamic_Berhad',
  MbsbBankBerhad = 'MBSB_Bank_Berhad',
  MizuhoBankMalaysiaBerhad = 'Mizuho_Bank_Malaysia_Berhad',
  MufgBankMalaysiaBerhad = 'MUFG_Bank_Malaysia_Berhad',
  OcbcAlAminBankBerhad = 'OCBC_Al_Amin_Bank_Berhad',
  OcbcBankMalaysiaBerhad = 'OCBC_Bank_Malaysia_Berhad',
  PtBankMuamalatIndonesiaTbk = 'PT_Bank_Muamalat_Indonesia_Tbk',
  PublicBankBerhad = 'Public_Bank_Berhad',
  PublicIslamicBankBerhad = 'Public_Islamic_Bank_Berhad',
  RhbBankBerhad = 'RHB_Bank_Berhad',
  RhbIslamicBankBerhad = 'RHB_Islamic_Bank_Berhad',
  StandardCharteredBankMalaysiaBerhad = 'Standard_Chartered_Bank_Malaysia_Berhad',
  StandardCharteredSaadiqBerhad = 'Standard_Chartered_Saadiq_Berhad',
  SumitomoMitsuiBankingCorporationMalaysiaBerhad = 'Sumitomo_Mitsui_Banking_Corporation_Malaysia_Berhad',
  TheBankOfNovaScotiaBerhad = 'The_Bank_of_Nova_Scotia_Berhad',
  UnitedOverseasBankMalaysiaBerhad = 'United_Overseas_Bank_Malaysia_Berhad',
  DbsBankLimited = 'DBS_Bank_Limited'
}

export type BankInfoEntity = InterfaceBase & {
  __typename?: 'BankInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BankInfoID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  AccountName: Scalars['String'];
  AccountNo: Scalars['String'];
  SwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<Address2>;
  CompanyBank: CompanyEntity;
  ContactNo?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  CorporateID?: Maybe<Scalars['String']>;
  ClientBatchID?: Maybe<Scalars['String']>;
  OriginatorID?: Maybe<Scalars['String']>;
};

export type BankInfoInputs = {
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput2>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  CorporateID?: Maybe<Scalars['String']>;
  ClientBatchID?: Maybe<Scalars['String']>;
  OriginatorID?: Maybe<Scalars['String']>;
};

export type BankInfoOutput = {
  __typename?: 'BankInfoOutput';
  BankInfoID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CorporateID?: Maybe<Scalars['String']>;
  ClientBatchID?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BasicDashboardCardOutput = {
  __typename?: 'BasicDashboardCardOutput';
  FullName: Scalars['String'];
  EmployeeNo: Scalars['String'];
  CompanyName?: Maybe<Scalars['String']>;
  type?: Maybe<DashboardCardType>;
  ObjectDate?: Maybe<Scalars['DateTime']>;
  Due: Scalars['Float'];
};

export type BatchEntryByShiftEntity = InterfaceBase & {
  __typename?: 'BatchEntryByShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchEntryByShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Shift?: Maybe<ShiftEntity>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
};

export type BatchEntryByShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  BatchEntryByShiftID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};

export type BatchEntryByShiftPatternEntity = InterfaceBase & {
  __typename?: 'BatchEntryByShiftPatternEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchEntryByShiftPatternID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftPatternID: Scalars['String'];
  ShiftGroupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  ShiftPattern?: Maybe<ShiftPatternEntity>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
};

export type BatchEntryByShiftPatternInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  BatchEntryByShiftPatternID?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};

export enum BatchOvertimeDelegation {
  BatchOvertimeApproval = 'Batch_Overtime_Approval',
  BatchOvertimeCancellationApproval = 'Batch_Overtime_Cancellation_Approval'
}

export type BatchOvertimeDetails = {
  __typename?: 'BatchOvertimeDetails';
  ApprovalStatus?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type BatchOvertimeEntity = InterfaceBase & {
  __typename?: 'BatchOvertimeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchOvertimeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  BatchOvertimeStatus: OvertimeStatus;
  OvertimeRecord?: Maybe<Array<Scalars['JSON']>>;
  Overtime?: Maybe<Array<OvertimeEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  Superior?: Maybe<Scalars['JSON']>;
};

export type BatchOvertimeInput = {
  OvertimeRecord?: Maybe<Array<Scalars['JSON']>>;
  BatchOvertimeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BatchOvertimeStatus?: Maybe<OvertimeStatus>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
};

export type BiYearError = {
  __typename?: 'BiYearError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type BiYearlyError = {
  __typename?: 'BiYearlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type BlockLeaveEntity = InterfaceBase & {
  __typename?: 'BlockLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BlockLeaveID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type BlockLeaveInput = {
  BlockLeaveID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum BloodType {
  OMinus = 'O_Minus',
  OPlus = 'O_Plus',
  AMinus = 'A_Minus',
  APlus = 'A_Plus',
  BMinus = 'B_Minus',
  BPlus = 'B_Plus',
  AbMinus = 'AB_Minus',
  AbPlus = 'AB_Plus'
}

export type BluetoothBeaconDevicesEntity = {
  __typename?: 'BluetoothBeaconDevicesEntity';
  BluetoothBeaconDevicesID: Scalars['String'];
  DeviceAddress?: Maybe<Scalars['String']>;
  DeviceName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  LocationName?: Maybe<Scalars['String']>;
  InUse: Scalars['Boolean'];
};

export type BluetoothBeaconDevicesInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  BluetoothBeaconDevicesID?: Maybe<Scalars['String']>;
  DeviceAddress?: Maybe<Scalars['String']>;
  DeviceName?: Maybe<Scalars['String']>;
  LocationName?: Maybe<Scalars['String']>;
};

export type BonusSimulationEntity = InterfaceBase & {
  __typename?: 'BonusSimulationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BonusSimulationID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Months?: Maybe<Scalars['Float']>;
  Company?: Maybe<CompanyEntity>;
};

export type BonusSimulationInput = {
  BonusSimulationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Months?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BreakTimeEntity = InterfaceBase & {
  __typename?: 'BreakTimeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BreakTimeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Type: BreakTimeType;
  Start: Scalars['String'];
  End: Scalars['String'];
  ShiftID: Scalars['String'];
  shift?: Maybe<ShiftEntity>;
  ShiftDayID: Scalars['String'];
  shiftDay?: Maybe<ShiftDayEntity>;
};

export type BreakTimeInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  Type: BreakTimeType;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Start: Scalars['String'];
  End: Scalars['String'];
  BreakTimeID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  dayIndex?: Maybe<Scalars['Float']>;
  ShiftDayID?: Maybe<Scalars['String']>;
};

export type BreakTimeListInput = {
  Type?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  ShiftDuration?: Maybe<Scalars['Float']>;
  ShiftMinDuration?: Maybe<Scalars['Float']>;
  dayIndex?: Maybe<Scalars['Float']>;
};

export enum BreakTimeType {
  OtBreak = 'OT_BREAK',
  ShiftBreak = 'SHIFT_BREAK',
  OtBreakPrePost = 'OT_BREAK_Pre_Post'
}

export type BudgetGroupEntity = NewInterfaceBase1 & {
  __typename?: 'BudgetGroupEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  in_use?: Maybe<Scalars['Boolean']>;
  TrainingBudgetItems?: Maybe<Array<TrainingBudgetItemsEntity>>;
};

export type BudgetGroupInput = {
  name?: Maybe<Scalars['String']>;
  budget_group_id?: Maybe<Scalars['String']>;
};

export type BudgetGroupMatrixEntity = NewInterfaceBase1 & {
  __typename?: 'BudgetGroupMatrixEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  budget_group_id: Scalars['String'];
  condition_list: Array<Scalars['JSON']>;
  description: Scalars['String'];
  BudgetGroup?: Maybe<BudgetGroupEntity>;
};

export type BudgetGroupMatrixInput = {
  budget_group_matrix_id?: Maybe<Scalars['String']>;
  budget_group_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  condition_list?: Maybe<Array<Scalars['JSON']>>;
};

export type BusinessInsightClaimTakenOuput = {
  __typename?: 'BusinessInsightClaimTakenOuput';
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  ClaimTakenByLeaveType?: Maybe<Array<ClaimTakenByLeaveType>>;
};

export type BusinessInsightCompanyOutput = {
  __typename?: 'BusinessInsightCompanyOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<BusinessInsightDepartmentOutput>>;
};

export type BusinessInsightDepartmentOutput = {
  __typename?: 'BusinessInsightDepartmentOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<BusinessInsightDivisionOutput>>;
};

export type BusinessInsightDivisionOutput = {
  __typename?: 'BusinessInsightDivisionOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BusinessInsightGroupingOutput = {
  __typename?: 'BusinessInsightGroupingOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  groupName?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
};

export type BusinessInsightLeaveTakenOutput = {
  __typename?: 'BusinessInsightLeaveTakenOutput';
  TotalAllLeave?: Maybe<Scalars['Float']>;
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type BusinessInsightLeaveTypeOutput = {
  __typename?: 'BusinessInsightLeaveTypeOutput';
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  OthersLeave?: Maybe<Scalars['Float']>;
};

export type BusinessInsightListingOutput = {
  __typename?: 'BusinessInsightListingOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsResigned?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  LengthOfService?: Maybe<Scalars['Float']>;
  CurrentYearNewJoin?: Maybe<Scalars['Float']>;
  CurrentYearResigned?: Maybe<Scalars['Float']>;
};

export type BusinessInsightOutput = {
  __typename?: 'BusinessInsightOutput';
  FilterType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  TotalEmp?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollPayItemOutput = {
  __typename?: 'BusinessInsightPayrollPayItemOutput';
  NetAmount?: Maybe<Scalars['Float']>;
  TotalEarning?: Maybe<Scalars['Float']>;
  TotalOvertime?: Maybe<Scalars['Float']>;
  TotalNonpayable?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollSalaryOverheadOutput = {
  __typename?: 'BusinessInsightPayrollSalaryOverheadOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  NetPay?: Maybe<Scalars['Float']>;
  TotalGross?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollStatutoryOutput = {
  __typename?: 'BusinessInsightPayrollStatutoryOutput';
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  EmployerContribution?: Maybe<Scalars['Float']>;
  EmployeeContribution?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  EmployerSOCSO?: Maybe<Scalars['Float']>;
  EmployeeSOCSO?: Maybe<Scalars['Float']>;
};

export type BusinessInsightSummaryOutput = {
  __typename?: 'BusinessInsightSummaryOutput';
  FilterType?: Maybe<Scalars['String']>;
  Race?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Gender?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Religion?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Marital?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Age?: Maybe<Array<BusinessInsightGroupingOutput>>;
  EmploymentType?: Maybe<Array<BusinessInsightGroupingOutput>>;
  JobGrade?: Maybe<Array<BusinessInsightGroupingOutput>>;
  LengthOfService?: Maybe<Array<BusinessInsightGroupingOutput>>;
  RecruitmentSource?: Maybe<Array<BusinessInsightGroupingOutput>>;
  StaffTurnover?: Maybe<Array<BusinessInsightGroupingOutput>>;
};

export type BusinessInsightSummaryPayrollOutput = {
  __typename?: 'BusinessInsightSummaryPayrollOutput';
  FilterType?: Maybe<Scalars['String']>;
  SalaryOverhead?: Maybe<Array<BusinessInsightPayrollSalaryOverheadOutput>>;
  StatutoryContribution?: Maybe<Array<BusinessInsightPayrollStatutoryOutput>>;
  PayItems?: Maybe<Array<BusinessInsightPayrollPayItemOutput>>;
};

export type CcMappingListEntity = InterfaceBase & {
  __typename?: 'CCMappingListEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export enum CfMethod {
  No = 'No',
  PercentageEntitlement = 'Percentage_Entitlement',
  PercentageBalance = 'Percentage_Balance',
  Day = 'Day'
}

export type Cp159Entity = InterfaceBase & {
  __typename?: 'CP159Entity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CP159ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  ReceiptNoPCB?: Maybe<Scalars['String']>;
  ReceiptDatePCB?: Maybe<Scalars['DateTime']>;
  ReceiptNoCP38?: Maybe<Scalars['String']>;
  ReceiptDateCP38?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<CompanyEntity>;
};

export type Cp159Input = {
  CP159ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  ReceiptNoPCB?: Maybe<Scalars['String']>;
  ReceiptDatePCB?: Maybe<Scalars['DateTime']>;
  ReceiptNoCP38?: Maybe<Scalars['String']>;
  ReceiptDateCP38?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum CalculationMethod {
  ServiceDay = 'Service_Day',
  ServiceMonth = 'Service_Month'
}

export type CalendarOffDayEmployeeOutput = {
  __typename?: 'CalendarOffDayEmployeeOutput';
  StateID: Scalars['String'];
  EmployeeID: Scalars['String'];
  DesignationName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  CalendarOffDayID: Scalars['String'];
  StateName?: Maybe<Scalars['String']>;
};

export type CalendarOffDayEntity = InterfaceBase & {
  __typename?: 'CalendarOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CalendarOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  States?: Maybe<StatesEntity>;
  Employee?: Maybe<EmployeeEntity>;
  TotalEmployee?: Maybe<Scalars['Float']>;
};

export type CalendarOffDayInput = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  StateID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CalendarOffDayOutput = {
  __typename?: 'CalendarOffDayOutput';
  StateID: Scalars['String'];
  Description: Scalars['String'];
  TotalEmployee?: Maybe<Scalars['Float']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type CalendarPolicyEntity = InterfaceBase & {
  __typename?: 'CalendarPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CalendarPolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  StateID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  States?: Maybe<StatesEntity>;
  RecurringRestOffDays?: Maybe<Array<RecurringRestOffDayEntity>>;
  CompanyOffDays?: Maybe<Array<CompanyOffDayEntity>>;
  Employee?: Maybe<Array<EmployeeEntity>>;
  CompanyDelaredHolidays?: Maybe<Array<CompanyDelaredHolidayEntity>>;
};

export type CalendarPolicyInput = {
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  StateID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CalendarPolicyOutput = {
  __typename?: 'CalendarPolicyOutput';
  CalendarPolicyID?: Maybe<Scalars['String']>;
  StateID: Scalars['String'];
  StateName?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Array<Scalars['Float']>>;
  Default?: Maybe<Scalars['Boolean']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Title?: Maybe<Scalars['String']>;
  CompanyOffDayID?: Maybe<Scalars['String']>;
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  inUse?: Maybe<Scalars['Boolean']>;
  RestOffDay?: Maybe<Array<RecurringRestOffDayEntity>>;
  CompanyDelaredHoliday?: Maybe<Array<CompanyDelaredHolidayEntity>>;
  Holiday?: Maybe<Array<HolidayEntity>>;
  YearlyHolidaysGroup?: Maybe<Array<Scalars['JSON']>>;
};

export type CareerLogEntity = InterfaceBaseV3 & {
  __typename?: 'CareerLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  SalaryType?: Maybe<SalaryType>;
  MovementType?: Maybe<MovementType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  PrevConfirmedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  PayGroupID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  FromCompanyTable?: Maybe<CompanyEntity>;
  ToCompanyTable?: Maybe<CompanyEntity>;
  FromPositionTable?: Maybe<JobDesignationEntity>;
  ToPositionTable?: Maybe<JobDesignationEntity>;
  FromJobGradeTable?: Maybe<JobGradeEntity>;
  ToJobGradeTable?: Maybe<JobGradeEntity>;
  FromDepartmentTable?: Maybe<DepartmentEntity>;
  ToDepartmentTable?: Maybe<DepartmentEntity>;
  FromDivisionTable?: Maybe<DepartmentEntity>;
  ToDivisionTable?: Maybe<DepartmentEntity>;
  ExitCompanyReason?: Maybe<PersonnelParameterEntity>;
  ToOrgUnitTable?: Maybe<CompanyOrgUnitEntity>;
  Document?: Maybe<DocumentsEntity>;
  ToRole?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
  CancellationRemarks?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  Sequence?: Maybe<Scalars['Boolean']>;
  IsCopyActiveZakatCont?: Maybe<Scalars['Boolean']>;
  IsTransferAlBalance?: Maybe<Scalars['Boolean']>;
  IsTransferLeaveTaken?: Maybe<Scalars['Boolean']>;
  IsCopyStatutory?: Maybe<Scalars['Boolean']>;
  IsTransferCurrentYearPayroll?: Maybe<Scalars['Boolean']>;
  IsTransferLatestSalary?: Maybe<Scalars['Boolean']>;
  IsCopyCurrentYearTaxRelief?: Maybe<Scalars['Boolean']>;
  IsCopyActiveFixedAllowance?: Maybe<Scalars['Boolean']>;
  IsTransferMonthlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferBiYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferLifetimeClaimAmount?: Maybe<Scalars['Boolean']>;
  IsCopyProjectCostAllocation?: Maybe<Scalars['Boolean']>;
  IsTransferShiftGroup?: Maybe<Scalars['Boolean']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CalendarStateTitle?: Maybe<Scalars['String']>;
  NewEmployeeNo?: Maybe<Scalars['String']>;
  PayGroup?: Maybe<PayGroupEntity>;
  FromStaffCategory?: Maybe<Scalars['String']>;
  ToStaffCategory?: Maybe<Scalars['String']>;
  IsRetirementMandatory?: Maybe<Scalars['Boolean']>;
  IsAllowToCancel: Scalars['Boolean'];
  ShiftGroupName: Scalars['String'];
  ReportToSuperiorName: Scalars['String'];
  PrevCompReportToSuperiorName: Scalars['String'];
};

export type CareerLogInput = {
  CareerLogID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  SalaryType?: Maybe<SalaryType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  PrevConfirmedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogInput>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  IsCopyActiveZakatCont?: Maybe<Scalars['Boolean']>;
  IsTransferAlBalance?: Maybe<Scalars['Boolean']>;
  IsTransferLeaveTaken?: Maybe<Scalars['Boolean']>;
  IsCopyStatutory?: Maybe<Scalars['Boolean']>;
  IsTransferCurrentYearPayroll?: Maybe<Scalars['Boolean']>;
  IsTransferLatestSalary?: Maybe<Scalars['Boolean']>;
  IsCopyCurrentYearTaxRelief?: Maybe<Scalars['Boolean']>;
  IsCopyActiveFixedAllowance?: Maybe<Scalars['Boolean']>;
  IsTransferMonthlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferBiYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferYearlyClaimAmount?: Maybe<Scalars['Boolean']>;
  IsTransferLifetimeClaimAmount?: Maybe<Scalars['Boolean']>;
  IsCopyProjectCostAllocation?: Maybe<Scalars['Boolean']>;
  IsTransferShiftGroup?: Maybe<Scalars['Boolean']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CalendarStateTitle?: Maybe<Scalars['String']>;
  NewEmployeeNo?: Maybe<Scalars['String']>;
  PayGroupID?: Maybe<Scalars['String']>;
  FromStaffCategory?: Maybe<Scalars['String']>;
  ToStaffCategory?: Maybe<Scalars['String']>;
  IsRetirementMandatory?: Maybe<Scalars['Boolean']>;
};

export type ChangeShiftEntity = InterfaceBase & {
  __typename?: 'ChangeShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ChangeShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Date: Scalars['String'];
  Reason?: Maybe<Scalars['String']>;
  FromShift?: Maybe<ShiftEntity>;
  FromShiftID: Scalars['String'];
  Employee?: Maybe<EmployeeEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToShift?: Maybe<ShiftEntity>;
  ToShiftID?: Maybe<Scalars['String']>;
  ChangeShiftStatus: ChangeShiftStatus;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  RejectReason?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  ReplacedEmployee?: Maybe<EmployeeEntity>;
  ReplacedBy?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ChangeShiftHistory?: Maybe<Array<ChangeShiftEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ChangeShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ChangeShiftID?: Maybe<Scalars['String']>;
  FromShiftID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToShiftID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ChangeShiftStatus?: Maybe<Scalars['String']>;
  ReplacedBy?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
};

export enum ChangeShiftStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Withdrawn = 'Withdrawn',
  Withdraw = 'Withdraw'
}

export type ChangesData = {
  __typename?: 'ChangesData';
  DocumentID?: Maybe<Scalars['String']>;
  ProfileDocumentID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  PermanentAddress?: Maybe<Address2>;
  Address?: Maybe<Address2>;
  MobileNo?: Maybe<Scalars['String']>;
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  Occupation?: Maybe<Scalars['String']>;
  Education?: Maybe<Education>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  PCBNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  PersonnelEducationID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Major?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  IssuedDate?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['String']>;
  CertRegNo?: Maybe<Scalars['String']>;
  CertURL?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<Scalars['String']>;
  Diet?: Maybe<Scalars['String']>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
  MedicalRecordIDs?: Maybe<Array<Scalars['String']>>;
  ExistingMedicalRecords?: Maybe<Array<Scalars['String']>>;
  PersonnelJobHistoryID?: Maybe<Scalars['String']>;
  JobStartDate?: Maybe<Scalars['String']>;
  JobEndDate?: Maybe<Scalars['String']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  CompanyAddress?: Maybe<Address2>;
  EmployeeBank?: Maybe<Scalars['String']>;
  EmployeeBankNo?: Maybe<Scalars['String']>;
  EmployeeEPFNo?: Maybe<Scalars['String']>;
  EmployeePCBNo?: Maybe<Scalars['String']>;
};

export type ChangesDataInput = {
  DocumentID?: Maybe<Scalars['String']>;
  ProfileDocumentID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  PermanentAddress?: Maybe<AddressInput2>;
  Address?: Maybe<AddressInput2>;
  MobileNo?: Maybe<Scalars['String']>;
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  Occupation?: Maybe<Scalars['String']>;
  Education?: Maybe<Education>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  PCBNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  PersonnelEducationID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Major?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  Grade?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  IssuedDate?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['String']>;
  CertRegNo?: Maybe<Scalars['String']>;
  CertURL?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<Scalars['String']>;
  Diet?: Maybe<Scalars['String']>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
  MedicalRecordIDs?: Maybe<Array<Scalars['String']>>;
  ExistingMedicalRecords?: Maybe<Array<Scalars['String']>>;
  PersonnelJobHistoryID?: Maybe<Scalars['String']>;
  JobStartDate?: Maybe<Scalars['String']>;
  JobEndDate?: Maybe<Scalars['String']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  CompanyAddress?: Maybe<AddressInput2>;
  EmployeeBank?: Maybe<Scalars['String']>;
  EmployeeBankNo?: Maybe<Scalars['String']>;
  EmployeeEPFNo?: Maybe<Scalars['String']>;
  EmployeePCBNo?: Maybe<Scalars['String']>;
};

export enum CheckInMethod {
  Qr = 'QR',
  Location = 'Location',
  Bluetooth = 'Bluetooth',
  ManualEntry = 'ManualEntry'
}

export type ClaimAccountAssignmentEntity = InterfaceBase & {
  __typename?: 'ClaimAccountAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  ClaimAccountHeader?: Maybe<ClaimAccountHeaderEntity>;
};

export type ClaimAccountAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ClaimAccountDetailEntity = InterfaceBase & {
  __typename?: 'ClaimAccountDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  ClaimAccountHeader?: Maybe<ClaimAccountHeaderEntity>;
};

export type ClaimAccountDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
};

export type ClaimAccountHeaderEntity = InterfaceBase & {
  __typename?: 'ClaimAccountHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  ClaimAccountDetail?: Maybe<Array<ClaimAccountDetailEntity>>;
};

export type ClaimAccountHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
};

export type ClaimAccountHeaderOutput = {
  __typename?: 'ClaimAccountHeaderOutput';
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  DeleteDisable?: Maybe<Scalars['Boolean']>;
};

export type ClaimAppDetailsOutput = {
  __typename?: 'ClaimAppDetailsOutput';
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type ClaimAppOutput = {
  __typename?: 'ClaimAppOutput';
  Subject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Details?: Maybe<Array<ClaimAppDetailsOutput>>;
};

export type ClaimBenefitEntity = InterfaceBase & {
  __typename?: 'ClaimBenefitEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimBenefitID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<SoftDelete>;
  ClaimItems?: Maybe<Array<ClaimItemEntity>>;
  RateDetails?: Maybe<Array<ClaimDailyRateEntity>>;
  VehicleRateDetails?: Maybe<Array<ClaimVehicleRateEntity>>;
  MealRateDetails?: Maybe<Array<ClaimMealRateEntity>>;
  InUse: Scalars['Boolean'];
};

export type ClaimBenefitInput = {
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<SoftDelete>;
};

export enum ClaimCategory {
  Normal = 'Normal',
  Mileage = 'Mileage',
  Daily = 'Daily',
  Meal = 'Meal'
}

export type ClaimCutOffPeriodEntity = InterfaceBase & {
  __typename?: 'ClaimCutOffPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimCutOffPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  Company?: Maybe<CompanyEntity>;
};

export enum ClaimDailyFrequency {
  Day = 'Day',
  Night = 'Night'
}

export type ClaimDailyRateEntity = InterfaceBase & {
  __typename?: 'ClaimDailyRateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimDailyRateID: Scalars['String'];
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  dailyRate?: Maybe<Array<ClaimParameterEntity>>;
  dailyRates?: Maybe<Array<ClaimBenefitEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<MultiCurrencySetupEntity>;
  VenueName: Scalars['String'];
};

export type ClaimDailyRateInput = {
  ClaimDailyRateID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimDetailListingReportInput = {
  CompanyID: Scalars['String'];
  SelectAllEmp: Scalars['Boolean'];
  ClaimTypeIDs: Array<Scalars['String']>;
  ApprovalStatus: Array<Scalars['String']>;
  GroupBy: Scalars['String'];
  ApproveStartDate?: Maybe<Scalars['DateTime']>;
  ApproveEndDate?: Maybe<Scalars['DateTime']>;
  CreateStartDate?: Maybe<Scalars['DateTime']>;
  VerifyStartDate?: Maybe<Scalars['DateTime']>;
  VerifyEndDate?: Maybe<Scalars['DateTime']>;
  CreateEndDate?: Maybe<Scalars['DateTime']>;
  ReceiptStartDate?: Maybe<Scalars['DateTime']>;
  ReceiptEndDate?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
};

export type ClaimDetails = {
  __typename?: 'ClaimDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ClaimSubject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  TotalVerifiedAmount?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ClaimDetailsListing?: Maybe<Array<ClaimDetailsEntity>>;
};

export type ClaimDetailsEntity = InterfaceBase & {
  __typename?: 'ClaimDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimDetailsID: Scalars['String'];
  ClaimHeaderID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  Fields?: Maybe<Array<Scalars['JSON']>>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  FreeTextLocationFrom?: Maybe<Scalars['String']>;
  FreeTextLocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  ForeignAmount?: Maybe<Scalars['Float']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  Distance?: Maybe<Scalars['Float']>;
  DataTable?: Maybe<Scalars['String']>;
  ClaimHeader?: Maybe<ClaimHeaderEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
  Documents?: Maybe<DocumentsEntity>;
  MultipleDocuments?: Maybe<Array<DocumentsEntity>>;
  MultiCurrencySetup?: Maybe<MultiCurrencySetupEntity>;
  ClaimMileage?: Maybe<ClaimMileageTableEntity>;
};

export type ClaimDetailsInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  ClaimRemarks: Scalars['String'];
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus: ApprovalStatus;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimDuplicateList = {
  __typename?: 'ClaimDuplicateList';
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  DocFile?: Maybe<Scalars['String']>;
};

export enum ClaimFrequency {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  BiYearly = 'Bi_Yearly',
  Lifetime = 'Lifetime'
}

export type ClaimHeaderEntity = InterfaceBase & {
  __typename?: 'ClaimHeaderEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CancelRemark?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ClaimNo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimHistory?: Maybe<Array<ClaimHeaderEntity>>;
  ClaimDetails: Array<ClaimDetailsEntity>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  JobAccount: JobAccountEntity;
  Approver?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ClaimHeaderInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
};

export type ClaimHistoryListingOutput = {
  __typename?: 'ClaimHistoryListingOutput';
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimDetails?: Maybe<Array<ClaimDetailsEntity>>;
};

export type ClaimInsertOutput = {
  __typename?: 'ClaimInsertOutput';
  RespStatus?: Maybe<Scalars['String']>;
  RespMsg?: Maybe<Array<ArrMesg>>;
};

export type ClaimItemEntity = InterfaceBase & {
  __typename?: 'ClaimItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimItemID: Scalars['String'];
  ClaimBenefitID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<ClaimFrequency>;
  TotalAmt?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  DailyFrequency?: Maybe<ClaimDailyFrequency>;
  OccurrenceLimit?: Maybe<Scalars['Float']>;
  IsLimit?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsMileageTable?: Maybe<Scalars['Boolean']>;
  MileageTableID?: Maybe<Scalars['String']>;
  MileageTable?: Maybe<ClaimParameterEntity>;
  ClaimBenefit?: Maybe<ClaimBenefitEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
  ClaimTemplates?: Maybe<Array<ClaimTemplateEntity>>;
  RateDetails?: Maybe<Array<ClaimDailyRateEntity>>;
  VehicleRateDetails?: Maybe<Array<ClaimVehicleRateEntity>>;
  MealRateDetails?: Maybe<Array<ClaimMealRateEntity>>;
  MileageTableName?: Maybe<Scalars['String']>;
};

export type ClaimItemInput = {
  ClaimItemID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<ClaimFrequency>;
  DailyFrequency?: Maybe<ClaimDailyFrequency>;
  TotalAmt?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  OccurrenceLimit?: Maybe<Scalars['Float']>;
  IsLimit?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsMileageTable?: Maybe<Scalars['Boolean']>;
  MileageTableID?: Maybe<Scalars['String']>;
  ClaimVehicleRateID?: Maybe<Scalars['String']>;
};

export type ClaimItemsWithAmountInput = {
  OrgUnitID?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  StaffCategoryName?: Maybe<Scalars['String']>;
  AllocationTotalAmount?: Maybe<Scalars['Float']>;
  Method?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ClaimDetails?: Maybe<Array<ClaimRecordsDetailInput>>;
};

export type ClaimItemsWithAmountList = {
  __typename?: 'ClaimItemsWithAmountList';
  OrgUnitID?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  StaffCategoryName?: Maybe<Scalars['String']>;
  AllocationTotalAmount?: Maybe<Scalars['Float']>;
  Method?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ClaimDetails?: Maybe<Array<ClaimRecordsDetailList>>;
};

export type ClaimManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum ClaimManagementDelegation {
  ClaimSubmissionApproval = 'Claim_Submission_Approval',
  ClaimCancellationApproval = 'Claim_Cancellation_Approval'
}

export type ClaimManagementListing = {
  __typename?: 'ClaimManagementListing';
  Title?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ClaimMealRateEntity = InterfaceBase & {
  __typename?: 'ClaimMealRateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimMealRateID: Scalars['String'];
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  MealRate?: Maybe<Array<ClaimParameterEntity>>;
  MealRates?: Maybe<Array<ClaimBenefitEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  VenueID?: Maybe<Scalars['String']>;
  MealType: Scalars['String'];
  VenueName: Scalars['String'];
};

export type ClaimMealRateInput = {
  ClaimMealRateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  VenueID?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimMileageTableEntity = InterfaceBase & {
  __typename?: 'ClaimMileageTableEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimMileageTableID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Route?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
  mileageTable?: Maybe<Array<ClaimParameterEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
};

export type ClaimMileageTableInput = {
  ClaimMileageTableID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  Route?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimParameterEntity = InterfaceBase & {
  __typename?: 'ClaimParameterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimParameterID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimParameterType?: Maybe<ClaimParameterType>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Details?: Maybe<Array<ClaimMileageTableEntity>>;
  UserFieldsDetails?: Maybe<Array<ClaimUserDefineFieldsEntity>>;
  VehicleDetails?: Maybe<Array<ClaimVehicleRateEntity>>;
  MealDetails?: Maybe<Array<ClaimMealRateEntity>>;
  RateDetails?: Maybe<Array<ClaimDailyRateEntity>>;
  MultiCurrency?: Maybe<MultiCurrencySetupEntity>;
};

export type ClaimParameterInput = {
  ClaimParameterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimParameterType?: Maybe<ClaimParameterType>;
  Status?: Maybe<Status>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ClaimParameterType {
  VehicleType = 'Vehicle_Type',
  MileageLocation = 'Mileage_Location',
  MileageTable = 'Mileage_Table',
  MealType = 'Meal_Type',
  Venue = 'Venue',
  ExpenseAccounts = 'Expense_Accounts',
  ClaimUserDefineFields = 'Claim_User_Define_Fields'
}

export type ClaimPoolConditionEntity = InterfaceBase & {
  __typename?: 'ClaimPoolConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  ClaimPoolID?: Maybe<Scalars['String']>;
  PoolAmountLimit?: Maybe<Scalars['Float']>;
  PoolFrequency?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimPool?: Maybe<ClaimPoolEntity>;
};

export type ClaimPoolConditionInput = {
  ClaimPoolID?: Maybe<Scalars['String']>;
  PoolAmountLimit?: Maybe<Scalars['Float']>;
  PoolFrequency?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
};

export type ClaimPoolEntity = InterfaceBase & {
  __typename?: 'ClaimPoolEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  PoolTitle?: Maybe<Scalars['String']>;
  ClaimTypeIDs?: Maybe<Scalars['String']>;
  FrequencyCriteria?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  ClaimTypes?: Maybe<Array<ClaimTypeEntity>>;
  ClaimPoolCondition: Array<ClaimPoolConditionEntity>;
};

export type ClaimPoolInput = {
  ID?: Maybe<Scalars['String']>;
  PoolTitle?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimTypeIDs?: Maybe<Scalars['String']>;
  FrequencyCriteria?: Maybe<Scalars['String']>;
  ClaimPoolConditionList?: Maybe<Array<ClaimPoolConditionInput>>;
};

export type ClaimPostedListingOutput = {
  __typename?: 'ClaimPostedListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleName?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostedBy?: Maybe<Scalars['String']>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export type ClaimPostingInput = {
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type ClaimPostingListingOutput = {
  __typename?: 'ClaimPostingListingOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  DocumentIDList?: Maybe<Array<Scalars['String']>>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
  History?: Maybe<Array<ClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<GlClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
  ClaimPostedListing?: Maybe<ClaimPostedListingOutput>;
};

export type ClaimPostingManagementInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['String']>;
  DateTo?: Maybe<Scalars['String']>;
};

export type ClaimPostingManagementOutput = {
  __typename?: 'ClaimPostingManagementOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  HeaderTotalAmt?: Maybe<Scalars['String']>;
  HeaderTotalClaim?: Maybe<Scalars['String']>;
};

export type ClaimPostingPeriodListingInput = {
  Year?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type ClaimPostingPeriodListingOutput = {
  __typename?: 'ClaimPostingPeriodListingOutput';
  ClaimCutOffPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  LatestPeriod?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ListingStatus?: Maybe<Scalars['String']>;
  ClaimPostingPeriodStatusList?: Maybe<Array<ClaimPostingPeriodStatus>>;
};

export type ClaimPostingPeriodStatus = {
  __typename?: 'ClaimPostingPeriodStatus';
  ClaimPostingPeriodStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
};

export type ClaimRecordHistoryInput = {
  EmployeeID?: Maybe<Scalars['String']>;
};

export type ClaimRecordHistoryOutput = {
  __typename?: 'ClaimRecordHistoryOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
};

export type ClaimRecordInput = {
  CompanyID?: Maybe<Scalars['String']>;
};

export type ClaimRecordOutput = {
  __typename?: 'ClaimRecordOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  VerifyBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  History?: Maybe<Scalars['String']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  CheckComplete?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetail = {
  __typename?: 'ClaimRecordsDetail';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
  UserDefineField1?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentUrl?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  ForeignAmount?: Maybe<Scalars['Float']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  ClaimNo?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  FreeTextLocationFrom?: Maybe<Scalars['String']>;
  FreeTextLocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  DataTable?: Maybe<Scalars['String']>;
  ClaimTypePaymentMethod?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  ClaimCategory?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetailHeaderOutput = {
  __typename?: 'ClaimRecordsDetailHeaderOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeBankName?: Maybe<Scalars['String']>;
  EmployeeBankAccountNo?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  DepartmentName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  PostingStatus?: Maybe<Scalars['String']>;
  ClaimRecordsDetail?: Maybe<Array<ClaimRecordsDetail>>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetailInput = {
  Subject?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimDetailsID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetailList = {
  __typename?: 'ClaimRecordsDetailList';
  Subject?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimDetailsID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
};

export type ClaimRecordsInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export type ClaimRecordsItemInput = {
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  ForeignAmount?: Maybe<Scalars['Float']>;
  Fields?: Maybe<Scalars['JSON']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  Document?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  FreeTextLocationFrom?: Maybe<Scalars['String']>;
  FreeTextLocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  VoiceInput?: Maybe<DocumentsInput>;
  DocInput?: Maybe<DocumentsInput>;
  DocInputs?: Maybe<Array<DocumentsInput>>;
  DocToRemove?: Maybe<Array<Scalars['String']>>;
  DefaultDocList?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  DataTable?: Maybe<Scalars['String']>;
  Distance?: Maybe<Scalars['Float']>;
};

export type ClaimRecordsListing = {
  __typename?: 'ClaimRecordsListing';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  ClaimMileageID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  DocumentIDList?: Maybe<Array<Scalars['String']>>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
  History?: Maybe<Array<ClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<GlClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ClaimSettingEntity = InterfaceBase & {
  __typename?: 'ClaimSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimSettingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
};

export type ClaimSettingInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimSettingsListing = {
  __typename?: 'ClaimSettingsListing';
  Title?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CountRecords?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ClaimTakenByLeaveType = {
  __typename?: 'ClaimTakenByLeaveType';
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimTaken?: Maybe<Scalars['Float']>;
};

export type ClaimTemplateEntity = InterfaceBase & {
  __typename?: 'ClaimTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimTemplateID: Scalars['String'];
  JobGradePolicyID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  JobGradePolicy?: Maybe<JobGradePolicyEntity>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type ClaimTemplateInput = {
  ClaimTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimToApproveListingOutput = {
  __typename?: 'ClaimToApproveListingOutput';
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  ClaimNo?: Maybe<Scalars['String']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimDetails?: Maybe<Array<ClaimDetailsEntity>>;
  ClaimHistory?: Maybe<Array<ClaimHistoryListingOutput>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
};

export type ClaimTrxError = {
  __typename?: 'ClaimTrxError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ClaimTypeEntity = InterfaceBase & {
  __typename?: 'ClaimTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimTypeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  ClaimCategory?: Maybe<ClaimCategory>;
  PayItem?: Maybe<PayItemEntity>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsDisplay?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  IsAdditionalFieldsTemplate?: Maybe<Scalars['Boolean']>;
  UserDefineFieldsTemplate?: Maybe<Scalars['String']>;
  IsMultiCurrencyEnable?: Maybe<Scalars['Boolean']>;
  IsCurrencyOverride?: Maybe<Scalars['Boolean']>;
  CurrencyEffectiveMethod?: Maybe<CurrencyEffectiveMethod>;
  ClaimPolicies?: Maybe<Array<ClaimItemEntity>>;
};

export type ClaimTypeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  ClaimCategory?: Maybe<ClaimCategory>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsDisplay?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsAdditionalFieldsTemplate?: Maybe<Scalars['Boolean']>;
  IsMultiCurrencyEnable?: Maybe<Scalars['Boolean']>;
  IsCurrencyOverride?: Maybe<Scalars['Boolean']>;
  CurrencyEffectiveMethod?: Maybe<CurrencyEffectiveMethod>;
  UserDefineFieldsTemplate?: Maybe<Scalars['String']>;
};

export type ClaimUserDefineFieldsEntity = InterfaceBase & {
  __typename?: 'ClaimUserDefineFieldsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimUserDefineFieldsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsCompulsory?: Maybe<Scalars['Boolean']>;
  userTable?: Maybe<Array<ClaimParameterEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type ClaimUserDefineFieldsInput = {
  ClaimUserDefineFieldsID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsCompulsory?: Maybe<Scalars['Boolean']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type ClaimVehicleRateEntity = InterfaceBase & {
  __typename?: 'ClaimVehicleRateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimVehicleRateID: Scalars['String'];
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  StartValue?: Maybe<Scalars['Float']>;
  EndValue?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
  vehicleRate?: Maybe<Array<ClaimParameterEntity>>;
  vehicleRates?: Maybe<Array<ClaimBenefitEntity>>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  VehicleName: Scalars['String'];
};

export type ClaimVehicleRateInput = {
  ClaimVehicleRateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimParameterID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  StartValue?: Maybe<Scalars['Float']>;
  EndValue?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ClockPairingType {
  Today = 'TODAY',
  Tomorrow = 'TOMORROW'
}

export type CommonSettingsInfoLinesOutput = {
  __typename?: 'CommonSettingsInfoLinesOutput';
  JobGradeTotal?: Maybe<Scalars['Float']>;
  SkillSetTotal?: Maybe<Scalars['Float']>;
  ReportSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  ReportSettingsLastPostedDate2?: Maybe<Scalars['DateTime']>;
  JobPositionTotal?: Maybe<Scalars['Float']>;
  InsSchemeTotal?: Maybe<Scalars['Float']>;
  RecSrcTotal?: Maybe<Scalars['Float']>;
  UsrDefTotal?: Maybe<Scalars['Float']>;
  DataChangeApprovalPolicyLastModifiedDate?: Maybe<Scalars['DateTime']>;
  PersonnelEFileSecurityPolicyLastModifiedDate?: Maybe<Scalars['DateTime']>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type CompanyAccessPermEntity = InterfaceBase & {
  __typename?: 'CompanyAccessPermEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyAccessPermID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
};

export type CompanyAccessPermInput = {
  OrgUnitID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
};

export type CompanyAltAddressEntity = InterfaceBase & {
  __typename?: 'CompanyAltAddressEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyAltAddressID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Address?: Maybe<Address2>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
};

export type CompanyAltAddressInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput2>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyCyclePeriodListOutput = {
  __typename?: 'CompanyCyclePeriodListOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollStatus?: Maybe<Scalars['String']>;
  IsLastProcess?: Maybe<Scalars['Boolean']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  MMMYYYY?: Maybe<Scalars['String']>;
  PayCycleName?: Maybe<Scalars['String']>;
};

export type CompanyDelaredHolidayEntity = InterfaceBase & {
  __typename?: 'CompanyDelaredHolidayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyDelaredHolidayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
};

export type CompanyDelaredHolidayInput = {
  CompanyDelaredHolidayID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
};

export type CompanyEntity = InterfaceBase & {
  __typename?: 'CompanyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CalendarTitle?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  SupportEmails?: Maybe<Scalars['String']>;
  BizRegNo?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  WorkDaysAMonth?: Maybe<Scalars['Float']>;
  WorkHoursADay?: Maybe<Scalars['Float']>;
  PayrollCutOffDay?: Maybe<Scalars['Float']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  OvertimeCutOffDay?: Maybe<Scalars['Float']>;
  AttendanceCorrectionCutOffDay?: Maybe<Scalars['Float']>;
  EnableExpenseAccount?: Maybe<Scalars['Boolean']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  ProjectExpenseTrxDayLimit?: Maybe<Scalars['Float']>;
  ProjectExpenseCutOff?: Maybe<Scalars['Float']>;
  ExcludeTaxRelief?: Maybe<Scalars['Boolean']>;
  Departments?: Maybe<Array<DepartmentEntity>>;
  Employees?: Maybe<Array<EmployeeEntity>>;
  BankInfos?: Maybe<Array<BankInfoEntity>>;
  CompanyOffDays?: Maybe<Array<CompanyOffDayEntity>>;
  RecurringRestOffDays?: Maybe<Array<RecurringRestOffDayEntity>>;
  LeaveRounding?: Maybe<LeaveRoundingEntity>;
  Documents?: Maybe<Array<DocumentsEntity>>;
  Contact?: Maybe<ContactEntity>;
  CompanyStatutory?: Maybe<CompanyStatutoryEntity>;
  LeaveCompulsory?: Maybe<Array<LeaveCompulsoryPolicyEntity>>;
  JobGrade?: Maybe<Array<JobGradePolicyEntity>>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  State?: Maybe<StatesEntity>;
  CustomPayslipFormat?: Maybe<Scalars['String']>;
  CustomPayrollGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimGlExportFormat?: Maybe<Scalars['String']>;
  CustomProjectExpenseGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimSubmissionReport?: Maybe<Scalars['String']>;
  CustomClaimConfirmation?: Maybe<Scalars['String']>;
  ModerationTableModifiedDate?: Maybe<Scalars['DateTime']>;
};

export type CompanyHierarchyEntity = InterfaceBase & {
  __typename?: 'CompanyHierarchyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyHierarchyID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
};

export type CompanyHierarchyInput = {
  CompanyHierarchyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  SupportEmails?: Maybe<Scalars['String']>;
  OvertimeCutOffDay?: Maybe<Scalars['Float']>;
  AttendanceCorrectionCutOffDay?: Maybe<Scalars['Float']>;
  BizRegNo?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  WorkDaysAMonth?: Maybe<Scalars['Float']>;
  WorkHoursADay?: Maybe<Scalars['Float']>;
  EnableExpenseAccount?: Maybe<Scalars['Boolean']>;
  PayrollCutOffDay?: Maybe<Scalars['Float']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  ProjectExpenseTrxDayLimit?: Maybe<Scalars['Float']>;
  ProjectExpenseCutOff?: Maybe<Scalars['Float']>;
  ExcludeTaxRelief?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CustomPayslipFormat?: Maybe<Scalars['String']>;
  CustomPayrollGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimGlExportFormat?: Maybe<Scalars['String']>;
  CustomProjectExpenseGlExportFormat?: Maybe<Scalars['String']>;
  CustomClaimSubmissionReport?: Maybe<Scalars['String']>;
  CustomClaimConfirmation?: Maybe<Scalars['String']>;
};

export type CompanyOffDayEntity = InterfaceBase & {
  __typename?: 'CompanyOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  HolidayID?: Maybe<Scalars['String']>;
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  Holiday?: Maybe<HolidayEntity>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
};

export type CompanyOffDayInput = {
  CompanyID?: Maybe<Scalars['String']>;
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  HolidayID?: Maybe<Scalars['String']>;
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  StateID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CompanyDayOffIDs?: Maybe<Array<Scalars['String']>>;
};

export type CompanyOffDayOutput = {
  __typename?: 'CompanyOffDayOutput';
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
};

export type CompanyOffDayWithFilterOutput = {
  __typename?: 'CompanyOffDayWithFilterOutput';
  CalendarOffDayID: Scalars['String'];
};

export type CompanyOrgUnitEntity = InterfaceBase & {
  __typename?: 'CompanyOrgUnitEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyOrgUnitID: Scalars['String'];
  CompanyHierarchyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Employee?: Maybe<Array<EmployeeEntity>>;
  CompanyHierarchy?: Maybe<CompanyHierarchyEntity>;
};

export type CompanyOrgUnitInput = {
  CompanyOrgUnitID?: Maybe<Scalars['String']>;
  CompanyHierarchyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type CompanyPayGroupListingOutput = {
  __typename?: 'CompanyPayGroupListingOutput';
  PayGroupID: Scalars['String'];
  Title: Scalars['String'];
  CutOffDate: Scalars['String'];
  UserLogs: Array<UserLogs>;
};

export type CompanyRunningNoEntity = InterfaceBase & {
  __typename?: 'CompanyRunningNoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyRunningNoID: Scalars['String'];
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  NextNumber?: Maybe<Scalars['Float']>;
};

export type CompanyRunningNoInput = {
  CompanyRunningNoID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  NextNumber?: Maybe<Scalars['Float']>;
};

export type CompanyStatutoryAccountEntity = {
  __typename?: 'CompanyStatutoryAccountEntity';
  CompanyStatutoryAccountID: Scalars['String'];
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyAltAddressID?: Maybe<Scalars['String']>;
  StatutoryAccount?: Maybe<CompanyStatutoryEntity>;
};

export type CompanyStatutoryAccountInput = {
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  CompanyAltAddressID?: Maybe<Scalars['String']>;
};

export type CompanyStatutoryAccountOutput = {
  __typename?: 'CompanyStatutoryAccountOutput';
  CompanyStatutoryAccountID: Scalars['String'];
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  removable?: Maybe<Scalars['Boolean']>;
  CompanyAltAddressID?: Maybe<Scalars['String']>;
};

export type CompanyStatutoryEntity = InterfaceBase & {
  __typename?: 'CompanyStatutoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyStatutoryID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EPFNo?: Maybe<Scalars['String']>;
  SocsoNo?: Maybe<Scalars['String']>;
  PCBNo?: Maybe<Scalars['String']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  SignBy?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  SignByCompany?: Maybe<CompanyEntity>;
  EPFAccount?: Maybe<Array<StatutoryAccount>>;
  SocsoAccount?: Maybe<Array<StatutoryAccount>>;
  PCBAccount?: Maybe<Array<StatutoryAccount>>;
  EISAccount?: Maybe<Array<StatutoryAccount>>;
  StatutoryAccount?: Maybe<Array<CompanyStatutoryAccountEntity>>;
};

export type CompanyStatutoryInput = {
  CompanyID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  SignBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyStatutoryOutput = {
  __typename?: 'CompanyStatutoryOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyStatutoryID?: Maybe<Scalars['String']>;
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  AccNoDesc?: Maybe<Scalars['String']>;
};

export type CompanyViewLastUpdatedOutput = {
  __typename?: 'CompanyViewLastUpdatedOutput';
  CompanyInfoLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyInfoIsNewLastUpdated?: Maybe<Scalars['Boolean']>;
  StatutoryLastPostedDate?: Maybe<Scalars['DateTime']>;
  DepartmentLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyAccessAuthLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyHierarchyLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyOrgUnitLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyReportingStructureLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyJobPositionLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyAccessLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyAutoNumberingLastPostedDate?: Maybe<Scalars['DateTime']>;
  ExpensesAccountLastPostedDate?: Maybe<Scalars['DateTime']>;
  BankInfoLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarOffDayPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  LeavePolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  ClaimPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  EmployeeLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyLastUpdated?: Maybe<Scalars['DateTime']>;
  CalendarPolicyListLastPostedDate?: Maybe<Scalars['DateTime']>;
  NoOfGeneralDocument?: Maybe<Scalars['Float']>;
  TotalSizeOfGeneralDocument?: Maybe<Scalars['Float']>;
  RoleLastUpdated?: Maybe<Scalars['DateTime']>;
  RoleLastPostedDate?: Maybe<Scalars['DateTime']>;
  NoOfDepartment?: Maybe<Scalars['Float']>;
  NoOfRoleAssignment?: Maybe<Scalars['Float']>;
  DefaultState?: Maybe<Scalars['String']>;
  NoOfStates?: Maybe<Scalars['Float']>;
};

export type CompositionRulesInput = {
  KpiCategoryID: Scalars['String'];
  Title: Scalars['String'];
  Min: Scalars['Float'];
  Max: Scalars['Float'];
};

export type CompulsaryCourseByJobGradeEntity = InterfaceBase & {
  __typename?: 'CompulsaryCourseByJobGradeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
};

export type CompulsaryCourseByJobGradeInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompulsaryCourseItemInput = {
  training_course_library_id?: Maybe<Scalars['String']>;
};

export type CompulsoryLeaveOutput = {
  __typename?: 'CompulsoryLeaveOutput';
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  CountEmployees?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostedByName?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Employees?: Maybe<Array<EmployeeIndentity>>;
};

export type ConditionList = {
  TypeID: Scalars['String'];
  operatorValue: Scalars['String'];
  valueVal: Array<Scalars['String']>;
};

export type ConditionListInput = {
  Criteria: Array<CriteriaListInput>;
  Description: Scalars['String'];
};

export type ConditionObject = {
  __typename?: 'ConditionObject';
  Description?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type ConditionObjectSpecialCase = {
  __typename?: 'ConditionObjectSpecialCase';
  Description?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type ContactEntity = InterfaceBase & {
  __typename?: 'ContactEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Address2>;
  ProfilePicture?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  PermanentAddress?: Maybe<Address2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  DocumentID?: Maybe<Scalars['String']>;
  Documents?: Maybe<DocumentsEntity>;
  TargetEmployeesContact?: Maybe<Array<DelegationEntity>>;
  EmployeeSalutation?: Maybe<PersonnelParameterEntity>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  PassportExpiryDate?: Maybe<Scalars['DateTime']>;
  PhoneCountryCode?: Maybe<Scalars['String']>;
  IsTalentPool?: Maybe<Scalars['Boolean']>;
};

export type ContactInput = {
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<AddressInput2>;
  PermanentAddress?: Maybe<AddressInput2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  PassportExpiryDate?: Maybe<Scalars['DateTime']>;
  PhoneCountryCode?: Maybe<Scalars['String']>;
  IsTalentPool?: Maybe<Scalars['Boolean']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum ContractDocType {
  WorkPermit = 'Work_Permit',
  EmploymentLetter = 'Employment_Letter',
  EmploymentContract = 'Employment_Contract',
  EmploymentPass = 'Employment_Pass'
}

export type ContractEmployeeOutput = {
  __typename?: 'ContractEmployeeOutput';
  Employee?: Maybe<EmployeeEntity>;
  ContractInfo?: Maybe<ContractInfoEntity>;
  Document?: Maybe<Scalars['String']>;
  ContractDocType?: Maybe<Scalars['String']>;
  IsContractOverdue?: Maybe<Scalars['Boolean']>;
  IsContractEnded?: Maybe<Scalars['Boolean']>;
};

export type ContractInfoEntity = InterfaceBase & {
  __typename?: 'ContractInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContractInfoID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  ContractDocType?: Maybe<ContractDocType>;
  ContractDocTypeID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  EndRemark?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  ContractDocumentType?: Maybe<PersonnelParameterEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ContractInfoDocument?: Maybe<DocumentsEntity>;
};

export type ContractInfoInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelID?: Maybe<Scalars['String']>;
  ContractDocType?: Maybe<ContractDocType>;
  ContractDocTypeID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentInput?: Maybe<DocumentsInput>;
  Remark?: Maybe<Scalars['String']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  EndRemark?: Maybe<Scalars['String']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CostAllocationEntity = InterfaceBase & {
  __typename?: 'CostAllocationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CostAllocationID: Scalars['String'];
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrainingAttendeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  AllocateAmount?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type CostAllocationInput = {
  CostAllocationID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrainingAttendeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  AllocateAmount?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CostCenterMappingEntity = InterfaceBase & {
  __typename?: 'CostCenterMappingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type CostCenterMappingInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export enum Country {
  Algeria = 'Algeria',
  AmericanSamoa = 'American_Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  AntiguaAndBarbuda = 'Antigua_and_Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bolivia = 'Bolivia',
  BosniaHerzegovina = 'Bosnia_Herzegovina',
  Brazil = 'Brazil',
  BritishVirginIslands = 'British_Virgin_Islands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina_Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'Cape_Verde',
  CaymanIslands = 'Cayman_Islands',
  CentralAfricanRepublic = 'Central_African_Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CookIslands = 'Cook_Islands',
  CostaRica = 'Costa_Rica',
  CoteDIvoire = 'Cote_d_Ivoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech_Republic',
  Djibouti = 'Djibouti',
  DominicaCommonwealth = 'Dominica_Commonwealth',
  DominicanRepublic = 'Dominican_Republic',
  Ecuador = 'Ecuador',
  ElSalvador = 'El_Salvador',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FaeroeIsland = 'Faeroe_Island',
  FalklandIslands = 'Falkland_Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French_Guiana',
  FrenchPolynesia = 'French_Polynesia',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadaloupe = 'Guadaloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea_Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  NorthKorea = 'North_Korea',
  SouthKorea = 'South_Korea',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macau = 'Macau',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall_Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NetherlandsAntilles = 'Netherlands_Antilles',
  NewCaledonia = 'New_Caledonia',
  NewZealand = 'New_Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  NiueIsland = 'Niue_Island',
  NorfolkIsland = 'Norfolk_Island',
  NorthernMarianaIslands = 'Northern_Mariana_Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua_New_Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto_Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  Samoa = 'Samoa',
  SanMarino = 'San_Marino',
  SaoTomeAndPrincipe = 'Sao_Tome_and_Principe',
  SaudiArabia = 'Saudi_Arabia',
  Senegal = 'Senegal',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra_Leone',
  Singapore = 'Singapore',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon_Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South_Africa',
  Spain = 'Spain',
  SriLanka = 'Sri_Lanka',
  StHelena = 'St_Helena',
  StKittsAndNevis = 'St_Kitts_and_Nevis',
  StLucia = 'St_Lucia',
  StPierreAndMiquelon = 'St_Pierre_and_Miquelon',
  StVincentAndTheGrenadines = 'St_Vincent_and_the_Grenadines',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad_and_Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks_and_Caicos_Islands',
  Tuvalu = 'Tuvalu',
  UsVirginIslands = 'US_Virgin_Islands',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United_Arab_Emirates',
  UnitedKingdom = 'United_Kingdom',
  UnitedStatesOfAmerica = 'United_States_of_America',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  WallisAndFutuna = 'Wallis_and_Futuna',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe'
}

export type CountryEntity = InterfaceBase & {
  __typename?: 'CountryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CountryID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  CountryCode?: Maybe<Scalars['String']>;
  PhoneCode?: Maybe<Scalars['String']>;
};

export type CourseCategoryEntity = NewInterfaceBase1 & {
  __typename?: 'CourseCategoryEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  in_use?: Maybe<Scalars['Boolean']>;
  TrainingCourseLibrary?: Maybe<Array<TrainingCourseLibraryEntity>>;
};

export type CourseCategoryInput = {
  name?: Maybe<Scalars['String']>;
  course_category_id?: Maybe<Scalars['String']>;
};

export type CourseClassificationEntity = InterfaceBase & {
  __typename?: 'CourseClassificationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  TrainingCourses?: Maybe<Array<TrainingCourseEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type CourseClassificationInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CourseEvalEntity = InterfaceBase & {
  __typename?: 'CourseEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CourseEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type CourseEvalInput = {
  CourseEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CourseFulfilmentOutput = {
  __typename?: 'CourseFulfilmentOutput';
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  Employee?: Maybe<Array<EmployeeFulfilmentOutput>>;
  OS_Fulfillment?: Maybe<Scalars['Float']>;
};

export type CourseProviderOutput = {
  __typename?: 'CourseProviderOutput';
  CourseID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  providers?: Maybe<Array<AuthorisedTrainingProviderEntity>>;
};

export enum CourseType {
  Hour = 'Hour',
  Day = 'Day'
}

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type CriteriaListInput = {
  TypeID: Scalars['String'];
  operatorValue: Scalars['String'];
  valueVal: Array<Scalars['String']>;
  valueInOut: Scalars['String'];
  checkValue: Array<Scalars['String']>;
};

export enum CrossDayType {
  No = 'NO',
  Yes = 'YES',
  ShiftStartTime = 'SHIFT_START_TIME',
  ShiftFirstHalfEndTime = 'SHIFT_FIRST_HALF_END_TIME',
  ShiftSecondHalfStartTime = 'SHIFT_SECOND_HALF_START_TIME',
  ShiftEndTime = 'SHIFT_END_TIME',
  ClockOutTime = 'CLOCK_OUT_TIME'
}

export enum CurrencyEffectiveMethod {
  ByReceiptDate = 'By_Receipt_Date',
  BySubmissionDate = 'By_Submission_Date'
}

export type DmImported = {
  __typename?: 'DMImported';
  MigratedData_Bank?: Maybe<Scalars['Float']>;
  MigratedData_Company?: Maybe<Scalars['Float']>;
  MigratedData_DepartmentDivision?: Maybe<Scalars['Float']>;
  MigratedData_Employee?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeDependent?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeJobInfo?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeSalary?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeStatutoryInfo?: Maybe<Scalars['Float']>;
  MigratedData_FixedAllwDed?: Maybe<Scalars['Float']>;
  MigratedData_JobDesignation?: Maybe<Scalars['Float']>;
  MigratedData_JobGrade?: Maybe<Scalars['Float']>;
  MigratedData_PayItem?: Maybe<Scalars['Float']>;
  MigratedData_PayrollCutOff?: Maybe<Scalars['Float']>;
  MigratedData_PayrollCycle?: Maybe<Scalars['Float']>;
  MigratedData_PayrollHistory?: Maybe<Scalars['Float']>;
  MigratedData_RecruitmentSource?: Maybe<Scalars['Float']>;
};

export type DailyAllowanceInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type DailyAllowancePolicyEntity = InterfaceBase & {
  __typename?: 'DailyAllowancePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DailyAllowanceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Condition: Scalars['String'];
  Value: Scalars['String'];
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
};

export type DailyTemplateEntity = InterfaceBase & {
  __typename?: 'DailyTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DailyTemplateID: Scalars['String'];
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type DailyTemplateInput = {
  DailyTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DashboardCardSetting = {
  isExpand: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  DashboardCardType: DashboardCardType;
  Sequence?: Maybe<Scalars['Float']>;
};

export enum DashboardCardType {
  Announcements = 'Announcements',
  AttendanceToday = 'Attendance_Today',
  AttendanceSummary = 'Attendance_Summary',
  Birthday = 'Birthday',
  ConfirmationAdmin = 'Confirmation_Admin',
  ConfirmationManager = 'Confirmation_Manager',
  LeaveAdmin = 'Leave_Admin',
  LeaveManager = 'Leave_Manager',
  LeaveSummary = 'Leave_Summary',
  OffboardingAdmin = 'Offboarding_Admin',
  OffboardingManager = 'Offboarding_Manager'
}

export type DataChangesDetails = {
  __typename?: 'DataChangesDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ChangesData?: Maybe<ChangesData>;
  OldData?: Maybe<ChangesData>;
  DataChangesID: Scalars['String'];
  Action: Scalars['String'];
  Type: Scalars['String'];
};

export type DataChangesEntity = {
  __typename?: 'DataChangesEntity';
  DataChangesID: Scalars['String'];
  PersonalID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  ChangesData?: Maybe<ChangesData>;
  OldData?: Maybe<ChangesData>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  DataChangeHistory?: Maybe<Array<DataChangesEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  Approver?: Maybe<EmployeeEntity>;
  Contact?: Maybe<ContactEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type DataChangesInput = {
  DataChangesID?: Maybe<Scalars['String']>;
  PersonalID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  ChangesData?: Maybe<ChangesDataInput>;
  OldData?: Maybe<ChangesDataInput>;
  Status?: Maybe<Status>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
};

export enum DataUpdateStatus {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Approved = 'Approved'
}

export type DataUpdateStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<DataUpdateStatus>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};


export enum DayType {
  NormalDay = 'NORMAL_DAY',
  OffDay = 'OFF_DAY',
  RestDay = 'REST_DAY',
  PublicHoliday = 'PUBLIC_HOLIDAY'
}

export type DeductionPolicyEntity = InterfaceBase & {
  __typename?: 'DeductionPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DeductionPolicyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Eligibility: Scalars['String'];
  Value: Scalars['String'];
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type DeductionPolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DeductionPolicyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Eligibility?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type DeductionTemplateEntity = InterfaceBase & {
  __typename?: 'DeductionTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DeductionTemplateID: Scalars['String'];
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type DeductionTemplateInput = {
  DeductionTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DelegationEntity = InterfaceBase & {
  __typename?: 'DelegationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DelegationID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  TargetID?: Maybe<Scalars['String']>;
  TargetContactID?: Maybe<Scalars['String']>;
  SubmitterEmployee?: Maybe<EmployeeEntity>;
  TargetEmployee?: Maybe<EmployeeEntity>;
  TargetEmployeeContact?: Maybe<ContactEntity>;
};

export type DelegationInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  EmployeeID?: Maybe<Scalars['String']>;
  DelegationID?: Maybe<Scalars['String']>;
  TargetID?: Maybe<Scalars['String']>;
  TargetContactID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
};

export type DepartmentEntity = InterfaceBase & {
  __typename?: 'DepartmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DepartmentType: DepartmentType;
  Company: CompanyEntity;
};

export type DepartmentInput = {
  ParentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DepartmentType: DepartmentType;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum DepartmentType {
  Department = 'Department',
  Division = 'Division'
}

export type DepartmentUpdateInput = {
  DepartmentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum DietaryPreference {
  CellacDisease = 'Cellac_Disease',
  Diabetic = 'Diabetic',
  Halal = 'Halal',
  Kosher = 'Kosher',
  LactoseIntolerance = 'Lactose_Intolerance',
  PeanutAllergies = 'Peanut_Allergies',
  VegetarianAndVegans = 'Vegetarian_And_Vegans'
}

export type DisplayReportTypeSequenceOutput = {
  __typename?: 'DisplayReportTypeSequenceOutput';
  CP22aReportSeq?: Maybe<Scalars['Float']>;
  CP21ReportSeq?: Maybe<Scalars['Float']>;
  EAFormReportSeq?: Maybe<Scalars['Float']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  EAFormSetupID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
};

export type DocumentGrouping = {
  __typename?: 'DocumentGrouping';
  DocumentType: DocumentType;
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
};

export type DocumentNumberAssignmentEntity = InterfaceBase & {
  __typename?: 'DocumentNumberAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID: Scalars['String'];
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  RefTableName?: Maybe<Scalars['String']>;
  ProgramRef?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type DocumentNumberAssignmentInput = {
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  RefTableName?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Scalars['String']>;
  ProgramRef?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type DocumentNumberDetailEntity = InterfaceBase & {
  __typename?: 'DocumentNumberDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberDetailID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  DocNumHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export type DocumentNumberDetailInput = {
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type DocumentNumberHeaderEntity = InterfaceBase & {
  __typename?: 'DocumentNumberHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  HasNextNumber?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
  IsStandard?: Maybe<Scalars['Boolean']>;
  DocNumDetail?: Maybe<Array<DocumentNumberDetailEntity>>;
};

export type DocumentNumberHeaderInput = {
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
};

export type DocumentNumberingInput = {
  DocumentNumberingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  StartFrom?: Maybe<Scalars['Float']>;
  Format?: Maybe<Array<DocumentNumberDetailInput>>;
  RefTableName?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Array<Scalars['String']>>;
  ProgramRef?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
};

export type DocumentProfilePictureOutput = {
  __typename?: 'DocumentProfilePictureOutput';
  Employee?: Maybe<EmployeeEntity>;
};

export enum DocumentType {
  Payslip = 'Payslip',
  EaForm = 'EA_FORM',
  TaxRelief = 'Tax_Relief',
  GeneralDoc = 'General_Doc',
  PersonalDoc = 'Personal_Doc',
  ContractDoc = 'Contract_Doc',
  Claim = 'Claim',
  Leave = 'Leave',
  Employee = 'Employee',
  News = 'News',
  Requisition = 'Requisition',
  Company = 'Company',
  ReportTemplate = 'ReportTemplate',
  Appraisal = 'Appraisal',
  Tms = 'TMS',
  ProjectExpense = 'ProjectExpense',
  DataChanges = 'DataChanges',
  AttendanceRecord = 'AttendanceRecord',
  EmployeeSalary = 'EmployeeSalary',
  MonthlyTransaction = 'MonthlyTransaction',
  FixedAllowanceDeduction = 'FixedAllowanceDeduction',
  Helpdesk = 'Helpdesk',
  Training = 'Training',
  PayrollProcess = 'Payroll_Process',
  Overtime = 'Overtime',
  Recruitment = 'Recruitment'
}

export type DocumentsEntity = InterfaceBase & {
  __typename?: 'DocumentsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentID: Scalars['String'];
  DocumentType?: Maybe<DocumentType>;
  Description?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  IsPrivate?: Maybe<Scalars['Boolean']>;
  DocumentName?: Maybe<Scalars['String']>;
  FolderID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  Folder?: Maybe<FolderEntity>;
};

export type DocumentsInput = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<DocumentType>;
  Description?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  Status?: Maybe<Status>;
  ParentID?: Maybe<Scalars['String']>;
  IsPrivate?: Maybe<Scalars['Boolean']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FolderID?: Maybe<Scalars['String']>;
};

export type DutyRosterEntity = InterfaceBase & {
  __typename?: 'DutyRosterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DutyRosterID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Date: Scalars['String'];
  Color?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  shift?: Maybe<ShiftEntity>;
  ShiftID?: Maybe<Scalars['String']>;
  PrevShiftID?: Maybe<Scalars['String']>;
  PrevShiftDayID?: Maybe<Scalars['String']>;
  shiftDay?: Maybe<ShiftDayEntity>;
  ShiftDayID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ShiftPattern?: Maybe<ShiftPatternEntity>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};

export type DutyRosterInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  DutyRosterID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  PrevShiftID?: Maybe<Scalars['String']>;
  PrevShiftDayID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};

export type EaFormSetupEntity = InterfaceBase & {
  __typename?: 'EAFormSetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EAFormSetupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  IsShow?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
};

export type EaFormSetupInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  IsShow?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EaPartFCategoryEntity = {
  __typename?: 'EAPartFCategoryEntity';
  EAPartFCategoryID: Scalars['String'];
  Description: Scalars['String'];
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
};

export type EaPartFEntity = InterfaceBase & {
  __typename?: 'EAPartFEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EAPartFID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  LimitAmt?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  EAPartFCategoryID?: Maybe<Scalars['String']>;
};

export type EaPartFInput = {
  EAPartFCategoryID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LimitAmt?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EApplicationSummaryOutput = {
  __typename?: 'EApplicationSummaryOutput';
  EApplicationList: Array<Rec_EApplicationFormSetupEntity>;
  SummaryData?: Maybe<Scalars['JSON']>;
};

export type EisCalculationInput = {
  EmployeeID: Scalars['String'];
  Age: Scalars['Float'];
  EISChargableAmount: Scalars['Float'];
  CurrentPeriod?: Maybe<Scalars['String']>;
};

export type EisCalculationOutput = {
  __typename?: 'EISCalculationOutput';
  EmployeeID: Scalars['String'];
  EISAmount: Scalars['Float'];
  EISRequirement?: Maybe<Scalars['String']>;
  EmployeeEISAmount?: Maybe<Scalars['Float']>;
  EmployerEISAmount?: Maybe<Scalars['Float']>;
  FromAmount?: Maybe<Scalars['Float']>;
  ToAmount?: Maybe<Scalars['Float']>;
};

export type EisPolicyEntity = InterfaceBase & {
  __typename?: 'EISPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EISPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  AgeFrom?: Maybe<Scalars['Float']>;
  AgeTo?: Maybe<Scalars['Float']>;
  Formula: Scalars['String'];
  Remarks: Scalars['String'];
};

export type EisPolicyInput = {
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<NationalityType>;
  AgeFrom?: Maybe<Scalars['Float']>;
  AgeTo?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EisRefEntity = InterfaceBase & {
  __typename?: 'EISRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EISRefID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  CmpAmount: Scalars['Float'];
  EmpAmount: Scalars['Float'];
};

export type EisRefInput = {
  EISRefID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  CmpAmount?: Maybe<Scalars['Float']>;
  EmpAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EpfCalculationInput = {
  EmployeeID: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  Age: Scalars['Float'];
  EPFChargableAmount: Scalars['Float'];
  CurrentMonthEPFBonusChargableAmount?: Maybe<Scalars['Float']>;
  EPFNormalRemunerationChargableAmount?: Maybe<Scalars['Float']>;
  AccumulatedEPFBonusChargableAmount?: Maybe<Scalars['Float']>;
};

export type EpfCalculationInputV2 = {
  EmployeeID: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  Age: Scalars['Float'];
  BasicSalary: Scalars['Float'];
  CurrentPeriod?: Maybe<Scalars['String']>;
  NormalRemuneration: Scalars['Float'];
  AdditionalRemuneration?: Maybe<Scalars['Float']>;
  EPFEmployeeToMinus?: Maybe<Scalars['Float']>;
  ForcastedRemuneration?: Maybe<Scalars['Float']>;
  MonthlyWageRemuneration?: Maybe<Scalars['Float']>;
  EPFChargableAmount: Scalars['Float'];
  EmployerEPFVERate?: Maybe<Scalars['Float']>;
  EmployeeEPFVERate?: Maybe<Scalars['Float']>;
};

export type EpfCalculationOutput = {
  __typename?: 'EPFCalculationOutput';
  EmployeeID: Scalars['String'];
  EPFAmount: Scalars['Float'];
  EPFRoundingAmount: Scalars['Float'];
  EmployeeEPFAmount?: Maybe<Scalars['Float']>;
  EmployerEPFAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFAmount?: Maybe<Scalars['Float']>;
  EmployeeEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployerEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployeeEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  EmployerVERate: Scalars['Float'];
  EmployeeVERate: Scalars['Float'];
  TotalEmployerRate: Scalars['Float'];
  TotalEmployeeRate: Scalars['Float'];
  EmpUserDefineEPF?: Maybe<Scalars['JSON']>;
};

export type EpfPolicyDetailEntity = InterfaceBase & {
  __typename?: 'EPFPolicyDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFPolicyDetailID: Scalars['String'];
  EPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  EffectiveMonth?: Maybe<Scalars['String']>;
  EPFPolicy?: Maybe<EpfPolicyEntity>;
};

export type EpfPolicyDetailInput = {
  EPFPolicyDetailID?: Maybe<Scalars['String']>;
  EPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  EffectiveMonth?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EpfPolicyEntity = InterfaceBase & {
  __typename?: 'EPFPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType: Scalars['String'];
  Age: Scalars['Float'];
  EPFChargableAmount: Scalars['Float'];
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  Formula: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  EPFPolicyDetails?: Maybe<Array<EpfPolicyDetailEntity>>;
};

export type EpfPolicyInput = {
  EPFPolicyID?: Maybe<Scalars['String']>;
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<NationalityType>;
  Age?: Maybe<Scalars['Float']>;
  EPFChargableAmount?: Maybe<Scalars['Float']>;
  EmployerRate?: Maybe<Scalars['Float']>;
  EmployeeRate?: Maybe<Scalars['Float']>;
  Formula?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EpfRateCalculationOutput = {
  __typename?: 'EPFRateCalculationOutput';
  EmployeeRate?: Maybe<Scalars['String']>;
  EmployerRate?: Maybe<Scalars['String']>;
  EmployeeRateType?: Maybe<Scalars['String']>;
  EmployerRateType?: Maybe<Scalars['String']>;
};

export type EarnLeaveEntity = InterfaceBase & {
  __typename?: 'EarnLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EarnLeaveID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
};

export enum Education {
  No = 'No',
  Under_18YearsOld = 'Under_18_years_old',
  CertificateMatriculation = 'CertificateMatriculation',
  DiplomaDegree = 'DiplomaDegree'
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  Subject?: Maybe<Scalars['String']>;
  Body?: Maybe<Scalars['String']>;
};

export type EmailTemplateInput = {
  Subject?: Maybe<Scalars['String']>;
  Body?: Maybe<Scalars['String']>;
};

export type EmmployeeListingInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export type EmpCalendarLeaveListing = {
  CompanyID?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
};

export type EmpClaimBalance = {
  __typename?: 'EmpClaimBalance';
  ClaimItems?: Maybe<ClaimItemEntity>;
  Drafted?: Maybe<Scalars['Float']>;
  PendingSuperior?: Maybe<Scalars['Float']>;
  PendingFinance?: Maybe<Scalars['Float']>;
  PendingPayroll?: Maybe<Scalars['Float']>;
  Posted?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  OccurenceBalance?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['String']>;
};

export type EmpClaimBalanceOutput = {
  __typename?: 'EmpClaimBalanceOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimBalance?: Maybe<Array<EmpClaimBalance>>;
};

export type EmpClaimEntitlementByClaimTypeOutput = {
  __typename?: 'EmpClaimEntitlementByClaimTypeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Summary?: Maybe<EntitlementSummary>;
};

export type EmpClaimEntitlementByEmployeeOutput = {
  __typename?: 'EmpClaimEntitlementByEmployeeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Summarries?: Maybe<Array<EntitlementSummary>>;
};

export type EmpClaimEntitlementInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate?: Maybe<Scalars['DateTime']>;
};

export type EmpClaimEntitlementOutput = {
  __typename?: 'EmpClaimEntitlementOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimItems?: Maybe<Array<ClaimItemEntity>>;
};

export type EmpClaimPoolBalance = {
  __typename?: 'EmpClaimPoolBalance';
  ClaimPool?: Maybe<ClaimPoolEntity>;
  ClaimPoolCondition?: Maybe<ClaimPoolConditionEntity>;
  Drafted?: Maybe<Scalars['Float']>;
  PendingSuperior?: Maybe<Scalars['Float']>;
  PendingFinance?: Maybe<Scalars['Float']>;
  PendingPayroll?: Maybe<Scalars['Float']>;
  Posted?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
  Frequency?: Maybe<Scalars['String']>;
};

export type EmpClaimPoolBalanceOutput = {
  __typename?: 'EmpClaimPoolBalanceOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimPoolBalance?: Maybe<Array<EmpClaimPoolBalance>>;
};

export type EmpClaimTypeValidationOutput = {
  __typename?: 'EmpClaimTypeValidationOutput';
  ClaimTypeID?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Array<Scalars['String']>>;
};

export type EmpLeaveApplicationInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
  SaveAsType: ApprovalStatus;
};

export type EmpLeaveBalanceInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate?: Maybe<Scalars['DateTime']>;
};

export type EmpLeaveBalanceOuput = {
  __typename?: 'EmpLeaveBalanceOuput';
  Employee?: Maybe<EmployeeEntity>;
  LeaveTypeBalance?: Maybe<Array<LeaveTypeBalance>>;
};

export type EmpLeaveDetailInput = {
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeavePolicyID?: Maybe<Scalars['String']>;
};

export type EmpLeaveDetailOuput = {
  __typename?: 'EmpLeaveDetailOuput';
  LeaveItems?: Maybe<Array<LeaveItemEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveHeader?: Maybe<LeaveHeaderEntity>;
};

export type EmpLeaveEntitlementOuput = {
  __typename?: 'EmpLeaveEntitlementOuput';
  Employee?: Maybe<EmployeeEntity>;
  LOSByMonth?: Maybe<Scalars['Float']>;
  LOSByDay?: Maybe<Scalars['Float']>;
  EmpLeaveItems?: Maybe<Array<EmpLeaveItemEntitlement>>;
};

export type EmpLeaveItemEntitlement = {
  __typename?: 'EmpLeaveItemEntitlement';
  LeaveItem?: Maybe<LeaveItemEntity>;
  LeaveBenefitRules?: Maybe<Array<LeaveBenefitRuleEntity>>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
};

export type EmpLeaveListingInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatusList?: Maybe<Array<ApprovalStatus>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
};

export type EmpLeaveListingOutput = {
  __typename?: 'EmpLeaveListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveItemName?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type EmpLeaveTypeValidationOutput = {
  __typename?: 'EmpLeaveTypeValidationOutput';
  LeaveTypeID?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Array<Scalars['String']>>;
};

export type EmpProjectExpenseTypeValidationOutput = {
  __typename?: 'EmpProjectExpenseTypeValidationOutput';
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Array<Scalars['String']>>;
};

export type EmpRequisitionInput = {
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
};

export type EmpSalary = {
  __typename?: 'EmpSalary';
  EmployeeID?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  StandardWages?: Maybe<Scalars['Float']>;
  PreStandardWages?: Maybe<Scalars['Float']>;
  EffectiveDate: Scalars['DateTime'];
  SalaryType?: Maybe<Scalars['String']>;
};

export type EmpWorkTime = {
  SysID: Scalars['String'];
  EmployeeNo: Scalars['String'];
  StartDateTime: Scalars['DateTime'];
  EndDateTime: Scalars['DateTime'];
  PunchInDateTime: Scalars['String'];
  PunchOutDateTime: Scalars['String'];
  CalStartDateTime: Scalars['String'];
  CalEndDateTime: Scalars['String'];
  ProductivityMins: Scalars['Float'];
  OvertimeMins: Scalars['Float'];
  ErrorDay: Scalars['String'];
  ErrorDate: Scalars['String'];
  TranStatus: Scalars['String'];
};

export type EmployeeAppLoginResponse = {
  __typename?: 'EmployeeAppLoginResponse';
  accessToken?: Maybe<Scalars['String']>;
  employee?: Maybe<EmployeeResponse>;
};

export type EmployeeAppSecurityAssignmentEntity = {
  __typename?: 'EmployeeAppSecurityAssignmentEntity';
  EmployeeAppSecurityAssignmentID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeAppSecurityID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Array<Scalars['JSON']>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppSecurity?: Maybe<EmployeeAppSecurityEntity>;
};

export type EmployeeAppSecurityAssignmentInput = {
  EmployeeAppSecurityAssignmentID?: Maybe<Scalars['String']>;
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Array<Scalars['JSON']>>;
  Deletion?: Maybe<Scalars['Boolean']>;
};

export type EmployeeAppSecurityEntity = {
  __typename?: 'EmployeeAppSecurityEntity';
  EmployeeAppSecurityID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppSecurityPermission?: Maybe<Array<EmployeeAppSecurityPermissionEntity>>;
};

export type EmployeeAppSecurityInput = {
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Deletion?: Maybe<Scalars['Boolean']>;
};

export type EmployeeAppSecurityOutput = {
  __typename?: 'EmployeeAppSecurityOutput';
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  PermissionList?: Maybe<Array<EmployeeAppSecurityPermissionOutput>>;
  InUse: Scalars['Boolean'];
};

export type EmployeeAppSecurityPermissionEntity = {
  __typename?: 'EmployeeAppSecurityPermissionEntity';
  EmployeeAppSecurityPermissionID: Scalars['String'];
  EmployeeAppSecurityID: Scalars['String'];
  PermissionID: Scalars['String'];
  EmployeeAppSecurity?: Maybe<EmployeeAppSecurityEntity>;
};

export type EmployeeAppSecurityPermissionOutput = {
  __typename?: 'EmployeeAppSecurityPermissionOutput';
  EmployeeAppSecurityPermissionID?: Maybe<Scalars['String']>;
  PermissionID?: Maybe<Scalars['Float']>;
  PermissionName?: Maybe<Scalars['String']>;
};

export type EmployeeAppraisalDetailEntity = InterfaceBase & {
  __typename?: 'EmployeeAppraisalDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppraisalDetailID: Scalars['String'];
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Rating?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeader?: Maybe<EmployeeAppraisalHeaderEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type EmployeeAppraisalDetailInput = {
  EmployeeAppraisalDetailID?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Rating?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeAppraisalEntity = InterfaceBase & {
  __typename?: 'EmployeeAppraisalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppraisalID: Scalars['String'];
  EmployeeID: Scalars['String'];
  AppraisalRecord: Scalars['String'];
  Date: Scalars['DateTime'];
  Rating?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  AppraiseeRemark: Scalars['String'];
  AppraiserRemark: Scalars['String'];
  AppraiserName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type EmployeeAppraisalHeaderEntity = InterfaceBase & {
  __typename?: 'EmployeeAppraisalHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppraisalHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraiseeType?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  KPIPlanningID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  CompetancyTemplateID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  AppraiserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Array<Scalars['JSON']>>;
  Signature?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  EmployeeAppraisalDetails?: Maybe<Array<EmployeeAppraisalDetailEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  SignatureDocument?: Maybe<DocumentsEntity>;
  FinalRating?: Maybe<Scalars['Float']>;
  AttachmentList?: Maybe<Array<DocumentsEntity>>;
  GetSignatureDocument?: Maybe<DocumentsEntity>;
};

export type EmployeeAppraisalHeaderInput = {
  EmployeeAppraisalHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraiseeType?: Maybe<AppraiseeType>;
  EmployeeID?: Maybe<Scalars['String']>;
  AppraiserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  KPIPlanningID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  OthersTempleteID?: Maybe<Scalars['String']>;
  CompetancyTemplateID?: Maybe<Scalars['String']>;
  AppraisalScheduleHeaderID?: Maybe<Scalars['String']>;
  KPIWeightage?: Maybe<Scalars['Float']>;
  CompetencyWeightage?: Maybe<Scalars['Float']>;
  OthersWeightage?: Maybe<Scalars['Float']>;
  SelfAssessmentWeightage?: Maybe<Scalars['Float']>;
  SuperiorWeightage?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  SignatureInput?: Maybe<DocumentsInput>;
  SignatureToRemove?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<EmployeeAppraisalDetailInput>>;
};

export type EmployeeAppraisalInput = {
  EmployeeID: Scalars['String'];
  AppraisalRecord: Scalars['String'];
  Date: Scalars['DateTime'];
  Rating?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  AppraiseeRemark: Scalars['String'];
  AppraiserRemark: Scalars['String'];
  AppraiserName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeAssetEntity = InterfaceBase & {
  __typename?: 'EmployeeAssetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAssetID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  DateAssign?: Maybe<Scalars['DateTime']>;
  Item?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeAssetInput = {
  EmployeeAssetID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  DateAssign?: Maybe<Scalars['DateTime']>;
  Item?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeAttendanceOutput = {
  __typename?: 'EmployeeAttendanceOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CourseRecord?: Maybe<Array<EmployeeCourseRecordAttendanceOutput>>;
  FinalTotalAttendanceHour?: Maybe<Scalars['Float']>;
};

export type EmployeeBankInfoEntity = InterfaceBase & {
  __typename?: 'EmployeeBankInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeBankInfoID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Employee?: Maybe<EmployeeEntity>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  BTCountryCode?: Maybe<Scalars['String']>;
};

export type EmployeeBankInfoInput = {
  EmployeeBankInfoID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  BTCountryCode?: Maybe<Scalars['String']>;
};

export type EmployeeBenefitInKindEntity = InterfaceBase & {
  __typename?: 'EmployeeBenefitInKindEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeBenefitInKindID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PaymentDetails?: Maybe<Scalars['String']>;
  GratuityFromDate?: Maybe<Scalars['DateTime']>;
  GratuityToDate?: Maybe<Scalars['DateTime']>;
  IncomeTypeA?: Maybe<Scalars['String']>;
  IncomeTypeB?: Maybe<Scalars['String']>;
  IncomeAmount?: Maybe<Scalars['Float']>;
  BIKDescription?: Maybe<Scalars['String']>;
  MotorcarDate?: Maybe<Scalars['DateTime']>;
  MotorcarYear?: Maybe<Scalars['Float']>;
  MotorcarType?: Maybe<Scalars['String']>;
  MotorcarModel?: Maybe<Scalars['String']>;
  MotorcarPetrol?: Maybe<Scalars['Float']>;
  MotorcarDriverProviderAmount?: Maybe<Scalars['Float']>;
  HouseholdAmount?: Maybe<Scalars['Float']>;
  TelephoneAmount?: Maybe<Scalars['Float']>;
  RecreationalAmount?: Maybe<Scalars['Float']>;
  GardenerAmount?: Maybe<Scalars['Float']>;
  HouseholdServantAmount?: Maybe<Scalars['Float']>;
  ValueOfLivingAddresss?: Maybe<Scalars['String']>;
  ValueOfLivingAmount?: Maybe<Scalars['Float']>;
  RefundUnapprovedFunds?: Maybe<Scalars['Float']>;
  CompensationEmployment?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  GratuityAmount?: Maybe<Scalars['Float']>;
  PaymentAmount?: Maybe<Scalars['Float']>;
  FeeDescription?: Maybe<Scalars['String']>;
  FeeAmount?: Maybe<Scalars['Float']>;
  ESOSDescription?: Maybe<Scalars['String']>;
  ESOSAmount?: Maybe<Scalars['Float']>;
  TaxDescription?: Maybe<Scalars['String']>;
  TaxAmount?: Maybe<Scalars['Float']>;
};

export type EmployeeBenefitInKindInput = {
  EmployeeBenefitInKindID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PaymentDetails?: Maybe<Scalars['String']>;
  GratuityFromDate?: Maybe<Scalars['DateTime']>;
  GratuityToDate?: Maybe<Scalars['DateTime']>;
  IncomeTypeA?: Maybe<Scalars['String']>;
  IncomeTypeB?: Maybe<Scalars['String']>;
  IncomeAmount?: Maybe<Scalars['Float']>;
  BIKDescription?: Maybe<Scalars['String']>;
  MotorcarDate?: Maybe<Scalars['DateTime']>;
  MotorcarYear?: Maybe<Scalars['Float']>;
  MotorcarType?: Maybe<Scalars['String']>;
  MotorcarModel?: Maybe<Scalars['String']>;
  MotorcarPetrol?: Maybe<Scalars['Float']>;
  MotorcarDriverProviderAmount?: Maybe<Scalars['Float']>;
  HouseholdAmount?: Maybe<Scalars['Float']>;
  TelephoneAmount?: Maybe<Scalars['Float']>;
  RecreationalAmount?: Maybe<Scalars['Float']>;
  GardenerAmount?: Maybe<Scalars['Float']>;
  HouseholdServantAmount?: Maybe<Scalars['Float']>;
  ValueOfLivingAddresss?: Maybe<Scalars['String']>;
  ValueOfLivingAmount?: Maybe<Scalars['Float']>;
  RefundUnapprovedFunds?: Maybe<Scalars['Float']>;
  CompensationEmployment?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GratuityAmount?: Maybe<Scalars['Float']>;
  PaymentAmount?: Maybe<Scalars['Float']>;
  FeeDescription?: Maybe<Scalars['String']>;
  FeeAmount?: Maybe<Scalars['Float']>;
  ESOSDescription?: Maybe<Scalars['String']>;
  ESOSAmount?: Maybe<Scalars['Float']>;
  TaxDescription?: Maybe<Scalars['String']>;
  TaxAmount?: Maybe<Scalars['Float']>;
};

export type EmployeeBirthdaysOutput = {
  __typename?: 'EmployeeBirthdaysOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type EmployeeBusinessInsightListing = {
  FilterType?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type EmployeeCp22Entity = InterfaceBase & {
  __typename?: 'EmployeeCP22Entity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeCP22ID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LHDNReportID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  FormStatus?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LHDNReport?: Maybe<LhdnReportEntity>;
};

export type EmployeeCp22Input = {
  EmployeeCP22ID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  FormStatus?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeCp22Output = {
  __typename?: 'EmployeeCP22Output';
  EmployeeCP22ID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReportSequence?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type EmployeeCalendarOutput = {
  __typename?: 'EmployeeCalendarOutput';
  companyOffDays?: Maybe<Array<CompanyOffDayOutput>>;
  employeeBirthdays?: Maybe<Array<EmployeeBirthdaysOutput>>;
  employeeLeaves?: Maybe<Array<EmployeeLeavesOutput>>;
  employeeStaffLeaves?: Maybe<Array<EmployeeLeavesOutput>>;
};

export type EmployeeCheckIn = {
  EmployeeNo: Scalars['String'];
  ClockDateTime: Scalars['DateTime'];
  ClockType: Scalars['String'];
  ClockRemark: Scalars['String'];
};

export type EmployeeConditionsDetails = {
  __typename?: 'EmployeeConditionsDetails';
  Name?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<ConditionObject>;
  JobPosition?: Maybe<ConditionObject>;
  EmployeeType?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<ConditionObject>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  Gender?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<ConditionObject>;
  ShiftGroup?: Maybe<ConditionObjectSpecialCase>;
  DateJoined?: Maybe<Scalars['DateTime']>;
  ConfirmedDueDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Policies?: Maybe<Array<PolicyOutput>>;
  EmployeeCareerLogs?: Maybe<Array<Scalars['JSON']>>;
  Age?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
};

export type EmployeeCourseRecordAttendanceOutput = {
  __typename?: 'EmployeeCourseRecordAttendanceOutput';
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  TotalAttendaceHour?: Maybe<Scalars['Float']>;
};

export type EmployeeDependentsEntity = InterfaceBase & {
  __typename?: 'EmployeeDependentsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeDependentsID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Contact?: Maybe<ContactEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  PersonnelID?: Maybe<Scalars['String']>;
};

export type EmployeeDependentsInput = {
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactInput>;
  PersonnelID?: Maybe<Scalars['String']>;
};

export type EmployeeDetailsInput = {
  Date: Scalars['DateTime'];
  Company: Scalars['String'];
  JoinedDate: Scalars['DateTime'];
  ConfirmedDate: Scalars['DateTime'];
  JobPosition: Scalars['String'];
  JobGrade: Scalars['String'];
  EmploymentType: Scalars['String'];
  OrgUnit: Scalars['String'];
  ShiftGroupID?: Maybe<Scalars['String']>;
};

export type EmployeeDiaryEntity = InterfaceBase & {
  __typename?: 'EmployeeDiaryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeDiaryID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  CaseNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Countermeasure?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
  Private?: Maybe<Scalars['Boolean']>;
};

export type EmployeeDiaryInput = {
  EmployeeID: Scalars['String'];
  EmployeeDiaryID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  CaseNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Countermeasure?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsInput>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Private?: Maybe<Scalars['Boolean']>;
};

export type EmployeeEntity = InterfaceBase & {
  __typename?: 'EmployeeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ShopeeEmployeeID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<EmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  AttendanceRecordPaired?: Maybe<Array<AttendanceRecordPairedEntity>>;
  TargetEmployees?: Maybe<Array<DelegationEntity>>;
  SubmitterEmployees?: Maybe<Array<DelegationEntity>>;
  Subordinate?: Maybe<Array<EmployeeEntity>>;
  SubordinateDetail?: Maybe<Array<RequisitionEntity>>;
  EmployeeContract?: Maybe<Array<ContractInfoEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<EmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  KpiExercise?: Maybe<Array<KpiExerciseEntity>>;
  IsInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  IsFromInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  InterCompanyTransferDate?: Maybe<Scalars['DateTime']>;
  HelpdeskTicket?: Maybe<Array<HelpdeskTicketEntity>>;
  ProjectTimesheetApproverAssignment?: Maybe<ProjectTimesheetApproverAssignmentEntity>;
  isDailyEntryFilled?: Maybe<Scalars['Boolean']>;
  BatchOvertimes?: Maybe<Array<BatchOvertimeEntity>>;
  TrainingRequest?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  IsProbationExtended?: Maybe<Scalars['Boolean']>;
  EmployeeCurrentSalary: Scalars['String'];
  DesignationWithTemplete?: Maybe<JobDesignationEntity>;
  EmployeeDepartment?: Maybe<DepartmentEntity>;
  GeneralDocCount?: Maybe<Scalars['Float']>;
  GeneralDocTotalSize?: Maybe<Scalars['Float']>;
  PersonalDocCount: Scalars['Float'];
  PersonalDocTotalSize: Scalars['Float'];
  ContractInfoDocCount: Scalars['Float'];
  ContractInfoDocTotalSize: Scalars['Float'];
  PaySlipDocCount: Scalars['Float'];
  PaySlipDocTotalSize: Scalars['Float'];
  EAFormDocCount: Scalars['Float'];
  EAFormDocTotalSize: Scalars['Float'];
  TotalLeave: Scalars['Float'];
  TotalAnnualLeave: Scalars['Float'];
  TotalSickLeave: Scalars['Float'];
  TotalUnpaidLeave: Scalars['Float'];
  TotalClaim: Scalars['Float'];
  TotalClaimApproved: Scalars['Float'];
  TotalClaimAmountApproved: Scalars['Float'];
  TotalTaxRelief: Scalars['Float'];
  TotalRequisition: Scalars['Float'];
  TotalTravelRequest: Scalars['Float'];
  TotalPurchaseRequisition: Scalars['Float'];
  TotalCashAdvanceRequisition: Scalars['Float'];
  TotalOtherRequisition: Scalars['Float'];
  TotalUnreadNews: Scalars['Float'];
  LastUpdatedDates: LastUpdatedDatesOutput;
  MyTodo: Scalars['Float'];
  MyNotification: Scalars['Float'];
  EmployeeSubordinates: Array<EmployeeEntity>;
  TotalEducation: Scalars['Float'];
  TotalJobHistory: Scalars['Float'];
  TotalCertificate: Scalars['Float'];
  TotalPersonalDocument: Scalars['Float'];
  ShiftGroupEmployeeAssignmentLogList: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  TotalKpiRecord: Scalars['Float'];
  TotalReviewRecord: Scalars['Float'];
  EmpOrgUnitDesc: Scalars['String'];
};

export type EmployeeEvaluationAnswerEntity = NewInterfaceBase1 & {
  __typename?: 'EmployeeEvaluationAnswerEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_employee_evaluation_id: Scalars['String'];
  survey_item_id: Scalars['String'];
  answer: Scalars['String'];
  answer_point?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  ScheduledTrainingEmployeeEvaluation?: Maybe<ScheduledTrainingEmployeeEvaluationEntity>;
};

export type EmployeeEvaluationAnswerInput = {
  employee_evaluation_answer_id?: Maybe<Scalars['String']>;
  survey_item_id?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  answer_point?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
};

export type EmployeeFilterInput = {
  CompanyID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmploymentTypes?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
};

export type EmployeeFilterListingBySubscriptionAccountOutput = {
  __typename?: 'EmployeeFilterListingBySubscriptionAccountOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  EmployeeNoName?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  EmployeeStatutoryID?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<Scalars['String']>;
  ResidentStatus?: Maybe<Scalars['String']>;
  EmployeeStatutoryCreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeStatutoryModifiedDT?: Maybe<Scalars['DateTime']>;
  EmployeeBankInfoID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<Scalars['String']>;
  Bank?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  CareerLogID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  CareerLogLastWorkingDate?: Maybe<Scalars['DateTime']>;
  CareerLogLastEmploymentDate?: Maybe<Scalars['DateTime']>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  FromEmploymentType?: Maybe<Scalars['String']>;
  ToEmploymentType?: Maybe<Scalars['String']>;
  ExitReasonID?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<Scalars['String']>;
  PersonnelParameterType?: Maybe<Scalars['String']>;
};

export type EmployeeFulfilmentOutput = {
  __typename?: 'EmployeeFulfilmentOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
};

export type EmployeeIndentity = {
  __typename?: 'EmployeeIndentity';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
};

export type EmployeeInput = {
  IsSpecial?: Maybe<Scalars['Boolean']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystemDoc?: Maybe<Scalars['Boolean']>;
  SerialNo?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<FormStatus>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  CalendarTitle?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  EmployeeStatus?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type EmployeeInsuranceSchemeEntity = InterfaceBase & {
  __typename?: 'EmployeeInsuranceSchemeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeInsuranceSchemeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  InsuranceSchemeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Employee?: Maybe<EmployeeEntity>;
  InsuranceScheme?: Maybe<InsuranceSchemeEntity>;
};

export type EmployeeInsuranceSchemeInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  InsuranceSchemeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeKpiCategoryEntity = InterfaceBase & {
  __typename?: 'EmployeeKPICategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPICategoryID: Scalars['String'];
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalCategory?: Maybe<AppraisalCategoryEntity>;
  EmployeeKPIHeader?: Maybe<EmployeeKpiHeaderEntity>;
  EmployeeKPIDetail?: Maybe<Array<EmployeeKpiDetailEntity>>;
};

export type EmployeeKpiCategoryInput = {
  EmployeeKPICategoryID?: Maybe<Scalars['String']>;
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  AppraisalCategoryID?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<EmployeeKpiDetailInput>>;
};

export type EmployeeKpiDetailEntity = InterfaceBase & {
  __typename?: 'EmployeeKPIDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID: Scalars['String'];
  EmployeeKPICategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeKPICategory?: Maybe<EmployeeKpiCategoryEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  EmployeeKPIDetailRating?: Maybe<Array<EmployeeKpiDetailRatingEntity>>;
};

export type EmployeeKpiDetailInput = {
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  EmployeeKPICategoryID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Weightage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Scale?: Maybe<Array<EmployeeKpiDetailRatingInput>>;
};

export type EmployeeKpiDetailRatingEntity = InterfaceBase & {
  __typename?: 'EmployeeKPIDetailRatingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPIDetailRatingID: Scalars['String'];
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeKPIDetail?: Maybe<EmployeeKpiDetailEntity>;
};

export type EmployeeKpiDetailRatingInput = {
  EmployeeKPIDetailRatingID?: Maybe<Scalars['String']>;
  EmployeeKPIDetailID?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeKpiHeaderEntity = InterfaceBase & {
  __typename?: 'EmployeeKPIHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  EmployeeKPICategory?: Maybe<Array<EmployeeKpiCategoryEntity>>;
  AppraisalTemplete?: Maybe<AppraisalTempleteEntity>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeKpiHeaderInput = {
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalTempleteID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Categories?: Maybe<Array<EmployeeKpiCategoryInput>>;
};

export type EmployeeKpiEntity = InterfaceBase & {
  __typename?: 'EmployeeKpiEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseDetailsID: Scalars['String'];
  EmployeeKpiDetails: Array<Scalars['JSON']>;
  Comment?: Maybe<Scalars['String']>;
  KpiExerciseDetail: KpiExerciseDetailsEntity;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeKpiInput = {
  ID?: Maybe<Scalars['String']>;
  KpiExerciseID: Scalars['String'];
  EmployeeKpiDetails: Array<KpiMinMaxWeightage>;
  Status: Status;
};

export type EmployeeKpiRepositoryEntity = InterfaceBase & {
  __typename?: 'EmployeeKpiRepositoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  EmployeeKpiDetails: Scalars['JSON'];
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeKpiRepositoryInput = {
  ID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  EmployeeKpiDetails: KpiCategoryItemInput;
};

export type EmployeeLeaveEntitlementEntity = {
  __typename?: 'EmployeeLeaveEntitlementEntity';
  EmployeeLeaveEntitlementID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveYear: Scalars['Float'];
  Date: Scalars['DateTime'];
  Entitlement: Scalars['Float'];
  Days?: Maybe<Scalars['Float']>;
  LeavePolicyID: Scalars['String'];
  IsLocked: Scalars['Boolean'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Employee: EmployeeEntity;
  LeaveType: LeaveTypeEntity;
  LeaveItem: LeaveItemEntity;
};

export type EmployeeLeavePolicy = {
  __typename?: 'EmployeeLeavePolicy';
  LeaveYear?: Maybe<Scalars['Float']>;
  LeavePolicy?: Maybe<LeaveItemEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
};

export type EmployeeLeavesOutput = {
  __typename?: 'EmployeeLeavesOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<Scalars['String']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  LeaveApprovalStatus?: Maybe<Scalars['String']>;
  OwnSubordinates?: Maybe<Scalars['Boolean']>;
  TotalDay?: Maybe<Scalars['Float']>;
};

export type EmployeeLeavesSummary = {
  __typename?: 'EmployeeLeavesSummary';
  Employee?: Maybe<EmployeeEntity>;
  LOSByMonth?: Maybe<Scalars['Float']>;
  LOSByDay?: Maybe<Scalars['Float']>;
  EmployeeLeavePolicies?: Maybe<Array<EmployeeLeavePolicy>>;
};

export type EmployeeListOutput = {
  __typename?: 'EmployeeListOutput';
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
};

export type EmployeeListingOutput = {
  __typename?: 'EmployeeListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  EmployeeNoName?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
};

export type EmployeeListingOutputv2 = {
  __typename?: 'EmployeeListingOutputv2';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
};

export type EmployeeNotificationOutput = {
  __typename?: 'EmployeeNotificationOutput';
  NotificationID?: Maybe<Scalars['String']>;
  Topic?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['String']>;
  NotificationType?: Maybe<Scalars['String']>;
  ReadStatus?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  LeaveDetails?: Maybe<LeaveDetails>;
  ClaimDetails?: Maybe<ClaimDetails>;
  RequisitionDetails?: Maybe<RequisitionDetails>;
  OvertimeDetails?: Maybe<OvertimeEntity>;
  TimeOffDetails?: Maybe<TimeOffEntity>;
  ChangeShiftDetails?: Maybe<ChangeShiftEntity>;
  DataChangesDetails?: Maybe<DataChangesDetails>;
  ProjectExpenseClaimDetails?: Maybe<ProjectExpenseClaimDetails>;
  PerformanceKpiDetail?: Maybe<KpiExerciseDetailsEntity>;
  PerformanceReviewDetail?: Maybe<EmployeeReviewEntity>;
  HelpdeskTicketDetail?: Maybe<HelpdeskTicketEntity>;
  ProjectTimesheetDetail?: Maybe<ProjectTimesheetMonthlyRecordEntity>;
  TrainingNominationDetail?: Maybe<ScheduledTrainingEmployeeEntity>;
  TrainingRequestDetail?: Maybe<EmployeeTrainingRequestEntity>;
  TrainingSelfRegistrationDetail?: Maybe<ScheduledTrainingEmployeeEntity>;
  TrainingWithdrawalDetail?: Maybe<ScheduledTrainingEmployeeEntity>;
  Rec_RequisitionDetail?: Maybe<Rec_RequisitionEntity>;
  Rec_OfferDetail?: Maybe<Rec_OfferOutput>;
  Rec_HiringDetail?: Maybe<Rec_HiringOutput>;
  Status?: Maybe<Status>;
  AttendanceCorrectionDetail?: Maybe<AttendanceCorrectionDetail>;
  BatchOvertimeDetail?: Maybe<BatchOvertimeDetails>;
};

export type EmployeePlanningOutput = {
  __typename?: 'EmployeePlanningOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  QuaterType?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  PlanningList?: Maybe<Array<EmployeeKpiHeaderEntity>>;
};

export type EmployeePrevContributionEntity = InterfaceBase & {
  __typename?: 'EmployeePrevContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeePrevContributionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  GrossTaxable?: Maybe<Scalars['Float']>;
  EPFPaid?: Maybe<Scalars['Float']>;
  PCBPaid?: Maybe<Scalars['Float']>;
  ZAKATPaid?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  TaxExemptionList?: Maybe<Scalars['String']>;
};

export type EmployeePrevContributionInput = {
  EmployeePrevContributionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  GrossTaxable: Scalars['Float'];
  EPFPaid: Scalars['Float'];
  PCBPaid: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxExemptionList?: Maybe<Scalars['String']>;
};

export type EmployeeReportingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ReportingStructureID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  ItemActionType?: Maybe<ItemActionType>;
  ReportingRecordType?: Maybe<ReportingRecordType>;
};

export type EmployeeReportingOutput = {
  __typename?: 'EmployeeReportingOutput';
  ReportingStructureID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  ItemActionType: ItemActionType;
  ReportingType: ReportingType;
  SuperiorID: Scalars['String'];
  Superior?: Maybe<EmployeeEntity>;
  SubordinateID: Scalars['String'];
  Subordinate?: Maybe<EmployeeEntity>;
};

export type EmployeeResponse = {
  __typename?: 'EmployeeResponse';
  subscriptionAccountID?: Maybe<Scalars['String']>;
  employeeID?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  SuperUserID?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  contactID?: Maybe<Scalars['String']>;
};

export type EmployeeReviewEntity = InterfaceBase & {
  __typename?: 'EmployeeReviewEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReviewerID: Scalars['String'];
  ReviewExerciseID: Scalars['String'];
  ReviewFormType: Scalars['String'];
  ReviewFormTemplateID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  IsDelegation?: Maybe<Scalars['Boolean']>;
  ToDate: Scalars['DateTime'];
  ReviewDate?: Maybe<Scalars['DateTime']>;
  EmployeeRating?: Maybe<Scalars['Float']>;
  ReviewerRating?: Maybe<Scalars['Float']>;
  OverallGrade?: Maybe<Scalars['String']>;
  ReviewProcessLog?: Maybe<Scalars['JSON']>;
  ReviewExercise?: Maybe<ReviewExerciseEntity>;
  EmployeeReviewSection: Array<EmployeeReviewSectionEntity>;
  Employee?: Maybe<EmployeeEntity>;
  Reviewer?: Maybe<EmployeeEntity>;
  ReviewFormTemplate?: Maybe<ReviewFormTemplateEntity>;
  ReviewExerciseName: Scalars['String'];
  ReviewGroupName?: Maybe<Scalars['String']>;
  ReviewFormTemplateName: Scalars['String'];
  ApprovalHistory: Array<EmployeeReviewEntity>;
  ReviewGradingTable: Scalars['JSON'];
  HasNextLevelApproval?: Maybe<Scalars['Boolean']>;
  ReviewType: Scalars['String'];
  WeightageType: QuantitativeType;
  IsSendBackToInitaite?: Maybe<Scalars['Boolean']>;
};

export type EmployeeReviewInput = {
  EmployeeID: Scalars['String'];
  ReviewerID: Scalars['String'];
  ReviewFormType: Scalars['String'];
  ReviewFormTemplateID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  EmployeeReviewSection: Array<EmployeeReviewSectionInput>;
};

export type EmployeeReviewSectionEntity = InterfaceBase & {
  __typename?: 'EmployeeReviewSectionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeReviewID: Scalars['String'];
  ReviewSectionID: Scalars['String'];
  ReviewTemplateID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  EmployeeKpiID?: Maybe<Scalars['String']>;
  EmployeeViewable?: Maybe<Scalars['Boolean']>;
  Recommendation?: Maybe<Scalars['JSON']>;
  Weightage: Scalars['Float'];
  EmployeeReview?: Maybe<EmployeeReviewEntity>;
  ReviewSection?: Maybe<ReviewSectionEntity>;
  EmployeeReviewSectionItem: Array<EmployeeReviewSectionItemEntity>;
};

export type EmployeeReviewSectionInput = {
  ReviewSectionID: Scalars['String'];
  ReviewTemplateID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  EmployeeKpiID?: Maybe<Scalars['String']>;
  EmployeeViewable: Scalars['Boolean'];
  Weightage: Scalars['Float'];
  EmployeeReviewSectionItem: Array<EmployeeReviewSectionItemInput>;
};

export type EmployeeReviewSectionItemEntity = InterfaceBase & {
  __typename?: 'EmployeeReviewSectionItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeReviewSectionID: Scalars['String'];
  TemplateID: Scalars['String'];
  KpiCategoryID?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  ReviewItemID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  EmployeeReview?: Maybe<Scalars['String']>;
  ReviewerReview?: Maybe<Scalars['String']>;
  ReviewItemDetail: Scalars['JSON'];
  Weightage: Scalars['Float'];
  ItemWeightage?: Maybe<Scalars['Float']>;
  EmployeeReviewSection?: Maybe<EmployeeReviewSectionEntity>;
  ReviewConversationLog?: Maybe<Array<ReviewConversationLogEntity>>;
  ItemDetails: Scalars['JSON'];
};

export type EmployeeReviewSectionItemInput = {
  TemplateID: Scalars['String'];
  KpiCategoryID?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  ReviewItemID?: Maybe<Scalars['String']>;
  Sequence: Scalars['String'];
  Weightage: Scalars['Float'];
  ItemWeightage?: Maybe<Scalars['Float']>;
  EmployeeReview?: Maybe<Scalars['String']>;
  ReviewerReview?: Maybe<Scalars['String']>;
};

export type EmployeeReviewSetupInput = {
  ID: Scalars['String'];
  ReviewerID?: Maybe<Scalars['String']>;
  ReviewExerciseID?: Maybe<Scalars['String']>;
  ReviewFormTemplateID?: Maybe<Scalars['String']>;
};

export enum EmployeeSalaryReason {
  AnnualReview = 'Annual_Review',
  ChangePosition = 'Change_Position',
  ConfirmationOfProbation = 'Confirmation_of_Probation',
  ContractRenewal = 'Contract_Renewal',
  Increment = 'Increment',
  InternalTransfer = 'Internal_Transfer',
  MarketAdjustment = 'Market_Adjustment',
  Others = 'Others',
  Promotion = 'Promotion',
  Redesignation = 'Redesignation',
  NewHire = 'New_Hire'
}

export type EmployeeStatutoryEntity = {
  __typename?: 'EmployeeStatutoryEntity';
  EmployeeStatutoryID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  CountryCode?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type EmployeeStatutoryInput = {
  EmployeeStatutoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  CountryCode?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Scalars['JSON']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
};

export type EmployeeSummary = {
  __typename?: 'EmployeeSummary';
  TimeOff?: Maybe<Scalars['JSON']>;
  Overtime?: Maybe<Scalars['Float']>;
  ChangeShift?: Maybe<Scalars['Float']>;
  Roster?: Maybe<Scalars['Float']>;
  OT?: Maybe<Scalars['JSON']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ScanDate?: Maybe<Scalars['DateTime']>;
  Shift?: Maybe<Scalars['JSON']>;
  ClockType?: Maybe<IntegrationClockType>;
  TimeOffDate?: Maybe<Scalars['DateTime']>;
  ChangeShiftDate?: Maybe<Scalars['DateTime']>;
  DutyRosterDate?: Maybe<Scalars['DateTime']>;
  LatestAttendanceCorrectionRecord?: Maybe<Scalars['String']>;
  Batch?: Maybe<Scalars['DateTime']>;
  totalBatch?: Maybe<Scalars['Float']>;
  subordinatesTotal?: Maybe<Scalars['Float']>;
};

export type EmployeeTaxReliefEntity = InterfaceBase & {
  __typename?: 'EmployeeTaxReliefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeTaxReliefID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  TaxReliefID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ReliefAmount?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  TaxRelief?: Maybe<TaxReliefEntity>;
};

export type EmployeeTaxReliefInput = {
  EmployeeTaxReliefID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  TaxReliefID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ReliefAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeTrainingEntity = InterfaceBase & {
  __typename?: 'EmployeeTrainingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeTrainingID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CourseName: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  TrainingHour: Scalars['Float'];
  TrainingProvider: Scalars['String'];
  Trainer: Scalars['String'];
  TrainerRating?: Maybe<Scalars['Float']>;
  TrainerScore?: Maybe<Scalars['Float']>;
  Superior?: Maybe<Scalars['String']>;
  SuperiorRating?: Maybe<Scalars['Float']>;
  SuperiorScore?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type EmployeeTrainingInput = {
  EmployeeID: Scalars['String'];
  CourseName: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  TrainingHour: Scalars['Float'];
  TrainingProvider: Scalars['String'];
  Trainer: Scalars['String'];
  TrainerRating?: Maybe<Scalars['Float']>;
  TrainerScore?: Maybe<Scalars['Float']>;
  Superior?: Maybe<Scalars['String']>;
  SuperiorRating?: Maybe<Scalars['Float']>;
  SuperiorScore?: Maybe<Scalars['Float']>;
  DocumentInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeTrainingRequestEntity = NewInterfaceBase1 & {
  __typename?: 'EmployeeTrainingRequestEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  company_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  subscription_account_id: Scalars['String'];
  training_name: Scalars['String'];
  description: Scalars['String'];
  training_provider_name?: Maybe<Scalars['String']>;
  contact_info?: Maybe<Scalars['String']>;
  training_dt?: Maybe<Scalars['DateTime']>;
  estimated_course_fee?: Maybe<Scalars['Float']>;
  venue?: Maybe<Scalars['String']>;
  justification: Scalars['String'];
  web_url?: Maybe<Scalars['String']>;
  voice_document_id?: Maybe<Scalars['String']>;
  document_id?: Maybe<Array<Scalars['JSON']>>;
  approval_status: Request_Approval_Status;
  approval_log: Array<Scalars['JSON']>;
  remarks?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  voiceDocument?: Maybe<DocumentsEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  TrainingHistory?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  documents?: Maybe<Array<DocumentsEntity>>;
};

export type EmployeeTransactionOutput = {
  __typename?: 'EmployeeTransactionOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeFullName?: Maybe<Scalars['String']>;
  PayItems?: Maybe<Array<PayItemOutput>>;
  TotalAmount?: Maybe<Scalars['Float']>;
  TotalQuantity?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  InUse?: Maybe<Scalars['Boolean']>;
  IsProcessed: Scalars['Boolean'];
};

export type EmployeeUserDefineFieldsDetailEntity = InterfaceBase & {
  __typename?: 'EmployeeUserDefineFieldsDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsDetailID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsID?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
};

export type EmployeeUserDefineFieldsDetailInput = {
  EmployeeUserDefineFieldsDetailID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsID?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeUserDefineFieldsEntity = InterfaceBase & {
  __typename?: 'EmployeeUserDefineFieldsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeUserDefineFieldsID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsTrackHistory?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  EmployeeUserDefineFieldsDetail?: Maybe<EmployeeUserDefineFieldsDetailEntity>;
};

export type EmployeeUserDefineFieldsInput = {
  EmployeeUserDefineFieldsID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FieldLabel?: Maybe<Scalars['String']>;
  FieldType?: Maybe<Scalars['String']>;
  Values?: Maybe<Scalars['JSON']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsTrackHistory?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeesBalanceLeaveSummary = {
  __typename?: 'EmployeesBalanceLeaveSummary';
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
};

export enum EmploymentType {
  Contract = 'Contract',
  Internship = 'Internship',
  Permanent = 'Permanent',
  PartTime = 'Part_Time',
  CasualWorker = 'Casual_Worker',
  FixedTermContract = 'Fixed_Term_Contract'
}

export type EncashCarryForwardLeaveEntity = InterfaceBase & {
  __typename?: 'EncashCarryForwardLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EncashCarryForwardLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveEncashment?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
};

export type EncashCarryForwardLeaveInput = {
  EncashCarryForwardLeaveID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveEncashment?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EncashCarryForwardLeaveOutput = InterfaceBase & {
  __typename?: 'EncashCarryForwardLeaveOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EncashCarryForwardLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveEncashment?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
  PayrollProcessed?: Maybe<Scalars['Boolean']>;
};

export type EncashOffboadingLeaveEntity = InterfaceBase & {
  __typename?: 'EncashOffboadingLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EncashOffboadingLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveBalance?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
};

export type EncashOffboadingLeaveInput = {
  EncashOffboadingLeaveID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveBalance?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EncashedLeaveHistory = {
  __typename?: 'EncashedLeaveHistory';
  EncashOffboadingLeaveID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  LeaveBalance?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<Scalars['String']>;
};

export enum EntDistributionMethod {
  LosPeriod = 'LOS_Period',
  EqualMonthly = 'Equal_Monthly'
}

export type EntRoleUsrArgCountOutput = {
  __typename?: 'EntRoleUsrArgCountOutput';
  roleId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type EntRoleUsrArgEntity = {
  __typename?: 'EntRoleUsrArgEntity';
  id: Scalars['String'];
  entId: Scalars['String'];
  roleId: Scalars['String'];
  accId: Scalars['String'];
  usrId?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Role?: Maybe<RoleEntity>;
};

export type EntRoleUsrArgInput = {
  id?: Maybe<Scalars['String']>;
  entId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  accId?: Maybe<Scalars['String']>;
  usrId?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
};

export enum EntitlementAvailability {
  LengthOfService = 'Length_of_Service',
  BeginningOfYear = 'Beginning_of_Year'
}

export enum EntitlementBasedOn {
  DateJoin = 'Date_Join',
  JoinReferenceDate = 'Join_Reference_Date'
}

export enum EntitlementComputation {
  Max = 'Max',
  ByService = 'By_Service'
}

export type EntitlementSummary = {
  __typename?: 'EntitlementSummary';
  ClaimItemID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimType?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<Scalars['String']>;
  Entitled?: Maybe<Scalars['Float']>;
  Draft?: Maybe<Scalars['Float']>;
  Pending?: Maybe<Scalars['Float']>;
  Claimed?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  companyID?: Maybe<Array<Scalars['String']>>;
  roleID: Scalars['String'];
  userID?: Maybe<Scalars['String']>;
  userIDs?: Maybe<Array<Scalars['String']>>;
};

export type EonEaDocumentEntity = {
  __typename?: 'EonEaDocumentEntity';
  EonEaDocumentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  DocumentFile?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  BatchDateTime?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};

export type EonPayslipDocumentEntity = {
  __typename?: 'EonPayslipDocumentEntity';
  EonPayslipDocumentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  DocumentFile?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  BatchDateTime?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};

export type EventStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Event_Status>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type ExcelPayItemTransactionOutput = {
  __typename?: 'ExcelPayItemTransactionOutput';
  ExcelFileName?: Maybe<Scalars['String']>;
  PayPeriod?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  TotalEarning?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  TotalNonPayable?: Maybe<Scalars['Float']>;
  UploadedDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export enum ExitReason {
  Resigned = 'Resigned',
  Terminated = 'Terminated',
  Deceased = 'Deceased',
  Retired = 'Retired',
  Inactive = 'Inactive',
  Illness = 'Illness',
  Absconded = 'Absconded',
  DepartureFromMalaysia = 'DepartureFromMalaysia',
  InterCompanyTransfer = 'InterCompanyTransfer',
  Null = 'Null'
}

export type ExportAttendanceProcessing = InterfaceBase & {
  __typename?: 'ExportAttendanceProcessing';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCalculationID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  Value?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  ShiftDayName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  MonthlyAdjustedValue?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
  ShiftDay?: Maybe<ShiftDayEntity>;
  PayItem?: Maybe<PayItemEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  PeriodicCycleStartDate?: Maybe<Scalars['DateTime']>;
  PeriodicCycleEndDate?: Maybe<Scalars['DateTime']>;
  OTAmount?: Maybe<Scalars['Float']>;
  Contact?: Maybe<ContactEntity>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FinalClaimApprovalUpdateInput = {
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FinalRatingDetailEntity = InterfaceBase & {
  __typename?: 'FinalRatingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FinalRatingDetailID: Scalars['String'];
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FinalRatingHeader?: Maybe<FinalRatingHeaderEntity>;
};

export type FinalRatingDetailInput = {
  FinalRatingDetailID?: Maybe<Scalars['String']>;
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FinalRatingHeaderEntity = InterfaceBase & {
  __typename?: 'FinalRatingHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FinalRatingHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  FinalRatingDetails?: Maybe<Array<FinalRatingDetailEntity>>;
  AppraisalPolicy?: Maybe<Array<AppraisalPolicyEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type FinalRatingHeaderInput = {
  FinalRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<FinalRatingDetailInput>>;
};

export type FolderEntity = InterfaceBase & {
  __typename?: 'FolderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FolderID: Scalars['String'];
  FolderName?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Documents?: Maybe<Array<DocumentsEntity>>;
};

export type FormItemRatingEntity = InterfaceBase & {
  __typename?: 'FormItemRatingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  OrderType: SequenceOrderType;
  From: Scalars['Float'];
  To: Scalars['Float'];
  RatingScaleTable: Array<Scalars['JSON']>;
  InputMethod: RatingInputMethod;
  AllowFraction?: Maybe<Scalars['Boolean']>;
  AllowIncrement?: Maybe<Scalars['Boolean']>;
  IncrementNumber?: Maybe<Scalars['Float']>;
  InUse: Scalars['Boolean'];
};

export type FormItemRatingInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  OrderType?: Maybe<SequenceOrderType>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  RatingScaleTable?: Maybe<Array<RatingScaleTableInput>>;
  InputMethod?: Maybe<Scalars['String']>;
  AllowFraction?: Maybe<Scalars['Boolean']>;
  AllowIncrement?: Maybe<Scalars['Boolean']>;
  IncrementNumber?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type FormItemRatingSetup = {
  InputMethod?: Maybe<Scalars['String']>;
  AllowFraction?: Maybe<Scalars['Boolean']>;
  AllowIncrement?: Maybe<Scalars['Boolean']>;
  IncrementNumber?: Maybe<Scalars['Float']>;
};

export enum FormStatus {
  Completed = 'Completed',
  Draft = 'Draft'
}

export type FreezePayrollEntity = InterfaceBase & {
  __typename?: 'FreezePayrollEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FreezePayrollID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Employees?: Maybe<EmployeeEntity>;
};

export type FreezePayrollInput = {
  FreezePayrollID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type GlAccountAssignmentEntity = InterfaceBase & {
  __typename?: 'GLAccountAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  GLAccountHeader?: Maybe<GlAccountHeaderEntity>;
};

export type GlAccountAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
};

export type GlAccountDetailEntity = InterfaceBase & {
  __typename?: 'GLAccountDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  GLAccountHeader?: Maybe<GlAccountHeaderEntity>;
};

export type GlAccountDetailInput = {
  ID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
};

export type GlAccountDetailOutputEntity = InterfaceBase & {
  __typename?: 'GLAccountDetailOutputEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['JSON']>;
  Details?: Maybe<Scalars['JSON']>;
  Type?: Maybe<Scalars['String']>;
};

export type GlAccountHeaderEntity = InterfaceBase & {
  __typename?: 'GLAccountHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  GLAccountDetail?: Maybe<Array<GlAccountDetailEntity>>;
};

export type GlAccountHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
};

export type GlClaimRecordsDetail = {
  __typename?: 'GLClaimRecordsDetail';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentUrl?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  ClaimNo?: Maybe<Scalars['String']>;
  ExpenseAccount?: Maybe<Scalars['String']>;
  LocationFrom?: Maybe<Scalars['String']>;
  LocationTo?: Maybe<Scalars['String']>;
  MealStartDatetime?: Maybe<Scalars['DateTime']>;
  MealEndDatetime?: Maybe<Scalars['DateTime']>;
  OutstationStartDate?: Maybe<Scalars['DateTime']>;
  OutstationEndDate?: Maybe<Scalars['DateTime']>;
  VehicleTypeID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export type GeneralSettingsLastUpdatedOutput = {
  __typename?: 'GeneralSettingsLastUpdatedOutput';
  RoleSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  ReportSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  RoleSettingsIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PayrollSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollSettingsIsLastUpdated?: Maybe<Scalars['Boolean']>;
  LeaveSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  LeaveSettingsIsLastUpddated?: Maybe<Scalars['Boolean']>;
  ClaimSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  ClaimSettingsIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobGradeLastPostedDate?: Maybe<Scalars['DateTime']>;
  JobGradeIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobGradeNoOfActiveRecords?: Maybe<Scalars['Float']>;
  JobDesignationLastPostedDate?: Maybe<Scalars['DateTime']>;
  JobDesignationIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobDesignationNoOfActiveRecords?: Maybe<Scalars['Float']>;
  RecruitmentSourceCount?: Maybe<Scalars['Float']>;
  RecruitmentSourceIsLastUpddated?: Maybe<Scalars['Boolean']>;
  PayrollItemLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollItemIsLastUpddated?: Maybe<Scalars['Boolean']>;
  PayrollItemNoOfActiveRecords?: Maybe<Scalars['Float']>;
  LHDNReportsNoOfActiveRecords?: Maybe<Scalars['Float']>;
  CompanylastUpdate?: Maybe<Scalars['Boolean']>;
  CompanylastPosted?: Maybe<Scalars['DateTime']>;
  NewslastUpdate?: Maybe<Scalars['Boolean']>;
  NewslastPosted?: Maybe<Scalars['DateTime']>;
  AutoNumberSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
};

export type GenerateClaimCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
};

export type GenerateLeaveCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
};

export type GetApprovedDetailInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
};

export type GetApprovedDetailOutput = {
  __typename?: 'GetApprovedDetailOutput';
  Date?: Maybe<Scalars['String']>;
  ApprovedBySuperior?: Maybe<Scalars['String']>;
  ApprovedAmt?: Maybe<Scalars['Float']>;
};

export type GetCompletedDetailInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
};

export type GetCompletedDetailOutput = {
  __typename?: 'GetCompletedDetailOutput';
  ClaimType?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['String']>;
  ClaimApprovedAmount?: Maybe<Scalars['Float']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  JobCode?: Maybe<Scalars['String']>;
  ClaimLimit?: Maybe<Scalars['String']>;
  ClaimStatus?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type GetEmpClaimListingInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
};

export type GetEmpClaimListingOutput = {
  __typename?: 'GetEmpClaimListingOutput';
  Subject?: Maybe<Scalars['String']>;
  SummaryTotalAmount?: Maybe<Scalars['Float']>;
  TotalClaimItem?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  ClaimStatus?: Maybe<Scalars['String']>;
  ClaimDate?: Maybe<Scalars['String']>;
};

export type GetMainClaimListingOutput = {
  __typename?: 'GetMainClaimListingOutput';
  Year?: Maybe<Scalars['Float']>;
  TotalClaimApproved?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
};

export type GetOsFulfilmentByJobGradeOutput = {
  __typename?: 'GetOSFulfilmentByJobGradeOutput';
  JobGradeID?: Maybe<Scalars['String']>;
  OS_Fulfillment?: Maybe<Scalars['Float']>;
};

export type GradingTable = {
  __typename?: 'GradingTable';
  FinalGrading: Scalars['String'];
  From: Scalars['Float'];
  To: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export type GradingTableInput = {
  FinalGrading: Scalars['String'];
  From: Scalars['Float'];
  To: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export type GroupCountEmployeeRoleListingOutput = {
  __typename?: 'GroupCountEmployeeRoleListingOutput';
  RecruitmentSourceID?: Maybe<Scalars['String']>;
  totalEmployees?: Maybe<Scalars['Float']>;
};

export type HrdcClaimInput = {
  hrdc_claim_id?: Maybe<Scalars['String']>;
  scheduled_training_id?: Maybe<Scalars['String']>;
  application_no?: Maybe<Scalars['String']>;
  amount_claimed?: Maybe<Scalars['Float']>;
  total_trainee?: Maybe<Scalars['Float']>;
  approved_claimed?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  attachment?: Maybe<DocumentsInput>;
  voice_input?: Maybe<DocumentsInput>;
  approver_id?: Maybe<Scalars['String']>;
};

export type HrdfCalculationInput = {
  EmployeeID: Scalars['String'];
  HRDFChargableAmount: Scalars['Float'];
  HRDFRate: Scalars['Float'];
};

export type HrdfCalculationOutput = {
  __typename?: 'HRDFCalculationOutput';
  EmployeeID: Scalars['String'];
  EmployerHRDFAmount?: Maybe<Scalars['Float']>;
  HRDFRate: Scalars['Float'];
};

export type HrdfClaimEntity = InterfaceBase & {
  __typename?: 'HRDFClaimEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HRDFClaimID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  RefNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type HrdfClaimInput = {
  HRDFClaimID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  RefNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HrdfClaimOutput = {
  __typename?: 'HRDFClaimOutput';
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  LatestClaimRefNo?: Maybe<Scalars['String']>;
  LatestClaimAmount?: Maybe<Scalars['Float']>;
};

export type HelpdeskListingReportInput = {
  CompanyID: Scalars['String'];
  SubmissionStartDate?: Maybe<Scalars['DateTime']>;
  SubmissionEndDate?: Maybe<Scalars['DateTime']>;
  ClosedStartDate?: Maybe<Scalars['DateTime']>;
  ClosedEndDate?: Maybe<Scalars['DateTime']>;
  AssigneeIDs: Array<Scalars['JSON']>;
  StatusLists: Array<Status>;
};

export type HelpdeskSubjectEntity = InterfaceBase & {
  __typename?: 'HelpdeskSubjectEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Subject: Scalars['String'];
  Description: Scalars['String'];
  RequireAttachment?: Maybe<Scalars['Boolean']>;
  DefaultAssignee: Array<Scalars['JSON']>;
  TargetResolutionDays: Scalars['Float'];
  ReminderFrequency: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
  Assignees: Scalars['JSON'];
  InUse: Scalars['Boolean'];
};

export type HelpdeskSubjectInput = {
  ID?: Maybe<Scalars['String']>;
  Subject: Scalars['String'];
  Description: Scalars['String'];
  RequireAttachment: Scalars['Boolean'];
  DefaultAssignee: Array<Scalars['JSON']>;
  TargetResolutionDays: Scalars['Float'];
  ReminderFrequency: Scalars['Float'];
};

export type HelpdeskTicketEntity = InterfaceBase & {
  __typename?: 'HelpdeskTicketEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  HelpdeskSubjectID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubjectDetails: Scalars['JSON'];
  TicketNo: Scalars['String'];
  ContactID: Scalars['String'];
  RequestDescription: Scalars['String'];
  RequestAttachment?: Maybe<Array<Scalars['JSON']>>;
  ResolutionDescription?: Maybe<Scalars['String']>;
  ResolutionAttachment?: Maybe<Array<Scalars['JSON']>>;
  ReassignedDT?: Maybe<Scalars['DateTime']>;
  OpenDT?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReassignedAssignee?: Maybe<Array<Scalars['JSON']>>;
  Employee?: Maybe<EmployeeEntity>;
  isOverdue?: Maybe<Scalars['Boolean']>;
  RequestDocuments?: Maybe<Array<DocumentsEntity>>;
  ResolutionDocuments?: Maybe<Array<DocumentsEntity>>;
  CurrentAssignees: Scalars['JSON'];
  AssigneeClosedTicket?: Maybe<Scalars['String']>;
};

export type HelpdeskTicketInput = {
  HelpdeskTicketID?: Maybe<Scalars['String']>;
  HelpdeskSubjectID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  TicketNo?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  RequestDescription?: Maybe<Scalars['String']>;
  RequestAttachment?: Maybe<Array<DocumentsInput>>;
  ResolutionDescription?: Maybe<Scalars['String']>;
  ResolutionAttachment?: Maybe<Array<DocumentsInput>>;
  ReassignedDT?: Maybe<Scalars['String']>;
  OpenDT?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ReassignedAssignee?: Maybe<Array<Scalars['JSON']>>;
};

export type HolidayEntity = InterfaceBase & {
  __typename?: 'HolidayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HolidayID: Scalars['String'];
  RestOffType: RestOffType;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  States?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  CompanyOffDays?: Maybe<Array<CompanyOffDayEntity>>;
};

export type HolidayInput = {
  HolidayID?: Maybe<Scalars['String']>;
  RestOffType?: Maybe<RestOffType>;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  States?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  currentEmployeeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  LeaveTypeHeader?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  UntillDate?: Maybe<Scalars['DateTime']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  myBirthDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  leaveType?: Maybe<Scalars['String']>;
  HolidayDescription?: Maybe<Scalars['String']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export enum HrPermission {
  HomeRead = 'HOME__READ',
  HomeCalendarRead = 'HOME_CALENDAR_READ',
  HomeCalendarBirthday = 'HOME_CALENDAR_BIRTHDAY',
  HomeDashboardRead = 'HOME_DASHBOARD____READ',
  Announcements = 'Announcements',
  AttendanceToday = 'Attendance_Today',
  AttendanceSummary = 'Attendance_Summary',
  Birthday = 'Birthday',
  ConfirmationAdmin = 'Confirmation_Admin',
  ConfirmationManager = 'Confirmation_Manager',
  LeaveAdmin = 'Leave_Admin',
  LeaveManager = 'Leave_Manager',
  LeaveSummary = 'Leave_Summary',
  OffboardingAdmin = 'Offboarding_Admin',
  OffboardingManager = 'Offboarding_Manager',
  HomeMymenuMyPersonalInfoRead = 'HOME_MYMENU_MY_PERSONAL_INFO_____READ',
  HomeMymenuRead = 'HOME_MYMENU_____READ',
  HomeMymenuMyAppovalDelegationRead = 'HOME_MYMENU_____MY_APPOVAL_DELEGATION_READ',
  HomeMymenuMyAppovalDelegationCreate = 'HOME_MYMENU_____MY_APPOVAL_DELEGATION_CREATE',
  HomeMymenuMyAppovalDelegationUpdate = 'HOME_MYMENU_____MY_APPOVAL_DELEGATION_UPDATE',
  HomeMymenuMyAppovalDelegationDelete = 'HOME_MYMENU_____MY_APPOVAL_DELEGATION_DELETE',
  HomeMymenuMyPersonalInfoPersonalProfileRead = 'HOME_MYMENU_MY_PERSONAL_INFO_PERSONAL_PROFILE_____READ',
  HomeMymenuMyPersonalInfoEducationMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____READ',
  HomeMymenuMyPersonalInfoEducationMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoEducationMymenuView = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____VIEW',
  HomeMymenuMyPersonalInfoEducationMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoEducationMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_EDUCATION_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoCertificateMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____READ',
  HomeMymenuMyPersonalInfoCertificateMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoCertificateMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoCertificateMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_CERTIFICATE_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoReferencesMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____READ',
  HomeMymenuMyPersonalInfoReferencesMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoReferencesMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoReferencesMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_REFERENCES_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoDependentMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_DEPENDENT_MYMENU_____READ',
  HomeMymenuMyPersonalInfoHealthInfoMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____READ',
  HomeMymenuMyPersonalInfoHealthInfoMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoHealthInfoMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoHealthInfoMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_HEALTH_INFO_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____READ',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoEmploymentHistoryMymenuDelete = 'HOME_MYMENU_MY_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYMENU_____DELETE',
  HomeMymenuMyPersonalInfoAdditionalUserDefinedMymenuCreate = 'HOME_MYMENU_MY_PERSONAL_INFO_ADDITIONAL_USER_DEFINED_MYMENU_____CREATE',
  HomeMymenuMyPersonalInfoAdditionalUserDefinedMymenuUpdate = 'HOME_MYMENU_MY_PERSONAL_INFO_ADDITIONAL_USER_DEFINED_MYMENU_____UPDATE',
  HomeMymenuMyPersonalInfoAdditionalUserDefinedMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_ADDITIONAL_USER_DEFINED_MYMENU_____READ',
  HomeMymenuMyPersonalInfoPersonalDocumentMymenuRead = 'HOME_MYMENU_MY_PERSONAL_INFO_PERSONAL_DOCUMENT_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoJobInfoMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_JOB_INFO_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_____READ',
  HomeMymenuMyEmploymentInfoAssetAssignedMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_ASSET_ASSIGNED_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoPerformanceJournalMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_PERFORMANCE_JOURNAL_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoInsuranceSchemeMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_INSURANCE_SCHEME_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoCareerMovementMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_CAREER_MOVEMENT_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoGeneralDocumentMymenuRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_GENERAL_DOCUMENT_MYMENU_____READ',
  HomeMymenuMyEmploymentInfoEmployeeUserDefineFieldsRead = 'HOME_MYMENU_MY_EMPLOYMENT_INFO_EMPLOYEE_USER_DEFINE_FIELDS_READ',
  HomeMymenuMyPayrollInfoRead = 'HOME_MYMENU_MY_PAYROLL_INFO_____READ',
  HomeMymenuMyPayrollInfoViewPayrollHistoryMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_VIEW_PAYROLL_HISTORY_MYMENU_____READ',
  HomeMymenuMyPayrollInfoStatutoryInfoMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_STATUTORY_INFO_MYMENU_____READ',
  HomeMymenuMyPayrollInfoTp3MymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_TP3_MYMENU_____READ',
  HomeMymenuMyPayrollInfoPayslipMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_PAYSLIP_MYMENU_____READ',
  HomeMymenuMyPayrollInfoEaFormMymenuRead = 'HOME_MYMENU_MY_PAYROLL_INFO_EA_FORM_MYMENU_____READ',
  HomeMymenuMyLeaveRead = 'HOME_MYMENU_MY_LEAVE_____READ',
  HomeMymenuMyLeaveCreate = 'HOME_MYMENU_MY_LEAVE_____CREATE',
  HomeMymenuMyLeaveUpdate = 'HOME_MYMENU_MY_LEAVE_____UPDATE',
  HomeMymenuMyLeaveSubmit = 'HOME_MYMENU_MY_LEAVE_____SUBMIT',
  HomeMymenuMyLeaveDelete = 'HOME_MYMENU_MY_LEAVE_____DELETE',
  HomeMymenuMyLeaveCancel = 'HOME_MYMENU_MY_LEAVE_____CANCEL',
  HomeMymenuMyLeaveWithdraw = 'HOME_MYMENU_MY_LEAVE_____WITHDRAW',
  HomeMymenuMyLeaveEntitlementRead = 'HOME_MYMENU_MY_LEAVE_ENTITLEMENT_____READ',
  HomeMymenuMyClaimEntitlementRead = 'HOME_MYMENU_MY_CLAIM_ENTITLEMENT_____READ',
  HomeMymenuMyClaimRead = 'HOME_MYMENU_MY_CLAIM_____READ',
  HomeMymenuMyClaimCreate = 'HOME_MYMENU_MY_CLAIM_____CREATE',
  HomeMymenuMyClaimUpdate = 'HOME_MYMENU_MY_CLAIM_____UPDATE',
  HomeMymenuMyClaimDelete = 'HOME_MYMENU_MY_CLAIM_____DELETE',
  HomeMymenuMyClaimWithdraw = 'HOME_MYMENU_MY_CLAIM_____WITHDRAW',
  HomeMymenuMyClaimSubmit = 'HOME_MYMENU_MY_CLAIM_____SUBMIT',
  HomeMymenuMyClaimCancel = 'HOME_MYMENU_MY_CLAIM_____CANCEL',
  HomeMymenuMyProjectExpenseClaimWithdraw = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____WITHDRAW',
  HomeMymenuMyProjectExpenseClaimSubmit = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____SUBMIT',
  HomeMymenuMyProjectExpenseClaimCreate = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____CREATE',
  HomeMymenuMyProjectExpenseClaimRead = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____READ',
  HomeMymenuMyProjectExpenseClaimUpdate = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____UPDATE',
  HomeMymenuMyProjectExpenseClaimDelete = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____DELETE',
  HomeMymenuMyProjectExpenseClaimCancel = 'HOME_MYMENU_MY_PROJECT_EXPENSE_CLAIM_____CANCEL',
  HomeMymenuMyRequisitionDelete = 'HOME_MYMENU_MY_REQUISITION_____DELETE',
  HomeMymenuMyRequisitionSubmit = 'HOME_MYMENU_MY_REQUISITION_____SUBMIT',
  HomeMymenuMyRequisitionRead = 'HOME_MYMENU_MY_REQUISITION_____READ',
  HomeMymenuMyRequisitionCreate = 'HOME_MYMENU_MY_REQUISITION_____CREATE',
  HomeMymenuMyRequisitionUpdate = 'HOME_MYMENU_MY_REQUISITION_____UPDATE',
  HomeMymenuMyRequisitionWithdraw = 'HOME_MYMENU_MY_REQUISITION_____WITHDRAW',
  HomeMymenuMyRequisitionCancel = 'HOME_MYMENU_MY_REQUISITION_____CANCEL',
  HomeMymenuMyTimesheetRead = 'HOME_MYMENU_MY_TIMESHEET_____READ',
  HomeMymenuMyTimesheetMyTimesheetRead = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____READ',
  HomeMymenuMyTimesheetMyTimesheetCreate = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____CREATE',
  HomeMymenuMyTimesheetMyTimesheetDelete = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____DELETE',
  HomeMymenuMyTimesheetMyTimesheetUpdate = 'HOME_MYMENU_MY_TIMESHEET_MY_TIMESHEET____UPDATE',
  HomeMymenuMyTimesheetProjectTimesheetRead = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET____READ',
  HomeMymenuMyTimesheetProjectTimesheetEntryRead = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____READ',
  HomeMymenuMyTimesheetProjectTimesheetEntryCreate = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____CREATE',
  HomeMymenuMyTimesheetProjectTimesheetEntryDelete = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____DELETE',
  HomeMymenuMyTimesheetProjectTimesheetEntryUpdate = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_ENTRY____UPDATE',
  HomeMymenuMyTimesheetProjectTimesheetSubmissionRead = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____READ',
  HomeMymenuMyTimesheetProjectTimesheetSubmissionSubmit = 'HOME_MYMENU_MY_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____SUBMIT',
  HomeMymenuMyTimeAttendanceRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_____READ',
  HomeMymenuMyTimeAttendanceDutyRosterMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_DUTY_ROSTER_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuCreate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____CREATE',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuSubmit = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____SUBMIT',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuCancel = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____CANCEL',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuDelete = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____DELETE',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuWithdraw = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____WITHDRAW',
  HomeMymenuMyTimeAttendanceOvertimeApplicationMymenuUpdate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYMENU_____UPDATE',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____SUBMIT',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuWithdraw = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____WITHDRAW',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCancel = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____CANCEL',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCreate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____CREATE',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuUpdate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____UPDATE',
  HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuDelete = 'HOME_MYMENU_MY_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYMENU_____DELETE',
  HomeMymenuMyTimeAttendanceAttendanceCorrectionRequestMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuSubmit = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____SUBMIT',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuCreate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____CREATE',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuUpdate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____UPDATE',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuDelete = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____DELETE',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuCancel = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____CANCEL',
  HomeMymenuMyTimeAttendanceChangeShiftRequestMymenuWithdraw = 'HOME_MYMENU_MY_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYMENU_____WITHDRAW',
  HomeMymenuMyTimeAttendanceAttendanceMymenuRead = 'HOME_MYMENU_MY_TIME_ATTENDANCE_ATTENDANCE_MYMENU_____READ',
  HomeMymenuMyTimeAttendanceAttendanceCorrectionRequestMymenuCreate = 'HOME_MYMENU_MY_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYMENU_____CREATE',
  HomeMymenuMyTimeAttendanceAttendanceCorrectionRequestMymenuCancel = 'HOME_MYMENU_MY_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYMENU_____CANCEL',
  HomeMysubordinateMyTimeAttendanceBatchOvertimeEntryMysubordinateRead = 'HOME_MYSUBORDINATE_MY_TIME_ATTENDANCE_BATCH_OVERTIME_ENTRY_MYSUBORDINATE_____READ',
  HomeMysubordinateMyTimeAttendanceBatchOvertimeEntryMysubordinateCreate = 'HOME_MYSUBORDINATE_MY_TIME_ATTENDANCE_BATCH_OVERTIME_ENTRY_MYSUBORDINATE_____CREATE',
  HomeMysubordinateMyTimeAttendanceBatchOvertimeEntryMysubordinateCancel = 'HOME_MYSUBORDINATE_MY_TIME_ATTENDANCE_BATCH_OVERTIME_ENTRY_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateMyTimeAttendanceBatchOvertimeEntryMysubordinateWithdraw = 'HOME_MYSUBORDINATE_MY_TIME_ATTENDANCE_BATCH_OVERTIME_ENTRY_MYSUBORDINATE_____WITHDRAW',
  HomeMymenuMyPerformanceRead = 'HOME_MYMENU_MY_PERFORMANCE_____READ',
  HomeMymenuMyPerformanceKpiMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_KPI_MYMENU_____READ',
  HomeMymenuMyPerformanceKpiMymenuUpdate = 'HOME_MYMENU_MY_PERFORMANCE_KPI_MYMENU_____UPDATE',
  HomeMymenuMyPerformanceKpiRepositoryMymenuDelete = 'HOME_MYMENU_MY_PERFORMANCE_KPI_REPOSITORY_MYMENU_____DELETE',
  HomeMymenuMyPerformanceKpiRepositoryMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_KPI_REPOSITORY_MYMENU_____READ',
  HomeMymenuMyPerformanceKpiRepositoryMymenuUpdate = 'HOME_MYMENU_MY_PERFORMANCE_KPI_REPOSITORY_MYMENU_____UPDATE',
  HomeMymenuMyDataChangeRequestRead = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____READ',
  HomeMymenuMyDataChangeRequestCreate = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____CREATE',
  HomeMymenuMyDataChangeRequestSubmit = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____SUBMIT',
  HomeMymenuMyDataChangeRequestCancel = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____CANCEL',
  HomeMymenuMyDataChangeRequestUpdate = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____UPDATE',
  HomeMymenuMyDataChangeRequestDelete = 'HOME_MYMENU_MY_DATA_CHANGE_REQUEST_____DELETE',
  HomeMysubordinateRead = 'HOME_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_____READ',
  HomeMysubordinateSubordinatePersonalInfoPersonalProfileMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_PERSONAL_PROFILE_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoEducationMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EDUCATION_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoCertificateMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_CERTIFICATE_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoReferencesMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_REFERENCES_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoDependentMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_DEPENDENT_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoHealthInfoMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_HEALTH_INFO_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePersonalInfoEmploymentHistoryMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoEmploymentHistoryMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoEmploymentHistoryMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_EMPLOYMENT_HISTORY_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoOtherAdditionalInfoMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_OTHER_ADDITIONAL_INFO_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePersonalInfoOtherAdditionalInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_OTHER_ADDITIONAL_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePersonalInfoOtherAdditionalInfoMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_OTHER_ADDITIONAL_INFO_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSubordinatePersonalInfoPersonalDocumentMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERSONAL_INFO_PERSONAL_DOCUMENT_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_____READ',
  HomeMysubordinateSubordinateEmploymentInfoJobInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_JOB_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoStatutoryInfoMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_STATUTORY_INFO_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoTp3MysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_TP3_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoAssetAssignedMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_ASSET_ASSIGNED_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoPerformanceJournalMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_PERFORMANCE_JOURNAL_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoInsuranceSchemeMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_INSURANCE_SCHEME_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoCareerMovementMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_CAREER_MOVEMENT_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateEmploymentInfoEmployeeUserDefineFieldsMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_EMPLOYMENT_INFO_EMPLOYEE_USER_DEFINE_FIELDS_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinateTimesheetRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TIMESHEET_____READ',
  HomeMysubordinateSubordinateLeaveRead = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____READ',
  HomeMysubordinateSubordinateLeaveSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____SUBMIT',
  HomeMysubordinateSubordinateLeaveCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____CREATE',
  HomeMysubordinateSubordinateLeaveWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____WITHDRAW',
  HomeMysubordinateSubordinateLeaveCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_____CANCEL',
  HomeMysubordinateSubordinateClaimSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____SUBMIT',
  HomeMysubordinateSubordinateClaimCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____CREATE',
  HomeMysubordinateSubordinateClaimRead = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____READ',
  HomeMysubordinateSubordinateClaimCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____CANCEL',
  HomeMysubordinateSubordinateLeaveEntitlementRead = 'HOME_MYSUBORDINATE_SUBORDINATE_LEAVE_ENTITLEMENT_____READ',
  HomeMysubordinateSubordinateClaimEntitlementRead = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_ENTITLEMENT_____READ',
  HomeMysubordinateSubordinateClaimWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_CLAIM_____WITHDRAW',
  HomeMysubordinateSubordinateRequisitionWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____WITHDRAW',
  HomeMysubordinateSubordinateRequisitionSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____SUBMIT',
  HomeMysubordinateSubordinateRequisitionCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____CREATE',
  HomeMysubordinateSubordinateRequisitionRead = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____READ',
  HomeMysubordinateSubordinateRequisitionCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_REQUISITION_____CANCEL',
  HomeMysubordinateSubordinateProjectExpenseClaimWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____WITHDRAW',
  HomeMysubordinateSubordinateProjectExpenseClaimRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____READ',
  HomeMysubordinateSubordinateProjectExpenseClaimSubmit = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____SUBMIT',
  HomeMysubordinateSubordinateProjectExpenseClaimCancel = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____CANCEL',
  HomeMysubordinateSubordinateProjectExpenseClaimCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PROJECT_EXPENSE_CLAIM_____CREATE',
  HomeMysubordinateSubordinatePerformanceManagementCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_____CREATE',
  HomeMysubordinateSubordinatePerformanceManagementRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_____READ',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateDelete = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateSave = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____SAVE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceDutyRosterMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_DUTY_ROSTER_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateCreate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateSubmit = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____SUBMIT',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateCancel = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateWithdraw = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____WITHDRAW',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateDelete = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSsubordinateTimeAttendanceOvertimeApplicationMysubordinateUpdate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_OVERTIME_APPLICATION_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateSubmit = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____SUBMIT',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateCancel = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateWithdraw = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____WITHDRAW',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateUpdate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateCreate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSsubordinateTimeAttendanceTimeOffApplicationMysubordinateDelete = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_TIME_OFF_APPLICATION_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSsubordinateTimeAttendanceAttendanceCorrectionRequestMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateCancel = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateWithdraw = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____WITHDRAW',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateSubmit = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____SUBMIT',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateCreate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateUpdate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____UPDATE',
  HomeMysubordinateSsubordinateTimeAttendanceChangeShiftRequestMysubordinateDelete = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_CHANGE_SHIFT_REQUEST_MYSUBORDINATE_____DELETE',
  HomeMysubordinateSsubordinateTimeAttendanceAttendanceMysubordinateRead = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_ATTENDANCE_MYSUBORDINATE_____READ',
  HomeMysubordinateSsubordinateTimeAttendanceAttendanceCorrectionRequestMysubordinateCreate = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYSUBORDINATE_____CREATE',
  HomeMysubordinateSsubordinateTimeAttendanceAttendanceCorrectionRequestMysubordinateCancel = 'HOME_MYSUBORDINATE_SSUBORDINATE_TIME_ATTENDANCE_ATTENDANCE_CORRECTION_REQUEST_MYSUBORDINATE_____CANCEL',
  HomeMysubordinateSubordinateOrganizationChartRead = 'HOME_MYSUBORDINATE_SUBORDINATE_ORGANIZATION_CHART_____READ',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateApprove = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____APPROVE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateReInitiate = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____RE_INITIATE',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateSendBack = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____SEND_BACK',
  HomeMysubordinateSubordinatePerformanceManagementKpiMysubordinateProceed = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_KPI_MYSUBORDINATE_____PROCEED',
  HomeMysubordinateTrainingManagementRead = 'HOME_MYSUBORDINATE_TRAINING_MANAGEMENT_____READ',
  HomeMysubordinateSubordinateTrainingSubordinateListingRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_____READ',
  HomeMysubordinateSubordinateTrainingSubordinateListingScheduledTrainingRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_SCHEDULED_TRAINING_____READ',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequiredTrainingRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUIRED_TRAINING_____READ',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequiredTrainingUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUIRED_TRAINING_____UPDATE',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequestForTrainingRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUEST_FOR_TRAINING_____READ',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequestForTrainingUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUEST_FOR_TRAINING_____UPDATE',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequestForTrainingCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUEST_FOR_TRAINING_____CREATE',
  HomeMysubordinateSubordinateTrainingSubordinateListingRequestForTrainingWithdraw = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SUBORDINATE_LISTING_REQUEST_FOR_TRAINING_____WITHDRAW',
  HomeMysubordinateSubordinateTrainingScheduledTrainingRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SCHEDULED_TRAINING_____READ',
  HomeMysubordinateSubordinateTrainingScheduledTrainingCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SCHEDULED_TRAINING_____CREATE',
  HomeMysubordinateSubordinateTrainingScheduledTrainingUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_SCHEDULED_TRAINING_____UPDATE',
  HomeMysubordinateSubordinateTrainingTrainingCalendarRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_CALENDAR_____READ',
  HomeMysubordinateSubordinateTrainingTrainingCalendarCreate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_CALENDAR_____CREATE',
  HomeMysubordinateSubordinateTrainingTrainingCalendarUpdate = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_CALENDAR_____UPDATE',
  HomeMysubordinateSubordinateTrainingTrainingRecordsRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_RECORDS_____READ',
  HomeMysubordinateSubordinateTrainingTrainingFulfillmentRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_FULFILLMENT_____READ',
  HomeMysubordinateSubordinateTrainingTrainingFulfillmentByCompulsoryCoursesRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_FULFILLMENT_BY_COMPULSORY_COURSES_____READ',
  HomeMysubordinateSubordinateTrainingTrainingFulfillmentByTargetHoursRead = 'HOME_MYSUBORDINATE_SUBORDINATE_TRAINING_TRAINING_FULFILLMENT_BY_TARGET_HOURS_____READ',
  HomeOnBehalfRead = 'HOME_ON_BEHALF_____READ',
  HomeOnBehalfLeaveRead = 'HOME_ON_BEHALF_LEAVE_____READ',
  HomeOnBehalfLeaveEntitlement = 'HOME_ON_BEHALF_LEAVE_____ENTITLEMENT',
  HomeOnBehalfLeaveCreate = 'HOME_ON_BEHALF_LEAVE_____CREATE',
  HomeOnBehalfLeaveCancel = 'HOME_ON_BEHALF_LEAVE_____CANCEL',
  HomeOnBehalfLeaveWithdraw = 'HOME_ON_BEHALF_LEAVE_____WITHDRAW',
  HomeOnBehalfClaimRead = 'HOME_ON_BEHALF_CLAIM_____READ',
  HomeOnBehalfClaimEntitlement = 'HOME_ON_BEHALF_CLAIM_____ENTITLEMENT',
  HomeOnBehalfClaimCreate = 'HOME_ON_BEHALF_CLAIM_____CREATE',
  HomeOnBehalfClaimWithdraw = 'HOME_ON_BEHALF_CLAIM_____WITHDRAW',
  HomeOnBehalfClaimCancel = 'HOME_ON_BEHALF_CLAIM_____CANCEL',
  HomeOnBehalfTimeAttendanceRead = 'HOME_ON_BEHALF_TIME_ATTENDANCE_____READ',
  HomeOnBehalfTimeAttendanceOvertimeRead = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____READ',
  HomeOnBehalfTimeAttendanceOvertimeCreate = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____CREATE',
  HomeOnBehalfTimeAttendanceOvertimeCancel = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____CANCEL',
  HomeOnBehalfTimeAttendanceOvertimeWithdraw = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____WITHDRAW',
  HomeOnBehalfTimeAttendanceOvertimeDelete = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____DELETE',
  HomeOnBehalfTimeAttendanceOvertimeUpdate = 'HOME_ON_BEHALF_TIME_ATTENDANCE_OVERTIME_____UPDATE',
  HomeOnBehalfTimeAttendanceTimeOffRead = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____READ',
  HomeOnBehalfTimeAttendanceTimeOffCancel = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____CANCEL',
  HomeOnBehalfTimeAttendanceTimeOffWithdraw = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____WITHDRAW',
  HomeOnBehalfTimeAttendanceTimeOffUpdate = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____UPDATE',
  HomeOnBehalfTimeAttendanceTimeOffCreate = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____CREATE',
  HomeOnBehalfTimeAttendanceTimeOffDelete = 'HOME_ON_BEHALF_TIME_ATTENDANCE_TIME_OFF_____DELETE',
  HomeTrainingRead = 'HOME_TRAINING_______READ',
  HomeTimesheetRead = 'HOME_TIMESHEET_______READ',
  CompanySettingTimesheetRead = 'COMPANY_SETTING_TIMESHEET_______READ',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityRead = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______READ',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityCreate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______CREATE',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityUpdate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______UPDATE',
  CompanySettingTimesheetTimesheetAccountTypeAndStdActivityDelete = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_TYPE_AND_STD_ACTIVITY_______DELETE',
  CompanySettingTimesheetTimesheetAccountCodeRead = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_CODE_______READ',
  CompanySettingTimesheetTimesheetAccountCodeCreate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_CODE_______CREATE',
  CompanySettingTimesheetTimesheetAccountCodeUpdate = 'COMPANY_SETTING_TIMESHEET_TIMESHEET_ACCOUNT_CODE_______UPDATE',
  HomeTimesheetEmployeeTimesheetRead = 'HOME_TIMESHEET_EMPLOYEE_TIMESHEET_______READ',
  HomeTimesheetEmployeeTimesheetExport = 'HOME_TIMESHEET_EMPLOYEE_TIMESHEET_______EXPORT',
  HomeTimesheetProjectTimesheetProjectTimesheetEntrySubmissionRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY_SUBMISSION_______READ',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY______READ',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryCreate = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY____CREATE',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryDelete = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY____DELETE',
  HomeTimesheetProjectTimesheetProjectTimesheetEntryUpdate = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_ENTRY____UPDATE',
  HomeTimesheetProjectTimesheetProjectTimesheetSubmissionRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____READ',
  HomeTimesheetProjectTimesheetProjectTimesheetSubmissionSubmit = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_SUBMISSION____SUBMIT',
  HomeTimesheetProjectTimesheetProjectTimesheetReportForApproverRead = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_REPORT_FOR_APPROVER______READ',
  HomeTimesheetProjectTimesheetProjectTimesheetReportForApproverGenerateReport = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_REPORT_FOR_APPROVER______GENERATE_REPORT',
  HomeTimesheetProjectTimesheetProjectTimesheetReportForApproverSendEmail = 'HOME_TIMESHEET_PROJECT_TIMESHEET_PROJECT_TIMESHEET_REPORT_FOR_APPROVER______SEND_EMAIL',
  HomeTimesheetMandayAnalysisByAccountCodeRead = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_CODE_______READ',
  HomeTimesheetMandayAnalysisByAccountCodeExport = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_CODE_______EXPORT',
  HomeTimesheetMandayAnalysisByAccountTypeRead = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_TYPE_______READ',
  HomeTimesheetMandayAnalysisByAccountTypeExport = 'HOME_TIMESHEET_MANDAY_ANALYSIS_BY_ACCOUNT_TYPE_______EXPORT',
  HomeAdminStandardAutoNumberingRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_______READ',
  HomeAdminStandardAutoNumberingEmployeeIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_EMPLOYEE_ID_______READ',
  HomeAdminStandardAutoNumberingEmployeeIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_EMPLOYEE_ID_______UPDATE',
  HomeAdminStandardAutoNumberingClaimIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CLAIM_ID_______READ',
  HomeAdminStandardAutoNumberingClaimIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CLAIM_ID_______UPDATE',
  HomeAdminStandardAutoNumberingTravelRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_TRAVEL_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingTravelRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_TRAVEL_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingCashAdvanceRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CASH_ADVANCE_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingCashAdvanceRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_CASH_ADVANCE_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingAssetRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_ASSET_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingAssetRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_ASSET_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingOtherRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_OTHER_REQUISITION_ID_______READ',
  HomeAdminStandardAutoNumberingOtherRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_OTHER_REQUISITION_ID_______UPDATE',
  HomeAdminStandardAutoNumberingProjectExpenseClaimIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_PROJECT_EXPENSE_CLAIM_ID_______READ',
  HomeAdminStandardAutoNumberingProjectExpenseClaimIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_PROJECT_EXPENSE_CLAIM_ID_______UPDATE',
  HomeAdminStandardAutoNumberingHelpdeskTicketIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_HELPDESK_TICKET_ID_______READ',
  HomeAdminStandardAutoNumberingHelpdeskTicketIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_HELPDESK_TICKET_ID_______UPDATE',
  HomeAdminStandardAutoNumberingStaffMovementRequestIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_STAFF_MOVEMENT_REQUEST_ID_______READ',
  HomeAdminStandardAutoNumberingStaffMovementRequestIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_STAFF_MOVEMENT_REQUEST_ID_______UPDATE',
  HomeAdminStandardAutoNumberingRecruitmentRequisitionIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_Recruitment_Requisition_ID_______READ',
  HomeAdminStandardAutoNumberingRecruitmentRequisitionIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_Recruitment_Requisition_ID_______UPDATE',
  HomeAdminStandardAutoNumberingJobVacancyIdRead = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_Job_Vacancy_ID_______READ',
  HomeAdminStandardAutoNumberingJobVacancyIdUpdate = 'HOME_ADMIN_STANDARD_AUTO_NUMBERING_Job_Vacancy_ID_______UPDATE',
  CompanySettingAdminRead = 'COMPANY_SETTING_ADMIN_______READ',
  CompanySettingAdminCreate = 'COMPANY_SETTING_ADMIN_______CREATE',
  CompanySettingAdminCompanyInfoRead = 'COMPANY_SETTING_ADMIN_COMPANY_INFO_______READ',
  CompanySettingAdminCompanyInfoUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_INFO_______UPDATE',
  CompanySettingAdminStatutoryInfoRead = 'COMPANY_SETTING_ADMIN_STATUTORY_INFO_______READ',
  CompanySettingAdminStatutoryInfoUpdate = 'COMPANY_SETTING_ADMIN_STATUTORY_INFO_______UPDATE',
  CompanySettingAdminCompanyHierarchyStructureRead = 'COMPANY_SETTING_ADMIN_COMPANY_HIERARCHY_STRUCTURE_______READ',
  CompanySettingAdminCompanyHierarchyStructureUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_HIERARCHY_STRUCTURE_______UPDATE',
  CompanySettingAdminOrgUnitStructureRead = 'COMPANY_SETTING_ADMIN_ORG_UNIT_STRUCTURE_______READ',
  CompanySettingAdminOrgUnitStructureUpdate = 'COMPANY_SETTING_ADMIN_ORG_UNIT_STRUCTURE_______UPDATE',
  CompanySettingAdminJobPositionByOrgUnitRead = 'COMPANY_SETTING_ADMIN_JOB_POSITION_BY_ORG_UNIT_______READ',
  CompanySettingAdminJobPositionByOrgUnitUpdate = 'COMPANY_SETTING_ADMIN_JOB_POSITION_BY_ORG_UNIT_______UPDATE',
  CompanySettingAdminReportingStructureRead = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______READ',
  CompanySettingAdminReportingStructureCreate = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______CREATE',
  CompanySettingAdminReportingStructureUpdate = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______UPDATE',
  CompanySettingAdminReportingStructureDelete = 'COMPANY_SETTING_ADMIN_REPORTING_STRUCTURE_______DELETE',
  CompanySettingAdminCalendarPolicyRead = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______READ',
  CompanySettingAdminCalendarPolicyCreate = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______CREATE',
  CompanySettingAdminCalendarPolicyUpdate = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______UPDATE',
  CompanySettingAdminCalendarPolicyDelete = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______DELETE',
  CompanySettingAdminCalendarPolicyInactive = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______INACTIVE',
  CompanySettingAdminCalendarPolicyReactivate = 'COMPANY_SETTING_ADMIN_CALENDAR_POLICY_______REACTIVATE',
  CompanySettingAdminCompanyAccessLevelAuthorizationRead = 'COMPANY_SETTING_ADMIN_COMPANY_ACCESS_LEVEL_AUTHORIZATION_______READ',
  CompanySettingAdminAccessPermissionRead = 'COMPANY_SETTING_ADMIN_ACCESS_PERMISSION_______READ',
  CompanySettingAdminAccessPermissionUpdate = 'COMPANY_SETTING_ADMIN_ACCESS_PERMISSION_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentEmployeeIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_EMPLOYEE_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentEmployeeIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_EMPLOYEE_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentClaimIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CLAIM_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentClaimIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CLAIM_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentTravelRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_TRAVEL_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentTravelRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_TRAVEL_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentCashAdvanceRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CASH_ADVANCE_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentCashAdvanceRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_CASH_ADVANCE_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentAssetRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_ASSET_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentAssetRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_ASSET_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentOtherRequisitionIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_OTHER_REQUISITION_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentOtherRequisitionIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_OTHER_REQUISITION_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentProjectExpenseClaimIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_PROJECT_EXPENSE_CLAIM_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentProjectExpenseClaimIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_PROJECT_EXPENSE_CLAIM_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentHelpdeskTicketIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_HELPDESK_TICKET_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentHelpdeskTicketIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_HELPDESK_TICKET_ID_______UPDATE',
  CompanySettingAdminCompanyAutoNumberingAssignmentStaffMovementRequestIdRead = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_STAFF_MOVEMENT_REQUEST_ID_______READ',
  CompanySettingAdminCompanyAutoNumberingAssignmentStaffMovementRequestIdUpdate = 'COMPANY_SETTING_ADMIN_COMPANY_AUTO_NUMBERING_ASSIGNMENT_STAFF_MOVEMENT_REQUEST_ID_______UPDATE',
  CompanySettingAdminGeneralDocumentRead = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______READ',
  CompanySettingAdminGeneralDocumentView = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______VIEW',
  CompanySettingAdminGeneralDocumentCreate = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______CREATE',
  CompanySettingAdminEmployeeAppSecurityAssignmentDelete = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______DELETE',
  CompanySettingAdminEmployeeAppSecurityAssignmentRead = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______READ',
  CompanySettingAdminEmployeeAppSecurityAssignmentView = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______VIEW',
  CompanySettingAdminEmployeeAppSecurityAssignmentCreate = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______CREATE',
  CompanySettingAdminEmployeeAppSecurityAssignmentUpdate = 'COMPANY_SETTING_ADMIN_EMPLOYEE_APP_SECURITY_ASSIGNMENT_______UPDATE',
  CompanySettingAdminGeneralDocumentDelete = 'COMPANY_SETTING_ADMIN_GENERAL_DOCUMENT_______DELETE',
  CommonSettingAdminRead = 'COMMON_SETTING_ADMIN_______READ',
  CommonSettingTrainingRead = 'COMMON_SETTING_TRAINING_______READ',
  CommonSettingTrainingCourseCategoryRead = 'COMMON_SETTING_TRAINING_COURSE_CATEGORY_______READ',
  CommonSettingTrainingCourseCategoryCreate = 'COMMON_SETTING_TRAINING_COURSE_CATEGORY_______CREATE',
  CommonSettingTrainingCourseCategoryUpdate = 'COMMON_SETTING_TRAINING_COURSE_CATEGORY_______UPDATE',
  CommonSettingTrainingCourseCategoryDelete = 'COMMON_SETTING_TRAINING_COURSE_CATEGORY_______DELETE',
  CommonSettingTrainingTrainingCoursesLibraryRead = 'COMMON_SETTING_TRAINING_TRAINING_COURSES_LIBRARY_______READ',
  CommonSettingTrainingTrainingCoursesLibraryCreate = 'COMMON_SETTING_TRAINING_TRAINING_COURSES_LIBRARY_______CREATE',
  CommonSettingTrainingTrainingCoursesLibraryUpdate = 'COMMON_SETTING_TRAINING_TRAINING_COURSES_LIBRARY_______UPDATE',
  CommonSettingTrainingTrainingCoursesLibraryDelete = 'COMMON_SETTING_TRAINING_TRAINING_COURSES_LIBRARY_______DELETE',
  CommonSettingTrainingTrainingProvidersRead = 'COMMON_SETTING_TRAINING_TRAINING_PROVIDERS_______READ',
  CommonSettingTrainingTrainingProvidersCreate = 'COMMON_SETTING_TRAINING_TRAINING_PROVIDERS_______CREATE',
  CommonSettingTrainingTrainingProvidersUpdate = 'COMMON_SETTING_TRAINING_TRAINING_PROVIDERS_______UPDATE',
  CommonSettingTrainingTrainingProvidersDelete = 'COMMON_SETTING_TRAINING_TRAINING_PROVIDERS_______DELETE',
  CommonSettingTrainingTrainingExpensesRead = 'COMMON_SETTING_TRAINING_TRAINING_EXPENSES_______READ',
  CommonSettingTrainingTrainingExpensesCreate = 'COMMON_SETTING_TRAINING_TRAINING_EXPENSES_______CREATE',
  CommonSettingTrainingTrainingExpensesUpdate = 'COMMON_SETTING_TRAINING_TRAINING_EXPENSES_______UPDATE',
  CommonSettingTrainingTrainingExpensesDelete = 'COMMON_SETTING_TRAINING_TRAINING_EXPENSES_______DELETE',
  CommonSettingTrainingTrainingApprovalPolicyRead = 'COMMON_SETTING_TRAINING_TRAINING_APPROVAL_POLICY_______READ',
  CommonSettingTrainingTrainingApprovalPolicyCreate = 'COMMON_SETTING_TRAINING_TRAINING_APPROVAL_POLICY_______CREATE',
  CommonSettingTrainingTrainingApprovalPolicyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_APPROVAL_POLICY_______UPDATE',
  CommonSettingTrainingTrainingApprovalPolicyDelete = 'COMMON_SETTING_TRAINING_TRAINING_APPROVAL_POLICY_______DELETE',
  CommonSettingTrainingTrainingRequestApprovalPolicyRead = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______READ',
  CommonSettingTrainingTrainingRequestApprovalPolicyCreate = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______CREATE',
  CommonSettingTrainingTrainingRequestApprovalPolicyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______UPDATE',
  CommonSettingTrainingTrainingRequestApprovalPolicyDelete = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______DELETE',
  CommonSettingTrainingTrainingRequestApprovalPolicyReactive = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______REACTIVE',
  CommonSettingTrainingTrainingRequestApprovalPolicyInactive = 'COMMON_SETTING_TRAINING_TRAINING_REQUEST_APPROVAL_POLICY_______INACTIVE',
  CommonSettingTrainingTrainingNominationApprovalPolicyRead = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______READ',
  CommonSettingTrainingTrainingNominationApprovalPolicyCreate = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______CREATE',
  CommonSettingTrainingTrainingNominationApprovalPolicyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______UPDATE',
  CommonSettingTrainingTrainingNominationApprovalPolicyDelete = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______DELETE',
  CommonSettingTrainingTrainingNominationApprovalPolicyReactive = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______REACTIVE',
  CommonSettingTrainingTrainingNominationApprovalPolicyInactive = 'COMMON_SETTING_TRAINING_TRAINING_NOMINATION_APPROVAL_POLICY_______INACTIVE',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyRead = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______READ',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyCreate = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______CREATE',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______UPDATE',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyDelete = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______DELETE',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyReactive = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______REACTIVE',
  CommonSettingTrainingTrainingSelfRegistrationApprovalPolicyInactive = 'COMMON_SETTING_TRAINING_TRAINING_SELF_REGISTRATION_APPROVAL_POLICY_______INACTIVE',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyRead = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______READ',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyCreate = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______CREATE',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______UPDATE',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyDelete = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______DELETE',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyReactive = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______REACTIVE',
  CommonSettingTrainingTrainingWithdrawalApprovalPolicyInactive = 'COMMON_SETTING_TRAINING_TRAINING_WITHDRAWAL_APPROVAL_POLICY_______INACTIVE',
  CommonSettingTrainingTrainerProfileRead = 'COMMON_SETTING_TRAINING_TRAINER_PROFILE_______READ',
  CommonSettingTrainingTrainerProfileCreate = 'COMMON_SETTING_TRAINING_TRAINER_PROFILE_______CREATE',
  CommonSettingTrainingTrainerProfileUpdate = 'COMMON_SETTING_TRAINING_TRAINER_PROFILE_______UPDATE',
  CommonSettingTrainingTrainerProfileDelete = 'COMMON_SETTING_TRAINING_TRAINER_PROFILE_______DELETE',
  CommonSettingTrainingTrainingSurveyRead = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_______READ',
  CommonSettingTrainingTrainingSurveyCreate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_______CREATE',
  CommonSettingTrainingTrainingSurveyUpdate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_______UPDATE',
  CommonSettingTrainingTrainingSurveyDelete = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_______DELETE',
  CommonSettingTrainingTrainingSurveySurveyTypeRead = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_TYPE_______READ',
  CommonSettingTrainingTrainingSurveySurveyTypeCreate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_TYPE_______CREATE',
  CommonSettingTrainingTrainingSurveySurveyTypeUpdate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_TYPE_______UPDATE',
  CommonSettingTrainingTrainingSurveySurveyTypeDelete = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_TYPE_______DELETE',
  CommonSettingTrainingTrainingSurveySurveyItemsRead = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_ITEMS_______READ',
  CommonSettingTrainingTrainingSurveySurveyItemsCreate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_ITEMS_______CREATE',
  CommonSettingTrainingTrainingSurveySurveyItemsUpdate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_ITEMS_______UPDATE',
  CommonSettingTrainingTrainingSurveySurveyItemsDelete = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_ITEMS_______DELETE',
  CommonSettingTrainingTrainingSurveySurveyFormRead = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_FORM_______READ',
  CommonSettingTrainingTrainingSurveySurveyFormCreate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_FORM_______CREATE',
  CommonSettingTrainingTrainingSurveySurveyFormUpdate = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_FORM_______UPDATE',
  CommonSettingTrainingTrainingSurveySurveyFormDelete = 'COMMON_SETTING_TRAINING_TRAINING_SURVEY_SURVEY_FORM_______DELETE',
  CommonSettingTrainingBudgetGroupRead = 'COMMON_SETTING_TRAINING_BUDGET_GROUP_______READ',
  CommonSettingTrainingBudgetGroupCreate = 'COMMON_SETTING_TRAINING_BUDGET_GROUP_______CREATE',
  CommonSettingTrainingBudgetGroupUpdate = 'COMMON_SETTING_TRAINING_BUDGET_GROUP_______UPDATE',
  CommonSettingTrainingBudgetGroupDelete = 'COMMON_SETTING_TRAINING_BUDGET_GROUP_______DELETE',
  CommonSettingTrainingTargetGroupRead = 'COMMON_SETTING_TRAINING_TARGET_GROUP_______READ',
  CommonSettingTrainingTargetGroupCreate = 'COMMON_SETTING_TRAINING_TARGET_GROUP_______CREATE',
  CommonSettingTrainingTargetGroupUpdate = 'COMMON_SETTING_TRAINING_TARGET_GROUP_______UPDATE',
  CommonSettingTrainingTargetGroupDelete = 'COMMON_SETTING_TRAINING_TARGET_GROUP_______DELETE',
  HomeTrainingTrainingSettingsRead = 'HOME_TRAINING_TRAINING_SETTINGS____READ',
  HomeTrainingTrainingSettingBudgetRead = 'HOME_TRAINING_TRAINING_SETTING_BUDGET____READ',
  HomeTrainingTrainingSettingBudgetUpdate = 'HOME_TRAINING_TRAINING_SETTING_BUDGET____UPDATE',
  HomeTrainingTrainingSettingBudgetCreate = 'HOME_TRAINING_TRAINING_SETTING_BUDGET____CREATE',
  HomeTrainingTrainingSettingBudgetDelete = 'HOME_TRAINING_TRAINING_SETTING_BUDGET____DELETE',
  HomeTrainingTrainingSettingTargetRead = 'HOME_TRAINING_TRAINING_SETTING_TARGET____READ',
  HomeTrainingTrainingSettingTargetUpdate = 'HOME_TRAINING_TRAINING_SETTING_TARGET____UPDATE',
  HomeTrainingTrainingSettingTargetCreate = 'HOME_TRAINING_TRAINING_SETTING_TARGET____CREATE',
  HomeTrainingTrainingSettingTargetDelete = 'HOME_TRAINING_TRAINING_SETTING_TARGET____DELETE',
  HomeTrainingTrainingSettingApprovalRead = 'HOME_TRAINING_TRAINING_SETTING_APPROVAL____READ',
  HomeTrainingTrainingSettingRequestApprovalRead = 'HOME_TRAINING_TRAINING_SETTING_REQUEST_APPROVAL____READ',
  HomeTrainingTrainingSettingRequestApprovalCreate = 'HOME_TRAINING_TRAINING_SETTING_REQUEST_APPROVAL____CREATE',
  HomeTrainingTrainingSettingRequestApprovalUpdate = 'HOME_TRAINING_TRAINING_SETTING_REQUEST_APPROVAL____UPDATE',
  HomeTrainingTrainingSettingRequestApprovalDelete = 'HOME_TRAINING_TRAINING_SETTING_REQUEST_APPROVAL____DELETE',
  HomeTrainingTrainingSettingNominationApprovalRead = 'HOME_TRAINING_TRAINING_SETTING_NOMINATION_APPROVAL____READ',
  HomeTrainingTrainingSettingNominationApprovalCreate = 'HOME_TRAINING_TRAINING_SETTING_NOMINATION_APPROVAL____CREATE',
  HomeTrainingTrainingSettingNominationApprovalUpdate = 'HOME_TRAINING_TRAINING_SETTING_NOMINATION_APPROVAL____UPDATE',
  HomeTrainingTrainingSettingNominationApprovalDelete = 'HOME_TRAINING_TRAINING_SETTING_NOMINATION_APPROVAL____DELETE',
  HomeTrainingTrainingSettingSelfRegistrationApprovalRead = 'HOME_TRAINING_TRAINING_SETTING_SELF_REGISTRATION_APPROVAL____READ',
  HomeTrainingTrainingSettingSelfRegistrationApprovalCreate = 'HOME_TRAINING_TRAINING_SETTING_SELF_REGISTRATION_APPROVAL____CREATE',
  HomeTrainingTrainingSettingSelfRegistrationApprovalUpdate = 'HOME_TRAINING_TRAINING_SETTING_SELF_REGISTRATION_APPROVAL____UPDATE',
  HomeTrainingTrainingSettingSelfRegistrationApprovalDelete = 'HOME_TRAINING_TRAINING_SETTING_SELF_REGISTRATION_APPROVAL____DELETE',
  HomeTrainingTrainingSettingWithdrawalApprovalRead = 'HOME_TRAINING_TRAINING_SETTING_WITHDRAWAL_APPROVAL____READ',
  HomeTrainingTrainingSettingWithdrawalApprovalCreate = 'HOME_TRAINING_TRAINING_SETTING_WITHDRAWAL_APPROVAL____CREATE',
  HomeTrainingTrainingSettingWithdrawalApprovalUpdate = 'HOME_TRAINING_TRAINING_SETTING_WITHDRAWAL_APPROVAL____UPDATE',
  HomeTrainingTrainingSettingWithdrawalApprovalDelete = 'HOME_TRAINING_TRAINING_SETTING_WITHDRAWAL_APPROVAL____DELETE',
  HomeTrainingTrainingExpensesView = 'HOME_TRAINING_TRAINING_EXPENSES____VIEW',
  HomeTrainingTrainingExpensesUpdate = 'HOME_TRAINING_TRAINING_EXPENSES____UPDATE',
  HomeTrainingTrainingMaintenanceRead = 'HOME_TRAINING_TRAINING_MAINTENANCE____READ',
  HomeTrainingTrainingMaintenanceBudgetRead = 'HOME_TRAINING_TRAINING_MAINTENANCE_BUDGET____READ',
  HomeTrainingTrainingMaintenanceBudgetUpdate = 'HOME_TRAINING_TRAINING_MAINTENANCE_BUDGET____UPDATE',
  HomeTrainingTrainingMaintenanceBudgetCreate = 'HOME_TRAINING_TRAINING_MAINTENANCE_BUDGET____CREATE',
  HomeTrainingTrainingMaintenanceBudgetDelete = 'HOME_TRAINING_TRAINING_MAINTENANCE_BUDGET____DELETE',
  HomeTrainingTrainingMaintenanceCompulsoryCoursesRead = 'HOME_TRAINING_TRAINING_MAINTENANCE_COMPULSORY_COURSES____READ',
  HomeTrainingTrainingMaintenanceCompulsoryCoursesUpdate = 'HOME_TRAINING_TRAINING_MAINTENANCE_COMPULSORY_COURSES____UPDATE',
  HomeTrainingTrainingMaintenanceTargetHoursRead = 'HOME_TRAINING_TRAINING_MAINTENANCE_TARGET_HOURS____READ',
  HomeTrainingTrainingMaintenanceTargetHoursUpdate = 'HOME_TRAINING_TRAINING_MAINTENANCE_TARGET_HOURS____UPDATE',
  HomeTrainingTrainingMaintenanceTargetHoursCreate = 'HOME_TRAINING_TRAINING_MAINTENANCE_TARGET_HOURS____CREATE',
  HomeTrainingTrainingMaintenanceTargetHoursDelete = 'HOME_TRAINING_TRAINING_MAINTENANCE_TARGET_HOURS____DELETE',
  HomeTrainingTrainingRequestRead = 'HOME_TRAINING_TRAINING_REQUEST____READ',
  HomeTrainingTrainingRequestUpdate = 'HOME_TRAINING_TRAINING_REQUEST____UPDATE',
  HomeTrainingTrainingRequestCreate = 'HOME_TRAINING_TRAINING_REQUEST____CREATE',
  HomeTrainingTrainingRequestDelete = 'HOME_TRAINING_TRAINING_REQUEST____DELETE',
  HomeTrainingTrainingRequestWithdraw = 'HOME_TRAINING_TRAINING_REQUEST____WITHDRAW',
  HomeTrainingTrainingEventScheduleRead = 'HOME_TRAINING_TRAINING_EVENT_SCHEDULE____READ',
  HomeTrainingTrainingEventScheduleUpdate = 'HOME_TRAINING_TRAINING_EVENT_SCHEDULE____UPDATE',
  HomeTrainingTrainingEventScheduleCreate = 'HOME_TRAINING_TRAINING_EVENT_SCHEDULE____CREATE',
  HomeTrainingTrainingEventAttendanceRead = 'HOME_TRAINING_TRAINING_EVENT_ATTENDANCE____READ',
  HomeTrainingTrainingEventAttendanceSave = 'HOME_TRAINING_TRAINING_EVENT_ATTENDANCE____SAVE',
  HomeTrainingTrainingEventAttendanceUpdate = 'HOME_TRAINING_TRAINING_EVENT_ATTENDANCE____UPDATE',
  HomeTrainingTrainingEvaluationRead = 'HOME_TRAINING_TRAINING_EVALUATION____READ',
  HomeTrainingTrainingEvaluationEventEvaluationRead = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION____READ',
  HomeTrainingTrainingEvaluationEventEvaluationCreate = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION____CREATE',
  HomeTrainingTrainingEvaluationEventEvaluationRevert = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION_______REVERT',
  HomeTrainingTrainingEvaluationEventEvaluationUpload = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION_______UPLOAD',
  HomeTrainingTrainingEvaluationEventEvaluationUpdate = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION_______UPDATE',
  HomeTrainingTrainingEvaluationEventEvaluationDelete = 'HOME_TRAINING_TRAINING_EVALUATION_EVENT_EVALUATION_______DELETE',
  HomeTrainingTrainingEvaluationEvaluationResultsRead = 'HOME_TRAINING_TRAINING_EVALUATION_EVALUATION_RESULTS____READ',
  HomeTrainingTrainingEvaluationEvaluationResultsUpdate = 'HOME_TRAINING_TRAINING_EVALUATION_EVALUATION_RESULTS_______UPDATE',
  HomeTrainingTrainingExpensesRead = 'HOME_TRAINING_TRAINING_EXPENSES____READ',
  HomeTrainingHrdcClaimRead = 'HOME_TRAINING_HRDC_CLAIM____READ',
  HomeTrainingHrdcClaimCreate = 'HOME_TRAINING_HRDC_CLAIM____CREATE',
  HomeTrainingHrdcClaimUpdate = 'HOME_TRAINING_HRDC_CLAIM____UPDATE',
  HomeTrainingHrdcClaimDelete = 'HOME_TRAINING_HRDC_CLAIM____DELETE',
  HomeTrainingHrdcClaimUpdateStatus = 'HOME_TRAINING_HRDC_CLAIM____UPDATE_STATUS',
  HomeTrainingHrdcClaimStatusLog = 'HOME_TRAINING_HRDC_CLAIM____STATUS_LOG',
  HomeTrainingTrainingFulfillmentRead = 'HOME_TRAINING_TRAINING_FULFILLMENT____READ',
  HomeTrainingTrainingRecordsRead = 'HOME_TRAINING_TRAINING_RECORDS____READ',
  HomeAdminExecSummaryRead = 'HOME_ADMIN_EXEC_SUMMARY____READ',
  HomeAdminExternalUserRead = 'HOME_ADMIN_EXTERNAL_USER____READ',
  HomeAdminExternalUserReadUser = 'HOME_ADMIN_EXTERNAL_USER_READ_USER',
  HomeAdminExternalUserReadEmployee = 'HOME_ADMIN_EXTERNAL_USER_READ_EMPLOYEE',
  HomeAdminExternalUserView = 'HOME_ADMIN_EXTERNAL_USER_______VIEW',
  HomeAdminExternalUserCreate = 'HOME_ADMIN_EXTERNAL_USER_______CREATE',
  HomeAdminExternalUserUpdate = 'HOME_ADMIN_EXTERNAL_USER_______UPDATE',
  HomeAdminExternalUserUnblock = 'HOME_ADMIN_EXTERNAL_USER_______UNBLOCK',
  HomeAdminExternalUserResendInvitation = 'HOME_ADMIN_EXTERNAL_USER_______RESEND_INVITATION',
  HomeAdminExternalUserChangePassword = 'HOME_ADMIN_EXTERNAL_USER_______CHANGE_PASSWORD',
  HomeAdminExternalUserCompanyAccessRead = 'HOME_ADMIN_EXTERNAL_USER_COMPANY_ACCESS_READ',
  HomeAdminExternalUserCompanyAccessUpdate = 'HOME_ADMIN_EXTERNAL_USER_COMPANY_ACCESS_UPDATE',
  HomeAdminExternalUserChangeAccessLevel = 'HOME_ADMIN_EXTERNAL_USER_CHANGE_ACCESS_LEVEL',
  HomeAdminExternalUserEmployeeView = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_VIEW',
  HomeAdminExternalUserEmployeeChangeAccessLevel = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_CHANGE_ACCESS_LEVEL',
  HomeAdminExternalUserEmployeeInactive = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_INACTIVE',
  HomeAdminExternalUserEmployeeReactivate = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_REACTIVATE',
  HomeAdminExternalUserEmployeeChangePassword = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_CHANGE_PASSWORD',
  HomeAdminExternalUserEmployeeResendInvitation = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_RESEND_INVITATION',
  HomeAdminExternalUserEmployeeHelpdeskAssignee = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_HELPDESK_ASSIGNEE',
  HomeAdminExternalUserEmployeeCompanyAccessRead = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_COMPANY_ACCESS_READ',
  HomeAdminExternalUserEmployeeCompanyAccessUpdate = 'HOME_ADMIN_EXTERNAL_USER_EMPLOYEE_COMPANY_ACCESS_UPDATE',
  HomeAdminAccessLevelRead = 'HOME_ADMIN_ACCESS_LEVEL____READ',
  HomeAdminAccessLevelView = 'HOME_ADMIN_ACCESS_LEVEL_______VIEW',
  HomeAdminAccessLevelCreate = 'HOME_ADMIN_ACCESS_LEVEL_______CREATE',
  HomeAdminAccessLevelUpdate = 'HOME_ADMIN_ACCESS_LEVEL_______UPDATE',
  HomeAdminAccessLevelDelete = 'HOME_ADMIN_ACCESS_LEVEL_______DELETE',
  HomeAdminEmployeeAppSecurityRead = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY____READ',
  HomeAdminEmployeeAppSecurityView = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______VIEW',
  HomeAdminEmployeeAppSecurityCreate = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______CREATE',
  HomeAdminEmployeeAppSecurityUpdate = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______UPDATE',
  HomeAdminEmployeeAppSecurityDelete = 'HOME_ADMIN_EMPLOYEE_APP_SECURITY_______DELETE',
  HomePersonnelExecSummaryRead = 'HOME_PERSONNEL_EXEC_SUMMARY____READ',
  HomePersonnelRead = 'HOME_PERSONNEL_______READ',
  HomeAdminBulletinRead = 'HOME_ADMIN_BULLETIN______READ',
  HomeAdminBulletinCreate = 'HOME_ADMIN_BULLETIN______CREATE',
  HomeAdminBulletinUpdate = 'HOME_ADMIN_BULLETIN______UPDATE',
  HomeAdminBulletinDelete = 'HOME_ADMIN_BULLETIN______DELETE',
  HomeAdminBulletinPublish = 'HOME_ADMIN_BULLETIN______PUBLISH',
  HomeAdminBulletinUnpublish = 'HOME_ADMIN_BULLETIN______UNPUBLISH',
  HomeAdminBulletinDuplicate = 'HOME_ADMIN_BULLETIN______DUPLICATE',
  HomeAdminBulletinAcknowledgedList = 'HOME_ADMIN_BULLETIN______ACKNOWLEDGED_LIST',
  HomeAdminReportingStructureGroupRead = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______READ',
  HomeAdminReportingStructureGroupCreate = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______CREATE',
  HomeAdminReportingStructureGroupUpdate = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______UPDATE',
  HomeAdminReportingStructureGroupDelete = 'HOME_ADMIN_REPORTING_STRUCTURE_GROUP______DELETE',
  CommonSettingPersonnelRead = 'COMMON_SETTING_PERSONNEL_______READ',
  CommonSettingPersonnelPersonnelParameterRead = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___READ',
  CommonSettingPersonnelPersonnelParameterCreate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___CREATE',
  CommonSettingPersonnelPersonnelParameterUpdate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___UPDATE',
  CommonSettingPersonnelPersonnelParameterDelete = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___DELETE',
  CommonSettingPersonnelPersonnelParameterInactive = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___INACTIVE',
  CommonSettingPersonnelPersonnelParameterReactivate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_PARAMETER___REACTIVATE',
  CommonSettingPersonnelJobGradeRead = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___READ',
  CommonSettingPersonnelJobGradeCreate = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___CREATE',
  CommonSettingPersonnelJobGradeUpdate = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___UPDATE',
  CommonSettingPersonnelJobGradeDelete = 'COMMON_SETTING_PERSONNEL_JOB_GRADE___DELETE',
  CommonSettingPersonnelSkillSetupRead = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___READ',
  CommonSettingPersonnelSkillSetupCreate = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___CREATE',
  CommonSettingPersonnelSkillSetupUpdate = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___UPDATE',
  CommonSettingPersonnelSkillSetupDelete = 'COMMON_SETTING_PERSONNEL_SKILL_SETUP___DELETE',
  CommonSettingPersonnelJobPositionRead = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___READ',
  CommonSettingPersonnelJobPositionCreate = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___CREATE',
  CommonSettingPersonnelJobPositionUpdate = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___UPDATE',
  CommonSettingPersonnelJobPositionDelete = 'COMMON_SETTING_PERSONNEL_JOB_POSITION___DELETE',
  CommonSettingPersonnelInsuranceSchemeRead = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___READ',
  CommonSettingPersonnelInsuranceSchemeCreate = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___CREATE',
  CommonSettingPersonnelInsuranceSchemeUpdate = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___UPDATE',
  CommonSettingPersonnelInsuranceSchemeDelete = 'COMMON_SETTING_PERSONNEL_INSURANCE_SCHEME___DELETE',
  CommonSettingPersonnelRecruitmentSourceRead = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___READ',
  CommonSettingPersonnelRecruitmentSourceCreate = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___CREATE',
  CommonSettingPersonnelRecruitmentSourceUpdate = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___UPDATE',
  CommonSettingPersonnelRecruitmentSourceDelete = 'COMMON_SETTING_PERSONNEL_RECRUITMENT_SOURCE___DELETE',
  CommonSettingPersonnelUserDefinedRead = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___READ',
  CommonSettingPersonnelUserDefinedCreate = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___CREATE',
  CommonSettingPersonnelUserDefinedUpdate = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___UPDATE',
  CommonSettingPersonnelUserDefinedDelete = 'COMMON_SETTING_PERSONNEL_USER_DEFINED___DELETE',
  CommonSettingPersonnelELetterTemplateRead = 'COMMON_SETTING_PERSONNEL_E_LETTER_TEMPLATE___READ',
  CommonSettingPersonnelELetterTemplateUpdate = 'COMMON_SETTING_PERSONNEL_E_LETTER_TEMPLATE___UPDATE',
  CompanySettingPersonnelRead = 'COMPANY_SETTING_PERSONNEL_______READ',
  CompanySettingPersonnelInsuranceSchemeRead = 'COMPANY_SETTING_PERSONNEL_INSURANCE_SCHEME___READ',
  CompanySettingPersonnelInsuranceSchemeUpdate = 'COMPANY_SETTING_PERSONNEL_INSURANCE_SCHEME___UPDATE',
  CompanySettingPersonnelRecruitmentSourceRead = 'COMPANY_SETTING_PERSONNEL_RECRUITMENT_SOURCE___READ',
  CompanySettingPersonnelRecruitmentSourceUpdate = 'COMPANY_SETTING_PERSONNEL_RECRUITMENT_SOURCE___UPDATE',
  CompanySettingPersonnelUserDefinedRead = 'COMPANY_SETTING_PERSONNEL_USER_DEFINED___READ',
  CompanySettingPersonnelUserDefinedUpdate = 'COMPANY_SETTING_PERSONNEL_USER_DEFINED___UPDATE',
  CompanySettingPersonnelCostCenterMappingRead = 'COMPANY_SETTING_PERSONNEL_COST_CENTER_MAPPING___READ',
  CompanySettingPersonnelCostCenterMappingUpdate = 'COMPANY_SETTING_PERSONNEL_COST_CENTER_MAPPING___UPDATE',
  CompanySettingPersonnelELetterTemplateRead = 'COMPANY_SETTING_PERSONNEL_E_LETTER_TEMPLATE___READ',
  CompanySettingPersonnelELetterTemplatedUpdate = 'COMPANY_SETTING_PERSONNEL_E_LETTER_TEMPLATED___UPDATE',
  CompanySettingPersonnelEmployeeUserDefineFieldsRead = 'COMPANY_SETTING_PERSONNEL_EMPLOYEE_USER_DEFINE_FIELDS_READ',
  CompanySettingPersonnelEmployeeUserDefineFieldsUpdate = 'COMPANY_SETTING_PERSONNEL_EMPLOYEE_USER_DEFINE_FIELDS_UPDATE',
  HomePersonnelPersonnelEFileRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE____READ',
  HomePersonnelPersonnelEFilePersonnelProfileRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_PERSONNEL_PROFILE___READ',
  HomePersonnelPersonnelEFilePersonnelProfileCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_PERSONNEL_PROFILE___CREATE',
  HomePersonnelPersonnelEFilePersonnelProfileUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_PERSONNEL_PROFILE___UPDATE',
  HomePersonnelPersonnelEFileEducationRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___READ',
  HomePersonnelPersonnelEFileEducationCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___CREATE',
  HomePersonnelPersonnelEFileEducationUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___UPDATE',
  HomePersonnelPersonnelEFileEducationDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_EDUCATION___DELETE',
  HomePersonnelPersonnelEFileCertificateRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___READ',
  HomePersonnelPersonnelEFileCertificateCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___CREATE',
  HomePersonnelPersonnelEFileCertificateUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___UPDATE',
  HomePersonnelPersonnelEFileCertificateDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_CERTIFICATE___DELETE',
  HomePersonnelPersonnelEFileReferencesRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___READ',
  HomePersonnelPersonnelEFileReferencesCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___CREATE',
  HomePersonnelPersonnelEFileReferencesUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___UPDATE',
  HomePersonnelPersonnelEFileReferencesDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_REFERENCES___DELETE',
  HomePersonnelPersonnelEFileHealthInfoRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_HEALTH_INFO___READ',
  HomePersonnelPersonnelEFileHealthInfoUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_HEALTH_INFO___UPDATE',
  HomePersonnelPersonnelEFileDependentRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___READ',
  HomePersonnelPersonnelEFileDependentCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___CREATE',
  HomePersonnelPersonnelEFileDependentUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___UPDATE',
  HomePersonnelPersonnelEFileDependentDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DEPENDENT___DELETE',
  HomePersonnelPersonnelEFileJobHistoryRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__READ',
  HomePersonnelPersonnelEFileJobHistoryCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__CREATE',
  HomePersonnelPersonnelEFileJobHistoryUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__UPDATE',
  HomePersonnelPersonnelEFileJobHistoryDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_JOB_HISTORY__DELETE',
  HomePersonnelPersonnelEFileUserDefinedRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_USER_DEFINED___READ',
  HomePersonnelPersonnelEFileUserDefinedUpdate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_USER_DEFINED___UPDATE',
  HomePersonnelPersonnelEFileDocumentsRead = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DOCUMENTS___READ',
  HomePersonnelPersonnelEFileDocumentsCreate = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DOCUMENTS___CREATE',
  HomePersonnelPersonnelEFileDocumentsDelete = 'HOME_PERSONNEL_PERSONNEL_E_FILE_DOCUMENTS___DELETE',
  HomePersonnelEmployeeInfoCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO____CREATE',
  HomePersonnelEmployeeInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO____READ',
  HomePersonnelEmployeeInfoJobInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_JOB_INFO_READ',
  HomePersonnelEmployeeInfoJobInfoUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_JOB_INFO_UPDATE',
  HomePersonnelEmployeeInfoPersonalInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERSONAL_INFO_READ',
  HomePersonnelEmployeeInfoApprovalDelegationRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_APPROVAL_DELEGATION_READ',
  HomePersonnelEmployeeInfoApprovalDelegationCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_APPROVAL_DELEGATION_CREATE',
  HomePersonnelEmployeeInfoApprovalDelegationUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_APPROVAL_DELEGATION_UPDATE',
  HomePersonnelEmployeeInfoApprovalDelegationDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_APPROVAL_DELEGATION_DELETE',
  HomePersonnelEmployeeInfoPersonalInfoCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERSONAL_INFO_CREATE',
  HomePersonnelEmployeeInfoStatutoryInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_STATUTORY_INFO_READ',
  HomePersonnelEmployeeInfoStatutoryInfoUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_STATUTORY_INFO_UPDATE',
  HomePersonnelEmployeeInfoZakatContributionRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_READ',
  HomePersonnelEmployeeInfoZakatContributionCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_CREATE',
  HomePersonnelEmployeeInfoZakatContributionUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_UPDATE',
  HomePersonnelEmployeeInfoZakatContributionDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_ZAKAT_CONTRIBUTION_DELETE',
  HomePersonnelEmployeeInfoTp3Read = 'HOME_PERSONNEL_EMPLOYEE_INFO_TP3_READ',
  HomePersonnelEmployeeInfoTp3Update = 'HOME_PERSONNEL_EMPLOYEE_INFO_TP3_UPDATE',
  HomePersonnelEmployeeInfoAssetAssignedRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____READ',
  HomePersonnelEmployeeInfoAssetAssignedCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____CREATE',
  HomePersonnelEmployeeInfoAssetAssignedUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____UPDATE',
  HomePersonnelEmployeeInfoAssetAssignedDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_ASSET_ASSIGNED____DELETE',
  HomePersonnelEmployeeInfoPerformanceJournalRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____READ',
  HomePersonnelEmployeeInfoPerformanceJournalCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____CREATE',
  HomePersonnelEmployeeInfoPerformanceJournalUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____UPDATE',
  HomePersonnelEmployeeInfoPerformanceJournalDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERFORMANCE_JOURNAL____DELETE',
  HomePersonnelEmployeeInfoInsuranceSchemeRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_INSURANCE_SCHEME____READ',
  HomePersonnelEmployeeInfoCareerMovementRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_CAREER_MOVEMENT____READ',
  HomePersonnelEmployeeInfoSubordinateRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_SUBORDINATE____READ',
  HomePersonnelEmployeeInfoDigitalDocumentRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_DIGITAL_DOCUMENT____READ',
  HomePersonnelEmployeeInfoGeneralDocumentRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_GENERAL_DOCUMENT____READ',
  HomePersonnelEmployeeInfoContractDocumentRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____READ',
  HomePersonnelEmployeeInfoContractDocumentCreate = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____CREATE',
  HomePersonnelEmployeeInfoContractDocumentUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____UPDATE',
  HomePersonnelEmployeeInfoContractDocumentDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_CONTRACT_DOCUMENT____DELETE',
  HomePersonnelEmployeeInfoPaySlipRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_PAY_SLIP____READ',
  HomePersonnelEmployeeInfoEaFormRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_EA_FORM____READ',
  HomePersonnelEmployeeInfoELetterRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_E_LETTER____READ',
  HomePersonnelEmployeeInfoELetterDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_E_LETTER____DELETE',
  HomePersonnelEmployeeInfoEmployeeUserDefineFieldsRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_EMPLOYEE_USER_DEFINE_FIELDS_READ',
  HomePersonnelEmployeeInfoEmployeeUserDefineFieldsUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_EMPLOYEE_USER_DEFINE_FIELDS_UPDATE',
  HomePersonnelStaffConfirmationRead = 'HOME_PERSONNEL_STAFF_CONFIRMATION____READ',
  HomePersonnelStaffConfirmationUpdate = 'HOME_PERSONNEL_STAFF_CONFIRMATION____UPDATE',
  HomePersonnelProbationExtensionRead = 'HOME_PERSONNEL_PROBATION_EXTENSION____READ',
  HomePersonnelStaffPromotionRead = 'HOME_PERSONNEL_STAFF_PROMOTION____READ',
  HomePersonnelStaffPromotionCreate = 'HOME_PERSONNEL_STAFF_PROMOTION____CREATE',
  HomePersonnelStaffPromotionUpdate = 'HOME_PERSONNEL_STAFF_PROMOTION____UPDATE',
  HomePersonnelStaffPromotionDelete = 'HOME_PERSONNEL_STAFF_PROMOTION____DELETE',
  HomePersonnelStaffDemotionRead = 'HOME_PERSONNEL_STAFF_DEMOTION____READ',
  HomePersonnelStaffDemotionCreate = 'HOME_PERSONNEL_STAFF_DEMOTION____CREATE',
  HomePersonnelStaffDemotionUpdate = 'HOME_PERSONNEL_STAFF_DEMOTION____UPDATE',
  HomePersonnelStaffDemotionDelete = 'HOME_PERSONNEL_STAFF_DEMOTION____DELETE',
  HomePersonnelStaffRedesignationRead = 'HOME_PERSONNEL_STAFF_REDESIGNATION____READ',
  HomePersonnelStaffRedesignationCreate = 'HOME_PERSONNEL_STAFF_REDESIGNATION____CREATE',
  HomePersonnelStaffRedesignationUpdate = 'HOME_PERSONNEL_STAFF_REDESIGNATION____UPDATE',
  HomePersonnelStaffRedesignationDelete = 'HOME_PERSONNEL_STAFF_REDESIGNATION____DELETE',
  HomePersonnelStaffInternalTransferRead = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____READ',
  HomePersonnelStaffInternalTransferCreate = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____CREATE',
  HomePersonnelStaffInternalTransferUpdate = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____UPDATE',
  HomePersonnelStaffInternalTransferDelete = 'HOME_PERSONNEL_STAFF_INTERNAL_TRANSFER____DELETE',
  HomePersonnelStaffInterCompanyTransferRead = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____READ',
  HomePersonnelStaffInterCompanyTransferCreate = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____CREATE',
  HomePersonnelStaffInterCompanyTransferUpdate = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____UPDATE',
  HomePersonnelStaffInterCompanyTransferDelete = 'HOME_PERSONNEL_STAFF_INTER_COMPANY_TRANSFER____DELETE',
  HomePersonnelStaffTerminationRead = 'HOME_PERSONNEL_STAFF_TERMINATION____READ',
  HomePersonnelStaffTerminationCreate = 'HOME_PERSONNEL_STAFF_TERMINATION____CREATE',
  HomePersonnelStaffTerminationUpdate = 'HOME_PERSONNEL_STAFF_TERMINATION____UPDATE',
  HomePersonnelStaffTerminationDelete = 'HOME_PERSONNEL_STAFF_TERMINATION____DELETE',
  HomePersonnelStaffOffboardingRead = 'HOME_PERSONNEL_STAFF_OFFBOARDING____READ',
  HomePersonnelStaffOffboardingCreate = 'HOME_PERSONNEL_STAFF_OFFBOARDING____CREATE',
  HomePersonnelStaffOffboardingUpdate = 'HOME_PERSONNEL_STAFF_OFFBOARDING____UPDATE',
  HomePersonnelStaffOffboardingDelete = 'HOME_PERSONNEL_STAFF_OFFBOARDING____DELETE',
  HomePersonnelStaffRetirementRead = 'HOME_PERSONNEL_STAFF_RETIREMENT____READ',
  HomePersonnelStaffRetirementUpdate = 'HOME_PERSONNEL_STAFF_RETIREMENT____UPDATE',
  HomePersonnelStaffRetirementDelete = 'HOME_PERSONNEL_STAFF_RETIREMENT____DELETE',
  HomePersonnelStaffInsuranceSchemeRead = 'HOME_PERSONNEL_STAFF_INSURANCE_SCHEME____READ',
  HomePersonnelStaffInsuranceSchemeCreate = 'HOME_PERSONNEL_STAFF_INSURANCE_SCHEME____CREATE',
  HomePersonnelStaffInsuranceSchemeUpdate = 'HOME_PERSONNEL_STAFF_INSURANCE_SCHEME____UPDATE',
  HomePayrollExecSummaryRead = 'HOME_PAYROLL_EXEC_SUMMARY____READ',
  HomePayrollRead = 'HOME_PAYROLL_______READ',
  CommonSettingPayrollRead = 'COMMON_SETTING_PAYROLL_______READ',
  CommonSettingPayrollPayItemRead = 'COMMON_SETTING_PAYROLL_PAY_ITEM___READ',
  CommonSettingPayrollPayItemCreate = 'COMMON_SETTING_PAYROLL_PAY_ITEM___CREATE',
  CommonSettingPayrollPayItemUpdate = 'COMMON_SETTING_PAYROLL_PAY_ITEM___UPDATE',
  CommonSettingPayrollPayItemDelete = 'COMMON_SETTING_PAYROLL_PAY_ITEM___DELETE',
  CommonSettingPayrollLhdnReportsRead = 'COMMON_SETTING_PAYROLL_LHDN_REPORTS___READ',
  CommonSettingPayrollLhdnReportsUpdate = 'COMMON_SETTING_PAYROLL_LHDN_REPORTS___UPDATE',
  CommonSettingPayrollLhdnReportsCopy = 'COMMON_SETTING_PAYROLL_LHDN_REPORTS___COPY',
  CommonSettingPayrollPayrollCycleRead = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___READ',
  CommonSettingPayrollPayrollCycleCreate = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___CREATE',
  CommonSettingPayrollPayrollCycleUpdate = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___UPDATE',
  CommonSettingPayrollPayrollCycleDelete = 'COMMON_SETTING_PAYROLL_PAYROLL_CYCLE___DELETE',
  CommonSettingPayrollAdvancePolicyRead = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___READ',
  CommonSettingPayrollAdvancePolicyCreate = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___CREATE',
  CommonSettingPayrollAdvancePolicyUpdate = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___UPDATE',
  CommonSettingPayrollAdvancePolicyDelete = 'COMMON_SETTING_PAYROLL_ADVANCE_POLICY___DELETE',
  CommonSettingPayrollGlAccountMappingRead = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___READ',
  CommonSettingPayrollGlAccountMappingCreate = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___CREATE',
  CommonSettingPayrollGlAccountMappingUpdate = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___UPDATE',
  CommonSettingPayrollGlAccountMappingDelete = 'COMMON_SETTING_PAYROLL_GL_ACCOUNT_MAPPING___DELETE',
  CommonSettingPayrollUserDefineEpfPolicyRead = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_READ',
  CommonSettingPayrollUserDefineEpfPolicyCreate = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_CREATE',
  CommonSettingPayrollUserDefineEpfPolicyUpdate = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_UPDATE',
  CommonSettingPayrollUserDefineEpfPolicyDelete = 'COMMON_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_DELETE',
  CommonSettingPayrollPayGroupRead = 'COMMON_SETTING_PAYROLL_PAY_GROUP_READ',
  CommonSettingPayrollPayGroupCreate = 'COMMON_SETTING_PAYROLL_PAY_GROUP_CREATE',
  CommonSettingPayrollPayGroupUpdate = 'COMMON_SETTING_PAYROLL_PAY_GROUP_UPDATE',
  CommonSettingPayrollPayGroupDelete = 'COMMON_SETTING_PAYROLL_PAY_GROUP_DELETE',
  CompanySettingPayrollUserDefineEpfPolicyMatrixRead = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_READ',
  CompanySettingPayrollUserDefineEpfPolicyMatrixCreate = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_CREATE',
  CompanySettingPayrollUserDefineEpfPolicyMatrixUpdate = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_UPDATE',
  CompanySettingPayrollUserDefineEpfPolicyMatrixDelete = 'COMPANY_SETTING_PAYROLL_USER_DEFINE_EPF_POLICY_MATRIX_DELETE',
  CompanySettingPayrollRead = 'COMPANY_SETTING_PAYROLL_______READ',
  CompanySettingPayrollCutoffDateUpdate = 'COMPANY_SETTING_PAYROLL_CUTOFF_DATE____UPDATE',
  CompanySettingPayrollPayrollCalendarRead = 'COMPANY_SETTING_PAYROLL_PAYROLL_CALENDAR____READ',
  CompanySettingPayrollPayrollCalendarUpdate = 'COMPANY_SETTING_PAYROLL_PAYROLL_CALENDAR____UPDATE',
  CompanySettingPayrollPayrollSettingRead = 'COMPANY_SETTING_PAYROLL_PAYROLL_SETTING____READ',
  CompanySettingPayrollPayItemRead = 'COMPANY_SETTING_PAYROLL_PAY_ITEM____READ',
  CompanySettingPayrollPayItemUpdate = 'COMPANY_SETTING_PAYROLL_PAY_ITEM____UPDATE',
  CompanySettingPayrollPayItemDelete = 'COMPANY_SETTING_PAYROLL_PAY_ITEM____DELETE',
  CompanySettingPayrollPayrollCycleRead = 'COMPANY_SETTING_PAYROLL_PAYROLL_CYCLE____READ',
  CompanySettingPayrollPayrollCycleUpdate = 'COMPANY_SETTING_PAYROLL_PAYROLL_CYCLE____UPDATE',
  CompanySettingPayrollAdvancePolicyRead = 'COMPANY_SETTING_PAYROLL_ADVANCE_POLICY____READ',
  CompanySettingPayrollAdvancePolicyUpdate = 'COMPANY_SETTING_PAYROLL_ADVANCE_POLICY____UPDATE',
  CompanySettingPayrollGlAccountAssignmentRead = 'COMPANY_SETTING_PAYROLL_GL_ACCOUNT_ASSIGNMENT____READ',
  CompanySettingPayrollGlAccountAssignmentUpdate = 'COMPANY_SETTING_PAYROLL_GL_ACCOUNT_ASSIGNMENT____UPDATE',
  CompanySettingPayrollBankCreate = 'COMPANY_SETTING_PAYROLL_BANK____CREATE',
  CompanySettingPayrollBankRead = 'COMPANY_SETTING_PAYROLL_BANK____READ',
  CompanySettingPayrollBankUpdate = 'COMPANY_SETTING_PAYROLL_BANK____UPDATE',
  CompanySettingPayrollBankDelete = 'COMPANY_SETTING_PAYROLL_BANK____DELETE',
  CompanySettingPayrollBankInterbankCreate = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_CREATE',
  CompanySettingPayrollBankInterbankRead = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_READ',
  CompanySettingPayrollBankInterbankUpdate = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_UPDATE',
  CompanySettingPayrollBankInterbankDelete = 'COMPANY_SETTING_PAYROLL_BANK_INTERBANK_DELETE',
  CompanySettingPayrollPayGroupRead = 'COMPANY_SETTING_PAYROLL_PAY_GROUP___READ',
  CompanySettingPayrollPayGroupUpdate = 'COMPANY_SETTING_PAYROLL_PAY_GROUP___UPDATE',
  HomePayrollEmployeeSalaryRead = 'HOME_PAYROLL_EMPLOYEE_SALARY____READ',
  HomePayrollEmployeeSalarySalaryRecordsRead = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____READ',
  HomePayrollEmployeeSalarySalaryRecordsCreate = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____CREATE',
  HomePayrollEmployeeSalarySalaryRecordsUpdate = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____UPDATE',
  HomePayrollEmployeeSalarySalaryRecordsDelete = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_RECORDS____DELETE',
  HomePayrollEmployeeSalaryExcelUploadRead = 'HOME_PAYROLL_EMPLOYEE_SALARY_EXCEL_UPLOAD_READ',
  HomePayrollEmployeeSalaryExcelUploadUpload = 'HOME_PAYROLL_EMPLOYEE_SALARY_EXCEL_UPLOAD_UPLOAD',
  HomePayrollEmployeeSalaryExcelUploadDownload = 'HOME_PAYROLL_EMPLOYEE_SALARY_EXCEL_UPLOAD_DOWNLOAD',
  HomePayrollEmployeeSalarySalaryAdjustmentRead = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_ADJUSTMENT_READ',
  HomePayrollEmployeeSalarySalaryAdjustmentDownload = 'HOME_PAYROLL_EMPLOYEE_SALARY_SALARY_ADJUSTMENT_DOWNLOAD',
  HomePayrollGlExportRead = 'HOME_PAYROLL_GL_EXPORT____READ',
  HomePayrollGlExportDownload = 'HOME_PAYROLL_GL_EXPORT____DOWNLOAD',
  HomePayrollMonthlyRemunerationRead = 'HOME_PAYROLL_MONTHLY_REMUNERATION____READ',
  HomePayrollMonthlyRemunerationUpdate = 'HOME_PAYROLL_MONTHLY_REMUNERATION____UPDATE',
  HomePayrollBikRead = 'HOME_PAYROLL_BIK____READ',
  HomePayrollBikUpdate = 'HOME_PAYROLL_BIK____UPDATE',
  HomePayrollTaxReliefCreate = 'HOME_PAYROLL_TAX_RELIEF____CREATE',
  HomePayrollTaxReliefRead = 'HOME_PAYROLL_TAX_RELIEF____READ',
  HomePayrollTaxReliefUpdate = 'HOME_PAYROLL_TAX_RELIEF____UPDATE',
  HomePayrollOtherPayrollRelatedRead = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED____READ',
  HomePayrollOtherPayrollRelatedAdvanceProcessingRead = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED_ADVANCE_PROCESSING____READ',
  HomePayrollOtherPayrollRelatedAdvanceProcessingPost = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED_ADVANCE_PROCESSING____POST',
  HomePayrollOtherPayrollRelatedAdvanceProcessingRevert = 'HOME_PAYROLL_OTHER_PAYROLL_RELATED_ADVANCE_PROCESSING____REVERT',
  HomePayrollFixedAllowanceDeductionRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION____READ',
  HomePayrollFixedAllowanceDeductionManualEntryCreate = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_CREATE',
  HomePayrollFixedAllowanceDeductionManualEntryRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_READ',
  HomePayrollFixedAllowanceDeductionManualEntryUpdate = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_UPDATE',
  HomePayrollFixedAllowanceDeductionManualEntryDelete = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_DELETE',
  HomePayrollFixedAllowanceDeductionExcelUploadRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_READ',
  HomePayrollFixedAllowanceDeductionExcelUploadUpload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_UPLOAD',
  HomePayrollFixedAllowanceDeductionExcelUploadDownload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_DOWNLOAD',
  HomePayrollFixedAllowanceDeductionExportDataDownload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXPORT_DATA_DOWNLOAD',
  HomePayrollMonthlyTrxRead = 'HOME_PAYROLL_MONTHLY_TRX____READ',
  HomePayrollMonthlyTrxByEmployeeCreate = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_CREATE',
  HomePayrollMonthlyTrxByEmployeeRead = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_READ',
  HomePayrollMonthlyTrxByEmployeeUpdate = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_UPDATE',
  HomePayrollMonthlyTrxByEmployeeDelete = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_DELETE',
  HomePayrollMonthlyTrxByPayitemCreate = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_CREATE',
  HomePayrollMonthlyTrxByPayitemRead = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_READ',
  HomePayrollMonthlyTrxByPayitemUpdate = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_UPDATE',
  HomePayrollMonthlyTrxByPayitemDelete = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_DELETE',
  HomePayrollMonthlyTrxExcelUploadRead = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_READ',
  HomePayrollMonthlyTrxExcelUploadUpload = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_UPLOAD',
  HomePayrollMonthlyTrxExcelUploadDownload = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_DOWNLOAD',
  HomePayrollMonthlyTrxExportDataDownload = 'HOME_PAYROLL_MONTHLY_TRX_EXPORT_DATA_DOWNLOAD',
  HomePayrollFreezePayrollRead = 'HOME_PAYROLL_FREEZE_PAYROLL____READ',
  HomePayrollFreezePayrollCreate = 'HOME_PAYROLL_FREEZE_PAYROLL____CREATE',
  HomePayrollFreezePayrollDelete = 'HOME_PAYROLL_FREEZE_PAYROLL____DELETE',
  HomePayrollPayrollProcessRead = 'HOME_PAYROLL_PAYROLL_PROCESS____READ',
  HomePayrollPayrollProcessProcess = 'HOME_PAYROLL_PAYROLL_PROCESS____PROCESS',
  HomePayrollPayrollProcessDelete = 'HOME_PAYROLL_PAYROLL_PROCESS____DELETE',
  HomePayrollShopeeImportPayrollProcessRead = 'HOME_PAYROLL_SHOPEE_IMPORT_PAYROLL_PROCESS____READ',
  HomePayrollShopeeImportPayrollProcessImport = 'HOME_PAYROLL_SHOPEE_IMPORT_PAYROLL_PROCESS____IMPORT',
  HomePayrollShopeeImportPayrollProcessDelete = 'HOME_PAYROLL_SHOPEE_IMPORT_PAYROLL_PROCESS____DELETE',
  HomePayrollPayrollClosingRead = 'HOME_PAYROLL_PAYROLL_CLOSING____READ',
  HomePayrollPayrollClosingLock = 'HOME_PAYROLL_PAYROLL_CLOSING____LOCK',
  HomePayrollPayrollClosingUnlock = 'HOME_PAYROLL_PAYROLL_CLOSING____UNLOCK',
  HomePayrollReadPayrollHistoryRead = 'HOME_PAYROLL_READ_PAYROLL_HISTORY____READ',
  HomePayrollReadPayrollHistoryDelete = 'HOME_PAYROLL_READ_PAYROLL_HISTORY____DELETE',
  HomePayrollPublishPayslipRead = 'HOME_PAYROLL_PUBLISH_PAYSLIP____READ',
  HomePayrollPublishPayslipPublish = 'HOME_PAYROLL_PUBLISH_PAYSLIP____PUBLISH',
  HomePayrollStatutoryAdjustmentRead = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_READ',
  HomePayrollStatutoryAdjustmentUpdate = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_UPDATE',
  HomePayrollStatutoryAdjustmentCreate = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_CREATE',
  HomePayrollStatutoryAdjustmentDelete = 'HOME_PAYROLL_STATUTORY_ADJUSTMENT_DELETE',
  HomePayrollPublishEaformRead = 'HOME_PAYROLL_PUBLISH_EAFORM____READ',
  HomePayrollPublishEaformPublish = 'HOME_PAYROLL_PUBLISH_EAFORM____PUBLISH',
  HomePayrollPublishEaformUnpublish = 'HOME_PAYROLL_PUBLISH_EAFORM____UNPUBLISH',
  HomePayrollPcbcp39PaymentRead = 'HOME_PAYROLL_PCBCP39_PAYMENT____READ',
  HomePayrollPayrollInitiationRead = 'HOME_PAYROLL_PAYROLL_INITIATION____READ',
  HomePayrollPcbcp39PaymentUpdate = 'HOME_PAYROLL_PCBCP39_PAYMENT____UPDATE',
  HomePayrollPayrollInitiationInitiate = 'HOME_PAYROLL_PAYROLL_INITIATION____INITIATE',
  HomeLeaveExecSummaryRead = 'HOME_LEAVE_EXEC_SUMMARY____READ',
  HomeLeaveRead = 'HOME_LEAVE____READ',
  CommonSettingLeaveRead = 'COMMON_SETTING_LEAVE_______READ',
  CommonSettingLeaveLeaveReasonRead = 'COMMON_SETTING_LEAVE_LEAVE_REASON___READ',
  CommonSettingLeaveLeaveReasonCreate = 'COMMON_SETTING_LEAVE_LEAVE_REASON___CREATE',
  CommonSettingLeaveLeaveReasonUpdate = 'COMMON_SETTING_LEAVE_LEAVE_REASON___UPDATE',
  CommonSettingLeaveLeaveReasonInactive = 'COMMON_SETTING_LEAVE_LEAVE_REASON___INACTIVE',
  CommonSettingLeaveLeaveReasonReactivate = 'COMMON_SETTING_LEAVE_LEAVE_REASON___REACTIVATE',
  CommonSettingLeaveDiseaseCodeRead = 'COMMON_SETTING_LEAVE_DISEASE_CODE___READ',
  CommonSettingLeaveDiseaseCodeCreate = 'COMMON_SETTING_LEAVE_DISEASE_CODE___CREATE',
  CommonSettingLeaveDiseaseCodeUpdate = 'COMMON_SETTING_LEAVE_DISEASE_CODE___UPDATE',
  CommonSettingLeaveDiseaseCodeInactive = 'COMMON_SETTING_LEAVE_DISEASE_CODE___INACTIVE',
  CommonSettingLeaveDiseaseCodeReactivate = 'COMMON_SETTING_LEAVE_DISEASE_CODE___REACTIVATE',
  CommonSettingLeaveLeaveTypeRead = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___READ',
  CommonSettingLeaveLeaveTypeCreate = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___CREATE',
  CommonSettingLeaveLeaveTypeUpdate = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___UPDATE',
  CommonSettingLeaveLeaveTypeDelete = 'COMMON_SETTING_LEAVE_LEAVE_TYPE___DELETE',
  CommonSettingLeaveLeavePolicyRead = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___READ',
  CommonSettingLeaveLeavePolicyView = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___VIEW',
  CommonSettingLeaveLeavePolicyCreate = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___CREATE',
  CommonSettingLeaveLeavePolicyUpdate = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___UPDATE',
  CommonSettingLeaveLeavePolicyDelete = 'COMMON_SETTING_LEAVE_LEAVE_POLICY___DELETE',
  CommonSettingLeaveLeaveRoundingRead = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___READ',
  CommonSettingLeaveLeaveRoundingView = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___VIEW',
  CommonSettingLeaveLeaveRoundingCreate = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___CREATE',
  CommonSettingLeaveLeaveRoundingUpdate = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___UPDATE',
  CommonSettingLeaveLeaveRoundingDelete = 'COMMON_SETTING_LEAVE_LEAVE_ROUNDING___DELETE',
  CommonSettingLeaveLeaveApprovalPolicyRead = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___READ',
  CommonSettingLeaveLeaveApprovalPolicyCreate = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___CREATE',
  CommonSettingLeaveLeaveApprovalPolicyUpdate = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___UPDATE',
  CommonSettingLeaveLeaveApprovalPolicyDelete = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___DELETE',
  CommonSettingLeaveLeaveApprovalPolicyInactive = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___INACTIVE',
  CommonSettingLeaveLeaveApprovalPolicyReactivate = 'COMMON_SETTING_LEAVE_LEAVE_APPROVAL_POLICY___REACTIVATE',
  CompanySettingLeaveRead = 'COMPANY_SETTING_LEAVE_______READ',
  CompanySettingLeaveLeaveJobGradePolicyMatrixRead = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___READ',
  CompanySettingLeaveLeaveJobGradePolicyMatrixUpdate = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___UPDATE',
  CompanySettingLeaveLeaveJobGradePolicyMatrixCreate = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___CREATE',
  CompanySettingLeaveLeaveJobGradePolicyMatrixDelete = 'COMPANY_SETTING_LEAVE_LEAVE_JOB_GRADE_POLICY_MATRIX___DELETE',
  CompanySettingLeaveLeaveSettingRead = 'COMPANY_SETTING_LEAVE_LEAVE_SETTING___READ',
  CompanySettingLeaveLeaveSettingUpdate = 'COMPANY_SETTING_LEAVE_LEAVE_SETTING___UPDATE',
  CompanySettingLeaveCompulsoryLeaveDeductionRead = 'COMPANY_SETTING_LEAVE_COMPULSORY_LEAVE_DEDUCTION___READ',
  CompanySettingLeaveCompulsoryLeaveDeductionUpdate = 'COMPANY_SETTING_LEAVE_COMPULSORY_LEAVE_DEDUCTION___UPDATE',
  CompanySettingLeaveOnBehalfPolicyRead = 'COMPANY_SETTING_LEAVE_ON_BEHALF_POLICY___READ',
  CompanySettingLeaveOnBehalfPolicyUpdate = 'COMPANY_SETTING_LEAVE_ON_BEHALF_POLICY___UPDATE',
  CompanySettingLeaveLeaveApprovalPolicyAssignmentRead = 'COMPANY_SETTING_LEAVE_LEAVE_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingLeaveLeaveApprovalPolicyAssignmentCreate = 'COMPANY_SETTING_LEAVE_LEAVE_APPROVAL_POLICY_ASSIGNMENT___CREATE',
  CompanySettingLeaveLeaveApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_LEAVE_LEAVE_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  CompanySettingLeaveLeaveApprovalPolicyAssignmentDelete = 'COMPANY_SETTING_LEAVE_LEAVE_APPROVAL_POLICY_ASSIGNMENT___DELETE',
  HomeLeaveOutOfStateCreate = 'HOME_LEAVE_OUT_OF_STATE____CREATE',
  HomeLeaveOutOfStateRead = 'HOME_LEAVE_OUT_OF_STATE____READ',
  HomeLeaveOutOfStateUpdate = 'HOME_LEAVE_OUT_OF_STATE____UPDATE',
  HomeLeaveOutOfStateDelete = 'HOME_LEAVE_OUT_OF_STATE____DELETE',
  HomeLeaveApplicationCreate = 'HOME_LEAVE_APPLICATION____CREATE',
  HomeLeaveApplicationRead = 'HOME_LEAVE_APPLICATION____READ',
  HomeLeaveApplicationUpdate = 'HOME_LEAVE_APPLICATION____UPDATE',
  HomeLeaveApplicationDelete = 'HOME_LEAVE_APPLICATION____DELETE',
  HomeLeaveApplicationApprove = 'HOME_LEAVE_APPLICATION____APPROVE',
  HomeLeaveApplicationReject = 'HOME_LEAVE_APPLICATION____REJECT',
  HomeLeaveApplicationWithdraw = 'HOME_LEAVE_APPLICATION____WITHDRAW',
  HomeLeaveApplicationView = 'HOME_LEAVE_APPLICATION____VIEW',
  HomeLeaveApplicationCalendarRead = 'HOME_LEAVE_APPLICATION_CALENDAR____READ',
  HomeLeaveLeaveTakenImportFromExcelRead = 'HOME_LEAVE_LEAVE_TAKEN_IMPORT_FROM_EXCEL____READ',
  HomeLeaveLeaveTakenImportFromExcelDownload = 'HOME_LEAVE_LEAVE_TAKEN_IMPORT_FROM_EXCEL____DOWNLOAD',
  HomeLeaveLeaveTakenImportFromExcelImport = 'HOME_LEAVE_LEAVE_TAKEN_IMPORT_FROM_EXCEL____IMPORT',
  HomeLeaveAdjustmentCreate = 'HOME_LEAVE_ADJUSTMENT____CREATE',
  HomeLeaveAdjustmentRead = 'HOME_LEAVE_ADJUSTMENT____READ',
  HomeLeaveAdjustmentUpdate = 'HOME_LEAVE_ADJUSTMENT____UPDATE',
  HomeLeaveAdjustmentDelete = 'HOME_LEAVE_ADJUSTMENT____DELETE',
  HomeLeaveLeaveEntitlementByEmployeeRead = 'HOME_LEAVE_LEAVE_ENTITLEMENT_BY_EMPLOYEE____READ',
  HomeLeaveLeaveEntitlementByLeaveTypeRead = 'HOME_LEAVE_LEAVE_ENTITLEMENT_BY_LEAVE_TYPE____READ',
  HomeLeaveBlockLeaveCreate = 'HOME_LEAVE_BLOCK_LEAVE____CREATE',
  HomeLeaveBlockLeaveRead = 'HOME_LEAVE_BLOCK_LEAVE____READ',
  HomeLeaveBlockLeaveUpdate = 'HOME_LEAVE_BLOCK_LEAVE____UPDATE',
  HomeLeaveCompulsoryLeaveCreate = 'HOME_LEAVE_COMPULSORY_LEAVE____CREATE',
  HomeLeaveCompulsoryLeaveUpdate = 'HOME_LEAVE_COMPULSORY_LEAVE____UPDATE',
  HomeLeaveCompulsoryLeaveDelete = 'HOME_LEAVE_COMPULSORY_LEAVE____DELETE',
  HomeLeaveCompulsoryLeaveRead = 'HOME_LEAVE_COMPULSORY_LEAVE____READ',
  HomeLeavePostingRead = 'HOME_LEAVE_POSTING____READ',
  HomeLeavePostingPost = 'HOME_LEAVE_POSTING____POST',
  HomeLeavePostingRevert = 'HOME_LEAVE_POSTING____REVERT',
  HomeLeaveYearendCarryforwardRead = 'HOME_LEAVE_YEAREND_CARRYFORWARD____READ',
  HomeLeaveYearendCarryforwardPost = 'HOME_LEAVE_YEAREND_CARRYFORWARD____POST',
  HomeLeaveYearendCarryforwardReverse = 'HOME_LEAVE_YEAREND_CARRYFORWARD____REVERSE',
  HomeLeaveOffboardingEncashmentRead = 'HOME_LEAVE_OFFBOARDING_ENCASHMENT____READ',
  HomeLeaveOffboardingEncashmentPost = 'HOME_LEAVE_OFFBOARDING_ENCASHMENT____POST',
  HomeLeaveOffboardingEncashmentRevert = 'HOME_LEAVE_OFFBOARDING_ENCASHMENT____REVERT',
  HomeLeaveLeaveCarryForwardEncashmentRead = 'HOME_LEAVE_LEAVE_CARRY_FORWARD_ENCASHMENT____READ',
  HomeLeaveLeaveCarryForwardEncashmentPost = 'HOME_LEAVE_LEAVE_CARRY_FORWARD_ENCASHMENT____POST',
  HomeLeaveCareerMovementProcessRead = 'HOME_LEAVE_CAREER_MOVEMENT_PROCESS____READ',
  GeneralSettingLeaveTemplateCreate = 'GENERAL_SETTING_LEAVE_TEMPLATE____CREATE',
  GeneralSettingLeaveTemplateRead = 'GENERAL_SETTING_LEAVE_TEMPLATE____READ',
  GeneralSettingLeaveTemplateUpdate = 'GENERAL_SETTING_LEAVE_TEMPLATE____UPDATE',
  HomeClaimExecSummaryCreate = 'HOME_CLAIM_EXEC_SUMMARY____CREATE',
  HomeClaimExecSummaryRead = 'HOME_CLAIM_EXEC_SUMMARY____READ',
  HomeClaimRead = 'HOME_CLAIM_______READ',
  CommonSettingClaimRead = 'COMMON_SETTING_CLAIM_______READ',
  CommonSettingClaimClaimParameterRead = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___READ',
  CommonSettingClaimClaimParameterCreate = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___CREATE',
  CommonSettingClaimClaimParameterUpdate = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___UPDATE',
  CommonSettingClaimClaimParameterDelete = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___DELETE',
  CommonSettingClaimClaimParameterInactive = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___INACTIVE',
  CommonSettingClaimClaimParameterReactivate = 'COMMON_SETTING_CLAIM_CLAIM_PARAMETER___REACTIVATE',
  CommonSettingClaimClaimTypeRead = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___READ',
  CommonSettingClaimClaimTypeCreate = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___CREATE',
  CommonSettingClaimClaimTypeUpdate = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___UPDATE',
  CommonSettingClaimClaimTypeDelete = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___DELETE',
  CommonSettingClaimClaimTypeInactive = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___INACTIVE',
  CommonSettingClaimClaimTypeReactivate = 'COMMON_SETTING_CLAIM_CLAIM_TYPE___REACTIVATE',
  CommonSettingClaimClaimPolicyRead = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___READ',
  CommonSettingClaimClaimPolicyCreate = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___CREATE',
  CommonSettingClaimClaimPolicyUpdate = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___UPDATE',
  CommonSettingClaimClaimPolicyDelete = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___DELETE',
  CommonSettingClaimClaimPolicyInactive = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___INACTIVE',
  CommonSettingClaimClaimPolicyReactivate = 'COMMON_SETTING_CLAIM_CLAIM_POLICY___REACTIVATE',
  CommonSettingClaimClaimApprovalPolicyRead = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___READ',
  CommonSettingClaimClaimApprovalPolicyCreate = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___CREATE',
  CommonSettingClaimClaimApprovalPolicyUpdate = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___UPDATE',
  CommonSettingClaimClaimApprovalPolicyDelete = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___DELETE',
  CommonSettingClaimClaimApprovalPolicyInactive = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___INACTIVE',
  CommonSettingClaimClaimApprovalPolicyReactivate = 'COMMON_SETTING_CLAIM_CLAIM_APPROVAL_POLICY___REACTIVATE',
  CommonSettingClaimGlAccountMappingRead = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___READ',
  CommonSettingClaimGlAccountMappingCreate = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___CREATE',
  CommonSettingClaimGlAccountMappingUpdate = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___UPDATE',
  CommonSettingClaimGlAccountMappingDelete = 'COMMON_SETTING_CLAIM_GL_ACCOUNT_MAPPING___DELETE',
  CompanySettingClaimRead = 'COMPANY_SETTING_CLAIM_______READ',
  CompanySettingClaimJobGradePolicyRead = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___READ',
  CompanySettingClaimJobGradePolicyUpdate = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___UPDATE',
  CompanySettingClaimJobGradePolicyCreate = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___CREATE',
  CompanySettingClaimJobGradePolicyDelete = 'COMPANY_SETTING_CLAIM_JOB_GRADE_POLICY___DELETE',
  CompanySettingClaimGlAccountMappingRead = 'COMPANY_SETTING_CLAIM_GL_ACCOUNT_MAPPING___READ',
  CompanySettingClaimGlAccountMappingUpdate = 'COMPANY_SETTING_CLAIM_GL_ACCOUNT_MAPPING___UPDATE',
  CompanySettingClaimClaimSettingRead = 'COMPANY_SETTING_CLAIM_CLAIM_SETTING___READ',
  CompanySettingClaimClaimSettingUpdate = 'COMPANY_SETTING_CLAIM_CLAIM_SETTING___UPDATE',
  CompanySettingClaimOnBehalfPolicyRead = 'COMPANY_SETTING_CLAIM_ON_BEHALF_POLICY___READ',
  CompanySettingClaimOnBehalfPolicyUpdate = 'COMPANY_SETTING_CLAIM_ON_BEHALF_POLICY___UPDATE',
  CompanySettingClaimClaimApprovalPolicyAssignmentRead = 'COMPANY_SETTING_CLAIM_CLAIM_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingClaimClaimApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_CLAIM_CLAIM_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  HomeClaimOutOfStateCreate = 'HOME_CLAIM_OUT_OF_STATE____CREATE',
  HomeClaimOutOfStateRead = 'HOME_CLAIM_OUT_OF_STATE____READ',
  HomeClaimOutOfStateUpdate = 'HOME_CLAIM_OUT_OF_STATE____UPDATE',
  HomeClaimOutOfStateDelete = 'HOME_CLAIM_OUT_OF_STATE____DELETE',
  HomeClaimApplicationCreate = 'HOME_CLAIM_APPLICATION____CREATE',
  HomeClaimApplicationRead = 'HOME_CLAIM_APPLICATION____READ',
  HomeClaimApplicationUpdate = 'HOME_CLAIM_APPLICATION____UPDATE',
  HomeClaimApplicationDelete = 'HOME_CLAIM_APPLICATION____DELETE',
  HomeClaimApplicationApprove = 'HOME_CLAIM_APPLICATION____APPROVE',
  HomeClaimApplicationReject = 'HOME_CLAIM_APPLICATION____REJECT',
  HomeClaimApplicationWithdraw = 'HOME_CLAIM_APPLICATION____WITHDRAW',
  HomeClaimApprovedClaimVerifyRead = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____READ',
  HomeClaimApprovedClaimVerifyReject = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____REJECT',
  HomeClaimApprovedClaimVerifyVerify = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____VERIFY',
  HomeClaimApprovedClaimVerifyReverse = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____REVERSE',
  HomeClaimPostingRead = 'HOME_CLAIM_POSTING____READ',
  HomeClaimPostingPost = 'HOME_CLAIM_POSTING____POST',
  HomeClaimPostingReverse = 'HOME_CLAIM_POSTING____REVERSE',
  HomeClaimPaymentRead = 'HOME_CLAIM_PAYMENT____READ',
  HomeClaimPaymentPost = 'HOME_CLAIM_PAYMENT____POST',
  HomeClaimPaymentReverse = 'HOME_CLAIM_PAYMENT____REVERSE',
  HomeClaimClaimEntitlementByEmployeeRead = 'HOME_CLAIM_CLAIM_ENTITLEMENT_BY_EMPLOYEE____READ',
  HomeClaimClaimEntitlementByClaimTypeRead = 'HOME_CLAIM_CLAIM_ENTITLEMENT_BY_CLAIM_TYPE____READ',
  HomeClaimGlProcessingRead = 'HOME_CLAIM_GL_PROCESSING____READ',
  HomeClaimGlProcessingProcess = 'HOME_CLAIM_GL_PROCESSING____PROCESS',
  HomeClaimGlProcessingExport = 'HOME_CLAIM_GL_PROCESSING____EXPORT',
  HomeClaimGlExportRead = 'HOME_CLAIM_GL_EXPORT____READ',
  HomeClaimGlExportExport = 'HOME_CLAIM_GL_EXPORT____EXPORT',
  GeneralSettingClaimTemplateCreate = 'GENERAL_SETTING_CLAIM_TEMPLATE____CREATE',
  GeneralSettingClaimTemplateRead = 'GENERAL_SETTING_CLAIM_TEMPLATE____READ',
  GeneralSettingClaimTemplateUpdate = 'GENERAL_SETTING_CLAIM_TEMPLATE____UPDATE',
  HomeTmsExecSummaryRead = 'HOME_TMS_EXEC_SUMMARY____READ',
  HomeTmsRead = 'HOME_TMS_______READ',
  CommonSettingTmsRead = 'COMMON_SETTING_TMS_______READ',
  CommonSettingTmsShiftSetupRead = 'COMMON_SETTING_TMS_SHIFT_SETUP___READ',
  CommonSettingTmsShiftSetupCreate = 'COMMON_SETTING_TMS_SHIFT_SETUP___CREATE',
  CommonSettingTmsShiftSetupUpdate = 'COMMON_SETTING_TMS_SHIFT_SETUP___UPDATE',
  CommonSettingTmsShiftSetupDelete = 'COMMON_SETTING_TMS_SHIFT_SETUP___DELETE',
  CommonSettingTmsShiftSetupDuplicate = 'COMMON_SETTING_TMS_SHIFT_SETUP___DUPLICATE',
  CommonSettingTmsShiftPatternRead = 'COMMON_SETTING_TMS_SHIFT_PATTERN___READ',
  CommonSettingTmsShiftPatternCreate = 'COMMON_SETTING_TMS_SHIFT_PATTERN___CREATE',
  CommonSettingTmsShiftPatternUpdate = 'COMMON_SETTING_TMS_SHIFT_PATTERN___UPDATE',
  CommonSettingTmsShiftPatternDelete = 'COMMON_SETTING_TMS_SHIFT_PATTERN___DELETE',
  CommonSettingTmsShiftGroupRead = 'COMMON_SETTING_TMS_SHIFT_GROUP___READ',
  CommonSettingTmsShiftGroupCreate = 'COMMON_SETTING_TMS_SHIFT_GROUP___CREATE',
  CommonSettingTmsShiftGroupUpdate = 'COMMON_SETTING_TMS_SHIFT_GROUP___UPDATE',
  CommonSettingTmsAttendanceAnalysisTemplateRead = 'COMMON_SETTING_TMS_ATTENDANCE_ANALYSIS_TEMPLATE___READ',
  CommonSettingTmsAttendanceAnalysisTemplateUpdate = 'COMMON_SETTING_TMS_ATTENDANCE_ANALYSIS_TEMPLATE___UPDATE',
  CommonSettingTmsShiftGroupDelete = 'COMMON_SETTING_TMS_SHIFT_GROUP___DELETE',
  CommonSettingTmsOvertimePolicyRead = 'COMMON_SETTING_TMS_OVERTIME_POLICY___READ',
  CommonSettingTmsOvertimePolicyCreate = 'COMMON_SETTING_TMS_OVERTIME_POLICY___CREATE',
  CommonSettingTmsOvertimePolicyUpdate = 'COMMON_SETTING_TMS_OVERTIME_POLICY___UPDATE',
  CommonSettingTmsOvertimePolicyDelete = 'COMMON_SETTING_TMS_OVERTIME_POLICY___DELETE',
  CommonSettingTmsOvertimeClaimTableRead = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___READ',
  CommonSettingTmsOvertimeClaimTableCreate = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___CREATE',
  CommonSettingTmsOvertimeClaimTableUpdate = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___UPDATE',
  CommonSettingTmsOvertimeClaimTableDelete = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___DELETE',
  CommonSettingTmsOvertimeClaimTableView = 'COMMON_SETTING_TMS_OVERTIME_CLAIM_TABLE___VIEW',
  CommonSettingTmsOvertimeReasonCodesRead = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___READ',
  CommonSettingTmsOvertimeReasonCodesCreate = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___CREATE',
  CommonSettingTmsOvertimeReasonCodesUpdate = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___UPDATE',
  CommonSettingTmsOvertimeReasonCodesDelete = 'COMMON_SETTING_TMS_OVERTIME_REASON_CODES___DELETE',
  CommonSettingTmsOvertimeRoundingPolicyRead = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___READ',
  CommonSettingTmsOvertimeRoundingPolicyCreate = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___CREATE',
  CommonSettingTmsOvertimeRoundingPolicyUpdate = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___UPDATE',
  CommonSettingTmsOvertimeRoundingPolicyDelete = 'COMMON_SETTING_TMS_OVERTIME_ROUNDING_POLICY___DELETE',
  CommonSettingTmsDailyAllowancePolicyRead = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___READ',
  CommonSettingTmsDailyAllowancePolicyCreate = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___CREATE',
  CommonSettingTmsDailyAllowancePolicyUpdate = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___UPDATE',
  CommonSettingTmsDailyAllowancePolicyDelete = 'COMMON_SETTING_TMS_DAILY_ALLOWANCE_POLICY___DELETE',
  CommonSettingTmsMonthlyAllowancePolicyRead = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___READ',
  CommonSettingTmsMonthlyAllowancePolicyCreate = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___CREATE',
  CommonSettingTmsMonthlyAllowancePolicyUpdate = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___UPDATE',
  CommonSettingTmsMonthlyAllowancePolicyDelete = 'COMMON_SETTING_TMS_MONTHLY_ALLOWANCE_POLICY___DELETE',
  CommonSettingTmsPeriodicDeductionPolicyRead = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___READ',
  CommonSettingTmsPeriodicDeductionPolicyCreate = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___CREATE',
  CommonSettingTmsPeriodicDeductionPolicyUpdate = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___UPDATE',
  CommonSettingTmsPeriodicDeductionPolicyDelete = 'COMMON_SETTING_TMS_PERIODIC_DEDUCTION_POLICY___DELETE',
  CommonSettingTmsDeductionPolicyRead = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___READ',
  CommonSettingTmsDeductionPolicyCreate = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___CREATE',
  CommonSettingTmsDeductionPolicyUpdate = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___UPDATE',
  CommonSettingTmsDeductionPolicyDelete = 'COMMON_SETTING_TMS_DEDUCTION_POLICY___DELETE',
  CommonSettingTmsAllowanceDeductionRoundingPolicyRead = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___READ',
  CommonSettingTmsAllowanceDeductionRoundingPolicyCreate = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___CREATE',
  CommonSettingTmsAllowanceDeductionRoundingPolicyUpdate = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___UPDATE',
  CommonSettingTmsAllowanceDeductionRoundingPolicyDelete = 'COMMON_SETTING_TMS_ALLOWANCE_DEDUCTION_ROUNDING_POLICY___DELETE',
  CommonSettingTmsTimeOffPolicyRead = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___READ',
  CommonSettingTmsTimeOffPolicyCreate = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___CREATE',
  CommonSettingTmsTimeOffPolicyUpdate = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___UPDATE',
  CommonSettingTmsTimeOffPolicyDelete = 'COMMON_SETTING_TMS_TIME_OFF_POLICY___DELETE',
  CommonSettingTmsTimeOffReasonCodesRead = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___READ',
  CommonSettingTmsTimeOffReasonCodesCreate = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___CREATE',
  CommonSettingTmsTimeOffReasonCodesUpdate = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___UPDATE',
  CommonSettingTmsTimeOffReasonCodesDelete = 'COMMON_SETTING_TMS_TIME_OFF_REASON_CODES___DELETE',
  CommonSettingTmsPeriodicAllowanceTypeRead = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___READ',
  CommonSettingTmsMobileCheckLocationRead = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___READ',
  CommonSettingTmsMobileCheckLocationCreate = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___CREATE',
  CommonSettingTmsMobileCheckLocationUpdate = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___UPDATE',
  CommonSettingTmsMobileCheckLocationDelete = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___DELETE',
  CommonSettingTmsMobileCheckLocationView = 'COMMON_SETTING_TMS_MOBILE_CHECK_LOCATION___VIEW',
  CommonSettingTmsTmsApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY___READ',
  CommonSettingTmsPeriodicAllowanceTypeCreate = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___CREATE',
  CommonSettingTmsPeriodicAllowanceTypeUpdate = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___UPDATE',
  CommonSettingTmsPeriodicAllowanceTypeDelete = 'COMMON_SETTING_TMS_PERIODIC_ALLOWANCE_TYPE___DELETE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyOvertimeApprovalPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_OVERTIME_APPROVAL_POLICY___REACTIVATE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyTimeOffApprovalPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_TIME_OFF_APPROVAL_POLICY___REACTIVATE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyChangeShiftRequestApprovalPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_CHANGE_SHIFT_REQUEST_APPROVAL_POLICY___REACTIVATE',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyBatchOvertimeEntryApprovalPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_BATCH_OVERTIME_ENTRY_APPROVAL_POLICY___REACTIVATE',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyRead = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___READ',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyCreate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___CREATE',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyUpdate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___UPDATE',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyDelete = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___DELETE',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyInactive = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___INACTIVE',
  CommonSettingTmsTmsApprovalPolicyAttendanceCorrectionRequestPolicyReactivate = 'COMMON_SETTING_TMS_TMS_APPROVAL_POLICY_ATTENDANCE_CORRECTION_REQUEST_POLICY___REACTIVATE',
  CompanySettingTmsRead = 'COMPANY_SETTING_TMS_______READ',
  CompanySettingTmsShiftAssignmentRead = 'COMPANY_SETTING_TMS_SHIFT_ASSIGNMENT___READ',
  CompanySettingTmsShiftAssignmentUpdate = 'COMPANY_SETTING_TMS_SHIFT_ASSIGNMENT___UPDATE',
  CompanySettingTmsShiftPatternAssignmentRead = 'COMPANY_SETTING_TMS_SHIFT_PATTERN_ASSIGNMENT___READ',
  CompanySettingTmsShiftPatternAssignmentUpdate = 'COMPANY_SETTING_TMS_SHIFT_PATTERN_ASSIGNMENT___UPDATE',
  CompanySettingTmsShiftGroupAssignmentRead = 'COMPANY_SETTING_TMS_SHIFT_GROUP_ASSIGNMENT___READ',
  CompanySettingTmsShiftGroupAssignmentUpdate = 'COMPANY_SETTING_TMS_SHIFT_GROUP_ASSIGNMENT___UPDATE',
  CompanySettingTmsTimeOffPolicyAssignmentRead = 'COMPANY_SETTING_TMS_TIME_OFF_POLICY_ASSIGNMENT___READ',
  CompanySettingTmsTimeOffPolicyAssignmentUpdate = 'COMPANY_SETTING_TMS_TIME_OFF_POLICY_ASSIGNMENT___UPDATE',
  CompanySettingTmsCsOvertimeReasonCodesRead = 'COMPANY_SETTING_TMS_CS_OVERTIME_REASON_CODES___READ',
  CompanySettingTmsCsOvertimeReasonCodesUpdate = 'COMPANY_SETTING_TMS_CS_OVERTIME_REASON_CODES___UPDATE',
  CompanySettingTmsCsTimeOffReasonCodesRead = 'COMPANY_SETTING_TMS_CS_TIME_OFF_REASON_CODES___READ',
  CompanySettingTmsCsTimeOffReasonCodesUpdate = 'COMPANY_SETTING_TMS_CS_TIME_OFF_REASON_CODES___UPDATE',
  CompanySettingTmsJobGradePolicyMatrixRead = 'COMPANY_SETTING_TMS_JOB_GRADE_POLICY_MATRIX___READ',
  CompanySettingTmsDailyPolicyMatrixCreate = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___CREATE',
  CompanySettingTmsDailyPolicyMatrixRead = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___READ',
  CompanySettingTmsDailyPolicyMatrixUpdate = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___UPDATE',
  CompanySettingTmsDailyPolicyMatrixDelete = 'COMPANY_SETTING_TMS_DAILY_POLICY_MATRIX___DELETE',
  CompanySettingTmsMonthlyPolicyMatrixCreate = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___CREATE',
  CompanySettingTmsMonthlyPolicyMatrixRead = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___READ',
  CompanySettingTmsMonthlyPolicyMatrixUpdate = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___UPDATE',
  CompanySettingTmsMonthlyPolicyMatrixDelete = 'COMPANY_SETTING_TMS_MONTHLY_POLICY_MATRIX___DELETE',
  CompanySettingTmsDeductionPolicyMatrixCreate = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___CREATE',
  CompanySettingTmsDeductionPolicyMatrixRead = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___READ',
  CompanySettingTmsDeductionPolicyMatrixUpdate = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___UPDATE',
  CompanySettingTmsDeductionPolicyMatrixDelete = 'COMPANY_SETTING_TMS_DEDUCTION_POLICY_MATRIX___DELETE',
  CompanySettingTmsOvertimePolicyMatrixCreate = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___CREATE',
  CompanySettingTmsOvertimePolicyMatrixRead = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___READ',
  CompanySettingTmsOvertimePolicyMatrixUpdate = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___UPDATE',
  CompanySettingTmsOvertimePolicyMatrixDelete = 'COMPANY_SETTING_TMS_OVERTIME_POLICY_MATRIX___DELETE',
  CompanySettingTmsCsJobGradePolicyMatrixUpdate = 'COMPANY_SETTING_TMS_CS_JOB_GRADE_POLICY_MATRIX___UPDATE',
  CompanySettingTmsMobileCheckLocationAssignmentRead = 'COMPANY_SETTING_TMS_MOBILE_CHECK_LOCATION_ASSIGNMENT___READ',
  CompanySettingTmsMobileCheckLocationAssignmentUpdate = 'COMPANY_SETTING_TMS_MOBILE_CHECK_LOCATION_ASSIGNMENT___UPDATE',
  CompanySettingTmsOvertimeSettingRead = 'COMPANY_SETTING_TMS_OVERTIME_SETTING___READ',
  CompanySettingTmsOvertimeSettingUpdate = 'COMPANY_SETTING_TMS_OVERTIME_SETTING___UPDATE',
  CompanySettingTmsOnBehalfPolicyRead = 'COMPANY_SETTING_TMS_ON_BEHALF_POLICY___READ',
  CompanySettingTmsOnBehalfPolicyUpdate = 'COMPANY_SETTING_TMS_ON_BEHALF_POLICY___UPDATE',
  CompanySettingTmsTmsApprovalPolicyAssignmentRead = 'COMPANY_SETTING_TMS_TMS_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingTmsTmsApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_TMS_TMS_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  CompanySettingTmsTmsApprovalPolicyAssignmentCreate = 'COMPANY_SETTING_TMS_TMS_APPROVAL_POLICY_ASSIGNMENT___CREATE',
  CompanySettingTmsTmsApprovalPolicyAssignmentDelete = 'COMPANY_SETTING_TMS_TMS_APPROVAL_POLICY_ASSIGNMENT___DELETE',
  HomeTmsAttendanceRecordRead = 'HOME_TMS_ATTENDANCE_RECORD____READ',
  HomeTmsAttendanceRecordManualEntryRead = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____READ',
  HomeTmsAttendanceRecordManualEntryCreate = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____CREATE',
  HomeTmsAttendanceRecordManualEntryUpdate = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____UPDATE',
  HomeTmsAttendanceRecordManualEntryDelete = 'HOME_TMS_ATTENDANCE_RECORD_MANUAL_ENTRY____DELETE',
  HomeTmsAttendanceRecordImportAttendanceDataFromExcelRead = 'HOME_TMS_ATTENDANCE_RECORD_IMPORT_ATTENDANCE_DATA_FROM_EXCEL____READ',
  HomeTmsAttendanceRecordImportAttendanceDataFromExcelDownload = 'HOME_TMS_ATTENDANCE_RECORD_IMPORT_ATTENDANCE_DATA_FROM_EXCEL____DOWNLOAD',
  HomeTmsAttendanceRecordImportAttendanceDataFromExcelImport = 'HOME_TMS_ATTENDANCE_RECORD_IMPORT_ATTENDANCE_DATA_FROM_EXCEL____IMPORT',
  HomeTmsAttendanceRecordExportAttendanceDataToExcelRead = 'HOME_TMS_ATTENDANCE_RECORD_EXPORT_ATTENDANCE_DATA_TO_EXCEL____READ',
  HomeTmsShiftGroupEmployeeAssignmentRead = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT____READ',
  HomeTmsShiftGroupEmployeeAssignmentBatchAssignmentRead = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_BATCH_ASSIGNMENT____READ',
  HomeTmsShiftGroupEmployeeAssignmentBatchAssignmentUpdate = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_BATCH_ASSIGNMENT____UPDATE',
  HomeTmsShiftGroupEmployeeAssignmentIndividualAssignmentRead = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_INDIVIDUAL_ASSIGNMENT____READ',
  HomeTmsShiftGroupEmployeeAssignmentIndividualAssignmentUpdate = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_INDIVIDUAL_ASSIGNMENT____UPDATE',
  HomeTmsShiftGroupEmployeeAssignmentIndividualAssignmentDelete = 'HOME_TMS_SHIFT_GROUP_EMPLOYEE_ASSIGNMENT_INDIVIDUAL_ASSIGNMENT____DELETE',
  HomeTmsShiftGroupShiftPatternAssignmentRead = 'HOME_TMS_SHIFT_GROUP_SHIFT_PATTERN_ASSIGNMENT____READ',
  HomeTmsShiftGroupShiftPatternAssignmentUpdate = 'HOME_TMS_SHIFT_GROUP_SHIFT_PATTERN_ASSIGNMENT____UPDATE',
  HomeTmsShiftGroupShiftPatternAssignmentDelete = 'HOME_TMS_SHIFT_GROUP_SHIFT_PATTERN_ASSIGNMENT____DELETE',
  HomeTmsDutyRosterRead = 'HOME_TMS_DUTY_ROSTER____READ',
  HomeTmsDutyRosterDutyRosterByIndividualRead = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_INDIVIDUAL____READ',
  HomeTmsDutyRosterDutyRosterByIndividualUpdate = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_INDIVIDUAL____UPDATE',
  HomeTmsDutyRosterDutyRosterByEmployeeListRead = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_EMPLOYEE_LIST____READ',
  HomeTmsDutyRosterDutyRosterByEmployeeListUpdate = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_BY_EMPLOYEE_LIST____UPDATE',
  HomeTmsDutyRosterShiftAssignmentToEmployeeRead = 'HOME_TMS_DUTY_ROSTER_SHIFT_ASSIGNMENT_TO_EMPLOYEE____READ',
  HomeTmsDutyRosterShiftAssignmentToEmployeeUpdate = 'HOME_TMS_DUTY_ROSTER_SHIFT_ASSIGNMENT_TO_EMPLOYEE____UPDATE',
  HomeTmsDutyRosterShiftPatternAssignmentToEmployeeRead = 'HOME_TMS_DUTY_ROSTER_SHIFT_PATTERN_ASSIGNMENT_TO_EMPLOYEE____READ',
  HomeTmsDutyRosterShiftPatternAssignmentToEmployeeUpdate = 'HOME_TMS_DUTY_ROSTER_SHIFT_PATTERN_ASSIGNMENT_TO_EMPLOYEE____UPDATE',
  HomeTmsDutyRosterExcelUploadMaintenanceRead = 'HOME_TMS_DUTY_ROSTER_EXCEL_UPLOAD_MAINTENANCE____READ',
  HomeTmsDutyRosterExcelUploadMaintenanceDownload = 'HOME_TMS_DUTY_ROSTER_EXCEL_UPLOAD_MAINTENANCE____DOWNLOAD',
  HomeTmsDutyRosterExcelUploadMaintenanceImport = 'HOME_TMS_DUTY_ROSTER_EXCEL_UPLOAD_MAINTENANCE____IMPORT',
  HomeTmsDutyRosterDutyRosterSummaryRead = 'HOME_TMS_DUTY_ROSTER_DUTY_ROSTER_SUMMARY____READ',
  HomeTmsOvertimeApplicationRead = 'HOME_TMS_OVERTIME_APPLICATION_______READ',
  HomeTmsOvertimeApplicationManualEntryRead = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____READ',
  HomeTmsOvertimeApplicationManualEntryCreate = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____CREATE',
  HomeTmsOvertimeApplicationManualEntryWithdraw = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____WITHDRAW',
  HomeTmsOvertimeApplicationManualEntryApprove = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____APPROVE',
  HomeTmsOvertimeApplicationManualEntryReject = 'HOME_TMS_OVERTIME_APPLICATION_MANUAL_ENTRY____REJECT',
  HomeTmsOvertimeApplicationExcelUploadMaintenanceRead = 'HOME_TMS_OVERTIME_APPLICATION_EXCEL_UPLOAD_MAINTENANCE____READ',
  HomeTmsOvertimeApplicationExcelUploadMaintenanceDownload = 'HOME_TMS_OVERTIME_APPLICATION_EXCEL_UPLOAD_MAINTENANCE____DOWNLOAD',
  HomeTmsOvertimeApplicationExcelUploadMaintenanceImport = 'HOME_TMS_OVERTIME_APPLICATION_EXCEL_UPLOAD_MAINTENANCE____IMPORT',
  HomeTmsOvertimeApplicationExportOvertimeDataRead = 'HOME_TMS_OVERTIME_APPLICATION_EXPORT_OVERTIME_DATA____READ',
  HomeTmsChangeShiftRequestCreate = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______CREATE',
  HomeTmsChangeShiftRequestRead = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______READ',
  HomeTmsChangeShiftRequestDelete = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______DELETE',
  HomeTmsChangeShiftRequestApprove = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______APPROVE',
  HomeTmsChangeShiftRequestReject = 'HOME_TMS_CHANGE_SHIFT_REQUEST_______REJECT',
  HomeTmsTimeOffApplicationCreate = 'HOME_TMS_TIME_OFF_APPLICATION_______CREATE',
  HomeTmsTimeOffApplicationRead = 'HOME_TMS_TIME_OFF_APPLICATION_______READ',
  HomeTmsTimeOffApplicationApprove = 'HOME_TMS_TIME_OFF_APPLICATION_______APPROVE',
  HomeTmsTimeOffApplicationReject = 'HOME_TMS_TIME_OFF_APPLICATION_______REJECT',
  HomeTmsTimeOffApplicationWithdraw = 'HOME_TMS_TIME_OFF_APPLICATION_______WITHDRAW',
  HomeTmsNewAttendanceRecordRead = 'HOME_TMS_NEW_ATTENDANCE_RECORD_______READ',
  HomeTmsNewAttendanceRecordUpdate = 'HOME_TMS_NEW_ATTENDANCE_RECORD_______UPDATE',
  HomeTmsNewAttendanceRecordRepair = 'HOME_TMS_NEW_ATTENDANCE_RECORD_______REPAIR',
  HomeTmsAttendanceCorrectionRequestRead = 'HOME_TMS_ATTENDANCE_CORRECTION_REQUEST____READ',
  HomeTmsAttendanceCorrectionRequestApprove = 'HOME_TMS_ATTENDANCE_CORRECTION_REQUEST_______APPROVE',
  HomeTmsAttendanceCorrectionRequestReject = 'HOME_TMS_ATTENDANCE_CORRECTION_REQUEST_______REJECT',
  HomeTmsAttendanceAnalysisRead = 'HOME_TMS_ATTENDANCE_ANALYSIS_______READ',
  HomeTmsAttendanceAnalysisExport = 'HOME_TMS_ATTENDANCE_ANALYSIS_______EXPORT',
  HomeTmsAttendanceProcessingRead = 'HOME_TMS_ATTENDANCE_PROCESSING_______READ',
  HomeTmsAttendanceProcessingDailyTransactionListingRead = 'HOME_TMS_ATTENDANCE_PROCESSING_DAILY_TRANSACTION_LISTING_______READ',
  HomeTmsAttendanceProcessingDailyTransactionListingCalculate = 'HOME_TMS_ATTENDANCE_PROCESSING_DAILY_TRANSACTION_LISTING_______CALCULATE',
  HomeTmsAttendanceProcessingDailyTransactionListingExport = 'HOME_TMS_ATTENDANCE_PROCESSING_DAILY_TRANSACTION_LISTING_______EXPORT',
  HomeTmsAttendanceProcessingMonthlySummaryRead = 'HOME_TMS_ATTENDANCE_PROCESSING_MONTHLY_SUMMARY_______READ',
  HomeTmsAttendanceProcessingMonthlySummaryCalculate = 'HOME_TMS_ATTENDANCE_PROCESSING_MONTHLY_SUMMARY_______CALCULATE',
  HomeTmsAttendanceProcessingMonthlySummaryExport = 'HOME_TMS_ATTENDANCE_PROCESSING_MONTHLY_SUMMARY_______EXPORT',
  HomeTmsAttendancePostingRead = 'HOME_TMS_ATTENDANCE_POSTING_______READ',
  HomeTmsAttendancePostingPost = 'HOME_TMS_ATTENDANCE_POSTING_______POST',
  HomeTmsAttendancePostingRevert = 'HOME_TMS_ATTENDANCE_POSTING_______REVERT',
  HomeTmsAttendancePostingPopulate = 'HOME_TMS_ATTENDANCE_POSTING_______POPULATE',
  HomeTmsEarnLeavePostingRead = 'HOME_TMS_EARN_LEAVE_POSTING_______READ',
  HomeTmsEarnLeavePostingPost = 'HOME_TMS_EARN_LEAVE_POSTING_______POST',
  HomeTmsEarnLeavePostingRevert = 'HOME_TMS_EARN_LEAVE_POSTING_______REVERT',
  HomeTmsAttendanceExclusionListRead = 'HOME_TMS_ATTENDANCE_EXCLUSION_LIST_______READ',
  HomeTmsAttendanceExclusionListCreate = 'HOME_TMS_ATTENDANCE_EXCLUSION_LIST_______CREATE',
  HomeTmsAttendanceExclusionListDelete = 'HOME_TMS_ATTENDANCE_EXCLUSION_LIST_______DELETE',
  HomeTmsContactTracingRead = 'HOME_TMS_CONTACT_TRACING_______READ',
  HomeTmsContactTracingExport = 'HOME_TMS_CONTACT_TRACING_______EXPORT',
  CommonSettingRequisitionRead = 'COMMON_SETTING_REQUISITION_______READ',
  CommonSettingRequisitionTypeRead = 'COMMON_SETTING_REQUISITION_TYPE______READ',
  CommonSettingRequisitionTypeCreate = 'COMMON_SETTING_REQUISITION_TYPE______CREATE',
  CommonSettingRequisitionTypeUpdate = 'COMMON_SETTING_REQUISITION_TYPE______UPDATE',
  CommonSettingRequisitionRequisitionApprovalPolicyRead = 'COMMON_SETTING_REQUISITION_REQUISITION_APPROVAL_POLICY___READ',
  CommonSettingRequisitionAssetRequisitionRead = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___READ',
  CommonSettingRequisitionAssetRequisitionCreate = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___CREATE',
  CommonSettingRequisitionAssetRequisitionUpdate = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___UPDATE',
  CommonSettingRequisitionAssetRequisitionDelete = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___DELETE',
  CommonSettingRequisitionAssetRequisitionInactive = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___INACTIVE',
  CommonSettingRequisitionAssetRequisitionReactivate = 'COMMON_SETTING_REQUISITION_ASSET_REQUISITION___REACTIVATE',
  CommonSettingRequisitionCashAdvanceRead = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___READ',
  CommonSettingRequisitionCashAdvanceCreate = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___CREATE',
  CommonSettingRequisitionCashAdvanceUpdate = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___UPDATE',
  CommonSettingRequisitionCashAdvanceDelete = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___DELETE',
  CommonSettingRequisitionCashAdvanceInactive = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___INACTIVE',
  CommonSettingRequisitionCashAdvanceReactivate = 'COMMON_SETTING_REQUISITION_CASH_ADVANCE___REACTIVATE',
  CommonSettingRequisitionOtherRequisitionRead = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___READ',
  CommonSettingRequisitionOtherRequisitionCreate = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___CREATE',
  CommonSettingRequisitionOtherRequisitionUpdate = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___UPDATE',
  CommonSettingRequisitionOtherRequisitionDelete = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___DELETE',
  CommonSettingRequisitionOtherRequisitionInactive = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___INACTIVE',
  CommonSettingRequisitionOtherRequisitionReactivate = 'COMMON_SETTING_REQUISITION_OTHER_REQUISITION___REACTIVATE',
  CommonSettingRequisitionTravelRequestRead = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___READ',
  CommonSettingRequisitionTravelRequestCreate = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___CREATE',
  CommonSettingRequisitionTravelRequestUpdate = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___UPDATE',
  CommonSettingRequisitionTravelRequestDelete = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___DELETE',
  CommonSettingRequisitionTravelRequestInactive = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___INACTIVE',
  CommonSettingRequisitionTravelRequestReactivate = 'COMMON_SETTING_REQUISITION_TRAVEL_REQUEST___REACTIVATE',
  CommonSettingRequisitionStaffMovementRequestRead = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__READ',
  CommonSettingRequisitionStaffMovementRequestCreate = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__CREATE',
  CommonSettingRequisitionStaffMovementRequestUpdate = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__UPDATE',
  CommonSettingRequisitionStaffMovementRequestDelete = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__DELETE',
  CommonSettingRequisitionStaffMovementRequestInactive = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__INACTIVE',
  CommonSettingRequisitionStaffMovementRequestReactivate = 'COMMON_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST__REACTIVATE',
  CompanySettingRequisitionRead = 'COMPANY_SETTING_REQUISITION_______READ',
  CompanySettingRequisitionStaffMovementRequestRead = 'COMPANY_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST_______READ',
  CompanySettingRequisitionStaffMovementRequestUpdate = 'COMPANY_SETTING_REQUISITION_STAFF_MOVEMENT_REQUEST_______UPDATE',
  CompanySettingRequisitionTravelRequestApprovalPolicyAssignmentRead = 'COMPANY_SETTING_REQUISITION_TRAVEL_REQUEST_APPROVAL_POLICY_ASSIGNMENT___READ',
  CompanySettingRequisitionTravelRequestApprovalPolicyAssignmentUpdate = 'COMPANY_SETTING_REQUISITION_TRAVEL_REQUEST_APPROVAL_POLICY_ASSIGNMENT___UPDATE',
  HomeRequisitionExecSummaryRead = 'HOME_REQUISITION_EXEC_SUMMARY____READ',
  HomeRequisitionRead = 'HOME_REQUISITION_______READ',
  HomeRequisitionUpdate = 'HOME_REQUISITION_______UPDATE',
  HomeRequisitionRequisitionApplicationRead = 'HOME_REQUISITION_REQUISITION_APPLICATION_______READ',
  HomeRequisitionRequisitionApplicationCreate = 'HOME_REQUISITION_REQUISITION_APPLICATION_______CREATE',
  HomeRequisitionRequisitionApplicationApprove = 'HOME_REQUISITION_REQUISITION_APPLICATION_______APPROVE',
  HomeRequisitionRequisitionApplicationReject = 'HOME_REQUISITION_REQUISITION_APPLICATION_______REJECT',
  HomeRequisitionRequisitionApplicationWithdraw = 'HOME_REQUISITION_REQUISITION_APPLICATION_______WITHDRAW',
  GeneralSettingRequisitionNotificationRead = 'GENERAL_SETTING_REQUISITION_NOTIFICATION____READ',
  DigitalReportingPersonnelRptBasicinfoView = 'DIGITAL_REPORTING_PERSONNEL_RPT_BASICINFO____VIEW',
  DigitalReportingPersonnelRptConfirmationView = 'DIGITAL_REPORTING_PERSONNEL_RPT_CONFIRMATION____VIEW',
  DigitalReportingPersonnelRptDependentView = 'DIGITAL_REPORTING_PERSONNEL_RPT_DEPENDENT____VIEW',
  DigitalReportingPersonnelRptEduBackgroundView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EDU_BACKGROUND_VIEW',
  DigitalReportingPersonnelRptNewView = 'DIGITAL_REPORTING_PERSONNEL_RPT_NEW____VIEW',
  DigitalReportingPersonnelRptRegisnationView = 'DIGITAL_REPORTING_PERSONNEL_RPT_REGISNATION____VIEW',
  DigitalReportingPersonnelRptEmployeeServiceMovementView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EMPLOYEE_SERVICE_MOVEMENT____VIEW',
  DigitalReportingPersonnelRptEmployeeServiceSalaryMovementView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EMPLOYEE_SERVICE_SALARY_MOVEMENT____VIEW',
  DigitalReportingPersonnelRptEmployeeMasterExcelView = 'DIGITAL_REPORTING_PERSONNEL_RPT_EMPLOYEE_MASTER_EXCEL_VIEW',
  DigitalReportingPayrollRptBankBankPayoutView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK_BANK_PAYOUT_VIEW',
  DigitalReportingPayrollRptBankCashcheckPayoutView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK_CASHCHECK_PAYOUT_VIEW',
  DigitalReportingPayrollRptPayrollEmployeePayView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_EMPLOYEE_PAY_VIEW',
  DigitalReportingPayrollRptPayrollEmployeeSalaryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_EMPLOYEE_SALARY_VIEW',
  DigitalReportingPayrollRptPayrollHistoryTrxView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_HISTORY_TRX_VIEW',
  DigitalReportingPayrollRptPayrollHistoryTrxByOrgUnitView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_HISTORY_TRX_BY_ORG_UNIT_VIEW',
  DigitalReportingPayrollRptPayrollPayrollDetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_DETAIL_VIEW',
  DigitalReportingPayrollRptPayrollPayrollTrxDetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_TRX_DETAIL_VIEW',
  DigitalReportingPayrollRptPayrollPayrollTrxDetailByOrgUnitView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_TRX_DETAIL_BY_ORG_UNIT_VIEW',
  DigitalReportingPayrollRptPayrollPayrollTrxDetailByJobGradeOrgUnitView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_TRX_DETAIL_BY_JOB_GRADE_ORG_UNIT_VIEW',
  DigitalReportingPayrollRptPayrollSalaryvarianceView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYVARIANCE_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAIL_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailbystaffcategoryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAILBYSTAFFCATEGORY_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailbyjobpositionView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAILBYJOBPOSITION_VIEW',
  DigitalReportingPayrollRptPayrollSalarysummaryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYSUMMARY_VIEW',
  DigitalReportingPayrollRptPayrollSalarysummaryExport = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYSUMMARY_EXPORT',
  DigitalReportingPayrollRptPayrollSalarysummaryByCostCenterView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYSUMMARY_BY_COST_CENTER_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailByCostCenterView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAIL_BY_COST_CENTER_VIEW',
  DigitalReportingPayrollRptJoinPayrollSalarydetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_JOIN_PAYROLL_SALARYDETAIL_VIEW',
  DigitalReportingPayrollRptResignPayrollSalarydetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_RESIGN_PAYROLL_SALARYDETAIL_VIEW',
  DigitalReportingPayrollRptPayrollSalaryVarianceByEmployeeView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARY_VARIANCE_BY_EMPLOYEE_VIEW',
  DigitalReportingPayrollRptPayrollSalaryVarianceByPayitemView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARY_VARIANCE_BY_PAYITEM_VIEW',
  DigitalReportingPayrollRptPayrollTrxDetailExport = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_TRX_DETAIL_EXPORT',
  DigitalReportingPayrollRptPayrollStaffcurrentcompensationView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_STAFFCURRENTCOMPENSATION_VIEW',
  DigitalReportingPayrollRptPayrollYtdtransactionsummaryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_YTDTRANSACTIONSUMMARY_VIEW',
  DigitalReportingPayrollRptPayslipView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYSLIP____VIEW',
  DigitalReportingPayrollRptPayslipMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYSLIP_MULTI_VIEW',
  DigitalReportingPayrollRptPayslipBmEnMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYSLIP_BM_EN_MULTI_VIEW',
  DigitalReportingPayrollRptAsbView = 'DIGITAL_REPORTING_PAYROLL_RPT_ASB____VIEW',
  DigitalReportingPayrollRptAsbRptView = 'DIGITAL_REPORTING_PAYROLL_RPT_ASB_RPT_VIEW',
  DigitalReportingPayrollRptAsbCsvTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_ASB_CSV_TXT_VIEW',
  DigitalReportingPayrollRptHrdfView = 'DIGITAL_REPORTING_PAYROLL_RPT_HRDF_VIEW',
  DigitalReportingPayrollRptHrDevelopmentFundListingMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_HR_DEVELOPMENT_FUND_LISTING_MULTI_VIEW',
  DigitalReportingPayrollRptZakatView = 'DIGITAL_REPORTING_PAYROLL_RPT_ZAKAT_VIEW',
  DigitalReportingPayrollRptZakatListingMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_ZAKAT_LISTING_MULTI_VIEW',
  DigitalReportingPayrollRptZakatListingWpklMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_ZAKAT_LISTING_WPKL_MULTI_VIEW',
  DigitalReportingPayrollRptTabungHajiView = 'DIGITAL_REPORTING_PAYROLL_RPT_TABUNG_HAJI_VIEW',
  DigitalReportingPayrollRptTabungHajiRptView = 'DIGITAL_REPORTING_PAYROLL_RPT_TABUNG_HAJI_RPT_VIEW',
  DigitalReportingPayrollRptTabungHajiExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_TABUNG_HAJI_EXCEL_VIEW',
  DigitalReportingPayrollRptKwspBorangaView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_BORANGA_VIEW',
  DigitalReportingPayrollRptKwspBorangaMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_BORANGA_MULTI_VIEW',
  DigitalReportingPayrollRptKwspGovernmentListingCsvView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_GOVERNMENT_LISTING_CSV_VIEW',
  DigitalReportingPayrollRptKwspGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptSocsoBorang8AView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG8A_VIEW',
  DigitalReportingPayrollRptSocsoBorang2MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG2_MULTI_VIEW',
  DigitalReportingPayrollRptSocsoBorang3MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG3_MULTI_VIEW',
  DigitalReportingPayrollRptSocsoBorang8AMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG8A_MULTI_VIEW',
  DigitalReportingPayrollRptSocsoGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptSocsoEisGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_EIS_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptEisLampiran1View = 'DIGITAL_REPORTING_PAYROLL_RPT_EIS_LAMPIRAN1_VIEW',
  DigitalReportingPayrollRptEisGovernmentListingTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_EIS_GOVERNMENT_LISTING_TXT_VIEW',
  DigitalReportingPayrollRptEisLampiran1MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_EIS_LAMPIRAN1_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangeView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGE_VIEW',
  DigitalReportingPayrollRptLhdnBorangeaView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2017MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2017_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2021MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2021_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2022MultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2022_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2022BmEnMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2022_BM_EN_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnBorangea2023BmEnMultiView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA2023_BM_EN_MULTI_VIEW',
  DigitalReportingPayrollRptLhdnCp159View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP159_VIEW',
  DigitalReportingPayrollRptLhdnCp21View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP21_VIEW',
  DigitalReportingPayrollRptLhdnCp22View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP22_VIEW',
  DigitalReportingPayrollRptLhdnCp22AView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP22A_VIEW',
  DigitalReportingPayrollRptLhdnCp22ATxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP22A_TXT_VIEW',
  DigitalReportingPayrollRptLhdnCp39View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP39_VIEW',
  DigitalReportingPayrollRptLhdnCp39AView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP39A_VIEW',
  DigitalReportingPayrollRptLhdnCp8DView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP8D_VIEW',
  DigitalReportingPayrollRptLhdnCp8DtxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP8DTXT_VIEW',
  DigitalReportingPayrollRptLhdnTaxView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_TAX_VIEW',
  DigitalReportingPayrollRptLhdnLampiranBView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_LAMPIRAN_B_VIEW',
  DigitalReportingPayrollRptIntegrationAffbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_VIEW',
  DigitalReportingPayrollRptIntegrationAffbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationAffbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAffbEpfTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_EPF_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAffbSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAffbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAffbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AFFB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAbmbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_ABMB_VIEW',
  DigitalReportingPayrollRptIntegrationAbmbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_ABMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationAmmbKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_AMMB_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationBimbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_BIMB_VIEW',
  DigitalReportingPayrollRptIntegrationBimbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_BIMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationBimbOnlinePayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_BIMB_ONLINE_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_VIEW',
  DigitalReportingPayrollRptIntegrationCimbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbZakatTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_ZAKAT_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationCimbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_CIMB_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationHlbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_VIEW',
  DigitalReportingPayrollRptIntegrationHlbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbConnectfirstPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_CONNECTFIRST_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbKwspExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_KWSP_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbSocsoExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_SOCSO_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbLhdnExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_LHDN_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbTabungHajiExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_TABUNG_HAJI_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHlbZakatExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_ZAKAT_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationHsbcView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HSBC_VIEW',
  DigitalReportingPayrollRptIntegrationHsbcPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HSBC_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankPayrollM2UCsvView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_PAYROLL_M2U_CSV_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankPayrollM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_PAYROLL_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankPayrollMasapsTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_PAYROLL_MASAPS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankKwspM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_KWSP_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankSocsoM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_SOCSO_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankEisM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_EIS_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankLhdnM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_LHDN_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationMaybankZakatM2ETxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_MAYBANK_ZAKAT_M2E_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationRhbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_VIEW',
  DigitalReportingPayrollRptIntegrationRhbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbPayrollIbgExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_PAYROLL_IBG_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbSmartPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_SMART_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbKwspExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_KWSP_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbSocsoExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_SOCSO_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationRhbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationRhbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_VIEW',
  DigitalReportingPayrollRptIntegrationUobPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationUobEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_UOB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcKwspTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_KWSP_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationOcbcLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_OCBC_LHDN_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationPbbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_VIEW',
  DigitalReportingPayrollRptIntegrationPbbPayrollExcelView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_PAYROLL_EXCEL_VIEW',
  DigitalReportingPayrollRptIntegrationPbbPayrollTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_PAYROLL_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationPbbEpfTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_EPF_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationPbbEisTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_EIS_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationPbbSocsoTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_SOCSO_TXT_VIEW',
  DigitalReportingPayrollRptIntegrationPbbLhdnTxtView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_PBB_LHDN_TXT_VIEW',
  DigitalReportingLeaveView = 'DIGITAL_REPORTING_LEAVE_______VIEW',
  DigitalReportingLeaveRptLeaveAnalysisView = 'DIGITAL_REPORTING_LEAVE_RPT_LEAVE_ANALYSIS____VIEW',
  DigitalReportingLeaveRptLeaveEntitlementBalanceAnalysisView = 'DIGITAL_REPORTING_LEAVE_RPT_LEAVE_ENTITLEMENT_BALANCE_ANALYSIS____VIEW',
  DigitalReportingLeaveRptLeaveTakenSummaryView = 'DIGITAL_REPORTING_LEAVE_RPT_LEAVE_TAKEN_SUMMARY____VIEW',
  DigitalReportingClaimView = 'DIGITAL_REPORTING_CLAIM_______VIEW',
  DigitalReportingClaimRptClaimBalanceAnalysisView = 'DIGITAL_REPORTING_CLAIM_RPT_CLAIM_BALANCE_ANALYSIS____VIEW',
  DigitalReportingClaimRptClaimStatusAnalysisView = 'DIGITAL_REPORTING_CLAIM_RPT_CLAIM_STATUS_ANALYSIS____VIEW',
  DigitalReportingClaimRptClaimDetailListingView = 'DIGITAL_REPORTING_CLAIM_RPT_CLAIM_DETAIL_LISTING____VIEW',
  DigitalReportingClaimRptStaffClaimView = 'DIGITAL_REPORTING_CLAIM_RPT_STAFF_CLAIM_VIEW',
  DigitalReportingClaimRptIntegrationView = 'DIGITAL_REPORTING_CLAIM_RPT_INTEGRATION____VIEW',
  DigitalReportingClaimRptIntegrationHlbView = 'DIGITAL_REPORTING_CLAIM_RPT_INTEGRATION_HLB_VIEW',
  DigitalReportingHelpdeskView = 'DIGITAL_REPORTING_HELPDESK_______VIEW',
  DigitalReportingHelpdeskRptHelpdeskListingView = 'DIGITAL_REPORTING_HELPDESK_RPT_HELPDESK_LISTING____VIEW',
  HomeDigitalReportingView = 'HOME_DIGITAL_REPORTING_______VIEW',
  DigitalReportingPersonnelView = 'DIGITAL_REPORTING_PERSONNEL_______VIEW',
  DigitalReportingPayrollBankPayoutView = 'DIGITAL_REPORTING_PAYROLL____BANK_PAYOUT_VIEW',
  DigitalReportingPayrollRptBankView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK____VIEW',
  DigitalReportingPayrollRptPayrollView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL____VIEW',
  DigitalReportingPayrollRptLhdnView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN____VIEW',
  DigitalReportingPayrollRptIntegrationView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION____VIEW',
  DigitalReportingPayrollView = 'DIGITAL_REPORTING_PAYROLL_______VIEW',
  DigitalReportingTmsView = 'DIGITAL_REPORTING_TMS_VIEW',
  DigitalReportingTmsAttendanceListingRptView = 'DIGITAL_REPORTING_TMS_ATTENDANCE_LISTING_RPT_VIEW',
  DigitalReportingTmsEmployeeClockingListingRptView = 'DIGITAL_REPORTING_TMS_EMPLOYEE_CLOCKING_LISTING_RPT_VIEW',
  DigitalReportingTmsPeriodicOvertimeForecastListingRptView = 'DIGITAL_REPORTING_TMS_PERIODIC_OVERTIME_FORECAST_LISTING_RPT_VIEW',
  DigitalReportingTmsMonthlyAttendanceReportListingRptView = 'DIGITAL_REPORTING_TMS_MONTHLY_ATTENDANCE_REPORT_LISTING_RPT_VIEW',
  DigitalReportingTmsAbsenteeismListingRptView = 'DIGITAL_REPORTING_TMS_ABSENTEEISM_LISTING_RPT_VIEW',
  DigitalReportingTmsIncompleteClockingListingRptView = 'DIGITAL_REPORTING_TMS_INCOMPLETE_CLOCKING_LISTING_RPT_VIEW',
  DigitalReportingTmsIrregularAttendanceListingRptView = 'DIGITAL_REPORTING_TMS_IRREGULAR_ATTENDANCE_LISTING_RPT_VIEW',
  DigitalReportingTmsLateInEarlyOutListingRptView = 'DIGITAL_REPORTING_TMS_LATE_IN_EARLY_OUT_LISTING_RPT_VIEW',
  DigitalReportingTmsOtMealSummaryRptView = 'DIGITAL_REPORTING_TMS_OT_MEAL_SUMMARY_RPT_VIEW',
  DigitalReportingTmsOtMealDetailRptView = 'DIGITAL_REPORTING_TMS_OT_MEAL_DETAIL_RPT_VIEW',
  DigitalReportingProjectTimesheetView = 'DIGITAL_REPORTING_PROJECT_TIMESHEET_VIEW',
  DigitalReportingProjectTimesheetRptView = 'DIGITAL_REPORTING_PROJECT_TIMESHEET_RPT_VIEW',
  DigitalReportingProjectTimesheetSummaryRptView = 'DIGITAL_REPORTING_PROJECT_TIMESHEET_SUMMARY_RPT_VIEW',
  MulticurrencySetupView = 'MULTICURRENCY_SETUP_VIEW',
  MulticurrencySetupManualEntryView = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_VIEW',
  MulticurrencySetupManualEntryCreate = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_CREATE',
  MulticurrencySetupManualEntryUpdate = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_UPDATE',
  MulticurrencySetupManualEntryDelete = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_DELETE',
  MulticurrencySetupManualEntryReactivate = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_REACTIVATE',
  MulticurrencySetupManualEntryInactive = 'MULTICURRENCY_SETUP_MANUAL_ENTRY_INACTIVE',
  MulticurrencySetupManualExcelUploadView = 'MULTICURRENCY_SETUP_MANUAL_EXCEL_UPLOAD_VIEW',
  MulticurrencySetupManualExcelUploadImport = 'MULTICURRENCY_SETUP_MANUAL_EXCEL_UPLOAD_IMPORT',
  MulticurrencySetupManualExcelUploadDownload = 'MULTICURRENCY_SETUP_MANUAL_EXCEL_UPLOAD_DOWNLOAD',
  CommonSettingProjectExpenseRead = 'COMMON_SETTING_PROJECT_EXPENSE_READ',
  CommonSettingProjectExpenseProjectMasterRead = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_READ',
  CommonSettingProjectExpenseProjectMasterCreate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_CREATE',
  CommonSettingProjectExpenseProjectMasterUpdate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_UPDATE',
  CommonSettingProjectExpenseProjectMasterDelete = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_MASTER_DELETE',
  CommonSettingProjectExpenseProjectExpenseTypeRead = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_READ',
  CommonSettingProjectExpenseProjectExpenseTypeCreate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_CREATE',
  CommonSettingProjectExpenseProjectExpenseTypeUpdate = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_UPDATE',
  CommonSettingProjectExpenseProjectExpenseTypeDelete = 'COMMON_SETTING_PROJECT_EXPENSE_PROJECT_EXPENSE_TYPE_DELETE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyRead = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___READ',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyCreate = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___CREATE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyUpdate = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___UPDATE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyDelete = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___DELETE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyInactive = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___INACTIVE',
  CommonSettingProjectProjectExpenseClaimApprovalPolicyReactivate = 'COMMON_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY___REACTIVATE',
  CommonSettingProjectExpenseGlAccountMappingRead = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_READ',
  CommonSettingProjectExpenseGlAccountMappingCreate = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_CREATE',
  CommonSettingProjectExpenseGlAccountMappingUpdate = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_UPDATE',
  CommonSettingProjectExpenseGlAccountMappingDelete = 'COMMON_SETTING_PROJECT_EXPENSE_GL_ACCOUNT_MAPPING_DELETE',
  HomeProjectExecSummaryRead = 'HOME_PROJECT_EXEC_SUMMARY____READ',
  CompanySettingProjectRead = 'COMPANY_SETTING_PROJECT_______READ',
  CompanySettingProjectProjectExpenseClaimSettingRead = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_SETTING___READ',
  CompanySettingProjectProjectExpenseClaimSettingUpdate = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_SETTING___UPDATE',
  CompanySettingProjectGlAccountMappingAssignmentRead = 'COMPANY_SETTING_PROJECT_GL_ACCOUNT_MAPPING_ASSIGNMENT___READ',
  CompanySettingProjectGlAccountMappingAssignmentUpdate = 'COMPANY_SETTING_PROJECT_GL_ACCOUNT_MAPPING_ASSIGNMENT___UPDATE',
  CompanySettingProjectProjectExpenseClaimApprovalPolicyAssigmentRead = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY_ASSIGMENT___READ',
  CompanySettingProjectProjectExpenseClaimApprovalPolicyAssigmentUpdate = 'COMPANY_SETTING_PROJECT_PROJECT_EXPENSE_CLAIM_APPROVAL_POLICY_ASSIGMENT___UPDATE',
  HomeProjectRead = 'HOME_PROJECT_______READ',
  HomeProjectStaffProjectCostAllocationRead = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____READ',
  HomeProjectStaffProjectCostAllocationCreate = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____CREATE',
  HomeProjectStaffProjectCostAllocationUpdate = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____UPDATE',
  HomeProjectStaffProjectCostAllocationDelete = 'HOME_PROJECT_STAFF_PROJECT_COST_ALLOCATION____DELETE',
  HomeProjectProjectExpenseClaimApplicationRead = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____READ',
  HomeProjectProjectExpenseClaimApplicationCreate = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____CREATE',
  HomeProjectProjectExpenseClaimApplicationApprove = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____APPROVE',
  HomeProjectProjectExpenseClaimApplicationReject = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____REJECT',
  HomeProjectProjectExpenseClaimApplicationWithdraw = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_APPLICATION____WITHDRAW',
  HomeProjectApprovedProjectExpenseClaimVerificationRead = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____READ',
  HomeProjectApprovedProjectExpenseClaimVerificationVerify = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____VERIFY',
  HomeProjectApprovedProjectExpenseClaimVerificationReject = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____REJECT',
  HomeProjectApprovedProjectExpenseClaimVerificationReverse = 'HOME_PROJECT_APPROVED_PROJECT_EXPENSE_CLAIM_VERIFICATION____REVERSE',
  HomeProjectProjectExpenseClaimPostingToPayrollRead = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_POSTING_TO_PAYROLL____READ',
  HomeProjectProjectExpenseClaimPostingToPayrollPost = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_POSTING_TO_PAYROLL____POST',
  HomeProjectProjectExpenseClaimPostingToPayrollReverse = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_POSTING_TO_PAYROLL____REVERSE',
  HomeProjectProjectExpenseClaimPaymentRead = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_PAYMENT____READ',
  HomeProjectProjectExpenseClaimPaymentPost = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_PAYMENT____POST',
  HomeProjectProjectExpenseClaimPaymentReverse = 'HOME_PROJECT_PROJECT_EXPENSE_CLAIM_PAYMENT____REVERSE',
  HomeProjectGlProcessingRead = 'HOME_PROJECT_GL_PROCESSING____READ',
  HomeProjectGlProcessingPayrollGlProcessingWithCostAllocationRead = 'HOME_PROJECT_GL_PROCESSING_PAYROLL_GL_PROCESSING_WITH_COST_ALLOCATION____READ',
  HomeProjectGlProcessingPayrollGlProcessingWithCostAllocationProcess = 'HOME_PROJECT_GL_PROCESSING_PAYROLL_GL_PROCESSING_WITH_COST_ALLOCATION____PROCESS',
  HomeProjectGlProcessingPayrollGlProcessingWithCostAllocationExport = 'HOME_PROJECT_GL_PROCESSING_PAYROLL_GL_PROCESSING_WITH_COST_ALLOCATION____EXPORT',
  HomeProjectGlProcessingClaimGlProcessingWithCostAllocationRead = 'HOME_PROJECT_GL_PROCESSING_CLAIM_GL_PROCESSING_WITH_COST_ALLOCATION____READ',
  HomeProjectGlProcessingClaimGlProcessingWithCostAllocationProcess = 'HOME_PROJECT_GL_PROCESSING_CLAIM_GL_PROCESSING_WITH_COST_ALLOCATION____PROCESS',
  HomeProjectGlProcessingClaimGlProcessingWithCostAllocationExport = 'HOME_PROJECT_GL_PROCESSING_CLAIM_GL_PROCESSING_WITH_COST_ALLOCATION____EXPORT',
  HomeProjectGlExportRead = 'HOME_PROJECT_GL_EXPORT____READ',
  HomeProjectGlExportPayrollGlExportWithCostAllocationRead = 'HOME_PROJECT_GL_EXPORT_PAYROLL_GL_EXPORT_WITH_COST_ALLOCATION____READ',
  HomeProjectGlExportPayrollGlExportWithCostAllocationExport = 'HOME_PROJECT_GL_EXPORT_PAYROLL_GL_EXPORT_WITH_COST_ALLOCATION____EXPORT',
  HomeProjectGlExportClaimGlExportWithCostAllocationRead = 'HOME_PROJECT_GL_EXPORT_CLAIM_GL_EXPORT_WITH_COST_ALLOCATION____READ',
  HomeProjectGlExportClaimGlExportWithCostAllocationExport = 'HOME_PROJECT_GL_EXPORT_CLAIM_GL_EXPORT_WITH_COST_ALLOCATION____EXPORT',
  CommonSettingPersonnelDataChangesRead = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___READ',
  CommonSettingPersonnelDataChangesCreate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___CREATE',
  CommonSettingPersonnelDataChangesUpdate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___UPDATE',
  CommonSettingPersonnelDataChangesDelete = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___DELETE',
  CommonSettingPersonnelDataChangesInactive = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___INACTIVE',
  CommonSettingPersonnelDataChangesReactivate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES___REACTIVATE',
  CommonSettingPersonnelDataChangesPolicyView = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___VIEW',
  CommonSettingPersonnelDataChangesPolicyCreate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___CREATE',
  CommonSettingPersonnelDataChangesPolicyRead = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___READ',
  CommonSettingPersonnelDataChangesPolicyUpdate = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___UPDATE',
  CommonSettingPersonnelDataChangesPolicyDelete = 'COMMON_SETTING_PERSONNEL_DATA_CHANGES_POLICY___DELETE',
  CompanySettingPersonnelDataChangesRead = 'COMPANY_SETTING_PERSONNEL_DATA_CHANGES___READ',
  CompanySettingPersonnelDataChangesUpdate = 'COMPANY_SETTING_PERSONNEL_DATA_CHANGES___UPDATE',
  HomePersonnelDataChangesRead = 'HOME_PERSONNEL_DATA_CHANGES____READ',
  HomePersonnelDataChangesApprove = 'HOME_PERSONNEL_DATA_CHANGES____APPROVE',
  HomePersonnelDataChangesReject = 'HOME_PERSONNEL_DATA_CHANGES____REJECT',
  CommonSettingTmsBluetoothBeaconDevicesRead = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___READ',
  CommonSettingTmsBluetoothBeaconDevicesCreate = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___CREATE',
  CommonSettingTmsBluetoothBeaconDevicesUpdate = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___UPDATE',
  CommonSettingTmsBluetoothBeaconDevicesDelete = 'COMMON_SETTING_TMS_BLUETOOTH_BEACON_DEVICES___DELETE',
  CompanySettingTmsBluetoothBeaconDevicesAssignmentAssignmentRead = 'COMPANY_SETTING_TMS_BLUETOOTH_BEACON_DEVICES_ASSIGNMENT_ASSIGNMENT___READ',
  CompanySettingTmsBluetoothBeaconDevicesAssignmentAssignmentUpdate = 'COMPANY_SETTING_TMS_BLUETOOTH_BEACON_DEVICES_ASSIGNMENT_ASSIGNMENT___UPDATE',
  CommonSettingPerformanceRead = 'COMMON_SETTING_PERFORMANCE_______READ',
  CommonSettingPerformanceFormItemRatingSetupRead = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______READ',
  CommonSettingPerformanceFormItemRatingSetupCreate = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______CREATE',
  CommonSettingPerformanceFormItemRatingSetupUpdate = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______UPDATE',
  CommonSettingPerformanceFormItemRatingSetupDelete = 'COMMON_SETTING_PERFORMANCE_FORM_ITEM_RATING_SETUP_______DELETE',
  CommonSettingPerformanceReviewGradingTableRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______READ',
  CommonSettingPerformanceReviewGradingTableCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______CREATE',
  CommonSettingPerformanceReviewGradingTableUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______UPDATE',
  CommonSettingPerformanceReviewGradingTableDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_GRADING_TABLE_______DELETE',
  CommonSettingPerformanceReviewPolicyRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______READ',
  CommonSettingPerformanceReviewPolicyCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______CREATE',
  CommonSettingPerformanceReviewPolicyUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______UPDATE',
  CommonSettingPerformanceReviewPolicyDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_POLICY_______DELETE',
  CommonSettingPerformanceKpiCategoryRead = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______READ',
  CommonSettingPerformanceKpiCategoryCreate = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______CREATE',
  CommonSettingPerformanceKpiCategoryUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______UPDATE',
  CommonSettingPerformanceKpiCategoryDelete = 'COMMON_SETTING_PERFORMANCE_KPI_CATEGORY_______DELETE',
  CommonSettingPerformanceKpiPredefinedItemRead = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______READ',
  CommonSettingPerformanceKpiPredefinedItemCreate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______CREATE',
  CommonSettingPerformanceKpiPredefinedItemUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______UPDATE',
  CommonSettingPerformanceKpiPredefinedItemDelete = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______DELETE',
  CommonSettingPerformanceKpiPredefinedItemReactivate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______REACTIVATE',
  CommonSettingPerformanceKpiPredefinedItemInactive = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_ITEM_______INACTIVE',
  CommonSettingPerformanceKpiPredefinedTemplateRead = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______READ',
  CommonSettingPerformanceKpiPredefinedTemplateCreate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______CREATE',
  CommonSettingPerformanceKpiPredefinedTemplateUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______UPDATE',
  CommonSettingPerformanceKpiPredefinedTemplateDelete = 'COMMON_SETTING_PERFORMANCE_KPI_PREDEFINED_TEMPLATE_______DELETE',
  CommonSettingPerformanceReviewItemRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______READ',
  CommonSettingPerformanceReviewItemCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______CREATE',
  CommonSettingPerformanceReviewItemUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______UPDATE',
  CommonSettingPerformanceReviewItemDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_ITEM_______DELETE',
  CommonSettingPerformanceReviewTemplateRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______READ',
  CommonSettingPerformanceReviewTemplateCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______CREATE',
  CommonSettingPerformanceReviewTemplateUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______UPDATE',
  CommonSettingPerformanceReviewTemplateDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_TEMPLATE_______DELETE',
  CommonSettingPerformanceReviewProcessPolicyRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______READ',
  CommonSettingPerformanceReviewProcessPolicyCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______CREATE',
  CommonSettingPerformanceReviewProcessPolicyUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______UPDATE',
  CommonSettingPerformanceReviewProcessPolicyDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_PROCESS_POLICY_______DELETE',
  CommonSettingPerformanceReviewFormTemplateRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______READ',
  CommonSettingPerformanceReviewFormTemplateCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______CREATE',
  CommonSettingPerformanceReviewFormTemplateUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______UPDATE',
  CommonSettingPerformanceReviewFormTemplateDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_FORM_TEMPLATE_______DELETE',
  CommonSettingPerformanceReviewGroupRead = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______READ',
  CommonSettingPerformanceReviewGroupCreate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______CREATE',
  CommonSettingPerformanceReviewGroupUpdate = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______UPDATE',
  CommonSettingPerformanceReviewGroupDelete = 'COMMON_SETTING_PERFORMANCE_REVIEW_GROUP_______DELETE',
  CommonSettingPerformanceRewardGroupRead = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______READ',
  CommonSettingPerformanceRewardGroupCreate = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______CREATE',
  CommonSettingPerformanceRewardGroupUpdate = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______UPDATE',
  CommonSettingPerformanceRewardGroupDelete = 'COMMON_SETTING_PERFORMANCE_REWARD_GROUP_______DELETE',
  HomePerformanceRead = 'HOME_PERFORMANCE_______READ',
  CompanySettingPerformanceRead = 'COMPANY_SETTING_PERFORMANCE_______READ',
  CompanySettingPerformanceReviewGroupMatrixRead = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___READ',
  CommonSettingPersonnelPersonnelEFileSecurityPolicyRead = 'COMMON_SETTING_PERSONNEL_PERSONNEL_E_FILE_SECURITY_POLICY___READ',
  CompanySettingPerformanceReviewGroupMatrixCreate = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___CREATE',
  CompanySettingPerformanceReviewGroupMatrixUpdate = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___UPDATE',
  CompanySettingPerformanceReviewGroupMatrixDelete = 'COMPANY_SETTING_PERFORMANCE_REVIEW_GROUP_MATRIX___DELETE',
  CompanySettingPerformanceRewardGroupMatrixRead = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___READ',
  CompanySettingPerformanceRewardGroupMatrixCreate = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___CREATE',
  CompanySettingPerformanceRewardGroupMatrixUpdate = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___UPDATE',
  CompanySettingPerformanceRewardGroupMatrixDelete = 'COMPANY_SETTING_PERFORMANCE_REWARD_GROUP_MATRIX___DELETE',
  HomePerformanceOrganizationalGoalRead = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____READ',
  HomePerformanceOrganizationalGoalCreate = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____CREATE',
  HomePerformanceOrganizationalGoalUpdate = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____UPDATE',
  HomePerformanceOrganizationalGoalDelete = 'HOME_PERFORMANCE_ORGANIZATIONAL_GOAL____DELETE',
  HomePerformanceOrganizationalKpiPlanningRead = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____READ',
  HomePerformanceOrganizationalKpiPlanningCreate = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____CREATE',
  HomePerformanceOrganizationalKpiPlanningUpdate = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____UPDATE',
  HomePerformanceOrganizationalKpiPlanningDelete = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____DELETE',
  HomePerformanceOrganizationalKpiPlanningChangeOwner = 'HOME_PERFORMANCE_ORGANIZATIONAL_KPI_PLANNING____CHANGE_OWNER',
  HomePerformanceKpiExerciseRead = 'HOME_PERFORMANCE_KPI_EXERCISE____READ',
  HomePerformanceKpiExerciseSetupRead = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____READ',
  HomePerformanceKpiExerciseSetupCreate = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____CREATE',
  HomePerformanceKpiExerciseSetupUpdate = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____UPDATE',
  HomePerformanceKpiExerciseSetupDelete = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____DELETE',
  HomePerformanceKpiExerciseSetupGenerateKpi = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____GENERATE_KPI',
  HomePerformanceKpiExerciseSetupDuplicate = 'HOME_PERFORMANCE_KPI_EXERCISE_SETUP____DUPLICATE',
  HomePerformanceKpiExerciseListingRead = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____READ',
  HomePerformanceKpiExerciseListingCreate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____CREATE',
  HomePerformanceKpiExerciseListingUpdate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____UPDATE',
  HomePerformanceKpiExerciseListingDelete = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____DELETE',
  HomePerformanceKpiExerciseListingInitiate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____INITIATE',
  HomePerformanceKpiExerciseListingRevert = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____REVERT',
  HomePerformanceKpiExerciseListingClearRevert = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____CLEAR_REVERT',
  HomePerformanceKpiExerciseListingDuplicate = 'HOME_PERFORMANCE_KPI_EXERCISE_LISTING____DUPLICATE',
  CommonSettingPersonnelPersonnelEFileSecurityPolicyUpdate = 'COMMON_SETTING_PERSONNEL_PERSONNEL_E_FILE_SECURITY_POLICY___UPDATE',
  CommonSettingPerformanceKpiProcessPolicyRead = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______READ',
  CommonSettingPerformanceKpiProcessPolicyCreate = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______CREATE',
  CommonSettingPerformanceKpiProcessPolicyUpdate = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______UPDATE',
  CommonSettingPerformanceKpiProcessPolicyDelete = 'COMMON_SETTING_PERFORMANCE_KPI_PROCESS_POLICY_______DELETE',
  HomePerformanceEmployeeKpiRecordsRead = 'HOME_PERFORMANCE_EMPLOYEE_KPI_RECORDS____READ',
  HomePerformanceEmployeeKpiRecordsView = 'HOME_PERFORMANCE_EMPLOYEE_KPI_RECORDS____VIEW',
  HomePerformanceEmployeeReviewRecordsRead = 'HOME_PERFORMANCE_EMPLOYEE_REVIEW_RECORDS____READ',
  HomePerformanceEmployeeReviewRecordsView = 'HOME_PERFORMANCE_EMPLOYEE_REVIEW_RECORDS____VIEW',
  HomePerformanceReviewExerciseRead = 'HOME_PERFORMANCE_REVIEW_EXERCISE____READ',
  HomePerformanceReviewExerciseSetupRead = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____READ',
  HomePerformanceReviewExerciseSetupCreate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____CREATE',
  HomePerformanceReviewExerciseSetupView = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____VIEW',
  HomePerformanceReviewExerciseSetupUpdate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____UPDATE',
  HomePerformanceReviewExerciseSetupDelete = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____DELETE',
  HomePerformanceReviewExerciseSetupGenerate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____GENERATE',
  HomePerformanceReviewExerciseSetupDuplicate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____DUPLICATE',
  HomePerformanceReviewExerciseSetupPeerRegistration = 'HOME_PERFORMANCE_REVIEW_EXERCISE_SETUP____PEER_REGISTRATION',
  HomePerformanceReviewExerciseListingRead = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____READ',
  HomePerformanceReviewExerciseListingProceed = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____PROCEED',
  HomePerformanceReviewExerciseListingView = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____VIEW',
  HomePerformanceReviewExerciseListingUpdate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____UPDATE',
  HomePerformanceReviewExerciseListingDelete = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____DELETE',
  HomePerformanceReviewExerciseListingInitiate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____INITIATE',
  HomePerformanceReviewExerciseListingReInitiate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____RE_INITIATE',
  HomePerformanceReviewExerciseListingRevert = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____REVERT',
  HomePerformanceReviewExerciseListingClearRevert = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____CLEAR_REVERT',
  HomePerformanceReviewExerciseListingDuplicate = 'HOME_PERFORMANCE_REVIEW_EXERCISE_LISTING____DUPLICATE',
  HomeMymenuMyPerformancePerformanceReviewMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_PERFORMANCE_REVIEW_MYMENU_____READ',
  HomeMymenuMyPerformancePerformanceReviewMymenuView = 'HOME_MYMENU_MY_PERFORMANCE_PERFORMANCE_REVIEW_MYMENU_____VIEW',
  HomeMymenuMyPerformancePerformanceReviewMymenuSubmit = 'HOME_MYMENU_MY_PERFORMANCE_PERFORMANCE_REVIEW_MYMENU_____SUBMIT',
  HomeMymenuMyPerformanceReviewActionMymenuRead = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____READ',
  HomeMymenuMyPerformanceReviewActionMymenuView = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____VIEW',
  HomeMymenuMyPerformanceReviewActionMymenuUpdate = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____UPDATE',
  HomeMymenuMyPerformanceReviewActionMymenuApprove = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____APPROVE',
  HomeMymenuMyPerformanceReviewActionMymenuSendBack = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____SEND_BACK',
  HomeMymenuMyPerformanceReviewActionMymenuProceed = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____PROCEED',
  HomeMymenuMyPerformanceReviewActionMymenuSave = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____SAVE',
  HomeMymenuMyPerformanceReviewActionMymenuSubmit = 'HOME_MYMENU_MY_PERFORMANCE_REVIEW_ACTION_MYMENU_____SUBMIT',
  HomeMysubordinateSubordinatePerformanceManagementPerformanceReviewMysubordinateRead = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_PERFORMANCE_REVIEW_MYSUBORDINATE_____READ',
  HomeMysubordinateSubordinatePerformanceManagementPerformanceReviewMysubordinateView = 'HOME_MYSUBORDINATE_SUBORDINATE_PERFORMANCE_MANAGEMENT_PERFORMANCE_REVIEW_MYSUBORDINATE_____VIEW',
  CommonSettingTmsAutomatedDataImportRead = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___READ',
  CommonSettingTmsAutomatedDataImportCreate = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___CREATE',
  CommonSettingTmsAutomatedDataImportUpdate = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___UPDATE',
  CommonSettingTmsAutomatedDataImportDelete = 'COMMON_SETTING_TMS_AUTOMATED_DATA_IMPORT___DELETE',
  CommonSettingHelpdeskRead = 'COMMON_SETTING_HELPDESK_______READ',
  CommonSettingHelpdeskHelpdeskSubjectCreate = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___CREATE',
  CommonSettingHelpdeskHelpdeskSubjectRead = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___READ',
  CommonSettingHelpdeskHelpdeskSubjectUpdate = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___UPDATE',
  CommonSettingHelpdeskHelpdeskSubjectDelete = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___DELETE',
  CommonSettingHelpdeskHelpdeskSubjectInactive = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___INACTIVE',
  CommonSettingHelpdeskHelpdeskSubjectReactivate = 'COMMON_SETTING_HELPDESK_HELPDESK_SUBJECT___REACTIVATE',
  HomeHelpdeskRead = 'HOME_HELPDESK_______READ',
  HomeHelpdeskHelpdeskMasterListingRead = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___READ',
  HomeHelpdeskHelpdeskMasterListingView = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___VIEW',
  HomeHelpdeskHelpdeskMasterListingUpdate = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___UPDATE',
  HomeHelpdeskHelpdeskMasterListingCreate = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___CREATE',
  HomeHelpdeskHelpdeskMasterListingDelete = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___DELETE',
  HomeHelpdeskHelpdeskMasterListingReassign = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___REASSIGN',
  HomeHelpdeskHelpdeskMasterListingWithdraw = 'HOME_HELPDESK_HELPDESK_MASTER_LISTING___WITHDRAW',
  HomeHelpdeskAssigneeListingRead = 'HOME_HELPDESK_ASSIGNEE_LISTING___READ',
  HomeHelpdeskAssigneeListingView = 'HOME_HELPDESK_ASSIGNEE_LISTING___VIEW',
  HomeHelpdeskAssigneeListingUpdate = 'HOME_HELPDESK_ASSIGNEE_LISTING___UPDATE',
  HomeHelpdeskMyTicketsListingRead = 'HOME_HELPDESK_MY_TICKETS_LISTING___READ',
  HomeHelpdeskMyTicketsListingView = 'HOME_HELPDESK_MY_TICKETS_LISTING___VIEW',
  HomeHelpdeskMyTicketsListingCreate = 'HOME_HELPDESK_MY_TICKETS_LISTING___CREATE',
  HomeHelpdeskMyTicketsListingWithdraw = 'HOME_HELPDESK_MY_TICKETS_LISTING___WITHDRAW',
  HomeBusinessInsightsRead = 'HOME_BUSINESS_INSIGHTS_______READ',
  HomeBusinessInsightsHeadcountRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_______READ',
  HomeBusinessInsightsHeadcountHeadcountSalaryRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_HEADCOUNT_SALARY_______READ',
  HomeBusinessInsightsHeadcountYearsOfServiceRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_YEARS_OF_SERVICE_______READ',
  HomeBusinessInsightsHeadcountSalaryRangeRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_SALARY_RANGE_______READ',
  HomeBusinessInsightsHeadcountAgeGroupRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_AGE_GROUP_______READ',
  HomeBusinessInsightsHeadcountEmploymentTypeRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_EMPLOYMENT_TYPE_______READ',
  HomeBusinessInsightsHeadcountEmployeeRaceRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_EMPLOYEE_RACE_______READ',
  HomeBusinessInsightsHeadcountGenderRead = 'HOME_BUSINESS_INSIGHTS_HEADCOUNT_GENDER_______READ',
  HomeBusinessInsightsOverheadRead = 'HOME_BUSINESS_INSIGHTS_OVERHEAD_______READ',
  HomeBusinessInsightsOverheadOverheadRead = 'HOME_BUSINESS_INSIGHTS_OVERHEAD_OVERHEAD_______READ',
  HomeBusinessInsightsOverheadStatutoryContributionRead = 'HOME_BUSINESS_INSIGHTS_OVERHEAD_STATUTORY_CONTRIBUTION_______READ',
  HomeBusinessInsightsOvertimeRead = 'HOME_BUSINESS_INSIGHTS_OVERTIME_______READ',
  HomeBusinessInsightsOvertimeOtHoursCostRead = 'HOME_BUSINESS_INSIGHTS_OVERTIME_OT_HOURS_COST_______READ',
  HomeBusinessInsightsLeaveRead = 'HOME_BUSINESS_INSIGHTS_LEAVE_______READ',
  HomeBusinessInsightsLeaveLeaveAnalysisRead = 'HOME_BUSINESS_INSIGHTS_LEAVE_LEAVE_ANALYSIS_______READ',
  HomeBusinessInsightsStaffClaimRead = 'HOME_BUSINESS_INSIGHTS_STAFF_CLAIM_______READ',
  HomeBusinessInsightsStaffClaimClaimAnalysisRead = 'HOME_BUSINESS_INSIGHTS_STAFF_CLAIM_CLAIM_ANALYSIS_______READ',
  HomeBusinessInsightsStaffTurnoverRead = 'HOME_BUSINESS_INSIGHTS_STAFF_TURNOVER_______READ',
  HomeBusinessInsightsStaffTurnoverOffboardingAnalysisRead = 'HOME_BUSINESS_INSIGHTS_STAFF_TURNOVER_OFFBOARDING_ANALYSIS_______READ',
  CommonSettingTimesheetRead = 'COMMON_SETTING_TIMESHEET_______READ',
  CommonSettingTimesheetProjectTimesheetApproverMasterRead = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___READ',
  CommonSettingTimesheetProjectTimesheetApproverMasterCreate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___CREATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterUpdate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___UPDATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterDelete = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___DELETE',
  CommonSettingTimesheetProjectTimesheetApproverMasterResendEmail = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER___RESEND_EMAIL',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentRead = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___READ',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentCreate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___CREATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentUpdate = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___UPDATE',
  CommonSettingTimesheetProjectTimesheetApproverMasterProjectTimesheetApproverAssignmentDelete = 'COMMON_SETTING_TIMESHEET_PROJECT_TIMESHEET_APPROVER_MASTER_PROJECT_TIMESHEET_APPROVER_ASSIGNMENT___DELETE',
  HomeMymenuMyTrainingRead = 'HOME_MYMENU_MY_TRAINING_____READ',
  HomeMymenuMyTrainingTrainingCheckInRead = 'HOME_MYMENU_MY_TRAINING_TRAINING_CHECK_IN_____READ',
  HomeMymenuMyTrainingTrainingCheckInCreate = 'HOME_MYMENU_MY_TRAINING_TRAINING_CHECK_IN_____CREATE',
  HomeMymenuMyTrainingScheduledTrainingRead = 'HOME_MYMENU_MY_TRAINING_SCHEDULED_TRAINING_____READ',
  HomeMymenuMyTrainingScheduledTrainingUpdate = 'HOME_MYMENU_MY_TRAINING_SCHEDULED_TRAINING_____UPDATE',
  HomeMymenuMyTrainingTrainingCalendarRead = 'HOME_MYMENU_MY_TRAINING_TRAINING_CALENDAR_____READ',
  HomeMymenuMyTrainingTrainingCalendarCreate = 'HOME_MYMENU_MY_TRAINING_TRAINING_CALENDAR_____CREATE',
  HomeMymenuMyTrainingRequiredTrainingRead = 'HOME_MYMENU_MY_TRAINING_REQUIRED_TRAINING_____READ',
  HomeMymenuMyTrainingRequestForTrainingRead = 'HOME_MYMENU_MY_TRAINING_REQUEST_FOR_TRAINING_____READ',
  HomeMymenuMyTrainingRequestForTrainingCreate = 'HOME_MYMENU_MY_TRAINING_REQUEST_FOR_TRAINING_____CREATE',
  HomeMymenuMyTrainingRequestForTrainingCancel = 'HOME_MYMENU_MY_TRAINING_REQUEST_FOR_TRAINING_____CANCEL',
  HomeMymenuMyTrainingRequestForTrainingView = 'HOME_MYMENU_MY_TRAINING_REQUEST_FOR_TRAINING_____VIEW',
  HomeLeaveMaxLeaveBalanceForfeitureRead = 'HOME_LEAVE_MAX_LEAVE_BALANCE_FORFEITURE____READ',
  HomeLeaveMaxLeaveBalanceForfeiturePost = 'HOME_LEAVE_MAX_LEAVE_BALANCE_FORFEITURE____POST',
  HomeLeaveMaxLeaveBalanceForfeitureReverse = 'HOME_LEAVE_MAX_LEAVE_BALANCE_FORFEITURE____REVERSE',
  HomeLeaveLeaveEntitlementByEmployeeReprocess = 'HOME_LEAVE_LEAVE_ENTITLEMENT_BY_EMPLOYEE____REPROCESS',
  CommonSettingRecruitmentRead = 'COMMON_SETTING_RECRUITMENT_READ',
  CommonSettingRecruitmentApprovalPoliciesRead = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_READ',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentRequisitionApprovalRead = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_REQUISITION_APPROVAL____READ',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentRequisitionApprovalCreate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_REQUISITION_APPROVAL____CREATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentRequisitionApprovalUpdate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_REQUISITION_APPROVAL____UPDATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentRequisitionApprovalDelete = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_REQUISITION_APPROVAL____DELETE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOfferApprovalRead = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_OFFER_APPROVAL____READ',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOfferApprovalCreate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_OFFER_APPROVAL____CREATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOfferApprovalUpdate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_OFFER_APPROVAL____UPDATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOfferApprovalDelete = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_OFFER_APPROVAL____DELETE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOnboardApprovalRead = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_ONBOARD_APPROVAL____READ',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOnboardApprovalCreate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_ONBOARD_APPROVAL____CREATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOnboardApprovalUpdate = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_ONBOARD_APPROVAL____UPDATE',
  CommonSettingRecruitmentApprovalPoliciesRecruitmentOnboardApprovalDelete = 'COMMON_SETTING_RECRUITMENT_APPROVAL_POLICIES_RECRUITMENT_ONBOARD_APPROVAL____DELETE',
  CommonSettingRecruitmentRecruitmentSourceRead = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_SOURCE_READ',
  CommonSettingRecruitmentRecruitmentSourceCreate = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_SOURCE_CREATE',
  CommonSettingRecruitmentRecruitmentSourceUpdate = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_SOURCE_UPDATE',
  CommonSettingRecruitmentRecruitmentSourceDelete = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_SOURCE_DELETE',
  CommonSettingRecruitmentRecruitmentExpensesRead = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_EXPENSES_READ',
  CommonSettingRecruitmentRecruitmentExpensesCreate = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_EXPENSES_CREATE',
  CommonSettingRecruitmentRecruitmentExpensesUpdate = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_EXPENSES_UPDATE',
  CommonSettingRecruitmentRecruitmentExpensesDelete = 'COMMON_SETTING_RECRUITMENT_RECRUITMENT_EXPENSES_DELETE',
  CommonSettingRecruitmentEApplicationFormSetupCreate = 'COMMON_SETTING_RECRUITMENT_E_APPLICATION_FORM_SETUP_CREATE',
  CommonSettingRecruitmentEApplicationFormSetupUpdate = 'COMMON_SETTING_RECRUITMENT_E_APPLICATION_FORM_SETUP_UPDATE',
  CommonSettingRecruitmentEApplicationFormSetupRead = 'COMMON_SETTING_RECRUITMENT_E_APPLICATION_FORM_SETUP_READ',
  CommonSettingRecruitmentEApplicationFormSetupDelete = 'COMMON_SETTING_RECRUITMENT_E_APPLICATION_FORM_SETUP_DELETE',
  CommonSettingRecruitmentELetterTemplateRead = 'COMMON_SETTING_RECRUITMENT_E_LETTER_TEMPLATE_READ',
  CommonSettingRecruitmentELetterTemplateCreate = 'COMMON_SETTING_RECRUITMENT_E_LETTER_TEMPLATE_CREATE',
  CommonSettingRecruitmentELetterTemplateUpdate = 'COMMON_SETTING_RECRUITMENT_E_LETTER_TEMPLATE_UPDATE',
  CommonSettingRecruitmentELetterTemplateDelete = 'COMMON_SETTING_RECRUITMENT_E_LETTER_TEMPLATE_DELETE',
  CommonSettingRecruitmentPreofferChecklistRead = 'COMMON_SETTING_RECRUITMENT_PREOFFER_CHECKLIST_READ',
  CommonSettingRecruitmentPreofferChecklistCreate = 'COMMON_SETTING_RECRUITMENT_PREOFFER_CHECKLIST_CREATE',
  CommonSettingRecruitmentPreofferChecklistUpdate = 'COMMON_SETTING_RECRUITMENT_PREOFFER_CHECKLIST_UPDATE',
  CommonSettingRecruitmentPreofferChecklistDelete = 'COMMON_SETTING_RECRUITMENT_PREOFFER_CHECKLIST_DELETE',
  CommonSettingRecruitmentTalentPoolAdditionalUserFieldsRead = 'COMMON_SETTING_RECRUITMENT_TALENT_POOL_ADDITIONAL_USER_FIELDS_READ',
  CommonSettingRecruitmentTalentPoolAdditionalUserFieldsCreate = 'COMMON_SETTING_RECRUITMENT_TALENT_POOL_ADDITIONAL_USER_FIELDS_CREATE',
  CommonSettingRecruitmentTalentPoolAdditionalUserFieldsUpdate = 'COMMON_SETTING_RECRUITMENT_TALENT_POOL_ADDITIONAL_USER_FIELDS_UPDATE',
  CommonSettingRecruitmentTalentPoolAdditionalUserFieldsDelete = 'COMMON_SETTING_RECRUITMENT_TALENT_POOL_ADDITIONAL_USER_FIELDS_DELETE',
  CommonSettingRecruitmentDeclineReasonRead = 'COMMON_SETTING_RECRUITMENT_DECLINE_REASON_READ',
  CommonSettingRecruitmentDeclineReasonCreate = 'COMMON_SETTING_RECRUITMENT_DECLINE_REASON_CREATE',
  CommonSettingRecruitmentDeclineReasonUpdate = 'COMMON_SETTING_RECRUITMENT_DECLINE_REASON_UPDATE',
  CommonSettingRecruitmentDeclineReasonDelete = 'COMMON_SETTING_RECRUITMENT_DECLINE_REASON_DELETE',
  CommonSettingRecruitmentPrehiringFormSetupCreate = 'COMMON_SETTING_RECRUITMENT_PREHIRING_FORM_SETUP_CREATE',
  CommonSettingRecruitmentPrehiringFormSetupUpdate = 'COMMON_SETTING_RECRUITMENT_PREHIRING_FORM_SETUP_UPDATE',
  CommonSettingRecruitmentPrehiringFormSetupRead = 'COMMON_SETTING_RECRUITMENT_PREHIRING_FORM_SETUP_READ',
  CommonSettingRecruitmentPrehiringFormSetupDelete = 'COMMON_SETTING_RECRUITMENT_PREHIRING_FORM_SETUP_DELETE',
  CommonSettingRecruitmentJobPortalSetupRead = 'COMMON_SETTING_RECRUITMENT_JOB_PORTAL_SETUP_READ',
  CommonSettingRecruitmentJobPortalSetupCreate = 'COMMON_SETTING_RECRUITMENT_JOB_PORTAL_SETUP_CREATE',
  CommonSettingRecruitmentJobPortalSetupUpdate = 'COMMON_SETTING_RECRUITMENT_JOB_PORTAL_SETUP_UPDATE',
  CommonSettingRecruitmentJobPortalSetupDelete = 'COMMON_SETTING_RECRUITMENT_JOB_PORTAL_SETUP_DELETE',
  HomeRecruitmentRead = 'HOME_RECRUITMENT____READ',
  HomeRecruitmentRecruitmentSettingRead = 'HOME_RECRUITMENT_RECRUITMENT_SETTING____READ',
  HomeRecruitmentRecruitmentSettingRecruitmentSettingApprovalRead = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_SETTING_APPROVAL____READ',
  HomeRecruitmentRecruitmentSettingRecruitmentRequisitionApprovalRead = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_REQUISITION_APPROVAL____READ',
  HomeRecruitmentRecruitmentSettingRecruitmentRequisitionApprovalCreate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_REQUISITION_APPROVAL____CREATE',
  HomeRecruitmentRecruitmentSettingRecruitmentRequisitionApprovalUpdate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_REQUISITION_APPROVAL____UPDATE',
  HomeRecruitmentRecruitmentSettingRecruitmentRequisitionApprovalDelete = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_REQUISITION_APPROVAL____DELETE',
  HomeRecruitmentRecruitmentSettingRecruitmentOfferApprovalRead = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_OFFER_APPROVAL____READ',
  HomeRecruitmentRecruitmentSettingRecruitmentOfferApprovalCreate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_OFFER_APPROVAL____CREATE',
  HomeRecruitmentRecruitmentSettingRecruitmentOfferApprovalUpdate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_OFFER_APPROVAL____UPDATE',
  HomeRecruitmentRecruitmentSettingRecruitmentOfferApprovalDelete = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_OFFER_APPROVAL____DELETE',
  HomeRecruitmentRecruitmentSettingRecruitmentOnboardApprovalRead = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_ONBOARD_APPROVAL____READ',
  HomeRecruitmentRecruitmentSettingRecruitmentOnboardApprovalCreate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_ONBOARD_APPROVAL____CREATE',
  HomeRecruitmentRecruitmentSettingRecruitmentOnboardApprovalUpdate = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_ONBOARD_APPROVAL____UPDATE',
  HomeRecruitmentRecruitmentSettingRecruitmentOnboardApprovalDelete = 'HOME_RECRUITMENT_RECRUITMENT_SETTING_RECRUITMENT_ONBOARD_APPROVAL____DELETE',
  HomeRecruitmentTalentPoolRead = 'HOME_RECRUITMENT_TALENT_POOL____READ',
  HomeRecruitmentTalentPoolTalentPoolListingRead = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_POOL_LISTING____READ',
  HomeRecruitmentTalentPoolTalentPoolListingView = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_POOL_LISTING____VIEW',
  HomeRecruitmentTalentPoolTalentPoolListingCreate = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_POOL_LISTING____CREATE',
  HomeRecruitmentTalentPoolTalentPoolListingUpdate = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_POOL_LISTING____UPDATE',
  HomeRecruitmentTalentPoolTalentPoolListingDelete = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_POOL_LISTING____DELETE',
  HomeRecruitmentTalentPoolTalentInvitationRead = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____READ',
  HomeRecruitmentTalentPoolTalentInvitationCreate = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____CREATE',
  HomeRecruitmentTalentPoolTalentInvitationResendEmail = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____RESEND_EMAIL',
  HomeRecruitmentTalentPoolTalentInvitationCancel = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____CANCEL',
  HomeRecruitmentTalentPoolTalentInvitationUpdate = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____UPDATE',
  HomeRecruitmentTalentPoolTalentInvitationView = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_INVITATION____VIEW',
  HomeRecruitmentTalentPoolTalentSearchRead = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_SEARCH____READ',
  HomeRecruitmentTalentPoolTalentSearchView = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_SEARCH____VIEW',
  HomeRecruitmentTalentPoolTalentSearchCreate = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_SEARCH____CREATE',
  HomeRecruitmentTalentPoolTalentSearchDelete = 'HOME_RECRUITMENT_TALENT_POOL_TALENT_SEARCH____DELETE',
  HomeRecruitmentRecruitmentRequisitionRead = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____READ',
  HomeRecruitmentRecruitmentRequisitionView = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____VIEW',
  HomeRecruitmentRecruitmentRequisitionUpdate = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____UPDATE',
  HomeRecruitmentRecruitmentRequisitionCreate = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____CREATE',
  HomeRecruitmentRecruitmentRequisitionDelete = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____DELETE',
  HomeRecruitmentRecruitmentRequisitionWithdraw = 'HOME_RECRUITMENT_RECRUITMENT_REQUISITION____WITHDRAW',
  HomeRecruitmentJobVacancyManagementRead = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT____READ',
  HomeRecruitmentJobVacancyManagementJobVacancyRead = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_VACANCY____READ',
  HomeRecruitmentJobVacancyManagementJobVacancyCreate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_VACANCY____CREATE',
  HomeRecruitmentJobVacancyManagementJobVacancyUpdate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_VACANCY____UPDATE',
  HomeRecruitmentJobVacancyManagementJobVacancyDelete = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_VACANCY____DELETE',
  HomeRecruitmentJobVacancyManagementJobVacancyView = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_VACANCY____VIEW',
  HomeRecruitmentJobVacancyManagementJobPostingRead = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_POSTING____READ',
  HomeRecruitmentJobVacancyManagementJobPostingCreate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_POSTING____CREATE',
  HomeRecruitmentJobVacancyManagementJobPostingUpdate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_POSTING____UPDATE',
  HomeRecruitmentJobVacancyManagementJobPostingDelete = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_POSTING____DELETE',
  HomeRecruitmentJobVacancyManagementJobPostingView = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_JOB_POSTING____VIEW',
  HomeRecruitmentJobVacancyManagementApplicantManagementRead = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_APPLICANT_MANAGEMENT____READ',
  HomeRecruitmentJobVacancyManagementApplicantManagementCreate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_APPLICANT_MANAGEMENT____CREATE',
  HomeRecruitmentJobVacancyManagementApplicantManagementUpdate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_APPLICANT_MANAGEMENT____UPDATE',
  HomeRecruitmentJobVacancyManagementApplicantManagementDelete = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_APPLICANT_MANAGEMENT____DELETE',
  HomeRecruitmentJobVacancyManagementInterviewSchedulingView = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_INTERVIEW_SCHEDULING____VIEW',
  HomeRecruitmentJobOfferManagementRead = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT____READ',
  HomeRecruitmentJobOfferManagementFinalApplicantSelectionRead = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_FINAL_APPLICANT_SELECTION____READ',
  HomeRecruitmentJobOfferManagementFinalApplicantSelectionCreate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_FINAL_APPLICANT_SELECTION____CREATE',
  HomeRecruitmentJobOfferManagementFinalApplicantSelectionUpdate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_FINAL_APPLICANT_SELECTION____UPDATE',
  HomeRecruitmentJobOfferManagementFinalApplicantSelectionDelete = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_FINAL_APPLICANT_SELECTION____DELETE',
  HomeRecruitmentJobOfferManagementOfferProposalRead = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_PROPOSAL____READ',
  HomeRecruitmentJobOfferManagementOfferProposalCreate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_PROPOSAL____CREATE',
  HomeRecruitmentJobOfferManagementOfferProposalUpdate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_PROPOSAL____UPDATE',
  HomeRecruitmentJobOfferManagementOfferProposalDelete = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_PROPOSAL____DELETE',
  HomeRecruitmentJobVacancyManagementInterviewSchedulingRead = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_INTERVIEW_SCHEDULING____READ',
  HomeRecruitmentJobVacancyManagementInterviewSchedulingCreate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_INTERVIEW_SCHEDULING____CREATE',
  HomeRecruitmentJobVacancyManagementInterviewSchedulingUpdate = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_INTERVIEW_SCHEDULING____UPDATE',
  HomeRecruitmentJobVacancyManagementInterviewSchedulingDelete = 'HOME_RECRUITMENT_JOB_VACANCY_MANAGEMENT_INTERVIEW_SCHEDULING____DELETE',
  HomeRecruitmentJobOfferManagementOfferProposalView = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_PROPOSAL____VIEW',
  HomeRecruitmentJobOfferManagementOfferApprovalRead = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_APPROVAL____READ',
  HomeRecruitmentJobOfferManagementOfferApprovalCreate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_APPROVAL____CREATE',
  HomeRecruitmentJobOfferManagementOfferApprovalUpdate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_APPROVAL____UPDATE',
  HomeRecruitmentJobOfferManagementOfferApprovalDelete = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_APPROVAL____DELETE',
  HomeRecruitmentJobOfferManagementOfferApprovalView = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_OFFER_APPROVAL____VIEW',
  HomeRecruitmentJobOfferManagementApprovedJobOfferProcessingRead = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_APPROVED_JOB_OFFER_PROCESSING____READ',
  HomeRecruitmentJobOfferManagementApprovedJobOfferProcessingCreate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_APPROVED_JOB_OFFER_PROCESSING____CREATE',
  HomeRecruitmentJobOfferManagementApprovedJobOfferProcessingUpdate = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_APPROVED_JOB_OFFER_PROCESSING____UPDATE',
  HomeRecruitmentJobOfferManagementApprovedJobOfferProcessingDelete = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_APPROVED_JOB_OFFER_PROCESSING____DELETE',
  HomeRecruitmentJobOfferManagementApprovedJobOfferProcessingView = 'HOME_RECRUITMENT_JOB_OFFER_MANAGEMENT_APPROVED_JOB_OFFER_PROCESSING____VIEW',
  HomeRecruitmentHiringProcessRead = 'HOME_RECRUITMENT_HIRING_PROCESS____READ',
  HomeRecruitmentHiringProcessAcceptedOfferPrehiringRead = 'HOME_RECRUITMENT_HIRING_PROCESS_ACCEPTED_OFFER_PREHIRING____READ',
  HomeRecruitmentHiringProcessAcceptedOfferPrehiringSendEmail = 'HOME_RECRUITMENT_HIRING_PROCESS_ACCEPTED_OFFER_PREHIRING____SEND_EMAIL',
  HomeRecruitmentHiringProcessAcceptedOfferPrehiringResendEmail = 'HOME_RECRUITMENT_HIRING_PROCESS_ACCEPTED_OFFER_PREHIRING____RESEND_EMAIL',
  HomeRecruitmentHiringProcessQuickHiringRead = 'HOME_RECRUITMENT_HIRING_PROCESS_QUICK_HIRING____READ',
  HomeRecruitmentHiringProcessQuickHiringCreate = 'HOME_RECRUITMENT_HIRING_PROCESS_QUICK_HIRING____CREATE',
  HomeRecruitmentHiringProcessQuickHiringUpdate = 'HOME_RECRUITMENT_HIRING_PROCESS_QUICK_HIRING____UPDATE',
  HomeRecruitmentHiringProcessQuickHiringDelete = 'HOME_RECRUITMENT_HIRING_PROCESS_QUICK_HIRING____DELETE',
  HomeRecruitmentHiringProcessQuickHiringSendEmail = 'HOME_RECRUITMENT_HIRING_PROCESS_QUICK_HIRING____SEND_EMAIL',
  HomeRecruitmentHiringProcessPrehiringDataUpdateRead = 'HOME_RECRUITMENT_HIRING_PROCESS_PREHIRING_DATA_UPDATE____READ',
  HomeRecruitmentHiringProcessPrehiringDataUpdateCancel = 'HOME_RECRUITMENT_HIRING_PROCESS_PREHIRING_DATA_UPDATE____CANCEL',
  HomeRecruitmentHiringProcessPrehiringDataUpdateView = 'HOME_RECRUITMENT_HIRING_PROCESS_PREHIRING_DATA_UPDATE____VIEW',
  HomeRecruitmentHiringProcessPrehiringDataUpdateUpdate = 'HOME_RECRUITMENT_HIRING_PROCESS_PREHIRING_DATA_UPDATE____UPDATE',
  HomeRecruitmentHiringProcessPrehiringDataUpdateResendEmail = 'HOME_RECRUITMENT_HIRING_PROCESS_PREHIRING_DATA_UPDATE____RESEND_EMAIL',
  HomeRecruitmentHiringProcessNewHireProcessRead = 'HOME_RECRUITMENT_HIRING_PROCESS_NEW_HIRE_PROCESS____READ',
  HomeRecruitmentHiringProcessNewHireProcessUpdate = 'HOME_RECRUITMENT_HIRING_PROCESS_NEW_HIRE_PROCESS____UPDATE',
  HomeRecruitmentHiringProcessNewHireProcessView = 'HOME_RECRUITMENT_HIRING_PROCESS_NEW_HIRE_PROCESS____VIEW',
  HomeMymenuMyRecruitmentRead = 'HOME_MYMENU_MY_RECRUITMENT_____READ',
  HomeMymenuMyRecruitmentTalentSearchRead = 'HOME_MYMENU_MY_RECRUITMENT_TALENT_SEARCH____READ',
  HomeMymenuMyRecruitmentTalentSearchView = 'HOME_MYMENU_MY_RECRUITMENT_TALENT_SEARCH____VIEW',
  HomeMymenuMyRecruitmentTalentSearchCreate = 'HOME_MYMENU_MY_RECRUITMENT_TALENT_SEARCH____CREATE',
  HomeMymenuMyRecruitmentTalentSearchDelete = 'HOME_MYMENU_MY_RECRUITMENT_TALENT_SEARCH____DELETE',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuRead = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____READ',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuCreate = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____CREATE',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuView = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____VIEW',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuUpdate = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____UPDATE',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuSubmit = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____SUBMIT',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuDelete = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____DELETE',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuCancel = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____CANCEL',
  HomeMymenuMyRecruitmentRecruitmentRequisitionMymenuWithdraw = 'HOME_MYMENU_MY_RECRUITMENT_RECRUITMENT_REQUISITION_MYMENU_____WITHDRAW',
  HomeMymenuMyRecruitmentApplicantManagementRead = 'HOME_MYMENU_MY_RECRUITMENT_APPLICANT_MANAGEMENT____READ',
  HomeMymenuMyRecruitmentApplicantManagementCreate = 'HOME_MYMENU_MY_RECRUITMENT_APPLICANT_MANAGEMENT____CREATE',
  HomeMymenuMyRecruitmentApplicantManagementUpdate = 'HOME_MYMENU_MY_RECRUITMENT_APPLICANT_MANAGEMENT____UPDATE',
  HomeMymenuMyRecruitmentApplicantManagementDelete = 'HOME_MYMENU_MY_RECRUITMENT_APPLICANT_MANAGEMENT____DELETE',
  HomeMymenuMyRecruitmentInterviewSchedulingMymenuRead = 'HOME_MYMENU_MY_RECRUITMENT_INTERVIEW_SCHEDULING_MYMENU_____READ',
  HomeMymenuMyRecruitmentInterviewSchedulingMymenuUpdate = 'HOME_MYMENU_MY_RECRUITMENT_INTERVIEW_SCHEDULING_MYMENU_____UPDATE',
  HomeMymenuMyRecruitmentInterviewSchedulingMymenuView = 'HOME_MYMENU_MY_RECRUITMENT_INTERVIEW_SCHEDULING_MYMENU_____VIEW',
  HomeMymenuMyRecruitmentOfferListingRead = 'HOME_MYMENU_MY_RECRUITMENT_OFFER_LISTING____READ',
  HomeMymenuMyRecruitmentOfferListingView = 'HOME_MYMENU_MY_RECRUITMENT_OFFER_LISTING____VIEW'
}

export type HrdcClaimEntity = NewInterfaceBase1 & {
  __typename?: 'HrdcClaimEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  application_no: Scalars['String'];
  amount_claimed: Scalars['Float'];
  total_trainee?: Maybe<Scalars['Float']>;
  approved_claimed?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  document_id: Scalars['String'];
  voice_document_id?: Maybe<Scalars['String']>;
  approval_status: Approval_Status;
  HRDCClaimLog?: Maybe<Array<HrdcClaimLogEntity>>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  Document?: Maybe<DocumentsEntity>;
  voiceDocument?: Maybe<DocumentsEntity>;
};

export type HrdcClaimLogEntity = NewInterfaceBase1 & {
  __typename?: 'HrdcClaimLogEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  hrdc_claim_id: Scalars['String'];
  approver_id: Scalars['String'];
  approval_status: Approval_Status;
  remarks?: Maybe<Scalars['String']>;
  HRDCClaim?: Maybe<HrdcClaimEntity>;
  approverName: Scalars['String'];
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type IncrementSimulationEntity = InterfaceBase & {
  __typename?: 'IncrementSimulationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IncrementSimulationID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Company?: Maybe<CompanyEntity>;
};

export type IncrementSimulationInput = {
  IncrementSimulationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  QuaterID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  Version?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['String']>;
  QualifierID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InsuranceSchemeEntity = InterfaceBase & {
  __typename?: 'InsuranceSchemeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  InsuranceSchemeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  Document?: Maybe<DocumentsEntity>;
};

export type InsuranceSchemeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InsuranceSchemeInsertInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  Name: Scalars['String'];
  DocumentInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
};

export enum IntegrationClockType {
  In = 'IN',
  Out = 'OUT',
  Empty = 'Empty'
}

export type InterbankGiroEntity = InterfaceBase & {
  __typename?: 'InterbankGiroEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  InterbankGiroID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Bank>;
  IBGCode?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
};

export type InterbankGiroInput = {
  InterbankGiroID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Bank>;
  IBGCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceBase = {
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceBaseInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
};

export type InterfaceBaseV2 = {
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
};

export type InterfaceBaseV3 = {
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum InterviewerRating {
  VeryPoor = 'Very_Poor',
  Poor = 'Poor',
  Average = 'Average',
  Good = 'Good',
  VeryGood = 'Very_Good'
}

export enum InviteStatus {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Approved = 'Approved'
}

export type InvoiceRpaResult = {
  __typename?: 'InvoiceRPAResult';
  InvoiceDate?: Maybe<Scalars['String']>;
  InvoiceId?: Maybe<Scalars['String']>;
  InvoiceTotal?: Maybe<Scalars['Float']>;
  VendorName?: Maybe<Scalars['String']>;
};

export enum ItemActionType {
  New = 'New',
  View = 'View',
  Edit = 'Edit',
  Delete = 'Delete'
}

export enum ItemRatingMethod {
  Comment = 'Comment',
  FormItemRating = 'Form_Item_Rating'
}



export type JobAccountEntity = InterfaceBase & {
  __typename?: 'JobAccountEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobAccountID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsChargeable?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  MyJobAccount?: Maybe<Array<MyJobAccountEntity>>;
  ClaimHeader?: Maybe<Array<ClaimHeaderEntity>>;
};

export type JobAccountInput = {
  JobAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsChargeable?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobCompetencyRclEntity = InterfaceBase & {
  __typename?: 'JobCompetencyRCLEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobCompetencyRCLID: Scalars['String'];
  AppraisalTempleteByJobPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  RCLWeightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  AppraisalTempleteByJobPosition?: Maybe<AppraisalTempleteByJobPositionEntity>;
};

export type JobCompetencyRclInput = {
  JobCompetencyRCLID?: Maybe<Scalars['String']>;
  AppraisalTempleteByJobPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  RCLWeightage?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobDesignationEntity = InterfaceBase & {
  __typename?: 'JobDesignationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobDesignationID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsAdmin?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  JobDesignationGrades?: Maybe<Array<JobDesignationGradeEntity>>;
  JobDesignationSkillSets?: Maybe<Array<JobDesignationSkillSetEntity>>;
  Employees?: Maybe<Array<EmployeeEntity>>;
  AppraisalTempleteByJobPosition?: Maybe<AppraisalTempleteByJobPositionEntity>;
  IsInUse: Scalars['Boolean'];
};

export type JobDesignationGradeEntity = InterfaceBase & {
  __typename?: 'JobDesignationGradeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobDesignationGradeID: Scalars['String'];
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  JobGrade: JobGradeEntity;
  JobDesignation: JobDesignationEntity;
};

export type JobDesignationGradeInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
};

export type JobDesignationInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobDesignationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsAdmin?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type JobDesignationSkillSetEntity = InterfaceBase & {
  __typename?: 'JobDesignationSkillSetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobDesignationSkillSetID: Scalars['String'];
  JobDesignationID?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SkillSetDetailID?: Maybe<Scalars['String']>;
  SkillSet?: Maybe<SkillSetEntity>;
  SkillSetDetail?: Maybe<SkillSetDetailEntity>;
  JobDesignation?: Maybe<JobDesignationEntity>;
};

export type JobDesignationSkillSetInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobDesignationID?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SkillSetDetailID?: Maybe<Scalars['String']>;
};

export type JobGradeBulkInsertInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobGradeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type JobGradeByJobDesignationOutput = {
  __typename?: 'JobGradeByJobDesignationOutput';
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<JobGradeOutput>;
};

export type JobGradeEntity = InterfaceBase & {
  __typename?: 'JobGradeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  LeaveBenefit?: Maybe<LeaveBenefitEntity>;
  ClaimBenefit?: Maybe<ClaimBenefitEntity>;
  JobDesignationGrades?: Maybe<Array<JobDesignationGradeEntity>>;
  JobGradePolicies?: Maybe<Array<JobGradePolicyEntity>>;
};

export type JobGradeInput = {
  JobGradeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobGradeOutput = {
  __typename?: 'JobGradeOutput';
  JobGradeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type JobGradePolicyEntity = InterfaceBase & {
  __typename?: 'JobGradePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradePolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  LeaveTemplates?: Maybe<Array<LeaveTemplateEntity>>;
  ClaimTemplates?: Maybe<Array<ClaimTemplateEntity>>;
};

export type JobGradePolicyInput = {
  JobGradePolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobGradePolicyMatrixEntity = InterfaceBase & {
  __typename?: 'JobGradePolicyMatrixEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradePolicyMatrixID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  JobGradeID: Scalars['String'];
  OvertimeClaimTableID: Scalars['String'];
  DailyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
  OvertimeClaimTable?: Maybe<OvertimeClaimTableEntity>;
};

export type JobGradePolicyMatrixInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobGradePolicyMatrixID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  DailyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
};

export type JobGradesEntity = InterfaceBase & {
  __typename?: 'JobGradesEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type JobGradesInput = {
  name?: Maybe<Scalars['String']>;
};

export type JobPositionAssignmentEntity = InterfaceBase & {
  __typename?: 'JobPositionAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobPositionAssignmentID: Scalars['String'];
  JobPositionID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type JobPositionAssignmentInput = {
  JobPositionAssignmentID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobPositionListingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  JobPositionReportingID?: Maybe<Scalars['String']>;
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  ToCompanyID?: Maybe<Scalars['String']>;
  ItemActionType?: Maybe<ItemActionType>;
};

export type JobPositionReportingEntity = NewInterfaceBase & {
  __typename?: 'JobPositionReportingEntity';
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogs>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  job_position_reporting_id: Scalars['String'];
  company_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  reporting_structure_id?: Maybe<Scalars['String']>;
  reporting_structure?: Maybe<ReportingStructureEntity>;
  from_org_unit_id?: Maybe<Scalars['String']>;
  from_org_unit?: Maybe<CompanyOrgUnitEntity>;
  to_org_unit_id?: Maybe<Scalars['String']>;
  to_org_unit?: Maybe<CompanyOrgUnitEntity>;
  from_job_position_id?: Maybe<Scalars['String']>;
  from_job_position?: Maybe<JobDesignationEntity>;
  to_job_position_id?: Maybe<Scalars['String']>;
  to_job_position?: Maybe<JobDesignationEntity>;
  company?: Maybe<CompanyEntity>;
  to_company_id?: Maybe<Scalars['String']>;
  to_company?: Maybe<CompanyEntity>;
  ItemActionType?: Maybe<ItemActionType>;
};

export type JobPositionReportingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ReportingStructureID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  JobPositionList: Array<JobPositionListingInput>;
  ItemActionType?: Maybe<ItemActionType>;
  ReportingRecordType?: Maybe<ReportingRecordType>;
};

export type JobPositionReportingOutput = {
  __typename?: 'JobPositionReportingOutput';
  ReportingStructureID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  ItemActionType: ItemActionType;
  ReportingType: ReportingType;
  JobPositionList?: Maybe<Array<JobPositionReportingEntity>>;
};

export type JobVacancyStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<RecruitmentJobVacancyStatus>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};

export type JoinedDateError = {
  __typename?: 'JoinedDateError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export enum KpiType {
  Quantitative = 'Quantitative',
  Qualitative = 'Qualitative'
}

export type KpiActiveInactiveInput = {
  ID: Scalars['String'];
  Status: Status;
  ModifiedBy: Scalars['String'];
};

export type KpiCategoryEntity = InterfaceBase & {
  __typename?: 'KpiCategoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  OrgGoals?: Maybe<Array<OrganizationalGoalEntity>>;
  OrgKpiPlannings?: Maybe<Array<OrgKpiPlanningDetailsEntity>>;
  InUse: Scalars['Boolean'];
  PredefinedItemCount: Scalars['Float'];
};

export type KpiCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type KpiCategoryItemInput = {
  KpiPredefinedItemID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  Weightage: Scalars['Float'];
  Targets: Scalars['String'];
  Initiatives: Scalars['String'];
  RatingScaleTable: Array<RatingScaleTableInput>;
  IsComplete?: Maybe<Scalars['Boolean']>;
};

export type KpiExerciseDetailsEditInput = {
  ID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<CompositionRulesInput>>;
  KpiWeightage?: Maybe<Array<KpiMinMaxWeightage>>;
};

export type KpiExerciseDetailsEntity = InterfaceBase & {
  __typename?: 'KpiExerciseDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<Scalars['JSON']>>;
  KpiWeightage?: Maybe<Array<Scalars['JSON']>>;
  KpiExercise?: Maybe<KpiExerciseEntity>;
  ReviewGroup?: Maybe<ReviewGroupEntity>;
  FormItemRating?: Maybe<FormItemRatingEntity>;
  KpiPredefinedTemplate?: Maybe<KpiPredefinedTemplateEntity>;
  EmployeeKpiExerciseID?: Maybe<Scalars['String']>;
  EmployeeSubmitDT?: Maybe<Scalars['DateTime']>;
};

export type KpiExerciseEntity = InterfaceBase & {
  __typename?: 'KpiExerciseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Superior?: Maybe<EmployeeEntity>;
  Approver?: Maybe<EmployeeEntity>;
  ReviewGroupName?: Maybe<Scalars['String']>;
};

export type KpiExerciseSetupDetailsEntity = InterfaceBase & {
  __typename?: 'KpiExerciseSetupDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<Scalars['JSON']>>;
  KpiWeightage?: Maybe<Array<Scalars['JSON']>>;
  KpiExerciseSetup?: Maybe<KpiExerciseSetupEntity>;
};

export type KpiExerciseSetupDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  ReviewGroupID: Scalars['String'];
  KpiRule: KpiSource;
  FormItemRatingID?: Maybe<Scalars['String']>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  WeightageType: QuantitativeType;
  EditableWeightage?: Maybe<Scalars['Boolean']>;
  CompositionRules?: Maybe<Array<CompositionRulesInput>>;
  KpiWeightage?: Maybe<Array<KpiMinMaxWeightage>>;
};

export type KpiExerciseSetupEntity = InterfaceBase & {
  __typename?: 'KpiExerciseSetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  KpiExerciseSetupDetails?: Maybe<Array<KpiExerciseSetupDetailsEntity>>;
  InUse?: Maybe<Scalars['String']>;
};

export type KpiExerciseSetupInput = {
  ID?: Maybe<Scalars['String']>;
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate: Scalars['Boolean'];
  KpiExerciseSetupDetails: Array<KpiExerciseSetupDetailsInput>;
};

export type KpiMinMaxWeightage = {
  KpiCategoryID: Scalars['String'];
  Title: Scalars['String'];
  Weightage?: Maybe<Scalars['Float']>;
  Min: Scalars['Float'];
  Max: Scalars['Float'];
  KpiItem?: Maybe<Array<KpiCategoryItemInput>>;
  IsComplete?: Maybe<Scalars['Boolean']>;
};

export type KpiPredefinedItemEntity = InterfaceBase & {
  __typename?: 'KpiPredefinedItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  Title: Scalars['String'];
  FormItemRatingID: Scalars['String'];
  Targets: Scalars['String'];
  Initiatives: Scalars['String'];
  KpiCategory: KpiCategoryEntity;
  FormItemRating: FormItemRatingEntity;
  InUse: Scalars['Boolean'];
};

export type KpiPredefinedItemInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  Targets?: Maybe<Scalars['String']>;
  Initiatives?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type KpiPredefinedItemWeightage = {
  __typename?: 'KpiPredefinedItemWeightage';
  KpiPredefinedItemID: Scalars['String'];
  KpiPredefinedItemWeightage: Scalars['Float'];
};

export type KpiPredefinedItemWeightageInput = {
  KpiPredefinedItemID: Scalars['String'];
  KpiPredefinedItemWeightage: Scalars['Float'];
};

export type KpiPredefinedTemplateEntity = InterfaceBase & {
  __typename?: 'KpiPredefinedTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Description: Scalars['String'];
  WeightageType: QuantitativeType;
  KpiWeightage: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
  CategoryCount: Scalars['Float'];
  ItemCount: Scalars['Float'];
};

export type KpiPredefinedTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WeightageType?: Maybe<QuantitativeType>;
  KpiWeightage?: Maybe<Array<KpiWeightageInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export enum KpiSource {
  KpiPredefinedTemplate = 'Kpi_Predefined_Template',
  EmployeeKpi = 'Employee_Kpi'
}

export type KpiWeightage = {
  __typename?: 'KpiWeightage';
  KpiCategoryID: Scalars['String'];
  KpiCategoryWeightage?: Maybe<Scalars['Float']>;
  Sequence: Scalars['Float'];
  KpiPredefinedItem?: Maybe<Array<KpiPredefinedItemWeightage>>;
};

export type KpiWeightageInput = {
  KpiCategoryID: Scalars['String'];
  KpiCategoryWeightage?: Maybe<Scalars['Float']>;
  Sequence: Scalars['Float'];
  KpiPredefinedItem?: Maybe<Array<KpiPredefinedItemWeightageInput>>;
};

export type LhdnReportEntity = InterfaceBase & {
  __typename?: 'LHDNReportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LHDNReportID: Scalars['String'];
  ReportType: ReportType;
  ReportTypeSequence?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  ENDescription?: Maybe<Scalars['String']>;
  BMDescription?: Maybe<Scalars['String']>;
};

export type LhdnReportInput = {
  ReportType?: Maybe<ReportType>;
  ReportTypeSequence?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  ENDescription?: Maybe<Scalars['String']>;
  BMDescription?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LosInput = {
  From?: Maybe<Scalars['String']>;
  Until?: Maybe<Scalars['String']>;
  Day?: Maybe<Scalars['String']>;
  LeaveBenefitRuleID?: Maybe<Scalars['String']>;
};

export enum LanguageLevel {
  None = 'None',
  Slight = 'Slight',
  Fair = 'Fair',
  Fluent = 'Fluent'
}

export type LastUpdatedDatesOutput = {
  __typename?: 'LastUpdatedDatesOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeLastDate?: Maybe<Scalars['DateTime']>;
  AssetsLastDate?: Maybe<Scalars['DateTime']>;
  BIKLastDate?: Maybe<Scalars['DateTime']>;
  CareerLastDate?: Maybe<Scalars['DateTime']>;
  SubordinateLastDate?: Maybe<Scalars['DateTime']>;
  TaxLastDate?: Maybe<Scalars['DateTime']>;
  Cp22LastDate?: Maybe<Scalars['DateTime']>;
  HealthInfoLastDate?: Maybe<Scalars['DateTime']>;
  UserDefineFieldsLastDate?: Maybe<Scalars['DateTime']>;
};

export type Leave = {
  Name?: Maybe<Scalars['String']>;
  NoOfDay?: Maybe<Scalars['Float']>;
  SessionType?: Maybe<SessionType>;
};

export type LeaveAdjustmentListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  WantDraft?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  PeriodYear?: Maybe<Scalars['Float']>;
};

export type LeaveAdjustmentOutput = {
  __typename?: 'LeaveAdjustmentOutput';
  LeaveHeaderID?: Maybe<Scalars['String']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CanDeleteEdit?: Maybe<Scalars['Boolean']>;
};

export type LeaveAppOutput = {
  __typename?: 'LeaveAppOutput';
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type LeaveApprovalUpdateInput = {
  LeaveTypeID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrxStatus?: Maybe<Scalars['String']>;
};

export type LeaveBalanceSummary = {
  __typename?: 'LeaveBalanceSummary';
  BroughtForward?: Maybe<Scalars['Float']>;
  Adjustment?: Maybe<Scalars['Float']>;
  Entitled?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  Earned?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  TakenYTD?: Maybe<Scalars['Float']>;
  Forfeited?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  BalanceYTD?: Maybe<Scalars['Float']>;
  CarryForward?: Maybe<Scalars['Float']>;
  AdvanceBalance?: Maybe<Scalars['Float']>;
  Encashment?: Maybe<Scalars['Float']>;
  Emergency?: Maybe<Scalars['Float']>;
  ApplicationFrequency?: Maybe<Scalars['Float']>;
  Advance?: Maybe<Scalars['Float']>;
  Occurred?: Maybe<Scalars['Float']>;
  CurrentYearAdd?: Maybe<Scalars['Float']>;
  CurrentYearUtilized?: Maybe<Scalars['Float']>;
  CurrentYearBalance?: Maybe<Scalars['Float']>;
  CarryForwardList?: Maybe<Array<Scalars['JSON']>>;
};

export type LeaveBenefitEntity = InterfaceBase & {
  __typename?: 'LeaveBenefitEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveBenefitID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveItems?: Maybe<Array<LeaveItemEntity>>;
};

export type LeaveBenefitInput = {
  LeaveBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveBenefitRuleEntity = InterfaceBase & {
  __typename?: 'LeaveBenefitRuleEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveBenefitRuleID: Scalars['String'];
  LeaveItemID: Scalars['String'];
  FormulaText: Scalars['String'];
  FormulaValue: Scalars['String'];
  TotalDay: Scalars['Float'];
  LeaveRule: LeaveRuleType;
  LOSRange: Scalars['String'];
  LeaveItem?: Maybe<LeaveItemEntity>;
};

export type LeaveBenefitRuleInput = {
  LeaveBenefitRuleID?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
  FormulaText?: Maybe<Scalars['String']>;
  FormulaValue?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveRule?: Maybe<LeaveRuleType>;
  LOSRange?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryEmployeeEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryEmployeeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryEmployeeID: Scalars['String'];
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveCompulsory?: Maybe<LeaveCompulsoryEntity>;
};

export type LeaveCompulsoryEmployeeInput = {
  LeaveCompulsoryEmployeeID?: Maybe<Scalars['String']>;
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  LeaveCompulsoryEmployees?: Maybe<Array<LeaveCompulsoryEmployeeEntity>>;
};

export type LeaveCompulsoryInput = {
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryPolicyEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryPolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Sequence?: Maybe<Sequence>;
};

export type LeaveCompulsoryPolicyInput = {
  LeaveCompulsoryPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCutOffPeriodEntity = InterfaceBase & {
  __typename?: 'LeaveCutOffPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCutOffPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  Company?: Maybe<CompanyEntity>;
};

export type LeaveCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveDetails = {
  __typename?: 'LeaveDetails';
  ApprovalStatus?: Maybe<ApprovalStatus>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveType?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type LeaveDetailsEntity = InterfaceBase & {
  __typename?: 'LeaveDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveDetailsID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  SessionType?: Maybe<SessionType>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  NumberOfDay?: Maybe<Scalars['Float']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  LeaveHeader?: Maybe<LeaveHeaderEntity>;
  EmployeeID: Scalars['String'];
};

export type LeaveDetailsInput = {
  LeaveHeaderID?: Maybe<Scalars['String']>;
  SessionType?: Maybe<SessionType>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  NumberOfDay?: Maybe<Scalars['Float']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type LeaveDiseaseEntity = InterfaceBase & {
  __typename?: 'LeaveDiseaseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveDiseaseID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type LeaveDiseaseInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveEntitlementByLeaveTypeListing = {
  __typename?: 'LeaveEntitlementByLeaveTypeListing';
  LeaveYear?: Maybe<Scalars['Float']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeavePolicy?: Maybe<LeaveItemEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
  EncashedLeaveHistory?: Maybe<EncashedLeaveHistory>;
};

export type LeaveEntitlementEntity = InterfaceBase & {
  __typename?: 'LeaveEntitlementEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveEntitlementID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<Scalars['String']>;
  EmployeeLeavePolicies?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Level?: Maybe<Scalars['Float']>;
  Sort?: Maybe<Scalars['Float']>;
};

export type LeaveEntitlementTemplate = {
  __typename?: 'LeaveEntitlementTemplate';
  LeaveEntitlementName?: Maybe<Scalars['String']>;
  LeaveEntitlementDesc?: Maybe<Scalars['String']>;
  LeaveEntitlementTypes?: Maybe<Array<LeaveEntitlementTypes>>;
};

export type LeaveEntitlementTypes = {
  __typename?: 'LeaveEntitlementTypes';
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveItem?: Maybe<LeaveItemEntity>;
};

export enum LeaveFrequency {
  AnnualIncrement = 'Annual_Increment',
  Occurrence = 'Occurrence',
  ManualEntry = 'Manual_Entry'
}

export type LeaveHeaderEntity = InterfaceBase & {
  __typename?: 'LeaveHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  Superior?: Maybe<Scalars['JSON']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsTms?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  OldCompanyID: Scalars['String'];
  OldEmployeeID: Scalars['String'];
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  TimeOffID: Scalars['String'];
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  VoiceDocumentID: Scalars['String'];
  LeaveReasonID: Scalars['String'];
  DiseaseCodeID: Scalars['String'];
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type LeaveHeaderInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsInput>;
  Remarks?: Maybe<Scalars['String']>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  VoiceDocument?: Maybe<DocumentsInput>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  LeaveDetailsInput?: Maybe<Array<LeaveDetailsInput>>;
  AdvanceBalance?: Maybe<Scalars['Float']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  TimeOffID?: Maybe<Scalars['String']>;
};

export enum LeaveHeaderType {
  OpeningBalance = 'Opening_Balance',
  ClosingBalance = 'Closing_Balance',
  LeaveApplication = 'Leave_Application',
  LeaveAdjustment = 'Leave_Adjustment',
  SystemAdjustment = 'System_Adjustment',
  CompulsoryLeave = 'Compulsory_Leave',
  CarryForward = 'Carry_Forward',
  BroughtForward = 'Brought_Forward',
  InterCoBroughtForward = 'InterCo_BroughtForward',
  InterCoTaken = 'InterCo_Taken',
  InterCoAdjustment = 'InterCo_Adjustment',
  InterCoEarned = 'InterCo_Earned',
  TimeOffDeduction = 'TimeOff_Deduction',
  MaxBalanceForfeit = 'Max_Balance_Forfeit'
}

export type LeaveHomeSummaryOutput = {
  __typename?: 'LeaveHomeSummaryOutput';
  OutOfStateActiveRecords?: Maybe<Scalars['Float']>;
  OutOfStateIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PendingApproval?: Maybe<Scalars['Float']>;
  PendingApprovalIsLastUpdated?: Maybe<Scalars['Boolean']>;
  AdjustmentNoOfRecords?: Maybe<Scalars['Float']>;
  AdjustmentIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NoOfEmployee?: Maybe<Scalars['Float']>;
  EmployeeIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NoOfLeaveType?: Maybe<Scalars['Float']>;
  LeaveTypeIsLastUpdated?: Maybe<Scalars['Boolean']>;
  CompulsoryNoOfRecords?: Maybe<Scalars['Float']>;
  CompulsoryIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PayDeductionLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayDeductionIsLastUpdated?: Maybe<Scalars['Boolean']>;
  YearEndCarryForwardLastPostedDate?: Maybe<Scalars['DateTime']>;
  YearEndCarryForwardIsLastUpdated?: Maybe<Scalars['Boolean']>;
};

export type LeaveIndentity = {
  __typename?: 'LeaveIndentity';
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveEntitledDay?: Maybe<Scalars['Float']>;
  PendingApprovalTotalDay?: Maybe<Scalars['Float']>;
};

export type LeaveItemEntity = InterfaceBase & {
  __typename?: 'LeaveItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveItemID: Scalars['String'];
  LeaveBenefitID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<MonthlyCutOff>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsBackdated?: Maybe<Scalars['Boolean']>;
  BackdatedDays?: Maybe<Scalars['Float']>;
  TotalAdvanceDay?: Maybe<Scalars['Float']>;
  AdvanceStartMonth?: Maybe<Scalars['Float']>;
  AdvanceEndMonth?: Maybe<Scalars['Float']>;
  RemainingBalance?: Maybe<Scalars['String']>;
  EncashmentPayItem?: Maybe<Scalars['String']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  IsDisplayEmployee?: Maybe<Scalars['Boolean']>;
  IsAllowApplyEmployee?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  IsLeaveReason?: Maybe<Scalars['Boolean']>;
  IsLeaveDisease?: Maybe<Scalars['Boolean']>;
  IncludeSickLeave?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  Title?: Maybe<Scalars['String']>;
  EntitlementBasedOn?: Maybe<EntitlementBasedOn>;
  PlatformLeaveItemID?: Maybe<Scalars['String']>;
  LeaveBenefit?: Maybe<LeaveBenefitEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTemplates?: Maybe<Array<LeaveTemplateEntity>>;
  LOS?: Maybe<Array<LeaveBenefitRuleEntity>>;
  OccurrenceEntitlementDay?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  EntDistributionMethod?: Maybe<EntDistributionMethod>;
  EntitlementComputation?: Maybe<EntitlementComputation>;
  MaxApplyLeave?: Maybe<MaxApplyLeave>;
  EntitlementAvailability?: Maybe<EntitlementAvailability>;
  AllowWithdrawal?: Maybe<Scalars['Boolean']>;
  WithdrawalOnDate?: Maybe<Scalars['Boolean']>;
  AutoWithdrawal?: Maybe<Scalars['Boolean']>;
  IsMonthlyMaxBalance?: Maybe<Scalars['Boolean']>;
  MonthlyMaxBalance?: Maybe<Scalars['Float']>;
  CFList?: Maybe<Array<Scalars['JSON']>>;
  IsCrossCheckLeaveBalance?: Maybe<Scalars['Boolean']>;
  CrossCheckLeaveList?: Maybe<Array<Scalars['String']>>;
  InUse: Scalars['Boolean'];
};

export type LeaveItemInput = {
  LeaveItemID?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum LeaveManagementDelegation {
  LeaveSubmissionApproval = 'Leave_Submission_Approval',
  LeaveCancellationApproval = 'Leave_Cancellation_Approval'
}

export type LeaveManagementListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
};

export type LeaveOutOfStateEntity = InterfaceBase & {
  __typename?: 'LeaveOutOfStateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveOutOfStateID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromStateID?: Maybe<Scalars['String']>;
  ToStateID?: Maybe<Scalars['String']>;
  FromCalendarPolicy?: Maybe<Scalars['String']>;
  ToCalendarPolicy?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Employee?: Maybe<EmployeeEntity>;
  FromState?: Maybe<StatesEntity>;
  ToState?: Maybe<StatesEntity>;
  FromCalendarPolicyDetail?: Maybe<CalendarPolicyEntity>;
  ToCalendarPolicyDetail?: Maybe<CalendarPolicyEntity>;
};

export type LeaveOutOfStateInput = {
  LeaveOutOfStateID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  FromStateID?: Maybe<Scalars['String']>;
  ToStateID?: Maybe<Scalars['String']>;
  FromCalendarPolicy?: Maybe<Scalars['String']>;
  ToCalendarPolicy?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type LeavePolicyInput = {
  Title: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveFrequency: LeaveFrequency;
  AvailableBasis?: Maybe<AvailableBasis>;
  EntitlementBasedOn?: Maybe<EntitlementBasedOn>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<MonthlyCutOff>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  OccurrenceEntitlementDay?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  TotalAdvanceDay?: Maybe<Scalars['Float']>;
  AdvanceStartMonth?: Maybe<Scalars['Float']>;
  AdvanceEndMonth?: Maybe<Scalars['Float']>;
  RemainingBalance?: Maybe<Scalars['String']>;
  EncashmentPayItem?: Maybe<Scalars['String']>;
  BackdatedDays?: Maybe<Scalars['Float']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  IsBackdated?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  IsLeaveReason?: Maybe<Scalars['Boolean']>;
  IsLeaveDisease?: Maybe<Scalars['Boolean']>;
  IncludeSickLeave?: Maybe<Scalars['Boolean']>;
  IsDisplayEmployee?: Maybe<Scalars['Boolean']>;
  IsAllowApplyEmployee?: Maybe<Scalars['Boolean']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
  EntDistributionMethod?: Maybe<EntDistributionMethod>;
  EntitlementComputation?: Maybe<EntitlementComputation>;
  MaxApplyLeave?: Maybe<MaxApplyLeave>;
  EntitlementAvailability?: Maybe<EntitlementAvailability>;
  AllowWithdrawal?: Maybe<Scalars['Boolean']>;
  AutoWithdrawal?: Maybe<Scalars['Boolean']>;
  WithdrawalOnDate?: Maybe<Scalars['Boolean']>;
  IsMonthlyMaxBalance?: Maybe<Scalars['Boolean']>;
  MonthlyMaxBalance?: Maybe<Scalars['Float']>;
  CFList?: Maybe<Array<Scalars['JSON']>>;
  IsCrossCheckLeaveBalance?: Maybe<Scalars['Boolean']>;
  CrossCheckLeaveList?: Maybe<Array<Scalars['String']>>;
};

export type LeavePostingInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveHeaderIDs?: Maybe<Array<Scalars['String']>>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveDetailsIDs?: Maybe<Array<Scalars['String']>>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
};

export type LeavePostingListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type LeavePostingOutput = {
  __typename?: 'LeavePostingOutput';
  LeaveHeaderStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  VoiceDocumentName?: Maybe<Scalars['String']>;
  VoiceDocumentFile?: Maybe<Scalars['String']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  LeaveReason?: Maybe<Scalars['String']>;
  DiseaseCode?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  PrivateDocument?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  PayPeriod?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
};

export type LeavePostingPeriodOutput = {
  __typename?: 'LeavePostingPeriodOutput';
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  CountUnpostedLeave?: Maybe<Scalars['Float']>;
  CountPostedLeave?: Maybe<Scalars['Float']>;
  CountLeaveRecords?: Maybe<Scalars['Float']>;
  PeriodStatus?: Maybe<Scalars['String']>;
  PeriodFromDate?: Maybe<Scalars['DateTime']>;
  PeriodToDate?: Maybe<Scalars['DateTime']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  isPostedInTheListing?: Maybe<Scalars['Boolean']>;
};

export type LeaveReasonEntity = InterfaceBase & {
  __typename?: 'LeaveReasonEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveReasonID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type LeaveReasonInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveRecordsListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  WantDraft?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
};

export type LeaveRecordsOutput = {
  __typename?: 'LeaveRecordsOutput';
  LeaveHeaderStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  VoiceDocumentName?: Maybe<Scalars['String']>;
  VoiceDocumentFile?: Maybe<Scalars['String']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  LeaveReason?: Maybe<Scalars['String']>;
  DiseaseCode?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  PrivateDocument?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type LeaveRoundingDetailEntity = InterfaceBase & {
  __typename?: 'LeaveRoundingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveRoundingDetailID: Scalars['String'];
  LeaveRoundingID?: Maybe<Scalars['String']>;
  StartValue: Scalars['Float'];
  EndValue: Scalars['Float'];
  RoundTo: Scalars['Float'];
  LeaveRounding?: Maybe<LeaveRoundingEntity>;
};

export type LeaveRoundingDetailInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  LeaveRoundingDetailID?: Maybe<Scalars['String']>;
  StartValue: Scalars['Float'];
  EndValue: Scalars['Float'];
  RoundTo: Scalars['Float'];
};

export type LeaveRoundingEntity = InterfaceBase & {
  __typename?: 'LeaveRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveRoundingID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  LeaveRoundingDetails?: Maybe<Array<LeaveRoundingDetailEntity>>;
  Companies?: Maybe<Array<CompanyEntity>>;
};

export type LeaveRoundingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
};

export enum LeaveRuleType {
  Los = 'LOS',
  Gender = 'Gender',
  IsConfirmed = 'Is_Confirmed'
}

export type LeaveTakenOutput = {
  __typename?: 'LeaveTakenOutput';
  ExcelFileName?: Maybe<Scalars['String']>;
  UploadedDate?: Maybe<Scalars['DateTime']>;
  TotalRecords?: Maybe<Scalars['Float']>;
};

export type LeaveTempEntitlementEntity = InterfaceBase & {
  __typename?: 'LeaveTempEntitlementEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTempEntitlementID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Year: Scalars['Float'];
  TotalDay: Scalars['Float'];
  AccumulateTotalDay: Scalars['Float'];
};

export type LeaveTempEntitlementInput = {
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Year: Scalars['Float'];
  TotalDay: Scalars['Float'];
  AccumulateTotalDay: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveTemplateEntity = InterfaceBase & {
  __typename?: 'LeaveTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTemplateID: Scalars['String'];
  JobGradePolicyID?: Maybe<Scalars['String']>;
  LeavePolicyID?: Maybe<Scalars['String']>;
  JobGradePolicy?: Maybe<JobGradePolicyEntity>;
  LeaveItem?: Maybe<LeaveItemEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  LeavePolicyIDList?: Maybe<Array<Scalars['JSON']>>;
};

export type LeaveTemplateInput = {
  LeaveTemplateID?: Maybe<Scalars['String']>;
  LeavePolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  LeavePolicyIDList?: Maybe<Array<Scalars['JSON']>>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveTypeBalance = {
  __typename?: 'LeaveTypeBalance';
  LeaveItem?: Maybe<LeaveItemEntity>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
  ToDateEarned?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
  Entitlement?: Maybe<Scalars['Float']>;
  BroughtForward?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  OccurrenceBalance?: Maybe<Scalars['Float']>;
};

export type LeaveTypeEntity = InterfaceBase & {
  __typename?: 'LeaveTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTypeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  LeavePolicies?: Maybe<Array<LeaveItemEntity>>;
};

export type LeaveTypeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LifeTimeError = {
  __typename?: 'LifeTimeError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  companyID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
  isEmployeeSuperUser?: Maybe<Scalars['Boolean']>;
  onPremServer?: Maybe<Scalars['String']>;
};

export type Md_BankEntity = {
  __typename?: 'MD_BankEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNumber?: Maybe<Scalars['String']>;
  BankSwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Postcode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Scalars['String']>;
  IBGCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_CompanyEntity = {
  __typename?: 'MD_CompanyEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  CompanyWebsiteURL?: Maybe<Scalars['String']>;
  TelNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Postcode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  EPFNo?: Maybe<Scalars['String']>;
  SOCSONo?: Maybe<Scalars['String']>;
  LHDNNo?: Maybe<Scalars['String']>;
  EISNo?: Maybe<Scalars['String']>;
  Angkasa?: Maybe<Scalars['String']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  ASB?: Maybe<Scalars['String']>;
  ASBNo?: Maybe<Scalars['String']>;
  KWAP?: Maybe<Scalars['String']>;
  KWAPNo?: Maybe<Scalars['String']>;
  TabungHaji?: Maybe<Scalars['String']>;
  TabungHajiNo?: Maybe<Scalars['String']>;
  Zakat?: Maybe<Scalars['String']>;
  ZakatNo?: Maybe<Scalars['String']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  StatutorySignatoryEmployeeNo?: Maybe<Scalars['String']>;
  StatutorySignatoryEmployeeName?: Maybe<Scalars['String']>;
  StatutorySignatoryCompany?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
};

export type Md_DepartmentDivisionEntity = {
  __typename?: 'MD_DepartmentDivisionEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  DepartmentType?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeDependentEntity = {
  __typename?: 'MD_EmployeeDependentEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  DependentName?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Scalars['String']>;
  EmergencyContactPerson?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  NRICNo?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  DateofBirth?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Occupation?: Maybe<Scalars['String']>;
  PCBNo?: Maybe<Scalars['String']>;
  ChildEducation?: Maybe<Scalars['String']>;
  ChildEligibleRate?: Maybe<Scalars['String']>;
  Disabled?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeEntity = {
  __typename?: 'MD_EmployeeEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  NRICNo?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  DateofBirth?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  MaritalStatus?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Disabled?: Maybe<Scalars['Boolean']>;
  PermanentAddress?: Maybe<Scalars['String']>;
  PermanentPostcode?: Maybe<Scalars['String']>;
  PermanentCity?: Maybe<Scalars['String']>;
  PermanentState?: Maybe<Scalars['String']>;
  PermanentCountry?: Maybe<Scalars['String']>;
  IsSameAddress?: Maybe<Scalars['String']>;
  PresentAddress?: Maybe<Scalars['String']>;
  PresentPostcode?: Maybe<Scalars['String']>;
  PresentCity?: Maybe<Scalars['String']>;
  PresentState?: Maybe<Scalars['String']>;
  PresentCountry?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeJobInfoEntity = {
  __typename?: 'MD_EmployeeJobInfoEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  HeadofDepartment?: Maybe<Scalars['String']>;
  JobDesignation?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<Scalars['String']>;
  SupervisorEmployeeNo?: Maybe<Scalars['String']>;
  SupervisorName?: Maybe<Scalars['String']>;
  SupervisorCompany?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Scalars['String']>;
  JoinDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  Probation?: Maybe<Scalars['String']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  CalendarState?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeSalaryEntity = {
  __typename?: 'MD_EmployeeSalaryEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeStatutoryInfoEntity = {
  __typename?: 'MD_EmployeeStatutoryInfoEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  Bank?: Maybe<Scalars['String']>;
  PayMethod?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFEEVERate?: Maybe<Scalars['String']>;
  EPFERVERate?: Maybe<Scalars['String']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  SOCSONo?: Maybe<Scalars['String']>;
  LHDN?: Maybe<Scalars['Boolean']>;
  LHDNNo?: Maybe<Scalars['String']>;
  TaxMaritalStatus?: Maybe<Scalars['String']>;
  ResidentStatus?: Maybe<Scalars['String']>;
  EIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  Angkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  ASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  KWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  TabungHaji?: Maybe<Scalars['Boolean']>;
  TabungHajiNo?: Maybe<Scalars['String']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatPercentage?: Maybe<Scalars['String']>;
  HRDF?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_FixedAllwDedEntity = {
  __typename?: 'MD_FixedAllwDedEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  PayCategory?: Maybe<Scalars['String']>;
  PayItem?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_JobDesignationEntity = {
  __typename?: 'MD_JobDesignationEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  JobDesignationName?: Maybe<Scalars['String']>;
  JobGradeName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_JobGradeEntity = {
  __typename?: 'MD_JobGradeEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  JobGradeName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayItemEntity = {
  __typename?: 'MD_PayItemEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
  EAPartF?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  LHDN?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  AddRemuneration?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  Bonus?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  HRDF?: Maybe<Scalars['Boolean']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22A?: Maybe<Scalars['String']>;
  CP22B?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollCutOffEntity = {
  __typename?: 'MD_PayrollCutOffEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  PayrollCutOffDate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollCycleEntity = {
  __typename?: 'MD_PayrollCycleEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollHistoryEntity = {
  __typename?: 'MD_PayrollHistoryEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayItem?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_RecruitmentSourceEntity = {
  __typename?: 'MD_RecruitmentSourceEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type MtdCalcOutput = {
  __typename?: 'MTDCalcOutput';
  MTD?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  CurrentMonthMTD?: Maybe<Scalars['Float']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  CalcInput?: Maybe<MtdCalculationInput>;
  TotalOneTimeChargableTax?: Maybe<Scalars['Float']>;
  TotalOneTimeChargableEPF?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  IsZakatPercent?: Maybe<Scalars['Boolean']>;
  PrevTax?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  EPF?: Maybe<Scalars['Float']>;
  MTD_A?: Maybe<Scalars['Float']>;
  MTD_B?: Maybe<Scalars['Float']>;
  MTD_C?: Maybe<Scalars['Float']>;
  CS?: Maybe<Scalars['Float']>;
  NonResidentRate?: Maybe<Scalars['Float']>;
};

export type MtdCalculationInput = {
  __typename?: 'MTDCalculationInput';
  Y?: Maybe<Scalars['Float']>;
  K?: Maybe<Scalars['Float']>;
  Y1?: Maybe<Scalars['Float']>;
  K1?: Maybe<Scalars['Float']>;
  Yt?: Maybe<Scalars['Float']>;
  Kt?: Maybe<Scalars['Float']>;
  Y2?: Maybe<Scalars['Float']>;
  K2?: Maybe<Scalars['Float']>;
  KA?: Maybe<Scalars['Float']>;
  YtA?: Maybe<Scalars['Float']>;
  KtA?: Maybe<Scalars['Float']>;
  K2A?: Maybe<Scalars['Float']>;
  CS?: Maybe<Scalars['Float']>;
  B2?: Maybe<Scalars['Float']>;
  T2?: Maybe<Scalars['Float']>;
  P2?: Maybe<Scalars['Float']>;
  M2?: Maybe<Scalars['Float']>;
  R2?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Float']>;
  n1?: Maybe<Scalars['Float']>;
  D?: Maybe<Scalars['Float']>;
  Du?: Maybe<Scalars['Float']>;
  S?: Maybe<Scalars['Float']>;
  Su?: Maybe<Scalars['Float']>;
  Qc?: Maybe<Scalars['Float']>;
  ELP?: Maybe<Scalars['Float']>;
  LP1?: Maybe<Scalars['Float']>;
  P?: Maybe<Scalars['Float']>;
  P1?: Maybe<Scalars['Float']>;
  K22?: Maybe<Scalars['Float']>;
  Y22?: Maybe<Scalars['Float']>;
  M?: Maybe<Scalars['Float']>;
  R?: Maybe<Scalars['Float']>;
  B?: Maybe<Scalars['Float']>;
  T?: Maybe<Scalars['Float']>;
  Z?: Maybe<Scalars['Float']>;
  X?: Maybe<Scalars['Float']>;
  TaxResult?: Maybe<Scalars['Float']>;
};

export type MtdOutput = {
  __typename?: 'MTDOutput';
  EmployeeID: Scalars['String'];
  FullName: Scalars['String'];
  IsBasicSalary?: Maybe<Scalars['Boolean']>;
  NetMTDBeforeZakat?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  RoundedNetMTD?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  TaxPartA1MTDOutput?: Maybe<MtdCalcOutput>;
  TaxPartA2MTDOutput?: Maybe<MtdCalcOutput>;
};

export type MaintenanceBudgetInput = {
  training_budget_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  budget_items?: Maybe<Array<MaintenanceBudgetItemsInput>>;
};

export type MaintenanceBudgetItemsInput = {
  training_budget_item_id?: Maybe<Scalars['String']>;
  budget_group_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type MaintenanceCourseInput = {
  target_group_courses_id?: Maybe<Scalars['String']>;
  target_group_id?: Maybe<Scalars['String']>;
  compulsary_course_items?: Maybe<Array<Scalars['JSON']>>;
};

export type MaintenanceTargetHoursInput = {
  training_target_hours_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  target_hours_items?: Maybe<Array<MaintenanceTargetHoursItemInput>>;
};

export type MaintenanceTargetHoursItemInput = {
  training_target_hours_item_id?: Maybe<Scalars['String']>;
  target_group_id?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
};

export enum Marital {
  Divorced = 'Divorced',
  Married = 'Married',
  Separated = 'Separated',
  Single = 'Single',
  Widowed = 'Widowed'
}

export enum MaxApplyLeave {
  ApplyDate = 'Apply_Date',
  LeaveDate = 'Leave_Date'
}

export type MaxOtPayItemInput = {
  PayItemID?: Maybe<Scalars['String']>;
};

export type MaxOtPayItemObject = {
  __typename?: 'MaxOTPayItemObject';
  PayItemID?: Maybe<Scalars['String']>;
};

export type MedicalRecordEntity = InterfaceBase & {
  __typename?: 'MedicalRecordEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MedicalRecordID?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
};

export type MedicalRecordInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  MedicalRecordID?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentInput?: Maybe<DocumentsInput>;
};

export type MobileCheckInEntity = InterfaceBase & {
  __typename?: 'MobileCheckInEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MobileCheckInID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius: Scalars['Float'];
  IsByLocation?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  InUse: Scalars['Boolean'];
};

export type MobileCheckInInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  MobileCheckInID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
};

export type MobileVersionEntity = {
  __typename?: 'MobileVersionEntity';
  MobileVersionID: Scalars['String'];
  Version?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  Android?: Maybe<Scalars['Boolean']>;
  iOS?: Maybe<Scalars['Boolean']>;
};

export type ModerationRatingEntity = InterfaceBase & {
  __typename?: 'ModerationRatingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModerationRatingID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ModerationRatingInput = {
  ModerationRatingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum Module {
  Tms = 'TMS',
  TmsJobGradePolicy = 'TMS__JOB_GRADE_POLICY',
  TmsShiftGroupAssignment = 'TMS__SHIFT_GROUP_ASSIGNMENT',
  TmsAttendanceExclusionList = 'TMS__ATTENDANCE_EXCLUSION_LIST',
  Personnel = 'PERSONNEL',
  Payroll = 'PAYROLL',
  TmsOvertimePolicyMatrix = 'TMS_OVERTIME_POLICY_MATRIX'
}

export enum MonthPeriod {
  CurrentMonth = 'Current_Month',
  NextMonth = 'Next_Month'
}

export type MonthlyAllowanceInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  PeriodicCycle: Scalars['Float'];
  PeriodicCutoff: Scalars['Float'];
  PeriodicEffectiveDate: Scalars['DateTime'];
  PeriodicAllowanceTypeID: Scalars['String'];
};

export type MonthlyAllowancePolicyEntity = InterfaceBase & {
  __typename?: 'MonthlyAllowancePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MonthlyAllowanceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  PeriodicCycle?: Maybe<Scalars['Float']>;
  PeriodicCutoff?: Maybe<Scalars['Float']>;
  PeriodicEffectiveDate?: Maybe<Scalars['DateTime']>;
  PeriodicAllowanceTypeID?: Maybe<Scalars['String']>;
  PeriodicAllowancePolicyCondition?: Maybe<Array<PeriodicAllowancePolicyConditionEntity>>;
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
};

export enum MonthlyCutOff {
  EarnOnStartingOfTheMonth = 'Earn_On_Starting_Of_The_Month',
  EarnOnEndOfTheMonth = 'Earn_On_End_Of_The_Month',
  ServiceDate = 'Service_Date'
}

export type MonthlyError = {
  __typename?: 'MonthlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type MonthlyTemplateEntity = InterfaceBase & {
  __typename?: 'MonthlyTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MonthlyTemplateID: Scalars['String'];
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  ClaimItem?: Maybe<ClaimItemEntity>;
  PeriodicClaimType: PeriodicClaimType;
};

export type MonthlyTemplateInput = {
  MonthlyTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  ClaimPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodicClaimType: PeriodicClaimType;
};

export type MonthlyTransactionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  EmploymentTypes?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  StaffCategoryID?: Maybe<Array<Scalars['String']>>;
};

export enum MovementType {
  ProbationExtension = 'Probation_Extension',
  NewHire = 'NewHire',
  Confirmation = 'Confirmation',
  Promotion = 'Promotion',
  Transfer = 'Transfer',
  Demotion = 'Demotion',
  ExitCompany = 'Exit_Company',
  Secondment = 'Secondment',
  Redesignation = 'Redesignation',
  InternalTransfer = 'Internal_Transfer',
  InterCompanyTransfer = 'Inter_Company_Transfer'
}

export type MultiCurrencyDetailsEntity = InterfaceBase & {
  __typename?: 'MultiCurrencyDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MultiCurrencyDetailsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<MultiCurrencySetupEntity>;
};

export type MultiCurrencyDetailsInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyDetailsID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MultiCurrencySetupEntity = InterfaceBase & {
  __typename?: 'MultiCurrencySetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MultiCurrencyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  IsSystemDefault?: Maybe<Scalars['Boolean']>;
  SoftDelete?: Maybe<Status>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrencyDetails?: Maybe<Array<MultiCurrencyDetailsEntity>>;
};

export type MultiCurrencySetupInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MultiCurrencyID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  InUse?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createDutyRoster: Scalars['Boolean'];
  DutyRosterBatch: Scalars['Boolean'];
  ShiftPatternAssignmentToEmployee: Scalars['Boolean'];
  createBatchEntryByShiftPattern: Scalars['Boolean'];
  DeleteDutyRosterByEmployee: Scalars['Boolean'];
  DeleteDutyRosterForAll: Scalars['Boolean'];
  DeleteDutyRosterBySelectedEmployee: Scalars['Boolean'];
  NewsReadInsert: Scalars['Boolean'];
  NotificationUpdate: Scalars['Boolean'];
  deleteAttendanceRecord: Scalars['Boolean'];
  createAttendanceRecord: AttendanceRecordEntity;
  submitPairedAttandanceRecord: Scalars['Boolean'];
  updateAttendanceRecord: Scalars['Float'];
  DeleteTimeAttendanceRecord: Scalars['Boolean'];
  AttendanceExcelUpload?: Maybe<Array<ProcessAttendanceEntity>>;
  AttendanceExcelDelete: Scalars['Boolean'];
  AttendanceExcelInsertActual: Scalars['Boolean'];
  AttendanceRecordBatchDelete: Scalars['Boolean'];
  AttendanceRecordBatch: Scalars['Boolean'];
  AutomatedClockPairing: Scalars['Boolean'];
  DocumentInsert: DocumentsEntity;
  DocumentUpdate: DocumentsEntity;
  DocumentUpdateParentID: Scalars['Boolean'];
  DocumentDelete: Scalars['Boolean'];
  EmployeeProfileInsertUpdate: Scalars['Boolean'];
  AppraisalCategoryInsert: AppraisalCategoryEntity;
  AppraisalCategoryUpdate: Scalars['Boolean'];
  AppraisalCategoryDelete: Scalars['Boolean'];
  AppraisalCategoryItemInsert: AppraisalCategoryItemEntity;
  AppraisalCategoryItemUpdate: Scalars['Boolean'];
  AppraisalCategoryItemDelete: Scalars['Boolean'];
  AppraisalPolicyInsert: AppraisalPolicyEntity;
  AppraisalPolicyDelete: Scalars['Boolean'];
  AppraisalTemplateByJobPositionSubmit: Scalars['Boolean'];
  AppraisalScheduleSubmit: AppraisalScheduleHeaderEntity;
  AppraisalCompanySettingSubmit: AppraisalCompanySettingEntity;
  FinalRatingSubmit: FinalRatingHeaderEntity;
  FinalRatingDelete: Scalars['Boolean'];
  ScaleRatingSubmit: ScaleRatingHeaderEntity;
  ScaleRatingDelete: Scalars['Boolean'];
  BonusSimulationSubmit: Scalars['Boolean'];
  IncrementSimulationSubmit: Scalars['Boolean'];
  SubmitAppraisalTemplete: Scalars['Boolean'];
  SubmitAppraisal?: Maybe<Scalars['Boolean']>;
  ModerationSubmit: Scalars['Boolean'];
  SubmitStaffKPIPlanning?: Maybe<Scalars['Boolean']>;
  StaffKPIPlannigDelete: Scalars['Boolean'];
  StaffPerformanceAnalysisSummarySubmit: Scalars['Boolean'];
  ApprovalPolicyInsert: Scalars['Boolean'];
  ApprovalPolicyUpdate: Scalars['Boolean'];
  ApprovalPolicySubmit: Scalars['Boolean'];
  ApprovalPolicyDelete: Scalars['Boolean'];
  ApprovalPolicyAssignmentUpdate: Scalars['Boolean'];
  NewApprovalPolicyAssignmentDelete: Scalars['Boolean'];
  AttendanceAnalysisTemplateInsert: Scalars['Boolean'];
  SubmitAttendanceCorrectionRequest: Scalars['Boolean'];
  CancelAttendanceCorrectionRequest: Scalars['Boolean'];
  AttendanceCorrectionRequestApproval: Scalars['Boolean'];
  PostAttendanceRecord: Scalars['Boolean'];
  ReverseAttendanceRecord?: Maybe<Scalars['Boolean']>;
  AttendancePeriodEdit?: Maybe<Scalars['Boolean']>;
  AttendanceProccessBulkDelete: Scalars['Boolean'];
  OvertimeInsert: OvertimeEntity;
  OvertimeDraft: Scalars['Boolean'];
  OvertimeBatch: Scalars['Boolean'];
  OvertimeUpdate: Scalars['Boolean'];
  OvertimeDelete: Scalars['Boolean'];
  OvertimeExcelUpload?: Maybe<Array<Process_Overtime_ImportEntity>>;
  OvertimeExcelDelete: Scalars['Boolean'];
  OvertimeExcelInsertActual: Scalars['Boolean'];
  BatchOvertimeEntryInsert: Scalars['Boolean'];
  BatchOvertimeCancel: Scalars['Boolean'];
  BatchOvertimeWithdraw: Scalars['Boolean'];
  ToDoBatchOvertimeDraft: Scalars['Boolean'];
  ToDoBatchOvertimeSubmit: Scalars['Boolean'];
  ToDoBatchOvertimeWithdrawalApproval: Scalars['Boolean'];
  SubmitManagementClaimRecords?: Maybe<Scalars['Boolean']>;
  ApproveClaimRecords?: Maybe<Scalars['Boolean']>;
  CancelClaimRecords?: Maybe<Scalars['Boolean']>;
  DeleteApprovedClaimRecords?: Maybe<Scalars['Boolean']>;
  ReverseApprovedClaimRecords?: Maybe<Scalars['Boolean']>;
  VerifyApprovedClaimDetail?: Maybe<Scalars['Boolean']>;
  PostClaimRecords?: Maybe<Scalars['Boolean']>;
  ReverseClaimRecords?: Maybe<Scalars['Boolean']>;
  SubmitMyClaim: ClaimHeaderEntity;
  DeleteClaimRecords: Scalars['Boolean'];
  SubmitClaimForApproval: Scalars['Boolean'];
  DoUpdateClaim: Scalars['Boolean'];
  MyClaimInsert: ClaimInsertOutput;
  ClaimValidityUpdate: Scalars['Boolean'];
  ClaimValidityInsert: Scalars['Boolean'];
  ClaimTypeInsert: ClaimTypeEntity;
  ClaimTypeUpdate: Scalars['Boolean'];
  ClaimTypeDelete: Scalars['Boolean'];
  ClaimTypeTemplateInsert: Scalars['Boolean'];
  ClaimTypeTemplateUpdate: Scalars['Boolean'];
  ClaimTypeTemplateDelete: Scalars['Boolean'];
  ClaimTypeTemplateUpdateStatus: Scalars['Boolean'];
  ClaimTypeConditionUpdate: Scalars['Boolean'];
  ClaimTypeConditionDelete: Scalars['Boolean'];
  ClaimJobGradePolicyInsert: Scalars['Boolean'];
  ClaimJobGradePolicyUpdate: Scalars['Boolean'];
  ClaimPoolDelete: Scalars['Boolean'];
  ClaimPoolSubmit: Scalars['Boolean'];
  InsertClaimCutOffPeriod: Scalars['Boolean'];
  BankInsert: BankInfoEntity;
  BankUpdate: Scalars['Boolean'];
  BankDelete: Scalars['Boolean'];
  CompanyOffDaysDelete: Scalars['Boolean'];
  updateCompanyOffDaysStatus: Scalars['Boolean'];
  updateRestOffDayStatus: Scalars['Boolean'];
  CompanyOffDaysBulkInsert: Scalars['Boolean'];
  CalendarPolicyStatusUpdate: Scalars['Boolean'];
  CalendarPolicyDelete: Scalars['Boolean'];
  OffRestDayDelete: Scalars['Boolean'];
  OffRestDayBulkInsert: Scalars['Boolean'];
  CalendarOffDayUpdate: Scalars['Boolean'];
  CalendarOffDayInsert: CalendarOffDayEntity;
  CalanderPolicySubmit: Scalars['Boolean'];
  CompanyHolidaysBulkInsert: Scalars['Boolean'];
  CompanyDocumentInsert: DocumentsEntity;
  CompanyDocumentDelete: Scalars['Boolean'];
  CompanyFolderCreate: Scalars['Boolean'];
  CompanyFolderDelete: Scalars['Boolean'];
  CompanyInsert: Scalars['Boolean'];
  CompanyUpdate: Scalars['Boolean'];
  CompanyLeaveSettingUpdate: Scalars['Boolean'];
  CompanyTaxReliefUpdate: Scalars['Boolean'];
  CompanyClaimSettingUpdate: Scalars['Boolean'];
  CompanyProjectExpenseClaimSettingUpdate: Scalars['Boolean'];
  CompanyOnlyUpdate: Scalars['Boolean'];
  JobDesignationInsert: Scalars['Boolean'];
  JobPositionInsert: Scalars['Boolean'];
  JobDesignationUpdate: Scalars['Boolean'];
  JobDesignationSequenceUpdate: Scalars['Boolean'];
  JobPositionUpdate: Scalars['Boolean'];
  JobDesignationDelete: Scalars['Boolean'];
  JobPositionDelete: Scalars['Boolean'];
  RecruitmentSourceBulkInsert: Scalars['Boolean'];
  RecruitmentSourceInsert: RecruitmentSourceEntity;
  RecruitmentSourceUpdate: Scalars['Boolean'];
  RecruitmentSourceDelete: Scalars['Boolean'];
  CompanyAltAddressInsert: Scalars['Boolean'];
  CompanyAltAddressUpdate: Scalars['Boolean'];
  CompanyAltAddressDelete: Scalars['Boolean'];
  CompanyStatutoryAccountUpdate: Scalars['Boolean'];
  CompanyStatutoryInsert: CompanyStatutoryEntity;
  CompanyStatutoryUpdate: Scalars['Boolean'];
  CompanyStructureInsert: Scalars['Boolean'];
  CompanyOrgUnitInsert: Scalars['Boolean'];
  CompanyOrgUnitUpdate: Scalars['Boolean'];
  AssignJobPosition: Scalars['Boolean'];
  CreateCompanyAccessPerm: Scalars['Boolean'];
  DepartmentInsert: DepartmentEntity;
  DepartmentUpdate: Scalars['Boolean'];
  DepartmentDelete: Scalars['Boolean'];
  DivisionInsert: DepartmentEntity;
  DivisionUpdate: Scalars['Boolean'];
  DivisionDelete: Scalars['Boolean'];
  CreateInsuranceScheme: Scalars['Boolean'];
  InsuranceSchemeInsert: Scalars['Boolean'];
  UpdateInsuranceScheme: Scalars['Boolean'];
  InsuranceSchemeUpdate: Scalars['Boolean'];
  DeleteInsuranceScheme: Scalars['Boolean'];
  InsuranceSchemeDelete: Scalars['Boolean'];
  InterbankInsert: InterbankGiroEntity;
  InterbankBulkInsert: Scalars['Boolean'];
  InterbankDelete: Scalars['Boolean'];
  InterbankUpdate: Scalars['Boolean'];
  InterbankSingleUpdate: Scalars['Boolean'];
  JobAccountInsert: Scalars['Boolean'];
  JobAccountUpdate: Scalars['Boolean'];
  JobAccountDelete: Scalars['Boolean'];
  JobGradeBulkInsert: Scalars['Boolean'];
  JobGradeInsert: JobGradeEntity;
  JobGradeUpdate: Scalars['Boolean'];
  JobGradeDelete: Scalars['Boolean'];
  LeaveRoundingBulkInsert: Scalars['Boolean'];
  LeaveRoundingInsert: Scalars['Boolean'];
  UpdateLeaveRounding: Scalars['Boolean'];
  LeaveRoundingUpdate: Scalars['Boolean'];
  LeaveRoundingDelete: Scalars['Boolean'];
  LeaveCompulsoryPolicyBulkInsert: Scalars['Boolean'];
  LeaveCompulsoryPolicyUpdate: Scalars['Boolean'];
  LHDNReportInsert: Scalars['Boolean'];
  LHDNReportUpdate: Scalars['Boolean'];
  LHDNReportCopyInsert: Scalars['Boolean'];
  NotificationPolicyInsert: NotificationPolicyEntity;
  NotificationPolicyUpdate: Scalars['Boolean'];
  NotificationPolicyDelete: Scalars['Boolean'];
  NotificationPolicyConditionInsert: NotificationPolicyConditionEntity;
  NotificationPolicyConditionUpdate: Scalars['Boolean'];
  PayrollCycleInsert: PayrollCycleEntity;
  PayrollCycleUpdate: Scalars['Boolean'];
  PayrollCycleDelete: Scalars['Boolean'];
  ReportingGroupSubmit: Scalars['Boolean'];
  ReportingGroupDelete: Scalars['Boolean'];
  ReportingCompanyGroupDelete: Scalars['Boolean'];
  ReportingStructureGroupSubmit: Scalars['Boolean'];
  CreateSkillSet: Scalars['Boolean'];
  UpdateSkillSet: Scalars['Boolean'];
  DeleteSkillSet: Scalars['Boolean'];
  UserDefineBulkInsert: Scalars['Boolean'];
  PostOffboardingLeaveEncashment: Scalars['Boolean'];
  RevertOffboardingLeaveEncashment: Scalars['Boolean'];
  PostFromPlatformLeaveTypes: Scalars['String'];
  SubmitMyLeaveApplication: Scalars['Boolean'];
  PostLeaveApplication: Scalars['Boolean'];
  SubmitLeaveApplication: Scalars['Boolean'];
  SubmitLeaveApplicationV2: LeaveHeaderEntity;
  DeleteLeaveApplication: Scalars['Boolean'];
  DeletePendingLeaveApplication: Scalars['Boolean'];
  DeleteApprovedLeaveApplication: Scalars['Boolean'];
  PostLeaveOpeningYear: Scalars['Boolean'];
  LeaveTypeInsert: LeaveTypeEntity;
  LeaveTypeUpdate: Scalars['Boolean'];
  LeaveTypeDelete: Scalars['Boolean'];
  LeaveReasonInsert: LeaveReasonEntity;
  LeaveReasonUpdate: Scalars['Boolean'];
  LeaveReasonDelete: Scalars['Boolean'];
  LeaveDiseaseInsert: LeaveDiseaseEntity;
  LeaveDiseaseUpdate: Scalars['Boolean'];
  LeaveDiseaseDelete: Scalars['Boolean'];
  LeaveJobGradePolicyInsert: Scalars['Boolean'];
  LeaveJobGradePolicyUpdate: Scalars['Boolean'];
  LeaveTypeConditionUpdate: Scalars['Boolean'];
  LeaveTypeConditionDelete: Scalars['Boolean'];
  LeavePolicyUpdate: Scalars['Boolean'];
  LeavePolicyDelete: Scalars['Boolean'];
  LeavePolicyInsert: Scalars['Boolean'];
  LeaveTypeTemplateInsert: Scalars['Boolean'];
  LeaveTypeTemplateUpdate: Scalars['Boolean'];
  LeaveTypeTemplateDelete: Scalars['Boolean'];
  UserDashboardControlSubmit: Scalars['Boolean'];
  DataChangesInsert: DataChangesEntity;
  SubmitDataChanges: Scalars['Boolean'];
  DeleteDataChanges: Scalars['Boolean'];
  CancelDataChanges: Scalars['Boolean'];
  ApproveDataChanges: Scalars['Boolean'];
  RejectDataChanges: Scalars['Boolean'];
  DataChangesApproval: Scalars['Boolean'];
  DataChangesReject: Scalars['Boolean'];
  ShopeeDataImportVerify: Scalars['Boolean'];
  ShopeeDataImportVerifyRedirect: Scalars['Boolean'];
  DataImportVerify: Scalars['Boolean'];
  DataImportVerifyRedirect: Scalars['Boolean'];
  RemoveTempData: Scalars['Boolean'];
  ProceedForImport: Scalars['Boolean'];
  ActivateEmployees: Scalars['Boolean'];
  ActivateEmployeesInBulk: Scalars['Boolean'];
  DelegationInsert: Scalars['Boolean'];
  DelegationUpdate: Scalars['Boolean'];
  DelegationDelete: Scalars['Boolean'];
  NewsDelete: Scalars['Boolean'];
  NewsInsert: Scalars['Boolean'];
  NewsUpdate: Scalars['Boolean'];
  NewsPublish: Scalars['Boolean'];
  NewsDuplicate: Scalars['Boolean'];
  UpdatePasswordInBulk: Scalars['Boolean'];
  NewDocumentNumberingInsert: Scalars['Boolean'];
  CompanyDocumentNumberingUpdate: Scalars['Boolean'];
  DocumentNumberingInsert: Scalars['Boolean'];
  DocumentNumberingUpdate: Scalars['Boolean'];
  DocumentNumberingDelete: Scalars['Boolean'];
  DocumentNumberingUpdateStatus: Scalars['Boolean'];
  DocumentNumberAssignment: Scalars['Boolean'];
  BatchEntryByShiftInsert: Scalars['Boolean'];
  updateBatchEntryByShiftPattern: Scalars['Boolean'];
  CreateEmployeeAppraisal: Scalars['Boolean'];
  UpdateEmployeeAppraisal: Scalars['Boolean'];
  DeleteEmployeeAppraisal: Scalars['Boolean'];
  CareerLogInsert: Scalars['Boolean'];
  CareerLogUpdate: Scalars['Boolean'];
  CreateEmployeeDiary: Scalars['Boolean'];
  UpdateEmployeeDiary: Scalars['Boolean'];
  DeleteEmployeeDiary: Scalars['Boolean'];
  EmployeeInfoInsert: EmployeeEntity;
  EmployeeProfilePicUpdate: Scalars['Boolean'];
  EmployeeInfoUpdate: Scalars['Boolean'];
  EmployeeCareerLogUpdate: Scalars['Boolean'];
  EmployeeStatutoryInsert: EmployeeStatutoryEntity;
  EmployeeStatutoryUpdate: Scalars['Boolean'];
  EmployeePrevContributionInsert: EmployeePrevContributionEntity;
  EmployeePrevContributionUpdate: Scalars['Boolean'];
  EmployeeDependentInsert: EmployeeDependentsEntity;
  EmployeeDependentUpdate: Scalars['Boolean'];
  EmployeeDependentInsertByEmployee: EmployeeDependentsEntity;
  EmployeeDependentUpdateByEmployee: Scalars['Boolean'];
  EmployeeTaxReliefBulkInsert: Scalars['Boolean'];
  EmployeeTaxReliefBulkUpdate: Scalars['Boolean'];
  EmployeeAssetInsert: EmployeeAssetEntity;
  EmployeeAssetUpdate: Scalars['Boolean'];
  EmployeeAssetDelete: Scalars['Boolean'];
  EmployeeBenefitInKindInsert: EmployeeBenefitInKindEntity;
  EmployeeBenefitInKindUpdate: Scalars['Boolean'];
  EmployeeBenefitInKindDelete: Scalars['Boolean'];
  EmployeeDependentDelete: Scalars['Boolean'];
  EmployeeSalaryInsert: Scalars['Boolean'];
  EmployeeSalaryUpdate: Scalars['Boolean'];
  EmployeeSalaryDelete: Scalars['Boolean'];
  EmployeeProfileUpload: EmployeeEntity;
  UpdateEmployeeLastLoginCompany: Scalars['Boolean'];
  EmployeeCP22Update: Scalars['Boolean'];
  CreateEmployeeInsuranceScheme: Scalars['Boolean'];
  UpdateEmployeeInsuranceScheme: Scalars['Boolean'];
  DeleteEmployeeInsuranceScheme: Scalars['Boolean'];
  UpdateNotificationReadStatus: Scalars['Boolean'];
  UpdateAllNotificationReadStatus: Scalars['Boolean'];
  UpdateBulkNotificationReadStatus: Scalars['Boolean'];
  NotificationReadStatusDelete: Scalars['Boolean'];
  CreateEmployeeTraining: Scalars['Boolean'];
  UpdateEmployeeTraining: Scalars['Boolean'];
  DeleteEmployeeTraining: Scalars['Boolean'];
  EmployeeUserDefineFieldsInsert: Scalars['Boolean'];
  EmployeeUserDefineFieldsUpdate: Scalars['Boolean'];
  EmployeeUserDefineFieldsDetailInsert: Scalars['Boolean'];
  EmployeeUserDefineFieldsDetailUpdate: Scalars['Boolean'];
  ZakatContributionInsert: Scalars['Boolean'];
  ZakatContributionUpdate: Scalars['Boolean'];
  ZakatContributionDelete: Scalars['Boolean'];
  CreateUpdateAppSecurity: Scalars['Boolean'];
  CreateUpdateAppSecurityAssignment: Scalars['Boolean'];
  DownloadDocuments: Scalars['JSON'];
  UploadEaDocuments: Scalars['Boolean'];
  UploadPayslipDocuments: Scalars['Boolean'];
  RemoveEonDocumentsByBatch: Scalars['Boolean'];
  HelpdeskSubjectSubmit: Scalars['Boolean'];
  HelpdeskSubjectAction: Scalars['Boolean'];
  SubmitHelpdeskTicket: Scalars['Boolean'];
  ReassignAssignee: Scalars['Boolean'];
  UpdateOpenDT?: Maybe<Scalars['Boolean']>;
  WithdrawOpenTicket: Scalars['Boolean'];
  AttendanceClocking: Scalars['JSON'];
  GetUserInfo: Scalars['JSON'];
  GetStaffAttendance: Scalars['JSON'];
  GetEmpWorkTime: Scalars['JSON'];
  BlockLeaveInsert: Scalars['Boolean'];
  BlockLeaveUpdate: Scalars['Boolean'];
  BlockLeaveStatusUpdate: Scalars['Boolean'];
  ReprocessLeaveEntitlement: Scalars['Boolean'];
  ReprocessLeaveEntitlementRedirect: Scalars['Boolean'];
  ReprocessLeaveEntitlementBySubscription: Scalars['Boolean'];
  ReprocessLeaveEntitlementBySubscriptionRedirect: Scalars['Boolean'];
  PostCarryForwardEncashment: Scalars['Boolean'];
  LeaveRecordsApproval: Scalars['Boolean'];
  LeaveRecordsInsert: LeaveHeaderEntity;
  LeaveRecordsUpdate: Scalars['Boolean'];
  LeaveAdjustmentInsert: Scalars['Boolean'];
  LeaveStatusUpdate: Scalars['Boolean'];
  LeaveAdjustmentUpdate: Scalars['Boolean'];
  LeaveAdjustmentDelete: Scalars['Boolean'];
  PostLeaveRecords: Scalars['Boolean'];
  RevertLeaveRecords: Scalars['Boolean'];
  SaveCompulsoryLeave: Scalars['Boolean'];
  SaveUpdateCompulsoryLeave: Scalars['Boolean'];
  DeleteCompulsoryLeave: Scalars['Boolean'];
  InsertLeaveCutOffPeriods: Scalars['Boolean'];
  SubmitYearEndCarryForward: Scalars['Boolean'];
  ReversePostedYECFRecords?: Maybe<Scalars['Boolean']>;
  ReversePostedLCFE: Scalars['Boolean'];
  LeaveTakenUpload?: Maybe<Array<Process_LeaveDetails_ImportEntity>>;
  LeaveTakenDelete: Scalars['Boolean'];
  LeaveTakenInsertActual: Scalars['Boolean'];
  LeaveTakenBulkInsert: Scalars['Boolean'];
  PostMaxLeaveBalanceForfeiture: Scalars['Boolean'];
  ReverseMaxLeaveBalanceForfeiture: Scalars['Boolean'];
  LeaveApprovalPolicyInsert: Scalars['Boolean'];
  LeaveApprovalPolicyDelete: Scalars['Boolean'];
  LeaveApprovalSequenceUpdate: Scalars['Boolean'];
  LeaveOutOfStateInsert: LeaveOutOfStateEntity;
  LeaveOufOfStateUpdate: Scalars['Boolean'];
  LeaveOutOfStateDelete: Scalars['Boolean'];
  AccountMappingInsert: Scalars['Boolean'];
  AccountMappingUpdate: Scalars['Boolean'];
  AccountMappingDelete: Scalars['Boolean'];
  AccountMappingAssignmentInsert: Scalars['Boolean'];
  AccountMappingAssignmentUpdate: Scalars['Boolean'];
  ClaimAccountMappingInsert: Scalars['Boolean'];
  ClaimAccountMappingUpdate: Scalars['Boolean'];
  ClaimAccountMappingDelete: Scalars['Boolean'];
  ClaimAccountAssignmentInsert: Scalars['Boolean'];
  ClaimAccountAssignmentUpdate: Scalars['Boolean'];
  CostMappingUpdate: Scalars['Boolean'];
  DeleteProcessedGL: Scalars['Boolean'];
  FlagGLData: Scalars['JSON'];
  UnflagGLData: Scalars['JSON'];
  HR365DocumentMigrations: Scalars['Boolean'];
  CreateMobileVersion: Scalars['Boolean'];
  MobileVersionDeleting: Scalars['Boolean'];
  MultiCurrencyUpdateStatus: Scalars['Boolean'];
  MultiCurrencyUpdateDelete: Scalars['Boolean'];
  MultiCurrencyInsert: Scalars['Boolean'];
  MultiCurrencyUpdate: Scalars['Boolean'];
  MultiCurrencySetupExcelUpload?: Maybe<Array<Process_Multicurrencysetup_ImportEntity>>;
  MultiCurrencyExcelDelete: Scalars['Boolean'];
  MultiCurrencyInsertActual: Scalars['Boolean'];
  SubmitOnBehalfPolicyByType: Scalars['Boolean'];
  PopulateAttendanceRecord: Scalars['Boolean'];
  OvertimeRoundingInsert: OvertimeRoundingSettingEntity;
  OvertimeRoundingSettingUpdate: Scalars['Boolean'];
  OvertimeRoundingSettingDelete: Scalars['Boolean'];
  OvertimeTemplateInsert: OvertimeTemplateEntity;
  OvertimeTemplateUpdate: Scalars['Boolean'];
  OvertimeTemplateDelete: Scalars['Boolean'];
  SubmitAdvancePolicy: Scalars['Boolean'];
  DeleteAdvancePolicy: Scalars['Boolean'];
  AssignAdvancePolicy: Scalars['Boolean'];
  GenerateAdvanceProcessing: Array<AdvanceProcessingEntity>;
  PostUnpostAdvanceProcessing: Scalars['Boolean'];
  SubmitPayGroup: Scalars['Boolean'];
  PayGroupDelete: Scalars['Boolean'];
  AssignCompanyPayGroup: Scalars['Boolean'];
  UpdateStatutoryAdjustment: Scalars['Boolean'];
  DeleteStatutoryAdjustment: Scalars['Boolean'];
  DeletePayrollHistory: Scalars['Boolean'];
  DeletePayrollProcess: Scalars['Boolean'];
  UpdatePayrollCalendar: Scalars['Boolean'];
  GenerateDefaultOrderforAllPayPeriods: Scalars['Boolean'];
  UpdatePayrollProcessStatus: Scalars['Boolean'];
  UpdateMonthlyTransactionByFixedAllowance: Scalars['Boolean'];
  InsertPayPeriodListing: Scalars['Boolean'];
  InsertSubsequentPayPeriodListing: Array<PayPeriodEntity>;
  PayItemInsert: Scalars['Boolean'];
  PayItemUpdate: Scalars['Boolean'];
  PayItemConditionTemplateLibraryUpdate: Scalars['Boolean'];
  SalaryBatch: Scalars['Boolean'];
  PayItemDelete: Scalars['Boolean'];
  RecurringPayInsert: Scalars['Boolean'];
  RecurringPayUpdate: Scalars['Boolean'];
  RecurringPayDelete: Scalars['Boolean'];
  CP159Update: Scalars['Boolean'];
  OneTimePayDelete: Scalars['Boolean'];
  OneTimePayInsert: Scalars['Boolean'];
  OneTimePayUpdate: Scalars['Boolean'];
  PublishPaySlip: Scalars['Boolean'];
  PublishEA: Scalars['Boolean'];
  UnPublishEA: Scalars['Boolean'];
  DeleteMonthlyTrx: Scalars['Boolean'];
  FreezePayrollInsert: Scalars['Boolean'];
  EmployeeFreezePayrollDelete: Scalars['Boolean'];
  EmployeeSalaryExcelUpload?: Maybe<Array<ProcessSalaryEntity>>;
  SalaryExcelDelete: Scalars['Boolean'];
  SalaryExcelInsertActual: Scalars['Boolean'];
  SubmitEmployeeKpi: Scalars['Boolean'];
  SaveEmployeeKpiInRepo: Scalars['Boolean'];
  EmployeeKpiRepoDelete: Scalars['Boolean'];
  KpiExerciseChangeSuperior: Scalars['Boolean'];
  KpiExerciseChangeStatus: Scalars['Boolean'];
  KpiExerciseSubmit: Scalars['Boolean'];
  KpiExerciseDelete: Scalars['Boolean'];
  KpiExerciseInitiateDraft: Scalars['Boolean'];
  KpiExerciseCopy: Scalars['Boolean'];
  KpiExerciseSetupSubmit: Scalars['Boolean'];
  KpiExerciseSetupCopy: Scalars['Boolean'];
  KpiExerciseSetupDelete: Scalars['Boolean'];
  KpiExerciseSetupGenerate: Scalars['Boolean'];
  OrganizationalGoalSubmit: Scalars['Boolean'];
  OrganizationalGoalDelete: Scalars['Boolean'];
  OrgKpiPlanningSubmit: Scalars['Boolean'];
  OrgKpiPlanningDetailSubmit: Scalars['Boolean'];
  OrgKpiPlanningDetailsDelete: Scalars['Boolean'];
  PerformanceGroupMatrixSubmit: Scalars['Boolean'];
  PerformanceGroupMatrixDelete: Scalars['Boolean'];
  EmployeeReviewUpdateSetup: Scalars['Boolean'];
  CopyEmployeeReviewExercise: Scalars['Boolean'];
  EmployeeReviewFormStatusUpdate: Scalars['Boolean'];
  SubmitEmployeeReviewForm: Scalars['Boolean'];
  AddConversationLog: Scalars['Boolean'];
  PeerRegistrationSubmit: Scalars['Boolean'];
  ReviewExerciseSetupSubmit: Scalars['Boolean'];
  ReviewExerciseSetupCopy: Scalars['Boolean'];
  ReviewExerciseSetupDelete: Scalars['Boolean'];
  ReviewExerciseSetupGenerate: Scalars['Boolean'];
  PeriodicAllowanceTypeInput: PeriodicAllowanceTypeEntity;
  PeriodicAllowanceTypeUpdate: Scalars['Boolean'];
  PeriodicAllowanceTypeDelete: Scalars['Boolean'];
  PersonnelCertificateInsert: Scalars['Boolean'];
  PersonnelCertificateUpdate: Scalars['Boolean'];
  PersonnelCertificateDelete: Scalars['Boolean'];
  ContractInfoInsert: Scalars['Boolean'];
  ContractInfoUpdate: Scalars['Boolean'];
  ContractInfoEnd: Scalars['Boolean'];
  ContractInfoRenewal: Scalars['Boolean'];
  ReportDocDelete: Scalars['Boolean'];
  ContractInfoDelete: Scalars['Boolean'];
  PersonnelEducationInsert: Scalars['String'];
  PersonnelEducationUpdate: Scalars['Boolean'];
  PersonnelEducationDelete: Scalars['Boolean'];
  PersonnelHealthInfoInsert: Scalars['Boolean'];
  PersonnelHealthInfoUpdate: Scalars['Boolean'];
  MedicalRecordDelete: Scalars['Boolean'];
  PersonnelJobHistoryInsert: Scalars['Boolean'];
  PersonnelJobHistoryUpdate: Scalars['Boolean'];
  PersonnelJobHistoryInsertByEmployee: Scalars['String'];
  PersonnelJobHistoryUpdateByEmployee: Scalars['Boolean'];
  PersonnelJobHistoryDelete: Scalars['Boolean'];
  PersonnelReferenceInsert: Scalars['Boolean'];
  PersonnelReferenceUpdate: Scalars['Boolean'];
  PersonnelReferenceDelete: Scalars['Boolean'];
  UserDefineDetailBulkInsert: Scalars['Boolean'];
  PersonnelInsuranceSchemeBulkInsert: Scalars['Boolean'];
  PersonnelInsuranceSchemeUpdate: Scalars['Boolean'];
  StaffConfirmationInsert: Scalars['Boolean'];
  StaffConfirmationUpdate: Scalars['Boolean'];
  StaffConfirmationCancelConfirmed: Scalars['Boolean'];
  PersonnelStaffExitCompanyInsert: Scalars['Boolean'];
  PersonnelStaffExitCompanyUpdate: Scalars['Boolean'];
  StaffExitCompanyCancel: Scalars['Boolean'];
  ProcessStaffMovement: Scalars['Boolean'];
  StaffPromotionDemotionInsert: Scalars['Boolean'];
  StaffPromotionDemotionUpdate: Scalars['Boolean'];
  StaffPromotionDemotionCancel: Scalars['Boolean'];
  StaffTransferInsert: Scalars['Boolean'];
  StaffTransferUpdate: Scalars['Boolean'];
  StaffTransferCancel: Scalars['Boolean'];
  DeleteProjectExpenseClaimRecords: Scalars['Boolean'];
  SubmitProjectExpenseClaimForApproval: Scalars['Boolean'];
  SubmitMyProjectClaim: ProjectExpenseClaimHeaderEntity;
  ApproveProjectExpenseClaimRecords?: Maybe<Scalars['Boolean']>;
  DeleteApprovedProjectExpenseClaimRecords?: Maybe<Scalars['Boolean']>;
  PostPEClaimRecords?: Maybe<Scalars['Boolean']>;
  ReversePEClaimRecords?: Maybe<Scalars['Boolean']>;
  VerifyApprovedProjectExpenseClaimDetail?: Maybe<Scalars['Boolean']>;
  ReverseApprovedProjectExpenseClaimRecords?: Maybe<Scalars['Boolean']>;
  ProjectExpenseTypeSubmit: ProjectExpenseTypeEntity;
  ProjectExpenseTypeDelete: Scalars['Boolean'];
  ProjectGLAccountMappingSubmit: Scalars['Boolean'];
  ProjectGLAccountMappingDelete: Scalars['Boolean'];
  ProjectAccountAssignmentInsert: Scalars['Boolean'];
  ProjectAccountAssignmentUpdate: Scalars['Boolean'];
  DeleteClaimProcessedGL: Scalars['Boolean'];
  ProjectMasterSubmit: ProjectMasterEntity;
  ProjectMasterDelete: Scalars['Boolean'];
  ProjectCostAllocationSubmit: Scalars['Boolean'];
  ProjectCostAllocationDelete: Scalars['Boolean'];
  SubmitApproverMaster: Scalars['Boolean'];
  ResendConfirmationEmail: Scalars['Boolean'];
  ApproverConfirmedEmailAction: Scalars['Boolean'];
  DeleteApproverMaster: Scalars['Boolean'];
  DeleteApproverEmployeeListing: Scalars['Boolean'];
  SubmitApproverAssignment: Scalars['Boolean'];
  SubmitDailyEntry: Scalars['Boolean'];
  DeleteDailyEntry: Scalars['Boolean'];
  SubmitProjectTimesheet: Scalars['Boolean'];
  SubmitProjectTimesheetApproval: Scalars['Boolean'];
  ProjectTimesheetReportForApproverSendEmail: Scalars['Boolean'];
  RecruitmentExpensesInsert?: Maybe<Scalars['Boolean']>;
  RecruitmentExpensesDelete?: Maybe<Scalars['Boolean']>;
  ELetterTemplateInsert?: Maybe<Scalars['Boolean']>;
  ELetterTemplateUpdate?: Maybe<Scalars['Boolean']>;
  ELetterTemplateUpdateDefault?: Maybe<Scalars['Boolean']>;
  ELetterTemplateDelete?: Maybe<Scalars['Boolean']>;
  Rec_EApplicationFormSetupInsert: Scalars['Boolean'];
  PreOfferChecklistInsert?: Maybe<Scalars['Boolean']>;
  PreOfferChecklistUpdate?: Maybe<Scalars['Boolean']>;
  PreOfferChecklistSequenceUpdate?: Maybe<Scalars['Boolean']>;
  PreOfferChecklistDelete?: Maybe<Scalars['Boolean']>;
  TalentPoolAdditionalUserInsert: Scalars['Boolean'];
  DeclineReasonInsert: Scalars['Boolean'];
  Rec_PrehiringFormSetupInsert: Scalars['Boolean'];
  JobPortalSetupInsert: Scalars['Boolean'];
  JobPortalSetupUpdate: Scalars['Boolean'];
  JobPortalSetupDelete: Scalars['Boolean'];
  JobPortalTalentInsert?: Maybe<Scalars['JSON']>;
  EApplicationInsert: Scalars['JSON'];
  DataUpdateEApplicationInsert: Scalars['JSON'];
  SubmitMyRecruitmentRequisition?: Maybe<Scalars['Boolean']>;
  UpdateMyRecRequisitionApprovalStatus?: Maybe<Scalars['Boolean']>;
  MyApprovedJobVacancyApplicantStatusUpdate: Scalars['Boolean'];
  SubmitRecruitmentRequisition?: Maybe<Scalars['Boolean']>;
  DeleteRecRequisition?: Maybe<Scalars['Boolean']>;
  UpdateRecRequisitionApprovalStatus?: Maybe<Scalars['Boolean']>;
  JobVacancyInsert: Scalars['Boolean'];
  JobVacancyStatusUpdate: Scalars['Boolean'];
  SubmitInterviewSchedule?: Maybe<Scalars['Boolean']>;
  UpdateInterviewResult?: Maybe<Scalars['Boolean']>;
  CompleteInterview?: Maybe<Scalars['Boolean']>;
  ReopenInterview?: Maybe<Scalars['Boolean']>;
  DeleteInterviewSchedule: Scalars['Boolean'];
  JobVacancyDelete: Scalars['Boolean'];
  JobPostingInsert: Scalars['Boolean'];
  JobPostingStatusUpdate: Scalars['Boolean'];
  JobPostingUpdate: Scalars['Boolean'];
  JobPostingDelete: Scalars['Boolean'];
  ApplicantStatusUpdate: Scalars['Boolean'];
  FinalSelectionStatusUpdate: Scalars['Boolean'];
  OfferProposalUpdate: Scalars['Boolean'];
  OfferProposalInsert: Scalars['Boolean'];
  OfferApprovalUpdate: Scalars['Boolean'];
  OfferContentInsert: Scalars['Boolean'];
  FinalizeOffer: Scalars['Boolean'];
  UpdateOfferResponse: Scalars['Boolean'];
  SendLetter: Scalars['Boolean'];
  InsertAcceptedPreHiring: Scalars['Boolean'];
  SendHiringEmail: Scalars['Boolean'];
  InsertQuickHiring: Scalars['Boolean'];
  UpdateQuickHiring: Scalars['Boolean'];
  DeleteQuickHiring: Scalars['Boolean'];
  CancelPreHiring: Scalars['Boolean'];
  ApprovePreHiring: Scalars['Boolean'];
  DataUpdateStatusLog: Scalars['Boolean'];
  DeclineNewHiringProcess: Scalars['Boolean'];
  OnboardNewHireProcess: Scalars['Boolean'];
  TalentPoolDelete: Scalars['Boolean'];
  TalentPersonalInsert: Scalars['Boolean'];
  TalentEducationInsert: Scalars['Boolean'];
  TalentHealthInfoInsert: Scalars['Boolean'];
  TalentStatutoryInsert: Scalars['Boolean'];
  TalentCertificateInsert: Scalars['Boolean'];
  TalentMembershipInsert: Scalars['Boolean'];
  TalentEmpHistoryInsert: Scalars['Boolean'];
  TalentReferenceInsert: Scalars['Boolean'];
  TalentDependentInsert: Scalars['Boolean'];
  TalentLanguageInsert: Scalars['Boolean'];
  TalentDocInsert: Scalars['Boolean'];
  TalentInfoDelete: Scalars['Boolean'];
  AddTalentToPrescreen: Scalars['Boolean'];
  SaveTalentSearch: Scalars['Boolean'];
  DeleteTalentSearch: Scalars['Boolean'];
  UpdateTalentStatus: Scalars['Boolean'];
  InsertTalentInvitation: Scalars['Boolean'];
  SendTalentInvitationEmail: Scalars['Boolean'];
  CancelTalentInvitation: Scalars['Boolean'];
  ApproveTalentInvitation: Scalars['Boolean'];
  ApprovalPolicyMatrixInsert?: Maybe<Scalars['Boolean']>;
  ApprovalPolicyMatrixDelete?: Maybe<Scalars['Boolean']>;
  DeleteApprovedRequisition: Scalars['Boolean'];
  RequisitionManagementApprovalUpdate: Scalars['Boolean'];
  RequisitionInsert: RequisitionEntity;
  RequisitionUpdate: Scalars['Boolean'];
  RequisitionDelete: Scalars['Boolean'];
  RequisitionSubmit: Scalars['Boolean'];
  submitRequisitionType: Scalars['Boolean'];
  InvoiceRPA: Array<InvoiceRpaResult>;
  ClaimParameterInsert: ClaimParameterEntity;
  ClaimUserDefineFieldsInsert: Scalars['Boolean'];
  MileageTableInsert: Scalars['Boolean'];
  VehicleRateInsert: Scalars['Boolean'];
  ClaimUserDefineFieldsUpdate: Scalars['Boolean'];
  ClaimUserDefineFieldsDuplicate: Scalars['Boolean'];
  MileageTableUpdate: Scalars['Boolean'];
  MileageTableDelete: Scalars['Boolean'];
  ClaimParameterUpdate: Scalars['Boolean'];
  ClaimParameterDelete: Scalars['Boolean'];
  PersonnelParameterInsert: PersonnelParameterEntity;
  PersonnelParameterUpdate: Scalars['Boolean'];
  PersonnelParameterDelete: Scalars['Boolean'];
  ReportTemplateInsert: Scalars['Boolean'];
  ReportTemplateAssignment: Scalars['Boolean'];
  ReportTemplateInactive: Scalars['Boolean'];
  ReportTemplateUpdate: Scalars['Boolean'];
  submitStaffMovementRequest: Scalars['Boolean'];
  EAPartFInsert: Scalars['Boolean'];
  EAPartFUpdate: Scalars['Boolean'];
  EAPartFDelete: Scalars['Boolean'];
  EAPartFCopyInsert: Scalars['Boolean'];
  EISPolicyUpdate: Scalars['Boolean'];
  EPFPolicyUpdate: Scalars['Boolean'];
  FormItemRatingSubmit: Scalars['Boolean'];
  FormItemRatingDelete: Scalars['Boolean'];
  KpiCategorySubmit: Scalars['Boolean'];
  KpiCategoryDelete: Scalars['Boolean'];
  KpiPredefinedItemSubmit: Scalars['Boolean'];
  KpiPredefinedItemUpdateStatus: Scalars['Boolean'];
  KpiPredefinedItemDelete: Scalars['Boolean'];
  KpiPredefinedTemplateSubmit: Scalars['Boolean'];
  KpiPredefinedTemplateDelete: Scalars['Boolean'];
  ReviewFormTemplateSubmit: Scalars['Boolean'];
  ReviewFormTemplateDelete: Scalars['Boolean'];
  ReviewGradingTableSubmit: Scalars['Boolean'];
  ReviewGradingTableDelete: Scalars['Boolean'];
  ReviewGroupSubmit: Scalars['Boolean'];
  ReviewGroupDelete: Scalars['Boolean'];
  ReviewItemSubmit: Scalars['Boolean'];
  ReviewItemDelete: Scalars['Boolean'];
  ReviewPolicySubmit: Scalars['Boolean'];
  ReviewPolicyDelete: Scalars['Boolean'];
  ReviewProcessPolicySubmit: Scalars['Boolean'];
  ReviewProcessPolicyDelete: Scalars['Boolean'];
  ReviewTemplateSubmit: Scalars['Boolean'];
  ReviewTemplateDelete: Scalars['Boolean'];
  RewardGroupSubmit: Scalars['Boolean'];
  RewardGroupDelete: Scalars['Boolean'];
  PersonnelSecurityPolicySubmit: Scalars['Boolean'];
  HolidayBulkInsert: Scalars['Boolean'];
  HolidayBulkDelete: Scalars['Boolean'];
  HolidayBulkUpdate: Scalars['Boolean'];
  SocsoPolicyUpdate: Scalars['Boolean'];
  TaxReliefInsert: Scalars['Boolean'];
  TaxReliefUpdate: Scalars['Boolean'];
  TaxReliefDelete: Scalars['Boolean'];
  TaxReliefCopyInsert: Scalars['Boolean'];
  MonthlyAttendanceProcessing: Scalars['Boolean'];
  AttendanceProcessingRedirect: Scalars['Boolean'];
  AttendanceProcessing: Scalars['Boolean'];
  UpdateAdjustedValue: Scalars['Boolean'];
  DeleteAutomatedClockDataImport: Scalars['Boolean'];
  CreateUpdateAutomatedClockDataImport: Scalars['Boolean'];
  CreateUpdateBeaconDevice: Scalars['Boolean'];
  createChangeShift: ChangeShiftEntity;
  updateChangeShift: Scalars['Boolean'];
  deleteChangeShift: Scalars['Boolean'];
  updateChangeShiftSubmit: Scalars['Boolean'];
  createDailyAllowance: Scalars['Boolean'];
  updateDailyAllowance: Scalars['Boolean'];
  deleteDailyAllowance: Scalars['Boolean'];
  createDeductionPolicy: DeductionPolicyEntity;
  updateDeductionPolicyStatus: Scalars['Boolean'];
  updateDeductionPolicy: Scalars['Boolean'];
  PostEarnedLeave: Scalars['Boolean'];
  ReverseEarnedLeave: Scalars['Boolean'];
  createJobGradePolicyMatrix: JobGradePolicyMatrixEntity;
  createMobileCheckIn: Scalars['Boolean'];
  updateMobileCheckIn: Scalars['Boolean'];
  deleteMobileCheckIn: Scalars['Boolean'];
  InsertUpdateLocationRadiusSetting: Scalars['Boolean'];
  createMonthlyAllowance: Scalars['Boolean'];
  updateMonthlyAllowance: Scalars['Boolean'];
  deleteMonthlyAllowance: Scalars['Boolean'];
  createOvertimeClaimTable: OvertimeClaimTableEntity;
  updateOvertimeClaimTableStatus: Scalars['Boolean'];
  OTReasonUpdate: Scalars['Boolean'];
  OTReasonDelete: Scalars['Boolean'];
  OTReasonStatus: Scalars['Boolean'];
  OTReasonInsert: OvertimeReasonEntity;
  OvertimePolicyInsert: Scalars['Boolean'];
  OvertimePolicyUpdate: Scalars['Boolean'];
  OvertimePolicyDelete: Scalars['Boolean'];
  createPeriodicDeduction: Scalars['Boolean'];
  updatePeriodicDeduction: Scalars['Boolean'];
  deletePeriodicDeduction: Scalars['Boolean'];
  createAllowanceDeduction: Scalars['Boolean'];
  updateAllowanceDeduction: Scalars['Boolean'];
  deleteAllowanceDeduction: Scalars['Boolean'];
  createOTRounding: Scalars['Boolean'];
  updateOTRounding: Scalars['Boolean'];
  deleteOTRounding: Scalars['Boolean'];
  ShiftSettingInsert: Scalars['Boolean'];
  ShiftSetupUpdate: Scalars['Boolean'];
  ShiftSetupDelete: Scalars['Boolean'];
  createShiftGroup: Scalars['Boolean'];
  updateShiftGroup: Scalars['Boolean'];
  updateShiftGroupStatus: Scalars['Boolean'];
  SubmitShiftGroupPattern: Scalars['Boolean'];
  DeleteShiftGroupPattern: Scalars['Boolean'];
  logShiftGroupEmployeeAssignmentHistory: Scalars['Float'];
  updateShiftGroupEmployeeAssignmentHistory: Scalars['Float'];
  deleteShiftGroupEmployeeAssignmentHistory: Scalars['Float'];
  createShiftPattern: Scalars['Boolean'];
  updateShiftPattern: Scalars['Boolean'];
  deleteShiftPattern: Scalars['Boolean'];
  UserDefineEPFPolicyUpdate: Scalars['Boolean'];
  UserDefineEPFPolicyDelete: Scalars['Boolean'];
  UserDefineEPFPolicyConditionInsertOrUpdate: Scalars['Boolean'];
  UserDefineEPFPolicyConditionDelete: Scalars['Boolean'];
  updateTemplateLibraryAssignment: TemplateLibraryAssignmentEntity;
  createTemplateLibraryAssignment: Scalars['Float'];
  OvertimeSettingUpdate: Scalars['Boolean'];
  replaceTemplateLibraryAssignment: Scalars['Float'];
  replaceTemplateLibraryAssignmentForTMSShiftGroup: Scalars['Float'];
  replaceTemplateLibraryAssignmentv2: Scalars['Float'];
  deleteTemplateLibraryAssignment: Scalars['Float'];
  ShiftGroupAssignment: Scalars['Float'];
  DailyTypeConditionUpdate: Scalars['Boolean'];
  DailyTypeConditionDelete: Scalars['Boolean'];
  DeductionTypeConditionUpdate: Scalars['Boolean'];
  DeductionTypeConditionDelete: Scalars['Boolean'];
  TMSTypeConditionUpdate: Scalars['Boolean'];
  TMSTypeConditionDelete: Scalars['Boolean'];
  TmsotConditionTemplateLibraryUpdate: Scalars['Boolean'];
  TMSTimeOffConditionUpdate: Scalars['Boolean'];
  MonthlyTypeConditionUpdate: Scalars['Boolean'];
  MonthlyTypeConditionDelete: Scalars['Boolean'];
  TimeOffInsert: TimeOffEntity;
  TimeOffUpdate: Scalars['Boolean'];
  TimeOffDelete: Scalars['Boolean'];
  createTimeOff: Scalars['Boolean'];
  TimeOffReasonInput: TimeOffReasonEntity;
  TimeOffReasonUpdate: Scalars['Boolean'];
  TimeOffReasonDelete: Scalars['Boolean'];
  TimeOffSettingInsert: TimeOffSettingEntity;
  TimeOffSettingUpdate: Scalars['Boolean'];
  TimeOffSettingDelete: Scalars['Boolean'];
  TsAccountTypeInsert: Scalars['Boolean'];
  TsAccountTypeUpdate: Scalars['Boolean'];
  TsAccountTypeDelete: Scalars['Boolean'];
  TsAccountCodeInsert: Scalars['Boolean'];
  TsAccountCodeUpdate: Scalars['Boolean'];
  TsAccountCodeDelete: Scalars['Boolean'];
  StaffTimeSheetInsert: Scalars['Boolean'];
  StaffTimesheetDelete: Scalars['Boolean'];
  ToDoStatusUpdate: Scalars['Boolean'];
  createTrainingAttendanceRecord: TrainingAttendanceEntity;
  updateTrainingAttendanceRecord: Scalars['Float'];
  CompulsaryCourseByJobGradeSubmit: Scalars['Boolean'];
  CourseClassificationBulkInsert: Scalars['Boolean'];
  CourseClassificationDelete: Scalars['Boolean'];
  TrainingCourseSubmit: Scalars['Boolean'];
  TrainingCourseDelete: Scalars['Boolean'];
  AuthorisedTrainingProviderSubmit: Scalars['Boolean'];
  TrainingCostItemBulkInsert: Scalars['Boolean'];
  TrainingCostItemDelete: Scalars['Boolean'];
  TraineeEvalSubmit: Scalars['Boolean'];
  SupervisorEvalSubmit: Scalars['Boolean'];
  CourseEvalSubmit: Scalars['Boolean'];
  TrainerEvalSubmit: Scalars['Boolean'];
  ScheduleTrainingSubmit: Scalars['Boolean'];
  ScheduleTableSubmit: Scalars['Boolean'];
  ScheduleTrainingDelete: Scalars['Boolean'];
  ScheduleTrainingCancel: Scalars['Boolean'];
  RegisteredTraineeInsert: Scalars['Boolean'];
  RegisteredTraineeDelete: Scalars['Boolean'];
  TrainingBudgetInputBulkInsert: Scalars['Boolean'];
  TrainingCostSubmit: Scalars['Boolean'];
  CostAllocationSubmit: Scalars['Boolean'];
  HRDFClaimSubmit: Scalars['Boolean'];
  SubmitBudgetGroupMatrix?: Maybe<Scalars['Boolean']>;
  DeleteBudgetGroupMatrix?: Maybe<Scalars['Boolean']>;
  SubmitTargetGroupMatrix?: Maybe<Scalars['Boolean']>;
  DeleteTargetGroupMatrix?: Maybe<Scalars['Boolean']>;
  SubmitApprovalPolicyMatrix?: Maybe<Scalars['Boolean']>;
  DeleteApprovalPolicyMatrix?: Maybe<Scalars['Boolean']>;
  IsMaintenanceBudgetPeriodOverlap?: Maybe<Scalars['Boolean']>;
  SubmitMaintenanceBudget?: Maybe<Scalars['Boolean']>;
  DeleteMaintenanceBudget?: Maybe<Scalars['Boolean']>;
  IsMaintenanceTargetHoursPeriodOverlap?: Maybe<Scalars['Boolean']>;
  SubmitMaintenanceTargetHours?: Maybe<Scalars['Boolean']>;
  DeleteMaintenanceTargetHours?: Maybe<Scalars['Boolean']>;
  SubmitMaintenanceCourse?: Maybe<Scalars['Boolean']>;
  SubmitTrainingRequest?: Maybe<Scalars['Boolean']>;
  DeleteTrainingRequest?: Maybe<Scalars['Boolean']>;
  WithdrawTrainingRequest?: Maybe<Scalars['Boolean']>;
  SubmitScheduledTraining?: Maybe<Scalars['Boolean']>;
  DeleteScheduledTraining?: Maybe<Scalars['Boolean']>;
  UpdateScheduledTrainingEventStatus?: Maybe<Scalars['Boolean']>;
  SubmitAdminTrainingRegistration?: Maybe<Scalars['Boolean']>;
  UpdateEmployeeStatusAndNominator?: Maybe<Scalars['Boolean']>;
  UpdateEmployeeScheduledTrainingStatus?: Maybe<Scalars['Boolean']>;
  DeleteEmployeeTrainingRegistration?: Maybe<Scalars['Boolean']>;
  SubmitVenueInfo: Scalars['Boolean'];
  NotifyTrainee?: Maybe<Scalars['Boolean']>;
  SubmitTrainingAttendance?: Maybe<Scalars['Boolean']>;
  FinalizedAttendance?: Maybe<Scalars['Boolean']>;
  GenerateTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  InitiateTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  SubmitSurveyFormAnswer?: Maybe<Scalars['Boolean']>;
  UploadTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  RevertDraftTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  ClearRevertDraftTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  DeleteTrainingEvaluation?: Maybe<Scalars['Boolean']>;
  SubmitScheduledTrainingExpense?: Maybe<Scalars['Boolean']>;
  SubmitHRDCClaim?: Maybe<Scalars['Boolean']>;
  UpdateHRDCClaimStatus?: Maybe<Scalars['Boolean']>;
  DeleteHRDCClaim?: Maybe<Scalars['Boolean']>;
  EmployeeSubmitTrainingRequest?: Maybe<Scalars['Boolean']>;
  EmployeeDeleteTrainingRequest?: Maybe<Scalars['Boolean']>;
  EmployeeCancelTrainingRequest?: Maybe<Scalars['Boolean']>;
  TrainingCheckIn: Scalars['JSON'];
  WithdrawSubordinateTrainingScheduled?: Maybe<Scalars['Boolean']>;
  ApplyTrainingEvent?: Maybe<Scalars['Boolean']>;
  NominateSubordinate?: Maybe<Scalars['Boolean']>;
  WithdrawSubordinate?: Maybe<Scalars['Boolean']>;
  SubmitCourseCategory?: Maybe<Scalars['Boolean']>;
  DeleteCourseCategory?: Maybe<Scalars['Boolean']>;
  SubmitTrainingCourseLibrary: Scalars['Boolean'];
  DeleteTrainingCourseLibrary?: Maybe<Scalars['Boolean']>;
  SubmitTrainingProvider?: Maybe<Scalars['Boolean']>;
  DeleteTrainingProvider?: Maybe<Scalars['Boolean']>;
  SubmitSurveyType?: Maybe<Scalars['Boolean']>;
  DeleteSurveyType?: Maybe<Scalars['Boolean']>;
  SubmitSurveyItem?: Maybe<Scalars['Boolean']>;
  DeleteSurveyItem?: Maybe<Scalars['Boolean']>;
  SubmitSurveyForm?: Maybe<Scalars['Boolean']>;
  DeleteSurveyForm?: Maybe<Scalars['Boolean']>;
  SubmitTrainingExpense?: Maybe<Scalars['Boolean']>;
  DeleteTrainingExpense?: Maybe<Scalars['Boolean']>;
  SubmitBudgetGroup?: Maybe<Scalars['Boolean']>;
  DeleteBudgetGroup?: Maybe<Scalars['Boolean']>;
  SubmitTargetGroup?: Maybe<Scalars['Boolean']>;
  DeleteTargetGroup?: Maybe<Scalars['Boolean']>;
  SubmitTrainerProfile?: Maybe<Scalars['Boolean']>;
  DeleteTrainerProfile?: Maybe<Scalars['Boolean']>;
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  AllowancePolicyInsert: AllowanceBenfitEntity;
  AllowancePolicyUpdate: Scalars['Boolean'];
  AllowanceEntitlementInsert: AllowanceItemEntity;
  AllowanceEntitlementUpdate: Scalars['Boolean'];
  UpdateLastProcess: Scalars['Boolean'];
  PayProcessInsert: Scalars['Boolean'];
  employeeAppLogin: EmployeeAppLoginResponse;
  createUser: UserEntity;
  genHashPassword: Scalars['JSON'];
  createSuperUser: UserEntity;
  resendInvitation: Scalars['Boolean'];
  updateUser: UserEntity;
  activateUser: Scalars['Boolean'];
  RedirectDomainToken: Scalars['String'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  changeUserPassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  employeeLogout: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  forgotEmployeePassword: Scalars['Boolean'];
  resetEmployeePassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  createPassword: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  userActionStatus: Scalars['Boolean'];
  updateHelpdeskAssignee: Scalars['Boolean'];
  changeEmployeePassword: Scalars['Boolean'];
  UpdateLastLoginCompany: Scalars['Boolean'];
  UserProfilePicUpdate: Scalars['Boolean'];
  ResendInvitationById: Scalars['Boolean'];
};


export type MutationCreateDutyRosterArgs = {
  DutyRosterInput: DutyRosterInput;
};


export type MutationDutyRosterBatchArgs = {
  removeList?: Maybe<Array<DutyRosterInput>>;
  Input?: Maybe<Array<DutyRosterInput>>;
};


export type MutationShiftPatternAssignmentToEmployeeArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  ShiftPatternID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type MutationCreateBatchEntryByShiftPatternArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  Input: DutyRosterInput;
};


export type MutationDeleteDutyRosterByEmployeeArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type MutationDeleteDutyRosterForAllArgs = {
  CompanyID: Scalars['String'];
};


export type MutationDeleteDutyRosterBySelectedEmployeeArgs = {
  Date: Array<Scalars['String']>;
  EmployeeID: Array<Scalars['String']>;
};


export type MutationNewsReadInsertArgs = {
  NewsReadInput: NewsReadInput;
};


export type MutationNotificationUpdateArgs = {
  input: NotificationInput;
};


export type MutationDeleteAttendanceRecordArgs = {
  input: AttendanceRecordInput;
};


export type MutationCreateAttendanceRecordArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  input: AttendanceRecordInput;
};


export type MutationSubmitPairedAttandanceRecordArgs = {
  DataToDelete?: Maybe<Array<Scalars['String']>>;
  input: Array<AttendanceRecordPairedObj>;
};


export type MutationUpdateAttendanceRecordArgs = {
  input: AttendanceRecordInput;
};


export type MutationDeleteTimeAttendanceRecordArgs = {
  AttendanceRecordID: Array<Scalars['String']>;
};


export type MutationAttendanceExcelUploadArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationAttendanceExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationAttendanceExcelInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationAttendanceRecordBatchDeleteArgs = {
  Input: Array<AttendanceRecordInput>;
};


export type MutationAttendanceRecordBatchArgs = {
  Input: Array<AttendanceRecordInput>;
};


export type MutationAutomatedClockPairingArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeList: Array<Scalars['String']>;
};


export type MutationDocumentInsertArgs = {
  IsStatic?: Maybe<Scalars['Boolean']>;
  DocumentsInput: DocumentsInput;
};


export type MutationDocumentUpdateArgs = {
  DocumentsID: Scalars['String'];
  DocumentsInput: DocumentsInput;
};


export type MutationDocumentUpdateParentIdArgs = {
  DocumentID: Scalars['String'];
  ParentID: Scalars['String'];
};


export type MutationDocumentDeleteArgs = {
  DocumentID: Scalars['String'];
};


export type MutationEmployeeProfileInsertUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
};


export type MutationAppraisalCategoryInsertArgs = {
  input: AppraisalCategoryInput;
};


export type MutationAppraisalCategoryUpdateArgs = {
  AppraisalCategoryID: Scalars['String'];
  input: AppraisalCategoryInput;
};


export type MutationAppraisalCategoryDeleteArgs = {
  AppraisalCategoryID: Scalars['String'];
};


export type MutationAppraisalCategoryItemInsertArgs = {
  input: AppraisalCategoryItemInput;
};


export type MutationAppraisalCategoryItemUpdateArgs = {
  AppraisalCategoryItemID: Scalars['String'];
  input: AppraisalCategoryItemInput;
};


export type MutationAppraisalCategoryItemDeleteArgs = {
  AppraisalCategoryItemID: Scalars['String'];
};


export type MutationAppraisalPolicyInsertArgs = {
  input: AppraisalPolicyInput;
};


export type MutationAppraisalPolicyDeleteArgs = {
  AppraisalPolicyID: Scalars['String'];
};


export type MutationAppraisalTemplateByJobPositionSubmitArgs = {
  input: AppraisalTempleteByJobPositionHeaderInput;
};


export type MutationAppraisalScheduleSubmitArgs = {
  input: AppraisalScheduleHeaderInput;
};


export type MutationAppraisalCompanySettingSubmitArgs = {
  input: AppraisalCompanySettingInput;
};


export type MutationFinalRatingSubmitArgs = {
  input: FinalRatingHeaderInput;
};


export type MutationFinalRatingDeleteArgs = {
  FinalRatingHeaderID: Scalars['String'];
};


export type MutationScaleRatingSubmitArgs = {
  input: ScaleRatingHeaderInput;
};


export type MutationScaleRatingDeleteArgs = {
  ScaleRatingHeaderID: Scalars['String'];
};


export type MutationBonusSimulationSubmitArgs = {
  BonusSimulationInput: Array<BonusSimulationInput>;
};


export type MutationIncrementSimulationSubmitArgs = {
  IncrementSimulationInput: Array<IncrementSimulationInput>;
};


export type MutationSubmitAppraisalTempleteArgs = {
  input: AppraisalTempleteInput;
};


export type MutationSubmitAppraisalArgs = {
  DocToRemove?: Maybe<Array<Scalars['String']>>;
  DocList?: Maybe<Array<Scalars['String']>>;
  DocInput?: Maybe<Array<DocumentsInput>>;
  Input: EmployeeAppraisalHeaderInput;
};


export type MutationModerationSubmitArgs = {
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
  input: Array<ModerationRatingInput>;
};


export type MutationSubmitStaffKpiPlanningArgs = {
  LatestVersion?: Maybe<Scalars['String']>;
  Submmit_Type: Scalars['String'];
  Input: EmployeeKpiHeaderInput;
};


export type MutationStaffKpiPlannigDeleteArgs = {
  EmployeeKPIHeaderID: Scalars['String'];
};


export type MutationStaffPerformanceAnalysisSummarySubmitArgs = {
  input: Array<StaffPerformanceAnalysisSummaryInput>;
};


export type MutationApprovalPolicyInsertArgs = {
  ApprovalPolicyInput: ApprovalPolicyInput;
};


export type MutationApprovalPolicyUpdateArgs = {
  ApprovalPolicyInput: ApprovalPolicyInput;
};


export type MutationApprovalPolicySubmitArgs = {
  input: NewApprovalPolicyInput;
};


export type MutationApprovalPolicyDeleteArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationApprovalPolicyAssignmentUpdateArgs = {
  ApprovalPolicyInput: ApprovalPolicyAssignmentInput;
};


export type MutationNewApprovalPolicyAssignmentDeleteArgs = {
  ApprovalPolicyAssignmentID: Scalars['String'];
};


export type MutationAttendanceAnalysisTemplateInsertArgs = {
  AttendanceAnalysisTemplateInput?: Maybe<Array<AttendanceAnalysisTemplateInput>>;
};


export type MutationSubmitAttendanceCorrectionRequestArgs = {
  input: AttendanceCorrectionInput;
};


export type MutationCancelAttendanceCorrectionRequestArgs = {
  AttendanceCorrectionRequestHeaderID: Scalars['String'];
};


export type MutationAttendanceCorrectionRequestApprovalArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: AttendanceCorrectionRequestUpdateInput;
};


export type MutationPostAttendanceRecordArgs = {
  input: AttendancePostInput;
};


export type MutationReverseAttendanceRecordArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  isSelectAll?: Maybe<Scalars['Boolean']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EmployeePayItemIDPairs: Array<AttendanceCalculationInput>;
};


export type MutationAttendancePeriodEditArgs = {
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type MutationAttendanceProccessBulkDeleteArgs = {
  AttendanceCalculationID: Array<Scalars['String']>;
};


export type MutationOvertimeInsertArgs = {
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  input: OvertimeInput;
};


export type MutationOvertimeDraftArgs = {
  input: OvertimeInput;
};


export type MutationOvertimeBatchArgs = {
  Input: Array<OvertimeInput>;
};


export type MutationOvertimeUpdateArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  ActionStatus?: Maybe<Scalars['String']>;
  input: Array<OvertimeInput>;
};


export type MutationOvertimeDeleteArgs = {
  OvertimeID: Scalars['String'];
};


export type MutationOvertimeExcelUploadArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationOvertimeExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationOvertimeExcelInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationBatchOvertimeEntryInsertArgs = {
  Remark?: Maybe<Scalars['String']>;
  BatchOvertimeStatus?: Maybe<OvertimeStatus>;
  EmployeeIDs: Array<Scalars['String']>;
  SubmitterID: Scalars['String'];
  Duration: Scalars['Float'];
  EndTime: Scalars['DateTime'];
  StartTime: Scalars['DateTime'];
  WorkDate: Scalars['DateTime'];
  ShiftID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationBatchOvertimeCancelArgs = {
  ActionStatus?: Maybe<Scalars['String']>;
  BatchOvertimeInput?: Maybe<Array<BatchOvertimeInput>>;
};


export type MutationBatchOvertimeWithdrawArgs = {
  ActionStatus?: Maybe<Scalars['String']>;
  BatchOvertimeInput?: Maybe<Array<BatchOvertimeInput>>;
};


export type MutationToDoBatchOvertimeDraftArgs = {
  BatchOvertimeInput?: Maybe<Array<BatchOvertimeInput>>;
};


export type MutationToDoBatchOvertimeSubmitArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  StatusApproval?: Maybe<ApprovalStatus>;
  BatchOvertimeInput?: Maybe<Array<BatchOvertimeInput>>;
};


export type MutationToDoBatchOvertimeWithdrawalApprovalArgs = {
  StatusApproval?: Maybe<ApprovalStatus>;
  BatchOvertimeInput?: Maybe<Array<BatchOvertimeInput>>;
  RejectedRemarks?: Maybe<Scalars['String']>;
};


export type MutationSubmitManagementClaimRecordsArgs = {
  input: SubmitClaimRecordsInput;
};


export type MutationApproveClaimRecordsArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: ApproveClaimRecordsInput;
};


export type MutationCancelClaimRecordsArgs = {
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderID: Scalars['String'];
};


export type MutationDeleteApprovedClaimRecordsArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  CancelRemark?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  input: ApproveClaimRecordsInput;
};


export type MutationReverseApprovedClaimRecordsArgs = {
  input: ApproveClaimRecordsInput;
};


export type MutationVerifyApprovedClaimDetailArgs = {
  input: VerifyApprovedClaimDetailInput;
};


export type MutationPostClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  input: PostClaimRecordsInput;
};


export type MutationReverseClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationSubmitMyClaimArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  workFlowStepInput?: Maybe<Array<WorkFlowStepInput>>;
  input: SubmitClaimRecordsInput;
};


export type MutationDeleteClaimRecordsArgs = {
  ClaimHeaderIDs: Array<Scalars['String']>;
  SubscriptionUserID: Scalars['String'];
};


export type MutationSubmitClaimForApprovalArgs = {
  ClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationDoUpdateClaimArgs = {
  ClaimDetailsInput: Array<ClaimDetailsInput>;
  input: ClaimHeaderInput;
  ClaimHeaderID: Scalars['String'];
};


export type MutationMyClaimInsertArgs = {
  ClaimDetailsInput: Array<ClaimDetailsInput>;
  ClaimHeaderInput: ClaimHeaderInput;
};


export type MutationClaimValidityUpdateArgs = {
  ModifiedBy: Scalars['String'];
  CreatedBy: Scalars['String'];
  ClaimTrxDayLimit: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationClaimValidityInsertArgs = {
  ClaimSettingInput: ClaimSettingInput;
};


export type MutationClaimTypeInsertArgs = {
  input: ClaimTypeInput;
};


export type MutationClaimTypeUpdateArgs = {
  ClaimTypeID: Scalars['String'];
  input: ClaimTypeInput;
};


export type MutationClaimTypeDeleteArgs = {
  ClaimTypeID: Scalars['String'];
};


export type MutationClaimTypeTemplateInsertArgs = {
  claimDailyRateInput?: Maybe<Array<ClaimDailyRateInput>>;
  claimMealRateInput?: Maybe<Array<ClaimMealRateInput>>;
  claimVehicleRateInput?: Maybe<Array<ClaimVehicleRateInput>>;
  claimItemInput: Array<ClaimItemInput>;
  claimBenefitInput: ClaimBenefitInput;
};


export type MutationClaimTypeTemplateUpdateArgs = {
  claimDailyRateInput?: Maybe<Array<ClaimDailyRateInput>>;
  claimMealRateInput?: Maybe<Array<ClaimMealRateInput>>;
  claimVehicleRateInput?: Maybe<Array<ClaimVehicleRateInput>>;
  claimItemInput: Array<ClaimItemInput>;
  claimBenefitInput: ClaimBenefitInput;
};


export type MutationClaimTypeTemplateDeleteArgs = {
  ClaimBenefitID: Scalars['String'];
};


export type MutationClaimTypeTemplateUpdateStatusArgs = {
  claimBenefitInput: ClaimBenefitInput;
};


export type MutationClaimTypeConditionUpdateArgs = {
  ClaimTemplateInput: ClaimTemplateInput;
};


export type MutationClaimTypeConditionDeleteArgs = {
  ClaimTemplateID: Scalars['String'];
};


export type MutationClaimJobGradePolicyInsertArgs = {
  ClaimTemplateInput?: Maybe<Array<ClaimTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationClaimJobGradePolicyUpdateArgs = {
  JobGradePolicyID: Scalars['String'];
  ClaimTemplateInput?: Maybe<Array<ClaimTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationClaimPoolDeleteArgs = {
  ClaimPoolID: Scalars['String'];
};


export type MutationClaimPoolSubmitArgs = {
  ClaimPoolInput: ClaimPoolInput;
};


export type MutationInsertClaimCutOffPeriodArgs = {
  input: GenerateClaimCutOffPeriodInput;
};


export type MutationBankInsertArgs = {
  BankInfoInputs: BankInfoInputs;
  CompanyID: Scalars['String'];
};


export type MutationBankUpdateArgs = {
  BankInfoID: Scalars['String'];
  input: BankInfoInputs;
};


export type MutationBankDeleteArgs = {
  BankInfoID: Scalars['String'];
};


export type MutationCompanyOffDaysDeleteArgs = {
  CompanyOffDayID: Array<Scalars['String']>;
};


export type MutationUpdateCompanyOffDaysStatusArgs = {
  Status: Status;
  CompanyOffDayID: Array<Scalars['String']>;
};


export type MutationUpdateRestOffDayStatusArgs = {
  Status: Status;
  RestOffDayID: Array<Scalars['String']>;
};


export type MutationCompanyOffDaysBulkInsertArgs = {
  StateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyOffDayInput: Array<CompanyOffDayInput>;
};


export type MutationCalendarPolicyStatusUpdateArgs = {
  Status: Status;
  CalendarPolicyID: Array<Scalars['String']>;
};


export type MutationCalendarPolicyDeleteArgs = {
  CalendarPolicyID: Array<Scalars['String']>;
};


export type MutationOffRestDayDeleteArgs = {
  RecurringRestOffDayID: Array<Scalars['String']>;
};


export type MutationOffRestDayBulkInsertArgs = {
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  RecurringRestOffDayInput: Array<RecurringRestOffDayInput>;
};


export type MutationCalendarOffDayUpdateArgs = {
  input: CalendarOffDayInput;
  CalendarOffDayID: Scalars['String'];
};


export type MutationCalendarOffDayInsertArgs = {
  input: CalendarOffDayInput;
};


export type MutationCalanderPolicySubmitArgs = {
  RecurringRestOffDayInput: Array<RecurringRestOffDayInput>;
  CompanyDelaredHolidayInput: Array<CompanyDelaredHolidayInput>;
  CalendarPolicyInput?: Maybe<CalendarPolicyInput>;
  StateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyOffDayInput: Array<CompanyOffDayInput>;
};


export type MutationCompanyHolidaysBulkInsertArgs = {
  CompanyDelaredHolidayInput: Array<CompanyDelaredHolidayInput>;
  StateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyOffDayInput: Array<CompanyOffDayInput>;
};


export type MutationCompanyDocumentInsertArgs = {
  DocumentsInput: DocumentsInput;
};


export type MutationCompanyDocumentDeleteArgs = {
  DocumentID: Scalars['String'];
};


export type MutationCompanyFolderCreateArgs = {
  userID: Scalars['String'];
  CompanyID: Scalars['String'];
  FolderName: Scalars['String'];
};


export type MutationCompanyFolderDeleteArgs = {
  FolderID: Scalars['String'];
};


export type MutationCompanyInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  ContactInput: ContactInput;
  CompanyInput: CompanyInput;
};


export type MutationCompanyUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  ContactInput: ContactInput;
  CompanyInput: CompanyInput;
  ContactID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationCompanyLeaveSettingUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationCompanyTaxReliefUpdateArgs = {
  ExcludeTaxRelief: Scalars['Boolean'];
  CompanyID: Scalars['String'];
};


export type MutationCompanyClaimSettingUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationCompanyProjectExpenseClaimSettingUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationCompanyOnlyUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationJobDesignationInsertArgs = {
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobPositionInsertArgs = {
  DesignationSkillInput: Array<JobDesignationSkillSetInput>;
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobDesignationUpdateArgs = {
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobDesignationSequenceUpdateArgs = {
  JobDesignationIDs: Array<Scalars['String']>;
};


export type MutationJobPositionUpdateArgs = {
  DesignationSkillInput: Array<JobDesignationSkillSetInput>;
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobDesignationDeleteArgs = {
  JobDesignationID: Scalars['String'];
};


export type MutationJobPositionDeleteArgs = {
  JobDesignationID: Scalars['String'];
};


export type MutationRecruitmentSourceBulkInsertArgs = {
  Input: Array<RecruitmentSourceBulkInput>;
};


export type MutationRecruitmentSourceInsertArgs = {
  input: RecruitmentSourceInput;
};


export type MutationRecruitmentSourceUpdateArgs = {
  input: RecruitmentSourceInput;
  RecruitmentSourceID: Scalars['String'];
};


export type MutationRecruitmentSourceDeleteArgs = {
  RecruitmentSourceID: Scalars['String'];
};


export type MutationCompanyAltAddressInsertArgs = {
  input: CompanyAltAddressInput;
};


export type MutationCompanyAltAddressUpdateArgs = {
  input: CompanyAltAddressInput;
  CompanyAltAddressID: Scalars['String'];
};


export type MutationCompanyAltAddressDeleteArgs = {
  CompanyAltAddressID: Scalars['String'];
};


export type MutationCompanyStatutoryAccountUpdateArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  CompanyStatutoryID: Scalars['String'];
  input: Array<CompanyStatutoryAccountInput>;
};


export type MutationCompanyStatutoryInsertArgs = {
  CompanyStatutoryInput: CompanyStatutoryInput;
};


export type MutationCompanyStatutoryUpdateArgs = {
  CompanyStatutoryInput: CompanyStatutoryInput;
  CompanyStatutoryID: Scalars['String'];
};


export type MutationCompanyStructureInsertArgs = {
  input: Array<CompanyHierarchyInput>;
};


export type MutationCompanyOrgUnitInsertArgs = {
  input: Array<CompanyOrgUnitInput>;
};


export type MutationCompanyOrgUnitUpdateArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  Inputs: Array<CompanyOrgUnitInput>;
};


export type MutationAssignJobPositionArgs = {
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  input: Array<JobPositionAssignmentInput>;
};


export type MutationCreateCompanyAccessPermArgs = {
  RoleID: Scalars['String'];
  CompanyID: Scalars['String'];
  input: Array<CompanyAccessPermInput>;
};


export type MutationDepartmentInsertArgs = {
  input: DepartmentInput;
};


export type MutationDepartmentUpdateArgs = {
  DepartmentID: Scalars['String'];
  input: DepartmentUpdateInput;
};


export type MutationDepartmentDeleteArgs = {
  DepartmentUpdateInput: DepartmentUpdateInput;
};


export type MutationDivisionInsertArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationDivisionUpdateArgs = {
  DepartmentID: Scalars['String'];
  input: DepartmentUpdateInput;
};


export type MutationDivisionDeleteArgs = {
  DepartmentID: Scalars['String'];
};


export type MutationCreateInsuranceSchemeArgs = {
  InsuranceSchemeInput: InsuranceSchemeInput;
};


export type MutationInsuranceSchemeInsertArgs = {
  InsuranceSchemeInsertInput: InsuranceSchemeInsertInput;
};


export type MutationUpdateInsuranceSchemeArgs = {
  InsuranceSchemeInput: InsuranceSchemeInput;
  InsuranceSchemeID: Scalars['String'];
};


export type MutationInsuranceSchemeUpdateArgs = {
  InsuranceSchemeInsertInput: InsuranceSchemeInsertInput;
  InsuranceSchemeID: Scalars['String'];
};


export type MutationDeleteInsuranceSchemeArgs = {
  InsuranceSchemeID: Scalars['String'];
};


export type MutationInsuranceSchemeDeleteArgs = {
  InsuranceSchemeID: Scalars['String'];
};


export type MutationInterbankInsertArgs = {
  InterbankGiroInput: InterbankGiroInput;
};


export type MutationInterbankBulkInsertArgs = {
  InterbankGiroInput: Array<InterbankGiroInput>;
};


export type MutationInterbankDeleteArgs = {
  InterbankGiroID: Scalars['String'];
};


export type MutationInterbankUpdateArgs = {
  InterbankGiroInput: Array<InterbankGiroInput>;
};


export type MutationInterbankSingleUpdateArgs = {
  InterbankGiroInput: InterbankGiroInput;
  InterbankGiroID: Scalars['String'];
};


export type MutationJobAccountInsertArgs = {
  MyJobAccountInput: Array<MyJobAccountInput>;
  JobAccountInput: JobAccountInput;
};


export type MutationJobAccountUpdateArgs = {
  MyJobAccountInput: Array<MyJobAccountInput>;
  JobAccountInput: JobAccountInput;
};


export type MutationJobAccountDeleteArgs = {
  JobAccountID: Scalars['String'];
};


export type MutationJobGradeBulkInsertArgs = {
  input: Array<JobGradeBulkInsertInput>;
};


export type MutationJobGradeInsertArgs = {
  input: JobGradeInput;
};


export type MutationJobGradeUpdateArgs = {
  JobGradeID: Scalars['String'];
  input: JobGradeInput;
};


export type MutationJobGradeDeleteArgs = {
  JobGradeID: Scalars['String'];
};


export type MutationLeaveRoundingBulkInsertArgs = {
  LeaveRoundingInput: Array<LeaveRoundingInput>;
};


export type MutationLeaveRoundingInsertArgs = {
  LeaveRoundingDetailInput: Array<LeaveRoundingDetailInput>;
  LeaveRoundingInput: LeaveRoundingInput;
};


export type MutationUpdateLeaveRoundingArgs = {
  LeaveRoundingDetailInput: Array<LeaveRoundingDetailInput>;
  LeaveRoundingInput: LeaveRoundingInput;
  LeaveRoundingID: Scalars['String'];
};


export type MutationLeaveRoundingUpdateArgs = {
  LeaveRoundingInput: Array<LeaveRoundingInput>;
};


export type MutationLeaveRoundingDeleteArgs = {
  LeaveRoundingID: Scalars['String'];
};


export type MutationLeaveCompulsoryPolicyBulkInsertArgs = {
  LeaveCompulsoryPolicyInput: Array<LeaveCompulsoryPolicyInput>;
};


export type MutationLeaveCompulsoryPolicyUpdateArgs = {
  LeaveCompulsoryPolicyInput: Array<LeaveCompulsoryPolicyInput>;
};


export type MutationLhdnReportInsertArgs = {
  input: EaFormSetupInput;
};


export type MutationLhdnReportUpdateArgs = {
  input: EaFormSetupInput;
  EAFormSetupID: Scalars['String'];
};


export type MutationLhdnReportCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationNotificationPolicyInsertArgs = {
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyUpdateArgs = {
  NotificationPolicyID: Scalars['String'];
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyDeleteArgs = {
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyConditionInsertArgs = {
  NotificationPolicyConditionInput: NotificationPolicyConditionInput;
};


export type MutationNotificationPolicyConditionUpdateArgs = {
  NotificationPolicyConditionID: Scalars['String'];
  NotificationPolicyConditionInput: NotificationPolicyConditionInput;
};


export type MutationPayrollCycleInsertArgs = {
  input: PayrollCycleInput;
};


export type MutationPayrollCycleUpdateArgs = {
  PayrollCycleID: Scalars['String'];
  input: PayrollCycleInput;
};


export type MutationPayrollCycleDeleteArgs = {
  PayrollCycleID: Scalars['String'];
};


export type MutationReportingGroupSubmitArgs = {
  input: ReportingGroupInput;
};


export type MutationReportingGroupDeleteArgs = {
  ReportingGroupID: Scalars['String'];
};


export type MutationReportingCompanyGroupDeleteArgs = {
  ReportingGroupID: Scalars['String'];
};


export type MutationReportingStructureGroupSubmitArgs = {
  input: ReportingStructureGroupInput;
};


export type MutationCreateSkillSetArgs = {
  SkillSetDetailInput: Array<SkillSetDetailInput>;
  SkillSetInput: SkillSetInput;
};


export type MutationUpdateSkillSetArgs = {
  SkillSetDetailInput: Array<SkillSetDetailInput>;
  SkillSetInput: SkillSetInput;
  SkillSetID: Scalars['String'];
};


export type MutationDeleteSkillSetArgs = {
  SkillSetID: Scalars['String'];
};


export type MutationUserDefineBulkInsertArgs = {
  input: Array<UserDefineBulkInput>;
};


export type MutationPostOffboardingLeaveEncashmentArgs = {
  input: Array<EncashOffboadingLeaveInput>;
};


export type MutationRevertOffboardingLeaveEncashmentArgs = {
  input: Array<EncashOffboadingLeaveInput>;
};


export type MutationPostFromPlatformLeaveTypesArgs = {
  SubscriptionUserID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationSubmitMyLeaveApplicationArgs = {
  input: EmpLeaveApplicationInput;
};


export type MutationPostLeaveApplicationArgs = {
  input: EmpLeaveApplicationInput;
};


export type MutationSubmitLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationSubmitLeaveApplicationV2Args = {
  input: EmpLeaveDetailInput;
};


export type MutationDeleteLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationDeletePendingLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationDeleteApprovedLeaveApplicationArgs = {
  IsAutoWithdrawal?: Maybe<Scalars['Boolean']>;
  IsSupersede?: Maybe<Scalars['Boolean']>;
  Remark: Scalars['String'];
  input: EmpLeaveDetailInput;
};


export type MutationPostLeaveOpeningYearArgs = {
  Year: Scalars['Float'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type MutationLeaveTypeInsertArgs = {
  input: LeaveTypeInput;
};


export type MutationLeaveTypeUpdateArgs = {
  input: LeaveTypeInput;
  LeaveTypeID: Scalars['String'];
};


export type MutationLeaveTypeDeleteArgs = {
  LeaveTypeID: Scalars['String'];
};


export type MutationLeaveReasonInsertArgs = {
  input: LeaveReasonInput;
};


export type MutationLeaveReasonUpdateArgs = {
  input: LeaveReasonInput;
  LeaveReasonID: Scalars['String'];
};


export type MutationLeaveReasonDeleteArgs = {
  LeaveReasonID: Scalars['String'];
};


export type MutationLeaveDiseaseInsertArgs = {
  input: LeaveDiseaseInput;
};


export type MutationLeaveDiseaseUpdateArgs = {
  input: LeaveDiseaseInput;
  LeaveDiseaseID: Scalars['String'];
};


export type MutationLeaveDiseaseDeleteArgs = {
  LeaveDiseaseID: Scalars['String'];
};


export type MutationLeaveJobGradePolicyInsertArgs = {
  LeaveTemplateInput?: Maybe<Array<LeaveTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationLeaveJobGradePolicyUpdateArgs = {
  JobGradePolicyID: Scalars['String'];
  LeaveTemplateInput?: Maybe<Array<LeaveTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationLeaveTypeConditionUpdateArgs = {
  LeaveTemplateInput: LeaveTemplateInput;
};


export type MutationLeaveTypeConditionDeleteArgs = {
  LeaveTemplateID: Scalars['String'];
};


export type MutationLeavePolicyUpdateArgs = {
  LOSInput?: Maybe<Array<LosInput>>;
  leavePolicyInput: LeavePolicyInput;
};


export type MutationLeavePolicyDeleteArgs = {
  LeavePolicyID: Scalars['String'];
};


export type MutationLeavePolicyInsertArgs = {
  LOSInput?: Maybe<Array<LosInput>>;
  leavePolicyInput: LeavePolicyInput;
};


export type MutationLeaveTypeTemplateInsertArgs = {
  leaveItemInput: Array<LeaveItemInput>;
  leaveBenefitInput: LeaveBenefitInput;
};


export type MutationLeaveTypeTemplateUpdateArgs = {
  leaveItemInput: Array<LeaveItemInput>;
  leaveBenefitInput: LeaveBenefitInput;
};


export type MutationLeaveTypeTemplateDeleteArgs = {
  LeaveBenefitID: Scalars['String'];
};


export type MutationUserDashboardControlSubmitArgs = {
  input: UserDashboardControlInput;
};


export type MutationDataChangesInsertArgs = {
  PersonnelEducationID?: Maybe<Scalars['String']>;
  PersonnelEducationInsertInput?: Maybe<PersonnelEducationInsertInput>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  PersonnelCertificateInput?: Maybe<PersonnelCertificateInput>;
  DocumentsInput?: Maybe<DocumentsInput>;
  input: DataChangesInput;
};


export type MutationSubmitDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationDeleteDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationCancelDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationApproveDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationRejectDataChangesArgs = {
  input: DataChangesInput;
};


export type MutationDataChangesApprovalArgs = {
  UpdateBy: Scalars['String'];
  input: Array<DataChangesInput>;
};


export type MutationDataChangesRejectArgs = {
  UpdateBy: Scalars['String'];
  input: Array<DataChangesInput>;
};


export type MutationShopeeDataImportVerifyArgs = {
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type MutationShopeeDataImportVerifyRedirectArgs = {
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type MutationDataImportVerifyArgs = {
  PayItemMapping?: Maybe<Array<PayItemMappingInput>>;
  File: Scalars['Upload'];
  Type: Scalars['String'];
};


export type MutationDataImportVerifyRedirectArgs = {
  PayItemMapping?: Maybe<Array<PayItemMappingInput>>;
  File: Scalars['Upload'];
  Type: Scalars['String'];
};


export type MutationRemoveTempDataArgs = {
  Type: Scalars['String'];
};


export type MutationProceedForImportArgs = {
  Type: Scalars['String'];
};


export type MutationActivateEmployeesArgs = {
  Emails: Array<Scalars['String']>;
  IsSelectAll: Scalars['Boolean'];
};


export type MutationActivateEmployeesInBulkArgs = {
  accountID: Scalars['String'];
};


export type MutationDelegationInsertArgs = {
  DelegationInput: Array<DelegationInput>;
};


export type MutationDelegationUpdateArgs = {
  DelegationInput: Array<DelegationInput>;
  BatchOvertimeDelegation?: Maybe<Array<BatchOvertimeDelegation>>;
  PerformanceManagement?: Maybe<Array<PerformanceManagementDelegation>>;
  TimeAttendance?: Maybe<Array<TimeAttendanceDelegation>>;
  LeaveManagement?: Maybe<Array<LeaveManagementDelegation>>;
  RequisitionManagement?: Maybe<Array<RequisitionManagementDelegation>>;
  ProjectExpenseManagement?: Maybe<Array<ProjectExpenseManagementDelegation>>;
  ClaimManagement?: Maybe<Array<ClaimManagementDelegation>>;
  PersonnelManagement?: Maybe<Array<PersonnelManagementDelegation>>;
};


export type MutationDelegationDeleteArgs = {
  DelegationID: Scalars['String'];
};


export type MutationNewsDeleteArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
};


export type MutationNewsInsertArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentsInput: DocumentsInput;
  NewsInput: NewsInput;
};


export type MutationNewsUpdateArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentID: Scalars['String'];
  NewsID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  NewsInput: NewsInput;
};


export type MutationNewsPublishArgs = {
  PostStatus: PostStatus;
  NewsID: Scalars['String'];
};


export type MutationNewsDuplicateArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  NewsID: Scalars['String'];
};


export type MutationNewDocumentNumberingInsertArgs = {
  input: NewDocumentNumberingInsertInput;
};


export type MutationCompanyDocumentNumberingUpdateArgs = {
  input: NewDocumentNumberingInsertInput;
};


export type MutationDocumentNumberingInsertArgs = {
  input: DocumentNumberingInput;
};


export type MutationDocumentNumberingUpdateArgs = {
  input: DocumentNumberingInput;
};


export type MutationDocumentNumberingDeleteArgs = {
  DocumentNumberHeaderID: Scalars['String'];
};


export type MutationDocumentNumberingUpdateStatusArgs = {
  DocStatus: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
};


export type MutationDocumentNumberAssignmentArgs = {
  input: Array<DocumentNumberAssignmentInput>;
};


export type MutationBatchEntryByShiftInsertArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  input: DutyRosterInput;
};


export type MutationUpdateBatchEntryByShiftPatternArgs = {
  BatchEntryByShiftPatternID: Scalars['String'];
  BatchEntryByShiftPatternInput: BatchEntryByShiftPatternInput;
};


export type MutationCreateEmployeeAppraisalArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeAppraisalInput: EmployeeAppraisalInput;
};


export type MutationUpdateEmployeeAppraisalArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeAppraisalID: Scalars['String'];
  EmployeeAppraisalInput: EmployeeAppraisalInput;
};


export type MutationDeleteEmployeeAppraisalArgs = {
  EmployeeAppraisalID: Scalars['String'];
};


export type MutationCareerLogInsertArgs = {
  DocumentsInput: DocumentsInput;
  CareerLogInput: CareerLogInput;
};


export type MutationCareerLogUpdateArgs = {
  DocumentID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  CareerLogInput: CareerLogInput;
};


export type MutationCreateEmployeeDiaryArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeDiaryInput: EmployeeDiaryInput;
};


export type MutationUpdateEmployeeDiaryArgs = {
  DocumentID: Scalars['String'];
  EmployeeDiaryID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  EmployeeDiaryInput: EmployeeDiaryInput;
};


export type MutationDeleteEmployeeDiaryArgs = {
  EmployeeDiaryID: Scalars['String'];
};


export type MutationEmployeeInfoInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeInput: EmployeeInput;
  ContactInput: ContactInput;
};


export type MutationEmployeeProfilePicUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeID: Scalars['String'];
};


export type MutationEmployeeInfoUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeInput: EmployeeInput;
  ContactInput: ContactInput;
};


export type MutationEmployeeCareerLogUpdateArgs = {
  EmployeeInput: EmployeeInput;
};


export type MutationEmployeeStatutoryInsertArgs = {
  EmployeeStatutoryInput: EmployeeStatutoryInput;
  EmployeeBankInfoInput: EmployeeBankInfoInput;
};


export type MutationEmployeeStatutoryUpdateArgs = {
  EmployeeStatutoryInput: EmployeeStatutoryInput;
  EmployeeBankInfoInput: EmployeeBankInfoInput;
};


export type MutationEmployeePrevContributionInsertArgs = {
  EmployeePrevContributionInput: EmployeePrevContributionInput;
};


export type MutationEmployeePrevContributionUpdateArgs = {
  EmployeePrevContributionInput: EmployeePrevContributionInput;
};


export type MutationEmployeeDependentInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
};


export type MutationEmployeeDependentUpdateArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
};


export type MutationEmployeeDependentInsertByEmployeeArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
  SaveAction: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type MutationEmployeeDependentUpdateByEmployeeArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
  SaveAction: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type MutationEmployeeTaxReliefBulkInsertArgs = {
  inputs: Array<EmployeeTaxReliefInput>;
};


export type MutationEmployeeTaxReliefBulkUpdateArgs = {
  inputs: Array<EmployeeTaxReliefInput>;
};


export type MutationEmployeeAssetInsertArgs = {
  EmployeeAssetInput: EmployeeAssetInput;
};


export type MutationEmployeeAssetUpdateArgs = {
  EmployeeAssetInput: EmployeeAssetInput;
  EmployeeAssetID: Scalars['String'];
};


export type MutationEmployeeAssetDeleteArgs = {
  EmployeeAssetID: Scalars['String'];
};


export type MutationEmployeeBenefitInKindInsertArgs = {
  EmployeeBenefitInKindInput: EmployeeBenefitInKindInput;
};


export type MutationEmployeeBenefitInKindUpdateArgs = {
  EmployeeBenefitInKindInput: EmployeeBenefitInKindInput;
  EmployeeBenefitInKindID: Scalars['String'];
};


export type MutationEmployeeBenefitInKindDeleteArgs = {
  EmployeeBenefitInKindID: Scalars['String'];
};


export type MutationEmployeeDependentDeleteArgs = {
  EmployeeDependentsID: Scalars['String'];
};


export type MutationEmployeeSalaryInsertArgs = {
  Input: CareerLogInput;
};


export type MutationEmployeeSalaryUpdateArgs = {
  Input: CareerLogInput;
  CareerLogID: Scalars['String'];
};


export type MutationEmployeeSalaryDeleteArgs = {
  CareerLogID: Scalars['String'];
};


export type MutationEmployeeProfileUploadArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeID: Scalars['String'];
  DocumentID: Scalars['String'];
  EmployeeInput: EmployeeInput;
};


export type MutationUpdateEmployeeLastLoginCompanyArgs = {
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  LastestAccessedEntity: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type MutationEmployeeCp22UpdateArgs = {
  Input: Array<EmployeeCp22Input>;
};


export type MutationCreateEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
};


export type MutationUpdateEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID: Scalars['String'];
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
};


export type MutationDeleteEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID: Scalars['String'];
};


export type MutationUpdateNotificationReadStatusArgs = {
  NotificationID: Scalars['String'];
};


export type MutationUpdateAllNotificationReadStatusArgs = {
  EmployeeID: Scalars['String'];
};


export type MutationUpdateBulkNotificationReadStatusArgs = {
  IDs: Array<Scalars['String']>;
};


export type MutationNotificationReadStatusDeleteArgs = {
  ID: Array<Scalars['String']>;
};


export type MutationCreateEmployeeTrainingArgs = {
  EmployeeTrainingInput: EmployeeTrainingInput;
};


export type MutationUpdateEmployeeTrainingArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeTrainingInput: EmployeeTrainingInput;
  EmployeeTrainingID: Scalars['String'];
};


export type MutationDeleteEmployeeTrainingArgs = {
  EmployeeTrainingID: Scalars['String'];
};


export type MutationEmployeeUserDefineFieldsInsertArgs = {
  Inputs: Array<EmployeeUserDefineFieldsInput>;
};


export type MutationEmployeeUserDefineFieldsUpdateArgs = {
  Inputs: Array<EmployeeUserDefineFieldsInput>;
};


export type MutationEmployeeUserDefineFieldsDetailInsertArgs = {
  Inputs: Array<EmployeeUserDefineFieldsDetailInput>;
};


export type MutationEmployeeUserDefineFieldsDetailUpdateArgs = {
  Inputs: Array<EmployeeUserDefineFieldsDetailInput>;
};


export type MutationZakatContributionInsertArgs = {
  ZakatContributionInput: ZakatContributionInput;
};


export type MutationZakatContributionUpdateArgs = {
  input: ZakatContributionInput;
  ID: Scalars['String'];
};


export type MutationZakatContributionDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUpdateAppSecurityArgs = {
  permissionArr?: Maybe<Array<HrPermission>>;
  input: EmployeeAppSecurityInput;
};


export type MutationCreateUpdateAppSecurityAssignmentArgs = {
  input: EmployeeAppSecurityAssignmentInput;
};


export type MutationDownloadDocumentsArgs = {
  File: Scalars['String'];
};


export type MutationRemoveEonDocumentsByBatchArgs = {
  BatchDateTime: Scalars['String'];
  Type: Scalars['String'];
};


export type MutationHelpdeskSubjectSubmitArgs = {
  input: HelpdeskSubjectInput;
};


export type MutationHelpdeskSubjectActionArgs = {
  Action: Scalars['String'];
  SubjectID: Scalars['String'];
};


export type MutationSubmitHelpdeskTicketArgs = {
  mode: Scalars['String'];
  input: HelpdeskTicketInput;
  CompanyID: Scalars['String'];
};


export type MutationReassignAssigneeArgs = {
  Assignee: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  HelpdeskTicketID: Scalars['String'];
};


export type MutationUpdateOpenDtArgs = {
  HelpdeskTicketID: Scalars['String'];
};


export type MutationWithdrawOpenTicketArgs = {
  HelpdeskTicketID: Scalars['String'];
};


export type MutationAttendanceClockingArgs = {
  EmployeeCheckIn: Array<EmployeeCheckIn>;
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGetUserInfoArgs = {
  FromDate: Scalars['DateTime'];
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGetStaffAttendanceArgs = {
  ShiftDate: Scalars['DateTime'];
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGetEmpWorkTimeArgs = {
  Refrence: Array<Scalars['JSON']>;
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationBlockLeaveInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  Input: Array<BlockLeaveInput>;
};


export type MutationBlockLeaveUpdateArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
  IsUpdateAttachment: Scalars['Boolean'];
  Input: Array<BlockLeaveInput>;
};


export type MutationBlockLeaveStatusUpdateArgs = {
  Input: BlockLeaveInput;
};


export type MutationReprocessLeaveEntitlementArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  LeaveYear: Scalars['Float'];
};


export type MutationReprocessLeaveEntitlementRedirectArgs = {
  UserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  LeaveYear: Scalars['Float'];
};


export type MutationReprocessLeaveEntitlementBySubscriptionArgs = {
  LeaveYear: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationReprocessLeaveEntitlementBySubscriptionRedirectArgs = {
  LeaveYear: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationPostCarryForwardEncashmentArgs = {
  input: Array<EncashCarryForwardLeaveInput>;
};


export type MutationLeaveRecordsApprovalArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: Array<LeaveApprovalUpdateInput>;
};


export type MutationLeaveRecordsInsertArgs = {
  LeavePolicyID?: Maybe<Scalars['String']>;
  leaveDetailsInput: Array<LeaveDetailsInput>;
  leaveHeaderInput: LeaveHeaderInput;
};


export type MutationLeaveRecordsUpdateArgs = {
  LeavePolicyID?: Maybe<Scalars['String']>;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  isRemovedAttachment?: Maybe<Scalars['Boolean']>;
  leaveDetailsInput: Array<LeaveDetailsInput>;
  leaveHeaderInput: LeaveHeaderInput;
  leaveHeaderID: Scalars['String'];
};


export type MutationLeaveAdjustmentInsertArgs = {
  input: Array<LeaveHeaderInput>;
};


export type MutationLeaveStatusUpdateArgs = {
  ApprovalStatus: ApprovalStatus;
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationLeaveAdjustmentUpdateArgs = {
  input: LeaveHeaderInput;
  LeaveHeaderID: Scalars['String'];
};


export type MutationLeaveAdjustmentDeleteArgs = {
  input: LeaveHeaderInput;
  LeaveHeaderID: Scalars['String'];
};


export type MutationPostLeaveRecordsArgs = {
  input: Array<LeavePostingInput>;
};


export type MutationRevertLeaveRecordsArgs = {
  input: Array<LeavePostingInput>;
};


export type MutationSaveCompulsoryLeaveArgs = {
  LeaveCompulsoryEmployeeInputs: Array<LeaveCompulsoryEmployeeInput>;
  LeaveCompulsoryInput: LeaveCompulsoryInput;
};


export type MutationSaveUpdateCompulsoryLeaveArgs = {
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  LeaveCompulsoryEmployeeInputs: Array<LeaveCompulsoryEmployeeInput>;
  LeaveCompulsoryInput: LeaveCompulsoryInput;
};


export type MutationDeleteCompulsoryLeaveArgs = {
  LeaveCompulsoryID: Scalars['String'];
};


export type MutationInsertLeaveCutOffPeriodsArgs = {
  input: GenerateLeaveCutOffPeriodInput;
};


export type MutationSubmitYearEndCarryForwardArgs = {
  leaveHeaderInput: Array<LeaveHeaderInput>;
};


export type MutationReversePostedYecfRecordsArgs = {
  Year: Scalars['Float'];
  Input: Array<ReversePostedYecfInput>;
};


export type MutationReversePostedLcfeArgs = {
  Year: Scalars['Float'];
  Inputs: Array<ReversePostedLcfeInput>;
};


export type MutationLeaveTakenUploadArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationLeaveTakenDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationLeaveTakenInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationLeaveTakenBulkInsertArgs = {
  LeaveHeaderInput: Array<LeaveHeaderInput>;
};


export type MutationPostMaxLeaveBalanceForfeitureArgs = {
  LeaveHeaderInputs: Array<LeaveHeaderInput>;
};


export type MutationReverseMaxLeaveBalanceForfeitureArgs = {
  LeaveHeaderIDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationLeaveApprovalPolicyInsertArgs = {
  input: Leave_Approval_PolicyInput;
};


export type MutationLeaveApprovalPolicyDeleteArgs = {
  LeaveApprovalPolicyID: Scalars['String'];
};


export type MutationLeaveApprovalSequenceUpdateArgs = {
  LeaveApprovalPolicyIDs: Array<Scalars['String']>;
};


export type MutationLeaveOutOfStateInsertArgs = {
  input: LeaveOutOfStateInput;
};


export type MutationLeaveOufOfStateUpdateArgs = {
  input: LeaveOutOfStateInput;
};


export type MutationLeaveOutOfStateDeleteArgs = {
  LeaveOutOfStateID: Scalars['String'];
};


export type MutationAccountMappingInsertArgs = {
  DetailInput: Array<GlAccountDetailInput>;
  HeaderInput: GlAccountHeaderInput;
};


export type MutationAccountMappingUpdateArgs = {
  DetailInput: Array<GlAccountDetailInput>;
  HeaderInput: GlAccountHeaderInput;
};


export type MutationAccountMappingDeleteArgs = {
  GLAccountHeaderID: Scalars['String'];
};


export type MutationAccountMappingAssignmentInsertArgs = {
  HeaderInput: GlAccountAssignmentInput;
};


export type MutationAccountMappingAssignmentUpdateArgs = {
  HeaderInput: GlAccountAssignmentInput;
};


export type MutationClaimAccountMappingInsertArgs = {
  DetailInput: Array<ClaimAccountDetailInput>;
  HeaderInput: ClaimAccountHeaderInput;
};


export type MutationClaimAccountMappingUpdateArgs = {
  DetailInput: Array<ClaimAccountDetailInput>;
  HeaderInput: ClaimAccountHeaderInput;
};


export type MutationClaimAccountMappingDeleteArgs = {
  ClaimAccountHeaderID: Scalars['String'];
};


export type MutationClaimAccountAssignmentInsertArgs = {
  HeaderInput: ClaimAccountAssignmentInput;
};


export type MutationClaimAccountAssignmentUpdateArgs = {
  HeaderInput: ClaimAccountAssignmentInput;
};


export type MutationCostMappingUpdateArgs = {
  input: Array<CostCenterMappingInput>;
};


export type MutationDeleteProcessedGlArgs = {
  Type?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationFlagGlDataArgs = {
  AccountID: Scalars['String'];
  RequestKey: Scalars['String'];
  BatchNo: Scalars['String'];
  TransactionType: Scalars['String'];
  EntityCode: Scalars['String'];
};


export type MutationUnflagGlDataArgs = {
  AccountID: Scalars['String'];
  RequestKey: Scalars['String'];
  BatchNo: Scalars['String'];
  TransactionType: Scalars['String'];
  EntityCode: Scalars['String'];
};


export type MutationCreateMobileVersionArgs = {
  Android: Scalars['Boolean'];
  iOS: Scalars['Boolean'];
  Version: Scalars['String'];
};


export type MutationMobileVersionDeletingArgs = {
  MobileVersionID: Scalars['String'];
};


export type MutationMultiCurrencyUpdateStatusArgs = {
  Status: Scalars['String'];
  MultiCurrencyID: Scalars['String'];
};


export type MutationMultiCurrencyUpdateDeleteArgs = {
  Status: Scalars['String'];
  MultiCurrencyID: Scalars['String'];
};


export type MutationMultiCurrencyInsertArgs = {
  MultiCurrencyDetailsInput?: Maybe<Array<MultiCurrencyDetailsInput>>;
  MultiCurrencySetupInput?: Maybe<Array<MultiCurrencySetupInput>>;
  SubscriptionAccountID: Scalars['String'];
};


export type MutationMultiCurrencyUpdateArgs = {
  MultiCurrencyDetailsInput?: Maybe<Array<MultiCurrencyDetailsInput>>;
  MultiCurrencySetupInput?: Maybe<Array<MultiCurrencySetupInput>>;
  SubscriptionAccountID: Scalars['String'];
};


export type MutationMultiCurrencySetupExcelUploadArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationMultiCurrencyExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationMultiCurrencyInsertActualArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationSubmitOnBehalfPolicyByTypeArgs = {
  input: Array<OnBehalfPolicyInput>;
  UserLogs: Array<UserLogInput>;
  ModuleType: RequestType;
  CompanyID: Scalars['String'];
};


export type MutationPopulateAttendanceRecordArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type MutationOvertimeRoundingInsertArgs = {
  input: OvertimeRoundingSettingInput;
};


export type MutationOvertimeRoundingSettingUpdateArgs = {
  input: OvertimeRoundingSettingInput;
};


export type MutationOvertimeRoundingSettingDeleteArgs = {
  OvertimeRoundingSettingID: Scalars['String'];
};


export type MutationOvertimeTemplateInsertArgs = {
  input: OvertimeTemplateInput;
};


export type MutationOvertimeTemplateUpdateArgs = {
  input: OvertimeTemplateInput;
};


export type MutationOvertimeTemplateDeleteArgs = {
  OvertimeTemplateID: Scalars['String'];
};


export type MutationSubmitAdvancePolicyArgs = {
  input: AdvancePolicyInput;
};


export type MutationDeleteAdvancePolicyArgs = {
  AdvancePolicyID: Scalars['String'];
};


export type MutationAssignAdvancePolicyArgs = {
  AdvancePolicyIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationGenerateAdvanceProcessingArgs = {
  PayPeriodID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationPostUnpostAdvanceProcessingArgs = {
  IsPosting: Scalars['Boolean'];
  AdvanceProcessingIDs: Array<Scalars['String']>;
};


export type MutationSubmitPayGroupArgs = {
  input: PayGroupInput;
};


export type MutationPayGroupDeleteArgs = {
  PayGroupID: Scalars['String'];
};


export type MutationAssignCompanyPayGroupArgs = {
  input: PayGroupAssignmentInput;
};


export type MutationUpdateStatutoryAdjustmentArgs = {
  PayrollDetailInputs?: Maybe<Array<Scalars['JSON']>>;
  PayrollHeaderInput: Scalars['JSON'];
  StatutoryInput: StatutoryAdjustmentInput;
};


export type MutationDeleteStatutoryAdjustmentArgs = {
  PayrollHeaderID: Scalars['String'];
};


export type MutationDeletePayrollHistoryArgs = {
  PayrollHeaderID: Scalars['String'];
};


export type MutationDeletePayrollProcessArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationUpdatePayrollCalendarArgs = {
  DeferredPayCycles?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  companyID: Scalars['String'];
};


export type MutationGenerateDefaultOrderforAllPayPeriodsArgs = {
  PayPeriodIDs: Array<Scalars['String']>;
  companyID: Scalars['String'];
};


export type MutationUpdatePayrollProcessStatusArgs = {
  ModifiedBy: Scalars['String'];
  CreatedBy: Scalars['String'];
  PayrollCycleStatus: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationUpdateMonthlyTransactionByFixedAllowanceArgs = {
  PayrollCycleID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationInsertPayPeriodListingArgs = {
  input: PayPeriodInput;
};


export type MutationInsertSubsequentPayPeriodListingArgs = {
  input: PayPeriodInput;
};


export type MutationPayItemInsertArgs = {
  condition?: Maybe<PayItemConditionInput>;
  input: PayItemInput;
};


export type MutationPayItemUpdateArgs = {
  Condition?: Maybe<PayItemConditionInput>;
  input: PayItemInput;
  PayItemID: Scalars['String'];
};


export type MutationPayItemConditionTemplateLibraryUpdateArgs = {
  Condition?: Maybe<PayItemConditionInput>;
};


export type MutationSalaryBatchArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  Input: Array<CareerLogInput>;
};


export type MutationPayItemDeleteArgs = {
  PayItemID: Scalars['String'];
};


export type MutationRecurringPayInsertArgs = {
  ExcelUpload: Scalars['Boolean'];
  DocumentsInput?: Maybe<DocumentsInput>;
  RecurringPayInput: Array<RecurringPayInput>;
};


export type MutationRecurringPayUpdateArgs = {
  input: RecurringPayInput;
  RecurringPayID: Scalars['String'];
};


export type MutationRecurringPayDeleteArgs = {
  RecurringPayID: Scalars['String'];
};


export type MutationCp159UpdateArgs = {
  CP159Input: Cp159Input;
};


export type MutationOneTimePayDeleteArgs = {
  OneTimePayID: Scalars['String'];
};


export type MutationOneTimePayInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  OneTimePayInput: Array<OneTimePayInput>;
};


export type MutationOneTimePayUpdateArgs = {
  OneTimePayInput: Array<OneTimePayInput>;
};


export type MutationPublishPaySlipArgs = {
  PublishPaySlipID: Scalars['String'];
};


export type MutationPublishEaArgs = {
  PublishEAID: Scalars['String'];
};


export type MutationUnPublishEaArgs = {
  PublishEAID: Scalars['String'];
};


export type MutationDeleteMonthlyTrxArgs = {
  OneTimePayIDs: Array<Scalars['String']>;
};


export type MutationFreezePayrollInsertArgs = {
  input: FreezePayrollInput;
};


export type MutationEmployeeFreezePayrollDeleteArgs = {
  FreezePayrollID: Scalars['String'];
};


export type MutationEmployeeSalaryExcelUploadArgs = {
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
  File: Scalars['Upload'];
};


export type MutationSalaryExcelDeleteArgs = {
  FileName: Scalars['String'];
};


export type MutationSalaryExcelInsertActualArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  CompanyID: Scalars['String'];
  FileName: Scalars['String'];
};


export type MutationSubmitEmployeeKpiArgs = {
  Comment?: Maybe<Scalars['String']>;
  IsSubmitByApprover?: Maybe<Scalars['Boolean']>;
  input: EmployeeKpiInput;
};


export type MutationSaveEmployeeKpiInRepoArgs = {
  input: EmployeeKpiRepositoryInput;
};


export type MutationEmployeeKpiRepoDeleteArgs = {
  EmployeeKpiRepositoryID: Scalars['String'];
};


export type MutationKpiExerciseChangeSuperiorArgs = {
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseChangeStatusArgs = {
  Comment?: Maybe<Scalars['String']>;
  StatusToChange: Status;
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseSubmitArgs = {
  input: KpiExerciseDetailsEditInput;
};


export type MutationKpiExerciseDeleteArgs = {
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseInitiateDraftArgs = {
  KpiExerciseIDs: Array<Scalars['String']>;
};


export type MutationKpiExerciseCopyArgs = {
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
};


export type MutationKpiExerciseSetupSubmitArgs = {
  input: KpiExerciseSetupInput;
};


export type MutationKpiExerciseSetupCopyArgs = {
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  KpiExerciseID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
};


export type MutationKpiExerciseSetupDeleteArgs = {
  KpiExerciseSetupID: Scalars['String'];
};


export type MutationKpiExerciseSetupGenerateArgs = {
  Overwrite?: Maybe<Scalars['Boolean']>;
  KpiExerciseSetupID: Scalars['String'];
};


export type MutationOrganizationalGoalSubmitArgs = {
  input: OrganizationalGoalInput;
};


export type MutationOrganizationalGoalDeleteArgs = {
  OrganizationalGoalID: Scalars['String'];
};


export type MutationOrgKpiPlanningSubmitArgs = {
  input: OrgKpiPlanningInput;
};


export type MutationOrgKpiPlanningDetailSubmitArgs = {
  input: OrgKpiPlanningDetailsInput;
};


export type MutationOrgKpiPlanningDetailsDeleteArgs = {
  OrgKpiPlanningDetailsID: Scalars['String'];
};


export type MutationPerformanceGroupMatrixSubmitArgs = {
  input: PerformanceGroupMatrixInput;
};


export type MutationPerformanceGroupMatrixDeleteArgs = {
  PerformanceGroupMatrixID: Scalars['String'];
};


export type MutationEmployeeReviewUpdateSetupArgs = {
  input: EmployeeReviewSetupInput;
};


export type MutationCopyEmployeeReviewExerciseArgs = {
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ReviewerID: Scalars['String'];
  EmployeeReviewID: Scalars['String'];
};


export type MutationEmployeeReviewFormStatusUpdateArgs = {
  IsSendBack?: Maybe<Scalars['Boolean']>;
  Comment?: Maybe<Scalars['String']>;
  StatusToChange: Status;
  EmployeeReviewIDs: Array<Scalars['String']>;
};


export type MutationSubmitEmployeeReviewFormArgs = {
  Comment?: Maybe<Scalars['String']>;
  input: ReviewFormSubmitInput;
};


export type MutationAddConversationLogArgs = {
  input: ReviewConversationLogInput;
};


export type MutationPeerRegistrationSubmitArgs = {
  input?: Maybe<Array<ReviewPeerRegistrationInput>>;
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationReviewExerciseSetupSubmitArgs = {
  input: ReviewExerciseSetupInput;
};


export type MutationReviewExerciseSetupCopyArgs = {
  KpiAsAtDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ReviewExerciseID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationReviewExerciseSetupDeleteArgs = {
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationReviewExerciseSetupGenerateArgs = {
  Overwrite?: Maybe<Scalars['Boolean']>;
  ReviewExerciseSetupID: Scalars['String'];
};


export type MutationPeriodicAllowanceTypeInputArgs = {
  input: PeriodicAllowanceTypeInput;
};


export type MutationPeriodicAllowanceTypeUpdateArgs = {
  input: PeriodicAllowanceTypeInput;
};


export type MutationPeriodicAllowanceTypeDeleteArgs = {
  PeriodicAllowanceTypeID: Scalars['String'];
};


export type MutationPersonnelCertificateInsertArgs = {
  Input: PersonnelCertificateInput;
};


export type MutationPersonnelCertificateUpdateArgs = {
  Input: PersonnelCertificateInput;
  PersonnelCertificateID: Scalars['String'];
};


export type MutationPersonnelCertificateDeleteArgs = {
  PersonnelCertificateID: Scalars['String'];
};


export type MutationContractInfoInsertArgs = {
  ContractInfoInput: ContractInfoInput;
};


export type MutationContractInfoUpdateArgs = {
  IsRemovedAttachment?: Maybe<Scalars['Boolean']>;
  ContractInfoInput: ContractInfoInput;
  ContractInfoID: Scalars['String'];
};


export type MutationContractInfoEndArgs = {
  CurrentContractEndRemark: Scalars['String'];
  CurrentContractEndDate: Scalars['DateTime'];
  ContractInfoID: Scalars['String'];
};


export type MutationContractInfoRenewalArgs = {
  ContractInfoInput: ContractInfoInput;
  CurrentContractEndRemark: Scalars['String'];
  CurrentContractEndDate: Scalars['DateTime'];
  ContractInfoID: Scalars['String'];
};


export type MutationReportDocDeleteArgs = {
  DocumentID: Scalars['String'];
};


export type MutationContractInfoDeleteArgs = {
  ContractInfoID: Scalars['String'];
};


export type MutationPersonnelEducationInsertArgs = {
  PersonnelEducationInsertInput: PersonnelEducationInsertInput;
};


export type MutationPersonnelEducationUpdateArgs = {
  PersonnelEducationInsertInput: PersonnelEducationInsertInput;
  PersonnelEducationID: Scalars['String'];
};


export type MutationPersonnelEducationDeleteArgs = {
  PersonnelEducationID: Scalars['String'];
};


export type MutationPersonnelHealthInfoInsertArgs = {
  MedicalRecordInput: Array<MedicalRecordInput>;
  VaccinationRecordInput?: Maybe<Array<VaccinationRecordInput>>;
  PersonnelHealthInfoInput: PersonnelHealthInfoInput;
};


export type MutationPersonnelHealthInfoUpdateArgs = {
  AttachmentToRemove?: Maybe<Array<DocumentsInput>>;
  MedicalRecordInput: Array<MedicalRecordInput>;
  VaccinationRecordInput?: Maybe<Array<VaccinationRecordInput>>;
  PersonnelHealthInfoInput: PersonnelHealthInfoInput;
  PersonnelHealthInfoID: Scalars['String'];
};


export type MutationMedicalRecordDeleteArgs = {
  MedicalRecordID: Scalars['String'];
};


export type MutationPersonnelJobHistoryInsertArgs = {
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryUpdateArgs = {
  PersonnelJobHistoryID: Scalars['String'];
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryInsertByEmployeeArgs = {
  SaveAction: ApprovalStatus;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryUpdateByEmployeeArgs = {
  PersonnelJobHistoryID: Scalars['String'];
  SaveAction: ApprovalStatus;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Input: PersonnelJobHistoryInput;
};


export type MutationPersonnelJobHistoryDeleteArgs = {
  PersonnelJobHistoryID: Scalars['String'];
};


export type MutationPersonnelReferenceInsertArgs = {
  Input: PersonnelReferenceInput;
};


export type MutationPersonnelReferenceUpdateArgs = {
  Input: PersonnelReferenceInput;
  PersonnelReferenceID: Scalars['String'];
};


export type MutationPersonnelReferenceDeleteArgs = {
  PersonnelReferenceID: Scalars['String'];
};


export type MutationUserDefineDetailBulkInsertArgs = {
  UserDefineDetailInput: Array<UserDefineDetailInput>;
};


export type MutationPersonnelInsuranceSchemeBulkInsertArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  PersonnelIDs: Array<Scalars['String']>;
  InsuranceSchemeID: Scalars['String'];
};


export type MutationPersonnelInsuranceSchemeUpdateArgs = {
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
  EmployeeInsuranceSchemeID: Scalars['String'];
};


export type MutationStaffConfirmationInsertArgs = {
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationStaffConfirmationUpdateArgs = {
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffConfirmationCancelConfirmedArgs = {
  CareerLogID: Scalars['String'];
};


export type MutationPersonnelStaffExitCompanyInsertArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationPersonnelStaffExitCompanyUpdateArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffExitCompanyCancelArgs = {
  CancellationRemarks?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
};


export type MutationProcessStaffMovementArgs = {
  DateToProcess: Scalars['DateTime'];
};


export type MutationStaffPromotionDemotionInsertArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationStaffPromotionDemotionUpdateArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffPromotionDemotionCancelArgs = {
  CancellationRemarks?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
};


export type MutationStaffTransferInsertArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
};


export type MutationStaffTransferUpdateArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
};


export type MutationStaffTransferCancelArgs = {
  CancellationRemarks?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
};


export type MutationDeleteProjectExpenseClaimRecordsArgs = {
  ProjectExpenseClaimHeaderIDs: Array<Scalars['String']>;
  SubscriptionUserID: Scalars['String'];
};


export type MutationSubmitProjectExpenseClaimForApprovalArgs = {
  ProjectExpenseClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationSubmitMyProjectClaimArgs = {
  input: SubmitProjectClaimRecordsInput;
};


export type MutationApproveProjectExpenseClaimRecordsArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: ApproveProjectExpenseClaimRecordsInput;
};


export type MutationDeleteApprovedProjectExpenseClaimRecordsArgs = {
  Remark: Scalars['String'];
  input: ApproveProjectExpenseClaimRecordsInput;
};


export type MutationPostPeClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  input: PostPeClaimRecordsInput;
};


export type MutationReversePeClaimRecordsArgs = {
  PaymentMethod: Scalars['String'];
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationVerifyApprovedProjectExpenseClaimDetailArgs = {
  input: VerifyApprovedProjectExpenseClaimDetailInput;
};


export type MutationReverseApprovedProjectExpenseClaimRecordsArgs = {
  input: ApproveProjectExpenseClaimRecordsInput;
};


export type MutationProjectExpenseTypeSubmitArgs = {
  input: ProjectExpenseTypeInput;
};


export type MutationProjectExpenseTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationProjectGlAccountMappingSubmitArgs = {
  DetailInput: Array<ProjectGlAccountMappingDetailInput>;
  HeaderInput: ProjectGlAccountMappingInput;
};


export type MutationProjectGlAccountMappingDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationProjectAccountAssignmentInsertArgs = {
  HeaderInput: ProjectAccountAssignmentInput;
};


export type MutationProjectAccountAssignmentUpdateArgs = {
  HeaderInput: ProjectAccountAssignmentInput;
};


export type MutationDeleteClaimProcessedGlArgs = {
  Type?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PeriodID: Scalars['String'];
};


export type MutationProjectMasterSubmitArgs = {
  input: ProjectMasterInput;
};


export type MutationProjectMasterDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationProjectCostAllocationSubmitArgs = {
  ProjectCostAllocationDetailInput: Array<ProjectCostAllocationDetailInput>;
  ProjectCostAllocationInput: ProjectCostAllocationInput;
};


export type MutationProjectCostAllocationDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitApproverMasterArgs = {
  accountName: Scalars['String'];
  mode: Scalars['String'];
  input: ProjectTimesheetApproverMasterInput;
};


export type MutationResendConfirmationEmailArgs = {
  accountName: Scalars['String'];
  ProjectTimesheetApproverMasterID: Scalars['String'];
};


export type MutationApproverConfirmedEmailActionArgs = {
  token: Scalars['String'];
};


export type MutationDeleteApproverMasterArgs = {
  ProjectTimesheetApproverMasterID: Scalars['String'];
};


export type MutationDeleteApproverEmployeeListingArgs = {
  ProjectTimesheetPeriodID: Scalars['String'];
};


export type MutationSubmitApproverAssignmentArgs = {
  selectAllEmployee: Scalars['Boolean'];
  mode: Scalars['String'];
  input: ProjectTimesheetApproverAssignmentInput;
};


export type MutationSubmitDailyEntryArgs = {
  input: ProjectTimesheetDailyEntryInput;
  ContactID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  mode: Scalars['String'];
};


export type MutationDeleteDailyEntryArgs = {
  ProjectTimesheetDailyEntryID: Scalars['String'];
};


export type MutationSubmitProjectTimesheetArgs = {
  input: Array<ProjectTimesheetDailyEntryInput>;
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
};


export type MutationSubmitProjectTimesheetApprovalArgs = {
  Remarks?: Maybe<Scalars['String']>;
  StatusToChange: WorkflowLogStatus;
  token: Scalars['String'];
};


export type MutationProjectTimesheetReportForApproverSendEmailArgs = {
  input: ProjectTimesheetReportForApproverInput;
  ReportURL?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
};


export type MutationRecruitmentExpensesInsertArgs = {
  input: Array<RecruitmentExpensesInput>;
};


export type MutationRecruitmentExpensesDeleteArgs = {
  recruitment_expense_ids: Array<Scalars['String']>;
};


export type MutationELetterTemplateInsertArgs = {
  input: RecruitmentE_LetterInput;
};


export type MutationELetterTemplateUpdateArgs = {
  IsRemovedAttachment?: Maybe<Scalars['Boolean']>;
  ELetterTemplateID: Scalars['String'];
  input: RecruitmentE_LetterInput;
};


export type MutationELetterTemplateUpdateDefaultArgs = {
  PreofferChecklistID: Scalars['String'];
  ELetterTemplateID: Scalars['String'];
};


export type MutationELetterTemplateDeleteArgs = {
  ELetterTemplateID: Scalars['String'];
};


export type MutationRec_EApplicationFormSetupInsertArgs = {
  input: Array<Rec_EApplicationFormSetupInput>;
};


export type MutationPreOfferChecklistInsertArgs = {
  input: RecruitmentPre_OfferChecklistInput;
};


export type MutationPreOfferChecklistUpdateArgs = {
  input: Array<RecruitmentPre_OfferChecklistInput>;
};


export type MutationPreOfferChecklistSequenceUpdateArgs = {
  PreOfferChecklistIDs: Array<Scalars['String']>;
};


export type MutationPreOfferChecklistDeleteArgs = {
  PreOfferChecklistID: Scalars['String'];
};


export type MutationTalentPoolAdditionalUserInsertArgs = {
  input: Array<Rec_TalentPoolUserFieldsInput>;
};


export type MutationDeclineReasonInsertArgs = {
  input: Array<Rec_DeclineReasonInput>;
};


export type MutationRec_PrehiringFormSetupInsertArgs = {
  input: Array<Rec_PrehiringFormSetupInput>;
};


export type MutationJobPortalSetupInsertArgs = {
  input: Rec_JobPortalSetupInput;
};


export type MutationJobPortalSetupUpdateArgs = {
  input: Rec_JobPortalSetupInput;
  JobPortalID: Scalars['String'];
};


export type MutationJobPortalSetupDeleteArgs = {
  JobPortalID: Scalars['String'];
};


export type MutationJobPortalTalentInsertArgs = {
  JobPostingID: Scalars['String'];
  portalToken: Scalars['String'];
  input: ContactInput;
};


export type MutationEApplicationInsertArgs = {
  JobPostingID?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  documentsInput: Array<DocumentsInput>;
  referenceInput: Array<PersonnelReferenceInput>;
  languageInput: Array<PersonnelLanguageInput>;
  empHistoryInput: Array<PersonnelJobHistoryInput>;
  membershipInput: Array<PersonnelMembershipInput>;
  educationInput: Array<PersonnelEducationInsertInput>;
  talentInput: Rec_TalentPoolInput;
  documentInput?: Maybe<DocumentsInput>;
  contactInput: ContactInput;
  userID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationDataUpdateEApplicationInsertArgs = {
  vaccinationRecordInput?: Maybe<Array<VaccinationRecordInput>>;
  medicalRecordInput: Array<MedicalRecordInput>;
  attachmentToRemove?: Maybe<Array<DocumentsInput>>;
  healthInput: PersonnelHealthInfoInput;
  statutoryInput?: Maybe<Rec_TalentStatutoryInput>;
  dependentInput: Array<EmployeeDependentsInput>;
  documentsInput: Array<DocumentsInput>;
  referenceInput: Array<PersonnelReferenceInput>;
  empHistoryInput: Array<PersonnelJobHistoryInput>;
  certificateInput: Array<PersonnelCertificateInput>;
  educationInput: Array<PersonnelEducationInsertInput>;
  employeeInput?: Maybe<EmployeeInput>;
  talentInput: Rec_TalentPoolInput;
  documentInput?: Maybe<DocumentsInput>;
  contactInput: ContactInput;
  userID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationSubmitMyRecruitmentRequisitionArgs = {
  input: Rec_RequisitionInput;
};


export type MutationUpdateMyRecRequisitionApprovalStatusArgs = {
  input: Rec_RequisitionInput;
};


export type MutationMyApprovedJobVacancyApplicantStatusUpdateArgs = {
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  talentStatusLog?: Maybe<TalentStatusLog>;
  applicantLog?: Maybe<ApplicantStatusLog>;
  ApplicantIDs: Array<Scalars['String']>;
};


export type MutationSubmitRecruitmentRequisitionArgs = {
  input: Rec_RequisitionInput;
};


export type MutationDeleteRecRequisitionArgs = {
  RecRequisitionID: Scalars['String'];
};


export type MutationUpdateRecRequisitionApprovalStatusArgs = {
  input: Rec_RequisitionInput;
};


export type MutationJobVacancyInsertArgs = {
  recExpenseInput?: Maybe<Array<Rec_JobVacancyExpenseInput>>;
  recSourceInput?: Maybe<Array<Rec_JobVacancySourceInput>>;
  jobVacancyInput: Rec_JobVacancyInput;
};


export type MutationJobVacancyStatusUpdateArgs = {
  input: JobVacancyStatusLog;
  JobVacancyID: Scalars['String'];
};


export type MutationSubmitInterviewScheduleArgs = {
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  input: Rec_InterviewScheduleInput;
};


export type MutationUpdateInterviewResultArgs = {
  isRemovedAttachment?: Maybe<Scalars['Boolean']>;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  input: Rec_InterviewerInput;
};


export type MutationCompleteInterviewArgs = {
  input: Rec_ApplicantInput;
};


export type MutationReopenInterviewArgs = {
  rec_applicant_id: Scalars['String'];
};


export type MutationDeleteInterviewScheduleArgs = {
  rec_interview_schedule_id: Scalars['String'];
};


export type MutationJobVacancyDeleteArgs = {
  JobVacancyID: Scalars['String'];
};


export type MutationJobPostingInsertArgs = {
  jobPostingInput: Rec_JobPostingInput;
};


export type MutationJobPostingStatusUpdateArgs = {
  input: Rec_JobPostingUpdateStatus;
  jobPostingId: Scalars['String'];
};


export type MutationJobPostingUpdateArgs = {
  jobPostingInput: Rec_JobPostingUpdate;
  jobPostingId: Scalars['String'];
};


export type MutationJobPostingDeleteArgs = {
  JobPostingID: Scalars['String'];
};


export type MutationApplicantStatusUpdateArgs = {
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  talentStatusLog?: Maybe<TalentStatusLog>;
  applicantLog?: Maybe<ApplicantStatusLog>;
  ApplicantIDs: Array<Scalars['String']>;
};


export type MutationFinalSelectionStatusUpdateArgs = {
  FinalSelectionStatus?: Maybe<RecruitmentFinalSelectionStatus>;
  applicantLog?: Maybe<ApplicantStatusLog>;
  ApplicantIDs: Array<Scalars['String']>;
};


export type MutationOfferProposalUpdateArgs = {
  isRemovedAttachment?: Maybe<Scalars['Boolean']>;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  input: Rec_OfferInput;
};


export type MutationOfferProposalInsertArgs = {
  input: Rec_OfferInput;
};


export type MutationOfferApprovalUpdateArgs = {
  input: Rec_OfferInput;
};


export type MutationOfferContentInsertArgs = {
  offerChecklistSnapshot?: Maybe<Array<OfferChecklistSnapshot>>;
  offerContentInput: Rec_OfferContentInput;
};


export type MutationFinalizeOfferArgs = {
  offerContentInput: Array<Rec_OfferContentInput>;
};


export type MutationUpdateOfferResponseArgs = {
  talentStatusLog?: Maybe<TalentStatusLog>;
  applicantLog?: Maybe<ApplicantStatusLog>;
  input: Rec_OfferInput;
};


export type MutationSendLetterArgs = {
  Email: Scalars['String'];
  FullName: Scalars['String'];
  OfferProcessID: Scalars['String'];
  OfferChecklistSnapshot: Array<OfferChecklistSnapshot>;
  OfferContent: Array<Rec_OfferContentInput>;
  sendLog: SendLog;
};


export type MutationInsertAcceptedPreHiringArgs = {
  hiringInput: AcceptedPreHiringInput;
};


export type MutationSendHiringEmailArgs = {
  emailRemark?: Maybe<Scalars['String']>;
  dataUpdateStatusLog: DataUpdateStatusLog;
  HiringIDs: Array<Scalars['String']>;
};


export type MutationInsertQuickHiringArgs = {
  quickHiringInput: QuickHiringInput;
};


export type MutationUpdateQuickHiringArgs = {
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  quickHiringInput: QuickHiringInput;
  quickHiringId: Scalars['String'];
};


export type MutationDeleteQuickHiringArgs = {
  talentStatusLog: TalentStatusLog;
  quickHiringId: Scalars['String'];
};


export type MutationCancelPreHiringArgs = {
  cancelRemark?: Maybe<Scalars['String']>;
  hiringId: Scalars['String'];
};


export type MutationApprovePreHiringArgs = {
  talentStatusLog: TalentStatusLog;
  dataUpdateStatusLog: DataUpdateStatusLog;
  hiringIds: Array<Scalars['String']>;
};


export type MutationDataUpdateStatusLogArgs = {
  input: DataUpdateStatusLog;
  hiringId: Scalars['String'];
};


export type MutationDeclineNewHiringProcessArgs = {
  declineRemarkVoice?: Maybe<DocumentsInput>;
  declineRemark?: Maybe<Scalars['String']>;
  declineReason: Scalars['String'];
  declineDate: Scalars['DateTime'];
  talentStatusLog: TalentStatusLog;
  hiringId: Scalars['String'];
};


export type MutationOnboardNewHireProcessArgs = {
  input: OnboardNewHireInput;
  hiringId: Scalars['String'];
};


export type MutationTalentPoolDeleteArgs = {
  TalentPoolID: Scalars['String'];
};


export type MutationTalentPersonalInsertArgs = {
  employeeInput?: Maybe<EmployeeInput>;
  talentInput: Rec_TalentPoolInput;
  documentInput?: Maybe<DocumentsInput>;
  contactInput: ContactInput;
};


export type MutationTalentEducationInsertArgs = {
  PersonnelEducationID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: PersonnelEducationInsertInput;
};


export type MutationTalentHealthInfoInsertArgs = {
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  vaccinationRecordInput?: Maybe<Array<VaccinationRecordInput>>;
  medicalRecordInput: Array<MedicalRecordInput>;
  attachmentToRemove?: Maybe<Array<DocumentsInput>>;
  healthInput: PersonnelHealthInfoInput;
};


export type MutationTalentStatutoryInsertArgs = {
  TalentStatutoryID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: Rec_TalentStatutoryInput;
};


export type MutationTalentCertificateInsertArgs = {
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: PersonnelCertificateInput;
};


export type MutationTalentMembershipInsertArgs = {
  PersonnelMembershipID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: PersonnelMembershipInput;
};


export type MutationTalentEmpHistoryInsertArgs = {
  PersonnelJobHistoryID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: PersonnelJobHistoryInput;
};


export type MutationTalentReferenceInsertArgs = {
  PersonnelReferenceID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: PersonnelReferenceInput;
};


export type MutationTalentDependentInsertArgs = {
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  contactInput: ContactInput;
  dependentInput: EmployeeDependentsInput;
};


export type MutationTalentLanguageInsertArgs = {
  PersonnelLanguageID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: Array<PersonnelLanguageInput>;
};


export type MutationTalentDocInsertArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  TalentPoolID?: Maybe<Scalars['String']>;
  input: DocumentsInput;
};


export type MutationTalentInfoDeleteArgs = {
  type: Scalars['String'];
  typeID: Scalars['String'];
};


export type MutationAddTalentToPrescreenArgs = {
  talentStatusLog: TalentStatusLog;
  applicantLog: ApplicantStatusLog;
  JobVacancyIDs: Array<Scalars['String']>;
  TalentPoolID: Scalars['String'];
};


export type MutationSaveTalentSearchArgs = {
  input: Rec_TalentSearchInput;
};


export type MutationDeleteTalentSearchArgs = {
  rec_talent_search_id: Scalars['String'];
};


export type MutationUpdateTalentStatusArgs = {
  talentStatusLog: TalentStatusLog;
  talentStatus: RecruitmentTalentStatus;
  talentPoolID: Scalars['String'];
};


export type MutationInsertTalentInvitationArgs = {
  sourceID?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  talentInvitationLog: TalentInvitationStatusLog;
  email: Scalars['String'];
  name: Scalars['String'];
};


export type MutationSendTalentInvitationEmailArgs = {
  emailRemark?: Maybe<Scalars['String']>;
  talentInvitationLog: TalentInvitationStatusLog;
  companyName: Scalars['String'];
  talentInvitationIDs: Array<Scalars['String']>;
};


export type MutationCancelTalentInvitationArgs = {
  cancelRemark?: Maybe<Scalars['String']>;
  talentInvitationID: Scalars['String'];
};


export type MutationApproveTalentInvitationArgs = {
  talentStatusLog: TalentStatusLog;
  talentInvitationLog: TalentInvitationStatusLog;
  talentInvitationIDs: Array<Scalars['String']>;
};


export type MutationApprovalPolicyMatrixInsertArgs = {
  input: ApprovalPolicyMatrixInput;
};


export type MutationApprovalPolicyMatrixDeleteArgs = {
  ApprovalPolicyMatrixID: Scalars['String'];
};


export type MutationDeleteApprovedRequisitionArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: EmpRequisitionInput;
};


export type MutationRequisitionManagementApprovalUpdateArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  input: Array<RequisitionUpdateInput>;
};


export type MutationRequisitionInsertArgs = {
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  input: RequisitionInput;
};


export type MutationRequisitionUpdateArgs = {
  IsRemovedAttachment?: Maybe<Scalars['Boolean']>;
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  RequisitionInput: RequisitionInput;
};


export type MutationRequisitionDeleteArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  RequisitionHeaderID: Scalars['String'];
};


export type MutationRequisitionSubmitArgs = {
  RequisitionHeaderID: Scalars['String'];
};


export type MutationSubmitRequisitionTypeArgs = {
  input: RequisitionTypeInput;
};


export type MutationInvoiceRpaArgs = {
  files: Array<Scalars['Upload']>;
};


export type MutationClaimParameterInsertArgs = {
  input: ClaimParameterInput;
};


export type MutationClaimUserDefineFieldsInsertArgs = {
  ClaimUserDefineFieldsInput: Array<ClaimUserDefineFieldsInput>;
  input: ClaimParameterInput;
};


export type MutationMileageTableInsertArgs = {
  ClaimMileageTableInput: Array<ClaimMileageTableInput>;
  input: ClaimParameterInput;
};


export type MutationVehicleRateInsertArgs = {
  ClaimVehicleRateInput: Array<ClaimVehicleRateInput>;
  input: ClaimParameterInput;
};


export type MutationClaimUserDefineFieldsUpdateArgs = {
  ClaimUserDefineFieldsInput: Array<ClaimUserDefineFieldsInput>;
  input: ClaimParameterInput;
  ClaimParameterID: Scalars['String'];
};


export type MutationClaimUserDefineFieldsDuplicateArgs = {
  input: ClaimParameterInput;
  ClaimParameterID: Scalars['String'];
};


export type MutationMileageTableUpdateArgs = {
  ClaimMileageTableInput: Array<ClaimMileageTableInput>;
  input: ClaimParameterInput;
  ClaimParameterID: Scalars['String'];
};


export type MutationMileageTableDeleteArgs = {
  ClaimParameterID: Scalars['String'];
};


export type MutationClaimParameterUpdateArgs = {
  input: ClaimParameterInput;
};


export type MutationClaimParameterDeleteArgs = {
  ClaimParameterID: Scalars['String'];
};


export type MutationPersonnelParameterInsertArgs = {
  input: PersonnelParameterInput;
};


export type MutationPersonnelParameterUpdateArgs = {
  input: PersonnelParameterInput;
};


export type MutationPersonnelParameterDeleteArgs = {
  PersonnelParameterID: Scalars['String'];
};


export type MutationReportTemplateInsertArgs = {
  DocumentsInput: DocumentsInput;
  input: ReportTemplateInput;
};


export type MutationReportTemplateAssignmentArgs = {
  DocumentsInput: DocumentsInput;
  CompanyID: Scalars['String'];
  input: ReportTemplateInput;
};


export type MutationReportTemplateInactiveArgs = {
  ReportTemplateID: Scalars['String'];
};


export type MutationReportTemplateUpdateArgs = {
  DocumentID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  input: ReportTemplateInput;
};


export type MutationSubmitStaffMovementRequestArgs = {
  input: StaffMovementRequestInput;
};


export type MutationEaPartFInsertArgs = {
  input: EaPartFInput;
};


export type MutationEaPartFUpdateArgs = {
  EAPartFID: Scalars['String'];
  input: EaPartFInput;
};


export type MutationEaPartFDeleteArgs = {
  EAPartFID: Scalars['String'];
};


export type MutationEaPartFCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
};


export type MutationEisPolicyUpdateArgs = {
  EISPolicyID: Scalars['String'];
  input: EisPolicyInput;
};


export type MutationEpfPolicyUpdateArgs = {
  EPFPolicyDetailInput: Array<EpfPolicyDetailInput>;
  EPFPolicyInput: EpfPolicyInput;
  EPFPolicyID: Scalars['String'];
};


export type MutationFormItemRatingSubmitArgs = {
  input: FormItemRatingInput;
};


export type MutationFormItemRatingDeleteArgs = {
  FormItemRatingID: Scalars['String'];
};


export type MutationKpiCategorySubmitArgs = {
  input: KpiCategoryInput;
};


export type MutationKpiCategoryDeleteArgs = {
  KpiCategoryID: Scalars['String'];
};


export type MutationKpiPredefinedItemSubmitArgs = {
  input: KpiPredefinedItemInput;
};


export type MutationKpiPredefinedItemUpdateStatusArgs = {
  input: KpiActiveInactiveInput;
};


export type MutationKpiPredefinedItemDeleteArgs = {
  KpiPredefinedItemID: Scalars['String'];
};


export type MutationKpiPredefinedTemplateSubmitArgs = {
  input: KpiPredefinedTemplateInput;
};


export type MutationKpiPredefinedTemplateDeleteArgs = {
  KpiPredefinedTemplateID: Scalars['String'];
};


export type MutationReviewFormTemplateSubmitArgs = {
  input: ReviewFormTemplateInput;
};


export type MutationReviewFormTemplateDeleteArgs = {
  ReviewFormTemplateID: Scalars['String'];
};


export type MutationReviewGradingTableSubmitArgs = {
  input: ReviewGradingTableInput;
};


export type MutationReviewGradingTableDeleteArgs = {
  ReviewGradingTableID: Scalars['String'];
};


export type MutationReviewGroupSubmitArgs = {
  input: ReviewGroupInput;
};


export type MutationReviewGroupDeleteArgs = {
  ReviewGroupID: Scalars['String'];
};


export type MutationReviewItemSubmitArgs = {
  input: ReviewItemInput;
};


export type MutationReviewItemDeleteArgs = {
  ReviewItemID: Scalars['String'];
};


export type MutationReviewPolicySubmitArgs = {
  input: ReviewPolicyInput;
};


export type MutationReviewPolicyDeleteArgs = {
  ReviewPolicyID: Scalars['String'];
};


export type MutationReviewProcessPolicySubmitArgs = {
  input: ReviewProcessPolicyInput;
};


export type MutationReviewProcessPolicyDeleteArgs = {
  ReviewProcessPolicyID: Scalars['String'];
};


export type MutationReviewTemplateSubmitArgs = {
  input: ReviewTemplateInput;
};


export type MutationReviewTemplateDeleteArgs = {
  ReviewTemplateID: Scalars['String'];
};


export type MutationRewardGroupSubmitArgs = {
  input: RewardGroupInput;
};


export type MutationRewardGroupDeleteArgs = {
  RewardGroupID: Scalars['String'];
};


export type MutationPersonnelSecurityPolicySubmitArgs = {
  input: PersonnelSecurityPolicyInput;
};


export type MutationHolidayBulkInsertArgs = {
  HolidayInput: Array<HolidayInput>;
};


export type MutationHolidayBulkDeleteArgs = {
  HolidayID: Array<Scalars['String']>;
};


export type MutationHolidayBulkUpdateArgs = {
  HolidayID: Array<Scalars['String']>;
  HolidayInput: Array<HolidayInput>;
};


export type MutationSocsoPolicyUpdateArgs = {
  SocsoPolicyID: Scalars['String'];
  input: SocsoPolicyInput;
};


export type MutationTaxReliefInsertArgs = {
  input: TaxReliefInput;
};


export type MutationTaxReliefUpdateArgs = {
  TaxReliefID: Scalars['String'];
  input: TaxReliefInput;
};


export type MutationTaxReliefDeleteArgs = {
  TaxReliefID: Scalars['String'];
};


export type MutationTaxReliefCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
};


export type MutationMonthlyAttendanceProcessingArgs = {
  ToDate: Scalars['String'];
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationAttendanceProcessingRedirectArgs = {
  ToDate: Scalars['String'];
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
};


export type MutationAttendanceProcessingArgs = {
  ToDate: Scalars['String'];
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationUpdateAdjustedValueArgs = {
  input: AttendanceCalculationInput;
};


export type MutationDeleteAutomatedClockDataImportArgs = {
  AutomatedClockDataImportID: Scalars['String'];
};


export type MutationCreateUpdateAutomatedClockDataImportArgs = {
  input: AutomatedClockDataImportInput;
};


export type MutationCreateUpdateBeaconDeviceArgs = {
  input: BluetoothBeaconDevicesInput;
};


export type MutationCreateChangeShiftArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  ChangeShiftInput: ChangeShiftInput;
};


export type MutationUpdateChangeShiftArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  RejectReason?: Maybe<Scalars['String']>;
  CSStatus: Scalars['String'];
  Input: Array<ChangeShiftInput>;
};


export type MutationDeleteChangeShiftArgs = {
  ChangeShiftID: Scalars['String'];
};


export type MutationUpdateChangeShiftSubmitArgs = {
  input: ChangeShiftInput;
};


export type MutationCreateDailyAllowanceArgs = {
  input: DailyAllowanceInput;
};


export type MutationUpdateDailyAllowanceArgs = {
  input: DailyAllowanceInput;
  DailyAllowanceID: Scalars['String'];
};


export type MutationDeleteDailyAllowanceArgs = {
  DailyAllowanceID: Scalars['String'];
};


export type MutationCreateDeductionPolicyArgs = {
  input: DeductionPolicyInput;
};


export type MutationUpdateDeductionPolicyStatusArgs = {
  Status: Status;
  DeductionPolicyID: Scalars['String'];
};


export type MutationUpdateDeductionPolicyArgs = {
  input: DeductionPolicyInput;
  DeductionPolicyID: Scalars['String'];
};


export type MutationPostEarnedLeaveArgs = {
  Remarks?: Maybe<Scalars['String']>;
  UntilDate: Scalars['String'];
  FromDate: Scalars['String'];
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationReverseEarnedLeaveArgs = {
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationCreateJobGradePolicyMatrixArgs = {
  input: JobGradePolicyMatrixInput;
};


export type MutationCreateMobileCheckInArgs = {
  input: MobileCheckInInput;
};


export type MutationUpdateMobileCheckInArgs = {
  input: MobileCheckInInput;
  MobileCheckInID: Scalars['String'];
};


export type MutationDeleteMobileCheckInArgs = {
  MobileCheckInID: Scalars['String'];
};


export type MutationInsertUpdateLocationRadiusSettingArgs = {
  MobileCheckInID?: Maybe<Scalars['String']>;
  Radius: Scalars['Float'];
};


export type MutationCreateMonthlyAllowanceArgs = {
  conditionList?: Maybe<Array<PeriodicAllowancePolicyConditionInput>>;
  input: MonthlyAllowanceInput;
};


export type MutationUpdateMonthlyAllowanceArgs = {
  conditionList?: Maybe<Array<PeriodicAllowancePolicyConditionInput>>;
  input: MonthlyAllowanceInput;
  MonthlyAllowanceID: Scalars['String'];
};


export type MutationDeleteMonthlyAllowanceArgs = {
  MonthlyAllowanceID: Scalars['String'];
};


export type MutationCreateOvertimeClaimTableArgs = {
  input: OvertimeClaimTableInput;
};


export type MutationUpdateOvertimeClaimTableStatusArgs = {
  Status: Status;
  OvertimeClaimTableID: Scalars['String'];
};


export type MutationOtReasonUpdateArgs = {
  OvertimeReasonID: Scalars['String'];
  Input: OvertimeReasonInput;
};


export type MutationOtReasonDeleteArgs = {
  OvertimeReasonID: Scalars['String'];
};


export type MutationOtReasonStatusArgs = {
  OvertimeReasonID: Scalars['String'];
  Status: Scalars['String'];
};


export type MutationOtReasonInsertArgs = {
  Input: OvertimeReasonInput;
};


export type MutationOvertimePolicyInsertArgs = {
  input: OvertimePolicyInput;
};


export type MutationOvertimePolicyUpdateArgs = {
  input: OvertimePolicyInput;
};


export type MutationOvertimePolicyDeleteArgs = {
  OvertimePolicyID: Scalars['String'];
};


export type MutationCreatePeriodicDeductionArgs = {
  conditionList?: Maybe<Array<PeriodicDeductionPolicyConditionInput>>;
  input: PeriodicDeductionInput;
};


export type MutationUpdatePeriodicDeductionArgs = {
  conditionList?: Maybe<Array<PeriodicDeductionPolicyConditionInput>>;
  input: PeriodicDeductionInput;
  PeriodicDeductionID: Scalars['String'];
};


export type MutationDeletePeriodicDeductionArgs = {
  PeriodicDeductionID: Scalars['String'];
};


export type MutationCreateAllowanceDeductionArgs = {
  input: AllowanceDeductionInput;
};


export type MutationUpdateAllowanceDeductionArgs = {
  input: AllowanceDeductionInput;
  AllowanceDeductionID: Scalars['String'];
};


export type MutationDeleteAllowanceDeductionArgs = {
  AllowanceDeductionID: Scalars['String'];
};


export type MutationCreateOtRoundingArgs = {
  input: OtRoundingInput;
};


export type MutationUpdateOtRoundingArgs = {
  OTRoundingID: Scalars['String'];
  input: OtRoundingInput;
};


export type MutationDeleteOtRoundingArgs = {
  OTRoundingID: Scalars['String'];
};


export type MutationShiftSettingInsertArgs = {
  BreakTimeInput: Array<BreakTimeInput>;
  ShiftDayInput: Array<ShiftDayInput>;
  ShiftInput: ShiftInput;
};


export type MutationShiftSetupUpdateArgs = {
  BreakTimeInput: Array<BreakTimeInput>;
  ShiftDayInput: Array<ShiftDayInput>;
  ShiftInput: ShiftInput;
  ShiftID: Scalars['String'];
};


export type MutationShiftSetupDeleteArgs = {
  ShiftID: Scalars['String'];
};


export type MutationCreateShiftGroupArgs = {
  ShiftGroupShiftInput?: Maybe<Array<ShiftGroupShiftInput>>;
  ShiftGroupInput: ShiftGroupInput;
};


export type MutationUpdateShiftGroupArgs = {
  ShiftGroupShiftInput?: Maybe<Array<ShiftGroupShiftInput>>;
  ShiftGroupInput: ShiftGroupInput;
  ShiftGroupID: Scalars['String'];
};


export type MutationUpdateShiftGroupStatusArgs = {
  Status: Status;
  ShiftGroupID: Scalars['String'];
};


export type MutationSubmitShiftGroupPatternArgs = {
  input: ShiftGroupPatternInput;
};


export type MutationDeleteShiftGroupPatternArgs = {
  ShiftGroupPatternID: Scalars['String'];
};


export type MutationLogShiftGroupEmployeeAssignmentHistoryArgs = {
  inputs: Array<ShiftGroupEmployeeAssignmentLogInput>;
};


export type MutationUpdateShiftGroupEmployeeAssignmentHistoryArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  AssignmentLogID: Scalars['String'];
};


export type MutationDeleteShiftGroupEmployeeAssignmentHistoryArgs = {
  AssignmentLogID: Scalars['String'];
};


export type MutationCreateShiftPatternArgs = {
  ShiftPatternDetailInput: Array<ShiftPatternDetailInput>;
  ShiftPatternInput: ShiftPatternInput;
};


export type MutationUpdateShiftPatternArgs = {
  ShiftPatternDetailInput: Array<ShiftPatternDetailInput>;
  ShiftPatternInput: ShiftPatternInput;
  ShiftPatternID: Scalars['String'];
};


export type MutationDeleteShiftPatternArgs = {
  ShiftPatternID: Scalars['String'];
};


export type MutationUserDefineEpfPolicyUpdateArgs = {
  UserDefineEPFPolicyDetailInput: Array<UserDefineEpfPolicyDetailInput>;
  UserDefineEPFPolicyInput: UserDefineEpfPolicyInput;
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
};


export type MutationUserDefineEpfPolicyDeleteArgs = {
  UserDefineEPFPolicyID: Scalars['String'];
};


export type MutationUserDefineEpfPolicyConditionInsertOrUpdateArgs = {
  UserDefineEPFPolicyMatrixInput: UserDefineEpfPolicyMatrixInput;
};


export type MutationUserDefineEpfPolicyConditionDeleteArgs = {
  UserDefineEPFPolicyMatrixID: Scalars['String'];
};


export type MutationUpdateTemplateLibraryAssignmentArgs = {
  input: TemplateLibraryAssignmentInput;
};


export type MutationCreateTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationOvertimeSettingUpdateArgs = {
  CompanyInput?: Maybe<CompanyInput>;
  CompanyID?: Maybe<Scalars['String']>;
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationReplaceTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationReplaceTemplateLibraryAssignmentForTmsShiftGroupArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationReplaceTemplateLibraryAssignmentv2Args = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationDeleteTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationShiftGroupAssignmentArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
};


export type MutationDailyTypeConditionUpdateArgs = {
  DailyTemplateInput: DailyTemplateInput;
};


export type MutationDailyTypeConditionDeleteArgs = {
  DailyTemplateID: Scalars['String'];
};


export type MutationDeductionTypeConditionUpdateArgs = {
  DeductionTemplateInput: DeductionTemplateInput;
};


export type MutationDeductionTypeConditionDeleteArgs = {
  DeductionTemplateID: Scalars['String'];
};


export type MutationTmsTypeConditionUpdateArgs = {
  TimeAttendanceTemplateInput: TimeAttendanceTemplateInput;
};


export type MutationTmsTypeConditionDeleteArgs = {
  TMSTemplateID: Scalars['String'];
};


export type MutationTmsotConditionTemplateLibraryUpdateArgs = {
  CompanyID: Scalars['String'];
  TMSOTConditionInput: Array<TmsotConditionInput>;
};


export type MutationTmsTimeOffConditionUpdateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  TMSTimeOffConditionInput: Array<TmsTimeOffConditionInput>;
};


export type MutationMonthlyTypeConditionUpdateArgs = {
  MonthlyTemplateInput: MonthlyTemplateInput;
};


export type MutationMonthlyTypeConditionDeleteArgs = {
  MonthlyTemplateID: Scalars['String'];
};


export type MutationTimeOffInsertArgs = {
  input: TimeOffInput;
};


export type MutationTimeOffUpdateArgs = {
  IsSupersede?: Maybe<Scalars['Boolean']>;
  ActionStatus?: Maybe<Scalars['String']>;
  input: Array<TimeOffInput>;
};


export type MutationTimeOffDeleteArgs = {
  TimeOffID: Scalars['String'];
};


export type MutationCreateTimeOffArgs = {
  input: TimeOffPolicyInput;
};


export type MutationTimeOffReasonInputArgs = {
  input: TimeOffReasonInput;
};


export type MutationTimeOffReasonUpdateArgs = {
  input: TimeOffReasonInput;
};


export type MutationTimeOffReasonDeleteArgs = {
  TimeOffReasonID: Scalars['String'];
};


export type MutationTimeOffSettingInsertArgs = {
  input: TimeOffSettingInput;
};


export type MutationTimeOffSettingUpdateArgs = {
  input: TimeOffSettingInput;
};


export type MutationTimeOffSettingDeleteArgs = {
  TimeOffSettingID: Scalars['String'];
};


export type MutationTsAccountTypeInsertArgs = {
  TsAccountTypeInput: TsAccountTypeInput;
};


export type MutationTsAccountTypeUpdateArgs = {
  TsAccountTypeInput: TsAccountTypeInput;
};


export type MutationTsAccountTypeDeleteArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationTsAccountCodeInsertArgs = {
  TsAccountCodeInput: TsAccountCodeInput;
};


export type MutationTsAccountCodeUpdateArgs = {
  TsAccountCodeInput: TsAccountCodeInput;
};


export type MutationTsAccountCodeDeleteArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationStaffTimeSheetInsertArgs = {
  StaffTimesheetInput: StaffTimesheetInput;
};


export type MutationStaffTimesheetDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationToDoStatusUpdateArgs = {
  DocumentsInput?: Maybe<Array<DocumentsInput>>;
  Remark?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  RequestCompanyID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  TrxStatus?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  RecruitmentHiringIDs?: Maybe<Array<TrxIDs>>;
  RecruitmentOfferIDs?: Maybe<Array<TrxIDs>>;
  RecruitmentRequisitionIDs?: Maybe<Array<TrxIDs>>;
  TrainingWithdrawalIDs?: Maybe<Array<TrxIDs>>;
  TrainingSelfRegistrationIDs?: Maybe<Array<TrxIDs>>;
  TrainingRequestIDs?: Maybe<Array<TrxIDs>>;
  TrainingNominationIDs?: Maybe<Array<TrxIDs>>;
  AttendanceCorrectionHeaderIDs?: Maybe<Array<TrxIDs>>;
  DataChangesIDs?: Maybe<Array<TrxIDs>>;
  ProjectExpenseClaimHeaderIDs: Array<TrxIDs>;
  ChangeShiftIDs: Array<TrxIDs>;
  TimeOffIDs: Array<TrxIDs>;
  OvertimeIDs: Array<TrxIDs>;
  ClaimHeaderIDs: Array<TrxIDs>;
  RequisitionIDs: Array<TrxIDs>;
  LeaveHeaderIDs: Array<TrxIDs>;
  EmployeeID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
};


export type MutationCreateTrainingAttendanceRecordArgs = {
  input: TrainingAttendanceInput;
};


export type MutationUpdateTrainingAttendanceRecordArgs = {
  input: TrainingAttendanceInput;
};


export type MutationCompulsaryCourseByJobGradeSubmitArgs = {
  JobGradeID: Scalars['String'];
  CompanyID: Scalars['String'];
  ids: Array<Scalars['String']>;
};


export type MutationCourseClassificationBulkInsertArgs = {
  input: Array<CourseClassificationInput>;
};


export type MutationCourseClassificationDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationTrainingCourseSubmitArgs = {
  input: TrainingCourseInput;
};


export type MutationTrainingCourseDeleteArgs = {
  TrainingCourseID: Scalars['String'];
};


export type MutationAuthorisedTrainingProviderSubmitArgs = {
  input: AuthorisedTrainingProviderInput;
};


export type MutationTrainingCostItemBulkInsertArgs = {
  input: Array<TrainingCostItemInput>;
};


export type MutationTrainingCostItemDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationTraineeEvalSubmitArgs = {
  input: TraineeEvalInput;
};


export type MutationSupervisorEvalSubmitArgs = {
  input: SupervisorEvalInput;
};


export type MutationCourseEvalSubmitArgs = {
  input: CourseEvalInput;
};


export type MutationTrainerEvalSubmitArgs = {
  input: TrainerEvalInput;
};


export type MutationScheduleTrainingSubmitArgs = {
  input: ScheduleTrainingInput;
};


export type MutationScheduleTableSubmitArgs = {
  ScheduleTrainingID: Scalars['String'];
  input: Array<TrainingScheduleTableInput>;
};


export type MutationScheduleTrainingDeleteArgs = {
  ScheduleTrainingID: Scalars['String'];
};


export type MutationScheduleTrainingCancelArgs = {
  ScheduleTrainingID: Scalars['String'];
};


export type MutationRegisteredTraineeInsertArgs = {
  ScheduleTrainingID: Scalars['String'];
  RegisteredTraineeID: Scalars['String'];
};


export type MutationRegisteredTraineeDeleteArgs = {
  ScheduleTrainingID: Scalars['String'];
  RegisteredTraineeID: Scalars['String'];
};


export type MutationTrainingBudgetInputBulkInsertArgs = {
  input: Array<TrainingBudgetInput>;
};


export type MutationTrainingCostSubmitArgs = {
  ScheduleTrainingID: Scalars['String'];
  input: Array<TrainingCostInput>;
};


export type MutationCostAllocationSubmitArgs = {
  ScheduleTrainingID: Scalars['String'];
  input: Array<CostAllocationInput>;
};


export type MutationHrdfClaimSubmitArgs = {
  input: HrdfClaimInput;
};


export type MutationSubmitBudgetGroupMatrixArgs = {
  input: BudgetGroupMatrixInput;
};


export type MutationDeleteBudgetGroupMatrixArgs = {
  BudgetGroupMatrixID: Scalars['String'];
};


export type MutationSubmitTargetGroupMatrixArgs = {
  input: TargetGroupMatrixInput;
};


export type MutationDeleteTargetGroupMatrixArgs = {
  TargetGroupMatrixID: Scalars['String'];
};


export type MutationSubmitApprovalPolicyMatrixArgs = {
  input: ApprovalPolicyMatrixInput;
};


export type MutationDeleteApprovalPolicyMatrixArgs = {
  ApprovalPolicyMatrixID: Scalars['String'];
};


export type MutationIsMaintenanceBudgetPeriodOverlapArgs = {
  TrainingBudgetID?: Maybe<Scalars['String']>;
  PeriodTo: Scalars['String'];
  PeriodFrom: Scalars['String'];
};


export type MutationSubmitMaintenanceBudgetArgs = {
  input: MaintenanceBudgetInput;
};


export type MutationDeleteMaintenanceBudgetArgs = {
  TrainingBudgetID: Scalars['String'];
};


export type MutationIsMaintenanceTargetHoursPeriodOverlapArgs = {
  TrainingTargetHoursID?: Maybe<Scalars['String']>;
  PeriodTo: Scalars['String'];
  PeriodFrom: Scalars['String'];
};


export type MutationSubmitMaintenanceTargetHoursArgs = {
  input: MaintenanceTargetHoursInput;
};


export type MutationDeleteMaintenanceTargetHoursArgs = {
  TrainingTargetHoursID: Scalars['String'];
};


export type MutationSubmitMaintenanceCourseArgs = {
  input: MaintenanceCourseInput;
};


export type MutationSubmitTrainingRequestArgs = {
  input: TrainingRequestInput;
};


export type MutationDeleteTrainingRequestArgs = {
  TrainingRequestID: Scalars['String'];
};


export type MutationWithdrawTrainingRequestArgs = {
  Remarks?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  EmployeeTrainingRequestID: Scalars['String'];
};


export type MutationSubmitScheduledTrainingArgs = {
  input: ScheduledTrainingInput;
};


export type MutationDeleteScheduledTrainingArgs = {
  ScheduledTrainingID: Scalars['String'];
};


export type MutationUpdateScheduledTrainingEventStatusArgs = {
  Status: Event_Status;
  comment?: Maybe<Scalars['String']>;
  AdminEmployeeNo?: Maybe<Scalars['String']>;
  AdminEmployeeName?: Maybe<Scalars['String']>;
  AdminEmployeeID?: Maybe<Scalars['String']>;
  AdminContactID?: Maybe<Scalars['String']>;
  AccountName: Scalars['String'];
  AdminUserID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
};


export type MutationSubmitAdminTrainingRegistrationArgs = {
  NominatorID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
};


export type MutationUpdateEmployeeStatusAndNominatorArgs = {
  ParticipationStatus?: Maybe<Participation_Status>;
  NominatorID?: Maybe<Scalars['String']>;
  EmployeeScheduledTrainingID: Scalars['String'];
};


export type MutationUpdateEmployeeScheduledTrainingStatusArgs = {
  Status: Participation_Status;
  ScheduledTrainingEmployeeID: Scalars['String'];
};


export type MutationDeleteEmployeeTrainingRegistrationArgs = {
  EmployeeID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
  ScheduledTrainingEmployeeID: Scalars['String'];
};


export type MutationSubmitVenueInfoArgs = {
  ScheduledTrainingData: Scalars['JSON'];
  input: ScheduledTrainingVenueInput;
};


export type MutationNotifyTraineeArgs = {
  SelectedEmployeeIDs: Array<Scalars['String']>;
  Attachment?: Maybe<DocumentsInput>;
  EmailInput: EmailTemplateInput;
  ScheduledTrainingID: Scalars['String'];
  AccountName: Scalars['String'];
};


export type MutationSubmitTrainingAttendanceArgs = {
  Remarks?: Maybe<Scalars['String']>;
  AdjustedCheckOut?: Maybe<Scalars['String']>;
  AdjustedCheckIn?: Maybe<Scalars['String']>;
  ScheduledTrainingTimetableID?: Maybe<Scalars['String']>;
  ScheduledTrainingEmployeeID?: Maybe<Scalars['String']>;
  TrainingAttendanceID?: Maybe<Scalars['String']>;
};


export type MutationFinalizedAttendanceArgs = {
  input?: Maybe<Array<NewTrainingAttendanceInput>>;
};


export type MutationGenerateTrainingEvaluationArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  ParticipaionStatus: Array<Participation_Status>;
  ScheduledTrainingSurveyID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
};


export type MutationInitiateTrainingEvaluationArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  ScheduledTrainingEmployeeEvaluationIDs: Array<Scalars['String']>;
};


export type MutationSubmitSurveyFormAnswerArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  SurveyAnswerInput: Array<EmployeeEvaluationAnswerInput>;
  ScheduledTrainingEmployeeEvaluationID: Scalars['String'];
};


export type MutationUploadTrainingEvaluationArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  Attachments: Array<DocumentsInput>;
  Score: Scalars['Float'];
  ScheduledTrainingEmployeeEvaluationID: Scalars['String'];
};


export type MutationRevertDraftTrainingEvaluationArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  ScheduledTrainingEmployeeEvaluationID: Scalars['String'];
};


export type MutationClearRevertDraftTrainingEvaluationArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ApproverName: Scalars['String'];
  ApproverID: Scalars['String'];
  ScheduledTrainingEmployeeEvaluationID: Scalars['String'];
};


export type MutationDeleteTrainingEvaluationArgs = {
  ScheduledTrainingEmployeeEvaluationID: Scalars['String'];
};


export type MutationSubmitScheduledTrainingExpenseArgs = {
  EmployeeInput?: Maybe<Array<Scalars['JSON']>>;
  input: Array<ScheduledTrainingExpenseInput>;
  ScheduledTrainingID: Scalars['String'];
};


export type MutationSubmitHrdcClaimArgs = {
  input: HrdcClaimInput;
};


export type MutationUpdateHrdcClaimStatusArgs = {
  ApprovalStatus: Approval_Status;
  Remarks?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
  HRDCClaimID: Scalars['String'];
};


export type MutationDeleteHrdcClaimArgs = {
  HRDCClaimID: Scalars['String'];
};


export type MutationEmployeeSubmitTrainingRequestArgs = {
  input: TrainingRequestInput;
};


export type MutationEmployeeDeleteTrainingRequestArgs = {
  TrainingRequestID: Scalars['String'];
};


export type MutationEmployeeCancelTrainingRequestArgs = {
  Remarks?: Maybe<Scalars['String']>;
  EmployeeTrainingRequestID: Scalars['String'];
};


export type MutationTrainingCheckInArgs = {
  input: TrainingCheckInInput;
};


export type MutationWithdrawSubordinateTrainingScheduledArgs = {
  Voice_Input?: Maybe<DocumentsInput>;
  Withdraw_Remarks?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  TrainingEventID: Scalars['String'];
};


export type MutationApplyTrainingEventArgs = {
  IsFull?: Maybe<Scalars['Boolean']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  TrainingEventID: Scalars['String'];
};


export type MutationNominateSubordinateArgs = {
  Remarks?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  TrainingEventID: Scalars['String'];
};


export type MutationWithdrawSubordinateArgs = {
  EmployeeID: Scalars['String'];
  TrainingEventID: Scalars['String'];
};


export type MutationSubmitCourseCategoryArgs = {
  input: Array<CourseCategoryInput>;
};


export type MutationDeleteCourseCategoryArgs = {
  course_category_ids: Array<Scalars['String']>;
};


export type MutationSubmitTrainingCourseLibraryArgs = {
  input: TrainingCourseLibraryInput;
};


export type MutationDeleteTrainingCourseLibraryArgs = {
  training_course_library_id: Scalars['String'];
};


export type MutationSubmitTrainingProviderArgs = {
  input: TrainingProviderInput;
};


export type MutationDeleteTrainingProviderArgs = {
  training_provider_id: Scalars['String'];
};


export type MutationSubmitSurveyTypeArgs = {
  input: SurveyTypeInput;
};


export type MutationDeleteSurveyTypeArgs = {
  survey_type_id: Scalars['String'];
};


export type MutationSubmitSurveyItemArgs = {
  input: SurveyItemInput;
};


export type MutationDeleteSurveyItemArgs = {
  survey_item_id: Scalars['String'];
};


export type MutationSubmitSurveyFormArgs = {
  Training_Evaluation?: Maybe<Array<Training_Evaluation>>;
  input: SurveyFormInput;
};


export type MutationDeleteSurveyFormArgs = {
  survey_form_id: Scalars['String'];
};


export type MutationSubmitTrainingExpenseArgs = {
  input: Array<TrainingExpenseInput>;
};


export type MutationDeleteTrainingExpenseArgs = {
  training_expense_ids: Array<Scalars['String']>;
};


export type MutationSubmitBudgetGroupArgs = {
  input: Array<BudgetGroupInput>;
};


export type MutationDeleteBudgetGroupArgs = {
  budget_group_ids: Array<Scalars['String']>;
};


export type MutationSubmitTargetGroupArgs = {
  input: Array<TargetGroupInput>;
};


export type MutationDeleteTargetGroupArgs = {
  target_group_ids: Array<Scalars['String']>;
};


export type MutationSubmitTrainerProfileArgs = {
  input: TrainerProfileInput;
};


export type MutationDeleteTrainerProfileArgs = {
  TrainerProfileID: Scalars['String'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<HrPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<HrPermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  companyID: Scalars['String'];
};


export type MutationAllowancePolicyInsertArgs = {
  input: AllowanceBenefitInput;
};


export type MutationAllowancePolicyUpdateArgs = {
  AllowanceBenefitID: Scalars['String'];
  input: AllowanceBenefitInput;
};


export type MutationAllowanceEntitlementInsertArgs = {
  input: AllowanceItemInput;
};


export type MutationAllowanceEntitlementUpdateArgs = {
  AllowanceItemID: Scalars['String'];
  input: AllowanceItemInput;
};


export type MutationUpdateLastProcessArgs = {
  IsLastProcess: Scalars['Boolean'];
  PayrollCyclePeriodID: Scalars['String'];
};


export type MutationPayProcessInsertArgs = {
  input: PayrollDetailInput;
};


export type MutationEmployeeAppLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  accountName: Scalars['String'];
  input: UserInput;
};


export type MutationGenHashPasswordArgs = {
  Password?: Maybe<Scalars['String']>;
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationResendInvitationArgs = {
  accountID: Scalars['String'];
  accountName: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  companyID?: Maybe<Scalars['String']>;
  input: UserInput;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationRedirectDomainTokenArgs = {
  UserID: Scalars['String'];
};


export type MutationLoginArgs = {
  deviceRegistrationToken?: Maybe<Scalars['String']>;
  softwareCode: SoftwareCode;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationChangeUserPasswordArgs = {
  accountID: Scalars['String'];
  userID: Scalars['String'];
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationForgotEmployeePasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetEmployeePasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationUnblockUserArgs = {
  accountID: Scalars['String'];
  input: PasswordInfo;
};


export type MutationUserActionStatusArgs = {
  accountID: Scalars['String'];
  input: ActionStatus;
};


export type MutationUpdateHelpdeskAssigneeArgs = {
  input: UpdateHelpdeskAssigneeInput;
};


export type MutationChangeEmployeePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationUpdateLastLoginCompanyArgs = {
  lastestAccessedEntity: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationUserProfilePicUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  UserID: Scalars['String'];
};


export type MutationResendInvitationByIdArgs = {
  data: Array<Scalars['JSON']>;
};

export type MyApplicationOutput = {
  __typename?: 'MyApplicationOutput';
  leave?: Maybe<LeaveAppOutput>;
  claim?: Maybe<ClaimAppOutput>;
  requisition?: Maybe<RequisitionAppOutput>;
};

export type MyClaimDetailOutput = {
  __typename?: 'MyClaimDetailOutput';
  ClaimDetailID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimBalance?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<Scalars['String']>;
  DataTable?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  Remark?: Maybe<Scalars['String']>;
  VerifiedClaimDetail?: Maybe<VerifiedClaimDetail>;
  PostedClaimDetail?: Maybe<PostedClaimDetail>;
  ClaimDuplicateList?: Maybe<Array<ClaimDuplicateList>>;
};

export type MyClaimHeaderDetails = {
  __typename?: 'MyClaimHeaderDetails';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<ApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
};

export type MyClaimListingOutput = {
  __typename?: 'MyClaimListingOutput';
  Year?: Maybe<Scalars['Float']>;
  CountApprovedClaims?: Maybe<Scalars['Float']>;
  TotalApprovedClaimsAmount?: Maybe<Scalars['Float']>;
  ClaimRecords?: Maybe<Array<ClaimRecordsListing>>;
};

export type MyJobAccountEntity = InterfaceBase & {
  __typename?: 'MyJobAccountEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MyJobAccountID: Scalars['String'];
  JobAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  JobAccount: JobAccountEntity;
  Department: DepartmentEntity;
};

export type MyJobAccountInput = {
  JobAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MyLeaveDetailOutput = InterfaceBase & {
  __typename?: 'MyLeaveDetailOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  Superior?: Maybe<Scalars['JSON']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsTms?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  OldCompanyID: Scalars['String'];
  OldEmployeeID: Scalars['String'];
  SubmittedDT?: Maybe<Scalars['DateTime']>;
  TimeOffID: Scalars['String'];
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  VoiceDocumentID: Scalars['String'];
  LeaveReasonID: Scalars['String'];
  DiseaseCodeID: Scalars['String'];
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  DocumentFile?: Maybe<Scalars['String']>;
  ApprovalID?: Maybe<Scalars['String']>;
  ApprovedByEmployeeID?: Maybe<Scalars['String']>;
};

export type MyLeaveEntitlementList = {
  __typename?: 'MyLeaveEntitlementList';
  LeaveItem?: Maybe<LeaveItemEntity>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
  ToDateEarned?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
};

export type MyLeaveEntitlementOuput = {
  __typename?: 'MyLeaveEntitlementOuput';
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  BroughtForward?: Maybe<Scalars['Float']>;
  Entitlement?: Maybe<Scalars['Float']>;
  AsToDateEnt?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
};

export type MyLeaveInsertInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MyLeaveListingInput = {
  EmployeeID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  LeaveTypes?: Maybe<Array<Scalars['String']>>;
};

export type MyLeaveSummaryInput = {
  EmployeeID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export type MyLeaveSummaryOutput = {
  __typename?: 'MyLeaveSummaryOutput';
  LeaveType: Scalars['String'];
  EntitledDay: Scalars['Float'];
  TakenDay: Scalars['Float'];
  BalanceDay: Scalars['Float'];
};

export type MyLeaveUpdateInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MyPeClaimHeaderDetails = {
  __typename?: 'MyPEClaimHeaderDetails';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<PeApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
};

export type MyProjectExpenseClaimDetailOutput = {
  __typename?: 'MyProjectExpenseClaimDetailOutput';
  ClaimDetailID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimBalance?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  Remark?: Maybe<Scalars['String']>;
  VerifiedClaimDetail?: Maybe<PeVerifiedClaimDetail>;
  PostedClaimDetail?: Maybe<PePostedClaimDetail>;
  ClaimDuplicateList?: Maybe<Array<PeClaimDuplicateList>>;
};

export type MyReviewActionOutput = {
  __typename?: 'MyReviewActionOutput';
  ID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeReview: Array<EmployeeReviewEntity>;
  ReviewExerciseName: Scalars['String'];
};

export type MySubordinatesInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['Float']>;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bruneian = 'Bruneian',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Pakistani = 'Pakistani',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Yemeni = 'Yemeni',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export enum NationalityType {
  Malaysian = 'Malaysian',
  NonMalaysian = 'Non_Malaysian'
}

export type NewApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
};

export type NewDocumentNumberingInsertInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  Format?: Maybe<Array<DocumentNumberDetailInput>>;
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  RefTableName?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Array<Scalars['String']>>;
  ProgramRef?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type NewHireProcessOnboardInfoOutput = {
  __typename?: 'NewHireProcessOnboardInfoOutput';
  contact_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  superior_id?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  probation_month?: Maybe<Scalars['Float']>;
  full_name?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  superior_full_name?: Maybe<Scalars['String']>;
  superior_role?: Maybe<Scalars['String']>;
  recruitment_source_name?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
};

export type NewInterfaceBase = {
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogs>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
};

export type NewInterfaceBase1 = {
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
};

export type NewInterfaceBaseInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
};

export type NewInterfaceBaseInput1 = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
};

export type NewReportingStructureEntity = NewInterfaceBase & {
  __typename?: 'NewReportingStructureEntity';
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogs>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  reporting_structure_id: Scalars['String'];
  company_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  reporting_group?: Maybe<ReportingGroupEntity>;
  effective_date?: Maybe<Scalars['DateTime']>;
  expiry_date?: Maybe<Scalars['DateTime']>;
  subordinate_id?: Maybe<Scalars['String']>;
  subordinate?: Maybe<EmployeeEntity>;
  superior_id?: Maybe<Scalars['String']>;
  superior?: Maybe<EmployeeEntity>;
  reporting_type: ReportingType;
  is_data_import?: Maybe<Scalars['Boolean']>;
};

export type NewTrainingAttendanceEntity = NewInterfaceBase1 & {
  __typename?: 'NewTrainingAttendanceEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_employee_id: Scalars['String'];
  scheduled_training_timetable_id: Scalars['String'];
  check_in?: Maybe<Scalars['DateTime']>;
  check_out?: Maybe<Scalars['DateTime']>;
  adjusted_check_in?: Maybe<Scalars['DateTime']>;
  adjusted_check_out?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  EmployeeScheduledTraining?: Maybe<ScheduledTrainingEmployeeEntity>;
  ScheduledTrainingTimetable?: Maybe<ScheduledTrainingTimetableEntity>;
};

export type NewTrainingAttendanceInput = {
  scheduled_training_employee_id?: Maybe<Scalars['String']>;
  scheduled_training_id?: Maybe<Scalars['String']>;
  finalized_attendance?: Maybe<Scalars['Boolean']>;
};

export type NewTrainingBudgetEntity = NewInterfaceBase1 & {
  __typename?: 'NewTrainingBudgetEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  TrainingBudgetItems?: Maybe<Array<TrainingBudgetItemsEntity>>;
};

export type NewsEntity = InterfaceBase & {
  __typename?: 'NewsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ValidFrom?: Maybe<Scalars['DateTime']>;
  ValidTo?: Maybe<Scalars['DateTime']>;
  Acknowledgement?: Maybe<Scalars['Boolean']>;
  AcknowledgementStatement?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<PostStatus>;
  NewsRead: Array<NewsReadEntity>;
  Documents?: Maybe<DocumentsEntity>;
  Contact?: Maybe<ContactEntity>;
  ContentUrl?: Maybe<Scalars['String']>;
  IsTraining?: Maybe<Scalars['Boolean']>;
  PublishedBy: Scalars['String'];
};

export type NewsInput = {
  NewsID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ValidFrom: Scalars['DateTime'];
  ValidTo?: Maybe<Scalars['DateTime']>;
  AcknowledgementStatement?: Maybe<Scalars['String']>;
  Acknowledgement?: Maybe<Scalars['Boolean']>;
  PostStatus?: Maybe<PostStatus>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContentUrl?: Maybe<Scalars['String']>;
  IsTraining?: Maybe<Scalars['Boolean']>;
};

export type NewsReadEntity = InterfaceBase & {
  __typename?: 'NewsReadEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsReadID: Scalars['String'];
  NewsID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  News: NewsEntity;
};

export type NewsReadInput = {
  NewsID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationEntity = InterfaceBase & {
  __typename?: 'NotificationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  FromEmployeeID?: Maybe<Scalars['String']>;
  ToEmployeeID?: Maybe<Scalars['String']>;
  NotificationType: NotificationType;
  RequestType: RequestType;
  RequestID?: Maybe<Scalars['String']>;
  IsTodo?: Maybe<Scalars['Boolean']>;
  Message?: Maybe<Scalars['String']>;
  ReadStatus: ReadStatus;
  ShowNotification?: Maybe<Scalars['Boolean']>;
  Company: CompanyEntity;
  FromEmployee?: Maybe<EmployeeEntity>;
  ToEmployee?: Maybe<EmployeeEntity>;
};

export type NotificationInput = {
  NotificationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromEmployeeID?: Maybe<Scalars['String']>;
  ToEmployeeID?: Maybe<Scalars['String']>;
  NotificationType: NotificationType;
  RequestType: RequestType;
  RequestID?: Maybe<Scalars['String']>;
  IsTodo?: Maybe<Scalars['Boolean']>;
  Message?: Maybe<Scalars['String']>;
  ReadStatus: ReadStatus;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShowNotification?: Maybe<Scalars['Boolean']>;
};

export type NotificationPolicyClaimEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyClaimEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyClaimID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
};

export type NotificationPolicyClaimInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyConditionEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyConditionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Value: Scalars['Float'];
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
};

export type NotificationPolicyConditionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Value?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyID: Scalars['String'];
  Subject?: Maybe<Scalars['String']>;
  NotificationPolicyType: NotificationPolicyType;
  PolicyType: PolicyType;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicyClaim?: Maybe<Array<NotificationPolicyClaimEntity>>;
  NotificationPolicyLeave?: Maybe<Array<NotificationPolicyLeaveEntity>>;
  NotificationPolicyRequisition?: Maybe<Array<NotificationPolicyRequisitionEntity>>;
  NotificationPolicyCondition?: Maybe<Array<NotificationPolicyConditionEntity>>;
};

export type NotificationPolicyInput = {
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  NotificationPolicyType?: Maybe<NotificationPolicyType>;
  PolicyType?: Maybe<PolicyType>;
  CompanyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyClaim?: Maybe<Array<NotificationPolicyClaimInput>>;
  NotificationPolicyLeave?: Maybe<Array<NotificationPolicyLeaveInput>>;
  NotificationPolicyRequisition?: Maybe<Array<NotificationPolicyRequisitionInput>>;
  NotificationPolicyCondition?: Maybe<Array<NotificationPolicyConditionInput>>;
};

export type NotificationPolicyLastUpdatedOutput = {
  __typename?: 'NotificationPolicyLastUpdatedOutput';
  NotificationEmploymentLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationLeaveLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationClaimLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationRequisitionLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationEmploymentIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationLeaveIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationClaimIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationRequisitionIsLastUpdated?: Maybe<Scalars['Boolean']>;
};

export type NotificationPolicyLeaveEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
};

export type NotificationPolicyLeaveInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyRequisitionEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyRequisitionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyRequisitionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  RequisitionType: RequisitionType;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
};

export type NotificationPolicyRequisitionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum NotificationPolicyType {
  EmploymentStatus = 'EmploymentStatus',
  Leave = 'Leave',
  Claim = 'Claim',
  Requisition = 'Requisition'
}

export enum NotificationType {
  News = 'News',
  Approval = 'Approval',
  SelfNotification = 'Self_Notification'
}

export type OtClaimEntitlement = {
  __typename?: 'OTClaimEntitlement';
  DayType: DayType;
  OTClaimEntitlementListInput?: Maybe<Array<OtClaimEntitlementList>>;
};

export type OtClaimEntitlementInput = {
  DayType: DayType;
  OTClaimEntitlementListInput?: Maybe<Array<OtClaimEntitlementListInput>>;
};

export type OtClaimEntitlementList = {
  __typename?: 'OTClaimEntitlementList';
  MinHours?: Maybe<Scalars['Float']>;
  MaxHours?: Maybe<Scalars['Float']>;
  OTEntitlementType?: Maybe<OtEntitlementType>;
  PayItem?: Maybe<Scalars['String']>;
};

export type OtClaimEntitlementListInput = {
  MinHours?: Maybe<Scalars['Float']>;
  MaxHours?: Maybe<Scalars['Float']>;
  OTEntitlementType?: Maybe<OtEntitlementType>;
  PayItem?: Maybe<Scalars['String']>;
};

export enum OtClaimTableType {
  OtClaims = 'OT_CLAIMS',
  ReplacementLeave = 'REPLACEMENT_LEAVE'
}

export enum OtEntitlementType {
  ActualHour = 'ACTUAL_HOUR',
  MaxHours = 'MAX_HOURS'
}

export type OtProcessingOutput = {
  __typename?: 'OTProcessingOutput';
  WorkDate?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  FormStatus?: Maybe<Scalars['String']>;
};

export type OtRoundingEntity = InterfaceBase & {
  __typename?: 'OTRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OTRoundingID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type OtRoundingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OTRoundingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
};

export type OTconditionList = {
  Condition?: Maybe<Scalars['String']>;
  totalOTMinutes?: Maybe<Scalars['String']>;
  DeductOTMinutes?: Maybe<Scalars['String']>;
  MinimumPay?: Maybe<Scalars['String']>;
};

export enum Occurrence {
  CalendarYear = 'Calendar_Year',
  EmploymentPeriod = 'Employment_Period'
}

export type OfferChecklistSnapshot = {
  rec_offer_checklist_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  is_required?: Maybe<Scalars['Boolean']>;
};

export type OnBehalfCompanyOutput = {
  __typename?: 'OnBehalfCompanyOutput';
  CompanyID: Scalars['String'];
  CompanyName: Scalars['String'];
  ReportingGroupIDs: Array<Scalars['String']>;
};

export type OnBehalfEntity = InterfaceBaseV2 & {
  __typename?: 'OnBehalfEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  OnBehalfPolicyID: Scalars['String'];
  CompanyID: Scalars['String'];
  ModuleType: RequestType;
  ReportingGroupID: Scalars['String'];
  RequestTypeIDs: Array<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  ReportingGroupName: Scalars['String'];
};

export type OnBehalfPolicyInput = {
  ReportingGroupID: Scalars['String'];
  RequestTypeIDs: Array<Scalars['String']>;
};

export type OnBehalfSubordinateOutput = {
  __typename?: 'OnBehalfSubordinateOutput';
  ReportingGroupID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ContactID: Scalars['String'];
  EmployeeName: Scalars['String'];
  Email: Scalars['String'];
  MobileNo: Scalars['String'];
  Avatar?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  CompanyName: Scalars['String'];
  Designation: Scalars['String'];
};

export type OnboardNewHireInput = {
  ContactID: Scalars['String'];
  CompanyID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  IsHOD: Scalars['Boolean'];
  DesignationID: Scalars['String'];
  JobGradeID: Scalars['String'];
  StaffCategoryID: Scalars['String'];
  EmployeeStatus: Status;
  EmploymentType: EmploymentType;
  JoinedDate: Scalars['DateTime'];
  JoinedReferenceDate: Scalars['DateTime'];
  Probation?: Maybe<Scalars['Float']>;
  ConfirmedDate: Scalars['DateTime'];
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyID: Scalars['String'];
  RecuritmentSourceID: Scalars['String'];
  RetirementAge: Scalars['Float'];
  submitter_id?: Maybe<Scalars['String']>;
  onboard_status?: Maybe<Scalars['String']>;
  onboard_approval_log?: Maybe<Array<Scalars['JSON']>>;
  onboard_approval_remark?: Maybe<Scalars['String']>;
  onboard_approval_remark_voice?: Maybe<Scalars['String']>;
};

export enum OnboardStatus {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export type OneTimePayEntity = InterfaceBase & {
  __typename?: 'OneTimePayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OneTimePayID: Scalars['String'];
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  RecordSource?: Maybe<RecordSource>;
  ExcelFileName?: Maybe<Scalars['String']>;
  StartPeriod?: Maybe<Scalars['DateTime']>;
  EndPeriod?: Maybe<Scalars['DateTime']>;
  IsInitiated?: Maybe<Scalars['Boolean']>;
  DocumentID?: Maybe<Scalars['String']>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  PayItem?: Maybe<PayItemEntity>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  Employee?: Maybe<EmployeeEntity>;
  User?: Maybe<UserEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type OneTimePayInput = {
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StartPeriod?: Maybe<Scalars['DateTime']>;
  EndPeriod?: Maybe<Scalars['DateTime']>;
  IsInitiated?: Maybe<Scalars['Boolean']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type OneTimePayItemCalculatioOutput = {
  __typename?: 'OneTimePayItemCalculatioOutput';
  TotalOneTimeTax: Scalars['Float'];
  TotalOneTimeAddRemuneration: Scalars['Float'];
  TotalOneTimeEPF: Scalars['Float'];
  TotalOneTimeEPFVE: Scalars['Float'];
  EPFEmployeeToBeMinus: Scalars['Float'];
  TotalOneTimeNormalRemunerationEPF: Scalars['Float'];
  TotalForcastedNormalRemunerationEPF: Scalars['Float'];
  TotalOneTimeMonthlyWageNumeration: Scalars['Float'];
  TotalOneTimeAddRemunerationEPF: Scalars['Float'];
  TotalOneTimeEPFEmployee: Scalars['Float'];
  TotalOneTimeEPFVEEmployee: Scalars['Float'];
  TotalOneTimeNormalRemunerationEPFEmployee: Scalars['Float'];
  TotalForcastedNormalRemunerationEPFEmployee: Scalars['Float'];
  TotalOneTimeMonthlyWageNumerationEmployee: Scalars['Float'];
  TotalOneTimeAddRemunerationEPFEmployee: Scalars['Float'];
  TotalOneTimeEPFEmployer: Scalars['Float'];
  TotalOneTimeEPFVEEmployer: Scalars['Float'];
  TotalOneTimeNormalRemunerationEPFEmployer: Scalars['Float'];
  TotalForcastedNormalRemunerationEPFEmployer: Scalars['Float'];
  TotalOneTimeMonthlyWageNumerationEmployer: Scalars['Float'];
  TotalOneTimeAddRemunerationEPFEmployer: Scalars['Float'];
  TotalOneTimeSocso: Scalars['Float'];
  TotalOneTimeZakat: Scalars['Float'];
  TotalOneTimeEIS: Scalars['Float'];
  TotalOneTimeBonus: Scalars['Float'];
  TotalOneTimeHRDF: Scalars['Float'];
  TotalOneTimeBonusEPF: Scalars['Float'];
  TotalOneTimeBonusEPFEmployee: Scalars['Float'];
  TotalOneTimeBonusEPFEmployer: Scalars['Float'];
  TotalCountOneTimeAddRemuneration: Scalars['Float'];
  OneTimePayItemListing?: Maybe<Array<OneTimePayItemOutput>>;
};

export type OneTimePayItemOutput = {
  __typename?: 'OneTimePayItemOutput';
  PayItemID: Scalars['String'];
  PayItemType?: Maybe<PayItemType>;
  PayName: Scalars['String'];
  PayCode: Scalars['String'];
  Calculation: Scalars['String'];
  Formula: Scalars['String'];
  Quantity: Scalars['Float'];
  Amount: Scalars['Float'];
  ActualAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TotalAddRemuneration: Scalars['Float'];
  ExemptNormalRemunerationAmount: Scalars['Float'];
  ExemptAdditionalRemunerationAmount: Scalars['Float'];
  AddRemunerationAmount: Scalars['Float'];
  EPFAmount: Scalars['Float'];
  EPFVEAmount: Scalars['Float'];
  SocsoAmount: Scalars['Float'];
  EISAmount: Scalars['Float'];
  BonusAmount: Scalars['Float'];
  HRDFAmount: Scalars['Float'];
  ZakatAmount: Scalars['Float'];
  EPFEmployee: Scalars['Boolean'];
  EPFEmployer: Scalars['Boolean'];
  EPFAmountEmployee: Scalars['Float'];
  EPFAmountEmployer: Scalars['Float'];
  EPFVEAmountEmployee: Scalars['Float'];
  EPFVEAmountEmployer: Scalars['Float'];
};

export type OrgKpiPlanningDetailsEntity = InterfaceBase & {
  __typename?: 'OrgKpiPlanningDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  OrgKpiPlanningID: Scalars['String'];
  OrgGoalID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  KPI: Scalars['String'];
  Target: Scalars['String'];
  Initiatives: Scalars['String'];
  PercentageComplete: Scalars['Float'];
  KpiCategory?: Maybe<KpiCategoryEntity>;
  OrgGoal?: Maybe<OrganizationalGoalEntity>;
};

export type OrgKpiPlanningDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  OrgKpiPlanningID: Scalars['String'];
  OrgGoalID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  KPI: Scalars['String'];
  Target: Scalars['String'];
  Initiatives: Scalars['String'];
  PercentageComplete: Scalars['Float'];
};

export type OrgKpiPlanningEntity = InterfaceBase & {
  __typename?: 'OrgKpiPlanningEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  OrgUnitID: Scalars['String'];
  OwnerID: Scalars['String'];
  Owner?: Maybe<EmployeeEntity>;
  TotalGoals: Scalars['Float'];
  PercentageComplete: Scalars['Float'];
};

export type OrgKpiPlanningInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  OrgUnitID: Scalars['String'];
  OwnerID: Scalars['String'];
};

export type OrganizationalGoalEntity = InterfaceBase & {
  __typename?: 'OrganizationalGoalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Year: Scalars['Float'];
  KpiCategoryID: Scalars['String'];
  Goal: Scalars['String'];
  Objective: Scalars['String'];
  PercentageComplete: Scalars['Float'];
  KpiCategory?: Maybe<KpiCategoryEntity>;
  InUse: Scalars['Boolean'];
};

export type OrganizationalGoalInput = {
  ID?: Maybe<Scalars['String']>;
  Year: Scalars['Float'];
  KpiCategoryID: Scalars['String'];
  Goal: Scalars['String'];
  Objective: Scalars['String'];
  PercentageComplete: Scalars['Float'];
};

export type OvertimeCalculationItemOutput = {
  __typename?: 'OvertimeCalculationItemOutput';
  OTData?: Maybe<Scalars['JSON']>;
};

export type OvertimeClaimTableEntity = InterfaceBase & {
  __typename?: 'OvertimeClaimTableEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeClaimTableID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  OTClaimTableType?: Maybe<OtClaimTableType>;
  OTClaimData?: Maybe<Scalars['JSON']>;
  ReplacementLeaveData?: Maybe<Scalars['JSON']>;
  Company?: Maybe<CompanyEntity>;
};

export type OvertimeClaimTableInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  OTClaimData?: Maybe<Array<OtClaimEntitlementInput>>;
  ReplacementLeaveData?: Maybe<Array<ReplacementLeaveClaimTypeInput>>;
  OTClaimTableType: OtClaimTableType;
};

export type OvertimeDetails = {
  __typename?: 'OvertimeDetails';
  ApprovalStatus?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type OvertimeEntity = InterfaceBase & {
  __typename?: 'OvertimeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus: OvertimeStatus;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Approver?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  Duration?: Maybe<Scalars['Float']>;
  otReason?: Maybe<OvertimeReasonEntity>;
  Employee?: Maybe<EmployeeEntity>;
  OvertimeHistory?: Maybe<Array<OvertimeEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  Documents?: Maybe<Array<DocumentsEntity>>;
  ShiftID?: Maybe<Scalars['String']>;
  Shift?: Maybe<ShiftEntity>;
  BatchOvertimeID?: Maybe<Scalars['String']>;
  BatchOvertime?: Maybe<BatchOvertimeEntity>;
};

export type OvertimeInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus?: Maybe<OvertimeStatus>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  BatchOvertimeID?: Maybe<Scalars['String']>;
};

export type OvertimeOutput = {
  __typename?: 'OvertimeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus?: Maybe<OvertimeStatus>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Record?: Maybe<Scalars['String']>;
};

export type OvertimePolicyEntity = InterfaceBase & {
  __typename?: 'OvertimePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  CutOffDate?: Maybe<Scalars['Float']>;
  EnableAutoSubmission?: Maybe<Scalars['Boolean']>;
  TimeOff?: Maybe<Scalars['Boolean']>;
  HourlyLeave?: Maybe<Scalars['Boolean']>;
  HalfDayLeave?: Maybe<Scalars['Boolean']>;
  LateIn?: Maybe<Scalars['Boolean']>;
  EarlyOut?: Maybe<Scalars['Boolean']>;
  ShiftBreakLateIn?: Maybe<Scalars['Boolean']>;
  ShiftBreakEarlyOut?: Maybe<Scalars['Boolean']>;
  OvertimeRounding?: Maybe<Scalars['Boolean']>;
  AllowBackdatedOvertime?: Maybe<Scalars['Boolean']>;
  BackdatedOTMethod?: Maybe<BackdatedOtMethod>;
  BackdatedOTDay?: Maybe<Scalars['Float']>;
  EnableMaxOvertimeLimit?: Maybe<Scalars['Boolean']>;
  MaxOTHour?: Maybe<Scalars['Float']>;
  OvertimeClaimTable?: Maybe<Scalars['String']>;
  OvertimeClaimTableObj?: Maybe<OvertimeClaimTableEntity>;
  ExceedOvertimeClaimTable?: Maybe<Scalars['String']>;
  ExceedOvertimeClaimTableObj?: Maybe<OvertimeClaimTableEntity>;
  OTLimitDayType?: Maybe<Scalars['JSON']>;
  AllowExceedOvertimeLimit?: Maybe<Scalars['Boolean']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  otRounding?: Maybe<OtRoundingEntity>;
};

export type OvertimePolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  OvertimeClaimTable?: Maybe<Scalars['String']>;
  ExceedOvertimeClaimTable?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CutOffDate?: Maybe<Scalars['Float']>;
  EnableAutoSubmission?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TimeOff?: Maybe<Scalars['Boolean']>;
  HourlyLeave?: Maybe<Scalars['Boolean']>;
  HalfDayLeave?: Maybe<Scalars['Boolean']>;
  LateIn?: Maybe<Scalars['Boolean']>;
  EarlyOut?: Maybe<Scalars['Boolean']>;
  ShiftBreakLateIn?: Maybe<Scalars['Boolean']>;
  ShiftBreakEarlyOut?: Maybe<Scalars['Boolean']>;
  OvertimeRounding?: Maybe<Scalars['Boolean']>;
  AllowBackdatedOvertime?: Maybe<Scalars['Boolean']>;
  BackdatedOTMethod?: Maybe<BackdatedOtMethod>;
  BackdatedOTDay?: Maybe<Scalars['Float']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  EnableMaxOvertimeLimit?: Maybe<Scalars['Boolean']>;
  MaxOTHour?: Maybe<Scalars['Float']>;
  AllowExceedOvertimeLimit?: Maybe<Scalars['Boolean']>;
  OTLimitDayType?: Maybe<Array<Scalars['JSON']>>;
};

export type OvertimeReasonEntity = InterfaceBase & {
  __typename?: 'OvertimeReasonEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeReasonID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type OvertimeReasonInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
};

export type OvertimeRoundingSettingEntity = InterfaceBase & {
  __typename?: 'OvertimeRoundingSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeRoundingSettingID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
};

export type OvertimeRoundingSettingInput = {
  SubscriptionAccountID: Scalars['String'];
  OvertimeRoundingSettingID: Scalars['String'];
  Name: Scalars['String'];
};

export enum OvertimeStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Withdrawn = 'Withdrawn',
  Withdraw = 'Withdraw'
}

export type OvertimeTemplateEntity = InterfaceBase & {
  __typename?: 'OvertimeTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OvertimeTemplateID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimePolicyIDs: Scalars['String'];
  JobGradeIDs: Scalars['String'];
};

export type OvertimeTemplateInput = {
  OvertimeTemplateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OvertimePolicyIDs: Scalars['String'];
  JobGradeIDs: Scalars['String'];
};

export type PcbCalculationInput = {
  EmployeeID: Scalars['String'];
  AccumulatedGrossTaxable: Scalars['Float'];
  AccumulatedEPF: Scalars['Float'];
  AccumulatedPCB: Scalars['Float'];
  AccumulatedZakat: Scalars['Float'];
};

export type PeApprovedClaimDetail = {
  __typename?: 'PEApprovedClaimDetail';
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  ApprovalByID?: Maybe<Scalars['String']>;
  ApprovalByName?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type PeClaimDuplicateList = {
  __typename?: 'PEClaimDuplicateList';
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  DocFile?: Maybe<Scalars['String']>;
};

export type PeClaimPostedListingOutput = {
  __typename?: 'PEClaimPostedListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleName?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostedBy?: Maybe<Scalars['String']>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export type PeClaimPostingListingOutput = {
  __typename?: 'PEClaimPostingListingOutput';
  ProjectID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
  History?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  IsOTPModified?: Maybe<Scalars['Boolean']>;
  IsOTPDeleted?: Maybe<Scalars['Boolean']>;
  OTPDeletedBy?: Maybe<Scalars['String']>;
  OTPModifiedBy?: Maybe<Scalars['String']>;
  ClaimPostedListing?: Maybe<PeClaimPostedListingOutput>;
};

export type PeClaimPostingPeriodListingInput = {
  Year?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type PeClaimPostingPeriodListingOutput = {
  __typename?: 'PEClaimPostingPeriodListingOutput';
  ClaimCutOffPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  LatestPeriod?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ListingStatus?: Maybe<Scalars['String']>;
  ClaimPostingPeriodStatusList?: Maybe<Array<PeClaimPostingPeriodStatus>>;
};

export type PeClaimPostingPeriodStatus = {
  __typename?: 'PEClaimPostingPeriodStatus';
  ClaimPostingPeriodStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
};

export type PeClaimRecordsDetailHeaderOutput = {
  __typename?: 'PEClaimRecordsDetailHeaderOutput';
  ProjectID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  DepartmentName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  PostingStatus?: Maybe<Scalars['String']>;
  ClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Project?: Maybe<Array<ProjectMasterEntity>>;
};

export type PeglClaimRecordsDetail = {
  __typename?: 'PEGLClaimRecordsDetail';
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
  ClaimNo?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type PePostedClaimDetail = {
  __typename?: 'PEPostedClaimDetail';
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
};

export type PeVerifiedClaimDetail = {
  __typename?: 'PEVerifiedClaimDetail';
  FinanceApprovalStatus?: Maybe<ApprovalStatus>;
  FinanceAmount?: Maybe<Scalars['Float']>;
  FinanceRemark?: Maybe<Scalars['String']>;
  FinanceApprovalByID?: Maybe<Scalars['String']>;
  FinanceApprovalByName?: Maybe<Scalars['String']>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
};

export enum PayCategoryType {
  General = 'General',
  PayItem = 'Pay_Item'
}

export type PayGroupAssignmentEntity = InterfaceBaseV2 & {
  __typename?: 'PayGroupAssignmentEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  PayGroupAssignmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayGroupID: Scalars['String'];
  PayGroup?: Maybe<PayGroupEntity>;
};

export type PayGroupAssignmentInput = {
  CompanyID: Scalars['String'];
  PayGroupIDs: Array<Scalars['String']>;
  UserLogs: Array<UserLogInput>;
};

export type PayGroupEntity = InterfaceBaseV2 & {
  __typename?: 'PayGroupEntity';
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT: Scalars['DateTime'];
  CreatedBy: Scalars['String'];
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy: Scalars['String'];
  PayGroupID: Scalars['String'];
  Title: Scalars['String'];
  CutOffDate: Scalars['String'];
  PayGroupAssignment?: Maybe<Array<PayGroupAssignmentEntity>>;
  InUse: Scalars['Boolean'];
};

export type PayGroupInput = {
  PayGroupID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CutOffDate?: Maybe<Scalars['String']>;
  UserLogs: Array<UserLogInput>;
};

export type PayItemConditionEntity = InterfaceBase & {
  __typename?: 'PayItemConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  ConditionList?: Maybe<Scalars['String']>;
  IsForTemplateLibrary?: Maybe<Scalars['Boolean']>;
};

export type PayItemConditionInput = {
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  ConditionList?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsForTemplateLibrary?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayItemEmployeeDetailsList = {
  __typename?: 'PayItemEmployeeDetailsList';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
};

export type PayItemEmployeeDetailsOutput = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
};

export type PayItemEntity = {
  __typename?: 'PayItemEntity';
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayCode?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  EAPartFID?: Maybe<Scalars['String']>;
  EAPartFCategoryID?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  IsLHDN?: Maybe<Scalars['Boolean']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  IsEPFNonMonthly?: Maybe<Scalars['Boolean']>;
  IsSOCSO?: Maybe<Scalars['Boolean']>;
  IsAddRemuneration?: Maybe<Scalars['Boolean']>;
  IsEPFVE?: Maybe<Scalars['Boolean']>;
  IsBonus?: Maybe<Scalars['Boolean']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  EAFormSetup?: Maybe<EaFormSetupEntity>;
  PayItemCondition?: Maybe<PayItemConditionEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFEmployer?: Maybe<Scalars['Boolean']>;
  EPFEmployee?: Maybe<Scalars['Boolean']>;
  IsInUse: Scalars['Boolean'];
};

export type PayItemFilterInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayItemTypes?: Maybe<Array<Scalars['String']>>;
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
};

export type PayItemGlEntity = InterfaceBase & {
  __typename?: 'PayItemGLEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CompanyOrgUnitID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayItemsWithAmount?: Maybe<Scalars['JSON']>;
  Type?: Maybe<Scalars['String']>;
};

export type PayItemInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayCode?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  EAPartFID?: Maybe<Scalars['String']>;
  EAPartFCategoryID?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  IsLHDN?: Maybe<Scalars['Boolean']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  IsEPFNonMonthly?: Maybe<Scalars['Boolean']>;
  IsSOCSO?: Maybe<Scalars['Boolean']>;
  IsAddRemuneration?: Maybe<Scalars['Boolean']>;
  IsEPFVE?: Maybe<Scalars['Boolean']>;
  IsBonus?: Maybe<Scalars['Boolean']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogInput>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFEmployer?: Maybe<Scalars['Boolean']>;
  EPFEmployee?: Maybe<Scalars['Boolean']>;
};

export type PayItemListingByTlaOutput = {
  __typename?: 'PayItemListingByTLAOutput';
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayCode?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  EAPartFID?: Maybe<Scalars['String']>;
  EAPartFCategoryID?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  IsLHDN?: Maybe<Scalars['Boolean']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  IsEPFNonMonthly?: Maybe<Scalars['Boolean']>;
  IsSOCSO?: Maybe<Scalars['Boolean']>;
  IsAddRemuneration?: Maybe<Scalars['Boolean']>;
  IsEPFVE?: Maybe<Scalars['Boolean']>;
  IsBonus?: Maybe<Scalars['Boolean']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  EAFormSetup?: Maybe<EaFormSetupEntity>;
  PayItemCondition?: Maybe<PayItemConditionEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFEmployer?: Maybe<Scalars['Boolean']>;
  EPFEmployee?: Maybe<Scalars['Boolean']>;
  IsInUse: Scalars['Boolean'];
  EmployeeIds?: Maybe<Array<Scalars['String']>>;
};

export type PayItemMapping = {
  __typename?: 'PayItemMapping';
  ColumnID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
};

export type PayItemMappingInput = {
  ColumnID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
};

export type PayItemOutput = {
  __typename?: 'PayItemOutput';
  TransactionID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  PayPeriod?: Maybe<Scalars['DateTime']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
};

export type PayItemTransactionOutput = {
  __typename?: 'PayItemTransactionOutput';
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  TotalQuantity?: Maybe<Scalars['Float']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Employees?: Maybe<Array<EmployeeTransactionOutput>>;
  IsAllProcessed: Scalars['Boolean'];
};

export enum PayItemType {
  Earning = 'Earning',
  Deduction = 'Deduction',
  NonPayable = 'Non_Payable',
  Overtime = 'Overtime'
}

export type PayItemsWithAmountList = {
  __typename?: 'PayItemsWithAmountList';
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  EmployeeDetails?: Maybe<Array<PayItemEmployeeDetailsList>>;
};

export type PayItemsWithAmountOutput = {
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  EmployeeDetails?: Maybe<Array<PayItemEmployeeDetailsOutput>>;
};

export type PayPeriodEntity = InterfaceBase & {
  __typename?: 'PayPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ActualWorkDays?: Maybe<Scalars['Float']>;
};

export type PayPeriodInput = {
  PayrollCutOffDate?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CustomPayslipFormat?: Maybe<Scalars['String']>;
  CustomPayrollGlExportFormat?: Maybe<Scalars['String']>;
};

export type PayPeriodSelectionInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export enum PaymentMethod {
  Payroll = 'Payroll',
  BankTransfer = 'Bank_Transfer',
  Cash = 'Cash',
  Cheque = 'Cheque'
}

export type PayrollCalculationInput = {
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
  IsLastProceed: Scalars['Boolean'];
  IsForecasted?: Maybe<Scalars['Boolean']>;
  SequenceNo: Scalars['Float'];
  CompanyID: Scalars['String'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  IsIncludedSocso?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SalaryInfo?: Maybe<Scalars['JSON']>;
  PenaltyBreakdownInfo?: Maybe<Scalars['JSON']>;
  ParcelSupplementaryInfo?: Maybe<Scalars['JSON']>;
};

export type PayrollCalculationItemOutput = {
  __typename?: 'PayrollCalculationItemOutput';
  OneTimePayItemListing?: Maybe<Array<OneTimePayItemOutput>>;
  accPreviousMonthOutput?: Maybe<AccumulatedPreviousMonthOutput>;
  AccCurrentMonthPreviousPayrollCycle?: Maybe<AccCurrentMonthPreviousPayrollCycleOutput>;
  EmployeeID?: Maybe<Scalars['String']>;
  NonResidentNonPayable?: Maybe<Scalars['Float']>;
  EmployeeName?: Maybe<Scalars['String']>;
  ResidentStatus?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  totalDu?: Maybe<Scalars['Float']>;
  totalSu?: Maybe<Scalars['Float']>;
  total100UnderAge?: Maybe<Scalars['Float']>;
  total50UnderAge?: Maybe<Scalars['Float']>;
  total100Certificate?: Maybe<Scalars['Float']>;
  total50Certificate?: Maybe<Scalars['Float']>;
  total100Diploma?: Maybe<Scalars['Float']>;
  total50Diploma?: Maybe<Scalars['Float']>;
  total100Disabled?: Maybe<Scalars['Float']>;
  total50Disabled?: Maybe<Scalars['Float']>;
  total100DisabledDiploma?: Maybe<Scalars['Float']>;
  total50DisabledDiploma?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ActualWorkday?: Maybe<Scalars['Float']>;
  RequiredWorkDay?: Maybe<Scalars['Float']>;
  EPFRoundingAmount?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployeeEPFRate?: Maybe<Scalars['Float']>;
  EmployerEPFRate?: Maybe<Scalars['Float']>;
  EmployeeEPFVERate?: Maybe<Scalars['Float']>;
  EmployerEPFVERate?: Maybe<Scalars['Float']>;
  TotalChargableEPFEmployer?: Maybe<Scalars['Float']>;
  EmployeeEPF2?: Maybe<Scalars['Float']>;
  TaxableEPF?: Maybe<Scalars['Float']>;
  TaxableEPF2?: Maybe<Scalars['Float']>;
  EmployerEPFVE?: Maybe<Scalars['Float']>;
  EmployeeEPFVE?: Maybe<Scalars['Float']>;
  TotalEmployerEPFRate?: Maybe<Scalars['Float']>;
  TotalEmployeeEPFRate?: Maybe<Scalars['Float']>;
  SocsoRequirement?: Maybe<Scalars['String']>;
  EISRequirement?: Maybe<Scalars['String']>;
  SocsoRule?: Maybe<Scalars['String']>;
  EmployerSocso?: Maybe<Scalars['Float']>;
  EmployeeSocso?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  Tax1?: Maybe<Scalars['Float']>;
  Tax2?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  TaxZakat?: Maybe<Scalars['Float']>;
  IsZakatPercent?: Maybe<Scalars['Boolean']>;
  EmployerHRDF?: Maybe<Scalars['Float']>;
  ContributeHRDF?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  StandardWages?: Maybe<Scalars['Float']>;
  SalaryType?: Maybe<Scalars['String']>;
  TaxBasicSalary?: Maybe<Scalars['Float']>;
  GrossSalary?: Maybe<Scalars['Float']>;
  TotalChargableTax?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  NetSalary?: Maybe<Scalars['Float']>;
  TotalEarningPayItem?: Maybe<Scalars['Float']>;
  TotalDeductionPayItem?: Maybe<Scalars['Float']>;
  TotalNonPayablePayItem?: Maybe<Scalars['Float']>;
  TotalBIKAmount?: Maybe<Scalars['Float']>;
  TotalVOLAAmount?: Maybe<Scalars['Float']>;
  TotalBIKVOLA?: Maybe<Scalars['Float']>;
  TotalChargableTax1?: Maybe<Scalars['Float']>;
  TotalChargableTax2?: Maybe<Scalars['Float']>;
  TotalChargableAddRemuneration?: Maybe<Scalars['Float']>;
  TotalChargableEPF?: Maybe<Scalars['Float']>;
  EPFRoundingAmountEmployee?: Maybe<Scalars['Float']>;
  EPFRoundingAmountEmployer?: Maybe<Scalars['Float']>;
  TotalChargableAdditionalRemunerationEPF: Scalars['Float'];
  TotalChargableNormalRemunerationEPF: Scalars['Float'];
  CurrentMonthAdditionalRemunerationEPFEmployer?: Maybe<Scalars['Float']>;
  CurrentMonthAdditionalRemunerationEPFEmployee?: Maybe<Scalars['Float']>;
  TotalChargableEPFVE?: Maybe<Scalars['Float']>;
  TotalChargableSocso?: Maybe<Scalars['Float']>;
  TotalChargableEIS?: Maybe<Scalars['Float']>;
  TotalChargableHRDF?: Maybe<Scalars['Float']>;
  TotalChargableBonus?: Maybe<Scalars['Float']>;
  TaxRelief?: Maybe<Scalars['Float']>;
  MTDCalc?: Maybe<MtdOutput>;
  CurrentMonthNormalRemunerationEPF?: Maybe<Scalars['Float']>;
  CurrentMonthAdditionalRemunerationEPF?: Maybe<Scalars['Float']>;
  ProcessLog?: Maybe<Scalars['String']>;
  ContributeTax?: Maybe<Scalars['Boolean']>;
  AgencyName: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  VehicleType?: Maybe<Scalars['String']>;
  NormalD2DSmall?: Maybe<Scalars['Float']>;
  NormalD2DLarge?: Maybe<Scalars['Float']>;
  NormalD2DBulky?: Maybe<Scalars['Float']>;
  PublicD2DSmall?: Maybe<Scalars['Float']>;
  PublicD2DLarge?: Maybe<Scalars['Float']>;
  PublicD2DBulky?: Maybe<Scalars['Float']>;
  CampaignD2DSmall?: Maybe<Scalars['Float']>;
  CampaignD2DLarge?: Maybe<Scalars['Float']>;
  CampaignD2DBulky?: Maybe<Scalars['Float']>;
  NormalBSCSmall?: Maybe<Scalars['Float']>;
  NormalBSCLarge?: Maybe<Scalars['Float']>;
  NormalBSCBulky?: Maybe<Scalars['Float']>;
  PublicBSCSmall?: Maybe<Scalars['Float']>;
  PublicBSCLarge?: Maybe<Scalars['Float']>;
  PublicBSCBulky?: Maybe<Scalars['Float']>;
  CampaignBSCSmall?: Maybe<Scalars['Float']>;
  CampaignBSCLarge?: Maybe<Scalars['Float']>;
  CampaignBSCBulky?: Maybe<Scalars['Float']>;
  PenaltyCharges?: Maybe<Scalars['JSON']>;
  PayGroupID?: Maybe<Scalars['String']>;
  PayGroup?: Maybe<Scalars['String']>;
  StartPayPeriod: Scalars['DateTime'];
  EndPayPeriod: Scalars['DateTime'];
};

export type PayrollCalculationOutput = {
  __typename?: 'PayrollCalculationOutput';
  Result?: Maybe<Scalars['Boolean']>;
  TotalRecord?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Listing?: Maybe<Array<PayrollCalculationItemOutput>>;
  Entity?: Maybe<Array<PayrollHeaderEntity>>;
};

export type PayrollConditionListInput = {
  Formula: Scalars['JSON'];
  Criteria: Array<PayrollCriteriaListInput>;
  Description: Scalars['String'];
  UserLogs?: Maybe<Array<UserLogInput>>;
};

export type PayrollCriteriaListInput = {
  TypeID: Scalars['String'];
  operatorValue: Scalars['String'];
  valueVal: Array<Scalars['JSON']>;
  valueInOut: Scalars['String'];
  checkValue: Array<Scalars['JSON']>;
  Description: Scalars['String'];
};

export type PayrollCycleEntity = InterfaceBase & {
  __typename?: 'PayrollCycleEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCycleID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  PayrollCyclePeriod?: Maybe<Array<PayrollCyclePeriodEntity>>;
};

export type PayrollCycleInput = {
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollCyclePeriodEntity = InterfaceBase & {
  __typename?: 'PayrollCyclePeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCyclePeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  SequenceNo: Scalars['Float'];
  PayrollCycleStatus: PayrollCycleStatus;
  IsLastProcess: Scalars['Boolean'];
  IsInitiated?: Maybe<Scalars['Boolean']>;
  IsDeferred?: Maybe<Scalars['Boolean']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  IsProccess?: Maybe<Scalars['Boolean']>;
};

export type PayrollCyclePeriodInput = {
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  SequenceNo: Scalars['Float'];
  PayrollCycleStatus: PayrollCycleStatus;
  IsLastProcess: Scalars['Boolean'];
  IsDeferred?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogInput>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollCyclePeriodListingOutput = {
  __typename?: 'PayrollCyclePeriodListingOutput';
  PayrollCycleID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  PayrollCycleStatus?: Maybe<Scalars['String']>;
  IsLastProcess?: Maybe<Scalars['Boolean']>;
  RowNumber?: Maybe<Scalars['Float']>;
};

export type PayrollCycleSelectionInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export enum PayrollCycleStatus {
  Calculated = 'Calculated',
  Locked = 'Locked',
  Unlock = 'Unlock',
  NoRecord = 'NoRecord'
}

export type PayrollDetailEntity = InterfaceBase & {
  __typename?: 'PayrollDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollDetailID: Scalars['String'];
  PayrollHeaderID: Scalars['String'];
  PayCategoryType: PayCategoryType;
  PayItemID: Scalars['String'];
  Percentage: Scalars['Float'];
  Amount: Scalars['String'];
  Quantity: Scalars['String'];
  ExemptNormalRemunerationAmount: Scalars['String'];
  ExemptAdditionalRemunerationAmount: Scalars['String'];
  TotalAmount: Scalars['String'];
  AdjustAmount: Scalars['String'];
  PayrollHeader: PayrollHeaderEntity;
  PayItem: PayItemEntity;
};

export type PayrollDetailInput = {
  PayrollHeaderID: Scalars['String'];
  PayCategoryType: PayCategoryType;
  PayItemID: Scalars['String'];
  Percentage?: Maybe<Scalars['Float']>;
  Amount: Scalars['String'];
  AdjustAmount: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollEmployeeListingOutput = {
  __typename?: 'PayrollEmployeeListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  EmployeeNoName?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  EmployeeStatutoryID?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<Scalars['String']>;
  ResidentStatus?: Maybe<Scalars['String']>;
  EmployeeStatutoryCreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeStatutoryModifiedDT?: Maybe<Scalars['DateTime']>;
  EmployeeBankInfoID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<Scalars['String']>;
  Bank?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  CareerLogID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  CareerLogLastWorkingDate?: Maybe<Scalars['DateTime']>;
  CareerLogLastEmploymentDate?: Maybe<Scalars['DateTime']>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  FromEmploymentType?: Maybe<Scalars['String']>;
  ToEmploymentType?: Maybe<Scalars['String']>;
  ExitReasonID?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<Scalars['String']>;
  PersonnelParameterType?: Maybe<Scalars['String']>;
  PayPeriodIDs?: Maybe<Scalars['String']>;
  PayrollCycleIDs?: Maybe<Scalars['String']>;
};

export type PayrollFixedAllowanceInput = {
  CompanyID?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  PayItemTypes?: Maybe<Array<Scalars['String']>>;
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
};

export type PayrollGlExportEntity = InterfaceBase & {
  __typename?: 'PayrollGLExportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  GLCode?: Maybe<Scalars['String']>;
  FinancialYear?: Maybe<Scalars['String']>;
  DR?: Maybe<Scalars['String']>;
  CR?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['String']>;
  EntityCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  PayCycle?: Maybe<Scalars['String']>;
  PayPeriod?: Maybe<Scalars['String']>;
  IsRequestedToLock?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
};

export type PayrollHeaderEntity = InterfaceBase & {
  __typename?: 'PayrollHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollHeaderID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  ActualWorkday: Scalars['Float'];
  RequiredWorkDay: Scalars['Float'];
  ProcessLog?: Maybe<Scalars['String']>;
  NetPay?: Maybe<Scalars['String']>;
  TotalGross: Scalars['String'];
  BasicSalary: Scalars['String'];
  TotalDeduction: Scalars['String'];
  TaxRelief: Scalars['String'];
  TotalChargableTax: Scalars['String'];
  TotalChargableAddRemuneration: Scalars['String'];
  TotalChargableNormalRemunerationEPF: Scalars['String'];
  TotalChargableAdditionalRemunerationEPF: Scalars['String'];
  TotalChargableEPF: Scalars['String'];
  TotalChargableEPFVE: Scalars['String'];
  TotalChargableSocso: Scalars['String'];
  TotalChargableEIS: Scalars['String'];
  TotalChargableHRDF: Scalars['String'];
  TotalChargableBonus: Scalars['String'];
  EPFPaid: Scalars['String'];
  EPFPaidByAdditionalRemuneration: Scalars['String'];
  EPFPaidByNormalRemunerationPaid: Scalars['String'];
  EPFVEPaid: Scalars['String'];
  SOCSOPaid: Scalars['String'];
  EISPaid: Scalars['String'];
  ZAKATPaid: Scalars['String'];
  TAXPaid: Scalars['String'];
  TotalBIK: Scalars['String'];
  TotalVOLA: Scalars['String'];
  AgencyName: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  VehicleType?: Maybe<Scalars['String']>;
  NormalD2DSmall?: Maybe<Scalars['String']>;
  NormalD2DLarge?: Maybe<Scalars['String']>;
  NormalD2DBulky?: Maybe<Scalars['String']>;
  PublicD2DSmall?: Maybe<Scalars['String']>;
  PublicD2DLarge?: Maybe<Scalars['String']>;
  PublicD2DBulky?: Maybe<Scalars['String']>;
  CampaignD2DSmall?: Maybe<Scalars['String']>;
  CampaignD2DLarge?: Maybe<Scalars['String']>;
  CampaignD2DBulky?: Maybe<Scalars['String']>;
  NormalBSCSmall?: Maybe<Scalars['String']>;
  NormalBSCLarge?: Maybe<Scalars['String']>;
  NormalBSCBulky?: Maybe<Scalars['String']>;
  PublicBSCSmall?: Maybe<Scalars['String']>;
  PublicBSCLarge?: Maybe<Scalars['String']>;
  PublicBSCBulky?: Maybe<Scalars['String']>;
  CampaignBSCSmall?: Maybe<Scalars['String']>;
  CampaignBSCLarge?: Maybe<Scalars['String']>;
  CampaignBSCBulky?: Maybe<Scalars['String']>;
  PenaltyCharges?: Maybe<Scalars['JSON']>;
  PayrollDetails: Array<PayrollDetailEntity>;
  PayGroupID?: Maybe<Scalars['String']>;
  StartPayPeriod?: Maybe<Scalars['DateTime']>;
  EndPayPeriod?: Maybe<Scalars['DateTime']>;
  Islocked?: Maybe<Scalars['Boolean']>;
};

export type PayrollHeaderInput = {
  EmployeeID: Scalars['String'];
  PayrollCyclePeriodID: Scalars['String'];
  ActualWorkday: Scalars['Float'];
  RequiredWorkDay: Scalars['Float'];
  ProcessLog?: Maybe<Scalars['String']>;
  NetPay: Scalars['String'];
  TotalGross: Scalars['String'];
  TotalDeduction: Scalars['String'];
  NormalRemn: Scalars['String'];
  AdditionalRemn: Scalars['String'];
  ChargeSOCSO: Scalars['String'];
  ChargeEPF: Scalars['String'];
  ChargeEIS: Scalars['String'];
  ChargeZAKAT: Scalars['String'];
  TaxRelief: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollHomeInfoLinesOutput = {
  __typename?: 'PayrollHomeInfoLinesOutput';
  OutstandingRecord?: Maybe<Scalars['Float']>;
  NewEmpPending?: Maybe<Scalars['Float']>;
  NoOfEmployee?: Maybe<Scalars['Float']>;
  Entitled?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Float']>;
  NoOfRecords?: Maybe<Scalars['Float']>;
  NoOfRecords1?: Maybe<Scalars['Float']>;
  TotalRecordsFixed?: Maybe<Scalars['Float']>;
  DeductionRecords?: Maybe<Scalars['Float']>;
  DeductionRecordsMonthly?: Maybe<Scalars['Float']>;
  EarningRecords?: Maybe<Scalars['Float']>;
  AllowanceRecordsMonthly?: Maybe<Scalars['Float']>;
  NonpayableRecords?: Maybe<Scalars['Float']>;
  NonpayableRecordsMonthly?: Maybe<Scalars['Float']>;
  OvertimeRecords?: Maybe<Scalars['Float']>;
  OvertimeRecordsMonthly?: Maybe<Scalars['Float']>;
  PayrollInitiateDate?: Maybe<Scalars['String']>;
  MthTotal?: Maybe<Scalars['Float']>;
  MthPromoTotal?: Maybe<Scalars['Float']>;
  PayrollFreeze?: Maybe<Scalars['Float']>;
  ProcessDate?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollDate?: Maybe<Scalars['DateTime']>;
  PayrollGross?: Maybe<Scalars['Float']>;
  PayrollNetPay?: Maybe<Scalars['Float']>;
  ClosingDate?: Maybe<Scalars['DateTime']>;
  ClosingModifiedBy?: Maybe<Scalars['String']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  PublishedModifiedBy?: Maybe<Scalars['String']>;
  PublishedEADate?: Maybe<Scalars['DateTime']>;
  PublishedEAModifiedBy?: Maybe<Scalars['String']>;
  PCBDate?: Maybe<Scalars['DateTime']>;
  GLDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollProcessEmployeeListingOutput = {
  __typename?: 'PayrollProcessEmployeeListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  Status?: Maybe<Status>;
  IsChecked?: Maybe<Scalars['Boolean']>;
  PayGroupID?: Maybe<Scalars['String']>;
};

export type PayrollProcessPayItemListingOutput = {
  __typename?: 'PayrollProcessPayItemListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  EarningPayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  DeductionPayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  NonPayablePayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  OvertimePayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
};

export type PayrollProcessPayItemOutput = {
  __typename?: 'PayrollProcessPayItemOutput';
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
};

export type PayrollProcessPayrollCycleListingOutput = {
  __typename?: 'PayrollProcessPayrollCycleListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  LastProcessedDate?: Maybe<Scalars['DateTime']>;
  LastInitiatedDate?: Maybe<Scalars['DateTime']>;
  LastInitiatedBy?: Maybe<Scalars['String']>;
  PayrollProcessStatus?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  ShowDelete?: Maybe<Scalars['Boolean']>;
  TotalRecords?: Maybe<Scalars['Float']>;
  PayrollCyclePeriodID?: Maybe<Scalars['String']>;
  IsLastProcess?: Maybe<Scalars['Boolean']>;
  IsInitiated?: Maybe<Scalars['Boolean']>;
  IsProjectGLProcessed?: Maybe<Scalars['Boolean']>;
  LastProjectGLProcessedDate?: Maybe<Scalars['DateTime']>;
  IsPayrollGLProcessed?: Maybe<Scalars['Boolean']>;
  LastPayrollGLProcessedDate?: Maybe<Scalars['DateTime']>;
};

export type PayrollProcessPeriodListingOutput = {
  __typename?: 'PayrollProcessPeriodListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LastInitiatedBy?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  LastProcessedDate?: Maybe<Scalars['DateTime']>;
  LastInitiatedDate?: Maybe<Scalars['DateTime']>;
  PayrollProcessStatus?: Maybe<Scalars['String']>;
  TotalNetPay?: Maybe<Scalars['Float']>;
  TotalEmployee?: Maybe<Scalars['Float']>;
  TotalPayrollCyclePeriodSetting?: Maybe<Scalars['Float']>;
  LastPayrollGLProcess?: Maybe<Scalars['DateTime']>;
};

export type PayrollProcessPeriodListingStatusOutput = {
  __typename?: 'PayrollProcessPeriodListingStatusOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCycleStatus?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  TotalLastProcess?: Maybe<Scalars['Float']>;
};

export type PayrollReportEntity = InterfaceBase & {
  __typename?: 'PayrollReportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollReportID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollData?: Maybe<Scalars['String']>;
};

export type PayrollReportOutput = {
  __typename?: 'PayrollReportOutput';
  PayrollReportID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
};

export type PayrollSnapshotEntity = InterfaceBase & {
  __typename?: 'PayrollSnapshotEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  PayrollHeaderID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  PaymentMethod?: Maybe<PaymentMethod>;
  BankName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  DisabledIndividual?: Maybe<Scalars['Boolean']>;
  DisabledSpouse?: Maybe<Scalars['Boolean']>;
  ChildEduCountUnder18Perc100?: Maybe<Scalars['Float']>;
  ChildEduCountUnder18Perc50?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc100?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc50?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc100?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc50?: Maybe<Scalars['Float']>;
  DisabledChildCount?: Maybe<Scalars['Float']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['Float']>;
  DisabledChildRateDiplomaDigree?: Maybe<Scalars['Float']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatPercentage?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  BTCountryCode?: Maybe<Scalars['String']>;
  NonResident?: Maybe<Scalars['Boolean']>;
};

export type PayrollSnapshotOutput = {
  __typename?: 'PayrollSnapshotOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  PayrollHeaderID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  PaymentMethod?: Maybe<PaymentMethod>;
  BankName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  DisabledIndividual?: Maybe<Scalars['Boolean']>;
  DisabledSpouse?: Maybe<Scalars['Boolean']>;
  DisabledChildCount?: Maybe<Scalars['Float']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['Float']>;
  ChildEduCountUnder18Perc100?: Maybe<Scalars['Float']>;
  ChildEduCountUnder18Perc50?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc100?: Maybe<Scalars['Float']>;
  ChildEduCountCertMatricPerc50?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc100?: Maybe<Scalars['Float']>;
  ChildEduCountDiplomaDigreePerc50?: Maybe<Scalars['Float']>;
  DisabledChildRateDiplomaDigree?: Maybe<Scalars['Float']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatPercentage?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  BTCountryCode?: Maybe<Scalars['String']>;
  NonResident?: Maybe<Scalars['Boolean']>;
};

export type PayrollSummmaryListingOutput = {
  __typename?: 'PayrollSummmaryListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  NetPay?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
};

export type PenaltyBreakdownInfo = {
  __typename?: 'PenaltyBreakdownInfo';
  ColumnID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
};

export type PenaltyBreakdownInfoInput = {
  ColumnID?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
};

export type PerformanceGroupMatrixEntity = InterfaceBase & {
  __typename?: 'PerformanceGroupMatrixEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  GroupType: PerformanceGroupType;
  GroupID: Scalars['String'];
  ConditionList: Array<Scalars['JSON']>;
  ReviewGroup?: Maybe<ReviewGroupEntity>;
  RewardGroup?: Maybe<RewardGroupEntity>;
  TotalCriteria: Scalars['Float'];
  CriteriaDesc: Scalars['String'];
};

export type PerformanceGroupMatrixInput = {
  ID?: Maybe<Scalars['String']>;
  GroupType: PerformanceGroupType;
  GroupID: Scalars['String'];
  ConditionList?: Maybe<Array<ConditionListInput>>;
};

export enum PerformanceGroupType {
  Review = 'Review',
  Reward = 'Reward'
}

export type PerformanceManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum PerformanceManagementDelegation {
  KpiProcessApproval = 'KPI_Process_Approval',
  ReviewProcessApproval = 'Review_Process_Approval'
}

export type PeriodicAllowancePolicyConditionEntity = InterfaceBase & {
  __typename?: 'PeriodicAllowancePolicyConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Condition: Scalars['String'];
  Value: Scalars['String'];
  MonthlyAllowanceID: Scalars['String'];
  MonthlyAllowance?: Maybe<MonthlyAllowancePolicyEntity>;
};

export type PeriodicAllowancePolicyConditionInput = {
  Condition: Scalars['String'];
  Value: Scalars['String'];
  CompanyID: Scalars['String'];
  MonthlyAllowanceID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};

export type PeriodicAllowanceTypeEntity = InterfaceBase & {
  __typename?: 'PeriodicAllowanceTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodicAllowanceTypeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type PeriodicAllowanceTypeInput = {
  PeriodicAllowanceTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum PeriodicClaimType {
  Allowance = 'Allowance',
  Deduction = 'Deduction'
}

export type PeriodicDeductionInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  PeriodicCycle: Scalars['Float'];
  PeriodicCutoff: Scalars['Float'];
  PeriodicEffectiveDate: Scalars['DateTime'];
  PeriodicAllowanceTypeID: Scalars['String'];
};

export type PeriodicDeductionPolicyConditionEntity = InterfaceBase & {
  __typename?: 'PeriodicDeductionPolicyConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Condition: Scalars['String'];
  Value: Scalars['String'];
  PeriodicDeductionID: Scalars['String'];
  PeriodicDeduction?: Maybe<PeriodicDeductionPolicyEntity>;
};

export type PeriodicDeductionPolicyConditionInput = {
  Condition: Scalars['String'];
  Value: Scalars['String'];
  CompanyID: Scalars['String'];
  PeriodicDeductionID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};

export type PeriodicDeductionPolicyEntity = InterfaceBase & {
  __typename?: 'PeriodicDeductionPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PeriodicDeductionID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PeriodicCycle?: Maybe<Scalars['Float']>;
  PeriodicCutoff?: Maybe<Scalars['Float']>;
  PeriodicEffectiveDate?: Maybe<Scalars['DateTime']>;
  PeriodicAllowanceTypeID?: Maybe<Scalars['String']>;
  PeriodicDeductionPolicyCondition?: Maybe<Array<PeriodicDeductionPolicyConditionEntity>>;
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
};

export type PersonnelCertificateEntity = InterfaceBase & {
  __typename?: 'PersonnelCertificateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelCertificateID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IssueDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  RegNo?: Maybe<Scalars['String']>;
  CertificateUrl?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type PersonnelCertificateInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IssueDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  RegNo?: Maybe<Scalars['String']>;
  CertificateUrl?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentInput?: Maybe<DocumentsInput>;
};

export type PersonnelEFileListingOutput = {
  __typename?: 'PersonnelEFileListingOutput';
  Employee?: Maybe<EmployeeEntity>;
  IsMultipleCompany?: Maybe<Scalars['Boolean']>;
  IsNotInUse?: Maybe<Scalars['Boolean']>;
};

export type PersonnelEducationEntity = InterfaceBase & {
  __typename?: 'PersonnelEducationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelEducationID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  EducationLevel?: Maybe<Scalars['String']>;
  EducationLevelID?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MajorID?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
  Grade?: Maybe<Scalars['String']>;
  GradeID?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  major_detail?: Maybe<Scalars['String']>;
};

export type PersonnelEducationInsertInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelEducationID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  EducationLevel?: Maybe<Scalars['String']>;
  EducationLevelID?: Maybe<Scalars['String']>;
  InstitutionName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
  Grade?: Maybe<Scalars['String']>;
  GradeID?: Maybe<Scalars['String']>;
  MajorID?: Maybe<Scalars['String']>;
  CGPA?: Maybe<Scalars['Float']>;
  major_detail?: Maybe<Scalars['String']>;
};

export type PersonnelHealthInfoEntity = InterfaceBase & {
  __typename?: 'PersonnelHealthInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelHealthInfoID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<BloodType>;
  DietaryPreference?: Maybe<DietaryPreference>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
  MedicalRecords?: Maybe<Array<MedicalRecordEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  VaccinationListSummary?: Maybe<Array<VaccinationListItemOutput>>;
};

export type PersonnelHealthInfoInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelHealthInfoID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  Height?: Maybe<Scalars['Float']>;
  Weight?: Maybe<Scalars['Float']>;
  BMI?: Maybe<Scalars['Float']>;
  BloodType?: Maybe<BloodType>;
  DietaryPreference?: Maybe<DietaryPreference>;
  IsSmoker?: Maybe<Scalars['Boolean']>;
  VaccinationList?: Maybe<Array<Scalars['JSON']>>;
};

export type PersonnelHomeInfoLinesOutput = {
  __typename?: 'PersonnelHomeInfoLinesOutput';
  EmpBySubsTotal?: Maybe<Scalars['Float']>;
  EmpTotal?: Maybe<Scalars['Float']>;
  MthNewEmpTotal?: Maybe<Scalars['Float']>;
  OverdueConfTotal?: Maybe<Scalars['Float']>;
  UpcomingConfTotal?: Maybe<Scalars['Float']>;
  MthProbExtTotal?: Maybe<Scalars['Float']>;
  MthContractDocExpTotal?: Maybe<Scalars['Float']>;
  MthPromoTotal?: Maybe<Scalars['Float']>;
  MthDemoTotal?: Maybe<Scalars['Float']>;
  MthSecondTotal?: Maybe<Scalars['Float']>;
  MthRedesTotal?: Maybe<Scalars['Float']>;
  MthIntTransTotal?: Maybe<Scalars['Float']>;
  MthIntCompTransTotal?: Maybe<Scalars['Float']>;
  MthTerminationTotal?: Maybe<Scalars['Float']>;
  MthRetireTotal?: Maybe<Scalars['Float']>;
  MthOffBoardTotal?: Maybe<Scalars['Float']>;
  InsSchemeTotal?: Maybe<Scalars['Float']>;
  DataChangesApplicationPendingTotal?: Maybe<Scalars['Float']>;
};

export type PersonnelJobHistoryEntity = InterfaceBase & {
  __typename?: 'PersonnelJobHistoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelJobHistoryID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  IsCurrentJob?: Maybe<Scalars['Boolean']>;
  CompanyAddress?: Maybe<Address2>;
  Employee?: Maybe<EmployeeEntity>;
};

export type PersonnelJobHistoryInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelJobHistoryID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  Specialization?: Maybe<Scalars['String']>;
  SpecializationID?: Maybe<Scalars['String']>;
  Industry?: Maybe<Scalars['String']>;
  IndustryID?: Maybe<Scalars['String']>;
  JobDescription?: Maybe<Scalars['String']>;
  IsCurrentJob?: Maybe<Scalars['Boolean']>;
  CompanyAddress?: Maybe<AddressInput2>;
};

export type PersonnelLanguageEntity = {
  __typename?: 'PersonnelLanguageEntity';
  PersonnelLanguageID: Scalars['String'];
  ContactID: Scalars['String'];
  LanguageID: Scalars['String'];
  Language: Scalars['String'];
  WrittenLevel?: Maybe<LanguageLevel>;
  ReadLevel?: Maybe<LanguageLevel>;
  SpokenLevel?: Maybe<LanguageLevel>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PersonnelLanguageInput = {
  PersonnelLanguageID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  LanguageID: Scalars['String'];
  Language: Scalars['String'];
  WrittenLevel?: Maybe<LanguageLevel>;
  ReadLevel?: Maybe<LanguageLevel>;
  SpokenLevel?: Maybe<LanguageLevel>;
  Status?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PersonnelManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum PersonnelManagementDelegation {
  DataChangesApproval = 'Data_Changes_Approval'
}

export type PersonnelMembershipEntity = {
  __typename?: 'PersonnelMembershipEntity';
  PersonnelMembershipID: Scalars['String'];
  ContactID: Scalars['String'];
  DateAdmitted: Scalars['DateTime'];
  ProfessionalInstitution: Scalars['String'];
  MembershipStatus: Scalars['String'];
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PersonnelMembershipInput = {
  PersonnelMembershipID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DateAdmitted: Scalars['DateTime'];
  ProfessionalInstitution: Scalars['String'];
  MembershipStatus: Scalars['String'];
  Status?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PersonnelParameterEntity = InterfaceBase & {
  __typename?: 'PersonnelParameterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelParameterID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Voluntary?: Maybe<Scalars['Boolean']>;
  PersonnelParameterType?: Maybe<PersonnelParameterType>;
};

export type PersonnelParameterInput = {
  PersonnelParameterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Voluntary?: Maybe<Scalars['Boolean']>;
  PersonnelParameterType?: Maybe<PersonnelParameterType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum PersonnelParameterType {
  ExitCompanyReason = 'Exit_Company_Reason',
  Salutation = 'Salutation',
  EducationLevel = 'Education_Level',
  Major = 'Major',
  Grade = 'Grade',
  StaffCategory = 'Staff_Category',
  Specialization = 'Specialization',
  Industry = 'Industry',
  Language = 'Language',
  DocumentType = 'Document_Type',
  VaccinationType = 'Vaccination_Type'
}

export type PersonnelReferenceEntity = InterfaceBase & {
  __typename?: 'PersonnelReferenceEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PersonnelReferenceID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Scalars['String']>;
  YearsKnown?: Maybe<Scalars['Float']>;
  JobDesignation?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  Contact?: Maybe<ContactEntity>;
};

export type PersonnelReferenceInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  PersonnelReferenceID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Scalars['String']>;
  YearsKnown?: Maybe<Scalars['Float']>;
  JobDesignation?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
};

export type PersonnelSecurityPolicyEntity = InterfaceBase & {
  __typename?: 'PersonnelSecurityPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  SecurityPolicy: PersonnelSecurityPolicyOption;
  HideNotInUse?: Maybe<Scalars['Boolean']>;
};

export type PersonnelSecurityPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SecurityPolicy: PersonnelSecurityPolicyOption;
  HideNotInUse?: Maybe<Scalars['Boolean']>;
};

export enum PersonnelSecurityPolicyOption {
  Default = 'Default',
  EnforceByCompany = 'Enforce_By_Company',
  EnforceByAccessPermission = 'Enforce_By_Access_Permission',
  HideNotInUseRecords = 'Hide_Not_In_Use_Records'
}

export type PersonnelTemplateLibraryAssignmentOutput = {
  __typename?: 'PersonnelTemplateLibraryAssignmentOutput';
  JobGrade?: Maybe<Array<JobGradeEntity>>;
  SkillSet?: Maybe<Array<SkillSetEntity>>;
  JobPosition?: Maybe<Array<JobDesignationEntity>>;
  InsuranceScheme?: Maybe<Array<InsuranceSchemeEntity>>;
  RecruitmentSource?: Maybe<Array<RecruitmentSourceEntity>>;
  UserDefine?: Maybe<Array<UserDefineEntity>>;
};

export type PlatformLeaveItemEntity = InterfaceBase & {
  __typename?: 'PlatformLeaveItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformLeaveItemID: Scalars['String'];
  PlatformLeaveTypeID: Scalars['String'];
  TotalDay: Scalars['Float'];
  LeaveFrequency: LeaveFrequency;
  CutOffDate: Scalars['DateTime'];
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay: Scalars['Float'];
  ResignCutOffDay: Scalars['Float'];
  MonthlyCutOffDay: Scalars['Float'];
  ShortNoticeType: ShortNoticeType;
  ShortNoticePeriod: Scalars['Float'];
  CFMethod: CfMethod;
  CFValue: Scalars['Float'];
  CFMonth: Scalars['Float'];
  IsOffDay: Scalars['Boolean'];
  IsRestDay: Scalars['Boolean'];
  IsPublicHoliday: Scalars['Boolean'];
  IsHalfDay: Scalars['Boolean'];
  IsAttachment: Scalars['Boolean'];
  IsRemarks: Scalars['Boolean'];
  IsRounding: Scalars['Boolean'];
  IsConfirmedStaff: Scalars['Boolean'];
};

export type PlatformLeaveItemInput = {
  PlatformLeaveTypeID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<Scalars['Float']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformLeaveTypeEntity = InterfaceBase & {
  __typename?: 'PlatformLeaveTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformLeaveTypeID: Scalars['String'];
  IntegratedLeaveTypeID: Scalars['String'];
  LeaveTypeCode: Scalars['String'];
  Description: Scalars['String'];
};

export type PlatformLeaveTypeInput = {
  IntegratedLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type PolicyOutput = {
  __typename?: 'PolicyOutput';
  ID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  Policy?: Maybe<Array<Scalars['JSON']>>;
};

export enum PolicyType {
  EmploymentConfirmation = 'EmploymentConfirmation',
  Transfer = 'Transfer',
  Exit = 'Exit',
  ShortNotice = 'ShortNotice',
  NoOfDayPerApplication = 'NoOfDayPerApplication',
  LeavePendingApproval = 'LeavePendingApproval',
  ClaimSubmissionAmount = 'ClaimSubmissionAmount',
  ClaimPendingApproval = 'ClaimPendingApproval',
  RequisitionAmount = 'RequisitionAmount',
  RequisitionPendingApproval = 'RequisitionPendingApproval'
}

export type PossibleAnswerInput = {
  possible_answer_id?: Maybe<Scalars['String']>;
  answer_label?: Maybe<Scalars['String']>;
  point?: Maybe<Scalars['Float']>;
  correct_answer?: Maybe<Scalars['Boolean']>;
};

export type PostClaimRecordsInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export type PostPeClaimRecordsInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
};

export enum PostStatus {
  Draft = 'Draft',
  Posted = 'Posted'
}

export type PostedClaimDetail = {
  __typename?: 'PostedClaimDetail';
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
};

export enum PrehiringStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Invited = 'Invited'
}

export type ProbationExtendedEmployeeOutput = {
  __typename?: 'ProbationExtendedEmployeeOutput';
  Employee?: Maybe<EmployeeEntity>;
  ProbationExtension?: Maybe<CareerLogEntity>;
};

export type ProcessAttendanceEntity = {
  __typename?: 'ProcessAttendanceEntity';
  ProcessAttendanceID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  IntergrationClockType?: Maybe<Scalars['String']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  AttendanceRecordID?: Maybe<Scalars['String']>;
  AttendanceRecord?: Maybe<AttendanceRecordEntity>;
};

export type ProcessSalaryEntity = {
  __typename?: 'ProcessSalaryEntity';
  ProcessSalaryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CareerLogID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  RepeatedCount?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  CareerLog?: Maybe<CareerLogEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
};

export type ProjectAccountAssignmentEntity = InterfaceBase & {
  __typename?: 'ProjectAccountAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ProjectAccountHeaderID?: Maybe<Scalars['String']>;
  ProjectAccountHeader?: Maybe<ProjectGlAccountMappingEntity>;
};

export type ProjectAccountAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ProjectAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ProjectClaimCcMappingListEntity = InterfaceBase & {
  __typename?: 'ProjectClaimCCMappingListEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  PeriodID?: Maybe<Scalars['String']>;
  PeriodList?: Maybe<ProjectClaimProcessingPeriodEntity>;
  ClaimItemsWithAmount?: Maybe<Scalars['JSON']>;
  Type?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountDetailInput = {
  ID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  ExpenseTypeID?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  StaffCategory?: Maybe<Scalars['String']>;
  ExpenseTypeName?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ProjectGLAccountHeaderID?: Maybe<Scalars['String']>;
  ClaimName?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountDetailList = {
  __typename?: 'ProjectClaimGLAccountDetailList';
  ID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  ExpenseTypeID?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  StaffCategory?: Maybe<Scalars['String']>;
  ExpenseTypeName?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ProjectGLAccountHeaderID?: Maybe<Scalars['String']>;
  ClaimName?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountDetailOutputEntity = InterfaceBase & {
  __typename?: 'ProjectClaimGLAccountDetailOutputEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  Header?: Maybe<Scalars['JSON']>;
  Details?: Maybe<Scalars['JSON']>;
  PeriodList?: Maybe<ProjectClaimProcessingPeriodEntity>;
  Type?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ProjectClaimGlAccountHeaderList = {
  __typename?: 'ProjectClaimGLAccountHeaderList';
  ID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ProjectClaimProcessingPeriodEntity = InterfaceBase & {
  __typename?: 'ProjectClaimProcessingPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  CCMappingDetails: Array<ProjectClaimCcMappingListEntity>;
  AccountDetails: Array<ProjectClaimGlAccountDetailOutputEntity>;
  Type?: Maybe<Scalars['String']>;
};

export type ProjectCostAllocationDetailEntity = InterfaceBase & {
  __typename?: 'ProjectCostAllocationDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectCostAllocationID: Scalars['String'];
  ProjectID: Scalars['String'];
  Allocation: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
  Project?: Maybe<ProjectMasterEntity>;
  ProjectCostAllocation?: Maybe<ProjectCostAllocationEntity>;
};

export type ProjectCostAllocationDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectCostAllocationID?: Maybe<Scalars['String']>;
  ProjectID: Scalars['String'];
  Allocation: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectCostAllocationEntity = InterfaceBase & {
  __typename?: 'ProjectCostAllocationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  SubscriptionAccountID: Scalars['String'];
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  ProjectCostAllocationDetail?: Maybe<Array<ProjectCostAllocationDetailEntity>>;
};

export type ProjectCostAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimDetailEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseClaimDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectExpenseClaimHeaderID: Scalars['String'];
  ProjectExpenseTypeID: Scalars['String'];
  ReceiptDate: Scalars['DateTime'];
  ReceiptNo: Scalars['String'];
  ClaimAmount: Scalars['Float'];
  ClaimRemark: Scalars['String'];
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID: Scalars['String'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID: Scalars['String'];
  OneTimePayID: Scalars['String'];
  Reference1?: Maybe<Scalars['String']>;
  Reference2?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Header?: Maybe<ProjectExpenseClaimHeaderEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  ProjectExpenseType?: Maybe<ProjectExpenseTypeEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  MultipleDocuments?: Maybe<Array<DocumentsEntity>>;
};

export type ProjectExpenseClaimDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID: Scalars['String'];
  ReceiptDate: Scalars['DateTime'];
  ReceiptNo: Scalars['String'];
  ClaimAmount: Scalars['Float'];
  ClaimRemark: Scalars['String'];
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApprovalStatus?: Maybe<ApprovalStatus>;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID: Scalars['String'];
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID: Scalars['String'];
  OneTimePayID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimDetails = {
  __typename?: 'ProjectExpenseClaimDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ClaimSubject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  TotalVerifiedAmount?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  ClaimDetailsListing?: Maybe<Array<ProjectExpenseClaimDetailEntity>>;
};

export type ProjectExpenseClaimGlExportEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseClaimGLExportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<Scalars['String']>;
  CostCenterCode?: Maybe<Scalars['String']>;
  CostCenterName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimID?: Maybe<Scalars['String']>;
  ClaimType?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['String']>;
  Credit?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  AccountDescription?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['String']>;
  EntityCode?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  IsRequestedToLock?: Maybe<Scalars['Boolean']>;
  FinancialYear?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimHeaderEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseClaimHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Subject: Scalars['String'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID: Scalars['String'];
  Project?: Maybe<ProjectMasterEntity>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  ClaimDetails: Array<ProjectExpenseClaimDetailEntity>;
  Approver?: Maybe<EmployeeEntity>;
};

export type ProjectExpenseClaimHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Subject: Scalars['String'];
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectExpenseClaimRecordsInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ProjectExpenseTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export type ProjectExpenseClaimRecordsItemInput = {
  ProjectExpenseClaimDetailsID?: Maybe<Scalars['String']>;
  ProjectExpenseTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  Document?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  VoiceInput?: Maybe<DocumentsInput>;
  DocInput?: Maybe<DocumentsInput>;
  DocInputs?: Maybe<Array<DocumentsInput>>;
  DocToRemove?: Maybe<Array<Scalars['String']>>;
  DefaultDocList?: Maybe<Array<Scalars['String']>>;
  DocumentID?: Maybe<Scalars['String']>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
};

export type ProjectExpenseClaimRecordsListing = {
  __typename?: 'ProjectExpenseClaimRecordsListing';
  ProjectID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimNo?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  CompletedVerificationDate?: Maybe<Scalars['DateTime']>;
  CompletedVerificationBy?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PostedBy?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  SubmitterName?: Maybe<Scalars['String']>;
  MyClaimDetails?: Maybe<Array<MyProjectExpenseClaimDetailOutput>>;
  History?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GLClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type ProjectExpenseHistoryListingOutput = {
  __typename?: 'ProjectExpenseHistoryListingOutput';
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimDetails?: Maybe<Array<ProjectExpenseClaimDetailEntity>>;
};

export type ProjectExpenseManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum ProjectExpenseManagementDelegation {
  ProjectExpenseSubmissionApproval = 'Project_Expense_Submission_Approval',
  ProjectExpenseCancellationApproval = 'Project_Expense_Cancellation_Approval'
}

export type ProjectExpenseToApproveListingOutput = {
  __typename?: 'ProjectExpenseToApproveListingOutput';
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  IsRejected?: Maybe<Scalars['Boolean']>;
  ClaimRecordsDetail?: Maybe<Array<PeglClaimRecordsDetail>>;
  ClaimHistory?: Maybe<Array<ProjectExpenseHistoryListingOutput>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
};

export type ProjectExpenseTypeEntity = InterfaceBase & {
  __typename?: 'ProjectExpenseTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectExpenseType: Scalars['String'];
  PaymentType: Scalars['String'];
  PayItemID?: Maybe<Scalars['String']>;
  ValidUntil: Scalars['DateTime'];
  IsRemark?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
  ProjectExpenseClaimDetail?: Maybe<Array<ProjectExpenseClaimDetailEntity>>;
  PayItem?: Maybe<PayItemEntity>;
};

export type ProjectExpenseTypeInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectExpenseType?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  ValidUntil?: Maybe<Scalars['DateTime']>;
  IsRemark?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsReceiptNo?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlAccountMappingDetailEntity = InterfaceBase & {
  __typename?: 'ProjectGLAccountMappingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectGLAccountMappingID: Scalars['String'];
  StaffCategoryID?: Maybe<Scalars['String']>;
  ExpenseTypeID: Scalars['String'];
  IsCentralized?: Maybe<Scalars['Boolean']>;
  GLName?: Maybe<Scalars['String']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Header?: Maybe<ProjectGlAccountMappingEntity>;
};

export type ProjectGlAccountMappingDetailInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectGLAccountMappingID?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  ExpenseTypeID?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  GLName?: Maybe<Scalars['String']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDesc?: Maybe<Scalars['String']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDesc?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlAccountMappingEntity = InterfaceBase & {
  __typename?: 'ProjectGLAccountMappingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Title: Scalars['String'];
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
  ProjectGLAccountMappingDetail?: Maybe<Array<ProjectGlAccountMappingDetailEntity>>;
  ProjectAccountAssignment?: Maybe<Array<ProjectAccountAssignmentEntity>>;
};

export type ProjectGlAccountMappingInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  IsStaffCategory?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlDetailsOutput = {
  ID?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDescription?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDescription?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlDetailsOutputList = {
  __typename?: 'ProjectGLDetailsOutputList';
  ID?: Maybe<Scalars['String']>;
  CreditAccount?: Maybe<Scalars['String']>;
  CreditAccountDescription?: Maybe<Scalars['String']>;
  DebitAccount?: Maybe<Scalars['String']>;
  DebitAccountDescription?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  GLName?: Maybe<Scalars['String']>;
  IsCentralized?: Maybe<Scalars['Boolean']>;
  IsCreditCC?: Maybe<Scalars['Boolean']>;
  IsDebitCC?: Maybe<Scalars['Boolean']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlHeaderList = {
  __typename?: 'ProjectGLHeaderList';
  ID?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectGlHeaderOutput = {
  ID?: Maybe<Scalars['String']>;
  EnableStaffCategory?: Maybe<Scalars['Boolean']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectMasterEntity = InterfaceBase & {
  __typename?: 'ProjectMasterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectName: Scalars['String'];
  CostCenter: Scalars['String'];
  CostCenterDescription: Scalars['String'];
  ValidUntil: Scalars['DateTime'];
  ProjectOwnerID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ProjectOwner?: Maybe<EmployeeEntity>;
  ProjectCostAllocationDetail?: Maybe<Array<ProjectCostAllocationDetailEntity>>;
  ProjectExpenseClaimHeader?: Maybe<Array<ProjectExpenseClaimHeaderEntity>>;
};

export type ProjectMasterInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectName?: Maybe<Scalars['String']>;
  CostCenter?: Maybe<Scalars['String']>;
  CostCenterDescription?: Maybe<Scalars['String']>;
  ValidUntil?: Maybe<Scalars['DateTime']>;
  ProjectOwnerID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ProjectTimesheetApproverAssignmentEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetApproverAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetPeriodID: Scalars['String'];
  EmployeeID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ProjectTimesheetPeriod?: Maybe<ProjectTimesheetPeriodEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type ProjectTimesheetApproverAssignmentInput = {
  ProjectTimesheetPeriodID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverAssignmentID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  PeriodFrom?: Maybe<Scalars['String']>;
  PeriodTo?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['JSON']>>;
  ApproverFilter?: Maybe<Array<Scalars['JSON']>>;
};

export type ProjectTimesheetApproverMasterEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetApproverMasterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  Name: Scalars['String'];
  EmailAddress: Scalars['String'];
  ClientName: Scalars['String'];
  Department: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  LastUpdatedDate?: Maybe<Scalars['DateTime']>;
  ConfirmationDT?: Maybe<Scalars['DateTime']>;
  ProjectTimesheetPeriod?: Maybe<Array<ProjectTimesheetPeriodEntity>>;
  ProjectTimesheetMonthlyRecord?: Maybe<Array<ProjectTimesheetMonthlyRecordEntity>>;
};

export type ProjectTimesheetApproverMasterInput = {
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  ClientName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
};

export type ProjectTimesheetCheckOverlapOutput = {
  __typename?: 'ProjectTimesheetCheckOverlapOutput';
  ApproverPeriodRangeOverlap?: Maybe<Scalars['String']>;
  EmployeeListingBySubscriptionAccount?: Maybe<Array<EmployeeEntity>>;
};

export type ProjectTimesheetDailyEntryEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetDailyEntryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
  EntryDate: Scalars['DateTime'];
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  BreakTime?: Maybe<Scalars['Float']>;
  TotalHours?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  Leave?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ProjectTimesheetMonthlyRecord?: Maybe<ProjectTimesheetMonthlyRecordEntity>;
};

export type ProjectTimesheetDailyEntryInput = {
  ProjectTimesheetDailyEntryID?: Maybe<Scalars['String']>;
  ProjectTimesheetMonthlyRecordID?: Maybe<Scalars['String']>;
  EntryDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  BreakTime?: Maybe<Scalars['Float']>;
  TotalHours?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  Leave?: Maybe<Scalars['String']>;
};

export type ProjectTimesheetMonthlyRecordEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetMonthlyRecordEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetApproverMasterID: Scalars['String'];
  CompanyID: Scalars['String'];
  ContactID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PeriodYearMonth: Scalars['DateTime'];
  ApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  SubscriptionAccountID: Scalars['String'];
  ProjectTimesheetApproverMaster?: Maybe<ProjectTimesheetApproverMasterEntity>;
  ProjectTimesheetDailyEntry?: Maybe<Array<ProjectTimesheetDailyEntryEntity>>;
};

export type ProjectTimesheetMonthlyRecordInput = {
  ID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  ApprovalLog?: Maybe<Array<ApprovalLog>>;
};

export type ProjectTimesheetPeriodEntity = InterfaceBase & {
  __typename?: 'ProjectTimesheetPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  ProjectTimesheetApproverMasterID: Scalars['String'];
  PeriodFrom: Scalars['String'];
  PeriodTo: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ApproverFilter?: Maybe<Array<Scalars['JSON']>>;
  ProjectTimesheetApproverMaster?: Maybe<ProjectTimesheetApproverMasterEntity>;
  ProjectTimesheetApproverAssignment?: Maybe<Array<ProjectTimesheetApproverAssignmentEntity>>;
  ProjectTimesheetMonthlyRecord?: Maybe<Array<ProjectTimesheetMonthlyRecordEntity>>;
  InUse: Scalars['Boolean'];
};

export type ProjectTimesheetPeriodListingOutput = {
  __typename?: 'ProjectTimesheetPeriodListingOutput';
  ProjectTimesheetMonthlyRecordID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  PeriodStatus?: Maybe<Scalars['String']>;
  PeriodFromDate?: Maybe<Scalars['DateTime']>;
  PeriodToDate?: Maybe<Scalars['DateTime']>;
  PeriodInfoline?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  isAssignedToApprover?: Maybe<Scalars['Boolean']>;
};

export type ProjectTimesheetReportForApproverInput = {
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Attachment?: Maybe<DocumentsInput>;
  EmployeeList?: Maybe<Array<Scalars['JSON']>>;
};

export type PublishEaEntity = InterfaceBase & {
  __typename?: 'PublishEAEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PublishEAID: Scalars['String'];
  CompanyID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type PublishEaInput = {
  CompanyID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PublishEaSingleEmployeeListingOutput = {
  __typename?: 'PublishEASingleEmployeeListingOutput';
  PublishEAID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  CompanyName?: Maybe<Scalars['String']>;
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PublishPayslipEntity = InterfaceBase & {
  __typename?: 'PublishPayslipEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PublishPaySlipID: Scalars['String'];
  CompanyID: Scalars['String'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayPeriod: PayPeriodEntity;
  PayrollCycle: PayrollCycleEntity;
};

export type PublishPayslipInput = {
  CompanyID: Scalars['String'];
  PayrollCyclePeriodID: Scalars['String'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum QuantitativeType {
  Value = 'Value',
  Unit = 'Unit',
  Date = 'Date',
  Percentage = 'Percentage'
}

export type Query = {
  __typename?: 'Query';
  TestLoader: Array<DutyRosterEntity>;
  getDutyRoster: Array<DutyRosterEntity>;
  DutyRosterInfoLine: Scalars['JSON'];
  DutyRosterEmployeeSum: Scalars['JSON'];
  DutyRosterDates: Scalars['JSON'];
  DutyRosterSummaryByEmployee: Scalars['JSON'];
  DutyRosterSummary: Scalars['JSON'];
  DutyRosterByExcel: Scalars['JSON'];
  getDutyRosterByEmployeeListV2: Scalars['JSON'];
  getDutyRosterByEmployee: Scalars['JSON'];
  getDutyRosterByEmployeeList: Scalars['JSON'];
  NotificationListing: Array<NotificationEntity>;
  NewsReadListing: Array<NewsReadEntity>;
  EmployeeCalendar?: Maybe<EmployeeCalendarOutput>;
  GetAttendanceRecordPairedData: Scalars['JSON'];
  GetAttendanceRecordPairedDataCount: Scalars['JSON'];
  GetFinalizeAnalysisDataByEmployee: Scalars['JSON'];
  GetFinalizeAnalysisData: Scalars['JSON'];
  GetFinalizeAnalysisDataCount: Scalars['JSON'];
  GetClockPairing: Scalars['String'];
  GetClockPairingRedirect: Scalars['String'];
  getAttenRecords: Array<AttendanceRecordEntity>;
  AttendanceRecordByHikvision: Scalars['JSON'];
  AttendanceRecordByHikvisionByCompany: Scalars['JSON'];
  getAttendanceRecord: Array<AttendanceRecordEntity>;
  getAttendanceRecordwithHikvision: Array<Scalars['JSON']>;
  AttendanceRecordSummary?: Maybe<Scalars['JSON']>;
  AttendanceRecordByExcel: Scalars['JSON'];
  AttendanceRecordListingByClockType: AttendanceRecordEntity;
  AttendanceRecordListing: Array<AttendanceRecordEntity>;
  AttendanceRecordByPeriod: Array<AttendanceRecordEntity>;
  getAttendanceRecordLastModified: Array<Scalars['DateTime']>;
  getAttendanceRecordTotalCount: Array<Scalars['JSON']>;
  getContactTracing: Scalars['JSON'];
  GetAttendanceRecordManualEntryPageTotalCount?: Maybe<Scalars['Float']>;
  GetServerDateTime: Scalars['JSON'];
  GetPrivateDocumentByFileName: Array<Scalars['JSON']>;
  AppraisalCategoryListing: Array<AppraisalCategoryEntity>;
  AppraisalCategoryListingAll: Array<AppraisalCategoryEntity>;
  AppraisalCategoryItemListingAll: Array<AppraisalCategoryItemEntity>;
  AppraisalPolicyListing: Array<AppraisalPolicyEntity>;
  AppraisalTemplateByJobPositionListing: Array<AppraisalTemplateHeaderEntity>;
  GetAppraisalTemplateByJobPosition: AppraisalTempleteByJobPositionEntity;
  AppraisalScheduleListing: Array<AppraisalScheduleHeaderEntity>;
  GetAppraisalCompanySetting: AppraisalCompanySettingEntity;
  GetCompanyAppraisalTemplete: Array<AppraisalTempleteByJobPositionEntity>;
  GetCompanyAppraisalPolicy: AppraisalCompanySettingEntity;
  FinalRatingListing: Array<FinalRatingHeaderEntity>;
  ScaleRatingListing: Array<ScaleRatingHeaderEntity>;
  BonusSimulationListing: Array<BonusSimulationEntity>;
  IncrementSimulationListing: Array<IncrementSimulationEntity>;
  GetBonusRecordByCompanyOrgUnitList: Scalars['JSON'];
  GetIncrementRecordByCompanyOrgUnitList: Scalars['JSON'];
  AppraisalTempleteListing: Array<AppraisalTempleteEntity>;
  AppraisalTempleteListingAll: Array<AppraisalTempleteEntity>;
  GetEmployeeListingByscheduledAndQuater: Array<AppraisalEmployeeLisitngOutput>;
  GetEmployeeAppraisalHeader: Array<EmployeeAppraisalHeaderEntity>;
  GetEmployeeAppraisalDetails: Array<EmployeeAppraisalDetailEntity>;
  ModerationListing: Array<ModerationRatingEntity>;
  GetAppraisalDefaultSettingByJobPosition: AppraisalTempleteByJobPositionEntity;
  GetEmployeeKPITempleteLatestVersion: EmployeeKpiHeaderEntity;
  GetEmployeeKPIPlanningRecord: EmployeePlanningOutput;
  GetSubordinateKPIPlanning: Array<Scalars['JSON']>;
  GetEmployeeKPIPlanningByYear: Array<EmployeePlanningOutput>;
  GetKPIPlanningDetails: Array<EmployeeKpiCategoryEntity>;
  StaffPerformanceAnalysisSummaryListing: Array<StaffPerformanceAnalysisSummaryOutput>;
  ApprovalPolicySummary: Scalars['JSON'];
  ApprovalPolicyAssignmentSummary: Scalars['JSON'];
  ApprovalPolicyList: Array<ApprovalPolicyEntity>;
  ApprovalPolicyDetails: ApprovalPolicyEntity;
  IsApprovalPolicyTitleExist: Scalars['Boolean'];
  ApprovalPolicyAssignmentList?: Maybe<ApprovalPolicyAssignmentEntity>;
  NewApprovalPolicyAssignmentList?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  EmployeeListingByRoleID: Array<EmployeeEntity>;
  ApproverListByCompany: Scalars['JSON'];
  GetAttendanceAnalysisTemplate?: Maybe<Array<AttendanceAnalysisTemplateEntity>>;
  EmployeeAttendanceCorrectionRequestListing: Array<AttendanceCorrectionHeaderEntity>;
  EmployeeAttendanceCorrectionRequestDetail: AttendanceCorrectionHeaderEntity;
  IsAttCorReqRecordAlreadyExistAndPendingApproval: Scalars['Boolean'];
  AttendancePeriodSummaryForAttendanceCorrection?: Maybe<Scalars['JSON']>;
  AdminAttendanceCorrectionRequestListing?: Maybe<Array<AttendanceCorrectionHeaderEntity>>;
  AdminAttendanceCorrectionRequestListingTotalCount?: Maybe<Scalars['Float']>;
  GetAttendanceCorrectionToDo?: Maybe<Array<AttendanceCorrectionHeaderEntity>>;
  AttendancePostingListing: Array<AttendancePostingOutput>;
  AttendancePostingListingCount: Scalars['JSON'];
  ExportAttendanceProcessing: Array<ExportAttendanceProcessing>;
  AdjustedValueQuantityDetails: Array<AttendancePostingOutput>;
  getLastPostedAttendanceProccess?: Maybe<Scalars['JSON']>;
  AttendancePeriodSummary?: Maybe<Scalars['JSON']>;
  GetLatestAttandanceCalculationDateByCompany?: Maybe<Scalars['JSON']>;
  GetPersonalInfo: Scalars['JSON'];
  getOvertime: Array<OvertimeEntity>;
  GetTotalOverTime: Scalars['JSON'];
  OvertimeListing: Array<OvertimeEntity>;
  OvertimeWithdrawalChecking: Scalars['Boolean'];
  OvertimeListingByExcel: Scalars['JSON'];
  OvertimeListingByYear: Array<OvertimeEntity>;
  OvertimeListingByDateTimeV2: Array<OvertimeEntity>;
  OvertimeListingByDateTime: Array<OvertimeEntity>;
  OvertimeListingByPeriodV2: Array<OvertimeEntity>;
  OvertimeListingByPeriod: Array<OvertimeEntity>;
  ViewOvertimeApprovalHistory?: Maybe<Array<OvertimeEntity>>;
  GetOvertimeApproveToDo?: Maybe<Array<OvertimeEntity>>;
  GetOvertimeToApprove?: Maybe<Array<OvertimeEntity>>;
  OvertimePeriodSummary?: Maybe<Scalars['JSON']>;
  OvertimeMenuSummary?: Maybe<Scalars['JSON']>;
  EmployeeOvertimeChecking: Scalars['JSON'];
  BatchOvertimeListing: Array<BatchOvertimeEntity>;
  AdminBatchOvertimeListing: Array<BatchOvertimeEntity>;
  GetBatchOvertimeToDo?: Maybe<Array<BatchOvertimeEntity>>;
  CompanyBusinessInsight: Array<BusinessInsightCompanyOutput>;
  EmployeeBusinessInsightSummary: BusinessInsightSummaryOutput;
  EmployeeBusinessInsightRawListing: Array<BusinessInsightListingOutput>;
  EmployeeBusinessInsightListing?: Maybe<Array<BusinessInsightOutput>>;
  PayrollBusinessInsightStatutoryListing: Array<BusinessInsightPayrollStatutoryOutput>;
  PayrollBusinessInsightSalaryOverheadListing: Array<BusinessInsightPayrollSalaryOverheadOutput>;
  PayrollBusinessInsightPayItemListing: Array<BusinessInsightPayrollPayItemOutput>;
  PayrollBusinessInsightSummary: BusinessInsightSummaryPayrollOutput;
  TopLeaveTakerByType: Array<LeaveEntitlementByLeaveTypeListing>;
  LeaveBILeaveTakenListing: Array<BusinessInsightLeaveTakenOutput>;
  TopClaimTaker: Array<TopClaimTakerOutput>;
  ClaimBIClaimTaken: Array<BusinessInsightClaimTakenOuput>;
  TopRequisitionTaker: Array<TopRequisitionTakerOutput>;
  RequisitionTakenSummaryByType: Array<RequisitionTakenSummaryByTypeOutput>;
  BIManagementListing: Scalars['JSON'];
  HeadCountSummary: Scalars['JSON'];
  OverHeadSummary: Scalars['JSON'];
  TurnOverSummary: Scalars['JSON'];
  OvertimeSummary: Scalars['JSON'];
  LeaveSummary: Scalars['JSON'];
  ClaimSummary: Scalars['JSON'];
  ClaimByMonth: Scalars['JSON'];
  HeadCountAndBasicSalaryByMonth: Scalars['JSON'];
  EmployeeYOS: Scalars['JSON'];
  EmployeeSalary: Scalars['JSON'];
  EmployeeAge: Scalars['JSON'];
  EmpGender: Scalars['JSON'];
  EmpRace: Scalars['JSON'];
  EmpEmploymentType: Scalars['JSON'];
  LeaveByMonth: Scalars['JSON'];
  OverheadByMonth: Scalars['JSON'];
  ContributionByMonth: Scalars['JSON'];
  OvertimeHrsAndCost: Scalars['JSON'];
  StaffTurnOverByMonth: Scalars['JSON'];
  GetClaimManagementListing?: Maybe<Array<ClaimManagementListing>>;
  GetClaimRecordsListing?: Maybe<Array<ClaimRecordsListing>>;
  GetClaimRecordsTotalCount: Scalars['Float'];
  ClaimMenuSummary?: Maybe<Scalars['JSON']>;
  GetClaimRecordsDetails?: Maybe<ClaimRecordsDetailHeaderOutput>;
  GetClaimRecordsHistoryListing?: Maybe<Array<ClaimRecordsListing>>;
  GetApprovedClaimVerificationListing?: Maybe<Array<ClaimRecordsListing>>;
  GetApprovedClaimCount: Scalars['JSON'];
  GetApprovedClaimVerificationDetails?: Maybe<ApprovedClaimVerificationDetailsOutput>;
  GetClaimPostingListing?: Maybe<Array<ClaimPostingListingOutput>>;
  GetClaimPostingCount?: Maybe<Scalars['JSON']>;
  GetClaimPostingDetail?: Maybe<ClaimRecordsDetailHeaderOutput>;
  GetClaimPostingPeriodListing?: Maybe<Array<ClaimPostingPeriodListingOutput>>;
  GetClaimPostingPeriodListingV2?: Maybe<Array<ClaimPostingPeriodListingOutput>>;
  GetClaimEntitlementByClaimTypeListing?: Maybe<Array<EmpClaimEntitlementByClaimTypeOutput>>;
  GetClaimEntitlementByClaimTypeListingCount?: Maybe<Scalars['JSON']>;
  GetClaimEntitlementByEmployeeListing?: Maybe<Array<EmpClaimEntitlementByEmployeeOutput>>;
  GetClaimToApprove?: Maybe<Array<ClaimToApproveListingOutput>>;
  GetClaimApprovalHistory?: Maybe<Array<ClaimToApproveListingOutput>>;
  ClaimHomeMenuSummary?: Maybe<Scalars['JSON']>;
  GetClaimReportDetails?: Maybe<Scalars['JSON']>;
  ClaimCompanySettingSummary?: Maybe<Scalars['JSON']>;
  GetEmpListingbyCompany: Array<EmployeeEntity>;
  GetEmpsClaimEntitlement: Array<EmpClaimEntitlementOutput>;
  GetMyClaimEntitlement: EmpClaimEntitlementOutput;
  GetEmpsClaimBalance: Array<EmpClaimBalanceOutput>;
  GetMyClaimBalance: EmpClaimBalanceOutput;
  GetMyClaimPoolBalance: EmpClaimPoolBalanceOutput;
  GetMyClaimListing: MyClaimListingOutput;
  GetMyClaimDetails: MyClaimHeaderDetails;
  VerifyMyClaim: Scalars['Boolean'];
  GetMainClaimListing: Array<GetMainClaimListingOutput>;
  GetEmpClaimListing: Array<GetEmpClaimListingOutput>;
  GetApprovedDetail: Array<GetApprovedDetailOutput>;
  GetClaimRecordListing: Array<ClaimRecordOutput>;
  GetApproveClaimVerification: Array<ApproveClaimVerificationOutput>;
  GetClaimPostingManagement: Array<ClaimPostingManagementOutput>;
  GetClaimRecordHistory: Array<ClaimRecordHistoryOutput>;
  GetCompletedDetail: Array<GetCompletedDetailOutput>;
  MyClaimDetail: ClaimDetailsEntity;
  ClaimListing: Array<ClaimHeaderEntity>;
  GetAllExpenseAccount: Array<JobAccountEntity>;
  ClaimValidityDetails: ClaimSettingEntity;
  ClaimTypeListing: Array<ClaimTypeEntity>;
  ClaimTypeListingAll: Array<ClaimTypeEntity>;
  ClaimTypeTemplateListing: Array<ClaimBenefitEntity>;
  ClaimTypeTemplateListingForChecking: Array<ClaimBenefitEntity>;
  ClaimPolicyListing: Array<ClaimBenefitEntity>;
  ClaimPolicyListingAll: Array<ClaimBenefitEntity>;
  ClaimTypeTemplateDetail: ClaimBenefitEntity;
  GetClaimSettingsListing: Array<ClaimSettingsListing>;
  ClaimTypeConditionList: Array<ClaimTemplateEntity>;
  ClaimTemplateListing: Array<ClaimTemplateEntity>;
  IsClaimSubjectExists: Scalars['Boolean'];
  ClaimPoolListing: Array<ClaimPoolEntity>;
  GetEmployeePolicies?: Maybe<Scalars['JSON']>;
  GetEmployeeClaimPolicies?: Maybe<Scalars['JSON']>;
  BankDetail: Array<BankInfoEntity>;
  BankListing: Array<BankInfoEntity>;
  IsAccountNoExists: Scalars['Boolean'];
  IsAccountNameExists: Scalars['Boolean'];
  HolidayListing: Array<HolidayEntity>;
  CompanyOffDaysListing: Array<CompanyOffDayEntity>;
  CompanyOffDaysListingWithFilters: Scalars['JSON'];
  OffRestDayListing: Array<RecurringRestOffDayEntity>;
  CalendarStateListing: Array<CalendarOffDayOutput>;
  EmployeeStateAssignListing: Array<CalendarOffDayEmployeeOutput>;
  CalendarPolicyListing: Array<CalendarPolicyOutput>;
  EmployeeHomeCalendar?: Maybe<Array<HomeCalendarOutput>>;
  CompanyDeclaredHolidayListing: Array<CompanyDelaredHolidayEntity>;
  CompanyDeclaredHolidayListingByCalendarPolicyID: Array<CompanyDelaredHolidayEntity>;
  DocumentListing: Array<DocumentsEntity>;
  DocumentListingCount: Scalars['JSON'];
  PersonnelDocumentListing: Array<DocumentsEntity>;
  CompanyFolderListing: Array<FolderEntity>;
  CompanyListingByEmail: Scalars['JSON'];
  EmployeeCompanyListing: Scalars['JSON'];
  CompanyListWithAccessLevel?: Maybe<Array<Scalars['JSON']>>;
  CompanyListing: Array<CompanyEntity>;
  CompanySummary: CompanyEntity;
  CompanySummarySimplified: CompanyEntity;
  JobPositionListing: Array<JobDesignationEntity>;
  JobDesignationDetail: JobDesignationEntity;
  JobDesignationGradeListing: Array<JobDesignationGradeEntity>;
  JobGradeByJobDesignationListingV2: Scalars['JSON'];
  JobGradeByJobDesignationListing: Array<JobGradeByJobDesignationOutput>;
  JobDesignationListing: Array<JobDesignationEntity>;
  RecruitmentSourceListing: Array<RecruitmentSourceEntity>;
  GetCompanyCyclePeriodList?: Maybe<Array<CompanyCyclePeriodListOutput>>;
  GetCompanyCyclePeriodListV2?: Maybe<Array<CompanyCyclePeriodListOutput>>;
  CompanyAltAddressListing: Scalars['JSON'];
  getCompanyStatutoryAccountListing?: Maybe<Array<CompanyStatutoryAccountOutput>>;
  CompanyStatutoryDetail?: Maybe<CompanyStatutoryEntity>;
  IsCompanyHierarchyExists: Scalars['Boolean'];
  CompanyHierarchyList: Scalars['JSON'];
  CompanyOrgUnitListV2: Scalars['JSON'];
  CompanyOrgUnitList: Scalars['JSON'];
  CompanyOrgUnitListWithFilter: Scalars['JSON'];
  CompanyOrgUnitListWithEmpCheck: Scalars['JSON'];
  JobPositionByOrgUnitListing: Scalars['JSON'];
  JobPositionAssignmentList: Scalars['JSON'];
  CompanyAccessPermList: Scalars['JSON'];
  GetAllCoOrgUnitListBySubscription: Scalars['JSON'];
  GetEmpCoOrgUnitList: Scalars['JSON'];
  DepartmentListing: Array<DepartmentEntity>;
  DepartmentDivisionListing: Array<DepartmentEntity>;
  DepartmentDetail: Array<DepartmentEntity>;
  DivisionListing: Array<DepartmentEntity>;
  DivisionDetail: Array<DepartmentEntity>;
  GetInsuranceScheme: Array<InsuranceSchemeEntity>;
  InsuranceSchemeListing: Array<InsuranceSchemeEntity>;
  InterbankDetail: Array<InterbankGiroEntity>;
  InterbankListingBulk: Array<InterbankGiroEntity>;
  InterbankListing: Array<InterbankGiroEntity>;
  GetBankInfoListing: Array<BankInfoOutput>;
  GetBankInfoListingV2: Array<BankInfoOutput>;
  MyJobAccountListing: Array<MyJobAccountEntity>;
  JobAccountListing: Array<JobAccountEntity>;
  JobAccountDetail: Array<JobAccountEntity>;
  JobGradeDetail: JobGradeEntity;
  JobGradeListing: Array<JobGradeEntity>;
  JobGradeListingByCompany: Array<JobGradeEntity>;
  JobGradePolicyListing: Array<JobGradePolicyEntity>;
  JobGradePolicyListingAll: Array<JobGradePolicyEntity>;
  LeaveRoundingListing: Array<LeaveRoundingEntity>;
  LeaveCompulsoryPolicyListing: Array<LeaveCompulsoryPolicyEntity>;
  LeaveTemplateListing: Array<LeaveTemplateEntity>;
  LHDNReportListing: Array<PayItemEntity>;
  LHDNReportDropdownListing: Array<LhdnReportEntity>;
  LHDNReportDetail: PayItemEntity;
  GetLHDNReportListing?: Maybe<Array<DisplayReportTypeSequenceOutput>>;
  NotificationPolicyDetail: Array<NotificationPolicyEntity>;
  NotificationPolicyListing: Array<NotificationPolicyEntity>;
  NotificationPolicyConditionListing: Array<NotificationPolicyConditionEntity>;
  NotificationPolicyConditionDetail: NotificationPolicyConditionEntity;
  NotificationPolicySummaryDates: NotificationPolicyLastUpdatedOutput;
  PayrollCycleDetail: PayrollCycleEntity;
  PayrollProcessPayrollCycleListingByTLA: Array<PayrollProcessPayrollCycleListingOutput>;
  PayrollCycleByPayPeriod: Scalars['JSON'];
  PayPeriodsByCompany: Scalars['JSON'];
  PayrollCycleListingByTLA: Array<PayrollCycleEntity>;
  PayItemListingByTLA: Array<PayItemListingByTlaOutput>;
  PayrollCycleListing: Array<PayrollCycleEntity>;
  PayrollCycleListingV2: Array<PayrollCycleEntity>;
  PayrollCycleListingV3: Array<PayrollCycleEntity>;
  PayrollCycleListingWithAudit: Scalars['JSON'];
  IsPayrollCycleLocked: Scalars['Boolean'];
  ReportingGroupListing: Array<ReportingGroupEntity>;
  ReportingStructureGroupOptionList: Scalars['JSON'];
  ReportingCompanyGroupListing: Scalars['JSON'];
  ReportingGroupTitleExist: Scalars['Boolean'];
  GetReportingStructureListing: ReportingStructureOutput;
  GetSuperior?: Maybe<Scalars['JSON']>;
  GetSkillSet: Array<SkillSetEntity>;
  GetSkillSetDetail: Array<SkillSetDetailEntity>;
  UserDefineListing: Array<UserDefineEntity>;
  GetLeaveOffDay: Scalars['JSON'];
  GetLeaveOffDayByAllStateWithCompany: Scalars['JSON'];
  GetLeavePolicyByEmployee?: Maybe<Scalars['JSON']>;
  GetEmployeeLeavePolicyListByDate?: Maybe<Scalars['JSON']>;
  GetPayItemPolicy?: Maybe<Scalars['JSON']>;
  GetLeavePolicyByJobGradeID?: Maybe<Array<LeaveItemEntity>>;
  GetOffboardEncashPeriodListing?: Maybe<Array<LeavePostingPeriodOutput>>;
  GetPlatformNewLeaveTypePolicy?: Maybe<LeaveEntitlementTemplate>;
  GetMyLeaveEntitlement?: Maybe<EmpLeaveEntitlementOuput>;
  GetEmpLeaveBalance?: Maybe<Array<EmpLeaveBalanceOuput>>;
  GetMyLeaveBalance?: Maybe<EmpLeaveBalanceOuput>;
  EmployeeCalendarLeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  EmployeeLeaveListing?: Maybe<Array<LeaveHeaderEntity>>;
  GetEmpLeaveDetail?: Maybe<EmpLeaveDetailOuput>;
  GetLeaveRoundedValue?: Maybe<Scalars['Float']>;
  LeaveTypeListing: Array<LeaveTypeEntity>;
  LeaveReasonListing: Array<LeaveReasonEntity>;
  LeaveDiseaseListing: Array<LeaveDiseaseEntity>;
  LeaveCompanySettingSummary?: Maybe<Scalars['JSON']>;
  LeaveTypeConditionList: Array<LeaveTemplateEntity>;
  LeavePolicyListing?: Maybe<Array<LeaveItemEntity>>;
  LeavePolicyByID?: Maybe<LeaveItemEntity>;
  LeaveTypeTemplateListing: Array<LeaveBenefitEntity>;
  LeaveTypeTemplateDetail: LeaveBenefitEntity;
  GetIntegratedLeaveBalance: Scalars['String'];
  GetEmployeesLeaveSummaryReport?: Maybe<Array<EmployeeLeavesSummary>>;
  GetEmployeesLeaveSummary?: Maybe<Array<EmployeeLeavesSummary>>;
  EnhanceGetEmployeesLeaveSummary?: Maybe<Array<EmployeeLeavesSummary>>;
  GetLeaveEntitlementByLeaveTypeListingTotalCount: Scalars['Float'];
  GetLeaveEntitlementByLeaveTypeListing?: Maybe<Array<LeaveEntitlementByLeaveTypeListing>>;
  GetALBalanceForResignedEmployees?: Maybe<Array<LeaveEntitlementByLeaveTypeListing>>;
  getEmployeeCalendar: Scalars['JSON'];
  GetTakenLeaveByEmployeeID?: Maybe<Array<LeaveDetailsEntity>>;
  GetEmployeeEntitlementByYear?: Maybe<Scalars['JSON']>;
  GetLeaveAdjustmentEmployeeListing?: Maybe<Array<EmployeeEntity>>;
  GetLeaveAdjustmentEmployeeListingCount?: Maybe<Scalars['JSON']>;
  GetEmployeeLeaveCriteriaByEmpID?: Maybe<Scalars['JSON']>;
  GetUserDashboardSetting?: Maybe<UserDashboardControlEntity>;
  GetOffboardingList?: Maybe<Array<Scalars['JSON']>>;
  GetEmployeeLeaveList?: Maybe<Array<Scalars['JSON']>>;
  GetEmployeeLeaveSummary?: Maybe<Array<Scalars['JSON']>>;
  GetBirthdayList: Array<BasicDashboardCardOutput>;
  GetConfirmationDueList: Array<BasicDashboardCardOutput>;
  GetAnnouncementList: Array<AnnouncementDashboardCardOutput>;
  GetAttendanceList: Array<AttendanceDashboardCardOutput>;
  getDataChangesListing?: Maybe<Array<DataChangesEntity>>;
  getDocument: Array<DocumentsEntity>;
  getDocuments: Array<DocumentsEntity>;
  EmployeeDependentsSummary: EmployeeDependentsEntity;
  SelectedDataChangesApprovalPolicy: Scalars['JSON'];
  GetDataChangesToDo?: Maybe<Array<DataChangesEntity>>;
  ViewDataChangesApprovalHistory?: Maybe<Array<DataChangesEntity>>;
  GetDataImportTemplate: Scalars['JSONObject'];
  ShopeeImport: Scalars['Boolean'];
  ShopeeImportRedirect: Scalars['Boolean'];
  EmployeePendingActivateListing: Array<ContactEntity>;
  DelegationList?: Maybe<Array<DelegationEntity>>;
  DelegationInfoLine?: Maybe<Array<DelegationEntity>>;
  BulletinListing: Scalars['JSON'];
  NewsListing: Array<NewsEntity>;
  ELearningNewsCount?: Maybe<Scalars['Float']>;
  NewsCount?: Maybe<Scalars['Float']>;
  ClaimDetailReportListing: Array<Scalars['JSON']>;
  TTDA_ClaimDetailReportListing: Array<Scalars['JSON']>;
  GetPayrollReportListing?: Maybe<Array<PayrollReportOutput>>;
  GetPayrollReportListingRedirect?: Maybe<Array<PayrollReportOutput>>;
  GetCompanyStatutoryListing?: Maybe<Array<CompanyStatutoryOutput>>;
  GetCompanyReportYearListing?: Maybe<Array<ReportYearOutput>>;
  GetPayItemListing?: Maybe<Array<ReportPayItemOutput>>;
  EmployeeMasterExcelReport: Array<Scalars['JSON']>;
  SimpleShiftGroupList: Array<ShiftGroupEntity>;
  SimpleShiftList: Array<ShiftEntity>;
  SimpleJobPositionListing: Array<JobDesignationEntity>;
  AttendanceListingReport: Array<Scalars['JSON']>;
  EmployeeClockingListingReport: Array<Scalars['JSON']>;
  AbsenteeismListingReport: Array<Scalars['JSON']>;
  IncompleteClockingListingReport: Array<Scalars['JSON']>;
  IrregularAttendanceListingReport: Array<Scalars['JSON']>;
  LateInEarlyOutListingReport: Array<Scalars['JSON']>;
  GetNextDocNumber: Scalars['JSON'];
  DocumentNumberingList: Array<DocumentNumberHeaderEntity>;
  DocumentNumberingAssignmentList: Scalars['JSON'];
  HasEmployeeDocNumber: Scalars['Boolean'];
  getBatchEntryByShift: Array<BatchEntryByShiftEntity>;
  getBatchEntryByPattern: Array<BatchEntryByShiftPatternEntity>;
  GetEmployeeAppraisal: Array<EmployeeAppraisalEntity>;
  CareerLogListing: Array<CareerLogEntity>;
  GetEmployeeDiary: Array<EmployeeDiaryEntity>;
  EmployeeDocumentListing: Array<DocumentsEntity>;
  TestNotification: Scalars['Boolean'];
  EmployeeListForSpecificSection: Scalars['JSON'];
  EmployeeListForSpecificSectionCount: Scalars['JSON'];
  EmployeeListForReporting: Scalars['JSON'];
  EmployeeListForReportingCount: Scalars['JSON'];
  SimpleEmployeeListing: Array<EmployeeEntity>;
  EmployeeListing: Array<EmployeeEntity>;
  EmployeeListingv2: Array<EmployeeListingOutputv2>;
  EmployeeListingCount?: Maybe<Scalars['JSON']>;
  SubordinateMenuSummary: Scalars['JSON'];
  GetOrgChart: Scalars['JSON'];
  SubordinateTree: Scalars['JSON'];
  SubordinateTreeTimeAttendance: Scalars['JSON'];
  TMSEmployeeListing: Array<EmployeeEntity>;
  TMSEmployeeListingCount?: Maybe<Scalars['JSON']>;
  EmployeeListingBySubscriptionAccount: Array<EmployeeEntity>;
  EmployeeListingBySubscriptionAccountCount?: Maybe<Scalars['JSON']>;
  PersonnelEmployementInfoListing: Scalars['JSON'];
  PersonnelEmployementInfoListingCount: Scalars['JSON'];
  EmployeeListForJobInfo: Scalars['JSON'];
  EmployeeAppSummary: Scalars['JSON'];
  EmployeeAppDocSummary: Scalars['JSON'];
  EmployeeSummary: EmployeeEntity;
  EmployeeDelegationSummary: Scalars['JSON'];
  EmployeeContactID: EmployeeEntity;
  EmployeeInfoKpi: EmployeeEntity;
  ZakatSummary?: Maybe<Scalars['String']>;
  EmployeeSummaryBySubscription: EmployeeEntity;
  GetEmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  IsSuperUserEmployee: Scalars['Boolean'];
  FilterRequisitionListing: Array<RequisitionEntity>;
  RequisitionListing: Array<RequisitionEntity>;
  FixedAllowanceListing: Array<RecurringPayEntity>;
  FilterEmployeeLeaveRecords: Array<LeaveHeaderEntity>;
  TaxReliefListingDefaultExcluded: Array<TaxReliefEntity>;
  EmployeeTaxReliefListing: Array<EmployeeTaxReliefEntity>;
  EmployeeTaxReliefDetails: Array<EmployeeTaxReliefEntity>;
  EmployeeAssetListing: Array<EmployeeAssetEntity>;
  EmployeeAssetDetail: EmployeeAssetEntity;
  EmployeeBenefitInKindListing: Array<EmployeeBenefitInKindEntity>;
  EmployeeBenefitInKindDetailForYear: EmployeeBenefitInKindEntity;
  EmployeeBenefitInKindDetail: EmployeeBenefitInKindEntity;
  EmployeeDependentsListing: Array<EmployeeDependentsEntity>;
  hasEmergencyContact: Scalars['Boolean'];
  MyEmployeeInfo?: Maybe<EmployeeEntity>;
  MyNewsList: Scalars['Float'];
  MyApplicationDetail: MyApplicationOutput;
  EmployeeCP22Listing: Array<EmployeeCp22Output>;
  GetEmployeeListing?: Maybe<Array<EmployeeListingOutput>>;
  PayrollEmployeeListing?: Maybe<Array<PayrollEmployeeListingOutput>>;
  GetSignatoryListing?: Maybe<Array<EmployeeListingOutput>>;
  GetEmployeeListingWithPayrollInput?: Maybe<Array<EmployeeListingOutput>>;
  EmployeeDirectoryListing: Scalars['JSON'];
  EmployeeDirectoryListingCount: Scalars['JSON'];
  EmployeeDirectoryDetails: Scalars['JSON'];
  GetEmployeeInsuranceScheme: Array<EmployeeInsuranceSchemeEntity>;
  EmployeeNotificationListing: Array<EmployeeNotificationOutput>;
  SubordinateListing: Array<EmployeeEntity>;
  GetEmployeeTraining: Array<EmployeeTrainingEntity>;
  EmployeeUserDefineFieldsListing: Array<EmployeeUserDefineFieldsEntity>;
  EmployeeUserDefineFieldsDetailListing: Array<EmployeeUserDefineFieldsDetailEntity>;
  EmployeeUserDefineFieldsDetailSummary?: Maybe<Scalars['String']>;
  ZakatContributionListing: Array<ZakatContributionEntity>;
  ZakatContributionDetails: Array<ZakatContributionEntity>;
  IsZakatPeriodOverlap: Scalars['Boolean'];
  GetEmployeeAppSecurityListing?: Maybe<Array<EmployeeAppSecurityOutput>>;
  GetEmployeeAppSecurityAssignmentListing?: Maybe<Array<Scalars['JSON']>>;
  GetEmployeeAppSecurityPermissionsByContactID?: Maybe<Scalars['JSON']>;
  EaDocumentsByEmployeeID: Array<Scalars['JSON']>;
  PayslipDocumentsByEmployeeID: Array<Scalars['JSON']>;
  EonDocumentsByBatch: Array<Scalars['JSON']>;
  SystemEonDocumentsCount: Scalars['Float'];
  HelpdeskListingReport?: Maybe<Array<Scalars['JSON']>>;
  HelpdeskSubjectListing: Array<HelpdeskSubjectEntity>;
  HelpdeskAssigneeListing: Array<EmployeeEntity>;
  HelpdeskSubjectDetail: HelpdeskSubjectEntity;
  IsHelpdeskSubjectExist: Scalars['Boolean'];
  getHelpdeskTicketSummary?: Maybe<Scalars['JSON']>;
  HelpdeskTicketDetail?: Maybe<HelpdeskTicketEntity>;
  HelpdeskTicketListing?: Maybe<Array<HelpdeskTicketEntity>>;
  HelpdeskToDoListing?: Maybe<Array<HelpdeskTicketEntity>>;
  HelpdeskAssigneeApprovalHistory?: Maybe<Array<HelpdeskTicketEntity>>;
  BlockLeaveListing: Array<BlockLeaveEntity>;
  BlockLeaveEmpListing?: Maybe<Array<BlockLeaveEntity>>;
  GetCarryForwardEncashmentPostedListing?: Maybe<Array<EncashCarryForwardLeaveOutput>>;
  GetCarryForwardEncashmentPendingListing?: Maybe<Array<YecfListingOutput>>;
  ViewLeaveApprovalHistory?: Maybe<Array<LeaveHeaderEntity>>;
  GetLeaveRecordsListing?: Maybe<Array<LeaveRecordsOutput>>;
  GetLeaveRecordsTotalCount?: Maybe<Scalars['Float']>;
  GetLeaveReplacementListingByEmployee?: Maybe<Array<LeaveAdjustmentOutput>>;
  GetLeaveAdjustmentListing?: Maybe<Array<LeaveAdjustmentOutput>>;
  GetCompulsoryLeaveListing?: Maybe<Array<CompulsoryLeaveOutput>>;
  GetCompulsoryLeaveTotalCount?: Maybe<Scalars['Float']>;
  GetCompulsoryLeaveDetail?: Maybe<CompulsoryLeaveOutput>;
  GetLeavePostingListing?: Maybe<Array<LeavePostingOutput>>;
  GetLeaveApproveToDo?: Maybe<Array<LeaveHeaderEntity>>;
  GetLeaveListing?: Maybe<Array<LeaveHeaderEntity>>;
  GetLeaveToAprove?: Maybe<Array<LeaveHeaderEntity>>;
  LeaveAdjustmentDetail: LeaveHeaderEntity;
  GetLeavePostingPeriodListing?: Maybe<Array<LeavePostingPeriodOutput>>;
  GetYECFPendingListing?: Maybe<Array<YecfListingOutput>>;
  GetYECFPendingListingWithFilter?: Maybe<Array<YecfListingOutput>>;
  GetYECFPostedListing?: Maybe<Array<YecfListingOutput>>;
  GetYECFPostedListingWithFilter?: Maybe<Array<YecfListingOutput>>;
  GetYearEndCarryForwardListing?: Maybe<Array<YearEndCarryForwardOutput>>;
  FilterGetYearEndCarryForwardListing?: Maybe<Array<YearEndCarryForwardOutput>>;
  LeaveMenuSummary?: Maybe<Scalars['JSON']>;
  LeaveHomeSummary: LeaveHomeSummaryOutput;
  LeaveTakenListing?: Maybe<Array<LeaveTakenOutput>>;
  GetLeaveByMonthCalendar?: Maybe<Scalars['JSON']>;
  GetLeaveByMonthCalendarToDo?: Maybe<Scalars['JSON']>;
  GetLeaveMaxBalancePendingListingWithFilter?: Maybe<Array<Scalars['JSON']>>;
  GetLeaveMaxBalancePostedListingWithFilter?: Maybe<Array<Scalars['JSON']>>;
  CheckLeaveWithdrawalValidity: Scalars['Boolean'];
  GetCareerMovementEmployees: Array<CareerLogEntity>;
  GetCareerMovementEmployeesCount: Scalars['Float'];
  LeaveApprovalPolicyListing?: Maybe<Array<Leave_Approval_PolicyEntity>>;
  LeaveOutOfStateListing: Array<LeaveOutOfStateEntity>;
  GLAccountMappingList: Scalars['JSON'];
  GLAccountHeaderList: Array<GlAccountHeaderEntity>;
  GetAccountMappingAssignment: GlAccountAssignmentEntity;
  GLAccountHeaderListWithDetails: Scalars['JSON'];
  PayItemAmountByOrgAndPayPeriod: Scalars['JSON'];
  ClaimAccountMappingList: Scalars['JSON'];
  ClaimAccountHeaderList: Array<ClaimAccountHeaderOutput>;
  GetClaimAccountMappingAssignment: Array<ClaimAccountHeaderEntity>;
  ClaimAccountHeaderListWithDetails: Scalars['JSON'];
  PEClaimAccountHeaderListWithDetails: Scalars['JSON'];
  GetClaimAccountAssignment?: Maybe<ClaimAccountAssignmentEntity>;
  GetGLClaimRecordsListing?: Maybe<Array<Scalars['JSON']>>;
  ClaimGLCycleProcess: Scalars['Boolean'];
  CostCenterMappingList: Scalars['JSON'];
  IsGLProcessed: Scalars['Boolean'];
  GetGLData: Scalars['JSON'];
  ProjectCostCenterMappingListForGLExport: Scalars['JSON'];
  CostCenterMappingListForGLExport: Scalars['JSON'];
  CurrentMobileVersion?: Maybe<Scalars['JSON']>;
  MobileVersionListing?: Maybe<Array<Scalars['JSON']>>;
  getMultiCurrencySetup: Array<MultiCurrencySetupEntity>;
  CheckMultiCurrencyAlreadyExist: Scalars['String'];
  GetEmployeeRoleName: Array<EmployeeEntity>;
  MultiCurrencyDetailsListing: Array<MultiCurrencyDetailsEntity>;
  MultiCurrencyListingByExcel: Scalars['JSON'];
  MultiCurrencyInfoline: Scalars['JSON'];
  CheckMultiCurrencyInUse: Scalars['Boolean'];
  OnBehalfMenuInfoline: Scalars['JSON'];
  TimeAttendanceMenuInfoline: Scalars['JSON'];
  OnBehalfCompanyListing: Array<OnBehalfCompanyOutput>;
  OnBehalfSubordinateListing: Array<OnBehalfSubordinateOutput>;
  GetOnBehalfPolicyTypeIDs: Array<Scalars['String']>;
  TmsOnBehalfPolicySummary?: Maybe<Scalars['JSON']>;
  OnBehalfPolicyListingByType?: Maybe<Array<OnBehalfEntity>>;
  OvertimeForecastReport?: Maybe<Scalars['JSON']>;
  OvertimeRoundingListing: Array<OvertimeRoundingSettingEntity>;
  OvertimeTemplateListing: Array<OvertimeTemplateEntity>;
  AdvancePolicyListing: Array<AdvancePolicyEntity>;
  GetSingleAdvancePolicyDetail: AdvancePolicyEntity;
  DuplicateAdvancePolicyName: Scalars['Boolean'];
  AdvancePolicyByCompanyAssignment?: Maybe<Scalars['JSON']>;
  PostedAdvanceProcessingListing: Array<AdvanceProcessingEntity>;
  PayGroupListing: Array<PayGroupEntity>;
  CompanyPayGroupListing: Array<CompanyPayGroupListingOutput>;
  StatutoryAdjustmentListing?: Maybe<Array<Scalars['JSON']>>;
  StatutoryAdjFormEmpListing: Array<Scalars['JSON']>;
  GetFormulaTest: Scalars['String'];
  PayrollCompanySettingSummary?: Maybe<Scalars['JSON']>;
  GetSalaryTest: Scalars['JSON'];
  SalaryPayoutAnalysisSummaryExportExcel: Scalars['JSON'];
  PayrollDetailListingExportExcel: Scalars['JSON'];
  EmployeeOutstandingSalaryListing: Array<EmployeeEntity>;
  EmployeeOutstandingSalaryListingAndCount?: Maybe<Scalars['JSON']>;
  CP22Count: Array<EmployeeCp22Entity>;
  GetPayrollCyclePeriodSetting: Array<PayrollCyclePeriodEntity>;
  ViewPayrollHistorySummary: ViewPayrollHistorySummaryOutput;
  TestPayrollCalc: Array<Scalars['JSON']>;
  ViewPayrollHistory: Array<PayrollHeaderEntity>;
  ViewPayrollHistoryCount?: Maybe<Scalars['JSON']>;
  ViewPayrollHistoryTotal?: Maybe<Scalars['JSON']>;
  ViewPayrollHistoryMyPayrollInfo: Array<PayrollHeaderEntity>;
  ViewPayrollHistoryByEmployee: Scalars['JSON'];
  PayrollProcessPeriodListing: Array<PayrollProcessPeriodListingOutput>;
  PayrollProcessPayrollCycleListing: Array<PayrollProcessPayrollCycleListingOutput>;
  PayrollProcessPayItemListing: PayrollProcessPayItemListingOutput;
  PayrollProcessEmployeeListing: Array<PayrollProcessEmployeeListingOutput>;
  CalculatePayroll: PayrollCalculationOutput;
  CalculatePayrollRedirect: PayrollCalculationOutput;
  CalculateEPFRate?: Maybe<EpfRateCalculationOutput>;
  Rounding: Scalars['String'];
  CalculateHRDF: HrdfCalculationOutput;
  CalculateEPFV2: EpfCalculationOutput;
  CalculateEIS: EisCalculationOutput;
  CalculateSocso: SocsoCalculationOutput;
  PayPeriodDetails: PayPeriodEntity;
  PayPeriodListing: Array<PayPeriodEntity>;
  StartPayPeriodListingByYear: Array<PayPeriodEntity>;
  EndPayPeriodListingByYear: Array<PayPeriodEntity>;
  PayItemByPayType: Array<PayItemEntity>;
  PayItemListing: Array<PayItemEntity>;
  PayItemConditionListTemplateLibrary: PayItemConditionEntity;
  PayItemConditionList: PayItemConditionEntity;
  PayrollProcessingPayItemListing: Array<PayItemEntity>;
  PayrollProcessingPayItemListing2: Array<PayItemEntity>;
  LeaveTypePayItemListing: Array<PayItemEntity>;
  SalaryListingByExcel: Scalars['JSON'];
  SalaryListingByPeriod: Array<CareerLogEntity>;
  ClaimTypePayItemListing: Array<PayItemEntity>;
  PayItemDetail?: Maybe<PayItemEntity>;
  PayrollFixedAllowanceListing: Array<RecurringPayEntity>;
  PayrollFixedAllowanceManualEntryListing: Array<RecurringPayEntity>;
  PayrollFixedAllowanceManualEntryListingCount?: Maybe<Scalars['JSON']>;
  PayrollFixedAllowanceDetails: Array<RecurringPayEntity>;
  CP159Listing: Array<Cp159Entity>;
  OneTimePayListing: Array<OneTimePayEntity>;
  OneTimePayListingByPayPeriodAndPayCycle: Array<OneTimePayEntity>;
  PublishPaySlipListing: Array<PublishPayslipEntity>;
  PublishPaySlipListingByEmployee: Array<PublishPayslipEntity>;
  GetSingleUser: Array<UserEntity>;
  PublishEAListing: Array<PublishEaEntity>;
  PublishEAListingByEmployee: Scalars['JSON'];
  IsMonthlyTrxInUse: Scalars['Boolean'];
  MonthlyTransactionByEmployee: Array<EmployeeTransactionOutput>;
  MonthlyTransactionByEmployeeCount?: Maybe<Scalars['JSON']>;
  MonthlyTransactionByPayItem: Array<PayItemTransactionOutput>;
  MonthlyTransactionByExcel: Array<ExcelPayItemTransactionOutput>;
  FreezepayrollListing: Array<FreezePayrollEntity>;
  PayrollFixedAllowanceByExcel: Array<ExcelPayItemTransactionOutput>;
  PublishedEACompanyListing: Array<PublishEaSingleEmployeeListingOutput>;
  CheckOldDataForSalaryBatch: Array<Scalars['JSON']>;
  CheckOldDataForOneTimePayInsert: Array<Scalars['JSON']>;
  CheckOldDataForRecurringPayInsert?: Maybe<Array<Scalars['JSON']>>;
  EmployeeSalaryLogs: Array<EmployeeEntity>;
  EmployeeSalaryLogsCount?: Maybe<Scalars['JSON']>;
  EmployeeSalaryListing: EmployeeEntity;
  PayrollHomeInfoLinesOutput?: Maybe<Scalars['JSON']>;
  PayrollCommonSettingsInfoLine?: Maybe<Scalars['JSON']>;
  OtherPayrollRelatedInfoLine: Scalars['JSON'];
  EmployeeKpiListing: Array<EmployeeKpiEntity>;
  EmployeeKpiDetails: EmployeeKpiEntity;
  EmployeeKpiRepoListing: Array<EmployeeKpiRepositoryEntity>;
  RetrieveEmployeeKpiDetailFromRepo: EmployeeKpiRepositoryEntity;
  IsKpiExistInRepo: Scalars['Boolean'];
  KpiExerciseListing: Array<KpiExerciseDetailsEntity>;
  KpiExerciseSubmenuSummary: Scalars['JSON'];
  KpiExerciseSetupListing: Array<KpiExerciseSetupEntity>;
  KpiExerciseSetupInfo: KpiExerciseSetupEntity;
  IsKpiExerciseIDExistByCompany: Scalars['Boolean'];
  IsKpiExerciseDateRangeOverlappingByCompany: Scalars['Boolean'];
  KpiCategoryWithOrgGoalListing: Array<KpiCategoryEntity>;
  OrganizationalGoalByCategoryListing: Array<OrganizationalGoalEntity>;
  OrganizationalGoalDetails: OrganizationalGoalEntity;
  IsOrgGoalExistByCategory: Scalars['Boolean'];
  OrgKpiPlanningTreeViewListing: Array<OrgKpiPlanningEntity>;
  KpiCategoryWithOrgKpiPlanningListing: Array<KpiCategoryEntity>;
  OrgKpiPlanningDetailsByKpiCategoryListing: Array<OrgKpiPlanningDetailsEntity>;
  OrgKpiPlanningInfo: OrgKpiPlanningDetailsEntity;
  IsOrgKpiExistByCategory: Scalars['Boolean'];
  PerformanceGroupMatrixListing: Array<PerformanceGroupMatrixEntity>;
  PerformanceMainMenuSummary: Scalars['JSON'];
  MyPerformanceSummary: Scalars['JSON'];
  GetPerformanceToDo: ToDoPerformanceOutput;
  GetPerformanceApprovalHistory: ToDoPerformanceOutput;
  PerformanceEmployeeListing?: Maybe<Array<EmployeeEntity>>;
  ReviewExerciseListing: Array<ReviewExerciseListingOutput>;
  GetEmployeeReviewListing: Array<ReviewExerciseEntity>;
  GetEmployeeReviewActionListing: Array<MyReviewActionOutput>;
  GetEmployeeReviewForm: EmployeeReviewEntity;
  GetConversationLogByReviewFormItem: Array<ReviewConversationLogEntity>;
  ReviewExerciseSubmenuSummary: Scalars['JSON'];
  ReviewExerciseSetupListing: Array<ReviewExerciseSetupEntity>;
  ReviewExerciseSetupInfo: ReviewExerciseSetupEntity;
  IsReviewExerciseIDExist: Scalars['Boolean'];
  PeerRegistrationListing: Array<ReviewPeerRegistrationEntity>;
  IsSuperiorAlreadyExist: Scalars['Boolean'];
  MySubordinatePerformanceSummary: Scalars['JSON'];
  SubordinateKpiEmpListing: Array<SubordinateKpiEmpOutput>;
  PeriodicAllowanceTypeListing: Array<PeriodicAllowanceTypeEntity>;
  PersonnelCertificateListing: Array<PersonnelCertificateEntity>;
  ContractInfoListing: Array<ContractInfoEntity>;
  ContractInfoDueListing: Array<ContractEmployeeOutput>;
  ContractInfoDueListingCount?: Maybe<Scalars['JSON']>;
  ContractInfoExistingListing: Array<ContractEmployeeOutput>;
  ContractInfoExistingListingCount?: Maybe<Scalars['JSON']>;
  PersonnelEducationListing: Array<PersonnelEducationEntity>;
  IsEmailExists: Scalars['Boolean'];
  IsMobileNoExists: Scalars['Boolean'];
  IsEmployeeNoExists: Scalars['Boolean'];
  DocumentProfilePicture?: Maybe<Array<DocumentProfilePictureOutput>>;
  PersonnelEFileListing?: Maybe<Scalars['JSON']>;
  PersonnelEFileListingCount?: Maybe<Scalars['Float']>;
  PersonnelHealthInfoSummary: PersonnelHealthInfoEntity;
  PersonnelHomeInfoLines: PersonnelHomeInfoLinesOutput;
  ELetterTempleteInfolines: Array<ReportTemplateEntity>;
  CommonSettingsInfoLines: CommonSettingsInfoLinesOutput;
  PersonnelJobHistoryListing: Array<PersonnelJobHistoryEntity>;
  PersonnelReferenceListing: Array<PersonnelReferenceEntity>;
  UserDefineDetailListing: Array<UserDefineDetailEntity>;
  InsuranceSchemeInUse: Scalars['JSON'];
  PersonnelInsuranceSchemePersonnelListing: Array<EmployeeInsuranceSchemeEntity>;
  ProbationExtensionListing: Array<ProbationExtendedEmployeeOutput>;
  StaffConfirmationProbationListing: Array<EmployeeEntity>;
  StaffConfirmationConfirmedListing: Array<EmployeeEntity>;
  StaffConfirmationConfirmedCareerLogListing: Array<CareerLogEntity>;
  StaffTerminationListing: Array<CareerLogEntity>;
  StaffTerminationListingBySubscription: Array<CareerLogEntity>;
  StaffRetirementRetiredListing: Array<CareerLogEntity>;
  StaffRetirementDueListing: Array<StaffDueForRetirementOutput>;
  StaffExitCompanyListing: Array<CareerLogEntity>;
  StaffPromotionDemotionListing: Array<CareerLogEntity>;
  StaffSecondmentListing: Array<CareerLogEntity>;
  StaffRedesignationListing: Array<CareerLogEntity>;
  StaffInternalTransferListing: Array<CareerLogEntity>;
  StaffInterCompanyTransferListing: Array<CareerLogEntity>;
  StaffInterCompanyTransferCopyTransferData: Scalars['JSON'];
  StaffInterCompanyTransferDetails: CareerLogEntity;
  InterCoTransferLeavePolicyData: Scalars['JSON'];
  GetProjectExpenseClaimRecordsListing?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GetPEClaimRecordsDetails?: Maybe<PeClaimRecordsDetailHeaderOutput>;
  GetPEClaimRecordsTotalCount: Scalars['Float'];
  ProjecExpenseSummary: Scalars['JSON'];
  VerifyMyPEClaim: Scalars['Boolean'];
  IsProjectExpenseSubjectExists: Scalars['Boolean'];
  GetPEClaimPostingPeriodListingV2?: Maybe<Array<PeClaimPostingPeriodListingOutput>>;
  GetPEClaimPostingListing?: Maybe<Array<PeClaimPostingListingOutput>>;
  GetPEClaimPostingDetail?: Maybe<PeClaimRecordsDetailHeaderOutput>;
  GetApprovedProjectExpenseClaimVerificationListing?: Maybe<Array<ProjectExpenseClaimRecordsListing>>;
  GetPEApprovedClaimCount: Scalars['JSON'];
  GetApprovedProjectExpenseClaimVerificationDetails?: Maybe<ApprovedProjectExpenseClaimVerificationDetailsOutput>;
  PEClaimCompanySettingSummary?: Maybe<Scalars['JSON']>;
  GetProjectToApprove?: Maybe<Array<ProjectExpenseToApproveListingOutput>>;
  GetProjectExpenseApprovalHistory?: Maybe<Array<ProjectExpenseToApproveListingOutput>>;
  ProjectExpenseTypeListing: Array<ProjectExpenseTypeEntity>;
  ProjectGLAccountMappingListing: Array<ProjectGlAccountMappingEntity>;
  GetProjectAccountAssignment: ProjectAccountAssignmentEntity;
  ProjectAccountMappingList: Scalars['JSON'];
  ProjectGLAccountHeaderListWithDetails: Scalars['JSON'];
  ProjectPayItemAmountByOrgAndPayPeriod: Scalars['JSON'];
  ProjectPayrollGLExport: Scalars['Boolean'];
  ProjectGLClaimCycleProcess: Scalars['Boolean'];
  GetGLClaimCycleList: Scalars['JSON'];
  ProjectMasterListing: Array<ProjectMasterEntity>;
  ProjecCommonSettingSummary: Scalars['JSON'];
  ProjectCostAllocationListing: Array<ProjectCostAllocationEntity>;
  ProjectCostAllocationEmployeeListing: Array<EmployeeEntity>;
  ProjectCostAllocationDetailListing: Array<ProjectCostAllocationDetailEntity>;
  getApproverMasterListing?: Maybe<Array<ProjectTimesheetApproverMasterEntity>>;
  getApproverAssignmentListing?: Maybe<Array<ProjectTimesheetPeriodEntity>>;
  IsApproverEmailAlreadyExist: Scalars['Boolean'];
  ProjectTimesheetPeriodListing: Array<ProjectTimesheetPeriodListingOutput>;
  DailyEntryListing: Array<ProjectTimesheetDailyEntryEntity>;
  ProjectTimesheetSubmissionListing?: Maybe<Array<Scalars['JSON']>>;
  ProjectTimesheetApprovalListing: Scalars['JSON'];
  ProjectTimesheetCheckPeriodOverlapping: ProjectTimesheetCheckOverlapOutput;
  ProjectTimesheetCheckPeriodOverlappingCount: Scalars['JSON'];
  ProjectTimesheetPeriod: Array<ProjectTimesheetMonthlyRecordEntity>;
  ProjectTimesheetApprover: Array<ProjectTimesheetApproverMasterEntity>;
  ProjectTimesheetEmployeeListing?: Maybe<Array<ProjectTimesheetApproverAssignmentEntity>>;
  AdminDailyEntryListing?: Maybe<Array<ProjectTimesheetDailyEntryEntity>>;
  ProjectTimesheeTApproverReportListing?: Maybe<Array<ProjectTimesheetApproverMasterEntity>>;
  AdminRecruitmentSummary: AdminRecruitmentSummaryOutput;
  RecruitmentExpensesListing?: Maybe<Array<RecruitmentExpensesEntity>>;
  ELetterTemplateListing?: Maybe<Array<RecruitmentE_LetterEntity>>;
  RecruitmentApprovalPolicySummary: Scalars['JSON'];
  RecruitmentApprovalPolicyListing?: Maybe<Scalars['JSON']>;
  Rec_EApplicationFormSetupListing: Array<Rec_EApplicationFormSetupEntity>;
  PreOfferChecklistListing?: Maybe<Array<RecruitmentPre_OfferChecklistEntity>>;
  TalentPoolUserFieldsListing: Array<Rec_TalentPoolUserFieldsEntity>;
  DeclineReasonListing: Array<Rec_DeclineReasonEntity>;
  Rec_PrehiringFormSetupListing: Array<Rec_PrehiringFormSetupEntity>;
  JobPortalSetupListing?: Maybe<Array<Scalars['JSON']>>;
  JobPortalSetupDetail: Rec_JobPortalSetupEntity;
  JobPortalQrCode: Scalars['JSON'];
  GetRecRequisitionToDo?: Maybe<Array<Rec_RequisitionEntity>>;
  ViewRec_RequisitionApprovalHistory?: Maybe<Array<Rec_RequisitionEntity>>;
  GetRecOfferToDo?: Maybe<Array<Rec_OfferOutput>>;
  ViewRec_OfferApprovalHistory?: Maybe<Array<Rec_OfferOutput>>;
  GetRecHiringToDo?: Maybe<Array<Rec_HiringOutput>>;
  ViewRec_HiringApprovalHistory?: Maybe<Array<Rec_HiringOutput>>;
  JobPortalInfo?: Maybe<Scalars['JSON']>;
  JobPostingListByJobPortal?: Maybe<Array<Scalars['JSON']>>;
  JobPostingListByJobPortalCount?: Maybe<Scalars['Float']>;
  JobPostingInfoByJobPortal?: Maybe<Scalars['JSON']>;
  TokenValidation?: Maybe<Scalars['JSON']>;
  EApplicationListing: Array<Rec_EApplicationFormSetupEntity>;
  EApplicationSummary?: Maybe<EApplicationSummaryOutput>;
  DataUpdateEApplicationSummary?: Maybe<Scalars['JSON']>;
  RecruitmentParameterListing: Array<PersonnelParameterEntity>;
  TalentUserDefineFieldsListing: Array<Rec_TalentPoolUserFieldsEntity>;
  DataUpdateTalentPersonal: Scalars['JSON'];
  DataUpdateHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  LanguageParameterListing: Array<PersonnelParameterEntity>;
  MyRecruitmentMenuSummary?: Maybe<Scalars['JSON']>;
  MyRecRequisitionListing: Array<Rec_Requisition_Output>;
  MyRecRequisitionListingTotalCount?: Maybe<Scalars['Float']>;
  MyApprovedJobVacancyListing?: Maybe<Array<Rec_JobVacancyOutput>>;
  MyApprovedJobVacancyListingCount?: Maybe<Scalars['Float']>;
  MyApprovedJobVacancyInfo?: Maybe<Array<Rec_JobVacancyOutput>>;
  MyApprovedJobVacancyApplicantListing: Array<Rec_ApplicantOutput>;
  MyApprovedJobVacancyApplicantListingCount?: Maybe<Scalars['Float']>;
  MyRecInterviewListing?: Maybe<Array<Rec_InterviewListing_Output>>;
  MyRecInterviewListingCount?: Maybe<Scalars['Float']>;
  MyOfferListing?: Maybe<Array<Rec_OfferOutput>>;
  MyOfferListingCount?: Maybe<Scalars['Float']>;
  MyOfferDetail: Array<Rec_OfferEntity>;
  MyTalentSearchApplicationListing?: Maybe<Array<Rec_JobVacancyOutput>>;
  AdminRecruitmentMenuSummary?: Maybe<Scalars['JSON']>;
  SettingSummary?: Maybe<Scalars['JSON']>;
  TalentPoolSummary?: Maybe<Scalars['JSON']>;
  JobVacancyManagementSummary?: Maybe<Scalars['JSON']>;
  JobOfferManagementSummary?: Maybe<Scalars['JSON']>;
  HiringProcessSummary?: Maybe<Scalars['JSON']>;
  GetRecRequisitionInfo?: Maybe<Rec_Requisition_Output>;
  RecRequisitionListing: Array<Rec_Requisition_Output>;
  RecRequisitionListingTotalCount?: Maybe<Scalars['Float']>;
  RecRequisitionNotInUseListing: Array<Rec_Requisition_Output>;
  RecRequisitionNotInUseListingCount: Scalars['JSON'];
  JobVacancyListing: Array<Rec_JobVacancyOutput>;
  JobVacancyListingCount?: Maybe<Scalars['Float']>;
  JobVacancyInfo: Rec_JobVacancyOutput;
  JobVacancyWithApplicantListing?: Maybe<Array<Rec_JobVacancyWithApplicantOutput>>;
  InterviewerInUse?: Maybe<Array<Scalars['JSON']>>;
  InterviewScheduleListing?: Maybe<Array<Rec_InterviewScheduleOutput>>;
  JobVacancyNotInUseListing: Array<Rec_JobVacancyOutput>;
  JobVacancyNotInUseListingCount: Scalars['Float'];
  JobPostingListing: Array<Rec_JobPostingOutput>;
  JobPostingListingCount?: Maybe<Scalars['Float']>;
  JobPostingInfo: Rec_JobPostingOutput;
  PostedJobVacancyListing?: Maybe<Array<Rec_JobVacancyOutput>>;
  PostedJobVacancyListingCount?: Maybe<Scalars['Float']>;
  PostedJobVacancyInfo?: Maybe<Array<Rec_JobVacancyOutput>>;
  ApplicantListing: Array<Rec_ApplicantOutput>;
  ApplicantListingCount?: Maybe<Scalars['Float']>;
  ActiveJobVacancyListing: Array<Rec_JobVacancyOutput>;
  ActiveJobVacancyListingCount?: Maybe<Scalars['Float']>;
  ActiveJobVacancyInfo?: Maybe<Array<Rec_JobVacancyOutput>>;
  FinalApplicantSelectionListing: Array<Rec_ApplicantOutput>;
  FinalApplicantSelectionListingCount?: Maybe<Scalars['Float']>;
  OfferProposalListing?: Maybe<Array<Rec_OfferOutput>>;
  OfferProposalListingCount?: Maybe<Scalars['Float']>;
  OfferProposalInfo: Rec_OfferEntity;
  OfferProposalDetailListing?: Maybe<Array<Rec_OfferEntity>>;
  OfferApprovalListing?: Maybe<Array<Rec_OfferOutput>>;
  OfferApprovalListingCount?: Maybe<Scalars['Float']>;
  OfferApprovalDetailListing?: Maybe<Array<Rec_OfferEntity>>;
  ApprovedJobOfferListing?: Maybe<Array<Rec_OfferOutput>>;
  ApprovedJobOfferListingCount?: Maybe<Scalars['Float']>;
  ApprovedJobOfferInfo: Scalars['JSON'];
  ProcessedOfferInfo: Array<Rec_OfferEntity>;
  OfferChecklistListing?: Maybe<Array<RecruitmentPre_OfferChecklistEntity>>;
  ELetterTemplateInfo?: Maybe<Array<RecruitmentE_LetterEntity>>;
  ApplicantOfferPreHiringListing: Array<Rec_HiringOutput>;
  ApplicantOfferPreHiringListingCount: Scalars['Float'];
  QuickHiringListing: Array<Rec_HiringOutput>;
  QuickHiringListingCount: Scalars['Float'];
  QuickHiringDetails: QuickHiringDetails;
  PreHiringDataUpdateListing: Array<Rec_HiringOutput>;
  PreHiringDataUpdateListingCount: Scalars['Float'];
  NewHireProcessListing: Array<Rec_HiringOutput>;
  NewHireProcessListingCount: Scalars['Float'];
  NewHireProcessOnboardInfo: NewHireProcessOnboardInfoOutput;
  TalentPoolListing?: Maybe<Array<Rec_TalentPoolOutput>>;
  TalentPoolListingCount?: Maybe<Scalars['Float']>;
  TalentProfileSummary?: Maybe<Scalars['JSON']>;
  TalentProfileDetail?: Maybe<Scalars['JSON']>;
  TalentHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  TalentApplicationListing: Array<Rec_JobVacancyOutput>;
  TalentApplicationListingCount: Scalars['Float'];
  TalentJobVacancyAppliedListing: Array<Rec_JobVacancyOutput>;
  TalentSearchListing: Array<Rec_TalentSearchOutput>;
  TalentInvitationListing: Array<Rec_TalentInvitationOutput>;
  TalentInvitationListingCount: Scalars['Float'];
  ApprovalPolicyMatrixSummary?: Maybe<Scalars['JSON']>;
  RecruitmentApprovalPolicyByType?: Maybe<Array<ApprovalPolicyMatrixEntity>>;
  WorkFlowDetail: WorkFlowDetail;
  RequisitionMenuSummary: Scalars['JSON'];
  RequisitionManagementListing?: Maybe<Array<RequisitionOutput>>;
  RequisitionManagementListingTotalCount?: Maybe<Scalars['Float']>;
  EmployeeRequisitionListing: Array<RequisitionEntity>;
  RequisitionDetails: Scalars['JSON'];
  FilterEmployeeRequisitionListing: Array<RequisitionEntity>;
  TravelRequsitionListing: Array<RequisitionEntity>;
  RequisitionDetail: Array<RequisitionEntity>;
  CheckVerifyEndIf: Scalars['Boolean'];
  GetRequisitionApproveToDo?: Maybe<Array<RequisitionEntity>>;
  GetRequisitionToAprove?: Maybe<Array<RequisitionEntity>>;
  GetRequisitionApprovalHistory?: Maybe<Array<RequisitionOutput>>;
  RequisitionTypeList: RequisitionTypeEntity;
  ClaimParameterListing: Array<ClaimParameterEntity>;
  ClaimParameterListingAll: Array<ClaimParameterEntity>;
  ClaimUserDefineFieldsListing: Array<ClaimUserDefineFieldsEntity>;
  VehicleRateListing: Array<ClaimVehicleRateEntity>;
  PersonnelParameterListingByType: Array<Scalars['JSON']>;
  PersonnelParameterListing: Array<PersonnelParameterEntity>;
  ReportTemplateListing: Array<ReportTemplateEntity>;
  ReportTemplateAssignmentListing: Scalars['JSON'];
  StatesListing: Array<StatesEntity>;
  ViewGeneralSettingSummary: GeneralSettingsLastUpdatedOutput;
  StaffMovementRequestList: StaffMovementRequestEntity;
  HomeSummary: Scalars['JSON'];
  EmployeeMainMenuSummary: Scalars['JSON'];
  EmploymentInfoSummary: Scalars['JSON'];
  LoginUserPerms: Scalars['String'];
  PersonnelMenuSummary: Scalars['JSON'];
  EAPartFListing: Array<EaPartFEntity>;
  EAPartFListingByYear: Array<EaPartFEntity>;
  EISPolicyListing: Array<EisPolicyEntity>;
  EISPolicyDetail: EisPolicyEntity;
  EPFPolicyListing: Array<EpfPolicyEntity>;
  EPFPolicyDetail: EpfPolicyEntity;
  FormItemRatingListing: Array<FormItemRatingEntity>;
  FormItemRatingDetails: FormItemRatingEntity;
  IsFormItemRatingTitleExist: Scalars['Boolean'];
  KpiCategoryListing: Array<KpiCategoryEntity>;
  KpiCategoryDetails: KpiCategoryEntity;
  IsKpiCategoryTitleExist: Scalars['Boolean'];
  KpiPredefinedItemListing: Array<KpiPredefinedItemEntity>;
  KpiPredefinedTemplateItemListing: Array<KpiPredefinedItemEntity>;
  KpiPredefinedItemDetails: KpiPredefinedItemEntity;
  IsKpiPredefinedItemTitleExist: Scalars['Boolean'];
  KpiPredefinedTemplateListing: Array<KpiPredefinedTemplateEntity>;
  KpiPredefinedTemplateDetails: KpiPredefinedTemplateEntity;
  IsKpiPredefinedTemplateTitleExist: Scalars['Boolean'];
  PerformanceMenuSummary: Scalars['JSON'];
  ReviewFormTemplateListing: Array<ReviewFormTemplateEntity>;
  ReviewFormTemplateDetails: ReviewFormTemplateEntity;
  IsReviewFormTemplateTitleExist: Scalars['Boolean'];
  ReviewGradingTableListing: Array<ReviewGradingTableEntity>;
  ReviewGradingTableDetails: ReviewGradingTableEntity;
  IsReviewGradingTitleExist: Scalars['Boolean'];
  ReviewGroupListing: Array<ReviewGroupEntity>;
  ReviewGroupDetails: ReviewGroupEntity;
  IsReviewGroupTitleExist: Scalars['Boolean'];
  ReviewItemListing: Array<ReviewItemEntity>;
  ReviewItemDetails: ReviewItemEntity;
  IsReviewItemCodeExist: Scalars['Boolean'];
  ReviewPolicyListing: Array<ReviewPolicyEntity>;
  ReviewPolicyDetails: ReviewPolicyEntity;
  IsReviewPolicyTitleExist: Scalars['Boolean'];
  ReviewProcessPolicyListing: Array<ReviewProcessPolicyEntity>;
  ReviewProcessPolicyDetails: ReviewProcessPolicyEntity;
  IsReviewProcessPolicyTitleExist: Scalars['Boolean'];
  ReviewTemplateListing: Array<ReviewTemplateEntity>;
  ReviewTemplateDetails: ReviewTemplateEntity;
  IsReviewTemplateTitleExist: Scalars['Boolean'];
  RewardGroupListing: Array<RewardGroupEntity>;
  RewardGroupDetails: RewardGroupEntity;
  IsRewardGroupTitleExist: Scalars['Boolean'];
  PersonnelSecurityPolicyInfoByRole?: Maybe<PersonnelSecurityPolicyEntity>;
  PublicHolidayCalendarListing: Array<HolidayEntity>;
  PublicHolidayListing: Array<HolidayEntity>;
  PublicHolidayForStatesListing: Array<HolidayEntity>;
  SocsoPolicyListing: Array<SocsoPolicyEntity>;
  SocsoPolicyDetail: SocsoPolicyEntity;
  SystemAdminSummaryListing: SystemAdminSummaryOutput;
  SystemAdminMenuSummary: Scalars['JSON'];
  TaxReliefListing: Array<TaxReliefEntity>;
  TaxReliefListingByYear: Array<TaxReliefEntity>;
  AttendanceAnalysis: Array<AttendanceDetailOutput>;
  AttendanceProcessingList: Array<Scalars['JSON']>;
  MonthlyAttendanceProcessingList: Array<Scalars['JSON']>;
  ProcessingListing: Array<AttendanceProcessingBatchEntity>;
  GetAllAutomatedClockDataImportListing?: Maybe<Array<Scalars['JSON']>>;
  GetAutomatedClockDataImportListing?: Maybe<Array<Scalars['JSON']>>;
  GetBeaconDevicesListing?: Maybe<Array<BluetoothBeaconDevicesEntity>>;
  IsDeviceExists: Scalars['Boolean'];
  getChangeShift: Array<ChangeShiftEntity>;
  getShiftByEmployee: Scalars['JSON'];
  getShiftByTLA: Array<ShiftEntity>;
  getShiftGroupByTLA: Array<ShiftGroupEntity>;
  getShiftGroupByShiftPattern: Array<ShiftGroupEntity>;
  getShiftPatternByTLA: Array<ShiftPatternEntity>;
  getEmpByDuty: Array<Scalars['JSON']>;
  getChangeShiftListing: Array<ChangeShiftEntity>;
  ViewChangeShiftApprovalHistory?: Maybe<Array<ChangeShiftEntity>>;
  GetChangeShiftApproveToDo?: Maybe<Array<ChangeShiftEntity>>;
  GetChangeShiftToApprove?: Maybe<Array<ChangeShiftEntity>>;
  getChangeShiftByYear: Array<ChangeShiftEntity>;
  ChangeShiftMenuSummary?: Maybe<Scalars['JSON']>;
  getDailyAllowance: Array<DailyAllowancePolicyEntity>;
  getDailyAllowanceByTLA: Array<DailyAllowancePolicyEntity>;
  DailyAllowanceInUseListing?: Maybe<Array<Scalars['String']>>;
  getDeductionPolicy: Array<DeductionPolicyEntity>;
  getDeductionPolicyByTLA: Array<DeductionPolicyEntity>;
  DeductionPolicyInUseListing?: Maybe<Array<Scalars['String']>>;
  EarnLeavePosting: Scalars['JSON'];
  EarnLeavePeriodSummary?: Maybe<Scalars['JSON']>;
  EmployeeTMSSummary?: Maybe<EmployeeSummary>;
  getJobGradePolicyMatrix: Array<JobGradePolicyMatrixEntity>;
  getMobileCheckIn?: Maybe<Array<MobileCheckInEntity>>;
  QRListing: DocumentsEntity;
  EmpAttendanceHistory: Scalars['JSON'];
  AttendanceHistory: Array<Scalars['JSON']>;
  VerifyScan: Scalars['Boolean'];
  GetLocationRadiusSetting: MobileCheckInEntity;
  getMonthlyAllowanceByTLA: Array<MonthlyAllowancePolicyEntity>;
  MonthlyAllowanceInUseListing?: Maybe<Array<Scalars['String']>>;
  getMonthlyAllowance: Array<MonthlyAllowancePolicyEntity>;
  getOvertimeClaimTable: Array<OvertimeClaimTableEntity>;
  OvertimeClaimTableInUseListing?: Maybe<Array<Scalars['String']>>;
  getOvertimeReason: Array<OvertimeReasonEntity>;
  OTReasonListing: Array<OvertimeReasonEntity>;
  OTReasonByTLA: Array<OvertimeReasonEntity>;
  getOvertimePolicy?: Maybe<Array<OvertimePolicyEntity>>;
  OvertimePolicyInUseListing?: Maybe<Array<Scalars['String']>>;
  PeriodicDeductionInUseListing?: Maybe<Array<Scalars['String']>>;
  getPeriodicDeduction: Array<PeriodicDeductionPolicyEntity>;
  getAllowanceDeduction: Array<AllowanceDeductionRoundingEntity>;
  getOTRounding: Array<OtRoundingEntity>;
  getShift: Array<ShiftEntity>;
  getShiftDay: Array<ShiftDayEntity>;
  getShiftList: Array<ShiftEntity>;
  getShiftGroup: Array<ShiftGroupEntity>;
  EmployeeShiftGroup?: Maybe<Scalars['JSON']>;
  EmployeeShiftGroupAssignmentLog: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  getShiftPattern: Array<ShiftPatternEntity>;
  ShiftPatternListing: Array<ShiftPatternEntity>;
  ValidShiftPatternListing: Array<ShiftPatternEntity>;
  TemplateLibrarySummary?: Maybe<TemplateLibrarySummaryOutput>;
  TMSSummary?: Maybe<TmsSummary>;
  UserDefineEPFPolicyListing: Array<UserDefineEpfPolicyEntity>;
  UserDefineEPFPolicyConditionList: Array<UserDefineEpfPolicyMatrixEntity>;
  getTemplateLibrary: Array<TemplateLibraryAssignmentEntity>;
  getTemplateLibraryAssignment: Array<TemplateLibraryAssignmentEntity>;
  getJobGradeByTLA: Array<Scalars['JSON']>;
  getPayrollTemplateLibrary: Array<TemplateLibraryAssignmentEntity>;
  getPayCycleForPayCalendar: Scalars['JSON'];
  IsPayrollCycleInUse: Scalars['Boolean'];
  GetTemplateLibraryAssignmentInfoline: TemplateLibraryAssignmentInfolineOutput;
  PersonnelTemplateLibraryAssignmentListing: PersonnelTemplateLibraryAssignmentOutput;
  DailyTypeConditionList: Array<DailyTemplateEntity>;
  GetEmployeeDailyPolicies?: Maybe<Scalars['JSON']>;
  DeductionTypeConditionList: Array<DeductionTemplateEntity>;
  GetEmployeeDeductionPolicies?: Maybe<Scalars['JSON']>;
  TMSTypeConditionList: Array<ClaimTemplateEntity>;
  OTPayItemConditionList: Array<TmsotConditionEntity>;
  GetEmployeeOtPolicies?: Maybe<Scalars['JSON']>;
  TMSTimeOffConditionListing?: Maybe<Array<TmsTimeOffConditionEntity>>;
  MonthlyTypeConditionList: Array<MonthlyTemplateEntity>;
  TmsPolicyMenuInfoLinesOutput?: Maybe<Scalars['JSON']>;
  TimeOffListing: Array<TimeOffEntity>;
  TimeOffListingByYear: Array<TimeOffEntity>;
  ViewTimeOffApprovalHistory?: Maybe<Array<TimeOffEntity>>;
  GetTimeOffAproveToDo?: Maybe<Array<TimeOffEntity>>;
  GetTimeOffToApprove?: Maybe<Array<TimeOffEntity>>;
  TimeOffApplicationPeriodSummary?: Maybe<Scalars['JSON']>;
  TimeOffMenuSummary?: Maybe<Scalars['JSON']>;
  TimeOffListingByPeriod: Array<TimeOffEntity>;
  TimeOffPolicyDetailsByEmployeeID: Array<Scalars['JSON']>;
  getTimeOff: Array<TimeOffPolicyEntity>;
  TimeOffReasonByTLA: Array<TimeOffReasonEntity>;
  TimeOffReasonListing: Array<TimeOffReasonEntity>;
  TimeOffSettingListing: Array<TimeOffSettingEntity>;
  TsAccountTypeList: Array<TsAccountTypeEntity>;
  TsAccountCodeList: Array<TsAccountCodeEntity>;
  StaffTimesheetList: Array<StaffTimesheetEntity>;
  TimesheetHours?: Maybe<Scalars['JSON']>;
  TimesheetDates: Scalars['JSON'];
  TimesheetMandayAnalysisByAccCode: Array<TimesheetMandayAnalysisByAccCodeDto>;
  TimesheetMandayAnalysisByActivity: Array<TimesheetMandayAnalysisDto>;
  TimesheetMandayAnalysisByAccType: Array<TimesheetMandayAnalysisByAccTypeDto>;
  TimesheetMandayAnalysisByAccount: Array<TimesheetMandayAnalysisDto>;
  TimesheetCompanyDate: Scalars['JSON'];
  TimesheetSummary: Scalars['JSON'];
  IsActvitiyInUse: Scalars['JSON'];
  ToDoSummary: Scalars['JSON'];
  TrainingAttendanceListing: Array<TrainingAttendanceEntity>;
  GetEmployeeTrainingRecord: Array<EmployeeAttendanceOutput>;
  EmployeeTrainingAttendanceListing: Array<TrainingAttendanceEntity>;
  CompulsaryCourseByJobGradeListing: Array<CompulsaryCourseByJobGradeEntity>;
  GetCompulsaryCourseByJobGradeListingWithCompany: Array<CompulsaryCourseByJobGradeEntity>;
  CourseClassificationListing: Array<CourseClassificationEntity>;
  TrainingCourseListing: Array<TrainingCourseEntity>;
  TrainingCourseListingAll: Array<TrainingCourseEntity>;
  TrainingCourseListingByJobGrade: Array<TrainingCourseEntity>;
  AuthorisedTrainingProviderListing: Array<AuthorisedTrainingProviderEntity>;
  GetProviderListByCourse: Array<CourseProviderOutput>;
  TrainingCostItemListing: Array<TrainingCostItemEntity>;
  GetLatestUpdateForMainMenuModule: TraningModuleLatestUpdateOutput;
  TraineeEvalListing: Array<TraineeEvalEntity>;
  TraineeEvalListingAll: Array<TraineeEvalEntity>;
  SupervisorEvalListing: Array<SupervisorEvalEntity>;
  CourseEvalListing: Array<CourseEvalEntity>;
  TrainerEvalListing: Array<TrainerEvalEntity>;
  GetTrainerEvalLuation: Array<TrainerEvaluationListingOutput>;
  GetSupervisorEvalLuation: Array<SupervisorEvaluationListingOutput>;
  GetFulfilmentByJobGrade: Array<EmployeeFulfilmentOutput>;
  GetFulfilmentByCourse: Array<CourseFulfilmentOutput>;
  GetOSFulfilmentByJobGrade: Array<GetOsFulfilmentByJobGradeOutput>;
  ScheduleTrainingListing: Array<ScheduleTrainingEntity>;
  RegisteredTraineeListing: Array<EmployeeEntity>;
  EmployeeScheduledTrainingListing: Array<ScheduleTrainingEntity>;
  AvailableCoursesListing: Array<ScheduleTrainingEntity>;
  CompulsoryCourseListing: Array<ScheduleTrainingEntity>;
  EmpTrainingAttendanceHistory: Scalars['JSON'];
  TrainingCostBudgetListing: Array<TrainingBudgetEntity>;
  TrainingCostListing: Array<TrainingCostEntity>;
  CostAllocationListing: Array<CostAllocationEntity>;
  HRDFClaimListing: Array<HrdfClaimOutput>;
  TCourseLibraryListing?: Maybe<Array<TrainingCourseLibraryEntity>>;
  TCourseLibraryListingByCategory?: Maybe<Array<CourseCategoryEntity>>;
  AdminTrainingMenuSummary?: Maybe<Scalars['JSON']>;
  TrainingSettingsSummary?: Maybe<Scalars['JSON']>;
  BudgetGroupMatrixListing?: Maybe<Array<BudgetGroupMatrixEntity>>;
  TargetGroupMatrixListing?: Maybe<Array<TargetGroupMatrixEntity>>;
  ApprovalPolicyMatrixListing?: Maybe<Scalars['JSON']>;
  ApprovalPolicyByType?: Maybe<Array<ApprovalPolicyMatrixEntity>>;
  TrainingMaintenanceSummary?: Maybe<Scalars['JSON']>;
  MaintenanceBudgetGroupListing?: Maybe<Array<NewTrainingBudgetEntity>>;
  MaintenanceCourseListing?: Maybe<Array<TargetGroupEntity>>;
  MaintenanceTrainingCourseLibraryListing?: Maybe<Array<CourseCategoryEntity>>;
  MaintenanceTargetHoursListing?: Maybe<Array<TrainingTargetHoursEntity>>;
  TrainingRequestListing?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  TrainingRequestBudgetInfo?: Maybe<Scalars['JSON']>;
  ScheduledTrainingListing?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingRegistrationListing?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  ScheduledTrainingVenueListing?: Maybe<Array<ScheduledTrainingVenueEntity>>;
  getTrainingQRCode: DocumentsEntity;
  OngoingTrainingListing?: Maybe<Array<ScheduledTrainingEntity>>;
  getOngoingTrainingAttendance?: Maybe<ScheduledTrainingEntity>;
  TrainingEvaluationSummary?: Maybe<Scalars['JSON']>;
  TrainingEvaluationListing?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingEvaluationSurveyFormListing?: Maybe<ScheduledTrainingEntity>;
  TrainingEvaluationAttendeesListing?: Maybe<Array<ScheduledTrainingEmployeeEvaluationEntity>>;
  getSurveyFormStructure?: Maybe<SurveyFormEntity>;
  EvaluationResultListing?: Maybe<Array<ScheduledTrainingEntity>>;
  EvaluationResultParticipantListing?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  EmployeeEvaluationResult?: Maybe<ScheduledTrainingEntity>;
  AdminExpensesListing?: Maybe<Array<ScheduledTrainingEntity>>;
  AdminExpensesFilterListing?: Maybe<Array<ScheduledTrainingEntity>>;
  HRDCClaimListing?: Maybe<Array<HrdcClaimEntity>>;
  TrainingFulfillmentSummary?: Maybe<Scalars['JSON']>;
  TrainingFulfillmentByCC?: Maybe<Array<Scalars['JSON']>>;
  TrainingFulfillmentByCCDetails?: Maybe<Scalars['JSON']>;
  TrainingFulfillmentByTH?: Maybe<Array<Scalars['JSON']>>;
  TrainingFulfillmentByTHDetails?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingRecordsListing?: Maybe<Array<Scalars['JSON']>>;
  getEmployeeTrainingRecords?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  EmployeeTrainingRequestListing?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  GetTodoTrainingEvaluation?: Maybe<Array<ScheduledTrainingEmployeeEvaluationEntity>>;
  MySubordinateTrainingSummary: Scalars['JSON'];
  SubordinateTrainingListingSummary: Scalars['JSON'];
  MyTrainingScheduledTrainingListing?: Maybe<Array<ScheduledTrainingEntity>>;
  SubordinateTrainingRecordsListing?: Maybe<Array<Scalars['JSON']>>;
  MySubordinateTrainingFulfillmentSummary?: Maybe<Scalars['JSON']>;
  MySubordinateTrainingFulfillmentByCC?: Maybe<Array<Scalars['JSON']>>;
  MySubordinateTrainingFulfillmentByCCDetails?: Maybe<Scalars['JSON']>;
  MySubordinateTrainingFulfillmentByTH?: Maybe<Array<Scalars['JSON']>>;
  MyTrainingSummary: Scalars['JSON'];
  TrainingCheckInHistory: Scalars['JSON'];
  MyTrainingRequiredTraining: Scalars['JSON'];
  MyTrainingRequiredTrainingCalendarListing?: Maybe<Array<ScheduledTrainingEntity>>;
  SubordinateScheduledTrainingListing?: Maybe<Array<ScheduledTrainingEntity>>;
  SubordinateTrainingRegistrationListing?: Maybe<ScheduledTrainingEntity>;
  TrainingCalendarEmployee?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingCalendarSubordinate?: Maybe<Array<ScheduledTrainingEntity>>;
  GetTrainingToDo?: Maybe<TrainingToDoOutput>;
  TrainingMenuSummary?: Maybe<Scalars['JSON']>;
  CourseCategoryListing?: Maybe<Array<CourseCategoryEntity>>;
  TrainingCourseLibraryListing?: Maybe<Array<TrainingCourseLibraryEntity>>;
  getCourseListingByCategory?: Maybe<Scalars['JSON']>;
  getCourseDetail?: Maybe<TrainingCourseLibraryEntity>;
  TrainingProvidersListing?: Maybe<Scalars['JSON']>;
  getTrainingProviderDetail?: Maybe<TrainingProviderEntity>;
  TrainingProviderHistory?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingSurveyMenuSummary?: Maybe<Scalars['JSON']>;
  SurveyTypeListing?: Maybe<Array<SurveyTypeEntity>>;
  SurveyItemsListing?: Maybe<Array<SurveyItemEntity>>;
  getSurveyItemDetail?: Maybe<SurveyItemEntity>;
  SurveyFormListing?: Maybe<Array<SurveyFormEntity>>;
  getSurveyFormDetail?: Maybe<SurveyFormEntity>;
  TrainingExpensesListing?: Maybe<Array<TrainingExpenseEntity>>;
  BudgetGroupListing?: Maybe<Array<BudgetGroupEntity>>;
  TargetGroupListing?: Maybe<Array<TargetGroupEntity>>;
  EmployeeTrainerProfilePersonnelInfo: EmployeeEntity;
  TrainerProfileListing?: Maybe<Array<TrainerProfileEntity>>;
  TrainerProfileHistory?: Maybe<Array<ScheduledTrainingEntity>>;
  TrainingApprovalPoliciesSummary?: Maybe<Scalars['JSON']>;
  TrainingApprovalPoliciesListing?: Maybe<Scalars['JSON']>;
  IsTrainingProviderCompanyIDExist: Scalars['Boolean'];
  IsTrainingCourseLibraryCodeExist: Scalars['Boolean'];
  IsTrainingProviderNameExist: Scalars['Boolean'];
  IsTrainingEventNameExist: Scalars['Boolean'];
  IsTrainerProfileEmailExist: Scalars['Boolean'];
  CourseLibraryListing?: Maybe<Array<TrainingCourseLibraryEntity>>;
  GetPersonalTTDAInfo: Scalars['JSON'];
  getEntityRoleUserAssignment: Array<RoleUserEntAssignmentEntity>;
  getRoleList?: Maybe<Array<Scalars['JSON']>>;
  getRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  userRoleIDbyUser: Array<RoleUserEntAssignmentEntity>;
  getCompanyPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getRolePermissionByUser: Scalars['JSON'];
  getUsersRoleContract: Array<UserEntity>;
  GetUserRolePermissionByRole: Scalars['JSON'];
  AllowancePolicy: Array<AllowanceBenfitEntity>;
  AllowanceEntitlementListing: Array<AllowanceItemEntity>;
  AllowanceEntitlementDetail: Array<AllowanceItemEntity>;
  CompanySummaryDates: CompanyViewLastUpdatedOutput;
  CurrentCountry?: Maybe<Scalars['JSON']>;
  CountryListing?: Maybe<Array<CountryEntity>>;
  PayProcessSelectPayPeriod: Array<PayPeriodEntity>;
  PayProcessSelectPayrollCycle: Array<PayrollCycleEntity>;
  PayProcessEmployeeList: Array<EmployeeEntity>;
  PayProcessDetail: Array<PayrollDetailEntity>;
  loggedInEmployeeProfile: EmployeeResponse;
  loggedInUserProfile: UserEntity;
  TestFCM: Scalars['Boolean'];
  TestEmail: Scalars['Boolean'];
  MobileNotification: Scalars['Boolean'];
  loginSSO: Scalars['JSON'];
  VerifyRedirectDomainToken: Scalars['String'];
  activeUser: Scalars['Float'];
  getUserListing?: Maybe<Array<Scalars['JSON']>>;
  getEmployeeUserListing?: Maybe<Array<Scalars['JSON']>>;
  getUsersByAccount?: Maybe<Array<Scalars['JSON']>>;
  getActiveUsersByAccount?: Maybe<Array<Scalars['JSON']>>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  GetExceededUserWarning?: Maybe<Scalars['Boolean']>;
  UserProfilePic?: Maybe<Scalars['JSON']>;
  GetNonAccessUsers?: Maybe<Array<Scalars['JSON']>>;
  GetNonAccessUsersCount?: Maybe<Scalars['JSON']>;
  CheckOnPremServer?: Maybe<Scalars['String']>;
  GoogleApiKey?: Maybe<Scalars['JSON']>;
  testWorkFlowServices: Scalars['JSON'];
};


export type QueryGetDutyRosterArgs = {
  IsExcelFile?: Maybe<Scalars['Boolean']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  DutyRosterID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  DutyDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterInfoLineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterEmployeeSumArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterDatesArgs = {
  date: Scalars['DateTime'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterSummaryByEmployeeArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterSummaryArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDutyRosterByEmployeeListV2Args = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['String']>;
};


export type QueryGetDutyRosterByEmployeeArgs = {
  GetLeaveTimeOffOvertimeData?: Maybe<Scalars['Boolean']>;
  ToDate?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetDutyRosterByEmployeeListArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['String']>;
};


export type QueryNotificationListingArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryNewsReadListingArgs = {
  NewsID?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeCalendarArgs = {
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetAttendanceRecordPairedDataArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetAttendanceRecordPairedDataCountArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetFinalizeAnalysisDataByEmployeeArgs = {
  EmployeeID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetFinalizeAnalysisDataArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetFinalizeAnalysisDataCountArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetClockPairingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  Overwrite: Scalars['Boolean'];
};


export type QueryGetClockPairingRedirectArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  Overwrite: Scalars['Boolean'];
  payload?: Maybe<Scalars['JSON']>;
};


export type QueryGetAttenRecordsArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  WorkDate: Scalars['DateTime'];
};


export type QueryAttendanceRecordByHikvisionArgs = {
  keyword?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordByHikvisionByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetAttendanceRecordArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  input?: Maybe<AttendanceRecordInput>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetAttendanceRecordwithHikvisionArgs = {
  DateTime?: Maybe<Scalars['DateTime']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  input?: Maybe<AttendanceRecordInput>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryAttendanceRecordSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordByExcelArgs = {
  DateTime?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordListingByClockTypeArgs = {
  ClockType: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryGetAttendanceRecordLastModifiedArgs = {
  companyID: Scalars['String'];
  timezoneOffset: Scalars['Float'];
  year: Scalars['DateTime'];
};


export type QueryGetAttendanceRecordTotalCountArgs = {
  companyID: Scalars['String'];
  year: Scalars['DateTime'];
};


export type QueryGetContactTracingArgs = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryGetAttendanceRecordManualEntryPageTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  input?: Maybe<AttendanceRecordInput>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetPrivateDocumentByFileNameArgs = {
  Documents: Array<Scalars['JSON']>;
};


export type QueryAppraisalCategoryListingArgs = {
  AppraisalType: Scalars['String'];
};


export type QueryGetAppraisalTemplateByJobPositionArgs = {
  JobPositionID: Scalars['String'];
};


export type QueryGetAppraisalCompanySettingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyAppraisalTempleteArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyAppraisalPolicyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryBonusSimulationListingArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryIncrementSimulationListingArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetBonusRecordByCompanyOrgUnitListArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetIncrementRecordByCompanyOrgUnitListArgs = {
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAppraisalTempleteListingArgs = {
  TempleteType: Scalars['String'];
};


export type QueryAppraisalTempleteListingAllArgs = {
  TempleteType: Scalars['String'];
};


export type QueryGetEmployeeListingByscheduledAndQuaterArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  ScheduledID: Scalars['String'];
  QuaterID: Scalars['String'];
};


export type QueryGetEmployeeAppraisalHeaderArgs = {
  PeriodYear: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeAppraisalDetailsArgs = {
  EmployeeAppraisalHeaderID: Scalars['String'];
};


export type QueryModerationListingArgs = {
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetAppraisalDefaultSettingByJobPositionArgs = {
  JobPositionID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeKpiTempleteLatestVersionArgs = {
  KPIPlanningID?: Maybe<Scalars['String']>;
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeKpiPlanningRecordArgs = {
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryGetSubordinateKpiPlanningArgs = {
  EmployeeID: Array<Scalars['String']>;
};


export type QueryGetEmployeeKpiPlanningByYearArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetKpiPlanningDetailsArgs = {
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
};


export type QueryStaffPerformanceAnalysisSummaryListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  QuaterID: Scalars['String'];
};


export type QueryApprovalPolicySummaryArgs = {
  Category?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryApprovalPolicyAssignmentSummaryArgs = {
  Category: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryApprovalPolicyListArgs = {
  Category: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryApprovalPolicyDetailsArgs = {
  Category: Scalars['String'];
  ApprovalPolicyID: Scalars['String'];
};


export type QueryIsApprovalPolicyTitleExistArgs = {
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  Category: Scalars['String'];
  Title: Scalars['String'];
};


export type QueryApprovalPolicyAssignmentListArgs = {
  Category: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryNewApprovalPolicyAssignmentListArgs = {
  Category: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryEmployeeListingByRoleIdArgs = {
  RoleID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryApproverListByCompanyArgs = {
  Category?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryEmployeeAttendanceCorrectionRequestListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeAttendanceCorrectionRequestDetailArgs = {
  AttendanceCorrectionRequestHeaderID: Scalars['String'];
};


export type QueryIsAttCorReqRecordAlreadyExistAndPendingApprovalArgs = {
  EmployeeID: Scalars['String'];
  SelectedDate: Scalars['String'];
};


export type QueryAttendancePeriodSummaryForAttendanceCorrectionArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};


export type QueryAdminAttendanceCorrectionRequestListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  filterText: Scalars['String'];
  ApStatus: Array<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAdminAttendanceCorrectionRequestListingTotalCountArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  ApStatus: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetAttendanceCorrectionToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryAttendancePostingListingArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  isAttendanceGrouped?: Maybe<Scalars['Boolean']>;
  PostStatus?: Maybe<Array<Scalars['String']>>;
  AttendanceType?: Maybe<AttendanceTransactionType>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAttendancePostingListingCountArgs = {
  PostStatus?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  isAttendanceGrouped?: Maybe<Scalars['Boolean']>;
  AttendanceType?: Maybe<AttendanceTransactionType>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryExportAttendanceProcessingArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  AttendanceType?: Maybe<AttendanceTransactionType>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAdjustedValueQuantityDetailsArgs = {
  EmployeeID: Scalars['String'];
  PayItemID: Scalars['String'];
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLastPostedAttendanceProccessArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryAttendancePeriodSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};


export type QueryGetLatestAttandanceCalculationDateByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPersonalInfoArgs = {
  AccountID: Scalars['String'];
  RequestKey: Scalars['String'];
};


export type QueryGetOvertimeArgs = {
  WorkDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
};


export type QueryGetTotalOverTimeArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CutOffDay: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryOvertimeListingArgs = {
  IsExcelFileOnly?: Maybe<Scalars['Boolean']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryOvertimeWithdrawalCheckingArgs = {
  WorkDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryOvertimeListingByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOvertimeListingByYearArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  WorkDate: Scalars['DateTime'];
};


export type QueryOvertimeListingByDateTimeV2Args = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryOvertimeListingByDateTimeArgs = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryOvertimeListingByPeriodV2Args = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryOvertimeListingByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryViewOvertimeApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetOvertimeApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetOvertimeToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryOvertimePeriodSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryOvertimeMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeOvertimeCheckingArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  Duration?: Maybe<Scalars['Float']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
};


export type QueryBatchOvertimeListingArgs = {
  BatchOvertimeStatus?: Maybe<Array<OvertimeStatus>>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAdminBatchOvertimeListingArgs = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  BatchOvertimeStatus?: Maybe<Array<OvertimeStatus>>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetBatchOvertimeToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryCompanyBusinessInsightArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightSummaryArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightRawListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightStatutoryListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightSalaryOverheadListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightPayItemListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightSummaryArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryTopLeaveTakerByTypeArgs = {
  LeaveTypeIDs: Array<Scalars['String']>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryLeaveBiLeaveTakenListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryTopClaimTakerArgs = {
  ClaimTypeIDs: Array<Scalars['String']>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryClaimBiClaimTakenArgs = {
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs: Array<Scalars['String']>;
  Year: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryTopRequisitionTakerArgs = {
  RequisitionType: Array<RequisitionType>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryRequisitionTakenSummaryByTypeArgs = {
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryHeadCountSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOverHeadSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryTurnOverSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOvertimeSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryLeaveSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryClaimSummaryArgs = {
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryClaimByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryHeadCountAndBasicSalaryByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmployeeYosArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmployeeSalaryArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmployeeAgeArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmpGenderArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmpRaceArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryEmpEmploymentTypeArgs = {
  MonthYear: Scalars['String'];
  OrgUnitIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryLeaveByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOverheadByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryContributionByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryOvertimeHrsAndCostArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryStaffTurnOverByMonthArgs = {
  OrgUnitIDs: Array<Scalars['String']>;
  MonthYear: Scalars['String'];
  CompanyIDs: Array<Scalars['String']>;
};


export type QueryGetClaimManagementListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetClaimRecordsListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  superiorRejectToDraft?: Maybe<Scalars['Boolean']>;
  input: ClaimRecordsInput;
};


export type QueryGetClaimRecordsTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  superiorRejectToDraft?: Maybe<Scalars['Boolean']>;
  input: ClaimRecordsInput;
};


export type QueryClaimMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetClaimRecordsDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimRecordsHistoryListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryGetApprovedClaimVerificationListingArgs = {
  Incomplete?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ApprovalClaimVerificationInput;
};


export type QueryGetApprovedClaimCountArgs = {
  input: ApprovalClaimVerificationInput;
};


export type QueryGetApprovedClaimVerificationDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimPostingListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  userID?: Maybe<Scalars['String']>;
  PaymentMethod: Scalars['String'];
  input: ApprovalClaimVerificationInput;
};


export type QueryGetClaimPostingCountArgs = {
  PaymentMethod: Scalars['String'];
  input: ApprovalClaimVerificationInput;
};


export type QueryGetClaimPostingDetailArgs = {
  PaymentMethod: Scalars['String'];
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimPostingPeriodListingArgs = {
  input: ClaimPostingPeriodListingInput;
};


export type QueryGetClaimPostingPeriodListingV2Args = {
  PaymentMethod?: Maybe<Scalars['String']>;
  input: ClaimPostingPeriodListingInput;
};


export type QueryGetClaimEntitlementByClaimTypeListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
  ClaimTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimEntitlementByClaimTypeListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
  ClaimTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimEntitlementByEmployeeListingArgs = {
  IsV2?: Maybe<Scalars['Boolean']>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetClaimToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetClaimApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryClaimHomeMenuSummaryArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimReportDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryClaimCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmpListingbyCompanyArgs = {
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmpsClaimEntitlementArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetMyClaimEntitlementArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpsClaimBalanceArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  input: EmpClaimEntitlementInput;
};


export type QueryGetMyClaimBalanceArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetMyClaimPoolBalanceArgs = {
  isV5?: Maybe<Scalars['Boolean']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetMyClaimListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryGetMyClaimDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryVerifyMyClaimArgs = {
  VerfiyClaimInput: VerfiyClaimInput;
};


export type QueryGetMainClaimListingArgs = {
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpClaimListingArgs = {
  Input: Array<GetEmpClaimListingInput>;
};


export type QueryGetApprovedDetailArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimRecordListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetApproveClaimVerificationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetClaimPostingManagementArgs = {
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimRecordHistoryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetCompletedDetailArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryMyClaimDetailArgs = {
  ClaimDetailID: Scalars['String'];
};


export type QueryClaimListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetAllExpenseAccountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryClaimValidityDetailsArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeListingArgs = {
  ClaimCategory?: Maybe<ClaimCategory>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeListingAllArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeTemplateListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeTemplateListingForCheckingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimPolicyListingArgs = {
  ClaimCategory?: Maybe<ClaimCategory>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimPolicyListingAllArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeTemplateDetailArgs = {
  ClaimBenefitID: Scalars['String'];
};


export type QueryGetClaimSettingsListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryClaimTemplateListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  JobGradePolicyID?: Maybe<Scalars['String']>;
};


export type QueryIsClaimSubjectExistsArgs = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
  input: ClaimRecordsInput;
  Subject: Scalars['String'];
};


export type QueryClaimPoolListingArgs = {
  ClaimPoolID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeePoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeClaimPoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryBankDetailArgs = {
  BankInfoIDs: Array<Scalars['String']>;
};


export type QueryBankListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsAccountNoExistsArgs = {
  CompanyID: Scalars['String'];
  AccountNo: Scalars['String'];
};


export type QueryIsAccountNameExistsArgs = {
  CompanyID: Scalars['String'];
  AccountName: Scalars['String'];
};


export type QueryHolidayListingArgs = {
  States: Scalars['String'];
  PeriodYear: Scalars['Float'];
};


export type QueryCompanyOffDaysListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanyOffDaysListingWithFiltersArgs = {
  StateID: Scalars['String'];
  Title: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryOffRestDayListingArgs = {
  StateID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCalendarStateListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeStateAssignListingArgs = {
  IsDefault: Scalars['Boolean'];
  EmployeeFormStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  StateID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCalendarPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeHomeCalendarArgs = {
  CompanyID: Scalars['String'];
  LeaveDate: Scalars['DateTime'];
};


export type QueryCompanyDeclaredHolidayListingArgs = {
  ContactID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  StateID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryCompanyDeclaredHolidayListingByCalendarPolicyIdArgs = {
  CalendarPolicyID: Scalars['String'];
};


export type QueryDocumentListingArgs = {
  FolderID?: Maybe<Scalars['String']>;
  ParentIDs?: Maybe<Array<Scalars['String']>>;
  ParentID?: Maybe<Scalars['String']>;
  DocumentType: DocumentType;
};


export type QueryDocumentListingCountArgs = {
  ParentIDs?: Maybe<Array<Scalars['String']>>;
  ParentID?: Maybe<Scalars['String']>;
};


export type QueryPersonnelDocumentListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  DocumentType: DocumentType;
};


export type QueryCompanyFolderListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanyListingByEmailArgs = {
  EmployeeEmail?: Maybe<Scalars['String']>;
};


export type QueryEmployeeCompanyListingArgs = {
  EmployeeEmail?: Maybe<Scalars['String']>;
};


export type QueryCompanyListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryCompanySummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanySummarySimplifiedArgs = {
  CompanyID: Scalars['String'];
};


export type QueryJobPositionListingArgs = {
  JobDesignationID?: Maybe<Scalars['String']>;
};


export type QueryJobDesignationDetailArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobDesignationGradeListingArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobGradeByJobDesignationListingV2Args = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobGradeByJobDesignationListingArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobDesignationListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryRecruitmentSourceListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryGetCompanyCyclePeriodListV2Args = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyAltAddressListingArgs = {
  IsStatutoryInfo?: Maybe<Scalars['Boolean']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyStatutoryAccountListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCompanyStatutoryDetailArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsCompanyHierarchyExistsArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyHierarchyListArgs = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListV2Args = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListArgs = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListWithFilterArgs = {
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyOrgUnitListWithEmpCheckArgs = {
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryJobPositionByOrgUnitListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryJobPositionAssignmentListArgs = {
  CompanyIDArray?: Maybe<Array<Scalars['String']>>;
  JobDesignationID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryCompanyAccessPermListArgs = {
  usePayload?: Maybe<Scalars['Boolean']>;
  RoleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmpCoOrgUnitListArgs = {
  EmployeeEmail?: Maybe<Scalars['String']>;
};


export type QueryDepartmentListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDepartmentDivisionListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDepartmentDetailArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryDivisionListingArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryDivisionDetailArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryGetInsuranceSchemeArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  InsuranceSchemeID?: Maybe<Scalars['String']>;
};


export type QueryInsuranceSchemeListingArgs = {
  InsuranceSchemeID?: Maybe<Scalars['String']>;
};


export type QueryInterbankDetailArgs = {
  InterbankGiroIDs: Array<Scalars['String']>;
};


export type QueryInterbankListingBulkArgs = {
  BankInfoID: Array<Scalars['String']>;
};


export type QueryInterbankListingArgs = {
  BankInfoID: Scalars['String'];
};


export type QueryGetBankInfoListingV2Args = {
  BankName?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryMyJobAccountListingArgs = {
  JobAccountID: Scalars['String'];
};


export type QueryJobAccountListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryJobAccountDetailArgs = {
  JobAccountID: Scalars['String'];
};


export type QueryJobGradeDetailArgs = {
  JobGradeID: Scalars['String'];
};


export type QueryJobGradeListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryJobGradeListingByCompanyArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryJobGradePolicyListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryJobGradePolicyListingAllArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveRoundingListingArgs = {
  LeaveRoundingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryLeaveCompulsoryPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveTemplateListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  JobGradePolicyID?: Maybe<Scalars['String']>;
};


export type QueryLhdnReportListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLhdnReportDropdownListingArgs = {
  ReportYear: Scalars['Float'];
};


export type QueryLhdnReportDetailArgs = {
  PayItemID?: Maybe<Scalars['String']>;
};


export type QueryGetLhdnReportListingArgs = {
  SubscriptionAccountID: Scalars['String'];
  periodYear: Scalars['Float'];
};


export type QueryNotificationPolicyDetailArgs = {
  NotificationPolicyID: Scalars['String'];
};


export type QueryNotificationPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryNotificationPolicyConditionListingArgs = {
  NotificationPolicyID: Scalars['String'];
};


export type QueryNotificationPolicyConditionDetailArgs = {
  NotificationPolicyConditionID: Scalars['String'];
};


export type QueryNotificationPolicySummaryDatesArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleDetailArgs = {
  PayrollCycleID: Scalars['String'];
};


export type QueryPayrollProcessPayrollCycleListingByTlaArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleByPayPeriodArgs = {
  ToPayPeriod: Scalars['DateTime'];
  FromPayPeriod: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryPayPeriodsByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayItemListingByTlaArgs = {
  PayItemID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingV2Args = {
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingV3Args = {
  CompanyID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
};


export type QueryIsPayrollCycleLockedArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryReportingStructureGroupOptionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryReportingCompanyGroupListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryReportingGroupTitleExistArgs = {
  ReportingGroupID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryGetReportingStructureListingArgs = {
  CompanyID: Scalars['String'];
  ReportingGroupID: Scalars['String'];
};


export type QueryGetSuperiorArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetSkillSetArgs = {
  Name?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetSkillSetDetailArgs = {
  Name?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SkillSetDetailID?: Maybe<Scalars['String']>;
};


export type QueryGetLeaveOffDayArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveOffDayByAllStateWithCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLeavePolicyByEmployeeArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeLeavePolicyListByDateArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetPayItemPolicyArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetLeavePolicyByJobGradeIdArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID: Scalars['String'];
};


export type QueryGetOffboardEncashPeriodListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetPlatformNewLeaveTypePolicyArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryGetMyLeaveEntitlementArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpLeaveBalanceArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryGetMyLeaveBalanceArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeCalendarLeaveDetailsArgs = {
  input: EmpCalendarLeaveListing;
};


export type QueryEmployeeLeaveListingArgs = {
  input: EmpLeaveListingInput;
};


export type QueryGetEmpLeaveDetailArgs = {
  input: EmpLeaveDetailInput;
};


export type QueryGetLeaveRoundedValueArgs = {
  LeaveValue: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryLeaveTypeListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveReasonListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveDiseaseListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeavePolicyListingArgs = {
  ShortNoticeOptioIDs?: Maybe<Array<Scalars['String']>>;
  AvailableBasisIDs?: Maybe<Array<Scalars['String']>>;
  EntitlementMethodIDs?: Maybe<Array<Scalars['String']>>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeavePolicyByIdArgs = {
  LeavePolicyID: Scalars['String'];
};


export type QueryLeaveTypeTemplateListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveTypeTemplateDetailArgs = {
  LeaveBenefitID: Scalars['String'];
};


export type QueryGetIntegratedLeaveBalanceArgs = {
  sTaken: Scalars['Float'];
  hTaken: Scalars['Float'];
  sEntilement: Scalars['Float'];
  hEntitlement: Scalars['Float'];
};


export type QueryGetEmployeesLeaveSummaryReportArgs = {
  IsReport?: Maybe<Scalars['Boolean']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  IsResigned?: Maybe<Scalars['Boolean']>;
  Sort?: Maybe<Scalars['Float']>;
  Level?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeesLeaveSummaryArgs = {
  AnnualLeaveOnly?: Maybe<Scalars['Boolean']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  IsV2?: Maybe<Scalars['Boolean']>;
  IsResigned?: Maybe<Scalars['Boolean']>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryEnhanceGetEmployeesLeaveSummaryArgs = {
  IsResigned?: Maybe<Scalars['Boolean']>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveEntitlementByLeaveTypeListingTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveEntitlementByLeaveTypeListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsV2?: Maybe<Scalars['Boolean']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetAlBalanceForResignedEmployeesArgs = {
  toDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeCalendarArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetTakenLeaveByEmployeeIdArgs = {
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeEntitlementByYearArgs = {
  CompanyID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryGetLeaveAdjustmentEmployeeListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  LeaveFrequency: LeaveFrequency;
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveAdjustmentEmployeeListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  LeaveFrequency: LeaveFrequency;
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeLeaveCriteriaByEmpIdArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetUserDashboardSettingArgs = {
  ContactID?: Maybe<Scalars['String']>;
};


export type QueryGetOffboardingListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  OffboardingCardType: DashboardCardType;
};


export type QueryGetEmployeeLeaveListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  LeaveCardType: DashboardCardType;
};


export type QueryGetEmployeeLeaveSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetConfirmationDueListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ConfirmationCardType: DashboardCardType;
};


export type QueryGetAttendanceListArgs = {
  ContactID?: Maybe<Scalars['String']>;
  AttendanceCardType: DashboardCardType;
};


export type QueryGetDataChangesListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentArgs = {
  DocumentID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentsArgs = {
  DocumentIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeDependentsSummaryArgs = {
  EmployeeDependentsID: Scalars['String'];
};


export type QuerySelectedDataChangesApprovalPolicyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDataChangesToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryViewDataChangesApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetDataImportTemplateArgs = {
  Type: Scalars['String'];
};


export type QueryShopeeImportArgs = {
  SequenceNo: Scalars['Float'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  IsLastProcess: Scalars['Boolean'];
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  CompanyID: Scalars['String'];
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type QueryShopeeImportRedirectArgs = {
  SequenceNo: Scalars['Float'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  IsLastProcess: Scalars['Boolean'];
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput>;
  PayItemMapping: Array<PayItemMappingInput>;
  CompanyID: Scalars['String'];
  ColumnDataMapping: Array<Scalars['String']>;
  File: Scalars['Upload'];
};


export type QueryEmployeePendingActivateListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryDelegationListArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryDelegationInfoLineArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryBulletinListingArgs = {
  NewsID: Scalars['String'];
};


export type QueryNewsListingArgs = {
  isValid?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsPublishedOnly: Scalars['Boolean'];
  EndMonth: Scalars['String'];
  StartMonth: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryELearningNewsCountArgs = {
  isValid?: Maybe<Scalars['Boolean']>;
  IsPublishedOnly: Scalars['Boolean'];
  EndMonth: Scalars['String'];
  StartMonth: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryNewsCountArgs = {
  isValid?: Maybe<Scalars['Boolean']>;
  IsPublishedOnly: Scalars['Boolean'];
  EndMonth: Scalars['String'];
  StartMonth: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimDetailReportListingArgs = {
  input: ClaimDetailListingReportInput;
};


export type QueryTtda_ClaimDetailReportListingArgs = {
  input: ClaimDetailListingReportInput;
};


export type QueryGetPayrollReportListingArgs = {
  ReportType: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodID?: Maybe<Scalars['String']>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetPayrollReportListingRedirectArgs = {
  ReportType: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodID?: Maybe<Scalars['String']>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCompanyStatutoryListingArgs = {
  Types?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetPayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryEmployeeMasterExcelReportArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryAttendanceListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryEmployeeClockingListingReportArgs = {
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryAbsenteeismListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryIncompleteClockingListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryIrregularAttendanceListingReportArgs = {
  IsExcludeAbsent?: Maybe<Scalars['Boolean']>;
  IsExcludeIncomplete?: Maybe<Scalars['Boolean']>;
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryLateInEarlyOutListingReportArgs = {
  DayTypes?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  JobDesignationIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  ShiftGroupIDs?: Maybe<Array<Scalars['String']>>;
  ShiftIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
  CompanyIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryGetNextDocNumberArgs = {
  RefTableName: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryDocumentNumberingListArgs = {
  IsStatusActive?: Maybe<Scalars['Boolean']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
};


export type QueryDocumentNumberingAssignmentListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryHasEmployeeDocNumberArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetBatchEntryByShiftArgs = {
  BatchEntryByShiftID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetBatchEntryByPatternArgs = {
  BatchEntryByShiftPatternID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeAppraisalArgs = {
  EmployeeAppraisalID?: Maybe<Scalars['String']>;
};


export type QueryCareerLogListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeDiaryArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeDocumentListingArgs = {
  ParentID: Scalars['String'];
};


export type QueryTestNotificationArgs = {
  msg: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeListForSpecificSectionArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ExcludeMonth?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeListForSpecificSectionCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  ExcludeMonth?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeListForReportingArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeListForReportingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
};


export type QuerySimpleEmployeeListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeListingArgs = {
  exitReason?: Maybe<Array<Scalars['String']>>;
  Page?: Maybe<Scalars['String']>;
  CheckForResignedDate?: Maybe<Array<Scalars['String']>>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeListingv2Args = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeListingCountArgs = {
  exitReason?: Maybe<Array<Scalars['String']>>;
  Page?: Maybe<Scalars['String']>;
  CheckForResignedDate?: Maybe<Array<Scalars['String']>>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QuerySubordinateMenuSummaryArgs = {
  ApproverID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryGetOrgChartArgs = {
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateTreeArgs = {
  level?: Maybe<Scalars['Float']>;
  ModuleName?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateTreeTimeAttendanceArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
  ModuleName?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryTmsEmployeeListingArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  exitReason?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryTmsEmployeeListingCountArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  exitReason?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeListingBySubscriptionAccountArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeListingBySubscriptionAccountCountArgs = {
  Page?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryPersonnelEmployementInfoListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryPersonnelEmployementInfoListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeListForJobInfoArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
};


export type QueryEmployeeAppSummaryArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeAppDocSummaryArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeDelegationSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeContactIdArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeInfoKpiArgs = {
  CompanyID: Scalars['String'];
  EmployeeNo: Scalars['String'];
};


export type QueryZakatSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeSummaryBySubscriptionArgs = {
  SubscriptionUserID: Scalars['String'];
};


export type QueryGetEmployeePrevContributionArgs = {
  PeriodYear?: Maybe<Scalars['Float']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryIsSuperUserEmployeeArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryFilterRequisitionListingArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
  RequisitionType: Array<Scalars['String']>;
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryRequisitionListingArgs = {
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryFixedAllowanceListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryFilterEmployeeLeaveRecordsArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  LeaveTypeIDs: Array<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryTaxReliefListingDefaultExcludedArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryEmployeeTaxReliefListingArgs = {
  PeriodYear: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeTaxReliefDetailsArgs = {
  EmployeeTaxReliefID: Scalars['String'];
};


export type QueryEmployeeAssetListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeAssetDetailArgs = {
  EmployeeAssetID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindDetailForYearArgs = {
  PeriodYear: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindDetailArgs = {
  EmployeeBenefitInKindID: Scalars['String'];
};


export type QueryEmployeeDependentsListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryHasEmergencyContactArgs = {
  DependentID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryMyEmployeeInfoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryMyNewsListArgs = {
  EmployeeID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMyApplicationDetailArgs = {
  RequestID: Scalars['String'];
  RequestType: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeCp22ListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryPayrollEmployeeListingArgs = {
  PayCycleID?: Maybe<Array<Scalars['String']>>;
  PayPeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeListingWithPayrollInputArgs = {
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeDirectoryListingArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeDirectoryListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryEmployeeDirectoryDetailsArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeNotificationListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateListingArgs = {
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  StaffCategoryIDs?: Maybe<Array<Scalars['String']>>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeTrainingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeUserDefineFieldsListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeUserDefineFieldsDetailListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeUserDefineFieldsDetailSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryZakatContributionListingArgs = {
  input: ZakatContributionInput;
};


export type QueryZakatContributionDetailsArgs = {
  ID: Scalars['String'];
};


export type QueryIsZakatPeriodOverlapArgs = {
  PayrollCycleID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  ZakatID?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeAppSecurityListingArgs = {
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeAppSecurityAssignmentListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeAppSecurityAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeAppSecurityPermissionsByContactIdArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  Permissions?: Maybe<Array<HrPermission>>;
  ContactID: Scalars['String'];
};


export type QueryEaDocumentsByEmployeeIdArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryPayslipDocumentsByEmployeeIdArgs = {
  Year?: Maybe<Scalars['Float']>;
  EmployeeID: Scalars['String'];
};


export type QueryEonDocumentsByBatchArgs = {
  Type: Scalars['String'];
};


export type QuerySystemEonDocumentsCountArgs = {
  Type: Scalars['String'];
};


export type QueryHelpdeskListingReportArgs = {
  input: HelpdeskListingReportInput;
};


export type QueryHelpdeskSubjectDetailArgs = {
  SubjectID: Scalars['String'];
};


export type QueryIsHelpdeskSubjectExistArgs = {
  SubjectID?: Maybe<Scalars['String']>;
  Subject: Scalars['String'];
};


export type QueryGetHelpdeskTicketSummaryArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
};


export type QueryHelpdeskTicketDetailArgs = {
  HelpdeskTicketID: Scalars['String'];
};


export type QueryHelpdeskTicketListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Assignee?: Maybe<Array<Scalars['String']>>;
  HelpdeskSubjectType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RequestType: Scalars['String'];
};


export type QueryHelpdeskToDoListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryBlockLeaveListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryBlockLeaveEmpListingArgs = {
  UntilDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  LeaveTypeID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetCarryForwardEncashmentPostedListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetCarryForwardEncashmentPendingListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryViewLeaveApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetLeaveRecordsListingArgs = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  effectiveMonth?: Maybe<Scalars['String']>;
  input: LeaveRecordsListingInput;
};


export type QueryGetLeaveRecordsTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  input: LeaveRecordsListingInput;
};


export type QueryGetLeaveReplacementListingByEmployeeArgs = {
  AsToDate?: Maybe<Scalars['String']>;
  LeaveTypeID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveAdjustmentListingArgs = {
  input: LeaveAdjustmentListingInput;
};


export type QueryGetCompulsoryLeaveListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetCompulsoryLeaveTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetCompulsoryLeaveDetailArgs = {
  LeaveCompulsoryID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeavePostingListingArgs = {
  input: LeavePostingListingInput;
};


export type QueryGetLeaveApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveListingArgs = {
  FromDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveToAproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryLeaveAdjustmentDetailArgs = {
  LeaveHeaderID: Scalars['String'];
};


export type QueryGetLeavePostingPeriodListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPendingListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPendingListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPostedListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPostedListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYearEndCarryForwardListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryFilterGetYearEndCarryForwardListingArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryLeaveMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveHomeSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryLeaveTakenListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveByMonthCalendarArgs = {
  CalendarDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveByMonthCalendarToDoArgs = {
  CalendarDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveMaxBalancePendingListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  AsToDate: Scalars['DateTime'];
};


export type QueryGetLeaveMaxBalancePostedListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  AsToDate: Scalars['DateTime'];
};


export type QueryCheckLeaveWithdrawalValidityArgs = {
  LeaveTypeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubmitDate: Scalars['DateTime'];
};


export type QueryGetCareerMovementEmployeesArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  CompanyID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetCareerMovementEmployeesCountArgs = {
  CompanyID: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryLeaveApprovalPolicyListingArgs = {
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveOutOfStateListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGlAccountMappingListArgs = {
  GLAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetAccountMappingAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGlAccountHeaderListWithDetailsArgs = {
  Type?: Maybe<Scalars['String']>;
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  GLAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryPayItemAmountByOrgAndPayPeriodArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
};


export type QueryClaimAccountMappingListArgs = {
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetClaimAccountMappingAssignmentArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryClaimAccountHeaderListWithDetailsArgs = {
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryPeClaimAccountHeaderListWithDetailsArgs = {
  Type?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  PeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetClaimAccountAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetGlClaimRecordsListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryClaimGlCycleProcessArgs = {
  Type?: Maybe<Scalars['String']>;
  input: ClaimRecordsInput;
};


export type QueryCostCenterMappingListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsGlProcessedArgs = {
  Type?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetGlDataArgs = {
  AccountID: Scalars['String'];
  RequestKey: Scalars['String'];
  TransactionType: Scalars['String'];
  EntityCode: Scalars['String'];
};


export type QueryProjectCostCenterMappingListForGlExportArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryCostCenterMappingListForGlExportArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetMultiCurrencySetupArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryCheckMultiCurrencyAlreadyExistArgs = {
  CurrencyName: Scalars['String'];
  CurrencyCode: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryGetEmployeeRoleNameArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMultiCurrencyDetailsListingArgs = {
  MultiCurrencyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMultiCurrencyListingByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCheckMultiCurrencyInUseArgs = {
  MultiCurrencyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryOnBehalfMenuInfolineArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryTimeAttendanceMenuInfolineArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryOnBehalfCompanyListingArgs = {
  Module: RequestType;
};


export type QueryOnBehalfSubordinateListingArgs = {
  ReportingGroupIDs: Array<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetOnBehalfPolicyTypeIDsArgs = {
  ReportingGroupID: Scalars['String'];
  ModuleType: RequestType;
  CompanyID: Scalars['String'];
};


export type QueryTmsOnBehalfPolicySummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOnBehalfPolicyListingByTypeArgs = {
  ModuleType: RequestType;
  CompanyID: Scalars['String'];
};


export type QueryOvertimeForecastReportArgs = {
  FormulaFormat: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetSingleAdvancePolicyDetailArgs = {
  AdvancePolicyID: Scalars['String'];
};


export type QueryDuplicateAdvancePolicyNameArgs = {
  AdvancePolicyID?: Maybe<Scalars['String']>;
  AdvancePolicyName: Scalars['String'];
};


export type QueryAdvancePolicyByCompanyAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPostedAdvanceProcessingListingArgs = {
  PayPeriodID: Scalars['String'];
  AdvancePolicyID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCompanyPayGroupListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryStatutoryAdjustmentListingArgs = {
  PayrollCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryStatutoryAdjFormEmpListingArgs = {
  ExcludeEmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetFormulaTestArgs = {
  formula: Scalars['String'];
};


export type QueryPayrollCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSalaryTestArgs = {
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QuerySalaryPayoutAnalysisSummaryExportExcelArgs = {
  StaffCategoryIDs?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Scalars['String']>;
  OrgUnitIDs?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollDetailListingExportExcelArgs = {
  StaffCategoryIDs?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Scalars['String']>;
  OrgUnitIDs?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs: Array<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeOutstandingSalaryListingArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeOutstandingSalaryListingAndCountArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCp22CountArgs = {
  ID: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetPayrollCyclePeriodSettingArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryViewPayrollHistorySummaryArgs = {
  EmployeeID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTestPayrollCalcArgs = {
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryCountArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryTotalArgs = {
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryMyPayrollInfoArgs = {
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryByEmployeeArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryPayrollProcessPeriodListingArgs = {
  AdvancePolicyID?: Maybe<Scalars['String']>;
  IsViewHistory: Scalars['Boolean'];
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessPayrollCycleListingArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessPayItemListingArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessEmployeeListingArgs = {
  input: EmployeeFilterInput;
};


export type QueryCalculatePayrollArgs = {
  PayrollCalculationInput: PayrollCalculationInput;
};


export type QueryCalculatePayrollRedirectArgs = {
  PayrollCalculationInput: PayrollCalculationInput;
};


export type QueryCalculateEpfRateArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryRoundingArgs = {
  input: Scalars['Float'];
};


export type QueryCalculateHrdfArgs = {
  HRDFCalculationInput: HrdfCalculationInput;
};


export type QueryCalculateEpfv2Args = {
  EPFCalculationInput: EpfCalculationInputV2;
};


export type QueryCalculateEisArgs = {
  EISCalculationInput: EisCalculationInput;
};


export type QueryCalculateSocsoArgs = {
  SocsoCalculationInput: SocsoCalculationInput;
};


export type QueryPayPeriodDetailsArgs = {
  PeriodYearMonth: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayPeriodListingArgs = {
  PeriodYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryStartPayPeriodListingByYearArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['String'];
};


export type QueryEndPayPeriodListingByYearArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['String'];
};


export type QueryPayItemByPayTypeArgs = {
  PayItemID?: Maybe<Scalars['String']>;
  PayType: Array<Scalars['String']>;
};


export type QueryPayItemListingArgs = {
  OnlySubscription?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayItemConditionListTemplateLibraryArgs = {
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayItemConditionListArgs = {
  PayItemID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayrollProcessingPayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayrollProcessingPayItemListing2Args = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveTypePayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QuerySalaryListingByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QuerySalaryListingByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryClaimTypePayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayItemDetailArgs = {
  PayItemID?: Maybe<Scalars['String']>;
};


export type QueryPayrollFixedAllowanceListingArgs = {
  input: PayrollFixedAllowanceInput;
};


export type QueryPayrollFixedAllowanceManualEntryListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: PayrollFixedAllowanceInput;
};


export type QueryPayrollFixedAllowanceManualEntryListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  input: PayrollFixedAllowanceInput;
};


export type QueryPayrollFixedAllowanceDetailsArgs = {
  RecurringPayID: Scalars['String'];
};


export type QueryCp159ListingArgs = {
  PeriodYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryOneTimePayListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOneTimePayListingByPayPeriodAndPayCycleArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPublishPaySlipListingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  Year: Scalars['Float'];
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPublishPaySlipListingByEmployeeArgs = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetSingleUserArgs = {
  ID: Array<Scalars['String']>;
};


export type QueryPublishEaListingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPublishEaListingByEmployeeArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryIsMonthlyTrxInUseArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryMonthlyTransactionByEmployeeArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: MonthlyTransactionInput;
};


export type QueryMonthlyTransactionByEmployeeCountArgs = {
  input: MonthlyTransactionInput;
};


export type QueryMonthlyTransactionByPayItemArgs = {
  input: PayItemFilterInput;
};


export type QueryMonthlyTransactionByExcelArgs = {
  input: MonthlyTransactionInput;
};


export type QueryFreezepayrollListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryPayrollFixedAllowanceByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPublishedEaCompanyListingArgs = {
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryCheckOldDataForSalaryBatchArgs = {
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type QueryCheckOldDataForOneTimePayInsertArgs = {
  PayPeriodID?: Maybe<Scalars['String']>;
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type QueryCheckOldDataForRecurringPayInsertArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  Data?: Maybe<Array<Scalars['JSON']>>;
};


export type QueryEmployeeSalaryLogsArgs = {
  Page?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeSalaryLogsCountArgs = {
  Page?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeSalaryListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryPayrollHomeInfoLinesOutputArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryOtherPayrollRelatedInfoLineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeKpiListingArgs = {
  ContactID?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeKpiDetailsArgs = {
  EmployeeKpiID: Scalars['String'];
};


export type QueryEmployeeKpiRepoListingArgs = {
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryRetrieveEmployeeKpiDetailFromRepoArgs = {
  EmployeeKpiRepositoryID: Scalars['String'];
};


export type QueryIsKpiExistInRepoArgs = {
  KpiRepoID?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  KPI: Scalars['String'];
};


export type QueryKpiExerciseListingArgs = {
  KpiFormStatus?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  KpiExerciseIDs: Array<Scalars['String']>;
};


export type QueryKpiExerciseSetupInfoArgs = {
  KpiExerciseSetupID: Scalars['String'];
};


export type QueryIsKpiExerciseIdExistByCompanyArgs = {
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  KpiExerciseID: Scalars['String'];
};


export type QueryIsKpiExerciseDateRangeOverlappingByCompanyArgs = {
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
};


export type QueryKpiCategoryWithOrgGoalListingArgs = {
  Year: Scalars['Float'];
};


export type QueryOrganizationalGoalByCategoryListingArgs = {
  Year: Scalars['Float'];
  KpiCategoryID: Scalars['String'];
};


export type QueryOrganizationalGoalDetailsArgs = {
  OrganizationalGoalID: Scalars['String'];
};


export type QueryIsOrgGoalExistByCategoryArgs = {
  OrgGoalID?: Maybe<Scalars['String']>;
  KpiCategoryID: Scalars['String'];
  Year: Scalars['Float'];
  Goal: Scalars['String'];
};


export type QueryOrgKpiPlanningTreeViewListingArgs = {
  Year: Scalars['Float'];
};


export type QueryKpiCategoryWithOrgKpiPlanningListingArgs = {
  OrgKpiPlanningID: Scalars['String'];
};


export type QueryOrgKpiPlanningDetailsByKpiCategoryListingArgs = {
  KpiCategoryID: Scalars['String'];
  OrgKpiPlanningID: Scalars['String'];
};


export type QueryOrgKpiPlanningInfoArgs = {
  OrgKpiPlanningID: Scalars['String'];
};


export type QueryIsOrgKpiExistByCategoryArgs = {
  OrgKpiPlanningDetailslID?: Maybe<Scalars['String']>;
  OrgKpiPlanningID: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  KPI: Scalars['String'];
};


export type QueryPerformanceGroupMatrixListingArgs = {
  GroupType: Scalars['String'];
};


export type QueryMyPerformanceSummaryArgs = {
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetPerformanceToDoArgs = {
  PerformanceRequestType: Scalars['String'];
  ContactID: Scalars['String'];
};


export type QueryGetPerformanceApprovalHistoryArgs = {
  PerformanceRequestType: Scalars['String'];
  ApprovalContactID: Scalars['String'];
};


export type QueryPerformanceEmployeeListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  RequestType: Scalars['String'];
};


export type QueryReviewExerciseListingArgs = {
  ReviewFormStatus?: Maybe<Array<Scalars['String']>>;
  ReviewFormType?: Maybe<Array<AppraiseeType>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ReviewExerciseIDs: Array<Scalars['String']>;
};


export type QueryGetEmployeeReviewListingArgs = {
  ReviewFormStatus?: Maybe<Array<Status>>;
  ReviewFormType?: Maybe<Array<AppraiseeType>>;
  ToDate: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ContactID: Scalars['String'];
};


export type QueryGetEmployeeReviewActionListingArgs = {
  ReviewFormStatus?: Maybe<Array<Status>>;
  ReviewFormType?: Maybe<Array<AppraiseeType>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ReviewID?: Maybe<Array<Scalars['String']>>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ContactID: Scalars['String'];
};


export type QueryGetEmployeeReviewFormArgs = {
  EmployeeReviewID: Scalars['String'];
};


export type QueryGetConversationLogByReviewFormItemArgs = {
  EmployeeReviewSectionItemID: Scalars['String'];
};


export type QueryReviewExerciseSetupInfoArgs = {
  ReviewExerciseSetupID: Scalars['String'];
};


export type QueryIsReviewExerciseIdExistArgs = {
  ReviewExerciseSetupID?: Maybe<Scalars['String']>;
  ReviewExerciseID: Scalars['String'];
};


export type QueryPeerRegistrationListingArgs = {
  ReviewExerciseSetupID: Scalars['String'];
};


export type QueryIsSuperiorAlreadyExistArgs = {
  NewSuperiorID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseSetupID?: Maybe<Scalars['String']>;
  ReviewExerciseID?: Maybe<Scalars['String']>;
};


export type QueryMySubordinatePerformanceSummaryArgs = {
  ContactID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QuerySubordinateKpiEmpListingArgs = {
  ContactID: Scalars['String'];
};


export type QueryPeriodicAllowanceTypeListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryPersonnelCertificateListingArgs = {
  PersonnelCertificateID?: Maybe<Scalars['String']>;
  PersonnelID: Scalars['String'];
};


export type QueryContractInfoListingArgs = {
  ContractInfoID?: Maybe<Scalars['String']>;
  PersonnelID: Scalars['String'];
};


export type QueryContractInfoDueListingArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  dueStatus?: Maybe<Scalars['String']>;
  expiryDueDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryContractInfoDueListingCountArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  dueStatus?: Maybe<Scalars['String']>;
  expiryDueDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryContractInfoExistingListingArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  ContractStatus?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryContractInfoExistingListingCountArgs = {
  contractDocType?: Maybe<Array<Scalars['String']>>;
  ContractStatus?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryPersonnelEducationListingArgs = {
  PersonnelEducationID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
};


export type QueryIsEmailExistsArgs = {
  Email: Scalars['String'];
};


export type QueryIsMobileNoExistsArgs = {
  MobileNo: Scalars['String'];
};


export type QueryIsEmployeeNoExistsArgs = {
  EmployeeNo: Scalars['String'];
};


export type QueryDocumentProfilePictureArgs = {
  ContactID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryPersonnelEFileListingArgs = {
  Keyword?: Maybe<Scalars['String']>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SortBy?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPersonnelEFileListingCountArgs = {
  Keyword?: Maybe<Scalars['String']>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  SortBy?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPersonnelHealthInfoSummaryArgs = {
  PersonnelID: Scalars['String'];
};


export type QueryPersonnelHomeInfoLinesArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryPersonnelJobHistoryListingArgs = {
  PersonnelID: Scalars['String'];
};


export type QueryPersonnelReferenceListingArgs = {
  PersonnelReferenceID?: Maybe<Scalars['String']>;
  PersonnelID: Scalars['String'];
};


export type QueryUserDefineDetailListingArgs = {
  PersonnelID?: Maybe<Scalars['String']>;
};


export type QueryInsuranceSchemeInUseArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  InsuranceSchemeID: Scalars['String'];
};


export type QueryPersonnelInsuranceSchemePersonnelListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeInsuranceSchemeID?: Maybe<Scalars['String']>;
  InsuranceSchemeID: Scalars['String'];
};


export type QueryProbationExtensionListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffConfirmationProbationListingArgs = {
  IsCompleted?: Maybe<Scalars['Boolean']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffConfirmationConfirmedListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryStaffConfirmationConfirmedCareerLogListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffTerminationListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffTerminationListingBySubscriptionArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryStaffRetirementRetiredListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffRetirementDueListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffExitCompanyListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryStaffPromotionDemotionListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  PromotionOrDemotion: MovementType;
  CompanyID: Scalars['String'];
};


export type QueryStaffSecondmentListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffRedesignationListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffInternalTransferListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffInterCompanyTransferListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryStaffInterCompanyTransferCopyTransferDataArgs = {
  EffectiveDate: Scalars['DateTime'];
  CurrentCompanyID: Scalars['String'];
  CurrentEmployeeID: Scalars['String'];
  NewCompanyID: Scalars['String'];
};


export type QueryStaffInterCompanyTransferDetailsArgs = {
  CareerLogID: Scalars['String'];
};


export type QueryInterCoTransferLeavePolicyDataArgs = {
  EmployeeDetailsInput: EmployeeDetailsInput;
  EmployeeID: Scalars['String'];
  CurrentCompanyID: Scalars['String'];
};


export type QueryGetProjectExpenseClaimRecordsListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  superiorRejectToDraft?: Maybe<Scalars['Boolean']>;
  input: ProjectExpenseClaimRecordsInput;
};


export type QueryGetPeClaimRecordsDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetPeClaimRecordsTotalCountArgs = {
  input: ProjectExpenseClaimRecordsInput;
};


export type QueryProjecExpenseSummaryArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryVerifyMyPeClaimArgs = {
  VerfiyPEClaimInput: VerfiyPeClaimInput;
};


export type QueryIsProjectExpenseSubjectExistsArgs = {
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  input: ProjectExpenseClaimRecordsInput;
  Subject: Scalars['String'];
};


export type QueryGetPeClaimPostingPeriodListingV2Args = {
  PaymentMethod?: Maybe<Scalars['String']>;
  input: PeClaimPostingPeriodListingInput;
};


export type QueryGetPeClaimPostingListingArgs = {
  PaymentMethod: Scalars['String'];
  input: ApprovalProjectExpenseClaimVerificationInput;
};


export type QueryGetPeClaimPostingDetailArgs = {
  PaymentMethod: Scalars['String'];
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetApprovedProjectExpenseClaimVerificationListingArgs = {
  Incomplete?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  input: ApprovalProjectExpenseClaimVerificationInput;
};


export type QueryGetPeApprovedClaimCountArgs = {
  input: ApprovalProjectExpenseClaimVerificationInput;
};


export type QueryGetApprovedProjectExpenseClaimVerificationDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryPeClaimCompanySettingSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetProjectToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetProjectExpenseApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetProjectAccountAssignmentArgs = {
  CompanyID: Scalars['String'];
};


export type QueryProjectAccountMappingListArgs = {
  ProjectAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryProjectGlAccountHeaderListWithDetailsArgs = {
  Type?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  PeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ProjectGLAccountHeaderID?: Maybe<Scalars['String']>;
};


export type QueryProjectPayItemAmountByOrgAndPayPeriodArgs = {
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
};


export type QueryProjectPayrollGlExportArgs = {
  Type?: Maybe<Scalars['String']>;
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
};


export type QueryProjectGlClaimCycleProcessArgs = {
  Type?: Maybe<Scalars['String']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryGetGlClaimCycleListArgs = {
  Type?: Maybe<Scalars['String']>;
  AllClaimCycles?: Maybe<Scalars['Boolean']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryProjectCostAllocationListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryProjectCostAllocationEmployeeListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryProjectCostAllocationDetailListingArgs = {
  ProjectCostAllocationID: Scalars['String'];
};


export type QueryGetApproverAssignmentListingArgs = {
  ProjectTimesheetApproverMasterID: Scalars['String'];
};


export type QueryIsApproverEmailAlreadyExistArgs = {
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  EmailAddress: Scalars['String'];
};


export type QueryProjectTimesheetPeriodListingArgs = {
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryDailyEntryListingArgs = {
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
};


export type QueryProjectTimesheetSubmissionListingArgs = {
  EmployeeCalendar: Scalars['Boolean'];
  DutyRoster: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
};


export type QueryProjectTimesheetApprovalListingArgs = {
  token: Scalars['String'];
};


export type QueryProjectTimesheetCheckPeriodOverlappingArgs = {
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ProjectTimesheetPeriodID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  PeriodTo: Scalars['DateTime'];
  PeriodFrom: Scalars['DateTime'];
};


export type QueryProjectTimesheetCheckPeriodOverlappingCountArgs = {
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  SortBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ProjectTimesheetPeriodID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  PeriodTo: Scalars['DateTime'];
  PeriodFrom: Scalars['DateTime'];
};


export type QueryProjectTimesheetPeriodArgs = {
  CompanyID: Scalars['String'];
};


export type QueryProjectTimesheetEmployeeListingArgs = {
  CompanyID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
};


export type QueryAdminDailyEntryListingArgs = {
  PeriodYearMonth: Scalars['String'];
  ContactID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryProjectTimesheeTApproverReportListingArgs = {
  SortBy?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
};


export type QueryELetterTemplateListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  PreofferChecklistID?: Maybe<Scalars['String']>;
};


export type QueryRecruitmentApprovalPolicyListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  type: RecruitmentPolicyType;
};


export type QueryJobPortalSetupListingArgs = {
  isOption?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
};


export type QueryJobPortalSetupDetailArgs = {
  JobPortalID: Scalars['String'];
};


export type QueryJobPortalQrCodeArgs = {
  JobPortalID: Scalars['String'];
};


export type QueryGetRecRequisitionToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryViewRec_RequisitionApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetRecOfferToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryViewRec_OfferApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetRecHiringToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryViewRec_HiringApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryJobPortalInfoArgs = {
  PortalToken: Scalars['String'];
};


export type QueryJobPostingListByJobPortalArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  PortalToken: Scalars['String'];
};


export type QueryJobPostingListByJobPortalCountArgs = {
  PortalToken: Scalars['String'];
};


export type QueryJobPostingInfoByJobPortalArgs = {
  JobPostingID: Scalars['String'];
};


export type QueryTokenValidationArgs = {
  type?: Maybe<Scalars['String']>;
  portalToken?: Maybe<Scalars['String']>;
  talentToken?: Maybe<Scalars['String']>;
};


export type QueryEApplicationListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryEApplicationSummaryArgs = {
  SubscriptionAccountID: Scalars['String'];
  TalentPoolID?: Maybe<Scalars['String']>;
};


export type QueryDataUpdateEApplicationSummaryArgs = {
  SubscriptionAccountID: Scalars['String'];
  TalentPoolID?: Maybe<Scalars['String']>;
};


export type QueryRecruitmentParameterListingArgs = {
  type: Array<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryTalentUserDefineFieldsListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryDataUpdateTalentPersonalArgs = {
  TalentPoolID: Scalars['String'];
};


export type QueryDataUpdateHealthInfoArgs = {
  ContactID: Scalars['String'];
};


export type QueryLanguageParameterListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMyRecruitmentMenuSummaryArgs = {
  RequestorID?: Maybe<Scalars['String']>;
};


export type QueryMyRecRequisitionListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  RequestorID?: Maybe<Scalars['String']>;
};


export type QueryMyRecRequisitionListingTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  RequestorID?: Maybe<Scalars['String']>;
};


export type QueryMyApprovedJobVacancyListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  RequestorID: Scalars['String'];
};


export type QueryMyApprovedJobVacancyListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  RequestorID: Scalars['String'];
};


export type QueryMyApprovedJobVacancyInfoArgs = {
  JobVacancyID: Scalars['String'];
};


export type QueryMyApprovedJobVacancyApplicantListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  ApplicationStage?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryMyApprovedJobVacancyApplicantListingCountArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  ApplicationStage?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryMyRecInterviewListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  orgUnitFilter?: Maybe<Array<Scalars['String']>>;
  jobPositionFilter?: Maybe<Array<Scalars['String']>>;
  interview_status?: Maybe<Scalars['String']>;
  interviewer_id: Scalars['String'];
};


export type QueryMyRecInterviewListingCountArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
  orgUnitFilter?: Maybe<Array<Scalars['String']>>;
  jobPositionFilter?: Maybe<Array<Scalars['String']>>;
  interview_status?: Maybe<Scalars['String']>;
  interviewer_id: Scalars['String'];
};


export type QueryMyOfferListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferProcessStatus?: Maybe<RecruitmentOfferProcessStatus>;
  OfferResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus>>;
  OfferStatus?: Maybe<Array<RecruitmentOfferStatus>>;
  RequestorID: Scalars['String'];
};


export type QueryMyOfferListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferProcessStatus?: Maybe<RecruitmentOfferProcessStatus>;
  OfferResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus>>;
  OfferStatus?: Maybe<Array<RecruitmentOfferStatus>>;
  RequestorID: Scalars['String'];
};


export type QueryMyOfferDetailArgs = {
  ApplicantID: Scalars['String'];
};


export type QueryMyTalentSearchApplicationListingArgs = {
  TalentPoolID: Scalars['String'];
  RequestorID: Scalars['String'];
};


export type QueryTalentPoolSummaryArgs = {
  employeeID?: Maybe<Scalars['String']>;
};


export type QueryGetRecRequisitionInfoArgs = {
  RecRequisitionID: Scalars['String'];
};


export type QueryRecRequisitionListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryRecRequisitionListingTotalCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryRecRequisitionNotInUseListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryRecRequisitionNotInUseListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryJobVacancyListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryJobVacancyListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryJobVacancyInfoArgs = {
  JobVacancyID: Scalars['String'];
};


export type QueryJobVacancyWithApplicantListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  orgUnitFilter?: Maybe<Array<Scalars['String']>>;
  jobPositionFilter?: Maybe<Array<Scalars['String']>>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  application_stage: Scalars['String'];
  tabStatus: Scalars['String'];
};


export type QueryInterviewScheduleListingArgs = {
  rec_applicant_id?: Maybe<Scalars['String']>;
};


export type QueryJobVacancyNotInUseListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryJobVacancyNotInUseListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryJobPostingListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPostingStatus?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryJobPostingListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPostingStatus?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryJobPostingInfoArgs = {
  jobPostingId: Scalars['String'];
};


export type QueryPostedJobVacancyListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryPostedJobVacancyListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobVacancyStatus?: Maybe<Array<Scalars['String']>>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryPostedJobVacancyInfoArgs = {
  JobVacancyID: Scalars['String'];
};


export type QueryApplicantListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  ApplicationStage?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryApplicantListingCountArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ApplicationStatus?: Maybe<RecruitmentApplicationStatus>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  ApplicationStage?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryActiveJobVacancyListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryActiveJobVacancyListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryActiveJobVacancyInfoArgs = {
  JobVacancyID: Scalars['String'];
};


export type QueryFinalApplicantSelectionListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  FinalSelectionStatus?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryFinalApplicantSelectionListingCountArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  MaxLastDrawnSalary?: Maybe<Scalars['Float']>;
  MinLastDrawnSalary?: Maybe<Scalars['Float']>;
  FinalSelectionStatus?: Maybe<Array<Scalars['String']>>;
  keyword?: Maybe<Scalars['String']>;
  JobVacancyID: Scalars['String'];
};


export type QueryOfferProposalListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus>>;
  OfferStatus: Array<RecruitmentOfferStatus>;
};


export type QueryOfferProposalListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ResponseStatus?: Maybe<Array<RecruitmentOfferResponseStatus>>;
  OfferStatus: Array<RecruitmentOfferStatus>;
};


export type QueryOfferProposalInfoArgs = {
  ApplicantID: Scalars['String'];
};


export type QueryOfferProposalDetailListingArgs = {
  ApplicantID: Scalars['String'];
};


export type QueryOfferApprovalListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferStatus: Array<RecruitmentOfferStatus>;
};


export type QueryOfferApprovalListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferStatus: Array<RecruitmentOfferStatus>;
};


export type QueryOfferApprovalDetailListingArgs = {
  ApplicantID: Scalars['String'];
};


export type QueryApprovedJobOfferListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferProcessStatus: Array<RecruitmentOfferProcessStatus>;
};


export type QueryApprovedJobOfferListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  OfferProcessStatus: Array<RecruitmentOfferProcessStatus>;
};


export type QueryApprovedJobOfferInfoArgs = {
  OfferProcessID: Scalars['String'];
};


export type QueryProcessedOfferInfoArgs = {
  ApplicantID: Scalars['String'];
};


export type QueryELetterTemplateInfoArgs = {
  OfferChecklistID: Scalars['String'];
};


export type QueryApplicantOfferPreHiringListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type QueryApplicantOfferPreHiringListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['DateTime']>;
};


export type QueryQuickHiringListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type QueryQuickHiringListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryQuickHiringDetailsArgs = {
  hiringId: Scalars['String'];
};


export type QueryPreHiringDataUpdateListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  dataUpdateStatus: DataUpdateStatus;
};


export type QueryPreHiringDataUpdateListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  dataUpdateStatus: DataUpdateStatus;
};


export type QueryNewHireProcessListingArgs = {
  OnboardStatus: Array<OnboardStatus>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryNewHireProcessListingCountArgs = {
  OnboardStatus: Array<OnboardStatus>;
  keyword?: Maybe<Scalars['String']>;
  JobPositionIDs?: Maybe<Array<Scalars['String']>>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryNewHireProcessOnboardInfoArgs = {
  hiringId: Scalars['String'];
};


export type QueryTalentPoolListingArgs = {
  keyword?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  talentStatus?: Maybe<Array<RecruitmentTalentStatus>>;
  extraFilters?: Maybe<Array<Scalars['JSON']>>;
  preferLocations?: Maybe<Array<Scalars['String']>>;
  jobPositions?: Maybe<Array<Scalars['String']>>;
};


export type QueryTalentPoolListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  talentStatus?: Maybe<Array<RecruitmentTalentStatus>>;
  extraFilters?: Maybe<Array<Scalars['JSON']>>;
  preferLocations?: Maybe<Array<Scalars['String']>>;
  jobPositions?: Maybe<Array<Scalars['String']>>;
};


export type QueryTalentProfileSummaryArgs = {
  type?: Maybe<Scalars['String']>;
  TalentPoolID: Scalars['String'];
};


export type QueryTalentProfileDetailArgs = {
  formType?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  typeID: Scalars['String'];
};


export type QueryTalentHealthInfoArgs = {
  PersonnelHealthInfoID: Scalars['String'];
};


export type QueryTalentApplicationListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  TalentPoolID: Scalars['String'];
};


export type QueryTalentApplicationListingCountArgs = {
  TalentPoolID: Scalars['String'];
};


export type QueryTalentJobVacancyAppliedListingArgs = {
  TalentPoolID: Scalars['String'];
};


export type QueryTalentSearchListingArgs = {
  employee_id?: Maybe<Scalars['String']>;
};


export type QueryTalentInvitationListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  inviteStatus?: Maybe<InviteStatus>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryTalentInvitationListingCountArgs = {
  keyword?: Maybe<Scalars['String']>;
  inviteStatus?: Maybe<InviteStatus>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryRecruitmentApprovalPolicyByTypeArgs = {
  ApprovalPolicyType: Scalars['String'];
};


export type QueryWorkFlowDetailArgs = {
  typeID: Scalars['String'];
  type: Scalars['String'];
};


export type QueryRequisitionMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryRequisitionManagementListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  filterText: Scalars['String'];
  ApStatus: Array<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  RequisitionType: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryRequisitionManagementListingTotalCountArgs = {
  ApStatus: Array<Scalars['String']>;
  DepartmentIDs: Array<Scalars['String']>;
  EmployeeStatus: Array<Scalars['String']>;
  RequisitionType: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeRequisitionListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryRequisitionDetailsArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  RequestCompanyID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
};


export type QueryFilterEmployeeRequisitionListingArgs = {
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  RequisitionType: Array<Scalars['String']>;
  ApprovalStatus: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryTravelRequsitionListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryRequisitionDetailArgs = {
  RequisitionHeaderID: Scalars['String'];
};


export type QueryCheckVerifyEndIfArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetRequisitionApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetRequisitionToAproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetRequisitionApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryClaimParameterListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ClaimParameterType: Scalars['String'];
};


export type QueryClaimParameterListingAllArgs = {
  ClaimParameterType: Scalars['String'];
};


export type QueryClaimUserDefineFieldsListingArgs = {
  ClaimParameterID: Scalars['String'];
};


export type QueryVehicleRateListingArgs = {
  ClaimParameterID: Scalars['String'];
};


export type QueryPersonnelParameterListingByTypeArgs = {
  TypeList?: Maybe<Array<Scalars['String']>>;
};


export type QueryReportTemplateListingArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
};


export type QueryReportTemplateAssignmentListingArgs = {
  ReportTemplateType?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryStatesListingArgs = {
  Country?: Maybe<Scalars['String']>;
};


export type QueryViewGeneralSettingSummaryArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryHomeSummaryArgs = {
  IsEmployee?: Maybe<Scalars['Boolean']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeMainMenuSummaryArgs = {
  JobPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmploymentInfoSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryPersonnelMenuSummaryArgs = {
  ContactID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEaPartFListingByYearArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryEisPolicyDetailArgs = {
  EISPolicyID: Scalars['String'];
};


export type QueryEpfPolicyDetailArgs = {
  EPFPolicyID: Scalars['String'];
};


export type QueryFormItemRatingDetailsArgs = {
  FormItemRatingID: Scalars['String'];
};


export type QueryIsFormItemRatingTitleExistArgs = {
  FormItemRatingID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryKpiCategoryDetailsArgs = {
  KpiCategoryID: Scalars['String'];
};


export type QueryIsKpiCategoryTitleExistArgs = {
  KpiCategoryID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryKpiPredefinedItemListingArgs = {
  KpiCategoryID: Scalars['String'];
};


export type QueryKpiPredefinedItemDetailsArgs = {
  KpiPredefinedItemID: Scalars['String'];
};


export type QueryIsKpiPredefinedItemTitleExistArgs = {
  KpiPredefinedItemID?: Maybe<Scalars['String']>;
  KpiCategoryID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryKpiPredefinedTemplateDetailsArgs = {
  KpiPredefinedTemplateID: Scalars['String'];
};


export type QueryIsKpiPredefinedTemplateTitleExistArgs = {
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewFormTemplateDetailsArgs = {
  ReviewFormTemplateID: Scalars['String'];
};


export type QueryIsReviewFormTemplateTitleExistArgs = {
  ReviewFormTemplateID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewGradingTableDetailsArgs = {
  ReviewGradingTableID: Scalars['String'];
};


export type QueryIsReviewGradingTitleExistArgs = {
  ReviewGradingTableID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewGroupDetailsArgs = {
  ReviewGroupID: Scalars['String'];
};


export type QueryIsReviewGroupTitleExistArgs = {
  ReviewGroupID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewItemDetailsArgs = {
  ReviewItemID: Scalars['String'];
};


export type QueryIsReviewItemCodeExistArgs = {
  ReviewItemID?: Maybe<Scalars['String']>;
  Code: Scalars['String'];
};


export type QueryReviewPolicyDetailsArgs = {
  ReviewPolicyID: Scalars['String'];
};


export type QueryIsReviewPolicyTitleExistArgs = {
  ReviewPolicyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewProcessPolicyDetailsArgs = {
  ReviewProcessPolicyID: Scalars['String'];
};


export type QueryIsReviewProcessPolicyTitleExistArgs = {
  ReviewProcessPolicyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryReviewTemplateDetailsArgs = {
  ReviewTemplateID: Scalars['String'];
};


export type QueryIsReviewTemplateTitleExistArgs = {
  ReviewTemplateID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryRewardGroupDetailsArgs = {
  RewardGroupID: Scalars['String'];
};


export type QueryIsRewardGroupTitleExistArgs = {
  RewardGroupID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryPublicHolidayCalendarListingArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryPublicHolidayListingArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryPublicHolidayForStatesListingArgs = {
  Description: Scalars['String'];
  HolidayDate: Scalars['DateTime'];
};


export type QuerySocsoPolicyDetailArgs = {
  SocsoPolicyID: Scalars['String'];
};


export type QuerySystemAdminMenuSummaryArgs = {
  userID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryTaxReliefListingByYearArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryAttendanceAnalysisArgs = {
  ToDate?: Maybe<Scalars['String']>;
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceProcessingListArgs = {
  ToDate?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
  subscriptionAccountID: Scalars['String'];
};


export type QueryMonthlyAttendanceProcessingListArgs = {
  ToDate?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  subscriptionAccountID: Scalars['String'];
};


export type QueryProcessingListingArgs = {
  ToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetAutomatedClockDataImportListingArgs = {
  AutomatedClockDataImportID?: Maybe<Scalars['String']>;
};


export type QueryGetBeaconDevicesListingArgs = {
  BluetoothBeaconDevicesID?: Maybe<Scalars['String']>;
};


export type QueryGetChangeShiftArgs = {
  CompanyID: Scalars['String'];
  ChangeShiftID?: Maybe<Scalars['String']>;
};


export type QueryGetShiftByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetShiftGroupByTlaArgs = {
  ShiftID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetShiftGroupByShiftPatternArgs = {
  CompanyID: Scalars['String'];
  ShiftPatternID: Scalars['String'];
};


export type QueryGetShiftPatternByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmpByDutyArgs = {
  DutyDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetChangeShiftListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryViewChangeShiftApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetChangeShiftApproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetChangeShiftToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetChangeShiftByYearArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ChangeShiftStatus?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Date: Scalars['DateTime'];
};


export type QueryChangeShiftMenuSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryGetDailyAllowanceArgs = {
  DailyAllowanceID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};


export type QueryGetDailyAllowanceByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDeductionPolicyArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DeductionPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetDeductionPolicyByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEarnLeavePostingArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  UntilDate: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryEarnLeavePeriodSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};


export type QueryEmployeeTmsSummaryArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetJobGradePolicyMatrixArgs = {
  JobGradePolicyMatrixID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  JobGradeID?: Maybe<Scalars['String']>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetMobileCheckInArgs = {
  Status?: Maybe<Status>;
  MobileCheckInID?: Maybe<Scalars['String']>;
};


export type QueryQrListingArgs = {
  DocumentID: Scalars['String'];
};


export type QueryEmpAttendanceHistoryArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryAttendanceHistoryArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryVerifyScanArgs = {
  Location: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetMonthlyAllowanceByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetMonthlyAllowanceArgs = {
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetOvertimeClaimTableArgs = {
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetOvertimeReasonArgs = {
  OTReason?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryOtReasonListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryOtReasonByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPeriodicDeductionArgs = {
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetAllowanceDeductionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AllowanceDeductionID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetOtRoundingArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftDayArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftGroupArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeShiftGroupArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeShiftGroupAssignmentLogArgs = {
  Today?: Maybe<Scalars['Boolean']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetShiftPatternArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryShiftPatternListingArgs = {
  EndMonth?: Maybe<Scalars['DateTime']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
};


export type QueryValidShiftPatternListingArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};


export type QueryTemplateLibrarySummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryTmsSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryUserDefineEpfPolicyConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetTemplateLibraryArgs = {
  input?: Maybe<TemplateLibraryAssignmentInput>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetTemplateLibraryAssignmentArgs = {
  input?: Maybe<TemplateLibraryAssignmentInput>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetJobGradeByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPayrollTemplateLibraryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPayCycleForPayCalendarArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryIsPayrollCycleInUseArgs = {
  PayrollCycleID: Scalars['String'];
};


export type QueryGetTemplateLibraryAssignmentInfolineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDailyTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeDailyPoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryDeductionTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeDeductionPoliciesArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryTmsTypeConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOtPayItemConditionListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeOtPoliciesArgs = {
  WorkDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryTmsTimeOffConditionListingArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryMonthlyTypeConditionListArgs = {
  ClaimType: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTmsPolicyMenuInfoLinesOutputArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryTimeOffListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTimeOffListingByYearArgs = {
  orderBy?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryViewTimeOffApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetTimeOffAproveToDoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetTimeOffToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryTimeOffApplicationPeriodSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryTimeOffMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTimeOffListingByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryTimeOffPolicyDetailsByEmployeeIdArgs = {
  Date?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetTimeOffArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
};


export type QueryTimeOffReasonByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTimeOffReasonListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryTimeOffSettingListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryTsAccountTypeListArgs = {
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTsAccountCodeListArgs = {
  AccountTypeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryStaffTimesheetListArgs = {
  FromDate?: Maybe<Scalars['DateTime']>;
  Date?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetHoursArgs = {
  SheetDate: Scalars['DateTime'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTimesheetDatesArgs = {
  sheetDate: Scalars['DateTime'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByAccCodeArgs = {
  AccountCodeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByActivityArgs = {
  Activity: Scalars['String'];
  AccountCodeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByAccTypeArgs = {
  AccountTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetMandayAnalysisByAccountArgs = {
  Account: Scalars['String'];
  AccountTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTimesheetSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryIsActvitiyInUseArgs = {
  TsAccountTypeID: Scalars['String'];
  Activities: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryToDoSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryTrainingAttendanceListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeTrainingRecordArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeTrainingAttendanceListingArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCompulsaryCourseByJobGradeListingArgs = {
  CourseID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetCompulsaryCourseByJobGradeListingWithCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTrainingCourseListingArgs = {
  CourseClassificationID: Scalars['String'];
};


export type QueryTrainingCourseListingByJobGradeArgs = {
  JobGradeID: Scalars['String'];
};


export type QueryGetLatestUpdateForMainMenuModuleArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTraineeEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTraineeEvalListingAllArgs = {
  CompanyID: Scalars['String'];
};


export type QuerySupervisorEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCourseEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTrainerEvalListingArgs = {
  ScheduledTrainingID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetTrainerEvalLuationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSupervisorEvalLuationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetFulfilmentByJobGradeArgs = {
  JobGradeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetFulfilmentByCourseArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetOsFulfilmentByJobGradeArgs = {
  CompanyID: Scalars['String'];
};


export type QueryScheduleTrainingListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryRegisteredTraineeListingArgs = {
  ScheduleTrainingID: Scalars['String'];
};


export type QueryEmployeeScheduledTrainingListingArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAvailableCoursesListingArgs = {
  JobGradeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCompulsoryCourseListingArgs = {
  JobGradeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmpTrainingAttendanceHistoryArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryTrainingCostBudgetListingArgs = {
  Year: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTrainingCostListingArgs = {
  ScheduledTrainingID: Scalars['String'];
};


export type QueryCostAllocationListingArgs = {
  ScheduledTrainingID: Scalars['String'];
};


export type QueryHrdfClaimListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryApprovalPolicyByTypeArgs = {
  TrainingApprovalPolicyType: Training_Policy_Type;
};


export type QueryTrainingRequestListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryTrainingRequestBudgetInfoArgs = {
  EmployeeID: Scalars['String'];
  TrainingRequestID: Scalars['String'];
};


export type QueryScheduledTrainingListingArgs = {
  EventStatus?: Maybe<Array<Event_Status>>;
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};


export type QueryTrainingRegistrationListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ParticipationStatus?: Maybe<Participation_Status>;
  ScheduledTrainingID: Scalars['String'];
};


export type QueryScheduledTrainingVenueListingArgs = {
  ScheduledTrainingID: Scalars['String'];
};


export type QueryGetTrainingQrCodeArgs = {
  ScheduledTrainingVenueID: Scalars['String'];
};


export type QueryOngoingTrainingListingArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryGetOngoingTrainingAttendanceArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ScheduledTrainingID: Scalars['String'];
};


export type QueryTrainingEvaluationListingArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryTrainingEvaluationSurveyFormListingArgs = {
  SurveyFormIDs?: Maybe<Array<Scalars['String']>>;
  SurveyTypeIDs?: Maybe<Array<Scalars['String']>>;
  ScheduledTrainingID: Scalars['String'];
};


export type QueryTrainingEvaluationAttendeesListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ScheduledTrainingSurveyID: Scalars['String'];
};


export type QueryGetSurveyFormStructureArgs = {
  SurveyFormID: Scalars['String'];
};


export type QueryEvaluationResultListingArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryEvaluationResultParticipantListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ScheduledTrainingID: Scalars['String'];
};


export type QueryEmployeeEvaluationResultArgs = {
  SurveyFormIDs?: Maybe<Array<Scalars['String']>>;
  SurveyTypeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
};


export type QueryAdminExpensesFilterListingArgs = {
  input?: Maybe<AdminExpenseFilterInput>;
};


export type QueryHrdcClaimListingArgs = {
  HRDCClaimStatus?: Maybe<Array<Approval_Status>>;
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryTrainingFulfillmentByCcArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate: Scalars['DateTime'];
};


export type QueryTrainingFulfillmentByCcDetailsArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  TrainingCourseLibraryID: Scalars['String'];
};


export type QueryTrainingFulfillmentByThArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  AsToDate: Scalars['DateTime'];
};


export type QueryTrainingFulfillmentByThDetailsArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryTrainingRecordsListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetEmployeeTrainingRecordsArgs = {
  ParticipationStatus?: Maybe<Array<Participation_Status>>;
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeTrainingRequestListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
};


export type QueryGetTodoTrainingEvaluationArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryMySubordinateTrainingSummaryArgs = {
  EmployeeIDs: Array<Scalars['String']>;
};


export type QuerySubordinateTrainingListingSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryMyTrainingScheduledTrainingListingArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateTrainingRecordsListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryMySubordinateTrainingFulfillmentSummaryArgs = {
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryMySubordinateTrainingFulfillmentByCcArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryMySubordinateTrainingFulfillmentByCcDetailsArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  TrainingCourseLibraryID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryMySubordinateTrainingFulfillmentByThArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
};


export type QueryMyTrainingSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryTrainingCheckInHistoryArgs = {
  PeriodYearMonth: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryMyTrainingRequiredTrainingArgs = {
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryMyTrainingRequiredTrainingCalendarListingArgs = {
  EndDate?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  TrainingCourseLibraryID: Scalars['String'];
  TargetGroupIDs: Array<Scalars['String']>;
};


export type QuerySubordinateScheduledTrainingListingArgs = {
  EventStatus?: Maybe<Array<Event_Status>>;
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateTrainingRegistrationListingArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ParticipationStatus?: Maybe<Participation_Status>;
  EmployeeID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
};


export type QueryTrainingCalendarEmployeeArgs = {
  CalendarDate?: Maybe<Scalars['DateTime']>;
  TrainingCourseLibraryIDs?: Maybe<Array<Scalars['String']>>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EmployeeID: Scalars['String'];
};


export type QueryTrainingCalendarSubordinateArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ParticipationStatus?: Maybe<Participation_Status>;
  TrainingEventID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetTrainingToDoArgs = {
  TrainingRequestType: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryTrainingCourseLibraryListingArgs = {
  course_category_id: Scalars['String'];
};


export type QueryGetCourseDetailArgs = {
  training_course_library_id: Scalars['String'];
};


export type QueryGetTrainingProviderDetailArgs = {
  training_provider_id: Scalars['String'];
};


export type QueryTrainingProviderHistoryArgs = {
  training_provider_id: Scalars['String'];
};


export type QueryGetSurveyItemDetailArgs = {
  survey_item_id: Scalars['String'];
};


export type QueryGetSurveyFormDetailArgs = {
  survey_form_id: Scalars['String'];
};


export type QueryEmployeeTrainerProfilePersonnelInfoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryTrainerProfileHistoryArgs = {
  trainer_profile_id: Scalars['String'];
};


export type QueryTrainingApprovalPoliciesListingArgs = {
  policy_category: Training_Policy_Type;
};


export type QueryIsTrainingProviderCompanyIdExistArgs = {
  TrainingProviderID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryIsTrainingCourseLibraryCodeExistArgs = {
  TrainingCourseLibraryID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryIsTrainingProviderNameExistArgs = {
  TrainingProviderID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryIsTrainingEventNameExistArgs = {
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};


export type QueryIsTrainerProfileEmailExistArgs = {
  TrainerProfileID?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};


export type QueryGetPersonalTtdaInfoArgs = {
  RequestKey: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  IsApprovalPolicy?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  companyID: Scalars['String'];
};


export type QueryUserRoleIDbyUserArgs = {
  roleID?: Maybe<Scalars['String']>;
  userID: Scalars['String'];
};


export type QueryGetCompanyPermissionArgs = {
  RoleID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  hrPermissions?: Maybe<Array<HrPermission>>;
};


export type QueryGetRolePermissionByUserArgs = {
  hrPermissions?: Maybe<Array<HrPermission>>;
};


export type QueryGetUsersRoleContractArgs = {
  companyID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetUserRolePermissionByRoleArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAllowancePolicyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAllowanceEntitlementListingArgs = {
  AllowanceBenefitID: Scalars['String'];
};


export type QueryAllowanceEntitlementDetailArgs = {
  AllowanceItemIDs: Array<Scalars['String']>;
};


export type QueryCompanySummaryDatesArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayProcessSelectPayPeriodArgs = {
  input: PayPeriodSelectionInput;
};


export type QueryPayProcessSelectPayrollCycleArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayProcessEmployeeListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayProcessDetailArgs = {
  PayrollDetailID: Scalars['String'];
};


export type QueryMobileNotificationArgs = {
  Body: Scalars['String'];
  Title: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryLoginSsoArgs = {
  DeviceToken?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};


export type QueryVerifyRedirectDomainTokenArgs = {
  Token: Scalars['String'];
};


export type QueryGetUserListingArgs = {
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  UserStatus?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeUserListingArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetUsersByAccountArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Array<Scalars['String']>>;
  accountID: Scalars['String'];
};


export type QueryGetActiveUsersByAccountArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryUserProfilePicArgs = {
  IsEmployee?: Maybe<Scalars['Boolean']>;
  UserID: Scalars['String'];
};


export type QueryGetNonAccessUsersArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetNonAccessUsersCountArgs = {
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
};


export type QueryTestWorkFlowServicesArgs = {
  input?: Maybe<Scalars['Float']>;
};

export type QuickHiringDetails = {
  __typename?: 'QuickHiringDetails';
  rec_hiring_id?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  acceptance_date?: Maybe<Scalars['DateTime']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  prehiring_status?: Maybe<PrehiringStatus>;
  source_id?: Maybe<Scalars['String']>;
  source_name?: Maybe<Scalars['String']>;
  superior_id?: Maybe<Scalars['String']>;
  superior_full_name?: Maybe<Scalars['String']>;
  superior_no?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  remark_voice_file?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile_no?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
};

export type QuickHiringInput = {
  company_id: Scalars['String'];
  org_unit_id: Scalars['String'];
  job_designation_id: Scalars['String'];
  superior_id?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  full_name: Scalars['String'];
  email: Scalars['String'];
  mobile_no: Scalars['String'];
  acceptance_date: Scalars['DateTime'];
  duty_date: Scalars['DateTime'];
  prehiring_status?: Maybe<PrehiringStatus>;
  data_update_status?: Maybe<DataUpdateStatus>;
  data_update_status_log?: Maybe<Scalars['JSON']>;
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  remark_voice_file_input?: Maybe<DocumentsInput>;
  talentStatusLog?: Maybe<TalentStatusLog>;
};

export enum Race {
  Malay = 'Malay',
  Chinese = 'Chinese',
  Indian = 'Indian',
  Iban = 'Iban',
  Bidayuh = 'Bidayuh',
  Melanau = 'Melanau',
  OrangUlu = 'Orang_Ulu',
  Bajau = 'Bajau',
  Murut = 'Murut',
  KadazanDusun = 'Kadazan_Dusun',
  OthersSarawakNative = 'Others_Sarawak_Native',
  OthersSabahNative = 'Others_Sabah_Native',
  Others = 'Others'
}

export enum RatingInputMethod {
  Fixed = 'Fixed',
  Range = 'Range'
}

export type RatingScaleTable = {
  __typename?: 'RatingScaleTable';
  Scale: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export type RatingScaleTableInput = {
  Scale: Scalars['Float'];
  Description: Scalars['String'];
  Definition: Scalars['String'];
};

export enum ReadStatus {
  Read = 'Read',
  Unread = 'Unread'
}

export type Reason = InterfaceBase & {
  __typename?: 'Reason';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReasonID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
};

export type ReasonInput = {
  Name: Scalars['String'];
};

export type Rec_ApplicantEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_ApplicantEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_applicant_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_talent_pool_id: Scalars['String'];
  rec_job_posting_id: Scalars['String'];
  application_stage?: Maybe<Scalars['String']>;
  application_status: Scalars['String'];
  application_status_log?: Maybe<Array<Scalars['JSON']>>;
  expected_salary: Scalars['Float'];
  availability_date?: Maybe<Scalars['DateTime']>;
  interview_status?: Maybe<Scalars['String']>;
  final_selection_status?: Maybe<Scalars['String']>;
  interview_remark?: Maybe<Scalars['String']>;
  interview_remark_voice?: Maybe<Scalars['String']>;
  interview_remark_voice_file?: Maybe<DocumentsEntity>;
  rec_talent_pool?: Maybe<Rec_TalentPoolEntity>;
  rec_job_posting?: Maybe<Rec_JobPostingEntity>;
};

export type Rec_ApplicantInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  rec_job_posting_id?: Maybe<Scalars['String']>;
  application_stage?: Maybe<Scalars['String']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  application_status?: Maybe<Scalars['String']>;
  application_status_log?: Maybe<Array<Scalars['JSON']>>;
  expected_salary?: Maybe<Scalars['Float']>;
  interview_status?: Maybe<Scalars['String']>;
  final_selection_status?: Maybe<Scalars['String']>;
  interview_remark?: Maybe<Scalars['String']>;
  interview_remark_voice?: Maybe<Scalars['String']>;
  interview_remark_voice_input?: Maybe<DocumentsInput>;
};

export type Rec_ApplicantOutput = {
  __typename?: 'Rec_ApplicantOutput';
  rec_applicant_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  preferred_location?: Maybe<Scalars['String']>;
  rec_job_posting_id?: Maybe<Scalars['String']>;
  application_stage?: Maybe<Scalars['String']>;
  application_status?: Maybe<Scalars['String']>;
  application_status_log?: Maybe<Scalars['JSON']>;
  applicant_count_by_stage?: Maybe<Scalars['JSON']>;
  interview_status?: Maybe<Scalars['String']>;
  final_selection_status?: Maybe<Scalars['String']>;
  expected_salary?: Maybe<Scalars['Float']>;
  rec_talent_pool?: Maybe<Rec_TalentPoolEntity>;
  contact?: Maybe<ContactEntity>;
  FullName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  personnel_job_history?: Maybe<PersonnelJobHistoryEntity>;
  CompanyName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  JobPosition?: Maybe<Scalars['String']>;
  LastDrawnSalary?: Maybe<Scalars['Float']>;
  IsCurrentJob?: Maybe<Scalars['Boolean']>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  rec_requisition_id?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  rec_requisition?: Maybe<Scalars['JSON']>;
  requisition_no?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  org_unit?: Maybe<CompanyOrgUnitEntity>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  job_designation?: Maybe<JobDesignationEntity>;
  rec_offer_id?: Maybe<Scalars['String']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  latest_offer_status?: Maybe<Scalars['String']>;
  offer_count?: Maybe<Scalars['Float']>;
  response_status?: Maybe<RecruitmentOfferResponseStatus>;
  rec_offer_process_id?: Maybe<Scalars['String']>;
  offer_process_status?: Maybe<RecruitmentOfferProcessStatus>;
  requestor_name?: Maybe<Scalars['String']>;
  offer_checklist_snapshot?: Maybe<Array<Scalars['JSON']>>;
  rec_offer_content?: Maybe<Array<Rec_OfferContentEntity>>;
};

export type Rec_DeclineReasonEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_DeclineReasonEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_decline_reason_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  reason: Scalars['String'];
  in_use: Scalars['Boolean'];
};

export type Rec_DeclineReasonInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_decline_reason_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

export type Rec_EApplicationFormSetupEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_EApplicationFormSetupEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_eapplication_form_setup_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  section_name: Scalars['String'];
  sequence: Scalars['Float'];
  is_show: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  content: Scalars['String'];
  min_record: Scalars['Float'];
};

export type Rec_EApplicationFormSetupInput = {
  section_name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  is_show?: Maybe<Scalars['Boolean']>;
  is_required?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  min_record?: Maybe<Scalars['Float']>;
  rec_eapplication_form_setup_id?: Maybe<Scalars['String']>;
};

export type Rec_HiringEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_HiringEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_hiring_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  company_id: Scalars['String'];
  org_unit_id: Scalars['String'];
  job_designation_id: Scalars['String'];
  superior_id?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  acceptance_date: Scalars['DateTime'];
  duty_date: Scalars['DateTime'];
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  prehiring_status: Scalars['String'];
  is_cancel?: Maybe<Scalars['Boolean']>;
  cancel_remark?: Maybe<Scalars['String']>;
  data_update_status?: Maybe<Scalars['String']>;
  data_update_status_log?: Maybe<Array<Scalars['JSON']>>;
  onboard_status?: Maybe<Scalars['String']>;
  decline_reason?: Maybe<Scalars['String']>;
  onboard_approval_log?: Maybe<Array<Scalars['JSON']>>;
  onboard_approval_remark?: Maybe<Scalars['String']>;
  onboard_approval_remark_voice?: Maybe<Scalars['String']>;
  response_remark?: Maybe<Scalars['String']>;
  response_remark_voice?: Maybe<Scalars['String']>;
  submitter_id?: Maybe<Scalars['String']>;
  remark_voice_file?: Maybe<DocumentsEntity>;
  rec_applicant?: Maybe<Rec_ApplicantEntity>;
  rec_talent_pool?: Maybe<Rec_TalentPoolEntity>;
  superior?: Maybe<EmployeeEntity>;
  org_unit?: Maybe<CompanyOrgUnitEntity>;
  job_designation?: Maybe<JobDesignationEntity>;
  rec_source?: Maybe<RecruitmentSourceEntity>;
  onboard_approval_remark_voice_file?: Maybe<DocumentsEntity>;
  employee?: Maybe<EmployeeEntity>;
  submitter?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  RecHiringHistory?: Maybe<Array<Rec_HiringEntity>>;
};

export type Rec_HiringOutput = {
  __typename?: 'Rec_HiringOutput';
  rec_hiring_id: Scalars['String'];
  company_id?: Maybe<Scalars['String']>;
  org_unit_name: Scalars['String'];
  job_designation_id: Scalars['String'];
  job_designation_name: Scalars['String'];
  employee_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  acceptance_date: Scalars['DateTime'];
  duty_date: Scalars['DateTime'];
  prehiring_status: Scalars['String'];
  data_update_status?: Maybe<Scalars['String']>;
  data_update_status_log?: Maybe<Array<Scalars['JSON']>>;
  FullName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  document_file?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  requisition_no?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  in_use?: Maybe<Scalars['Boolean']>;
  onboard_status?: Maybe<Scalars['String']>;
  onboard_approval_log?: Maybe<Array<Scalars['JSON']>>;
  onboard_approval_remark?: Maybe<Scalars['String']>;
  onboard_approval_remark_voice?: Maybe<DocumentsEntity>;
  submitter_id?: Maybe<Scalars['String']>;
  submitter_name?: Maybe<Scalars['String']>;
  submitter_no?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  RecHiringHistory?: Maybe<Array<Rec_HiringOutput>>;
};

export type Rec_InterviewListing_Output = {
  __typename?: 'Rec_InterviewListing_Output';
  rec_applicant_id?: Maybe<Scalars['String']>;
  rec_interview_schedule_id?: Maybe<Scalars['String']>;
  applicant_name?: Maybe<Scalars['String']>;
  applicant_image?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  requisition_no?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  interview_date?: Maybe<Scalars['DateTime']>;
  start_time?: Maybe<Scalars['DateTime']>;
  end_time?: Maybe<Scalars['DateTime']>;
  venue?: Maybe<Scalars['String']>;
  schedule_remark?: Maybe<Scalars['String']>;
  rec_interviewer_id?: Maybe<Scalars['String']>;
  interviewer_id?: Maybe<Scalars['String']>;
  is_attend?: Maybe<Scalars['Boolean']>;
  rating?: Maybe<InterviewerRating>;
  is_recommend?: Maybe<Scalars['Boolean']>;
  interviewer_remark?: Maybe<Scalars['String']>;
  attachment_id?: Maybe<Scalars['String']>;
  attachment_file?: Maybe<Scalars['String']>;
  attachment_file_name?: Maybe<Scalars['String']>;
  schedule_round?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
};

export type Rec_InterviewScheduleEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_InterviewScheduleEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_interview_schedule_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_applicant_id: Scalars['String'];
  interview_date: Scalars['DateTime'];
  start_time: Scalars['DateTime'];
  end_time: Scalars['DateTime'];
  venue: Scalars['String'];
  is_complete: Scalars['Boolean'];
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  remark_voice_file?: Maybe<DocumentsEntity>;
  applicant?: Maybe<Rec_ApplicantEntity>;
  interviewers?: Maybe<Array<Rec_InterviewerEntity>>;
};

export type Rec_InterviewScheduleInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_interview_schedule_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_applicant_id: Scalars['String'];
  interview_date?: Maybe<Scalars['DateTime']>;
  start_time?: Maybe<Scalars['DateTime']>;
  end_time?: Maybe<Scalars['DateTime']>;
  venue?: Maybe<Scalars['String']>;
  is_complete?: Maybe<Scalars['Boolean']>;
  interviewers?: Maybe<Array<Scalars['ID']>>;
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  remark_voice_input?: Maybe<DocumentsInput>;
};

export type Rec_InterviewScheduleOutput = {
  __typename?: 'Rec_InterviewScheduleOutput';
  rec_interview_schedule_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  interview_date?: Maybe<Scalars['DateTime']>;
  start_time?: Maybe<Scalars['DateTime']>;
  end_time?: Maybe<Scalars['DateTime']>;
  venue?: Maybe<Scalars['String']>;
  is_complete?: Maybe<Scalars['Boolean']>;
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  interviewers?: Maybe<Array<Rec_InterviewerEntity>>;
  applicant?: Maybe<Rec_ApplicantEntity>;
  remark_voice_file?: Maybe<DocumentsEntity>;
};

export type Rec_InterviewerEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_InterviewerEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_interviewer_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_interview_schedule_id: Scalars['String'];
  interviewer_id: Scalars['String'];
  rating?: Maybe<InterviewerRating>;
  remark?: Maybe<Scalars['String']>;
  remark_voice?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  is_recommend?: Maybe<Scalars['Boolean']>;
  is_attend?: Maybe<Scalars['Boolean']>;
  remark_voice_file?: Maybe<DocumentsEntity>;
  attachment_file?: Maybe<DocumentsEntity>;
  interview_schedule?: Maybe<Rec_InterviewScheduleEntity>;
  interviewer?: Maybe<EmployeeEntity>;
};

export type Rec_InterviewerInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_interviewer_id: Scalars['String'];
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_interview_schedule_id: Scalars['String'];
  interviewer_id: Scalars['String'];
  rating?: Maybe<InterviewerRating>;
  remark?: Maybe<Scalars['String']>;
  is_recommend?: Maybe<Scalars['Boolean']>;
  is_attend?: Maybe<Scalars['Boolean']>;
  remark_voice?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  remark_voice_input?: Maybe<DocumentsInput>;
  attachment_input?: Maybe<DocumentsInput>;
};

export type Rec_JobPortalSetupEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobPortalSetupEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_portal_setup_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  desktop_banner_attachment?: Maybe<Scalars['String']>;
  mobile_banner_attachment?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  theme_color?: Maybe<Scalars['String']>;
  job_portal_qr?: Maybe<Scalars['String']>;
  job_portal_token: Scalars['String'];
  desktop_banner?: Maybe<DocumentsEntity>;
  mobile_banner?: Maybe<DocumentsEntity>;
  qrcode?: Maybe<DocumentsEntity>;
};

export type Rec_JobPortalSetupInput = {
  name: Scalars['String'];
  desktop_banner_attachment?: Maybe<Scalars['String']>;
  mobile_banner_attachment?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  theme_color?: Maybe<Scalars['String']>;
  job_portal_qr?: Maybe<Scalars['String']>;
  job_portal_token?: Maybe<Scalars['String']>;
  desktop_banner_input?: Maybe<DocumentsInput>;
  mobile_banner_input?: Maybe<DocumentsInput>;
  qrcode_input?: Maybe<DocumentsInput>;
};

export type Rec_JobPostingEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobPostingEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_posting_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_job_vacancy_id: Scalars['String'];
  job_posting_status: Scalars['String'];
  job_description: Scalars['String'];
  job_specification: Scalars['String'];
  job_closing_date: Scalars['DateTime'];
  remark?: Maybe<Scalars['String']>;
  rec_job_vacancy: Rec_JobVacancyEntity;
  rec_job_posting_portal: Array<Rec_JobPostingPortalEntity>;
};

export type Rec_JobPostingInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_job_posting_id?: Maybe<Scalars['String']>;
  subscription_account_id: Scalars['String'];
  rec_job_vacancy_id: Scalars['String'];
  job_posting_status: RecruitmentJobPostingStatus;
  job_description: Scalars['String'];
  job_specification: Scalars['String'];
  job_closing_date: Scalars['DateTime'];
  rec_job_portal_setup_id?: Maybe<Array<Scalars['String']>>;
  remark?: Maybe<Scalars['String']>;
};

export type Rec_JobPostingOutput = {
  __typename?: 'Rec_JobPostingOutput';
  rec_job_posting_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  job_posting_status?: Maybe<Scalars['String']>;
  job_description?: Maybe<Scalars['String']>;
  job_specification?: Maybe<Scalars['String']>;
  job_closing_date?: Maybe<Scalars['DateTime']>;
  remark?: Maybe<Scalars['String']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  requisition_no?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  rec_job_posting_portal?: Maybe<Scalars['JSON']>;
  in_use?: Maybe<Scalars['Boolean']>;
  applicant_count?: Maybe<Scalars['JSON']>;
};

export type Rec_JobPostingPortalEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobPostingPortalEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_posting_portal_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_job_posting_id: Scalars['String'];
  rec_job_portal_setup_id: Scalars['String'];
  rec_job_posting: Rec_JobPostingEntity;
  rec_job_portal_setup: Rec_JobPortalSetupEntity;
};

export type Rec_JobPostingUpdate = {
  subscription_account_id: Scalars['String'];
  rec_job_vacancy_id: Scalars['String'];
  job_description: Scalars['String'];
  job_specification: Scalars['String'];
  job_closing_date: Scalars['DateTime'];
  rec_job_portal_setup_id: Array<Scalars['String']>;
};

export type Rec_JobPostingUpdateStatus = {
  job_posting_status: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
};

export type Rec_JobVacancyEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobVacancyEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_vacancy_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_requisition_id: Scalars['String'];
  job_vacancy_no: Scalars['String'];
  job_vacancy_status: Scalars['String'];
  location: Scalars['String'];
  target_hiring_date: Scalars['DateTime'];
  job_vacancy_status_log: Array<Scalars['JSON']>;
  requisition?: Maybe<Rec_RequisitionEntity>;
};

export type Rec_JobVacancyExpenseEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobVacancyExpenseEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_vacancy_expense_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_job_vacancy_id: Scalars['String'];
  rec_expense_id: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  rec_job_vacancy?: Maybe<Rec_JobVacancyEntity>;
  rec_expense?: Maybe<RecruitmentExpensesEntity>;
};

export type Rec_JobVacancyExpenseInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_job_vacancy_expense_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  rec_expense_id?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type Rec_JobVacancyInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_requisition_id?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  target_hiring_date: Scalars['DateTime'];
  job_vacancy_status_log?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_JobVacancyOutput = {
  __typename?: 'Rec_JobVacancyOutput';
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  rec_requisition_id?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  job_vacancy_status_log?: Maybe<Array<Scalars['JSON']>>;
  total_amount?: Maybe<Scalars['Float']>;
  rec_requisition?: Maybe<Scalars['JSON']>;
  requisition_no?: Maybe<Scalars['String']>;
  requested_headcount?: Maybe<Scalars['Float']>;
  org_unit_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  org_unit?: Maybe<CompanyOrgUnitEntity>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  job_designation?: Maybe<JobDesignationEntity>;
  rec_job_vacancy_source?: Maybe<Array<Rec_JobVacancySourceEntity>>;
  rec_job_vacancy_expense?: Maybe<Array<Rec_JobVacancyExpenseEntity>>;
  applicant_count_by_stage?: Maybe<Scalars['JSON']>;
  in_use?: Maybe<Scalars['Boolean']>;
  application_stage?: Maybe<Scalars['String']>;
  is_applied?: Maybe<Scalars['Boolean']>;
  has_access?: Maybe<Scalars['Boolean']>;
};

export type Rec_JobVacancySourceEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_JobVacancySourceEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_job_vacancy_source_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_job_vacancy_id: Scalars['String'];
  source_id: Scalars['String'];
  rec_job_vacancy?: Maybe<Rec_JobVacancyEntity>;
  rec_source?: Maybe<RecruitmentSourceEntity>;
};

export type Rec_JobVacancySourceInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_job_vacancy_source_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
};

export type Rec_JobVacancyWithApplicantOutput = {
  __typename?: 'Rec_JobVacancyWithApplicantOutput';
  job_vacancy_id?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_posting_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  requestor_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  applicant_name?: Maybe<Scalars['String']>;
  applicant_image?: Maybe<Scalars['String']>;
  application_stage?: Maybe<Scalars['String']>;
  interview_remark?: Maybe<Scalars['String']>;
  expected_salary?: Maybe<Scalars['Float']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  final_selection_status?: Maybe<Scalars['String']>;
};

export type Rec_OfferContentEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_OfferContentEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_offer_content_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_offer_process_id: Scalars['String'];
  rec_offer_checklist_id: Scalars['String'];
  eletter_document?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  offer_content_status: RecruitmentOfferContentStatus;
  rec_offer_process?: Maybe<Rec_OfferProcessEntity>;
  eletter_document_file?: Maybe<DocumentsEntity>;
};

export type Rec_OfferContentInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_offer_content_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  rec_offer_process_id?: Maybe<Scalars['String']>;
  rec_offer_checklist_id?: Maybe<Scalars['String']>;
  eletter_document?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  offer_content_status?: Maybe<RecruitmentOfferContentStatus>;
  eletter_document_input?: Maybe<DocumentsInput>;
};

export type Rec_OfferEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_OfferEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_offer_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_applicant_id: Scalars['String'];
  salary?: Maybe<Scalars['Float']>;
  probation_month?: Maybe<Scalars['Float']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  offer_remark?: Maybe<Scalars['String']>;
  offer_remark_voice?: Maybe<Scalars['String']>;
  offer_attachment?: Maybe<Scalars['String']>;
  submit_date?: Maybe<Scalars['DateTime']>;
  offer_status?: Maybe<RecruitmentOfferStatus>;
  approval_log?: Maybe<Array<Scalars['JSON']>>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_remark_voice?: Maybe<Scalars['String']>;
  response_status?: Maybe<RecruitmentOfferResponseStatus>;
  response_date?: Maybe<Scalars['DateTime']>;
  response_remark?: Maybe<Scalars['String']>;
  response_remark_voice?: Maybe<Scalars['String']>;
  response_attachment?: Maybe<Scalars['String']>;
  decline_reason?: Maybe<Scalars['String']>;
  submitter_id?: Maybe<Scalars['String']>;
  rec_applicant?: Maybe<Rec_ApplicantEntity>;
  offer_remark_voice_file?: Maybe<DocumentsEntity>;
  offer_attachment_file?: Maybe<DocumentsEntity>;
  approval_remark_voice_file?: Maybe<DocumentsEntity>;
  response_remark_voice_file?: Maybe<DocumentsEntity>;
  response_attachment_file?: Maybe<DocumentsEntity>;
  rec_decline_reason?: Maybe<Rec_DeclineReasonEntity>;
  submitter?: Maybe<EmployeeEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  RecOfferHistory?: Maybe<Array<Rec_OfferEntity>>;
};

export type Rec_OfferInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_offer_id?: Maybe<Scalars['String']>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Float']>;
  probation_month?: Maybe<Scalars['Float']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  offer_remark?: Maybe<Scalars['String']>;
  offer_remark_voice?: Maybe<Scalars['String']>;
  offer_remark_voice_input?: Maybe<DocumentsInput>;
  offer_attachment?: Maybe<Scalars['String']>;
  offer_attachment_input?: Maybe<DocumentsInput>;
  submit_date?: Maybe<Scalars['DateTime']>;
  offer_status?: Maybe<RecruitmentOfferStatus>;
  approval_id?: Maybe<Scalars['String']>;
  approval_log?: Maybe<Array<Scalars['JSON']>>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_remark_voice?: Maybe<Scalars['String']>;
  approval_remark_voice_input?: Maybe<DocumentsInput>;
  response_status?: Maybe<RecruitmentOfferResponseStatus>;
  response_date?: Maybe<Scalars['DateTime']>;
  response_remark?: Maybe<Scalars['String']>;
  response_remark_voice?: Maybe<Scalars['String']>;
  response_remark_voice_input?: Maybe<DocumentsInput>;
  response_attachment?: Maybe<Scalars['String']>;
  response_attachment_input?: Maybe<DocumentsInput>;
  decline_reason?: Maybe<Scalars['String']>;
  submitter_id?: Maybe<Scalars['String']>;
};

export type Rec_OfferOutput = {
  __typename?: 'Rec_OfferOutput';
  rec_offer_id?: Maybe<Scalars['String']>;
  duty_date?: Maybe<Scalars['DateTime']>;
  offer_status?: Maybe<RecruitmentOfferStatus>;
  probation_month?: Maybe<Scalars['Float']>;
  offer_remark?: Maybe<Scalars['String']>;
  offer_remark_voice_file?: Maybe<DocumentsEntity>;
  offer_attachment_file?: Maybe<DocumentsEntity>;
  response_status?: Maybe<RecruitmentOfferResponseStatus>;
  rec_applicant_id?: Maybe<Scalars['String']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  expected_salary: Scalars['Float'];
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  rec_job_vacancy_id?: Maybe<Scalars['String']>;
  job_vacancy_no?: Maybe<Scalars['String']>;
  job_vacancy_status?: Maybe<Scalars['String']>;
  target_hiring_date?: Maybe<Scalars['DateTime']>;
  rec_requisition_id?: Maybe<Scalars['String']>;
  requisition_no?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  offer_count?: Maybe<Scalars['Float']>;
  rec_offer_process_id?: Maybe<Scalars['String']>;
  offer_process_status?: Maybe<RecruitmentOfferProcessStatus>;
  send_log?: Maybe<Array<Scalars['JSON']>>;
  requestor_name?: Maybe<Scalars['String']>;
  offer_checklist_snapshot?: Maybe<Array<Scalars['JSON']>>;
  rec_offer_content?: Maybe<Array<Rec_OfferContentEntity>>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_remark_voice_file?: Maybe<DocumentsEntity>;
  approval_log?: Maybe<Array<Scalars['JSON']>>;
  submitter_id?: Maybe<Scalars['String']>;
  submitter_name?: Maybe<Scalars['String']>;
  submitter_no?: Maybe<Scalars['String']>;
  submit_date?: Maybe<Scalars['DateTime']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  RecOfferHistory?: Maybe<Array<Rec_OfferOutput>>;
};

export type Rec_OfferProcessEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_OfferProcessEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_offer_process_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_offer_id: Scalars['String'];
  offer_checklist_snapshot?: Maybe<Array<Scalars['JSON']>>;
  offer_process_status: RecruitmentOfferProcessStatus;
  send_log?: Maybe<Array<Scalars['JSON']>>;
  rec_offer?: Maybe<Rec_OfferEntity>;
};

export type Rec_OfferProcessInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_offer_process_id?: Maybe<Scalars['String']>;
  rec_offer_id?: Maybe<Scalars['String']>;
  offer_checklist_snapshot?: Maybe<Array<OfferChecklistSnapshot>>;
  offer_process_status: RecruitmentOfferProcessStatus;
  send_log?: Maybe<Array<SendLog>>;
};

export type Rec_PrehiringFormSetupEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_PrehiringFormSetupEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_prehiring_form_setup_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  section_name: Scalars['String'];
  sequence: Scalars['Float'];
  is_show: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  min_record: Scalars['Float'];
};

export type Rec_PrehiringFormSetupInput = {
  section_name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  is_show?: Maybe<Scalars['Boolean']>;
  is_required?: Maybe<Scalars['Boolean']>;
  min_record?: Maybe<Scalars['Float']>;
  rec_prehiring_form_setup_id?: Maybe<Scalars['String']>;
};

export type Rec_RequisitionEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_RequisitionEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_requisition_id: Scalars['String'];
  subscription_account_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  requestor_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  requisition_no?: Maybe<Scalars['String']>;
  employee_type: EmploymentType;
  recruitment_type: RecruitmentType;
  requested_headcount?: Maybe<Scalars['Float']>;
  is_budget?: Maybe<Scalars['Boolean']>;
  request_date?: Maybe<Scalars['DateTime']>;
  required_date?: Maybe<Scalars['DateTime']>;
  justification_remark?: Maybe<Scalars['String']>;
  justification_remark_voice?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  job_description?: Maybe<Scalars['String']>;
  min_year_experience?: Maybe<Scalars['Float']>;
  min_salary?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  min_education_id?: Maybe<Scalars['String']>;
  job_specification?: Maybe<Scalars['String']>;
  rec_requisition_status?: Maybe<Scalars['String']>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_remark_voice?: Maybe<Scalars['String']>;
  approval_log: Array<Scalars['JSON']>;
  requestor?: Maybe<EmployeeEntity>;
  org_unit?: Maybe<CompanyOrgUnitEntity>;
  job_designation?: Maybe<JobDesignationEntity>;
  attachment_file?: Maybe<DocumentsEntity>;
  justification_remark_voice_file?: Maybe<DocumentsEntity>;
  approval_remark_voice_file?: Maybe<DocumentsEntity>;
  min_education?: Maybe<PersonnelParameterEntity>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  RecRequisitionHistory?: Maybe<Array<Rec_RequisitionEntity>>;
  org_unit_name: Scalars['String'];
};

export type Rec_RequisitionInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_requisition_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  job_designation_id?: Maybe<Scalars['String']>;
  requestor_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  requisition_no?: Maybe<Scalars['String']>;
  employee_type?: Maybe<Scalars['String']>;
  recruitment_type?: Maybe<Scalars['String']>;
  requested_headcount?: Maybe<Scalars['Float']>;
  is_budget?: Maybe<Scalars['Boolean']>;
  request_date?: Maybe<Scalars['DateTime']>;
  required_date?: Maybe<Scalars['DateTime']>;
  justification_remark?: Maybe<Scalars['String']>;
  justification_voice_input?: Maybe<DocumentsInput>;
  justification_voice_id?: Maybe<Scalars['String']>;
  attachment_input?: Maybe<DocumentsInput>;
  attachment_id?: Maybe<Scalars['String']>;
  job_description?: Maybe<Scalars['String']>;
  min_year_experience?: Maybe<Scalars['Float']>;
  min_salary?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  min_education_id?: Maybe<Scalars['String']>;
  job_specification?: Maybe<Scalars['String']>;
  rec_requisition_status?: Maybe<ApprovalStatus>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_voice_input?: Maybe<DocumentsInput>;
  approval_voice_id?: Maybe<Scalars['String']>;
  approval_log?: Maybe<Array<Approval_Log>>;
  approval_id?: Maybe<Scalars['String']>;
};

export type Rec_Requisition_Output = {
  __typename?: 'Rec_Requisition_Output';
  rec_requisition_id: Scalars['String'];
  subscription_account_id?: Maybe<Scalars['String']>;
  org_unit_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  org_unit_name?: Maybe<Scalars['String']>;
  org_unit?: Maybe<CompanyOrgUnitEntity>;
  job_designation_id?: Maybe<Scalars['String']>;
  job_designation_name?: Maybe<Scalars['String']>;
  job_designation?: Maybe<JobDesignationEntity>;
  requestor_id?: Maybe<Scalars['String']>;
  requestor?: Maybe<EmployeeEntity>;
  requisition_no?: Maybe<Scalars['String']>;
  employee_type?: Maybe<Scalars['String']>;
  recruitment_type?: Maybe<Scalars['String']>;
  requested_headcount?: Maybe<Scalars['Float']>;
  is_budget?: Maybe<Scalars['Boolean']>;
  request_date?: Maybe<Scalars['DateTime']>;
  required_date?: Maybe<Scalars['DateTime']>;
  justification_remark?: Maybe<Scalars['String']>;
  justification_remark_voice_file?: Maybe<DocumentsEntity>;
  attachment_file?: Maybe<DocumentsEntity>;
  job_description?: Maybe<Scalars['String']>;
  min_year_experience?: Maybe<Scalars['Float']>;
  min_salary?: Maybe<Scalars['Float']>;
  max_salary?: Maybe<Scalars['Float']>;
  min_education_id?: Maybe<Scalars['String']>;
  min_education?: Maybe<PersonnelParameterEntity>;
  job_specification?: Maybe<Scalars['String']>;
  rec_requisition_status?: Maybe<Scalars['String']>;
  approval_remark?: Maybe<Scalars['String']>;
  approval_remark_voice_file?: Maybe<DocumentsEntity>;
  approval_log?: Maybe<Array<Scalars['JSON']>>;
  submitter_id?: Maybe<Scalars['String']>;
  submitter_name?: Maybe<Scalars['String']>;
  submitter_no?: Maybe<Scalars['String']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  RecRequisitionHistory?: Maybe<Array<Rec_Requisition_Output>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type Rec_TalentInvitationEntity = {
  __typename?: 'Rec_TalentInvitationEntity';
  rec_talent_invitation_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_talent_pool_id: Scalars['String'];
  source_id?: Maybe<Scalars['String']>;
  invite_date: Scalars['DateTime'];
  invite_status: InviteStatus;
  is_cancel?: Maybe<Scalars['Boolean']>;
  cancel_remark?: Maybe<Scalars['String']>;
  talent_invitation_log?: Maybe<Array<Scalars['JSON']>>;
  rec_talent_pool?: Maybe<Rec_TalentPoolEntity>;
};

export type Rec_TalentInvitationOutput = {
  __typename?: 'Rec_TalentInvitationOutput';
  rec_talent_invitation_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  document_file?: Maybe<Scalars['String']>;
  invite_date?: Maybe<Scalars['DateTime']>;
  talent_invitation_log?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_TalentPoolEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_TalentPoolEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_talent_pool_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  contact_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  invite_token?: Maybe<Scalars['String']>;
  talent_status?: Maybe<RecruitmentTalentStatus>;
  talent_status_log?: Maybe<Array<Scalars['JSON']>>;
  is_blacklist: Scalars['Boolean'];
  is_hire: Scalars['Boolean'];
  declaration_dt?: Maybe<Scalars['DateTime']>;
  no_of_children?: Maybe<Scalars['Float']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  expected_salary?: Maybe<Scalars['Float']>;
  current_location?: Maybe<Scalars['String']>;
  preferred_location?: Maybe<Scalars['String']>;
  additional_user_field?: Maybe<Array<Scalars['JSON']>>;
  contact?: Maybe<ContactEntity>;
};

export type Rec_TalentPoolInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  contact_id?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  invite_token?: Maybe<Scalars['String']>;
  talent_status?: Maybe<Scalars['String']>;
  talent_status_log?: Maybe<Array<Scalars['JSON']>>;
  is_blacklist?: Maybe<Scalars['Boolean']>;
  is_hire?: Maybe<Scalars['Boolean']>;
  declaration_dt?: Maybe<Scalars['DateTime']>;
  no_of_children?: Maybe<Scalars['Float']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  expected_salary?: Maybe<Scalars['Float']>;
  current_location?: Maybe<Scalars['String']>;
  preferred_location?: Maybe<Scalars['String']>;
  additional_user_field?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_TalentPoolOutput = {
  __typename?: 'Rec_TalentPoolOutput';
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  preferred_location?: Maybe<Scalars['String']>;
  availability_date?: Maybe<Scalars['DateTime']>;
  expected_salary?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  talent_status?: Maybe<Scalars['String']>;
  talent_status_log?: Maybe<Array<Scalars['JSON']>>;
  is_hire?: Maybe<Scalars['Boolean']>;
  is_blacklist?: Maybe<Scalars['Boolean']>;
  application_count?: Maybe<Scalars['Float']>;
  applicant_ids?: Maybe<Array<Scalars['String']>>;
  talent_image?: Maybe<Scalars['String']>;
  talent_name?: Maybe<Scalars['String']>;
  job_position?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_drawn_salary?: Maybe<Scalars['Float']>;
  is_current_job?: Maybe<Scalars['Boolean']>;
};

export type Rec_TalentPoolUserFieldsEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_TalentPoolUserFieldsEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_additional_user_field_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  field_label: Scalars['String'];
  field_type?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['JSON']>>;
  is_required?: Maybe<Scalars['Boolean']>;
  is_active?: Maybe<Scalars['Boolean']>;
  in_use?: Maybe<Scalars['Boolean']>;
};

export type Rec_TalentPoolUserFieldsInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  rec_additional_user_field_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  field_label?: Maybe<Scalars['String']>;
  field_type?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['JSON']>>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_required?: Maybe<Scalars['Boolean']>;
};

export type Rec_TalentSearchEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_TalentSearchEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_talent_search_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  employee_id?: Maybe<Scalars['String']>;
  predefined_search_name: Scalars['String'];
  job_position?: Maybe<Array<Scalars['String']>>;
  preferred_location?: Maybe<Array<Scalars['String']>>;
  extra_filter?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_TalentSearchInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  predefined_search_name: Scalars['String'];
  employee_id?: Maybe<Scalars['String']>;
  job_position?: Maybe<Array<Scalars['String']>>;
  preferred_location?: Maybe<Array<Scalars['String']>>;
  extra_filter?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_TalentSearchOutput = {
  __typename?: 'Rec_TalentSearchOutput';
  rec_talent_search_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  predefined_search_name?: Maybe<Scalars['String']>;
  job_position?: Maybe<Array<Scalars['String']>>;
  preferred_location?: Maybe<Array<Scalars['String']>>;
  extra_filter?: Maybe<Array<Scalars['JSON']>>;
};

export type Rec_TalentStatutoryEntity = NewInterfaceBase1 & {
  __typename?: 'Rec_TalentStatutoryEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_talent_statutory_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_talent_pool_id: Scalars['String'];
  bank: Scalars['String'];
  account_no: Scalars['String'];
  EPF_no?: Maybe<Scalars['String']>;
  tax_no?: Maybe<Scalars['String']>;
  tax_marital_status?: Maybe<TaxMaritialStatus>;
  tax_resident_status?: Maybe<ResidentStatus>;
  country_code: Scalars['String'];
  rec_talent_pool?: Maybe<Rec_TalentPoolEntity>;
};

export type Rec_TalentStatutoryInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  bank: Scalars['String'];
  account_no: Scalars['String'];
  EPF_no?: Maybe<Scalars['String']>;
  tax_no?: Maybe<Scalars['String']>;
  tax_marital_status?: Maybe<TaxMaritialStatus>;
  tax_resident_status?: Maybe<ResidentStatus>;
  country_code?: Maybe<Scalars['String']>;
};

export enum RecommendOption {
  AnnualIncrement = 'Annual_Increment',
  SalaryAdjustment = 'Salary_Adjustment',
  Promotion = 'Promotion',
  Redesignation = 'Redesignation',
  WitholdIncrement = 'Withold_Increment',
  Termination = 'Termination'
}

export type RecommendSectionInput = {
  RecommendOption?: Maybe<RecommendOption>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  EffectiveUntilDate?: Maybe<Scalars['DateTime']>;
  CurrentSalary?: Maybe<Scalars['Float']>;
  ProposedIncrement?: Maybe<Scalars['Float']>;
  CurrentJobPosition?: Maybe<Scalars['String']>;
  NewJobPosition?: Maybe<Scalars['String']>;
  CurrentJobGrade?: Maybe<Scalars['String']>;
  NewJobGrade?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export enum RecordSource {
  ExcelUpload = 'ExcelUpload',
  ManualByPayItem = 'ManualByPayItem',
  ManualByEmployee = 'ManualByEmployee',
  RecurringPay = 'RecurringPay',
  RecurringZakat = 'RecurringZakat',
  AdvancePolicy = 'AdvancePolicy'
}

export enum RecruitmentApplicationStage {
  New = 'New',
  Prescreen = 'Prescreen',
  Shortlist = 'Shortlist',
  Interview = 'Interview',
  Offer = 'Offer',
  Accept = 'Accept',
  NotSuitable = 'Not_Suitable'
}

export enum RecruitmentApplicationStatus {
  Applied = 'Applied',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export type RecruitmentDocumentEntity = NewInterfaceBase1 & {
  __typename?: 'RecruitmentDocumentEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_document_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  document_type?: Maybe<DocumentType>;
  description?: Maybe<Scalars['String']>;
  document_file?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['Float']>;
  content?: Maybe<Scalars['String']>;
  bucket_file_name?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
};

export type RecruitmentDocumentInput = {
  rec_document_id?: Maybe<Scalars['String']>;
  rec_talent_pool_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  document_type?: Maybe<DocumentType>;
  description?: Maybe<Scalars['String']>;
  document_file?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['Float']>;
  content?: Maybe<Scalars['String']>;
  bucket_file_name?: Maybe<Scalars['String']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
};

export type RecruitmentE_LetterEntity = NewInterfaceBase1 & {
  __typename?: 'RecruitmentE_LetterEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_eletter_template_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  content: Scalars['String'];
  type: Scalars['String'];
  is_default: Scalars['Boolean'];
  rec_offer_checklist_id: Scalars['String'];
  in_use?: Maybe<Scalars['Boolean']>;
  rec_document_id?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
};

export type RecruitmentE_LetterInput = {
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  is_default?: Maybe<Scalars['Boolean']>;
  rec_offer_checklist_id?: Maybe<Scalars['String']>;
  rec_document_id?: Maybe<Scalars['String']>;
  RecruitmentDocumentInput?: Maybe<DocumentsInput>;
};

export type RecruitmentExpensesEntity = NewInterfaceBase1 & {
  __typename?: 'RecruitmentExpensesEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_expense_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  in_use: Scalars['Boolean'];
};

export type RecruitmentExpensesInput = {
  name?: Maybe<Scalars['String']>;
  rec_expense_id?: Maybe<Scalars['String']>;
};

export enum RecruitmentFinalSelectionStatus {
  Pending = 'Pending',
  Offered = 'Offered',
  Declined = 'Declined'
}

export enum RecruitmentJobPostingStatus {
  Pending = 'Pending',
  Published = 'Published',
  Closed = 'Closed'
}

export enum RecruitmentJobVacancyStatus {
  Active = 'Active',
  Closed = 'Closed',
  Cancelled = 'Cancelled'
}

export enum RecruitmentOfferContentStatus {
  Draft = 'Draft',
  Completed = 'Completed'
}

export enum RecruitmentOfferProcessStatus {
  Incomplete = 'Incomplete',
  Ready = 'Ready',
  Finalized = 'Finalized',
  Completed = 'Completed'
}

export enum RecruitmentOfferResponseStatus {
  Accepted = 'Accepted',
  Declined = 'Declined'
}

export enum RecruitmentOfferStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export enum RecruitmentPolicyType {
  RecruitmentRequisition = 'Recruitment_Requisition',
  Offer = 'Offer',
  Onboard = 'Onboard'
}

export type RecruitmentPre_OfferChecklistEntity = NewInterfaceBase1 & {
  __typename?: 'RecruitmentPre_offerChecklistEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  rec_offer_checklist_id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  sequence: Scalars['Float'];
  in_use?: Maybe<Scalars['Boolean']>;
  is_show: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
};

export type RecruitmentPre_OfferChecklistInput = {
  rec_offer_checklist_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  is_show?: Maybe<Scalars['Boolean']>;
  is_required?: Maybe<Scalars['Boolean']>;
};

export type RecruitmentSourceBulkInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  RecruitmentSourceID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type RecruitmentSourceEntity = InterfaceBase & {
  __typename?: 'RecruitmentSourceEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecruitmentSourceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  EmployeeInfo?: Maybe<EmployeeEntity>;
};

export type RecruitmentSourceInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum RecruitmentTalentStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Hired = 'Hired',
  Blacklisted = 'Blacklisted'
}

export enum RecruitmentType {
  New = 'New',
  Replacement = 'Replacement'
}

export type RecurringPayEntity = InterfaceBase & {
  __typename?: 'RecurringPayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecurringPayID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  StartPeriod?: Maybe<PayPeriodEntity>;
  EndPeriod?: Maybe<PayPeriodEntity>;
  Employee?: Maybe<EmployeeEntity>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type RecurringPayInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OldRecurringPayID?: Maybe<Array<Scalars['String']>>;
};

export type RecurringRestOffDayEntity = InterfaceBase & {
  __typename?: 'RecurringRestOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecurringRestOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  RestOffType: RestOffType;
  DayOfWeek: Scalars['Float'];
  StateID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  States?: Maybe<StatesEntity>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
};

export type RecurringRestOffDayInput = {
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  RestOffType?: Maybe<RestOffType>;
  DayOfWeek: Scalars['Float'];
  StateID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  RecurringRestOffDayIDs?: Maybe<Array<Scalars['String']>>;
};

export enum Relationship {
  Aunt = 'Aunt',
  Brother = 'Brother',
  Cousin = 'Cousin',
  Daughter = 'Daughter',
  Father = 'Father',
  Husband = 'Husband',
  Mother = 'Mother',
  Nephew = 'Nephew',
  Niece = 'Niece',
  Sister = 'Sister',
  Son = 'Son',
  Uncle = 'Uncle',
  Wife = 'Wife',
  Others = 'Others'
}

export enum Religion {
  Islam = 'Islam',
  Buddhism = 'Buddhism',
  Christianity = 'Christianity',
  Hinduism = 'Hinduism',
  Taoism = 'Taoism',
  Sikh = 'Sikh',
  Others = 'Others'
}

export type ReplacementLeaveClaimType = {
  __typename?: 'ReplacementLeaveClaimType';
  DayType: DayType;
  ReplacementLeaveClaimTypeListInput?: Maybe<Array<ReplacementLeaveClaimTypeList>>;
};

export type ReplacementLeaveClaimTypeInput = {
  DayType: DayType;
  ReplacementLeaveClaimTypeListInput?: Maybe<Array<ReplacementLeaveClaimTypeListInput>>;
};

export type ReplacementLeaveClaimTypeList = {
  __typename?: 'ReplacementLeaveClaimTypeList';
  OTHoursGreater?: Maybe<Scalars['Float']>;
  OTHoursLess?: Maybe<Scalars['Float']>;
  RLEntitle?: Maybe<Scalars['Float']>;
};

export type ReplacementLeaveClaimTypeListInput = {
  OTHoursGreater?: Maybe<Scalars['Float']>;
  OTHoursLess?: Maybe<Scalars['Float']>;
  RLEntitle?: Maybe<Scalars['Float']>;
};

export type ReportPayItemOutput = {
  __typename?: 'ReportPayItemOutput';
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
  PayItemTypeName?: Maybe<Scalars['String']>;
};

export type ReportTemplateAssignmentEntity = InterfaceBase & {
  __typename?: 'ReportTemplateAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportTemplateAssignmentID: Scalars['String'];
  ReportTemplateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
};

export type ReportTemplateAssignmentInput = {
  ReportTemplateAssignmentID?: Maybe<Scalars['String']>;
  ReportTemplateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ReportTemplateEntity = InterfaceBase & {
  __typename?: 'ReportTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportTemplateID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
  Documents?: Maybe<DocumentsEntity>;
};

export type ReportTemplateInput = {
  ReportTemplateID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  ReportTemplateType?: Maybe<ReportTemplateType>;
  Status?: Maybe<Status>;
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ReportTemplateType {
  Confirmation = 'Confirmation',
  ProbationExtension = 'Probation_Extension',
  Demotion = 'Demotion',
  Secondment = 'Secondment',
  Promotion = 'Promotion',
  Redesignation = 'Redesignation',
  InternalTransfer = 'Internal_Transfer',
  InterCompanyTransfer = 'Inter_Company_Transfer',
  Termination = 'Termination',
  Retirement = 'Retirement',
  ExitCompany = 'Exit_Company',
  OfferLetter = 'Offer_Letter',
  HealthScreening = 'Health_Screening',
  BankAccountOpening = 'Bank_Account_Opening'
}

export enum ReportType {
  EaForm = 'EAForm',
  Cp21 = 'CP21',
  Cp22A = 'CP22A',
  Cp22B = 'CP22B',
  Cp22 = 'CP22'
}

export type ReportYearOutput = {
  __typename?: 'ReportYearOutput';
  Year?: Maybe<Scalars['Float']>;
};

export type ReportingGroupEntity = NewInterfaceBase & {
  __typename?: 'ReportingGroupEntity';
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogs>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  reporting_group_id: Scalars['String'];
  subscription_account_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  GroupInUse: Scalars['Boolean'];
  CompanyInUse: Scalars['Boolean'];
};

export type ReportingGroupInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ReportingGroupID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
};

export enum ReportingRecordType {
  Active = 'Active',
  Future = 'Future',
  Expired = 'Expired'
}

export type ReportingStructureEntity = InterfaceBase & {
  __typename?: 'ReportingStructureEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportingStructureID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromOrgUnitID?: Maybe<Scalars['String']>;
  FromOrgUnit?: Maybe<CompanyOrgUnitEntity>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<CompanyOrgUnitEntity>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  FromJobPosition?: Maybe<JobDesignationEntity>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  ToJobPosition?: Maybe<JobDesignationEntity>;
  SubordinateID?: Maybe<Scalars['String']>;
  Subordinate?: Maybe<EmployeeEntity>;
  SuperiorID?: Maybe<Scalars['String']>;
  Superior?: Maybe<EmployeeEntity>;
  ReportingType: ReportingType;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type ReportingStructureGroupInput = {
  ReportingGroupID?: Maybe<Scalars['String']>;
  ItemActionType?: Maybe<ItemActionType>;
  CompanyID: Scalars['String'];
  JobPositionReportingInput?: Maybe<Array<JobPositionReportingInput>>;
  EmployeeReportingInput?: Maybe<Array<EmployeeReportingInput>>;
};

export type ReportingStructureInput = {
  ReportingStructureID: Scalars['String'];
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  ReportingGroupID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
};

export type ReportingStructureOutput = {
  __typename?: 'ReportingStructureOutput';
  ReportingGroupID: Scalars['String'];
  ReportingGroup?: Maybe<ReportingGroupEntity>;
  ItemActionType: ItemActionType;
  Title: Scalars['String'];
  JobPositionReporting?: Maybe<Array<JobPositionReportingOutput>>;
  EmployeeReporting?: Maybe<Array<EmployeeReportingOutput>>;
};

export enum ReportingType {
  ByEmployee = 'By_Employee',
  ByJobPosition = 'By_JobPosition'
}

export enum RequestType {
  Leave = 'Leave',
  Claim = 'Claim',
  Requisition = 'Requisition',
  Overtime = 'Overtime',
  ChangeShift = 'Change_Shift',
  TimeOff = 'Time_Off',
  Request = 'Request',
  ProjectExpenseClaim = 'Project_Expense_Claim',
  DataChanges = 'Data_Changes',
  KpiSetting = 'KPI_Setting',
  KpiApproval = 'KPI_Approval',
  ReviewSetting = 'Review_Setting',
  ReviewApproval = 'Review_Approval',
  HelpdeskTicket = 'Helpdesk_Ticket',
  ProjectTimesheet = 'Project_Timesheet',
  TrainingRequest = 'Training_Request',
  TrainingSelfRegistration = 'Training_SelfRegistration',
  TrainingNomination = 'Training_Nomination',
  TrainingWithdrawal = 'Training_Withdrawal',
  TrainingEvaluation = 'Training_Evaluation',
  AttendanceCorrectionRequest = 'Attendance_Correction_Request',
  BatchOvertime = 'Batch_Overtime',
  RecruitmentRequisition = 'Recruitment_Requisition',
  RecruitmentOffer = 'Recruitment_Offer',
  RecruitmentOnboard = 'Recruitment_Onboard'
}

export type RequisitionAppOutput = {
  __typename?: 'RequisitionAppOutput';
  RequisitionType?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<Scalars['String']>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type RequisitionDetails = {
  __typename?: 'RequisitionDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TotalDays?: Maybe<Scalars['Float']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type RequisitionEntity = InterfaceBase & {
  __typename?: 'RequisitionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RequisitionHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  TravelReqNo?: Maybe<Scalars['String']>;
  AssetReqNo?: Maybe<Scalars['String']>;
  CashReqNo?: Maybe<Scalars['String']>;
  OtherReqNo?: Maybe<Scalars['String']>;
  TravelRequestID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Documents?: Maybe<DocumentsEntity>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<Scalars['JSON']>>;
  SubmitterName?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  StaffMovementNo?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  MovementType?: Maybe<MovementType>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SubordinateDetail?: Maybe<EmployeeEntity>;
  RequestCompanyID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  EmployeeID: Scalars['String'];
  RequisitionHistory?: Maybe<Array<RequisitionEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  MultipleDocuments?: Maybe<Array<DocumentsEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type RequisitionInput = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  RequisitionType: RequisitionType;
  TravelRequestID?: Maybe<Scalars['String']>;
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TravelReqNo?: Maybe<Scalars['String']>;
  AssetReqNo?: Maybe<Scalars['String']>;
  CashReqNo?: Maybe<Scalars['String']>;
  OtherReqNo?: Maybe<Scalars['String']>;
  StaffMovementNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  RequestCompanyID?: Maybe<Scalars['String']>;
};

export type RequisitionManagement = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum RequisitionManagementDelegation {
  AssetRequisitionSubmissionApproval = 'Asset_Requisition_Submission_Approval',
  AssetRequisitionCancellationApproval = 'Asset_Requisition_Cancellation_Approval',
  CashRequisitionSubmissionApproval = 'Cash_Requisition_Submission_Approval',
  CashRequisitionCancellationApproval = 'Cash_Requisition_Cancellation_Approval',
  TravelRequisitionSubmissionApproval = 'Travel_Requisition_Submission_Approval',
  TravelRequisitionCancellationApproval = 'Travel_Requisition_Cancellation_Approval',
  OtherRequisitionSubmissionApproval = 'Other_Requisition_Submission_Approval',
  OtherRequisitionCancellationApproval = 'Other_Requisition_Cancellation_Approval',
  StaffMovementRequisitionSubmissionApproval = 'Staff_Movement_Requisition_Submission_Approval',
  StaffMovementRequisitionCancellationApproval = 'Staff_Movement_Requisition_Cancellation_Approval'
}

export type RequisitionOutput = {
  __typename?: 'RequisitionOutput';
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  EmployeeID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  TravelRequestID?: Maybe<Scalars['String']>;
  TravelReqNo?: Maybe<Scalars['String']>;
  AssetReqNo?: Maybe<Scalars['String']>;
  CashReqNo?: Maybe<Scalars['String']>;
  OtherReqNo?: Maybe<Scalars['String']>;
  StaffMovementNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  DocumentIDs?: Maybe<Array<DocumentsEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  JobPositionID?: Maybe<Scalars['String']>;
  JobPositionName?: Maybe<Scalars['String']>;
  JobGradeName?: Maybe<Scalars['String']>;
  OrgUnitName?: Maybe<Scalars['String']>;
  ReqOrgUnitID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  EmpSubordinateName?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  ShiftGroupName?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  RequestCompanyID?: Maybe<Scalars['String']>;
  RequestCompanyName?: Maybe<Scalars['String']>;
};

export type RequisitionTakenSummaryByTypeOutput = {
  __typename?: 'RequisitionTakenSummaryByTypeOutput';
  RequisitionType?: Maybe<RequisitionType>;
  TotalRequisitionCount?: Maybe<Scalars['Float']>;
  TotalRequisitionAmount?: Maybe<Scalars['Float']>;
  Percentage?: Maybe<Scalars['Float']>;
};

export enum RequisitionType {
  AssetRequisition = 'Asset_Requisition',
  TravelRequest = 'Travel_Request',
  CashAdvance = 'Cash_Advance',
  Others = 'Others',
  StaffMovementRequest = 'Staff_Movement_Request'
}

export type RequisitionTypeEntity = InterfaceBase & {
  __typename?: 'RequisitionTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type RequisitionTypeInput = {
  ID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type RequisitionUpdateDocumentsInput = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<DocumentType>;
  Description?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  IsPrivate?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  ParentID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FolderID?: Maybe<Scalars['String']>;
};

export type RequisitionUpdateInput = {
  RequisitionHeaderID: Scalars['String'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TrxStatus?: Maybe<Scalars['String']>;
};

export type ResBiyearly = {
  __typename?: 'ResBiyearly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResLifetimely = {
  __typename?: 'ResLifetimely';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResMonthly = {
  __typename?: 'ResMonthly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResYearly = {
  __typename?: 'ResYearly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export enum ResidentStatus {
  Resident = 'Resident',
  NonResident = 'Non_Resident',
  ReturningExpertProgram = 'Returning_Expert_Program',
  KnowledgeWorker = 'Knowledge_Worker',
  CSuite = 'C_Suite'
}

export type RestOffDayOutput = {
  __typename?: 'RestOffDayOutput';
  DayOfWeek?: Maybe<Scalars['Float']>;
  Label?: Maybe<Scalars['String']>;
  Selected?: Maybe<Scalars['Boolean']>;
  Disabled?: Maybe<Scalars['Boolean']>;
};

export enum RestOffType {
  RestDay = 'Rest_Day',
  OffDay = 'Off_Day',
  PublicHoliday = 'Public_Holiday'
}

export type ReversePostedLcfeInput = {
  EncashCarryForwardLeaveID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type ReversePostedYecfInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type ReviewConversationLogEntity = InterfaceBase & {
  __typename?: 'ReviewConversationLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeReviewSectionItemID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Comment: Scalars['String'];
  DocumentIDs?: Maybe<Scalars['JSON']>;
  VoiceDocumentID?: Maybe<Scalars['String']>;
  EmployeeReviewSectionItem?: Maybe<EmployeeReviewSectionItemEntity>;
  Employee?: Maybe<EmployeeEntity>;
  CommentBy: Scalars['JSON'];
  Documents?: Maybe<Array<DocumentsEntity>>;
  VoiceDocument?: Maybe<DocumentsEntity>;
};

export type ReviewConversationLogInput = {
  ID?: Maybe<Scalars['String']>;
  EmployeeReviewSectionItemID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Comment: Scalars['String'];
  VoiceInput?: Maybe<DocumentsInput>;
  DocInputs?: Maybe<Array<DocumentsInput>>;
};

export type ReviewExerciseEntity = InterfaceBase & {
  __typename?: 'ReviewExerciseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReviewGroupID?: Maybe<Scalars['String']>;
  EmployeeReview: Array<EmployeeReviewEntity>;
  ReviewExerciseSetup?: Maybe<ReviewExerciseSetupEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ReviewExerciseName: Scalars['String'];
  ReviewGroupName?: Maybe<Scalars['String']>;
};

export type ReviewExerciseInput = {
  ID?: Maybe<Scalars['String']>;
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ReviewGroupID?: Maybe<Scalars['String']>;
  EmployeeReview: Array<EmployeeReviewInput>;
};

export type ReviewExerciseListingOutput = {
  __typename?: 'ReviewExerciseListingOutput';
  ReviewExerciseSetupID: Scalars['String'];
  ReviewExerciseName: Scalars['String'];
  ReviewExercise: Array<ReviewExerciseEntity>;
};

export type ReviewExerciseSetupDetailsEntity = InterfaceBase & {
  __typename?: 'ReviewExerciseSetupDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  ReviewGroupID: Scalars['String'];
  ConfirmDueFrom?: Maybe<Scalars['DateTime']>;
  ConfirmDueTo?: Maybe<Scalars['DateTime']>;
  SuperiorReviewFormTemplateID?: Maybe<Scalars['String']>;
  SuperiorSelfReviewFormTemplateID?: Maybe<Scalars['String']>;
  PeerReviewFormTemplateID?: Maybe<Scalars['String']>;
  SubordinateReviewFormTemplateID?: Maybe<Scalars['String']>;
  ReviewExerciseSetup?: Maybe<ReviewExerciseSetupEntity>;
  ReviewGroupName: Scalars['String'];
  ReviewFormTemplateName: Scalars['JSON'];
};

export type ReviewExerciseSetupDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  ReviewGroupID: Scalars['String'];
  ConfirmDueFrom?: Maybe<Scalars['DateTime']>;
  ConfirmDueTo?: Maybe<Scalars['DateTime']>;
  SuperiorReviewFormTemplateID?: Maybe<Scalars['String']>;
  SuperiorSelfReviewFormTemplateID?: Maybe<Scalars['String']>;
  PeerReviewFormTemplateID?: Maybe<Scalars['String']>;
  SubordinateReviewFormTemplateID?: Maybe<Scalars['String']>;
};

export type ReviewExerciseSetupEntity = InterfaceBase & {
  __typename?: 'ReviewExerciseSetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  ReviewType: ReviewFormTemplateType;
  KpiAsAtDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  GenerateForCurrentReporting?: Maybe<Scalars['Boolean']>;
  UseRecentKpi?: Maybe<Scalars['Boolean']>;
  ExcludeResigning?: Maybe<Scalars['Boolean']>;
  AdhocSetupDetails?: Maybe<Scalars['JSON']>;
  ReviewExerciseSetupDetails: Array<ReviewExerciseSetupDetailsEntity>;
  ReviewExercise: Array<ReviewExerciseEntity>;
  InUse: Scalars['Boolean'];
};

export type ReviewExerciseSetupInput = {
  ID?: Maybe<Scalars['String']>;
  ReviewExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  ReviewType: ReviewFormTemplateType;
  KpiAsAtDate: Scalars['DateTime'];
  AllowToReInitiate: Scalars['Boolean'];
  GenerateForCurrentReporting: Scalars['Boolean'];
  UseRecentKpi: Scalars['Boolean'];
  ExcludeResigning: Scalars['Boolean'];
  AdhocSetupDetails?: Maybe<AdhocSetupDetails>;
  ReviewExerciseSetupDetails: Array<ReviewExerciseSetupDetailsInput>;
};

export type ReviewFormItemSubmitInput = {
  ID: Scalars['String'];
  Weightage: Scalars['Float'];
  ItemWeightage?: Maybe<Scalars['Float']>;
  EmployeeReview?: Maybe<Scalars['String']>;
  ReviewerReview?: Maybe<Scalars['String']>;
};

export type ReviewFormSectionSubmitInput = {
  ID: Scalars['String'];
  Weightage: Scalars['Float'];
  Recommendation?: Maybe<RecommendSectionInput>;
  ReviewFormItem?: Maybe<Array<ReviewFormItemSubmitInput>>;
};

export type ReviewFormSubmitInput = {
  ID: Scalars['String'];
  ReviewDate: Scalars['DateTime'];
  Status: Status;
  EmployeeRating?: Maybe<Scalars['Float']>;
  ReviewerRating?: Maybe<Scalars['Float']>;
  OverallGrade?: Maybe<Scalars['String']>;
  ReviewProcessLog: Array<ReviewProcessLog>;
  ReviewFormSection: Array<ReviewFormSectionSubmitInput>;
};

export type ReviewFormTemplateEntity = InterfaceBase & {
  __typename?: 'ReviewFormTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  WeightageType: QuantitativeType;
  ReviewProcessPolicyID?: Maybe<Scalars['String']>;
  ReviewGradingID: Scalars['String'];
  ReviewType: ReviewFormTemplateType;
  ApplicableTo: Array<AppraiseeType>;
  Description: Scalars['String'];
  ReviewProcessPolicy?: Maybe<ApprovalPolicyEntity>;
  ReviewGradingTable: ReviewGradingTableEntity;
  ReviewSection: Array<ReviewSectionEntity>;
  AllowSectionEdited?: Maybe<Scalars['Boolean']>;
  SectionEditedByEmployee?: Maybe<Scalars['Boolean']>;
  SectionEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowSectionZero?: Maybe<Scalars['Boolean']>;
  InUse: Scalars['Boolean'];
};

export type ReviewFormTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  WeightageType?: Maybe<QuantitativeType>;
  ReviewProcessPolicyID?: Maybe<Scalars['String']>;
  ReviewGradingID?: Maybe<Scalars['String']>;
  ReviewType?: Maybe<ReviewFormTemplateType>;
  ApplicableTo?: Maybe<Array<AppraiseeType>>;
  ReviewSections?: Maybe<Array<ReviewSectionInput>>;
  AllowSectionEdited?: Maybe<Scalars['Boolean']>;
  SectionEditedByEmployee?: Maybe<Scalars['Boolean']>;
  SectionEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowSectionZero?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export enum ReviewFormTemplateType {
  Periodic = 'Periodic',
  Confirmation = 'Confirmation',
  Adhoc = 'Adhoc'
}

export type ReviewGradingTableEntity = InterfaceBase & {
  __typename?: 'ReviewGradingTableEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  GradingTable: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
};

export type ReviewGradingTableInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  GradingTable?: Maybe<Array<GradingTableInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewGroupEntity = InterfaceBase & {
  __typename?: 'ReviewGroupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  KpiExercises?: Maybe<Array<KpiExerciseDetailsEntity>>;
  InUse: Scalars['Boolean'];
};

export type ReviewGroupInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewItemDetail = {
  Title: Scalars['String'];
  Weightage: Scalars['Float'];
  KpiItemWeightage?: Maybe<Scalars['Float']>;
  WeightageType: Scalars['String'];
  Targets?: Maybe<Scalars['String']>;
  Initiatives?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ItemRatingMethod?: Maybe<Scalars['String']>;
  ratingScaleTable?: Maybe<Array<RatingScaleTableInput>>;
  TemplateID: Scalars['String'];
  ReviewItemID?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  KpiCategoryID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  StaffComment?: Maybe<Scalars['Boolean']>;
  SuperiorComment?: Maybe<Scalars['Boolean']>;
  FormItemRatingSetup?: Maybe<FormItemRatingSetup>;
};

export type ReviewItemEntity = InterfaceBase & {
  __typename?: 'ReviewItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Code: Scalars['String'];
  Description: Scalars['String'];
  ItemRatingMethod: ItemRatingMethod;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ApplicableTo: Array<AppraiseeType>;
  FormItemRating?: Maybe<FormItemRatingEntity>;
  StaffComment?: Maybe<Scalars['Boolean']>;
  SuperiorComment?: Maybe<Scalars['Boolean']>;
  InUse: Scalars['Boolean'];
};

export type ReviewItemInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ItemRatingMethod?: Maybe<ItemRatingMethod>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ApplicableTo?: Maybe<Array<AppraiseeType>>;
  StaffComment?: Maybe<Scalars['Boolean']>;
  SuperiorComment?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewItemList = {
  __typename?: 'ReviewItemList';
  ReviewItemID: Scalars['String'];
  Weightage: Scalars['Float'];
  Sequence: Scalars['Float'];
};

export type ReviewItemListInput = {
  ReviewItemID: Scalars['String'];
  Weightage: Scalars['Float'];
  Sequence: Scalars['Float'];
};

export type ReviewPeerRegistrationEntity = InterfaceBase & {
  __typename?: 'ReviewPeerRegistrationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewExerciseSetupID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PeerIDs: Array<Scalars['String']>;
  Employee: EmployeeEntity;
  PeerInfoListing: Array<EmployeeEntity>;
};

export type ReviewPeerRegistrationInput = {
  EmployeeID: Scalars['String'];
  PeerIDs: Array<Scalars['String']>;
};

export type ReviewPolicyDetail = {
  __typename?: 'ReviewPolicyDetail';
  RoleName: AppraiseeType;
  Weightage: Scalars['Float'];
};

export type ReviewPolicyDetailInput = {
  RoleName: AppraiseeType;
  Weightage: Scalars['Float'];
};

export type ReviewPolicyEntity = InterfaceBase & {
  __typename?: 'ReviewPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  ReviewPolicyDetails: Array<Scalars['JSON']>;
  ReviewGradingID: Scalars['String'];
  ReviewGrading: ReviewGradingTableEntity;
  InUse: Scalars['Boolean'];
};

export type ReviewPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  ReviewGradingID?: Maybe<Scalars['String']>;
  ReviewPolicyDetails?: Maybe<Array<ReviewPolicyDetailInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewProcessLog = {
  ApprovalStepNo?: Maybe<Scalars['Float']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeRoleID?: Maybe<Scalars['String']>;
  EmployeeRole?: Maybe<Scalars['String']>;
  Status: WorkflowLogStatus;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Comment?: Maybe<Scalars['String']>;
};

export type ReviewProcessPolicyEntity = InterfaceBase & {
  __typename?: 'ReviewProcessPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  ApprovalList: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
};

export type ReviewProcessPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<ApprovalListInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type ReviewSectionEntity = InterfaceBase & {
  __typename?: 'ReviewSectionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReviewFormTemplateID: Scalars['String'];
  Title: Scalars['String'];
  Type: ReviewSectionType;
  Weightage: Scalars['Float'];
  KpiSource?: Maybe<KpiSource>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ReviewTemplateID?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  AccessControl: Array<Scalars['JSON']>;
  Sequence: Scalars['Float'];
  KpiPredefinedTemplate?: Maybe<KpiPredefinedTemplateEntity>;
  FormItemRating?: Maybe<FormItemRatingEntity>;
  ReviewTemplate?: Maybe<ReviewTemplateEntity>;
  ReviewFormTemplate: ReviewFormTemplateEntity;
  AllowItemEdited?: Maybe<Scalars['Boolean']>;
  ItemEditedByEmployee?: Maybe<Scalars['Boolean']>;
  ItemEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowItemZero?: Maybe<Scalars['Boolean']>;
};

export type ReviewSectionInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  ReviewFormTemplateID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Type?: Maybe<ReviewSectionType>;
  Weightage?: Maybe<Scalars['Float']>;
  KpiSource?: Maybe<KpiSource>;
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
  FormItemRatingID?: Maybe<Scalars['String']>;
  ReviewTemplateID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  AllowItemEdited?: Maybe<Scalars['Boolean']>;
  ItemEditedByEmployee?: Maybe<Scalars['Boolean']>;
  ItemEditedBySuperior?: Maybe<Scalars['Boolean']>;
  AllowItemZero?: Maybe<Scalars['Boolean']>;
  AccessControl?: Maybe<Array<AccessControlInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export enum ReviewSectionType {
  Kpi = 'Kpi',
  Review = 'Review',
  Recommendation = 'Recommendation'
}

export type ReviewTemplateEntity = InterfaceBase & {
  __typename?: 'ReviewTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Description: Scalars['String'];
  ApplicableTo: Array<AppraiseeType>;
  WeightageType: QuantitativeType;
  ReviewItemList: Array<Scalars['JSON']>;
  InUse: Scalars['Boolean'];
  ItemCount: Scalars['Float'];
};

export type ReviewTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  WeightageType?: Maybe<QuantitativeType>;
  ApplicableTo?: Maybe<Array<AppraiseeType>>;
  ReviewItemList?: Maybe<Array<ReviewItemListInput>>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type RewardGroupEntity = InterfaceBase & {
  __typename?: 'RewardGroupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  InUse: Scalars['Boolean'];
};

export type RewardGroupInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy: Scalars['String'];
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm: Array<RolePermissionAssignmentEntity>;
  roleUserAssignments?: Maybe<Array<RoleUserEntAssignmentEntity>>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'RolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type RoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'RoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
  role: RoleEntity;
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoundingCriteria = {
  __typename?: 'RoundingCriteria';
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  RoundedTo?: Maybe<Scalars['Float']>;
};

export type RoundingCriteriaInput = {
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  RoundedTo?: Maybe<Scalars['Float']>;
};

export type RoundingMethodEntity = InterfaceBase & {
  __typename?: 'RoundingMethodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoundingMethodID: Scalars['String'];
  Name: Scalars['String'];
  RoundingType?: Maybe<RoundingType>;
  RoundingList: Scalars['String'];
};

export type RoundingMethodInput = {
  RoundingMethodID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  RoundingType?: Maybe<RoundingType>;
  RoundingList: Scalars['String'];
};

export enum RoundingType {
  Time = 'TIME',
  Unit = 'UNIT'
}

export type SdTrainingCoursesEntity = InterfaceBase & {
  __typename?: 'SDTrainingCoursesEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SDTrainingCoursesID: Scalars['String'];
  StaffDevelopmentProgramID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AppraisalCategoryItem?: Maybe<AppraisalCategoryItemEntity>;
  StaffDevelopmentProgram?: Maybe<StaffDevelopmentProgramEntity>;
};

export type SdTrainingCoursesInput = {
  SDTrainingCoursesID?: Maybe<Scalars['String']>;
  StaffDevelopmentProgramID?: Maybe<Scalars['String']>;
  AppraisalCategoryItemID?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum SalaryType {
  Monthly = 'Monthly',
  Daily = 'Daily'
}

export type ScaleRatingDetailEntity = InterfaceBase & {
  __typename?: 'ScaleRatingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ScaleRatingDetailID: Scalars['String'];
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ScaleRatingHeader?: Maybe<ScaleRatingHeaderEntity>;
};

export type ScaleRatingDetailInput = {
  ScaleRatingDetailID?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ScaleSequence?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ScaleRatingHeaderEntity = InterfaceBase & {
  __typename?: 'ScaleRatingHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ScaleRatingHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  ScaleRatingDetails?: Maybe<Array<ScaleRatingDetailEntity>>;
  AppraisalPolicy?: Maybe<Array<AppraisalPolicyEntity>>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type ScaleRatingHeaderInput = {
  ScaleRatingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Scale?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<ScaleRatingDetailInput>>;
};

export type ScheduleTrainingEntity = InterfaceBase & {
  __typename?: 'ScheduleTrainingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ScheduledTrainingID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TrainingProviderID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  CourseFee?: Maybe<Scalars['Float']>;
  LumpSum?: Maybe<Scalars['Float']>;
  MaxPax?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  PerClass?: Maybe<Scalars['Boolean']>;
  TraineeEval?: Maybe<Scalars['Boolean']>;
  CourseEval?: Maybe<Scalars['Boolean']>;
  TrainerEval?: Maybe<Scalars['Boolean']>;
  SupervisorEval?: Maybe<Scalars['Boolean']>;
  TrainingSchedule?: Maybe<Array<Scalars['JSON']>>;
  QRCode?: Maybe<Scalars['String']>;
  RegisteredTrainee?: Maybe<Array<Scalars['String']>>;
  Registered?: Maybe<Scalars['Boolean']>;
  Document?: Maybe<DocumentsEntity>;
  QRCodeDocument?: Maybe<DocumentsEntity>;
  GetTrainingCostItemTotal?: Maybe<Scalars['Int']>;
};

export type ScheduleTrainingInput = {
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
  CourseName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TrainingProviderID?: Maybe<Scalars['String']>;
  Venue?: Maybe<Scalars['String']>;
  CourseFee?: Maybe<Scalars['Float']>;
  LumpSum?: Maybe<Scalars['Float']>;
  MaxPax?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  PerClass?: Maybe<Scalars['Boolean']>;
  TraineeEval?: Maybe<Scalars['Boolean']>;
  CourseEval?: Maybe<Scalars['Boolean']>;
  TrainerEval?: Maybe<Scalars['Boolean']>;
  SupervisorEval?: Maybe<Scalars['Boolean']>;
  TrainingSchedule?: Maybe<Array<Scalars['JSON']>>;
  QRCode?: Maybe<Scalars['String']>;
  RegisteredTrainee?: Maybe<Array<Scalars['String']>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ScheduledTrainingEmployeeEntity = NewInterfaceBase1 & {
  __typename?: 'ScheduledTrainingEmployeeEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  company_id: Scalars['String'];
  employee_id: Scalars['String'];
  participation_status?: Maybe<Participation_Status>;
  queue_number?: Maybe<Scalars['Float']>;
  total_hours?: Maybe<Scalars['Float']>;
  finalized_attendance?: Maybe<Scalars['Boolean']>;
  is_charged?: Maybe<Scalars['Boolean']>;
  withdraw_remarks?: Maybe<Scalars['String']>;
  voice_document_id?: Maybe<Scalars['String']>;
  registration_method?: Maybe<Registration_Method>;
  registered_by?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  TrainingAttendance?: Maybe<Array<NewTrainingAttendanceEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  TrainingHistory?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  course_rating?: Maybe<Scalars['Float']>;
  provider_rating?: Maybe<Scalars['Float']>;
  trainer_rating?: Maybe<Scalars['Float']>;
};

export type ScheduledTrainingEmployeeEvaluationEntity = NewInterfaceBase1 & {
  __typename?: 'ScheduledTrainingEmployeeEvaluationEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  employee_id: Scalars['String'];
  scheduled_training_survey_id: Scalars['String'];
  course_rating?: Maybe<Scalars['Float']>;
  provider_rating?: Maybe<Scalars['Float']>;
  trainer_rating?: Maybe<Scalars['Float']>;
  survey_answer?: Maybe<Array<Scalars['JSON']>>;
  process_status: Process_Status;
  process_status_log: Array<Scalars['JSON']>;
  document_id?: Maybe<Array<Scalars['JSON']>>;
  remarks?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  ScheduledTrainingSurvey?: Maybe<ScheduledTrainingSurveyEntity>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  EmployeeEvaluationAnswer?: Maybe<Array<EmployeeEvaluationAnswerEntity>>;
  documents?: Maybe<Array<DocumentsEntity>>;
};

export type ScheduledTrainingEntity = NewInterfaceBase1 & {
  __typename?: 'ScheduledTrainingEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  training_event_name: Scalars['String'];
  training_course_library_id: Scalars['String'];
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  training_provider_id: Scalars['String'];
  cost_method: Cost_Method;
  course_fee: Scalars['Float'];
  min_pax: Scalars['Float'];
  max_pax: Scalars['Float'];
  is_allow_registration: Scalars['Boolean'];
  open_registration_dt?: Maybe<Scalars['String']>;
  close_registration_dt?: Maybe<Scalars['String']>;
  remarks: Scalars['String'];
  event_status: Event_Status;
  event_status_log: Array<Scalars['JSON']>;
  email_template?: Maybe<Scalars['JSON']>;
  document_id: Scalars['String'];
  document?: Maybe<DocumentsEntity>;
  course_rating?: Maybe<Scalars['Float']>;
  provider_rating?: Maybe<Scalars['Float']>;
  trainer_rating?: Maybe<Scalars['Float']>;
  TrainingCourseLibrary?: Maybe<TrainingCourseLibraryEntity>;
  TrainingProvider?: Maybe<TrainingProviderEntity>;
  ScheduledTrainingTimetable?: Maybe<Array<ScheduledTrainingTimetableEntity>>;
  ScheduledTrainingSurvey?: Maybe<Array<ScheduledTrainingSurveyEntity>>;
  ScheduledTrainingTarget?: Maybe<Array<ScheduledTrainingTargetEntity>>;
  EmployeeScheduledTraining?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  ScheduledTrainingExpense?: Maybe<Array<ScheduledTrainingExpenseEntity>>;
  ScheduledTrainingEmployeeEvaluation?: Maybe<Array<ScheduledTrainingEmployeeEvaluationEntity>>;
  ScheduledTrainingVenue?: Maybe<Array<ScheduledTrainingVenueEntity>>;
};

export type ScheduledTrainingExpenseEntity = NewInterfaceBase1 & {
  __typename?: 'ScheduledTrainingExpenseEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  training_expense_id: Scalars['String'];
  amount: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  TrainingExpense?: Maybe<TrainingExpenseEntity>;
};

export type ScheduledTrainingExpenseInput = {
  scheduled_training_expense_id?: Maybe<Scalars['String']>;
  training_expense_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
};

export type ScheduledTrainingInput = {
  scheduled_training_id?: Maybe<Scalars['String']>;
  subscription_account_id?: Maybe<Scalars['String']>;
  training_event_name?: Maybe<Scalars['String']>;
  training_course_library_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  training_provider_id?: Maybe<Scalars['String']>;
  cost_method?: Maybe<Cost_Method>;
  course_fee?: Maybe<Scalars['Float']>;
  min_pax?: Maybe<Scalars['Float']>;
  max_pax?: Maybe<Scalars['Float']>;
  is_allow_registration?: Maybe<Scalars['Boolean']>;
  open_registration_dt?: Maybe<Scalars['String']>;
  close_registration_dt?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  event_status?: Maybe<Event_Status>;
  attachment?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
  course_rating?: Maybe<Scalars['Float']>;
  provider_rating?: Maybe<Scalars['Float']>;
  trainer_rating?: Maybe<Scalars['Float']>;
  schedule_items?: Maybe<Array<ScheduledTrainingTimetableInput>>;
  target_group_items?: Maybe<Array<ScheduledTrainingTargetInput>>;
  survey_items?: Maybe<Array<ScheduledTrainingSurveyInput>>;
  AdminUserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AdminContactID?: Maybe<Scalars['String']>;
  AdminEmployeeID?: Maybe<Scalars['String']>;
  AdminEmployeeName?: Maybe<Scalars['String']>;
  AdminEmployeeNo?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type ScheduledTrainingSurveyEntity = NewInterfaceBase1 & {
  __typename?: 'ScheduledTrainingSurveyEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  survey_type_id: Scalars['String'];
  survey_form_id: Scalars['String'];
  SurveyForm?: Maybe<SurveyFormEntity>;
  SurveyType?: Maybe<SurveyTypeEntity>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  ScheduledTrainingEmployeeEvaluation?: Maybe<Array<ScheduledTrainingEmployeeEvaluationEntity>>;
};

export type ScheduledTrainingSurveyInput = {
  scheduled_training_survey_id?: Maybe<Scalars['String']>;
  survey_type_id?: Maybe<Scalars['String']>;
  survey_form_id?: Maybe<Scalars['String']>;
};

export type ScheduledTrainingTargetEntity = NewInterfaceBase1 & {
  __typename?: 'ScheduledTrainingTargetEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  target_group_id: Scalars['String'];
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
};

export type ScheduledTrainingTargetInput = {
  scheduled_training_target_id?: Maybe<Scalars['String']>;
  target_group_id?: Maybe<Scalars['String']>;
};

export type ScheduledTrainingTimetableEntity = NewInterfaceBase1 & {
  __typename?: 'ScheduledTrainingTimetableEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  trainer_profile_id: Scalars['String'];
  date: Scalars['String'];
  start_time: Scalars['String'];
  end_time: Scalars['String'];
  venue: Scalars['String'];
  hours?: Maybe<Scalars['Float']>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
  TrainerProfile?: Maybe<TrainerProfileEntity>;
  TotalAttended: Scalars['Float'];
};

export type ScheduledTrainingTimetableInput = {
  scheduled_training_timetable_id?: Maybe<Scalars['String']>;
  trainer_profile_id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
};

export type ScheduledTrainingVenueEntity = NewInterfaceBase1 & {
  __typename?: 'ScheduledTrainingVenueEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  scheduled_training_id: Scalars['String'];
  venue_name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
  ScheduledTraining?: Maybe<ScheduledTrainingEntity>;
};

export type ScheduledTrainingVenueInput = {
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  userlogs?: Maybe<UserLogInput>;
  scheduled_training_venue_id?: Maybe<Scalars['String']>;
  venue_name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  radius?: Maybe<Scalars['Float']>;
};

export type SendLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  OfferChecklistName?: Maybe<Array<Scalars['JSON']>>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};

export enum Sequence {
  FirstDeduction = 'FIRST_DEDUCTION',
  SecondDeduction = 'SECOND_DEDUCTION',
  ThirdDeduction = 'THIRD_DEDUCTION'
}

export enum SequenceOrderType {
  Asc = 'Asc',
  Desc = 'Desc'
}

export enum SessionType {
  Full = 'Full',
  FirstHalf = 'First_Half',
  SecondHalf = 'Second_Half'
}

export type ShiftDayEntity = InterfaceBase & {
  __typename?: 'ShiftDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  DayType: DayType;
  ClockPairing?: Maybe<ClockPairingType>;
  CrossDay: CrossDayType;
  FlexiHour?: Maybe<Scalars['Boolean']>;
  TotalWorkingHour?: Maybe<Scalars['Float']>;
  ClockInTime?: Maybe<Scalars['String']>;
  ClockOutTime?: Maybe<Scalars['String']>;
  ShiftStartTime?: Maybe<Scalars['String']>;
  ShiftEndTime?: Maybe<Scalars['String']>;
  FirstHalfEndTime?: Maybe<Scalars['String']>;
  SecondHalfStartTime?: Maybe<Scalars['String']>;
  CoreHourStartTime?: Maybe<Scalars['String']>;
  CoreHourEndTime?: Maybe<Scalars['String']>;
  FlexiBreak: Scalars['Boolean'];
  ShiftBreakInterval?: Maybe<Scalars['Float']>;
  IsPreOvertime: Scalars['Boolean'];
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  IsPostOvertime: Scalars['Boolean'];
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  IsFixedOvertime: Scalars['Boolean'];
  FixedOvertimeStart?: Maybe<Scalars['String']>;
  FixedOvertimeEnd?: Maybe<Scalars['String']>;
  FixedOvertimeTotal?: Maybe<Scalars['Float']>;
  displaycolor?: Maybe<Scalars['String']>;
  PreOTcondition?: Maybe<Array<Scalars['JSON']>>;
  PostOTcondition?: Maybe<Array<Scalars['JSON']>>;
  shift?: Maybe<ShiftEntity>;
  ShiftID?: Maybe<Scalars['String']>;
  breakTimeList?: Maybe<Array<BreakTimeEntity>>;
};

export type ShiftDayInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ClockPairing?: Maybe<ClockPairingType>;
  CrossDay?: Maybe<CrossDayType>;
  DayType?: Maybe<DayType>;
  FlexiHour?: Maybe<Scalars['Boolean']>;
  TotalWorkingHour?: Maybe<Scalars['Float']>;
  ClockInTime?: Maybe<Scalars['String']>;
  ClockOutTime?: Maybe<Scalars['String']>;
  ShiftStartTime?: Maybe<Scalars['String']>;
  ShiftEndTime?: Maybe<Scalars['String']>;
  FirstHalfEndTime?: Maybe<Scalars['String']>;
  SecondHalfStartTime?: Maybe<Scalars['String']>;
  CoreHourStartTime?: Maybe<Scalars['String']>;
  CoreHourEndTime?: Maybe<Scalars['String']>;
  FlexiBreak?: Maybe<Scalars['Boolean']>;
  ShiftBreakInterval?: Maybe<Scalars['Float']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  BreakTimeList?: Maybe<Array<BreakTimeListInput>>;
  IsPreOvertime?: Maybe<Scalars['Boolean']>;
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  IsPostOvertime?: Maybe<Scalars['Boolean']>;
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  IsFixedOvertime?: Maybe<Scalars['Boolean']>;
  FixedOvertimeStart?: Maybe<Scalars['String']>;
  FixedOvertimeEnd?: Maybe<Scalars['String']>;
  FixedOvertimeTotal?: Maybe<Scalars['Float']>;
  ShiftID?: Maybe<Scalars['String']>;
  displaycolor?: Maybe<Scalars['String']>;
  PreOTcondition?: Maybe<Array<Scalars['JSON']>>;
  PostOTcondition?: Maybe<Array<Scalars['JSON']>>;
};

export type ShiftEntity = InterfaceBase & {
  __typename?: 'ShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  DutyRosterDisplayColour: Scalars['String'];
  IsPreOvertimeMin: Scalars['Boolean'];
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  IsPostOvertimeMin: Scalars['Boolean'];
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  IsFixedOvertimeMin: Scalars['Boolean'];
  FixedOvertimeTotal?: Maybe<Scalars['Float']>;
  BatchOvertime?: Maybe<Array<OvertimeEntity>>;
  fromShiftList?: Maybe<Array<ChangeShiftEntity>>;
  toShiftList?: Maybe<Array<ChangeShiftEntity>>;
  breakTimeList?: Maybe<Array<BreakTimeEntity>>;
  shiftDayList?: Maybe<Array<ShiftDayEntity>>;
  shiftPatternDetailList?: Maybe<Array<ShiftPatternDetailEntity>>;
  NewStructure?: Maybe<Scalars['Boolean']>;
};

export type ShiftGroupEmployeeAssignmentLogEntity = InterfaceBase & {
  __typename?: 'ShiftGroupEmployeeAssignmentLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupEmployeeAssignmentLogID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
  ShiftGroupOBJ: ShiftGroupEntity;
  hasDutyRoster: Scalars['Boolean'];
};

export type ShiftGroupEmployeeAssignmentLogInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CompanyID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ShiftGroupEntity = InterfaceBase & {
  __typename?: 'ShiftGroupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  DefaultShiftGroup?: Maybe<Scalars['Boolean']>;
  ClockingOption?: Maybe<Scalars['String']>;
  MultipleInOutOption?: Maybe<Scalars['String']>;
  ShiftGroupShift: Array<ShiftGroupShiftEntity>;
  ShiftGroupEmployeeAssignmentLog?: Maybe<Array<ShiftGroupEmployeeAssignmentLogEntity>>;
  ShiftGroupPattern?: Maybe<Array<ShiftGroupPatternEntity>>;
  ByQR?: Maybe<Scalars['Boolean']>;
  QRSelfie?: Maybe<Scalars['Boolean']>;
  ByLocation?: Maybe<Scalars['Boolean']>;
  LocationSelfie?: Maybe<Scalars['Boolean']>;
  ByBluetooth?: Maybe<Scalars['Boolean']>;
  BluetoothSelfie?: Maybe<Scalars['Boolean']>;
  Shift?: Maybe<Array<ShiftEntity>>;
  ShiftCount: Scalars['Float'];
  Employee?: Maybe<Array<EmployeeEntity>>;
  EmployeeCount: Scalars['Float'];
};

export type ShiftGroupInput = {
  ShiftGroupID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  DefaultShiftGroup?: Maybe<Scalars['Boolean']>;
  ClockingOption?: Maybe<Scalars['String']>;
  MultipleInOutOption?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ByQR?: Maybe<Scalars['Boolean']>;
  QRSelfie?: Maybe<Scalars['Boolean']>;
  ByLocation?: Maybe<Scalars['Boolean']>;
  LocationSelfie?: Maybe<Scalars['Boolean']>;
  ByBluetooth?: Maybe<Scalars['Boolean']>;
  BluetoothSelfie?: Maybe<Scalars['Boolean']>;
};

export type ShiftGroupPatternEntity = InterfaceBase & {
  __typename?: 'ShiftGroupPatternEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupPatternID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  ShiftPattern?: Maybe<ShiftPatternEntity>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
};

export type ShiftGroupPatternInput = {
  ShiftGroupPatternID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
};

export type ShiftGroupShiftEntity = InterfaceBase & {
  __typename?: 'ShiftGroupShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
  ShiftID: Scalars['String'];
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftGroup: ShiftGroupEntity;
};

export type ShiftGroupShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  DutyRosterDisplayColour: Scalars['String'];
  ShiftID?: Maybe<Scalars['String']>;
  NewStructure?: Maybe<Scalars['Boolean']>;
};

export type ShiftPatternDetail = {
  __typename?: 'ShiftPatternDetail';
  days?: Maybe<Scalars['String']>;
  ShiftDay?: Maybe<ShiftDayEntity>;
};

export type ShiftPatternDetailEntity = {
  __typename?: 'ShiftPatternDetailEntity';
  ShiftPatternDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Days?: Maybe<Scalars['Float']>;
  shiftList?: Maybe<Array<ShiftPatternEntity>>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  shiftType?: Maybe<Array<ShiftEntity>>;
  ShiftTypeID?: Maybe<Scalars['String']>;
  shiftDay?: Maybe<ShiftDayEntity>;
  ShiftDayID?: Maybe<Scalars['String']>;
};

export type ShiftPatternDetailInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  ShiftTypeID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  Days?: Maybe<Scalars['Float']>;
};

export type ShiftPatternEntity = InterfaceBase & {
  __typename?: 'ShiftPatternEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Active?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['DateTime']>;
  End?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<ShiftPatternDetailEntity>>;
};

export type ShiftPatternInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  Name?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  End?: Maybe<Scalars['DateTime']>;
};

export type ShopeePayItemEntity = {
  __typename?: 'ShopeePayItemEntity';
  ShopeePayItemID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ShortNoticeType {
  CalendarDay = 'Calendar_Day',
  WorkingDay = 'Working_Day'
}

export type SkillSetDetailEntity = InterfaceBase & {
  __typename?: 'SkillSetDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SkillSetDetailID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  SkillSet?: Maybe<SkillSetEntity>;
  SkillSetID?: Maybe<Scalars['String']>;
  JobDesignationSkillSets?: Maybe<Array<JobDesignationSkillSetEntity>>;
};

export type SkillSetDetailInput = {
  SkillSetDetailID?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SkillSetEntity = InterfaceBase & {
  __typename?: 'SkillSetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SkillSetID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SkillSetDetails?: Maybe<Array<SkillSetDetailEntity>>;
  JobDesignationSkillSets?: Maybe<Array<JobDesignationSkillSetEntity>>;
};

export type SkillSetInput = {
  SkillSetID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SocsoCalculationInput = {
  EmployeeID: Scalars['String'];
  Age: Scalars['Float'];
  NationalityType?: Maybe<NationalityType>;
  SocsoChargableAmount: Scalars['Float'];
  CurrentPeriod?: Maybe<Scalars['String']>;
};

export type SocsoCalculationOutput = {
  __typename?: 'SocsoCalculationOutput';
  EmployeeID: Scalars['String'];
  SocsoAmount: Scalars['Float'];
  FirstCatCmpAmount?: Maybe<Scalars['Float']>;
  FirstCatEmpAmount?: Maybe<Scalars['Float']>;
  SecondCatCmpAmount?: Maybe<Scalars['Float']>;
  FromAmount?: Maybe<Scalars['Float']>;
  ToAmount?: Maybe<Scalars['Float']>;
  SocsoRule?: Maybe<SocsoRule>;
  SocsoRequirement?: Maybe<Scalars['String']>;
};

export type SocsoPolicyEntity = InterfaceBase & {
  __typename?: 'SocsoPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SocsoPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType: Scalars['String'];
  Age: Scalars['Float'];
  SocsoRule: Scalars['String'];
  Formula: Scalars['String'];
};

export type SocsoPolicyInput = {
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<Scalars['String']>;
  Age: Scalars['Float'];
  SocsoRule?: Maybe<SocsoRule>;
  Formula?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SocsoRefEntity = InterfaceBase & {
  __typename?: 'SocsoRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SocsoReflD: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  FirstCatCmpAmount: Scalars['Float'];
  FirstCatEmpAmount: Scalars['Float'];
  SecondCatEmpAmount: Scalars['Float'];
};

export type SocsoRefInput = {
  SocsoRefID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  FirstCatCmpAmount: Scalars['Float'];
  FirstCatEmpAmount: Scalars['Float'];
  SecondCatEmpAmount: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum SocsoRule {
  FirstCategory = 'FirstCategory',
  SecondCategory = 'SecondCategory'
}

export enum SoftDelete {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION'
}

export type StaffDevelopmentProgramEntity = InterfaceBase & {
  __typename?: 'StaffDevelopmentProgramEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StaffDevelopmentProgramID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  ProgramName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  TargetDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type StaffDevelopmentProgramInput = {
  StaffDevelopmentProgramID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ProgramName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  TargetDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type StaffDueForRetirementOutput = {
  __typename?: 'StaffDueForRetirementOutput';
  Employee?: Maybe<EmployeeEntity>;
  RetirementDate?: Maybe<Scalars['DateTime']>;
  IsRetirementOverdue?: Maybe<Scalars['Boolean']>;
};

export type StaffMovementRequestEntity = InterfaceBase & {
  __typename?: 'StaffMovementRequestEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type StaffMovementRequestInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Array<Scalars['String']>>;
};

export type StaffPerformanceAnalysisSummaryEntity = InterfaceBase & {
  __typename?: 'StaffPerformanceAnalysisSummaryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StaffPerformanceAnalysisSummaryID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  RatingAdjustment?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  QuaterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type StaffPerformanceAnalysisSummaryInput = {
  StaffPerformanceAnalysisSummaryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  RatingAdjustment?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  QuaterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type StaffPerformanceAnalysisSummaryOutput = {
  __typename?: 'StaffPerformanceAnalysisSummaryOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeSalary?: Maybe<Scalars['Float']>;
  FinalRating?: Maybe<Scalars['String']>;
  FinalScore?: Maybe<Scalars['Float']>;
  EmployeeDetails?: Maybe<EmployeeEntity>;
  RatingAdjustment?: Maybe<StaffPerformanceAnalysisSummaryEntity>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
};

export type StaffTimesheetDetailEntity = InterfaceBase & {
  __typename?: 'StaffTimesheetDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  StaffTimesheetID?: Maybe<Scalars['String']>;
  Hour?: Maybe<Scalars['Float']>;
  Activity?: Maybe<Scalars['String']>;
  Other?: Maybe<Scalars['String']>;
  StaffTimesheet: StaffTimesheetEntity;
};

export type StaffTimesheetDetailInput = {
  Description?: Maybe<Scalars['String']>;
  Hour?: Maybe<Scalars['Float']>;
  Other?: Maybe<Scalars['String']>;
};

export type StaffTimesheetEntity = InterfaceBase & {
  __typename?: 'StaffTimesheetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  TsAccountCodeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  TsAccountType: TsAccountTypeEntity;
  TsAccountCode: TsAccountCodeEntity;
  StaffTimesheetDetail: Array<StaffTimesheetDetailEntity>;
};

export type StaffTimesheetInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  TsAccountCodeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StaffTimesheetDetail?: Maybe<Array<StaffTimesheetDetailInput>>;
};

export type StatesEntity = InterfaceBase & {
  __typename?: 'StatesEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StatesID: Scalars['String'];
  Country?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CalendarOffDay?: Maybe<Array<CalendarOffDayEntity>>;
  RecurringRestOffDays?: Maybe<Array<RecurringRestOffDayEntity>>;
};

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive',
  Confirmed = 'Confirmed',
  Probation = 'Probation',
  Resigned = 'Resigned',
  Terminated = 'Terminated',
  Deceased = 'Deceased',
  Retired = 'Retired',
  Illness = 'Illness',
  Absconded = 'Absconded',
  Draft = 'Draft',
  SoftDelete = 'SoftDelete',
  Hidden = 'Hidden',
  InUse = 'InUse',
  NotInUse = 'NotInUse',
  Initiated = 'Initiated',
  Submitted = 'Submitted',
  Finalised = 'Finalised',
  Open = 'Open',
  Closed = 'Closed',
  Withdraw = 'Withdraw',
  Overdue = 'Overdue'
}

export type StatutoryAccount = {
  __typename?: 'StatutoryAccount';
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type StatutoryAccountInput = {
  AccountNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type StatutoryAdjustmentEntity = InterfaceBase & {
  __typename?: 'StatutoryAdjustmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StatutoryAdjustmentID: Scalars['String'];
  PayrollHeaderID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  NetPay?: Maybe<Scalars['String']>;
  TotalGross: Scalars['String'];
  BasicSalary: Scalars['String'];
  TotalDeduction: Scalars['String'];
  EPFPaidEE: Scalars['String'];
  EPFVEPaidEE: Scalars['String'];
  SOCSOPaidEE: Scalars['String'];
  EISPaidEE: Scalars['String'];
  TAXPaidEE: Scalars['String'];
  EPFPaidER: Scalars['String'];
  EPFVEPaidER: Scalars['String'];
  SOCSOPaidER: Scalars['String'];
  EISPaidER: Scalars['String'];
  HRDFPaidER: Scalars['String'];
  TotalChargableTax: Scalars['String'];
  PayrollHeader: PayrollHeaderEntity;
  PayrollCycle: PayrollCycleEntity;
  Employee: EmployeeEntity;
};

export type StatutoryAdjustmentInput = {
  PayrollHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  NetPay?: Maybe<Scalars['String']>;
  TotalGross?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['String']>;
  TotalDeduction?: Maybe<Scalars['String']>;
  EPFPaidEE?: Maybe<Scalars['String']>;
  EPFVEPaidEE?: Maybe<Scalars['String']>;
  SOCSOPaidEE?: Maybe<Scalars['String']>;
  EISPaidEE?: Maybe<Scalars['String']>;
  TAXPaidEE?: Maybe<Scalars['String']>;
  EPFPaidER?: Maybe<Scalars['String']>;
  EPFVEPaidER?: Maybe<Scalars['String']>;
  SOCSOPaidER?: Maybe<Scalars['String']>;
  EISPaidER?: Maybe<Scalars['String']>;
  HRDFPaidER?: Maybe<Scalars['String']>;
  TotalChargableTax?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type SubmitClaimRecordsInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  ExpenseAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  ClaimRecordsItems?: Maybe<Array<ClaimRecordsItemInput>>;
  IsRejected?: Maybe<Scalars['Boolean']>;
};

export type SubmitProjectClaimRecordsInput = {
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  ProjectID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  ProjectClaimRecordsItems?: Maybe<Array<ProjectExpenseClaimRecordsItemInput>>;
  IsRejected?: Maybe<Scalars['Boolean']>;
};

export type SubordinateKpiEmpOutput = InterfaceBase & {
  __typename?: 'SubordinateKpiEmpOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  ShopeeEmployeeID?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  CostCenterID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<EmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  AttendanceRecordPaired?: Maybe<Array<AttendanceRecordPairedEntity>>;
  TargetEmployees?: Maybe<Array<DelegationEntity>>;
  SubmitterEmployees?: Maybe<Array<DelegationEntity>>;
  Subordinate?: Maybe<Array<EmployeeEntity>>;
  SubordinateDetail?: Maybe<Array<RequisitionEntity>>;
  EmployeeContract?: Maybe<Array<ContractInfoEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<EmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  KpiExercise?: Maybe<Array<KpiExerciseEntity>>;
  IsInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  IsFromInterCompanyTransfer?: Maybe<Scalars['Boolean']>;
  InterCompanyTransferDate?: Maybe<Scalars['DateTime']>;
  HelpdeskTicket?: Maybe<Array<HelpdeskTicketEntity>>;
  ProjectTimesheetApproverAssignment?: Maybe<ProjectTimesheetApproverAssignmentEntity>;
  isDailyEntryFilled?: Maybe<Scalars['Boolean']>;
  BatchOvertimes?: Maybe<Array<BatchOvertimeEntity>>;
  TrainingRequest?: Maybe<Array<EmployeeTrainingRequestEntity>>;
  IsProbationExtended?: Maybe<Scalars['Boolean']>;
  EmployeeCurrentSalary: Scalars['String'];
  DesignationWithTemplete?: Maybe<JobDesignationEntity>;
  EmployeeDepartment?: Maybe<DepartmentEntity>;
  GeneralDocCount?: Maybe<Scalars['Float']>;
  GeneralDocTotalSize?: Maybe<Scalars['Float']>;
  PersonalDocCount: Scalars['Float'];
  PersonalDocTotalSize: Scalars['Float'];
  ContractInfoDocCount: Scalars['Float'];
  ContractInfoDocTotalSize: Scalars['Float'];
  PaySlipDocCount: Scalars['Float'];
  PaySlipDocTotalSize: Scalars['Float'];
  EAFormDocCount: Scalars['Float'];
  EAFormDocTotalSize: Scalars['Float'];
  TotalLeave: Scalars['Float'];
  TotalAnnualLeave: Scalars['Float'];
  TotalSickLeave: Scalars['Float'];
  TotalUnpaidLeave: Scalars['Float'];
  TotalClaim: Scalars['Float'];
  TotalClaimApproved: Scalars['Float'];
  TotalClaimAmountApproved: Scalars['Float'];
  TotalTaxRelief: Scalars['Float'];
  TotalRequisition: Scalars['Float'];
  TotalTravelRequest: Scalars['Float'];
  TotalPurchaseRequisition: Scalars['Float'];
  TotalCashAdvanceRequisition: Scalars['Float'];
  TotalOtherRequisition: Scalars['Float'];
  TotalUnreadNews: Scalars['Float'];
  LastUpdatedDates: LastUpdatedDatesOutput;
  MyTodo: Scalars['Float'];
  MyNotification: Scalars['Float'];
  EmployeeSubordinates: Array<EmployeeEntity>;
  TotalEducation: Scalars['Float'];
  TotalJobHistory: Scalars['Float'];
  TotalCertificate: Scalars['Float'];
  TotalPersonalDocument: Scalars['Float'];
  ShiftGroupEmployeeAssignmentLogList: Array<ShiftGroupEmployeeAssignmentLogEntity>;
  TotalKpiRecord: Scalars['Float'];
  TotalReviewRecord: Scalars['Float'];
  EmpOrgUnitDesc: Scalars['String'];
  KpiPendingActionCount: Scalars['Float'];
};

export type Subscription = {
  __typename?: 'Subscription';
  newNotifications: NotificationEntity;
};


export type SubscriptionNewNotificationsArgs = {
  ToEmployeeID: Scalars['String'];
};

export type SuccessionPlanningEntity = InterfaceBase & {
  __typename?: 'SuccessionPlanningEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SuccessionPlanningID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type SuccessionPlanningInput = {
  SuccessionPlanningID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SuccessionPlanningPositionEntity = InterfaceBase & {
  __typename?: 'SuccessionPlanningPositionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SuccessionPlanningPositionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type SuccessionPlanningPositionInput = {
  SuccessionPlanningPositionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobPositionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SupervisorEvalEntity = InterfaceBase & {
  __typename?: 'SupervisorEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SupervisorEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type SupervisorEvalInput = {
  SupervisorEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SupervisorEvaluationListingOutput = {
  __typename?: 'SupervisorEvaluationListingOutput';
  ScheduleTraining?: Maybe<ScheduleTrainingEntity>;
  Rate?: Maybe<Scalars['Float']>;
};

export type SurveyAnswerInput = {
  survey_item_id?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
};

export type SurveyFormDetailEntity = NewInterfaceBase1 & {
  __typename?: 'SurveyFormDetailEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  survey_form_id: Scalars['String'];
  section_name: Scalars['String'];
  header: Scalars['String'];
  course_evaluation?: Maybe<Scalars['Boolean']>;
  provider_evaluation?: Maybe<Scalars['Boolean']>;
  trainer_evaluation?: Maybe<Scalars['Boolean']>;
  survey_items: Array<Scalars['JSON']>;
  sequence: Scalars['Float'];
  survey_form?: Maybe<SurveyFormEntity>;
};

export type SurveyFormDetailInput = {
  survey_form_detail_id?: Maybe<Scalars['String']>;
  section_name?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  course_evaluation?: Maybe<Scalars['Boolean']>;
  provider_evaluation?: Maybe<Scalars['Boolean']>;
  trainer_evaluation?: Maybe<Scalars['Boolean']>;
  survey_items?: Maybe<Array<Scalars['JSON']>>;
  sequence?: Maybe<Scalars['Float']>;
};

export type SurveyFormEntity = NewInterfaceBase1 & {
  __typename?: 'SurveyFormEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  purpose: Scalars['String'];
  valid_for: Valid_For;
  in_use?: Maybe<Scalars['Boolean']>;
  survey_section?: Maybe<Array<SurveyFormDetailEntity>>;
};

export type SurveyFormInput = {
  survey_form_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  valid_for?: Maybe<Valid_For>;
  survey_section?: Maybe<Array<SurveyFormDetailInput>>;
};

export type SurveyItemEntity = NewInterfaceBase1 & {
  __typename?: 'SurveyItemEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  display_label: Scalars['String'];
  code: Scalars['String'];
  type: Survey_Type;
  layout_format?: Maybe<Layout_Format>;
  require_remark: Scalars['Boolean'];
  remark_label?: Maybe<Scalars['String']>;
  possible_answer?: Maybe<Scalars['JSON']>;
  in_use?: Maybe<Scalars['Boolean']>;
};

export type SurveyItemInput = {
  survey_item_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  display_label?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  type?: Maybe<Survey_Type>;
  layout_format?: Maybe<Layout_Format>;
  require_remark?: Maybe<Scalars['Boolean']>;
  remark_label?: Maybe<Scalars['String']>;
  possible_answer?: Maybe<Array<PossibleAnswerInput>>;
};

export type SurveyTypeEntity = NewInterfaceBase1 & {
  __typename?: 'SurveyTypeEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  valid_for: Valid_For;
  description: Scalars['String'];
  in_use: Scalars['Boolean'];
};

export type SurveyTypeInput = {
  survey_type_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  valid_for?: Maybe<Valid_For>;
  description?: Maybe<Scalars['String']>;
};

export type SystemAdminSummaryOutput = {
  __typename?: 'SystemAdminSummaryOutput';
  PublicHolidayCalendarLastPostedDate?: Maybe<Scalars['DateTime']>;
  TaxReliefLastPostedDate?: Maybe<Scalars['DateTime']>;
  EAPartFLastPostedDate?: Maybe<Scalars['DateTime']>;
  EPFPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  SocsoPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  EISPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  LatestMobileVersion?: Maybe<Scalars['String']>;
};

export type TaxzakatCalculationInput = {
  PayrollCycleIsZakat: Scalars['Boolean'];
  IsZakat: Scalars['Boolean'];
  ZakatRate: Scalars['Float'];
  PayrollCycleIsMTD: Scalars['Boolean'];
  IsLastProcess: Scalars['Boolean'];
  CurrentMTD: Scalars['Float'];
  AccumulatedTAXPaid: Scalars['Float'];
  AccumulatedZAKATPaid: Scalars['Float'];
  AccumulatedTax: Scalars['Float'];
};

export type TmsotConditionEntity = InterfaceBase & {
  __typename?: 'TMSOTConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
};

export type TmsotConditionInput = {
  PayItemConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TmsSummary = {
  __typename?: 'TMSSummary';
  ShiftGroup?: Maybe<Scalars['Float']>;
  AttendanceExclusion?: Maybe<Scalars['Float']>;
  EarnLeave?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  Overtime?: Maybe<Scalars['Float']>;
  ChangeShift?: Maybe<Scalars['Float']>;
  AttendanceRecord?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  Absent?: Maybe<Scalars['Float']>;
  IncompleteClocking?: Maybe<Scalars['Float']>;
  DutyRosterDate?: Maybe<Scalars['DateTime']>;
  AttendanceProcessing?: Maybe<Scalars['DateTime']>;
  AttendancePosting?: Maybe<Scalars['DateTime']>;
  IncompleteClockingEmployees?: Maybe<Array<Scalars['JSON']>>;
  LateInEmployees?: Maybe<Array<Scalars['JSON']>>;
  AbsentEmployee?: Maybe<Array<Scalars['JSON']>>;
  TodayData?: Maybe<Array<Scalars['JSON']>>;
  AttendanceCorrectionRequest?: Maybe<Scalars['Float']>;
};

export type TmsTimeOffConditionEntity = InterfaceBase & {
  __typename?: 'TMSTimeOffConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffPolicyConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
};

export type TmsTimeOffConditionInput = {
  TimeOffPolicyConditionID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TalentAdditionalUserField = {
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TalentInvitationStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};

export type TalentStatusLog = {
  StepNo?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status?: Maybe<RecruitmentTalentStatus>;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
};

export type TargetGroupCoursesEntity = NewInterfaceBase1 & {
  __typename?: 'TargetGroupCoursesEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  target_group_id: Scalars['String'];
  compulsary_course_items: Array<Scalars['JSON']>;
  TargetGroup?: Maybe<TargetGroupEntity>;
};

export type TargetGroupEntity = NewInterfaceBase1 & {
  __typename?: 'TargetGroupEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  TargetGroupCourse?: Maybe<TargetGroupCoursesEntity>;
  TrainingTargetHoursItems?: Maybe<Array<TrainingTargetHoursItemsEntity>>;
  compulsary_course_count?: Maybe<Scalars['String']>;
  in_use: Scalars['Boolean'];
};

export type TargetGroupInput = {
  name?: Maybe<Scalars['String']>;
  target_group_id?: Maybe<Scalars['String']>;
};

export type TargetGroupMatrixEntity = NewInterfaceBase1 & {
  __typename?: 'TargetGroupMatrixEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  description: Scalars['String'];
  target_group_id: Scalars['String'];
  condition_list: Array<Scalars['JSON']>;
  TargetGroup?: Maybe<TargetGroupEntity>;
};

export type TargetGroupMatrixInput = {
  target_group_matrix_id?: Maybe<Scalars['String']>;
  target_group_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  condition_list?: Maybe<Array<Scalars['JSON']>>;
};

export enum TargetType {
  Company = 'COMPANY',
  Designation = 'DESIGNATION',
  Department = 'DEPARTMENT',
  JobGrade = 'JOB_GRADE',
  DailyAllowancePolicy = 'DAILY_ALLOWANCE_POLICY',
  MonthlyAllowancePolicy = 'MONTHLY_ALLOWANCE_POLICY',
  DeductionPolicy = 'DEDUCTION_POLICY',
  ShiftSetup = 'SHIFT_SETUP',
  ShiftPattern = 'SHIFT_PATTERN',
  ShiftGroup = 'SHIFT_GROUP',
  TimeOff = 'TIME_OFF',
  OvertimeRoundingPolicy = 'OVERTIME_ROUNDING_POLICY',
  AllowanceDeductionRoundingPolicy = 'ALLOWANCE_DEDUCTION_ROUNDING_POLICY',
  OvertimeReasonCodes = 'OVERTIME_REASON_CODES',
  TimeOffReasonCodes = 'TIME_OFF_REASON_CODES',
  MobileCheckInLocation = 'MOBILE_CHECK_IN_LOCATION',
  OvertimeClaimTable = 'OVERTIME_CLAIM_TABLE',
  OvertimePolicy = 'OVERTIME_POLICY',
  Employee = 'EMPLOYEE',
  AttendanceRecord = 'ATTENDANCE_RECORD',
  SkillSet = 'SKILL_SET',
  InsuranceScheme = 'INSURANCE_SCHEME',
  RecruitmentSource = 'RECRUITMENT_SOURCE',
  UserDefineField = 'USER_DEFINE_FIELD',
  OvertimePayitem = 'OVERTIME_PAYITEM',
  EarningPayitem = 'EARNING_PAYITEM',
  DeductionPayitem = 'DEDUCTION_PAYITEM',
  NonPayablePayitem = 'NON_PAYABLE_PAYITEM',
  PayrollCycle = 'PAYROLL_CYCLE',
  OvertimePolicyMatrix = 'OVERTIME_POLICY_MATRIX',
  OvertimeClaimMatrix = 'OVERTIME_CLAIM_MATRIX',
  BluetoothBeaconDevices = 'BLUETOOTH_BEACON_DEVICES'
}

export type TargetUnion = CompanyEntity | DepartmentEntity | JobDesignationEntity | JobGradeEntity | DailyAllowancePolicyEntity | MonthlyAllowancePolicyEntity | DeductionPolicyEntity | ShiftEntity | ShiftPatternEntity | ShiftGroupEntity | TimeOffPolicyEntity | OvertimeRoundingSettingEntity | AllowanceDeductionRoundingEntity | OvertimeReasonEntity | TimeOffReasonEntity | MobileCheckInEntity | OvertimeClaimTableEntity | OvertimePolicyEntity | EmployeeEntity | AttendanceRecordEntity | SkillSetEntity | InsuranceSchemeEntity | RecruitmentSourceEntity | UserDefineEntity;

export enum TaxMaritialStatus {
  SingleSpouseNotClaimedChildren = 'Single_Spouse_Not_Claimed_Children',
  MarriedAndSpouseNotWorking = 'Married_and_Spouse_Not_Working',
  MarriedAndSpouseWorking = 'Married_and_Spouse_Working',
  DivorceWidowWidower = 'Divorce_Widow_Widower'
}

export type TaxOutput = {
  __typename?: 'TaxOutput';
  FinalTax1: Scalars['Float'];
  FinalTax2: Scalars['Float'];
  NetMTD: Scalars['Float'];
  MTD_A: Scalars['Float'];
  MTD_B: Scalars['Float'];
  YearlyTax_CS: Scalars['Float'];
  MTD_C: Scalars['Float'];
  FinalZakat: Scalars['Float'];
  TaxZakat: Scalars['Float'];
  Tax1: Scalars['Float'];
  Tax2: Scalars['Float'];
};

export type TaxReisscosInput = {
  __typename?: 'TaxREISSCOSInput';
  currentEISSCOS?: Maybe<Scalars['Float']>;
  TaxReliefSocsoEISLimit?: Maybe<Scalars['Float']>;
  PreviousMonthTaxRelief?: Maybe<Array<EmployeeTaxReliefEntity>>;
  CurrentMonthReliefAmount?: Maybe<Scalars['Float']>;
};

export type TaxRefEntity = InterfaceBase & {
  __typename?: 'TaxRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxReflD: Scalars['String'];
  TaxRefType: TaxRefType;
  PeriodYear: Scalars['Float'];
  PFromAmount: Scalars['Float'];
  PToAmount: Scalars['Float'];
  MAmount: Scalars['Float'];
  RTaxRate: Scalars['Float'];
  BIN3Amount: Scalars['Float'];
  B2Amount: Scalars['Float'];
  NonResidentRate: Scalars['Float'];
};

export enum TaxRefType {
  ResidentNonResident = 'ResidentNonResident',
  ReturningExpertProgram = 'Returning_Expert_Program',
  KnowledgeWorker = 'Knowledge_Worker'
}

export type TaxReliefEntity = InterfaceBase & {
  __typename?: 'TaxReliefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxReliefID: Scalars['String'];
  ReliefCode: Scalars['String'];
  Description: Scalars['String'];
  PeriodYear: Scalars['Float'];
  MaxAmount: Scalars['Float'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type TaxReliefInput = {
  ReliefCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  MaxAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TaxZakatCalculationOutput = {
  __typename?: 'TaxZakatCalculationOutput';
  NetMTD: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  PayrollCycleIsMTD: Scalars['Boolean'];
  PayrollCycleIsZakat: Scalars['Boolean'];
  ZakatRate: Scalars['Float'];
  IsZakat: Scalars['Boolean'];
  FinalTotalTAXPaid: Scalars['Float'];
  FinalTotalZAKATPaid: Scalars['Float'];
  FinalTotalMTD: Scalars['Float'];
};

export type TempAttendanceEntity = {
  __typename?: 'TempAttendanceEntity';
  id?: Maybe<Scalars['String']>;
  file_name: Scalars['String'];
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['DateTime']>;
  integration_clock_type?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
};

export type TempCareerMovementEntity = {
  __typename?: 'TempCareerMovementEntity';
  TempCareerSalaryID: Scalars['String'];
  ToCompany: Scalars['String'];
  EmployeeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  MovementType: Scalars['String'];
  Sequence: Scalars['Float'];
  ToOrgUnit?: Maybe<Scalars['String']>;
  ToPosition: Scalars['String'];
  ToJobGrade: Scalars['String'];
  ToEmploymentType: Scalars['String'];
  StaffCategory: Scalars['String'];
  ExitReasonID?: Maybe<Scalars['String']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsCareerLog: Scalars['Boolean'];
  IsSalaryLog: Scalars['Boolean'];
  FromCompany?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  NewEmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempDependentContactEntity = {
  __typename?: 'TempDependentContactEntity';
  TempDependentContactID: Scalars['String'];
  FullName: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  Nationality: Scalars['String'];
  BirthDate?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempDependentEntity = {
  __typename?: 'TempDependentEntity';
  TempDependentID: Scalars['String'];
  EmployeeID: Scalars['String'];
  FullName: Scalars['String'];
  Relationship: Scalars['String'];
  IsEmergencyContact: Scalars['Boolean'];
  Nationality: Scalars['String'];
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  MobileNo?: Maybe<Scalars['String']>;
  Occupation?: Maybe<Scalars['String']>;
  IsWorking: Scalars['Boolean'];
  Education: Scalars['String'];
  ChildEligibleRate: Scalars['Float'];
  IsOKU: Scalars['Boolean'];
  PCBNo?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};

export type TempEmployeeSalaryRecordsEntity = {
  __typename?: 'TempEmployeeSalaryRecordsEntity';
  TempCareerSalaryID: Scalars['String'];
  ToCompany: Scalars['String'];
  EmployeeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  FromSalary: Scalars['String'];
  ToSalary: Scalars['String'];
  Reason: Scalars['String'];
  SalaryType: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  IsSalaryLog: Scalars['Boolean'];
  IsCareerLog: Scalars['Boolean'];
  MovementType?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempEmploymentInfoEntity = {
  __typename?: 'TempEmploymentInfoEntity';
  TempEmploymentInfoID: Scalars['String'];
  ContactID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeNo: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  DesignationID: Scalars['String'];
  JobGradeID: Scalars['String'];
  StaffCategoryID: Scalars['String'];
  RoleID: Scalars['String'];
  RoleName: Scalars['String'];
  EmployeeStatus: Status;
  Status: Status;
  EmploymentType: EmploymentType;
  JoinedDate: Scalars['DateTime'];
  JoinedReferenceDate: Scalars['DateTime'];
  Probation?: Maybe<Scalars['Float']>;
  ConfirmedDate: Scalars['DateTime'];
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyID: Scalars['String'];
  CalendarTitle: Scalars['String'];
  RecuritmentSourceID: Scalars['String'];
  IsOKU?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID: Scalars['String'];
  RetirementAge: Scalars['Float'];
};

export type TempLeaveAdjustmentEntity = {
  __typename?: 'TempLeaveAdjustmentEntity';
  TempLeaveAdjustmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  TotalDay: Scalars['Float'];
  Remarks: Scalars['String'];
  PeriodYear: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};

export type TempManualAdjustmentEntity = {
  __typename?: 'TempManualAdjustmentEntity';
  TempLeaveAdjustmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  TotalDay: Scalars['Float'];
  Remarks: Scalars['String'];
  FromDate: Scalars['DateTime'];
  UntilDate: Scalars['DateTime'];
  SubscriptionAccountID: Scalars['String'];
};

export type TempPayrollHistoryEntity = {
  __typename?: 'TempPayrollHistoryEntity';
  Id: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayItem?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
};

export type TempPersonnelProfileEntity = {
  __typename?: 'TempPersonnelProfileEntity';
  TempPersonnelProfileID: Scalars['String'];
  FullName: Scalars['String'];
  Salutation: Scalars['String'];
  NickName?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  MobileNo: Scalars['String'];
  Nationality: Scalars['String'];
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Gender: Scalars['String'];
  Marital: Scalars['String'];
  Race: Scalars['String'];
  Religion: Scalars['String'];
  PermanentAddress: Address2;
  SameAddress?: Maybe<Scalars['Boolean']>;
  Address?: Maybe<Address2>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempReportingStructureEntity = {
  __typename?: 'TempReportingStructureEntity';
  TempReportingStructureID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubordinateID: Scalars['String'];
  SuperiorID: Scalars['Float'];
  ReportingType: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ReportingGroupID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  ExpiryDate: Scalars['DateTime'];
};

export type TempSalaryEntity = {
  __typename?: 'TempSalaryEntity';
  id?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  reason?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
};

export type TempStatutoryInfoEntity = {
  __typename?: 'TempStatutoryInfoEntity';
  TempStatutoryInfoID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PaymentMethod: PaymentMethod;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  IsEPF: Scalars['Boolean'];
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['String']>;
  EPFVERateCmp?: Maybe<Scalars['String']>;
  IsSocso: Scalars['Boolean'];
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB: Scalars['Boolean'];
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  PCBNo?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  IsEIS: Scalars['Boolean'];
  CompanyEISAccount?: Maybe<Scalars['String']>;
  EISNo?: Maybe<Scalars['String']>;
  IsASB: Scalars['Boolean'];
  ASBNo?: Maybe<Scalars['String']>;
  IsAngkasa: Scalars['Boolean'];
  AngkasaNo?: Maybe<Scalars['String']>;
  IsKWAP: Scalars['Boolean'];
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH: Scalars['Boolean'];
  TBHNo?: Maybe<Scalars['String']>;
  IsHRDF: Scalars['Boolean'];
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  CountryCode?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempTp3Entity = {
  __typename?: 'TempTP3Entity';
  TempTP3ID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  GrossTaxable: Scalars['Float'];
  EPFPaid: Scalars['Float'];
  PCBPaid: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  TaxExemptionList?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TempUserAccessEntity = InterfaceBase & {
  __typename?: 'TempUserAccessEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyAccess: Scalars['JSON'];
  EmployeeIDs: Scalars['JSON'];
  SubordinateIDs: Scalars['JSON'];
};

export type TempZakatContributionEntity = {
  __typename?: 'TempZakatContributionEntity';
  TempZakatContributionID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PayItemID: Scalars['String'];
  StartPeriodID: Scalars['String'];
  EndPeroidID?: Maybe<Scalars['String']>;
  ZakatStateID: Scalars['String'];
  AccountNo: Scalars['String'];
  Percentage?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};

export type TemplateLibraryAssignmentEntity = InterfaceBase & {
  __typename?: 'TemplateLibraryAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TemplateLibraryAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Module?: Maybe<Module>;
  SourceType?: Maybe<TargetType>;
  SourceID?: Maybe<Scalars['String']>;
  TargetType?: Maybe<TargetType>;
  TargetID?: Maybe<Scalars['String']>;
  EnableTarget?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  ConditionList?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  Target?: Maybe<TargetUnion>;
};

export type TemplateLibraryAssignmentInfolineOutput = {
  __typename?: 'TemplateLibraryAssignmentInfolineOutput';
  ShiftAssignment?: Maybe<Scalars['DateTime']>;
  ShiftPatternAssignment?: Maybe<Scalars['DateTime']>;
  ShiftGroupAssignment?: Maybe<Scalars['DateTime']>;
  DailyAllowanceAssignment?: Maybe<Scalars['DateTime']>;
  MonthlyAllowanceAssignment?: Maybe<Scalars['DateTime']>;
  DeductionAssignment?: Maybe<Scalars['DateTime']>;
  TimeOffAssignment?: Maybe<Scalars['DateTime']>;
  OvertimeReason?: Maybe<Scalars['DateTime']>;
  TimeOffReason?: Maybe<Scalars['DateTime']>;
  MobileCheckInSite?: Maybe<Scalars['Float']>;
  MobileCheckInRadius?: Maybe<Scalars['Float']>;
  BluetoothBeaconDevices?: Maybe<Scalars['Float']>;
  PeriodicAllowanceType?: Maybe<Scalars['DateTime']>;
};

export type TemplateLibraryAssignmentInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  TemplateLibraryAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Module?: Maybe<Module>;
  SourceType?: Maybe<TargetType>;
  SourceID?: Maybe<Scalars['String']>;
  TargetType?: Maybe<TargetType>;
  TargetID?: Maybe<Scalars['String']>;
  EnableTarget?: Maybe<Scalars['Boolean']>;
  ConditionList?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type TemplateLibrarySummaryOutput = {
  __typename?: 'TemplateLibrarySummaryOutput';
  ShiftSetup?: Maybe<Scalars['Float']>;
  ShiftPattern?: Maybe<Scalars['Float']>;
  ShiftGroup?: Maybe<Scalars['Float']>;
  OvertimePolicy?: Maybe<Scalars['Float']>;
  OvertimeClaimTable?: Maybe<Scalars['Float']>;
  OvertimeReasonCode?: Maybe<Scalars['Float']>;
  OvertimeRoundingPolicy?: Maybe<Scalars['Float']>;
  DailyAllowancePolicy?: Maybe<Scalars['Float']>;
  MonthlyAllowancePolicy?: Maybe<Scalars['Float']>;
  PeriodicDeductionPolicy?: Maybe<Scalars['Float']>;
  DeudctionPolicy?: Maybe<Scalars['Float']>;
  DeductionRoundingPolicy?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  JobGrade?: Maybe<Scalars['Float']>;
  assignedJob?: Maybe<Scalars['Float']>;
  TimeOffReasonCode?: Maybe<Scalars['Float']>;
  MobileCheckInLocation?: Maybe<Scalars['Float']>;
  templateDate?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Scalars['JSON']>;
  OvertimeCutOffDay?: Maybe<Scalars['Float']>;
  OTRoundingPolicy?: Maybe<Scalars['String']>;
  AutomatedClockDataImport?: Maybe<Scalars['Float']>;
  PeriodicDate?: Maybe<Scalars['DateTime']>;
  AttendanceAnalysisTemplateDate?: Maybe<Scalars['DateTime']>;
};

export type TestingBusinessInsightCompanyOutput = {
  __typename?: 'TestingBusinessInsightCompanyOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<TestingBusinessInsightDepartmentOutput>>;
};

export type TestingBusinessInsightDepartmentOutput = {
  __typename?: 'TestingBusinessInsightDepartmentOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<TestingBusinessInsightDivisionOutput>>;
};

export type TestingBusinessInsightDivisionOutput = {
  __typename?: 'TestingBusinessInsightDivisionOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TestingBusinessInsightGroupingOutput = {
  __typename?: 'TestingBusinessInsightGroupingOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  groupName?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightLeaveTakenOutput = {
  __typename?: 'TestingBusinessInsightLeaveTakenOutput';
  TotalAllLeave?: Maybe<Scalars['Float']>;
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightLeaveTypeOutput = {
  __typename?: 'TestingBusinessInsightLeaveTypeOutput';
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  OthersLeave?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightListingOutput = {
  __typename?: 'TestingBusinessInsightListingOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsResigned?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  LengthOfService?: Maybe<Scalars['Float']>;
  CurrentYearNewJoin?: Maybe<Scalars['Float']>;
  CurrentYearResigned?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightOutput = {
  __typename?: 'TestingBusinessInsightOutput';
  FilterType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  TotalEmp?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightPayrollPayItemOutput = {
  __typename?: 'TestingBusinessInsightPayrollPayItemOutput';
  NetAmount?: Maybe<Scalars['Float']>;
  TotalEarning?: Maybe<Scalars['Float']>;
  TotalOvertime?: Maybe<Scalars['Float']>;
  TotalNonpayable?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightPayrollSalaryOverheadOutput = {
  __typename?: 'TestingBusinessInsightPayrollSalaryOverheadOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  NetPay?: Maybe<Scalars['Float']>;
  TotalGross?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightPayrollStatutoryOutput = {
  __typename?: 'TestingBusinessInsightPayrollStatutoryOutput';
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  EmployerContribution?: Maybe<Scalars['Float']>;
  EmployeeContribution?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  EmployerSOCSO?: Maybe<Scalars['Float']>;
  EmployeeSOCSO?: Maybe<Scalars['Float']>;
};

export type TestingBusinessInsightSummaryOutput = {
  __typename?: 'TestingBusinessInsightSummaryOutput';
  FilterType?: Maybe<Scalars['String']>;
  Race?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Gender?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Religion?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Marital?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  Age?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  EmploymentType?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  JobGrade?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  LengthOfService?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  RecruitmentSource?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
  StaffTurnover?: Maybe<Array<TestingBusinessInsightGroupingOutput>>;
};

export type TestingBusinessInsightSummaryPayrollOutput = {
  __typename?: 'TestingBusinessInsightSummaryPayrollOutput';
  FilterType?: Maybe<Scalars['String']>;
  SalaryOverhead?: Maybe<Array<TestingBusinessInsightPayrollSalaryOverheadOutput>>;
  StatutoryContribution?: Maybe<Array<TestingBusinessInsightPayrollStatutoryOutput>>;
  PayItems?: Maybe<Array<TestingBusinessInsightPayrollPayItemOutput>>;
};

export type TestingCareerLogEntity = InterfaceBase & {
  __typename?: 'TestingCareerLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  SalaryType?: Maybe<SalaryType>;
  MovementType?: Maybe<MovementType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
  FromCompanyTable?: Maybe<CompanyEntity>;
  ToCompanyTable?: Maybe<CompanyEntity>;
  FromPositionTable?: Maybe<JobDesignationEntity>;
  ToPositionTable?: Maybe<JobDesignationEntity>;
  FromJobGradeTable?: Maybe<JobGradeEntity>;
  ToJobGradeTable?: Maybe<JobGradeEntity>;
  FromDepartmentTable?: Maybe<DepartmentEntity>;
  ToDepartmentTable?: Maybe<DepartmentEntity>;
  FromDivisionTable?: Maybe<DepartmentEntity>;
  ToDivisionTable?: Maybe<DepartmentEntity>;
  ExitCompanyReason?: Maybe<PersonnelParameterEntity>;
  Document?: Maybe<DocumentsEntity>;
  ToRole?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
  CancellationRemarks?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  Sequence?: Maybe<Scalars['Boolean']>;
};

export type TestingCareerLogInput = {
  CareerLogID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  SalaryType?: Maybe<SalaryType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  ExitReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  FromOrgUnit?: Maybe<Scalars['String']>;
  ToOrgUnit?: Maybe<Scalars['String']>;
  ProbationExtensionMonth?: Maybe<Scalars['Float']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  excelfilename?: Maybe<Scalars['String']>;
};

export type TestingClaimAppDetailsOutput = {
  __typename?: 'TestingClaimAppDetailsOutput';
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type TestingClaimAppOutput = {
  __typename?: 'TestingClaimAppOutput';
  Subject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Details?: Maybe<Array<TestingClaimAppDetailsOutput>>;
};

export type TestingConditionObject = {
  __typename?: 'TestingConditionObject';
  Description?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};

export type TestingContactEntity = InterfaceBase & {
  __typename?: 'TestingContactEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Address2>;
  ProfilePicture?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  PermanentAddress?: Maybe<Address2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  EmployeeSalutation?: Maybe<PersonnelParameterEntity>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingContactInput = {
  ContactID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportNoLHDN?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Salutation?: Maybe<Scalars['String']>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<AddressInput2>;
  PermanentAddress?: Maybe<AddressInput2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeBankInfoEntity = InterfaceBase & {
  __typename?: 'TestingEmployeeBankInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeBankInfoID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Employee?: Maybe<EmployeeEntity>;
  CompanyBankAccount?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PayeeID?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeBusinessInsightListing = {
  FilterType?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type TestingEmployeeConditionsDetails = {
  __typename?: 'TestingEmployeeConditionsDetails';
  Name?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<TestingConditionObject>;
  JobPosition?: Maybe<TestingConditionObject>;
  EmployeeType?: Maybe<Scalars['String']>;
  StaffCategory?: Maybe<TestingConditionObject>;
  Confirmed?: Maybe<Scalars['Boolean']>;
  Gender?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  OrgUnit?: Maybe<TestingConditionObject>;
  DateJoined?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Policies?: Maybe<Array<TestingPolicyOutput>>;
};

export type TestingEmployeeDependentsEntity = InterfaceBase & {
  __typename?: 'TestingEmployeeDependentsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeDependentsID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Employee?: Maybe<TestingEmployeeEntity>;
  Contact?: Maybe<TestingContactEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeDependentsInput = {
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TestingEmployeeEntity = InterfaceBase & {
  __typename?: 'TestingEmployeeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Status>;
  CalendarTitle?: Maybe<Scalars['String']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  DeviceRegistrationToken?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<TestingContactEntity>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  CalendarPolicy?: Maybe<CalendarPolicyEntity>;
  EmployeeStatutory?: Maybe<TestingEmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  AttendanceRecord?: Maybe<Array<AttendanceRecordEntity>>;
  Subordinate?: Maybe<Array<TestingEmployeeEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<TestingEmployeeEntity>;
  StaffCategoryDetail?: Maybe<PersonnelParameterEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  OrgUnit?: Maybe<CompanyOrgUnitEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  PersonnelEducations?: Maybe<Array<PersonnelEducationEntity>>;
  PersonnelCertificates?: Maybe<Array<PersonnelCertificateEntity>>;
  PersonnelHealthInfo?: Maybe<PersonnelHealthInfoEntity>;
  PersonnelJobHistories?: Maybe<Array<PersonnelJobHistoryEntity>>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  AttendanceRecordHikVision?: Maybe<Array<AttendanceRecordHikVisionEntity>>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  DataChangesList?: Maybe<Array<DataChangesEntity>>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
  LastWorkingDate?: Maybe<Scalars['DateTime']>;
  LastEmploymentDate?: Maybe<Scalars['DateTime']>;
};

export type TestingEmployeeInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystemDoc?: Maybe<Scalars['Boolean']>;
  SerialNo?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<FormStatus>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  TenderResignDate?: Maybe<Scalars['DateTime']>;
  Probation?: Maybe<Scalars['Float']>;
  RetirementAge?: Maybe<Scalars['Float']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  JoinedReferenceDate?: Maybe<Scalars['DateTime']>;
  StaffCategory?: Maybe<Scalars['String']>;
  StaffCategoryID?: Maybe<Scalars['String']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  CalendarTitle?: Maybe<Scalars['String']>;
  CalendarPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  EmployeeStatus?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  ActualConfirmedDate?: Maybe<Scalars['DateTime']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeeListOutput = {
  __typename?: 'TestingEmployeeListOutput';
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
};

export type TestingEmployeeListingOutput = {
  __typename?: 'TestingEmployeeListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  EmployeeNoName?: Maybe<Scalars['String']>;
};

export type TestingEmployeePrevContributionEntity = InterfaceBase & {
  __typename?: 'TestingEmployeePrevContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeePrevContributionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  GrossTaxable?: Maybe<Scalars['Float']>;
  EPFPaid?: Maybe<Scalars['Float']>;
  PCBPaid?: Maybe<Scalars['Float']>;
  ZAKATPaid?: Maybe<Scalars['Float']>;
  Employee?: Maybe<TestingEmployeeEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingEmployeePrevContributionInput = {
  EmployeePrevContributionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  GrossTaxable: Scalars['Float'];
  EPFPaid: Scalars['Float'];
  PCBPaid: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TestingEmployeeStatutoryEntity = {
  __typename?: 'TestingEmployeeStatutoryEntity';
  EmployeeStatutoryID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<TestingEmployeeEntity>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<Array<UserLogs>>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyEPFAccount?: Maybe<Scalars['String']>;
  CompanySocsoAccount?: Maybe<Scalars['String']>;
  CompanyPCBAccount?: Maybe<Scalars['String']>;
  CompanyEISAccount?: Maybe<Scalars['String']>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingGroupCountEmployeeRoleListingOutput = {
  __typename?: 'TestingGroupCountEmployeeRoleListingOutput';
  RecruitmentSourceID?: Maybe<Scalars['String']>;
  totalEmployees?: Maybe<Scalars['Float']>;
};

export type TestingLastUpdatedDatesOutput = {
  __typename?: 'TestingLastUpdatedDatesOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeLastDate?: Maybe<Scalars['DateTime']>;
  AssetsLastDate?: Maybe<Scalars['DateTime']>;
  BIKLastDate?: Maybe<Scalars['DateTime']>;
  CareerLastDate?: Maybe<Scalars['DateTime']>;
  SubordinateLastDate?: Maybe<Scalars['DateTime']>;
  TaxLastDate?: Maybe<Scalars['DateTime']>;
  Cp22LastDate?: Maybe<Scalars['DateTime']>;
  HealthInfoLastDate?: Maybe<Scalars['DateTime']>;
  UserDefineFieldsLastDate?: Maybe<Scalars['DateTime']>;
};

export type TestingLeaveAppOutput = {
  __typename?: 'TestingLeaveAppOutput';
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type TestingLeaveHeaderEntity = InterfaceBase & {
  __typename?: 'TestingLeaveHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  IsTms?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  IsAdvanceLeave?: Maybe<Scalars['Boolean']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<TestingEmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<TestingEmployeeEntity>;
  LeaveHistory?: Maybe<Array<TestingLeaveHeaderEntity>>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  VoiceDocumentID: Scalars['String'];
  LeaveReasonID: Scalars['String'];
  DiseaseCodeID: Scalars['String'];
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubmitterName?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
  OneTimePay?: Maybe<OneTimePayEntity>;
  VoiceDocument?: Maybe<DocumentsEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingMyApplicationOutput = {
  __typename?: 'TestingMyApplicationOutput';
  leave?: Maybe<TestingLeaveAppOutput>;
  claim?: Maybe<TestingClaimAppOutput>;
  requisition?: Maybe<TestingRequisitionAppOutput>;
};

export type TestingMySubordinatesInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['Float']>;
};

export type TestingPersonnelEFileListingOutput = {
  __typename?: 'TestingPersonnelEFileListingOutput';
  Employee?: Maybe<TestingEmployeeEntity>;
  IsMultipleCompany?: Maybe<Scalars['Boolean']>;
  IsNotInUse?: Maybe<Scalars['Boolean']>;
};

export type TestingPolicyOutput = {
  __typename?: 'TestingPolicyOutput';
  ID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  Policy?: Maybe<Array<Scalars['JSON']>>;
};

export type TestingProbationExtendedEmployeeOutput = {
  __typename?: 'TestingProbationExtendedEmployeeOutput';
  Employee?: Maybe<EmployeeEntity>;
  ProbationExtension?: Maybe<TestingCareerLogEntity>;
};

export type TestingReportingStructureEntity = InterfaceBase & {
  __typename?: 'TestingReportingStructureEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReportingStructureID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TestingReportingStructureInput = {
  ReportingStructureID: Scalars['String'];
  FromOrgUnitID?: Maybe<Scalars['String']>;
  ToOrgUnitID?: Maybe<Scalars['String']>;
  FromJobPositionID?: Maybe<Scalars['String']>;
  ToJobPositionID?: Maybe<Scalars['String']>;
  SubordinateID?: Maybe<Scalars['String']>;
  SuperiorID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ReportingType: ReportingType;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TestingRequisitionAppOutput = {
  __typename?: 'TestingRequisitionAppOutput';
  RequisitionType?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<Scalars['String']>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type TestingZakatContributionEntity = InterfaceBase & {
  __typename?: 'TestingZakatContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ZakatStateID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  StartPeriod?: Maybe<PayPeriodEntity>;
  EndPeriod?: Maybe<PayPeriodEntity>;
  Employee?: Maybe<TestingEmployeeEntity>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type TimeAttendance = {
  IsSelected?: Maybe<Scalars['Boolean']>;
  ApprovalName?: Maybe<Scalars['String']>;
};

export enum TimeAttendanceDelegation {
  OvertimeSubmissionApproval = 'Overtime_Submission_Approval',
  OvertimeCancellationApproval = 'Overtime_Cancellation_Approval',
  TimeOffSubmissionApproval = 'Time_Off_Submission_Approval',
  TimeOffCancellationApproval = 'Time_Off_Cancellation_Approval',
  ChangeShiftRequestApproval = 'Change_Shift_Request_Approval'
}

export type TimeAttendanceTemplateEntity = InterfaceBase & {
  __typename?: 'TimeAttendanceTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TMSTemplateID: Scalars['String'];
  JobGradePolicyID?: Maybe<Scalars['String']>;
  TMSPolicyID?: Maybe<Scalars['String']>;
  TMSTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TMSConditionList?: Maybe<Scalars['String']>;
  TMSPolicyIDList?: Maybe<Scalars['String']>;
  JobGradePolicy?: Maybe<JobGradePolicyEntity>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type TimeAttendanceTemplateInput = {
  TMSTemplateID?: Maybe<Scalars['String']>;
  TMSPolicyID?: Maybe<Scalars['String']>;
  TMSTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  TMSPolicyIDList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TimeOffApConditionList = {
  TypeID: Scalars['String'];
  operatorValue: Scalars['String'];
  valueVal: Array<Scalars['String']>;
};

export type TimeOffEntity = InterfaceBase & {
  __typename?: 'TimeOffEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TimeOffStatus: TimeOffStatus;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToReasonID?: Maybe<Scalars['String']>;
  IsDelegation?: Maybe<Scalars['Boolean']>;
  WorkDate: Scalars['DateTime'];
  EndTime: Scalars['DateTime'];
  StartTime: Scalars['DateTime'];
  VisitingPlace?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ToReason?: Maybe<TimeOffReasonEntity>;
  TimeOffHistory?: Maybe<Array<TimeOffEntity>>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type TimeOffInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  EmployeeID?: Maybe<Scalars['String']>;
  TimeOffID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  ToReasonID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsInput>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  VisitingPlace?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  TimeOffStatus?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveApprovalStatus?: Maybe<ApprovalStatus>;
  IsLeaveDeduction?: Maybe<Scalars['Boolean']>;
  DailyWorkingHours?: Maybe<Scalars['Float']>;
};

export type TimeOffPolicyEntity = InterfaceBase & {
  __typename?: 'TimeOffPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffPolicyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AllowTimeOff: Scalars['Boolean'];
  RequireTimeOffReason: Scalars['Boolean'];
  TimesPerMonth: Scalars['Float'];
  MinIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerDay: Scalars['Float'];
  MaxIntervalPerMonth: Scalars['Float'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type TimeOffPolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
  AllowTimeOff: Scalars['Boolean'];
  TimesPerMonth: Scalars['Float'];
  MinIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerDay: Scalars['Float'];
  MaxIntervalPerMonth: Scalars['Float'];
};

export type TimeOffReasonEntity = InterfaceBase & {
  __typename?: 'TimeOffReasonEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffReasonID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type TimeOffReasonInput = {
  TimeOffReasonID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type TimeOffSettingEntity = InterfaceBase & {
  __typename?: 'TimeOffSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffSettingID: Scalars['String'];
  Name: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EnableTimeOff?: Maybe<Scalars['Boolean']>;
  TimesPerMonth?: Maybe<Scalars['Float']>;
  MinIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxTimeOffPerDay?: Maybe<Scalars['Float']>;
  MaxTimeOffPerMonth?: Maybe<Scalars['Float']>;
  TimeOffReasonID?: Maybe<Scalars['String']>;
  BackdatedTimeOff?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<Array<Scalars['JSON']>>;
  Duration?: Maybe<Scalars['String']>;
  DailyWorkingHours?: Maybe<Scalars['String']>;
  IsLeaveDeduction?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  TimeOffReasonEntity?: Maybe<TimeOffReasonEntity>;
};

export type TimeOffSettingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  TimeOffSettingID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TimesPerMonth?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MinIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxTimeOffPerDay?: Maybe<Scalars['Float']>;
  MaxTimeOffPerMonth?: Maybe<Scalars['Float']>;
  Reasons?: Maybe<Scalars['String']>;
  ReasonIDs?: Maybe<Scalars['String']>;
  EnableTimeOff?: Maybe<Scalars['Boolean']>;
  BackdatedTimeOff?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<Array<Scalars['JSON']>>;
  Duration?: Maybe<Scalars['String']>;
  DailyWorkingHours?: Maybe<Scalars['String']>;
  IsLeaveDeduction?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
};

export enum TimeOffStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Withdrawn = 'Withdrawn',
  Withdraw = 'Withdraw'
}

export type TimeSheetAgingDto = {
  __typename?: 'TimeSheetAgingDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
};

export type TimesheetMandayAnalysisByAccCodeDto = {
  __typename?: 'TimesheetMandayAnalysisByAccCodeDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
  Activity?: Maybe<Scalars['String']>;
  AccountCodeID?: Maybe<Scalars['String']>;
  AccountCodeName?: Maybe<Scalars['String']>;
};

export type TimesheetMandayAnalysisByAccTypeDto = {
  __typename?: 'TimesheetMandayAnalysisByAccTypeDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
  Account?: Maybe<Scalars['String']>;
};

export type TimesheetMandayAnalysisDto = {
  __typename?: 'TimesheetMandayAnalysisDTO';
  MTD?: Maybe<Scalars['String']>;
  YTD?: Maybe<Scalars['String']>;
  TD?: Maybe<Scalars['String']>;
  TotalMTD?: Maybe<Scalars['Float']>;
  TotalYTD?: Maybe<Scalars['Float']>;
  TotalTD?: Maybe<Scalars['Float']>;
  AccountTypeID?: Maybe<Scalars['String']>;
  AccountTypeName?: Maybe<Scalars['String']>;
  AccountTypeCode?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
};

export type TmsPolicyMenuInfoLinesOutput = {
  __typename?: 'TmsPolicyMenuInfoLinesOutput';
  OvertimeDateCreate?: Maybe<Scalars['DateTime']>;
  OvertimeDateModified?: Maybe<Scalars['DateTime']>;
  DailyDateCreate?: Maybe<Scalars['DateTime']>;
  DailyDateModified?: Maybe<Scalars['DateTime']>;
  MonthlyDateCreate?: Maybe<Scalars['DateTime']>;
  MonthlyDateModified?: Maybe<Scalars['DateTime']>;
  DeductionDateCreate?: Maybe<Scalars['DateTime']>;
  DeductionDateModified?: Maybe<Scalars['DateTime']>;
};

export type ToDoKpiExerciseOutput = InterfaceBase & {
  __typename?: 'ToDoKpiExerciseOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
  KpiExerciseSetupID: Scalars['String'];
  SuperiorID: Scalars['String'];
  KpiExerciseID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  AllowToReInitiate?: Maybe<Scalars['Boolean']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Superior?: Maybe<EmployeeEntity>;
  Approver?: Maybe<EmployeeEntity>;
  ReviewGroupName?: Maybe<Scalars['String']>;
  Type: Scalars['String'];
  EmployeeName: Scalars['String'];
  EmployeeNo: Scalars['String'];
  SubmitDate?: Maybe<Scalars['DateTime']>;
  ApproverName: Scalars['String'];
  IsDelegation?: Maybe<Scalars['Boolean']>;
  ApprovalHistory: Array<KpiExerciseEntity>;
};

export type ToDoPerformanceOutput = {
  __typename?: 'ToDoPerformanceOutput';
  KpiExercise?: Maybe<Array<ToDoKpiExerciseOutput>>;
  EmployeeReview?: Maybe<Array<EmployeeReviewEntity>>;
};

export type TopClaimTakerOutput = {
  __typename?: 'TopClaimTakerOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimTaken?: Maybe<Scalars['Float']>;
};

export type TopRequisitionTakerOutput = {
  __typename?: 'TopRequisitionTakerOutput';
  Employee?: Maybe<EmployeeEntity>;
  RequisitionTaken?: Maybe<Scalars['Float']>;
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type TraineeEvalEntity = InterfaceBase & {
  __typename?: 'TraineeEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TraineeEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type TraineeEvalInput = {
  TraineeEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainerEvalEntity = InterfaceBase & {
  __typename?: 'TrainerEvalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainerEvalID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type TrainerEvalInput = {
  TrainerEvalID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Score?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainerEvaluationListingOutput = {
  __typename?: 'TrainerEvaluationListingOutput';
  Provider?: Maybe<AuthorisedTrainingProviderEntity>;
  TrainingCourse?: Maybe<Array<TrainingCourseOutput>>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TrainerProfileEntity = NewInterfaceBase1 & {
  __typename?: 'TrainerProfileEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  trainer_name: Scalars['String'];
  employee_id?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  salutation: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  nationality?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  mobile_no?: Maybe<Scalars['String']>;
  birthday_dt?: Maybe<Scalars['DateTime']>;
  address: Address2;
  overall_rating?: Maybe<Scalars['Float']>;
  document_id: Scalars['String'];
  document?: Maybe<DocumentsEntity>;
  TTProviders?: Maybe<Array<TrainerTrainingProvidersEntity>>;
  TTCourses?: Maybe<Array<TrainerTrainingCoursesEntity>>;
  in_use?: Maybe<Scalars['Boolean']>;
};

export type TrainerProfileInput = {
  trainer_name?: Maybe<Scalars['String']>;
  trainer_profile_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  nationality?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  mobile_no?: Maybe<Scalars['String']>;
  birthday_dt?: Maybe<Scalars['DateTime']>;
  address?: Maybe<AddressInput2>;
  attachment?: Maybe<DocumentsInput>;
  training_providers?: Maybe<Array<TrainerTrainingProviderInput>>;
  training_courses?: Maybe<Array<TrainerTrainingCourseInput>>;
  trainer_training_courses_id?: Maybe<Scalars['String']>;
  training_course_library_id?: Maybe<Scalars['String']>;
};

export type TrainerTrainingCourseInput = {
  trainer_profile_id?: Maybe<Scalars['String']>;
  training_course_library_id?: Maybe<Scalars['String']>;
  trainer_training_courses_id?: Maybe<Scalars['String']>;
};

export type TrainerTrainingCoursesEntity = NewInterfaceBase1 & {
  __typename?: 'TrainerTrainingCoursesEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  trainer_profile_id: Scalars['String'];
  training_course_library_id: Scalars['String'];
  TrainerProfile?: Maybe<TrainerProfileEntity>;
  TrainingCourses?: Maybe<TrainingCourseLibraryEntity>;
};

export type TrainerTrainingProviderInput = {
  trainer_profile_id?: Maybe<Scalars['String']>;
  training_provider_id?: Maybe<Scalars['String']>;
  trainer_training_providers_id?: Maybe<Scalars['String']>;
};

export type TrainerTrainingProvidersEntity = NewInterfaceBase1 & {
  __typename?: 'TrainerTrainingProvidersEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  trainer_profile_id: Scalars['String'];
  training_provider_id: Scalars['String'];
  TrainerProfile?: Maybe<TrainerProfileEntity>;
  TrainingProviders?: Maybe<TrainingProviderEntity>;
};

export type TrainingAttendanceEntity = InterfaceBase & {
  __typename?: 'TrainingAttendanceEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainingAttendanceID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type TrainingAttendanceInput = {
  TrainingAttendanceID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingBudgetEntity = InterfaceBase & {
  __typename?: 'TrainingBudgetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainingBudgetID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  BudgetAmount?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type TrainingBudgetInput = {
  TrainingBudgetID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  BudgetAmount?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingBudgetItemsEntity = NewInterfaceBase1 & {
  __typename?: 'TrainingBudgetItemsEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  training_budget_id: Scalars['String'];
  budget_group_id: Scalars['String'];
  amount: Scalars['Float'];
  BudgetGroup?: Maybe<BudgetGroupEntity>;
  TrainingBudget?: Maybe<NewTrainingBudgetEntity>;
};

export type TrainingCheckInInput = {
  scheduled_training_data?: Maybe<Scalars['JSON']>;
  employee_check_in_time?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
};

export type TrainingCostEntity = InterfaceBase & {
  __typename?: 'TrainingCostEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TrainingCostID: Scalars['String'];
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  TrainingCostItemID?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TrainingCostItem?: Maybe<TrainingCostItemEntity>;
};

export type TrainingCostInput = {
  TrainingCostID?: Maybe<Scalars['String']>;
  ScheduledTrainingID?: Maybe<Scalars['String']>;
  TrainingCostItemID?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingCostItemEntity = InterfaceBase & {
  __typename?: 'TrainingCostItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  isInUse?: Maybe<Scalars['Boolean']>;
};

export type TrainingCostItemInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingCourseEntity = InterfaceBase & {
  __typename?: 'TrainingCourseEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  CourseClassificationID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Evaluation?: Maybe<Scalars['Boolean']>;
  HRDFClaimable?: Maybe<Scalars['Boolean']>;
  Type?: Maybe<CourseType>;
  Duration?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  PreferredTrainer?: Maybe<Array<Scalars['String']>>;
  AllowedJobGrade?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CourseClassification?: Maybe<CourseClassificationEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type TrainingCourseInput = {
  ID?: Maybe<Scalars['String']>;
  CourseClassificationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Evaluation?: Maybe<Scalars['Boolean']>;
  HRDFClaimable?: Maybe<Scalars['Boolean']>;
  Type?: Maybe<CourseType>;
  Duration?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  PreferredTrainer?: Maybe<Array<Scalars['String']>>;
  AllowedJobGrade?: Maybe<Array<Scalars['String']>>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TrainingCourseLibraryEntity = NewInterfaceBase1 & {
  __typename?: 'TrainingCourseLibraryEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  course_category_id: Scalars['String'];
  course_title: Scalars['String'];
  description: Scalars['String'];
  duration_type: Duration_Type;
  duration: Scalars['Float'];
  document?: Maybe<DocumentsEntity>;
  document_id?: Maybe<Scalars['String']>;
  hrdc_claimable?: Maybe<Scalars['Boolean']>;
  require_recertification?: Maybe<Scalars['Boolean']>;
  recertification_frequency_type?: Maybe<Recertification_Frequency_Type>;
  recertification_frequency?: Maybe<Scalars['Float']>;
  internal?: Maybe<Scalars['Boolean']>;
  local?: Maybe<Scalars['Boolean']>;
  min_pax: Scalars['Float'];
  max_pax: Scalars['Float'];
  estimated_cost: Scalars['Float'];
  cost_method: Cost_Method;
  code?: Maybe<Scalars['String']>;
  CourseCategory?: Maybe<CourseCategoryEntity>;
  TTCourses?: Maybe<TrainerTrainingCoursesEntity>;
  ScheduledTraining?: Maybe<Array<ScheduledTrainingEntity>>;
  in_use: Scalars['Boolean'];
};

export type TrainingCourseLibraryInput = {
  training_course_library_id?: Maybe<Scalars['String']>;
  course_category_id?: Maybe<Scalars['String']>;
  course_title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration_type?: Maybe<Duration_Type>;
  duration?: Maybe<Scalars['Float']>;
  attachment?: Maybe<DocumentsInput>;
  hrdc_claimable?: Maybe<Scalars['Boolean']>;
  require_recertification?: Maybe<Scalars['Boolean']>;
  recertification_frequency_type?: Maybe<Recertification_Frequency_Type>;
  recertification_frequency?: Maybe<Scalars['Float']>;
  internal?: Maybe<Scalars['Boolean']>;
  local?: Maybe<Scalars['Boolean']>;
  min_pax?: Maybe<Scalars['Float']>;
  max_pax?: Maybe<Scalars['Float']>;
  estimated_cost?: Maybe<Scalars['Float']>;
  cost_method?: Maybe<Cost_Method>;
  code?: Maybe<Scalars['String']>;
};

export type TrainingCourseOutput = {
  __typename?: 'TrainingCourseOutput';
  Course?: Maybe<ScheduleTrainingEntity>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TrainingExpenseEntity = NewInterfaceBase1 & {
  __typename?: 'TrainingExpenseEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  name: Scalars['String'];
  in_use?: Maybe<Scalars['Boolean']>;
};

export type TrainingExpenseInput = {
  name?: Maybe<Scalars['String']>;
  training_expense_id?: Maybe<Scalars['String']>;
};

export type TrainingProviderCoursesEntity = NewInterfaceBase1 & {
  __typename?: 'TrainingProviderCoursesEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  training_provider_id: Scalars['String'];
  training_course_library_id: Scalars['String'];
  provider_cost: Scalars['Float'];
  cost_method: Cost_Method;
  hrdc_title: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  TrainingProvider?: Maybe<TrainingProviderEntity>;
};

export type TrainingProviderCoursesInput = {
  training_provider_courses_id?: Maybe<Scalars['String']>;
  training_course_library_id?: Maybe<Scalars['String']>;
  provider_cost?: Maybe<Scalars['Float']>;
  cost_method?: Maybe<Cost_Method>;
  hrdc_title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  courses_provided?: Maybe<Array<TrainingProviderCoursesInput>>;
};

export type TrainingProviderEntity = NewInterfaceBase1 & {
  __typename?: 'TrainingProviderEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  document_id?: Maybe<Scalars['String']>;
  document?: Maybe<DocumentsEntity>;
  training_provider_name: Scalars['String'];
  training_provider_company_id: Scalars['String'];
  provider_type: Provider_Type;
  contact_person: Scalars['String'];
  mobile_no: Scalars['String'];
  web_url: Scalars['String'];
  hrdc_registered: Scalars['Boolean'];
  address: Address2;
  description: Scalars['String'];
  overall_rating?: Maybe<Scalars['Float']>;
  TTProviders?: Maybe<TrainerTrainingProvidersEntity>;
  TrainingProviderCourses?: Maybe<Array<TrainingProviderCoursesEntity>>;
  ScheduledTraining?: Maybe<Array<ScheduledTrainingEntity>>;
};

export type TrainingProviderInput = {
  training_provider_id?: Maybe<Scalars['String']>;
  attachment?: Maybe<DocumentsInput>;
  training_provider_name?: Maybe<Scalars['String']>;
  training_provider_company_id?: Maybe<Scalars['String']>;
  provider_type?: Maybe<Provider_Type>;
  contact_person?: Maybe<Scalars['String']>;
  mobile_no?: Maybe<Scalars['String']>;
  web_url?: Maybe<Scalars['String']>;
  hrdc_registered?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput2>;
  description?: Maybe<Scalars['String']>;
  courses_provided?: Maybe<Array<TrainingProviderCoursesInput>>;
};

export type TrainingRequestInput = {
  employee_training_request_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  employee_id?: Maybe<Scalars['String']>;
  attachment_input?: Maybe<Array<DocumentsInput>>;
  voice_input?: Maybe<DocumentsInput>;
  training_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  training_provider_name?: Maybe<Scalars['String']>;
  contact_info?: Maybe<Scalars['String']>;
  training_dt?: Maybe<Scalars['DateTime']>;
  estimated_course_fee?: Maybe<Scalars['Float']>;
  venue?: Maybe<Scalars['String']>;
  justification?: Maybe<Scalars['String']>;
  web_url?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approval_status?: Maybe<Request_Approval_Status>;
  comment?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
};

export type TrainingScheduleTableInput = {
  Date?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Hour?: Maybe<Scalars['Float']>;
};

export type TrainingTargetHoursEntity = NewInterfaceBase1 & {
  __typename?: 'TrainingTargetHoursEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  TrainingTargetHoursItems?: Maybe<Array<TrainingTargetHoursItemsEntity>>;
};

export type TrainingTargetHoursItemsEntity = NewInterfaceBase1 & {
  __typename?: 'TrainingTargetHoursItemsEntity';
  status?: Maybe<Status>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  subscription_account_id: Scalars['String'];
  training_target_hours_id: Scalars['String'];
  target_group_id: Scalars['String'];
  hours: Scalars['Float'];
  TargetGroup?: Maybe<TargetGroupEntity>;
  TrainingTargetHours?: Maybe<TrainingTargetHoursEntity>;
};

export type TrainingToDoOutput = {
  __typename?: 'TrainingToDoOutput';
  ScheduledTrainingEmployee?: Maybe<Array<ScheduledTrainingEmployeeEntity>>;
  EmployeeTrainingRequest?: Maybe<Array<EmployeeTrainingRequestEntity>>;
};

export enum Training_Evaluation {
  CourseEvaluation = 'Course_Evaluation',
  ProviderEvaluation = 'Provider_Evaluation',
  TrainerEvaluation = 'Trainer_Evaluation'
}

export type TraningModuleLatestUpdateOutput = {
  __typename?: 'TraningModuleLatestUpdateOutput';
  CostBudget?: Maybe<Scalars['DateTime']>;
  ScheduledTrainig?: Maybe<Scalars['DateTime']>;
  ScheduledTrainingCount?: Maybe<Scalars['Float']>;
  TraineeEval?: Maybe<Scalars['DateTime']>;
  CourseEval?: Maybe<Scalars['DateTime']>;
  TrainerEval?: Maybe<Scalars['DateTime']>;
  SupervisorEval?: Maybe<Scalars['DateTime']>;
  TrainingCost?: Maybe<Scalars['DateTime']>;
  CostAllocation?: Maybe<Scalars['DateTime']>;
  HRDFClaim?: Maybe<Scalars['DateTime']>;
};

export enum TransportMode {
  Flight = 'Flight',
  Car = 'Car',
  Bus = 'Bus',
  Train = 'Train',
  Motorcycle = 'Motorcycle'
}

export type TrxIDs = {
  ID?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  TrxStatus?: Maybe<Scalars['String']>;
  ChangesData?: Maybe<ChangesDataInput>;
  Type?: Maybe<Scalars['String']>;
  Action?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PersonalID?: Maybe<Scalars['String']>;
};

export type TsAccountCodeEntity = InterfaceBase & {
  __typename?: 'TsAccountCodeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TsAccountType: TsAccountTypeEntity;
};

export type TsAccountCodeInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TsAccountTypeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TsAccountTypeEntity = InterfaceBase & {
  __typename?: 'TsAccountTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  ActivityList: Array<ActivityListJson>;
  TsAccountCode: Array<TsAccountCodeEntity>;
};

export type TsAccountTypeInput = {
  ID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ActivityList?: Maybe<Array<ActivityListJsonInput>>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};


export type UserDashboardControlEntity = InterfaceBase & {
  __typename?: 'UserDashboardControlEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  DashboardCard: Scalars['JSON'];
};

export type UserDashboardControlInput = {
  ID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  DashboardCard: Array<DashboardCardSetting>;
  CreatedBy?: Maybe<Scalars['String']>;
};

export type UserDefineBulkInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  UserDefineID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type UserDefineDetailEntity = InterfaceBase & {
  __typename?: 'UserDefineDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineDetailID: Scalars['String'];
  PersonnelID?: Maybe<Scalars['String']>;
  UserDefineID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  UserDefine?: Maybe<UserDefineEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type UserDefineDetailInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  UserDefineDetailID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
  UserDefineID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type UserDefineEpfPolicyDetailEntity = InterfaceBase & {
  __typename?: 'UserDefineEPFPolicyDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyDetailID: Scalars['String'];
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate?: Maybe<Scalars['String']>;
  EmployeeRate?: Maybe<Scalars['String']>;
  EmployerRateType?: Maybe<Scalars['String']>;
  EmployeeRateType?: Maybe<Scalars['String']>;
  IsEmployerRateInUse?: Maybe<Scalars['Boolean']>;
  IsEmployeeRateInUse?: Maybe<Scalars['Boolean']>;
  EffectiveMonth?: Maybe<Scalars['String']>;
  UserDefineEPFPolicy?: Maybe<UserDefineEpfPolicyEntity>;
};

export type UserDefineEpfPolicyDetailInput = {
  UserDefineEPFPolicyDetaiID?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  EmployerRate?: Maybe<Scalars['String']>;
  EmployeeRate?: Maybe<Scalars['String']>;
  EmployerRateType?: Maybe<Scalars['String']>;
  EmployeeRateType?: Maybe<Scalars['String']>;
  IsEmployerRateInUse?: Maybe<Scalars['Boolean']>;
  IsEmployeeRateInUse?: Maybe<Scalars['Boolean']>;
  EffectiveMonth?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UserDefineEpfPolicyEntity = InterfaceBase & {
  __typename?: 'UserDefineEPFPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyID: Scalars['String'];
  Title: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyDetail?: Maybe<Array<UserDefineEpfPolicyDetailEntity>>;
  UserDefineEPFPolicyMatrix?: Maybe<Array<UserDefineEpfPolicyMatrixEntity>>;
};

export type UserDefineEpfPolicyInput = {
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UserDefineEpfPolicyMatrixEntity = InterfaceBase & {
  __typename?: 'UserDefineEPFPolicyMatrixEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyMatrixID: Scalars['String'];
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  UserDefineEPFPolicy?: Maybe<UserDefineEpfPolicyEntity>;
};

export type UserDefineEpfPolicyMatrixInput = {
  UserDefineEPFPolicyMatrixID?: Maybe<Scalars['String']>;
  UserDefineEPFPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ConditionList?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type UserDefineEntity = InterfaceBase & {
  __typename?: 'UserDefineEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserDefineID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  UserDefineDetails?: Maybe<Array<UserDefineDetailEntity>>;
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  employeeID?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isEmployeeAuth?: Maybe<Scalars['Boolean']>;
  deviceRegistrationToken?: Maybe<Scalars['String']>;
  lastAccessDate?: Maybe<Scalars['DateTime']>;
  helpdeskAssignee?: Maybe<Scalars['Boolean']>;
  NavToken?: Maybe<Scalars['String']>;
};

export type UserInfo = {
  StaffID: Scalars['String'];
  UserName: Scalars['String'];
  StaffName: Scalars['String'];
  Dept: Scalars['String'];
  CostCenter: Scalars['String'];
  EmailID: Scalars['String'];
  Status: Scalars['String'];
  Shop: Scalars['String'];
  StaffCode: Scalars['String'];
  StaffType: Scalars['String'];
  UserRank: Scalars['String'];
  UserJoindDate: Scalars['DateTime'];
  UserStatus: Scalars['String'];
  TranStatus: Scalars['String'];
  LastUpdatedDate: Scalars['DateTime'];
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
  isEmployee?: Maybe<Scalars['Boolean']>;
  isEmployeeAuth?: Maybe<Scalars['Boolean']>;
  employeeID?: Maybe<Scalars['String']>;
};

export type UserListInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type UserLogInput = {
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
  columnName?: Maybe<Scalars['String']>;
  oldColumnValue?: Maybe<Scalars['String']>;
  newColumnValue?: Maybe<Scalars['String']>;
};

export type UserLogs = {
  __typename?: 'UserLogs';
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
  columnName?: Maybe<Scalars['String']>;
  oldColumnValue?: Maybe<Scalars['String']>;
  newColumnValue?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
  roleID?: Maybe<Scalars['String']>;
  employeeID?: Maybe<Scalars['String']>;
};

export type VaccinationInput = {
  vaccinationID?: Maybe<Scalars['String']>;
  vaccinationDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type VaccinationListItemOutput = {
  __typename?: 'VaccinationListItemOutput';
  vaccinationID?: Maybe<Scalars['String']>;
  vaccinationDate?: Maybe<Scalars['DateTime']>;
  vaccinationAttachedment?: Maybe<DocumentsEntity>;
};

export type VaccinationRecordInput = {
  DocumentID?: Maybe<Scalars['String']>;
  vaccinationID?: Maybe<Scalars['String']>;
  vaccinationDate?: Maybe<Scalars['DateTime']>;
  vaccinationAttachedment?: Maybe<DocumentsInput>;
};

export type ValidateResult = {
  __typename?: 'ValidateResult';
  subscriptionaccountid?: Maybe<Scalars['String']>;
  datamodule?: Maybe<Scalars['String']>;
  datarow?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  rowno?: Maybe<Scalars['String']>;
  totalrecord?: Maybe<Scalars['String']>;
  exceltotalrecord?: Maybe<Scalars['String']>;
};

export type VerfiyClaimInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmt?: Maybe<Scalars['Float']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Fields?: Maybe<Scalars['JSON']>;
};

export type VerfiyPeClaimInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmt?: Maybe<Scalars['Float']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  ProjectTypeID?: Maybe<Scalars['String']>;
};

export type VerifiedClaimDetail = {
  __typename?: 'VerifiedClaimDetail';
  FinanceApprovalStatus?: Maybe<ApprovalStatus>;
  FinanceAmount?: Maybe<Scalars['Float']>;
  FinanceRemark?: Maybe<Scalars['String']>;
  FinanceApprovalByID?: Maybe<Scalars['String']>;
  FinanceApprovalByName?: Maybe<Scalars['String']>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type VerifyApprovedClaimDetailInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  VerifyAmount?: Maybe<Scalars['Float']>;
  VerifyRemark?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type VerifyApprovedProjectExpenseClaimDetailInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  VerifyAmount?: Maybe<Scalars['Float']>;
  VerifyRemark?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  FromEmployeeID?: Maybe<Scalars['String']>;
  ToEmployeeID?: Maybe<Scalars['String']>;
};

export type VerifyCompany = {
  __typename?: 'VerifyCompany';
  Company?: Maybe<Array<ValidateResult>>;
  Department?: Maybe<Array<ValidateResult>>;
  JobGrade?: Maybe<Array<ValidateResult>>;
  RecruitmentSource?: Maybe<Array<ValidateResult>>;
};

export type VerifyEmployee = {
  __typename?: 'VerifyEmployee';
  Employee?: Maybe<Array<ValidateResult>>;
  JobInfo?: Maybe<Array<ValidateResult>>;
  Statutory?: Maybe<Array<ValidateResult>>;
  Dependent?: Maybe<Array<ValidateResult>>;
};

export type VerifyPayroll = {
  __typename?: 'VerifyPayroll';
  CutOff?: Maybe<Array<ValidateResult>>;
  Cycle?: Maybe<Array<ValidateResult>>;
  Bank?: Maybe<Array<ValidateResult>>;
  PayItem?: Maybe<Array<ValidateResult>>;
  EmpSalary?: Maybe<Array<ValidateResult>>;
  FixedAllowDec?: Maybe<Array<ValidateResult>>;
  History?: Maybe<Array<ValidateResult>>;
};

export type ViewPayrollHistoryDetailListingOutput = {
  __typename?: 'ViewPayrollHistoryDetailListingOutput';
  Description?: Maybe<Scalars['String']>;
  PayrollCycleId?: Maybe<Scalars['String']>;
  TotalEmployee?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  AdditionalEarnings?: Maybe<Scalars['Float']>;
  GrossPay?: Maybe<Scalars['Float']>;
  Deduction?: Maybe<Scalars['Float']>;
  NetPay?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployeeEPFVE?: Maybe<Scalars['Float']>;
  EmployeeSocso?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployerEPFVE?: Maybe<Scalars['Float']>;
  EmployerSocso?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployerHRDF?: Maybe<Scalars['Float']>;
};

export type ViewPayrollHistoryInput = {
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  OrgUnitID?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  PayrollCycleIDs?: Maybe<Array<Scalars['String']>>;
  StaffCategoryID?: Maybe<Array<Scalars['String']>>;
};

export type ViewPayrollHistorySummaryOutput = {
  __typename?: 'ViewPayrollHistorySummaryOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TotalPayrollCycle?: Maybe<Scalars['Float']>;
  Listing?: Maybe<Array<ViewPayrollHistoryDetailListingOutput>>;
};

export type WorkFlowApprovedStepEntity = InterfaceBase & {
  __typename?: 'WorkFlowApprovedStepEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  NotifiedDT?: Maybe<Scalars['DateTime']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlowStep: WorkFlowStepEntity;
  ApproverName?: Maybe<Scalars['String']>;
};

export type WorkFlowDetail = {
  __typename?: 'WorkFlowDetail';
  submitter_id?: Maybe<Scalars['String']>;
  submitter_name?: Maybe<Scalars['String']>;
  submitter_no?: Maybe<Scalars['String']>;
  WorkFlowCurrentApproversList?: Maybe<Array<Scalars['JSON']>>;
  WorkFlow?: Maybe<WorkFlowEntity>;
};

export type WorkFlowEntity = InterfaceBase & {
  __typename?: 'WorkFlowEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['DateTime']>;
  WorkFlowStatus?: Maybe<Scalars['String']>;
  WorkFlowStep?: Maybe<Array<WorkFlowStepEntity>>;
  SubmitterName?: Maybe<Scalars['String']>;
  ApproverPendingList?: Maybe<Array<Scalars['JSON']>>;
  GroupWorkFlowStep?: Maybe<Scalars['JSON']>;
};

export type WorkFlowInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['DateTime']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type WorkFlowStepEntity = InterfaceBase & {
  __typename?: 'WorkFlowStepEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  WorkFlowID?: Maybe<Scalars['String']>;
  ApproverRoleID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  reporting_group_id?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['DateTime']>;
  StepNo?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlow: WorkFlowEntity;
  WorkFlowApprovedStep?: Maybe<Array<WorkFlowApprovedStepEntity>>;
  RoleName?: Maybe<Scalars['String']>;
};

export type WorkFlowStepInput = {
  ApproverID?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  UserList?: Maybe<Array<UserListInput>>;
  Predicate?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
  ReportingGroupID?: Maybe<Scalars['String']>;
};

export type WorkGroupShiftEntity = InterfaceBase & {
  __typename?: 'WorkGroupShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  WorkGroupShiftID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
};

export type WorkdayAdjustmentEntity = InterfaceBase & {
  __typename?: 'WorkdayAdjustmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  WorkdayAdjustmentID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  WorkDays: Scalars['Float'];
  AdjustedWorkDays: Scalars['Float'];
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type WorkdayAdjustmentInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  WorkDays?: Maybe<Scalars['Float']>;
  AdjustedWorkDays?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum WorkflowLogStatus {
  Initiated = 'Initiated',
  Pending = 'Pending',
  Submitted = 'Submitted',
  Approved = 'Approved',
  SendBack = 'Send_Back',
  ReInitiated = 'ReInitiated',
  RevertDraft = 'RevertDraft',
  Rejected = 'Rejected'
}

export type YecfListingOutput = {
  __typename?: 'YECFListingOutput';
  HeaderStatus?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  NoOfPendingApprovalApplication?: Maybe<Scalars['Float']>;
  LeaveItem?: Maybe<LeaveItemEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
  Employee?: Maybe<EmployeeEntity>;
  InUse?: Maybe<Scalars['Boolean']>;
};

export type YearEndCarryForwardOutput = {
  __typename?: 'YearEndCarryForwardOutput';
  HeaderStatus?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveTypes?: Maybe<Array<LeaveIndentity>>;
};

export type YearlyError = {
  __typename?: 'YearlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type YearlyHolidaysGroup = {
  __typename?: 'YearlyHolidaysGroup';
  PeriodYear?: Maybe<Scalars['Float']>;
  RestDay?: Maybe<Array<RestOffDayOutput>>;
  OffDay?: Maybe<Array<RestOffDayOutput>>;
  CompanyDelaredHoliday?: Maybe<Array<CompanyDelaredHolidayEntity>>;
  Holiday?: Maybe<Array<HolidayEntity>>;
  CompanyOffDay?: Maybe<Array<CompanyOffDayEntity>>;
};

export type ZakatContributionEntity = InterfaceBase & {
  __typename?: 'ZakatContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ZakatStateID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  StartPeriod?: Maybe<PayPeriodEntity>;
  EndPeriod?: Maybe<PayPeriodEntity>;
  Employee?: Maybe<EmployeeEntity>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Percentage?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  IsDataImport?: Maybe<Scalars['Boolean']>;
};

export type ZakatContributionInput = {
  ID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['String']>;
  EndMonth?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Percentage?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ZakatStateID?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ActionStatus = {
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};

export type Approval_Log = {
  ApprovalStepNo?: Maybe<Scalars['Float']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status: Request_Approval_Status;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export enum Approval_Status {
  Initial = 'Initial',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Disbursed = 'Disbursed',
  Completed = 'Completed'
}

export type ArrMesg = {
  __typename?: 'arrMesg';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
  JoinedDateError?: Maybe<Array<JoinedDateError>>;
  ClaimTrxError?: Maybe<Array<ClaimTrxError>>;
  BiYearlyError?: Maybe<Array<BiYearlyError>>;
  YearlyError?: Maybe<Array<YearlyError>>;
  MonthlyError?: Maybe<Array<MonthlyError>>;
  LifeTimeError?: Maybe<Array<LifeTimeError>>;
};

export enum Cost_Method {
  PerPax = 'Per_Pax',
  LumpSum = 'Lump_Sum'
}

export type DropDownItem = {
  __typename?: 'dropDownItem';
  Value?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export enum Duration_Type {
  Hour = 'Hour',
  Day = 'Day'
}

export enum Event_Status {
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Ongoing = 'Ongoing',
  Confirmed = 'Confirmed',
  Closed = 'Closed',
  Open = 'Open',
  Planned = 'Planned'
}

export enum Layout_Format {
  Dropdown = 'Dropdown',
  List = 'List'
}

export type Leave_Approval_PolicyEntity = InterfaceBase & {
  __typename?: 'leave_approval_policyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  approval_policy_id?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  condition_list?: Maybe<Scalars['JSON']>;
  leave_type?: Maybe<Scalars['JSON']>;
  leave_days?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  ApprovalPolicy?: Maybe<ApprovalPolicyEntity>;
};

export type Leave_Approval_PolicyInput = {
  id?: Maybe<Scalars['String']>;
  approval_policy_id?: Maybe<Scalars['String']>;
  condition_list?: Maybe<Array<Scalars['JSON']>>;
  leave_type?: Maybe<Array<Scalars['JSON']>>;
  leave_days?: Maybe<Array<Scalars['JSON']>>;
  title?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum Participation_Status {
  Submitted = 'Submitted',
  Confirmed = 'Confirmed',
  Attended = 'Attended',
  Withdraw = 'Withdraw',
  Withdrawn = 'Withdrawn',
  Rejected = 'Rejected'
}

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  confirmedPassword?: Maybe<Scalars['String']>;
};

export type Process_LeaveDetails_ImportEntity = InterfaceBase & {
  __typename?: 'process_LeaveDetails_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessLeaveDetailsID: Scalars['String'];
  ProcessLeaveHeaderID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  SessionType?: Maybe<SessionType>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  NumberOfDay?: Maybe<Scalars['Float']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ProcessLeaveHeader?: Maybe<Process_LeaveHeader_ImportEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
};

export type Process_LeaveHeader_ImportEntity = InterfaceBase & {
  __typename?: 'process_LeaveHeader_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessLeaveHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  LeaveReasonID?: Maybe<Scalars['String']>;
  DiseaseCodeID?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  LeaveReason?: Maybe<LeaveReasonEntity>;
  DiseaseCode?: Maybe<LeaveDiseaseEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
  BlockLeaveID?: Maybe<Scalars['String']>;
  BlockLeave?: Maybe<BlockLeaveEntity>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveHeader?: Maybe<LeaveHeaderEntity>;
};

export type Process_Multicurrencydetails_ImportEntity = InterfaceBase & {
  __typename?: 'process_multicurrencydetails_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessMultiCurrencyDetailsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ProcessMultiCurrencyID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<Process_Multicurrencysetup_ImportEntity>;
};

export type Process_Multicurrencysetup_ImportEntity = InterfaceBase & {
  __typename?: 'process_multicurrencysetup_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ProcessMultiCurrencyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CurrencyCode?: Maybe<Scalars['String']>;
  CurrencyName?: Maybe<Scalars['String']>;
  SoftDelete?: Maybe<Status>;
  Index?: Maybe<Scalars['Float']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  MultiCurrencyDetails?: Maybe<Array<Process_Multicurrencydetails_ImportEntity>>;
};

export type Process_Overtime_ImportEntity = {
  __typename?: 'process_overtime_importEntity';
  ProcessOvertimeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Index?: Maybe<Scalars['Float']>;
  Duration?: Maybe<Scalars['Float']>;
  HasDutyRoster?: Maybe<Scalars['Boolean']>;
  OvertimeReason?: Maybe<OvertimeReasonEntity>;
  Employee?: Maybe<EmployeeEntity>;
  ExcelFileName?: Maybe<Scalars['String']>;
};

export enum Process_Status {
  Draft = 'Draft',
  Initiated = 'Initiated',
  Finalised = 'Finalised'
}

export type Process_Status_Log = {
  ApprovalStepNo?: Maybe<Scalars['Float']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApproverEmployeeNo?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Status: Process_Status;
  WorkflowDT?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export enum Provider_Type {
  Internal = 'Internal',
  External = 'External'
}

export enum Recertification_Frequency_Type {
  Day = 'Day',
  Months = 'Months',
  Year = 'Year'
}

export enum Registration_Method {
  TrainingPlan = 'training_plan',
  Nominated = 'nominated',
  RegisteredByHr = 'registered_by_hr'
}

export enum Request_Approval_Status {
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Withdraw = 'Withdraw',
  Cancelled = 'Cancelled'
}

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export enum Survey_Type {
  ObjectiveTest = 'Objective_Test',
  ObjectiveEvaluation = 'Objective_Evaluation',
  ObjectiveCount = 'Objective_Count',
  Subjective = 'Subjective',
  YesOrNo = 'Yes_Or_No',
  Text = 'Text'
}

export type Temp_Leave_TakenEntity = InterfaceBase & {
  __typename?: 'temp_leave_takenEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  file_name: Scalars['String'];
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  leave_type?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  session?: Maybe<Scalars['String']>;
  total_leave?: Maybe<Scalars['Float']>;
  leave_reason?: Maybe<Scalars['String']>;
  disease_code?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  is_short_period_notice?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Float']>;
};

export type Temp_Multicurrencydetails_ImportEntity = InterfaceBase & {
  __typename?: 'temp_multicurrencydetails_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  details_id: Scalars['String'];
  subscriptionaccountID?: Maybe<Scalars['String']>;
  setup_id?: Maybe<Scalars['String']>;
  exchange_rate?: Maybe<Scalars['Float']>;
  effective_date?: Maybe<Scalars['DateTime']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  MultiCurrency?: Maybe<Process_Multicurrencysetup_ImportEntity>;
};

export type Temp_Multicurrencysetup_ImportEntity = InterfaceBase & {
  __typename?: 'temp_multicurrencysetup_importEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  currencyid: Scalars['String'];
  subscriptionaccountid?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  currency_name?: Maybe<Scalars['String']>;
  created_dt?: Maybe<Scalars['DateTime']>;
  effective_date?: Maybe<Scalars['DateTime']>;
  exchange_rate?: Maybe<Scalars['Float']>;
  created_by?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  modified_dt?: Maybe<Scalars['DateTime']>;
  modified_by?: Maybe<Scalars['String']>;
  soft_delete?: Maybe<Status>;
  index?: Maybe<Scalars['Float']>;
  file_name?: Maybe<Scalars['String']>;
  MultiCurrencyDetails?: Maybe<Array<Temp_Multicurrencydetails_ImportEntity>>;
};

export type Temp_OvertimeEntity = {
  __typename?: 'temp_overtimeEntity';
  id?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  employee_name?: Maybe<Scalars['String']>;
  employee_no?: Maybe<Scalars['String']>;
  work_date?: Maybe<Scalars['DateTime']>;
  start_time?: Maybe<Scalars['DateTime']>;
  end_time?: Maybe<Scalars['DateTime']>;
  overtime_reason?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Float']>;
};

export enum Training_Policy_Type {
  RequestPolicy = 'Request_Policy',
  NominationPolicy = 'Nomination_Policy',
  SelfRegistrationPolicy = 'Self_Registration_Policy',
  WithdrawalPolicy = 'Withdrawal_Policy'
}

export type UpdateHelpdeskAssigneeInput = {
  ID?: Maybe<Scalars['String']>;
  helpdeskAssignee?: Maybe<Scalars['Boolean']>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export enum Valid_For {
  Evaluation = 'Evaluation',
  Survey = 'Survey',
  Test = 'Test'
}

export type EmployeeProfileInsertUpdateMutationVariables = Exact<{
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
}>;


export type EmployeeProfileInsertUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeProfileInsertUpdate'>
);

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'userName' | 'email' | 'contactNo' | 'department' | 'accountID' | 'jobTitle' | 'superUser' | 'lastestAccessedEntity' | 'roleID' | 'isEmployee' | 'isEmployeeAuth' | 'employeeID' | 'helpdeskAssignee'>
  ) }
);

export type IsSuperUserEmployeeQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type IsSuperUserEmployeeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsSuperUserEmployee'>
);

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  loginId: Scalars['String'];
  softwareCode: SoftwareCode;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken' | 'isEmployeeSuperUser'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'accountID' | 'ID' | 'name' | 'superUser' | 'employeeID'>
    ) }
  ) }
);

export type EmployeeAppLoginMutationVariables = Exact<{
  loginId: Scalars['String'];
  password: Scalars['String'];
}>;


export type EmployeeAppLoginMutation = (
  { __typename?: 'Mutation' }
  & { employeeAppLogin: (
    { __typename?: 'EmployeeAppLoginResponse' }
    & Pick<EmployeeAppLoginResponse, 'accessToken'>
    & { employee?: Maybe<(
      { __typename?: 'EmployeeResponse' }
      & Pick<EmployeeResponse, 'subscriptionAccountID' | 'employeeID' | 'employeeNo' | 'name' | 'email' | 'mobileNo'>
    )> }
  ) }
);

export type LoggedInEmployeeProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInEmployeeProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInEmployeeProfile: (
    { __typename?: 'EmployeeResponse' }
    & Pick<EmployeeResponse, 'subscriptionAccountID' | 'employeeID' | 'employeeNo' | 'companyID' | 'name' | 'email' | 'mobileNo' | 'LastestAccessedEntity' | 'SuperUserID' | 'Salutation' | 'contactID'>
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ChangeEmployeePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangeEmployeePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeEmployeePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ForgotEmployeePasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotEmployeePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotEmployeePassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type ResetEmployeePasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetEmployeePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetEmployeePassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type EmployeeLogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type EmployeeLogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'employeeLogout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type UserProfilePicQueryVariables = Exact<{
  UserID: Scalars['String'];
  IsEmployee?: Maybe<Scalars['Boolean']>;
}>;


export type UserProfilePicQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'UserProfilePic'>
);

export type GetEmployeeKpiTempleteLatestVersionQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  Year: Scalars['Float'];
  KPIPlanningID?: Maybe<Scalars['String']>;
}>;


export type GetEmployeeKpiTempleteLatestVersionQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeKPITempleteLatestVersion: (
    { __typename?: 'EmployeeKPIHeaderEntity' }
    & Pick<EmployeeKpiHeaderEntity, 'EmployeeKPIHeaderID' | 'EmployeeID' | 'CompanyID' | 'Year' | 'Version' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { EmployeeKPICategory?: Maybe<Array<(
      { __typename?: 'EmployeeKPICategoryEntity' }
      & Pick<EmployeeKpiCategoryEntity, 'EmployeeKPICategoryID' | 'AppraisalCategoryID' | 'Weightage'>
      & { EmployeeKPIDetail?: Maybe<Array<(
        { __typename?: 'EmployeeKPIDetailEntity' }
        & Pick<EmployeeKpiDetailEntity, 'EmployeeKPIDetailID' | 'AppraisalCategoryItemID' | 'Title' | 'Description' | 'Weightage'>
        & { EmployeeKPIDetailRating?: Maybe<Array<(
          { __typename?: 'EmployeeKPIDetailRatingEntity' }
          & Pick<EmployeeKpiDetailRatingEntity, 'EmployeeKPIDetailRatingID' | 'ScaleSequence' | 'Description'>
        )>> }
      )>> }
    )>> }
  ) }
);

export type GetAppraisalDefaultSettingByJobPositionQueryVariables = Exact<{
  JobPositionID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type GetAppraisalDefaultSettingByJobPositionQuery = (
  { __typename?: 'Query' }
  & { GetAppraisalDefaultSettingByJobPosition: (
    { __typename?: 'AppraisalTempleteByJobPositionEntity' }
    & Pick<AppraisalTempleteByJobPositionEntity, 'AppraisalTempleteByJobPositionID' | 'JobPositionID' | 'SubscriptionAccountID' | 'KPIWeightage' | 'CompetencyTempleteID' | 'CompetencyWeightage' | 'OthersWeightage' | 'OthersTempleteID'>
    & { KPITemplete?: Maybe<(
      { __typename?: 'AppraisalTempleteEntity' }
      & Pick<AppraisalTempleteEntity, 'AppraisalTempleteID' | 'Title' | 'Description' | 'TempleteType'>
      & { AppraisalTempleteCategory?: Maybe<Array<(
        { __typename?: 'AppraisalTempleteCategoryEntity' }
        & Pick<AppraisalTempleteCategoryEntity, 'AppraisalTempleteCategoryID' | 'AppraisalCategoryID' | 'Weightage'>
        & { AppraisalCategory?: Maybe<(
          { __typename?: 'AppraisalCategoryEntity' }
          & Pick<AppraisalCategoryEntity, 'Name' | 'AppraisalType'>
        )>, AppraisalTempleteCategoryItems?: Maybe<Array<(
          { __typename?: 'AppraisalTempleteCategoryItemEntity' }
          & Pick<AppraisalTempleteCategoryItemEntity, 'AppraisalTempleteCategoryItemID' | 'AppraisalCategoryItemID' | 'Weightage'>
          & { AppraisalCategoryItem?: Maybe<(
            { __typename?: 'AppraisalCategoryItemEntity' }
            & Pick<AppraisalCategoryItemEntity, 'Name' | 'Description' | 'AppraisalItemType' | 'QuantitativeType'>
          )> }
        )>> }
      )>> }
    )>, CompetencyTemplete?: Maybe<(
      { __typename?: 'AppraisalTempleteEntity' }
      & Pick<AppraisalTempleteEntity, 'AppraisalTempleteID' | 'Title' | 'Description' | 'TempleteType'>
      & { AppraisalTempleteCategory?: Maybe<Array<(
        { __typename?: 'AppraisalTempleteCategoryEntity' }
        & Pick<AppraisalTempleteCategoryEntity, 'AppraisalTempleteCategoryID' | 'AppraisalCategoryID' | 'Weightage'>
        & { AppraisalCategory?: Maybe<(
          { __typename?: 'AppraisalCategoryEntity' }
          & Pick<AppraisalCategoryEntity, 'Name' | 'AppraisalType'>
        )>, AppraisalTempleteCategoryItems?: Maybe<Array<(
          { __typename?: 'AppraisalTempleteCategoryItemEntity' }
          & Pick<AppraisalTempleteCategoryItemEntity, 'AppraisalTempleteCategoryItemID' | 'AppraisalCategoryItemID' | 'Weightage'>
          & { AppraisalCategoryItem?: Maybe<(
            { __typename?: 'AppraisalCategoryItemEntity' }
            & Pick<AppraisalCategoryItemEntity, 'Name' | 'Description' | 'AppraisalItemType' | 'QuantitativeType'>
          )> }
        )>> }
      )>> }
    )>, OthersTemplete?: Maybe<(
      { __typename?: 'AppraisalTempleteEntity' }
      & Pick<AppraisalTempleteEntity, 'AppraisalTempleteID' | 'Title' | 'Description' | 'TempleteType'>
      & { AppraisalTempleteCategory?: Maybe<Array<(
        { __typename?: 'AppraisalTempleteCategoryEntity' }
        & Pick<AppraisalTempleteCategoryEntity, 'AppraisalTempleteCategoryID' | 'AppraisalCategoryID' | 'Weightage'>
        & { AppraisalCategory?: Maybe<(
          { __typename?: 'AppraisalCategoryEntity' }
          & Pick<AppraisalCategoryEntity, 'Name' | 'AppraisalType'>
        )>, AppraisalTempleteCategoryItems?: Maybe<Array<(
          { __typename?: 'AppraisalTempleteCategoryItemEntity' }
          & Pick<AppraisalTempleteCategoryItemEntity, 'AppraisalTempleteCategoryItemID' | 'AppraisalCategoryItemID' | 'Weightage'>
          & { AppraisalCategoryItem?: Maybe<(
            { __typename?: 'AppraisalCategoryItemEntity' }
            & Pick<AppraisalCategoryItemEntity, 'Name' | 'Description' | 'AppraisalItemType' | 'QuantitativeType'>
          )> }
        )>> }
      )>> }
    )>, AppraisalSchedule?: Maybe<(
      { __typename?: 'AppraisalScheduleHeaderEntity' }
      & Pick<AppraisalScheduleHeaderEntity, 'AppraisalScheduleHeaderID' | 'Title' | 'Frequency'>
      & { AppraisalScheduleDetails?: Maybe<Array<(
        { __typename?: 'AppraisalScheduleDetailEntity' }
        & Pick<AppraisalScheduleDetailEntity, 'Sequence' | 'Description' | 'StartDate' | 'EndDate'>
      )>> }
    )> }
  ) }
);

export type GetCompanyAppraisalPolicyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetCompanyAppraisalPolicyQuery = (
  { __typename?: 'Query' }
  & { GetCompanyAppraisalPolicy: (
    { __typename?: 'AppraisalCompanySettingEntity' }
    & { AppraisalPolicy?: Maybe<(
      { __typename?: 'AppraisalPolicyEntity' }
      & Pick<AppraisalPolicyEntity, 'AppraisalPolicyID' | 'SuperiorWeightage' | 'SelfAssessmentWeightage' | 'Title'>
      & { ScaleRatingHeader?: Maybe<(
        { __typename?: 'ScaleRatingHeaderEntity' }
        & Pick<ScaleRatingHeaderEntity, 'ScaleRatingHeaderID' | 'Title' | 'Scale'>
        & { ScaleRatingDetails?: Maybe<Array<(
          { __typename?: 'ScaleRatingDetailEntity' }
          & Pick<ScaleRatingDetailEntity, 'ScaleRatingDetailID' | 'Description' | 'ScaleSequence'>
        )>> }
      )>, FinalRatingHeader?: Maybe<(
        { __typename?: 'FinalRatingHeaderEntity' }
        & Pick<FinalRatingHeaderEntity, 'FinalRatingHeaderID' | 'Title' | 'Scale'>
        & { FinalRatingDetails?: Maybe<Array<(
          { __typename?: 'FinalRatingDetailEntity' }
          & Pick<FinalRatingDetailEntity, 'FinalRatingDetailID' | 'Description' | 'ScaleSequence' | 'FinalRating' | 'From' | 'To'>
        )>> }
      )> }
    )> }
  ) }
);

export type AppraisalCategoryItemListingAllQueryVariables = Exact<{ [key: string]: never; }>;


export type AppraisalCategoryItemListingAllQuery = (
  { __typename?: 'Query' }
  & { AppraisalCategoryItemListingAll: Array<(
    { __typename?: 'AppraisalCategoryItemEntity' }
    & Pick<AppraisalCategoryItemEntity, 'AppraisalCategoryItemID' | 'AppraisalCategoryID' | 'Name' | 'Description' | 'AppraisalItemType' | 'QuantitativeType' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type AppraisalPolicyListingQueryVariables = Exact<{ [key: string]: never; }>;


export type AppraisalPolicyListingQuery = (
  { __typename?: 'Query' }
  & { AppraisalPolicyListing: Array<(
    { __typename?: 'AppraisalPolicyEntity' }
    & Pick<AppraisalPolicyEntity, 'Title' | 'AppraisalPolicyID' | 'SubscriptionAccountID' | 'ScaleRatingHeaderID' | 'SuperiorWeightage' | 'SelfAssessmentWeightage' | 'ModifiedDT' | 'isInUse'>
    & { ScaleRatingHeader?: Maybe<(
      { __typename?: 'ScaleRatingHeaderEntity' }
      & Pick<ScaleRatingHeaderEntity, 'ScaleRatingHeaderID' | 'Title' | 'Scale'>
      & { ScaleRatingDetails?: Maybe<Array<(
        { __typename?: 'ScaleRatingDetailEntity' }
        & Pick<ScaleRatingDetailEntity, 'ScaleRatingDetailID' | 'Description' | 'ScaleSequence'>
      )>> }
    )>, FinalRatingHeader?: Maybe<(
      { __typename?: 'FinalRatingHeaderEntity' }
      & Pick<FinalRatingHeaderEntity, 'FinalRatingHeaderID' | 'Title' | 'Scale'>
      & { FinalRatingDetails?: Maybe<Array<(
        { __typename?: 'FinalRatingDetailEntity' }
        & Pick<FinalRatingDetailEntity, 'FinalRatingDetailID' | 'Description' | 'ScaleSequence' | 'FinalRating' | 'From' | 'To'>
      )>> }
    )> }
  )> }
);

export type AppraisalCategoryListingAllQueryVariables = Exact<{ [key: string]: never; }>;


export type AppraisalCategoryListingAllQuery = (
  { __typename?: 'Query' }
  & { AppraisalCategoryListingAll: Array<(
    { __typename?: 'AppraisalCategoryEntity' }
    & Pick<AppraisalCategoryEntity, 'AppraisalCategoryID' | 'Name' | 'AppraisalType' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { AppraisalCategoryItems?: Maybe<Array<(
      { __typename?: 'AppraisalCategoryItemEntity' }
      & Pick<AppraisalCategoryItemEntity, 'AppraisalCategoryItemID' | 'AppraisalCategoryID' | 'Name' | 'Description' | 'AppraisalItemType' | 'QuantitativeType' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>> }
  )> }
);

export type GetEmployeeAppraisalHeaderQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  PeriodYear: Scalars['String'];
}>;


export type GetEmployeeAppraisalHeaderQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeAppraisalHeader: Array<(
    { __typename?: 'EmployeeAppraisalHeaderEntity' }
    & Pick<EmployeeAppraisalHeaderEntity, 'EmployeeAppraisalHeaderID' | 'AppraiseeType' | 'EmployeeID' | 'AppraiserID' | 'CompanyID' | 'Date' | 'Remark' | 'KPIPlanningID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { EmployeeAppraisalDetails?: Maybe<Array<(
      { __typename?: 'EmployeeAppraisalDetailEntity' }
      & Pick<EmployeeAppraisalDetailEntity, 'EmployeeAppraisalDetailID' | 'EmployeeKPIDetailID' | 'AppraisalCategoryItemID' | 'Rating' | 'Remark' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
      )> }
    )>> }
  )> }
);

export type SubmitAppraisalMutationVariables = Exact<{
  Input: EmployeeAppraisalHeaderInput;
}>;


export type SubmitAppraisalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitAppraisal'>
);

export type EmployeeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
}>;


export type EmployeeListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'SubscriptionUserID' | 'EmployeeNo' | 'EmploymentType' | 'JoinedReferenceDate' | 'JoinedDate' | 'ConfirmedDate' | 'Probation' | 'Status' | 'FormStatus' | 'OrgUnitID' | 'DesignationID' | 'ResignedDate' | 'JobGradeID'>
    & { EmployeeSalaryLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'MovementType' | 'FromSalary' | 'ToSalary' | 'excelfilename' | 'EffectiveDate' | 'SalaryAdjustmentAmount' | 'Remark'>
    )>>, Supervisor?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, Division?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description' | 'ParentID'>
    )>, EmployeeSubordinates: Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName'>
      )> }
    )> }
  )> }
);

export type EmployeeListingCountQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
}>;


export type EmployeeListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeListingCount'>
);

export type GetAppraisalCompanySettingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetAppraisalCompanySettingQuery = (
  { __typename?: 'Query' }
  & { GetAppraisalCompanySetting: (
    { __typename?: 'AppraisalCompanySettingEntity' }
    & Pick<AppraisalCompanySettingEntity, 'ID' | 'CompanyID' | 'AppraisalPolicyID' | 'AppraisalTempleteID' | 'AppraisalApprovalProcessID' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  ) }
);

export type AppraisalCompanySettingSubmitMutationVariables = Exact<{
  input: AppraisalCompanySettingInput;
}>;


export type AppraisalCompanySettingSubmitMutation = (
  { __typename?: 'Mutation' }
  & { AppraisalCompanySettingSubmit: (
    { __typename?: 'AppraisalCompanySettingEntity' }
    & Pick<AppraisalCompanySettingEntity, 'ID'>
  ) }
);

export type GetCompanyAppraisalTempleteQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetCompanyAppraisalTempleteQuery = (
  { __typename?: 'Query' }
  & { GetCompanyAppraisalTemplete: Array<(
    { __typename?: 'AppraisalTempleteByJobPositionEntity' }
    & Pick<AppraisalTempleteByJobPositionEntity, 'AppraisalTempleteByJobPositionID' | 'JobPositionID' | 'SubscriptionAccountID' | 'KPIWeightage' | 'CompetencyWeightage' | 'OthersWeightage'>
    & { KPITemplete?: Maybe<(
      { __typename?: 'AppraisalTempleteEntity' }
      & Pick<AppraisalTempleteEntity, 'AppraisalTempleteID' | 'Title' | 'Description' | 'TempleteType'>
      & { AppraisalTempleteCategory?: Maybe<Array<(
        { __typename?: 'AppraisalTempleteCategoryEntity' }
        & Pick<AppraisalTempleteCategoryEntity, 'AppraisalTempleteCategoryID' | 'AppraisalCategoryID' | 'Weightage'>
        & { AppraisalTempleteCategoryItems?: Maybe<Array<(
          { __typename?: 'AppraisalTempleteCategoryItemEntity' }
          & Pick<AppraisalTempleteCategoryItemEntity, 'AppraisalTempleteCategoryItemID' | 'AppraisalCategoryItemID' | 'Weightage'>
        )>> }
      )>> }
    )>, CompetencyTemplete?: Maybe<(
      { __typename?: 'AppraisalTempleteEntity' }
      & Pick<AppraisalTempleteEntity, 'AppraisalTempleteID' | 'Title' | 'Description' | 'TempleteType'>
      & { AppraisalTempleteCategory?: Maybe<Array<(
        { __typename?: 'AppraisalTempleteCategoryEntity' }
        & Pick<AppraisalTempleteCategoryEntity, 'AppraisalTempleteCategoryID' | 'AppraisalCategoryID' | 'Weightage'>
        & { AppraisalTempleteCategoryItems?: Maybe<Array<(
          { __typename?: 'AppraisalTempleteCategoryItemEntity' }
          & Pick<AppraisalTempleteCategoryItemEntity, 'AppraisalTempleteCategoryItemID' | 'AppraisalCategoryItemID' | 'Weightage'>
        )>> }
      )>> }
    )>, OthersTemplete?: Maybe<(
      { __typename?: 'AppraisalTempleteEntity' }
      & Pick<AppraisalTempleteEntity, 'AppraisalTempleteID' | 'Title' | 'Description' | 'TempleteType'>
      & { AppraisalTempleteCategory?: Maybe<Array<(
        { __typename?: 'AppraisalTempleteCategoryEntity' }
        & Pick<AppraisalTempleteCategoryEntity, 'AppraisalTempleteCategoryID' | 'AppraisalCategoryID' | 'Weightage'>
        & { AppraisalTempleteCategoryItems?: Maybe<Array<(
          { __typename?: 'AppraisalTempleteCategoryItemEntity' }
          & Pick<AppraisalTempleteCategoryItemEntity, 'AppraisalTempleteCategoryItemID' | 'AppraisalCategoryItemID' | 'Weightage'>
        )>> }
      )>> }
    )>, AppraisalSchedule?: Maybe<(
      { __typename?: 'AppraisalScheduleHeaderEntity' }
      & Pick<AppraisalScheduleHeaderEntity, 'AppraisalScheduleHeaderID' | 'Title' | 'Frequency'>
      & { AppraisalScheduleDetails?: Maybe<Array<(
        { __typename?: 'AppraisalScheduleDetailEntity' }
        & Pick<AppraisalScheduleDetailEntity, 'Sequence' | 'Description' | 'StartDate' | 'EndDate'>
      )>> }
    )> }
  )> }
);

export type AppraisalPolicyInsertMutationVariables = Exact<{
  input: AppraisalPolicyInput;
}>;


export type AppraisalPolicyInsertMutation = (
  { __typename?: 'Mutation' }
  & { AppraisalPolicyInsert: (
    { __typename?: 'AppraisalPolicyEntity' }
    & Pick<AppraisalPolicyEntity, 'AppraisalPolicyID'>
  ) }
);

export type AppraisalPolicyDeleteMutationVariables = Exact<{
  AppraisalPolicyID: Scalars['String'];
}>;


export type AppraisalPolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AppraisalPolicyDelete'>
);

export type AppraisalTemplateByJobPositionListingQueryVariables = Exact<{ [key: string]: never; }>;


export type AppraisalTemplateByJobPositionListingQuery = (
  { __typename?: 'Query' }
  & { AppraisalTemplateByJobPositionListing: Array<(
    { __typename?: 'AppraisalTemplateHeaderEntity' }
    & Pick<AppraisalTemplateHeaderEntity, 'AppraisalTemplateHeaderID' | 'isInUse' | 'Title' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { AppraisalTempleteByJobPosition?: Maybe<Array<(
      { __typename?: 'AppraisalTempleteByJobPositionEntity' }
      & Pick<AppraisalTempleteByJobPositionEntity, 'AppraisalTempleteByJobPositionID' | 'JobPositionID' | 'KPIWeightage' | 'KPITempleteID' | 'CompetencyWeightage' | 'CompetencyTempleteID' | 'OthersWeightage' | 'OthersTempleteID' | 'AppraisalScheduleHeaderID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>> }
  )> }
);

export type AppraisalTemplateByJobPositionSubmitMutationVariables = Exact<{
  input: AppraisalTempleteByJobPositionHeaderInput;
}>;


export type AppraisalTemplateByJobPositionSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AppraisalTemplateByJobPositionSubmit'>
);

export type AppraisalScheduleListingQueryVariables = Exact<{ [key: string]: never; }>;


export type AppraisalScheduleListingQuery = (
  { __typename?: 'Query' }
  & { AppraisalScheduleListing: Array<(
    { __typename?: 'AppraisalScheduleHeaderEntity' }
    & Pick<AppraisalScheduleHeaderEntity, 'AppraisalScheduleHeaderID' | 'Title' | 'Frequency' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'isInUse'>
    & { AppraisalScheduleDetails?: Maybe<Array<(
      { __typename?: 'AppraisalScheduleDetailEntity' }
      & Pick<AppraisalScheduleDetailEntity, 'AppraisalScheduleDetailID' | 'Sequence' | 'Description' | 'StartDate' | 'EndDate' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>>, AppraisalTempleteByJobPosition?: Maybe<Array<(
      { __typename?: 'AppraisalTempleteByJobPositionEntity' }
      & Pick<AppraisalTempleteByJobPositionEntity, 'AppraisalTempleteByJobPositionID' | 'JobPositionID'>
    )>> }
  )> }
);

export type AppraisalScheduleSubmitMutationVariables = Exact<{
  input: AppraisalScheduleHeaderInput;
}>;


export type AppraisalScheduleSubmitMutation = (
  { __typename?: 'Mutation' }
  & { AppraisalScheduleSubmit: (
    { __typename?: 'AppraisalScheduleHeaderEntity' }
    & Pick<AppraisalScheduleHeaderEntity, 'AppraisalScheduleHeaderID'>
  ) }
);

export type ApprovalPolicyInsertMutationVariables = Exact<{
  ApprovalPolicyInput: ApprovalPolicyInput;
}>;


export type ApprovalPolicyInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApprovalPolicyInsert'>
);

export type ApprovalPolicyUpdateMutationVariables = Exact<{
  ApprovalPolicyInput: ApprovalPolicyInput;
}>;


export type ApprovalPolicyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApprovalPolicyUpdate'>
);

export type ApprovalPolicyDeleteMutationVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  ID: Scalars['String'];
}>;


export type ApprovalPolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApprovalPolicyDelete'>
);

export type ApprovalPolicyListQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  Category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
}>;


export type ApprovalPolicyListQuery = (
  { __typename?: 'Query' }
  & { ApprovalPolicyList: Array<(
    { __typename?: 'ApprovalPolicyEntity' }
    & Pick<ApprovalPolicyEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'ID' | 'SubscriptionAccountID' | 'Title' | 'Category'>
    & { ApprovalList: Array<(
      { __typename?: 'ApprovalListJson' }
      & Pick<ApprovalListJson, 'ID' | 'StepNo' | 'RoleID' | 'Predicate' | 'Amount' | 'Category' | 'RoleName' | 'EndIf' | 'Skip'>
    )> }
  )> }
);

export type ApprovalPolicyAssignmentUpdateMutationVariables = Exact<{
  ApprovalPolicyInput: ApprovalPolicyAssignmentInput;
}>;


export type ApprovalPolicyAssignmentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApprovalPolicyAssignmentUpdate'>
);

export type AppraisalCategoryInsertMutationVariables = Exact<{
  input: AppraisalCategoryInput;
}>;


export type AppraisalCategoryInsertMutation = (
  { __typename?: 'Mutation' }
  & { AppraisalCategoryInsert: (
    { __typename?: 'AppraisalCategoryEntity' }
    & Pick<AppraisalCategoryEntity, 'AppraisalCategoryID'>
  ) }
);

export type AppraisalCategoryUpdateMutationVariables = Exact<{
  input: AppraisalCategoryInput;
  AppraisalCategoryID: Scalars['String'];
}>;


export type AppraisalCategoryUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AppraisalCategoryUpdate'>
);

export type AppraisalCategoryDeleteMutationVariables = Exact<{
  AppraisalCategoryID: Scalars['String'];
}>;


export type AppraisalCategoryDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AppraisalCategoryDelete'>
);

export type AppraisalCategoryItemInsertMutationVariables = Exact<{
  input: AppraisalCategoryItemInput;
}>;


export type AppraisalCategoryItemInsertMutation = (
  { __typename?: 'Mutation' }
  & { AppraisalCategoryItemInsert: (
    { __typename?: 'AppraisalCategoryItemEntity' }
    & Pick<AppraisalCategoryItemEntity, 'AppraisalCategoryItemID'>
  ) }
);

export type AppraisalCategoryItemUpdateMutationVariables = Exact<{
  input: AppraisalCategoryItemInput;
  AppraisalCategoryItemID: Scalars['String'];
}>;


export type AppraisalCategoryItemUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AppraisalCategoryItemUpdate'>
);

export type AppraisalCategoryItemDeleteMutationVariables = Exact<{
  AppraisalCategoryItemID: Scalars['String'];
}>;


export type AppraisalCategoryItemDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AppraisalCategoryItemDelete'>
);

export type AppraisalTempleteListingQueryVariables = Exact<{
  AppraisalType: Scalars['String'];
}>;


export type AppraisalTempleteListingQuery = (
  { __typename?: 'Query' }
  & { AppraisalTempleteListing: Array<(
    { __typename?: 'AppraisalTempleteEntity' }
    & Pick<AppraisalTempleteEntity, 'AppraisalTempleteID' | 'Title' | 'Description' | 'TempleteType' | 'SubscriptionAccountID' | 'Status'>
    & { AppraisalTempleteCategory?: Maybe<Array<(
      { __typename?: 'AppraisalTempleteCategoryEntity' }
      & Pick<AppraisalTempleteCategoryEntity, 'AppraisalTempleteCategoryID' | 'AppraisalTempleteID' | 'AppraisalCategoryID' | 'Status' | 'Weightage' | 'SubscriptionAccountID'>
      & { AppraisalCategory?: Maybe<(
        { __typename?: 'AppraisalCategoryEntity' }
        & Pick<AppraisalCategoryEntity, 'Name'>
      )>, AppraisalTempleteCategoryItems?: Maybe<Array<(
        { __typename?: 'AppraisalTempleteCategoryItemEntity' }
        & Pick<AppraisalTempleteCategoryItemEntity, 'AppraisalTempleteCategoryItemID' | 'AppraisalCategoryItemID' | 'Status' | 'Weightage' | 'SubscriptionAccountID'>
        & { AppraisalCategoryItem?: Maybe<(
          { __typename?: 'AppraisalCategoryItemEntity' }
          & Pick<AppraisalCategoryItemEntity, 'Name' | 'Description' | 'AppraisalItemType' | 'QuantitativeType'>
        )> }
      )>> }
    )>> }
  )> }
);

export type SubmitAppraisalTempleteMutationVariables = Exact<{
  input: AppraisalTempleteInput;
}>;


export type SubmitAppraisalTempleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitAppraisalTemplete'>
);

export type TrainingAttendanceListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
}>;


export type TrainingAttendanceListingQuery = (
  { __typename?: 'Query' }
  & { TrainingAttendanceListing: Array<(
    { __typename?: 'TrainingAttendanceEntity' }
    & Pick<TrainingAttendanceEntity, 'ScheduledTrainingID' | 'SubscriptionAccountID' | 'CompanyID' | 'EmployeeID' | 'CheckInDate' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'OrgUnitID' | 'JoinedDate' | 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'DocumentID' | 'TotalTaxRelief' | 'FormStatus' | 'EmploymentType' | 'CreatedDT' | 'ModifiedDT'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )>, EmployeeStatutory?: Maybe<(
        { __typename?: 'EmployeeStatutoryEntity' }
        & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID'>
      )>, EmployeePrevContribution?: Maybe<(
        { __typename?: 'EmployeePrevContributionEntity' }
        & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
      )>, EmployeeDependents?: Maybe<Array<(
        { __typename?: 'EmployeeDependentsEntity' }
        & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID'>
      )>>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'DepartmentID' | 'DepartmentType' | 'ParentID' | 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )> }
  )> }
);

export type GetEmployeeTrainingRecordQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetEmployeeTrainingRecordQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeTrainingRecord: Array<(
    { __typename?: 'EmployeeAttendanceOutput' }
    & Pick<EmployeeAttendanceOutput, 'EmployeeID' | 'FinalTotalAttendanceHour'>
    & { CourseRecord?: Maybe<Array<(
      { __typename?: 'EmployeeCourseRecordAttendanceOutput' }
      & Pick<EmployeeCourseRecordAttendanceOutput, 'ScheduledTrainingID' | 'Name' | 'TotalAttendaceHour'>
    )>> }
  )> }
);

export type GetUserRolePermissionByRoleQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetUserRolePermissionByRoleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetUserRolePermissionByRole'>
);

export type SubmitPairedAttandanceRecordMutationVariables = Exact<{
  input: Array<AttendanceRecordPairedObj> | AttendanceRecordPairedObj;
  DataToDelete: Array<Scalars['String']> | Scalars['String'];
}>;


export type SubmitPairedAttandanceRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitPairedAttandanceRecord'>
);

export type GetAttendanceRecordPairedDataQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetAttendanceRecordPairedDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetAttendanceRecordPairedData'>
);

export type GetFinalizeAnalysisDataByEmployeeQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  EmployeeID: Scalars['String'];
}>;


export type GetFinalizeAnalysisDataByEmployeeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetFinalizeAnalysisDataByEmployee'>
);

export type GetFinalizeAnalysisDataQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetFinalizeAnalysisDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetFinalizeAnalysisData'>
);

export type GetClockPairingQueryVariables = Exact<{
  Overwrite: Scalars['Boolean'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  EmployeeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetClockPairingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetClockPairing'>
);

export type GetLatestAttandanceCalculationDateByCompanyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetLatestAttandanceCalculationDateByCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetLatestAttandanceCalculationDateByCompany'>
);

export type TrainingCostBudgetListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['String'];
}>;


export type TrainingCostBudgetListingQuery = (
  { __typename?: 'Query' }
  & { TrainingCostBudgetListing: Array<(
    { __typename?: 'TrainingBudgetEntity' }
    & Pick<TrainingBudgetEntity, 'TrainingBudgetID' | 'SubscriptionAccountID' | 'CompanyID' | 'OrgUnitID' | 'BudgetAmount' | 'Year' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type TrainingBudgetInputBulkInsertMutationVariables = Exact<{
  input: Array<TrainingBudgetInput> | TrainingBudgetInput;
}>;


export type TrainingBudgetInputBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TrainingBudgetInputBulkInsert'>
);

export type CompanyHierarchyListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CompanyHierarchyListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyHierarchyList'>
);

export type NewsReadListingQueryVariables = Exact<{
  EmployeeID?: Maybe<Scalars['String']>;
  NewsID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type NewsReadListingQuery = (
  { __typename?: 'Query' }
  & { NewsReadListing: Array<(
    { __typename?: 'NewsReadEntity' }
    & Pick<NewsReadEntity, 'NewsReadID' | 'NewsID' | 'EmployeeID' | 'ModifiedDT' | 'CreatedDT'>
  )> }
);

export type NewsReadInsertMutationVariables = Exact<{
  NewsReadInput: NewsReadInput;
}>;


export type NewsReadInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NewsReadInsert'>
);

export type BulletinListingQueryVariables = Exact<{
  NewsID: Scalars['String'];
}>;


export type BulletinListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'BulletinListing'>
);

export type EmployeeBusinessInsightSummaryQueryVariables = Exact<{
  input: EmployeeBusinessInsightListing;
}>;


export type EmployeeBusinessInsightSummaryQuery = (
  { __typename?: 'Query' }
  & { EmployeeBusinessInsightSummary: (
    { __typename?: 'BusinessInsightSummaryOutput' }
    & { Gender?: Maybe<Array<(
      { __typename?: 'BusinessInsightGroupingOutput' }
      & Pick<BusinessInsightGroupingOutput, 'groupName' | 'TotalEmp'>
    )>>, Race?: Maybe<Array<(
      { __typename?: 'BusinessInsightGroupingOutput' }
      & Pick<BusinessInsightGroupingOutput, 'groupName' | 'TotalEmp'>
    )>>, Religion?: Maybe<Array<(
      { __typename?: 'BusinessInsightGroupingOutput' }
      & Pick<BusinessInsightGroupingOutput, 'groupName' | 'TotalEmp'>
    )>>, Marital?: Maybe<Array<(
      { __typename?: 'BusinessInsightGroupingOutput' }
      & Pick<BusinessInsightGroupingOutput, 'groupName' | 'TotalEmp'>
    )>>, Age?: Maybe<Array<(
      { __typename?: 'BusinessInsightGroupingOutput' }
      & Pick<BusinessInsightGroupingOutput, 'groupName' | 'TotalEmp'>
    )>>, EmploymentType?: Maybe<Array<(
      { __typename?: 'BusinessInsightGroupingOutput' }
      & Pick<BusinessInsightGroupingOutput, 'groupName' | 'TotalEmp'>
    )>>, StaffTurnover?: Maybe<Array<(
      { __typename?: 'BusinessInsightGroupingOutput' }
      & Pick<BusinessInsightGroupingOutput, 'groupName' | 'TotalEmp'>
    )>>, JobGrade?: Maybe<Array<(
      { __typename?: 'BusinessInsightGroupingOutput' }
      & Pick<BusinessInsightGroupingOutput, 'groupName' | 'TotalEmp'>
    )>>, LengthOfService?: Maybe<Array<(
      { __typename?: 'BusinessInsightGroupingOutput' }
      & Pick<BusinessInsightGroupingOutput, 'groupName' | 'TotalEmp'>
    )>>, RecruitmentSource?: Maybe<Array<(
      { __typename?: 'BusinessInsightGroupingOutput' }
      & Pick<BusinessInsightGroupingOutput, 'groupName' | 'TotalEmp'>
    )>> }
  ) }
);

export type PayrollBusinessInsightSummaryQueryVariables = Exact<{
  input: EmployeeBusinessInsightListing;
}>;


export type PayrollBusinessInsightSummaryQuery = (
  { __typename?: 'Query' }
  & { PayrollBusinessInsightSummary: (
    { __typename?: 'BusinessInsightSummaryPayrollOutput' }
    & { StatutoryContribution?: Maybe<Array<(
      { __typename?: 'BusinessInsightPayrollStatutoryOutput' }
      & Pick<BusinessInsightPayrollStatutoryOutput, 'Month' | 'Year' | 'EmployerContribution' | 'EmployeeContribution'>
    )>>, SalaryOverhead?: Maybe<Array<(
      { __typename?: 'BusinessInsightPayrollSalaryOverheadOutput' }
      & Pick<BusinessInsightPayrollSalaryOverheadOutput, 'Year' | 'Month' | 'TotalEmp' | 'TotalGross' | 'TotalDeduction' | 'NetPay'>
    )>>, PayItems?: Maybe<Array<(
      { __typename?: 'BusinessInsightPayrollPayItemOutput' }
      & Pick<BusinessInsightPayrollPayItemOutput, 'Year' | 'Month' | 'NetAmount' | 'TotalEarning' | 'TotalOvertime' | 'TotalDeduction' | 'TotalNonpayable'>
    )>> }
  ) }
);

export type LeaveBiLeaveTakenListingQueryVariables = Exact<{
  input: EmployeeBusinessInsightListing;
}>;


export type LeaveBiLeaveTakenListingQuery = (
  { __typename?: 'Query' }
  & { LeaveBILeaveTakenListing: Array<(
    { __typename?: 'BusinessInsightLeaveTakenOutput' }
    & Pick<BusinessInsightLeaveTakenOutput, 'Year' | 'Month' | 'TotalAllLeave' | 'TotalAnnualLeave' | 'TotalSickLeave' | 'TotalHospitalizationLeave' | 'TotalMaternityLeave' | 'TotalCompassionateLeave' | 'TotalUnpaidLeave' | 'TotalReplacementLeave'>
  )> }
);

export type ClaimBiClaimTakenQueryVariables = Exact<{
  DivisionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ClaimTypeIDs: Array<Scalars['String']> | Scalars['String'];
  Year: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
}>;


export type ClaimBiClaimTakenQuery = (
  { __typename?: 'Query' }
  & { ClaimBIClaimTaken: Array<(
    { __typename?: 'BusinessInsightClaimTakenOuput' }
    & Pick<BusinessInsightClaimTakenOuput, 'Month' | 'Year'>
    & { ClaimTakenByLeaveType?: Maybe<Array<(
      { __typename?: 'ClaimTakenByLeaveType' }
      & Pick<ClaimTakenByLeaveType, 'ClaimTypeID' | 'ClaimTypeName' | 'ClaimTaken'>
    )>> }
  )> }
);

export type RequisitionTakenSummaryByTypeQueryVariables = Exact<{
  EntityIDs: Array<Scalars['String']> | Scalars['String'];
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type RequisitionTakenSummaryByTypeQuery = (
  { __typename?: 'Query' }
  & { RequisitionTakenSummaryByType: Array<(
    { __typename?: 'RequisitionTakenSummaryByTypeOutput' }
    & Pick<RequisitionTakenSummaryByTypeOutput, 'RequisitionType' | 'TotalRequisitionCount' | 'TotalRequisitionAmount' | 'Percentage'>
  )> }
);

export type TopRequisitionTakerQueryVariables = Exact<{
  RequisitionType: Array<RequisitionType> | RequisitionType;
  EntityIDs: Array<Scalars['String']> | Scalars['String'];
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type TopRequisitionTakerQuery = (
  { __typename?: 'Query' }
  & { TopRequisitionTaker: Array<(
    { __typename?: 'TopRequisitionTakerOutput' }
    & Pick<TopRequisitionTakerOutput, 'RequisitionTaken'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )> }
);

export type CompanyBusinessInsightQueryVariables = Exact<{
  input: EmployeeBusinessInsightListing;
}>;


export type CompanyBusinessInsightQuery = (
  { __typename?: 'Query' }
  & { CompanyBusinessInsight: Array<(
    { __typename?: 'BusinessInsightCompanyOutput' }
    & Pick<BusinessInsightCompanyOutput, 'label' | 'value'>
    & { children?: Maybe<Array<(
      { __typename?: 'BusinessInsightDepartmentOutput' }
      & Pick<BusinessInsightDepartmentOutput, 'label' | 'value'>
      & { children?: Maybe<Array<(
        { __typename?: 'BusinessInsightDivisionOutput' }
        & Pick<BusinessInsightDivisionOutput, 'label' | 'value'>
      )>> }
    )>> }
  )> }
);

export type EmployeeBusinessInsightListingQueryVariables = Exact<{
  input: EmployeeBusinessInsightListing;
}>;


export type EmployeeBusinessInsightListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeBusinessInsightListing?: Maybe<Array<(
    { __typename?: 'BusinessInsightOutput' }
    & Pick<BusinessInsightOutput, 'FilterType' | 'TotalEmp'>
  )>> }
);

export type CalendarPolicyListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CalendarPolicyListingQuery = (
  { __typename?: 'Query' }
  & { CalendarPolicyListing: Array<(
    { __typename?: 'CalendarPolicyOutput' }
    & Pick<CalendarPolicyOutput, 'CalendarPolicyID' | 'StateName' | 'StateID' | 'Default' | 'ModifiedDT' | 'Title' | 'Status' | 'inUse' | 'YearlyHolidaysGroup'>
    & { RestOffDay?: Maybe<Array<(
      { __typename?: 'RecurringRestOffDayEntity' }
      & Pick<RecurringRestOffDayEntity, 'CompanyID' | 'PeriodYear' | 'RecurringRestOffDayID' | 'RestOffType' | 'StateID' | 'Title' | 'DayOfWeek'>
    )>>, CompanyDelaredHoliday?: Maybe<Array<(
      { __typename?: 'CompanyDelaredHolidayEntity' }
      & Pick<CompanyDelaredHolidayEntity, 'CompanyDelaredHolidayID' | 'CalendarPolicyID' | 'CompanyID' | 'StateID' | 'HolidayDate' | 'Description' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>>, Holiday?: Maybe<Array<(
      { __typename?: 'HolidayEntity' }
      & Pick<HolidayEntity, 'HolidayDate' | 'HolidayID' | 'Description' | 'PeriodYear' | 'States' | 'RestOffType'>
    )>> }
  )> }
);

export type OffRestDayListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StateID: Scalars['String'];
}>;


export type OffRestDayListingQuery = (
  { __typename?: 'Query' }
  & { OffRestDayListing: Array<(
    { __typename?: 'RecurringRestOffDayEntity' }
    & Pick<RecurringRestOffDayEntity, 'CalendarPolicyID' | 'RecurringRestOffDayID' | 'CompanyID' | 'PeriodYear' | 'RestOffType' | 'DayOfWeek' | 'StateID' | 'Title'>
  )> }
);

export type HolidayListingQueryVariables = Exact<{
  States: Scalars['String'];
  PeriodYear: Scalars['Float'];
}>;


export type HolidayListingQuery = (
  { __typename?: 'Query' }
  & { HolidayListing: Array<(
    { __typename?: 'HolidayEntity' }
    & Pick<HolidayEntity, 'HolidayDate' | 'HolidayID' | 'Description' | 'PeriodYear' | 'States' | 'RestOffType'>
  )> }
);

export type CompanyOffDaysListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CompanyOffDaysListingQuery = (
  { __typename?: 'Query' }
  & { CompanyOffDaysListing: Array<(
    { __typename?: 'CompanyOffDayEntity' }
    & Pick<CompanyOffDayEntity, 'CompanyOffDayID' | 'Title' | 'RecurringRestOffDayID' | 'CompanyID' | 'HolidayID' | 'Description' | 'DayOffDate' | 'PeriodYear'>
    & { Company: (
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CalendarStateID'>
    ) }
  )> }
);

export type CompanyOffDaysListingWithFiltersQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Title: Scalars['String'];
  StateID: Scalars['String'];
}>;


export type CompanyOffDaysListingWithFiltersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyOffDaysListingWithFilters'>
);

export type CompanyDeclaredHolidayListingByCalendarPolicyIdQueryVariables = Exact<{
  CalendarPolicyID: Scalars['String'];
}>;


export type CompanyDeclaredHolidayListingByCalendarPolicyIdQuery = (
  { __typename?: 'Query' }
  & { CompanyDeclaredHolidayListingByCalendarPolicyID: Array<(
    { __typename?: 'CompanyDelaredHolidayEntity' }
    & Pick<CompanyDelaredHolidayEntity, 'CompanyDelaredHolidayID' | 'CalendarPolicyID' | 'CompanyID' | 'StateID' | 'HolidayDate' | 'Description' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type CalanderPolicySubmitMutationVariables = Exact<{
  CompanyOffDayInput: Array<CompanyOffDayInput> | CompanyOffDayInput;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  CalendarPolicyInput?: Maybe<CalendarPolicyInput>;
  CompanyDelaredHolidayInput: Array<CompanyDelaredHolidayInput> | CompanyDelaredHolidayInput;
  RecurringRestOffDayInput: Array<RecurringRestOffDayInput> | RecurringRestOffDayInput;
}>;


export type CalanderPolicySubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CalanderPolicySubmit'>
);

export type CompanyHolidaysBulkInsertMutationVariables = Exact<{
  CompanyOffDayInput: Array<CompanyOffDayInput> | CompanyOffDayInput;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  CompanyDelaredHolidayInput: Array<CompanyDelaredHolidayInput> | CompanyDelaredHolidayInput;
}>;


export type CompanyHolidaysBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyHolidaysBulkInsert'>
);

export type CompanyOffDaysBulkInsertMutationVariables = Exact<{
  CompanyOffDayInput: Array<CompanyOffDayInput> | CompanyOffDayInput;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
}>;


export type CompanyOffDaysBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyOffDaysBulkInsert'>
);

export type CalendarPolicyStatusUpdateMutationVariables = Exact<{
  CalendarPolicyID: Array<Scalars['String']> | Scalars['String'];
  Status: Status;
}>;


export type CalendarPolicyStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CalendarPolicyStatusUpdate'>
);

export type CalendarPolicyDeleteMutationVariables = Exact<{
  CalendarPolicyID: Array<Scalars['String']> | Scalars['String'];
}>;


export type CalendarPolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CalendarPolicyDelete'>
);

export type CompanyOffDaysDeleteMutationVariables = Exact<{
  CompanyOffDayID: Array<Scalars['String']> | Scalars['String'];
}>;


export type CompanyOffDaysDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyOffDaysDelete'>
);

export type OffRestDayBulkInsertMutationVariables = Exact<{
  RecurringRestOffDayInput: Array<RecurringRestOffDayInput> | RecurringRestOffDayInput;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
}>;


export type OffRestDayBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OffRestDayBulkInsert'>
);

export type UpdateRestOffDayStatusMutationVariables = Exact<{
  RestOffDayID: Array<Scalars['String']> | Scalars['String'];
  Status: Status;
}>;


export type UpdateRestOffDayStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRestOffDayStatus'>
);

export type UpdateCompanyOffDaysStatusMutationVariables = Exact<{
  CompanyOffDayID: Array<Scalars['String']> | Scalars['String'];
  Status: Status;
}>;


export type UpdateCompanyOffDaysStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompanyOffDaysStatus'>
);

export type OffRestDayDeleteMutationVariables = Exact<{
  RecurringRestOffDayID: Array<Scalars['String']> | Scalars['String'];
}>;


export type OffRestDayDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OffRestDayDelete'>
);

export type CompanyOnlyUpdateMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  CompanyInput: CompanyInput;
}>;


export type CompanyOnlyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyOnlyUpdate'>
);

export type ClaimAccountHeaderListQueryVariables = Exact<{ [key: string]: never; }>;


export type ClaimAccountHeaderListQuery = (
  { __typename?: 'Query' }
  & { ClaimAccountHeaderList: Array<(
    { __typename?: 'ClaimAccountHeaderOutput' }
    & Pick<ClaimAccountHeaderOutput, 'ID' | 'Title' | 'EnableStaffCategory' | 'ModifiedDT' | 'CreatedDT' | 'DeleteDisable'>
  )> }
);

export type ClaimAccountMappingListQueryVariables = Exact<{
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
}>;


export type ClaimAccountMappingListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ClaimAccountMappingList'>
);

export type ClaimAccountMappingInsertMutationVariables = Exact<{
  HeaderInput: ClaimAccountHeaderInput;
  DetailInput: Array<ClaimAccountDetailInput> | ClaimAccountDetailInput;
}>;


export type ClaimAccountMappingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimAccountMappingInsert'>
);

export type ClaimAccountMappingUpdateMutationVariables = Exact<{
  HeaderInput: ClaimAccountHeaderInput;
  DetailInput: Array<ClaimAccountDetailInput> | ClaimAccountDetailInput;
}>;


export type ClaimAccountMappingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimAccountMappingUpdate'>
);

export type ClaimAccountMappingDeleteMutationVariables = Exact<{
  ClaimAccountHeaderID: Scalars['String'];
}>;


export type ClaimAccountMappingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimAccountMappingDelete'>
);

export type ClaimAccountHeaderListWithDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClaimAccountHeaderListWithDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ClaimAccountHeaderListWithDetails'>
);

export type SpecificClaimAccountHeaderListWithDetailsQueryVariables = Exact<{
  ClaimAccountHeaderID?: Maybe<Scalars['String']>;
}>;


export type SpecificClaimAccountHeaderListWithDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ClaimAccountHeaderListWithDetails'>
);

export type ClaimAccountAssignmentInsertMutationVariables = Exact<{
  HeaderInput: ClaimAccountAssignmentInput;
}>;


export type ClaimAccountAssignmentInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimAccountAssignmentInsert'>
);

export type ClaimAccountAssignmentUpdateMutationVariables = Exact<{
  HeaderInput: ClaimAccountAssignmentInput;
}>;


export type ClaimAccountAssignmentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimAccountAssignmentUpdate'>
);

export type GetClaimAccountAssignmentQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetClaimAccountAssignmentQuery = (
  { __typename?: 'Query' }
  & { GetClaimAccountAssignment?: Maybe<(
    { __typename?: 'ClaimAccountAssignmentEntity' }
    & Pick<ClaimAccountAssignmentEntity, 'ID' | 'CompanyID' | 'ClaimAccountHeaderID'>
    & { ClaimAccountHeader?: Maybe<(
      { __typename?: 'ClaimAccountHeaderEntity' }
      & Pick<ClaimAccountHeaderEntity, 'Title'>
    )> }
  )> }
);

export type GetGlClaimRecordsListingQueryVariables = Exact<{
  input: ClaimRecordsInput;
}>;


export type GetGlClaimRecordsListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetGLClaimRecordsListing'>
);

export type ClaimGlCycleProcessQueryVariables = Exact<{
  input: ClaimRecordsInput;
  Type: Scalars['String'];
}>;


export type ClaimGlCycleProcessQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ClaimGLCycleProcess'>
);

export type GetClaimEntitlementByClaimTypeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ClaimTypeID: Scalars['String'];
  toDate: Scalars['DateTime'];
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetClaimEntitlementByClaimTypeListingQuery = (
  { __typename?: 'Query' }
  & { GetClaimEntitlementByClaimTypeListing?: Maybe<Array<(
    { __typename?: 'EmpClaimEntitlementByClaimTypeOutput' }
    & Pick<EmpClaimEntitlementByClaimTypeOutput, 'JobGradeID' | 'OrgUnitID' | 'FormStatus' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName'>
    & { Summary?: Maybe<(
      { __typename?: 'EntitlementSummary' }
      & Pick<EntitlementSummary, 'ClaimType' | 'ClaimFrequency' | 'Entitled' | 'Draft' | 'Pending' | 'Claimed' | 'Balance'>
    )> }
  )>> }
);

export type GetClaimEntitlementByEmployeeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
  AsToDate: Scalars['DateTime'];
}>;


export type GetClaimEntitlementByEmployeeListingQuery = (
  { __typename?: 'Query' }
  & { GetClaimEntitlementByEmployeeListing?: Maybe<Array<(
    { __typename?: 'EmpClaimEntitlementByEmployeeOutput' }
    & Pick<EmpClaimEntitlementByEmployeeOutput, 'EmployeeName'>
    & { Summarries?: Maybe<Array<(
      { __typename?: 'EntitlementSummary' }
      & Pick<EntitlementSummary, 'ClaimType' | 'ClaimFrequency' | 'Entitled' | 'Draft' | 'Pending' | 'Claimed' | 'Balance'>
    )>> }
  )>> }
);

export type ClaimTypeListingAllQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type ClaimTypeListingAllQuery = (
  { __typename?: 'Query' }
  & { ClaimTypeListingAll: Array<(
    { __typename?: 'ClaimTypeEntity' }
    & Pick<ClaimTypeEntity, 'ClaimTypeID' | 'SubscriptionAccountID' | 'Name' | 'ClaimCategory'>
  )> }
);

export type ClaimPolicyListingAllQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type ClaimPolicyListingAllQuery = (
  { __typename?: 'Query' }
  & { ClaimPolicyListingAll: Array<(
    { __typename?: 'ClaimBenefitEntity' }
    & Pick<ClaimBenefitEntity, 'ClaimBenefitID' | 'SubscriptionAccountID' | 'Name' | 'Status'>
    & { ClaimItems?: Maybe<Array<(
      { __typename?: 'ClaimItemEntity' }
      & { ClaimType?: Maybe<(
        { __typename?: 'ClaimTypeEntity' }
        & Pick<ClaimTypeEntity, 'ClaimTypeID' | 'Name' | 'ClaimCategory'>
      )> }
    )>> }
  )> }
);

export type ClaimParameterListingAllQueryVariables = Exact<{
  ClaimParameterType: Scalars['String'];
}>;


export type ClaimParameterListingAllQuery = (
  { __typename?: 'Query' }
  & { ClaimParameterListingAll: Array<(
    { __typename?: 'ClaimParameterEntity' }
    & Pick<ClaimParameterEntity, 'ClaimParameterID' | 'CompanyID' | 'Name' | 'ClaimParameterType'>
  )> }
);

export type ClaimUserDefineFieldsListingQueryVariables = Exact<{
  ClaimParameterID: Scalars['String'];
}>;


export type ClaimUserDefineFieldsListingQuery = (
  { __typename?: 'Query' }
  & { ClaimUserDefineFieldsListing: Array<(
    { __typename?: 'ClaimUserDefineFieldsEntity' }
    & Pick<ClaimUserDefineFieldsEntity, 'ClaimParameterID' | 'ClaimUserDefineFieldsID' | 'SubscriptionAccountID' | 'IsCompulsory' | 'IsActive' | 'FieldLabel' | 'FieldType' | 'Values'>
  )> }
);

export type ClaimParameterDeleteMutationVariables = Exact<{
  ClaimParameterID: Scalars['String'];
}>;


export type ClaimParameterDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimParameterDelete'>
);

export type ClaimParameterInsertMutationVariables = Exact<{
  input: ClaimParameterInput;
}>;


export type ClaimParameterInsertMutation = (
  { __typename?: 'Mutation' }
  & { ClaimParameterInsert: (
    { __typename?: 'ClaimParameterEntity' }
    & Pick<ClaimParameterEntity, 'ClaimParameterID'>
  ) }
);

export type ClaimParameterUpdateMutationVariables = Exact<{
  input: ClaimParameterInput;
}>;


export type ClaimParameterUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimParameterUpdate'>
);

export type MileageTableDeleteMutationVariables = Exact<{
  ClaimParameterID: Scalars['String'];
}>;


export type MileageTableDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MileageTableDelete'>
);

export type ClaimMileageLocationListingQueryVariables = Exact<{
  ClaimParameterType: Scalars['String'];
}>;


export type ClaimMileageLocationListingQuery = (
  { __typename?: 'Query' }
  & { ClaimParameterListing: Array<(
    { __typename?: 'ClaimParameterEntity' }
    & Pick<ClaimParameterEntity, 'ClaimParameterID' | 'Name' | 'ClaimParameterType' | 'ModifiedBy' | 'ModifiedDT' | 'CreatedBy' | 'CreatedDT' | 'Status' | 'StartTime' | 'EndTime'>
    & { Details?: Maybe<Array<(
      { __typename?: 'ClaimMileageTableEntity' }
      & Pick<ClaimMileageTableEntity, 'ClaimParameterID' | 'ClaimMileageTableID' | 'TravelFrom' | 'TravelTo' | 'Route' | 'Distance'>
    )>> }
  )> }
);

export type MileageTableInsertMutationVariables = Exact<{
  input: ClaimParameterInput;
  ClaimMileageTableInput: Array<ClaimMileageTableInput> | ClaimMileageTableInput;
}>;


export type MileageTableInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MileageTableInsert'>
);

export type MileageTableUpdateMutationVariables = Exact<{
  ClaimParameterID: Scalars['String'];
  input: ClaimParameterInput;
  ClaimMileageTableInput: Array<ClaimMileageTableInput> | ClaimMileageTableInput;
}>;


export type MileageTableUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MileageTableUpdate'>
);

export type ClaimUserDefineFieldsInsertMutationVariables = Exact<{
  input: ClaimParameterInput;
  ClaimUserDefineFieldsInput: Array<ClaimUserDefineFieldsInput> | ClaimUserDefineFieldsInput;
}>;


export type ClaimUserDefineFieldsInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimUserDefineFieldsInsert'>
);

export type ClaimUserDefineFieldsUpdateMutationVariables = Exact<{
  ClaimParameterID: Scalars['String'];
  input: ClaimParameterInput;
  ClaimUserDefineFieldsInput: Array<ClaimUserDefineFieldsInput> | ClaimUserDefineFieldsInput;
}>;


export type ClaimUserDefineFieldsUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimUserDefineFieldsUpdate'>
);

export type ClaimUserDefineFieldsDuplicateMutationVariables = Exact<{
  ClaimParameterID: Scalars['String'];
  input: ClaimParameterInput;
}>;


export type ClaimUserDefineFieldsDuplicateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimUserDefineFieldsDuplicate'>
);

export type ClaimTypePayItemListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type ClaimTypePayItemListingQuery = (
  { __typename?: 'Query' }
  & { ClaimTypePayItemListing: Array<(
    { __typename?: 'PayItemEntity' }
    & Pick<PayItemEntity, 'PayItemID' | 'CompanyID' | 'PayCode' | 'PayName' | 'PayItemType'>
  )> }
);

export type ClaimTypeInsertMutationVariables = Exact<{
  input: ClaimTypeInput;
}>;


export type ClaimTypeInsertMutation = (
  { __typename?: 'Mutation' }
  & { ClaimTypeInsert: (
    { __typename?: 'ClaimTypeEntity' }
    & Pick<ClaimTypeEntity, 'ClaimTypeID'>
  ) }
);

export type ClaimTypeUpdateMutationVariables = Exact<{
  input: ClaimTypeInput;
  ClaimTypeID: Scalars['String'];
}>;


export type ClaimTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimTypeUpdate'>
);

export type ClaimTypeDeleteMutationVariables = Exact<{
  ClaimTypeID: Scalars['String'];
}>;


export type ClaimTypeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimTypeDelete'>
);

export type ClaimTypeTemplateDeleteMutationVariables = Exact<{
  ClaimBenefitID: Scalars['String'];
}>;


export type ClaimTypeTemplateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimTypeTemplateDelete'>
);

export type ClaimPolicyListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  ClaimCategory: ClaimCategory;
}>;


export type ClaimPolicyListingQuery = (
  { __typename?: 'Query' }
  & { ClaimPolicyListing: Array<(
    { __typename?: 'ClaimBenefitEntity' }
    & Pick<ClaimBenefitEntity, 'ClaimBenefitID' | 'SubscriptionAccountID' | 'Name' | 'Description' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'InUse'>
    & { ClaimItems?: Maybe<Array<(
      { __typename?: 'ClaimItemEntity' }
      & Pick<ClaimItemEntity, 'ClaimItemID' | 'ClaimBenefitID' | 'ClaimTypeID' | 'ClaimFrequency' | 'TotalAmt' | 'Occurrence' | 'OccurrenceLimit' | 'MileageTableID' | 'IsMileageTable' | 'IsLimit' | 'IsRemarks' | 'IsAttachment' | 'DailyFrequency' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'MileageTableName'>
      & { ClaimType?: Maybe<(
        { __typename?: 'ClaimTypeEntity' }
        & Pick<ClaimTypeEntity, 'Name' | 'ClaimTypeID'>
      )>, ClaimTemplates?: Maybe<Array<(
        { __typename?: 'ClaimTemplateEntity' }
        & Pick<ClaimTemplateEntity, 'ClaimPolicyID' | 'ClaimTemplateID'>
      )>>, MileageTable?: Maybe<(
        { __typename?: 'ClaimParameterEntity' }
        & Pick<ClaimParameterEntity, 'Name'>
      )> }
    )>>, RateDetails?: Maybe<Array<(
      { __typename?: 'ClaimDailyRateEntity' }
      & Pick<ClaimDailyRateEntity, 'ClaimBenefitID' | 'ClaimDailyRateID' | 'ClaimParameterID' | 'Rate' | 'VenueName'>
    )>>, VehicleRateDetails?: Maybe<Array<(
      { __typename?: 'ClaimVehicleRateEntity' }
      & Pick<ClaimVehicleRateEntity, 'ClaimVehicleRateID' | 'ClaimParameterID' | 'ClaimBenefitID' | 'StartValue' | 'EndValue' | 'Rate' | 'Status' | 'VehicleName'>
    )>>, MealRateDetails?: Maybe<Array<(
      { __typename?: 'ClaimMealRateEntity' }
      & Pick<ClaimMealRateEntity, 'ClaimMealRateID' | 'ClaimParameterID' | 'VenueID' | 'ClaimBenefitID' | 'Rate' | 'Status' | 'MealType' | 'VenueName'>
    )>> }
  )> }
);

export type ClaimTypeCompanyListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type ClaimTypeCompanyListingQuery = (
  { __typename?: 'Query' }
  & { ClaimTypeListing: Array<(
    { __typename?: 'ClaimTypeEntity' }
    & Pick<ClaimTypeEntity, 'ClaimTypeID' | 'SubscriptionAccountID' | 'Name' | 'IsAttachment' | 'IsRemarks' | 'IsReceiptNo' | 'IsDisplay' | 'ClaimCategory' | 'PayItemID' | 'PaymentMethod' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayItemID' | 'PayName' | 'PayCode' | 'PayItemType' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>, ClaimPolicies?: Maybe<Array<(
      { __typename?: 'ClaimItemEntity' }
      & Pick<ClaimItemEntity, 'ClaimItemID' | 'ClaimTypeID' | 'TotalAmt' | 'ClaimFrequency' | 'Status'>
      & { ClaimBenefit?: Maybe<(
        { __typename?: 'ClaimBenefitEntity' }
        & Pick<ClaimBenefitEntity, 'Name' | 'Description' | 'Status' | 'SoftDelete'>
      )> }
    )>> }
  )> }
);

export type ClaimTypeTemplateUpdateStatusMutationVariables = Exact<{
  claimBenefitInput: ClaimBenefitInput;
}>;


export type ClaimTypeTemplateUpdateStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimTypeTemplateUpdateStatus'>
);

export type ClaimTypeTemplateInsertMutationVariables = Exact<{
  claimBenefitInput: ClaimBenefitInput;
  claimItemInput: Array<ClaimItemInput> | ClaimItemInput;
  claimVehicleRateInput?: Maybe<Array<ClaimVehicleRateInput> | ClaimVehicleRateInput>;
  claimMealRateInput?: Maybe<Array<ClaimMealRateInput> | ClaimMealRateInput>;
  ClaimDailyRateInput?: Maybe<Array<ClaimDailyRateInput> | ClaimDailyRateInput>;
}>;


export type ClaimTypeTemplateInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimTypeTemplateInsert'>
);

export type ClaimTypeTemplateListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type ClaimTypeTemplateListingQuery = (
  { __typename?: 'Query' }
  & { ClaimTypeTemplateListing: Array<(
    { __typename?: 'ClaimBenefitEntity' }
    & Pick<ClaimBenefitEntity, 'ClaimBenefitID' | 'SubscriptionAccountID' | 'Name' | 'Description' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { ClaimItems?: Maybe<Array<(
      { __typename?: 'ClaimItemEntity' }
      & Pick<ClaimItemEntity, 'ClaimItemID' | 'ClaimBenefitID' | 'ClaimTypeID' | 'ClaimFrequency' | 'TotalAmt' | 'Occurrence' | 'OccurrenceLimit' | 'IsLimit' | 'IsRemarks' | 'IsAttachment' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
      & { ClaimType?: Maybe<(
        { __typename?: 'ClaimTypeEntity' }
        & Pick<ClaimTypeEntity, 'Name' | 'ClaimTypeID'>
      )>, ClaimTemplates?: Maybe<Array<(
        { __typename?: 'ClaimTemplateEntity' }
        & Pick<ClaimTemplateEntity, 'ClaimTemplateID' | 'ClaimPolicyID'>
      )>> }
    )>> }
  )> }
);

export type ClaimTypeTemplateUpdateMutationVariables = Exact<{
  claimBenefitInput: ClaimBenefitInput;
  claimItemInput: Array<ClaimItemInput> | ClaimItemInput;
  claimVehicleRateInput?: Maybe<Array<ClaimVehicleRateInput> | ClaimVehicleRateInput>;
  claimMealRateInput?: Maybe<Array<ClaimMealRateInput> | ClaimMealRateInput>;
  claimDailyRateInput?: Maybe<Array<ClaimDailyRateInput> | ClaimDailyRateInput>;
}>;


export type ClaimTypeTemplateUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimTypeTemplateUpdate'>
);

export type JobGradePolicyListingAllQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type JobGradePolicyListingAllQuery = (
  { __typename?: 'Query' }
  & { JobGradePolicyListingAll: Array<(
    { __typename?: 'JobGradePolicyEntity' }
    & Pick<JobGradePolicyEntity, 'JobGradePolicyID' | 'JobGradeID'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, ClaimTemplates?: Maybe<Array<(
      { __typename?: 'ClaimTemplateEntity' }
      & Pick<ClaimTemplateEntity, 'ClaimPolicyID'>
    )>> }
  )> }
);

export type CompanySummarySimplifiedQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CompanySummarySimplifiedQuery = (
  { __typename?: 'Query' }
  & { CompanySummarySimplified: (
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'CompanyName' | 'ClaimCutOffDay' | 'ClaimTrxDayLimit' | 'ProjectExpenseTrxDayLimit' | 'ProjectExpenseCutOff'>
  ) }
);

export type ClaimTemplateListingQueryVariables = Exact<{
  JobGradePolicyID: Scalars['String'];
  JobGradeID?: Maybe<Scalars['String']>;
}>;


export type ClaimTemplateListingQuery = (
  { __typename?: 'Query' }
  & { ClaimTemplateListing: Array<(
    { __typename?: 'ClaimTemplateEntity' }
    & Pick<ClaimTemplateEntity, 'ClaimTemplateID' | 'JobGradePolicyID' | 'ClaimPolicyID'>
    & { ClaimItem?: Maybe<(
      { __typename?: 'ClaimItemEntity' }
      & Pick<ClaimItemEntity, 'ClaimItemID' | 'ClaimTypeID' | 'ClaimBenefitID'>
      & { ClaimType?: Maybe<(
        { __typename?: 'ClaimTypeEntity' }
        & Pick<ClaimTypeEntity, 'ClaimTypeID' | 'Name'>
      )>, ClaimBenefit?: Maybe<(
        { __typename?: 'ClaimBenefitEntity' }
        & Pick<ClaimBenefitEntity, 'Name'>
      )> }
    )> }
  )> }
);

export type ClaimJobGradePolicyInsertMutationVariables = Exact<{
  JobGradePolicyInput: JobGradePolicyInput;
  ClaimTemplateInput: Array<ClaimTemplateInput> | ClaimTemplateInput;
}>;


export type ClaimJobGradePolicyInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimJobGradePolicyInsert'>
);

export type ClaimJobGradePolicyUpdateMutationVariables = Exact<{
  JobGradePolicyID: Scalars['String'];
  JobGradePolicyInput: JobGradePolicyInput;
  ClaimTemplateInput: Array<ClaimTemplateInput> | ClaimTemplateInput;
}>;


export type ClaimJobGradePolicyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimJobGradePolicyUpdate'>
);

export type CompanyClaimSettingUpdateMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  CompanyInput: CompanyInput;
}>;


export type CompanyClaimSettingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyClaimSettingUpdate'>
);

export type ClaimTypeConditionUpdateMutationVariables = Exact<{
  ClaimTemplateInput: ClaimTemplateInput;
}>;


export type ClaimTypeConditionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimTypeConditionUpdate'>
);

export type ClaimTypeConditionDeleteMutationVariables = Exact<{
  ClaimTemplateID: Scalars['String'];
}>;


export type ClaimTypeConditionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ClaimTypeConditionDelete'>
);

export type ClaimTypeConditionListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type ClaimTypeConditionListQuery = (
  { __typename?: 'Query' }
  & { ClaimTypeConditionList: Array<(
    { __typename?: 'ClaimTemplateEntity' }
    & Pick<ClaimTemplateEntity, 'CompanyID' | 'ClaimTemplateID' | 'ConditionList' | 'ClaimPolicyIDList' | 'ClaimTypeID' | 'ModifiedDT' | 'CreatedDT'>
  )> }
);

export type GetClaimPostingListingQueryVariables = Exact<{
  input: ApprovalClaimVerificationInput;
  PaymentMethod: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  userID?: Maybe<Scalars['String']>;
}>;


export type GetClaimPostingListingQuery = (
  { __typename?: 'Query' }
  & { GetClaimPostingListing?: Maybe<Array<(
    { __typename?: 'ClaimPostingListingOutput' }
    & Pick<ClaimPostingListingOutput, 'JobGradeID' | 'OrgUnitID' | 'FormStatus' | 'ClaimHeaderID' | 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'Subject' | 'SubmissionDate' | 'ClaimNo' | 'ApprovalName' | 'ApprovalDate' | 'HeaderStatus' | 'CountClaimDetails' | 'TotalClaimAmount' | 'CountVerifiedClaim' | 'TotalVerifiedClaimAmount' | 'CompletedVerificationDate' | 'CompletedVerificationBy' | 'Remark' | 'IsOTPModified' | 'IsOTPDeleted' | 'OTPModifiedBy' | 'OTPDeletedBy' | 'DetailStatus'>
    & { MyClaimDetails?: Maybe<Array<(
      { __typename?: 'MyClaimDetailOutput' }
      & Pick<MyClaimDetailOutput, 'ClaimDetailID' | 'ClaimTypeID' | 'ClaimTypeName' | 'FinanceApproveStatus'>
    )>>, ClaimPostedListing?: Maybe<(
      { __typename?: 'ClaimPostedListingOutput' }
      & Pick<ClaimPostedListingOutput, 'PayPeriodID' | 'PayrollMonth' | 'PayrollCycleID' | 'PayrollCycleName' | 'PostedDate' | 'PostedBy' | 'Reference1' | 'Reference2'>
    )> }
  )>> }
);

export type GetClaimPostingCountQueryVariables = Exact<{
  input: ApprovalClaimVerificationInput;
  PaymentMethod: Scalars['String'];
}>;


export type GetClaimPostingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetClaimPostingCount'>
);

export type GetClaimPostingDetailQueryVariables = Exact<{
  ClaimHeaderID: Scalars['String'];
  PaymentMethod: Scalars['String'];
}>;


export type GetClaimPostingDetailQuery = (
  { __typename?: 'Query' }
  & { GetClaimPostingDetail?: Maybe<(
    { __typename?: 'ClaimRecordsDetailHeaderOutput' }
    & Pick<ClaimRecordsDetailHeaderOutput, 'EmployeeID' | 'EmployeeNo' | 'ClaimNo' | 'EmployeeName' | 'DepartmentName' | 'ClaimHeaderID' | 'SubmissionDate' | 'Subject' | 'CountClaimDetails' | 'TotalClaimAmount' | 'PostingStatus' | 'ApprovalDate' | 'ApprovalName' | 'CompletedVerificationDate' | 'CompletedVerificationBy'>
    & { ClaimRecordsDetail?: Maybe<Array<(
      { __typename?: 'ClaimRecordsDetail' }
      & Pick<ClaimRecordsDetail, 'ClaimDetailsID' | 'ClaimTypeID' | 'ClaimTypeName' | 'ReceiptNo' | 'ReceiptDate' | 'DocumentName' | 'DocumentUrl' | 'ClaimRemarks' | 'ClaimAmount' | 'VerifiedAmount' | 'FinanceApproveDate' | 'FinanceApproveBy' | 'FinanceRemarks' | 'FinanceApproveStatus' | 'PostedBy' | 'PostedDate'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )> }
    )>> }
  )> }
);

export type PayrollCycleListingByTlaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type PayrollCycleListingByTlaQuery = (
  { __typename?: 'Query' }
  & { PayrollCycleListingByTLA: Array<(
    { __typename?: 'PayrollCycleEntity' }
    & Pick<PayrollCycleEntity, 'PayrollCycleID' | 'Description' | 'CompanyID'>
  )> }
);

export type PayrollCycleByPayPeriodQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  FromPayPeriod: Scalars['DateTime'];
  ToPayPeriod: Scalars['DateTime'];
}>;


export type PayrollCycleByPayPeriodQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PayrollCycleByPayPeriod'>
);

export type PayPeriodsByCompanyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type PayPeriodsByCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PayPeriodsByCompany'>
);

export type PayrollProcessPayrollCycleListingByTlaQueryVariables = Exact<{
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type PayrollProcessPayrollCycleListingByTlaQuery = (
  { __typename?: 'Query' }
  & { PayrollProcessPayrollCycleListingByTLA: Array<(
    { __typename?: 'PayrollProcessPayrollCycleListingOutput' }
    & Pick<PayrollProcessPayrollCycleListingOutput, 'PayrollCycleID' | 'Description' | 'LastProcessedDate' | 'ShowDelete' | 'PayrollProcessStatus' | 'PeriodYearMonth' | 'IsLastProcess' | 'SequenceNo'>
  )> }
);

export type PayrollProcessPayrollCycleListingQueryVariables = Exact<{
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type PayrollProcessPayrollCycleListingQuery = (
  { __typename?: 'Query' }
  & { PayrollProcessPayrollCycleListing: Array<(
    { __typename?: 'PayrollProcessPayrollCycleListingOutput' }
    & Pick<PayrollProcessPayrollCycleListingOutput, 'PayrollCycleID' | 'Description' | 'LastProcessedDate' | 'ShowDelete' | 'PayrollProcessStatus' | 'PeriodYearMonth' | 'IsLastProcess' | 'SequenceNo' | 'IsInitiated' | 'IsProjectGLProcessed' | 'LastProjectGLProcessedDate' | 'IsPayrollGLProcessed' | 'LastPayrollGLProcessedDate'>
  )> }
);

export type PostClaimRecordsMutationVariables = Exact<{
  input: PostClaimRecordsInput;
  PaymentMethod: Scalars['String'];
}>;


export type PostClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PostClaimRecords'>
);

export type ReverseClaimRecordsMutationVariables = Exact<{
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderIDs: Array<Scalars['String']> | Scalars['String'];
  PaymentMethod: Scalars['String'];
}>;


export type ReverseClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReverseClaimRecords'>
);

export type GetClaimPostingPeriodListingV2QueryVariables = Exact<{
  input: ClaimPostingPeriodListingInput;
  PaymentMethod?: Maybe<Scalars['String']>;
}>;


export type GetClaimPostingPeriodListingV2Query = (
  { __typename?: 'Query' }
  & { GetClaimPostingPeriodListingV2?: Maybe<Array<(
    { __typename?: 'ClaimPostingPeriodListingOutput' }
    & Pick<ClaimPostingPeriodListingOutput, 'PeriodYearMonth' | 'FromDate' | 'ToDate' | 'ListingStatus' | 'LatestPeriod'>
    & { ClaimPostingPeriodStatusList?: Maybe<Array<(
      { __typename?: 'ClaimPostingPeriodStatus' }
      & Pick<ClaimPostingPeriodStatus, 'ClaimPostingPeriodStatus' | 'CountClaimDetails' | 'TotalClaimAmount'>
    )>> }
  )>> }
);

export type GetClaimPaymentPeriodListingV2QueryVariables = Exact<{
  input: ClaimPostingPeriodListingInput;
  PaymentMethod?: Maybe<Scalars['String']>;
}>;


export type GetClaimPaymentPeriodListingV2Query = (
  { __typename?: 'Query' }
  & { GetClaimPostingPeriodListingV2?: Maybe<Array<(
    { __typename?: 'ClaimPostingPeriodListingOutput' }
    & Pick<ClaimPostingPeriodListingOutput, 'PeriodYearMonth' | 'FromDate' | 'ToDate' | 'ListingStatus' | 'LatestPeriod'>
    & { ClaimPostingPeriodStatusList?: Maybe<Array<(
      { __typename?: 'ClaimPostingPeriodStatus' }
      & Pick<ClaimPostingPeriodStatus, 'ClaimPostingPeriodStatus' | 'CountClaimDetails' | 'TotalClaimAmount'>
    )>> }
  )>> }
);

export type ClaimHomeMenuSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
}>;


export type ClaimHomeMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ClaimHomeMenuSummary'>
);

export type GetCompanyStatutoryAccountListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetCompanyStatutoryAccountListingQuery = (
  { __typename?: 'Query' }
  & { getCompanyStatutoryAccountListing?: Maybe<Array<(
    { __typename?: 'CompanyStatutoryAccountOutput' }
    & Pick<CompanyStatutoryAccountOutput, 'CompanyStatutoryAccountID' | 'CompanyStatutoryID' | 'CompanyID' | 'Type' | 'AccountNo' | 'Description' | 'IsDefault' | 'Status' | 'removable' | 'CompanyAltAddressID'>
  )>> }
);

export type CalculateEpfRateQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type CalculateEpfRateQuery = (
  { __typename?: 'Query' }
  & { CalculateEPFRate?: Maybe<(
    { __typename?: 'EPFRateCalculationOutput' }
    & Pick<EpfRateCalculationOutput, 'EmployeeRate' | 'EmployerRate'>
  )> }
);

export type CompanyStatutoryAccountUpdateMutationVariables = Exact<{
  CompanyStatutoryAccountInput: Array<CompanyStatutoryAccountInput> | CompanyStatutoryAccountInput;
  CompanyStatutoryID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
}>;


export type CompanyStatutoryAccountUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyStatutoryAccountUpdate'>
);

export type CompulsaryCourseByJobGradeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  JobGradeID?: Maybe<Scalars['String']>;
  CourseID?: Maybe<Scalars['String']>;
}>;


export type CompulsaryCourseByJobGradeListingQuery = (
  { __typename?: 'Query' }
  & { CompulsaryCourseByJobGradeListing: Array<(
    { __typename?: 'CompulsaryCourseByJobGradeEntity' }
    & Pick<CompulsaryCourseByJobGradeEntity, 'ID' | 'CompanyID' | 'JobGradeID' | 'CourseID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type GetCompulsaryCourseByJobGradeListingWithCompanyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetCompulsaryCourseByJobGradeListingWithCompanyQuery = (
  { __typename?: 'Query' }
  & { GetCompulsaryCourseByJobGradeListingWithCompany: Array<(
    { __typename?: 'CompulsaryCourseByJobGradeEntity' }
    & Pick<CompulsaryCourseByJobGradeEntity, 'ID' | 'CompanyID' | 'JobGradeID' | 'CourseID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type JobGradeTrainingCourseListingAllQueryVariables = Exact<{ [key: string]: never; }>;


export type JobGradeTrainingCourseListingAllQuery = (
  { __typename?: 'Query' }
  & { TrainingCourseListingAll: Array<(
    { __typename?: 'TrainingCourseEntity' }
    & Pick<TrainingCourseEntity, 'SubscriptionAccountID' | 'ID' | 'Name' | 'Description' | 'Evaluation' | 'HRDFClaimable' | 'Type' | 'Duration' | 'PreferredTrainer' | 'AllowedJobGrade' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type CompulsaryCourseByJobGradeSubmitMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
  CompanyID: Scalars['String'];
  JobGradeID: Scalars['String'];
}>;


export type CompulsaryCourseByJobGradeSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompulsaryCourseByJobGradeSubmit'>
);

export type CourseClassificationListingQueryVariables = Exact<{ [key: string]: never; }>;


export type CourseClassificationListingQuery = (
  { __typename?: 'Query' }
  & { CourseClassificationListing: Array<(
    { __typename?: 'CourseClassificationEntity' }
    & Pick<CourseClassificationEntity, 'ID' | 'SubscriptionAccountID' | 'Name' | 'DisplaySeq' | 'isInUse' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { TrainingCourses?: Maybe<Array<(
      { __typename?: 'TrainingCourseEntity' }
      & Pick<TrainingCourseEntity, 'ID'>
    )>> }
  )> }
);

export type CourseClassificationBulkInsertMutationVariables = Exact<{
  input: Array<CourseClassificationInput> | CourseClassificationInput;
}>;


export type CourseClassificationBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CourseClassificationBulkInsert'>
);

export type CourseClassificationDeleteMutationVariables = Exact<{
  CourseClassificationID: Scalars['String'];
}>;


export type CourseClassificationDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CourseClassificationDelete'>
);

export type TrainingCourseListingQueryVariables = Exact<{
  CourseClassificationID: Scalars['String'];
}>;


export type TrainingCourseListingQuery = (
  { __typename?: 'Query' }
  & { TrainingCourseListing: Array<(
    { __typename?: 'TrainingCourseEntity' }
    & Pick<TrainingCourseEntity, 'ID' | 'Name' | 'Description' | 'Evaluation' | 'HRDFClaimable' | 'Type' | 'Duration' | 'PreferredTrainer' | 'AllowedJobGrade' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type TrainingCourseListingAllQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainingCourseListingAllQuery = (
  { __typename?: 'Query' }
  & { TrainingCourseListingAll: Array<(
    { __typename?: 'TrainingCourseEntity' }
    & Pick<TrainingCourseEntity, 'ID' | 'Name' | 'Description' | 'Evaluation' | 'HRDFClaimable' | 'Type' | 'Duration' | 'PreferredTrainer' | 'AllowedJobGrade' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type TrainingCourseListingByJobGradeQueryVariables = Exact<{
  JobGradeID: Scalars['String'];
}>;


export type TrainingCourseListingByJobGradeQuery = (
  { __typename?: 'Query' }
  & { TrainingCourseListingByJobGrade: Array<(
    { __typename?: 'TrainingCourseEntity' }
    & Pick<TrainingCourseEntity, 'ID' | 'Name' | 'Description' | 'Evaluation' | 'HRDFClaimable' | 'Type' | 'Duration' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type TrainingCourseSubmitMutationVariables = Exact<{
  input: TrainingCourseInput;
}>;


export type TrainingCourseSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TrainingCourseSubmit'>
);

export type TrainingCourseDeleteMutationVariables = Exact<{
  TrainingCourseID: Scalars['String'];
}>;


export type TrainingCourseDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TrainingCourseDelete'>
);

export type GetProviderListByCourseQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProviderListByCourseQuery = (
  { __typename?: 'Query' }
  & { GetProviderListByCourse: Array<(
    { __typename?: 'CourseProviderOutput' }
    & Pick<CourseProviderOutput, 'CourseID' | 'CourseName'>
    & { providers?: Maybe<Array<(
      { __typename?: 'AuthorisedTrainingProviderEntity' }
      & Pick<AuthorisedTrainingProviderEntity, 'ID' | 'SubscriptionAccountID' | 'DocumentID' | 'Name' | 'IsExternal' | 'ApprovedCourses' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'NickName' | 'MobileNo' | 'OfficeNo' | 'FaxNo' | 'Email' | 'NRIC' | 'PassportNo' | 'PassportNoLHDN' | 'Nationality' | 'Marital' | 'Gender' | 'Race' | 'Religion' | 'BirthDate' | 'ProfilePicture' | 'WebUrl' | 'SameAddress'>
        & { Address?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
        )>, PermanentAddress?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
        )> }
      )> }
    )>> }
  )> }
);

export type AuthorisedTrainingProviderSubmitMutationVariables = Exact<{
  input: AuthorisedTrainingProviderInput;
}>;


export type AuthorisedTrainingProviderSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AuthorisedTrainingProviderSubmit'>
);

export type StatesListingQueryVariables = Exact<{
  Country: Scalars['String'];
}>;


export type StatesListingQuery = (
  { __typename?: 'Query' }
  & { StatesListing: Array<(
    { __typename?: 'StatesEntity' }
    & Pick<StatesEntity, 'StatesID' | 'Description'>
  )> }
);

export type TrainingCostItemListingQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainingCostItemListingQuery = (
  { __typename?: 'Query' }
  & { TrainingCostItemListing: Array<(
    { __typename?: 'TrainingCostItemEntity' }
    & Pick<TrainingCostItemEntity, 'ID' | 'SubscriptionAccountID' | 'isInUse' | 'Name' | 'DisplaySeq' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type TrainingCostItemBulkInsertMutationVariables = Exact<{
  input: Array<TrainingCostItemInput> | TrainingCostItemInput;
}>;


export type TrainingCostItemBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TrainingCostItemBulkInsert'>
);

export type TrainingCostItemDeleteMutationVariables = Exact<{
  TrainingCostItemID: Scalars['String'];
}>;


export type TrainingCostItemDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TrainingCostItemDelete'>
);

export type GetDataImportTemplateQueryVariables = Exact<{
  Type: Scalars['String'];
}>;


export type GetDataImportTemplateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetDataImportTemplate'>
);

export type DataImportVerifyMutationVariables = Exact<{
  Type: Scalars['String'];
  File: Scalars['Upload'];
  PayItemMapping?: Maybe<Array<PayItemMappingInput> | PayItemMappingInput>;
}>;


export type DataImportVerifyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DataImportVerify'>
);

export type ShopeeDataImportVerifyMutationVariables = Exact<{
  File: Scalars['Upload'];
  ColumnDataMapping: Array<Scalars['String']> | Scalars['String'];
  PayItemMapping: Array<PayItemMappingInput> | PayItemMappingInput;
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput> | PenaltyBreakdownInfoInput;
}>;


export type ShopeeDataImportVerifyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ShopeeDataImportVerify'>
);

export type ShopeeImportQueryVariables = Exact<{
  File: Scalars['Upload'];
  ColumnDataMapping: Array<Scalars['String']> | Scalars['String'];
  PayItemMapping: Array<PayItemMappingInput> | PayItemMappingInput;
  PenaltyBreakdownInfo: Array<PenaltyBreakdownInfoInput> | PenaltyBreakdownInfoInput;
  CompanyID: Scalars['String'];
  IsLastProcess: Scalars['Boolean'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  SequenceNo: Scalars['Float'];
}>;


export type ShopeeImportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ShopeeImport'>
);

export type RemoveTempDataMutationVariables = Exact<{
  Type: Scalars['String'];
}>;


export type RemoveTempDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RemoveTempData'>
);

export type ProceedForImportMutationVariables = Exact<{
  Type: Scalars['String'];
}>;


export type ProceedForImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ProceedForImport'>
);

export type EmployeePendingActivateListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type EmployeePendingActivateListingQuery = (
  { __typename?: 'Query' }
  & { EmployeePendingActivateListing: Array<(
    { __typename?: 'ContactEntity' }
    & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email'>
  )> }
);

export type ActivateEmployeesMutationVariables = Exact<{
  IsSelectAll: Scalars['Boolean'];
  Emails: Array<Scalars['String']> | Scalars['String'];
}>;


export type ActivateEmployeesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ActivateEmployees'>
);

export type NewsPublishMutationVariables = Exact<{
  NewsID: Scalars['String'];
  PostStatus: PostStatus;
}>;


export type NewsPublishMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NewsPublish'>
);

export type NewsDuplicateMutationVariables = Exact<{
  NewsID: Scalars['String'];
  ContentURL?: Maybe<Scalars['Upload']>;
}>;


export type NewsDuplicateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NewsDuplicate'>
);

export type UpdateEmployeeProfilePicMutationVariables = Exact<{
  EmployeeID: Scalars['String'];
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
}>;


export type UpdateEmployeeProfilePicMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeProfilePicUpdate'>
);

export type EmployeeListForSpecificSectionQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type EmployeeListForSpecificSectionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeListForSpecificSection'>
);

export type EmployeeListForReportingQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployeeListForReportingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeListForReporting'>
);

export type PersonnelEFileListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeStatus: Array<Scalars['String']> | Scalars['String'];
  SortBy?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  CompanyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PersonnelEFileListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PersonnelEFileListing'>
);

export type PersonnelEFileListingCountQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeStatus: Array<Scalars['String']> | Scalars['String'];
  CompanyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PersonnelEFileListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PersonnelEFileListingCount'>
);

export type EmployeeListingBySubscriptionAccountQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
}>;


export type EmployeeListingBySubscriptionAccountQuery = (
  { __typename?: 'Query' }
  & { EmployeeListingBySubscriptionAccount: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'RetirementAge' | 'OrgUnitID' | 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'JoinedDate' | 'ResignedDate' | 'Status' | 'CompanyID' | 'CalendarTitle' | 'CalendarStateID' | 'DocumentID' | 'TotalTaxRelief' | 'FormStatus' | 'StaffCategoryID' | 'EmployeeStatus' | 'EmploymentType' | 'CreatedDT' | 'ModifiedDT' | 'RoleID' | 'RoleName' | 'JoinedReferenceDate'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email' | 'Gender' | 'Race' | 'Religion' | 'Nationality'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, EmployeeStatutory?: Maybe<(
      { __typename?: 'EmployeeStatutoryEntity' }
      & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID' | 'ResidentStatus' | 'TaxMaritialStatus'>
    )>, EmployeePrevContribution?: Maybe<(
      { __typename?: 'EmployeePrevContributionEntity' }
      & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
    )>, EmployeeDependents?: Maybe<Array<(
      { __typename?: 'EmployeeDependentsEntity' }
      & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID'>
    )>>, EmployeeSalaryLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'FromSalary' | 'ToSalary' | 'SalaryAdjustmentAmount' | 'SalaryType' | 'Reason' | 'Remark'>
    )>>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'DepartmentType' | 'ParentID' | 'Description'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>, LastUpdatedDates: (
      { __typename?: 'LastUpdatedDatesOutput' }
      & Pick<LastUpdatedDatesOutput, 'TaxLastDate' | 'Cp22LastDate' | 'BIKLastDate'>
    ), EmployeeBankInfo?: Maybe<(
      { __typename?: 'EmployeeBankInfoEntity' }
      & Pick<EmployeeBankInfoEntity, 'PaymentMethod'>
    )>, EmployeeCareerLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'FromCompany' | 'ToCompany' | 'FromOrgUnit' | 'ToOrgUnit' | 'FromJobGrade' | 'ToJobGrade' | 'FromPosition' | 'ToPosition'>
    )>>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
    )> }
  )> }
);

export type EmployeeListingBySubscriptionAccountCountQueryVariables = Exact<{
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
}>;


export type EmployeeListingBySubscriptionAccountCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeListingBySubscriptionAccountCount'>
);

export type EmployeeListingByRoleIdQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  RoleID: Scalars['String'];
}>;


export type EmployeeListingByRoleIdQuery = (
  { __typename?: 'Query' }
  & { EmployeeListingByRoleID: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'RoleID' | 'RoleName'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
    )> }
  )> }
);

export type EmployeeInfoUpdateMutationVariables = Exact<{
  ContactInput: ContactInput;
  EmployeeInput: EmployeeInput;
  DocumentsInput?: Maybe<DocumentsInput>;
  DocumentID?: Maybe<Scalars['String']>;
}>;


export type EmployeeInfoUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeInfoUpdate'>
);

export type CreateEmployeeDiaryMutationVariables = Exact<{
  EmployeeDiaryInput: EmployeeDiaryInput;
  DocumentsInput: DocumentsInput;
}>;


export type CreateEmployeeDiaryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateEmployeeDiary'>
);

export type UpdateEmployeeDiaryMutationVariables = Exact<{
  EmployeeDiaryInput: EmployeeDiaryInput;
  EmployeeDiaryID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  DocumentID: Scalars['String'];
}>;


export type UpdateEmployeeDiaryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateEmployeeDiary'>
);

export type DeleteEmployeeDiaryMutationVariables = Exact<{
  EmployeeDiaryID: Scalars['String'];
}>;


export type DeleteEmployeeDiaryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteEmployeeDiary'>
);

export type CreateEmployeeAppraisalMutationVariables = Exact<{
  EmployeeAppraisalInput: EmployeeAppraisalInput;
  DocumentsInput: DocumentsInput;
}>;


export type CreateEmployeeAppraisalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateEmployeeAppraisal'>
);

export type UpdateEmployeeAppraisalMutationVariables = Exact<{
  EmployeeAppraisalInput: EmployeeAppraisalInput;
  EmployeeAppraisalID: Scalars['String'];
  DocumentsInput: DocumentsInput;
}>;


export type UpdateEmployeeAppraisalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateEmployeeAppraisal'>
);

export type DeleteEmployeeAppraisalMutationVariables = Exact<{
  EmployeeAppraisalID: Scalars['String'];
}>;


export type DeleteEmployeeAppraisalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteEmployeeAppraisal'>
);

export type CreateEmployeeTrainingMutationVariables = Exact<{
  EmployeeTrainingInput: EmployeeTrainingInput;
}>;


export type CreateEmployeeTrainingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateEmployeeTraining'>
);

export type UpdateEmployeeTrainingMutationVariables = Exact<{
  EmployeeTrainingInput: EmployeeTrainingInput;
  EmployeeTrainingID: Scalars['String'];
  DocumentsInput: DocumentsInput;
}>;


export type UpdateEmployeeTrainingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateEmployeeTraining'>
);

export type DeleteEmployeeTrainingMutationVariables = Exact<{
  EmployeeTrainingID: Scalars['String'];
}>;


export type DeleteEmployeeTrainingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteEmployeeTraining'>
);

export type GetEmployeeTrainingQueryVariables = Exact<{
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type GetEmployeeTrainingQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeTraining: Array<(
    { __typename?: 'EmployeeTrainingEntity' }
    & Pick<EmployeeTrainingEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeTrainingID' | 'EmployeeID' | 'CourseName' | 'StartDate' | 'EndDate' | 'TrainingHour' | 'TrainingProvider' | 'Trainer' | 'TrainerRating' | 'TrainerScore' | 'Superior' | 'SuperiorRating' | 'SuperiorScore'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID'>
    )> }
  )> }
);

export type PersonnelEducationListingQueryVariables = Exact<{
  PersonnelEducationID?: Maybe<Scalars['String']>;
  PersonnelID?: Maybe<Scalars['String']>;
}>;


export type PersonnelEducationListingQuery = (
  { __typename?: 'Query' }
  & { PersonnelEducationListing: Array<(
    { __typename?: 'PersonnelEducationEntity' }
    & Pick<PersonnelEducationEntity, 'PersonnelEducationID' | 'StartDate' | 'EndDate' | 'EducationLevel' | 'EducationLevelID' | 'InstitutionName' | 'Description' | 'MajorID' | 'Country' | 'Grade' | 'GradeID' | 'CGPA' | 'ModifiedDT'>
  )> }
);

export type PersonnelEducationInsertMutationVariables = Exact<{
  PersonnelEducationInsertInput: PersonnelEducationInsertInput;
}>;


export type PersonnelEducationInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelEducationInsert'>
);

export type PersonnelEducationUpdateMutationVariables = Exact<{
  PersonnelEducationInsertInput: PersonnelEducationInsertInput;
  PersonnelEducationID: Scalars['String'];
}>;


export type PersonnelEducationUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelEducationUpdate'>
);

export type PersonnelEducationDeleteMutationVariables = Exact<{
  PersonnelEducationID: Scalars['String'];
}>;


export type PersonnelEducationDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelEducationDelete'>
);

export type PersonnelParameterListingQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonnelParameterListingQuery = (
  { __typename?: 'Query' }
  & { PersonnelParameterListing: Array<(
    { __typename?: 'PersonnelParameterEntity' }
    & Pick<PersonnelParameterEntity, 'PersonnelParameterID' | 'SubscriptionAccountID' | 'Name' | 'IsDefault' | 'Voluntary' | 'PersonnelParameterType' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type HasEmployeeDocNumberQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type HasEmployeeDocNumberQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'HasEmployeeDocNumber'>
);

export type PersonnelCertificateInsertMutationVariables = Exact<{
  Input: PersonnelCertificateInput;
}>;


export type PersonnelCertificateInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelCertificateInsert'>
);

export type PersonnelCertificateUpdateMutationVariables = Exact<{
  Input: PersonnelCertificateInput;
  PersonnelCertificateID: Scalars['String'];
}>;


export type PersonnelCertificateUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelCertificateUpdate'>
);

export type PersonnelCertificateDeleteMutationVariables = Exact<{
  PersonnelCertificateID: Scalars['String'];
}>;


export type PersonnelCertificateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelCertificateDelete'>
);

export type PersonnelReferenceListingQueryVariables = Exact<{
  PersonnelID: Scalars['String'];
  PersonnelReferenceID?: Maybe<Scalars['String']>;
}>;


export type PersonnelReferenceListingQuery = (
  { __typename?: 'Query' }
  & { PersonnelReferenceListing: Array<(
    { __typename?: 'PersonnelReferenceEntity' }
    & Pick<PersonnelReferenceEntity, 'PersonnelID' | 'PersonnelReferenceID' | 'Name' | 'MobileNo' | 'Email' | 'Relationship' | 'JobDesignation' | 'CompanyName' | 'Status'>
  )> }
);

export type PersonnelReferenceInsertMutationVariables = Exact<{
  Input: PersonnelReferenceInput;
}>;


export type PersonnelReferenceInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelReferenceInsert'>
);

export type PersonnelReferenceUpdateMutationVariables = Exact<{
  Input: PersonnelReferenceInput;
  PersonnelReferenceID: Scalars['String'];
}>;


export type PersonnelReferenceUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelReferenceUpdate'>
);

export type PersonnelReferenceDeleteMutationVariables = Exact<{
  PersonnelReferenceID: Scalars['String'];
}>;


export type PersonnelReferenceDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelReferenceDelete'>
);

export type PersonnelHealthInfoSummaryQueryVariables = Exact<{
  PersonnelID: Scalars['String'];
}>;


export type PersonnelHealthInfoSummaryQuery = (
  { __typename?: 'Query' }
  & { PersonnelHealthInfoSummary: (
    { __typename?: 'PersonnelHealthInfoEntity' }
    & Pick<PersonnelHealthInfoEntity, 'PersonnelHealthInfoID' | 'Height' | 'Weight' | 'BMI' | 'BloodType' | 'DietaryPreference' | 'IsSmoker' | 'VaccinationList'>
    & { VaccinationListSummary?: Maybe<Array<(
      { __typename?: 'VaccinationListItemOutput' }
      & Pick<VaccinationListItemOutput, 'vaccinationID' | 'vaccinationDate'>
      & { vaccinationAttachedment?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentFile' | 'BucketFileName'>
      )> }
    )>>, MedicalRecords?: Maybe<Array<(
      { __typename?: 'MedicalRecordEntity' }
      & Pick<MedicalRecordEntity, 'MedicalRecordID' | 'DocumentID' | 'Description'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentFile' | 'BucketFileName'>
      )> }
    )>> }
  ) }
);

export type PersonnelHealthInfoInsertMutationVariables = Exact<{
  PersonnelHealthInfoInput: PersonnelHealthInfoInput;
  MedicalRecordInput: Array<MedicalRecordInput> | MedicalRecordInput;
  VaccinationRecordInput: Array<VaccinationRecordInput> | VaccinationRecordInput;
}>;


export type PersonnelHealthInfoInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelHealthInfoInsert'>
);

export type PersonnelHealthInfoUpdateMutationVariables = Exact<{
  PersonnelHealthInfoInput: PersonnelHealthInfoInput;
  PersonnelHealthInfoID: Scalars['String'];
  MedicalRecordInput: Array<MedicalRecordInput> | MedicalRecordInput;
  VaccinationRecordInput: Array<VaccinationRecordInput> | VaccinationRecordInput;
  AttachmentToRemove?: Maybe<Array<DocumentsInput> | DocumentsInput>;
}>;


export type PersonnelHealthInfoUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelHealthInfoUpdate'>
);

export type MedicalRecordDeleteMutationVariables = Exact<{
  MedicalRecordID: Scalars['String'];
}>;


export type MedicalRecordDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MedicalRecordDelete'>
);

export type JobHistoryListingQueryVariables = Exact<{
  PersonnelID: Scalars['String'];
}>;


export type JobHistoryListingQuery = (
  { __typename?: 'Query' }
  & { PersonnelJobHistoryListing: Array<(
    { __typename?: 'PersonnelJobHistoryEntity' }
    & Pick<PersonnelJobHistoryEntity, 'PersonnelJobHistoryID' | 'StartDate' | 'EndDate' | 'CompanyName' | 'ModifiedDT' | 'JobPosition' | 'LastDrawnSalary' | 'Industry' | 'IndustryID' | 'Specialization' | 'SpecializationID' | 'JobDescription'>
    & { CompanyAddress?: Maybe<(
      { __typename?: 'Address2' }
      & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
    )> }
  )> }
);

export type PersonnelJobHistoryInsertMutationVariables = Exact<{
  Input: PersonnelJobHistoryInput;
}>;


export type PersonnelJobHistoryInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelJobHistoryInsert'>
);

export type PersonnelJobHistoryUpdateMutationVariables = Exact<{
  PersonnelJobHistoryID: Scalars['String'];
  Input: PersonnelJobHistoryInput;
}>;


export type PersonnelJobHistoryUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelJobHistoryUpdate'>
);

export type PersonnelJobHistoryDeleteMutationVariables = Exact<{
  PersonnelJobHistoryID: Scalars['String'];
}>;


export type PersonnelJobHistoryDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelJobHistoryDelete'>
);

export type UserDefineDetailBulkInsertMutationVariables = Exact<{
  UserDefineDetailInput: Array<UserDefineDetailInput> | UserDefineDetailInput;
}>;


export type UserDefineDetailBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UserDefineDetailBulkInsert'>
);

export type UserDefineDetailListingQueryVariables = Exact<{
  PersonnelID?: Maybe<Scalars['String']>;
}>;


export type UserDefineDetailListingQuery = (
  { __typename?: 'Query' }
  & { UserDefineDetailListing: Array<(
    { __typename?: 'UserDefineDetailEntity' }
    & Pick<UserDefineDetailEntity, 'UserDefineDetailID' | 'PersonnelID' | 'UserDefineID' | 'Description' | 'SequenceNo'>
    & { UserDefine?: Maybe<(
      { __typename?: 'UserDefineEntity' }
      & Pick<UserDefineEntity, 'Name'>
    )> }
  )> }
);

export type UserDefineListingQueryVariables = Exact<{ [key: string]: never; }>;


export type UserDefineListingQuery = (
  { __typename?: 'Query' }
  & { UserDefineListing: Array<(
    { __typename?: 'UserDefineEntity' }
    & Pick<UserDefineEntity, 'UserDefineID' | 'SubscriptionAccountID' | 'Name' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type ContractInfoListingQueryVariables = Exact<{
  PersonnelID: Scalars['String'];
  ContractInfoID?: Maybe<Scalars['String']>;
}>;


export type ContractInfoListingQuery = (
  { __typename?: 'Query' }
  & { ContractInfoListing: Array<(
    { __typename?: 'ContractInfoEntity' }
    & Pick<ContractInfoEntity, 'ContractInfoID' | 'ContractDocType' | 'DocNo' | 'StartDate' | 'EndDate' | 'DocumentID' | 'CreatedBy' | 'ModifiedBy' | 'Remark' | 'PersonnelID' | 'ContractDocTypeID'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'Description' | 'DocumentFile' | 'DocumentType' | 'ModifiedDT'>
    )>, ContractDocumentType?: Maybe<(
      { __typename?: 'PersonnelParameterEntity' }
      & Pick<PersonnelParameterEntity, 'PersonnelParameterID' | 'Name' | 'PersonnelParameterType'>
    )> }
  )> }
);

export type ZakatContributionDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type ZakatContributionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ZakatContributionDelete'>
);

export type ZakatContributionListingQueryVariables = Exact<{
  input: ZakatContributionInput;
}>;


export type ZakatContributionListingQuery = (
  { __typename?: 'Query' }
  & { ZakatContributionListing: Array<(
    { __typename?: 'ZakatContributionEntity' }
    & Pick<ZakatContributionEntity, 'PayrollCycleID' | 'ID' | 'EmployeeID' | 'Remark' | 'Amount' | 'Percentage' | 'ZakatStateID' | 'AccountNo' | 'ModifiedDT' | 'CreatedDT'>
    & { PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayName' | 'PayItemType'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, StartPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PeriodYearMonth' | 'PayPeriodID'>
    )>, EndPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PeriodYearMonth' | 'PayPeriodID'>
    )>, PayrollCycle?: Maybe<(
      { __typename?: 'PayrollCycleEntity' }
      & Pick<PayrollCycleEntity, 'PayrollCycleID' | 'Description'>
    )> }
  )> }
);

export type ZakatContributionInsertMutationVariables = Exact<{
  ZakatContributionInput: ZakatContributionInput;
}>;


export type ZakatContributionInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ZakatContributionInsert'>
);

export type ZakatContributionUpdateMutationVariables = Exact<{
  ID: Scalars['String'];
  ZakatContributionInput: ZakatContributionInput;
}>;


export type ZakatContributionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ZakatContributionUpdate'>
);

export type ZakatSummaryQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type ZakatSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ZakatSummary'>
);

export type EmployeeListForJobInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployeeListForJobInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeListForJobInfo'>
);

export type PersonnelEmployementInfoListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type PersonnelEmployementInfoListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PersonnelEmployementInfoListing'>
);

export type PersonnelEmployementInfoListingCountQueryVariables = Exact<{
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
}>;


export type PersonnelEmployementInfoListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PersonnelEmployementInfoListingCount'>
);

export type JobInfoEmployeeListingBySubscriptionAccountQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus: Array<Scalars['String']> | Scalars['String'];
  EmployeeType: Array<Scalars['String']> | Scalars['String'];
  OrgUnitID: Array<Scalars['String']> | Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type JobInfoEmployeeListingBySubscriptionAccountQuery = (
  { __typename?: 'Query' }
  & { EmployeeListingBySubscriptionAccount: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'CompanyID' | 'EmployeeID' | 'EmployeeNo' | 'ResignedDate'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName'>
    )> }
  )> }
);

export type IsZakatPeriodOverlapQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  ZakatID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
}>;


export type IsZakatPeriodOverlapQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsZakatPeriodOverlap'>
);

export type GetEmployeeAppSecurityListingQueryVariables = Exact<{
  EmployeeAppSecurityID?: Maybe<Scalars['String']>;
}>;


export type GetEmployeeAppSecurityListingQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeAppSecurityListing?: Maybe<Array<(
    { __typename?: 'EmployeeAppSecurityOutput' }
    & Pick<EmployeeAppSecurityOutput, 'EmployeeAppSecurityID' | 'Title' | 'ModifiedDT' | 'InUse'>
    & { PermissionList?: Maybe<Array<(
      { __typename?: 'EmployeeAppSecurityPermissionOutput' }
      & Pick<EmployeeAppSecurityPermissionOutput, 'EmployeeAppSecurityPermissionID' | 'PermissionID' | 'PermissionName'>
    )>> }
  )>> }
);

export type CreateUpdateAppSecurityMutationVariables = Exact<{
  input: EmployeeAppSecurityInput;
  permissionArr?: Maybe<Array<HrPermission> | HrPermission>;
}>;


export type CreateUpdateAppSecurityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateUpdateAppSecurity'>
);

export type GetEmployeeAppSecurityAssignmentListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeAppSecurityAssignmentID?: Maybe<Scalars['String']>;
}>;


export type GetEmployeeAppSecurityAssignmentListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetEmployeeAppSecurityAssignmentListing'>
);

export type CreateUpdateAppSecurityAssignmentMutationVariables = Exact<{
  input: EmployeeAppSecurityAssignmentInput;
}>;


export type CreateUpdateAppSecurityAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateUpdateAppSecurityAssignment'>
);

export type GetEmployeeAppSecurityPermissionsByContactIdQueryVariables = Exact<{
  ContactID: Scalars['String'];
  Permissions?: Maybe<Array<HrPermission> | HrPermission>;
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type GetEmployeeAppSecurityPermissionsByContactIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetEmployeeAppSecurityPermissionsByContactID'>
);

export type DepartmentDivisionListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type DepartmentDivisionListingQuery = (
  { __typename?: 'Query' }
  & { DepartmentDivisionListing: Array<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'DepartmentID' | 'CompanyID' | 'Description' | 'DepartmentType' | 'ParentID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type DepartmentDetailQueryVariables = Exact<{
  DepartmentID: Scalars['String'];
}>;


export type DepartmentDetailQuery = (
  { __typename?: 'Query' }
  & { DepartmentDetail: Array<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'DepartmentID' | 'CompanyID' | 'Description' | 'DepartmentType' | 'ParentID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type DivisionListingQueryVariables = Exact<{
  DepartmentID: Scalars['String'];
}>;


export type DivisionListingQuery = (
  { __typename?: 'Query' }
  & { DivisionListing: Array<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'DepartmentID' | 'CompanyID' | 'Description' | 'DepartmentType' | 'ParentID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type DepartmentListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type DepartmentListingQuery = (
  { __typename?: 'Query' }
  & { DepartmentListing: Array<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'DepartmentID' | 'CompanyID' | 'Description' | 'DepartmentType' | 'ParentID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type DepartmentInsertMutationVariables = Exact<{
  input: DepartmentInput;
}>;


export type DepartmentInsertMutation = (
  { __typename?: 'Mutation' }
  & { DepartmentInsert: (
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'DepartmentID'>
  ) }
);

export type DepartmentDeleteMutationVariables = Exact<{
  DepartmentUpdateInput: DepartmentUpdateInput;
}>;


export type DepartmentDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DepartmentDelete'>
);

export type DivisionInsertMutationVariables = Exact<{
  DepartmentInput: DepartmentInput;
}>;


export type DivisionInsertMutation = (
  { __typename?: 'Mutation' }
  & { DivisionInsert: (
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'DepartmentID'>
  ) }
);

export type DepartmentUpdateMutationVariables = Exact<{
  input: DepartmentUpdateInput;
  DepartmentID: Scalars['String'];
}>;


export type DepartmentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DepartmentUpdate'>
);

export type DivisionUpdateMutationVariables = Exact<{
  input: DepartmentUpdateInput;
  DepartmentID: Scalars['String'];
}>;


export type DivisionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DivisionUpdate'>
);

export type DivisionDeleteMutationVariables = Exact<{
  DepartmentID: Scalars['String'];
}>;


export type DivisionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DivisionDelete'>
);

export type TraineeEvalListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
}>;


export type TraineeEvalListingQuery = (
  { __typename?: 'Query' }
  & { TraineeEvalListing: Array<(
    { __typename?: 'TraineeEvalEntity' }
    & Pick<TraineeEvalEntity, 'TraineeEvalID' | 'ScheduledTrainingID' | 'CompanyID' | 'Score' | 'Remarks' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'DocumentID' | 'TotalTaxRelief' | 'FormStatus' | 'EmploymentType' | 'CreatedDT' | 'ModifiedDT'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )>, EmployeeStatutory?: Maybe<(
        { __typename?: 'EmployeeStatutoryEntity' }
        & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID'>
      )>, EmployeePrevContribution?: Maybe<(
        { __typename?: 'EmployeePrevContributionEntity' }
        & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
      )>, EmployeeDependents?: Maybe<Array<(
        { __typename?: 'EmployeeDependentsEntity' }
        & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID'>
      )>>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'DepartmentID' | 'DepartmentType' | 'ParentID' | 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )> }
  )> }
);

export type TraineeEvalListingAllQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type TraineeEvalListingAllQuery = (
  { __typename?: 'Query' }
  & { TraineeEvalListingAll: Array<(
    { __typename?: 'TraineeEvalEntity' }
    & Pick<TraineeEvalEntity, 'TraineeEvalID' | 'ScheduledTrainingID' | 'CompanyID' | 'Score' | 'Remarks' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'DocumentID' | 'TotalTaxRelief' | 'FormStatus' | 'EmploymentType' | 'CreatedDT' | 'ModifiedDT'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )>, EmployeeStatutory?: Maybe<(
        { __typename?: 'EmployeeStatutoryEntity' }
        & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID'>
      )>, EmployeePrevContribution?: Maybe<(
        { __typename?: 'EmployeePrevContributionEntity' }
        & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
      )>, EmployeeDependents?: Maybe<Array<(
        { __typename?: 'EmployeeDependentsEntity' }
        & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID'>
      )>>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'DepartmentID' | 'DepartmentType' | 'ParentID' | 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )> }
  )> }
);

export type SupervisorEvalListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
}>;


export type SupervisorEvalListingQuery = (
  { __typename?: 'Query' }
  & { SupervisorEvalListing: Array<(
    { __typename?: 'SupervisorEvalEntity' }
    & Pick<SupervisorEvalEntity, 'SupervisorEvalID' | 'ScheduledTrainingID' | 'CompanyID' | 'Score' | 'Remarks' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'DocumentID' | 'TotalTaxRelief' | 'FormStatus' | 'EmploymentType' | 'CreatedDT' | 'ModifiedDT'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )>, EmployeeStatutory?: Maybe<(
        { __typename?: 'EmployeeStatutoryEntity' }
        & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID'>
      )>, EmployeePrevContribution?: Maybe<(
        { __typename?: 'EmployeePrevContributionEntity' }
        & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
      )>, EmployeeDependents?: Maybe<Array<(
        { __typename?: 'EmployeeDependentsEntity' }
        & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID'>
      )>>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'DepartmentID' | 'DepartmentType' | 'ParentID' | 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )> }
  )> }
);

export type CourseEvalListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
}>;


export type CourseEvalListingQuery = (
  { __typename?: 'Query' }
  & { CourseEvalListing: Array<(
    { __typename?: 'CourseEvalEntity' }
    & Pick<CourseEvalEntity, 'CourseEvalID' | 'ScheduledTrainingID' | 'CompanyID' | 'Score' | 'Remarks' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'DocumentID' | 'TotalTaxRelief' | 'FormStatus' | 'EmploymentType' | 'CreatedDT' | 'ModifiedDT'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )>, EmployeeStatutory?: Maybe<(
        { __typename?: 'EmployeeStatutoryEntity' }
        & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID'>
      )>, EmployeePrevContribution?: Maybe<(
        { __typename?: 'EmployeePrevContributionEntity' }
        & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
      )>, EmployeeDependents?: Maybe<Array<(
        { __typename?: 'EmployeeDependentsEntity' }
        & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID'>
      )>>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'DepartmentID' | 'DepartmentType' | 'ParentID' | 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )> }
  )> }
);

export type TrainerEvalListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ScheduledTrainingID: Scalars['String'];
}>;


export type TrainerEvalListingQuery = (
  { __typename?: 'Query' }
  & { TrainerEvalListing: Array<(
    { __typename?: 'TrainerEvalEntity' }
    & Pick<TrainerEvalEntity, 'TrainerEvalID' | 'ScheduledTrainingID' | 'CompanyID' | 'Score' | 'Remarks' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'DocumentID' | 'TotalTaxRelief' | 'FormStatus' | 'EmploymentType' | 'CreatedDT' | 'ModifiedDT'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )>, EmployeeStatutory?: Maybe<(
        { __typename?: 'EmployeeStatutoryEntity' }
        & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID'>
      )>, EmployeePrevContribution?: Maybe<(
        { __typename?: 'EmployeePrevContributionEntity' }
        & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
      )>, EmployeeDependents?: Maybe<Array<(
        { __typename?: 'EmployeeDependentsEntity' }
        & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID'>
      )>>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'DepartmentID' | 'DepartmentType' | 'ParentID' | 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )> }
  )> }
);

export type GetSupervisorEvalLuationQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetSupervisorEvalLuationQuery = (
  { __typename?: 'Query' }
  & { GetSupervisorEvalLuation: Array<(
    { __typename?: 'SupervisorEvaluationListingOutput' }
    & Pick<SupervisorEvaluationListingOutput, 'Rate'>
    & { ScheduleTraining?: Maybe<(
      { __typename?: 'ScheduleTrainingEntity' }
      & Pick<ScheduleTrainingEntity, 'ScheduledTrainingID' | 'SubscriptionAccountID' | 'CompanyID' | 'TrainingProviderID' | 'CourseID' | 'CourseName' | 'StartDate' | 'EndDate' | 'Venue' | 'CourseFee' | 'LumpSum' | 'PerClass' | 'MaxPax' | 'Remarks' | 'TraineeEval' | 'CourseEval' | 'TrainerEval' | 'SupervisorEval' | 'TrainingSchedule' | 'QRCode' | 'RegisteredTrainee' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID'>
    )> }
  )> }
);

export type GetTrainerEvalLuationQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetTrainerEvalLuationQuery = (
  { __typename?: 'Query' }
  & { GetTrainerEvalLuation: Array<(
    { __typename?: 'TrainerEvaluationListingOutput' }
    & Pick<TrainerEvaluationListingOutput, 'Rate'>
    & { Provider?: Maybe<(
      { __typename?: 'AuthorisedTrainingProviderEntity' }
      & Pick<AuthorisedTrainingProviderEntity, 'ID' | 'SubscriptionAccountID' | 'DocumentID' | 'Name' | 'IsExternal' | 'ApprovedCourses' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'NickName' | 'MobileNo' | 'OfficeNo' | 'FaxNo' | 'Email' | 'NRIC' | 'PassportNo' | 'PassportNoLHDN' | 'Nationality' | 'Marital' | 'Gender' | 'Race' | 'Religion' | 'BirthDate' | 'ProfilePicture' | 'WebUrl' | 'SameAddress'>
        & { Address?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
        )>, PermanentAddress?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
        )> }
      )> }
    )>, TrainingCourse?: Maybe<Array<(
      { __typename?: 'TrainingCourseOutput' }
      & Pick<TrainingCourseOutput, 'Rate'>
      & { Course?: Maybe<(
        { __typename?: 'ScheduleTrainingEntity' }
        & Pick<ScheduleTrainingEntity, 'ScheduledTrainingID' | 'SubscriptionAccountID' | 'CompanyID' | 'TrainingProviderID' | 'CourseID' | 'CourseName' | 'StartDate' | 'EndDate' | 'Venue' | 'CourseFee' | 'LumpSum' | 'PerClass' | 'MaxPax' | 'Remarks' | 'TraineeEval' | 'CourseEval' | 'TrainerEval' | 'SupervisorEval' | 'TrainingSchedule' | 'QRCode' | 'RegisteredTrainee' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID'>
      )> }
    )>> }
  )> }
);

export type TraineeEvalSubmitMutationVariables = Exact<{
  input: TraineeEvalInput;
}>;


export type TraineeEvalSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TraineeEvalSubmit'>
);

export type SupervisorEvalSubmitMutationVariables = Exact<{
  input: SupervisorEvalInput;
}>;


export type SupervisorEvalSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SupervisorEvalSubmit'>
);

export type CourseEvalSubmitMutationVariables = Exact<{
  input: CourseEvalInput;
}>;


export type CourseEvalSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CourseEvalSubmit'>
);

export type TrainerEvalSubmitMutationVariables = Exact<{
  input: TrainerEvalInput;
}>;


export type TrainerEvalSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TrainerEvalSubmit'>
);

export type FinalRatingListingQueryVariables = Exact<{ [key: string]: never; }>;


export type FinalRatingListingQuery = (
  { __typename?: 'Query' }
  & { FinalRatingListing: Array<(
    { __typename?: 'FinalRatingHeaderEntity' }
    & Pick<FinalRatingHeaderEntity, 'FinalRatingHeaderID' | 'SubscriptionAccountID' | 'Title' | 'Scale' | 'isInUse'>
    & { FinalRatingDetails?: Maybe<Array<(
      { __typename?: 'FinalRatingDetailEntity' }
      & Pick<FinalRatingDetailEntity, 'FinalRatingDetailID' | 'Description' | 'ScaleSequence' | 'From' | 'To' | 'FinalRating'>
    )>> }
  )> }
);

export type FinalRatingSubmitMutationVariables = Exact<{
  input: FinalRatingHeaderInput;
}>;


export type FinalRatingSubmitMutation = (
  { __typename?: 'Mutation' }
  & { FinalRatingSubmit: (
    { __typename?: 'FinalRatingHeaderEntity' }
    & Pick<FinalRatingHeaderEntity, 'FinalRatingHeaderID'>
  ) }
);

export type FinalRatingDeleteMutationVariables = Exact<{
  FinalRatingHeaderID: Scalars['String'];
}>;


export type FinalRatingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FinalRatingDelete'>
);

export type GetFulfilmentByJobGradeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  JobGradeID: Scalars['String'];
}>;


export type GetFulfilmentByJobGradeQuery = (
  { __typename?: 'Query' }
  & { GetFulfilmentByJobGrade: Array<(
    { __typename?: 'EmployeeFulfilmentOutput' }
    & Pick<EmployeeFulfilmentOutput, 'EmployeeID' | 'CourseName' | 'Date'>
  )> }
);

export type GetFulfilmentByCourseQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetFulfilmentByCourseQuery = (
  { __typename?: 'Query' }
  & { GetFulfilmentByCourse: Array<(
    { __typename?: 'CourseFulfilmentOutput' }
    & Pick<CourseFulfilmentOutput, 'ScheduledTrainingID' | 'OS_Fulfillment'>
    & { Employee?: Maybe<Array<(
      { __typename?: 'EmployeeFulfilmentOutput' }
      & Pick<EmployeeFulfilmentOutput, 'EmployeeID' | 'CourseName' | 'Date'>
    )>> }
  )> }
);

export type GetOsFulfilmentByJobGradeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetOsFulfilmentByJobGradeQuery = (
  { __typename?: 'Query' }
  & { GetOSFulfilmentByJobGrade: Array<(
    { __typename?: 'GetOSFulfilmentByJobGradeOutput' }
    & Pick<GetOsFulfilmentByJobGradeOutput, 'JobGradeID' | 'OS_Fulfillment'>
  )> }
);

export type HelpdeskSubjectListingQueryVariables = Exact<{ [key: string]: never; }>;


export type HelpdeskSubjectListingQuery = (
  { __typename?: 'Query' }
  & { HelpdeskSubjectListing: Array<(
    { __typename?: 'HelpdeskSubjectEntity' }
    & Pick<HelpdeskSubjectEntity, 'ID' | 'Subject' | 'Assignees' | 'InUse' | 'Status' | 'ModifiedDT'>
  )> }
);

export type HelpdeskAssigneeListingQueryVariables = Exact<{ [key: string]: never; }>;


export type HelpdeskAssigneeListingQuery = (
  { __typename?: 'Query' }
  & { HelpdeskAssigneeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )> }
  )> }
);

export type IsHelpdeskSubjectExistQueryVariables = Exact<{
  SubjectID?: Maybe<Scalars['String']>;
  Subject: Scalars['String'];
}>;


export type IsHelpdeskSubjectExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsHelpdeskSubjectExist'>
);

export type HelpdeskSubjectDetailQueryVariables = Exact<{
  SubjectID: Scalars['String'];
}>;


export type HelpdeskSubjectDetailQuery = (
  { __typename?: 'Query' }
  & { HelpdeskSubjectDetail: (
    { __typename?: 'HelpdeskSubjectEntity' }
    & Pick<HelpdeskSubjectEntity, 'ID' | 'Subject' | 'Description' | 'RequireAttachment' | 'DefaultAssignee' | 'TargetResolutionDays' | 'ReminderFrequency'>
  ) }
);

export type HelpdeskSubjectSubmitMutationVariables = Exact<{
  input: HelpdeskSubjectInput;
}>;


export type HelpdeskSubjectSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HelpdeskSubjectSubmit'>
);

export type HelpdeskSubjectActionMutationVariables = Exact<{
  SubjectID: Scalars['String'];
  Action: Scalars['String'];
}>;


export type HelpdeskSubjectActionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HelpdeskSubjectAction'>
);

export type GetHelpdeskTicketSummaryQueryVariables = Exact<{
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
}>;


export type GetHelpdeskTicketSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getHelpdeskTicketSummary'>
);

export type HelpdeskTicketDetailQueryVariables = Exact<{
  HelpdeskTicketID: Scalars['String'];
}>;


export type HelpdeskTicketDetailQuery = (
  { __typename?: 'Query' }
  & { HelpdeskTicketDetail?: Maybe<(
    { __typename?: 'HelpdeskTicketEntity' }
    & Pick<HelpdeskTicketEntity, 'ID' | 'TicketNo' | 'Status' | 'isOverdue' | 'HelpdeskSubjectID' | 'SubjectDetails' | 'EmployeeID' | 'RequestDescription' | 'RequestAttachment' | 'ResolutionDescription' | 'ResolutionAttachment'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, RequestDocuments?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>>, ResolutionDocuments?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>> }
  )> }
);

export type HelpdeskTicketListingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  RequestType: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  HelpdeskSubjectType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  Assignee?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type HelpdeskTicketListingQuery = (
  { __typename?: 'Query' }
  & { HelpdeskTicketListing?: Maybe<Array<(
    { __typename?: 'HelpdeskTicketEntity' }
    & Pick<HelpdeskTicketEntity, 'ID' | 'TicketNo' | 'Status' | 'isOverdue' | 'CreatedDT' | 'ModifiedDT' | 'CurrentAssignees' | 'SubjectDetails' | 'ReassignedAssignee' | 'RequestDescription' | 'AssigneeClosedTicket' | 'RequestAttachment'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, RequestDocuments?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>> }
  )>> }
);

export type HelpdeskAssigneeApprovalHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type HelpdeskAssigneeApprovalHistoryQuery = (
  { __typename?: 'Query' }
  & { HelpdeskAssigneeApprovalHistory?: Maybe<Array<(
    { __typename?: 'HelpdeskTicketEntity' }
    & Pick<HelpdeskTicketEntity, 'ID' | 'TicketNo' | 'EmployeeID' | 'ContactID' | 'SubjectDetails' | 'RequestDescription' | 'ModifiedDT' | 'Status'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )>> }
);

export type HelpdeskToDoListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type HelpdeskToDoListingQuery = (
  { __typename?: 'Query' }
  & { HelpdeskToDoListing?: Maybe<Array<(
    { __typename?: 'HelpdeskTicketEntity' }
    & Pick<HelpdeskTicketEntity, 'ID' | 'TicketNo' | 'SubjectDetails' | 'EmployeeID' | 'ContactID' | 'RequestDescription' | 'CreatedDT' | 'Status' | 'isOverdue'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )>> }
);

export type UpdateOpenDtMutationVariables = Exact<{
  HelpdeskTicketID: Scalars['String'];
}>;


export type UpdateOpenDtMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateOpenDT'>
);

export type ReassignAssigneeMutationVariables = Exact<{
  HelpdeskTicketID: Scalars['String'];
  Assignee: Array<Scalars['String']> | Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type ReassignAssigneeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReassignAssignee'>
);

export type SubmitHelpdeskTicketMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  input: HelpdeskTicketInput;
  mode: Scalars['String'];
}>;


export type SubmitHelpdeskTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitHelpdeskTicket'>
);

export type WithdrawOpenTicketMutationVariables = Exact<{
  HelpdeskTicketID: Scalars['String'];
}>;


export type WithdrawOpenTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'WithdrawOpenTicket'>
);

export type HelpdeskListingReportQueryVariables = Exact<{
  input: HelpdeskListingReportInput;
}>;


export type HelpdeskListingReportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'HelpdeskListingReport'>
);

export type SubscriptionUserSummaryQueryVariables = Exact<{
  SubscriptionUserID: Scalars['String'];
}>;


export type SubscriptionUserSummaryQuery = (
  { __typename?: 'Query' }
  & { EmployeeSummaryBySubscription: (
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'CompanyID' | 'DepartmentID' | 'DivisionID' | 'DesignationID' | 'JobGradeID' | 'EmployeeNo' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'GeneralDocCount' | 'EAFormDocCount' | 'PaySlipDocCount' | 'PersonalDocCount' | 'GeneralDocTotalSize' | 'EAFormDocTotalSize' | 'PaySlipDocTotalSize' | 'PersonalDocTotalSize' | 'TotalLeave' | 'TotalAnnualLeave' | 'TotalSickLeave' | 'TotalUnpaidLeave' | 'TotalClaim' | 'TotalClaimApproved' | 'TotalClaimAmountApproved' | 'TotalRequisition' | 'TotalPurchaseRequisition' | 'TotalTravelRequest' | 'TotalCashAdvanceRequisition' | 'TotalOtherRequisition' | 'TotalUnreadNews'>
    & { OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Description'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, EmployeeDepartment?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description' | 'DepartmentType'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, EmployeeCareerLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'MovementType'>
      & { ToPositionTable?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'Name'>
      )> }
    )>>, LastUpdatedDates: (
      { __typename?: 'LastUpdatedDatesOutput' }
      & Pick<LastUpdatedDatesOutput, 'EmployeeLastDate' | 'AssetsLastDate' | 'BIKLastDate' | 'CareerLastDate' | 'SubordinateLastDate' | 'TaxLastDate'>
    ) }
  ) }
);

export type HomeSummaryQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type HomeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'HomeSummary'>
);

export type EmployeeMainMenuSummaryQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type EmployeeMainMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeMainMenuSummary'>
);

export type EmploymentInfoSummaryQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type EmploymentInfoSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmploymentInfoSummary'>
);

export type PersonnelMenuSummaryQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  ContactID: Scalars['String'];
}>;


export type PersonnelMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PersonnelMenuSummary'>
);

export type MyEmployeeInfoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type MyEmployeeInfoQuery = (
  { __typename?: 'Query' }
  & { MyEmployeeInfo?: Maybe<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'DesignationID' | 'CalendarPolicyID' | 'OrgUnitID' | 'ContactID' | 'JobGradeID' | 'TotalAnnualLeave' | 'TotalSickLeave' | 'TotalUnpaidLeave' | 'TotalClaimApproved' | 'TotalClaimAmountApproved' | 'TotalTravelRequest' | 'TotalPurchaseRequisition' | 'TotalCashAdvanceRequisition' | 'TotalOtherRequisition' | 'RoleID' | 'RoleName' | 'Status' | 'ReportTo' | 'MyTodo' | 'MyNotification' | 'GeneralDocCount' | 'EAFormDocCount' | 'PaySlipDocCount' | 'PersonalDocCount' | 'GeneralDocTotalSize' | 'EAFormDocTotalSize' | 'PaySlipDocTotalSize' | 'PersonalDocTotalSize'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Salutation'>
      & { EmployeeSalutation?: Maybe<(
        { __typename?: 'PersonnelParameterEntity' }
        & Pick<PersonnelParameterEntity, 'Name'>
      )> }
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Description'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'SubscriptionAccountID' | 'CompanyName'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, LastUpdatedDates: (
      { __typename?: 'LastUpdatedDatesOutput' }
      & Pick<LastUpdatedDatesOutput, 'CareerLastDate'>
    ), EmployeeSubordinates: Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'CompanyID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'ParentID' | 'DocumentType' | 'FileSize' | 'DocumentFile' | 'DocumentID'>
    )> }
  )> }
);

export type NewNotificationSubscriptionVariables = Exact<{
  ToEmployeeID: Scalars['String'];
}>;


export type NewNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { newNotifications: (
    { __typename?: 'NotificationEntity' }
    & Pick<NotificationEntity, 'ToEmployeeID' | 'Message'>
  ) }
);

export type NotificationListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
}>;


export type NotificationListingQuery = (
  { __typename?: 'Query' }
  & { NotificationListing: Array<(
    { __typename?: 'NotificationEntity' }
    & Pick<NotificationEntity, 'NotificationID' | 'CompanyID' | 'FromEmployeeID' | 'ToEmployeeID' | 'NotificationType' | 'RequestType' | 'RequestID' | 'IsTodo' | 'Message' | 'ReadStatus' | 'CreatedDT' | 'CreatedBy'>
  )> }
);

export type MyNewsListQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  EmployeeID: Scalars['String'];
}>;


export type MyNewsListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MyNewsList'>
);

export type EmployeeCompanyListingQueryVariables = Exact<{
  EmployeeEmail: Scalars['String'];
}>;


export type EmployeeCompanyListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeCompanyListing'>
);

export type CompanyListingByEmailQueryVariables = Exact<{
  EmployeeEmail: Scalars['String'];
}>;


export type CompanyListingByEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyListingByEmail'>
);

export type SimpleCompanyListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  CompanyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SimpleCompanyListingQuery = (
  { __typename?: 'Query' }
  & { CompanyListing: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
  )> }
);

export type PayrollHomeInfoLinesOutputQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type PayrollHomeInfoLinesOutputQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PayrollHomeInfoLinesOutput'>
);

export type TmsPolicyMenuInfoLinesOutputQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type TmsPolicyMenuInfoLinesOutputQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TmsPolicyMenuInfoLinesOutput'>
);

export type GetCompanyPermissionQueryVariables = Exact<{
  hrPermissions?: Maybe<Array<HrPermission> | HrPermission>;
  CompanyID?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCompanyPermission'>
);

export type EmployeeDependentDeleteMutationVariables = Exact<{
  EmployeeDependentsID: Scalars['String'];
}>;


export type EmployeeDependentDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeDependentDelete'>
);

export type UpdateEmployeeLastLoginCompanyMutationVariables = Exact<{
  EmployeeID: Scalars['String'];
  LastestAccessedEntity: Scalars['String'];
}>;


export type UpdateEmployeeLastLoginCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateEmployeeLastLoginCompany'>
);

export type PersonnalInfoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type PersonnalInfoQuery = (
  { __typename?: 'Query' }
  & { EmployeeSummary: (
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'IsOKU'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'NickName' | 'ContactID' | 'FullName' | 'Email' | 'Nationality' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'BirthDate' | 'Gender' | 'Marital' | 'Race' | 'Religion' | 'Salutation' | 'SameAddress' | 'ProfilePicture'>
      & { EmployeeSalutation?: Maybe<(
        { __typename?: 'PersonnelParameterEntity' }
        & Pick<PersonnelParameterEntity, 'Name'>
      )>, Address?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
      )>, PermanentAddress?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
      )> }
    )> }
  ) }
);

export type EmployeeInfoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type EmployeeInfoQuery = (
  { __typename?: 'Query' }
  & { EmployeeSummary: (
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'RetirementAge' | 'EmployeeID' | 'CalendarTitle' | 'CalendarPolicyID' | 'IsOKU' | 'IsHOD' | 'IsSuperUser' | 'EmployeeNo' | 'EmploymentType' | 'Status' | 'StaffCategory' | 'StaffCategoryID' | 'CalendarStateID' | 'Probation' | 'SubscriptionAccountID' | 'ActualConfirmedDate' | 'ReportTo' | 'JoinedDate' | 'ConfirmedDate' | 'ResignedDate' | 'GeneralDocCount' | 'EAFormDocCount' | 'PaySlipDocCount' | 'PersonalDocCount' | 'GeneralDocTotalSize' | 'EAFormDocTotalSize' | 'PaySlipDocTotalSize' | 'PersonalDocTotalSize' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { CalendarPolicy?: Maybe<(
      { __typename?: 'CalendarPolicyEntity' }
      & Pick<CalendarPolicyEntity, 'Title' | 'StateID'>
      & { States?: Maybe<(
        { __typename?: 'StatesEntity' }
        & Pick<StatesEntity, 'Description'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'NickName' | 'ContactID' | 'FullName' | 'Email' | 'Nationality' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'BirthDate' | 'Gender' | 'Marital' | 'Race' | 'Religion' | 'Salutation' | 'SameAddress' | 'ProfilePicture'>
      & { EmployeeSalutation?: Maybe<(
        { __typename?: 'PersonnelParameterEntity' }
        & Pick<PersonnelParameterEntity, 'Name'>
      )>, Address?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
      )>, PermanentAddress?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
      )> }
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CalendarTitle' | 'CompanyName' | 'CalendarStateID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID'>
        & { Address?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
        )> }
      )> }
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
    )>, EmployeeDepartment?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
    )>, RecruitmentSource?: Maybe<(
      { __typename?: 'RecruitmentSourceEntity' }
      & Pick<RecruitmentSourceEntity, 'RecruitmentSourceID' | 'Name'>
    )>, EmployeeCareerLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'SalaryType' | 'FromSalary' | 'ToSalary'>
    )>>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description' | 'ParentID'>
    )>, EmployeeBankInfo?: Maybe<(
      { __typename?: 'EmployeeBankInfoEntity' }
      & Pick<EmployeeBankInfoEntity, 'EmployeeBankInfoID' | 'Bank' | 'AcctNo' | 'PaymentMethod'>
    )>, EmployeeStatutory?: Maybe<(
      { __typename?: 'EmployeeStatutoryEntity' }
      & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID' | 'IsEPF' | 'EPFNo' | 'EPFVERate' | 'EPFVERateCmp' | 'IsSocso' | 'SocsoNo' | 'IsPCB' | 'PCBNo' | 'IsEIS' | 'EISNo' | 'IsAngkasa' | 'AngkasaNo' | 'IsASB' | 'ASBNo' | 'IsKWAP' | 'KWAPNo' | 'IsTBH' | 'TBHNo' | 'IsZakat' | 'ZakatNo' | 'ZakatAmount' | 'ZakatRate' | 'ResidentStatus' | 'TaxMaritialStatus' | 'ZakatState' | 'IsHRDF'>
    )>, EmployeePrevContribution?: Maybe<(
      { __typename?: 'EmployeePrevContributionEntity' }
      & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID' | 'CompanyID' | 'EmployeeID' | 'PeriodYear' | 'GrossTaxable' | 'EPFPaid' | 'PCBPaid' | 'ZAKATPaid'>
    )>, EmployeeDependents?: Maybe<Array<(
      { __typename?: 'EmployeeDependentsEntity' }
      & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID' | 'Relationship' | 'Education' | 'ChildEligibleRate' | 'Occupation' | 'PCBNo' | 'IsWorking' | 'IsOKU' | 'IsEmergencyContact'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'BirthDate' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'Nationality'>
      )> }
    )>>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>, EmployeeInsuranceSchemes?: Maybe<Array<(
      { __typename?: 'EmployeeInsuranceSchemeEntity' }
      & Pick<EmployeeInsuranceSchemeEntity, 'InsuranceSchemeID' | 'EmployeeInsuranceSchemeID' | 'StartDate' | 'EndDate' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
      & { InsuranceScheme?: Maybe<(
        { __typename?: 'InsuranceSchemeEntity' }
        & Pick<InsuranceSchemeEntity, 'Name' | 'Status'>
      )> }
    )>> }
  ) }
);

export type CompanyOrgUnitListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CompanyOrgUnitListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyOrgUnitList'>
);

export type CompanyOrgUnitListWithFilterQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CompanyOrgUnitListWithFilterQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyOrgUnitListWithFilter'>
);

export type MyApplicationDetailQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  RequestType: Scalars['String'];
  RequestID: Scalars['String'];
}>;


export type MyApplicationDetailQuery = (
  { __typename?: 'Query' }
  & { MyApplicationDetail: (
    { __typename?: 'MyApplicationOutput' }
    & { leave?: Maybe<(
      { __typename?: 'LeaveAppOutput' }
      & Pick<LeaveAppOutput, 'FromDate' | 'UntilDate' | 'TotalDay' | 'Remarks' | 'ApprovalStatus' | 'ApprovalDT' | 'Name'>
    )>, claim?: Maybe<(
      { __typename?: 'ClaimAppOutput' }
      & Pick<ClaimAppOutput, 'Subject' | 'ApprovalStatus' | 'ApprovalDate'>
      & { Details?: Maybe<Array<(
        { __typename?: 'ClaimAppDetailsOutput' }
        & Pick<ClaimAppDetailsOutput, 'Name' | 'ReceiptNo' | 'ClaimAmount' | 'ClaimRemarks'>
      )>> }
    )>, requisition?: Maybe<(
      { __typename?: 'RequisitionAppOutput' }
      & Pick<RequisitionAppOutput, 'RequisitionType' | 'Subject' | 'Remark' | 'NumberOfItem' | 'Amount' | 'StartDate' | 'EndDate' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'PurposeOfTravel' | 'ApprovalStatus' | 'ApprovalDate'>
    )> }
  ) }
);

export type IsCompanyHierarchyExistsQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type IsCompanyHierarchyExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsCompanyHierarchyExists'>
);

export type CompanyTaxReliefUpdateMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  ExcludeTaxRelief: Scalars['Boolean'];
}>;


export type CompanyTaxReliefUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyTaxReliefUpdate'>
);

export type CompanySummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CompanySummaryQuery = (
  { __typename?: 'Query' }
  & { CompanySummary: (
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'CompanyName' | 'SupportEmails' | 'BizRegNo' | 'Sector' | 'ExcludeTaxRelief' | 'ProjectExpenseTrxDayLimit' | 'ProjectExpenseCutOff' | 'CalendarStateID' | 'PayrollCutOffDay' | 'LeaveCutOffDay' | 'ClaimCutOffDay' | 'EnableExpenseAccount' | 'ClaimTrxDayLimit' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'CustomPayslipFormat' | 'CustomPayrollGlExportFormat' | 'CustomClaimGlExportFormat' | 'CustomProjectExpenseGlExportFormat' | 'CustomClaimSubmissionReport'>
    & { Departments?: Maybe<Array<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID'>
    )>>, Employees?: Maybe<Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID'>
    )>>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'Email' | 'WebUrl' | 'OfficeNo' | 'MobileNo' | 'FaxNo' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
      & { Address?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'PostalCode' | 'City' | 'State' | 'Country'>
      )> }
    )>, Documents?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>>, LeaveRounding?: Maybe<(
      { __typename?: 'LeaveRoundingEntity' }
      & Pick<LeaveRoundingEntity, 'LeaveRoundingID' | 'Title'>
    )> }
  ) }
);

export type GetEmployeeInsuranceSchemeQueryVariables = Exact<{
  EmployeeInsuranceSchemeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type GetEmployeeInsuranceSchemeQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeInsuranceScheme: Array<(
    { __typename?: 'EmployeeInsuranceSchemeEntity' }
    & Pick<EmployeeInsuranceSchemeEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeInsuranceSchemeID' | 'EmployeeID' | 'InsuranceSchemeID' | 'StartDate' | 'EndDate'>
    & { InsuranceScheme?: Maybe<(
      { __typename?: 'InsuranceSchemeEntity' }
      & Pick<InsuranceSchemeEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'InsuranceSchemeID' | 'Name'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'Description' | 'DocumentFile'>
      )> }
    )> }
  )> }
);

export type InvoiceRpaMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type InvoiceRpaMutation = (
  { __typename?: 'Mutation' }
  & { InvoiceRPA: Array<(
    { __typename?: 'InvoiceRPAResult' }
    & Pick<InvoiceRpaResult, 'InvoiceDate' | 'InvoiceId' | 'InvoiceTotal' | 'VendorName'>
  )> }
);

export type GetRoleQueryVariables = Exact<{
  IsApprovalPolicy?: Maybe<Scalars['Boolean']>;
  ID?: Maybe<Scalars['String']>;
}>;


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
    & { rolePerm: Array<(
      { __typename?: 'RolePermissionAssignmentEntity' }
      & Pick<RolePermissionAssignmentEntity, 'permissionID' | 'roleID' | 'permName'>
    )>, roleUserAssignments?: Maybe<Array<(
      { __typename?: 'RoleUserEntAssignmentEntity' }
      & Pick<RoleUserEntAssignmentEntity, 'roleID' | 'userID' | 'entityID' | 'ID'>
    )>> }
  )> }
);

export type GetRoleByUserIdQueryVariables = Exact<{
  UserID?: Maybe<Scalars['String']>;
}>;


export type GetRoleByUserIdQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
  )> }
);

export type GetRoleListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRoleListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRoleList'>
);

export type DocumentNumberingListingQueryVariables = Exact<{
  IsStatusActive?: Maybe<Scalars['Boolean']>;
}>;


export type DocumentNumberingListingQuery = (
  { __typename?: 'Query' }
  & { DocumentNumberingList: Array<(
    { __typename?: 'DocumentNumberHeaderEntity' }
    & Pick<DocumentNumberHeaderEntity, 'DocumentNumberHeaderID' | 'Name' | 'SampleOutput' | 'Status' | 'StartFrom' | 'ModifiedDT' | 'CreatedDT' | 'IsStandard'>
    & { DocNumDetail?: Maybe<Array<(
      { __typename?: 'DocumentNumberDetailEntity' }
      & Pick<DocumentNumberDetailEntity, 'DocumentNumberHeaderID' | 'FormatType' | 'FormatValue' | 'Sequence'>
    )>> }
  )> }
);

export type DocumentNumberingUpdateStatusMutationVariables = Exact<{
  DocumentNumberHeaderID: Scalars['String'];
  DocStatus: Scalars['String'];
}>;


export type DocumentNumberingUpdateStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DocumentNumberingUpdateStatus'>
);

export type ViewGeneralSettingSummaryQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type ViewGeneralSettingSummaryQuery = (
  { __typename?: 'Query' }
  & { ViewGeneralSettingSummary: (
    { __typename?: 'GeneralSettingsLastUpdatedOutput' }
    & Pick<GeneralSettingsLastUpdatedOutput, 'RoleSettingsIsLastUpdated' | 'RoleSettingsLastPostedDate' | 'CompanylastPosted' | 'NewslastPosted' | 'AutoNumberSettingsLastPostedDate'>
  ) }
);

export type SystemAdminMenuSummaryQueryVariables = Exact<{
  accountID: Scalars['String'];
  userID: Scalars['String'];
}>;


export type SystemAdminMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SystemAdminMenuSummary'>
);

export type DeleteRolePermissionMutationVariables = Exact<{
  roleID: Scalars['String'];
}>;


export type DeleteRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteRolePermission'>
);

export type CreateRolePermissionMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<HrPermission> | HrPermission;
}>;


export type CreateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateRolePermission'>
);

export type UpdateRolePermissionMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<HrPermission> | HrPermission;
}>;


export type UpdateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateRolePermission'>
);

export type CompanyInsertMutationVariables = Exact<{
  CompanyInput: CompanyInput;
  ContactInput: ContactInput;
  DocumentsInput: DocumentsInput;
}>;


export type CompanyInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyInsert'>
);

export type CompanyUpdateMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  ContactID: Scalars['String'];
  CompanyInput: CompanyInput;
  ContactInput: ContactInput;
  DocumentsInput: DocumentsInput;
  DocumentID: Scalars['String'];
}>;


export type CompanyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyUpdate'>
);

export type DocumentNumberingInsertMutationVariables = Exact<{
  input: DocumentNumberingInput;
}>;


export type DocumentNumberingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DocumentNumberingInsert'>
);

export type DocumentNumberingUpdateMutationVariables = Exact<{
  input: DocumentNumberingInput;
}>;


export type DocumentNumberingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DocumentNumberingUpdate'>
);

export type ViewCompanySummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
}>;


export type ViewCompanySummaryQuery = (
  { __typename?: 'Query' }
  & { CompanySummaryDates: (
    { __typename?: 'CompanyViewLastUpdatedOutput' }
    & Pick<CompanyViewLastUpdatedOutput, 'CompanyInfoIsNewLastUpdated' | 'CompanyInfoLastPostedDate' | 'StatutoryLastPostedDate' | 'DepartmentLastPostedDate' | 'CompanyAccessAuthLastPostedDate' | 'ExpensesAccountLastPostedDate' | 'BankInfoLastPostedDate' | 'CalendarPolicyLastPostedDate' | 'CalendarOffDayPolicyLastPostedDate' | 'LeavePolicyLastPostedDate' | 'ClaimPolicyLastPostedDate' | 'PayrollPolicyLastPostedDate' | 'NotificationPolicyLastPostedDate' | 'EmployeeLastPostedDate' | 'CalendarPolicyLastUpdated' | 'CalendarPolicyListLastPostedDate' | 'NoOfGeneralDocument' | 'TotalSizeOfGeneralDocument' | 'RoleLastPostedDate' | 'NoOfDepartment' | 'NoOfRoleAssignment' | 'CompanyHierarchyLastPostedDate' | 'CompanyOrgUnitLastPostedDate' | 'CompanyReportingStructureLastPostedDate' | 'CompanyJobPositionLastPostedDate' | 'CompanyAccessLastPostedDate' | 'CompanyAutoNumberingLastPostedDate' | 'DefaultState' | 'NoOfStates'>
  ) }
);

export type ViewNotificationPolicySummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type ViewNotificationPolicySummaryQuery = (
  { __typename?: 'Query' }
  & { NotificationPolicySummaryDates: (
    { __typename?: 'NotificationPolicyLastUpdatedOutput' }
    & Pick<NotificationPolicyLastUpdatedOutput, 'NotificationEmploymentIsLastUpdated' | 'NotificationLeaveIsLastUpdated' | 'NotificationClaimIsLastUpdated' | 'NotificationRequisitionIsLastUpdated' | 'NotificationEmploymentLastPostedDate' | 'NotificationLeaveLastPostedDate' | 'NotificationClaimLastPostedDate' | 'NotificationRequisitionLastPostedDate' | 'NotificationLastPostedDate' | 'NotificationIsLastUpdated'>
  ) }
);

export type ReportTemplateInsertMutationVariables = Exact<{
  DocumentsInput: DocumentsInput;
  input: ReportTemplateInput;
}>;


export type ReportTemplateInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReportTemplateInsert'>
);

export type ReportTemplateUpdateMutationVariables = Exact<{
  DocumentID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  input: ReportTemplateInput;
}>;


export type ReportTemplateUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReportTemplateUpdate'>
);

export type ReportTemplateInactiveMutationVariables = Exact<{
  ReportTemplateID: Scalars['String'];
}>;


export type ReportTemplateInactiveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReportTemplateInactive'>
);

export type ReportTemplateListingQueryVariables = Exact<{
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type ReportTemplateListingQuery = (
  { __typename?: 'Query' }
  & { ReportTemplateListing: Array<(
    { __typename?: 'ReportTemplateEntity' }
    & Pick<ReportTemplateEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'ReportTemplateID' | 'SubscriptionAccountID' | 'Name' | 'Content' | 'DocumentID' | 'IsDefault' | 'ReportTemplateType'>
    & { Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'BucketFileName' | 'DocumentFile'>
    )> }
  )> }
);

export type ELetterTempleteInfolinesQueryVariables = Exact<{ [key: string]: never; }>;


export type ELetterTempleteInfolinesQuery = (
  { __typename?: 'Query' }
  & { ELetterTempleteInfolines: Array<(
    { __typename?: 'ReportTemplateEntity' }
    & Pick<ReportTemplateEntity, 'ModifiedDT' | 'ReportTemplateType'>
  )> }
);

export type CommonSettingsInfoLinesQueryVariables = Exact<{ [key: string]: never; }>;


export type CommonSettingsInfoLinesQuery = (
  { __typename?: 'Query' }
  & { CommonSettingsInfoLines: (
    { __typename?: 'CommonSettingsInfoLinesOutput' }
    & Pick<CommonSettingsInfoLinesOutput, 'JobGradeTotal' | 'SkillSetTotal' | 'JobPositionTotal' | 'InsSchemeTotal' | 'RecSrcTotal' | 'UsrDefTotal' | 'ReportSettingsLastPostedDate' | 'DataChangeApprovalPolicyLastModifiedDate' | 'PersonnelEFileSecurityPolicyLastModifiedDate'>
  ) }
);

export type PersonnelParameterInsertMutationVariables = Exact<{
  input: PersonnelParameterInput;
}>;


export type PersonnelParameterInsertMutation = (
  { __typename?: 'Mutation' }
  & { PersonnelParameterInsert: (
    { __typename?: 'PersonnelParameterEntity' }
    & Pick<PersonnelParameterEntity, 'PersonnelParameterID'>
  ) }
);

export type PersonnelParameterUpdateMutationVariables = Exact<{
  input: PersonnelParameterInput;
}>;


export type PersonnelParameterUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelParameterUpdate'>
);

export type PersonnelParameterDeleteMutationVariables = Exact<{
  PersonnelParameterID: Scalars['String'];
}>;


export type PersonnelParameterDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelParameterDelete'>
);

export type JobGradeListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type JobGradeListingQuery = (
  { __typename?: 'Query' }
  & { JobGradeListing: Array<(
    { __typename?: 'JobGradeEntity' }
    & Pick<JobGradeEntity, 'JobGradeID' | 'SubscriptionAccountID' | 'Description' | 'LeaveBenefitID' | 'ClaimBenefitID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { JobDesignationGrades?: Maybe<Array<(
      { __typename?: 'JobDesignationGradeEntity' }
      & Pick<JobDesignationGradeEntity, 'JobDesignationGradeID'>
    )>> }
  )> }
);

export type JobGradeBulkInsertMutationVariables = Exact<{
  input: Array<JobGradeBulkInsertInput> | JobGradeBulkInsertInput;
}>;


export type JobGradeBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobGradeBulkInsert'>
);

export type JobGradeDeleteMutationVariables = Exact<{
  JobGradeID: Scalars['String'];
}>;


export type JobGradeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobGradeDelete'>
);

export type GetSkillSetQueryVariables = Exact<{
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
}>;


export type GetSkillSetQuery = (
  { __typename?: 'Query' }
  & { GetSkillSet: Array<(
    { __typename?: 'SkillSetEntity' }
    & Pick<SkillSetEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'SkillSetID' | 'SubscriptionAccountID' | 'Name'>
    & { UserLogs?: Maybe<(
      { __typename?: 'UserLogs' }
      & Pick<UserLogs, 'createdDT' | 'createdBy' | 'modifiedDT' | 'modifiedBy'>
    )>, SkillSetDetails?: Maybe<Array<(
      { __typename?: 'SkillSetDetailEntity' }
      & Pick<SkillSetDetailEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'SkillSetDetailID' | 'Name'>
      & { UserLogs?: Maybe<(
        { __typename?: 'UserLogs' }
        & Pick<UserLogs, 'createdDT' | 'createdBy' | 'modifiedDT' | 'modifiedBy'>
      )> }
    )>> }
  )> }
);

export type DeleteSkillSetMutationVariables = Exact<{
  SkillSetID: Scalars['String'];
}>;


export type DeleteSkillSetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteSkillSet'>
);

export type CreateSkillSetMutationVariables = Exact<{
  SkillSetInput: SkillSetInput;
  SkillSetDetailInput: Array<SkillSetDetailInput> | SkillSetDetailInput;
}>;


export type CreateSkillSetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateSkillSet'>
);

export type GetSkillSetDetailQueryVariables = Exact<{
  SkillSetDetailID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
}>;


export type GetSkillSetDetailQuery = (
  { __typename?: 'Query' }
  & { GetSkillSetDetail: Array<(
    { __typename?: 'SkillSetDetailEntity' }
    & Pick<SkillSetDetailEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'SkillSetDetailID' | 'Name' | 'SkillSetID'>
    & { UserLogs?: Maybe<(
      { __typename?: 'UserLogs' }
      & Pick<UserLogs, 'createdDT' | 'createdBy' | 'modifiedDT' | 'modifiedBy'>
    )>, SkillSet?: Maybe<(
      { __typename?: 'SkillSetEntity' }
      & Pick<SkillSetEntity, 'Name' | 'SkillSetID'>
    )> }
  )> }
);

export type UpdateSkillSetMutationVariables = Exact<{
  SkillSetID: Scalars['String'];
  SkillSetInput: SkillSetInput;
  SkillSetDetailInput: Array<SkillSetDetailInput> | SkillSetDetailInput;
}>;


export type UpdateSkillSetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateSkillSet'>
);

export type JobPositionDeleteMutationVariables = Exact<{
  JobDesignationID: Scalars['String'];
}>;


export type JobPositionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobPositionDelete'>
);

export type JobPositionListingQueryVariables = Exact<{
  JobDesignationID?: Maybe<Scalars['String']>;
}>;


export type JobPositionListingQuery = (
  { __typename?: 'Query' }
  & { JobPositionListing: Array<(
    { __typename?: 'JobDesignationEntity' }
    & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name' | 'Description' | 'IsInUse' | 'Sequence'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentFile' | 'BucketFileName'>
    )>, JobDesignationGrades?: Maybe<Array<(
      { __typename?: 'JobDesignationGradeEntity' }
      & Pick<JobDesignationGradeEntity, 'JobDesignationGradeID' | 'JobDesignationID' | 'JobGradeID'>
      & { JobGrade: (
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'Description'>
      ) }
    )>>, JobDesignationSkillSets?: Maybe<Array<(
      { __typename?: 'JobDesignationSkillSetEntity' }
      & Pick<JobDesignationSkillSetEntity, 'JobDesignationSkillSetID' | 'SkillSetID' | 'SkillSetDetailID'>
      & { SkillSet?: Maybe<(
        { __typename?: 'SkillSetEntity' }
        & Pick<SkillSetEntity, 'Name'>
      )>, SkillSetDetail?: Maybe<(
        { __typename?: 'SkillSetDetailEntity' }
        & Pick<SkillSetDetailEntity, 'Name'>
      )> }
    )>> }
  )> }
);

export type JobDesignationGradeListingQueryVariables = Exact<{
  JobDesignationID: Scalars['String'];
}>;


export type JobDesignationGradeListingQuery = (
  { __typename?: 'Query' }
  & { JobDesignationGradeListing: Array<(
    { __typename?: 'JobDesignationGradeEntity' }
    & Pick<JobDesignationGradeEntity, 'JobDesignationGradeID' | 'JobDesignationID' | 'JobGradeID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type JobPositionInsertMutationVariables = Exact<{
  DesignationInput: JobDesignationInput;
  DesignationGradeInput: Array<JobDesignationGradeInput> | JobDesignationGradeInput;
  DesignationSkillInput: Array<JobDesignationSkillSetInput> | JobDesignationSkillSetInput;
}>;


export type JobPositionInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobPositionInsert'>
);

export type JobDesignationSequenceUpdateMutationVariables = Exact<{
  JobDesignationIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type JobDesignationSequenceUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobDesignationSequenceUpdate'>
);

export type JobPositionUpdateMutationVariables = Exact<{
  DesignationInput: JobDesignationInput;
  DesignationGradeInput: Array<JobDesignationGradeInput> | JobDesignationGradeInput;
  DesignationSkillInput: Array<JobDesignationSkillSetInput> | JobDesignationSkillSetInput;
}>;


export type JobPositionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'JobPositionUpdate'>
);

export type InsuranceSchemeListingQueryVariables = Exact<{
  InsuranceSchemeID?: Maybe<Scalars['String']>;
}>;


export type InsuranceSchemeListingQuery = (
  { __typename?: 'Query' }
  & { InsuranceSchemeListing: Array<(
    { __typename?: 'InsuranceSchemeEntity' }
    & Pick<InsuranceSchemeEntity, 'Name' | 'InsuranceSchemeID' | 'SubscriptionAccountID' | 'ModifiedDT' | 'ModifiedBy' | 'CreatedBy' | 'CreatedDT' | 'DocumentID'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, EmployeeInsuranceSchemes?: Maybe<Array<(
      { __typename?: 'EmployeeInsuranceSchemeEntity' }
      & Pick<EmployeeInsuranceSchemeEntity, 'Status' | 'EmployeeID'>
    )>> }
  )> }
);

export type InsuranceSchemeInsertMutationVariables = Exact<{
  InsuranceSchemeInsertInput: InsuranceSchemeInsertInput;
}>;


export type InsuranceSchemeInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InsuranceSchemeInsert'>
);

export type InsuranceSchemeUpdateMutationVariables = Exact<{
  InsuranceSchemeInsertInput: InsuranceSchemeInsertInput;
  InsuranceSchemeID: Scalars['String'];
}>;


export type InsuranceSchemeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InsuranceSchemeUpdate'>
);

export type InsuranceSchemeDeleteMutationVariables = Exact<{
  InsuranceSchemeID: Scalars['String'];
}>;


export type InsuranceSchemeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InsuranceSchemeDelete'>
);

export type GetInsuranceSchemeQueryVariables = Exact<{
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  InsuranceSchemeID?: Maybe<Scalars['String']>;
}>;


export type GetInsuranceSchemeQuery = (
  { __typename?: 'Query' }
  & { GetInsuranceScheme: Array<(
    { __typename?: 'InsuranceSchemeEntity' }
    & Pick<InsuranceSchemeEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'InsuranceSchemeID' | 'SubscriptionAccountID' | 'Name'>
    & { EmployeeInsuranceSchemes?: Maybe<Array<(
      { __typename?: 'EmployeeInsuranceSchemeEntity' }
      & Pick<EmployeeInsuranceSchemeEntity, 'InsuranceSchemeID' | 'EmployeeID' | 'EmployeeInsuranceSchemeID'>
    )>> }
  )> }
);

export type RecruitmentSourceBulkInsertMutationVariables = Exact<{
  Input: Array<RecruitmentSourceBulkInput> | RecruitmentSourceBulkInput;
}>;


export type RecruitmentSourceBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RecruitmentSourceBulkInsert'>
);

export type RecruitmentSourceDeleteMutationVariables = Exact<{
  RecruitmentSourceID: Scalars['String'];
}>;


export type RecruitmentSourceDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RecruitmentSourceDelete'>
);

export type RecruitmentSourceListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type RecruitmentSourceListingQuery = (
  { __typename?: 'Query' }
  & { RecruitmentSourceListing: Array<(
    { __typename?: 'RecruitmentSourceEntity' }
    & Pick<RecruitmentSourceEntity, 'RecruitmentSourceID' | 'SubscriptionAccountID' | 'Name' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { EmployeeInfo?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'Status' | 'EmployeeID'>
    )> }
  )> }
);

export type UserDefineBulkInsertMutationVariables = Exact<{
  input: Array<UserDefineBulkInput> | UserDefineBulkInput;
}>;


export type UserDefineBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UserDefineBulkInsert'>
);

export type PersonnelTemplateLibraryAssignmentPageDataQueryVariables = Exact<{
  input?: Maybe<TemplateLibraryAssignmentInput>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
}>;


export type PersonnelTemplateLibraryAssignmentPageDataQuery = (
  { __typename?: 'Query' }
  & { PersonnelTemplateLibraryAssignmentListing: (
    { __typename?: 'PersonnelTemplateLibraryAssignmentOutput' }
    & { JobGrade?: Maybe<Array<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'JobGradeID' | 'Description' | 'Status'>
    )>>, SkillSet?: Maybe<Array<(
      { __typename?: 'SkillSetEntity' }
      & Pick<SkillSetEntity, 'Name' | 'SkillSetID' | 'Status'>
    )>>, JobPosition?: Maybe<Array<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name' | 'JobDesignationID' | 'Status'>
    )>>, InsuranceScheme?: Maybe<Array<(
      { __typename?: 'InsuranceSchemeEntity' }
      & Pick<InsuranceSchemeEntity, 'Name' | 'InsuranceSchemeID' | 'Status'>
    )>>, RecruitmentSource?: Maybe<Array<(
      { __typename?: 'RecruitmentSourceEntity' }
      & Pick<RecruitmentSourceEntity, 'Name' | 'RecruitmentSourceID' | 'Status'>
    )>>, UserDefine?: Maybe<Array<(
      { __typename?: 'UserDefineEntity' }
      & Pick<UserDefineEntity, 'Name' | 'UserDefineID' | 'Status'>
    )>> }
  ), getTemplateLibraryAssignment: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TemplateLibraryAssignmentID' | 'SubscriptionAccountID' | 'CompanyID' | 'SourceID' | 'SourceType' | 'TargetID' | 'TargetType' | 'ModifiedDT' | 'CreatedDT'>
  )> }
);

export type ReportTemplateAssignmentListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type ReportTemplateAssignmentListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ReportTemplateAssignmentListing'>
);

export type ReplaceTemplateLibraryAssignmentMutationVariables = Exact<{
  inputs: Array<TemplateLibraryAssignmentInput> | TemplateLibraryAssignmentInput;
}>;


export type ReplaceTemplateLibraryAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'replaceTemplateLibraryAssignment'>
);

export type ReportTemplateAssignmentMutationVariables = Exact<{
  input: ReportTemplateInput;
  CompanyID: Scalars['String'];
  DocumentsInput: DocumentsInput;
}>;


export type ReportTemplateAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReportTemplateAssignment'>
);

export type PayrollCycleListingWithAuditQueryVariables = Exact<{ [key: string]: never; }>;


export type PayrollCycleListingWithAuditQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PayrollCycleListingWithAudit'>
);

export type PayrollCycleListingV3QueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
}>;


export type PayrollCycleListingV3Query = (
  { __typename?: 'Query' }
  & { PayrollCycleListingV3: Array<(
    { __typename?: 'PayrollCycleEntity' }
    & Pick<PayrollCycleEntity, 'Status' | 'PayrollCycleID' | 'CompanyID' | 'Description' | 'IsDefault' | 'EPF' | 'EPFVE' | 'SOCSO' | 'MTD' | 'Zakat' | 'EIS' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName' | 'SubscriptionAccountID'>
    )> }
  )> }
);

export type PayItemListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type PayItemListingQuery = (
  { __typename?: 'Query' }
  & { PayItemListing: Array<(
    { __typename?: 'PayItemEntity' }
    & Pick<PayItemEntity, 'PayItemID' | 'CompanyID' | 'PayName' | 'PayItemType' | 'IsLHDN' | 'IsSystem' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'IsInUse'>
    & { UserLogs?: Maybe<Array<(
      { __typename?: 'UserLogs' }
      & Pick<UserLogs, 'createdDT' | 'createdBy' | 'modifiedDT' | 'modifiedBy' | 'columnName' | 'oldColumnValue' | 'newColumnValue'>
    )>> }
  )> }
);

export type PayItemDeleteMutationVariables = Exact<{
  PayItemID: Scalars['String'];
}>;


export type PayItemDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PayItemDelete'>
);

export type PayItemInsertMutationVariables = Exact<{
  Input: PayItemInput;
  Condition?: Maybe<PayItemConditionInput>;
}>;


export type PayItemInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PayItemInsert'>
);

export type PayItemConditionListQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  PayItemID: Scalars['String'];
}>;


export type PayItemConditionListQuery = (
  { __typename?: 'Query' }
  & { PayItemConditionList: (
    { __typename?: 'PayItemConditionEntity' }
    & Pick<PayItemConditionEntity, 'PayItemConditionID' | 'PayItemID' | 'ConditionList' | 'PayItemType'>
  ) }
);

export type PayItemDetailQueryVariables = Exact<{
  PayItemID: Scalars['String'];
}>;


export type PayItemDetailQuery = (
  { __typename?: 'Query' }
  & { PayItemDetail?: Maybe<(
    { __typename?: 'PayItemEntity' }
    & Pick<PayItemEntity, 'PayItemID' | 'EAPartFID' | 'PayName' | 'PayCode' | 'Formula' | 'PayItemType' | 'IsLHDN' | 'IsEPF' | 'IsEIS' | 'IsHRDF' | 'IsSOCSO' | 'IsEPFVE' | 'IsBonus' | 'IsSystem' | 'IsAddRemuneration' | 'EPFEmployer' | 'EPFEmployee' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'IsEPFNonMonthly' | 'ModifiedBy'>
    & { UserLogs?: Maybe<Array<(
      { __typename?: 'UserLogs' }
      & Pick<UserLogs, 'createdDT' | 'createdBy' | 'modifiedDT' | 'modifiedBy' | 'columnName' | 'oldColumnValue' | 'newColumnValue'>
    )>> }
  )> }
);

export type PayItemUpdateMutationVariables = Exact<{
  PayItemID: Scalars['String'];
  Input: PayItemInput;
  Condition?: Maybe<PayItemConditionInput>;
}>;


export type PayItemUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PayItemUpdate'>
);

export type PayItemConditionTemplateLibraryUpdateMutationVariables = Exact<{
  Condition?: Maybe<PayItemConditionInput>;
}>;


export type PayItemConditionTemplateLibraryUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PayItemConditionTemplateLibraryUpdate'>
);

export type TmsotConditionTemplateLibraryUpdateMutationVariables = Exact<{
  TMSOTConditionInput: Array<TmsotConditionInput> | TmsotConditionInput;
  CompanyID: Scalars['String'];
}>;


export type TmsotConditionTemplateLibraryUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TmsotConditionTemplateLibraryUpdate'>
);

export type DailyTypeConditionUpdateMutationVariables = Exact<{
  DailyTemplateInput: DailyTemplateInput;
}>;


export type DailyTypeConditionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DailyTypeConditionUpdate'>
);

export type DailyTypeConditionDeleteMutationVariables = Exact<{
  DailyTemplateID: Scalars['String'];
}>;


export type DailyTypeConditionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DailyTypeConditionDelete'>
);

export type DailyTypeConditionListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type DailyTypeConditionListQuery = (
  { __typename?: 'Query' }
  & { DailyTypeConditionList: Array<(
    { __typename?: 'DailyTemplateEntity' }
    & Pick<DailyTemplateEntity, 'CompanyID' | 'DailyTemplateID' | 'ConditionList' | 'ClaimPolicyID' | 'ClaimPolicyIDList' | 'ClaimTypeID' | 'ModifiedDT' | 'CreatedDT'>
  )> }
);

export type MonthlyTypeConditionUpdateMutationVariables = Exact<{
  MonthlyTemplateInput: MonthlyTemplateInput;
}>;


export type MonthlyTypeConditionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MonthlyTypeConditionUpdate'>
);

export type MonthlyTypeConditionDeleteMutationVariables = Exact<{
  MonthlyTemplateID: Scalars['String'];
}>;


export type MonthlyTypeConditionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MonthlyTypeConditionDelete'>
);

export type MonthlyTypeConditionListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ClaimType: Scalars['String'];
}>;


export type MonthlyTypeConditionListQuery = (
  { __typename?: 'Query' }
  & { MonthlyTypeConditionList: Array<(
    { __typename?: 'MonthlyTemplateEntity' }
    & Pick<MonthlyTemplateEntity, 'CompanyID' | 'MonthlyTemplateID' | 'ConditionList' | 'ClaimPolicyIDList' | 'ClaimPolicyID' | 'ClaimTypeID' | 'ModifiedDT' | 'CreatedDT' | 'PeriodicClaimType'>
  )> }
);

export type DeductionTypeConditionUpdateMutationVariables = Exact<{
  DeductionTemplateInput: DeductionTemplateInput;
}>;


export type DeductionTypeConditionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeductionTypeConditionUpdate'>
);

export type DeductionTypeConditionDeleteMutationVariables = Exact<{
  DeductionTemplateID: Scalars['String'];
}>;


export type DeductionTypeConditionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeductionTypeConditionDelete'>
);

export type DeductionTypeConditionListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type DeductionTypeConditionListQuery = (
  { __typename?: 'Query' }
  & { DeductionTypeConditionList: Array<(
    { __typename?: 'DeductionTemplateEntity' }
    & Pick<DeductionTemplateEntity, 'CompanyID' | 'DeductionTemplateID' | 'ConditionList' | 'ClaimPolicyIDList' | 'ClaimPolicyID' | 'ClaimTypeID' | 'ModifiedDT' | 'CreatedDT'>
  )> }
);

export type PayItemConditionListTemplateLibraryQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type PayItemConditionListTemplateLibraryQuery = (
  { __typename?: 'Query' }
  & { PayItemConditionListTemplateLibrary: (
    { __typename?: 'PayItemConditionEntity' }
    & Pick<PayItemConditionEntity, 'PayItemConditionID' | 'CompanyID' | 'IsForTemplateLibrary' | 'ConditionList'>
  ) }
);

export type OtPayItemConditionListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type OtPayItemConditionListQuery = (
  { __typename?: 'Query' }
  & { OTPayItemConditionList: Array<(
    { __typename?: 'TMSOTConditionEntity' }
    & Pick<TmsotConditionEntity, 'PayItemConditionID' | 'CompanyID' | 'ConditionList' | 'OvertimePolicyID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'Status' | 'SubscriptionAccountID'>
  )> }
);

export type EaPartFListingByYearQueryVariables = Exact<{
  PeriodYear: Scalars['Float'];
}>;


export type EaPartFListingByYearQuery = (
  { __typename?: 'Query' }
  & { EAPartFListingByYear: Array<(
    { __typename?: 'EAPartFEntity' }
    & Pick<EaPartFEntity, 'EAPartFID' | 'Description' | 'LimitAmt' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'ReportYear'>
  )> }
);

export type LhdnReportDropdownListingQueryVariables = Exact<{
  ReportYear: Scalars['Float'];
}>;


export type LhdnReportDropdownListingQuery = (
  { __typename?: 'Query' }
  & { LHDNReportDropdownListing: Array<(
    { __typename?: 'LHDNReportEntity' }
    & Pick<LhdnReportEntity, 'ReportTypeSequence' | 'ReportType' | 'LHDNReportID' | 'ENDescription' | 'Status'>
  )> }
);

export type LhdnReportUpdateMutationVariables = Exact<{
  EAFormSetupID: Scalars['String'];
  input: EaFormSetupInput;
}>;


export type LhdnReportUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LHDNReportUpdate'>
);

export type LhdnReportInsertMutationVariables = Exact<{
  input: EaFormSetupInput;
}>;


export type LhdnReportInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LHDNReportInsert'>
);

export type GetLhdnReportListingQueryVariables = Exact<{
  periodYear: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
}>;


export type GetLhdnReportListingQuery = (
  { __typename?: 'Query' }
  & { GetLHDNReportListing?: Maybe<Array<(
    { __typename?: 'DisplayReportTypeSequenceOutput' }
    & Pick<DisplayReportTypeSequenceOutput, 'CP22aReportSeq' | 'CP21ReportSeq' | 'EAFormReportSeq' | 'LHDNReportID' | 'PayItemID' | 'PayItemType' | 'CreatedDT' | 'ModifiedDT' | 'PayName' | 'SubscriptionAccountID' | 'CompanyID' | 'CP21' | 'CP22a' | 'EAForm' | 'EAFormSetupID'>
  )>> }
);

export type LhdnReportCopyInsertMutationVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  FromYear: Scalars['Float'];
  ToYear: Scalars['Float'];
}>;


export type LhdnReportCopyInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LHDNReportCopyInsert'>
);

export type PayrollCycleDeleteMutationVariables = Exact<{
  PayrollCycleID: Scalars['String'];
}>;


export type PayrollCycleDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PayrollCycleDelete'>
);

export type IsPayrollCycleInUseQueryVariables = Exact<{
  PayrollCycleID: Scalars['String'];
}>;


export type IsPayrollCycleInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsPayrollCycleInUse'>
);

export type UpdateLastLoginCompanyMutationVariables = Exact<{
  userID: Scalars['String'];
  lastestAccessedEntity: Scalars['String'];
}>;


export type UpdateLastLoginCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateLastLoginCompany'>
);

export type PayrollCycleInsertMutationVariables = Exact<{
  Input: PayrollCycleInput;
}>;


export type PayrollCycleInsertMutation = (
  { __typename?: 'Mutation' }
  & { PayrollCycleInsert: (
    { __typename?: 'PayrollCycleEntity' }
    & Pick<PayrollCycleEntity, 'PayrollCycleID'>
  ) }
);

export type PayrollCycleDetailQueryVariables = Exact<{
  PayrollCycleID: Scalars['String'];
}>;


export type PayrollCycleDetailQuery = (
  { __typename?: 'Query' }
  & { PayrollCycleDetail: (
    { __typename?: 'PayrollCycleEntity' }
    & Pick<PayrollCycleEntity, 'PayrollCycleID' | 'Description' | 'EPF' | 'EPFVE' | 'SOCSO' | 'MTD' | 'Zakat' | 'EIS' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  ) }
);

export type PayrollCycleUpdateMutationVariables = Exact<{
  PayrollCycleID: Scalars['String'];
  Input: PayrollCycleInput;
}>;


export type PayrollCycleUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PayrollCycleUpdate'>
);

export type PayrollCycleListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type PayrollCycleListingQuery = (
  { __typename?: 'Query' }
  & { PayrollCycleListing: Array<(
    { __typename?: 'PayrollCycleEntity' }
    & Pick<PayrollCycleEntity, 'Status' | 'PayrollCycleID' | 'CompanyID' | 'Description' | 'IsDefault' | 'EPF' | 'EPFVE' | 'SOCSO' | 'MTD' | 'Zakat' | 'EIS' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName' | 'SubscriptionAccountID'>
    )> }
  )> }
);

export type BankListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type BankListingQuery = (
  { __typename?: 'Query' }
  & { BankListing: Array<(
    { __typename?: 'BankInfoEntity' }
    & Pick<BankInfoEntity, 'BankInfoID' | 'Name' | 'AccountNo' | 'AccountName' | 'SwiftCode' | 'OrganizationCode' | 'ContactNo' | 'ContactName' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'IsDefault' | 'CorporateID' | 'ClientBatchID' | 'OriginatorID'>
    & { Address?: Maybe<(
      { __typename?: 'Address2' }
      & Pick<Address2, 'Line1' | 'Line2' | 'PostalCode' | 'City' | 'State' | 'Country'>
    )>, CompanyBank: (
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    ) }
  )> }
);

export type CompanyStatutoryDetailQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CompanyStatutoryDetailQuery = (
  { __typename?: 'Query' }
  & { CompanyStatutoryDetail?: Maybe<(
    { __typename?: 'CompanyStatutoryEntity' }
    & Pick<CompanyStatutoryEntity, 'CompanyStatutoryID' | 'SignBy'>
  )> }
);

export type GetPayrollTemplateLibraryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetPayrollTemplateLibraryQuery = (
  { __typename?: 'Query' }
  & { getPayrollTemplateLibrary: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TemplateLibraryAssignmentID' | 'CompanyID' | 'TargetID' | 'TargetType' | 'Module' | 'SourceType' | 'CreatedDT' | 'ModifiedDT' | 'SourceID' | 'EnableTarget' | 'Sequence'>
    & { PayrollCycle?: Maybe<(
      { __typename?: 'PayrollCycleEntity' }
      & Pick<PayrollCycleEntity, 'PayrollCycleID' | 'Description'>
    )> }
  )> }
);

export type GetPayCycleForPayCalendarQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
}>;


export type GetPayCycleForPayCalendarQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPayCycleForPayCalendar'>
);

export type InsertPayPeriodListingMutationVariables = Exact<{
  PayPeriodInput: PayPeriodInput;
}>;


export type InsertPayPeriodListingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InsertPayPeriodListing'>
);

export type CompanyStatutoryUpdateMutationVariables = Exact<{
  CompanyStatutoryID: Scalars['String'];
  CompanyStatutoryInput: CompanyStatutoryInput;
}>;


export type CompanyStatutoryUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyStatutoryUpdate'>
);

export type BankDeleteMutationVariables = Exact<{
  BankInfoID: Scalars['String'];
}>;


export type BankDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BankDelete'>
);

export type InterbankListingBulkQueryVariables = Exact<{
  BankInfoID: Array<Scalars['String']> | Scalars['String'];
}>;


export type InterbankListingBulkQuery = (
  { __typename?: 'Query' }
  & { InterbankListingBulk: Array<(
    { __typename?: 'InterbankGiroEntity' }
    & Pick<InterbankGiroEntity, 'BankInfoID' | 'InterbankGiroID' | 'IBGCode' | 'EmployeeBank' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type BankInsertMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  BankInfoInputs: BankInfoInputs;
}>;


export type BankInsertMutation = (
  { __typename?: 'Mutation' }
  & { BankInsert: (
    { __typename?: 'BankInfoEntity' }
    & Pick<BankInfoEntity, 'BankInfoID'>
  ) }
);

export type BankUpdateMutationVariables = Exact<{
  input: BankInfoInputs;
  BankInfoID: Scalars['String'];
}>;


export type BankUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BankUpdate'>
);

export type InterbankDeleteMutationVariables = Exact<{
  InterbankGiroID: Scalars['String'];
}>;


export type InterbankDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InterbankDelete'>
);

export type InterbankInsertMutationVariables = Exact<{
  InterbankGiroInput: InterbankGiroInput;
}>;


export type InterbankInsertMutation = (
  { __typename?: 'Mutation' }
  & { InterbankInsert: (
    { __typename?: 'InterbankGiroEntity' }
    & Pick<InterbankGiroEntity, 'InterbankGiroID'>
  ) }
);

export type InterbankSingleUpdateMutationVariables = Exact<{
  InterbankGiroID: Scalars['String'];
  InterbankGiroInput: InterbankGiroInput;
}>;


export type InterbankSingleUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InterbankSingleUpdate'>
);

export type GetRolePermissionByUserQueryVariables = Exact<{
  hrPermissions?: Maybe<Array<HrPermission> | HrPermission>;
}>;


export type GetRolePermissionByUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRolePermissionByUser'>
);

export type IsAccountNoExistsQueryVariables = Exact<{
  AccountNo: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type IsAccountNoExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsAccountNoExists'>
);

export type IsAccountNameExistsQueryVariables = Exact<{
  AccountName: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type IsAccountNameExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsAccountNameExists'>
);

export type EmployeeDependentsListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type EmployeeDependentsListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeDependentsListing: Array<(
    { __typename?: 'EmployeeDependentsEntity' }
    & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID' | 'Relationship' | 'Education' | 'ChildEligibleRate' | 'Occupation' | 'PCBNo' | 'IsWorking' | 'IsOKU' | 'IsEmergencyContact'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'BirthDate' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'Nationality'>
    )> }
  )> }
);

export type HasEmergencyContactQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  DependentID?: Maybe<Scalars['String']>;
}>;


export type HasEmergencyContactQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'hasEmergencyContact'>
);

export type CompanyAltAddressListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  IsStatutoryInfo?: Maybe<Scalars['Boolean']>;
}>;


export type CompanyAltAddressListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyAltAddressListing'>
);

export type CompanyAltAddressInsertMutationVariables = Exact<{
  input: CompanyAltAddressInput;
}>;


export type CompanyAltAddressInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyAltAddressInsert'>
);

export type CompanyAltAddressUpdateMutationVariables = Exact<{
  CompanyAltAddressID: Scalars['String'];
  input: CompanyAltAddressInput;
}>;


export type CompanyAltAddressUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyAltAddressUpdate'>
);

export type CompanyAltAddressDeleteMutationVariables = Exact<{
  CompanyAltAddressID: Scalars['String'];
}>;


export type CompanyAltAddressDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyAltAddressDelete'>
);

export type LoginUserPermsQueryVariables = Exact<{ [key: string]: never; }>;


export type LoginUserPermsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'LoginUserPerms'>
);

export type PayrollCommonSettingsInfoLineQueryVariables = Exact<{ [key: string]: never; }>;


export type PayrollCommonSettingsInfoLineQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PayrollCommonSettingsInfoLine'>
);

export type EmployeeCalendarLeaveDetailsQueryVariables = Exact<{
  input: EmpCalendarLeaveListing;
}>;


export type EmployeeCalendarLeaveDetailsQuery = (
  { __typename?: 'Query' }
  & { EmployeeCalendarLeaveDetails?: Maybe<Array<(
    { __typename?: 'LeaveDetailsEntity' }
    & Pick<LeaveDetailsEntity, 'Status' | 'LeaveHeaderID'>
    & { LeaveHeader?: Maybe<(
      { __typename?: 'LeaveHeaderEntity' }
      & Pick<LeaveHeaderEntity, 'LeaveHeaderType' | 'FromDate' | 'UntilDate'>
      & { LeaveType?: Maybe<(
        { __typename?: 'LeaveTypeEntity' }
        & Pick<LeaveTypeEntity, 'LeaveTypeCode' | 'Name'>
      )>, Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'SubscriptionUserID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'BirthDate'>
        )> }
      )> }
    )> }
  )>> }
);

export type EmployeeHomeCalendarQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  LeaveDate: Scalars['DateTime'];
}>;


export type EmployeeHomeCalendarQuery = (
  { __typename?: 'Query' }
  & { EmployeeHomeCalendar?: Maybe<Array<(
    { __typename?: 'HomeCalendarOutput' }
    & Pick<HomeCalendarOutput, 'HolidayDescription' | 'publicHolidayOffDayDate' | 'EmployeeName' | 'EmployeeNo' | 'EmployeeID' | 'myBirthDate' | 'BirthDate' | 'Remarks' | 'leaveType' | 'TotalDay' | 'FromDate' | 'UntilDate' | 'LeaveDate'>
  )>> }
);

export type CompanyDeclaredHolidayListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StateID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  ContactID?: Maybe<Scalars['String']>;
}>;


export type CompanyDeclaredHolidayListingQuery = (
  { __typename?: 'Query' }
  & { CompanyDeclaredHolidayListing: Array<(
    { __typename?: 'CompanyDelaredHolidayEntity' }
    & Pick<CompanyDelaredHolidayEntity, 'CompanyDelaredHolidayID' | 'CompanyID' | 'StateID' | 'HolidayDate' | 'Description' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type EmployeeCalendarQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
}>;


export type EmployeeCalendarQuery = (
  { __typename?: 'Query' }
  & { EmployeeCalendar?: Maybe<(
    { __typename?: 'EmployeeCalendarOutput' }
    & { companyOffDays?: Maybe<Array<(
      { __typename?: 'CompanyOffDayOutput' }
      & Pick<CompanyOffDayOutput, 'DayOffDate' | 'Description'>
    )>>, employeeLeaves?: Maybe<Array<(
      { __typename?: 'EmployeeLeavesOutput' }
      & Pick<EmployeeLeavesOutput, 'EmployeeNo' | 'FullName' | 'LeaveType' | 'LeaveHeaderID' | 'Remarks' | 'LeaveDate' | 'FromDate' | 'UntilDate' | 'ApproverID' | 'SessionType' | 'IsOffDay' | 'IsRestDay' | 'IsPublicHoliday' | 'LeaveApprovalStatus' | 'TotalDay'>
    )>>, employeeStaffLeaves?: Maybe<Array<(
      { __typename?: 'EmployeeLeavesOutput' }
      & Pick<EmployeeLeavesOutput, 'EmployeeNo' | 'LeaveHeaderID' | 'FullName' | 'LeaveType' | 'Remarks' | 'LeaveDate' | 'FromDate' | 'UntilDate' | 'SessionType' | 'IsOffDay' | 'IsRestDay' | 'IsPublicHoliday' | 'LeaveApprovalStatus' | 'OwnSubordinates' | 'TotalDay'>
    )>>, employeeBirthdays?: Maybe<Array<(
      { __typename?: 'EmployeeBirthdaysOutput' }
      & Pick<EmployeeBirthdaysOutput, 'EmployeeNo' | 'FullName' | 'BirthDate' | 'CompanyID'>
    )>> }
  )> }
);

export type GetLeaveOffDayQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetLeaveOffDayQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetLeaveOffDay'>
);

export type GetMyClaimListingQueryVariables = Exact<{
  input: ClaimRecordsInput;
}>;


export type GetMyClaimListingQuery = (
  { __typename?: 'Query' }
  & { GetMyClaimListing: (
    { __typename?: 'MyClaimListingOutput' }
    & Pick<MyClaimListingOutput, 'CountApprovedClaims' | 'TotalApprovedClaimsAmount'>
    & { ClaimRecords?: Maybe<Array<(
      { __typename?: 'ClaimRecordsListing' }
      & Pick<ClaimRecordsListing, 'EmployeeID' | 'ClaimHeaderID' | 'EmployeeNo' | 'EmployeeName' | 'Subject' | 'SubmissionDate' | 'CountClaimDetails' | 'TotalClaimAmount' | 'CountVerifiedClaim' | 'TotalVerifiedClaimAmount' | 'DetailStatus'>
    )>> }
  ) }
);

export type ClaimCompanySettingSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type ClaimCompanySettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ClaimCompanySettingSummary'>
);

export type GetApprovedClaimVerificationDetailsQueryVariables = Exact<{
  ClaimHeaderID: Scalars['String'];
}>;


export type GetApprovedClaimVerificationDetailsQuery = (
  { __typename?: 'Query' }
  & { GetApprovedClaimVerificationDetails?: Maybe<(
    { __typename?: 'ApprovedClaimVerificationDetailsOutput' }
    & Pick<ApprovedClaimVerificationDetailsOutput, 'Subject' | 'ClaimNo' | 'ClaimHeaderID' | 'JobAccountID' | 'JobAccountName' | 'HeaderStatus' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName' | 'CountClaimDetails' | 'CountVerifiedClaim' | 'TotalClaimAmount' | 'SubmissionDate'>
    & { ApprovedClaimDetail?: Maybe<(
      { __typename?: 'ApprovedClaimDetail' }
      & Pick<ApprovedClaimDetail, 'ApprovalStatus' | 'ApprovalRemark' | 'ApprovalByID' | 'ApprovalByName' | 'ApprovalDate'>
    )>, MyClaimDetails?: Maybe<Array<(
      { __typename?: 'MyClaimDetailOutput' }
      & Pick<MyClaimDetailOutput, 'ClaimDetailID' | 'ClaimTypeID' | 'ClaimTypeName' | 'ClaimAmount' | 'ClaimBalance' | 'ReceiptDate' | 'ReceiptNo' | 'DocumentID' | 'DocumentName' | 'Remark'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )>, DocumentIDs?: Maybe<Array<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
      )>>, VerifiedClaimDetail?: Maybe<(
        { __typename?: 'VerifiedClaimDetail' }
        & Pick<VerifiedClaimDetail, 'FinanceApprovalStatus' | 'FinanceAmount' | 'FinanceRemark' | 'FinanceApprovalByID' | 'FinanceApprovalByName' | 'FinanceApprovalDate'>
      )>, ClaimDuplicateList?: Maybe<Array<(
        { __typename?: 'ClaimDuplicateList' }
        & Pick<ClaimDuplicateList, 'EmployeeName' | 'EmployeeNo' | 'CompanyName' | 'ReceiptDate' | 'Remarks' | 'DocFile'>
      )>> }
    )>> }
  )> }
);

export type GetApprovedClaimVerificationListingQueryVariables = Exact<{
  input: ApprovalClaimVerificationInput;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  Incomplete?: Maybe<Scalars['Boolean']>;
}>;


export type GetApprovedClaimVerificationListingQuery = (
  { __typename?: 'Query' }
  & { GetApprovedClaimVerificationListing?: Maybe<Array<(
    { __typename?: 'ClaimRecordsListing' }
    & Pick<ClaimRecordsListing, 'JobGradeID' | 'OrgUnitID' | 'FormStatus' | 'ClaimHeaderID' | 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'SubmissionDate' | 'Subject' | 'CountClaimDetails' | 'TotalClaimAmount' | 'CountVerifiedClaim' | 'TotalVerifiedClaimAmount' | 'HeaderStatus' | 'DetailStatus' | 'ApprovalDate' | 'ApprovalName' | 'FinanceApprovalName' | 'FinanceApprovalDate' | 'ClaimNo' | 'PostedBy' | 'PostedDate'>
    & { MyClaimDetails?: Maybe<Array<(
      { __typename?: 'MyClaimDetailOutput' }
      & Pick<MyClaimDetailOutput, 'ClaimDetailID' | 'ClaimTypeID' | 'ClaimTypeName' | 'ClaimAmount' | 'ClaimBalance' | 'ReceiptDate' | 'ReceiptNo' | 'DocumentID' | 'DocumentName' | 'Remark'>
    )>> }
  )>> }
);

export type GetApprovedClaimCountQueryVariables = Exact<{
  input: ApprovalClaimVerificationInput;
}>;


export type GetApprovedClaimCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetApprovedClaimCount'>
);

export type VerifyApprovedClaimDetailMutationVariables = Exact<{
  input: VerifyApprovedClaimDetailInput;
}>;


export type VerifyApprovedClaimDetailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'VerifyApprovedClaimDetail'>
);

export type ApproveClaimRecordsMutationVariables = Exact<{
  input: ApproveClaimRecordsInput;
  IsSupersede?: Maybe<Scalars['Boolean']>;
}>;


export type ApproveClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApproveClaimRecords'>
);

export type ReverseApprovedClaimRecordsMutationVariables = Exact<{
  input: ApproveClaimRecordsInput;
}>;


export type ReverseApprovedClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReverseApprovedClaimRecords'>
);

export type VerifyMyClaimQueryVariables = Exact<{
  VerfiyClaimInput: VerfiyClaimInput;
}>;


export type VerifyMyClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'VerifyMyClaim'>
);

export type ClaimTypeListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  ClaimCategory?: Maybe<ClaimCategory>;
}>;


export type ClaimTypeListingQuery = (
  { __typename?: 'Query' }
  & { ClaimTypeListing: Array<(
    { __typename?: 'ClaimTypeEntity' }
    & Pick<ClaimTypeEntity, 'ClaimTypeID' | 'SubscriptionAccountID' | 'Name' | 'IsAttachment' | 'IsRemarks' | 'IsDisplay' | 'IsReceiptNo' | 'IsAdditionalFieldsTemplate' | 'UserDefineFieldsTemplate' | 'ClaimCategory' | 'PayItemID' | 'PaymentMethod' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayItemID' | 'PayName' | 'PayCode' | 'PayItemType' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>, ClaimPolicies?: Maybe<Array<(
      { __typename?: 'ClaimItemEntity' }
      & Pick<ClaimItemEntity, 'ClaimItemID' | 'ClaimTypeID' | 'TotalAmt' | 'ClaimFrequency'>
      & { ClaimBenefit?: Maybe<(
        { __typename?: 'ClaimBenefitEntity' }
        & Pick<ClaimBenefitEntity, 'Name' | 'Description' | 'SoftDelete'>
      )> }
    )>> }
  )> }
);

export type GetEmpClaimListingQueryVariables = Exact<{
  GetEmpClaimListingInput: Array<GetEmpClaimListingInput> | GetEmpClaimListingInput;
}>;


export type GetEmpClaimListingQuery = (
  { __typename?: 'Query' }
  & { GetEmpClaimListing: Array<(
    { __typename?: 'GetEmpClaimListingOutput' }
    & Pick<GetEmpClaimListingOutput, 'ClaimStatus'>
  )> }
);

export type GetMainClaimListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type GetMainClaimListingQuery = (
  { __typename?: 'Query' }
  & { GetMainClaimListing: Array<(
    { __typename?: 'GetMainClaimListingOutput' }
    & Pick<GetMainClaimListingOutput, 'Year' | 'Amount' | 'TotalClaimApproved'>
  )> }
);

export type GetMyClaimEntitlementQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type GetMyClaimEntitlementQuery = (
  { __typename?: 'Query' }
  & { GetMyClaimEntitlement: (
    { __typename?: 'EmpClaimEntitlementOutput' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo' | 'JoinedDate' | 'EmployeeID'>
    )>, ClaimItems?: Maybe<Array<(
      { __typename?: 'ClaimItemEntity' }
      & { ClaimType?: Maybe<(
        { __typename?: 'ClaimTypeEntity' }
        & Pick<ClaimTypeEntity, 'Name'>
      )> }
    )>> }
  ) }
);

export type GetEmpsClaimBalanceQueryVariables = Exact<{
  input: EmpClaimEntitlementInput;
  isV5?: Maybe<Scalars['Boolean']>;
}>;


export type GetEmpsClaimBalanceQuery = (
  { __typename?: 'Query' }
  & { GetEmpsClaimBalance: Array<(
    { __typename?: 'EmpClaimBalanceOutput' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'JoinedDate'>
    )>, ClaimBalance?: Maybe<Array<(
      { __typename?: 'EmpClaimBalance' }
      & Pick<EmpClaimBalance, 'Drafted' | 'PendingSuperior' | 'PendingFinance' | 'PendingPayroll' | 'Posted' | 'OccurenceBalance' | 'Balance' | 'ErrorMessage'>
      & { ClaimItems?: Maybe<(
        { __typename?: 'ClaimItemEntity' }
        & Pick<ClaimItemEntity, 'ClaimItemID' | 'TotalAmt' | 'Occurrence' | 'OccurrenceLimit' | 'ClaimFrequency'>
        & { ClaimType?: Maybe<(
          { __typename?: 'ClaimTypeEntity' }
          & Pick<ClaimTypeEntity, 'Name' | 'ClaimTypeID'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetMyClaimPoolBalanceQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  AsToDate: Scalars['DateTime'];
  ClaimHeaderID?: Maybe<Scalars['String']>;
  isV5?: Maybe<Scalars['Boolean']>;
}>;


export type GetMyClaimPoolBalanceQuery = (
  { __typename?: 'Query' }
  & { GetMyClaimPoolBalance: (
    { __typename?: 'EmpClaimPoolBalanceOutput' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, ClaimPoolBalance?: Maybe<Array<(
      { __typename?: 'EmpClaimPoolBalance' }
      & Pick<EmpClaimPoolBalance, 'Drafted' | 'Frequency' | 'PendingSuperior' | 'PendingFinance' | 'PendingPayroll' | 'Posted' | 'Balance' | 'ErrorMessage'>
      & { ClaimPool?: Maybe<(
        { __typename?: 'ClaimPoolEntity' }
        & Pick<ClaimPoolEntity, 'ID' | 'PoolTitle' | 'ClaimTypeIDs' | 'FrequencyCriteria' | 'CompanyID'>
        & { ClaimTypes?: Maybe<Array<(
          { __typename?: 'ClaimTypeEntity' }
          & Pick<ClaimTypeEntity, 'Name' | 'ClaimTypeID'>
        )>> }
      )>, ClaimPoolCondition?: Maybe<(
        { __typename?: 'ClaimPoolConditionEntity' }
        & Pick<ClaimPoolConditionEntity, 'ID' | 'ClaimPoolID' | 'PoolAmountLimit' | 'PoolFrequency' | 'ConditionList'>
      )> }
    )>> }
  ) }
);

export type GetMyClaimBalanceQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  AsToDate: Scalars['DateTime'];
  ClaimHeaderID?: Maybe<Scalars['String']>;
  isV5?: Maybe<Scalars['Boolean']>;
}>;


export type GetMyClaimBalanceQuery = (
  { __typename?: 'Query' }
  & { GetMyClaimBalance: (
    { __typename?: 'EmpClaimBalanceOutput' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, ClaimBalance?: Maybe<Array<(
      { __typename?: 'EmpClaimBalance' }
      & Pick<EmpClaimBalance, 'Drafted' | 'Frequency' | 'PendingSuperior' | 'PendingFinance' | 'PendingPayroll' | 'Posted' | 'Balance' | 'OccurenceBalance' | 'ErrorMessage'>
      & { ClaimItems?: Maybe<(
        { __typename?: 'ClaimItemEntity' }
        & Pick<ClaimItemEntity, 'ClaimItemID' | 'ClaimBenefitID' | 'ClaimTypeID' | 'ClaimFrequency' | 'TotalAmt' | 'Occurrence' | 'OccurrenceLimit' | 'IsLimit' | 'IsRemarks' | 'IsAttachment'>
        & { ClaimType?: Maybe<(
          { __typename?: 'ClaimTypeEntity' }
          & Pick<ClaimTypeEntity, 'Name'>
        )> }
      )> }
    )>> }
  ) }
);

export type GetMyClaimDetailsQueryVariables = Exact<{
  ClaimHeaderID: Scalars['String'];
}>;


export type GetMyClaimDetailsQuery = (
  { __typename?: 'Query' }
  & { GetMyClaimDetails: (
    { __typename?: 'MyClaimHeaderDetails' }
    & Pick<MyClaimHeaderDetails, 'ClaimHeaderID' | 'JobAccountID' | 'Subject' | 'SubmissionDate' | 'CountClaimDetails' | 'TotalClaimAmount' | 'JobAccountName' | 'HeaderStatus'>
    & { MyClaimDetails?: Maybe<Array<(
      { __typename?: 'MyClaimDetailOutput' }
      & Pick<MyClaimDetailOutput, 'ClaimDetailID' | 'ClaimTypeID' | 'ClaimTypeName' | 'ClaimAmount' | 'ReceiptDate' | 'ReceiptNo' | 'DocumentName' | 'DocumentID' | 'Remark'>
      & { VerifiedClaimDetail?: Maybe<(
        { __typename?: 'VerifiedClaimDetail' }
        & Pick<VerifiedClaimDetail, 'FinanceAmount' | 'FinanceRemark' | 'FinanceApprovalByID' | 'FinanceApprovalDate' | 'FinanceApprovalStatus' | 'FinanceApprovalByName'>
      )> }
    )>> }
  ) }
);

export type SubmitMyClaimMutationVariables = Exact<{
  input: SubmitClaimRecordsInput;
  workFlowStepInput?: Maybe<Array<WorkFlowStepInput> | WorkFlowStepInput>;
  isV5?: Maybe<Scalars['Boolean']>;
}>;


export type SubmitMyClaimMutation = (
  { __typename?: 'Mutation' }
  & { SubmitMyClaim: (
    { __typename?: 'ClaimHeaderEntity' }
    & Pick<ClaimHeaderEntity, 'ClaimHeaderID' | 'EmployeeID' | 'CompanyID' | 'ApprovalStatus'>
  ) }
);

export type DeleteClaimRecordsMutationVariables = Exact<{
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteClaimRecords'>
);

export type SubmitClaimForApprovalMutationVariables = Exact<{
  ClaimHeaderIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type SubmitClaimForApprovalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitClaimForApproval'>
);

export type DeleteApprovedClaimRecordsMutationVariables = Exact<{
  input: ApproveClaimRecordsInput;
  Remark: Scalars['String'];
  CancelRemark: Scalars['String'];
  IsSupersede?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteApprovedClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteApprovedClaimRecords'>
);

export type GetAllExpenseAccountQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetAllExpenseAccountQuery = (
  { __typename?: 'Query' }
  & { GetAllExpenseAccount: Array<(
    { __typename?: 'JobAccountEntity' }
    & Pick<JobAccountEntity, 'Name' | 'JobAccountID'>
  )> }
);

export type ClaimListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type ClaimListingQuery = (
  { __typename?: 'Query' }
  & { ClaimListing: Array<(
    { __typename?: 'ClaimHeaderEntity' }
    & Pick<ClaimHeaderEntity, 'ClaimHeaderID' | 'Subject' | 'ApprovalStatus' | 'CreatedDT'>
    & { ClaimDetails: Array<(
      { __typename?: 'ClaimDetailsEntity' }
      & Pick<ClaimDetailsEntity, 'ClaimDetailsID' | 'ClaimAmount' | 'DocumentID'>
    )> }
  )> }
);

export type JobGradePolicyListingQueryVariables = Exact<{
  JobGradeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
}>;


export type JobGradePolicyListingQuery = (
  { __typename?: 'Query' }
  & { JobGradePolicyListing: Array<(
    { __typename?: 'JobGradePolicyEntity' }
    & Pick<JobGradePolicyEntity, 'JobGradePolicyID' | 'CompanyID' | 'JobGradeID'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, ClaimTemplates?: Maybe<Array<(
      { __typename?: 'ClaimTemplateEntity' }
      & Pick<ClaimTemplateEntity, 'ClaimPolicyID'>
      & { ClaimItem?: Maybe<(
        { __typename?: 'ClaimItemEntity' }
        & Pick<ClaimItemEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'ClaimItemID' | 'ClaimBenefitID' | 'ClaimTypeID' | 'ClaimFrequency' | 'TotalAmt' | 'Occurrence' | 'OccurrenceLimit' | 'MileageTableID' | 'IsMileageTable' | 'IsLimit' | 'IsRemarks' | 'IsAttachment'>
        & { ClaimType?: Maybe<(
          { __typename?: 'ClaimTypeEntity' }
          & Pick<ClaimTypeEntity, 'Name' | 'ClaimTypeID' | 'ClaimCategory' | 'IsRemarks' | 'IsAttachment' | 'IsReceiptNo'>
        )>, RateDetails?: Maybe<Array<(
          { __typename?: 'ClaimDailyRateEntity' }
          & Pick<ClaimDailyRateEntity, 'ClaimBenefitID' | 'ClaimDailyRateID' | 'ClaimParameterID' | 'Rate'>
        )>>, VehicleRateDetails?: Maybe<Array<(
          { __typename?: 'ClaimVehicleRateEntity' }
          & Pick<ClaimVehicleRateEntity, 'ClaimVehicleRateID' | 'ClaimParameterID' | 'ClaimBenefitID' | 'StartValue' | 'EndValue' | 'Rate' | 'Status'>
        )>>, MealRateDetails?: Maybe<Array<(
          { __typename?: 'ClaimMealRateEntity' }
          & Pick<ClaimMealRateEntity, 'ClaimMealRateID' | 'ClaimParameterID' | 'VenueID' | 'ClaimBenefitID' | 'Rate' | 'Status'>
        )>> }
      )> }
    )>>, LeaveTemplates?: Maybe<Array<(
      { __typename?: 'LeaveTemplateEntity' }
      & Pick<LeaveTemplateEntity, 'LeaveTemplateID' | 'JobGradePolicyID' | 'LeavePolicyID'>
      & { LeaveItem?: Maybe<(
        { __typename?: 'LeaveItemEntity' }
        & Pick<LeaveItemEntity, 'Title' | 'LeaveItemID' | 'LeaveTypeID' | 'TotalDay' | 'LeaveFrequency' | 'CutOffDate' | 'Occurrence' | 'NoOccurrence' | 'AvailableBasis' | 'CalculationMethod' | 'NewCutOffDay' | 'ResignCutOffDay' | 'MonthlyCutOffDay' | 'ShortNoticeType' | 'ShortNoticePeriod' | 'CFMethod' | 'CFValue' | 'CFMonth' | 'IsOffDay' | 'IsRestDay' | 'IsPublicHoliday' | 'IsHalfDay' | 'IsAttachment' | 'IsRemarks' | 'IsRounding' | 'IsConfirmedStaff' | 'IsDisplayEmployee' | 'IsAdvanceLeave' | 'IsLeaveReason' | 'IsLeaveDisease'>
        & { LeaveType?: Maybe<(
          { __typename?: 'LeaveTypeEntity' }
          & Pick<LeaveTypeEntity, 'Name' | 'LeaveTypeID' | 'LeaveTypeCode'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetClaimRecordsListingQueryVariables = Exact<{
  input: ClaimRecordsInput;
  superiorRejectToDraft?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetClaimRecordsListingQuery = (
  { __typename?: 'Query' }
  & { GetClaimRecordsListing?: Maybe<Array<(
    { __typename?: 'ClaimRecordsListing' }
    & Pick<ClaimRecordsListing, 'ClaimHeaderID' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName' | 'SubmissionDate' | 'Subject' | 'ClaimNo' | 'JobGradeID' | 'OrgUnitID' | 'CountClaimDetails' | 'TotalClaimAmount' | 'HeaderStatus' | 'DetailStatus' | 'CountVerifiedClaim' | 'TotalVerifiedClaimAmount' | 'CountClaimHistory' | 'ApprovalDate' | 'CompletedVerificationDate' | 'CompletedVerificationBy' | 'ApprovalName' | 'SubmitterName' | 'Remark' | 'ApprovalStatus' | 'ApproverID' | 'IsRejected' | 'WorkFlowCurrentApproversList'>
    & { WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName' | 'NotifiedDT'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )>, DocumentIDs?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
    )>> }
  )>> }
);

export type GetClaimRecordsTotalCountQueryVariables = Exact<{
  input: ClaimRecordsInput;
}>;


export type GetClaimRecordsTotalCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetClaimRecordsTotalCount'>
);

export type GetClaimRecordsDetailsQueryVariables = Exact<{
  ClaimHeaderID: Scalars['String'];
}>;


export type GetClaimRecordsDetailsQuery = (
  { __typename?: 'Query' }
  & { GetClaimRecordsDetails?: Maybe<(
    { __typename?: 'ClaimRecordsDetailHeaderOutput' }
    & Pick<ClaimRecordsDetailHeaderOutput, 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'ClaimNo' | 'JobAccountName' | 'SubmissionDate' | 'Subject' | 'CountClaimDetails' | 'TotalClaimAmount' | 'ApprovalStatus'>
    & { ClaimRecordsDetail?: Maybe<Array<(
      { __typename?: 'ClaimRecordsDetail' }
      & Pick<ClaimRecordsDetail, 'ClaimDetailsID' | 'ClaimTypeID' | 'ClaimTypeName' | 'ClaimTypePaymentMethod' | 'ClaimAmount' | 'VerifiedAmount' | 'FinanceApproveStatus' | 'FinanceRemarks' | 'FinanceApproveDate' | 'FinanceApproveBy' | 'ReceiptDate' | 'ReceiptNo' | 'PostedDate' | 'ExpenseAccount' | 'LocationFrom' | 'LocationTo' | 'MealStartDatetime' | 'MealEndDatetime' | 'OutstationStartDate' | 'OutstationEndDate' | 'VehicleTypeID' | 'Venue' | 'Rate' | 'ClaimMileageID' | 'DocumentID' | 'DocumentName' | 'ClaimRemarks'>
      & { DocumentIDs?: Maybe<Array<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
      )>>, Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
      )> }
    )>> }
  )> }
);

export type GetClaimApprovalHistoryQueryVariables = Exact<{
  ApproverID: Scalars['String'];
}>;


export type GetClaimApprovalHistoryQuery = (
  { __typename?: 'Query' }
  & { GetClaimApprovalHistory?: Maybe<Array<(
    { __typename?: 'ClaimToApproveListingOutput' }
    & Pick<ClaimToApproveListingOutput, 'ApprovalDate' | 'ApproverName' | 'ApprovalStatus' | 'ClaimHeaderID' | 'CreatedDT' | 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'Subject' | 'TotalClaim' | 'TotalClaimAmount' | 'IsRejected' | 'WorkFlowCurrentApproversList'>
    & { WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName' | 'NotifiedDT'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type ClaimParameterListingQueryVariables = Exact<{
  ClaimParameterType: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type ClaimParameterListingQuery = (
  { __typename?: 'Query' }
  & { ClaimParameterListing: Array<(
    { __typename?: 'ClaimParameterEntity' }
    & Pick<ClaimParameterEntity, 'ClaimParameterID' | 'Name' | 'CompanyID' | 'ClaimParameterType' | 'ModifiedBy' | 'ModifiedDT' | 'CreatedBy' | 'CreatedDT' | 'Status' | 'StartTime' | 'EndTime'>
    & { Details?: Maybe<Array<(
      { __typename?: 'ClaimMileageTableEntity' }
      & Pick<ClaimMileageTableEntity, 'ClaimParameterID' | 'ClaimMileageTableID' | 'TravelFrom' | 'TravelTo' | 'Route' | 'Distance'>
    )>> }
  )> }
);

export type ApproverListByCompanyQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
}>;


export type ApproverListByCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ApproverListByCompany'>
);

export type IsClaimSubjectExistsQueryVariables = Exact<{
  Subject: Scalars['String'];
  input: ClaimRecordsInput;
  ClaimHeaderID?: Maybe<Scalars['String']>;
}>;


export type IsClaimSubjectExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsClaimSubjectExists'>
);

export type GetClaimReportDetailsQueryVariables = Exact<{
  ClaimHeaderID: Scalars['String'];
}>;


export type GetClaimReportDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetClaimReportDetails'>
);

export type DataChangesInsertMutationVariables = Exact<{
  DataChangesInput: DataChangesInput;
  DocumentsInput?: Maybe<DocumentsInput>;
}>;


export type DataChangesInsertMutation = (
  { __typename?: 'Mutation' }
  & { DataChangesInsert: (
    { __typename?: 'DataChangesEntity' }
    & Pick<DataChangesEntity, 'DataChangesID' | 'ApprovalStatus'>
  ) }
);

export type GetDataChangesListingQueryVariables = Exact<{
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetDataChangesListingQuery = (
  { __typename?: 'Query' }
  & { getDataChangesListing?: Maybe<Array<(
    { __typename?: 'DataChangesEntity' }
    & Pick<DataChangesEntity, 'DataChangesID' | 'CompanyID' | 'Type' | 'ApprovalStatus' | 'ApprovalDT' | 'ApproverID' | 'CreatedDT' | 'ModifiedDT' | 'Remarks' | 'Description' | 'Action' | 'Status'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
    )>, Approver?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName'>
      )> }
    )>, ChangesData?: Maybe<(
      { __typename?: 'ChangesData' }
      & Pick<ChangesData, 'DocumentID' | 'Nationality' | 'PassportNo' | 'NRIC' | 'Marital' | 'Race' | 'Religion' | 'IsOKU' | 'EmployeeBank' | 'EmployeeBankNo' | 'EmployeeEPFNo' | 'EmployeePCBNo' | 'EmployeeDependentsID' | 'ContactID' | 'IsEmergencyContact' | 'MobileNo' | 'Occupation' | 'Education' | 'ChildEligibleRate' | 'PCBNo' | 'FullName' | 'BirthDate' | 'SameAddress'>
      & { PermanentAddress?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
      )>, Address?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
      )> }
    )>, OldData?: Maybe<(
      { __typename?: 'ChangesData' }
      & Pick<ChangesData, 'DocumentID' | 'Nationality' | 'PassportNo' | 'NRIC' | 'Marital' | 'Race' | 'Religion' | 'IsOKU' | 'EmployeeBank' | 'EmployeeBankNo' | 'EmployeeEPFNo' | 'EmployeePCBNo' | 'EmployeeDependentsID' | 'ContactID' | 'IsEmergencyContact' | 'MobileNo' | 'Occupation' | 'Education' | 'ChildEligibleRate' | 'PCBNo' | 'FullName' | 'BirthDate'>
      & { PermanentAddress?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
      )>, Address?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
      )> }
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName' | 'NotifiedDT'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type SubmitDataChangesMutationVariables = Exact<{
  DataChangesInput: DataChangesInput;
}>;


export type SubmitDataChangesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitDataChanges'>
);

export type DeleteDataChangesMutationVariables = Exact<{
  DataChangesInput: DataChangesInput;
}>;


export type DeleteDataChangesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteDataChanges'>
);

export type CancelDataChangesMutationVariables = Exact<{
  DataChangesInput: DataChangesInput;
}>;


export type CancelDataChangesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CancelDataChanges'>
);

export type GetDocumentQueryVariables = Exact<{
  DocumentID: Scalars['String'];
}>;


export type GetDocumentQuery = (
  { __typename?: 'Query' }
  & { getDocument: Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'Description'>
  )> }
);

export type EmployeeDependentsSummaryQueryVariables = Exact<{
  EmployeeDependentsID: Scalars['String'];
}>;


export type EmployeeDependentsSummaryQuery = (
  { __typename?: 'Query' }
  & { EmployeeDependentsSummary: (
    { __typename?: 'EmployeeDependentsEntity' }
    & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID' | 'Relationship' | 'Education' | 'IsOKU' | 'ChildEligibleRate' | 'Occupation' | 'IsEmergencyContact' | 'PCBNo' | 'DocumentID'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'Nationality' | 'BirthDate'>
    )> }
  ) }
);

export type ApproveDataChangesMutationVariables = Exact<{
  DataChangesInput: DataChangesInput;
}>;


export type ApproveDataChangesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApproveDataChanges'>
);

export type RejectDataChangesMutationVariables = Exact<{
  DataChangesInput: DataChangesInput;
}>;


export type RejectDataChangesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RejectDataChanges'>
);

export type DataChangesApprovalMutationVariables = Exact<{
  input: Array<DataChangesInput> | DataChangesInput;
  UpdateBy: Scalars['String'];
}>;


export type DataChangesApprovalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DataChangesApproval'>
);

export type DataChangesRejectMutationVariables = Exact<{
  input: Array<DataChangesInput> | DataChangesInput;
  UpdateBy: Scalars['String'];
}>;


export type DataChangesRejectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DataChangesReject'>
);

export type SelectedDataChangesApprovalPolicyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type SelectedDataChangesApprovalPolicyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SelectedDataChangesApprovalPolicy'>
);

export type NewsListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  IsPublishedOnly: Scalars['Boolean'];
  StartMonth: Scalars['String'];
  EndMonth: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  isValid?: Maybe<Scalars['Boolean']>;
}>;


export type NewsListingQuery = (
  { __typename?: 'Query' }
  & { NewsListing: Array<(
    { __typename?: 'NewsEntity' }
    & Pick<NewsEntity, 'NewsID' | 'SubscriptionAccountID' | 'Title' | 'Content' | 'PublishedBy' | 'ValidFrom' | 'ValidTo' | 'Acknowledgement' | 'AcknowledgementStatement' | 'PostStatus' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'ContentUrl'>
    & { Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'DocumentType' | 'Description' | 'BucketFileName'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName'>
    )> }
  )> }
);

export type NewsCountQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  IsPublishedOnly: Scalars['Boolean'];
  StartMonth: Scalars['String'];
  EndMonth: Scalars['String'];
  isValid?: Maybe<Scalars['Boolean']>;
}>;


export type NewsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'NewsCount'>
);

export type NewsDeleteMutationVariables = Exact<{
  NewsID: Scalars['String'];
  DocumentID?: Maybe<Scalars['String']>;
}>;


export type NewsDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NewsDelete'>
);

export type NewsUpdateMutationVariables = Exact<{
  NewsID: Scalars['String'];
  DocumentID: Scalars['String'];
  NewsInput: NewsInput;
  DocumentsInput: DocumentsInput;
  ContentURL?: Maybe<Scalars['Upload']>;
}>;


export type NewsUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NewsUpdate'>
);

export type NewsInsertMutationVariables = Exact<{
  NewsInput: NewsInput;
  DocumentsInput: DocumentsInput;
  ContentURL?: Maybe<Scalars['Upload']>;
}>;


export type NewsInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NewsInsert'>
);

export type DocumentListingQueryVariables = Exact<{
  ParentID?: Maybe<Scalars['String']>;
  DocumentType: DocumentType;
  ParentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DocumentListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing: Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentType' | 'DocumentFile' | 'BucketFileName' | 'Content' | 'FileSize' | 'ParentID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type DocumentDeleteMutationVariables = Exact<{
  DocumentID: Scalars['String'];
}>;


export type DocumentDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DocumentDelete'>
);

export type CompanyDocumentDeleteMutationVariables = Exact<{
  DocumentID: Scalars['String'];
}>;


export type CompanyDocumentDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyDocumentDelete'>
);

export type CompanyDocumentInsertMutationVariables = Exact<{
  DocumentsInput: DocumentsInput;
}>;


export type CompanyDocumentInsertMutation = (
  { __typename?: 'Mutation' }
  & { CompanyDocumentInsert: (
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'DocumentID'>
  ) }
);

export type DocumentInsertMutationVariables = Exact<{
  DocumentsInput: DocumentsInput;
}>;


export type DocumentInsertMutation = (
  { __typename?: 'Mutation' }
  & { DocumentInsert: (
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'DocumentID'>
  ) }
);

export type PublishPaySlipListingByEmployeeQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
}>;


export type PublishPaySlipListingByEmployeeQuery = (
  { __typename?: 'Query' }
  & { PublishPaySlipListingByEmployee: Array<(
    { __typename?: 'PublishPayslipEntity' }
    & Pick<PublishPayslipEntity, 'PublishPaySlipID' | 'CompanyID' | 'PublishedDate' | 'IsPublish' | 'PayrollCycleID' | 'PayPeriodID' | 'Remarks' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { PayPeriod: (
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PayPeriodID' | 'PeriodYearMonth' | 'FromDate' | 'ToDate' | 'ActualWorkDays'>
    ), PayrollCycle: (
      { __typename?: 'PayrollCycleEntity' }
      & Pick<PayrollCycleEntity, 'PayrollCycleID' | 'Description'>
    ) }
  )> }
);

export type PublishPaySlipListingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  Year: Scalars['Float'];
  CompanyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PublishPaySlipListingQuery = (
  { __typename?: 'Query' }
  & { PublishPaySlipListing: Array<(
    { __typename?: 'PublishPayslipEntity' }
    & Pick<PublishPayslipEntity, 'PublishPaySlipID' | 'CompanyID' | 'PublishedDate' | 'IsPublish' | 'PayrollCycleID' | 'PayPeriodID' | 'Remarks' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { PayPeriod: (
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PayPeriodID' | 'PeriodYearMonth' | 'FromDate' | 'ToDate' | 'ActualWorkDays'>
    ), PayrollCycle: (
      { __typename?: 'PayrollCycleEntity' }
      & Pick<PayrollCycleEntity, 'PayrollCycleID' | 'Description'>
    ) }
  )> }
);

export type PublishPaySlipMutationVariables = Exact<{
  PublishPaySlipID: Scalars['String'];
}>;


export type PublishPaySlipMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PublishPaySlip'>
);

export type PublishEaListingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  CompanyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PublishEaListingQuery = (
  { __typename?: 'Query' }
  & { PublishEAListing: Array<(
    { __typename?: 'PublishEAEntity' }
    & Pick<PublishEaEntity, 'PublishEAID' | 'CompanyID' | 'PeriodYear' | 'PublishedDate' | 'IsPublish' | 'Remarks' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type PublishEaListingByEmployeeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
}>;


export type PublishEaListingByEmployeeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PublishEAListingByEmployee'>
);

export type PublishEaMutationVariables = Exact<{
  PublishEAID: Scalars['String'];
}>;


export type PublishEaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PublishEA'>
);

export type RequisitionInsertMutationVariables = Exact<{
  RequisitionInput: RequisitionInput;
  DocumentsInput?: Maybe<Array<DocumentsInput> | DocumentsInput>;
}>;


export type RequisitionInsertMutation = (
  { __typename?: 'Mutation' }
  & { RequisitionInsert: (
    { __typename?: 'RequisitionEntity' }
    & Pick<RequisitionEntity, 'RequisitionHeaderID' | 'ApprovalStatus'>
  ) }
);

export type FilterEmployeeRequisitionListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  RequisitionType: Array<Scalars['String']> | Scalars['String'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  ApprovalStatus: Array<Scalars['String']> | Scalars['String'];
  EmployeeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type FilterEmployeeRequisitionListingQuery = (
  { __typename?: 'Query' }
  & { FilterEmployeeRequisitionListing: Array<(
    { __typename?: 'RequisitionEntity' }
    & Pick<RequisitionEntity, 'RequisitionHeaderID' | 'CompanyID' | 'EmployeeID' | 'ApprovedBy' | 'SubmitterName' | 'RequisitionType' | 'TravelRequestID' | 'Subject' | 'Remark' | 'TravelReqNo' | 'CashReqNo' | 'OtherReqNo' | 'AssetReqNo' | 'NumberOfItem' | 'CostPerItem' | 'Amount' | 'StartDate' | 'EndDate' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'PurposeOfTravel' | 'ApprovalStatus' | 'ApprovalDT' | 'ApproverID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'ReportTo' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'Description'>
    )>, MultipleDocuments?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'DocumentType' | 'Description'>
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName' | 'NotifiedDT'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )> }
);

export type EmployeeRequisitionListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type EmployeeRequisitionListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeRequisitionListing: Array<(
    { __typename?: 'RequisitionEntity' }
    & Pick<RequisitionEntity, 'RequisitionHeaderID' | 'CompanyID' | 'EmployeeID' | 'RequisitionType' | 'TravelRequestID' | 'Subject' | 'Amount' | 'Remark' | 'NumberOfItem' | 'CostPerItem' | 'TravelReqNo' | 'CashReqNo' | 'OtherReqNo' | 'AssetReqNo' | 'StartDate' | 'EndDate' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'PurposeOfTravel' | 'ApprovalStatus' | 'ApprovalDT' | 'ApproverID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'DocumentType' | 'Description'>
    )> }
  )> }
);

export type RequisitionDeleteMutationVariables = Exact<{
  RequisitionHeaderID: Scalars['String'];
  DocumentID?: Maybe<Scalars['String']>;
}>;


export type RequisitionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RequisitionDelete'>
);

export type RequisitionSubmitMutationVariables = Exact<{
  RequisitionHeaderID: Scalars['String'];
}>;


export type RequisitionSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RequisitionSubmit'>
);

export type RequisitionUpdateMutationVariables = Exact<{
  RequisitionInput: RequisitionInput;
  DocumentsInput?: Maybe<Array<DocumentsInput> | DocumentsInput>;
  IsRemovedAttachment?: Maybe<Scalars['Boolean']>;
}>;


export type RequisitionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RequisitionUpdate'>
);

export type FilterRequisitionListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  RequisitionType: Array<Scalars['String']> | Scalars['String'];
}>;


export type FilterRequisitionListingQuery = (
  { __typename?: 'Query' }
  & { FilterRequisitionListing: Array<(
    { __typename?: 'RequisitionEntity' }
    & Pick<RequisitionEntity, 'RequisitionType' | 'Remark' | 'Subject' | 'PurposeOfTravel' | 'TravelReqNo' | 'CashReqNo' | 'OtherReqNo' | 'AssetReqNo' | 'NumberOfItem' | 'CostPerItem' | 'Amount' | 'StartDate' | 'EndDate' | 'TravelTo' | 'TravelFrom' | 'ApprovalStatus' | 'DocumentID' | 'EmployeeID' | 'CreatedDT' | 'RequisitionHeaderID' | 'TravelRequestID' | 'TransportMode' | 'ApproverID' | 'ApprovalDT'>
    & { Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'DocumentType' | 'Description'>
    )> }
  )> }
);

export type TravelRequsitionListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type TravelRequsitionListingQuery = (
  { __typename?: 'Query' }
  & { TravelRequsitionListing: Array<(
    { __typename?: 'RequisitionEntity' }
    & Pick<RequisitionEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'RequisitionHeaderID' | 'CompanyID' | 'RequisitionType' | 'TravelRequestID' | 'Subject' | 'Remark' | 'NumberOfItem' | 'CostPerItem' | 'Amount' | 'StartDate' | 'EndDate' | 'TravelReqNo' | 'CashReqNo' | 'OtherReqNo' | 'AssetReqNo' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'PurposeOfTravel' | 'ApprovalStatus' | 'ApprovalDT' | 'DocumentID' | 'ApproverID' | 'EmployeeID'>
  )> }
);

export type GetRequisitionApprovalHistoryQueryVariables = Exact<{
  ApproverID: Scalars['String'];
}>;


export type GetRequisitionApprovalHistoryQuery = (
  { __typename?: 'Query' }
  & { GetRequisitionApprovalHistory?: Maybe<Array<(
    { __typename?: 'RequisitionOutput' }
    & Pick<RequisitionOutput, 'RequisitionHeaderID' | 'RequisitionType' | 'Subject' | 'Remark' | 'NumberOfItem' | 'CostPerItem' | 'Amount' | 'StartDate' | 'EndDate' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'PurposeOfTravel' | 'ApprovalStatus' | 'ApprovalDT' | 'ApprovedBy' | 'RejectedRemarks' | 'EmployeeName' | 'EmployeeNo' | 'DocumentID' | 'DocumentFile' | 'CreatedDT'>
    & { WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName' | 'NotifiedDT'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type DeleteApprovedRequisitionMutationVariables = Exact<{
  input: EmpRequisitionInput;
  IsSupersede?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteApprovedRequisitionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteApprovedRequisition'>
);

export type GetLeaveRecordsTotalCountQueryVariables = Exact<{
  input: LeaveRecordsListingInput;
}>;


export type GetLeaveRecordsTotalCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetLeaveRecordsTotalCount'>
);

export type GetLeaveRecordsListingQueryVariables = Exact<{
  input: LeaveRecordsListingInput;
  effectiveMonth?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  sort?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
}>;


export type GetLeaveRecordsListingQuery = (
  { __typename?: 'Query' }
  & { GetLeaveRecordsListing?: Maybe<Array<(
    { __typename?: 'LeaveRecordsOutput' }
    & Pick<LeaveRecordsOutput, 'EmployeeID' | 'EmployeeNo' | 'EmployeeName' | 'LeaveTypeID' | 'LeaveHeaderID' | 'LeaveTypeName' | 'LeaveFromDate' | 'LeaveToDate' | 'TotalDay' | 'Remarks' | 'IsEmergencyLeave' | 'SubmissionDate' | 'SubmitterName' | 'DiseaseCode' | 'LeaveReason' | 'ApprovalStatus' | 'DocumentID' | 'DocumentName' | 'DocumentFile' | 'ApprovalDate' | 'ApprovedBy' | 'LeaveReasonID' | 'DiseaseCodeID' | 'VoiceDocumentID' | 'VoiceDocumentFile' | 'VoiceDocumentName' | 'ApproverID' | 'ReportTo' | 'CancelledBy' | 'CancelledDate' | 'RejectedRemarks' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'FormStatus' | 'JobGradeID'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID'>
      )> }
    )>, LeaveDetails?: Maybe<Array<(
      { __typename?: 'LeaveDetailsEntity' }
      & Pick<LeaveDetailsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'LeaveDetailsID' | 'LeaveHeaderID' | 'PayPeriodID' | 'SessionType' | 'LeaveDate' | 'NumberOfDay' | 'ManualEntryID' | 'PostedDate' | 'PayrollCycleID'>
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'NotifiedDT' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT' | 'ApproverName'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type ViewLeaveApprovalHistoryQueryVariables = Exact<{
  ApproverID: Scalars['String'];
}>;


export type ViewLeaveApprovalHistoryQuery = (
  { __typename?: 'Query' }
  & { ViewLeaveApprovalHistory?: Maybe<Array<(
    { __typename?: 'LeaveHeaderEntity' }
    & Pick<LeaveHeaderEntity, 'LeaveHeaderID' | 'FromDate' | 'UntilDate' | 'ApprovalStatus' | 'ApprovalDT' | 'TotalDay' | 'CreatedDT' | 'Remarks' | 'WorkFlowCurrentApproversList'>
    & { LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>, Approver?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type MyLeaveListingQueryVariables = Exact<{
  MyLeaveInput: EmpLeaveListingInput;
}>;


export type MyLeaveListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeLeaveListing?: Maybe<Array<(
    { __typename?: 'LeaveHeaderEntity' }
    & Pick<LeaveHeaderEntity, 'LeaveHeaderID' | 'FromDate' | 'UntilDate' | 'TotalDay' | 'Remarks' | 'ApprovalStatus' | 'ModifiedDT' | 'ApprovalDT'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName' | 'SubscriptionAccountID'>
    )>, LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'Name'>
    )>, LeaveDetails?: Maybe<Array<(
      { __typename?: 'LeaveDetailsEntity' }
      & Pick<LeaveDetailsEntity, 'SessionType' | 'ManualEntryID'>
    )>>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName'>
      )> }
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'Description'>
    )>, Approver?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )>> }
);

export type GetEmployeesLeaveSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
  IsResigned?: Maybe<Scalars['Boolean']>;
}>;


export type GetEmployeesLeaveSummaryQuery = (
  { __typename?: 'Query' }
  & { GetEmployeesLeaveSummary?: Maybe<Array<(
    { __typename?: 'EmployeeLeavesSummary' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'Status' | 'ResignedDate'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'Gender' | 'Marital'>
      )> }
    )>, EmployeeLeavePolicies?: Maybe<Array<(
      { __typename?: 'EmployeeLeavePolicy' }
      & { LeavePolicy?: Maybe<(
        { __typename?: 'LeaveItemEntity' }
        & Pick<LeaveItemEntity, 'IsAdvanceLeave' | 'TotalAdvanceDay' | 'AdvanceStartMonth' | 'AdvanceEndMonth' | 'Title' | 'LeaveTypeID' | 'LeaveFrequency'>
        & { LeaveType?: Maybe<(
          { __typename?: 'LeaveTypeEntity' }
          & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'LeaveTypeCode' | 'Name'>
        )> }
      )>, LeaveBalanceSummary?: Maybe<(
        { __typename?: 'LeaveBalanceSummary' }
        & Pick<LeaveBalanceSummary, 'Entitled' | 'BroughtForward' | 'Adjustment' | 'Earned' | 'Taken' | 'TakenYTD' | 'Forfeited' | 'Balance' | 'BalanceYTD' | 'Occurrence'>
      )> }
    )>> }
  )>> }
);

export type EnhanceGetEmployeesLeaveSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
  IsResigned?: Maybe<Scalars['Boolean']>;
}>;


export type EnhanceGetEmployeesLeaveSummaryQuery = (
  { __typename?: 'Query' }
  & { EnhanceGetEmployeesLeaveSummary?: Maybe<Array<(
    { __typename?: 'EmployeeLeavesSummary' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'Status' | 'ResignedDate'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'Gender' | 'Marital'>
      )> }
    )>, EmployeeLeavePolicies?: Maybe<Array<(
      { __typename?: 'EmployeeLeavePolicy' }
      & { LeavePolicy?: Maybe<(
        { __typename?: 'LeaveItemEntity' }
        & Pick<LeaveItemEntity, 'IsAdvanceLeave' | 'TotalAdvanceDay' | 'AdvanceStartMonth' | 'AdvanceEndMonth' | 'Title' | 'LeaveTypeID' | 'LeaveFrequency'>
        & { LeaveType?: Maybe<(
          { __typename?: 'LeaveTypeEntity' }
          & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'LeaveTypeCode' | 'Name'>
        )> }
      )>, LeaveBalanceSummary?: Maybe<(
        { __typename?: 'LeaveBalanceSummary' }
        & Pick<LeaveBalanceSummary, 'Entitled' | 'BroughtForward' | 'Adjustment' | 'Earned' | 'Taken' | 'TakenYTD' | 'Forfeited' | 'Balance' | 'BalanceYTD' | 'Occurrence'>
      )> }
    )>> }
  )>> }
);

export type GetCompanyCyclePeriodListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyCyclePeriodListQuery = (
  { __typename?: 'Query' }
  & { GetCompanyCyclePeriodList?: Maybe<Array<(
    { __typename?: 'CompanyCyclePeriodListOutput' }
    & Pick<CompanyCyclePeriodListOutput, 'CompanyID' | 'CompanyName' | 'PayPeriodID' | 'PayrollCycleID' | 'PayrollStatus' | 'IsLastProcess' | 'PeriodYearMonth' | 'MMMYYYY' | 'PayCycleName'>
  )>> }
);

export type GetEmployeeListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmployeeListingQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeListing?: Maybe<Array<(
    { __typename?: 'EmployeeListingOutput' }
    & Pick<EmployeeListingOutput, 'EmployeeID' | 'CompanyID' | 'EmployeeNo' | 'EmployeeStatus' | 'FullName' | 'EmployeeNoName'>
  )>> }
);

export type GetMyLeaveEntitlementQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  AsToDate: Scalars['DateTime'];
}>;


export type GetMyLeaveEntitlementQuery = (
  { __typename?: 'Query' }
  & { GetMyLeaveEntitlement?: Maybe<(
    { __typename?: 'EmpLeaveEntitlementOuput' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, EmpLeaveItems?: Maybe<Array<(
      { __typename?: 'EmpLeaveItemEntitlement' }
      & Pick<EmpLeaveItemEntitlement, 'EntitlementDayByLOS'>
      & { LeaveItem?: Maybe<(
        { __typename?: 'LeaveItemEntity' }
        & { LeaveType?: Maybe<(
          { __typename?: 'LeaveTypeEntity' }
          & Pick<LeaveTypeEntity, 'Name'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetMyLeaveBalanceQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  AsToDate: Scalars['DateTime'];
}>;


export type GetMyLeaveBalanceQuery = (
  { __typename?: 'Query' }
  & { GetMyLeaveBalance?: Maybe<(
    { __typename?: 'EmpLeaveBalanceOuput' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, LeaveTypeBalance?: Maybe<Array<(
      { __typename?: 'LeaveTypeBalance' }
      & Pick<LeaveTypeBalance, 'EntitlementDayByLOS' | 'Entitlement' | 'BroughtForward' | 'Taken' | 'Balance' | 'ToDateEarned' | 'ErrorMessage'>
      & { LeaveItem?: Maybe<(
        { __typename?: 'LeaveItemEntity' }
        & { LeaveType?: Maybe<(
          { __typename?: 'LeaveTypeEntity' }
          & Pick<LeaveTypeEntity, 'Name'>
        )> }
      )> }
    )>> }
  )> }
);

export type DeleteLeaveApplicationMutationVariables = Exact<{
  EmpLeaveDetailInput: EmpLeaveDetailInput;
}>;


export type DeleteLeaveApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteLeaveApplication'>
);

export type SubmitLeaveApplicationMutationVariables = Exact<{
  EmpLeaveDetailInput: EmpLeaveDetailInput;
}>;


export type SubmitLeaveApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitLeaveApplication'>
);

export type SubmitLeaveApplicationV2MutationVariables = Exact<{
  EmpLeaveDetailInput: EmpLeaveDetailInput;
}>;


export type SubmitLeaveApplicationV2Mutation = (
  { __typename?: 'Mutation' }
  & { SubmitLeaveApplicationV2: (
    { __typename?: 'LeaveHeaderEntity' }
    & Pick<LeaveHeaderEntity, 'LeaveHeaderID' | 'ApprovalStatus' | 'TotalDay' | 'ApproverID'>
  ) }
);

export type LeaveTypeListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type LeaveTypeListingQuery = (
  { __typename?: 'Query' }
  & { LeaveTypeListing: Array<(
    { __typename?: 'LeaveTypeEntity' }
    & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'Name'>
    & { LeavePolicies?: Maybe<Array<(
      { __typename?: 'LeaveItemEntity' }
      & Pick<LeaveItemEntity, 'LeaveTypeID' | 'LeaveItemID'>
      & { LeaveBenefit?: Maybe<(
        { __typename?: 'LeaveBenefitEntity' }
        & Pick<LeaveBenefitEntity, 'Name' | 'Description' | 'Status'>
      )> }
    )>> }
  )> }
);

export type GetReplacementLeaveByEmployeeQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  AsToDate?: Maybe<Scalars['String']>;
}>;


export type GetReplacementLeaveByEmployeeQuery = (
  { __typename?: 'Query' }
  & { GetLeaveReplacementListingByEmployee?: Maybe<Array<(
    { __typename?: 'LeaveAdjustmentOutput' }
    & Pick<LeaveAdjustmentOutput, 'TotalDay' | 'EffectiveDate' | 'ExpiryDate' | 'LeaveHeaderID' | 'Taken' | 'Balance' | 'Remarks'>
  )>> }
);

export type LeaveRecordsInsertMutationVariables = Exact<{
  leaveHeaderInput: LeaveHeaderInput;
  leaveDetailsInput: Array<LeaveDetailsInput> | LeaveDetailsInput;
  LeavePolicyID?: Maybe<Scalars['String']>;
}>;


export type LeaveRecordsInsertMutation = (
  { __typename?: 'Mutation' }
  & { LeaveRecordsInsert: (
    { __typename?: 'LeaveHeaderEntity' }
    & Pick<LeaveHeaderEntity, 'LeaveHeaderID' | 'ApprovalStatus' | 'TotalDay'>
  ) }
);

export type LeaveRecordsUpdateMutationVariables = Exact<{
  leaveHeaderID: Scalars['String'];
  leaveHeaderInput: LeaveHeaderInput;
  leaveDetailsInput: Array<LeaveDetailsInput> | LeaveDetailsInput;
  isRemovedAttachment?: Maybe<Scalars['Boolean']>;
  isRemovedVoice?: Maybe<Scalars['Boolean']>;
  LeavePolicyID?: Maybe<Scalars['String']>;
}>;


export type LeaveRecordsUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveRecordsUpdate'>
);

export type PostLeaveApplicationMutationVariables = Exact<{
  EmpLeaveApplicationInput: EmpLeaveApplicationInput;
}>;


export type PostLeaveApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PostLeaveApplication'>
);

export type EmployeeSummaryQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type EmployeeSummaryQuery = (
  { __typename?: 'Query' }
  & { EmployeeSummary: (
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'RetirementAge' | 'EmployeeID' | 'CalendarPolicyID' | 'CalendarTitle' | 'IsOKU' | 'IsHOD' | 'IsSuperUser' | 'IsInterCompanyTransfer' | 'EmployeeNo' | 'EmploymentType' | 'Status' | 'EmployeeStatus' | 'FormStatus' | 'Probation' | 'CalendarStateID' | 'JoinedReferenceDate' | 'StaffCategory' | 'StaffCategoryID' | 'OrgUnitID' | 'ActualConfirmedDate' | 'EAFormDocCount' | 'PaySlipDocCount' | 'PersonalDocCount' | 'GeneralDocCount' | 'ContractInfoDocCount' | 'GeneralDocTotalSize' | 'EAFormDocTotalSize' | 'PaySlipDocTotalSize' | 'PersonalDocTotalSize' | 'SubscriptionAccountID' | 'ReportTo' | 'JoinedDate' | 'ConfirmedDate' | 'ResignedDate' | 'TenderResignDate' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'TotalEducation' | 'TotalJobHistory' | 'TotalPersonalDocument' | 'TotalCertificate' | 'RoleID' | 'RoleName'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'NickName' | 'Email' | 'Nationality' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'PassportNoLHDN' | 'BirthDate' | 'Gender' | 'Marital' | 'Salutation' | 'Race' | 'Religion' | 'SameAddress' | 'ProfilePicture'>
      & { Address?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
      )>, PermanentAddress?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
      )> }
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CalendarTitle' | 'CompanyName' | 'CalendarStateID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID'>
        & { Address?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
        )> }
      )> }
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
    )>, EmployeeDepartment?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
    )>, Division?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
    )>, RecruitmentSource?: Maybe<(
      { __typename?: 'RecruitmentSourceEntity' }
      & Pick<RecruitmentSourceEntity, 'RecruitmentSourceID' | 'Name'>
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description' | 'ParentID'>
    )>, Supervisor?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, EmployeeCareerLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'SalaryType' | 'FromSalary' | 'ToSalary' | 'TenderResignDate' | 'MovementType' | 'ExitReasonID' | 'Status' | 'LastEmploymentDate' | 'LastWorkingDate' | 'ModifiedDT'>
      & { ExitCompanyReason?: Maybe<(
        { __typename?: 'PersonnelParameterEntity' }
        & Pick<PersonnelParameterEntity, 'PersonnelParameterID' | 'Name'>
      )> }
    )>>, EmployeeSalaryLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'FromSalary' | 'ToSalary' | 'SalaryAdjustmentAmount' | 'SalaryType' | 'Reason' | 'Remark'>
    )>>, EmployeeBankInfo?: Maybe<(
      { __typename?: 'EmployeeBankInfoEntity' }
      & Pick<EmployeeBankInfoEntity, 'EmployeeBankInfoID' | 'Bank' | 'AcctNo' | 'PaymentMethod' | 'CompanyBankAccount' | 'PayeeName' | 'PayeeID'>
    )>, EmployeeStatutory?: Maybe<(
      { __typename?: 'EmployeeStatutoryEntity' }
      & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID' | 'IsEPF' | 'EPFNo' | 'EPFVERate' | 'EPFVERateCmp' | 'IsSocso' | 'SocsoNo' | 'IsPCB' | 'PCBNo' | 'CountryCode' | 'IsEIS' | 'EISNo' | 'IsAngkasa' | 'AngkasaNo' | 'IsASB' | 'ASBNo' | 'IsKWAP' | 'KWAPNo' | 'IsTBH' | 'TBHNo' | 'IsZakat' | 'ZakatNo' | 'ZakatAmount' | 'ZakatRate' | 'ResidentStatus' | 'TaxMaritialStatus' | 'ZakatState' | 'IsHRDF' | 'ModifiedDT' | 'ModifiedBy' | 'CreatedBy' | 'CreatedDT' | 'CompanyEPFAccount' | 'CompanySocsoAccount' | 'CompanyPCBAccount' | 'CompanyEISAccount'>
      & { UserLogs?: Maybe<Array<(
        { __typename?: 'UserLogs' }
        & Pick<UserLogs, 'columnName' | 'newColumnValue' | 'oldColumnValue' | 'createdDT' | 'createdBy' | 'modifiedDT' | 'modifiedBy'>
      )>> }
    )>, EmployeePrevContribution?: Maybe<(
      { __typename?: 'EmployeePrevContributionEntity' }
      & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID' | 'CompanyID' | 'EmployeeID' | 'PeriodYear' | 'GrossTaxable' | 'EPFPaid' | 'PCBPaid' | 'ZAKATPaid' | 'ModifiedDT' | 'CreatedDT'>
    )>, EmployeeDependents?: Maybe<Array<(
      { __typename?: 'EmployeeDependentsEntity' }
      & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID' | 'Relationship' | 'Education' | 'ChildEligibleRate' | 'Occupation' | 'IsWorking' | 'IsOKU' | 'IsEmergencyContact' | 'PCBNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'BirthDate' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'Nationality'>
      )> }
    )>>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>, EmployeeAppraisals?: Maybe<Array<(
      { __typename?: 'EmployeeAppraisalEntity' }
      & Pick<EmployeeAppraisalEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeAppraisalID' | 'EmployeeID' | 'AppraisalRecord' | 'Date' | 'Rating' | 'Score' | 'AppraiseeRemark' | 'AppraiserRemark' | 'AppraiserName' | 'DocumentFile' | 'BucketFileName'>
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )>>, EmployeeDiaries?: Maybe<Array<(
      { __typename?: 'EmployeeDiaryEntity' }
      & Pick<EmployeeDiaryEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeDiaryID' | 'EmployeeID' | 'Date' | 'CaseNo' | 'Description' | 'Countermeasure' | 'Private' | 'DocumentID'>
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'DocumentType' | 'Description' | 'BucketFileName'>
      )> }
    )>>, EmployeeTrainings?: Maybe<Array<(
      { __typename?: 'EmployeeTrainingEntity' }
      & Pick<EmployeeTrainingEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeTrainingID' | 'EmployeeID' | 'CourseName' | 'StartDate' | 'EndDate' | 'TrainingHour' | 'TrainingProvider' | 'Trainer' | 'TrainerRating' | 'TrainerScore' | 'Superior' | 'SuperiorRating' | 'SuperiorScore'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )>>, EmployeeInsuranceSchemes?: Maybe<Array<(
      { __typename?: 'EmployeeInsuranceSchemeEntity' }
      & Pick<EmployeeInsuranceSchemeEntity, 'InsuranceSchemeID' | 'EmployeeInsuranceSchemeID' | 'StartDate' | 'EndDate' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
      & { InsuranceScheme?: Maybe<(
        { __typename?: 'InsuranceSchemeEntity' }
        & Pick<InsuranceSchemeEntity, 'Name' | 'Status'>
      )> }
    )>>, EmployeeSubordinates: Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName'>
      )> }
    )>, EmployeeAssets?: Maybe<Array<(
      { __typename?: 'EmployeeAssetEntity' }
      & Pick<EmployeeAssetEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeAssetID' | 'EmployeeID' | 'DateAssign' | 'Item' | 'Remark'>
    )>>, LastUpdatedDates: (
      { __typename?: 'LastUpdatedDatesOutput' }
      & Pick<LastUpdatedDatesOutput, 'EmployeeLastDate' | 'AssetsLastDate' | 'BIKLastDate' | 'CareerLastDate' | 'SubordinateLastDate' | 'TaxLastDate' | 'HealthInfoLastDate' | 'UserDefineFieldsLastDate'>
    ) }
  ) }
);

export type FilterEmployeeLeaveRecordsQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  LeaveTypeIDs: Array<Scalars['String']> | Scalars['String'];
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
}>;


export type FilterEmployeeLeaveRecordsQuery = (
  { __typename?: 'Query' }
  & { FilterEmployeeLeaveRecords: Array<(
    { __typename?: 'LeaveHeaderEntity' }
    & Pick<LeaveHeaderEntity, 'LeaveHeaderID' | 'LeaveTypeID' | 'FromDate' | 'UntilDate' | 'TotalDay' | 'CreatedDT' | 'Remarks' | 'ApprovalStatus' | 'ApprovalDT' | 'ApproverID' | 'DocumentID'>
    & { LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'Status' | 'ContactID'>
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )> }
  )> }
);

export type LeaveStatusUpdateMutationVariables = Exact<{
  LeaveHeaderIDs: Array<Scalars['String']> | Scalars['String'];
  ApprovalStatus: ApprovalStatus;
}>;


export type LeaveStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveStatusUpdate'>
);

export type GetLeaveOffDayByAllStateWithCompanyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetLeaveOffDayByAllStateWithCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetLeaveOffDayByAllStateWithCompany'>
);

export type GetLeavePolicyByJobGradeIdQueryVariables = Exact<{
  JobGradeID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetLeavePolicyByJobGradeIdQuery = (
  { __typename?: 'Query' }
  & { GetLeavePolicyByJobGradeID?: Maybe<Array<(
    { __typename?: 'LeaveItemEntity' }
    & Pick<LeaveItemEntity, 'Title' | 'LeaveTypeID' | 'IsOffDay' | 'IsAdvanceLeave' | 'TotalAdvanceDay' | 'AdvanceStartMonth' | 'AdvanceEndMonth' | 'RemainingBalance' | 'IsRestDay' | 'IsHalfDay' | 'IsRemarks' | 'IsAttachment' | 'IsConfirmedStaff' | 'IsPublicHoliday' | 'IsBackdated' | 'BackdatedDays'>
    & { LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'Name' | 'LeaveTypeCode'>
    )> }
  )>> }
);

export type ApproverInfoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type ApproverInfoQuery = (
  { __typename?: 'Query' }
  & { EmployeeSummary: (
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'Nationality' | 'MobileNo'>
    )> }
  ) }
);

export type DeleteApprovedLeaveApplicationMutationVariables = Exact<{
  input: EmpLeaveDetailInput;
  Remark: Scalars['String'];
  IsSupersede?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteApprovedLeaveApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteApprovedLeaveApplication'>
);

export type LeaveReasonListQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type LeaveReasonListQuery = (
  { __typename?: 'Query' }
  & { LeaveReasonListing: Array<(
    { __typename?: 'LeaveReasonEntity' }
    & Pick<LeaveReasonEntity, 'LeaveReasonID' | 'SubscriptionAccountID' | 'Name' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type LeaveDiseaseListQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type LeaveDiseaseListQuery = (
  { __typename?: 'Query' }
  & { LeaveDiseaseListing: Array<(
    { __typename?: 'LeaveDiseaseEntity' }
    & Pick<LeaveDiseaseEntity, 'LeaveDiseaseID' | 'SubscriptionAccountID' | 'Name' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type LeaveCompanySettingSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type LeaveCompanySettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'LeaveCompanySettingSummary'>
);

export type BlockLeaveEmpListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  FromDate: Scalars['DateTime'];
  UntilDate: Scalars['DateTime'];
}>;


export type BlockLeaveEmpListingQuery = (
  { __typename?: 'Query' }
  & { BlockLeaveEmpListing?: Maybe<Array<(
    { __typename?: 'BlockLeaveEntity' }
    & Pick<BlockLeaveEntity, 'BlockLeaveID' | 'SubscriptionAccountID' | 'CompanyID' | 'Name' | 'StartDate' | 'EndDate' | 'LeaveTypeID' | 'OrgUnitID' | 'Status'>
    & { LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'ContactID' | 'OrgUnitID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )>> }
);

export type GetEmployeeCalendarQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetEmployeeCalendarQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEmployeeCalendar'>
);

export type GetTakenLeaveByEmployeeIdQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  LeaveHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetTakenLeaveByEmployeeIdQuery = (
  { __typename?: 'Query' }
  & { GetTakenLeaveByEmployeeID?: Maybe<Array<(
    { __typename?: 'LeaveDetailsEntity' }
    & Pick<LeaveDetailsEntity, 'SessionType' | 'LeaveDate' | 'NumberOfDay'>
  )>> }
);

export type GetEmployeeEntitlementByYearQueryVariables = Exact<{
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type GetEmployeeEntitlementByYearQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetEmployeeEntitlementByYear'>
);

export type GetLeaveByMonthCalendarQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
}>;


export type GetLeaveByMonthCalendarQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetLeaveByMonthCalendar'>
);

export type GetLeaveByMonthCalendarToDoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
}>;


export type GetLeaveByMonthCalendarToDoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetLeaveByMonthCalendarToDo'>
);

export type LeaveTypeListQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type LeaveTypeListQuery = (
  { __typename?: 'Query' }
  & { LeaveTypeListing: Array<(
    { __typename?: 'LeaveTypeEntity' }
    & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'LeaveTypeCode' | 'SubscriptionAccountID' | 'Name' | 'PayItemID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayItemID' | 'PayName' | 'PayCode' | 'PayItemType' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>, LeavePolicies?: Maybe<Array<(
      { __typename?: 'LeaveItemEntity' }
      & Pick<LeaveItemEntity, 'LeaveItemID' | 'Title' | 'LeaveBenefitID'>
      & { LeaveBenefit?: Maybe<(
        { __typename?: 'LeaveBenefitEntity' }
        & Pick<LeaveBenefitEntity, 'Status' | 'LeaveBenefitID' | 'Name' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetLeavePolicyListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  LeaveTypeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EntitlementMethodIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  AvailableBasisIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ShortNoticeOptioIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetLeavePolicyListingQuery = (
  { __typename?: 'Query' }
  & { LeavePolicyListing?: Maybe<Array<(
    { __typename?: 'LeaveItemEntity' }
    & Pick<LeaveItemEntity, 'Title' | 'ModifiedDT' | 'PlatformLeaveItemID' | 'LeaveItemID' | 'LeaveFrequency' | 'AvailableBasis' | 'ShortNoticeType' | 'IsBackdated' | 'BackdatedDays' | 'IsAdvanceLeave' | 'TotalAdvanceDay' | 'AdvanceStartMonth' | 'AdvanceEndMonth' | 'EncashmentPayItem' | 'RemainingBalance' | 'CFMethod' | 'InUse'>
    & { LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'Name' | 'LeaveTypeCode' | 'LeaveTypeID'>
    )>, LeaveTemplates?: Maybe<Array<(
      { __typename?: 'LeaveTemplateEntity' }
      & Pick<LeaveTemplateEntity, 'LeaveTemplateID'>
    )>> }
  )>> }
);

export type LeaveRoundingListingQueryVariables = Exact<{
  LeaveRoundingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
}>;


export type LeaveRoundingListingQuery = (
  { __typename?: 'Query' }
  & { LeaveRoundingListing: Array<(
    { __typename?: 'LeaveRoundingEntity' }
    & Pick<LeaveRoundingEntity, 'LeaveRoundingID' | 'CompanyID' | 'Title' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { LeaveRoundingDetails?: Maybe<Array<(
      { __typename?: 'LeaveRoundingDetailEntity' }
      & Pick<LeaveRoundingDetailEntity, 'StartValue' | 'EndValue' | 'RoundTo'>
    )>>, Companies?: Maybe<Array<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName' | 'LeaveRoundingID'>
    )>> }
  )> }
);

export type LeaveReasonInsertMutationVariables = Exact<{
  input: LeaveReasonInput;
}>;


export type LeaveReasonInsertMutation = (
  { __typename?: 'Mutation' }
  & { LeaveReasonInsert: (
    { __typename?: 'LeaveReasonEntity' }
    & Pick<LeaveReasonEntity, 'LeaveReasonID'>
  ) }
);

export type LeaveReasonUpdateMutationVariables = Exact<{
  LeaveReasonID: Scalars['String'];
  input: LeaveReasonInput;
}>;


export type LeaveReasonUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveReasonUpdate'>
);

export type LeaveReasonDeleteMutationVariables = Exact<{
  LeaveReasonID: Scalars['String'];
}>;


export type LeaveReasonDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveReasonDelete'>
);

export type LeaveDiseaseInsertMutationVariables = Exact<{
  input: LeaveDiseaseInput;
}>;


export type LeaveDiseaseInsertMutation = (
  { __typename?: 'Mutation' }
  & { LeaveDiseaseInsert: (
    { __typename?: 'LeaveDiseaseEntity' }
    & Pick<LeaveDiseaseEntity, 'LeaveDiseaseID'>
  ) }
);

export type LeaveDiseaseUpdateMutationVariables = Exact<{
  LeaveDiseaseID: Scalars['String'];
  input: LeaveDiseaseInput;
}>;


export type LeaveDiseaseUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveDiseaseUpdate'>
);

export type LeaveDiseaseDeleteMutationVariables = Exact<{
  LeaveDiseaseID: Scalars['String'];
}>;


export type LeaveDiseaseDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveDiseaseDelete'>
);

export type LeaveTypePayItemListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type LeaveTypePayItemListingQuery = (
  { __typename?: 'Query' }
  & { LeaveTypePayItemListing: Array<(
    { __typename?: 'PayItemEntity' }
    & Pick<PayItemEntity, 'PayItemID' | 'CompanyID' | 'PayCode' | 'PayName' | 'PayItemType'>
  )> }
);

export type LeaveTypeInsertMutationVariables = Exact<{
  input: LeaveTypeInput;
}>;


export type LeaveTypeInsertMutation = (
  { __typename?: 'Mutation' }
  & { LeaveTypeInsert: (
    { __typename?: 'LeaveTypeEntity' }
    & Pick<LeaveTypeEntity, 'LeaveTypeID'>
  ) }
);

export type LeaveTypeUpdateMutationVariables = Exact<{
  LeaveTypeID: Scalars['String'];
  input: LeaveTypeInput;
}>;


export type LeaveTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveTypeUpdate'>
);

export type LeaveTypeDeleteMutationVariables = Exact<{
  LeaveTypeID: Scalars['String'];
}>;


export type LeaveTypeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveTypeDelete'>
);

export type LeavePolicyDeleteMutationVariables = Exact<{
  LeavePolicyID: Scalars['String'];
}>;


export type LeavePolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeavePolicyDelete'>
);

export type GetLeavePolicyByIdQueryVariables = Exact<{
  LeavePolicyID: Scalars['String'];
}>;


export type GetLeavePolicyByIdQuery = (
  { __typename?: 'Query' }
  & { LeavePolicyByID?: Maybe<(
    { __typename?: 'LeaveItemEntity' }
    & Pick<LeaveItemEntity, 'Title' | 'LeaveTypeID' | 'LeaveFrequency' | 'AvailableBasis' | 'EntitlementBasedOn' | 'CalculationMethod' | 'NewCutOffDay' | 'ResignCutOffDay' | 'MonthlyCutOffDay' | 'CFMethod' | 'CFValue' | 'CFMonth' | 'ShortNoticePeriod' | 'IsBackdated' | 'BackdatedDays' | 'EncashmentPayItem' | 'RemainingBalance' | 'ShortNoticeType' | 'IsPublicHoliday' | 'IsOffDay' | 'IsRestDay' | 'IsHalfDay' | 'IsConfirmedStaff' | 'IsAttachment' | 'IsRemarks' | 'IsRounding' | 'IsAdvanceLeave' | 'TotalAdvanceDay' | 'AdvanceStartMonth' | 'AdvanceEndMonth' | 'IsDisplayEmployee' | 'IsLeaveReason' | 'IsLeaveDisease' | 'IncludeSickLeave' | 'ModifiedDT' | 'Occurrence' | 'NoOccurrence' | 'OccurrenceEntitlementDay' | 'LeaveItemID' | 'EntDistributionMethod' | 'PlatformLeaveItemID' | 'EntitlementComputation' | 'MaxApplyLeave' | 'EntitlementAvailability'>
    & { LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'Name' | 'LeaveTypeCode'>
    )>, LOS?: Maybe<Array<(
      { __typename?: 'LeaveBenefitRuleEntity' }
      & Pick<LeaveBenefitRuleEntity, 'FormulaText' | 'LeaveBenefitRuleID' | 'TotalDay' | 'Status'>
    )>> }
  )> }
);

export type LeavePolicyInsertMutationVariables = Exact<{
  LeavePolicyInput: LeavePolicyInput;
  LOSInput?: Maybe<Array<LosInput> | LosInput>;
}>;


export type LeavePolicyInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeavePolicyInsert'>
);

export type LeavePolicyUpdateMutationVariables = Exact<{
  LeavePolicyInput: LeavePolicyInput;
  LOSInput?: Maybe<Array<LosInput> | LosInput>;
}>;


export type LeavePolicyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeavePolicyUpdate'>
);

export type PayItemByPayTypeQueryVariables = Exact<{
  PayType: Array<Scalars['String']> | Scalars['String'];
  PayItemID?: Maybe<Scalars['String']>;
}>;


export type PayItemByPayTypeQuery = (
  { __typename?: 'Query' }
  & { PayItemByPayType: Array<(
    { __typename?: 'PayItemEntity' }
    & Pick<PayItemEntity, 'PayItemID' | 'PayName' | 'PayItemType'>
  )> }
);

export type LeaveRoundingDeleteMutationVariables = Exact<{
  LeaveRoundingID: Scalars['String'];
}>;


export type LeaveRoundingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveRoundingDelete'>
);

export type LeaveRoundingInsertMutationVariables = Exact<{
  LeaveRoundingInput: LeaveRoundingInput;
  LeaveRoundingDetailInput: Array<LeaveRoundingDetailInput> | LeaveRoundingDetailInput;
}>;


export type LeaveRoundingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveRoundingInsert'>
);

export type UpdateLeaveRoundingMutationVariables = Exact<{
  LeaveRoundingID: Scalars['String'];
  LeaveRoundingInput: LeaveRoundingInput;
  LeaveRoundingDetailInput: Array<LeaveRoundingDetailInput> | LeaveRoundingDetailInput;
}>;


export type UpdateLeaveRoundingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateLeaveRounding'>
);

export type JobGradeListingByCompanyQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type JobGradeListingByCompanyQuery = (
  { __typename?: 'Query' }
  & { JobGradeListingByCompany: Array<(
    { __typename?: 'JobGradeEntity' }
    & Pick<JobGradeEntity, 'JobGradeID' | 'SubscriptionAccountID' | 'Description' | 'LeaveBenefitID' | 'ClaimBenefitID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { JobDesignationGrades?: Maybe<Array<(
      { __typename?: 'JobDesignationGradeEntity' }
      & Pick<JobDesignationGradeEntity, 'JobDesignationGradeID' | 'JobDesignationID' | 'JobGradeID'>
    )>> }
  )> }
);

export type LeaveCompulsoryPolicyListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type LeaveCompulsoryPolicyListingQuery = (
  { __typename?: 'Query' }
  & { LeaveCompulsoryPolicyListing: Array<(
    { __typename?: 'LeaveCompulsoryPolicyEntity' }
    & Pick<LeaveCompulsoryPolicyEntity, 'LeaveCompulsoryPolicyID' | 'CompanyID' | 'LeaveTypeID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type ApprovalPolicyAssignmentListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
  Category: Scalars['String'];
}>;


export type ApprovalPolicyAssignmentListQuery = (
  { __typename?: 'Query' }
  & { ApprovalPolicyAssignmentList?: Maybe<(
    { __typename?: 'ApprovalPolicyAssignmentEntity' }
    & Pick<ApprovalPolicyAssignmentEntity, 'Status' | 'ID' | 'ApprovalPolicyID' | 'ModifiedDT'>
    & { ApprovalPolicy?: Maybe<(
      { __typename?: 'ApprovalPolicyEntity' }
      & Pick<ApprovalPolicyEntity, 'SubscriptionAccountID' | 'Title'>
    )> }
  )> }
);

export type LeaveJobGradePolicyInsertMutationVariables = Exact<{
  JobGradePolicyInput: JobGradePolicyInput;
  LeaveTemplateInput: Array<LeaveTemplateInput> | LeaveTemplateInput;
}>;


export type LeaveJobGradePolicyInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveJobGradePolicyInsert'>
);

export type LeaveJobGradePolicyUpdateMutationVariables = Exact<{
  JobGradePolicyID: Scalars['String'];
  JobGradePolicyInput: JobGradePolicyInput;
  LeaveTemplateInput: Array<LeaveTemplateInput> | LeaveTemplateInput;
}>;


export type LeaveJobGradePolicyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveJobGradePolicyUpdate'>
);

export type LeaveTypeConditionUpdateMutationVariables = Exact<{
  LeaveTemplateInput: LeaveTemplateInput;
}>;


export type LeaveTypeConditionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveTypeConditionUpdate'>
);

export type LeaveTypeConditionDeleteMutationVariables = Exact<{
  LeaveTemplateID: Scalars['String'];
}>;


export type LeaveTypeConditionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveTypeConditionDelete'>
);

export type LeaveTypeConditionListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type LeaveTypeConditionListQuery = (
  { __typename?: 'Query' }
  & { LeaveTypeConditionList: Array<(
    { __typename?: 'LeaveTemplateEntity' }
    & Pick<LeaveTemplateEntity, 'CompanyID' | 'LeaveTemplateID' | 'ConditionList' | 'LeavePolicyIDList' | 'LeaveTypeID' | 'ModifiedDT' | 'CreatedDT'>
  )> }
);

export type LeaveTemplateListingQueryVariables = Exact<{
  JobGradePolicyID: Scalars['String'];
  JobGradeID?: Maybe<Scalars['String']>;
}>;


export type LeaveTemplateListingQuery = (
  { __typename?: 'Query' }
  & { LeaveTemplateListing: Array<(
    { __typename?: 'LeaveTemplateEntity' }
    & Pick<LeaveTemplateEntity, 'LeaveTemplateID' | 'JobGradePolicyID' | 'LeavePolicyID'>
    & { LeaveItem?: Maybe<(
      { __typename?: 'LeaveItemEntity' }
      & Pick<LeaveItemEntity, 'LeaveItemID' | 'LeaveTypeID' | 'Title'>
      & { LeaveType?: Maybe<(
        { __typename?: 'LeaveTypeEntity' }
        & Pick<LeaveTypeEntity, 'Name' | 'LeaveTypeCode'>
      )> }
    )> }
  )> }
);

export type CompanyLeaveSettingUpdateMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  CompanyInput: CompanyInput;
}>;


export type CompanyLeaveSettingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyLeaveSettingUpdate'>
);

export type LeaveCompulsoryPolicyBulkInsertMutationVariables = Exact<{
  LeaveCompulsoryPolicyInput: Array<LeaveCompulsoryPolicyInput> | LeaveCompulsoryPolicyInput;
}>;


export type LeaveCompulsoryPolicyBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveCompulsoryPolicyBulkInsert'>
);

export type LeaveCompulsoryPolicyUpdateMutationVariables = Exact<{
  LeaveCompulsoryPolicyInput: Array<LeaveCompulsoryPolicyInput> | LeaveCompulsoryPolicyInput;
}>;


export type LeaveCompulsoryPolicyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveCompulsoryPolicyUpdate'>
);

export type GetCompulsoryLeaveListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
}>;


export type GetCompulsoryLeaveListingQuery = (
  { __typename?: 'Query' }
  & { GetCompulsoryLeaveListing?: Maybe<Array<(
    { __typename?: 'CompulsoryLeaveOutput' }
    & Pick<CompulsoryLeaveOutput, 'LeaveCompulsoryID' | 'Title' | 'LeaveFromDate' | 'LeaveToDate' | 'SessionType' | 'TotalDay' | 'PostedByName' | 'PostedDate' | 'Remarks' | 'DocumentID' | 'DocumentName' | 'CountEmployees'>
    & { Employees?: Maybe<Array<(
      { __typename?: 'EmployeeIndentity' }
      & Pick<EmployeeIndentity, 'EmployeeID' | 'EmployeeNo' | 'EmployeeName'>
    )>> }
  )>> }
);

export type LeaveMenuSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type LeaveMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'LeaveMenuSummary'>
);

export type LeaveHomeSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  LeaveAdjustmentListingInput: LeaveAdjustmentListingInput;
  input: LeaveRecordsListingInput;
}>;


export type LeaveHomeSummaryQuery = (
  { __typename?: 'Query' }
  & { LeaveHomeSummary: (
    { __typename?: 'LeaveHomeSummaryOutput' }
    & Pick<LeaveHomeSummaryOutput, 'OutOfStateActiveRecords' | 'OutOfStateIsLastUpdated' | 'NoOfEmployee' | 'EmployeeIsLastUpdated' | 'YearEndCarryForwardLastPostedDate' | 'YearEndCarryForwardIsLastUpdated'>
  ), GetLeaveAdjustmentListing?: Maybe<Array<(
    { __typename?: 'LeaveAdjustmentOutput' }
    & Pick<LeaveAdjustmentOutput, 'LeaveHeaderID' | 'EmployeeID' | 'LeaveTypeName' | 'LeaveFrequency' | 'Remarks'>
  )>>, GetLeaveRecordsListing?: Maybe<Array<(
    { __typename?: 'LeaveRecordsOutput' }
    & Pick<LeaveRecordsOutput, 'EmployeeID' | 'EmployeeName' | 'LeaveHeaderID' | 'ApprovalStatus' | 'ApprovalDate' | 'ApprovedBy'>
  )>> }
);

export type GetEntityRoleUserAssignmentQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
}>;


export type GetEntityRoleUserAssignmentQuery = (
  { __typename?: 'Query' }
  & { getEntityRoleUserAssignment: Array<(
    { __typename?: 'RoleUserEntAssignmentEntity' }
    & Pick<RoleUserEntAssignmentEntity, 'roleID'>
  )> }
);

export type LeaveOutOfStateListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type LeaveOutOfStateListingQuery = (
  { __typename?: 'Query' }
  & { LeaveOutOfStateListing: Array<(
    { __typename?: 'LeaveOutOfStateEntity' }
    & Pick<LeaveOutOfStateEntity, 'LeaveOutOfStateID' | 'CompanyID' | 'EmployeeID' | 'SubscriptionAccountID' | 'FromStateID' | 'ToStateID' | 'StartDate' | 'EndDate' | 'FromCalendarPolicy' | 'ToCalendarPolicy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'JoinedDate' | 'JobGradeID' | 'OrgUnitID' | 'EmployeeNo' | 'ContactID' | 'CalendarStateID' | 'CompanyID'>
      & { Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CalendarStateID'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
        & { PermanentAddress?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'State'>
        )> }
      )> }
    )>, FromState?: Maybe<(
      { __typename?: 'StatesEntity' }
      & Pick<StatesEntity, 'StatesID' | 'Description'>
    )>, ToState?: Maybe<(
      { __typename?: 'StatesEntity' }
      & Pick<StatesEntity, 'StatesID' | 'Description'>
    )>, FromCalendarPolicyDetail?: Maybe<(
      { __typename?: 'CalendarPolicyEntity' }
      & Pick<CalendarPolicyEntity, 'CalendarPolicyID' | 'Title'>
      & { States?: Maybe<(
        { __typename?: 'StatesEntity' }
        & Pick<StatesEntity, 'StatesID' | 'Description'>
      )> }
    )>, ToCalendarPolicyDetail?: Maybe<(
      { __typename?: 'CalendarPolicyEntity' }
      & Pick<CalendarPolicyEntity, 'CalendarPolicyID' | 'Title'>
      & { States?: Maybe<(
        { __typename?: 'StatesEntity' }
        & Pick<StatesEntity, 'StatesID' | 'Description'>
      )> }
    )> }
  )> }
);

export type LeaveTakenListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type LeaveTakenListingQuery = (
  { __typename?: 'Query' }
  & { LeaveTakenListing?: Maybe<Array<(
    { __typename?: 'LeaveTakenOutput' }
    & Pick<LeaveTakenOutput, 'ExcelFileName' | 'UploadedDate' | 'TotalRecords'>
  )>> }
);

export type GetLeavePostingPeriodListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type GetLeavePostingPeriodListingQuery = (
  { __typename?: 'Query' }
  & { GetLeavePostingPeriodListing?: Maybe<Array<(
    { __typename?: 'LeavePostingPeriodOutput' }
    & Pick<LeavePostingPeriodOutput, 'PeriodYearMonth' | 'CountUnpostedLeave' | 'CountPostedLeave' | 'CountLeaveRecords' | 'PeriodStatus' | 'PeriodFromDate' | 'PeriodToDate' | 'ModifiedDT' | 'isPostedInTheListing'>
  )>> }
);

export type LeaveOutOfStateDeleteMutationVariables = Exact<{
  LeaveOutOfStateID: Scalars['String'];
}>;


export type LeaveOutOfStateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveOutOfStateDelete'>
);

export type LeaveOutOfStateInsertMutationVariables = Exact<{
  input: LeaveOutOfStateInput;
}>;


export type LeaveOutOfStateInsertMutation = (
  { __typename?: 'Mutation' }
  & { LeaveOutOfStateInsert: (
    { __typename?: 'LeaveOutOfStateEntity' }
    & Pick<LeaveOutOfStateEntity, 'LeaveOutOfStateID'>
  ) }
);

export type LeaveOufOfStateUpdateMutationVariables = Exact<{
  input: LeaveOutOfStateInput;
}>;


export type LeaveOufOfStateUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveOufOfStateUpdate'>
);

export type LeaveRecordsApprovalMutationVariables = Exact<{
  input: Array<LeaveApprovalUpdateInput> | LeaveApprovalUpdateInput;
  IsSupersede?: Maybe<Scalars['Boolean']>;
}>;


export type LeaveRecordsApprovalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveRecordsApproval'>
);

export type DeletePendingLeaveApplicationMutationVariables = Exact<{
  input: EmpLeaveDetailInput;
}>;


export type DeletePendingLeaveApplicationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeletePendingLeaveApplication'>
);

export type EmployeeLeaveListingQueryVariables = Exact<{
  input: EmpLeaveListingInput;
}>;


export type EmployeeLeaveListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeLeaveListing?: Maybe<Array<(
    { __typename?: 'LeaveHeaderEntity' }
    & Pick<LeaveHeaderEntity, 'LeaveHeaderID' | 'CompanyID' | 'EmployeeID' | 'LeaveTypeID' | 'LeaveHeaderType' | 'PeriodYear' | 'TotalDay' | 'FromDate' | 'UntilDate' | 'DocumentID' | 'Remarks' | 'ApprovalStatus' | 'ApprovalDT' | 'ApproverID' | 'OneTimePayID'>
    & { LeaveDetails?: Maybe<Array<(
      { __typename?: 'LeaveDetailsEntity' }
      & Pick<LeaveDetailsEntity, 'LeaveDetailsID' | 'LeaveHeaderID' | 'PayPeriodID' | 'SessionType' | 'LeaveDate' | 'NumberOfDay' | 'ManualEntryID' | 'PostedDate'>
    )>> }
  )>> }
);

export type LeaveTakenBulkInsertMutationVariables = Exact<{
  LeaveHeaderInput: Array<LeaveHeaderInput> | LeaveHeaderInput;
}>;


export type LeaveTakenBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveTakenBulkInsert'>
);

export type BlockLeaveListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type BlockLeaveListingQuery = (
  { __typename?: 'Query' }
  & { BlockLeaveListing: Array<(
    { __typename?: 'BlockLeaveEntity' }
    & Pick<BlockLeaveEntity, 'BlockLeaveID' | 'SubscriptionAccountID' | 'CompanyID' | 'Name' | 'ParentID' | 'Remark' | 'DocumentID' | 'StartDate' | 'EndDate' | 'LeaveTypeID' | 'OrgUnitID' | 'Status'>
    & { LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'ContactID' | 'OrgUnitID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type GetLeaveAdjustmentListingQueryVariables = Exact<{
  input: LeaveAdjustmentListingInput;
}>;


export type GetLeaveAdjustmentListingQuery = (
  { __typename?: 'Query' }
  & { GetLeaveAdjustmentListing?: Maybe<Array<(
    { __typename?: 'LeaveAdjustmentOutput' }
    & Pick<LeaveAdjustmentOutput, 'LeaveHeaderID' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName' | 'LeaveTypeID' | 'LeaveTypeName' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks' | 'TotalDay' | 'LeaveFrequency'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'CompanyID' | 'JoinedDate' | 'FormStatus' | 'JobGradeID' | 'OrgUnitID'>
    )> }
  )>> }
);

export type LeaveAdjustmentDeleteMutationVariables = Exact<{
  LeaveHeaderID: Scalars['String'];
  input: LeaveHeaderInput;
}>;


export type LeaveAdjustmentDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveAdjustmentDelete'>
);

export type LeaveAdjustmentInsertMutationVariables = Exact<{
  input: Array<LeaveHeaderInput> | LeaveHeaderInput;
}>;


export type LeaveAdjustmentInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveAdjustmentInsert'>
);

export type LeaveAdjustmentUpdateMutationVariables = Exact<{
  LeaveHeaderID: Scalars['String'];
  input: LeaveHeaderInput;
}>;


export type LeaveAdjustmentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LeaveAdjustmentUpdate'>
);

export type LeaveAdjustmentLeaveTypeListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type LeaveAdjustmentLeaveTypeListingQuery = (
  { __typename?: 'Query' }
  & { LeavePolicyListing?: Maybe<Array<(
    { __typename?: 'LeaveItemEntity' }
    & Pick<LeaveItemEntity, 'LeaveFrequency'>
    & { LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'Name' | 'LeaveTypeCode'>
    )> }
  )>> }
);

export type DeleteCompulsoryLeaveMutationVariables = Exact<{
  LeaveCompulsoryID: Scalars['String'];
}>;


export type DeleteCompulsoryLeaveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteCompulsoryLeave'>
);

export type SaveUpdateCompulsoryLeaveMutationVariables = Exact<{
  LeaveCompulsoryInput: LeaveCompulsoryInput;
  LeaveCompulsoryEmployeeInputs: Array<LeaveCompulsoryEmployeeInput> | LeaveCompulsoryEmployeeInput;
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
}>;


export type SaveUpdateCompulsoryLeaveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SaveUpdateCompulsoryLeave'>
);

export type BlockLeaveStatusUpdateMutationVariables = Exact<{
  Input: BlockLeaveInput;
}>;


export type BlockLeaveStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BlockLeaveStatusUpdate'>
);

export type BlockLeaveInsertMutationVariables = Exact<{
  Input: Array<BlockLeaveInput> | BlockLeaveInput;
  DocumentsInput?: Maybe<DocumentsInput>;
}>;


export type BlockLeaveInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BlockLeaveInsert'>
);

export type BlockLeaveUpdateMutationVariables = Exact<{
  Input: Array<BlockLeaveInput> | BlockLeaveInput;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  IsUpdateAttachment: Scalars['Boolean'];
}>;


export type BlockLeaveUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BlockLeaveUpdate'>
);

export type GetLeavePostingListingQueryVariables = Exact<{
  input: LeavePostingListingInput;
}>;


export type GetLeavePostingListingQuery = (
  { __typename?: 'Query' }
  & { GetLeavePostingListing?: Maybe<Array<(
    { __typename?: 'LeavePostingOutput' }
    & Pick<LeavePostingOutput, 'LeaveHeaderID' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName' | 'LeaveFromDate' | 'LeaveToDate' | 'LeaveTypeID' | 'LeaveTypeName' | 'TotalDay' | 'Remarks' | 'PostedDate' | 'LeaveHeaderStatus' | 'OneTimePayID' | 'ApprovalDate' | 'ApprovedBy' | 'SubmissionDate' | 'PayPeriod' | 'PayrollCycle' | 'IsOTPModified' | 'OTPModifiedBy' | 'IsOTPDeleted' | 'OTPDeletedBy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'CompanyID' | 'JoinedDate' | 'FormStatus' | 'JobGradeID' | 'OrgUnitID'>
    )>, LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'LeaveTypeID'>
      & { PayItem?: Maybe<(
        { __typename?: 'PayItemEntity' }
        & Pick<PayItemEntity, 'PayItemID' | 'PayName' | 'PayItemType'>
      )> }
    )>, LeaveDetails?: Maybe<Array<(
      { __typename?: 'LeaveDetailsEntity' }
      & Pick<LeaveDetailsEntity, 'LeaveDetailsID' | 'PayPeriodID' | 'PayrollCycleID'>
    )>> }
  )>> }
);

export type InsertLeaveCutOffPeriodsMutationVariables = Exact<{
  input: GenerateLeaveCutOffPeriodInput;
}>;


export type InsertLeaveCutOffPeriodsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InsertLeaveCutOffPeriods'>
);

export type PostLeaveRecordsMutationVariables = Exact<{
  input: Array<LeavePostingInput> | LeavePostingInput;
}>;


export type PostLeaveRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PostLeaveRecords'>
);

export type RevertLeaveRecordsMutationVariables = Exact<{
  input: Array<LeavePostingInput> | LeavePostingInput;
}>;


export type RevertLeaveRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RevertLeaveRecords'>
);

export type SubmitYearEndCarryForwardMutationVariables = Exact<{
  LeaveHeaderInputs: Array<LeaveHeaderInput> | LeaveHeaderInput;
}>;


export type SubmitYearEndCarryForwardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitYearEndCarryForward'>
);

export type ReversePostedYecfRecordsMutationVariables = Exact<{
  Inputs: Array<ReversePostedYecfInput> | ReversePostedYecfInput;
  Year: Scalars['Float'];
}>;


export type ReversePostedYecfRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReversePostedYECFRecords'>
);

export type ReversePostedLcfeMutationVariables = Exact<{
  Inputs: Array<ReversePostedLcfeInput> | ReversePostedLcfeInput;
  Year: Scalars['Float'];
}>;


export type ReversePostedLcfeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReversePostedLCFE'>
);

export type GetYecfPendingListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type GetYecfPendingListingQuery = (
  { __typename?: 'Query' }
  & { GetYECFPendingListing?: Maybe<Array<(
    { __typename?: 'YECFListingOutput' }
    & Pick<YecfListingOutput, 'HeaderStatus' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName' | 'NoOfPendingApprovalApplication'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'CompanyID' | 'JoinedDate' | 'FormStatus' | 'JobGradeID' | 'OrgUnitID'>
    )>, LeaveItem?: Maybe<(
      { __typename?: 'LeaveItemEntity' }
      & Pick<LeaveItemEntity, 'Title' | 'CFMethod' | 'CFValue' | 'CFMonth'>
      & { LeaveType?: Maybe<(
        { __typename?: 'LeaveTypeEntity' }
        & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'Name'>
      )> }
    )>, LeaveBalanceSummary?: Maybe<(
      { __typename?: 'LeaveBalanceSummary' }
      & Pick<LeaveBalanceSummary, 'Entitled' | 'Balance' | 'CarryForward'>
    )> }
  )>> }
);

export type GetYecfPostedListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type GetYecfPostedListingQuery = (
  { __typename?: 'Query' }
  & { GetYECFPostedListing?: Maybe<Array<(
    { __typename?: 'YECFListingOutput' }
    & Pick<YecfListingOutput, 'HeaderStatus' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'CompanyID' | 'JoinedDate' | 'FormStatus' | 'JobGradeID' | 'OrgUnitID'>
    )>, LeaveItem?: Maybe<(
      { __typename?: 'LeaveItemEntity' }
      & Pick<LeaveItemEntity, 'LeaveTypeID'>
      & { LeaveType?: Maybe<(
        { __typename?: 'LeaveTypeEntity' }
        & Pick<LeaveTypeEntity, 'Name'>
      )> }
    )>, LeaveBalanceSummary?: Maybe<(
      { __typename?: 'LeaveBalanceSummary' }
      & Pick<LeaveBalanceSummary, 'CarryForward'>
    )> }
  )>> }
);

export type GetYecfPendingListingWithFilterQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  DivisionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetYecfPendingListingWithFilterQuery = (
  { __typename?: 'Query' }
  & { GetYECFPendingListingWithFilter?: Maybe<Array<(
    { __typename?: 'YECFListingOutput' }
    & Pick<YecfListingOutput, 'HeaderStatus' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName' | 'NoOfPendingApprovalApplication'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'CompanyID' | 'JoinedDate' | 'FormStatus' | 'JobGradeID' | 'OrgUnitID'>
    )>, LeaveItem?: Maybe<(
      { __typename?: 'LeaveItemEntity' }
      & Pick<LeaveItemEntity, 'Title' | 'CFMethod' | 'CFValue' | 'CFMonth'>
      & { LeaveType?: Maybe<(
        { __typename?: 'LeaveTypeEntity' }
        & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'Name'>
      )> }
    )>, LeaveBalanceSummary?: Maybe<(
      { __typename?: 'LeaveBalanceSummary' }
      & Pick<LeaveBalanceSummary, 'Entitled' | 'Balance' | 'CarryForward'>
    )> }
  )>> }
);

export type GetYecfPostedListingWithFilterQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  DivisionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetYecfPostedListingWithFilterQuery = (
  { __typename?: 'Query' }
  & { GetYECFPostedListingWithFilter?: Maybe<Array<(
    { __typename?: 'YECFListingOutput' }
    & Pick<YecfListingOutput, 'HeaderStatus' | 'InUse' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'CompanyID' | 'JoinedDate' | 'FormStatus' | 'JobGradeID' | 'OrgUnitID'>
    )>, LeaveItem?: Maybe<(
      { __typename?: 'LeaveItemEntity' }
      & Pick<LeaveItemEntity, 'LeaveTypeID'>
      & { LeaveType?: Maybe<(
        { __typename?: 'LeaveTypeEntity' }
        & Pick<LeaveTypeEntity, 'Name'>
      )> }
    )>, LeaveBalanceSummary?: Maybe<(
      { __typename?: 'LeaveBalanceSummary' }
      & Pick<LeaveBalanceSummary, 'CarryForward'>
    )> }
  )>> }
);

export type GetAlBalanceForResignedEmployeesQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  toDate: Scalars['DateTime'];
}>;


export type GetAlBalanceForResignedEmployeesQuery = (
  { __typename?: 'Query' }
  & { GetALBalanceForResignedEmployees?: Maybe<Array<(
    { __typename?: 'LeaveEntitlementByLeaveTypeListing' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'CompanyID' | 'FormStatus' | 'JobGradeID' | 'OrgUnitID' | 'EmployeeID' | 'EmployeeNo' | 'JoinedDate' | 'ResignedDate' | 'Status' | 'ContactID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'Name'>
    )>, LeaveBalanceSummary?: Maybe<(
      { __typename?: 'LeaveBalanceSummary' }
      & Pick<LeaveBalanceSummary, 'Entitled' | 'Earned' | 'BroughtForward' | 'Adjustment' | 'Taken' | 'TakenYTD' | 'Balance' | 'BalanceYTD' | 'Forfeited'>
    )>, EncashedLeaveHistory?: Maybe<(
      { __typename?: 'EncashedLeaveHistory' }
      & Pick<EncashedLeaveHistory, 'EncashOffboadingLeaveID' | 'CompanyID' | 'LeaveTypeID' | 'EmployeeID' | 'OneTimePayID' | 'PayItemID' | 'PayPeriodID' | 'PayrollCycleID' | 'PeriodYear' | 'LeaveBalance' | 'PostedDate' | 'PostStatus'>
    )> }
  )>> }
);

export type PostOffboardingLeaveEncashmentMutationVariables = Exact<{
  input: Array<EncashOffboadingLeaveInput> | EncashOffboadingLeaveInput;
}>;


export type PostOffboardingLeaveEncashmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PostOffboardingLeaveEncashment'>
);

export type RevertOffboardingLeaveEncashmentMutationVariables = Exact<{
  input: Array<EncashOffboadingLeaveInput> | EncashOffboadingLeaveInput;
}>;


export type RevertOffboardingLeaveEncashmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RevertOffboardingLeaveEncashment'>
);

export type GetLeaveEntitlementByLeaveTypeListingTotalCountQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetLeaveEntitlementByLeaveTypeListingTotalCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetLeaveEntitlementByLeaveTypeListingTotalCount'>
);

export type GetLeaveEntitlementByLeaveTypeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  toDate: Scalars['DateTime'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  DivisionIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetLeaveEntitlementByLeaveTypeListingQuery = (
  { __typename?: 'Query' }
  & { GetLeaveEntitlementByLeaveTypeListing?: Maybe<Array<(
    { __typename?: 'LeaveEntitlementByLeaveTypeListing' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'JobGradeID' | 'OrgUnitID' | 'EmployeeID' | 'EmployeeNo' | 'JoinedDate' | 'Status' | 'FormStatus' | 'ResignedDate' | 'CompanyID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'Name'>
      & { LeavePolicies?: Maybe<Array<(
        { __typename?: 'LeaveItemEntity' }
        & Pick<LeaveItemEntity, 'LeaveFrequency'>
      )>> }
    )>, LeaveBalanceSummary?: Maybe<(
      { __typename?: 'LeaveBalanceSummary' }
      & Pick<LeaveBalanceSummary, 'Entitled' | 'Earned' | 'BroughtForward' | 'Adjustment' | 'Taken' | 'TakenYTD' | 'Balance' | 'BalanceYTD' | 'Forfeited' | 'Occurrence'>
    )> }
  )>> }
);

export type GetOffboardEncashPeriodListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type GetOffboardEncashPeriodListingQuery = (
  { __typename?: 'Query' }
  & { GetOffboardEncashPeriodListing?: Maybe<Array<(
    { __typename?: 'LeavePostingPeriodOutput' }
    & Pick<LeavePostingPeriodOutput, 'PeriodYearMonth' | 'PeriodStatus' | 'ModifiedDT'>
  )>> }
);

export type GetCarryForwardEncashmentPostedListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type GetCarryForwardEncashmentPostedListingQuery = (
  { __typename?: 'Query' }
  & { GetCarryForwardEncashmentPostedListing?: Maybe<Array<(
    { __typename?: 'EncashCarryForwardLeaveOutput' }
    & Pick<EncashCarryForwardLeaveOutput, 'EncashCarryForwardLeaveID' | 'EmployeeID' | 'CompanyID' | 'OneTimePayID' | 'PayItemID' | 'PayPeriodID' | 'PayrollCycleID' | 'PeriodYear' | 'LeaveEncashment' | 'PayrollProcessed' | 'IsOTPModified' | 'OTPModifiedBy' | 'IsOTPDeleted' | 'OTPDeletedBy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo' | 'FormStatus' | 'JobGradeID' | 'OrgUnitID'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'Name'>
    )> }
  )>> }
);

export type GetCarryForwardEncashmentPendingListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type GetCarryForwardEncashmentPendingListingQuery = (
  { __typename?: 'Query' }
  & { GetCarryForwardEncashmentPendingListing?: Maybe<Array<(
    { __typename?: 'YECFListingOutput' }
    & Pick<YecfListingOutput, 'HeaderStatus' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName' | 'NoOfPendingApprovalApplication'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'CompanyID' | 'JoinedDate' | 'FormStatus' | 'JobGradeID' | 'OrgUnitID'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description'>
      )> }
    )>, LeaveItem?: Maybe<(
      { __typename?: 'LeaveItemEntity' }
      & Pick<LeaveItemEntity, 'LeaveItemID' | 'EncashmentPayItem' | 'Title' | 'RemainingBalance' | 'CFMethod' | 'CFValue' | 'CFMonth'>
      & { LeaveType?: Maybe<(
        { __typename?: 'LeaveTypeEntity' }
        & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'Name'>
      )> }
    )>, LeaveBalanceSummary?: Maybe<(
      { __typename?: 'LeaveBalanceSummary' }
      & Pick<LeaveBalanceSummary, 'Entitled' | 'Balance' | 'CarryForward' | 'Encashment'>
    )> }
  )>> }
);

export type PostCarryForwardEncashmentMutationVariables = Exact<{
  input: Array<EncashCarryForwardLeaveInput> | EncashCarryForwardLeaveInput;
}>;


export type PostCarryForwardEncashmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PostCarryForwardEncashment'>
);

export type GetLeaveAdjustmentEmployeeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveFrequency: LeaveFrequency;
}>;


export type GetLeaveAdjustmentEmployeeListingQuery = (
  { __typename?: 'Query' }
  & { GetLeaveAdjustmentEmployeeListing?: Maybe<Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'CalendarStateID' | 'CalendarPolicyID' | 'IsInterCompanyTransfer' | 'JoinedDate' | 'JoinedReferenceDate' | 'Probation' | 'ConfirmedDate' | 'EmployeeStatus' | 'Status' | 'FormStatus' | 'ResignedDate' | 'EmploymentType' | 'JobGradeID' | 'OrgUnitID'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description' | 'ParentID'>
    )> }
  )>> }
);

export type ModerationListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type ModerationListingQuery = (
  { __typename?: 'Query' }
  & { ModerationListing: Array<(
    { __typename?: 'ModerationRatingEntity' }
    & Pick<ModerationRatingEntity, 'ModerationRatingID' | 'CompanyID' | 'Description' | 'ScaleSequence' | 'FinalRating' | 'From' | 'To' | 'Year' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type ModerationSubmitMutationVariables = Exact<{
  input: Array<ModerationRatingInput> | ModerationRatingInput;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type ModerationSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ModerationSubmit'>
);

export type EmployeeScheduledTrainingListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
}>;


export type EmployeeScheduledTrainingListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeScheduledTrainingListing: Array<(
    { __typename?: 'ScheduleTrainingEntity' }
    & Pick<ScheduleTrainingEntity, 'ScheduledTrainingID' | 'SubscriptionAccountID' | 'CompanyID' | 'TrainingProviderID' | 'CourseID' | 'CourseName' | 'StartDate' | 'EndDate' | 'Venue' | 'CourseFee' | 'LumpSum' | 'PerClass' | 'MaxPax' | 'Remarks' | 'TraineeEval' | 'CourseEval' | 'TrainerEval' | 'SupervisorEval' | 'TrainingSchedule' | 'QRCode' | 'RegisteredTrainee' | 'Registered' | 'GetTrainingCostItemTotal' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, QRCodeDocument?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type AvailableCoursesListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  JobGradeID: Scalars['String'];
}>;


export type AvailableCoursesListingQuery = (
  { __typename?: 'Query' }
  & { AvailableCoursesListing: Array<(
    { __typename?: 'ScheduleTrainingEntity' }
    & Pick<ScheduleTrainingEntity, 'ScheduledTrainingID' | 'SubscriptionAccountID' | 'CompanyID' | 'TrainingProviderID' | 'CourseID' | 'CourseName' | 'StartDate' | 'EndDate' | 'Venue' | 'CourseFee' | 'LumpSum' | 'PerClass' | 'MaxPax' | 'Remarks' | 'TraineeEval' | 'CourseEval' | 'TrainerEval' | 'SupervisorEval' | 'TrainingSchedule' | 'QRCode' | 'RegisteredTrainee' | 'Registered' | 'GetTrainingCostItemTotal' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, QRCodeDocument?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type CompulsoryCourseListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  JobGradeID: Scalars['String'];
}>;


export type CompulsoryCourseListingQuery = (
  { __typename?: 'Query' }
  & { CompulsoryCourseListing: Array<(
    { __typename?: 'ScheduleTrainingEntity' }
    & Pick<ScheduleTrainingEntity, 'ScheduledTrainingID' | 'SubscriptionAccountID' | 'CompanyID' | 'TrainingProviderID' | 'CourseID' | 'CourseName' | 'StartDate' | 'EndDate' | 'Venue' | 'CourseFee' | 'LumpSum' | 'PerClass' | 'MaxPax' | 'Remarks' | 'TraineeEval' | 'CourseEval' | 'TrainerEval' | 'SupervisorEval' | 'TrainingSchedule' | 'QRCode' | 'RegisteredTrainee' | 'Registered' | 'GetTrainingCostItemTotal' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, QRCodeDocument?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type RegisteredTraineeInsertMutationVariables = Exact<{
  RegisteredTraineeID: Scalars['String'];
  ScheduleTrainingID: Scalars['String'];
}>;


export type RegisteredTraineeInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RegisteredTraineeInsert'>
);

export type EmployeeTrainingAttendanceListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
}>;


export type EmployeeTrainingAttendanceListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeTrainingAttendanceListing: Array<(
    { __typename?: 'TrainingAttendanceEntity' }
    & Pick<TrainingAttendanceEntity, 'ScheduledTrainingID' | 'SubscriptionAccountID' | 'CompanyID' | 'EmployeeID' | 'CheckInDate' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'DocumentID' | 'TotalTaxRelief' | 'FormStatus' | 'EmploymentType' | 'CreatedDT' | 'ModifiedDT'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )>, EmployeeStatutory?: Maybe<(
        { __typename?: 'EmployeeStatutoryEntity' }
        & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID'>
      )>, EmployeePrevContribution?: Maybe<(
        { __typename?: 'EmployeePrevContributionEntity' }
        & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
      )>, EmployeeDependents?: Maybe<Array<(
        { __typename?: 'EmployeeDependentsEntity' }
        & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID'>
      )>>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'DepartmentID' | 'DepartmentType' | 'ParentID' | 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )> }
  )> }
);

export type ScheduleTrainingListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type ScheduleTrainingListingQuery = (
  { __typename?: 'Query' }
  & { ScheduleTrainingListing: Array<(
    { __typename?: 'ScheduleTrainingEntity' }
    & Pick<ScheduleTrainingEntity, 'ScheduledTrainingID' | 'SubscriptionAccountID' | 'CompanyID' | 'TrainingProviderID' | 'CourseID' | 'CourseName' | 'StartDate' | 'EndDate' | 'Venue' | 'CourseFee' | 'LumpSum' | 'PerClass' | 'MaxPax' | 'Remarks' | 'TraineeEval' | 'CourseEval' | 'TrainerEval' | 'SupervisorEval' | 'TrainingSchedule' | 'QRCode' | 'RegisteredTrainee' | 'GetTrainingCostItemTotal' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, QRCodeDocument?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type CreateTrainingAttendanceRecordMutationVariables = Exact<{
  input: TrainingAttendanceInput;
}>;


export type CreateTrainingAttendanceRecordMutation = (
  { __typename?: 'Mutation' }
  & { createTrainingAttendanceRecord: (
    { __typename?: 'TrainingAttendanceEntity' }
    & Pick<TrainingAttendanceEntity, 'TrainingAttendanceID'>
  ) }
);

export type UpdateTrainingAttendanceRecordMutationVariables = Exact<{
  input: TrainingAttendanceInput;
}>;


export type UpdateTrainingAttendanceRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateTrainingAttendanceRecord'>
);

export type EmpTrainingAttendanceHistoryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  FromDate: Scalars['String'];
  ToDate: Scalars['String'];
}>;


export type EmpTrainingAttendanceHistoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmpTrainingAttendanceHistory'>
);

export type AuthorisedTrainingProviderListingQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthorisedTrainingProviderListingQuery = (
  { __typename?: 'Query' }
  & { AuthorisedTrainingProviderListing: Array<(
    { __typename?: 'AuthorisedTrainingProviderEntity' }
    & Pick<AuthorisedTrainingProviderEntity, 'ID' | 'SubscriptionAccountID' | 'DocumentID' | 'Name' | 'IsExternal' | 'ApprovedCourses' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'NickName' | 'MobileNo' | 'OfficeNo' | 'FaxNo' | 'Email' | 'NRIC' | 'PassportNo' | 'PassportNoLHDN' | 'Nationality' | 'Marital' | 'Gender' | 'Race' | 'Religion' | 'BirthDate' | 'ProfilePicture' | 'WebUrl' | 'SameAddress'>
      & { Address?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
      )>, PermanentAddress?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'Country' | 'State' | 'City' | 'PostalCode'>
      )> }
    )> }
  )> }
);

export type EmployeeNotificationListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type EmployeeNotificationListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeNotificationListing: Array<(
    { __typename?: 'EmployeeNotificationOutput' }
    & Pick<EmployeeNotificationOutput, 'Message' | 'CreatedDT' | 'RequestType' | 'Topic' | 'Status' | 'NotificationType' | 'ReadStatus' | 'NotificationID'>
    & { LeaveDetails?: Maybe<(
      { __typename?: 'LeaveDetails' }
      & Pick<LeaveDetails, 'ApprovalStatus' | 'TotalDay' | 'LeaveType' | 'ApproverName' | 'ApprovalRemark' | 'Remarks' | 'FromDate' | 'UntilDate'>
    )>, ClaimDetails?: Maybe<(
      { __typename?: 'ClaimDetails' }
      & Pick<ClaimDetails, 'ApproverName' | 'ApprovalStatus' | 'ApprovalRemark' | 'ClaimSubject' | 'CreatedDT' | 'TotalClaim' | 'TotalClaimAmount' | 'TotalVerifiedAmount'>
      & { ClaimDetailsListing?: Maybe<Array<(
        { __typename?: 'ClaimDetailsEntity' }
        & Pick<ClaimDetailsEntity, 'ClaimAmountByFinance' | 'ClaimAmount' | 'ClaimRemarks' | 'ClaimDetailsID' | 'ReceiptDate' | 'ReceiptNo' | 'FinanceApproveStatus'>
        & { Documents?: Maybe<(
          { __typename?: 'DocumentsEntity' }
          & Pick<DocumentsEntity, 'DocumentFile' | 'DocumentID'>
        )>, ClaimType?: Maybe<(
          { __typename?: 'ClaimTypeEntity' }
          & Pick<ClaimTypeEntity, 'Name'>
        )> }
      )>> }
    )>, RequisitionDetails?: Maybe<(
      { __typename?: 'RequisitionDetails' }
      & Pick<RequisitionDetails, 'ApproverName' | 'ApprovalStatus' | 'ApprovalRemark' | 'RequisitionType' | 'DocumentFile' | 'Subject' | 'NumberOfItem' | 'CostPerItem' | 'Amount' | 'Remark' | 'StartDate' | 'EndDate' | 'TotalDays' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'CreatedDT'>
    )>, DataChangesDetails?: Maybe<(
      { __typename?: 'DataChangesDetails' }
      & Pick<DataChangesDetails, 'DataChangesID' | 'ApproverName' | 'ApprovalStatus' | 'ApprovalRemark' | 'CreatedDT' | 'Action' | 'Type'>
      & { ChangesData?: Maybe<(
        { __typename?: 'ChangesData' }
        & Pick<ChangesData, 'DocumentID' | 'Nationality' | 'PassportNo' | 'NRIC' | 'Marital' | 'Race' | 'Religion' | 'IsOKU' | 'EmployeeBank' | 'EmployeeBankNo' | 'EmployeeEPFNo' | 'EmployeePCBNo' | 'EmployeeDependentsID' | 'ContactID' | 'IsEmergencyContact' | 'MobileNo' | 'Occupation' | 'Education' | 'ChildEligibleRate' | 'PCBNo' | 'FullName' | 'BirthDate'>
        & { PermanentAddress?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
        )>, Address?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
        )> }
      )>, OldData?: Maybe<(
        { __typename?: 'ChangesData' }
        & Pick<ChangesData, 'DocumentID' | 'Nationality' | 'PassportNo' | 'NRIC' | 'Marital' | 'Race' | 'Religion' | 'IsOKU' | 'EmployeeBank' | 'EmployeeBankNo' | 'EmployeeEPFNo' | 'EmployeePCBNo' | 'EmployeeDependentsID' | 'ContactID' | 'IsEmergencyContact' | 'MobileNo' | 'Occupation' | 'Education' | 'ChildEligibleRate' | 'PCBNo' | 'FullName' | 'BirthDate'>
        & { PermanentAddress?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
        )>, Address?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
        )> }
      )> }
    )>, ProjectExpenseClaimDetails?: Maybe<(
      { __typename?: 'ProjectExpenseClaimDetails' }
      & Pick<ProjectExpenseClaimDetails, 'ApproverName' | 'ApprovalStatus' | 'ApprovalRemark' | 'ClaimSubject' | 'CreatedDT' | 'TotalClaim' | 'TotalClaimAmount' | 'TotalVerifiedAmount'>
      & { DocumentIDs?: Maybe<Array<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )>>, ClaimDetailsListing?: Maybe<Array<(
        { __typename?: 'ProjectExpenseClaimDetailEntity' }
        & Pick<ProjectExpenseClaimDetailEntity, 'ClaimAmountByFinance' | 'ClaimAmount' | 'ClaimRemark' | 'ID' | 'ReceiptDate' | 'ReceiptNo' | 'FinanceApproveStatus'>
        & { ProjectExpenseType?: Maybe<(
          { __typename?: 'ProjectExpenseTypeEntity' }
          & Pick<ProjectExpenseTypeEntity, 'ProjectExpenseType'>
        )> }
      )>> }
    )>, HelpdeskTicketDetail?: Maybe<(
      { __typename?: 'HelpdeskTicketEntity' }
      & Pick<HelpdeskTicketEntity, 'TicketNo' | 'SubjectDetails'>
    )> }
  )> }
);

export type UpdateNotificationReadStatusMutationVariables = Exact<{
  NotificationID: Scalars['String'];
}>;


export type UpdateNotificationReadStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateNotificationReadStatus'>
);

export type UpdateAllNotificationReadStatusMutationVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type UpdateAllNotificationReadStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateAllNotificationReadStatus'>
);

export type NotificationUpdateMutationVariables = Exact<{
  NotificationInput: NotificationInput;
}>;


export type NotificationUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NotificationUpdate'>
);

export type NotificationReadStatusDeleteMutationVariables = Exact<{
  ID: Array<Scalars['String']> | Scalars['String'];
}>;


export type NotificationReadStatusDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NotificationReadStatusDelete'>
);

export type UpdateBulkNotificationReadStatusMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateBulkNotificationReadStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateBulkNotificationReadStatus'>
);

export type NotificationPolicyInsertMutationVariables = Exact<{
  NotificationPolicyInput: NotificationPolicyInput;
}>;


export type NotificationPolicyInsertMutation = (
  { __typename?: 'Mutation' }
  & { NotificationPolicyInsert: (
    { __typename?: 'NotificationPolicyEntity' }
    & Pick<NotificationPolicyEntity, 'NotificationPolicyID'>
  ) }
);

export type NotificationPolicyUpdateMutationVariables = Exact<{
  NotificationPolicyID: Scalars['String'];
  NotificationPolicyInput: NotificationPolicyInput;
}>;


export type NotificationPolicyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NotificationPolicyUpdate'>
);

export type NotificationPolicyDetailQueryVariables = Exact<{
  NotificationPolicyID: Scalars['String'];
}>;


export type NotificationPolicyDetailQuery = (
  { __typename?: 'Query' }
  & { NotificationPolicyDetail: Array<(
    { __typename?: 'NotificationPolicyEntity' }
    & Pick<NotificationPolicyEntity, 'NotificationPolicyID' | 'Subject' | 'NotificationPolicyType' | 'PolicyType' | 'CompanyID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { NotificationPolicyClaim?: Maybe<Array<(
      { __typename?: 'NotificationPolicyClaimEntity' }
      & Pick<NotificationPolicyClaimEntity, 'NotificationPolicyClaimID' | 'CompanyID' | 'NotificationPolicyID' | 'ClaimTypeID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>>, NotificationPolicyLeave?: Maybe<Array<(
      { __typename?: 'NotificationPolicyLeaveEntity' }
      & Pick<NotificationPolicyLeaveEntity, 'NotificationPolicyLeaveID' | 'CompanyID' | 'NotificationPolicyID' | 'LeaveTypeID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>>, NotificationPolicyRequisition?: Maybe<Array<(
      { __typename?: 'NotificationPolicyRequisitionEntity' }
      & Pick<NotificationPolicyRequisitionEntity, 'NotificationPolicyRequisitionID' | 'CompanyID' | 'NotificationPolicyID' | 'RequisitionType' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>>, NotificationPolicyCondition?: Maybe<Array<(
      { __typename?: 'NotificationPolicyConditionEntity' }
      & Pick<NotificationPolicyConditionEntity, 'NotificationPolicyConditionID' | 'CompanyID' | 'NotificationPolicyID' | 'Level' | 'Value' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>> }
  )> }
);

export type NotificationPolicyListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type NotificationPolicyListingQuery = (
  { __typename?: 'Query' }
  & { NotificationPolicyListing: Array<(
    { __typename?: 'NotificationPolicyEntity' }
    & Pick<NotificationPolicyEntity, 'NotificationPolicyID' | 'Subject' | 'NotificationPolicyType' | 'PolicyType' | 'CompanyID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { NotificationPolicyClaim?: Maybe<Array<(
      { __typename?: 'NotificationPolicyClaimEntity' }
      & Pick<NotificationPolicyClaimEntity, 'NotificationPolicyClaimID' | 'CompanyID' | 'NotificationPolicyID' | 'ClaimTypeID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>>, NotificationPolicyLeave?: Maybe<Array<(
      { __typename?: 'NotificationPolicyLeaveEntity' }
      & Pick<NotificationPolicyLeaveEntity, 'NotificationPolicyLeaveID' | 'CompanyID' | 'NotificationPolicyID' | 'LeaveTypeID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>>, NotificationPolicyRequisition?: Maybe<Array<(
      { __typename?: 'NotificationPolicyRequisitionEntity' }
      & Pick<NotificationPolicyRequisitionEntity, 'NotificationPolicyRequisitionID' | 'CompanyID' | 'NotificationPolicyID' | 'RequisitionType' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>>, NotificationPolicyCondition?: Maybe<Array<(
      { __typename?: 'NotificationPolicyConditionEntity' }
      & Pick<NotificationPolicyConditionEntity, 'NotificationPolicyConditionID' | 'CompanyID' | 'NotificationPolicyID' | 'Level' | 'Value' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>> }
  )> }
);

export type NotificationPolicyConditionInsertMutationVariables = Exact<{
  NotificationPolicyConditionInput: NotificationPolicyConditionInput;
}>;


export type NotificationPolicyConditionInsertMutation = (
  { __typename?: 'Mutation' }
  & { NotificationPolicyConditionInsert: (
    { __typename?: 'NotificationPolicyConditionEntity' }
    & Pick<NotificationPolicyConditionEntity, 'NotificationPolicyConditionID'>
  ) }
);

export type NotificationPolicyConditionUpdateMutationVariables = Exact<{
  NotificationPolicyConditionID: Scalars['String'];
  NotificationPolicyConditionInput: NotificationPolicyConditionInput;
}>;


export type NotificationPolicyConditionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NotificationPolicyConditionUpdate'>
);

export type NotificationPolicyConditionListingQueryVariables = Exact<{
  NotificationPolicyID: Scalars['String'];
}>;


export type NotificationPolicyConditionListingQuery = (
  { __typename?: 'Query' }
  & { NotificationPolicyConditionListing: Array<(
    { __typename?: 'NotificationPolicyConditionEntity' }
    & Pick<NotificationPolicyConditionEntity, 'NotificationPolicyConditionID' | 'CompanyID' | 'NotificationPolicyID' | 'Level' | 'Value' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type NotificationPolicyConditionDetailQueryVariables = Exact<{
  NotificationPolicyConditionID: Scalars['String'];
}>;


export type NotificationPolicyConditionDetailQuery = (
  { __typename?: 'Query' }
  & { NotificationPolicyConditionDetail: (
    { __typename?: 'NotificationPolicyConditionEntity' }
    & Pick<NotificationPolicyConditionEntity, 'NotificationPolicyConditionID' | 'CompanyID' | 'NotificationPolicyID' | 'Level' | 'Value' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  ) }
);

export type NotificationPolicyDeleteMutationVariables = Exact<{
  NotificationPolicyInput: NotificationPolicyInput;
}>;


export type NotificationPolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NotificationPolicyDelete'>
);

export type NotificationPolicySummaryDatesQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type NotificationPolicySummaryDatesQuery = (
  { __typename?: 'Query' }
  & { NotificationPolicySummaryDates: (
    { __typename?: 'NotificationPolicyLastUpdatedOutput' }
    & Pick<NotificationPolicyLastUpdatedOutput, 'NotificationEmploymentIsLastUpdated' | 'NotificationLeaveIsLastUpdated' | 'NotificationClaimIsLastUpdated' | 'NotificationRequisitionIsLastUpdated' | 'NotificationEmploymentLastPostedDate' | 'NotificationLeaveLastPostedDate' | 'NotificationClaimLastPostedDate' | 'NotificationRequisitionLastPostedDate' | 'NotificationLastPostedDate' | 'NotificationIsLastUpdated'>
  ) }
);

export type UpdateMonthlyTransactionByFixedAllowanceMutationVariables = Exact<{
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
}>;


export type UpdateMonthlyTransactionByFixedAllowanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateMonthlyTransactionByFixedAllowance'>
);

export type PayrollCompanySettingSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type PayrollCompanySettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PayrollCompanySettingSummary'>
);

export type ViewPayrollHistorySummaryListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  IsViewHistory: Scalars['Boolean'];
}>;


export type ViewPayrollHistorySummaryListingQuery = (
  { __typename?: 'Query' }
  & { PayrollProcessPeriodListing: Array<(
    { __typename?: 'PayrollProcessPeriodListingOutput' }
    & Pick<PayrollProcessPeriodListingOutput, 'PayPeriodID' | 'FromDate' | 'ToDate' | 'PeriodYearMonth' | 'LastProcessedDate' | 'PayrollProcessStatus' | 'TotalNetPay' | 'TotalEmployee' | 'ModifiedBy' | 'TotalPayrollCyclePeriodSetting'>
  )> }
);

export type GetPayrollCyclePeriodSettingQueryVariables = Exact<{
  companyID: Scalars['String'];
  payPeriodID: Scalars['String'];
}>;


export type GetPayrollCyclePeriodSettingQuery = (
  { __typename?: 'Query' }
  & { GetPayrollCyclePeriodSetting: Array<(
    { __typename?: 'PayrollCyclePeriodEntity' }
    & Pick<PayrollCyclePeriodEntity, 'PayrollCycleID' | 'SequenceNo' | 'IsLastProcess' | 'PayPeriodID' | 'PayrollCyclePeriodID' | 'IsProccess'>
  )> }
);

export type ViewPayrollHistorySummaryQueryVariables = Exact<{
  input: ViewPayrollHistoryInput;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type ViewPayrollHistorySummaryQuery = (
  { __typename?: 'Query' }
  & { ViewPayrollHistory: Array<(
    { __typename?: 'PayrollHeaderEntity' }
    & Pick<PayrollHeaderEntity, 'PayrollHeaderID' | 'EmployeeID' | 'TotalGross' | 'TotalDeduction' | 'ProcessLog' | 'NetPay' | 'Status' | 'CompanyID' | 'Islocked'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'JobGradeID' | 'OrgUnitID' | 'FormStatus' | 'EmployeeNo' | 'Status'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, PayrollCycle?: Maybe<(
      { __typename?: 'PayrollCycleEntity' }
      & Pick<PayrollCycleEntity, 'Description'>
    )> }
  )> }
);

export type ViewPayrollHistorySummaryCountQueryVariables = Exact<{
  input: ViewPayrollHistoryInput;
}>;


export type ViewPayrollHistorySummaryCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ViewPayrollHistoryCount'>
);

export type ViewPayrollHistoryTotalQueryVariables = Exact<{
  input: ViewPayrollHistoryInput;
}>;


export type ViewPayrollHistoryTotalQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ViewPayrollHistoryTotal'>
);

export type PayrollFixedAllowanceListingQueryVariables = Exact<{
  input: PayrollFixedAllowanceInput;
}>;


export type PayrollFixedAllowanceListingQuery = (
  { __typename?: 'Query' }
  & { PayrollFixedAllowanceListing: Array<(
    { __typename?: 'RecurringPayEntity' }
    & Pick<RecurringPayEntity, 'PayrollCycleID' | 'RecurringPayID' | 'EmployeeID' | 'Remark' | 'Amount' | 'Quantity' | 'ModifiedDT' | 'CreatedDT'>
    & { PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayName' | 'PayItemType'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, StartPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PeriodYearMonth'>
    )>, EndPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PeriodYearMonth'>
    )>, PayrollCycle?: Maybe<(
      { __typename?: 'PayrollCycleEntity' }
      & Pick<PayrollCycleEntity, 'PayrollCycleID' | 'Description'>
    )> }
  )> }
);

export type PayrollFixedAllowanceManualEntryListingQueryVariables = Exact<{
  input: PayrollFixedAllowanceInput;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type PayrollFixedAllowanceManualEntryListingQuery = (
  { __typename?: 'Query' }
  & { PayrollFixedAllowanceManualEntryListing: Array<(
    { __typename?: 'RecurringPayEntity' }
    & Pick<RecurringPayEntity, 'PayrollCycleID' | 'RecurringPayID' | 'EmployeeID' | 'Remark' | 'Amount' | 'Quantity' | 'ModifiedDT' | 'CreatedDT'>
    & { PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayName' | 'PayItemType'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, StartPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PeriodYearMonth'>
    )>, EndPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PeriodYearMonth'>
    )>, PayrollCycle?: Maybe<(
      { __typename?: 'PayrollCycleEntity' }
      & Pick<PayrollCycleEntity, 'PayrollCycleID' | 'Description'>
    )> }
  )> }
);

export type PayrollFixedAllowanceManualEntryListingCountQueryVariables = Exact<{
  input: PayrollFixedAllowanceInput;
}>;


export type PayrollFixedAllowanceManualEntryListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PayrollFixedAllowanceManualEntryListingCount'>
);

export type PayrollProcessPeriodListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  IsViewHistory: Scalars['Boolean'];
}>;


export type PayrollProcessPeriodListingQuery = (
  { __typename?: 'Query' }
  & { PayrollProcessPeriodListing: Array<(
    { __typename?: 'PayrollProcessPeriodListingOutput' }
    & Pick<PayrollProcessPeriodListingOutput, 'PayPeriodID' | 'FromDate' | 'ToDate' | 'PeriodYearMonth' | 'LastProcessedDate' | 'PayrollProcessStatus' | 'TotalNetPay' | 'TotalEmployee' | 'ModifiedBy' | 'TotalPayrollCyclePeriodSetting' | 'LastInitiatedDate' | 'LastPayrollGLProcess'>
  )> }
);

export type IsMonthlyTrxInUseQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
}>;


export type IsMonthlyTrxInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsMonthlyTrxInUse'>
);

export type MonthlyTransactionByEmployeeQueryVariables = Exact<{
  MonthlyTransactionInput: MonthlyTransactionInput;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type MonthlyTransactionByEmployeeQuery = (
  { __typename?: 'Query' }
  & { MonthlyTransactionByEmployee: Array<(
    { __typename?: 'EmployeeTransactionOutput' }
    & Pick<EmployeeTransactionOutput, 'EmployeeID' | 'EmployeeNo' | 'EmployeeFullName' | 'TotalAmount' | 'TotalQuantity' | 'InUse'>
    & { PayItems?: Maybe<Array<(
      { __typename?: 'PayItemOutput' }
      & Pick<PayItemOutput, 'TransactionID' | 'PayItemID' | 'PayName' | 'Amount' | 'Quantity' | 'PayPeriod' | 'PayPeriodID' | 'PayrollCycle' | 'PayrollCycleID' | 'PayItemType'>
    )>> }
  )> }
);

export type MonthlyTransactionByEmployeeCountQueryVariables = Exact<{
  MonthlyTransactionInput: MonthlyTransactionInput;
}>;


export type MonthlyTransactionByEmployeeCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MonthlyTransactionByEmployeeCount'>
);

export type FreezepayrollListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type FreezepayrollListingQuery = (
  { __typename?: 'Query' }
  & { FreezepayrollListing: Array<(
    { __typename?: 'FreezePayrollEntity' }
    & Pick<FreezePayrollEntity, 'FreezePayrollID'>
    & { Employees?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'EmploymentType'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'Name'>
      )>, OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description' | 'Level'>
      )>, Division?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description' | 'DepartmentType' | 'ParentID' | 'DepartmentID'>
      )>, EmployeeDepartment?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description' | 'DepartmentType' | 'ParentID' | 'DepartmentID'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile'>
      )> }
    )> }
  )> }
);

export type Cp159ListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PeriodYear: Scalars['Float'];
}>;


export type Cp159ListingQuery = (
  { __typename?: 'Query' }
  & { CP159Listing: Array<(
    { __typename?: 'CP159Entity' }
    & Pick<Cp159Entity, 'CP159ID' | 'CompanyID' | 'PeriodYearMonth' | 'ReceiptNoPCB' | 'ReceiptDatePCB' | 'ReceiptNoCP38' | 'ReceiptDateCP38' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type EmployeeOutstandingSalaryListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
}>;


export type EmployeeOutstandingSalaryListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeOutstandingSalaryListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'JoinedDate' | 'FormStatus' | 'Status'>
    & { EmployeeBenefitInKind?: Maybe<Array<(
      { __typename?: 'EmployeeBenefitInKindEntity' }
      & Pick<EmployeeBenefitInKindEntity, 'EmployeeID' | 'EmployeeBenefitInKindID' | 'PaymentDetails' | 'IncomeAmount' | 'BIKDescription' | 'RefundUnapprovedFunds' | 'Status'>
    )>>, EmployeeSalaryLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'FromSalary' | 'ToSalary'>
    )>>, EmployeeCareerLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'SalaryType' | 'FromSalary' | 'ToSalary'>
    )>>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )> }
  )> }
);

export type Cp22CountQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ID: Array<Scalars['String']> | Scalars['String'];
}>;


export type Cp22CountQuery = (
  { __typename?: 'Query' }
  & { CP22Count: Array<(
    { __typename?: 'EmployeeCP22Entity' }
    & Pick<EmployeeCp22Entity, 'Status' | 'ModifiedBy' | 'ModifiedDT' | 'FormStatus' | 'CreatedDT' | 'CreatedBy' | 'EmployeeCP22ID' | 'EmployeeID'>
    & { UserLogs?: Maybe<(
      { __typename?: 'UserLogs' }
      & Pick<UserLogs, 'createdDT' | 'createdBy' | 'modifiedBy' | 'modifiedDT'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )> }
);

export type UpdatePayrollCalendarMutationVariables = Exact<{
  companyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayCycleIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdatePayrollCalendarMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdatePayrollCalendar'>
);

export type GenerateDefaultOrderforAllPayPeriodsMutationVariables = Exact<{
  companyID: Scalars['String'];
  PayPeriodIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GenerateDefaultOrderforAllPayPeriodsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GenerateDefaultOrderforAllPayPeriods'>
);

export type EmployeeSalaryDeleteMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
}>;


export type EmployeeSalaryDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeSalaryDelete'>
);

export type EmployeeSalaryInsertMutationVariables = Exact<{
  Input: CareerLogInput;
}>;


export type EmployeeSalaryInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeSalaryInsert'>
);

export type EmployeeSalaryUpdateMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  Input: CareerLogInput;
}>;


export type EmployeeSalaryUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeSalaryUpdate'>
);

export type EmployeeCp22ListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type EmployeeCp22ListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeCP22Listing: Array<(
    { __typename?: 'EmployeeCP22Output' }
    & Pick<EmployeeCp22Output, 'EmployeeCP22ID' | 'EmployeeID' | 'LHDNReportID' | 'Description' | 'ReportSequence' | 'Amount' | 'Remark'>
  )> }
);

export type EmployeeCp22UpdateMutationVariables = Exact<{
  Input: Array<EmployeeCp22Input> | EmployeeCp22Input;
}>;


export type EmployeeCp22UpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeCP22Update'>
);

export type EmployeeBenefitInKindListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type EmployeeBenefitInKindListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeBenefitInKindListing: Array<(
    { __typename?: 'EmployeeBenefitInKindEntity' }
    & Pick<EmployeeBenefitInKindEntity, 'EmployeeBenefitInKindID' | 'EmployeeID' | 'PeriodYear' | 'PaymentDetails' | 'GratuityFromDate' | 'GratuityToDate' | 'IncomeTypeA' | 'IncomeTypeB' | 'IncomeAmount' | 'BIKDescription' | 'MotorcarDate' | 'MotorcarYear' | 'MotorcarType' | 'MotorcarModel' | 'MotorcarPetrol' | 'MotorcarDriverProviderAmount' | 'HouseholdAmount' | 'TelephoneAmount' | 'RecreationalAmount' | 'GardenerAmount' | 'HouseholdServantAmount' | 'ValueOfLivingAddresss' | 'ValueOfLivingAmount' | 'RefundUnapprovedFunds' | 'CompensationEmployment' | 'Status' | 'PaymentAmount' | 'GratuityAmount' | 'FeeDescription' | 'FeeAmount' | 'ESOSDescription' | 'ESOSAmount' | 'TaxDescription' | 'TaxAmount'>
  )> }
);

export type EmployeeBenefitInKindInsertMutationVariables = Exact<{
  EmployeeBenefitInKindInput: EmployeeBenefitInKindInput;
}>;


export type EmployeeBenefitInKindInsertMutation = (
  { __typename?: 'Mutation' }
  & { EmployeeBenefitInKindInsert: (
    { __typename?: 'EmployeeBenefitInKindEntity' }
    & Pick<EmployeeBenefitInKindEntity, 'EmployeeBenefitInKindID'>
  ) }
);

export type EmployeeBenefitInKindUpdateMutationVariables = Exact<{
  EmployeeBenefitInKindID: Scalars['String'];
  EmployeeBenefitInKindInput: EmployeeBenefitInKindInput;
}>;


export type EmployeeBenefitInKindUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeBenefitInKindUpdate'>
);

export type EmployeeTaxReliefListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  PeriodYear: Scalars['Float'];
}>;


export type EmployeeTaxReliefListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeTaxReliefListing: Array<(
    { __typename?: 'EmployeeTaxReliefEntity' }
    & Pick<EmployeeTaxReliefEntity, 'EmployeeTaxReliefID' | 'TaxReliefID' | 'PayPeriodID' | 'ReliefAmount' | 'CreatedDT' | 'ModifiedDT'>
    & { PayPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PeriodYearMonth'>
    )>, TaxRelief?: Maybe<(
      { __typename?: 'TaxReliefEntity' }
      & Pick<TaxReliefEntity, 'TaxReliefID' | 'ReliefCode' | 'Description' | 'MaxAmount' | 'PeriodYear'>
    )> }
  )> }
);

export type TaxReliefListingDefaultExcludedQueryVariables = Exact<{
  PeriodYear: Scalars['Float'];
}>;


export type TaxReliefListingDefaultExcludedQuery = (
  { __typename?: 'Query' }
  & { TaxReliefListingDefaultExcluded: Array<(
    { __typename?: 'TaxReliefEntity' }
    & Pick<TaxReliefEntity, 'TaxReliefID' | 'ReliefCode' | 'Description' | 'PeriodYear' | 'MaxAmount'>
  )> }
);

export type StartPayPeriodListingByYearQueryVariables = Exact<{
  Year: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type StartPayPeriodListingByYearQuery = (
  { __typename?: 'Query' }
  & { StartPayPeriodListingByYear: Array<(
    { __typename?: 'PayPeriodEntity' }
    & Pick<PayPeriodEntity, 'PayPeriodID' | 'PeriodYearMonth'>
  )> }
);

export type EmployeeTaxReliefBulkInsertMutationVariables = Exact<{
  EmployeeTaxReliefInput: Array<EmployeeTaxReliefInput> | EmployeeTaxReliefInput;
}>;


export type EmployeeTaxReliefBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeTaxReliefBulkInsert'>
);

export type EmployeeTaxReliefBulkUpdateMutationVariables = Exact<{
  EmployeeTaxReliefInput: Array<EmployeeTaxReliefInput> | EmployeeTaxReliefInput;
}>;


export type EmployeeTaxReliefBulkUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeTaxReliefBulkUpdate'>
);

export type RecurringPayDeleteMutationVariables = Exact<{
  RecurringPayID: Scalars['String'];
}>;


export type RecurringPayDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RecurringPayDelete'>
);

export type RecurringPayInsertMutationVariables = Exact<{
  RecurringPayInput: Array<RecurringPayInput> | RecurringPayInput;
  DocumentsInput?: Maybe<DocumentsInput>;
  ExcelUpload: Scalars['Boolean'];
}>;


export type RecurringPayInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RecurringPayInsert'>
);

export type RecurringPayUpdateMutationVariables = Exact<{
  RecurringPayID: Scalars['String'];
  RecurringPayInput: RecurringPayInput;
}>;


export type RecurringPayUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RecurringPayUpdate'>
);

export type GetPayPeriodByYearQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PeriodYear: Scalars['Float'];
}>;


export type GetPayPeriodByYearQuery = (
  { __typename?: 'Query' }
  & { PayPeriodListing: Array<(
    { __typename?: 'PayPeriodEntity' }
    & Pick<PayPeriodEntity, 'PayPeriodID' | 'CompanyID' | 'PeriodYearMonth' | 'FromDate' | 'ToDate' | 'ActualWorkDays' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type PayrollFixedAllowanceDetailsQueryVariables = Exact<{
  RecurringPayID: Scalars['String'];
}>;


export type PayrollFixedAllowanceDetailsQuery = (
  { __typename?: 'Query' }
  & { PayrollFixedAllowanceDetails: Array<(
    { __typename?: 'RecurringPayEntity' }
    & Pick<RecurringPayEntity, 'PayrollCycleID' | 'RecurringPayID' | 'EmployeeID' | 'Remark' | 'Amount' | 'Quantity' | 'PayItemID'>
    & { PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayName' | 'PayItemType'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, StartPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PeriodYearMonth' | 'PayPeriodID'>
    )>, EndPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PeriodYearMonth'>
    )> }
  )> }
);

export type PayItemListingByTlaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
}>;


export type PayItemListingByTlaQuery = (
  { __typename?: 'Query' }
  & { PayItemListingByTLA: Array<(
    { __typename?: 'PayItemListingByTLAOutput' }
    & Pick<PayItemListingByTlaOutput, 'PayItemID' | 'PayName' | 'PayCode' | 'PayItemType' | 'EmployeeIds'>
  )> }
);

export type PayrollFixedAllowanceByExcelQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type PayrollFixedAllowanceByExcelQuery = (
  { __typename?: 'Query' }
  & { PayrollFixedAllowanceByExcel: Array<(
    { __typename?: 'ExcelPayItemTransactionOutput' }
    & Pick<ExcelPayItemTransactionOutput, 'ExcelFileName' | 'TotalEarning' | 'TotalNonPayable' | 'TotalDeduction' | 'UploadedDate' | 'DocumentID'>
  )> }
);

export type OneTimePayListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type OneTimePayListingQuery = (
  { __typename?: 'Query' }
  & { OneTimePayListing: Array<(
    { __typename?: 'OneTimePayEntity' }
    & Pick<OneTimePayEntity, 'OneTimePayID' | 'CompanyID' | 'EmployeeID' | 'PayItemID' | 'PayPeriodID' | 'PayrollCycleID' | 'Amount' | 'Quantity' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayItemID' | 'PayName' | 'PayCode' | 'PayItemType'>
    )>, PayPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PayPeriodID' | 'PeriodYearMonth' | 'FromDate' | 'ToDate' | 'ActualWorkDays'>
    )>, PayrollCycle?: Maybe<(
      { __typename?: 'PayrollCycleEntity' }
      & Pick<PayrollCycleEntity, 'PayrollCycleID' | 'Description'>
    )> }
  )> }
);

export type OneTimePayListingByPayPeriodAndPayCycleQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
}>;


export type OneTimePayListingByPayPeriodAndPayCycleQuery = (
  { __typename?: 'Query' }
  & { OneTimePayListingByPayPeriodAndPayCycle: Array<(
    { __typename?: 'OneTimePayEntity' }
    & Pick<OneTimePayEntity, 'OneTimePayID' | 'CompanyID' | 'EmployeeID' | 'PayItemID' | 'PayPeriodID' | 'PayrollCycleID' | 'Amount' | 'Quantity' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayItemID' | 'PayName' | 'PayCode' | 'PayItemType'>
    )>, PayPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PayPeriodID' | 'PeriodYearMonth' | 'FromDate' | 'ToDate' | 'ActualWorkDays'>
    )>, PayrollCycle?: Maybe<(
      { __typename?: 'PayrollCycleEntity' }
      & Pick<PayrollCycleEntity, 'PayrollCycleID' | 'Description'>
    )> }
  )> }
);

export type DeleteMonthlyTrxMutationVariables = Exact<{
  OneTimePayIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteMonthlyTrxMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteMonthlyTrx'>
);

export type OneTimePayInsertMutationVariables = Exact<{
  OneTimePayInput: Array<OneTimePayInput> | OneTimePayInput;
  DocumentsInput?: Maybe<DocumentsInput>;
}>;


export type OneTimePayInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OneTimePayInsert'>
);

export type OneTimePayUpdateMutationVariables = Exact<{
  OneTimePayInput: Array<OneTimePayInput> | OneTimePayInput;
}>;


export type OneTimePayUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OneTimePayUpdate'>
);

export type MonthlyTransactionByPayItemQueryVariables = Exact<{
  PayItemFilterInput: PayItemFilterInput;
}>;


export type MonthlyTransactionByPayItemQuery = (
  { __typename?: 'Query' }
  & { MonthlyTransactionByPayItem: Array<(
    { __typename?: 'PayItemTransactionOutput' }
    & Pick<PayItemTransactionOutput, 'PayName' | 'PayItemID' | 'PayrollCycle' | 'PayrollCycleID' | 'TotalAmount' | 'TotalQuantity' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT'>
    & { Employees?: Maybe<Array<(
      { __typename?: 'EmployeeTransactionOutput' }
      & Pick<EmployeeTransactionOutput, 'EmployeeID' | 'EmployeeNo' | 'EmployeeFullName' | 'CreatedDT' | 'InUse'>
      & { PayItems?: Maybe<Array<(
        { __typename?: 'PayItemOutput' }
        & Pick<PayItemOutput, 'TransactionID' | 'PayItemID' | 'PayName' | 'Amount' | 'Quantity' | 'PayPeriod' | 'PayPeriodID' | 'PayrollCycle' | 'PayrollCycleID' | 'PayItemType'>
      )>> }
    )>> }
  )> }
);

export type MonthlyTransactionByPayItemWithinPeriodQueryVariables = Exact<{
  PayItemFilterInput: PayItemFilterInput;
}>;


export type MonthlyTransactionByPayItemWithinPeriodQuery = (
  { __typename?: 'Query' }
  & { MonthlyTransactionByPayItem: Array<(
    { __typename?: 'PayItemTransactionOutput' }
    & Pick<PayItemTransactionOutput, 'PayName' | 'IsAllProcessed' | 'PayItemID' | 'TotalAmount' | 'TotalQuantity' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT'>
    & { Employees?: Maybe<Array<(
      { __typename?: 'EmployeeTransactionOutput' }
      & { PayItems?: Maybe<Array<(
        { __typename?: 'PayItemOutput' }
        & Pick<PayItemOutput, 'TransactionID' | 'PayrollCycleID' | 'PayrollCycle' | 'PayItemType'>
      )>> }
    )>> }
  )> }
);

export type MonthlyTransactionByPayItemSelectedQueryVariables = Exact<{
  PayItemFilterInput: PayItemFilterInput;
}>;


export type MonthlyTransactionByPayItemSelectedQuery = (
  { __typename?: 'Query' }
  & { MonthlyTransactionByPayItem: Array<(
    { __typename?: 'PayItemTransactionOutput' }
    & { Employees?: Maybe<Array<(
      { __typename?: 'EmployeeTransactionOutput' }
      & Pick<EmployeeTransactionOutput, 'EmployeeID' | 'EmployeeNo' | 'EmployeeFullName' | 'IsProcessed'>
      & { PayItems?: Maybe<Array<(
        { __typename?: 'PayItemOutput' }
        & Pick<PayItemOutput, 'TransactionID' | 'Amount' | 'Quantity' | 'PayrollCycleID'>
      )>> }
    )>> }
  )> }
);

export type PayrollProcessingPayItemListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type PayrollProcessingPayItemListingQuery = (
  { __typename?: 'Query' }
  & { PayrollProcessingPayItemListing: Array<(
    { __typename?: 'PayItemEntity' }
    & Pick<PayItemEntity, 'PayItemID' | 'CompanyID' | 'PayName' | 'PayItemType' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type MonthlyTransactionByExcelQueryVariables = Exact<{
  MonthlyTransactionInput: MonthlyTransactionInput;
}>;


export type MonthlyTransactionByExcelQuery = (
  { __typename?: 'Query' }
  & { MonthlyTransactionByExcel: Array<(
    { __typename?: 'ExcelPayItemTransactionOutput' }
    & Pick<ExcelPayItemTransactionOutput, 'ExcelFileName' | 'PayPeriod' | 'PayrollCycle' | 'TotalEarning' | 'TotalDeduction' | 'TotalNonPayable' | 'UploadedDate' | 'DocumentID'>
  )> }
);

export type FreezePayrollInsertMutationVariables = Exact<{
  input: FreezePayrollInput;
}>;


export type FreezePayrollInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FreezePayrollInsert'>
);

export type EmployeeFreezePayrollDeleteMutationVariables = Exact<{
  FreezePayrollID: Scalars['String'];
}>;


export type EmployeeFreezePayrollDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeFreezePayrollDelete'>
);

export type DeletePayrollProcessMutationVariables = Exact<{
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type DeletePayrollProcessMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeletePayrollProcess'>
);

export type PayrollProcessPayItemListingQueryVariables = Exact<{
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type PayrollProcessPayItemListingQuery = (
  { __typename?: 'Query' }
  & { PayrollProcessPayItemListing: (
    { __typename?: 'PayrollProcessPayItemListingOutput' }
    & Pick<PayrollProcessPayItemListingOutput, 'PayPeriodID' | 'PayrollCycleID' | 'Description' | 'PeriodYearMonth'>
    & { EarningPayItem?: Maybe<Array<(
      { __typename?: 'PayrollProcessPayItemOutput' }
      & Pick<PayrollProcessPayItemOutput, 'PayItemID' | 'PayName' | 'PayItemType'>
    )>>, DeductionPayItem?: Maybe<Array<(
      { __typename?: 'PayrollProcessPayItemOutput' }
      & Pick<PayrollProcessPayItemOutput, 'PayItemID' | 'PayName' | 'PayItemType'>
    )>>, NonPayablePayItem?: Maybe<Array<(
      { __typename?: 'PayrollProcessPayItemOutput' }
      & Pick<PayrollProcessPayItemOutput, 'PayItemID' | 'PayName' | 'PayItemType'>
    )>>, OvertimePayItem?: Maybe<Array<(
      { __typename?: 'PayrollProcessPayItemOutput' }
      & Pick<PayrollProcessPayItemOutput, 'PayItemID' | 'PayName' | 'PayItemType'>
    )>> }
  ) }
);

export type PayrollProcessingPayItemListing2QueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
}>;


export type PayrollProcessingPayItemListing2Query = (
  { __typename?: 'Query' }
  & { PayrollProcessingPayItemListing2: Array<(
    { __typename?: 'PayItemEntity' }
    & Pick<PayItemEntity, 'PayItemID' | 'CompanyID' | 'PayName' | 'PayItemType' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type PayrollProcessEmployeeListingQueryVariables = Exact<{
  Input: EmployeeFilterInput;
}>;


export type PayrollProcessEmployeeListingQuery = (
  { __typename?: 'Query' }
  & { PayrollProcessEmployeeListing: Array<(
    { __typename?: 'PayrollProcessEmployeeListingOutput' }
    & Pick<PayrollProcessEmployeeListingOutput, 'EmployeeID' | 'JobGradeID' | 'OrgUnitID' | 'FormStatus' | 'EmployeeNo' | 'EmployeeName' | 'Designation' | 'IsChecked' | 'Status' | 'EmploymentType'>
  )> }
);

export type CalculatePayrollQueryVariables = Exact<{
  PayrollCalculationInput: PayrollCalculationInput;
}>;


export type CalculatePayrollQuery = (
  { __typename?: 'Query' }
  & { CalculatePayroll: (
    { __typename?: 'PayrollCalculationOutput' }
    & Pick<PayrollCalculationOutput, 'Result' | 'TotalRecord'>
    & { Entity?: Maybe<Array<(
      { __typename?: 'PayrollHeaderEntity' }
      & Pick<PayrollHeaderEntity, 'EPFPaid' | 'EISPaid' | 'EPFVEPaid' | 'EmployeeID' | 'NetPay' | 'CreatedDT' | 'CreatedBy' | 'TaxRelief' | 'ModifiedDT' | 'ModifiedBy' | 'PayPeriodID' | 'TotalDeduction' | 'ProcessLog' | 'PayrollCycleID' | 'PayrollHeaderID' | 'RequiredWorkDay' | 'TotalChargableTax' | 'TotalChargableEPF' | 'TotalChargableEIS' | 'TotalChargableHRDF' | 'TotalChargableEPFVE' | 'TotalChargableSocso' | 'TotalChargableBonus' | 'TotalChargableAddRemuneration'>
      & { Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID'>
      )>, PayPeriod?: Maybe<(
        { __typename?: 'PayPeriodEntity' }
        & Pick<PayPeriodEntity, 'PayPeriodID'>
      )>, PayrollCycle?: Maybe<(
        { __typename?: 'PayrollCycleEntity' }
        & Pick<PayrollCycleEntity, 'PayrollCycleID'>
      )> }
    )>>, Listing?: Maybe<Array<(
      { __typename?: 'PayrollCalculationItemOutput' }
      & Pick<PayrollCalculationItemOutput, 'ActualWorkday' | 'Tax1' | 'Tax2' | 'TaxZakat' | 'TaxRelief' | 'TaxBasicSalary' | 'TotalDeduction' | 'TotalChargableTax' | 'TotalChargableEPF' | 'TotalChargableEIS' | 'TotalChargableTax1' | 'TotalChargableTax2' | 'TotalChargableHRDF' | 'TotalEarningPayItem' | 'TotalChargableEPFVE' | 'TotalChargableSocso' | 'TotalChargableBonus' | 'TotalDeductionPayItem' | 'TotalNonPayablePayItem' | 'TotalChargableAddRemuneration' | 'Zakat' | 'NetSalary'>
    )>> }
  ) }
);

export type PayPeriodDetailsQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
}>;


export type PayPeriodDetailsQuery = (
  { __typename?: 'Query' }
  & { PayPeriodDetails: (
    { __typename?: 'PayPeriodEntity' }
    & Pick<PayPeriodEntity, 'PayPeriodID' | 'CompanyID' | 'PeriodYearMonth' | 'FromDate' | 'ToDate' | 'ActualWorkDays'>
  ) }
);

export type DeletePayrollHistoryMutationVariables = Exact<{
  PayrollHeaderID: Scalars['String'];
}>;


export type DeletePayrollHistoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeletePayrollHistory'>
);

export type ViewPayrollHistoryDetailsQueryVariables = Exact<{
  input: ViewPayrollHistoryInput;
}>;


export type ViewPayrollHistoryDetailsQuery = (
  { __typename?: 'Query' }
  & { ViewPayrollHistory: Array<(
    { __typename?: 'PayrollHeaderEntity' }
    & Pick<PayrollHeaderEntity, 'PayrollHeaderID' | 'EmployeeID' | 'TotalChargableTax' | 'TotalGross' | 'TotalDeduction' | 'ProcessLog' | 'NetPay' | 'Status' | 'CompanyID'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo' | 'Status'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, PayrollCycle?: Maybe<(
      { __typename?: 'PayrollCycleEntity' }
      & Pick<PayrollCycleEntity, 'Description' | 'Status'>
    )>, PayPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PeriodYearMonth' | 'Status'>
    )>, PayrollDetails: Array<(
      { __typename?: 'PayrollDetailEntity' }
      & Pick<PayrollDetailEntity, 'PayrollDetailID' | 'PayItemID' | 'TotalAmount'>
      & { PayItem: (
        { __typename?: 'PayItemEntity' }
        & Pick<PayItemEntity, 'PayName' | 'PayCode' | 'PayItemType' | 'Status'>
      ) }
    )> }
  )> }
);

export type ViewPayrollHistoryDetailsMyPayrollInfoQueryVariables = Exact<{
  input: ViewPayrollHistoryInput;
}>;


export type ViewPayrollHistoryDetailsMyPayrollInfoQuery = (
  { __typename?: 'Query' }
  & { ViewPayrollHistoryMyPayrollInfo: Array<(
    { __typename?: 'PayrollHeaderEntity' }
    & Pick<PayrollHeaderEntity, 'PayrollHeaderID' | 'EmployeeID' | 'TotalGross' | 'TotalDeduction' | 'ProcessLog' | 'NetPay' | 'Status' | 'CompanyID'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo' | 'Status'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, PayrollCycle?: Maybe<(
      { __typename?: 'PayrollCycleEntity' }
      & Pick<PayrollCycleEntity, 'Description' | 'Status'>
    )>, PayPeriod?: Maybe<(
      { __typename?: 'PayPeriodEntity' }
      & Pick<PayPeriodEntity, 'PeriodYearMonth' | 'Status'>
    )>, PayrollDetails: Array<(
      { __typename?: 'PayrollDetailEntity' }
      & Pick<PayrollDetailEntity, 'PayrollDetailID' | 'PayItemID' | 'TotalAmount'>
      & { PayItem: (
        { __typename?: 'PayItemEntity' }
        & Pick<PayItemEntity, 'PayName' | 'PayCode' | 'PayItemType' | 'Status'>
      ) }
    )> }
  )> }
);

export type UpdatePayrollProcessStatusMutationVariables = Exact<{
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayrollCycleStatus: Scalars['String'];
  CreatedBy: Scalars['String'];
  ModifiedBy: Scalars['String'];
}>;


export type UpdatePayrollProcessStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdatePayrollProcessStatus'>
);

export type UnPublishEaMutationVariables = Exact<{
  PublishEAID: Scalars['String'];
}>;


export type UnPublishEaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnPublishEA'>
);

export type Cp159UpdateMutationVariables = Exact<{
  CP159Input: Cp159Input;
}>;


export type Cp159UpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CP159Update'>
);

export type GlAccountMappingListQueryVariables = Exact<{
  GLAccountHeaderID?: Maybe<Scalars['String']>;
}>;


export type GlAccountMappingListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GLAccountMappingList'>
);

export type AccountMappingInsertMutationVariables = Exact<{
  HeaderInput: GlAccountHeaderInput;
  DetailInput: Array<GlAccountDetailInput> | GlAccountDetailInput;
}>;


export type AccountMappingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AccountMappingInsert'>
);

export type AccountMappingUpdateMutationVariables = Exact<{
  HeaderInput: GlAccountHeaderInput;
  DetailInput: Array<GlAccountDetailInput> | GlAccountDetailInput;
}>;


export type AccountMappingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AccountMappingUpdate'>
);

export type AccountMappingDeleteMutationVariables = Exact<{
  GLAccountHeaderID: Scalars['String'];
}>;


export type AccountMappingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AccountMappingDelete'>
);

export type GlAccountHeaderListQueryVariables = Exact<{ [key: string]: never; }>;


export type GlAccountHeaderListQuery = (
  { __typename?: 'Query' }
  & { GLAccountHeaderList: Array<(
    { __typename?: 'GLAccountHeaderEntity' }
    & Pick<GlAccountHeaderEntity, 'ID' | 'Title' | 'EnableStaffCategory' | 'ModifiedDT'>
  )> }
);

export type GlAccountHeaderListWithDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GlAccountHeaderListWithDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GLAccountHeaderListWithDetails'>
);

export type SpecificGlAccountHeaderListWithDetailsQueryVariables = Exact<{
  GLAccountHeaderID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AllPayCycles?: Maybe<Scalars['Boolean']>;
  Type?: Maybe<Scalars['String']>;
}>;


export type SpecificGlAccountHeaderListWithDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GLAccountHeaderListWithDetails'>
);

export type GetAccountMappingAssignmentQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetAccountMappingAssignmentQuery = (
  { __typename?: 'Query' }
  & { GetAccountMappingAssignment: (
    { __typename?: 'GLAccountAssignmentEntity' }
    & Pick<GlAccountAssignmentEntity, 'ID' | 'CompanyID' | 'GLAccountHeaderID'>
    & { GLAccountHeader?: Maybe<(
      { __typename?: 'GLAccountHeaderEntity' }
      & Pick<GlAccountHeaderEntity, 'Title'>
    )> }
  ) }
);

export type AccountMappingAssignmentInsertMutationVariables = Exact<{
  HeaderInput: GlAccountAssignmentInput;
}>;


export type AccountMappingAssignmentInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AccountMappingAssignmentInsert'>
);

export type AccountMappingAssignmentUpdateMutationVariables = Exact<{
  HeaderInput: GlAccountAssignmentInput;
}>;


export type AccountMappingAssignmentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AccountMappingAssignmentUpdate'>
);

export type PayItemAmountByOrgAndPayPeriodQueryVariables = Exact<{
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayrollCycleID?: Maybe<Scalars['String']>;
  AllPayCycles?: Maybe<Scalars['Boolean']>;
}>;


export type PayItemAmountByOrgAndPayPeriodQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PayItemAmountByOrgAndPayPeriod'>
);

export type ViewPayrollHistoryByEmployeeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type ViewPayrollHistoryByEmployeeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ViewPayrollHistoryByEmployee'>
);

export type SalaryListingByExcelQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type SalaryListingByExcelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SalaryListingByExcel'>
);

export type SalaryBatchMutationVariables = Exact<{
  input: Array<CareerLogInput> | CareerLogInput;
  DocumentsInput?: Maybe<DocumentsInput>;
}>;


export type SalaryBatchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SalaryBatch'>
);

export type SalaryListingByPeriodQueryVariables = Exact<{
  StartMonth: Scalars['DateTime'];
  EndMonth: Scalars['DateTime'];
  CompanyID: Scalars['String'];
}>;


export type SalaryListingByPeriodQuery = (
  { __typename?: 'Query' }
  & { SalaryListingByPeriod: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'Status' | 'excelfilename' | 'SalaryAdjustmentAmount' | 'EmployeeID' | 'ToCompany' | 'ToSalary' | 'FromSalary' | 'Remark' | 'Reason' | 'CreatedDT' | 'EffectiveDate'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )> }
  )> }
);

export type CheckOldDataForSalaryBatchQueryVariables = Exact<{
  Data?: Maybe<Array<Scalars['JSON']> | Scalars['JSON']>;
}>;


export type CheckOldDataForSalaryBatchQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CheckOldDataForSalaryBatch'>
);

export type CheckOldDataForOneTimePayInsertQueryVariables = Exact<{
  Data?: Maybe<Array<Scalars['JSON']> | Scalars['JSON']>;
  PayPeriodID?: Maybe<Scalars['String']>;
}>;


export type CheckOldDataForOneTimePayInsertQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CheckOldDataForOneTimePayInsert'>
);

export type CheckOldDataForRecurringPayInsertQueryVariables = Exact<{
  Data?: Maybe<Array<Scalars['JSON']> | Scalars['JSON']>;
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type CheckOldDataForRecurringPayInsertQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CheckOldDataForRecurringPayInsert'>
);

export type StatutoryAdjustmentListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StatutoryAdjustmentListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'StatutoryAdjustmentListing'>
);

export type StatutoryAdjFormEmpListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  ExcludeEmployeeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StatutoryAdjFormEmpListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'StatutoryAdjFormEmpListing'>
);

export type UpdateStatutoryAdjustmentMutationVariables = Exact<{
  StatutoryInput: StatutoryAdjustmentInput;
  PayrollHeaderInput: Scalars['JSON'];
  PayrollDetailInputs?: Maybe<Array<Scalars['JSON']> | Scalars['JSON']>;
}>;


export type UpdateStatutoryAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateStatutoryAdjustment'>
);

export type DeleteStatutoryAdjustmentMutationVariables = Exact<{
  PayrollHeaderID: Scalars['String'];
}>;


export type DeleteStatutoryAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteStatutoryAdjustment'>
);

export type IsPayrollCycleLockedQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
}>;


export type IsPayrollCycleLockedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsPayrollCycleLocked'>
);

export type PerformanceMenuSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type PerformanceMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PerformanceMenuSummary'>
);

export type FormItemRatingListingQueryVariables = Exact<{ [key: string]: never; }>;


export type FormItemRatingListingQuery = (
  { __typename?: 'Query' }
  & { FormItemRatingListing: Array<(
    { __typename?: 'FormItemRatingEntity' }
    & Pick<FormItemRatingEntity, 'ID' | 'Title' | 'InUse' | 'RatingScaleTable'>
  )> }
);

export type FormItemRatingDetailsQueryVariables = Exact<{
  FormItemRatingID: Scalars['String'];
}>;


export type FormItemRatingDetailsQuery = (
  { __typename?: 'Query' }
  & { FormItemRatingDetails: (
    { __typename?: 'FormItemRatingEntity' }
    & Pick<FormItemRatingEntity, 'ID' | 'Title' | 'To' | 'From' | 'OrderType' | 'RatingScaleTable'>
  ) }
);

export type IsFormItemRatingTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  FormItemRatingID?: Maybe<Scalars['String']>;
}>;


export type IsFormItemRatingTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsFormItemRatingTitleExist'>
);

export type FormItemRatingSubmitMutationVariables = Exact<{
  input: FormItemRatingInput;
}>;


export type FormItemRatingSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FormItemRatingSubmit'>
);

export type FormItemRatingDeleteMutationVariables = Exact<{
  FormItemRatingID: Scalars['String'];
}>;


export type FormItemRatingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FormItemRatingDelete'>
);

export type ReviewGradingTableListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewGradingTableListingQuery = (
  { __typename?: 'Query' }
  & { ReviewGradingTableListing: Array<(
    { __typename?: 'ReviewGradingTableEntity' }
    & Pick<ReviewGradingTableEntity, 'ID' | 'Title' | 'InUse' | 'GradingTable'>
  )> }
);

export type ReviewGradingTableDetailsQueryVariables = Exact<{
  ReviewGradingTableID: Scalars['String'];
}>;


export type ReviewGradingTableDetailsQuery = (
  { __typename?: 'Query' }
  & { ReviewGradingTableDetails: (
    { __typename?: 'ReviewGradingTableEntity' }
    & Pick<ReviewGradingTableEntity, 'ID' | 'Title' | 'GradingTable'>
  ) }
);

export type IsReviewGradingTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReviewGradingTableID?: Maybe<Scalars['String']>;
}>;


export type IsReviewGradingTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewGradingTitleExist'>
);

export type ReviewGradingTableSubmitMutationVariables = Exact<{
  input: ReviewGradingTableInput;
}>;


export type ReviewGradingTableSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewGradingTableSubmit'>
);

export type ReviewGradingTableDeleteMutationVariables = Exact<{
  ReviewGradingTableID: Scalars['String'];
}>;


export type ReviewGradingTableDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewGradingTableDelete'>
);

export type ReviewPolicyListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewPolicyListingQuery = (
  { __typename?: 'Query' }
  & { ReviewPolicyListing: Array<(
    { __typename?: 'ReviewPolicyEntity' }
    & Pick<ReviewPolicyEntity, 'ID' | 'Title' | 'InUse' | 'ModifiedDT'>
  )> }
);

export type ReviewPolicyDetailsQueryVariables = Exact<{
  ReviewPolicyID: Scalars['String'];
}>;


export type ReviewPolicyDetailsQuery = (
  { __typename?: 'Query' }
  & { ReviewPolicyDetails: (
    { __typename?: 'ReviewPolicyEntity' }
    & Pick<ReviewPolicyEntity, 'ID' | 'Title' | 'ReviewPolicyDetails'>
    & { ReviewGrading: (
      { __typename?: 'ReviewGradingTableEntity' }
      & Pick<ReviewGradingTableEntity, 'ID' | 'Title'>
    ) }
  ) }
);

export type IsReviewPolicyTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReviewPolicyID?: Maybe<Scalars['String']>;
}>;


export type IsReviewPolicyTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewPolicyTitleExist'>
);

export type ReviewPolicySubmitMutationVariables = Exact<{
  input: ReviewPolicyInput;
}>;


export type ReviewPolicySubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewPolicySubmit'>
);

export type ReviewPolicyDeleteMutationVariables = Exact<{
  ReviewPolicyID: Scalars['String'];
}>;


export type ReviewPolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewPolicyDelete'>
);

export type KpiCategoryListingQueryVariables = Exact<{ [key: string]: never; }>;


export type KpiCategoryListingQuery = (
  { __typename?: 'Query' }
  & { KpiCategoryListing: Array<(
    { __typename?: 'KpiCategoryEntity' }
    & Pick<KpiCategoryEntity, 'ID' | 'Title' | 'InUse' | 'ModifiedDT' | 'PredefinedItemCount'>
  )> }
);

export type KpiCategoryDetailsQueryVariables = Exact<{
  KpiCategoryID: Scalars['String'];
}>;


export type KpiCategoryDetailsQuery = (
  { __typename?: 'Query' }
  & { KpiCategoryDetails: (
    { __typename?: 'KpiCategoryEntity' }
    & Pick<KpiCategoryEntity, 'ID' | 'Title' | 'PredefinedItemCount'>
  ) }
);

export type IsKpiCategoryTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  KpiCategoryID?: Maybe<Scalars['String']>;
}>;


export type IsKpiCategoryTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsKpiCategoryTitleExist'>
);

export type KpiCategorySubmitMutationVariables = Exact<{
  input: KpiCategoryInput;
}>;


export type KpiCategorySubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiCategorySubmit'>
);

export type KpiCategoryDeleteMutationVariables = Exact<{
  KpiCategoryID: Scalars['String'];
}>;


export type KpiCategoryDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiCategoryDelete'>
);

export type KpiCategoryListingWithItemCountQueryVariables = Exact<{ [key: string]: never; }>;


export type KpiCategoryListingWithItemCountQuery = (
  { __typename?: 'Query' }
  & { KpiCategoryListing: Array<(
    { __typename?: 'KpiCategoryEntity' }
    & Pick<KpiCategoryEntity, 'ID' | 'Title' | 'PredefinedItemCount'>
  )> }
);

export type KpiPredefinedItemListingQueryVariables = Exact<{
  KpiCategoryID: Scalars['String'];
}>;


export type KpiPredefinedItemListingQuery = (
  { __typename?: 'Query' }
  & { KpiPredefinedItemListing: Array<(
    { __typename?: 'KpiPredefinedItemEntity' }
    & Pick<KpiPredefinedItemEntity, 'ID' | 'Title' | 'Targets' | 'Initiatives' | 'InUse' | 'Status'>
  )> }
);

export type KpiPredefinedItemDetailsQueryVariables = Exact<{
  KpiPredefinedItemID: Scalars['String'];
}>;


export type KpiPredefinedItemDetailsQuery = (
  { __typename?: 'Query' }
  & { KpiPredefinedItemDetails: (
    { __typename?: 'KpiPredefinedItemEntity' }
    & Pick<KpiPredefinedItemEntity, 'ID' | 'Title' | 'Targets' | 'Initiatives'>
    & { FormItemRating: (
      { __typename?: 'FormItemRatingEntity' }
      & Pick<FormItemRatingEntity, 'ID' | 'Title'>
    ) }
  ) }
);

export type IsKpiPredefinedItemTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  KpiCategoryID: Scalars['String'];
  KpiPredefinedItemID?: Maybe<Scalars['String']>;
}>;


export type IsKpiPredefinedItemTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsKpiPredefinedItemTitleExist'>
);

export type KpiPredefinedItemSubmitMutationVariables = Exact<{
  input: KpiPredefinedItemInput;
}>;


export type KpiPredefinedItemSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiPredefinedItemSubmit'>
);

export type KpiPredefinedItemUpdateStatusMutationVariables = Exact<{
  input: KpiActiveInactiveInput;
}>;


export type KpiPredefinedItemUpdateStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiPredefinedItemUpdateStatus'>
);

export type KpiPredefinedItemDeleteMutationVariables = Exact<{
  KpiPredefinedItemID: Scalars['String'];
}>;


export type KpiPredefinedItemDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiPredefinedItemDelete'>
);

export type KpiPredefinedTemplateListingQueryVariables = Exact<{ [key: string]: never; }>;


export type KpiPredefinedTemplateListingQuery = (
  { __typename?: 'Query' }
  & { KpiPredefinedTemplateListing: Array<(
    { __typename?: 'KpiPredefinedTemplateEntity' }
    & Pick<KpiPredefinedTemplateEntity, 'ID' | 'Title' | 'InUse' | 'CategoryCount' | 'ItemCount' | 'ModifiedDT'>
  )> }
);

export type KpiPredefinedTemplateDetailsQueryVariables = Exact<{
  KpiPredefinedTemplateID: Scalars['String'];
}>;


export type KpiPredefinedTemplateDetailsQuery = (
  { __typename?: 'Query' }
  & { KpiPredefinedTemplateDetails: (
    { __typename?: 'KpiPredefinedTemplateEntity' }
    & Pick<KpiPredefinedTemplateEntity, 'ID' | 'Title' | 'Description' | 'WeightageType' | 'KpiWeightage'>
  ) }
);

export type IsKpiPredefinedTemplateTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  KpiPredefinedTemplateID?: Maybe<Scalars['String']>;
}>;


export type IsKpiPredefinedTemplateTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsKpiPredefinedTemplateTitleExist'>
);

export type KpiPredefinedTemplateSubmitMutationVariables = Exact<{
  input: KpiPredefinedTemplateInput;
}>;


export type KpiPredefinedTemplateSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiPredefinedTemplateSubmit'>
);

export type KpiPredefinedTemplateDeleteMutationVariables = Exact<{
  KpiPredefinedTemplateID: Scalars['String'];
}>;


export type KpiPredefinedTemplateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KpiPredefinedTemplateDelete'>
);

export type ReviewItemListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewItemListingQuery = (
  { __typename?: 'Query' }
  & { ReviewItemListing: Array<(
    { __typename?: 'ReviewItemEntity' }
    & Pick<ReviewItemEntity, 'ID' | 'Description' | 'Title' | 'InUse' | 'ApplicableTo' | 'Code'>
  )> }
);

export type ReviewItemDetailsQueryVariables = Exact<{
  ReviewItemID: Scalars['String'];
}>;


export type ReviewItemDetailsQuery = (
  { __typename?: 'Query' }
  & { ReviewItemDetails: (
    { __typename?: 'ReviewItemEntity' }
    & Pick<ReviewItemEntity, 'ID' | 'Title' | 'Code' | 'Description' | 'ItemRatingMethod' | 'ApplicableTo'>
    & { FormItemRating?: Maybe<(
      { __typename?: 'FormItemRatingEntity' }
      & Pick<FormItemRatingEntity, 'ID' | 'Title'>
    )> }
  ) }
);

export type IsReviewItemCodeExistQueryVariables = Exact<{
  Code: Scalars['String'];
  ReviewItemID?: Maybe<Scalars['String']>;
}>;


export type IsReviewItemCodeExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewItemCodeExist'>
);

export type ReviewItemSubmitMutationVariables = Exact<{
  input: ReviewItemInput;
}>;


export type ReviewItemSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewItemSubmit'>
);

export type ReviewItemDeleteMutationVariables = Exact<{
  ReviewItemID: Scalars['String'];
}>;


export type ReviewItemDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewItemDelete'>
);

export type ReviewTemplateListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewTemplateListingQuery = (
  { __typename?: 'Query' }
  & { ReviewTemplateListing: Array<(
    { __typename?: 'ReviewTemplateEntity' }
    & Pick<ReviewTemplateEntity, 'ID' | 'Title' | 'InUse' | 'ItemCount' | 'ModifiedDT'>
  )> }
);

export type ReviewTemplateDetailsQueryVariables = Exact<{
  ReviewTemplateID: Scalars['String'];
}>;


export type ReviewTemplateDetailsQuery = (
  { __typename?: 'Query' }
  & { ReviewTemplateDetails: (
    { __typename?: 'ReviewTemplateEntity' }
    & Pick<ReviewTemplateEntity, 'ID' | 'Title' | 'Description' | 'ApplicableTo' | 'WeightageType' | 'ReviewItemList'>
  ) }
);

export type IsReviewTemplateTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReviewTemplateID?: Maybe<Scalars['String']>;
}>;


export type IsReviewTemplateTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewTemplateTitleExist'>
);

export type ReviewTemplateSubmitMutationVariables = Exact<{
  input: ReviewTemplateInput;
}>;


export type ReviewTemplateSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewTemplateSubmit'>
);

export type ReviewTemplateDeleteMutationVariables = Exact<{
  ReviewTemplateID: Scalars['String'];
}>;


export type ReviewTemplateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewTemplateDelete'>
);

export type ReviewProcessPolicyListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewProcessPolicyListingQuery = (
  { __typename?: 'Query' }
  & { ReviewProcessPolicyListing: Array<(
    { __typename?: 'ReviewProcessPolicyEntity' }
    & Pick<ReviewProcessPolicyEntity, 'ID' | 'Title' | 'InUse' | 'ModifiedDT'>
  )> }
);

export type ReviewProcessPolicyDetailsQueryVariables = Exact<{
  ReviewProcessPolicyID: Scalars['String'];
}>;


export type ReviewProcessPolicyDetailsQuery = (
  { __typename?: 'Query' }
  & { ReviewProcessPolicyDetails: (
    { __typename?: 'ReviewProcessPolicyEntity' }
    & Pick<ReviewProcessPolicyEntity, 'ID' | 'Title' | 'ApprovalList'>
  ) }
);

export type IsReviewProcessPolicyTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReviewProcessPolicyID?: Maybe<Scalars['String']>;
}>;


export type IsReviewProcessPolicyTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewProcessPolicyTitleExist'>
);

export type ReviewProcessPolicySubmitMutationVariables = Exact<{
  input: ReviewProcessPolicyInput;
}>;


export type ReviewProcessPolicySubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewProcessPolicySubmit'>
);

export type ReviewProcessPolicyDeleteMutationVariables = Exact<{
  ReviewProcessPolicyID: Scalars['String'];
}>;


export type ReviewProcessPolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewProcessPolicyDelete'>
);

export type ReviewFormTemplateListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewFormTemplateListingQuery = (
  { __typename?: 'Query' }
  & { ReviewFormTemplateListing: Array<(
    { __typename?: 'ReviewFormTemplateEntity' }
    & Pick<ReviewFormTemplateEntity, 'ID' | 'Title' | 'InUse' | 'ModifiedDT'>
  )> }
);

export type ReviewFormTemplateDetailsQueryVariables = Exact<{
  ReviewFormTemplateID: Scalars['String'];
}>;


export type ReviewFormTemplateDetailsQuery = (
  { __typename?: 'Query' }
  & { ReviewFormTemplateDetails: (
    { __typename?: 'ReviewFormTemplateEntity' }
    & Pick<ReviewFormTemplateEntity, 'ID' | 'Title' | 'Description' | 'WeightageType' | 'ReviewType' | 'ApplicableTo'>
    & { ReviewProcessPolicy?: Maybe<(
      { __typename?: 'ApprovalPolicyEntity' }
      & Pick<ApprovalPolicyEntity, 'Title' | 'ID'>
    )>, ReviewSection: Array<(
      { __typename?: 'ReviewSectionEntity' }
      & Pick<ReviewSectionEntity, 'ID' | 'Title' | 'Type' | 'Weightage' | 'KpiSource' | 'Description'>
      & { KpiPredefinedTemplate?: Maybe<(
        { __typename?: 'KpiPredefinedTemplateEntity' }
        & Pick<KpiPredefinedTemplateEntity, 'ID' | 'Title'>
      )>, FormItemRating?: Maybe<(
        { __typename?: 'FormItemRatingEntity' }
        & Pick<FormItemRatingEntity, 'ID' | 'Title'>
      )>, ReviewTemplate?: Maybe<(
        { __typename?: 'ReviewTemplateEntity' }
        & Pick<ReviewTemplateEntity, 'ID' | 'Title'>
      )> }
    )> }
  ) }
);

export type IsReviewFormTemplateTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReviewFormTemplateID?: Maybe<Scalars['String']>;
}>;


export type IsReviewFormTemplateTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewFormTemplateTitleExist'>
);

export type ReviewFormTemplateSubmitMutationVariables = Exact<{
  input: ReviewFormTemplateInput;
}>;


export type ReviewFormTemplateSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewFormTemplateSubmit'>
);

export type ReviewFormTemplateDeleteMutationVariables = Exact<{
  ReviewFormTemplateID: Scalars['String'];
}>;


export type ReviewFormTemplateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewFormTemplateDelete'>
);

export type ReviewGroupListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReviewGroupListingQuery = (
  { __typename?: 'Query' }
  & { ReviewGroupListing: Array<(
    { __typename?: 'ReviewGroupEntity' }
    & Pick<ReviewGroupEntity, 'ID' | 'Title' | 'InUse' | 'ModifiedDT'>
  )> }
);

export type IsReviewGroupTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReviewGroupID?: Maybe<Scalars['String']>;
}>;


export type IsReviewGroupTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsReviewGroupTitleExist'>
);

export type ReviewGroupSubmitMutationVariables = Exact<{
  input: ReviewGroupInput;
}>;


export type ReviewGroupSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewGroupSubmit'>
);

export type ReviewGroupDeleteMutationVariables = Exact<{
  ReviewGroupID: Scalars['String'];
}>;


export type ReviewGroupDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReviewGroupDelete'>
);

export type RewardGroupListingQueryVariables = Exact<{ [key: string]: never; }>;


export type RewardGroupListingQuery = (
  { __typename?: 'Query' }
  & { RewardGroupListing: Array<(
    { __typename?: 'RewardGroupEntity' }
    & Pick<RewardGroupEntity, 'ID' | 'Title' | 'InUse' | 'ModifiedDT'>
  )> }
);

export type IsRewardGroupTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  RewardGroupID?: Maybe<Scalars['String']>;
}>;


export type IsRewardGroupTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsRewardGroupTitleExist'>
);

export type RewardGroupSubmitMutationVariables = Exact<{
  input: RewardGroupInput;
}>;


export type RewardGroupSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RewardGroupSubmit'>
);

export type RewardGroupDeleteMutationVariables = Exact<{
  RewardGroupID: Scalars['String'];
}>;


export type RewardGroupDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RewardGroupDelete'>
);

export type PeriodicAllowanceTypeListingQueryVariables = Exact<{ [key: string]: never; }>;


export type PeriodicAllowanceTypeListingQuery = (
  { __typename?: 'Query' }
  & { PeriodicAllowanceTypeListing: Array<(
    { __typename?: 'PeriodicAllowanceTypeEntity' }
    & Pick<PeriodicAllowanceTypeEntity, 'PeriodicAllowanceTypeID' | 'Name' | 'ModifiedDT' | 'CreatedDT' | 'Status'>
  )> }
);

export type PeriodicAllowanceTypeUpdateMutationVariables = Exact<{
  input: PeriodicAllowanceTypeInput;
}>;


export type PeriodicAllowanceTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PeriodicAllowanceTypeUpdate'>
);

export type PeriodicAllowanceTypeInputMutationVariables = Exact<{
  input: PeriodicAllowanceTypeInput;
}>;


export type PeriodicAllowanceTypeInputMutation = (
  { __typename?: 'Mutation' }
  & { PeriodicAllowanceTypeInput: (
    { __typename?: 'PeriodicAllowanceTypeEntity' }
    & Pick<PeriodicAllowanceTypeEntity, 'PeriodicAllowanceTypeID' | 'Name'>
  ) }
);

export type PeriodicAllowanceTypeDeleteMutationVariables = Exact<{
  PeriodicAllowanceTypeID: Scalars['String'];
}>;


export type PeriodicAllowanceTypeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PeriodicAllowanceTypeDelete'>
);

export type EmployeeInfoInsertMutationVariables = Exact<{
  ContactInput: ContactInput;
  EmployeeInput: EmployeeInput;
  DocumentsInput: DocumentsInput;
}>;


export type EmployeeInfoInsertMutation = (
  { __typename?: 'Mutation' }
  & { EmployeeInfoInsert: (
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID'>
  ) }
);

export type GetNextDocNumberQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  RefTableName: Scalars['String'];
}>;


export type GetNextDocNumberQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetNextDocNumber'>
);

export type JobDesignationListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type JobDesignationListingQuery = (
  { __typename?: 'Query' }
  & { JobDesignationListing: Array<(
    { __typename?: 'JobDesignationEntity' }
    & Pick<JobDesignationEntity, 'JobDesignationID' | 'SubscriptionAccountID' | 'Name' | 'IsAdmin' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Employees?: Maybe<Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'Status' | 'DesignationID' | 'EmployeeID'>
    )>>, JobDesignationGrades?: Maybe<Array<(
      { __typename?: 'JobDesignationGradeEntity' }
      & Pick<JobDesignationGradeEntity, 'JobGradeID'>
    )>> }
  )> }
);

export type JobGradeByJobDesignationListingQueryVariables = Exact<{
  JobDesignationID: Scalars['String'];
}>;


export type JobGradeByJobDesignationListingQuery = (
  { __typename?: 'Query' }
  & { JobGradeByJobDesignationListing: Array<(
    { __typename?: 'JobGradeByJobDesignationOutput' }
    & Pick<JobGradeByJobDesignationOutput, 'JobDesignationID'>
    & { JobGrade?: Maybe<(
      { __typename?: 'JobGradeOutput' }
      & Pick<JobGradeOutput, 'JobGradeID' | 'Description'>
    )> }
  )> }
);

export type InsuranceSchemeInUseQueryVariables = Exact<{
  InsuranceSchemeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type InsuranceSchemeInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'InsuranceSchemeInUse'>
);

export type CompanyListWithAccessLevelQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyListWithAccessLevelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyListWithAccessLevel'>
);

export type CompanyListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type CompanyListingQuery = (
  { __typename?: 'Query' }
  & { CompanyListing: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CreatedDT' | 'ModifiedDT' | 'CompanyID' | 'CompanyName' | 'BizRegNo'>
    & { Departments?: Maybe<Array<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description' | 'DepartmentType'>
    )>>, Documents?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description' | 'DocumentType'>
    )>> }
  )> }
);

export type JobPositionAssignmentListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
  JobDesignationID?: Maybe<Scalars['String']>;
}>;


export type JobPositionAssignmentListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPositionAssignmentList'>
);

export type GetSuperiorQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type GetSuperiorQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetSuperior'>
);

export type EmployeeCareerLogUpdateMutationVariables = Exact<{
  EmployeeInput: EmployeeInput;
}>;


export type EmployeeCareerLogUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeCareerLogUpdate'>
);

export type EmployeeStatutoryInsertMutationVariables = Exact<{
  EmployeeBankInfoInput: EmployeeBankInfoInput;
  EmployeeStatutoryInput: EmployeeStatutoryInput;
}>;


export type EmployeeStatutoryInsertMutation = (
  { __typename?: 'Mutation' }
  & { EmployeeStatutoryInsert: (
    { __typename?: 'EmployeeStatutoryEntity' }
    & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID'>
  ) }
);

export type EmployeeStatutoryUpdateMutationVariables = Exact<{
  EmployeeBankInfoInput: EmployeeBankInfoInput;
  EmployeeStatutoryInput: EmployeeStatutoryInput;
}>;


export type EmployeeStatutoryUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeStatutoryUpdate'>
);

export type EmployeePrevContributionInsertMutationVariables = Exact<{
  EmployeePrevContributionInput: EmployeePrevContributionInput;
}>;


export type EmployeePrevContributionInsertMutation = (
  { __typename?: 'Mutation' }
  & { EmployeePrevContributionInsert: (
    { __typename?: 'EmployeePrevContributionEntity' }
    & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
  ) }
);

export type EmployeePrevContributionUpdateMutationVariables = Exact<{
  EmployeePrevContributionInput: EmployeePrevContributionInput;
}>;


export type EmployeePrevContributionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeePrevContributionUpdate'>
);

export type EmployeeAssetListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type EmployeeAssetListingQuery = (
  { __typename?: 'Query' }
  & { EmployeeAssetListing: Array<(
    { __typename?: 'EmployeeAssetEntity' }
    & Pick<EmployeeAssetEntity, 'EmployeeAssetID' | 'EmployeeID' | 'DateAssign' | 'Item' | 'Remark' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type EmployeeAssetDeleteMutationVariables = Exact<{
  EmployeeAssetID: Scalars['String'];
}>;


export type EmployeeAssetDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeAssetDelete'>
);

export type EmployeeAssetDetailQueryVariables = Exact<{
  EmployeeAssetID: Scalars['String'];
}>;


export type EmployeeAssetDetailQuery = (
  { __typename?: 'Query' }
  & { EmployeeAssetDetail: (
    { __typename?: 'EmployeeAssetEntity' }
    & Pick<EmployeeAssetEntity, 'EmployeeAssetID' | 'EmployeeID' | 'DateAssign' | 'Item' | 'Remark' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  ) }
);

export type EmployeeAssetUpdateMutationVariables = Exact<{
  EmployeeAssetID: Scalars['String'];
  EmployeeAssetInput: EmployeeAssetInput;
}>;


export type EmployeeAssetUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeAssetUpdate'>
);

export type EmployeeAssetInsertMutationVariables = Exact<{
  EmployeeAssetInput: EmployeeAssetInput;
}>;


export type EmployeeAssetInsertMutation = (
  { __typename?: 'Mutation' }
  & { EmployeeAssetInsert: (
    { __typename?: 'EmployeeAssetEntity' }
    & Pick<EmployeeAssetEntity, 'EmployeeAssetID'>
  ) }
);

export type GetEmployeePrevContributionQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  PeriodYear?: Maybe<Scalars['Float']>;
}>;


export type GetEmployeePrevContributionQuery = (
  { __typename?: 'Query' }
  & { GetEmployeePrevContribution?: Maybe<(
    { __typename?: 'EmployeePrevContributionEntity' }
    & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID' | 'CompanyID' | 'EmployeeID' | 'PeriodYear' | 'GrossTaxable' | 'EPFPaid' | 'PCBPaid' | 'ZAKATPaid'>
  )> }
);

export type CareerLogUpdateMutationVariables = Exact<{
  CareerLogInput: CareerLogInput;
  DocumentsInput: DocumentsInput;
  DocumentID: Scalars['String'];
}>;


export type CareerLogUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CareerLogUpdate'>
);

export type CareerLogInsertMutationVariables = Exact<{
  CareerLogInput: CareerLogInput;
  DocumentsInput: DocumentsInput;
}>;


export type CareerLogInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CareerLogInsert'>
);

export type EmployeeDependentInsertMutationVariables = Exact<{
  DependentContactInput: ContactInput;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DocumentsInput?: Maybe<DocumentsInput>;
}>;


export type EmployeeDependentInsertMutation = (
  { __typename?: 'Mutation' }
  & { EmployeeDependentInsert: (
    { __typename?: 'EmployeeDependentsEntity' }
    & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID' | 'ContactID'>
  ) }
);

export type EmployeeDependentUpdateMutationVariables = Exact<{
  DependentContactInput: ContactInput;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DocumentsInput?: Maybe<DocumentsInput>;
}>;


export type EmployeeDependentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeDependentUpdate'>
);

export type EmployeeDependentInsertByEmployeeMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  SaveAction: ApprovalStatus;
  DependentContactInput: ContactInput;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DocumentsInput?: Maybe<DocumentsInput>;
}>;


export type EmployeeDependentInsertByEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { EmployeeDependentInsertByEmployee: (
    { __typename?: 'EmployeeDependentsEntity' }
    & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID' | 'ContactID'>
  ) }
);

export type EmployeeDependentUpdateByEmployeeMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  SaveAction: ApprovalStatus;
  DependentContactInput: ContactInput;
  EmployeeDependentsInput: EmployeeDependentsInput;
  DocumentsInput?: Maybe<DocumentsInput>;
}>;


export type EmployeeDependentUpdateByEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmployeeDependentUpdateByEmployee'>
);

export type GetEmployeeAppraisalQueryVariables = Exact<{
  EmployeeAppraisalID?: Maybe<Scalars['String']>;
}>;


export type GetEmployeeAppraisalQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeAppraisal: Array<(
    { __typename?: 'EmployeeAppraisalEntity' }
    & Pick<EmployeeAppraisalEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeAppraisalID' | 'EmployeeID' | 'AppraisalRecord' | 'Date' | 'Rating' | 'Score' | 'AppraiseeRemark' | 'AppraiserRemark' | 'AppraiserName' | 'DocumentFile' | 'BucketFileName'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID'>
    )> }
  )> }
);

export type GetEmployeeDiaryQueryVariables = Exact<{
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type GetEmployeeDiaryQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeDiary: Array<(
    { __typename?: 'EmployeeDiaryEntity' }
    & Pick<EmployeeDiaryEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'EmployeeDiaryID' | 'EmployeeID' | 'Date' | 'CaseNo' | 'Description' | 'Countermeasure' | 'DocumentID' | 'Private'>
    & { Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID'>
    )> }
  )> }
);

export type CreateEmployeeInsuranceSchemeMutationVariables = Exact<{
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
}>;


export type CreateEmployeeInsuranceSchemeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateEmployeeInsuranceScheme'>
);

export type UpdateEmployeeInsuranceSchemeMutationVariables = Exact<{
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
  EmployeeInsuranceSchemeID: Scalars['String'];
}>;


export type UpdateEmployeeInsuranceSchemeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateEmployeeInsuranceScheme'>
);

export type DeleteEmployeeInsuranceSchemeMutationVariables = Exact<{
  EmployeeInsuranceSchemeID: Scalars['String'];
}>;


export type DeleteEmployeeInsuranceSchemeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteEmployeeInsuranceScheme'>
);

export type TemplateLibraryTargetListingQueryVariables = Exact<{ [key: string]: never; }>;


export type TemplateLibraryTargetListingQuery = (
  { __typename?: 'Query' }
  & { getTemplateLibrary: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TargetID' | 'TargetType' | 'SourceID' | 'ModifiedBy' | 'ModifiedDT' | 'CreatedBy' | 'CreatedDT'>
  )> }
);

export type PersonnelInsuranceSchemePersonnelListingQueryVariables = Exact<{
  InsuranceSchemeID: Scalars['String'];
  EmployeeInsuranceSchemeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PersonnelInsuranceSchemePersonnelListingQuery = (
  { __typename?: 'Query' }
  & { PersonnelInsuranceSchemePersonnelListing: Array<(
    { __typename?: 'EmployeeInsuranceSchemeEntity' }
    & Pick<EmployeeInsuranceSchemeEntity, 'EmployeeInsuranceSchemeID' | 'EmployeeID' | 'InsuranceSchemeID' | 'StartDate' | 'EndDate' | 'Status'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'JobGradeID' | 'OrgUnitID' | 'FormStatus' | 'EmployeeNo'>
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile' | 'DocumentID' | 'Description' | 'BucketFileName'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'Name'>
      )> }
    )>, InsuranceScheme?: Maybe<(
      { __typename?: 'InsuranceSchemeEntity' }
      & Pick<InsuranceSchemeEntity, 'Name' | 'DocumentID'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'Description' | 'DocumentFile' | 'FileSize' | 'BucketFileName' | 'DocumentType' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
      )> }
    )> }
  )> }
);

export type PersonnelInsuranceSchemeBulkInsertMutationVariables = Exact<{
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  PersonnelIDs: Array<Scalars['String']> | Scalars['String'];
  InsuranceSchemeID: Scalars['String'];
}>;


export type PersonnelInsuranceSchemeBulkInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelInsuranceSchemeBulkInsert'>
);

export type PersonnelInsuranceSchemeUpdateMutationVariables = Exact<{
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
  EmployeeInsuranceSchemeID: Scalars['String'];
}>;


export type PersonnelInsuranceSchemeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelInsuranceSchemeUpdate'>
);

export type ProbationExtensionListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ProbationExtensionListingQuery = (
  { __typename?: 'Query' }
  & { ProbationExtensionListing: Array<(
    { __typename?: 'ProbationExtendedEmployeeOutput' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'FormStatus' | 'OrgUnitID' | 'JobGradeID' | 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, Division?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'Name'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'BucketFileName' | 'Description' | 'DocumentFile' | 'DocumentID'>
      )> }
    )>, ProbationExtension?: Maybe<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'ModifiedDT' | 'ModifiedBy' | 'CreatedDT' | 'CreatedBy' | 'CareerLogID' | 'Remark' | 'MovementType' | 'FromDate' | 'EffectiveDate'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'BucketFileName' | 'Description' | 'DocumentFile' | 'DocumentID'>
      )> }
    )> }
  )> }
);

export type StaffConfirmationProbationListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  IsCompleted?: Maybe<Scalars['Boolean']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StaffConfirmationProbationListingQuery = (
  { __typename?: 'Query' }
  & { StaffConfirmationProbationListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'FormStatus' | 'CompanyID' | 'JobGradeID' | 'DepartmentID' | 'DivisionID' | 'DesignationID' | 'ReportTo' | 'EmployeeID' | 'Remark' | 'JoinedReferenceDate' | 'ConfirmedDate' | 'IsProbationExtended' | 'EmployeeNo' | 'JoinedDate' | 'Probation' | 'Status' | 'EmploymentType'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'Description' | 'CompanyHierarchyID' | 'CompanyOrgUnitID'>
    )>, EmployeeCareerLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'MovementType' | 'TenderResignDate' | 'Remark'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )>>, Supervisor?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, Division?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'BucketFileName' | 'Description' | 'DocumentFile' | 'DocumentID'>
    )> }
  )> }
);

export type StaffConfirmationConfirmedCareerLogListingQueryVariables = Exact<{
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StaffConfirmationConfirmedCareerLogListingQuery = (
  { __typename?: 'Query' }
  & { StaffConfirmationConfirmedCareerLogListing: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'CreatedBy' | 'ModifiedBy' | 'ModifiedDT' | 'CreatedDT' | 'Status' | 'CareerLogID' | 'EmployeeID' | 'MovementType' | 'Remark' | 'EffectiveDate' | 'FromDate' | 'FromJobGrade' | 'ToJobGrade' | 'FromOrgUnit' | 'ToOrgUnit'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'OrgUnitID' | 'Status' | 'EmploymentType' | 'JoinedDate' | 'ConfirmedDate' | 'Probation' | 'EmployeeNo' | 'EmployeeID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )>, ToDivisionTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToDepartmentTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, ToJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )> }
  )> }
);

export type StaffConfirmationConfirmedListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type StaffConfirmationConfirmedListingQuery = (
  { __typename?: 'Query' }
  & { StaffConfirmationConfirmedListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'FormStatus' | 'CompanyID' | 'JobGradeID' | 'DepartmentID' | 'ReportTo' | 'DivisionID' | 'DesignationID' | 'ModifiedBy' | 'ModifiedDT' | 'CreatedBy' | 'CreatedDT' | 'EmployeeID' | 'EmployeeNo' | 'JoinedDate' | 'ConfirmedDate' | 'Probation' | 'Status' | 'EmploymentType'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, EmployeeCareerLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'MovementType' | 'TenderResignDate' | 'Remark' | 'CreatedDT' | 'CreatedBy' | 'ModifiedBy' | 'ModifiedDT'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )>>, Supervisor?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, Division?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'BucketFileName' | 'Description' | 'DocumentFile' | 'DocumentID'>
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'Description' | 'CompanyHierarchyID' | 'CompanyOrgUnitID'>
    )> }
  )> }
);

export type StaffConfirmationInsertMutationVariables = Exact<{
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
}>;


export type StaffConfirmationInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffConfirmationInsert'>
);

export type StaffConfirmationUpdateMutationVariables = Exact<{
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
  CareerLogID: Scalars['String'];
  IsAttachmentRemoved: Scalars['Boolean'];
}>;


export type StaffConfirmationUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffConfirmationUpdate'>
);

export type StaffPromotionDemotionListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PromotionOrDemotion: MovementType;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StaffPromotionDemotionListingQuery = (
  { __typename?: 'Query' }
  & { StaffPromotionDemotionListing: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'FromDate' | 'Status' | 'CareerLogID' | 'EffectiveDate' | 'FromCompany' | 'ToCompany' | 'FromDepartment' | 'ToDepartment' | 'FromDivision' | 'ToDivision' | 'FromOrgUnit' | 'ToOrgUnit' | 'FromJobGrade' | 'MovementType' | 'ExitReason' | 'ToJobGrade' | 'FromEmploymentType' | 'ToEmploymentType' | 'ReportTo' | 'ModifiedDT' | 'ModifiedBy' | 'CreatedBy' | 'CreatedDT' | 'Remark' | 'CancellationRemarks' | 'FromPosition' | 'ToPosition'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'FormStatus' | 'EmployeeID' | 'Status' | 'EmploymentType' | 'JoinedReferenceDate' | 'JoinedDate' | 'ConfirmedDate' | 'ReportTo' | 'Probation' | 'EmployeeNo' | 'OrgUnitID'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'ParentID' | 'Description' | 'CompanyOrgUnitID' | 'CompanyHierarchyID' | 'Level'>
      )>, Supervisor?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, Division?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, EmployeeSubordinates: Array<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name' | 'Sequence'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )>, FromJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, ToJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, FromPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, ToPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )> }
  )> }
);

export type StaffPromotionDemotionInsertMutationVariables = Exact<{
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffPromotionDemotionInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffPromotionDemotionInsert'>
);

export type StaffPromotionDemotionUpdateMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffPromotionDemotionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffPromotionDemotionUpdate'>
);

export type StaffTerminationListingQueryVariables = Exact<{
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StaffTerminationListingQuery = (
  { __typename?: 'Query' }
  & { StaffTerminationListing: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'EmployeeID' | 'CancellationRemarks' | 'Status' | 'CareerLogID' | 'EffectiveDate' | 'FromCompany' | 'ToCompany' | 'FromDepartment' | 'ToDepartment' | 'FromDivision' | 'ToDivision' | 'FromOrgUnit' | 'ToOrgUnit' | 'FromJobGrade' | 'ToJobGrade' | 'FromPosition' | 'ToPosition' | 'ReportTo' | 'Remark' | 'ExitReason' | 'LastWorkingDate' | 'LastEmploymentDate' | 'TenderResignDate' | 'ModifiedBy' | 'ModifiedDT' | 'CreatedDT' | 'CreatedBy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'Status' | 'EmploymentType' | 'JoinedReferenceDate' | 'JoinedDate' | 'ConfirmedDate' | 'TenderResignDate' | 'ResignedDate' | 'ReportTo' | 'Probation' | 'EmployeeNo' | 'OrgUnitID'>
      & { Supervisor?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description' | 'CompanyOrgUnitID' | 'CompanyHierarchyID' | 'Level' | 'ParentID'>
      )>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, Division?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, EmployeeSubordinates: Array<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )>, FromJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, ToJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, FromPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, ToPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )> }
  )> }
);

export type StaffTerminationListingBySubscriptionQueryVariables = Exact<{
  SubscriptionAccountID?: Maybe<Scalars['String']>;
}>;


export type StaffTerminationListingBySubscriptionQuery = (
  { __typename?: 'Query' }
  & { StaffTerminationListingBySubscription: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'EmployeeID' | 'LastEmploymentDate' | 'Status'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'CompanyID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )> }
);

export type StaffRetirementDueListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StaffRetirementDueListingQuery = (
  { __typename?: 'Query' }
  & { StaffRetirementDueListing: Array<(
    { __typename?: 'StaffDueForRetirementOutput' }
    & Pick<StaffDueForRetirementOutput, 'RetirementDate' | 'IsRetirementOverdue'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'JobGradeID' | 'FormStatus' | 'OrgUnitID' | 'EmployeeID' | 'CompanyID' | 'JoinedReferenceDate' | 'DesignationID' | 'TenderResignDate' | 'DivisionID' | 'DepartmentID' | 'JoinedDate' | 'Probation' | 'Status' | 'EmploymentType' | 'ConfirmedDate' | 'ReportTo' | 'EmployeeNo'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description' | 'CompanyHierarchyID' | 'CompanyOrgUnitID'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'Description'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )>, EmployeeCareerLogs?: Maybe<Array<(
        { __typename?: 'CareerLogEntity' }
        & { Document?: Maybe<(
          { __typename?: 'DocumentsEntity' }
          & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
        )> }
      )>>, Supervisor?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, EmployeeDepartment?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, Division?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'Name'>
      )> }
    )> }
  )> }
);

export type StaffRetirementRetiredListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StaffRetirementRetiredListingQuery = (
  { __typename?: 'Query' }
  & { StaffRetirementRetiredListing: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'Remark' | 'ModifiedDT' | 'ModifiedBy' | 'CreatedBy' | 'CreatedDT' | 'FromOrgUnit' | 'ToOrgUnit' | 'Status'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize' | 'DocumentID'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'JobGradeID' | 'FormStatus' | 'OrgUnitID' | 'JoinedReferenceDate' | 'CompanyID' | 'Status' | 'EmploymentType' | 'DesignationID' | 'DivisionID' | 'DepartmentID' | 'ConfirmedDate' | 'ReportTo' | 'JoinedDate' | 'Probation' | 'EmployeeID' | 'EmployeeNo'>
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )>, OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description' | 'CompanyHierarchyID' | 'CompanyOrgUnitID'>
      )>, Supervisor?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, EmployeeDepartment?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, Division?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'Name'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'Description'>
      )> }
    )>, ToDivisionTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToDepartmentTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, ToPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )> }
  )> }
);

export type StaffRetirementInsertMutationVariables = Exact<{
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffRetirementInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelStaffExitCompanyInsert'>
);

export type StaffRetirementUpdateMutationVariables = Exact<{
  DocumentInput?: Maybe<DocumentsInput>;
  CareerLogInput: CareerLogInput;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  CareerLogID: Scalars['String'];
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffRetirementUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelStaffExitCompanyUpdate'>
);

export type StaffExitCompanyListingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type StaffExitCompanyListingQuery = (
  { __typename?: 'Query' }
  & { StaffExitCompanyListing: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'CareerLogID' | 'Status' | 'EffectiveDate' | 'LastWorkingDate' | 'LastEmploymentDate' | 'Remark' | 'MovementType' | 'ExitReason' | 'CreatedDT' | 'CreatedBy' | 'TenderResignDate' | 'CancellationRemarks' | 'ModifiedBy' | 'ModifiedDT' | 'ExitReasonID' | 'FromOrgUnit' | 'ToOrgUnit'>
    & { ExitCompanyReason?: Maybe<(
      { __typename?: 'PersonnelParameterEntity' }
      & Pick<PersonnelParameterEntity, 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>, FromJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, FromPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'FormStatus' | 'OrgUnitID' | 'EmployeeID' | 'EmployeeNo' | 'Status' | 'EmploymentType' | 'JoinedReferenceDate' | 'JoinedDate' | 'ConfirmedDate' | 'TenderResignDate' | 'ResignedDate' | 'ReportTo' | 'Probation'>
      & { Supervisor?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description' | 'CompanyOrgUnitID' | 'CompanyHierarchyID' | 'ParentID'>
      )>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, Division?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'Description' | 'JobGradeID'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'Name'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile' | 'Description'>
      )> }
    )> }
  )> }
);

export type PersonnelStaffExitCompanyInsertMutationVariables = Exact<{
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type PersonnelStaffExitCompanyInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelStaffExitCompanyInsert'>
);

export type PersonnelStaffExitCompanyUpdateMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type PersonnelStaffExitCompanyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelStaffExitCompanyUpdate'>
);

export type StaffInternalTransferListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StaffInternalTransferListingQuery = (
  { __typename?: 'Query' }
  & { StaffInternalTransferListing: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'CancellationRemarks' | 'Status' | 'CareerLogID' | 'EffectiveDate' | 'FromOrgUnit' | 'ToOrgUnit' | 'FromCompany' | 'ToCompany' | 'FromDepartment' | 'ToDepartment' | 'FromDivision' | 'ToDivision' | 'FromPosition' | 'ToPosition' | 'FromJobGrade' | 'ToJobGrade' | 'FromEmploymentType' | 'ToEmploymentType' | 'ReportTo' | 'ModifiedDT' | 'ModifiedBy' | 'CreatedBy' | 'CreatedDT' | 'MovementType' | 'Remark'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'FormStatus' | 'EmployeeID' | 'EmployeeNo' | 'Status' | 'EmploymentType' | 'JoinedReferenceDate' | 'JoinedDate' | 'ConfirmedDate' | 'ReportTo' | 'Probation' | 'OrgUnitID'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description' | 'CompanyHierarchyID' | 'CompanyOrgUnitID'>
      )>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, Division?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Supervisor?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile' | 'Description'>
      )> }
    )>, FromCompanyTable?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, ToCompanyTable?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, FromDepartmentTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToDepartmentTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, FromDivisionTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToDivisionTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, FromJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, ToJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, FromPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, ToPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )> }
  )> }
);

export type StaffInternalTransferInsertMutationVariables = Exact<{
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffInternalTransferInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferInsert'>
);

export type StaffInternalTransferUpdateMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffInternalTransferUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferUpdate'>
);

export type StaffInternalTransferCancelMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CancellationRemarks?: Maybe<Scalars['String']>;
}>;


export type StaffInternalTransferCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferCancel'>
);

export type StaffDemotionCancelMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CancellationRemarks?: Maybe<Scalars['String']>;
}>;


export type StaffDemotionCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffPromotionDemotionCancel'>
);

export type StaffSecondmentListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StaffSecondmentListingQuery = (
  { __typename?: 'Query' }
  & { StaffSecondmentListing: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'Status' | 'CancellationRemarks' | 'CareerLogID' | 'EmployeeID' | 'EffectiveDate' | 'FromCompany' | 'ToCompany' | 'FromOrgUnit' | 'ToOrgUnit' | 'FromDepartment' | 'ToDepartment' | 'FromDivision' | 'ToDivision' | 'FromPosition' | 'ToPosition' | 'FromJobGrade' | 'ToJobGrade' | 'FromEmploymentType' | 'ToEmploymentType' | 'ReportTo' | 'Remark' | 'CreatedDT' | 'CreatedBy' | 'ModifiedBy' | 'ModifiedDT'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'FormStatus' | 'EmployeeID' | 'DivisionID' | 'DesignationID' | 'DepartmentID' | 'JobGradeID' | 'CompanyID' | 'EmployeeNo' | 'OrgUnitID' | 'JoinedDate' | 'JoinedReferenceDate' | 'Probation' | 'ConfirmedDate' | 'Status' | 'EmploymentType'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'ParentID' | 'Description' | 'CompanyOrgUnitID' | 'CompanyHierarchyID' | 'Level'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
      )>, Supervisor?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile' | 'Description'>
      )> }
    )>, FromCompanyTable?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, ToCompanyTable?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, FromDepartmentTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToDepartmentTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, FromDivisionTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToDivisionTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, FromPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, ToPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, FromJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, ToJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )> }
  )> }
);

export type StaffInterCompanyTransferListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StaffInterCompanyTransferListingQuery = (
  { __typename?: 'Query' }
  & { StaffInterCompanyTransferListing: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'CancellationRemarks' | 'Status' | 'IsCopyCurrentYearTP3' | 'CareerLogID' | 'EmployeeID' | 'EffectiveDate' | 'FromOrgUnit' | 'ToOrgUnit' | 'FromCompany' | 'ToCompany' | 'FromDepartment' | 'ToDepartment' | 'FromDivision' | 'ToDivision' | 'FromPosition' | 'ToPosition' | 'FromJobGrade' | 'ToJobGrade' | 'FromEmploymentType' | 'ToEmploymentType' | 'ReportTo' | 'CreatedDT' | 'CreatedBy' | 'ModifiedBy' | 'ModifiedDT' | 'Remark' | 'IsCopyActiveZakatCont' | 'IsTransferAlBalance' | 'IsTransferLeaveTaken' | 'IsCopyStatutory' | 'IsTransferCurrentYearPayroll' | 'IsTransferLatestSalary' | 'IsCopyCurrentYearTaxRelief' | 'IsCopyActiveFixedAllowance' | 'IsTransferMonthlyClaimAmount' | 'IsTransferBiYearlyClaimAmount' | 'IsTransferYearlyClaimAmount' | 'IsTransferLifetimeClaimAmount' | 'IsCopyProjectCostAllocation' | 'IsTransferShiftGroup' | 'ShiftGroupID' | 'NewEmployeeNo' | 'CalendarStateTitle' | 'IsAllowToCancel'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'FormStatus' | 'OrgUnitID' | 'DivisionID' | 'EmployeeID' | 'DesignationID' | 'DepartmentID' | 'JobGradeID' | 'EmployeeNo' | 'JoinedDate' | 'JoinedReferenceDate' | 'Probation' | 'ConfirmedDate' | 'Status' | 'EmploymentType'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description' | 'CompanyOrgUnitID' | 'CompanyHierarchyID' | 'ParentID'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'Name'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'Description'>
      )>, Supervisor?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile' | 'Description'>
      )> }
    )>, FromCompanyTable?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, ToCompanyTable?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, FromDepartmentTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToDepartmentTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, FromDivisionTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToDivisionTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, FromPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, ToPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, FromJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, ToJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )> }
  )> }
);

export type StaffSecondmentInsertMutationVariables = Exact<{
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffSecondmentInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferInsert'>
);

export type StaffSecondmentUpdateMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffSecondmentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferUpdate'>
);

export type StaffInterCompanyTransferInsertMutationVariables = Exact<{
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffInterCompanyTransferInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferInsert'>
);

export type StaffInterCompanyTransferUpdateMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffInterCompanyTransferUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferUpdate'>
);

export type StaffRedesignationListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StaffRedesignationListingQuery = (
  { __typename?: 'Query' }
  & { StaffRedesignationListing: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'CancellationRemarks' | 'CareerLogID' | 'EffectiveDate' | 'FromCompany' | 'ToCompany' | 'FromDepartment' | 'ToDepartment' | 'FromDivision' | 'ToDivision' | 'FromPosition' | 'ToPosition' | 'FromJobGrade' | 'ToJobGrade' | 'FromOrgUnit' | 'ToOrgUnit' | 'FromEmploymentType' | 'ToEmploymentType' | 'CreatedDT' | 'CreatedBy' | 'ModifiedBy' | 'ModifiedDT' | 'ReportTo' | 'Remark' | 'Status'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'FormStatus' | 'JobGradeID' | 'EmployeeNo' | 'EmployeeID' | 'DesignationID' | 'OrgUnitID' | 'JoinedDate' | 'JoinedReferenceDate' | 'Probation' | 'ConfirmedDate' | 'Status' | 'EmploymentType'>
      & { Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyName'>
      )>, OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'ParentID' | 'Description' | 'CompanyOrgUnitID' | 'CompanyHierarchyID' | 'Level'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'Name'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'Description'>
      )>, Supervisor?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile' | 'Description'>
      )> }
    )>, FromCompanyTable?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, ToCompanyTable?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, FromDepartmentTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToDepartmentTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, FromDivisionTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, ToDivisionTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, FromJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, ToJobGradeTable?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, FromPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, ToPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )> }
  )> }
);

export type StaffTransferInsertMutationVariables = Exact<{
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffTransferInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferInsert'>
);

export type StaffTransferUpdateMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CareerLogInput: CareerLogInput;
  DocumentInput?: Maybe<DocumentsInput>;
  IsCopyCurrentYearTP3?: Maybe<Scalars['Boolean']>;
  ContentURL?: Maybe<Scalars['Upload']>;
  Content?: Maybe<Scalars['String']>;
  ReportTemplateType?: Maybe<Scalars['String']>;
}>;


export type StaffTransferUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferUpdate'>
);

export type StaffTransferCancelMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CancellationRemarks?: Maybe<Scalars['String']>;
}>;


export type StaffTransferCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferCancel'>
);

export type StaffPromotionDemotionCancelMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CancellationRemarks?: Maybe<Scalars['String']>;
}>;


export type StaffPromotionDemotionCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffPromotionDemotionCancel'>
);

export type StaffExitCompanyCancelMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CancellationRemarks?: Maybe<Scalars['String']>;
}>;


export type StaffExitCompanyCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffExitCompanyCancel'>
);

export type StaffSecondmentCancelMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CancellationRemarks?: Maybe<Scalars['String']>;
}>;


export type StaffSecondmentCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferCancel'>
);

export type StaffInterCompanyTransferCancelMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
  CancellationRemarks?: Maybe<Scalars['String']>;
}>;


export type StaffInterCompanyTransferCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTransferCancel'>
);

export type StaffConfirmationCancelConfirmedMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
}>;


export type StaffConfirmationCancelConfirmedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffConfirmationCancelConfirmed'>
);

export type StaffRetirementCancelMutationVariables = Exact<{
  CareerLogID: Scalars['String'];
}>;


export type StaffRetirementCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffExitCompanyCancel'>
);

export type PersonnelHomeInfoLinesQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type PersonnelHomeInfoLinesQuery = (
  { __typename?: 'Query' }
  & { PersonnelHomeInfoLines: (
    { __typename?: 'PersonnelHomeInfoLinesOutput' }
    & Pick<PersonnelHomeInfoLinesOutput, 'EmpBySubsTotal' | 'EmpTotal' | 'MthNewEmpTotal' | 'OverdueConfTotal' | 'UpcomingConfTotal' | 'MthProbExtTotal' | 'MthPromoTotal' | 'MthDemoTotal' | 'MthSecondTotal' | 'MthRedesTotal' | 'MthIntTransTotal' | 'MthIntCompTransTotal' | 'MthTerminationTotal' | 'MthRetireTotal' | 'MthOffBoardTotal' | 'InsSchemeTotal' | 'DataChangesApplicationPendingTotal'>
  ) }
);

export type CostCenterMappingListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CostCenterMappingListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CostCenterMappingList'>
);

export type CostMappingUpdateMutationVariables = Exact<{
  input: Array<CostCenterMappingInput> | CostCenterMappingInput;
}>;


export type CostMappingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CostMappingUpdate'>
);

export type StaffInterCompanyTransferCopyTransferDataQueryVariables = Exact<{
  NewCompanyID: Scalars['String'];
  CurrentEmployeeID: Scalars['String'];
  CurrentCompanyID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
}>;


export type StaffInterCompanyTransferCopyTransferDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'StaffInterCompanyTransferCopyTransferData'>
);

export type StaffInterCompanyTransferDetailsQueryVariables = Exact<{
  CareerLogID: Scalars['String'];
}>;


export type StaffInterCompanyTransferDetailsQuery = (
  { __typename?: 'Query' }
  & { StaffInterCompanyTransferDetails: (
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'ShiftGroupName' | 'ReportToSuperiorName' | 'CalendarStateTitle' | 'PrevCompReportToSuperiorName'>
  ) }
);

export type PersonnelCertificateListingQueryVariables = Exact<{
  PersonnelID: Scalars['String'];
  PersonnelCertificateID?: Maybe<Scalars['String']>;
}>;


export type PersonnelCertificateListingQuery = (
  { __typename?: 'Query' }
  & { PersonnelCertificateListing: Array<(
    { __typename?: 'PersonnelCertificateEntity' }
    & Pick<PersonnelCertificateEntity, 'PersonnelCertificateID' | 'Description' | 'IssueDate' | 'ExpiryDate' | 'RegNo' | 'CertificateUrl' | 'DocumentID'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
    )> }
  )> }
);

export type IsMobileNoExistsQueryVariables = Exact<{
  MobileNo: Scalars['String'];
}>;


export type IsMobileNoExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsMobileNoExists'>
);

export type IsEmailExistsQueryVariables = Exact<{
  Email: Scalars['String'];
}>;


export type IsEmailExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsEmailExists'>
);

export type IsEmployeeNoExistsQueryVariables = Exact<{
  EmployeeNo: Scalars['String'];
}>;


export type IsEmployeeNoExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsEmployeeNoExists'>
);

export type ContractInfoInsertMutationVariables = Exact<{
  ContractInfoInput: ContractInfoInput;
}>;


export type ContractInfoInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ContractInfoInsert'>
);

export type ContractInfoUpdateMutationVariables = Exact<{
  ContractInfoInput: ContractInfoInput;
  ContractInfoID: Scalars['String'];
}>;


export type ContractInfoUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ContractInfoUpdate'>
);

export type ContractInfoDeleteMutationVariables = Exact<{
  ContractInfoID: Scalars['String'];
}>;


export type ContractInfoDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ContractInfoDelete'>
);

export type ReportDocDeleteMutationVariables = Exact<{
  DocumentID: Scalars['String'];
}>;


export type ReportDocDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReportDocDelete'>
);

export type PersonnelSecurityPolicyInfoByRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonnelSecurityPolicyInfoByRoleQuery = (
  { __typename?: 'Query' }
  & { PersonnelSecurityPolicyInfoByRole?: Maybe<(
    { __typename?: 'PersonnelSecurityPolicyEntity' }
    & Pick<PersonnelSecurityPolicyEntity, 'ID' | 'SecurityPolicy'>
  )> }
);

export type PersonnelSecurityPolicySubmitMutationVariables = Exact<{
  input: PersonnelSecurityPolicyInput;
}>;


export type PersonnelSecurityPolicySubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PersonnelSecurityPolicySubmit'>
);

export type GetEmployeePoliciesQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type GetEmployeePoliciesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetEmployeePolicies'>
);

export type GetEmployeeClaimPoliciesQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type GetEmployeeClaimPoliciesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetEmployeeClaimPolicies'>
);

export type GetLeavePolicyByEmployeeQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type GetLeavePolicyByEmployeeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetLeavePolicyByEmployee'>
);

export type ProjecCommonSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjecCommonSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ProjecCommonSettingSummary'>
);

export type ProjectMasterListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectMasterListingQuery = (
  { __typename?: 'Query' }
  & { ProjectMasterListing: Array<(
    { __typename?: 'ProjectMasterEntity' }
    & Pick<ProjectMasterEntity, 'ID' | 'ProjectName' | 'CostCenter' | 'CostCenterDescription' | 'ValidUntil' | 'ProjectOwnerID' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { ProjectCostAllocationDetail?: Maybe<Array<(
      { __typename?: 'ProjectCostAllocationDetailEntity' }
      & Pick<ProjectCostAllocationDetailEntity, 'ID'>
    )>>, ProjectExpenseClaimHeader?: Maybe<Array<(
      { __typename?: 'ProjectExpenseClaimHeaderEntity' }
      & Pick<ProjectExpenseClaimHeaderEntity, 'ID'>
    )>> }
  )> }
);

export type ProjectMasterSubmitMutationVariables = Exact<{
  input: ProjectMasterInput;
}>;


export type ProjectMasterSubmitMutation = (
  { __typename?: 'Mutation' }
  & { ProjectMasterSubmit: (
    { __typename?: 'ProjectMasterEntity' }
    & Pick<ProjectMasterEntity, 'ID'>
  ) }
);

export type ProjectMasterDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type ProjectMasterDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ProjectMasterDelete'>
);

export type ProjectExpenseTypeListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectExpenseTypeListingQuery = (
  { __typename?: 'Query' }
  & { ProjectExpenseTypeListing: Array<(
    { __typename?: 'ProjectExpenseTypeEntity' }
    & Pick<ProjectExpenseTypeEntity, 'ID' | 'ProjectExpenseType' | 'PaymentType' | 'PayItemID' | 'ValidUntil' | 'IsRemark' | 'IsAttachment' | 'IsReceiptNo' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { ProjectExpenseClaimDetail?: Maybe<Array<(
      { __typename?: 'ProjectExpenseClaimDetailEntity' }
      & Pick<ProjectExpenseClaimDetailEntity, 'ID'>
    )>> }
  )> }
);

export type ProjectExpenseTypeSubmitMutationVariables = Exact<{
  input: ProjectExpenseTypeInput;
}>;


export type ProjectExpenseTypeSubmitMutation = (
  { __typename?: 'Mutation' }
  & { ProjectExpenseTypeSubmit: (
    { __typename?: 'ProjectExpenseTypeEntity' }
    & Pick<ProjectExpenseTypeEntity, 'ID'>
  ) }
);

export type ProjectExpenseTypeDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type ProjectExpenseTypeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ProjectExpenseTypeDelete'>
);

export type ProjectAccountMappingListQueryVariables = Exact<{
  ProjectAccountHeaderID?: Maybe<Scalars['String']>;
}>;


export type ProjectAccountMappingListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ProjectAccountMappingList'>
);

export type IsGlProcessedQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
}>;


export type IsGlProcessedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsGLProcessed'>
);

export type DeleteProcessedGlMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
}>;


export type DeleteProcessedGlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteProcessedGL'>
);

export type ProjectCostCenterMappingListForGlExportQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  AllPayCycles?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectCostCenterMappingListForGlExportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ProjectCostCenterMappingListForGLExport'>
);

export type ProjectGlAccountMappingListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectGlAccountMappingListingQuery = (
  { __typename?: 'Query' }
  & { ProjectGLAccountMappingListing: Array<(
    { __typename?: 'ProjectGLAccountMappingEntity' }
    & Pick<ProjectGlAccountMappingEntity, 'ID' | 'Title' | 'IsStaffCategory' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { ProjectGLAccountMappingDetail?: Maybe<Array<(
      { __typename?: 'ProjectGLAccountMappingDetailEntity' }
      & Pick<ProjectGlAccountMappingDetailEntity, 'ID' | 'ProjectGLAccountMappingID' | 'StaffCategoryID' | 'ExpenseTypeID' | 'IsCentralized' | 'GLName' | 'IsCreditCC' | 'CreditAccount' | 'CreditAccountDesc' | 'IsDebitCC' | 'DebitAccount' | 'DebitAccountDesc' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>>, ProjectAccountAssignment?: Maybe<Array<(
      { __typename?: 'ProjectAccountAssignmentEntity' }
      & Pick<ProjectAccountAssignmentEntity, 'ID'>
    )>> }
  )> }
);

export type ProjectGlAccountMappingSubmitMutationVariables = Exact<{
  HeaderInput: ProjectGlAccountMappingInput;
  DetailInput: Array<ProjectGlAccountMappingDetailInput> | ProjectGlAccountMappingDetailInput;
}>;


export type ProjectGlAccountMappingSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ProjectGLAccountMappingSubmit'>
);

export type ProjectGlAccountMappingDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type ProjectGlAccountMappingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ProjectGLAccountMappingDelete'>
);

export type CompanyProjectExpenseClaimSettingUpdateMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  CompanyInput: CompanyInput;
}>;


export type CompanyProjectExpenseClaimSettingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyProjectExpenseClaimSettingUpdate'>
);

export type ProjectAccountAssignmentInsertMutationVariables = Exact<{
  HeaderInput: ProjectAccountAssignmentInput;
}>;


export type ProjectAccountAssignmentInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ProjectAccountAssignmentInsert'>
);

export type ProjectAccountAssignmentUpdateMutationVariables = Exact<{
  HeaderInput: ProjectAccountAssignmentInput;
}>;


export type ProjectAccountAssignmentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ProjectAccountAssignmentUpdate'>
);

export type GetProjectAccountAssignmentQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetProjectAccountAssignmentQuery = (
  { __typename?: 'Query' }
  & { GetProjectAccountAssignment: (
    { __typename?: 'ProjectAccountAssignmentEntity' }
    & Pick<ProjectAccountAssignmentEntity, 'ID' | 'CompanyID' | 'ProjectAccountHeaderID'>
    & { ProjectAccountHeader?: Maybe<(
      { __typename?: 'ProjectGLAccountMappingEntity' }
      & Pick<ProjectGlAccountMappingEntity, 'Title'>
    )> }
  ) }
);

export type ProjectCostAllocationListingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ProjectCostAllocationListingQuery = (
  { __typename?: 'Query' }
  & { ProjectCostAllocationListing: Array<(
    { __typename?: 'ProjectCostAllocationEntity' }
    & Pick<ProjectCostAllocationEntity, 'ID' | 'Status' | 'CompanyID' | 'EmployeeID' | 'StartDate' | 'EndDate' | 'ModifiedDT'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile'>
      )> }
    )>, ProjectCostAllocationDetail?: Maybe<Array<(
      { __typename?: 'ProjectCostAllocationDetailEntity' }
      & Pick<ProjectCostAllocationDetailEntity, 'ID' | 'ProjectCostAllocationID' | 'ProjectID' | 'Allocation'>
    )>> }
  )> }
);

export type ProjectCostAllocationEmployeeListingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type ProjectCostAllocationEmployeeListingQuery = (
  { __typename?: 'Query' }
  & { ProjectCostAllocationEmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )> }
  )> }
);

export type ProjectCostAllocationDetailListingQueryVariables = Exact<{
  ProjectCostAllocationID: Scalars['String'];
}>;


export type ProjectCostAllocationDetailListingQuery = (
  { __typename?: 'Query' }
  & { ProjectCostAllocationDetailListing: Array<(
    { __typename?: 'ProjectCostAllocationDetailEntity' }
    & Pick<ProjectCostAllocationDetailEntity, 'ID' | 'ProjectCostAllocationID' | 'ProjectID' | 'Allocation'>
  )> }
);

export type ProjectCostAllocationSubmitMutationVariables = Exact<{
  ProjectCostAllocationInput: ProjectCostAllocationInput;
  ProjectCostAllocationDetailInput: Array<ProjectCostAllocationDetailInput> | ProjectCostAllocationDetailInput;
}>;


export type ProjectCostAllocationSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ProjectCostAllocationSubmit'>
);

export type ProjectCostAllocationDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type ProjectCostAllocationDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ProjectCostAllocationDelete'>
);

export type SubmitMyProjectClaimMutationVariables = Exact<{
  input: SubmitProjectClaimRecordsInput;
}>;


export type SubmitMyProjectClaimMutation = (
  { __typename?: 'Mutation' }
  & { SubmitMyProjectClaim: (
    { __typename?: 'ProjectExpenseClaimHeaderEntity' }
    & Pick<ProjectExpenseClaimHeaderEntity, 'ID' | 'EmployeeID' | 'CompanyID' | 'ApprovalStatus'>
  ) }
);

export type ApproveProjectExpenseClaimRecordsMutationVariables = Exact<{
  input: ApproveProjectExpenseClaimRecordsInput;
  IsSupersede?: Maybe<Scalars['Boolean']>;
}>;


export type ApproveProjectExpenseClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApproveProjectExpenseClaimRecords'>
);

export type DeleteApprovedProjectExpenseClaimRecordsMutationVariables = Exact<{
  input: ApproveProjectExpenseClaimRecordsInput;
  Remark: Scalars['String'];
}>;


export type DeleteApprovedProjectExpenseClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteApprovedProjectExpenseClaimRecords'>
);

export type GetProjectExpenseClaimRecordsListingQueryVariables = Exact<{
  input: ProjectExpenseClaimRecordsInput;
  superiorRejectToDraft?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GetProjectExpenseClaimRecordsListingQuery = (
  { __typename?: 'Query' }
  & { GetProjectExpenseClaimRecordsListing?: Maybe<Array<(
    { __typename?: 'ProjectExpenseClaimRecordsListing' }
    & Pick<ProjectExpenseClaimRecordsListing, 'ClaimHeaderID' | 'ProjectID' | 'ProjectName' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName' | 'SubmissionDate' | 'Subject' | 'ClaimNo' | 'JobGradeID' | 'OrgUnitID' | 'CountClaimDetails' | 'TotalClaimAmount' | 'HeaderStatus' | 'DetailStatus' | 'CountVerifiedClaim' | 'TotalVerifiedClaimAmount' | 'CountClaimHistory' | 'ApprovalDate' | 'CompletedVerificationDate' | 'CompletedVerificationBy' | 'ApprovalName' | 'SubmitterName' | 'Remark' | 'ApprovalStatus' | 'ApproverID' | 'IsRejected'>
    & { GLClaimRecordsDetail?: Maybe<Array<(
      { __typename?: 'PEGLClaimRecordsDetail' }
      & Pick<PeglClaimRecordsDetail, 'ID' | 'ProjectExpenseClaimHeaderID' | 'ProjectExpenseTypeID' | 'ProjectExpenseType' | 'ClaimAmount' | 'ReceiptDate' | 'ReceiptNo' | 'ClaimRemarks' | 'VerifiedAmount' | 'VerifiedRemark' | 'FinanceApproveDate' | 'FinanceApproveBy' | 'FinanceRemarks' | 'FinanceApproveStatus' | 'ClaimNo' | 'PostedBy' | 'PostedDate'>
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName' | 'NotifiedDT'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )>, DocumentIDs?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
    )>> }
  )>> }
);

export type GetApprovedProjectExpenseClaimVerificationListingQueryVariables = Exact<{
  input: ApprovalProjectExpenseClaimVerificationInput;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  Incomplete?: Maybe<Scalars['Boolean']>;
}>;


export type GetApprovedProjectExpenseClaimVerificationListingQuery = (
  { __typename?: 'Query' }
  & { GetApprovedProjectExpenseClaimVerificationListing?: Maybe<Array<(
    { __typename?: 'ProjectExpenseClaimRecordsListing' }
    & Pick<ProjectExpenseClaimRecordsListing, 'ProjectID' | 'ProjectName' | 'ClaimHeaderID' | 'ClaimNo' | 'JobGradeID' | 'OrgUnitID' | 'FormStatus' | 'CreatedBy' | 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'SubmissionDate' | 'Subject' | 'CountClaimDetails' | 'TotalClaimAmount' | 'TotalVerifiedClaimAmount' | 'CountClaimHistory' | 'HeaderStatus' | 'DetailStatus' | 'ApprovalStatus' | 'ApprovalDate' | 'ApprovalName' | 'FinanceApprovalDate' | 'FinanceApprovalName' | 'CompletedVerificationBy' | 'CompletedVerificationDate' | 'Remark' | 'PostedBy' | 'ApproverID' | 'SubmitterName' | 'CountVerifiedClaim'>
    & { DocumentIDs?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
    )>>, MyClaimDetails?: Maybe<Array<(
      { __typename?: 'MyProjectExpenseClaimDetailOutput' }
      & Pick<MyProjectExpenseClaimDetailOutput, 'ClaimDetailID' | 'ProjectExpenseType' | 'ProjectExpenseTypeID' | 'Remark' | 'ReceiptNo' | 'ReceiptDate' | 'ClaimAmount' | 'ClaimBalance' | 'FinanceApproveDate' | 'FinanceApproveBy' | 'FinanceRemarks' | 'FinanceApproveStatus'>
    )>>, History?: Maybe<Array<(
      { __typename?: 'ProjectExpenseClaimRecordsListing' }
      & Pick<ProjectExpenseClaimRecordsListing, 'ClaimHeaderID' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName' | 'SubmissionDate' | 'Subject' | 'CountClaimDetails' | 'TotalClaimAmount' | 'HeaderStatus' | 'DetailStatus' | 'CountVerifiedClaim' | 'TotalVerifiedClaimAmount' | 'CountClaimHistory' | 'ApprovalDate' | 'ApprovalName' | 'ApprovalStatus' | 'CompletedVerificationDate' | 'CompletedVerificationBy' | 'Remark' | 'ClaimNo'>
    )>>, GLClaimRecordsDetail?: Maybe<Array<(
      { __typename?: 'PEGLClaimRecordsDetail' }
      & Pick<PeglClaimRecordsDetail, 'ID' | 'ProjectExpenseClaimHeaderID' | 'ProjectExpenseTypeID' | 'ProjectExpenseType' | 'ClaimAmount' | 'ReceiptDate' | 'ReceiptNo' | 'ClaimRemarks' | 'VerifiedAmount' | 'VerifiedRemark' | 'FinanceApproveDate' | 'FinanceApproveBy' | 'FinanceRemarks' | 'FinanceApproveStatus' | 'ClaimNo' | 'PostedBy' | 'PostedDate'>
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName' | 'NotifiedDT'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type GetPeApprovedClaimCountQueryVariables = Exact<{
  input: ApprovalProjectExpenseClaimVerificationInput;
}>;


export type GetPeApprovedClaimCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetPEApprovedClaimCount'>
);

export type VerifyApprovedProjectExpenseClaimDetailMutationVariables = Exact<{
  input: VerifyApprovedProjectExpenseClaimDetailInput;
}>;


export type VerifyApprovedProjectExpenseClaimDetailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'VerifyApprovedProjectExpenseClaimDetail'>
);

export type ReverseApprovedProjectExpenseClaimRecordsMutationVariables = Exact<{
  input: ApproveProjectExpenseClaimRecordsInput;
}>;


export type ReverseApprovedProjectExpenseClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReverseApprovedProjectExpenseClaimRecords'>
);

export type GetPeClaimRecordsDetailsQueryVariables = Exact<{
  ClaimHeaderID: Scalars['String'];
}>;


export type GetPeClaimRecordsDetailsQuery = (
  { __typename?: 'Query' }
  & { GetPEClaimRecordsDetails?: Maybe<(
    { __typename?: 'PEClaimRecordsDetailHeaderOutput' }
    & Pick<PeClaimRecordsDetailHeaderOutput, 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'ClaimNo' | 'SubmissionDate' | 'Subject' | 'CountClaimDetails' | 'TotalClaimAmount' | 'ApprovalStatus'>
    & { Project?: Maybe<Array<(
      { __typename?: 'ProjectMasterEntity' }
      & Pick<ProjectMasterEntity, 'ID' | 'ProjectName' | 'CostCenter' | 'CostCenterDescription' | 'ValidUntil' | 'ProjectOwnerID' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
      & { ProjectCostAllocationDetail?: Maybe<Array<(
        { __typename?: 'ProjectCostAllocationDetailEntity' }
        & Pick<ProjectCostAllocationDetailEntity, 'ID'>
      )>>, ProjectExpenseClaimHeader?: Maybe<Array<(
        { __typename?: 'ProjectExpenseClaimHeaderEntity' }
        & Pick<ProjectExpenseClaimHeaderEntity, 'ID'>
      )>> }
    )>>, ClaimRecordsDetail?: Maybe<Array<(
      { __typename?: 'PEGLClaimRecordsDetail' }
      & Pick<PeglClaimRecordsDetail, 'ID' | 'ProjectExpenseTypeID' | 'ProjectExpenseType' | 'ClaimAmount' | 'VerifiedAmount' | 'FinanceApproveStatus' | 'FinanceRemarks' | 'FinanceApproveDate' | 'FinanceApproveBy' | 'ReceiptDate' | 'ReceiptNo' | 'ClaimRemarks'>
      & { DocumentIDs?: Maybe<Array<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
      )>>, Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
      )> }
    )>> }
  )> }
);

export type VerifyMyPeClaimQueryVariables = Exact<{
  VerfiyPEClaimInput: VerfiyPeClaimInput;
}>;


export type VerifyMyPeClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'VerifyMyPEClaim'>
);

export type ProjecExpenseSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
}>;


export type ProjecExpenseSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ProjecExpenseSummary'>
);

export type PostPeClaimRecordsMutationVariables = Exact<{
  input: PostPeClaimRecordsInput;
  PaymentMethod: Scalars['String'];
}>;


export type PostPeClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PostPEClaimRecords'>
);

export type ReversePeClaimRecordsMutationVariables = Exact<{
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderIDs: Array<Scalars['String']> | Scalars['String'];
  PaymentMethod: Scalars['String'];
}>;


export type ReversePeClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReversePEClaimRecords'>
);

export type GetPeClaimPostingPeriodListingV2QueryVariables = Exact<{
  input: PeClaimPostingPeriodListingInput;
  PaymentMethod?: Maybe<Scalars['String']>;
}>;


export type GetPeClaimPostingPeriodListingV2Query = (
  { __typename?: 'Query' }
  & { GetPEClaimPostingPeriodListingV2?: Maybe<Array<(
    { __typename?: 'PEClaimPostingPeriodListingOutput' }
    & Pick<PeClaimPostingPeriodListingOutput, 'PeriodYearMonth' | 'FromDate' | 'ToDate' | 'ListingStatus' | 'LatestPeriod'>
    & { ClaimPostingPeriodStatusList?: Maybe<Array<(
      { __typename?: 'PEClaimPostingPeriodStatus' }
      & Pick<PeClaimPostingPeriodStatus, 'ClaimPostingPeriodStatus' | 'CountClaimDetails' | 'TotalClaimAmount'>
    )>> }
  )>> }
);

export type GetPeClaimPaymentPeriodListingV2QueryVariables = Exact<{
  input: PeClaimPostingPeriodListingInput;
  PaymentMethod?: Maybe<Scalars['String']>;
}>;


export type GetPeClaimPaymentPeriodListingV2Query = (
  { __typename?: 'Query' }
  & { GetPEClaimPostingPeriodListingV2?: Maybe<Array<(
    { __typename?: 'PEClaimPostingPeriodListingOutput' }
    & Pick<PeClaimPostingPeriodListingOutput, 'PeriodYearMonth' | 'FromDate' | 'ToDate' | 'ListingStatus' | 'LatestPeriod'>
    & { ClaimPostingPeriodStatusList?: Maybe<Array<(
      { __typename?: 'PEClaimPostingPeriodStatus' }
      & Pick<PeClaimPostingPeriodStatus, 'ClaimPostingPeriodStatus' | 'CountClaimDetails' | 'TotalClaimAmount'>
    )>> }
  )>> }
);

export type GetPeClaimPostingListingQueryVariables = Exact<{
  input: ApprovalProjectExpenseClaimVerificationInput;
  PaymentMethod: Scalars['String'];
}>;


export type GetPeClaimPostingListingQuery = (
  { __typename?: 'Query' }
  & { GetPEClaimPostingListing?: Maybe<Array<(
    { __typename?: 'PEClaimPostingListingOutput' }
    & Pick<PeClaimPostingListingOutput, 'ProjectID' | 'ProjectName' | 'JobGradeID' | 'OrgUnitID' | 'FormStatus' | 'ClaimHeaderID' | 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'Subject' | 'SubmissionDate' | 'ClaimNo' | 'ApprovalName' | 'ApprovalDate' | 'HeaderStatus' | 'CountClaimDetails' | 'TotalClaimAmount' | 'CountVerifiedClaim' | 'TotalVerifiedClaimAmount' | 'CompletedVerificationDate' | 'CompletedVerificationBy' | 'Remark' | 'IsOTPModified' | 'IsOTPDeleted' | 'OTPModifiedBy' | 'OTPDeletedBy' | 'DetailStatus'>
    & { MyClaimDetails?: Maybe<Array<(
      { __typename?: 'MyProjectExpenseClaimDetailOutput' }
      & Pick<MyProjectExpenseClaimDetailOutput, 'ClaimDetailID' | 'ProjectExpenseTypeID' | 'ProjectExpenseType'>
    )>>, ClaimPostedListing?: Maybe<(
      { __typename?: 'PEClaimPostedListingOutput' }
      & Pick<PeClaimPostedListingOutput, 'PayPeriodID' | 'PayrollMonth' | 'PayrollCycleID' | 'PayrollCycleName' | 'PostedDate' | 'PostedBy' | 'Reference1' | 'Reference2'>
    )> }
  )>> }
);

export type GetPeClaimPostingDetailQueryVariables = Exact<{
  ClaimHeaderID: Scalars['String'];
  PaymentMethod: Scalars['String'];
}>;


export type GetPeClaimPostingDetailQuery = (
  { __typename?: 'Query' }
  & { GetPEClaimPostingDetail?: Maybe<(
    { __typename?: 'PEClaimRecordsDetailHeaderOutput' }
    & Pick<PeClaimRecordsDetailHeaderOutput, 'ProjectID' | 'ProjectName' | 'EmployeeID' | 'EmployeeNo' | 'ClaimNo' | 'EmployeeName' | 'DepartmentName' | 'ClaimHeaderID' | 'SubmissionDate' | 'Subject' | 'CountClaimDetails' | 'TotalClaimAmount' | 'PostingStatus' | 'ApprovalDate' | 'ApprovalName' | 'CompletedVerificationDate' | 'CompletedVerificationBy'>
    & { ClaimRecordsDetail?: Maybe<Array<(
      { __typename?: 'PEGLClaimRecordsDetail' }
      & Pick<PeglClaimRecordsDetail, 'ID' | 'ProjectExpenseTypeID' | 'ProjectExpenseType' | 'ReceiptNo' | 'ReceiptDate' | 'ClaimRemarks' | 'ClaimAmount' | 'VerifiedAmount' | 'FinanceApproveDate' | 'FinanceApproveBy' | 'FinanceRemarks' | 'FinanceApproveStatus' | 'PostedBy' | 'PostedDate'>
      & { DocumentIDs?: Maybe<Array<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
      )>> }
    )>> }
  )> }
);

export type DeleteProjectExpenseClaimRecordsMutationVariables = Exact<{
  SubscriptionUserID: Scalars['String'];
  ProjectExpenseClaimHeaderIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteProjectExpenseClaimRecordsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteProjectExpenseClaimRecords'>
);

export type SubmitProjectExpenseClaimForApprovalMutationVariables = Exact<{
  ProjectExpenseClaimHeaderIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type SubmitProjectExpenseClaimForApprovalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitProjectExpenseClaimForApproval'>
);

export type GetApprovedProjectExpenseClaimVerificationDetailsQueryVariables = Exact<{
  ClaimHeaderID: Scalars['String'];
}>;


export type GetApprovedProjectExpenseClaimVerificationDetailsQuery = (
  { __typename?: 'Query' }
  & { GetApprovedProjectExpenseClaimVerificationDetails?: Maybe<(
    { __typename?: 'ApprovedProjectExpenseClaimVerificationDetailsOutput' }
    & Pick<ApprovedProjectExpenseClaimVerificationDetailsOutput, 'Subject' | 'ClaimNo' | 'ClaimHeaderID' | 'JobAccountID' | 'JobAccountName' | 'HeaderStatus' | 'EmployeeID' | 'EmployeeNo' | 'EmployeeName' | 'CountClaimDetails' | 'CountVerifiedClaim' | 'TotalClaimAmount' | 'SubmissionDate'>
    & { ApprovedClaimDetail?: Maybe<(
      { __typename?: 'PEApprovedClaimDetail' }
      & Pick<PeApprovedClaimDetail, 'ApprovalStatus' | 'ApprovalRemark' | 'ApprovalByID' | 'ApprovalByName' | 'ApprovalDate'>
    )>, MyClaimDetails?: Maybe<Array<(
      { __typename?: 'MyProjectExpenseClaimDetailOutput' }
      & Pick<MyProjectExpenseClaimDetailOutput, 'ClaimDetailID' | 'ProjectExpenseTypeID' | 'ProjectExpenseType' | 'ClaimAmount' | 'ClaimBalance' | 'ReceiptDate' | 'ReceiptNo' | 'DocumentID' | 'DocumentName' | 'Remark'>
      & { Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )>, DocumentIDs?: Maybe<Array<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
      )>>, VerifiedClaimDetail?: Maybe<(
        { __typename?: 'PEVerifiedClaimDetail' }
        & Pick<PeVerifiedClaimDetail, 'FinanceApprovalStatus' | 'FinanceAmount' | 'FinanceRemark' | 'FinanceApprovalByID' | 'FinanceApprovalByName' | 'FinanceApprovalDate'>
      )>, ClaimDuplicateList?: Maybe<Array<(
        { __typename?: 'PEClaimDuplicateList' }
        & Pick<PeClaimDuplicateList, 'EmployeeName' | 'EmployeeNo' | 'CompanyName' | 'ReceiptDate' | 'Remarks' | 'DocFile'>
      )>> }
    )>> }
  )> }
);

export type ProjectGlAccountHeaderListWithDetailsQueryVariables = Exact<{
  ProjectGLAccountHeaderID?: Maybe<Scalars['String']>;
  PeriodID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  CompanyID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
}>;


export type ProjectGlAccountHeaderListWithDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ProjectGLAccountHeaderListWithDetails'>
);

export type ProjectPayItemAmountByOrgAndPayPeriodQueryVariables = Exact<{
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayrollCycleID?: Maybe<Scalars['String']>;
  AllPayCycles?: Maybe<Scalars['Boolean']>;
}>;


export type ProjectPayItemAmountByOrgAndPayPeriodQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ProjectPayItemAmountByOrgAndPayPeriod'>
);

export type ProjectPayrollGlExportQueryVariables = Exact<{
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayrollCycleID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
}>;


export type ProjectPayrollGlExportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ProjectPayrollGLExport'>
);

export type CostCenterMappingListForGlExportQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  AllPayCycles?: Maybe<Scalars['Boolean']>;
}>;


export type CostCenterMappingListForGlExportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CostCenterMappingListForGLExport'>
);

export type GetProjectExpenseApprovalHistoryQueryVariables = Exact<{
  ApproverID: Scalars['String'];
}>;


export type GetProjectExpenseApprovalHistoryQuery = (
  { __typename?: 'Query' }
  & { GetProjectExpenseApprovalHistory?: Maybe<Array<(
    { __typename?: 'ProjectExpenseToApproveListingOutput' }
    & Pick<ProjectExpenseToApproveListingOutput, 'ApprovalDate' | 'ApproverName' | 'ApprovalStatus' | 'ClaimHeaderID' | 'CreatedDT' | 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'Subject' | 'TotalClaim' | 'TotalClaimAmount' | 'IsRejected'>
    & { WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName' | 'NotifiedDT'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type ProjectGlClaimCycleProcessQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  Type: Scalars['String'];
}>;


export type ProjectGlClaimCycleProcessQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ProjectGLClaimCycleProcess'>
);

export type GetGlClaimCycleListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  AllClaimCycles?: Maybe<Scalars['Boolean']>;
  Type: Scalars['String'];
}>;


export type GetGlClaimCycleListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetGLClaimCycleList'>
);

export type DeleteClaimProcessedGlMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  PeriodID: Scalars['String'];
  Type: Scalars['String'];
}>;


export type DeleteClaimProcessedGlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteClaimProcessedGL'>
);

export type PeClaimCompanySettingSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type PeClaimCompanySettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PEClaimCompanySettingSummary'>
);

export type GetPeClaimRecordsTotalCountQueryVariables = Exact<{
  input: ProjectExpenseClaimRecordsInput;
}>;


export type GetPeClaimRecordsTotalCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetPEClaimRecordsTotalCount'>
);

export type IsProjectExpenseSubjectExistsQueryVariables = Exact<{
  Subject: Scalars['String'];
  input: ProjectExpenseClaimRecordsInput;
  ProjectExpenseClaimHeaderID?: Maybe<Scalars['String']>;
}>;


export type IsProjectExpenseSubjectExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsProjectExpenseSubjectExists'>
);

export type GetApproverMasterListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApproverMasterListingQuery = (
  { __typename?: 'Query' }
  & { getApproverMasterListing?: Maybe<Array<(
    { __typename?: 'ProjectTimesheetApproverMasterEntity' }
    & Pick<ProjectTimesheetApproverMasterEntity, 'ID' | 'Name' | 'ClientName' | 'Department' | 'EmailAddress' | 'LastUpdatedDate' | 'ConfirmationDT'>
  )>> }
);

export type GetApproverAssignmentListingQueryVariables = Exact<{
  ProjectTimesheetApproverMasterID: Scalars['String'];
}>;


export type GetApproverAssignmentListingQuery = (
  { __typename?: 'Query' }
  & { getApproverAssignmentListing?: Maybe<Array<(
    { __typename?: 'ProjectTimesheetPeriodEntity' }
    & Pick<ProjectTimesheetPeriodEntity, 'ID' | 'PeriodFrom' | 'PeriodTo' | 'ApproverFilter' | 'InUse'>
    & { ProjectTimesheetApproverAssignment?: Maybe<Array<(
      { __typename?: 'ProjectTimesheetApproverAssignmentEntity' }
      & Pick<ProjectTimesheetApproverAssignmentEntity, 'ID' | 'EmployeeID'>
    )>> }
  )>> }
);

export type IsApproverEmailAlreadyExistQueryVariables = Exact<{
  EmailAddress: Scalars['String'];
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
}>;


export type IsApproverEmailAlreadyExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsApproverEmailAlreadyExist'>
);

export type ProjectTimesheetCheckPeriodOverlappingQueryVariables = Exact<{
  PeriodFrom: Scalars['DateTime'];
  PeriodTo: Scalars['DateTime'];
  ProjectTimesheetPeriodID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
}>;


export type ProjectTimesheetCheckPeriodOverlappingQuery = (
  { __typename?: 'Query' }
  & { ProjectTimesheetCheckPeriodOverlapping: (
    { __typename?: 'ProjectTimesheetCheckOverlapOutput' }
    & Pick<ProjectTimesheetCheckOverlapOutput, 'ApproverPeriodRangeOverlap'>
    & { EmployeeListingBySubscriptionAccount?: Maybe<Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'OrgUnitID' | 'EmployeeID' | 'EmployeeNo' | 'Status' | 'CompanyID' | 'isDailyEntryFilled'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'Description'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )> }
    )>> }
  ) }
);

export type ProjectTimesheetCheckPeriodOverlappingCountQueryVariables = Exact<{
  PeriodFrom: Scalars['DateTime'];
  PeriodTo: Scalars['DateTime'];
  ProjectTimesheetPeriodID?: Maybe<Scalars['String']>;
  ProjectTimesheetApproverMasterID?: Maybe<Scalars['String']>;
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
}>;


export type ProjectTimesheetCheckPeriodOverlappingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ProjectTimesheetCheckPeriodOverlappingCount'>
);

export type SubmitApproverMasterMutationVariables = Exact<{
  mode: Scalars['String'];
  input: ProjectTimesheetApproverMasterInput;
  accountName: Scalars['String'];
}>;


export type SubmitApproverMasterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitApproverMaster'>
);

export type DeleteApproverMasterMutationVariables = Exact<{
  ProjectTimesheetApproverMasterID: Scalars['String'];
}>;


export type DeleteApproverMasterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteApproverMaster'>
);

export type SubmitApproverAssignmentMutationVariables = Exact<{
  mode: Scalars['String'];
  input: ProjectTimesheetApproverAssignmentInput;
  selectAllEmployee: Scalars['Boolean'];
}>;


export type SubmitApproverAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitApproverAssignment'>
);

export type DeleteApproverEmployeeListingMutationVariables = Exact<{
  ProjectTimesheetPeriodID: Scalars['String'];
}>;


export type DeleteApproverEmployeeListingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteApproverEmployeeListing'>
);

export type ResendConfirmationEmailMutationVariables = Exact<{
  ProjectTimesheetApproverMasterID: Scalars['String'];
  accountName: Scalars['String'];
}>;


export type ResendConfirmationEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ResendConfirmationEmail'>
);

export type ApproverConfirmedEmailActionMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ApproverConfirmedEmailActionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ApproverConfirmedEmailAction'>
);

export type ProjectTimesheetPeriodListingQueryVariables = Exact<{
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
}>;


export type ProjectTimesheetPeriodListingQuery = (
  { __typename?: 'Query' }
  & { ProjectTimesheetPeriodListing: Array<(
    { __typename?: 'ProjectTimesheetPeriodListingOutput' }
    & Pick<ProjectTimesheetPeriodListingOutput, 'ProjectTimesheetMonthlyRecordID' | 'PeriodYearMonth' | 'PeriodFromDate' | 'PeriodToDate' | 'PeriodStatus' | 'PeriodInfoline' | 'Remarks' | 'isAssignedToApprover'>
  )> }
);

export type DailyEntryListingQueryVariables = Exact<{
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
}>;


export type DailyEntryListingQuery = (
  { __typename?: 'Query' }
  & { DailyEntryListing: Array<(
    { __typename?: 'ProjectTimesheetDailyEntryEntity' }
    & Pick<ProjectTimesheetDailyEntryEntity, 'ID' | 'EntryDate' | 'StartTime' | 'EndTime' | 'BreakTime' | 'TotalHours' | 'Remarks'>
    & { ProjectTimesheetMonthlyRecord?: Maybe<(
      { __typename?: 'ProjectTimesheetMonthlyRecordEntity' }
      & Pick<ProjectTimesheetMonthlyRecordEntity, 'ID'>
    )> }
  )> }
);

export type ProjectTimesheetSubmissionListingQueryVariables = Exact<{
  EmployeeCalendar: Scalars['Boolean'];
  DutyRoster: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
}>;


export type ProjectTimesheetSubmissionListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ProjectTimesheetSubmissionListing'>
);

export type SubmitDailyEntryMutationVariables = Exact<{
  mode: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  input: ProjectTimesheetDailyEntryInput;
}>;


export type SubmitDailyEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitDailyEntry'>
);

export type DeleteDailyEntryMutationVariables = Exact<{
  ProjectTimesheetDailyEntryID: Scalars['String'];
}>;


export type DeleteDailyEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteDailyEntry'>
);

export type SubmitProjectTimesheetMutationVariables = Exact<{
  ProjectTimesheetMonthlyRecordID: Scalars['String'];
  input: Array<ProjectTimesheetDailyEntryInput> | ProjectTimesheetDailyEntryInput;
}>;


export type SubmitProjectTimesheetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitProjectTimesheet'>
);

export type ProjectTimesheetApprovalListingQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type ProjectTimesheetApprovalListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ProjectTimesheetApprovalListing'>
);

export type SubmitProjectTimesheetApprovalMutationVariables = Exact<{
  token: Scalars['String'];
  StatusToChange: WorkflowLogStatus;
  Remarks?: Maybe<Scalars['String']>;
}>;


export type SubmitProjectTimesheetApprovalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitProjectTimesheetApproval'>
);

export type ProjectTimesheetPeriodQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type ProjectTimesheetPeriodQuery = (
  { __typename?: 'Query' }
  & { ProjectTimesheetPeriod: Array<(
    { __typename?: 'ProjectTimesheetMonthlyRecordEntity' }
    & Pick<ProjectTimesheetMonthlyRecordEntity, 'PeriodYearMonth' | 'CompanyID' | 'ID'>
  )> }
);

export type ProjectTimesheetApproverQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectTimesheetApproverQuery = (
  { __typename?: 'Query' }
  & { ProjectTimesheetApprover: Array<(
    { __typename?: 'ProjectTimesheetApproverMasterEntity' }
    & Pick<ProjectTimesheetApproverMasterEntity, 'Name'>
  )> }
);

export type GetEmployeesLeaveSummaryReportQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  AsToDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  Level?: Maybe<Scalars['Float']>;
  Sort?: Maybe<Scalars['Float']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  IsResigned?: Maybe<Scalars['Boolean']>;
  IsReport?: Maybe<Scalars['Boolean']>;
}>;


export type GetEmployeesLeaveSummaryReportQuery = (
  { __typename?: 'Query' }
  & { GetEmployeesLeaveSummaryReport?: Maybe<Array<(
    { __typename?: 'EmployeeLeavesSummary' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'Status' | 'ResignedDate'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'Gender' | 'Marital'>
      )> }
    )>, EmployeeLeavePolicies?: Maybe<Array<(
      { __typename?: 'EmployeeLeavePolicy' }
      & { LeavePolicy?: Maybe<(
        { __typename?: 'LeaveItemEntity' }
        & Pick<LeaveItemEntity, 'IsAdvanceLeave' | 'TotalAdvanceDay' | 'AdvanceStartMonth' | 'AdvanceEndMonth' | 'Title' | 'LeaveTypeID' | 'LeaveFrequency'>
        & { LeaveType?: Maybe<(
          { __typename?: 'LeaveTypeEntity' }
          & Pick<LeaveTypeEntity, 'LeaveTypeID' | 'LeaveTypeCode' | 'Name'>
        )> }
      )>, LeaveBalanceSummary?: Maybe<(
        { __typename?: 'LeaveBalanceSummary' }
        & Pick<LeaveBalanceSummary, 'Entitled' | 'BroughtForward' | 'Adjustment' | 'Earned' | 'Taken' | 'TakenYTD' | 'Forfeited' | 'Balance' | 'BalanceYTD'>
      )> }
    )>> }
  )>> }
);

export type GetCompanyCyclePeriodListV2QueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanyCyclePeriodListV2Query = (
  { __typename?: 'Query' }
  & { GetCompanyCyclePeriodListV2?: Maybe<Array<(
    { __typename?: 'CompanyCyclePeriodListOutput' }
    & Pick<CompanyCyclePeriodListOutput, 'CompanyID' | 'CompanyName' | 'PayPeriodID' | 'PayrollCycleID' | 'PayrollStatus' | 'IsLastProcess' | 'PeriodYearMonth' | 'MMMYYYY' | 'PayCycleName'>
  )>> }
);

export type GetSignatoryListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSignatoryListingQuery = (
  { __typename?: 'Query' }
  & { GetSignatoryListing?: Maybe<Array<(
    { __typename?: 'EmployeeListingOutput' }
    & Pick<EmployeeListingOutput, 'EmployeeID' | 'CompanyID' | 'EmployeeNo' | 'EmployeeStatus' | 'FullName' | 'EmployeeNoName'>
  )>> }
);

export type GetBankInfoListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBankInfoListingQuery = (
  { __typename?: 'Query' }
  & { GetBankInfoListing: Array<(
    { __typename?: 'BankInfoOutput' }
    & Pick<BankInfoOutput, 'BankInfoID' | 'CompanyID' | 'Name' | 'AccountName' | 'AccountNo'>
  )> }
);

export type GetBankInfoListingV2QueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
}>;


export type GetBankInfoListingV2Query = (
  { __typename?: 'Query' }
  & { GetBankInfoListingV2: Array<(
    { __typename?: 'BankInfoOutput' }
    & Pick<BankInfoOutput, 'BankInfoID' | 'CompanyID' | 'Name' | 'AccountName' | 'AccountNo'>
  )> }
);

export type GetPayrollReportListingQueryVariables = Exact<{
  CompanyStatutoryAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  PayCycleIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
  BankInfoID?: Maybe<Scalars['String']>;
  ReportType: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
}>;


export type GetPayrollReportListingQuery = (
  { __typename?: 'Query' }
  & { GetPayrollReportListing?: Maybe<Array<(
    { __typename?: 'PayrollReportOutput' }
    & Pick<PayrollReportOutput, 'CompanyID' | 'EmployeeID'>
  )>> }
);

export type GetCompanyStatutoryListingQueryVariables = Exact<{
  Types?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetCompanyStatutoryListingQuery = (
  { __typename?: 'Query' }
  & { GetCompanyStatutoryListing?: Maybe<Array<(
    { __typename?: 'CompanyStatutoryOutput' }
    & Pick<CompanyStatutoryOutput, 'CompanyID' | 'CompanyStatutoryID' | 'CompanyStatutoryAccountID' | 'CompanyName' | 'Type' | 'Description' | 'AccountNo' | 'AccNoDesc'>
  )>> }
);

export type GetCompanyReportYearListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyReportYearListingQuery = (
  { __typename?: 'Query' }
  & { GetCompanyReportYearListing?: Maybe<Array<(
    { __typename?: 'ReportYearOutput' }
    & Pick<ReportYearOutput, 'Year'>
  )>> }
);

export type ClaimReportClaimTypeCompanyListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type ClaimReportClaimTypeCompanyListingQuery = (
  { __typename?: 'Query' }
  & { ClaimTypeListing: Array<(
    { __typename?: 'ClaimTypeEntity' }
    & Pick<ClaimTypeEntity, 'ClaimTypeID' | 'Name'>
  )> }
);

export type ClaimDetailReportListingQueryVariables = Exact<{
  Input: ClaimDetailListingReportInput;
}>;


export type ClaimDetailReportListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ClaimDetailReportListing'>
);

export type GetPayItemListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type GetPayItemListingQuery = (
  { __typename?: 'Query' }
  & { GetPayItemListing?: Maybe<Array<(
    { __typename?: 'ReportPayItemOutput' }
    & Pick<ReportPayItemOutput, 'PayItemID' | 'SubscriptionAccountID' | 'PayName' | 'PayItemType' | 'PayItemTypeName'>
  )>> }
);

export type PayrollDetailListingExportExcelQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayCycleIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitIDs: Scalars['String'];
  JobGradeIDs: Scalars['String'];
  StaffCategoryIDs: Scalars['String'];
}>;


export type PayrollDetailListingExportExcelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PayrollDetailListingExportExcel'>
);

export type GetEmployeeListingWithPayrollInputQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayCycleIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetEmployeeListingWithPayrollInputQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeListingWithPayrollInput?: Maybe<Array<(
    { __typename?: 'EmployeeListingOutput' }
    & Pick<EmployeeListingOutput, 'EmployeeID' | 'EmployeeNoName'>
  )>> }
);

export type RequisitionManagementApprovalUpdateMutationVariables = Exact<{
  input: Array<RequisitionUpdateInput> | RequisitionUpdateInput;
  IsSupersede?: Maybe<Scalars['Boolean']>;
}>;


export type RequisitionManagementApprovalUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RequisitionManagementApprovalUpdate'>
);

export type RequisitionApprovalPolicySummaryQueryVariables = Exact<{
  Category?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type RequisitionApprovalPolicySummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ApprovalPolicySummary'>
);

export type RequisitionMenuSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type RequisitionMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RequisitionMenuSummary'>
);

export type RequisitionManagementListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  RequisitionType: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  ApStatus: Array<Scalars['String']> | Scalars['String'];
  filterText: Scalars['String'];
}>;


export type RequisitionManagementListingQuery = (
  { __typename?: 'Query' }
  & { RequisitionManagementListing?: Maybe<Array<(
    { __typename?: 'RequisitionOutput' }
    & Pick<RequisitionOutput, 'RequisitionHeaderID' | 'ApprovalStatus' | 'EmployeeID' | 'EmployeeName' | 'JobGradeID' | 'OrgUnitID' | 'FormStatus' | 'EmployeeNo' | 'RequisitionType' | 'TravelRequestID' | 'Subject' | 'Remark' | 'NumberOfItem' | 'CostPerItem' | 'Amount' | 'StartDate' | 'EndDate' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'PurposeOfTravel' | 'DocumentID' | 'DocumentName' | 'DocumentFile' | 'ApprovalDT' | 'ApproverID' | 'ApprovedBy' | 'CreatedDT' | 'SubmitterName' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'RejectedRemarks' | 'CancelledDate' | 'CancelledBy' | 'ReportTo' | 'OtherReqNo' | 'TravelReqNo' | 'AssetReqNo' | 'CashReqNo'>
    & { DocumentIDs?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName' | 'NotifiedDT'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT' | 'ApproverName'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type ApprovalPolicyAssignmentSummaryQueryVariables = Exact<{
  Category: Array<Scalars['String']> | Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type ApprovalPolicyAssignmentSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ApprovalPolicyAssignmentSummary'>
);

export type RequisitionManagementListingTotalCountQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  RequisitionType: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus: Array<Scalars['String']> | Scalars['String'];
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  ApStatus: Array<Scalars['String']> | Scalars['String'];
}>;


export type RequisitionManagementListingTotalCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RequisitionManagementListingTotalCount'>
);

export type ScaleRatingListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ScaleRatingListingQuery = (
  { __typename?: 'Query' }
  & { ScaleRatingListing: Array<(
    { __typename?: 'ScaleRatingHeaderEntity' }
    & Pick<ScaleRatingHeaderEntity, 'ScaleRatingHeaderID' | 'SubscriptionAccountID' | 'Title' | 'Scale' | 'isInUse'>
    & { ScaleRatingDetails?: Maybe<Array<(
      { __typename?: 'ScaleRatingDetailEntity' }
      & Pick<ScaleRatingDetailEntity, 'ScaleRatingDetailID' | 'Description' | 'ScaleSequence'>
    )>> }
  )> }
);

export type ScaleRatingSubmitMutationVariables = Exact<{
  input: ScaleRatingHeaderInput;
}>;


export type ScaleRatingSubmitMutation = (
  { __typename?: 'Mutation' }
  & { ScaleRatingSubmit: (
    { __typename?: 'ScaleRatingHeaderEntity' }
    & Pick<ScaleRatingHeaderEntity, 'ScaleRatingHeaderID'>
  ) }
);

export type ScaleRatingDeleteMutationVariables = Exact<{
  ScaleRatingHeaderID: Scalars['String'];
}>;


export type ScaleRatingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ScaleRatingDelete'>
);

export type ScheduleTableSubmitMutationVariables = Exact<{
  ScheduleTrainingID: Scalars['String'];
  input: Array<TrainingScheduleTableInput> | TrainingScheduleTableInput;
}>;


export type ScheduleTableSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ScheduleTableSubmit'>
);

export type ScheduleTrainingSubmitMutationVariables = Exact<{
  input: ScheduleTrainingInput;
}>;


export type ScheduleTrainingSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ScheduleTrainingSubmit'>
);

export type ScheduleTrainingDeleteMutationVariables = Exact<{
  ScheduleTrainingID: Scalars['String'];
}>;


export type ScheduleTrainingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ScheduleTrainingDelete'>
);

export type ScheduleTrainingCancelMutationVariables = Exact<{
  ScheduleTrainingID: Scalars['String'];
}>;


export type ScheduleTrainingCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ScheduleTrainingCancel'>
);

export type RegisteredTraineeListingQueryVariables = Exact<{
  ScheduleTrainingID: Scalars['String'];
}>;


export type RegisteredTraineeListingQuery = (
  { __typename?: 'Query' }
  & { RegisteredTraineeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'DocumentID' | 'TotalTaxRelief' | 'FormStatus' | 'EmploymentType' | 'CreatedDT' | 'ModifiedDT'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, EmployeeStatutory?: Maybe<(
      { __typename?: 'EmployeeStatutoryEntity' }
      & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID'>
    )>, EmployeePrevContribution?: Maybe<(
      { __typename?: 'EmployeePrevContributionEntity' }
      & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
    )>, EmployeeDependents?: Maybe<Array<(
      { __typename?: 'EmployeeDependentsEntity' }
      & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID'>
    )>>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'DepartmentType' | 'ParentID' | 'Description'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description' | 'ParentID'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )> }
  )> }
);

export type RegisteredTraineeDeleteMutationVariables = Exact<{
  RegisteredTraineeID: Scalars['String'];
  ScheduleTrainingID: Scalars['String'];
}>;


export type RegisteredTraineeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RegisteredTraineeDelete'>
);

export type GetLatestUpdateForMainMenuModuleQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetLatestUpdateForMainMenuModuleQuery = (
  { __typename?: 'Query' }
  & { GetLatestUpdateForMainMenuModule: (
    { __typename?: 'TraningModuleLatestUpdateOutput' }
    & Pick<TraningModuleLatestUpdateOutput, 'CostBudget' | 'ScheduledTrainig' | 'ScheduledTrainingCount' | 'TraineeEval' | 'CourseEval' | 'TrainerEval' | 'SupervisorEval' | 'TrainingCost' | 'CostAllocation' | 'HRDFClaim'>
  ) }
);

export type GetEmployeeKpiPlanningByYearAppraisalManagementQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus: Array<Scalars['String']> | Scalars['String'];
  EmployeeType: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetEmployeeKpiPlanningByYearAppraisalManagementQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeKPIPlanningByYear: Array<(
    { __typename?: 'EmployeePlanningOutput' }
    & Pick<EmployeePlanningOutput, 'EmployeeID' | 'Name' | 'JobGradeID' | 'QuaterType' | 'OrgUnitID' | 'FormStatus'>
    & { PlanningList?: Maybe<Array<(
      { __typename?: 'EmployeeKPIHeaderEntity' }
      & Pick<EmployeeKpiHeaderEntity, 'EmployeeKPIHeaderID' | 'Year' | 'Version' | 'AppraisalTempleteID' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>> }
  )> }
);

export type GetEmployeeListingByscheduledAndQuaterQueryVariables = Exact<{
  QuaterID: Scalars['String'];
  ScheduledID: Scalars['String'];
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus: Array<Scalars['String']> | Scalars['String'];
  EmployeeType: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetEmployeeListingByscheduledAndQuaterQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeListingByscheduledAndQuater: Array<(
    { __typename?: 'AppraisalEmployeeLisitngOutput' }
    & Pick<AppraisalEmployeeLisitngOutput, 'EmployeeID' | 'SubscriptionUserID' | 'EmployeeNo' | 'EmploymentType' | 'Status' | 'AppraisalStatus' | 'FormStatus' | 'OrgUnitID' | 'ResignedDate' | 'JobGradeID'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, Division?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description' | 'ParentID'>
    )> }
  )> }
);

export type StaffPerformanceAnalysisSummaryListingQueryVariables = Exact<{
  QuaterID: Scalars['String'];
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus: Array<Scalars['String']> | Scalars['String'];
  EmployeeType: Array<Scalars['String']> | Scalars['String'];
}>;


export type StaffPerformanceAnalysisSummaryListingQuery = (
  { __typename?: 'Query' }
  & { StaffPerformanceAnalysisSummaryListing: Array<(
    { __typename?: 'StaffPerformanceAnalysisSummaryOutput' }
    & Pick<StaffPerformanceAnalysisSummaryOutput, 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'EmployeeSalary' | 'FinalScore' | 'FinalRating'>
    & { EmployeeDetails?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'DepartmentID' | 'OrgUnitID' | 'DivisionID'>
      & { EmployeeCareerLogs?: Maybe<Array<(
        { __typename?: 'CareerLogEntity' }
        & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'SalaryType' | 'FromSalary' | 'ToSalary'>
      )>> }
    )>, RatingAdjustment?: Maybe<(
      { __typename?: 'StaffPerformanceAnalysisSummaryEntity' }
      & Pick<StaffPerformanceAnalysisSummaryEntity, 'StaffPerformanceAnalysisSummaryID' | 'Year' | 'RatingAdjustment' | 'QuaterID'>
    )> }
  )> }
);

export type StaffPerformanceAnalysisSummarySubmitMutationVariables = Exact<{
  input: Array<StaffPerformanceAnalysisSummaryInput> | StaffPerformanceAnalysisSummaryInput;
}>;


export type StaffPerformanceAnalysisSummarySubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffPerformanceAnalysisSummarySubmit'>
);

export type GetBonusRecordByCompanyOrgUnitListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  QuaterID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type GetBonusRecordByCompanyOrgUnitListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetBonusRecordByCompanyOrgUnitList'>
);

export type BonusSimulationListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  QuaterID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type BonusSimulationListingQuery = (
  { __typename?: 'Query' }
  & { BonusSimulationListing: Array<(
    { __typename?: 'BonusSimulationEntity' }
    & Pick<BonusSimulationEntity, 'BonusSimulationID' | 'OrgUnitID' | 'CompanyID' | 'QuaterID' | 'Year' | 'Version' | 'Rate' | 'QualifierID' | 'Months' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type BonusSimulationSubmitMutationVariables = Exact<{
  BonusSimulationInput: Array<BonusSimulationInput> | BonusSimulationInput;
}>;


export type BonusSimulationSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BonusSimulationSubmit'>
);

export type GetIncrementRecordByCompanyOrgUnitListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  QuaterID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type GetIncrementRecordByCompanyOrgUnitListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetIncrementRecordByCompanyOrgUnitList'>
);

export type IncrementSimulationListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  OrgUnitID: Scalars['String'];
  QuaterID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type IncrementSimulationListingQuery = (
  { __typename?: 'Query' }
  & { IncrementSimulationListing: Array<(
    { __typename?: 'IncrementSimulationEntity' }
    & Pick<IncrementSimulationEntity, 'IncrementSimulationID' | 'OrgUnitID' | 'CompanyID' | 'QuaterID' | 'Year' | 'Version' | 'Rate' | 'QualifierID' | 'Percentage' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type IncrementSimulationSubmitMutationVariables = Exact<{
  IncrementSimulationInput: Array<IncrementSimulationInput> | IncrementSimulationInput;
}>;


export type IncrementSimulationSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'IncrementSimulationSubmit'>
);

export type GetKpiPlanningDetailsQueryVariables = Exact<{
  EmployeeKPIHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetKpiPlanningDetailsQuery = (
  { __typename?: 'Query' }
  & { GetKPIPlanningDetails: Array<(
    { __typename?: 'EmployeeKPICategoryEntity' }
    & Pick<EmployeeKpiCategoryEntity, 'EmployeeKPICategoryID' | 'AppraisalCategoryID' | 'Weightage'>
    & { EmployeeKPIDetail?: Maybe<Array<(
      { __typename?: 'EmployeeKPIDetailEntity' }
      & Pick<EmployeeKpiDetailEntity, 'EmployeeKPIDetailID' | 'AppraisalCategoryItemID' | 'Weightage' | 'Title' | 'Description'>
      & { EmployeeKPIDetailRating?: Maybe<Array<(
        { __typename?: 'EmployeeKPIDetailRatingEntity' }
        & Pick<EmployeeKpiDetailRatingEntity, 'ScaleSequence' | 'Description'>
      )>> }
    )>> }
  )> }
);

export type GetEmployeeKpiPlanningRecordQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type GetEmployeeKpiPlanningRecordQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeKPIPlanningRecord: (
    { __typename?: 'EmployeePlanningOutput' }
    & Pick<EmployeePlanningOutput, 'EmployeeID'>
    & { PlanningList?: Maybe<Array<(
      { __typename?: 'EmployeeKPIHeaderEntity' }
      & Pick<EmployeeKpiHeaderEntity, 'EmployeeKPIHeaderID' | 'Year' | 'Version' | 'AppraisalTempleteID' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>> }
  ) }
);

export type GetEmployeeKpiPlanningByYearQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
}>;


export type GetEmployeeKpiPlanningByYearQuery = (
  { __typename?: 'Query' }
  & { GetEmployeeKPIPlanningByYear: Array<(
    { __typename?: 'EmployeePlanningOutput' }
    & Pick<EmployeePlanningOutput, 'EmployeeID' | 'JobGradeID' | 'QuaterType' | 'OrgUnitID' | 'FormStatus'>
    & { PlanningList?: Maybe<Array<(
      { __typename?: 'EmployeeKPIHeaderEntity' }
      & Pick<EmployeeKpiHeaderEntity, 'EmployeeKPIHeaderID' | 'Year' | 'Version' | 'AppraisalTempleteID' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    )>> }
  )> }
);

export type SubmitStaffKpiPlanningMutationVariables = Exact<{
  Input: EmployeeKpiHeaderInput;
  Submmit_Type: Scalars['String'];
  LatestVersion?: Maybe<Scalars['String']>;
}>;


export type SubmitStaffKpiPlanningMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitStaffKPIPlanning'>
);

export type StaffKpiPlannigDeleteMutationVariables = Exact<{
  EmployeeKPIHeaderID: Scalars['String'];
}>;


export type StaffKpiPlannigDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffKPIPlannigDelete'>
);

export type AppraisalCategoryListingQueryVariables = Exact<{
  AppraisalType: Scalars['String'];
}>;


export type AppraisalCategoryListingQuery = (
  { __typename?: 'Query' }
  & { AppraisalCategoryListing: Array<(
    { __typename?: 'AppraisalCategoryEntity' }
    & Pick<AppraisalCategoryEntity, 'AppraisalCategoryID' | 'Name' | 'AppraisalType' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { AppraisalCategoryItems?: Maybe<Array<(
      { __typename?: 'AppraisalCategoryItemEntity' }
      & Pick<AppraisalCategoryItemEntity, 'AppraisalCategoryItemID' | 'AppraisalCategoryID' | 'Name' | 'Description' | 'AppraisalItemType' | 'QuantitativeType' | 'SubscriptionAccountID' | 'Status'>
    )>> }
  )> }
);

export type SubordinateListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SubordinateListingQuery = (
  { __typename?: 'Query' }
  & { SubordinateListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'CompanyID' | 'EmployeeNo' | 'EmploymentType' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName' | 'CompanyID'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'BucketFileName' | 'Description' | 'DocumentFile' | 'DocumentID'>
    )> }
  )> }
);

export type CareerLogListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type CareerLogListingQuery = (
  { __typename?: 'Query' }
  & { CareerLogListing: Array<(
    { __typename?: 'CareerLogEntity' }
    & Pick<CareerLogEntity, 'CareerLogID' | 'EmployeeID' | 'FromCompany' | 'ToCompany' | 'FromDepartment' | 'ToDepartment' | 'FromDivision' | 'ToDivision' | 'FromPosition' | 'ToPosition' | 'FromJobGrade' | 'ToJobGrade' | 'FromSalary' | 'ToSalary' | 'ToOrgUnit' | 'SalaryAdjustmentAmount' | 'EffectiveDate' | 'DocumentID' | 'ReportTo' | 'Remark' | 'MovementType' | 'Status' | 'SalaryType' | 'ExitReason' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'JoinedDate' | 'PrevConfirmedDate'>
    & { FromCompanyTable?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, ToCompanyTable?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'JoinedDate'>
    )>, ToPositionTable?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, ToDepartmentTable?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
    )>, ToOrgUnitTable?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'Description'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'Description' | 'DocumentFile' | 'DocumentType'>
    )> }
  )> }
);

export type GetOrgChartQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type GetOrgChartQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetOrgChart'>
);

export type SubordinateMenuSummaryQueryVariables = Exact<{
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
  ApproverID: Scalars['String'];
}>;


export type SubordinateMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SubordinateMenuSummary'>
);

export type SubordinateTreeQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  ModuleName?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
}>;


export type SubordinateTreeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SubordinateTree'>
);

export type SubordinateTreeTimeAttendanceQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  ModuleName?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type SubordinateTreeTimeAttendanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SubordinateTreeTimeAttendance'>
);

export type EmployeeAppSummaryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CompanyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type EmployeeAppSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeAppSummary'>
);

export type SystemAdminCompanyStatutoryDetailQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type SystemAdminCompanyStatutoryDetailQuery = (
  { __typename?: 'Query' }
  & { CompanyStatutoryDetail?: Maybe<(
    { __typename?: 'CompanyStatutoryEntity' }
    & Pick<CompanyStatutoryEntity, 'CompanyStatutoryID' | 'SignBy' | 'EPFNo' | 'SocsoNo' | 'PCBNo' | 'EISNo' | 'IsAngkasa' | 'AngkasaNo' | 'IsASB' | 'ASBNo' | 'IsKWAP' | 'KWAPNo' | 'IsTBH' | 'TBHNo' | 'IsZakat' | 'ZakatNo' | 'HRDFNo' | 'HRDFRate' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { SignByCompany?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, EPFAccount?: Maybe<Array<(
      { __typename?: 'StatutoryAccount' }
      & Pick<StatutoryAccount, 'AccountNo' | 'Description' | 'IsDefault'>
    )>>, SocsoAccount?: Maybe<Array<(
      { __typename?: 'StatutoryAccount' }
      & Pick<StatutoryAccount, 'AccountNo' | 'Description' | 'IsDefault'>
    )>>, PCBAccount?: Maybe<Array<(
      { __typename?: 'StatutoryAccount' }
      & Pick<StatutoryAccount, 'AccountNo' | 'Description' | 'IsDefault'>
    )>>, EISAccount?: Maybe<Array<(
      { __typename?: 'StatutoryAccount' }
      & Pick<StatutoryAccount, 'AccountNo' | 'Description' | 'IsDefault'>
    )>> }
  )> }
);

export type CreateCompanyAccessPermMutationVariables = Exact<{
  Input: Array<CompanyAccessPermInput> | CompanyAccessPermInput;
  CompanyID: Scalars['String'];
  RoleID: Scalars['String'];
}>;


export type CreateCompanyAccessPermMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateCompanyAccessPerm'>
);

export type UserRoleIDsQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type UserRoleIDsQuery = (
  { __typename?: 'Query' }
  & { userRoleIDs: Array<(
    { __typename?: 'userRoleID' }
    & Pick<UserRoleId, 'roleID' | 'userID'>
  )> }
);

export type DocumentNumberAssignmentMutationVariables = Exact<{
  input: Array<DocumentNumberAssignmentInput> | DocumentNumberAssignmentInput;
}>;


export type DocumentNumberAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DocumentNumberAssignment'>
);

export type NewDocumentNumberingInsertMutationVariables = Exact<{
  input: NewDocumentNumberingInsertInput;
}>;


export type NewDocumentNumberingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NewDocumentNumberingInsert'>
);

export type CompanyDocumentNumberingUpdateMutationVariables = Exact<{
  input: NewDocumentNumberingInsertInput;
}>;


export type CompanyDocumentNumberingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyDocumentNumberingUpdate'>
);

export type DocumentNumberingAssignmentListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type DocumentNumberingAssignmentListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DocumentNumberingAssignmentList'>
);

export type CompanyStructureInsertMutationVariables = Exact<{
  Input: Array<CompanyHierarchyInput> | CompanyHierarchyInput;
}>;


export type CompanyStructureInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyStructureInsert'>
);

export type EmployeeListingBySubscriptionQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  DepartmentIDs: Array<Scalars['String']> | Scalars['String'];
  EmployeeStatus: Array<Scalars['String']> | Scalars['String'];
  EmployeeType: Array<Scalars['String']> | Scalars['String'];
}>;


export type EmployeeListingBySubscriptionQuery = (
  { __typename?: 'Query' }
  & { CompanyListing: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'CompanyName' | 'DocumentID'>
    & { Documents?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
    )>>, Employees?: Maybe<Array<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'DocumentID' | 'EmploymentType' | 'CreatedDT' | 'ModifiedDT'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'MobileNo' | 'Email'>
      )>, EmployeeStatutory?: Maybe<(
        { __typename?: 'EmployeeStatutoryEntity' }
        & Pick<EmployeeStatutoryEntity, 'EmployeeStatutoryID'>
      )>, EmployeePrevContribution?: Maybe<(
        { __typename?: 'EmployeePrevContributionEntity' }
        & Pick<EmployeePrevContributionEntity, 'EmployeePrevContributionID'>
      )>, EmployeeDependents?: Maybe<Array<(
        { __typename?: 'EmployeeDependentsEntity' }
        & Pick<EmployeeDependentsEntity, 'EmployeeDependentsID'>
      )>>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'DepartmentID' | 'DepartmentType' | 'ParentID' | 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'BucketFileName' | 'DocumentFile' | 'Description'>
      )> }
    )>> }
  )> }
);

export type AssignJobPositionMutationVariables = Exact<{
  Input: Array<JobPositionAssignmentInput> | JobPositionAssignmentInput;
  CompanyID?: Maybe<Scalars['String']>;
  OrgUnitID?: Maybe<Scalars['String']>;
}>;


export type AssignJobPositionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignJobPosition'>
);

export type JobPositionByOrgUnitListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  OrgUnitID?: Maybe<Scalars['String']>;
}>;


export type JobPositionByOrgUnitListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPositionAssignmentList'>
);

export type JobPositionByCompanyIdArrayQueryVariables = Exact<{
  CompanyIDArray?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type JobPositionByCompanyIdArrayQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPositionAssignmentList'>
);

export type CompanyOrgUnitByCompanyIdArrayQueryVariables = Exact<{
  CompanyIDArray?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CompanyOrgUnitByCompanyIdArrayQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyOrgUnitList'>
);

export type CompanyHierarchyByCompanyIdArrayQueryVariables = Exact<{
  CompanyIDArray?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CompanyHierarchyByCompanyIdArrayQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyHierarchyList'>
);

export type CompanyOrgUnitInsertMutationVariables = Exact<{
  Input: Array<CompanyOrgUnitInput> | CompanyOrgUnitInput;
}>;


export type CompanyOrgUnitInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyOrgUnitInsert'>
);

export type CompanyOrgUnitUpdateMutationVariables = Exact<{
  Inputs: Array<CompanyOrgUnitInput> | CompanyOrgUnitInput;
  OrgUnitIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type CompanyOrgUnitUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyOrgUnitUpdate'>
);

export type JobPositionByOrgUnitListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type JobPositionByOrgUnitListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'JobPositionByOrgUnitListing'>
);

export type CreateEntityRoleUserMutationVariables = Exact<{
  input: EntityRoleUserAsgInput;
}>;


export type CreateEntityRoleUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateEntityRoleUser'>
);

export type CompanyStatutoryInsertMutationVariables = Exact<{
  CompanyStatutoryInput: CompanyStatutoryInput;
}>;


export type CompanyStatutoryInsertMutation = (
  { __typename?: 'Mutation' }
  & { CompanyStatutoryInsert: (
    { __typename?: 'CompanyStatutoryEntity' }
    & Pick<CompanyStatutoryEntity, 'CompanyStatutoryID' | 'CompanyID'>
  ) }
);

export type ReportingGroupListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportingGroupListingQuery = (
  { __typename?: 'Query' }
  & { ReportingGroupListing: Array<(
    { __typename?: 'ReportingGroupEntity' }
    & Pick<ReportingGroupEntity, 'reporting_group_id' | 'name' | 'created_dt' | 'modified_dt' | 'GroupInUse' | 'CompanyInUse'>
  )> }
);

export type ReportingGroupDeleteMutationVariables = Exact<{
  ReportingGroupID: Scalars['String'];
}>;


export type ReportingGroupDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReportingGroupDelete'>
);

export type ReportingGroupSubmitMutationVariables = Exact<{
  input: ReportingGroupInput;
}>;


export type ReportingGroupSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReportingGroupSubmit'>
);

export type ReportingCompanyGroupListingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type ReportingCompanyGroupListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ReportingCompanyGroupListing'>
);

export type ReportingCompanyGroupDeleteMutationVariables = Exact<{
  ReportingGroupID: Scalars['String'];
}>;


export type ReportingCompanyGroupDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReportingCompanyGroupDelete'>
);

export type ReportingGroupTitleExistQueryVariables = Exact<{
  Title: Scalars['String'];
  ReportingGroupID?: Maybe<Scalars['String']>;
}>;


export type ReportingGroupTitleExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ReportingGroupTitleExist'>
);

export type ReportingStructureGroupSubmitMutationVariables = Exact<{
  input: ReportingStructureGroupInput;
}>;


export type ReportingStructureGroupSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReportingStructureGroupSubmit'>
);

export type GetReportingStructureListingQueryVariables = Exact<{
  ReportingGroupID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type GetReportingStructureListingQuery = (
  { __typename?: 'Query' }
  & { GetReportingStructureListing: (
    { __typename?: 'ReportingStructureOutput' }
    & Pick<ReportingStructureOutput, 'ReportingGroupID' | 'ItemActionType' | 'Title'>
    & { ReportingGroup?: Maybe<(
      { __typename?: 'ReportingGroupEntity' }
      & Pick<ReportingGroupEntity, 'name' | 'reporting_group_id'>
    )>, JobPositionReporting?: Maybe<Array<(
      { __typename?: 'JobPositionReportingOutput' }
      & Pick<JobPositionReportingOutput, 'ReportingStructureID' | 'EffectiveDate' | 'ItemActionType' | 'ReportingType'>
      & { JobPositionList?: Maybe<Array<(
        { __typename?: 'JobPositionReportingEntity' }
        & Pick<JobPositionReportingEntity, 'job_position_reporting_id' | 'from_org_unit_id' | 'to_org_unit_id' | 'from_job_position_id' | 'to_job_position_id' | 'to_company_id' | 'created_dt' | 'created_by' | 'modified_dt' | 'modified_by' | 'ItemActionType'>
        & { from_org_unit?: Maybe<(
          { __typename?: 'CompanyOrgUnitEntity' }
          & Pick<CompanyOrgUnitEntity, 'Description'>
        )>, to_org_unit?: Maybe<(
          { __typename?: 'CompanyOrgUnitEntity' }
          & Pick<CompanyOrgUnitEntity, 'Description'>
        )>, from_job_position?: Maybe<(
          { __typename?: 'JobDesignationEntity' }
          & Pick<JobDesignationEntity, 'Name'>
        )>, to_job_position?: Maybe<(
          { __typename?: 'JobDesignationEntity' }
          & Pick<JobDesignationEntity, 'Name'>
        )>, to_company?: Maybe<(
          { __typename?: 'CompanyEntity' }
          & Pick<CompanyEntity, 'CompanyName'>
        )> }
      )>> }
    )>>, EmployeeReporting?: Maybe<Array<(
      { __typename?: 'EmployeeReportingOutput' }
      & Pick<EmployeeReportingOutput, 'ReportingStructureID' | 'EffectiveDate' | 'ExpiryDate' | 'ReportingType' | 'ItemActionType' | 'SuperiorID' | 'SubordinateID'>
      & { Superior?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Designation?: Maybe<(
          { __typename?: 'JobDesignationEntity' }
          & Pick<JobDesignationEntity, 'Name'>
        )>, Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Subordinate?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Designation?: Maybe<(
          { __typename?: 'JobDesignationEntity' }
          & Pick<JobDesignationEntity, 'Name'>
        )>, Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>> }
  ) }
);

export type ReportingStructureGroupOptionListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type ReportingStructureGroupOptionListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ReportingStructureGroupOptionList'>
);

export type PersonnelTemplateLibraryAssignmentListingQueryVariables = Exact<{ [key: string]: never; }>;


export type PersonnelTemplateLibraryAssignmentListingQuery = (
  { __typename?: 'Query' }
  & { PersonnelTemplateLibraryAssignmentListing: (
    { __typename?: 'PersonnelTemplateLibraryAssignmentOutput' }
    & { JobGrade?: Maybe<Array<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
    )>>, SkillSet?: Maybe<Array<(
      { __typename?: 'SkillSetEntity' }
      & Pick<SkillSetEntity, 'Name' | 'SkillSetID'>
    )>>, JobPosition?: Maybe<Array<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name' | 'JobDesignationID'>
    )>>, InsuranceScheme?: Maybe<Array<(
      { __typename?: 'InsuranceSchemeEntity' }
      & Pick<InsuranceSchemeEntity, 'Name' | 'InsuranceSchemeID'>
    )>>, RecruitmentSource?: Maybe<Array<(
      { __typename?: 'RecruitmentSourceEntity' }
      & Pick<RecruitmentSourceEntity, 'Name' | 'RecruitmentSourceID'>
    )>>, UserDefine?: Maybe<Array<(
      { __typename?: 'UserDefineEntity' }
      & Pick<UserDefineEntity, 'Name' | 'UserDefineID'>
    )>> }
  ) }
);

export type GetTemplateLibraryAssignmentQueryVariables = Exact<{
  input?: Maybe<TemplateLibraryAssignmentInput>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
}>;


export type GetTemplateLibraryAssignmentQuery = (
  { __typename?: 'Query' }
  & { getTemplateLibraryAssignment: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TemplateLibraryAssignmentID' | 'SubscriptionAccountID' | 'CompanyID' | 'SourceID' | 'SourceType' | 'TargetID' | 'TargetType' | 'ModifiedDT'>
  )> }
);

export type ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutationVariables = Exact<{
  inputs: Array<TemplateLibraryAssignmentInput> | TemplateLibraryAssignmentInput;
}>;


export type ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'replaceTemplateLibraryAssignmentForTMSShiftGroup'>
);

export type TmsSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type TmsSummaryQuery = (
  { __typename?: 'Query' }
  & { TMSSummary?: Maybe<(
    { __typename?: 'TMSSummary' }
    & Pick<TmsSummary, 'ShiftGroup' | 'AttendanceExclusion' | 'EarnLeave' | 'TimeOff' | 'Overtime' | 'ChangeShift' | 'AttendanceRecord' | 'LateIn' | 'Absent' | 'IncompleteClocking' | 'DutyRosterDate' | 'AttendanceProcessing' | 'AttendancePosting' | 'IncompleteClockingEmployees' | 'LateInEmployees' | 'AbsentEmployee' | 'TodayData'>
  )> }
);

export type GetShiftGroupListingPageDataQueryVariables = Exact<{
  input?: Maybe<TemplateLibraryAssignmentInput>;
}>;


export type GetShiftGroupListingPageDataQuery = (
  { __typename?: 'Query' }
  & { getTemplateLibraryAssignment: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TargetID' | 'TargetType' | 'SourceID' | 'SourceType' | 'CompanyID' | 'Status'>
    & { Target?: Maybe<{ __typename?: 'CompanyEntity' } | { __typename?: 'DepartmentEntity' } | { __typename?: 'JobDesignationEntity' } | { __typename?: 'JobGradeEntity' } | { __typename?: 'DailyAllowancePolicyEntity' } | { __typename?: 'MonthlyAllowancePolicyEntity' } | { __typename?: 'DeductionPolicyEntity' } | { __typename?: 'ShiftEntity' } | { __typename?: 'ShiftPatternEntity' } | (
      { __typename?: 'ShiftGroupEntity' }
      & Pick<ShiftGroupEntity, 'ShiftGroupID' | 'Name' | 'ShiftCount' | 'EmployeeCount'>
      & { Employee?: Maybe<Array<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'CompanyID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Company?: Maybe<(
          { __typename?: 'CompanyEntity' }
          & Pick<CompanyEntity, 'CompanyName'>
        )>, JobGrade?: Maybe<(
          { __typename?: 'JobGradeEntity' }
          & Pick<JobGradeEntity, 'Description'>
        )>, ShiftGroupEmployeeAssignmentLogList: Array<(
          { __typename?: 'ShiftGroupEmployeeAssignmentLogEntity' }
          & Pick<ShiftGroupEmployeeAssignmentLogEntity, 'ShiftGroupID' | 'Status' | 'End' | 'Start'>
        )> }
      )>> }
    ) | { __typename?: 'TimeOffPolicyEntity' } | { __typename?: 'OvertimeRoundingSettingEntity' } | { __typename?: 'AllowanceDeductionRoundingEntity' } | { __typename?: 'OvertimeReasonEntity' } | { __typename?: 'TimeOffReasonEntity' } | { __typename?: 'MobileCheckInEntity' } | { __typename?: 'OvertimeClaimTableEntity' } | { __typename?: 'OvertimePolicyEntity' } | { __typename?: 'EmployeeEntity' } | { __typename?: 'AttendanceRecordEntity' } | { __typename?: 'SkillSetEntity' } | { __typename?: 'InsuranceSchemeEntity' } | { __typename?: 'RecruitmentSourceEntity' } | { __typename?: 'UserDefineEntity' }> }
  )> }
);

export type ShiftGroupEmployeeListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  exitReason?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  Page?: Maybe<Scalars['String']>;
}>;


export type ShiftGroupEmployeeListQuery = (
  { __typename?: 'Query' }
  & { EmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'JoinedDate' | 'EmployeeID' | 'CompanyID' | 'EmployeeNo' | 'Status' | 'ResignedDate' | 'FormStatus'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'Description'>
    )>, ShiftGroupEmployeeAssignmentLogList: Array<(
      { __typename?: 'ShiftGroupEmployeeAssignmentLogEntity' }
      & Pick<ShiftGroupEmployeeAssignmentLogEntity, 'ShiftGroupID' | 'Start' | 'End' | 'Status'>
      & { ShiftGroup?: Maybe<(
        { __typename?: 'ShiftGroupEntity' }
        & Pick<ShiftGroupEntity, 'Name'>
      )> }
    )> }
  )> }
);

export type ShiftGroupEmployeeListCountQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  exitReason?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  Page?: Maybe<Scalars['String']>;
}>;


export type ShiftGroupEmployeeListCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeListingCount'>
);

export type GetShiftGroupFormAssignedEmployeesDataQueryVariables = Exact<{
  input?: Maybe<TemplateLibraryAssignmentInput>;
}>;


export type GetShiftGroupFormAssignedEmployeesDataQuery = (
  { __typename?: 'Query' }
  & { getTemplateLibraryAssignment: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TargetID' | 'SourceID' | 'SourceType' | 'CompanyID' | 'TargetType'>
  )> }
);

export type ShiftGroupDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ShiftGroupDataQuery = (
  { __typename?: 'Query' }
  & { getShiftGroup: Array<(
    { __typename?: 'ShiftGroupEntity' }
    & Pick<ShiftGroupEntity, 'ShiftGroupID' | 'Name'>
    & { ShiftGroupShift: Array<(
      { __typename?: 'ShiftGroupShiftEntity' }
      & Pick<ShiftGroupShiftEntity, 'ShiftGroupID' | 'ShiftGroupShiftID' | 'ShiftID'>
    )> }
  )> }
);

export type ShiftGroupDataByIdQueryVariables = Exact<{
  ShiftGroupID?: Maybe<Scalars['String']>;
}>;


export type ShiftGroupDataByIdQuery = (
  { __typename?: 'Query' }
  & { getShiftGroup: Array<(
    { __typename?: 'ShiftGroupEntity' }
    & Pick<ShiftGroupEntity, 'ShiftGroupID'>
    & { ShiftGroupShift: Array<(
      { __typename?: 'ShiftGroupShiftEntity' }
      & Pick<ShiftGroupShiftEntity, 'ShiftGroupID' | 'ShiftGroupShiftID' | 'ShiftID'>
    )> }
  )> }
);

export type ShiftGroupEmployeeQueryVariables = Exact<{
  input?: Maybe<TemplateLibraryAssignmentInput>;
}>;


export type ShiftGroupEmployeeQuery = (
  { __typename?: 'Query' }
  & { getTemplateLibraryAssignment: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TargetID' | 'SourceID' | 'SourceType' | 'CompanyID' | 'TargetType'>
  )> }
);

export type DutyRosterInfoLineQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type DutyRosterInfoLineQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DutyRosterInfoLine'>
);

export type DeleteDutyRosterByEmployeeMutationVariables = Exact<{
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
}>;


export type DeleteDutyRosterByEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteDutyRosterByEmployee'>
);

export type DutyRosterBatchMutationVariables = Exact<{
  input?: Maybe<Array<DutyRosterInput> | DutyRosterInput>;
  removeList?: Maybe<Array<DutyRosterInput> | DutyRosterInput>;
}>;


export type DutyRosterBatchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DutyRosterBatch'>
);

export type EmployeeListingByCompanyTmsQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  exitReason?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  Page?: Maybe<Scalars['String']>;
}>;


export type EmployeeListingByCompanyTmsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TMSEmployeeListingCount'>
  & { TMSEmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'SubscriptionUserID' | 'EmployeeNo' | 'EmploymentType' | 'JoinedDate' | 'OrgUnitID' | 'DepartmentID' | 'DivisionID' | 'Status' | 'ResignedDate' | 'FormStatus'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'ProfilePicture'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentFile'>
    )>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
    )> }
  )> }
);

export type EmployeeListingByCompanyTmsCountQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  exitReason?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  Page?: Maybe<Scalars['String']>;
}>;


export type EmployeeListingByCompanyTmsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TMSEmployeeListingCount'>
);

export type BatchEntryByShiftInsertMutationVariables = Exact<{
  input: DutyRosterInput;
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type BatchEntryByShiftInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BatchEntryByShiftInsert'>
);

export type GetEmployeesByShiftGroupDataQueryVariables = Exact<{
  input?: Maybe<TemplateLibraryAssignmentInput>;
}>;


export type GetEmployeesByShiftGroupDataQuery = (
  { __typename?: 'Query' }
  & { getTemplateLibraryAssignment: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TargetID' | 'SourceID' | 'CompanyID'>
    & { Target?: Maybe<{ __typename?: 'CompanyEntity' } | { __typename?: 'DepartmentEntity' } | { __typename?: 'JobDesignationEntity' } | { __typename?: 'JobGradeEntity' } | { __typename?: 'DailyAllowancePolicyEntity' } | { __typename?: 'MonthlyAllowancePolicyEntity' } | { __typename?: 'DeductionPolicyEntity' } | { __typename?: 'ShiftEntity' } | { __typename?: 'ShiftPatternEntity' } | { __typename?: 'ShiftGroupEntity' } | { __typename?: 'TimeOffPolicyEntity' } | { __typename?: 'OvertimeRoundingSettingEntity' } | { __typename?: 'AllowanceDeductionRoundingEntity' } | { __typename?: 'OvertimeReasonEntity' } | { __typename?: 'TimeOffReasonEntity' } | { __typename?: 'MobileCheckInEntity' } | { __typename?: 'OvertimeClaimTableEntity' } | { __typename?: 'OvertimePolicyEntity' } | (
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'OrgUnitID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
      )> }
    ) | { __typename?: 'AttendanceRecordEntity' } | { __typename?: 'SkillSetEntity' } | { __typename?: 'InsuranceSchemeEntity' } | { __typename?: 'RecruitmentSourceEntity' } | { __typename?: 'UserDefineEntity' }> }
  )> }
);

export type GetShiftGroupByTlaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ShiftID?: Maybe<Scalars['String']>;
}>;


export type GetShiftGroupByTlaQuery = (
  { __typename?: 'Query' }
  & { getShiftGroupByTLA: Array<(
    { __typename?: 'ShiftGroupEntity' }
    & Pick<ShiftGroupEntity, 'ShiftGroupID' | 'SubscriptionAccountID' | 'Name' | 'Description' | 'Start' | 'End' | 'Status'>
  )> }
);

export type ShiftPatternAssignmentToEmployeeMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
  ShiftPatternID: Scalars['String'];
  FromDate: Scalars['String'];
  ToDate: Scalars['String'];
}>;


export type ShiftPatternAssignmentToEmployeeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ShiftPatternAssignmentToEmployee'>
);

export type CreateBatchEntryByShiftPatternMutationVariables = Exact<{
  Input: DutyRosterInput;
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateBatchEntryByShiftPatternMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBatchEntryByShiftPattern'>
);

export type GetShiftGroupByShiftPatternQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ShiftPatternID: Scalars['String'];
}>;


export type GetShiftGroupByShiftPatternQuery = (
  { __typename?: 'Query' }
  & { getShiftGroupByShiftPattern: Array<(
    { __typename?: 'ShiftGroupEntity' }
    & Pick<ShiftGroupEntity, 'ShiftGroupID' | 'SubscriptionAccountID' | 'Name' | 'Description' | 'Start' | 'End' | 'Status'>
  )> }
);

export type GetShiftPatternByTlaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetShiftPatternByTlaQuery = (
  { __typename?: 'Query' }
  & { getShiftPatternByTLA: Array<(
    { __typename?: 'ShiftPatternEntity' }
    & Pick<ShiftPatternEntity, 'ShiftPatternID' | 'Active' | 'Name' | 'Start' | 'End' | 'CompanyID' | 'SubscriptionAccountID' | 'Status'>
    & { Details?: Maybe<Array<(
      { __typename?: 'ShiftPatternDetailEntity' }
      & Pick<ShiftPatternDetailEntity, 'ShiftPatternDetailID' | 'Days' | 'ShiftTypeID' | 'ShiftDayID'>
      & { shiftDay?: Maybe<(
        { __typename?: 'ShiftDayEntity' }
        & Pick<ShiftDayEntity, 'ShiftID' | 'ShiftDayID' | 'DayType'>
      )> }
    )>> }
  )> }
);

export type DutyRosterByExcelQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type DutyRosterByExcelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DutyRosterByExcel'>
);

export type DutyRosterEmployeeSumQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  date: Scalars['DateTime'];
}>;


export type DutyRosterEmployeeSumQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DutyRosterEmployeeSum'>
);

export type DutyRosterSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  date: Scalars['DateTime'];
}>;


export type DutyRosterSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DutyRosterSummary'>
);

export type OvertimeListingByPeriodQueryVariables = Exact<{
  StartMonth: Scalars['DateTime'];
  EndMonth: Scalars['DateTime'];
  CompanyID: Scalars['String'];
}>;


export type OvertimeListingByPeriodQuery = (
  { __typename?: 'Query' }
  & { OvertimeListingByPeriod: Array<(
    { __typename?: 'OvertimeEntity' }
    & Pick<OvertimeEntity, 'OvertimeID' | 'EmployeeID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Remark' | 'OvertimeReasonID' | 'OvertimeStatus' | 'CreatedDT'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, otReason?: Maybe<(
      { __typename?: 'OvertimeReasonEntity' }
      & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
    )> }
  )> }
);

export type OvertimeMenuSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type OvertimeMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OvertimeMenuSummary'>
);

export type TimeOffListingByPeriodQueryVariables = Exact<{
  StartMonth: Scalars['DateTime'];
  EndMonth: Scalars['DateTime'];
  CompanyID: Scalars['String'];
}>;


export type TimeOffListingByPeriodQuery = (
  { __typename?: 'Query' }
  & { TimeOffListingByPeriod: Array<(
    { __typename?: 'TimeOffEntity' }
    & Pick<TimeOffEntity, 'TimeOffID' | 'EmployeeID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Duration' | 'VisitingPlace' | 'TravelFrom' | 'TravelTo' | 'ToReasonID' | 'TimeOffStatus' | 'Remark' | 'CreatedDT'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, ToReason?: Maybe<(
      { __typename?: 'TimeOffReasonEntity' }
      & Pick<TimeOffReasonEntity, 'TimeOffReasonID' | 'Name'>
    )> }
  )> }
);

export type OvertimeListingByDateTimeQueryVariables = Exact<{
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
}>;


export type OvertimeListingByDateTimeQuery = (
  { __typename?: 'Query' }
  & { OvertimeListingByDateTime: Array<(
    { __typename?: 'OvertimeEntity' }
    & Pick<OvertimeEntity, 'OvertimeID' | 'EmployeeID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Approver' | 'Duration' | 'Remark' | 'OvertimeStatus' | 'CreatedDT' | 'ModifiedBy' | 'ModifiedDT' | 'CreatedBy' | 'ApproverID' | 'RejectionRemarks' | 'ApproveDate' | 'CancelledBy' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'OrgUnitID' | 'JobGradeID' | 'JoinedDate' | 'CompanyID' | 'FormStatus' | 'EmployeeID' | 'DepartmentID' | 'DivisionID' | 'EmploymentType' | 'ReportTo' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, otReason?: Maybe<(
      { __typename?: 'OvertimeReasonEntity' }
      & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'NotifiedDT' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT' | 'ApproverName'>
        )>> }
      )>> }
    )>, Documents?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'Description'>
    )>> }
  )> }
);

export type OvertimeListingByDateTimeV2QueryVariables = Exact<{
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
}>;


export type OvertimeListingByDateTimeV2Query = (
  { __typename?: 'Query' }
  & { OvertimeListingByDateTimeV2: Array<(
    { __typename?: 'OvertimeEntity' }
    & Pick<OvertimeEntity, 'OvertimeID' | 'EmployeeID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Approver' | 'Duration' | 'Remark' | 'OvertimeStatus' | 'CreatedDT' | 'ModifiedDT' | 'ApproverID' | 'RejectionRemarks' | 'ApproveDate' | 'CancelledBy' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'OrgUnitID' | 'JobGradeID' | 'JoinedDate' | 'CompanyID' | 'FormStatus' | 'EmployeeID' | 'DepartmentID' | 'DivisionID' | 'EmploymentType' | 'ReportTo' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, otReason?: Maybe<(
      { __typename?: 'OvertimeReasonEntity' }
      & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'NotifiedDT' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT' | 'ApproverName'>
        )>> }
      )>> }
    )>, Documents?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'Description'>
    )>> }
  )> }
);

export type OvertimeListingByYearQueryVariables = Exact<{
  WorkDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type OvertimeListingByYearQuery = (
  { __typename?: 'Query' }
  & { OvertimeListingByYear: Array<(
    { __typename?: 'OvertimeEntity' }
    & Pick<OvertimeEntity, 'OvertimeID' | 'EmployeeID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Approver' | 'Duration' | 'Remark' | 'OvertimeStatus' | 'CreatedDT' | 'ModifiedBy' | 'ModifiedDT' | 'CreatedBy' | 'ApproverID' | 'RejectionRemarks' | 'ApproveDate' | 'CancelledBy' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'OrgUnitID' | 'JobGradeID' | 'JoinedDate' | 'CompanyID' | 'FormStatus' | 'EmployeeID' | 'DepartmentID' | 'DivisionID' | 'EmploymentType' | 'ReportTo' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, otReason?: Maybe<(
      { __typename?: 'OvertimeReasonEntity' }
      & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'NotifiedDT' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT' | 'ApproverName'>
        )>> }
      )>> }
    )> }
  )> }
);

export type OvertimePeriodSummaryQueryVariables = Exact<{
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
}>;


export type OvertimePeriodSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OvertimePeriodSummary'>
);

export type GetAttendanceRecordManualEntryPageDataQueryVariables = Exact<{
  input?: Maybe<AttendanceRecordInput>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type GetAttendanceRecordManualEntryPageDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetAttendanceRecordManualEntryPageTotalCount'>
  & { getAttendanceRecord: Array<(
    { __typename?: 'AttendanceRecordEntity' }
    & Pick<AttendanceRecordEntity, 'CompanyID' | 'AttendanceRecordID' | 'DateTime' | 'ModifiedDT' | 'Remark' | 'IntegrationClockType' | 'CheckInMethod' | 'Location' | 'Lat' | 'Long' | 'Address'>
    & { Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'DepartmentID' | 'DivisionID' | 'EmployeeID' | 'Status' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )> }
);

export type EmployeeSummaryTmsQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CheckForResignedDate?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  Page?: Maybe<Scalars['String']>;
}>;


export type EmployeeSummaryTmsQuery = (
  { __typename?: 'Query' }
  & { EmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'CompanyID' | 'EmployeeNo' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'Status' | 'FormStatus' | 'ResignedDate' | 'JobGradeID'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, OvertimeList?: Maybe<Array<(
      { __typename?: 'OvertimeEntity' }
      & Pick<OvertimeEntity, 'OvertimeID' | 'EmployeeID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Remark' | 'CreatedDT'>
      & { otReason?: Maybe<(
        { __typename?: 'OvertimeReasonEntity' }
        & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
      )> }
    )>>, JobGrade?: Maybe<(
      { __typename?: 'JobGradeEntity' }
      & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
    )> }
  )>, OTReasonByTLA: Array<(
    { __typename?: 'OvertimeReasonEntity' }
    & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
  )> }
);

export type EmployeeSummaryTmsCountQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CheckForResignedDate?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  Page?: Maybe<Scalars['String']>;
}>;


export type EmployeeSummaryTmsCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeListingCount'>
);

export type OvertimeListingByExcelQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type OvertimeListingByExcelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OvertimeListingByExcel'>
);

export type GetOvertimeReasonQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOvertimeReasonQuery = (
  { __typename?: 'Query' }
  & { getOvertimeReason: Array<(
    { __typename?: 'OvertimeReasonEntity' }
    & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
  )> }
);

export type TimeOffMenuSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type TimeOffMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TimeOffMenuSummary'>
);

export type TimeOffApplicationPeriodSummaryQueryVariables = Exact<{
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
}>;


export type TimeOffApplicationPeriodSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TimeOffApplicationPeriodSummary'>
);

export type TimeOffListingByYearQueryVariables = Exact<{
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
}>;


export type TimeOffListingByYearQuery = (
  { __typename?: 'Query' }
  & { TimeOffListingByYear: Array<(
    { __typename?: 'TimeOffEntity' }
    & Pick<TimeOffEntity, 'TimeOffID' | 'EmployeeID' | 'Approver' | 'ToReasonID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Remark' | 'Reason' | 'VisitingPlace' | 'TravelFrom' | 'TravelTo' | 'TimeOffStatus' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'ApproverID' | 'ApproveDate' | 'CancelledBy' | 'RejectionRemarks' | 'Duration' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'OrgUnitID' | 'JoinedDate' | 'FormStatus' | 'CompanyID' | 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID' | 'EmploymentType' | 'JobGradeID' | 'ContactID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, ToReason?: Maybe<(
      { __typename?: 'TimeOffReasonEntity' }
      & Pick<TimeOffReasonEntity, 'TimeOffReasonID' | 'Name'>
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'NotifiedDT' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT' | 'ApproverName'>
        )>> }
      )>> }
    )> }
  )> }
);

export type TimeOffEmployeeSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type TimeOffEmployeeSummaryQuery = (
  { __typename?: 'Query' }
  & { EmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'OrgUnitID' | 'JoinedDate' | 'JobGradeID' | 'CompanyID' | 'FormStatus' | 'EmployeeID' | 'EmployeeNo' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )> }
  )> }
);

export type TimeOffPolicyDetailsByEmployeeIdQueryVariables = Exact<{
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
}>;


export type TimeOffPolicyDetailsByEmployeeIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TimeOffPolicyDetailsByEmployeeID'>
);

export type GetEmpByDutyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DutyDate: Scalars['DateTime'];
}>;


export type GetEmpByDutyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEmpByDuty'>
);

export type GetAttendanceRecordLastModifiedQueryVariables = Exact<{
  year: Scalars['DateTime'];
  timezoneOffset: Scalars['Float'];
  companyID: Scalars['String'];
}>;


export type GetAttendanceRecordLastModifiedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAttendanceRecordLastModified'>
);

export type AttendanceRecordByPeriodQueryVariables = Exact<{
  StartMonth: Scalars['DateTime'];
  EndMonth: Scalars['DateTime'];
  CompanyID: Scalars['String'];
}>;


export type AttendanceRecordByPeriodQuery = (
  { __typename?: 'Query' }
  & { AttendanceRecordByPeriod: Array<(
    { __typename?: 'AttendanceRecordEntity' }
    & Pick<AttendanceRecordEntity, 'AttendanceRecordID' | 'EmployeeID' | 'DateTime' | 'CompanyID' | 'Location' | 'Address' | 'Lat' | 'Long' | 'Temperature' | 'WearMask' | 'Radius' | 'Remark' | 'IntegrationClockType'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )> }
);

export type AttendanceRecordSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type AttendanceRecordSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AttendanceRecordSummary'>
);

export type DeleteTimeAttendanceRecordMutationVariables = Exact<{
  AttendanceRecordID: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteTimeAttendanceRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteTimeAttendanceRecord'>
);

export type GetManualEntryFormPageDataQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  exitReason?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  Page?: Maybe<Scalars['String']>;
}>;


export type GetManualEntryFormPageDataQuery = (
  { __typename?: 'Query' }
  & { EmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'Status' | 'ResignedDate' | 'FormStatus' | 'EmployeeNo'>
    & { Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'Name'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )> }
  )> }
);

export type UpdateAttendanceRecordMutationVariables = Exact<{
  input: AttendanceRecordInput;
}>;


export type UpdateAttendanceRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAttendanceRecord'>
);

export type AttendanceRecordByExcelQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DateTime?: Maybe<Scalars['DateTime']>;
}>;


export type AttendanceRecordByExcelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AttendanceRecordByExcel'>
);

export type AttendanceRecordBatchDeleteMutationVariables = Exact<{
  input: Array<AttendanceRecordInput> | AttendanceRecordInput;
}>;


export type AttendanceRecordBatchDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AttendanceRecordBatchDelete'>
);

export type AttendanceRecordBatchMutationVariables = Exact<{
  input: Array<AttendanceRecordInput> | AttendanceRecordInput;
}>;


export type AttendanceRecordBatchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AttendanceRecordBatch'>
);

export type GetEmpListingbyCompanyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetEmpListingbyCompanyQuery = (
  { __typename?: 'Query' }
  & { GetEmpListingbyCompany: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'EmploymentType' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'Status' | 'ReportTo'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )> }
  )> }
);

export type AttendancePeriodEditMutationVariables = Exact<{
  Data: Array<Scalars['JSON']> | Scalars['JSON'];
}>;


export type AttendancePeriodEditMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AttendancePeriodEdit'>
);

export type GetLastPostedAttendanceProccessQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetLastPostedAttendanceProccessQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getLastPostedAttendanceProccess'>
);

export type AttendancePeriodSummaryQueryVariables = Exact<{
  CreatedDT: Scalars['DateTime'];
  CompanyID: Scalars['String'];
}>;


export type AttendancePeriodSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AttendancePeriodSummary'>
);

export type AttendanceProccessBulkDeleteMutationVariables = Exact<{
  AttendanceCalculationID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AttendanceProccessBulkDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AttendanceProccessBulkDelete'>
);

export type AttendancePostingListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DateFrom: Scalars['String'];
  DateTo: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  AttendanceType?: Maybe<AttendanceTransactionType>;
  PostStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  isAttendanceGrouped?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type AttendancePostingListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AttendancePostingListingCount'>
  & { AttendancePostingListing: Array<(
    { __typename?: 'AttendancePostingOutput' }
    & Pick<AttendancePostingOutput, 'AttendanceCalculationID' | 'PostStatus' | 'Date' | 'Value' | 'PayItemID' | 'EmployeeID' | 'AdjustedValue' | 'Department' | 'ShiftDayName' | 'Division' | 'PostedDate' | 'ModifiedDT' | 'Type' | 'PeriodicCycleStartDate' | 'PeriodicCycleEndDate' | 'IsOTPModified' | 'OTPModifiedBy' | 'IsOTPDeleted' | 'OTPDeletedBy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'OrgUnitID' | 'JoinedDate' | 'JobGradeID' | 'CompanyID' | 'FormStatus' | 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description' | 'ParentID'>
      )>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, Shift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, ShiftDay?: Maybe<(
      { __typename?: 'ShiftDayEntity' }
      & Pick<ShiftDayEntity, 'ShiftDayID' | 'DayType'>
    )>, PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayItemID' | 'PayName'>
    )> }
  )> }
);

export type AdjustedValueQuantityDetailsQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  PayItemID: Scalars['String'];
  DateFrom: Scalars['String'];
  DateTo: Scalars['String'];
}>;


export type AdjustedValueQuantityDetailsQuery = (
  { __typename?: 'Query' }
  & { AdjustedValueQuantityDetails: Array<(
    { __typename?: 'AttendancePostingOutput' }
    & Pick<AttendancePostingOutput, 'AttendanceCalculationID' | 'PostStatus' | 'Date' | 'Value' | 'PayItemID' | 'EmployeeID' | 'AdjustedValue' | 'Department' | 'ShiftDayName' | 'Division' | 'PostedDate' | 'ModifiedDT' | 'Type' | 'PeriodicCycleStartDate' | 'PeriodicCycleEndDate' | 'IsOTPModified' | 'OTPModifiedBy' | 'IsOTPDeleted' | 'OTPDeletedBy'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'OrgUnitID' | 'JoinedDate' | 'JobGradeID' | 'CompanyID' | 'FormStatus' | 'EmployeeID' | 'EmployeeNo' | 'DepartmentID' | 'DivisionID'>
      & { OrgUnit?: Maybe<(
        { __typename?: 'CompanyOrgUnitEntity' }
        & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description' | 'ParentID'>
      )>, Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Description'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )>, Shift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, ShiftDay?: Maybe<(
      { __typename?: 'ShiftDayEntity' }
      & Pick<ShiftDayEntity, 'ShiftDayID' | 'DayType'>
    )>, PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayItemID' | 'PayName'>
    )> }
  )> }
);

export type AttendanceProcessingMutationVariables = Exact<{
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID: Scalars['String'];
  FromDate: Scalars['String'];
  AccountID: Scalars['String'];
  ToDate: Scalars['String'];
}>;


export type AttendanceProcessingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AttendanceProcessing'>
);

export type ProcessingListingQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
}>;


export type ProcessingListingQuery = (
  { __typename?: 'Query' }
  & { ProcessingListing: Array<(
    { __typename?: 'AttendanceProcessingBatchEntity' }
    & Pick<AttendanceProcessingBatchEntity, 'AttendanceProcessingBatchID' | 'CompanyID' | 'Date' | 'Status' | 'Errorlog' | 'CompletedTime'>
  )> }
);

export type UpdateAdjustedValueMutationVariables = Exact<{
  input: AttendanceCalculationInput;
}>;


export type UpdateAdjustedValueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateAdjustedValue'>
);

export type MonthlyAttendanceProcessingMutationVariables = Exact<{
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID: Scalars['String'];
  FromDate: Scalars['String'];
  AccountID: Scalars['String'];
  ToDate: Scalars['String'];
}>;


export type MonthlyAttendanceProcessingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MonthlyAttendanceProcessing'>
);

export type GetPayrollCycleByCompanyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetPayrollCycleByCompanyQuery = (
  { __typename?: 'Query' }
  & { PayrollCycleListing: Array<(
    { __typename?: 'PayrollCycleEntity' }
    & Pick<PayrollCycleEntity, 'PayrollCycleID' | 'CompanyID' | 'Description' | 'IsDefault' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type PostAttendanceRecordMutationVariables = Exact<{
  input: AttendancePostInput;
}>;


export type PostAttendanceRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PostAttendanceRecord'>
);

export type ReverseAttendanceRecordMutationVariables = Exact<{
  EmployeePayItemIDPairs: Array<AttendanceCalculationInput> | AttendanceCalculationInput;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
}>;


export type ReverseAttendanceRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReverseAttendanceRecord'>
);

export type PopulateAttendanceRecordMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type PopulateAttendanceRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PopulateAttendanceRecord'>
);

export type EarnLeavePeriodSummaryQueryVariables = Exact<{
  CreatedDT: Scalars['DateTime'];
  CompanyID: Scalars['String'];
}>;


export type EarnLeavePeriodSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EarnLeavePeriodSummary'>
);

export type EarnLeavePostingQueryVariables = Exact<{
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
  CompanyID: Scalars['String'];
  FromDate: Scalars['String'];
  UntilDate: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type EarnLeavePostingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EarnLeavePosting'>
);

export type PostEarnedLeaveMutationVariables = Exact<{
  LeaveHeaderIDs: Array<Scalars['String']> | Scalars['String'];
  FromDate: Scalars['String'];
  UntilDate: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
}>;


export type PostEarnedLeaveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PostEarnedLeave'>
);

export type ReverseEarnedLeaveMutationVariables = Exact<{
  LeaveHeaderIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type ReverseEarnedLeaveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReverseEarnedLeave'>
);

export type CreateTemplateLibraryAssignmentMutationVariables = Exact<{
  inputs: Array<TemplateLibraryAssignmentInput> | TemplateLibraryAssignmentInput;
}>;


export type CreateTemplateLibraryAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createTemplateLibraryAssignment'>
);

export type DeleteTemplateLibraryAssignmentMutationVariables = Exact<{
  inputs: Array<TemplateLibraryAssignmentInput> | TemplateLibraryAssignmentInput;
}>;


export type DeleteTemplateLibraryAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTemplateLibraryAssignment'>
);

export type GetAttendanceExclusionListingPageDataQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  Page?: Maybe<Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  TemplateLibraryAssignmentInput?: Maybe<TemplateLibraryAssignmentInput>;
}>;


export type GetAttendanceExclusionListingPageDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeListingCount'>
  & { EmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'OrgUnitID' | 'JoinedDate' | 'JobGradeID' | 'CompanyID' | 'FormStatus' | 'EmployeeID' | 'EmployeeNo'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )> }
  )>, getTemplateLibraryAssignment: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TemplateLibraryAssignmentID' | 'Module' | 'SourceType' | 'SourceID' | 'TargetType' | 'TargetID'>
    & { Target?: Maybe<{ __typename?: 'CompanyEntity' } | { __typename?: 'DepartmentEntity' } | { __typename?: 'JobDesignationEntity' } | { __typename?: 'JobGradeEntity' } | { __typename?: 'DailyAllowancePolicyEntity' } | { __typename?: 'MonthlyAllowancePolicyEntity' } | { __typename?: 'DeductionPolicyEntity' } | { __typename?: 'ShiftEntity' } | { __typename?: 'ShiftPatternEntity' } | { __typename?: 'ShiftGroupEntity' } | { __typename?: 'TimeOffPolicyEntity' } | { __typename?: 'OvertimeRoundingSettingEntity' } | { __typename?: 'AllowanceDeductionRoundingEntity' } | { __typename?: 'OvertimeReasonEntity' } | { __typename?: 'TimeOffReasonEntity' } | { __typename?: 'MobileCheckInEntity' } | { __typename?: 'OvertimeClaimTableEntity' } | { __typename?: 'OvertimePolicyEntity' } | (
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'OrgUnitID' | 'JoinedDate' | 'CompanyID' | 'FormStatus' | 'EmployeeID' | 'EmployeeNo' | 'EmploymentType' | 'DocumentID' | 'DepartmentID' | 'DivisionID' | 'JobGradeID' | 'Status'>
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )>, EmployeeDepartment?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
      )>, Designation?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
      )>, Division?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'DepartmentID' | 'Description'>
      )>, Company?: Maybe<(
        { __typename?: 'CompanyEntity' }
        & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, JobGrade?: Maybe<(
        { __typename?: 'JobGradeEntity' }
        & Pick<JobGradeEntity, 'JobGradeID' | 'Description'>
      )> }
    ) | { __typename?: 'AttendanceRecordEntity' } | { __typename?: 'SkillSetEntity' } | { __typename?: 'InsuranceSchemeEntity' } | { __typename?: 'RecruitmentSourceEntity' } | { __typename?: 'UserDefineEntity' }> }
  )> }
);

export type SearchEmployeeQueryVariables = Exact<{
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CompanyID: Scalars['String'];
}>;


export type SearchEmployeeQuery = (
  { __typename?: 'Query' }
  & { EmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'OrgUnitID' | 'JoinedDate' | 'JobGradeID' | 'CompanyID' | 'FormStatus' | 'EmployeeID' | 'EmployeeNo'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName'>
    )> }
  )> }
);

export type GetContactTracingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
}>;


export type GetContactTracingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContactTracing'>
);

export type LogShiftGroupEmployeeAssignmentHistoryMutationVariables = Exact<{
  inputs: Array<ShiftGroupEmployeeAssignmentLogInput> | ShiftGroupEmployeeAssignmentLogInput;
}>;


export type LogShiftGroupEmployeeAssignmentHistoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logShiftGroupEmployeeAssignmentHistory'>
);

export type UpdateShiftGroupEmployeeAssignmentHistoryMutationVariables = Exact<{
  AssignmentLogID: Scalars['String'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
}>;


export type UpdateShiftGroupEmployeeAssignmentHistoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateShiftGroupEmployeeAssignmentHistory'>
);

export type DeleteShiftGroupEmployeeAssignmentHistoryMutationVariables = Exact<{
  AssignmentLogID: Scalars['String'];
}>;


export type DeleteShiftGroupEmployeeAssignmentHistoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteShiftGroupEmployeeAssignmentHistory'>
);

export type GetAttendanceRecordManualEntryPageTotalCountQueryVariables = Exact<{
  input?: Maybe<AttendanceRecordInput>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  DateTime?: Maybe<Scalars['DateTime']>;
  keyword?: Maybe<Scalars['String']>;
}>;


export type GetAttendanceRecordManualEntryPageTotalCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetAttendanceRecordManualEntryPageTotalCount'>
);

export type SubmitShiftGroupPatternMutationVariables = Exact<{
  input: ShiftGroupPatternInput;
}>;


export type SubmitShiftGroupPatternMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SubmitShiftGroupPattern'>
);

export type DeleteShiftGroupPatternMutationVariables = Exact<{
  ShiftGroupPatternID: Scalars['String'];
}>;


export type DeleteShiftGroupPatternMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteShiftGroupPattern'>
);

export type EmployeeShiftGroupAssignmentLogQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  Today?: Maybe<Scalars['Boolean']>;
}>;


export type EmployeeShiftGroupAssignmentLogQuery = (
  { __typename?: 'Query' }
  & { EmployeeShiftGroupAssignmentLog: Array<(
    { __typename?: 'ShiftGroupEmployeeAssignmentLogEntity' }
    & Pick<ShiftGroupEmployeeAssignmentLogEntity, 'ShiftGroupEmployeeAssignmentLogID' | 'ShiftGroupID' | 'EmployeeID' | 'Start' | 'End' | 'Status' | 'hasDutyRoster'>
    & { ShiftGroup?: Maybe<(
      { __typename?: 'ShiftGroupEntity' }
      & Pick<ShiftGroupEntity, 'Name' | 'ByQR' | 'QRSelfie' | 'ByLocation' | 'LocationSelfie' | 'ByBluetooth' | 'BluetoothSelfie'>
    )> }
  )> }
);

export type GetShiftGroupEployeeAssignmentDutyRosterQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetShiftGroupEployeeAssignmentDutyRosterQuery = (
  { __typename?: 'Query' }
  & { getDutyRoster: Array<(
    { __typename?: 'DutyRosterEntity' }
    & Pick<DutyRosterEntity, 'Date'>
  )> }
);

export type OvertimeInsertMutationVariables = Exact<{
  input: OvertimeInput;
}>;


export type OvertimeInsertMutation = (
  { __typename?: 'Mutation' }
  & { OvertimeInsert: (
    { __typename?: 'OvertimeEntity' }
    & Pick<OvertimeEntity, 'OvertimeID' | 'OvertimeStatus'>
  ) }
);

export type OvertimeDraftMutationVariables = Exact<{
  input: OvertimeInput;
}>;


export type OvertimeDraftMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OvertimeDraft'>
);

export type OvertimeDeleteMutationVariables = Exact<{
  OvertimeID: Scalars['String'];
}>;


export type OvertimeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OvertimeDelete'>
);

export type OvertimeUpdateMutationVariables = Exact<{
  input: Array<OvertimeInput> | OvertimeInput;
  ActionStatus?: Maybe<Scalars['String']>;
  IsSupersede?: Maybe<Scalars['Boolean']>;
}>;


export type OvertimeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OvertimeUpdate'>
);

export type OvertimeBatchMutationVariables = Exact<{
  input: Array<OvertimeInput> | OvertimeInput;
}>;


export type OvertimeBatchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OvertimeBatch'>
);

export type OvertimeWithdrawalCheckingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  WorkDate: Scalars['DateTime'];
}>;


export type OvertimeWithdrawalCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OvertimeWithdrawalChecking'>
);

export type OtReasonByTlaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type OtReasonByTlaQuery = (
  { __typename?: 'Query' }
  & { OTReasonByTLA: Array<(
    { __typename?: 'OvertimeReasonEntity' }
    & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
  )> }
);

export type CreateAttendanceRecordMutationVariables = Exact<{
  input: AttendanceRecordInput;
  DocumentsInput?: Maybe<DocumentsInput>;
}>;


export type CreateAttendanceRecordMutation = (
  { __typename?: 'Mutation' }
  & { createAttendanceRecord: (
    { __typename?: 'AttendanceRecordEntity' }
    & Pick<AttendanceRecordEntity, 'AttendanceRecordID'>
  ) }
);

export type VerifyScanQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Location: Scalars['String'];
}>;


export type VerifyScanQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'VerifyScan'>
);

export type AttendanceHistoryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  FromDate: Scalars['String'];
  ToDate: Scalars['String'];
}>;


export type AttendanceHistoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AttendanceHistory'>
);

export type EmpAttendanceHistoryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  FromDate: Scalars['String'];
  ToDate: Scalars['String'];
}>;


export type EmpAttendanceHistoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmpAttendanceHistory'>
);

export type AttendanceRecordListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type AttendanceRecordListingQuery = (
  { __typename?: 'Query' }
  & { AttendanceRecordListing: Array<(
    { __typename?: 'AttendanceRecordEntity' }
    & Pick<AttendanceRecordEntity, 'AttendanceRecordID' | 'EmployeeID' | 'CompanyID' | 'DateTime' | 'IntegrationClockType' | 'ExcelFileName' | 'CreatedDT' | 'ModifiedDT' | 'Location' | 'Address' | 'Lat' | 'Long' | 'Radius'>
  )> }
);

export type AttendanceTableQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  FromDate: Scalars['String'];
  ToDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type AttendanceTableQuery = (
  { __typename?: 'Query' }
  & { AttendanceAnalysis: Array<(
    { __typename?: 'AttendanceDetailOutput' }
    & Pick<AttendanceDetailOutput, 'OrgUnitID' | 'JobGradeID' | 'Date' | 'EmployeeID' | 'CompanyID' | 'EmployeeName' | 'OrgUnit' | 'EmployeeNo' | 'ShiftName' | 'DayType' | 'ShiftTimeIn' | 'ShiftTimeOut' | 'ActualIn' | 'ActualOut' | 'PreOT' | 'PostOT' | 'ShiftPlanDay' | 'ShiftHour' | 'WorkHour' | 'ActualWorkHour' | 'RawOTHours' | 'RawOTDay' | 'LateInDay' | 'LateIn' | 'EarlyOut' | 'Absenteeism' | 'TimeOff' | 'ApprovedOTHours' | 'Leave' | 'TravelTime' | 'UnproductiveTime' | 'Irregular' | 'IncompleteClocking' | 'FixedHours' | 'Location' | 'Address' | 'Lat' | 'Long'>
  )> }
);

export type SimpleOvertimeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  IsExcelFileOnly?: Maybe<Scalars['Boolean']>;
}>;


export type SimpleOvertimeListingQuery = (
  { __typename?: 'Query' }
  & { OvertimeListing: Array<(
    { __typename?: 'OvertimeEntity' }
    & Pick<OvertimeEntity, 'OvertimeID' | 'ExcelFileName'>
  )> }
);

export type OvertimeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type OvertimeListingQuery = (
  { __typename?: 'Query' }
  & { OvertimeListing: Array<(
    { __typename?: 'OvertimeEntity' }
    & Pick<OvertimeEntity, 'OvertimeID' | 'EmployeeID' | 'CompanyID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Duration' | 'Remark' | 'OvertimeReasonID' | 'ExcelFileName' | 'OvertimeStatus' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'ApproverID' | 'Approver' | 'ApproveDate' | 'RejectionRemarks' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, otReason?: Maybe<(
      { __typename?: 'OvertimeReasonEntity' }
      & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'NotifiedDT' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT' | 'ApproverName'>
        )>> }
      )>> }
    )> }
  )> }
);

export type DutyRosterSummaryByEmployeeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  date: Scalars['DateTime'];
}>;


export type DutyRosterSummaryByEmployeeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DutyRosterSummaryByEmployee'>
);

export type DutyRosterDatesQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  date: Scalars['DateTime'];
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type DutyRosterDatesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DutyRosterDates'>
);

export type EmployeeListingByCompanyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  ExcludeFormStatus?: Maybe<Scalars['String']>;
  ExcludeEmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  CheckForResignedDate?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  keyword?: Maybe<Scalars['String']>;
  Page?: Maybe<Scalars['String']>;
}>;


export type EmployeeListingByCompanyQuery = (
  { __typename?: 'Query' }
  & { EmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'CalendarStateID' | 'CalendarPolicyID' | 'IsInterCompanyTransfer' | 'JoinedDate' | 'JoinedReferenceDate' | 'Probation' | 'ConfirmedDate' | 'EmployeeStatus' | 'Status' | 'FormStatus' | 'ResignedDate' | 'EmploymentType' | 'JobGradeID' | 'OrgUnitID'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>, OrgUnit?: Maybe<(
      { __typename?: 'CompanyOrgUnitEntity' }
      & Pick<CompanyOrgUnitEntity, 'CompanyOrgUnitID' | 'Level' | 'Description' | 'ParentID'>
    )> }
  )> }
);

export type EmployeeTmsSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type EmployeeTmsSummaryQuery = (
  { __typename?: 'Query' }
  & { EmployeeTMSSummary?: Maybe<(
    { __typename?: 'EmployeeSummary' }
    & Pick<EmployeeSummary, 'Roster' | 'DutyRosterDate' | 'OT' | 'TimeOff' | 'CheckInDate' | 'CheckOutDate' | 'ScanDate' | 'Shift'>
  )> }
);

export type GetSingleUserQueryVariables = Exact<{
  ID: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetSingleUserQuery = (
  { __typename?: 'Query' }
  & { GetSingleUser: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'userName'>
  )> }
);

export type GetOtPolicyByJobGradeQueryVariables = Exact<{
  Status?: Maybe<Status>;
  input?: Maybe<TemplateLibraryAssignmentInput>;
}>;


export type GetOtPolicyByJobGradeQuery = (
  { __typename?: 'Query' }
  & { getTemplateLibraryAssignment: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TargetID' | 'TargetType' | 'SourceType' | 'SourceID' | 'Module'>
  )>, getOvertimePolicy?: Maybe<Array<(
    { __typename?: 'OvertimePolicyEntity' }
    & Pick<OvertimePolicyEntity, 'Status' | 'OvertimePolicyID' | 'Title' | 'LateIn' | 'EarlyOut'>
  )>>, getOvertimeClaimTable: Array<(
    { __typename?: 'OvertimeClaimTableEntity' }
    & Pick<OvertimeClaimTableEntity, 'Status' | 'OvertimeClaimTableID' | 'Title'>
  )> }
);

export type GetEmployeeOtPoliciesQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  WorkDate: Scalars['DateTime'];
}>;


export type GetEmployeeOtPoliciesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetEmployeeOtPolicies'>
);

export type GetAttenRecordsQueryVariables = Exact<{
  WorkDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
}>;


export type GetAttenRecordsQuery = (
  { __typename?: 'Query' }
  & { getAttenRecords: Array<(
    { __typename?: 'AttendanceRecordEntity' }
    & Pick<AttendanceRecordEntity, 'AttendanceRecordID' | 'EmployeeID' | 'CompanyID' | 'DateTime' | 'IntegrationClockType'>
  )> }
);

export type GetServerDateTimeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetServerDateTimeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetServerDateTime'>
);

export type SimpleEmployeeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type SimpleEmployeeListingQuery = (
  { __typename?: 'Query' }
  & { SimpleEmployeeListing: Array<(
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
  )> }
);

export type OvertimeExcelUploadMutationVariables = Exact<{
  File: Scalars['Upload'];
  FileName: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type OvertimeExcelUploadMutation = (
  { __typename?: 'Mutation' }
  & { OvertimeExcelUpload?: Maybe<Array<(
    { __typename?: 'process_overtime_importEntity' }
    & Pick<Process_Overtime_ImportEntity, 'ProcessOvertimeID' | 'CompanyID' | 'EmployeeID' | 'OvertimeReasonID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Remark' | 'Index' | 'HasDutyRoster'>
    & { OvertimeReason?: Maybe<(
      { __typename?: 'OvertimeReasonEntity' }
      & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )>> }
);

export type OvertimeExcelDeleteMutationVariables = Exact<{
  FileName: Scalars['String'];
}>;


export type OvertimeExcelDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OvertimeExcelDelete'>
);

export type OvertimeExcelInsertActualMutationVariables = Exact<{
  FileName: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type OvertimeExcelInsertActualMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OvertimeExcelInsertActual'>
);

export type AttendanceExcelUploadMutationVariables = Exact<{
  File: Scalars['Upload'];
  FileName: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type AttendanceExcelUploadMutation = (
  { __typename?: 'Mutation' }
  & { AttendanceExcelUpload?: Maybe<Array<(
    { __typename?: 'ProcessAttendanceEntity' }
    & Pick<ProcessAttendanceEntity, 'IntergrationClockType' | 'DateTime' | 'Remark' | 'ExcelFileName' | 'Index' | 'AttendanceRecordID'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )>> }
);

export type AttendanceExcelDeleteMutationVariables = Exact<{
  FileName: Scalars['String'];
}>;


export type AttendanceExcelDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AttendanceExcelDelete'>
);

export type AttendanceExcelInsertActualMutationVariables = Exact<{
  FileName: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type AttendanceExcelInsertActualMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AttendanceExcelInsertActual'>
);

export type GetChangeShiftByYearQueryVariables = Exact<{
  ChangeShiftStatus?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
}>;


export type GetChangeShiftByYearQuery = (
  { __typename?: 'Query' }
  & { getChangeShiftByYear: Array<(
    { __typename?: 'ChangeShiftEntity' }
    & Pick<ChangeShiftEntity, 'ChangeShiftID' | 'Date' | 'Reason' | 'ChangeShiftStatus' | 'CreatedDT' | 'ModifiedDT' | 'DocumentID'>
    & { FromShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, ToShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, ReplacedEmployee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )> }
);

export type GetChangeShiftListingQueryVariables = Exact<{
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type GetChangeShiftListingQuery = (
  { __typename?: 'Query' }
  & { getChangeShiftListing: Array<(
    { __typename?: 'ChangeShiftEntity' }
    & Pick<ChangeShiftEntity, 'ChangeShiftID' | 'Date' | 'Reason' | 'RejectReason' | 'Approver' | 'ChangeShiftStatus' | 'CreatedDT' | 'ModifiedDT' | 'ApprovedDate' | 'DocumentID'>
    & { FromShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, ToShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, ReplacedEmployee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'NotifiedDT' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT' | 'ApproverName'>
        )>> }
      )>> }
    )> }
  )> }
);

export type GetChangeShiftQueryVariables = Exact<{
  ChangeShiftID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
}>;


export type GetChangeShiftQuery = (
  { __typename?: 'Query' }
  & { getChangeShift: Array<(
    { __typename?: 'ChangeShiftEntity' }
    & Pick<ChangeShiftEntity, 'ChangeShiftID' | 'Date' | 'Reason' | 'ChangeShiftStatus' | 'RejectReason' | 'CreatedDT' | 'ModifiedBy' | 'ModifiedDT' | 'CreatedBy' | 'DocumentID'>
    & { FromShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, ToShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, ReplacedEmployee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )> }
  )> }
);

export type CreateChangeShiftMutationVariables = Exact<{
  ChangeShiftInput: ChangeShiftInput;
  DocumentsInput?: Maybe<DocumentsInput>;
}>;


export type CreateChangeShiftMutation = (
  { __typename?: 'Mutation' }
  & { createChangeShift: (
    { __typename?: 'ChangeShiftEntity' }
    & Pick<ChangeShiftEntity, 'ChangeShiftID' | 'ChangeShiftStatus'>
  ) }
);

export type UpdateChangeShiftMutationVariables = Exact<{
  RejectReason?: Maybe<Scalars['String']>;
  CSStatus: Scalars['String'];
  Input: Array<ChangeShiftInput> | ChangeShiftInput;
  IsSupersede?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateChangeShiftMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateChangeShift'>
);

export type DeleteChangeShiftMutationVariables = Exact<{
  ChangeShiftID: Scalars['String'];
}>;


export type DeleteChangeShiftMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteChangeShift'>
);

export type UpdateChangeShiftSubmitMutationVariables = Exact<{
  input: ChangeShiftInput;
}>;


export type UpdateChangeShiftSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateChangeShiftSubmit'>
);

export type ChangeShiftMenuSummaryQueryVariables = Exact<{
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
}>;


export type ChangeShiftMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ChangeShiftMenuSummary'>
);

export type GetShiftByTlaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetShiftByTlaQuery = (
  { __typename?: 'Query' }
  & { getShiftByTLA: Array<(
    { __typename?: 'ShiftEntity' }
    & Pick<ShiftEntity, 'ShiftID' | 'Name' | 'DutyRosterDisplayColour'>
    & { shiftDayList?: Maybe<Array<(
      { __typename?: 'ShiftDayEntity' }
      & Pick<ShiftDayEntity, 'ShiftDayID' | 'DayType'>
    )>> }
  )> }
);

export type GetSimpleDutyRosterQueryVariables = Exact<{
  ExcelFileName?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DutyDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetSimpleDutyRosterQuery = (
  { __typename?: 'Query' }
  & { getDutyRoster: Array<(
    { __typename?: 'DutyRosterEntity' }
    & Pick<DutyRosterEntity, 'Date'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
    )> }
  )> }
);

export type GetDutyRosterQueryVariables = Exact<{
  ExcelFileName?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DutyDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetDutyRosterQuery = (
  { __typename?: 'Query' }
  & { getDutyRoster: Array<(
    { __typename?: 'DutyRosterEntity' }
    & Pick<DutyRosterEntity, 'DutyRosterID' | 'Date' | 'ShiftDayID' | 'ShiftID' | 'EmployeeID' | 'ShiftPatternID' | 'ShiftGroupID' | 'CompanyID' | 'ExcelFileName'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, shiftDay?: Maybe<(
      { __typename?: 'ShiftDayEntity' }
      & Pick<ShiftDayEntity, 'DayType' | 'ClockPairing' | 'CrossDay' | 'FlexiHour' | 'TotalWorkingHour' | 'ClockInTime' | 'ClockOutTime' | 'ShiftStartTime' | 'ShiftEndTime' | 'FirstHalfEndTime' | 'SecondHalfStartTime' | 'CoreHourStartTime' | 'CoreHourEndTime' | 'FlexiBreak' | 'ShiftBreakInterval'>
    )>, shift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'Name' | 'DutyRosterDisplayColour' | 'IsPreOvertimeMin' | 'PreOvertimeMin' | 'IsPostOvertimeMin' | 'PostOvertimeMin' | 'IsFixedOvertimeMin' | 'FixedOvertimeTotal'>
      & { breakTimeList?: Maybe<Array<(
        { __typename?: 'BreakTimeEntity' }
        & Pick<BreakTimeEntity, 'BreakTimeID' | 'Type' | 'Start' | 'End' | 'ShiftID'>
      )>> }
    )> }
  )> }
);

export type GetChangeShiftByYearTmsQueryVariables = Exact<{
  ChangeShiftStatus?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  DepartmentIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  JobGradeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetChangeShiftByYearTmsQuery = (
  { __typename?: 'Query' }
  & { getChangeShiftByYear: Array<(
    { __typename?: 'ChangeShiftEntity' }
    & Pick<ChangeShiftEntity, 'ChangeShiftID' | 'Date' | 'Reason' | 'ChangeShiftStatus' | 'CreatedDT' | 'ModifiedDT' | 'CreatedBy' | 'ModifiedBy' | 'RejectReason' | 'Approver' | 'ApprovedDate' | 'CancelledBy' | 'DocumentID'>
    & { FromShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, ToShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'OrgUnitID' | 'JoinedDate' | 'JobGradeID' | 'CompanyID' | 'FormStatus' | 'EmployeeID' | 'DepartmentID' | 'DivisionID' | 'EmploymentType' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, ReplacedEmployee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, changeShiftList?: Maybe<Array<(
        { __typename?: 'ChangeShiftEntity' }
        & Pick<ChangeShiftEntity, 'EmployeeID'>
      )>> }
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'NotifiedDT' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT' | 'ApproverName'>
        )>> }
      )>> }
    )> }
  )> }
);

export type TemplateLibrarySummaryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type TemplateLibrarySummaryQuery = (
  { __typename?: 'Query' }
  & { TemplateLibrarySummary?: Maybe<(
    { __typename?: 'TemplateLibrarySummaryOutput' }
    & Pick<TemplateLibrarySummaryOutput, 'ShiftSetup' | 'ShiftPattern' | 'ShiftGroup' | 'OvertimePolicy' | 'OvertimeClaimTable' | 'OvertimeReasonCode' | 'OvertimeRoundingPolicy' | 'DailyAllowancePolicy' | 'MonthlyAllowancePolicy' | 'PeriodicDeductionPolicy' | 'DeudctionPolicy' | 'DeductionRoundingPolicy' | 'TimeOff' | 'TimeOffReasonCode' | 'MobileCheckInLocation' | 'templateDate' | 'assignedJob' | 'JobGrade' | 'company' | 'OvertimeCutOffDay' | 'OTRoundingPolicy' | 'AutomatedClockDataImport' | 'PeriodicDate'>
  )> }
);

export type GetOtRoundingQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetOtRoundingQuery = (
  { __typename?: 'Query' }
  & { getOTRounding: Array<(
    { __typename?: 'OTRoundingEntity' }
    & Pick<OtRoundingEntity, 'OTRoundingID' | 'Name' | 'Status' | 'ModifiedDT' | 'CreatedDT' | 'RoundingCriteria'>
  )> }
);

export type ShiftSettingInsertMutationVariables = Exact<{
  ShiftInput: ShiftInput;
  BreakTimeInput: Array<BreakTimeInput> | BreakTimeInput;
  ShiftDayInput: Array<ShiftDayInput> | ShiftDayInput;
}>;


export type ShiftSettingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ShiftSettingInsert'>
);

export type GetDailyAllowanceQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type GetDailyAllowanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DailyAllowanceInUseListing'>
  & { getDailyAllowance: Array<(
    { __typename?: 'DailyAllowancePolicyEntity' }
    & Pick<DailyAllowancePolicyEntity, 'Status' | 'CreatedDT' | 'ModifiedDT' | 'CreatedBy' | 'DailyAllowanceID' | 'Name' | 'Condition' | 'Value' | 'RoundingPolicyID' | 'PayItemID'>
    & { RoundingPolicy?: Maybe<(
      { __typename?: 'AllowanceDeductionRoundingEntity' }
      & Pick<AllowanceDeductionRoundingEntity, 'AllowanceDeductionID' | 'Name' | 'RoundingCriteria'>
    )>, PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayItemID' | 'PayName'>
    )> }
  )> }
);

export type CreateDailyAllowanceMutationVariables = Exact<{
  input: DailyAllowanceInput;
}>;


export type CreateDailyAllowanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDailyAllowance'>
);

export type ShiftSetupDeleteMutationVariables = Exact<{
  ShiftID: Scalars['String'];
}>;


export type ShiftSetupDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ShiftSetupDelete'>
);

export type GetAllowanceDeductionQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetAllowanceDeductionQuery = (
  { __typename?: 'Query' }
  & { getAllowanceDeduction: Array<(
    { __typename?: 'AllowanceDeductionRoundingEntity' }
    & Pick<AllowanceDeductionRoundingEntity, 'AllowanceDeductionID' | 'Name' | 'Status' | 'ModifiedDT' | 'CreatedDT' | 'RoundingCriteria'>
  )> }
);

export type GetShiftListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShiftListQuery = (
  { __typename?: 'Query' }
  & { getShiftList: Array<(
    { __typename?: 'ShiftEntity' }
    & Pick<ShiftEntity, 'DutyRosterDisplayColour' | 'Name' | 'ModifiedDT' | 'IsPreOvertimeMin' | 'PreOvertimeMin' | 'IsPostOvertimeMin' | 'IsDefault' | 'PostOvertimeMin' | 'IsFixedOvertimeMin' | 'FixedOvertimeTotal' | 'ShiftID' | 'Status'>
    & { shiftDayList?: Maybe<Array<(
      { __typename?: 'ShiftDayEntity' }
      & Pick<ShiftDayEntity, 'ShiftDayID' | 'DayType' | 'ShiftID' | 'ClockPairing' | 'CrossDay' | 'FlexiHour' | 'TotalWorkingHour' | 'ClockInTime' | 'ClockOutTime' | 'ShiftStartTime' | 'ShiftEndTime' | 'FirstHalfEndTime' | 'SecondHalfStartTime' | 'FlexiBreak' | 'ShiftBreakInterval' | 'CoreHourStartTime' | 'CoreHourEndTime'>
    )>>, breakTimeList?: Maybe<Array<(
      { __typename?: 'BreakTimeEntity' }
      & Pick<BreakTimeEntity, 'BreakTimeID' | 'Type' | 'Start' | 'End'>
    )>> }
  )> }
);

export type GetShiftByIdQueryVariables = Exact<{
  ShiftID: Scalars['String'];
}>;


export type GetShiftByIdQuery = (
  { __typename?: 'Query' }
  & { getShift: Array<(
    { __typename?: 'ShiftEntity' }
    & Pick<ShiftEntity, 'ShiftID' | 'Name' | 'DutyRosterDisplayColour' | 'CreatedBy' | 'IsPreOvertimeMin' | 'PreOvertimeMin' | 'IsPostOvertimeMin' | 'PostOvertimeMin' | 'IsFixedOvertimeMin' | 'FixedOvertimeTotal' | 'Status' | 'NewStructure'>
    & { breakTimeList?: Maybe<Array<(
      { __typename?: 'BreakTimeEntity' }
      & Pick<BreakTimeEntity, 'BreakTimeID' | 'Type' | 'Start' | 'End'>
    )>>, shiftDayList?: Maybe<Array<(
      { __typename?: 'ShiftDayEntity' }
      & Pick<ShiftDayEntity, 'DayType' | 'ShiftDayID' | 'ClockPairing' | 'CrossDay' | 'FlexiHour' | 'TotalWorkingHour' | 'ClockInTime' | 'ClockOutTime' | 'ShiftStartTime' | 'ShiftEndTime' | 'FirstHalfEndTime' | 'SecondHalfStartTime' | 'FlexiBreak' | 'ShiftBreakInterval' | 'CoreHourStartTime' | 'CoreHourEndTime' | 'IsPreOvertime' | 'PreOvertimeMin' | 'IsPostOvertime' | 'PostOvertimeMin' | 'IsFixedOvertime' | 'FixedOvertimeStart' | 'FixedOvertimeEnd' | 'FixedOvertimeTotal'>
      & { breakTimeList?: Maybe<Array<(
        { __typename?: 'BreakTimeEntity' }
        & Pick<BreakTimeEntity, 'BreakTimeID' | 'Type' | 'Start' | 'End'>
      )>> }
    )>> }
  )> }
);

export type ShiftSetupUpdateMutationVariables = Exact<{
  ShiftID: Scalars['String'];
  ShiftInput: ShiftInput;
  BreakTimeInput: Array<BreakTimeInput> | BreakTimeInput;
  ShiftDayInput: Array<ShiftDayInput> | ShiftDayInput;
}>;


export type ShiftSetupUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ShiftSetupUpdate'>
);

export type DeleteShiftPatternMutationVariables = Exact<{
  ShiftPatternID: Scalars['String'];
}>;


export type DeleteShiftPatternMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteShiftPattern'>
);

export type ShiftPatternListingQueryVariables = Exact<{
  ShiftPatternID?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  EndMonth?: Maybe<Scalars['DateTime']>;
}>;


export type ShiftPatternListingQuery = (
  { __typename?: 'Query' }
  & { ShiftPatternListing: Array<(
    { __typename?: 'ShiftPatternEntity' }
    & Pick<ShiftPatternEntity, 'Name' | 'ShiftPatternID' | 'Start' | 'End' | 'Active' | 'ModifiedDT' | 'CreatedDT' | 'Status'>
    & { Details?: Maybe<Array<(
      { __typename?: 'ShiftPatternDetailEntity' }
      & Pick<ShiftPatternDetailEntity, 'ShiftPatternID' | 'ShiftPatternDetailID' | 'Days' | 'ShiftTypeID' | 'ShiftDayID'>
      & { shiftDay?: Maybe<(
        { __typename?: 'ShiftDayEntity' }
        & Pick<ShiftDayEntity, 'DayType'>
      )> }
    )>> }
  )> }
);

export type ValidShiftPatternListingQueryVariables = Exact<{
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
}>;


export type ValidShiftPatternListingQuery = (
  { __typename?: 'Query' }
  & { ValidShiftPatternListing: Array<(
    { __typename?: 'ShiftPatternEntity' }
    & Pick<ShiftPatternEntity, 'Name' | 'ShiftPatternID' | 'Start' | 'End' | 'Active' | 'ModifiedDT' | 'CreatedDT' | 'Status'>
    & { Details?: Maybe<Array<(
      { __typename?: 'ShiftPatternDetailEntity' }
      & Pick<ShiftPatternDetailEntity, 'ShiftPatternID' | 'ShiftPatternDetailID' | 'Days' | 'ShiftTypeID' | 'ShiftDayID'>
      & { shiftDay?: Maybe<(
        { __typename?: 'ShiftDayEntity' }
        & Pick<ShiftDayEntity, 'DayType'>
      )> }
    )>> }
  )> }
);

export type CreateShiftPatternMutationVariables = Exact<{
  ShiftPatternInput: ShiftPatternInput;
  ShiftPatternDetailInput: Array<ShiftPatternDetailInput> | ShiftPatternDetailInput;
}>;


export type CreateShiftPatternMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createShiftPattern'>
);

export type ShiftSettingActiveListingQueryVariables = Exact<{ [key: string]: never; }>;


export type ShiftSettingActiveListingQuery = (
  { __typename?: 'Query' }
  & { getShift: Array<(
    { __typename?: 'ShiftEntity' }
    & Pick<ShiftEntity, 'DutyRosterDisplayColour' | 'Name' | 'ModifiedDT' | 'IsPreOvertimeMin' | 'PreOvertimeMin' | 'IsPostOvertimeMin' | 'PostOvertimeMin' | 'IsFixedOvertimeMin' | 'FixedOvertimeTotal' | 'ShiftID' | 'Status'>
    & { shiftDayList?: Maybe<Array<(
      { __typename?: 'ShiftDayEntity' }
      & Pick<ShiftDayEntity, 'ShiftDayID' | 'DayType' | 'ShiftID' | 'ClockPairing' | 'CrossDay' | 'FlexiHour' | 'TotalWorkingHour' | 'ClockInTime' | 'ClockOutTime' | 'ShiftStartTime' | 'ShiftEndTime' | 'FirstHalfEndTime' | 'SecondHalfStartTime' | 'FlexiBreak' | 'ShiftBreakInterval' | 'CoreHourStartTime' | 'CoreHourEndTime'>
    )>>, breakTimeList?: Maybe<Array<(
      { __typename?: 'BreakTimeEntity' }
      & Pick<BreakTimeEntity, 'BreakTimeID' | 'Type' | 'Start' | 'End'>
    )>> }
  )> }
);

export type UpdateShiftPatternMutationVariables = Exact<{
  ShiftPatternID: Scalars['String'];
  ShiftPatternInput: ShiftPatternInput;
  ShiftPatternDetailInput: Array<ShiftPatternDetailInput> | ShiftPatternDetailInput;
}>;


export type UpdateShiftPatternMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateShiftPattern'>
);

export type UpdateShiftGroupStatusMutationVariables = Exact<{
  ShiftGroupID: Scalars['String'];
  Status: Status;
}>;


export type UpdateShiftGroupStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateShiftGroupStatus'>
);

export type GetShiftGroupListingQueryVariables = Exact<{
  EndMonth?: Maybe<Scalars['DateTime']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
}>;


export type GetShiftGroupListingQuery = (
  { __typename?: 'Query' }
  & { getShiftGroup: Array<(
    { __typename?: 'ShiftGroupEntity' }
    & Pick<ShiftGroupEntity, 'Start' | 'End' | 'Name' | 'Status' | 'Description' | 'DefaultShiftGroup' | 'ClockingOption' | 'MultipleInOutOption' | 'ShiftGroupID' | 'ModifiedDT' | 'ByQR' | 'QRSelfie' | 'ByLocation' | 'LocationSelfie' | 'ByBluetooth' | 'BluetoothSelfie'>
    & { ShiftGroupShift: Array<(
      { __typename?: 'ShiftGroupShiftEntity' }
      & Pick<ShiftGroupShiftEntity, 'ShiftGroupShiftID' | 'ShiftID'>
    )>, ShiftGroupPattern?: Maybe<Array<(
      { __typename?: 'ShiftGroupPatternEntity' }
      & Pick<ShiftGroupPatternEntity, 'ShiftGroupPatternID' | 'CompanyID' | 'ShiftGroupID' | 'ShiftPatternID' | 'EffectiveDate'>
    )>> }
  )> }
);

export type GetAllShiftQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllShiftQuery = (
  { __typename?: 'Query' }
  & { getShift: Array<(
    { __typename?: 'ShiftEntity' }
    & Pick<ShiftEntity, 'Name' | 'ShiftID' | 'Status'>
  )> }
);

export type CreateShiftGroupMutationVariables = Exact<{
  ShiftGroupInput: ShiftGroupInput;
  ShiftGroupShiftInput?: Maybe<Array<ShiftGroupShiftInput> | ShiftGroupShiftInput>;
}>;


export type CreateShiftGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createShiftGroup'>
);

export type UpdateShiftGroupMutationVariables = Exact<{
  ShiftGroupID: Scalars['String'];
  ShiftGroupInput: ShiftGroupInput;
  ShiftGroupShiftInput?: Maybe<Array<ShiftGroupShiftInput> | ShiftGroupShiftInput>;
}>;


export type UpdateShiftGroupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateShiftGroup'>
);

export type GetTotalOverTimeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CutOffDay: Scalars['Float'];
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type GetTotalOverTimeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetTotalOverTime'>
);

export type GetOvertimePolicyQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type GetOvertimePolicyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OvertimePolicyInUseListing'>
  & { getOvertimePolicy?: Maybe<Array<(
    { __typename?: 'OvertimePolicyEntity' }
    & Pick<OvertimePolicyEntity, 'OvertimePolicyID' | 'Title' | 'CutOffDate' | 'TimeOff' | 'HourlyLeave' | 'HalfDayLeave' | 'EnableAutoSubmission' | 'OvertimeRounding' | 'AllowBackdatedOvertime' | 'BackdatedOTMethod' | 'BackdatedOTDay' | 'EnableMaxOvertimeLimit' | 'MaxOTHour' | 'OvertimeClaimTable' | 'ExceedOvertimeClaimTable' | 'AllowExceedOvertimeLimit' | 'OTLimitDayType' | 'OTRoundingID' | 'LateIn' | 'EarlyOut' | 'ModifiedDT' | 'CreatedDT' | 'ShiftBreakLateIn' | 'ShiftBreakEarlyOut'>
    & { otRounding?: Maybe<(
      { __typename?: 'OTRoundingEntity' }
      & Pick<OtRoundingEntity, 'OTRoundingID' | 'Name'>
    )>, ExceedOvertimeClaimTableObj?: Maybe<(
      { __typename?: 'OvertimeClaimTableEntity' }
      & Pick<OvertimeClaimTableEntity, 'Title' | 'OTClaimTableType' | 'OvertimeClaimTableID' | 'OTClaimData' | 'ReplacementLeaveData'>
    )>, OvertimeClaimTableObj?: Maybe<(
      { __typename?: 'OvertimeClaimTableEntity' }
      & Pick<OvertimeClaimTableEntity, 'Title' | 'OTClaimTableType' | 'OvertimeClaimTableID' | 'OTClaimData' | 'ReplacementLeaveData'>
    )> }
  )>>, getOTRounding: Array<(
    { __typename?: 'OTRoundingEntity' }
    & Pick<OtRoundingEntity, 'OTRoundingID' | 'Name'>
  )>, PayItemListing: Array<(
    { __typename?: 'PayItemEntity' }
    & Pick<PayItemEntity, 'PayName' | 'PayItemID' | 'PayItemType'>
  )> }
);

export type OvertimePolicyDeleteMutationVariables = Exact<{
  OvertimePolicyID: Scalars['String'];
}>;


export type OvertimePolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OvertimePolicyDelete'>
);

export type OvertimePolicyInsertMutationVariables = Exact<{
  input: OvertimePolicyInput;
}>;


export type OvertimePolicyInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OvertimePolicyInsert'>
);

export type OvertimePolicyUpdateMutationVariables = Exact<{
  input: OvertimePolicyInput;
}>;


export type OvertimePolicyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OvertimePolicyUpdate'>
);

export type GetOvertimeTableListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
}>;


export type GetOvertimeTableListingQuery = (
  { __typename?: 'Query' }
  & { getOvertimeClaimTable: Array<(
    { __typename?: 'OvertimeClaimTableEntity' }
    & Pick<OvertimeClaimTableEntity, 'Title' | 'OTClaimTableType' | 'OvertimeClaimTableID' | 'OTClaimData' | 'ReplacementLeaveData'>
  )> }
);

export type UpdateOvertimeClaimTableStatusMutationVariables = Exact<{
  OvertimeClaimTableID: Scalars['String'];
  Status: Status;
}>;


export type UpdateOvertimeClaimTableStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOvertimeClaimTableStatus'>
);

export type CreateOvertimeClaimTableMutationVariables = Exact<{
  Input: OvertimeClaimTableInput;
}>;


export type CreateOvertimeClaimTableMutation = (
  { __typename?: 'Mutation' }
  & { createOvertimeClaimTable: (
    { __typename?: 'OvertimeClaimTableEntity' }
    & Pick<OvertimeClaimTableEntity, 'OvertimeClaimTableID'>
  ) }
);

export type GetPayItemListingsQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type GetPayItemListingsQuery = (
  { __typename?: 'Query' }
  & { PayItemListing: Array<(
    { __typename?: 'PayItemEntity' }
    & Pick<PayItemEntity, 'PayName' | 'PayItemID' | 'PayItemType'>
  )> }
);

export type OtReasonInsertMutationVariables = Exact<{
  Input: OvertimeReasonInput;
}>;


export type OtReasonInsertMutation = (
  { __typename?: 'Mutation' }
  & { OTReasonInsert: (
    { __typename?: 'OvertimeReasonEntity' }
    & Pick<OvertimeReasonEntity, 'OvertimeReasonID'>
  ) }
);

export type OtReasonListingQueryVariables = Exact<{ [key: string]: never; }>;


export type OtReasonListingQuery = (
  { __typename?: 'Query' }
  & { OTReasonListing: Array<(
    { __typename?: 'OvertimeReasonEntity' }
    & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name' | 'Status' | 'CreatedDT' | 'ModifiedDT'>
  )> }
);

export type OtReasonUpdateMutationVariables = Exact<{
  OvertimeReasonID: Scalars['String'];
  Input: OvertimeReasonInput;
}>;


export type OtReasonUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OTReasonUpdate'>
);

export type OtReasonStatusMutationVariables = Exact<{
  OvertimeReasonID: Scalars['String'];
  Status: Scalars['String'];
}>;


export type OtReasonStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OTReasonStatus'>
);

export type OtReasonDeleteMutationVariables = Exact<{
  OvertimeReasonID: Scalars['String'];
}>;


export type OtReasonDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OTReasonDelete'>
);

export type DeleteOtRoundingMutationVariables = Exact<{
  OTRoundingID: Scalars['String'];
}>;


export type DeleteOtRoundingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOTRounding'>
);

export type CreateOtRoundingMutationVariables = Exact<{
  input: OtRoundingInput;
}>;


export type CreateOtRoundingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOTRounding'>
);

export type UpdateOtRoundingMutationVariables = Exact<{
  OTRoundingID: Scalars['String'];
  input: OtRoundingInput;
}>;


export type UpdateOtRoundingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOTRounding'>
);

export type DeleteAllowanceDeductionMutationVariables = Exact<{
  AllowanceDeductionID: Scalars['String'];
}>;


export type DeleteAllowanceDeductionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAllowanceDeduction'>
);

export type CreateAllowanceDeductionMutationVariables = Exact<{
  input: AllowanceDeductionInput;
}>;


export type CreateAllowanceDeductionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAllowanceDeduction'>
);

export type UpdateAllowanceDeductionMutationVariables = Exact<{
  AllowanceDeductionID: Scalars['String'];
  input: AllowanceDeductionInput;
}>;


export type UpdateAllowanceDeductionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAllowanceDeduction'>
);

export type DeleteDailyAllowanceMutationVariables = Exact<{
  DailyAllowanceID: Scalars['String'];
}>;


export type DeleteDailyAllowanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDailyAllowance'>
);

export type GetShiftQueryVariables = Exact<{
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
}>;


export type GetShiftQuery = (
  { __typename?: 'Query' }
  & { getShift: Array<(
    { __typename?: 'ShiftEntity' }
    & Pick<ShiftEntity, 'ShiftID' | 'Name' | 'Status' | 'DutyRosterDisplayColour'>
    & { shiftDayList?: Maybe<Array<(
      { __typename?: 'ShiftDayEntity' }
      & Pick<ShiftDayEntity, 'ShiftDayID' | 'ShiftID' | 'DayType'>
    )>> }
  )> }
);

export type UpdateDailyAllowanceMutationVariables = Exact<{
  DailyAllowanceID: Scalars['String'];
  input: DailyAllowanceInput;
}>;


export type UpdateDailyAllowanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDailyAllowance'>
);

export type DeleteMonthlyAllowanceMutationVariables = Exact<{
  MonthlyAllowanceID: Scalars['String'];
}>;


export type DeleteMonthlyAllowanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMonthlyAllowance'>
);

export type GetMonthlyAllowanceQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  Status?: Maybe<Status>;
}>;


export type GetMonthlyAllowanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MonthlyAllowanceInUseListing'>
  & { getMonthlyAllowance: Array<(
    { __typename?: 'MonthlyAllowancePolicyEntity' }
    & Pick<MonthlyAllowancePolicyEntity, 'PeriodicCycle' | 'PeriodicCutoff' | 'PeriodicEffectiveDate' | 'PeriodicAllowanceTypeID' | 'Status' | 'CreatedDT' | 'ModifiedDT' | 'CreatedBy' | 'MonthlyAllowanceID' | 'Name' | 'Condition' | 'Value' | 'RoundingPolicyID' | 'PayItemID'>
    & { RoundingPolicy?: Maybe<(
      { __typename?: 'AllowanceDeductionRoundingEntity' }
      & Pick<AllowanceDeductionRoundingEntity, 'AllowanceDeductionID' | 'Name' | 'RoundingCriteria'>
    )>, PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayItemID' | 'PayName'>
    )>, PeriodicAllowancePolicyCondition?: Maybe<Array<(
      { __typename?: 'PeriodicAllowancePolicyConditionEntity' }
      & Pick<PeriodicAllowancePolicyConditionEntity, 'ID' | 'Value' | 'Condition' | 'Status'>
    )>> }
  )> }
);

export type CreateMonthlyAllowanceMutationVariables = Exact<{
  input: MonthlyAllowanceInput;
  conditionList: Array<PeriodicAllowancePolicyConditionInput> | PeriodicAllowancePolicyConditionInput;
}>;


export type CreateMonthlyAllowanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMonthlyAllowance'>
);

export type UpdateMonthlyAllowanceMutationVariables = Exact<{
  MonthlyAllowanceID: Scalars['String'];
  input: MonthlyAllowanceInput;
  conditionList: Array<PeriodicAllowancePolicyConditionInput> | PeriodicAllowancePolicyConditionInput;
}>;


export type UpdateMonthlyAllowanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMonthlyAllowance'>
);

export type GetPeriodicDeductionQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
}>;


export type GetPeriodicDeductionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PeriodicDeductionInUseListing'>
  & { getPeriodicDeduction: Array<(
    { __typename?: 'PeriodicDeductionPolicyEntity' }
    & Pick<PeriodicDeductionPolicyEntity, 'PeriodicCycle' | 'PeriodicCutoff' | 'PeriodicEffectiveDate' | 'PeriodicAllowanceTypeID' | 'Status' | 'CreatedDT' | 'ModifiedDT' | 'CreatedBy' | 'PeriodicDeductionID' | 'Name' | 'RoundingPolicyID' | 'PayItemID'>
    & { RoundingPolicy?: Maybe<(
      { __typename?: 'AllowanceDeductionRoundingEntity' }
      & Pick<AllowanceDeductionRoundingEntity, 'AllowanceDeductionID' | 'Name' | 'RoundingCriteria'>
    )>, PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayItemID' | 'PayName'>
    )>, PeriodicDeductionPolicyCondition?: Maybe<Array<(
      { __typename?: 'PeriodicDeductionPolicyConditionEntity' }
      & Pick<PeriodicDeductionPolicyConditionEntity, 'ID' | 'Value' | 'Condition' | 'Status'>
    )>> }
  )> }
);

export type CreatePeriodicDeductionMutationVariables = Exact<{
  input: PeriodicDeductionInput;
  conditionList: Array<PeriodicDeductionPolicyConditionInput> | PeriodicDeductionPolicyConditionInput;
}>;


export type CreatePeriodicDeductionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPeriodicDeduction'>
);

export type UpdatePeriodicDeductionMutationVariables = Exact<{
  PeriodicDeductionID: Scalars['String'];
  input: PeriodicDeductionInput;
  conditionList: Array<PeriodicDeductionPolicyConditionInput> | PeriodicDeductionPolicyConditionInput;
}>;


export type UpdatePeriodicDeductionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePeriodicDeduction'>
);

export type DeletePeriodicDeductionMutationVariables = Exact<{
  PeriodicDeductionID: Scalars['String'];
}>;


export type DeletePeriodicDeductionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePeriodicDeduction'>
);

export type GetDeductionPolicyListingQueryVariables = Exact<{
  SubscriptionAccountID: Scalars['String'];
  Status: Status;
}>;


export type GetDeductionPolicyListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DeductionPolicyInUseListing'>
  & { getDeductionPolicy: Array<(
    { __typename?: 'DeductionPolicyEntity' }
    & Pick<DeductionPolicyEntity, 'DeductionPolicyID' | 'Title' | 'Status' | 'Eligibility' | 'Value' | 'RoundingPolicyID' | 'PayItemID'>
    & { PayItem?: Maybe<(
      { __typename?: 'PayItemEntity' }
      & Pick<PayItemEntity, 'PayName'>
    )> }
  )> }
);

export type UpdateDeductionPolicyStatusMutationVariables = Exact<{
  DeductionPolicyID: Scalars['String'];
  Status: Status;
}>;


export type UpdateDeductionPolicyStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDeductionPolicyStatus'>
);

export type CreateDeductionPolicyMutationVariables = Exact<{
  input: DeductionPolicyInput;
}>;


export type CreateDeductionPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createDeductionPolicy: (
    { __typename?: 'DeductionPolicyEntity' }
    & Pick<DeductionPolicyEntity, 'DeductionPolicyID'>
  ) }
);

export type UpdateDeductionPolicyMutationVariables = Exact<{
  input: DeductionPolicyInput;
  DeductionPolicyID: Scalars['String'];
}>;


export type UpdateDeductionPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDeductionPolicy'>
);

export type TimeOffSettingInsertMutationVariables = Exact<{
  input: TimeOffSettingInput;
}>;


export type TimeOffSettingInsertMutation = (
  { __typename?: 'Mutation' }
  & { TimeOffSettingInsert: (
    { __typename?: 'TimeOffSettingEntity' }
    & Pick<TimeOffSettingEntity, 'TimeOffSettingID'>
  ) }
);

export type TimeOffSettingUpdateMutationVariables = Exact<{
  input: TimeOffSettingInput;
}>;


export type TimeOffSettingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TimeOffSettingUpdate'>
);

export type TimeOffSettingDeleteMutationVariables = Exact<{
  TimeOffSettingID: Scalars['String'];
}>;


export type TimeOffSettingDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TimeOffSettingDelete'>
);

export type TimeOffSettingListingQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeOffSettingListingQuery = (
  { __typename?: 'Query' }
  & { TimeOffSettingListing: Array<(
    { __typename?: 'TimeOffSettingEntity' }
    & Pick<TimeOffSettingEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'TimeOffSettingID' | 'SubscriptionAccountID' | 'Name' | 'EnableTimeOff' | 'TimesPerMonth' | 'MinIntervalPerRequest' | 'MaxIntervalPerRequest' | 'MaxTimeOffPerDay' | 'MaxTimeOffPerMonth'>
  )> }
);

export type TimeOffReasonDeleteMutationVariables = Exact<{
  TimeOffReasonID: Scalars['String'];
}>;


export type TimeOffReasonDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TimeOffReasonDelete'>
);

export type TimeOffReasonInputMutationVariables = Exact<{
  input: TimeOffReasonInput;
}>;


export type TimeOffReasonInputMutation = (
  { __typename?: 'Mutation' }
  & { TimeOffReasonInput: (
    { __typename?: 'TimeOffReasonEntity' }
    & Pick<TimeOffReasonEntity, 'TimeOffReasonID' | 'Name'>
  ) }
);

export type TimeOffReasonUpdateMutationVariables = Exact<{
  input: TimeOffReasonInput;
}>;


export type TimeOffReasonUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TimeOffReasonUpdate'>
);

export type DeleteMobileCheckInMutationVariables = Exact<{
  MobileCheckInID: Scalars['String'];
}>;


export type DeleteMobileCheckInMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMobileCheckIn'>
);

export type GetMobileCheckInQueryVariables = Exact<{
  MobileCheckInID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
}>;


export type GetMobileCheckInQuery = (
  { __typename?: 'Query' }
  & { getMobileCheckIn?: Maybe<Array<(
    { __typename?: 'MobileCheckInEntity' }
    & Pick<MobileCheckInEntity, 'Name' | 'Lat' | 'Long' | 'Radius' | 'Address' | 'MobileCheckInID' | 'CompanyID' | 'InUse'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyName'>
    )> }
  )>> }
);

export type InsertUpdateLocationRadiusSettingMutationVariables = Exact<{
  Radius: Scalars['Float'];
  MobileCheckInID?: Maybe<Scalars['String']>;
}>;


export type InsertUpdateLocationRadiusSettingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InsertUpdateLocationRadiusSetting'>
);

export type GetLocationRadiusSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationRadiusSettingQuery = (
  { __typename?: 'Query' }
  & { GetLocationRadiusSetting: (
    { __typename?: 'MobileCheckInEntity' }
    & Pick<MobileCheckInEntity, 'SubscriptionAccountID' | 'MobileCheckInID' | 'IsByLocation' | 'Radius'>
  ) }
);

export type CreateMobileCheckInMutationVariables = Exact<{
  input: MobileCheckInInput;
}>;


export type CreateMobileCheckInMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMobileCheckIn'>
);

export type UpdateMobileCheckInMutationVariables = Exact<{
  MobileCheckInID: Scalars['String'];
  input: MobileCheckInInput;
}>;


export type UpdateMobileCheckInMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMobileCheckIn'>
);

export type QrListingQueryVariables = Exact<{
  DocumentID: Scalars['String'];
}>;


export type QrListingQuery = (
  { __typename?: 'Query' }
  & { QRListing: (
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
  ) }
);

export type GetTemplateLibraryAssignmentInfolineQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetTemplateLibraryAssignmentInfolineQuery = (
  { __typename?: 'Query' }
  & { GetTemplateLibraryAssignmentInfoline: (
    { __typename?: 'TemplateLibraryAssignmentInfolineOutput' }
    & Pick<TemplateLibraryAssignmentInfolineOutput, 'ShiftAssignment' | 'ShiftPatternAssignment' | 'ShiftGroupAssignment' | 'DailyAllowanceAssignment' | 'MonthlyAllowanceAssignment' | 'DeductionAssignment' | 'TimeOffAssignment' | 'OvertimeReason' | 'TimeOffReason' | 'MobileCheckInSite' | 'MobileCheckInRadius' | 'BluetoothBeaconDevices'>
  ) }
);

export type ReplaceTemplateLibraryAssignmentv2MutationVariables = Exact<{
  inputs: Array<TemplateLibraryAssignmentInput> | TemplateLibraryAssignmentInput;
}>;


export type ReplaceTemplateLibraryAssignmentv2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'replaceTemplateLibraryAssignmentv2'>
);

export type GetTemplateLibraryAssignmentPageDataQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Status?: Maybe<Status>;
  input?: Maybe<TemplateLibraryAssignmentInput>;
}>;


export type GetTemplateLibraryAssignmentPageDataQuery = (
  { __typename?: 'Query' }
  & { CompanySummary: (
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
  ), getTemplateLibraryAssignment: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TargetID' | 'TargetType' | 'EnableTarget' | 'CompanyID' | 'Status'>
  )>, getShift: Array<(
    { __typename?: 'ShiftEntity' }
    & Pick<ShiftEntity, 'ShiftID' | 'Status' | 'Name'>
  )>, getShiftPattern: Array<(
    { __typename?: 'ShiftPatternEntity' }
    & Pick<ShiftPatternEntity, 'ShiftPatternID' | 'Status' | 'Name'>
  )>, getShiftGroup: Array<(
    { __typename?: 'ShiftGroupEntity' }
    & Pick<ShiftGroupEntity, 'ShiftGroupID' | 'Status' | 'Name'>
  )>, getDailyAllowance: Array<(
    { __typename?: 'DailyAllowancePolicyEntity' }
    & Pick<DailyAllowancePolicyEntity, 'DailyAllowanceID' | 'Status' | 'Name'>
  )>, getMonthlyAllowance: Array<(
    { __typename?: 'MonthlyAllowancePolicyEntity' }
    & Pick<MonthlyAllowancePolicyEntity, 'MonthlyAllowanceID' | 'Status' | 'Name'>
  )>, getDeductionPolicy: Array<(
    { __typename?: 'DeductionPolicyEntity' }
    & Pick<DeductionPolicyEntity, 'DeductionPolicyID' | 'Status' | 'Title'>
  )>, getAllowanceDeduction: Array<(
    { __typename?: 'AllowanceDeductionRoundingEntity' }
    & Pick<AllowanceDeductionRoundingEntity, 'AllowanceDeductionID' | 'Status' | 'Name'>
  )>, TimeOffSettingListing: Array<(
    { __typename?: 'TimeOffSettingEntity' }
    & Pick<TimeOffSettingEntity, 'Status' | 'TimeOffSettingID' | 'Name'>
  )>, getOTRounding: Array<(
    { __typename?: 'OTRoundingEntity' }
    & Pick<OtRoundingEntity, 'OTRoundingID' | 'Status' | 'Name'>
  )>, OTReasonListing: Array<(
    { __typename?: 'OvertimeReasonEntity' }
    & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Status' | 'Name'>
  )>, TimeOffReasonListing: Array<(
    { __typename?: 'TimeOffReasonEntity' }
    & Pick<TimeOffReasonEntity, 'TimeOffReasonID' | 'Status' | 'Name'>
  )>, getMobileCheckIn?: Maybe<Array<(
    { __typename?: 'MobileCheckInEntity' }
    & Pick<MobileCheckInEntity, 'MobileCheckInID' | 'Status' | 'Name'>
  )>> }
);

export type GetJobGradePolicyMatrixPageDataQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Status?: Maybe<Status>;
  input?: Maybe<TemplateLibraryAssignmentInput>;
}>;


export type GetJobGradePolicyMatrixPageDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getJobGradeByTLA'>
  & { CompanySummary: (
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyName'>
  ), getTemplateLibraryAssignment: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TargetID' | 'TargetType' | 'SourceType' | 'SourceID' | 'Module'>
  )>, getDailyAllowanceByTLA: Array<(
    { __typename?: 'DailyAllowancePolicyEntity' }
    & Pick<DailyAllowancePolicyEntity, 'DailyAllowanceID' | 'Name' | 'Status'>
  )>, getMonthlyAllowanceByTLA: Array<(
    { __typename?: 'MonthlyAllowancePolicyEntity' }
    & Pick<MonthlyAllowancePolicyEntity, 'MonthlyAllowanceID' | 'Name' | 'Status'>
  )>, getDeductionPolicyByTLA: Array<(
    { __typename?: 'DeductionPolicyEntity' }
    & Pick<DeductionPolicyEntity, 'DeductionPolicyID' | 'Title' | 'Status'>
  )>, getOvertimeClaimTable: Array<(
    { __typename?: 'OvertimeClaimTableEntity' }
    & Pick<OvertimeClaimTableEntity, 'Status' | 'OvertimeClaimTableID' | 'Title'>
  )>, getOvertimePolicy?: Maybe<Array<(
    { __typename?: 'OvertimePolicyEntity' }
    & Pick<OvertimePolicyEntity, 'Status' | 'OvertimePolicyID' | 'Title'>
  )>>, getDailyAllowance: Array<(
    { __typename?: 'DailyAllowancePolicyEntity' }
    & Pick<DailyAllowancePolicyEntity, 'Status' | 'DailyAllowanceID' | 'Name'>
  )>, getMonthlyAllowance: Array<(
    { __typename?: 'MonthlyAllowancePolicyEntity' }
    & Pick<MonthlyAllowancePolicyEntity, 'Status' | 'MonthlyAllowanceID' | 'Name'>
  )>, getDeductionPolicy: Array<(
    { __typename?: 'DeductionPolicyEntity' }
    & Pick<DeductionPolicyEntity, 'Status' | 'DeductionPolicyID' | 'Title'>
  )> }
);

export type JobGradeByTlaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type JobGradeByTlaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getJobGradeByTLA'>
);

export type GetOvertimeSettingPageDataQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Status?: Maybe<Status>;
  input?: Maybe<TemplateLibraryAssignmentInput>;
}>;


export type GetOvertimeSettingPageDataQuery = (
  { __typename?: 'Query' }
  & { CompanySummary: (
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'CompanyName' | 'OvertimeCutOffDay'>
  ), getTemplateLibraryAssignment: Array<(
    { __typename?: 'TemplateLibraryAssignmentEntity' }
    & Pick<TemplateLibraryAssignmentEntity, 'TargetID' | 'TargetType' | 'EnableTarget' | 'CompanyID' | 'Status'>
  )>, getOTRounding: Array<(
    { __typename?: 'OTRoundingEntity' }
    & Pick<OtRoundingEntity, 'OTRoundingID' | 'Status' | 'Name'>
  )> }
);

export type OvertimeSettingUpdateMutationVariables = Exact<{
  inputs: Array<TemplateLibraryAssignmentInput> | TemplateLibraryAssignmentInput;
  CompanyID?: Maybe<Scalars['String']>;
  CompanyInput?: Maybe<CompanyInput>;
}>;


export type OvertimeSettingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OvertimeSettingUpdate'>
);

export type EmployeeShiftGroupQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
}>;


export type EmployeeShiftGroupQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EmployeeShiftGroup'>
);

export type GetBeaconDevicesListingQueryVariables = Exact<{
  BluetoothBeaconDevicesID?: Maybe<Scalars['String']>;
}>;


export type GetBeaconDevicesListingQuery = (
  { __typename?: 'Query' }
  & { GetBeaconDevicesListing?: Maybe<Array<(
    { __typename?: 'BluetoothBeaconDevicesEntity' }
    & Pick<BluetoothBeaconDevicesEntity, 'BluetoothBeaconDevicesID' | 'DeviceAddress' | 'DeviceName' | 'LocationName' | 'Status' | 'InUse'>
  )>> }
);

export type CreateUpdateBeaconDeviceMutationVariables = Exact<{
  input: BluetoothBeaconDevicesInput;
}>;


export type CreateUpdateBeaconDeviceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateUpdateBeaconDevice'>
);

export type GetAutomatedClockDataImportListingQueryVariables = Exact<{
  AutomatedClockDataImportID?: Maybe<Scalars['String']>;
}>;


export type GetAutomatedClockDataImportListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetAutomatedClockDataImportListing'>
);

export type DeleteAutomatedClockDataImportMutationVariables = Exact<{
  AutomatedClockDataImportID: Scalars['String'];
}>;


export type DeleteAutomatedClockDataImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteAutomatedClockDataImport'>
);

export type CreateUpdateAutomatedClockDataImportMutationVariables = Exact<{
  input: AutomatedClockDataImportInput;
}>;


export type CreateUpdateAutomatedClockDataImportMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateUpdateAutomatedClockDataImport'>
);

export type TimeOffListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
}>;


export type TimeOffListingQuery = (
  { __typename?: 'Query' }
  & { TimeOffListing: Array<(
    { __typename?: 'TimeOffEntity' }
    & Pick<TimeOffEntity, 'TimeOffID' | 'SubscriptionAccountID' | 'CompanyID' | 'EmployeeID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ToReasonID' | 'WorkDate' | 'Remark' | 'VisitingPlace' | 'TimeOffStatus' | 'EndTime' | 'StartTime' | 'TravelFrom' | 'TravelTo' | 'DocumentID' | 'Reason' | 'Approver' | 'ApproveDate' | 'RejectionRemarks'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'ContactID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'Description'>
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'NotifiedDT' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT' | 'ApproverName'>
        )>> }
      )>> }
    )> }
  )> }
);

export type TimeOffReasonListingQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeOffReasonListingQuery = (
  { __typename?: 'Query' }
  & { TimeOffReasonListing: Array<(
    { __typename?: 'TimeOffReasonEntity' }
    & Pick<TimeOffReasonEntity, 'TimeOffReasonID' | 'Name' | 'ModifiedDT' | 'CreatedDT'>
  )> }
);

export type TimeOffReasonByTlaQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type TimeOffReasonByTlaQuery = (
  { __typename?: 'Query' }
  & { TimeOffReasonByTLA: Array<(
    { __typename?: 'TimeOffReasonEntity' }
    & Pick<TimeOffReasonEntity, 'TimeOffReasonID' | 'Name' | 'ModifiedDT' | 'CreatedDT'>
  )> }
);

export type TimeOffInsertMutationVariables = Exact<{
  input: TimeOffInput;
}>;


export type TimeOffInsertMutation = (
  { __typename?: 'Mutation' }
  & { TimeOffInsert: (
    { __typename?: 'TimeOffEntity' }
    & Pick<TimeOffEntity, 'TimeOffID' | 'TimeOffStatus'>
  ) }
);

export type TimeOffUpdateMutationVariables = Exact<{
  input: Array<TimeOffInput> | TimeOffInput;
  ActionStatus?: Maybe<Scalars['String']>;
  IsSupersede?: Maybe<Scalars['Boolean']>;
}>;


export type TimeOffUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TimeOffUpdate'>
);

export type TimeOffDeleteMutationVariables = Exact<{
  TimeOffID: Scalars['String'];
}>;


export type TimeOffDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TimeOffDelete'>
);

export type TsAccountTypeListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
}>;


export type TsAccountTypeListQuery = (
  { __typename?: 'Query' }
  & { TsAccountTypeList: Array<(
    { __typename?: 'TsAccountTypeEntity' }
    & Pick<TsAccountTypeEntity, 'ID' | 'Code' | 'Name'>
    & { ActivityList: Array<(
      { __typename?: 'ActivityListJson' }
      & Pick<ActivityListJson, 'Description'>
    )>, TsAccountCode: Array<(
      { __typename?: 'TsAccountCodeEntity' }
      & Pick<TsAccountCodeEntity, 'ID' | 'Name'>
    )> }
  )> }
);

export type StaffTimeSheetInsertMutationVariables = Exact<{
  StaffTimesheetInput: StaffTimesheetInput;
}>;


export type StaffTimeSheetInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTimeSheetInsert'>
);

export type StaffTimesheetDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type StaffTimesheetDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StaffTimesheetDelete'>
);

export type TimesheetDatesQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  sheetDate: Scalars['DateTime'];
}>;


export type TimesheetDatesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TimesheetDates'>
);

export type StaffTimesheetListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Date?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
}>;


export type StaffTimesheetListQuery = (
  { __typename?: 'Query' }
  & { StaffTimesheetList: Array<(
    { __typename?: 'StaffTimesheetEntity' }
    & Pick<StaffTimesheetEntity, 'ID' | 'EmployeeID' | 'CompanyID' | 'Date' | 'TsAccountTypeID' | 'TsAccountCodeID' | 'Remark' | 'Status'>
    & { TsAccountCode: (
      { __typename?: 'TsAccountCodeEntity' }
      & Pick<TsAccountCodeEntity, 'Name'>
    ), TsAccountType: (
      { __typename?: 'TsAccountTypeEntity' }
      & Pick<TsAccountTypeEntity, 'Name' | 'Code'>
    ), StaffTimesheetDetail: Array<(
      { __typename?: 'StaffTimesheetDetailEntity' }
      & Pick<StaffTimesheetDetailEntity, 'Activity' | 'Hour' | 'Other'>
    )> }
  )> }
);

export type TsAccountCodeListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  AccountTypeID?: Maybe<Scalars['String']>;
}>;


export type TsAccountCodeListQuery = (
  { __typename?: 'Query' }
  & { TsAccountCodeList: Array<(
    { __typename?: 'TsAccountCodeEntity' }
    & Pick<TsAccountCodeEntity, 'ID' | 'Name' | 'TsAccountTypeID' | 'Status'>
    & { TsAccountType: (
      { __typename?: 'TsAccountTypeEntity' }
      & Pick<TsAccountTypeEntity, 'ID' | 'Code' | 'Name'>
    ) }
  )> }
);

export type TimesheetSummaryQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type TimesheetSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TimesheetSummary'>
);

export type TimesheetHoursQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  SheetDate: Scalars['DateTime'];
}>;


export type TimesheetHoursQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TimesheetHours'>
);

export type TimesheetMandayAnalysisByAccCodeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  AccountCodeID: Scalars['String'];
}>;


export type TimesheetMandayAnalysisByAccCodeQuery = (
  { __typename?: 'Query' }
  & { TimesheetMandayAnalysisByAccCode: Array<(
    { __typename?: 'TimesheetMandayAnalysisByAccCodeDTO' }
    & Pick<TimesheetMandayAnalysisByAccCodeDto, 'Activity' | 'MTD' | 'YTD' | 'TD' | 'AccountCodeID' | 'AccountCodeName'>
  )> }
);

export type TimesheetMandayAnalysisByActivityQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  AccountCodeID: Scalars['String'];
  Activity: Scalars['String'];
}>;


export type TimesheetMandayAnalysisByActivityQuery = (
  { __typename?: 'Query' }
  & { TimesheetMandayAnalysisByActivity: Array<(
    { __typename?: 'TimesheetMandayAnalysisDTO' }
    & Pick<TimesheetMandayAnalysisDto, 'JobGradeID' | 'OrgUnitID' | 'FormStatus' | 'EmployeeName' | 'MTD' | 'YTD' | 'TD' | 'TotalMTD' | 'TotalYTD' | 'TotalTD'>
  )> }
);

export type TimesheetMandayAnalysisByAccTypeQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  AccountTypeID: Scalars['String'];
}>;


export type TimesheetMandayAnalysisByAccTypeQuery = (
  { __typename?: 'Query' }
  & { TimesheetMandayAnalysisByAccType: Array<(
    { __typename?: 'TimesheetMandayAnalysisByAccTypeDTO' }
    & Pick<TimesheetMandayAnalysisByAccTypeDto, 'Account' | 'YTD' | 'MTD' | 'TD' | 'TotalMTD' | 'TotalYTD' | 'TotalTD' | 'AccountTypeName' | 'AccountTypeCode' | 'AccountTypeID'>
  )> }
);

export type TimesheetMandayAnalysisByAccountQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Account: Scalars['String'];
  AccountTypeID: Scalars['String'];
}>;


export type TimesheetMandayAnalysisByAccountQuery = (
  { __typename?: 'Query' }
  & { TimesheetMandayAnalysisByAccount: Array<(
    { __typename?: 'TimesheetMandayAnalysisDTO' }
    & Pick<TimesheetMandayAnalysisDto, 'JobGradeID' | 'OrgUnitID' | 'FormStatus' | 'EmployeeName' | 'YTD' | 'MTD' | 'TD' | 'TotalMTD' | 'TotalYTD' | 'TotalTD'>
  )> }
);

export type IsActvitiyInUseQueryVariables = Exact<{
  TsAccountTypeID: Scalars['String'];
  Activities: Array<Scalars['String']> | Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type IsActvitiyInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsActvitiyInUse'>
);

export type TsAccountCodeInsertMutationVariables = Exact<{
  TsAccountCodeInput: TsAccountCodeInput;
}>;


export type TsAccountCodeInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TsAccountCodeInsert'>
);

export type TsAccountCodeUpdateMutationVariables = Exact<{
  TsAccountCodeInput: TsAccountCodeInput;
}>;


export type TsAccountCodeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TsAccountCodeUpdate'>
);

export type TsAccountCodeDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
}>;


export type TsAccountCodeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TsAccountCodeDelete'>
);

export type TsAccountTypeInsertMutationVariables = Exact<{
  TsAccountTypeInput: TsAccountTypeInput;
}>;


export type TsAccountTypeInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TsAccountTypeInsert'>
);

export type TsAccountTypeUpdateMutationVariables = Exact<{
  TsAccountTypeInput: TsAccountTypeInput;
}>;


export type TsAccountTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TsAccountTypeUpdate'>
);

export type TsAccountTypeDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
}>;


export type TsAccountTypeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TsAccountTypeDelete'>
);

export type ProjectTimesheetEmployeeListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
}>;


export type ProjectTimesheetEmployeeListingQuery = (
  { __typename?: 'Query' }
  & { ProjectTimesheetEmployeeListing?: Maybe<Array<(
    { __typename?: 'ProjectTimesheetApproverAssignmentEntity' }
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName'>
      )> }
    )> }
  )>> }
);

export type AdminDailyEntryListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  ContactID: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
}>;


export type AdminDailyEntryListingQuery = (
  { __typename?: 'Query' }
  & { AdminDailyEntryListing?: Maybe<Array<(
    { __typename?: 'ProjectTimesheetDailyEntryEntity' }
    & Pick<ProjectTimesheetDailyEntryEntity, 'ID' | 'EntryDate' | 'StartTime' | 'EndTime' | 'BreakTime' | 'TotalHours' | 'Remarks'>
    & { ProjectTimesheetMonthlyRecord?: Maybe<(
      { __typename?: 'ProjectTimesheetMonthlyRecordEntity' }
      & Pick<ProjectTimesheetMonthlyRecordEntity, 'ID' | 'ApprovalLog'>
    )> }
  )>> }
);

export type ProjectTimesheeTApproverReportListingQueryVariables = Exact<{
  PeriodYearMonth: Scalars['String'];
  CompanyID: Scalars['String'];
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
}>;


export type ProjectTimesheeTApproverReportListingQuery = (
  { __typename?: 'Query' }
  & { ProjectTimesheeTApproverReportListing?: Maybe<Array<(
    { __typename?: 'ProjectTimesheetApproverMasterEntity' }
    & Pick<ProjectTimesheetApproverMasterEntity, 'ID' | 'Name' | 'ClientName' | 'Department' | 'EmailAddress'>
    & { ProjectTimesheetPeriod?: Maybe<Array<(
      { __typename?: 'ProjectTimesheetPeriodEntity' }
      & Pick<ProjectTimesheetPeriodEntity, 'ID'>
      & { ProjectTimesheetMonthlyRecord?: Maybe<Array<(
        { __typename?: 'ProjectTimesheetMonthlyRecordEntity' }
        & Pick<ProjectTimesheetMonthlyRecordEntity, 'EmployeeID' | 'ApprovalLog'>
      )>>, ProjectTimesheetApproverAssignment?: Maybe<Array<(
        { __typename?: 'ProjectTimesheetApproverAssignmentEntity' }
        & Pick<ProjectTimesheetApproverAssignmentEntity, 'ID' | 'EmployeeID'>
        & { Employee?: Maybe<(
          { __typename?: 'EmployeeEntity' }
          & Pick<EmployeeEntity, 'EmployeeNo' | 'Status' | 'CompanyID'>
          & { OrgUnit?: Maybe<(
            { __typename?: 'CompanyOrgUnitEntity' }
            & Pick<CompanyOrgUnitEntity, 'Description'>
          )>, Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'ContactID' | 'FullName'>
          )>, Company?: Maybe<(
            { __typename?: 'CompanyEntity' }
            & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
          )> }
        )> }
      )>> }
    )>> }
  )>> }
);

export type ProjectTimesheetReportForApproverSendEmailMutationVariables = Exact<{
  CompanyName: Scalars['String'];
  PeriodYearMonth: Scalars['String'];
  ReportURL: Scalars['String'];
  input: ProjectTimesheetReportForApproverInput;
}>;


export type ProjectTimesheetReportForApproverSendEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ProjectTimesheetReportForApproverSendEmail'>
);

export type GetLeaveToAproveQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
}>;


export type GetLeaveToAproveQuery = (
  { __typename?: 'Query' }
  & { GetLeaveToAprove?: Maybe<Array<(
    { __typename?: 'LeaveHeaderEntity' }
    & Pick<LeaveHeaderEntity, 'LeaveHeaderID' | 'FromDate' | 'UntilDate' | 'EmployeeID' | 'IsEmergencyLeave' | 'ApprovalStatus' | 'TotalDay' | 'CreatedDT' | 'Remarks' | 'WorkFlowCurrentApproversList'>
    & { LeaveHistory?: Maybe<Array<(
      { __typename?: 'LeaveHeaderEntity' }
      & Pick<LeaveHeaderEntity, 'ApprovalStatus' | 'FromDate' | 'UntilDate' | 'IsEmergencyLeave' | 'TotalDay' | 'CreatedDT' | 'Remarks'>
      & { LeaveType?: Maybe<(
        { __typename?: 'LeaveTypeEntity' }
        & Pick<LeaveTypeEntity, 'Name'>
      )>, Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )>, LeaveDetails?: Maybe<Array<(
        { __typename?: 'LeaveDetailsEntity' }
        & Pick<LeaveDetailsEntity, 'SessionType' | 'LeaveDate'>
      )>> }
    )>>, LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>, LeaveDetails?: Maybe<Array<(
      { __typename?: 'LeaveDetailsEntity' }
      & Pick<LeaveDetailsEntity, 'SessionType' | 'LeaveDate'>
    )>>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type GetLeaveApproveToDoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type GetLeaveApproveToDoQuery = (
  { __typename?: 'Query' }
  & { GetLeaveApproveToDo?: Maybe<Array<(
    { __typename?: 'LeaveHeaderEntity' }
    & Pick<LeaveHeaderEntity, 'LeaveHeaderID' | 'FromDate' | 'UntilDate' | 'EmployeeID' | 'IsEmergencyLeave' | 'Superior' | 'ApproverID' | 'ApprovalStatus' | 'TotalDay' | 'CreatedDT' | 'Remarks' | 'RejectedRemarks' | 'WorkFlowCurrentApproversList'>
    & { LeaveHistory?: Maybe<Array<(
      { __typename?: 'LeaveHeaderEntity' }
      & Pick<LeaveHeaderEntity, 'ApprovalStatus' | 'FromDate' | 'UntilDate' | 'IsEmergencyLeave' | 'TotalDay' | 'CreatedDT' | 'Remarks'>
      & { LeaveType?: Maybe<(
        { __typename?: 'LeaveTypeEntity' }
        & Pick<LeaveTypeEntity, 'Name'>
      )>, Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )>, LeaveDetails?: Maybe<Array<(
        { __typename?: 'LeaveDetailsEntity' }
        & Pick<LeaveDetailsEntity, 'SessionType' | 'LeaveDate'>
      )>> }
    )>>, LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>, VoiceDocument?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>, LeaveDetails?: Maybe<Array<(
      { __typename?: 'LeaveDetailsEntity' }
      & Pick<LeaveDetailsEntity, 'SessionType' | 'LeaveDate'>
    )>>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type GetLeaveListingQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  FromDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetLeaveListingQuery = (
  { __typename?: 'Query' }
  & { GetLeaveListing?: Maybe<Array<(
    { __typename?: 'LeaveHeaderEntity' }
    & Pick<LeaveHeaderEntity, 'LeaveHeaderID' | 'FromDate' | 'UntilDate' | 'EmployeeID' | 'IsEmergencyLeave' | 'ApprovalStatus' | 'TotalDay' | 'CreatedDT' | 'Remarks' | 'RejectedRemarks' | 'WorkFlowCurrentApproversList'>
    & { LeaveHistory?: Maybe<Array<(
      { __typename?: 'LeaveHeaderEntity' }
      & Pick<LeaveHeaderEntity, 'ApprovalStatus' | 'FromDate' | 'UntilDate' | 'IsEmergencyLeave' | 'TotalDay' | 'CreatedDT' | 'Remarks'>
      & { LeaveType?: Maybe<(
        { __typename?: 'LeaveTypeEntity' }
        & Pick<LeaveTypeEntity, 'Name'>
      )>, Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )>, LeaveDetails?: Maybe<Array<(
        { __typename?: 'LeaveDetailsEntity' }
        & Pick<LeaveDetailsEntity, 'SessionType' | 'LeaveDate'>
      )>> }
    )>>, LeaveType?: Maybe<(
      { __typename?: 'LeaveTypeEntity' }
      & Pick<LeaveTypeEntity, 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>, VoiceDocument?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>, LeaveDetails?: Maybe<Array<(
      { __typename?: 'LeaveDetailsEntity' }
      & Pick<LeaveDetailsEntity, 'SessionType' | 'LeaveDate'>
    )>>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type ToDoSummaryQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type ToDoSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ToDoSummary'>
);

export type GetSubordinateKpiPlanningQueryVariables = Exact<{
  EmployeeID: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetSubordinateKpiPlanningQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetSubordinateKPIPlanning'>
);

export type GetRequisitionToAproveQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
}>;


export type GetRequisitionToAproveQuery = (
  { __typename?: 'Query' }
  & { GetRequisitionToAprove?: Maybe<Array<(
    { __typename?: 'RequisitionEntity' }
    & Pick<RequisitionEntity, 'RequisitionHeaderID' | 'Subject' | 'Status' | 'EmployeeID' | 'PurposeOfTravel' | 'RequisitionType' | 'ApprovalStatus' | 'TravelRequestID' | 'Amount' | 'Remark' | 'NumberOfItem' | 'CostPerItem' | 'CreatedDT' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'StartDate' | 'EndDate' | 'WorkFlowCurrentApproversList'>
    & { Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'Description' | 'DocumentID' | 'DocumentFile'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, RequisitionHistory?: Maybe<Array<(
      { __typename?: 'RequisitionEntity' }
      & Pick<RequisitionEntity, 'Subject' | 'Status' | 'PurposeOfTravel' | 'RequisitionType' | 'Amount' | 'Remark' | 'NumberOfItem' | 'CostPerItem' | 'ModifiedDT' | 'ApprovalStatus' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'StartDate' | 'EndDate'>
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )> }
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type GetRequisitionApproveToDoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type GetRequisitionApproveToDoQuery = (
  { __typename?: 'Query' }
  & { GetRequisitionApproveToDo?: Maybe<Array<(
    { __typename?: 'RequisitionEntity' }
    & Pick<RequisitionEntity, 'RequisitionHeaderID' | 'Subject' | 'Status' | 'EmployeeID' | 'PurposeOfTravel' | 'RequisitionType' | 'ApprovalStatus' | 'OtherReqNo' | 'TravelReqNo' | 'AssetReqNo' | 'CashReqNo' | 'TravelRequestID' | 'Amount' | 'Remark' | 'NumberOfItem' | 'CostPerItem' | 'CreatedDT' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'StartDate' | 'EndDate' | 'WorkFlowCurrentApproversList'>
    & { Documents?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'Description' | 'DocumentID' | 'DocumentFile'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, MultipleDocuments?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'DocumentType' | 'Description'>
    )>>, RequisitionHistory?: Maybe<Array<(
      { __typename?: 'RequisitionEntity' }
      & Pick<RequisitionEntity, 'Subject' | 'Status' | 'PurposeOfTravel' | 'RequisitionType' | 'Amount' | 'Remark' | 'NumberOfItem' | 'CostPerItem' | 'ModifiedDT' | 'CreatedDT' | 'ApprovalStatus' | 'TravelFrom' | 'TravelTo' | 'TransportMode' | 'StartDate' | 'EndDate'>
      & { Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
      )> }
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type GetClaimToApproveQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
}>;


export type GetClaimToApproveQuery = (
  { __typename?: 'Query' }
  & { GetClaimToApprove?: Maybe<Array<(
    { __typename?: 'ClaimToApproveListingOutput' }
    & Pick<ClaimToApproveListingOutput, 'ApprovalStatus' | 'ClaimHeaderID' | 'CreatedDT' | 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'Subject' | 'ClaimNo' | 'TotalClaim' | 'TotalClaimAmount' | 'WorkFlowCurrentApproversList'>
    & { WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )>, ClaimDetails?: Maybe<Array<(
      { __typename?: 'ClaimDetailsEntity' }
      & Pick<ClaimDetailsEntity, 'ClaimAmount' | 'ClaimRemarks' | 'ClaimDetailsID' | 'ReceiptDate' | 'ReceiptNo'>
      & { MultipleDocuments?: Maybe<Array<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile' | 'DocumentID'>
      )>>, Documents?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentFile' | 'DocumentID'>
      )>, ClaimType?: Maybe<(
        { __typename?: 'ClaimTypeEntity' }
        & Pick<ClaimTypeEntity, 'Name'>
      )> }
    )>>, ClaimHistory?: Maybe<Array<(
      { __typename?: 'ClaimHistoryListingOutput' }
      & Pick<ClaimHistoryListingOutput, 'ApprovalStatus' | 'ClaimHeaderID' | 'CreatedDT' | 'EmployeeID' | 'Subject' | 'TotalClaim' | 'TotalClaimAmount' | 'IsRejected'>
      & { ClaimDetails?: Maybe<Array<(
        { __typename?: 'ClaimDetailsEntity' }
        & Pick<ClaimDetailsEntity, 'ClaimAmount' | 'ClaimRemarks' | 'ClaimDetailsID'>
        & { Documents?: Maybe<(
          { __typename?: 'DocumentsEntity' }
          & Pick<DocumentsEntity, 'DocumentFile' | 'DocumentID'>
        )> }
      )>> }
    )>>, DocumentIDs?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
    )>> }
  )>> }
);

export type GetOvertimeApproveToDoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type GetOvertimeApproveToDoQuery = (
  { __typename?: 'Query' }
  & { GetOvertimeApproveToDo?: Maybe<Array<(
    { __typename?: 'OvertimeEntity' }
    & Pick<OvertimeEntity, 'OvertimeID' | 'EmployeeID' | 'CompanyID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Remark' | 'OvertimeReasonID' | 'ExcelFileName' | 'OvertimeStatus' | 'CreatedDT' | 'ModifiedDT' | 'ModifiedBy' | 'ApproverID' | 'RejectionRemarks' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, otReason?: Maybe<(
      { __typename?: 'OvertimeReasonEntity' }
      & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
    )>, OvertimeHistory?: Maybe<Array<(
      { __typename?: 'OvertimeEntity' }
      & Pick<OvertimeEntity, 'OvertimeID' | 'EmployeeID' | 'CompanyID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Remark' | 'OvertimeReasonID' | 'ExcelFileName' | 'OvertimeStatus' | 'CreatedDT' | 'ModifiedDT' | 'ModifiedBy' | 'ApproverID' | 'RejectionRemarks'>
      & { Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
        )> }
      )>, otReason?: Maybe<(
        { __typename?: 'OvertimeReasonEntity' }
        & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
      )> }
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type ViewOvertimeApprovalHistoryQueryVariables = Exact<{
  ApproverID: Scalars['String'];
}>;


export type ViewOvertimeApprovalHistoryQuery = (
  { __typename?: 'Query' }
  & { ViewOvertimeApprovalHistory?: Maybe<Array<(
    { __typename?: 'OvertimeEntity' }
    & Pick<OvertimeEntity, 'OvertimeID' | 'CreatedDT' | 'StartTime' | 'EndTime' | 'Remark' | 'OvertimeStatus' | 'Approver' | 'ApproveDate' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, otReason?: Maybe<(
      { __typename?: 'OvertimeReasonEntity' }
      & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type GetOvertimeToApproveQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
}>;


export type GetOvertimeToApproveQuery = (
  { __typename?: 'Query' }
  & { GetOvertimeToApprove?: Maybe<Array<(
    { __typename?: 'OvertimeEntity' }
    & Pick<OvertimeEntity, 'OvertimeID' | 'EmployeeID' | 'CompanyID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Remark' | 'OvertimeReasonID' | 'ExcelFileName' | 'OvertimeStatus' | 'CreatedDT' | 'ModifiedDT' | 'ModifiedBy' | 'ApproverID' | 'RejectionRemarks' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, otReason?: Maybe<(
      { __typename?: 'OvertimeReasonEntity' }
      & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
    )>, OvertimeHistory?: Maybe<Array<(
      { __typename?: 'OvertimeEntity' }
      & Pick<OvertimeEntity, 'OvertimeID' | 'EmployeeID' | 'CompanyID' | 'WorkDate' | 'StartTime' | 'EndTime' | 'Remark' | 'OvertimeReasonID' | 'ExcelFileName' | 'OvertimeStatus' | 'CreatedDT' | 'ModifiedDT' | 'ModifiedBy' | 'ApproverID' | 'RejectionRemarks'>
      & { Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
        )> }
      )>, otReason?: Maybe<(
        { __typename?: 'OvertimeReasonEntity' }
        & Pick<OvertimeReasonEntity, 'OvertimeReasonID' | 'Name'>
      )> }
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type GetTimeOffToApproveQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
}>;


export type GetTimeOffToApproveQuery = (
  { __typename?: 'Query' }
  & { GetTimeOffToApprove?: Maybe<Array<(
    { __typename?: 'TimeOffEntity' }
    & Pick<TimeOffEntity, 'TimeOffID' | 'SubscriptionAccountID' | 'CompanyID' | 'EmployeeID' | 'CreatedDT' | 'ModifiedDT' | 'ToReasonID' | 'WorkDate' | 'Remark' | 'VisitingPlace' | 'TimeOffStatus' | 'EndTime' | 'StartTime' | 'TravelFrom' | 'TravelTo' | 'Reason' | 'Approver' | 'RejectionRemarks' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'ContactID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, ToReason?: Maybe<(
      { __typename?: 'TimeOffReasonEntity' }
      & Pick<TimeOffReasonEntity, 'TimeOffReasonID' | 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>, TimeOffHistory?: Maybe<Array<(
      { __typename?: 'TimeOffEntity' }
      & Pick<TimeOffEntity, 'TimeOffID' | 'SubscriptionAccountID' | 'CompanyID' | 'EmployeeID' | 'CreatedDT' | 'ModifiedDT' | 'ToReasonID' | 'WorkDate' | 'Remark' | 'VisitingPlace' | 'TimeOffStatus' | 'EndTime' | 'StartTime' | 'TravelFrom' | 'TravelTo' | 'Reason' | 'Approver' | 'RejectionRemarks'>
      & { Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'ContactID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
        )> }
      )>, ToReason?: Maybe<(
        { __typename?: 'TimeOffReasonEntity' }
        & Pick<TimeOffReasonEntity, 'TimeOffReasonID' | 'Name'>
      )>, Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
      )> }
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type GetTimeOffAproveToDoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type GetTimeOffAproveToDoQuery = (
  { __typename?: 'Query' }
  & { GetTimeOffAproveToDo?: Maybe<Array<(
    { __typename?: 'TimeOffEntity' }
    & Pick<TimeOffEntity, 'TimeOffID' | 'SubscriptionAccountID' | 'CompanyID' | 'EmployeeID' | 'CreatedDT' | 'ModifiedDT' | 'ToReasonID' | 'WorkDate' | 'Remark' | 'VisitingPlace' | 'TimeOffStatus' | 'EndTime' | 'StartTime' | 'TravelFrom' | 'TravelTo' | 'Reason' | 'Approver' | 'RejectionRemarks' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'ContactID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, ToReason?: Maybe<(
      { __typename?: 'TimeOffReasonEntity' }
      & Pick<TimeOffReasonEntity, 'TimeOffReasonID' | 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile'>
    )>, TimeOffHistory?: Maybe<Array<(
      { __typename?: 'TimeOffEntity' }
      & Pick<TimeOffEntity, 'TimeOffID' | 'SubscriptionAccountID' | 'CompanyID' | 'EmployeeID' | 'CreatedDT' | 'ModifiedDT' | 'ToReasonID' | 'WorkDate' | 'Remark' | 'VisitingPlace' | 'TimeOffStatus' | 'EndTime' | 'StartTime' | 'TravelFrom' | 'TravelTo' | 'Reason' | 'Approver' | 'RejectionRemarks'>
      & { Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'ContactID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
        )> }
      )>, ToReason?: Maybe<(
        { __typename?: 'TimeOffReasonEntity' }
        & Pick<TimeOffReasonEntity, 'TimeOffReasonID' | 'Name'>
      )>, Document?: Maybe<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'BucketFileName' | 'FileSize'>
      )> }
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type ViewTimeOffApprovalHistoryQueryVariables = Exact<{
  ApproverID: Scalars['String'];
}>;


export type ViewTimeOffApprovalHistoryQuery = (
  { __typename?: 'Query' }
  & { ViewTimeOffApprovalHistory?: Maybe<Array<(
    { __typename?: 'TimeOffEntity' }
    & Pick<TimeOffEntity, 'TimeOffID' | 'CreatedDT' | 'TimeOffStatus' | 'StartTime' | 'EndTime' | 'Remark' | 'Approver' | 'ApproveDate' | 'WorkFlowCurrentApproversList'>
    & { Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo' | 'ContactID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, ToReason?: Maybe<(
      { __typename?: 'TimeOffReasonEntity' }
      & Pick<TimeOffReasonEntity, 'TimeOffReasonID' | 'Name'>
    )>, Document?: Maybe<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'DocumentID' | 'DocumentFile' | 'DocumentType' | 'Description' | 'BucketFileName' | 'FileSize'>
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type GetChangeShiftApproveToDoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type GetChangeShiftApproveToDoQuery = (
  { __typename?: 'Query' }
  & { GetChangeShiftApproveToDo?: Maybe<Array<(
    { __typename?: 'ChangeShiftEntity' }
    & Pick<ChangeShiftEntity, 'ChangeShiftID' | 'Date' | 'Reason' | 'RejectReason' | 'Approver' | 'ChangeShiftStatus' | 'CreatedDT' | 'ModifiedDT' | 'EmployeeID' | 'DocumentID' | 'WorkFlowCurrentApproversList'>
    & { FromShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, ToShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, ReplacedEmployee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, ChangeShiftHistory?: Maybe<Array<(
      { __typename?: 'ChangeShiftEntity' }
      & Pick<ChangeShiftEntity, 'ChangeShiftID' | 'Date' | 'Reason' | 'RejectReason' | 'Approver' | 'ChangeShiftStatus' | 'CreatedDT' | 'DocumentID'>
      & { FromShift?: Maybe<(
        { __typename?: 'ShiftEntity' }
        & Pick<ShiftEntity, 'ShiftID' | 'Name'>
      )>, ToShift?: Maybe<(
        { __typename?: 'ShiftEntity' }
        & Pick<ShiftEntity, 'ShiftID' | 'Name'>
      )>, Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
        )> }
      )>, ReplacedEmployee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type ViewChangeShiftApprovalHistoryQueryVariables = Exact<{
  ApproverID: Scalars['String'];
}>;


export type ViewChangeShiftApprovalHistoryQuery = (
  { __typename?: 'Query' }
  & { ViewChangeShiftApprovalHistory?: Maybe<Array<(
    { __typename?: 'ChangeShiftEntity' }
    & Pick<ChangeShiftEntity, 'ChangeShiftID' | 'Date' | 'Reason' | 'RejectReason' | 'Approver' | 'ApprovedDate' | 'ChangeShiftStatus' | 'CreatedDT' | 'WorkFlowCurrentApproversList'>
    & { FromShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, ToShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, ReplacedEmployee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, ChangeShiftHistory?: Maybe<Array<(
      { __typename?: 'ChangeShiftEntity' }
      & Pick<ChangeShiftEntity, 'ChangeShiftID' | 'Date' | 'Reason' | 'RejectReason' | 'Approver' | 'ChangeShiftStatus' | 'CreatedDT' | 'DocumentID'>
      & { FromShift?: Maybe<(
        { __typename?: 'ShiftEntity' }
        & Pick<ShiftEntity, 'ShiftID' | 'Name'>
      )>, ToShift?: Maybe<(
        { __typename?: 'ShiftEntity' }
        & Pick<ShiftEntity, 'ShiftID' | 'Name'>
      )>, Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
        )> }
      )>, ReplacedEmployee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type GetChangeShiftToApproveQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
}>;


export type GetChangeShiftToApproveQuery = (
  { __typename?: 'Query' }
  & { GetChangeShiftToApprove?: Maybe<Array<(
    { __typename?: 'ChangeShiftEntity' }
    & Pick<ChangeShiftEntity, 'ChangeShiftID' | 'Date' | 'Reason' | 'RejectReason' | 'Approver' | 'ChangeShiftStatus' | 'CreatedDT' | 'ModifiedDT' | 'EmployeeID' | 'DocumentID' | 'WorkFlowCurrentApproversList'>
    & { FromShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, ToShift?: Maybe<(
      { __typename?: 'ShiftEntity' }
      & Pick<ShiftEntity, 'ShiftID' | 'Name'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, ReplacedEmployee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, ChangeShiftHistory?: Maybe<Array<(
      { __typename?: 'ChangeShiftEntity' }
      & Pick<ChangeShiftEntity, 'ChangeShiftID' | 'Date' | 'Reason' | 'RejectReason' | 'Approver' | 'ChangeShiftStatus' | 'CreatedDT' | 'DocumentID'>
      & { FromShift?: Maybe<(
        { __typename?: 'ShiftEntity' }
        & Pick<ShiftEntity, 'ShiftID' | 'Name'>
      )>, ToShift?: Maybe<(
        { __typename?: 'ShiftEntity' }
        & Pick<ShiftEntity, 'ShiftID' | 'Name'>
      )>, Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
        )> }
      )>, ReplacedEmployee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type ToDoStatusUpdateMutationVariables = Exact<{
  ApprovalStatus: ApprovalStatus;
  ChangeShiftIDs: Array<TrxIDs> | TrxIDs;
  TimeOffIDs: Array<TrxIDs> | TrxIDs;
  OvertimeIDs: Array<TrxIDs> | TrxIDs;
  ClaimHeaderIDs: Array<TrxIDs> | TrxIDs;
  RequisitionIDs: Array<TrxIDs> | TrxIDs;
  LeaveHeaderIDs: Array<TrxIDs> | TrxIDs;
  ProjectExpenseClaimHeaderIDs: Array<TrxIDs> | TrxIDs;
  DataChangesIDs?: Maybe<Array<TrxIDs> | TrxIDs>;
  EmployeeID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
}>;


export type ToDoStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ToDoStatusUpdate'>
);

export type CompanyAccessPermListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  RoleID?: Maybe<Scalars['String']>;
  usePayload?: Maybe<Scalars['Boolean']>;
}>;


export type CompanyAccessPermListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CompanyAccessPermList'>
);

export type GetProjectToApproveQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
}>;


export type GetProjectToApproveQuery = (
  { __typename?: 'Query' }
  & { GetProjectToApprove?: Maybe<Array<(
    { __typename?: 'ProjectExpenseToApproveListingOutput' }
    & Pick<ProjectExpenseToApproveListingOutput, 'ApprovalStatus' | 'ApproverName' | 'ApprovalDate' | 'ClaimHeaderID' | 'CreatedDT' | 'EmployeeID' | 'EmployeeName' | 'EmployeeNo' | 'Subject' | 'TotalClaim' | 'TotalClaimAmount' | 'WorkFlowCurrentApproversList'>
    & { WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )>, ClaimRecordsDetail?: Maybe<Array<(
      { __typename?: 'PEGLClaimRecordsDetail' }
      & Pick<PeglClaimRecordsDetail, 'ClaimAmount' | 'ClaimRemarks' | 'ID' | 'ReceiptDate' | 'ReceiptNo' | 'ProjectExpenseTypeID' | 'ProjectExpenseType'>
      & { DocumentIDs?: Maybe<Array<(
        { __typename?: 'DocumentsEntity' }
        & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
      )>> }
    )>>, ClaimHistory?: Maybe<Array<(
      { __typename?: 'ProjectExpenseHistoryListingOutput' }
      & Pick<ProjectExpenseHistoryListingOutput, 'ApprovalStatus' | 'ClaimHeaderID' | 'CreatedDT' | 'EmployeeID' | 'Subject' | 'TotalClaim' | 'TotalClaimAmount' | 'IsRejected'>
      & { ClaimDetails?: Maybe<Array<(
        { __typename?: 'ProjectExpenseClaimDetailEntity' }
        & Pick<ProjectExpenseClaimDetailEntity, 'ClaimAmount' | 'ClaimRemark' | 'ID'>
      )>> }
    )>>, DocumentIDs?: Maybe<Array<(
      { __typename?: 'DocumentsEntity' }
      & Pick<DocumentsEntity, 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentID' | 'DocumentType' | 'Description' | 'DocumentFile' | 'PayPeriodID' | 'ParentID' | 'BucketFileName' | 'FileSize'>
    )>> }
  )>> }
);

export type GetDataChangesToDoQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
}>;


export type GetDataChangesToDoQuery = (
  { __typename?: 'Query' }
  & { GetDataChangesToDo?: Maybe<Array<(
    { __typename?: 'DataChangesEntity' }
    & Pick<DataChangesEntity, 'DataChangesID' | 'CompanyID' | 'Type' | 'ApprovalStatus' | 'CreatedDT' | 'ModifiedDT' | 'Remarks' | 'Description' | 'Action' | 'WorkFlowCurrentApproversList'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
    )>, ChangesData?: Maybe<(
      { __typename?: 'ChangesData' }
      & Pick<ChangesData, 'DocumentID' | 'Nationality' | 'PassportNo' | 'NRIC' | 'Marital' | 'Race' | 'Religion' | 'IsOKU' | 'EmployeeBank' | 'EmployeeBankNo' | 'EmployeeEPFNo' | 'EmployeePCBNo' | 'EmployeeDependentsID' | 'ContactID' | 'IsEmergencyContact' | 'MobileNo' | 'Occupation' | 'Education' | 'ChildEligibleRate' | 'PCBNo' | 'FullName' | 'BirthDate'>
      & { PermanentAddress?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
      )>, Address?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
      )> }
    )>, OldData?: Maybe<(
      { __typename?: 'ChangesData' }
      & Pick<ChangesData, 'DocumentID' | 'Nationality' | 'PassportNo' | 'NRIC' | 'Marital' | 'Race' | 'Religion' | 'IsOKU' | 'EmployeeBank' | 'EmployeeBankNo' | 'EmployeeEPFNo' | 'EmployeePCBNo' | 'EmployeeDependentsID' | 'ContactID' | 'IsEmergencyContact' | 'MobileNo' | 'Occupation' | 'Education' | 'ChildEligibleRate' | 'PCBNo' | 'FullName' | 'BirthDate'>
      & { PermanentAddress?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
      )>, Address?: Maybe<(
        { __typename?: 'Address2' }
        & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
      )> }
    )>, DataChangeHistory?: Maybe<Array<(
      { __typename?: 'DataChangesEntity' }
      & Pick<DataChangesEntity, 'ApprovalStatus' | 'Description' | 'DataChangesID' | 'CreatedDT' | 'Type'>
      & { Employee?: Maybe<(
        { __typename?: 'EmployeeEntity' }
        & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
      )>, ChangesData?: Maybe<(
        { __typename?: 'ChangesData' }
        & Pick<ChangesData, 'DocumentID' | 'Nationality' | 'PassportNo' | 'NRIC' | 'Marital' | 'Race' | 'Religion' | 'IsOKU' | 'EmployeeBank' | 'EmployeeBankNo' | 'EmployeeEPFNo' | 'EmployeePCBNo' | 'EmployeeDependentsID' | 'ContactID' | 'IsEmergencyContact' | 'MobileNo' | 'Occupation' | 'Education' | 'ChildEligibleRate' | 'PCBNo' | 'FullName' | 'BirthDate'>
        & { PermanentAddress?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
        )>, Address?: Maybe<(
          { __typename?: 'Address2' }
          & Pick<Address2, 'Line1' | 'Line2' | 'City' | 'PostalCode' | 'State' | 'Country'>
        )> }
      )> }
    )>>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type ViewDataChangesApprovalHistoryQueryVariables = Exact<{
  ApproverID: Scalars['String'];
}>;


export type ViewDataChangesApprovalHistoryQuery = (
  { __typename?: 'Query' }
  & { ViewDataChangesApprovalHistory?: Maybe<Array<(
    { __typename?: 'DataChangesEntity' }
    & Pick<DataChangesEntity, 'DataChangesID' | 'ApprovalDT' | 'ApproverID' | 'CompanyID' | 'Type' | 'ApprovalStatus' | 'CreatedDT' | 'Description' | 'Action' | 'WorkFlowCurrentApproversList'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName'>
    )>, ChangesData?: Maybe<(
      { __typename?: 'ChangesData' }
      & Pick<ChangesData, 'DocumentID'>
    )>, Employee?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID' | 'EmployeeNo'>
    )>, Approver?: Maybe<(
      { __typename?: 'EmployeeEntity' }
      & Pick<EmployeeEntity, 'EmployeeID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, WorkFlow?: Maybe<(
      { __typename?: 'WorkFlowEntity' }
      & Pick<WorkFlowEntity, 'ID' | 'WorkFlowStatus' | 'SubmitterName' | 'SubmitDate'>
      & { WorkFlowStep?: Maybe<Array<(
        { __typename?: 'WorkFlowStepEntity' }
        & Pick<WorkFlowStepEntity, 'ID' | 'ApprovalStatus' | 'ApproverRoleID' | 'StepNo' | 'RoleName'>
        & { WorkFlowApprovedStep?: Maybe<Array<(
          { __typename?: 'WorkFlowApprovedStepEntity' }
          & Pick<WorkFlowApprovedStepEntity, 'ID' | 'ApproverID' | 'ApproverName' | 'NotifiedDT' | 'ApprovalStatus' | 'StatusRemarks' | 'ApprovalDT'>
        )>> }
      )>> }
    )> }
  )>> }
);

export type TrainingCostListingQueryVariables = Exact<{
  ScheduledTrainingID: Scalars['String'];
}>;


export type TrainingCostListingQuery = (
  { __typename?: 'Query' }
  & { TrainingCostListing: Array<(
    { __typename?: 'TrainingCostEntity' }
    & Pick<TrainingCostEntity, 'TrainingCostID' | 'ScheduledTrainingID' | 'TrainingCostItemID' | 'Cost' | 'Remarks' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
    & { TrainingCostItem?: Maybe<(
      { __typename?: 'TrainingCostItemEntity' }
      & Pick<TrainingCostItemEntity, 'Name'>
    )> }
  )> }
);

export type TrainingCostSubmitMutationVariables = Exact<{
  ScheduleTrainingID: Scalars['String'];
  input: Array<TrainingCostInput> | TrainingCostInput;
}>;


export type TrainingCostSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TrainingCostSubmit'>
);

export type CostAllocationListingQueryVariables = Exact<{
  ScheduledTrainingID: Scalars['String'];
}>;


export type CostAllocationListingQuery = (
  { __typename?: 'Query' }
  & { CostAllocationListing: Array<(
    { __typename?: 'CostAllocationEntity' }
    & Pick<CostAllocationEntity, 'CostAllocationID' | 'CompanyID' | 'ScheduledTrainingID' | 'TrainingAttendeeID' | 'OrgUnitID' | 'AllocateAmount' | 'SubscriptionAccountID' | 'Status' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy'>
  )> }
);

export type HrdfClaimListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type HrdfClaimListingQuery = (
  { __typename?: 'Query' }
  & { HRDFClaimListing: Array<(
    { __typename?: 'HRDFClaimOutput' }
    & Pick<HrdfClaimOutput, 'ScheduledTrainingID' | 'LatestClaimAmount' | 'LatestClaimRefNo'>
  )> }
);

export type CostAllocationSubmitMutationVariables = Exact<{
  ScheduleTrainingID: Scalars['String'];
  input: Array<CostAllocationInput> | CostAllocationInput;
}>;


export type CostAllocationSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CostAllocationSubmit'>
);

export type HrdfClaimSubmitMutationVariables = Exact<{
  input: HrdfClaimInput;
}>;


export type HrdfClaimSubmitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HRDFClaimSubmit'>
);

export type GetUserListingQueryVariables = Exact<{
  UserStatus?: Maybe<Scalars['String']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
}>;


export type GetUserListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserListing'>
);

export type GetEmployeeUserListingQueryVariables = Exact<{
  CompanyID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetEmployeeUserListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEmployeeUserListing'>
);

export type GetUsersByAccountQueryVariables = Exact<{
  accountID: Scalars['String'];
  CompanyID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetUsersByAccountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUsersByAccount'>
);

export type GetActiveUsersByAccountQueryVariables = Exact<{
  CompanyID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  EmployeeType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  OrgUnitID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetActiveUsersByAccountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getActiveUsersByAccount'>
);

export type GetExceededUserWarningQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExceededUserWarningQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetExceededUserWarning'>
);

export type UserRoleIDbyUserQueryVariables = Exact<{
  userID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
}>;


export type UserRoleIDbyUserQuery = (
  { __typename?: 'Query' }
  & { userRoleIDbyUser: Array<(
    { __typename?: 'RoleUserEntAssignmentEntity' }
    & Pick<RoleUserEntAssignmentEntity, 'roleID' | 'userID' | 'entityID'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
  accountName: Scalars['String'];
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'email'>
  ) }
);

export type ResendInvitationMutationVariables = Exact<{
  userID: Scalars['String'];
  accountName: Scalars['String'];
  accountID: Scalars['String'];
}>;


export type ResendInvitationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resendInvitation'>
);

export type UpdateUserMutationVariables = Exact<{
  input: UserInput;
  companyID?: Maybe<Scalars['String']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID'>
  ) }
);

export type ChangeUserPasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
}>;


export type ChangeUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeUserPassword'>
);

export type UnblockUserMutationVariables = Exact<{
  input: PasswordInfo;
  accountID: Scalars['String'];
}>;


export type UnblockUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unblockUser'>
);

export type UserActionStatusMutationVariables = Exact<{
  input: ActionStatus;
  accountID: Scalars['String'];
}>;


export type UserActionStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userActionStatus'>
);

export type GetNonAccessUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNonAccessUsersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetNonAccessUsers'>
);

export type ResendInvitationByIdMutationVariables = Exact<{
  data: Array<Scalars['JSON']> | Scalars['JSON'];
}>;


export type ResendInvitationByIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ResendInvitationById'>
);

export type UpdateHelpdeskAssigneeMutationVariables = Exact<{
  input: UpdateHelpdeskAssigneeInput;
}>;


export type UpdateHelpdeskAssigneeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateHelpdeskAssignee'>
);

export type GetDutyRosterByEmployeeQueryVariables = Exact<{
  EmployeeID: Scalars['String'];
  FromDate: Scalars['String'];
  ToDate: Scalars['String'];
  GetLeaveTimeOffOvertimeData?: Maybe<Scalars['Boolean']>;
}>;


export type GetDutyRosterByEmployeeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDutyRosterByEmployee'>
);

export type GetDutyRosterByEmployeeListQueryVariables = Exact<{
  EmployeeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  FromDate: Scalars['String'];
  ToDate: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetDutyRosterByEmployeeListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDutyRosterByEmployeeList'>
);


export const EmployeeProfileInsertUpdateDocument = gql`
    mutation EmployeeProfileInsertUpdate($DocumentsInput: DocumentsInput, $DocumentID: String) {
  EmployeeProfileInsertUpdate(DocumentsInput: $DocumentsInput, DocumentID: $DocumentID)
}
    `;
export type EmployeeProfileInsertUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>;

/**
 * __useEmployeeProfileInsertUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeProfileInsertUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeProfileInsertUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeProfileInsertUpdateMutation, { data, loading, error }] = useEmployeeProfileInsertUpdateMutation({
 *   variables: {
 *      DocumentsInput: // value for 'DocumentsInput'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useEmployeeProfileInsertUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>(EmployeeProfileInsertUpdateDocument, baseOptions);
      }
export type EmployeeProfileInsertUpdateMutationHookResult = ReturnType<typeof useEmployeeProfileInsertUpdateMutation>;
export type EmployeeProfileInsertUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeProfileInsertUpdateMutation>;
export type EmployeeProfileInsertUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeProfileInsertUpdateMutation, EmployeeProfileInsertUpdateMutationVariables>;
export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    name
    userName
    email
    contactNo
    department
    accountID
    jobTitle
    superUser
    lastestAccessedEntity
    roleID
    isEmployee
    isEmployeeAuth
    employeeID
    helpdeskAssignee
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const IsSuperUserEmployeeDocument = gql`
    query IsSuperUserEmployee($EmployeeID: String!) {
  IsSuperUserEmployee(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useIsSuperUserEmployeeQuery__
 *
 * To run a query within a React component, call `useIsSuperUserEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSuperUserEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSuperUserEmployeeQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useIsSuperUserEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>(IsSuperUserEmployeeDocument, baseOptions);
      }
export function useIsSuperUserEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>(IsSuperUserEmployeeDocument, baseOptions);
        }
export type IsSuperUserEmployeeQueryHookResult = ReturnType<typeof useIsSuperUserEmployeeQuery>;
export type IsSuperUserEmployeeLazyQueryHookResult = ReturnType<typeof useIsSuperUserEmployeeLazyQuery>;
export type IsSuperUserEmployeeQueryResult = ApolloReactCommon.QueryResult<IsSuperUserEmployeeQuery, IsSuperUserEmployeeQueryVariables>;
export const LoginDocument = gql`
    mutation login($password: String!, $loginId: String!, $softwareCode: SoftwareCode!) {
  login(password: $password, loginId: $loginId, softwareCode: $softwareCode) {
    accessToken
    isEmployeeSuperUser
    user {
      accountID
      ID
      name
      superUser
      employeeID
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      loginId: // value for 'loginId'
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const EmployeeAppLoginDocument = gql`
    mutation employeeAppLogin($loginId: String!, $password: String!) {
  employeeAppLogin(loginId: $loginId, password: $password) {
    accessToken
    employee {
      subscriptionAccountID
      employeeID
      employeeNo
      name
      email
      mobileNo
    }
  }
}
    `;
export type EmployeeAppLoginMutationFn = ApolloReactCommon.MutationFunction<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>;

/**
 * __useEmployeeAppLoginMutation__
 *
 * To run a mutation, you first call `useEmployeeAppLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAppLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAppLoginMutation, { data, loading, error }] = useEmployeeAppLoginMutation({
 *   variables: {
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useEmployeeAppLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>(EmployeeAppLoginDocument, baseOptions);
      }
export type EmployeeAppLoginMutationHookResult = ReturnType<typeof useEmployeeAppLoginMutation>;
export type EmployeeAppLoginMutationResult = ApolloReactCommon.MutationResult<EmployeeAppLoginMutation>;
export type EmployeeAppLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeAppLoginMutation, EmployeeAppLoginMutationVariables>;
export const LoggedInEmployeeProfileDocument = gql`
    query loggedInEmployeeProfile {
  loggedInEmployeeProfile {
    subscriptionAccountID
    employeeID
    employeeNo
    companyID
    name
    email
    mobileNo
    LastestAccessedEntity
    SuperUserID
    Salutation
    contactID
  }
}
    `;

/**
 * __useLoggedInEmployeeProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInEmployeeProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInEmployeeProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInEmployeeProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInEmployeeProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>(LoggedInEmployeeProfileDocument, baseOptions);
      }
export function useLoggedInEmployeeProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>(LoggedInEmployeeProfileDocument, baseOptions);
        }
export type LoggedInEmployeeProfileQueryHookResult = ReturnType<typeof useLoggedInEmployeeProfileQuery>;
export type LoggedInEmployeeProfileLazyQueryHookResult = ReturnType<typeof useLoggedInEmployeeProfileLazyQuery>;
export type LoggedInEmployeeProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInEmployeeProfileQuery, LoggedInEmployeeProfileQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChangeEmployeePasswordDocument = gql`
    mutation changeEmployeePassword($newPassword: String!, $currentPassword: String!) {
  changeEmployeePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangeEmployeePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>;

/**
 * __useChangeEmployeePasswordMutation__
 *
 * To run a mutation, you first call `useChangeEmployeePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmployeePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmployeePasswordMutation, { data, loading, error }] = useChangeEmployeePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangeEmployeePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>(ChangeEmployeePasswordDocument, baseOptions);
      }
export type ChangeEmployeePasswordMutationHookResult = ReturnType<typeof useChangeEmployeePasswordMutation>;
export type ChangeEmployeePasswordMutationResult = ApolloReactCommon.MutationResult<ChangeEmployeePasswordMutation>;
export type ChangeEmployeePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeEmployeePasswordMutation, ChangeEmployeePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ForgotEmployeePasswordDocument = gql`
    mutation forgotEmployeePassword($email: String!) {
  forgotEmployeePassword(email: $email)
}
    `;
export type ForgotEmployeePasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>;

/**
 * __useForgotEmployeePasswordMutation__
 *
 * To run a mutation, you first call `useForgotEmployeePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotEmployeePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotEmployeePasswordMutation, { data, loading, error }] = useForgotEmployeePasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotEmployeePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>(ForgotEmployeePasswordDocument, baseOptions);
      }
export type ForgotEmployeePasswordMutationHookResult = ReturnType<typeof useForgotEmployeePasswordMutation>;
export type ForgotEmployeePasswordMutationResult = ApolloReactCommon.MutationResult<ForgotEmployeePasswordMutation>;
export type ForgotEmployeePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotEmployeePasswordMutation, ForgotEmployeePasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResetEmployeePasswordDocument = gql`
    mutation resetEmployeePassword($password: String!, $token: String!) {
  resetEmployeePassword(password: $password, token: $token)
}
    `;
export type ResetEmployeePasswordMutationFn = ApolloReactCommon.MutationFunction<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>;

/**
 * __useResetEmployeePasswordMutation__
 *
 * To run a mutation, you first call `useResetEmployeePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetEmployeePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetEmployeePasswordMutation, { data, loading, error }] = useResetEmployeePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetEmployeePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>(ResetEmployeePasswordDocument, baseOptions);
      }
export type ResetEmployeePasswordMutationHookResult = ReturnType<typeof useResetEmployeePasswordMutation>;
export type ResetEmployeePasswordMutationResult = ApolloReactCommon.MutationResult<ResetEmployeePasswordMutation>;
export type ResetEmployeePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetEmployeePasswordMutation, ResetEmployeePasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const EmployeeLogoutDocument = gql`
    mutation employeeLogout {
  employeeLogout
}
    `;
export type EmployeeLogoutMutationFn = ApolloReactCommon.MutationFunction<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>;

/**
 * __useEmployeeLogoutMutation__
 *
 * To run a mutation, you first call `useEmployeeLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeLogoutMutation, { data, loading, error }] = useEmployeeLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>(EmployeeLogoutDocument, baseOptions);
      }
export type EmployeeLogoutMutationHookResult = ReturnType<typeof useEmployeeLogoutMutation>;
export type EmployeeLogoutMutationResult = ApolloReactCommon.MutationResult<EmployeeLogoutMutation>;
export type EmployeeLogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeLogoutMutation, EmployeeLogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const UserProfilePicDocument = gql`
    query UserProfilePic($UserID: String!, $IsEmployee: Boolean) {
  UserProfilePic(UserID: $UserID, IsEmployee: $IsEmployee)
}
    `;

/**
 * __useUserProfilePicQuery__
 *
 * To run a query within a React component, call `useUserProfilePicQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfilePicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfilePicQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *      IsEmployee: // value for 'IsEmployee'
 *   },
 * });
 */
export function useUserProfilePicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserProfilePicQuery, UserProfilePicQueryVariables>) {
        return ApolloReactHooks.useQuery<UserProfilePicQuery, UserProfilePicQueryVariables>(UserProfilePicDocument, baseOptions);
      }
export function useUserProfilePicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProfilePicQuery, UserProfilePicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserProfilePicQuery, UserProfilePicQueryVariables>(UserProfilePicDocument, baseOptions);
        }
export type UserProfilePicQueryHookResult = ReturnType<typeof useUserProfilePicQuery>;
export type UserProfilePicLazyQueryHookResult = ReturnType<typeof useUserProfilePicLazyQuery>;
export type UserProfilePicQueryResult = ApolloReactCommon.QueryResult<UserProfilePicQuery, UserProfilePicQueryVariables>;
export const GetEmployeeKpiTempleteLatestVersionDocument = gql`
    query GetEmployeeKPITempleteLatestVersion($EmployeeID: String!, $Year: Float!, $KPIPlanningID: String) {
  GetEmployeeKPITempleteLatestVersion(EmployeeID: $EmployeeID, Year: $Year, KPIPlanningID: $KPIPlanningID) {
    EmployeeKPIHeaderID
    EmployeeID
    CompanyID
    Year
    Version
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    EmployeeKPICategory {
      EmployeeKPICategoryID
      AppraisalCategoryID
      Weightage
      EmployeeKPIDetail {
        EmployeeKPIDetailID
        AppraisalCategoryItemID
        Title
        Description
        Weightage
        EmployeeKPIDetailRating {
          EmployeeKPIDetailRatingID
          ScaleSequence
          Description
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeeKpiTempleteLatestVersionQuery__
 *
 * To run a query within a React component, call `useGetEmployeeKpiTempleteLatestVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeKpiTempleteLatestVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeKpiTempleteLatestVersionQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      Year: // value for 'Year'
 *      KPIPlanningID: // value for 'KPIPlanningID'
 *   },
 * });
 */
export function useGetEmployeeKpiTempleteLatestVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeKpiTempleteLatestVersionQuery, GetEmployeeKpiTempleteLatestVersionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeKpiTempleteLatestVersionQuery, GetEmployeeKpiTempleteLatestVersionQueryVariables>(GetEmployeeKpiTempleteLatestVersionDocument, baseOptions);
      }
export function useGetEmployeeKpiTempleteLatestVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeKpiTempleteLatestVersionQuery, GetEmployeeKpiTempleteLatestVersionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeKpiTempleteLatestVersionQuery, GetEmployeeKpiTempleteLatestVersionQueryVariables>(GetEmployeeKpiTempleteLatestVersionDocument, baseOptions);
        }
export type GetEmployeeKpiTempleteLatestVersionQueryHookResult = ReturnType<typeof useGetEmployeeKpiTempleteLatestVersionQuery>;
export type GetEmployeeKpiTempleteLatestVersionLazyQueryHookResult = ReturnType<typeof useGetEmployeeKpiTempleteLatestVersionLazyQuery>;
export type GetEmployeeKpiTempleteLatestVersionQueryResult = ApolloReactCommon.QueryResult<GetEmployeeKpiTempleteLatestVersionQuery, GetEmployeeKpiTempleteLatestVersionQueryVariables>;
export const GetAppraisalDefaultSettingByJobPositionDocument = gql`
    query GetAppraisalDefaultSettingByJobPosition($JobPositionID: String!, $CompanyID: String!) {
  GetAppraisalDefaultSettingByJobPosition(JobPositionID: $JobPositionID, CompanyID: $CompanyID) {
    AppraisalTempleteByJobPositionID
    JobPositionID
    SubscriptionAccountID
    KPIWeightage
    KPITemplete {
      AppraisalTempleteID
      Title
      Description
      TempleteType
      AppraisalTempleteCategory {
        AppraisalTempleteCategoryID
        AppraisalCategoryID
        AppraisalCategory {
          Name
          AppraisalType
        }
        Weightage
        AppraisalTempleteCategoryItems {
          AppraisalTempleteCategoryItemID
          AppraisalCategoryItemID
          AppraisalCategoryItem {
            Name
            Description
            AppraisalItemType
            QuantitativeType
          }
          Weightage
        }
      }
    }
    CompetencyTempleteID
    CompetencyWeightage
    CompetencyTemplete {
      AppraisalTempleteID
      Title
      Description
      TempleteType
      AppraisalTempleteCategory {
        AppraisalTempleteCategoryID
        AppraisalCategoryID
        AppraisalCategory {
          Name
          AppraisalType
        }
        Weightage
        AppraisalTempleteCategoryItems {
          AppraisalTempleteCategoryItemID
          AppraisalCategoryItemID
          AppraisalCategoryItem {
            Name
            Description
            AppraisalItemType
            QuantitativeType
          }
          Weightage
        }
      }
    }
    OthersWeightage
    OthersTempleteID
    OthersTemplete {
      AppraisalTempleteID
      Title
      Description
      TempleteType
      AppraisalTempleteCategory {
        AppraisalTempleteCategoryID
        AppraisalCategoryID
        AppraisalCategory {
          Name
          AppraisalType
        }
        Weightage
        AppraisalTempleteCategoryItems {
          AppraisalTempleteCategoryItemID
          AppraisalCategoryItemID
          AppraisalCategoryItem {
            Name
            Description
            AppraisalItemType
            QuantitativeType
          }
          Weightage
        }
      }
    }
    AppraisalSchedule {
      AppraisalScheduleHeaderID
      Title
      Frequency
      AppraisalScheduleDetails {
        Sequence
        Description
        StartDate
        EndDate
      }
    }
  }
}
    `;

/**
 * __useGetAppraisalDefaultSettingByJobPositionQuery__
 *
 * To run a query within a React component, call `useGetAppraisalDefaultSettingByJobPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppraisalDefaultSettingByJobPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppraisalDefaultSettingByJobPositionQuery({
 *   variables: {
 *      JobPositionID: // value for 'JobPositionID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetAppraisalDefaultSettingByJobPositionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAppraisalDefaultSettingByJobPositionQuery, GetAppraisalDefaultSettingByJobPositionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAppraisalDefaultSettingByJobPositionQuery, GetAppraisalDefaultSettingByJobPositionQueryVariables>(GetAppraisalDefaultSettingByJobPositionDocument, baseOptions);
      }
export function useGetAppraisalDefaultSettingByJobPositionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAppraisalDefaultSettingByJobPositionQuery, GetAppraisalDefaultSettingByJobPositionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAppraisalDefaultSettingByJobPositionQuery, GetAppraisalDefaultSettingByJobPositionQueryVariables>(GetAppraisalDefaultSettingByJobPositionDocument, baseOptions);
        }
export type GetAppraisalDefaultSettingByJobPositionQueryHookResult = ReturnType<typeof useGetAppraisalDefaultSettingByJobPositionQuery>;
export type GetAppraisalDefaultSettingByJobPositionLazyQueryHookResult = ReturnType<typeof useGetAppraisalDefaultSettingByJobPositionLazyQuery>;
export type GetAppraisalDefaultSettingByJobPositionQueryResult = ApolloReactCommon.QueryResult<GetAppraisalDefaultSettingByJobPositionQuery, GetAppraisalDefaultSettingByJobPositionQueryVariables>;
export const GetCompanyAppraisalPolicyDocument = gql`
    query GetCompanyAppraisalPolicy($CompanyID: String!) {
  GetCompanyAppraisalPolicy(CompanyID: $CompanyID) {
    AppraisalPolicy {
      AppraisalPolicyID
      SuperiorWeightage
      SelfAssessmentWeightage
      Title
      ScaleRatingHeader {
        ScaleRatingHeaderID
        Title
        Scale
        ScaleRatingDetails {
          ScaleRatingDetailID
          Description
          ScaleSequence
        }
      }
      FinalRatingHeader {
        FinalRatingHeaderID
        Title
        Scale
        FinalRatingDetails {
          FinalRatingDetailID
          Description
          ScaleSequence
          FinalRating
          From
          To
        }
      }
    }
  }
}
    `;

/**
 * __useGetCompanyAppraisalPolicyQuery__
 *
 * To run a query within a React component, call `useGetCompanyAppraisalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAppraisalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAppraisalPolicyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCompanyAppraisalPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyAppraisalPolicyQuery, GetCompanyAppraisalPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyAppraisalPolicyQuery, GetCompanyAppraisalPolicyQueryVariables>(GetCompanyAppraisalPolicyDocument, baseOptions);
      }
export function useGetCompanyAppraisalPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyAppraisalPolicyQuery, GetCompanyAppraisalPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyAppraisalPolicyQuery, GetCompanyAppraisalPolicyQueryVariables>(GetCompanyAppraisalPolicyDocument, baseOptions);
        }
export type GetCompanyAppraisalPolicyQueryHookResult = ReturnType<typeof useGetCompanyAppraisalPolicyQuery>;
export type GetCompanyAppraisalPolicyLazyQueryHookResult = ReturnType<typeof useGetCompanyAppraisalPolicyLazyQuery>;
export type GetCompanyAppraisalPolicyQueryResult = ApolloReactCommon.QueryResult<GetCompanyAppraisalPolicyQuery, GetCompanyAppraisalPolicyQueryVariables>;
export const AppraisalCategoryItemListingAllDocument = gql`
    query AppraisalCategoryItemListingAll {
  AppraisalCategoryItemListingAll {
    AppraisalCategoryItemID
    AppraisalCategoryID
    Name
    Description
    AppraisalItemType
    QuantitativeType
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useAppraisalCategoryItemListingAllQuery__
 *
 * To run a query within a React component, call `useAppraisalCategoryItemListingAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalCategoryItemListingAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalCategoryItemListingAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppraisalCategoryItemListingAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppraisalCategoryItemListingAllQuery, AppraisalCategoryItemListingAllQueryVariables>) {
        return ApolloReactHooks.useQuery<AppraisalCategoryItemListingAllQuery, AppraisalCategoryItemListingAllQueryVariables>(AppraisalCategoryItemListingAllDocument, baseOptions);
      }
export function useAppraisalCategoryItemListingAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppraisalCategoryItemListingAllQuery, AppraisalCategoryItemListingAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppraisalCategoryItemListingAllQuery, AppraisalCategoryItemListingAllQueryVariables>(AppraisalCategoryItemListingAllDocument, baseOptions);
        }
export type AppraisalCategoryItemListingAllQueryHookResult = ReturnType<typeof useAppraisalCategoryItemListingAllQuery>;
export type AppraisalCategoryItemListingAllLazyQueryHookResult = ReturnType<typeof useAppraisalCategoryItemListingAllLazyQuery>;
export type AppraisalCategoryItemListingAllQueryResult = ApolloReactCommon.QueryResult<AppraisalCategoryItemListingAllQuery, AppraisalCategoryItemListingAllQueryVariables>;
export const AppraisalPolicyListingDocument = gql`
    query AppraisalPolicyListing {
  AppraisalPolicyListing {
    Title
    AppraisalPolicyID
    SubscriptionAccountID
    ScaleRatingHeaderID
    SuperiorWeightage
    SelfAssessmentWeightage
    ModifiedDT
    isInUse
    Title
    ScaleRatingHeader {
      ScaleRatingHeaderID
      Title
      Scale
      ScaleRatingDetails {
        ScaleRatingDetailID
        Description
        ScaleSequence
      }
    }
    FinalRatingHeader {
      FinalRatingHeaderID
      Title
      Scale
      FinalRatingDetails {
        FinalRatingDetailID
        Description
        ScaleSequence
        FinalRating
        From
        To
      }
    }
  }
}
    `;

/**
 * __useAppraisalPolicyListingQuery__
 *
 * To run a query within a React component, call `useAppraisalPolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalPolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalPolicyListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppraisalPolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppraisalPolicyListingQuery, AppraisalPolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AppraisalPolicyListingQuery, AppraisalPolicyListingQueryVariables>(AppraisalPolicyListingDocument, baseOptions);
      }
export function useAppraisalPolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppraisalPolicyListingQuery, AppraisalPolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppraisalPolicyListingQuery, AppraisalPolicyListingQueryVariables>(AppraisalPolicyListingDocument, baseOptions);
        }
export type AppraisalPolicyListingQueryHookResult = ReturnType<typeof useAppraisalPolicyListingQuery>;
export type AppraisalPolicyListingLazyQueryHookResult = ReturnType<typeof useAppraisalPolicyListingLazyQuery>;
export type AppraisalPolicyListingQueryResult = ApolloReactCommon.QueryResult<AppraisalPolicyListingQuery, AppraisalPolicyListingQueryVariables>;
export const AppraisalCategoryListingAllDocument = gql`
    query AppraisalCategoryListingAll {
  AppraisalCategoryListingAll {
    AppraisalCategoryID
    Name
    AppraisalType
    AppraisalCategoryItems {
      AppraisalCategoryItemID
      AppraisalCategoryID
      Name
      Description
      AppraisalItemType
      QuantitativeType
      SubscriptionAccountID
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useAppraisalCategoryListingAllQuery__
 *
 * To run a query within a React component, call `useAppraisalCategoryListingAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalCategoryListingAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalCategoryListingAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppraisalCategoryListingAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppraisalCategoryListingAllQuery, AppraisalCategoryListingAllQueryVariables>) {
        return ApolloReactHooks.useQuery<AppraisalCategoryListingAllQuery, AppraisalCategoryListingAllQueryVariables>(AppraisalCategoryListingAllDocument, baseOptions);
      }
export function useAppraisalCategoryListingAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppraisalCategoryListingAllQuery, AppraisalCategoryListingAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppraisalCategoryListingAllQuery, AppraisalCategoryListingAllQueryVariables>(AppraisalCategoryListingAllDocument, baseOptions);
        }
export type AppraisalCategoryListingAllQueryHookResult = ReturnType<typeof useAppraisalCategoryListingAllQuery>;
export type AppraisalCategoryListingAllLazyQueryHookResult = ReturnType<typeof useAppraisalCategoryListingAllLazyQuery>;
export type AppraisalCategoryListingAllQueryResult = ApolloReactCommon.QueryResult<AppraisalCategoryListingAllQuery, AppraisalCategoryListingAllQueryVariables>;
export const GetEmployeeAppraisalHeaderDocument = gql`
    query GetEmployeeAppraisalHeader($EmployeeID: String!, $PeriodYear: String!) {
  GetEmployeeAppraisalHeader(EmployeeID: $EmployeeID, PeriodYear: $PeriodYear) {
    EmployeeAppraisalHeaderID
    AppraiseeType
    EmployeeID
    AppraiserID
    CompanyID
    Date
    Remark
    KPIPlanningID
    EmployeeAppraisalDetails {
      EmployeeAppraisalDetailID
      EmployeeKPIDetailID
      AppraisalCategoryItemID
      Rating
      Remark
      Document {
        DocumentID
        DocumentType
        Description
        DocumentFile
        BucketFileName
        FileSize
      }
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useGetEmployeeAppraisalHeaderQuery__
 *
 * To run a query within a React component, call `useGetEmployeeAppraisalHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeAppraisalHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeAppraisalHeaderQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      PeriodYear: // value for 'PeriodYear'
 *   },
 * });
 */
export function useGetEmployeeAppraisalHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeAppraisalHeaderQuery, GetEmployeeAppraisalHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeAppraisalHeaderQuery, GetEmployeeAppraisalHeaderQueryVariables>(GetEmployeeAppraisalHeaderDocument, baseOptions);
      }
export function useGetEmployeeAppraisalHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeAppraisalHeaderQuery, GetEmployeeAppraisalHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeAppraisalHeaderQuery, GetEmployeeAppraisalHeaderQueryVariables>(GetEmployeeAppraisalHeaderDocument, baseOptions);
        }
export type GetEmployeeAppraisalHeaderQueryHookResult = ReturnType<typeof useGetEmployeeAppraisalHeaderQuery>;
export type GetEmployeeAppraisalHeaderLazyQueryHookResult = ReturnType<typeof useGetEmployeeAppraisalHeaderLazyQuery>;
export type GetEmployeeAppraisalHeaderQueryResult = ApolloReactCommon.QueryResult<GetEmployeeAppraisalHeaderQuery, GetEmployeeAppraisalHeaderQueryVariables>;
export const SubmitAppraisalDocument = gql`
    mutation SubmitAppraisal($Input: EmployeeAppraisalHeaderInput!) {
  SubmitAppraisal(Input: $Input)
}
    `;
export type SubmitAppraisalMutationFn = ApolloReactCommon.MutationFunction<SubmitAppraisalMutation, SubmitAppraisalMutationVariables>;

/**
 * __useSubmitAppraisalMutation__
 *
 * To run a mutation, you first call `useSubmitAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAppraisalMutation, { data, loading, error }] = useSubmitAppraisalMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useSubmitAppraisalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitAppraisalMutation, SubmitAppraisalMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitAppraisalMutation, SubmitAppraisalMutationVariables>(SubmitAppraisalDocument, baseOptions);
      }
export type SubmitAppraisalMutationHookResult = ReturnType<typeof useSubmitAppraisalMutation>;
export type SubmitAppraisalMutationResult = ApolloReactCommon.MutationResult<SubmitAppraisalMutation>;
export type SubmitAppraisalMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitAppraisalMutation, SubmitAppraisalMutationVariables>;
export const EmployeeListingDocument = gql`
    query EmployeeListing($CompanyID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $offset: Float, $limit: Float, $ExcludeFormStatus: String) {
  EmployeeListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, offset: $offset, limit: $limit, ExcludeFormStatus: $ExcludeFormStatus) {
    EmployeeID
    SubscriptionUserID
    EmployeeNo
    EmploymentType
    JoinedReferenceDate
    JoinedDate
    ConfirmedDate
    Probation
    Status
    FormStatus
    EmployeeSalaryLogs {
      MovementType
      FromSalary
      ToSalary
      excelfilename
      EffectiveDate
      SalaryAdjustmentAmount
      Remark
    }
    OrgUnitID
    Supervisor {
      Contact {
        FullName
      }
      EmployeeID
      EmployeeNo
    }
    DesignationID
    Contact {
      FullName
    }
    Company {
      CompanyID
      CompanyName
    }
    Designation {
      JobDesignationID
      Name
    }
    Documents {
      DocumentFile
    }
    ResignedDate
    JobGradeID
    Department {
      Description
    }
    Division {
      Description
    }
    JobGrade {
      Description
    }
    OrgUnit {
      CompanyOrgUnitID
      Level
      Description
      ParentID
    }
    EmployeeSubordinates {
      EmployeeID
      EmployeeNo
      Contact {
        ContactID
        FullName
      }
    }
  }
}
    `;

/**
 * __useEmployeeListingQuery__
 *
 * To run a query within a React component, call `useEmployeeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *   },
 * });
 */
export function useEmployeeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListingQuery, EmployeeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListingQuery, EmployeeListingQueryVariables>(EmployeeListingDocument, baseOptions);
      }
export function useEmployeeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListingQuery, EmployeeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListingQuery, EmployeeListingQueryVariables>(EmployeeListingDocument, baseOptions);
        }
export type EmployeeListingQueryHookResult = ReturnType<typeof useEmployeeListingQuery>;
export type EmployeeListingLazyQueryHookResult = ReturnType<typeof useEmployeeListingLazyQuery>;
export type EmployeeListingQueryResult = ApolloReactCommon.QueryResult<EmployeeListingQuery, EmployeeListingQueryVariables>;
export const EmployeeListingCountDocument = gql`
    query EmployeeListingCount($CompanyID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $ExcludeFormStatus: String) {
  EmployeeListingCount(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, ExcludeFormStatus: $ExcludeFormStatus)
}
    `;

/**
 * __useEmployeeListingCountQuery__
 *
 * To run a query within a React component, call `useEmployeeListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListingCountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *   },
 * });
 */
export function useEmployeeListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListingCountQuery, EmployeeListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListingCountQuery, EmployeeListingCountQueryVariables>(EmployeeListingCountDocument, baseOptions);
      }
export function useEmployeeListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListingCountQuery, EmployeeListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListingCountQuery, EmployeeListingCountQueryVariables>(EmployeeListingCountDocument, baseOptions);
        }
export type EmployeeListingCountQueryHookResult = ReturnType<typeof useEmployeeListingCountQuery>;
export type EmployeeListingCountLazyQueryHookResult = ReturnType<typeof useEmployeeListingCountLazyQuery>;
export type EmployeeListingCountQueryResult = ApolloReactCommon.QueryResult<EmployeeListingCountQuery, EmployeeListingCountQueryVariables>;
export const GetAppraisalCompanySettingDocument = gql`
    query GetAppraisalCompanySetting($CompanyID: String!) {
  GetAppraisalCompanySetting(CompanyID: $CompanyID) {
    ID
    CompanyID
    AppraisalPolicyID
    AppraisalTempleteID
    AppraisalApprovalProcessID
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useGetAppraisalCompanySettingQuery__
 *
 * To run a query within a React component, call `useGetAppraisalCompanySettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppraisalCompanySettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppraisalCompanySettingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetAppraisalCompanySettingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAppraisalCompanySettingQuery, GetAppraisalCompanySettingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAppraisalCompanySettingQuery, GetAppraisalCompanySettingQueryVariables>(GetAppraisalCompanySettingDocument, baseOptions);
      }
export function useGetAppraisalCompanySettingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAppraisalCompanySettingQuery, GetAppraisalCompanySettingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAppraisalCompanySettingQuery, GetAppraisalCompanySettingQueryVariables>(GetAppraisalCompanySettingDocument, baseOptions);
        }
export type GetAppraisalCompanySettingQueryHookResult = ReturnType<typeof useGetAppraisalCompanySettingQuery>;
export type GetAppraisalCompanySettingLazyQueryHookResult = ReturnType<typeof useGetAppraisalCompanySettingLazyQuery>;
export type GetAppraisalCompanySettingQueryResult = ApolloReactCommon.QueryResult<GetAppraisalCompanySettingQuery, GetAppraisalCompanySettingQueryVariables>;
export const AppraisalCompanySettingSubmitDocument = gql`
    mutation AppraisalCompanySettingSubmit($input: AppraisalCompanySettingInput!) {
  AppraisalCompanySettingSubmit(input: $input) {
    ID
  }
}
    `;
export type AppraisalCompanySettingSubmitMutationFn = ApolloReactCommon.MutationFunction<AppraisalCompanySettingSubmitMutation, AppraisalCompanySettingSubmitMutationVariables>;

/**
 * __useAppraisalCompanySettingSubmitMutation__
 *
 * To run a mutation, you first call `useAppraisalCompanySettingSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppraisalCompanySettingSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appraisalCompanySettingSubmitMutation, { data, loading, error }] = useAppraisalCompanySettingSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppraisalCompanySettingSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppraisalCompanySettingSubmitMutation, AppraisalCompanySettingSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<AppraisalCompanySettingSubmitMutation, AppraisalCompanySettingSubmitMutationVariables>(AppraisalCompanySettingSubmitDocument, baseOptions);
      }
export type AppraisalCompanySettingSubmitMutationHookResult = ReturnType<typeof useAppraisalCompanySettingSubmitMutation>;
export type AppraisalCompanySettingSubmitMutationResult = ApolloReactCommon.MutationResult<AppraisalCompanySettingSubmitMutation>;
export type AppraisalCompanySettingSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<AppraisalCompanySettingSubmitMutation, AppraisalCompanySettingSubmitMutationVariables>;
export const GetCompanyAppraisalTempleteDocument = gql`
    query GetCompanyAppraisalTemplete($CompanyID: String!) {
  GetCompanyAppraisalTemplete(CompanyID: $CompanyID) {
    AppraisalTempleteByJobPositionID
    JobPositionID
    SubscriptionAccountID
    KPIWeightage
    KPITemplete {
      AppraisalTempleteID
      Title
      Description
      TempleteType
      AppraisalTempleteCategory {
        AppraisalTempleteCategoryID
        AppraisalCategoryID
        Weightage
        AppraisalTempleteCategoryItems {
          AppraisalTempleteCategoryItemID
          AppraisalCategoryItemID
          Weightage
        }
      }
    }
    CompetencyWeightage
    CompetencyTemplete {
      AppraisalTempleteID
      Title
      Description
      TempleteType
      AppraisalTempleteCategory {
        AppraisalTempleteCategoryID
        AppraisalCategoryID
        Weightage
        AppraisalTempleteCategoryItems {
          AppraisalTempleteCategoryItemID
          AppraisalCategoryItemID
          Weightage
        }
      }
    }
    OthersWeightage
    OthersTemplete {
      AppraisalTempleteID
      Title
      Description
      TempleteType
      AppraisalTempleteCategory {
        AppraisalTempleteCategoryID
        AppraisalCategoryID
        Weightage
        AppraisalTempleteCategoryItems {
          AppraisalTempleteCategoryItemID
          AppraisalCategoryItemID
          Weightage
        }
      }
    }
    AppraisalSchedule {
      AppraisalScheduleHeaderID
      Title
      Frequency
      AppraisalScheduleDetails {
        Sequence
        Description
        StartDate
        EndDate
      }
    }
  }
}
    `;

/**
 * __useGetCompanyAppraisalTempleteQuery__
 *
 * To run a query within a React component, call `useGetCompanyAppraisalTempleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAppraisalTempleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAppraisalTempleteQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCompanyAppraisalTempleteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyAppraisalTempleteQuery, GetCompanyAppraisalTempleteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyAppraisalTempleteQuery, GetCompanyAppraisalTempleteQueryVariables>(GetCompanyAppraisalTempleteDocument, baseOptions);
      }
export function useGetCompanyAppraisalTempleteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyAppraisalTempleteQuery, GetCompanyAppraisalTempleteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyAppraisalTempleteQuery, GetCompanyAppraisalTempleteQueryVariables>(GetCompanyAppraisalTempleteDocument, baseOptions);
        }
export type GetCompanyAppraisalTempleteQueryHookResult = ReturnType<typeof useGetCompanyAppraisalTempleteQuery>;
export type GetCompanyAppraisalTempleteLazyQueryHookResult = ReturnType<typeof useGetCompanyAppraisalTempleteLazyQuery>;
export type GetCompanyAppraisalTempleteQueryResult = ApolloReactCommon.QueryResult<GetCompanyAppraisalTempleteQuery, GetCompanyAppraisalTempleteQueryVariables>;
export const AppraisalPolicyInsertDocument = gql`
    mutation AppraisalPolicyInsert($input: AppraisalPolicyInput!) {
  AppraisalPolicyInsert(input: $input) {
    AppraisalPolicyID
  }
}
    `;
export type AppraisalPolicyInsertMutationFn = ApolloReactCommon.MutationFunction<AppraisalPolicyInsertMutation, AppraisalPolicyInsertMutationVariables>;

/**
 * __useAppraisalPolicyInsertMutation__
 *
 * To run a mutation, you first call `useAppraisalPolicyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppraisalPolicyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appraisalPolicyInsertMutation, { data, loading, error }] = useAppraisalPolicyInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppraisalPolicyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppraisalPolicyInsertMutation, AppraisalPolicyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<AppraisalPolicyInsertMutation, AppraisalPolicyInsertMutationVariables>(AppraisalPolicyInsertDocument, baseOptions);
      }
export type AppraisalPolicyInsertMutationHookResult = ReturnType<typeof useAppraisalPolicyInsertMutation>;
export type AppraisalPolicyInsertMutationResult = ApolloReactCommon.MutationResult<AppraisalPolicyInsertMutation>;
export type AppraisalPolicyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<AppraisalPolicyInsertMutation, AppraisalPolicyInsertMutationVariables>;
export const AppraisalPolicyDeleteDocument = gql`
    mutation AppraisalPolicyDelete($AppraisalPolicyID: String!) {
  AppraisalPolicyDelete(AppraisalPolicyID: $AppraisalPolicyID)
}
    `;
export type AppraisalPolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<AppraisalPolicyDeleteMutation, AppraisalPolicyDeleteMutationVariables>;

/**
 * __useAppraisalPolicyDeleteMutation__
 *
 * To run a mutation, you first call `useAppraisalPolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppraisalPolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appraisalPolicyDeleteMutation, { data, loading, error }] = useAppraisalPolicyDeleteMutation({
 *   variables: {
 *      AppraisalPolicyID: // value for 'AppraisalPolicyID'
 *   },
 * });
 */
export function useAppraisalPolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppraisalPolicyDeleteMutation, AppraisalPolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AppraisalPolicyDeleteMutation, AppraisalPolicyDeleteMutationVariables>(AppraisalPolicyDeleteDocument, baseOptions);
      }
export type AppraisalPolicyDeleteMutationHookResult = ReturnType<typeof useAppraisalPolicyDeleteMutation>;
export type AppraisalPolicyDeleteMutationResult = ApolloReactCommon.MutationResult<AppraisalPolicyDeleteMutation>;
export type AppraisalPolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AppraisalPolicyDeleteMutation, AppraisalPolicyDeleteMutationVariables>;
export const AppraisalTemplateByJobPositionListingDocument = gql`
    query AppraisalTemplateByJobPositionListing {
  AppraisalTemplateByJobPositionListing {
    AppraisalTemplateHeaderID
    isInUse
    Title
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    AppraisalTempleteByJobPosition {
      AppraisalTempleteByJobPositionID
      JobPositionID
      KPIWeightage
      KPITempleteID
      CompetencyWeightage
      CompetencyTempleteID
      OthersWeightage
      OthersTempleteID
      AppraisalScheduleHeaderID
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
  }
}
    `;

/**
 * __useAppraisalTemplateByJobPositionListingQuery__
 *
 * To run a query within a React component, call `useAppraisalTemplateByJobPositionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalTemplateByJobPositionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalTemplateByJobPositionListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppraisalTemplateByJobPositionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppraisalTemplateByJobPositionListingQuery, AppraisalTemplateByJobPositionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AppraisalTemplateByJobPositionListingQuery, AppraisalTemplateByJobPositionListingQueryVariables>(AppraisalTemplateByJobPositionListingDocument, baseOptions);
      }
export function useAppraisalTemplateByJobPositionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppraisalTemplateByJobPositionListingQuery, AppraisalTemplateByJobPositionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppraisalTemplateByJobPositionListingQuery, AppraisalTemplateByJobPositionListingQueryVariables>(AppraisalTemplateByJobPositionListingDocument, baseOptions);
        }
export type AppraisalTemplateByJobPositionListingQueryHookResult = ReturnType<typeof useAppraisalTemplateByJobPositionListingQuery>;
export type AppraisalTemplateByJobPositionListingLazyQueryHookResult = ReturnType<typeof useAppraisalTemplateByJobPositionListingLazyQuery>;
export type AppraisalTemplateByJobPositionListingQueryResult = ApolloReactCommon.QueryResult<AppraisalTemplateByJobPositionListingQuery, AppraisalTemplateByJobPositionListingQueryVariables>;
export const AppraisalTemplateByJobPositionSubmitDocument = gql`
    mutation AppraisalTemplateByJobPositionSubmit($input: AppraisalTempleteByJobPositionHeaderInput!) {
  AppraisalTemplateByJobPositionSubmit(input: $input)
}
    `;
export type AppraisalTemplateByJobPositionSubmitMutationFn = ApolloReactCommon.MutationFunction<AppraisalTemplateByJobPositionSubmitMutation, AppraisalTemplateByJobPositionSubmitMutationVariables>;

/**
 * __useAppraisalTemplateByJobPositionSubmitMutation__
 *
 * To run a mutation, you first call `useAppraisalTemplateByJobPositionSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppraisalTemplateByJobPositionSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appraisalTemplateByJobPositionSubmitMutation, { data, loading, error }] = useAppraisalTemplateByJobPositionSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppraisalTemplateByJobPositionSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppraisalTemplateByJobPositionSubmitMutation, AppraisalTemplateByJobPositionSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<AppraisalTemplateByJobPositionSubmitMutation, AppraisalTemplateByJobPositionSubmitMutationVariables>(AppraisalTemplateByJobPositionSubmitDocument, baseOptions);
      }
export type AppraisalTemplateByJobPositionSubmitMutationHookResult = ReturnType<typeof useAppraisalTemplateByJobPositionSubmitMutation>;
export type AppraisalTemplateByJobPositionSubmitMutationResult = ApolloReactCommon.MutationResult<AppraisalTemplateByJobPositionSubmitMutation>;
export type AppraisalTemplateByJobPositionSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<AppraisalTemplateByJobPositionSubmitMutation, AppraisalTemplateByJobPositionSubmitMutationVariables>;
export const AppraisalScheduleListingDocument = gql`
    query AppraisalScheduleListing {
  AppraisalScheduleListing {
    AppraisalScheduleHeaderID
    Title
    Frequency
    Status
    CreatedDT
    CreatedBy
    isInUse
    AppraisalScheduleDetails {
      AppraisalScheduleDetailID
      Sequence
      Description
      StartDate
      EndDate
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    AppraisalTempleteByJobPosition {
      AppraisalTempleteByJobPositionID
      JobPositionID
    }
  }
}
    `;

/**
 * __useAppraisalScheduleListingQuery__
 *
 * To run a query within a React component, call `useAppraisalScheduleListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalScheduleListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalScheduleListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppraisalScheduleListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppraisalScheduleListingQuery, AppraisalScheduleListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AppraisalScheduleListingQuery, AppraisalScheduleListingQueryVariables>(AppraisalScheduleListingDocument, baseOptions);
      }
export function useAppraisalScheduleListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppraisalScheduleListingQuery, AppraisalScheduleListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppraisalScheduleListingQuery, AppraisalScheduleListingQueryVariables>(AppraisalScheduleListingDocument, baseOptions);
        }
export type AppraisalScheduleListingQueryHookResult = ReturnType<typeof useAppraisalScheduleListingQuery>;
export type AppraisalScheduleListingLazyQueryHookResult = ReturnType<typeof useAppraisalScheduleListingLazyQuery>;
export type AppraisalScheduleListingQueryResult = ApolloReactCommon.QueryResult<AppraisalScheduleListingQuery, AppraisalScheduleListingQueryVariables>;
export const AppraisalScheduleSubmitDocument = gql`
    mutation AppraisalScheduleSubmit($input: AppraisalScheduleHeaderInput!) {
  AppraisalScheduleSubmit(input: $input) {
    AppraisalScheduleHeaderID
  }
}
    `;
export type AppraisalScheduleSubmitMutationFn = ApolloReactCommon.MutationFunction<AppraisalScheduleSubmitMutation, AppraisalScheduleSubmitMutationVariables>;

/**
 * __useAppraisalScheduleSubmitMutation__
 *
 * To run a mutation, you first call `useAppraisalScheduleSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppraisalScheduleSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appraisalScheduleSubmitMutation, { data, loading, error }] = useAppraisalScheduleSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppraisalScheduleSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppraisalScheduleSubmitMutation, AppraisalScheduleSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<AppraisalScheduleSubmitMutation, AppraisalScheduleSubmitMutationVariables>(AppraisalScheduleSubmitDocument, baseOptions);
      }
export type AppraisalScheduleSubmitMutationHookResult = ReturnType<typeof useAppraisalScheduleSubmitMutation>;
export type AppraisalScheduleSubmitMutationResult = ApolloReactCommon.MutationResult<AppraisalScheduleSubmitMutation>;
export type AppraisalScheduleSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<AppraisalScheduleSubmitMutation, AppraisalScheduleSubmitMutationVariables>;
export const ApprovalPolicyInsertDocument = gql`
    mutation ApprovalPolicyInsert($ApprovalPolicyInput: ApprovalPolicyInput!) {
  ApprovalPolicyInsert(ApprovalPolicyInput: $ApprovalPolicyInput)
}
    `;
export type ApprovalPolicyInsertMutationFn = ApolloReactCommon.MutationFunction<ApprovalPolicyInsertMutation, ApprovalPolicyInsertMutationVariables>;

/**
 * __useApprovalPolicyInsertMutation__
 *
 * To run a mutation, you first call `useApprovalPolicyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvalPolicyInsertMutation, { data, loading, error }] = useApprovalPolicyInsertMutation({
 *   variables: {
 *      ApprovalPolicyInput: // value for 'ApprovalPolicyInput'
 *   },
 * });
 */
export function useApprovalPolicyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApprovalPolicyInsertMutation, ApprovalPolicyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ApprovalPolicyInsertMutation, ApprovalPolicyInsertMutationVariables>(ApprovalPolicyInsertDocument, baseOptions);
      }
export type ApprovalPolicyInsertMutationHookResult = ReturnType<typeof useApprovalPolicyInsertMutation>;
export type ApprovalPolicyInsertMutationResult = ApolloReactCommon.MutationResult<ApprovalPolicyInsertMutation>;
export type ApprovalPolicyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ApprovalPolicyInsertMutation, ApprovalPolicyInsertMutationVariables>;
export const ApprovalPolicyUpdateDocument = gql`
    mutation ApprovalPolicyUpdate($ApprovalPolicyInput: ApprovalPolicyInput!) {
  ApprovalPolicyUpdate(ApprovalPolicyInput: $ApprovalPolicyInput)
}
    `;
export type ApprovalPolicyUpdateMutationFn = ApolloReactCommon.MutationFunction<ApprovalPolicyUpdateMutation, ApprovalPolicyUpdateMutationVariables>;

/**
 * __useApprovalPolicyUpdateMutation__
 *
 * To run a mutation, you first call `useApprovalPolicyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvalPolicyUpdateMutation, { data, loading, error }] = useApprovalPolicyUpdateMutation({
 *   variables: {
 *      ApprovalPolicyInput: // value for 'ApprovalPolicyInput'
 *   },
 * });
 */
export function useApprovalPolicyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApprovalPolicyUpdateMutation, ApprovalPolicyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ApprovalPolicyUpdateMutation, ApprovalPolicyUpdateMutationVariables>(ApprovalPolicyUpdateDocument, baseOptions);
      }
export type ApprovalPolicyUpdateMutationHookResult = ReturnType<typeof useApprovalPolicyUpdateMutation>;
export type ApprovalPolicyUpdateMutationResult = ApolloReactCommon.MutationResult<ApprovalPolicyUpdateMutation>;
export type ApprovalPolicyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ApprovalPolicyUpdateMutation, ApprovalPolicyUpdateMutationVariables>;
export const ApprovalPolicyDeleteDocument = gql`
    mutation ApprovalPolicyDelete($SubscriptionAccountID: String!, $ID: String!) {
  ApprovalPolicyDelete(SubscriptionAccountID: $SubscriptionAccountID, ID: $ID)
}
    `;
export type ApprovalPolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<ApprovalPolicyDeleteMutation, ApprovalPolicyDeleteMutationVariables>;

/**
 * __useApprovalPolicyDeleteMutation__
 *
 * To run a mutation, you first call `useApprovalPolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvalPolicyDeleteMutation, { data, loading, error }] = useApprovalPolicyDeleteMutation({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useApprovalPolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApprovalPolicyDeleteMutation, ApprovalPolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ApprovalPolicyDeleteMutation, ApprovalPolicyDeleteMutationVariables>(ApprovalPolicyDeleteDocument, baseOptions);
      }
export type ApprovalPolicyDeleteMutationHookResult = ReturnType<typeof useApprovalPolicyDeleteMutation>;
export type ApprovalPolicyDeleteMutationResult = ApolloReactCommon.MutationResult<ApprovalPolicyDeleteMutation>;
export type ApprovalPolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ApprovalPolicyDeleteMutation, ApprovalPolicyDeleteMutationVariables>;
export const ApprovalPolicyListDocument = gql`
    query ApprovalPolicyList($SubscriptionAccountID: String!, $Category: String!, $ID: String) {
  ApprovalPolicyList(SubscriptionAccountID: $SubscriptionAccountID, Category: $Category, ID: $ID) {
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ID
    SubscriptionAccountID
    Title
    Category
    ApprovalList {
      ID
      StepNo
      RoleID
      Predicate
      Amount
      Category
      RoleName
      EndIf
      Skip
    }
  }
}
    `;

/**
 * __useApprovalPolicyListQuery__
 *
 * To run a query within a React component, call `useApprovalPolicyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalPolicyListQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      Category: // value for 'Category'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useApprovalPolicyListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovalPolicyListQuery, ApprovalPolicyListQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovalPolicyListQuery, ApprovalPolicyListQueryVariables>(ApprovalPolicyListDocument, baseOptions);
      }
export function useApprovalPolicyListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovalPolicyListQuery, ApprovalPolicyListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovalPolicyListQuery, ApprovalPolicyListQueryVariables>(ApprovalPolicyListDocument, baseOptions);
        }
export type ApprovalPolicyListQueryHookResult = ReturnType<typeof useApprovalPolicyListQuery>;
export type ApprovalPolicyListLazyQueryHookResult = ReturnType<typeof useApprovalPolicyListLazyQuery>;
export type ApprovalPolicyListQueryResult = ApolloReactCommon.QueryResult<ApprovalPolicyListQuery, ApprovalPolicyListQueryVariables>;
export const ApprovalPolicyAssignmentUpdateDocument = gql`
    mutation ApprovalPolicyAssignmentUpdate($ApprovalPolicyInput: ApprovalPolicyAssignmentInput!) {
  ApprovalPolicyAssignmentUpdate(ApprovalPolicyInput: $ApprovalPolicyInput)
}
    `;
export type ApprovalPolicyAssignmentUpdateMutationFn = ApolloReactCommon.MutationFunction<ApprovalPolicyAssignmentUpdateMutation, ApprovalPolicyAssignmentUpdateMutationVariables>;

/**
 * __useApprovalPolicyAssignmentUpdateMutation__
 *
 * To run a mutation, you first call `useApprovalPolicyAssignmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyAssignmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approvalPolicyAssignmentUpdateMutation, { data, loading, error }] = useApprovalPolicyAssignmentUpdateMutation({
 *   variables: {
 *      ApprovalPolicyInput: // value for 'ApprovalPolicyInput'
 *   },
 * });
 */
export function useApprovalPolicyAssignmentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApprovalPolicyAssignmentUpdateMutation, ApprovalPolicyAssignmentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ApprovalPolicyAssignmentUpdateMutation, ApprovalPolicyAssignmentUpdateMutationVariables>(ApprovalPolicyAssignmentUpdateDocument, baseOptions);
      }
export type ApprovalPolicyAssignmentUpdateMutationHookResult = ReturnType<typeof useApprovalPolicyAssignmentUpdateMutation>;
export type ApprovalPolicyAssignmentUpdateMutationResult = ApolloReactCommon.MutationResult<ApprovalPolicyAssignmentUpdateMutation>;
export type ApprovalPolicyAssignmentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ApprovalPolicyAssignmentUpdateMutation, ApprovalPolicyAssignmentUpdateMutationVariables>;
export const AppraisalCategoryInsertDocument = gql`
    mutation AppraisalCategoryInsert($input: AppraisalCategoryInput!) {
  AppraisalCategoryInsert(input: $input) {
    AppraisalCategoryID
  }
}
    `;
export type AppraisalCategoryInsertMutationFn = ApolloReactCommon.MutationFunction<AppraisalCategoryInsertMutation, AppraisalCategoryInsertMutationVariables>;

/**
 * __useAppraisalCategoryInsertMutation__
 *
 * To run a mutation, you first call `useAppraisalCategoryInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppraisalCategoryInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appraisalCategoryInsertMutation, { data, loading, error }] = useAppraisalCategoryInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppraisalCategoryInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppraisalCategoryInsertMutation, AppraisalCategoryInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<AppraisalCategoryInsertMutation, AppraisalCategoryInsertMutationVariables>(AppraisalCategoryInsertDocument, baseOptions);
      }
export type AppraisalCategoryInsertMutationHookResult = ReturnType<typeof useAppraisalCategoryInsertMutation>;
export type AppraisalCategoryInsertMutationResult = ApolloReactCommon.MutationResult<AppraisalCategoryInsertMutation>;
export type AppraisalCategoryInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<AppraisalCategoryInsertMutation, AppraisalCategoryInsertMutationVariables>;
export const AppraisalCategoryUpdateDocument = gql`
    mutation AppraisalCategoryUpdate($input: AppraisalCategoryInput!, $AppraisalCategoryID: String!) {
  AppraisalCategoryUpdate(input: $input, AppraisalCategoryID: $AppraisalCategoryID)
}
    `;
export type AppraisalCategoryUpdateMutationFn = ApolloReactCommon.MutationFunction<AppraisalCategoryUpdateMutation, AppraisalCategoryUpdateMutationVariables>;

/**
 * __useAppraisalCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useAppraisalCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppraisalCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appraisalCategoryUpdateMutation, { data, loading, error }] = useAppraisalCategoryUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      AppraisalCategoryID: // value for 'AppraisalCategoryID'
 *   },
 * });
 */
export function useAppraisalCategoryUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppraisalCategoryUpdateMutation, AppraisalCategoryUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<AppraisalCategoryUpdateMutation, AppraisalCategoryUpdateMutationVariables>(AppraisalCategoryUpdateDocument, baseOptions);
      }
export type AppraisalCategoryUpdateMutationHookResult = ReturnType<typeof useAppraisalCategoryUpdateMutation>;
export type AppraisalCategoryUpdateMutationResult = ApolloReactCommon.MutationResult<AppraisalCategoryUpdateMutation>;
export type AppraisalCategoryUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<AppraisalCategoryUpdateMutation, AppraisalCategoryUpdateMutationVariables>;
export const AppraisalCategoryDeleteDocument = gql`
    mutation AppraisalCategoryDelete($AppraisalCategoryID: String!) {
  AppraisalCategoryDelete(AppraisalCategoryID: $AppraisalCategoryID)
}
    `;
export type AppraisalCategoryDeleteMutationFn = ApolloReactCommon.MutationFunction<AppraisalCategoryDeleteMutation, AppraisalCategoryDeleteMutationVariables>;

/**
 * __useAppraisalCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useAppraisalCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppraisalCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appraisalCategoryDeleteMutation, { data, loading, error }] = useAppraisalCategoryDeleteMutation({
 *   variables: {
 *      AppraisalCategoryID: // value for 'AppraisalCategoryID'
 *   },
 * });
 */
export function useAppraisalCategoryDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppraisalCategoryDeleteMutation, AppraisalCategoryDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AppraisalCategoryDeleteMutation, AppraisalCategoryDeleteMutationVariables>(AppraisalCategoryDeleteDocument, baseOptions);
      }
export type AppraisalCategoryDeleteMutationHookResult = ReturnType<typeof useAppraisalCategoryDeleteMutation>;
export type AppraisalCategoryDeleteMutationResult = ApolloReactCommon.MutationResult<AppraisalCategoryDeleteMutation>;
export type AppraisalCategoryDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AppraisalCategoryDeleteMutation, AppraisalCategoryDeleteMutationVariables>;
export const AppraisalCategoryItemInsertDocument = gql`
    mutation AppraisalCategoryItemInsert($input: AppraisalCategoryItemInput!) {
  AppraisalCategoryItemInsert(input: $input) {
    AppraisalCategoryItemID
  }
}
    `;
export type AppraisalCategoryItemInsertMutationFn = ApolloReactCommon.MutationFunction<AppraisalCategoryItemInsertMutation, AppraisalCategoryItemInsertMutationVariables>;

/**
 * __useAppraisalCategoryItemInsertMutation__
 *
 * To run a mutation, you first call `useAppraisalCategoryItemInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppraisalCategoryItemInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appraisalCategoryItemInsertMutation, { data, loading, error }] = useAppraisalCategoryItemInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppraisalCategoryItemInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppraisalCategoryItemInsertMutation, AppraisalCategoryItemInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<AppraisalCategoryItemInsertMutation, AppraisalCategoryItemInsertMutationVariables>(AppraisalCategoryItemInsertDocument, baseOptions);
      }
export type AppraisalCategoryItemInsertMutationHookResult = ReturnType<typeof useAppraisalCategoryItemInsertMutation>;
export type AppraisalCategoryItemInsertMutationResult = ApolloReactCommon.MutationResult<AppraisalCategoryItemInsertMutation>;
export type AppraisalCategoryItemInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<AppraisalCategoryItemInsertMutation, AppraisalCategoryItemInsertMutationVariables>;
export const AppraisalCategoryItemUpdateDocument = gql`
    mutation AppraisalCategoryItemUpdate($input: AppraisalCategoryItemInput!, $AppraisalCategoryItemID: String!) {
  AppraisalCategoryItemUpdate(input: $input, AppraisalCategoryItemID: $AppraisalCategoryItemID)
}
    `;
export type AppraisalCategoryItemUpdateMutationFn = ApolloReactCommon.MutationFunction<AppraisalCategoryItemUpdateMutation, AppraisalCategoryItemUpdateMutationVariables>;

/**
 * __useAppraisalCategoryItemUpdateMutation__
 *
 * To run a mutation, you first call `useAppraisalCategoryItemUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppraisalCategoryItemUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appraisalCategoryItemUpdateMutation, { data, loading, error }] = useAppraisalCategoryItemUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      AppraisalCategoryItemID: // value for 'AppraisalCategoryItemID'
 *   },
 * });
 */
export function useAppraisalCategoryItemUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppraisalCategoryItemUpdateMutation, AppraisalCategoryItemUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<AppraisalCategoryItemUpdateMutation, AppraisalCategoryItemUpdateMutationVariables>(AppraisalCategoryItemUpdateDocument, baseOptions);
      }
export type AppraisalCategoryItemUpdateMutationHookResult = ReturnType<typeof useAppraisalCategoryItemUpdateMutation>;
export type AppraisalCategoryItemUpdateMutationResult = ApolloReactCommon.MutationResult<AppraisalCategoryItemUpdateMutation>;
export type AppraisalCategoryItemUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<AppraisalCategoryItemUpdateMutation, AppraisalCategoryItemUpdateMutationVariables>;
export const AppraisalCategoryItemDeleteDocument = gql`
    mutation AppraisalCategoryItemDelete($AppraisalCategoryItemID: String!) {
  AppraisalCategoryItemDelete(AppraisalCategoryItemID: $AppraisalCategoryItemID)
}
    `;
export type AppraisalCategoryItemDeleteMutationFn = ApolloReactCommon.MutationFunction<AppraisalCategoryItemDeleteMutation, AppraisalCategoryItemDeleteMutationVariables>;

/**
 * __useAppraisalCategoryItemDeleteMutation__
 *
 * To run a mutation, you first call `useAppraisalCategoryItemDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppraisalCategoryItemDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appraisalCategoryItemDeleteMutation, { data, loading, error }] = useAppraisalCategoryItemDeleteMutation({
 *   variables: {
 *      AppraisalCategoryItemID: // value for 'AppraisalCategoryItemID'
 *   },
 * });
 */
export function useAppraisalCategoryItemDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AppraisalCategoryItemDeleteMutation, AppraisalCategoryItemDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AppraisalCategoryItemDeleteMutation, AppraisalCategoryItemDeleteMutationVariables>(AppraisalCategoryItemDeleteDocument, baseOptions);
      }
export type AppraisalCategoryItemDeleteMutationHookResult = ReturnType<typeof useAppraisalCategoryItemDeleteMutation>;
export type AppraisalCategoryItemDeleteMutationResult = ApolloReactCommon.MutationResult<AppraisalCategoryItemDeleteMutation>;
export type AppraisalCategoryItemDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AppraisalCategoryItemDeleteMutation, AppraisalCategoryItemDeleteMutationVariables>;
export const AppraisalTempleteListingDocument = gql`
    query AppraisalTempleteListing($AppraisalType: String!) {
  AppraisalTempleteListing(TempleteType: $AppraisalType) {
    AppraisalTempleteID
    Title
    Description
    TempleteType
    SubscriptionAccountID
    Status
    AppraisalTempleteCategory {
      AppraisalTempleteCategoryID
      AppraisalTempleteID
      AppraisalCategoryID
      Status
      AppraisalCategory {
        Name
      }
      Weightage
      SubscriptionAccountID
      AppraisalTempleteCategoryItems {
        AppraisalTempleteCategoryItemID
        AppraisalCategoryItemID
        AppraisalCategoryItem {
          Name
          Description
          AppraisalItemType
          QuantitativeType
        }
        Status
        Weightage
        SubscriptionAccountID
      }
    }
  }
}
    `;

/**
 * __useAppraisalTempleteListingQuery__
 *
 * To run a query within a React component, call `useAppraisalTempleteListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalTempleteListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalTempleteListingQuery({
 *   variables: {
 *      AppraisalType: // value for 'AppraisalType'
 *   },
 * });
 */
export function useAppraisalTempleteListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppraisalTempleteListingQuery, AppraisalTempleteListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AppraisalTempleteListingQuery, AppraisalTempleteListingQueryVariables>(AppraisalTempleteListingDocument, baseOptions);
      }
export function useAppraisalTempleteListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppraisalTempleteListingQuery, AppraisalTempleteListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppraisalTempleteListingQuery, AppraisalTempleteListingQueryVariables>(AppraisalTempleteListingDocument, baseOptions);
        }
export type AppraisalTempleteListingQueryHookResult = ReturnType<typeof useAppraisalTempleteListingQuery>;
export type AppraisalTempleteListingLazyQueryHookResult = ReturnType<typeof useAppraisalTempleteListingLazyQuery>;
export type AppraisalTempleteListingQueryResult = ApolloReactCommon.QueryResult<AppraisalTempleteListingQuery, AppraisalTempleteListingQueryVariables>;
export const SubmitAppraisalTempleteDocument = gql`
    mutation SubmitAppraisalTemplete($input: AppraisalTempleteInput!) {
  SubmitAppraisalTemplete(input: $input)
}
    `;
export type SubmitAppraisalTempleteMutationFn = ApolloReactCommon.MutationFunction<SubmitAppraisalTempleteMutation, SubmitAppraisalTempleteMutationVariables>;

/**
 * __useSubmitAppraisalTempleteMutation__
 *
 * To run a mutation, you first call `useSubmitAppraisalTempleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAppraisalTempleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAppraisalTempleteMutation, { data, loading, error }] = useSubmitAppraisalTempleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitAppraisalTempleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitAppraisalTempleteMutation, SubmitAppraisalTempleteMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitAppraisalTempleteMutation, SubmitAppraisalTempleteMutationVariables>(SubmitAppraisalTempleteDocument, baseOptions);
      }
export type SubmitAppraisalTempleteMutationHookResult = ReturnType<typeof useSubmitAppraisalTempleteMutation>;
export type SubmitAppraisalTempleteMutationResult = ApolloReactCommon.MutationResult<SubmitAppraisalTempleteMutation>;
export type SubmitAppraisalTempleteMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitAppraisalTempleteMutation, SubmitAppraisalTempleteMutationVariables>;
export const TrainingAttendanceListingDocument = gql`
    query TrainingAttendanceListing($CompanyID: String!, $ScheduledTrainingID: String!) {
  TrainingAttendanceListing(CompanyID: $CompanyID, ScheduledTrainingID: $ScheduledTrainingID) {
    ScheduledTrainingID
    SubscriptionAccountID
    CompanyID
    EmployeeID
    CheckInDate
    Employee {
      OrgUnitID
      JoinedDate
      EmployeeID
      EmployeeNo
      DepartmentID
      DivisionID
      JobGradeID
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
      Company {
        CompanyID
        CompanyName
      }
      EmployeeStatutory {
        EmployeeStatutoryID
      }
      EmployeePrevContribution {
        EmployeePrevContributionID
      }
      EmployeeDependents {
        EmployeeDependentsID
      }
      Department {
        DepartmentID
        DepartmentType
        ParentID
        Description
      }
      Designation {
        JobDesignationID
        Name
      }
      DocumentID
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
      TotalTaxRelief
      FormStatus
      EmploymentType
      CreatedDT
      ModifiedDT
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useTrainingAttendanceListingQuery__
 *
 * To run a query within a React component, call `useTrainingAttendanceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingAttendanceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingAttendanceListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ScheduledTrainingID: // value for 'ScheduledTrainingID'
 *   },
 * });
 */
export function useTrainingAttendanceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingAttendanceListingQuery, TrainingAttendanceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingAttendanceListingQuery, TrainingAttendanceListingQueryVariables>(TrainingAttendanceListingDocument, baseOptions);
      }
export function useTrainingAttendanceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingAttendanceListingQuery, TrainingAttendanceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingAttendanceListingQuery, TrainingAttendanceListingQueryVariables>(TrainingAttendanceListingDocument, baseOptions);
        }
export type TrainingAttendanceListingQueryHookResult = ReturnType<typeof useTrainingAttendanceListingQuery>;
export type TrainingAttendanceListingLazyQueryHookResult = ReturnType<typeof useTrainingAttendanceListingLazyQuery>;
export type TrainingAttendanceListingQueryResult = ApolloReactCommon.QueryResult<TrainingAttendanceListingQuery, TrainingAttendanceListingQueryVariables>;
export const GetEmployeeTrainingRecordDocument = gql`
    query GetEmployeeTrainingRecord($CompanyID: String!) {
  GetEmployeeTrainingRecord(CompanyID: $CompanyID) {
    EmployeeID
    FinalTotalAttendanceHour
    CourseRecord {
      ScheduledTrainingID
      Name
      TotalAttendaceHour
    }
  }
}
    `;

/**
 * __useGetEmployeeTrainingRecordQuery__
 *
 * To run a query within a React component, call `useGetEmployeeTrainingRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeTrainingRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeTrainingRecordQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetEmployeeTrainingRecordQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeTrainingRecordQuery, GetEmployeeTrainingRecordQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeTrainingRecordQuery, GetEmployeeTrainingRecordQueryVariables>(GetEmployeeTrainingRecordDocument, baseOptions);
      }
export function useGetEmployeeTrainingRecordLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeTrainingRecordQuery, GetEmployeeTrainingRecordQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeTrainingRecordQuery, GetEmployeeTrainingRecordQueryVariables>(GetEmployeeTrainingRecordDocument, baseOptions);
        }
export type GetEmployeeTrainingRecordQueryHookResult = ReturnType<typeof useGetEmployeeTrainingRecordQuery>;
export type GetEmployeeTrainingRecordLazyQueryHookResult = ReturnType<typeof useGetEmployeeTrainingRecordLazyQuery>;
export type GetEmployeeTrainingRecordQueryResult = ApolloReactCommon.QueryResult<GetEmployeeTrainingRecordQuery, GetEmployeeTrainingRecordQueryVariables>;
export const GetUserRolePermissionByRoleDocument = gql`
    query GetUserRolePermissionByRole($CompanyID: String!) {
  GetUserRolePermissionByRole(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetUserRolePermissionByRoleQuery__
 *
 * To run a query within a React component, call `useGetUserRolePermissionByRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolePermissionByRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolePermissionByRoleQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetUserRolePermissionByRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserRolePermissionByRoleQuery, GetUserRolePermissionByRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserRolePermissionByRoleQuery, GetUserRolePermissionByRoleQueryVariables>(GetUserRolePermissionByRoleDocument, baseOptions);
      }
export function useGetUserRolePermissionByRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserRolePermissionByRoleQuery, GetUserRolePermissionByRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserRolePermissionByRoleQuery, GetUserRolePermissionByRoleQueryVariables>(GetUserRolePermissionByRoleDocument, baseOptions);
        }
export type GetUserRolePermissionByRoleQueryHookResult = ReturnType<typeof useGetUserRolePermissionByRoleQuery>;
export type GetUserRolePermissionByRoleLazyQueryHookResult = ReturnType<typeof useGetUserRolePermissionByRoleLazyQuery>;
export type GetUserRolePermissionByRoleQueryResult = ApolloReactCommon.QueryResult<GetUserRolePermissionByRoleQuery, GetUserRolePermissionByRoleQueryVariables>;
export const SubmitPairedAttandanceRecordDocument = gql`
    mutation submitPairedAttandanceRecord($input: [AttendanceRecordPairedObj!]!, $DataToDelete: [String!]!) {
  submitPairedAttandanceRecord(input: $input, DataToDelete: $DataToDelete)
}
    `;
export type SubmitPairedAttandanceRecordMutationFn = ApolloReactCommon.MutationFunction<SubmitPairedAttandanceRecordMutation, SubmitPairedAttandanceRecordMutationVariables>;

/**
 * __useSubmitPairedAttandanceRecordMutation__
 *
 * To run a mutation, you first call `useSubmitPairedAttandanceRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPairedAttandanceRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPairedAttandanceRecordMutation, { data, loading, error }] = useSubmitPairedAttandanceRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *      DataToDelete: // value for 'DataToDelete'
 *   },
 * });
 */
export function useSubmitPairedAttandanceRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitPairedAttandanceRecordMutation, SubmitPairedAttandanceRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitPairedAttandanceRecordMutation, SubmitPairedAttandanceRecordMutationVariables>(SubmitPairedAttandanceRecordDocument, baseOptions);
      }
export type SubmitPairedAttandanceRecordMutationHookResult = ReturnType<typeof useSubmitPairedAttandanceRecordMutation>;
export type SubmitPairedAttandanceRecordMutationResult = ApolloReactCommon.MutationResult<SubmitPairedAttandanceRecordMutation>;
export type SubmitPairedAttandanceRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitPairedAttandanceRecordMutation, SubmitPairedAttandanceRecordMutationVariables>;
export const GetAttendanceRecordPairedDataDocument = gql`
    query GetAttendanceRecordPairedData($startDate: String!, $endDate: String!, $CompanyID: String!, $EmployeeIDs: [String!]) {
  GetAttendanceRecordPairedData(startDate: $startDate, endDate: $endDate, CompanyID: $CompanyID, EmployeeIDs: $EmployeeIDs)
}
    `;

/**
 * __useGetAttendanceRecordPairedDataQuery__
 *
 * To run a query within a React component, call `useGetAttendanceRecordPairedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceRecordPairedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceRecordPairedDataQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *   },
 * });
 */
export function useGetAttendanceRecordPairedDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendanceRecordPairedDataQuery, GetAttendanceRecordPairedDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendanceRecordPairedDataQuery, GetAttendanceRecordPairedDataQueryVariables>(GetAttendanceRecordPairedDataDocument, baseOptions);
      }
export function useGetAttendanceRecordPairedDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendanceRecordPairedDataQuery, GetAttendanceRecordPairedDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendanceRecordPairedDataQuery, GetAttendanceRecordPairedDataQueryVariables>(GetAttendanceRecordPairedDataDocument, baseOptions);
        }
export type GetAttendanceRecordPairedDataQueryHookResult = ReturnType<typeof useGetAttendanceRecordPairedDataQuery>;
export type GetAttendanceRecordPairedDataLazyQueryHookResult = ReturnType<typeof useGetAttendanceRecordPairedDataLazyQuery>;
export type GetAttendanceRecordPairedDataQueryResult = ApolloReactCommon.QueryResult<GetAttendanceRecordPairedDataQuery, GetAttendanceRecordPairedDataQueryVariables>;
export const GetFinalizeAnalysisDataByEmployeeDocument = gql`
    query GetFinalizeAnalysisDataByEmployee($startDate: String!, $endDate: String!, $EmployeeID: String!) {
  GetFinalizeAnalysisDataByEmployee(startDate: $startDate, endDate: $endDate, EmployeeID: $EmployeeID)
}
    `;

/**
 * __useGetFinalizeAnalysisDataByEmployeeQuery__
 *
 * To run a query within a React component, call `useGetFinalizeAnalysisDataByEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinalizeAnalysisDataByEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinalizeAnalysisDataByEmployeeQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetFinalizeAnalysisDataByEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFinalizeAnalysisDataByEmployeeQuery, GetFinalizeAnalysisDataByEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFinalizeAnalysisDataByEmployeeQuery, GetFinalizeAnalysisDataByEmployeeQueryVariables>(GetFinalizeAnalysisDataByEmployeeDocument, baseOptions);
      }
export function useGetFinalizeAnalysisDataByEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFinalizeAnalysisDataByEmployeeQuery, GetFinalizeAnalysisDataByEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFinalizeAnalysisDataByEmployeeQuery, GetFinalizeAnalysisDataByEmployeeQueryVariables>(GetFinalizeAnalysisDataByEmployeeDocument, baseOptions);
        }
export type GetFinalizeAnalysisDataByEmployeeQueryHookResult = ReturnType<typeof useGetFinalizeAnalysisDataByEmployeeQuery>;
export type GetFinalizeAnalysisDataByEmployeeLazyQueryHookResult = ReturnType<typeof useGetFinalizeAnalysisDataByEmployeeLazyQuery>;
export type GetFinalizeAnalysisDataByEmployeeQueryResult = ApolloReactCommon.QueryResult<GetFinalizeAnalysisDataByEmployeeQuery, GetFinalizeAnalysisDataByEmployeeQueryVariables>;
export const GetFinalizeAnalysisDataDocument = gql`
    query GetFinalizeAnalysisData($startDate: String!, $endDate: String!, $CompanyID: String!, $EmployeeIDs: [String!]) {
  GetFinalizeAnalysisData(startDate: $startDate, endDate: $endDate, CompanyID: $CompanyID, EmployeeIDs: $EmployeeIDs)
}
    `;

/**
 * __useGetFinalizeAnalysisDataQuery__
 *
 * To run a query within a React component, call `useGetFinalizeAnalysisDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinalizeAnalysisDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinalizeAnalysisDataQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *   },
 * });
 */
export function useGetFinalizeAnalysisDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFinalizeAnalysisDataQuery, GetFinalizeAnalysisDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFinalizeAnalysisDataQuery, GetFinalizeAnalysisDataQueryVariables>(GetFinalizeAnalysisDataDocument, baseOptions);
      }
export function useGetFinalizeAnalysisDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFinalizeAnalysisDataQuery, GetFinalizeAnalysisDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFinalizeAnalysisDataQuery, GetFinalizeAnalysisDataQueryVariables>(GetFinalizeAnalysisDataDocument, baseOptions);
        }
export type GetFinalizeAnalysisDataQueryHookResult = ReturnType<typeof useGetFinalizeAnalysisDataQuery>;
export type GetFinalizeAnalysisDataLazyQueryHookResult = ReturnType<typeof useGetFinalizeAnalysisDataLazyQuery>;
export type GetFinalizeAnalysisDataQueryResult = ApolloReactCommon.QueryResult<GetFinalizeAnalysisDataQuery, GetFinalizeAnalysisDataQueryVariables>;
export const GetClockPairingDocument = gql`
    query GetClockPairing($Overwrite: Boolean!, $StartDate: String!, $EndDate: String!, $EmployeeIDs: [String!], $CompanyIDs: [String!]) {
  GetClockPairing(Overwrite: $Overwrite, StartDate: $StartDate, EndDate: $EndDate, EmployeeIDs: $EmployeeIDs, CompanyIDs: $CompanyIDs)
}
    `;

/**
 * __useGetClockPairingQuery__
 *
 * To run a query within a React component, call `useGetClockPairingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClockPairingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClockPairingQuery({
 *   variables: {
 *      Overwrite: // value for 'Overwrite'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      CompanyIDs: // value for 'CompanyIDs'
 *   },
 * });
 */
export function useGetClockPairingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClockPairingQuery, GetClockPairingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClockPairingQuery, GetClockPairingQueryVariables>(GetClockPairingDocument, baseOptions);
      }
export function useGetClockPairingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClockPairingQuery, GetClockPairingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClockPairingQuery, GetClockPairingQueryVariables>(GetClockPairingDocument, baseOptions);
        }
export type GetClockPairingQueryHookResult = ReturnType<typeof useGetClockPairingQuery>;
export type GetClockPairingLazyQueryHookResult = ReturnType<typeof useGetClockPairingLazyQuery>;
export type GetClockPairingQueryResult = ApolloReactCommon.QueryResult<GetClockPairingQuery, GetClockPairingQueryVariables>;
export const GetLatestAttandanceCalculationDateByCompanyDocument = gql`
    query GetLatestAttandanceCalculationDateByCompany($CompanyID: String!) {
  GetLatestAttandanceCalculationDateByCompany(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetLatestAttandanceCalculationDateByCompanyQuery__
 *
 * To run a query within a React component, call `useGetLatestAttandanceCalculationDateByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestAttandanceCalculationDateByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestAttandanceCalculationDateByCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetLatestAttandanceCalculationDateByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestAttandanceCalculationDateByCompanyQuery, GetLatestAttandanceCalculationDateByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestAttandanceCalculationDateByCompanyQuery, GetLatestAttandanceCalculationDateByCompanyQueryVariables>(GetLatestAttandanceCalculationDateByCompanyDocument, baseOptions);
      }
export function useGetLatestAttandanceCalculationDateByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestAttandanceCalculationDateByCompanyQuery, GetLatestAttandanceCalculationDateByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestAttandanceCalculationDateByCompanyQuery, GetLatestAttandanceCalculationDateByCompanyQueryVariables>(GetLatestAttandanceCalculationDateByCompanyDocument, baseOptions);
        }
export type GetLatestAttandanceCalculationDateByCompanyQueryHookResult = ReturnType<typeof useGetLatestAttandanceCalculationDateByCompanyQuery>;
export type GetLatestAttandanceCalculationDateByCompanyLazyQueryHookResult = ReturnType<typeof useGetLatestAttandanceCalculationDateByCompanyLazyQuery>;
export type GetLatestAttandanceCalculationDateByCompanyQueryResult = ApolloReactCommon.QueryResult<GetLatestAttandanceCalculationDateByCompanyQuery, GetLatestAttandanceCalculationDateByCompanyQueryVariables>;
export const TrainingCostBudgetListingDocument = gql`
    query TrainingCostBudgetListing($CompanyID: String!, $Year: String!) {
  TrainingCostBudgetListing(CompanyID: $CompanyID, Year: $Year) {
    TrainingBudgetID
    SubscriptionAccountID
    CompanyID
    OrgUnitID
    BudgetAmount
    Year
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useTrainingCostBudgetListingQuery__
 *
 * To run a query within a React component, call `useTrainingCostBudgetListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingCostBudgetListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingCostBudgetListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useTrainingCostBudgetListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingCostBudgetListingQuery, TrainingCostBudgetListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingCostBudgetListingQuery, TrainingCostBudgetListingQueryVariables>(TrainingCostBudgetListingDocument, baseOptions);
      }
export function useTrainingCostBudgetListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingCostBudgetListingQuery, TrainingCostBudgetListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingCostBudgetListingQuery, TrainingCostBudgetListingQueryVariables>(TrainingCostBudgetListingDocument, baseOptions);
        }
export type TrainingCostBudgetListingQueryHookResult = ReturnType<typeof useTrainingCostBudgetListingQuery>;
export type TrainingCostBudgetListingLazyQueryHookResult = ReturnType<typeof useTrainingCostBudgetListingLazyQuery>;
export type TrainingCostBudgetListingQueryResult = ApolloReactCommon.QueryResult<TrainingCostBudgetListingQuery, TrainingCostBudgetListingQueryVariables>;
export const TrainingBudgetInputBulkInsertDocument = gql`
    mutation TrainingBudgetInputBulkInsert($input: [TrainingBudgetInput!]!) {
  TrainingBudgetInputBulkInsert(input: $input)
}
    `;
export type TrainingBudgetInputBulkInsertMutationFn = ApolloReactCommon.MutationFunction<TrainingBudgetInputBulkInsertMutation, TrainingBudgetInputBulkInsertMutationVariables>;

/**
 * __useTrainingBudgetInputBulkInsertMutation__
 *
 * To run a mutation, you first call `useTrainingBudgetInputBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrainingBudgetInputBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trainingBudgetInputBulkInsertMutation, { data, loading, error }] = useTrainingBudgetInputBulkInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrainingBudgetInputBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TrainingBudgetInputBulkInsertMutation, TrainingBudgetInputBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TrainingBudgetInputBulkInsertMutation, TrainingBudgetInputBulkInsertMutationVariables>(TrainingBudgetInputBulkInsertDocument, baseOptions);
      }
export type TrainingBudgetInputBulkInsertMutationHookResult = ReturnType<typeof useTrainingBudgetInputBulkInsertMutation>;
export type TrainingBudgetInputBulkInsertMutationResult = ApolloReactCommon.MutationResult<TrainingBudgetInputBulkInsertMutation>;
export type TrainingBudgetInputBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TrainingBudgetInputBulkInsertMutation, TrainingBudgetInputBulkInsertMutationVariables>;
export const CompanyHierarchyListDocument = gql`
    query CompanyHierarchyList($CompanyID: String!) {
  CompanyHierarchyList(CompanyID: $CompanyID)
}
    `;

/**
 * __useCompanyHierarchyListQuery__
 *
 * To run a query within a React component, call `useCompanyHierarchyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyHierarchyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyHierarchyListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCompanyHierarchyListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyHierarchyListQuery, CompanyHierarchyListQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyHierarchyListQuery, CompanyHierarchyListQueryVariables>(CompanyHierarchyListDocument, baseOptions);
      }
export function useCompanyHierarchyListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyHierarchyListQuery, CompanyHierarchyListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyHierarchyListQuery, CompanyHierarchyListQueryVariables>(CompanyHierarchyListDocument, baseOptions);
        }
export type CompanyHierarchyListQueryHookResult = ReturnType<typeof useCompanyHierarchyListQuery>;
export type CompanyHierarchyListLazyQueryHookResult = ReturnType<typeof useCompanyHierarchyListLazyQuery>;
export type CompanyHierarchyListQueryResult = ApolloReactCommon.QueryResult<CompanyHierarchyListQuery, CompanyHierarchyListQueryVariables>;
export const NewsReadListingDocument = gql`
    query NewsReadListing($EmployeeID: String, $NewsID: [String!]) {
  NewsReadListing(EmployeeID: $EmployeeID, NewsID: $NewsID) {
    NewsReadID
    NewsID
    EmployeeID
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __useNewsReadListingQuery__
 *
 * To run a query within a React component, call `useNewsReadListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsReadListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsReadListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      NewsID: // value for 'NewsID'
 *   },
 * });
 */
export function useNewsReadListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsReadListingQuery, NewsReadListingQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsReadListingQuery, NewsReadListingQueryVariables>(NewsReadListingDocument, baseOptions);
      }
export function useNewsReadListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsReadListingQuery, NewsReadListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsReadListingQuery, NewsReadListingQueryVariables>(NewsReadListingDocument, baseOptions);
        }
export type NewsReadListingQueryHookResult = ReturnType<typeof useNewsReadListingQuery>;
export type NewsReadListingLazyQueryHookResult = ReturnType<typeof useNewsReadListingLazyQuery>;
export type NewsReadListingQueryResult = ApolloReactCommon.QueryResult<NewsReadListingQuery, NewsReadListingQueryVariables>;
export const NewsReadInsertDocument = gql`
    mutation NewsReadInsert($NewsReadInput: NewsReadInput!) {
  NewsReadInsert(NewsReadInput: $NewsReadInput)
}
    `;
export type NewsReadInsertMutationFn = ApolloReactCommon.MutationFunction<NewsReadInsertMutation, NewsReadInsertMutationVariables>;

/**
 * __useNewsReadInsertMutation__
 *
 * To run a mutation, you first call `useNewsReadInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsReadInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsReadInsertMutation, { data, loading, error }] = useNewsReadInsertMutation({
 *   variables: {
 *      NewsReadInput: // value for 'NewsReadInput'
 *   },
 * });
 */
export function useNewsReadInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewsReadInsertMutation, NewsReadInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<NewsReadInsertMutation, NewsReadInsertMutationVariables>(NewsReadInsertDocument, baseOptions);
      }
export type NewsReadInsertMutationHookResult = ReturnType<typeof useNewsReadInsertMutation>;
export type NewsReadInsertMutationResult = ApolloReactCommon.MutationResult<NewsReadInsertMutation>;
export type NewsReadInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<NewsReadInsertMutation, NewsReadInsertMutationVariables>;
export const BulletinListingDocument = gql`
    query BulletinListing($NewsID: String!) {
  BulletinListing(NewsID: $NewsID)
}
    `;

/**
 * __useBulletinListingQuery__
 *
 * To run a query within a React component, call `useBulletinListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulletinListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulletinListingQuery({
 *   variables: {
 *      NewsID: // value for 'NewsID'
 *   },
 * });
 */
export function useBulletinListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BulletinListingQuery, BulletinListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BulletinListingQuery, BulletinListingQueryVariables>(BulletinListingDocument, baseOptions);
      }
export function useBulletinListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BulletinListingQuery, BulletinListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BulletinListingQuery, BulletinListingQueryVariables>(BulletinListingDocument, baseOptions);
        }
export type BulletinListingQueryHookResult = ReturnType<typeof useBulletinListingQuery>;
export type BulletinListingLazyQueryHookResult = ReturnType<typeof useBulletinListingLazyQuery>;
export type BulletinListingQueryResult = ApolloReactCommon.QueryResult<BulletinListingQuery, BulletinListingQueryVariables>;
export const EmployeeBusinessInsightSummaryDocument = gql`
    query EmployeeBusinessInsightSummary($input: EmployeeBusinessInsightListing!) {
  EmployeeBusinessInsightSummary(input: $input) {
    Gender {
      groupName
      TotalEmp
    }
    Race {
      groupName
      TotalEmp
    }
    Religion {
      groupName
      TotalEmp
    }
    Marital {
      groupName
      TotalEmp
    }
    Age {
      groupName
      TotalEmp
    }
    EmploymentType {
      groupName
      TotalEmp
    }
    StaffTurnover {
      groupName
      TotalEmp
    }
    JobGrade {
      groupName
      TotalEmp
    }
    LengthOfService {
      groupName
      TotalEmp
    }
    RecruitmentSource {
      groupName
      TotalEmp
    }
  }
}
    `;

/**
 * __useEmployeeBusinessInsightSummaryQuery__
 *
 * To run a query within a React component, call `useEmployeeBusinessInsightSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeBusinessInsightSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeBusinessInsightSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeBusinessInsightSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeBusinessInsightSummaryQuery, EmployeeBusinessInsightSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeBusinessInsightSummaryQuery, EmployeeBusinessInsightSummaryQueryVariables>(EmployeeBusinessInsightSummaryDocument, baseOptions);
      }
export function useEmployeeBusinessInsightSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeBusinessInsightSummaryQuery, EmployeeBusinessInsightSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeBusinessInsightSummaryQuery, EmployeeBusinessInsightSummaryQueryVariables>(EmployeeBusinessInsightSummaryDocument, baseOptions);
        }
export type EmployeeBusinessInsightSummaryQueryHookResult = ReturnType<typeof useEmployeeBusinessInsightSummaryQuery>;
export type EmployeeBusinessInsightSummaryLazyQueryHookResult = ReturnType<typeof useEmployeeBusinessInsightSummaryLazyQuery>;
export type EmployeeBusinessInsightSummaryQueryResult = ApolloReactCommon.QueryResult<EmployeeBusinessInsightSummaryQuery, EmployeeBusinessInsightSummaryQueryVariables>;
export const PayrollBusinessInsightSummaryDocument = gql`
    query PayrollBusinessInsightSummary($input: EmployeeBusinessInsightListing!) {
  PayrollBusinessInsightSummary(input: $input) {
    StatutoryContribution {
      Month
      Year
      EmployerContribution
      EmployeeContribution
    }
    SalaryOverhead {
      Year
      Month
      TotalEmp
      TotalGross
      TotalDeduction
      NetPay
    }
    PayItems {
      Year
      Month
      NetAmount
      TotalEarning
      TotalOvertime
      TotalDeduction
      TotalNonpayable
    }
  }
}
    `;

/**
 * __usePayrollBusinessInsightSummaryQuery__
 *
 * To run a query within a React component, call `usePayrollBusinessInsightSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollBusinessInsightSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollBusinessInsightSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayrollBusinessInsightSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollBusinessInsightSummaryQuery, PayrollBusinessInsightSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollBusinessInsightSummaryQuery, PayrollBusinessInsightSummaryQueryVariables>(PayrollBusinessInsightSummaryDocument, baseOptions);
      }
export function usePayrollBusinessInsightSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollBusinessInsightSummaryQuery, PayrollBusinessInsightSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollBusinessInsightSummaryQuery, PayrollBusinessInsightSummaryQueryVariables>(PayrollBusinessInsightSummaryDocument, baseOptions);
        }
export type PayrollBusinessInsightSummaryQueryHookResult = ReturnType<typeof usePayrollBusinessInsightSummaryQuery>;
export type PayrollBusinessInsightSummaryLazyQueryHookResult = ReturnType<typeof usePayrollBusinessInsightSummaryLazyQuery>;
export type PayrollBusinessInsightSummaryQueryResult = ApolloReactCommon.QueryResult<PayrollBusinessInsightSummaryQuery, PayrollBusinessInsightSummaryQueryVariables>;
export const LeaveBiLeaveTakenListingDocument = gql`
    query LeaveBILeaveTakenListing($input: EmployeeBusinessInsightListing!) {
  LeaveBILeaveTakenListing(input: $input) {
    Year
    Month
    TotalAllLeave
    TotalAnnualLeave
    TotalSickLeave
    TotalHospitalizationLeave
    TotalMaternityLeave
    TotalCompassionateLeave
    TotalUnpaidLeave
    TotalReplacementLeave
  }
}
    `;

/**
 * __useLeaveBiLeaveTakenListingQuery__
 *
 * To run a query within a React component, call `useLeaveBiLeaveTakenListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveBiLeaveTakenListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveBiLeaveTakenListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveBiLeaveTakenListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveBiLeaveTakenListingQuery, LeaveBiLeaveTakenListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveBiLeaveTakenListingQuery, LeaveBiLeaveTakenListingQueryVariables>(LeaveBiLeaveTakenListingDocument, baseOptions);
      }
export function useLeaveBiLeaveTakenListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveBiLeaveTakenListingQuery, LeaveBiLeaveTakenListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveBiLeaveTakenListingQuery, LeaveBiLeaveTakenListingQueryVariables>(LeaveBiLeaveTakenListingDocument, baseOptions);
        }
export type LeaveBiLeaveTakenListingQueryHookResult = ReturnType<typeof useLeaveBiLeaveTakenListingQuery>;
export type LeaveBiLeaveTakenListingLazyQueryHookResult = ReturnType<typeof useLeaveBiLeaveTakenListingLazyQuery>;
export type LeaveBiLeaveTakenListingQueryResult = ApolloReactCommon.QueryResult<LeaveBiLeaveTakenListingQuery, LeaveBiLeaveTakenListingQueryVariables>;
export const ClaimBiClaimTakenDocument = gql`
    query ClaimBIClaimTaken($DivisionIDs: [String!], $DepartmentIDs: [String!], $CompanyIDs: [String!], $ClaimTypeIDs: [String!]!, $Year: Float!, $SubscriptionAccountID: String!) {
  ClaimBIClaimTaken(DivisionIDs: $DivisionIDs, DepartmentIDs: $DepartmentIDs, CompanyIDs: $CompanyIDs, ClaimTypeIDs: $ClaimTypeIDs, Year: $Year, SubscriptionAccountID: $SubscriptionAccountID) {
    Month
    Year
    ClaimTakenByLeaveType {
      ClaimTypeID
      ClaimTypeName
      ClaimTaken
    }
  }
}
    `;

/**
 * __useClaimBiClaimTakenQuery__
 *
 * To run a query within a React component, call `useClaimBiClaimTakenQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimBiClaimTakenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimBiClaimTakenQuery({
 *   variables: {
 *      DivisionIDs: // value for 'DivisionIDs'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      CompanyIDs: // value for 'CompanyIDs'
 *      ClaimTypeIDs: // value for 'ClaimTypeIDs'
 *      Year: // value for 'Year'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useClaimBiClaimTakenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimBiClaimTakenQuery, ClaimBiClaimTakenQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimBiClaimTakenQuery, ClaimBiClaimTakenQueryVariables>(ClaimBiClaimTakenDocument, baseOptions);
      }
export function useClaimBiClaimTakenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimBiClaimTakenQuery, ClaimBiClaimTakenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimBiClaimTakenQuery, ClaimBiClaimTakenQueryVariables>(ClaimBiClaimTakenDocument, baseOptions);
        }
export type ClaimBiClaimTakenQueryHookResult = ReturnType<typeof useClaimBiClaimTakenQuery>;
export type ClaimBiClaimTakenLazyQueryHookResult = ReturnType<typeof useClaimBiClaimTakenLazyQuery>;
export type ClaimBiClaimTakenQueryResult = ApolloReactCommon.QueryResult<ClaimBiClaimTakenQuery, ClaimBiClaimTakenQueryVariables>;
export const RequisitionTakenSummaryByTypeDocument = gql`
    query RequisitionTakenSummaryByType($EntityIDs: [String!]!, $FilterBy: String!, $Year: Float!) {
  RequisitionTakenSummaryByType(EntityIDs: $EntityIDs, FilterBy: $FilterBy, Year: $Year) {
    RequisitionType
    TotalRequisitionCount
    TotalRequisitionAmount
    Percentage
  }
}
    `;

/**
 * __useRequisitionTakenSummaryByTypeQuery__
 *
 * To run a query within a React component, call `useRequisitionTakenSummaryByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequisitionTakenSummaryByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequisitionTakenSummaryByTypeQuery({
 *   variables: {
 *      EntityIDs: // value for 'EntityIDs'
 *      FilterBy: // value for 'FilterBy'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useRequisitionTakenSummaryByTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RequisitionTakenSummaryByTypeQuery, RequisitionTakenSummaryByTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<RequisitionTakenSummaryByTypeQuery, RequisitionTakenSummaryByTypeQueryVariables>(RequisitionTakenSummaryByTypeDocument, baseOptions);
      }
export function useRequisitionTakenSummaryByTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RequisitionTakenSummaryByTypeQuery, RequisitionTakenSummaryByTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RequisitionTakenSummaryByTypeQuery, RequisitionTakenSummaryByTypeQueryVariables>(RequisitionTakenSummaryByTypeDocument, baseOptions);
        }
export type RequisitionTakenSummaryByTypeQueryHookResult = ReturnType<typeof useRequisitionTakenSummaryByTypeQuery>;
export type RequisitionTakenSummaryByTypeLazyQueryHookResult = ReturnType<typeof useRequisitionTakenSummaryByTypeLazyQuery>;
export type RequisitionTakenSummaryByTypeQueryResult = ApolloReactCommon.QueryResult<RequisitionTakenSummaryByTypeQuery, RequisitionTakenSummaryByTypeQueryVariables>;
export const TopRequisitionTakerDocument = gql`
    query TopRequisitionTaker($RequisitionType: [RequisitionType!]!, $EntityIDs: [String!]!, $FilterBy: String!, $Year: Float!) {
  TopRequisitionTaker(RequisitionType: $RequisitionType, EntityIDs: $EntityIDs, FilterBy: $FilterBy, Year: $Year) {
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    RequisitionTaken
  }
}
    `;

/**
 * __useTopRequisitionTakerQuery__
 *
 * To run a query within a React component, call `useTopRequisitionTakerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopRequisitionTakerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopRequisitionTakerQuery({
 *   variables: {
 *      RequisitionType: // value for 'RequisitionType'
 *      EntityIDs: // value for 'EntityIDs'
 *      FilterBy: // value for 'FilterBy'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useTopRequisitionTakerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TopRequisitionTakerQuery, TopRequisitionTakerQueryVariables>) {
        return ApolloReactHooks.useQuery<TopRequisitionTakerQuery, TopRequisitionTakerQueryVariables>(TopRequisitionTakerDocument, baseOptions);
      }
export function useTopRequisitionTakerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TopRequisitionTakerQuery, TopRequisitionTakerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TopRequisitionTakerQuery, TopRequisitionTakerQueryVariables>(TopRequisitionTakerDocument, baseOptions);
        }
export type TopRequisitionTakerQueryHookResult = ReturnType<typeof useTopRequisitionTakerQuery>;
export type TopRequisitionTakerLazyQueryHookResult = ReturnType<typeof useTopRequisitionTakerLazyQuery>;
export type TopRequisitionTakerQueryResult = ApolloReactCommon.QueryResult<TopRequisitionTakerQuery, TopRequisitionTakerQueryVariables>;
export const CompanyBusinessInsightDocument = gql`
    query CompanyBusinessInsight($input: EmployeeBusinessInsightListing!) {
  CompanyBusinessInsight(input: $input) {
    label
    value
    children {
      label
      value
      children {
        label
        value
      }
    }
  }
}
    `;

/**
 * __useCompanyBusinessInsightQuery__
 *
 * To run a query within a React component, call `useCompanyBusinessInsightQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyBusinessInsightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyBusinessInsightQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyBusinessInsightQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyBusinessInsightQuery, CompanyBusinessInsightQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyBusinessInsightQuery, CompanyBusinessInsightQueryVariables>(CompanyBusinessInsightDocument, baseOptions);
      }
export function useCompanyBusinessInsightLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyBusinessInsightQuery, CompanyBusinessInsightQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyBusinessInsightQuery, CompanyBusinessInsightQueryVariables>(CompanyBusinessInsightDocument, baseOptions);
        }
export type CompanyBusinessInsightQueryHookResult = ReturnType<typeof useCompanyBusinessInsightQuery>;
export type CompanyBusinessInsightLazyQueryHookResult = ReturnType<typeof useCompanyBusinessInsightLazyQuery>;
export type CompanyBusinessInsightQueryResult = ApolloReactCommon.QueryResult<CompanyBusinessInsightQuery, CompanyBusinessInsightQueryVariables>;
export const EmployeeBusinessInsightListingDocument = gql`
    query EmployeeBusinessInsightListing($input: EmployeeBusinessInsightListing!) {
  EmployeeBusinessInsightListing(input: $input) {
    FilterType
    TotalEmp
  }
}
    `;

/**
 * __useEmployeeBusinessInsightListingQuery__
 *
 * To run a query within a React component, call `useEmployeeBusinessInsightListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeBusinessInsightListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeBusinessInsightListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeBusinessInsightListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeBusinessInsightListingQuery, EmployeeBusinessInsightListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeBusinessInsightListingQuery, EmployeeBusinessInsightListingQueryVariables>(EmployeeBusinessInsightListingDocument, baseOptions);
      }
export function useEmployeeBusinessInsightListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeBusinessInsightListingQuery, EmployeeBusinessInsightListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeBusinessInsightListingQuery, EmployeeBusinessInsightListingQueryVariables>(EmployeeBusinessInsightListingDocument, baseOptions);
        }
export type EmployeeBusinessInsightListingQueryHookResult = ReturnType<typeof useEmployeeBusinessInsightListingQuery>;
export type EmployeeBusinessInsightListingLazyQueryHookResult = ReturnType<typeof useEmployeeBusinessInsightListingLazyQuery>;
export type EmployeeBusinessInsightListingQueryResult = ApolloReactCommon.QueryResult<EmployeeBusinessInsightListingQuery, EmployeeBusinessInsightListingQueryVariables>;
export const CalendarPolicyListingDocument = gql`
    query CalendarPolicyListing($CompanyID: String!) {
  CalendarPolicyListing(CompanyID: $CompanyID) {
    CalendarPolicyID
    StateName
    StateID
    Default
    ModifiedDT
    Title
    Status
    inUse
    YearlyHolidaysGroup
    RestOffDay {
      CompanyID
      PeriodYear
      RecurringRestOffDayID
      RestOffType
      StateID
      Title
      DayOfWeek
    }
    CompanyDelaredHoliday {
      CompanyDelaredHolidayID
      CalendarPolicyID
      CompanyID
      StateID
      HolidayDate
      Description
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    Holiday {
      HolidayDate
      HolidayID
      Description
      PeriodYear
      States
      RestOffType
    }
  }
}
    `;

/**
 * __useCalendarPolicyListingQuery__
 *
 * To run a query within a React component, call `useCalendarPolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarPolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarPolicyListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCalendarPolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalendarPolicyListingQuery, CalendarPolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CalendarPolicyListingQuery, CalendarPolicyListingQueryVariables>(CalendarPolicyListingDocument, baseOptions);
      }
export function useCalendarPolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalendarPolicyListingQuery, CalendarPolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalendarPolicyListingQuery, CalendarPolicyListingQueryVariables>(CalendarPolicyListingDocument, baseOptions);
        }
export type CalendarPolicyListingQueryHookResult = ReturnType<typeof useCalendarPolicyListingQuery>;
export type CalendarPolicyListingLazyQueryHookResult = ReturnType<typeof useCalendarPolicyListingLazyQuery>;
export type CalendarPolicyListingQueryResult = ApolloReactCommon.QueryResult<CalendarPolicyListingQuery, CalendarPolicyListingQueryVariables>;
export const OffRestDayListingDocument = gql`
    query OffRestDayListing($CompanyID: String!, $StateID: String!) {
  OffRestDayListing(CompanyID: $CompanyID, StateID: $StateID) {
    CalendarPolicyID
    RecurringRestOffDayID
    CompanyID
    PeriodYear
    RestOffType
    DayOfWeek
    StateID
    Title
  }
}
    `;

/**
 * __useOffRestDayListingQuery__
 *
 * To run a query within a React component, call `useOffRestDayListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffRestDayListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffRestDayListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StateID: // value for 'StateID'
 *   },
 * });
 */
export function useOffRestDayListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OffRestDayListingQuery, OffRestDayListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OffRestDayListingQuery, OffRestDayListingQueryVariables>(OffRestDayListingDocument, baseOptions);
      }
export function useOffRestDayListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OffRestDayListingQuery, OffRestDayListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OffRestDayListingQuery, OffRestDayListingQueryVariables>(OffRestDayListingDocument, baseOptions);
        }
export type OffRestDayListingQueryHookResult = ReturnType<typeof useOffRestDayListingQuery>;
export type OffRestDayListingLazyQueryHookResult = ReturnType<typeof useOffRestDayListingLazyQuery>;
export type OffRestDayListingQueryResult = ApolloReactCommon.QueryResult<OffRestDayListingQuery, OffRestDayListingQueryVariables>;
export const HolidayListingDocument = gql`
    query HolidayListing($States: String!, $PeriodYear: Float!) {
  HolidayListing(States: $States, PeriodYear: $PeriodYear) {
    HolidayDate
    HolidayID
    Description
    PeriodYear
    States
    RestOffType
  }
}
    `;

/**
 * __useHolidayListingQuery__
 *
 * To run a query within a React component, call `useHolidayListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHolidayListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHolidayListingQuery({
 *   variables: {
 *      States: // value for 'States'
 *      PeriodYear: // value for 'PeriodYear'
 *   },
 * });
 */
export function useHolidayListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HolidayListingQuery, HolidayListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HolidayListingQuery, HolidayListingQueryVariables>(HolidayListingDocument, baseOptions);
      }
export function useHolidayListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HolidayListingQuery, HolidayListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HolidayListingQuery, HolidayListingQueryVariables>(HolidayListingDocument, baseOptions);
        }
export type HolidayListingQueryHookResult = ReturnType<typeof useHolidayListingQuery>;
export type HolidayListingLazyQueryHookResult = ReturnType<typeof useHolidayListingLazyQuery>;
export type HolidayListingQueryResult = ApolloReactCommon.QueryResult<HolidayListingQuery, HolidayListingQueryVariables>;
export const CompanyOffDaysListingDocument = gql`
    query CompanyOffDaysListing($CompanyID: String!) {
  CompanyOffDaysListing(CompanyID: $CompanyID) {
    CompanyOffDayID
    Company {
      CalendarStateID
    }
    Title
    RecurringRestOffDayID
    CompanyID
    HolidayID
    Description
    DayOffDate
    PeriodYear
  }
}
    `;

/**
 * __useCompanyOffDaysListingQuery__
 *
 * To run a query within a React component, call `useCompanyOffDaysListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOffDaysListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOffDaysListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCompanyOffDaysListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyOffDaysListingQuery, CompanyOffDaysListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyOffDaysListingQuery, CompanyOffDaysListingQueryVariables>(CompanyOffDaysListingDocument, baseOptions);
      }
export function useCompanyOffDaysListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyOffDaysListingQuery, CompanyOffDaysListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyOffDaysListingQuery, CompanyOffDaysListingQueryVariables>(CompanyOffDaysListingDocument, baseOptions);
        }
export type CompanyOffDaysListingQueryHookResult = ReturnType<typeof useCompanyOffDaysListingQuery>;
export type CompanyOffDaysListingLazyQueryHookResult = ReturnType<typeof useCompanyOffDaysListingLazyQuery>;
export type CompanyOffDaysListingQueryResult = ApolloReactCommon.QueryResult<CompanyOffDaysListingQuery, CompanyOffDaysListingQueryVariables>;
export const CompanyOffDaysListingWithFiltersDocument = gql`
    query CompanyOffDaysListingWithFilters($CompanyID: String!, $Title: String!, $StateID: String!) {
  CompanyOffDaysListingWithFilters(CompanyID: $CompanyID, Title: $Title, StateID: $StateID)
}
    `;

/**
 * __useCompanyOffDaysListingWithFiltersQuery__
 *
 * To run a query within a React component, call `useCompanyOffDaysListingWithFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOffDaysListingWithFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOffDaysListingWithFiltersQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Title: // value for 'Title'
 *      StateID: // value for 'StateID'
 *   },
 * });
 */
export function useCompanyOffDaysListingWithFiltersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyOffDaysListingWithFiltersQuery, CompanyOffDaysListingWithFiltersQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyOffDaysListingWithFiltersQuery, CompanyOffDaysListingWithFiltersQueryVariables>(CompanyOffDaysListingWithFiltersDocument, baseOptions);
      }
export function useCompanyOffDaysListingWithFiltersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyOffDaysListingWithFiltersQuery, CompanyOffDaysListingWithFiltersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyOffDaysListingWithFiltersQuery, CompanyOffDaysListingWithFiltersQueryVariables>(CompanyOffDaysListingWithFiltersDocument, baseOptions);
        }
export type CompanyOffDaysListingWithFiltersQueryHookResult = ReturnType<typeof useCompanyOffDaysListingWithFiltersQuery>;
export type CompanyOffDaysListingWithFiltersLazyQueryHookResult = ReturnType<typeof useCompanyOffDaysListingWithFiltersLazyQuery>;
export type CompanyOffDaysListingWithFiltersQueryResult = ApolloReactCommon.QueryResult<CompanyOffDaysListingWithFiltersQuery, CompanyOffDaysListingWithFiltersQueryVariables>;
export const CompanyDeclaredHolidayListingByCalendarPolicyIdDocument = gql`
    query CompanyDeclaredHolidayListingByCalendarPolicyID($CalendarPolicyID: String!) {
  CompanyDeclaredHolidayListingByCalendarPolicyID(CalendarPolicyID: $CalendarPolicyID) {
    CompanyDelaredHolidayID
    CalendarPolicyID
    CompanyID
    StateID
    HolidayDate
    Description
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useCompanyDeclaredHolidayListingByCalendarPolicyIdQuery__
 *
 * To run a query within a React component, call `useCompanyDeclaredHolidayListingByCalendarPolicyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDeclaredHolidayListingByCalendarPolicyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDeclaredHolidayListingByCalendarPolicyIdQuery({
 *   variables: {
 *      CalendarPolicyID: // value for 'CalendarPolicyID'
 *   },
 * });
 */
export function useCompanyDeclaredHolidayListingByCalendarPolicyIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyDeclaredHolidayListingByCalendarPolicyIdQuery, CompanyDeclaredHolidayListingByCalendarPolicyIdQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyDeclaredHolidayListingByCalendarPolicyIdQuery, CompanyDeclaredHolidayListingByCalendarPolicyIdQueryVariables>(CompanyDeclaredHolidayListingByCalendarPolicyIdDocument, baseOptions);
      }
export function useCompanyDeclaredHolidayListingByCalendarPolicyIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyDeclaredHolidayListingByCalendarPolicyIdQuery, CompanyDeclaredHolidayListingByCalendarPolicyIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyDeclaredHolidayListingByCalendarPolicyIdQuery, CompanyDeclaredHolidayListingByCalendarPolicyIdQueryVariables>(CompanyDeclaredHolidayListingByCalendarPolicyIdDocument, baseOptions);
        }
export type CompanyDeclaredHolidayListingByCalendarPolicyIdQueryHookResult = ReturnType<typeof useCompanyDeclaredHolidayListingByCalendarPolicyIdQuery>;
export type CompanyDeclaredHolidayListingByCalendarPolicyIdLazyQueryHookResult = ReturnType<typeof useCompanyDeclaredHolidayListingByCalendarPolicyIdLazyQuery>;
export type CompanyDeclaredHolidayListingByCalendarPolicyIdQueryResult = ApolloReactCommon.QueryResult<CompanyDeclaredHolidayListingByCalendarPolicyIdQuery, CompanyDeclaredHolidayListingByCalendarPolicyIdQueryVariables>;
export const CalanderPolicySubmitDocument = gql`
    mutation CalanderPolicySubmit($CompanyOffDayInput: [CompanyOffDayInput!]!, $IsCompanyDefaultCalendar: Boolean, $CompanyID: String, $StateID: String, $CalendarPolicyInput: CalendarPolicyInput, $CompanyDelaredHolidayInput: [CompanyDelaredHolidayInput!]!, $RecurringRestOffDayInput: [RecurringRestOffDayInput!]!) {
  CalanderPolicySubmit(CompanyOffDayInput: $CompanyOffDayInput, IsCompanyDefaultCalendar: $IsCompanyDefaultCalendar, CompanyID: $CompanyID, StateID: $StateID, CalendarPolicyInput: $CalendarPolicyInput, CompanyDelaredHolidayInput: $CompanyDelaredHolidayInput, RecurringRestOffDayInput: $RecurringRestOffDayInput)
}
    `;
export type CalanderPolicySubmitMutationFn = ApolloReactCommon.MutationFunction<CalanderPolicySubmitMutation, CalanderPolicySubmitMutationVariables>;

/**
 * __useCalanderPolicySubmitMutation__
 *
 * To run a mutation, you first call `useCalanderPolicySubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalanderPolicySubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calanderPolicySubmitMutation, { data, loading, error }] = useCalanderPolicySubmitMutation({
 *   variables: {
 *      CompanyOffDayInput: // value for 'CompanyOffDayInput'
 *      IsCompanyDefaultCalendar: // value for 'IsCompanyDefaultCalendar'
 *      CompanyID: // value for 'CompanyID'
 *      StateID: // value for 'StateID'
 *      CalendarPolicyInput: // value for 'CalendarPolicyInput'
 *      CompanyDelaredHolidayInput: // value for 'CompanyDelaredHolidayInput'
 *      RecurringRestOffDayInput: // value for 'RecurringRestOffDayInput'
 *   },
 * });
 */
export function useCalanderPolicySubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalanderPolicySubmitMutation, CalanderPolicySubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<CalanderPolicySubmitMutation, CalanderPolicySubmitMutationVariables>(CalanderPolicySubmitDocument, baseOptions);
      }
export type CalanderPolicySubmitMutationHookResult = ReturnType<typeof useCalanderPolicySubmitMutation>;
export type CalanderPolicySubmitMutationResult = ApolloReactCommon.MutationResult<CalanderPolicySubmitMutation>;
export type CalanderPolicySubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<CalanderPolicySubmitMutation, CalanderPolicySubmitMutationVariables>;
export const CompanyHolidaysBulkInsertDocument = gql`
    mutation CompanyHolidaysBulkInsert($CompanyOffDayInput: [CompanyOffDayInput!]!, $IsCompanyDefaultCalendar: Boolean, $CompanyID: String, $StateID: String, $CompanyDelaredHolidayInput: [CompanyDelaredHolidayInput!]!) {
  CompanyHolidaysBulkInsert(CompanyOffDayInput: $CompanyOffDayInput, IsCompanyDefaultCalendar: $IsCompanyDefaultCalendar, CompanyID: $CompanyID, StateID: $StateID, CompanyDelaredHolidayInput: $CompanyDelaredHolidayInput)
}
    `;
export type CompanyHolidaysBulkInsertMutationFn = ApolloReactCommon.MutationFunction<CompanyHolidaysBulkInsertMutation, CompanyHolidaysBulkInsertMutationVariables>;

/**
 * __useCompanyHolidaysBulkInsertMutation__
 *
 * To run a mutation, you first call `useCompanyHolidaysBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyHolidaysBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyHolidaysBulkInsertMutation, { data, loading, error }] = useCompanyHolidaysBulkInsertMutation({
 *   variables: {
 *      CompanyOffDayInput: // value for 'CompanyOffDayInput'
 *      IsCompanyDefaultCalendar: // value for 'IsCompanyDefaultCalendar'
 *      CompanyID: // value for 'CompanyID'
 *      StateID: // value for 'StateID'
 *      CompanyDelaredHolidayInput: // value for 'CompanyDelaredHolidayInput'
 *   },
 * });
 */
export function useCompanyHolidaysBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyHolidaysBulkInsertMutation, CompanyHolidaysBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyHolidaysBulkInsertMutation, CompanyHolidaysBulkInsertMutationVariables>(CompanyHolidaysBulkInsertDocument, baseOptions);
      }
export type CompanyHolidaysBulkInsertMutationHookResult = ReturnType<typeof useCompanyHolidaysBulkInsertMutation>;
export type CompanyHolidaysBulkInsertMutationResult = ApolloReactCommon.MutationResult<CompanyHolidaysBulkInsertMutation>;
export type CompanyHolidaysBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyHolidaysBulkInsertMutation, CompanyHolidaysBulkInsertMutationVariables>;
export const CompanyOffDaysBulkInsertDocument = gql`
    mutation CompanyOffDaysBulkInsert($CompanyOffDayInput: [CompanyOffDayInput!]!, $IsCompanyDefaultCalendar: Boolean, $CompanyID: String, $StateID: String) {
  CompanyOffDaysBulkInsert(CompanyOffDayInput: $CompanyOffDayInput, IsCompanyDefaultCalendar: $IsCompanyDefaultCalendar, CompanyID: $CompanyID, StateID: $StateID)
}
    `;
export type CompanyOffDaysBulkInsertMutationFn = ApolloReactCommon.MutationFunction<CompanyOffDaysBulkInsertMutation, CompanyOffDaysBulkInsertMutationVariables>;

/**
 * __useCompanyOffDaysBulkInsertMutation__
 *
 * To run a mutation, you first call `useCompanyOffDaysBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyOffDaysBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyOffDaysBulkInsertMutation, { data, loading, error }] = useCompanyOffDaysBulkInsertMutation({
 *   variables: {
 *      CompanyOffDayInput: // value for 'CompanyOffDayInput'
 *      IsCompanyDefaultCalendar: // value for 'IsCompanyDefaultCalendar'
 *      CompanyID: // value for 'CompanyID'
 *      StateID: // value for 'StateID'
 *   },
 * });
 */
export function useCompanyOffDaysBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyOffDaysBulkInsertMutation, CompanyOffDaysBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyOffDaysBulkInsertMutation, CompanyOffDaysBulkInsertMutationVariables>(CompanyOffDaysBulkInsertDocument, baseOptions);
      }
export type CompanyOffDaysBulkInsertMutationHookResult = ReturnType<typeof useCompanyOffDaysBulkInsertMutation>;
export type CompanyOffDaysBulkInsertMutationResult = ApolloReactCommon.MutationResult<CompanyOffDaysBulkInsertMutation>;
export type CompanyOffDaysBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyOffDaysBulkInsertMutation, CompanyOffDaysBulkInsertMutationVariables>;
export const CalendarPolicyStatusUpdateDocument = gql`
    mutation CalendarPolicyStatusUpdate($CalendarPolicyID: [String!]!, $Status: Status!) {
  CalendarPolicyStatusUpdate(CalendarPolicyID: $CalendarPolicyID, Status: $Status)
}
    `;
export type CalendarPolicyStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<CalendarPolicyStatusUpdateMutation, CalendarPolicyStatusUpdateMutationVariables>;

/**
 * __useCalendarPolicyStatusUpdateMutation__
 *
 * To run a mutation, you first call `useCalendarPolicyStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarPolicyStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarPolicyStatusUpdateMutation, { data, loading, error }] = useCalendarPolicyStatusUpdateMutation({
 *   variables: {
 *      CalendarPolicyID: // value for 'CalendarPolicyID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useCalendarPolicyStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalendarPolicyStatusUpdateMutation, CalendarPolicyStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CalendarPolicyStatusUpdateMutation, CalendarPolicyStatusUpdateMutationVariables>(CalendarPolicyStatusUpdateDocument, baseOptions);
      }
export type CalendarPolicyStatusUpdateMutationHookResult = ReturnType<typeof useCalendarPolicyStatusUpdateMutation>;
export type CalendarPolicyStatusUpdateMutationResult = ApolloReactCommon.MutationResult<CalendarPolicyStatusUpdateMutation>;
export type CalendarPolicyStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CalendarPolicyStatusUpdateMutation, CalendarPolicyStatusUpdateMutationVariables>;
export const CalendarPolicyDeleteDocument = gql`
    mutation CalendarPolicyDelete($CalendarPolicyID: [String!]!) {
  CalendarPolicyDelete(CalendarPolicyID: $CalendarPolicyID)
}
    `;
export type CalendarPolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<CalendarPolicyDeleteMutation, CalendarPolicyDeleteMutationVariables>;

/**
 * __useCalendarPolicyDeleteMutation__
 *
 * To run a mutation, you first call `useCalendarPolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarPolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarPolicyDeleteMutation, { data, loading, error }] = useCalendarPolicyDeleteMutation({
 *   variables: {
 *      CalendarPolicyID: // value for 'CalendarPolicyID'
 *   },
 * });
 */
export function useCalendarPolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalendarPolicyDeleteMutation, CalendarPolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CalendarPolicyDeleteMutation, CalendarPolicyDeleteMutationVariables>(CalendarPolicyDeleteDocument, baseOptions);
      }
export type CalendarPolicyDeleteMutationHookResult = ReturnType<typeof useCalendarPolicyDeleteMutation>;
export type CalendarPolicyDeleteMutationResult = ApolloReactCommon.MutationResult<CalendarPolicyDeleteMutation>;
export type CalendarPolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CalendarPolicyDeleteMutation, CalendarPolicyDeleteMutationVariables>;
export const CompanyOffDaysDeleteDocument = gql`
    mutation CompanyOffDaysDelete($CompanyOffDayID: [String!]!) {
  CompanyOffDaysDelete(CompanyOffDayID: $CompanyOffDayID)
}
    `;
export type CompanyOffDaysDeleteMutationFn = ApolloReactCommon.MutationFunction<CompanyOffDaysDeleteMutation, CompanyOffDaysDeleteMutationVariables>;

/**
 * __useCompanyOffDaysDeleteMutation__
 *
 * To run a mutation, you first call `useCompanyOffDaysDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyOffDaysDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyOffDaysDeleteMutation, { data, loading, error }] = useCompanyOffDaysDeleteMutation({
 *   variables: {
 *      CompanyOffDayID: // value for 'CompanyOffDayID'
 *   },
 * });
 */
export function useCompanyOffDaysDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyOffDaysDeleteMutation, CompanyOffDaysDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyOffDaysDeleteMutation, CompanyOffDaysDeleteMutationVariables>(CompanyOffDaysDeleteDocument, baseOptions);
      }
export type CompanyOffDaysDeleteMutationHookResult = ReturnType<typeof useCompanyOffDaysDeleteMutation>;
export type CompanyOffDaysDeleteMutationResult = ApolloReactCommon.MutationResult<CompanyOffDaysDeleteMutation>;
export type CompanyOffDaysDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyOffDaysDeleteMutation, CompanyOffDaysDeleteMutationVariables>;
export const OffRestDayBulkInsertDocument = gql`
    mutation OffRestDayBulkInsert($RecurringRestOffDayInput: [RecurringRestOffDayInput!]!, $IsCompanyDefaultCalendar: Boolean) {
  OffRestDayBulkInsert(RecurringRestOffDayInput: $RecurringRestOffDayInput, IsCompanyDefaultCalendar: $IsCompanyDefaultCalendar)
}
    `;
export type OffRestDayBulkInsertMutationFn = ApolloReactCommon.MutationFunction<OffRestDayBulkInsertMutation, OffRestDayBulkInsertMutationVariables>;

/**
 * __useOffRestDayBulkInsertMutation__
 *
 * To run a mutation, you first call `useOffRestDayBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOffRestDayBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offRestDayBulkInsertMutation, { data, loading, error }] = useOffRestDayBulkInsertMutation({
 *   variables: {
 *      RecurringRestOffDayInput: // value for 'RecurringRestOffDayInput'
 *      IsCompanyDefaultCalendar: // value for 'IsCompanyDefaultCalendar'
 *   },
 * });
 */
export function useOffRestDayBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OffRestDayBulkInsertMutation, OffRestDayBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<OffRestDayBulkInsertMutation, OffRestDayBulkInsertMutationVariables>(OffRestDayBulkInsertDocument, baseOptions);
      }
export type OffRestDayBulkInsertMutationHookResult = ReturnType<typeof useOffRestDayBulkInsertMutation>;
export type OffRestDayBulkInsertMutationResult = ApolloReactCommon.MutationResult<OffRestDayBulkInsertMutation>;
export type OffRestDayBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<OffRestDayBulkInsertMutation, OffRestDayBulkInsertMutationVariables>;
export const UpdateRestOffDayStatusDocument = gql`
    mutation updateRestOffDayStatus($RestOffDayID: [String!]!, $Status: Status!) {
  updateRestOffDayStatus(RestOffDayID: $RestOffDayID, Status: $Status)
}
    `;
export type UpdateRestOffDayStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateRestOffDayStatusMutation, UpdateRestOffDayStatusMutationVariables>;

/**
 * __useUpdateRestOffDayStatusMutation__
 *
 * To run a mutation, you first call `useUpdateRestOffDayStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestOffDayStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestOffDayStatusMutation, { data, loading, error }] = useUpdateRestOffDayStatusMutation({
 *   variables: {
 *      RestOffDayID: // value for 'RestOffDayID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useUpdateRestOffDayStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRestOffDayStatusMutation, UpdateRestOffDayStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRestOffDayStatusMutation, UpdateRestOffDayStatusMutationVariables>(UpdateRestOffDayStatusDocument, baseOptions);
      }
export type UpdateRestOffDayStatusMutationHookResult = ReturnType<typeof useUpdateRestOffDayStatusMutation>;
export type UpdateRestOffDayStatusMutationResult = ApolloReactCommon.MutationResult<UpdateRestOffDayStatusMutation>;
export type UpdateRestOffDayStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRestOffDayStatusMutation, UpdateRestOffDayStatusMutationVariables>;
export const UpdateCompanyOffDaysStatusDocument = gql`
    mutation updateCompanyOffDaysStatus($CompanyOffDayID: [String!]!, $Status: Status!) {
  updateCompanyOffDaysStatus(CompanyOffDayID: $CompanyOffDayID, Status: $Status)
}
    `;
export type UpdateCompanyOffDaysStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyOffDaysStatusMutation, UpdateCompanyOffDaysStatusMutationVariables>;

/**
 * __useUpdateCompanyOffDaysStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyOffDaysStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyOffDaysStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyOffDaysStatusMutation, { data, loading, error }] = useUpdateCompanyOffDaysStatusMutation({
 *   variables: {
 *      CompanyOffDayID: // value for 'CompanyOffDayID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useUpdateCompanyOffDaysStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyOffDaysStatusMutation, UpdateCompanyOffDaysStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyOffDaysStatusMutation, UpdateCompanyOffDaysStatusMutationVariables>(UpdateCompanyOffDaysStatusDocument, baseOptions);
      }
export type UpdateCompanyOffDaysStatusMutationHookResult = ReturnType<typeof useUpdateCompanyOffDaysStatusMutation>;
export type UpdateCompanyOffDaysStatusMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyOffDaysStatusMutation>;
export type UpdateCompanyOffDaysStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyOffDaysStatusMutation, UpdateCompanyOffDaysStatusMutationVariables>;
export const OffRestDayDeleteDocument = gql`
    mutation OffRestDayDelete($RecurringRestOffDayID: [String!]!) {
  OffRestDayDelete(RecurringRestOffDayID: $RecurringRestOffDayID)
}
    `;
export type OffRestDayDeleteMutationFn = ApolloReactCommon.MutationFunction<OffRestDayDeleteMutation, OffRestDayDeleteMutationVariables>;

/**
 * __useOffRestDayDeleteMutation__
 *
 * To run a mutation, you first call `useOffRestDayDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOffRestDayDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offRestDayDeleteMutation, { data, loading, error }] = useOffRestDayDeleteMutation({
 *   variables: {
 *      RecurringRestOffDayID: // value for 'RecurringRestOffDayID'
 *   },
 * });
 */
export function useOffRestDayDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OffRestDayDeleteMutation, OffRestDayDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<OffRestDayDeleteMutation, OffRestDayDeleteMutationVariables>(OffRestDayDeleteDocument, baseOptions);
      }
export type OffRestDayDeleteMutationHookResult = ReturnType<typeof useOffRestDayDeleteMutation>;
export type OffRestDayDeleteMutationResult = ApolloReactCommon.MutationResult<OffRestDayDeleteMutation>;
export type OffRestDayDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<OffRestDayDeleteMutation, OffRestDayDeleteMutationVariables>;
export const CompanyOnlyUpdateDocument = gql`
    mutation CompanyOnlyUpdate($CompanyID: String!, $CompanyInput: CompanyInput!) {
  CompanyOnlyUpdate(CompanyID: $CompanyID, CompanyInput: $CompanyInput)
}
    `;
export type CompanyOnlyUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyOnlyUpdateMutation, CompanyOnlyUpdateMutationVariables>;

/**
 * __useCompanyOnlyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyOnlyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyOnlyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyOnlyUpdateMutation, { data, loading, error }] = useCompanyOnlyUpdateMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CompanyInput: // value for 'CompanyInput'
 *   },
 * });
 */
export function useCompanyOnlyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyOnlyUpdateMutation, CompanyOnlyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyOnlyUpdateMutation, CompanyOnlyUpdateMutationVariables>(CompanyOnlyUpdateDocument, baseOptions);
      }
export type CompanyOnlyUpdateMutationHookResult = ReturnType<typeof useCompanyOnlyUpdateMutation>;
export type CompanyOnlyUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyOnlyUpdateMutation>;
export type CompanyOnlyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyOnlyUpdateMutation, CompanyOnlyUpdateMutationVariables>;
export const ClaimAccountHeaderListDocument = gql`
    query ClaimAccountHeaderList {
  ClaimAccountHeaderList {
    ID
    Title
    EnableStaffCategory
    ModifiedDT
    CreatedDT
    DeleteDisable
  }
}
    `;

/**
 * __useClaimAccountHeaderListQuery__
 *
 * To run a query within a React component, call `useClaimAccountHeaderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimAccountHeaderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimAccountHeaderListQuery({
 *   variables: {
 *   },
 * });
 */
export function useClaimAccountHeaderListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimAccountHeaderListQuery, ClaimAccountHeaderListQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimAccountHeaderListQuery, ClaimAccountHeaderListQueryVariables>(ClaimAccountHeaderListDocument, baseOptions);
      }
export function useClaimAccountHeaderListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimAccountHeaderListQuery, ClaimAccountHeaderListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimAccountHeaderListQuery, ClaimAccountHeaderListQueryVariables>(ClaimAccountHeaderListDocument, baseOptions);
        }
export type ClaimAccountHeaderListQueryHookResult = ReturnType<typeof useClaimAccountHeaderListQuery>;
export type ClaimAccountHeaderListLazyQueryHookResult = ReturnType<typeof useClaimAccountHeaderListLazyQuery>;
export type ClaimAccountHeaderListQueryResult = ApolloReactCommon.QueryResult<ClaimAccountHeaderListQuery, ClaimAccountHeaderListQueryVariables>;
export const ClaimAccountMappingListDocument = gql`
    query ClaimAccountMappingList($ClaimAccountHeaderID: String) {
  ClaimAccountMappingList(ClaimAccountHeaderID: $ClaimAccountHeaderID)
}
    `;

/**
 * __useClaimAccountMappingListQuery__
 *
 * To run a query within a React component, call `useClaimAccountMappingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimAccountMappingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimAccountMappingListQuery({
 *   variables: {
 *      ClaimAccountHeaderID: // value for 'ClaimAccountHeaderID'
 *   },
 * });
 */
export function useClaimAccountMappingListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimAccountMappingListQuery, ClaimAccountMappingListQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimAccountMappingListQuery, ClaimAccountMappingListQueryVariables>(ClaimAccountMappingListDocument, baseOptions);
      }
export function useClaimAccountMappingListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimAccountMappingListQuery, ClaimAccountMappingListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimAccountMappingListQuery, ClaimAccountMappingListQueryVariables>(ClaimAccountMappingListDocument, baseOptions);
        }
export type ClaimAccountMappingListQueryHookResult = ReturnType<typeof useClaimAccountMappingListQuery>;
export type ClaimAccountMappingListLazyQueryHookResult = ReturnType<typeof useClaimAccountMappingListLazyQuery>;
export type ClaimAccountMappingListQueryResult = ApolloReactCommon.QueryResult<ClaimAccountMappingListQuery, ClaimAccountMappingListQueryVariables>;
export const ClaimAccountMappingInsertDocument = gql`
    mutation ClaimAccountMappingInsert($HeaderInput: ClaimAccountHeaderInput!, $DetailInput: [ClaimAccountDetailInput!]!) {
  ClaimAccountMappingInsert(HeaderInput: $HeaderInput, DetailInput: $DetailInput)
}
    `;
export type ClaimAccountMappingInsertMutationFn = ApolloReactCommon.MutationFunction<ClaimAccountMappingInsertMutation, ClaimAccountMappingInsertMutationVariables>;

/**
 * __useClaimAccountMappingInsertMutation__
 *
 * To run a mutation, you first call `useClaimAccountMappingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimAccountMappingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimAccountMappingInsertMutation, { data, loading, error }] = useClaimAccountMappingInsertMutation({
 *   variables: {
 *      HeaderInput: // value for 'HeaderInput'
 *      DetailInput: // value for 'DetailInput'
 *   },
 * });
 */
export function useClaimAccountMappingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimAccountMappingInsertMutation, ClaimAccountMappingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimAccountMappingInsertMutation, ClaimAccountMappingInsertMutationVariables>(ClaimAccountMappingInsertDocument, baseOptions);
      }
export type ClaimAccountMappingInsertMutationHookResult = ReturnType<typeof useClaimAccountMappingInsertMutation>;
export type ClaimAccountMappingInsertMutationResult = ApolloReactCommon.MutationResult<ClaimAccountMappingInsertMutation>;
export type ClaimAccountMappingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimAccountMappingInsertMutation, ClaimAccountMappingInsertMutationVariables>;
export const ClaimAccountMappingUpdateDocument = gql`
    mutation ClaimAccountMappingUpdate($HeaderInput: ClaimAccountHeaderInput!, $DetailInput: [ClaimAccountDetailInput!]!) {
  ClaimAccountMappingUpdate(HeaderInput: $HeaderInput, DetailInput: $DetailInput)
}
    `;
export type ClaimAccountMappingUpdateMutationFn = ApolloReactCommon.MutationFunction<ClaimAccountMappingUpdateMutation, ClaimAccountMappingUpdateMutationVariables>;

/**
 * __useClaimAccountMappingUpdateMutation__
 *
 * To run a mutation, you first call `useClaimAccountMappingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimAccountMappingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimAccountMappingUpdateMutation, { data, loading, error }] = useClaimAccountMappingUpdateMutation({
 *   variables: {
 *      HeaderInput: // value for 'HeaderInput'
 *      DetailInput: // value for 'DetailInput'
 *   },
 * });
 */
export function useClaimAccountMappingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimAccountMappingUpdateMutation, ClaimAccountMappingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimAccountMappingUpdateMutation, ClaimAccountMappingUpdateMutationVariables>(ClaimAccountMappingUpdateDocument, baseOptions);
      }
export type ClaimAccountMappingUpdateMutationHookResult = ReturnType<typeof useClaimAccountMappingUpdateMutation>;
export type ClaimAccountMappingUpdateMutationResult = ApolloReactCommon.MutationResult<ClaimAccountMappingUpdateMutation>;
export type ClaimAccountMappingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimAccountMappingUpdateMutation, ClaimAccountMappingUpdateMutationVariables>;
export const ClaimAccountMappingDeleteDocument = gql`
    mutation ClaimAccountMappingDelete($ClaimAccountHeaderID: String!) {
  ClaimAccountMappingDelete(ClaimAccountHeaderID: $ClaimAccountHeaderID)
}
    `;
export type ClaimAccountMappingDeleteMutationFn = ApolloReactCommon.MutationFunction<ClaimAccountMappingDeleteMutation, ClaimAccountMappingDeleteMutationVariables>;

/**
 * __useClaimAccountMappingDeleteMutation__
 *
 * To run a mutation, you first call `useClaimAccountMappingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimAccountMappingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimAccountMappingDeleteMutation, { data, loading, error }] = useClaimAccountMappingDeleteMutation({
 *   variables: {
 *      ClaimAccountHeaderID: // value for 'ClaimAccountHeaderID'
 *   },
 * });
 */
export function useClaimAccountMappingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimAccountMappingDeleteMutation, ClaimAccountMappingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimAccountMappingDeleteMutation, ClaimAccountMappingDeleteMutationVariables>(ClaimAccountMappingDeleteDocument, baseOptions);
      }
export type ClaimAccountMappingDeleteMutationHookResult = ReturnType<typeof useClaimAccountMappingDeleteMutation>;
export type ClaimAccountMappingDeleteMutationResult = ApolloReactCommon.MutationResult<ClaimAccountMappingDeleteMutation>;
export type ClaimAccountMappingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimAccountMappingDeleteMutation, ClaimAccountMappingDeleteMutationVariables>;
export const ClaimAccountHeaderListWithDetailsDocument = gql`
    query ClaimAccountHeaderListWithDetails {
  ClaimAccountHeaderListWithDetails
}
    `;

/**
 * __useClaimAccountHeaderListWithDetailsQuery__
 *
 * To run a query within a React component, call `useClaimAccountHeaderListWithDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimAccountHeaderListWithDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimAccountHeaderListWithDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClaimAccountHeaderListWithDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimAccountHeaderListWithDetailsQuery, ClaimAccountHeaderListWithDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimAccountHeaderListWithDetailsQuery, ClaimAccountHeaderListWithDetailsQueryVariables>(ClaimAccountHeaderListWithDetailsDocument, baseOptions);
      }
export function useClaimAccountHeaderListWithDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimAccountHeaderListWithDetailsQuery, ClaimAccountHeaderListWithDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimAccountHeaderListWithDetailsQuery, ClaimAccountHeaderListWithDetailsQueryVariables>(ClaimAccountHeaderListWithDetailsDocument, baseOptions);
        }
export type ClaimAccountHeaderListWithDetailsQueryHookResult = ReturnType<typeof useClaimAccountHeaderListWithDetailsQuery>;
export type ClaimAccountHeaderListWithDetailsLazyQueryHookResult = ReturnType<typeof useClaimAccountHeaderListWithDetailsLazyQuery>;
export type ClaimAccountHeaderListWithDetailsQueryResult = ApolloReactCommon.QueryResult<ClaimAccountHeaderListWithDetailsQuery, ClaimAccountHeaderListWithDetailsQueryVariables>;
export const SpecificClaimAccountHeaderListWithDetailsDocument = gql`
    query SpecificClaimAccountHeaderListWithDetails($ClaimAccountHeaderID: String) {
  ClaimAccountHeaderListWithDetails(ClaimAccountHeaderID: $ClaimAccountHeaderID)
}
    `;

/**
 * __useSpecificClaimAccountHeaderListWithDetailsQuery__
 *
 * To run a query within a React component, call `useSpecificClaimAccountHeaderListWithDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificClaimAccountHeaderListWithDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificClaimAccountHeaderListWithDetailsQuery({
 *   variables: {
 *      ClaimAccountHeaderID: // value for 'ClaimAccountHeaderID'
 *   },
 * });
 */
export function useSpecificClaimAccountHeaderListWithDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SpecificClaimAccountHeaderListWithDetailsQuery, SpecificClaimAccountHeaderListWithDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<SpecificClaimAccountHeaderListWithDetailsQuery, SpecificClaimAccountHeaderListWithDetailsQueryVariables>(SpecificClaimAccountHeaderListWithDetailsDocument, baseOptions);
      }
export function useSpecificClaimAccountHeaderListWithDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpecificClaimAccountHeaderListWithDetailsQuery, SpecificClaimAccountHeaderListWithDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SpecificClaimAccountHeaderListWithDetailsQuery, SpecificClaimAccountHeaderListWithDetailsQueryVariables>(SpecificClaimAccountHeaderListWithDetailsDocument, baseOptions);
        }
export type SpecificClaimAccountHeaderListWithDetailsQueryHookResult = ReturnType<typeof useSpecificClaimAccountHeaderListWithDetailsQuery>;
export type SpecificClaimAccountHeaderListWithDetailsLazyQueryHookResult = ReturnType<typeof useSpecificClaimAccountHeaderListWithDetailsLazyQuery>;
export type SpecificClaimAccountHeaderListWithDetailsQueryResult = ApolloReactCommon.QueryResult<SpecificClaimAccountHeaderListWithDetailsQuery, SpecificClaimAccountHeaderListWithDetailsQueryVariables>;
export const ClaimAccountAssignmentInsertDocument = gql`
    mutation ClaimAccountAssignmentInsert($HeaderInput: ClaimAccountAssignmentInput!) {
  ClaimAccountAssignmentInsert(HeaderInput: $HeaderInput)
}
    `;
export type ClaimAccountAssignmentInsertMutationFn = ApolloReactCommon.MutationFunction<ClaimAccountAssignmentInsertMutation, ClaimAccountAssignmentInsertMutationVariables>;

/**
 * __useClaimAccountAssignmentInsertMutation__
 *
 * To run a mutation, you first call `useClaimAccountAssignmentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimAccountAssignmentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimAccountAssignmentInsertMutation, { data, loading, error }] = useClaimAccountAssignmentInsertMutation({
 *   variables: {
 *      HeaderInput: // value for 'HeaderInput'
 *   },
 * });
 */
export function useClaimAccountAssignmentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimAccountAssignmentInsertMutation, ClaimAccountAssignmentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimAccountAssignmentInsertMutation, ClaimAccountAssignmentInsertMutationVariables>(ClaimAccountAssignmentInsertDocument, baseOptions);
      }
export type ClaimAccountAssignmentInsertMutationHookResult = ReturnType<typeof useClaimAccountAssignmentInsertMutation>;
export type ClaimAccountAssignmentInsertMutationResult = ApolloReactCommon.MutationResult<ClaimAccountAssignmentInsertMutation>;
export type ClaimAccountAssignmentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimAccountAssignmentInsertMutation, ClaimAccountAssignmentInsertMutationVariables>;
export const ClaimAccountAssignmentUpdateDocument = gql`
    mutation ClaimAccountAssignmentUpdate($HeaderInput: ClaimAccountAssignmentInput!) {
  ClaimAccountAssignmentUpdate(HeaderInput: $HeaderInput)
}
    `;
export type ClaimAccountAssignmentUpdateMutationFn = ApolloReactCommon.MutationFunction<ClaimAccountAssignmentUpdateMutation, ClaimAccountAssignmentUpdateMutationVariables>;

/**
 * __useClaimAccountAssignmentUpdateMutation__
 *
 * To run a mutation, you first call `useClaimAccountAssignmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimAccountAssignmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimAccountAssignmentUpdateMutation, { data, loading, error }] = useClaimAccountAssignmentUpdateMutation({
 *   variables: {
 *      HeaderInput: // value for 'HeaderInput'
 *   },
 * });
 */
export function useClaimAccountAssignmentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimAccountAssignmentUpdateMutation, ClaimAccountAssignmentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimAccountAssignmentUpdateMutation, ClaimAccountAssignmentUpdateMutationVariables>(ClaimAccountAssignmentUpdateDocument, baseOptions);
      }
export type ClaimAccountAssignmentUpdateMutationHookResult = ReturnType<typeof useClaimAccountAssignmentUpdateMutation>;
export type ClaimAccountAssignmentUpdateMutationResult = ApolloReactCommon.MutationResult<ClaimAccountAssignmentUpdateMutation>;
export type ClaimAccountAssignmentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimAccountAssignmentUpdateMutation, ClaimAccountAssignmentUpdateMutationVariables>;
export const GetClaimAccountAssignmentDocument = gql`
    query GetClaimAccountAssignment($CompanyID: String!) {
  GetClaimAccountAssignment(CompanyID: $CompanyID) {
    ID
    CompanyID
    ClaimAccountHeaderID
    ClaimAccountHeader {
      Title
    }
  }
}
    `;

/**
 * __useGetClaimAccountAssignmentQuery__
 *
 * To run a query within a React component, call `useGetClaimAccountAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimAccountAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimAccountAssignmentQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetClaimAccountAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimAccountAssignmentQuery, GetClaimAccountAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimAccountAssignmentQuery, GetClaimAccountAssignmentQueryVariables>(GetClaimAccountAssignmentDocument, baseOptions);
      }
export function useGetClaimAccountAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimAccountAssignmentQuery, GetClaimAccountAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimAccountAssignmentQuery, GetClaimAccountAssignmentQueryVariables>(GetClaimAccountAssignmentDocument, baseOptions);
        }
export type GetClaimAccountAssignmentQueryHookResult = ReturnType<typeof useGetClaimAccountAssignmentQuery>;
export type GetClaimAccountAssignmentLazyQueryHookResult = ReturnType<typeof useGetClaimAccountAssignmentLazyQuery>;
export type GetClaimAccountAssignmentQueryResult = ApolloReactCommon.QueryResult<GetClaimAccountAssignmentQuery, GetClaimAccountAssignmentQueryVariables>;
export const GetGlClaimRecordsListingDocument = gql`
    query GetGLClaimRecordsListing($input: ClaimRecordsInput!) {
  GetGLClaimRecordsListing(input: $input)
}
    `;

/**
 * __useGetGlClaimRecordsListingQuery__
 *
 * To run a query within a React component, call `useGetGlClaimRecordsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlClaimRecordsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlClaimRecordsListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGlClaimRecordsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlClaimRecordsListingQuery, GetGlClaimRecordsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlClaimRecordsListingQuery, GetGlClaimRecordsListingQueryVariables>(GetGlClaimRecordsListingDocument, baseOptions);
      }
export function useGetGlClaimRecordsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlClaimRecordsListingQuery, GetGlClaimRecordsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlClaimRecordsListingQuery, GetGlClaimRecordsListingQueryVariables>(GetGlClaimRecordsListingDocument, baseOptions);
        }
export type GetGlClaimRecordsListingQueryHookResult = ReturnType<typeof useGetGlClaimRecordsListingQuery>;
export type GetGlClaimRecordsListingLazyQueryHookResult = ReturnType<typeof useGetGlClaimRecordsListingLazyQuery>;
export type GetGlClaimRecordsListingQueryResult = ApolloReactCommon.QueryResult<GetGlClaimRecordsListingQuery, GetGlClaimRecordsListingQueryVariables>;
export const ClaimGlCycleProcessDocument = gql`
    query ClaimGLCycleProcess($input: ClaimRecordsInput!, $Type: String!) {
  ClaimGLCycleProcess(input: $input, Type: $Type)
}
    `;

/**
 * __useClaimGlCycleProcessQuery__
 *
 * To run a query within a React component, call `useClaimGlCycleProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimGlCycleProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimGlCycleProcessQuery({
 *   variables: {
 *      input: // value for 'input'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useClaimGlCycleProcessQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimGlCycleProcessQuery, ClaimGlCycleProcessQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimGlCycleProcessQuery, ClaimGlCycleProcessQueryVariables>(ClaimGlCycleProcessDocument, baseOptions);
      }
export function useClaimGlCycleProcessLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimGlCycleProcessQuery, ClaimGlCycleProcessQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimGlCycleProcessQuery, ClaimGlCycleProcessQueryVariables>(ClaimGlCycleProcessDocument, baseOptions);
        }
export type ClaimGlCycleProcessQueryHookResult = ReturnType<typeof useClaimGlCycleProcessQuery>;
export type ClaimGlCycleProcessLazyQueryHookResult = ReturnType<typeof useClaimGlCycleProcessLazyQuery>;
export type ClaimGlCycleProcessQueryResult = ApolloReactCommon.QueryResult<ClaimGlCycleProcessQuery, ClaimGlCycleProcessQueryVariables>;
export const GetClaimEntitlementByClaimTypeListingDocument = gql`
    query GetClaimEntitlementByClaimTypeListing($CompanyID: String!, $ClaimTypeID: String!, $toDate: DateTime!, $DepartmentIDs: [String!]!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  GetClaimEntitlementByClaimTypeListing(CompanyID: $CompanyID, ClaimTypeID: $ClaimTypeID, toDate: $toDate, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID) {
    JobGradeID
    OrgUnitID
    FormStatus
    EmployeeID
    EmployeeNo
    EmployeeName
    Summary {
      ClaimType
      ClaimFrequency
      Entitled
      Draft
      Pending
      Claimed
      Balance
    }
  }
}
    `;

/**
 * __useGetClaimEntitlementByClaimTypeListingQuery__
 *
 * To run a query within a React component, call `useGetClaimEntitlementByClaimTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimEntitlementByClaimTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimEntitlementByClaimTypeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ClaimTypeID: // value for 'ClaimTypeID'
 *      toDate: // value for 'toDate'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useGetClaimEntitlementByClaimTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimEntitlementByClaimTypeListingQuery, GetClaimEntitlementByClaimTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimEntitlementByClaimTypeListingQuery, GetClaimEntitlementByClaimTypeListingQueryVariables>(GetClaimEntitlementByClaimTypeListingDocument, baseOptions);
      }
export function useGetClaimEntitlementByClaimTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimEntitlementByClaimTypeListingQuery, GetClaimEntitlementByClaimTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimEntitlementByClaimTypeListingQuery, GetClaimEntitlementByClaimTypeListingQueryVariables>(GetClaimEntitlementByClaimTypeListingDocument, baseOptions);
        }
export type GetClaimEntitlementByClaimTypeListingQueryHookResult = ReturnType<typeof useGetClaimEntitlementByClaimTypeListingQuery>;
export type GetClaimEntitlementByClaimTypeListingLazyQueryHookResult = ReturnType<typeof useGetClaimEntitlementByClaimTypeListingLazyQuery>;
export type GetClaimEntitlementByClaimTypeListingQueryResult = ApolloReactCommon.QueryResult<GetClaimEntitlementByClaimTypeListingQuery, GetClaimEntitlementByClaimTypeListingQueryVariables>;
export const GetClaimEntitlementByEmployeeListingDocument = gql`
    query GetClaimEntitlementByEmployeeListing($CompanyID: String!, $EmployeeIDs: [String!]!, $AsToDate: DateTime!) {
  GetClaimEntitlementByEmployeeListing(CompanyID: $CompanyID, EmployeeIDs: $EmployeeIDs, AsToDate: $AsToDate) {
    EmployeeName
    Summarries {
      ClaimType
      ClaimFrequency
      Entitled
      Draft
      Pending
      Claimed
      Balance
    }
  }
}
    `;

/**
 * __useGetClaimEntitlementByEmployeeListingQuery__
 *
 * To run a query within a React component, call `useGetClaimEntitlementByEmployeeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimEntitlementByEmployeeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimEntitlementByEmployeeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      AsToDate: // value for 'AsToDate'
 *   },
 * });
 */
export function useGetClaimEntitlementByEmployeeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimEntitlementByEmployeeListingQuery, GetClaimEntitlementByEmployeeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimEntitlementByEmployeeListingQuery, GetClaimEntitlementByEmployeeListingQueryVariables>(GetClaimEntitlementByEmployeeListingDocument, baseOptions);
      }
export function useGetClaimEntitlementByEmployeeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimEntitlementByEmployeeListingQuery, GetClaimEntitlementByEmployeeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimEntitlementByEmployeeListingQuery, GetClaimEntitlementByEmployeeListingQueryVariables>(GetClaimEntitlementByEmployeeListingDocument, baseOptions);
        }
export type GetClaimEntitlementByEmployeeListingQueryHookResult = ReturnType<typeof useGetClaimEntitlementByEmployeeListingQuery>;
export type GetClaimEntitlementByEmployeeListingLazyQueryHookResult = ReturnType<typeof useGetClaimEntitlementByEmployeeListingLazyQuery>;
export type GetClaimEntitlementByEmployeeListingQueryResult = ApolloReactCommon.QueryResult<GetClaimEntitlementByEmployeeListingQuery, GetClaimEntitlementByEmployeeListingQueryVariables>;
export const ClaimTypeListingAllDocument = gql`
    query ClaimTypeListingAll($SubscriptionAccountID: String!) {
  ClaimTypeListingAll(SubscriptionAccountID: $SubscriptionAccountID) {
    ClaimTypeID
    SubscriptionAccountID
    Name
    ClaimCategory
  }
}
    `;

/**
 * __useClaimTypeListingAllQuery__
 *
 * To run a query within a React component, call `useClaimTypeListingAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeListingAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimTypeListingAllQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useClaimTypeListingAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimTypeListingAllQuery, ClaimTypeListingAllQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimTypeListingAllQuery, ClaimTypeListingAllQueryVariables>(ClaimTypeListingAllDocument, baseOptions);
      }
export function useClaimTypeListingAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimTypeListingAllQuery, ClaimTypeListingAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimTypeListingAllQuery, ClaimTypeListingAllQueryVariables>(ClaimTypeListingAllDocument, baseOptions);
        }
export type ClaimTypeListingAllQueryHookResult = ReturnType<typeof useClaimTypeListingAllQuery>;
export type ClaimTypeListingAllLazyQueryHookResult = ReturnType<typeof useClaimTypeListingAllLazyQuery>;
export type ClaimTypeListingAllQueryResult = ApolloReactCommon.QueryResult<ClaimTypeListingAllQuery, ClaimTypeListingAllQueryVariables>;
export const ClaimPolicyListingAllDocument = gql`
    query ClaimPolicyListingAll($SubscriptionAccountID: String!) {
  ClaimPolicyListingAll(SubscriptionAccountID: $SubscriptionAccountID) {
    ClaimBenefitID
    SubscriptionAccountID
    Name
    Status
    ClaimItems {
      ClaimType {
        ClaimTypeID
        Name
        ClaimCategory
      }
    }
  }
}
    `;

/**
 * __useClaimPolicyListingAllQuery__
 *
 * To run a query within a React component, call `useClaimPolicyListingAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimPolicyListingAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimPolicyListingAllQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useClaimPolicyListingAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimPolicyListingAllQuery, ClaimPolicyListingAllQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimPolicyListingAllQuery, ClaimPolicyListingAllQueryVariables>(ClaimPolicyListingAllDocument, baseOptions);
      }
export function useClaimPolicyListingAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimPolicyListingAllQuery, ClaimPolicyListingAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimPolicyListingAllQuery, ClaimPolicyListingAllQueryVariables>(ClaimPolicyListingAllDocument, baseOptions);
        }
export type ClaimPolicyListingAllQueryHookResult = ReturnType<typeof useClaimPolicyListingAllQuery>;
export type ClaimPolicyListingAllLazyQueryHookResult = ReturnType<typeof useClaimPolicyListingAllLazyQuery>;
export type ClaimPolicyListingAllQueryResult = ApolloReactCommon.QueryResult<ClaimPolicyListingAllQuery, ClaimPolicyListingAllQueryVariables>;
export const ClaimParameterListingAllDocument = gql`
    query ClaimParameterListingAll($ClaimParameterType: String!) {
  ClaimParameterListingAll(ClaimParameterType: $ClaimParameterType) {
    ClaimParameterID
    CompanyID
    Name
    ClaimParameterType
  }
}
    `;

/**
 * __useClaimParameterListingAllQuery__
 *
 * To run a query within a React component, call `useClaimParameterListingAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimParameterListingAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimParameterListingAllQuery({
 *   variables: {
 *      ClaimParameterType: // value for 'ClaimParameterType'
 *   },
 * });
 */
export function useClaimParameterListingAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimParameterListingAllQuery, ClaimParameterListingAllQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimParameterListingAllQuery, ClaimParameterListingAllQueryVariables>(ClaimParameterListingAllDocument, baseOptions);
      }
export function useClaimParameterListingAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimParameterListingAllQuery, ClaimParameterListingAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimParameterListingAllQuery, ClaimParameterListingAllQueryVariables>(ClaimParameterListingAllDocument, baseOptions);
        }
export type ClaimParameterListingAllQueryHookResult = ReturnType<typeof useClaimParameterListingAllQuery>;
export type ClaimParameterListingAllLazyQueryHookResult = ReturnType<typeof useClaimParameterListingAllLazyQuery>;
export type ClaimParameterListingAllQueryResult = ApolloReactCommon.QueryResult<ClaimParameterListingAllQuery, ClaimParameterListingAllQueryVariables>;
export const ClaimUserDefineFieldsListingDocument = gql`
    query ClaimUserDefineFieldsListing($ClaimParameterID: String!) {
  ClaimUserDefineFieldsListing(ClaimParameterID: $ClaimParameterID) {
    ClaimParameterID
    ClaimUserDefineFieldsID
    SubscriptionAccountID
    IsCompulsory
    IsActive
    FieldLabel
    FieldType
    Values
  }
}
    `;

/**
 * __useClaimUserDefineFieldsListingQuery__
 *
 * To run a query within a React component, call `useClaimUserDefineFieldsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimUserDefineFieldsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimUserDefineFieldsListingQuery({
 *   variables: {
 *      ClaimParameterID: // value for 'ClaimParameterID'
 *   },
 * });
 */
export function useClaimUserDefineFieldsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimUserDefineFieldsListingQuery, ClaimUserDefineFieldsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimUserDefineFieldsListingQuery, ClaimUserDefineFieldsListingQueryVariables>(ClaimUserDefineFieldsListingDocument, baseOptions);
      }
export function useClaimUserDefineFieldsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimUserDefineFieldsListingQuery, ClaimUserDefineFieldsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimUserDefineFieldsListingQuery, ClaimUserDefineFieldsListingQueryVariables>(ClaimUserDefineFieldsListingDocument, baseOptions);
        }
export type ClaimUserDefineFieldsListingQueryHookResult = ReturnType<typeof useClaimUserDefineFieldsListingQuery>;
export type ClaimUserDefineFieldsListingLazyQueryHookResult = ReturnType<typeof useClaimUserDefineFieldsListingLazyQuery>;
export type ClaimUserDefineFieldsListingQueryResult = ApolloReactCommon.QueryResult<ClaimUserDefineFieldsListingQuery, ClaimUserDefineFieldsListingQueryVariables>;
export const ClaimParameterDeleteDocument = gql`
    mutation ClaimParameterDelete($ClaimParameterID: String!) {
  ClaimParameterDelete(ClaimParameterID: $ClaimParameterID)
}
    `;
export type ClaimParameterDeleteMutationFn = ApolloReactCommon.MutationFunction<ClaimParameterDeleteMutation, ClaimParameterDeleteMutationVariables>;

/**
 * __useClaimParameterDeleteMutation__
 *
 * To run a mutation, you first call `useClaimParameterDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimParameterDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimParameterDeleteMutation, { data, loading, error }] = useClaimParameterDeleteMutation({
 *   variables: {
 *      ClaimParameterID: // value for 'ClaimParameterID'
 *   },
 * });
 */
export function useClaimParameterDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimParameterDeleteMutation, ClaimParameterDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimParameterDeleteMutation, ClaimParameterDeleteMutationVariables>(ClaimParameterDeleteDocument, baseOptions);
      }
export type ClaimParameterDeleteMutationHookResult = ReturnType<typeof useClaimParameterDeleteMutation>;
export type ClaimParameterDeleteMutationResult = ApolloReactCommon.MutationResult<ClaimParameterDeleteMutation>;
export type ClaimParameterDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimParameterDeleteMutation, ClaimParameterDeleteMutationVariables>;
export const ClaimParameterInsertDocument = gql`
    mutation ClaimParameterInsert($input: ClaimParameterInput!) {
  ClaimParameterInsert(input: $input) {
    ClaimParameterID
  }
}
    `;
export type ClaimParameterInsertMutationFn = ApolloReactCommon.MutationFunction<ClaimParameterInsertMutation, ClaimParameterInsertMutationVariables>;

/**
 * __useClaimParameterInsertMutation__
 *
 * To run a mutation, you first call `useClaimParameterInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimParameterInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimParameterInsertMutation, { data, loading, error }] = useClaimParameterInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimParameterInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimParameterInsertMutation, ClaimParameterInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimParameterInsertMutation, ClaimParameterInsertMutationVariables>(ClaimParameterInsertDocument, baseOptions);
      }
export type ClaimParameterInsertMutationHookResult = ReturnType<typeof useClaimParameterInsertMutation>;
export type ClaimParameterInsertMutationResult = ApolloReactCommon.MutationResult<ClaimParameterInsertMutation>;
export type ClaimParameterInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimParameterInsertMutation, ClaimParameterInsertMutationVariables>;
export const ClaimParameterUpdateDocument = gql`
    mutation ClaimParameterUpdate($input: ClaimParameterInput!) {
  ClaimParameterUpdate(input: $input)
}
    `;
export type ClaimParameterUpdateMutationFn = ApolloReactCommon.MutationFunction<ClaimParameterUpdateMutation, ClaimParameterUpdateMutationVariables>;

/**
 * __useClaimParameterUpdateMutation__
 *
 * To run a mutation, you first call `useClaimParameterUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimParameterUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimParameterUpdateMutation, { data, loading, error }] = useClaimParameterUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimParameterUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimParameterUpdateMutation, ClaimParameterUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimParameterUpdateMutation, ClaimParameterUpdateMutationVariables>(ClaimParameterUpdateDocument, baseOptions);
      }
export type ClaimParameterUpdateMutationHookResult = ReturnType<typeof useClaimParameterUpdateMutation>;
export type ClaimParameterUpdateMutationResult = ApolloReactCommon.MutationResult<ClaimParameterUpdateMutation>;
export type ClaimParameterUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimParameterUpdateMutation, ClaimParameterUpdateMutationVariables>;
export const MileageTableDeleteDocument = gql`
    mutation MileageTableDelete($ClaimParameterID: String!) {
  MileageTableDelete(ClaimParameterID: $ClaimParameterID)
}
    `;
export type MileageTableDeleteMutationFn = ApolloReactCommon.MutationFunction<MileageTableDeleteMutation, MileageTableDeleteMutationVariables>;

/**
 * __useMileageTableDeleteMutation__
 *
 * To run a mutation, you first call `useMileageTableDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMileageTableDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mileageTableDeleteMutation, { data, loading, error }] = useMileageTableDeleteMutation({
 *   variables: {
 *      ClaimParameterID: // value for 'ClaimParameterID'
 *   },
 * });
 */
export function useMileageTableDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MileageTableDeleteMutation, MileageTableDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<MileageTableDeleteMutation, MileageTableDeleteMutationVariables>(MileageTableDeleteDocument, baseOptions);
      }
export type MileageTableDeleteMutationHookResult = ReturnType<typeof useMileageTableDeleteMutation>;
export type MileageTableDeleteMutationResult = ApolloReactCommon.MutationResult<MileageTableDeleteMutation>;
export type MileageTableDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<MileageTableDeleteMutation, MileageTableDeleteMutationVariables>;
export const ClaimMileageLocationListingDocument = gql`
    query ClaimMileageLocationListing($ClaimParameterType: String!) {
  ClaimParameterListing(ClaimParameterType: $ClaimParameterType) {
    ClaimParameterID
    Name
    ClaimParameterType
    ModifiedBy
    ModifiedDT
    CreatedBy
    CreatedDT
    Status
    StartTime
    EndTime
    Details {
      ClaimParameterID
      ClaimMileageTableID
      TravelFrom
      TravelTo
      Route
      Distance
    }
  }
}
    `;

/**
 * __useClaimMileageLocationListingQuery__
 *
 * To run a query within a React component, call `useClaimMileageLocationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimMileageLocationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimMileageLocationListingQuery({
 *   variables: {
 *      ClaimParameterType: // value for 'ClaimParameterType'
 *   },
 * });
 */
export function useClaimMileageLocationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimMileageLocationListingQuery, ClaimMileageLocationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimMileageLocationListingQuery, ClaimMileageLocationListingQueryVariables>(ClaimMileageLocationListingDocument, baseOptions);
      }
export function useClaimMileageLocationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimMileageLocationListingQuery, ClaimMileageLocationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimMileageLocationListingQuery, ClaimMileageLocationListingQueryVariables>(ClaimMileageLocationListingDocument, baseOptions);
        }
export type ClaimMileageLocationListingQueryHookResult = ReturnType<typeof useClaimMileageLocationListingQuery>;
export type ClaimMileageLocationListingLazyQueryHookResult = ReturnType<typeof useClaimMileageLocationListingLazyQuery>;
export type ClaimMileageLocationListingQueryResult = ApolloReactCommon.QueryResult<ClaimMileageLocationListingQuery, ClaimMileageLocationListingQueryVariables>;
export const MileageTableInsertDocument = gql`
    mutation MileageTableInsert($input: ClaimParameterInput!, $ClaimMileageTableInput: [ClaimMileageTableInput!]!) {
  MileageTableInsert(input: $input, ClaimMileageTableInput: $ClaimMileageTableInput)
}
    `;
export type MileageTableInsertMutationFn = ApolloReactCommon.MutationFunction<MileageTableInsertMutation, MileageTableInsertMutationVariables>;

/**
 * __useMileageTableInsertMutation__
 *
 * To run a mutation, you first call `useMileageTableInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMileageTableInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mileageTableInsertMutation, { data, loading, error }] = useMileageTableInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ClaimMileageTableInput: // value for 'ClaimMileageTableInput'
 *   },
 * });
 */
export function useMileageTableInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MileageTableInsertMutation, MileageTableInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<MileageTableInsertMutation, MileageTableInsertMutationVariables>(MileageTableInsertDocument, baseOptions);
      }
export type MileageTableInsertMutationHookResult = ReturnType<typeof useMileageTableInsertMutation>;
export type MileageTableInsertMutationResult = ApolloReactCommon.MutationResult<MileageTableInsertMutation>;
export type MileageTableInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<MileageTableInsertMutation, MileageTableInsertMutationVariables>;
export const MileageTableUpdateDocument = gql`
    mutation MileageTableUpdate($ClaimParameterID: String!, $input: ClaimParameterInput!, $ClaimMileageTableInput: [ClaimMileageTableInput!]!) {
  MileageTableUpdate(ClaimParameterID: $ClaimParameterID, input: $input, ClaimMileageTableInput: $ClaimMileageTableInput)
}
    `;
export type MileageTableUpdateMutationFn = ApolloReactCommon.MutationFunction<MileageTableUpdateMutation, MileageTableUpdateMutationVariables>;

/**
 * __useMileageTableUpdateMutation__
 *
 * To run a mutation, you first call `useMileageTableUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMileageTableUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mileageTableUpdateMutation, { data, loading, error }] = useMileageTableUpdateMutation({
 *   variables: {
 *      ClaimParameterID: // value for 'ClaimParameterID'
 *      input: // value for 'input'
 *      ClaimMileageTableInput: // value for 'ClaimMileageTableInput'
 *   },
 * });
 */
export function useMileageTableUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MileageTableUpdateMutation, MileageTableUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<MileageTableUpdateMutation, MileageTableUpdateMutationVariables>(MileageTableUpdateDocument, baseOptions);
      }
export type MileageTableUpdateMutationHookResult = ReturnType<typeof useMileageTableUpdateMutation>;
export type MileageTableUpdateMutationResult = ApolloReactCommon.MutationResult<MileageTableUpdateMutation>;
export type MileageTableUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<MileageTableUpdateMutation, MileageTableUpdateMutationVariables>;
export const ClaimUserDefineFieldsInsertDocument = gql`
    mutation ClaimUserDefineFieldsInsert($input: ClaimParameterInput!, $ClaimUserDefineFieldsInput: [ClaimUserDefineFieldsInput!]!) {
  ClaimUserDefineFieldsInsert(input: $input, ClaimUserDefineFieldsInput: $ClaimUserDefineFieldsInput)
}
    `;
export type ClaimUserDefineFieldsInsertMutationFn = ApolloReactCommon.MutationFunction<ClaimUserDefineFieldsInsertMutation, ClaimUserDefineFieldsInsertMutationVariables>;

/**
 * __useClaimUserDefineFieldsInsertMutation__
 *
 * To run a mutation, you first call `useClaimUserDefineFieldsInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimUserDefineFieldsInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimUserDefineFieldsInsertMutation, { data, loading, error }] = useClaimUserDefineFieldsInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ClaimUserDefineFieldsInput: // value for 'ClaimUserDefineFieldsInput'
 *   },
 * });
 */
export function useClaimUserDefineFieldsInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimUserDefineFieldsInsertMutation, ClaimUserDefineFieldsInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimUserDefineFieldsInsertMutation, ClaimUserDefineFieldsInsertMutationVariables>(ClaimUserDefineFieldsInsertDocument, baseOptions);
      }
export type ClaimUserDefineFieldsInsertMutationHookResult = ReturnType<typeof useClaimUserDefineFieldsInsertMutation>;
export type ClaimUserDefineFieldsInsertMutationResult = ApolloReactCommon.MutationResult<ClaimUserDefineFieldsInsertMutation>;
export type ClaimUserDefineFieldsInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimUserDefineFieldsInsertMutation, ClaimUserDefineFieldsInsertMutationVariables>;
export const ClaimUserDefineFieldsUpdateDocument = gql`
    mutation ClaimUserDefineFieldsUpdate($ClaimParameterID: String!, $input: ClaimParameterInput!, $ClaimUserDefineFieldsInput: [ClaimUserDefineFieldsInput!]!) {
  ClaimUserDefineFieldsUpdate(ClaimParameterID: $ClaimParameterID, input: $input, ClaimUserDefineFieldsInput: $ClaimUserDefineFieldsInput)
}
    `;
export type ClaimUserDefineFieldsUpdateMutationFn = ApolloReactCommon.MutationFunction<ClaimUserDefineFieldsUpdateMutation, ClaimUserDefineFieldsUpdateMutationVariables>;

/**
 * __useClaimUserDefineFieldsUpdateMutation__
 *
 * To run a mutation, you first call `useClaimUserDefineFieldsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimUserDefineFieldsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimUserDefineFieldsUpdateMutation, { data, loading, error }] = useClaimUserDefineFieldsUpdateMutation({
 *   variables: {
 *      ClaimParameterID: // value for 'ClaimParameterID'
 *      input: // value for 'input'
 *      ClaimUserDefineFieldsInput: // value for 'ClaimUserDefineFieldsInput'
 *   },
 * });
 */
export function useClaimUserDefineFieldsUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimUserDefineFieldsUpdateMutation, ClaimUserDefineFieldsUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimUserDefineFieldsUpdateMutation, ClaimUserDefineFieldsUpdateMutationVariables>(ClaimUserDefineFieldsUpdateDocument, baseOptions);
      }
export type ClaimUserDefineFieldsUpdateMutationHookResult = ReturnType<typeof useClaimUserDefineFieldsUpdateMutation>;
export type ClaimUserDefineFieldsUpdateMutationResult = ApolloReactCommon.MutationResult<ClaimUserDefineFieldsUpdateMutation>;
export type ClaimUserDefineFieldsUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimUserDefineFieldsUpdateMutation, ClaimUserDefineFieldsUpdateMutationVariables>;
export const ClaimUserDefineFieldsDuplicateDocument = gql`
    mutation ClaimUserDefineFieldsDuplicate($ClaimParameterID: String!, $input: ClaimParameterInput!) {
  ClaimUserDefineFieldsDuplicate(ClaimParameterID: $ClaimParameterID, input: $input)
}
    `;
export type ClaimUserDefineFieldsDuplicateMutationFn = ApolloReactCommon.MutationFunction<ClaimUserDefineFieldsDuplicateMutation, ClaimUserDefineFieldsDuplicateMutationVariables>;

/**
 * __useClaimUserDefineFieldsDuplicateMutation__
 *
 * To run a mutation, you first call `useClaimUserDefineFieldsDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimUserDefineFieldsDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimUserDefineFieldsDuplicateMutation, { data, loading, error }] = useClaimUserDefineFieldsDuplicateMutation({
 *   variables: {
 *      ClaimParameterID: // value for 'ClaimParameterID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimUserDefineFieldsDuplicateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimUserDefineFieldsDuplicateMutation, ClaimUserDefineFieldsDuplicateMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimUserDefineFieldsDuplicateMutation, ClaimUserDefineFieldsDuplicateMutationVariables>(ClaimUserDefineFieldsDuplicateDocument, baseOptions);
      }
export type ClaimUserDefineFieldsDuplicateMutationHookResult = ReturnType<typeof useClaimUserDefineFieldsDuplicateMutation>;
export type ClaimUserDefineFieldsDuplicateMutationResult = ApolloReactCommon.MutationResult<ClaimUserDefineFieldsDuplicateMutation>;
export type ClaimUserDefineFieldsDuplicateMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimUserDefineFieldsDuplicateMutation, ClaimUserDefineFieldsDuplicateMutationVariables>;
export const ClaimTypePayItemListingDocument = gql`
    query ClaimTypePayItemListing($SubscriptionAccountID: String!) {
  ClaimTypePayItemListing(SubscriptionAccountID: $SubscriptionAccountID) {
    PayItemID
    CompanyID
    PayCode
    PayName
    PayItemType
  }
}
    `;

/**
 * __useClaimTypePayItemListingQuery__
 *
 * To run a query within a React component, call `useClaimTypePayItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimTypePayItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimTypePayItemListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useClaimTypePayItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimTypePayItemListingQuery, ClaimTypePayItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimTypePayItemListingQuery, ClaimTypePayItemListingQueryVariables>(ClaimTypePayItemListingDocument, baseOptions);
      }
export function useClaimTypePayItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimTypePayItemListingQuery, ClaimTypePayItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimTypePayItemListingQuery, ClaimTypePayItemListingQueryVariables>(ClaimTypePayItemListingDocument, baseOptions);
        }
export type ClaimTypePayItemListingQueryHookResult = ReturnType<typeof useClaimTypePayItemListingQuery>;
export type ClaimTypePayItemListingLazyQueryHookResult = ReturnType<typeof useClaimTypePayItemListingLazyQuery>;
export type ClaimTypePayItemListingQueryResult = ApolloReactCommon.QueryResult<ClaimTypePayItemListingQuery, ClaimTypePayItemListingQueryVariables>;
export const ClaimTypeInsertDocument = gql`
    mutation ClaimTypeInsert($input: ClaimTypeInput!) {
  ClaimTypeInsert(input: $input) {
    ClaimTypeID
  }
}
    `;
export type ClaimTypeInsertMutationFn = ApolloReactCommon.MutationFunction<ClaimTypeInsertMutation, ClaimTypeInsertMutationVariables>;

/**
 * __useClaimTypeInsertMutation__
 *
 * To run a mutation, you first call `useClaimTypeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimTypeInsertMutation, { data, loading, error }] = useClaimTypeInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimTypeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimTypeInsertMutation, ClaimTypeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimTypeInsertMutation, ClaimTypeInsertMutationVariables>(ClaimTypeInsertDocument, baseOptions);
      }
export type ClaimTypeInsertMutationHookResult = ReturnType<typeof useClaimTypeInsertMutation>;
export type ClaimTypeInsertMutationResult = ApolloReactCommon.MutationResult<ClaimTypeInsertMutation>;
export type ClaimTypeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimTypeInsertMutation, ClaimTypeInsertMutationVariables>;
export const ClaimTypeUpdateDocument = gql`
    mutation ClaimTypeUpdate($input: ClaimTypeInput!, $ClaimTypeID: String!) {
  ClaimTypeUpdate(input: $input, ClaimTypeID: $ClaimTypeID)
}
    `;
export type ClaimTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<ClaimTypeUpdateMutation, ClaimTypeUpdateMutationVariables>;

/**
 * __useClaimTypeUpdateMutation__
 *
 * To run a mutation, you first call `useClaimTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimTypeUpdateMutation, { data, loading, error }] = useClaimTypeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ClaimTypeID: // value for 'ClaimTypeID'
 *   },
 * });
 */
export function useClaimTypeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimTypeUpdateMutation, ClaimTypeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimTypeUpdateMutation, ClaimTypeUpdateMutationVariables>(ClaimTypeUpdateDocument, baseOptions);
      }
export type ClaimTypeUpdateMutationHookResult = ReturnType<typeof useClaimTypeUpdateMutation>;
export type ClaimTypeUpdateMutationResult = ApolloReactCommon.MutationResult<ClaimTypeUpdateMutation>;
export type ClaimTypeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimTypeUpdateMutation, ClaimTypeUpdateMutationVariables>;
export const ClaimTypeDeleteDocument = gql`
    mutation ClaimTypeDelete($ClaimTypeID: String!) {
  ClaimTypeDelete(ClaimTypeID: $ClaimTypeID)
}
    `;
export type ClaimTypeDeleteMutationFn = ApolloReactCommon.MutationFunction<ClaimTypeDeleteMutation, ClaimTypeDeleteMutationVariables>;

/**
 * __useClaimTypeDeleteMutation__
 *
 * To run a mutation, you first call `useClaimTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimTypeDeleteMutation, { data, loading, error }] = useClaimTypeDeleteMutation({
 *   variables: {
 *      ClaimTypeID: // value for 'ClaimTypeID'
 *   },
 * });
 */
export function useClaimTypeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimTypeDeleteMutation, ClaimTypeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimTypeDeleteMutation, ClaimTypeDeleteMutationVariables>(ClaimTypeDeleteDocument, baseOptions);
      }
export type ClaimTypeDeleteMutationHookResult = ReturnType<typeof useClaimTypeDeleteMutation>;
export type ClaimTypeDeleteMutationResult = ApolloReactCommon.MutationResult<ClaimTypeDeleteMutation>;
export type ClaimTypeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimTypeDeleteMutation, ClaimTypeDeleteMutationVariables>;
export const ClaimTypeTemplateDeleteDocument = gql`
    mutation ClaimTypeTemplateDelete($ClaimBenefitID: String!) {
  ClaimTypeTemplateDelete(ClaimBenefitID: $ClaimBenefitID)
}
    `;
export type ClaimTypeTemplateDeleteMutationFn = ApolloReactCommon.MutationFunction<ClaimTypeTemplateDeleteMutation, ClaimTypeTemplateDeleteMutationVariables>;

/**
 * __useClaimTypeTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useClaimTypeTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimTypeTemplateDeleteMutation, { data, loading, error }] = useClaimTypeTemplateDeleteMutation({
 *   variables: {
 *      ClaimBenefitID: // value for 'ClaimBenefitID'
 *   },
 * });
 */
export function useClaimTypeTemplateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimTypeTemplateDeleteMutation, ClaimTypeTemplateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimTypeTemplateDeleteMutation, ClaimTypeTemplateDeleteMutationVariables>(ClaimTypeTemplateDeleteDocument, baseOptions);
      }
export type ClaimTypeTemplateDeleteMutationHookResult = ReturnType<typeof useClaimTypeTemplateDeleteMutation>;
export type ClaimTypeTemplateDeleteMutationResult = ApolloReactCommon.MutationResult<ClaimTypeTemplateDeleteMutation>;
export type ClaimTypeTemplateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimTypeTemplateDeleteMutation, ClaimTypeTemplateDeleteMutationVariables>;
export const ClaimPolicyListingDocument = gql`
    query ClaimPolicyListing($SubscriptionAccountID: String!, $ClaimCategory: ClaimCategory!) {
  ClaimPolicyListing(SubscriptionAccountID: $SubscriptionAccountID, ClaimCategory: $ClaimCategory) {
    ClaimBenefitID
    SubscriptionAccountID
    Name
    Description
    Status
    ClaimItems {
      ClaimItemID
      ClaimBenefitID
      ClaimTypeID
      ClaimFrequency
      TotalAmt
      Occurrence
      ClaimType {
        Name
        ClaimTypeID
      }
      ClaimTemplates {
        ClaimPolicyID
        ClaimTemplateID
      }
      OccurrenceLimit
      MileageTableID
      IsMileageTable
      IsLimit
      IsRemarks
      IsAttachment
      DailyFrequency
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      MileageTable {
        Name
      }
      MileageTableName
    }
    RateDetails {
      ClaimBenefitID
      ClaimDailyRateID
      ClaimParameterID
      Rate
      VenueName
    }
    VehicleRateDetails {
      ClaimVehicleRateID
      ClaimParameterID
      ClaimBenefitID
      StartValue
      EndValue
      Rate
      Status
      VehicleName
    }
    MealRateDetails {
      ClaimMealRateID
      ClaimParameterID
      VenueID
      ClaimBenefitID
      Rate
      Status
      MealType
      VenueName
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    InUse
  }
}
    `;

/**
 * __useClaimPolicyListingQuery__
 *
 * To run a query within a React component, call `useClaimPolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimPolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimPolicyListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      ClaimCategory: // value for 'ClaimCategory'
 *   },
 * });
 */
export function useClaimPolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimPolicyListingQuery, ClaimPolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimPolicyListingQuery, ClaimPolicyListingQueryVariables>(ClaimPolicyListingDocument, baseOptions);
      }
export function useClaimPolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimPolicyListingQuery, ClaimPolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimPolicyListingQuery, ClaimPolicyListingQueryVariables>(ClaimPolicyListingDocument, baseOptions);
        }
export type ClaimPolicyListingQueryHookResult = ReturnType<typeof useClaimPolicyListingQuery>;
export type ClaimPolicyListingLazyQueryHookResult = ReturnType<typeof useClaimPolicyListingLazyQuery>;
export type ClaimPolicyListingQueryResult = ApolloReactCommon.QueryResult<ClaimPolicyListingQuery, ClaimPolicyListingQueryVariables>;
export const ClaimTypeCompanyListingDocument = gql`
    query ClaimTypeCompanyListing($SubscriptionAccountID: String!) {
  ClaimTypeListing(SubscriptionAccountID: $SubscriptionAccountID) {
    ClaimTypeID
    SubscriptionAccountID
    Name
    IsAttachment
    IsRemarks
    IsReceiptNo
    IsDisplay
    ClaimCategory
    PayItemID
    PayItem {
      PayItemID
      PayName
      PayCode
      PayItemType
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    ClaimPolicies {
      ClaimItemID
      ClaimTypeID
      TotalAmt
      ClaimFrequency
      Status
      ClaimBenefit {
        Name
        Description
        Status
        SoftDelete
      }
    }
    PaymentMethod
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useClaimTypeCompanyListingQuery__
 *
 * To run a query within a React component, call `useClaimTypeCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimTypeCompanyListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useClaimTypeCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimTypeCompanyListingQuery, ClaimTypeCompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimTypeCompanyListingQuery, ClaimTypeCompanyListingQueryVariables>(ClaimTypeCompanyListingDocument, baseOptions);
      }
export function useClaimTypeCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimTypeCompanyListingQuery, ClaimTypeCompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimTypeCompanyListingQuery, ClaimTypeCompanyListingQueryVariables>(ClaimTypeCompanyListingDocument, baseOptions);
        }
export type ClaimTypeCompanyListingQueryHookResult = ReturnType<typeof useClaimTypeCompanyListingQuery>;
export type ClaimTypeCompanyListingLazyQueryHookResult = ReturnType<typeof useClaimTypeCompanyListingLazyQuery>;
export type ClaimTypeCompanyListingQueryResult = ApolloReactCommon.QueryResult<ClaimTypeCompanyListingQuery, ClaimTypeCompanyListingQueryVariables>;
export const ClaimTypeTemplateUpdateStatusDocument = gql`
    mutation ClaimTypeTemplateUpdateStatus($claimBenefitInput: ClaimBenefitInput!) {
  ClaimTypeTemplateUpdateStatus(claimBenefitInput: $claimBenefitInput)
}
    `;
export type ClaimTypeTemplateUpdateStatusMutationFn = ApolloReactCommon.MutationFunction<ClaimTypeTemplateUpdateStatusMutation, ClaimTypeTemplateUpdateStatusMutationVariables>;

/**
 * __useClaimTypeTemplateUpdateStatusMutation__
 *
 * To run a mutation, you first call `useClaimTypeTemplateUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeTemplateUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimTypeTemplateUpdateStatusMutation, { data, loading, error }] = useClaimTypeTemplateUpdateStatusMutation({
 *   variables: {
 *      claimBenefitInput: // value for 'claimBenefitInput'
 *   },
 * });
 */
export function useClaimTypeTemplateUpdateStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimTypeTemplateUpdateStatusMutation, ClaimTypeTemplateUpdateStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimTypeTemplateUpdateStatusMutation, ClaimTypeTemplateUpdateStatusMutationVariables>(ClaimTypeTemplateUpdateStatusDocument, baseOptions);
      }
export type ClaimTypeTemplateUpdateStatusMutationHookResult = ReturnType<typeof useClaimTypeTemplateUpdateStatusMutation>;
export type ClaimTypeTemplateUpdateStatusMutationResult = ApolloReactCommon.MutationResult<ClaimTypeTemplateUpdateStatusMutation>;
export type ClaimTypeTemplateUpdateStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimTypeTemplateUpdateStatusMutation, ClaimTypeTemplateUpdateStatusMutationVariables>;
export const ClaimTypeTemplateInsertDocument = gql`
    mutation ClaimTypeTemplateInsert($claimBenefitInput: ClaimBenefitInput!, $claimItemInput: [ClaimItemInput!]!, $claimVehicleRateInput: [ClaimVehicleRateInput!], $claimMealRateInput: [ClaimMealRateInput!], $ClaimDailyRateInput: [ClaimDailyRateInput!]) {
  ClaimTypeTemplateInsert(claimBenefitInput: $claimBenefitInput, claimItemInput: $claimItemInput, claimVehicleRateInput: $claimVehicleRateInput, claimMealRateInput: $claimMealRateInput, claimDailyRateInput: $ClaimDailyRateInput)
}
    `;
export type ClaimTypeTemplateInsertMutationFn = ApolloReactCommon.MutationFunction<ClaimTypeTemplateInsertMutation, ClaimTypeTemplateInsertMutationVariables>;

/**
 * __useClaimTypeTemplateInsertMutation__
 *
 * To run a mutation, you first call `useClaimTypeTemplateInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeTemplateInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimTypeTemplateInsertMutation, { data, loading, error }] = useClaimTypeTemplateInsertMutation({
 *   variables: {
 *      claimBenefitInput: // value for 'claimBenefitInput'
 *      claimItemInput: // value for 'claimItemInput'
 *      claimVehicleRateInput: // value for 'claimVehicleRateInput'
 *      claimMealRateInput: // value for 'claimMealRateInput'
 *      ClaimDailyRateInput: // value for 'ClaimDailyRateInput'
 *   },
 * });
 */
export function useClaimTypeTemplateInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimTypeTemplateInsertMutation, ClaimTypeTemplateInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimTypeTemplateInsertMutation, ClaimTypeTemplateInsertMutationVariables>(ClaimTypeTemplateInsertDocument, baseOptions);
      }
export type ClaimTypeTemplateInsertMutationHookResult = ReturnType<typeof useClaimTypeTemplateInsertMutation>;
export type ClaimTypeTemplateInsertMutationResult = ApolloReactCommon.MutationResult<ClaimTypeTemplateInsertMutation>;
export type ClaimTypeTemplateInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimTypeTemplateInsertMutation, ClaimTypeTemplateInsertMutationVariables>;
export const ClaimTypeTemplateListingDocument = gql`
    query ClaimTypeTemplateListing($SubscriptionAccountID: String!) {
  ClaimTypeTemplateListing(SubscriptionAccountID: $SubscriptionAccountID) {
    ClaimBenefitID
    SubscriptionAccountID
    Name
    Description
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ClaimItems {
      ClaimItemID
      ClaimBenefitID
      ClaimTypeID
      ClaimFrequency
      TotalAmt
      Occurrence
      OccurrenceLimit
      IsLimit
      IsRemarks
      IsAttachment
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      ClaimType {
        Name
        ClaimTypeID
      }
      ClaimTemplates {
        ClaimTemplateID
        ClaimPolicyID
      }
    }
  }
}
    `;

/**
 * __useClaimTypeTemplateListingQuery__
 *
 * To run a query within a React component, call `useClaimTypeTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimTypeTemplateListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useClaimTypeTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimTypeTemplateListingQuery, ClaimTypeTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimTypeTemplateListingQuery, ClaimTypeTemplateListingQueryVariables>(ClaimTypeTemplateListingDocument, baseOptions);
      }
export function useClaimTypeTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimTypeTemplateListingQuery, ClaimTypeTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimTypeTemplateListingQuery, ClaimTypeTemplateListingQueryVariables>(ClaimTypeTemplateListingDocument, baseOptions);
        }
export type ClaimTypeTemplateListingQueryHookResult = ReturnType<typeof useClaimTypeTemplateListingQuery>;
export type ClaimTypeTemplateListingLazyQueryHookResult = ReturnType<typeof useClaimTypeTemplateListingLazyQuery>;
export type ClaimTypeTemplateListingQueryResult = ApolloReactCommon.QueryResult<ClaimTypeTemplateListingQuery, ClaimTypeTemplateListingQueryVariables>;
export const ClaimTypeTemplateUpdateDocument = gql`
    mutation ClaimTypeTemplateUpdate($claimBenefitInput: ClaimBenefitInput!, $claimItemInput: [ClaimItemInput!]!, $claimVehicleRateInput: [ClaimVehicleRateInput!], $claimMealRateInput: [ClaimMealRateInput!], $claimDailyRateInput: [ClaimDailyRateInput!]) {
  ClaimTypeTemplateUpdate(claimBenefitInput: $claimBenefitInput, claimItemInput: $claimItemInput, claimVehicleRateInput: $claimVehicleRateInput, claimMealRateInput: $claimMealRateInput, claimDailyRateInput: $claimDailyRateInput)
}
    `;
export type ClaimTypeTemplateUpdateMutationFn = ApolloReactCommon.MutationFunction<ClaimTypeTemplateUpdateMutation, ClaimTypeTemplateUpdateMutationVariables>;

/**
 * __useClaimTypeTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useClaimTypeTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimTypeTemplateUpdateMutation, { data, loading, error }] = useClaimTypeTemplateUpdateMutation({
 *   variables: {
 *      claimBenefitInput: // value for 'claimBenefitInput'
 *      claimItemInput: // value for 'claimItemInput'
 *      claimVehicleRateInput: // value for 'claimVehicleRateInput'
 *      claimMealRateInput: // value for 'claimMealRateInput'
 *      claimDailyRateInput: // value for 'claimDailyRateInput'
 *   },
 * });
 */
export function useClaimTypeTemplateUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimTypeTemplateUpdateMutation, ClaimTypeTemplateUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimTypeTemplateUpdateMutation, ClaimTypeTemplateUpdateMutationVariables>(ClaimTypeTemplateUpdateDocument, baseOptions);
      }
export type ClaimTypeTemplateUpdateMutationHookResult = ReturnType<typeof useClaimTypeTemplateUpdateMutation>;
export type ClaimTypeTemplateUpdateMutationResult = ApolloReactCommon.MutationResult<ClaimTypeTemplateUpdateMutation>;
export type ClaimTypeTemplateUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimTypeTemplateUpdateMutation, ClaimTypeTemplateUpdateMutationVariables>;
export const JobGradePolicyListingAllDocument = gql`
    query JobGradePolicyListingAll($CompanyID: String!) {
  JobGradePolicyListingAll(CompanyID: $CompanyID) {
    JobGradePolicyID
    JobGradeID
    Company {
      CompanyName
    }
    JobGrade {
      Description
    }
    ClaimTemplates {
      ClaimPolicyID
    }
  }
}
    `;

/**
 * __useJobGradePolicyListingAllQuery__
 *
 * To run a query within a React component, call `useJobGradePolicyListingAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobGradePolicyListingAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobGradePolicyListingAllQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useJobGradePolicyListingAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobGradePolicyListingAllQuery, JobGradePolicyListingAllQueryVariables>) {
        return ApolloReactHooks.useQuery<JobGradePolicyListingAllQuery, JobGradePolicyListingAllQueryVariables>(JobGradePolicyListingAllDocument, baseOptions);
      }
export function useJobGradePolicyListingAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobGradePolicyListingAllQuery, JobGradePolicyListingAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobGradePolicyListingAllQuery, JobGradePolicyListingAllQueryVariables>(JobGradePolicyListingAllDocument, baseOptions);
        }
export type JobGradePolicyListingAllQueryHookResult = ReturnType<typeof useJobGradePolicyListingAllQuery>;
export type JobGradePolicyListingAllLazyQueryHookResult = ReturnType<typeof useJobGradePolicyListingAllLazyQuery>;
export type JobGradePolicyListingAllQueryResult = ApolloReactCommon.QueryResult<JobGradePolicyListingAllQuery, JobGradePolicyListingAllQueryVariables>;
export const CompanySummarySimplifiedDocument = gql`
    query CompanySummarySimplified($CompanyID: String!) {
  CompanySummarySimplified(CompanyID: $CompanyID) {
    CompanyID
    CompanyName
    ClaimCutOffDay
    ClaimTrxDayLimit
    ProjectExpenseTrxDayLimit
    ProjectExpenseCutOff
  }
}
    `;

/**
 * __useCompanySummarySimplifiedQuery__
 *
 * To run a query within a React component, call `useCompanySummarySimplifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySummarySimplifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySummarySimplifiedQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCompanySummarySimplifiedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanySummarySimplifiedQuery, CompanySummarySimplifiedQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanySummarySimplifiedQuery, CompanySummarySimplifiedQueryVariables>(CompanySummarySimplifiedDocument, baseOptions);
      }
export function useCompanySummarySimplifiedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanySummarySimplifiedQuery, CompanySummarySimplifiedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanySummarySimplifiedQuery, CompanySummarySimplifiedQueryVariables>(CompanySummarySimplifiedDocument, baseOptions);
        }
export type CompanySummarySimplifiedQueryHookResult = ReturnType<typeof useCompanySummarySimplifiedQuery>;
export type CompanySummarySimplifiedLazyQueryHookResult = ReturnType<typeof useCompanySummarySimplifiedLazyQuery>;
export type CompanySummarySimplifiedQueryResult = ApolloReactCommon.QueryResult<CompanySummarySimplifiedQuery, CompanySummarySimplifiedQueryVariables>;
export const ClaimTemplateListingDocument = gql`
    query ClaimTemplateListing($JobGradePolicyID: String!, $JobGradeID: String) {
  ClaimTemplateListing(JobGradePolicyID: $JobGradePolicyID, JobGradeID: $JobGradeID) {
    ClaimTemplateID
    JobGradePolicyID
    ClaimPolicyID
    ClaimItem {
      ClaimItemID
      ClaimTypeID
      ClaimType {
        ClaimTypeID
        Name
      }
      ClaimBenefitID
      ClaimBenefit {
        Name
      }
    }
  }
}
    `;

/**
 * __useClaimTemplateListingQuery__
 *
 * To run a query within a React component, call `useClaimTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimTemplateListingQuery({
 *   variables: {
 *      JobGradePolicyID: // value for 'JobGradePolicyID'
 *      JobGradeID: // value for 'JobGradeID'
 *   },
 * });
 */
export function useClaimTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimTemplateListingQuery, ClaimTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimTemplateListingQuery, ClaimTemplateListingQueryVariables>(ClaimTemplateListingDocument, baseOptions);
      }
export function useClaimTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimTemplateListingQuery, ClaimTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimTemplateListingQuery, ClaimTemplateListingQueryVariables>(ClaimTemplateListingDocument, baseOptions);
        }
export type ClaimTemplateListingQueryHookResult = ReturnType<typeof useClaimTemplateListingQuery>;
export type ClaimTemplateListingLazyQueryHookResult = ReturnType<typeof useClaimTemplateListingLazyQuery>;
export type ClaimTemplateListingQueryResult = ApolloReactCommon.QueryResult<ClaimTemplateListingQuery, ClaimTemplateListingQueryVariables>;
export const ClaimJobGradePolicyInsertDocument = gql`
    mutation ClaimJobGradePolicyInsert($JobGradePolicyInput: JobGradePolicyInput!, $ClaimTemplateInput: [ClaimTemplateInput!]!) {
  ClaimJobGradePolicyInsert(JobGradePolicyInput: $JobGradePolicyInput, ClaimTemplateInput: $ClaimTemplateInput)
}
    `;
export type ClaimJobGradePolicyInsertMutationFn = ApolloReactCommon.MutationFunction<ClaimJobGradePolicyInsertMutation, ClaimJobGradePolicyInsertMutationVariables>;

/**
 * __useClaimJobGradePolicyInsertMutation__
 *
 * To run a mutation, you first call `useClaimJobGradePolicyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimJobGradePolicyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimJobGradePolicyInsertMutation, { data, loading, error }] = useClaimJobGradePolicyInsertMutation({
 *   variables: {
 *      JobGradePolicyInput: // value for 'JobGradePolicyInput'
 *      ClaimTemplateInput: // value for 'ClaimTemplateInput'
 *   },
 * });
 */
export function useClaimJobGradePolicyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimJobGradePolicyInsertMutation, ClaimJobGradePolicyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimJobGradePolicyInsertMutation, ClaimJobGradePolicyInsertMutationVariables>(ClaimJobGradePolicyInsertDocument, baseOptions);
      }
export type ClaimJobGradePolicyInsertMutationHookResult = ReturnType<typeof useClaimJobGradePolicyInsertMutation>;
export type ClaimJobGradePolicyInsertMutationResult = ApolloReactCommon.MutationResult<ClaimJobGradePolicyInsertMutation>;
export type ClaimJobGradePolicyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimJobGradePolicyInsertMutation, ClaimJobGradePolicyInsertMutationVariables>;
export const ClaimJobGradePolicyUpdateDocument = gql`
    mutation ClaimJobGradePolicyUpdate($JobGradePolicyID: String!, $JobGradePolicyInput: JobGradePolicyInput!, $ClaimTemplateInput: [ClaimTemplateInput!]!) {
  ClaimJobGradePolicyUpdate(JobGradePolicyID: $JobGradePolicyID, JobGradePolicyInput: $JobGradePolicyInput, ClaimTemplateInput: $ClaimTemplateInput)
}
    `;
export type ClaimJobGradePolicyUpdateMutationFn = ApolloReactCommon.MutationFunction<ClaimJobGradePolicyUpdateMutation, ClaimJobGradePolicyUpdateMutationVariables>;

/**
 * __useClaimJobGradePolicyUpdateMutation__
 *
 * To run a mutation, you first call `useClaimJobGradePolicyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimJobGradePolicyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimJobGradePolicyUpdateMutation, { data, loading, error }] = useClaimJobGradePolicyUpdateMutation({
 *   variables: {
 *      JobGradePolicyID: // value for 'JobGradePolicyID'
 *      JobGradePolicyInput: // value for 'JobGradePolicyInput'
 *      ClaimTemplateInput: // value for 'ClaimTemplateInput'
 *   },
 * });
 */
export function useClaimJobGradePolicyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimJobGradePolicyUpdateMutation, ClaimJobGradePolicyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimJobGradePolicyUpdateMutation, ClaimJobGradePolicyUpdateMutationVariables>(ClaimJobGradePolicyUpdateDocument, baseOptions);
      }
export type ClaimJobGradePolicyUpdateMutationHookResult = ReturnType<typeof useClaimJobGradePolicyUpdateMutation>;
export type ClaimJobGradePolicyUpdateMutationResult = ApolloReactCommon.MutationResult<ClaimJobGradePolicyUpdateMutation>;
export type ClaimJobGradePolicyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimJobGradePolicyUpdateMutation, ClaimJobGradePolicyUpdateMutationVariables>;
export const CompanyClaimSettingUpdateDocument = gql`
    mutation CompanyClaimSettingUpdate($CompanyID: String!, $CompanyInput: CompanyInput!) {
  CompanyClaimSettingUpdate(CompanyID: $CompanyID, CompanyInput: $CompanyInput)
}
    `;
export type CompanyClaimSettingUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyClaimSettingUpdateMutation, CompanyClaimSettingUpdateMutationVariables>;

/**
 * __useCompanyClaimSettingUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyClaimSettingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyClaimSettingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyClaimSettingUpdateMutation, { data, loading, error }] = useCompanyClaimSettingUpdateMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CompanyInput: // value for 'CompanyInput'
 *   },
 * });
 */
export function useCompanyClaimSettingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyClaimSettingUpdateMutation, CompanyClaimSettingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyClaimSettingUpdateMutation, CompanyClaimSettingUpdateMutationVariables>(CompanyClaimSettingUpdateDocument, baseOptions);
      }
export type CompanyClaimSettingUpdateMutationHookResult = ReturnType<typeof useCompanyClaimSettingUpdateMutation>;
export type CompanyClaimSettingUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyClaimSettingUpdateMutation>;
export type CompanyClaimSettingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyClaimSettingUpdateMutation, CompanyClaimSettingUpdateMutationVariables>;
export const ClaimTypeConditionUpdateDocument = gql`
    mutation ClaimTypeConditionUpdate($ClaimTemplateInput: ClaimTemplateInput!) {
  ClaimTypeConditionUpdate(ClaimTemplateInput: $ClaimTemplateInput)
}
    `;
export type ClaimTypeConditionUpdateMutationFn = ApolloReactCommon.MutationFunction<ClaimTypeConditionUpdateMutation, ClaimTypeConditionUpdateMutationVariables>;

/**
 * __useClaimTypeConditionUpdateMutation__
 *
 * To run a mutation, you first call `useClaimTypeConditionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeConditionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimTypeConditionUpdateMutation, { data, loading, error }] = useClaimTypeConditionUpdateMutation({
 *   variables: {
 *      ClaimTemplateInput: // value for 'ClaimTemplateInput'
 *   },
 * });
 */
export function useClaimTypeConditionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimTypeConditionUpdateMutation, ClaimTypeConditionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimTypeConditionUpdateMutation, ClaimTypeConditionUpdateMutationVariables>(ClaimTypeConditionUpdateDocument, baseOptions);
      }
export type ClaimTypeConditionUpdateMutationHookResult = ReturnType<typeof useClaimTypeConditionUpdateMutation>;
export type ClaimTypeConditionUpdateMutationResult = ApolloReactCommon.MutationResult<ClaimTypeConditionUpdateMutation>;
export type ClaimTypeConditionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimTypeConditionUpdateMutation, ClaimTypeConditionUpdateMutationVariables>;
export const ClaimTypeConditionDeleteDocument = gql`
    mutation ClaimTypeConditionDelete($ClaimTemplateID: String!) {
  ClaimTypeConditionDelete(ClaimTemplateID: $ClaimTemplateID)
}
    `;
export type ClaimTypeConditionDeleteMutationFn = ApolloReactCommon.MutationFunction<ClaimTypeConditionDeleteMutation, ClaimTypeConditionDeleteMutationVariables>;

/**
 * __useClaimTypeConditionDeleteMutation__
 *
 * To run a mutation, you first call `useClaimTypeConditionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeConditionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimTypeConditionDeleteMutation, { data, loading, error }] = useClaimTypeConditionDeleteMutation({
 *   variables: {
 *      ClaimTemplateID: // value for 'ClaimTemplateID'
 *   },
 * });
 */
export function useClaimTypeConditionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimTypeConditionDeleteMutation, ClaimTypeConditionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimTypeConditionDeleteMutation, ClaimTypeConditionDeleteMutationVariables>(ClaimTypeConditionDeleteDocument, baseOptions);
      }
export type ClaimTypeConditionDeleteMutationHookResult = ReturnType<typeof useClaimTypeConditionDeleteMutation>;
export type ClaimTypeConditionDeleteMutationResult = ApolloReactCommon.MutationResult<ClaimTypeConditionDeleteMutation>;
export type ClaimTypeConditionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimTypeConditionDeleteMutation, ClaimTypeConditionDeleteMutationVariables>;
export const ClaimTypeConditionListDocument = gql`
    query ClaimTypeConditionList($CompanyID: String!) {
  ClaimTypeConditionList(CompanyID: $CompanyID) {
    CompanyID
    ClaimTemplateID
    ConditionList
    ClaimPolicyIDList
    ClaimTypeID
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __useClaimTypeConditionListQuery__
 *
 * To run a query within a React component, call `useClaimTypeConditionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeConditionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimTypeConditionListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useClaimTypeConditionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimTypeConditionListQuery, ClaimTypeConditionListQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimTypeConditionListQuery, ClaimTypeConditionListQueryVariables>(ClaimTypeConditionListDocument, baseOptions);
      }
export function useClaimTypeConditionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimTypeConditionListQuery, ClaimTypeConditionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimTypeConditionListQuery, ClaimTypeConditionListQueryVariables>(ClaimTypeConditionListDocument, baseOptions);
        }
export type ClaimTypeConditionListQueryHookResult = ReturnType<typeof useClaimTypeConditionListQuery>;
export type ClaimTypeConditionListLazyQueryHookResult = ReturnType<typeof useClaimTypeConditionListLazyQuery>;
export type ClaimTypeConditionListQueryResult = ApolloReactCommon.QueryResult<ClaimTypeConditionListQuery, ClaimTypeConditionListQueryVariables>;
export const GetClaimPostingListingDocument = gql`
    query GetClaimPostingListing($input: ApprovalClaimVerificationInput!, $PaymentMethod: String!, $offset: Float, $limit: Float, $userID: String) {
  GetClaimPostingListing(input: $input, PaymentMethod: $PaymentMethod, offset: $offset, limit: $limit, userID: $userID) {
    JobGradeID
    OrgUnitID
    FormStatus
    ClaimHeaderID
    EmployeeID
    EmployeeName
    EmployeeNo
    Subject
    SubmissionDate
    ClaimNo
    ApprovalName
    ApprovalDate
    HeaderStatus
    CountClaimDetails
    TotalClaimAmount
    CountVerifiedClaim
    TotalVerifiedClaimAmount
    CompletedVerificationDate
    CompletedVerificationBy
    Remark
    IsOTPModified
    IsOTPDeleted
    OTPModifiedBy
    OTPDeletedBy
    MyClaimDetails {
      ClaimDetailID
      ClaimTypeID
      ClaimTypeName
      FinanceApproveStatus
    }
    DetailStatus
    ClaimPostedListing {
      PayPeriodID
      PayrollMonth
      PayrollCycleID
      PayrollCycleName
      PostedDate
      PostedBy
      Reference1
      Reference2
    }
  }
}
    `;

/**
 * __useGetClaimPostingListingQuery__
 *
 * To run a query within a React component, call `useGetClaimPostingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimPostingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimPostingListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      PaymentMethod: // value for 'PaymentMethod'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useGetClaimPostingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimPostingListingQuery, GetClaimPostingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimPostingListingQuery, GetClaimPostingListingQueryVariables>(GetClaimPostingListingDocument, baseOptions);
      }
export function useGetClaimPostingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimPostingListingQuery, GetClaimPostingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimPostingListingQuery, GetClaimPostingListingQueryVariables>(GetClaimPostingListingDocument, baseOptions);
        }
export type GetClaimPostingListingQueryHookResult = ReturnType<typeof useGetClaimPostingListingQuery>;
export type GetClaimPostingListingLazyQueryHookResult = ReturnType<typeof useGetClaimPostingListingLazyQuery>;
export type GetClaimPostingListingQueryResult = ApolloReactCommon.QueryResult<GetClaimPostingListingQuery, GetClaimPostingListingQueryVariables>;
export const GetClaimPostingCountDocument = gql`
    query GetClaimPostingCount($input: ApprovalClaimVerificationInput!, $PaymentMethod: String!) {
  GetClaimPostingCount(input: $input, PaymentMethod: $PaymentMethod)
}
    `;

/**
 * __useGetClaimPostingCountQuery__
 *
 * To run a query within a React component, call `useGetClaimPostingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimPostingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimPostingCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function useGetClaimPostingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimPostingCountQuery, GetClaimPostingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimPostingCountQuery, GetClaimPostingCountQueryVariables>(GetClaimPostingCountDocument, baseOptions);
      }
export function useGetClaimPostingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimPostingCountQuery, GetClaimPostingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimPostingCountQuery, GetClaimPostingCountQueryVariables>(GetClaimPostingCountDocument, baseOptions);
        }
export type GetClaimPostingCountQueryHookResult = ReturnType<typeof useGetClaimPostingCountQuery>;
export type GetClaimPostingCountLazyQueryHookResult = ReturnType<typeof useGetClaimPostingCountLazyQuery>;
export type GetClaimPostingCountQueryResult = ApolloReactCommon.QueryResult<GetClaimPostingCountQuery, GetClaimPostingCountQueryVariables>;
export const GetClaimPostingDetailDocument = gql`
    query GetClaimPostingDetail($ClaimHeaderID: String!, $PaymentMethod: String!) {
  GetClaimPostingDetail(ClaimHeaderID: $ClaimHeaderID, PaymentMethod: $PaymentMethod) {
    EmployeeID
    EmployeeNo
    ClaimNo
    EmployeeName
    DepartmentName
    ClaimHeaderID
    SubmissionDate
    Subject
    CountClaimDetails
    TotalClaimAmount
    PostingStatus
    ApprovalDate
    ApprovalName
    CompletedVerificationDate
    CompletedVerificationBy
    ClaimRecordsDetail {
      ClaimDetailsID
      ClaimTypeID
      ClaimTypeName
      ReceiptNo
      ReceiptDate
      DocumentName
      DocumentUrl
      Document {
        DocumentID
        DocumentFile
      }
      ClaimRemarks
      ClaimAmount
      VerifiedAmount
      FinanceApproveDate
      FinanceApproveBy
      FinanceRemarks
      FinanceApproveStatus
      PostedBy
      PostedDate
    }
  }
}
    `;

/**
 * __useGetClaimPostingDetailQuery__
 *
 * To run a query within a React component, call `useGetClaimPostingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimPostingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimPostingDetailQuery({
 *   variables: {
 *      ClaimHeaderID: // value for 'ClaimHeaderID'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function useGetClaimPostingDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimPostingDetailQuery, GetClaimPostingDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimPostingDetailQuery, GetClaimPostingDetailQueryVariables>(GetClaimPostingDetailDocument, baseOptions);
      }
export function useGetClaimPostingDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimPostingDetailQuery, GetClaimPostingDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimPostingDetailQuery, GetClaimPostingDetailQueryVariables>(GetClaimPostingDetailDocument, baseOptions);
        }
export type GetClaimPostingDetailQueryHookResult = ReturnType<typeof useGetClaimPostingDetailQuery>;
export type GetClaimPostingDetailLazyQueryHookResult = ReturnType<typeof useGetClaimPostingDetailLazyQuery>;
export type GetClaimPostingDetailQueryResult = ApolloReactCommon.QueryResult<GetClaimPostingDetailQuery, GetClaimPostingDetailQueryVariables>;
export const PayrollCycleListingByTlaDocument = gql`
    query PayrollCycleListingByTLA($CompanyID: String!) {
  PayrollCycleListingByTLA(CompanyID: $CompanyID) {
    PayrollCycleID
    Description
    CompanyID
    PayrollCycleID
  }
}
    `;

/**
 * __usePayrollCycleListingByTlaQuery__
 *
 * To run a query within a React component, call `usePayrollCycleListingByTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCycleListingByTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCycleListingByTlaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePayrollCycleListingByTlaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollCycleListingByTlaQuery, PayrollCycleListingByTlaQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollCycleListingByTlaQuery, PayrollCycleListingByTlaQueryVariables>(PayrollCycleListingByTlaDocument, baseOptions);
      }
export function usePayrollCycleListingByTlaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollCycleListingByTlaQuery, PayrollCycleListingByTlaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollCycleListingByTlaQuery, PayrollCycleListingByTlaQueryVariables>(PayrollCycleListingByTlaDocument, baseOptions);
        }
export type PayrollCycleListingByTlaQueryHookResult = ReturnType<typeof usePayrollCycleListingByTlaQuery>;
export type PayrollCycleListingByTlaLazyQueryHookResult = ReturnType<typeof usePayrollCycleListingByTlaLazyQuery>;
export type PayrollCycleListingByTlaQueryResult = ApolloReactCommon.QueryResult<PayrollCycleListingByTlaQuery, PayrollCycleListingByTlaQueryVariables>;
export const PayrollCycleByPayPeriodDocument = gql`
    query PayrollCycleByPayPeriod($CompanyID: String!, $FromPayPeriod: DateTime!, $ToPayPeriod: DateTime!) {
  PayrollCycleByPayPeriod(CompanyID: $CompanyID, FromPayPeriod: $FromPayPeriod, ToPayPeriod: $ToPayPeriod)
}
    `;

/**
 * __usePayrollCycleByPayPeriodQuery__
 *
 * To run a query within a React component, call `usePayrollCycleByPayPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCycleByPayPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCycleByPayPeriodQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FromPayPeriod: // value for 'FromPayPeriod'
 *      ToPayPeriod: // value for 'ToPayPeriod'
 *   },
 * });
 */
export function usePayrollCycleByPayPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollCycleByPayPeriodQuery, PayrollCycleByPayPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollCycleByPayPeriodQuery, PayrollCycleByPayPeriodQueryVariables>(PayrollCycleByPayPeriodDocument, baseOptions);
      }
export function usePayrollCycleByPayPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollCycleByPayPeriodQuery, PayrollCycleByPayPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollCycleByPayPeriodQuery, PayrollCycleByPayPeriodQueryVariables>(PayrollCycleByPayPeriodDocument, baseOptions);
        }
export type PayrollCycleByPayPeriodQueryHookResult = ReturnType<typeof usePayrollCycleByPayPeriodQuery>;
export type PayrollCycleByPayPeriodLazyQueryHookResult = ReturnType<typeof usePayrollCycleByPayPeriodLazyQuery>;
export type PayrollCycleByPayPeriodQueryResult = ApolloReactCommon.QueryResult<PayrollCycleByPayPeriodQuery, PayrollCycleByPayPeriodQueryVariables>;
export const PayPeriodsByCompanyDocument = gql`
    query PayPeriodsByCompany($CompanyID: String!) {
  PayPeriodsByCompany(CompanyID: $CompanyID)
}
    `;

/**
 * __usePayPeriodsByCompanyQuery__
 *
 * To run a query within a React component, call `usePayPeriodsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayPeriodsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayPeriodsByCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePayPeriodsByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayPeriodsByCompanyQuery, PayPeriodsByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<PayPeriodsByCompanyQuery, PayPeriodsByCompanyQueryVariables>(PayPeriodsByCompanyDocument, baseOptions);
      }
export function usePayPeriodsByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayPeriodsByCompanyQuery, PayPeriodsByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayPeriodsByCompanyQuery, PayPeriodsByCompanyQueryVariables>(PayPeriodsByCompanyDocument, baseOptions);
        }
export type PayPeriodsByCompanyQueryHookResult = ReturnType<typeof usePayPeriodsByCompanyQuery>;
export type PayPeriodsByCompanyLazyQueryHookResult = ReturnType<typeof usePayPeriodsByCompanyLazyQuery>;
export type PayPeriodsByCompanyQueryResult = ApolloReactCommon.QueryResult<PayPeriodsByCompanyQuery, PayPeriodsByCompanyQueryVariables>;
export const PayrollProcessPayrollCycleListingByTlaDocument = gql`
    query PayrollProcessPayrollCycleListingByTLA($PayPeriodID: String!, $CompanyID: String!) {
  PayrollProcessPayrollCycleListingByTLA(PayPeriodID: $PayPeriodID, CompanyID: $CompanyID) {
    PayrollCycleID
    Description
    LastProcessedDate
    ShowDelete
    PayrollProcessStatus
    PeriodYearMonth
    IsLastProcess
    SequenceNo
  }
}
    `;

/**
 * __usePayrollProcessPayrollCycleListingByTlaQuery__
 *
 * To run a query within a React component, call `usePayrollProcessPayrollCycleListingByTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollProcessPayrollCycleListingByTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollProcessPayrollCycleListingByTlaQuery({
 *   variables: {
 *      PayPeriodID: // value for 'PayPeriodID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePayrollProcessPayrollCycleListingByTlaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollProcessPayrollCycleListingByTlaQuery, PayrollProcessPayrollCycleListingByTlaQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollProcessPayrollCycleListingByTlaQuery, PayrollProcessPayrollCycleListingByTlaQueryVariables>(PayrollProcessPayrollCycleListingByTlaDocument, baseOptions);
      }
export function usePayrollProcessPayrollCycleListingByTlaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollProcessPayrollCycleListingByTlaQuery, PayrollProcessPayrollCycleListingByTlaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollProcessPayrollCycleListingByTlaQuery, PayrollProcessPayrollCycleListingByTlaQueryVariables>(PayrollProcessPayrollCycleListingByTlaDocument, baseOptions);
        }
export type PayrollProcessPayrollCycleListingByTlaQueryHookResult = ReturnType<typeof usePayrollProcessPayrollCycleListingByTlaQuery>;
export type PayrollProcessPayrollCycleListingByTlaLazyQueryHookResult = ReturnType<typeof usePayrollProcessPayrollCycleListingByTlaLazyQuery>;
export type PayrollProcessPayrollCycleListingByTlaQueryResult = ApolloReactCommon.QueryResult<PayrollProcessPayrollCycleListingByTlaQuery, PayrollProcessPayrollCycleListingByTlaQueryVariables>;
export const PayrollProcessPayrollCycleListingDocument = gql`
    query PayrollProcessPayrollCycleListing($PayPeriodID: String!, $CompanyID: String!) {
  PayrollProcessPayrollCycleListing(PayPeriodID: $PayPeriodID, CompanyID: $CompanyID) {
    PayrollCycleID
    Description
    LastProcessedDate
    ShowDelete
    PayrollProcessStatus
    PeriodYearMonth
    IsLastProcess
    SequenceNo
    IsInitiated
    IsProjectGLProcessed
    LastProjectGLProcessedDate
    IsPayrollGLProcessed
    LastPayrollGLProcessedDate
  }
}
    `;

/**
 * __usePayrollProcessPayrollCycleListingQuery__
 *
 * To run a query within a React component, call `usePayrollProcessPayrollCycleListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollProcessPayrollCycleListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollProcessPayrollCycleListingQuery({
 *   variables: {
 *      PayPeriodID: // value for 'PayPeriodID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePayrollProcessPayrollCycleListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollProcessPayrollCycleListingQuery, PayrollProcessPayrollCycleListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollProcessPayrollCycleListingQuery, PayrollProcessPayrollCycleListingQueryVariables>(PayrollProcessPayrollCycleListingDocument, baseOptions);
      }
export function usePayrollProcessPayrollCycleListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollProcessPayrollCycleListingQuery, PayrollProcessPayrollCycleListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollProcessPayrollCycleListingQuery, PayrollProcessPayrollCycleListingQueryVariables>(PayrollProcessPayrollCycleListingDocument, baseOptions);
        }
export type PayrollProcessPayrollCycleListingQueryHookResult = ReturnType<typeof usePayrollProcessPayrollCycleListingQuery>;
export type PayrollProcessPayrollCycleListingLazyQueryHookResult = ReturnType<typeof usePayrollProcessPayrollCycleListingLazyQuery>;
export type PayrollProcessPayrollCycleListingQueryResult = ApolloReactCommon.QueryResult<PayrollProcessPayrollCycleListingQuery, PayrollProcessPayrollCycleListingQueryVariables>;
export const PostClaimRecordsDocument = gql`
    mutation PostClaimRecords($input: PostClaimRecordsInput!, $PaymentMethod: String!) {
  PostClaimRecords(input: $input, PaymentMethod: $PaymentMethod)
}
    `;
export type PostClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<PostClaimRecordsMutation, PostClaimRecordsMutationVariables>;

/**
 * __usePostClaimRecordsMutation__
 *
 * To run a mutation, you first call `usePostClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postClaimRecordsMutation, { data, loading, error }] = usePostClaimRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function usePostClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostClaimRecordsMutation, PostClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<PostClaimRecordsMutation, PostClaimRecordsMutationVariables>(PostClaimRecordsDocument, baseOptions);
      }
export type PostClaimRecordsMutationHookResult = ReturnType<typeof usePostClaimRecordsMutation>;
export type PostClaimRecordsMutationResult = ApolloReactCommon.MutationResult<PostClaimRecordsMutation>;
export type PostClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<PostClaimRecordsMutation, PostClaimRecordsMutationVariables>;
export const ReverseClaimRecordsDocument = gql`
    mutation ReverseClaimRecords($SubscriptionUserID: String!, $ClaimHeaderIDs: [String!]!, $PaymentMethod: String!) {
  ReverseClaimRecords(SubscriptionUserID: $SubscriptionUserID, ClaimHeaderIDs: $ClaimHeaderIDs, PaymentMethod: $PaymentMethod)
}
    `;
export type ReverseClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<ReverseClaimRecordsMutation, ReverseClaimRecordsMutationVariables>;

/**
 * __useReverseClaimRecordsMutation__
 *
 * To run a mutation, you first call `useReverseClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverseClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverseClaimRecordsMutation, { data, loading, error }] = useReverseClaimRecordsMutation({
 *   variables: {
 *      SubscriptionUserID: // value for 'SubscriptionUserID'
 *      ClaimHeaderIDs: // value for 'ClaimHeaderIDs'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function useReverseClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReverseClaimRecordsMutation, ReverseClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<ReverseClaimRecordsMutation, ReverseClaimRecordsMutationVariables>(ReverseClaimRecordsDocument, baseOptions);
      }
export type ReverseClaimRecordsMutationHookResult = ReturnType<typeof useReverseClaimRecordsMutation>;
export type ReverseClaimRecordsMutationResult = ApolloReactCommon.MutationResult<ReverseClaimRecordsMutation>;
export type ReverseClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReverseClaimRecordsMutation, ReverseClaimRecordsMutationVariables>;
export const GetClaimPostingPeriodListingV2Document = gql`
    query GetClaimPostingPeriodListingV2($input: ClaimPostingPeriodListingInput!, $PaymentMethod: String) {
  GetClaimPostingPeriodListingV2(input: $input, PaymentMethod: $PaymentMethod) {
    PeriodYearMonth
    FromDate
    ToDate
    ListingStatus
    LatestPeriod
    ClaimPostingPeriodStatusList {
      ClaimPostingPeriodStatus
      CountClaimDetails
      TotalClaimAmount
    }
  }
}
    `;

/**
 * __useGetClaimPostingPeriodListingV2Query__
 *
 * To run a query within a React component, call `useGetClaimPostingPeriodListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimPostingPeriodListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimPostingPeriodListingV2Query({
 *   variables: {
 *      input: // value for 'input'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function useGetClaimPostingPeriodListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimPostingPeriodListingV2Query, GetClaimPostingPeriodListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimPostingPeriodListingV2Query, GetClaimPostingPeriodListingV2QueryVariables>(GetClaimPostingPeriodListingV2Document, baseOptions);
      }
export function useGetClaimPostingPeriodListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimPostingPeriodListingV2Query, GetClaimPostingPeriodListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimPostingPeriodListingV2Query, GetClaimPostingPeriodListingV2QueryVariables>(GetClaimPostingPeriodListingV2Document, baseOptions);
        }
export type GetClaimPostingPeriodListingV2QueryHookResult = ReturnType<typeof useGetClaimPostingPeriodListingV2Query>;
export type GetClaimPostingPeriodListingV2LazyQueryHookResult = ReturnType<typeof useGetClaimPostingPeriodListingV2LazyQuery>;
export type GetClaimPostingPeriodListingV2QueryResult = ApolloReactCommon.QueryResult<GetClaimPostingPeriodListingV2Query, GetClaimPostingPeriodListingV2QueryVariables>;
export const GetClaimPaymentPeriodListingV2Document = gql`
    query GetClaimPaymentPeriodListingV2($input: ClaimPostingPeriodListingInput!, $PaymentMethod: String) {
  GetClaimPostingPeriodListingV2(input: $input, PaymentMethod: $PaymentMethod) {
    PeriodYearMonth
    FromDate
    ToDate
    ListingStatus
    LatestPeriod
    ClaimPostingPeriodStatusList {
      ClaimPostingPeriodStatus
      CountClaimDetails
      TotalClaimAmount
    }
  }
}
    `;

/**
 * __useGetClaimPaymentPeriodListingV2Query__
 *
 * To run a query within a React component, call `useGetClaimPaymentPeriodListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimPaymentPeriodListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimPaymentPeriodListingV2Query({
 *   variables: {
 *      input: // value for 'input'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function useGetClaimPaymentPeriodListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimPaymentPeriodListingV2Query, GetClaimPaymentPeriodListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimPaymentPeriodListingV2Query, GetClaimPaymentPeriodListingV2QueryVariables>(GetClaimPaymentPeriodListingV2Document, baseOptions);
      }
export function useGetClaimPaymentPeriodListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimPaymentPeriodListingV2Query, GetClaimPaymentPeriodListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimPaymentPeriodListingV2Query, GetClaimPaymentPeriodListingV2QueryVariables>(GetClaimPaymentPeriodListingV2Document, baseOptions);
        }
export type GetClaimPaymentPeriodListingV2QueryHookResult = ReturnType<typeof useGetClaimPaymentPeriodListingV2Query>;
export type GetClaimPaymentPeriodListingV2LazyQueryHookResult = ReturnType<typeof useGetClaimPaymentPeriodListingV2LazyQuery>;
export type GetClaimPaymentPeriodListingV2QueryResult = ApolloReactCommon.QueryResult<GetClaimPaymentPeriodListingV2Query, GetClaimPaymentPeriodListingV2QueryVariables>;
export const ClaimHomeMenuSummaryDocument = gql`
    query ClaimHomeMenuSummary($CompanyID: String!, $SubscriptionAccountID: String!) {
  ClaimHomeMenuSummary(CompanyID: $CompanyID, SubscriptionAccountID: $SubscriptionAccountID)
}
    `;

/**
 * __useClaimHomeMenuSummaryQuery__
 *
 * To run a query within a React component, call `useClaimHomeMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimHomeMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimHomeMenuSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useClaimHomeMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimHomeMenuSummaryQuery, ClaimHomeMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimHomeMenuSummaryQuery, ClaimHomeMenuSummaryQueryVariables>(ClaimHomeMenuSummaryDocument, baseOptions);
      }
export function useClaimHomeMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimHomeMenuSummaryQuery, ClaimHomeMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimHomeMenuSummaryQuery, ClaimHomeMenuSummaryQueryVariables>(ClaimHomeMenuSummaryDocument, baseOptions);
        }
export type ClaimHomeMenuSummaryQueryHookResult = ReturnType<typeof useClaimHomeMenuSummaryQuery>;
export type ClaimHomeMenuSummaryLazyQueryHookResult = ReturnType<typeof useClaimHomeMenuSummaryLazyQuery>;
export type ClaimHomeMenuSummaryQueryResult = ApolloReactCommon.QueryResult<ClaimHomeMenuSummaryQuery, ClaimHomeMenuSummaryQueryVariables>;
export const GetCompanyStatutoryAccountListingDocument = gql`
    query getCompanyStatutoryAccountListing($CompanyID: String!) {
  getCompanyStatutoryAccountListing(CompanyID: $CompanyID) {
    CompanyStatutoryAccountID
    CompanyStatutoryID
    CompanyID
    Type
    AccountNo
    Description
    IsDefault
    Status
    removable
    CompanyAltAddressID
  }
}
    `;

/**
 * __useGetCompanyStatutoryAccountListingQuery__
 *
 * To run a query within a React component, call `useGetCompanyStatutoryAccountListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyStatutoryAccountListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyStatutoryAccountListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCompanyStatutoryAccountListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyStatutoryAccountListingQuery, GetCompanyStatutoryAccountListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyStatutoryAccountListingQuery, GetCompanyStatutoryAccountListingQueryVariables>(GetCompanyStatutoryAccountListingDocument, baseOptions);
      }
export function useGetCompanyStatutoryAccountListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyStatutoryAccountListingQuery, GetCompanyStatutoryAccountListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyStatutoryAccountListingQuery, GetCompanyStatutoryAccountListingQueryVariables>(GetCompanyStatutoryAccountListingDocument, baseOptions);
        }
export type GetCompanyStatutoryAccountListingQueryHookResult = ReturnType<typeof useGetCompanyStatutoryAccountListingQuery>;
export type GetCompanyStatutoryAccountListingLazyQueryHookResult = ReturnType<typeof useGetCompanyStatutoryAccountListingLazyQuery>;
export type GetCompanyStatutoryAccountListingQueryResult = ApolloReactCommon.QueryResult<GetCompanyStatutoryAccountListingQuery, GetCompanyStatutoryAccountListingQueryVariables>;
export const CalculateEpfRateDocument = gql`
    query CalculateEPFRate($EmployeeID: String!) {
  CalculateEPFRate(EmployeeID: $EmployeeID) {
    EmployeeRate
    EmployerRate
  }
}
    `;

/**
 * __useCalculateEpfRateQuery__
 *
 * To run a query within a React component, call `useCalculateEpfRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateEpfRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateEpfRateQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useCalculateEpfRateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalculateEpfRateQuery, CalculateEpfRateQueryVariables>) {
        return ApolloReactHooks.useQuery<CalculateEpfRateQuery, CalculateEpfRateQueryVariables>(CalculateEpfRateDocument, baseOptions);
      }
export function useCalculateEpfRateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalculateEpfRateQuery, CalculateEpfRateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalculateEpfRateQuery, CalculateEpfRateQueryVariables>(CalculateEpfRateDocument, baseOptions);
        }
export type CalculateEpfRateQueryHookResult = ReturnType<typeof useCalculateEpfRateQuery>;
export type CalculateEpfRateLazyQueryHookResult = ReturnType<typeof useCalculateEpfRateLazyQuery>;
export type CalculateEpfRateQueryResult = ApolloReactCommon.QueryResult<CalculateEpfRateQuery, CalculateEpfRateQueryVariables>;
export const CompanyStatutoryAccountUpdateDocument = gql`
    mutation CompanyStatutoryAccountUpdate($CompanyStatutoryAccountInput: [CompanyStatutoryAccountInput!]!, $CompanyStatutoryID: String!, $CompanyID: String!, $EmployeeID: String!) {
  CompanyStatutoryAccountUpdate(input: $CompanyStatutoryAccountInput, CompanyStatutoryID: $CompanyStatutoryID, CompanyID: $CompanyID, EmployeeID: $EmployeeID)
}
    `;
export type CompanyStatutoryAccountUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyStatutoryAccountUpdateMutation, CompanyStatutoryAccountUpdateMutationVariables>;

/**
 * __useCompanyStatutoryAccountUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyStatutoryAccountUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyStatutoryAccountUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyStatutoryAccountUpdateMutation, { data, loading, error }] = useCompanyStatutoryAccountUpdateMutation({
 *   variables: {
 *      CompanyStatutoryAccountInput: // value for 'CompanyStatutoryAccountInput'
 *      CompanyStatutoryID: // value for 'CompanyStatutoryID'
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useCompanyStatutoryAccountUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyStatutoryAccountUpdateMutation, CompanyStatutoryAccountUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyStatutoryAccountUpdateMutation, CompanyStatutoryAccountUpdateMutationVariables>(CompanyStatutoryAccountUpdateDocument, baseOptions);
      }
export type CompanyStatutoryAccountUpdateMutationHookResult = ReturnType<typeof useCompanyStatutoryAccountUpdateMutation>;
export type CompanyStatutoryAccountUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyStatutoryAccountUpdateMutation>;
export type CompanyStatutoryAccountUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyStatutoryAccountUpdateMutation, CompanyStatutoryAccountUpdateMutationVariables>;
export const CompulsaryCourseByJobGradeListingDocument = gql`
    query CompulsaryCourseByJobGradeListing($CompanyID: String!, $JobGradeID: String, $CourseID: String) {
  CompulsaryCourseByJobGradeListing(CompanyID: $CompanyID, JobGradeID: $JobGradeID, CourseID: $CourseID) {
    ID
    CompanyID
    JobGradeID
    CourseID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useCompulsaryCourseByJobGradeListingQuery__
 *
 * To run a query within a React component, call `useCompulsaryCourseByJobGradeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompulsaryCourseByJobGradeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompulsaryCourseByJobGradeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      JobGradeID: // value for 'JobGradeID'
 *      CourseID: // value for 'CourseID'
 *   },
 * });
 */
export function useCompulsaryCourseByJobGradeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompulsaryCourseByJobGradeListingQuery, CompulsaryCourseByJobGradeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CompulsaryCourseByJobGradeListingQuery, CompulsaryCourseByJobGradeListingQueryVariables>(CompulsaryCourseByJobGradeListingDocument, baseOptions);
      }
export function useCompulsaryCourseByJobGradeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompulsaryCourseByJobGradeListingQuery, CompulsaryCourseByJobGradeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompulsaryCourseByJobGradeListingQuery, CompulsaryCourseByJobGradeListingQueryVariables>(CompulsaryCourseByJobGradeListingDocument, baseOptions);
        }
export type CompulsaryCourseByJobGradeListingQueryHookResult = ReturnType<typeof useCompulsaryCourseByJobGradeListingQuery>;
export type CompulsaryCourseByJobGradeListingLazyQueryHookResult = ReturnType<typeof useCompulsaryCourseByJobGradeListingLazyQuery>;
export type CompulsaryCourseByJobGradeListingQueryResult = ApolloReactCommon.QueryResult<CompulsaryCourseByJobGradeListingQuery, CompulsaryCourseByJobGradeListingQueryVariables>;
export const GetCompulsaryCourseByJobGradeListingWithCompanyDocument = gql`
    query GetCompulsaryCourseByJobGradeListingWithCompany($CompanyID: String!) {
  GetCompulsaryCourseByJobGradeListingWithCompany(CompanyID: $CompanyID) {
    ID
    CompanyID
    JobGradeID
    CourseID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useGetCompulsaryCourseByJobGradeListingWithCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompulsaryCourseByJobGradeListingWithCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompulsaryCourseByJobGradeListingWithCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompulsaryCourseByJobGradeListingWithCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCompulsaryCourseByJobGradeListingWithCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompulsaryCourseByJobGradeListingWithCompanyQuery, GetCompulsaryCourseByJobGradeListingWithCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompulsaryCourseByJobGradeListingWithCompanyQuery, GetCompulsaryCourseByJobGradeListingWithCompanyQueryVariables>(GetCompulsaryCourseByJobGradeListingWithCompanyDocument, baseOptions);
      }
export function useGetCompulsaryCourseByJobGradeListingWithCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompulsaryCourseByJobGradeListingWithCompanyQuery, GetCompulsaryCourseByJobGradeListingWithCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompulsaryCourseByJobGradeListingWithCompanyQuery, GetCompulsaryCourseByJobGradeListingWithCompanyQueryVariables>(GetCompulsaryCourseByJobGradeListingWithCompanyDocument, baseOptions);
        }
export type GetCompulsaryCourseByJobGradeListingWithCompanyQueryHookResult = ReturnType<typeof useGetCompulsaryCourseByJobGradeListingWithCompanyQuery>;
export type GetCompulsaryCourseByJobGradeListingWithCompanyLazyQueryHookResult = ReturnType<typeof useGetCompulsaryCourseByJobGradeListingWithCompanyLazyQuery>;
export type GetCompulsaryCourseByJobGradeListingWithCompanyQueryResult = ApolloReactCommon.QueryResult<GetCompulsaryCourseByJobGradeListingWithCompanyQuery, GetCompulsaryCourseByJobGradeListingWithCompanyQueryVariables>;
export const JobGradeTrainingCourseListingAllDocument = gql`
    query JobGradeTrainingCourseListingAll {
  TrainingCourseListingAll {
    SubscriptionAccountID
    ID
    Name
    Description
    Evaluation
    HRDFClaimable
    Type
    Duration
    PreferredTrainer
    AllowedJobGrade
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useJobGradeTrainingCourseListingAllQuery__
 *
 * To run a query within a React component, call `useJobGradeTrainingCourseListingAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobGradeTrainingCourseListingAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobGradeTrainingCourseListingAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useJobGradeTrainingCourseListingAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobGradeTrainingCourseListingAllQuery, JobGradeTrainingCourseListingAllQueryVariables>) {
        return ApolloReactHooks.useQuery<JobGradeTrainingCourseListingAllQuery, JobGradeTrainingCourseListingAllQueryVariables>(JobGradeTrainingCourseListingAllDocument, baseOptions);
      }
export function useJobGradeTrainingCourseListingAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobGradeTrainingCourseListingAllQuery, JobGradeTrainingCourseListingAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobGradeTrainingCourseListingAllQuery, JobGradeTrainingCourseListingAllQueryVariables>(JobGradeTrainingCourseListingAllDocument, baseOptions);
        }
export type JobGradeTrainingCourseListingAllQueryHookResult = ReturnType<typeof useJobGradeTrainingCourseListingAllQuery>;
export type JobGradeTrainingCourseListingAllLazyQueryHookResult = ReturnType<typeof useJobGradeTrainingCourseListingAllLazyQuery>;
export type JobGradeTrainingCourseListingAllQueryResult = ApolloReactCommon.QueryResult<JobGradeTrainingCourseListingAllQuery, JobGradeTrainingCourseListingAllQueryVariables>;
export const CompulsaryCourseByJobGradeSubmitDocument = gql`
    mutation CompulsaryCourseByJobGradeSubmit($ids: [String!]!, $CompanyID: String!, $JobGradeID: String!) {
  CompulsaryCourseByJobGradeSubmit(ids: $ids, CompanyID: $CompanyID, JobGradeID: $JobGradeID)
}
    `;
export type CompulsaryCourseByJobGradeSubmitMutationFn = ApolloReactCommon.MutationFunction<CompulsaryCourseByJobGradeSubmitMutation, CompulsaryCourseByJobGradeSubmitMutationVariables>;

/**
 * __useCompulsaryCourseByJobGradeSubmitMutation__
 *
 * To run a mutation, you first call `useCompulsaryCourseByJobGradeSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompulsaryCourseByJobGradeSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [compulsaryCourseByJobGradeSubmitMutation, { data, loading, error }] = useCompulsaryCourseByJobGradeSubmitMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      CompanyID: // value for 'CompanyID'
 *      JobGradeID: // value for 'JobGradeID'
 *   },
 * });
 */
export function useCompulsaryCourseByJobGradeSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompulsaryCourseByJobGradeSubmitMutation, CompulsaryCourseByJobGradeSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<CompulsaryCourseByJobGradeSubmitMutation, CompulsaryCourseByJobGradeSubmitMutationVariables>(CompulsaryCourseByJobGradeSubmitDocument, baseOptions);
      }
export type CompulsaryCourseByJobGradeSubmitMutationHookResult = ReturnType<typeof useCompulsaryCourseByJobGradeSubmitMutation>;
export type CompulsaryCourseByJobGradeSubmitMutationResult = ApolloReactCommon.MutationResult<CompulsaryCourseByJobGradeSubmitMutation>;
export type CompulsaryCourseByJobGradeSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<CompulsaryCourseByJobGradeSubmitMutation, CompulsaryCourseByJobGradeSubmitMutationVariables>;
export const CourseClassificationListingDocument = gql`
    query CourseClassificationListing {
  CourseClassificationListing {
    ID
    SubscriptionAccountID
    Name
    DisplaySeq
    isInUse
    TrainingCourses {
      ID
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useCourseClassificationListingQuery__
 *
 * To run a query within a React component, call `useCourseClassificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseClassificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseClassificationListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCourseClassificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseClassificationListingQuery, CourseClassificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CourseClassificationListingQuery, CourseClassificationListingQueryVariables>(CourseClassificationListingDocument, baseOptions);
      }
export function useCourseClassificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseClassificationListingQuery, CourseClassificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CourseClassificationListingQuery, CourseClassificationListingQueryVariables>(CourseClassificationListingDocument, baseOptions);
        }
export type CourseClassificationListingQueryHookResult = ReturnType<typeof useCourseClassificationListingQuery>;
export type CourseClassificationListingLazyQueryHookResult = ReturnType<typeof useCourseClassificationListingLazyQuery>;
export type CourseClassificationListingQueryResult = ApolloReactCommon.QueryResult<CourseClassificationListingQuery, CourseClassificationListingQueryVariables>;
export const CourseClassificationBulkInsertDocument = gql`
    mutation CourseClassificationBulkInsert($input: [CourseClassificationInput!]!) {
  CourseClassificationBulkInsert(input: $input)
}
    `;
export type CourseClassificationBulkInsertMutationFn = ApolloReactCommon.MutationFunction<CourseClassificationBulkInsertMutation, CourseClassificationBulkInsertMutationVariables>;

/**
 * __useCourseClassificationBulkInsertMutation__
 *
 * To run a mutation, you first call `useCourseClassificationBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseClassificationBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseClassificationBulkInsertMutation, { data, loading, error }] = useCourseClassificationBulkInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseClassificationBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CourseClassificationBulkInsertMutation, CourseClassificationBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CourseClassificationBulkInsertMutation, CourseClassificationBulkInsertMutationVariables>(CourseClassificationBulkInsertDocument, baseOptions);
      }
export type CourseClassificationBulkInsertMutationHookResult = ReturnType<typeof useCourseClassificationBulkInsertMutation>;
export type CourseClassificationBulkInsertMutationResult = ApolloReactCommon.MutationResult<CourseClassificationBulkInsertMutation>;
export type CourseClassificationBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CourseClassificationBulkInsertMutation, CourseClassificationBulkInsertMutationVariables>;
export const CourseClassificationDeleteDocument = gql`
    mutation CourseClassificationDelete($CourseClassificationID: String!) {
  CourseClassificationDelete(ID: $CourseClassificationID)
}
    `;
export type CourseClassificationDeleteMutationFn = ApolloReactCommon.MutationFunction<CourseClassificationDeleteMutation, CourseClassificationDeleteMutationVariables>;

/**
 * __useCourseClassificationDeleteMutation__
 *
 * To run a mutation, you first call `useCourseClassificationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseClassificationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseClassificationDeleteMutation, { data, loading, error }] = useCourseClassificationDeleteMutation({
 *   variables: {
 *      CourseClassificationID: // value for 'CourseClassificationID'
 *   },
 * });
 */
export function useCourseClassificationDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CourseClassificationDeleteMutation, CourseClassificationDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CourseClassificationDeleteMutation, CourseClassificationDeleteMutationVariables>(CourseClassificationDeleteDocument, baseOptions);
      }
export type CourseClassificationDeleteMutationHookResult = ReturnType<typeof useCourseClassificationDeleteMutation>;
export type CourseClassificationDeleteMutationResult = ApolloReactCommon.MutationResult<CourseClassificationDeleteMutation>;
export type CourseClassificationDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CourseClassificationDeleteMutation, CourseClassificationDeleteMutationVariables>;
export const TrainingCourseListingDocument = gql`
    query TrainingCourseListing($CourseClassificationID: String!) {
  TrainingCourseListing(CourseClassificationID: $CourseClassificationID) {
    ID
    Name
    Description
    Evaluation
    HRDFClaimable
    Type
    Duration
    PreferredTrainer
    AllowedJobGrade
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useTrainingCourseListingQuery__
 *
 * To run a query within a React component, call `useTrainingCourseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingCourseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingCourseListingQuery({
 *   variables: {
 *      CourseClassificationID: // value for 'CourseClassificationID'
 *   },
 * });
 */
export function useTrainingCourseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingCourseListingQuery, TrainingCourseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingCourseListingQuery, TrainingCourseListingQueryVariables>(TrainingCourseListingDocument, baseOptions);
      }
export function useTrainingCourseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingCourseListingQuery, TrainingCourseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingCourseListingQuery, TrainingCourseListingQueryVariables>(TrainingCourseListingDocument, baseOptions);
        }
export type TrainingCourseListingQueryHookResult = ReturnType<typeof useTrainingCourseListingQuery>;
export type TrainingCourseListingLazyQueryHookResult = ReturnType<typeof useTrainingCourseListingLazyQuery>;
export type TrainingCourseListingQueryResult = ApolloReactCommon.QueryResult<TrainingCourseListingQuery, TrainingCourseListingQueryVariables>;
export const TrainingCourseListingAllDocument = gql`
    query TrainingCourseListingAll {
  TrainingCourseListingAll {
    ID
    Name
    Description
    Evaluation
    HRDFClaimable
    Type
    Duration
    PreferredTrainer
    AllowedJobGrade
    SubscriptionAccountID
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useTrainingCourseListingAllQuery__
 *
 * To run a query within a React component, call `useTrainingCourseListingAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingCourseListingAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingCourseListingAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainingCourseListingAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingCourseListingAllQuery, TrainingCourseListingAllQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingCourseListingAllQuery, TrainingCourseListingAllQueryVariables>(TrainingCourseListingAllDocument, baseOptions);
      }
export function useTrainingCourseListingAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingCourseListingAllQuery, TrainingCourseListingAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingCourseListingAllQuery, TrainingCourseListingAllQueryVariables>(TrainingCourseListingAllDocument, baseOptions);
        }
export type TrainingCourseListingAllQueryHookResult = ReturnType<typeof useTrainingCourseListingAllQuery>;
export type TrainingCourseListingAllLazyQueryHookResult = ReturnType<typeof useTrainingCourseListingAllLazyQuery>;
export type TrainingCourseListingAllQueryResult = ApolloReactCommon.QueryResult<TrainingCourseListingAllQuery, TrainingCourseListingAllQueryVariables>;
export const TrainingCourseListingByJobGradeDocument = gql`
    query TrainingCourseListingByJobGrade($JobGradeID: String!) {
  TrainingCourseListingByJobGrade(JobGradeID: $JobGradeID) {
    ID
    Name
    Description
    Evaluation
    HRDFClaimable
    Type
    Duration
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useTrainingCourseListingByJobGradeQuery__
 *
 * To run a query within a React component, call `useTrainingCourseListingByJobGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingCourseListingByJobGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingCourseListingByJobGradeQuery({
 *   variables: {
 *      JobGradeID: // value for 'JobGradeID'
 *   },
 * });
 */
export function useTrainingCourseListingByJobGradeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingCourseListingByJobGradeQuery, TrainingCourseListingByJobGradeQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingCourseListingByJobGradeQuery, TrainingCourseListingByJobGradeQueryVariables>(TrainingCourseListingByJobGradeDocument, baseOptions);
      }
export function useTrainingCourseListingByJobGradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingCourseListingByJobGradeQuery, TrainingCourseListingByJobGradeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingCourseListingByJobGradeQuery, TrainingCourseListingByJobGradeQueryVariables>(TrainingCourseListingByJobGradeDocument, baseOptions);
        }
export type TrainingCourseListingByJobGradeQueryHookResult = ReturnType<typeof useTrainingCourseListingByJobGradeQuery>;
export type TrainingCourseListingByJobGradeLazyQueryHookResult = ReturnType<typeof useTrainingCourseListingByJobGradeLazyQuery>;
export type TrainingCourseListingByJobGradeQueryResult = ApolloReactCommon.QueryResult<TrainingCourseListingByJobGradeQuery, TrainingCourseListingByJobGradeQueryVariables>;
export const TrainingCourseSubmitDocument = gql`
    mutation TrainingCourseSubmit($input: TrainingCourseInput!) {
  TrainingCourseSubmit(input: $input)
}
    `;
export type TrainingCourseSubmitMutationFn = ApolloReactCommon.MutationFunction<TrainingCourseSubmitMutation, TrainingCourseSubmitMutationVariables>;

/**
 * __useTrainingCourseSubmitMutation__
 *
 * To run a mutation, you first call `useTrainingCourseSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrainingCourseSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trainingCourseSubmitMutation, { data, loading, error }] = useTrainingCourseSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrainingCourseSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TrainingCourseSubmitMutation, TrainingCourseSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<TrainingCourseSubmitMutation, TrainingCourseSubmitMutationVariables>(TrainingCourseSubmitDocument, baseOptions);
      }
export type TrainingCourseSubmitMutationHookResult = ReturnType<typeof useTrainingCourseSubmitMutation>;
export type TrainingCourseSubmitMutationResult = ApolloReactCommon.MutationResult<TrainingCourseSubmitMutation>;
export type TrainingCourseSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<TrainingCourseSubmitMutation, TrainingCourseSubmitMutationVariables>;
export const TrainingCourseDeleteDocument = gql`
    mutation TrainingCourseDelete($TrainingCourseID: String!) {
  TrainingCourseDelete(TrainingCourseID: $TrainingCourseID)
}
    `;
export type TrainingCourseDeleteMutationFn = ApolloReactCommon.MutationFunction<TrainingCourseDeleteMutation, TrainingCourseDeleteMutationVariables>;

/**
 * __useTrainingCourseDeleteMutation__
 *
 * To run a mutation, you first call `useTrainingCourseDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrainingCourseDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trainingCourseDeleteMutation, { data, loading, error }] = useTrainingCourseDeleteMutation({
 *   variables: {
 *      TrainingCourseID: // value for 'TrainingCourseID'
 *   },
 * });
 */
export function useTrainingCourseDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TrainingCourseDeleteMutation, TrainingCourseDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TrainingCourseDeleteMutation, TrainingCourseDeleteMutationVariables>(TrainingCourseDeleteDocument, baseOptions);
      }
export type TrainingCourseDeleteMutationHookResult = ReturnType<typeof useTrainingCourseDeleteMutation>;
export type TrainingCourseDeleteMutationResult = ApolloReactCommon.MutationResult<TrainingCourseDeleteMutation>;
export type TrainingCourseDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TrainingCourseDeleteMutation, TrainingCourseDeleteMutationVariables>;
export const GetProviderListByCourseDocument = gql`
    query GetProviderListByCourse {
  GetProviderListByCourse {
    CourseID
    CourseName
    providers {
      ID
      SubscriptionAccountID
      DocumentID
      Document {
        DocumentID
        DocumentType
        Description
        DocumentFile
        BucketFileName
        FileSize
      }
      Name
      IsExternal
      ApprovedCourses
      Contact {
        ContactID
        FullName
        NickName
        MobileNo
        OfficeNo
        FaxNo
        Email
        NRIC
        PassportNo
        PassportNoLHDN
        Nationality
        Marital
        Gender
        Race
        Religion
        BirthDate
        ProfilePicture
        WebUrl
        Address {
          Line1
          Line2
          Country
          State
          City
          PostalCode
        }
        PermanentAddress {
          Line1
          Line2
          Country
          State
          City
          PostalCode
        }
        SameAddress
      }
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
  }
}
    `;

/**
 * __useGetProviderListByCourseQuery__
 *
 * To run a query within a React component, call `useGetProviderListByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderListByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderListByCourseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProviderListByCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProviderListByCourseQuery, GetProviderListByCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProviderListByCourseQuery, GetProviderListByCourseQueryVariables>(GetProviderListByCourseDocument, baseOptions);
      }
export function useGetProviderListByCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProviderListByCourseQuery, GetProviderListByCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProviderListByCourseQuery, GetProviderListByCourseQueryVariables>(GetProviderListByCourseDocument, baseOptions);
        }
export type GetProviderListByCourseQueryHookResult = ReturnType<typeof useGetProviderListByCourseQuery>;
export type GetProviderListByCourseLazyQueryHookResult = ReturnType<typeof useGetProviderListByCourseLazyQuery>;
export type GetProviderListByCourseQueryResult = ApolloReactCommon.QueryResult<GetProviderListByCourseQuery, GetProviderListByCourseQueryVariables>;
export const AuthorisedTrainingProviderSubmitDocument = gql`
    mutation AuthorisedTrainingProviderSubmit($input: AuthorisedTrainingProviderInput!) {
  AuthorisedTrainingProviderSubmit(input: $input)
}
    `;
export type AuthorisedTrainingProviderSubmitMutationFn = ApolloReactCommon.MutationFunction<AuthorisedTrainingProviderSubmitMutation, AuthorisedTrainingProviderSubmitMutationVariables>;

/**
 * __useAuthorisedTrainingProviderSubmitMutation__
 *
 * To run a mutation, you first call `useAuthorisedTrainingProviderSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorisedTrainingProviderSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorisedTrainingProviderSubmitMutation, { data, loading, error }] = useAuthorisedTrainingProviderSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthorisedTrainingProviderSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthorisedTrainingProviderSubmitMutation, AuthorisedTrainingProviderSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthorisedTrainingProviderSubmitMutation, AuthorisedTrainingProviderSubmitMutationVariables>(AuthorisedTrainingProviderSubmitDocument, baseOptions);
      }
export type AuthorisedTrainingProviderSubmitMutationHookResult = ReturnType<typeof useAuthorisedTrainingProviderSubmitMutation>;
export type AuthorisedTrainingProviderSubmitMutationResult = ApolloReactCommon.MutationResult<AuthorisedTrainingProviderSubmitMutation>;
export type AuthorisedTrainingProviderSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<AuthorisedTrainingProviderSubmitMutation, AuthorisedTrainingProviderSubmitMutationVariables>;
export const StatesListingDocument = gql`
    query StatesListing($Country: String!) {
  StatesListing(Country: $Country) {
    StatesID
    Description
  }
}
    `;

/**
 * __useStatesListingQuery__
 *
 * To run a query within a React component, call `useStatesListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatesListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatesListingQuery({
 *   variables: {
 *      Country: // value for 'Country'
 *   },
 * });
 */
export function useStatesListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatesListingQuery, StatesListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StatesListingQuery, StatesListingQueryVariables>(StatesListingDocument, baseOptions);
      }
export function useStatesListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatesListingQuery, StatesListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StatesListingQuery, StatesListingQueryVariables>(StatesListingDocument, baseOptions);
        }
export type StatesListingQueryHookResult = ReturnType<typeof useStatesListingQuery>;
export type StatesListingLazyQueryHookResult = ReturnType<typeof useStatesListingLazyQuery>;
export type StatesListingQueryResult = ApolloReactCommon.QueryResult<StatesListingQuery, StatesListingQueryVariables>;
export const TrainingCostItemListingDocument = gql`
    query TrainingCostItemListing {
  TrainingCostItemListing {
    ID
    SubscriptionAccountID
    isInUse
    Name
    DisplaySeq
    isInUse
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useTrainingCostItemListingQuery__
 *
 * To run a query within a React component, call `useTrainingCostItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingCostItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingCostItemListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrainingCostItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingCostItemListingQuery, TrainingCostItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingCostItemListingQuery, TrainingCostItemListingQueryVariables>(TrainingCostItemListingDocument, baseOptions);
      }
export function useTrainingCostItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingCostItemListingQuery, TrainingCostItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingCostItemListingQuery, TrainingCostItemListingQueryVariables>(TrainingCostItemListingDocument, baseOptions);
        }
export type TrainingCostItemListingQueryHookResult = ReturnType<typeof useTrainingCostItemListingQuery>;
export type TrainingCostItemListingLazyQueryHookResult = ReturnType<typeof useTrainingCostItemListingLazyQuery>;
export type TrainingCostItemListingQueryResult = ApolloReactCommon.QueryResult<TrainingCostItemListingQuery, TrainingCostItemListingQueryVariables>;
export const TrainingCostItemBulkInsertDocument = gql`
    mutation TrainingCostItemBulkInsert($input: [TrainingCostItemInput!]!) {
  TrainingCostItemBulkInsert(input: $input)
}
    `;
export type TrainingCostItemBulkInsertMutationFn = ApolloReactCommon.MutationFunction<TrainingCostItemBulkInsertMutation, TrainingCostItemBulkInsertMutationVariables>;

/**
 * __useTrainingCostItemBulkInsertMutation__
 *
 * To run a mutation, you first call `useTrainingCostItemBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrainingCostItemBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trainingCostItemBulkInsertMutation, { data, loading, error }] = useTrainingCostItemBulkInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrainingCostItemBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TrainingCostItemBulkInsertMutation, TrainingCostItemBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TrainingCostItemBulkInsertMutation, TrainingCostItemBulkInsertMutationVariables>(TrainingCostItemBulkInsertDocument, baseOptions);
      }
export type TrainingCostItemBulkInsertMutationHookResult = ReturnType<typeof useTrainingCostItemBulkInsertMutation>;
export type TrainingCostItemBulkInsertMutationResult = ApolloReactCommon.MutationResult<TrainingCostItemBulkInsertMutation>;
export type TrainingCostItemBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TrainingCostItemBulkInsertMutation, TrainingCostItemBulkInsertMutationVariables>;
export const TrainingCostItemDeleteDocument = gql`
    mutation TrainingCostItemDelete($TrainingCostItemID: String!) {
  TrainingCostItemDelete(ID: $TrainingCostItemID)
}
    `;
export type TrainingCostItemDeleteMutationFn = ApolloReactCommon.MutationFunction<TrainingCostItemDeleteMutation, TrainingCostItemDeleteMutationVariables>;

/**
 * __useTrainingCostItemDeleteMutation__
 *
 * To run a mutation, you first call `useTrainingCostItemDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrainingCostItemDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trainingCostItemDeleteMutation, { data, loading, error }] = useTrainingCostItemDeleteMutation({
 *   variables: {
 *      TrainingCostItemID: // value for 'TrainingCostItemID'
 *   },
 * });
 */
export function useTrainingCostItemDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TrainingCostItemDeleteMutation, TrainingCostItemDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TrainingCostItemDeleteMutation, TrainingCostItemDeleteMutationVariables>(TrainingCostItemDeleteDocument, baseOptions);
      }
export type TrainingCostItemDeleteMutationHookResult = ReturnType<typeof useTrainingCostItemDeleteMutation>;
export type TrainingCostItemDeleteMutationResult = ApolloReactCommon.MutationResult<TrainingCostItemDeleteMutation>;
export type TrainingCostItemDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TrainingCostItemDeleteMutation, TrainingCostItemDeleteMutationVariables>;
export const GetDataImportTemplateDocument = gql`
    query GetDataImportTemplate($Type: String!) {
  GetDataImportTemplate(Type: $Type)
}
    `;

/**
 * __useGetDataImportTemplateQuery__
 *
 * To run a query within a React component, call `useGetDataImportTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataImportTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataImportTemplateQuery({
 *   variables: {
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useGetDataImportTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDataImportTemplateQuery, GetDataImportTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDataImportTemplateQuery, GetDataImportTemplateQueryVariables>(GetDataImportTemplateDocument, baseOptions);
      }
export function useGetDataImportTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDataImportTemplateQuery, GetDataImportTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDataImportTemplateQuery, GetDataImportTemplateQueryVariables>(GetDataImportTemplateDocument, baseOptions);
        }
export type GetDataImportTemplateQueryHookResult = ReturnType<typeof useGetDataImportTemplateQuery>;
export type GetDataImportTemplateLazyQueryHookResult = ReturnType<typeof useGetDataImportTemplateLazyQuery>;
export type GetDataImportTemplateQueryResult = ApolloReactCommon.QueryResult<GetDataImportTemplateQuery, GetDataImportTemplateQueryVariables>;
export const DataImportVerifyDocument = gql`
    mutation DataImportVerify($Type: String!, $File: Upload!, $PayItemMapping: [PayItemMappingInput!]) {
  DataImportVerify(Type: $Type, File: $File, PayItemMapping: $PayItemMapping)
}
    `;
export type DataImportVerifyMutationFn = ApolloReactCommon.MutationFunction<DataImportVerifyMutation, DataImportVerifyMutationVariables>;

/**
 * __useDataImportVerifyMutation__
 *
 * To run a mutation, you first call `useDataImportVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDataImportVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dataImportVerifyMutation, { data, loading, error }] = useDataImportVerifyMutation({
 *   variables: {
 *      Type: // value for 'Type'
 *      File: // value for 'File'
 *      PayItemMapping: // value for 'PayItemMapping'
 *   },
 * });
 */
export function useDataImportVerifyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DataImportVerifyMutation, DataImportVerifyMutationVariables>) {
        return ApolloReactHooks.useMutation<DataImportVerifyMutation, DataImportVerifyMutationVariables>(DataImportVerifyDocument, baseOptions);
      }
export type DataImportVerifyMutationHookResult = ReturnType<typeof useDataImportVerifyMutation>;
export type DataImportVerifyMutationResult = ApolloReactCommon.MutationResult<DataImportVerifyMutation>;
export type DataImportVerifyMutationOptions = ApolloReactCommon.BaseMutationOptions<DataImportVerifyMutation, DataImportVerifyMutationVariables>;
export const ShopeeDataImportVerifyDocument = gql`
    mutation ShopeeDataImportVerify($File: Upload!, $ColumnDataMapping: [String!]!, $PayItemMapping: [PayItemMappingInput!]!, $PenaltyBreakdownInfo: [PenaltyBreakdownInfoInput!]!) {
  ShopeeDataImportVerify(File: $File, ColumnDataMapping: $ColumnDataMapping, PayItemMapping: $PayItemMapping, PenaltyBreakdownInfo: $PenaltyBreakdownInfo)
}
    `;
export type ShopeeDataImportVerifyMutationFn = ApolloReactCommon.MutationFunction<ShopeeDataImportVerifyMutation, ShopeeDataImportVerifyMutationVariables>;

/**
 * __useShopeeDataImportVerifyMutation__
 *
 * To run a mutation, you first call `useShopeeDataImportVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopeeDataImportVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopeeDataImportVerifyMutation, { data, loading, error }] = useShopeeDataImportVerifyMutation({
 *   variables: {
 *      File: // value for 'File'
 *      ColumnDataMapping: // value for 'ColumnDataMapping'
 *      PayItemMapping: // value for 'PayItemMapping'
 *      PenaltyBreakdownInfo: // value for 'PenaltyBreakdownInfo'
 *   },
 * });
 */
export function useShopeeDataImportVerifyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShopeeDataImportVerifyMutation, ShopeeDataImportVerifyMutationVariables>) {
        return ApolloReactHooks.useMutation<ShopeeDataImportVerifyMutation, ShopeeDataImportVerifyMutationVariables>(ShopeeDataImportVerifyDocument, baseOptions);
      }
export type ShopeeDataImportVerifyMutationHookResult = ReturnType<typeof useShopeeDataImportVerifyMutation>;
export type ShopeeDataImportVerifyMutationResult = ApolloReactCommon.MutationResult<ShopeeDataImportVerifyMutation>;
export type ShopeeDataImportVerifyMutationOptions = ApolloReactCommon.BaseMutationOptions<ShopeeDataImportVerifyMutation, ShopeeDataImportVerifyMutationVariables>;
export const ShopeeImportDocument = gql`
    query ShopeeImport($File: Upload!, $ColumnDataMapping: [String!]!, $PayItemMapping: [PayItemMappingInput!]!, $PenaltyBreakdownInfo: [PenaltyBreakdownInfoInput!]!, $CompanyID: String!, $IsLastProcess: Boolean!, $PayPeriodID: String!, $PayrollCycleID: String!, $SequenceNo: Float!) {
  ShopeeImport(File: $File, ColumnDataMapping: $ColumnDataMapping, PayItemMapping: $PayItemMapping, PenaltyBreakdownInfo: $PenaltyBreakdownInfo, CompanyID: $CompanyID, IsLastProcess: $IsLastProcess, PayPeriodID: $PayPeriodID, PayrollCycleID: $PayrollCycleID, SequenceNo: $SequenceNo)
}
    `;

/**
 * __useShopeeImportQuery__
 *
 * To run a query within a React component, call `useShopeeImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopeeImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopeeImportQuery({
 *   variables: {
 *      File: // value for 'File'
 *      ColumnDataMapping: // value for 'ColumnDataMapping'
 *      PayItemMapping: // value for 'PayItemMapping'
 *      PenaltyBreakdownInfo: // value for 'PenaltyBreakdownInfo'
 *      CompanyID: // value for 'CompanyID'
 *      IsLastProcess: // value for 'IsLastProcess'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      SequenceNo: // value for 'SequenceNo'
 *   },
 * });
 */
export function useShopeeImportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShopeeImportQuery, ShopeeImportQueryVariables>) {
        return ApolloReactHooks.useQuery<ShopeeImportQuery, ShopeeImportQueryVariables>(ShopeeImportDocument, baseOptions);
      }
export function useShopeeImportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShopeeImportQuery, ShopeeImportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShopeeImportQuery, ShopeeImportQueryVariables>(ShopeeImportDocument, baseOptions);
        }
export type ShopeeImportQueryHookResult = ReturnType<typeof useShopeeImportQuery>;
export type ShopeeImportLazyQueryHookResult = ReturnType<typeof useShopeeImportLazyQuery>;
export type ShopeeImportQueryResult = ApolloReactCommon.QueryResult<ShopeeImportQuery, ShopeeImportQueryVariables>;
export const RemoveTempDataDocument = gql`
    mutation RemoveTempData($Type: String!) {
  RemoveTempData(Type: $Type)
}
    `;
export type RemoveTempDataMutationFn = ApolloReactCommon.MutationFunction<RemoveTempDataMutation, RemoveTempDataMutationVariables>;

/**
 * __useRemoveTempDataMutation__
 *
 * To run a mutation, you first call `useRemoveTempDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTempDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTempDataMutation, { data, loading, error }] = useRemoveTempDataMutation({
 *   variables: {
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useRemoveTempDataMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTempDataMutation, RemoveTempDataMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTempDataMutation, RemoveTempDataMutationVariables>(RemoveTempDataDocument, baseOptions);
      }
export type RemoveTempDataMutationHookResult = ReturnType<typeof useRemoveTempDataMutation>;
export type RemoveTempDataMutationResult = ApolloReactCommon.MutationResult<RemoveTempDataMutation>;
export type RemoveTempDataMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTempDataMutation, RemoveTempDataMutationVariables>;
export const ProceedForImportDocument = gql`
    mutation ProceedForImport($Type: String!) {
  ProceedForImport(Type: $Type)
}
    `;
export type ProceedForImportMutationFn = ApolloReactCommon.MutationFunction<ProceedForImportMutation, ProceedForImportMutationVariables>;

/**
 * __useProceedForImportMutation__
 *
 * To run a mutation, you first call `useProceedForImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProceedForImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [proceedForImportMutation, { data, loading, error }] = useProceedForImportMutation({
 *   variables: {
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useProceedForImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProceedForImportMutation, ProceedForImportMutationVariables>) {
        return ApolloReactHooks.useMutation<ProceedForImportMutation, ProceedForImportMutationVariables>(ProceedForImportDocument, baseOptions);
      }
export type ProceedForImportMutationHookResult = ReturnType<typeof useProceedForImportMutation>;
export type ProceedForImportMutationResult = ApolloReactCommon.MutationResult<ProceedForImportMutation>;
export type ProceedForImportMutationOptions = ApolloReactCommon.BaseMutationOptions<ProceedForImportMutation, ProceedForImportMutationVariables>;
export const EmployeePendingActivateListingDocument = gql`
    query EmployeePendingActivateListing($SubscriptionAccountID: String!) {
  EmployeePendingActivateListing(SubscriptionAccountID: $SubscriptionAccountID) {
    ContactID
    FullName
    Email
  }
}
    `;

/**
 * __useEmployeePendingActivateListingQuery__
 *
 * To run a query within a React component, call `useEmployeePendingActivateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeePendingActivateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeePendingActivateListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useEmployeePendingActivateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeePendingActivateListingQuery, EmployeePendingActivateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeePendingActivateListingQuery, EmployeePendingActivateListingQueryVariables>(EmployeePendingActivateListingDocument, baseOptions);
      }
export function useEmployeePendingActivateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeePendingActivateListingQuery, EmployeePendingActivateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeePendingActivateListingQuery, EmployeePendingActivateListingQueryVariables>(EmployeePendingActivateListingDocument, baseOptions);
        }
export type EmployeePendingActivateListingQueryHookResult = ReturnType<typeof useEmployeePendingActivateListingQuery>;
export type EmployeePendingActivateListingLazyQueryHookResult = ReturnType<typeof useEmployeePendingActivateListingLazyQuery>;
export type EmployeePendingActivateListingQueryResult = ApolloReactCommon.QueryResult<EmployeePendingActivateListingQuery, EmployeePendingActivateListingQueryVariables>;
export const ActivateEmployeesDocument = gql`
    mutation ActivateEmployees($IsSelectAll: Boolean!, $Emails: [String!]!) {
  ActivateEmployees(IsSelectAll: $IsSelectAll, Emails: $Emails)
}
    `;
export type ActivateEmployeesMutationFn = ApolloReactCommon.MutationFunction<ActivateEmployeesMutation, ActivateEmployeesMutationVariables>;

/**
 * __useActivateEmployeesMutation__
 *
 * To run a mutation, you first call `useActivateEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateEmployeesMutation, { data, loading, error }] = useActivateEmployeesMutation({
 *   variables: {
 *      IsSelectAll: // value for 'IsSelectAll'
 *      Emails: // value for 'Emails'
 *   },
 * });
 */
export function useActivateEmployeesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateEmployeesMutation, ActivateEmployeesMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateEmployeesMutation, ActivateEmployeesMutationVariables>(ActivateEmployeesDocument, baseOptions);
      }
export type ActivateEmployeesMutationHookResult = ReturnType<typeof useActivateEmployeesMutation>;
export type ActivateEmployeesMutationResult = ApolloReactCommon.MutationResult<ActivateEmployeesMutation>;
export type ActivateEmployeesMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateEmployeesMutation, ActivateEmployeesMutationVariables>;
export const NewsPublishDocument = gql`
    mutation NewsPublish($NewsID: String!, $PostStatus: PostStatus!) {
  NewsPublish(NewsID: $NewsID, PostStatus: $PostStatus)
}
    `;
export type NewsPublishMutationFn = ApolloReactCommon.MutationFunction<NewsPublishMutation, NewsPublishMutationVariables>;

/**
 * __useNewsPublishMutation__
 *
 * To run a mutation, you first call `useNewsPublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsPublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsPublishMutation, { data, loading, error }] = useNewsPublishMutation({
 *   variables: {
 *      NewsID: // value for 'NewsID'
 *      PostStatus: // value for 'PostStatus'
 *   },
 * });
 */
export function useNewsPublishMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewsPublishMutation, NewsPublishMutationVariables>) {
        return ApolloReactHooks.useMutation<NewsPublishMutation, NewsPublishMutationVariables>(NewsPublishDocument, baseOptions);
      }
export type NewsPublishMutationHookResult = ReturnType<typeof useNewsPublishMutation>;
export type NewsPublishMutationResult = ApolloReactCommon.MutationResult<NewsPublishMutation>;
export type NewsPublishMutationOptions = ApolloReactCommon.BaseMutationOptions<NewsPublishMutation, NewsPublishMutationVariables>;
export const NewsDuplicateDocument = gql`
    mutation NewsDuplicate($NewsID: String!, $ContentURL: Upload) {
  NewsDuplicate(NewsID: $NewsID, ContentURL: $ContentURL)
}
    `;
export type NewsDuplicateMutationFn = ApolloReactCommon.MutationFunction<NewsDuplicateMutation, NewsDuplicateMutationVariables>;

/**
 * __useNewsDuplicateMutation__
 *
 * To run a mutation, you first call `useNewsDuplicateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsDuplicateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsDuplicateMutation, { data, loading, error }] = useNewsDuplicateMutation({
 *   variables: {
 *      NewsID: // value for 'NewsID'
 *      ContentURL: // value for 'ContentURL'
 *   },
 * });
 */
export function useNewsDuplicateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewsDuplicateMutation, NewsDuplicateMutationVariables>) {
        return ApolloReactHooks.useMutation<NewsDuplicateMutation, NewsDuplicateMutationVariables>(NewsDuplicateDocument, baseOptions);
      }
export type NewsDuplicateMutationHookResult = ReturnType<typeof useNewsDuplicateMutation>;
export type NewsDuplicateMutationResult = ApolloReactCommon.MutationResult<NewsDuplicateMutation>;
export type NewsDuplicateMutationOptions = ApolloReactCommon.BaseMutationOptions<NewsDuplicateMutation, NewsDuplicateMutationVariables>;
export const UpdateEmployeeProfilePicDocument = gql`
    mutation updateEmployeeProfilePic($EmployeeID: String!, $DocumentsInput: DocumentsInput, $DocumentID: String) {
  EmployeeProfilePicUpdate(EmployeeID: $EmployeeID, DocumentsInput: $DocumentsInput, DocumentID: $DocumentID)
}
    `;
export type UpdateEmployeeProfilePicMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>;

/**
 * __useUpdateEmployeeProfilePicMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeProfilePicMutation, { data, loading, error }] = useUpdateEmployeeProfilePicMutation({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useUpdateEmployeeProfilePicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>(UpdateEmployeeProfilePicDocument, baseOptions);
      }
export type UpdateEmployeeProfilePicMutationHookResult = ReturnType<typeof useUpdateEmployeeProfilePicMutation>;
export type UpdateEmployeeProfilePicMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeProfilePicMutation>;
export type UpdateEmployeeProfilePicMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeProfilePicMutation, UpdateEmployeeProfilePicMutationVariables>;
export const EmployeeListForSpecificSectionDocument = gql`
    query EmployeeListForSpecificSection($CompanyID: String) {
  EmployeeListForSpecificSection(CompanyID: $CompanyID)
}
    `;

/**
 * __useEmployeeListForSpecificSectionQuery__
 *
 * To run a query within a React component, call `useEmployeeListForSpecificSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListForSpecificSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListForSpecificSectionQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useEmployeeListForSpecificSectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListForSpecificSectionQuery, EmployeeListForSpecificSectionQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListForSpecificSectionQuery, EmployeeListForSpecificSectionQueryVariables>(EmployeeListForSpecificSectionDocument, baseOptions);
      }
export function useEmployeeListForSpecificSectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListForSpecificSectionQuery, EmployeeListForSpecificSectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListForSpecificSectionQuery, EmployeeListForSpecificSectionQueryVariables>(EmployeeListForSpecificSectionDocument, baseOptions);
        }
export type EmployeeListForSpecificSectionQueryHookResult = ReturnType<typeof useEmployeeListForSpecificSectionQuery>;
export type EmployeeListForSpecificSectionLazyQueryHookResult = ReturnType<typeof useEmployeeListForSpecificSectionLazyQuery>;
export type EmployeeListForSpecificSectionQueryResult = ApolloReactCommon.QueryResult<EmployeeListForSpecificSectionQuery, EmployeeListForSpecificSectionQueryVariables>;
export const EmployeeListForReportingDocument = gql`
    query EmployeeListForReporting {
  EmployeeListForReporting
}
    `;

/**
 * __useEmployeeListForReportingQuery__
 *
 * To run a query within a React component, call `useEmployeeListForReportingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListForReportingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListForReportingQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeListForReportingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListForReportingQuery, EmployeeListForReportingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListForReportingQuery, EmployeeListForReportingQueryVariables>(EmployeeListForReportingDocument, baseOptions);
      }
export function useEmployeeListForReportingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListForReportingQuery, EmployeeListForReportingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListForReportingQuery, EmployeeListForReportingQueryVariables>(EmployeeListForReportingDocument, baseOptions);
        }
export type EmployeeListForReportingQueryHookResult = ReturnType<typeof useEmployeeListForReportingQuery>;
export type EmployeeListForReportingLazyQueryHookResult = ReturnType<typeof useEmployeeListForReportingLazyQuery>;
export type EmployeeListForReportingQueryResult = ApolloReactCommon.QueryResult<EmployeeListForReportingQuery, EmployeeListForReportingQueryVariables>;
export const PersonnelEFileListingDocument = gql`
    query PersonnelEFileListing($CompanyID: String!, $EmployeeStatus: [String!]!, $SortBy: String, $offset: Float, $limit: Float, $CompanyIDs: [String!]) {
  PersonnelEFileListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, SortBy: $SortBy, offset: $offset, limit: $limit, CompanyIDs: $CompanyIDs)
}
    `;

/**
 * __usePersonnelEFileListingQuery__
 *
 * To run a query within a React component, call `usePersonnelEFileListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelEFileListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelEFileListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      SortBy: // value for 'SortBy'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      CompanyIDs: // value for 'CompanyIDs'
 *   },
 * });
 */
export function usePersonnelEFileListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelEFileListingQuery, PersonnelEFileListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelEFileListingQuery, PersonnelEFileListingQueryVariables>(PersonnelEFileListingDocument, baseOptions);
      }
export function usePersonnelEFileListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelEFileListingQuery, PersonnelEFileListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelEFileListingQuery, PersonnelEFileListingQueryVariables>(PersonnelEFileListingDocument, baseOptions);
        }
export type PersonnelEFileListingQueryHookResult = ReturnType<typeof usePersonnelEFileListingQuery>;
export type PersonnelEFileListingLazyQueryHookResult = ReturnType<typeof usePersonnelEFileListingLazyQuery>;
export type PersonnelEFileListingQueryResult = ApolloReactCommon.QueryResult<PersonnelEFileListingQuery, PersonnelEFileListingQueryVariables>;
export const PersonnelEFileListingCountDocument = gql`
    query PersonnelEFileListingCount($CompanyID: String!, $EmployeeStatus: [String!]!, $CompanyIDs: [String!]) {
  PersonnelEFileListingCount(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, CompanyIDs: $CompanyIDs)
}
    `;

/**
 * __usePersonnelEFileListingCountQuery__
 *
 * To run a query within a React component, call `usePersonnelEFileListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelEFileListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelEFileListingCountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      CompanyIDs: // value for 'CompanyIDs'
 *   },
 * });
 */
export function usePersonnelEFileListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelEFileListingCountQuery, PersonnelEFileListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelEFileListingCountQuery, PersonnelEFileListingCountQueryVariables>(PersonnelEFileListingCountDocument, baseOptions);
      }
export function usePersonnelEFileListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelEFileListingCountQuery, PersonnelEFileListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelEFileListingCountQuery, PersonnelEFileListingCountQueryVariables>(PersonnelEFileListingCountDocument, baseOptions);
        }
export type PersonnelEFileListingCountQueryHookResult = ReturnType<typeof usePersonnelEFileListingCountQuery>;
export type PersonnelEFileListingCountLazyQueryHookResult = ReturnType<typeof usePersonnelEFileListingCountLazyQuery>;
export type PersonnelEFileListingCountQueryResult = ApolloReactCommon.QueryResult<PersonnelEFileListingCountQuery, PersonnelEFileListingCountQueryVariables>;
export const EmployeeListingBySubscriptionAccountDocument = gql`
    query EmployeeListingBySubscriptionAccount($SubscriptionAccountID: String!, $DepartmentIDs: [String!]!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $CompanyID: String, $SortBy: String, $isDraft: Boolean, $offset: Float, $limit: Float, $ExcludeFormStatus: String) {
  EmployeeListingBySubscriptionAccount(SubscriptionAccountID: $SubscriptionAccountID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID, SortBy: $SortBy, isDraft: $isDraft, offset: $offset, limit: $limit, ExcludeFormStatus: $ExcludeFormStatus) {
    RetirementAge
    OrgUnitID
    EmployeeID
    EmployeeNo
    DepartmentID
    DivisionID
    JobGradeID
    OrgUnitID
    JoinedDate
    ResignedDate
    Status
    CompanyID
    CalendarTitle
    CalendarStateID
    Contact {
      ContactID
      FullName
      MobileNo
      Email
      Gender
      Race
      Religion
      Nationality
    }
    Company {
      CompanyID
      CompanyName
    }
    EmployeeStatutory {
      EmployeeStatutoryID
      ResidentStatus
      TaxMaritialStatus
    }
    EmployeePrevContribution {
      EmployeePrevContributionID
    }
    EmployeeDependents {
      EmployeeDependentsID
    }
    EmployeeSalaryLogs {
      CareerLogID
      EffectiveDate
      FromSalary
      ToSalary
      SalaryAdjustmentAmount
      SalaryType
      Reason
      Remark
    }
    Department {
      DepartmentID
      DepartmentType
      ParentID
      Description
    }
    Designation {
      JobDesignationID
      Name
    }
    DocumentID
    Documents {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    LastUpdatedDates {
      TaxLastDate
      Cp22LastDate
      BIKLastDate
    }
    EmployeeBankInfo {
      PaymentMethod
    }
    EmployeeCareerLogs {
      CareerLogID
      EffectiveDate
      FromCompany
      ToCompany
      FromOrgUnit
      ToOrgUnit
      FromJobGrade
      ToJobGrade
      FromPosition
      ToPosition
    }
    JobGrade {
      JobGradeID
      Description
    }
    JobGradeID
    TotalTaxRelief
    FormStatus
    CalendarStateID
    StaffCategoryID
    EmployeeStatus
    EmploymentType
    CreatedDT
    ModifiedDT
    RoleID
    RoleName
    JoinedDate
    JoinedReferenceDate
    ResignedDate
    FormStatus
  }
}
    `;

/**
 * __useEmployeeListingBySubscriptionAccountQuery__
 *
 * To run a query within a React component, call `useEmployeeListingBySubscriptionAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListingBySubscriptionAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListingBySubscriptionAccountQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      CompanyID: // value for 'CompanyID'
 *      SortBy: // value for 'SortBy'
 *      isDraft: // value for 'isDraft'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *   },
 * });
 */
export function useEmployeeListingBySubscriptionAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListingBySubscriptionAccountQuery, EmployeeListingBySubscriptionAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListingBySubscriptionAccountQuery, EmployeeListingBySubscriptionAccountQueryVariables>(EmployeeListingBySubscriptionAccountDocument, baseOptions);
      }
export function useEmployeeListingBySubscriptionAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListingBySubscriptionAccountQuery, EmployeeListingBySubscriptionAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListingBySubscriptionAccountQuery, EmployeeListingBySubscriptionAccountQueryVariables>(EmployeeListingBySubscriptionAccountDocument, baseOptions);
        }
export type EmployeeListingBySubscriptionAccountQueryHookResult = ReturnType<typeof useEmployeeListingBySubscriptionAccountQuery>;
export type EmployeeListingBySubscriptionAccountLazyQueryHookResult = ReturnType<typeof useEmployeeListingBySubscriptionAccountLazyQuery>;
export type EmployeeListingBySubscriptionAccountQueryResult = ApolloReactCommon.QueryResult<EmployeeListingBySubscriptionAccountQuery, EmployeeListingBySubscriptionAccountQueryVariables>;
export const EmployeeListingBySubscriptionAccountCountDocument = gql`
    query EmployeeListingBySubscriptionAccountCount($DepartmentIDs: [String!]!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $CompanyID: String, $SortBy: String, $isDraft: Boolean, $ExcludeFormStatus: String) {
  EmployeeListingBySubscriptionAccountCount(EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID, SortBy: $SortBy, isDraft: $isDraft, ExcludeFormStatus: $ExcludeFormStatus)
}
    `;

/**
 * __useEmployeeListingBySubscriptionAccountCountQuery__
 *
 * To run a query within a React component, call `useEmployeeListingBySubscriptionAccountCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListingBySubscriptionAccountCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListingBySubscriptionAccountCountQuery({
 *   variables: {
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      CompanyID: // value for 'CompanyID'
 *      SortBy: // value for 'SortBy'
 *      isDraft: // value for 'isDraft'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *   },
 * });
 */
export function useEmployeeListingBySubscriptionAccountCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListingBySubscriptionAccountCountQuery, EmployeeListingBySubscriptionAccountCountQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListingBySubscriptionAccountCountQuery, EmployeeListingBySubscriptionAccountCountQueryVariables>(EmployeeListingBySubscriptionAccountCountDocument, baseOptions);
      }
export function useEmployeeListingBySubscriptionAccountCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListingBySubscriptionAccountCountQuery, EmployeeListingBySubscriptionAccountCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListingBySubscriptionAccountCountQuery, EmployeeListingBySubscriptionAccountCountQueryVariables>(EmployeeListingBySubscriptionAccountCountDocument, baseOptions);
        }
export type EmployeeListingBySubscriptionAccountCountQueryHookResult = ReturnType<typeof useEmployeeListingBySubscriptionAccountCountQuery>;
export type EmployeeListingBySubscriptionAccountCountLazyQueryHookResult = ReturnType<typeof useEmployeeListingBySubscriptionAccountCountLazyQuery>;
export type EmployeeListingBySubscriptionAccountCountQueryResult = ApolloReactCommon.QueryResult<EmployeeListingBySubscriptionAccountCountQuery, EmployeeListingBySubscriptionAccountCountQueryVariables>;
export const EmployeeListingByRoleIdDocument = gql`
    query EmployeeListingByRoleID($SubscriptionAccountID: String!, $CompanyID: String!, $RoleID: String!) {
  EmployeeListingByRoleID(SubscriptionAccountID: $SubscriptionAccountID, CompanyID: $CompanyID, RoleID: $RoleID) {
    EmployeeID
    EmployeeNo
    Contact {
      ContactID
      FullName
      MobileNo
      Email
    }
    RoleID
    RoleName
  }
}
    `;

/**
 * __useEmployeeListingByRoleIdQuery__
 *
 * To run a query within a React component, call `useEmployeeListingByRoleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListingByRoleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListingByRoleIdQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      CompanyID: // value for 'CompanyID'
 *      RoleID: // value for 'RoleID'
 *   },
 * });
 */
export function useEmployeeListingByRoleIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListingByRoleIdQuery, EmployeeListingByRoleIdQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListingByRoleIdQuery, EmployeeListingByRoleIdQueryVariables>(EmployeeListingByRoleIdDocument, baseOptions);
      }
export function useEmployeeListingByRoleIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListingByRoleIdQuery, EmployeeListingByRoleIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListingByRoleIdQuery, EmployeeListingByRoleIdQueryVariables>(EmployeeListingByRoleIdDocument, baseOptions);
        }
export type EmployeeListingByRoleIdQueryHookResult = ReturnType<typeof useEmployeeListingByRoleIdQuery>;
export type EmployeeListingByRoleIdLazyQueryHookResult = ReturnType<typeof useEmployeeListingByRoleIdLazyQuery>;
export type EmployeeListingByRoleIdQueryResult = ApolloReactCommon.QueryResult<EmployeeListingByRoleIdQuery, EmployeeListingByRoleIdQueryVariables>;
export const EmployeeInfoUpdateDocument = gql`
    mutation EmployeeInfoUpdate($ContactInput: ContactInput!, $EmployeeInput: EmployeeInput!, $DocumentsInput: DocumentsInput, $DocumentID: String) {
  EmployeeInfoUpdate(ContactInput: $ContactInput, EmployeeInput: $EmployeeInput, DocumentsInput: $DocumentsInput, DocumentID: $DocumentID)
}
    `;
export type EmployeeInfoUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeInfoUpdateMutation, EmployeeInfoUpdateMutationVariables>;

/**
 * __useEmployeeInfoUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeInfoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeInfoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeInfoUpdateMutation, { data, loading, error }] = useEmployeeInfoUpdateMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      EmployeeInput: // value for 'EmployeeInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useEmployeeInfoUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeInfoUpdateMutation, EmployeeInfoUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeInfoUpdateMutation, EmployeeInfoUpdateMutationVariables>(EmployeeInfoUpdateDocument, baseOptions);
      }
export type EmployeeInfoUpdateMutationHookResult = ReturnType<typeof useEmployeeInfoUpdateMutation>;
export type EmployeeInfoUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeInfoUpdateMutation>;
export type EmployeeInfoUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeInfoUpdateMutation, EmployeeInfoUpdateMutationVariables>;
export const CreateEmployeeDiaryDocument = gql`
    mutation CreateEmployeeDiary($EmployeeDiaryInput: EmployeeDiaryInput!, $DocumentsInput: DocumentsInput!) {
  CreateEmployeeDiary(EmployeeDiaryInput: $EmployeeDiaryInput, DocumentsInput: $DocumentsInput)
}
    `;
export type CreateEmployeeDiaryMutationFn = ApolloReactCommon.MutationFunction<CreateEmployeeDiaryMutation, CreateEmployeeDiaryMutationVariables>;

/**
 * __useCreateEmployeeDiaryMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeDiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeDiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeDiaryMutation, { data, loading, error }] = useCreateEmployeeDiaryMutation({
 *   variables: {
 *      EmployeeDiaryInput: // value for 'EmployeeDiaryInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useCreateEmployeeDiaryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEmployeeDiaryMutation, CreateEmployeeDiaryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEmployeeDiaryMutation, CreateEmployeeDiaryMutationVariables>(CreateEmployeeDiaryDocument, baseOptions);
      }
export type CreateEmployeeDiaryMutationHookResult = ReturnType<typeof useCreateEmployeeDiaryMutation>;
export type CreateEmployeeDiaryMutationResult = ApolloReactCommon.MutationResult<CreateEmployeeDiaryMutation>;
export type CreateEmployeeDiaryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmployeeDiaryMutation, CreateEmployeeDiaryMutationVariables>;
export const UpdateEmployeeDiaryDocument = gql`
    mutation UpdateEmployeeDiary($EmployeeDiaryInput: EmployeeDiaryInput!, $EmployeeDiaryID: String!, $DocumentsInput: DocumentsInput!, $DocumentID: String!) {
  UpdateEmployeeDiary(EmployeeDiaryInput: $EmployeeDiaryInput, EmployeeDiaryID: $EmployeeDiaryID, DocumentsInput: $DocumentsInput, DocumentID: $DocumentID)
}
    `;
export type UpdateEmployeeDiaryMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeDiaryMutation, UpdateEmployeeDiaryMutationVariables>;

/**
 * __useUpdateEmployeeDiaryMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeDiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeDiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeDiaryMutation, { data, loading, error }] = useUpdateEmployeeDiaryMutation({
 *   variables: {
 *      EmployeeDiaryInput: // value for 'EmployeeDiaryInput'
 *      EmployeeDiaryID: // value for 'EmployeeDiaryID'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useUpdateEmployeeDiaryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeDiaryMutation, UpdateEmployeeDiaryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeDiaryMutation, UpdateEmployeeDiaryMutationVariables>(UpdateEmployeeDiaryDocument, baseOptions);
      }
export type UpdateEmployeeDiaryMutationHookResult = ReturnType<typeof useUpdateEmployeeDiaryMutation>;
export type UpdateEmployeeDiaryMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeDiaryMutation>;
export type UpdateEmployeeDiaryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeDiaryMutation, UpdateEmployeeDiaryMutationVariables>;
export const DeleteEmployeeDiaryDocument = gql`
    mutation DeleteEmployeeDiary($EmployeeDiaryID: String!) {
  DeleteEmployeeDiary(EmployeeDiaryID: $EmployeeDiaryID)
}
    `;
export type DeleteEmployeeDiaryMutationFn = ApolloReactCommon.MutationFunction<DeleteEmployeeDiaryMutation, DeleteEmployeeDiaryMutationVariables>;

/**
 * __useDeleteEmployeeDiaryMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeDiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeDiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeDiaryMutation, { data, loading, error }] = useDeleteEmployeeDiaryMutation({
 *   variables: {
 *      EmployeeDiaryID: // value for 'EmployeeDiaryID'
 *   },
 * });
 */
export function useDeleteEmployeeDiaryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEmployeeDiaryMutation, DeleteEmployeeDiaryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEmployeeDiaryMutation, DeleteEmployeeDiaryMutationVariables>(DeleteEmployeeDiaryDocument, baseOptions);
      }
export type DeleteEmployeeDiaryMutationHookResult = ReturnType<typeof useDeleteEmployeeDiaryMutation>;
export type DeleteEmployeeDiaryMutationResult = ApolloReactCommon.MutationResult<DeleteEmployeeDiaryMutation>;
export type DeleteEmployeeDiaryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEmployeeDiaryMutation, DeleteEmployeeDiaryMutationVariables>;
export const CreateEmployeeAppraisalDocument = gql`
    mutation CreateEmployeeAppraisal($EmployeeAppraisalInput: EmployeeAppraisalInput!, $DocumentsInput: DocumentsInput!) {
  CreateEmployeeAppraisal(EmployeeAppraisalInput: $EmployeeAppraisalInput, DocumentsInput: $DocumentsInput)
}
    `;
export type CreateEmployeeAppraisalMutationFn = ApolloReactCommon.MutationFunction<CreateEmployeeAppraisalMutation, CreateEmployeeAppraisalMutationVariables>;

/**
 * __useCreateEmployeeAppraisalMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeAppraisalMutation, { data, loading, error }] = useCreateEmployeeAppraisalMutation({
 *   variables: {
 *      EmployeeAppraisalInput: // value for 'EmployeeAppraisalInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useCreateEmployeeAppraisalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEmployeeAppraisalMutation, CreateEmployeeAppraisalMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEmployeeAppraisalMutation, CreateEmployeeAppraisalMutationVariables>(CreateEmployeeAppraisalDocument, baseOptions);
      }
export type CreateEmployeeAppraisalMutationHookResult = ReturnType<typeof useCreateEmployeeAppraisalMutation>;
export type CreateEmployeeAppraisalMutationResult = ApolloReactCommon.MutationResult<CreateEmployeeAppraisalMutation>;
export type CreateEmployeeAppraisalMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmployeeAppraisalMutation, CreateEmployeeAppraisalMutationVariables>;
export const UpdateEmployeeAppraisalDocument = gql`
    mutation UpdateEmployeeAppraisal($EmployeeAppraisalInput: EmployeeAppraisalInput!, $EmployeeAppraisalID: String!, $DocumentsInput: DocumentsInput!) {
  UpdateEmployeeAppraisal(EmployeeAppraisalInput: $EmployeeAppraisalInput, EmployeeAppraisalID: $EmployeeAppraisalID, DocumentsInput: $DocumentsInput)
}
    `;
export type UpdateEmployeeAppraisalMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeAppraisalMutation, UpdateEmployeeAppraisalMutationVariables>;

/**
 * __useUpdateEmployeeAppraisalMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeAppraisalMutation, { data, loading, error }] = useUpdateEmployeeAppraisalMutation({
 *   variables: {
 *      EmployeeAppraisalInput: // value for 'EmployeeAppraisalInput'
 *      EmployeeAppraisalID: // value for 'EmployeeAppraisalID'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useUpdateEmployeeAppraisalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeAppraisalMutation, UpdateEmployeeAppraisalMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeAppraisalMutation, UpdateEmployeeAppraisalMutationVariables>(UpdateEmployeeAppraisalDocument, baseOptions);
      }
export type UpdateEmployeeAppraisalMutationHookResult = ReturnType<typeof useUpdateEmployeeAppraisalMutation>;
export type UpdateEmployeeAppraisalMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeAppraisalMutation>;
export type UpdateEmployeeAppraisalMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeAppraisalMutation, UpdateEmployeeAppraisalMutationVariables>;
export const DeleteEmployeeAppraisalDocument = gql`
    mutation DeleteEmployeeAppraisal($EmployeeAppraisalID: String!) {
  DeleteEmployeeAppraisal(EmployeeAppraisalID: $EmployeeAppraisalID)
}
    `;
export type DeleteEmployeeAppraisalMutationFn = ApolloReactCommon.MutationFunction<DeleteEmployeeAppraisalMutation, DeleteEmployeeAppraisalMutationVariables>;

/**
 * __useDeleteEmployeeAppraisalMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeAppraisalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeAppraisalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeAppraisalMutation, { data, loading, error }] = useDeleteEmployeeAppraisalMutation({
 *   variables: {
 *      EmployeeAppraisalID: // value for 'EmployeeAppraisalID'
 *   },
 * });
 */
export function useDeleteEmployeeAppraisalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEmployeeAppraisalMutation, DeleteEmployeeAppraisalMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEmployeeAppraisalMutation, DeleteEmployeeAppraisalMutationVariables>(DeleteEmployeeAppraisalDocument, baseOptions);
      }
export type DeleteEmployeeAppraisalMutationHookResult = ReturnType<typeof useDeleteEmployeeAppraisalMutation>;
export type DeleteEmployeeAppraisalMutationResult = ApolloReactCommon.MutationResult<DeleteEmployeeAppraisalMutation>;
export type DeleteEmployeeAppraisalMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEmployeeAppraisalMutation, DeleteEmployeeAppraisalMutationVariables>;
export const CreateEmployeeTrainingDocument = gql`
    mutation CreateEmployeeTraining($EmployeeTrainingInput: EmployeeTrainingInput!) {
  CreateEmployeeTraining(EmployeeTrainingInput: $EmployeeTrainingInput)
}
    `;
export type CreateEmployeeTrainingMutationFn = ApolloReactCommon.MutationFunction<CreateEmployeeTrainingMutation, CreateEmployeeTrainingMutationVariables>;

/**
 * __useCreateEmployeeTrainingMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeTrainingMutation, { data, loading, error }] = useCreateEmployeeTrainingMutation({
 *   variables: {
 *      EmployeeTrainingInput: // value for 'EmployeeTrainingInput'
 *   },
 * });
 */
export function useCreateEmployeeTrainingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEmployeeTrainingMutation, CreateEmployeeTrainingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEmployeeTrainingMutation, CreateEmployeeTrainingMutationVariables>(CreateEmployeeTrainingDocument, baseOptions);
      }
export type CreateEmployeeTrainingMutationHookResult = ReturnType<typeof useCreateEmployeeTrainingMutation>;
export type CreateEmployeeTrainingMutationResult = ApolloReactCommon.MutationResult<CreateEmployeeTrainingMutation>;
export type CreateEmployeeTrainingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmployeeTrainingMutation, CreateEmployeeTrainingMutationVariables>;
export const UpdateEmployeeTrainingDocument = gql`
    mutation UpdateEmployeeTraining($EmployeeTrainingInput: EmployeeTrainingInput!, $EmployeeTrainingID: String!, $DocumentsInput: DocumentsInput!) {
  UpdateEmployeeTraining(EmployeeTrainingInput: $EmployeeTrainingInput, EmployeeTrainingID: $EmployeeTrainingID, DocumentsInput: $DocumentsInput)
}
    `;
export type UpdateEmployeeTrainingMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeTrainingMutation, UpdateEmployeeTrainingMutationVariables>;

/**
 * __useUpdateEmployeeTrainingMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeTrainingMutation, { data, loading, error }] = useUpdateEmployeeTrainingMutation({
 *   variables: {
 *      EmployeeTrainingInput: // value for 'EmployeeTrainingInput'
 *      EmployeeTrainingID: // value for 'EmployeeTrainingID'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useUpdateEmployeeTrainingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeTrainingMutation, UpdateEmployeeTrainingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeTrainingMutation, UpdateEmployeeTrainingMutationVariables>(UpdateEmployeeTrainingDocument, baseOptions);
      }
export type UpdateEmployeeTrainingMutationHookResult = ReturnType<typeof useUpdateEmployeeTrainingMutation>;
export type UpdateEmployeeTrainingMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeTrainingMutation>;
export type UpdateEmployeeTrainingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeTrainingMutation, UpdateEmployeeTrainingMutationVariables>;
export const DeleteEmployeeTrainingDocument = gql`
    mutation DeleteEmployeeTraining($EmployeeTrainingID: String!) {
  DeleteEmployeeTraining(EmployeeTrainingID: $EmployeeTrainingID)
}
    `;
export type DeleteEmployeeTrainingMutationFn = ApolloReactCommon.MutationFunction<DeleteEmployeeTrainingMutation, DeleteEmployeeTrainingMutationVariables>;

/**
 * __useDeleteEmployeeTrainingMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeTrainingMutation, { data, loading, error }] = useDeleteEmployeeTrainingMutation({
 *   variables: {
 *      EmployeeTrainingID: // value for 'EmployeeTrainingID'
 *   },
 * });
 */
export function useDeleteEmployeeTrainingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEmployeeTrainingMutation, DeleteEmployeeTrainingMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEmployeeTrainingMutation, DeleteEmployeeTrainingMutationVariables>(DeleteEmployeeTrainingDocument, baseOptions);
      }
export type DeleteEmployeeTrainingMutationHookResult = ReturnType<typeof useDeleteEmployeeTrainingMutation>;
export type DeleteEmployeeTrainingMutationResult = ApolloReactCommon.MutationResult<DeleteEmployeeTrainingMutation>;
export type DeleteEmployeeTrainingMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEmployeeTrainingMutation, DeleteEmployeeTrainingMutationVariables>;
export const GetEmployeeTrainingDocument = gql`
    query GetEmployeeTraining($EmployeeID: String) {
  GetEmployeeTraining(EmployeeID: $EmployeeID) {
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    EmployeeTrainingID
    EmployeeID
    CourseName
    StartDate
    EndDate
    TrainingHour
    TrainingProvider
    Trainer
    TrainerRating
    TrainerScore
    Superior
    SuperiorRating
    SuperiorScore
    Employee {
      EmployeeID
    }
  }
}
    `;

/**
 * __useGetEmployeeTrainingQuery__
 *
 * To run a query within a React component, call `useGetEmployeeTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeTrainingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetEmployeeTrainingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeTrainingQuery, GetEmployeeTrainingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeTrainingQuery, GetEmployeeTrainingQueryVariables>(GetEmployeeTrainingDocument, baseOptions);
      }
export function useGetEmployeeTrainingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeTrainingQuery, GetEmployeeTrainingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeTrainingQuery, GetEmployeeTrainingQueryVariables>(GetEmployeeTrainingDocument, baseOptions);
        }
export type GetEmployeeTrainingQueryHookResult = ReturnType<typeof useGetEmployeeTrainingQuery>;
export type GetEmployeeTrainingLazyQueryHookResult = ReturnType<typeof useGetEmployeeTrainingLazyQuery>;
export type GetEmployeeTrainingQueryResult = ApolloReactCommon.QueryResult<GetEmployeeTrainingQuery, GetEmployeeTrainingQueryVariables>;
export const PersonnelEducationListingDocument = gql`
    query PersonnelEducationListing($PersonnelEducationID: String, $PersonnelID: String) {
  PersonnelEducationListing(PersonnelEducationID: $PersonnelEducationID, PersonnelID: $PersonnelID) {
    PersonnelEducationID
    StartDate
    EndDate
    EducationLevel
    EducationLevelID
    InstitutionName
    Description
    MajorID
    Country
    Grade
    GradeID
    CGPA
    ModifiedDT
  }
}
    `;

/**
 * __usePersonnelEducationListingQuery__
 *
 * To run a query within a React component, call `usePersonnelEducationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelEducationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelEducationListingQuery({
 *   variables: {
 *      PersonnelEducationID: // value for 'PersonnelEducationID'
 *      PersonnelID: // value for 'PersonnelID'
 *   },
 * });
 */
export function usePersonnelEducationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelEducationListingQuery, PersonnelEducationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelEducationListingQuery, PersonnelEducationListingQueryVariables>(PersonnelEducationListingDocument, baseOptions);
      }
export function usePersonnelEducationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelEducationListingQuery, PersonnelEducationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelEducationListingQuery, PersonnelEducationListingQueryVariables>(PersonnelEducationListingDocument, baseOptions);
        }
export type PersonnelEducationListingQueryHookResult = ReturnType<typeof usePersonnelEducationListingQuery>;
export type PersonnelEducationListingLazyQueryHookResult = ReturnType<typeof usePersonnelEducationListingLazyQuery>;
export type PersonnelEducationListingQueryResult = ApolloReactCommon.QueryResult<PersonnelEducationListingQuery, PersonnelEducationListingQueryVariables>;
export const PersonnelEducationInsertDocument = gql`
    mutation PersonnelEducationInsert($PersonnelEducationInsertInput: PersonnelEducationInsertInput!) {
  PersonnelEducationInsert(PersonnelEducationInsertInput: $PersonnelEducationInsertInput)
}
    `;
export type PersonnelEducationInsertMutationFn = ApolloReactCommon.MutationFunction<PersonnelEducationInsertMutation, PersonnelEducationInsertMutationVariables>;

/**
 * __usePersonnelEducationInsertMutation__
 *
 * To run a mutation, you first call `usePersonnelEducationInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelEducationInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelEducationInsertMutation, { data, loading, error }] = usePersonnelEducationInsertMutation({
 *   variables: {
 *      PersonnelEducationInsertInput: // value for 'PersonnelEducationInsertInput'
 *   },
 * });
 */
export function usePersonnelEducationInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelEducationInsertMutation, PersonnelEducationInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelEducationInsertMutation, PersonnelEducationInsertMutationVariables>(PersonnelEducationInsertDocument, baseOptions);
      }
export type PersonnelEducationInsertMutationHookResult = ReturnType<typeof usePersonnelEducationInsertMutation>;
export type PersonnelEducationInsertMutationResult = ApolloReactCommon.MutationResult<PersonnelEducationInsertMutation>;
export type PersonnelEducationInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelEducationInsertMutation, PersonnelEducationInsertMutationVariables>;
export const PersonnelEducationUpdateDocument = gql`
    mutation PersonnelEducationUpdate($PersonnelEducationInsertInput: PersonnelEducationInsertInput!, $PersonnelEducationID: String!) {
  PersonnelEducationUpdate(PersonnelEducationInsertInput: $PersonnelEducationInsertInput, PersonnelEducationID: $PersonnelEducationID)
}
    `;
export type PersonnelEducationUpdateMutationFn = ApolloReactCommon.MutationFunction<PersonnelEducationUpdateMutation, PersonnelEducationUpdateMutationVariables>;

/**
 * __usePersonnelEducationUpdateMutation__
 *
 * To run a mutation, you first call `usePersonnelEducationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelEducationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelEducationUpdateMutation, { data, loading, error }] = usePersonnelEducationUpdateMutation({
 *   variables: {
 *      PersonnelEducationInsertInput: // value for 'PersonnelEducationInsertInput'
 *      PersonnelEducationID: // value for 'PersonnelEducationID'
 *   },
 * });
 */
export function usePersonnelEducationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelEducationUpdateMutation, PersonnelEducationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelEducationUpdateMutation, PersonnelEducationUpdateMutationVariables>(PersonnelEducationUpdateDocument, baseOptions);
      }
export type PersonnelEducationUpdateMutationHookResult = ReturnType<typeof usePersonnelEducationUpdateMutation>;
export type PersonnelEducationUpdateMutationResult = ApolloReactCommon.MutationResult<PersonnelEducationUpdateMutation>;
export type PersonnelEducationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelEducationUpdateMutation, PersonnelEducationUpdateMutationVariables>;
export const PersonnelEducationDeleteDocument = gql`
    mutation PersonnelEducationDelete($PersonnelEducationID: String!) {
  PersonnelEducationDelete(PersonnelEducationID: $PersonnelEducationID)
}
    `;
export type PersonnelEducationDeleteMutationFn = ApolloReactCommon.MutationFunction<PersonnelEducationDeleteMutation, PersonnelEducationDeleteMutationVariables>;

/**
 * __usePersonnelEducationDeleteMutation__
 *
 * To run a mutation, you first call `usePersonnelEducationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelEducationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelEducationDeleteMutation, { data, loading, error }] = usePersonnelEducationDeleteMutation({
 *   variables: {
 *      PersonnelEducationID: // value for 'PersonnelEducationID'
 *   },
 * });
 */
export function usePersonnelEducationDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelEducationDeleteMutation, PersonnelEducationDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelEducationDeleteMutation, PersonnelEducationDeleteMutationVariables>(PersonnelEducationDeleteDocument, baseOptions);
      }
export type PersonnelEducationDeleteMutationHookResult = ReturnType<typeof usePersonnelEducationDeleteMutation>;
export type PersonnelEducationDeleteMutationResult = ApolloReactCommon.MutationResult<PersonnelEducationDeleteMutation>;
export type PersonnelEducationDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelEducationDeleteMutation, PersonnelEducationDeleteMutationVariables>;
export const PersonnelParameterListingDocument = gql`
    query PersonnelParameterListing {
  PersonnelParameterListing {
    PersonnelParameterID
    SubscriptionAccountID
    Name
    IsDefault
    Voluntary
    PersonnelParameterType
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __usePersonnelParameterListingQuery__
 *
 * To run a query within a React component, call `usePersonnelParameterListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelParameterListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelParameterListingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonnelParameterListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelParameterListingQuery, PersonnelParameterListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelParameterListingQuery, PersonnelParameterListingQueryVariables>(PersonnelParameterListingDocument, baseOptions);
      }
export function usePersonnelParameterListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelParameterListingQuery, PersonnelParameterListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelParameterListingQuery, PersonnelParameterListingQueryVariables>(PersonnelParameterListingDocument, baseOptions);
        }
export type PersonnelParameterListingQueryHookResult = ReturnType<typeof usePersonnelParameterListingQuery>;
export type PersonnelParameterListingLazyQueryHookResult = ReturnType<typeof usePersonnelParameterListingLazyQuery>;
export type PersonnelParameterListingQueryResult = ApolloReactCommon.QueryResult<PersonnelParameterListingQuery, PersonnelParameterListingQueryVariables>;
export const HasEmployeeDocNumberDocument = gql`
    query HasEmployeeDocNumber($CompanyID: String!) {
  HasEmployeeDocNumber(CompanyID: $CompanyID)
}
    `;

/**
 * __useHasEmployeeDocNumberQuery__
 *
 * To run a query within a React component, call `useHasEmployeeDocNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasEmployeeDocNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasEmployeeDocNumberQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useHasEmployeeDocNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HasEmployeeDocNumberQuery, HasEmployeeDocNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<HasEmployeeDocNumberQuery, HasEmployeeDocNumberQueryVariables>(HasEmployeeDocNumberDocument, baseOptions);
      }
export function useHasEmployeeDocNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HasEmployeeDocNumberQuery, HasEmployeeDocNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HasEmployeeDocNumberQuery, HasEmployeeDocNumberQueryVariables>(HasEmployeeDocNumberDocument, baseOptions);
        }
export type HasEmployeeDocNumberQueryHookResult = ReturnType<typeof useHasEmployeeDocNumberQuery>;
export type HasEmployeeDocNumberLazyQueryHookResult = ReturnType<typeof useHasEmployeeDocNumberLazyQuery>;
export type HasEmployeeDocNumberQueryResult = ApolloReactCommon.QueryResult<HasEmployeeDocNumberQuery, HasEmployeeDocNumberQueryVariables>;
export const PersonnelCertificateInsertDocument = gql`
    mutation PersonnelCertificateInsert($Input: PersonnelCertificateInput!) {
  PersonnelCertificateInsert(Input: $Input)
}
    `;
export type PersonnelCertificateInsertMutationFn = ApolloReactCommon.MutationFunction<PersonnelCertificateInsertMutation, PersonnelCertificateInsertMutationVariables>;

/**
 * __usePersonnelCertificateInsertMutation__
 *
 * To run a mutation, you first call `usePersonnelCertificateInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelCertificateInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelCertificateInsertMutation, { data, loading, error }] = usePersonnelCertificateInsertMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function usePersonnelCertificateInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelCertificateInsertMutation, PersonnelCertificateInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelCertificateInsertMutation, PersonnelCertificateInsertMutationVariables>(PersonnelCertificateInsertDocument, baseOptions);
      }
export type PersonnelCertificateInsertMutationHookResult = ReturnType<typeof usePersonnelCertificateInsertMutation>;
export type PersonnelCertificateInsertMutationResult = ApolloReactCommon.MutationResult<PersonnelCertificateInsertMutation>;
export type PersonnelCertificateInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelCertificateInsertMutation, PersonnelCertificateInsertMutationVariables>;
export const PersonnelCertificateUpdateDocument = gql`
    mutation PersonnelCertificateUpdate($Input: PersonnelCertificateInput!, $PersonnelCertificateID: String!) {
  PersonnelCertificateUpdate(Input: $Input, PersonnelCertificateID: $PersonnelCertificateID)
}
    `;
export type PersonnelCertificateUpdateMutationFn = ApolloReactCommon.MutationFunction<PersonnelCertificateUpdateMutation, PersonnelCertificateUpdateMutationVariables>;

/**
 * __usePersonnelCertificateUpdateMutation__
 *
 * To run a mutation, you first call `usePersonnelCertificateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelCertificateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelCertificateUpdateMutation, { data, loading, error }] = usePersonnelCertificateUpdateMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *      PersonnelCertificateID: // value for 'PersonnelCertificateID'
 *   },
 * });
 */
export function usePersonnelCertificateUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelCertificateUpdateMutation, PersonnelCertificateUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelCertificateUpdateMutation, PersonnelCertificateUpdateMutationVariables>(PersonnelCertificateUpdateDocument, baseOptions);
      }
export type PersonnelCertificateUpdateMutationHookResult = ReturnType<typeof usePersonnelCertificateUpdateMutation>;
export type PersonnelCertificateUpdateMutationResult = ApolloReactCommon.MutationResult<PersonnelCertificateUpdateMutation>;
export type PersonnelCertificateUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelCertificateUpdateMutation, PersonnelCertificateUpdateMutationVariables>;
export const PersonnelCertificateDeleteDocument = gql`
    mutation PersonnelCertificateDelete($PersonnelCertificateID: String!) {
  PersonnelCertificateDelete(PersonnelCertificateID: $PersonnelCertificateID)
}
    `;
export type PersonnelCertificateDeleteMutationFn = ApolloReactCommon.MutationFunction<PersonnelCertificateDeleteMutation, PersonnelCertificateDeleteMutationVariables>;

/**
 * __usePersonnelCertificateDeleteMutation__
 *
 * To run a mutation, you first call `usePersonnelCertificateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelCertificateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelCertificateDeleteMutation, { data, loading, error }] = usePersonnelCertificateDeleteMutation({
 *   variables: {
 *      PersonnelCertificateID: // value for 'PersonnelCertificateID'
 *   },
 * });
 */
export function usePersonnelCertificateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelCertificateDeleteMutation, PersonnelCertificateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelCertificateDeleteMutation, PersonnelCertificateDeleteMutationVariables>(PersonnelCertificateDeleteDocument, baseOptions);
      }
export type PersonnelCertificateDeleteMutationHookResult = ReturnType<typeof usePersonnelCertificateDeleteMutation>;
export type PersonnelCertificateDeleteMutationResult = ApolloReactCommon.MutationResult<PersonnelCertificateDeleteMutation>;
export type PersonnelCertificateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelCertificateDeleteMutation, PersonnelCertificateDeleteMutationVariables>;
export const PersonnelReferenceListingDocument = gql`
    query PersonnelReferenceListing($PersonnelID: String!, $PersonnelReferenceID: String) {
  PersonnelReferenceListing(PersonnelID: $PersonnelID, PersonnelReferenceID: $PersonnelReferenceID) {
    PersonnelID
    PersonnelReferenceID
    Name
    MobileNo
    Email
    Relationship
    JobDesignation
    CompanyName
    Status
  }
}
    `;

/**
 * __usePersonnelReferenceListingQuery__
 *
 * To run a query within a React component, call `usePersonnelReferenceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelReferenceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelReferenceListingQuery({
 *   variables: {
 *      PersonnelID: // value for 'PersonnelID'
 *      PersonnelReferenceID: // value for 'PersonnelReferenceID'
 *   },
 * });
 */
export function usePersonnelReferenceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelReferenceListingQuery, PersonnelReferenceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelReferenceListingQuery, PersonnelReferenceListingQueryVariables>(PersonnelReferenceListingDocument, baseOptions);
      }
export function usePersonnelReferenceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelReferenceListingQuery, PersonnelReferenceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelReferenceListingQuery, PersonnelReferenceListingQueryVariables>(PersonnelReferenceListingDocument, baseOptions);
        }
export type PersonnelReferenceListingQueryHookResult = ReturnType<typeof usePersonnelReferenceListingQuery>;
export type PersonnelReferenceListingLazyQueryHookResult = ReturnType<typeof usePersonnelReferenceListingLazyQuery>;
export type PersonnelReferenceListingQueryResult = ApolloReactCommon.QueryResult<PersonnelReferenceListingQuery, PersonnelReferenceListingQueryVariables>;
export const PersonnelReferenceInsertDocument = gql`
    mutation PersonnelReferenceInsert($Input: PersonnelReferenceInput!) {
  PersonnelReferenceInsert(Input: $Input)
}
    `;
export type PersonnelReferenceInsertMutationFn = ApolloReactCommon.MutationFunction<PersonnelReferenceInsertMutation, PersonnelReferenceInsertMutationVariables>;

/**
 * __usePersonnelReferenceInsertMutation__
 *
 * To run a mutation, you first call `usePersonnelReferenceInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelReferenceInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelReferenceInsertMutation, { data, loading, error }] = usePersonnelReferenceInsertMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function usePersonnelReferenceInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelReferenceInsertMutation, PersonnelReferenceInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelReferenceInsertMutation, PersonnelReferenceInsertMutationVariables>(PersonnelReferenceInsertDocument, baseOptions);
      }
export type PersonnelReferenceInsertMutationHookResult = ReturnType<typeof usePersonnelReferenceInsertMutation>;
export type PersonnelReferenceInsertMutationResult = ApolloReactCommon.MutationResult<PersonnelReferenceInsertMutation>;
export type PersonnelReferenceInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelReferenceInsertMutation, PersonnelReferenceInsertMutationVariables>;
export const PersonnelReferenceUpdateDocument = gql`
    mutation PersonnelReferenceUpdate($Input: PersonnelReferenceInput!, $PersonnelReferenceID: String!) {
  PersonnelReferenceUpdate(Input: $Input, PersonnelReferenceID: $PersonnelReferenceID)
}
    `;
export type PersonnelReferenceUpdateMutationFn = ApolloReactCommon.MutationFunction<PersonnelReferenceUpdateMutation, PersonnelReferenceUpdateMutationVariables>;

/**
 * __usePersonnelReferenceUpdateMutation__
 *
 * To run a mutation, you first call `usePersonnelReferenceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelReferenceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelReferenceUpdateMutation, { data, loading, error }] = usePersonnelReferenceUpdateMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *      PersonnelReferenceID: // value for 'PersonnelReferenceID'
 *   },
 * });
 */
export function usePersonnelReferenceUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelReferenceUpdateMutation, PersonnelReferenceUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelReferenceUpdateMutation, PersonnelReferenceUpdateMutationVariables>(PersonnelReferenceUpdateDocument, baseOptions);
      }
export type PersonnelReferenceUpdateMutationHookResult = ReturnType<typeof usePersonnelReferenceUpdateMutation>;
export type PersonnelReferenceUpdateMutationResult = ApolloReactCommon.MutationResult<PersonnelReferenceUpdateMutation>;
export type PersonnelReferenceUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelReferenceUpdateMutation, PersonnelReferenceUpdateMutationVariables>;
export const PersonnelReferenceDeleteDocument = gql`
    mutation PersonnelReferenceDelete($PersonnelReferenceID: String!) {
  PersonnelReferenceDelete(PersonnelReferenceID: $PersonnelReferenceID)
}
    `;
export type PersonnelReferenceDeleteMutationFn = ApolloReactCommon.MutationFunction<PersonnelReferenceDeleteMutation, PersonnelReferenceDeleteMutationVariables>;

/**
 * __usePersonnelReferenceDeleteMutation__
 *
 * To run a mutation, you first call `usePersonnelReferenceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelReferenceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelReferenceDeleteMutation, { data, loading, error }] = usePersonnelReferenceDeleteMutation({
 *   variables: {
 *      PersonnelReferenceID: // value for 'PersonnelReferenceID'
 *   },
 * });
 */
export function usePersonnelReferenceDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelReferenceDeleteMutation, PersonnelReferenceDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelReferenceDeleteMutation, PersonnelReferenceDeleteMutationVariables>(PersonnelReferenceDeleteDocument, baseOptions);
      }
export type PersonnelReferenceDeleteMutationHookResult = ReturnType<typeof usePersonnelReferenceDeleteMutation>;
export type PersonnelReferenceDeleteMutationResult = ApolloReactCommon.MutationResult<PersonnelReferenceDeleteMutation>;
export type PersonnelReferenceDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelReferenceDeleteMutation, PersonnelReferenceDeleteMutationVariables>;
export const PersonnelHealthInfoSummaryDocument = gql`
    query PersonnelHealthInfoSummary($PersonnelID: String!) {
  PersonnelHealthInfoSummary(PersonnelID: $PersonnelID) {
    PersonnelHealthInfoID
    Height
    Weight
    BMI
    BloodType
    DietaryPreference
    IsSmoker
    VaccinationList
    VaccinationListSummary {
      vaccinationID
      vaccinationDate
      vaccinationAttachedment {
        DocumentID
        Description
        DocumentFile
        BucketFileName
      }
    }
    MedicalRecords {
      MedicalRecordID
      DocumentID
      Description
      Document {
        DocumentID
        Description
        DocumentFile
        BucketFileName
      }
    }
  }
}
    `;

/**
 * __usePersonnelHealthInfoSummaryQuery__
 *
 * To run a query within a React component, call `usePersonnelHealthInfoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelHealthInfoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelHealthInfoSummaryQuery({
 *   variables: {
 *      PersonnelID: // value for 'PersonnelID'
 *   },
 * });
 */
export function usePersonnelHealthInfoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelHealthInfoSummaryQuery, PersonnelHealthInfoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelHealthInfoSummaryQuery, PersonnelHealthInfoSummaryQueryVariables>(PersonnelHealthInfoSummaryDocument, baseOptions);
      }
export function usePersonnelHealthInfoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelHealthInfoSummaryQuery, PersonnelHealthInfoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelHealthInfoSummaryQuery, PersonnelHealthInfoSummaryQueryVariables>(PersonnelHealthInfoSummaryDocument, baseOptions);
        }
export type PersonnelHealthInfoSummaryQueryHookResult = ReturnType<typeof usePersonnelHealthInfoSummaryQuery>;
export type PersonnelHealthInfoSummaryLazyQueryHookResult = ReturnType<typeof usePersonnelHealthInfoSummaryLazyQuery>;
export type PersonnelHealthInfoSummaryQueryResult = ApolloReactCommon.QueryResult<PersonnelHealthInfoSummaryQuery, PersonnelHealthInfoSummaryQueryVariables>;
export const PersonnelHealthInfoInsertDocument = gql`
    mutation PersonnelHealthInfoInsert($PersonnelHealthInfoInput: PersonnelHealthInfoInput!, $MedicalRecordInput: [MedicalRecordInput!]!, $VaccinationRecordInput: [VaccinationRecordInput!]!) {
  PersonnelHealthInfoInsert(PersonnelHealthInfoInput: $PersonnelHealthInfoInput, MedicalRecordInput: $MedicalRecordInput, VaccinationRecordInput: $VaccinationRecordInput)
}
    `;
export type PersonnelHealthInfoInsertMutationFn = ApolloReactCommon.MutationFunction<PersonnelHealthInfoInsertMutation, PersonnelHealthInfoInsertMutationVariables>;

/**
 * __usePersonnelHealthInfoInsertMutation__
 *
 * To run a mutation, you first call `usePersonnelHealthInfoInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelHealthInfoInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelHealthInfoInsertMutation, { data, loading, error }] = usePersonnelHealthInfoInsertMutation({
 *   variables: {
 *      PersonnelHealthInfoInput: // value for 'PersonnelHealthInfoInput'
 *      MedicalRecordInput: // value for 'MedicalRecordInput'
 *      VaccinationRecordInput: // value for 'VaccinationRecordInput'
 *   },
 * });
 */
export function usePersonnelHealthInfoInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelHealthInfoInsertMutation, PersonnelHealthInfoInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelHealthInfoInsertMutation, PersonnelHealthInfoInsertMutationVariables>(PersonnelHealthInfoInsertDocument, baseOptions);
      }
export type PersonnelHealthInfoInsertMutationHookResult = ReturnType<typeof usePersonnelHealthInfoInsertMutation>;
export type PersonnelHealthInfoInsertMutationResult = ApolloReactCommon.MutationResult<PersonnelHealthInfoInsertMutation>;
export type PersonnelHealthInfoInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelHealthInfoInsertMutation, PersonnelHealthInfoInsertMutationVariables>;
export const PersonnelHealthInfoUpdateDocument = gql`
    mutation PersonnelHealthInfoUpdate($PersonnelHealthInfoInput: PersonnelHealthInfoInput!, $PersonnelHealthInfoID: String!, $MedicalRecordInput: [MedicalRecordInput!]!, $VaccinationRecordInput: [VaccinationRecordInput!]!, $AttachmentToRemove: [DocumentsInput!]) {
  PersonnelHealthInfoUpdate(PersonnelHealthInfoInput: $PersonnelHealthInfoInput, PersonnelHealthInfoID: $PersonnelHealthInfoID, MedicalRecordInput: $MedicalRecordInput, VaccinationRecordInput: $VaccinationRecordInput, AttachmentToRemove: $AttachmentToRemove)
}
    `;
export type PersonnelHealthInfoUpdateMutationFn = ApolloReactCommon.MutationFunction<PersonnelHealthInfoUpdateMutation, PersonnelHealthInfoUpdateMutationVariables>;

/**
 * __usePersonnelHealthInfoUpdateMutation__
 *
 * To run a mutation, you first call `usePersonnelHealthInfoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelHealthInfoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelHealthInfoUpdateMutation, { data, loading, error }] = usePersonnelHealthInfoUpdateMutation({
 *   variables: {
 *      PersonnelHealthInfoInput: // value for 'PersonnelHealthInfoInput'
 *      PersonnelHealthInfoID: // value for 'PersonnelHealthInfoID'
 *      MedicalRecordInput: // value for 'MedicalRecordInput'
 *      VaccinationRecordInput: // value for 'VaccinationRecordInput'
 *      AttachmentToRemove: // value for 'AttachmentToRemove'
 *   },
 * });
 */
export function usePersonnelHealthInfoUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelHealthInfoUpdateMutation, PersonnelHealthInfoUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelHealthInfoUpdateMutation, PersonnelHealthInfoUpdateMutationVariables>(PersonnelHealthInfoUpdateDocument, baseOptions);
      }
export type PersonnelHealthInfoUpdateMutationHookResult = ReturnType<typeof usePersonnelHealthInfoUpdateMutation>;
export type PersonnelHealthInfoUpdateMutationResult = ApolloReactCommon.MutationResult<PersonnelHealthInfoUpdateMutation>;
export type PersonnelHealthInfoUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelHealthInfoUpdateMutation, PersonnelHealthInfoUpdateMutationVariables>;
export const MedicalRecordDeleteDocument = gql`
    mutation MedicalRecordDelete($MedicalRecordID: String!) {
  MedicalRecordDelete(MedicalRecordID: $MedicalRecordID)
}
    `;
export type MedicalRecordDeleteMutationFn = ApolloReactCommon.MutationFunction<MedicalRecordDeleteMutation, MedicalRecordDeleteMutationVariables>;

/**
 * __useMedicalRecordDeleteMutation__
 *
 * To run a mutation, you first call `useMedicalRecordDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMedicalRecordDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [medicalRecordDeleteMutation, { data, loading, error }] = useMedicalRecordDeleteMutation({
 *   variables: {
 *      MedicalRecordID: // value for 'MedicalRecordID'
 *   },
 * });
 */
export function useMedicalRecordDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MedicalRecordDeleteMutation, MedicalRecordDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<MedicalRecordDeleteMutation, MedicalRecordDeleteMutationVariables>(MedicalRecordDeleteDocument, baseOptions);
      }
export type MedicalRecordDeleteMutationHookResult = ReturnType<typeof useMedicalRecordDeleteMutation>;
export type MedicalRecordDeleteMutationResult = ApolloReactCommon.MutationResult<MedicalRecordDeleteMutation>;
export type MedicalRecordDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<MedicalRecordDeleteMutation, MedicalRecordDeleteMutationVariables>;
export const JobHistoryListingDocument = gql`
    query JobHistoryListing($PersonnelID: String!) {
  PersonnelJobHistoryListing(PersonnelID: $PersonnelID) {
    PersonnelJobHistoryID
    StartDate
    EndDate
    CompanyName
    ModifiedDT
    JobPosition
    LastDrawnSalary
    Industry
    IndustryID
    Specialization
    SpecializationID
    JobDescription
    CompanyAddress {
      Line1
      Line2
      City
      PostalCode
      State
      Country
    }
  }
}
    `;

/**
 * __useJobHistoryListingQuery__
 *
 * To run a query within a React component, call `useJobHistoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobHistoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobHistoryListingQuery({
 *   variables: {
 *      PersonnelID: // value for 'PersonnelID'
 *   },
 * });
 */
export function useJobHistoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobHistoryListingQuery, JobHistoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobHistoryListingQuery, JobHistoryListingQueryVariables>(JobHistoryListingDocument, baseOptions);
      }
export function useJobHistoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobHistoryListingQuery, JobHistoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobHistoryListingQuery, JobHistoryListingQueryVariables>(JobHistoryListingDocument, baseOptions);
        }
export type JobHistoryListingQueryHookResult = ReturnType<typeof useJobHistoryListingQuery>;
export type JobHistoryListingLazyQueryHookResult = ReturnType<typeof useJobHistoryListingLazyQuery>;
export type JobHistoryListingQueryResult = ApolloReactCommon.QueryResult<JobHistoryListingQuery, JobHistoryListingQueryVariables>;
export const PersonnelJobHistoryInsertDocument = gql`
    mutation PersonnelJobHistoryInsert($Input: PersonnelJobHistoryInput!) {
  PersonnelJobHistoryInsert(Input: $Input)
}
    `;
export type PersonnelJobHistoryInsertMutationFn = ApolloReactCommon.MutationFunction<PersonnelJobHistoryInsertMutation, PersonnelJobHistoryInsertMutationVariables>;

/**
 * __usePersonnelJobHistoryInsertMutation__
 *
 * To run a mutation, you first call `usePersonnelJobHistoryInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelJobHistoryInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelJobHistoryInsertMutation, { data, loading, error }] = usePersonnelJobHistoryInsertMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function usePersonnelJobHistoryInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelJobHistoryInsertMutation, PersonnelJobHistoryInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelJobHistoryInsertMutation, PersonnelJobHistoryInsertMutationVariables>(PersonnelJobHistoryInsertDocument, baseOptions);
      }
export type PersonnelJobHistoryInsertMutationHookResult = ReturnType<typeof usePersonnelJobHistoryInsertMutation>;
export type PersonnelJobHistoryInsertMutationResult = ApolloReactCommon.MutationResult<PersonnelJobHistoryInsertMutation>;
export type PersonnelJobHistoryInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelJobHistoryInsertMutation, PersonnelJobHistoryInsertMutationVariables>;
export const PersonnelJobHistoryUpdateDocument = gql`
    mutation PersonnelJobHistoryUpdate($PersonnelJobHistoryID: String!, $Input: PersonnelJobHistoryInput!) {
  PersonnelJobHistoryUpdate(PersonnelJobHistoryID: $PersonnelJobHistoryID, Input: $Input)
}
    `;
export type PersonnelJobHistoryUpdateMutationFn = ApolloReactCommon.MutationFunction<PersonnelJobHistoryUpdateMutation, PersonnelJobHistoryUpdateMutationVariables>;

/**
 * __usePersonnelJobHistoryUpdateMutation__
 *
 * To run a mutation, you first call `usePersonnelJobHistoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelJobHistoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelJobHistoryUpdateMutation, { data, loading, error }] = usePersonnelJobHistoryUpdateMutation({
 *   variables: {
 *      PersonnelJobHistoryID: // value for 'PersonnelJobHistoryID'
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function usePersonnelJobHistoryUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelJobHistoryUpdateMutation, PersonnelJobHistoryUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelJobHistoryUpdateMutation, PersonnelJobHistoryUpdateMutationVariables>(PersonnelJobHistoryUpdateDocument, baseOptions);
      }
export type PersonnelJobHistoryUpdateMutationHookResult = ReturnType<typeof usePersonnelJobHistoryUpdateMutation>;
export type PersonnelJobHistoryUpdateMutationResult = ApolloReactCommon.MutationResult<PersonnelJobHistoryUpdateMutation>;
export type PersonnelJobHistoryUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelJobHistoryUpdateMutation, PersonnelJobHistoryUpdateMutationVariables>;
export const PersonnelJobHistoryDeleteDocument = gql`
    mutation PersonnelJobHistoryDelete($PersonnelJobHistoryID: String!) {
  PersonnelJobHistoryDelete(PersonnelJobHistoryID: $PersonnelJobHistoryID)
}
    `;
export type PersonnelJobHistoryDeleteMutationFn = ApolloReactCommon.MutationFunction<PersonnelJobHistoryDeleteMutation, PersonnelJobHistoryDeleteMutationVariables>;

/**
 * __usePersonnelJobHistoryDeleteMutation__
 *
 * To run a mutation, you first call `usePersonnelJobHistoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelJobHistoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelJobHistoryDeleteMutation, { data, loading, error }] = usePersonnelJobHistoryDeleteMutation({
 *   variables: {
 *      PersonnelJobHistoryID: // value for 'PersonnelJobHistoryID'
 *   },
 * });
 */
export function usePersonnelJobHistoryDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelJobHistoryDeleteMutation, PersonnelJobHistoryDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelJobHistoryDeleteMutation, PersonnelJobHistoryDeleteMutationVariables>(PersonnelJobHistoryDeleteDocument, baseOptions);
      }
export type PersonnelJobHistoryDeleteMutationHookResult = ReturnType<typeof usePersonnelJobHistoryDeleteMutation>;
export type PersonnelJobHistoryDeleteMutationResult = ApolloReactCommon.MutationResult<PersonnelJobHistoryDeleteMutation>;
export type PersonnelJobHistoryDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelJobHistoryDeleteMutation, PersonnelJobHistoryDeleteMutationVariables>;
export const UserDefineDetailBulkInsertDocument = gql`
    mutation UserDefineDetailBulkInsert($UserDefineDetailInput: [UserDefineDetailInput!]!) {
  UserDefineDetailBulkInsert(UserDefineDetailInput: $UserDefineDetailInput)
}
    `;
export type UserDefineDetailBulkInsertMutationFn = ApolloReactCommon.MutationFunction<UserDefineDetailBulkInsertMutation, UserDefineDetailBulkInsertMutationVariables>;

/**
 * __useUserDefineDetailBulkInsertMutation__
 *
 * To run a mutation, you first call `useUserDefineDetailBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDefineDetailBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDefineDetailBulkInsertMutation, { data, loading, error }] = useUserDefineDetailBulkInsertMutation({
 *   variables: {
 *      UserDefineDetailInput: // value for 'UserDefineDetailInput'
 *   },
 * });
 */
export function useUserDefineDetailBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserDefineDetailBulkInsertMutation, UserDefineDetailBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<UserDefineDetailBulkInsertMutation, UserDefineDetailBulkInsertMutationVariables>(UserDefineDetailBulkInsertDocument, baseOptions);
      }
export type UserDefineDetailBulkInsertMutationHookResult = ReturnType<typeof useUserDefineDetailBulkInsertMutation>;
export type UserDefineDetailBulkInsertMutationResult = ApolloReactCommon.MutationResult<UserDefineDetailBulkInsertMutation>;
export type UserDefineDetailBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<UserDefineDetailBulkInsertMutation, UserDefineDetailBulkInsertMutationVariables>;
export const UserDefineDetailListingDocument = gql`
    query UserDefineDetailListing($PersonnelID: String) {
  UserDefineDetailListing(PersonnelID: $PersonnelID) {
    UserDefineDetailID
    PersonnelID
    UserDefineID
    Description
    SequenceNo
    UserDefine {
      Name
    }
  }
}
    `;

/**
 * __useUserDefineDetailListingQuery__
 *
 * To run a query within a React component, call `useUserDefineDetailListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDefineDetailListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDefineDetailListingQuery({
 *   variables: {
 *      PersonnelID: // value for 'PersonnelID'
 *   },
 * });
 */
export function useUserDefineDetailListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserDefineDetailListingQuery, UserDefineDetailListingQueryVariables>) {
        return ApolloReactHooks.useQuery<UserDefineDetailListingQuery, UserDefineDetailListingQueryVariables>(UserDefineDetailListingDocument, baseOptions);
      }
export function useUserDefineDetailListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserDefineDetailListingQuery, UserDefineDetailListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserDefineDetailListingQuery, UserDefineDetailListingQueryVariables>(UserDefineDetailListingDocument, baseOptions);
        }
export type UserDefineDetailListingQueryHookResult = ReturnType<typeof useUserDefineDetailListingQuery>;
export type UserDefineDetailListingLazyQueryHookResult = ReturnType<typeof useUserDefineDetailListingLazyQuery>;
export type UserDefineDetailListingQueryResult = ApolloReactCommon.QueryResult<UserDefineDetailListingQuery, UserDefineDetailListingQueryVariables>;
export const UserDefineListingDocument = gql`
    query UserDefineListing {
  UserDefineListing {
    UserDefineID
    SubscriptionAccountID
    Name
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useUserDefineListingQuery__
 *
 * To run a query within a React component, call `useUserDefineListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDefineListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDefineListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDefineListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserDefineListingQuery, UserDefineListingQueryVariables>) {
        return ApolloReactHooks.useQuery<UserDefineListingQuery, UserDefineListingQueryVariables>(UserDefineListingDocument, baseOptions);
      }
export function useUserDefineListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserDefineListingQuery, UserDefineListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserDefineListingQuery, UserDefineListingQueryVariables>(UserDefineListingDocument, baseOptions);
        }
export type UserDefineListingQueryHookResult = ReturnType<typeof useUserDefineListingQuery>;
export type UserDefineListingLazyQueryHookResult = ReturnType<typeof useUserDefineListingLazyQuery>;
export type UserDefineListingQueryResult = ApolloReactCommon.QueryResult<UserDefineListingQuery, UserDefineListingQueryVariables>;
export const ContractInfoListingDocument = gql`
    query ContractInfoListing($PersonnelID: String!, $ContractInfoID: String) {
  ContractInfoListing(PersonnelID: $PersonnelID, ContractInfoID: $ContractInfoID) {
    ContractInfoID
    ContractDocType
    DocNo
    StartDate
    EndDate
    DocumentID
    DocumentID
    CreatedBy
    ModifiedBy
    Document {
      Description
      DocumentFile
      DocumentType
      ModifiedDT
    }
    Remark
    PersonnelID
    ContractDocTypeID
    ContractDocumentType {
      PersonnelParameterID
      Name
      PersonnelParameterType
    }
  }
}
    `;

/**
 * __useContractInfoListingQuery__
 *
 * To run a query within a React component, call `useContractInfoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractInfoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractInfoListingQuery({
 *   variables: {
 *      PersonnelID: // value for 'PersonnelID'
 *      ContractInfoID: // value for 'ContractInfoID'
 *   },
 * });
 */
export function useContractInfoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContractInfoListingQuery, ContractInfoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ContractInfoListingQuery, ContractInfoListingQueryVariables>(ContractInfoListingDocument, baseOptions);
      }
export function useContractInfoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractInfoListingQuery, ContractInfoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContractInfoListingQuery, ContractInfoListingQueryVariables>(ContractInfoListingDocument, baseOptions);
        }
export type ContractInfoListingQueryHookResult = ReturnType<typeof useContractInfoListingQuery>;
export type ContractInfoListingLazyQueryHookResult = ReturnType<typeof useContractInfoListingLazyQuery>;
export type ContractInfoListingQueryResult = ApolloReactCommon.QueryResult<ContractInfoListingQuery, ContractInfoListingQueryVariables>;
export const ZakatContributionDeleteDocument = gql`
    mutation ZakatContributionDelete($ID: String!) {
  ZakatContributionDelete(ID: $ID)
}
    `;
export type ZakatContributionDeleteMutationFn = ApolloReactCommon.MutationFunction<ZakatContributionDeleteMutation, ZakatContributionDeleteMutationVariables>;

/**
 * __useZakatContributionDeleteMutation__
 *
 * To run a mutation, you first call `useZakatContributionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZakatContributionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zakatContributionDeleteMutation, { data, loading, error }] = useZakatContributionDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useZakatContributionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ZakatContributionDeleteMutation, ZakatContributionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ZakatContributionDeleteMutation, ZakatContributionDeleteMutationVariables>(ZakatContributionDeleteDocument, baseOptions);
      }
export type ZakatContributionDeleteMutationHookResult = ReturnType<typeof useZakatContributionDeleteMutation>;
export type ZakatContributionDeleteMutationResult = ApolloReactCommon.MutationResult<ZakatContributionDeleteMutation>;
export type ZakatContributionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ZakatContributionDeleteMutation, ZakatContributionDeleteMutationVariables>;
export const ZakatContributionListingDocument = gql`
    query ZakatContributionListing($input: ZakatContributionInput!) {
  ZakatContributionListing(input: $input) {
    PayrollCycleID
    ID
    EmployeeID
    Remark
    Amount
    Percentage
    ZakatStateID
    AccountNo
    PayItem {
      PayName
      PayItemType
    }
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    StartPeriod {
      PeriodYearMonth
      PayPeriodID
    }
    EndPeriod {
      PeriodYearMonth
      PayPeriodID
    }
    PayrollCycle {
      PayrollCycleID
      Description
    }
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __useZakatContributionListingQuery__
 *
 * To run a query within a React component, call `useZakatContributionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useZakatContributionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZakatContributionListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useZakatContributionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ZakatContributionListingQuery, ZakatContributionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ZakatContributionListingQuery, ZakatContributionListingQueryVariables>(ZakatContributionListingDocument, baseOptions);
      }
export function useZakatContributionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ZakatContributionListingQuery, ZakatContributionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ZakatContributionListingQuery, ZakatContributionListingQueryVariables>(ZakatContributionListingDocument, baseOptions);
        }
export type ZakatContributionListingQueryHookResult = ReturnType<typeof useZakatContributionListingQuery>;
export type ZakatContributionListingLazyQueryHookResult = ReturnType<typeof useZakatContributionListingLazyQuery>;
export type ZakatContributionListingQueryResult = ApolloReactCommon.QueryResult<ZakatContributionListingQuery, ZakatContributionListingQueryVariables>;
export const ZakatContributionInsertDocument = gql`
    mutation ZakatContributionInsert($ZakatContributionInput: ZakatContributionInput!) {
  ZakatContributionInsert(ZakatContributionInput: $ZakatContributionInput)
}
    `;
export type ZakatContributionInsertMutationFn = ApolloReactCommon.MutationFunction<ZakatContributionInsertMutation, ZakatContributionInsertMutationVariables>;

/**
 * __useZakatContributionInsertMutation__
 *
 * To run a mutation, you first call `useZakatContributionInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZakatContributionInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zakatContributionInsertMutation, { data, loading, error }] = useZakatContributionInsertMutation({
 *   variables: {
 *      ZakatContributionInput: // value for 'ZakatContributionInput'
 *   },
 * });
 */
export function useZakatContributionInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ZakatContributionInsertMutation, ZakatContributionInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ZakatContributionInsertMutation, ZakatContributionInsertMutationVariables>(ZakatContributionInsertDocument, baseOptions);
      }
export type ZakatContributionInsertMutationHookResult = ReturnType<typeof useZakatContributionInsertMutation>;
export type ZakatContributionInsertMutationResult = ApolloReactCommon.MutationResult<ZakatContributionInsertMutation>;
export type ZakatContributionInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ZakatContributionInsertMutation, ZakatContributionInsertMutationVariables>;
export const ZakatContributionUpdateDocument = gql`
    mutation ZakatContributionUpdate($ID: String!, $ZakatContributionInput: ZakatContributionInput!) {
  ZakatContributionUpdate(ID: $ID, input: $ZakatContributionInput)
}
    `;
export type ZakatContributionUpdateMutationFn = ApolloReactCommon.MutationFunction<ZakatContributionUpdateMutation, ZakatContributionUpdateMutationVariables>;

/**
 * __useZakatContributionUpdateMutation__
 *
 * To run a mutation, you first call `useZakatContributionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZakatContributionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zakatContributionUpdateMutation, { data, loading, error }] = useZakatContributionUpdateMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      ZakatContributionInput: // value for 'ZakatContributionInput'
 *   },
 * });
 */
export function useZakatContributionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ZakatContributionUpdateMutation, ZakatContributionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ZakatContributionUpdateMutation, ZakatContributionUpdateMutationVariables>(ZakatContributionUpdateDocument, baseOptions);
      }
export type ZakatContributionUpdateMutationHookResult = ReturnType<typeof useZakatContributionUpdateMutation>;
export type ZakatContributionUpdateMutationResult = ApolloReactCommon.MutationResult<ZakatContributionUpdateMutation>;
export type ZakatContributionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ZakatContributionUpdateMutation, ZakatContributionUpdateMutationVariables>;
export const ZakatSummaryDocument = gql`
    query ZakatSummary($EmployeeID: String!) {
  ZakatSummary(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useZakatSummaryQuery__
 *
 * To run a query within a React component, call `useZakatSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useZakatSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZakatSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useZakatSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ZakatSummaryQuery, ZakatSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ZakatSummaryQuery, ZakatSummaryQueryVariables>(ZakatSummaryDocument, baseOptions);
      }
export function useZakatSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ZakatSummaryQuery, ZakatSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ZakatSummaryQuery, ZakatSummaryQueryVariables>(ZakatSummaryDocument, baseOptions);
        }
export type ZakatSummaryQueryHookResult = ReturnType<typeof useZakatSummaryQuery>;
export type ZakatSummaryLazyQueryHookResult = ReturnType<typeof useZakatSummaryLazyQuery>;
export type ZakatSummaryQueryResult = ApolloReactCommon.QueryResult<ZakatSummaryQuery, ZakatSummaryQueryVariables>;
export const EmployeeListForJobInfoDocument = gql`
    query EmployeeListForJobInfo {
  EmployeeListForJobInfo
}
    `;

/**
 * __useEmployeeListForJobInfoQuery__
 *
 * To run a query within a React component, call `useEmployeeListForJobInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListForJobInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListForJobInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeListForJobInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListForJobInfoQuery, EmployeeListForJobInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListForJobInfoQuery, EmployeeListForJobInfoQueryVariables>(EmployeeListForJobInfoDocument, baseOptions);
      }
export function useEmployeeListForJobInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListForJobInfoQuery, EmployeeListForJobInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListForJobInfoQuery, EmployeeListForJobInfoQueryVariables>(EmployeeListForJobInfoDocument, baseOptions);
        }
export type EmployeeListForJobInfoQueryHookResult = ReturnType<typeof useEmployeeListForJobInfoQuery>;
export type EmployeeListForJobInfoLazyQueryHookResult = ReturnType<typeof useEmployeeListForJobInfoLazyQuery>;
export type EmployeeListForJobInfoQueryResult = ApolloReactCommon.QueryResult<EmployeeListForJobInfoQuery, EmployeeListForJobInfoQueryVariables>;
export const PersonnelEmployementInfoListingDocument = gql`
    query PersonnelEmployementInfoListing($SubscriptionAccountID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $CompanyID: String, $SortBy: String, $isDraft: Boolean, $offset: Float, $limit: Float) {
  PersonnelEmployementInfoListing(SubscriptionAccountID: $SubscriptionAccountID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID, SortBy: $SortBy, isDraft: $isDraft, offset: $offset, limit: $limit)
}
    `;

/**
 * __usePersonnelEmployementInfoListingQuery__
 *
 * To run a query within a React component, call `usePersonnelEmployementInfoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelEmployementInfoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelEmployementInfoListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      CompanyID: // value for 'CompanyID'
 *      SortBy: // value for 'SortBy'
 *      isDraft: // value for 'isDraft'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePersonnelEmployementInfoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelEmployementInfoListingQuery, PersonnelEmployementInfoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelEmployementInfoListingQuery, PersonnelEmployementInfoListingQueryVariables>(PersonnelEmployementInfoListingDocument, baseOptions);
      }
export function usePersonnelEmployementInfoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelEmployementInfoListingQuery, PersonnelEmployementInfoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelEmployementInfoListingQuery, PersonnelEmployementInfoListingQueryVariables>(PersonnelEmployementInfoListingDocument, baseOptions);
        }
export type PersonnelEmployementInfoListingQueryHookResult = ReturnType<typeof usePersonnelEmployementInfoListingQuery>;
export type PersonnelEmployementInfoListingLazyQueryHookResult = ReturnType<typeof usePersonnelEmployementInfoListingLazyQuery>;
export type PersonnelEmployementInfoListingQueryResult = ApolloReactCommon.QueryResult<PersonnelEmployementInfoListingQuery, PersonnelEmployementInfoListingQueryVariables>;
export const PersonnelEmployementInfoListingCountDocument = gql`
    query PersonnelEmployementInfoListingCount($DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $CompanyID: String, $SortBy: String, $isDraft: Boolean) {
  PersonnelEmployementInfoListingCount(EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID, SortBy: $SortBy, isDraft: $isDraft)
}
    `;

/**
 * __usePersonnelEmployementInfoListingCountQuery__
 *
 * To run a query within a React component, call `usePersonnelEmployementInfoListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelEmployementInfoListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelEmployementInfoListingCountQuery({
 *   variables: {
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      CompanyID: // value for 'CompanyID'
 *      SortBy: // value for 'SortBy'
 *      isDraft: // value for 'isDraft'
 *   },
 * });
 */
export function usePersonnelEmployementInfoListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelEmployementInfoListingCountQuery, PersonnelEmployementInfoListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelEmployementInfoListingCountQuery, PersonnelEmployementInfoListingCountQueryVariables>(PersonnelEmployementInfoListingCountDocument, baseOptions);
      }
export function usePersonnelEmployementInfoListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelEmployementInfoListingCountQuery, PersonnelEmployementInfoListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelEmployementInfoListingCountQuery, PersonnelEmployementInfoListingCountQueryVariables>(PersonnelEmployementInfoListingCountDocument, baseOptions);
        }
export type PersonnelEmployementInfoListingCountQueryHookResult = ReturnType<typeof usePersonnelEmployementInfoListingCountQuery>;
export type PersonnelEmployementInfoListingCountLazyQueryHookResult = ReturnType<typeof usePersonnelEmployementInfoListingCountLazyQuery>;
export type PersonnelEmployementInfoListingCountQueryResult = ApolloReactCommon.QueryResult<PersonnelEmployementInfoListingCountQuery, PersonnelEmployementInfoListingCountQueryVariables>;
export const JobInfoEmployeeListingBySubscriptionAccountDocument = gql`
    query JobInfoEmployeeListingBySubscriptionAccount($SubscriptionAccountID: String!, $DepartmentIDs: [String!]!, $EmployeeStatus: [String!]!, $EmployeeType: [String!]!, $OrgUnitID: [String!]!, $CompanyID: String) {
  EmployeeListingBySubscriptionAccount(SubscriptionAccountID: $SubscriptionAccountID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, OrgUnitID: $OrgUnitID, EmployeeType: $EmployeeType, CompanyID: $CompanyID) {
    CompanyID
    EmployeeID
    EmployeeNo
    ResignedDate
    Contact {
      ContactID
      FullName
    }
  }
}
    `;

/**
 * __useJobInfoEmployeeListingBySubscriptionAccountQuery__
 *
 * To run a query within a React component, call `useJobInfoEmployeeListingBySubscriptionAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobInfoEmployeeListingBySubscriptionAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobInfoEmployeeListingBySubscriptionAccountQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useJobInfoEmployeeListingBySubscriptionAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobInfoEmployeeListingBySubscriptionAccountQuery, JobInfoEmployeeListingBySubscriptionAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<JobInfoEmployeeListingBySubscriptionAccountQuery, JobInfoEmployeeListingBySubscriptionAccountQueryVariables>(JobInfoEmployeeListingBySubscriptionAccountDocument, baseOptions);
      }
export function useJobInfoEmployeeListingBySubscriptionAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobInfoEmployeeListingBySubscriptionAccountQuery, JobInfoEmployeeListingBySubscriptionAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobInfoEmployeeListingBySubscriptionAccountQuery, JobInfoEmployeeListingBySubscriptionAccountQueryVariables>(JobInfoEmployeeListingBySubscriptionAccountDocument, baseOptions);
        }
export type JobInfoEmployeeListingBySubscriptionAccountQueryHookResult = ReturnType<typeof useJobInfoEmployeeListingBySubscriptionAccountQuery>;
export type JobInfoEmployeeListingBySubscriptionAccountLazyQueryHookResult = ReturnType<typeof useJobInfoEmployeeListingBySubscriptionAccountLazyQuery>;
export type JobInfoEmployeeListingBySubscriptionAccountQueryResult = ApolloReactCommon.QueryResult<JobInfoEmployeeListingBySubscriptionAccountQuery, JobInfoEmployeeListingBySubscriptionAccountQueryVariables>;
export const IsZakatPeriodOverlapDocument = gql`
    query IsZakatPeriodOverlap($EmployeeID: String!, $StartDate: DateTime!, $ZakatID: String, $EndDate: DateTime, $PayrollCycleID: String) {
  IsZakatPeriodOverlap(ZakatID: $ZakatID, EmployeeID: $EmployeeID, StartDate: $StartDate, EndDate: $EndDate, PayrollCycleID: $PayrollCycleID)
}
    `;

/**
 * __useIsZakatPeriodOverlapQuery__
 *
 * To run a query within a React component, call `useIsZakatPeriodOverlapQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsZakatPeriodOverlapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsZakatPeriodOverlapQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      StartDate: // value for 'StartDate'
 *      ZakatID: // value for 'ZakatID'
 *      EndDate: // value for 'EndDate'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *   },
 * });
 */
export function useIsZakatPeriodOverlapQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsZakatPeriodOverlapQuery, IsZakatPeriodOverlapQueryVariables>) {
        return ApolloReactHooks.useQuery<IsZakatPeriodOverlapQuery, IsZakatPeriodOverlapQueryVariables>(IsZakatPeriodOverlapDocument, baseOptions);
      }
export function useIsZakatPeriodOverlapLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsZakatPeriodOverlapQuery, IsZakatPeriodOverlapQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsZakatPeriodOverlapQuery, IsZakatPeriodOverlapQueryVariables>(IsZakatPeriodOverlapDocument, baseOptions);
        }
export type IsZakatPeriodOverlapQueryHookResult = ReturnType<typeof useIsZakatPeriodOverlapQuery>;
export type IsZakatPeriodOverlapLazyQueryHookResult = ReturnType<typeof useIsZakatPeriodOverlapLazyQuery>;
export type IsZakatPeriodOverlapQueryResult = ApolloReactCommon.QueryResult<IsZakatPeriodOverlapQuery, IsZakatPeriodOverlapQueryVariables>;
export const GetEmployeeAppSecurityListingDocument = gql`
    query GetEmployeeAppSecurityListing($EmployeeAppSecurityID: String) {
  GetEmployeeAppSecurityListing(EmployeeAppSecurityID: $EmployeeAppSecurityID) {
    EmployeeAppSecurityID
    Title
    ModifiedDT
    InUse
    PermissionList {
      EmployeeAppSecurityPermissionID
      PermissionID
      PermissionName
    }
  }
}
    `;

/**
 * __useGetEmployeeAppSecurityListingQuery__
 *
 * To run a query within a React component, call `useGetEmployeeAppSecurityListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeAppSecurityListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeAppSecurityListingQuery({
 *   variables: {
 *      EmployeeAppSecurityID: // value for 'EmployeeAppSecurityID'
 *   },
 * });
 */
export function useGetEmployeeAppSecurityListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeAppSecurityListingQuery, GetEmployeeAppSecurityListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeAppSecurityListingQuery, GetEmployeeAppSecurityListingQueryVariables>(GetEmployeeAppSecurityListingDocument, baseOptions);
      }
export function useGetEmployeeAppSecurityListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeAppSecurityListingQuery, GetEmployeeAppSecurityListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeAppSecurityListingQuery, GetEmployeeAppSecurityListingQueryVariables>(GetEmployeeAppSecurityListingDocument, baseOptions);
        }
export type GetEmployeeAppSecurityListingQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityListingQuery>;
export type GetEmployeeAppSecurityListingLazyQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityListingLazyQuery>;
export type GetEmployeeAppSecurityListingQueryResult = ApolloReactCommon.QueryResult<GetEmployeeAppSecurityListingQuery, GetEmployeeAppSecurityListingQueryVariables>;
export const CreateUpdateAppSecurityDocument = gql`
    mutation CreateUpdateAppSecurity($input: EmployeeAppSecurityInput!, $permissionArr: [HrPermission!]) {
  CreateUpdateAppSecurity(input: $input, permissionArr: $permissionArr)
}
    `;
export type CreateUpdateAppSecurityMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateAppSecurityMutation, CreateUpdateAppSecurityMutationVariables>;

/**
 * __useCreateUpdateAppSecurityMutation__
 *
 * To run a mutation, you first call `useCreateUpdateAppSecurityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateAppSecurityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateAppSecurityMutation, { data, loading, error }] = useCreateUpdateAppSecurityMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useCreateUpdateAppSecurityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateAppSecurityMutation, CreateUpdateAppSecurityMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateAppSecurityMutation, CreateUpdateAppSecurityMutationVariables>(CreateUpdateAppSecurityDocument, baseOptions);
      }
export type CreateUpdateAppSecurityMutationHookResult = ReturnType<typeof useCreateUpdateAppSecurityMutation>;
export type CreateUpdateAppSecurityMutationResult = ApolloReactCommon.MutationResult<CreateUpdateAppSecurityMutation>;
export type CreateUpdateAppSecurityMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateAppSecurityMutation, CreateUpdateAppSecurityMutationVariables>;
export const GetEmployeeAppSecurityAssignmentListingDocument = gql`
    query GetEmployeeAppSecurityAssignmentListing($CompanyID: String!, $EmployeeAppSecurityAssignmentID: String) {
  GetEmployeeAppSecurityAssignmentListing(CompanyID: $CompanyID, EmployeeAppSecurityAssignmentID: $EmployeeAppSecurityAssignmentID)
}
    `;

/**
 * __useGetEmployeeAppSecurityAssignmentListingQuery__
 *
 * To run a query within a React component, call `useGetEmployeeAppSecurityAssignmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeAppSecurityAssignmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeAppSecurityAssignmentListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeAppSecurityAssignmentID: // value for 'EmployeeAppSecurityAssignmentID'
 *   },
 * });
 */
export function useGetEmployeeAppSecurityAssignmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeAppSecurityAssignmentListingQuery, GetEmployeeAppSecurityAssignmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeAppSecurityAssignmentListingQuery, GetEmployeeAppSecurityAssignmentListingQueryVariables>(GetEmployeeAppSecurityAssignmentListingDocument, baseOptions);
      }
export function useGetEmployeeAppSecurityAssignmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeAppSecurityAssignmentListingQuery, GetEmployeeAppSecurityAssignmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeAppSecurityAssignmentListingQuery, GetEmployeeAppSecurityAssignmentListingQueryVariables>(GetEmployeeAppSecurityAssignmentListingDocument, baseOptions);
        }
export type GetEmployeeAppSecurityAssignmentListingQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityAssignmentListingQuery>;
export type GetEmployeeAppSecurityAssignmentListingLazyQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityAssignmentListingLazyQuery>;
export type GetEmployeeAppSecurityAssignmentListingQueryResult = ApolloReactCommon.QueryResult<GetEmployeeAppSecurityAssignmentListingQuery, GetEmployeeAppSecurityAssignmentListingQueryVariables>;
export const CreateUpdateAppSecurityAssignmentDocument = gql`
    mutation CreateUpdateAppSecurityAssignment($input: EmployeeAppSecurityAssignmentInput!) {
  CreateUpdateAppSecurityAssignment(input: $input)
}
    `;
export type CreateUpdateAppSecurityAssignmentMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateAppSecurityAssignmentMutation, CreateUpdateAppSecurityAssignmentMutationVariables>;

/**
 * __useCreateUpdateAppSecurityAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateUpdateAppSecurityAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateAppSecurityAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateAppSecurityAssignmentMutation, { data, loading, error }] = useCreateUpdateAppSecurityAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateAppSecurityAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateAppSecurityAssignmentMutation, CreateUpdateAppSecurityAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateAppSecurityAssignmentMutation, CreateUpdateAppSecurityAssignmentMutationVariables>(CreateUpdateAppSecurityAssignmentDocument, baseOptions);
      }
export type CreateUpdateAppSecurityAssignmentMutationHookResult = ReturnType<typeof useCreateUpdateAppSecurityAssignmentMutation>;
export type CreateUpdateAppSecurityAssignmentMutationResult = ApolloReactCommon.MutationResult<CreateUpdateAppSecurityAssignmentMutation>;
export type CreateUpdateAppSecurityAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateAppSecurityAssignmentMutation, CreateUpdateAppSecurityAssignmentMutationVariables>;
export const GetEmployeeAppSecurityPermissionsByContactIdDocument = gql`
    query GetEmployeeAppSecurityPermissionsByContactID($ContactID: String!, $Permissions: [HrPermission!], $EmployeeID: String) {
  GetEmployeeAppSecurityPermissionsByContactID(ContactID: $ContactID, Permissions: $Permissions, EmployeeID: $EmployeeID)
}
    `;

/**
 * __useGetEmployeeAppSecurityPermissionsByContactIdQuery__
 *
 * To run a query within a React component, call `useGetEmployeeAppSecurityPermissionsByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeAppSecurityPermissionsByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeAppSecurityPermissionsByContactIdQuery({
 *   variables: {
 *      ContactID: // value for 'ContactID'
 *      Permissions: // value for 'Permissions'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetEmployeeAppSecurityPermissionsByContactIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>(GetEmployeeAppSecurityPermissionsByContactIdDocument, baseOptions);
      }
export function useGetEmployeeAppSecurityPermissionsByContactIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>(GetEmployeeAppSecurityPermissionsByContactIdDocument, baseOptions);
        }
export type GetEmployeeAppSecurityPermissionsByContactIdQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityPermissionsByContactIdQuery>;
export type GetEmployeeAppSecurityPermissionsByContactIdLazyQueryHookResult = ReturnType<typeof useGetEmployeeAppSecurityPermissionsByContactIdLazyQuery>;
export type GetEmployeeAppSecurityPermissionsByContactIdQueryResult = ApolloReactCommon.QueryResult<GetEmployeeAppSecurityPermissionsByContactIdQuery, GetEmployeeAppSecurityPermissionsByContactIdQueryVariables>;
export const DepartmentDivisionListingDocument = gql`
    query DepartmentDivisionListing($CompanyID: String!) {
  DepartmentDivisionListing(CompanyID: $CompanyID) {
    DepartmentID
    CompanyID
    Description
    DepartmentType
    ParentID
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useDepartmentDivisionListingQuery__
 *
 * To run a query within a React component, call `useDepartmentDivisionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentDivisionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentDivisionListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDepartmentDivisionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepartmentDivisionListingQuery, DepartmentDivisionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DepartmentDivisionListingQuery, DepartmentDivisionListingQueryVariables>(DepartmentDivisionListingDocument, baseOptions);
      }
export function useDepartmentDivisionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartmentDivisionListingQuery, DepartmentDivisionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepartmentDivisionListingQuery, DepartmentDivisionListingQueryVariables>(DepartmentDivisionListingDocument, baseOptions);
        }
export type DepartmentDivisionListingQueryHookResult = ReturnType<typeof useDepartmentDivisionListingQuery>;
export type DepartmentDivisionListingLazyQueryHookResult = ReturnType<typeof useDepartmentDivisionListingLazyQuery>;
export type DepartmentDivisionListingQueryResult = ApolloReactCommon.QueryResult<DepartmentDivisionListingQuery, DepartmentDivisionListingQueryVariables>;
export const DepartmentDetailDocument = gql`
    query DepartmentDetail($DepartmentID: String!) {
  DepartmentDetail(DepartmentID: $DepartmentID) {
    DepartmentID
    CompanyID
    Description
    DepartmentType
    ParentID
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useDepartmentDetailQuery__
 *
 * To run a query within a React component, call `useDepartmentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentDetailQuery({
 *   variables: {
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useDepartmentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepartmentDetailQuery, DepartmentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<DepartmentDetailQuery, DepartmentDetailQueryVariables>(DepartmentDetailDocument, baseOptions);
      }
export function useDepartmentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartmentDetailQuery, DepartmentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepartmentDetailQuery, DepartmentDetailQueryVariables>(DepartmentDetailDocument, baseOptions);
        }
export type DepartmentDetailQueryHookResult = ReturnType<typeof useDepartmentDetailQuery>;
export type DepartmentDetailLazyQueryHookResult = ReturnType<typeof useDepartmentDetailLazyQuery>;
export type DepartmentDetailQueryResult = ApolloReactCommon.QueryResult<DepartmentDetailQuery, DepartmentDetailQueryVariables>;
export const DivisionListingDocument = gql`
    query DivisionListing($DepartmentID: String!) {
  DivisionListing(DepartmentID: $DepartmentID) {
    DepartmentID
    CompanyID
    Description
    DepartmentType
    ParentID
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useDivisionListingQuery__
 *
 * To run a query within a React component, call `useDivisionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDivisionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDivisionListingQuery({
 *   variables: {
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useDivisionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DivisionListingQuery, DivisionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DivisionListingQuery, DivisionListingQueryVariables>(DivisionListingDocument, baseOptions);
      }
export function useDivisionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DivisionListingQuery, DivisionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DivisionListingQuery, DivisionListingQueryVariables>(DivisionListingDocument, baseOptions);
        }
export type DivisionListingQueryHookResult = ReturnType<typeof useDivisionListingQuery>;
export type DivisionListingLazyQueryHookResult = ReturnType<typeof useDivisionListingLazyQuery>;
export type DivisionListingQueryResult = ApolloReactCommon.QueryResult<DivisionListingQuery, DivisionListingQueryVariables>;
export const DepartmentListingDocument = gql`
    query DepartmentListing($CompanyID: String!) {
  DepartmentListing(CompanyID: $CompanyID) {
    DepartmentID
    CompanyID
    Description
    DepartmentType
    ParentID
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useDepartmentListingQuery__
 *
 * To run a query within a React component, call `useDepartmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDepartmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepartmentListingQuery, DepartmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DepartmentListingQuery, DepartmentListingQueryVariables>(DepartmentListingDocument, baseOptions);
      }
export function useDepartmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartmentListingQuery, DepartmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepartmentListingQuery, DepartmentListingQueryVariables>(DepartmentListingDocument, baseOptions);
        }
export type DepartmentListingQueryHookResult = ReturnType<typeof useDepartmentListingQuery>;
export type DepartmentListingLazyQueryHookResult = ReturnType<typeof useDepartmentListingLazyQuery>;
export type DepartmentListingQueryResult = ApolloReactCommon.QueryResult<DepartmentListingQuery, DepartmentListingQueryVariables>;
export const DepartmentInsertDocument = gql`
    mutation DepartmentInsert($input: DepartmentInput!) {
  DepartmentInsert(input: $input) {
    DepartmentID
  }
}
    `;
export type DepartmentInsertMutationFn = ApolloReactCommon.MutationFunction<DepartmentInsertMutation, DepartmentInsertMutationVariables>;

/**
 * __useDepartmentInsertMutation__
 *
 * To run a mutation, you first call `useDepartmentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentInsertMutation, { data, loading, error }] = useDepartmentInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepartmentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DepartmentInsertMutation, DepartmentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DepartmentInsertMutation, DepartmentInsertMutationVariables>(DepartmentInsertDocument, baseOptions);
      }
export type DepartmentInsertMutationHookResult = ReturnType<typeof useDepartmentInsertMutation>;
export type DepartmentInsertMutationResult = ApolloReactCommon.MutationResult<DepartmentInsertMutation>;
export type DepartmentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DepartmentInsertMutation, DepartmentInsertMutationVariables>;
export const DepartmentDeleteDocument = gql`
    mutation DepartmentDelete($DepartmentUpdateInput: DepartmentUpdateInput!) {
  DepartmentDelete(DepartmentUpdateInput: $DepartmentUpdateInput)
}
    `;
export type DepartmentDeleteMutationFn = ApolloReactCommon.MutationFunction<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>;

/**
 * __useDepartmentDeleteMutation__
 *
 * To run a mutation, you first call `useDepartmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentDeleteMutation, { data, loading, error }] = useDepartmentDeleteMutation({
 *   variables: {
 *      DepartmentUpdateInput: // value for 'DepartmentUpdateInput'
 *   },
 * });
 */
export function useDepartmentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>(DepartmentDeleteDocument, baseOptions);
      }
export type DepartmentDeleteMutationHookResult = ReturnType<typeof useDepartmentDeleteMutation>;
export type DepartmentDeleteMutationResult = ApolloReactCommon.MutationResult<DepartmentDeleteMutation>;
export type DepartmentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>;
export const DivisionInsertDocument = gql`
    mutation DivisionInsert($DepartmentInput: DepartmentInput!) {
  DivisionInsert(DepartmentInput: $DepartmentInput) {
    DepartmentID
  }
}
    `;
export type DivisionInsertMutationFn = ApolloReactCommon.MutationFunction<DivisionInsertMutation, DivisionInsertMutationVariables>;

/**
 * __useDivisionInsertMutation__
 *
 * To run a mutation, you first call `useDivisionInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDivisionInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [divisionInsertMutation, { data, loading, error }] = useDivisionInsertMutation({
 *   variables: {
 *      DepartmentInput: // value for 'DepartmentInput'
 *   },
 * });
 */
export function useDivisionInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DivisionInsertMutation, DivisionInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DivisionInsertMutation, DivisionInsertMutationVariables>(DivisionInsertDocument, baseOptions);
      }
export type DivisionInsertMutationHookResult = ReturnType<typeof useDivisionInsertMutation>;
export type DivisionInsertMutationResult = ApolloReactCommon.MutationResult<DivisionInsertMutation>;
export type DivisionInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DivisionInsertMutation, DivisionInsertMutationVariables>;
export const DepartmentUpdateDocument = gql`
    mutation DepartmentUpdate($input: DepartmentUpdateInput!, $DepartmentID: String!) {
  DepartmentUpdate(input: $input, DepartmentID: $DepartmentID)
}
    `;
export type DepartmentUpdateMutationFn = ApolloReactCommon.MutationFunction<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>;

/**
 * __useDepartmentUpdateMutation__
 *
 * To run a mutation, you first call `useDepartmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentUpdateMutation, { data, loading, error }] = useDepartmentUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useDepartmentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>(DepartmentUpdateDocument, baseOptions);
      }
export type DepartmentUpdateMutationHookResult = ReturnType<typeof useDepartmentUpdateMutation>;
export type DepartmentUpdateMutationResult = ApolloReactCommon.MutationResult<DepartmentUpdateMutation>;
export type DepartmentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>;
export const DivisionUpdateDocument = gql`
    mutation DivisionUpdate($input: DepartmentUpdateInput!, $DepartmentID: String!) {
  DivisionUpdate(input: $input, DepartmentID: $DepartmentID)
}
    `;
export type DivisionUpdateMutationFn = ApolloReactCommon.MutationFunction<DivisionUpdateMutation, DivisionUpdateMutationVariables>;

/**
 * __useDivisionUpdateMutation__
 *
 * To run a mutation, you first call `useDivisionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDivisionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [divisionUpdateMutation, { data, loading, error }] = useDivisionUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useDivisionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DivisionUpdateMutation, DivisionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DivisionUpdateMutation, DivisionUpdateMutationVariables>(DivisionUpdateDocument, baseOptions);
      }
export type DivisionUpdateMutationHookResult = ReturnType<typeof useDivisionUpdateMutation>;
export type DivisionUpdateMutationResult = ApolloReactCommon.MutationResult<DivisionUpdateMutation>;
export type DivisionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DivisionUpdateMutation, DivisionUpdateMutationVariables>;
export const DivisionDeleteDocument = gql`
    mutation DivisionDelete($DepartmentID: String!) {
  DivisionDelete(DepartmentID: $DepartmentID)
}
    `;
export type DivisionDeleteMutationFn = ApolloReactCommon.MutationFunction<DivisionDeleteMutation, DivisionDeleteMutationVariables>;

/**
 * __useDivisionDeleteMutation__
 *
 * To run a mutation, you first call `useDivisionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDivisionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [divisionDeleteMutation, { data, loading, error }] = useDivisionDeleteMutation({
 *   variables: {
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useDivisionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DivisionDeleteMutation, DivisionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<DivisionDeleteMutation, DivisionDeleteMutationVariables>(DivisionDeleteDocument, baseOptions);
      }
export type DivisionDeleteMutationHookResult = ReturnType<typeof useDivisionDeleteMutation>;
export type DivisionDeleteMutationResult = ApolloReactCommon.MutationResult<DivisionDeleteMutation>;
export type DivisionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<DivisionDeleteMutation, DivisionDeleteMutationVariables>;
export const TraineeEvalListingDocument = gql`
    query TraineeEvalListing($CompanyID: String!, $ScheduledTrainingID: String!) {
  TraineeEvalListing(CompanyID: $CompanyID, ScheduledTrainingID: $ScheduledTrainingID) {
    TraineeEvalID
    ScheduledTrainingID
    CompanyID
    Score
    Remarks
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Employee {
      EmployeeID
      EmployeeNo
      DepartmentID
      DivisionID
      JobGradeID
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
      Company {
        CompanyID
        CompanyName
      }
      EmployeeStatutory {
        EmployeeStatutoryID
      }
      EmployeePrevContribution {
        EmployeePrevContributionID
      }
      EmployeeDependents {
        EmployeeDependentsID
      }
      Department {
        DepartmentID
        DepartmentType
        ParentID
        Description
      }
      Designation {
        JobDesignationID
        Name
      }
      DocumentID
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
      TotalTaxRelief
      FormStatus
      EmploymentType
      CreatedDT
      ModifiedDT
    }
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useTraineeEvalListingQuery__
 *
 * To run a query within a React component, call `useTraineeEvalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraineeEvalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraineeEvalListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ScheduledTrainingID: // value for 'ScheduledTrainingID'
 *   },
 * });
 */
export function useTraineeEvalListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TraineeEvalListingQuery, TraineeEvalListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TraineeEvalListingQuery, TraineeEvalListingQueryVariables>(TraineeEvalListingDocument, baseOptions);
      }
export function useTraineeEvalListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TraineeEvalListingQuery, TraineeEvalListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TraineeEvalListingQuery, TraineeEvalListingQueryVariables>(TraineeEvalListingDocument, baseOptions);
        }
export type TraineeEvalListingQueryHookResult = ReturnType<typeof useTraineeEvalListingQuery>;
export type TraineeEvalListingLazyQueryHookResult = ReturnType<typeof useTraineeEvalListingLazyQuery>;
export type TraineeEvalListingQueryResult = ApolloReactCommon.QueryResult<TraineeEvalListingQuery, TraineeEvalListingQueryVariables>;
export const TraineeEvalListingAllDocument = gql`
    query TraineeEvalListingAll($CompanyID: String!) {
  TraineeEvalListingAll(CompanyID: $CompanyID) {
    TraineeEvalID
    ScheduledTrainingID
    CompanyID
    Score
    Remarks
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Employee {
      EmployeeID
      EmployeeNo
      DepartmentID
      DivisionID
      JobGradeID
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
      Company {
        CompanyID
        CompanyName
      }
      EmployeeStatutory {
        EmployeeStatutoryID
      }
      EmployeePrevContribution {
        EmployeePrevContributionID
      }
      EmployeeDependents {
        EmployeeDependentsID
      }
      Department {
        DepartmentID
        DepartmentType
        ParentID
        Description
      }
      Designation {
        JobDesignationID
        Name
      }
      DocumentID
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
      TotalTaxRelief
      FormStatus
      EmploymentType
      CreatedDT
      ModifiedDT
    }
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useTraineeEvalListingAllQuery__
 *
 * To run a query within a React component, call `useTraineeEvalListingAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraineeEvalListingAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraineeEvalListingAllQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useTraineeEvalListingAllQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TraineeEvalListingAllQuery, TraineeEvalListingAllQueryVariables>) {
        return ApolloReactHooks.useQuery<TraineeEvalListingAllQuery, TraineeEvalListingAllQueryVariables>(TraineeEvalListingAllDocument, baseOptions);
      }
export function useTraineeEvalListingAllLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TraineeEvalListingAllQuery, TraineeEvalListingAllQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TraineeEvalListingAllQuery, TraineeEvalListingAllQueryVariables>(TraineeEvalListingAllDocument, baseOptions);
        }
export type TraineeEvalListingAllQueryHookResult = ReturnType<typeof useTraineeEvalListingAllQuery>;
export type TraineeEvalListingAllLazyQueryHookResult = ReturnType<typeof useTraineeEvalListingAllLazyQuery>;
export type TraineeEvalListingAllQueryResult = ApolloReactCommon.QueryResult<TraineeEvalListingAllQuery, TraineeEvalListingAllQueryVariables>;
export const SupervisorEvalListingDocument = gql`
    query SupervisorEvalListing($CompanyID: String!, $ScheduledTrainingID: String!) {
  SupervisorEvalListing(CompanyID: $CompanyID, ScheduledTrainingID: $ScheduledTrainingID) {
    SupervisorEvalID
    ScheduledTrainingID
    CompanyID
    Score
    Remarks
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Employee {
      EmployeeID
      EmployeeNo
      DepartmentID
      DivisionID
      JobGradeID
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
      Company {
        CompanyID
        CompanyName
      }
      EmployeeStatutory {
        EmployeeStatutoryID
      }
      EmployeePrevContribution {
        EmployeePrevContributionID
      }
      EmployeeDependents {
        EmployeeDependentsID
      }
      Department {
        DepartmentID
        DepartmentType
        ParentID
        Description
      }
      Designation {
        JobDesignationID
        Name
      }
      DocumentID
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
      TotalTaxRelief
      FormStatus
      EmploymentType
      CreatedDT
      ModifiedDT
    }
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useSupervisorEvalListingQuery__
 *
 * To run a query within a React component, call `useSupervisorEvalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupervisorEvalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupervisorEvalListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ScheduledTrainingID: // value for 'ScheduledTrainingID'
 *   },
 * });
 */
export function useSupervisorEvalListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SupervisorEvalListingQuery, SupervisorEvalListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SupervisorEvalListingQuery, SupervisorEvalListingQueryVariables>(SupervisorEvalListingDocument, baseOptions);
      }
export function useSupervisorEvalListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupervisorEvalListingQuery, SupervisorEvalListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SupervisorEvalListingQuery, SupervisorEvalListingQueryVariables>(SupervisorEvalListingDocument, baseOptions);
        }
export type SupervisorEvalListingQueryHookResult = ReturnType<typeof useSupervisorEvalListingQuery>;
export type SupervisorEvalListingLazyQueryHookResult = ReturnType<typeof useSupervisorEvalListingLazyQuery>;
export type SupervisorEvalListingQueryResult = ApolloReactCommon.QueryResult<SupervisorEvalListingQuery, SupervisorEvalListingQueryVariables>;
export const CourseEvalListingDocument = gql`
    query CourseEvalListing($CompanyID: String!, $ScheduledTrainingID: String!) {
  CourseEvalListing(CompanyID: $CompanyID, ScheduledTrainingID: $ScheduledTrainingID) {
    CourseEvalID
    ScheduledTrainingID
    CompanyID
    Score
    Remarks
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Employee {
      EmployeeID
      EmployeeNo
      DepartmentID
      DivisionID
      JobGradeID
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
      Company {
        CompanyID
        CompanyName
      }
      EmployeeStatutory {
        EmployeeStatutoryID
      }
      EmployeePrevContribution {
        EmployeePrevContributionID
      }
      EmployeeDependents {
        EmployeeDependentsID
      }
      Department {
        DepartmentID
        DepartmentType
        ParentID
        Description
      }
      Designation {
        JobDesignationID
        Name
      }
      DocumentID
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
      TotalTaxRelief
      FormStatus
      EmploymentType
      CreatedDT
      ModifiedDT
    }
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useCourseEvalListingQuery__
 *
 * To run a query within a React component, call `useCourseEvalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseEvalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseEvalListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ScheduledTrainingID: // value for 'ScheduledTrainingID'
 *   },
 * });
 */
export function useCourseEvalListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CourseEvalListingQuery, CourseEvalListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CourseEvalListingQuery, CourseEvalListingQueryVariables>(CourseEvalListingDocument, baseOptions);
      }
export function useCourseEvalListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CourseEvalListingQuery, CourseEvalListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CourseEvalListingQuery, CourseEvalListingQueryVariables>(CourseEvalListingDocument, baseOptions);
        }
export type CourseEvalListingQueryHookResult = ReturnType<typeof useCourseEvalListingQuery>;
export type CourseEvalListingLazyQueryHookResult = ReturnType<typeof useCourseEvalListingLazyQuery>;
export type CourseEvalListingQueryResult = ApolloReactCommon.QueryResult<CourseEvalListingQuery, CourseEvalListingQueryVariables>;
export const TrainerEvalListingDocument = gql`
    query TrainerEvalListing($CompanyID: String!, $ScheduledTrainingID: String!) {
  TrainerEvalListing(CompanyID: $CompanyID, ScheduledTrainingID: $ScheduledTrainingID) {
    TrainerEvalID
    ScheduledTrainingID
    CompanyID
    Score
    Remarks
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Employee {
      EmployeeID
      EmployeeNo
      DepartmentID
      DivisionID
      JobGradeID
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
      Company {
        CompanyID
        CompanyName
      }
      EmployeeStatutory {
        EmployeeStatutoryID
      }
      EmployeePrevContribution {
        EmployeePrevContributionID
      }
      EmployeeDependents {
        EmployeeDependentsID
      }
      Department {
        DepartmentID
        DepartmentType
        ParentID
        Description
      }
      Designation {
        JobDesignationID
        Name
      }
      DocumentID
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
      TotalTaxRelief
      FormStatus
      EmploymentType
      CreatedDT
      ModifiedDT
    }
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useTrainerEvalListingQuery__
 *
 * To run a query within a React component, call `useTrainerEvalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainerEvalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainerEvalListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ScheduledTrainingID: // value for 'ScheduledTrainingID'
 *   },
 * });
 */
export function useTrainerEvalListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainerEvalListingQuery, TrainerEvalListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainerEvalListingQuery, TrainerEvalListingQueryVariables>(TrainerEvalListingDocument, baseOptions);
      }
export function useTrainerEvalListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainerEvalListingQuery, TrainerEvalListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainerEvalListingQuery, TrainerEvalListingQueryVariables>(TrainerEvalListingDocument, baseOptions);
        }
export type TrainerEvalListingQueryHookResult = ReturnType<typeof useTrainerEvalListingQuery>;
export type TrainerEvalListingLazyQueryHookResult = ReturnType<typeof useTrainerEvalListingLazyQuery>;
export type TrainerEvalListingQueryResult = ApolloReactCommon.QueryResult<TrainerEvalListingQuery, TrainerEvalListingQueryVariables>;
export const GetSupervisorEvalLuationDocument = gql`
    query GetSupervisorEvalLuation($CompanyID: String!) {
  GetSupervisorEvalLuation(CompanyID: $CompanyID) {
    ScheduleTraining {
      ScheduledTrainingID
      SubscriptionAccountID
      CompanyID
      TrainingProviderID
      CourseID
      CourseName
      StartDate
      EndDate
      Venue
      CourseFee
      LumpSum
      PerClass
      MaxPax
      Remarks
      TraineeEval
      CourseEval
      TrainerEval
      SupervisorEval
      TrainingSchedule
      QRCode
      RegisteredTrainee
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      DocumentID
    }
    Rate
  }
}
    `;

/**
 * __useGetSupervisorEvalLuationQuery__
 *
 * To run a query within a React component, call `useGetSupervisorEvalLuationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupervisorEvalLuationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupervisorEvalLuationQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetSupervisorEvalLuationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupervisorEvalLuationQuery, GetSupervisorEvalLuationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupervisorEvalLuationQuery, GetSupervisorEvalLuationQueryVariables>(GetSupervisorEvalLuationDocument, baseOptions);
      }
export function useGetSupervisorEvalLuationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupervisorEvalLuationQuery, GetSupervisorEvalLuationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupervisorEvalLuationQuery, GetSupervisorEvalLuationQueryVariables>(GetSupervisorEvalLuationDocument, baseOptions);
        }
export type GetSupervisorEvalLuationQueryHookResult = ReturnType<typeof useGetSupervisorEvalLuationQuery>;
export type GetSupervisorEvalLuationLazyQueryHookResult = ReturnType<typeof useGetSupervisorEvalLuationLazyQuery>;
export type GetSupervisorEvalLuationQueryResult = ApolloReactCommon.QueryResult<GetSupervisorEvalLuationQuery, GetSupervisorEvalLuationQueryVariables>;
export const GetTrainerEvalLuationDocument = gql`
    query GetTrainerEvalLuation($CompanyID: String!) {
  GetTrainerEvalLuation(CompanyID: $CompanyID) {
    Provider {
      ID
      SubscriptionAccountID
      DocumentID
      Document {
        DocumentID
        DocumentType
        Description
        DocumentFile
        BucketFileName
        FileSize
      }
      Name
      IsExternal
      ApprovedCourses
      Contact {
        ContactID
        FullName
        NickName
        MobileNo
        OfficeNo
        FaxNo
        Email
        NRIC
        PassportNo
        PassportNoLHDN
        Nationality
        Marital
        Gender
        Race
        Religion
        BirthDate
        ProfilePicture
        WebUrl
        Address {
          Line1
          Line2
          Country
          State
          City
          PostalCode
        }
        PermanentAddress {
          Line1
          Line2
          Country
          State
          City
          PostalCode
        }
        SameAddress
      }
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    TrainingCourse {
      Course {
        ScheduledTrainingID
        SubscriptionAccountID
        CompanyID
        TrainingProviderID
        CourseID
        CourseName
        StartDate
        EndDate
        Venue
        CourseFee
        LumpSum
        PerClass
        MaxPax
        Remarks
        TraineeEval
        CourseEval
        TrainerEval
        SupervisorEval
        TrainingSchedule
        QRCode
        RegisteredTrainee
        Status
        CreatedDT
        CreatedBy
        ModifiedDT
        ModifiedBy
        DocumentID
      }
      Rate
    }
    Rate
  }
}
    `;

/**
 * __useGetTrainerEvalLuationQuery__
 *
 * To run a query within a React component, call `useGetTrainerEvalLuationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainerEvalLuationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainerEvalLuationQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetTrainerEvalLuationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTrainerEvalLuationQuery, GetTrainerEvalLuationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTrainerEvalLuationQuery, GetTrainerEvalLuationQueryVariables>(GetTrainerEvalLuationDocument, baseOptions);
      }
export function useGetTrainerEvalLuationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTrainerEvalLuationQuery, GetTrainerEvalLuationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTrainerEvalLuationQuery, GetTrainerEvalLuationQueryVariables>(GetTrainerEvalLuationDocument, baseOptions);
        }
export type GetTrainerEvalLuationQueryHookResult = ReturnType<typeof useGetTrainerEvalLuationQuery>;
export type GetTrainerEvalLuationLazyQueryHookResult = ReturnType<typeof useGetTrainerEvalLuationLazyQuery>;
export type GetTrainerEvalLuationQueryResult = ApolloReactCommon.QueryResult<GetTrainerEvalLuationQuery, GetTrainerEvalLuationQueryVariables>;
export const TraineeEvalSubmitDocument = gql`
    mutation TraineeEvalSubmit($input: TraineeEvalInput!) {
  TraineeEvalSubmit(input: $input)
}
    `;
export type TraineeEvalSubmitMutationFn = ApolloReactCommon.MutationFunction<TraineeEvalSubmitMutation, TraineeEvalSubmitMutationVariables>;

/**
 * __useTraineeEvalSubmitMutation__
 *
 * To run a mutation, you first call `useTraineeEvalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTraineeEvalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [traineeEvalSubmitMutation, { data, loading, error }] = useTraineeEvalSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTraineeEvalSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TraineeEvalSubmitMutation, TraineeEvalSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<TraineeEvalSubmitMutation, TraineeEvalSubmitMutationVariables>(TraineeEvalSubmitDocument, baseOptions);
      }
export type TraineeEvalSubmitMutationHookResult = ReturnType<typeof useTraineeEvalSubmitMutation>;
export type TraineeEvalSubmitMutationResult = ApolloReactCommon.MutationResult<TraineeEvalSubmitMutation>;
export type TraineeEvalSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<TraineeEvalSubmitMutation, TraineeEvalSubmitMutationVariables>;
export const SupervisorEvalSubmitDocument = gql`
    mutation SupervisorEvalSubmit($input: SupervisorEvalInput!) {
  SupervisorEvalSubmit(input: $input)
}
    `;
export type SupervisorEvalSubmitMutationFn = ApolloReactCommon.MutationFunction<SupervisorEvalSubmitMutation, SupervisorEvalSubmitMutationVariables>;

/**
 * __useSupervisorEvalSubmitMutation__
 *
 * To run a mutation, you first call `useSupervisorEvalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupervisorEvalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supervisorEvalSubmitMutation, { data, loading, error }] = useSupervisorEvalSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupervisorEvalSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SupervisorEvalSubmitMutation, SupervisorEvalSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<SupervisorEvalSubmitMutation, SupervisorEvalSubmitMutationVariables>(SupervisorEvalSubmitDocument, baseOptions);
      }
export type SupervisorEvalSubmitMutationHookResult = ReturnType<typeof useSupervisorEvalSubmitMutation>;
export type SupervisorEvalSubmitMutationResult = ApolloReactCommon.MutationResult<SupervisorEvalSubmitMutation>;
export type SupervisorEvalSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<SupervisorEvalSubmitMutation, SupervisorEvalSubmitMutationVariables>;
export const CourseEvalSubmitDocument = gql`
    mutation CourseEvalSubmit($input: CourseEvalInput!) {
  CourseEvalSubmit(input: $input)
}
    `;
export type CourseEvalSubmitMutationFn = ApolloReactCommon.MutationFunction<CourseEvalSubmitMutation, CourseEvalSubmitMutationVariables>;

/**
 * __useCourseEvalSubmitMutation__
 *
 * To run a mutation, you first call `useCourseEvalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseEvalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseEvalSubmitMutation, { data, loading, error }] = useCourseEvalSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseEvalSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CourseEvalSubmitMutation, CourseEvalSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<CourseEvalSubmitMutation, CourseEvalSubmitMutationVariables>(CourseEvalSubmitDocument, baseOptions);
      }
export type CourseEvalSubmitMutationHookResult = ReturnType<typeof useCourseEvalSubmitMutation>;
export type CourseEvalSubmitMutationResult = ApolloReactCommon.MutationResult<CourseEvalSubmitMutation>;
export type CourseEvalSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<CourseEvalSubmitMutation, CourseEvalSubmitMutationVariables>;
export const TrainerEvalSubmitDocument = gql`
    mutation TrainerEvalSubmit($input: TrainerEvalInput!) {
  TrainerEvalSubmit(input: $input)
}
    `;
export type TrainerEvalSubmitMutationFn = ApolloReactCommon.MutationFunction<TrainerEvalSubmitMutation, TrainerEvalSubmitMutationVariables>;

/**
 * __useTrainerEvalSubmitMutation__
 *
 * To run a mutation, you first call `useTrainerEvalSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrainerEvalSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trainerEvalSubmitMutation, { data, loading, error }] = useTrainerEvalSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrainerEvalSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TrainerEvalSubmitMutation, TrainerEvalSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<TrainerEvalSubmitMutation, TrainerEvalSubmitMutationVariables>(TrainerEvalSubmitDocument, baseOptions);
      }
export type TrainerEvalSubmitMutationHookResult = ReturnType<typeof useTrainerEvalSubmitMutation>;
export type TrainerEvalSubmitMutationResult = ApolloReactCommon.MutationResult<TrainerEvalSubmitMutation>;
export type TrainerEvalSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<TrainerEvalSubmitMutation, TrainerEvalSubmitMutationVariables>;
export const FinalRatingListingDocument = gql`
    query FinalRatingListing {
  FinalRatingListing {
    FinalRatingHeaderID
    SubscriptionAccountID
    Title
    Scale
    isInUse
    FinalRatingDetails {
      FinalRatingDetailID
      Description
      ScaleSequence
      From
      To
      FinalRating
    }
  }
}
    `;

/**
 * __useFinalRatingListingQuery__
 *
 * To run a query within a React component, call `useFinalRatingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalRatingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalRatingListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useFinalRatingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FinalRatingListingQuery, FinalRatingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FinalRatingListingQuery, FinalRatingListingQueryVariables>(FinalRatingListingDocument, baseOptions);
      }
export function useFinalRatingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FinalRatingListingQuery, FinalRatingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FinalRatingListingQuery, FinalRatingListingQueryVariables>(FinalRatingListingDocument, baseOptions);
        }
export type FinalRatingListingQueryHookResult = ReturnType<typeof useFinalRatingListingQuery>;
export type FinalRatingListingLazyQueryHookResult = ReturnType<typeof useFinalRatingListingLazyQuery>;
export type FinalRatingListingQueryResult = ApolloReactCommon.QueryResult<FinalRatingListingQuery, FinalRatingListingQueryVariables>;
export const FinalRatingSubmitDocument = gql`
    mutation FinalRatingSubmit($input: FinalRatingHeaderInput!) {
  FinalRatingSubmit(input: $input) {
    FinalRatingHeaderID
  }
}
    `;
export type FinalRatingSubmitMutationFn = ApolloReactCommon.MutationFunction<FinalRatingSubmitMutation, FinalRatingSubmitMutationVariables>;

/**
 * __useFinalRatingSubmitMutation__
 *
 * To run a mutation, you first call `useFinalRatingSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalRatingSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalRatingSubmitMutation, { data, loading, error }] = useFinalRatingSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinalRatingSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FinalRatingSubmitMutation, FinalRatingSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<FinalRatingSubmitMutation, FinalRatingSubmitMutationVariables>(FinalRatingSubmitDocument, baseOptions);
      }
export type FinalRatingSubmitMutationHookResult = ReturnType<typeof useFinalRatingSubmitMutation>;
export type FinalRatingSubmitMutationResult = ApolloReactCommon.MutationResult<FinalRatingSubmitMutation>;
export type FinalRatingSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<FinalRatingSubmitMutation, FinalRatingSubmitMutationVariables>;
export const FinalRatingDeleteDocument = gql`
    mutation FinalRatingDelete($FinalRatingHeaderID: String!) {
  FinalRatingDelete(FinalRatingHeaderID: $FinalRatingHeaderID)
}
    `;
export type FinalRatingDeleteMutationFn = ApolloReactCommon.MutationFunction<FinalRatingDeleteMutation, FinalRatingDeleteMutationVariables>;

/**
 * __useFinalRatingDeleteMutation__
 *
 * To run a mutation, you first call `useFinalRatingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalRatingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalRatingDeleteMutation, { data, loading, error }] = useFinalRatingDeleteMutation({
 *   variables: {
 *      FinalRatingHeaderID: // value for 'FinalRatingHeaderID'
 *   },
 * });
 */
export function useFinalRatingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FinalRatingDeleteMutation, FinalRatingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<FinalRatingDeleteMutation, FinalRatingDeleteMutationVariables>(FinalRatingDeleteDocument, baseOptions);
      }
export type FinalRatingDeleteMutationHookResult = ReturnType<typeof useFinalRatingDeleteMutation>;
export type FinalRatingDeleteMutationResult = ApolloReactCommon.MutationResult<FinalRatingDeleteMutation>;
export type FinalRatingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<FinalRatingDeleteMutation, FinalRatingDeleteMutationVariables>;
export const GetFulfilmentByJobGradeDocument = gql`
    query GetFulfilmentByJobGrade($CompanyID: String!, $JobGradeID: String!) {
  GetFulfilmentByJobGrade(CompanyID: $CompanyID, JobGradeID: $JobGradeID) {
    EmployeeID
    CourseName
    Date
  }
}
    `;

/**
 * __useGetFulfilmentByJobGradeQuery__
 *
 * To run a query within a React component, call `useGetFulfilmentByJobGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulfilmentByJobGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulfilmentByJobGradeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      JobGradeID: // value for 'JobGradeID'
 *   },
 * });
 */
export function useGetFulfilmentByJobGradeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFulfilmentByJobGradeQuery, GetFulfilmentByJobGradeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFulfilmentByJobGradeQuery, GetFulfilmentByJobGradeQueryVariables>(GetFulfilmentByJobGradeDocument, baseOptions);
      }
export function useGetFulfilmentByJobGradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFulfilmentByJobGradeQuery, GetFulfilmentByJobGradeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFulfilmentByJobGradeQuery, GetFulfilmentByJobGradeQueryVariables>(GetFulfilmentByJobGradeDocument, baseOptions);
        }
export type GetFulfilmentByJobGradeQueryHookResult = ReturnType<typeof useGetFulfilmentByJobGradeQuery>;
export type GetFulfilmentByJobGradeLazyQueryHookResult = ReturnType<typeof useGetFulfilmentByJobGradeLazyQuery>;
export type GetFulfilmentByJobGradeQueryResult = ApolloReactCommon.QueryResult<GetFulfilmentByJobGradeQuery, GetFulfilmentByJobGradeQueryVariables>;
export const GetFulfilmentByCourseDocument = gql`
    query GetFulfilmentByCourse($CompanyID: String!) {
  GetFulfilmentByCourse(CompanyID: $CompanyID) {
    ScheduledTrainingID
    OS_Fulfillment
    Employee {
      EmployeeID
      CourseName
      Date
    }
  }
}
    `;

/**
 * __useGetFulfilmentByCourseQuery__
 *
 * To run a query within a React component, call `useGetFulfilmentByCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulfilmentByCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulfilmentByCourseQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetFulfilmentByCourseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFulfilmentByCourseQuery, GetFulfilmentByCourseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFulfilmentByCourseQuery, GetFulfilmentByCourseQueryVariables>(GetFulfilmentByCourseDocument, baseOptions);
      }
export function useGetFulfilmentByCourseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFulfilmentByCourseQuery, GetFulfilmentByCourseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFulfilmentByCourseQuery, GetFulfilmentByCourseQueryVariables>(GetFulfilmentByCourseDocument, baseOptions);
        }
export type GetFulfilmentByCourseQueryHookResult = ReturnType<typeof useGetFulfilmentByCourseQuery>;
export type GetFulfilmentByCourseLazyQueryHookResult = ReturnType<typeof useGetFulfilmentByCourseLazyQuery>;
export type GetFulfilmentByCourseQueryResult = ApolloReactCommon.QueryResult<GetFulfilmentByCourseQuery, GetFulfilmentByCourseQueryVariables>;
export const GetOsFulfilmentByJobGradeDocument = gql`
    query GetOSFulfilmentByJobGrade($CompanyID: String!) {
  GetOSFulfilmentByJobGrade(CompanyID: $CompanyID) {
    JobGradeID
    OS_Fulfillment
  }
}
    `;

/**
 * __useGetOsFulfilmentByJobGradeQuery__
 *
 * To run a query within a React component, call `useGetOsFulfilmentByJobGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOsFulfilmentByJobGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOsFulfilmentByJobGradeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetOsFulfilmentByJobGradeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOsFulfilmentByJobGradeQuery, GetOsFulfilmentByJobGradeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOsFulfilmentByJobGradeQuery, GetOsFulfilmentByJobGradeQueryVariables>(GetOsFulfilmentByJobGradeDocument, baseOptions);
      }
export function useGetOsFulfilmentByJobGradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOsFulfilmentByJobGradeQuery, GetOsFulfilmentByJobGradeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOsFulfilmentByJobGradeQuery, GetOsFulfilmentByJobGradeQueryVariables>(GetOsFulfilmentByJobGradeDocument, baseOptions);
        }
export type GetOsFulfilmentByJobGradeQueryHookResult = ReturnType<typeof useGetOsFulfilmentByJobGradeQuery>;
export type GetOsFulfilmentByJobGradeLazyQueryHookResult = ReturnType<typeof useGetOsFulfilmentByJobGradeLazyQuery>;
export type GetOsFulfilmentByJobGradeQueryResult = ApolloReactCommon.QueryResult<GetOsFulfilmentByJobGradeQuery, GetOsFulfilmentByJobGradeQueryVariables>;
export const HelpdeskSubjectListingDocument = gql`
    query HelpdeskSubjectListing {
  HelpdeskSubjectListing {
    ID
    Subject
    Assignees
    InUse
    Status
    ModifiedDT
  }
}
    `;

/**
 * __useHelpdeskSubjectListingQuery__
 *
 * To run a query within a React component, call `useHelpdeskSubjectListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpdeskSubjectListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpdeskSubjectListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelpdeskSubjectListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HelpdeskSubjectListingQuery, HelpdeskSubjectListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HelpdeskSubjectListingQuery, HelpdeskSubjectListingQueryVariables>(HelpdeskSubjectListingDocument, baseOptions);
      }
export function useHelpdeskSubjectListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HelpdeskSubjectListingQuery, HelpdeskSubjectListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HelpdeskSubjectListingQuery, HelpdeskSubjectListingQueryVariables>(HelpdeskSubjectListingDocument, baseOptions);
        }
export type HelpdeskSubjectListingQueryHookResult = ReturnType<typeof useHelpdeskSubjectListingQuery>;
export type HelpdeskSubjectListingLazyQueryHookResult = ReturnType<typeof useHelpdeskSubjectListingLazyQuery>;
export type HelpdeskSubjectListingQueryResult = ApolloReactCommon.QueryResult<HelpdeskSubjectListingQuery, HelpdeskSubjectListingQueryVariables>;
export const HelpdeskAssigneeListingDocument = gql`
    query HelpdeskAssigneeListing {
  HelpdeskAssigneeListing {
    EmployeeID
    EmployeeNo
    Contact {
      FullName
    }
  }
}
    `;

/**
 * __useHelpdeskAssigneeListingQuery__
 *
 * To run a query within a React component, call `useHelpdeskAssigneeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpdeskAssigneeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpdeskAssigneeListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelpdeskAssigneeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HelpdeskAssigneeListingQuery, HelpdeskAssigneeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HelpdeskAssigneeListingQuery, HelpdeskAssigneeListingQueryVariables>(HelpdeskAssigneeListingDocument, baseOptions);
      }
export function useHelpdeskAssigneeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HelpdeskAssigneeListingQuery, HelpdeskAssigneeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HelpdeskAssigneeListingQuery, HelpdeskAssigneeListingQueryVariables>(HelpdeskAssigneeListingDocument, baseOptions);
        }
export type HelpdeskAssigneeListingQueryHookResult = ReturnType<typeof useHelpdeskAssigneeListingQuery>;
export type HelpdeskAssigneeListingLazyQueryHookResult = ReturnType<typeof useHelpdeskAssigneeListingLazyQuery>;
export type HelpdeskAssigneeListingQueryResult = ApolloReactCommon.QueryResult<HelpdeskAssigneeListingQuery, HelpdeskAssigneeListingQueryVariables>;
export const IsHelpdeskSubjectExistDocument = gql`
    query IsHelpdeskSubjectExist($SubjectID: String, $Subject: String!) {
  IsHelpdeskSubjectExist(Subject: $Subject, SubjectID: $SubjectID)
}
    `;

/**
 * __useIsHelpdeskSubjectExistQuery__
 *
 * To run a query within a React component, call `useIsHelpdeskSubjectExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsHelpdeskSubjectExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsHelpdeskSubjectExistQuery({
 *   variables: {
 *      SubjectID: // value for 'SubjectID'
 *      Subject: // value for 'Subject'
 *   },
 * });
 */
export function useIsHelpdeskSubjectExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsHelpdeskSubjectExistQuery, IsHelpdeskSubjectExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsHelpdeskSubjectExistQuery, IsHelpdeskSubjectExistQueryVariables>(IsHelpdeskSubjectExistDocument, baseOptions);
      }
export function useIsHelpdeskSubjectExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsHelpdeskSubjectExistQuery, IsHelpdeskSubjectExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsHelpdeskSubjectExistQuery, IsHelpdeskSubjectExistQueryVariables>(IsHelpdeskSubjectExistDocument, baseOptions);
        }
export type IsHelpdeskSubjectExistQueryHookResult = ReturnType<typeof useIsHelpdeskSubjectExistQuery>;
export type IsHelpdeskSubjectExistLazyQueryHookResult = ReturnType<typeof useIsHelpdeskSubjectExistLazyQuery>;
export type IsHelpdeskSubjectExistQueryResult = ApolloReactCommon.QueryResult<IsHelpdeskSubjectExistQuery, IsHelpdeskSubjectExistQueryVariables>;
export const HelpdeskSubjectDetailDocument = gql`
    query HelpdeskSubjectDetail($SubjectID: String!) {
  HelpdeskSubjectDetail(SubjectID: $SubjectID) {
    ID
    Subject
    Description
    RequireAttachment
    DefaultAssignee
    TargetResolutionDays
    ReminderFrequency
  }
}
    `;

/**
 * __useHelpdeskSubjectDetailQuery__
 *
 * To run a query within a React component, call `useHelpdeskSubjectDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpdeskSubjectDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpdeskSubjectDetailQuery({
 *   variables: {
 *      SubjectID: // value for 'SubjectID'
 *   },
 * });
 */
export function useHelpdeskSubjectDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HelpdeskSubjectDetailQuery, HelpdeskSubjectDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<HelpdeskSubjectDetailQuery, HelpdeskSubjectDetailQueryVariables>(HelpdeskSubjectDetailDocument, baseOptions);
      }
export function useHelpdeskSubjectDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HelpdeskSubjectDetailQuery, HelpdeskSubjectDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HelpdeskSubjectDetailQuery, HelpdeskSubjectDetailQueryVariables>(HelpdeskSubjectDetailDocument, baseOptions);
        }
export type HelpdeskSubjectDetailQueryHookResult = ReturnType<typeof useHelpdeskSubjectDetailQuery>;
export type HelpdeskSubjectDetailLazyQueryHookResult = ReturnType<typeof useHelpdeskSubjectDetailLazyQuery>;
export type HelpdeskSubjectDetailQueryResult = ApolloReactCommon.QueryResult<HelpdeskSubjectDetailQuery, HelpdeskSubjectDetailQueryVariables>;
export const HelpdeskSubjectSubmitDocument = gql`
    mutation HelpdeskSubjectSubmit($input: HelpdeskSubjectInput!) {
  HelpdeskSubjectSubmit(input: $input)
}
    `;
export type HelpdeskSubjectSubmitMutationFn = ApolloReactCommon.MutationFunction<HelpdeskSubjectSubmitMutation, HelpdeskSubjectSubmitMutationVariables>;

/**
 * __useHelpdeskSubjectSubmitMutation__
 *
 * To run a mutation, you first call `useHelpdeskSubjectSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHelpdeskSubjectSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [helpdeskSubjectSubmitMutation, { data, loading, error }] = useHelpdeskSubjectSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHelpdeskSubjectSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HelpdeskSubjectSubmitMutation, HelpdeskSubjectSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<HelpdeskSubjectSubmitMutation, HelpdeskSubjectSubmitMutationVariables>(HelpdeskSubjectSubmitDocument, baseOptions);
      }
export type HelpdeskSubjectSubmitMutationHookResult = ReturnType<typeof useHelpdeskSubjectSubmitMutation>;
export type HelpdeskSubjectSubmitMutationResult = ApolloReactCommon.MutationResult<HelpdeskSubjectSubmitMutation>;
export type HelpdeskSubjectSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<HelpdeskSubjectSubmitMutation, HelpdeskSubjectSubmitMutationVariables>;
export const HelpdeskSubjectActionDocument = gql`
    mutation HelpdeskSubjectAction($SubjectID: String!, $Action: String!) {
  HelpdeskSubjectAction(SubjectID: $SubjectID, Action: $Action)
}
    `;
export type HelpdeskSubjectActionMutationFn = ApolloReactCommon.MutationFunction<HelpdeskSubjectActionMutation, HelpdeskSubjectActionMutationVariables>;

/**
 * __useHelpdeskSubjectActionMutation__
 *
 * To run a mutation, you first call `useHelpdeskSubjectActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHelpdeskSubjectActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [helpdeskSubjectActionMutation, { data, loading, error }] = useHelpdeskSubjectActionMutation({
 *   variables: {
 *      SubjectID: // value for 'SubjectID'
 *      Action: // value for 'Action'
 *   },
 * });
 */
export function useHelpdeskSubjectActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HelpdeskSubjectActionMutation, HelpdeskSubjectActionMutationVariables>) {
        return ApolloReactHooks.useMutation<HelpdeskSubjectActionMutation, HelpdeskSubjectActionMutationVariables>(HelpdeskSubjectActionDocument, baseOptions);
      }
export type HelpdeskSubjectActionMutationHookResult = ReturnType<typeof useHelpdeskSubjectActionMutation>;
export type HelpdeskSubjectActionMutationResult = ApolloReactCommon.MutationResult<HelpdeskSubjectActionMutation>;
export type HelpdeskSubjectActionMutationOptions = ApolloReactCommon.BaseMutationOptions<HelpdeskSubjectActionMutation, HelpdeskSubjectActionMutationVariables>;
export const GetHelpdeskTicketSummaryDocument = gql`
    query getHelpdeskTicketSummary($EmployeeID: String, $ContactID: String) {
  getHelpdeskTicketSummary(EmployeeID: $EmployeeID, ContactID: $ContactID)
}
    `;

/**
 * __useGetHelpdeskTicketSummaryQuery__
 *
 * To run a query within a React component, call `useGetHelpdeskTicketSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpdeskTicketSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpdeskTicketSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      ContactID: // value for 'ContactID'
 *   },
 * });
 */
export function useGetHelpdeskTicketSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHelpdeskTicketSummaryQuery, GetHelpdeskTicketSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHelpdeskTicketSummaryQuery, GetHelpdeskTicketSummaryQueryVariables>(GetHelpdeskTicketSummaryDocument, baseOptions);
      }
export function useGetHelpdeskTicketSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHelpdeskTicketSummaryQuery, GetHelpdeskTicketSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHelpdeskTicketSummaryQuery, GetHelpdeskTicketSummaryQueryVariables>(GetHelpdeskTicketSummaryDocument, baseOptions);
        }
export type GetHelpdeskTicketSummaryQueryHookResult = ReturnType<typeof useGetHelpdeskTicketSummaryQuery>;
export type GetHelpdeskTicketSummaryLazyQueryHookResult = ReturnType<typeof useGetHelpdeskTicketSummaryLazyQuery>;
export type GetHelpdeskTicketSummaryQueryResult = ApolloReactCommon.QueryResult<GetHelpdeskTicketSummaryQuery, GetHelpdeskTicketSummaryQueryVariables>;
export const HelpdeskTicketDetailDocument = gql`
    query HelpdeskTicketDetail($HelpdeskTicketID: String!) {
  HelpdeskTicketDetail(HelpdeskTicketID: $HelpdeskTicketID) {
    ID
    TicketNo
    Status
    isOverdue
    HelpdeskSubjectID
    SubjectDetails
    EmployeeID
    Employee {
      EmployeeNo
      Contact {
        FullName
      }
    }
    RequestDescription
    RequestAttachment
    RequestDocuments {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    ResolutionDescription
    ResolutionAttachment
    ResolutionDocuments {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useHelpdeskTicketDetailQuery__
 *
 * To run a query within a React component, call `useHelpdeskTicketDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpdeskTicketDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpdeskTicketDetailQuery({
 *   variables: {
 *      HelpdeskTicketID: // value for 'HelpdeskTicketID'
 *   },
 * });
 */
export function useHelpdeskTicketDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HelpdeskTicketDetailQuery, HelpdeskTicketDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<HelpdeskTicketDetailQuery, HelpdeskTicketDetailQueryVariables>(HelpdeskTicketDetailDocument, baseOptions);
      }
export function useHelpdeskTicketDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HelpdeskTicketDetailQuery, HelpdeskTicketDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HelpdeskTicketDetailQuery, HelpdeskTicketDetailQueryVariables>(HelpdeskTicketDetailDocument, baseOptions);
        }
export type HelpdeskTicketDetailQueryHookResult = ReturnType<typeof useHelpdeskTicketDetailQuery>;
export type HelpdeskTicketDetailLazyQueryHookResult = ReturnType<typeof useHelpdeskTicketDetailLazyQuery>;
export type HelpdeskTicketDetailQueryResult = ApolloReactCommon.QueryResult<HelpdeskTicketDetailQuery, HelpdeskTicketDetailQueryVariables>;
export const HelpdeskTicketListingDocument = gql`
    query HelpdeskTicketListing($CompanyID: String, $EmployeeID: String, $ContactID: String, $RequestType: String!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $HelpdeskSubjectType: [String!], $Assignee: [String!], $StartDate: DateTime, $EndDate: DateTime) {
  HelpdeskTicketListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID, ContactID: $ContactID, RequestType: $RequestType, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, HelpdeskSubjectType: $HelpdeskSubjectType, Assignee: $Assignee, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    TicketNo
    Status
    isOverdue
    CreatedDT
    ModifiedDT
    CurrentAssignees
    SubjectDetails
    ReassignedAssignee
    RequestDescription
    AssigneeClosedTicket
    Employee {
      EmployeeNo
      Contact {
        FullName
      }
    }
    RequestAttachment
    RequestDocuments {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useHelpdeskTicketListingQuery__
 *
 * To run a query within a React component, call `useHelpdeskTicketListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpdeskTicketListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpdeskTicketListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      ContactID: // value for 'ContactID'
 *      RequestType: // value for 'RequestType'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      HelpdeskSubjectType: // value for 'HelpdeskSubjectType'
 *      Assignee: // value for 'Assignee'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useHelpdeskTicketListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HelpdeskTicketListingQuery, HelpdeskTicketListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HelpdeskTicketListingQuery, HelpdeskTicketListingQueryVariables>(HelpdeskTicketListingDocument, baseOptions);
      }
export function useHelpdeskTicketListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HelpdeskTicketListingQuery, HelpdeskTicketListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HelpdeskTicketListingQuery, HelpdeskTicketListingQueryVariables>(HelpdeskTicketListingDocument, baseOptions);
        }
export type HelpdeskTicketListingQueryHookResult = ReturnType<typeof useHelpdeskTicketListingQuery>;
export type HelpdeskTicketListingLazyQueryHookResult = ReturnType<typeof useHelpdeskTicketListingLazyQuery>;
export type HelpdeskTicketListingQueryResult = ApolloReactCommon.QueryResult<HelpdeskTicketListingQuery, HelpdeskTicketListingQueryVariables>;
export const HelpdeskAssigneeApprovalHistoryDocument = gql`
    query HelpdeskAssigneeApprovalHistory {
  HelpdeskAssigneeApprovalHistory {
    ID
    TicketNo
    EmployeeID
    Employee {
      EmployeeNo
      Contact {
        FullName
      }
    }
    ContactID
    SubjectDetails
    RequestDescription
    ModifiedDT
    Status
  }
}
    `;

/**
 * __useHelpdeskAssigneeApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useHelpdeskAssigneeApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpdeskAssigneeApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpdeskAssigneeApprovalHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelpdeskAssigneeApprovalHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HelpdeskAssigneeApprovalHistoryQuery, HelpdeskAssigneeApprovalHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<HelpdeskAssigneeApprovalHistoryQuery, HelpdeskAssigneeApprovalHistoryQueryVariables>(HelpdeskAssigneeApprovalHistoryDocument, baseOptions);
      }
export function useHelpdeskAssigneeApprovalHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HelpdeskAssigneeApprovalHistoryQuery, HelpdeskAssigneeApprovalHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HelpdeskAssigneeApprovalHistoryQuery, HelpdeskAssigneeApprovalHistoryQueryVariables>(HelpdeskAssigneeApprovalHistoryDocument, baseOptions);
        }
export type HelpdeskAssigneeApprovalHistoryQueryHookResult = ReturnType<typeof useHelpdeskAssigneeApprovalHistoryQuery>;
export type HelpdeskAssigneeApprovalHistoryLazyQueryHookResult = ReturnType<typeof useHelpdeskAssigneeApprovalHistoryLazyQuery>;
export type HelpdeskAssigneeApprovalHistoryQueryResult = ApolloReactCommon.QueryResult<HelpdeskAssigneeApprovalHistoryQuery, HelpdeskAssigneeApprovalHistoryQueryVariables>;
export const HelpdeskToDoListingDocument = gql`
    query HelpdeskToDoListing($EmployeeID: String!) {
  HelpdeskToDoListing(EmployeeID: $EmployeeID) {
    ID
    TicketNo
    SubjectDetails
    EmployeeID
    Employee {
      EmployeeNo
      Contact {
        FullName
      }
    }
    ContactID
    RequestDescription
    CreatedDT
    Status
    isOverdue
  }
}
    `;

/**
 * __useHelpdeskToDoListingQuery__
 *
 * To run a query within a React component, call `useHelpdeskToDoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpdeskToDoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpdeskToDoListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useHelpdeskToDoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HelpdeskToDoListingQuery, HelpdeskToDoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HelpdeskToDoListingQuery, HelpdeskToDoListingQueryVariables>(HelpdeskToDoListingDocument, baseOptions);
      }
export function useHelpdeskToDoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HelpdeskToDoListingQuery, HelpdeskToDoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HelpdeskToDoListingQuery, HelpdeskToDoListingQueryVariables>(HelpdeskToDoListingDocument, baseOptions);
        }
export type HelpdeskToDoListingQueryHookResult = ReturnType<typeof useHelpdeskToDoListingQuery>;
export type HelpdeskToDoListingLazyQueryHookResult = ReturnType<typeof useHelpdeskToDoListingLazyQuery>;
export type HelpdeskToDoListingQueryResult = ApolloReactCommon.QueryResult<HelpdeskToDoListingQuery, HelpdeskToDoListingQueryVariables>;
export const UpdateOpenDtDocument = gql`
    mutation UpdateOpenDT($HelpdeskTicketID: String!) {
  UpdateOpenDT(HelpdeskTicketID: $HelpdeskTicketID)
}
    `;
export type UpdateOpenDtMutationFn = ApolloReactCommon.MutationFunction<UpdateOpenDtMutation, UpdateOpenDtMutationVariables>;

/**
 * __useUpdateOpenDtMutation__
 *
 * To run a mutation, you first call `useUpdateOpenDtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpenDtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpenDtMutation, { data, loading, error }] = useUpdateOpenDtMutation({
 *   variables: {
 *      HelpdeskTicketID: // value for 'HelpdeskTicketID'
 *   },
 * });
 */
export function useUpdateOpenDtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOpenDtMutation, UpdateOpenDtMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOpenDtMutation, UpdateOpenDtMutationVariables>(UpdateOpenDtDocument, baseOptions);
      }
export type UpdateOpenDtMutationHookResult = ReturnType<typeof useUpdateOpenDtMutation>;
export type UpdateOpenDtMutationResult = ApolloReactCommon.MutationResult<UpdateOpenDtMutation>;
export type UpdateOpenDtMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOpenDtMutation, UpdateOpenDtMutationVariables>;
export const ReassignAssigneeDocument = gql`
    mutation ReassignAssignee($HelpdeskTicketID: String!, $Assignee: [String!]!, $CompanyID: String!) {
  ReassignAssignee(HelpdeskTicketID: $HelpdeskTicketID, Assignee: $Assignee, CompanyID: $CompanyID)
}
    `;
export type ReassignAssigneeMutationFn = ApolloReactCommon.MutationFunction<ReassignAssigneeMutation, ReassignAssigneeMutationVariables>;

/**
 * __useReassignAssigneeMutation__
 *
 * To run a mutation, you first call `useReassignAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignAssigneeMutation, { data, loading, error }] = useReassignAssigneeMutation({
 *   variables: {
 *      HelpdeskTicketID: // value for 'HelpdeskTicketID'
 *      Assignee: // value for 'Assignee'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useReassignAssigneeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReassignAssigneeMutation, ReassignAssigneeMutationVariables>) {
        return ApolloReactHooks.useMutation<ReassignAssigneeMutation, ReassignAssigneeMutationVariables>(ReassignAssigneeDocument, baseOptions);
      }
export type ReassignAssigneeMutationHookResult = ReturnType<typeof useReassignAssigneeMutation>;
export type ReassignAssigneeMutationResult = ApolloReactCommon.MutationResult<ReassignAssigneeMutation>;
export type ReassignAssigneeMutationOptions = ApolloReactCommon.BaseMutationOptions<ReassignAssigneeMutation, ReassignAssigneeMutationVariables>;
export const SubmitHelpdeskTicketDocument = gql`
    mutation SubmitHelpdeskTicket($CompanyID: String!, $input: HelpdeskTicketInput!, $mode: String!) {
  SubmitHelpdeskTicket(CompanyID: $CompanyID, input: $input, mode: $mode)
}
    `;
export type SubmitHelpdeskTicketMutationFn = ApolloReactCommon.MutationFunction<SubmitHelpdeskTicketMutation, SubmitHelpdeskTicketMutationVariables>;

/**
 * __useSubmitHelpdeskTicketMutation__
 *
 * To run a mutation, you first call `useSubmitHelpdeskTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitHelpdeskTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitHelpdeskTicketMutation, { data, loading, error }] = useSubmitHelpdeskTicketMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useSubmitHelpdeskTicketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitHelpdeskTicketMutation, SubmitHelpdeskTicketMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitHelpdeskTicketMutation, SubmitHelpdeskTicketMutationVariables>(SubmitHelpdeskTicketDocument, baseOptions);
      }
export type SubmitHelpdeskTicketMutationHookResult = ReturnType<typeof useSubmitHelpdeskTicketMutation>;
export type SubmitHelpdeskTicketMutationResult = ApolloReactCommon.MutationResult<SubmitHelpdeskTicketMutation>;
export type SubmitHelpdeskTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitHelpdeskTicketMutation, SubmitHelpdeskTicketMutationVariables>;
export const WithdrawOpenTicketDocument = gql`
    mutation WithdrawOpenTicket($HelpdeskTicketID: String!) {
  WithdrawOpenTicket(HelpdeskTicketID: $HelpdeskTicketID)
}
    `;
export type WithdrawOpenTicketMutationFn = ApolloReactCommon.MutationFunction<WithdrawOpenTicketMutation, WithdrawOpenTicketMutationVariables>;

/**
 * __useWithdrawOpenTicketMutation__
 *
 * To run a mutation, you first call `useWithdrawOpenTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawOpenTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawOpenTicketMutation, { data, loading, error }] = useWithdrawOpenTicketMutation({
 *   variables: {
 *      HelpdeskTicketID: // value for 'HelpdeskTicketID'
 *   },
 * });
 */
export function useWithdrawOpenTicketMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WithdrawOpenTicketMutation, WithdrawOpenTicketMutationVariables>) {
        return ApolloReactHooks.useMutation<WithdrawOpenTicketMutation, WithdrawOpenTicketMutationVariables>(WithdrawOpenTicketDocument, baseOptions);
      }
export type WithdrawOpenTicketMutationHookResult = ReturnType<typeof useWithdrawOpenTicketMutation>;
export type WithdrawOpenTicketMutationResult = ApolloReactCommon.MutationResult<WithdrawOpenTicketMutation>;
export type WithdrawOpenTicketMutationOptions = ApolloReactCommon.BaseMutationOptions<WithdrawOpenTicketMutation, WithdrawOpenTicketMutationVariables>;
export const HelpdeskListingReportDocument = gql`
    query HelpdeskListingReport($input: HelpdeskListingReportInput!) {
  HelpdeskListingReport(input: $input)
}
    `;

/**
 * __useHelpdeskListingReportQuery__
 *
 * To run a query within a React component, call `useHelpdeskListingReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelpdeskListingReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelpdeskListingReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHelpdeskListingReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HelpdeskListingReportQuery, HelpdeskListingReportQueryVariables>) {
        return ApolloReactHooks.useQuery<HelpdeskListingReportQuery, HelpdeskListingReportQueryVariables>(HelpdeskListingReportDocument, baseOptions);
      }
export function useHelpdeskListingReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HelpdeskListingReportQuery, HelpdeskListingReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HelpdeskListingReportQuery, HelpdeskListingReportQueryVariables>(HelpdeskListingReportDocument, baseOptions);
        }
export type HelpdeskListingReportQueryHookResult = ReturnType<typeof useHelpdeskListingReportQuery>;
export type HelpdeskListingReportLazyQueryHookResult = ReturnType<typeof useHelpdeskListingReportLazyQuery>;
export type HelpdeskListingReportQueryResult = ApolloReactCommon.QueryResult<HelpdeskListingReportQuery, HelpdeskListingReportQueryVariables>;
export const SubscriptionUserSummaryDocument = gql`
    query SubscriptionUserSummary($SubscriptionUserID: String!) {
  EmployeeSummaryBySubscription(SubscriptionUserID: $SubscriptionUserID) {
    EmployeeID
    CompanyID
    DepartmentID
    DivisionID
    DesignationID
    JobGradeID
    OrgUnit {
      CompanyOrgUnitID
      Description
    }
    Contact {
      ContactID
      FullName
      Email
      MobileNo
    }
    EmployeeNo
    Company {
      CompanyID
      CompanyName
    }
    EmployeeDepartment {
      Description
      DepartmentType
    }
    Designation {
      JobDesignationID
      Name
    }
    EmployeeCareerLogs {
      CareerLogID
      EffectiveDate
      MovementType
      ToPositionTable {
        Name
      }
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    GeneralDocCount
    EAFormDocCount
    PaySlipDocCount
    PersonalDocCount
    GeneralDocTotalSize
    EAFormDocTotalSize
    PaySlipDocTotalSize
    PersonalDocTotalSize
    TotalLeave
    TotalAnnualLeave
    TotalSickLeave
    TotalUnpaidLeave
    TotalClaim
    TotalClaimApproved
    TotalClaimAmountApproved
    TotalRequisition
    TotalPurchaseRequisition
    TotalTravelRequest
    TotalCashAdvanceRequisition
    TotalOtherRequisition
    TotalUnreadNews
    LastUpdatedDates {
      EmployeeLastDate
      AssetsLastDate
      BIKLastDate
      CareerLastDate
      SubordinateLastDate
      TaxLastDate
    }
  }
}
    `;

/**
 * __useSubscriptionUserSummaryQuery__
 *
 * To run a query within a React component, call `useSubscriptionUserSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionUserSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionUserSummaryQuery({
 *   variables: {
 *      SubscriptionUserID: // value for 'SubscriptionUserID'
 *   },
 * });
 */
export function useSubscriptionUserSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubscriptionUserSummaryQuery, SubscriptionUserSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<SubscriptionUserSummaryQuery, SubscriptionUserSummaryQueryVariables>(SubscriptionUserSummaryDocument, baseOptions);
      }
export function useSubscriptionUserSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubscriptionUserSummaryQuery, SubscriptionUserSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubscriptionUserSummaryQuery, SubscriptionUserSummaryQueryVariables>(SubscriptionUserSummaryDocument, baseOptions);
        }
export type SubscriptionUserSummaryQueryHookResult = ReturnType<typeof useSubscriptionUserSummaryQuery>;
export type SubscriptionUserSummaryLazyQueryHookResult = ReturnType<typeof useSubscriptionUserSummaryLazyQuery>;
export type SubscriptionUserSummaryQueryResult = ApolloReactCommon.QueryResult<SubscriptionUserSummaryQuery, SubscriptionUserSummaryQueryVariables>;
export const HomeSummaryDocument = gql`
    query HomeSummary($EmployeeID: String!) {
  HomeSummary(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useHomeSummaryQuery__
 *
 * To run a query within a React component, call `useHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomeSummaryQuery, HomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<HomeSummaryQuery, HomeSummaryQueryVariables>(HomeSummaryDocument, baseOptions);
      }
export function useHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomeSummaryQuery, HomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomeSummaryQuery, HomeSummaryQueryVariables>(HomeSummaryDocument, baseOptions);
        }
export type HomeSummaryQueryHookResult = ReturnType<typeof useHomeSummaryQuery>;
export type HomeSummaryLazyQueryHookResult = ReturnType<typeof useHomeSummaryLazyQuery>;
export type HomeSummaryQueryResult = ApolloReactCommon.QueryResult<HomeSummaryQuery, HomeSummaryQueryVariables>;
export const EmployeeMainMenuSummaryDocument = gql`
    query EmployeeMainMenuSummary($EmployeeID: String!, $CompanyID: String!) {
  EmployeeMainMenuSummary(EmployeeID: $EmployeeID, CompanyID: $CompanyID)
}
    `;

/**
 * __useEmployeeMainMenuSummaryQuery__
 *
 * To run a query within a React component, call `useEmployeeMainMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeMainMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeMainMenuSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useEmployeeMainMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeMainMenuSummaryQuery, EmployeeMainMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeMainMenuSummaryQuery, EmployeeMainMenuSummaryQueryVariables>(EmployeeMainMenuSummaryDocument, baseOptions);
      }
export function useEmployeeMainMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeMainMenuSummaryQuery, EmployeeMainMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeMainMenuSummaryQuery, EmployeeMainMenuSummaryQueryVariables>(EmployeeMainMenuSummaryDocument, baseOptions);
        }
export type EmployeeMainMenuSummaryQueryHookResult = ReturnType<typeof useEmployeeMainMenuSummaryQuery>;
export type EmployeeMainMenuSummaryLazyQueryHookResult = ReturnType<typeof useEmployeeMainMenuSummaryLazyQuery>;
export type EmployeeMainMenuSummaryQueryResult = ApolloReactCommon.QueryResult<EmployeeMainMenuSummaryQuery, EmployeeMainMenuSummaryQueryVariables>;
export const EmploymentInfoSummaryDocument = gql`
    query EmploymentInfoSummary($EmployeeID: String!) {
  EmploymentInfoSummary(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useEmploymentInfoSummaryQuery__
 *
 * To run a query within a React component, call `useEmploymentInfoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmploymentInfoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmploymentInfoSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmploymentInfoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmploymentInfoSummaryQuery, EmploymentInfoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmploymentInfoSummaryQuery, EmploymentInfoSummaryQueryVariables>(EmploymentInfoSummaryDocument, baseOptions);
      }
export function useEmploymentInfoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmploymentInfoSummaryQuery, EmploymentInfoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmploymentInfoSummaryQuery, EmploymentInfoSummaryQueryVariables>(EmploymentInfoSummaryDocument, baseOptions);
        }
export type EmploymentInfoSummaryQueryHookResult = ReturnType<typeof useEmploymentInfoSummaryQuery>;
export type EmploymentInfoSummaryLazyQueryHookResult = ReturnType<typeof useEmploymentInfoSummaryLazyQuery>;
export type EmploymentInfoSummaryQueryResult = ApolloReactCommon.QueryResult<EmploymentInfoSummaryQuery, EmploymentInfoSummaryQueryVariables>;
export const PersonnelMenuSummaryDocument = gql`
    query PersonnelMenuSummary($EmployeeID: String!, $ContactID: String!) {
  PersonnelMenuSummary(EmployeeID: $EmployeeID, ContactID: $ContactID)
}
    `;

/**
 * __usePersonnelMenuSummaryQuery__
 *
 * To run a query within a React component, call `usePersonnelMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelMenuSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      ContactID: // value for 'ContactID'
 *   },
 * });
 */
export function usePersonnelMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelMenuSummaryQuery, PersonnelMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelMenuSummaryQuery, PersonnelMenuSummaryQueryVariables>(PersonnelMenuSummaryDocument, baseOptions);
      }
export function usePersonnelMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelMenuSummaryQuery, PersonnelMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelMenuSummaryQuery, PersonnelMenuSummaryQueryVariables>(PersonnelMenuSummaryDocument, baseOptions);
        }
export type PersonnelMenuSummaryQueryHookResult = ReturnType<typeof usePersonnelMenuSummaryQuery>;
export type PersonnelMenuSummaryLazyQueryHookResult = ReturnType<typeof usePersonnelMenuSummaryLazyQuery>;
export type PersonnelMenuSummaryQueryResult = ApolloReactCommon.QueryResult<PersonnelMenuSummaryQuery, PersonnelMenuSummaryQueryVariables>;
export const MyEmployeeInfoDocument = gql`
    query MyEmployeeInfo($EmployeeID: String!) {
  MyEmployeeInfo(EmployeeID: $EmployeeID) {
    EmployeeID
    EmployeeNo
    DesignationID
    CalendarPolicyID
    OrgUnitID
    ContactID
    Contact {
      ContactID
      FullName
      Salutation
      EmployeeSalutation {
        Name
      }
    }
    OrgUnit {
      CompanyOrgUnitID
      Description
    }
    Company {
      CompanyID
      SubscriptionAccountID
      CompanyName
    }
    Designation {
      Name
    }
    Department {
      Description
    }
    JobGradeID
    TotalAnnualLeave
    TotalSickLeave
    TotalUnpaidLeave
    TotalClaimApproved
    TotalClaimAmountApproved
    TotalTravelRequest
    TotalPurchaseRequisition
    TotalCashAdvanceRequisition
    TotalOtherRequisition
    LastUpdatedDates {
      CareerLastDate
    }
    EmployeeSubordinates {
      Contact {
        FullName
      }
      EmployeeID
      EmployeeNo
      CompanyID
    }
    Documents {
      ParentID
      DocumentType
      FileSize
      DocumentFile
      DocumentID
    }
    RoleID
    RoleName
    Status
    ReportTo
    MyTodo
    MyNotification
    GeneralDocCount
    EAFormDocCount
    PaySlipDocCount
    PersonalDocCount
    GeneralDocTotalSize
    EAFormDocTotalSize
    PaySlipDocTotalSize
    PersonalDocTotalSize
  }
}
    `;

/**
 * __useMyEmployeeInfoQuery__
 *
 * To run a query within a React component, call `useMyEmployeeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyEmployeeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyEmployeeInfoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useMyEmployeeInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>(MyEmployeeInfoDocument, baseOptions);
      }
export function useMyEmployeeInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>(MyEmployeeInfoDocument, baseOptions);
        }
export type MyEmployeeInfoQueryHookResult = ReturnType<typeof useMyEmployeeInfoQuery>;
export type MyEmployeeInfoLazyQueryHookResult = ReturnType<typeof useMyEmployeeInfoLazyQuery>;
export type MyEmployeeInfoQueryResult = ApolloReactCommon.QueryResult<MyEmployeeInfoQuery, MyEmployeeInfoQueryVariables>;
export const NewNotificationDocument = gql`
    subscription newNotification($ToEmployeeID: String!) {
  newNotifications(ToEmployeeID: $ToEmployeeID) {
    ToEmployeeID
    Message
  }
}
    `;

/**
 * __useNewNotificationSubscription__
 *
 * To run a query within a React component, call `useNewNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewNotificationSubscription({
 *   variables: {
 *      ToEmployeeID: // value for 'ToEmployeeID'
 *   },
 * });
 */
export function useNewNotificationSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NewNotificationSubscription, NewNotificationSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<NewNotificationSubscription, NewNotificationSubscriptionVariables>(NewNotificationDocument, baseOptions);
      }
export type NewNotificationSubscriptionHookResult = ReturnType<typeof useNewNotificationSubscription>;
export type NewNotificationSubscriptionResult = ApolloReactCommon.SubscriptionResult<NewNotificationSubscription>;
export const NotificationListingDocument = gql`
    query NotificationListing($CompanyID: String!, $EmployeeID: String!) {
  NotificationListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID) {
    NotificationID
    CompanyID
    FromEmployeeID
    ToEmployeeID
    NotificationType
    RequestType
    RequestID
    IsTodo
    Message
    ReadStatus
    CreatedDT
    CreatedBy
  }
}
    `;

/**
 * __useNotificationListingQuery__
 *
 * To run a query within a React component, call `useNotificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useNotificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationListingQuery, NotificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationListingQuery, NotificationListingQueryVariables>(NotificationListingDocument, baseOptions);
      }
export function useNotificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationListingQuery, NotificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationListingQuery, NotificationListingQueryVariables>(NotificationListingDocument, baseOptions);
        }
export type NotificationListingQueryHookResult = ReturnType<typeof useNotificationListingQuery>;
export type NotificationListingLazyQueryHookResult = ReturnType<typeof useNotificationListingLazyQuery>;
export type NotificationListingQueryResult = ApolloReactCommon.QueryResult<NotificationListingQuery, NotificationListingQueryVariables>;
export const MyNewsListDocument = gql`
    query MyNewsList($SubscriptionAccountID: String!, $EmployeeID: String!) {
  MyNewsList(SubscriptionAccountID: $SubscriptionAccountID, EmployeeID: $EmployeeID)
}
    `;

/**
 * __useMyNewsListQuery__
 *
 * To run a query within a React component, call `useMyNewsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyNewsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyNewsListQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useMyNewsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyNewsListQuery, MyNewsListQueryVariables>) {
        return ApolloReactHooks.useQuery<MyNewsListQuery, MyNewsListQueryVariables>(MyNewsListDocument, baseOptions);
      }
export function useMyNewsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyNewsListQuery, MyNewsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyNewsListQuery, MyNewsListQueryVariables>(MyNewsListDocument, baseOptions);
        }
export type MyNewsListQueryHookResult = ReturnType<typeof useMyNewsListQuery>;
export type MyNewsListLazyQueryHookResult = ReturnType<typeof useMyNewsListLazyQuery>;
export type MyNewsListQueryResult = ApolloReactCommon.QueryResult<MyNewsListQuery, MyNewsListQueryVariables>;
export const EmployeeCompanyListingDocument = gql`
    query EmployeeCompanyListing($EmployeeEmail: String!) {
  EmployeeCompanyListing(EmployeeEmail: $EmployeeEmail)
}
    `;

/**
 * __useEmployeeCompanyListingQuery__
 *
 * To run a query within a React component, call `useEmployeeCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeCompanyListingQuery({
 *   variables: {
 *      EmployeeEmail: // value for 'EmployeeEmail'
 *   },
 * });
 */
export function useEmployeeCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeCompanyListingQuery, EmployeeCompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeCompanyListingQuery, EmployeeCompanyListingQueryVariables>(EmployeeCompanyListingDocument, baseOptions);
      }
export function useEmployeeCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeCompanyListingQuery, EmployeeCompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeCompanyListingQuery, EmployeeCompanyListingQueryVariables>(EmployeeCompanyListingDocument, baseOptions);
        }
export type EmployeeCompanyListingQueryHookResult = ReturnType<typeof useEmployeeCompanyListingQuery>;
export type EmployeeCompanyListingLazyQueryHookResult = ReturnType<typeof useEmployeeCompanyListingLazyQuery>;
export type EmployeeCompanyListingQueryResult = ApolloReactCommon.QueryResult<EmployeeCompanyListingQuery, EmployeeCompanyListingQueryVariables>;
export const CompanyListingByEmailDocument = gql`
    query CompanyListingByEmail($EmployeeEmail: String!) {
  CompanyListingByEmail(EmployeeEmail: $EmployeeEmail)
}
    `;

/**
 * __useCompanyListingByEmailQuery__
 *
 * To run a query within a React component, call `useCompanyListingByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyListingByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyListingByEmailQuery({
 *   variables: {
 *      EmployeeEmail: // value for 'EmployeeEmail'
 *   },
 * });
 */
export function useCompanyListingByEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyListingByEmailQuery, CompanyListingByEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyListingByEmailQuery, CompanyListingByEmailQueryVariables>(CompanyListingByEmailDocument, baseOptions);
      }
export function useCompanyListingByEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyListingByEmailQuery, CompanyListingByEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyListingByEmailQuery, CompanyListingByEmailQueryVariables>(CompanyListingByEmailDocument, baseOptions);
        }
export type CompanyListingByEmailQueryHookResult = ReturnType<typeof useCompanyListingByEmailQuery>;
export type CompanyListingByEmailLazyQueryHookResult = ReturnType<typeof useCompanyListingByEmailLazyQuery>;
export type CompanyListingByEmailQueryResult = ApolloReactCommon.QueryResult<CompanyListingByEmailQuery, CompanyListingByEmailQueryVariables>;
export const SimpleCompanyListingDocument = gql`
    query SimpleCompanyListing($SubscriptionAccountID: String!, $CompanyIDs: [String!]) {
  CompanyListing(SubscriptionAccountID: $SubscriptionAccountID, CompanyIDs: $CompanyIDs) {
    CompanyID
    CompanyName
  }
}
    `;

/**
 * __useSimpleCompanyListingQuery__
 *
 * To run a query within a React component, call `useSimpleCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleCompanyListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      CompanyIDs: // value for 'CompanyIDs'
 *   },
 * });
 */
export function useSimpleCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>(SimpleCompanyListingDocument, baseOptions);
      }
export function useSimpleCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>(SimpleCompanyListingDocument, baseOptions);
        }
export type SimpleCompanyListingQueryHookResult = ReturnType<typeof useSimpleCompanyListingQuery>;
export type SimpleCompanyListingLazyQueryHookResult = ReturnType<typeof useSimpleCompanyListingLazyQuery>;
export type SimpleCompanyListingQueryResult = ApolloReactCommon.QueryResult<SimpleCompanyListingQuery, SimpleCompanyListingQueryVariables>;
export const PayrollHomeInfoLinesOutputDocument = gql`
    query PayrollHomeInfoLinesOutput($CompanyID: String!) {
  PayrollHomeInfoLinesOutput(CompanyID: $CompanyID)
}
    `;

/**
 * __usePayrollHomeInfoLinesOutputQuery__
 *
 * To run a query within a React component, call `usePayrollHomeInfoLinesOutputQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollHomeInfoLinesOutputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollHomeInfoLinesOutputQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePayrollHomeInfoLinesOutputQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollHomeInfoLinesOutputQuery, PayrollHomeInfoLinesOutputQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollHomeInfoLinesOutputQuery, PayrollHomeInfoLinesOutputQueryVariables>(PayrollHomeInfoLinesOutputDocument, baseOptions);
      }
export function usePayrollHomeInfoLinesOutputLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollHomeInfoLinesOutputQuery, PayrollHomeInfoLinesOutputQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollHomeInfoLinesOutputQuery, PayrollHomeInfoLinesOutputQueryVariables>(PayrollHomeInfoLinesOutputDocument, baseOptions);
        }
export type PayrollHomeInfoLinesOutputQueryHookResult = ReturnType<typeof usePayrollHomeInfoLinesOutputQuery>;
export type PayrollHomeInfoLinesOutputLazyQueryHookResult = ReturnType<typeof usePayrollHomeInfoLinesOutputLazyQuery>;
export type PayrollHomeInfoLinesOutputQueryResult = ApolloReactCommon.QueryResult<PayrollHomeInfoLinesOutputQuery, PayrollHomeInfoLinesOutputQueryVariables>;
export const TmsPolicyMenuInfoLinesOutputDocument = gql`
    query TmsPolicyMenuInfoLinesOutput($CompanyID: String!) {
  TmsPolicyMenuInfoLinesOutput(CompanyID: $CompanyID)
}
    `;

/**
 * __useTmsPolicyMenuInfoLinesOutputQuery__
 *
 * To run a query within a React component, call `useTmsPolicyMenuInfoLinesOutputQuery` and pass it any options that fit your needs.
 * When your component renders, `useTmsPolicyMenuInfoLinesOutputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTmsPolicyMenuInfoLinesOutputQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useTmsPolicyMenuInfoLinesOutputQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TmsPolicyMenuInfoLinesOutputQuery, TmsPolicyMenuInfoLinesOutputQueryVariables>) {
        return ApolloReactHooks.useQuery<TmsPolicyMenuInfoLinesOutputQuery, TmsPolicyMenuInfoLinesOutputQueryVariables>(TmsPolicyMenuInfoLinesOutputDocument, baseOptions);
      }
export function useTmsPolicyMenuInfoLinesOutputLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TmsPolicyMenuInfoLinesOutputQuery, TmsPolicyMenuInfoLinesOutputQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TmsPolicyMenuInfoLinesOutputQuery, TmsPolicyMenuInfoLinesOutputQueryVariables>(TmsPolicyMenuInfoLinesOutputDocument, baseOptions);
        }
export type TmsPolicyMenuInfoLinesOutputQueryHookResult = ReturnType<typeof useTmsPolicyMenuInfoLinesOutputQuery>;
export type TmsPolicyMenuInfoLinesOutputLazyQueryHookResult = ReturnType<typeof useTmsPolicyMenuInfoLinesOutputLazyQuery>;
export type TmsPolicyMenuInfoLinesOutputQueryResult = ApolloReactCommon.QueryResult<TmsPolicyMenuInfoLinesOutputQuery, TmsPolicyMenuInfoLinesOutputQueryVariables>;
export const GetCompanyPermissionDocument = gql`
    query getCompanyPermission($hrPermissions: [HrPermission!], $CompanyID: String, $RoleID: String) {
  getCompanyPermission(hrPermissions: $hrPermissions, CompanyID: $CompanyID, RoleID: $RoleID)
}
    `;

/**
 * __useGetCompanyPermissionQuery__
 *
 * To run a query within a React component, call `useGetCompanyPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPermissionQuery({
 *   variables: {
 *      hrPermissions: // value for 'hrPermissions'
 *      CompanyID: // value for 'CompanyID'
 *      RoleID: // value for 'RoleID'
 *   },
 * });
 */
export function useGetCompanyPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyPermissionQuery, GetCompanyPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyPermissionQuery, GetCompanyPermissionQueryVariables>(GetCompanyPermissionDocument, baseOptions);
      }
export function useGetCompanyPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyPermissionQuery, GetCompanyPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyPermissionQuery, GetCompanyPermissionQueryVariables>(GetCompanyPermissionDocument, baseOptions);
        }
export type GetCompanyPermissionQueryHookResult = ReturnType<typeof useGetCompanyPermissionQuery>;
export type GetCompanyPermissionLazyQueryHookResult = ReturnType<typeof useGetCompanyPermissionLazyQuery>;
export type GetCompanyPermissionQueryResult = ApolloReactCommon.QueryResult<GetCompanyPermissionQuery, GetCompanyPermissionQueryVariables>;
export const EmployeeDependentDeleteDocument = gql`
    mutation EmployeeDependentDelete($EmployeeDependentsID: String!) {
  EmployeeDependentDelete(EmployeeDependentsID: $EmployeeDependentsID)
}
    `;
export type EmployeeDependentDeleteMutationFn = ApolloReactCommon.MutationFunction<EmployeeDependentDeleteMutation, EmployeeDependentDeleteMutationVariables>;

/**
 * __useEmployeeDependentDeleteMutation__
 *
 * To run a mutation, you first call `useEmployeeDependentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDependentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeDependentDeleteMutation, { data, loading, error }] = useEmployeeDependentDeleteMutation({
 *   variables: {
 *      EmployeeDependentsID: // value for 'EmployeeDependentsID'
 *   },
 * });
 */
export function useEmployeeDependentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeDependentDeleteMutation, EmployeeDependentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeDependentDeleteMutation, EmployeeDependentDeleteMutationVariables>(EmployeeDependentDeleteDocument, baseOptions);
      }
export type EmployeeDependentDeleteMutationHookResult = ReturnType<typeof useEmployeeDependentDeleteMutation>;
export type EmployeeDependentDeleteMutationResult = ApolloReactCommon.MutationResult<EmployeeDependentDeleteMutation>;
export type EmployeeDependentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeDependentDeleteMutation, EmployeeDependentDeleteMutationVariables>;
export const UpdateEmployeeLastLoginCompanyDocument = gql`
    mutation UpdateEmployeeLastLoginCompany($EmployeeID: String!, $LastestAccessedEntity: String!) {
  UpdateEmployeeLastLoginCompany(EmployeeID: $EmployeeID, LastestAccessedEntity: $LastestAccessedEntity)
}
    `;
export type UpdateEmployeeLastLoginCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeLastLoginCompanyMutation, UpdateEmployeeLastLoginCompanyMutationVariables>;

/**
 * __useUpdateEmployeeLastLoginCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeLastLoginCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeLastLoginCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeLastLoginCompanyMutation, { data, loading, error }] = useUpdateEmployeeLastLoginCompanyMutation({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      LastestAccessedEntity: // value for 'LastestAccessedEntity'
 *   },
 * });
 */
export function useUpdateEmployeeLastLoginCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeLastLoginCompanyMutation, UpdateEmployeeLastLoginCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeLastLoginCompanyMutation, UpdateEmployeeLastLoginCompanyMutationVariables>(UpdateEmployeeLastLoginCompanyDocument, baseOptions);
      }
export type UpdateEmployeeLastLoginCompanyMutationHookResult = ReturnType<typeof useUpdateEmployeeLastLoginCompanyMutation>;
export type UpdateEmployeeLastLoginCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeLastLoginCompanyMutation>;
export type UpdateEmployeeLastLoginCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeLastLoginCompanyMutation, UpdateEmployeeLastLoginCompanyMutationVariables>;
export const PersonnalInfoDocument = gql`
    query PersonnalInfo($EmployeeID: String!) {
  EmployeeSummary(EmployeeID: $EmployeeID) {
    EmployeeID
    Contact {
      NickName
      ContactID
      FullName
      Email
      Nationality
      MobileNo
      NRIC
      PassportNo
      BirthDate
      Gender
      Marital
      Race
      Religion
      Salutation
      EmployeeSalutation {
        Name
      }
      Address {
        Line1
        Line2
        Country
        State
        City
        PostalCode
      }
      PermanentAddress {
        Line1
        Line2
        Country
        State
        City
        PostalCode
      }
      SameAddress
      ProfilePicture
    }
    IsOKU
  }
}
    `;

/**
 * __usePersonnalInfoQuery__
 *
 * To run a query within a React component, call `usePersonnalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnalInfoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function usePersonnalInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnalInfoQuery, PersonnalInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnalInfoQuery, PersonnalInfoQueryVariables>(PersonnalInfoDocument, baseOptions);
      }
export function usePersonnalInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnalInfoQuery, PersonnalInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnalInfoQuery, PersonnalInfoQueryVariables>(PersonnalInfoDocument, baseOptions);
        }
export type PersonnalInfoQueryHookResult = ReturnType<typeof usePersonnalInfoQuery>;
export type PersonnalInfoLazyQueryHookResult = ReturnType<typeof usePersonnalInfoLazyQuery>;
export type PersonnalInfoQueryResult = ApolloReactCommon.QueryResult<PersonnalInfoQuery, PersonnalInfoQueryVariables>;
export const EmployeeInfoDocument = gql`
    query EmployeeInfo($EmployeeID: String!) {
  EmployeeSummary(EmployeeID: $EmployeeID) {
    RetirementAge
    EmployeeID
    CalendarTitle
    CalendarPolicyID
    CalendarPolicy {
      Title
      StateID
      States {
        Description
      }
    }
    Contact {
      NickName
      ContactID
      FullName
      Email
      Nationality
      MobileNo
      NRIC
      PassportNo
      BirthDate
      Gender
      Marital
      Race
      Religion
      Salutation
      EmployeeSalutation {
        Name
      }
      Address {
        Line1
        Line2
        Country
        State
        City
        PostalCode
      }
      PermanentAddress {
        Line1
        Line2
        Country
        State
        City
        PostalCode
      }
      SameAddress
      ProfilePicture
    }
    IsOKU
    IsHOD
    IsSuperUser
    EmployeeNo
    EmploymentType
    Status
    StaffCategory
    StaffCategoryID
    CalendarStateID
    Probation
    SubscriptionAccountID
    Company {
      CompanyID
      CalendarTitle
      CompanyName
      CalendarStateID
      Contact {
        ContactID
        Address {
          Line1
          Line2
          Country
          State
          City
          PostalCode
        }
      }
    }
    ActualConfirmedDate
    Department {
      DepartmentID
      Description
    }
    EmployeeDepartment {
      DepartmentID
      Description
    }
    Designation {
      JobDesignationID
      Name
    }
    JobGrade {
      JobGradeID
      Description
    }
    RecruitmentSource {
      RecruitmentSourceID
      Name
    }
    ReportTo
    JoinedDate
    ConfirmedDate
    ResignedDate
    EmployeeCareerLogs {
      CareerLogID
      EffectiveDate
      SalaryType
      FromSalary
      ToSalary
    }
    OrgUnit {
      CompanyOrgUnitID
      Level
      Description
      ParentID
    }
    EmployeeBankInfo {
      EmployeeBankInfoID
      Bank
      AcctNo
      PaymentMethod
    }
    EmployeeStatutory {
      EmployeeStatutoryID
      IsEPF
      EPFNo
      EPFVERate
      EPFVERateCmp
      IsSocso
      SocsoNo
      IsPCB
      PCBNo
      IsEIS
      EISNo
      IsAngkasa
      AngkasaNo
      IsASB
      ASBNo
      IsKWAP
      KWAPNo
      IsTBH
      TBHNo
      IsZakat
      ZakatNo
      ZakatAmount
      ZakatRate
      ResidentStatus
      TaxMaritialStatus
      ZakatState
      IsHRDF
    }
    EmployeePrevContribution {
      EmployeePrevContributionID
      CompanyID
      EmployeeID
      PeriodYear
      GrossTaxable
      EPFPaid
      PCBPaid
      ZAKATPaid
    }
    EmployeeDependents {
      EmployeeDependentsID
      Relationship
      Education
      ChildEligibleRate
      Occupation
      Contact {
        ContactID
        FullName
        BirthDate
        MobileNo
        NRIC
        PassportNo
        Nationality
      }
      PCBNo
      IsWorking
      IsOKU
      IsEmergencyContact
    }
    Documents {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    GeneralDocCount
    EAFormDocCount
    PaySlipDocCount
    PersonalDocCount
    GeneralDocTotalSize
    EAFormDocTotalSize
    PaySlipDocTotalSize
    PersonalDocTotalSize
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    EmployeeInsuranceSchemes {
      InsuranceSchemeID
      EmployeeInsuranceSchemeID
      StartDate
      EndDate
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      InsuranceScheme {
        Name
        Status
      }
    }
  }
}
    `;

/**
 * __useEmployeeInfoQuery__
 *
 * To run a query within a React component, call `useEmployeeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeInfoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeInfoQuery, EmployeeInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeInfoQuery, EmployeeInfoQueryVariables>(EmployeeInfoDocument, baseOptions);
      }
export function useEmployeeInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeInfoQuery, EmployeeInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeInfoQuery, EmployeeInfoQueryVariables>(EmployeeInfoDocument, baseOptions);
        }
export type EmployeeInfoQueryHookResult = ReturnType<typeof useEmployeeInfoQuery>;
export type EmployeeInfoLazyQueryHookResult = ReturnType<typeof useEmployeeInfoLazyQuery>;
export type EmployeeInfoQueryResult = ApolloReactCommon.QueryResult<EmployeeInfoQuery, EmployeeInfoQueryVariables>;
export const CompanyOrgUnitListDocument = gql`
    query CompanyOrgUnitList($CompanyID: String!) {
  CompanyOrgUnitList(CompanyID: $CompanyID)
}
    `;

/**
 * __useCompanyOrgUnitListQuery__
 *
 * To run a query within a React component, call `useCompanyOrgUnitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOrgUnitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOrgUnitListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCompanyOrgUnitListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyOrgUnitListQuery, CompanyOrgUnitListQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyOrgUnitListQuery, CompanyOrgUnitListQueryVariables>(CompanyOrgUnitListDocument, baseOptions);
      }
export function useCompanyOrgUnitListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyOrgUnitListQuery, CompanyOrgUnitListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyOrgUnitListQuery, CompanyOrgUnitListQueryVariables>(CompanyOrgUnitListDocument, baseOptions);
        }
export type CompanyOrgUnitListQueryHookResult = ReturnType<typeof useCompanyOrgUnitListQuery>;
export type CompanyOrgUnitListLazyQueryHookResult = ReturnType<typeof useCompanyOrgUnitListLazyQuery>;
export type CompanyOrgUnitListQueryResult = ApolloReactCommon.QueryResult<CompanyOrgUnitListQuery, CompanyOrgUnitListQueryVariables>;
export const CompanyOrgUnitListWithFilterDocument = gql`
    query CompanyOrgUnitListWithFilter($CompanyID: String!, $OrgUnitIDs: [String!]) {
  CompanyOrgUnitListWithFilter(CompanyID: $CompanyID, OrgUnitIDs: $OrgUnitIDs)
}
    `;

/**
 * __useCompanyOrgUnitListWithFilterQuery__
 *
 * To run a query within a React component, call `useCompanyOrgUnitListWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOrgUnitListWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOrgUnitListWithFilterQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *   },
 * });
 */
export function useCompanyOrgUnitListWithFilterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyOrgUnitListWithFilterQuery, CompanyOrgUnitListWithFilterQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyOrgUnitListWithFilterQuery, CompanyOrgUnitListWithFilterQueryVariables>(CompanyOrgUnitListWithFilterDocument, baseOptions);
      }
export function useCompanyOrgUnitListWithFilterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyOrgUnitListWithFilterQuery, CompanyOrgUnitListWithFilterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyOrgUnitListWithFilterQuery, CompanyOrgUnitListWithFilterQueryVariables>(CompanyOrgUnitListWithFilterDocument, baseOptions);
        }
export type CompanyOrgUnitListWithFilterQueryHookResult = ReturnType<typeof useCompanyOrgUnitListWithFilterQuery>;
export type CompanyOrgUnitListWithFilterLazyQueryHookResult = ReturnType<typeof useCompanyOrgUnitListWithFilterLazyQuery>;
export type CompanyOrgUnitListWithFilterQueryResult = ApolloReactCommon.QueryResult<CompanyOrgUnitListWithFilterQuery, CompanyOrgUnitListWithFilterQueryVariables>;
export const MyApplicationDetailDocument = gql`
    query MyApplicationDetail($CompanyID: String!, $EmployeeID: String!, $RequestType: String!, $RequestID: String!) {
  MyApplicationDetail(CompanyID: $CompanyID, EmployeeID: $EmployeeID, RequestType: $RequestType, RequestID: $RequestID) {
    leave {
      FromDate
      UntilDate
      TotalDay
      Remarks
      ApprovalStatus
      ApprovalDT
      Name
    }
    claim {
      Subject
      ApprovalStatus
      ApprovalDate
      Details {
        Name
        ReceiptNo
        ClaimAmount
        ClaimRemarks
      }
    }
    requisition {
      RequisitionType
      Subject
      Remark
      NumberOfItem
      Amount
      StartDate
      EndDate
      TravelFrom
      TravelTo
      TransportMode
      PurposeOfTravel
      ApprovalStatus
      ApprovalDate
    }
  }
}
    `;

/**
 * __useMyApplicationDetailQuery__
 *
 * To run a query within a React component, call `useMyApplicationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApplicationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApplicationDetailQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      RequestType: // value for 'RequestType'
 *      RequestID: // value for 'RequestID'
 *   },
 * });
 */
export function useMyApplicationDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyApplicationDetailQuery, MyApplicationDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<MyApplicationDetailQuery, MyApplicationDetailQueryVariables>(MyApplicationDetailDocument, baseOptions);
      }
export function useMyApplicationDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyApplicationDetailQuery, MyApplicationDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyApplicationDetailQuery, MyApplicationDetailQueryVariables>(MyApplicationDetailDocument, baseOptions);
        }
export type MyApplicationDetailQueryHookResult = ReturnType<typeof useMyApplicationDetailQuery>;
export type MyApplicationDetailLazyQueryHookResult = ReturnType<typeof useMyApplicationDetailLazyQuery>;
export type MyApplicationDetailQueryResult = ApolloReactCommon.QueryResult<MyApplicationDetailQuery, MyApplicationDetailQueryVariables>;
export const IsCompanyHierarchyExistsDocument = gql`
    query IsCompanyHierarchyExists($CompanyID: String!) {
  IsCompanyHierarchyExists(CompanyID: $CompanyID)
}
    `;

/**
 * __useIsCompanyHierarchyExistsQuery__
 *
 * To run a query within a React component, call `useIsCompanyHierarchyExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsCompanyHierarchyExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsCompanyHierarchyExistsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useIsCompanyHierarchyExistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsCompanyHierarchyExistsQuery, IsCompanyHierarchyExistsQueryVariables>) {
        return ApolloReactHooks.useQuery<IsCompanyHierarchyExistsQuery, IsCompanyHierarchyExistsQueryVariables>(IsCompanyHierarchyExistsDocument, baseOptions);
      }
export function useIsCompanyHierarchyExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsCompanyHierarchyExistsQuery, IsCompanyHierarchyExistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsCompanyHierarchyExistsQuery, IsCompanyHierarchyExistsQueryVariables>(IsCompanyHierarchyExistsDocument, baseOptions);
        }
export type IsCompanyHierarchyExistsQueryHookResult = ReturnType<typeof useIsCompanyHierarchyExistsQuery>;
export type IsCompanyHierarchyExistsLazyQueryHookResult = ReturnType<typeof useIsCompanyHierarchyExistsLazyQuery>;
export type IsCompanyHierarchyExistsQueryResult = ApolloReactCommon.QueryResult<IsCompanyHierarchyExistsQuery, IsCompanyHierarchyExistsQueryVariables>;
export const CompanyTaxReliefUpdateDocument = gql`
    mutation CompanyTaxReliefUpdate($CompanyID: String!, $ExcludeTaxRelief: Boolean!) {
  CompanyTaxReliefUpdate(CompanyID: $CompanyID, ExcludeTaxRelief: $ExcludeTaxRelief)
}
    `;
export type CompanyTaxReliefUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyTaxReliefUpdateMutation, CompanyTaxReliefUpdateMutationVariables>;

/**
 * __useCompanyTaxReliefUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyTaxReliefUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyTaxReliefUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyTaxReliefUpdateMutation, { data, loading, error }] = useCompanyTaxReliefUpdateMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ExcludeTaxRelief: // value for 'ExcludeTaxRelief'
 *   },
 * });
 */
export function useCompanyTaxReliefUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyTaxReliefUpdateMutation, CompanyTaxReliefUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyTaxReliefUpdateMutation, CompanyTaxReliefUpdateMutationVariables>(CompanyTaxReliefUpdateDocument, baseOptions);
      }
export type CompanyTaxReliefUpdateMutationHookResult = ReturnType<typeof useCompanyTaxReliefUpdateMutation>;
export type CompanyTaxReliefUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyTaxReliefUpdateMutation>;
export type CompanyTaxReliefUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyTaxReliefUpdateMutation, CompanyTaxReliefUpdateMutationVariables>;
export const CompanySummaryDocument = gql`
    query CompanySummary($CompanyID: String!) {
  CompanySummary(CompanyID: $CompanyID) {
    CompanyID
    CompanyName
    SupportEmails
    BizRegNo
    Sector
    ExcludeTaxRelief
    ProjectExpenseTrxDayLimit
    ProjectExpenseCutOff
    Departments {
      DepartmentID
    }
    Employees {
      EmployeeID
    }
    Contact {
      ContactID
      Email
      WebUrl
      OfficeNo
      MobileNo
      FaxNo
      Address {
        Line1
        Line2
        PostalCode
        City
        State
        Country
      }
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    Documents {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    CalendarStateID
    PayrollCutOffDay
    LeaveCutOffDay
    ClaimCutOffDay
    EnableExpenseAccount
    ClaimTrxDayLimit
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    LeaveRounding {
      LeaveRoundingID
      Title
    }
    CustomPayslipFormat
    CustomPayrollGlExportFormat
    CustomClaimGlExportFormat
    CustomProjectExpenseGlExportFormat
    CustomClaimSubmissionReport
  }
}
    `;

/**
 * __useCompanySummaryQuery__
 *
 * To run a query within a React component, call `useCompanySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCompanySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanySummaryQuery, CompanySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanySummaryQuery, CompanySummaryQueryVariables>(CompanySummaryDocument, baseOptions);
      }
export function useCompanySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanySummaryQuery, CompanySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanySummaryQuery, CompanySummaryQueryVariables>(CompanySummaryDocument, baseOptions);
        }
export type CompanySummaryQueryHookResult = ReturnType<typeof useCompanySummaryQuery>;
export type CompanySummaryLazyQueryHookResult = ReturnType<typeof useCompanySummaryLazyQuery>;
export type CompanySummaryQueryResult = ApolloReactCommon.QueryResult<CompanySummaryQuery, CompanySummaryQueryVariables>;
export const GetEmployeeInsuranceSchemeDocument = gql`
    query GetEmployeeInsuranceScheme($EmployeeInsuranceSchemeID: String, $EmployeeID: String) {
  GetEmployeeInsuranceScheme(EmployeeInsuranceSchemeID: $EmployeeInsuranceSchemeID, EmployeeID: $EmployeeID) {
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    EmployeeInsuranceSchemeID
    EmployeeID
    InsuranceSchemeID
    StartDate
    EndDate
    InsuranceScheme {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      InsuranceSchemeID
      Document {
        Description
        DocumentFile
      }
      Name
    }
  }
}
    `;

/**
 * __useGetEmployeeInsuranceSchemeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeInsuranceSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeInsuranceSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeInsuranceSchemeQuery({
 *   variables: {
 *      EmployeeInsuranceSchemeID: // value for 'EmployeeInsuranceSchemeID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetEmployeeInsuranceSchemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeInsuranceSchemeQuery, GetEmployeeInsuranceSchemeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeInsuranceSchemeQuery, GetEmployeeInsuranceSchemeQueryVariables>(GetEmployeeInsuranceSchemeDocument, baseOptions);
      }
export function useGetEmployeeInsuranceSchemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeInsuranceSchemeQuery, GetEmployeeInsuranceSchemeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeInsuranceSchemeQuery, GetEmployeeInsuranceSchemeQueryVariables>(GetEmployeeInsuranceSchemeDocument, baseOptions);
        }
export type GetEmployeeInsuranceSchemeQueryHookResult = ReturnType<typeof useGetEmployeeInsuranceSchemeQuery>;
export type GetEmployeeInsuranceSchemeLazyQueryHookResult = ReturnType<typeof useGetEmployeeInsuranceSchemeLazyQuery>;
export type GetEmployeeInsuranceSchemeQueryResult = ApolloReactCommon.QueryResult<GetEmployeeInsuranceSchemeQuery, GetEmployeeInsuranceSchemeQueryVariables>;
export const InvoiceRpaDocument = gql`
    mutation InvoiceRPA($files: [Upload!]!) {
  InvoiceRPA(files: $files) {
    InvoiceDate
    InvoiceId
    InvoiceTotal
    VendorName
  }
}
    `;
export type InvoiceRpaMutationFn = ApolloReactCommon.MutationFunction<InvoiceRpaMutation, InvoiceRpaMutationVariables>;

/**
 * __useInvoiceRpaMutation__
 *
 * To run a mutation, you first call `useInvoiceRpaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceRpaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceRpaMutation, { data, loading, error }] = useInvoiceRpaMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useInvoiceRpaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceRpaMutation, InvoiceRpaMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceRpaMutation, InvoiceRpaMutationVariables>(InvoiceRpaDocument, baseOptions);
      }
export type InvoiceRpaMutationHookResult = ReturnType<typeof useInvoiceRpaMutation>;
export type InvoiceRpaMutationResult = ApolloReactCommon.MutationResult<InvoiceRpaMutation>;
export type InvoiceRpaMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceRpaMutation, InvoiceRpaMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($IsApprovalPolicy: Boolean, $ID: String) {
  getRole(IsApprovalPolicy: $IsApprovalPolicy, ID: $ID) {
    ID
    name
    rolePerm {
      permissionID
      roleID
      permName
    }
    roleUserAssignments {
      roleID
      userID
      entityID
      ID
    }
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      IsApprovalPolicy: // value for 'IsApprovalPolicy'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
      }
export function useGetRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = ApolloReactCommon.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetRoleByUserIdDocument = gql`
    query getRoleByUserID($UserID: String) {
  getRole(UserID: $UserID) {
    ID
    name
  }
}
    `;

/**
 * __useGetRoleByUserIdQuery__
 *
 * To run a query within a React component, call `useGetRoleByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleByUserIdQuery({
 *   variables: {
 *      UserID: // value for 'UserID'
 *   },
 * });
 */
export function useGetRoleByUserIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>(GetRoleByUserIdDocument, baseOptions);
      }
export function useGetRoleByUserIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>(GetRoleByUserIdDocument, baseOptions);
        }
export type GetRoleByUserIdQueryHookResult = ReturnType<typeof useGetRoleByUserIdQuery>;
export type GetRoleByUserIdLazyQueryHookResult = ReturnType<typeof useGetRoleByUserIdLazyQuery>;
export type GetRoleByUserIdQueryResult = ApolloReactCommon.QueryResult<GetRoleByUserIdQuery, GetRoleByUserIdQueryVariables>;
export const GetRoleListDocument = gql`
    query getRoleList {
  getRoleList
}
    `;

/**
 * __useGetRoleListQuery__
 *
 * To run a query within a React component, call `useGetRoleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoleListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleListQuery, GetRoleListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleListQuery, GetRoleListQueryVariables>(GetRoleListDocument, baseOptions);
      }
export function useGetRoleListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleListQuery, GetRoleListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleListQuery, GetRoleListQueryVariables>(GetRoleListDocument, baseOptions);
        }
export type GetRoleListQueryHookResult = ReturnType<typeof useGetRoleListQuery>;
export type GetRoleListLazyQueryHookResult = ReturnType<typeof useGetRoleListLazyQuery>;
export type GetRoleListQueryResult = ApolloReactCommon.QueryResult<GetRoleListQuery, GetRoleListQueryVariables>;
export const DocumentNumberingListingDocument = gql`
    query DocumentNumberingListing($IsStatusActive: Boolean) {
  DocumentNumberingList(IsStatusActive: $IsStatusActive) {
    DocumentNumberHeaderID
    Name
    SampleOutput
    Status
    StartFrom
    ModifiedDT
    CreatedDT
    DocNumDetail {
      DocumentNumberHeaderID
      FormatType
      FormatValue
      Sequence
    }
    IsStandard
  }
}
    `;

/**
 * __useDocumentNumberingListingQuery__
 *
 * To run a query within a React component, call `useDocumentNumberingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentNumberingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentNumberingListingQuery({
 *   variables: {
 *      IsStatusActive: // value for 'IsStatusActive'
 *   },
 * });
 */
export function useDocumentNumberingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentNumberingListingQuery, DocumentNumberingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentNumberingListingQuery, DocumentNumberingListingQueryVariables>(DocumentNumberingListingDocument, baseOptions);
      }
export function useDocumentNumberingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentNumberingListingQuery, DocumentNumberingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentNumberingListingQuery, DocumentNumberingListingQueryVariables>(DocumentNumberingListingDocument, baseOptions);
        }
export type DocumentNumberingListingQueryHookResult = ReturnType<typeof useDocumentNumberingListingQuery>;
export type DocumentNumberingListingLazyQueryHookResult = ReturnType<typeof useDocumentNumberingListingLazyQuery>;
export type DocumentNumberingListingQueryResult = ApolloReactCommon.QueryResult<DocumentNumberingListingQuery, DocumentNumberingListingQueryVariables>;
export const DocumentNumberingUpdateStatusDocument = gql`
    mutation DocumentNumberingUpdateStatus($DocumentNumberHeaderID: String!, $DocStatus: String!) {
  DocumentNumberingUpdateStatus(DocumentNumberHeaderID: $DocumentNumberHeaderID, DocStatus: $DocStatus)
}
    `;
export type DocumentNumberingUpdateStatusMutationFn = ApolloReactCommon.MutationFunction<DocumentNumberingUpdateStatusMutation, DocumentNumberingUpdateStatusMutationVariables>;

/**
 * __useDocumentNumberingUpdateStatusMutation__
 *
 * To run a mutation, you first call `useDocumentNumberingUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentNumberingUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentNumberingUpdateStatusMutation, { data, loading, error }] = useDocumentNumberingUpdateStatusMutation({
 *   variables: {
 *      DocumentNumberHeaderID: // value for 'DocumentNumberHeaderID'
 *      DocStatus: // value for 'DocStatus'
 *   },
 * });
 */
export function useDocumentNumberingUpdateStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentNumberingUpdateStatusMutation, DocumentNumberingUpdateStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentNumberingUpdateStatusMutation, DocumentNumberingUpdateStatusMutationVariables>(DocumentNumberingUpdateStatusDocument, baseOptions);
      }
export type DocumentNumberingUpdateStatusMutationHookResult = ReturnType<typeof useDocumentNumberingUpdateStatusMutation>;
export type DocumentNumberingUpdateStatusMutationResult = ApolloReactCommon.MutationResult<DocumentNumberingUpdateStatusMutation>;
export type DocumentNumberingUpdateStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentNumberingUpdateStatusMutation, DocumentNumberingUpdateStatusMutationVariables>;
export const ViewGeneralSettingSummaryDocument = gql`
    query ViewGeneralSettingSummary($SubscriptionAccountID: String!) {
  ViewGeneralSettingSummary(SubscriptionAccountID: $SubscriptionAccountID) {
    RoleSettingsIsLastUpdated
    RoleSettingsLastPostedDate
    CompanylastPosted
    NewslastPosted
    AutoNumberSettingsLastPostedDate
  }
}
    `;

/**
 * __useViewGeneralSettingSummaryQuery__
 *
 * To run a query within a React component, call `useViewGeneralSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewGeneralSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewGeneralSettingSummaryQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useViewGeneralSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewGeneralSettingSummaryQuery, ViewGeneralSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewGeneralSettingSummaryQuery, ViewGeneralSettingSummaryQueryVariables>(ViewGeneralSettingSummaryDocument, baseOptions);
      }
export function useViewGeneralSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewGeneralSettingSummaryQuery, ViewGeneralSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewGeneralSettingSummaryQuery, ViewGeneralSettingSummaryQueryVariables>(ViewGeneralSettingSummaryDocument, baseOptions);
        }
export type ViewGeneralSettingSummaryQueryHookResult = ReturnType<typeof useViewGeneralSettingSummaryQuery>;
export type ViewGeneralSettingSummaryLazyQueryHookResult = ReturnType<typeof useViewGeneralSettingSummaryLazyQuery>;
export type ViewGeneralSettingSummaryQueryResult = ApolloReactCommon.QueryResult<ViewGeneralSettingSummaryQuery, ViewGeneralSettingSummaryQueryVariables>;
export const SystemAdminMenuSummaryDocument = gql`
    query SystemAdminMenuSummary($accountID: String!, $userID: String!) {
  SystemAdminMenuSummary(accountID: $accountID, userID: $userID)
}
    `;

/**
 * __useSystemAdminMenuSummaryQuery__
 *
 * To run a query within a React component, call `useSystemAdminMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemAdminMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemAdminMenuSummaryQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useSystemAdminMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SystemAdminMenuSummaryQuery, SystemAdminMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<SystemAdminMenuSummaryQuery, SystemAdminMenuSummaryQueryVariables>(SystemAdminMenuSummaryDocument, baseOptions);
      }
export function useSystemAdminMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemAdminMenuSummaryQuery, SystemAdminMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SystemAdminMenuSummaryQuery, SystemAdminMenuSummaryQueryVariables>(SystemAdminMenuSummaryDocument, baseOptions);
        }
export type SystemAdminMenuSummaryQueryHookResult = ReturnType<typeof useSystemAdminMenuSummaryQuery>;
export type SystemAdminMenuSummaryLazyQueryHookResult = ReturnType<typeof useSystemAdminMenuSummaryLazyQuery>;
export type SystemAdminMenuSummaryQueryResult = ApolloReactCommon.QueryResult<SystemAdminMenuSummaryQuery, SystemAdminMenuSummaryQueryVariables>;
export const DeleteRolePermissionDocument = gql`
    mutation DeleteRolePermission($roleID: String!) {
  DeleteRolePermission(roleID: $roleID)
}
    `;
export type DeleteRolePermissionMutationFn = ApolloReactCommon.MutationFunction<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;

/**
 * __useDeleteRolePermissionMutation__
 *
 * To run a mutation, you first call `useDeleteRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolePermissionMutation, { data, loading, error }] = useDeleteRolePermissionMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useDeleteRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>(DeleteRolePermissionDocument, baseOptions);
      }
export type DeleteRolePermissionMutationHookResult = ReturnType<typeof useDeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationResult = ApolloReactCommon.MutationResult<DeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;
export const CreateRolePermissionDocument = gql`
    mutation CreateRolePermission($input: RoleInput!, $permissionArr: [HrPermission!]!) {
  CreateRolePermission(input: $input, permissionArr: $permissionArr)
}
    `;
export type CreateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;

/**
 * __useCreateRolePermissionMutation__
 *
 * To run a mutation, you first call `useCreateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRolePermissionMutation, { data, loading, error }] = useCreateRolePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useCreateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>(CreateRolePermissionDocument, baseOptions);
      }
export type CreateRolePermissionMutationHookResult = ReturnType<typeof useCreateRolePermissionMutation>;
export type CreateRolePermissionMutationResult = ApolloReactCommon.MutationResult<CreateRolePermissionMutation>;
export type CreateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;
export const UpdateRolePermissionDocument = gql`
    mutation UpdateRolePermission($input: RoleInput!, $permissionArr: [HrPermission!]!) {
  UpdateRolePermission(input: $input, permissionArr: $permissionArr)
}
    `;
export type UpdateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;

/**
 * __useUpdateRolePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolePermissionMutation, { data, loading, error }] = useUpdateRolePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useUpdateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>(UpdateRolePermissionDocument, baseOptions);
      }
export type UpdateRolePermissionMutationHookResult = ReturnType<typeof useUpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationResult = ApolloReactCommon.MutationResult<UpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;
export const CompanyInsertDocument = gql`
    mutation CompanyInsert($CompanyInput: CompanyInput!, $ContactInput: ContactInput!, $DocumentsInput: DocumentsInput!) {
  CompanyInsert(CompanyInput: $CompanyInput, ContactInput: $ContactInput, DocumentsInput: $DocumentsInput)
}
    `;
export type CompanyInsertMutationFn = ApolloReactCommon.MutationFunction<CompanyInsertMutation, CompanyInsertMutationVariables>;

/**
 * __useCompanyInsertMutation__
 *
 * To run a mutation, you first call `useCompanyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyInsertMutation, { data, loading, error }] = useCompanyInsertMutation({
 *   variables: {
 *      CompanyInput: // value for 'CompanyInput'
 *      ContactInput: // value for 'ContactInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useCompanyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyInsertMutation, CompanyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyInsertMutation, CompanyInsertMutationVariables>(CompanyInsertDocument, baseOptions);
      }
export type CompanyInsertMutationHookResult = ReturnType<typeof useCompanyInsertMutation>;
export type CompanyInsertMutationResult = ApolloReactCommon.MutationResult<CompanyInsertMutation>;
export type CompanyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyInsertMutation, CompanyInsertMutationVariables>;
export const CompanyUpdateDocument = gql`
    mutation CompanyUpdate($CompanyID: String!, $ContactID: String!, $CompanyInput: CompanyInput!, $ContactInput: ContactInput!, $DocumentsInput: DocumentsInput!, $DocumentID: String!) {
  CompanyUpdate(CompanyID: $CompanyID, ContactID: $ContactID, CompanyInput: $CompanyInput, ContactInput: $ContactInput, DocumentsInput: $DocumentsInput, DocumentID: $DocumentID)
}
    `;
export type CompanyUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyUpdateMutation, CompanyUpdateMutationVariables>;

/**
 * __useCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateMutation, { data, loading, error }] = useCompanyUpdateMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ContactID: // value for 'ContactID'
 *      CompanyInput: // value for 'CompanyInput'
 *      ContactInput: // value for 'ContactInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useCompanyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyUpdateMutation, CompanyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyUpdateMutation, CompanyUpdateMutationVariables>(CompanyUpdateDocument, baseOptions);
      }
export type CompanyUpdateMutationHookResult = ReturnType<typeof useCompanyUpdateMutation>;
export type CompanyUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyUpdateMutation>;
export type CompanyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyUpdateMutation, CompanyUpdateMutationVariables>;
export const DocumentNumberingInsertDocument = gql`
    mutation DocumentNumberingInsert($input: DocumentNumberingInput!) {
  DocumentNumberingInsert(input: $input)
}
    `;
export type DocumentNumberingInsertMutationFn = ApolloReactCommon.MutationFunction<DocumentNumberingInsertMutation, DocumentNumberingInsertMutationVariables>;

/**
 * __useDocumentNumberingInsertMutation__
 *
 * To run a mutation, you first call `useDocumentNumberingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentNumberingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentNumberingInsertMutation, { data, loading, error }] = useDocumentNumberingInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentNumberingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentNumberingInsertMutation, DocumentNumberingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentNumberingInsertMutation, DocumentNumberingInsertMutationVariables>(DocumentNumberingInsertDocument, baseOptions);
      }
export type DocumentNumberingInsertMutationHookResult = ReturnType<typeof useDocumentNumberingInsertMutation>;
export type DocumentNumberingInsertMutationResult = ApolloReactCommon.MutationResult<DocumentNumberingInsertMutation>;
export type DocumentNumberingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentNumberingInsertMutation, DocumentNumberingInsertMutationVariables>;
export const DocumentNumberingUpdateDocument = gql`
    mutation DocumentNumberingUpdate($input: DocumentNumberingInput!) {
  DocumentNumberingUpdate(input: $input)
}
    `;
export type DocumentNumberingUpdateMutationFn = ApolloReactCommon.MutationFunction<DocumentNumberingUpdateMutation, DocumentNumberingUpdateMutationVariables>;

/**
 * __useDocumentNumberingUpdateMutation__
 *
 * To run a mutation, you first call `useDocumentNumberingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentNumberingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentNumberingUpdateMutation, { data, loading, error }] = useDocumentNumberingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentNumberingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentNumberingUpdateMutation, DocumentNumberingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentNumberingUpdateMutation, DocumentNumberingUpdateMutationVariables>(DocumentNumberingUpdateDocument, baseOptions);
      }
export type DocumentNumberingUpdateMutationHookResult = ReturnType<typeof useDocumentNumberingUpdateMutation>;
export type DocumentNumberingUpdateMutationResult = ApolloReactCommon.MutationResult<DocumentNumberingUpdateMutation>;
export type DocumentNumberingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentNumberingUpdateMutation, DocumentNumberingUpdateMutationVariables>;
export const ViewCompanySummaryDocument = gql`
    query ViewCompanySummary($CompanyID: String!, $SubscriptionAccountID: String!) {
  CompanySummaryDates(CompanyID: $CompanyID, SubscriptionAccountID: $SubscriptionAccountID) {
    CompanyInfoIsNewLastUpdated
    CompanyInfoLastPostedDate
    StatutoryLastPostedDate
    DepartmentLastPostedDate
    CompanyAccessAuthLastPostedDate
    ExpensesAccountLastPostedDate
    BankInfoLastPostedDate
    CalendarPolicyLastPostedDate
    CalendarOffDayPolicyLastPostedDate
    LeavePolicyLastPostedDate
    ClaimPolicyLastPostedDate
    PayrollPolicyLastPostedDate
    NotificationPolicyLastPostedDate
    EmployeeLastPostedDate
    CalendarPolicyLastUpdated
    CalendarPolicyListLastPostedDate
    NoOfGeneralDocument
    TotalSizeOfGeneralDocument
    RoleLastPostedDate
    NoOfDepartment
    NoOfRoleAssignment
    CompanyHierarchyLastPostedDate
    CompanyOrgUnitLastPostedDate
    CompanyReportingStructureLastPostedDate
    CompanyJobPositionLastPostedDate
    CompanyAccessLastPostedDate
    CompanyAutoNumberingLastPostedDate
    DefaultState
    NoOfStates
  }
}
    `;

/**
 * __useViewCompanySummaryQuery__
 *
 * To run a query within a React component, call `useViewCompanySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCompanySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCompanySummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useViewCompanySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewCompanySummaryQuery, ViewCompanySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewCompanySummaryQuery, ViewCompanySummaryQueryVariables>(ViewCompanySummaryDocument, baseOptions);
      }
export function useViewCompanySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewCompanySummaryQuery, ViewCompanySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewCompanySummaryQuery, ViewCompanySummaryQueryVariables>(ViewCompanySummaryDocument, baseOptions);
        }
export type ViewCompanySummaryQueryHookResult = ReturnType<typeof useViewCompanySummaryQuery>;
export type ViewCompanySummaryLazyQueryHookResult = ReturnType<typeof useViewCompanySummaryLazyQuery>;
export type ViewCompanySummaryQueryResult = ApolloReactCommon.QueryResult<ViewCompanySummaryQuery, ViewCompanySummaryQueryVariables>;
export const ViewNotificationPolicySummaryDocument = gql`
    query ViewNotificationPolicySummary($CompanyID: String!) {
  NotificationPolicySummaryDates(CompanyID: $CompanyID) {
    NotificationEmploymentIsLastUpdated
    NotificationLeaveIsLastUpdated
    NotificationClaimIsLastUpdated
    NotificationRequisitionIsLastUpdated
    NotificationEmploymentLastPostedDate
    NotificationLeaveLastPostedDate
    NotificationClaimLastPostedDate
    NotificationRequisitionLastPostedDate
    NotificationLastPostedDate
    NotificationIsLastUpdated
  }
}
    `;

/**
 * __useViewNotificationPolicySummaryQuery__
 *
 * To run a query within a React component, call `useViewNotificationPolicySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewNotificationPolicySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewNotificationPolicySummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useViewNotificationPolicySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewNotificationPolicySummaryQuery, ViewNotificationPolicySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewNotificationPolicySummaryQuery, ViewNotificationPolicySummaryQueryVariables>(ViewNotificationPolicySummaryDocument, baseOptions);
      }
export function useViewNotificationPolicySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewNotificationPolicySummaryQuery, ViewNotificationPolicySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewNotificationPolicySummaryQuery, ViewNotificationPolicySummaryQueryVariables>(ViewNotificationPolicySummaryDocument, baseOptions);
        }
export type ViewNotificationPolicySummaryQueryHookResult = ReturnType<typeof useViewNotificationPolicySummaryQuery>;
export type ViewNotificationPolicySummaryLazyQueryHookResult = ReturnType<typeof useViewNotificationPolicySummaryLazyQuery>;
export type ViewNotificationPolicySummaryQueryResult = ApolloReactCommon.QueryResult<ViewNotificationPolicySummaryQuery, ViewNotificationPolicySummaryQueryVariables>;
export const ReportTemplateInsertDocument = gql`
    mutation ReportTemplateInsert($DocumentsInput: DocumentsInput!, $input: ReportTemplateInput!) {
  ReportTemplateInsert(DocumentsInput: $DocumentsInput, input: $input)
}
    `;
export type ReportTemplateInsertMutationFn = ApolloReactCommon.MutationFunction<ReportTemplateInsertMutation, ReportTemplateInsertMutationVariables>;

/**
 * __useReportTemplateInsertMutation__
 *
 * To run a mutation, you first call `useReportTemplateInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportTemplateInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportTemplateInsertMutation, { data, loading, error }] = useReportTemplateInsertMutation({
 *   variables: {
 *      DocumentsInput: // value for 'DocumentsInput'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTemplateInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportTemplateInsertMutation, ReportTemplateInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportTemplateInsertMutation, ReportTemplateInsertMutationVariables>(ReportTemplateInsertDocument, baseOptions);
      }
export type ReportTemplateInsertMutationHookResult = ReturnType<typeof useReportTemplateInsertMutation>;
export type ReportTemplateInsertMutationResult = ApolloReactCommon.MutationResult<ReportTemplateInsertMutation>;
export type ReportTemplateInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportTemplateInsertMutation, ReportTemplateInsertMutationVariables>;
export const ReportTemplateUpdateDocument = gql`
    mutation ReportTemplateUpdate($DocumentID: String!, $DocumentsInput: DocumentsInput!, $input: ReportTemplateInput!) {
  ReportTemplateUpdate(input: $input, DocumentsInput: $DocumentsInput, DocumentID: $DocumentID)
}
    `;
export type ReportTemplateUpdateMutationFn = ApolloReactCommon.MutationFunction<ReportTemplateUpdateMutation, ReportTemplateUpdateMutationVariables>;

/**
 * __useReportTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useReportTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportTemplateUpdateMutation, { data, loading, error }] = useReportTemplateUpdateMutation({
 *   variables: {
 *      DocumentID: // value for 'DocumentID'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportTemplateUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportTemplateUpdateMutation, ReportTemplateUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportTemplateUpdateMutation, ReportTemplateUpdateMutationVariables>(ReportTemplateUpdateDocument, baseOptions);
      }
export type ReportTemplateUpdateMutationHookResult = ReturnType<typeof useReportTemplateUpdateMutation>;
export type ReportTemplateUpdateMutationResult = ApolloReactCommon.MutationResult<ReportTemplateUpdateMutation>;
export type ReportTemplateUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportTemplateUpdateMutation, ReportTemplateUpdateMutationVariables>;
export const ReportTemplateInactiveDocument = gql`
    mutation ReportTemplateInactive($ReportTemplateID: String!) {
  ReportTemplateInactive(ReportTemplateID: $ReportTemplateID)
}
    `;
export type ReportTemplateInactiveMutationFn = ApolloReactCommon.MutationFunction<ReportTemplateInactiveMutation, ReportTemplateInactiveMutationVariables>;

/**
 * __useReportTemplateInactiveMutation__
 *
 * To run a mutation, you first call `useReportTemplateInactiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportTemplateInactiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportTemplateInactiveMutation, { data, loading, error }] = useReportTemplateInactiveMutation({
 *   variables: {
 *      ReportTemplateID: // value for 'ReportTemplateID'
 *   },
 * });
 */
export function useReportTemplateInactiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportTemplateInactiveMutation, ReportTemplateInactiveMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportTemplateInactiveMutation, ReportTemplateInactiveMutationVariables>(ReportTemplateInactiveDocument, baseOptions);
      }
export type ReportTemplateInactiveMutationHookResult = ReturnType<typeof useReportTemplateInactiveMutation>;
export type ReportTemplateInactiveMutationResult = ApolloReactCommon.MutationResult<ReportTemplateInactiveMutation>;
export type ReportTemplateInactiveMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportTemplateInactiveMutation, ReportTemplateInactiveMutationVariables>;
export const ReportTemplateListingDocument = gql`
    query ReportTemplateListing($ReportTemplateType: String) {
  ReportTemplateListing(ReportTemplateType: $ReportTemplateType) {
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ReportTemplateID
    SubscriptionAccountID
    Name
    Content
    DocumentID
    IsDefault
    ReportTemplateType
    Documents {
      DocumentID
      DocumentType
      Description
      BucketFileName
      DocumentFile
    }
  }
}
    `;

/**
 * __useReportTemplateListingQuery__
 *
 * To run a query within a React component, call `useReportTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTemplateListingQuery({
 *   variables: {
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useReportTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportTemplateListingQuery, ReportTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportTemplateListingQuery, ReportTemplateListingQueryVariables>(ReportTemplateListingDocument, baseOptions);
      }
export function useReportTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportTemplateListingQuery, ReportTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportTemplateListingQuery, ReportTemplateListingQueryVariables>(ReportTemplateListingDocument, baseOptions);
        }
export type ReportTemplateListingQueryHookResult = ReturnType<typeof useReportTemplateListingQuery>;
export type ReportTemplateListingLazyQueryHookResult = ReturnType<typeof useReportTemplateListingLazyQuery>;
export type ReportTemplateListingQueryResult = ApolloReactCommon.QueryResult<ReportTemplateListingQuery, ReportTemplateListingQueryVariables>;
export const ELetterTempleteInfolinesDocument = gql`
    query ELetterTempleteInfolines {
  ELetterTempleteInfolines {
    ModifiedDT
    ReportTemplateType
  }
}
    `;

/**
 * __useELetterTempleteInfolinesQuery__
 *
 * To run a query within a React component, call `useELetterTempleteInfolinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useELetterTempleteInfolinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useELetterTempleteInfolinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useELetterTempleteInfolinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ELetterTempleteInfolinesQuery, ELetterTempleteInfolinesQueryVariables>) {
        return ApolloReactHooks.useQuery<ELetterTempleteInfolinesQuery, ELetterTempleteInfolinesQueryVariables>(ELetterTempleteInfolinesDocument, baseOptions);
      }
export function useELetterTempleteInfolinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ELetterTempleteInfolinesQuery, ELetterTempleteInfolinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ELetterTempleteInfolinesQuery, ELetterTempleteInfolinesQueryVariables>(ELetterTempleteInfolinesDocument, baseOptions);
        }
export type ELetterTempleteInfolinesQueryHookResult = ReturnType<typeof useELetterTempleteInfolinesQuery>;
export type ELetterTempleteInfolinesLazyQueryHookResult = ReturnType<typeof useELetterTempleteInfolinesLazyQuery>;
export type ELetterTempleteInfolinesQueryResult = ApolloReactCommon.QueryResult<ELetterTempleteInfolinesQuery, ELetterTempleteInfolinesQueryVariables>;
export const CommonSettingsInfoLinesDocument = gql`
    query CommonSettingsInfoLines {
  CommonSettingsInfoLines {
    JobGradeTotal
    SkillSetTotal
    JobPositionTotal
    InsSchemeTotal
    RecSrcTotal
    UsrDefTotal
    ReportSettingsLastPostedDate
    DataChangeApprovalPolicyLastModifiedDate
    PersonnelEFileSecurityPolicyLastModifiedDate
  }
}
    `;

/**
 * __useCommonSettingsInfoLinesQuery__
 *
 * To run a query within a React component, call `useCommonSettingsInfoLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonSettingsInfoLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonSettingsInfoLinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommonSettingsInfoLinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CommonSettingsInfoLinesQuery, CommonSettingsInfoLinesQueryVariables>) {
        return ApolloReactHooks.useQuery<CommonSettingsInfoLinesQuery, CommonSettingsInfoLinesQueryVariables>(CommonSettingsInfoLinesDocument, baseOptions);
      }
export function useCommonSettingsInfoLinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommonSettingsInfoLinesQuery, CommonSettingsInfoLinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CommonSettingsInfoLinesQuery, CommonSettingsInfoLinesQueryVariables>(CommonSettingsInfoLinesDocument, baseOptions);
        }
export type CommonSettingsInfoLinesQueryHookResult = ReturnType<typeof useCommonSettingsInfoLinesQuery>;
export type CommonSettingsInfoLinesLazyQueryHookResult = ReturnType<typeof useCommonSettingsInfoLinesLazyQuery>;
export type CommonSettingsInfoLinesQueryResult = ApolloReactCommon.QueryResult<CommonSettingsInfoLinesQuery, CommonSettingsInfoLinesQueryVariables>;
export const PersonnelParameterInsertDocument = gql`
    mutation PersonnelParameterInsert($input: PersonnelParameterInput!) {
  PersonnelParameterInsert(input: $input) {
    PersonnelParameterID
  }
}
    `;
export type PersonnelParameterInsertMutationFn = ApolloReactCommon.MutationFunction<PersonnelParameterInsertMutation, PersonnelParameterInsertMutationVariables>;

/**
 * __usePersonnelParameterInsertMutation__
 *
 * To run a mutation, you first call `usePersonnelParameterInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelParameterInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelParameterInsertMutation, { data, loading, error }] = usePersonnelParameterInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonnelParameterInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelParameterInsertMutation, PersonnelParameterInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelParameterInsertMutation, PersonnelParameterInsertMutationVariables>(PersonnelParameterInsertDocument, baseOptions);
      }
export type PersonnelParameterInsertMutationHookResult = ReturnType<typeof usePersonnelParameterInsertMutation>;
export type PersonnelParameterInsertMutationResult = ApolloReactCommon.MutationResult<PersonnelParameterInsertMutation>;
export type PersonnelParameterInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelParameterInsertMutation, PersonnelParameterInsertMutationVariables>;
export const PersonnelParameterUpdateDocument = gql`
    mutation PersonnelParameterUpdate($input: PersonnelParameterInput!) {
  PersonnelParameterUpdate(input: $input)
}
    `;
export type PersonnelParameterUpdateMutationFn = ApolloReactCommon.MutationFunction<PersonnelParameterUpdateMutation, PersonnelParameterUpdateMutationVariables>;

/**
 * __usePersonnelParameterUpdateMutation__
 *
 * To run a mutation, you first call `usePersonnelParameterUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelParameterUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelParameterUpdateMutation, { data, loading, error }] = usePersonnelParameterUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonnelParameterUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelParameterUpdateMutation, PersonnelParameterUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelParameterUpdateMutation, PersonnelParameterUpdateMutationVariables>(PersonnelParameterUpdateDocument, baseOptions);
      }
export type PersonnelParameterUpdateMutationHookResult = ReturnType<typeof usePersonnelParameterUpdateMutation>;
export type PersonnelParameterUpdateMutationResult = ApolloReactCommon.MutationResult<PersonnelParameterUpdateMutation>;
export type PersonnelParameterUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelParameterUpdateMutation, PersonnelParameterUpdateMutationVariables>;
export const PersonnelParameterDeleteDocument = gql`
    mutation PersonnelParameterDelete($PersonnelParameterID: String!) {
  PersonnelParameterDelete(PersonnelParameterID: $PersonnelParameterID)
}
    `;
export type PersonnelParameterDeleteMutationFn = ApolloReactCommon.MutationFunction<PersonnelParameterDeleteMutation, PersonnelParameterDeleteMutationVariables>;

/**
 * __usePersonnelParameterDeleteMutation__
 *
 * To run a mutation, you first call `usePersonnelParameterDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelParameterDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelParameterDeleteMutation, { data, loading, error }] = usePersonnelParameterDeleteMutation({
 *   variables: {
 *      PersonnelParameterID: // value for 'PersonnelParameterID'
 *   },
 * });
 */
export function usePersonnelParameterDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelParameterDeleteMutation, PersonnelParameterDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelParameterDeleteMutation, PersonnelParameterDeleteMutationVariables>(PersonnelParameterDeleteDocument, baseOptions);
      }
export type PersonnelParameterDeleteMutationHookResult = ReturnType<typeof usePersonnelParameterDeleteMutation>;
export type PersonnelParameterDeleteMutationResult = ApolloReactCommon.MutationResult<PersonnelParameterDeleteMutation>;
export type PersonnelParameterDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelParameterDeleteMutation, PersonnelParameterDeleteMutationVariables>;
export const JobGradeListingDocument = gql`
    query JobGradeListing($SubscriptionAccountID: String!) {
  JobGradeListing(SubscriptionAccountID: $SubscriptionAccountID) {
    JobGradeID
    SubscriptionAccountID
    Description
    LeaveBenefitID
    ClaimBenefitID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    JobDesignationGrades {
      JobDesignationGradeID
    }
  }
}
    `;

/**
 * __useJobGradeListingQuery__
 *
 * To run a query within a React component, call `useJobGradeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobGradeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobGradeListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useJobGradeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobGradeListingQuery, JobGradeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobGradeListingQuery, JobGradeListingQueryVariables>(JobGradeListingDocument, baseOptions);
      }
export function useJobGradeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobGradeListingQuery, JobGradeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobGradeListingQuery, JobGradeListingQueryVariables>(JobGradeListingDocument, baseOptions);
        }
export type JobGradeListingQueryHookResult = ReturnType<typeof useJobGradeListingQuery>;
export type JobGradeListingLazyQueryHookResult = ReturnType<typeof useJobGradeListingLazyQuery>;
export type JobGradeListingQueryResult = ApolloReactCommon.QueryResult<JobGradeListingQuery, JobGradeListingQueryVariables>;
export const JobGradeBulkInsertDocument = gql`
    mutation JobGradeBulkInsert($input: [JobGradeBulkInsertInput!]!) {
  JobGradeBulkInsert(input: $input)
}
    `;
export type JobGradeBulkInsertMutationFn = ApolloReactCommon.MutationFunction<JobGradeBulkInsertMutation, JobGradeBulkInsertMutationVariables>;

/**
 * __useJobGradeBulkInsertMutation__
 *
 * To run a mutation, you first call `useJobGradeBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobGradeBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobGradeBulkInsertMutation, { data, loading, error }] = useJobGradeBulkInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobGradeBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobGradeBulkInsertMutation, JobGradeBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<JobGradeBulkInsertMutation, JobGradeBulkInsertMutationVariables>(JobGradeBulkInsertDocument, baseOptions);
      }
export type JobGradeBulkInsertMutationHookResult = ReturnType<typeof useJobGradeBulkInsertMutation>;
export type JobGradeBulkInsertMutationResult = ApolloReactCommon.MutationResult<JobGradeBulkInsertMutation>;
export type JobGradeBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<JobGradeBulkInsertMutation, JobGradeBulkInsertMutationVariables>;
export const JobGradeDeleteDocument = gql`
    mutation JobGradeDelete($JobGradeID: String!) {
  JobGradeDelete(JobGradeID: $JobGradeID)
}
    `;
export type JobGradeDeleteMutationFn = ApolloReactCommon.MutationFunction<JobGradeDeleteMutation, JobGradeDeleteMutationVariables>;

/**
 * __useJobGradeDeleteMutation__
 *
 * To run a mutation, you first call `useJobGradeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobGradeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobGradeDeleteMutation, { data, loading, error }] = useJobGradeDeleteMutation({
 *   variables: {
 *      JobGradeID: // value for 'JobGradeID'
 *   },
 * });
 */
export function useJobGradeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobGradeDeleteMutation, JobGradeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<JobGradeDeleteMutation, JobGradeDeleteMutationVariables>(JobGradeDeleteDocument, baseOptions);
      }
export type JobGradeDeleteMutationHookResult = ReturnType<typeof useJobGradeDeleteMutation>;
export type JobGradeDeleteMutationResult = ApolloReactCommon.MutationResult<JobGradeDeleteMutation>;
export type JobGradeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<JobGradeDeleteMutation, JobGradeDeleteMutationVariables>;
export const GetSkillSetDocument = gql`
    query GetSkillSet($SubscriptionAccountID: String, $SkillSetID: String, $Name: String) {
  GetSkillSet(SubscriptionAccountID: $SubscriptionAccountID, SkillSetID: $SkillSetID, Name: $Name) {
    Status
    UserLogs {
      createdDT
      createdBy
      modifiedDT
      modifiedBy
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    SkillSetID
    SubscriptionAccountID
    Name
    SkillSetDetails {
      Status
      UserLogs {
        createdDT
        createdBy
        modifiedDT
        modifiedBy
      }
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      SkillSetDetailID
      Name
    }
  }
}
    `;

/**
 * __useGetSkillSetQuery__
 *
 * To run a query within a React component, call `useGetSkillSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkillSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkillSetQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      SkillSetID: // value for 'SkillSetID'
 *      Name: // value for 'Name'
 *   },
 * });
 */
export function useGetSkillSetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSkillSetQuery, GetSkillSetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSkillSetQuery, GetSkillSetQueryVariables>(GetSkillSetDocument, baseOptions);
      }
export function useGetSkillSetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSkillSetQuery, GetSkillSetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSkillSetQuery, GetSkillSetQueryVariables>(GetSkillSetDocument, baseOptions);
        }
export type GetSkillSetQueryHookResult = ReturnType<typeof useGetSkillSetQuery>;
export type GetSkillSetLazyQueryHookResult = ReturnType<typeof useGetSkillSetLazyQuery>;
export type GetSkillSetQueryResult = ApolloReactCommon.QueryResult<GetSkillSetQuery, GetSkillSetQueryVariables>;
export const DeleteSkillSetDocument = gql`
    mutation DeleteSkillSet($SkillSetID: String!) {
  DeleteSkillSet(SkillSetID: $SkillSetID)
}
    `;
export type DeleteSkillSetMutationFn = ApolloReactCommon.MutationFunction<DeleteSkillSetMutation, DeleteSkillSetMutationVariables>;

/**
 * __useDeleteSkillSetMutation__
 *
 * To run a mutation, you first call `useDeleteSkillSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkillSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkillSetMutation, { data, loading, error }] = useDeleteSkillSetMutation({
 *   variables: {
 *      SkillSetID: // value for 'SkillSetID'
 *   },
 * });
 */
export function useDeleteSkillSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSkillSetMutation, DeleteSkillSetMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSkillSetMutation, DeleteSkillSetMutationVariables>(DeleteSkillSetDocument, baseOptions);
      }
export type DeleteSkillSetMutationHookResult = ReturnType<typeof useDeleteSkillSetMutation>;
export type DeleteSkillSetMutationResult = ApolloReactCommon.MutationResult<DeleteSkillSetMutation>;
export type DeleteSkillSetMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSkillSetMutation, DeleteSkillSetMutationVariables>;
export const CreateSkillSetDocument = gql`
    mutation CreateSkillSet($SkillSetInput: SkillSetInput!, $SkillSetDetailInput: [SkillSetDetailInput!]!) {
  CreateSkillSet(SkillSetInput: $SkillSetInput, SkillSetDetailInput: $SkillSetDetailInput)
}
    `;
export type CreateSkillSetMutationFn = ApolloReactCommon.MutationFunction<CreateSkillSetMutation, CreateSkillSetMutationVariables>;

/**
 * __useCreateSkillSetMutation__
 *
 * To run a mutation, you first call `useCreateSkillSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkillSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkillSetMutation, { data, loading, error }] = useCreateSkillSetMutation({
 *   variables: {
 *      SkillSetInput: // value for 'SkillSetInput'
 *      SkillSetDetailInput: // value for 'SkillSetDetailInput'
 *   },
 * });
 */
export function useCreateSkillSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSkillSetMutation, CreateSkillSetMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSkillSetMutation, CreateSkillSetMutationVariables>(CreateSkillSetDocument, baseOptions);
      }
export type CreateSkillSetMutationHookResult = ReturnType<typeof useCreateSkillSetMutation>;
export type CreateSkillSetMutationResult = ApolloReactCommon.MutationResult<CreateSkillSetMutation>;
export type CreateSkillSetMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSkillSetMutation, CreateSkillSetMutationVariables>;
export const GetSkillSetDetailDocument = gql`
    query GetSkillSetDetail($SkillSetDetailID: String, $Name: String, $SkillSetID: String) {
  GetSkillSetDetail(SkillSetDetailID: $SkillSetDetailID, Name: $Name, SkillSetID: $SkillSetID) {
    Status
    UserLogs {
      createdDT
      createdBy
      modifiedDT
      modifiedBy
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    SkillSetDetailID
    Name
    SkillSet {
      Name
      SkillSetID
    }
    SkillSetID
  }
}
    `;

/**
 * __useGetSkillSetDetailQuery__
 *
 * To run a query within a React component, call `useGetSkillSetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkillSetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkillSetDetailQuery({
 *   variables: {
 *      SkillSetDetailID: // value for 'SkillSetDetailID'
 *      Name: // value for 'Name'
 *      SkillSetID: // value for 'SkillSetID'
 *   },
 * });
 */
export function useGetSkillSetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSkillSetDetailQuery, GetSkillSetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSkillSetDetailQuery, GetSkillSetDetailQueryVariables>(GetSkillSetDetailDocument, baseOptions);
      }
export function useGetSkillSetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSkillSetDetailQuery, GetSkillSetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSkillSetDetailQuery, GetSkillSetDetailQueryVariables>(GetSkillSetDetailDocument, baseOptions);
        }
export type GetSkillSetDetailQueryHookResult = ReturnType<typeof useGetSkillSetDetailQuery>;
export type GetSkillSetDetailLazyQueryHookResult = ReturnType<typeof useGetSkillSetDetailLazyQuery>;
export type GetSkillSetDetailQueryResult = ApolloReactCommon.QueryResult<GetSkillSetDetailQuery, GetSkillSetDetailQueryVariables>;
export const UpdateSkillSetDocument = gql`
    mutation UpdateSkillSet($SkillSetID: String!, $SkillSetInput: SkillSetInput!, $SkillSetDetailInput: [SkillSetDetailInput!]!) {
  UpdateSkillSet(SkillSetID: $SkillSetID, SkillSetInput: $SkillSetInput, SkillSetDetailInput: $SkillSetDetailInput)
}
    `;
export type UpdateSkillSetMutationFn = ApolloReactCommon.MutationFunction<UpdateSkillSetMutation, UpdateSkillSetMutationVariables>;

/**
 * __useUpdateSkillSetMutation__
 *
 * To run a mutation, you first call `useUpdateSkillSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkillSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkillSetMutation, { data, loading, error }] = useUpdateSkillSetMutation({
 *   variables: {
 *      SkillSetID: // value for 'SkillSetID'
 *      SkillSetInput: // value for 'SkillSetInput'
 *      SkillSetDetailInput: // value for 'SkillSetDetailInput'
 *   },
 * });
 */
export function useUpdateSkillSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSkillSetMutation, UpdateSkillSetMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSkillSetMutation, UpdateSkillSetMutationVariables>(UpdateSkillSetDocument, baseOptions);
      }
export type UpdateSkillSetMutationHookResult = ReturnType<typeof useUpdateSkillSetMutation>;
export type UpdateSkillSetMutationResult = ApolloReactCommon.MutationResult<UpdateSkillSetMutation>;
export type UpdateSkillSetMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSkillSetMutation, UpdateSkillSetMutationVariables>;
export const JobPositionDeleteDocument = gql`
    mutation JobPositionDelete($JobDesignationID: String!) {
  JobPositionDelete(JobDesignationID: $JobDesignationID)
}
    `;
export type JobPositionDeleteMutationFn = ApolloReactCommon.MutationFunction<JobPositionDeleteMutation, JobPositionDeleteMutationVariables>;

/**
 * __useJobPositionDeleteMutation__
 *
 * To run a mutation, you first call `useJobPositionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPositionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPositionDeleteMutation, { data, loading, error }] = useJobPositionDeleteMutation({
 *   variables: {
 *      JobDesignationID: // value for 'JobDesignationID'
 *   },
 * });
 */
export function useJobPositionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPositionDeleteMutation, JobPositionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPositionDeleteMutation, JobPositionDeleteMutationVariables>(JobPositionDeleteDocument, baseOptions);
      }
export type JobPositionDeleteMutationHookResult = ReturnType<typeof useJobPositionDeleteMutation>;
export type JobPositionDeleteMutationResult = ApolloReactCommon.MutationResult<JobPositionDeleteMutation>;
export type JobPositionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPositionDeleteMutation, JobPositionDeleteMutationVariables>;
export const JobPositionListingDocument = gql`
    query JobPositionListing($JobDesignationID: String) {
  JobPositionListing(JobDesignationID: $JobDesignationID) {
    JobDesignationID
    Name
    Description
    IsInUse
    Document {
      DocumentID
      Description
      DocumentFile
      BucketFileName
    }
    Sequence
    JobDesignationGrades {
      JobDesignationGradeID
      JobDesignationID
      JobGradeID
      JobGrade {
        Description
      }
    }
    JobDesignationSkillSets {
      JobDesignationSkillSetID
      SkillSetID
      SkillSetDetailID
      SkillSet {
        Name
      }
      SkillSetDetail {
        Name
      }
    }
  }
}
    `;

/**
 * __useJobPositionListingQuery__
 *
 * To run a query within a React component, call `useJobPositionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPositionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPositionListingQuery({
 *   variables: {
 *      JobDesignationID: // value for 'JobDesignationID'
 *   },
 * });
 */
export function useJobPositionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPositionListingQuery, JobPositionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPositionListingQuery, JobPositionListingQueryVariables>(JobPositionListingDocument, baseOptions);
      }
export function useJobPositionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPositionListingQuery, JobPositionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPositionListingQuery, JobPositionListingQueryVariables>(JobPositionListingDocument, baseOptions);
        }
export type JobPositionListingQueryHookResult = ReturnType<typeof useJobPositionListingQuery>;
export type JobPositionListingLazyQueryHookResult = ReturnType<typeof useJobPositionListingLazyQuery>;
export type JobPositionListingQueryResult = ApolloReactCommon.QueryResult<JobPositionListingQuery, JobPositionListingQueryVariables>;
export const JobDesignationGradeListingDocument = gql`
    query JobDesignationGradeListing($JobDesignationID: String!) {
  JobDesignationGradeListing(JobDesignationID: $JobDesignationID) {
    JobDesignationGradeID
    JobDesignationID
    JobGradeID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useJobDesignationGradeListingQuery__
 *
 * To run a query within a React component, call `useJobDesignationGradeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDesignationGradeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDesignationGradeListingQuery({
 *   variables: {
 *      JobDesignationID: // value for 'JobDesignationID'
 *   },
 * });
 */
export function useJobDesignationGradeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobDesignationGradeListingQuery, JobDesignationGradeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobDesignationGradeListingQuery, JobDesignationGradeListingQueryVariables>(JobDesignationGradeListingDocument, baseOptions);
      }
export function useJobDesignationGradeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobDesignationGradeListingQuery, JobDesignationGradeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobDesignationGradeListingQuery, JobDesignationGradeListingQueryVariables>(JobDesignationGradeListingDocument, baseOptions);
        }
export type JobDesignationGradeListingQueryHookResult = ReturnType<typeof useJobDesignationGradeListingQuery>;
export type JobDesignationGradeListingLazyQueryHookResult = ReturnType<typeof useJobDesignationGradeListingLazyQuery>;
export type JobDesignationGradeListingQueryResult = ApolloReactCommon.QueryResult<JobDesignationGradeListingQuery, JobDesignationGradeListingQueryVariables>;
export const JobPositionInsertDocument = gql`
    mutation JobPositionInsert($DesignationInput: JobDesignationInput!, $DesignationGradeInput: [JobDesignationGradeInput!]!, $DesignationSkillInput: [JobDesignationSkillSetInput!]!) {
  JobPositionInsert(DesignationInput: $DesignationInput, DesignationGradeInput: $DesignationGradeInput, DesignationSkillInput: $DesignationSkillInput)
}
    `;
export type JobPositionInsertMutationFn = ApolloReactCommon.MutationFunction<JobPositionInsertMutation, JobPositionInsertMutationVariables>;

/**
 * __useJobPositionInsertMutation__
 *
 * To run a mutation, you first call `useJobPositionInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPositionInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPositionInsertMutation, { data, loading, error }] = useJobPositionInsertMutation({
 *   variables: {
 *      DesignationInput: // value for 'DesignationInput'
 *      DesignationGradeInput: // value for 'DesignationGradeInput'
 *      DesignationSkillInput: // value for 'DesignationSkillInput'
 *   },
 * });
 */
export function useJobPositionInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPositionInsertMutation, JobPositionInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPositionInsertMutation, JobPositionInsertMutationVariables>(JobPositionInsertDocument, baseOptions);
      }
export type JobPositionInsertMutationHookResult = ReturnType<typeof useJobPositionInsertMutation>;
export type JobPositionInsertMutationResult = ApolloReactCommon.MutationResult<JobPositionInsertMutation>;
export type JobPositionInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPositionInsertMutation, JobPositionInsertMutationVariables>;
export const JobDesignationSequenceUpdateDocument = gql`
    mutation JobDesignationSequenceUpdate($JobDesignationIDs: [String!]!) {
  JobDesignationSequenceUpdate(JobDesignationIDs: $JobDesignationIDs)
}
    `;
export type JobDesignationSequenceUpdateMutationFn = ApolloReactCommon.MutationFunction<JobDesignationSequenceUpdateMutation, JobDesignationSequenceUpdateMutationVariables>;

/**
 * __useJobDesignationSequenceUpdateMutation__
 *
 * To run a mutation, you first call `useJobDesignationSequenceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobDesignationSequenceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobDesignationSequenceUpdateMutation, { data, loading, error }] = useJobDesignationSequenceUpdateMutation({
 *   variables: {
 *      JobDesignationIDs: // value for 'JobDesignationIDs'
 *   },
 * });
 */
export function useJobDesignationSequenceUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobDesignationSequenceUpdateMutation, JobDesignationSequenceUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<JobDesignationSequenceUpdateMutation, JobDesignationSequenceUpdateMutationVariables>(JobDesignationSequenceUpdateDocument, baseOptions);
      }
export type JobDesignationSequenceUpdateMutationHookResult = ReturnType<typeof useJobDesignationSequenceUpdateMutation>;
export type JobDesignationSequenceUpdateMutationResult = ApolloReactCommon.MutationResult<JobDesignationSequenceUpdateMutation>;
export type JobDesignationSequenceUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<JobDesignationSequenceUpdateMutation, JobDesignationSequenceUpdateMutationVariables>;
export const JobPositionUpdateDocument = gql`
    mutation JobPositionUpdate($DesignationInput: JobDesignationInput!, $DesignationGradeInput: [JobDesignationGradeInput!]!, $DesignationSkillInput: [JobDesignationSkillSetInput!]!) {
  JobPositionUpdate(DesignationInput: $DesignationInput, DesignationGradeInput: $DesignationGradeInput, DesignationSkillInput: $DesignationSkillInput)
}
    `;
export type JobPositionUpdateMutationFn = ApolloReactCommon.MutationFunction<JobPositionUpdateMutation, JobPositionUpdateMutationVariables>;

/**
 * __useJobPositionUpdateMutation__
 *
 * To run a mutation, you first call `useJobPositionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPositionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPositionUpdateMutation, { data, loading, error }] = useJobPositionUpdateMutation({
 *   variables: {
 *      DesignationInput: // value for 'DesignationInput'
 *      DesignationGradeInput: // value for 'DesignationGradeInput'
 *      DesignationSkillInput: // value for 'DesignationSkillInput'
 *   },
 * });
 */
export function useJobPositionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<JobPositionUpdateMutation, JobPositionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<JobPositionUpdateMutation, JobPositionUpdateMutationVariables>(JobPositionUpdateDocument, baseOptions);
      }
export type JobPositionUpdateMutationHookResult = ReturnType<typeof useJobPositionUpdateMutation>;
export type JobPositionUpdateMutationResult = ApolloReactCommon.MutationResult<JobPositionUpdateMutation>;
export type JobPositionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<JobPositionUpdateMutation, JobPositionUpdateMutationVariables>;
export const InsuranceSchemeListingDocument = gql`
    query InsuranceSchemeListing($InsuranceSchemeID: String) {
  InsuranceSchemeListing(InsuranceSchemeID: $InsuranceSchemeID) {
    Name
    InsuranceSchemeID
    SubscriptionAccountID
    ModifiedDT
    ModifiedBy
    CreatedBy
    CreatedDT
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    DocumentID
    EmployeeInsuranceSchemes {
      Status
      EmployeeID
    }
  }
}
    `;

/**
 * __useInsuranceSchemeListingQuery__
 *
 * To run a query within a React component, call `useInsuranceSchemeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceSchemeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceSchemeListingQuery({
 *   variables: {
 *      InsuranceSchemeID: // value for 'InsuranceSchemeID'
 *   },
 * });
 */
export function useInsuranceSchemeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InsuranceSchemeListingQuery, InsuranceSchemeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<InsuranceSchemeListingQuery, InsuranceSchemeListingQueryVariables>(InsuranceSchemeListingDocument, baseOptions);
      }
export function useInsuranceSchemeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InsuranceSchemeListingQuery, InsuranceSchemeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InsuranceSchemeListingQuery, InsuranceSchemeListingQueryVariables>(InsuranceSchemeListingDocument, baseOptions);
        }
export type InsuranceSchemeListingQueryHookResult = ReturnType<typeof useInsuranceSchemeListingQuery>;
export type InsuranceSchemeListingLazyQueryHookResult = ReturnType<typeof useInsuranceSchemeListingLazyQuery>;
export type InsuranceSchemeListingQueryResult = ApolloReactCommon.QueryResult<InsuranceSchemeListingQuery, InsuranceSchemeListingQueryVariables>;
export const InsuranceSchemeInsertDocument = gql`
    mutation InsuranceSchemeInsert($InsuranceSchemeInsertInput: InsuranceSchemeInsertInput!) {
  InsuranceSchemeInsert(InsuranceSchemeInsertInput: $InsuranceSchemeInsertInput)
}
    `;
export type InsuranceSchemeInsertMutationFn = ApolloReactCommon.MutationFunction<InsuranceSchemeInsertMutation, InsuranceSchemeInsertMutationVariables>;

/**
 * __useInsuranceSchemeInsertMutation__
 *
 * To run a mutation, you first call `useInsuranceSchemeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsuranceSchemeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insuranceSchemeInsertMutation, { data, loading, error }] = useInsuranceSchemeInsertMutation({
 *   variables: {
 *      InsuranceSchemeInsertInput: // value for 'InsuranceSchemeInsertInput'
 *   },
 * });
 */
export function useInsuranceSchemeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsuranceSchemeInsertMutation, InsuranceSchemeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<InsuranceSchemeInsertMutation, InsuranceSchemeInsertMutationVariables>(InsuranceSchemeInsertDocument, baseOptions);
      }
export type InsuranceSchemeInsertMutationHookResult = ReturnType<typeof useInsuranceSchemeInsertMutation>;
export type InsuranceSchemeInsertMutationResult = ApolloReactCommon.MutationResult<InsuranceSchemeInsertMutation>;
export type InsuranceSchemeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<InsuranceSchemeInsertMutation, InsuranceSchemeInsertMutationVariables>;
export const InsuranceSchemeUpdateDocument = gql`
    mutation InsuranceSchemeUpdate($InsuranceSchemeInsertInput: InsuranceSchemeInsertInput!, $InsuranceSchemeID: String!) {
  InsuranceSchemeUpdate(InsuranceSchemeInsertInput: $InsuranceSchemeInsertInput, InsuranceSchemeID: $InsuranceSchemeID)
}
    `;
export type InsuranceSchemeUpdateMutationFn = ApolloReactCommon.MutationFunction<InsuranceSchemeUpdateMutation, InsuranceSchemeUpdateMutationVariables>;

/**
 * __useInsuranceSchemeUpdateMutation__
 *
 * To run a mutation, you first call `useInsuranceSchemeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsuranceSchemeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insuranceSchemeUpdateMutation, { data, loading, error }] = useInsuranceSchemeUpdateMutation({
 *   variables: {
 *      InsuranceSchemeInsertInput: // value for 'InsuranceSchemeInsertInput'
 *      InsuranceSchemeID: // value for 'InsuranceSchemeID'
 *   },
 * });
 */
export function useInsuranceSchemeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsuranceSchemeUpdateMutation, InsuranceSchemeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<InsuranceSchemeUpdateMutation, InsuranceSchemeUpdateMutationVariables>(InsuranceSchemeUpdateDocument, baseOptions);
      }
export type InsuranceSchemeUpdateMutationHookResult = ReturnType<typeof useInsuranceSchemeUpdateMutation>;
export type InsuranceSchemeUpdateMutationResult = ApolloReactCommon.MutationResult<InsuranceSchemeUpdateMutation>;
export type InsuranceSchemeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<InsuranceSchemeUpdateMutation, InsuranceSchemeUpdateMutationVariables>;
export const InsuranceSchemeDeleteDocument = gql`
    mutation InsuranceSchemeDelete($InsuranceSchemeID: String!) {
  InsuranceSchemeDelete(InsuranceSchemeID: $InsuranceSchemeID)
}
    `;
export type InsuranceSchemeDeleteMutationFn = ApolloReactCommon.MutationFunction<InsuranceSchemeDeleteMutation, InsuranceSchemeDeleteMutationVariables>;

/**
 * __useInsuranceSchemeDeleteMutation__
 *
 * To run a mutation, you first call `useInsuranceSchemeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsuranceSchemeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insuranceSchemeDeleteMutation, { data, loading, error }] = useInsuranceSchemeDeleteMutation({
 *   variables: {
 *      InsuranceSchemeID: // value for 'InsuranceSchemeID'
 *   },
 * });
 */
export function useInsuranceSchemeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsuranceSchemeDeleteMutation, InsuranceSchemeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<InsuranceSchemeDeleteMutation, InsuranceSchemeDeleteMutationVariables>(InsuranceSchemeDeleteDocument, baseOptions);
      }
export type InsuranceSchemeDeleteMutationHookResult = ReturnType<typeof useInsuranceSchemeDeleteMutation>;
export type InsuranceSchemeDeleteMutationResult = ApolloReactCommon.MutationResult<InsuranceSchemeDeleteMutation>;
export type InsuranceSchemeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<InsuranceSchemeDeleteMutation, InsuranceSchemeDeleteMutationVariables>;
export const GetInsuranceSchemeDocument = gql`
    query GetInsuranceScheme($SubscriptionAccountID: String, $InsuranceSchemeID: String) {
  GetInsuranceScheme(SubscriptionAccountID: $SubscriptionAccountID, InsuranceSchemeID: $InsuranceSchemeID) {
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    InsuranceSchemeID
    SubscriptionAccountID
    Name
    EmployeeInsuranceSchemes {
      InsuranceSchemeID
      EmployeeID
      EmployeeInsuranceSchemeID
    }
  }
}
    `;

/**
 * __useGetInsuranceSchemeQuery__
 *
 * To run a query within a React component, call `useGetInsuranceSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsuranceSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsuranceSchemeQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      InsuranceSchemeID: // value for 'InsuranceSchemeID'
 *   },
 * });
 */
export function useGetInsuranceSchemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInsuranceSchemeQuery, GetInsuranceSchemeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInsuranceSchemeQuery, GetInsuranceSchemeQueryVariables>(GetInsuranceSchemeDocument, baseOptions);
      }
export function useGetInsuranceSchemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInsuranceSchemeQuery, GetInsuranceSchemeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInsuranceSchemeQuery, GetInsuranceSchemeQueryVariables>(GetInsuranceSchemeDocument, baseOptions);
        }
export type GetInsuranceSchemeQueryHookResult = ReturnType<typeof useGetInsuranceSchemeQuery>;
export type GetInsuranceSchemeLazyQueryHookResult = ReturnType<typeof useGetInsuranceSchemeLazyQuery>;
export type GetInsuranceSchemeQueryResult = ApolloReactCommon.QueryResult<GetInsuranceSchemeQuery, GetInsuranceSchemeQueryVariables>;
export const RecruitmentSourceBulkInsertDocument = gql`
    mutation RecruitmentSourceBulkInsert($Input: [RecruitmentSourceBulkInput!]!) {
  RecruitmentSourceBulkInsert(Input: $Input)
}
    `;
export type RecruitmentSourceBulkInsertMutationFn = ApolloReactCommon.MutationFunction<RecruitmentSourceBulkInsertMutation, RecruitmentSourceBulkInsertMutationVariables>;

/**
 * __useRecruitmentSourceBulkInsertMutation__
 *
 * To run a mutation, you first call `useRecruitmentSourceBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecruitmentSourceBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recruitmentSourceBulkInsertMutation, { data, loading, error }] = useRecruitmentSourceBulkInsertMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useRecruitmentSourceBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecruitmentSourceBulkInsertMutation, RecruitmentSourceBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RecruitmentSourceBulkInsertMutation, RecruitmentSourceBulkInsertMutationVariables>(RecruitmentSourceBulkInsertDocument, baseOptions);
      }
export type RecruitmentSourceBulkInsertMutationHookResult = ReturnType<typeof useRecruitmentSourceBulkInsertMutation>;
export type RecruitmentSourceBulkInsertMutationResult = ApolloReactCommon.MutationResult<RecruitmentSourceBulkInsertMutation>;
export type RecruitmentSourceBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RecruitmentSourceBulkInsertMutation, RecruitmentSourceBulkInsertMutationVariables>;
export const RecruitmentSourceDeleteDocument = gql`
    mutation RecruitmentSourceDelete($RecruitmentSourceID: String!) {
  RecruitmentSourceDelete(RecruitmentSourceID: $RecruitmentSourceID)
}
    `;
export type RecruitmentSourceDeleteMutationFn = ApolloReactCommon.MutationFunction<RecruitmentSourceDeleteMutation, RecruitmentSourceDeleteMutationVariables>;

/**
 * __useRecruitmentSourceDeleteMutation__
 *
 * To run a mutation, you first call `useRecruitmentSourceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecruitmentSourceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recruitmentSourceDeleteMutation, { data, loading, error }] = useRecruitmentSourceDeleteMutation({
 *   variables: {
 *      RecruitmentSourceID: // value for 'RecruitmentSourceID'
 *   },
 * });
 */
export function useRecruitmentSourceDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecruitmentSourceDeleteMutation, RecruitmentSourceDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RecruitmentSourceDeleteMutation, RecruitmentSourceDeleteMutationVariables>(RecruitmentSourceDeleteDocument, baseOptions);
      }
export type RecruitmentSourceDeleteMutationHookResult = ReturnType<typeof useRecruitmentSourceDeleteMutation>;
export type RecruitmentSourceDeleteMutationResult = ApolloReactCommon.MutationResult<RecruitmentSourceDeleteMutation>;
export type RecruitmentSourceDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RecruitmentSourceDeleteMutation, RecruitmentSourceDeleteMutationVariables>;
export const RecruitmentSourceListingDocument = gql`
    query RecruitmentSourceListing($SubscriptionAccountID: String!) {
  RecruitmentSourceListing(SubscriptionAccountID: $SubscriptionAccountID) {
    RecruitmentSourceID
    SubscriptionAccountID
    Name
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    EmployeeInfo {
      Status
      EmployeeID
    }
  }
}
    `;

/**
 * __useRecruitmentSourceListingQuery__
 *
 * To run a query within a React component, call `useRecruitmentSourceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruitmentSourceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruitmentSourceListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useRecruitmentSourceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecruitmentSourceListingQuery, RecruitmentSourceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RecruitmentSourceListingQuery, RecruitmentSourceListingQueryVariables>(RecruitmentSourceListingDocument, baseOptions);
      }
export function useRecruitmentSourceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecruitmentSourceListingQuery, RecruitmentSourceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecruitmentSourceListingQuery, RecruitmentSourceListingQueryVariables>(RecruitmentSourceListingDocument, baseOptions);
        }
export type RecruitmentSourceListingQueryHookResult = ReturnType<typeof useRecruitmentSourceListingQuery>;
export type RecruitmentSourceListingLazyQueryHookResult = ReturnType<typeof useRecruitmentSourceListingLazyQuery>;
export type RecruitmentSourceListingQueryResult = ApolloReactCommon.QueryResult<RecruitmentSourceListingQuery, RecruitmentSourceListingQueryVariables>;
export const UserDefineBulkInsertDocument = gql`
    mutation UserDefineBulkInsert($input: [UserDefineBulkInput!]!) {
  UserDefineBulkInsert(input: $input)
}
    `;
export type UserDefineBulkInsertMutationFn = ApolloReactCommon.MutationFunction<UserDefineBulkInsertMutation, UserDefineBulkInsertMutationVariables>;

/**
 * __useUserDefineBulkInsertMutation__
 *
 * To run a mutation, you first call `useUserDefineBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDefineBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDefineBulkInsertMutation, { data, loading, error }] = useUserDefineBulkInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserDefineBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserDefineBulkInsertMutation, UserDefineBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<UserDefineBulkInsertMutation, UserDefineBulkInsertMutationVariables>(UserDefineBulkInsertDocument, baseOptions);
      }
export type UserDefineBulkInsertMutationHookResult = ReturnType<typeof useUserDefineBulkInsertMutation>;
export type UserDefineBulkInsertMutationResult = ApolloReactCommon.MutationResult<UserDefineBulkInsertMutation>;
export type UserDefineBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<UserDefineBulkInsertMutation, UserDefineBulkInsertMutationVariables>;
export const PersonnelTemplateLibraryAssignmentPageDataDocument = gql`
    query PersonnelTemplateLibraryAssignmentPageData($input: TemplateLibraryAssignmentInput, $skip: Float, $take: Float, $order: String) {
  PersonnelTemplateLibraryAssignmentListing {
    JobGrade {
      JobGradeID
      Description
      Status
    }
    SkillSet {
      Name
      SkillSetID
      Status
    }
    JobPosition {
      Name
      JobDesignationID
      Status
    }
    InsuranceScheme {
      Name
      InsuranceSchemeID
      Status
    }
    RecruitmentSource {
      Name
      RecruitmentSourceID
      Status
    }
    UserDefine {
      Name
      UserDefineID
      Status
    }
  }
  getTemplateLibraryAssignment(input: $input, skip: $skip, take: $take, order: $order) {
    TemplateLibraryAssignmentID
    SubscriptionAccountID
    CompanyID
    SourceID
    SourceType
    TargetID
    TargetType
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __usePersonnelTemplateLibraryAssignmentPageDataQuery__
 *
 * To run a query within a React component, call `usePersonnelTemplateLibraryAssignmentPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelTemplateLibraryAssignmentPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelTemplateLibraryAssignmentPageDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePersonnelTemplateLibraryAssignmentPageDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelTemplateLibraryAssignmentPageDataQuery, PersonnelTemplateLibraryAssignmentPageDataQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelTemplateLibraryAssignmentPageDataQuery, PersonnelTemplateLibraryAssignmentPageDataQueryVariables>(PersonnelTemplateLibraryAssignmentPageDataDocument, baseOptions);
      }
export function usePersonnelTemplateLibraryAssignmentPageDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelTemplateLibraryAssignmentPageDataQuery, PersonnelTemplateLibraryAssignmentPageDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelTemplateLibraryAssignmentPageDataQuery, PersonnelTemplateLibraryAssignmentPageDataQueryVariables>(PersonnelTemplateLibraryAssignmentPageDataDocument, baseOptions);
        }
export type PersonnelTemplateLibraryAssignmentPageDataQueryHookResult = ReturnType<typeof usePersonnelTemplateLibraryAssignmentPageDataQuery>;
export type PersonnelTemplateLibraryAssignmentPageDataLazyQueryHookResult = ReturnType<typeof usePersonnelTemplateLibraryAssignmentPageDataLazyQuery>;
export type PersonnelTemplateLibraryAssignmentPageDataQueryResult = ApolloReactCommon.QueryResult<PersonnelTemplateLibraryAssignmentPageDataQuery, PersonnelTemplateLibraryAssignmentPageDataQueryVariables>;
export const ReportTemplateAssignmentListingDocument = gql`
    query ReportTemplateAssignmentListing($CompanyID: String!, $ReportTemplateType: String) {
  ReportTemplateAssignmentListing(CompanyID: $CompanyID, ReportTemplateType: $ReportTemplateType)
}
    `;

/**
 * __useReportTemplateAssignmentListingQuery__
 *
 * To run a query within a React component, call `useReportTemplateAssignmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTemplateAssignmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTemplateAssignmentListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useReportTemplateAssignmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportTemplateAssignmentListingQuery, ReportTemplateAssignmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportTemplateAssignmentListingQuery, ReportTemplateAssignmentListingQueryVariables>(ReportTemplateAssignmentListingDocument, baseOptions);
      }
export function useReportTemplateAssignmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportTemplateAssignmentListingQuery, ReportTemplateAssignmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportTemplateAssignmentListingQuery, ReportTemplateAssignmentListingQueryVariables>(ReportTemplateAssignmentListingDocument, baseOptions);
        }
export type ReportTemplateAssignmentListingQueryHookResult = ReturnType<typeof useReportTemplateAssignmentListingQuery>;
export type ReportTemplateAssignmentListingLazyQueryHookResult = ReturnType<typeof useReportTemplateAssignmentListingLazyQuery>;
export type ReportTemplateAssignmentListingQueryResult = ApolloReactCommon.QueryResult<ReportTemplateAssignmentListingQuery, ReportTemplateAssignmentListingQueryVariables>;
export const ReplaceTemplateLibraryAssignmentDocument = gql`
    mutation replaceTemplateLibraryAssignment($inputs: [TemplateLibraryAssignmentInput!]!) {
  replaceTemplateLibraryAssignment(inputs: $inputs)
}
    `;
export type ReplaceTemplateLibraryAssignmentMutationFn = ApolloReactCommon.MutationFunction<ReplaceTemplateLibraryAssignmentMutation, ReplaceTemplateLibraryAssignmentMutationVariables>;

/**
 * __useReplaceTemplateLibraryAssignmentMutation__
 *
 * To run a mutation, you first call `useReplaceTemplateLibraryAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceTemplateLibraryAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceTemplateLibraryAssignmentMutation, { data, loading, error }] = useReplaceTemplateLibraryAssignmentMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useReplaceTemplateLibraryAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReplaceTemplateLibraryAssignmentMutation, ReplaceTemplateLibraryAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<ReplaceTemplateLibraryAssignmentMutation, ReplaceTemplateLibraryAssignmentMutationVariables>(ReplaceTemplateLibraryAssignmentDocument, baseOptions);
      }
export type ReplaceTemplateLibraryAssignmentMutationHookResult = ReturnType<typeof useReplaceTemplateLibraryAssignmentMutation>;
export type ReplaceTemplateLibraryAssignmentMutationResult = ApolloReactCommon.MutationResult<ReplaceTemplateLibraryAssignmentMutation>;
export type ReplaceTemplateLibraryAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<ReplaceTemplateLibraryAssignmentMutation, ReplaceTemplateLibraryAssignmentMutationVariables>;
export const ReportTemplateAssignmentDocument = gql`
    mutation ReportTemplateAssignment($input: ReportTemplateInput!, $CompanyID: String!, $DocumentsInput: DocumentsInput!) {
  ReportTemplateAssignment(input: $input, CompanyID: $CompanyID, DocumentsInput: $DocumentsInput)
}
    `;
export type ReportTemplateAssignmentMutationFn = ApolloReactCommon.MutationFunction<ReportTemplateAssignmentMutation, ReportTemplateAssignmentMutationVariables>;

/**
 * __useReportTemplateAssignmentMutation__
 *
 * To run a mutation, you first call `useReportTemplateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportTemplateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportTemplateAssignmentMutation, { data, loading, error }] = useReportTemplateAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      CompanyID: // value for 'CompanyID'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useReportTemplateAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportTemplateAssignmentMutation, ReportTemplateAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportTemplateAssignmentMutation, ReportTemplateAssignmentMutationVariables>(ReportTemplateAssignmentDocument, baseOptions);
      }
export type ReportTemplateAssignmentMutationHookResult = ReturnType<typeof useReportTemplateAssignmentMutation>;
export type ReportTemplateAssignmentMutationResult = ApolloReactCommon.MutationResult<ReportTemplateAssignmentMutation>;
export type ReportTemplateAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportTemplateAssignmentMutation, ReportTemplateAssignmentMutationVariables>;
export const PayrollCycleListingWithAuditDocument = gql`
    query PayrollCycleListingWithAudit {
  PayrollCycleListingWithAudit
}
    `;

/**
 * __usePayrollCycleListingWithAuditQuery__
 *
 * To run a query within a React component, call `usePayrollCycleListingWithAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCycleListingWithAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCycleListingWithAuditQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayrollCycleListingWithAuditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollCycleListingWithAuditQuery, PayrollCycleListingWithAuditQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollCycleListingWithAuditQuery, PayrollCycleListingWithAuditQueryVariables>(PayrollCycleListingWithAuditDocument, baseOptions);
      }
export function usePayrollCycleListingWithAuditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollCycleListingWithAuditQuery, PayrollCycleListingWithAuditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollCycleListingWithAuditQuery, PayrollCycleListingWithAuditQueryVariables>(PayrollCycleListingWithAuditDocument, baseOptions);
        }
export type PayrollCycleListingWithAuditQueryHookResult = ReturnType<typeof usePayrollCycleListingWithAuditQuery>;
export type PayrollCycleListingWithAuditLazyQueryHookResult = ReturnType<typeof usePayrollCycleListingWithAuditLazyQuery>;
export type PayrollCycleListingWithAuditQueryResult = ApolloReactCommon.QueryResult<PayrollCycleListingWithAuditQuery, PayrollCycleListingWithAuditQueryVariables>;
export const PayrollCycleListingV3Document = gql`
    query PayrollCycleListingV3($CompanyID: String, $UserID: String!) {
  PayrollCycleListingV3(CompanyID: $CompanyID, UserID: $UserID) {
    Status
    PayrollCycleID
    CompanyID
    Description
    IsDefault
    EPF
    EPFVE
    SOCSO
    MTD
    Zakat
    EIS
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    Company {
      CompanyName
      SubscriptionAccountID
    }
  }
}
    `;

/**
 * __usePayrollCycleListingV3Query__
 *
 * To run a query within a React component, call `usePayrollCycleListingV3Query` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCycleListingV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCycleListingV3Query({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      UserID: // value for 'UserID'
 *   },
 * });
 */
export function usePayrollCycleListingV3Query(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollCycleListingV3Query, PayrollCycleListingV3QueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollCycleListingV3Query, PayrollCycleListingV3QueryVariables>(PayrollCycleListingV3Document, baseOptions);
      }
export function usePayrollCycleListingV3LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollCycleListingV3Query, PayrollCycleListingV3QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollCycleListingV3Query, PayrollCycleListingV3QueryVariables>(PayrollCycleListingV3Document, baseOptions);
        }
export type PayrollCycleListingV3QueryHookResult = ReturnType<typeof usePayrollCycleListingV3Query>;
export type PayrollCycleListingV3LazyQueryHookResult = ReturnType<typeof usePayrollCycleListingV3LazyQuery>;
export type PayrollCycleListingV3QueryResult = ApolloReactCommon.QueryResult<PayrollCycleListingV3Query, PayrollCycleListingV3QueryVariables>;
export const PayItemListingDocument = gql`
    query PayItemListing($SubscriptionAccountID: String!) {
  PayItemListing(SubscriptionAccountID: $SubscriptionAccountID) {
    PayItemID
    CompanyID
    PayName
    PayItemType
    IsLHDN
    IsSystem
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    IsInUse
    UserLogs {
      createdDT
      createdBy
      modifiedDT
      modifiedBy
      columnName
      oldColumnValue
      newColumnValue
    }
  }
}
    `;

/**
 * __usePayItemListingQuery__
 *
 * To run a query within a React component, call `usePayItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayItemListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function usePayItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayItemListingQuery, PayItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PayItemListingQuery, PayItemListingQueryVariables>(PayItemListingDocument, baseOptions);
      }
export function usePayItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayItemListingQuery, PayItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayItemListingQuery, PayItemListingQueryVariables>(PayItemListingDocument, baseOptions);
        }
export type PayItemListingQueryHookResult = ReturnType<typeof usePayItemListingQuery>;
export type PayItemListingLazyQueryHookResult = ReturnType<typeof usePayItemListingLazyQuery>;
export type PayItemListingQueryResult = ApolloReactCommon.QueryResult<PayItemListingQuery, PayItemListingQueryVariables>;
export const PayItemDeleteDocument = gql`
    mutation PayItemDelete($PayItemID: String!) {
  PayItemDelete(PayItemID: $PayItemID)
}
    `;
export type PayItemDeleteMutationFn = ApolloReactCommon.MutationFunction<PayItemDeleteMutation, PayItemDeleteMutationVariables>;

/**
 * __usePayItemDeleteMutation__
 *
 * To run a mutation, you first call `usePayItemDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayItemDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payItemDeleteMutation, { data, loading, error }] = usePayItemDeleteMutation({
 *   variables: {
 *      PayItemID: // value for 'PayItemID'
 *   },
 * });
 */
export function usePayItemDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayItemDeleteMutation, PayItemDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PayItemDeleteMutation, PayItemDeleteMutationVariables>(PayItemDeleteDocument, baseOptions);
      }
export type PayItemDeleteMutationHookResult = ReturnType<typeof usePayItemDeleteMutation>;
export type PayItemDeleteMutationResult = ApolloReactCommon.MutationResult<PayItemDeleteMutation>;
export type PayItemDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PayItemDeleteMutation, PayItemDeleteMutationVariables>;
export const PayItemInsertDocument = gql`
    mutation PayItemInsert($Input: PayItemInput!, $Condition: PayItemConditionInput) {
  PayItemInsert(input: $Input, condition: $Condition)
}
    `;
export type PayItemInsertMutationFn = ApolloReactCommon.MutationFunction<PayItemInsertMutation, PayItemInsertMutationVariables>;

/**
 * __usePayItemInsertMutation__
 *
 * To run a mutation, you first call `usePayItemInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayItemInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payItemInsertMutation, { data, loading, error }] = usePayItemInsertMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *      Condition: // value for 'Condition'
 *   },
 * });
 */
export function usePayItemInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayItemInsertMutation, PayItemInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PayItemInsertMutation, PayItemInsertMutationVariables>(PayItemInsertDocument, baseOptions);
      }
export type PayItemInsertMutationHookResult = ReturnType<typeof usePayItemInsertMutation>;
export type PayItemInsertMutationResult = ApolloReactCommon.MutationResult<PayItemInsertMutation>;
export type PayItemInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PayItemInsertMutation, PayItemInsertMutationVariables>;
export const PayItemConditionListDocument = gql`
    query PayItemConditionList($SubscriptionAccountID: String!, $PayItemID: String!) {
  PayItemConditionList(SubscriptionAccountID: $SubscriptionAccountID, PayItemID: $PayItemID) {
    PayItemConditionID
    PayItemID
    ConditionList
    PayItemType
  }
}
    `;

/**
 * __usePayItemConditionListQuery__
 *
 * To run a query within a React component, call `usePayItemConditionListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayItemConditionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayItemConditionListQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      PayItemID: // value for 'PayItemID'
 *   },
 * });
 */
export function usePayItemConditionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayItemConditionListQuery, PayItemConditionListQueryVariables>) {
        return ApolloReactHooks.useQuery<PayItemConditionListQuery, PayItemConditionListQueryVariables>(PayItemConditionListDocument, baseOptions);
      }
export function usePayItemConditionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayItemConditionListQuery, PayItemConditionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayItemConditionListQuery, PayItemConditionListQueryVariables>(PayItemConditionListDocument, baseOptions);
        }
export type PayItemConditionListQueryHookResult = ReturnType<typeof usePayItemConditionListQuery>;
export type PayItemConditionListLazyQueryHookResult = ReturnType<typeof usePayItemConditionListLazyQuery>;
export type PayItemConditionListQueryResult = ApolloReactCommon.QueryResult<PayItemConditionListQuery, PayItemConditionListQueryVariables>;
export const PayItemDetailDocument = gql`
    query PayItemDetail($PayItemID: String!) {
  PayItemDetail(PayItemID: $PayItemID) {
    PayItemID
    EAPartFID
    PayName
    PayCode
    Formula
    PayItemType
    IsLHDN
    IsEPF
    IsEIS
    IsHRDF
    IsSOCSO
    IsEPFVE
    IsBonus
    IsSystem
    IsAddRemuneration
    EPFEmployer
    EPFEmployee
    CreatedDT
    CreatedBy
    ModifiedDT
    IsEPFNonMonthly
    ModifiedBy
    UserLogs {
      createdDT
      createdBy
      modifiedDT
      modifiedBy
      columnName
      oldColumnValue
      newColumnValue
    }
  }
}
    `;

/**
 * __usePayItemDetailQuery__
 *
 * To run a query within a React component, call `usePayItemDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayItemDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayItemDetailQuery({
 *   variables: {
 *      PayItemID: // value for 'PayItemID'
 *   },
 * });
 */
export function usePayItemDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayItemDetailQuery, PayItemDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<PayItemDetailQuery, PayItemDetailQueryVariables>(PayItemDetailDocument, baseOptions);
      }
export function usePayItemDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayItemDetailQuery, PayItemDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayItemDetailQuery, PayItemDetailQueryVariables>(PayItemDetailDocument, baseOptions);
        }
export type PayItemDetailQueryHookResult = ReturnType<typeof usePayItemDetailQuery>;
export type PayItemDetailLazyQueryHookResult = ReturnType<typeof usePayItemDetailLazyQuery>;
export type PayItemDetailQueryResult = ApolloReactCommon.QueryResult<PayItemDetailQuery, PayItemDetailQueryVariables>;
export const PayItemUpdateDocument = gql`
    mutation PayItemUpdate($PayItemID: String!, $Input: PayItemInput!, $Condition: PayItemConditionInput) {
  PayItemUpdate(PayItemID: $PayItemID, input: $Input, Condition: $Condition)
}
    `;
export type PayItemUpdateMutationFn = ApolloReactCommon.MutationFunction<PayItemUpdateMutation, PayItemUpdateMutationVariables>;

/**
 * __usePayItemUpdateMutation__
 *
 * To run a mutation, you first call `usePayItemUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayItemUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payItemUpdateMutation, { data, loading, error }] = usePayItemUpdateMutation({
 *   variables: {
 *      PayItemID: // value for 'PayItemID'
 *      Input: // value for 'Input'
 *      Condition: // value for 'Condition'
 *   },
 * });
 */
export function usePayItemUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayItemUpdateMutation, PayItemUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PayItemUpdateMutation, PayItemUpdateMutationVariables>(PayItemUpdateDocument, baseOptions);
      }
export type PayItemUpdateMutationHookResult = ReturnType<typeof usePayItemUpdateMutation>;
export type PayItemUpdateMutationResult = ApolloReactCommon.MutationResult<PayItemUpdateMutation>;
export type PayItemUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PayItemUpdateMutation, PayItemUpdateMutationVariables>;
export const PayItemConditionTemplateLibraryUpdateDocument = gql`
    mutation PayItemConditionTemplateLibraryUpdate($Condition: PayItemConditionInput) {
  PayItemConditionTemplateLibraryUpdate(Condition: $Condition)
}
    `;
export type PayItemConditionTemplateLibraryUpdateMutationFn = ApolloReactCommon.MutationFunction<PayItemConditionTemplateLibraryUpdateMutation, PayItemConditionTemplateLibraryUpdateMutationVariables>;

/**
 * __usePayItemConditionTemplateLibraryUpdateMutation__
 *
 * To run a mutation, you first call `usePayItemConditionTemplateLibraryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayItemConditionTemplateLibraryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payItemConditionTemplateLibraryUpdateMutation, { data, loading, error }] = usePayItemConditionTemplateLibraryUpdateMutation({
 *   variables: {
 *      Condition: // value for 'Condition'
 *   },
 * });
 */
export function usePayItemConditionTemplateLibraryUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayItemConditionTemplateLibraryUpdateMutation, PayItemConditionTemplateLibraryUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PayItemConditionTemplateLibraryUpdateMutation, PayItemConditionTemplateLibraryUpdateMutationVariables>(PayItemConditionTemplateLibraryUpdateDocument, baseOptions);
      }
export type PayItemConditionTemplateLibraryUpdateMutationHookResult = ReturnType<typeof usePayItemConditionTemplateLibraryUpdateMutation>;
export type PayItemConditionTemplateLibraryUpdateMutationResult = ApolloReactCommon.MutationResult<PayItemConditionTemplateLibraryUpdateMutation>;
export type PayItemConditionTemplateLibraryUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PayItemConditionTemplateLibraryUpdateMutation, PayItemConditionTemplateLibraryUpdateMutationVariables>;
export const TmsotConditionTemplateLibraryUpdateDocument = gql`
    mutation TmsotConditionTemplateLibraryUpdate($TMSOTConditionInput: [TMSOTConditionInput!]!, $CompanyID: String!) {
  TmsotConditionTemplateLibraryUpdate(TMSOTConditionInput: $TMSOTConditionInput, CompanyID: $CompanyID)
}
    `;
export type TmsotConditionTemplateLibraryUpdateMutationFn = ApolloReactCommon.MutationFunction<TmsotConditionTemplateLibraryUpdateMutation, TmsotConditionTemplateLibraryUpdateMutationVariables>;

/**
 * __useTmsotConditionTemplateLibraryUpdateMutation__
 *
 * To run a mutation, you first call `useTmsotConditionTemplateLibraryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTmsotConditionTemplateLibraryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tmsotConditionTemplateLibraryUpdateMutation, { data, loading, error }] = useTmsotConditionTemplateLibraryUpdateMutation({
 *   variables: {
 *      TMSOTConditionInput: // value for 'TMSOTConditionInput'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useTmsotConditionTemplateLibraryUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TmsotConditionTemplateLibraryUpdateMutation, TmsotConditionTemplateLibraryUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TmsotConditionTemplateLibraryUpdateMutation, TmsotConditionTemplateLibraryUpdateMutationVariables>(TmsotConditionTemplateLibraryUpdateDocument, baseOptions);
      }
export type TmsotConditionTemplateLibraryUpdateMutationHookResult = ReturnType<typeof useTmsotConditionTemplateLibraryUpdateMutation>;
export type TmsotConditionTemplateLibraryUpdateMutationResult = ApolloReactCommon.MutationResult<TmsotConditionTemplateLibraryUpdateMutation>;
export type TmsotConditionTemplateLibraryUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TmsotConditionTemplateLibraryUpdateMutation, TmsotConditionTemplateLibraryUpdateMutationVariables>;
export const DailyTypeConditionUpdateDocument = gql`
    mutation DailyTypeConditionUpdate($DailyTemplateInput: DailyTemplateInput!) {
  DailyTypeConditionUpdate(DailyTemplateInput: $DailyTemplateInput)
}
    `;
export type DailyTypeConditionUpdateMutationFn = ApolloReactCommon.MutationFunction<DailyTypeConditionUpdateMutation, DailyTypeConditionUpdateMutationVariables>;

/**
 * __useDailyTypeConditionUpdateMutation__
 *
 * To run a mutation, you first call `useDailyTypeConditionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDailyTypeConditionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dailyTypeConditionUpdateMutation, { data, loading, error }] = useDailyTypeConditionUpdateMutation({
 *   variables: {
 *      DailyTemplateInput: // value for 'DailyTemplateInput'
 *   },
 * });
 */
export function useDailyTypeConditionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DailyTypeConditionUpdateMutation, DailyTypeConditionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DailyTypeConditionUpdateMutation, DailyTypeConditionUpdateMutationVariables>(DailyTypeConditionUpdateDocument, baseOptions);
      }
export type DailyTypeConditionUpdateMutationHookResult = ReturnType<typeof useDailyTypeConditionUpdateMutation>;
export type DailyTypeConditionUpdateMutationResult = ApolloReactCommon.MutationResult<DailyTypeConditionUpdateMutation>;
export type DailyTypeConditionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DailyTypeConditionUpdateMutation, DailyTypeConditionUpdateMutationVariables>;
export const DailyTypeConditionDeleteDocument = gql`
    mutation DailyTypeConditionDelete($DailyTemplateID: String!) {
  DailyTypeConditionDelete(DailyTemplateID: $DailyTemplateID)
}
    `;
export type DailyTypeConditionDeleteMutationFn = ApolloReactCommon.MutationFunction<DailyTypeConditionDeleteMutation, DailyTypeConditionDeleteMutationVariables>;

/**
 * __useDailyTypeConditionDeleteMutation__
 *
 * To run a mutation, you first call `useDailyTypeConditionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDailyTypeConditionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dailyTypeConditionDeleteMutation, { data, loading, error }] = useDailyTypeConditionDeleteMutation({
 *   variables: {
 *      DailyTemplateID: // value for 'DailyTemplateID'
 *   },
 * });
 */
export function useDailyTypeConditionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DailyTypeConditionDeleteMutation, DailyTypeConditionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<DailyTypeConditionDeleteMutation, DailyTypeConditionDeleteMutationVariables>(DailyTypeConditionDeleteDocument, baseOptions);
      }
export type DailyTypeConditionDeleteMutationHookResult = ReturnType<typeof useDailyTypeConditionDeleteMutation>;
export type DailyTypeConditionDeleteMutationResult = ApolloReactCommon.MutationResult<DailyTypeConditionDeleteMutation>;
export type DailyTypeConditionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<DailyTypeConditionDeleteMutation, DailyTypeConditionDeleteMutationVariables>;
export const DailyTypeConditionListDocument = gql`
    query DailyTypeConditionList($CompanyID: String!) {
  DailyTypeConditionList(CompanyID: $CompanyID) {
    CompanyID
    DailyTemplateID
    ConditionList
    ClaimPolicyID
    ClaimPolicyIDList
    ClaimTypeID
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __useDailyTypeConditionListQuery__
 *
 * To run a query within a React component, call `useDailyTypeConditionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyTypeConditionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyTypeConditionListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDailyTypeConditionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DailyTypeConditionListQuery, DailyTypeConditionListQueryVariables>) {
        return ApolloReactHooks.useQuery<DailyTypeConditionListQuery, DailyTypeConditionListQueryVariables>(DailyTypeConditionListDocument, baseOptions);
      }
export function useDailyTypeConditionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DailyTypeConditionListQuery, DailyTypeConditionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DailyTypeConditionListQuery, DailyTypeConditionListQueryVariables>(DailyTypeConditionListDocument, baseOptions);
        }
export type DailyTypeConditionListQueryHookResult = ReturnType<typeof useDailyTypeConditionListQuery>;
export type DailyTypeConditionListLazyQueryHookResult = ReturnType<typeof useDailyTypeConditionListLazyQuery>;
export type DailyTypeConditionListQueryResult = ApolloReactCommon.QueryResult<DailyTypeConditionListQuery, DailyTypeConditionListQueryVariables>;
export const MonthlyTypeConditionUpdateDocument = gql`
    mutation MonthlyTypeConditionUpdate($MonthlyTemplateInput: MonthlyTemplateInput!) {
  MonthlyTypeConditionUpdate(MonthlyTemplateInput: $MonthlyTemplateInput)
}
    `;
export type MonthlyTypeConditionUpdateMutationFn = ApolloReactCommon.MutationFunction<MonthlyTypeConditionUpdateMutation, MonthlyTypeConditionUpdateMutationVariables>;

/**
 * __useMonthlyTypeConditionUpdateMutation__
 *
 * To run a mutation, you first call `useMonthlyTypeConditionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonthlyTypeConditionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monthlyTypeConditionUpdateMutation, { data, loading, error }] = useMonthlyTypeConditionUpdateMutation({
 *   variables: {
 *      MonthlyTemplateInput: // value for 'MonthlyTemplateInput'
 *   },
 * });
 */
export function useMonthlyTypeConditionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MonthlyTypeConditionUpdateMutation, MonthlyTypeConditionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<MonthlyTypeConditionUpdateMutation, MonthlyTypeConditionUpdateMutationVariables>(MonthlyTypeConditionUpdateDocument, baseOptions);
      }
export type MonthlyTypeConditionUpdateMutationHookResult = ReturnType<typeof useMonthlyTypeConditionUpdateMutation>;
export type MonthlyTypeConditionUpdateMutationResult = ApolloReactCommon.MutationResult<MonthlyTypeConditionUpdateMutation>;
export type MonthlyTypeConditionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<MonthlyTypeConditionUpdateMutation, MonthlyTypeConditionUpdateMutationVariables>;
export const MonthlyTypeConditionDeleteDocument = gql`
    mutation MonthlyTypeConditionDelete($MonthlyTemplateID: String!) {
  MonthlyTypeConditionDelete(MonthlyTemplateID: $MonthlyTemplateID)
}
    `;
export type MonthlyTypeConditionDeleteMutationFn = ApolloReactCommon.MutationFunction<MonthlyTypeConditionDeleteMutation, MonthlyTypeConditionDeleteMutationVariables>;

/**
 * __useMonthlyTypeConditionDeleteMutation__
 *
 * To run a mutation, you first call `useMonthlyTypeConditionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonthlyTypeConditionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monthlyTypeConditionDeleteMutation, { data, loading, error }] = useMonthlyTypeConditionDeleteMutation({
 *   variables: {
 *      MonthlyTemplateID: // value for 'MonthlyTemplateID'
 *   },
 * });
 */
export function useMonthlyTypeConditionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MonthlyTypeConditionDeleteMutation, MonthlyTypeConditionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<MonthlyTypeConditionDeleteMutation, MonthlyTypeConditionDeleteMutationVariables>(MonthlyTypeConditionDeleteDocument, baseOptions);
      }
export type MonthlyTypeConditionDeleteMutationHookResult = ReturnType<typeof useMonthlyTypeConditionDeleteMutation>;
export type MonthlyTypeConditionDeleteMutationResult = ApolloReactCommon.MutationResult<MonthlyTypeConditionDeleteMutation>;
export type MonthlyTypeConditionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<MonthlyTypeConditionDeleteMutation, MonthlyTypeConditionDeleteMutationVariables>;
export const MonthlyTypeConditionListDocument = gql`
    query MonthlyTypeConditionList($CompanyID: String!, $ClaimType: String!) {
  MonthlyTypeConditionList(CompanyID: $CompanyID, ClaimType: $ClaimType) {
    CompanyID
    MonthlyTemplateID
    ConditionList
    ClaimPolicyIDList
    ClaimPolicyID
    ClaimTypeID
    ModifiedDT
    CreatedDT
    PeriodicClaimType
  }
}
    `;

/**
 * __useMonthlyTypeConditionListQuery__
 *
 * To run a query within a React component, call `useMonthlyTypeConditionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyTypeConditionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyTypeConditionListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ClaimType: // value for 'ClaimType'
 *   },
 * });
 */
export function useMonthlyTypeConditionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthlyTypeConditionListQuery, MonthlyTypeConditionListQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthlyTypeConditionListQuery, MonthlyTypeConditionListQueryVariables>(MonthlyTypeConditionListDocument, baseOptions);
      }
export function useMonthlyTypeConditionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthlyTypeConditionListQuery, MonthlyTypeConditionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthlyTypeConditionListQuery, MonthlyTypeConditionListQueryVariables>(MonthlyTypeConditionListDocument, baseOptions);
        }
export type MonthlyTypeConditionListQueryHookResult = ReturnType<typeof useMonthlyTypeConditionListQuery>;
export type MonthlyTypeConditionListLazyQueryHookResult = ReturnType<typeof useMonthlyTypeConditionListLazyQuery>;
export type MonthlyTypeConditionListQueryResult = ApolloReactCommon.QueryResult<MonthlyTypeConditionListQuery, MonthlyTypeConditionListQueryVariables>;
export const DeductionTypeConditionUpdateDocument = gql`
    mutation DeductionTypeConditionUpdate($DeductionTemplateInput: DeductionTemplateInput!) {
  DeductionTypeConditionUpdate(DeductionTemplateInput: $DeductionTemplateInput)
}
    `;
export type DeductionTypeConditionUpdateMutationFn = ApolloReactCommon.MutationFunction<DeductionTypeConditionUpdateMutation, DeductionTypeConditionUpdateMutationVariables>;

/**
 * __useDeductionTypeConditionUpdateMutation__
 *
 * To run a mutation, you first call `useDeductionTypeConditionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeductionTypeConditionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deductionTypeConditionUpdateMutation, { data, loading, error }] = useDeductionTypeConditionUpdateMutation({
 *   variables: {
 *      DeductionTemplateInput: // value for 'DeductionTemplateInput'
 *   },
 * });
 */
export function useDeductionTypeConditionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeductionTypeConditionUpdateMutation, DeductionTypeConditionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeductionTypeConditionUpdateMutation, DeductionTypeConditionUpdateMutationVariables>(DeductionTypeConditionUpdateDocument, baseOptions);
      }
export type DeductionTypeConditionUpdateMutationHookResult = ReturnType<typeof useDeductionTypeConditionUpdateMutation>;
export type DeductionTypeConditionUpdateMutationResult = ApolloReactCommon.MutationResult<DeductionTypeConditionUpdateMutation>;
export type DeductionTypeConditionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeductionTypeConditionUpdateMutation, DeductionTypeConditionUpdateMutationVariables>;
export const DeductionTypeConditionDeleteDocument = gql`
    mutation DeductionTypeConditionDelete($DeductionTemplateID: String!) {
  DeductionTypeConditionDelete(DeductionTemplateID: $DeductionTemplateID)
}
    `;
export type DeductionTypeConditionDeleteMutationFn = ApolloReactCommon.MutationFunction<DeductionTypeConditionDeleteMutation, DeductionTypeConditionDeleteMutationVariables>;

/**
 * __useDeductionTypeConditionDeleteMutation__
 *
 * To run a mutation, you first call `useDeductionTypeConditionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeductionTypeConditionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deductionTypeConditionDeleteMutation, { data, loading, error }] = useDeductionTypeConditionDeleteMutation({
 *   variables: {
 *      DeductionTemplateID: // value for 'DeductionTemplateID'
 *   },
 * });
 */
export function useDeductionTypeConditionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeductionTypeConditionDeleteMutation, DeductionTypeConditionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeductionTypeConditionDeleteMutation, DeductionTypeConditionDeleteMutationVariables>(DeductionTypeConditionDeleteDocument, baseOptions);
      }
export type DeductionTypeConditionDeleteMutationHookResult = ReturnType<typeof useDeductionTypeConditionDeleteMutation>;
export type DeductionTypeConditionDeleteMutationResult = ApolloReactCommon.MutationResult<DeductionTypeConditionDeleteMutation>;
export type DeductionTypeConditionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeductionTypeConditionDeleteMutation, DeductionTypeConditionDeleteMutationVariables>;
export const DeductionTypeConditionListDocument = gql`
    query DeductionTypeConditionList($CompanyID: String!) {
  DeductionTypeConditionList(CompanyID: $CompanyID) {
    CompanyID
    DeductionTemplateID
    ConditionList
    ClaimPolicyIDList
    ClaimPolicyID
    ClaimTypeID
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __useDeductionTypeConditionListQuery__
 *
 * To run a query within a React component, call `useDeductionTypeConditionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeductionTypeConditionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeductionTypeConditionListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDeductionTypeConditionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeductionTypeConditionListQuery, DeductionTypeConditionListQueryVariables>) {
        return ApolloReactHooks.useQuery<DeductionTypeConditionListQuery, DeductionTypeConditionListQueryVariables>(DeductionTypeConditionListDocument, baseOptions);
      }
export function useDeductionTypeConditionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeductionTypeConditionListQuery, DeductionTypeConditionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeductionTypeConditionListQuery, DeductionTypeConditionListQueryVariables>(DeductionTypeConditionListDocument, baseOptions);
        }
export type DeductionTypeConditionListQueryHookResult = ReturnType<typeof useDeductionTypeConditionListQuery>;
export type DeductionTypeConditionListLazyQueryHookResult = ReturnType<typeof useDeductionTypeConditionListLazyQuery>;
export type DeductionTypeConditionListQueryResult = ApolloReactCommon.QueryResult<DeductionTypeConditionListQuery, DeductionTypeConditionListQueryVariables>;
export const PayItemConditionListTemplateLibraryDocument = gql`
    query PayItemConditionListTemplateLibrary($SubscriptionAccountID: String!, $CompanyID: String!) {
  PayItemConditionListTemplateLibrary(SubscriptionAccountID: $SubscriptionAccountID, CompanyID: $CompanyID) {
    PayItemConditionID
    CompanyID
    IsForTemplateLibrary
    ConditionList
  }
}
    `;

/**
 * __usePayItemConditionListTemplateLibraryQuery__
 *
 * To run a query within a React component, call `usePayItemConditionListTemplateLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayItemConditionListTemplateLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayItemConditionListTemplateLibraryQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePayItemConditionListTemplateLibraryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayItemConditionListTemplateLibraryQuery, PayItemConditionListTemplateLibraryQueryVariables>) {
        return ApolloReactHooks.useQuery<PayItemConditionListTemplateLibraryQuery, PayItemConditionListTemplateLibraryQueryVariables>(PayItemConditionListTemplateLibraryDocument, baseOptions);
      }
export function usePayItemConditionListTemplateLibraryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayItemConditionListTemplateLibraryQuery, PayItemConditionListTemplateLibraryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayItemConditionListTemplateLibraryQuery, PayItemConditionListTemplateLibraryQueryVariables>(PayItemConditionListTemplateLibraryDocument, baseOptions);
        }
export type PayItemConditionListTemplateLibraryQueryHookResult = ReturnType<typeof usePayItemConditionListTemplateLibraryQuery>;
export type PayItemConditionListTemplateLibraryLazyQueryHookResult = ReturnType<typeof usePayItemConditionListTemplateLibraryLazyQuery>;
export type PayItemConditionListTemplateLibraryQueryResult = ApolloReactCommon.QueryResult<PayItemConditionListTemplateLibraryQuery, PayItemConditionListTemplateLibraryQueryVariables>;
export const OtPayItemConditionListDocument = gql`
    query OTPayItemConditionList($CompanyID: String!) {
  OTPayItemConditionList(CompanyID: $CompanyID) {
    PayItemConditionID
    CompanyID
    ConditionList
    OvertimePolicyID
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    Status
    SubscriptionAccountID
  }
}
    `;

/**
 * __useOtPayItemConditionListQuery__
 *
 * To run a query within a React component, call `useOtPayItemConditionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtPayItemConditionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtPayItemConditionListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useOtPayItemConditionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OtPayItemConditionListQuery, OtPayItemConditionListQueryVariables>) {
        return ApolloReactHooks.useQuery<OtPayItemConditionListQuery, OtPayItemConditionListQueryVariables>(OtPayItemConditionListDocument, baseOptions);
      }
export function useOtPayItemConditionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OtPayItemConditionListQuery, OtPayItemConditionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OtPayItemConditionListQuery, OtPayItemConditionListQueryVariables>(OtPayItemConditionListDocument, baseOptions);
        }
export type OtPayItemConditionListQueryHookResult = ReturnType<typeof useOtPayItemConditionListQuery>;
export type OtPayItemConditionListLazyQueryHookResult = ReturnType<typeof useOtPayItemConditionListLazyQuery>;
export type OtPayItemConditionListQueryResult = ApolloReactCommon.QueryResult<OtPayItemConditionListQuery, OtPayItemConditionListQueryVariables>;
export const EaPartFListingByYearDocument = gql`
    query EAPartFListingByYear($PeriodYear: Float!) {
  EAPartFListingByYear(PeriodYear: $PeriodYear) {
    EAPartFID
    Description
    LimitAmt
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ReportYear
  }
}
    `;

/**
 * __useEaPartFListingByYearQuery__
 *
 * To run a query within a React component, call `useEaPartFListingByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useEaPartFListingByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEaPartFListingByYearQuery({
 *   variables: {
 *      PeriodYear: // value for 'PeriodYear'
 *   },
 * });
 */
export function useEaPartFListingByYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EaPartFListingByYearQuery, EaPartFListingByYearQueryVariables>) {
        return ApolloReactHooks.useQuery<EaPartFListingByYearQuery, EaPartFListingByYearQueryVariables>(EaPartFListingByYearDocument, baseOptions);
      }
export function useEaPartFListingByYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EaPartFListingByYearQuery, EaPartFListingByYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EaPartFListingByYearQuery, EaPartFListingByYearQueryVariables>(EaPartFListingByYearDocument, baseOptions);
        }
export type EaPartFListingByYearQueryHookResult = ReturnType<typeof useEaPartFListingByYearQuery>;
export type EaPartFListingByYearLazyQueryHookResult = ReturnType<typeof useEaPartFListingByYearLazyQuery>;
export type EaPartFListingByYearQueryResult = ApolloReactCommon.QueryResult<EaPartFListingByYearQuery, EaPartFListingByYearQueryVariables>;
export const LhdnReportDropdownListingDocument = gql`
    query LHDNReportDropdownListing($ReportYear: Float!) {
  LHDNReportDropdownListing(ReportYear: $ReportYear) {
    ReportTypeSequence
    ReportType
    LHDNReportID
    ENDescription
    Status
  }
}
    `;

/**
 * __useLhdnReportDropdownListingQuery__
 *
 * To run a query within a React component, call `useLhdnReportDropdownListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLhdnReportDropdownListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLhdnReportDropdownListingQuery({
 *   variables: {
 *      ReportYear: // value for 'ReportYear'
 *   },
 * });
 */
export function useLhdnReportDropdownListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LhdnReportDropdownListingQuery, LhdnReportDropdownListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LhdnReportDropdownListingQuery, LhdnReportDropdownListingQueryVariables>(LhdnReportDropdownListingDocument, baseOptions);
      }
export function useLhdnReportDropdownListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LhdnReportDropdownListingQuery, LhdnReportDropdownListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LhdnReportDropdownListingQuery, LhdnReportDropdownListingQueryVariables>(LhdnReportDropdownListingDocument, baseOptions);
        }
export type LhdnReportDropdownListingQueryHookResult = ReturnType<typeof useLhdnReportDropdownListingQuery>;
export type LhdnReportDropdownListingLazyQueryHookResult = ReturnType<typeof useLhdnReportDropdownListingLazyQuery>;
export type LhdnReportDropdownListingQueryResult = ApolloReactCommon.QueryResult<LhdnReportDropdownListingQuery, LhdnReportDropdownListingQueryVariables>;
export const LhdnReportUpdateDocument = gql`
    mutation LHDNReportUpdate($EAFormSetupID: String!, $input: EAFormSetupInput!) {
  LHDNReportUpdate(EAFormSetupID: $EAFormSetupID, input: $input)
}
    `;
export type LhdnReportUpdateMutationFn = ApolloReactCommon.MutationFunction<LhdnReportUpdateMutation, LhdnReportUpdateMutationVariables>;

/**
 * __useLhdnReportUpdateMutation__
 *
 * To run a mutation, you first call `useLhdnReportUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLhdnReportUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lhdnReportUpdateMutation, { data, loading, error }] = useLhdnReportUpdateMutation({
 *   variables: {
 *      EAFormSetupID: // value for 'EAFormSetupID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLhdnReportUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LhdnReportUpdateMutation, LhdnReportUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LhdnReportUpdateMutation, LhdnReportUpdateMutationVariables>(LhdnReportUpdateDocument, baseOptions);
      }
export type LhdnReportUpdateMutationHookResult = ReturnType<typeof useLhdnReportUpdateMutation>;
export type LhdnReportUpdateMutationResult = ApolloReactCommon.MutationResult<LhdnReportUpdateMutation>;
export type LhdnReportUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LhdnReportUpdateMutation, LhdnReportUpdateMutationVariables>;
export const LhdnReportInsertDocument = gql`
    mutation LHDNReportInsert($input: EAFormSetupInput!) {
  LHDNReportInsert(input: $input)
}
    `;
export type LhdnReportInsertMutationFn = ApolloReactCommon.MutationFunction<LhdnReportInsertMutation, LhdnReportInsertMutationVariables>;

/**
 * __useLhdnReportInsertMutation__
 *
 * To run a mutation, you first call `useLhdnReportInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLhdnReportInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lhdnReportInsertMutation, { data, loading, error }] = useLhdnReportInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLhdnReportInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LhdnReportInsertMutation, LhdnReportInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LhdnReportInsertMutation, LhdnReportInsertMutationVariables>(LhdnReportInsertDocument, baseOptions);
      }
export type LhdnReportInsertMutationHookResult = ReturnType<typeof useLhdnReportInsertMutation>;
export type LhdnReportInsertMutationResult = ApolloReactCommon.MutationResult<LhdnReportInsertMutation>;
export type LhdnReportInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LhdnReportInsertMutation, LhdnReportInsertMutationVariables>;
export const GetLhdnReportListingDocument = gql`
    query GetLHDNReportListing($periodYear: Float!, $SubscriptionAccountID: String!) {
  GetLHDNReportListing(periodYear: $periodYear, SubscriptionAccountID: $SubscriptionAccountID) {
    CP22aReportSeq
    CP21ReportSeq
    EAFormReportSeq
    LHDNReportID
    PayItemID
    PayItemType
    CreatedDT
    ModifiedDT
    PayName
    SubscriptionAccountID
    CompanyID
    CP21
    CP22a
    EAForm
    EAFormSetupID
  }
}
    `;

/**
 * __useGetLhdnReportListingQuery__
 *
 * To run a query within a React component, call `useGetLhdnReportListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLhdnReportListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLhdnReportListingQuery({
 *   variables: {
 *      periodYear: // value for 'periodYear'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useGetLhdnReportListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLhdnReportListingQuery, GetLhdnReportListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLhdnReportListingQuery, GetLhdnReportListingQueryVariables>(GetLhdnReportListingDocument, baseOptions);
      }
export function useGetLhdnReportListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLhdnReportListingQuery, GetLhdnReportListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLhdnReportListingQuery, GetLhdnReportListingQueryVariables>(GetLhdnReportListingDocument, baseOptions);
        }
export type GetLhdnReportListingQueryHookResult = ReturnType<typeof useGetLhdnReportListingQuery>;
export type GetLhdnReportListingLazyQueryHookResult = ReturnType<typeof useGetLhdnReportListingLazyQuery>;
export type GetLhdnReportListingQueryResult = ApolloReactCommon.QueryResult<GetLhdnReportListingQuery, GetLhdnReportListingQueryVariables>;
export const LhdnReportCopyInsertDocument = gql`
    mutation LHDNReportCopyInsert($SubscriptionAccountID: String!, $FromYear: Float!, $ToYear: Float!) {
  LHDNReportCopyInsert(SubscriptionAccountID: $SubscriptionAccountID, FromYear: $FromYear, ToYear: $ToYear)
}
    `;
export type LhdnReportCopyInsertMutationFn = ApolloReactCommon.MutationFunction<LhdnReportCopyInsertMutation, LhdnReportCopyInsertMutationVariables>;

/**
 * __useLhdnReportCopyInsertMutation__
 *
 * To run a mutation, you first call `useLhdnReportCopyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLhdnReportCopyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lhdnReportCopyInsertMutation, { data, loading, error }] = useLhdnReportCopyInsertMutation({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      FromYear: // value for 'FromYear'
 *      ToYear: // value for 'ToYear'
 *   },
 * });
 */
export function useLhdnReportCopyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LhdnReportCopyInsertMutation, LhdnReportCopyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LhdnReportCopyInsertMutation, LhdnReportCopyInsertMutationVariables>(LhdnReportCopyInsertDocument, baseOptions);
      }
export type LhdnReportCopyInsertMutationHookResult = ReturnType<typeof useLhdnReportCopyInsertMutation>;
export type LhdnReportCopyInsertMutationResult = ApolloReactCommon.MutationResult<LhdnReportCopyInsertMutation>;
export type LhdnReportCopyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LhdnReportCopyInsertMutation, LhdnReportCopyInsertMutationVariables>;
export const PayrollCycleDeleteDocument = gql`
    mutation PayrollCycleDelete($PayrollCycleID: String!) {
  PayrollCycleDelete(PayrollCycleID: $PayrollCycleID)
}
    `;
export type PayrollCycleDeleteMutationFn = ApolloReactCommon.MutationFunction<PayrollCycleDeleteMutation, PayrollCycleDeleteMutationVariables>;

/**
 * __usePayrollCycleDeleteMutation__
 *
 * To run a mutation, you first call `usePayrollCycleDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollCycleDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollCycleDeleteMutation, { data, loading, error }] = usePayrollCycleDeleteMutation({
 *   variables: {
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *   },
 * });
 */
export function usePayrollCycleDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayrollCycleDeleteMutation, PayrollCycleDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PayrollCycleDeleteMutation, PayrollCycleDeleteMutationVariables>(PayrollCycleDeleteDocument, baseOptions);
      }
export type PayrollCycleDeleteMutationHookResult = ReturnType<typeof usePayrollCycleDeleteMutation>;
export type PayrollCycleDeleteMutationResult = ApolloReactCommon.MutationResult<PayrollCycleDeleteMutation>;
export type PayrollCycleDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PayrollCycleDeleteMutation, PayrollCycleDeleteMutationVariables>;
export const IsPayrollCycleInUseDocument = gql`
    query IsPayrollCycleInUse($PayrollCycleID: String!) {
  IsPayrollCycleInUse(PayrollCycleID: $PayrollCycleID)
}
    `;

/**
 * __useIsPayrollCycleInUseQuery__
 *
 * To run a query within a React component, call `useIsPayrollCycleInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPayrollCycleInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPayrollCycleInUseQuery({
 *   variables: {
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *   },
 * });
 */
export function useIsPayrollCycleInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsPayrollCycleInUseQuery, IsPayrollCycleInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<IsPayrollCycleInUseQuery, IsPayrollCycleInUseQueryVariables>(IsPayrollCycleInUseDocument, baseOptions);
      }
export function useIsPayrollCycleInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsPayrollCycleInUseQuery, IsPayrollCycleInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsPayrollCycleInUseQuery, IsPayrollCycleInUseQueryVariables>(IsPayrollCycleInUseDocument, baseOptions);
        }
export type IsPayrollCycleInUseQueryHookResult = ReturnType<typeof useIsPayrollCycleInUseQuery>;
export type IsPayrollCycleInUseLazyQueryHookResult = ReturnType<typeof useIsPayrollCycleInUseLazyQuery>;
export type IsPayrollCycleInUseQueryResult = ApolloReactCommon.QueryResult<IsPayrollCycleInUseQuery, IsPayrollCycleInUseQueryVariables>;
export const UpdateLastLoginCompanyDocument = gql`
    mutation UpdateLastLoginCompany($userID: String!, $lastestAccessedEntity: String!) {
  UpdateLastLoginCompany(userID: $userID, lastestAccessedEntity: $lastestAccessedEntity)
}
    `;
export type UpdateLastLoginCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateLastLoginCompanyMutation, UpdateLastLoginCompanyMutationVariables>;

/**
 * __useUpdateLastLoginCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateLastLoginCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastLoginCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastLoginCompanyMutation, { data, loading, error }] = useUpdateLastLoginCompanyMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      lastestAccessedEntity: // value for 'lastestAccessedEntity'
 *   },
 * });
 */
export function useUpdateLastLoginCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLastLoginCompanyMutation, UpdateLastLoginCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLastLoginCompanyMutation, UpdateLastLoginCompanyMutationVariables>(UpdateLastLoginCompanyDocument, baseOptions);
      }
export type UpdateLastLoginCompanyMutationHookResult = ReturnType<typeof useUpdateLastLoginCompanyMutation>;
export type UpdateLastLoginCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateLastLoginCompanyMutation>;
export type UpdateLastLoginCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLastLoginCompanyMutation, UpdateLastLoginCompanyMutationVariables>;
export const PayrollCycleInsertDocument = gql`
    mutation PayrollCycleInsert($Input: PayrollCycleInput!) {
  PayrollCycleInsert(input: $Input) {
    PayrollCycleID
  }
}
    `;
export type PayrollCycleInsertMutationFn = ApolloReactCommon.MutationFunction<PayrollCycleInsertMutation, PayrollCycleInsertMutationVariables>;

/**
 * __usePayrollCycleInsertMutation__
 *
 * To run a mutation, you first call `usePayrollCycleInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollCycleInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollCycleInsertMutation, { data, loading, error }] = usePayrollCycleInsertMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function usePayrollCycleInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayrollCycleInsertMutation, PayrollCycleInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PayrollCycleInsertMutation, PayrollCycleInsertMutationVariables>(PayrollCycleInsertDocument, baseOptions);
      }
export type PayrollCycleInsertMutationHookResult = ReturnType<typeof usePayrollCycleInsertMutation>;
export type PayrollCycleInsertMutationResult = ApolloReactCommon.MutationResult<PayrollCycleInsertMutation>;
export type PayrollCycleInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PayrollCycleInsertMutation, PayrollCycleInsertMutationVariables>;
export const PayrollCycleDetailDocument = gql`
    query PayrollCycleDetail($PayrollCycleID: String!) {
  PayrollCycleDetail(PayrollCycleID: $PayrollCycleID) {
    PayrollCycleID
    Description
    EPF
    EPFVE
    SOCSO
    MTD
    Zakat
    EIS
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __usePayrollCycleDetailQuery__
 *
 * To run a query within a React component, call `usePayrollCycleDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCycleDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCycleDetailQuery({
 *   variables: {
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *   },
 * });
 */
export function usePayrollCycleDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollCycleDetailQuery, PayrollCycleDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollCycleDetailQuery, PayrollCycleDetailQueryVariables>(PayrollCycleDetailDocument, baseOptions);
      }
export function usePayrollCycleDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollCycleDetailQuery, PayrollCycleDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollCycleDetailQuery, PayrollCycleDetailQueryVariables>(PayrollCycleDetailDocument, baseOptions);
        }
export type PayrollCycleDetailQueryHookResult = ReturnType<typeof usePayrollCycleDetailQuery>;
export type PayrollCycleDetailLazyQueryHookResult = ReturnType<typeof usePayrollCycleDetailLazyQuery>;
export type PayrollCycleDetailQueryResult = ApolloReactCommon.QueryResult<PayrollCycleDetailQuery, PayrollCycleDetailQueryVariables>;
export const PayrollCycleUpdateDocument = gql`
    mutation PayrollCycleUpdate($PayrollCycleID: String!, $Input: PayrollCycleInput!) {
  PayrollCycleUpdate(PayrollCycleID: $PayrollCycleID, input: $Input)
}
    `;
export type PayrollCycleUpdateMutationFn = ApolloReactCommon.MutationFunction<PayrollCycleUpdateMutation, PayrollCycleUpdateMutationVariables>;

/**
 * __usePayrollCycleUpdateMutation__
 *
 * To run a mutation, you first call `usePayrollCycleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayrollCycleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payrollCycleUpdateMutation, { data, loading, error }] = usePayrollCycleUpdateMutation({
 *   variables: {
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function usePayrollCycleUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PayrollCycleUpdateMutation, PayrollCycleUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PayrollCycleUpdateMutation, PayrollCycleUpdateMutationVariables>(PayrollCycleUpdateDocument, baseOptions);
      }
export type PayrollCycleUpdateMutationHookResult = ReturnType<typeof usePayrollCycleUpdateMutation>;
export type PayrollCycleUpdateMutationResult = ApolloReactCommon.MutationResult<PayrollCycleUpdateMutation>;
export type PayrollCycleUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PayrollCycleUpdateMutation, PayrollCycleUpdateMutationVariables>;
export const PayrollCycleListingDocument = gql`
    query PayrollCycleListing($CompanyID: String!) {
  PayrollCycleListing(CompanyID: $CompanyID) {
    Status
    PayrollCycleID
    CompanyID
    Description
    IsDefault
    EPF
    EPFVE
    SOCSO
    MTD
    Zakat
    EIS
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    Company {
      CompanyName
      SubscriptionAccountID
    }
  }
}
    `;

/**
 * __usePayrollCycleListingQuery__
 *
 * To run a query within a React component, call `usePayrollCycleListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCycleListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCycleListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePayrollCycleListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollCycleListingQuery, PayrollCycleListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollCycleListingQuery, PayrollCycleListingQueryVariables>(PayrollCycleListingDocument, baseOptions);
      }
export function usePayrollCycleListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollCycleListingQuery, PayrollCycleListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollCycleListingQuery, PayrollCycleListingQueryVariables>(PayrollCycleListingDocument, baseOptions);
        }
export type PayrollCycleListingQueryHookResult = ReturnType<typeof usePayrollCycleListingQuery>;
export type PayrollCycleListingLazyQueryHookResult = ReturnType<typeof usePayrollCycleListingLazyQuery>;
export type PayrollCycleListingQueryResult = ApolloReactCommon.QueryResult<PayrollCycleListingQuery, PayrollCycleListingQueryVariables>;
export const BankListingDocument = gql`
    query BankListing($CompanyID: String!) {
  BankListing(CompanyID: $CompanyID) {
    BankInfoID
    Name
    AccountNo
    AccountName
    SwiftCode
    OrganizationCode
    Address {
      Line1
      Line2
      PostalCode
      City
      State
      Country
    }
    ContactNo
    ContactName
    CompanyBank {
      CompanyName
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    IsDefault
    CorporateID
    ClientBatchID
    OriginatorID
  }
}
    `;

/**
 * __useBankListingQuery__
 *
 * To run a query within a React component, call `useBankListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useBankListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BankListingQuery, BankListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BankListingQuery, BankListingQueryVariables>(BankListingDocument, baseOptions);
      }
export function useBankListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BankListingQuery, BankListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BankListingQuery, BankListingQueryVariables>(BankListingDocument, baseOptions);
        }
export type BankListingQueryHookResult = ReturnType<typeof useBankListingQuery>;
export type BankListingLazyQueryHookResult = ReturnType<typeof useBankListingLazyQuery>;
export type BankListingQueryResult = ApolloReactCommon.QueryResult<BankListingQuery, BankListingQueryVariables>;
export const CompanyStatutoryDetailDocument = gql`
    query CompanyStatutoryDetail($CompanyID: String!) {
  CompanyStatutoryDetail(CompanyID: $CompanyID) {
    CompanyStatutoryID
    SignBy
  }
}
    `;

/**
 * __useCompanyStatutoryDetailQuery__
 *
 * To run a query within a React component, call `useCompanyStatutoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyStatutoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyStatutoryDetailQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCompanyStatutoryDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyStatutoryDetailQuery, CompanyStatutoryDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyStatutoryDetailQuery, CompanyStatutoryDetailQueryVariables>(CompanyStatutoryDetailDocument, baseOptions);
      }
export function useCompanyStatutoryDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyStatutoryDetailQuery, CompanyStatutoryDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyStatutoryDetailQuery, CompanyStatutoryDetailQueryVariables>(CompanyStatutoryDetailDocument, baseOptions);
        }
export type CompanyStatutoryDetailQueryHookResult = ReturnType<typeof useCompanyStatutoryDetailQuery>;
export type CompanyStatutoryDetailLazyQueryHookResult = ReturnType<typeof useCompanyStatutoryDetailLazyQuery>;
export type CompanyStatutoryDetailQueryResult = ApolloReactCommon.QueryResult<CompanyStatutoryDetailQuery, CompanyStatutoryDetailQueryVariables>;
export const GetPayrollTemplateLibraryDocument = gql`
    query getPayrollTemplateLibrary($CompanyID: String!) {
  getPayrollTemplateLibrary(CompanyID: $CompanyID) {
    TemplateLibraryAssignmentID
    CompanyID
    TargetID
    TargetType
    Module
    SourceType
    CreatedDT
    ModifiedDT
    SourceID
    EnableTarget
    Sequence
    PayrollCycle {
      PayrollCycleID
      Description
    }
  }
}
    `;

/**
 * __useGetPayrollTemplateLibraryQuery__
 *
 * To run a query within a React component, call `useGetPayrollTemplateLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollTemplateLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollTemplateLibraryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetPayrollTemplateLibraryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPayrollTemplateLibraryQuery, GetPayrollTemplateLibraryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPayrollTemplateLibraryQuery, GetPayrollTemplateLibraryQueryVariables>(GetPayrollTemplateLibraryDocument, baseOptions);
      }
export function useGetPayrollTemplateLibraryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPayrollTemplateLibraryQuery, GetPayrollTemplateLibraryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPayrollTemplateLibraryQuery, GetPayrollTemplateLibraryQueryVariables>(GetPayrollTemplateLibraryDocument, baseOptions);
        }
export type GetPayrollTemplateLibraryQueryHookResult = ReturnType<typeof useGetPayrollTemplateLibraryQuery>;
export type GetPayrollTemplateLibraryLazyQueryHookResult = ReturnType<typeof useGetPayrollTemplateLibraryLazyQuery>;
export type GetPayrollTemplateLibraryQueryResult = ApolloReactCommon.QueryResult<GetPayrollTemplateLibraryQuery, GetPayrollTemplateLibraryQueryVariables>;
export const GetPayCycleForPayCalendarDocument = gql`
    query getPayCycleForPayCalendar($CompanyID: String!, $PayPeriodID: String!) {
  getPayCycleForPayCalendar(CompanyID: $CompanyID, PayPeriodID: $PayPeriodID)
}
    `;

/**
 * __useGetPayCycleForPayCalendarQuery__
 *
 * To run a query within a React component, call `useGetPayCycleForPayCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayCycleForPayCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayCycleForPayCalendarQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *   },
 * });
 */
export function useGetPayCycleForPayCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPayCycleForPayCalendarQuery, GetPayCycleForPayCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPayCycleForPayCalendarQuery, GetPayCycleForPayCalendarQueryVariables>(GetPayCycleForPayCalendarDocument, baseOptions);
      }
export function useGetPayCycleForPayCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPayCycleForPayCalendarQuery, GetPayCycleForPayCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPayCycleForPayCalendarQuery, GetPayCycleForPayCalendarQueryVariables>(GetPayCycleForPayCalendarDocument, baseOptions);
        }
export type GetPayCycleForPayCalendarQueryHookResult = ReturnType<typeof useGetPayCycleForPayCalendarQuery>;
export type GetPayCycleForPayCalendarLazyQueryHookResult = ReturnType<typeof useGetPayCycleForPayCalendarLazyQuery>;
export type GetPayCycleForPayCalendarQueryResult = ApolloReactCommon.QueryResult<GetPayCycleForPayCalendarQuery, GetPayCycleForPayCalendarQueryVariables>;
export const InsertPayPeriodListingDocument = gql`
    mutation InsertPayPeriodListing($PayPeriodInput: PayPeriodInput!) {
  InsertPayPeriodListing(input: $PayPeriodInput)
}
    `;
export type InsertPayPeriodListingMutationFn = ApolloReactCommon.MutationFunction<InsertPayPeriodListingMutation, InsertPayPeriodListingMutationVariables>;

/**
 * __useInsertPayPeriodListingMutation__
 *
 * To run a mutation, you first call `useInsertPayPeriodListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPayPeriodListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPayPeriodListingMutation, { data, loading, error }] = useInsertPayPeriodListingMutation({
 *   variables: {
 *      PayPeriodInput: // value for 'PayPeriodInput'
 *   },
 * });
 */
export function useInsertPayPeriodListingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertPayPeriodListingMutation, InsertPayPeriodListingMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertPayPeriodListingMutation, InsertPayPeriodListingMutationVariables>(InsertPayPeriodListingDocument, baseOptions);
      }
export type InsertPayPeriodListingMutationHookResult = ReturnType<typeof useInsertPayPeriodListingMutation>;
export type InsertPayPeriodListingMutationResult = ApolloReactCommon.MutationResult<InsertPayPeriodListingMutation>;
export type InsertPayPeriodListingMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertPayPeriodListingMutation, InsertPayPeriodListingMutationVariables>;
export const CompanyStatutoryUpdateDocument = gql`
    mutation CompanyStatutoryUpdate($CompanyStatutoryID: String!, $CompanyStatutoryInput: CompanyStatutoryInput!) {
  CompanyStatutoryUpdate(CompanyStatutoryID: $CompanyStatutoryID, CompanyStatutoryInput: $CompanyStatutoryInput)
}
    `;
export type CompanyStatutoryUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyStatutoryUpdateMutation, CompanyStatutoryUpdateMutationVariables>;

/**
 * __useCompanyStatutoryUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyStatutoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyStatutoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyStatutoryUpdateMutation, { data, loading, error }] = useCompanyStatutoryUpdateMutation({
 *   variables: {
 *      CompanyStatutoryID: // value for 'CompanyStatutoryID'
 *      CompanyStatutoryInput: // value for 'CompanyStatutoryInput'
 *   },
 * });
 */
export function useCompanyStatutoryUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyStatutoryUpdateMutation, CompanyStatutoryUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyStatutoryUpdateMutation, CompanyStatutoryUpdateMutationVariables>(CompanyStatutoryUpdateDocument, baseOptions);
      }
export type CompanyStatutoryUpdateMutationHookResult = ReturnType<typeof useCompanyStatutoryUpdateMutation>;
export type CompanyStatutoryUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyStatutoryUpdateMutation>;
export type CompanyStatutoryUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyStatutoryUpdateMutation, CompanyStatutoryUpdateMutationVariables>;
export const BankDeleteDocument = gql`
    mutation BankDelete($BankInfoID: String!) {
  BankDelete(BankInfoID: $BankInfoID)
}
    `;
export type BankDeleteMutationFn = ApolloReactCommon.MutationFunction<BankDeleteMutation, BankDeleteMutationVariables>;

/**
 * __useBankDeleteMutation__
 *
 * To run a mutation, you first call `useBankDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankDeleteMutation, { data, loading, error }] = useBankDeleteMutation({
 *   variables: {
 *      BankInfoID: // value for 'BankInfoID'
 *   },
 * });
 */
export function useBankDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BankDeleteMutation, BankDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<BankDeleteMutation, BankDeleteMutationVariables>(BankDeleteDocument, baseOptions);
      }
export type BankDeleteMutationHookResult = ReturnType<typeof useBankDeleteMutation>;
export type BankDeleteMutationResult = ApolloReactCommon.MutationResult<BankDeleteMutation>;
export type BankDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<BankDeleteMutation, BankDeleteMutationVariables>;
export const InterbankListingBulkDocument = gql`
    query InterbankListingBulk($BankInfoID: [String!]!) {
  InterbankListingBulk(BankInfoID: $BankInfoID) {
    BankInfoID
    InterbankGiroID
    IBGCode
    EmployeeBank
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useInterbankListingBulkQuery__
 *
 * To run a query within a React component, call `useInterbankListingBulkQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterbankListingBulkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterbankListingBulkQuery({
 *   variables: {
 *      BankInfoID: // value for 'BankInfoID'
 *   },
 * });
 */
export function useInterbankListingBulkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InterbankListingBulkQuery, InterbankListingBulkQueryVariables>) {
        return ApolloReactHooks.useQuery<InterbankListingBulkQuery, InterbankListingBulkQueryVariables>(InterbankListingBulkDocument, baseOptions);
      }
export function useInterbankListingBulkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InterbankListingBulkQuery, InterbankListingBulkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InterbankListingBulkQuery, InterbankListingBulkQueryVariables>(InterbankListingBulkDocument, baseOptions);
        }
export type InterbankListingBulkQueryHookResult = ReturnType<typeof useInterbankListingBulkQuery>;
export type InterbankListingBulkLazyQueryHookResult = ReturnType<typeof useInterbankListingBulkLazyQuery>;
export type InterbankListingBulkQueryResult = ApolloReactCommon.QueryResult<InterbankListingBulkQuery, InterbankListingBulkQueryVariables>;
export const BankInsertDocument = gql`
    mutation BankInsert($CompanyID: String!, $BankInfoInputs: BankInfoInputs!) {
  BankInsert(CompanyID: $CompanyID, BankInfoInputs: $BankInfoInputs) {
    BankInfoID
  }
}
    `;
export type BankInsertMutationFn = ApolloReactCommon.MutationFunction<BankInsertMutation, BankInsertMutationVariables>;

/**
 * __useBankInsertMutation__
 *
 * To run a mutation, you first call `useBankInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankInsertMutation, { data, loading, error }] = useBankInsertMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BankInfoInputs: // value for 'BankInfoInputs'
 *   },
 * });
 */
export function useBankInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BankInsertMutation, BankInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<BankInsertMutation, BankInsertMutationVariables>(BankInsertDocument, baseOptions);
      }
export type BankInsertMutationHookResult = ReturnType<typeof useBankInsertMutation>;
export type BankInsertMutationResult = ApolloReactCommon.MutationResult<BankInsertMutation>;
export type BankInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<BankInsertMutation, BankInsertMutationVariables>;
export const BankUpdateDocument = gql`
    mutation BankUpdate($input: BankInfoInputs!, $BankInfoID: String!) {
  BankUpdate(input: $input, BankInfoID: $BankInfoID)
}
    `;
export type BankUpdateMutationFn = ApolloReactCommon.MutationFunction<BankUpdateMutation, BankUpdateMutationVariables>;

/**
 * __useBankUpdateMutation__
 *
 * To run a mutation, you first call `useBankUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankUpdateMutation, { data, loading, error }] = useBankUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      BankInfoID: // value for 'BankInfoID'
 *   },
 * });
 */
export function useBankUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BankUpdateMutation, BankUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BankUpdateMutation, BankUpdateMutationVariables>(BankUpdateDocument, baseOptions);
      }
export type BankUpdateMutationHookResult = ReturnType<typeof useBankUpdateMutation>;
export type BankUpdateMutationResult = ApolloReactCommon.MutationResult<BankUpdateMutation>;
export type BankUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BankUpdateMutation, BankUpdateMutationVariables>;
export const InterbankDeleteDocument = gql`
    mutation InterbankDelete($InterbankGiroID: String!) {
  InterbankDelete(InterbankGiroID: $InterbankGiroID)
}
    `;
export type InterbankDeleteMutationFn = ApolloReactCommon.MutationFunction<InterbankDeleteMutation, InterbankDeleteMutationVariables>;

/**
 * __useInterbankDeleteMutation__
 *
 * To run a mutation, you first call `useInterbankDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterbankDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interbankDeleteMutation, { data, loading, error }] = useInterbankDeleteMutation({
 *   variables: {
 *      InterbankGiroID: // value for 'InterbankGiroID'
 *   },
 * });
 */
export function useInterbankDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InterbankDeleteMutation, InterbankDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<InterbankDeleteMutation, InterbankDeleteMutationVariables>(InterbankDeleteDocument, baseOptions);
      }
export type InterbankDeleteMutationHookResult = ReturnType<typeof useInterbankDeleteMutation>;
export type InterbankDeleteMutationResult = ApolloReactCommon.MutationResult<InterbankDeleteMutation>;
export type InterbankDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<InterbankDeleteMutation, InterbankDeleteMutationVariables>;
export const InterbankInsertDocument = gql`
    mutation InterbankInsert($InterbankGiroInput: InterbankGiroInput!) {
  InterbankInsert(InterbankGiroInput: $InterbankGiroInput) {
    InterbankGiroID
  }
}
    `;
export type InterbankInsertMutationFn = ApolloReactCommon.MutationFunction<InterbankInsertMutation, InterbankInsertMutationVariables>;

/**
 * __useInterbankInsertMutation__
 *
 * To run a mutation, you first call `useInterbankInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterbankInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interbankInsertMutation, { data, loading, error }] = useInterbankInsertMutation({
 *   variables: {
 *      InterbankGiroInput: // value for 'InterbankGiroInput'
 *   },
 * });
 */
export function useInterbankInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InterbankInsertMutation, InterbankInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<InterbankInsertMutation, InterbankInsertMutationVariables>(InterbankInsertDocument, baseOptions);
      }
export type InterbankInsertMutationHookResult = ReturnType<typeof useInterbankInsertMutation>;
export type InterbankInsertMutationResult = ApolloReactCommon.MutationResult<InterbankInsertMutation>;
export type InterbankInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<InterbankInsertMutation, InterbankInsertMutationVariables>;
export const InterbankSingleUpdateDocument = gql`
    mutation InterbankSingleUpdate($InterbankGiroID: String!, $InterbankGiroInput: InterbankGiroInput!) {
  InterbankSingleUpdate(InterbankGiroID: $InterbankGiroID, InterbankGiroInput: $InterbankGiroInput)
}
    `;
export type InterbankSingleUpdateMutationFn = ApolloReactCommon.MutationFunction<InterbankSingleUpdateMutation, InterbankSingleUpdateMutationVariables>;

/**
 * __useInterbankSingleUpdateMutation__
 *
 * To run a mutation, you first call `useInterbankSingleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterbankSingleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interbankSingleUpdateMutation, { data, loading, error }] = useInterbankSingleUpdateMutation({
 *   variables: {
 *      InterbankGiroID: // value for 'InterbankGiroID'
 *      InterbankGiroInput: // value for 'InterbankGiroInput'
 *   },
 * });
 */
export function useInterbankSingleUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InterbankSingleUpdateMutation, InterbankSingleUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<InterbankSingleUpdateMutation, InterbankSingleUpdateMutationVariables>(InterbankSingleUpdateDocument, baseOptions);
      }
export type InterbankSingleUpdateMutationHookResult = ReturnType<typeof useInterbankSingleUpdateMutation>;
export type InterbankSingleUpdateMutationResult = ApolloReactCommon.MutationResult<InterbankSingleUpdateMutation>;
export type InterbankSingleUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<InterbankSingleUpdateMutation, InterbankSingleUpdateMutationVariables>;
export const GetRolePermissionByUserDocument = gql`
    query getRolePermissionByUser($hrPermissions: [HrPermission!]) {
  getRolePermissionByUser(hrPermissions: $hrPermissions)
}
    `;

/**
 * __useGetRolePermissionByUserQuery__
 *
 * To run a query within a React component, call `useGetRolePermissionByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolePermissionByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolePermissionByUserQuery({
 *   variables: {
 *      hrPermissions: // value for 'hrPermissions'
 *   },
 * });
 */
export function useGetRolePermissionByUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>(GetRolePermissionByUserDocument, baseOptions);
      }
export function useGetRolePermissionByUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>(GetRolePermissionByUserDocument, baseOptions);
        }
export type GetRolePermissionByUserQueryHookResult = ReturnType<typeof useGetRolePermissionByUserQuery>;
export type GetRolePermissionByUserLazyQueryHookResult = ReturnType<typeof useGetRolePermissionByUserLazyQuery>;
export type GetRolePermissionByUserQueryResult = ApolloReactCommon.QueryResult<GetRolePermissionByUserQuery, GetRolePermissionByUserQueryVariables>;
export const IsAccountNoExistsDocument = gql`
    query IsAccountNoExists($AccountNo: String!, $CompanyID: String!) {
  IsAccountNoExists(AccountNo: $AccountNo, CompanyID: $CompanyID)
}
    `;

/**
 * __useIsAccountNoExistsQuery__
 *
 * To run a query within a React component, call `useIsAccountNoExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAccountNoExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAccountNoExistsQuery({
 *   variables: {
 *      AccountNo: // value for 'AccountNo'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useIsAccountNoExistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsAccountNoExistsQuery, IsAccountNoExistsQueryVariables>) {
        return ApolloReactHooks.useQuery<IsAccountNoExistsQuery, IsAccountNoExistsQueryVariables>(IsAccountNoExistsDocument, baseOptions);
      }
export function useIsAccountNoExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsAccountNoExistsQuery, IsAccountNoExistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsAccountNoExistsQuery, IsAccountNoExistsQueryVariables>(IsAccountNoExistsDocument, baseOptions);
        }
export type IsAccountNoExistsQueryHookResult = ReturnType<typeof useIsAccountNoExistsQuery>;
export type IsAccountNoExistsLazyQueryHookResult = ReturnType<typeof useIsAccountNoExistsLazyQuery>;
export type IsAccountNoExistsQueryResult = ApolloReactCommon.QueryResult<IsAccountNoExistsQuery, IsAccountNoExistsQueryVariables>;
export const IsAccountNameExistsDocument = gql`
    query IsAccountNameExists($AccountName: String!, $CompanyID: String!) {
  IsAccountNameExists(AccountName: $AccountName, CompanyID: $CompanyID)
}
    `;

/**
 * __useIsAccountNameExistsQuery__
 *
 * To run a query within a React component, call `useIsAccountNameExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAccountNameExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAccountNameExistsQuery({
 *   variables: {
 *      AccountName: // value for 'AccountName'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useIsAccountNameExistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsAccountNameExistsQuery, IsAccountNameExistsQueryVariables>) {
        return ApolloReactHooks.useQuery<IsAccountNameExistsQuery, IsAccountNameExistsQueryVariables>(IsAccountNameExistsDocument, baseOptions);
      }
export function useIsAccountNameExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsAccountNameExistsQuery, IsAccountNameExistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsAccountNameExistsQuery, IsAccountNameExistsQueryVariables>(IsAccountNameExistsDocument, baseOptions);
        }
export type IsAccountNameExistsQueryHookResult = ReturnType<typeof useIsAccountNameExistsQuery>;
export type IsAccountNameExistsLazyQueryHookResult = ReturnType<typeof useIsAccountNameExistsLazyQuery>;
export type IsAccountNameExistsQueryResult = ApolloReactCommon.QueryResult<IsAccountNameExistsQuery, IsAccountNameExistsQueryVariables>;
export const EmployeeDependentsListingDocument = gql`
    query EmployeeDependentsListing($EmployeeID: String!) {
  EmployeeDependentsListing(EmployeeID: $EmployeeID) {
    EmployeeDependentsID
    Relationship
    Education
    ChildEligibleRate
    Occupation
    Contact {
      ContactID
      FullName
      BirthDate
      MobileNo
      NRIC
      PassportNo
      Nationality
    }
    PCBNo
    IsWorking
    IsOKU
    IsEmergencyContact
  }
}
    `;

/**
 * __useEmployeeDependentsListingQuery__
 *
 * To run a query within a React component, call `useEmployeeDependentsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDependentsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDependentsListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeDependentsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeDependentsListingQuery, EmployeeDependentsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeDependentsListingQuery, EmployeeDependentsListingQueryVariables>(EmployeeDependentsListingDocument, baseOptions);
      }
export function useEmployeeDependentsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeDependentsListingQuery, EmployeeDependentsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeDependentsListingQuery, EmployeeDependentsListingQueryVariables>(EmployeeDependentsListingDocument, baseOptions);
        }
export type EmployeeDependentsListingQueryHookResult = ReturnType<typeof useEmployeeDependentsListingQuery>;
export type EmployeeDependentsListingLazyQueryHookResult = ReturnType<typeof useEmployeeDependentsListingLazyQuery>;
export type EmployeeDependentsListingQueryResult = ApolloReactCommon.QueryResult<EmployeeDependentsListingQuery, EmployeeDependentsListingQueryVariables>;
export const HasEmergencyContactDocument = gql`
    query hasEmergencyContact($EmployeeID: String!, $DependentID: String) {
  hasEmergencyContact(EmployeeID: $EmployeeID, DependentID: $DependentID)
}
    `;

/**
 * __useHasEmergencyContactQuery__
 *
 * To run a query within a React component, call `useHasEmergencyContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasEmergencyContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasEmergencyContactQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      DependentID: // value for 'DependentID'
 *   },
 * });
 */
export function useHasEmergencyContactQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HasEmergencyContactQuery, HasEmergencyContactQueryVariables>) {
        return ApolloReactHooks.useQuery<HasEmergencyContactQuery, HasEmergencyContactQueryVariables>(HasEmergencyContactDocument, baseOptions);
      }
export function useHasEmergencyContactLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HasEmergencyContactQuery, HasEmergencyContactQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HasEmergencyContactQuery, HasEmergencyContactQueryVariables>(HasEmergencyContactDocument, baseOptions);
        }
export type HasEmergencyContactQueryHookResult = ReturnType<typeof useHasEmergencyContactQuery>;
export type HasEmergencyContactLazyQueryHookResult = ReturnType<typeof useHasEmergencyContactLazyQuery>;
export type HasEmergencyContactQueryResult = ApolloReactCommon.QueryResult<HasEmergencyContactQuery, HasEmergencyContactQueryVariables>;
export const CompanyAltAddressListingDocument = gql`
    query CompanyAltAddressListing($CompanyID: String!, $IsStatutoryInfo: Boolean) {
  CompanyAltAddressListing(CompanyID: $CompanyID, IsStatutoryInfo: $IsStatutoryInfo)
}
    `;

/**
 * __useCompanyAltAddressListingQuery__
 *
 * To run a query within a React component, call `useCompanyAltAddressListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAltAddressListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAltAddressListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      IsStatutoryInfo: // value for 'IsStatutoryInfo'
 *   },
 * });
 */
export function useCompanyAltAddressListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyAltAddressListingQuery, CompanyAltAddressListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyAltAddressListingQuery, CompanyAltAddressListingQueryVariables>(CompanyAltAddressListingDocument, baseOptions);
      }
export function useCompanyAltAddressListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyAltAddressListingQuery, CompanyAltAddressListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyAltAddressListingQuery, CompanyAltAddressListingQueryVariables>(CompanyAltAddressListingDocument, baseOptions);
        }
export type CompanyAltAddressListingQueryHookResult = ReturnType<typeof useCompanyAltAddressListingQuery>;
export type CompanyAltAddressListingLazyQueryHookResult = ReturnType<typeof useCompanyAltAddressListingLazyQuery>;
export type CompanyAltAddressListingQueryResult = ApolloReactCommon.QueryResult<CompanyAltAddressListingQuery, CompanyAltAddressListingQueryVariables>;
export const CompanyAltAddressInsertDocument = gql`
    mutation CompanyAltAddressInsert($input: CompanyAltAddressInput!) {
  CompanyAltAddressInsert(input: $input)
}
    `;
export type CompanyAltAddressInsertMutationFn = ApolloReactCommon.MutationFunction<CompanyAltAddressInsertMutation, CompanyAltAddressInsertMutationVariables>;

/**
 * __useCompanyAltAddressInsertMutation__
 *
 * To run a mutation, you first call `useCompanyAltAddressInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAltAddressInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAltAddressInsertMutation, { data, loading, error }] = useCompanyAltAddressInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyAltAddressInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyAltAddressInsertMutation, CompanyAltAddressInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyAltAddressInsertMutation, CompanyAltAddressInsertMutationVariables>(CompanyAltAddressInsertDocument, baseOptions);
      }
export type CompanyAltAddressInsertMutationHookResult = ReturnType<typeof useCompanyAltAddressInsertMutation>;
export type CompanyAltAddressInsertMutationResult = ApolloReactCommon.MutationResult<CompanyAltAddressInsertMutation>;
export type CompanyAltAddressInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyAltAddressInsertMutation, CompanyAltAddressInsertMutationVariables>;
export const CompanyAltAddressUpdateDocument = gql`
    mutation CompanyAltAddressUpdate($CompanyAltAddressID: String!, $input: CompanyAltAddressInput!) {
  CompanyAltAddressUpdate(CompanyAltAddressID: $CompanyAltAddressID, input: $input)
}
    `;
export type CompanyAltAddressUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyAltAddressUpdateMutation, CompanyAltAddressUpdateMutationVariables>;

/**
 * __useCompanyAltAddressUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyAltAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAltAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAltAddressUpdateMutation, { data, loading, error }] = useCompanyAltAddressUpdateMutation({
 *   variables: {
 *      CompanyAltAddressID: // value for 'CompanyAltAddressID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyAltAddressUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyAltAddressUpdateMutation, CompanyAltAddressUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyAltAddressUpdateMutation, CompanyAltAddressUpdateMutationVariables>(CompanyAltAddressUpdateDocument, baseOptions);
      }
export type CompanyAltAddressUpdateMutationHookResult = ReturnType<typeof useCompanyAltAddressUpdateMutation>;
export type CompanyAltAddressUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyAltAddressUpdateMutation>;
export type CompanyAltAddressUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyAltAddressUpdateMutation, CompanyAltAddressUpdateMutationVariables>;
export const CompanyAltAddressDeleteDocument = gql`
    mutation CompanyAltAddressDelete($CompanyAltAddressID: String!) {
  CompanyAltAddressDelete(CompanyAltAddressID: $CompanyAltAddressID)
}
    `;
export type CompanyAltAddressDeleteMutationFn = ApolloReactCommon.MutationFunction<CompanyAltAddressDeleteMutation, CompanyAltAddressDeleteMutationVariables>;

/**
 * __useCompanyAltAddressDeleteMutation__
 *
 * To run a mutation, you first call `useCompanyAltAddressDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAltAddressDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAltAddressDeleteMutation, { data, loading, error }] = useCompanyAltAddressDeleteMutation({
 *   variables: {
 *      CompanyAltAddressID: // value for 'CompanyAltAddressID'
 *   },
 * });
 */
export function useCompanyAltAddressDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyAltAddressDeleteMutation, CompanyAltAddressDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyAltAddressDeleteMutation, CompanyAltAddressDeleteMutationVariables>(CompanyAltAddressDeleteDocument, baseOptions);
      }
export type CompanyAltAddressDeleteMutationHookResult = ReturnType<typeof useCompanyAltAddressDeleteMutation>;
export type CompanyAltAddressDeleteMutationResult = ApolloReactCommon.MutationResult<CompanyAltAddressDeleteMutation>;
export type CompanyAltAddressDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyAltAddressDeleteMutation, CompanyAltAddressDeleteMutationVariables>;
export const LoginUserPermsDocument = gql`
    query LoginUserPerms {
  LoginUserPerms
}
    `;

/**
 * __useLoginUserPermsQuery__
 *
 * To run a query within a React component, call `useLoginUserPermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginUserPermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginUserPermsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoginUserPermsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoginUserPermsQuery, LoginUserPermsQueryVariables>) {
        return ApolloReactHooks.useQuery<LoginUserPermsQuery, LoginUserPermsQueryVariables>(LoginUserPermsDocument, baseOptions);
      }
export function useLoginUserPermsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoginUserPermsQuery, LoginUserPermsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoginUserPermsQuery, LoginUserPermsQueryVariables>(LoginUserPermsDocument, baseOptions);
        }
export type LoginUserPermsQueryHookResult = ReturnType<typeof useLoginUserPermsQuery>;
export type LoginUserPermsLazyQueryHookResult = ReturnType<typeof useLoginUserPermsLazyQuery>;
export type LoginUserPermsQueryResult = ApolloReactCommon.QueryResult<LoginUserPermsQuery, LoginUserPermsQueryVariables>;
export const PayrollCommonSettingsInfoLineDocument = gql`
    query PayrollCommonSettingsInfoLine {
  PayrollCommonSettingsInfoLine
}
    `;

/**
 * __usePayrollCommonSettingsInfoLineQuery__
 *
 * To run a query within a React component, call `usePayrollCommonSettingsInfoLineQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCommonSettingsInfoLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCommonSettingsInfoLineQuery({
 *   variables: {
 *   },
 * });
 */
export function usePayrollCommonSettingsInfoLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollCommonSettingsInfoLineQuery, PayrollCommonSettingsInfoLineQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollCommonSettingsInfoLineQuery, PayrollCommonSettingsInfoLineQueryVariables>(PayrollCommonSettingsInfoLineDocument, baseOptions);
      }
export function usePayrollCommonSettingsInfoLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollCommonSettingsInfoLineQuery, PayrollCommonSettingsInfoLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollCommonSettingsInfoLineQuery, PayrollCommonSettingsInfoLineQueryVariables>(PayrollCommonSettingsInfoLineDocument, baseOptions);
        }
export type PayrollCommonSettingsInfoLineQueryHookResult = ReturnType<typeof usePayrollCommonSettingsInfoLineQuery>;
export type PayrollCommonSettingsInfoLineLazyQueryHookResult = ReturnType<typeof usePayrollCommonSettingsInfoLineLazyQuery>;
export type PayrollCommonSettingsInfoLineQueryResult = ApolloReactCommon.QueryResult<PayrollCommonSettingsInfoLineQuery, PayrollCommonSettingsInfoLineQueryVariables>;
export const EmployeeCalendarLeaveDetailsDocument = gql`
    query EmployeeCalendarLeaveDetails($input: EmpCalendarLeaveListing!) {
  EmployeeCalendarLeaveDetails(input: $input) {
    Status
    LeaveHeaderID
    LeaveHeader {
      LeaveHeaderType
      FromDate
      UntilDate
      LeaveType {
        LeaveTypeCode
        Name
      }
      Employee {
        EmployeeID
        SubscriptionUserID
        Contact {
          FullName
          BirthDate
        }
      }
    }
  }
}
    `;

/**
 * __useEmployeeCalendarLeaveDetailsQuery__
 *
 * To run a query within a React component, call `useEmployeeCalendarLeaveDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeCalendarLeaveDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeCalendarLeaveDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeCalendarLeaveDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeCalendarLeaveDetailsQuery, EmployeeCalendarLeaveDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeCalendarLeaveDetailsQuery, EmployeeCalendarLeaveDetailsQueryVariables>(EmployeeCalendarLeaveDetailsDocument, baseOptions);
      }
export function useEmployeeCalendarLeaveDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeCalendarLeaveDetailsQuery, EmployeeCalendarLeaveDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeCalendarLeaveDetailsQuery, EmployeeCalendarLeaveDetailsQueryVariables>(EmployeeCalendarLeaveDetailsDocument, baseOptions);
        }
export type EmployeeCalendarLeaveDetailsQueryHookResult = ReturnType<typeof useEmployeeCalendarLeaveDetailsQuery>;
export type EmployeeCalendarLeaveDetailsLazyQueryHookResult = ReturnType<typeof useEmployeeCalendarLeaveDetailsLazyQuery>;
export type EmployeeCalendarLeaveDetailsQueryResult = ApolloReactCommon.QueryResult<EmployeeCalendarLeaveDetailsQuery, EmployeeCalendarLeaveDetailsQueryVariables>;
export const EmployeeHomeCalendarDocument = gql`
    query EmployeeHomeCalendar($CompanyID: String!, $LeaveDate: DateTime!) {
  EmployeeHomeCalendar(CompanyID: $CompanyID, LeaveDate: $LeaveDate) {
    HolidayDescription
    publicHolidayOffDayDate
    EmployeeName
    EmployeeNo
    EmployeeID
    myBirthDate
    BirthDate
    Remarks
    leaveType
    TotalDay
    FromDate
    UntilDate
    LeaveDate
  }
}
    `;

/**
 * __useEmployeeHomeCalendarQuery__
 *
 * To run a query within a React component, call `useEmployeeHomeCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeHomeCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeHomeCalendarQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      LeaveDate: // value for 'LeaveDate'
 *   },
 * });
 */
export function useEmployeeHomeCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeHomeCalendarQuery, EmployeeHomeCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeHomeCalendarQuery, EmployeeHomeCalendarQueryVariables>(EmployeeHomeCalendarDocument, baseOptions);
      }
export function useEmployeeHomeCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeHomeCalendarQuery, EmployeeHomeCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeHomeCalendarQuery, EmployeeHomeCalendarQueryVariables>(EmployeeHomeCalendarDocument, baseOptions);
        }
export type EmployeeHomeCalendarQueryHookResult = ReturnType<typeof useEmployeeHomeCalendarQuery>;
export type EmployeeHomeCalendarLazyQueryHookResult = ReturnType<typeof useEmployeeHomeCalendarLazyQuery>;
export type EmployeeHomeCalendarQueryResult = ApolloReactCommon.QueryResult<EmployeeHomeCalendarQuery, EmployeeHomeCalendarQueryVariables>;
export const CompanyDeclaredHolidayListingDocument = gql`
    query CompanyDeclaredHolidayListing($CompanyID: String!, $StateID: String, $Year: Float, $ContactID: String) {
  CompanyDeclaredHolidayListing(CompanyID: $CompanyID, StateID: $StateID, Year: $Year, ContactID: $ContactID) {
    CompanyDelaredHolidayID
    CompanyID
    StateID
    HolidayDate
    Description
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useCompanyDeclaredHolidayListingQuery__
 *
 * To run a query within a React component, call `useCompanyDeclaredHolidayListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDeclaredHolidayListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDeclaredHolidayListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StateID: // value for 'StateID'
 *      Year: // value for 'Year'
 *      ContactID: // value for 'ContactID'
 *   },
 * });
 */
export function useCompanyDeclaredHolidayListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyDeclaredHolidayListingQuery, CompanyDeclaredHolidayListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyDeclaredHolidayListingQuery, CompanyDeclaredHolidayListingQueryVariables>(CompanyDeclaredHolidayListingDocument, baseOptions);
      }
export function useCompanyDeclaredHolidayListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyDeclaredHolidayListingQuery, CompanyDeclaredHolidayListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyDeclaredHolidayListingQuery, CompanyDeclaredHolidayListingQueryVariables>(CompanyDeclaredHolidayListingDocument, baseOptions);
        }
export type CompanyDeclaredHolidayListingQueryHookResult = ReturnType<typeof useCompanyDeclaredHolidayListingQuery>;
export type CompanyDeclaredHolidayListingLazyQueryHookResult = ReturnType<typeof useCompanyDeclaredHolidayListingLazyQuery>;
export type CompanyDeclaredHolidayListingQueryResult = ApolloReactCommon.QueryResult<CompanyDeclaredHolidayListingQuery, CompanyDeclaredHolidayListingQueryVariables>;
export const EmployeeCalendarDocument = gql`
    query EmployeeCalendar($CompanyID: String!, $CalendarDate: DateTime!, $EmployeeID: String!, $ContactID: String) {
  EmployeeCalendar(CompanyID: $CompanyID, CalendarDate: $CalendarDate, EmployeeID: $EmployeeID, ContactID: $ContactID) {
    companyOffDays {
      DayOffDate
      Description
    }
    employeeLeaves {
      EmployeeNo
      FullName
      LeaveType
      LeaveHeaderID
      Remarks
      LeaveDate
      FromDate
      UntilDate
      ApproverID
      SessionType
      IsOffDay
      IsRestDay
      IsPublicHoliday
      LeaveApprovalStatus
      TotalDay
    }
    employeeStaffLeaves {
      EmployeeNo
      LeaveHeaderID
      FullName
      LeaveType
      Remarks
      LeaveDate
      FromDate
      UntilDate
      SessionType
      IsOffDay
      IsRestDay
      IsPublicHoliday
      LeaveApprovalStatus
      OwnSubordinates
      TotalDay
    }
    employeeBirthdays {
      EmployeeNo
      FullName
      BirthDate
      CompanyID
    }
  }
}
    `;

/**
 * __useEmployeeCalendarQuery__
 *
 * To run a query within a React component, call `useEmployeeCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeCalendarQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CalendarDate: // value for 'CalendarDate'
 *      EmployeeID: // value for 'EmployeeID'
 *      ContactID: // value for 'ContactID'
 *   },
 * });
 */
export function useEmployeeCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeCalendarQuery, EmployeeCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeCalendarQuery, EmployeeCalendarQueryVariables>(EmployeeCalendarDocument, baseOptions);
      }
export function useEmployeeCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeCalendarQuery, EmployeeCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeCalendarQuery, EmployeeCalendarQueryVariables>(EmployeeCalendarDocument, baseOptions);
        }
export type EmployeeCalendarQueryHookResult = ReturnType<typeof useEmployeeCalendarQuery>;
export type EmployeeCalendarLazyQueryHookResult = ReturnType<typeof useEmployeeCalendarLazyQuery>;
export type EmployeeCalendarQueryResult = ApolloReactCommon.QueryResult<EmployeeCalendarQuery, EmployeeCalendarQueryVariables>;
export const GetLeaveOffDayDocument = gql`
    query GetLeaveOffDay($CompanyID: String!) {
  GetLeaveOffDay(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetLeaveOffDayQuery__
 *
 * To run a query within a React component, call `useGetLeaveOffDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveOffDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveOffDayQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetLeaveOffDayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveOffDayQuery, GetLeaveOffDayQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveOffDayQuery, GetLeaveOffDayQueryVariables>(GetLeaveOffDayDocument, baseOptions);
      }
export function useGetLeaveOffDayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveOffDayQuery, GetLeaveOffDayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveOffDayQuery, GetLeaveOffDayQueryVariables>(GetLeaveOffDayDocument, baseOptions);
        }
export type GetLeaveOffDayQueryHookResult = ReturnType<typeof useGetLeaveOffDayQuery>;
export type GetLeaveOffDayLazyQueryHookResult = ReturnType<typeof useGetLeaveOffDayLazyQuery>;
export type GetLeaveOffDayQueryResult = ApolloReactCommon.QueryResult<GetLeaveOffDayQuery, GetLeaveOffDayQueryVariables>;
export const GetMyClaimListingDocument = gql`
    query GetMyClaimListing($input: ClaimRecordsInput!) {
  GetMyClaimListing(input: $input) {
    CountApprovedClaims
    TotalApprovedClaimsAmount
    ClaimRecords {
      EmployeeID
      ClaimHeaderID
      EmployeeNo
      EmployeeName
      Subject
      SubmissionDate
      CountClaimDetails
      TotalClaimAmount
      CountVerifiedClaim
      TotalVerifiedClaimAmount
      DetailStatus
    }
  }
}
    `;

/**
 * __useGetMyClaimListingQuery__
 *
 * To run a query within a React component, call `useGetMyClaimListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyClaimListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyClaimListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyClaimListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyClaimListingQuery, GetMyClaimListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyClaimListingQuery, GetMyClaimListingQueryVariables>(GetMyClaimListingDocument, baseOptions);
      }
export function useGetMyClaimListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyClaimListingQuery, GetMyClaimListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyClaimListingQuery, GetMyClaimListingQueryVariables>(GetMyClaimListingDocument, baseOptions);
        }
export type GetMyClaimListingQueryHookResult = ReturnType<typeof useGetMyClaimListingQuery>;
export type GetMyClaimListingLazyQueryHookResult = ReturnType<typeof useGetMyClaimListingLazyQuery>;
export type GetMyClaimListingQueryResult = ApolloReactCommon.QueryResult<GetMyClaimListingQuery, GetMyClaimListingQueryVariables>;
export const ClaimCompanySettingSummaryDocument = gql`
    query ClaimCompanySettingSummary($CompanyID: String!) {
  ClaimCompanySettingSummary(CompanyID: $CompanyID)
}
    `;

/**
 * __useClaimCompanySettingSummaryQuery__
 *
 * To run a query within a React component, call `useClaimCompanySettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimCompanySettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimCompanySettingSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useClaimCompanySettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimCompanySettingSummaryQuery, ClaimCompanySettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimCompanySettingSummaryQuery, ClaimCompanySettingSummaryQueryVariables>(ClaimCompanySettingSummaryDocument, baseOptions);
      }
export function useClaimCompanySettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimCompanySettingSummaryQuery, ClaimCompanySettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimCompanySettingSummaryQuery, ClaimCompanySettingSummaryQueryVariables>(ClaimCompanySettingSummaryDocument, baseOptions);
        }
export type ClaimCompanySettingSummaryQueryHookResult = ReturnType<typeof useClaimCompanySettingSummaryQuery>;
export type ClaimCompanySettingSummaryLazyQueryHookResult = ReturnType<typeof useClaimCompanySettingSummaryLazyQuery>;
export type ClaimCompanySettingSummaryQueryResult = ApolloReactCommon.QueryResult<ClaimCompanySettingSummaryQuery, ClaimCompanySettingSummaryQueryVariables>;
export const GetApprovedClaimVerificationDetailsDocument = gql`
    query GetApprovedClaimVerificationDetails($ClaimHeaderID: String!) {
  GetApprovedClaimVerificationDetails(ClaimHeaderID: $ClaimHeaderID) {
    Subject
    ClaimNo
    ClaimHeaderID
    JobAccountID
    JobAccountName
    HeaderStatus
    EmployeeID
    EmployeeNo
    EmployeeName
    CountClaimDetails
    CountVerifiedClaim
    TotalClaimAmount
    SubmissionDate
    ApprovedClaimDetail {
      ApprovalStatus
      ApprovalRemark
      ApprovalByID
      ApprovalByName
      ApprovalDate
    }
    MyClaimDetails {
      ClaimDetailID
      ClaimTypeID
      ClaimTypeName
      ClaimAmount
      ClaimBalance
      ReceiptDate
      ReceiptNo
      DocumentID
      DocumentName
      Document {
        DocumentID
        DocumentFile
      }
      DocumentIDs {
        Status
        CreatedDT
        CreatedBy
        ModifiedDT
        ModifiedBy
        DocumentID
        DocumentType
        Description
        DocumentFile
        PayPeriodID
        ParentID
        BucketFileName
        FileSize
      }
      Remark
      VerifiedClaimDetail {
        FinanceApprovalStatus
        FinanceAmount
        FinanceRemark
        FinanceApprovalByID
        FinanceApprovalByName
        FinanceApprovalDate
      }
      ClaimDuplicateList {
        EmployeeName
        EmployeeNo
        CompanyName
        ReceiptDate
        Remarks
        DocFile
      }
    }
  }
}
    `;

/**
 * __useGetApprovedClaimVerificationDetailsQuery__
 *
 * To run a query within a React component, call `useGetApprovedClaimVerificationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovedClaimVerificationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovedClaimVerificationDetailsQuery({
 *   variables: {
 *      ClaimHeaderID: // value for 'ClaimHeaderID'
 *   },
 * });
 */
export function useGetApprovedClaimVerificationDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovedClaimVerificationDetailsQuery, GetApprovedClaimVerificationDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovedClaimVerificationDetailsQuery, GetApprovedClaimVerificationDetailsQueryVariables>(GetApprovedClaimVerificationDetailsDocument, baseOptions);
      }
export function useGetApprovedClaimVerificationDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovedClaimVerificationDetailsQuery, GetApprovedClaimVerificationDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovedClaimVerificationDetailsQuery, GetApprovedClaimVerificationDetailsQueryVariables>(GetApprovedClaimVerificationDetailsDocument, baseOptions);
        }
export type GetApprovedClaimVerificationDetailsQueryHookResult = ReturnType<typeof useGetApprovedClaimVerificationDetailsQuery>;
export type GetApprovedClaimVerificationDetailsLazyQueryHookResult = ReturnType<typeof useGetApprovedClaimVerificationDetailsLazyQuery>;
export type GetApprovedClaimVerificationDetailsQueryResult = ApolloReactCommon.QueryResult<GetApprovedClaimVerificationDetailsQuery, GetApprovedClaimVerificationDetailsQueryVariables>;
export const GetApprovedClaimVerificationListingDocument = gql`
    query GetApprovedClaimVerificationListing($input: ApprovalClaimVerificationInput!, $offset: Float, $limit: Float, $Incomplete: Boolean) {
  GetApprovedClaimVerificationListing(input: $input, offset: $offset, limit: $limit, Incomplete: $Incomplete) {
    JobGradeID
    OrgUnitID
    FormStatus
    ClaimHeaderID
    EmployeeID
    EmployeeName
    EmployeeNo
    SubmissionDate
    Subject
    CountClaimDetails
    TotalClaimAmount
    CountVerifiedClaim
    TotalVerifiedClaimAmount
    HeaderStatus
    DetailStatus
    ApprovalDate
    ApprovalName
    FinanceApprovalName
    FinanceApprovalDate
    ClaimNo
    MyClaimDetails {
      ClaimDetailID
      ClaimTypeID
      ClaimTypeName
      ClaimAmount
      ClaimBalance
      ReceiptDate
      ReceiptNo
      DocumentID
      DocumentName
      Remark
    }
    PostedBy
    PostedDate
  }
}
    `;

/**
 * __useGetApprovedClaimVerificationListingQuery__
 *
 * To run a query within a React component, call `useGetApprovedClaimVerificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovedClaimVerificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovedClaimVerificationListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      Incomplete: // value for 'Incomplete'
 *   },
 * });
 */
export function useGetApprovedClaimVerificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovedClaimVerificationListingQuery, GetApprovedClaimVerificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovedClaimVerificationListingQuery, GetApprovedClaimVerificationListingQueryVariables>(GetApprovedClaimVerificationListingDocument, baseOptions);
      }
export function useGetApprovedClaimVerificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovedClaimVerificationListingQuery, GetApprovedClaimVerificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovedClaimVerificationListingQuery, GetApprovedClaimVerificationListingQueryVariables>(GetApprovedClaimVerificationListingDocument, baseOptions);
        }
export type GetApprovedClaimVerificationListingQueryHookResult = ReturnType<typeof useGetApprovedClaimVerificationListingQuery>;
export type GetApprovedClaimVerificationListingLazyQueryHookResult = ReturnType<typeof useGetApprovedClaimVerificationListingLazyQuery>;
export type GetApprovedClaimVerificationListingQueryResult = ApolloReactCommon.QueryResult<GetApprovedClaimVerificationListingQuery, GetApprovedClaimVerificationListingQueryVariables>;
export const GetApprovedClaimCountDocument = gql`
    query GetApprovedClaimCount($input: ApprovalClaimVerificationInput!) {
  GetApprovedClaimCount(input: $input)
}
    `;

/**
 * __useGetApprovedClaimCountQuery__
 *
 * To run a query within a React component, call `useGetApprovedClaimCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovedClaimCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovedClaimCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetApprovedClaimCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovedClaimCountQuery, GetApprovedClaimCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovedClaimCountQuery, GetApprovedClaimCountQueryVariables>(GetApprovedClaimCountDocument, baseOptions);
      }
export function useGetApprovedClaimCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovedClaimCountQuery, GetApprovedClaimCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovedClaimCountQuery, GetApprovedClaimCountQueryVariables>(GetApprovedClaimCountDocument, baseOptions);
        }
export type GetApprovedClaimCountQueryHookResult = ReturnType<typeof useGetApprovedClaimCountQuery>;
export type GetApprovedClaimCountLazyQueryHookResult = ReturnType<typeof useGetApprovedClaimCountLazyQuery>;
export type GetApprovedClaimCountQueryResult = ApolloReactCommon.QueryResult<GetApprovedClaimCountQuery, GetApprovedClaimCountQueryVariables>;
export const VerifyApprovedClaimDetailDocument = gql`
    mutation VerifyApprovedClaimDetail($input: VerifyApprovedClaimDetailInput!) {
  VerifyApprovedClaimDetail(input: $input)
}
    `;
export type VerifyApprovedClaimDetailMutationFn = ApolloReactCommon.MutationFunction<VerifyApprovedClaimDetailMutation, VerifyApprovedClaimDetailMutationVariables>;

/**
 * __useVerifyApprovedClaimDetailMutation__
 *
 * To run a mutation, you first call `useVerifyApprovedClaimDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyApprovedClaimDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyApprovedClaimDetailMutation, { data, loading, error }] = useVerifyApprovedClaimDetailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyApprovedClaimDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyApprovedClaimDetailMutation, VerifyApprovedClaimDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyApprovedClaimDetailMutation, VerifyApprovedClaimDetailMutationVariables>(VerifyApprovedClaimDetailDocument, baseOptions);
      }
export type VerifyApprovedClaimDetailMutationHookResult = ReturnType<typeof useVerifyApprovedClaimDetailMutation>;
export type VerifyApprovedClaimDetailMutationResult = ApolloReactCommon.MutationResult<VerifyApprovedClaimDetailMutation>;
export type VerifyApprovedClaimDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyApprovedClaimDetailMutation, VerifyApprovedClaimDetailMutationVariables>;
export const ApproveClaimRecordsDocument = gql`
    mutation ApproveClaimRecords($input: ApproveClaimRecordsInput!, $IsSupersede: Boolean) {
  ApproveClaimRecords(input: $input, IsSupersede: $IsSupersede)
}
    `;
export type ApproveClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<ApproveClaimRecordsMutation, ApproveClaimRecordsMutationVariables>;

/**
 * __useApproveClaimRecordsMutation__
 *
 * To run a mutation, you first call `useApproveClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveClaimRecordsMutation, { data, loading, error }] = useApproveClaimRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      IsSupersede: // value for 'IsSupersede'
 *   },
 * });
 */
export function useApproveClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveClaimRecordsMutation, ApproveClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveClaimRecordsMutation, ApproveClaimRecordsMutationVariables>(ApproveClaimRecordsDocument, baseOptions);
      }
export type ApproveClaimRecordsMutationHookResult = ReturnType<typeof useApproveClaimRecordsMutation>;
export type ApproveClaimRecordsMutationResult = ApolloReactCommon.MutationResult<ApproveClaimRecordsMutation>;
export type ApproveClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveClaimRecordsMutation, ApproveClaimRecordsMutationVariables>;
export const ReverseApprovedClaimRecordsDocument = gql`
    mutation ReverseApprovedClaimRecords($input: ApproveClaimRecordsInput!) {
  ReverseApprovedClaimRecords(input: $input)
}
    `;
export type ReverseApprovedClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<ReverseApprovedClaimRecordsMutation, ReverseApprovedClaimRecordsMutationVariables>;

/**
 * __useReverseApprovedClaimRecordsMutation__
 *
 * To run a mutation, you first call `useReverseApprovedClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverseApprovedClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverseApprovedClaimRecordsMutation, { data, loading, error }] = useReverseApprovedClaimRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReverseApprovedClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReverseApprovedClaimRecordsMutation, ReverseApprovedClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<ReverseApprovedClaimRecordsMutation, ReverseApprovedClaimRecordsMutationVariables>(ReverseApprovedClaimRecordsDocument, baseOptions);
      }
export type ReverseApprovedClaimRecordsMutationHookResult = ReturnType<typeof useReverseApprovedClaimRecordsMutation>;
export type ReverseApprovedClaimRecordsMutationResult = ApolloReactCommon.MutationResult<ReverseApprovedClaimRecordsMutation>;
export type ReverseApprovedClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReverseApprovedClaimRecordsMutation, ReverseApprovedClaimRecordsMutationVariables>;
export const VerifyMyClaimDocument = gql`
    query VerifyMyClaim($VerfiyClaimInput: VerfiyClaimInput!) {
  VerifyMyClaim(VerfiyClaimInput: $VerfiyClaimInput)
}
    `;

/**
 * __useVerifyMyClaimQuery__
 *
 * To run a query within a React component, call `useVerifyMyClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyMyClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyMyClaimQuery({
 *   variables: {
 *      VerfiyClaimInput: // value for 'VerfiyClaimInput'
 *   },
 * });
 */
export function useVerifyMyClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyMyClaimQuery, VerifyMyClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyMyClaimQuery, VerifyMyClaimQueryVariables>(VerifyMyClaimDocument, baseOptions);
      }
export function useVerifyMyClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyMyClaimQuery, VerifyMyClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyMyClaimQuery, VerifyMyClaimQueryVariables>(VerifyMyClaimDocument, baseOptions);
        }
export type VerifyMyClaimQueryHookResult = ReturnType<typeof useVerifyMyClaimQuery>;
export type VerifyMyClaimLazyQueryHookResult = ReturnType<typeof useVerifyMyClaimLazyQuery>;
export type VerifyMyClaimQueryResult = ApolloReactCommon.QueryResult<VerifyMyClaimQuery, VerifyMyClaimQueryVariables>;
export const ClaimTypeListingDocument = gql`
    query ClaimTypeListing($SubscriptionAccountID: String!, $ClaimCategory: ClaimCategory) {
  ClaimTypeListing(SubscriptionAccountID: $SubscriptionAccountID, ClaimCategory: $ClaimCategory) {
    ClaimTypeID
    SubscriptionAccountID
    Name
    IsAttachment
    IsRemarks
    IsDisplay
    IsReceiptNo
    IsAdditionalFieldsTemplate
    UserDefineFieldsTemplate
    ClaimCategory
    PayItemID
    PayItem {
      PayItemID
      PayName
      PayCode
      PayItemType
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    ClaimPolicies {
      ClaimItemID
      ClaimTypeID
      TotalAmt
      ClaimFrequency
      ClaimBenefit {
        Name
        Description
        SoftDelete
      }
    }
    PaymentMethod
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useClaimTypeListingQuery__
 *
 * To run a query within a React component, call `useClaimTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimTypeListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      ClaimCategory: // value for 'ClaimCategory'
 *   },
 * });
 */
export function useClaimTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimTypeListingQuery, ClaimTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimTypeListingQuery, ClaimTypeListingQueryVariables>(ClaimTypeListingDocument, baseOptions);
      }
export function useClaimTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimTypeListingQuery, ClaimTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimTypeListingQuery, ClaimTypeListingQueryVariables>(ClaimTypeListingDocument, baseOptions);
        }
export type ClaimTypeListingQueryHookResult = ReturnType<typeof useClaimTypeListingQuery>;
export type ClaimTypeListingLazyQueryHookResult = ReturnType<typeof useClaimTypeListingLazyQuery>;
export type ClaimTypeListingQueryResult = ApolloReactCommon.QueryResult<ClaimTypeListingQuery, ClaimTypeListingQueryVariables>;
export const GetEmpClaimListingDocument = gql`
    query GetEmpClaimListing($GetEmpClaimListingInput: [GetEmpClaimListingInput!]!) {
  GetEmpClaimListing(Input: $GetEmpClaimListingInput) {
    ClaimStatus
  }
}
    `;

/**
 * __useGetEmpClaimListingQuery__
 *
 * To run a query within a React component, call `useGetEmpClaimListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmpClaimListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmpClaimListingQuery({
 *   variables: {
 *      GetEmpClaimListingInput: // value for 'GetEmpClaimListingInput'
 *   },
 * });
 */
export function useGetEmpClaimListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmpClaimListingQuery, GetEmpClaimListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmpClaimListingQuery, GetEmpClaimListingQueryVariables>(GetEmpClaimListingDocument, baseOptions);
      }
export function useGetEmpClaimListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmpClaimListingQuery, GetEmpClaimListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmpClaimListingQuery, GetEmpClaimListingQueryVariables>(GetEmpClaimListingDocument, baseOptions);
        }
export type GetEmpClaimListingQueryHookResult = ReturnType<typeof useGetEmpClaimListingQuery>;
export type GetEmpClaimListingLazyQueryHookResult = ReturnType<typeof useGetEmpClaimListingLazyQuery>;
export type GetEmpClaimListingQueryResult = ApolloReactCommon.QueryResult<GetEmpClaimListingQuery, GetEmpClaimListingQueryVariables>;
export const GetMainClaimListingDocument = gql`
    query GetMainClaimListing($EmployeeID: String!, $Year: Float!) {
  GetMainClaimListing(EmployeeID: $EmployeeID, Year: $Year) {
    Year
    Amount
    TotalClaimApproved
  }
}
    `;

/**
 * __useGetMainClaimListingQuery__
 *
 * To run a query within a React component, call `useGetMainClaimListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMainClaimListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMainClaimListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetMainClaimListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMainClaimListingQuery, GetMainClaimListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMainClaimListingQuery, GetMainClaimListingQueryVariables>(GetMainClaimListingDocument, baseOptions);
      }
export function useGetMainClaimListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMainClaimListingQuery, GetMainClaimListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMainClaimListingQuery, GetMainClaimListingQueryVariables>(GetMainClaimListingDocument, baseOptions);
        }
export type GetMainClaimListingQueryHookResult = ReturnType<typeof useGetMainClaimListingQuery>;
export type GetMainClaimListingLazyQueryHookResult = ReturnType<typeof useGetMainClaimListingLazyQuery>;
export type GetMainClaimListingQueryResult = ApolloReactCommon.QueryResult<GetMainClaimListingQuery, GetMainClaimListingQueryVariables>;
export const GetMyClaimEntitlementDocument = gql`
    query GetMyClaimEntitlement($EmployeeID: String!) {
  GetMyClaimEntitlement(EmployeeID: $EmployeeID) {
    Employee {
      EmployeeNo
      JoinedDate
      EmployeeID
    }
    ClaimItems {
      ClaimType {
        Name
      }
    }
  }
}
    `;

/**
 * __useGetMyClaimEntitlementQuery__
 *
 * To run a query within a React component, call `useGetMyClaimEntitlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyClaimEntitlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyClaimEntitlementQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetMyClaimEntitlementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyClaimEntitlementQuery, GetMyClaimEntitlementQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyClaimEntitlementQuery, GetMyClaimEntitlementQueryVariables>(GetMyClaimEntitlementDocument, baseOptions);
      }
export function useGetMyClaimEntitlementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyClaimEntitlementQuery, GetMyClaimEntitlementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyClaimEntitlementQuery, GetMyClaimEntitlementQueryVariables>(GetMyClaimEntitlementDocument, baseOptions);
        }
export type GetMyClaimEntitlementQueryHookResult = ReturnType<typeof useGetMyClaimEntitlementQuery>;
export type GetMyClaimEntitlementLazyQueryHookResult = ReturnType<typeof useGetMyClaimEntitlementLazyQuery>;
export type GetMyClaimEntitlementQueryResult = ApolloReactCommon.QueryResult<GetMyClaimEntitlementQuery, GetMyClaimEntitlementQueryVariables>;
export const GetEmpsClaimBalanceDocument = gql`
    query GetEmpsClaimBalance($input: EmpClaimEntitlementInput!, $isV5: Boolean) {
  GetEmpsClaimBalance(input: $input, isV5: $isV5) {
    Employee {
      EmployeeID
      EmployeeNo
      JoinedDate
    }
    ClaimBalance {
      ClaimItems {
        ClaimItemID
        ClaimType {
          Name
          ClaimTypeID
        }
        TotalAmt
        Occurrence
        OccurrenceLimit
        ClaimFrequency
      }
      Drafted
      PendingSuperior
      PendingFinance
      PendingPayroll
      Posted
      OccurenceBalance
      Balance
      ErrorMessage
    }
  }
}
    `;

/**
 * __useGetEmpsClaimBalanceQuery__
 *
 * To run a query within a React component, call `useGetEmpsClaimBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmpsClaimBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmpsClaimBalanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *      isV5: // value for 'isV5'
 *   },
 * });
 */
export function useGetEmpsClaimBalanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmpsClaimBalanceQuery, GetEmpsClaimBalanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmpsClaimBalanceQuery, GetEmpsClaimBalanceQueryVariables>(GetEmpsClaimBalanceDocument, baseOptions);
      }
export function useGetEmpsClaimBalanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmpsClaimBalanceQuery, GetEmpsClaimBalanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmpsClaimBalanceQuery, GetEmpsClaimBalanceQueryVariables>(GetEmpsClaimBalanceDocument, baseOptions);
        }
export type GetEmpsClaimBalanceQueryHookResult = ReturnType<typeof useGetEmpsClaimBalanceQuery>;
export type GetEmpsClaimBalanceLazyQueryHookResult = ReturnType<typeof useGetEmpsClaimBalanceLazyQuery>;
export type GetEmpsClaimBalanceQueryResult = ApolloReactCommon.QueryResult<GetEmpsClaimBalanceQuery, GetEmpsClaimBalanceQueryVariables>;
export const GetMyClaimPoolBalanceDocument = gql`
    query GetMyClaimPoolBalance($EmployeeID: String!, $AsToDate: DateTime!, $ClaimHeaderID: String, $isV5: Boolean) {
  GetMyClaimPoolBalance(EmployeeID: $EmployeeID, AsToDate: $AsToDate, ClaimHeaderID: $ClaimHeaderID, isV5: $isV5) {
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    ClaimPoolBalance {
      ClaimPool {
        ID
        PoolTitle
        ClaimTypeIDs
        FrequencyCriteria
        CompanyID
        ClaimTypes {
          Name
          ClaimTypeID
        }
      }
      ClaimPoolCondition {
        ID
        ClaimPoolID
        PoolAmountLimit
        PoolFrequency
        ConditionList
      }
      Drafted
      Frequency
      PendingSuperior
      PendingFinance
      PendingPayroll
      Posted
      Balance
      ErrorMessage
    }
  }
}
    `;

/**
 * __useGetMyClaimPoolBalanceQuery__
 *
 * To run a query within a React component, call `useGetMyClaimPoolBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyClaimPoolBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyClaimPoolBalanceQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      AsToDate: // value for 'AsToDate'
 *      ClaimHeaderID: // value for 'ClaimHeaderID'
 *      isV5: // value for 'isV5'
 *   },
 * });
 */
export function useGetMyClaimPoolBalanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyClaimPoolBalanceQuery, GetMyClaimPoolBalanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyClaimPoolBalanceQuery, GetMyClaimPoolBalanceQueryVariables>(GetMyClaimPoolBalanceDocument, baseOptions);
      }
export function useGetMyClaimPoolBalanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyClaimPoolBalanceQuery, GetMyClaimPoolBalanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyClaimPoolBalanceQuery, GetMyClaimPoolBalanceQueryVariables>(GetMyClaimPoolBalanceDocument, baseOptions);
        }
export type GetMyClaimPoolBalanceQueryHookResult = ReturnType<typeof useGetMyClaimPoolBalanceQuery>;
export type GetMyClaimPoolBalanceLazyQueryHookResult = ReturnType<typeof useGetMyClaimPoolBalanceLazyQuery>;
export type GetMyClaimPoolBalanceQueryResult = ApolloReactCommon.QueryResult<GetMyClaimPoolBalanceQuery, GetMyClaimPoolBalanceQueryVariables>;
export const GetMyClaimBalanceDocument = gql`
    query GetMyClaimBalance($EmployeeID: String!, $AsToDate: DateTime!, $ClaimHeaderID: String, $isV5: Boolean) {
  GetMyClaimBalance(EmployeeID: $EmployeeID, AsToDate: $AsToDate, ClaimHeaderID: $ClaimHeaderID, isV5: $isV5) {
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    ClaimBalance {
      ClaimItems {
        ClaimType {
          Name
        }
        ClaimItemID
        ClaimBenefitID
        ClaimTypeID
        ClaimFrequency
        TotalAmt
        Occurrence
        OccurrenceLimit
        IsLimit
        IsRemarks
        IsAttachment
      }
      Drafted
      Frequency
      PendingSuperior
      PendingFinance
      PendingPayroll
      Posted
      Balance
      OccurenceBalance
      ErrorMessage
    }
  }
}
    `;

/**
 * __useGetMyClaimBalanceQuery__
 *
 * To run a query within a React component, call `useGetMyClaimBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyClaimBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyClaimBalanceQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      AsToDate: // value for 'AsToDate'
 *      ClaimHeaderID: // value for 'ClaimHeaderID'
 *      isV5: // value for 'isV5'
 *   },
 * });
 */
export function useGetMyClaimBalanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyClaimBalanceQuery, GetMyClaimBalanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyClaimBalanceQuery, GetMyClaimBalanceQueryVariables>(GetMyClaimBalanceDocument, baseOptions);
      }
export function useGetMyClaimBalanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyClaimBalanceQuery, GetMyClaimBalanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyClaimBalanceQuery, GetMyClaimBalanceQueryVariables>(GetMyClaimBalanceDocument, baseOptions);
        }
export type GetMyClaimBalanceQueryHookResult = ReturnType<typeof useGetMyClaimBalanceQuery>;
export type GetMyClaimBalanceLazyQueryHookResult = ReturnType<typeof useGetMyClaimBalanceLazyQuery>;
export type GetMyClaimBalanceQueryResult = ApolloReactCommon.QueryResult<GetMyClaimBalanceQuery, GetMyClaimBalanceQueryVariables>;
export const GetMyClaimDetailsDocument = gql`
    query GetMyClaimDetails($ClaimHeaderID: String!) {
  GetMyClaimDetails(ClaimHeaderID: $ClaimHeaderID) {
    ClaimHeaderID
    JobAccountID
    Subject
    SubmissionDate
    CountClaimDetails
    TotalClaimAmount
    JobAccountName
    HeaderStatus
    MyClaimDetails {
      ClaimDetailID
      ClaimTypeID
      ClaimTypeName
      ClaimAmount
      ReceiptDate
      ReceiptNo
      DocumentName
      DocumentID
      Remark
      VerifiedClaimDetail {
        FinanceAmount
        FinanceRemark
        FinanceApprovalByID
        FinanceApprovalDate
        FinanceApprovalStatus
        FinanceApprovalByName
      }
    }
  }
}
    `;

/**
 * __useGetMyClaimDetailsQuery__
 *
 * To run a query within a React component, call `useGetMyClaimDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyClaimDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyClaimDetailsQuery({
 *   variables: {
 *      ClaimHeaderID: // value for 'ClaimHeaderID'
 *   },
 * });
 */
export function useGetMyClaimDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyClaimDetailsQuery, GetMyClaimDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyClaimDetailsQuery, GetMyClaimDetailsQueryVariables>(GetMyClaimDetailsDocument, baseOptions);
      }
export function useGetMyClaimDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyClaimDetailsQuery, GetMyClaimDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyClaimDetailsQuery, GetMyClaimDetailsQueryVariables>(GetMyClaimDetailsDocument, baseOptions);
        }
export type GetMyClaimDetailsQueryHookResult = ReturnType<typeof useGetMyClaimDetailsQuery>;
export type GetMyClaimDetailsLazyQueryHookResult = ReturnType<typeof useGetMyClaimDetailsLazyQuery>;
export type GetMyClaimDetailsQueryResult = ApolloReactCommon.QueryResult<GetMyClaimDetailsQuery, GetMyClaimDetailsQueryVariables>;
export const SubmitMyClaimDocument = gql`
    mutation SubmitMyClaim($input: SubmitClaimRecordsInput!, $workFlowStepInput: [WorkFlowStepInput!], $isV5: Boolean) {
  SubmitMyClaim(input: $input, workFlowStepInput: $workFlowStepInput, isV5: $isV5) {
    ClaimHeaderID
    EmployeeID
    CompanyID
    ApprovalStatus
  }
}
    `;
export type SubmitMyClaimMutationFn = ApolloReactCommon.MutationFunction<SubmitMyClaimMutation, SubmitMyClaimMutationVariables>;

/**
 * __useSubmitMyClaimMutation__
 *
 * To run a mutation, you first call `useSubmitMyClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitMyClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitMyClaimMutation, { data, loading, error }] = useSubmitMyClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *      workFlowStepInput: // value for 'workFlowStepInput'
 *      isV5: // value for 'isV5'
 *   },
 * });
 */
export function useSubmitMyClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitMyClaimMutation, SubmitMyClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitMyClaimMutation, SubmitMyClaimMutationVariables>(SubmitMyClaimDocument, baseOptions);
      }
export type SubmitMyClaimMutationHookResult = ReturnType<typeof useSubmitMyClaimMutation>;
export type SubmitMyClaimMutationResult = ApolloReactCommon.MutationResult<SubmitMyClaimMutation>;
export type SubmitMyClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitMyClaimMutation, SubmitMyClaimMutationVariables>;
export const DeleteClaimRecordsDocument = gql`
    mutation DeleteClaimRecords($SubscriptionUserID: String!, $ClaimHeaderIDs: [String!]!) {
  DeleteClaimRecords(SubscriptionUserID: $SubscriptionUserID, ClaimHeaderIDs: $ClaimHeaderIDs)
}
    `;
export type DeleteClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<DeleteClaimRecordsMutation, DeleteClaimRecordsMutationVariables>;

/**
 * __useDeleteClaimRecordsMutation__
 *
 * To run a mutation, you first call `useDeleteClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClaimRecordsMutation, { data, loading, error }] = useDeleteClaimRecordsMutation({
 *   variables: {
 *      SubscriptionUserID: // value for 'SubscriptionUserID'
 *      ClaimHeaderIDs: // value for 'ClaimHeaderIDs'
 *   },
 * });
 */
export function useDeleteClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteClaimRecordsMutation, DeleteClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteClaimRecordsMutation, DeleteClaimRecordsMutationVariables>(DeleteClaimRecordsDocument, baseOptions);
      }
export type DeleteClaimRecordsMutationHookResult = ReturnType<typeof useDeleteClaimRecordsMutation>;
export type DeleteClaimRecordsMutationResult = ApolloReactCommon.MutationResult<DeleteClaimRecordsMutation>;
export type DeleteClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteClaimRecordsMutation, DeleteClaimRecordsMutationVariables>;
export const SubmitClaimForApprovalDocument = gql`
    mutation SubmitClaimForApproval($ClaimHeaderIDs: [String!]!) {
  SubmitClaimForApproval(ClaimHeaderIDs: $ClaimHeaderIDs)
}
    `;
export type SubmitClaimForApprovalMutationFn = ApolloReactCommon.MutationFunction<SubmitClaimForApprovalMutation, SubmitClaimForApprovalMutationVariables>;

/**
 * __useSubmitClaimForApprovalMutation__
 *
 * To run a mutation, you first call `useSubmitClaimForApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitClaimForApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitClaimForApprovalMutation, { data, loading, error }] = useSubmitClaimForApprovalMutation({
 *   variables: {
 *      ClaimHeaderIDs: // value for 'ClaimHeaderIDs'
 *   },
 * });
 */
export function useSubmitClaimForApprovalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitClaimForApprovalMutation, SubmitClaimForApprovalMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitClaimForApprovalMutation, SubmitClaimForApprovalMutationVariables>(SubmitClaimForApprovalDocument, baseOptions);
      }
export type SubmitClaimForApprovalMutationHookResult = ReturnType<typeof useSubmitClaimForApprovalMutation>;
export type SubmitClaimForApprovalMutationResult = ApolloReactCommon.MutationResult<SubmitClaimForApprovalMutation>;
export type SubmitClaimForApprovalMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitClaimForApprovalMutation, SubmitClaimForApprovalMutationVariables>;
export const DeleteApprovedClaimRecordsDocument = gql`
    mutation DeleteApprovedClaimRecords($input: ApproveClaimRecordsInput!, $Remark: String!, $CancelRemark: String!, $IsSupersede: Boolean) {
  DeleteApprovedClaimRecords(input: $input, Remark: $Remark, CancelRemark: $CancelRemark, IsSupersede: $IsSupersede)
}
    `;
export type DeleteApprovedClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovedClaimRecordsMutation, DeleteApprovedClaimRecordsMutationVariables>;

/**
 * __useDeleteApprovedClaimRecordsMutation__
 *
 * To run a mutation, you first call `useDeleteApprovedClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovedClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovedClaimRecordsMutation, { data, loading, error }] = useDeleteApprovedClaimRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      Remark: // value for 'Remark'
 *      CancelRemark: // value for 'CancelRemark'
 *      IsSupersede: // value for 'IsSupersede'
 *   },
 * });
 */
export function useDeleteApprovedClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovedClaimRecordsMutation, DeleteApprovedClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovedClaimRecordsMutation, DeleteApprovedClaimRecordsMutationVariables>(DeleteApprovedClaimRecordsDocument, baseOptions);
      }
export type DeleteApprovedClaimRecordsMutationHookResult = ReturnType<typeof useDeleteApprovedClaimRecordsMutation>;
export type DeleteApprovedClaimRecordsMutationResult = ApolloReactCommon.MutationResult<DeleteApprovedClaimRecordsMutation>;
export type DeleteApprovedClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovedClaimRecordsMutation, DeleteApprovedClaimRecordsMutationVariables>;
export const GetAllExpenseAccountDocument = gql`
    query GetAllExpenseAccount($CompanyID: String!) {
  GetAllExpenseAccount(CompanyID: $CompanyID) {
    Name
    JobAccountID
  }
}
    `;

/**
 * __useGetAllExpenseAccountQuery__
 *
 * To run a query within a React component, call `useGetAllExpenseAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllExpenseAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllExpenseAccountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetAllExpenseAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllExpenseAccountQuery, GetAllExpenseAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllExpenseAccountQuery, GetAllExpenseAccountQueryVariables>(GetAllExpenseAccountDocument, baseOptions);
      }
export function useGetAllExpenseAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllExpenseAccountQuery, GetAllExpenseAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllExpenseAccountQuery, GetAllExpenseAccountQueryVariables>(GetAllExpenseAccountDocument, baseOptions);
        }
export type GetAllExpenseAccountQueryHookResult = ReturnType<typeof useGetAllExpenseAccountQuery>;
export type GetAllExpenseAccountLazyQueryHookResult = ReturnType<typeof useGetAllExpenseAccountLazyQuery>;
export type GetAllExpenseAccountQueryResult = ApolloReactCommon.QueryResult<GetAllExpenseAccountQuery, GetAllExpenseAccountQueryVariables>;
export const ClaimListingDocument = gql`
    query ClaimListing($EmployeeID: String!) {
  ClaimListing(EmployeeID: $EmployeeID) {
    ClaimHeaderID
    Subject
    ApprovalStatus
    ClaimDetails {
      ClaimDetailsID
      ClaimAmount
      DocumentID
    }
    CreatedDT
  }
}
    `;

/**
 * __useClaimListingQuery__
 *
 * To run a query within a React component, call `useClaimListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useClaimListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimListingQuery, ClaimListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimListingQuery, ClaimListingQueryVariables>(ClaimListingDocument, baseOptions);
      }
export function useClaimListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimListingQuery, ClaimListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimListingQuery, ClaimListingQueryVariables>(ClaimListingDocument, baseOptions);
        }
export type ClaimListingQueryHookResult = ReturnType<typeof useClaimListingQuery>;
export type ClaimListingLazyQueryHookResult = ReturnType<typeof useClaimListingLazyQuery>;
export type ClaimListingQueryResult = ApolloReactCommon.QueryResult<ClaimListingQuery, ClaimListingQueryVariables>;
export const JobGradePolicyListingDocument = gql`
    query JobGradePolicyListing($JobGradeID: String, $CompanyID: String!) {
  JobGradePolicyListing(JobGradeID: $JobGradeID, CompanyID: $CompanyID) {
    JobGradePolicyID
    CompanyID
    JobGradeID
    Company {
      CompanyName
    }
    JobGrade {
      Description
    }
    ClaimTemplates {
      ClaimPolicyID
      ClaimItem {
        ClaimType {
          Name
          ClaimTypeID
          ClaimCategory
          IsRemarks
          IsAttachment
          IsReceiptNo
        }
        Status
        CreatedDT
        CreatedBy
        ModifiedDT
        ModifiedBy
        ClaimItemID
        ClaimBenefitID
        ClaimTypeID
        ClaimFrequency
        TotalAmt
        Occurrence
        OccurrenceLimit
        MileageTableID
        IsMileageTable
        RateDetails {
          ClaimBenefitID
          ClaimDailyRateID
          ClaimParameterID
          Rate
        }
        VehicleRateDetails {
          ClaimVehicleRateID
          ClaimParameterID
          ClaimBenefitID
          StartValue
          EndValue
          Rate
          Status
        }
        MealRateDetails {
          ClaimMealRateID
          ClaimParameterID
          VenueID
          ClaimBenefitID
          Rate
          Status
        }
        IsLimit
        IsRemarks
        IsAttachment
      }
    }
    LeaveTemplates {
      LeaveTemplateID
      JobGradePolicyID
      LeavePolicyID
      LeaveItem {
        Title
        LeaveItemID
        LeaveTypeID
        LeaveType {
          Name
          LeaveTypeID
          LeaveTypeCode
        }
        TotalDay
        LeaveFrequency
        CutOffDate
        Occurrence
        NoOccurrence
        AvailableBasis
        CalculationMethod
        NewCutOffDay
        ResignCutOffDay
        MonthlyCutOffDay
        ShortNoticeType
        ShortNoticePeriod
        CFMethod
        CFValue
        CFMonth
        IsOffDay
        IsRestDay
        IsPublicHoliday
        IsHalfDay
        IsAttachment
        IsRemarks
        IsRounding
        IsConfirmedStaff
        IsDisplayEmployee
        IsAdvanceLeave
        IsLeaveReason
        IsLeaveDisease
      }
    }
  }
}
    `;

/**
 * __useJobGradePolicyListingQuery__
 *
 * To run a query within a React component, call `useJobGradePolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobGradePolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobGradePolicyListingQuery({
 *   variables: {
 *      JobGradeID: // value for 'JobGradeID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useJobGradePolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobGradePolicyListingQuery, JobGradePolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobGradePolicyListingQuery, JobGradePolicyListingQueryVariables>(JobGradePolicyListingDocument, baseOptions);
      }
export function useJobGradePolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobGradePolicyListingQuery, JobGradePolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobGradePolicyListingQuery, JobGradePolicyListingQueryVariables>(JobGradePolicyListingDocument, baseOptions);
        }
export type JobGradePolicyListingQueryHookResult = ReturnType<typeof useJobGradePolicyListingQuery>;
export type JobGradePolicyListingLazyQueryHookResult = ReturnType<typeof useJobGradePolicyListingLazyQuery>;
export type JobGradePolicyListingQueryResult = ApolloReactCommon.QueryResult<JobGradePolicyListingQuery, JobGradePolicyListingQueryVariables>;
export const GetClaimRecordsListingDocument = gql`
    query GetClaimRecordsListing($input: ClaimRecordsInput!, $superiorRejectToDraft: Boolean, $offset: Float, $limit: Float) {
  GetClaimRecordsListing(input: $input, superiorRejectToDraft: $superiorRejectToDraft, offset: $offset, limit: $limit) {
    ClaimHeaderID
    EmployeeID
    EmployeeNo
    EmployeeName
    SubmissionDate
    Subject
    ClaimNo
    JobGradeID
    OrgUnitID
    CountClaimDetails
    TotalClaimAmount
    HeaderStatus
    DetailStatus
    CountVerifiedClaim
    TotalVerifiedClaimAmount
    CountClaimHistory
    ApprovalDate
    CompletedVerificationDate
    CompletedVerificationBy
    ApprovalName
    SubmitterName
    Remark
    ApprovalStatus
    ApproverID
    IsRejected
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        NotifiedDT
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
    DocumentIDs {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      DocumentID
      DocumentType
      Description
      DocumentFile
      PayPeriodID
      ParentID
      BucketFileName
      FileSize
    }
  }
}
    `;

/**
 * __useGetClaimRecordsListingQuery__
 *
 * To run a query within a React component, call `useGetClaimRecordsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimRecordsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimRecordsListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      superiorRejectToDraft: // value for 'superiorRejectToDraft'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetClaimRecordsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimRecordsListingQuery, GetClaimRecordsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimRecordsListingQuery, GetClaimRecordsListingQueryVariables>(GetClaimRecordsListingDocument, baseOptions);
      }
export function useGetClaimRecordsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimRecordsListingQuery, GetClaimRecordsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimRecordsListingQuery, GetClaimRecordsListingQueryVariables>(GetClaimRecordsListingDocument, baseOptions);
        }
export type GetClaimRecordsListingQueryHookResult = ReturnType<typeof useGetClaimRecordsListingQuery>;
export type GetClaimRecordsListingLazyQueryHookResult = ReturnType<typeof useGetClaimRecordsListingLazyQuery>;
export type GetClaimRecordsListingQueryResult = ApolloReactCommon.QueryResult<GetClaimRecordsListingQuery, GetClaimRecordsListingQueryVariables>;
export const GetClaimRecordsTotalCountDocument = gql`
    query GetClaimRecordsTotalCount($input: ClaimRecordsInput!) {
  GetClaimRecordsTotalCount(input: $input)
}
    `;

/**
 * __useGetClaimRecordsTotalCountQuery__
 *
 * To run a query within a React component, call `useGetClaimRecordsTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimRecordsTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimRecordsTotalCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClaimRecordsTotalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimRecordsTotalCountQuery, GetClaimRecordsTotalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimRecordsTotalCountQuery, GetClaimRecordsTotalCountQueryVariables>(GetClaimRecordsTotalCountDocument, baseOptions);
      }
export function useGetClaimRecordsTotalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimRecordsTotalCountQuery, GetClaimRecordsTotalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimRecordsTotalCountQuery, GetClaimRecordsTotalCountQueryVariables>(GetClaimRecordsTotalCountDocument, baseOptions);
        }
export type GetClaimRecordsTotalCountQueryHookResult = ReturnType<typeof useGetClaimRecordsTotalCountQuery>;
export type GetClaimRecordsTotalCountLazyQueryHookResult = ReturnType<typeof useGetClaimRecordsTotalCountLazyQuery>;
export type GetClaimRecordsTotalCountQueryResult = ApolloReactCommon.QueryResult<GetClaimRecordsTotalCountQuery, GetClaimRecordsTotalCountQueryVariables>;
export const GetClaimRecordsDetailsDocument = gql`
    query GetClaimRecordsDetails($ClaimHeaderID: String!) {
  GetClaimRecordsDetails(ClaimHeaderID: $ClaimHeaderID) {
    EmployeeID
    EmployeeName
    EmployeeNo
    ClaimNo
    JobAccountName
    SubmissionDate
    Subject
    CountClaimDetails
    TotalClaimAmount
    ApprovalStatus
    ClaimRecordsDetail {
      ClaimDetailsID
      ClaimTypeID
      ClaimTypeName
      ClaimTypePaymentMethod
      ClaimAmount
      VerifiedAmount
      FinanceApproveStatus
      FinanceRemarks
      FinanceApproveDate
      FinanceApproveBy
      ReceiptDate
      ReceiptNo
      PostedDate
      ExpenseAccount
      LocationFrom
      LocationTo
      MealStartDatetime
      MealEndDatetime
      OutstationStartDate
      OutstationEndDate
      VehicleTypeID
      Venue
      Rate
      ClaimMileageID
      DocumentID
      DocumentIDs {
        Status
        CreatedDT
        CreatedBy
        ModifiedDT
        ModifiedBy
        DocumentID
        DocumentType
        Description
        DocumentFile
        PayPeriodID
        ParentID
        BucketFileName
        FileSize
      }
      DocumentName
      Document {
        Status
        CreatedDT
        CreatedBy
        ModifiedDT
        ModifiedBy
        DocumentID
        DocumentType
        Description
        DocumentFile
        PayPeriodID
        ParentID
        BucketFileName
        FileSize
      }
      ClaimRemarks
    }
  }
}
    `;

/**
 * __useGetClaimRecordsDetailsQuery__
 *
 * To run a query within a React component, call `useGetClaimRecordsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimRecordsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimRecordsDetailsQuery({
 *   variables: {
 *      ClaimHeaderID: // value for 'ClaimHeaderID'
 *   },
 * });
 */
export function useGetClaimRecordsDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimRecordsDetailsQuery, GetClaimRecordsDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimRecordsDetailsQuery, GetClaimRecordsDetailsQueryVariables>(GetClaimRecordsDetailsDocument, baseOptions);
      }
export function useGetClaimRecordsDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimRecordsDetailsQuery, GetClaimRecordsDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimRecordsDetailsQuery, GetClaimRecordsDetailsQueryVariables>(GetClaimRecordsDetailsDocument, baseOptions);
        }
export type GetClaimRecordsDetailsQueryHookResult = ReturnType<typeof useGetClaimRecordsDetailsQuery>;
export type GetClaimRecordsDetailsLazyQueryHookResult = ReturnType<typeof useGetClaimRecordsDetailsLazyQuery>;
export type GetClaimRecordsDetailsQueryResult = ApolloReactCommon.QueryResult<GetClaimRecordsDetailsQuery, GetClaimRecordsDetailsQueryVariables>;
export const GetClaimApprovalHistoryDocument = gql`
    query GetClaimApprovalHistory($ApproverID: String!) {
  GetClaimApprovalHistory(ApproverID: $ApproverID) {
    ApprovalDate
    ApproverName
    ApprovalStatus
    ClaimHeaderID
    CreatedDT
    EmployeeID
    EmployeeName
    EmployeeNo
    Subject
    TotalClaim
    TotalClaimAmount
    IsRejected
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        NotifiedDT
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetClaimApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useGetClaimApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimApprovalHistoryQuery({
 *   variables: {
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useGetClaimApprovalHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimApprovalHistoryQuery, GetClaimApprovalHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimApprovalHistoryQuery, GetClaimApprovalHistoryQueryVariables>(GetClaimApprovalHistoryDocument, baseOptions);
      }
export function useGetClaimApprovalHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimApprovalHistoryQuery, GetClaimApprovalHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimApprovalHistoryQuery, GetClaimApprovalHistoryQueryVariables>(GetClaimApprovalHistoryDocument, baseOptions);
        }
export type GetClaimApprovalHistoryQueryHookResult = ReturnType<typeof useGetClaimApprovalHistoryQuery>;
export type GetClaimApprovalHistoryLazyQueryHookResult = ReturnType<typeof useGetClaimApprovalHistoryLazyQuery>;
export type GetClaimApprovalHistoryQueryResult = ApolloReactCommon.QueryResult<GetClaimApprovalHistoryQuery, GetClaimApprovalHistoryQueryVariables>;
export const ClaimParameterListingDocument = gql`
    query ClaimParameterListing($ClaimParameterType: String!, $CompanyID: String) {
  ClaimParameterListing(ClaimParameterType: $ClaimParameterType, CompanyID: $CompanyID) {
    ClaimParameterID
    Name
    CompanyID
    ClaimParameterType
    ModifiedBy
    ModifiedDT
    CreatedBy
    CreatedDT
    Status
    StartTime
    CompanyID
    EndTime
    Details {
      ClaimParameterID
      ClaimMileageTableID
      TravelFrom
      TravelTo
      Route
      Distance
    }
  }
}
    `;

/**
 * __useClaimParameterListingQuery__
 *
 * To run a query within a React component, call `useClaimParameterListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimParameterListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimParameterListingQuery({
 *   variables: {
 *      ClaimParameterType: // value for 'ClaimParameterType'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useClaimParameterListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimParameterListingQuery, ClaimParameterListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimParameterListingQuery, ClaimParameterListingQueryVariables>(ClaimParameterListingDocument, baseOptions);
      }
export function useClaimParameterListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimParameterListingQuery, ClaimParameterListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimParameterListingQuery, ClaimParameterListingQueryVariables>(ClaimParameterListingDocument, baseOptions);
        }
export type ClaimParameterListingQueryHookResult = ReturnType<typeof useClaimParameterListingQuery>;
export type ClaimParameterListingLazyQueryHookResult = ReturnType<typeof useClaimParameterListingLazyQuery>;
export type ClaimParameterListingQueryResult = ApolloReactCommon.QueryResult<ClaimParameterListingQuery, ClaimParameterListingQueryVariables>;
export const ApproverListByCompanyDocument = gql`
    query ApproverListByCompany($EmployeeID: String!, $CompanyID: String!, $SubscriptionAccountID: String!) {
  ApproverListByCompany(EmployeeID: $EmployeeID, CompanyID: $CompanyID, SubscriptionAccountID: $SubscriptionAccountID)
}
    `;

/**
 * __useApproverListByCompanyQuery__
 *
 * To run a query within a React component, call `useApproverListByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useApproverListByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApproverListByCompanyQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyID: // value for 'CompanyID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useApproverListByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApproverListByCompanyQuery, ApproverListByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<ApproverListByCompanyQuery, ApproverListByCompanyQueryVariables>(ApproverListByCompanyDocument, baseOptions);
      }
export function useApproverListByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApproverListByCompanyQuery, ApproverListByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApproverListByCompanyQuery, ApproverListByCompanyQueryVariables>(ApproverListByCompanyDocument, baseOptions);
        }
export type ApproverListByCompanyQueryHookResult = ReturnType<typeof useApproverListByCompanyQuery>;
export type ApproverListByCompanyLazyQueryHookResult = ReturnType<typeof useApproverListByCompanyLazyQuery>;
export type ApproverListByCompanyQueryResult = ApolloReactCommon.QueryResult<ApproverListByCompanyQuery, ApproverListByCompanyQueryVariables>;
export const IsClaimSubjectExistsDocument = gql`
    query IsClaimSubjectExists($Subject: String!, $input: ClaimRecordsInput!, $ClaimHeaderID: String) {
  IsClaimSubjectExists(Subject: $Subject, input: $input, ClaimHeaderID: $ClaimHeaderID)
}
    `;

/**
 * __useIsClaimSubjectExistsQuery__
 *
 * To run a query within a React component, call `useIsClaimSubjectExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsClaimSubjectExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsClaimSubjectExistsQuery({
 *   variables: {
 *      Subject: // value for 'Subject'
 *      input: // value for 'input'
 *      ClaimHeaderID: // value for 'ClaimHeaderID'
 *   },
 * });
 */
export function useIsClaimSubjectExistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsClaimSubjectExistsQuery, IsClaimSubjectExistsQueryVariables>) {
        return ApolloReactHooks.useQuery<IsClaimSubjectExistsQuery, IsClaimSubjectExistsQueryVariables>(IsClaimSubjectExistsDocument, baseOptions);
      }
export function useIsClaimSubjectExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsClaimSubjectExistsQuery, IsClaimSubjectExistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsClaimSubjectExistsQuery, IsClaimSubjectExistsQueryVariables>(IsClaimSubjectExistsDocument, baseOptions);
        }
export type IsClaimSubjectExistsQueryHookResult = ReturnType<typeof useIsClaimSubjectExistsQuery>;
export type IsClaimSubjectExistsLazyQueryHookResult = ReturnType<typeof useIsClaimSubjectExistsLazyQuery>;
export type IsClaimSubjectExistsQueryResult = ApolloReactCommon.QueryResult<IsClaimSubjectExistsQuery, IsClaimSubjectExistsQueryVariables>;
export const GetClaimReportDetailsDocument = gql`
    query GetClaimReportDetails($ClaimHeaderID: String!) {
  GetClaimReportDetails(ClaimHeaderID: $ClaimHeaderID)
}
    `;

/**
 * __useGetClaimReportDetailsQuery__
 *
 * To run a query within a React component, call `useGetClaimReportDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimReportDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimReportDetailsQuery({
 *   variables: {
 *      ClaimHeaderID: // value for 'ClaimHeaderID'
 *   },
 * });
 */
export function useGetClaimReportDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimReportDetailsQuery, GetClaimReportDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimReportDetailsQuery, GetClaimReportDetailsQueryVariables>(GetClaimReportDetailsDocument, baseOptions);
      }
export function useGetClaimReportDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimReportDetailsQuery, GetClaimReportDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimReportDetailsQuery, GetClaimReportDetailsQueryVariables>(GetClaimReportDetailsDocument, baseOptions);
        }
export type GetClaimReportDetailsQueryHookResult = ReturnType<typeof useGetClaimReportDetailsQuery>;
export type GetClaimReportDetailsLazyQueryHookResult = ReturnType<typeof useGetClaimReportDetailsLazyQuery>;
export type GetClaimReportDetailsQueryResult = ApolloReactCommon.QueryResult<GetClaimReportDetailsQuery, GetClaimReportDetailsQueryVariables>;
export const DataChangesInsertDocument = gql`
    mutation DataChangesInsert($DataChangesInput: DataChangesInput!, $DocumentsInput: DocumentsInput) {
  DataChangesInsert(input: $DataChangesInput, DocumentsInput: $DocumentsInput) {
    DataChangesID
    ApprovalStatus
  }
}
    `;
export type DataChangesInsertMutationFn = ApolloReactCommon.MutationFunction<DataChangesInsertMutation, DataChangesInsertMutationVariables>;

/**
 * __useDataChangesInsertMutation__
 *
 * To run a mutation, you first call `useDataChangesInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDataChangesInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dataChangesInsertMutation, { data, loading, error }] = useDataChangesInsertMutation({
 *   variables: {
 *      DataChangesInput: // value for 'DataChangesInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useDataChangesInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DataChangesInsertMutation, DataChangesInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DataChangesInsertMutation, DataChangesInsertMutationVariables>(DataChangesInsertDocument, baseOptions);
      }
export type DataChangesInsertMutationHookResult = ReturnType<typeof useDataChangesInsertMutation>;
export type DataChangesInsertMutationResult = ApolloReactCommon.MutationResult<DataChangesInsertMutation>;
export type DataChangesInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DataChangesInsertMutation, DataChangesInsertMutationVariables>;
export const GetDataChangesListingDocument = gql`
    query getDataChangesListing($EmployeeID: String, $CompanyID: String!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  getDataChangesListing(EmployeeID: $EmployeeID, CompanyID: $CompanyID, EmployeeType: $EmployeeType, EmployeeStatus: $EmployeeStatus, OrgUnitID: $OrgUnitID) {
    DataChangesID
    Contact {
      ContactID
      FullName
    }
    Employee {
      EmployeeID
      EmployeeNo
    }
    Approver {
      Contact {
        ContactID
        FullName
      }
    }
    CompanyID
    Type
    ChangesData {
      DocumentID
      Nationality
      PassportNo
      NRIC
      Marital
      Race
      Religion
      IsOKU
      EmployeeBank
      EmployeeBankNo
      EmployeeEPFNo
      EmployeePCBNo
      PermanentAddress {
        Line1
        Line2
        City
        PostalCode
        State
        Country
      }
      Address {
        Line1
        Line2
        City
        PostalCode
        State
        Country
      }
      EmployeeDependentsID
      ContactID
      IsEmergencyContact
      MobileNo
      Occupation
      Education
      ChildEligibleRate
      PCBNo
      FullName
      BirthDate
      SameAddress
    }
    OldData {
      DocumentID
      Nationality
      PassportNo
      NRIC
      Marital
      Race
      Religion
      IsOKU
      EmployeeBank
      EmployeeBankNo
      EmployeeEPFNo
      EmployeePCBNo
      PermanentAddress {
        Line1
        Line2
        City
        PostalCode
        State
        Country
      }
      Address {
        Line1
        Line2
        City
        PostalCode
        State
        Country
      }
      EmployeeDependentsID
      ContactID
      IsEmergencyContact
      MobileNo
      Occupation
      Education
      ChildEligibleRate
      PCBNo
      FullName
      BirthDate
    }
    ApprovalStatus
    ApprovalDT
    ApproverID
    CreatedDT
    ModifiedDT
    Remarks
    Description
    Action
    Status
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        NotifiedDT
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetDataChangesListingQuery__
 *
 * To run a query within a React component, call `useGetDataChangesListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataChangesListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataChangesListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useGetDataChangesListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDataChangesListingQuery, GetDataChangesListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDataChangesListingQuery, GetDataChangesListingQueryVariables>(GetDataChangesListingDocument, baseOptions);
      }
export function useGetDataChangesListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDataChangesListingQuery, GetDataChangesListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDataChangesListingQuery, GetDataChangesListingQueryVariables>(GetDataChangesListingDocument, baseOptions);
        }
export type GetDataChangesListingQueryHookResult = ReturnType<typeof useGetDataChangesListingQuery>;
export type GetDataChangesListingLazyQueryHookResult = ReturnType<typeof useGetDataChangesListingLazyQuery>;
export type GetDataChangesListingQueryResult = ApolloReactCommon.QueryResult<GetDataChangesListingQuery, GetDataChangesListingQueryVariables>;
export const SubmitDataChangesDocument = gql`
    mutation SubmitDataChanges($DataChangesInput: DataChangesInput!) {
  SubmitDataChanges(input: $DataChangesInput)
}
    `;
export type SubmitDataChangesMutationFn = ApolloReactCommon.MutationFunction<SubmitDataChangesMutation, SubmitDataChangesMutationVariables>;

/**
 * __useSubmitDataChangesMutation__
 *
 * To run a mutation, you first call `useSubmitDataChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDataChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDataChangesMutation, { data, loading, error }] = useSubmitDataChangesMutation({
 *   variables: {
 *      DataChangesInput: // value for 'DataChangesInput'
 *   },
 * });
 */
export function useSubmitDataChangesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitDataChangesMutation, SubmitDataChangesMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitDataChangesMutation, SubmitDataChangesMutationVariables>(SubmitDataChangesDocument, baseOptions);
      }
export type SubmitDataChangesMutationHookResult = ReturnType<typeof useSubmitDataChangesMutation>;
export type SubmitDataChangesMutationResult = ApolloReactCommon.MutationResult<SubmitDataChangesMutation>;
export type SubmitDataChangesMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitDataChangesMutation, SubmitDataChangesMutationVariables>;
export const DeleteDataChangesDocument = gql`
    mutation DeleteDataChanges($DataChangesInput: DataChangesInput!) {
  DeleteDataChanges(input: $DataChangesInput)
}
    `;
export type DeleteDataChangesMutationFn = ApolloReactCommon.MutationFunction<DeleteDataChangesMutation, DeleteDataChangesMutationVariables>;

/**
 * __useDeleteDataChangesMutation__
 *
 * To run a mutation, you first call `useDeleteDataChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataChangesMutation, { data, loading, error }] = useDeleteDataChangesMutation({
 *   variables: {
 *      DataChangesInput: // value for 'DataChangesInput'
 *   },
 * });
 */
export function useDeleteDataChangesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDataChangesMutation, DeleteDataChangesMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDataChangesMutation, DeleteDataChangesMutationVariables>(DeleteDataChangesDocument, baseOptions);
      }
export type DeleteDataChangesMutationHookResult = ReturnType<typeof useDeleteDataChangesMutation>;
export type DeleteDataChangesMutationResult = ApolloReactCommon.MutationResult<DeleteDataChangesMutation>;
export type DeleteDataChangesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDataChangesMutation, DeleteDataChangesMutationVariables>;
export const CancelDataChangesDocument = gql`
    mutation CancelDataChanges($DataChangesInput: DataChangesInput!) {
  CancelDataChanges(input: $DataChangesInput)
}
    `;
export type CancelDataChangesMutationFn = ApolloReactCommon.MutationFunction<CancelDataChangesMutation, CancelDataChangesMutationVariables>;

/**
 * __useCancelDataChangesMutation__
 *
 * To run a mutation, you first call `useCancelDataChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDataChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDataChangesMutation, { data, loading, error }] = useCancelDataChangesMutation({
 *   variables: {
 *      DataChangesInput: // value for 'DataChangesInput'
 *   },
 * });
 */
export function useCancelDataChangesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelDataChangesMutation, CancelDataChangesMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelDataChangesMutation, CancelDataChangesMutationVariables>(CancelDataChangesDocument, baseOptions);
      }
export type CancelDataChangesMutationHookResult = ReturnType<typeof useCancelDataChangesMutation>;
export type CancelDataChangesMutationResult = ApolloReactCommon.MutationResult<CancelDataChangesMutation>;
export type CancelDataChangesMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelDataChangesMutation, CancelDataChangesMutationVariables>;
export const GetDocumentDocument = gql`
    query getDocument($DocumentID: String!) {
  getDocument(DocumentID: $DocumentID) {
    DocumentID
    DocumentFile
    Description
  }
}
    `;

/**
 * __useGetDocumentQuery__
 *
 * To run a query within a React component, call `useGetDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentQuery({
 *   variables: {
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useGetDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, baseOptions);
      }
export function useGetDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, baseOptions);
        }
export type GetDocumentQueryHookResult = ReturnType<typeof useGetDocumentQuery>;
export type GetDocumentLazyQueryHookResult = ReturnType<typeof useGetDocumentLazyQuery>;
export type GetDocumentQueryResult = ApolloReactCommon.QueryResult<GetDocumentQuery, GetDocumentQueryVariables>;
export const EmployeeDependentsSummaryDocument = gql`
    query EmployeeDependentsSummary($EmployeeDependentsID: String!) {
  EmployeeDependentsSummary(EmployeeDependentsID: $EmployeeDependentsID) {
    EmployeeDependentsID
    Relationship
    Education
    Contact {
      ContactID
      FullName
      MobileNo
      NRIC
      PassportNo
      Nationality
      BirthDate
    }
    IsOKU
    ChildEligibleRate
    Occupation
    IsEmergencyContact
    PCBNo
    DocumentID
  }
}
    `;

/**
 * __useEmployeeDependentsSummaryQuery__
 *
 * To run a query within a React component, call `useEmployeeDependentsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDependentsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDependentsSummaryQuery({
 *   variables: {
 *      EmployeeDependentsID: // value for 'EmployeeDependentsID'
 *   },
 * });
 */
export function useEmployeeDependentsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeDependentsSummaryQuery, EmployeeDependentsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeDependentsSummaryQuery, EmployeeDependentsSummaryQueryVariables>(EmployeeDependentsSummaryDocument, baseOptions);
      }
export function useEmployeeDependentsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeDependentsSummaryQuery, EmployeeDependentsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeDependentsSummaryQuery, EmployeeDependentsSummaryQueryVariables>(EmployeeDependentsSummaryDocument, baseOptions);
        }
export type EmployeeDependentsSummaryQueryHookResult = ReturnType<typeof useEmployeeDependentsSummaryQuery>;
export type EmployeeDependentsSummaryLazyQueryHookResult = ReturnType<typeof useEmployeeDependentsSummaryLazyQuery>;
export type EmployeeDependentsSummaryQueryResult = ApolloReactCommon.QueryResult<EmployeeDependentsSummaryQuery, EmployeeDependentsSummaryQueryVariables>;
export const ApproveDataChangesDocument = gql`
    mutation ApproveDataChanges($DataChangesInput: DataChangesInput!) {
  ApproveDataChanges(input: $DataChangesInput)
}
    `;
export type ApproveDataChangesMutationFn = ApolloReactCommon.MutationFunction<ApproveDataChangesMutation, ApproveDataChangesMutationVariables>;

/**
 * __useApproveDataChangesMutation__
 *
 * To run a mutation, you first call `useApproveDataChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveDataChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveDataChangesMutation, { data, loading, error }] = useApproveDataChangesMutation({
 *   variables: {
 *      DataChangesInput: // value for 'DataChangesInput'
 *   },
 * });
 */
export function useApproveDataChangesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveDataChangesMutation, ApproveDataChangesMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveDataChangesMutation, ApproveDataChangesMutationVariables>(ApproveDataChangesDocument, baseOptions);
      }
export type ApproveDataChangesMutationHookResult = ReturnType<typeof useApproveDataChangesMutation>;
export type ApproveDataChangesMutationResult = ApolloReactCommon.MutationResult<ApproveDataChangesMutation>;
export type ApproveDataChangesMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveDataChangesMutation, ApproveDataChangesMutationVariables>;
export const RejectDataChangesDocument = gql`
    mutation RejectDataChanges($DataChangesInput: DataChangesInput!) {
  RejectDataChanges(input: $DataChangesInput)
}
    `;
export type RejectDataChangesMutationFn = ApolloReactCommon.MutationFunction<RejectDataChangesMutation, RejectDataChangesMutationVariables>;

/**
 * __useRejectDataChangesMutation__
 *
 * To run a mutation, you first call `useRejectDataChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectDataChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectDataChangesMutation, { data, loading, error }] = useRejectDataChangesMutation({
 *   variables: {
 *      DataChangesInput: // value for 'DataChangesInput'
 *   },
 * });
 */
export function useRejectDataChangesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectDataChangesMutation, RejectDataChangesMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectDataChangesMutation, RejectDataChangesMutationVariables>(RejectDataChangesDocument, baseOptions);
      }
export type RejectDataChangesMutationHookResult = ReturnType<typeof useRejectDataChangesMutation>;
export type RejectDataChangesMutationResult = ApolloReactCommon.MutationResult<RejectDataChangesMutation>;
export type RejectDataChangesMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectDataChangesMutation, RejectDataChangesMutationVariables>;
export const DataChangesApprovalDocument = gql`
    mutation DataChangesApproval($input: [DataChangesInput!]!, $UpdateBy: String!) {
  DataChangesApproval(input: $input, UpdateBy: $UpdateBy)
}
    `;
export type DataChangesApprovalMutationFn = ApolloReactCommon.MutationFunction<DataChangesApprovalMutation, DataChangesApprovalMutationVariables>;

/**
 * __useDataChangesApprovalMutation__
 *
 * To run a mutation, you first call `useDataChangesApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDataChangesApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dataChangesApprovalMutation, { data, loading, error }] = useDataChangesApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *      UpdateBy: // value for 'UpdateBy'
 *   },
 * });
 */
export function useDataChangesApprovalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DataChangesApprovalMutation, DataChangesApprovalMutationVariables>) {
        return ApolloReactHooks.useMutation<DataChangesApprovalMutation, DataChangesApprovalMutationVariables>(DataChangesApprovalDocument, baseOptions);
      }
export type DataChangesApprovalMutationHookResult = ReturnType<typeof useDataChangesApprovalMutation>;
export type DataChangesApprovalMutationResult = ApolloReactCommon.MutationResult<DataChangesApprovalMutation>;
export type DataChangesApprovalMutationOptions = ApolloReactCommon.BaseMutationOptions<DataChangesApprovalMutation, DataChangesApprovalMutationVariables>;
export const DataChangesRejectDocument = gql`
    mutation DataChangesReject($input: [DataChangesInput!]!, $UpdateBy: String!) {
  DataChangesReject(input: $input, UpdateBy: $UpdateBy)
}
    `;
export type DataChangesRejectMutationFn = ApolloReactCommon.MutationFunction<DataChangesRejectMutation, DataChangesRejectMutationVariables>;

/**
 * __useDataChangesRejectMutation__
 *
 * To run a mutation, you first call `useDataChangesRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDataChangesRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dataChangesRejectMutation, { data, loading, error }] = useDataChangesRejectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      UpdateBy: // value for 'UpdateBy'
 *   },
 * });
 */
export function useDataChangesRejectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DataChangesRejectMutation, DataChangesRejectMutationVariables>) {
        return ApolloReactHooks.useMutation<DataChangesRejectMutation, DataChangesRejectMutationVariables>(DataChangesRejectDocument, baseOptions);
      }
export type DataChangesRejectMutationHookResult = ReturnType<typeof useDataChangesRejectMutation>;
export type DataChangesRejectMutationResult = ApolloReactCommon.MutationResult<DataChangesRejectMutation>;
export type DataChangesRejectMutationOptions = ApolloReactCommon.BaseMutationOptions<DataChangesRejectMutation, DataChangesRejectMutationVariables>;
export const SelectedDataChangesApprovalPolicyDocument = gql`
    query SelectedDataChangesApprovalPolicy($CompanyID: String!) {
  SelectedDataChangesApprovalPolicy(CompanyID: $CompanyID)
}
    `;

/**
 * __useSelectedDataChangesApprovalPolicyQuery__
 *
 * To run a query within a React component, call `useSelectedDataChangesApprovalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedDataChangesApprovalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedDataChangesApprovalPolicyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useSelectedDataChangesApprovalPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectedDataChangesApprovalPolicyQuery, SelectedDataChangesApprovalPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectedDataChangesApprovalPolicyQuery, SelectedDataChangesApprovalPolicyQueryVariables>(SelectedDataChangesApprovalPolicyDocument, baseOptions);
      }
export function useSelectedDataChangesApprovalPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectedDataChangesApprovalPolicyQuery, SelectedDataChangesApprovalPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectedDataChangesApprovalPolicyQuery, SelectedDataChangesApprovalPolicyQueryVariables>(SelectedDataChangesApprovalPolicyDocument, baseOptions);
        }
export type SelectedDataChangesApprovalPolicyQueryHookResult = ReturnType<typeof useSelectedDataChangesApprovalPolicyQuery>;
export type SelectedDataChangesApprovalPolicyLazyQueryHookResult = ReturnType<typeof useSelectedDataChangesApprovalPolicyLazyQuery>;
export type SelectedDataChangesApprovalPolicyQueryResult = ApolloReactCommon.QueryResult<SelectedDataChangesApprovalPolicyQuery, SelectedDataChangesApprovalPolicyQueryVariables>;
export const NewsListingDocument = gql`
    query NewsListing($SubscriptionAccountID: String!, $IsPublishedOnly: Boolean!, $StartMonth: String!, $EndMonth: String!, $offset: Float, $limit: Float, $isValid: Boolean) {
  NewsListing(SubscriptionAccountID: $SubscriptionAccountID, IsPublishedOnly: $IsPublishedOnly, StartMonth: $StartMonth, EndMonth: $EndMonth, offset: $offset, limit: $limit, isValid: $isValid) {
    NewsID
    SubscriptionAccountID
    Title
    Content
    Documents {
      DocumentID
      DocumentFile
      DocumentType
      Description
      BucketFileName
    }
    Contact {
      ContactID
      FullName
    }
    PublishedBy
    ValidFrom
    ValidTo
    Acknowledgement
    AcknowledgementStatement
    PostStatus
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ContentUrl
  }
}
    `;

/**
 * __useNewsListingQuery__
 *
 * To run a query within a React component, call `useNewsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      IsPublishedOnly: // value for 'IsPublishedOnly'
 *      StartMonth: // value for 'StartMonth'
 *      EndMonth: // value for 'EndMonth'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      isValid: // value for 'isValid'
 *   },
 * });
 */
export function useNewsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsListingQuery, NewsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsListingQuery, NewsListingQueryVariables>(NewsListingDocument, baseOptions);
      }
export function useNewsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsListingQuery, NewsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsListingQuery, NewsListingQueryVariables>(NewsListingDocument, baseOptions);
        }
export type NewsListingQueryHookResult = ReturnType<typeof useNewsListingQuery>;
export type NewsListingLazyQueryHookResult = ReturnType<typeof useNewsListingLazyQuery>;
export type NewsListingQueryResult = ApolloReactCommon.QueryResult<NewsListingQuery, NewsListingQueryVariables>;
export const NewsCountDocument = gql`
    query NewsCount($SubscriptionAccountID: String!, $IsPublishedOnly: Boolean!, $StartMonth: String!, $EndMonth: String!, $isValid: Boolean) {
  NewsCount(SubscriptionAccountID: $SubscriptionAccountID, IsPublishedOnly: $IsPublishedOnly, StartMonth: $StartMonth, EndMonth: $EndMonth, isValid: $isValid)
}
    `;

/**
 * __useNewsCountQuery__
 *
 * To run a query within a React component, call `useNewsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsCountQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      IsPublishedOnly: // value for 'IsPublishedOnly'
 *      StartMonth: // value for 'StartMonth'
 *      EndMonth: // value for 'EndMonth'
 *      isValid: // value for 'isValid'
 *   },
 * });
 */
export function useNewsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsCountQuery, NewsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsCountQuery, NewsCountQueryVariables>(NewsCountDocument, baseOptions);
      }
export function useNewsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsCountQuery, NewsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsCountQuery, NewsCountQueryVariables>(NewsCountDocument, baseOptions);
        }
export type NewsCountQueryHookResult = ReturnType<typeof useNewsCountQuery>;
export type NewsCountLazyQueryHookResult = ReturnType<typeof useNewsCountLazyQuery>;
export type NewsCountQueryResult = ApolloReactCommon.QueryResult<NewsCountQuery, NewsCountQueryVariables>;
export const NewsDeleteDocument = gql`
    mutation NewsDelete($NewsID: String!, $DocumentID: String) {
  NewsDelete(NewsID: $NewsID, DocumentID: $DocumentID)
}
    `;
export type NewsDeleteMutationFn = ApolloReactCommon.MutationFunction<NewsDeleteMutation, NewsDeleteMutationVariables>;

/**
 * __useNewsDeleteMutation__
 *
 * To run a mutation, you first call `useNewsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsDeleteMutation, { data, loading, error }] = useNewsDeleteMutation({
 *   variables: {
 *      NewsID: // value for 'NewsID'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useNewsDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewsDeleteMutation, NewsDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<NewsDeleteMutation, NewsDeleteMutationVariables>(NewsDeleteDocument, baseOptions);
      }
export type NewsDeleteMutationHookResult = ReturnType<typeof useNewsDeleteMutation>;
export type NewsDeleteMutationResult = ApolloReactCommon.MutationResult<NewsDeleteMutation>;
export type NewsDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<NewsDeleteMutation, NewsDeleteMutationVariables>;
export const NewsUpdateDocument = gql`
    mutation NewsUpdate($NewsID: String!, $DocumentID: String!, $NewsInput: NewsInput!, $DocumentsInput: DocumentsInput!, $ContentURL: Upload) {
  NewsUpdate(NewsID: $NewsID, DocumentID: $DocumentID, NewsInput: $NewsInput, DocumentsInput: $DocumentsInput, ContentURL: $ContentURL)
}
    `;
export type NewsUpdateMutationFn = ApolloReactCommon.MutationFunction<NewsUpdateMutation, NewsUpdateMutationVariables>;

/**
 * __useNewsUpdateMutation__
 *
 * To run a mutation, you first call `useNewsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsUpdateMutation, { data, loading, error }] = useNewsUpdateMutation({
 *   variables: {
 *      NewsID: // value for 'NewsID'
 *      DocumentID: // value for 'DocumentID'
 *      NewsInput: // value for 'NewsInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      ContentURL: // value for 'ContentURL'
 *   },
 * });
 */
export function useNewsUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewsUpdateMutation, NewsUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<NewsUpdateMutation, NewsUpdateMutationVariables>(NewsUpdateDocument, baseOptions);
      }
export type NewsUpdateMutationHookResult = ReturnType<typeof useNewsUpdateMutation>;
export type NewsUpdateMutationResult = ApolloReactCommon.MutationResult<NewsUpdateMutation>;
export type NewsUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<NewsUpdateMutation, NewsUpdateMutationVariables>;
export const NewsInsertDocument = gql`
    mutation NewsInsert($NewsInput: NewsInput!, $DocumentsInput: DocumentsInput!, $ContentURL: Upload) {
  NewsInsert(NewsInput: $NewsInput, DocumentsInput: $DocumentsInput, ContentURL: $ContentURL)
}
    `;
export type NewsInsertMutationFn = ApolloReactCommon.MutationFunction<NewsInsertMutation, NewsInsertMutationVariables>;

/**
 * __useNewsInsertMutation__
 *
 * To run a mutation, you first call `useNewsInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsInsertMutation, { data, loading, error }] = useNewsInsertMutation({
 *   variables: {
 *      NewsInput: // value for 'NewsInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      ContentURL: // value for 'ContentURL'
 *   },
 * });
 */
export function useNewsInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewsInsertMutation, NewsInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<NewsInsertMutation, NewsInsertMutationVariables>(NewsInsertDocument, baseOptions);
      }
export type NewsInsertMutationHookResult = ReturnType<typeof useNewsInsertMutation>;
export type NewsInsertMutationResult = ApolloReactCommon.MutationResult<NewsInsertMutation>;
export type NewsInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<NewsInsertMutation, NewsInsertMutationVariables>;
export const DocumentListingDocument = gql`
    query DocumentListing($ParentID: String, $DocumentType: DocumentType!, $ParentIDs: [String!]) {
  DocumentListing(ParentID: $ParentID, DocumentType: $DocumentType, ParentIDs: $ParentIDs) {
    DocumentID
    Description
    DocumentType
    DocumentFile
    BucketFileName
    Content
    FileSize
    ParentID
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useDocumentListingQuery__
 *
 * To run a query within a React component, call `useDocumentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListingQuery({
 *   variables: {
 *      ParentID: // value for 'ParentID'
 *      DocumentType: // value for 'DocumentType'
 *      ParentIDs: // value for 'ParentIDs'
 *   },
 * });
 */
export function useDocumentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
      }
export function useDocumentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
        }
export type DocumentListingQueryHookResult = ReturnType<typeof useDocumentListingQuery>;
export type DocumentListingLazyQueryHookResult = ReturnType<typeof useDocumentListingLazyQuery>;
export type DocumentListingQueryResult = ApolloReactCommon.QueryResult<DocumentListingQuery, DocumentListingQueryVariables>;
export const DocumentDeleteDocument = gql`
    mutation DocumentDelete($DocumentID: String!) {
  DocumentDelete(DocumentID: $DocumentID)
}
    `;
export type DocumentDeleteMutationFn = ApolloReactCommon.MutationFunction<DocumentDeleteMutation, DocumentDeleteMutationVariables>;

/**
 * __useDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentDeleteMutation, { data, loading, error }] = useDocumentDeleteMutation({
 *   variables: {
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useDocumentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentDeleteMutation, DocumentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentDeleteMutation, DocumentDeleteMutationVariables>(DocumentDeleteDocument, baseOptions);
      }
export type DocumentDeleteMutationHookResult = ReturnType<typeof useDocumentDeleteMutation>;
export type DocumentDeleteMutationResult = ApolloReactCommon.MutationResult<DocumentDeleteMutation>;
export type DocumentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentDeleteMutation, DocumentDeleteMutationVariables>;
export const CompanyDocumentDeleteDocument = gql`
    mutation CompanyDocumentDelete($DocumentID: String!) {
  CompanyDocumentDelete(DocumentID: $DocumentID)
}
    `;
export type CompanyDocumentDeleteMutationFn = ApolloReactCommon.MutationFunction<CompanyDocumentDeleteMutation, CompanyDocumentDeleteMutationVariables>;

/**
 * __useCompanyDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useCompanyDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyDocumentDeleteMutation, { data, loading, error }] = useCompanyDocumentDeleteMutation({
 *   variables: {
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useCompanyDocumentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyDocumentDeleteMutation, CompanyDocumentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyDocumentDeleteMutation, CompanyDocumentDeleteMutationVariables>(CompanyDocumentDeleteDocument, baseOptions);
      }
export type CompanyDocumentDeleteMutationHookResult = ReturnType<typeof useCompanyDocumentDeleteMutation>;
export type CompanyDocumentDeleteMutationResult = ApolloReactCommon.MutationResult<CompanyDocumentDeleteMutation>;
export type CompanyDocumentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyDocumentDeleteMutation, CompanyDocumentDeleteMutationVariables>;
export const CompanyDocumentInsertDocument = gql`
    mutation CompanyDocumentInsert($DocumentsInput: DocumentsInput!) {
  CompanyDocumentInsert(DocumentsInput: $DocumentsInput) {
    DocumentID
  }
}
    `;
export type CompanyDocumentInsertMutationFn = ApolloReactCommon.MutationFunction<CompanyDocumentInsertMutation, CompanyDocumentInsertMutationVariables>;

/**
 * __useCompanyDocumentInsertMutation__
 *
 * To run a mutation, you first call `useCompanyDocumentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyDocumentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyDocumentInsertMutation, { data, loading, error }] = useCompanyDocumentInsertMutation({
 *   variables: {
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useCompanyDocumentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyDocumentInsertMutation, CompanyDocumentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyDocumentInsertMutation, CompanyDocumentInsertMutationVariables>(CompanyDocumentInsertDocument, baseOptions);
      }
export type CompanyDocumentInsertMutationHookResult = ReturnType<typeof useCompanyDocumentInsertMutation>;
export type CompanyDocumentInsertMutationResult = ApolloReactCommon.MutationResult<CompanyDocumentInsertMutation>;
export type CompanyDocumentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyDocumentInsertMutation, CompanyDocumentInsertMutationVariables>;
export const DocumentInsertDocument = gql`
    mutation DocumentInsert($DocumentsInput: DocumentsInput!) {
  DocumentInsert(DocumentsInput: $DocumentsInput) {
    DocumentID
  }
}
    `;
export type DocumentInsertMutationFn = ApolloReactCommon.MutationFunction<DocumentInsertMutation, DocumentInsertMutationVariables>;

/**
 * __useDocumentInsertMutation__
 *
 * To run a mutation, you first call `useDocumentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentInsertMutation, { data, loading, error }] = useDocumentInsertMutation({
 *   variables: {
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useDocumentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentInsertMutation, DocumentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentInsertMutation, DocumentInsertMutationVariables>(DocumentInsertDocument, baseOptions);
      }
export type DocumentInsertMutationHookResult = ReturnType<typeof useDocumentInsertMutation>;
export type DocumentInsertMutationResult = ApolloReactCommon.MutationResult<DocumentInsertMutation>;
export type DocumentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentInsertMutation, DocumentInsertMutationVariables>;
export const PublishPaySlipListingByEmployeeDocument = gql`
    query PublishPaySlipListingByEmployee($EmployeeID: String!, $Year: Float!, $CompanyID: String!) {
  PublishPaySlipListingByEmployee(EmployeeID: $EmployeeID, Year: $Year, CompanyID: $CompanyID) {
    PublishPaySlipID
    CompanyID
    PublishedDate
    IsPublish
    PayrollCycleID
    PayPeriodID
    PayPeriod {
      PayPeriodID
      PeriodYearMonth
      FromDate
      ToDate
      ActualWorkDays
    }
    PayrollCycle {
      PayrollCycleID
      Description
    }
    Remarks
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __usePublishPaySlipListingByEmployeeQuery__
 *
 * To run a query within a React component, call `usePublishPaySlipListingByEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishPaySlipListingByEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishPaySlipListingByEmployeeQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      Year: // value for 'Year'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePublishPaySlipListingByEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublishPaySlipListingByEmployeeQuery, PublishPaySlipListingByEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<PublishPaySlipListingByEmployeeQuery, PublishPaySlipListingByEmployeeQueryVariables>(PublishPaySlipListingByEmployeeDocument, baseOptions);
      }
export function usePublishPaySlipListingByEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublishPaySlipListingByEmployeeQuery, PublishPaySlipListingByEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublishPaySlipListingByEmployeeQuery, PublishPaySlipListingByEmployeeQueryVariables>(PublishPaySlipListingByEmployeeDocument, baseOptions);
        }
export type PublishPaySlipListingByEmployeeQueryHookResult = ReturnType<typeof usePublishPaySlipListingByEmployeeQuery>;
export type PublishPaySlipListingByEmployeeLazyQueryHookResult = ReturnType<typeof usePublishPaySlipListingByEmployeeLazyQuery>;
export type PublishPaySlipListingByEmployeeQueryResult = ApolloReactCommon.QueryResult<PublishPaySlipListingByEmployeeQuery, PublishPaySlipListingByEmployeeQueryVariables>;
export const PublishPaySlipListingDocument = gql`
    query PublishPaySlipListing($CompanyID: String, $Year: Float!, $CompanyIDs: [String!]) {
  PublishPaySlipListing(CompanyID: $CompanyID, Year: $Year, CompanyIDs: $CompanyIDs) {
    PublishPaySlipID
    CompanyID
    PublishedDate
    IsPublish
    PayrollCycleID
    PayPeriodID
    PayPeriod {
      PayPeriodID
      PeriodYearMonth
      FromDate
      ToDate
      ActualWorkDays
    }
    PayrollCycle {
      PayrollCycleID
      Description
    }
    Remarks
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __usePublishPaySlipListingQuery__
 *
 * To run a query within a React component, call `usePublishPaySlipListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishPaySlipListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishPaySlipListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *      CompanyIDs: // value for 'CompanyIDs'
 *   },
 * });
 */
export function usePublishPaySlipListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublishPaySlipListingQuery, PublishPaySlipListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PublishPaySlipListingQuery, PublishPaySlipListingQueryVariables>(PublishPaySlipListingDocument, baseOptions);
      }
export function usePublishPaySlipListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublishPaySlipListingQuery, PublishPaySlipListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublishPaySlipListingQuery, PublishPaySlipListingQueryVariables>(PublishPaySlipListingDocument, baseOptions);
        }
export type PublishPaySlipListingQueryHookResult = ReturnType<typeof usePublishPaySlipListingQuery>;
export type PublishPaySlipListingLazyQueryHookResult = ReturnType<typeof usePublishPaySlipListingLazyQuery>;
export type PublishPaySlipListingQueryResult = ApolloReactCommon.QueryResult<PublishPaySlipListingQuery, PublishPaySlipListingQueryVariables>;
export const PublishPaySlipDocument = gql`
    mutation PublishPaySlip($PublishPaySlipID: String!) {
  PublishPaySlip(PublishPaySlipID: $PublishPaySlipID)
}
    `;
export type PublishPaySlipMutationFn = ApolloReactCommon.MutationFunction<PublishPaySlipMutation, PublishPaySlipMutationVariables>;

/**
 * __usePublishPaySlipMutation__
 *
 * To run a mutation, you first call `usePublishPaySlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPaySlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPaySlipMutation, { data, loading, error }] = usePublishPaySlipMutation({
 *   variables: {
 *      PublishPaySlipID: // value for 'PublishPaySlipID'
 *   },
 * });
 */
export function usePublishPaySlipMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishPaySlipMutation, PublishPaySlipMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishPaySlipMutation, PublishPaySlipMutationVariables>(PublishPaySlipDocument, baseOptions);
      }
export type PublishPaySlipMutationHookResult = ReturnType<typeof usePublishPaySlipMutation>;
export type PublishPaySlipMutationResult = ApolloReactCommon.MutationResult<PublishPaySlipMutation>;
export type PublishPaySlipMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishPaySlipMutation, PublishPaySlipMutationVariables>;
export const PublishEaListingDocument = gql`
    query PublishEAListing($CompanyID: String, $CompanyIDs: [String!]) {
  PublishEAListing(CompanyID: $CompanyID, CompanyIDs: $CompanyIDs) {
    PublishEAID
    CompanyID
    PeriodYear
    PublishedDate
    IsPublish
    Remarks
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __usePublishEaListingQuery__
 *
 * To run a query within a React component, call `usePublishEaListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishEaListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishEaListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CompanyIDs: // value for 'CompanyIDs'
 *   },
 * });
 */
export function usePublishEaListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublishEaListingQuery, PublishEaListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PublishEaListingQuery, PublishEaListingQueryVariables>(PublishEaListingDocument, baseOptions);
      }
export function usePublishEaListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublishEaListingQuery, PublishEaListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublishEaListingQuery, PublishEaListingQueryVariables>(PublishEaListingDocument, baseOptions);
        }
export type PublishEaListingQueryHookResult = ReturnType<typeof usePublishEaListingQuery>;
export type PublishEaListingLazyQueryHookResult = ReturnType<typeof usePublishEaListingLazyQuery>;
export type PublishEaListingQueryResult = ApolloReactCommon.QueryResult<PublishEaListingQuery, PublishEaListingQueryVariables>;
export const PublishEaListingByEmployeeDocument = gql`
    query PublishEAListingByEmployee($CompanyID: String!, $EmployeeID: String!) {
  PublishEAListingByEmployee(CompanyID: $CompanyID, EmployeeID: $EmployeeID)
}
    `;

/**
 * __usePublishEaListingByEmployeeQuery__
 *
 * To run a query within a React component, call `usePublishEaListingByEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishEaListingByEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishEaListingByEmployeeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function usePublishEaListingByEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublishEaListingByEmployeeQuery, PublishEaListingByEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<PublishEaListingByEmployeeQuery, PublishEaListingByEmployeeQueryVariables>(PublishEaListingByEmployeeDocument, baseOptions);
      }
export function usePublishEaListingByEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublishEaListingByEmployeeQuery, PublishEaListingByEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublishEaListingByEmployeeQuery, PublishEaListingByEmployeeQueryVariables>(PublishEaListingByEmployeeDocument, baseOptions);
        }
export type PublishEaListingByEmployeeQueryHookResult = ReturnType<typeof usePublishEaListingByEmployeeQuery>;
export type PublishEaListingByEmployeeLazyQueryHookResult = ReturnType<typeof usePublishEaListingByEmployeeLazyQuery>;
export type PublishEaListingByEmployeeQueryResult = ApolloReactCommon.QueryResult<PublishEaListingByEmployeeQuery, PublishEaListingByEmployeeQueryVariables>;
export const PublishEaDocument = gql`
    mutation PublishEA($PublishEAID: String!) {
  PublishEA(PublishEAID: $PublishEAID)
}
    `;
export type PublishEaMutationFn = ApolloReactCommon.MutationFunction<PublishEaMutation, PublishEaMutationVariables>;

/**
 * __usePublishEaMutation__
 *
 * To run a mutation, you first call `usePublishEaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEaMutation, { data, loading, error }] = usePublishEaMutation({
 *   variables: {
 *      PublishEAID: // value for 'PublishEAID'
 *   },
 * });
 */
export function usePublishEaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishEaMutation, PublishEaMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishEaMutation, PublishEaMutationVariables>(PublishEaDocument, baseOptions);
      }
export type PublishEaMutationHookResult = ReturnType<typeof usePublishEaMutation>;
export type PublishEaMutationResult = ApolloReactCommon.MutationResult<PublishEaMutation>;
export type PublishEaMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishEaMutation, PublishEaMutationVariables>;
export const RequisitionInsertDocument = gql`
    mutation RequisitionInsert($RequisitionInput: RequisitionInput!, $DocumentsInput: [DocumentsInput!]) {
  RequisitionInsert(input: $RequisitionInput, DocumentsInput: $DocumentsInput) {
    RequisitionHeaderID
    ApprovalStatus
  }
}
    `;
export type RequisitionInsertMutationFn = ApolloReactCommon.MutationFunction<RequisitionInsertMutation, RequisitionInsertMutationVariables>;

/**
 * __useRequisitionInsertMutation__
 *
 * To run a mutation, you first call `useRequisitionInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionInsertMutation, { data, loading, error }] = useRequisitionInsertMutation({
 *   variables: {
 *      RequisitionInput: // value for 'RequisitionInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useRequisitionInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequisitionInsertMutation, RequisitionInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RequisitionInsertMutation, RequisitionInsertMutationVariables>(RequisitionInsertDocument, baseOptions);
      }
export type RequisitionInsertMutationHookResult = ReturnType<typeof useRequisitionInsertMutation>;
export type RequisitionInsertMutationResult = ApolloReactCommon.MutationResult<RequisitionInsertMutation>;
export type RequisitionInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RequisitionInsertMutation, RequisitionInsertMutationVariables>;
export const FilterEmployeeRequisitionListingDocument = gql`
    query FilterEmployeeRequisitionListing($EmployeeID: String!, $CompanyID: String!, $RequisitionType: [String!]!, $StartDate: String!, $EndDate: String!, $ApprovalStatus: [String!]!, $EmployeeIDs: [String!], $CompanyIDs: [String!]) {
  FilterEmployeeRequisitionListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID, RequisitionType: $RequisitionType, StartDate: $StartDate, EndDate: $EndDate, ApprovalStatus: $ApprovalStatus, EmployeeIDs: $EmployeeIDs, CompanyIDs: $CompanyIDs) {
    RequisitionHeaderID
    CompanyID
    EmployeeID
    ApprovedBy
    Employee {
      ReportTo
      EmployeeNo
      Contact {
        FullName
      }
    }
    SubmitterName
    RequisitionType
    TravelRequestID
    Subject
    Remark
    TravelReqNo
    CashReqNo
    OtherReqNo
    AssetReqNo
    NumberOfItem
    CostPerItem
    Amount
    StartDate
    EndDate
    TravelFrom
    TravelTo
    TransportMode
    PurposeOfTravel
    ApprovalStatus
    ApprovalDT
    ApproverID
    Documents {
      DocumentID
      DocumentFile
      Description
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    MultipleDocuments {
      DocumentID
      DocumentFile
      DocumentType
      Description
    }
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        NotifiedDT
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useFilterEmployeeRequisitionListingQuery__
 *
 * To run a query within a React component, call `useFilterEmployeeRequisitionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterEmployeeRequisitionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterEmployeeRequisitionListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyID: // value for 'CompanyID'
 *      RequisitionType: // value for 'RequisitionType'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      CompanyIDs: // value for 'CompanyIDs'
 *   },
 * });
 */
export function useFilterEmployeeRequisitionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FilterEmployeeRequisitionListingQuery, FilterEmployeeRequisitionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FilterEmployeeRequisitionListingQuery, FilterEmployeeRequisitionListingQueryVariables>(FilterEmployeeRequisitionListingDocument, baseOptions);
      }
export function useFilterEmployeeRequisitionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FilterEmployeeRequisitionListingQuery, FilterEmployeeRequisitionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FilterEmployeeRequisitionListingQuery, FilterEmployeeRequisitionListingQueryVariables>(FilterEmployeeRequisitionListingDocument, baseOptions);
        }
export type FilterEmployeeRequisitionListingQueryHookResult = ReturnType<typeof useFilterEmployeeRequisitionListingQuery>;
export type FilterEmployeeRequisitionListingLazyQueryHookResult = ReturnType<typeof useFilterEmployeeRequisitionListingLazyQuery>;
export type FilterEmployeeRequisitionListingQueryResult = ApolloReactCommon.QueryResult<FilterEmployeeRequisitionListingQuery, FilterEmployeeRequisitionListingQueryVariables>;
export const EmployeeRequisitionListingDocument = gql`
    query EmployeeRequisitionListing($EmployeeID: String!) {
  EmployeeRequisitionListing(EmployeeID: $EmployeeID) {
    RequisitionHeaderID
    CompanyID
    EmployeeID
    Employee {
      Contact {
        FullName
      }
    }
    RequisitionType
    TravelRequestID
    Subject
    Amount
    Remark
    NumberOfItem
    CostPerItem
    Amount
    TravelReqNo
    CashReqNo
    OtherReqNo
    AssetReqNo
    StartDate
    EndDate
    TravelFrom
    TravelTo
    TransportMode
    PurposeOfTravel
    ApprovalStatus
    ApprovalDT
    ApproverID
    Documents {
      DocumentID
      DocumentFile
      DocumentType
      Description
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useEmployeeRequisitionListingQuery__
 *
 * To run a query within a React component, call `useEmployeeRequisitionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeRequisitionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeRequisitionListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeRequisitionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeRequisitionListingQuery, EmployeeRequisitionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeRequisitionListingQuery, EmployeeRequisitionListingQueryVariables>(EmployeeRequisitionListingDocument, baseOptions);
      }
export function useEmployeeRequisitionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeRequisitionListingQuery, EmployeeRequisitionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeRequisitionListingQuery, EmployeeRequisitionListingQueryVariables>(EmployeeRequisitionListingDocument, baseOptions);
        }
export type EmployeeRequisitionListingQueryHookResult = ReturnType<typeof useEmployeeRequisitionListingQuery>;
export type EmployeeRequisitionListingLazyQueryHookResult = ReturnType<typeof useEmployeeRequisitionListingLazyQuery>;
export type EmployeeRequisitionListingQueryResult = ApolloReactCommon.QueryResult<EmployeeRequisitionListingQuery, EmployeeRequisitionListingQueryVariables>;
export const RequisitionDeleteDocument = gql`
    mutation RequisitionDelete($RequisitionHeaderID: String!, $DocumentID: String) {
  RequisitionDelete(RequisitionHeaderID: $RequisitionHeaderID, DocumentID: $DocumentID)
}
    `;
export type RequisitionDeleteMutationFn = ApolloReactCommon.MutationFunction<RequisitionDeleteMutation, RequisitionDeleteMutationVariables>;

/**
 * __useRequisitionDeleteMutation__
 *
 * To run a mutation, you first call `useRequisitionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionDeleteMutation, { data, loading, error }] = useRequisitionDeleteMutation({
 *   variables: {
 *      RequisitionHeaderID: // value for 'RequisitionHeaderID'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useRequisitionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequisitionDeleteMutation, RequisitionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RequisitionDeleteMutation, RequisitionDeleteMutationVariables>(RequisitionDeleteDocument, baseOptions);
      }
export type RequisitionDeleteMutationHookResult = ReturnType<typeof useRequisitionDeleteMutation>;
export type RequisitionDeleteMutationResult = ApolloReactCommon.MutationResult<RequisitionDeleteMutation>;
export type RequisitionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RequisitionDeleteMutation, RequisitionDeleteMutationVariables>;
export const RequisitionSubmitDocument = gql`
    mutation RequisitionSubmit($RequisitionHeaderID: String!) {
  RequisitionSubmit(RequisitionHeaderID: $RequisitionHeaderID)
}
    `;
export type RequisitionSubmitMutationFn = ApolloReactCommon.MutationFunction<RequisitionSubmitMutation, RequisitionSubmitMutationVariables>;

/**
 * __useRequisitionSubmitMutation__
 *
 * To run a mutation, you first call `useRequisitionSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionSubmitMutation, { data, loading, error }] = useRequisitionSubmitMutation({
 *   variables: {
 *      RequisitionHeaderID: // value for 'RequisitionHeaderID'
 *   },
 * });
 */
export function useRequisitionSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequisitionSubmitMutation, RequisitionSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<RequisitionSubmitMutation, RequisitionSubmitMutationVariables>(RequisitionSubmitDocument, baseOptions);
      }
export type RequisitionSubmitMutationHookResult = ReturnType<typeof useRequisitionSubmitMutation>;
export type RequisitionSubmitMutationResult = ApolloReactCommon.MutationResult<RequisitionSubmitMutation>;
export type RequisitionSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<RequisitionSubmitMutation, RequisitionSubmitMutationVariables>;
export const RequisitionUpdateDocument = gql`
    mutation RequisitionUpdate($RequisitionInput: RequisitionInput!, $DocumentsInput: [DocumentsInput!], $IsRemovedAttachment: Boolean) {
  RequisitionUpdate(RequisitionInput: $RequisitionInput, DocumentsInput: $DocumentsInput, IsRemovedAttachment: $IsRemovedAttachment)
}
    `;
export type RequisitionUpdateMutationFn = ApolloReactCommon.MutationFunction<RequisitionUpdateMutation, RequisitionUpdateMutationVariables>;

/**
 * __useRequisitionUpdateMutation__
 *
 * To run a mutation, you first call `useRequisitionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionUpdateMutation, { data, loading, error }] = useRequisitionUpdateMutation({
 *   variables: {
 *      RequisitionInput: // value for 'RequisitionInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      IsRemovedAttachment: // value for 'IsRemovedAttachment'
 *   },
 * });
 */
export function useRequisitionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequisitionUpdateMutation, RequisitionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RequisitionUpdateMutation, RequisitionUpdateMutationVariables>(RequisitionUpdateDocument, baseOptions);
      }
export type RequisitionUpdateMutationHookResult = ReturnType<typeof useRequisitionUpdateMutation>;
export type RequisitionUpdateMutationResult = ApolloReactCommon.MutationResult<RequisitionUpdateMutation>;
export type RequisitionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RequisitionUpdateMutation, RequisitionUpdateMutationVariables>;
export const FilterRequisitionListingDocument = gql`
    query FilterRequisitionListing($EmployeeID: String!, $Year: Float!, $CompanyID: String!, $EndDate: String!, $StartDate: String!, $RequisitionType: [String!]!) {
  FilterRequisitionListing(EmployeeID: $EmployeeID, Year: $Year, CompanyID: $CompanyID, EndDate: $EndDate, StartDate: $StartDate, RequisitionType: $RequisitionType) {
    RequisitionType
    Remark
    Subject
    PurposeOfTravel
    TravelReqNo
    CashReqNo
    OtherReqNo
    AssetReqNo
    Subject
    Remark
    NumberOfItem
    CostPerItem
    Amount
    StartDate
    EndDate
    TravelTo
    TravelFrom
    PurposeOfTravel
    ApprovalStatus
    RequisitionType
    DocumentID
    EmployeeID
    CreatedDT
    RequisitionHeaderID
    TravelRequestID
    TransportMode
    ApproverID
    ApprovalDT
    Documents {
      DocumentID
      DocumentFile
      DocumentType
      Description
    }
  }
}
    `;

/**
 * __useFilterRequisitionListingQuery__
 *
 * To run a query within a React component, call `useFilterRequisitionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterRequisitionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterRequisitionListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      Year: // value for 'Year'
 *      CompanyID: // value for 'CompanyID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      RequisitionType: // value for 'RequisitionType'
 *   },
 * });
 */
export function useFilterRequisitionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FilterRequisitionListingQuery, FilterRequisitionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FilterRequisitionListingQuery, FilterRequisitionListingQueryVariables>(FilterRequisitionListingDocument, baseOptions);
      }
export function useFilterRequisitionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FilterRequisitionListingQuery, FilterRequisitionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FilterRequisitionListingQuery, FilterRequisitionListingQueryVariables>(FilterRequisitionListingDocument, baseOptions);
        }
export type FilterRequisitionListingQueryHookResult = ReturnType<typeof useFilterRequisitionListingQuery>;
export type FilterRequisitionListingLazyQueryHookResult = ReturnType<typeof useFilterRequisitionListingLazyQuery>;
export type FilterRequisitionListingQueryResult = ApolloReactCommon.QueryResult<FilterRequisitionListingQuery, FilterRequisitionListingQueryVariables>;
export const TravelRequsitionListingDocument = gql`
    query TravelRequsitionListing($EmployeeID: String!) {
  TravelRequsitionListing(EmployeeID: $EmployeeID) {
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    RequisitionHeaderID
    CompanyID
    RequisitionType
    TravelRequestID
    Subject
    Remark
    NumberOfItem
    CostPerItem
    Amount
    StartDate
    EndDate
    TravelReqNo
    CashReqNo
    OtherReqNo
    AssetReqNo
    TravelFrom
    TravelTo
    TransportMode
    PurposeOfTravel
    ApprovalStatus
    ApprovalDT
    DocumentID
    ApproverID
    EmployeeID
  }
}
    `;

/**
 * __useTravelRequsitionListingQuery__
 *
 * To run a query within a React component, call `useTravelRequsitionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelRequsitionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelRequsitionListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useTravelRequsitionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TravelRequsitionListingQuery, TravelRequsitionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TravelRequsitionListingQuery, TravelRequsitionListingQueryVariables>(TravelRequsitionListingDocument, baseOptions);
      }
export function useTravelRequsitionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TravelRequsitionListingQuery, TravelRequsitionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TravelRequsitionListingQuery, TravelRequsitionListingQueryVariables>(TravelRequsitionListingDocument, baseOptions);
        }
export type TravelRequsitionListingQueryHookResult = ReturnType<typeof useTravelRequsitionListingQuery>;
export type TravelRequsitionListingLazyQueryHookResult = ReturnType<typeof useTravelRequsitionListingLazyQuery>;
export type TravelRequsitionListingQueryResult = ApolloReactCommon.QueryResult<TravelRequsitionListingQuery, TravelRequsitionListingQueryVariables>;
export const GetRequisitionApprovalHistoryDocument = gql`
    query GetRequisitionApprovalHistory($ApproverID: String!) {
  GetRequisitionApprovalHistory(ApproverID: $ApproverID) {
    RequisitionHeaderID
    RequisitionType
    Subject
    Remark
    NumberOfItem
    CostPerItem
    Amount
    StartDate
    EndDate
    TravelFrom
    TravelTo
    TransportMode
    PurposeOfTravel
    ApprovalStatus
    ApprovalDT
    ApprovedBy
    RejectedRemarks
    EmployeeName
    EmployeeNo
    DocumentID
    DocumentFile
    CreatedDT
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        NotifiedDT
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetRequisitionApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useGetRequisitionApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionApprovalHistoryQuery({
 *   variables: {
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useGetRequisitionApprovalHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRequisitionApprovalHistoryQuery, GetRequisitionApprovalHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRequisitionApprovalHistoryQuery, GetRequisitionApprovalHistoryQueryVariables>(GetRequisitionApprovalHistoryDocument, baseOptions);
      }
export function useGetRequisitionApprovalHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRequisitionApprovalHistoryQuery, GetRequisitionApprovalHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRequisitionApprovalHistoryQuery, GetRequisitionApprovalHistoryQueryVariables>(GetRequisitionApprovalHistoryDocument, baseOptions);
        }
export type GetRequisitionApprovalHistoryQueryHookResult = ReturnType<typeof useGetRequisitionApprovalHistoryQuery>;
export type GetRequisitionApprovalHistoryLazyQueryHookResult = ReturnType<typeof useGetRequisitionApprovalHistoryLazyQuery>;
export type GetRequisitionApprovalHistoryQueryResult = ApolloReactCommon.QueryResult<GetRequisitionApprovalHistoryQuery, GetRequisitionApprovalHistoryQueryVariables>;
export const DeleteApprovedRequisitionDocument = gql`
    mutation DeleteApprovedRequisition($input: EmpRequisitionInput!, $IsSupersede: Boolean) {
  DeleteApprovedRequisition(input: $input, IsSupersede: $IsSupersede)
}
    `;
export type DeleteApprovedRequisitionMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovedRequisitionMutation, DeleteApprovedRequisitionMutationVariables>;

/**
 * __useDeleteApprovedRequisitionMutation__
 *
 * To run a mutation, you first call `useDeleteApprovedRequisitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovedRequisitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovedRequisitionMutation, { data, loading, error }] = useDeleteApprovedRequisitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      IsSupersede: // value for 'IsSupersede'
 *   },
 * });
 */
export function useDeleteApprovedRequisitionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovedRequisitionMutation, DeleteApprovedRequisitionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovedRequisitionMutation, DeleteApprovedRequisitionMutationVariables>(DeleteApprovedRequisitionDocument, baseOptions);
      }
export type DeleteApprovedRequisitionMutationHookResult = ReturnType<typeof useDeleteApprovedRequisitionMutation>;
export type DeleteApprovedRequisitionMutationResult = ApolloReactCommon.MutationResult<DeleteApprovedRequisitionMutation>;
export type DeleteApprovedRequisitionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovedRequisitionMutation, DeleteApprovedRequisitionMutationVariables>;
export const GetLeaveRecordsTotalCountDocument = gql`
    query GetLeaveRecordsTotalCount($input: LeaveRecordsListingInput!) {
  GetLeaveRecordsTotalCount(input: $input)
}
    `;

/**
 * __useGetLeaveRecordsTotalCountQuery__
 *
 * To run a query within a React component, call `useGetLeaveRecordsTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveRecordsTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveRecordsTotalCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLeaveRecordsTotalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveRecordsTotalCountQuery, GetLeaveRecordsTotalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveRecordsTotalCountQuery, GetLeaveRecordsTotalCountQueryVariables>(GetLeaveRecordsTotalCountDocument, baseOptions);
      }
export function useGetLeaveRecordsTotalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveRecordsTotalCountQuery, GetLeaveRecordsTotalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveRecordsTotalCountQuery, GetLeaveRecordsTotalCountQueryVariables>(GetLeaveRecordsTotalCountDocument, baseOptions);
        }
export type GetLeaveRecordsTotalCountQueryHookResult = ReturnType<typeof useGetLeaveRecordsTotalCountQuery>;
export type GetLeaveRecordsTotalCountLazyQueryHookResult = ReturnType<typeof useGetLeaveRecordsTotalCountLazyQuery>;
export type GetLeaveRecordsTotalCountQueryResult = ApolloReactCommon.QueryResult<GetLeaveRecordsTotalCountQuery, GetLeaveRecordsTotalCountQueryVariables>;
export const GetLeaveRecordsListingDocument = gql`
    query GetLeaveRecordsListing($input: LeaveRecordsListingInput!, $effectiveMonth: String, $offset: Float, $limit: Float, $sort: String, $orderBy: String) {
  GetLeaveRecordsListing(input: $input, effectiveMonth: $effectiveMonth, offset: $offset, limit: $limit, sort: $sort, orderBy: $orderBy) {
    EmployeeID
    EmployeeNo
    EmployeeName
    Employee {
      FormStatus
      JobGradeID
      OrgUnit {
        CompanyOrgUnitID
      }
    }
    LeaveTypeID
    LeaveHeaderID
    LeaveTypeName
    LeaveFromDate
    LeaveToDate
    TotalDay
    Remarks
    IsEmergencyLeave
    SubmissionDate
    SubmitterName
    DiseaseCode
    LeaveReason
    ApprovalStatus
    DocumentID
    DocumentName
    DocumentFile
    ApprovalDate
    ApprovedBy
    LeaveReasonID
    DiseaseCodeID
    VoiceDocumentID
    VoiceDocumentFile
    VoiceDocumentName
    LeaveDetails {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      LeaveDetailsID
      LeaveHeaderID
      PayPeriodID
      SessionType
      LeaveDate
      NumberOfDay
      ManualEntryID
      PostedDate
      PayrollCycleID
    }
    ApproverID
    ReportTo
    CancelledBy
    CancelledDate
    RejectedRemarks
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        NotifiedDT
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
          ApproverName
        }
      }
    }
  }
}
    `;

/**
 * __useGetLeaveRecordsListingQuery__
 *
 * To run a query within a React component, call `useGetLeaveRecordsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveRecordsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveRecordsListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      effectiveMonth: // value for 'effectiveMonth'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetLeaveRecordsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveRecordsListingQuery, GetLeaveRecordsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveRecordsListingQuery, GetLeaveRecordsListingQueryVariables>(GetLeaveRecordsListingDocument, baseOptions);
      }
export function useGetLeaveRecordsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveRecordsListingQuery, GetLeaveRecordsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveRecordsListingQuery, GetLeaveRecordsListingQueryVariables>(GetLeaveRecordsListingDocument, baseOptions);
        }
export type GetLeaveRecordsListingQueryHookResult = ReturnType<typeof useGetLeaveRecordsListingQuery>;
export type GetLeaveRecordsListingLazyQueryHookResult = ReturnType<typeof useGetLeaveRecordsListingLazyQuery>;
export type GetLeaveRecordsListingQueryResult = ApolloReactCommon.QueryResult<GetLeaveRecordsListingQuery, GetLeaveRecordsListingQueryVariables>;
export const ViewLeaveApprovalHistoryDocument = gql`
    query ViewLeaveApprovalHistory($ApproverID: String!) {
  ViewLeaveApprovalHistory(ApproverID: $ApproverID) {
    LeaveHeaderID
    FromDate
    UntilDate
    ApprovalStatus
    ApprovalDT
    LeaveType {
      Name
    }
    TotalDay
    Document {
      DocumentID
      DocumentFile
    }
    Approver {
      Contact {
        FullName
      }
    }
    CreatedDT
    Remarks
    Employee {
      EmployeeNo
      Contact {
        FullName
      }
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useViewLeaveApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useViewLeaveApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewLeaveApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewLeaveApprovalHistoryQuery({
 *   variables: {
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useViewLeaveApprovalHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewLeaveApprovalHistoryQuery, ViewLeaveApprovalHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewLeaveApprovalHistoryQuery, ViewLeaveApprovalHistoryQueryVariables>(ViewLeaveApprovalHistoryDocument, baseOptions);
      }
export function useViewLeaveApprovalHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewLeaveApprovalHistoryQuery, ViewLeaveApprovalHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewLeaveApprovalHistoryQuery, ViewLeaveApprovalHistoryQueryVariables>(ViewLeaveApprovalHistoryDocument, baseOptions);
        }
export type ViewLeaveApprovalHistoryQueryHookResult = ReturnType<typeof useViewLeaveApprovalHistoryQuery>;
export type ViewLeaveApprovalHistoryLazyQueryHookResult = ReturnType<typeof useViewLeaveApprovalHistoryLazyQuery>;
export type ViewLeaveApprovalHistoryQueryResult = ApolloReactCommon.QueryResult<ViewLeaveApprovalHistoryQuery, ViewLeaveApprovalHistoryQueryVariables>;
export const MyLeaveListingDocument = gql`
    query MyLeaveListing($MyLeaveInput: EmpLeaveListingInput!) {
  EmployeeLeaveListing(input: $MyLeaveInput) {
    LeaveHeaderID
    Company {
      CompanyID
      CompanyName
      SubscriptionAccountID
    }
    LeaveType {
      LeaveTypeID
      Name
    }
    LeaveDetails {
      SessionType
      ManualEntryID
    }
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
      }
    }
    Document {
      DocumentID
      DocumentFile
      Description
    }
    FromDate
    UntilDate
    TotalDay
    Remarks
    ApprovalStatus
    ModifiedDT
    ApprovalDT
    Approver {
      Contact {
        FullName
      }
    }
  }
}
    `;

/**
 * __useMyLeaveListingQuery__
 *
 * To run a query within a React component, call `useMyLeaveListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyLeaveListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyLeaveListingQuery({
 *   variables: {
 *      MyLeaveInput: // value for 'MyLeaveInput'
 *   },
 * });
 */
export function useMyLeaveListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyLeaveListingQuery, MyLeaveListingQueryVariables>) {
        return ApolloReactHooks.useQuery<MyLeaveListingQuery, MyLeaveListingQueryVariables>(MyLeaveListingDocument, baseOptions);
      }
export function useMyLeaveListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyLeaveListingQuery, MyLeaveListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyLeaveListingQuery, MyLeaveListingQueryVariables>(MyLeaveListingDocument, baseOptions);
        }
export type MyLeaveListingQueryHookResult = ReturnType<typeof useMyLeaveListingQuery>;
export type MyLeaveListingLazyQueryHookResult = ReturnType<typeof useMyLeaveListingLazyQuery>;
export type MyLeaveListingQueryResult = ApolloReactCommon.QueryResult<MyLeaveListingQuery, MyLeaveListingQueryVariables>;
export const GetEmployeesLeaveSummaryDocument = gql`
    query GetEmployeesLeaveSummary($CompanyID: String!, $AsToDate: DateTime!, $EmployeeIDs: [String!]!, $IsResigned: Boolean) {
  GetEmployeesLeaveSummary(CompanyID: $CompanyID, AsToDate: $AsToDate, EmployeeIDs: $EmployeeIDs, IsResigned: $IsResigned) {
    Employee {
      EmployeeID
      EmployeeNo
      Status
      ResignedDate
      Contact {
        FullName
        Gender
        Marital
      }
    }
    EmployeeLeavePolicies {
      LeavePolicy {
        IsAdvanceLeave
        TotalAdvanceDay
        AdvanceStartMonth
        AdvanceEndMonth
        LeaveType {
          LeaveTypeID
          LeaveTypeCode
          Name
        }
        Title
        LeaveTypeID
        LeaveFrequency
      }
      LeaveBalanceSummary {
        Entitled
        BroughtForward
        Adjustment
        Earned
        Taken
        TakenYTD
        Forfeited
        Balance
        BalanceYTD
        Occurrence
      }
    }
  }
}
    `;

/**
 * __useGetEmployeesLeaveSummaryQuery__
 *
 * To run a query within a React component, call `useGetEmployeesLeaveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesLeaveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesLeaveSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AsToDate: // value for 'AsToDate'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      IsResigned: // value for 'IsResigned'
 *   },
 * });
 */
export function useGetEmployeesLeaveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeesLeaveSummaryQuery, GetEmployeesLeaveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeesLeaveSummaryQuery, GetEmployeesLeaveSummaryQueryVariables>(GetEmployeesLeaveSummaryDocument, baseOptions);
      }
export function useGetEmployeesLeaveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeesLeaveSummaryQuery, GetEmployeesLeaveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeesLeaveSummaryQuery, GetEmployeesLeaveSummaryQueryVariables>(GetEmployeesLeaveSummaryDocument, baseOptions);
        }
export type GetEmployeesLeaveSummaryQueryHookResult = ReturnType<typeof useGetEmployeesLeaveSummaryQuery>;
export type GetEmployeesLeaveSummaryLazyQueryHookResult = ReturnType<typeof useGetEmployeesLeaveSummaryLazyQuery>;
export type GetEmployeesLeaveSummaryQueryResult = ApolloReactCommon.QueryResult<GetEmployeesLeaveSummaryQuery, GetEmployeesLeaveSummaryQueryVariables>;
export const EnhanceGetEmployeesLeaveSummaryDocument = gql`
    query EnhanceGetEmployeesLeaveSummary($CompanyID: String!, $AsToDate: DateTime!, $EmployeeIDs: [String!]!, $IsResigned: Boolean) {
  EnhanceGetEmployeesLeaveSummary(CompanyID: $CompanyID, AsToDate: $AsToDate, EmployeeIDs: $EmployeeIDs, IsResigned: $IsResigned) {
    Employee {
      EmployeeID
      EmployeeNo
      Status
      ResignedDate
      Contact {
        FullName
        Gender
        Marital
      }
    }
    EmployeeLeavePolicies {
      LeavePolicy {
        IsAdvanceLeave
        TotalAdvanceDay
        AdvanceStartMonth
        AdvanceEndMonth
        LeaveType {
          LeaveTypeID
          LeaveTypeCode
          Name
        }
        Title
        LeaveTypeID
        LeaveFrequency
      }
      LeaveBalanceSummary {
        Entitled
        BroughtForward
        Adjustment
        Earned
        Taken
        TakenYTD
        Forfeited
        Balance
        BalanceYTD
        Occurrence
      }
    }
  }
}
    `;

/**
 * __useEnhanceGetEmployeesLeaveSummaryQuery__
 *
 * To run a query within a React component, call `useEnhanceGetEmployeesLeaveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnhanceGetEmployeesLeaveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnhanceGetEmployeesLeaveSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AsToDate: // value for 'AsToDate'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      IsResigned: // value for 'IsResigned'
 *   },
 * });
 */
export function useEnhanceGetEmployeesLeaveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EnhanceGetEmployeesLeaveSummaryQuery, EnhanceGetEmployeesLeaveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EnhanceGetEmployeesLeaveSummaryQuery, EnhanceGetEmployeesLeaveSummaryQueryVariables>(EnhanceGetEmployeesLeaveSummaryDocument, baseOptions);
      }
export function useEnhanceGetEmployeesLeaveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EnhanceGetEmployeesLeaveSummaryQuery, EnhanceGetEmployeesLeaveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EnhanceGetEmployeesLeaveSummaryQuery, EnhanceGetEmployeesLeaveSummaryQueryVariables>(EnhanceGetEmployeesLeaveSummaryDocument, baseOptions);
        }
export type EnhanceGetEmployeesLeaveSummaryQueryHookResult = ReturnType<typeof useEnhanceGetEmployeesLeaveSummaryQuery>;
export type EnhanceGetEmployeesLeaveSummaryLazyQueryHookResult = ReturnType<typeof useEnhanceGetEmployeesLeaveSummaryLazyQuery>;
export type EnhanceGetEmployeesLeaveSummaryQueryResult = ApolloReactCommon.QueryResult<EnhanceGetEmployeesLeaveSummaryQuery, EnhanceGetEmployeesLeaveSummaryQueryVariables>;
export const GetCompanyCyclePeriodListDocument = gql`
    query GetCompanyCyclePeriodList {
  GetCompanyCyclePeriodList {
    CompanyID
    CompanyName
    PayPeriodID
    PayrollCycleID
    PayrollStatus
    IsLastProcess
    PeriodYearMonth
    MMMYYYY
    PayCycleName
  }
}
    `;

/**
 * __useGetCompanyCyclePeriodListQuery__
 *
 * To run a query within a React component, call `useGetCompanyCyclePeriodListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCyclePeriodListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCyclePeriodListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyCyclePeriodListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyCyclePeriodListQuery, GetCompanyCyclePeriodListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyCyclePeriodListQuery, GetCompanyCyclePeriodListQueryVariables>(GetCompanyCyclePeriodListDocument, baseOptions);
      }
export function useGetCompanyCyclePeriodListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyCyclePeriodListQuery, GetCompanyCyclePeriodListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyCyclePeriodListQuery, GetCompanyCyclePeriodListQueryVariables>(GetCompanyCyclePeriodListDocument, baseOptions);
        }
export type GetCompanyCyclePeriodListQueryHookResult = ReturnType<typeof useGetCompanyCyclePeriodListQuery>;
export type GetCompanyCyclePeriodListLazyQueryHookResult = ReturnType<typeof useGetCompanyCyclePeriodListLazyQuery>;
export type GetCompanyCyclePeriodListQueryResult = ApolloReactCommon.QueryResult<GetCompanyCyclePeriodListQuery, GetCompanyCyclePeriodListQueryVariables>;
export const GetEmployeeListingDocument = gql`
    query GetEmployeeListing {
  GetEmployeeListing {
    EmployeeID
    CompanyID
    EmployeeNo
    EmployeeStatus
    FullName
    EmployeeNoName
  }
}
    `;

/**
 * __useGetEmployeeListingQuery__
 *
 * To run a query within a React component, call `useGetEmployeeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeListingQuery, GetEmployeeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeListingQuery, GetEmployeeListingQueryVariables>(GetEmployeeListingDocument, baseOptions);
      }
export function useGetEmployeeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeListingQuery, GetEmployeeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeListingQuery, GetEmployeeListingQueryVariables>(GetEmployeeListingDocument, baseOptions);
        }
export type GetEmployeeListingQueryHookResult = ReturnType<typeof useGetEmployeeListingQuery>;
export type GetEmployeeListingLazyQueryHookResult = ReturnType<typeof useGetEmployeeListingLazyQuery>;
export type GetEmployeeListingQueryResult = ApolloReactCommon.QueryResult<GetEmployeeListingQuery, GetEmployeeListingQueryVariables>;
export const GetMyLeaveEntitlementDocument = gql`
    query GetMyLeaveEntitlement($EmployeeID: String!, $AsToDate: DateTime!) {
  GetMyLeaveEntitlement(EmployeeID: $EmployeeID, AsToDate: $AsToDate) {
    Employee {
      Contact {
        FullName
      }
    }
    EmpLeaveItems {
      LeaveItem {
        LeaveType {
          Name
        }
      }
      EntitlementDayByLOS
    }
  }
}
    `;

/**
 * __useGetMyLeaveEntitlementQuery__
 *
 * To run a query within a React component, call `useGetMyLeaveEntitlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLeaveEntitlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLeaveEntitlementQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      AsToDate: // value for 'AsToDate'
 *   },
 * });
 */
export function useGetMyLeaveEntitlementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyLeaveEntitlementQuery, GetMyLeaveEntitlementQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyLeaveEntitlementQuery, GetMyLeaveEntitlementQueryVariables>(GetMyLeaveEntitlementDocument, baseOptions);
      }
export function useGetMyLeaveEntitlementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyLeaveEntitlementQuery, GetMyLeaveEntitlementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyLeaveEntitlementQuery, GetMyLeaveEntitlementQueryVariables>(GetMyLeaveEntitlementDocument, baseOptions);
        }
export type GetMyLeaveEntitlementQueryHookResult = ReturnType<typeof useGetMyLeaveEntitlementQuery>;
export type GetMyLeaveEntitlementLazyQueryHookResult = ReturnType<typeof useGetMyLeaveEntitlementLazyQuery>;
export type GetMyLeaveEntitlementQueryResult = ApolloReactCommon.QueryResult<GetMyLeaveEntitlementQuery, GetMyLeaveEntitlementQueryVariables>;
export const GetMyLeaveBalanceDocument = gql`
    query GetMyLeaveBalance($EmployeeID: String!, $AsToDate: DateTime!) {
  GetMyLeaveBalance(EmployeeID: $EmployeeID, AsToDate: $AsToDate) {
    Employee {
      Contact {
        FullName
      }
    }
    LeaveTypeBalance {
      LeaveItem {
        LeaveType {
          Name
        }
      }
      EntitlementDayByLOS
      Entitlement
      BroughtForward
      Taken
      Balance
      ToDateEarned
      ErrorMessage
    }
  }
}
    `;

/**
 * __useGetMyLeaveBalanceQuery__
 *
 * To run a query within a React component, call `useGetMyLeaveBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLeaveBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLeaveBalanceQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      AsToDate: // value for 'AsToDate'
 *   },
 * });
 */
export function useGetMyLeaveBalanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyLeaveBalanceQuery, GetMyLeaveBalanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyLeaveBalanceQuery, GetMyLeaveBalanceQueryVariables>(GetMyLeaveBalanceDocument, baseOptions);
      }
export function useGetMyLeaveBalanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyLeaveBalanceQuery, GetMyLeaveBalanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyLeaveBalanceQuery, GetMyLeaveBalanceQueryVariables>(GetMyLeaveBalanceDocument, baseOptions);
        }
export type GetMyLeaveBalanceQueryHookResult = ReturnType<typeof useGetMyLeaveBalanceQuery>;
export type GetMyLeaveBalanceLazyQueryHookResult = ReturnType<typeof useGetMyLeaveBalanceLazyQuery>;
export type GetMyLeaveBalanceQueryResult = ApolloReactCommon.QueryResult<GetMyLeaveBalanceQuery, GetMyLeaveBalanceQueryVariables>;
export const DeleteLeaveApplicationDocument = gql`
    mutation DeleteLeaveApplication($EmpLeaveDetailInput: EmpLeaveDetailInput!) {
  DeleteLeaveApplication(input: $EmpLeaveDetailInput)
}
    `;
export type DeleteLeaveApplicationMutationFn = ApolloReactCommon.MutationFunction<DeleteLeaveApplicationMutation, DeleteLeaveApplicationMutationVariables>;

/**
 * __useDeleteLeaveApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteLeaveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeaveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeaveApplicationMutation, { data, loading, error }] = useDeleteLeaveApplicationMutation({
 *   variables: {
 *      EmpLeaveDetailInput: // value for 'EmpLeaveDetailInput'
 *   },
 * });
 */
export function useDeleteLeaveApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLeaveApplicationMutation, DeleteLeaveApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteLeaveApplicationMutation, DeleteLeaveApplicationMutationVariables>(DeleteLeaveApplicationDocument, baseOptions);
      }
export type DeleteLeaveApplicationMutationHookResult = ReturnType<typeof useDeleteLeaveApplicationMutation>;
export type DeleteLeaveApplicationMutationResult = ApolloReactCommon.MutationResult<DeleteLeaveApplicationMutation>;
export type DeleteLeaveApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLeaveApplicationMutation, DeleteLeaveApplicationMutationVariables>;
export const SubmitLeaveApplicationDocument = gql`
    mutation SubmitLeaveApplication($EmpLeaveDetailInput: EmpLeaveDetailInput!) {
  SubmitLeaveApplication(input: $EmpLeaveDetailInput)
}
    `;
export type SubmitLeaveApplicationMutationFn = ApolloReactCommon.MutationFunction<SubmitLeaveApplicationMutation, SubmitLeaveApplicationMutationVariables>;

/**
 * __useSubmitLeaveApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitLeaveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitLeaveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitLeaveApplicationMutation, { data, loading, error }] = useSubmitLeaveApplicationMutation({
 *   variables: {
 *      EmpLeaveDetailInput: // value for 'EmpLeaveDetailInput'
 *   },
 * });
 */
export function useSubmitLeaveApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitLeaveApplicationMutation, SubmitLeaveApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitLeaveApplicationMutation, SubmitLeaveApplicationMutationVariables>(SubmitLeaveApplicationDocument, baseOptions);
      }
export type SubmitLeaveApplicationMutationHookResult = ReturnType<typeof useSubmitLeaveApplicationMutation>;
export type SubmitLeaveApplicationMutationResult = ApolloReactCommon.MutationResult<SubmitLeaveApplicationMutation>;
export type SubmitLeaveApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitLeaveApplicationMutation, SubmitLeaveApplicationMutationVariables>;
export const SubmitLeaveApplicationV2Document = gql`
    mutation SubmitLeaveApplicationV2($EmpLeaveDetailInput: EmpLeaveDetailInput!) {
  SubmitLeaveApplicationV2(input: $EmpLeaveDetailInput) {
    LeaveHeaderID
    ApprovalStatus
    TotalDay
    ApproverID
  }
}
    `;
export type SubmitLeaveApplicationV2MutationFn = ApolloReactCommon.MutationFunction<SubmitLeaveApplicationV2Mutation, SubmitLeaveApplicationV2MutationVariables>;

/**
 * __useSubmitLeaveApplicationV2Mutation__
 *
 * To run a mutation, you first call `useSubmitLeaveApplicationV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitLeaveApplicationV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitLeaveApplicationV2Mutation, { data, loading, error }] = useSubmitLeaveApplicationV2Mutation({
 *   variables: {
 *      EmpLeaveDetailInput: // value for 'EmpLeaveDetailInput'
 *   },
 * });
 */
export function useSubmitLeaveApplicationV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitLeaveApplicationV2Mutation, SubmitLeaveApplicationV2MutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitLeaveApplicationV2Mutation, SubmitLeaveApplicationV2MutationVariables>(SubmitLeaveApplicationV2Document, baseOptions);
      }
export type SubmitLeaveApplicationV2MutationHookResult = ReturnType<typeof useSubmitLeaveApplicationV2Mutation>;
export type SubmitLeaveApplicationV2MutationResult = ApolloReactCommon.MutationResult<SubmitLeaveApplicationV2Mutation>;
export type SubmitLeaveApplicationV2MutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitLeaveApplicationV2Mutation, SubmitLeaveApplicationV2MutationVariables>;
export const LeaveTypeListingDocument = gql`
    query LeaveTypeListing($SubscriptionAccountID: String!) {
  LeaveTypeListing(SubscriptionAccountID: $SubscriptionAccountID) {
    LeaveTypeID
    Name
    LeavePolicies {
      LeaveTypeID
      LeaveItemID
      LeaveBenefit {
        Name
        Description
        Status
      }
    }
  }
}
    `;

/**
 * __useLeaveTypeListingQuery__
 *
 * To run a query within a React component, call `useLeaveTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveTypeListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useLeaveTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveTypeListingQuery, LeaveTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveTypeListingQuery, LeaveTypeListingQueryVariables>(LeaveTypeListingDocument, baseOptions);
      }
export function useLeaveTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveTypeListingQuery, LeaveTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveTypeListingQuery, LeaveTypeListingQueryVariables>(LeaveTypeListingDocument, baseOptions);
        }
export type LeaveTypeListingQueryHookResult = ReturnType<typeof useLeaveTypeListingQuery>;
export type LeaveTypeListingLazyQueryHookResult = ReturnType<typeof useLeaveTypeListingLazyQuery>;
export type LeaveTypeListingQueryResult = ApolloReactCommon.QueryResult<LeaveTypeListingQuery, LeaveTypeListingQueryVariables>;
export const GetReplacementLeaveByEmployeeDocument = gql`
    query GetReplacementLeaveByEmployee($EmployeeID: String!, $LeaveTypeID: String!, $AsToDate: String) {
  GetLeaveReplacementListingByEmployee(EmployeeID: $EmployeeID, LeaveTypeID: $LeaveTypeID, AsToDate: $AsToDate) {
    TotalDay
    EffectiveDate
    ExpiryDate
    LeaveHeaderID
    Taken
    Balance
    Remarks
  }
}
    `;

/**
 * __useGetReplacementLeaveByEmployeeQuery__
 *
 * To run a query within a React component, call `useGetReplacementLeaveByEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReplacementLeaveByEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReplacementLeaveByEmployeeQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      LeaveTypeID: // value for 'LeaveTypeID'
 *      AsToDate: // value for 'AsToDate'
 *   },
 * });
 */
export function useGetReplacementLeaveByEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReplacementLeaveByEmployeeQuery, GetReplacementLeaveByEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReplacementLeaveByEmployeeQuery, GetReplacementLeaveByEmployeeQueryVariables>(GetReplacementLeaveByEmployeeDocument, baseOptions);
      }
export function useGetReplacementLeaveByEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReplacementLeaveByEmployeeQuery, GetReplacementLeaveByEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReplacementLeaveByEmployeeQuery, GetReplacementLeaveByEmployeeQueryVariables>(GetReplacementLeaveByEmployeeDocument, baseOptions);
        }
export type GetReplacementLeaveByEmployeeQueryHookResult = ReturnType<typeof useGetReplacementLeaveByEmployeeQuery>;
export type GetReplacementLeaveByEmployeeLazyQueryHookResult = ReturnType<typeof useGetReplacementLeaveByEmployeeLazyQuery>;
export type GetReplacementLeaveByEmployeeQueryResult = ApolloReactCommon.QueryResult<GetReplacementLeaveByEmployeeQuery, GetReplacementLeaveByEmployeeQueryVariables>;
export const LeaveRecordsInsertDocument = gql`
    mutation LeaveRecordsInsert($leaveHeaderInput: LeaveHeaderInput!, $leaveDetailsInput: [LeaveDetailsInput!]!, $LeavePolicyID: String) {
  LeaveRecordsInsert(leaveHeaderInput: $leaveHeaderInput, leaveDetailsInput: $leaveDetailsInput, LeavePolicyID: $LeavePolicyID) {
    LeaveHeaderID
    ApprovalStatus
    TotalDay
  }
}
    `;
export type LeaveRecordsInsertMutationFn = ApolloReactCommon.MutationFunction<LeaveRecordsInsertMutation, LeaveRecordsInsertMutationVariables>;

/**
 * __useLeaveRecordsInsertMutation__
 *
 * To run a mutation, you first call `useLeaveRecordsInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveRecordsInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveRecordsInsertMutation, { data, loading, error }] = useLeaveRecordsInsertMutation({
 *   variables: {
 *      leaveHeaderInput: // value for 'leaveHeaderInput'
 *      leaveDetailsInput: // value for 'leaveDetailsInput'
 *      LeavePolicyID: // value for 'LeavePolicyID'
 *   },
 * });
 */
export function useLeaveRecordsInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveRecordsInsertMutation, LeaveRecordsInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveRecordsInsertMutation, LeaveRecordsInsertMutationVariables>(LeaveRecordsInsertDocument, baseOptions);
      }
export type LeaveRecordsInsertMutationHookResult = ReturnType<typeof useLeaveRecordsInsertMutation>;
export type LeaveRecordsInsertMutationResult = ApolloReactCommon.MutationResult<LeaveRecordsInsertMutation>;
export type LeaveRecordsInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveRecordsInsertMutation, LeaveRecordsInsertMutationVariables>;
export const LeaveRecordsUpdateDocument = gql`
    mutation LeaveRecordsUpdate($leaveHeaderID: String!, $leaveHeaderInput: LeaveHeaderInput!, $leaveDetailsInput: [LeaveDetailsInput!]!, $isRemovedAttachment: Boolean, $isRemovedVoice: Boolean, $LeavePolicyID: String) {
  LeaveRecordsUpdate(leaveHeaderID: $leaveHeaderID, leaveHeaderInput: $leaveHeaderInput, leaveDetailsInput: $leaveDetailsInput, isRemovedAttachment: $isRemovedAttachment, isRemovedVoice: $isRemovedVoice, LeavePolicyID: $LeavePolicyID)
}
    `;
export type LeaveRecordsUpdateMutationFn = ApolloReactCommon.MutationFunction<LeaveRecordsUpdateMutation, LeaveRecordsUpdateMutationVariables>;

/**
 * __useLeaveRecordsUpdateMutation__
 *
 * To run a mutation, you first call `useLeaveRecordsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveRecordsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveRecordsUpdateMutation, { data, loading, error }] = useLeaveRecordsUpdateMutation({
 *   variables: {
 *      leaveHeaderID: // value for 'leaveHeaderID'
 *      leaveHeaderInput: // value for 'leaveHeaderInput'
 *      leaveDetailsInput: // value for 'leaveDetailsInput'
 *      isRemovedAttachment: // value for 'isRemovedAttachment'
 *      isRemovedVoice: // value for 'isRemovedVoice'
 *      LeavePolicyID: // value for 'LeavePolicyID'
 *   },
 * });
 */
export function useLeaveRecordsUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveRecordsUpdateMutation, LeaveRecordsUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveRecordsUpdateMutation, LeaveRecordsUpdateMutationVariables>(LeaveRecordsUpdateDocument, baseOptions);
      }
export type LeaveRecordsUpdateMutationHookResult = ReturnType<typeof useLeaveRecordsUpdateMutation>;
export type LeaveRecordsUpdateMutationResult = ApolloReactCommon.MutationResult<LeaveRecordsUpdateMutation>;
export type LeaveRecordsUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveRecordsUpdateMutation, LeaveRecordsUpdateMutationVariables>;
export const PostLeaveApplicationDocument = gql`
    mutation PostLeaveApplication($EmpLeaveApplicationInput: EmpLeaveApplicationInput!) {
  PostLeaveApplication(input: $EmpLeaveApplicationInput)
}
    `;
export type PostLeaveApplicationMutationFn = ApolloReactCommon.MutationFunction<PostLeaveApplicationMutation, PostLeaveApplicationMutationVariables>;

/**
 * __usePostLeaveApplicationMutation__
 *
 * To run a mutation, you first call `usePostLeaveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostLeaveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postLeaveApplicationMutation, { data, loading, error }] = usePostLeaveApplicationMutation({
 *   variables: {
 *      EmpLeaveApplicationInput: // value for 'EmpLeaveApplicationInput'
 *   },
 * });
 */
export function usePostLeaveApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostLeaveApplicationMutation, PostLeaveApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<PostLeaveApplicationMutation, PostLeaveApplicationMutationVariables>(PostLeaveApplicationDocument, baseOptions);
      }
export type PostLeaveApplicationMutationHookResult = ReturnType<typeof usePostLeaveApplicationMutation>;
export type PostLeaveApplicationMutationResult = ApolloReactCommon.MutationResult<PostLeaveApplicationMutation>;
export type PostLeaveApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<PostLeaveApplicationMutation, PostLeaveApplicationMutationVariables>;
export const EmployeeSummaryDocument = gql`
    query EmployeeSummary($EmployeeID: String!) {
  EmployeeSummary(EmployeeID: $EmployeeID) {
    RetirementAge
    EmployeeID
    CalendarPolicyID
    CalendarTitle
    Contact {
      ContactID
      FullName
      NickName
      Email
      Nationality
      MobileNo
      NRIC
      PassportNo
      PassportNoLHDN
      BirthDate
      Gender
      Marital
      Salutation
      Race
      Religion
      Address {
        Line1
        Line2
        Country
        State
        City
        PostalCode
      }
      PermanentAddress {
        Line1
        Line2
        Country
        State
        City
        PostalCode
      }
      SameAddress
      ProfilePicture
    }
    IsOKU
    IsHOD
    IsSuperUser
    IsInterCompanyTransfer
    EmployeeNo
    EmploymentType
    Status
    EmployeeStatus
    FormStatus
    Probation
    CalendarStateID
    JoinedReferenceDate
    StaffCategory
    StaffCategoryID
    OrgUnitID
    ActualConfirmedDate
    EAFormDocCount
    PaySlipDocCount
    PersonalDocCount
    GeneralDocCount
    ContractInfoDocCount
    GeneralDocTotalSize
    EAFormDocTotalSize
    PaySlipDocTotalSize
    PersonalDocTotalSize
    Company {
      CompanyID
      CalendarTitle
      CompanyName
      CalendarStateID
      Contact {
        ContactID
        Address {
          Line1
          Line2
          Country
          State
          City
          PostalCode
        }
      }
    }
    Department {
      DepartmentID
      Description
    }
    EmployeeDepartment {
      DepartmentID
      Description
    }
    Division {
      DepartmentID
      Description
    }
    Designation {
      JobDesignationID
      Name
    }
    JobGrade {
      JobGradeID
      Description
    }
    RecruitmentSource {
      RecruitmentSourceID
      Name
    }
    OrgUnit {
      CompanyOrgUnitID
      Level
      Description
      ParentID
    }
    SubscriptionAccountID
    ReportTo
    Supervisor {
      EmployeeID
      Contact {
        FullName
      }
    }
    JoinedDate
    ConfirmedDate
    ResignedDate
    TenderResignDate
    EmployeeCareerLogs {
      CareerLogID
      EffectiveDate
      SalaryType
      FromSalary
      ToSalary
      TenderResignDate
      MovementType
      ExitReasonID
      Status
      ExitCompanyReason {
        PersonnelParameterID
        Name
      }
      LastEmploymentDate
      LastWorkingDate
      ModifiedDT
    }
    EmployeeSalaryLogs {
      CareerLogID
      EffectiveDate
      FromSalary
      ToSalary
      SalaryAdjustmentAmount
      SalaryType
      Reason
      Remark
    }
    EmployeeBankInfo {
      EmployeeBankInfoID
      Bank
      AcctNo
      PaymentMethod
      CompanyBankAccount
      PayeeName
      PayeeID
    }
    EmployeeStatutory {
      EmployeeStatutoryID
      IsEPF
      EPFNo
      EPFVERate
      EPFVERateCmp
      IsSocso
      SocsoNo
      IsPCB
      PCBNo
      CountryCode
      IsEIS
      EISNo
      IsAngkasa
      AngkasaNo
      IsASB
      ASBNo
      IsKWAP
      KWAPNo
      IsTBH
      TBHNo
      IsZakat
      ZakatNo
      ZakatAmount
      ZakatRate
      ResidentStatus
      TaxMaritialStatus
      ZakatState
      IsHRDF
      ModifiedDT
      ModifiedBy
      CreatedBy
      CreatedDT
      UserLogs {
        columnName
        newColumnValue
        oldColumnValue
        createdDT
        createdBy
        modifiedDT
        modifiedBy
      }
      CompanyEPFAccount
      CompanySocsoAccount
      CompanyPCBAccount
      CompanyEISAccount
    }
    EmployeePrevContribution {
      EmployeePrevContributionID
      CompanyID
      EmployeeID
      PeriodYear
      GrossTaxable
      EPFPaid
      PCBPaid
      ZAKATPaid
      ModifiedDT
      CreatedDT
    }
    EmployeeDependents {
      EmployeeDependentsID
      Relationship
      Education
      ChildEligibleRate
      Occupation
      Contact {
        ContactID
        FullName
        BirthDate
        MobileNo
        NRIC
        PassportNo
        Nationality
      }
      IsWorking
      IsOKU
      IsEmergencyContact
      PCBNo
      Education
    }
    Documents {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    EmployeeAppraisals {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      EmployeeAppraisalID
      EmployeeID
      AppraisalRecord
      Date
      Rating
      Score
      AppraiseeRemark
      AppraiserRemark
      AppraiserName
      DocumentFile
      BucketFileName
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
    }
    EmployeeDiaries {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      EmployeeDiaryID
      EmployeeID
      Date
      CaseNo
      Description
      Countermeasure
      Private
      DocumentID
      Documents {
        DocumentID
        DocumentFile
        DocumentType
        Description
        BucketFileName
      }
    }
    EmployeeTrainings {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      EmployeeTrainingID
      EmployeeID
      CourseName
      StartDate
      EndDate
      TrainingHour
      TrainingProvider
      Trainer
      TrainerRating
      TrainerScore
      Superior
      SuperiorRating
      SuperiorScore
      Document {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
    }
    EmployeeInsuranceSchemes {
      InsuranceSchemeID
      EmployeeInsuranceSchemeID
      StartDate
      EndDate
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      InsuranceScheme {
        Name
        Status
      }
    }
    EmployeeSubordinates {
      EmployeeID
      EmployeeNo
      Contact {
        ContactID
        FullName
      }
    }
    EmployeeAssets {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      EmployeeAssetID
      EmployeeID
      DateAssign
      Item
      Remark
    }
    LastUpdatedDates {
      EmployeeLastDate
      AssetsLastDate
      BIKLastDate
      CareerLastDate
      SubordinateLastDate
      TaxLastDate
      HealthInfoLastDate
      UserDefineFieldsLastDate
    }
    TotalEducation
    TotalJobHistory
    TotalPersonalDocument
    TotalCertificate
    RoleID
    RoleName
  }
}
    `;

/**
 * __useEmployeeSummaryQuery__
 *
 * To run a query within a React component, call `useEmployeeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeSummaryQuery, EmployeeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeSummaryQuery, EmployeeSummaryQueryVariables>(EmployeeSummaryDocument, baseOptions);
      }
export function useEmployeeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeSummaryQuery, EmployeeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeSummaryQuery, EmployeeSummaryQueryVariables>(EmployeeSummaryDocument, baseOptions);
        }
export type EmployeeSummaryQueryHookResult = ReturnType<typeof useEmployeeSummaryQuery>;
export type EmployeeSummaryLazyQueryHookResult = ReturnType<typeof useEmployeeSummaryLazyQuery>;
export type EmployeeSummaryQueryResult = ApolloReactCommon.QueryResult<EmployeeSummaryQuery, EmployeeSummaryQueryVariables>;
export const FilterEmployeeLeaveRecordsDocument = gql`
    query FilterEmployeeLeaveRecords($EmployeeID: String!, $LeaveTypeIDs: [String!]!, $EndDate: String!, $StartDate: String!) {
  FilterEmployeeLeaveRecords(EmployeeID: $EmployeeID, LeaveTypeIDs: $LeaveTypeIDs, EndDate: $EndDate, StartDate: $StartDate) {
    LeaveHeaderID
    LeaveTypeID
    LeaveType {
      Name
    }
    FromDate
    UntilDate
    TotalDay
    CreatedDT
    Remarks
    ApprovalStatus
    ApprovalDT
    ApproverID
    Employee {
      EmployeeID
      Status
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
      ContactID
      Contact {
        FullName
      }
    }
    DocumentID
    Document {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useFilterEmployeeLeaveRecordsQuery__
 *
 * To run a query within a React component, call `useFilterEmployeeLeaveRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterEmployeeLeaveRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterEmployeeLeaveRecordsQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      LeaveTypeIDs: // value for 'LeaveTypeIDs'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *   },
 * });
 */
export function useFilterEmployeeLeaveRecordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FilterEmployeeLeaveRecordsQuery, FilterEmployeeLeaveRecordsQueryVariables>) {
        return ApolloReactHooks.useQuery<FilterEmployeeLeaveRecordsQuery, FilterEmployeeLeaveRecordsQueryVariables>(FilterEmployeeLeaveRecordsDocument, baseOptions);
      }
export function useFilterEmployeeLeaveRecordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FilterEmployeeLeaveRecordsQuery, FilterEmployeeLeaveRecordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FilterEmployeeLeaveRecordsQuery, FilterEmployeeLeaveRecordsQueryVariables>(FilterEmployeeLeaveRecordsDocument, baseOptions);
        }
export type FilterEmployeeLeaveRecordsQueryHookResult = ReturnType<typeof useFilterEmployeeLeaveRecordsQuery>;
export type FilterEmployeeLeaveRecordsLazyQueryHookResult = ReturnType<typeof useFilterEmployeeLeaveRecordsLazyQuery>;
export type FilterEmployeeLeaveRecordsQueryResult = ApolloReactCommon.QueryResult<FilterEmployeeLeaveRecordsQuery, FilterEmployeeLeaveRecordsQueryVariables>;
export const LeaveStatusUpdateDocument = gql`
    mutation LeaveStatusUpdate($LeaveHeaderIDs: [String!]!, $ApprovalStatus: ApprovalStatus!) {
  LeaveStatusUpdate(LeaveHeaderIDs: $LeaveHeaderIDs, ApprovalStatus: $ApprovalStatus)
}
    `;
export type LeaveStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<LeaveStatusUpdateMutation, LeaveStatusUpdateMutationVariables>;

/**
 * __useLeaveStatusUpdateMutation__
 *
 * To run a mutation, you first call `useLeaveStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveStatusUpdateMutation, { data, loading, error }] = useLeaveStatusUpdateMutation({
 *   variables: {
 *      LeaveHeaderIDs: // value for 'LeaveHeaderIDs'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *   },
 * });
 */
export function useLeaveStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveStatusUpdateMutation, LeaveStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveStatusUpdateMutation, LeaveStatusUpdateMutationVariables>(LeaveStatusUpdateDocument, baseOptions);
      }
export type LeaveStatusUpdateMutationHookResult = ReturnType<typeof useLeaveStatusUpdateMutation>;
export type LeaveStatusUpdateMutationResult = ApolloReactCommon.MutationResult<LeaveStatusUpdateMutation>;
export type LeaveStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveStatusUpdateMutation, LeaveStatusUpdateMutationVariables>;
export const GetLeaveOffDayByAllStateWithCompanyDocument = gql`
    query GetLeaveOffDayByAllStateWithCompany($CompanyID: String!) {
  GetLeaveOffDayByAllStateWithCompany(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetLeaveOffDayByAllStateWithCompanyQuery__
 *
 * To run a query within a React component, call `useGetLeaveOffDayByAllStateWithCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveOffDayByAllStateWithCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveOffDayByAllStateWithCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetLeaveOffDayByAllStateWithCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveOffDayByAllStateWithCompanyQuery, GetLeaveOffDayByAllStateWithCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveOffDayByAllStateWithCompanyQuery, GetLeaveOffDayByAllStateWithCompanyQueryVariables>(GetLeaveOffDayByAllStateWithCompanyDocument, baseOptions);
      }
export function useGetLeaveOffDayByAllStateWithCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveOffDayByAllStateWithCompanyQuery, GetLeaveOffDayByAllStateWithCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveOffDayByAllStateWithCompanyQuery, GetLeaveOffDayByAllStateWithCompanyQueryVariables>(GetLeaveOffDayByAllStateWithCompanyDocument, baseOptions);
        }
export type GetLeaveOffDayByAllStateWithCompanyQueryHookResult = ReturnType<typeof useGetLeaveOffDayByAllStateWithCompanyQuery>;
export type GetLeaveOffDayByAllStateWithCompanyLazyQueryHookResult = ReturnType<typeof useGetLeaveOffDayByAllStateWithCompanyLazyQuery>;
export type GetLeaveOffDayByAllStateWithCompanyQueryResult = ApolloReactCommon.QueryResult<GetLeaveOffDayByAllStateWithCompanyQuery, GetLeaveOffDayByAllStateWithCompanyQueryVariables>;
export const GetLeavePolicyByJobGradeIdDocument = gql`
    query GetLeavePolicyByJobGradeID($JobGradeID: String!, $CompanyID: String) {
  GetLeavePolicyByJobGradeID(JobGradeID: $JobGradeID, CompanyID: $CompanyID) {
    Title
    LeaveTypeID
    LeaveType {
      LeaveTypeID
      Name
      LeaveTypeCode
    }
    IsOffDay
    IsAdvanceLeave
    TotalAdvanceDay
    AdvanceStartMonth
    AdvanceEndMonth
    RemainingBalance
    IsRestDay
    IsHalfDay
    IsRemarks
    IsAttachment
    IsConfirmedStaff
    IsPublicHoliday
    IsBackdated
    BackdatedDays
  }
}
    `;

/**
 * __useGetLeavePolicyByJobGradeIdQuery__
 *
 * To run a query within a React component, call `useGetLeavePolicyByJobGradeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeavePolicyByJobGradeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeavePolicyByJobGradeIdQuery({
 *   variables: {
 *      JobGradeID: // value for 'JobGradeID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetLeavePolicyByJobGradeIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeavePolicyByJobGradeIdQuery, GetLeavePolicyByJobGradeIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeavePolicyByJobGradeIdQuery, GetLeavePolicyByJobGradeIdQueryVariables>(GetLeavePolicyByJobGradeIdDocument, baseOptions);
      }
export function useGetLeavePolicyByJobGradeIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeavePolicyByJobGradeIdQuery, GetLeavePolicyByJobGradeIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeavePolicyByJobGradeIdQuery, GetLeavePolicyByJobGradeIdQueryVariables>(GetLeavePolicyByJobGradeIdDocument, baseOptions);
        }
export type GetLeavePolicyByJobGradeIdQueryHookResult = ReturnType<typeof useGetLeavePolicyByJobGradeIdQuery>;
export type GetLeavePolicyByJobGradeIdLazyQueryHookResult = ReturnType<typeof useGetLeavePolicyByJobGradeIdLazyQuery>;
export type GetLeavePolicyByJobGradeIdQueryResult = ApolloReactCommon.QueryResult<GetLeavePolicyByJobGradeIdQuery, GetLeavePolicyByJobGradeIdQueryVariables>;
export const ApproverInfoDocument = gql`
    query ApproverInfo($EmployeeID: String!) {
  EmployeeSummary(EmployeeID: $EmployeeID) {
    EmployeeID
    Contact {
      ContactID
      FullName
      Email
      Nationality
      MobileNo
    }
  }
}
    `;

/**
 * __useApproverInfoQuery__
 *
 * To run a query within a React component, call `useApproverInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useApproverInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApproverInfoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useApproverInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApproverInfoQuery, ApproverInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ApproverInfoQuery, ApproverInfoQueryVariables>(ApproverInfoDocument, baseOptions);
      }
export function useApproverInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApproverInfoQuery, ApproverInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApproverInfoQuery, ApproverInfoQueryVariables>(ApproverInfoDocument, baseOptions);
        }
export type ApproverInfoQueryHookResult = ReturnType<typeof useApproverInfoQuery>;
export type ApproverInfoLazyQueryHookResult = ReturnType<typeof useApproverInfoLazyQuery>;
export type ApproverInfoQueryResult = ApolloReactCommon.QueryResult<ApproverInfoQuery, ApproverInfoQueryVariables>;
export const DeleteApprovedLeaveApplicationDocument = gql`
    mutation DeleteApprovedLeaveApplication($input: EmpLeaveDetailInput!, $Remark: String!, $IsSupersede: Boolean) {
  DeleteApprovedLeaveApplication(input: $input, Remark: $Remark, IsSupersede: $IsSupersede)
}
    `;
export type DeleteApprovedLeaveApplicationMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovedLeaveApplicationMutation, DeleteApprovedLeaveApplicationMutationVariables>;

/**
 * __useDeleteApprovedLeaveApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteApprovedLeaveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovedLeaveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovedLeaveApplicationMutation, { data, loading, error }] = useDeleteApprovedLeaveApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      Remark: // value for 'Remark'
 *      IsSupersede: // value for 'IsSupersede'
 *   },
 * });
 */
export function useDeleteApprovedLeaveApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovedLeaveApplicationMutation, DeleteApprovedLeaveApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovedLeaveApplicationMutation, DeleteApprovedLeaveApplicationMutationVariables>(DeleteApprovedLeaveApplicationDocument, baseOptions);
      }
export type DeleteApprovedLeaveApplicationMutationHookResult = ReturnType<typeof useDeleteApprovedLeaveApplicationMutation>;
export type DeleteApprovedLeaveApplicationMutationResult = ApolloReactCommon.MutationResult<DeleteApprovedLeaveApplicationMutation>;
export type DeleteApprovedLeaveApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovedLeaveApplicationMutation, DeleteApprovedLeaveApplicationMutationVariables>;
export const LeaveReasonListDocument = gql`
    query LeaveReasonList($SubscriptionAccountID: String!) {
  LeaveReasonListing(SubscriptionAccountID: $SubscriptionAccountID) {
    LeaveReasonID
    SubscriptionAccountID
    Name
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useLeaveReasonListQuery__
 *
 * To run a query within a React component, call `useLeaveReasonListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveReasonListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveReasonListQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useLeaveReasonListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveReasonListQuery, LeaveReasonListQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveReasonListQuery, LeaveReasonListQueryVariables>(LeaveReasonListDocument, baseOptions);
      }
export function useLeaveReasonListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveReasonListQuery, LeaveReasonListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveReasonListQuery, LeaveReasonListQueryVariables>(LeaveReasonListDocument, baseOptions);
        }
export type LeaveReasonListQueryHookResult = ReturnType<typeof useLeaveReasonListQuery>;
export type LeaveReasonListLazyQueryHookResult = ReturnType<typeof useLeaveReasonListLazyQuery>;
export type LeaveReasonListQueryResult = ApolloReactCommon.QueryResult<LeaveReasonListQuery, LeaveReasonListQueryVariables>;
export const LeaveDiseaseListDocument = gql`
    query LeaveDiseaseList($SubscriptionAccountID: String!) {
  LeaveDiseaseListing(SubscriptionAccountID: $SubscriptionAccountID) {
    LeaveDiseaseID
    SubscriptionAccountID
    Name
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useLeaveDiseaseListQuery__
 *
 * To run a query within a React component, call `useLeaveDiseaseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveDiseaseListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveDiseaseListQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useLeaveDiseaseListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveDiseaseListQuery, LeaveDiseaseListQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveDiseaseListQuery, LeaveDiseaseListQueryVariables>(LeaveDiseaseListDocument, baseOptions);
      }
export function useLeaveDiseaseListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveDiseaseListQuery, LeaveDiseaseListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveDiseaseListQuery, LeaveDiseaseListQueryVariables>(LeaveDiseaseListDocument, baseOptions);
        }
export type LeaveDiseaseListQueryHookResult = ReturnType<typeof useLeaveDiseaseListQuery>;
export type LeaveDiseaseListLazyQueryHookResult = ReturnType<typeof useLeaveDiseaseListLazyQuery>;
export type LeaveDiseaseListQueryResult = ApolloReactCommon.QueryResult<LeaveDiseaseListQuery, LeaveDiseaseListQueryVariables>;
export const LeaveCompanySettingSummaryDocument = gql`
    query LeaveCompanySettingSummary($CompanyID: String!) {
  LeaveCompanySettingSummary(CompanyID: $CompanyID)
}
    `;

/**
 * __useLeaveCompanySettingSummaryQuery__
 *
 * To run a query within a React component, call `useLeaveCompanySettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveCompanySettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveCompanySettingSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLeaveCompanySettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveCompanySettingSummaryQuery, LeaveCompanySettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveCompanySettingSummaryQuery, LeaveCompanySettingSummaryQueryVariables>(LeaveCompanySettingSummaryDocument, baseOptions);
      }
export function useLeaveCompanySettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveCompanySettingSummaryQuery, LeaveCompanySettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveCompanySettingSummaryQuery, LeaveCompanySettingSummaryQueryVariables>(LeaveCompanySettingSummaryDocument, baseOptions);
        }
export type LeaveCompanySettingSummaryQueryHookResult = ReturnType<typeof useLeaveCompanySettingSummaryQuery>;
export type LeaveCompanySettingSummaryLazyQueryHookResult = ReturnType<typeof useLeaveCompanySettingSummaryLazyQuery>;
export type LeaveCompanySettingSummaryQueryResult = ApolloReactCommon.QueryResult<LeaveCompanySettingSummaryQuery, LeaveCompanySettingSummaryQueryVariables>;
export const BlockLeaveEmpListingDocument = gql`
    query BlockLeaveEmpListing($CompanyID: String!, $OrgUnitID: String!, $LeaveTypeID: String!, $FromDate: DateTime!, $UntilDate: DateTime!) {
  BlockLeaveEmpListing(CompanyID: $CompanyID, OrgUnitID: $OrgUnitID, LeaveTypeID: $LeaveTypeID, FromDate: $FromDate, UntilDate: $UntilDate) {
    BlockLeaveID
    SubscriptionAccountID
    CompanyID
    Name
    StartDate
    EndDate
    LeaveTypeID
    LeaveType {
      LeaveTypeID
      Name
    }
    Employee {
      EmployeeID
      ContactID
      OrgUnitID
      Contact {
        FullName
      }
    }
    OrgUnitID
    Status
  }
}
    `;

/**
 * __useBlockLeaveEmpListingQuery__
 *
 * To run a query within a React component, call `useBlockLeaveEmpListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockLeaveEmpListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockLeaveEmpListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      LeaveTypeID: // value for 'LeaveTypeID'
 *      FromDate: // value for 'FromDate'
 *      UntilDate: // value for 'UntilDate'
 *   },
 * });
 */
export function useBlockLeaveEmpListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BlockLeaveEmpListingQuery, BlockLeaveEmpListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BlockLeaveEmpListingQuery, BlockLeaveEmpListingQueryVariables>(BlockLeaveEmpListingDocument, baseOptions);
      }
export function useBlockLeaveEmpListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BlockLeaveEmpListingQuery, BlockLeaveEmpListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BlockLeaveEmpListingQuery, BlockLeaveEmpListingQueryVariables>(BlockLeaveEmpListingDocument, baseOptions);
        }
export type BlockLeaveEmpListingQueryHookResult = ReturnType<typeof useBlockLeaveEmpListingQuery>;
export type BlockLeaveEmpListingLazyQueryHookResult = ReturnType<typeof useBlockLeaveEmpListingLazyQuery>;
export type BlockLeaveEmpListingQueryResult = ApolloReactCommon.QueryResult<BlockLeaveEmpListingQuery, BlockLeaveEmpListingQueryVariables>;
export const GetEmployeeCalendarDocument = gql`
    query getEmployeeCalendar($CompanyID: String!, $EmployeeID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  getEmployeeCalendar(CompanyID: $CompanyID, EmployeeID: $EmployeeID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useGetEmployeeCalendarQuery__
 *
 * To run a query within a React component, call `useGetEmployeeCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeCalendarQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useGetEmployeeCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeCalendarQuery, GetEmployeeCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeCalendarQuery, GetEmployeeCalendarQueryVariables>(GetEmployeeCalendarDocument, baseOptions);
      }
export function useGetEmployeeCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeCalendarQuery, GetEmployeeCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeCalendarQuery, GetEmployeeCalendarQueryVariables>(GetEmployeeCalendarDocument, baseOptions);
        }
export type GetEmployeeCalendarQueryHookResult = ReturnType<typeof useGetEmployeeCalendarQuery>;
export type GetEmployeeCalendarLazyQueryHookResult = ReturnType<typeof useGetEmployeeCalendarLazyQuery>;
export type GetEmployeeCalendarQueryResult = ApolloReactCommon.QueryResult<GetEmployeeCalendarQuery, GetEmployeeCalendarQueryVariables>;
export const GetTakenLeaveByEmployeeIdDocument = gql`
    query GetTakenLeaveByEmployeeID($EmployeeID: String!, $StartDate: String!, $EndDate: String!, $LeaveHeaderID: String) {
  GetTakenLeaveByEmployeeID(EmployeeID: $EmployeeID, StartDate: $StartDate, EndDate: $EndDate, LeaveHeaderID: $LeaveHeaderID) {
    SessionType
    LeaveDate
    NumberOfDay
  }
}
    `;

/**
 * __useGetTakenLeaveByEmployeeIdQuery__
 *
 * To run a query within a React component, call `useGetTakenLeaveByEmployeeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTakenLeaveByEmployeeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTakenLeaveByEmployeeIdQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      LeaveHeaderID: // value for 'LeaveHeaderID'
 *   },
 * });
 */
export function useGetTakenLeaveByEmployeeIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTakenLeaveByEmployeeIdQuery, GetTakenLeaveByEmployeeIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTakenLeaveByEmployeeIdQuery, GetTakenLeaveByEmployeeIdQueryVariables>(GetTakenLeaveByEmployeeIdDocument, baseOptions);
      }
export function useGetTakenLeaveByEmployeeIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTakenLeaveByEmployeeIdQuery, GetTakenLeaveByEmployeeIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTakenLeaveByEmployeeIdQuery, GetTakenLeaveByEmployeeIdQueryVariables>(GetTakenLeaveByEmployeeIdDocument, baseOptions);
        }
export type GetTakenLeaveByEmployeeIdQueryHookResult = ReturnType<typeof useGetTakenLeaveByEmployeeIdQuery>;
export type GetTakenLeaveByEmployeeIdLazyQueryHookResult = ReturnType<typeof useGetTakenLeaveByEmployeeIdLazyQuery>;
export type GetTakenLeaveByEmployeeIdQueryResult = ApolloReactCommon.QueryResult<GetTakenLeaveByEmployeeIdQuery, GetTakenLeaveByEmployeeIdQueryVariables>;
export const GetEmployeeEntitlementByYearDocument = gql`
    query GetEmployeeEntitlementByYear($StartDate: DateTime!, $EndDate: DateTime!, $EmployeeID: String!, $LeaveTypeID: String!, $CompanyID: String!) {
  GetEmployeeEntitlementByYear(StartDate: $StartDate, EndDate: $EndDate, EmployeeID: $EmployeeID, LeaveTypeID: $LeaveTypeID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetEmployeeEntitlementByYearQuery__
 *
 * To run a query within a React component, call `useGetEmployeeEntitlementByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeEntitlementByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeEntitlementByYearQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      EmployeeID: // value for 'EmployeeID'
 *      LeaveTypeID: // value for 'LeaveTypeID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetEmployeeEntitlementByYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeEntitlementByYearQuery, GetEmployeeEntitlementByYearQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeEntitlementByYearQuery, GetEmployeeEntitlementByYearQueryVariables>(GetEmployeeEntitlementByYearDocument, baseOptions);
      }
export function useGetEmployeeEntitlementByYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeEntitlementByYearQuery, GetEmployeeEntitlementByYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeEntitlementByYearQuery, GetEmployeeEntitlementByYearQueryVariables>(GetEmployeeEntitlementByYearDocument, baseOptions);
        }
export type GetEmployeeEntitlementByYearQueryHookResult = ReturnType<typeof useGetEmployeeEntitlementByYearQuery>;
export type GetEmployeeEntitlementByYearLazyQueryHookResult = ReturnType<typeof useGetEmployeeEntitlementByYearLazyQuery>;
export type GetEmployeeEntitlementByYearQueryResult = ApolloReactCommon.QueryResult<GetEmployeeEntitlementByYearQuery, GetEmployeeEntitlementByYearQueryVariables>;
export const GetLeaveByMonthCalendarDocument = gql`
    query GetLeaveByMonthCalendar($CompanyID: String!, $CalendarDate: DateTime!) {
  GetLeaveByMonthCalendar(CompanyID: $CompanyID, CalendarDate: $CalendarDate)
}
    `;

/**
 * __useGetLeaveByMonthCalendarQuery__
 *
 * To run a query within a React component, call `useGetLeaveByMonthCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveByMonthCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveByMonthCalendarQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CalendarDate: // value for 'CalendarDate'
 *   },
 * });
 */
export function useGetLeaveByMonthCalendarQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveByMonthCalendarQuery, GetLeaveByMonthCalendarQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveByMonthCalendarQuery, GetLeaveByMonthCalendarQueryVariables>(GetLeaveByMonthCalendarDocument, baseOptions);
      }
export function useGetLeaveByMonthCalendarLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveByMonthCalendarQuery, GetLeaveByMonthCalendarQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveByMonthCalendarQuery, GetLeaveByMonthCalendarQueryVariables>(GetLeaveByMonthCalendarDocument, baseOptions);
        }
export type GetLeaveByMonthCalendarQueryHookResult = ReturnType<typeof useGetLeaveByMonthCalendarQuery>;
export type GetLeaveByMonthCalendarLazyQueryHookResult = ReturnType<typeof useGetLeaveByMonthCalendarLazyQuery>;
export type GetLeaveByMonthCalendarQueryResult = ApolloReactCommon.QueryResult<GetLeaveByMonthCalendarQuery, GetLeaveByMonthCalendarQueryVariables>;
export const GetLeaveByMonthCalendarToDoDocument = gql`
    query GetLeaveByMonthCalendarToDo($EmployeeID: String!, $CalendarDate: DateTime!) {
  GetLeaveByMonthCalendarToDo(EmployeeID: $EmployeeID, CalendarDate: $CalendarDate)
}
    `;

/**
 * __useGetLeaveByMonthCalendarToDoQuery__
 *
 * To run a query within a React component, call `useGetLeaveByMonthCalendarToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveByMonthCalendarToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveByMonthCalendarToDoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CalendarDate: // value for 'CalendarDate'
 *   },
 * });
 */
export function useGetLeaveByMonthCalendarToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveByMonthCalendarToDoQuery, GetLeaveByMonthCalendarToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveByMonthCalendarToDoQuery, GetLeaveByMonthCalendarToDoQueryVariables>(GetLeaveByMonthCalendarToDoDocument, baseOptions);
      }
export function useGetLeaveByMonthCalendarToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveByMonthCalendarToDoQuery, GetLeaveByMonthCalendarToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveByMonthCalendarToDoQuery, GetLeaveByMonthCalendarToDoQueryVariables>(GetLeaveByMonthCalendarToDoDocument, baseOptions);
        }
export type GetLeaveByMonthCalendarToDoQueryHookResult = ReturnType<typeof useGetLeaveByMonthCalendarToDoQuery>;
export type GetLeaveByMonthCalendarToDoLazyQueryHookResult = ReturnType<typeof useGetLeaveByMonthCalendarToDoLazyQuery>;
export type GetLeaveByMonthCalendarToDoQueryResult = ApolloReactCommon.QueryResult<GetLeaveByMonthCalendarToDoQuery, GetLeaveByMonthCalendarToDoQueryVariables>;
export const LeaveTypeListDocument = gql`
    query LeaveTypeList($SubscriptionAccountID: String!) {
  LeaveTypeListing(SubscriptionAccountID: $SubscriptionAccountID) {
    LeaveTypeID
    LeaveTypeCode
    SubscriptionAccountID
    Name
    PayItemID
    PayItem {
      PayItemID
      PayName
      PayCode
      PayItemType
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    LeavePolicies {
      LeaveItemID
      Title
      LeaveBenefitID
      LeaveBenefit {
        Status
        LeaveBenefitID
        Name
        Description
      }
    }
  }
}
    `;

/**
 * __useLeaveTypeListQuery__
 *
 * To run a query within a React component, call `useLeaveTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveTypeListQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useLeaveTypeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveTypeListQuery, LeaveTypeListQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveTypeListQuery, LeaveTypeListQueryVariables>(LeaveTypeListDocument, baseOptions);
      }
export function useLeaveTypeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveTypeListQuery, LeaveTypeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveTypeListQuery, LeaveTypeListQueryVariables>(LeaveTypeListDocument, baseOptions);
        }
export type LeaveTypeListQueryHookResult = ReturnType<typeof useLeaveTypeListQuery>;
export type LeaveTypeListLazyQueryHookResult = ReturnType<typeof useLeaveTypeListLazyQuery>;
export type LeaveTypeListQueryResult = ApolloReactCommon.QueryResult<LeaveTypeListQuery, LeaveTypeListQueryVariables>;
export const GetLeavePolicyListingDocument = gql`
    query GetLeavePolicyListing($SubscriptionAccountID: String!, $LeaveTypeIDs: [String!], $EntitlementMethodIDs: [String!], $AvailableBasisIDs: [String!], $ShortNoticeOptioIDs: [String!]) {
  LeavePolicyListing(SubscriptionAccountID: $SubscriptionAccountID, LeaveTypeIDs: $LeaveTypeIDs, EntitlementMethodIDs: $EntitlementMethodIDs, AvailableBasisIDs: $AvailableBasisIDs, ShortNoticeOptioIDs: $ShortNoticeOptioIDs) {
    Title
    ModifiedDT
    PlatformLeaveItemID
    LeaveType {
      Name
      LeaveTypeCode
      LeaveTypeID
    }
    LeaveItemID
    LeaveTemplates {
      LeaveTemplateID
    }
    LeaveFrequency
    AvailableBasis
    ShortNoticeType
    IsBackdated
    BackdatedDays
    IsAdvanceLeave
    TotalAdvanceDay
    AdvanceStartMonth
    AdvanceEndMonth
    EncashmentPayItem
    RemainingBalance
    CFMethod
    InUse
  }
}
    `;

/**
 * __useGetLeavePolicyListingQuery__
 *
 * To run a query within a React component, call `useGetLeavePolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeavePolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeavePolicyListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      LeaveTypeIDs: // value for 'LeaveTypeIDs'
 *      EntitlementMethodIDs: // value for 'EntitlementMethodIDs'
 *      AvailableBasisIDs: // value for 'AvailableBasisIDs'
 *      ShortNoticeOptioIDs: // value for 'ShortNoticeOptioIDs'
 *   },
 * });
 */
export function useGetLeavePolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeavePolicyListingQuery, GetLeavePolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeavePolicyListingQuery, GetLeavePolicyListingQueryVariables>(GetLeavePolicyListingDocument, baseOptions);
      }
export function useGetLeavePolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeavePolicyListingQuery, GetLeavePolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeavePolicyListingQuery, GetLeavePolicyListingQueryVariables>(GetLeavePolicyListingDocument, baseOptions);
        }
export type GetLeavePolicyListingQueryHookResult = ReturnType<typeof useGetLeavePolicyListingQuery>;
export type GetLeavePolicyListingLazyQueryHookResult = ReturnType<typeof useGetLeavePolicyListingLazyQuery>;
export type GetLeavePolicyListingQueryResult = ApolloReactCommon.QueryResult<GetLeavePolicyListingQuery, GetLeavePolicyListingQueryVariables>;
export const LeaveRoundingListingDocument = gql`
    query LeaveRoundingListing($LeaveRoundingID: String, $CompanyID: String, $SubscriptionAccountID: String!) {
  LeaveRoundingListing(LeaveRoundingID: $LeaveRoundingID, CompanyID: $CompanyID, SubscriptionAccountID: $SubscriptionAccountID) {
    LeaveRoundingID
    CompanyID
    Title
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    LeaveRoundingDetails {
      StartValue
      EndValue
      RoundTo
    }
    Companies {
      CompanyName
      LeaveRoundingID
    }
  }
}
    `;

/**
 * __useLeaveRoundingListingQuery__
 *
 * To run a query within a React component, call `useLeaveRoundingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveRoundingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveRoundingListingQuery({
 *   variables: {
 *      LeaveRoundingID: // value for 'LeaveRoundingID'
 *      CompanyID: // value for 'CompanyID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useLeaveRoundingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveRoundingListingQuery, LeaveRoundingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveRoundingListingQuery, LeaveRoundingListingQueryVariables>(LeaveRoundingListingDocument, baseOptions);
      }
export function useLeaveRoundingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveRoundingListingQuery, LeaveRoundingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveRoundingListingQuery, LeaveRoundingListingQueryVariables>(LeaveRoundingListingDocument, baseOptions);
        }
export type LeaveRoundingListingQueryHookResult = ReturnType<typeof useLeaveRoundingListingQuery>;
export type LeaveRoundingListingLazyQueryHookResult = ReturnType<typeof useLeaveRoundingListingLazyQuery>;
export type LeaveRoundingListingQueryResult = ApolloReactCommon.QueryResult<LeaveRoundingListingQuery, LeaveRoundingListingQueryVariables>;
export const LeaveReasonInsertDocument = gql`
    mutation LeaveReasonInsert($input: LeaveReasonInput!) {
  LeaveReasonInsert(input: $input) {
    LeaveReasonID
  }
}
    `;
export type LeaveReasonInsertMutationFn = ApolloReactCommon.MutationFunction<LeaveReasonInsertMutation, LeaveReasonInsertMutationVariables>;

/**
 * __useLeaveReasonInsertMutation__
 *
 * To run a mutation, you first call `useLeaveReasonInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveReasonInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveReasonInsertMutation, { data, loading, error }] = useLeaveReasonInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveReasonInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveReasonInsertMutation, LeaveReasonInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveReasonInsertMutation, LeaveReasonInsertMutationVariables>(LeaveReasonInsertDocument, baseOptions);
      }
export type LeaveReasonInsertMutationHookResult = ReturnType<typeof useLeaveReasonInsertMutation>;
export type LeaveReasonInsertMutationResult = ApolloReactCommon.MutationResult<LeaveReasonInsertMutation>;
export type LeaveReasonInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveReasonInsertMutation, LeaveReasonInsertMutationVariables>;
export const LeaveReasonUpdateDocument = gql`
    mutation LeaveReasonUpdate($LeaveReasonID: String!, $input: LeaveReasonInput!) {
  LeaveReasonUpdate(LeaveReasonID: $LeaveReasonID, input: $input)
}
    `;
export type LeaveReasonUpdateMutationFn = ApolloReactCommon.MutationFunction<LeaveReasonUpdateMutation, LeaveReasonUpdateMutationVariables>;

/**
 * __useLeaveReasonUpdateMutation__
 *
 * To run a mutation, you first call `useLeaveReasonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveReasonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveReasonUpdateMutation, { data, loading, error }] = useLeaveReasonUpdateMutation({
 *   variables: {
 *      LeaveReasonID: // value for 'LeaveReasonID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveReasonUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveReasonUpdateMutation, LeaveReasonUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveReasonUpdateMutation, LeaveReasonUpdateMutationVariables>(LeaveReasonUpdateDocument, baseOptions);
      }
export type LeaveReasonUpdateMutationHookResult = ReturnType<typeof useLeaveReasonUpdateMutation>;
export type LeaveReasonUpdateMutationResult = ApolloReactCommon.MutationResult<LeaveReasonUpdateMutation>;
export type LeaveReasonUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveReasonUpdateMutation, LeaveReasonUpdateMutationVariables>;
export const LeaveReasonDeleteDocument = gql`
    mutation LeaveReasonDelete($LeaveReasonID: String!) {
  LeaveReasonDelete(LeaveReasonID: $LeaveReasonID)
}
    `;
export type LeaveReasonDeleteMutationFn = ApolloReactCommon.MutationFunction<LeaveReasonDeleteMutation, LeaveReasonDeleteMutationVariables>;

/**
 * __useLeaveReasonDeleteMutation__
 *
 * To run a mutation, you first call `useLeaveReasonDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveReasonDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveReasonDeleteMutation, { data, loading, error }] = useLeaveReasonDeleteMutation({
 *   variables: {
 *      LeaveReasonID: // value for 'LeaveReasonID'
 *   },
 * });
 */
export function useLeaveReasonDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveReasonDeleteMutation, LeaveReasonDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveReasonDeleteMutation, LeaveReasonDeleteMutationVariables>(LeaveReasonDeleteDocument, baseOptions);
      }
export type LeaveReasonDeleteMutationHookResult = ReturnType<typeof useLeaveReasonDeleteMutation>;
export type LeaveReasonDeleteMutationResult = ApolloReactCommon.MutationResult<LeaveReasonDeleteMutation>;
export type LeaveReasonDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveReasonDeleteMutation, LeaveReasonDeleteMutationVariables>;
export const LeaveDiseaseInsertDocument = gql`
    mutation LeaveDiseaseInsert($input: LeaveDiseaseInput!) {
  LeaveDiseaseInsert(input: $input) {
    LeaveDiseaseID
  }
}
    `;
export type LeaveDiseaseInsertMutationFn = ApolloReactCommon.MutationFunction<LeaveDiseaseInsertMutation, LeaveDiseaseInsertMutationVariables>;

/**
 * __useLeaveDiseaseInsertMutation__
 *
 * To run a mutation, you first call `useLeaveDiseaseInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveDiseaseInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveDiseaseInsertMutation, { data, loading, error }] = useLeaveDiseaseInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveDiseaseInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveDiseaseInsertMutation, LeaveDiseaseInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveDiseaseInsertMutation, LeaveDiseaseInsertMutationVariables>(LeaveDiseaseInsertDocument, baseOptions);
      }
export type LeaveDiseaseInsertMutationHookResult = ReturnType<typeof useLeaveDiseaseInsertMutation>;
export type LeaveDiseaseInsertMutationResult = ApolloReactCommon.MutationResult<LeaveDiseaseInsertMutation>;
export type LeaveDiseaseInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveDiseaseInsertMutation, LeaveDiseaseInsertMutationVariables>;
export const LeaveDiseaseUpdateDocument = gql`
    mutation LeaveDiseaseUpdate($LeaveDiseaseID: String!, $input: LeaveDiseaseInput!) {
  LeaveDiseaseUpdate(LeaveDiseaseID: $LeaveDiseaseID, input: $input)
}
    `;
export type LeaveDiseaseUpdateMutationFn = ApolloReactCommon.MutationFunction<LeaveDiseaseUpdateMutation, LeaveDiseaseUpdateMutationVariables>;

/**
 * __useLeaveDiseaseUpdateMutation__
 *
 * To run a mutation, you first call `useLeaveDiseaseUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveDiseaseUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveDiseaseUpdateMutation, { data, loading, error }] = useLeaveDiseaseUpdateMutation({
 *   variables: {
 *      LeaveDiseaseID: // value for 'LeaveDiseaseID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveDiseaseUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveDiseaseUpdateMutation, LeaveDiseaseUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveDiseaseUpdateMutation, LeaveDiseaseUpdateMutationVariables>(LeaveDiseaseUpdateDocument, baseOptions);
      }
export type LeaveDiseaseUpdateMutationHookResult = ReturnType<typeof useLeaveDiseaseUpdateMutation>;
export type LeaveDiseaseUpdateMutationResult = ApolloReactCommon.MutationResult<LeaveDiseaseUpdateMutation>;
export type LeaveDiseaseUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveDiseaseUpdateMutation, LeaveDiseaseUpdateMutationVariables>;
export const LeaveDiseaseDeleteDocument = gql`
    mutation LeaveDiseaseDelete($LeaveDiseaseID: String!) {
  LeaveDiseaseDelete(LeaveDiseaseID: $LeaveDiseaseID)
}
    `;
export type LeaveDiseaseDeleteMutationFn = ApolloReactCommon.MutationFunction<LeaveDiseaseDeleteMutation, LeaveDiseaseDeleteMutationVariables>;

/**
 * __useLeaveDiseaseDeleteMutation__
 *
 * To run a mutation, you first call `useLeaveDiseaseDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveDiseaseDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveDiseaseDeleteMutation, { data, loading, error }] = useLeaveDiseaseDeleteMutation({
 *   variables: {
 *      LeaveDiseaseID: // value for 'LeaveDiseaseID'
 *   },
 * });
 */
export function useLeaveDiseaseDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveDiseaseDeleteMutation, LeaveDiseaseDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveDiseaseDeleteMutation, LeaveDiseaseDeleteMutationVariables>(LeaveDiseaseDeleteDocument, baseOptions);
      }
export type LeaveDiseaseDeleteMutationHookResult = ReturnType<typeof useLeaveDiseaseDeleteMutation>;
export type LeaveDiseaseDeleteMutationResult = ApolloReactCommon.MutationResult<LeaveDiseaseDeleteMutation>;
export type LeaveDiseaseDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveDiseaseDeleteMutation, LeaveDiseaseDeleteMutationVariables>;
export const LeaveTypePayItemListingDocument = gql`
    query LeaveTypePayItemListing($SubscriptionAccountID: String!) {
  LeaveTypePayItemListing(SubscriptionAccountID: $SubscriptionAccountID) {
    PayItemID
    CompanyID
    PayCode
    PayName
    PayItemType
  }
}
    `;

/**
 * __useLeaveTypePayItemListingQuery__
 *
 * To run a query within a React component, call `useLeaveTypePayItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveTypePayItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveTypePayItemListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useLeaveTypePayItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveTypePayItemListingQuery, LeaveTypePayItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveTypePayItemListingQuery, LeaveTypePayItemListingQueryVariables>(LeaveTypePayItemListingDocument, baseOptions);
      }
export function useLeaveTypePayItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveTypePayItemListingQuery, LeaveTypePayItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveTypePayItemListingQuery, LeaveTypePayItemListingQueryVariables>(LeaveTypePayItemListingDocument, baseOptions);
        }
export type LeaveTypePayItemListingQueryHookResult = ReturnType<typeof useLeaveTypePayItemListingQuery>;
export type LeaveTypePayItemListingLazyQueryHookResult = ReturnType<typeof useLeaveTypePayItemListingLazyQuery>;
export type LeaveTypePayItemListingQueryResult = ApolloReactCommon.QueryResult<LeaveTypePayItemListingQuery, LeaveTypePayItemListingQueryVariables>;
export const LeaveTypeInsertDocument = gql`
    mutation LeaveTypeInsert($input: LeaveTypeInput!) {
  LeaveTypeInsert(input: $input) {
    LeaveTypeID
  }
}
    `;
export type LeaveTypeInsertMutationFn = ApolloReactCommon.MutationFunction<LeaveTypeInsertMutation, LeaveTypeInsertMutationVariables>;

/**
 * __useLeaveTypeInsertMutation__
 *
 * To run a mutation, you first call `useLeaveTypeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTypeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTypeInsertMutation, { data, loading, error }] = useLeaveTypeInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveTypeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveTypeInsertMutation, LeaveTypeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveTypeInsertMutation, LeaveTypeInsertMutationVariables>(LeaveTypeInsertDocument, baseOptions);
      }
export type LeaveTypeInsertMutationHookResult = ReturnType<typeof useLeaveTypeInsertMutation>;
export type LeaveTypeInsertMutationResult = ApolloReactCommon.MutationResult<LeaveTypeInsertMutation>;
export type LeaveTypeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveTypeInsertMutation, LeaveTypeInsertMutationVariables>;
export const LeaveTypeUpdateDocument = gql`
    mutation LeaveTypeUpdate($LeaveTypeID: String!, $input: LeaveTypeInput!) {
  LeaveTypeUpdate(LeaveTypeID: $LeaveTypeID, input: $input)
}
    `;
export type LeaveTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<LeaveTypeUpdateMutation, LeaveTypeUpdateMutationVariables>;

/**
 * __useLeaveTypeUpdateMutation__
 *
 * To run a mutation, you first call `useLeaveTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTypeUpdateMutation, { data, loading, error }] = useLeaveTypeUpdateMutation({
 *   variables: {
 *      LeaveTypeID: // value for 'LeaveTypeID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveTypeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveTypeUpdateMutation, LeaveTypeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveTypeUpdateMutation, LeaveTypeUpdateMutationVariables>(LeaveTypeUpdateDocument, baseOptions);
      }
export type LeaveTypeUpdateMutationHookResult = ReturnType<typeof useLeaveTypeUpdateMutation>;
export type LeaveTypeUpdateMutationResult = ApolloReactCommon.MutationResult<LeaveTypeUpdateMutation>;
export type LeaveTypeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveTypeUpdateMutation, LeaveTypeUpdateMutationVariables>;
export const LeaveTypeDeleteDocument = gql`
    mutation LeaveTypeDelete($LeaveTypeID: String!) {
  LeaveTypeDelete(LeaveTypeID: $LeaveTypeID)
}
    `;
export type LeaveTypeDeleteMutationFn = ApolloReactCommon.MutationFunction<LeaveTypeDeleteMutation, LeaveTypeDeleteMutationVariables>;

/**
 * __useLeaveTypeDeleteMutation__
 *
 * To run a mutation, you first call `useLeaveTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTypeDeleteMutation, { data, loading, error }] = useLeaveTypeDeleteMutation({
 *   variables: {
 *      LeaveTypeID: // value for 'LeaveTypeID'
 *   },
 * });
 */
export function useLeaveTypeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveTypeDeleteMutation, LeaveTypeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveTypeDeleteMutation, LeaveTypeDeleteMutationVariables>(LeaveTypeDeleteDocument, baseOptions);
      }
export type LeaveTypeDeleteMutationHookResult = ReturnType<typeof useLeaveTypeDeleteMutation>;
export type LeaveTypeDeleteMutationResult = ApolloReactCommon.MutationResult<LeaveTypeDeleteMutation>;
export type LeaveTypeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveTypeDeleteMutation, LeaveTypeDeleteMutationVariables>;
export const LeavePolicyDeleteDocument = gql`
    mutation LeavePolicyDelete($LeavePolicyID: String!) {
  LeavePolicyDelete(LeavePolicyID: $LeavePolicyID)
}
    `;
export type LeavePolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<LeavePolicyDeleteMutation, LeavePolicyDeleteMutationVariables>;

/**
 * __useLeavePolicyDeleteMutation__
 *
 * To run a mutation, you first call `useLeavePolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeavePolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leavePolicyDeleteMutation, { data, loading, error }] = useLeavePolicyDeleteMutation({
 *   variables: {
 *      LeavePolicyID: // value for 'LeavePolicyID'
 *   },
 * });
 */
export function useLeavePolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeavePolicyDeleteMutation, LeavePolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<LeavePolicyDeleteMutation, LeavePolicyDeleteMutationVariables>(LeavePolicyDeleteDocument, baseOptions);
      }
export type LeavePolicyDeleteMutationHookResult = ReturnType<typeof useLeavePolicyDeleteMutation>;
export type LeavePolicyDeleteMutationResult = ApolloReactCommon.MutationResult<LeavePolicyDeleteMutation>;
export type LeavePolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<LeavePolicyDeleteMutation, LeavePolicyDeleteMutationVariables>;
export const GetLeavePolicyByIdDocument = gql`
    query GetLeavePolicyByID($LeavePolicyID: String!) {
  LeavePolicyByID(LeavePolicyID: $LeavePolicyID) {
    Title
    LeaveTypeID
    LeaveFrequency
    AvailableBasis
    EntitlementBasedOn
    CalculationMethod
    NewCutOffDay
    ResignCutOffDay
    MonthlyCutOffDay
    CFMethod
    CFValue
    CFMonth
    ShortNoticePeriod
    IsBackdated
    BackdatedDays
    EncashmentPayItem
    RemainingBalance
    ShortNoticeType
    IsPublicHoliday
    IsOffDay
    IsRestDay
    IsHalfDay
    IsConfirmedStaff
    IsAttachment
    IsRemarks
    IsRounding
    IsAdvanceLeave
    TotalAdvanceDay
    AdvanceStartMonth
    AdvanceEndMonth
    IsDisplayEmployee
    IsLeaveReason
    IsLeaveDisease
    IncludeSickLeave
    ModifiedDT
    Occurrence
    NoOccurrence
    OccurrenceEntitlementDay
    LeaveType {
      Name
      LeaveTypeCode
    }
    LeaveItemID
    LOS {
      FormulaText
      LeaveBenefitRuleID
      TotalDay
      Status
    }
    EntDistributionMethod
    PlatformLeaveItemID
    EntitlementComputation
    MaxApplyLeave
    EntitlementAvailability
  }
}
    `;

/**
 * __useGetLeavePolicyByIdQuery__
 *
 * To run a query within a React component, call `useGetLeavePolicyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeavePolicyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeavePolicyByIdQuery({
 *   variables: {
 *      LeavePolicyID: // value for 'LeavePolicyID'
 *   },
 * });
 */
export function useGetLeavePolicyByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeavePolicyByIdQuery, GetLeavePolicyByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeavePolicyByIdQuery, GetLeavePolicyByIdQueryVariables>(GetLeavePolicyByIdDocument, baseOptions);
      }
export function useGetLeavePolicyByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeavePolicyByIdQuery, GetLeavePolicyByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeavePolicyByIdQuery, GetLeavePolicyByIdQueryVariables>(GetLeavePolicyByIdDocument, baseOptions);
        }
export type GetLeavePolicyByIdQueryHookResult = ReturnType<typeof useGetLeavePolicyByIdQuery>;
export type GetLeavePolicyByIdLazyQueryHookResult = ReturnType<typeof useGetLeavePolicyByIdLazyQuery>;
export type GetLeavePolicyByIdQueryResult = ApolloReactCommon.QueryResult<GetLeavePolicyByIdQuery, GetLeavePolicyByIdQueryVariables>;
export const LeavePolicyInsertDocument = gql`
    mutation LeavePolicyInsert($LeavePolicyInput: LeavePolicyInput!, $LOSInput: [LOSInput!]) {
  LeavePolicyInsert(leavePolicyInput: $LeavePolicyInput, LOSInput: $LOSInput)
}
    `;
export type LeavePolicyInsertMutationFn = ApolloReactCommon.MutationFunction<LeavePolicyInsertMutation, LeavePolicyInsertMutationVariables>;

/**
 * __useLeavePolicyInsertMutation__
 *
 * To run a mutation, you first call `useLeavePolicyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeavePolicyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leavePolicyInsertMutation, { data, loading, error }] = useLeavePolicyInsertMutation({
 *   variables: {
 *      LeavePolicyInput: // value for 'LeavePolicyInput'
 *      LOSInput: // value for 'LOSInput'
 *   },
 * });
 */
export function useLeavePolicyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeavePolicyInsertMutation, LeavePolicyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LeavePolicyInsertMutation, LeavePolicyInsertMutationVariables>(LeavePolicyInsertDocument, baseOptions);
      }
export type LeavePolicyInsertMutationHookResult = ReturnType<typeof useLeavePolicyInsertMutation>;
export type LeavePolicyInsertMutationResult = ApolloReactCommon.MutationResult<LeavePolicyInsertMutation>;
export type LeavePolicyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LeavePolicyInsertMutation, LeavePolicyInsertMutationVariables>;
export const LeavePolicyUpdateDocument = gql`
    mutation LeavePolicyUpdate($LeavePolicyInput: LeavePolicyInput!, $LOSInput: [LOSInput!]) {
  LeavePolicyUpdate(leavePolicyInput: $LeavePolicyInput, LOSInput: $LOSInput)
}
    `;
export type LeavePolicyUpdateMutationFn = ApolloReactCommon.MutationFunction<LeavePolicyUpdateMutation, LeavePolicyUpdateMutationVariables>;

/**
 * __useLeavePolicyUpdateMutation__
 *
 * To run a mutation, you first call `useLeavePolicyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeavePolicyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leavePolicyUpdateMutation, { data, loading, error }] = useLeavePolicyUpdateMutation({
 *   variables: {
 *      LeavePolicyInput: // value for 'LeavePolicyInput'
 *      LOSInput: // value for 'LOSInput'
 *   },
 * });
 */
export function useLeavePolicyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeavePolicyUpdateMutation, LeavePolicyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LeavePolicyUpdateMutation, LeavePolicyUpdateMutationVariables>(LeavePolicyUpdateDocument, baseOptions);
      }
export type LeavePolicyUpdateMutationHookResult = ReturnType<typeof useLeavePolicyUpdateMutation>;
export type LeavePolicyUpdateMutationResult = ApolloReactCommon.MutationResult<LeavePolicyUpdateMutation>;
export type LeavePolicyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LeavePolicyUpdateMutation, LeavePolicyUpdateMutationVariables>;
export const PayItemByPayTypeDocument = gql`
    query PayItemByPayType($PayType: [String!]!, $PayItemID: String) {
  PayItemByPayType(PayType: $PayType, PayItemID: $PayItemID) {
    PayItemID
    PayName
    PayItemType
  }
}
    `;

/**
 * __usePayItemByPayTypeQuery__
 *
 * To run a query within a React component, call `usePayItemByPayTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayItemByPayTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayItemByPayTypeQuery({
 *   variables: {
 *      PayType: // value for 'PayType'
 *      PayItemID: // value for 'PayItemID'
 *   },
 * });
 */
export function usePayItemByPayTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayItemByPayTypeQuery, PayItemByPayTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<PayItemByPayTypeQuery, PayItemByPayTypeQueryVariables>(PayItemByPayTypeDocument, baseOptions);
      }
export function usePayItemByPayTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayItemByPayTypeQuery, PayItemByPayTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayItemByPayTypeQuery, PayItemByPayTypeQueryVariables>(PayItemByPayTypeDocument, baseOptions);
        }
export type PayItemByPayTypeQueryHookResult = ReturnType<typeof usePayItemByPayTypeQuery>;
export type PayItemByPayTypeLazyQueryHookResult = ReturnType<typeof usePayItemByPayTypeLazyQuery>;
export type PayItemByPayTypeQueryResult = ApolloReactCommon.QueryResult<PayItemByPayTypeQuery, PayItemByPayTypeQueryVariables>;
export const LeaveRoundingDeleteDocument = gql`
    mutation LeaveRoundingDelete($LeaveRoundingID: String!) {
  LeaveRoundingDelete(LeaveRoundingID: $LeaveRoundingID)
}
    `;
export type LeaveRoundingDeleteMutationFn = ApolloReactCommon.MutationFunction<LeaveRoundingDeleteMutation, LeaveRoundingDeleteMutationVariables>;

/**
 * __useLeaveRoundingDeleteMutation__
 *
 * To run a mutation, you first call `useLeaveRoundingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveRoundingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveRoundingDeleteMutation, { data, loading, error }] = useLeaveRoundingDeleteMutation({
 *   variables: {
 *      LeaveRoundingID: // value for 'LeaveRoundingID'
 *   },
 * });
 */
export function useLeaveRoundingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveRoundingDeleteMutation, LeaveRoundingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveRoundingDeleteMutation, LeaveRoundingDeleteMutationVariables>(LeaveRoundingDeleteDocument, baseOptions);
      }
export type LeaveRoundingDeleteMutationHookResult = ReturnType<typeof useLeaveRoundingDeleteMutation>;
export type LeaveRoundingDeleteMutationResult = ApolloReactCommon.MutationResult<LeaveRoundingDeleteMutation>;
export type LeaveRoundingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveRoundingDeleteMutation, LeaveRoundingDeleteMutationVariables>;
export const LeaveRoundingInsertDocument = gql`
    mutation LeaveRoundingInsert($LeaveRoundingInput: LeaveRoundingInput!, $LeaveRoundingDetailInput: [LeaveRoundingDetailInput!]!) {
  LeaveRoundingInsert(LeaveRoundingInput: $LeaveRoundingInput, LeaveRoundingDetailInput: $LeaveRoundingDetailInput)
}
    `;
export type LeaveRoundingInsertMutationFn = ApolloReactCommon.MutationFunction<LeaveRoundingInsertMutation, LeaveRoundingInsertMutationVariables>;

/**
 * __useLeaveRoundingInsertMutation__
 *
 * To run a mutation, you first call `useLeaveRoundingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveRoundingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveRoundingInsertMutation, { data, loading, error }] = useLeaveRoundingInsertMutation({
 *   variables: {
 *      LeaveRoundingInput: // value for 'LeaveRoundingInput'
 *      LeaveRoundingDetailInput: // value for 'LeaveRoundingDetailInput'
 *   },
 * });
 */
export function useLeaveRoundingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveRoundingInsertMutation, LeaveRoundingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveRoundingInsertMutation, LeaveRoundingInsertMutationVariables>(LeaveRoundingInsertDocument, baseOptions);
      }
export type LeaveRoundingInsertMutationHookResult = ReturnType<typeof useLeaveRoundingInsertMutation>;
export type LeaveRoundingInsertMutationResult = ApolloReactCommon.MutationResult<LeaveRoundingInsertMutation>;
export type LeaveRoundingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveRoundingInsertMutation, LeaveRoundingInsertMutationVariables>;
export const UpdateLeaveRoundingDocument = gql`
    mutation UpdateLeaveRounding($LeaveRoundingID: String!, $LeaveRoundingInput: LeaveRoundingInput!, $LeaveRoundingDetailInput: [LeaveRoundingDetailInput!]!) {
  UpdateLeaveRounding(LeaveRoundingID: $LeaveRoundingID, LeaveRoundingInput: $LeaveRoundingInput, LeaveRoundingDetailInput: $LeaveRoundingDetailInput)
}
    `;
export type UpdateLeaveRoundingMutationFn = ApolloReactCommon.MutationFunction<UpdateLeaveRoundingMutation, UpdateLeaveRoundingMutationVariables>;

/**
 * __useUpdateLeaveRoundingMutation__
 *
 * To run a mutation, you first call `useUpdateLeaveRoundingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeaveRoundingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeaveRoundingMutation, { data, loading, error }] = useUpdateLeaveRoundingMutation({
 *   variables: {
 *      LeaveRoundingID: // value for 'LeaveRoundingID'
 *      LeaveRoundingInput: // value for 'LeaveRoundingInput'
 *      LeaveRoundingDetailInput: // value for 'LeaveRoundingDetailInput'
 *   },
 * });
 */
export function useUpdateLeaveRoundingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLeaveRoundingMutation, UpdateLeaveRoundingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLeaveRoundingMutation, UpdateLeaveRoundingMutationVariables>(UpdateLeaveRoundingDocument, baseOptions);
      }
export type UpdateLeaveRoundingMutationHookResult = ReturnType<typeof useUpdateLeaveRoundingMutation>;
export type UpdateLeaveRoundingMutationResult = ApolloReactCommon.MutationResult<UpdateLeaveRoundingMutation>;
export type UpdateLeaveRoundingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLeaveRoundingMutation, UpdateLeaveRoundingMutationVariables>;
export const JobGradeListingByCompanyDocument = gql`
    query JobGradeListingByCompany($SubscriptionAccountID: String!) {
  JobGradeListingByCompany(SubscriptionAccountID: $SubscriptionAccountID) {
    JobGradeID
    SubscriptionAccountID
    Description
    LeaveBenefitID
    ClaimBenefitID
    JobDesignationGrades {
      JobDesignationGradeID
      JobDesignationID
      JobGradeID
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useJobGradeListingByCompanyQuery__
 *
 * To run a query within a React component, call `useJobGradeListingByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobGradeListingByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobGradeListingByCompanyQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useJobGradeListingByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobGradeListingByCompanyQuery, JobGradeListingByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<JobGradeListingByCompanyQuery, JobGradeListingByCompanyQueryVariables>(JobGradeListingByCompanyDocument, baseOptions);
      }
export function useJobGradeListingByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobGradeListingByCompanyQuery, JobGradeListingByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobGradeListingByCompanyQuery, JobGradeListingByCompanyQueryVariables>(JobGradeListingByCompanyDocument, baseOptions);
        }
export type JobGradeListingByCompanyQueryHookResult = ReturnType<typeof useJobGradeListingByCompanyQuery>;
export type JobGradeListingByCompanyLazyQueryHookResult = ReturnType<typeof useJobGradeListingByCompanyLazyQuery>;
export type JobGradeListingByCompanyQueryResult = ApolloReactCommon.QueryResult<JobGradeListingByCompanyQuery, JobGradeListingByCompanyQueryVariables>;
export const LeaveCompulsoryPolicyListingDocument = gql`
    query LeaveCompulsoryPolicyListing($CompanyID: String!) {
  LeaveCompulsoryPolicyListing(CompanyID: $CompanyID) {
    LeaveCompulsoryPolicyID
    CompanyID
    LeaveTypeID
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useLeaveCompulsoryPolicyListingQuery__
 *
 * To run a query within a React component, call `useLeaveCompulsoryPolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveCompulsoryPolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveCompulsoryPolicyListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLeaveCompulsoryPolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveCompulsoryPolicyListingQuery, LeaveCompulsoryPolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveCompulsoryPolicyListingQuery, LeaveCompulsoryPolicyListingQueryVariables>(LeaveCompulsoryPolicyListingDocument, baseOptions);
      }
export function useLeaveCompulsoryPolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveCompulsoryPolicyListingQuery, LeaveCompulsoryPolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveCompulsoryPolicyListingQuery, LeaveCompulsoryPolicyListingQueryVariables>(LeaveCompulsoryPolicyListingDocument, baseOptions);
        }
export type LeaveCompulsoryPolicyListingQueryHookResult = ReturnType<typeof useLeaveCompulsoryPolicyListingQuery>;
export type LeaveCompulsoryPolicyListingLazyQueryHookResult = ReturnType<typeof useLeaveCompulsoryPolicyListingLazyQuery>;
export type LeaveCompulsoryPolicyListingQueryResult = ApolloReactCommon.QueryResult<LeaveCompulsoryPolicyListingQuery, LeaveCompulsoryPolicyListingQueryVariables>;
export const ApprovalPolicyAssignmentListDocument = gql`
    query ApprovalPolicyAssignmentList($CompanyID: String!, $SubscriptionAccountID: String!, $ID: String, $Category: String!) {
  ApprovalPolicyAssignmentList(CompanyID: $CompanyID, SubscriptionAccountID: $SubscriptionAccountID, ID: $ID, Category: $Category) {
    Status
    ID
    ApprovalPolicyID
    ModifiedDT
    ApprovalPolicy {
      SubscriptionAccountID
      Title
    }
  }
}
    `;

/**
 * __useApprovalPolicyAssignmentListQuery__
 *
 * To run a query within a React component, call `useApprovalPolicyAssignmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyAssignmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalPolicyAssignmentListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      ID: // value for 'ID'
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useApprovalPolicyAssignmentListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovalPolicyAssignmentListQuery, ApprovalPolicyAssignmentListQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovalPolicyAssignmentListQuery, ApprovalPolicyAssignmentListQueryVariables>(ApprovalPolicyAssignmentListDocument, baseOptions);
      }
export function useApprovalPolicyAssignmentListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovalPolicyAssignmentListQuery, ApprovalPolicyAssignmentListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovalPolicyAssignmentListQuery, ApprovalPolicyAssignmentListQueryVariables>(ApprovalPolicyAssignmentListDocument, baseOptions);
        }
export type ApprovalPolicyAssignmentListQueryHookResult = ReturnType<typeof useApprovalPolicyAssignmentListQuery>;
export type ApprovalPolicyAssignmentListLazyQueryHookResult = ReturnType<typeof useApprovalPolicyAssignmentListLazyQuery>;
export type ApprovalPolicyAssignmentListQueryResult = ApolloReactCommon.QueryResult<ApprovalPolicyAssignmentListQuery, ApprovalPolicyAssignmentListQueryVariables>;
export const LeaveJobGradePolicyInsertDocument = gql`
    mutation LeaveJobGradePolicyInsert($JobGradePolicyInput: JobGradePolicyInput!, $LeaveTemplateInput: [LeaveTemplateInput!]!) {
  LeaveJobGradePolicyInsert(JobGradePolicyInput: $JobGradePolicyInput, LeaveTemplateInput: $LeaveTemplateInput)
}
    `;
export type LeaveJobGradePolicyInsertMutationFn = ApolloReactCommon.MutationFunction<LeaveJobGradePolicyInsertMutation, LeaveJobGradePolicyInsertMutationVariables>;

/**
 * __useLeaveJobGradePolicyInsertMutation__
 *
 * To run a mutation, you first call `useLeaveJobGradePolicyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveJobGradePolicyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveJobGradePolicyInsertMutation, { data, loading, error }] = useLeaveJobGradePolicyInsertMutation({
 *   variables: {
 *      JobGradePolicyInput: // value for 'JobGradePolicyInput'
 *      LeaveTemplateInput: // value for 'LeaveTemplateInput'
 *   },
 * });
 */
export function useLeaveJobGradePolicyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveJobGradePolicyInsertMutation, LeaveJobGradePolicyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveJobGradePolicyInsertMutation, LeaveJobGradePolicyInsertMutationVariables>(LeaveJobGradePolicyInsertDocument, baseOptions);
      }
export type LeaveJobGradePolicyInsertMutationHookResult = ReturnType<typeof useLeaveJobGradePolicyInsertMutation>;
export type LeaveJobGradePolicyInsertMutationResult = ApolloReactCommon.MutationResult<LeaveJobGradePolicyInsertMutation>;
export type LeaveJobGradePolicyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveJobGradePolicyInsertMutation, LeaveJobGradePolicyInsertMutationVariables>;
export const LeaveJobGradePolicyUpdateDocument = gql`
    mutation LeaveJobGradePolicyUpdate($JobGradePolicyID: String!, $JobGradePolicyInput: JobGradePolicyInput!, $LeaveTemplateInput: [LeaveTemplateInput!]!) {
  LeaveJobGradePolicyUpdate(JobGradePolicyID: $JobGradePolicyID, JobGradePolicyInput: $JobGradePolicyInput, LeaveTemplateInput: $LeaveTemplateInput)
}
    `;
export type LeaveJobGradePolicyUpdateMutationFn = ApolloReactCommon.MutationFunction<LeaveJobGradePolicyUpdateMutation, LeaveJobGradePolicyUpdateMutationVariables>;

/**
 * __useLeaveJobGradePolicyUpdateMutation__
 *
 * To run a mutation, you first call `useLeaveJobGradePolicyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveJobGradePolicyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveJobGradePolicyUpdateMutation, { data, loading, error }] = useLeaveJobGradePolicyUpdateMutation({
 *   variables: {
 *      JobGradePolicyID: // value for 'JobGradePolicyID'
 *      JobGradePolicyInput: // value for 'JobGradePolicyInput'
 *      LeaveTemplateInput: // value for 'LeaveTemplateInput'
 *   },
 * });
 */
export function useLeaveJobGradePolicyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveJobGradePolicyUpdateMutation, LeaveJobGradePolicyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveJobGradePolicyUpdateMutation, LeaveJobGradePolicyUpdateMutationVariables>(LeaveJobGradePolicyUpdateDocument, baseOptions);
      }
export type LeaveJobGradePolicyUpdateMutationHookResult = ReturnType<typeof useLeaveJobGradePolicyUpdateMutation>;
export type LeaveJobGradePolicyUpdateMutationResult = ApolloReactCommon.MutationResult<LeaveJobGradePolicyUpdateMutation>;
export type LeaveJobGradePolicyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveJobGradePolicyUpdateMutation, LeaveJobGradePolicyUpdateMutationVariables>;
export const LeaveTypeConditionUpdateDocument = gql`
    mutation LeaveTypeConditionUpdate($LeaveTemplateInput: LeaveTemplateInput!) {
  LeaveTypeConditionUpdate(LeaveTemplateInput: $LeaveTemplateInput)
}
    `;
export type LeaveTypeConditionUpdateMutationFn = ApolloReactCommon.MutationFunction<LeaveTypeConditionUpdateMutation, LeaveTypeConditionUpdateMutationVariables>;

/**
 * __useLeaveTypeConditionUpdateMutation__
 *
 * To run a mutation, you first call `useLeaveTypeConditionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTypeConditionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTypeConditionUpdateMutation, { data, loading, error }] = useLeaveTypeConditionUpdateMutation({
 *   variables: {
 *      LeaveTemplateInput: // value for 'LeaveTemplateInput'
 *   },
 * });
 */
export function useLeaveTypeConditionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveTypeConditionUpdateMutation, LeaveTypeConditionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveTypeConditionUpdateMutation, LeaveTypeConditionUpdateMutationVariables>(LeaveTypeConditionUpdateDocument, baseOptions);
      }
export type LeaveTypeConditionUpdateMutationHookResult = ReturnType<typeof useLeaveTypeConditionUpdateMutation>;
export type LeaveTypeConditionUpdateMutationResult = ApolloReactCommon.MutationResult<LeaveTypeConditionUpdateMutation>;
export type LeaveTypeConditionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveTypeConditionUpdateMutation, LeaveTypeConditionUpdateMutationVariables>;
export const LeaveTypeConditionDeleteDocument = gql`
    mutation LeaveTypeConditionDelete($LeaveTemplateID: String!) {
  LeaveTypeConditionDelete(LeaveTemplateID: $LeaveTemplateID)
}
    `;
export type LeaveTypeConditionDeleteMutationFn = ApolloReactCommon.MutationFunction<LeaveTypeConditionDeleteMutation, LeaveTypeConditionDeleteMutationVariables>;

/**
 * __useLeaveTypeConditionDeleteMutation__
 *
 * To run a mutation, you first call `useLeaveTypeConditionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTypeConditionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTypeConditionDeleteMutation, { data, loading, error }] = useLeaveTypeConditionDeleteMutation({
 *   variables: {
 *      LeaveTemplateID: // value for 'LeaveTemplateID'
 *   },
 * });
 */
export function useLeaveTypeConditionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveTypeConditionDeleteMutation, LeaveTypeConditionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveTypeConditionDeleteMutation, LeaveTypeConditionDeleteMutationVariables>(LeaveTypeConditionDeleteDocument, baseOptions);
      }
export type LeaveTypeConditionDeleteMutationHookResult = ReturnType<typeof useLeaveTypeConditionDeleteMutation>;
export type LeaveTypeConditionDeleteMutationResult = ApolloReactCommon.MutationResult<LeaveTypeConditionDeleteMutation>;
export type LeaveTypeConditionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveTypeConditionDeleteMutation, LeaveTypeConditionDeleteMutationVariables>;
export const LeaveTypeConditionListDocument = gql`
    query LeaveTypeConditionList($CompanyID: String!) {
  LeaveTypeConditionList(CompanyID: $CompanyID) {
    CompanyID
    LeaveTemplateID
    ConditionList
    LeavePolicyIDList
    LeaveTypeID
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __useLeaveTypeConditionListQuery__
 *
 * To run a query within a React component, call `useLeaveTypeConditionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveTypeConditionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveTypeConditionListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLeaveTypeConditionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveTypeConditionListQuery, LeaveTypeConditionListQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveTypeConditionListQuery, LeaveTypeConditionListQueryVariables>(LeaveTypeConditionListDocument, baseOptions);
      }
export function useLeaveTypeConditionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveTypeConditionListQuery, LeaveTypeConditionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveTypeConditionListQuery, LeaveTypeConditionListQueryVariables>(LeaveTypeConditionListDocument, baseOptions);
        }
export type LeaveTypeConditionListQueryHookResult = ReturnType<typeof useLeaveTypeConditionListQuery>;
export type LeaveTypeConditionListLazyQueryHookResult = ReturnType<typeof useLeaveTypeConditionListLazyQuery>;
export type LeaveTypeConditionListQueryResult = ApolloReactCommon.QueryResult<LeaveTypeConditionListQuery, LeaveTypeConditionListQueryVariables>;
export const LeaveTemplateListingDocument = gql`
    query LeaveTemplateListing($JobGradePolicyID: String!, $JobGradeID: String) {
  LeaveTemplateListing(JobGradePolicyID: $JobGradePolicyID, JobGradeID: $JobGradeID) {
    LeaveTemplateID
    JobGradePolicyID
    LeavePolicyID
    LeaveItem {
      LeaveItemID
      LeaveTypeID
      Title
      LeaveType {
        Name
        LeaveTypeCode
      }
    }
  }
}
    `;

/**
 * __useLeaveTemplateListingQuery__
 *
 * To run a query within a React component, call `useLeaveTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveTemplateListingQuery({
 *   variables: {
 *      JobGradePolicyID: // value for 'JobGradePolicyID'
 *      JobGradeID: // value for 'JobGradeID'
 *   },
 * });
 */
export function useLeaveTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveTemplateListingQuery, LeaveTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveTemplateListingQuery, LeaveTemplateListingQueryVariables>(LeaveTemplateListingDocument, baseOptions);
      }
export function useLeaveTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveTemplateListingQuery, LeaveTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveTemplateListingQuery, LeaveTemplateListingQueryVariables>(LeaveTemplateListingDocument, baseOptions);
        }
export type LeaveTemplateListingQueryHookResult = ReturnType<typeof useLeaveTemplateListingQuery>;
export type LeaveTemplateListingLazyQueryHookResult = ReturnType<typeof useLeaveTemplateListingLazyQuery>;
export type LeaveTemplateListingQueryResult = ApolloReactCommon.QueryResult<LeaveTemplateListingQuery, LeaveTemplateListingQueryVariables>;
export const CompanyLeaveSettingUpdateDocument = gql`
    mutation CompanyLeaveSettingUpdate($CompanyID: String!, $CompanyInput: CompanyInput!) {
  CompanyLeaveSettingUpdate(CompanyID: $CompanyID, CompanyInput: $CompanyInput)
}
    `;
export type CompanyLeaveSettingUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyLeaveSettingUpdateMutation, CompanyLeaveSettingUpdateMutationVariables>;

/**
 * __useCompanyLeaveSettingUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyLeaveSettingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyLeaveSettingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyLeaveSettingUpdateMutation, { data, loading, error }] = useCompanyLeaveSettingUpdateMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CompanyInput: // value for 'CompanyInput'
 *   },
 * });
 */
export function useCompanyLeaveSettingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyLeaveSettingUpdateMutation, CompanyLeaveSettingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyLeaveSettingUpdateMutation, CompanyLeaveSettingUpdateMutationVariables>(CompanyLeaveSettingUpdateDocument, baseOptions);
      }
export type CompanyLeaveSettingUpdateMutationHookResult = ReturnType<typeof useCompanyLeaveSettingUpdateMutation>;
export type CompanyLeaveSettingUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyLeaveSettingUpdateMutation>;
export type CompanyLeaveSettingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyLeaveSettingUpdateMutation, CompanyLeaveSettingUpdateMutationVariables>;
export const LeaveCompulsoryPolicyBulkInsertDocument = gql`
    mutation LeaveCompulsoryPolicyBulkInsert($LeaveCompulsoryPolicyInput: [LeaveCompulsoryPolicyInput!]!) {
  LeaveCompulsoryPolicyBulkInsert(LeaveCompulsoryPolicyInput: $LeaveCompulsoryPolicyInput)
}
    `;
export type LeaveCompulsoryPolicyBulkInsertMutationFn = ApolloReactCommon.MutationFunction<LeaveCompulsoryPolicyBulkInsertMutation, LeaveCompulsoryPolicyBulkInsertMutationVariables>;

/**
 * __useLeaveCompulsoryPolicyBulkInsertMutation__
 *
 * To run a mutation, you first call `useLeaveCompulsoryPolicyBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveCompulsoryPolicyBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveCompulsoryPolicyBulkInsertMutation, { data, loading, error }] = useLeaveCompulsoryPolicyBulkInsertMutation({
 *   variables: {
 *      LeaveCompulsoryPolicyInput: // value for 'LeaveCompulsoryPolicyInput'
 *   },
 * });
 */
export function useLeaveCompulsoryPolicyBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveCompulsoryPolicyBulkInsertMutation, LeaveCompulsoryPolicyBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveCompulsoryPolicyBulkInsertMutation, LeaveCompulsoryPolicyBulkInsertMutationVariables>(LeaveCompulsoryPolicyBulkInsertDocument, baseOptions);
      }
export type LeaveCompulsoryPolicyBulkInsertMutationHookResult = ReturnType<typeof useLeaveCompulsoryPolicyBulkInsertMutation>;
export type LeaveCompulsoryPolicyBulkInsertMutationResult = ApolloReactCommon.MutationResult<LeaveCompulsoryPolicyBulkInsertMutation>;
export type LeaveCompulsoryPolicyBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveCompulsoryPolicyBulkInsertMutation, LeaveCompulsoryPolicyBulkInsertMutationVariables>;
export const LeaveCompulsoryPolicyUpdateDocument = gql`
    mutation LeaveCompulsoryPolicyUpdate($LeaveCompulsoryPolicyInput: [LeaveCompulsoryPolicyInput!]!) {
  LeaveCompulsoryPolicyUpdate(LeaveCompulsoryPolicyInput: $LeaveCompulsoryPolicyInput)
}
    `;
export type LeaveCompulsoryPolicyUpdateMutationFn = ApolloReactCommon.MutationFunction<LeaveCompulsoryPolicyUpdateMutation, LeaveCompulsoryPolicyUpdateMutationVariables>;

/**
 * __useLeaveCompulsoryPolicyUpdateMutation__
 *
 * To run a mutation, you first call `useLeaveCompulsoryPolicyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveCompulsoryPolicyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveCompulsoryPolicyUpdateMutation, { data, loading, error }] = useLeaveCompulsoryPolicyUpdateMutation({
 *   variables: {
 *      LeaveCompulsoryPolicyInput: // value for 'LeaveCompulsoryPolicyInput'
 *   },
 * });
 */
export function useLeaveCompulsoryPolicyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveCompulsoryPolicyUpdateMutation, LeaveCompulsoryPolicyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveCompulsoryPolicyUpdateMutation, LeaveCompulsoryPolicyUpdateMutationVariables>(LeaveCompulsoryPolicyUpdateDocument, baseOptions);
      }
export type LeaveCompulsoryPolicyUpdateMutationHookResult = ReturnType<typeof useLeaveCompulsoryPolicyUpdateMutation>;
export type LeaveCompulsoryPolicyUpdateMutationResult = ApolloReactCommon.MutationResult<LeaveCompulsoryPolicyUpdateMutation>;
export type LeaveCompulsoryPolicyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveCompulsoryPolicyUpdateMutation, LeaveCompulsoryPolicyUpdateMutationVariables>;
export const GetCompulsoryLeaveListingDocument = gql`
    query GetCompulsoryLeaveListing($CompanyID: String!, $StartDate: String!, $EndDate: String!) {
  GetCompulsoryLeaveListing(CompanyID: $CompanyID, StartDate: $StartDate, EndDate: $EndDate) {
    LeaveCompulsoryID
    Title
    LeaveFromDate
    LeaveToDate
    SessionType
    TotalDay
    PostedByName
    PostedDate
    Remarks
    DocumentID
    DocumentName
    CountEmployees
    Employees {
      EmployeeID
      EmployeeNo
      EmployeeName
    }
  }
}
    `;

/**
 * __useGetCompulsoryLeaveListingQuery__
 *
 * To run a query within a React component, call `useGetCompulsoryLeaveListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompulsoryLeaveListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompulsoryLeaveListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useGetCompulsoryLeaveListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompulsoryLeaveListingQuery, GetCompulsoryLeaveListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompulsoryLeaveListingQuery, GetCompulsoryLeaveListingQueryVariables>(GetCompulsoryLeaveListingDocument, baseOptions);
      }
export function useGetCompulsoryLeaveListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompulsoryLeaveListingQuery, GetCompulsoryLeaveListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompulsoryLeaveListingQuery, GetCompulsoryLeaveListingQueryVariables>(GetCompulsoryLeaveListingDocument, baseOptions);
        }
export type GetCompulsoryLeaveListingQueryHookResult = ReturnType<typeof useGetCompulsoryLeaveListingQuery>;
export type GetCompulsoryLeaveListingLazyQueryHookResult = ReturnType<typeof useGetCompulsoryLeaveListingLazyQuery>;
export type GetCompulsoryLeaveListingQueryResult = ApolloReactCommon.QueryResult<GetCompulsoryLeaveListingQuery, GetCompulsoryLeaveListingQueryVariables>;
export const LeaveMenuSummaryDocument = gql`
    query LeaveMenuSummary($CompanyID: String!) {
  LeaveMenuSummary(CompanyID: $CompanyID)
}
    `;

/**
 * __useLeaveMenuSummaryQuery__
 *
 * To run a query within a React component, call `useLeaveMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveMenuSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLeaveMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveMenuSummaryQuery, LeaveMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveMenuSummaryQuery, LeaveMenuSummaryQueryVariables>(LeaveMenuSummaryDocument, baseOptions);
      }
export function useLeaveMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveMenuSummaryQuery, LeaveMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveMenuSummaryQuery, LeaveMenuSummaryQueryVariables>(LeaveMenuSummaryDocument, baseOptions);
        }
export type LeaveMenuSummaryQueryHookResult = ReturnType<typeof useLeaveMenuSummaryQuery>;
export type LeaveMenuSummaryLazyQueryHookResult = ReturnType<typeof useLeaveMenuSummaryLazyQuery>;
export type LeaveMenuSummaryQueryResult = ApolloReactCommon.QueryResult<LeaveMenuSummaryQuery, LeaveMenuSummaryQueryVariables>;
export const LeaveHomeSummaryDocument = gql`
    query LeaveHomeSummary($CompanyID: String!, $LeaveAdjustmentListingInput: LeaveAdjustmentListingInput!, $input: LeaveRecordsListingInput!) {
  LeaveHomeSummary(CompanyID: $CompanyID) {
    OutOfStateActiveRecords
    OutOfStateIsLastUpdated
    NoOfEmployee
    EmployeeIsLastUpdated
    YearEndCarryForwardLastPostedDate
    YearEndCarryForwardIsLastUpdated
  }
  GetLeaveAdjustmentListing(input: $LeaveAdjustmentListingInput) {
    LeaveHeaderID
    EmployeeID
    LeaveTypeName
    LeaveFrequency
    Remarks
  }
  GetLeaveRecordsListing(input: $input) {
    EmployeeID
    EmployeeName
    LeaveHeaderID
    ApprovalStatus
    ApprovalDate
    ApprovedBy
  }
}
    `;

/**
 * __useLeaveHomeSummaryQuery__
 *
 * To run a query within a React component, call `useLeaveHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveHomeSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      LeaveAdjustmentListingInput: // value for 'LeaveAdjustmentListingInput'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveHomeSummaryQuery, LeaveHomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveHomeSummaryQuery, LeaveHomeSummaryQueryVariables>(LeaveHomeSummaryDocument, baseOptions);
      }
export function useLeaveHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveHomeSummaryQuery, LeaveHomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveHomeSummaryQuery, LeaveHomeSummaryQueryVariables>(LeaveHomeSummaryDocument, baseOptions);
        }
export type LeaveHomeSummaryQueryHookResult = ReturnType<typeof useLeaveHomeSummaryQuery>;
export type LeaveHomeSummaryLazyQueryHookResult = ReturnType<typeof useLeaveHomeSummaryLazyQuery>;
export type LeaveHomeSummaryQueryResult = ApolloReactCommon.QueryResult<LeaveHomeSummaryQuery, LeaveHomeSummaryQueryVariables>;
export const GetEntityRoleUserAssignmentDocument = gql`
    query getEntityRoleUserAssignment($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $entityID: String, $userID: String) {
  getEntityRoleUserAssignment(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, ID: $ID, entityID: $entityID, userID: $userID) {
    roleID
  }
}
    `;

/**
 * __useGetEntityRoleUserAssignmentQuery__
 *
 * To run a query within a React component, call `useGetEntityRoleUserAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityRoleUserAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityRoleUserAssignmentQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      entityID: // value for 'entityID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useGetEntityRoleUserAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
      }
export function useGetEntityRoleUserAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
        }
export type GetEntityRoleUserAssignmentQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentQuery>;
export type GetEntityRoleUserAssignmentLazyQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentLazyQuery>;
export type GetEntityRoleUserAssignmentQueryResult = ApolloReactCommon.QueryResult<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>;
export const LeaveOutOfStateListingDocument = gql`
    query LeaveOutOfStateListing($CompanyID: String!) {
  LeaveOutOfStateListing(CompanyID: $CompanyID) {
    LeaveOutOfStateID
    CompanyID
    EmployeeID
    SubscriptionAccountID
    FromStateID
    ToStateID
    StartDate
    EndDate
    Employee {
      JoinedDate
      JobGradeID
      OrgUnitID
      EmployeeNo
      ContactID
      CalendarStateID
      CompanyID
      Company {
        CalendarStateID
      }
      Contact {
        FullName
        PermanentAddress {
          State
        }
      }
    }
    FromState {
      StatesID
      Description
    }
    ToState {
      StatesID
      Description
    }
    FromCalendarPolicyDetail {
      CalendarPolicyID
      Title
      States {
        StatesID
        Description
      }
    }
    FromCalendarPolicy
    ToCalendarPolicy
    ToCalendarPolicyDetail {
      CalendarPolicyID
      Title
      States {
        StatesID
        Description
      }
    }
  }
}
    `;

/**
 * __useLeaveOutOfStateListingQuery__
 *
 * To run a query within a React component, call `useLeaveOutOfStateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveOutOfStateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveOutOfStateListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLeaveOutOfStateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveOutOfStateListingQuery, LeaveOutOfStateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveOutOfStateListingQuery, LeaveOutOfStateListingQueryVariables>(LeaveOutOfStateListingDocument, baseOptions);
      }
export function useLeaveOutOfStateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveOutOfStateListingQuery, LeaveOutOfStateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveOutOfStateListingQuery, LeaveOutOfStateListingQueryVariables>(LeaveOutOfStateListingDocument, baseOptions);
        }
export type LeaveOutOfStateListingQueryHookResult = ReturnType<typeof useLeaveOutOfStateListingQuery>;
export type LeaveOutOfStateListingLazyQueryHookResult = ReturnType<typeof useLeaveOutOfStateListingLazyQuery>;
export type LeaveOutOfStateListingQueryResult = ApolloReactCommon.QueryResult<LeaveOutOfStateListingQuery, LeaveOutOfStateListingQueryVariables>;
export const LeaveTakenListingDocument = gql`
    query LeaveTakenListing($CompanyID: String!) {
  LeaveTakenListing(CompanyID: $CompanyID) {
    ExcelFileName
    UploadedDate
    TotalRecords
  }
}
    `;

/**
 * __useLeaveTakenListingQuery__
 *
 * To run a query within a React component, call `useLeaveTakenListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveTakenListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveTakenListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLeaveTakenListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveTakenListingQuery, LeaveTakenListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveTakenListingQuery, LeaveTakenListingQueryVariables>(LeaveTakenListingDocument, baseOptions);
      }
export function useLeaveTakenListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveTakenListingQuery, LeaveTakenListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveTakenListingQuery, LeaveTakenListingQueryVariables>(LeaveTakenListingDocument, baseOptions);
        }
export type LeaveTakenListingQueryHookResult = ReturnType<typeof useLeaveTakenListingQuery>;
export type LeaveTakenListingLazyQueryHookResult = ReturnType<typeof useLeaveTakenListingLazyQuery>;
export type LeaveTakenListingQueryResult = ApolloReactCommon.QueryResult<LeaveTakenListingQuery, LeaveTakenListingQueryVariables>;
export const GetLeavePostingPeriodListingDocument = gql`
    query GetLeavePostingPeriodListing($CompanyID: String!, $Year: Float!) {
  GetLeavePostingPeriodListing(CompanyID: $CompanyID, Year: $Year) {
    PeriodYearMonth
    CountUnpostedLeave
    CountPostedLeave
    CountLeaveRecords
    PeriodStatus
    PeriodFromDate
    PeriodToDate
    ModifiedDT
    isPostedInTheListing
  }
}
    `;

/**
 * __useGetLeavePostingPeriodListingQuery__
 *
 * To run a query within a React component, call `useGetLeavePostingPeriodListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeavePostingPeriodListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeavePostingPeriodListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetLeavePostingPeriodListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeavePostingPeriodListingQuery, GetLeavePostingPeriodListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeavePostingPeriodListingQuery, GetLeavePostingPeriodListingQueryVariables>(GetLeavePostingPeriodListingDocument, baseOptions);
      }
export function useGetLeavePostingPeriodListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeavePostingPeriodListingQuery, GetLeavePostingPeriodListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeavePostingPeriodListingQuery, GetLeavePostingPeriodListingQueryVariables>(GetLeavePostingPeriodListingDocument, baseOptions);
        }
export type GetLeavePostingPeriodListingQueryHookResult = ReturnType<typeof useGetLeavePostingPeriodListingQuery>;
export type GetLeavePostingPeriodListingLazyQueryHookResult = ReturnType<typeof useGetLeavePostingPeriodListingLazyQuery>;
export type GetLeavePostingPeriodListingQueryResult = ApolloReactCommon.QueryResult<GetLeavePostingPeriodListingQuery, GetLeavePostingPeriodListingQueryVariables>;
export const LeaveOutOfStateDeleteDocument = gql`
    mutation LeaveOutOfStateDelete($LeaveOutOfStateID: String!) {
  LeaveOutOfStateDelete(LeaveOutOfStateID: $LeaveOutOfStateID)
}
    `;
export type LeaveOutOfStateDeleteMutationFn = ApolloReactCommon.MutationFunction<LeaveOutOfStateDeleteMutation, LeaveOutOfStateDeleteMutationVariables>;

/**
 * __useLeaveOutOfStateDeleteMutation__
 *
 * To run a mutation, you first call `useLeaveOutOfStateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveOutOfStateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveOutOfStateDeleteMutation, { data, loading, error }] = useLeaveOutOfStateDeleteMutation({
 *   variables: {
 *      LeaveOutOfStateID: // value for 'LeaveOutOfStateID'
 *   },
 * });
 */
export function useLeaveOutOfStateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveOutOfStateDeleteMutation, LeaveOutOfStateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveOutOfStateDeleteMutation, LeaveOutOfStateDeleteMutationVariables>(LeaveOutOfStateDeleteDocument, baseOptions);
      }
export type LeaveOutOfStateDeleteMutationHookResult = ReturnType<typeof useLeaveOutOfStateDeleteMutation>;
export type LeaveOutOfStateDeleteMutationResult = ApolloReactCommon.MutationResult<LeaveOutOfStateDeleteMutation>;
export type LeaveOutOfStateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveOutOfStateDeleteMutation, LeaveOutOfStateDeleteMutationVariables>;
export const LeaveOutOfStateInsertDocument = gql`
    mutation LeaveOutOfStateInsert($input: LeaveOutOfStateInput!) {
  LeaveOutOfStateInsert(input: $input) {
    LeaveOutOfStateID
  }
}
    `;
export type LeaveOutOfStateInsertMutationFn = ApolloReactCommon.MutationFunction<LeaveOutOfStateInsertMutation, LeaveOutOfStateInsertMutationVariables>;

/**
 * __useLeaveOutOfStateInsertMutation__
 *
 * To run a mutation, you first call `useLeaveOutOfStateInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveOutOfStateInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveOutOfStateInsertMutation, { data, loading, error }] = useLeaveOutOfStateInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveOutOfStateInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveOutOfStateInsertMutation, LeaveOutOfStateInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveOutOfStateInsertMutation, LeaveOutOfStateInsertMutationVariables>(LeaveOutOfStateInsertDocument, baseOptions);
      }
export type LeaveOutOfStateInsertMutationHookResult = ReturnType<typeof useLeaveOutOfStateInsertMutation>;
export type LeaveOutOfStateInsertMutationResult = ApolloReactCommon.MutationResult<LeaveOutOfStateInsertMutation>;
export type LeaveOutOfStateInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveOutOfStateInsertMutation, LeaveOutOfStateInsertMutationVariables>;
export const LeaveOufOfStateUpdateDocument = gql`
    mutation LeaveOufOfStateUpdate($input: LeaveOutOfStateInput!) {
  LeaveOufOfStateUpdate(input: $input)
}
    `;
export type LeaveOufOfStateUpdateMutationFn = ApolloReactCommon.MutationFunction<LeaveOufOfStateUpdateMutation, LeaveOufOfStateUpdateMutationVariables>;

/**
 * __useLeaveOufOfStateUpdateMutation__
 *
 * To run a mutation, you first call `useLeaveOufOfStateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveOufOfStateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveOufOfStateUpdateMutation, { data, loading, error }] = useLeaveOufOfStateUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveOufOfStateUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveOufOfStateUpdateMutation, LeaveOufOfStateUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveOufOfStateUpdateMutation, LeaveOufOfStateUpdateMutationVariables>(LeaveOufOfStateUpdateDocument, baseOptions);
      }
export type LeaveOufOfStateUpdateMutationHookResult = ReturnType<typeof useLeaveOufOfStateUpdateMutation>;
export type LeaveOufOfStateUpdateMutationResult = ApolloReactCommon.MutationResult<LeaveOufOfStateUpdateMutation>;
export type LeaveOufOfStateUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveOufOfStateUpdateMutation, LeaveOufOfStateUpdateMutationVariables>;
export const LeaveRecordsApprovalDocument = gql`
    mutation LeaveRecordsApproval($input: [LeaveApprovalUpdateInput!]!, $IsSupersede: Boolean) {
  LeaveRecordsApproval(input: $input, IsSupersede: $IsSupersede)
}
    `;
export type LeaveRecordsApprovalMutationFn = ApolloReactCommon.MutationFunction<LeaveRecordsApprovalMutation, LeaveRecordsApprovalMutationVariables>;

/**
 * __useLeaveRecordsApprovalMutation__
 *
 * To run a mutation, you first call `useLeaveRecordsApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveRecordsApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveRecordsApprovalMutation, { data, loading, error }] = useLeaveRecordsApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *      IsSupersede: // value for 'IsSupersede'
 *   },
 * });
 */
export function useLeaveRecordsApprovalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveRecordsApprovalMutation, LeaveRecordsApprovalMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveRecordsApprovalMutation, LeaveRecordsApprovalMutationVariables>(LeaveRecordsApprovalDocument, baseOptions);
      }
export type LeaveRecordsApprovalMutationHookResult = ReturnType<typeof useLeaveRecordsApprovalMutation>;
export type LeaveRecordsApprovalMutationResult = ApolloReactCommon.MutationResult<LeaveRecordsApprovalMutation>;
export type LeaveRecordsApprovalMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveRecordsApprovalMutation, LeaveRecordsApprovalMutationVariables>;
export const DeletePendingLeaveApplicationDocument = gql`
    mutation DeletePendingLeaveApplication($input: EmpLeaveDetailInput!) {
  DeletePendingLeaveApplication(input: $input)
}
    `;
export type DeletePendingLeaveApplicationMutationFn = ApolloReactCommon.MutationFunction<DeletePendingLeaveApplicationMutation, DeletePendingLeaveApplicationMutationVariables>;

/**
 * __useDeletePendingLeaveApplicationMutation__
 *
 * To run a mutation, you first call `useDeletePendingLeaveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePendingLeaveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePendingLeaveApplicationMutation, { data, loading, error }] = useDeletePendingLeaveApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePendingLeaveApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePendingLeaveApplicationMutation, DeletePendingLeaveApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePendingLeaveApplicationMutation, DeletePendingLeaveApplicationMutationVariables>(DeletePendingLeaveApplicationDocument, baseOptions);
      }
export type DeletePendingLeaveApplicationMutationHookResult = ReturnType<typeof useDeletePendingLeaveApplicationMutation>;
export type DeletePendingLeaveApplicationMutationResult = ApolloReactCommon.MutationResult<DeletePendingLeaveApplicationMutation>;
export type DeletePendingLeaveApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePendingLeaveApplicationMutation, DeletePendingLeaveApplicationMutationVariables>;
export const EmployeeLeaveListingDocument = gql`
    query EmployeeLeaveListing($input: EmpLeaveListingInput!) {
  EmployeeLeaveListing(input: $input) {
    LeaveHeaderID
    CompanyID
    EmployeeID
    LeaveTypeID
    LeaveHeaderType
    PeriodYear
    TotalDay
    FromDate
    UntilDate
    DocumentID
    Remarks
    ApprovalStatus
    ApprovalDT
    ApproverID
    OneTimePayID
    LeaveDetails {
      LeaveDetailsID
      LeaveHeaderID
      PayPeriodID
      SessionType
      LeaveDate
      NumberOfDay
      ManualEntryID
      PostedDate
    }
  }
}
    `;

/**
 * __useEmployeeLeaveListingQuery__
 *
 * To run a query within a React component, call `useEmployeeLeaveListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeLeaveListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeLeaveListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeLeaveListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeLeaveListingQuery, EmployeeLeaveListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeLeaveListingQuery, EmployeeLeaveListingQueryVariables>(EmployeeLeaveListingDocument, baseOptions);
      }
export function useEmployeeLeaveListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeLeaveListingQuery, EmployeeLeaveListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeLeaveListingQuery, EmployeeLeaveListingQueryVariables>(EmployeeLeaveListingDocument, baseOptions);
        }
export type EmployeeLeaveListingQueryHookResult = ReturnType<typeof useEmployeeLeaveListingQuery>;
export type EmployeeLeaveListingLazyQueryHookResult = ReturnType<typeof useEmployeeLeaveListingLazyQuery>;
export type EmployeeLeaveListingQueryResult = ApolloReactCommon.QueryResult<EmployeeLeaveListingQuery, EmployeeLeaveListingQueryVariables>;
export const LeaveTakenBulkInsertDocument = gql`
    mutation LeaveTakenBulkInsert($LeaveHeaderInput: [LeaveHeaderInput!]!) {
  LeaveTakenBulkInsert(LeaveHeaderInput: $LeaveHeaderInput)
}
    `;
export type LeaveTakenBulkInsertMutationFn = ApolloReactCommon.MutationFunction<LeaveTakenBulkInsertMutation, LeaveTakenBulkInsertMutationVariables>;

/**
 * __useLeaveTakenBulkInsertMutation__
 *
 * To run a mutation, you first call `useLeaveTakenBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTakenBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTakenBulkInsertMutation, { data, loading, error }] = useLeaveTakenBulkInsertMutation({
 *   variables: {
 *      LeaveHeaderInput: // value for 'LeaveHeaderInput'
 *   },
 * });
 */
export function useLeaveTakenBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveTakenBulkInsertMutation, LeaveTakenBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveTakenBulkInsertMutation, LeaveTakenBulkInsertMutationVariables>(LeaveTakenBulkInsertDocument, baseOptions);
      }
export type LeaveTakenBulkInsertMutationHookResult = ReturnType<typeof useLeaveTakenBulkInsertMutation>;
export type LeaveTakenBulkInsertMutationResult = ApolloReactCommon.MutationResult<LeaveTakenBulkInsertMutation>;
export type LeaveTakenBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveTakenBulkInsertMutation, LeaveTakenBulkInsertMutationVariables>;
export const BlockLeaveListingDocument = gql`
    query BlockLeaveListing($CompanyID: String!) {
  BlockLeaveListing(CompanyID: $CompanyID) {
    BlockLeaveID
    SubscriptionAccountID
    CompanyID
    Name
    ParentID
    Remark
    DocumentID
    StartDate
    EndDate
    LeaveTypeID
    LeaveType {
      LeaveTypeID
      Name
    }
    Employee {
      EmployeeID
      ContactID
      OrgUnitID
      Contact {
        FullName
      }
    }
    Documents {
      DocumentID
      DocumentType
      Description
      DocumentFile
      PayPeriodID
      ParentID
      BucketFileName
      FileSize
    }
    OrgUnitID
    Status
  }
}
    `;

/**
 * __useBlockLeaveListingQuery__
 *
 * To run a query within a React component, call `useBlockLeaveListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockLeaveListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockLeaveListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useBlockLeaveListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BlockLeaveListingQuery, BlockLeaveListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BlockLeaveListingQuery, BlockLeaveListingQueryVariables>(BlockLeaveListingDocument, baseOptions);
      }
export function useBlockLeaveListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BlockLeaveListingQuery, BlockLeaveListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BlockLeaveListingQuery, BlockLeaveListingQueryVariables>(BlockLeaveListingDocument, baseOptions);
        }
export type BlockLeaveListingQueryHookResult = ReturnType<typeof useBlockLeaveListingQuery>;
export type BlockLeaveListingLazyQueryHookResult = ReturnType<typeof useBlockLeaveListingLazyQuery>;
export type BlockLeaveListingQueryResult = ApolloReactCommon.QueryResult<BlockLeaveListingQuery, BlockLeaveListingQueryVariables>;
export const GetLeaveAdjustmentListingDocument = gql`
    query GetLeaveAdjustmentListing($input: LeaveAdjustmentListingInput!) {
  GetLeaveAdjustmentListing(input: $input) {
    Employee {
      CompanyID
      JoinedDate
      FormStatus
      JobGradeID
      OrgUnitID
    }
    LeaveHeaderID
    EmployeeID
    EmployeeNo
    EmployeeName
    LeaveTypeID
    LeaveTypeName
    EffectiveDate
    ExpiryDate
    Remarks
    TotalDay
    LeaveFrequency
  }
}
    `;

/**
 * __useGetLeaveAdjustmentListingQuery__
 *
 * To run a query within a React component, call `useGetLeaveAdjustmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveAdjustmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveAdjustmentListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLeaveAdjustmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveAdjustmentListingQuery, GetLeaveAdjustmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveAdjustmentListingQuery, GetLeaveAdjustmentListingQueryVariables>(GetLeaveAdjustmentListingDocument, baseOptions);
      }
export function useGetLeaveAdjustmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveAdjustmentListingQuery, GetLeaveAdjustmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveAdjustmentListingQuery, GetLeaveAdjustmentListingQueryVariables>(GetLeaveAdjustmentListingDocument, baseOptions);
        }
export type GetLeaveAdjustmentListingQueryHookResult = ReturnType<typeof useGetLeaveAdjustmentListingQuery>;
export type GetLeaveAdjustmentListingLazyQueryHookResult = ReturnType<typeof useGetLeaveAdjustmentListingLazyQuery>;
export type GetLeaveAdjustmentListingQueryResult = ApolloReactCommon.QueryResult<GetLeaveAdjustmentListingQuery, GetLeaveAdjustmentListingQueryVariables>;
export const LeaveAdjustmentDeleteDocument = gql`
    mutation LeaveAdjustmentDelete($LeaveHeaderID: String!, $input: LeaveHeaderInput!) {
  LeaveAdjustmentDelete(LeaveHeaderID: $LeaveHeaderID, input: $input)
}
    `;
export type LeaveAdjustmentDeleteMutationFn = ApolloReactCommon.MutationFunction<LeaveAdjustmentDeleteMutation, LeaveAdjustmentDeleteMutationVariables>;

/**
 * __useLeaveAdjustmentDeleteMutation__
 *
 * To run a mutation, you first call `useLeaveAdjustmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveAdjustmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveAdjustmentDeleteMutation, { data, loading, error }] = useLeaveAdjustmentDeleteMutation({
 *   variables: {
 *      LeaveHeaderID: // value for 'LeaveHeaderID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveAdjustmentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveAdjustmentDeleteMutation, LeaveAdjustmentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveAdjustmentDeleteMutation, LeaveAdjustmentDeleteMutationVariables>(LeaveAdjustmentDeleteDocument, baseOptions);
      }
export type LeaveAdjustmentDeleteMutationHookResult = ReturnType<typeof useLeaveAdjustmentDeleteMutation>;
export type LeaveAdjustmentDeleteMutationResult = ApolloReactCommon.MutationResult<LeaveAdjustmentDeleteMutation>;
export type LeaveAdjustmentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveAdjustmentDeleteMutation, LeaveAdjustmentDeleteMutationVariables>;
export const LeaveAdjustmentInsertDocument = gql`
    mutation LeaveAdjustmentInsert($input: [LeaveHeaderInput!]!) {
  LeaveAdjustmentInsert(input: $input)
}
    `;
export type LeaveAdjustmentInsertMutationFn = ApolloReactCommon.MutationFunction<LeaveAdjustmentInsertMutation, LeaveAdjustmentInsertMutationVariables>;

/**
 * __useLeaveAdjustmentInsertMutation__
 *
 * To run a mutation, you first call `useLeaveAdjustmentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveAdjustmentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveAdjustmentInsertMutation, { data, loading, error }] = useLeaveAdjustmentInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveAdjustmentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveAdjustmentInsertMutation, LeaveAdjustmentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveAdjustmentInsertMutation, LeaveAdjustmentInsertMutationVariables>(LeaveAdjustmentInsertDocument, baseOptions);
      }
export type LeaveAdjustmentInsertMutationHookResult = ReturnType<typeof useLeaveAdjustmentInsertMutation>;
export type LeaveAdjustmentInsertMutationResult = ApolloReactCommon.MutationResult<LeaveAdjustmentInsertMutation>;
export type LeaveAdjustmentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveAdjustmentInsertMutation, LeaveAdjustmentInsertMutationVariables>;
export const LeaveAdjustmentUpdateDocument = gql`
    mutation LeaveAdjustmentUpdate($LeaveHeaderID: String!, $input: LeaveHeaderInput!) {
  LeaveAdjustmentUpdate(LeaveHeaderID: $LeaveHeaderID, input: $input)
}
    `;
export type LeaveAdjustmentUpdateMutationFn = ApolloReactCommon.MutationFunction<LeaveAdjustmentUpdateMutation, LeaveAdjustmentUpdateMutationVariables>;

/**
 * __useLeaveAdjustmentUpdateMutation__
 *
 * To run a mutation, you first call `useLeaveAdjustmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveAdjustmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveAdjustmentUpdateMutation, { data, loading, error }] = useLeaveAdjustmentUpdateMutation({
 *   variables: {
 *      LeaveHeaderID: // value for 'LeaveHeaderID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveAdjustmentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveAdjustmentUpdateMutation, LeaveAdjustmentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveAdjustmentUpdateMutation, LeaveAdjustmentUpdateMutationVariables>(LeaveAdjustmentUpdateDocument, baseOptions);
      }
export type LeaveAdjustmentUpdateMutationHookResult = ReturnType<typeof useLeaveAdjustmentUpdateMutation>;
export type LeaveAdjustmentUpdateMutationResult = ApolloReactCommon.MutationResult<LeaveAdjustmentUpdateMutation>;
export type LeaveAdjustmentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveAdjustmentUpdateMutation, LeaveAdjustmentUpdateMutationVariables>;
export const LeaveAdjustmentLeaveTypeListingDocument = gql`
    query LeaveAdjustmentLeaveTypeListing($SubscriptionAccountID: String!) {
  LeavePolicyListing(SubscriptionAccountID: $SubscriptionAccountID) {
    LeaveType {
      LeaveTypeID
      Name
      LeaveTypeCode
    }
    LeaveFrequency
  }
}
    `;

/**
 * __useLeaveAdjustmentLeaveTypeListingQuery__
 *
 * To run a query within a React component, call `useLeaveAdjustmentLeaveTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaveAdjustmentLeaveTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaveAdjustmentLeaveTypeListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useLeaveAdjustmentLeaveTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LeaveAdjustmentLeaveTypeListingQuery, LeaveAdjustmentLeaveTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LeaveAdjustmentLeaveTypeListingQuery, LeaveAdjustmentLeaveTypeListingQueryVariables>(LeaveAdjustmentLeaveTypeListingDocument, baseOptions);
      }
export function useLeaveAdjustmentLeaveTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LeaveAdjustmentLeaveTypeListingQuery, LeaveAdjustmentLeaveTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LeaveAdjustmentLeaveTypeListingQuery, LeaveAdjustmentLeaveTypeListingQueryVariables>(LeaveAdjustmentLeaveTypeListingDocument, baseOptions);
        }
export type LeaveAdjustmentLeaveTypeListingQueryHookResult = ReturnType<typeof useLeaveAdjustmentLeaveTypeListingQuery>;
export type LeaveAdjustmentLeaveTypeListingLazyQueryHookResult = ReturnType<typeof useLeaveAdjustmentLeaveTypeListingLazyQuery>;
export type LeaveAdjustmentLeaveTypeListingQueryResult = ApolloReactCommon.QueryResult<LeaveAdjustmentLeaveTypeListingQuery, LeaveAdjustmentLeaveTypeListingQueryVariables>;
export const DeleteCompulsoryLeaveDocument = gql`
    mutation DeleteCompulsoryLeave($LeaveCompulsoryID: String!) {
  DeleteCompulsoryLeave(LeaveCompulsoryID: $LeaveCompulsoryID)
}
    `;
export type DeleteCompulsoryLeaveMutationFn = ApolloReactCommon.MutationFunction<DeleteCompulsoryLeaveMutation, DeleteCompulsoryLeaveMutationVariables>;

/**
 * __useDeleteCompulsoryLeaveMutation__
 *
 * To run a mutation, you first call `useDeleteCompulsoryLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompulsoryLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompulsoryLeaveMutation, { data, loading, error }] = useDeleteCompulsoryLeaveMutation({
 *   variables: {
 *      LeaveCompulsoryID: // value for 'LeaveCompulsoryID'
 *   },
 * });
 */
export function useDeleteCompulsoryLeaveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompulsoryLeaveMutation, DeleteCompulsoryLeaveMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompulsoryLeaveMutation, DeleteCompulsoryLeaveMutationVariables>(DeleteCompulsoryLeaveDocument, baseOptions);
      }
export type DeleteCompulsoryLeaveMutationHookResult = ReturnType<typeof useDeleteCompulsoryLeaveMutation>;
export type DeleteCompulsoryLeaveMutationResult = ApolloReactCommon.MutationResult<DeleteCompulsoryLeaveMutation>;
export type DeleteCompulsoryLeaveMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompulsoryLeaveMutation, DeleteCompulsoryLeaveMutationVariables>;
export const SaveUpdateCompulsoryLeaveDocument = gql`
    mutation SaveUpdateCompulsoryLeave($LeaveCompulsoryInput: LeaveCompulsoryInput!, $LeaveCompulsoryEmployeeInputs: [LeaveCompulsoryEmployeeInput!]!, $LeaveCompulsoryID: String) {
  SaveUpdateCompulsoryLeave(LeaveCompulsoryInput: $LeaveCompulsoryInput, LeaveCompulsoryEmployeeInputs: $LeaveCompulsoryEmployeeInputs, LeaveCompulsoryID: $LeaveCompulsoryID)
}
    `;
export type SaveUpdateCompulsoryLeaveMutationFn = ApolloReactCommon.MutationFunction<SaveUpdateCompulsoryLeaveMutation, SaveUpdateCompulsoryLeaveMutationVariables>;

/**
 * __useSaveUpdateCompulsoryLeaveMutation__
 *
 * To run a mutation, you first call `useSaveUpdateCompulsoryLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUpdateCompulsoryLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUpdateCompulsoryLeaveMutation, { data, loading, error }] = useSaveUpdateCompulsoryLeaveMutation({
 *   variables: {
 *      LeaveCompulsoryInput: // value for 'LeaveCompulsoryInput'
 *      LeaveCompulsoryEmployeeInputs: // value for 'LeaveCompulsoryEmployeeInputs'
 *      LeaveCompulsoryID: // value for 'LeaveCompulsoryID'
 *   },
 * });
 */
export function useSaveUpdateCompulsoryLeaveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveUpdateCompulsoryLeaveMutation, SaveUpdateCompulsoryLeaveMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveUpdateCompulsoryLeaveMutation, SaveUpdateCompulsoryLeaveMutationVariables>(SaveUpdateCompulsoryLeaveDocument, baseOptions);
      }
export type SaveUpdateCompulsoryLeaveMutationHookResult = ReturnType<typeof useSaveUpdateCompulsoryLeaveMutation>;
export type SaveUpdateCompulsoryLeaveMutationResult = ApolloReactCommon.MutationResult<SaveUpdateCompulsoryLeaveMutation>;
export type SaveUpdateCompulsoryLeaveMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveUpdateCompulsoryLeaveMutation, SaveUpdateCompulsoryLeaveMutationVariables>;
export const BlockLeaveStatusUpdateDocument = gql`
    mutation BlockLeaveStatusUpdate($Input: BlockLeaveInput!) {
  BlockLeaveStatusUpdate(Input: $Input)
}
    `;
export type BlockLeaveStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<BlockLeaveStatusUpdateMutation, BlockLeaveStatusUpdateMutationVariables>;

/**
 * __useBlockLeaveStatusUpdateMutation__
 *
 * To run a mutation, you first call `useBlockLeaveStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockLeaveStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockLeaveStatusUpdateMutation, { data, loading, error }] = useBlockLeaveStatusUpdateMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useBlockLeaveStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockLeaveStatusUpdateMutation, BlockLeaveStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BlockLeaveStatusUpdateMutation, BlockLeaveStatusUpdateMutationVariables>(BlockLeaveStatusUpdateDocument, baseOptions);
      }
export type BlockLeaveStatusUpdateMutationHookResult = ReturnType<typeof useBlockLeaveStatusUpdateMutation>;
export type BlockLeaveStatusUpdateMutationResult = ApolloReactCommon.MutationResult<BlockLeaveStatusUpdateMutation>;
export type BlockLeaveStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockLeaveStatusUpdateMutation, BlockLeaveStatusUpdateMutationVariables>;
export const BlockLeaveInsertDocument = gql`
    mutation BlockLeaveInsert($Input: [BlockLeaveInput!]!, $DocumentsInput: DocumentsInput) {
  BlockLeaveInsert(Input: $Input, DocumentsInput: $DocumentsInput)
}
    `;
export type BlockLeaveInsertMutationFn = ApolloReactCommon.MutationFunction<BlockLeaveInsertMutation, BlockLeaveInsertMutationVariables>;

/**
 * __useBlockLeaveInsertMutation__
 *
 * To run a mutation, you first call `useBlockLeaveInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockLeaveInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockLeaveInsertMutation, { data, loading, error }] = useBlockLeaveInsertMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useBlockLeaveInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockLeaveInsertMutation, BlockLeaveInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<BlockLeaveInsertMutation, BlockLeaveInsertMutationVariables>(BlockLeaveInsertDocument, baseOptions);
      }
export type BlockLeaveInsertMutationHookResult = ReturnType<typeof useBlockLeaveInsertMutation>;
export type BlockLeaveInsertMutationResult = ApolloReactCommon.MutationResult<BlockLeaveInsertMutation>;
export type BlockLeaveInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockLeaveInsertMutation, BlockLeaveInsertMutationVariables>;
export const BlockLeaveUpdateDocument = gql`
    mutation BlockLeaveUpdate($Input: [BlockLeaveInput!]!, $DocumentID: String, $DocumentsInput: DocumentsInput, $IsUpdateAttachment: Boolean!) {
  BlockLeaveUpdate(Input: $Input, DocumentID: $DocumentID, DocumentsInput: $DocumentsInput, IsUpdateAttachment: $IsUpdateAttachment)
}
    `;
export type BlockLeaveUpdateMutationFn = ApolloReactCommon.MutationFunction<BlockLeaveUpdateMutation, BlockLeaveUpdateMutationVariables>;

/**
 * __useBlockLeaveUpdateMutation__
 *
 * To run a mutation, you first call `useBlockLeaveUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockLeaveUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockLeaveUpdateMutation, { data, loading, error }] = useBlockLeaveUpdateMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *      DocumentID: // value for 'DocumentID'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      IsUpdateAttachment: // value for 'IsUpdateAttachment'
 *   },
 * });
 */
export function useBlockLeaveUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockLeaveUpdateMutation, BlockLeaveUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BlockLeaveUpdateMutation, BlockLeaveUpdateMutationVariables>(BlockLeaveUpdateDocument, baseOptions);
      }
export type BlockLeaveUpdateMutationHookResult = ReturnType<typeof useBlockLeaveUpdateMutation>;
export type BlockLeaveUpdateMutationResult = ApolloReactCommon.MutationResult<BlockLeaveUpdateMutation>;
export type BlockLeaveUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockLeaveUpdateMutation, BlockLeaveUpdateMutationVariables>;
export const GetLeavePostingListingDocument = gql`
    query GetLeavePostingListing($input: LeavePostingListingInput!) {
  GetLeavePostingListing(input: $input) {
    Employee {
      CompanyID
      JoinedDate
      FormStatus
      JobGradeID
      OrgUnitID
    }
    LeaveType {
      LeaveTypeID
      PayItem {
        PayItemID
        PayName
        PayItemType
      }
    }
    LeaveDetails {
      LeaveDetailsID
      PayPeriodID
      PayrollCycleID
    }
    LeaveHeaderID
    EmployeeID
    EmployeeNo
    EmployeeName
    LeaveFromDate
    LeaveToDate
    LeaveTypeID
    LeaveTypeName
    TotalDay
    Remarks
    PostedDate
    LeaveHeaderStatus
    OneTimePayID
    ApprovalDate
    ApprovedBy
    SubmissionDate
    PayPeriod
    PayrollCycle
    IsOTPModified
    OTPModifiedBy
    IsOTPDeleted
    OTPDeletedBy
  }
}
    `;

/**
 * __useGetLeavePostingListingQuery__
 *
 * To run a query within a React component, call `useGetLeavePostingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeavePostingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeavePostingListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLeavePostingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeavePostingListingQuery, GetLeavePostingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeavePostingListingQuery, GetLeavePostingListingQueryVariables>(GetLeavePostingListingDocument, baseOptions);
      }
export function useGetLeavePostingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeavePostingListingQuery, GetLeavePostingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeavePostingListingQuery, GetLeavePostingListingQueryVariables>(GetLeavePostingListingDocument, baseOptions);
        }
export type GetLeavePostingListingQueryHookResult = ReturnType<typeof useGetLeavePostingListingQuery>;
export type GetLeavePostingListingLazyQueryHookResult = ReturnType<typeof useGetLeavePostingListingLazyQuery>;
export type GetLeavePostingListingQueryResult = ApolloReactCommon.QueryResult<GetLeavePostingListingQuery, GetLeavePostingListingQueryVariables>;
export const InsertLeaveCutOffPeriodsDocument = gql`
    mutation InsertLeaveCutOffPeriods($input: GenerateLeaveCutOffPeriodInput!) {
  InsertLeaveCutOffPeriods(input: $input)
}
    `;
export type InsertLeaveCutOffPeriodsMutationFn = ApolloReactCommon.MutationFunction<InsertLeaveCutOffPeriodsMutation, InsertLeaveCutOffPeriodsMutationVariables>;

/**
 * __useInsertLeaveCutOffPeriodsMutation__
 *
 * To run a mutation, you first call `useInsertLeaveCutOffPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLeaveCutOffPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLeaveCutOffPeriodsMutation, { data, loading, error }] = useInsertLeaveCutOffPeriodsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsertLeaveCutOffPeriodsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertLeaveCutOffPeriodsMutation, InsertLeaveCutOffPeriodsMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertLeaveCutOffPeriodsMutation, InsertLeaveCutOffPeriodsMutationVariables>(InsertLeaveCutOffPeriodsDocument, baseOptions);
      }
export type InsertLeaveCutOffPeriodsMutationHookResult = ReturnType<typeof useInsertLeaveCutOffPeriodsMutation>;
export type InsertLeaveCutOffPeriodsMutationResult = ApolloReactCommon.MutationResult<InsertLeaveCutOffPeriodsMutation>;
export type InsertLeaveCutOffPeriodsMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertLeaveCutOffPeriodsMutation, InsertLeaveCutOffPeriodsMutationVariables>;
export const PostLeaveRecordsDocument = gql`
    mutation PostLeaveRecords($input: [LeavePostingInput!]!) {
  PostLeaveRecords(input: $input)
}
    `;
export type PostLeaveRecordsMutationFn = ApolloReactCommon.MutationFunction<PostLeaveRecordsMutation, PostLeaveRecordsMutationVariables>;

/**
 * __usePostLeaveRecordsMutation__
 *
 * To run a mutation, you first call `usePostLeaveRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostLeaveRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postLeaveRecordsMutation, { data, loading, error }] = usePostLeaveRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostLeaveRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostLeaveRecordsMutation, PostLeaveRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<PostLeaveRecordsMutation, PostLeaveRecordsMutationVariables>(PostLeaveRecordsDocument, baseOptions);
      }
export type PostLeaveRecordsMutationHookResult = ReturnType<typeof usePostLeaveRecordsMutation>;
export type PostLeaveRecordsMutationResult = ApolloReactCommon.MutationResult<PostLeaveRecordsMutation>;
export type PostLeaveRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<PostLeaveRecordsMutation, PostLeaveRecordsMutationVariables>;
export const RevertLeaveRecordsDocument = gql`
    mutation RevertLeaveRecords($input: [LeavePostingInput!]!) {
  RevertLeaveRecords(input: $input)
}
    `;
export type RevertLeaveRecordsMutationFn = ApolloReactCommon.MutationFunction<RevertLeaveRecordsMutation, RevertLeaveRecordsMutationVariables>;

/**
 * __useRevertLeaveRecordsMutation__
 *
 * To run a mutation, you first call `useRevertLeaveRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertLeaveRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertLeaveRecordsMutation, { data, loading, error }] = useRevertLeaveRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevertLeaveRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevertLeaveRecordsMutation, RevertLeaveRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<RevertLeaveRecordsMutation, RevertLeaveRecordsMutationVariables>(RevertLeaveRecordsDocument, baseOptions);
      }
export type RevertLeaveRecordsMutationHookResult = ReturnType<typeof useRevertLeaveRecordsMutation>;
export type RevertLeaveRecordsMutationResult = ApolloReactCommon.MutationResult<RevertLeaveRecordsMutation>;
export type RevertLeaveRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<RevertLeaveRecordsMutation, RevertLeaveRecordsMutationVariables>;
export const SubmitYearEndCarryForwardDocument = gql`
    mutation SubmitYearEndCarryForward($LeaveHeaderInputs: [LeaveHeaderInput!]!) {
  SubmitYearEndCarryForward(leaveHeaderInput: $LeaveHeaderInputs)
}
    `;
export type SubmitYearEndCarryForwardMutationFn = ApolloReactCommon.MutationFunction<SubmitYearEndCarryForwardMutation, SubmitYearEndCarryForwardMutationVariables>;

/**
 * __useSubmitYearEndCarryForwardMutation__
 *
 * To run a mutation, you first call `useSubmitYearEndCarryForwardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitYearEndCarryForwardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitYearEndCarryForwardMutation, { data, loading, error }] = useSubmitYearEndCarryForwardMutation({
 *   variables: {
 *      LeaveHeaderInputs: // value for 'LeaveHeaderInputs'
 *   },
 * });
 */
export function useSubmitYearEndCarryForwardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitYearEndCarryForwardMutation, SubmitYearEndCarryForwardMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitYearEndCarryForwardMutation, SubmitYearEndCarryForwardMutationVariables>(SubmitYearEndCarryForwardDocument, baseOptions);
      }
export type SubmitYearEndCarryForwardMutationHookResult = ReturnType<typeof useSubmitYearEndCarryForwardMutation>;
export type SubmitYearEndCarryForwardMutationResult = ApolloReactCommon.MutationResult<SubmitYearEndCarryForwardMutation>;
export type SubmitYearEndCarryForwardMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitYearEndCarryForwardMutation, SubmitYearEndCarryForwardMutationVariables>;
export const ReversePostedYecfRecordsDocument = gql`
    mutation ReversePostedYECFRecords($Inputs: [ReversePostedYECFInput!]!, $Year: Float!) {
  ReversePostedYECFRecords(Input: $Inputs, Year: $Year)
}
    `;
export type ReversePostedYecfRecordsMutationFn = ApolloReactCommon.MutationFunction<ReversePostedYecfRecordsMutation, ReversePostedYecfRecordsMutationVariables>;

/**
 * __useReversePostedYecfRecordsMutation__
 *
 * To run a mutation, you first call `useReversePostedYecfRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReversePostedYecfRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reversePostedYecfRecordsMutation, { data, loading, error }] = useReversePostedYecfRecordsMutation({
 *   variables: {
 *      Inputs: // value for 'Inputs'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useReversePostedYecfRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReversePostedYecfRecordsMutation, ReversePostedYecfRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<ReversePostedYecfRecordsMutation, ReversePostedYecfRecordsMutationVariables>(ReversePostedYecfRecordsDocument, baseOptions);
      }
export type ReversePostedYecfRecordsMutationHookResult = ReturnType<typeof useReversePostedYecfRecordsMutation>;
export type ReversePostedYecfRecordsMutationResult = ApolloReactCommon.MutationResult<ReversePostedYecfRecordsMutation>;
export type ReversePostedYecfRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReversePostedYecfRecordsMutation, ReversePostedYecfRecordsMutationVariables>;
export const ReversePostedLcfeDocument = gql`
    mutation ReversePostedLCFE($Inputs: [ReversePostedLCFEInput!]!, $Year: Float!) {
  ReversePostedLCFE(Inputs: $Inputs, Year: $Year)
}
    `;
export type ReversePostedLcfeMutationFn = ApolloReactCommon.MutationFunction<ReversePostedLcfeMutation, ReversePostedLcfeMutationVariables>;

/**
 * __useReversePostedLcfeMutation__
 *
 * To run a mutation, you first call `useReversePostedLcfeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReversePostedLcfeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reversePostedLcfeMutation, { data, loading, error }] = useReversePostedLcfeMutation({
 *   variables: {
 *      Inputs: // value for 'Inputs'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useReversePostedLcfeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReversePostedLcfeMutation, ReversePostedLcfeMutationVariables>) {
        return ApolloReactHooks.useMutation<ReversePostedLcfeMutation, ReversePostedLcfeMutationVariables>(ReversePostedLcfeDocument, baseOptions);
      }
export type ReversePostedLcfeMutationHookResult = ReturnType<typeof useReversePostedLcfeMutation>;
export type ReversePostedLcfeMutationResult = ApolloReactCommon.MutationResult<ReversePostedLcfeMutation>;
export type ReversePostedLcfeMutationOptions = ApolloReactCommon.BaseMutationOptions<ReversePostedLcfeMutation, ReversePostedLcfeMutationVariables>;
export const GetYecfPendingListingDocument = gql`
    query GetYECFPendingListing($CompanyID: String!, $Year: Float!) {
  GetYECFPendingListing(CompanyID: $CompanyID, Year: $Year) {
    Employee {
      CompanyID
      JoinedDate
      FormStatus
      JobGradeID
      OrgUnitID
    }
    HeaderStatus
    EmployeeID
    EmployeeNo
    EmployeeName
    NoOfPendingApprovalApplication
    LeaveItem {
      Title
      LeaveType {
        LeaveTypeID
        Name
      }
      CFMethod
      CFValue
      CFMonth
    }
    LeaveBalanceSummary {
      Entitled
      Balance
      CarryForward
    }
  }
}
    `;

/**
 * __useGetYecfPendingListingQuery__
 *
 * To run a query within a React component, call `useGetYecfPendingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYecfPendingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYecfPendingListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetYecfPendingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetYecfPendingListingQuery, GetYecfPendingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetYecfPendingListingQuery, GetYecfPendingListingQueryVariables>(GetYecfPendingListingDocument, baseOptions);
      }
export function useGetYecfPendingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetYecfPendingListingQuery, GetYecfPendingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetYecfPendingListingQuery, GetYecfPendingListingQueryVariables>(GetYecfPendingListingDocument, baseOptions);
        }
export type GetYecfPendingListingQueryHookResult = ReturnType<typeof useGetYecfPendingListingQuery>;
export type GetYecfPendingListingLazyQueryHookResult = ReturnType<typeof useGetYecfPendingListingLazyQuery>;
export type GetYecfPendingListingQueryResult = ApolloReactCommon.QueryResult<GetYecfPendingListingQuery, GetYecfPendingListingQueryVariables>;
export const GetYecfPostedListingDocument = gql`
    query GetYECFPostedListing($CompanyID: String!, $Year: Float!) {
  GetYECFPostedListing(CompanyID: $CompanyID, Year: $Year) {
    Employee {
      CompanyID
      JoinedDate
      FormStatus
      JobGradeID
      OrgUnitID
    }
    HeaderStatus
    EmployeeID
    EmployeeNo
    EmployeeName
    LeaveItem {
      LeaveTypeID
      LeaveType {
        Name
      }
    }
    LeaveBalanceSummary {
      CarryForward
    }
  }
}
    `;

/**
 * __useGetYecfPostedListingQuery__
 *
 * To run a query within a React component, call `useGetYecfPostedListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYecfPostedListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYecfPostedListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetYecfPostedListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetYecfPostedListingQuery, GetYecfPostedListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetYecfPostedListingQuery, GetYecfPostedListingQueryVariables>(GetYecfPostedListingDocument, baseOptions);
      }
export function useGetYecfPostedListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetYecfPostedListingQuery, GetYecfPostedListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetYecfPostedListingQuery, GetYecfPostedListingQueryVariables>(GetYecfPostedListingDocument, baseOptions);
        }
export type GetYecfPostedListingQueryHookResult = ReturnType<typeof useGetYecfPostedListingQuery>;
export type GetYecfPostedListingLazyQueryHookResult = ReturnType<typeof useGetYecfPostedListingLazyQuery>;
export type GetYecfPostedListingQueryResult = ApolloReactCommon.QueryResult<GetYecfPostedListingQuery, GetYecfPostedListingQueryVariables>;
export const GetYecfPendingListingWithFilterDocument = gql`
    query GetYECFPendingListingWithFilter($CompanyID: String!, $Year: Float!, $DepartmentIDs: [String!], $DivisionIDs: [String!], $LeaveTypeIDs: [String!]) {
  GetYECFPendingListingWithFilter(CompanyID: $CompanyID, Year: $Year, DepartmentIDs: $DepartmentIDs, DivisionIDs: $DivisionIDs, LeaveTypeIDs: $LeaveTypeIDs) {
    Employee {
      CompanyID
      JoinedDate
      FormStatus
      JobGradeID
      OrgUnitID
    }
    HeaderStatus
    EmployeeID
    EmployeeNo
    EmployeeName
    NoOfPendingApprovalApplication
    LeaveItem {
      Title
      LeaveType {
        LeaveTypeID
        Name
      }
      CFMethod
      CFValue
      CFMonth
    }
    LeaveBalanceSummary {
      Entitled
      Balance
      CarryForward
    }
  }
}
    `;

/**
 * __useGetYecfPendingListingWithFilterQuery__
 *
 * To run a query within a React component, call `useGetYecfPendingListingWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYecfPendingListingWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYecfPendingListingWithFilterQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      DivisionIDs: // value for 'DivisionIDs'
 *      LeaveTypeIDs: // value for 'LeaveTypeIDs'
 *   },
 * });
 */
export function useGetYecfPendingListingWithFilterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetYecfPendingListingWithFilterQuery, GetYecfPendingListingWithFilterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetYecfPendingListingWithFilterQuery, GetYecfPendingListingWithFilterQueryVariables>(GetYecfPendingListingWithFilterDocument, baseOptions);
      }
export function useGetYecfPendingListingWithFilterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetYecfPendingListingWithFilterQuery, GetYecfPendingListingWithFilterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetYecfPendingListingWithFilterQuery, GetYecfPendingListingWithFilterQueryVariables>(GetYecfPendingListingWithFilterDocument, baseOptions);
        }
export type GetYecfPendingListingWithFilterQueryHookResult = ReturnType<typeof useGetYecfPendingListingWithFilterQuery>;
export type GetYecfPendingListingWithFilterLazyQueryHookResult = ReturnType<typeof useGetYecfPendingListingWithFilterLazyQuery>;
export type GetYecfPendingListingWithFilterQueryResult = ApolloReactCommon.QueryResult<GetYecfPendingListingWithFilterQuery, GetYecfPendingListingWithFilterQueryVariables>;
export const GetYecfPostedListingWithFilterDocument = gql`
    query GetYECFPostedListingWithFilter($CompanyID: String!, $Year: Float!, $DepartmentIDs: [String!], $DivisionIDs: [String!], $LeaveTypeIDs: [String!]) {
  GetYECFPostedListingWithFilter(CompanyID: $CompanyID, Year: $Year, DepartmentIDs: $DepartmentIDs, DivisionIDs: $DivisionIDs, LeaveTypeIDs: $LeaveTypeIDs) {
    Employee {
      CompanyID
      JoinedDate
      FormStatus
      JobGradeID
      OrgUnitID
    }
    HeaderStatus
    InUse
    EmployeeID
    EmployeeNo
    EmployeeName
    LeaveItem {
      LeaveTypeID
      LeaveType {
        Name
      }
    }
    LeaveBalanceSummary {
      CarryForward
    }
  }
}
    `;

/**
 * __useGetYecfPostedListingWithFilterQuery__
 *
 * To run a query within a React component, call `useGetYecfPostedListingWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYecfPostedListingWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYecfPostedListingWithFilterQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      DivisionIDs: // value for 'DivisionIDs'
 *      LeaveTypeIDs: // value for 'LeaveTypeIDs'
 *   },
 * });
 */
export function useGetYecfPostedListingWithFilterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetYecfPostedListingWithFilterQuery, GetYecfPostedListingWithFilterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetYecfPostedListingWithFilterQuery, GetYecfPostedListingWithFilterQueryVariables>(GetYecfPostedListingWithFilterDocument, baseOptions);
      }
export function useGetYecfPostedListingWithFilterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetYecfPostedListingWithFilterQuery, GetYecfPostedListingWithFilterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetYecfPostedListingWithFilterQuery, GetYecfPostedListingWithFilterQueryVariables>(GetYecfPostedListingWithFilterDocument, baseOptions);
        }
export type GetYecfPostedListingWithFilterQueryHookResult = ReturnType<typeof useGetYecfPostedListingWithFilterQuery>;
export type GetYecfPostedListingWithFilterLazyQueryHookResult = ReturnType<typeof useGetYecfPostedListingWithFilterLazyQuery>;
export type GetYecfPostedListingWithFilterQueryResult = ApolloReactCommon.QueryResult<GetYecfPostedListingWithFilterQuery, GetYecfPostedListingWithFilterQueryVariables>;
export const GetAlBalanceForResignedEmployeesDocument = gql`
    query GetALBalanceForResignedEmployees($CompanyID: String!, $toDate: DateTime!) {
  GetALBalanceForResignedEmployees(CompanyID: $CompanyID, toDate: $toDate) {
    Employee {
      CompanyID
      FormStatus
      JobGradeID
      OrgUnitID
      EmployeeID
      EmployeeNo
      JoinedDate
      ResignedDate
      Status
      ContactID
      Contact {
        FullName
      }
    }
    LeaveType {
      LeaveTypeID
      Name
    }
    LeaveBalanceSummary {
      Entitled
      Earned
      BroughtForward
      Adjustment
      Taken
      TakenYTD
      Balance
      BalanceYTD
      Forfeited
    }
    EncashedLeaveHistory {
      EncashOffboadingLeaveID
      CompanyID
      LeaveTypeID
      EmployeeID
      OneTimePayID
      PayItemID
      PayPeriodID
      PayrollCycleID
      PeriodYear
      LeaveBalance
      PostedDate
      PostStatus
    }
  }
}
    `;

/**
 * __useGetAlBalanceForResignedEmployeesQuery__
 *
 * To run a query within a React component, call `useGetAlBalanceForResignedEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlBalanceForResignedEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlBalanceForResignedEmployeesQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetAlBalanceForResignedEmployeesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAlBalanceForResignedEmployeesQuery, GetAlBalanceForResignedEmployeesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAlBalanceForResignedEmployeesQuery, GetAlBalanceForResignedEmployeesQueryVariables>(GetAlBalanceForResignedEmployeesDocument, baseOptions);
      }
export function useGetAlBalanceForResignedEmployeesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAlBalanceForResignedEmployeesQuery, GetAlBalanceForResignedEmployeesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAlBalanceForResignedEmployeesQuery, GetAlBalanceForResignedEmployeesQueryVariables>(GetAlBalanceForResignedEmployeesDocument, baseOptions);
        }
export type GetAlBalanceForResignedEmployeesQueryHookResult = ReturnType<typeof useGetAlBalanceForResignedEmployeesQuery>;
export type GetAlBalanceForResignedEmployeesLazyQueryHookResult = ReturnType<typeof useGetAlBalanceForResignedEmployeesLazyQuery>;
export type GetAlBalanceForResignedEmployeesQueryResult = ApolloReactCommon.QueryResult<GetAlBalanceForResignedEmployeesQuery, GetAlBalanceForResignedEmployeesQueryVariables>;
export const PostOffboardingLeaveEncashmentDocument = gql`
    mutation PostOffboardingLeaveEncashment($input: [EncashOffboadingLeaveInput!]!) {
  PostOffboardingLeaveEncashment(input: $input)
}
    `;
export type PostOffboardingLeaveEncashmentMutationFn = ApolloReactCommon.MutationFunction<PostOffboardingLeaveEncashmentMutation, PostOffboardingLeaveEncashmentMutationVariables>;

/**
 * __usePostOffboardingLeaveEncashmentMutation__
 *
 * To run a mutation, you first call `usePostOffboardingLeaveEncashmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostOffboardingLeaveEncashmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postOffboardingLeaveEncashmentMutation, { data, loading, error }] = usePostOffboardingLeaveEncashmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostOffboardingLeaveEncashmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostOffboardingLeaveEncashmentMutation, PostOffboardingLeaveEncashmentMutationVariables>) {
        return ApolloReactHooks.useMutation<PostOffboardingLeaveEncashmentMutation, PostOffboardingLeaveEncashmentMutationVariables>(PostOffboardingLeaveEncashmentDocument, baseOptions);
      }
export type PostOffboardingLeaveEncashmentMutationHookResult = ReturnType<typeof usePostOffboardingLeaveEncashmentMutation>;
export type PostOffboardingLeaveEncashmentMutationResult = ApolloReactCommon.MutationResult<PostOffboardingLeaveEncashmentMutation>;
export type PostOffboardingLeaveEncashmentMutationOptions = ApolloReactCommon.BaseMutationOptions<PostOffboardingLeaveEncashmentMutation, PostOffboardingLeaveEncashmentMutationVariables>;
export const RevertOffboardingLeaveEncashmentDocument = gql`
    mutation RevertOffboardingLeaveEncashment($input: [EncashOffboadingLeaveInput!]!) {
  RevertOffboardingLeaveEncashment(input: $input)
}
    `;
export type RevertOffboardingLeaveEncashmentMutationFn = ApolloReactCommon.MutationFunction<RevertOffboardingLeaveEncashmentMutation, RevertOffboardingLeaveEncashmentMutationVariables>;

/**
 * __useRevertOffboardingLeaveEncashmentMutation__
 *
 * To run a mutation, you first call `useRevertOffboardingLeaveEncashmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertOffboardingLeaveEncashmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertOffboardingLeaveEncashmentMutation, { data, loading, error }] = useRevertOffboardingLeaveEncashmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevertOffboardingLeaveEncashmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevertOffboardingLeaveEncashmentMutation, RevertOffboardingLeaveEncashmentMutationVariables>) {
        return ApolloReactHooks.useMutation<RevertOffboardingLeaveEncashmentMutation, RevertOffboardingLeaveEncashmentMutationVariables>(RevertOffboardingLeaveEncashmentDocument, baseOptions);
      }
export type RevertOffboardingLeaveEncashmentMutationHookResult = ReturnType<typeof useRevertOffboardingLeaveEncashmentMutation>;
export type RevertOffboardingLeaveEncashmentMutationResult = ApolloReactCommon.MutationResult<RevertOffboardingLeaveEncashmentMutation>;
export type RevertOffboardingLeaveEncashmentMutationOptions = ApolloReactCommon.BaseMutationOptions<RevertOffboardingLeaveEncashmentMutation, RevertOffboardingLeaveEncashmentMutationVariables>;
export const GetLeaveEntitlementByLeaveTypeListingTotalCountDocument = gql`
    query GetLeaveEntitlementByLeaveTypeListingTotalCount($CompanyID: String!, $LeaveTypeID: String!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  GetLeaveEntitlementByLeaveTypeListingTotalCount(CompanyID: $CompanyID, LeaveTypeID: $LeaveTypeID, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID)
}
    `;

/**
 * __useGetLeaveEntitlementByLeaveTypeListingTotalCountQuery__
 *
 * To run a query within a React component, call `useGetLeaveEntitlementByLeaveTypeListingTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveEntitlementByLeaveTypeListingTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveEntitlementByLeaveTypeListingTotalCountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      LeaveTypeID: // value for 'LeaveTypeID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useGetLeaveEntitlementByLeaveTypeListingTotalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveEntitlementByLeaveTypeListingTotalCountQuery, GetLeaveEntitlementByLeaveTypeListingTotalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveEntitlementByLeaveTypeListingTotalCountQuery, GetLeaveEntitlementByLeaveTypeListingTotalCountQueryVariables>(GetLeaveEntitlementByLeaveTypeListingTotalCountDocument, baseOptions);
      }
export function useGetLeaveEntitlementByLeaveTypeListingTotalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveEntitlementByLeaveTypeListingTotalCountQuery, GetLeaveEntitlementByLeaveTypeListingTotalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveEntitlementByLeaveTypeListingTotalCountQuery, GetLeaveEntitlementByLeaveTypeListingTotalCountQueryVariables>(GetLeaveEntitlementByLeaveTypeListingTotalCountDocument, baseOptions);
        }
export type GetLeaveEntitlementByLeaveTypeListingTotalCountQueryHookResult = ReturnType<typeof useGetLeaveEntitlementByLeaveTypeListingTotalCountQuery>;
export type GetLeaveEntitlementByLeaveTypeListingTotalCountLazyQueryHookResult = ReturnType<typeof useGetLeaveEntitlementByLeaveTypeListingTotalCountLazyQuery>;
export type GetLeaveEntitlementByLeaveTypeListingTotalCountQueryResult = ApolloReactCommon.QueryResult<GetLeaveEntitlementByLeaveTypeListingTotalCountQuery, GetLeaveEntitlementByLeaveTypeListingTotalCountQueryVariables>;
export const GetLeaveEntitlementByLeaveTypeListingDocument = gql`
    query GetLeaveEntitlementByLeaveTypeListing($CompanyID: String!, $LeaveTypeID: String!, $toDate: DateTime!, $DepartmentIDs: [String!], $DivisionIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $offset: Float, $limit: Float) {
  GetLeaveEntitlementByLeaveTypeListing(CompanyID: $CompanyID, LeaveTypeID: $LeaveTypeID, toDate: $toDate, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, DivisionIDs: $DivisionIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, offset: $offset, limit: $limit) {
    Employee {
      JobGradeID
      OrgUnitID
      EmployeeID
      EmployeeNo
      JoinedDate
      Status
      FormStatus
      ResignedDate
      CompanyID
      Contact {
        FullName
      }
    }
    LeaveType {
      Name
      LeavePolicies {
        LeaveFrequency
      }
    }
    LeaveBalanceSummary {
      Entitled
      Earned
      BroughtForward
      Adjustment
      Taken
      TakenYTD
      Balance
      BalanceYTD
      Forfeited
      Occurrence
    }
  }
}
    `;

/**
 * __useGetLeaveEntitlementByLeaveTypeListingQuery__
 *
 * To run a query within a React component, call `useGetLeaveEntitlementByLeaveTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveEntitlementByLeaveTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveEntitlementByLeaveTypeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      LeaveTypeID: // value for 'LeaveTypeID'
 *      toDate: // value for 'toDate'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      DivisionIDs: // value for 'DivisionIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLeaveEntitlementByLeaveTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveEntitlementByLeaveTypeListingQuery, GetLeaveEntitlementByLeaveTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveEntitlementByLeaveTypeListingQuery, GetLeaveEntitlementByLeaveTypeListingQueryVariables>(GetLeaveEntitlementByLeaveTypeListingDocument, baseOptions);
      }
export function useGetLeaveEntitlementByLeaveTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveEntitlementByLeaveTypeListingQuery, GetLeaveEntitlementByLeaveTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveEntitlementByLeaveTypeListingQuery, GetLeaveEntitlementByLeaveTypeListingQueryVariables>(GetLeaveEntitlementByLeaveTypeListingDocument, baseOptions);
        }
export type GetLeaveEntitlementByLeaveTypeListingQueryHookResult = ReturnType<typeof useGetLeaveEntitlementByLeaveTypeListingQuery>;
export type GetLeaveEntitlementByLeaveTypeListingLazyQueryHookResult = ReturnType<typeof useGetLeaveEntitlementByLeaveTypeListingLazyQuery>;
export type GetLeaveEntitlementByLeaveTypeListingQueryResult = ApolloReactCommon.QueryResult<GetLeaveEntitlementByLeaveTypeListingQuery, GetLeaveEntitlementByLeaveTypeListingQueryVariables>;
export const GetOffboardEncashPeriodListingDocument = gql`
    query GetOffboardEncashPeriodListing($CompanyID: String!, $Year: Float!) {
  GetOffboardEncashPeriodListing(CompanyID: $CompanyID, Year: $Year) {
    PeriodYearMonth
    PeriodStatus
    ModifiedDT
  }
}
    `;

/**
 * __useGetOffboardEncashPeriodListingQuery__
 *
 * To run a query within a React component, call `useGetOffboardEncashPeriodListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOffboardEncashPeriodListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOffboardEncashPeriodListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetOffboardEncashPeriodListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOffboardEncashPeriodListingQuery, GetOffboardEncashPeriodListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOffboardEncashPeriodListingQuery, GetOffboardEncashPeriodListingQueryVariables>(GetOffboardEncashPeriodListingDocument, baseOptions);
      }
export function useGetOffboardEncashPeriodListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOffboardEncashPeriodListingQuery, GetOffboardEncashPeriodListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOffboardEncashPeriodListingQuery, GetOffboardEncashPeriodListingQueryVariables>(GetOffboardEncashPeriodListingDocument, baseOptions);
        }
export type GetOffboardEncashPeriodListingQueryHookResult = ReturnType<typeof useGetOffboardEncashPeriodListingQuery>;
export type GetOffboardEncashPeriodListingLazyQueryHookResult = ReturnType<typeof useGetOffboardEncashPeriodListingLazyQuery>;
export type GetOffboardEncashPeriodListingQueryResult = ApolloReactCommon.QueryResult<GetOffboardEncashPeriodListingQuery, GetOffboardEncashPeriodListingQueryVariables>;
export const GetCarryForwardEncashmentPostedListingDocument = gql`
    query GetCarryForwardEncashmentPostedListing($CompanyID: String!, $Year: Float!) {
  GetCarryForwardEncashmentPostedListing(CompanyID: $CompanyID, Year: $Year) {
    EncashCarryForwardLeaveID
    EmployeeID
    CompanyID
    OneTimePayID
    PayItemID
    PayPeriodID
    PayrollCycleID
    PeriodYear
    LeaveEncashment
    PayrollProcessed
    Employee {
      EmployeeNo
      FormStatus
      JobGradeID
      OrgUnitID
      OrgUnit {
        Description
      }
      Contact {
        FullName
      }
    }
    LeaveType {
      LeaveTypeID
      Name
    }
    IsOTPModified
    OTPModifiedBy
    IsOTPDeleted
    OTPDeletedBy
  }
}
    `;

/**
 * __useGetCarryForwardEncashmentPostedListingQuery__
 *
 * To run a query within a React component, call `useGetCarryForwardEncashmentPostedListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarryForwardEncashmentPostedListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarryForwardEncashmentPostedListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetCarryForwardEncashmentPostedListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCarryForwardEncashmentPostedListingQuery, GetCarryForwardEncashmentPostedListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCarryForwardEncashmentPostedListingQuery, GetCarryForwardEncashmentPostedListingQueryVariables>(GetCarryForwardEncashmentPostedListingDocument, baseOptions);
      }
export function useGetCarryForwardEncashmentPostedListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCarryForwardEncashmentPostedListingQuery, GetCarryForwardEncashmentPostedListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCarryForwardEncashmentPostedListingQuery, GetCarryForwardEncashmentPostedListingQueryVariables>(GetCarryForwardEncashmentPostedListingDocument, baseOptions);
        }
export type GetCarryForwardEncashmentPostedListingQueryHookResult = ReturnType<typeof useGetCarryForwardEncashmentPostedListingQuery>;
export type GetCarryForwardEncashmentPostedListingLazyQueryHookResult = ReturnType<typeof useGetCarryForwardEncashmentPostedListingLazyQuery>;
export type GetCarryForwardEncashmentPostedListingQueryResult = ApolloReactCommon.QueryResult<GetCarryForwardEncashmentPostedListingQuery, GetCarryForwardEncashmentPostedListingQueryVariables>;
export const GetCarryForwardEncashmentPendingListingDocument = gql`
    query GetCarryForwardEncashmentPendingListing($CompanyID: String!, $Year: Float!) {
  GetCarryForwardEncashmentPendingListing(CompanyID: $CompanyID, Year: $Year) {
    Employee {
      CompanyID
      JoinedDate
      FormStatus
      JobGradeID
      OrgUnitID
      OrgUnit {
        Description
      }
    }
    HeaderStatus
    EmployeeID
    EmployeeNo
    EmployeeName
    NoOfPendingApprovalApplication
    LeaveItem {
      LeaveItemID
      EncashmentPayItem
      Title
      LeaveType {
        LeaveTypeID
        Name
      }
      RemainingBalance
      CFMethod
      CFValue
      CFMonth
    }
    LeaveBalanceSummary {
      Entitled
      Balance
      CarryForward
      Encashment
    }
  }
}
    `;

/**
 * __useGetCarryForwardEncashmentPendingListingQuery__
 *
 * To run a query within a React component, call `useGetCarryForwardEncashmentPendingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarryForwardEncashmentPendingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarryForwardEncashmentPendingListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetCarryForwardEncashmentPendingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCarryForwardEncashmentPendingListingQuery, GetCarryForwardEncashmentPendingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCarryForwardEncashmentPendingListingQuery, GetCarryForwardEncashmentPendingListingQueryVariables>(GetCarryForwardEncashmentPendingListingDocument, baseOptions);
      }
export function useGetCarryForwardEncashmentPendingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCarryForwardEncashmentPendingListingQuery, GetCarryForwardEncashmentPendingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCarryForwardEncashmentPendingListingQuery, GetCarryForwardEncashmentPendingListingQueryVariables>(GetCarryForwardEncashmentPendingListingDocument, baseOptions);
        }
export type GetCarryForwardEncashmentPendingListingQueryHookResult = ReturnType<typeof useGetCarryForwardEncashmentPendingListingQuery>;
export type GetCarryForwardEncashmentPendingListingLazyQueryHookResult = ReturnType<typeof useGetCarryForwardEncashmentPendingListingLazyQuery>;
export type GetCarryForwardEncashmentPendingListingQueryResult = ApolloReactCommon.QueryResult<GetCarryForwardEncashmentPendingListingQuery, GetCarryForwardEncashmentPendingListingQueryVariables>;
export const PostCarryForwardEncashmentDocument = gql`
    mutation PostCarryForwardEncashment($input: [EncashCarryForwardLeaveInput!]!) {
  PostCarryForwardEncashment(input: $input)
}
    `;
export type PostCarryForwardEncashmentMutationFn = ApolloReactCommon.MutationFunction<PostCarryForwardEncashmentMutation, PostCarryForwardEncashmentMutationVariables>;

/**
 * __usePostCarryForwardEncashmentMutation__
 *
 * To run a mutation, you first call `usePostCarryForwardEncashmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCarryForwardEncashmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCarryForwardEncashmentMutation, { data, loading, error }] = usePostCarryForwardEncashmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostCarryForwardEncashmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostCarryForwardEncashmentMutation, PostCarryForwardEncashmentMutationVariables>) {
        return ApolloReactHooks.useMutation<PostCarryForwardEncashmentMutation, PostCarryForwardEncashmentMutationVariables>(PostCarryForwardEncashmentDocument, baseOptions);
      }
export type PostCarryForwardEncashmentMutationHookResult = ReturnType<typeof usePostCarryForwardEncashmentMutation>;
export type PostCarryForwardEncashmentMutationResult = ApolloReactCommon.MutationResult<PostCarryForwardEncashmentMutation>;
export type PostCarryForwardEncashmentMutationOptions = ApolloReactCommon.BaseMutationOptions<PostCarryForwardEncashmentMutation, PostCarryForwardEncashmentMutationVariables>;
export const GetLeaveAdjustmentEmployeeListingDocument = gql`
    query GetLeaveAdjustmentEmployeeListing($CompanyID: String!, $LeaveTypeID: String!, $LeaveFrequency: LeaveFrequency!) {
  GetLeaveAdjustmentEmployeeListing(CompanyID: $CompanyID, LeaveTypeID: $LeaveTypeID, LeaveFrequency: $LeaveFrequency) {
    EmployeeID
    EmployeeNo
    CalendarStateID
    CalendarPolicyID
    Contact {
      ContactID
      FullName
    }
    Designation {
      JobDesignationID
      Name
    }
    Company {
      CompanyID
      CompanyName
    }
    IsInterCompanyTransfer
    JoinedDate
    JoinedReferenceDate
    Probation
    ConfirmedDate
    EmployeeStatus
    Status
    FormStatus
    ResignedDate
    EmploymentType
    Documents {
      DocumentID
      DocumentFile
    }
    JobGradeID
    OrgUnitID
    OrgUnit {
      CompanyOrgUnitID
      Level
      Description
      ParentID
    }
  }
}
    `;

/**
 * __useGetLeaveAdjustmentEmployeeListingQuery__
 *
 * To run a query within a React component, call `useGetLeaveAdjustmentEmployeeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveAdjustmentEmployeeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveAdjustmentEmployeeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      LeaveTypeID: // value for 'LeaveTypeID'
 *      LeaveFrequency: // value for 'LeaveFrequency'
 *   },
 * });
 */
export function useGetLeaveAdjustmentEmployeeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveAdjustmentEmployeeListingQuery, GetLeaveAdjustmentEmployeeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveAdjustmentEmployeeListingQuery, GetLeaveAdjustmentEmployeeListingQueryVariables>(GetLeaveAdjustmentEmployeeListingDocument, baseOptions);
      }
export function useGetLeaveAdjustmentEmployeeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveAdjustmentEmployeeListingQuery, GetLeaveAdjustmentEmployeeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveAdjustmentEmployeeListingQuery, GetLeaveAdjustmentEmployeeListingQueryVariables>(GetLeaveAdjustmentEmployeeListingDocument, baseOptions);
        }
export type GetLeaveAdjustmentEmployeeListingQueryHookResult = ReturnType<typeof useGetLeaveAdjustmentEmployeeListingQuery>;
export type GetLeaveAdjustmentEmployeeListingLazyQueryHookResult = ReturnType<typeof useGetLeaveAdjustmentEmployeeListingLazyQuery>;
export type GetLeaveAdjustmentEmployeeListingQueryResult = ApolloReactCommon.QueryResult<GetLeaveAdjustmentEmployeeListingQuery, GetLeaveAdjustmentEmployeeListingQueryVariables>;
export const ModerationListingDocument = gql`
    query ModerationListing($CompanyID: String!, $Year: Float!) {
  ModerationListing(CompanyID: $CompanyID, Year: $Year) {
    ModerationRatingID
    CompanyID
    Description
    ScaleSequence
    FinalRating
    From
    To
    Year
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useModerationListingQuery__
 *
 * To run a query within a React component, call `useModerationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useModerationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModerationListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useModerationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ModerationListingQuery, ModerationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ModerationListingQuery, ModerationListingQueryVariables>(ModerationListingDocument, baseOptions);
      }
export function useModerationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModerationListingQuery, ModerationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ModerationListingQuery, ModerationListingQueryVariables>(ModerationListingDocument, baseOptions);
        }
export type ModerationListingQueryHookResult = ReturnType<typeof useModerationListingQuery>;
export type ModerationListingLazyQueryHookResult = ReturnType<typeof useModerationListingLazyQuery>;
export type ModerationListingQueryResult = ApolloReactCommon.QueryResult<ModerationListingQuery, ModerationListingQueryVariables>;
export const ModerationSubmitDocument = gql`
    mutation ModerationSubmit($input: [ModerationRatingInput!]!, $CompanyID: String!, $Year: Float!) {
  ModerationSubmit(input: $input, CompanyID: $CompanyID, Year: $Year)
}
    `;
export type ModerationSubmitMutationFn = ApolloReactCommon.MutationFunction<ModerationSubmitMutation, ModerationSubmitMutationVariables>;

/**
 * __useModerationSubmitMutation__
 *
 * To run a mutation, you first call `useModerationSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModerationSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moderationSubmitMutation, { data, loading, error }] = useModerationSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useModerationSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ModerationSubmitMutation, ModerationSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ModerationSubmitMutation, ModerationSubmitMutationVariables>(ModerationSubmitDocument, baseOptions);
      }
export type ModerationSubmitMutationHookResult = ReturnType<typeof useModerationSubmitMutation>;
export type ModerationSubmitMutationResult = ApolloReactCommon.MutationResult<ModerationSubmitMutation>;
export type ModerationSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ModerationSubmitMutation, ModerationSubmitMutationVariables>;
export const EmployeeScheduledTrainingListingDocument = gql`
    query EmployeeScheduledTrainingListing($CompanyID: String!, $EmployeeID: String!) {
  EmployeeScheduledTrainingListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID) {
    ScheduledTrainingID
    SubscriptionAccountID
    CompanyID
    TrainingProviderID
    CourseID
    CourseName
    StartDate
    EndDate
    Venue
    CourseFee
    LumpSum
    PerClass
    MaxPax
    Remarks
    TraineeEval
    CourseEval
    TrainerEval
    SupervisorEval
    TrainingSchedule
    QRCode
    RegisteredTrainee
    Registered
    GetTrainingCostItemTotal
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    QRCodeDocument {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    DocumentID
  }
}
    `;

/**
 * __useEmployeeScheduledTrainingListingQuery__
 *
 * To run a query within a React component, call `useEmployeeScheduledTrainingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeScheduledTrainingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeScheduledTrainingListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeScheduledTrainingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeScheduledTrainingListingQuery, EmployeeScheduledTrainingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeScheduledTrainingListingQuery, EmployeeScheduledTrainingListingQueryVariables>(EmployeeScheduledTrainingListingDocument, baseOptions);
      }
export function useEmployeeScheduledTrainingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeScheduledTrainingListingQuery, EmployeeScheduledTrainingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeScheduledTrainingListingQuery, EmployeeScheduledTrainingListingQueryVariables>(EmployeeScheduledTrainingListingDocument, baseOptions);
        }
export type EmployeeScheduledTrainingListingQueryHookResult = ReturnType<typeof useEmployeeScheduledTrainingListingQuery>;
export type EmployeeScheduledTrainingListingLazyQueryHookResult = ReturnType<typeof useEmployeeScheduledTrainingListingLazyQuery>;
export type EmployeeScheduledTrainingListingQueryResult = ApolloReactCommon.QueryResult<EmployeeScheduledTrainingListingQuery, EmployeeScheduledTrainingListingQueryVariables>;
export const AvailableCoursesListingDocument = gql`
    query AvailableCoursesListing($CompanyID: String!, $EmployeeID: String!, $JobGradeID: String!) {
  AvailableCoursesListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID, JobGradeID: $JobGradeID) {
    ScheduledTrainingID
    SubscriptionAccountID
    CompanyID
    TrainingProviderID
    CourseID
    CourseName
    StartDate
    EndDate
    Venue
    CourseFee
    LumpSum
    PerClass
    MaxPax
    Remarks
    TraineeEval
    CourseEval
    TrainerEval
    SupervisorEval
    TrainingSchedule
    QRCode
    RegisteredTrainee
    Registered
    GetTrainingCostItemTotal
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    QRCodeDocument {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    DocumentID
  }
}
    `;

/**
 * __useAvailableCoursesListingQuery__
 *
 * To run a query within a React component, call `useAvailableCoursesListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCoursesListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCoursesListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      JobGradeID: // value for 'JobGradeID'
 *   },
 * });
 */
export function useAvailableCoursesListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableCoursesListingQuery, AvailableCoursesListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableCoursesListingQuery, AvailableCoursesListingQueryVariables>(AvailableCoursesListingDocument, baseOptions);
      }
export function useAvailableCoursesListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableCoursesListingQuery, AvailableCoursesListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableCoursesListingQuery, AvailableCoursesListingQueryVariables>(AvailableCoursesListingDocument, baseOptions);
        }
export type AvailableCoursesListingQueryHookResult = ReturnType<typeof useAvailableCoursesListingQuery>;
export type AvailableCoursesListingLazyQueryHookResult = ReturnType<typeof useAvailableCoursesListingLazyQuery>;
export type AvailableCoursesListingQueryResult = ApolloReactCommon.QueryResult<AvailableCoursesListingQuery, AvailableCoursesListingQueryVariables>;
export const CompulsoryCourseListingDocument = gql`
    query CompulsoryCourseListing($CompanyID: String!, $EmployeeID: String!, $JobGradeID: String!) {
  CompulsoryCourseListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID, JobGradeID: $JobGradeID) {
    ScheduledTrainingID
    SubscriptionAccountID
    CompanyID
    TrainingProviderID
    CourseID
    CourseName
    StartDate
    EndDate
    Venue
    CourseFee
    LumpSum
    PerClass
    MaxPax
    Remarks
    TraineeEval
    CourseEval
    TrainerEval
    SupervisorEval
    TrainingSchedule
    QRCode
    RegisteredTrainee
    Registered
    GetTrainingCostItemTotal
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    QRCodeDocument {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    DocumentID
  }
}
    `;

/**
 * __useCompulsoryCourseListingQuery__
 *
 * To run a query within a React component, call `useCompulsoryCourseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompulsoryCourseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompulsoryCourseListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      JobGradeID: // value for 'JobGradeID'
 *   },
 * });
 */
export function useCompulsoryCourseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompulsoryCourseListingQuery, CompulsoryCourseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CompulsoryCourseListingQuery, CompulsoryCourseListingQueryVariables>(CompulsoryCourseListingDocument, baseOptions);
      }
export function useCompulsoryCourseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompulsoryCourseListingQuery, CompulsoryCourseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompulsoryCourseListingQuery, CompulsoryCourseListingQueryVariables>(CompulsoryCourseListingDocument, baseOptions);
        }
export type CompulsoryCourseListingQueryHookResult = ReturnType<typeof useCompulsoryCourseListingQuery>;
export type CompulsoryCourseListingLazyQueryHookResult = ReturnType<typeof useCompulsoryCourseListingLazyQuery>;
export type CompulsoryCourseListingQueryResult = ApolloReactCommon.QueryResult<CompulsoryCourseListingQuery, CompulsoryCourseListingQueryVariables>;
export const RegisteredTraineeInsertDocument = gql`
    mutation RegisteredTraineeInsert($RegisteredTraineeID: String!, $ScheduleTrainingID: String!) {
  RegisteredTraineeInsert(RegisteredTraineeID: $RegisteredTraineeID, ScheduleTrainingID: $ScheduleTrainingID)
}
    `;
export type RegisteredTraineeInsertMutationFn = ApolloReactCommon.MutationFunction<RegisteredTraineeInsertMutation, RegisteredTraineeInsertMutationVariables>;

/**
 * __useRegisteredTraineeInsertMutation__
 *
 * To run a mutation, you first call `useRegisteredTraineeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisteredTraineeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registeredTraineeInsertMutation, { data, loading, error }] = useRegisteredTraineeInsertMutation({
 *   variables: {
 *      RegisteredTraineeID: // value for 'RegisteredTraineeID'
 *      ScheduleTrainingID: // value for 'ScheduleTrainingID'
 *   },
 * });
 */
export function useRegisteredTraineeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisteredTraineeInsertMutation, RegisteredTraineeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisteredTraineeInsertMutation, RegisteredTraineeInsertMutationVariables>(RegisteredTraineeInsertDocument, baseOptions);
      }
export type RegisteredTraineeInsertMutationHookResult = ReturnType<typeof useRegisteredTraineeInsertMutation>;
export type RegisteredTraineeInsertMutationResult = ApolloReactCommon.MutationResult<RegisteredTraineeInsertMutation>;
export type RegisteredTraineeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisteredTraineeInsertMutation, RegisteredTraineeInsertMutationVariables>;
export const EmployeeTrainingAttendanceListingDocument = gql`
    query EmployeeTrainingAttendanceListing($CompanyID: String!, $EmployeeID: String!) {
  EmployeeTrainingAttendanceListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID) {
    ScheduledTrainingID
    SubscriptionAccountID
    CompanyID
    EmployeeID
    CheckInDate
    Employee {
      EmployeeID
      EmployeeNo
      DepartmentID
      DivisionID
      JobGradeID
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
      Company {
        CompanyID
        CompanyName
      }
      EmployeeStatutory {
        EmployeeStatutoryID
      }
      EmployeePrevContribution {
        EmployeePrevContributionID
      }
      EmployeeDependents {
        EmployeeDependentsID
      }
      Department {
        DepartmentID
        DepartmentType
        ParentID
        Description
      }
      Designation {
        JobDesignationID
        Name
      }
      DocumentID
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
      TotalTaxRelief
      FormStatus
      EmploymentType
      CreatedDT
      ModifiedDT
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useEmployeeTrainingAttendanceListingQuery__
 *
 * To run a query within a React component, call `useEmployeeTrainingAttendanceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeTrainingAttendanceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeTrainingAttendanceListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeTrainingAttendanceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeTrainingAttendanceListingQuery, EmployeeTrainingAttendanceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeTrainingAttendanceListingQuery, EmployeeTrainingAttendanceListingQueryVariables>(EmployeeTrainingAttendanceListingDocument, baseOptions);
      }
export function useEmployeeTrainingAttendanceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeTrainingAttendanceListingQuery, EmployeeTrainingAttendanceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeTrainingAttendanceListingQuery, EmployeeTrainingAttendanceListingQueryVariables>(EmployeeTrainingAttendanceListingDocument, baseOptions);
        }
export type EmployeeTrainingAttendanceListingQueryHookResult = ReturnType<typeof useEmployeeTrainingAttendanceListingQuery>;
export type EmployeeTrainingAttendanceListingLazyQueryHookResult = ReturnType<typeof useEmployeeTrainingAttendanceListingLazyQuery>;
export type EmployeeTrainingAttendanceListingQueryResult = ApolloReactCommon.QueryResult<EmployeeTrainingAttendanceListingQuery, EmployeeTrainingAttendanceListingQueryVariables>;
export const ScheduleTrainingListingDocument = gql`
    query ScheduleTrainingListing($CompanyID: String!) {
  ScheduleTrainingListing(CompanyID: $CompanyID) {
    ScheduledTrainingID
    SubscriptionAccountID
    CompanyID
    TrainingProviderID
    CourseID
    CourseName
    StartDate
    EndDate
    Venue
    CourseFee
    LumpSum
    PerClass
    MaxPax
    Remarks
    TraineeEval
    CourseEval
    TrainerEval
    SupervisorEval
    TrainingSchedule
    QRCode
    RegisteredTrainee
    GetTrainingCostItemTotal
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    QRCodeDocument {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    DocumentID
  }
}
    `;

/**
 * __useScheduleTrainingListingQuery__
 *
 * To run a query within a React component, call `useScheduleTrainingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleTrainingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleTrainingListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useScheduleTrainingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScheduleTrainingListingQuery, ScheduleTrainingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ScheduleTrainingListingQuery, ScheduleTrainingListingQueryVariables>(ScheduleTrainingListingDocument, baseOptions);
      }
export function useScheduleTrainingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScheduleTrainingListingQuery, ScheduleTrainingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ScheduleTrainingListingQuery, ScheduleTrainingListingQueryVariables>(ScheduleTrainingListingDocument, baseOptions);
        }
export type ScheduleTrainingListingQueryHookResult = ReturnType<typeof useScheduleTrainingListingQuery>;
export type ScheduleTrainingListingLazyQueryHookResult = ReturnType<typeof useScheduleTrainingListingLazyQuery>;
export type ScheduleTrainingListingQueryResult = ApolloReactCommon.QueryResult<ScheduleTrainingListingQuery, ScheduleTrainingListingQueryVariables>;
export const CreateTrainingAttendanceRecordDocument = gql`
    mutation createTrainingAttendanceRecord($input: TrainingAttendanceInput!) {
  createTrainingAttendanceRecord(input: $input) {
    TrainingAttendanceID
  }
}
    `;
export type CreateTrainingAttendanceRecordMutationFn = ApolloReactCommon.MutationFunction<CreateTrainingAttendanceRecordMutation, CreateTrainingAttendanceRecordMutationVariables>;

/**
 * __useCreateTrainingAttendanceRecordMutation__
 *
 * To run a mutation, you first call `useCreateTrainingAttendanceRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainingAttendanceRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainingAttendanceRecordMutation, { data, loading, error }] = useCreateTrainingAttendanceRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTrainingAttendanceRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTrainingAttendanceRecordMutation, CreateTrainingAttendanceRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTrainingAttendanceRecordMutation, CreateTrainingAttendanceRecordMutationVariables>(CreateTrainingAttendanceRecordDocument, baseOptions);
      }
export type CreateTrainingAttendanceRecordMutationHookResult = ReturnType<typeof useCreateTrainingAttendanceRecordMutation>;
export type CreateTrainingAttendanceRecordMutationResult = ApolloReactCommon.MutationResult<CreateTrainingAttendanceRecordMutation>;
export type CreateTrainingAttendanceRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTrainingAttendanceRecordMutation, CreateTrainingAttendanceRecordMutationVariables>;
export const UpdateTrainingAttendanceRecordDocument = gql`
    mutation updateTrainingAttendanceRecord($input: TrainingAttendanceInput!) {
  updateTrainingAttendanceRecord(input: $input)
}
    `;
export type UpdateTrainingAttendanceRecordMutationFn = ApolloReactCommon.MutationFunction<UpdateTrainingAttendanceRecordMutation, UpdateTrainingAttendanceRecordMutationVariables>;

/**
 * __useUpdateTrainingAttendanceRecordMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingAttendanceRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingAttendanceRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingAttendanceRecordMutation, { data, loading, error }] = useUpdateTrainingAttendanceRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrainingAttendanceRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTrainingAttendanceRecordMutation, UpdateTrainingAttendanceRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTrainingAttendanceRecordMutation, UpdateTrainingAttendanceRecordMutationVariables>(UpdateTrainingAttendanceRecordDocument, baseOptions);
      }
export type UpdateTrainingAttendanceRecordMutationHookResult = ReturnType<typeof useUpdateTrainingAttendanceRecordMutation>;
export type UpdateTrainingAttendanceRecordMutationResult = ApolloReactCommon.MutationResult<UpdateTrainingAttendanceRecordMutation>;
export type UpdateTrainingAttendanceRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTrainingAttendanceRecordMutation, UpdateTrainingAttendanceRecordMutationVariables>;
export const EmpTrainingAttendanceHistoryDocument = gql`
    query EmpTrainingAttendanceHistory($CompanyID: String!, $EmployeeID: String!, $FromDate: String!, $ToDate: String!) {
  EmpTrainingAttendanceHistory(CompanyID: $CompanyID, EmployeeID: $EmployeeID, FromDate: $FromDate, ToDate: $ToDate)
}
    `;

/**
 * __useEmpTrainingAttendanceHistoryQuery__
 *
 * To run a query within a React component, call `useEmpTrainingAttendanceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmpTrainingAttendanceHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmpTrainingAttendanceHistoryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useEmpTrainingAttendanceHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmpTrainingAttendanceHistoryQuery, EmpTrainingAttendanceHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmpTrainingAttendanceHistoryQuery, EmpTrainingAttendanceHistoryQueryVariables>(EmpTrainingAttendanceHistoryDocument, baseOptions);
      }
export function useEmpTrainingAttendanceHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmpTrainingAttendanceHistoryQuery, EmpTrainingAttendanceHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmpTrainingAttendanceHistoryQuery, EmpTrainingAttendanceHistoryQueryVariables>(EmpTrainingAttendanceHistoryDocument, baseOptions);
        }
export type EmpTrainingAttendanceHistoryQueryHookResult = ReturnType<typeof useEmpTrainingAttendanceHistoryQuery>;
export type EmpTrainingAttendanceHistoryLazyQueryHookResult = ReturnType<typeof useEmpTrainingAttendanceHistoryLazyQuery>;
export type EmpTrainingAttendanceHistoryQueryResult = ApolloReactCommon.QueryResult<EmpTrainingAttendanceHistoryQuery, EmpTrainingAttendanceHistoryQueryVariables>;
export const AuthorisedTrainingProviderListingDocument = gql`
    query AuthorisedTrainingProviderListing {
  AuthorisedTrainingProviderListing {
    ID
    SubscriptionAccountID
    DocumentID
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    Name
    IsExternal
    ApprovedCourses
    Contact {
      ContactID
      FullName
      NickName
      MobileNo
      OfficeNo
      FaxNo
      Email
      NRIC
      PassportNo
      PassportNoLHDN
      Nationality
      Marital
      Gender
      Race
      Religion
      BirthDate
      ProfilePicture
      WebUrl
      Address {
        Line1
        Line2
        Country
        State
        City
        PostalCode
      }
      PermanentAddress {
        Line1
        Line2
        Country
        State
        City
        PostalCode
      }
      SameAddress
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useAuthorisedTrainingProviderListingQuery__
 *
 * To run a query within a React component, call `useAuthorisedTrainingProviderListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorisedTrainingProviderListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorisedTrainingProviderListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthorisedTrainingProviderListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuthorisedTrainingProviderListingQuery, AuthorisedTrainingProviderListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AuthorisedTrainingProviderListingQuery, AuthorisedTrainingProviderListingQueryVariables>(AuthorisedTrainingProviderListingDocument, baseOptions);
      }
export function useAuthorisedTrainingProviderListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthorisedTrainingProviderListingQuery, AuthorisedTrainingProviderListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuthorisedTrainingProviderListingQuery, AuthorisedTrainingProviderListingQueryVariables>(AuthorisedTrainingProviderListingDocument, baseOptions);
        }
export type AuthorisedTrainingProviderListingQueryHookResult = ReturnType<typeof useAuthorisedTrainingProviderListingQuery>;
export type AuthorisedTrainingProviderListingLazyQueryHookResult = ReturnType<typeof useAuthorisedTrainingProviderListingLazyQuery>;
export type AuthorisedTrainingProviderListingQueryResult = ApolloReactCommon.QueryResult<AuthorisedTrainingProviderListingQuery, AuthorisedTrainingProviderListingQueryVariables>;
export const EmployeeNotificationListingDocument = gql`
    query EmployeeNotificationListing($EmployeeID: String!) {
  EmployeeNotificationListing(EmployeeID: $EmployeeID) {
    Message
    CreatedDT
    RequestType
    Topic
    Status
    NotificationType
    ReadStatus
    NotificationID
    Message
    LeaveDetails {
      ApprovalStatus
      TotalDay
      LeaveType
      ApproverName
      ApprovalRemark
      Remarks
      FromDate
      UntilDate
    }
    ClaimDetails {
      ApproverName
      ApprovalStatus
      ApprovalRemark
      ClaimSubject
      CreatedDT
      TotalClaim
      TotalClaimAmount
      TotalVerifiedAmount
      ClaimDetailsListing {
        ClaimAmountByFinance
        ClaimAmount
        ClaimRemarks
        ClaimDetailsID
        ReceiptDate
        ReceiptNo
        Documents {
          DocumentFile
          DocumentID
        }
        ClaimType {
          Name
        }
        FinanceApproveStatus
      }
    }
    RequisitionDetails {
      ApproverName
      ApprovalStatus
      ApprovalRemark
      RequisitionType
      DocumentFile
      Subject
      NumberOfItem
      CostPerItem
      Amount
      Remark
      StartDate
      EndDate
      TotalDays
      TravelFrom
      TravelTo
      TransportMode
      CreatedDT
    }
    DataChangesDetails {
      DataChangesID
      ApproverName
      ApprovalStatus
      ApprovalRemark
      CreatedDT
      Action
      Type
      ChangesData {
        DocumentID
        Nationality
        PassportNo
        NRIC
        Marital
        Race
        Religion
        IsOKU
        EmployeeBank
        EmployeeBankNo
        EmployeeEPFNo
        EmployeePCBNo
        PermanentAddress {
          Line1
          Line2
          City
          PostalCode
          State
          Country
        }
        Address {
          Line1
          Line2
          City
          PostalCode
          State
          Country
        }
        EmployeeDependentsID
        ContactID
        IsEmergencyContact
        MobileNo
        Occupation
        Education
        ChildEligibleRate
        PCBNo
        FullName
        BirthDate
      }
      OldData {
        DocumentID
        Nationality
        PassportNo
        NRIC
        Marital
        Race
        Religion
        IsOKU
        EmployeeBank
        EmployeeBankNo
        EmployeeEPFNo
        EmployeePCBNo
        PermanentAddress {
          Line1
          Line2
          City
          PostalCode
          State
          Country
        }
        Address {
          Line1
          Line2
          City
          PostalCode
          State
          Country
        }
        EmployeeDependentsID
        ContactID
        IsEmergencyContact
        MobileNo
        Occupation
        Education
        ChildEligibleRate
        PCBNo
        FullName
        BirthDate
      }
    }
    ProjectExpenseClaimDetails {
      ApproverName
      ApprovalStatus
      ApprovalRemark
      ClaimSubject
      CreatedDT
      TotalClaim
      TotalClaimAmount
      TotalVerifiedAmount
      DocumentIDs {
        DocumentID
        DocumentFile
      }
      ClaimDetailsListing {
        ClaimAmountByFinance
        ClaimAmount
        ClaimRemark
        ID
        ReceiptDate
        ReceiptNo
        ProjectExpenseType {
          ProjectExpenseType
        }
        FinanceApproveStatus
      }
    }
    HelpdeskTicketDetail {
      TicketNo
      SubjectDetails
    }
  }
}
    `;

/**
 * __useEmployeeNotificationListingQuery__
 *
 * To run a query within a React component, call `useEmployeeNotificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeNotificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeNotificationListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeNotificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeNotificationListingQuery, EmployeeNotificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeNotificationListingQuery, EmployeeNotificationListingQueryVariables>(EmployeeNotificationListingDocument, baseOptions);
      }
export function useEmployeeNotificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeNotificationListingQuery, EmployeeNotificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeNotificationListingQuery, EmployeeNotificationListingQueryVariables>(EmployeeNotificationListingDocument, baseOptions);
        }
export type EmployeeNotificationListingQueryHookResult = ReturnType<typeof useEmployeeNotificationListingQuery>;
export type EmployeeNotificationListingLazyQueryHookResult = ReturnType<typeof useEmployeeNotificationListingLazyQuery>;
export type EmployeeNotificationListingQueryResult = ApolloReactCommon.QueryResult<EmployeeNotificationListingQuery, EmployeeNotificationListingQueryVariables>;
export const UpdateNotificationReadStatusDocument = gql`
    mutation UpdateNotificationReadStatus($NotificationID: String!) {
  UpdateNotificationReadStatus(NotificationID: $NotificationID)
}
    `;
export type UpdateNotificationReadStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateNotificationReadStatusMutation, UpdateNotificationReadStatusMutationVariables>;

/**
 * __useUpdateNotificationReadStatusMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationReadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationReadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationReadStatusMutation, { data, loading, error }] = useUpdateNotificationReadStatusMutation({
 *   variables: {
 *      NotificationID: // value for 'NotificationID'
 *   },
 * });
 */
export function useUpdateNotificationReadStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNotificationReadStatusMutation, UpdateNotificationReadStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNotificationReadStatusMutation, UpdateNotificationReadStatusMutationVariables>(UpdateNotificationReadStatusDocument, baseOptions);
      }
export type UpdateNotificationReadStatusMutationHookResult = ReturnType<typeof useUpdateNotificationReadStatusMutation>;
export type UpdateNotificationReadStatusMutationResult = ApolloReactCommon.MutationResult<UpdateNotificationReadStatusMutation>;
export type UpdateNotificationReadStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNotificationReadStatusMutation, UpdateNotificationReadStatusMutationVariables>;
export const UpdateAllNotificationReadStatusDocument = gql`
    mutation UpdateAllNotificationReadStatus($EmployeeID: String!) {
  UpdateAllNotificationReadStatus(EmployeeID: $EmployeeID)
}
    `;
export type UpdateAllNotificationReadStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateAllNotificationReadStatusMutation, UpdateAllNotificationReadStatusMutationVariables>;

/**
 * __useUpdateAllNotificationReadStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAllNotificationReadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllNotificationReadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllNotificationReadStatusMutation, { data, loading, error }] = useUpdateAllNotificationReadStatusMutation({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useUpdateAllNotificationReadStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAllNotificationReadStatusMutation, UpdateAllNotificationReadStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAllNotificationReadStatusMutation, UpdateAllNotificationReadStatusMutationVariables>(UpdateAllNotificationReadStatusDocument, baseOptions);
      }
export type UpdateAllNotificationReadStatusMutationHookResult = ReturnType<typeof useUpdateAllNotificationReadStatusMutation>;
export type UpdateAllNotificationReadStatusMutationResult = ApolloReactCommon.MutationResult<UpdateAllNotificationReadStatusMutation>;
export type UpdateAllNotificationReadStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAllNotificationReadStatusMutation, UpdateAllNotificationReadStatusMutationVariables>;
export const NotificationUpdateDocument = gql`
    mutation NotificationUpdate($NotificationInput: NotificationInput!) {
  NotificationUpdate(input: $NotificationInput)
}
    `;
export type NotificationUpdateMutationFn = ApolloReactCommon.MutationFunction<NotificationUpdateMutation, NotificationUpdateMutationVariables>;

/**
 * __useNotificationUpdateMutation__
 *
 * To run a mutation, you first call `useNotificationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationUpdateMutation, { data, loading, error }] = useNotificationUpdateMutation({
 *   variables: {
 *      NotificationInput: // value for 'NotificationInput'
 *   },
 * });
 */
export function useNotificationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotificationUpdateMutation, NotificationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<NotificationUpdateMutation, NotificationUpdateMutationVariables>(NotificationUpdateDocument, baseOptions);
      }
export type NotificationUpdateMutationHookResult = ReturnType<typeof useNotificationUpdateMutation>;
export type NotificationUpdateMutationResult = ApolloReactCommon.MutationResult<NotificationUpdateMutation>;
export type NotificationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<NotificationUpdateMutation, NotificationUpdateMutationVariables>;
export const NotificationReadStatusDeleteDocument = gql`
    mutation NotificationReadStatusDelete($ID: [String!]!) {
  NotificationReadStatusDelete(ID: $ID)
}
    `;
export type NotificationReadStatusDeleteMutationFn = ApolloReactCommon.MutationFunction<NotificationReadStatusDeleteMutation, NotificationReadStatusDeleteMutationVariables>;

/**
 * __useNotificationReadStatusDeleteMutation__
 *
 * To run a mutation, you first call `useNotificationReadStatusDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationReadStatusDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationReadStatusDeleteMutation, { data, loading, error }] = useNotificationReadStatusDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useNotificationReadStatusDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotificationReadStatusDeleteMutation, NotificationReadStatusDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<NotificationReadStatusDeleteMutation, NotificationReadStatusDeleteMutationVariables>(NotificationReadStatusDeleteDocument, baseOptions);
      }
export type NotificationReadStatusDeleteMutationHookResult = ReturnType<typeof useNotificationReadStatusDeleteMutation>;
export type NotificationReadStatusDeleteMutationResult = ApolloReactCommon.MutationResult<NotificationReadStatusDeleteMutation>;
export type NotificationReadStatusDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<NotificationReadStatusDeleteMutation, NotificationReadStatusDeleteMutationVariables>;
export const UpdateBulkNotificationReadStatusDocument = gql`
    mutation UpdateBulkNotificationReadStatus($IDs: [String!]!) {
  UpdateBulkNotificationReadStatus(IDs: $IDs)
}
    `;
export type UpdateBulkNotificationReadStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateBulkNotificationReadStatusMutation, UpdateBulkNotificationReadStatusMutationVariables>;

/**
 * __useUpdateBulkNotificationReadStatusMutation__
 *
 * To run a mutation, you first call `useUpdateBulkNotificationReadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkNotificationReadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkNotificationReadStatusMutation, { data, loading, error }] = useUpdateBulkNotificationReadStatusMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useUpdateBulkNotificationReadStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBulkNotificationReadStatusMutation, UpdateBulkNotificationReadStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBulkNotificationReadStatusMutation, UpdateBulkNotificationReadStatusMutationVariables>(UpdateBulkNotificationReadStatusDocument, baseOptions);
      }
export type UpdateBulkNotificationReadStatusMutationHookResult = ReturnType<typeof useUpdateBulkNotificationReadStatusMutation>;
export type UpdateBulkNotificationReadStatusMutationResult = ApolloReactCommon.MutationResult<UpdateBulkNotificationReadStatusMutation>;
export type UpdateBulkNotificationReadStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBulkNotificationReadStatusMutation, UpdateBulkNotificationReadStatusMutationVariables>;
export const NotificationPolicyInsertDocument = gql`
    mutation NotificationPolicyInsert($NotificationPolicyInput: NotificationPolicyInput!) {
  NotificationPolicyInsert(NotificationPolicyInput: $NotificationPolicyInput) {
    NotificationPolicyID
  }
}
    `;
export type NotificationPolicyInsertMutationFn = ApolloReactCommon.MutationFunction<NotificationPolicyInsertMutation, NotificationPolicyInsertMutationVariables>;

/**
 * __useNotificationPolicyInsertMutation__
 *
 * To run a mutation, you first call `useNotificationPolicyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationPolicyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationPolicyInsertMutation, { data, loading, error }] = useNotificationPolicyInsertMutation({
 *   variables: {
 *      NotificationPolicyInput: // value for 'NotificationPolicyInput'
 *   },
 * });
 */
export function useNotificationPolicyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotificationPolicyInsertMutation, NotificationPolicyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<NotificationPolicyInsertMutation, NotificationPolicyInsertMutationVariables>(NotificationPolicyInsertDocument, baseOptions);
      }
export type NotificationPolicyInsertMutationHookResult = ReturnType<typeof useNotificationPolicyInsertMutation>;
export type NotificationPolicyInsertMutationResult = ApolloReactCommon.MutationResult<NotificationPolicyInsertMutation>;
export type NotificationPolicyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<NotificationPolicyInsertMutation, NotificationPolicyInsertMutationVariables>;
export const NotificationPolicyUpdateDocument = gql`
    mutation NotificationPolicyUpdate($NotificationPolicyID: String!, $NotificationPolicyInput: NotificationPolicyInput!) {
  NotificationPolicyUpdate(NotificationPolicyID: $NotificationPolicyID, NotificationPolicyInput: $NotificationPolicyInput)
}
    `;
export type NotificationPolicyUpdateMutationFn = ApolloReactCommon.MutationFunction<NotificationPolicyUpdateMutation, NotificationPolicyUpdateMutationVariables>;

/**
 * __useNotificationPolicyUpdateMutation__
 *
 * To run a mutation, you first call `useNotificationPolicyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationPolicyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationPolicyUpdateMutation, { data, loading, error }] = useNotificationPolicyUpdateMutation({
 *   variables: {
 *      NotificationPolicyID: // value for 'NotificationPolicyID'
 *      NotificationPolicyInput: // value for 'NotificationPolicyInput'
 *   },
 * });
 */
export function useNotificationPolicyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotificationPolicyUpdateMutation, NotificationPolicyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<NotificationPolicyUpdateMutation, NotificationPolicyUpdateMutationVariables>(NotificationPolicyUpdateDocument, baseOptions);
      }
export type NotificationPolicyUpdateMutationHookResult = ReturnType<typeof useNotificationPolicyUpdateMutation>;
export type NotificationPolicyUpdateMutationResult = ApolloReactCommon.MutationResult<NotificationPolicyUpdateMutation>;
export type NotificationPolicyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<NotificationPolicyUpdateMutation, NotificationPolicyUpdateMutationVariables>;
export const NotificationPolicyDetailDocument = gql`
    query NotificationPolicyDetail($NotificationPolicyID: String!) {
  NotificationPolicyDetail(NotificationPolicyID: $NotificationPolicyID) {
    NotificationPolicyID
    Subject
    NotificationPolicyType
    PolicyType
    CompanyID
    NotificationPolicyClaim {
      NotificationPolicyClaimID
      CompanyID
      NotificationPolicyID
      ClaimTypeID
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    NotificationPolicyLeave {
      NotificationPolicyLeaveID
      CompanyID
      NotificationPolicyID
      LeaveTypeID
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    NotificationPolicyRequisition {
      NotificationPolicyRequisitionID
      CompanyID
      NotificationPolicyID
      RequisitionType
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    NotificationPolicyCondition {
      NotificationPolicyConditionID
      CompanyID
      NotificationPolicyID
      Level
      Value
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useNotificationPolicyDetailQuery__
 *
 * To run a query within a React component, call `useNotificationPolicyDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationPolicyDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationPolicyDetailQuery({
 *   variables: {
 *      NotificationPolicyID: // value for 'NotificationPolicyID'
 *   },
 * });
 */
export function useNotificationPolicyDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationPolicyDetailQuery, NotificationPolicyDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationPolicyDetailQuery, NotificationPolicyDetailQueryVariables>(NotificationPolicyDetailDocument, baseOptions);
      }
export function useNotificationPolicyDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationPolicyDetailQuery, NotificationPolicyDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationPolicyDetailQuery, NotificationPolicyDetailQueryVariables>(NotificationPolicyDetailDocument, baseOptions);
        }
export type NotificationPolicyDetailQueryHookResult = ReturnType<typeof useNotificationPolicyDetailQuery>;
export type NotificationPolicyDetailLazyQueryHookResult = ReturnType<typeof useNotificationPolicyDetailLazyQuery>;
export type NotificationPolicyDetailQueryResult = ApolloReactCommon.QueryResult<NotificationPolicyDetailQuery, NotificationPolicyDetailQueryVariables>;
export const NotificationPolicyListingDocument = gql`
    query NotificationPolicyListing($CompanyID: String!) {
  NotificationPolicyListing(CompanyID: $CompanyID) {
    NotificationPolicyID
    Subject
    NotificationPolicyType
    PolicyType
    CompanyID
    NotificationPolicyClaim {
      NotificationPolicyClaimID
      CompanyID
      NotificationPolicyID
      ClaimTypeID
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    NotificationPolicyLeave {
      NotificationPolicyLeaveID
      CompanyID
      NotificationPolicyID
      LeaveTypeID
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    NotificationPolicyRequisition {
      NotificationPolicyRequisitionID
      CompanyID
      NotificationPolicyID
      RequisitionType
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    NotificationPolicyCondition {
      NotificationPolicyConditionID
      CompanyID
      NotificationPolicyID
      Level
      Value
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useNotificationPolicyListingQuery__
 *
 * To run a query within a React component, call `useNotificationPolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationPolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationPolicyListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useNotificationPolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationPolicyListingQuery, NotificationPolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationPolicyListingQuery, NotificationPolicyListingQueryVariables>(NotificationPolicyListingDocument, baseOptions);
      }
export function useNotificationPolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationPolicyListingQuery, NotificationPolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationPolicyListingQuery, NotificationPolicyListingQueryVariables>(NotificationPolicyListingDocument, baseOptions);
        }
export type NotificationPolicyListingQueryHookResult = ReturnType<typeof useNotificationPolicyListingQuery>;
export type NotificationPolicyListingLazyQueryHookResult = ReturnType<typeof useNotificationPolicyListingLazyQuery>;
export type NotificationPolicyListingQueryResult = ApolloReactCommon.QueryResult<NotificationPolicyListingQuery, NotificationPolicyListingQueryVariables>;
export const NotificationPolicyConditionInsertDocument = gql`
    mutation NotificationPolicyConditionInsert($NotificationPolicyConditionInput: NotificationPolicyConditionInput!) {
  NotificationPolicyConditionInsert(NotificationPolicyConditionInput: $NotificationPolicyConditionInput) {
    NotificationPolicyConditionID
  }
}
    `;
export type NotificationPolicyConditionInsertMutationFn = ApolloReactCommon.MutationFunction<NotificationPolicyConditionInsertMutation, NotificationPolicyConditionInsertMutationVariables>;

/**
 * __useNotificationPolicyConditionInsertMutation__
 *
 * To run a mutation, you first call `useNotificationPolicyConditionInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationPolicyConditionInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationPolicyConditionInsertMutation, { data, loading, error }] = useNotificationPolicyConditionInsertMutation({
 *   variables: {
 *      NotificationPolicyConditionInput: // value for 'NotificationPolicyConditionInput'
 *   },
 * });
 */
export function useNotificationPolicyConditionInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotificationPolicyConditionInsertMutation, NotificationPolicyConditionInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<NotificationPolicyConditionInsertMutation, NotificationPolicyConditionInsertMutationVariables>(NotificationPolicyConditionInsertDocument, baseOptions);
      }
export type NotificationPolicyConditionInsertMutationHookResult = ReturnType<typeof useNotificationPolicyConditionInsertMutation>;
export type NotificationPolicyConditionInsertMutationResult = ApolloReactCommon.MutationResult<NotificationPolicyConditionInsertMutation>;
export type NotificationPolicyConditionInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<NotificationPolicyConditionInsertMutation, NotificationPolicyConditionInsertMutationVariables>;
export const NotificationPolicyConditionUpdateDocument = gql`
    mutation NotificationPolicyConditionUpdate($NotificationPolicyConditionID: String!, $NotificationPolicyConditionInput: NotificationPolicyConditionInput!) {
  NotificationPolicyConditionUpdate(NotificationPolicyConditionID: $NotificationPolicyConditionID, NotificationPolicyConditionInput: $NotificationPolicyConditionInput)
}
    `;
export type NotificationPolicyConditionUpdateMutationFn = ApolloReactCommon.MutationFunction<NotificationPolicyConditionUpdateMutation, NotificationPolicyConditionUpdateMutationVariables>;

/**
 * __useNotificationPolicyConditionUpdateMutation__
 *
 * To run a mutation, you first call `useNotificationPolicyConditionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationPolicyConditionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationPolicyConditionUpdateMutation, { data, loading, error }] = useNotificationPolicyConditionUpdateMutation({
 *   variables: {
 *      NotificationPolicyConditionID: // value for 'NotificationPolicyConditionID'
 *      NotificationPolicyConditionInput: // value for 'NotificationPolicyConditionInput'
 *   },
 * });
 */
export function useNotificationPolicyConditionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotificationPolicyConditionUpdateMutation, NotificationPolicyConditionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<NotificationPolicyConditionUpdateMutation, NotificationPolicyConditionUpdateMutationVariables>(NotificationPolicyConditionUpdateDocument, baseOptions);
      }
export type NotificationPolicyConditionUpdateMutationHookResult = ReturnType<typeof useNotificationPolicyConditionUpdateMutation>;
export type NotificationPolicyConditionUpdateMutationResult = ApolloReactCommon.MutationResult<NotificationPolicyConditionUpdateMutation>;
export type NotificationPolicyConditionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<NotificationPolicyConditionUpdateMutation, NotificationPolicyConditionUpdateMutationVariables>;
export const NotificationPolicyConditionListingDocument = gql`
    query NotificationPolicyConditionListing($NotificationPolicyID: String!) {
  NotificationPolicyConditionListing(NotificationPolicyID: $NotificationPolicyID) {
    NotificationPolicyConditionID
    CompanyID
    NotificationPolicyID
    Level
    Value
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useNotificationPolicyConditionListingQuery__
 *
 * To run a query within a React component, call `useNotificationPolicyConditionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationPolicyConditionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationPolicyConditionListingQuery({
 *   variables: {
 *      NotificationPolicyID: // value for 'NotificationPolicyID'
 *   },
 * });
 */
export function useNotificationPolicyConditionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationPolicyConditionListingQuery, NotificationPolicyConditionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationPolicyConditionListingQuery, NotificationPolicyConditionListingQueryVariables>(NotificationPolicyConditionListingDocument, baseOptions);
      }
export function useNotificationPolicyConditionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationPolicyConditionListingQuery, NotificationPolicyConditionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationPolicyConditionListingQuery, NotificationPolicyConditionListingQueryVariables>(NotificationPolicyConditionListingDocument, baseOptions);
        }
export type NotificationPolicyConditionListingQueryHookResult = ReturnType<typeof useNotificationPolicyConditionListingQuery>;
export type NotificationPolicyConditionListingLazyQueryHookResult = ReturnType<typeof useNotificationPolicyConditionListingLazyQuery>;
export type NotificationPolicyConditionListingQueryResult = ApolloReactCommon.QueryResult<NotificationPolicyConditionListingQuery, NotificationPolicyConditionListingQueryVariables>;
export const NotificationPolicyConditionDetailDocument = gql`
    query NotificationPolicyConditionDetail($NotificationPolicyConditionID: String!) {
  NotificationPolicyConditionDetail(NotificationPolicyConditionID: $NotificationPolicyConditionID) {
    NotificationPolicyConditionID
    CompanyID
    NotificationPolicyID
    Level
    Value
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useNotificationPolicyConditionDetailQuery__
 *
 * To run a query within a React component, call `useNotificationPolicyConditionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationPolicyConditionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationPolicyConditionDetailQuery({
 *   variables: {
 *      NotificationPolicyConditionID: // value for 'NotificationPolicyConditionID'
 *   },
 * });
 */
export function useNotificationPolicyConditionDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationPolicyConditionDetailQuery, NotificationPolicyConditionDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationPolicyConditionDetailQuery, NotificationPolicyConditionDetailQueryVariables>(NotificationPolicyConditionDetailDocument, baseOptions);
      }
export function useNotificationPolicyConditionDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationPolicyConditionDetailQuery, NotificationPolicyConditionDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationPolicyConditionDetailQuery, NotificationPolicyConditionDetailQueryVariables>(NotificationPolicyConditionDetailDocument, baseOptions);
        }
export type NotificationPolicyConditionDetailQueryHookResult = ReturnType<typeof useNotificationPolicyConditionDetailQuery>;
export type NotificationPolicyConditionDetailLazyQueryHookResult = ReturnType<typeof useNotificationPolicyConditionDetailLazyQuery>;
export type NotificationPolicyConditionDetailQueryResult = ApolloReactCommon.QueryResult<NotificationPolicyConditionDetailQuery, NotificationPolicyConditionDetailQueryVariables>;
export const NotificationPolicyDeleteDocument = gql`
    mutation NotificationPolicyDelete($NotificationPolicyInput: NotificationPolicyInput!) {
  NotificationPolicyDelete(NotificationPolicyInput: $NotificationPolicyInput)
}
    `;
export type NotificationPolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<NotificationPolicyDeleteMutation, NotificationPolicyDeleteMutationVariables>;

/**
 * __useNotificationPolicyDeleteMutation__
 *
 * To run a mutation, you first call `useNotificationPolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationPolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationPolicyDeleteMutation, { data, loading, error }] = useNotificationPolicyDeleteMutation({
 *   variables: {
 *      NotificationPolicyInput: // value for 'NotificationPolicyInput'
 *   },
 * });
 */
export function useNotificationPolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NotificationPolicyDeleteMutation, NotificationPolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<NotificationPolicyDeleteMutation, NotificationPolicyDeleteMutationVariables>(NotificationPolicyDeleteDocument, baseOptions);
      }
export type NotificationPolicyDeleteMutationHookResult = ReturnType<typeof useNotificationPolicyDeleteMutation>;
export type NotificationPolicyDeleteMutationResult = ApolloReactCommon.MutationResult<NotificationPolicyDeleteMutation>;
export type NotificationPolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<NotificationPolicyDeleteMutation, NotificationPolicyDeleteMutationVariables>;
export const NotificationPolicySummaryDatesDocument = gql`
    query NotificationPolicySummaryDates($CompanyID: String!) {
  NotificationPolicySummaryDates(CompanyID: $CompanyID) {
    NotificationEmploymentIsLastUpdated
    NotificationLeaveIsLastUpdated
    NotificationClaimIsLastUpdated
    NotificationRequisitionIsLastUpdated
    NotificationEmploymentLastPostedDate
    NotificationLeaveLastPostedDate
    NotificationClaimLastPostedDate
    NotificationRequisitionLastPostedDate
    NotificationLastPostedDate
    NotificationIsLastUpdated
  }
}
    `;

/**
 * __useNotificationPolicySummaryDatesQuery__
 *
 * To run a query within a React component, call `useNotificationPolicySummaryDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationPolicySummaryDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationPolicySummaryDatesQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useNotificationPolicySummaryDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NotificationPolicySummaryDatesQuery, NotificationPolicySummaryDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<NotificationPolicySummaryDatesQuery, NotificationPolicySummaryDatesQueryVariables>(NotificationPolicySummaryDatesDocument, baseOptions);
      }
export function useNotificationPolicySummaryDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NotificationPolicySummaryDatesQuery, NotificationPolicySummaryDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NotificationPolicySummaryDatesQuery, NotificationPolicySummaryDatesQueryVariables>(NotificationPolicySummaryDatesDocument, baseOptions);
        }
export type NotificationPolicySummaryDatesQueryHookResult = ReturnType<typeof useNotificationPolicySummaryDatesQuery>;
export type NotificationPolicySummaryDatesLazyQueryHookResult = ReturnType<typeof useNotificationPolicySummaryDatesLazyQuery>;
export type NotificationPolicySummaryDatesQueryResult = ApolloReactCommon.QueryResult<NotificationPolicySummaryDatesQuery, NotificationPolicySummaryDatesQueryVariables>;
export const UpdateMonthlyTransactionByFixedAllowanceDocument = gql`
    mutation UpdateMonthlyTransactionByFixedAllowance($PayrollCycleID: String!, $PayPeriodID: String!, $CompanyID: String!, $PeriodYearMonth: String!) {
  UpdateMonthlyTransactionByFixedAllowance(PayrollCycleID: $PayrollCycleID, PayPeriodID: $PayPeriodID, CompanyID: $CompanyID, PeriodYearMonth: $PeriodYearMonth)
}
    `;
export type UpdateMonthlyTransactionByFixedAllowanceMutationFn = ApolloReactCommon.MutationFunction<UpdateMonthlyTransactionByFixedAllowanceMutation, UpdateMonthlyTransactionByFixedAllowanceMutationVariables>;

/**
 * __useUpdateMonthlyTransactionByFixedAllowanceMutation__
 *
 * To run a mutation, you first call `useUpdateMonthlyTransactionByFixedAllowanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonthlyTransactionByFixedAllowanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonthlyTransactionByFixedAllowanceMutation, { data, loading, error }] = useUpdateMonthlyTransactionByFixedAllowanceMutation({
 *   variables: {
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      CompanyID: // value for 'CompanyID'
 *      PeriodYearMonth: // value for 'PeriodYearMonth'
 *   },
 * });
 */
export function useUpdateMonthlyTransactionByFixedAllowanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMonthlyTransactionByFixedAllowanceMutation, UpdateMonthlyTransactionByFixedAllowanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMonthlyTransactionByFixedAllowanceMutation, UpdateMonthlyTransactionByFixedAllowanceMutationVariables>(UpdateMonthlyTransactionByFixedAllowanceDocument, baseOptions);
      }
export type UpdateMonthlyTransactionByFixedAllowanceMutationHookResult = ReturnType<typeof useUpdateMonthlyTransactionByFixedAllowanceMutation>;
export type UpdateMonthlyTransactionByFixedAllowanceMutationResult = ApolloReactCommon.MutationResult<UpdateMonthlyTransactionByFixedAllowanceMutation>;
export type UpdateMonthlyTransactionByFixedAllowanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMonthlyTransactionByFixedAllowanceMutation, UpdateMonthlyTransactionByFixedAllowanceMutationVariables>;
export const PayrollCompanySettingSummaryDocument = gql`
    query PayrollCompanySettingSummary($CompanyID: String!) {
  PayrollCompanySettingSummary(CompanyID: $CompanyID)
}
    `;

/**
 * __usePayrollCompanySettingSummaryQuery__
 *
 * To run a query within a React component, call `usePayrollCompanySettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollCompanySettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollCompanySettingSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePayrollCompanySettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollCompanySettingSummaryQuery, PayrollCompanySettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollCompanySettingSummaryQuery, PayrollCompanySettingSummaryQueryVariables>(PayrollCompanySettingSummaryDocument, baseOptions);
      }
export function usePayrollCompanySettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollCompanySettingSummaryQuery, PayrollCompanySettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollCompanySettingSummaryQuery, PayrollCompanySettingSummaryQueryVariables>(PayrollCompanySettingSummaryDocument, baseOptions);
        }
export type PayrollCompanySettingSummaryQueryHookResult = ReturnType<typeof usePayrollCompanySettingSummaryQuery>;
export type PayrollCompanySettingSummaryLazyQueryHookResult = ReturnType<typeof usePayrollCompanySettingSummaryLazyQuery>;
export type PayrollCompanySettingSummaryQueryResult = ApolloReactCommon.QueryResult<PayrollCompanySettingSummaryQuery, PayrollCompanySettingSummaryQueryVariables>;
export const ViewPayrollHistorySummaryListingDocument = gql`
    query ViewPayrollHistorySummaryListing($CompanyID: String!, $Year: Float!, $IsViewHistory: Boolean!) {
  PayrollProcessPeriodListing(CompanyID: $CompanyID, Year: $Year, IsViewHistory: $IsViewHistory) {
    PayPeriodID
    FromDate
    ToDate
    PeriodYearMonth
    LastProcessedDate
    PayrollProcessStatus
    TotalNetPay
    TotalEmployee
    ModifiedBy
    TotalPayrollCyclePeriodSetting
  }
}
    `;

/**
 * __useViewPayrollHistorySummaryListingQuery__
 *
 * To run a query within a React component, call `useViewPayrollHistorySummaryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPayrollHistorySummaryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPayrollHistorySummaryListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *      IsViewHistory: // value for 'IsViewHistory'
 *   },
 * });
 */
export function useViewPayrollHistorySummaryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewPayrollHistorySummaryListingQuery, ViewPayrollHistorySummaryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewPayrollHistorySummaryListingQuery, ViewPayrollHistorySummaryListingQueryVariables>(ViewPayrollHistorySummaryListingDocument, baseOptions);
      }
export function useViewPayrollHistorySummaryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewPayrollHistorySummaryListingQuery, ViewPayrollHistorySummaryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewPayrollHistorySummaryListingQuery, ViewPayrollHistorySummaryListingQueryVariables>(ViewPayrollHistorySummaryListingDocument, baseOptions);
        }
export type ViewPayrollHistorySummaryListingQueryHookResult = ReturnType<typeof useViewPayrollHistorySummaryListingQuery>;
export type ViewPayrollHistorySummaryListingLazyQueryHookResult = ReturnType<typeof useViewPayrollHistorySummaryListingLazyQuery>;
export type ViewPayrollHistorySummaryListingQueryResult = ApolloReactCommon.QueryResult<ViewPayrollHistorySummaryListingQuery, ViewPayrollHistorySummaryListingQueryVariables>;
export const GetPayrollCyclePeriodSettingDocument = gql`
    query GetPayrollCyclePeriodSetting($companyID: String!, $payPeriodID: String!) {
  GetPayrollCyclePeriodSetting(CompanyID: $companyID, PayPeriodID: $payPeriodID) {
    PayrollCycleID
    SequenceNo
    IsLastProcess
    PayPeriodID
    PayrollCyclePeriodID
    IsProccess
  }
}
    `;

/**
 * __useGetPayrollCyclePeriodSettingQuery__
 *
 * To run a query within a React component, call `useGetPayrollCyclePeriodSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollCyclePeriodSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollCyclePeriodSettingQuery({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      payPeriodID: // value for 'payPeriodID'
 *   },
 * });
 */
export function useGetPayrollCyclePeriodSettingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPayrollCyclePeriodSettingQuery, GetPayrollCyclePeriodSettingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPayrollCyclePeriodSettingQuery, GetPayrollCyclePeriodSettingQueryVariables>(GetPayrollCyclePeriodSettingDocument, baseOptions);
      }
export function useGetPayrollCyclePeriodSettingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPayrollCyclePeriodSettingQuery, GetPayrollCyclePeriodSettingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPayrollCyclePeriodSettingQuery, GetPayrollCyclePeriodSettingQueryVariables>(GetPayrollCyclePeriodSettingDocument, baseOptions);
        }
export type GetPayrollCyclePeriodSettingQueryHookResult = ReturnType<typeof useGetPayrollCyclePeriodSettingQuery>;
export type GetPayrollCyclePeriodSettingLazyQueryHookResult = ReturnType<typeof useGetPayrollCyclePeriodSettingLazyQuery>;
export type GetPayrollCyclePeriodSettingQueryResult = ApolloReactCommon.QueryResult<GetPayrollCyclePeriodSettingQuery, GetPayrollCyclePeriodSettingQueryVariables>;
export const ViewPayrollHistorySummaryDocument = gql`
    query ViewPayrollHistorySummary($input: ViewPayrollHistoryInput!, $offset: Float, $limit: Float) {
  ViewPayrollHistory(input: $input, offset: $offset, limit: $limit) {
    PayrollHeaderID
    EmployeeID
    Employee {
      JobGradeID
      OrgUnitID
      FormStatus
      EmployeeNo
      Status
      Contact {
        FullName
      }
    }
    TotalGross
    TotalDeduction
    ProcessLog
    NetPay
    Status
    CompanyID
    PayrollCycle {
      Description
    }
    Islocked
  }
}
    `;

/**
 * __useViewPayrollHistorySummaryQuery__
 *
 * To run a query within a React component, call `useViewPayrollHistorySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPayrollHistorySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPayrollHistorySummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useViewPayrollHistorySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewPayrollHistorySummaryQuery, ViewPayrollHistorySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewPayrollHistorySummaryQuery, ViewPayrollHistorySummaryQueryVariables>(ViewPayrollHistorySummaryDocument, baseOptions);
      }
export function useViewPayrollHistorySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewPayrollHistorySummaryQuery, ViewPayrollHistorySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewPayrollHistorySummaryQuery, ViewPayrollHistorySummaryQueryVariables>(ViewPayrollHistorySummaryDocument, baseOptions);
        }
export type ViewPayrollHistorySummaryQueryHookResult = ReturnType<typeof useViewPayrollHistorySummaryQuery>;
export type ViewPayrollHistorySummaryLazyQueryHookResult = ReturnType<typeof useViewPayrollHistorySummaryLazyQuery>;
export type ViewPayrollHistorySummaryQueryResult = ApolloReactCommon.QueryResult<ViewPayrollHistorySummaryQuery, ViewPayrollHistorySummaryQueryVariables>;
export const ViewPayrollHistorySummaryCountDocument = gql`
    query ViewPayrollHistorySummaryCount($input: ViewPayrollHistoryInput!) {
  ViewPayrollHistoryCount(input: $input)
}
    `;

/**
 * __useViewPayrollHistorySummaryCountQuery__
 *
 * To run a query within a React component, call `useViewPayrollHistorySummaryCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPayrollHistorySummaryCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPayrollHistorySummaryCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViewPayrollHistorySummaryCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewPayrollHistorySummaryCountQuery, ViewPayrollHistorySummaryCountQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewPayrollHistorySummaryCountQuery, ViewPayrollHistorySummaryCountQueryVariables>(ViewPayrollHistorySummaryCountDocument, baseOptions);
      }
export function useViewPayrollHistorySummaryCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewPayrollHistorySummaryCountQuery, ViewPayrollHistorySummaryCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewPayrollHistorySummaryCountQuery, ViewPayrollHistorySummaryCountQueryVariables>(ViewPayrollHistorySummaryCountDocument, baseOptions);
        }
export type ViewPayrollHistorySummaryCountQueryHookResult = ReturnType<typeof useViewPayrollHistorySummaryCountQuery>;
export type ViewPayrollHistorySummaryCountLazyQueryHookResult = ReturnType<typeof useViewPayrollHistorySummaryCountLazyQuery>;
export type ViewPayrollHistorySummaryCountQueryResult = ApolloReactCommon.QueryResult<ViewPayrollHistorySummaryCountQuery, ViewPayrollHistorySummaryCountQueryVariables>;
export const ViewPayrollHistoryTotalDocument = gql`
    query ViewPayrollHistoryTotal($input: ViewPayrollHistoryInput!) {
  ViewPayrollHistoryTotal(input: $input)
}
    `;

/**
 * __useViewPayrollHistoryTotalQuery__
 *
 * To run a query within a React component, call `useViewPayrollHistoryTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPayrollHistoryTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPayrollHistoryTotalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViewPayrollHistoryTotalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewPayrollHistoryTotalQuery, ViewPayrollHistoryTotalQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewPayrollHistoryTotalQuery, ViewPayrollHistoryTotalQueryVariables>(ViewPayrollHistoryTotalDocument, baseOptions);
      }
export function useViewPayrollHistoryTotalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewPayrollHistoryTotalQuery, ViewPayrollHistoryTotalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewPayrollHistoryTotalQuery, ViewPayrollHistoryTotalQueryVariables>(ViewPayrollHistoryTotalDocument, baseOptions);
        }
export type ViewPayrollHistoryTotalQueryHookResult = ReturnType<typeof useViewPayrollHistoryTotalQuery>;
export type ViewPayrollHistoryTotalLazyQueryHookResult = ReturnType<typeof useViewPayrollHistoryTotalLazyQuery>;
export type ViewPayrollHistoryTotalQueryResult = ApolloReactCommon.QueryResult<ViewPayrollHistoryTotalQuery, ViewPayrollHistoryTotalQueryVariables>;
export const PayrollFixedAllowanceListingDocument = gql`
    query PayrollFixedAllowanceListing($input: PayrollFixedAllowanceInput!) {
  PayrollFixedAllowanceListing(input: $input) {
    PayrollCycleID
    RecurringPayID
    EmployeeID
    Remark
    Amount
    Quantity
    PayItem {
      PayName
      PayItemType
    }
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    StartPeriod {
      PeriodYearMonth
    }
    EndPeriod {
      PeriodYearMonth
    }
    PayrollCycle {
      PayrollCycleID
      Description
    }
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __usePayrollFixedAllowanceListingQuery__
 *
 * To run a query within a React component, call `usePayrollFixedAllowanceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollFixedAllowanceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollFixedAllowanceListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayrollFixedAllowanceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollFixedAllowanceListingQuery, PayrollFixedAllowanceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollFixedAllowanceListingQuery, PayrollFixedAllowanceListingQueryVariables>(PayrollFixedAllowanceListingDocument, baseOptions);
      }
export function usePayrollFixedAllowanceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollFixedAllowanceListingQuery, PayrollFixedAllowanceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollFixedAllowanceListingQuery, PayrollFixedAllowanceListingQueryVariables>(PayrollFixedAllowanceListingDocument, baseOptions);
        }
export type PayrollFixedAllowanceListingQueryHookResult = ReturnType<typeof usePayrollFixedAllowanceListingQuery>;
export type PayrollFixedAllowanceListingLazyQueryHookResult = ReturnType<typeof usePayrollFixedAllowanceListingLazyQuery>;
export type PayrollFixedAllowanceListingQueryResult = ApolloReactCommon.QueryResult<PayrollFixedAllowanceListingQuery, PayrollFixedAllowanceListingQueryVariables>;
export const PayrollFixedAllowanceManualEntryListingDocument = gql`
    query PayrollFixedAllowanceManualEntryListing($input: PayrollFixedAllowanceInput!, $offset: Float, $limit: Float) {
  PayrollFixedAllowanceManualEntryListing(input: $input, offset: $offset, limit: $limit) {
    PayrollCycleID
    RecurringPayID
    EmployeeID
    Remark
    Amount
    Quantity
    PayItem {
      PayName
      PayItemType
    }
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    StartPeriod {
      PeriodYearMonth
    }
    EndPeriod {
      PeriodYearMonth
    }
    PayrollCycle {
      PayrollCycleID
      Description
    }
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __usePayrollFixedAllowanceManualEntryListingQuery__
 *
 * To run a query within a React component, call `usePayrollFixedAllowanceManualEntryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollFixedAllowanceManualEntryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollFixedAllowanceManualEntryListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePayrollFixedAllowanceManualEntryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollFixedAllowanceManualEntryListingQuery, PayrollFixedAllowanceManualEntryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollFixedAllowanceManualEntryListingQuery, PayrollFixedAllowanceManualEntryListingQueryVariables>(PayrollFixedAllowanceManualEntryListingDocument, baseOptions);
      }
export function usePayrollFixedAllowanceManualEntryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollFixedAllowanceManualEntryListingQuery, PayrollFixedAllowanceManualEntryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollFixedAllowanceManualEntryListingQuery, PayrollFixedAllowanceManualEntryListingQueryVariables>(PayrollFixedAllowanceManualEntryListingDocument, baseOptions);
        }
export type PayrollFixedAllowanceManualEntryListingQueryHookResult = ReturnType<typeof usePayrollFixedAllowanceManualEntryListingQuery>;
export type PayrollFixedAllowanceManualEntryListingLazyQueryHookResult = ReturnType<typeof usePayrollFixedAllowanceManualEntryListingLazyQuery>;
export type PayrollFixedAllowanceManualEntryListingQueryResult = ApolloReactCommon.QueryResult<PayrollFixedAllowanceManualEntryListingQuery, PayrollFixedAllowanceManualEntryListingQueryVariables>;
export const PayrollFixedAllowanceManualEntryListingCountDocument = gql`
    query PayrollFixedAllowanceManualEntryListingCount($input: PayrollFixedAllowanceInput!) {
  PayrollFixedAllowanceManualEntryListingCount(input: $input)
}
    `;

/**
 * __usePayrollFixedAllowanceManualEntryListingCountQuery__
 *
 * To run a query within a React component, call `usePayrollFixedAllowanceManualEntryListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollFixedAllowanceManualEntryListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollFixedAllowanceManualEntryListingCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayrollFixedAllowanceManualEntryListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollFixedAllowanceManualEntryListingCountQuery, PayrollFixedAllowanceManualEntryListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollFixedAllowanceManualEntryListingCountQuery, PayrollFixedAllowanceManualEntryListingCountQueryVariables>(PayrollFixedAllowanceManualEntryListingCountDocument, baseOptions);
      }
export function usePayrollFixedAllowanceManualEntryListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollFixedAllowanceManualEntryListingCountQuery, PayrollFixedAllowanceManualEntryListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollFixedAllowanceManualEntryListingCountQuery, PayrollFixedAllowanceManualEntryListingCountQueryVariables>(PayrollFixedAllowanceManualEntryListingCountDocument, baseOptions);
        }
export type PayrollFixedAllowanceManualEntryListingCountQueryHookResult = ReturnType<typeof usePayrollFixedAllowanceManualEntryListingCountQuery>;
export type PayrollFixedAllowanceManualEntryListingCountLazyQueryHookResult = ReturnType<typeof usePayrollFixedAllowanceManualEntryListingCountLazyQuery>;
export type PayrollFixedAllowanceManualEntryListingCountQueryResult = ApolloReactCommon.QueryResult<PayrollFixedAllowanceManualEntryListingCountQuery, PayrollFixedAllowanceManualEntryListingCountQueryVariables>;
export const PayrollProcessPeriodListingDocument = gql`
    query PayrollProcessPeriodListing($CompanyID: String!, $Year: Float!, $IsViewHistory: Boolean!) {
  PayrollProcessPeriodListing(CompanyID: $CompanyID, Year: $Year, IsViewHistory: $IsViewHistory) {
    PayPeriodID
    FromDate
    ToDate
    PeriodYearMonth
    LastProcessedDate
    PayrollProcessStatus
    TotalNetPay
    TotalEmployee
    ModifiedBy
    TotalPayrollCyclePeriodSetting
    LastInitiatedDate
    LastPayrollGLProcess
  }
}
    `;

/**
 * __usePayrollProcessPeriodListingQuery__
 *
 * To run a query within a React component, call `usePayrollProcessPeriodListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollProcessPeriodListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollProcessPeriodListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *      IsViewHistory: // value for 'IsViewHistory'
 *   },
 * });
 */
export function usePayrollProcessPeriodListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollProcessPeriodListingQuery, PayrollProcessPeriodListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollProcessPeriodListingQuery, PayrollProcessPeriodListingQueryVariables>(PayrollProcessPeriodListingDocument, baseOptions);
      }
export function usePayrollProcessPeriodListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollProcessPeriodListingQuery, PayrollProcessPeriodListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollProcessPeriodListingQuery, PayrollProcessPeriodListingQueryVariables>(PayrollProcessPeriodListingDocument, baseOptions);
        }
export type PayrollProcessPeriodListingQueryHookResult = ReturnType<typeof usePayrollProcessPeriodListingQuery>;
export type PayrollProcessPeriodListingLazyQueryHookResult = ReturnType<typeof usePayrollProcessPeriodListingLazyQuery>;
export type PayrollProcessPeriodListingQueryResult = ApolloReactCommon.QueryResult<PayrollProcessPeriodListingQuery, PayrollProcessPeriodListingQueryVariables>;
export const IsMonthlyTrxInUseDocument = gql`
    query IsMonthlyTrxInUse($EmployeeID: String!, $PayPeriodID: String!, $PayrollCycleID: String!) {
  IsMonthlyTrxInUse(EmployeeID: $EmployeeID, PayPeriodID: $PayPeriodID, PayrollCycleID: $PayrollCycleID)
}
    `;

/**
 * __useIsMonthlyTrxInUseQuery__
 *
 * To run a query within a React component, call `useIsMonthlyTrxInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsMonthlyTrxInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsMonthlyTrxInUseQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *   },
 * });
 */
export function useIsMonthlyTrxInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsMonthlyTrxInUseQuery, IsMonthlyTrxInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<IsMonthlyTrxInUseQuery, IsMonthlyTrxInUseQueryVariables>(IsMonthlyTrxInUseDocument, baseOptions);
      }
export function useIsMonthlyTrxInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsMonthlyTrxInUseQuery, IsMonthlyTrxInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsMonthlyTrxInUseQuery, IsMonthlyTrxInUseQueryVariables>(IsMonthlyTrxInUseDocument, baseOptions);
        }
export type IsMonthlyTrxInUseQueryHookResult = ReturnType<typeof useIsMonthlyTrxInUseQuery>;
export type IsMonthlyTrxInUseLazyQueryHookResult = ReturnType<typeof useIsMonthlyTrxInUseLazyQuery>;
export type IsMonthlyTrxInUseQueryResult = ApolloReactCommon.QueryResult<IsMonthlyTrxInUseQuery, IsMonthlyTrxInUseQueryVariables>;
export const MonthlyTransactionByEmployeeDocument = gql`
    query MonthlyTransactionByEmployee($MonthlyTransactionInput: MonthlyTransactionInput!, $offset: Float, $limit: Float) {
  MonthlyTransactionByEmployee(input: $MonthlyTransactionInput, offset: $offset, limit: $limit) {
    EmployeeID
    EmployeeNo
    EmployeeFullName
    TotalAmount
    TotalQuantity
    InUse
    PayItems {
      TransactionID
      PayItemID
      PayName
      Amount
      Quantity
      PayPeriod
      PayPeriodID
      PayrollCycle
      PayrollCycleID
      PayItemType
    }
  }
}
    `;

/**
 * __useMonthlyTransactionByEmployeeQuery__
 *
 * To run a query within a React component, call `useMonthlyTransactionByEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyTransactionByEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyTransactionByEmployeeQuery({
 *   variables: {
 *      MonthlyTransactionInput: // value for 'MonthlyTransactionInput'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMonthlyTransactionByEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthlyTransactionByEmployeeQuery, MonthlyTransactionByEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthlyTransactionByEmployeeQuery, MonthlyTransactionByEmployeeQueryVariables>(MonthlyTransactionByEmployeeDocument, baseOptions);
      }
export function useMonthlyTransactionByEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthlyTransactionByEmployeeQuery, MonthlyTransactionByEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthlyTransactionByEmployeeQuery, MonthlyTransactionByEmployeeQueryVariables>(MonthlyTransactionByEmployeeDocument, baseOptions);
        }
export type MonthlyTransactionByEmployeeQueryHookResult = ReturnType<typeof useMonthlyTransactionByEmployeeQuery>;
export type MonthlyTransactionByEmployeeLazyQueryHookResult = ReturnType<typeof useMonthlyTransactionByEmployeeLazyQuery>;
export type MonthlyTransactionByEmployeeQueryResult = ApolloReactCommon.QueryResult<MonthlyTransactionByEmployeeQuery, MonthlyTransactionByEmployeeQueryVariables>;
export const MonthlyTransactionByEmployeeCountDocument = gql`
    query MonthlyTransactionByEmployeeCount($MonthlyTransactionInput: MonthlyTransactionInput!) {
  MonthlyTransactionByEmployeeCount(input: $MonthlyTransactionInput)
}
    `;

/**
 * __useMonthlyTransactionByEmployeeCountQuery__
 *
 * To run a query within a React component, call `useMonthlyTransactionByEmployeeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyTransactionByEmployeeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyTransactionByEmployeeCountQuery({
 *   variables: {
 *      MonthlyTransactionInput: // value for 'MonthlyTransactionInput'
 *   },
 * });
 */
export function useMonthlyTransactionByEmployeeCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthlyTransactionByEmployeeCountQuery, MonthlyTransactionByEmployeeCountQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthlyTransactionByEmployeeCountQuery, MonthlyTransactionByEmployeeCountQueryVariables>(MonthlyTransactionByEmployeeCountDocument, baseOptions);
      }
export function useMonthlyTransactionByEmployeeCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthlyTransactionByEmployeeCountQuery, MonthlyTransactionByEmployeeCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthlyTransactionByEmployeeCountQuery, MonthlyTransactionByEmployeeCountQueryVariables>(MonthlyTransactionByEmployeeCountDocument, baseOptions);
        }
export type MonthlyTransactionByEmployeeCountQueryHookResult = ReturnType<typeof useMonthlyTransactionByEmployeeCountQuery>;
export type MonthlyTransactionByEmployeeCountLazyQueryHookResult = ReturnType<typeof useMonthlyTransactionByEmployeeCountLazyQuery>;
export type MonthlyTransactionByEmployeeCountQueryResult = ApolloReactCommon.QueryResult<MonthlyTransactionByEmployeeCountQuery, MonthlyTransactionByEmployeeCountQueryVariables>;
export const FreezepayrollListingDocument = gql`
    query FreezepayrollListing($CompanyID: String!, $DepartmentIDs: [String!]!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  FreezepayrollListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID) {
    FreezePayrollID
    Employees {
      Contact {
        FullName
      }
      EmployeeID
      EmployeeNo
      EmploymentType
      Designation {
        Name
      }
      OrgUnit {
        Description
        Level
      }
      Division {
        Description
      }
      Department {
        Description
        DepartmentType
        ParentID
        DepartmentID
      }
      EmployeeDepartment {
        Description
        DepartmentType
        ParentID
        DepartmentID
      }
      Division {
        Description
      }
      Documents {
        DocumentFile
      }
    }
  }
}
    `;

/**
 * __useFreezepayrollListingQuery__
 *
 * To run a query within a React component, call `useFreezepayrollListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFreezepayrollListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFreezepayrollListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useFreezepayrollListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FreezepayrollListingQuery, FreezepayrollListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FreezepayrollListingQuery, FreezepayrollListingQueryVariables>(FreezepayrollListingDocument, baseOptions);
      }
export function useFreezepayrollListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FreezepayrollListingQuery, FreezepayrollListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FreezepayrollListingQuery, FreezepayrollListingQueryVariables>(FreezepayrollListingDocument, baseOptions);
        }
export type FreezepayrollListingQueryHookResult = ReturnType<typeof useFreezepayrollListingQuery>;
export type FreezepayrollListingLazyQueryHookResult = ReturnType<typeof useFreezepayrollListingLazyQuery>;
export type FreezepayrollListingQueryResult = ApolloReactCommon.QueryResult<FreezepayrollListingQuery, FreezepayrollListingQueryVariables>;
export const Cp159ListingDocument = gql`
    query CP159Listing($CompanyID: String!, $PeriodYear: Float!) {
  CP159Listing(CompanyID: $CompanyID, PeriodYear: $PeriodYear) {
    CP159ID
    CompanyID
    PeriodYearMonth
    ReceiptNoPCB
    ReceiptDatePCB
    ReceiptNoCP38
    ReceiptDateCP38
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useCp159ListingQuery__
 *
 * To run a query within a React component, call `useCp159ListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCp159ListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCp159ListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PeriodYear: // value for 'PeriodYear'
 *   },
 * });
 */
export function useCp159ListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Cp159ListingQuery, Cp159ListingQueryVariables>) {
        return ApolloReactHooks.useQuery<Cp159ListingQuery, Cp159ListingQueryVariables>(Cp159ListingDocument, baseOptions);
      }
export function useCp159ListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Cp159ListingQuery, Cp159ListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Cp159ListingQuery, Cp159ListingQueryVariables>(Cp159ListingDocument, baseOptions);
        }
export type Cp159ListingQueryHookResult = ReturnType<typeof useCp159ListingQuery>;
export type Cp159ListingLazyQueryHookResult = ReturnType<typeof useCp159ListingLazyQuery>;
export type Cp159ListingQueryResult = ApolloReactCommon.QueryResult<Cp159ListingQuery, Cp159ListingQueryVariables>;
export const EmployeeOutstandingSalaryListingDocument = gql`
    query EmployeeOutstandingSalaryListing($CompanyID: String!, $SubscriptionAccountID: String!) {
  EmployeeOutstandingSalaryListing(CompanyID: $CompanyID, SubscriptionAccountID: $SubscriptionAccountID) {
    EmployeeID
    EmployeeNo
    JoinedDate
    EmployeeBenefitInKind {
      EmployeeID
      EmployeeBenefitInKindID
      PaymentDetails
      IncomeAmount
      BIKDescription
      RefundUnapprovedFunds
      Status
    }
    EmployeeSalaryLogs {
      CareerLogID
      EffectiveDate
      FromSalary
      ToSalary
    }
    EmployeeCareerLogs {
      CareerLogID
      EffectiveDate
      SalaryType
      FromSalary
      ToSalary
    }
    Contact {
      FullName
    }
    FormStatus
    Status
  }
}
    `;

/**
 * __useEmployeeOutstandingSalaryListingQuery__
 *
 * To run a query within a React component, call `useEmployeeOutstandingSalaryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeOutstandingSalaryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeOutstandingSalaryListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useEmployeeOutstandingSalaryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeOutstandingSalaryListingQuery, EmployeeOutstandingSalaryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeOutstandingSalaryListingQuery, EmployeeOutstandingSalaryListingQueryVariables>(EmployeeOutstandingSalaryListingDocument, baseOptions);
      }
export function useEmployeeOutstandingSalaryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeOutstandingSalaryListingQuery, EmployeeOutstandingSalaryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeOutstandingSalaryListingQuery, EmployeeOutstandingSalaryListingQueryVariables>(EmployeeOutstandingSalaryListingDocument, baseOptions);
        }
export type EmployeeOutstandingSalaryListingQueryHookResult = ReturnType<typeof useEmployeeOutstandingSalaryListingQuery>;
export type EmployeeOutstandingSalaryListingLazyQueryHookResult = ReturnType<typeof useEmployeeOutstandingSalaryListingLazyQuery>;
export type EmployeeOutstandingSalaryListingQueryResult = ApolloReactCommon.QueryResult<EmployeeOutstandingSalaryListingQuery, EmployeeOutstandingSalaryListingQueryVariables>;
export const Cp22CountDocument = gql`
    query CP22Count($CompanyID: String!, $ID: [String!]!) {
  CP22Count(CompanyID: $CompanyID, ID: $ID) {
    Status
    ModifiedBy
    ModifiedDT
    FormStatus
    CreatedDT
    CreatedBy
    UserLogs {
      createdDT
      createdBy
      modifiedBy
      modifiedDT
    }
    EmployeeCP22ID
    EmployeeID
    Employee {
      EmployeeID
      Contact {
        FullName
      }
    }
  }
}
    `;

/**
 * __useCp22CountQuery__
 *
 * To run a query within a React component, call `useCp22CountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCp22CountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCp22CountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCp22CountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Cp22CountQuery, Cp22CountQueryVariables>) {
        return ApolloReactHooks.useQuery<Cp22CountQuery, Cp22CountQueryVariables>(Cp22CountDocument, baseOptions);
      }
export function useCp22CountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Cp22CountQuery, Cp22CountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Cp22CountQuery, Cp22CountQueryVariables>(Cp22CountDocument, baseOptions);
        }
export type Cp22CountQueryHookResult = ReturnType<typeof useCp22CountQuery>;
export type Cp22CountLazyQueryHookResult = ReturnType<typeof useCp22CountLazyQuery>;
export type Cp22CountQueryResult = ApolloReactCommon.QueryResult<Cp22CountQuery, Cp22CountQueryVariables>;
export const UpdatePayrollCalendarDocument = gql`
    mutation UpdatePayrollCalendar($companyID: String!, $PayPeriodID: String!, $PayCycleIDs: [String!]!) {
  UpdatePayrollCalendar(companyID: $companyID, PayPeriodID: $PayPeriodID, PayCycleIDs: $PayCycleIDs)
}
    `;
export type UpdatePayrollCalendarMutationFn = ApolloReactCommon.MutationFunction<UpdatePayrollCalendarMutation, UpdatePayrollCalendarMutationVariables>;

/**
 * __useUpdatePayrollCalendarMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollCalendarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollCalendarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollCalendarMutation, { data, loading, error }] = useUpdatePayrollCalendarMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayCycleIDs: // value for 'PayCycleIDs'
 *   },
 * });
 */
export function useUpdatePayrollCalendarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePayrollCalendarMutation, UpdatePayrollCalendarMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePayrollCalendarMutation, UpdatePayrollCalendarMutationVariables>(UpdatePayrollCalendarDocument, baseOptions);
      }
export type UpdatePayrollCalendarMutationHookResult = ReturnType<typeof useUpdatePayrollCalendarMutation>;
export type UpdatePayrollCalendarMutationResult = ApolloReactCommon.MutationResult<UpdatePayrollCalendarMutation>;
export type UpdatePayrollCalendarMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePayrollCalendarMutation, UpdatePayrollCalendarMutationVariables>;
export const GenerateDefaultOrderforAllPayPeriodsDocument = gql`
    mutation GenerateDefaultOrderforAllPayPeriods($companyID: String!, $PayPeriodIDs: [String!]!) {
  GenerateDefaultOrderforAllPayPeriods(companyID: $companyID, PayPeriodIDs: $PayPeriodIDs)
}
    `;
export type GenerateDefaultOrderforAllPayPeriodsMutationFn = ApolloReactCommon.MutationFunction<GenerateDefaultOrderforAllPayPeriodsMutation, GenerateDefaultOrderforAllPayPeriodsMutationVariables>;

/**
 * __useGenerateDefaultOrderforAllPayPeriodsMutation__
 *
 * To run a mutation, you first call `useGenerateDefaultOrderforAllPayPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDefaultOrderforAllPayPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDefaultOrderforAllPayPeriodsMutation, { data, loading, error }] = useGenerateDefaultOrderforAllPayPeriodsMutation({
 *   variables: {
 *      companyID: // value for 'companyID'
 *      PayPeriodIDs: // value for 'PayPeriodIDs'
 *   },
 * });
 */
export function useGenerateDefaultOrderforAllPayPeriodsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateDefaultOrderforAllPayPeriodsMutation, GenerateDefaultOrderforAllPayPeriodsMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateDefaultOrderforAllPayPeriodsMutation, GenerateDefaultOrderforAllPayPeriodsMutationVariables>(GenerateDefaultOrderforAllPayPeriodsDocument, baseOptions);
      }
export type GenerateDefaultOrderforAllPayPeriodsMutationHookResult = ReturnType<typeof useGenerateDefaultOrderforAllPayPeriodsMutation>;
export type GenerateDefaultOrderforAllPayPeriodsMutationResult = ApolloReactCommon.MutationResult<GenerateDefaultOrderforAllPayPeriodsMutation>;
export type GenerateDefaultOrderforAllPayPeriodsMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateDefaultOrderforAllPayPeriodsMutation, GenerateDefaultOrderforAllPayPeriodsMutationVariables>;
export const EmployeeSalaryDeleteDocument = gql`
    mutation EmployeeSalaryDelete($CareerLogID: String!) {
  EmployeeSalaryDelete(CareerLogID: $CareerLogID)
}
    `;
export type EmployeeSalaryDeleteMutationFn = ApolloReactCommon.MutationFunction<EmployeeSalaryDeleteMutation, EmployeeSalaryDeleteMutationVariables>;

/**
 * __useEmployeeSalaryDeleteMutation__
 *
 * To run a mutation, you first call `useEmployeeSalaryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSalaryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeSalaryDeleteMutation, { data, loading, error }] = useEmployeeSalaryDeleteMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *   },
 * });
 */
export function useEmployeeSalaryDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeSalaryDeleteMutation, EmployeeSalaryDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeSalaryDeleteMutation, EmployeeSalaryDeleteMutationVariables>(EmployeeSalaryDeleteDocument, baseOptions);
      }
export type EmployeeSalaryDeleteMutationHookResult = ReturnType<typeof useEmployeeSalaryDeleteMutation>;
export type EmployeeSalaryDeleteMutationResult = ApolloReactCommon.MutationResult<EmployeeSalaryDeleteMutation>;
export type EmployeeSalaryDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeSalaryDeleteMutation, EmployeeSalaryDeleteMutationVariables>;
export const EmployeeSalaryInsertDocument = gql`
    mutation EmployeeSalaryInsert($Input: CareerLogInput!) {
  EmployeeSalaryInsert(Input: $Input)
}
    `;
export type EmployeeSalaryInsertMutationFn = ApolloReactCommon.MutationFunction<EmployeeSalaryInsertMutation, EmployeeSalaryInsertMutationVariables>;

/**
 * __useEmployeeSalaryInsertMutation__
 *
 * To run a mutation, you first call `useEmployeeSalaryInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSalaryInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeSalaryInsertMutation, { data, loading, error }] = useEmployeeSalaryInsertMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useEmployeeSalaryInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeSalaryInsertMutation, EmployeeSalaryInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeSalaryInsertMutation, EmployeeSalaryInsertMutationVariables>(EmployeeSalaryInsertDocument, baseOptions);
      }
export type EmployeeSalaryInsertMutationHookResult = ReturnType<typeof useEmployeeSalaryInsertMutation>;
export type EmployeeSalaryInsertMutationResult = ApolloReactCommon.MutationResult<EmployeeSalaryInsertMutation>;
export type EmployeeSalaryInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeSalaryInsertMutation, EmployeeSalaryInsertMutationVariables>;
export const EmployeeSalaryUpdateDocument = gql`
    mutation EmployeeSalaryUpdate($CareerLogID: String!, $Input: CareerLogInput!) {
  EmployeeSalaryUpdate(CareerLogID: $CareerLogID, Input: $Input)
}
    `;
export type EmployeeSalaryUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeSalaryUpdateMutation, EmployeeSalaryUpdateMutationVariables>;

/**
 * __useEmployeeSalaryUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeSalaryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSalaryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeSalaryUpdateMutation, { data, loading, error }] = useEmployeeSalaryUpdateMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useEmployeeSalaryUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeSalaryUpdateMutation, EmployeeSalaryUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeSalaryUpdateMutation, EmployeeSalaryUpdateMutationVariables>(EmployeeSalaryUpdateDocument, baseOptions);
      }
export type EmployeeSalaryUpdateMutationHookResult = ReturnType<typeof useEmployeeSalaryUpdateMutation>;
export type EmployeeSalaryUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeSalaryUpdateMutation>;
export type EmployeeSalaryUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeSalaryUpdateMutation, EmployeeSalaryUpdateMutationVariables>;
export const EmployeeCp22ListingDocument = gql`
    query EmployeeCP22Listing($EmployeeID: String!) {
  EmployeeCP22Listing(EmployeeID: $EmployeeID) {
    EmployeeCP22ID
    EmployeeID
    LHDNReportID
    Description
    ReportSequence
    Amount
    Remark
  }
}
    `;

/**
 * __useEmployeeCp22ListingQuery__
 *
 * To run a query within a React component, call `useEmployeeCp22ListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeCp22ListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeCp22ListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeCp22ListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeCp22ListingQuery, EmployeeCp22ListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeCp22ListingQuery, EmployeeCp22ListingQueryVariables>(EmployeeCp22ListingDocument, baseOptions);
      }
export function useEmployeeCp22ListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeCp22ListingQuery, EmployeeCp22ListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeCp22ListingQuery, EmployeeCp22ListingQueryVariables>(EmployeeCp22ListingDocument, baseOptions);
        }
export type EmployeeCp22ListingQueryHookResult = ReturnType<typeof useEmployeeCp22ListingQuery>;
export type EmployeeCp22ListingLazyQueryHookResult = ReturnType<typeof useEmployeeCp22ListingLazyQuery>;
export type EmployeeCp22ListingQueryResult = ApolloReactCommon.QueryResult<EmployeeCp22ListingQuery, EmployeeCp22ListingQueryVariables>;
export const EmployeeCp22UpdateDocument = gql`
    mutation EmployeeCP22Update($Input: [EmployeeCP22Input!]!) {
  EmployeeCP22Update(Input: $Input)
}
    `;
export type EmployeeCp22UpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeCp22UpdateMutation, EmployeeCp22UpdateMutationVariables>;

/**
 * __useEmployeeCp22UpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeCp22UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeCp22UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeCp22UpdateMutation, { data, loading, error }] = useEmployeeCp22UpdateMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useEmployeeCp22UpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeCp22UpdateMutation, EmployeeCp22UpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeCp22UpdateMutation, EmployeeCp22UpdateMutationVariables>(EmployeeCp22UpdateDocument, baseOptions);
      }
export type EmployeeCp22UpdateMutationHookResult = ReturnType<typeof useEmployeeCp22UpdateMutation>;
export type EmployeeCp22UpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeCp22UpdateMutation>;
export type EmployeeCp22UpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeCp22UpdateMutation, EmployeeCp22UpdateMutationVariables>;
export const EmployeeBenefitInKindListingDocument = gql`
    query EmployeeBenefitInKindListing($EmployeeID: String!) {
  EmployeeBenefitInKindListing(EmployeeID: $EmployeeID) {
    EmployeeBenefitInKindID
    EmployeeID
    PeriodYear
    PaymentDetails
    GratuityFromDate
    GratuityToDate
    IncomeTypeA
    IncomeTypeB
    IncomeAmount
    BIKDescription
    MotorcarDate
    MotorcarYear
    MotorcarType
    MotorcarModel
    MotorcarPetrol
    MotorcarDriverProviderAmount
    HouseholdAmount
    TelephoneAmount
    RecreationalAmount
    GardenerAmount
    HouseholdServantAmount
    ValueOfLivingAddresss
    ValueOfLivingAmount
    RefundUnapprovedFunds
    CompensationEmployment
    Status
    PaymentAmount
    GratuityAmount
    FeeDescription
    FeeAmount
    ESOSDescription
    ESOSAmount
    TaxDescription
    TaxAmount
  }
}
    `;

/**
 * __useEmployeeBenefitInKindListingQuery__
 *
 * To run a query within a React component, call `useEmployeeBenefitInKindListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeBenefitInKindListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeBenefitInKindListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeBenefitInKindListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeBenefitInKindListingQuery, EmployeeBenefitInKindListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeBenefitInKindListingQuery, EmployeeBenefitInKindListingQueryVariables>(EmployeeBenefitInKindListingDocument, baseOptions);
      }
export function useEmployeeBenefitInKindListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeBenefitInKindListingQuery, EmployeeBenefitInKindListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeBenefitInKindListingQuery, EmployeeBenefitInKindListingQueryVariables>(EmployeeBenefitInKindListingDocument, baseOptions);
        }
export type EmployeeBenefitInKindListingQueryHookResult = ReturnType<typeof useEmployeeBenefitInKindListingQuery>;
export type EmployeeBenefitInKindListingLazyQueryHookResult = ReturnType<typeof useEmployeeBenefitInKindListingLazyQuery>;
export type EmployeeBenefitInKindListingQueryResult = ApolloReactCommon.QueryResult<EmployeeBenefitInKindListingQuery, EmployeeBenefitInKindListingQueryVariables>;
export const EmployeeBenefitInKindInsertDocument = gql`
    mutation EmployeeBenefitInKindInsert($EmployeeBenefitInKindInput: EmployeeBenefitInKindInput!) {
  EmployeeBenefitInKindInsert(EmployeeBenefitInKindInput: $EmployeeBenefitInKindInput) {
    EmployeeBenefitInKindID
  }
}
    `;
export type EmployeeBenefitInKindInsertMutationFn = ApolloReactCommon.MutationFunction<EmployeeBenefitInKindInsertMutation, EmployeeBenefitInKindInsertMutationVariables>;

/**
 * __useEmployeeBenefitInKindInsertMutation__
 *
 * To run a mutation, you first call `useEmployeeBenefitInKindInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeBenefitInKindInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeBenefitInKindInsertMutation, { data, loading, error }] = useEmployeeBenefitInKindInsertMutation({
 *   variables: {
 *      EmployeeBenefitInKindInput: // value for 'EmployeeBenefitInKindInput'
 *   },
 * });
 */
export function useEmployeeBenefitInKindInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeBenefitInKindInsertMutation, EmployeeBenefitInKindInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeBenefitInKindInsertMutation, EmployeeBenefitInKindInsertMutationVariables>(EmployeeBenefitInKindInsertDocument, baseOptions);
      }
export type EmployeeBenefitInKindInsertMutationHookResult = ReturnType<typeof useEmployeeBenefitInKindInsertMutation>;
export type EmployeeBenefitInKindInsertMutationResult = ApolloReactCommon.MutationResult<EmployeeBenefitInKindInsertMutation>;
export type EmployeeBenefitInKindInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeBenefitInKindInsertMutation, EmployeeBenefitInKindInsertMutationVariables>;
export const EmployeeBenefitInKindUpdateDocument = gql`
    mutation EmployeeBenefitInKindUpdate($EmployeeBenefitInKindID: String!, $EmployeeBenefitInKindInput: EmployeeBenefitInKindInput!) {
  EmployeeBenefitInKindUpdate(EmployeeBenefitInKindID: $EmployeeBenefitInKindID, EmployeeBenefitInKindInput: $EmployeeBenefitInKindInput)
}
    `;
export type EmployeeBenefitInKindUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeBenefitInKindUpdateMutation, EmployeeBenefitInKindUpdateMutationVariables>;

/**
 * __useEmployeeBenefitInKindUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeBenefitInKindUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeBenefitInKindUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeBenefitInKindUpdateMutation, { data, loading, error }] = useEmployeeBenefitInKindUpdateMutation({
 *   variables: {
 *      EmployeeBenefitInKindID: // value for 'EmployeeBenefitInKindID'
 *      EmployeeBenefitInKindInput: // value for 'EmployeeBenefitInKindInput'
 *   },
 * });
 */
export function useEmployeeBenefitInKindUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeBenefitInKindUpdateMutation, EmployeeBenefitInKindUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeBenefitInKindUpdateMutation, EmployeeBenefitInKindUpdateMutationVariables>(EmployeeBenefitInKindUpdateDocument, baseOptions);
      }
export type EmployeeBenefitInKindUpdateMutationHookResult = ReturnType<typeof useEmployeeBenefitInKindUpdateMutation>;
export type EmployeeBenefitInKindUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeBenefitInKindUpdateMutation>;
export type EmployeeBenefitInKindUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeBenefitInKindUpdateMutation, EmployeeBenefitInKindUpdateMutationVariables>;
export const EmployeeTaxReliefListingDocument = gql`
    query EmployeeTaxReliefListing($EmployeeID: String!, $PeriodYear: Float!) {
  EmployeeTaxReliefListing(EmployeeID: $EmployeeID, PeriodYear: $PeriodYear) {
    EmployeeTaxReliefID
    TaxReliefID
    PayPeriodID
    ReliefAmount
    PayPeriod {
      PeriodYearMonth
    }
    TaxRelief {
      TaxReliefID
      ReliefCode
      Description
      MaxAmount
      PeriodYear
    }
    CreatedDT
    ModifiedDT
  }
}
    `;

/**
 * __useEmployeeTaxReliefListingQuery__
 *
 * To run a query within a React component, call `useEmployeeTaxReliefListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeTaxReliefListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeTaxReliefListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      PeriodYear: // value for 'PeriodYear'
 *   },
 * });
 */
export function useEmployeeTaxReliefListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeTaxReliefListingQuery, EmployeeTaxReliefListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeTaxReliefListingQuery, EmployeeTaxReliefListingQueryVariables>(EmployeeTaxReliefListingDocument, baseOptions);
      }
export function useEmployeeTaxReliefListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeTaxReliefListingQuery, EmployeeTaxReliefListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeTaxReliefListingQuery, EmployeeTaxReliefListingQueryVariables>(EmployeeTaxReliefListingDocument, baseOptions);
        }
export type EmployeeTaxReliefListingQueryHookResult = ReturnType<typeof useEmployeeTaxReliefListingQuery>;
export type EmployeeTaxReliefListingLazyQueryHookResult = ReturnType<typeof useEmployeeTaxReliefListingLazyQuery>;
export type EmployeeTaxReliefListingQueryResult = ApolloReactCommon.QueryResult<EmployeeTaxReliefListingQuery, EmployeeTaxReliefListingQueryVariables>;
export const TaxReliefListingDefaultExcludedDocument = gql`
    query TaxReliefListingDefaultExcluded($PeriodYear: Float!) {
  TaxReliefListingDefaultExcluded(PeriodYear: $PeriodYear) {
    TaxReliefID
    ReliefCode
    Description
    PeriodYear
    MaxAmount
  }
}
    `;

/**
 * __useTaxReliefListingDefaultExcludedQuery__
 *
 * To run a query within a React component, call `useTaxReliefListingDefaultExcludedQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxReliefListingDefaultExcludedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxReliefListingDefaultExcludedQuery({
 *   variables: {
 *      PeriodYear: // value for 'PeriodYear'
 *   },
 * });
 */
export function useTaxReliefListingDefaultExcludedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TaxReliefListingDefaultExcludedQuery, TaxReliefListingDefaultExcludedQueryVariables>) {
        return ApolloReactHooks.useQuery<TaxReliefListingDefaultExcludedQuery, TaxReliefListingDefaultExcludedQueryVariables>(TaxReliefListingDefaultExcludedDocument, baseOptions);
      }
export function useTaxReliefListingDefaultExcludedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaxReliefListingDefaultExcludedQuery, TaxReliefListingDefaultExcludedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TaxReliefListingDefaultExcludedQuery, TaxReliefListingDefaultExcludedQueryVariables>(TaxReliefListingDefaultExcludedDocument, baseOptions);
        }
export type TaxReliefListingDefaultExcludedQueryHookResult = ReturnType<typeof useTaxReliefListingDefaultExcludedQuery>;
export type TaxReliefListingDefaultExcludedLazyQueryHookResult = ReturnType<typeof useTaxReliefListingDefaultExcludedLazyQuery>;
export type TaxReliefListingDefaultExcludedQueryResult = ApolloReactCommon.QueryResult<TaxReliefListingDefaultExcludedQuery, TaxReliefListingDefaultExcludedQueryVariables>;
export const StartPayPeriodListingByYearDocument = gql`
    query StartPayPeriodListingByYear($Year: String!, $CompanyID: String!) {
  StartPayPeriodListingByYear(Year: $Year, CompanyID: $CompanyID) {
    PayPeriodID
    PeriodYearMonth
  }
}
    `;

/**
 * __useStartPayPeriodListingByYearQuery__
 *
 * To run a query within a React component, call `useStartPayPeriodListingByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartPayPeriodListingByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartPayPeriodListingByYearQuery({
 *   variables: {
 *      Year: // value for 'Year'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useStartPayPeriodListingByYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StartPayPeriodListingByYearQuery, StartPayPeriodListingByYearQueryVariables>) {
        return ApolloReactHooks.useQuery<StartPayPeriodListingByYearQuery, StartPayPeriodListingByYearQueryVariables>(StartPayPeriodListingByYearDocument, baseOptions);
      }
export function useStartPayPeriodListingByYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StartPayPeriodListingByYearQuery, StartPayPeriodListingByYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StartPayPeriodListingByYearQuery, StartPayPeriodListingByYearQueryVariables>(StartPayPeriodListingByYearDocument, baseOptions);
        }
export type StartPayPeriodListingByYearQueryHookResult = ReturnType<typeof useStartPayPeriodListingByYearQuery>;
export type StartPayPeriodListingByYearLazyQueryHookResult = ReturnType<typeof useStartPayPeriodListingByYearLazyQuery>;
export type StartPayPeriodListingByYearQueryResult = ApolloReactCommon.QueryResult<StartPayPeriodListingByYearQuery, StartPayPeriodListingByYearQueryVariables>;
export const EmployeeTaxReliefBulkInsertDocument = gql`
    mutation EmployeeTaxReliefBulkInsert($EmployeeTaxReliefInput: [EmployeeTaxReliefInput!]!) {
  EmployeeTaxReliefBulkInsert(inputs: $EmployeeTaxReliefInput)
}
    `;
export type EmployeeTaxReliefBulkInsertMutationFn = ApolloReactCommon.MutationFunction<EmployeeTaxReliefBulkInsertMutation, EmployeeTaxReliefBulkInsertMutationVariables>;

/**
 * __useEmployeeTaxReliefBulkInsertMutation__
 *
 * To run a mutation, you first call `useEmployeeTaxReliefBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeTaxReliefBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeTaxReliefBulkInsertMutation, { data, loading, error }] = useEmployeeTaxReliefBulkInsertMutation({
 *   variables: {
 *      EmployeeTaxReliefInput: // value for 'EmployeeTaxReliefInput'
 *   },
 * });
 */
export function useEmployeeTaxReliefBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeTaxReliefBulkInsertMutation, EmployeeTaxReliefBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeTaxReliefBulkInsertMutation, EmployeeTaxReliefBulkInsertMutationVariables>(EmployeeTaxReliefBulkInsertDocument, baseOptions);
      }
export type EmployeeTaxReliefBulkInsertMutationHookResult = ReturnType<typeof useEmployeeTaxReliefBulkInsertMutation>;
export type EmployeeTaxReliefBulkInsertMutationResult = ApolloReactCommon.MutationResult<EmployeeTaxReliefBulkInsertMutation>;
export type EmployeeTaxReliefBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeTaxReliefBulkInsertMutation, EmployeeTaxReliefBulkInsertMutationVariables>;
export const EmployeeTaxReliefBulkUpdateDocument = gql`
    mutation EmployeeTaxReliefBulkUpdate($EmployeeTaxReliefInput: [EmployeeTaxReliefInput!]!) {
  EmployeeTaxReliefBulkUpdate(inputs: $EmployeeTaxReliefInput)
}
    `;
export type EmployeeTaxReliefBulkUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeTaxReliefBulkUpdateMutation, EmployeeTaxReliefBulkUpdateMutationVariables>;

/**
 * __useEmployeeTaxReliefBulkUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeTaxReliefBulkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeTaxReliefBulkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeTaxReliefBulkUpdateMutation, { data, loading, error }] = useEmployeeTaxReliefBulkUpdateMutation({
 *   variables: {
 *      EmployeeTaxReliefInput: // value for 'EmployeeTaxReliefInput'
 *   },
 * });
 */
export function useEmployeeTaxReliefBulkUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeTaxReliefBulkUpdateMutation, EmployeeTaxReliefBulkUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeTaxReliefBulkUpdateMutation, EmployeeTaxReliefBulkUpdateMutationVariables>(EmployeeTaxReliefBulkUpdateDocument, baseOptions);
      }
export type EmployeeTaxReliefBulkUpdateMutationHookResult = ReturnType<typeof useEmployeeTaxReliefBulkUpdateMutation>;
export type EmployeeTaxReliefBulkUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeTaxReliefBulkUpdateMutation>;
export type EmployeeTaxReliefBulkUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeTaxReliefBulkUpdateMutation, EmployeeTaxReliefBulkUpdateMutationVariables>;
export const RecurringPayDeleteDocument = gql`
    mutation RecurringPayDelete($RecurringPayID: String!) {
  RecurringPayDelete(RecurringPayID: $RecurringPayID)
}
    `;
export type RecurringPayDeleteMutationFn = ApolloReactCommon.MutationFunction<RecurringPayDeleteMutation, RecurringPayDeleteMutationVariables>;

/**
 * __useRecurringPayDeleteMutation__
 *
 * To run a mutation, you first call `useRecurringPayDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecurringPayDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recurringPayDeleteMutation, { data, loading, error }] = useRecurringPayDeleteMutation({
 *   variables: {
 *      RecurringPayID: // value for 'RecurringPayID'
 *   },
 * });
 */
export function useRecurringPayDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecurringPayDeleteMutation, RecurringPayDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RecurringPayDeleteMutation, RecurringPayDeleteMutationVariables>(RecurringPayDeleteDocument, baseOptions);
      }
export type RecurringPayDeleteMutationHookResult = ReturnType<typeof useRecurringPayDeleteMutation>;
export type RecurringPayDeleteMutationResult = ApolloReactCommon.MutationResult<RecurringPayDeleteMutation>;
export type RecurringPayDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RecurringPayDeleteMutation, RecurringPayDeleteMutationVariables>;
export const RecurringPayInsertDocument = gql`
    mutation RecurringPayInsert($RecurringPayInput: [RecurringPayInput!]!, $DocumentsInput: DocumentsInput, $ExcelUpload: Boolean!) {
  RecurringPayInsert(RecurringPayInput: $RecurringPayInput, DocumentsInput: $DocumentsInput, ExcelUpload: $ExcelUpload)
}
    `;
export type RecurringPayInsertMutationFn = ApolloReactCommon.MutationFunction<RecurringPayInsertMutation, RecurringPayInsertMutationVariables>;

/**
 * __useRecurringPayInsertMutation__
 *
 * To run a mutation, you first call `useRecurringPayInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecurringPayInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recurringPayInsertMutation, { data, loading, error }] = useRecurringPayInsertMutation({
 *   variables: {
 *      RecurringPayInput: // value for 'RecurringPayInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      ExcelUpload: // value for 'ExcelUpload'
 *   },
 * });
 */
export function useRecurringPayInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecurringPayInsertMutation, RecurringPayInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RecurringPayInsertMutation, RecurringPayInsertMutationVariables>(RecurringPayInsertDocument, baseOptions);
      }
export type RecurringPayInsertMutationHookResult = ReturnType<typeof useRecurringPayInsertMutation>;
export type RecurringPayInsertMutationResult = ApolloReactCommon.MutationResult<RecurringPayInsertMutation>;
export type RecurringPayInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RecurringPayInsertMutation, RecurringPayInsertMutationVariables>;
export const RecurringPayUpdateDocument = gql`
    mutation RecurringPayUpdate($RecurringPayID: String!, $RecurringPayInput: RecurringPayInput!) {
  RecurringPayUpdate(RecurringPayID: $RecurringPayID, input: $RecurringPayInput)
}
    `;
export type RecurringPayUpdateMutationFn = ApolloReactCommon.MutationFunction<RecurringPayUpdateMutation, RecurringPayUpdateMutationVariables>;

/**
 * __useRecurringPayUpdateMutation__
 *
 * To run a mutation, you first call `useRecurringPayUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecurringPayUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recurringPayUpdateMutation, { data, loading, error }] = useRecurringPayUpdateMutation({
 *   variables: {
 *      RecurringPayID: // value for 'RecurringPayID'
 *      RecurringPayInput: // value for 'RecurringPayInput'
 *   },
 * });
 */
export function useRecurringPayUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecurringPayUpdateMutation, RecurringPayUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RecurringPayUpdateMutation, RecurringPayUpdateMutationVariables>(RecurringPayUpdateDocument, baseOptions);
      }
export type RecurringPayUpdateMutationHookResult = ReturnType<typeof useRecurringPayUpdateMutation>;
export type RecurringPayUpdateMutationResult = ApolloReactCommon.MutationResult<RecurringPayUpdateMutation>;
export type RecurringPayUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RecurringPayUpdateMutation, RecurringPayUpdateMutationVariables>;
export const GetPayPeriodByYearDocument = gql`
    query GetPayPeriodByYear($CompanyID: String!, $PeriodYear: Float!) {
  PayPeriodListing(CompanyID: $CompanyID, PeriodYear: $PeriodYear) {
    PayPeriodID
    CompanyID
    PeriodYearMonth
    FromDate
    ToDate
    ActualWorkDays
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useGetPayPeriodByYearQuery__
 *
 * To run a query within a React component, call `useGetPayPeriodByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayPeriodByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayPeriodByYearQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PeriodYear: // value for 'PeriodYear'
 *   },
 * });
 */
export function useGetPayPeriodByYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPayPeriodByYearQuery, GetPayPeriodByYearQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPayPeriodByYearQuery, GetPayPeriodByYearQueryVariables>(GetPayPeriodByYearDocument, baseOptions);
      }
export function useGetPayPeriodByYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPayPeriodByYearQuery, GetPayPeriodByYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPayPeriodByYearQuery, GetPayPeriodByYearQueryVariables>(GetPayPeriodByYearDocument, baseOptions);
        }
export type GetPayPeriodByYearQueryHookResult = ReturnType<typeof useGetPayPeriodByYearQuery>;
export type GetPayPeriodByYearLazyQueryHookResult = ReturnType<typeof useGetPayPeriodByYearLazyQuery>;
export type GetPayPeriodByYearQueryResult = ApolloReactCommon.QueryResult<GetPayPeriodByYearQuery, GetPayPeriodByYearQueryVariables>;
export const PayrollFixedAllowanceDetailsDocument = gql`
    query PayrollFixedAllowanceDetails($RecurringPayID: String!) {
  PayrollFixedAllowanceDetails(RecurringPayID: $RecurringPayID) {
    PayrollCycleID
    RecurringPayID
    EmployeeID
    Remark
    Amount
    Quantity
    PayItemID
    PayItem {
      PayName
      PayItemType
    }
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    StartPeriod {
      PeriodYearMonth
      PayPeriodID
    }
    EndPeriod {
      PeriodYearMonth
    }
  }
}
    `;

/**
 * __usePayrollFixedAllowanceDetailsQuery__
 *
 * To run a query within a React component, call `usePayrollFixedAllowanceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollFixedAllowanceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollFixedAllowanceDetailsQuery({
 *   variables: {
 *      RecurringPayID: // value for 'RecurringPayID'
 *   },
 * });
 */
export function usePayrollFixedAllowanceDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollFixedAllowanceDetailsQuery, PayrollFixedAllowanceDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollFixedAllowanceDetailsQuery, PayrollFixedAllowanceDetailsQueryVariables>(PayrollFixedAllowanceDetailsDocument, baseOptions);
      }
export function usePayrollFixedAllowanceDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollFixedAllowanceDetailsQuery, PayrollFixedAllowanceDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollFixedAllowanceDetailsQuery, PayrollFixedAllowanceDetailsQueryVariables>(PayrollFixedAllowanceDetailsDocument, baseOptions);
        }
export type PayrollFixedAllowanceDetailsQueryHookResult = ReturnType<typeof usePayrollFixedAllowanceDetailsQuery>;
export type PayrollFixedAllowanceDetailsLazyQueryHookResult = ReturnType<typeof usePayrollFixedAllowanceDetailsLazyQuery>;
export type PayrollFixedAllowanceDetailsQueryResult = ApolloReactCommon.QueryResult<PayrollFixedAllowanceDetailsQuery, PayrollFixedAllowanceDetailsQueryVariables>;
export const PayItemListingByTlaDocument = gql`
    query PayItemListingByTLA($CompanyID: String!, $EmployeeID: String, $PayItemID: String) {
  PayItemListingByTLA(CompanyID: $CompanyID, EmployeeID: $EmployeeID, PayItemID: $PayItemID) {
    PayItemID
    PayName
    PayCode
    PayItemType
    EmployeeIds
  }
}
    `;

/**
 * __usePayItemListingByTlaQuery__
 *
 * To run a query within a React component, call `usePayItemListingByTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayItemListingByTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayItemListingByTlaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      PayItemID: // value for 'PayItemID'
 *   },
 * });
 */
export function usePayItemListingByTlaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayItemListingByTlaQuery, PayItemListingByTlaQueryVariables>) {
        return ApolloReactHooks.useQuery<PayItemListingByTlaQuery, PayItemListingByTlaQueryVariables>(PayItemListingByTlaDocument, baseOptions);
      }
export function usePayItemListingByTlaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayItemListingByTlaQuery, PayItemListingByTlaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayItemListingByTlaQuery, PayItemListingByTlaQueryVariables>(PayItemListingByTlaDocument, baseOptions);
        }
export type PayItemListingByTlaQueryHookResult = ReturnType<typeof usePayItemListingByTlaQuery>;
export type PayItemListingByTlaLazyQueryHookResult = ReturnType<typeof usePayItemListingByTlaLazyQuery>;
export type PayItemListingByTlaQueryResult = ApolloReactCommon.QueryResult<PayItemListingByTlaQuery, PayItemListingByTlaQueryVariables>;
export const PayrollFixedAllowanceByExcelDocument = gql`
    query PayrollFixedAllowanceByExcel($CompanyID: String!) {
  PayrollFixedAllowanceByExcel(CompanyID: $CompanyID) {
    ExcelFileName
    TotalEarning
    TotalNonPayable
    TotalDeduction
    UploadedDate
    DocumentID
  }
}
    `;

/**
 * __usePayrollFixedAllowanceByExcelQuery__
 *
 * To run a query within a React component, call `usePayrollFixedAllowanceByExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollFixedAllowanceByExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollFixedAllowanceByExcelQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePayrollFixedAllowanceByExcelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollFixedAllowanceByExcelQuery, PayrollFixedAllowanceByExcelQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollFixedAllowanceByExcelQuery, PayrollFixedAllowanceByExcelQueryVariables>(PayrollFixedAllowanceByExcelDocument, baseOptions);
      }
export function usePayrollFixedAllowanceByExcelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollFixedAllowanceByExcelQuery, PayrollFixedAllowanceByExcelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollFixedAllowanceByExcelQuery, PayrollFixedAllowanceByExcelQueryVariables>(PayrollFixedAllowanceByExcelDocument, baseOptions);
        }
export type PayrollFixedAllowanceByExcelQueryHookResult = ReturnType<typeof usePayrollFixedAllowanceByExcelQuery>;
export type PayrollFixedAllowanceByExcelLazyQueryHookResult = ReturnType<typeof usePayrollFixedAllowanceByExcelLazyQuery>;
export type PayrollFixedAllowanceByExcelQueryResult = ApolloReactCommon.QueryResult<PayrollFixedAllowanceByExcelQuery, PayrollFixedAllowanceByExcelQueryVariables>;
export const OneTimePayListingDocument = gql`
    query OneTimePayListing($CompanyID: String!) {
  OneTimePayListing(CompanyID: $CompanyID) {
    OneTimePayID
    CompanyID
    EmployeeID
    PayItemID
    PayPeriodID
    PayrollCycleID
    Amount
    Quantity
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    PayItem {
      PayItemID
      PayName
      PayCode
      PayItemType
    }
    PayPeriod {
      PayPeriodID
      PeriodYearMonth
      FromDate
      ToDate
      ActualWorkDays
    }
    PayrollCycle {
      PayrollCycleID
      Description
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useOneTimePayListingQuery__
 *
 * To run a query within a React component, call `useOneTimePayListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneTimePayListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneTimePayListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useOneTimePayListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OneTimePayListingQuery, OneTimePayListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OneTimePayListingQuery, OneTimePayListingQueryVariables>(OneTimePayListingDocument, baseOptions);
      }
export function useOneTimePayListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OneTimePayListingQuery, OneTimePayListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OneTimePayListingQuery, OneTimePayListingQueryVariables>(OneTimePayListingDocument, baseOptions);
        }
export type OneTimePayListingQueryHookResult = ReturnType<typeof useOneTimePayListingQuery>;
export type OneTimePayListingLazyQueryHookResult = ReturnType<typeof useOneTimePayListingLazyQuery>;
export type OneTimePayListingQueryResult = ApolloReactCommon.QueryResult<OneTimePayListingQuery, OneTimePayListingQueryVariables>;
export const OneTimePayListingByPayPeriodAndPayCycleDocument = gql`
    query OneTimePayListingByPayPeriodAndPayCycle($CompanyID: String!, $PayPeriodID: String!, $PayrollCycleID: String!) {
  OneTimePayListingByPayPeriodAndPayCycle(CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayrollCycleID: $PayrollCycleID) {
    OneTimePayID
    CompanyID
    EmployeeID
    PayItemID
    PayPeriodID
    PayrollCycleID
    Amount
    Quantity
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    PayItem {
      PayItemID
      PayName
      PayCode
      PayItemType
    }
    PayPeriod {
      PayPeriodID
      PeriodYearMonth
      FromDate
      ToDate
      ActualWorkDays
    }
    PayrollCycle {
      PayrollCycleID
      Description
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useOneTimePayListingByPayPeriodAndPayCycleQuery__
 *
 * To run a query within a React component, call `useOneTimePayListingByPayPeriodAndPayCycleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneTimePayListingByPayPeriodAndPayCycleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneTimePayListingByPayPeriodAndPayCycleQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *   },
 * });
 */
export function useOneTimePayListingByPayPeriodAndPayCycleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OneTimePayListingByPayPeriodAndPayCycleQuery, OneTimePayListingByPayPeriodAndPayCycleQueryVariables>) {
        return ApolloReactHooks.useQuery<OneTimePayListingByPayPeriodAndPayCycleQuery, OneTimePayListingByPayPeriodAndPayCycleQueryVariables>(OneTimePayListingByPayPeriodAndPayCycleDocument, baseOptions);
      }
export function useOneTimePayListingByPayPeriodAndPayCycleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OneTimePayListingByPayPeriodAndPayCycleQuery, OneTimePayListingByPayPeriodAndPayCycleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OneTimePayListingByPayPeriodAndPayCycleQuery, OneTimePayListingByPayPeriodAndPayCycleQueryVariables>(OneTimePayListingByPayPeriodAndPayCycleDocument, baseOptions);
        }
export type OneTimePayListingByPayPeriodAndPayCycleQueryHookResult = ReturnType<typeof useOneTimePayListingByPayPeriodAndPayCycleQuery>;
export type OneTimePayListingByPayPeriodAndPayCycleLazyQueryHookResult = ReturnType<typeof useOneTimePayListingByPayPeriodAndPayCycleLazyQuery>;
export type OneTimePayListingByPayPeriodAndPayCycleQueryResult = ApolloReactCommon.QueryResult<OneTimePayListingByPayPeriodAndPayCycleQuery, OneTimePayListingByPayPeriodAndPayCycleQueryVariables>;
export const DeleteMonthlyTrxDocument = gql`
    mutation DeleteMonthlyTrx($OneTimePayIDs: [String!]!) {
  DeleteMonthlyTrx(OneTimePayIDs: $OneTimePayIDs)
}
    `;
export type DeleteMonthlyTrxMutationFn = ApolloReactCommon.MutationFunction<DeleteMonthlyTrxMutation, DeleteMonthlyTrxMutationVariables>;

/**
 * __useDeleteMonthlyTrxMutation__
 *
 * To run a mutation, you first call `useDeleteMonthlyTrxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMonthlyTrxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMonthlyTrxMutation, { data, loading, error }] = useDeleteMonthlyTrxMutation({
 *   variables: {
 *      OneTimePayIDs: // value for 'OneTimePayIDs'
 *   },
 * });
 */
export function useDeleteMonthlyTrxMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMonthlyTrxMutation, DeleteMonthlyTrxMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMonthlyTrxMutation, DeleteMonthlyTrxMutationVariables>(DeleteMonthlyTrxDocument, baseOptions);
      }
export type DeleteMonthlyTrxMutationHookResult = ReturnType<typeof useDeleteMonthlyTrxMutation>;
export type DeleteMonthlyTrxMutationResult = ApolloReactCommon.MutationResult<DeleteMonthlyTrxMutation>;
export type DeleteMonthlyTrxMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMonthlyTrxMutation, DeleteMonthlyTrxMutationVariables>;
export const OneTimePayInsertDocument = gql`
    mutation OneTimePayInsert($OneTimePayInput: [OneTimePayInput!]!, $DocumentsInput: DocumentsInput) {
  OneTimePayInsert(OneTimePayInput: $OneTimePayInput, DocumentsInput: $DocumentsInput)
}
    `;
export type OneTimePayInsertMutationFn = ApolloReactCommon.MutationFunction<OneTimePayInsertMutation, OneTimePayInsertMutationVariables>;

/**
 * __useOneTimePayInsertMutation__
 *
 * To run a mutation, you first call `useOneTimePayInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOneTimePayInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oneTimePayInsertMutation, { data, loading, error }] = useOneTimePayInsertMutation({
 *   variables: {
 *      OneTimePayInput: // value for 'OneTimePayInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useOneTimePayInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OneTimePayInsertMutation, OneTimePayInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<OneTimePayInsertMutation, OneTimePayInsertMutationVariables>(OneTimePayInsertDocument, baseOptions);
      }
export type OneTimePayInsertMutationHookResult = ReturnType<typeof useOneTimePayInsertMutation>;
export type OneTimePayInsertMutationResult = ApolloReactCommon.MutationResult<OneTimePayInsertMutation>;
export type OneTimePayInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<OneTimePayInsertMutation, OneTimePayInsertMutationVariables>;
export const OneTimePayUpdateDocument = gql`
    mutation OneTimePayUpdate($OneTimePayInput: [OneTimePayInput!]!) {
  OneTimePayUpdate(OneTimePayInput: $OneTimePayInput)
}
    `;
export type OneTimePayUpdateMutationFn = ApolloReactCommon.MutationFunction<OneTimePayUpdateMutation, OneTimePayUpdateMutationVariables>;

/**
 * __useOneTimePayUpdateMutation__
 *
 * To run a mutation, you first call `useOneTimePayUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOneTimePayUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oneTimePayUpdateMutation, { data, loading, error }] = useOneTimePayUpdateMutation({
 *   variables: {
 *      OneTimePayInput: // value for 'OneTimePayInput'
 *   },
 * });
 */
export function useOneTimePayUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OneTimePayUpdateMutation, OneTimePayUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OneTimePayUpdateMutation, OneTimePayUpdateMutationVariables>(OneTimePayUpdateDocument, baseOptions);
      }
export type OneTimePayUpdateMutationHookResult = ReturnType<typeof useOneTimePayUpdateMutation>;
export type OneTimePayUpdateMutationResult = ApolloReactCommon.MutationResult<OneTimePayUpdateMutation>;
export type OneTimePayUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OneTimePayUpdateMutation, OneTimePayUpdateMutationVariables>;
export const MonthlyTransactionByPayItemDocument = gql`
    query MonthlyTransactionByPayItem($PayItemFilterInput: PayItemFilterInput!) {
  MonthlyTransactionByPayItem(input: $PayItemFilterInput) {
    PayName
    PayItemID
    PayrollCycle
    PayrollCycleID
    TotalAmount
    TotalQuantity
    CreatedDT
    CreatedBy
    ModifiedDT
    Employees {
      EmployeeID
      EmployeeNo
      EmployeeFullName
      CreatedDT
      InUse
      PayItems {
        TransactionID
        PayItemID
        PayName
        Amount
        Quantity
        PayPeriod
        PayPeriodID
        PayrollCycle
        PayrollCycleID
        PayItemType
      }
    }
  }
}
    `;

/**
 * __useMonthlyTransactionByPayItemQuery__
 *
 * To run a query within a React component, call `useMonthlyTransactionByPayItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyTransactionByPayItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyTransactionByPayItemQuery({
 *   variables: {
 *      PayItemFilterInput: // value for 'PayItemFilterInput'
 *   },
 * });
 */
export function useMonthlyTransactionByPayItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthlyTransactionByPayItemQuery, MonthlyTransactionByPayItemQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthlyTransactionByPayItemQuery, MonthlyTransactionByPayItemQueryVariables>(MonthlyTransactionByPayItemDocument, baseOptions);
      }
export function useMonthlyTransactionByPayItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthlyTransactionByPayItemQuery, MonthlyTransactionByPayItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthlyTransactionByPayItemQuery, MonthlyTransactionByPayItemQueryVariables>(MonthlyTransactionByPayItemDocument, baseOptions);
        }
export type MonthlyTransactionByPayItemQueryHookResult = ReturnType<typeof useMonthlyTransactionByPayItemQuery>;
export type MonthlyTransactionByPayItemLazyQueryHookResult = ReturnType<typeof useMonthlyTransactionByPayItemLazyQuery>;
export type MonthlyTransactionByPayItemQueryResult = ApolloReactCommon.QueryResult<MonthlyTransactionByPayItemQuery, MonthlyTransactionByPayItemQueryVariables>;
export const MonthlyTransactionByPayItemWithinPeriodDocument = gql`
    query MonthlyTransactionByPayItemWithinPeriod($PayItemFilterInput: PayItemFilterInput!) {
  MonthlyTransactionByPayItem(input: $PayItemFilterInput) {
    PayName
    IsAllProcessed
    PayItemID
    TotalAmount
    TotalQuantity
    CreatedDT
    CreatedBy
    ModifiedDT
    Employees {
      PayItems {
        TransactionID
        PayrollCycleID
        PayrollCycle
        PayItemType
      }
    }
  }
}
    `;

/**
 * __useMonthlyTransactionByPayItemWithinPeriodQuery__
 *
 * To run a query within a React component, call `useMonthlyTransactionByPayItemWithinPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyTransactionByPayItemWithinPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyTransactionByPayItemWithinPeriodQuery({
 *   variables: {
 *      PayItemFilterInput: // value for 'PayItemFilterInput'
 *   },
 * });
 */
export function useMonthlyTransactionByPayItemWithinPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthlyTransactionByPayItemWithinPeriodQuery, MonthlyTransactionByPayItemWithinPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthlyTransactionByPayItemWithinPeriodQuery, MonthlyTransactionByPayItemWithinPeriodQueryVariables>(MonthlyTransactionByPayItemWithinPeriodDocument, baseOptions);
      }
export function useMonthlyTransactionByPayItemWithinPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthlyTransactionByPayItemWithinPeriodQuery, MonthlyTransactionByPayItemWithinPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthlyTransactionByPayItemWithinPeriodQuery, MonthlyTransactionByPayItemWithinPeriodQueryVariables>(MonthlyTransactionByPayItemWithinPeriodDocument, baseOptions);
        }
export type MonthlyTransactionByPayItemWithinPeriodQueryHookResult = ReturnType<typeof useMonthlyTransactionByPayItemWithinPeriodQuery>;
export type MonthlyTransactionByPayItemWithinPeriodLazyQueryHookResult = ReturnType<typeof useMonthlyTransactionByPayItemWithinPeriodLazyQuery>;
export type MonthlyTransactionByPayItemWithinPeriodQueryResult = ApolloReactCommon.QueryResult<MonthlyTransactionByPayItemWithinPeriodQuery, MonthlyTransactionByPayItemWithinPeriodQueryVariables>;
export const MonthlyTransactionByPayItemSelectedDocument = gql`
    query MonthlyTransactionByPayItemSelected($PayItemFilterInput: PayItemFilterInput!) {
  MonthlyTransactionByPayItem(input: $PayItemFilterInput) {
    Employees {
      EmployeeID
      EmployeeNo
      EmployeeFullName
      IsProcessed
      PayItems {
        TransactionID
        Amount
        Quantity
        PayrollCycleID
      }
    }
  }
}
    `;

/**
 * __useMonthlyTransactionByPayItemSelectedQuery__
 *
 * To run a query within a React component, call `useMonthlyTransactionByPayItemSelectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyTransactionByPayItemSelectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyTransactionByPayItemSelectedQuery({
 *   variables: {
 *      PayItemFilterInput: // value for 'PayItemFilterInput'
 *   },
 * });
 */
export function useMonthlyTransactionByPayItemSelectedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthlyTransactionByPayItemSelectedQuery, MonthlyTransactionByPayItemSelectedQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthlyTransactionByPayItemSelectedQuery, MonthlyTransactionByPayItemSelectedQueryVariables>(MonthlyTransactionByPayItemSelectedDocument, baseOptions);
      }
export function useMonthlyTransactionByPayItemSelectedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthlyTransactionByPayItemSelectedQuery, MonthlyTransactionByPayItemSelectedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthlyTransactionByPayItemSelectedQuery, MonthlyTransactionByPayItemSelectedQueryVariables>(MonthlyTransactionByPayItemSelectedDocument, baseOptions);
        }
export type MonthlyTransactionByPayItemSelectedQueryHookResult = ReturnType<typeof useMonthlyTransactionByPayItemSelectedQuery>;
export type MonthlyTransactionByPayItemSelectedLazyQueryHookResult = ReturnType<typeof useMonthlyTransactionByPayItemSelectedLazyQuery>;
export type MonthlyTransactionByPayItemSelectedQueryResult = ApolloReactCommon.QueryResult<MonthlyTransactionByPayItemSelectedQuery, MonthlyTransactionByPayItemSelectedQueryVariables>;
export const PayrollProcessingPayItemListingDocument = gql`
    query PayrollProcessingPayItemListing($SubscriptionAccountID: String!) {
  PayrollProcessingPayItemListing(SubscriptionAccountID: $SubscriptionAccountID) {
    PayItemID
    CompanyID
    PayName
    PayItemType
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __usePayrollProcessingPayItemListingQuery__
 *
 * To run a query within a React component, call `usePayrollProcessingPayItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollProcessingPayItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollProcessingPayItemListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function usePayrollProcessingPayItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollProcessingPayItemListingQuery, PayrollProcessingPayItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollProcessingPayItemListingQuery, PayrollProcessingPayItemListingQueryVariables>(PayrollProcessingPayItemListingDocument, baseOptions);
      }
export function usePayrollProcessingPayItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollProcessingPayItemListingQuery, PayrollProcessingPayItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollProcessingPayItemListingQuery, PayrollProcessingPayItemListingQueryVariables>(PayrollProcessingPayItemListingDocument, baseOptions);
        }
export type PayrollProcessingPayItemListingQueryHookResult = ReturnType<typeof usePayrollProcessingPayItemListingQuery>;
export type PayrollProcessingPayItemListingLazyQueryHookResult = ReturnType<typeof usePayrollProcessingPayItemListingLazyQuery>;
export type PayrollProcessingPayItemListingQueryResult = ApolloReactCommon.QueryResult<PayrollProcessingPayItemListingQuery, PayrollProcessingPayItemListingQueryVariables>;
export const MonthlyTransactionByExcelDocument = gql`
    query MonthlyTransactionByExcel($MonthlyTransactionInput: MonthlyTransactionInput!) {
  MonthlyTransactionByExcel(input: $MonthlyTransactionInput) {
    ExcelFileName
    PayPeriod
    PayrollCycle
    TotalEarning
    TotalDeduction
    TotalNonPayable
    UploadedDate
    DocumentID
  }
}
    `;

/**
 * __useMonthlyTransactionByExcelQuery__
 *
 * To run a query within a React component, call `useMonthlyTransactionByExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyTransactionByExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyTransactionByExcelQuery({
 *   variables: {
 *      MonthlyTransactionInput: // value for 'MonthlyTransactionInput'
 *   },
 * });
 */
export function useMonthlyTransactionByExcelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthlyTransactionByExcelQuery, MonthlyTransactionByExcelQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthlyTransactionByExcelQuery, MonthlyTransactionByExcelQueryVariables>(MonthlyTransactionByExcelDocument, baseOptions);
      }
export function useMonthlyTransactionByExcelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthlyTransactionByExcelQuery, MonthlyTransactionByExcelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthlyTransactionByExcelQuery, MonthlyTransactionByExcelQueryVariables>(MonthlyTransactionByExcelDocument, baseOptions);
        }
export type MonthlyTransactionByExcelQueryHookResult = ReturnType<typeof useMonthlyTransactionByExcelQuery>;
export type MonthlyTransactionByExcelLazyQueryHookResult = ReturnType<typeof useMonthlyTransactionByExcelLazyQuery>;
export type MonthlyTransactionByExcelQueryResult = ApolloReactCommon.QueryResult<MonthlyTransactionByExcelQuery, MonthlyTransactionByExcelQueryVariables>;
export const FreezePayrollInsertDocument = gql`
    mutation FreezePayrollInsert($input: FreezePayrollInput!) {
  FreezePayrollInsert(input: $input)
}
    `;
export type FreezePayrollInsertMutationFn = ApolloReactCommon.MutationFunction<FreezePayrollInsertMutation, FreezePayrollInsertMutationVariables>;

/**
 * __useFreezePayrollInsertMutation__
 *
 * To run a mutation, you first call `useFreezePayrollInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFreezePayrollInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [freezePayrollInsertMutation, { data, loading, error }] = useFreezePayrollInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFreezePayrollInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FreezePayrollInsertMutation, FreezePayrollInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<FreezePayrollInsertMutation, FreezePayrollInsertMutationVariables>(FreezePayrollInsertDocument, baseOptions);
      }
export type FreezePayrollInsertMutationHookResult = ReturnType<typeof useFreezePayrollInsertMutation>;
export type FreezePayrollInsertMutationResult = ApolloReactCommon.MutationResult<FreezePayrollInsertMutation>;
export type FreezePayrollInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<FreezePayrollInsertMutation, FreezePayrollInsertMutationVariables>;
export const EmployeeFreezePayrollDeleteDocument = gql`
    mutation EmployeeFreezePayrollDelete($FreezePayrollID: String!) {
  EmployeeFreezePayrollDelete(FreezePayrollID: $FreezePayrollID)
}
    `;
export type EmployeeFreezePayrollDeleteMutationFn = ApolloReactCommon.MutationFunction<EmployeeFreezePayrollDeleteMutation, EmployeeFreezePayrollDeleteMutationVariables>;

/**
 * __useEmployeeFreezePayrollDeleteMutation__
 *
 * To run a mutation, you first call `useEmployeeFreezePayrollDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeFreezePayrollDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeFreezePayrollDeleteMutation, { data, loading, error }] = useEmployeeFreezePayrollDeleteMutation({
 *   variables: {
 *      FreezePayrollID: // value for 'FreezePayrollID'
 *   },
 * });
 */
export function useEmployeeFreezePayrollDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeFreezePayrollDeleteMutation, EmployeeFreezePayrollDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeFreezePayrollDeleteMutation, EmployeeFreezePayrollDeleteMutationVariables>(EmployeeFreezePayrollDeleteDocument, baseOptions);
      }
export type EmployeeFreezePayrollDeleteMutationHookResult = ReturnType<typeof useEmployeeFreezePayrollDeleteMutation>;
export type EmployeeFreezePayrollDeleteMutationResult = ApolloReactCommon.MutationResult<EmployeeFreezePayrollDeleteMutation>;
export type EmployeeFreezePayrollDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeFreezePayrollDeleteMutation, EmployeeFreezePayrollDeleteMutationVariables>;
export const DeletePayrollProcessDocument = gql`
    mutation DeletePayrollProcess($PayrollCycleID: String!, $PayPeriodID: String!, $CompanyID: String!) {
  DeletePayrollProcess(PayrollCycleID: $PayrollCycleID, PayPeriodID: $PayPeriodID, CompanyID: $CompanyID)
}
    `;
export type DeletePayrollProcessMutationFn = ApolloReactCommon.MutationFunction<DeletePayrollProcessMutation, DeletePayrollProcessMutationVariables>;

/**
 * __useDeletePayrollProcessMutation__
 *
 * To run a mutation, you first call `useDeletePayrollProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayrollProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayrollProcessMutation, { data, loading, error }] = useDeletePayrollProcessMutation({
 *   variables: {
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDeletePayrollProcessMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePayrollProcessMutation, DeletePayrollProcessMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePayrollProcessMutation, DeletePayrollProcessMutationVariables>(DeletePayrollProcessDocument, baseOptions);
      }
export type DeletePayrollProcessMutationHookResult = ReturnType<typeof useDeletePayrollProcessMutation>;
export type DeletePayrollProcessMutationResult = ApolloReactCommon.MutationResult<DeletePayrollProcessMutation>;
export type DeletePayrollProcessMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePayrollProcessMutation, DeletePayrollProcessMutationVariables>;
export const PayrollProcessPayItemListingDocument = gql`
    query PayrollProcessPayItemListing($PayrollCycleID: String!, $PayPeriodID: String!, $CompanyID: String!) {
  PayrollProcessPayItemListing(PayrollCycleID: $PayrollCycleID, PayPeriodID: $PayPeriodID, CompanyID: $CompanyID) {
    PayPeriodID
    PayrollCycleID
    Description
    PeriodYearMonth
    EarningPayItem {
      PayItemID
      PayName
      PayItemType
    }
    DeductionPayItem {
      PayItemID
      PayName
      PayItemType
    }
    NonPayablePayItem {
      PayItemID
      PayName
      PayItemType
    }
    OvertimePayItem {
      PayItemID
      PayName
      PayItemType
    }
  }
}
    `;

/**
 * __usePayrollProcessPayItemListingQuery__
 *
 * To run a query within a React component, call `usePayrollProcessPayItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollProcessPayItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollProcessPayItemListingQuery({
 *   variables: {
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePayrollProcessPayItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollProcessPayItemListingQuery, PayrollProcessPayItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollProcessPayItemListingQuery, PayrollProcessPayItemListingQueryVariables>(PayrollProcessPayItemListingDocument, baseOptions);
      }
export function usePayrollProcessPayItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollProcessPayItemListingQuery, PayrollProcessPayItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollProcessPayItemListingQuery, PayrollProcessPayItemListingQueryVariables>(PayrollProcessPayItemListingDocument, baseOptions);
        }
export type PayrollProcessPayItemListingQueryHookResult = ReturnType<typeof usePayrollProcessPayItemListingQuery>;
export type PayrollProcessPayItemListingLazyQueryHookResult = ReturnType<typeof usePayrollProcessPayItemListingLazyQuery>;
export type PayrollProcessPayItemListingQueryResult = ApolloReactCommon.QueryResult<PayrollProcessPayItemListingQuery, PayrollProcessPayItemListingQueryVariables>;
export const PayrollProcessingPayItemListing2Document = gql`
    query PayrollProcessingPayItemListing2($SubscriptionAccountID: String!, $CompanyID: String!, $PayPeriodID: String!, $PayrollCycleID: String!) {
  PayrollProcessingPayItemListing2(SubscriptionAccountID: $SubscriptionAccountID, CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayrollCycleID: $PayrollCycleID) {
    PayItemID
    CompanyID
    PayName
    PayItemType
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __usePayrollProcessingPayItemListing2Query__
 *
 * To run a query within a React component, call `usePayrollProcessingPayItemListing2Query` and pass it any options that fit your needs.
 * When your component renders, `usePayrollProcessingPayItemListing2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollProcessingPayItemListing2Query({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *   },
 * });
 */
export function usePayrollProcessingPayItemListing2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollProcessingPayItemListing2Query, PayrollProcessingPayItemListing2QueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollProcessingPayItemListing2Query, PayrollProcessingPayItemListing2QueryVariables>(PayrollProcessingPayItemListing2Document, baseOptions);
      }
export function usePayrollProcessingPayItemListing2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollProcessingPayItemListing2Query, PayrollProcessingPayItemListing2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollProcessingPayItemListing2Query, PayrollProcessingPayItemListing2QueryVariables>(PayrollProcessingPayItemListing2Document, baseOptions);
        }
export type PayrollProcessingPayItemListing2QueryHookResult = ReturnType<typeof usePayrollProcessingPayItemListing2Query>;
export type PayrollProcessingPayItemListing2LazyQueryHookResult = ReturnType<typeof usePayrollProcessingPayItemListing2LazyQuery>;
export type PayrollProcessingPayItemListing2QueryResult = ApolloReactCommon.QueryResult<PayrollProcessingPayItemListing2Query, PayrollProcessingPayItemListing2QueryVariables>;
export const PayrollProcessEmployeeListingDocument = gql`
    query PayrollProcessEmployeeListing($Input: EmployeeFilterInput!) {
  PayrollProcessEmployeeListing(input: $Input) {
    EmployeeID
    JobGradeID
    OrgUnitID
    FormStatus
    EmployeeNo
    EmployeeName
    Designation
    IsChecked
    Status
    EmploymentType
  }
}
    `;

/**
 * __usePayrollProcessEmployeeListingQuery__
 *
 * To run a query within a React component, call `usePayrollProcessEmployeeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollProcessEmployeeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollProcessEmployeeListingQuery({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function usePayrollProcessEmployeeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollProcessEmployeeListingQuery, PayrollProcessEmployeeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollProcessEmployeeListingQuery, PayrollProcessEmployeeListingQueryVariables>(PayrollProcessEmployeeListingDocument, baseOptions);
      }
export function usePayrollProcessEmployeeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollProcessEmployeeListingQuery, PayrollProcessEmployeeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollProcessEmployeeListingQuery, PayrollProcessEmployeeListingQueryVariables>(PayrollProcessEmployeeListingDocument, baseOptions);
        }
export type PayrollProcessEmployeeListingQueryHookResult = ReturnType<typeof usePayrollProcessEmployeeListingQuery>;
export type PayrollProcessEmployeeListingLazyQueryHookResult = ReturnType<typeof usePayrollProcessEmployeeListingLazyQuery>;
export type PayrollProcessEmployeeListingQueryResult = ApolloReactCommon.QueryResult<PayrollProcessEmployeeListingQuery, PayrollProcessEmployeeListingQueryVariables>;
export const CalculatePayrollDocument = gql`
    query CalculatePayroll($PayrollCalculationInput: PayrollCalculationInput!) {
  CalculatePayroll(PayrollCalculationInput: $PayrollCalculationInput) {
    Result
    TotalRecord
    Entity {
      EPFPaid
      EISPaid
      Employee {
        EmployeeID
      }
      EPFVEPaid
      EmployeeID
      NetPay
      CreatedDT
      CreatedBy
      PayPeriod {
        PayPeriodID
      }
      TaxRelief
      ModifiedDT
      ModifiedBy
      PayPeriodID
      TotalDeduction
      ProcessLog
      PayrollCycle {
        PayrollCycleID
      }
      PayrollCycleID
      TotalDeduction
      PayrollHeaderID
      RequiredWorkDay
      TotalChargableTax
      TotalChargableEPF
      TotalChargableEIS
      TotalChargableHRDF
      TotalChargableEPFVE
      TotalChargableSocso
      TotalChargableBonus
      TotalChargableAddRemuneration
    }
    Listing {
      ActualWorkday
      Tax1
      Tax2
      TaxZakat
      TaxRelief
      TaxBasicSalary
      TotalDeduction
      TotalChargableTax
      TotalChargableEPF
      TotalChargableEIS
      TotalChargableTax1
      TotalChargableTax2
      TotalChargableHRDF
      TotalEarningPayItem
      TotalChargableEPFVE
      TotalChargableSocso
      TotalChargableBonus
      TotalDeductionPayItem
      TotalNonPayablePayItem
      TotalChargableAddRemuneration
      Zakat
      NetSalary
      ActualWorkday
    }
  }
}
    `;

/**
 * __useCalculatePayrollQuery__
 *
 * To run a query within a React component, call `useCalculatePayrollQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculatePayrollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculatePayrollQuery({
 *   variables: {
 *      PayrollCalculationInput: // value for 'PayrollCalculationInput'
 *   },
 * });
 */
export function useCalculatePayrollQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalculatePayrollQuery, CalculatePayrollQueryVariables>) {
        return ApolloReactHooks.useQuery<CalculatePayrollQuery, CalculatePayrollQueryVariables>(CalculatePayrollDocument, baseOptions);
      }
export function useCalculatePayrollLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalculatePayrollQuery, CalculatePayrollQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalculatePayrollQuery, CalculatePayrollQueryVariables>(CalculatePayrollDocument, baseOptions);
        }
export type CalculatePayrollQueryHookResult = ReturnType<typeof useCalculatePayrollQuery>;
export type CalculatePayrollLazyQueryHookResult = ReturnType<typeof useCalculatePayrollLazyQuery>;
export type CalculatePayrollQueryResult = ApolloReactCommon.QueryResult<CalculatePayrollQuery, CalculatePayrollQueryVariables>;
export const PayPeriodDetailsDocument = gql`
    query PayPeriodDetails($CompanyID: String!, $PeriodYearMonth: String!) {
  PayPeriodDetails(CompanyID: $CompanyID, PeriodYearMonth: $PeriodYearMonth) {
    PayPeriodID
    CompanyID
    PeriodYearMonth
    FromDate
    ToDate
    ActualWorkDays
  }
}
    `;

/**
 * __usePayPeriodDetailsQuery__
 *
 * To run a query within a React component, call `usePayPeriodDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayPeriodDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayPeriodDetailsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PeriodYearMonth: // value for 'PeriodYearMonth'
 *   },
 * });
 */
export function usePayPeriodDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayPeriodDetailsQuery, PayPeriodDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<PayPeriodDetailsQuery, PayPeriodDetailsQueryVariables>(PayPeriodDetailsDocument, baseOptions);
      }
export function usePayPeriodDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayPeriodDetailsQuery, PayPeriodDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayPeriodDetailsQuery, PayPeriodDetailsQueryVariables>(PayPeriodDetailsDocument, baseOptions);
        }
export type PayPeriodDetailsQueryHookResult = ReturnType<typeof usePayPeriodDetailsQuery>;
export type PayPeriodDetailsLazyQueryHookResult = ReturnType<typeof usePayPeriodDetailsLazyQuery>;
export type PayPeriodDetailsQueryResult = ApolloReactCommon.QueryResult<PayPeriodDetailsQuery, PayPeriodDetailsQueryVariables>;
export const DeletePayrollHistoryDocument = gql`
    mutation DeletePayrollHistory($PayrollHeaderID: String!) {
  DeletePayrollHistory(PayrollHeaderID: $PayrollHeaderID)
}
    `;
export type DeletePayrollHistoryMutationFn = ApolloReactCommon.MutationFunction<DeletePayrollHistoryMutation, DeletePayrollHistoryMutationVariables>;

/**
 * __useDeletePayrollHistoryMutation__
 *
 * To run a mutation, you first call `useDeletePayrollHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePayrollHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePayrollHistoryMutation, { data, loading, error }] = useDeletePayrollHistoryMutation({
 *   variables: {
 *      PayrollHeaderID: // value for 'PayrollHeaderID'
 *   },
 * });
 */
export function useDeletePayrollHistoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePayrollHistoryMutation, DeletePayrollHistoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePayrollHistoryMutation, DeletePayrollHistoryMutationVariables>(DeletePayrollHistoryDocument, baseOptions);
      }
export type DeletePayrollHistoryMutationHookResult = ReturnType<typeof useDeletePayrollHistoryMutation>;
export type DeletePayrollHistoryMutationResult = ApolloReactCommon.MutationResult<DeletePayrollHistoryMutation>;
export type DeletePayrollHistoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePayrollHistoryMutation, DeletePayrollHistoryMutationVariables>;
export const ViewPayrollHistoryDetailsDocument = gql`
    query ViewPayrollHistoryDetails($input: ViewPayrollHistoryInput!) {
  ViewPayrollHistory(input: $input) {
    PayrollHeaderID
    EmployeeID
    Employee {
      EmployeeNo
      Status
      Contact {
        FullName
      }
    }
    TotalChargableTax
    TotalGross
    TotalDeduction
    ProcessLog
    NetPay
    Status
    CompanyID
    PayrollCycle {
      Description
      Status
    }
    PayPeriod {
      PeriodYearMonth
      Status
    }
    PayrollDetails {
      PayrollDetailID
      PayItemID
      PayItem {
        PayName
        PayCode
        PayItemType
        Status
      }
      TotalAmount
    }
  }
}
    `;

/**
 * __useViewPayrollHistoryDetailsQuery__
 *
 * To run a query within a React component, call `useViewPayrollHistoryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPayrollHistoryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPayrollHistoryDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViewPayrollHistoryDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewPayrollHistoryDetailsQuery, ViewPayrollHistoryDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewPayrollHistoryDetailsQuery, ViewPayrollHistoryDetailsQueryVariables>(ViewPayrollHistoryDetailsDocument, baseOptions);
      }
export function useViewPayrollHistoryDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewPayrollHistoryDetailsQuery, ViewPayrollHistoryDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewPayrollHistoryDetailsQuery, ViewPayrollHistoryDetailsQueryVariables>(ViewPayrollHistoryDetailsDocument, baseOptions);
        }
export type ViewPayrollHistoryDetailsQueryHookResult = ReturnType<typeof useViewPayrollHistoryDetailsQuery>;
export type ViewPayrollHistoryDetailsLazyQueryHookResult = ReturnType<typeof useViewPayrollHistoryDetailsLazyQuery>;
export type ViewPayrollHistoryDetailsQueryResult = ApolloReactCommon.QueryResult<ViewPayrollHistoryDetailsQuery, ViewPayrollHistoryDetailsQueryVariables>;
export const ViewPayrollHistoryDetailsMyPayrollInfoDocument = gql`
    query ViewPayrollHistoryDetailsMyPayrollInfo($input: ViewPayrollHistoryInput!) {
  ViewPayrollHistoryMyPayrollInfo(input: $input) {
    PayrollHeaderID
    EmployeeID
    Employee {
      EmployeeNo
      Status
      Contact {
        FullName
      }
    }
    TotalGross
    TotalDeduction
    ProcessLog
    NetPay
    Status
    CompanyID
    PayrollCycle {
      Description
      Status
    }
    PayPeriod {
      PeriodYearMonth
      Status
    }
    PayrollDetails {
      PayrollDetailID
      PayItemID
      PayItem {
        PayName
        PayCode
        PayItemType
        Status
      }
      TotalAmount
    }
  }
}
    `;

/**
 * __useViewPayrollHistoryDetailsMyPayrollInfoQuery__
 *
 * To run a query within a React component, call `useViewPayrollHistoryDetailsMyPayrollInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPayrollHistoryDetailsMyPayrollInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPayrollHistoryDetailsMyPayrollInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useViewPayrollHistoryDetailsMyPayrollInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewPayrollHistoryDetailsMyPayrollInfoQuery, ViewPayrollHistoryDetailsMyPayrollInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewPayrollHistoryDetailsMyPayrollInfoQuery, ViewPayrollHistoryDetailsMyPayrollInfoQueryVariables>(ViewPayrollHistoryDetailsMyPayrollInfoDocument, baseOptions);
      }
export function useViewPayrollHistoryDetailsMyPayrollInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewPayrollHistoryDetailsMyPayrollInfoQuery, ViewPayrollHistoryDetailsMyPayrollInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewPayrollHistoryDetailsMyPayrollInfoQuery, ViewPayrollHistoryDetailsMyPayrollInfoQueryVariables>(ViewPayrollHistoryDetailsMyPayrollInfoDocument, baseOptions);
        }
export type ViewPayrollHistoryDetailsMyPayrollInfoQueryHookResult = ReturnType<typeof useViewPayrollHistoryDetailsMyPayrollInfoQuery>;
export type ViewPayrollHistoryDetailsMyPayrollInfoLazyQueryHookResult = ReturnType<typeof useViewPayrollHistoryDetailsMyPayrollInfoLazyQuery>;
export type ViewPayrollHistoryDetailsMyPayrollInfoQueryResult = ApolloReactCommon.QueryResult<ViewPayrollHistoryDetailsMyPayrollInfoQuery, ViewPayrollHistoryDetailsMyPayrollInfoQueryVariables>;
export const UpdatePayrollProcessStatusDocument = gql`
    mutation UpdatePayrollProcessStatus($PayrollCycleID: String!, $PayPeriodID: String!, $CompanyID: String!, $PayrollCycleStatus: String!, $CreatedBy: String!, $ModifiedBy: String!) {
  UpdatePayrollProcessStatus(PayrollCycleID: $PayrollCycleID, PayPeriodID: $PayPeriodID, CompanyID: $CompanyID, PayrollCycleStatus: $PayrollCycleStatus, CreatedBy: $CreatedBy, ModifiedBy: $ModifiedBy)
}
    `;
export type UpdatePayrollProcessStatusMutationFn = ApolloReactCommon.MutationFunction<UpdatePayrollProcessStatusMutation, UpdatePayrollProcessStatusMutationVariables>;

/**
 * __useUpdatePayrollProcessStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePayrollProcessStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePayrollProcessStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePayrollProcessStatusMutation, { data, loading, error }] = useUpdatePayrollProcessStatusMutation({
 *   variables: {
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      CompanyID: // value for 'CompanyID'
 *      PayrollCycleStatus: // value for 'PayrollCycleStatus'
 *      CreatedBy: // value for 'CreatedBy'
 *      ModifiedBy: // value for 'ModifiedBy'
 *   },
 * });
 */
export function useUpdatePayrollProcessStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePayrollProcessStatusMutation, UpdatePayrollProcessStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePayrollProcessStatusMutation, UpdatePayrollProcessStatusMutationVariables>(UpdatePayrollProcessStatusDocument, baseOptions);
      }
export type UpdatePayrollProcessStatusMutationHookResult = ReturnType<typeof useUpdatePayrollProcessStatusMutation>;
export type UpdatePayrollProcessStatusMutationResult = ApolloReactCommon.MutationResult<UpdatePayrollProcessStatusMutation>;
export type UpdatePayrollProcessStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePayrollProcessStatusMutation, UpdatePayrollProcessStatusMutationVariables>;
export const UnPublishEaDocument = gql`
    mutation UnPublishEA($PublishEAID: String!) {
  UnPublishEA(PublishEAID: $PublishEAID)
}
    `;
export type UnPublishEaMutationFn = ApolloReactCommon.MutationFunction<UnPublishEaMutation, UnPublishEaMutationVariables>;

/**
 * __useUnPublishEaMutation__
 *
 * To run a mutation, you first call `useUnPublishEaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnPublishEaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unPublishEaMutation, { data, loading, error }] = useUnPublishEaMutation({
 *   variables: {
 *      PublishEAID: // value for 'PublishEAID'
 *   },
 * });
 */
export function useUnPublishEaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnPublishEaMutation, UnPublishEaMutationVariables>) {
        return ApolloReactHooks.useMutation<UnPublishEaMutation, UnPublishEaMutationVariables>(UnPublishEaDocument, baseOptions);
      }
export type UnPublishEaMutationHookResult = ReturnType<typeof useUnPublishEaMutation>;
export type UnPublishEaMutationResult = ApolloReactCommon.MutationResult<UnPublishEaMutation>;
export type UnPublishEaMutationOptions = ApolloReactCommon.BaseMutationOptions<UnPublishEaMutation, UnPublishEaMutationVariables>;
export const Cp159UpdateDocument = gql`
    mutation CP159Update($CP159Input: CP159Input!) {
  CP159Update(CP159Input: $CP159Input)
}
    `;
export type Cp159UpdateMutationFn = ApolloReactCommon.MutationFunction<Cp159UpdateMutation, Cp159UpdateMutationVariables>;

/**
 * __useCp159UpdateMutation__
 *
 * To run a mutation, you first call `useCp159UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCp159UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cp159UpdateMutation, { data, loading, error }] = useCp159UpdateMutation({
 *   variables: {
 *      CP159Input: // value for 'CP159Input'
 *   },
 * });
 */
export function useCp159UpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Cp159UpdateMutation, Cp159UpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<Cp159UpdateMutation, Cp159UpdateMutationVariables>(Cp159UpdateDocument, baseOptions);
      }
export type Cp159UpdateMutationHookResult = ReturnType<typeof useCp159UpdateMutation>;
export type Cp159UpdateMutationResult = ApolloReactCommon.MutationResult<Cp159UpdateMutation>;
export type Cp159UpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<Cp159UpdateMutation, Cp159UpdateMutationVariables>;
export const GlAccountMappingListDocument = gql`
    query GLAccountMappingList($GLAccountHeaderID: String) {
  GLAccountMappingList(GLAccountHeaderID: $GLAccountHeaderID)
}
    `;

/**
 * __useGlAccountMappingListQuery__
 *
 * To run a query within a React component, call `useGlAccountMappingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlAccountMappingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlAccountMappingListQuery({
 *   variables: {
 *      GLAccountHeaderID: // value for 'GLAccountHeaderID'
 *   },
 * });
 */
export function useGlAccountMappingListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GlAccountMappingListQuery, GlAccountMappingListQueryVariables>) {
        return ApolloReactHooks.useQuery<GlAccountMappingListQuery, GlAccountMappingListQueryVariables>(GlAccountMappingListDocument, baseOptions);
      }
export function useGlAccountMappingListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlAccountMappingListQuery, GlAccountMappingListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GlAccountMappingListQuery, GlAccountMappingListQueryVariables>(GlAccountMappingListDocument, baseOptions);
        }
export type GlAccountMappingListQueryHookResult = ReturnType<typeof useGlAccountMappingListQuery>;
export type GlAccountMappingListLazyQueryHookResult = ReturnType<typeof useGlAccountMappingListLazyQuery>;
export type GlAccountMappingListQueryResult = ApolloReactCommon.QueryResult<GlAccountMappingListQuery, GlAccountMappingListQueryVariables>;
export const AccountMappingInsertDocument = gql`
    mutation AccountMappingInsert($HeaderInput: GLAccountHeaderInput!, $DetailInput: [GLAccountDetailInput!]!) {
  AccountMappingInsert(HeaderInput: $HeaderInput, DetailInput: $DetailInput)
}
    `;
export type AccountMappingInsertMutationFn = ApolloReactCommon.MutationFunction<AccountMappingInsertMutation, AccountMappingInsertMutationVariables>;

/**
 * __useAccountMappingInsertMutation__
 *
 * To run a mutation, you first call `useAccountMappingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountMappingInsertMutation, { data, loading, error }] = useAccountMappingInsertMutation({
 *   variables: {
 *      HeaderInput: // value for 'HeaderInput'
 *      DetailInput: // value for 'DetailInput'
 *   },
 * });
 */
export function useAccountMappingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AccountMappingInsertMutation, AccountMappingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<AccountMappingInsertMutation, AccountMappingInsertMutationVariables>(AccountMappingInsertDocument, baseOptions);
      }
export type AccountMappingInsertMutationHookResult = ReturnType<typeof useAccountMappingInsertMutation>;
export type AccountMappingInsertMutationResult = ApolloReactCommon.MutationResult<AccountMappingInsertMutation>;
export type AccountMappingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<AccountMappingInsertMutation, AccountMappingInsertMutationVariables>;
export const AccountMappingUpdateDocument = gql`
    mutation AccountMappingUpdate($HeaderInput: GLAccountHeaderInput!, $DetailInput: [GLAccountDetailInput!]!) {
  AccountMappingUpdate(HeaderInput: $HeaderInput, DetailInput: $DetailInput)
}
    `;
export type AccountMappingUpdateMutationFn = ApolloReactCommon.MutationFunction<AccountMappingUpdateMutation, AccountMappingUpdateMutationVariables>;

/**
 * __useAccountMappingUpdateMutation__
 *
 * To run a mutation, you first call `useAccountMappingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountMappingUpdateMutation, { data, loading, error }] = useAccountMappingUpdateMutation({
 *   variables: {
 *      HeaderInput: // value for 'HeaderInput'
 *      DetailInput: // value for 'DetailInput'
 *   },
 * });
 */
export function useAccountMappingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AccountMappingUpdateMutation, AccountMappingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<AccountMappingUpdateMutation, AccountMappingUpdateMutationVariables>(AccountMappingUpdateDocument, baseOptions);
      }
export type AccountMappingUpdateMutationHookResult = ReturnType<typeof useAccountMappingUpdateMutation>;
export type AccountMappingUpdateMutationResult = ApolloReactCommon.MutationResult<AccountMappingUpdateMutation>;
export type AccountMappingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<AccountMappingUpdateMutation, AccountMappingUpdateMutationVariables>;
export const AccountMappingDeleteDocument = gql`
    mutation AccountMappingDelete($GLAccountHeaderID: String!) {
  AccountMappingDelete(GLAccountHeaderID: $GLAccountHeaderID)
}
    `;
export type AccountMappingDeleteMutationFn = ApolloReactCommon.MutationFunction<AccountMappingDeleteMutation, AccountMappingDeleteMutationVariables>;

/**
 * __useAccountMappingDeleteMutation__
 *
 * To run a mutation, you first call `useAccountMappingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountMappingDeleteMutation, { data, loading, error }] = useAccountMappingDeleteMutation({
 *   variables: {
 *      GLAccountHeaderID: // value for 'GLAccountHeaderID'
 *   },
 * });
 */
export function useAccountMappingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AccountMappingDeleteMutation, AccountMappingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AccountMappingDeleteMutation, AccountMappingDeleteMutationVariables>(AccountMappingDeleteDocument, baseOptions);
      }
export type AccountMappingDeleteMutationHookResult = ReturnType<typeof useAccountMappingDeleteMutation>;
export type AccountMappingDeleteMutationResult = ApolloReactCommon.MutationResult<AccountMappingDeleteMutation>;
export type AccountMappingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AccountMappingDeleteMutation, AccountMappingDeleteMutationVariables>;
export const GlAccountHeaderListDocument = gql`
    query GLAccountHeaderList {
  GLAccountHeaderList {
    ID
    Title
    EnableStaffCategory
    ModifiedDT
  }
}
    `;

/**
 * __useGlAccountHeaderListQuery__
 *
 * To run a query within a React component, call `useGlAccountHeaderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlAccountHeaderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlAccountHeaderListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlAccountHeaderListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GlAccountHeaderListQuery, GlAccountHeaderListQueryVariables>) {
        return ApolloReactHooks.useQuery<GlAccountHeaderListQuery, GlAccountHeaderListQueryVariables>(GlAccountHeaderListDocument, baseOptions);
      }
export function useGlAccountHeaderListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlAccountHeaderListQuery, GlAccountHeaderListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GlAccountHeaderListQuery, GlAccountHeaderListQueryVariables>(GlAccountHeaderListDocument, baseOptions);
        }
export type GlAccountHeaderListQueryHookResult = ReturnType<typeof useGlAccountHeaderListQuery>;
export type GlAccountHeaderListLazyQueryHookResult = ReturnType<typeof useGlAccountHeaderListLazyQuery>;
export type GlAccountHeaderListQueryResult = ApolloReactCommon.QueryResult<GlAccountHeaderListQuery, GlAccountHeaderListQueryVariables>;
export const GlAccountHeaderListWithDetailsDocument = gql`
    query GLAccountHeaderListWithDetails {
  GLAccountHeaderListWithDetails
}
    `;

/**
 * __useGlAccountHeaderListWithDetailsQuery__
 *
 * To run a query within a React component, call `useGlAccountHeaderListWithDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlAccountHeaderListWithDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlAccountHeaderListWithDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlAccountHeaderListWithDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GlAccountHeaderListWithDetailsQuery, GlAccountHeaderListWithDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GlAccountHeaderListWithDetailsQuery, GlAccountHeaderListWithDetailsQueryVariables>(GlAccountHeaderListWithDetailsDocument, baseOptions);
      }
export function useGlAccountHeaderListWithDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlAccountHeaderListWithDetailsQuery, GlAccountHeaderListWithDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GlAccountHeaderListWithDetailsQuery, GlAccountHeaderListWithDetailsQueryVariables>(GlAccountHeaderListWithDetailsDocument, baseOptions);
        }
export type GlAccountHeaderListWithDetailsQueryHookResult = ReturnType<typeof useGlAccountHeaderListWithDetailsQuery>;
export type GlAccountHeaderListWithDetailsLazyQueryHookResult = ReturnType<typeof useGlAccountHeaderListWithDetailsLazyQuery>;
export type GlAccountHeaderListWithDetailsQueryResult = ApolloReactCommon.QueryResult<GlAccountHeaderListWithDetailsQuery, GlAccountHeaderListWithDetailsQueryVariables>;
export const SpecificGlAccountHeaderListWithDetailsDocument = gql`
    query SpecificGLAccountHeaderListWithDetails($GLAccountHeaderID: String, $PayPeriodID: String, $PayrollCycleID: String, $CompanyID: String, $AllPayCycles: Boolean, $Type: String) {
  GLAccountHeaderListWithDetails(GLAccountHeaderID: $GLAccountHeaderID, PayPeriodID: $PayPeriodID, PayrollCycleID: $PayrollCycleID, CompanyID: $CompanyID, AllPayCycles: $AllPayCycles, Type: $Type)
}
    `;

/**
 * __useSpecificGlAccountHeaderListWithDetailsQuery__
 *
 * To run a query within a React component, call `useSpecificGlAccountHeaderListWithDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificGlAccountHeaderListWithDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificGlAccountHeaderListWithDetailsQuery({
 *   variables: {
 *      GLAccountHeaderID: // value for 'GLAccountHeaderID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      CompanyID: // value for 'CompanyID'
 *      AllPayCycles: // value for 'AllPayCycles'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useSpecificGlAccountHeaderListWithDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SpecificGlAccountHeaderListWithDetailsQuery, SpecificGlAccountHeaderListWithDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<SpecificGlAccountHeaderListWithDetailsQuery, SpecificGlAccountHeaderListWithDetailsQueryVariables>(SpecificGlAccountHeaderListWithDetailsDocument, baseOptions);
      }
export function useSpecificGlAccountHeaderListWithDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpecificGlAccountHeaderListWithDetailsQuery, SpecificGlAccountHeaderListWithDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SpecificGlAccountHeaderListWithDetailsQuery, SpecificGlAccountHeaderListWithDetailsQueryVariables>(SpecificGlAccountHeaderListWithDetailsDocument, baseOptions);
        }
export type SpecificGlAccountHeaderListWithDetailsQueryHookResult = ReturnType<typeof useSpecificGlAccountHeaderListWithDetailsQuery>;
export type SpecificGlAccountHeaderListWithDetailsLazyQueryHookResult = ReturnType<typeof useSpecificGlAccountHeaderListWithDetailsLazyQuery>;
export type SpecificGlAccountHeaderListWithDetailsQueryResult = ApolloReactCommon.QueryResult<SpecificGlAccountHeaderListWithDetailsQuery, SpecificGlAccountHeaderListWithDetailsQueryVariables>;
export const GetAccountMappingAssignmentDocument = gql`
    query GetAccountMappingAssignment($CompanyID: String!) {
  GetAccountMappingAssignment(CompanyID: $CompanyID) {
    ID
    CompanyID
    GLAccountHeaderID
    GLAccountHeader {
      Title
    }
  }
}
    `;

/**
 * __useGetAccountMappingAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAccountMappingAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountMappingAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountMappingAssignmentQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetAccountMappingAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountMappingAssignmentQuery, GetAccountMappingAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountMappingAssignmentQuery, GetAccountMappingAssignmentQueryVariables>(GetAccountMappingAssignmentDocument, baseOptions);
      }
export function useGetAccountMappingAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountMappingAssignmentQuery, GetAccountMappingAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountMappingAssignmentQuery, GetAccountMappingAssignmentQueryVariables>(GetAccountMappingAssignmentDocument, baseOptions);
        }
export type GetAccountMappingAssignmentQueryHookResult = ReturnType<typeof useGetAccountMappingAssignmentQuery>;
export type GetAccountMappingAssignmentLazyQueryHookResult = ReturnType<typeof useGetAccountMappingAssignmentLazyQuery>;
export type GetAccountMappingAssignmentQueryResult = ApolloReactCommon.QueryResult<GetAccountMappingAssignmentQuery, GetAccountMappingAssignmentQueryVariables>;
export const AccountMappingAssignmentInsertDocument = gql`
    mutation AccountMappingAssignmentInsert($HeaderInput: GLAccountAssignmentInput!) {
  AccountMappingAssignmentInsert(HeaderInput: $HeaderInput)
}
    `;
export type AccountMappingAssignmentInsertMutationFn = ApolloReactCommon.MutationFunction<AccountMappingAssignmentInsertMutation, AccountMappingAssignmentInsertMutationVariables>;

/**
 * __useAccountMappingAssignmentInsertMutation__
 *
 * To run a mutation, you first call `useAccountMappingAssignmentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingAssignmentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountMappingAssignmentInsertMutation, { data, loading, error }] = useAccountMappingAssignmentInsertMutation({
 *   variables: {
 *      HeaderInput: // value for 'HeaderInput'
 *   },
 * });
 */
export function useAccountMappingAssignmentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AccountMappingAssignmentInsertMutation, AccountMappingAssignmentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<AccountMappingAssignmentInsertMutation, AccountMappingAssignmentInsertMutationVariables>(AccountMappingAssignmentInsertDocument, baseOptions);
      }
export type AccountMappingAssignmentInsertMutationHookResult = ReturnType<typeof useAccountMappingAssignmentInsertMutation>;
export type AccountMappingAssignmentInsertMutationResult = ApolloReactCommon.MutationResult<AccountMappingAssignmentInsertMutation>;
export type AccountMappingAssignmentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<AccountMappingAssignmentInsertMutation, AccountMappingAssignmentInsertMutationVariables>;
export const AccountMappingAssignmentUpdateDocument = gql`
    mutation AccountMappingAssignmentUpdate($HeaderInput: GLAccountAssignmentInput!) {
  AccountMappingAssignmentUpdate(HeaderInput: $HeaderInput)
}
    `;
export type AccountMappingAssignmentUpdateMutationFn = ApolloReactCommon.MutationFunction<AccountMappingAssignmentUpdateMutation, AccountMappingAssignmentUpdateMutationVariables>;

/**
 * __useAccountMappingAssignmentUpdateMutation__
 *
 * To run a mutation, you first call `useAccountMappingAssignmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingAssignmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountMappingAssignmentUpdateMutation, { data, loading, error }] = useAccountMappingAssignmentUpdateMutation({
 *   variables: {
 *      HeaderInput: // value for 'HeaderInput'
 *   },
 * });
 */
export function useAccountMappingAssignmentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AccountMappingAssignmentUpdateMutation, AccountMappingAssignmentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<AccountMappingAssignmentUpdateMutation, AccountMappingAssignmentUpdateMutationVariables>(AccountMappingAssignmentUpdateDocument, baseOptions);
      }
export type AccountMappingAssignmentUpdateMutationHookResult = ReturnType<typeof useAccountMappingAssignmentUpdateMutation>;
export type AccountMappingAssignmentUpdateMutationResult = ApolloReactCommon.MutationResult<AccountMappingAssignmentUpdateMutation>;
export type AccountMappingAssignmentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<AccountMappingAssignmentUpdateMutation, AccountMappingAssignmentUpdateMutationVariables>;
export const PayItemAmountByOrgAndPayPeriodDocument = gql`
    query PayItemAmountByOrgAndPayPeriod($PayPeriodID: String!, $CompanyID: String!, $PayrollCycleID: String, $AllPayCycles: Boolean) {
  PayItemAmountByOrgAndPayPeriod(PayPeriodID: $PayPeriodID, CompanyID: $CompanyID, PayrollCycleID: $PayrollCycleID, AllPayCycles: $AllPayCycles)
}
    `;

/**
 * __usePayItemAmountByOrgAndPayPeriodQuery__
 *
 * To run a query within a React component, call `usePayItemAmountByOrgAndPayPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayItemAmountByOrgAndPayPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayItemAmountByOrgAndPayPeriodQuery({
 *   variables: {
 *      PayPeriodID: // value for 'PayPeriodID'
 *      CompanyID: // value for 'CompanyID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      AllPayCycles: // value for 'AllPayCycles'
 *   },
 * });
 */
export function usePayItemAmountByOrgAndPayPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayItemAmountByOrgAndPayPeriodQuery, PayItemAmountByOrgAndPayPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<PayItemAmountByOrgAndPayPeriodQuery, PayItemAmountByOrgAndPayPeriodQueryVariables>(PayItemAmountByOrgAndPayPeriodDocument, baseOptions);
      }
export function usePayItemAmountByOrgAndPayPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayItemAmountByOrgAndPayPeriodQuery, PayItemAmountByOrgAndPayPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayItemAmountByOrgAndPayPeriodQuery, PayItemAmountByOrgAndPayPeriodQueryVariables>(PayItemAmountByOrgAndPayPeriodDocument, baseOptions);
        }
export type PayItemAmountByOrgAndPayPeriodQueryHookResult = ReturnType<typeof usePayItemAmountByOrgAndPayPeriodQuery>;
export type PayItemAmountByOrgAndPayPeriodLazyQueryHookResult = ReturnType<typeof usePayItemAmountByOrgAndPayPeriodLazyQuery>;
export type PayItemAmountByOrgAndPayPeriodQueryResult = ApolloReactCommon.QueryResult<PayItemAmountByOrgAndPayPeriodQuery, PayItemAmountByOrgAndPayPeriodQueryVariables>;
export const ViewPayrollHistoryByEmployeeDocument = gql`
    query ViewPayrollHistoryByEmployee($CompanyID: String!, $EmployeeID: String!, $Year: Float!) {
  ViewPayrollHistoryByEmployee(CompanyID: $CompanyID, EmployeeID: $EmployeeID, Year: $Year)
}
    `;

/**
 * __useViewPayrollHistoryByEmployeeQuery__
 *
 * To run a query within a React component, call `useViewPayrollHistoryByEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPayrollHistoryByEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPayrollHistoryByEmployeeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useViewPayrollHistoryByEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewPayrollHistoryByEmployeeQuery, ViewPayrollHistoryByEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewPayrollHistoryByEmployeeQuery, ViewPayrollHistoryByEmployeeQueryVariables>(ViewPayrollHistoryByEmployeeDocument, baseOptions);
      }
export function useViewPayrollHistoryByEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewPayrollHistoryByEmployeeQuery, ViewPayrollHistoryByEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewPayrollHistoryByEmployeeQuery, ViewPayrollHistoryByEmployeeQueryVariables>(ViewPayrollHistoryByEmployeeDocument, baseOptions);
        }
export type ViewPayrollHistoryByEmployeeQueryHookResult = ReturnType<typeof useViewPayrollHistoryByEmployeeQuery>;
export type ViewPayrollHistoryByEmployeeLazyQueryHookResult = ReturnType<typeof useViewPayrollHistoryByEmployeeLazyQuery>;
export type ViewPayrollHistoryByEmployeeQueryResult = ApolloReactCommon.QueryResult<ViewPayrollHistoryByEmployeeQuery, ViewPayrollHistoryByEmployeeQueryVariables>;
export const SalaryListingByExcelDocument = gql`
    query SalaryListingByExcel($CompanyID: String!) {
  SalaryListingByExcel(CompanyID: $CompanyID)
}
    `;

/**
 * __useSalaryListingByExcelQuery__
 *
 * To run a query within a React component, call `useSalaryListingByExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalaryListingByExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalaryListingByExcelQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useSalaryListingByExcelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SalaryListingByExcelQuery, SalaryListingByExcelQueryVariables>) {
        return ApolloReactHooks.useQuery<SalaryListingByExcelQuery, SalaryListingByExcelQueryVariables>(SalaryListingByExcelDocument, baseOptions);
      }
export function useSalaryListingByExcelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SalaryListingByExcelQuery, SalaryListingByExcelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SalaryListingByExcelQuery, SalaryListingByExcelQueryVariables>(SalaryListingByExcelDocument, baseOptions);
        }
export type SalaryListingByExcelQueryHookResult = ReturnType<typeof useSalaryListingByExcelQuery>;
export type SalaryListingByExcelLazyQueryHookResult = ReturnType<typeof useSalaryListingByExcelLazyQuery>;
export type SalaryListingByExcelQueryResult = ApolloReactCommon.QueryResult<SalaryListingByExcelQuery, SalaryListingByExcelQueryVariables>;
export const SalaryBatchDocument = gql`
    mutation SalaryBatch($input: [CareerLogInput!]!, $DocumentsInput: DocumentsInput) {
  SalaryBatch(Input: $input, DocumentsInput: $DocumentsInput)
}
    `;
export type SalaryBatchMutationFn = ApolloReactCommon.MutationFunction<SalaryBatchMutation, SalaryBatchMutationVariables>;

/**
 * __useSalaryBatchMutation__
 *
 * To run a mutation, you first call `useSalaryBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalaryBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salaryBatchMutation, { data, loading, error }] = useSalaryBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useSalaryBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SalaryBatchMutation, SalaryBatchMutationVariables>) {
        return ApolloReactHooks.useMutation<SalaryBatchMutation, SalaryBatchMutationVariables>(SalaryBatchDocument, baseOptions);
      }
export type SalaryBatchMutationHookResult = ReturnType<typeof useSalaryBatchMutation>;
export type SalaryBatchMutationResult = ApolloReactCommon.MutationResult<SalaryBatchMutation>;
export type SalaryBatchMutationOptions = ApolloReactCommon.BaseMutationOptions<SalaryBatchMutation, SalaryBatchMutationVariables>;
export const SalaryListingByPeriodDocument = gql`
    query SalaryListingByPeriod($StartMonth: DateTime!, $EndMonth: DateTime!, $CompanyID: String!) {
  SalaryListingByPeriod(StartMonth: $StartMonth, EndMonth: $EndMonth, CompanyID: $CompanyID) {
    Status
    excelfilename
    SalaryAdjustmentAmount
    EmployeeID
    ToCompany
    ToSalary
    FromSalary
    Remark
    Reason
    CreatedDT
    EffectiveDate
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
  }
}
    `;

/**
 * __useSalaryListingByPeriodQuery__
 *
 * To run a query within a React component, call `useSalaryListingByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalaryListingByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalaryListingByPeriodQuery({
 *   variables: {
 *      StartMonth: // value for 'StartMonth'
 *      EndMonth: // value for 'EndMonth'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useSalaryListingByPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SalaryListingByPeriodQuery, SalaryListingByPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<SalaryListingByPeriodQuery, SalaryListingByPeriodQueryVariables>(SalaryListingByPeriodDocument, baseOptions);
      }
export function useSalaryListingByPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SalaryListingByPeriodQuery, SalaryListingByPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SalaryListingByPeriodQuery, SalaryListingByPeriodQueryVariables>(SalaryListingByPeriodDocument, baseOptions);
        }
export type SalaryListingByPeriodQueryHookResult = ReturnType<typeof useSalaryListingByPeriodQuery>;
export type SalaryListingByPeriodLazyQueryHookResult = ReturnType<typeof useSalaryListingByPeriodLazyQuery>;
export type SalaryListingByPeriodQueryResult = ApolloReactCommon.QueryResult<SalaryListingByPeriodQuery, SalaryListingByPeriodQueryVariables>;
export const CheckOldDataForSalaryBatchDocument = gql`
    query CheckOldDataForSalaryBatch($Data: [JSON!]) {
  CheckOldDataForSalaryBatch(Data: $Data)
}
    `;

/**
 * __useCheckOldDataForSalaryBatchQuery__
 *
 * To run a query within a React component, call `useCheckOldDataForSalaryBatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOldDataForSalaryBatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOldDataForSalaryBatchQuery({
 *   variables: {
 *      Data: // value for 'Data'
 *   },
 * });
 */
export function useCheckOldDataForSalaryBatchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckOldDataForSalaryBatchQuery, CheckOldDataForSalaryBatchQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckOldDataForSalaryBatchQuery, CheckOldDataForSalaryBatchQueryVariables>(CheckOldDataForSalaryBatchDocument, baseOptions);
      }
export function useCheckOldDataForSalaryBatchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckOldDataForSalaryBatchQuery, CheckOldDataForSalaryBatchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckOldDataForSalaryBatchQuery, CheckOldDataForSalaryBatchQueryVariables>(CheckOldDataForSalaryBatchDocument, baseOptions);
        }
export type CheckOldDataForSalaryBatchQueryHookResult = ReturnType<typeof useCheckOldDataForSalaryBatchQuery>;
export type CheckOldDataForSalaryBatchLazyQueryHookResult = ReturnType<typeof useCheckOldDataForSalaryBatchLazyQuery>;
export type CheckOldDataForSalaryBatchQueryResult = ApolloReactCommon.QueryResult<CheckOldDataForSalaryBatchQuery, CheckOldDataForSalaryBatchQueryVariables>;
export const CheckOldDataForOneTimePayInsertDocument = gql`
    query CheckOldDataForOneTimePayInsert($Data: [JSON!], $PayPeriodID: String) {
  CheckOldDataForOneTimePayInsert(Data: $Data, PayPeriodID: $PayPeriodID)
}
    `;

/**
 * __useCheckOldDataForOneTimePayInsertQuery__
 *
 * To run a query within a React component, call `useCheckOldDataForOneTimePayInsertQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOldDataForOneTimePayInsertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOldDataForOneTimePayInsertQuery({
 *   variables: {
 *      Data: // value for 'Data'
 *      PayPeriodID: // value for 'PayPeriodID'
 *   },
 * });
 */
export function useCheckOldDataForOneTimePayInsertQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckOldDataForOneTimePayInsertQuery, CheckOldDataForOneTimePayInsertQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckOldDataForOneTimePayInsertQuery, CheckOldDataForOneTimePayInsertQueryVariables>(CheckOldDataForOneTimePayInsertDocument, baseOptions);
      }
export function useCheckOldDataForOneTimePayInsertLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckOldDataForOneTimePayInsertQuery, CheckOldDataForOneTimePayInsertQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckOldDataForOneTimePayInsertQuery, CheckOldDataForOneTimePayInsertQueryVariables>(CheckOldDataForOneTimePayInsertDocument, baseOptions);
        }
export type CheckOldDataForOneTimePayInsertQueryHookResult = ReturnType<typeof useCheckOldDataForOneTimePayInsertQuery>;
export type CheckOldDataForOneTimePayInsertLazyQueryHookResult = ReturnType<typeof useCheckOldDataForOneTimePayInsertLazyQuery>;
export type CheckOldDataForOneTimePayInsertQueryResult = ApolloReactCommon.QueryResult<CheckOldDataForOneTimePayInsertQuery, CheckOldDataForOneTimePayInsertQueryVariables>;
export const CheckOldDataForRecurringPayInsertDocument = gql`
    query CheckOldDataForRecurringPayInsert($Data: [JSON!], $CompanyID: String) {
  CheckOldDataForRecurringPayInsert(Data: $Data, CompanyID: $CompanyID)
}
    `;

/**
 * __useCheckOldDataForRecurringPayInsertQuery__
 *
 * To run a query within a React component, call `useCheckOldDataForRecurringPayInsertQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckOldDataForRecurringPayInsertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckOldDataForRecurringPayInsertQuery({
 *   variables: {
 *      Data: // value for 'Data'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCheckOldDataForRecurringPayInsertQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckOldDataForRecurringPayInsertQuery, CheckOldDataForRecurringPayInsertQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckOldDataForRecurringPayInsertQuery, CheckOldDataForRecurringPayInsertQueryVariables>(CheckOldDataForRecurringPayInsertDocument, baseOptions);
      }
export function useCheckOldDataForRecurringPayInsertLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckOldDataForRecurringPayInsertQuery, CheckOldDataForRecurringPayInsertQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckOldDataForRecurringPayInsertQuery, CheckOldDataForRecurringPayInsertQueryVariables>(CheckOldDataForRecurringPayInsertDocument, baseOptions);
        }
export type CheckOldDataForRecurringPayInsertQueryHookResult = ReturnType<typeof useCheckOldDataForRecurringPayInsertQuery>;
export type CheckOldDataForRecurringPayInsertLazyQueryHookResult = ReturnType<typeof useCheckOldDataForRecurringPayInsertLazyQuery>;
export type CheckOldDataForRecurringPayInsertQueryResult = ApolloReactCommon.QueryResult<CheckOldDataForRecurringPayInsertQuery, CheckOldDataForRecurringPayInsertQueryVariables>;
export const StatutoryAdjustmentListingDocument = gql`
    query StatutoryAdjustmentListing($CompanyID: String!, $PayPeriodID: String!, $PayrollCycleIDs: [String!]) {
  StatutoryAdjustmentListing(CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayrollCycleIDs: $PayrollCycleIDs)
}
    `;

/**
 * __useStatutoryAdjustmentListingQuery__
 *
 * To run a query within a React component, call `useStatutoryAdjustmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatutoryAdjustmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatutoryAdjustmentListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleIDs: // value for 'PayrollCycleIDs'
 *   },
 * });
 */
export function useStatutoryAdjustmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatutoryAdjustmentListingQuery, StatutoryAdjustmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StatutoryAdjustmentListingQuery, StatutoryAdjustmentListingQueryVariables>(StatutoryAdjustmentListingDocument, baseOptions);
      }
export function useStatutoryAdjustmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatutoryAdjustmentListingQuery, StatutoryAdjustmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StatutoryAdjustmentListingQuery, StatutoryAdjustmentListingQueryVariables>(StatutoryAdjustmentListingDocument, baseOptions);
        }
export type StatutoryAdjustmentListingQueryHookResult = ReturnType<typeof useStatutoryAdjustmentListingQuery>;
export type StatutoryAdjustmentListingLazyQueryHookResult = ReturnType<typeof useStatutoryAdjustmentListingLazyQuery>;
export type StatutoryAdjustmentListingQueryResult = ApolloReactCommon.QueryResult<StatutoryAdjustmentListingQuery, StatutoryAdjustmentListingQueryVariables>;
export const StatutoryAdjFormEmpListingDocument = gql`
    query StatutoryAdjFormEmpListing($CompanyID: String!, $PayPeriodID: String!, $PayrollCycleID: String!, $ExcludeEmployeeIDs: [String!]) {
  StatutoryAdjFormEmpListing(CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayrollCycleID: $PayrollCycleID, ExcludeEmployeeIDs: $ExcludeEmployeeIDs)
}
    `;

/**
 * __useStatutoryAdjFormEmpListingQuery__
 *
 * To run a query within a React component, call `useStatutoryAdjFormEmpListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatutoryAdjFormEmpListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatutoryAdjFormEmpListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      ExcludeEmployeeIDs: // value for 'ExcludeEmployeeIDs'
 *   },
 * });
 */
export function useStatutoryAdjFormEmpListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatutoryAdjFormEmpListingQuery, StatutoryAdjFormEmpListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StatutoryAdjFormEmpListingQuery, StatutoryAdjFormEmpListingQueryVariables>(StatutoryAdjFormEmpListingDocument, baseOptions);
      }
export function useStatutoryAdjFormEmpListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatutoryAdjFormEmpListingQuery, StatutoryAdjFormEmpListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StatutoryAdjFormEmpListingQuery, StatutoryAdjFormEmpListingQueryVariables>(StatutoryAdjFormEmpListingDocument, baseOptions);
        }
export type StatutoryAdjFormEmpListingQueryHookResult = ReturnType<typeof useStatutoryAdjFormEmpListingQuery>;
export type StatutoryAdjFormEmpListingLazyQueryHookResult = ReturnType<typeof useStatutoryAdjFormEmpListingLazyQuery>;
export type StatutoryAdjFormEmpListingQueryResult = ApolloReactCommon.QueryResult<StatutoryAdjFormEmpListingQuery, StatutoryAdjFormEmpListingQueryVariables>;
export const UpdateStatutoryAdjustmentDocument = gql`
    mutation UpdateStatutoryAdjustment($StatutoryInput: StatutoryAdjustmentInput!, $PayrollHeaderInput: JSON!, $PayrollDetailInputs: [JSON!]) {
  UpdateStatutoryAdjustment(StatutoryInput: $StatutoryInput, PayrollHeaderInput: $PayrollHeaderInput, PayrollDetailInputs: $PayrollDetailInputs)
}
    `;
export type UpdateStatutoryAdjustmentMutationFn = ApolloReactCommon.MutationFunction<UpdateStatutoryAdjustmentMutation, UpdateStatutoryAdjustmentMutationVariables>;

/**
 * __useUpdateStatutoryAdjustmentMutation__
 *
 * To run a mutation, you first call `useUpdateStatutoryAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStatutoryAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStatutoryAdjustmentMutation, { data, loading, error }] = useUpdateStatutoryAdjustmentMutation({
 *   variables: {
 *      StatutoryInput: // value for 'StatutoryInput'
 *      PayrollHeaderInput: // value for 'PayrollHeaderInput'
 *      PayrollDetailInputs: // value for 'PayrollDetailInputs'
 *   },
 * });
 */
export function useUpdateStatutoryAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStatutoryAdjustmentMutation, UpdateStatutoryAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStatutoryAdjustmentMutation, UpdateStatutoryAdjustmentMutationVariables>(UpdateStatutoryAdjustmentDocument, baseOptions);
      }
export type UpdateStatutoryAdjustmentMutationHookResult = ReturnType<typeof useUpdateStatutoryAdjustmentMutation>;
export type UpdateStatutoryAdjustmentMutationResult = ApolloReactCommon.MutationResult<UpdateStatutoryAdjustmentMutation>;
export type UpdateStatutoryAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStatutoryAdjustmentMutation, UpdateStatutoryAdjustmentMutationVariables>;
export const DeleteStatutoryAdjustmentDocument = gql`
    mutation DeleteStatutoryAdjustment($PayrollHeaderID: String!) {
  DeleteStatutoryAdjustment(PayrollHeaderID: $PayrollHeaderID)
}
    `;
export type DeleteStatutoryAdjustmentMutationFn = ApolloReactCommon.MutationFunction<DeleteStatutoryAdjustmentMutation, DeleteStatutoryAdjustmentMutationVariables>;

/**
 * __useDeleteStatutoryAdjustmentMutation__
 *
 * To run a mutation, you first call `useDeleteStatutoryAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStatutoryAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStatutoryAdjustmentMutation, { data, loading, error }] = useDeleteStatutoryAdjustmentMutation({
 *   variables: {
 *      PayrollHeaderID: // value for 'PayrollHeaderID'
 *   },
 * });
 */
export function useDeleteStatutoryAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteStatutoryAdjustmentMutation, DeleteStatutoryAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteStatutoryAdjustmentMutation, DeleteStatutoryAdjustmentMutationVariables>(DeleteStatutoryAdjustmentDocument, baseOptions);
      }
export type DeleteStatutoryAdjustmentMutationHookResult = ReturnType<typeof useDeleteStatutoryAdjustmentMutation>;
export type DeleteStatutoryAdjustmentMutationResult = ApolloReactCommon.MutationResult<DeleteStatutoryAdjustmentMutation>;
export type DeleteStatutoryAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteStatutoryAdjustmentMutation, DeleteStatutoryAdjustmentMutationVariables>;
export const IsPayrollCycleLockedDocument = gql`
    query IsPayrollCycleLocked($CompanyID: String!, $PayPeriodID: String!, $PayrollCycleID: String!) {
  IsPayrollCycleLocked(CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayrollCycleID: $PayrollCycleID)
}
    `;

/**
 * __useIsPayrollCycleLockedQuery__
 *
 * To run a query within a React component, call `useIsPayrollCycleLockedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPayrollCycleLockedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPayrollCycleLockedQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *   },
 * });
 */
export function useIsPayrollCycleLockedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsPayrollCycleLockedQuery, IsPayrollCycleLockedQueryVariables>) {
        return ApolloReactHooks.useQuery<IsPayrollCycleLockedQuery, IsPayrollCycleLockedQueryVariables>(IsPayrollCycleLockedDocument, baseOptions);
      }
export function useIsPayrollCycleLockedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsPayrollCycleLockedQuery, IsPayrollCycleLockedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsPayrollCycleLockedQuery, IsPayrollCycleLockedQueryVariables>(IsPayrollCycleLockedDocument, baseOptions);
        }
export type IsPayrollCycleLockedQueryHookResult = ReturnType<typeof useIsPayrollCycleLockedQuery>;
export type IsPayrollCycleLockedLazyQueryHookResult = ReturnType<typeof useIsPayrollCycleLockedLazyQuery>;
export type IsPayrollCycleLockedQueryResult = ApolloReactCommon.QueryResult<IsPayrollCycleLockedQuery, IsPayrollCycleLockedQueryVariables>;
export const PerformanceMenuSummaryDocument = gql`
    query PerformanceMenuSummary {
  PerformanceMenuSummary
}
    `;

/**
 * __usePerformanceMenuSummaryQuery__
 *
 * To run a query within a React component, call `usePerformanceMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformanceMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformanceMenuSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePerformanceMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PerformanceMenuSummaryQuery, PerformanceMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<PerformanceMenuSummaryQuery, PerformanceMenuSummaryQueryVariables>(PerformanceMenuSummaryDocument, baseOptions);
      }
export function usePerformanceMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PerformanceMenuSummaryQuery, PerformanceMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PerformanceMenuSummaryQuery, PerformanceMenuSummaryQueryVariables>(PerformanceMenuSummaryDocument, baseOptions);
        }
export type PerformanceMenuSummaryQueryHookResult = ReturnType<typeof usePerformanceMenuSummaryQuery>;
export type PerformanceMenuSummaryLazyQueryHookResult = ReturnType<typeof usePerformanceMenuSummaryLazyQuery>;
export type PerformanceMenuSummaryQueryResult = ApolloReactCommon.QueryResult<PerformanceMenuSummaryQuery, PerformanceMenuSummaryQueryVariables>;
export const FormItemRatingListingDocument = gql`
    query FormItemRatingListing {
  FormItemRatingListing {
    ID
    Title
    InUse
    RatingScaleTable
  }
}
    `;

/**
 * __useFormItemRatingListingQuery__
 *
 * To run a query within a React component, call `useFormItemRatingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormItemRatingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormItemRatingListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormItemRatingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormItemRatingListingQuery, FormItemRatingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FormItemRatingListingQuery, FormItemRatingListingQueryVariables>(FormItemRatingListingDocument, baseOptions);
      }
export function useFormItemRatingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormItemRatingListingQuery, FormItemRatingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FormItemRatingListingQuery, FormItemRatingListingQueryVariables>(FormItemRatingListingDocument, baseOptions);
        }
export type FormItemRatingListingQueryHookResult = ReturnType<typeof useFormItemRatingListingQuery>;
export type FormItemRatingListingLazyQueryHookResult = ReturnType<typeof useFormItemRatingListingLazyQuery>;
export type FormItemRatingListingQueryResult = ApolloReactCommon.QueryResult<FormItemRatingListingQuery, FormItemRatingListingQueryVariables>;
export const FormItemRatingDetailsDocument = gql`
    query FormItemRatingDetails($FormItemRatingID: String!) {
  FormItemRatingDetails(FormItemRatingID: $FormItemRatingID) {
    ID
    Title
    To
    From
    OrderType
    RatingScaleTable
  }
}
    `;

/**
 * __useFormItemRatingDetailsQuery__
 *
 * To run a query within a React component, call `useFormItemRatingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormItemRatingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormItemRatingDetailsQuery({
 *   variables: {
 *      FormItemRatingID: // value for 'FormItemRatingID'
 *   },
 * });
 */
export function useFormItemRatingDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormItemRatingDetailsQuery, FormItemRatingDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<FormItemRatingDetailsQuery, FormItemRatingDetailsQueryVariables>(FormItemRatingDetailsDocument, baseOptions);
      }
export function useFormItemRatingDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormItemRatingDetailsQuery, FormItemRatingDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FormItemRatingDetailsQuery, FormItemRatingDetailsQueryVariables>(FormItemRatingDetailsDocument, baseOptions);
        }
export type FormItemRatingDetailsQueryHookResult = ReturnType<typeof useFormItemRatingDetailsQuery>;
export type FormItemRatingDetailsLazyQueryHookResult = ReturnType<typeof useFormItemRatingDetailsLazyQuery>;
export type FormItemRatingDetailsQueryResult = ApolloReactCommon.QueryResult<FormItemRatingDetailsQuery, FormItemRatingDetailsQueryVariables>;
export const IsFormItemRatingTitleExistDocument = gql`
    query IsFormItemRatingTitleExist($Title: String!, $FormItemRatingID: String) {
  IsFormItemRatingTitleExist(Title: $Title, FormItemRatingID: $FormItemRatingID)
}
    `;

/**
 * __useIsFormItemRatingTitleExistQuery__
 *
 * To run a query within a React component, call `useIsFormItemRatingTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsFormItemRatingTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsFormItemRatingTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      FormItemRatingID: // value for 'FormItemRatingID'
 *   },
 * });
 */
export function useIsFormItemRatingTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsFormItemRatingTitleExistQuery, IsFormItemRatingTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsFormItemRatingTitleExistQuery, IsFormItemRatingTitleExistQueryVariables>(IsFormItemRatingTitleExistDocument, baseOptions);
      }
export function useIsFormItemRatingTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsFormItemRatingTitleExistQuery, IsFormItemRatingTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsFormItemRatingTitleExistQuery, IsFormItemRatingTitleExistQueryVariables>(IsFormItemRatingTitleExistDocument, baseOptions);
        }
export type IsFormItemRatingTitleExistQueryHookResult = ReturnType<typeof useIsFormItemRatingTitleExistQuery>;
export type IsFormItemRatingTitleExistLazyQueryHookResult = ReturnType<typeof useIsFormItemRatingTitleExistLazyQuery>;
export type IsFormItemRatingTitleExistQueryResult = ApolloReactCommon.QueryResult<IsFormItemRatingTitleExistQuery, IsFormItemRatingTitleExistQueryVariables>;
export const FormItemRatingSubmitDocument = gql`
    mutation FormItemRatingSubmit($input: FormItemRatingInput!) {
  FormItemRatingSubmit(input: $input)
}
    `;
export type FormItemRatingSubmitMutationFn = ApolloReactCommon.MutationFunction<FormItemRatingSubmitMutation, FormItemRatingSubmitMutationVariables>;

/**
 * __useFormItemRatingSubmitMutation__
 *
 * To run a mutation, you first call `useFormItemRatingSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormItemRatingSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formItemRatingSubmitMutation, { data, loading, error }] = useFormItemRatingSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFormItemRatingSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FormItemRatingSubmitMutation, FormItemRatingSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<FormItemRatingSubmitMutation, FormItemRatingSubmitMutationVariables>(FormItemRatingSubmitDocument, baseOptions);
      }
export type FormItemRatingSubmitMutationHookResult = ReturnType<typeof useFormItemRatingSubmitMutation>;
export type FormItemRatingSubmitMutationResult = ApolloReactCommon.MutationResult<FormItemRatingSubmitMutation>;
export type FormItemRatingSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<FormItemRatingSubmitMutation, FormItemRatingSubmitMutationVariables>;
export const FormItemRatingDeleteDocument = gql`
    mutation FormItemRatingDelete($FormItemRatingID: String!) {
  FormItemRatingDelete(FormItemRatingID: $FormItemRatingID)
}
    `;
export type FormItemRatingDeleteMutationFn = ApolloReactCommon.MutationFunction<FormItemRatingDeleteMutation, FormItemRatingDeleteMutationVariables>;

/**
 * __useFormItemRatingDeleteMutation__
 *
 * To run a mutation, you first call `useFormItemRatingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormItemRatingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formItemRatingDeleteMutation, { data, loading, error }] = useFormItemRatingDeleteMutation({
 *   variables: {
 *      FormItemRatingID: // value for 'FormItemRatingID'
 *   },
 * });
 */
export function useFormItemRatingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FormItemRatingDeleteMutation, FormItemRatingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<FormItemRatingDeleteMutation, FormItemRatingDeleteMutationVariables>(FormItemRatingDeleteDocument, baseOptions);
      }
export type FormItemRatingDeleteMutationHookResult = ReturnType<typeof useFormItemRatingDeleteMutation>;
export type FormItemRatingDeleteMutationResult = ApolloReactCommon.MutationResult<FormItemRatingDeleteMutation>;
export type FormItemRatingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<FormItemRatingDeleteMutation, FormItemRatingDeleteMutationVariables>;
export const ReviewGradingTableListingDocument = gql`
    query ReviewGradingTableListing {
  ReviewGradingTableListing {
    ID
    Title
    InUse
    GradingTable
  }
}
    `;

/**
 * __useReviewGradingTableListingQuery__
 *
 * To run a query within a React component, call `useReviewGradingTableListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewGradingTableListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewGradingTableListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewGradingTableListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewGradingTableListingQuery, ReviewGradingTableListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewGradingTableListingQuery, ReviewGradingTableListingQueryVariables>(ReviewGradingTableListingDocument, baseOptions);
      }
export function useReviewGradingTableListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewGradingTableListingQuery, ReviewGradingTableListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewGradingTableListingQuery, ReviewGradingTableListingQueryVariables>(ReviewGradingTableListingDocument, baseOptions);
        }
export type ReviewGradingTableListingQueryHookResult = ReturnType<typeof useReviewGradingTableListingQuery>;
export type ReviewGradingTableListingLazyQueryHookResult = ReturnType<typeof useReviewGradingTableListingLazyQuery>;
export type ReviewGradingTableListingQueryResult = ApolloReactCommon.QueryResult<ReviewGradingTableListingQuery, ReviewGradingTableListingQueryVariables>;
export const ReviewGradingTableDetailsDocument = gql`
    query ReviewGradingTableDetails($ReviewGradingTableID: String!) {
  ReviewGradingTableDetails(ReviewGradingTableID: $ReviewGradingTableID) {
    ID
    Title
    GradingTable
  }
}
    `;

/**
 * __useReviewGradingTableDetailsQuery__
 *
 * To run a query within a React component, call `useReviewGradingTableDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewGradingTableDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewGradingTableDetailsQuery({
 *   variables: {
 *      ReviewGradingTableID: // value for 'ReviewGradingTableID'
 *   },
 * });
 */
export function useReviewGradingTableDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewGradingTableDetailsQuery, ReviewGradingTableDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewGradingTableDetailsQuery, ReviewGradingTableDetailsQueryVariables>(ReviewGradingTableDetailsDocument, baseOptions);
      }
export function useReviewGradingTableDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewGradingTableDetailsQuery, ReviewGradingTableDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewGradingTableDetailsQuery, ReviewGradingTableDetailsQueryVariables>(ReviewGradingTableDetailsDocument, baseOptions);
        }
export type ReviewGradingTableDetailsQueryHookResult = ReturnType<typeof useReviewGradingTableDetailsQuery>;
export type ReviewGradingTableDetailsLazyQueryHookResult = ReturnType<typeof useReviewGradingTableDetailsLazyQuery>;
export type ReviewGradingTableDetailsQueryResult = ApolloReactCommon.QueryResult<ReviewGradingTableDetailsQuery, ReviewGradingTableDetailsQueryVariables>;
export const IsReviewGradingTitleExistDocument = gql`
    query IsReviewGradingTitleExist($Title: String!, $ReviewGradingTableID: String) {
  IsReviewGradingTitleExist(Title: $Title, ReviewGradingTableID: $ReviewGradingTableID)
}
    `;

/**
 * __useIsReviewGradingTitleExistQuery__
 *
 * To run a query within a React component, call `useIsReviewGradingTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewGradingTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewGradingTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReviewGradingTableID: // value for 'ReviewGradingTableID'
 *   },
 * });
 */
export function useIsReviewGradingTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewGradingTitleExistQuery, IsReviewGradingTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewGradingTitleExistQuery, IsReviewGradingTitleExistQueryVariables>(IsReviewGradingTitleExistDocument, baseOptions);
      }
export function useIsReviewGradingTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewGradingTitleExistQuery, IsReviewGradingTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewGradingTitleExistQuery, IsReviewGradingTitleExistQueryVariables>(IsReviewGradingTitleExistDocument, baseOptions);
        }
export type IsReviewGradingTitleExistQueryHookResult = ReturnType<typeof useIsReviewGradingTitleExistQuery>;
export type IsReviewGradingTitleExistLazyQueryHookResult = ReturnType<typeof useIsReviewGradingTitleExistLazyQuery>;
export type IsReviewGradingTitleExistQueryResult = ApolloReactCommon.QueryResult<IsReviewGradingTitleExistQuery, IsReviewGradingTitleExistQueryVariables>;
export const ReviewGradingTableSubmitDocument = gql`
    mutation ReviewGradingTableSubmit($input: ReviewGradingTableInput!) {
  ReviewGradingTableSubmit(input: $input)
}
    `;
export type ReviewGradingTableSubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewGradingTableSubmitMutation, ReviewGradingTableSubmitMutationVariables>;

/**
 * __useReviewGradingTableSubmitMutation__
 *
 * To run a mutation, you first call `useReviewGradingTableSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewGradingTableSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewGradingTableSubmitMutation, { data, loading, error }] = useReviewGradingTableSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewGradingTableSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewGradingTableSubmitMutation, ReviewGradingTableSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewGradingTableSubmitMutation, ReviewGradingTableSubmitMutationVariables>(ReviewGradingTableSubmitDocument, baseOptions);
      }
export type ReviewGradingTableSubmitMutationHookResult = ReturnType<typeof useReviewGradingTableSubmitMutation>;
export type ReviewGradingTableSubmitMutationResult = ApolloReactCommon.MutationResult<ReviewGradingTableSubmitMutation>;
export type ReviewGradingTableSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewGradingTableSubmitMutation, ReviewGradingTableSubmitMutationVariables>;
export const ReviewGradingTableDeleteDocument = gql`
    mutation ReviewGradingTableDelete($ReviewGradingTableID: String!) {
  ReviewGradingTableDelete(ReviewGradingTableID: $ReviewGradingTableID)
}
    `;
export type ReviewGradingTableDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewGradingTableDeleteMutation, ReviewGradingTableDeleteMutationVariables>;

/**
 * __useReviewGradingTableDeleteMutation__
 *
 * To run a mutation, you first call `useReviewGradingTableDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewGradingTableDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewGradingTableDeleteMutation, { data, loading, error }] = useReviewGradingTableDeleteMutation({
 *   variables: {
 *      ReviewGradingTableID: // value for 'ReviewGradingTableID'
 *   },
 * });
 */
export function useReviewGradingTableDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewGradingTableDeleteMutation, ReviewGradingTableDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewGradingTableDeleteMutation, ReviewGradingTableDeleteMutationVariables>(ReviewGradingTableDeleteDocument, baseOptions);
      }
export type ReviewGradingTableDeleteMutationHookResult = ReturnType<typeof useReviewGradingTableDeleteMutation>;
export type ReviewGradingTableDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewGradingTableDeleteMutation>;
export type ReviewGradingTableDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewGradingTableDeleteMutation, ReviewGradingTableDeleteMutationVariables>;
export const ReviewPolicyListingDocument = gql`
    query ReviewPolicyListing {
  ReviewPolicyListing {
    ID
    Title
    InUse
    ModifiedDT
  }
}
    `;

/**
 * __useReviewPolicyListingQuery__
 *
 * To run a query within a React component, call `useReviewPolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewPolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewPolicyListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewPolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewPolicyListingQuery, ReviewPolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewPolicyListingQuery, ReviewPolicyListingQueryVariables>(ReviewPolicyListingDocument, baseOptions);
      }
export function useReviewPolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewPolicyListingQuery, ReviewPolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewPolicyListingQuery, ReviewPolicyListingQueryVariables>(ReviewPolicyListingDocument, baseOptions);
        }
export type ReviewPolicyListingQueryHookResult = ReturnType<typeof useReviewPolicyListingQuery>;
export type ReviewPolicyListingLazyQueryHookResult = ReturnType<typeof useReviewPolicyListingLazyQuery>;
export type ReviewPolicyListingQueryResult = ApolloReactCommon.QueryResult<ReviewPolicyListingQuery, ReviewPolicyListingQueryVariables>;
export const ReviewPolicyDetailsDocument = gql`
    query ReviewPolicyDetails($ReviewPolicyID: String!) {
  ReviewPolicyDetails(ReviewPolicyID: $ReviewPolicyID) {
    ID
    Title
    ReviewPolicyDetails
    ReviewGrading {
      ID
      Title
    }
  }
}
    `;

/**
 * __useReviewPolicyDetailsQuery__
 *
 * To run a query within a React component, call `useReviewPolicyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewPolicyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewPolicyDetailsQuery({
 *   variables: {
 *      ReviewPolicyID: // value for 'ReviewPolicyID'
 *   },
 * });
 */
export function useReviewPolicyDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewPolicyDetailsQuery, ReviewPolicyDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewPolicyDetailsQuery, ReviewPolicyDetailsQueryVariables>(ReviewPolicyDetailsDocument, baseOptions);
      }
export function useReviewPolicyDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewPolicyDetailsQuery, ReviewPolicyDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewPolicyDetailsQuery, ReviewPolicyDetailsQueryVariables>(ReviewPolicyDetailsDocument, baseOptions);
        }
export type ReviewPolicyDetailsQueryHookResult = ReturnType<typeof useReviewPolicyDetailsQuery>;
export type ReviewPolicyDetailsLazyQueryHookResult = ReturnType<typeof useReviewPolicyDetailsLazyQuery>;
export type ReviewPolicyDetailsQueryResult = ApolloReactCommon.QueryResult<ReviewPolicyDetailsQuery, ReviewPolicyDetailsQueryVariables>;
export const IsReviewPolicyTitleExistDocument = gql`
    query IsReviewPolicyTitleExist($Title: String!, $ReviewPolicyID: String) {
  IsReviewPolicyTitleExist(Title: $Title, ReviewPolicyID: $ReviewPolicyID)
}
    `;

/**
 * __useIsReviewPolicyTitleExistQuery__
 *
 * To run a query within a React component, call `useIsReviewPolicyTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewPolicyTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewPolicyTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReviewPolicyID: // value for 'ReviewPolicyID'
 *   },
 * });
 */
export function useIsReviewPolicyTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewPolicyTitleExistQuery, IsReviewPolicyTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewPolicyTitleExistQuery, IsReviewPolicyTitleExistQueryVariables>(IsReviewPolicyTitleExistDocument, baseOptions);
      }
export function useIsReviewPolicyTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewPolicyTitleExistQuery, IsReviewPolicyTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewPolicyTitleExistQuery, IsReviewPolicyTitleExistQueryVariables>(IsReviewPolicyTitleExistDocument, baseOptions);
        }
export type IsReviewPolicyTitleExistQueryHookResult = ReturnType<typeof useIsReviewPolicyTitleExistQuery>;
export type IsReviewPolicyTitleExistLazyQueryHookResult = ReturnType<typeof useIsReviewPolicyTitleExistLazyQuery>;
export type IsReviewPolicyTitleExistQueryResult = ApolloReactCommon.QueryResult<IsReviewPolicyTitleExistQuery, IsReviewPolicyTitleExistQueryVariables>;
export const ReviewPolicySubmitDocument = gql`
    mutation ReviewPolicySubmit($input: ReviewPolicyInput!) {
  ReviewPolicySubmit(input: $input)
}
    `;
export type ReviewPolicySubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewPolicySubmitMutation, ReviewPolicySubmitMutationVariables>;

/**
 * __useReviewPolicySubmitMutation__
 *
 * To run a mutation, you first call `useReviewPolicySubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewPolicySubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewPolicySubmitMutation, { data, loading, error }] = useReviewPolicySubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewPolicySubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewPolicySubmitMutation, ReviewPolicySubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewPolicySubmitMutation, ReviewPolicySubmitMutationVariables>(ReviewPolicySubmitDocument, baseOptions);
      }
export type ReviewPolicySubmitMutationHookResult = ReturnType<typeof useReviewPolicySubmitMutation>;
export type ReviewPolicySubmitMutationResult = ApolloReactCommon.MutationResult<ReviewPolicySubmitMutation>;
export type ReviewPolicySubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewPolicySubmitMutation, ReviewPolicySubmitMutationVariables>;
export const ReviewPolicyDeleteDocument = gql`
    mutation ReviewPolicyDelete($ReviewPolicyID: String!) {
  ReviewPolicyDelete(ReviewPolicyID: $ReviewPolicyID)
}
    `;
export type ReviewPolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewPolicyDeleteMutation, ReviewPolicyDeleteMutationVariables>;

/**
 * __useReviewPolicyDeleteMutation__
 *
 * To run a mutation, you first call `useReviewPolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewPolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewPolicyDeleteMutation, { data, loading, error }] = useReviewPolicyDeleteMutation({
 *   variables: {
 *      ReviewPolicyID: // value for 'ReviewPolicyID'
 *   },
 * });
 */
export function useReviewPolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewPolicyDeleteMutation, ReviewPolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewPolicyDeleteMutation, ReviewPolicyDeleteMutationVariables>(ReviewPolicyDeleteDocument, baseOptions);
      }
export type ReviewPolicyDeleteMutationHookResult = ReturnType<typeof useReviewPolicyDeleteMutation>;
export type ReviewPolicyDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewPolicyDeleteMutation>;
export type ReviewPolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewPolicyDeleteMutation, ReviewPolicyDeleteMutationVariables>;
export const KpiCategoryListingDocument = gql`
    query KpiCategoryListing {
  KpiCategoryListing {
    ID
    Title
    InUse
    ModifiedDT
    PredefinedItemCount
  }
}
    `;

/**
 * __useKpiCategoryListingQuery__
 *
 * To run a query within a React component, call `useKpiCategoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiCategoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiCategoryListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useKpiCategoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiCategoryListingQuery, KpiCategoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiCategoryListingQuery, KpiCategoryListingQueryVariables>(KpiCategoryListingDocument, baseOptions);
      }
export function useKpiCategoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiCategoryListingQuery, KpiCategoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiCategoryListingQuery, KpiCategoryListingQueryVariables>(KpiCategoryListingDocument, baseOptions);
        }
export type KpiCategoryListingQueryHookResult = ReturnType<typeof useKpiCategoryListingQuery>;
export type KpiCategoryListingLazyQueryHookResult = ReturnType<typeof useKpiCategoryListingLazyQuery>;
export type KpiCategoryListingQueryResult = ApolloReactCommon.QueryResult<KpiCategoryListingQuery, KpiCategoryListingQueryVariables>;
export const KpiCategoryDetailsDocument = gql`
    query KpiCategoryDetails($KpiCategoryID: String!) {
  KpiCategoryDetails(KpiCategoryID: $KpiCategoryID) {
    ID
    Title
    PredefinedItemCount
  }
}
    `;

/**
 * __useKpiCategoryDetailsQuery__
 *
 * To run a query within a React component, call `useKpiCategoryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiCategoryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiCategoryDetailsQuery({
 *   variables: {
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *   },
 * });
 */
export function useKpiCategoryDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiCategoryDetailsQuery, KpiCategoryDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiCategoryDetailsQuery, KpiCategoryDetailsQueryVariables>(KpiCategoryDetailsDocument, baseOptions);
      }
export function useKpiCategoryDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiCategoryDetailsQuery, KpiCategoryDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiCategoryDetailsQuery, KpiCategoryDetailsQueryVariables>(KpiCategoryDetailsDocument, baseOptions);
        }
export type KpiCategoryDetailsQueryHookResult = ReturnType<typeof useKpiCategoryDetailsQuery>;
export type KpiCategoryDetailsLazyQueryHookResult = ReturnType<typeof useKpiCategoryDetailsLazyQuery>;
export type KpiCategoryDetailsQueryResult = ApolloReactCommon.QueryResult<KpiCategoryDetailsQuery, KpiCategoryDetailsQueryVariables>;
export const IsKpiCategoryTitleExistDocument = gql`
    query IsKpiCategoryTitleExist($Title: String!, $KpiCategoryID: String) {
  IsKpiCategoryTitleExist(Title: $Title, KpiCategoryID: $KpiCategoryID)
}
    `;

/**
 * __useIsKpiCategoryTitleExistQuery__
 *
 * To run a query within a React component, call `useIsKpiCategoryTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsKpiCategoryTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsKpiCategoryTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *   },
 * });
 */
export function useIsKpiCategoryTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsKpiCategoryTitleExistQuery, IsKpiCategoryTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsKpiCategoryTitleExistQuery, IsKpiCategoryTitleExistQueryVariables>(IsKpiCategoryTitleExistDocument, baseOptions);
      }
export function useIsKpiCategoryTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsKpiCategoryTitleExistQuery, IsKpiCategoryTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsKpiCategoryTitleExistQuery, IsKpiCategoryTitleExistQueryVariables>(IsKpiCategoryTitleExistDocument, baseOptions);
        }
export type IsKpiCategoryTitleExistQueryHookResult = ReturnType<typeof useIsKpiCategoryTitleExistQuery>;
export type IsKpiCategoryTitleExistLazyQueryHookResult = ReturnType<typeof useIsKpiCategoryTitleExistLazyQuery>;
export type IsKpiCategoryTitleExistQueryResult = ApolloReactCommon.QueryResult<IsKpiCategoryTitleExistQuery, IsKpiCategoryTitleExistQueryVariables>;
export const KpiCategorySubmitDocument = gql`
    mutation KpiCategorySubmit($input: KpiCategoryInput!) {
  KpiCategorySubmit(input: $input)
}
    `;
export type KpiCategorySubmitMutationFn = ApolloReactCommon.MutationFunction<KpiCategorySubmitMutation, KpiCategorySubmitMutationVariables>;

/**
 * __useKpiCategorySubmitMutation__
 *
 * To run a mutation, you first call `useKpiCategorySubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiCategorySubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiCategorySubmitMutation, { data, loading, error }] = useKpiCategorySubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKpiCategorySubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiCategorySubmitMutation, KpiCategorySubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiCategorySubmitMutation, KpiCategorySubmitMutationVariables>(KpiCategorySubmitDocument, baseOptions);
      }
export type KpiCategorySubmitMutationHookResult = ReturnType<typeof useKpiCategorySubmitMutation>;
export type KpiCategorySubmitMutationResult = ApolloReactCommon.MutationResult<KpiCategorySubmitMutation>;
export type KpiCategorySubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiCategorySubmitMutation, KpiCategorySubmitMutationVariables>;
export const KpiCategoryDeleteDocument = gql`
    mutation KpiCategoryDelete($KpiCategoryID: String!) {
  KpiCategoryDelete(KpiCategoryID: $KpiCategoryID)
}
    `;
export type KpiCategoryDeleteMutationFn = ApolloReactCommon.MutationFunction<KpiCategoryDeleteMutation, KpiCategoryDeleteMutationVariables>;

/**
 * __useKpiCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useKpiCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiCategoryDeleteMutation, { data, loading, error }] = useKpiCategoryDeleteMutation({
 *   variables: {
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *   },
 * });
 */
export function useKpiCategoryDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiCategoryDeleteMutation, KpiCategoryDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiCategoryDeleteMutation, KpiCategoryDeleteMutationVariables>(KpiCategoryDeleteDocument, baseOptions);
      }
export type KpiCategoryDeleteMutationHookResult = ReturnType<typeof useKpiCategoryDeleteMutation>;
export type KpiCategoryDeleteMutationResult = ApolloReactCommon.MutationResult<KpiCategoryDeleteMutation>;
export type KpiCategoryDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiCategoryDeleteMutation, KpiCategoryDeleteMutationVariables>;
export const KpiCategoryListingWithItemCountDocument = gql`
    query KpiCategoryListingWithItemCount {
  KpiCategoryListing {
    ID
    Title
    PredefinedItemCount
  }
}
    `;

/**
 * __useKpiCategoryListingWithItemCountQuery__
 *
 * To run a query within a React component, call `useKpiCategoryListingWithItemCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiCategoryListingWithItemCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiCategoryListingWithItemCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useKpiCategoryListingWithItemCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiCategoryListingWithItemCountQuery, KpiCategoryListingWithItemCountQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiCategoryListingWithItemCountQuery, KpiCategoryListingWithItemCountQueryVariables>(KpiCategoryListingWithItemCountDocument, baseOptions);
      }
export function useKpiCategoryListingWithItemCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiCategoryListingWithItemCountQuery, KpiCategoryListingWithItemCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiCategoryListingWithItemCountQuery, KpiCategoryListingWithItemCountQueryVariables>(KpiCategoryListingWithItemCountDocument, baseOptions);
        }
export type KpiCategoryListingWithItemCountQueryHookResult = ReturnType<typeof useKpiCategoryListingWithItemCountQuery>;
export type KpiCategoryListingWithItemCountLazyQueryHookResult = ReturnType<typeof useKpiCategoryListingWithItemCountLazyQuery>;
export type KpiCategoryListingWithItemCountQueryResult = ApolloReactCommon.QueryResult<KpiCategoryListingWithItemCountQuery, KpiCategoryListingWithItemCountQueryVariables>;
export const KpiPredefinedItemListingDocument = gql`
    query KpiPredefinedItemListing($KpiCategoryID: String!) {
  KpiPredefinedItemListing(KpiCategoryID: $KpiCategoryID) {
    ID
    Title
    Targets
    Initiatives
    InUse
    Status
  }
}
    `;

/**
 * __useKpiPredefinedItemListingQuery__
 *
 * To run a query within a React component, call `useKpiPredefinedItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiPredefinedItemListingQuery({
 *   variables: {
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *   },
 * });
 */
export function useKpiPredefinedItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiPredefinedItemListingQuery, KpiPredefinedItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiPredefinedItemListingQuery, KpiPredefinedItemListingQueryVariables>(KpiPredefinedItemListingDocument, baseOptions);
      }
export function useKpiPredefinedItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiPredefinedItemListingQuery, KpiPredefinedItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiPredefinedItemListingQuery, KpiPredefinedItemListingQueryVariables>(KpiPredefinedItemListingDocument, baseOptions);
        }
export type KpiPredefinedItemListingQueryHookResult = ReturnType<typeof useKpiPredefinedItemListingQuery>;
export type KpiPredefinedItemListingLazyQueryHookResult = ReturnType<typeof useKpiPredefinedItemListingLazyQuery>;
export type KpiPredefinedItemListingQueryResult = ApolloReactCommon.QueryResult<KpiPredefinedItemListingQuery, KpiPredefinedItemListingQueryVariables>;
export const KpiPredefinedItemDetailsDocument = gql`
    query KpiPredefinedItemDetails($KpiPredefinedItemID: String!) {
  KpiPredefinedItemDetails(KpiPredefinedItemID: $KpiPredefinedItemID) {
    ID
    Title
    FormItemRating {
      ID
      Title
    }
    Targets
    Initiatives
  }
}
    `;

/**
 * __useKpiPredefinedItemDetailsQuery__
 *
 * To run a query within a React component, call `useKpiPredefinedItemDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedItemDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiPredefinedItemDetailsQuery({
 *   variables: {
 *      KpiPredefinedItemID: // value for 'KpiPredefinedItemID'
 *   },
 * });
 */
export function useKpiPredefinedItemDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiPredefinedItemDetailsQuery, KpiPredefinedItemDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiPredefinedItemDetailsQuery, KpiPredefinedItemDetailsQueryVariables>(KpiPredefinedItemDetailsDocument, baseOptions);
      }
export function useKpiPredefinedItemDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiPredefinedItemDetailsQuery, KpiPredefinedItemDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiPredefinedItemDetailsQuery, KpiPredefinedItemDetailsQueryVariables>(KpiPredefinedItemDetailsDocument, baseOptions);
        }
export type KpiPredefinedItemDetailsQueryHookResult = ReturnType<typeof useKpiPredefinedItemDetailsQuery>;
export type KpiPredefinedItemDetailsLazyQueryHookResult = ReturnType<typeof useKpiPredefinedItemDetailsLazyQuery>;
export type KpiPredefinedItemDetailsQueryResult = ApolloReactCommon.QueryResult<KpiPredefinedItemDetailsQuery, KpiPredefinedItemDetailsQueryVariables>;
export const IsKpiPredefinedItemTitleExistDocument = gql`
    query IsKpiPredefinedItemTitleExist($Title: String!, $KpiCategoryID: String!, $KpiPredefinedItemID: String) {
  IsKpiPredefinedItemTitleExist(Title: $Title, KpiCategoryID: $KpiCategoryID, KpiPredefinedItemID: $KpiPredefinedItemID)
}
    `;

/**
 * __useIsKpiPredefinedItemTitleExistQuery__
 *
 * To run a query within a React component, call `useIsKpiPredefinedItemTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsKpiPredefinedItemTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsKpiPredefinedItemTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      KpiCategoryID: // value for 'KpiCategoryID'
 *      KpiPredefinedItemID: // value for 'KpiPredefinedItemID'
 *   },
 * });
 */
export function useIsKpiPredefinedItemTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsKpiPredefinedItemTitleExistQuery, IsKpiPredefinedItemTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsKpiPredefinedItemTitleExistQuery, IsKpiPredefinedItemTitleExistQueryVariables>(IsKpiPredefinedItemTitleExistDocument, baseOptions);
      }
export function useIsKpiPredefinedItemTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsKpiPredefinedItemTitleExistQuery, IsKpiPredefinedItemTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsKpiPredefinedItemTitleExistQuery, IsKpiPredefinedItemTitleExistQueryVariables>(IsKpiPredefinedItemTitleExistDocument, baseOptions);
        }
export type IsKpiPredefinedItemTitleExistQueryHookResult = ReturnType<typeof useIsKpiPredefinedItemTitleExistQuery>;
export type IsKpiPredefinedItemTitleExistLazyQueryHookResult = ReturnType<typeof useIsKpiPredefinedItemTitleExistLazyQuery>;
export type IsKpiPredefinedItemTitleExistQueryResult = ApolloReactCommon.QueryResult<IsKpiPredefinedItemTitleExistQuery, IsKpiPredefinedItemTitleExistQueryVariables>;
export const KpiPredefinedItemSubmitDocument = gql`
    mutation KpiPredefinedItemSubmit($input: KpiPredefinedItemInput!) {
  KpiPredefinedItemSubmit(input: $input)
}
    `;
export type KpiPredefinedItemSubmitMutationFn = ApolloReactCommon.MutationFunction<KpiPredefinedItemSubmitMutation, KpiPredefinedItemSubmitMutationVariables>;

/**
 * __useKpiPredefinedItemSubmitMutation__
 *
 * To run a mutation, you first call `useKpiPredefinedItemSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedItemSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiPredefinedItemSubmitMutation, { data, loading, error }] = useKpiPredefinedItemSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKpiPredefinedItemSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiPredefinedItemSubmitMutation, KpiPredefinedItemSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiPredefinedItemSubmitMutation, KpiPredefinedItemSubmitMutationVariables>(KpiPredefinedItemSubmitDocument, baseOptions);
      }
export type KpiPredefinedItemSubmitMutationHookResult = ReturnType<typeof useKpiPredefinedItemSubmitMutation>;
export type KpiPredefinedItemSubmitMutationResult = ApolloReactCommon.MutationResult<KpiPredefinedItemSubmitMutation>;
export type KpiPredefinedItemSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiPredefinedItemSubmitMutation, KpiPredefinedItemSubmitMutationVariables>;
export const KpiPredefinedItemUpdateStatusDocument = gql`
    mutation KpiPredefinedItemUpdateStatus($input: KpiActiveInactiveInput!) {
  KpiPredefinedItemUpdateStatus(input: $input)
}
    `;
export type KpiPredefinedItemUpdateStatusMutationFn = ApolloReactCommon.MutationFunction<KpiPredefinedItemUpdateStatusMutation, KpiPredefinedItemUpdateStatusMutationVariables>;

/**
 * __useKpiPredefinedItemUpdateStatusMutation__
 *
 * To run a mutation, you first call `useKpiPredefinedItemUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedItemUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiPredefinedItemUpdateStatusMutation, { data, loading, error }] = useKpiPredefinedItemUpdateStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKpiPredefinedItemUpdateStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiPredefinedItemUpdateStatusMutation, KpiPredefinedItemUpdateStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiPredefinedItemUpdateStatusMutation, KpiPredefinedItemUpdateStatusMutationVariables>(KpiPredefinedItemUpdateStatusDocument, baseOptions);
      }
export type KpiPredefinedItemUpdateStatusMutationHookResult = ReturnType<typeof useKpiPredefinedItemUpdateStatusMutation>;
export type KpiPredefinedItemUpdateStatusMutationResult = ApolloReactCommon.MutationResult<KpiPredefinedItemUpdateStatusMutation>;
export type KpiPredefinedItemUpdateStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiPredefinedItemUpdateStatusMutation, KpiPredefinedItemUpdateStatusMutationVariables>;
export const KpiPredefinedItemDeleteDocument = gql`
    mutation KpiPredefinedItemDelete($KpiPredefinedItemID: String!) {
  KpiPredefinedItemDelete(KpiPredefinedItemID: $KpiPredefinedItemID)
}
    `;
export type KpiPredefinedItemDeleteMutationFn = ApolloReactCommon.MutationFunction<KpiPredefinedItemDeleteMutation, KpiPredefinedItemDeleteMutationVariables>;

/**
 * __useKpiPredefinedItemDeleteMutation__
 *
 * To run a mutation, you first call `useKpiPredefinedItemDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedItemDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiPredefinedItemDeleteMutation, { data, loading, error }] = useKpiPredefinedItemDeleteMutation({
 *   variables: {
 *      KpiPredefinedItemID: // value for 'KpiPredefinedItemID'
 *   },
 * });
 */
export function useKpiPredefinedItemDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiPredefinedItemDeleteMutation, KpiPredefinedItemDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiPredefinedItemDeleteMutation, KpiPredefinedItemDeleteMutationVariables>(KpiPredefinedItemDeleteDocument, baseOptions);
      }
export type KpiPredefinedItemDeleteMutationHookResult = ReturnType<typeof useKpiPredefinedItemDeleteMutation>;
export type KpiPredefinedItemDeleteMutationResult = ApolloReactCommon.MutationResult<KpiPredefinedItemDeleteMutation>;
export type KpiPredefinedItemDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiPredefinedItemDeleteMutation, KpiPredefinedItemDeleteMutationVariables>;
export const KpiPredefinedTemplateListingDocument = gql`
    query KpiPredefinedTemplateListing {
  KpiPredefinedTemplateListing {
    ID
    Title
    InUse
    CategoryCount
    ItemCount
    ModifiedDT
  }
}
    `;

/**
 * __useKpiPredefinedTemplateListingQuery__
 *
 * To run a query within a React component, call `useKpiPredefinedTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiPredefinedTemplateListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useKpiPredefinedTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiPredefinedTemplateListingQuery, KpiPredefinedTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiPredefinedTemplateListingQuery, KpiPredefinedTemplateListingQueryVariables>(KpiPredefinedTemplateListingDocument, baseOptions);
      }
export function useKpiPredefinedTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiPredefinedTemplateListingQuery, KpiPredefinedTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiPredefinedTemplateListingQuery, KpiPredefinedTemplateListingQueryVariables>(KpiPredefinedTemplateListingDocument, baseOptions);
        }
export type KpiPredefinedTemplateListingQueryHookResult = ReturnType<typeof useKpiPredefinedTemplateListingQuery>;
export type KpiPredefinedTemplateListingLazyQueryHookResult = ReturnType<typeof useKpiPredefinedTemplateListingLazyQuery>;
export type KpiPredefinedTemplateListingQueryResult = ApolloReactCommon.QueryResult<KpiPredefinedTemplateListingQuery, KpiPredefinedTemplateListingQueryVariables>;
export const KpiPredefinedTemplateDetailsDocument = gql`
    query KpiPredefinedTemplateDetails($KpiPredefinedTemplateID: String!) {
  KpiPredefinedTemplateDetails(KpiPredefinedTemplateID: $KpiPredefinedTemplateID) {
    ID
    Title
    Description
    WeightageType
    KpiWeightage
  }
}
    `;

/**
 * __useKpiPredefinedTemplateDetailsQuery__
 *
 * To run a query within a React component, call `useKpiPredefinedTemplateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedTemplateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKpiPredefinedTemplateDetailsQuery({
 *   variables: {
 *      KpiPredefinedTemplateID: // value for 'KpiPredefinedTemplateID'
 *   },
 * });
 */
export function useKpiPredefinedTemplateDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KpiPredefinedTemplateDetailsQuery, KpiPredefinedTemplateDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<KpiPredefinedTemplateDetailsQuery, KpiPredefinedTemplateDetailsQueryVariables>(KpiPredefinedTemplateDetailsDocument, baseOptions);
      }
export function useKpiPredefinedTemplateDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KpiPredefinedTemplateDetailsQuery, KpiPredefinedTemplateDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KpiPredefinedTemplateDetailsQuery, KpiPredefinedTemplateDetailsQueryVariables>(KpiPredefinedTemplateDetailsDocument, baseOptions);
        }
export type KpiPredefinedTemplateDetailsQueryHookResult = ReturnType<typeof useKpiPredefinedTemplateDetailsQuery>;
export type KpiPredefinedTemplateDetailsLazyQueryHookResult = ReturnType<typeof useKpiPredefinedTemplateDetailsLazyQuery>;
export type KpiPredefinedTemplateDetailsQueryResult = ApolloReactCommon.QueryResult<KpiPredefinedTemplateDetailsQuery, KpiPredefinedTemplateDetailsQueryVariables>;
export const IsKpiPredefinedTemplateTitleExistDocument = gql`
    query IsKpiPredefinedTemplateTitleExist($Title: String!, $KpiPredefinedTemplateID: String) {
  IsKpiPredefinedTemplateTitleExist(Title: $Title, KpiPredefinedTemplateID: $KpiPredefinedTemplateID)
}
    `;

/**
 * __useIsKpiPredefinedTemplateTitleExistQuery__
 *
 * To run a query within a React component, call `useIsKpiPredefinedTemplateTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsKpiPredefinedTemplateTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsKpiPredefinedTemplateTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      KpiPredefinedTemplateID: // value for 'KpiPredefinedTemplateID'
 *   },
 * });
 */
export function useIsKpiPredefinedTemplateTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsKpiPredefinedTemplateTitleExistQuery, IsKpiPredefinedTemplateTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsKpiPredefinedTemplateTitleExistQuery, IsKpiPredefinedTemplateTitleExistQueryVariables>(IsKpiPredefinedTemplateTitleExistDocument, baseOptions);
      }
export function useIsKpiPredefinedTemplateTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsKpiPredefinedTemplateTitleExistQuery, IsKpiPredefinedTemplateTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsKpiPredefinedTemplateTitleExistQuery, IsKpiPredefinedTemplateTitleExistQueryVariables>(IsKpiPredefinedTemplateTitleExistDocument, baseOptions);
        }
export type IsKpiPredefinedTemplateTitleExistQueryHookResult = ReturnType<typeof useIsKpiPredefinedTemplateTitleExistQuery>;
export type IsKpiPredefinedTemplateTitleExistLazyQueryHookResult = ReturnType<typeof useIsKpiPredefinedTemplateTitleExistLazyQuery>;
export type IsKpiPredefinedTemplateTitleExistQueryResult = ApolloReactCommon.QueryResult<IsKpiPredefinedTemplateTitleExistQuery, IsKpiPredefinedTemplateTitleExistQueryVariables>;
export const KpiPredefinedTemplateSubmitDocument = gql`
    mutation KpiPredefinedTemplateSubmit($input: KpiPredefinedTemplateInput!) {
  KpiPredefinedTemplateSubmit(input: $input)
}
    `;
export type KpiPredefinedTemplateSubmitMutationFn = ApolloReactCommon.MutationFunction<KpiPredefinedTemplateSubmitMutation, KpiPredefinedTemplateSubmitMutationVariables>;

/**
 * __useKpiPredefinedTemplateSubmitMutation__
 *
 * To run a mutation, you first call `useKpiPredefinedTemplateSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedTemplateSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiPredefinedTemplateSubmitMutation, { data, loading, error }] = useKpiPredefinedTemplateSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKpiPredefinedTemplateSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiPredefinedTemplateSubmitMutation, KpiPredefinedTemplateSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiPredefinedTemplateSubmitMutation, KpiPredefinedTemplateSubmitMutationVariables>(KpiPredefinedTemplateSubmitDocument, baseOptions);
      }
export type KpiPredefinedTemplateSubmitMutationHookResult = ReturnType<typeof useKpiPredefinedTemplateSubmitMutation>;
export type KpiPredefinedTemplateSubmitMutationResult = ApolloReactCommon.MutationResult<KpiPredefinedTemplateSubmitMutation>;
export type KpiPredefinedTemplateSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiPredefinedTemplateSubmitMutation, KpiPredefinedTemplateSubmitMutationVariables>;
export const KpiPredefinedTemplateDeleteDocument = gql`
    mutation KpiPredefinedTemplateDelete($KpiPredefinedTemplateID: String!) {
  KpiPredefinedTemplateDelete(KpiPredefinedTemplateID: $KpiPredefinedTemplateID)
}
    `;
export type KpiPredefinedTemplateDeleteMutationFn = ApolloReactCommon.MutationFunction<KpiPredefinedTemplateDeleteMutation, KpiPredefinedTemplateDeleteMutationVariables>;

/**
 * __useKpiPredefinedTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useKpiPredefinedTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKpiPredefinedTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kpiPredefinedTemplateDeleteMutation, { data, loading, error }] = useKpiPredefinedTemplateDeleteMutation({
 *   variables: {
 *      KpiPredefinedTemplateID: // value for 'KpiPredefinedTemplateID'
 *   },
 * });
 */
export function useKpiPredefinedTemplateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KpiPredefinedTemplateDeleteMutation, KpiPredefinedTemplateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<KpiPredefinedTemplateDeleteMutation, KpiPredefinedTemplateDeleteMutationVariables>(KpiPredefinedTemplateDeleteDocument, baseOptions);
      }
export type KpiPredefinedTemplateDeleteMutationHookResult = ReturnType<typeof useKpiPredefinedTemplateDeleteMutation>;
export type KpiPredefinedTemplateDeleteMutationResult = ApolloReactCommon.MutationResult<KpiPredefinedTemplateDeleteMutation>;
export type KpiPredefinedTemplateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<KpiPredefinedTemplateDeleteMutation, KpiPredefinedTemplateDeleteMutationVariables>;
export const ReviewItemListingDocument = gql`
    query ReviewItemListing {
  ReviewItemListing {
    ID
    Description
    Title
    InUse
    ApplicableTo
    Code
  }
}
    `;

/**
 * __useReviewItemListingQuery__
 *
 * To run a query within a React component, call `useReviewItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewItemListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewItemListingQuery, ReviewItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewItemListingQuery, ReviewItemListingQueryVariables>(ReviewItemListingDocument, baseOptions);
      }
export function useReviewItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewItemListingQuery, ReviewItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewItemListingQuery, ReviewItemListingQueryVariables>(ReviewItemListingDocument, baseOptions);
        }
export type ReviewItemListingQueryHookResult = ReturnType<typeof useReviewItemListingQuery>;
export type ReviewItemListingLazyQueryHookResult = ReturnType<typeof useReviewItemListingLazyQuery>;
export type ReviewItemListingQueryResult = ApolloReactCommon.QueryResult<ReviewItemListingQuery, ReviewItemListingQueryVariables>;
export const ReviewItemDetailsDocument = gql`
    query ReviewItemDetails($ReviewItemID: String!) {
  ReviewItemDetails(ReviewItemID: $ReviewItemID) {
    ID
    Title
    Code
    Description
    ItemRatingMethod
    FormItemRating {
      ID
      Title
    }
    ApplicableTo
  }
}
    `;

/**
 * __useReviewItemDetailsQuery__
 *
 * To run a query within a React component, call `useReviewItemDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewItemDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewItemDetailsQuery({
 *   variables: {
 *      ReviewItemID: // value for 'ReviewItemID'
 *   },
 * });
 */
export function useReviewItemDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewItemDetailsQuery, ReviewItemDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewItemDetailsQuery, ReviewItemDetailsQueryVariables>(ReviewItemDetailsDocument, baseOptions);
      }
export function useReviewItemDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewItemDetailsQuery, ReviewItemDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewItemDetailsQuery, ReviewItemDetailsQueryVariables>(ReviewItemDetailsDocument, baseOptions);
        }
export type ReviewItemDetailsQueryHookResult = ReturnType<typeof useReviewItemDetailsQuery>;
export type ReviewItemDetailsLazyQueryHookResult = ReturnType<typeof useReviewItemDetailsLazyQuery>;
export type ReviewItemDetailsQueryResult = ApolloReactCommon.QueryResult<ReviewItemDetailsQuery, ReviewItemDetailsQueryVariables>;
export const IsReviewItemCodeExistDocument = gql`
    query IsReviewItemCodeExist($Code: String!, $ReviewItemID: String) {
  IsReviewItemCodeExist(Code: $Code, ReviewItemID: $ReviewItemID)
}
    `;

/**
 * __useIsReviewItemCodeExistQuery__
 *
 * To run a query within a React component, call `useIsReviewItemCodeExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewItemCodeExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewItemCodeExistQuery({
 *   variables: {
 *      Code: // value for 'Code'
 *      ReviewItemID: // value for 'ReviewItemID'
 *   },
 * });
 */
export function useIsReviewItemCodeExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewItemCodeExistQuery, IsReviewItemCodeExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewItemCodeExistQuery, IsReviewItemCodeExistQueryVariables>(IsReviewItemCodeExistDocument, baseOptions);
      }
export function useIsReviewItemCodeExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewItemCodeExistQuery, IsReviewItemCodeExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewItemCodeExistQuery, IsReviewItemCodeExistQueryVariables>(IsReviewItemCodeExistDocument, baseOptions);
        }
export type IsReviewItemCodeExistQueryHookResult = ReturnType<typeof useIsReviewItemCodeExistQuery>;
export type IsReviewItemCodeExistLazyQueryHookResult = ReturnType<typeof useIsReviewItemCodeExistLazyQuery>;
export type IsReviewItemCodeExistQueryResult = ApolloReactCommon.QueryResult<IsReviewItemCodeExistQuery, IsReviewItemCodeExistQueryVariables>;
export const ReviewItemSubmitDocument = gql`
    mutation ReviewItemSubmit($input: ReviewItemInput!) {
  ReviewItemSubmit(input: $input)
}
    `;
export type ReviewItemSubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewItemSubmitMutation, ReviewItemSubmitMutationVariables>;

/**
 * __useReviewItemSubmitMutation__
 *
 * To run a mutation, you first call `useReviewItemSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewItemSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewItemSubmitMutation, { data, loading, error }] = useReviewItemSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewItemSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewItemSubmitMutation, ReviewItemSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewItemSubmitMutation, ReviewItemSubmitMutationVariables>(ReviewItemSubmitDocument, baseOptions);
      }
export type ReviewItemSubmitMutationHookResult = ReturnType<typeof useReviewItemSubmitMutation>;
export type ReviewItemSubmitMutationResult = ApolloReactCommon.MutationResult<ReviewItemSubmitMutation>;
export type ReviewItemSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewItemSubmitMutation, ReviewItemSubmitMutationVariables>;
export const ReviewItemDeleteDocument = gql`
    mutation ReviewItemDelete($ReviewItemID: String!) {
  ReviewItemDelete(ReviewItemID: $ReviewItemID)
}
    `;
export type ReviewItemDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewItemDeleteMutation, ReviewItemDeleteMutationVariables>;

/**
 * __useReviewItemDeleteMutation__
 *
 * To run a mutation, you first call `useReviewItemDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewItemDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewItemDeleteMutation, { data, loading, error }] = useReviewItemDeleteMutation({
 *   variables: {
 *      ReviewItemID: // value for 'ReviewItemID'
 *   },
 * });
 */
export function useReviewItemDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewItemDeleteMutation, ReviewItemDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewItemDeleteMutation, ReviewItemDeleteMutationVariables>(ReviewItemDeleteDocument, baseOptions);
      }
export type ReviewItemDeleteMutationHookResult = ReturnType<typeof useReviewItemDeleteMutation>;
export type ReviewItemDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewItemDeleteMutation>;
export type ReviewItemDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewItemDeleteMutation, ReviewItemDeleteMutationVariables>;
export const ReviewTemplateListingDocument = gql`
    query ReviewTemplateListing {
  ReviewTemplateListing {
    ID
    Title
    InUse
    ItemCount
    ModifiedDT
  }
}
    `;

/**
 * __useReviewTemplateListingQuery__
 *
 * To run a query within a React component, call `useReviewTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewTemplateListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewTemplateListingQuery, ReviewTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewTemplateListingQuery, ReviewTemplateListingQueryVariables>(ReviewTemplateListingDocument, baseOptions);
      }
export function useReviewTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewTemplateListingQuery, ReviewTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewTemplateListingQuery, ReviewTemplateListingQueryVariables>(ReviewTemplateListingDocument, baseOptions);
        }
export type ReviewTemplateListingQueryHookResult = ReturnType<typeof useReviewTemplateListingQuery>;
export type ReviewTemplateListingLazyQueryHookResult = ReturnType<typeof useReviewTemplateListingLazyQuery>;
export type ReviewTemplateListingQueryResult = ApolloReactCommon.QueryResult<ReviewTemplateListingQuery, ReviewTemplateListingQueryVariables>;
export const ReviewTemplateDetailsDocument = gql`
    query ReviewTemplateDetails($ReviewTemplateID: String!) {
  ReviewTemplateDetails(ReviewTemplateID: $ReviewTemplateID) {
    ID
    Title
    Description
    ApplicableTo
    WeightageType
    ReviewItemList
  }
}
    `;

/**
 * __useReviewTemplateDetailsQuery__
 *
 * To run a query within a React component, call `useReviewTemplateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewTemplateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewTemplateDetailsQuery({
 *   variables: {
 *      ReviewTemplateID: // value for 'ReviewTemplateID'
 *   },
 * });
 */
export function useReviewTemplateDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewTemplateDetailsQuery, ReviewTemplateDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewTemplateDetailsQuery, ReviewTemplateDetailsQueryVariables>(ReviewTemplateDetailsDocument, baseOptions);
      }
export function useReviewTemplateDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewTemplateDetailsQuery, ReviewTemplateDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewTemplateDetailsQuery, ReviewTemplateDetailsQueryVariables>(ReviewTemplateDetailsDocument, baseOptions);
        }
export type ReviewTemplateDetailsQueryHookResult = ReturnType<typeof useReviewTemplateDetailsQuery>;
export type ReviewTemplateDetailsLazyQueryHookResult = ReturnType<typeof useReviewTemplateDetailsLazyQuery>;
export type ReviewTemplateDetailsQueryResult = ApolloReactCommon.QueryResult<ReviewTemplateDetailsQuery, ReviewTemplateDetailsQueryVariables>;
export const IsReviewTemplateTitleExistDocument = gql`
    query IsReviewTemplateTitleExist($Title: String!, $ReviewTemplateID: String) {
  IsReviewTemplateTitleExist(Title: $Title, ReviewTemplateID: $ReviewTemplateID)
}
    `;

/**
 * __useIsReviewTemplateTitleExistQuery__
 *
 * To run a query within a React component, call `useIsReviewTemplateTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewTemplateTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewTemplateTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReviewTemplateID: // value for 'ReviewTemplateID'
 *   },
 * });
 */
export function useIsReviewTemplateTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewTemplateTitleExistQuery, IsReviewTemplateTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewTemplateTitleExistQuery, IsReviewTemplateTitleExistQueryVariables>(IsReviewTemplateTitleExistDocument, baseOptions);
      }
export function useIsReviewTemplateTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewTemplateTitleExistQuery, IsReviewTemplateTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewTemplateTitleExistQuery, IsReviewTemplateTitleExistQueryVariables>(IsReviewTemplateTitleExistDocument, baseOptions);
        }
export type IsReviewTemplateTitleExistQueryHookResult = ReturnType<typeof useIsReviewTemplateTitleExistQuery>;
export type IsReviewTemplateTitleExistLazyQueryHookResult = ReturnType<typeof useIsReviewTemplateTitleExistLazyQuery>;
export type IsReviewTemplateTitleExistQueryResult = ApolloReactCommon.QueryResult<IsReviewTemplateTitleExistQuery, IsReviewTemplateTitleExistQueryVariables>;
export const ReviewTemplateSubmitDocument = gql`
    mutation ReviewTemplateSubmit($input: ReviewTemplateInput!) {
  ReviewTemplateSubmit(input: $input)
}
    `;
export type ReviewTemplateSubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewTemplateSubmitMutation, ReviewTemplateSubmitMutationVariables>;

/**
 * __useReviewTemplateSubmitMutation__
 *
 * To run a mutation, you first call `useReviewTemplateSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewTemplateSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewTemplateSubmitMutation, { data, loading, error }] = useReviewTemplateSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewTemplateSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewTemplateSubmitMutation, ReviewTemplateSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewTemplateSubmitMutation, ReviewTemplateSubmitMutationVariables>(ReviewTemplateSubmitDocument, baseOptions);
      }
export type ReviewTemplateSubmitMutationHookResult = ReturnType<typeof useReviewTemplateSubmitMutation>;
export type ReviewTemplateSubmitMutationResult = ApolloReactCommon.MutationResult<ReviewTemplateSubmitMutation>;
export type ReviewTemplateSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewTemplateSubmitMutation, ReviewTemplateSubmitMutationVariables>;
export const ReviewTemplateDeleteDocument = gql`
    mutation ReviewTemplateDelete($ReviewTemplateID: String!) {
  ReviewTemplateDelete(ReviewTemplateID: $ReviewTemplateID)
}
    `;
export type ReviewTemplateDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewTemplateDeleteMutation, ReviewTemplateDeleteMutationVariables>;

/**
 * __useReviewTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useReviewTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewTemplateDeleteMutation, { data, loading, error }] = useReviewTemplateDeleteMutation({
 *   variables: {
 *      ReviewTemplateID: // value for 'ReviewTemplateID'
 *   },
 * });
 */
export function useReviewTemplateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewTemplateDeleteMutation, ReviewTemplateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewTemplateDeleteMutation, ReviewTemplateDeleteMutationVariables>(ReviewTemplateDeleteDocument, baseOptions);
      }
export type ReviewTemplateDeleteMutationHookResult = ReturnType<typeof useReviewTemplateDeleteMutation>;
export type ReviewTemplateDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewTemplateDeleteMutation>;
export type ReviewTemplateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewTemplateDeleteMutation, ReviewTemplateDeleteMutationVariables>;
export const ReviewProcessPolicyListingDocument = gql`
    query ReviewProcessPolicyListing {
  ReviewProcessPolicyListing {
    ID
    Title
    InUse
    ModifiedDT
  }
}
    `;

/**
 * __useReviewProcessPolicyListingQuery__
 *
 * To run a query within a React component, call `useReviewProcessPolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewProcessPolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewProcessPolicyListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewProcessPolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewProcessPolicyListingQuery, ReviewProcessPolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewProcessPolicyListingQuery, ReviewProcessPolicyListingQueryVariables>(ReviewProcessPolicyListingDocument, baseOptions);
      }
export function useReviewProcessPolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewProcessPolicyListingQuery, ReviewProcessPolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewProcessPolicyListingQuery, ReviewProcessPolicyListingQueryVariables>(ReviewProcessPolicyListingDocument, baseOptions);
        }
export type ReviewProcessPolicyListingQueryHookResult = ReturnType<typeof useReviewProcessPolicyListingQuery>;
export type ReviewProcessPolicyListingLazyQueryHookResult = ReturnType<typeof useReviewProcessPolicyListingLazyQuery>;
export type ReviewProcessPolicyListingQueryResult = ApolloReactCommon.QueryResult<ReviewProcessPolicyListingQuery, ReviewProcessPolicyListingQueryVariables>;
export const ReviewProcessPolicyDetailsDocument = gql`
    query ReviewProcessPolicyDetails($ReviewProcessPolicyID: String!) {
  ReviewProcessPolicyDetails(ReviewProcessPolicyID: $ReviewProcessPolicyID) {
    ID
    Title
    ApprovalList
  }
}
    `;

/**
 * __useReviewProcessPolicyDetailsQuery__
 *
 * To run a query within a React component, call `useReviewProcessPolicyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewProcessPolicyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewProcessPolicyDetailsQuery({
 *   variables: {
 *      ReviewProcessPolicyID: // value for 'ReviewProcessPolicyID'
 *   },
 * });
 */
export function useReviewProcessPolicyDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewProcessPolicyDetailsQuery, ReviewProcessPolicyDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewProcessPolicyDetailsQuery, ReviewProcessPolicyDetailsQueryVariables>(ReviewProcessPolicyDetailsDocument, baseOptions);
      }
export function useReviewProcessPolicyDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewProcessPolicyDetailsQuery, ReviewProcessPolicyDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewProcessPolicyDetailsQuery, ReviewProcessPolicyDetailsQueryVariables>(ReviewProcessPolicyDetailsDocument, baseOptions);
        }
export type ReviewProcessPolicyDetailsQueryHookResult = ReturnType<typeof useReviewProcessPolicyDetailsQuery>;
export type ReviewProcessPolicyDetailsLazyQueryHookResult = ReturnType<typeof useReviewProcessPolicyDetailsLazyQuery>;
export type ReviewProcessPolicyDetailsQueryResult = ApolloReactCommon.QueryResult<ReviewProcessPolicyDetailsQuery, ReviewProcessPolicyDetailsQueryVariables>;
export const IsReviewProcessPolicyTitleExistDocument = gql`
    query IsReviewProcessPolicyTitleExist($Title: String!, $ReviewProcessPolicyID: String) {
  IsReviewProcessPolicyTitleExist(Title: $Title, ReviewProcessPolicyID: $ReviewProcessPolicyID)
}
    `;

/**
 * __useIsReviewProcessPolicyTitleExistQuery__
 *
 * To run a query within a React component, call `useIsReviewProcessPolicyTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewProcessPolicyTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewProcessPolicyTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReviewProcessPolicyID: // value for 'ReviewProcessPolicyID'
 *   },
 * });
 */
export function useIsReviewProcessPolicyTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewProcessPolicyTitleExistQuery, IsReviewProcessPolicyTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewProcessPolicyTitleExistQuery, IsReviewProcessPolicyTitleExistQueryVariables>(IsReviewProcessPolicyTitleExistDocument, baseOptions);
      }
export function useIsReviewProcessPolicyTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewProcessPolicyTitleExistQuery, IsReviewProcessPolicyTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewProcessPolicyTitleExistQuery, IsReviewProcessPolicyTitleExistQueryVariables>(IsReviewProcessPolicyTitleExistDocument, baseOptions);
        }
export type IsReviewProcessPolicyTitleExistQueryHookResult = ReturnType<typeof useIsReviewProcessPolicyTitleExistQuery>;
export type IsReviewProcessPolicyTitleExistLazyQueryHookResult = ReturnType<typeof useIsReviewProcessPolicyTitleExistLazyQuery>;
export type IsReviewProcessPolicyTitleExistQueryResult = ApolloReactCommon.QueryResult<IsReviewProcessPolicyTitleExistQuery, IsReviewProcessPolicyTitleExistQueryVariables>;
export const ReviewProcessPolicySubmitDocument = gql`
    mutation ReviewProcessPolicySubmit($input: ReviewProcessPolicyInput!) {
  ReviewProcessPolicySubmit(input: $input)
}
    `;
export type ReviewProcessPolicySubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewProcessPolicySubmitMutation, ReviewProcessPolicySubmitMutationVariables>;

/**
 * __useReviewProcessPolicySubmitMutation__
 *
 * To run a mutation, you first call `useReviewProcessPolicySubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewProcessPolicySubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewProcessPolicySubmitMutation, { data, loading, error }] = useReviewProcessPolicySubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewProcessPolicySubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewProcessPolicySubmitMutation, ReviewProcessPolicySubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewProcessPolicySubmitMutation, ReviewProcessPolicySubmitMutationVariables>(ReviewProcessPolicySubmitDocument, baseOptions);
      }
export type ReviewProcessPolicySubmitMutationHookResult = ReturnType<typeof useReviewProcessPolicySubmitMutation>;
export type ReviewProcessPolicySubmitMutationResult = ApolloReactCommon.MutationResult<ReviewProcessPolicySubmitMutation>;
export type ReviewProcessPolicySubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewProcessPolicySubmitMutation, ReviewProcessPolicySubmitMutationVariables>;
export const ReviewProcessPolicyDeleteDocument = gql`
    mutation ReviewProcessPolicyDelete($ReviewProcessPolicyID: String!) {
  ReviewProcessPolicyDelete(ReviewProcessPolicyID: $ReviewProcessPolicyID)
}
    `;
export type ReviewProcessPolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewProcessPolicyDeleteMutation, ReviewProcessPolicyDeleteMutationVariables>;

/**
 * __useReviewProcessPolicyDeleteMutation__
 *
 * To run a mutation, you first call `useReviewProcessPolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewProcessPolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewProcessPolicyDeleteMutation, { data, loading, error }] = useReviewProcessPolicyDeleteMutation({
 *   variables: {
 *      ReviewProcessPolicyID: // value for 'ReviewProcessPolicyID'
 *   },
 * });
 */
export function useReviewProcessPolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewProcessPolicyDeleteMutation, ReviewProcessPolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewProcessPolicyDeleteMutation, ReviewProcessPolicyDeleteMutationVariables>(ReviewProcessPolicyDeleteDocument, baseOptions);
      }
export type ReviewProcessPolicyDeleteMutationHookResult = ReturnType<typeof useReviewProcessPolicyDeleteMutation>;
export type ReviewProcessPolicyDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewProcessPolicyDeleteMutation>;
export type ReviewProcessPolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewProcessPolicyDeleteMutation, ReviewProcessPolicyDeleteMutationVariables>;
export const ReviewFormTemplateListingDocument = gql`
    query ReviewFormTemplateListing {
  ReviewFormTemplateListing {
    ID
    Title
    InUse
    ModifiedDT
  }
}
    `;

/**
 * __useReviewFormTemplateListingQuery__
 *
 * To run a query within a React component, call `useReviewFormTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewFormTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewFormTemplateListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewFormTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewFormTemplateListingQuery, ReviewFormTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewFormTemplateListingQuery, ReviewFormTemplateListingQueryVariables>(ReviewFormTemplateListingDocument, baseOptions);
      }
export function useReviewFormTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewFormTemplateListingQuery, ReviewFormTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewFormTemplateListingQuery, ReviewFormTemplateListingQueryVariables>(ReviewFormTemplateListingDocument, baseOptions);
        }
export type ReviewFormTemplateListingQueryHookResult = ReturnType<typeof useReviewFormTemplateListingQuery>;
export type ReviewFormTemplateListingLazyQueryHookResult = ReturnType<typeof useReviewFormTemplateListingLazyQuery>;
export type ReviewFormTemplateListingQueryResult = ApolloReactCommon.QueryResult<ReviewFormTemplateListingQuery, ReviewFormTemplateListingQueryVariables>;
export const ReviewFormTemplateDetailsDocument = gql`
    query ReviewFormTemplateDetails($ReviewFormTemplateID: String!) {
  ReviewFormTemplateDetails(ReviewFormTemplateID: $ReviewFormTemplateID) {
    ID
    Title
    Description
    WeightageType
    ReviewProcessPolicy {
      Title
      ID
    }
    ReviewType
    ApplicableTo
    ReviewSection {
      ID
      Title
      Type
      Weightage
      KpiSource
      KpiPredefinedTemplate {
        ID
        Title
      }
      FormItemRating {
        ID
        Title
      }
      ReviewTemplate {
        ID
        Title
      }
      Description
    }
  }
}
    `;

/**
 * __useReviewFormTemplateDetailsQuery__
 *
 * To run a query within a React component, call `useReviewFormTemplateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewFormTemplateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewFormTemplateDetailsQuery({
 *   variables: {
 *      ReviewFormTemplateID: // value for 'ReviewFormTemplateID'
 *   },
 * });
 */
export function useReviewFormTemplateDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewFormTemplateDetailsQuery, ReviewFormTemplateDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewFormTemplateDetailsQuery, ReviewFormTemplateDetailsQueryVariables>(ReviewFormTemplateDetailsDocument, baseOptions);
      }
export function useReviewFormTemplateDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewFormTemplateDetailsQuery, ReviewFormTemplateDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewFormTemplateDetailsQuery, ReviewFormTemplateDetailsQueryVariables>(ReviewFormTemplateDetailsDocument, baseOptions);
        }
export type ReviewFormTemplateDetailsQueryHookResult = ReturnType<typeof useReviewFormTemplateDetailsQuery>;
export type ReviewFormTemplateDetailsLazyQueryHookResult = ReturnType<typeof useReviewFormTemplateDetailsLazyQuery>;
export type ReviewFormTemplateDetailsQueryResult = ApolloReactCommon.QueryResult<ReviewFormTemplateDetailsQuery, ReviewFormTemplateDetailsQueryVariables>;
export const IsReviewFormTemplateTitleExistDocument = gql`
    query IsReviewFormTemplateTitleExist($Title: String!, $ReviewFormTemplateID: String) {
  IsReviewFormTemplateTitleExist(Title: $Title, ReviewFormTemplateID: $ReviewFormTemplateID)
}
    `;

/**
 * __useIsReviewFormTemplateTitleExistQuery__
 *
 * To run a query within a React component, call `useIsReviewFormTemplateTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewFormTemplateTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewFormTemplateTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReviewFormTemplateID: // value for 'ReviewFormTemplateID'
 *   },
 * });
 */
export function useIsReviewFormTemplateTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewFormTemplateTitleExistQuery, IsReviewFormTemplateTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewFormTemplateTitleExistQuery, IsReviewFormTemplateTitleExistQueryVariables>(IsReviewFormTemplateTitleExistDocument, baseOptions);
      }
export function useIsReviewFormTemplateTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewFormTemplateTitleExistQuery, IsReviewFormTemplateTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewFormTemplateTitleExistQuery, IsReviewFormTemplateTitleExistQueryVariables>(IsReviewFormTemplateTitleExistDocument, baseOptions);
        }
export type IsReviewFormTemplateTitleExistQueryHookResult = ReturnType<typeof useIsReviewFormTemplateTitleExistQuery>;
export type IsReviewFormTemplateTitleExistLazyQueryHookResult = ReturnType<typeof useIsReviewFormTemplateTitleExistLazyQuery>;
export type IsReviewFormTemplateTitleExistQueryResult = ApolloReactCommon.QueryResult<IsReviewFormTemplateTitleExistQuery, IsReviewFormTemplateTitleExistQueryVariables>;
export const ReviewFormTemplateSubmitDocument = gql`
    mutation ReviewFormTemplateSubmit($input: ReviewFormTemplateInput!) {
  ReviewFormTemplateSubmit(input: $input)
}
    `;
export type ReviewFormTemplateSubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewFormTemplateSubmitMutation, ReviewFormTemplateSubmitMutationVariables>;

/**
 * __useReviewFormTemplateSubmitMutation__
 *
 * To run a mutation, you first call `useReviewFormTemplateSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewFormTemplateSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewFormTemplateSubmitMutation, { data, loading, error }] = useReviewFormTemplateSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewFormTemplateSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewFormTemplateSubmitMutation, ReviewFormTemplateSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewFormTemplateSubmitMutation, ReviewFormTemplateSubmitMutationVariables>(ReviewFormTemplateSubmitDocument, baseOptions);
      }
export type ReviewFormTemplateSubmitMutationHookResult = ReturnType<typeof useReviewFormTemplateSubmitMutation>;
export type ReviewFormTemplateSubmitMutationResult = ApolloReactCommon.MutationResult<ReviewFormTemplateSubmitMutation>;
export type ReviewFormTemplateSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewFormTemplateSubmitMutation, ReviewFormTemplateSubmitMutationVariables>;
export const ReviewFormTemplateDeleteDocument = gql`
    mutation ReviewFormTemplateDelete($ReviewFormTemplateID: String!) {
  ReviewFormTemplateDelete(ReviewFormTemplateID: $ReviewFormTemplateID)
}
    `;
export type ReviewFormTemplateDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewFormTemplateDeleteMutation, ReviewFormTemplateDeleteMutationVariables>;

/**
 * __useReviewFormTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useReviewFormTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewFormTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewFormTemplateDeleteMutation, { data, loading, error }] = useReviewFormTemplateDeleteMutation({
 *   variables: {
 *      ReviewFormTemplateID: // value for 'ReviewFormTemplateID'
 *   },
 * });
 */
export function useReviewFormTemplateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewFormTemplateDeleteMutation, ReviewFormTemplateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewFormTemplateDeleteMutation, ReviewFormTemplateDeleteMutationVariables>(ReviewFormTemplateDeleteDocument, baseOptions);
      }
export type ReviewFormTemplateDeleteMutationHookResult = ReturnType<typeof useReviewFormTemplateDeleteMutation>;
export type ReviewFormTemplateDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewFormTemplateDeleteMutation>;
export type ReviewFormTemplateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewFormTemplateDeleteMutation, ReviewFormTemplateDeleteMutationVariables>;
export const ReviewGroupListingDocument = gql`
    query ReviewGroupListing {
  ReviewGroupListing {
    ID
    Title
    InUse
    ModifiedDT
  }
}
    `;

/**
 * __useReviewGroupListingQuery__
 *
 * To run a query within a React component, call `useReviewGroupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewGroupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewGroupListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReviewGroupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewGroupListingQuery, ReviewGroupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewGroupListingQuery, ReviewGroupListingQueryVariables>(ReviewGroupListingDocument, baseOptions);
      }
export function useReviewGroupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewGroupListingQuery, ReviewGroupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewGroupListingQuery, ReviewGroupListingQueryVariables>(ReviewGroupListingDocument, baseOptions);
        }
export type ReviewGroupListingQueryHookResult = ReturnType<typeof useReviewGroupListingQuery>;
export type ReviewGroupListingLazyQueryHookResult = ReturnType<typeof useReviewGroupListingLazyQuery>;
export type ReviewGroupListingQueryResult = ApolloReactCommon.QueryResult<ReviewGroupListingQuery, ReviewGroupListingQueryVariables>;
export const IsReviewGroupTitleExistDocument = gql`
    query IsReviewGroupTitleExist($Title: String!, $ReviewGroupID: String) {
  IsReviewGroupTitleExist(Title: $Title, ReviewGroupID: $ReviewGroupID)
}
    `;

/**
 * __useIsReviewGroupTitleExistQuery__
 *
 * To run a query within a React component, call `useIsReviewGroupTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsReviewGroupTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsReviewGroupTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReviewGroupID: // value for 'ReviewGroupID'
 *   },
 * });
 */
export function useIsReviewGroupTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsReviewGroupTitleExistQuery, IsReviewGroupTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsReviewGroupTitleExistQuery, IsReviewGroupTitleExistQueryVariables>(IsReviewGroupTitleExistDocument, baseOptions);
      }
export function useIsReviewGroupTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsReviewGroupTitleExistQuery, IsReviewGroupTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsReviewGroupTitleExistQuery, IsReviewGroupTitleExistQueryVariables>(IsReviewGroupTitleExistDocument, baseOptions);
        }
export type IsReviewGroupTitleExistQueryHookResult = ReturnType<typeof useIsReviewGroupTitleExistQuery>;
export type IsReviewGroupTitleExistLazyQueryHookResult = ReturnType<typeof useIsReviewGroupTitleExistLazyQuery>;
export type IsReviewGroupTitleExistQueryResult = ApolloReactCommon.QueryResult<IsReviewGroupTitleExistQuery, IsReviewGroupTitleExistQueryVariables>;
export const ReviewGroupSubmitDocument = gql`
    mutation ReviewGroupSubmit($input: ReviewGroupInput!) {
  ReviewGroupSubmit(input: $input)
}
    `;
export type ReviewGroupSubmitMutationFn = ApolloReactCommon.MutationFunction<ReviewGroupSubmitMutation, ReviewGroupSubmitMutationVariables>;

/**
 * __useReviewGroupSubmitMutation__
 *
 * To run a mutation, you first call `useReviewGroupSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewGroupSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewGroupSubmitMutation, { data, loading, error }] = useReviewGroupSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewGroupSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewGroupSubmitMutation, ReviewGroupSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewGroupSubmitMutation, ReviewGroupSubmitMutationVariables>(ReviewGroupSubmitDocument, baseOptions);
      }
export type ReviewGroupSubmitMutationHookResult = ReturnType<typeof useReviewGroupSubmitMutation>;
export type ReviewGroupSubmitMutationResult = ApolloReactCommon.MutationResult<ReviewGroupSubmitMutation>;
export type ReviewGroupSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewGroupSubmitMutation, ReviewGroupSubmitMutationVariables>;
export const ReviewGroupDeleteDocument = gql`
    mutation ReviewGroupDelete($ReviewGroupID: String!) {
  ReviewGroupDelete(ReviewGroupID: $ReviewGroupID)
}
    `;
export type ReviewGroupDeleteMutationFn = ApolloReactCommon.MutationFunction<ReviewGroupDeleteMutation, ReviewGroupDeleteMutationVariables>;

/**
 * __useReviewGroupDeleteMutation__
 *
 * To run a mutation, you first call `useReviewGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewGroupDeleteMutation, { data, loading, error }] = useReviewGroupDeleteMutation({
 *   variables: {
 *      ReviewGroupID: // value for 'ReviewGroupID'
 *   },
 * });
 */
export function useReviewGroupDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviewGroupDeleteMutation, ReviewGroupDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviewGroupDeleteMutation, ReviewGroupDeleteMutationVariables>(ReviewGroupDeleteDocument, baseOptions);
      }
export type ReviewGroupDeleteMutationHookResult = ReturnType<typeof useReviewGroupDeleteMutation>;
export type ReviewGroupDeleteMutationResult = ApolloReactCommon.MutationResult<ReviewGroupDeleteMutation>;
export type ReviewGroupDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviewGroupDeleteMutation, ReviewGroupDeleteMutationVariables>;
export const RewardGroupListingDocument = gql`
    query RewardGroupListing {
  RewardGroupListing {
    ID
    Title
    InUse
    ModifiedDT
  }
}
    `;

/**
 * __useRewardGroupListingQuery__
 *
 * To run a query within a React component, call `useRewardGroupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardGroupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardGroupListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useRewardGroupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RewardGroupListingQuery, RewardGroupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RewardGroupListingQuery, RewardGroupListingQueryVariables>(RewardGroupListingDocument, baseOptions);
      }
export function useRewardGroupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RewardGroupListingQuery, RewardGroupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RewardGroupListingQuery, RewardGroupListingQueryVariables>(RewardGroupListingDocument, baseOptions);
        }
export type RewardGroupListingQueryHookResult = ReturnType<typeof useRewardGroupListingQuery>;
export type RewardGroupListingLazyQueryHookResult = ReturnType<typeof useRewardGroupListingLazyQuery>;
export type RewardGroupListingQueryResult = ApolloReactCommon.QueryResult<RewardGroupListingQuery, RewardGroupListingQueryVariables>;
export const IsRewardGroupTitleExistDocument = gql`
    query IsRewardGroupTitleExist($Title: String!, $RewardGroupID: String) {
  IsRewardGroupTitleExist(Title: $Title, RewardGroupID: $RewardGroupID)
}
    `;

/**
 * __useIsRewardGroupTitleExistQuery__
 *
 * To run a query within a React component, call `useIsRewardGroupTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsRewardGroupTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsRewardGroupTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      RewardGroupID: // value for 'RewardGroupID'
 *   },
 * });
 */
export function useIsRewardGroupTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsRewardGroupTitleExistQuery, IsRewardGroupTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsRewardGroupTitleExistQuery, IsRewardGroupTitleExistQueryVariables>(IsRewardGroupTitleExistDocument, baseOptions);
      }
export function useIsRewardGroupTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsRewardGroupTitleExistQuery, IsRewardGroupTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsRewardGroupTitleExistQuery, IsRewardGroupTitleExistQueryVariables>(IsRewardGroupTitleExistDocument, baseOptions);
        }
export type IsRewardGroupTitleExistQueryHookResult = ReturnType<typeof useIsRewardGroupTitleExistQuery>;
export type IsRewardGroupTitleExistLazyQueryHookResult = ReturnType<typeof useIsRewardGroupTitleExistLazyQuery>;
export type IsRewardGroupTitleExistQueryResult = ApolloReactCommon.QueryResult<IsRewardGroupTitleExistQuery, IsRewardGroupTitleExistQueryVariables>;
export const RewardGroupSubmitDocument = gql`
    mutation RewardGroupSubmit($input: RewardGroupInput!) {
  RewardGroupSubmit(input: $input)
}
    `;
export type RewardGroupSubmitMutationFn = ApolloReactCommon.MutationFunction<RewardGroupSubmitMutation, RewardGroupSubmitMutationVariables>;

/**
 * __useRewardGroupSubmitMutation__
 *
 * To run a mutation, you first call `useRewardGroupSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRewardGroupSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rewardGroupSubmitMutation, { data, loading, error }] = useRewardGroupSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRewardGroupSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RewardGroupSubmitMutation, RewardGroupSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<RewardGroupSubmitMutation, RewardGroupSubmitMutationVariables>(RewardGroupSubmitDocument, baseOptions);
      }
export type RewardGroupSubmitMutationHookResult = ReturnType<typeof useRewardGroupSubmitMutation>;
export type RewardGroupSubmitMutationResult = ApolloReactCommon.MutationResult<RewardGroupSubmitMutation>;
export type RewardGroupSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<RewardGroupSubmitMutation, RewardGroupSubmitMutationVariables>;
export const RewardGroupDeleteDocument = gql`
    mutation RewardGroupDelete($RewardGroupID: String!) {
  RewardGroupDelete(RewardGroupID: $RewardGroupID)
}
    `;
export type RewardGroupDeleteMutationFn = ApolloReactCommon.MutationFunction<RewardGroupDeleteMutation, RewardGroupDeleteMutationVariables>;

/**
 * __useRewardGroupDeleteMutation__
 *
 * To run a mutation, you first call `useRewardGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRewardGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rewardGroupDeleteMutation, { data, loading, error }] = useRewardGroupDeleteMutation({
 *   variables: {
 *      RewardGroupID: // value for 'RewardGroupID'
 *   },
 * });
 */
export function useRewardGroupDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RewardGroupDeleteMutation, RewardGroupDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RewardGroupDeleteMutation, RewardGroupDeleteMutationVariables>(RewardGroupDeleteDocument, baseOptions);
      }
export type RewardGroupDeleteMutationHookResult = ReturnType<typeof useRewardGroupDeleteMutation>;
export type RewardGroupDeleteMutationResult = ApolloReactCommon.MutationResult<RewardGroupDeleteMutation>;
export type RewardGroupDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RewardGroupDeleteMutation, RewardGroupDeleteMutationVariables>;
export const PeriodicAllowanceTypeListingDocument = gql`
    query PeriodicAllowanceTypeListing {
  PeriodicAllowanceTypeListing {
    PeriodicAllowanceTypeID
    Name
    ModifiedDT
    CreatedDT
    Status
  }
}
    `;

/**
 * __usePeriodicAllowanceTypeListingQuery__
 *
 * To run a query within a React component, call `usePeriodicAllowanceTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeriodicAllowanceTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeriodicAllowanceTypeListingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeriodicAllowanceTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PeriodicAllowanceTypeListingQuery, PeriodicAllowanceTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PeriodicAllowanceTypeListingQuery, PeriodicAllowanceTypeListingQueryVariables>(PeriodicAllowanceTypeListingDocument, baseOptions);
      }
export function usePeriodicAllowanceTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PeriodicAllowanceTypeListingQuery, PeriodicAllowanceTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PeriodicAllowanceTypeListingQuery, PeriodicAllowanceTypeListingQueryVariables>(PeriodicAllowanceTypeListingDocument, baseOptions);
        }
export type PeriodicAllowanceTypeListingQueryHookResult = ReturnType<typeof usePeriodicAllowanceTypeListingQuery>;
export type PeriodicAllowanceTypeListingLazyQueryHookResult = ReturnType<typeof usePeriodicAllowanceTypeListingLazyQuery>;
export type PeriodicAllowanceTypeListingQueryResult = ApolloReactCommon.QueryResult<PeriodicAllowanceTypeListingQuery, PeriodicAllowanceTypeListingQueryVariables>;
export const PeriodicAllowanceTypeUpdateDocument = gql`
    mutation PeriodicAllowanceTypeUpdate($input: PeriodicAllowanceTypeInput!) {
  PeriodicAllowanceTypeUpdate(input: $input)
}
    `;
export type PeriodicAllowanceTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<PeriodicAllowanceTypeUpdateMutation, PeriodicAllowanceTypeUpdateMutationVariables>;

/**
 * __usePeriodicAllowanceTypeUpdateMutation__
 *
 * To run a mutation, you first call `usePeriodicAllowanceTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePeriodicAllowanceTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [periodicAllowanceTypeUpdateMutation, { data, loading, error }] = usePeriodicAllowanceTypeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePeriodicAllowanceTypeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PeriodicAllowanceTypeUpdateMutation, PeriodicAllowanceTypeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PeriodicAllowanceTypeUpdateMutation, PeriodicAllowanceTypeUpdateMutationVariables>(PeriodicAllowanceTypeUpdateDocument, baseOptions);
      }
export type PeriodicAllowanceTypeUpdateMutationHookResult = ReturnType<typeof usePeriodicAllowanceTypeUpdateMutation>;
export type PeriodicAllowanceTypeUpdateMutationResult = ApolloReactCommon.MutationResult<PeriodicAllowanceTypeUpdateMutation>;
export type PeriodicAllowanceTypeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PeriodicAllowanceTypeUpdateMutation, PeriodicAllowanceTypeUpdateMutationVariables>;
export const PeriodicAllowanceTypeInputDocument = gql`
    mutation PeriodicAllowanceTypeInput($input: PeriodicAllowanceTypeInput!) {
  PeriodicAllowanceTypeInput(input: $input) {
    PeriodicAllowanceTypeID
    Name
  }
}
    `;
export type PeriodicAllowanceTypeInputMutationFn = ApolloReactCommon.MutationFunction<PeriodicAllowanceTypeInputMutation, PeriodicAllowanceTypeInputMutationVariables>;

/**
 * __usePeriodicAllowanceTypeInputMutation__
 *
 * To run a mutation, you first call `usePeriodicAllowanceTypeInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePeriodicAllowanceTypeInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [periodicAllowanceTypeInputMutation, { data, loading, error }] = usePeriodicAllowanceTypeInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePeriodicAllowanceTypeInputMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PeriodicAllowanceTypeInputMutation, PeriodicAllowanceTypeInputMutationVariables>) {
        return ApolloReactHooks.useMutation<PeriodicAllowanceTypeInputMutation, PeriodicAllowanceTypeInputMutationVariables>(PeriodicAllowanceTypeInputDocument, baseOptions);
      }
export type PeriodicAllowanceTypeInputMutationHookResult = ReturnType<typeof usePeriodicAllowanceTypeInputMutation>;
export type PeriodicAllowanceTypeInputMutationResult = ApolloReactCommon.MutationResult<PeriodicAllowanceTypeInputMutation>;
export type PeriodicAllowanceTypeInputMutationOptions = ApolloReactCommon.BaseMutationOptions<PeriodicAllowanceTypeInputMutation, PeriodicAllowanceTypeInputMutationVariables>;
export const PeriodicAllowanceTypeDeleteDocument = gql`
    mutation PeriodicAllowanceTypeDelete($PeriodicAllowanceTypeID: String!) {
  PeriodicAllowanceTypeDelete(PeriodicAllowanceTypeID: $PeriodicAllowanceTypeID)
}
    `;
export type PeriodicAllowanceTypeDeleteMutationFn = ApolloReactCommon.MutationFunction<PeriodicAllowanceTypeDeleteMutation, PeriodicAllowanceTypeDeleteMutationVariables>;

/**
 * __usePeriodicAllowanceTypeDeleteMutation__
 *
 * To run a mutation, you first call `usePeriodicAllowanceTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePeriodicAllowanceTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [periodicAllowanceTypeDeleteMutation, { data, loading, error }] = usePeriodicAllowanceTypeDeleteMutation({
 *   variables: {
 *      PeriodicAllowanceTypeID: // value for 'PeriodicAllowanceTypeID'
 *   },
 * });
 */
export function usePeriodicAllowanceTypeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PeriodicAllowanceTypeDeleteMutation, PeriodicAllowanceTypeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PeriodicAllowanceTypeDeleteMutation, PeriodicAllowanceTypeDeleteMutationVariables>(PeriodicAllowanceTypeDeleteDocument, baseOptions);
      }
export type PeriodicAllowanceTypeDeleteMutationHookResult = ReturnType<typeof usePeriodicAllowanceTypeDeleteMutation>;
export type PeriodicAllowanceTypeDeleteMutationResult = ApolloReactCommon.MutationResult<PeriodicAllowanceTypeDeleteMutation>;
export type PeriodicAllowanceTypeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PeriodicAllowanceTypeDeleteMutation, PeriodicAllowanceTypeDeleteMutationVariables>;
export const EmployeeInfoInsertDocument = gql`
    mutation EmployeeInfoInsert($ContactInput: ContactInput!, $EmployeeInput: EmployeeInput!, $DocumentsInput: DocumentsInput!) {
  EmployeeInfoInsert(ContactInput: $ContactInput, EmployeeInput: $EmployeeInput, DocumentsInput: $DocumentsInput) {
    EmployeeID
  }
}
    `;
export type EmployeeInfoInsertMutationFn = ApolloReactCommon.MutationFunction<EmployeeInfoInsertMutation, EmployeeInfoInsertMutationVariables>;

/**
 * __useEmployeeInfoInsertMutation__
 *
 * To run a mutation, you first call `useEmployeeInfoInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeInfoInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeInfoInsertMutation, { data, loading, error }] = useEmployeeInfoInsertMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      EmployeeInput: // value for 'EmployeeInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useEmployeeInfoInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeInfoInsertMutation, EmployeeInfoInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeInfoInsertMutation, EmployeeInfoInsertMutationVariables>(EmployeeInfoInsertDocument, baseOptions);
      }
export type EmployeeInfoInsertMutationHookResult = ReturnType<typeof useEmployeeInfoInsertMutation>;
export type EmployeeInfoInsertMutationResult = ApolloReactCommon.MutationResult<EmployeeInfoInsertMutation>;
export type EmployeeInfoInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeInfoInsertMutation, EmployeeInfoInsertMutationVariables>;
export const GetNextDocNumberDocument = gql`
    query GetNextDocNumber($CompanyID: String!, $RefTableName: String!) {
  GetNextDocNumber(CompanyID: $CompanyID, RefTableName: $RefTableName)
}
    `;

/**
 * __useGetNextDocNumberQuery__
 *
 * To run a query within a React component, call `useGetNextDocNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextDocNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextDocNumberQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      RefTableName: // value for 'RefTableName'
 *   },
 * });
 */
export function useGetNextDocNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNextDocNumberQuery, GetNextDocNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNextDocNumberQuery, GetNextDocNumberQueryVariables>(GetNextDocNumberDocument, baseOptions);
      }
export function useGetNextDocNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNextDocNumberQuery, GetNextDocNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNextDocNumberQuery, GetNextDocNumberQueryVariables>(GetNextDocNumberDocument, baseOptions);
        }
export type GetNextDocNumberQueryHookResult = ReturnType<typeof useGetNextDocNumberQuery>;
export type GetNextDocNumberLazyQueryHookResult = ReturnType<typeof useGetNextDocNumberLazyQuery>;
export type GetNextDocNumberQueryResult = ApolloReactCommon.QueryResult<GetNextDocNumberQuery, GetNextDocNumberQueryVariables>;
export const JobDesignationListingDocument = gql`
    query JobDesignationListing($SubscriptionAccountID: String!) {
  JobDesignationListing(SubscriptionAccountID: $SubscriptionAccountID) {
    JobDesignationID
    SubscriptionAccountID
    Name
    IsAdmin
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    Employees {
      Status
      DesignationID
      EmployeeID
    }
    JobDesignationGrades {
      JobGradeID
    }
  }
}
    `;

/**
 * __useJobDesignationListingQuery__
 *
 * To run a query within a React component, call `useJobDesignationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDesignationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDesignationListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useJobDesignationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobDesignationListingQuery, JobDesignationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobDesignationListingQuery, JobDesignationListingQueryVariables>(JobDesignationListingDocument, baseOptions);
      }
export function useJobDesignationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobDesignationListingQuery, JobDesignationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobDesignationListingQuery, JobDesignationListingQueryVariables>(JobDesignationListingDocument, baseOptions);
        }
export type JobDesignationListingQueryHookResult = ReturnType<typeof useJobDesignationListingQuery>;
export type JobDesignationListingLazyQueryHookResult = ReturnType<typeof useJobDesignationListingLazyQuery>;
export type JobDesignationListingQueryResult = ApolloReactCommon.QueryResult<JobDesignationListingQuery, JobDesignationListingQueryVariables>;
export const JobGradeByJobDesignationListingDocument = gql`
    query JobGradeByJobDesignationListing($JobDesignationID: String!) {
  JobGradeByJobDesignationListing(JobDesignationID: $JobDesignationID) {
    JobDesignationID
    JobGrade {
      JobGradeID
      Description
    }
  }
}
    `;

/**
 * __useJobGradeByJobDesignationListingQuery__
 *
 * To run a query within a React component, call `useJobGradeByJobDesignationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobGradeByJobDesignationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobGradeByJobDesignationListingQuery({
 *   variables: {
 *      JobDesignationID: // value for 'JobDesignationID'
 *   },
 * });
 */
export function useJobGradeByJobDesignationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobGradeByJobDesignationListingQuery, JobGradeByJobDesignationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobGradeByJobDesignationListingQuery, JobGradeByJobDesignationListingQueryVariables>(JobGradeByJobDesignationListingDocument, baseOptions);
      }
export function useJobGradeByJobDesignationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobGradeByJobDesignationListingQuery, JobGradeByJobDesignationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobGradeByJobDesignationListingQuery, JobGradeByJobDesignationListingQueryVariables>(JobGradeByJobDesignationListingDocument, baseOptions);
        }
export type JobGradeByJobDesignationListingQueryHookResult = ReturnType<typeof useJobGradeByJobDesignationListingQuery>;
export type JobGradeByJobDesignationListingLazyQueryHookResult = ReturnType<typeof useJobGradeByJobDesignationListingLazyQuery>;
export type JobGradeByJobDesignationListingQueryResult = ApolloReactCommon.QueryResult<JobGradeByJobDesignationListingQuery, JobGradeByJobDesignationListingQueryVariables>;
export const InsuranceSchemeInUseDocument = gql`
    query InsuranceSchemeInUse($InsuranceSchemeID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  InsuranceSchemeInUse(InsuranceSchemeID: $InsuranceSchemeID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useInsuranceSchemeInUseQuery__
 *
 * To run a query within a React component, call `useInsuranceSchemeInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceSchemeInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceSchemeInUseQuery({
 *   variables: {
 *      InsuranceSchemeID: // value for 'InsuranceSchemeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useInsuranceSchemeInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InsuranceSchemeInUseQuery, InsuranceSchemeInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<InsuranceSchemeInUseQuery, InsuranceSchemeInUseQueryVariables>(InsuranceSchemeInUseDocument, baseOptions);
      }
export function useInsuranceSchemeInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InsuranceSchemeInUseQuery, InsuranceSchemeInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InsuranceSchemeInUseQuery, InsuranceSchemeInUseQueryVariables>(InsuranceSchemeInUseDocument, baseOptions);
        }
export type InsuranceSchemeInUseQueryHookResult = ReturnType<typeof useInsuranceSchemeInUseQuery>;
export type InsuranceSchemeInUseLazyQueryHookResult = ReturnType<typeof useInsuranceSchemeInUseLazyQuery>;
export type InsuranceSchemeInUseQueryResult = ApolloReactCommon.QueryResult<InsuranceSchemeInUseQuery, InsuranceSchemeInUseQueryVariables>;
export const CompanyListWithAccessLevelDocument = gql`
    query CompanyListWithAccessLevel {
  CompanyListWithAccessLevel
}
    `;

/**
 * __useCompanyListWithAccessLevelQuery__
 *
 * To run a query within a React component, call `useCompanyListWithAccessLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyListWithAccessLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyListWithAccessLevelQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyListWithAccessLevelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyListWithAccessLevelQuery, CompanyListWithAccessLevelQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyListWithAccessLevelQuery, CompanyListWithAccessLevelQueryVariables>(CompanyListWithAccessLevelDocument, baseOptions);
      }
export function useCompanyListWithAccessLevelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyListWithAccessLevelQuery, CompanyListWithAccessLevelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyListWithAccessLevelQuery, CompanyListWithAccessLevelQueryVariables>(CompanyListWithAccessLevelDocument, baseOptions);
        }
export type CompanyListWithAccessLevelQueryHookResult = ReturnType<typeof useCompanyListWithAccessLevelQuery>;
export type CompanyListWithAccessLevelLazyQueryHookResult = ReturnType<typeof useCompanyListWithAccessLevelLazyQuery>;
export type CompanyListWithAccessLevelQueryResult = ApolloReactCommon.QueryResult<CompanyListWithAccessLevelQuery, CompanyListWithAccessLevelQueryVariables>;
export const CompanyListingDocument = gql`
    query CompanyListing($SubscriptionAccountID: String!) {
  CompanyListing(SubscriptionAccountID: $SubscriptionAccountID) {
    CreatedDT
    ModifiedDT
    CompanyID
    CompanyName
    BizRegNo
    Departments {
      DepartmentID
      Description
      DepartmentType
      Description
    }
    Documents {
      DocumentID
      BucketFileName
      DocumentFile
      Description
      DocumentType
    }
  }
}
    `;

/**
 * __useCompanyListingQuery__
 *
 * To run a query within a React component, call `useCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyListingQuery, CompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyListingQuery, CompanyListingQueryVariables>(CompanyListingDocument, baseOptions);
      }
export function useCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyListingQuery, CompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyListingQuery, CompanyListingQueryVariables>(CompanyListingDocument, baseOptions);
        }
export type CompanyListingQueryHookResult = ReturnType<typeof useCompanyListingQuery>;
export type CompanyListingLazyQueryHookResult = ReturnType<typeof useCompanyListingLazyQuery>;
export type CompanyListingQueryResult = ApolloReactCommon.QueryResult<CompanyListingQuery, CompanyListingQueryVariables>;
export const JobPositionAssignmentListDocument = gql`
    query JobPositionAssignmentList($CompanyID: String!, $OrgUnitID: String, $JobDesignationID: String) {
  JobPositionAssignmentList(CompanyID: $CompanyID, OrgUnitID: $OrgUnitID, JobDesignationID: $JobDesignationID)
}
    `;

/**
 * __useJobPositionAssignmentListQuery__
 *
 * To run a query within a React component, call `useJobPositionAssignmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPositionAssignmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPositionAssignmentListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      JobDesignationID: // value for 'JobDesignationID'
 *   },
 * });
 */
export function useJobPositionAssignmentListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPositionAssignmentListQuery, JobPositionAssignmentListQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPositionAssignmentListQuery, JobPositionAssignmentListQueryVariables>(JobPositionAssignmentListDocument, baseOptions);
      }
export function useJobPositionAssignmentListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPositionAssignmentListQuery, JobPositionAssignmentListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPositionAssignmentListQuery, JobPositionAssignmentListQueryVariables>(JobPositionAssignmentListDocument, baseOptions);
        }
export type JobPositionAssignmentListQueryHookResult = ReturnType<typeof useJobPositionAssignmentListQuery>;
export type JobPositionAssignmentListLazyQueryHookResult = ReturnType<typeof useJobPositionAssignmentListLazyQuery>;
export type JobPositionAssignmentListQueryResult = ApolloReactCommon.QueryResult<JobPositionAssignmentListQuery, JobPositionAssignmentListQueryVariables>;
export const GetSuperiorDocument = gql`
    query GetSuperior($EmployeeID: String!) {
  GetSuperior(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useGetSuperiorQuery__
 *
 * To run a query within a React component, call `useGetSuperiorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuperiorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuperiorQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetSuperiorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSuperiorQuery, GetSuperiorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSuperiorQuery, GetSuperiorQueryVariables>(GetSuperiorDocument, baseOptions);
      }
export function useGetSuperiorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSuperiorQuery, GetSuperiorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSuperiorQuery, GetSuperiorQueryVariables>(GetSuperiorDocument, baseOptions);
        }
export type GetSuperiorQueryHookResult = ReturnType<typeof useGetSuperiorQuery>;
export type GetSuperiorLazyQueryHookResult = ReturnType<typeof useGetSuperiorLazyQuery>;
export type GetSuperiorQueryResult = ApolloReactCommon.QueryResult<GetSuperiorQuery, GetSuperiorQueryVariables>;
export const EmployeeCareerLogUpdateDocument = gql`
    mutation EmployeeCareerLogUpdate($EmployeeInput: EmployeeInput!) {
  EmployeeCareerLogUpdate(EmployeeInput: $EmployeeInput)
}
    `;
export type EmployeeCareerLogUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeCareerLogUpdateMutation, EmployeeCareerLogUpdateMutationVariables>;

/**
 * __useEmployeeCareerLogUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeCareerLogUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeCareerLogUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeCareerLogUpdateMutation, { data, loading, error }] = useEmployeeCareerLogUpdateMutation({
 *   variables: {
 *      EmployeeInput: // value for 'EmployeeInput'
 *   },
 * });
 */
export function useEmployeeCareerLogUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeCareerLogUpdateMutation, EmployeeCareerLogUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeCareerLogUpdateMutation, EmployeeCareerLogUpdateMutationVariables>(EmployeeCareerLogUpdateDocument, baseOptions);
      }
export type EmployeeCareerLogUpdateMutationHookResult = ReturnType<typeof useEmployeeCareerLogUpdateMutation>;
export type EmployeeCareerLogUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeCareerLogUpdateMutation>;
export type EmployeeCareerLogUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeCareerLogUpdateMutation, EmployeeCareerLogUpdateMutationVariables>;
export const EmployeeStatutoryInsertDocument = gql`
    mutation EmployeeStatutoryInsert($EmployeeBankInfoInput: EmployeeBankInfoInput!, $EmployeeStatutoryInput: EmployeeStatutoryInput!) {
  EmployeeStatutoryInsert(EmployeeBankInfoInput: $EmployeeBankInfoInput, EmployeeStatutoryInput: $EmployeeStatutoryInput) {
    EmployeeStatutoryID
  }
}
    `;
export type EmployeeStatutoryInsertMutationFn = ApolloReactCommon.MutationFunction<EmployeeStatutoryInsertMutation, EmployeeStatutoryInsertMutationVariables>;

/**
 * __useEmployeeStatutoryInsertMutation__
 *
 * To run a mutation, you first call `useEmployeeStatutoryInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeStatutoryInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeStatutoryInsertMutation, { data, loading, error }] = useEmployeeStatutoryInsertMutation({
 *   variables: {
 *      EmployeeBankInfoInput: // value for 'EmployeeBankInfoInput'
 *      EmployeeStatutoryInput: // value for 'EmployeeStatutoryInput'
 *   },
 * });
 */
export function useEmployeeStatutoryInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeStatutoryInsertMutation, EmployeeStatutoryInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeStatutoryInsertMutation, EmployeeStatutoryInsertMutationVariables>(EmployeeStatutoryInsertDocument, baseOptions);
      }
export type EmployeeStatutoryInsertMutationHookResult = ReturnType<typeof useEmployeeStatutoryInsertMutation>;
export type EmployeeStatutoryInsertMutationResult = ApolloReactCommon.MutationResult<EmployeeStatutoryInsertMutation>;
export type EmployeeStatutoryInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeStatutoryInsertMutation, EmployeeStatutoryInsertMutationVariables>;
export const EmployeeStatutoryUpdateDocument = gql`
    mutation EmployeeStatutoryUpdate($EmployeeBankInfoInput: EmployeeBankInfoInput!, $EmployeeStatutoryInput: EmployeeStatutoryInput!) {
  EmployeeStatutoryUpdate(EmployeeBankInfoInput: $EmployeeBankInfoInput, EmployeeStatutoryInput: $EmployeeStatutoryInput)
}
    `;
export type EmployeeStatutoryUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeStatutoryUpdateMutation, EmployeeStatutoryUpdateMutationVariables>;

/**
 * __useEmployeeStatutoryUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeStatutoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeStatutoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeStatutoryUpdateMutation, { data, loading, error }] = useEmployeeStatutoryUpdateMutation({
 *   variables: {
 *      EmployeeBankInfoInput: // value for 'EmployeeBankInfoInput'
 *      EmployeeStatutoryInput: // value for 'EmployeeStatutoryInput'
 *   },
 * });
 */
export function useEmployeeStatutoryUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeStatutoryUpdateMutation, EmployeeStatutoryUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeStatutoryUpdateMutation, EmployeeStatutoryUpdateMutationVariables>(EmployeeStatutoryUpdateDocument, baseOptions);
      }
export type EmployeeStatutoryUpdateMutationHookResult = ReturnType<typeof useEmployeeStatutoryUpdateMutation>;
export type EmployeeStatutoryUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeStatutoryUpdateMutation>;
export type EmployeeStatutoryUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeStatutoryUpdateMutation, EmployeeStatutoryUpdateMutationVariables>;
export const EmployeePrevContributionInsertDocument = gql`
    mutation EmployeePrevContributionInsert($EmployeePrevContributionInput: EmployeePrevContributionInput!) {
  EmployeePrevContributionInsert(EmployeePrevContributionInput: $EmployeePrevContributionInput) {
    EmployeePrevContributionID
  }
}
    `;
export type EmployeePrevContributionInsertMutationFn = ApolloReactCommon.MutationFunction<EmployeePrevContributionInsertMutation, EmployeePrevContributionInsertMutationVariables>;

/**
 * __useEmployeePrevContributionInsertMutation__
 *
 * To run a mutation, you first call `useEmployeePrevContributionInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeePrevContributionInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeePrevContributionInsertMutation, { data, loading, error }] = useEmployeePrevContributionInsertMutation({
 *   variables: {
 *      EmployeePrevContributionInput: // value for 'EmployeePrevContributionInput'
 *   },
 * });
 */
export function useEmployeePrevContributionInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeePrevContributionInsertMutation, EmployeePrevContributionInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeePrevContributionInsertMutation, EmployeePrevContributionInsertMutationVariables>(EmployeePrevContributionInsertDocument, baseOptions);
      }
export type EmployeePrevContributionInsertMutationHookResult = ReturnType<typeof useEmployeePrevContributionInsertMutation>;
export type EmployeePrevContributionInsertMutationResult = ApolloReactCommon.MutationResult<EmployeePrevContributionInsertMutation>;
export type EmployeePrevContributionInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeePrevContributionInsertMutation, EmployeePrevContributionInsertMutationVariables>;
export const EmployeePrevContributionUpdateDocument = gql`
    mutation EmployeePrevContributionUpdate($EmployeePrevContributionInput: EmployeePrevContributionInput!) {
  EmployeePrevContributionUpdate(EmployeePrevContributionInput: $EmployeePrevContributionInput)
}
    `;
export type EmployeePrevContributionUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeePrevContributionUpdateMutation, EmployeePrevContributionUpdateMutationVariables>;

/**
 * __useEmployeePrevContributionUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeePrevContributionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeePrevContributionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeePrevContributionUpdateMutation, { data, loading, error }] = useEmployeePrevContributionUpdateMutation({
 *   variables: {
 *      EmployeePrevContributionInput: // value for 'EmployeePrevContributionInput'
 *   },
 * });
 */
export function useEmployeePrevContributionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeePrevContributionUpdateMutation, EmployeePrevContributionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeePrevContributionUpdateMutation, EmployeePrevContributionUpdateMutationVariables>(EmployeePrevContributionUpdateDocument, baseOptions);
      }
export type EmployeePrevContributionUpdateMutationHookResult = ReturnType<typeof useEmployeePrevContributionUpdateMutation>;
export type EmployeePrevContributionUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeePrevContributionUpdateMutation>;
export type EmployeePrevContributionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeePrevContributionUpdateMutation, EmployeePrevContributionUpdateMutationVariables>;
export const EmployeeAssetListingDocument = gql`
    query EmployeeAssetListing($EmployeeID: String!) {
  EmployeeAssetListing(EmployeeID: $EmployeeID) {
    EmployeeAssetID
    EmployeeID
    DateAssign
    Item
    Remark
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useEmployeeAssetListingQuery__
 *
 * To run a query within a React component, call `useEmployeeAssetListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAssetListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeAssetListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeAssetListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeAssetListingQuery, EmployeeAssetListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeAssetListingQuery, EmployeeAssetListingQueryVariables>(EmployeeAssetListingDocument, baseOptions);
      }
export function useEmployeeAssetListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeAssetListingQuery, EmployeeAssetListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeAssetListingQuery, EmployeeAssetListingQueryVariables>(EmployeeAssetListingDocument, baseOptions);
        }
export type EmployeeAssetListingQueryHookResult = ReturnType<typeof useEmployeeAssetListingQuery>;
export type EmployeeAssetListingLazyQueryHookResult = ReturnType<typeof useEmployeeAssetListingLazyQuery>;
export type EmployeeAssetListingQueryResult = ApolloReactCommon.QueryResult<EmployeeAssetListingQuery, EmployeeAssetListingQueryVariables>;
export const EmployeeAssetDeleteDocument = gql`
    mutation EmployeeAssetDelete($EmployeeAssetID: String!) {
  EmployeeAssetDelete(EmployeeAssetID: $EmployeeAssetID)
}
    `;
export type EmployeeAssetDeleteMutationFn = ApolloReactCommon.MutationFunction<EmployeeAssetDeleteMutation, EmployeeAssetDeleteMutationVariables>;

/**
 * __useEmployeeAssetDeleteMutation__
 *
 * To run a mutation, you first call `useEmployeeAssetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAssetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAssetDeleteMutation, { data, loading, error }] = useEmployeeAssetDeleteMutation({
 *   variables: {
 *      EmployeeAssetID: // value for 'EmployeeAssetID'
 *   },
 * });
 */
export function useEmployeeAssetDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeAssetDeleteMutation, EmployeeAssetDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeAssetDeleteMutation, EmployeeAssetDeleteMutationVariables>(EmployeeAssetDeleteDocument, baseOptions);
      }
export type EmployeeAssetDeleteMutationHookResult = ReturnType<typeof useEmployeeAssetDeleteMutation>;
export type EmployeeAssetDeleteMutationResult = ApolloReactCommon.MutationResult<EmployeeAssetDeleteMutation>;
export type EmployeeAssetDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeAssetDeleteMutation, EmployeeAssetDeleteMutationVariables>;
export const EmployeeAssetDetailDocument = gql`
    query EmployeeAssetDetail($EmployeeAssetID: String!) {
  EmployeeAssetDetail(EmployeeAssetID: $EmployeeAssetID) {
    EmployeeAssetID
    EmployeeID
    DateAssign
    Item
    Remark
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useEmployeeAssetDetailQuery__
 *
 * To run a query within a React component, call `useEmployeeAssetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAssetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeAssetDetailQuery({
 *   variables: {
 *      EmployeeAssetID: // value for 'EmployeeAssetID'
 *   },
 * });
 */
export function useEmployeeAssetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeAssetDetailQuery, EmployeeAssetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeAssetDetailQuery, EmployeeAssetDetailQueryVariables>(EmployeeAssetDetailDocument, baseOptions);
      }
export function useEmployeeAssetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeAssetDetailQuery, EmployeeAssetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeAssetDetailQuery, EmployeeAssetDetailQueryVariables>(EmployeeAssetDetailDocument, baseOptions);
        }
export type EmployeeAssetDetailQueryHookResult = ReturnType<typeof useEmployeeAssetDetailQuery>;
export type EmployeeAssetDetailLazyQueryHookResult = ReturnType<typeof useEmployeeAssetDetailLazyQuery>;
export type EmployeeAssetDetailQueryResult = ApolloReactCommon.QueryResult<EmployeeAssetDetailQuery, EmployeeAssetDetailQueryVariables>;
export const EmployeeAssetUpdateDocument = gql`
    mutation EmployeeAssetUpdate($EmployeeAssetID: String!, $EmployeeAssetInput: EmployeeAssetInput!) {
  EmployeeAssetUpdate(EmployeeAssetID: $EmployeeAssetID, EmployeeAssetInput: $EmployeeAssetInput)
}
    `;
export type EmployeeAssetUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeAssetUpdateMutation, EmployeeAssetUpdateMutationVariables>;

/**
 * __useEmployeeAssetUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeAssetUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAssetUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAssetUpdateMutation, { data, loading, error }] = useEmployeeAssetUpdateMutation({
 *   variables: {
 *      EmployeeAssetID: // value for 'EmployeeAssetID'
 *      EmployeeAssetInput: // value for 'EmployeeAssetInput'
 *   },
 * });
 */
export function useEmployeeAssetUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeAssetUpdateMutation, EmployeeAssetUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeAssetUpdateMutation, EmployeeAssetUpdateMutationVariables>(EmployeeAssetUpdateDocument, baseOptions);
      }
export type EmployeeAssetUpdateMutationHookResult = ReturnType<typeof useEmployeeAssetUpdateMutation>;
export type EmployeeAssetUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeAssetUpdateMutation>;
export type EmployeeAssetUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeAssetUpdateMutation, EmployeeAssetUpdateMutationVariables>;
export const EmployeeAssetInsertDocument = gql`
    mutation EmployeeAssetInsert($EmployeeAssetInput: EmployeeAssetInput!) {
  EmployeeAssetInsert(EmployeeAssetInput: $EmployeeAssetInput) {
    EmployeeAssetID
  }
}
    `;
export type EmployeeAssetInsertMutationFn = ApolloReactCommon.MutationFunction<EmployeeAssetInsertMutation, EmployeeAssetInsertMutationVariables>;

/**
 * __useEmployeeAssetInsertMutation__
 *
 * To run a mutation, you first call `useEmployeeAssetInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAssetInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAssetInsertMutation, { data, loading, error }] = useEmployeeAssetInsertMutation({
 *   variables: {
 *      EmployeeAssetInput: // value for 'EmployeeAssetInput'
 *   },
 * });
 */
export function useEmployeeAssetInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeAssetInsertMutation, EmployeeAssetInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeAssetInsertMutation, EmployeeAssetInsertMutationVariables>(EmployeeAssetInsertDocument, baseOptions);
      }
export type EmployeeAssetInsertMutationHookResult = ReturnType<typeof useEmployeeAssetInsertMutation>;
export type EmployeeAssetInsertMutationResult = ApolloReactCommon.MutationResult<EmployeeAssetInsertMutation>;
export type EmployeeAssetInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeAssetInsertMutation, EmployeeAssetInsertMutationVariables>;
export const GetEmployeePrevContributionDocument = gql`
    query GetEmployeePrevContribution($EmployeeID: String!, $PeriodYear: Float) {
  GetEmployeePrevContribution(EmployeeID: $EmployeeID, PeriodYear: $PeriodYear) {
    EmployeePrevContributionID
    CompanyID
    EmployeeID
    PeriodYear
    GrossTaxable
    EPFPaid
    PCBPaid
    ZAKATPaid
  }
}
    `;

/**
 * __useGetEmployeePrevContributionQuery__
 *
 * To run a query within a React component, call `useGetEmployeePrevContributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeePrevContributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeePrevContributionQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      PeriodYear: // value for 'PeriodYear'
 *   },
 * });
 */
export function useGetEmployeePrevContributionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeePrevContributionQuery, GetEmployeePrevContributionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeePrevContributionQuery, GetEmployeePrevContributionQueryVariables>(GetEmployeePrevContributionDocument, baseOptions);
      }
export function useGetEmployeePrevContributionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeePrevContributionQuery, GetEmployeePrevContributionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeePrevContributionQuery, GetEmployeePrevContributionQueryVariables>(GetEmployeePrevContributionDocument, baseOptions);
        }
export type GetEmployeePrevContributionQueryHookResult = ReturnType<typeof useGetEmployeePrevContributionQuery>;
export type GetEmployeePrevContributionLazyQueryHookResult = ReturnType<typeof useGetEmployeePrevContributionLazyQuery>;
export type GetEmployeePrevContributionQueryResult = ApolloReactCommon.QueryResult<GetEmployeePrevContributionQuery, GetEmployeePrevContributionQueryVariables>;
export const CareerLogUpdateDocument = gql`
    mutation CareerLogUpdate($CareerLogInput: CareerLogInput!, $DocumentsInput: DocumentsInput!, $DocumentID: String!) {
  CareerLogUpdate(CareerLogInput: $CareerLogInput, DocumentsInput: $DocumentsInput, DocumentID: $DocumentID)
}
    `;
export type CareerLogUpdateMutationFn = ApolloReactCommon.MutationFunction<CareerLogUpdateMutation, CareerLogUpdateMutationVariables>;

/**
 * __useCareerLogUpdateMutation__
 *
 * To run a mutation, you first call `useCareerLogUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareerLogUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careerLogUpdateMutation, { data, loading, error }] = useCareerLogUpdateMutation({
 *   variables: {
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useCareerLogUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CareerLogUpdateMutation, CareerLogUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CareerLogUpdateMutation, CareerLogUpdateMutationVariables>(CareerLogUpdateDocument, baseOptions);
      }
export type CareerLogUpdateMutationHookResult = ReturnType<typeof useCareerLogUpdateMutation>;
export type CareerLogUpdateMutationResult = ApolloReactCommon.MutationResult<CareerLogUpdateMutation>;
export type CareerLogUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CareerLogUpdateMutation, CareerLogUpdateMutationVariables>;
export const CareerLogInsertDocument = gql`
    mutation CareerLogInsert($CareerLogInput: CareerLogInput!, $DocumentsInput: DocumentsInput!) {
  CareerLogInsert(CareerLogInput: $CareerLogInput, DocumentsInput: $DocumentsInput)
}
    `;
export type CareerLogInsertMutationFn = ApolloReactCommon.MutationFunction<CareerLogInsertMutation, CareerLogInsertMutationVariables>;

/**
 * __useCareerLogInsertMutation__
 *
 * To run a mutation, you first call `useCareerLogInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareerLogInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careerLogInsertMutation, { data, loading, error }] = useCareerLogInsertMutation({
 *   variables: {
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useCareerLogInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CareerLogInsertMutation, CareerLogInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CareerLogInsertMutation, CareerLogInsertMutationVariables>(CareerLogInsertDocument, baseOptions);
      }
export type CareerLogInsertMutationHookResult = ReturnType<typeof useCareerLogInsertMutation>;
export type CareerLogInsertMutationResult = ApolloReactCommon.MutationResult<CareerLogInsertMutation>;
export type CareerLogInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CareerLogInsertMutation, CareerLogInsertMutationVariables>;
export const EmployeeDependentInsertDocument = gql`
    mutation EmployeeDependentInsert($DependentContactInput: ContactInput!, $EmployeeDependentsInput: EmployeeDependentsInput!, $DocumentsInput: DocumentsInput) {
  EmployeeDependentInsert(DependentContactInput: $DependentContactInput, EmployeeDependentsInput: $EmployeeDependentsInput, DocumentsInput: $DocumentsInput) {
    EmployeeDependentsID
    ContactID
  }
}
    `;
export type EmployeeDependentInsertMutationFn = ApolloReactCommon.MutationFunction<EmployeeDependentInsertMutation, EmployeeDependentInsertMutationVariables>;

/**
 * __useEmployeeDependentInsertMutation__
 *
 * To run a mutation, you first call `useEmployeeDependentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDependentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeDependentInsertMutation, { data, loading, error }] = useEmployeeDependentInsertMutation({
 *   variables: {
 *      DependentContactInput: // value for 'DependentContactInput'
 *      EmployeeDependentsInput: // value for 'EmployeeDependentsInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useEmployeeDependentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeDependentInsertMutation, EmployeeDependentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeDependentInsertMutation, EmployeeDependentInsertMutationVariables>(EmployeeDependentInsertDocument, baseOptions);
      }
export type EmployeeDependentInsertMutationHookResult = ReturnType<typeof useEmployeeDependentInsertMutation>;
export type EmployeeDependentInsertMutationResult = ApolloReactCommon.MutationResult<EmployeeDependentInsertMutation>;
export type EmployeeDependentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeDependentInsertMutation, EmployeeDependentInsertMutationVariables>;
export const EmployeeDependentUpdateDocument = gql`
    mutation EmployeeDependentUpdate($DependentContactInput: ContactInput!, $EmployeeDependentsInput: EmployeeDependentsInput!, $DocumentsInput: DocumentsInput) {
  EmployeeDependentUpdate(DependentContactInput: $DependentContactInput, EmployeeDependentsInput: $EmployeeDependentsInput, DocumentsInput: $DocumentsInput)
}
    `;
export type EmployeeDependentUpdateMutationFn = ApolloReactCommon.MutationFunction<EmployeeDependentUpdateMutation, EmployeeDependentUpdateMutationVariables>;

/**
 * __useEmployeeDependentUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeDependentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDependentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeDependentUpdateMutation, { data, loading, error }] = useEmployeeDependentUpdateMutation({
 *   variables: {
 *      DependentContactInput: // value for 'DependentContactInput'
 *      EmployeeDependentsInput: // value for 'EmployeeDependentsInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useEmployeeDependentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeDependentUpdateMutation, EmployeeDependentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeDependentUpdateMutation, EmployeeDependentUpdateMutationVariables>(EmployeeDependentUpdateDocument, baseOptions);
      }
export type EmployeeDependentUpdateMutationHookResult = ReturnType<typeof useEmployeeDependentUpdateMutation>;
export type EmployeeDependentUpdateMutationResult = ApolloReactCommon.MutationResult<EmployeeDependentUpdateMutation>;
export type EmployeeDependentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeDependentUpdateMutation, EmployeeDependentUpdateMutationVariables>;
export const EmployeeDependentInsertByEmployeeDocument = gql`
    mutation EmployeeDependentInsertByEmployee($CompanyID: String!, $SaveAction: ApprovalStatus!, $DependentContactInput: ContactInput!, $EmployeeDependentsInput: EmployeeDependentsInput!, $DocumentsInput: DocumentsInput) {
  EmployeeDependentInsertByEmployee(CompanyID: $CompanyID, SaveAction: $SaveAction, DependentContactInput: $DependentContactInput, EmployeeDependentsInput: $EmployeeDependentsInput, DocumentsInput: $DocumentsInput) {
    EmployeeDependentsID
    ContactID
  }
}
    `;
export type EmployeeDependentInsertByEmployeeMutationFn = ApolloReactCommon.MutationFunction<EmployeeDependentInsertByEmployeeMutation, EmployeeDependentInsertByEmployeeMutationVariables>;

/**
 * __useEmployeeDependentInsertByEmployeeMutation__
 *
 * To run a mutation, you first call `useEmployeeDependentInsertByEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDependentInsertByEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeDependentInsertByEmployeeMutation, { data, loading, error }] = useEmployeeDependentInsertByEmployeeMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SaveAction: // value for 'SaveAction'
 *      DependentContactInput: // value for 'DependentContactInput'
 *      EmployeeDependentsInput: // value for 'EmployeeDependentsInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useEmployeeDependentInsertByEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeDependentInsertByEmployeeMutation, EmployeeDependentInsertByEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeDependentInsertByEmployeeMutation, EmployeeDependentInsertByEmployeeMutationVariables>(EmployeeDependentInsertByEmployeeDocument, baseOptions);
      }
export type EmployeeDependentInsertByEmployeeMutationHookResult = ReturnType<typeof useEmployeeDependentInsertByEmployeeMutation>;
export type EmployeeDependentInsertByEmployeeMutationResult = ApolloReactCommon.MutationResult<EmployeeDependentInsertByEmployeeMutation>;
export type EmployeeDependentInsertByEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeDependentInsertByEmployeeMutation, EmployeeDependentInsertByEmployeeMutationVariables>;
export const EmployeeDependentUpdateByEmployeeDocument = gql`
    mutation EmployeeDependentUpdateByEmployee($CompanyID: String!, $SaveAction: ApprovalStatus!, $DependentContactInput: ContactInput!, $EmployeeDependentsInput: EmployeeDependentsInput!, $DocumentsInput: DocumentsInput) {
  EmployeeDependentUpdateByEmployee(CompanyID: $CompanyID, SaveAction: $SaveAction, DependentContactInput: $DependentContactInput, EmployeeDependentsInput: $EmployeeDependentsInput, DocumentsInput: $DocumentsInput)
}
    `;
export type EmployeeDependentUpdateByEmployeeMutationFn = ApolloReactCommon.MutationFunction<EmployeeDependentUpdateByEmployeeMutation, EmployeeDependentUpdateByEmployeeMutationVariables>;

/**
 * __useEmployeeDependentUpdateByEmployeeMutation__
 *
 * To run a mutation, you first call `useEmployeeDependentUpdateByEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDependentUpdateByEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeDependentUpdateByEmployeeMutation, { data, loading, error }] = useEmployeeDependentUpdateByEmployeeMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SaveAction: // value for 'SaveAction'
 *      DependentContactInput: // value for 'DependentContactInput'
 *      EmployeeDependentsInput: // value for 'EmployeeDependentsInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useEmployeeDependentUpdateByEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmployeeDependentUpdateByEmployeeMutation, EmployeeDependentUpdateByEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<EmployeeDependentUpdateByEmployeeMutation, EmployeeDependentUpdateByEmployeeMutationVariables>(EmployeeDependentUpdateByEmployeeDocument, baseOptions);
      }
export type EmployeeDependentUpdateByEmployeeMutationHookResult = ReturnType<typeof useEmployeeDependentUpdateByEmployeeMutation>;
export type EmployeeDependentUpdateByEmployeeMutationResult = ApolloReactCommon.MutationResult<EmployeeDependentUpdateByEmployeeMutation>;
export type EmployeeDependentUpdateByEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<EmployeeDependentUpdateByEmployeeMutation, EmployeeDependentUpdateByEmployeeMutationVariables>;
export const GetEmployeeAppraisalDocument = gql`
    query GetEmployeeAppraisal($EmployeeAppraisalID: String) {
  GetEmployeeAppraisal(EmployeeAppraisalID: $EmployeeAppraisalID) {
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    EmployeeAppraisalID
    EmployeeID
    AppraisalRecord
    Date
    Rating
    Score
    AppraiseeRemark
    AppraiserRemark
    AppraiserName
    DocumentFile
    BucketFileName
    Employee {
      EmployeeID
    }
  }
}
    `;

/**
 * __useGetEmployeeAppraisalQuery__
 *
 * To run a query within a React component, call `useGetEmployeeAppraisalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeAppraisalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeAppraisalQuery({
 *   variables: {
 *      EmployeeAppraisalID: // value for 'EmployeeAppraisalID'
 *   },
 * });
 */
export function useGetEmployeeAppraisalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeAppraisalQuery, GetEmployeeAppraisalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeAppraisalQuery, GetEmployeeAppraisalQueryVariables>(GetEmployeeAppraisalDocument, baseOptions);
      }
export function useGetEmployeeAppraisalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeAppraisalQuery, GetEmployeeAppraisalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeAppraisalQuery, GetEmployeeAppraisalQueryVariables>(GetEmployeeAppraisalDocument, baseOptions);
        }
export type GetEmployeeAppraisalQueryHookResult = ReturnType<typeof useGetEmployeeAppraisalQuery>;
export type GetEmployeeAppraisalLazyQueryHookResult = ReturnType<typeof useGetEmployeeAppraisalLazyQuery>;
export type GetEmployeeAppraisalQueryResult = ApolloReactCommon.QueryResult<GetEmployeeAppraisalQuery, GetEmployeeAppraisalQueryVariables>;
export const GetEmployeeDiaryDocument = gql`
    query GetEmployeeDiary($EmployeeID: String) {
  GetEmployeeDiary(EmployeeID: $EmployeeID) {
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    EmployeeDiaryID
    EmployeeID
    Date
    CaseNo
    Description
    Countermeasure
    DocumentID
    Documents {
      DocumentFile
    }
    Private
    Employee {
      EmployeeID
    }
  }
}
    `;

/**
 * __useGetEmployeeDiaryQuery__
 *
 * To run a query within a React component, call `useGetEmployeeDiaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeDiaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeDiaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetEmployeeDiaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeDiaryQuery, GetEmployeeDiaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeDiaryQuery, GetEmployeeDiaryQueryVariables>(GetEmployeeDiaryDocument, baseOptions);
      }
export function useGetEmployeeDiaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeDiaryQuery, GetEmployeeDiaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeDiaryQuery, GetEmployeeDiaryQueryVariables>(GetEmployeeDiaryDocument, baseOptions);
        }
export type GetEmployeeDiaryQueryHookResult = ReturnType<typeof useGetEmployeeDiaryQuery>;
export type GetEmployeeDiaryLazyQueryHookResult = ReturnType<typeof useGetEmployeeDiaryLazyQuery>;
export type GetEmployeeDiaryQueryResult = ApolloReactCommon.QueryResult<GetEmployeeDiaryQuery, GetEmployeeDiaryQueryVariables>;
export const CreateEmployeeInsuranceSchemeDocument = gql`
    mutation CreateEmployeeInsuranceScheme($EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput!) {
  CreateEmployeeInsuranceScheme(EmployeeInsuranceSchemeInput: $EmployeeInsuranceSchemeInput)
}
    `;
export type CreateEmployeeInsuranceSchemeMutationFn = ApolloReactCommon.MutationFunction<CreateEmployeeInsuranceSchemeMutation, CreateEmployeeInsuranceSchemeMutationVariables>;

/**
 * __useCreateEmployeeInsuranceSchemeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeInsuranceSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeInsuranceSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeInsuranceSchemeMutation, { data, loading, error }] = useCreateEmployeeInsuranceSchemeMutation({
 *   variables: {
 *      EmployeeInsuranceSchemeInput: // value for 'EmployeeInsuranceSchemeInput'
 *   },
 * });
 */
export function useCreateEmployeeInsuranceSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEmployeeInsuranceSchemeMutation, CreateEmployeeInsuranceSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEmployeeInsuranceSchemeMutation, CreateEmployeeInsuranceSchemeMutationVariables>(CreateEmployeeInsuranceSchemeDocument, baseOptions);
      }
export type CreateEmployeeInsuranceSchemeMutationHookResult = ReturnType<typeof useCreateEmployeeInsuranceSchemeMutation>;
export type CreateEmployeeInsuranceSchemeMutationResult = ApolloReactCommon.MutationResult<CreateEmployeeInsuranceSchemeMutation>;
export type CreateEmployeeInsuranceSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmployeeInsuranceSchemeMutation, CreateEmployeeInsuranceSchemeMutationVariables>;
export const UpdateEmployeeInsuranceSchemeDocument = gql`
    mutation UpdateEmployeeInsuranceScheme($EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput!, $EmployeeInsuranceSchemeID: String!) {
  UpdateEmployeeInsuranceScheme(EmployeeInsuranceSchemeInput: $EmployeeInsuranceSchemeInput, EmployeeInsuranceSchemeID: $EmployeeInsuranceSchemeID)
}
    `;
export type UpdateEmployeeInsuranceSchemeMutationFn = ApolloReactCommon.MutationFunction<UpdateEmployeeInsuranceSchemeMutation, UpdateEmployeeInsuranceSchemeMutationVariables>;

/**
 * __useUpdateEmployeeInsuranceSchemeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeInsuranceSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeInsuranceSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeInsuranceSchemeMutation, { data, loading, error }] = useUpdateEmployeeInsuranceSchemeMutation({
 *   variables: {
 *      EmployeeInsuranceSchemeInput: // value for 'EmployeeInsuranceSchemeInput'
 *      EmployeeInsuranceSchemeID: // value for 'EmployeeInsuranceSchemeID'
 *   },
 * });
 */
export function useUpdateEmployeeInsuranceSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmployeeInsuranceSchemeMutation, UpdateEmployeeInsuranceSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmployeeInsuranceSchemeMutation, UpdateEmployeeInsuranceSchemeMutationVariables>(UpdateEmployeeInsuranceSchemeDocument, baseOptions);
      }
export type UpdateEmployeeInsuranceSchemeMutationHookResult = ReturnType<typeof useUpdateEmployeeInsuranceSchemeMutation>;
export type UpdateEmployeeInsuranceSchemeMutationResult = ApolloReactCommon.MutationResult<UpdateEmployeeInsuranceSchemeMutation>;
export type UpdateEmployeeInsuranceSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmployeeInsuranceSchemeMutation, UpdateEmployeeInsuranceSchemeMutationVariables>;
export const DeleteEmployeeInsuranceSchemeDocument = gql`
    mutation DeleteEmployeeInsuranceScheme($EmployeeInsuranceSchemeID: String!) {
  DeleteEmployeeInsuranceScheme(EmployeeInsuranceSchemeID: $EmployeeInsuranceSchemeID)
}
    `;
export type DeleteEmployeeInsuranceSchemeMutationFn = ApolloReactCommon.MutationFunction<DeleteEmployeeInsuranceSchemeMutation, DeleteEmployeeInsuranceSchemeMutationVariables>;

/**
 * __useDeleteEmployeeInsuranceSchemeMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeInsuranceSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeInsuranceSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeInsuranceSchemeMutation, { data, loading, error }] = useDeleteEmployeeInsuranceSchemeMutation({
 *   variables: {
 *      EmployeeInsuranceSchemeID: // value for 'EmployeeInsuranceSchemeID'
 *   },
 * });
 */
export function useDeleteEmployeeInsuranceSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEmployeeInsuranceSchemeMutation, DeleteEmployeeInsuranceSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteEmployeeInsuranceSchemeMutation, DeleteEmployeeInsuranceSchemeMutationVariables>(DeleteEmployeeInsuranceSchemeDocument, baseOptions);
      }
export type DeleteEmployeeInsuranceSchemeMutationHookResult = ReturnType<typeof useDeleteEmployeeInsuranceSchemeMutation>;
export type DeleteEmployeeInsuranceSchemeMutationResult = ApolloReactCommon.MutationResult<DeleteEmployeeInsuranceSchemeMutation>;
export type DeleteEmployeeInsuranceSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteEmployeeInsuranceSchemeMutation, DeleteEmployeeInsuranceSchemeMutationVariables>;
export const TemplateLibraryTargetListingDocument = gql`
    query TemplateLibraryTargetListing {
  getTemplateLibrary {
    TargetID
    TargetType
    SourceID
    ModifiedBy
    ModifiedDT
    CreatedBy
    CreatedDT
  }
}
    `;

/**
 * __useTemplateLibraryTargetListingQuery__
 *
 * To run a query within a React component, call `useTemplateLibraryTargetListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateLibraryTargetListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateLibraryTargetListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateLibraryTargetListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TemplateLibraryTargetListingQuery, TemplateLibraryTargetListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TemplateLibraryTargetListingQuery, TemplateLibraryTargetListingQueryVariables>(TemplateLibraryTargetListingDocument, baseOptions);
      }
export function useTemplateLibraryTargetListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplateLibraryTargetListingQuery, TemplateLibraryTargetListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TemplateLibraryTargetListingQuery, TemplateLibraryTargetListingQueryVariables>(TemplateLibraryTargetListingDocument, baseOptions);
        }
export type TemplateLibraryTargetListingQueryHookResult = ReturnType<typeof useTemplateLibraryTargetListingQuery>;
export type TemplateLibraryTargetListingLazyQueryHookResult = ReturnType<typeof useTemplateLibraryTargetListingLazyQuery>;
export type TemplateLibraryTargetListingQueryResult = ApolloReactCommon.QueryResult<TemplateLibraryTargetListingQuery, TemplateLibraryTargetListingQueryVariables>;
export const PersonnelInsuranceSchemePersonnelListingDocument = gql`
    query PersonnelInsuranceSchemePersonnelListing($InsuranceSchemeID: String!, $EmployeeInsuranceSchemeID: String, $CompanyID: String, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  PersonnelInsuranceSchemePersonnelListing(InsuranceSchemeID: $InsuranceSchemeID, EmployeeInsuranceSchemeID: $EmployeeInsuranceSchemeID, CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID) {
    EmployeeInsuranceSchemeID
    EmployeeID
    InsuranceSchemeID
    StartDate
    EndDate
    Status
    Employee {
      JobGradeID
      OrgUnitID
      FormStatus
      Documents {
        DocumentFile
        DocumentID
        Description
        BucketFileName
      }
      Contact {
        FullName
      }
      JobGrade {
        Description
      }
      EmployeeNo
      Designation {
        Name
      }
    }
    InsuranceScheme {
      Name
      DocumentID
      Document {
        Description
        DocumentFile
        FileSize
        BucketFileName
        DocumentType
        CreatedDT
        CreatedBy
        ModifiedDT
        ModifiedBy
      }
    }
  }
}
    `;

/**
 * __usePersonnelInsuranceSchemePersonnelListingQuery__
 *
 * To run a query within a React component, call `usePersonnelInsuranceSchemePersonnelListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelInsuranceSchemePersonnelListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelInsuranceSchemePersonnelListingQuery({
 *   variables: {
 *      InsuranceSchemeID: // value for 'InsuranceSchemeID'
 *      EmployeeInsuranceSchemeID: // value for 'EmployeeInsuranceSchemeID'
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function usePersonnelInsuranceSchemePersonnelListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelInsuranceSchemePersonnelListingQuery, PersonnelInsuranceSchemePersonnelListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelInsuranceSchemePersonnelListingQuery, PersonnelInsuranceSchemePersonnelListingQueryVariables>(PersonnelInsuranceSchemePersonnelListingDocument, baseOptions);
      }
export function usePersonnelInsuranceSchemePersonnelListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelInsuranceSchemePersonnelListingQuery, PersonnelInsuranceSchemePersonnelListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelInsuranceSchemePersonnelListingQuery, PersonnelInsuranceSchemePersonnelListingQueryVariables>(PersonnelInsuranceSchemePersonnelListingDocument, baseOptions);
        }
export type PersonnelInsuranceSchemePersonnelListingQueryHookResult = ReturnType<typeof usePersonnelInsuranceSchemePersonnelListingQuery>;
export type PersonnelInsuranceSchemePersonnelListingLazyQueryHookResult = ReturnType<typeof usePersonnelInsuranceSchemePersonnelListingLazyQuery>;
export type PersonnelInsuranceSchemePersonnelListingQueryResult = ApolloReactCommon.QueryResult<PersonnelInsuranceSchemePersonnelListingQuery, PersonnelInsuranceSchemePersonnelListingQueryVariables>;
export const PersonnelInsuranceSchemeBulkInsertDocument = gql`
    mutation PersonnelInsuranceSchemeBulkInsert($EndDate: DateTime!, $StartDate: DateTime!, $PersonnelIDs: [String!]!, $InsuranceSchemeID: String!) {
  PersonnelInsuranceSchemeBulkInsert(EndDate: $EndDate, StartDate: $StartDate, PersonnelIDs: $PersonnelIDs, InsuranceSchemeID: $InsuranceSchemeID)
}
    `;
export type PersonnelInsuranceSchemeBulkInsertMutationFn = ApolloReactCommon.MutationFunction<PersonnelInsuranceSchemeBulkInsertMutation, PersonnelInsuranceSchemeBulkInsertMutationVariables>;

/**
 * __usePersonnelInsuranceSchemeBulkInsertMutation__
 *
 * To run a mutation, you first call `usePersonnelInsuranceSchemeBulkInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelInsuranceSchemeBulkInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelInsuranceSchemeBulkInsertMutation, { data, loading, error }] = usePersonnelInsuranceSchemeBulkInsertMutation({
 *   variables: {
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      PersonnelIDs: // value for 'PersonnelIDs'
 *      InsuranceSchemeID: // value for 'InsuranceSchemeID'
 *   },
 * });
 */
export function usePersonnelInsuranceSchemeBulkInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelInsuranceSchemeBulkInsertMutation, PersonnelInsuranceSchemeBulkInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelInsuranceSchemeBulkInsertMutation, PersonnelInsuranceSchemeBulkInsertMutationVariables>(PersonnelInsuranceSchemeBulkInsertDocument, baseOptions);
      }
export type PersonnelInsuranceSchemeBulkInsertMutationHookResult = ReturnType<typeof usePersonnelInsuranceSchemeBulkInsertMutation>;
export type PersonnelInsuranceSchemeBulkInsertMutationResult = ApolloReactCommon.MutationResult<PersonnelInsuranceSchemeBulkInsertMutation>;
export type PersonnelInsuranceSchemeBulkInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelInsuranceSchemeBulkInsertMutation, PersonnelInsuranceSchemeBulkInsertMutationVariables>;
export const PersonnelInsuranceSchemeUpdateDocument = gql`
    mutation PersonnelInsuranceSchemeUpdate($EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput!, $EmployeeInsuranceSchemeID: String!) {
  PersonnelInsuranceSchemeUpdate(EmployeeInsuranceSchemeInput: $EmployeeInsuranceSchemeInput, EmployeeInsuranceSchemeID: $EmployeeInsuranceSchemeID)
}
    `;
export type PersonnelInsuranceSchemeUpdateMutationFn = ApolloReactCommon.MutationFunction<PersonnelInsuranceSchemeUpdateMutation, PersonnelInsuranceSchemeUpdateMutationVariables>;

/**
 * __usePersonnelInsuranceSchemeUpdateMutation__
 *
 * To run a mutation, you first call `usePersonnelInsuranceSchemeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelInsuranceSchemeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelInsuranceSchemeUpdateMutation, { data, loading, error }] = usePersonnelInsuranceSchemeUpdateMutation({
 *   variables: {
 *      EmployeeInsuranceSchemeInput: // value for 'EmployeeInsuranceSchemeInput'
 *      EmployeeInsuranceSchemeID: // value for 'EmployeeInsuranceSchemeID'
 *   },
 * });
 */
export function usePersonnelInsuranceSchemeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelInsuranceSchemeUpdateMutation, PersonnelInsuranceSchemeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelInsuranceSchemeUpdateMutation, PersonnelInsuranceSchemeUpdateMutationVariables>(PersonnelInsuranceSchemeUpdateDocument, baseOptions);
      }
export type PersonnelInsuranceSchemeUpdateMutationHookResult = ReturnType<typeof usePersonnelInsuranceSchemeUpdateMutation>;
export type PersonnelInsuranceSchemeUpdateMutationResult = ApolloReactCommon.MutationResult<PersonnelInsuranceSchemeUpdateMutation>;
export type PersonnelInsuranceSchemeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelInsuranceSchemeUpdateMutation, PersonnelInsuranceSchemeUpdateMutationVariables>;
export const ProbationExtensionListingDocument = gql`
    query ProbationExtensionListing($CompanyID: String!, $OrgUnitID: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!]) {
  ProbationExtensionListing(CompanyID: $CompanyID, EmployeeType: $EmployeeType, EmployeeStatus: $EmployeeStatus, OrgUnitID: $OrgUnitID) {
    Employee {
      FormStatus
      OrgUnitID
      JobGradeID
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
      Department {
        Description
      }
      Division {
        Description
      }
      Designation {
        Name
      }
      Documents {
        BucketFileName
        Description
        DocumentFile
        DocumentID
      }
    }
    ProbationExtension {
      ModifiedDT
      ModifiedBy
      CreatedDT
      CreatedBy
      CareerLogID
      Remark
      MovementType
      FromDate
      EffectiveDate
      Document {
        BucketFileName
        Description
        DocumentFile
        DocumentID
      }
    }
  }
}
    `;

/**
 * __useProbationExtensionListingQuery__
 *
 * To run a query within a React component, call `useProbationExtensionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProbationExtensionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProbationExtensionListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *   },
 * });
 */
export function useProbationExtensionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProbationExtensionListingQuery, ProbationExtensionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProbationExtensionListingQuery, ProbationExtensionListingQueryVariables>(ProbationExtensionListingDocument, baseOptions);
      }
export function useProbationExtensionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProbationExtensionListingQuery, ProbationExtensionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProbationExtensionListingQuery, ProbationExtensionListingQueryVariables>(ProbationExtensionListingDocument, baseOptions);
        }
export type ProbationExtensionListingQueryHookResult = ReturnType<typeof useProbationExtensionListingQuery>;
export type ProbationExtensionListingLazyQueryHookResult = ReturnType<typeof useProbationExtensionListingLazyQuery>;
export type ProbationExtensionListingQueryResult = ApolloReactCommon.QueryResult<ProbationExtensionListingQuery, ProbationExtensionListingQueryVariables>;
export const StaffConfirmationProbationListingDocument = gql`
    query StaffConfirmationProbationListing($CompanyID: String!, $IsCompleted: Boolean, $OrgUnitID: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!]) {
  StaffConfirmationProbationListing(CompanyID: $CompanyID, IsCompleted: $IsCompleted, EmployeeType: $EmployeeType, EmployeeStatus: $EmployeeStatus, OrgUnitID: $OrgUnitID) {
    Contact {
      FullName
    }
    FormStatus
    CompanyID
    JobGradeID
    DepartmentID
    DivisionID
    DesignationID
    ReportTo
    EmployeeID
    Remark
    JoinedReferenceDate
    ConfirmedDate
    OrgUnit {
      Description
      CompanyHierarchyID
      CompanyOrgUnitID
    }
    EmployeeCareerLogs {
      CareerLogID
      MovementType
      TenderResignDate
      Remark
      Document {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
    }
    IsProbationExtended
    EmployeeNo
    JoinedDate
    ConfirmedDate
    Probation
    Supervisor {
      EmployeeNo
      Contact {
        FullName
      }
    }
    Department {
      Description
    }
    Division {
      Description
    }
    Designation {
      Name
    }
    JobGrade {
      Description
    }
    Status
    EmploymentType
    Documents {
      BucketFileName
      Description
      DocumentFile
      DocumentID
    }
    OrgUnit {
      Description
      CompanyHierarchyID
      CompanyOrgUnitID
    }
  }
}
    `;

/**
 * __useStaffConfirmationProbationListingQuery__
 *
 * To run a query within a React component, call `useStaffConfirmationProbationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffConfirmationProbationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffConfirmationProbationListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      IsCompleted: // value for 'IsCompleted'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *   },
 * });
 */
export function useStaffConfirmationProbationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffConfirmationProbationListingQuery, StaffConfirmationProbationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffConfirmationProbationListingQuery, StaffConfirmationProbationListingQueryVariables>(StaffConfirmationProbationListingDocument, baseOptions);
      }
export function useStaffConfirmationProbationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffConfirmationProbationListingQuery, StaffConfirmationProbationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffConfirmationProbationListingQuery, StaffConfirmationProbationListingQueryVariables>(StaffConfirmationProbationListingDocument, baseOptions);
        }
export type StaffConfirmationProbationListingQueryHookResult = ReturnType<typeof useStaffConfirmationProbationListingQuery>;
export type StaffConfirmationProbationListingLazyQueryHookResult = ReturnType<typeof useStaffConfirmationProbationListingLazyQuery>;
export type StaffConfirmationProbationListingQueryResult = ApolloReactCommon.QueryResult<StaffConfirmationProbationListingQuery, StaffConfirmationProbationListingQueryVariables>;
export const StaffConfirmationConfirmedCareerLogListingDocument = gql`
    query StaffConfirmationConfirmedCareerLogListing($EmployeeID: String, $CompanyID: String!, $OrgUnitID: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!]) {
  StaffConfirmationConfirmedCareerLogListing(EmployeeID: $EmployeeID, CompanyID: $CompanyID, EmployeeType: $EmployeeType, EmployeeStatus: $EmployeeStatus, OrgUnitID: $OrgUnitID) {
    CreatedBy
    ModifiedBy
    ModifiedDT
    CreatedDT
    Status
    CareerLogID
    EmployeeID
    MovementType
    Remark
    EffectiveDate
    FromDate
    FromJobGrade
    ToJobGrade
    FromOrgUnit
    ToOrgUnit
    MovementType
    Document {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    Employee {
      OrgUnitID
      Status
      EmploymentType
      JoinedDate
      ConfirmedDate
      Probation
      EmployeeNo
      EmployeeID
      Contact {
        FullName
      }
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
    }
    ToDivisionTable {
      Description
    }
    ToDepartmentTable {
      Description
    }
    ToPositionTable {
      Name
    }
    ToJobGradeTable {
      Description
    }
  }
}
    `;

/**
 * __useStaffConfirmationConfirmedCareerLogListingQuery__
 *
 * To run a query within a React component, call `useStaffConfirmationConfirmedCareerLogListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffConfirmationConfirmedCareerLogListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffConfirmationConfirmedCareerLogListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyID: // value for 'CompanyID'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *   },
 * });
 */
export function useStaffConfirmationConfirmedCareerLogListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffConfirmationConfirmedCareerLogListingQuery, StaffConfirmationConfirmedCareerLogListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffConfirmationConfirmedCareerLogListingQuery, StaffConfirmationConfirmedCareerLogListingQueryVariables>(StaffConfirmationConfirmedCareerLogListingDocument, baseOptions);
      }
export function useStaffConfirmationConfirmedCareerLogListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffConfirmationConfirmedCareerLogListingQuery, StaffConfirmationConfirmedCareerLogListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffConfirmationConfirmedCareerLogListingQuery, StaffConfirmationConfirmedCareerLogListingQueryVariables>(StaffConfirmationConfirmedCareerLogListingDocument, baseOptions);
        }
export type StaffConfirmationConfirmedCareerLogListingQueryHookResult = ReturnType<typeof useStaffConfirmationConfirmedCareerLogListingQuery>;
export type StaffConfirmationConfirmedCareerLogListingLazyQueryHookResult = ReturnType<typeof useStaffConfirmationConfirmedCareerLogListingLazyQuery>;
export type StaffConfirmationConfirmedCareerLogListingQueryResult = ApolloReactCommon.QueryResult<StaffConfirmationConfirmedCareerLogListingQuery, StaffConfirmationConfirmedCareerLogListingQueryVariables>;
export const StaffConfirmationConfirmedListingDocument = gql`
    query StaffConfirmationConfirmedListing($CompanyID: String!) {
  StaffConfirmationConfirmedListing(CompanyID: $CompanyID) {
    Contact {
      FullName
    }
    FormStatus
    CompanyID
    JobGradeID
    DepartmentID
    ReportTo
    DivisionID
    DesignationID
    ModifiedBy
    ModifiedDT
    CreatedBy
    CreatedDT
    EmployeeID
    EmployeeCareerLogs {
      CareerLogID
      MovementType
      TenderResignDate
      Remark
      CreatedDT
      CreatedBy
      ModifiedBy
      ModifiedDT
      Document {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
    }
    EmployeeNo
    JoinedDate
    ConfirmedDate
    Probation
    Supervisor {
      EmployeeNo
      Contact {
        FullName
      }
    }
    Department {
      Description
    }
    JobGrade {
      Description
    }
    Division {
      Description
    }
    Designation {
      Name
    }
    Status
    EmploymentType
    Documents {
      BucketFileName
      Description
      DocumentFile
      DocumentID
    }
    OrgUnit {
      Description
      CompanyHierarchyID
      CompanyOrgUnitID
    }
  }
}
    `;

/**
 * __useStaffConfirmationConfirmedListingQuery__
 *
 * To run a query within a React component, call `useStaffConfirmationConfirmedListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffConfirmationConfirmedListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffConfirmationConfirmedListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useStaffConfirmationConfirmedListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffConfirmationConfirmedListingQuery, StaffConfirmationConfirmedListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffConfirmationConfirmedListingQuery, StaffConfirmationConfirmedListingQueryVariables>(StaffConfirmationConfirmedListingDocument, baseOptions);
      }
export function useStaffConfirmationConfirmedListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffConfirmationConfirmedListingQuery, StaffConfirmationConfirmedListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffConfirmationConfirmedListingQuery, StaffConfirmationConfirmedListingQueryVariables>(StaffConfirmationConfirmedListingDocument, baseOptions);
        }
export type StaffConfirmationConfirmedListingQueryHookResult = ReturnType<typeof useStaffConfirmationConfirmedListingQuery>;
export type StaffConfirmationConfirmedListingLazyQueryHookResult = ReturnType<typeof useStaffConfirmationConfirmedListingLazyQuery>;
export type StaffConfirmationConfirmedListingQueryResult = ApolloReactCommon.QueryResult<StaffConfirmationConfirmedListingQuery, StaffConfirmationConfirmedListingQueryVariables>;
export const StaffConfirmationInsertDocument = gql`
    mutation StaffConfirmationInsert($DocumentInput: DocumentsInput, $CareerLogInput: CareerLogInput!, $ContentURL: Upload, $Content: String) {
  StaffConfirmationInsert(DocumentInput: $DocumentInput, CareerLogInput: $CareerLogInput, ContentURL: $ContentURL, Content: $Content)
}
    `;
export type StaffConfirmationInsertMutationFn = ApolloReactCommon.MutationFunction<StaffConfirmationInsertMutation, StaffConfirmationInsertMutationVariables>;

/**
 * __useStaffConfirmationInsertMutation__
 *
 * To run a mutation, you first call `useStaffConfirmationInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffConfirmationInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffConfirmationInsertMutation, { data, loading, error }] = useStaffConfirmationInsertMutation({
 *   variables: {
 *      DocumentInput: // value for 'DocumentInput'
 *      CareerLogInput: // value for 'CareerLogInput'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *   },
 * });
 */
export function useStaffConfirmationInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffConfirmationInsertMutation, StaffConfirmationInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffConfirmationInsertMutation, StaffConfirmationInsertMutationVariables>(StaffConfirmationInsertDocument, baseOptions);
      }
export type StaffConfirmationInsertMutationHookResult = ReturnType<typeof useStaffConfirmationInsertMutation>;
export type StaffConfirmationInsertMutationResult = ApolloReactCommon.MutationResult<StaffConfirmationInsertMutation>;
export type StaffConfirmationInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffConfirmationInsertMutation, StaffConfirmationInsertMutationVariables>;
export const StaffConfirmationUpdateDocument = gql`
    mutation StaffConfirmationUpdate($DocumentInput: DocumentsInput, $CareerLogInput: CareerLogInput!, $CareerLogID: String!, $IsAttachmentRemoved: Boolean!) {
  StaffConfirmationUpdate(DocumentInput: $DocumentInput, CareerLogInput: $CareerLogInput, CareerLogID: $CareerLogID, IsAttachmentRemoved: $IsAttachmentRemoved)
}
    `;
export type StaffConfirmationUpdateMutationFn = ApolloReactCommon.MutationFunction<StaffConfirmationUpdateMutation, StaffConfirmationUpdateMutationVariables>;

/**
 * __useStaffConfirmationUpdateMutation__
 *
 * To run a mutation, you first call `useStaffConfirmationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffConfirmationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffConfirmationUpdateMutation, { data, loading, error }] = useStaffConfirmationUpdateMutation({
 *   variables: {
 *      DocumentInput: // value for 'DocumentInput'
 *      CareerLogInput: // value for 'CareerLogInput'
 *      CareerLogID: // value for 'CareerLogID'
 *      IsAttachmentRemoved: // value for 'IsAttachmentRemoved'
 *   },
 * });
 */
export function useStaffConfirmationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffConfirmationUpdateMutation, StaffConfirmationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffConfirmationUpdateMutation, StaffConfirmationUpdateMutationVariables>(StaffConfirmationUpdateDocument, baseOptions);
      }
export type StaffConfirmationUpdateMutationHookResult = ReturnType<typeof useStaffConfirmationUpdateMutation>;
export type StaffConfirmationUpdateMutationResult = ApolloReactCommon.MutationResult<StaffConfirmationUpdateMutation>;
export type StaffConfirmationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffConfirmationUpdateMutation, StaffConfirmationUpdateMutationVariables>;
export const StaffPromotionDemotionListingDocument = gql`
    query StaffPromotionDemotionListing($CompanyID: String!, $PromotionOrDemotion: MovementType!, $OrgUnitID: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!]) {
  StaffPromotionDemotionListing(CompanyID: $CompanyID, PromotionOrDemotion: $PromotionOrDemotion, EmployeeType: $EmployeeType, EmployeeStatus: $EmployeeStatus, OrgUnitID: $OrgUnitID) {
    Employee {
      FormStatus
      EmployeeID
      Status
      EmploymentType
      JoinedReferenceDate
      JoinedDate
      ConfirmedDate
      ReportTo
      Probation
      OrgUnit {
        ParentID
        Description
        CompanyOrgUnitID
        CompanyHierarchyID
        Level
      }
      Supervisor {
        Contact {
          FullName
        }
        EmployeeNo
      }
      Department {
        Description
      }
      Division {
        Description
      }
      EmployeeSubordinates {
        EmployeeID
      }
      EmployeeNo
      Contact {
        FullName
      }
      OrgUnitID
      Designation {
        JobDesignationID
        Name
        Sequence
      }
      JobGrade {
        JobGradeID
        Description
      }
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
    }
    FromDate
    Status
    CareerLogID
    EffectiveDate
    FromCompany
    ToCompany
    FromDepartment
    ToDepartment
    FromDivision
    ToDivision
    FromOrgUnit
    ToOrgUnit
    FromJobGrade
    MovementType
    ExitReason
    ToJobGrade
    FromEmploymentType
    ToEmploymentType
    ReportTo
    ModifiedDT
    ModifiedBy
    CreatedBy
    CreatedDT
    Remark
    CancellationRemarks
    FromJobGradeTable {
      Description
    }
    ToJobGradeTable {
      Description
    }
    FromPosition
    ToPosition
    FromPositionTable {
      Name
    }
    ToPositionTable {
      Name
    }
    Document {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useStaffPromotionDemotionListingQuery__
 *
 * To run a query within a React component, call `useStaffPromotionDemotionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffPromotionDemotionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffPromotionDemotionListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PromotionOrDemotion: // value for 'PromotionOrDemotion'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *   },
 * });
 */
export function useStaffPromotionDemotionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffPromotionDemotionListingQuery, StaffPromotionDemotionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffPromotionDemotionListingQuery, StaffPromotionDemotionListingQueryVariables>(StaffPromotionDemotionListingDocument, baseOptions);
      }
export function useStaffPromotionDemotionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffPromotionDemotionListingQuery, StaffPromotionDemotionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffPromotionDemotionListingQuery, StaffPromotionDemotionListingQueryVariables>(StaffPromotionDemotionListingDocument, baseOptions);
        }
export type StaffPromotionDemotionListingQueryHookResult = ReturnType<typeof useStaffPromotionDemotionListingQuery>;
export type StaffPromotionDemotionListingLazyQueryHookResult = ReturnType<typeof useStaffPromotionDemotionListingLazyQuery>;
export type StaffPromotionDemotionListingQueryResult = ApolloReactCommon.QueryResult<StaffPromotionDemotionListingQuery, StaffPromotionDemotionListingQueryVariables>;
export const StaffPromotionDemotionInsertDocument = gql`
    mutation StaffPromotionDemotionInsert($CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  StaffPromotionDemotionInsert(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffPromotionDemotionInsertMutationFn = ApolloReactCommon.MutationFunction<StaffPromotionDemotionInsertMutation, StaffPromotionDemotionInsertMutationVariables>;

/**
 * __useStaffPromotionDemotionInsertMutation__
 *
 * To run a mutation, you first call `useStaffPromotionDemotionInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffPromotionDemotionInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffPromotionDemotionInsertMutation, { data, loading, error }] = useStaffPromotionDemotionInsertMutation({
 *   variables: {
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffPromotionDemotionInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffPromotionDemotionInsertMutation, StaffPromotionDemotionInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffPromotionDemotionInsertMutation, StaffPromotionDemotionInsertMutationVariables>(StaffPromotionDemotionInsertDocument, baseOptions);
      }
export type StaffPromotionDemotionInsertMutationHookResult = ReturnType<typeof useStaffPromotionDemotionInsertMutation>;
export type StaffPromotionDemotionInsertMutationResult = ApolloReactCommon.MutationResult<StaffPromotionDemotionInsertMutation>;
export type StaffPromotionDemotionInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffPromotionDemotionInsertMutation, StaffPromotionDemotionInsertMutationVariables>;
export const StaffPromotionDemotionUpdateDocument = gql`
    mutation StaffPromotionDemotionUpdate($CareerLogID: String!, $CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $IsAttachmentRemoved: Boolean, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  StaffPromotionDemotionUpdate(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, CareerLogID: $CareerLogID, IsAttachmentRemoved: $IsAttachmentRemoved, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffPromotionDemotionUpdateMutationFn = ApolloReactCommon.MutationFunction<StaffPromotionDemotionUpdateMutation, StaffPromotionDemotionUpdateMutationVariables>;

/**
 * __useStaffPromotionDemotionUpdateMutation__
 *
 * To run a mutation, you first call `useStaffPromotionDemotionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffPromotionDemotionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffPromotionDemotionUpdateMutation, { data, loading, error }] = useStaffPromotionDemotionUpdateMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      IsAttachmentRemoved: // value for 'IsAttachmentRemoved'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffPromotionDemotionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffPromotionDemotionUpdateMutation, StaffPromotionDemotionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffPromotionDemotionUpdateMutation, StaffPromotionDemotionUpdateMutationVariables>(StaffPromotionDemotionUpdateDocument, baseOptions);
      }
export type StaffPromotionDemotionUpdateMutationHookResult = ReturnType<typeof useStaffPromotionDemotionUpdateMutation>;
export type StaffPromotionDemotionUpdateMutationResult = ApolloReactCommon.MutationResult<StaffPromotionDemotionUpdateMutation>;
export type StaffPromotionDemotionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffPromotionDemotionUpdateMutation, StaffPromotionDemotionUpdateMutationVariables>;
export const StaffTerminationListingDocument = gql`
    query StaffTerminationListing($EmployeeID: String, $CompanyID: String!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  StaffTerminationListing(EmployeeID: $EmployeeID, EmployeeType: $EmployeeType, EmployeeStatus: $EmployeeStatus, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID) {
    EmployeeID
    Employee {
      EmployeeID
      Status
      EmploymentType
      JoinedReferenceDate
      JoinedDate
      ConfirmedDate
      TenderResignDate
      ResignedDate
      ReportTo
      Probation
      Supervisor {
        Contact {
          FullName
        }
        EmployeeNo
      }
      OrgUnit {
        Description
        CompanyOrgUnitID
        CompanyHierarchyID
        Level
        ParentID
      }
      Department {
        Description
      }
      Division {
        Description
      }
      EmployeeSubordinates {
        EmployeeID
      }
      EmployeeNo
      Contact {
        FullName
      }
      OrgUnitID
      Designation {
        JobDesignationID
        Name
      }
      JobGrade {
        JobGradeID
        Description
      }
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
    }
    CancellationRemarks
    Status
    CareerLogID
    EffectiveDate
    FromCompany
    ToCompany
    FromDepartment
    ToDepartment
    FromDivision
    ToDivision
    FromOrgUnit
    ToOrgUnit
    FromJobGrade
    ToJobGrade
    FromJobGradeTable {
      Description
    }
    ToJobGradeTable {
      Description
    }
    FromPosition
    ToPosition
    FromPositionTable {
      Name
    }
    ToPositionTable {
      Name
    }
    ReportTo
    Remark
    ExitReason
    LastWorkingDate
    LastEmploymentDate
    TenderResignDate
    ModifiedBy
    ModifiedDT
    CreatedDT
    CreatedBy
    Document {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useStaffTerminationListingQuery__
 *
 * To run a query within a React component, call `useStaffTerminationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffTerminationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffTerminationListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useStaffTerminationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffTerminationListingQuery, StaffTerminationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffTerminationListingQuery, StaffTerminationListingQueryVariables>(StaffTerminationListingDocument, baseOptions);
      }
export function useStaffTerminationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffTerminationListingQuery, StaffTerminationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffTerminationListingQuery, StaffTerminationListingQueryVariables>(StaffTerminationListingDocument, baseOptions);
        }
export type StaffTerminationListingQueryHookResult = ReturnType<typeof useStaffTerminationListingQuery>;
export type StaffTerminationListingLazyQueryHookResult = ReturnType<typeof useStaffTerminationListingLazyQuery>;
export type StaffTerminationListingQueryResult = ApolloReactCommon.QueryResult<StaffTerminationListingQuery, StaffTerminationListingQueryVariables>;
export const StaffTerminationListingBySubscriptionDocument = gql`
    query StaffTerminationListingBySubscription($SubscriptionAccountID: String) {
  StaffTerminationListingBySubscription(SubscriptionAccountID: $SubscriptionAccountID) {
    EmployeeID
    Employee {
      CompanyID
      Contact {
        FullName
      }
    }
    LastEmploymentDate
    Status
  }
}
    `;

/**
 * __useStaffTerminationListingBySubscriptionQuery__
 *
 * To run a query within a React component, call `useStaffTerminationListingBySubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffTerminationListingBySubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffTerminationListingBySubscriptionQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useStaffTerminationListingBySubscriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffTerminationListingBySubscriptionQuery, StaffTerminationListingBySubscriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffTerminationListingBySubscriptionQuery, StaffTerminationListingBySubscriptionQueryVariables>(StaffTerminationListingBySubscriptionDocument, baseOptions);
      }
export function useStaffTerminationListingBySubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffTerminationListingBySubscriptionQuery, StaffTerminationListingBySubscriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffTerminationListingBySubscriptionQuery, StaffTerminationListingBySubscriptionQueryVariables>(StaffTerminationListingBySubscriptionDocument, baseOptions);
        }
export type StaffTerminationListingBySubscriptionQueryHookResult = ReturnType<typeof useStaffTerminationListingBySubscriptionQuery>;
export type StaffTerminationListingBySubscriptionLazyQueryHookResult = ReturnType<typeof useStaffTerminationListingBySubscriptionLazyQuery>;
export type StaffTerminationListingBySubscriptionQueryResult = ApolloReactCommon.QueryResult<StaffTerminationListingBySubscriptionQuery, StaffTerminationListingBySubscriptionQueryVariables>;
export const StaffRetirementDueListingDocument = gql`
    query StaffRetirementDueListing($CompanyID: String!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  StaffRetirementDueListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID) {
    Employee {
      JobGradeID
      FormStatus
      OrgUnitID
      EmployeeID
      CompanyID
      JoinedReferenceDate
      OrgUnit {
        Description
        CompanyHierarchyID
        CompanyOrgUnitID
      }
      JobGrade {
        Description
      }
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
      EmployeeCareerLogs {
        Document {
          DocumentID
          BucketFileName
          DocumentFile
          Description
        }
      }
      DesignationID
      TenderResignDate
      DivisionID
      JobGradeID
      DepartmentID
      JoinedDate
      Probation
      Status
      EmploymentType
      ConfirmedDate
      Supervisor {
        EmployeeNo
        Contact {
          FullName
        }
      }
      ReportTo
      Contact {
        FullName
      }
      EmployeeNo
      EmployeeDepartment {
        Description
      }
      Division {
        Description
      }
      Designation {
        Name
      }
    }
    RetirementDate
    IsRetirementOverdue
  }
}
    `;

/**
 * __useStaffRetirementDueListingQuery__
 *
 * To run a query within a React component, call `useStaffRetirementDueListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffRetirementDueListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffRetirementDueListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useStaffRetirementDueListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffRetirementDueListingQuery, StaffRetirementDueListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffRetirementDueListingQuery, StaffRetirementDueListingQueryVariables>(StaffRetirementDueListingDocument, baseOptions);
      }
export function useStaffRetirementDueListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffRetirementDueListingQuery, StaffRetirementDueListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffRetirementDueListingQuery, StaffRetirementDueListingQueryVariables>(StaffRetirementDueListingDocument, baseOptions);
        }
export type StaffRetirementDueListingQueryHookResult = ReturnType<typeof useStaffRetirementDueListingQuery>;
export type StaffRetirementDueListingLazyQueryHookResult = ReturnType<typeof useStaffRetirementDueListingLazyQuery>;
export type StaffRetirementDueListingQueryResult = ApolloReactCommon.QueryResult<StaffRetirementDueListingQuery, StaffRetirementDueListingQueryVariables>;
export const StaffRetirementRetiredListingDocument = gql`
    query StaffRetirementRetiredListing($CompanyID: String!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  StaffRetirementRetiredListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID) {
    CareerLogID
    EffectiveDate
    Remark
    ModifiedDT
    ModifiedBy
    CreatedBy
    CreatedDT
    FromOrgUnit
    ToOrgUnit
    Document {
      Description
      DocumentFile
      BucketFileName
      FileSize
      DocumentID
    }
    Status
    Employee {
      JobGradeID
      FormStatus
      OrgUnitID
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
      JoinedReferenceDate
      OrgUnit {
        Description
        CompanyHierarchyID
        CompanyOrgUnitID
      }
      CompanyID
      Status
      EmploymentType
      DesignationID
      DivisionID
      JobGradeID
      DepartmentID
      ConfirmedDate
      ReportTo
      JoinedDate
      Probation
      Supervisor {
        EmployeeNo
        Contact {
          FullName
        }
      }
      EmployeeID
      Contact {
        FullName
      }
      EmployeeNo
      EmployeeDepartment {
        Description
      }
      Division {
        Description
      }
      Designation {
        Name
      }
      JobGrade {
        Description
      }
    }
    ToDivisionTable {
      Description
    }
    ToDepartmentTable {
      Description
    }
    ToJobGradeTable {
      Description
    }
    ToPositionTable {
      Name
    }
  }
}
    `;

/**
 * __useStaffRetirementRetiredListingQuery__
 *
 * To run a query within a React component, call `useStaffRetirementRetiredListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffRetirementRetiredListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffRetirementRetiredListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useStaffRetirementRetiredListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffRetirementRetiredListingQuery, StaffRetirementRetiredListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffRetirementRetiredListingQuery, StaffRetirementRetiredListingQueryVariables>(StaffRetirementRetiredListingDocument, baseOptions);
      }
export function useStaffRetirementRetiredListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffRetirementRetiredListingQuery, StaffRetirementRetiredListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffRetirementRetiredListingQuery, StaffRetirementRetiredListingQueryVariables>(StaffRetirementRetiredListingDocument, baseOptions);
        }
export type StaffRetirementRetiredListingQueryHookResult = ReturnType<typeof useStaffRetirementRetiredListingQuery>;
export type StaffRetirementRetiredListingLazyQueryHookResult = ReturnType<typeof useStaffRetirementRetiredListingLazyQuery>;
export type StaffRetirementRetiredListingQueryResult = ApolloReactCommon.QueryResult<StaffRetirementRetiredListingQuery, StaffRetirementRetiredListingQueryVariables>;
export const StaffRetirementInsertDocument = gql`
    mutation StaffRetirementInsert($DocumentInput: DocumentsInput, $CareerLogInput: CareerLogInput!, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  PersonnelStaffExitCompanyInsert(DocumentInput: $DocumentInput, CareerLogInput: $CareerLogInput, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffRetirementInsertMutationFn = ApolloReactCommon.MutationFunction<StaffRetirementInsertMutation, StaffRetirementInsertMutationVariables>;

/**
 * __useStaffRetirementInsertMutation__
 *
 * To run a mutation, you first call `useStaffRetirementInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffRetirementInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffRetirementInsertMutation, { data, loading, error }] = useStaffRetirementInsertMutation({
 *   variables: {
 *      DocumentInput: // value for 'DocumentInput'
 *      CareerLogInput: // value for 'CareerLogInput'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffRetirementInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffRetirementInsertMutation, StaffRetirementInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffRetirementInsertMutation, StaffRetirementInsertMutationVariables>(StaffRetirementInsertDocument, baseOptions);
      }
export type StaffRetirementInsertMutationHookResult = ReturnType<typeof useStaffRetirementInsertMutation>;
export type StaffRetirementInsertMutationResult = ApolloReactCommon.MutationResult<StaffRetirementInsertMutation>;
export type StaffRetirementInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffRetirementInsertMutation, StaffRetirementInsertMutationVariables>;
export const StaffRetirementUpdateDocument = gql`
    mutation StaffRetirementUpdate($DocumentInput: DocumentsInput, $CareerLogInput: CareerLogInput!, $IsAttachmentRemoved: Boolean, $CareerLogID: String!, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  PersonnelStaffExitCompanyUpdate(DocumentInput: $DocumentInput, CareerLogInput: $CareerLogInput, CareerLogID: $CareerLogID, IsAttachmentRemoved: $IsAttachmentRemoved, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffRetirementUpdateMutationFn = ApolloReactCommon.MutationFunction<StaffRetirementUpdateMutation, StaffRetirementUpdateMutationVariables>;

/**
 * __useStaffRetirementUpdateMutation__
 *
 * To run a mutation, you first call `useStaffRetirementUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffRetirementUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffRetirementUpdateMutation, { data, loading, error }] = useStaffRetirementUpdateMutation({
 *   variables: {
 *      DocumentInput: // value for 'DocumentInput'
 *      CareerLogInput: // value for 'CareerLogInput'
 *      IsAttachmentRemoved: // value for 'IsAttachmentRemoved'
 *      CareerLogID: // value for 'CareerLogID'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffRetirementUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffRetirementUpdateMutation, StaffRetirementUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffRetirementUpdateMutation, StaffRetirementUpdateMutationVariables>(StaffRetirementUpdateDocument, baseOptions);
      }
export type StaffRetirementUpdateMutationHookResult = ReturnType<typeof useStaffRetirementUpdateMutation>;
export type StaffRetirementUpdateMutationResult = ApolloReactCommon.MutationResult<StaffRetirementUpdateMutation>;
export type StaffRetirementUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffRetirementUpdateMutation, StaffRetirementUpdateMutationVariables>;
export const StaffExitCompanyListingDocument = gql`
    query StaffExitCompanyListing($CompanyID: String, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $EmployeeID: String) {
  StaffExitCompanyListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID, EmployeeType: $EmployeeType, EmployeeStatus: $EmployeeStatus, OrgUnitID: $OrgUnitID) {
    CareerLogID
    Status
    EffectiveDate
    LastWorkingDate
    LastEmploymentDate
    Remark
    MovementType
    ExitReason
    CreatedDT
    CreatedBy
    TenderResignDate
    CancellationRemarks
    ModifiedBy
    ModifiedDT
    ExitReasonID
    FromOrgUnit
    ToOrgUnit
    ExitCompanyReason {
      Name
    }
    Document {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    FromJobGradeTable {
      Description
    }
    FromPositionTable {
      Name
    }
    Employee {
      FormStatus
      OrgUnitID
      EmployeeID
      EmployeeNo
      Status
      EmploymentType
      JoinedReferenceDate
      JoinedDate
      ConfirmedDate
      JoinedReferenceDate
      TenderResignDate
      ResignedDate
      ReportTo
      Probation
      Supervisor {
        EmployeeNo
        Contact {
          FullName
        }
      }
      OrgUnit {
        Description
        CompanyOrgUnitID
        CompanyHierarchyID
        ParentID
      }
      Department {
        Description
      }
      Division {
        Description
      }
      JobGrade {
        Description
        JobGradeID
      }
      Designation {
        Name
      }
      Contact {
        FullName
      }
      Documents {
        DocumentFile
        Description
      }
    }
    Document {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useStaffExitCompanyListingQuery__
 *
 * To run a query within a React component, call `useStaffExitCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffExitCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffExitCompanyListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useStaffExitCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffExitCompanyListingQuery, StaffExitCompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffExitCompanyListingQuery, StaffExitCompanyListingQueryVariables>(StaffExitCompanyListingDocument, baseOptions);
      }
export function useStaffExitCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffExitCompanyListingQuery, StaffExitCompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffExitCompanyListingQuery, StaffExitCompanyListingQueryVariables>(StaffExitCompanyListingDocument, baseOptions);
        }
export type StaffExitCompanyListingQueryHookResult = ReturnType<typeof useStaffExitCompanyListingQuery>;
export type StaffExitCompanyListingLazyQueryHookResult = ReturnType<typeof useStaffExitCompanyListingLazyQuery>;
export type StaffExitCompanyListingQueryResult = ApolloReactCommon.QueryResult<StaffExitCompanyListingQuery, StaffExitCompanyListingQueryVariables>;
export const PersonnelStaffExitCompanyInsertDocument = gql`
    mutation PersonnelStaffExitCompanyInsert($CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  PersonnelStaffExitCompanyInsert(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type PersonnelStaffExitCompanyInsertMutationFn = ApolloReactCommon.MutationFunction<PersonnelStaffExitCompanyInsertMutation, PersonnelStaffExitCompanyInsertMutationVariables>;

/**
 * __usePersonnelStaffExitCompanyInsertMutation__
 *
 * To run a mutation, you first call `usePersonnelStaffExitCompanyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelStaffExitCompanyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelStaffExitCompanyInsertMutation, { data, loading, error }] = usePersonnelStaffExitCompanyInsertMutation({
 *   variables: {
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function usePersonnelStaffExitCompanyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelStaffExitCompanyInsertMutation, PersonnelStaffExitCompanyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelStaffExitCompanyInsertMutation, PersonnelStaffExitCompanyInsertMutationVariables>(PersonnelStaffExitCompanyInsertDocument, baseOptions);
      }
export type PersonnelStaffExitCompanyInsertMutationHookResult = ReturnType<typeof usePersonnelStaffExitCompanyInsertMutation>;
export type PersonnelStaffExitCompanyInsertMutationResult = ApolloReactCommon.MutationResult<PersonnelStaffExitCompanyInsertMutation>;
export type PersonnelStaffExitCompanyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelStaffExitCompanyInsertMutation, PersonnelStaffExitCompanyInsertMutationVariables>;
export const PersonnelStaffExitCompanyUpdateDocument = gql`
    mutation PersonnelStaffExitCompanyUpdate($CareerLogID: String!, $CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $IsAttachmentRemoved: Boolean, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  PersonnelStaffExitCompanyUpdate(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, CareerLogID: $CareerLogID, IsAttachmentRemoved: $IsAttachmentRemoved, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type PersonnelStaffExitCompanyUpdateMutationFn = ApolloReactCommon.MutationFunction<PersonnelStaffExitCompanyUpdateMutation, PersonnelStaffExitCompanyUpdateMutationVariables>;

/**
 * __usePersonnelStaffExitCompanyUpdateMutation__
 *
 * To run a mutation, you first call `usePersonnelStaffExitCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelStaffExitCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelStaffExitCompanyUpdateMutation, { data, loading, error }] = usePersonnelStaffExitCompanyUpdateMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      IsAttachmentRemoved: // value for 'IsAttachmentRemoved'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function usePersonnelStaffExitCompanyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelStaffExitCompanyUpdateMutation, PersonnelStaffExitCompanyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelStaffExitCompanyUpdateMutation, PersonnelStaffExitCompanyUpdateMutationVariables>(PersonnelStaffExitCompanyUpdateDocument, baseOptions);
      }
export type PersonnelStaffExitCompanyUpdateMutationHookResult = ReturnType<typeof usePersonnelStaffExitCompanyUpdateMutation>;
export type PersonnelStaffExitCompanyUpdateMutationResult = ApolloReactCommon.MutationResult<PersonnelStaffExitCompanyUpdateMutation>;
export type PersonnelStaffExitCompanyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelStaffExitCompanyUpdateMutation, PersonnelStaffExitCompanyUpdateMutationVariables>;
export const StaffInternalTransferListingDocument = gql`
    query StaffInternalTransferListing($CompanyID: String!, $EmployeeID: String, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  StaffInternalTransferListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID) {
    CancellationRemarks
    Employee {
      FormStatus
      EmployeeID
      EmployeeNo
      Status
      EmploymentType
      JoinedReferenceDate
      JoinedDate
      ConfirmedDate
      ReportTo
      Probation
      OrgUnitID
      OrgUnit {
        Description
        CompanyHierarchyID
        CompanyOrgUnitID
      }
      Department {
        Description
      }
      Division {
        Description
      }
      JobGrade {
        JobGradeID
        Description
      }
      Designation {
        JobDesignationID
        Name
      }
      Contact {
        FullName
      }
      Supervisor {
        Contact {
          FullName
        }
        EmployeeNo
      }
      Documents {
        DocumentFile
        Description
      }
    }
    Status
    CareerLogID
    EffectiveDate
    FromOrgUnit
    ToOrgUnit
    FromCompany
    ToCompany
    FromDepartment
    ToDepartment
    FromDivision
    ToDivision
    FromPosition
    ToPosition
    FromJobGrade
    ToJobGrade
    FromEmploymentType
    ToEmploymentType
    ReportTo
    ModifiedDT
    ModifiedBy
    CreatedBy
    CreatedDT
    MovementType
    Remark
    FromCompanyTable {
      CompanyName
    }
    ToCompanyTable {
      CompanyName
    }
    FromDepartmentTable {
      Description
    }
    ToDepartmentTable {
      Description
    }
    FromDivisionTable {
      Description
    }
    ToDivisionTable {
      Description
    }
    FromJobGradeTable {
      Description
    }
    ToJobGradeTable {
      Description
    }
    FromPositionTable {
      Name
    }
    ToPositionTable {
      Name
    }
    Document {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useStaffInternalTransferListingQuery__
 *
 * To run a query within a React component, call `useStaffInternalTransferListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffInternalTransferListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffInternalTransferListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useStaffInternalTransferListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffInternalTransferListingQuery, StaffInternalTransferListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffInternalTransferListingQuery, StaffInternalTransferListingQueryVariables>(StaffInternalTransferListingDocument, baseOptions);
      }
export function useStaffInternalTransferListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffInternalTransferListingQuery, StaffInternalTransferListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffInternalTransferListingQuery, StaffInternalTransferListingQueryVariables>(StaffInternalTransferListingDocument, baseOptions);
        }
export type StaffInternalTransferListingQueryHookResult = ReturnType<typeof useStaffInternalTransferListingQuery>;
export type StaffInternalTransferListingLazyQueryHookResult = ReturnType<typeof useStaffInternalTransferListingLazyQuery>;
export type StaffInternalTransferListingQueryResult = ApolloReactCommon.QueryResult<StaffInternalTransferListingQuery, StaffInternalTransferListingQueryVariables>;
export const StaffInternalTransferInsertDocument = gql`
    mutation StaffInternalTransferInsert($CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $IsCopyCurrentYearTP3: Boolean, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  StaffTransferInsert(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, IsCopyCurrentYearTP3: $IsCopyCurrentYearTP3, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffInternalTransferInsertMutationFn = ApolloReactCommon.MutationFunction<StaffInternalTransferInsertMutation, StaffInternalTransferInsertMutationVariables>;

/**
 * __useStaffInternalTransferInsertMutation__
 *
 * To run a mutation, you first call `useStaffInternalTransferInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffInternalTransferInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffInternalTransferInsertMutation, { data, loading, error }] = useStaffInternalTransferInsertMutation({
 *   variables: {
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      IsCopyCurrentYearTP3: // value for 'IsCopyCurrentYearTP3'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffInternalTransferInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffInternalTransferInsertMutation, StaffInternalTransferInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffInternalTransferInsertMutation, StaffInternalTransferInsertMutationVariables>(StaffInternalTransferInsertDocument, baseOptions);
      }
export type StaffInternalTransferInsertMutationHookResult = ReturnType<typeof useStaffInternalTransferInsertMutation>;
export type StaffInternalTransferInsertMutationResult = ApolloReactCommon.MutationResult<StaffInternalTransferInsertMutation>;
export type StaffInternalTransferInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffInternalTransferInsertMutation, StaffInternalTransferInsertMutationVariables>;
export const StaffInternalTransferUpdateDocument = gql`
    mutation StaffInternalTransferUpdate($CareerLogID: String!, $CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $IsCopyCurrentYearTP3: Boolean, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  StaffTransferUpdate(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, CareerLogID: $CareerLogID, IsCopyCurrentYearTP3: $IsCopyCurrentYearTP3, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffInternalTransferUpdateMutationFn = ApolloReactCommon.MutationFunction<StaffInternalTransferUpdateMutation, StaffInternalTransferUpdateMutationVariables>;

/**
 * __useStaffInternalTransferUpdateMutation__
 *
 * To run a mutation, you first call `useStaffInternalTransferUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffInternalTransferUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffInternalTransferUpdateMutation, { data, loading, error }] = useStaffInternalTransferUpdateMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      IsCopyCurrentYearTP3: // value for 'IsCopyCurrentYearTP3'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffInternalTransferUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffInternalTransferUpdateMutation, StaffInternalTransferUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffInternalTransferUpdateMutation, StaffInternalTransferUpdateMutationVariables>(StaffInternalTransferUpdateDocument, baseOptions);
      }
export type StaffInternalTransferUpdateMutationHookResult = ReturnType<typeof useStaffInternalTransferUpdateMutation>;
export type StaffInternalTransferUpdateMutationResult = ApolloReactCommon.MutationResult<StaffInternalTransferUpdateMutation>;
export type StaffInternalTransferUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffInternalTransferUpdateMutation, StaffInternalTransferUpdateMutationVariables>;
export const StaffInternalTransferCancelDocument = gql`
    mutation StaffInternalTransferCancel($CareerLogID: String!, $CancellationRemarks: String) {
  StaffTransferCancel(CareerLogID: $CareerLogID, CancellationRemarks: $CancellationRemarks)
}
    `;
export type StaffInternalTransferCancelMutationFn = ApolloReactCommon.MutationFunction<StaffInternalTransferCancelMutation, StaffInternalTransferCancelMutationVariables>;

/**
 * __useStaffInternalTransferCancelMutation__
 *
 * To run a mutation, you first call `useStaffInternalTransferCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffInternalTransferCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffInternalTransferCancelMutation, { data, loading, error }] = useStaffInternalTransferCancelMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CancellationRemarks: // value for 'CancellationRemarks'
 *   },
 * });
 */
export function useStaffInternalTransferCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffInternalTransferCancelMutation, StaffInternalTransferCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffInternalTransferCancelMutation, StaffInternalTransferCancelMutationVariables>(StaffInternalTransferCancelDocument, baseOptions);
      }
export type StaffInternalTransferCancelMutationHookResult = ReturnType<typeof useStaffInternalTransferCancelMutation>;
export type StaffInternalTransferCancelMutationResult = ApolloReactCommon.MutationResult<StaffInternalTransferCancelMutation>;
export type StaffInternalTransferCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffInternalTransferCancelMutation, StaffInternalTransferCancelMutationVariables>;
export const StaffDemotionCancelDocument = gql`
    mutation StaffDemotionCancel($CareerLogID: String!, $CancellationRemarks: String) {
  StaffPromotionDemotionCancel(CareerLogID: $CareerLogID, CancellationRemarks: $CancellationRemarks)
}
    `;
export type StaffDemotionCancelMutationFn = ApolloReactCommon.MutationFunction<StaffDemotionCancelMutation, StaffDemotionCancelMutationVariables>;

/**
 * __useStaffDemotionCancelMutation__
 *
 * To run a mutation, you first call `useStaffDemotionCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffDemotionCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffDemotionCancelMutation, { data, loading, error }] = useStaffDemotionCancelMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CancellationRemarks: // value for 'CancellationRemarks'
 *   },
 * });
 */
export function useStaffDemotionCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffDemotionCancelMutation, StaffDemotionCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffDemotionCancelMutation, StaffDemotionCancelMutationVariables>(StaffDemotionCancelDocument, baseOptions);
      }
export type StaffDemotionCancelMutationHookResult = ReturnType<typeof useStaffDemotionCancelMutation>;
export type StaffDemotionCancelMutationResult = ApolloReactCommon.MutationResult<StaffDemotionCancelMutation>;
export type StaffDemotionCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffDemotionCancelMutation, StaffDemotionCancelMutationVariables>;
export const StaffSecondmentListingDocument = gql`
    query StaffSecondmentListing($CompanyID: String!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  StaffSecondmentListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID) {
    Employee {
      FormStatus
      EmployeeID
      DivisionID
      DesignationID
      DepartmentID
      JobGradeID
      OrgUnit {
        ParentID
        Description
        CompanyOrgUnitID
        CompanyHierarchyID
        Level
      }
      Contact {
        FullName
      }
      CompanyID
      EmployeeNo
      OrgUnitID
      Designation {
        JobDesignationID
        Name
      }
      JobGrade {
        JobGradeID
        Description
      }
      JoinedDate
      JoinedReferenceDate
      Probation
      ConfirmedDate
      Supervisor {
        Contact {
          FullName
        }
        EmployeeNo
      }
      Status
      EmploymentType
      Documents {
        DocumentFile
        Description
      }
    }
    Status
    CancellationRemarks
    CareerLogID
    EmployeeID
    EffectiveDate
    FromCompany
    ToCompany
    FromOrgUnit
    ToOrgUnit
    FromDepartment
    ToDepartment
    FromDivision
    ToDivision
    FromPosition
    ToPosition
    FromJobGrade
    ToJobGrade
    FromEmploymentType
    ToEmploymentType
    ReportTo
    Remark
    CreatedDT
    CreatedBy
    ModifiedBy
    ModifiedDT
    FromCompanyTable {
      CompanyName
    }
    ToCompanyTable {
      CompanyName
    }
    FromDepartmentTable {
      Description
    }
    ToDepartmentTable {
      Description
    }
    FromDivisionTable {
      Description
    }
    ToDivisionTable {
      Description
    }
    FromPositionTable {
      Name
    }
    ToPositionTable {
      Name
    }
    FromJobGradeTable {
      Description
    }
    ToJobGradeTable {
      Description
    }
    Document {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useStaffSecondmentListingQuery__
 *
 * To run a query within a React component, call `useStaffSecondmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffSecondmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffSecondmentListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useStaffSecondmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffSecondmentListingQuery, StaffSecondmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffSecondmentListingQuery, StaffSecondmentListingQueryVariables>(StaffSecondmentListingDocument, baseOptions);
      }
export function useStaffSecondmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffSecondmentListingQuery, StaffSecondmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffSecondmentListingQuery, StaffSecondmentListingQueryVariables>(StaffSecondmentListingDocument, baseOptions);
        }
export type StaffSecondmentListingQueryHookResult = ReturnType<typeof useStaffSecondmentListingQuery>;
export type StaffSecondmentListingLazyQueryHookResult = ReturnType<typeof useStaffSecondmentListingLazyQuery>;
export type StaffSecondmentListingQueryResult = ApolloReactCommon.QueryResult<StaffSecondmentListingQuery, StaffSecondmentListingQueryVariables>;
export const StaffInterCompanyTransferListingDocument = gql`
    query StaffInterCompanyTransferListing($CompanyID: String!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  StaffInterCompanyTransferListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID) {
    Employee {
      FormStatus
      OrgUnitID
      DivisionID
      EmployeeID
      DesignationID
      DepartmentID
      JobGradeID
      OrgUnit {
        Description
        CompanyOrgUnitID
        CompanyHierarchyID
        ParentID
      }
      Contact {
        FullName
      }
      EmployeeNo
      Designation {
        Name
      }
      JobGrade {
        Description
      }
      FormStatus
      JoinedDate
      JoinedReferenceDate
      Probation
      ConfirmedDate
      Supervisor {
        Contact {
          FullName
        }
        EmployeeNo
      }
      Status
      EmploymentType
      Documents {
        DocumentFile
        Description
      }
    }
    CancellationRemarks
    Status
    IsCopyCurrentYearTP3
    CareerLogID
    EmployeeID
    EffectiveDate
    FromOrgUnit
    ToOrgUnit
    FromCompany
    ToCompany
    FromDepartment
    ToDepartment
    FromDivision
    ToDivision
    FromPosition
    ToPosition
    FromJobGrade
    ToJobGrade
    FromEmploymentType
    ToEmploymentType
    ReportTo
    CreatedDT
    CreatedBy
    ModifiedBy
    ModifiedDT
    Remark
    IsCopyActiveZakatCont
    IsTransferAlBalance
    IsTransferLeaveTaken
    IsCopyStatutory
    IsTransferCurrentYearPayroll
    IsTransferLatestSalary
    IsCopyCurrentYearTaxRelief
    IsCopyActiveFixedAllowance
    IsTransferMonthlyClaimAmount
    IsTransferBiYearlyClaimAmount
    IsTransferYearlyClaimAmount
    IsTransferLifetimeClaimAmount
    IsCopyProjectCostAllocation
    IsTransferShiftGroup
    ShiftGroupID
    NewEmployeeNo
    CalendarStateTitle
    IsAllowToCancel
    FromCompanyTable {
      CompanyName
    }
    ToCompanyTable {
      CompanyName
    }
    FromDepartmentTable {
      Description
    }
    ToDepartmentTable {
      Description
    }
    FromDivisionTable {
      Description
    }
    ToDivisionTable {
      Description
    }
    FromPositionTable {
      Name
    }
    ToPositionTable {
      Name
    }
    FromJobGradeTable {
      Description
    }
    ToJobGradeTable {
      Description
    }
    Document {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useStaffInterCompanyTransferListingQuery__
 *
 * To run a query within a React component, call `useStaffInterCompanyTransferListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffInterCompanyTransferListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffInterCompanyTransferListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useStaffInterCompanyTransferListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffInterCompanyTransferListingQuery, StaffInterCompanyTransferListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffInterCompanyTransferListingQuery, StaffInterCompanyTransferListingQueryVariables>(StaffInterCompanyTransferListingDocument, baseOptions);
      }
export function useStaffInterCompanyTransferListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffInterCompanyTransferListingQuery, StaffInterCompanyTransferListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffInterCompanyTransferListingQuery, StaffInterCompanyTransferListingQueryVariables>(StaffInterCompanyTransferListingDocument, baseOptions);
        }
export type StaffInterCompanyTransferListingQueryHookResult = ReturnType<typeof useStaffInterCompanyTransferListingQuery>;
export type StaffInterCompanyTransferListingLazyQueryHookResult = ReturnType<typeof useStaffInterCompanyTransferListingLazyQuery>;
export type StaffInterCompanyTransferListingQueryResult = ApolloReactCommon.QueryResult<StaffInterCompanyTransferListingQuery, StaffInterCompanyTransferListingQueryVariables>;
export const StaffSecondmentInsertDocument = gql`
    mutation StaffSecondmentInsert($CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  StaffTransferInsert(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffSecondmentInsertMutationFn = ApolloReactCommon.MutationFunction<StaffSecondmentInsertMutation, StaffSecondmentInsertMutationVariables>;

/**
 * __useStaffSecondmentInsertMutation__
 *
 * To run a mutation, you first call `useStaffSecondmentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffSecondmentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffSecondmentInsertMutation, { data, loading, error }] = useStaffSecondmentInsertMutation({
 *   variables: {
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffSecondmentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffSecondmentInsertMutation, StaffSecondmentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffSecondmentInsertMutation, StaffSecondmentInsertMutationVariables>(StaffSecondmentInsertDocument, baseOptions);
      }
export type StaffSecondmentInsertMutationHookResult = ReturnType<typeof useStaffSecondmentInsertMutation>;
export type StaffSecondmentInsertMutationResult = ApolloReactCommon.MutationResult<StaffSecondmentInsertMutation>;
export type StaffSecondmentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffSecondmentInsertMutation, StaffSecondmentInsertMutationVariables>;
export const StaffSecondmentUpdateDocument = gql`
    mutation StaffSecondmentUpdate($CareerLogID: String!, $CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $IsAttachmentRemoved: Boolean, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  StaffTransferUpdate(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, CareerLogID: $CareerLogID, IsAttachmentRemoved: $IsAttachmentRemoved, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffSecondmentUpdateMutationFn = ApolloReactCommon.MutationFunction<StaffSecondmentUpdateMutation, StaffSecondmentUpdateMutationVariables>;

/**
 * __useStaffSecondmentUpdateMutation__
 *
 * To run a mutation, you first call `useStaffSecondmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffSecondmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffSecondmentUpdateMutation, { data, loading, error }] = useStaffSecondmentUpdateMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      IsAttachmentRemoved: // value for 'IsAttachmentRemoved'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffSecondmentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffSecondmentUpdateMutation, StaffSecondmentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffSecondmentUpdateMutation, StaffSecondmentUpdateMutationVariables>(StaffSecondmentUpdateDocument, baseOptions);
      }
export type StaffSecondmentUpdateMutationHookResult = ReturnType<typeof useStaffSecondmentUpdateMutation>;
export type StaffSecondmentUpdateMutationResult = ApolloReactCommon.MutationResult<StaffSecondmentUpdateMutation>;
export type StaffSecondmentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffSecondmentUpdateMutation, StaffSecondmentUpdateMutationVariables>;
export const StaffInterCompanyTransferInsertDocument = gql`
    mutation StaffInterCompanyTransferInsert($CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $IsCopyCurrentYearTP3: Boolean, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  StaffTransferInsert(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, IsCopyCurrentYearTP3: $IsCopyCurrentYearTP3, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffInterCompanyTransferInsertMutationFn = ApolloReactCommon.MutationFunction<StaffInterCompanyTransferInsertMutation, StaffInterCompanyTransferInsertMutationVariables>;

/**
 * __useStaffInterCompanyTransferInsertMutation__
 *
 * To run a mutation, you first call `useStaffInterCompanyTransferInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffInterCompanyTransferInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffInterCompanyTransferInsertMutation, { data, loading, error }] = useStaffInterCompanyTransferInsertMutation({
 *   variables: {
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      IsCopyCurrentYearTP3: // value for 'IsCopyCurrentYearTP3'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffInterCompanyTransferInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffInterCompanyTransferInsertMutation, StaffInterCompanyTransferInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffInterCompanyTransferInsertMutation, StaffInterCompanyTransferInsertMutationVariables>(StaffInterCompanyTransferInsertDocument, baseOptions);
      }
export type StaffInterCompanyTransferInsertMutationHookResult = ReturnType<typeof useStaffInterCompanyTransferInsertMutation>;
export type StaffInterCompanyTransferInsertMutationResult = ApolloReactCommon.MutationResult<StaffInterCompanyTransferInsertMutation>;
export type StaffInterCompanyTransferInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffInterCompanyTransferInsertMutation, StaffInterCompanyTransferInsertMutationVariables>;
export const StaffInterCompanyTransferUpdateDocument = gql`
    mutation StaffInterCompanyTransferUpdate($CareerLogID: String!, $CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $IsCopyCurrentYearTP3: Boolean, $IsAttachmentRemoved: Boolean, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  StaffTransferUpdate(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, CareerLogID: $CareerLogID, IsAttachmentRemoved: $IsAttachmentRemoved, IsCopyCurrentYearTP3: $IsCopyCurrentYearTP3, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffInterCompanyTransferUpdateMutationFn = ApolloReactCommon.MutationFunction<StaffInterCompanyTransferUpdateMutation, StaffInterCompanyTransferUpdateMutationVariables>;

/**
 * __useStaffInterCompanyTransferUpdateMutation__
 *
 * To run a mutation, you first call `useStaffInterCompanyTransferUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffInterCompanyTransferUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffInterCompanyTransferUpdateMutation, { data, loading, error }] = useStaffInterCompanyTransferUpdateMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      IsCopyCurrentYearTP3: // value for 'IsCopyCurrentYearTP3'
 *      IsAttachmentRemoved: // value for 'IsAttachmentRemoved'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffInterCompanyTransferUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffInterCompanyTransferUpdateMutation, StaffInterCompanyTransferUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffInterCompanyTransferUpdateMutation, StaffInterCompanyTransferUpdateMutationVariables>(StaffInterCompanyTransferUpdateDocument, baseOptions);
      }
export type StaffInterCompanyTransferUpdateMutationHookResult = ReturnType<typeof useStaffInterCompanyTransferUpdateMutation>;
export type StaffInterCompanyTransferUpdateMutationResult = ApolloReactCommon.MutationResult<StaffInterCompanyTransferUpdateMutation>;
export type StaffInterCompanyTransferUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffInterCompanyTransferUpdateMutation, StaffInterCompanyTransferUpdateMutationVariables>;
export const StaffRedesignationListingDocument = gql`
    query StaffRedesignationListing($CompanyID: String!, $EmployeeID: String, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  StaffRedesignationListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID) {
    Employee {
      FormStatus
      JobGradeID
      EmployeeNo
      EmployeeID
      Company {
        CompanyName
      }
      DesignationID
      OrgUnitID
      OrgUnit {
        ParentID
        Description
        CompanyOrgUnitID
        CompanyHierarchyID
        Level
      }
      Contact {
        FullName
      }
      Designation {
        Name
      }
      JobGrade {
        Description
      }
      JoinedDate
      JoinedReferenceDate
      Probation
      ConfirmedDate
      Supervisor {
        Contact {
          FullName
        }
        EmployeeNo
      }
      Status
      EmploymentType
      Documents {
        DocumentFile
        Description
      }
    }
    CancellationRemarks
    CareerLogID
    EffectiveDate
    FromCompany
    ToCompany
    FromDepartment
    ToDepartment
    FromDivision
    ToDivision
    FromPosition
    ToPosition
    FromJobGrade
    ToJobGrade
    FromOrgUnit
    ToOrgUnit
    FromEmploymentType
    ToEmploymentType
    CreatedDT
    CreatedBy
    ModifiedBy
    ModifiedDT
    ReportTo
    Remark
    Status
    FromCompanyTable {
      CompanyName
    }
    ToCompanyTable {
      CompanyName
    }
    FromDepartmentTable {
      Description
    }
    ToDepartmentTable {
      Description
    }
    FromDivisionTable {
      Description
    }
    ToDivisionTable {
      Description
    }
    FromJobGradeTable {
      Description
    }
    ToJobGradeTable {
      Description
    }
    FromPositionTable {
      Name
    }
    ToPositionTable {
      Name
    }
    Document {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useStaffRedesignationListingQuery__
 *
 * To run a query within a React component, call `useStaffRedesignationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffRedesignationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffRedesignationListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useStaffRedesignationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffRedesignationListingQuery, StaffRedesignationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffRedesignationListingQuery, StaffRedesignationListingQueryVariables>(StaffRedesignationListingDocument, baseOptions);
      }
export function useStaffRedesignationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffRedesignationListingQuery, StaffRedesignationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffRedesignationListingQuery, StaffRedesignationListingQueryVariables>(StaffRedesignationListingDocument, baseOptions);
        }
export type StaffRedesignationListingQueryHookResult = ReturnType<typeof useStaffRedesignationListingQuery>;
export type StaffRedesignationListingLazyQueryHookResult = ReturnType<typeof useStaffRedesignationListingLazyQuery>;
export type StaffRedesignationListingQueryResult = ApolloReactCommon.QueryResult<StaffRedesignationListingQuery, StaffRedesignationListingQueryVariables>;
export const StaffTransferInsertDocument = gql`
    mutation StaffTransferInsert($CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $IsCopyCurrentYearTP3: Boolean, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  StaffTransferInsert(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, IsCopyCurrentYearTP3: $IsCopyCurrentYearTP3, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffTransferInsertMutationFn = ApolloReactCommon.MutationFunction<StaffTransferInsertMutation, StaffTransferInsertMutationVariables>;

/**
 * __useStaffTransferInsertMutation__
 *
 * To run a mutation, you first call `useStaffTransferInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffTransferInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffTransferInsertMutation, { data, loading, error }] = useStaffTransferInsertMutation({
 *   variables: {
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      IsCopyCurrentYearTP3: // value for 'IsCopyCurrentYearTP3'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffTransferInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffTransferInsertMutation, StaffTransferInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffTransferInsertMutation, StaffTransferInsertMutationVariables>(StaffTransferInsertDocument, baseOptions);
      }
export type StaffTransferInsertMutationHookResult = ReturnType<typeof useStaffTransferInsertMutation>;
export type StaffTransferInsertMutationResult = ApolloReactCommon.MutationResult<StaffTransferInsertMutation>;
export type StaffTransferInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffTransferInsertMutation, StaffTransferInsertMutationVariables>;
export const StaffTransferUpdateDocument = gql`
    mutation StaffTransferUpdate($CareerLogID: String!, $CareerLogInput: CareerLogInput!, $DocumentInput: DocumentsInput, $IsCopyCurrentYearTP3: Boolean, $ContentURL: Upload, $Content: String, $ReportTemplateType: String) {
  StaffTransferUpdate(CareerLogInput: $CareerLogInput, DocumentInput: $DocumentInput, CareerLogID: $CareerLogID, IsCopyCurrentYearTP3: $IsCopyCurrentYearTP3, ContentURL: $ContentURL, Content: $Content, ReportTemplateType: $ReportTemplateType)
}
    `;
export type StaffTransferUpdateMutationFn = ApolloReactCommon.MutationFunction<StaffTransferUpdateMutation, StaffTransferUpdateMutationVariables>;

/**
 * __useStaffTransferUpdateMutation__
 *
 * To run a mutation, you first call `useStaffTransferUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffTransferUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffTransferUpdateMutation, { data, loading, error }] = useStaffTransferUpdateMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CareerLogInput: // value for 'CareerLogInput'
 *      DocumentInput: // value for 'DocumentInput'
 *      IsCopyCurrentYearTP3: // value for 'IsCopyCurrentYearTP3'
 *      ContentURL: // value for 'ContentURL'
 *      Content: // value for 'Content'
 *      ReportTemplateType: // value for 'ReportTemplateType'
 *   },
 * });
 */
export function useStaffTransferUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffTransferUpdateMutation, StaffTransferUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffTransferUpdateMutation, StaffTransferUpdateMutationVariables>(StaffTransferUpdateDocument, baseOptions);
      }
export type StaffTransferUpdateMutationHookResult = ReturnType<typeof useStaffTransferUpdateMutation>;
export type StaffTransferUpdateMutationResult = ApolloReactCommon.MutationResult<StaffTransferUpdateMutation>;
export type StaffTransferUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffTransferUpdateMutation, StaffTransferUpdateMutationVariables>;
export const StaffTransferCancelDocument = gql`
    mutation StaffTransferCancel($CareerLogID: String!, $CancellationRemarks: String) {
  StaffTransferCancel(CareerLogID: $CareerLogID, CancellationRemarks: $CancellationRemarks)
}
    `;
export type StaffTransferCancelMutationFn = ApolloReactCommon.MutationFunction<StaffTransferCancelMutation, StaffTransferCancelMutationVariables>;

/**
 * __useStaffTransferCancelMutation__
 *
 * To run a mutation, you first call `useStaffTransferCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffTransferCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffTransferCancelMutation, { data, loading, error }] = useStaffTransferCancelMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CancellationRemarks: // value for 'CancellationRemarks'
 *   },
 * });
 */
export function useStaffTransferCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffTransferCancelMutation, StaffTransferCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffTransferCancelMutation, StaffTransferCancelMutationVariables>(StaffTransferCancelDocument, baseOptions);
      }
export type StaffTransferCancelMutationHookResult = ReturnType<typeof useStaffTransferCancelMutation>;
export type StaffTransferCancelMutationResult = ApolloReactCommon.MutationResult<StaffTransferCancelMutation>;
export type StaffTransferCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffTransferCancelMutation, StaffTransferCancelMutationVariables>;
export const StaffPromotionDemotionCancelDocument = gql`
    mutation StaffPromotionDemotionCancel($CareerLogID: String!, $CancellationRemarks: String) {
  StaffPromotionDemotionCancel(CareerLogID: $CareerLogID, CancellationRemarks: $CancellationRemarks)
}
    `;
export type StaffPromotionDemotionCancelMutationFn = ApolloReactCommon.MutationFunction<StaffPromotionDemotionCancelMutation, StaffPromotionDemotionCancelMutationVariables>;

/**
 * __useStaffPromotionDemotionCancelMutation__
 *
 * To run a mutation, you first call `useStaffPromotionDemotionCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffPromotionDemotionCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffPromotionDemotionCancelMutation, { data, loading, error }] = useStaffPromotionDemotionCancelMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CancellationRemarks: // value for 'CancellationRemarks'
 *   },
 * });
 */
export function useStaffPromotionDemotionCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffPromotionDemotionCancelMutation, StaffPromotionDemotionCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffPromotionDemotionCancelMutation, StaffPromotionDemotionCancelMutationVariables>(StaffPromotionDemotionCancelDocument, baseOptions);
      }
export type StaffPromotionDemotionCancelMutationHookResult = ReturnType<typeof useStaffPromotionDemotionCancelMutation>;
export type StaffPromotionDemotionCancelMutationResult = ApolloReactCommon.MutationResult<StaffPromotionDemotionCancelMutation>;
export type StaffPromotionDemotionCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffPromotionDemotionCancelMutation, StaffPromotionDemotionCancelMutationVariables>;
export const StaffExitCompanyCancelDocument = gql`
    mutation StaffExitCompanyCancel($CareerLogID: String!, $CancellationRemarks: String) {
  StaffExitCompanyCancel(CareerLogID: $CareerLogID, CancellationRemarks: $CancellationRemarks)
}
    `;
export type StaffExitCompanyCancelMutationFn = ApolloReactCommon.MutationFunction<StaffExitCompanyCancelMutation, StaffExitCompanyCancelMutationVariables>;

/**
 * __useStaffExitCompanyCancelMutation__
 *
 * To run a mutation, you first call `useStaffExitCompanyCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffExitCompanyCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffExitCompanyCancelMutation, { data, loading, error }] = useStaffExitCompanyCancelMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CancellationRemarks: // value for 'CancellationRemarks'
 *   },
 * });
 */
export function useStaffExitCompanyCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffExitCompanyCancelMutation, StaffExitCompanyCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffExitCompanyCancelMutation, StaffExitCompanyCancelMutationVariables>(StaffExitCompanyCancelDocument, baseOptions);
      }
export type StaffExitCompanyCancelMutationHookResult = ReturnType<typeof useStaffExitCompanyCancelMutation>;
export type StaffExitCompanyCancelMutationResult = ApolloReactCommon.MutationResult<StaffExitCompanyCancelMutation>;
export type StaffExitCompanyCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffExitCompanyCancelMutation, StaffExitCompanyCancelMutationVariables>;
export const StaffSecondmentCancelDocument = gql`
    mutation StaffSecondmentCancel($CareerLogID: String!, $CancellationRemarks: String) {
  StaffTransferCancel(CareerLogID: $CareerLogID, CancellationRemarks: $CancellationRemarks)
}
    `;
export type StaffSecondmentCancelMutationFn = ApolloReactCommon.MutationFunction<StaffSecondmentCancelMutation, StaffSecondmentCancelMutationVariables>;

/**
 * __useStaffSecondmentCancelMutation__
 *
 * To run a mutation, you first call `useStaffSecondmentCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffSecondmentCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffSecondmentCancelMutation, { data, loading, error }] = useStaffSecondmentCancelMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CancellationRemarks: // value for 'CancellationRemarks'
 *   },
 * });
 */
export function useStaffSecondmentCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffSecondmentCancelMutation, StaffSecondmentCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffSecondmentCancelMutation, StaffSecondmentCancelMutationVariables>(StaffSecondmentCancelDocument, baseOptions);
      }
export type StaffSecondmentCancelMutationHookResult = ReturnType<typeof useStaffSecondmentCancelMutation>;
export type StaffSecondmentCancelMutationResult = ApolloReactCommon.MutationResult<StaffSecondmentCancelMutation>;
export type StaffSecondmentCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffSecondmentCancelMutation, StaffSecondmentCancelMutationVariables>;
export const StaffInterCompanyTransferCancelDocument = gql`
    mutation StaffInterCompanyTransferCancel($CareerLogID: String!, $CancellationRemarks: String) {
  StaffTransferCancel(CareerLogID: $CareerLogID, CancellationRemarks: $CancellationRemarks)
}
    `;
export type StaffInterCompanyTransferCancelMutationFn = ApolloReactCommon.MutationFunction<StaffInterCompanyTransferCancelMutation, StaffInterCompanyTransferCancelMutationVariables>;

/**
 * __useStaffInterCompanyTransferCancelMutation__
 *
 * To run a mutation, you first call `useStaffInterCompanyTransferCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffInterCompanyTransferCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffInterCompanyTransferCancelMutation, { data, loading, error }] = useStaffInterCompanyTransferCancelMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *      CancellationRemarks: // value for 'CancellationRemarks'
 *   },
 * });
 */
export function useStaffInterCompanyTransferCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffInterCompanyTransferCancelMutation, StaffInterCompanyTransferCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffInterCompanyTransferCancelMutation, StaffInterCompanyTransferCancelMutationVariables>(StaffInterCompanyTransferCancelDocument, baseOptions);
      }
export type StaffInterCompanyTransferCancelMutationHookResult = ReturnType<typeof useStaffInterCompanyTransferCancelMutation>;
export type StaffInterCompanyTransferCancelMutationResult = ApolloReactCommon.MutationResult<StaffInterCompanyTransferCancelMutation>;
export type StaffInterCompanyTransferCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffInterCompanyTransferCancelMutation, StaffInterCompanyTransferCancelMutationVariables>;
export const StaffConfirmationCancelConfirmedDocument = gql`
    mutation StaffConfirmationCancelConfirmed($CareerLogID: String!) {
  StaffConfirmationCancelConfirmed(CareerLogID: $CareerLogID)
}
    `;
export type StaffConfirmationCancelConfirmedMutationFn = ApolloReactCommon.MutationFunction<StaffConfirmationCancelConfirmedMutation, StaffConfirmationCancelConfirmedMutationVariables>;

/**
 * __useStaffConfirmationCancelConfirmedMutation__
 *
 * To run a mutation, you first call `useStaffConfirmationCancelConfirmedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffConfirmationCancelConfirmedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffConfirmationCancelConfirmedMutation, { data, loading, error }] = useStaffConfirmationCancelConfirmedMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *   },
 * });
 */
export function useStaffConfirmationCancelConfirmedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffConfirmationCancelConfirmedMutation, StaffConfirmationCancelConfirmedMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffConfirmationCancelConfirmedMutation, StaffConfirmationCancelConfirmedMutationVariables>(StaffConfirmationCancelConfirmedDocument, baseOptions);
      }
export type StaffConfirmationCancelConfirmedMutationHookResult = ReturnType<typeof useStaffConfirmationCancelConfirmedMutation>;
export type StaffConfirmationCancelConfirmedMutationResult = ApolloReactCommon.MutationResult<StaffConfirmationCancelConfirmedMutation>;
export type StaffConfirmationCancelConfirmedMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffConfirmationCancelConfirmedMutation, StaffConfirmationCancelConfirmedMutationVariables>;
export const StaffRetirementCancelDocument = gql`
    mutation StaffRetirementCancel($CareerLogID: String!) {
  StaffExitCompanyCancel(CareerLogID: $CareerLogID)
}
    `;
export type StaffRetirementCancelMutationFn = ApolloReactCommon.MutationFunction<StaffRetirementCancelMutation, StaffRetirementCancelMutationVariables>;

/**
 * __useStaffRetirementCancelMutation__
 *
 * To run a mutation, you first call `useStaffRetirementCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffRetirementCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffRetirementCancelMutation, { data, loading, error }] = useStaffRetirementCancelMutation({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *   },
 * });
 */
export function useStaffRetirementCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffRetirementCancelMutation, StaffRetirementCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffRetirementCancelMutation, StaffRetirementCancelMutationVariables>(StaffRetirementCancelDocument, baseOptions);
      }
export type StaffRetirementCancelMutationHookResult = ReturnType<typeof useStaffRetirementCancelMutation>;
export type StaffRetirementCancelMutationResult = ApolloReactCommon.MutationResult<StaffRetirementCancelMutation>;
export type StaffRetirementCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffRetirementCancelMutation, StaffRetirementCancelMutationVariables>;
export const PersonnelHomeInfoLinesDocument = gql`
    query PersonnelHomeInfoLines($CompanyID: String!) {
  PersonnelHomeInfoLines(CompanyID: $CompanyID) {
    EmpBySubsTotal
    EmpTotal
    MthNewEmpTotal
    OverdueConfTotal
    UpcomingConfTotal
    MthProbExtTotal
    MthPromoTotal
    MthDemoTotal
    MthSecondTotal
    MthRedesTotal
    MthIntTransTotal
    MthIntCompTransTotal
    MthTerminationTotal
    MthRetireTotal
    MthOffBoardTotal
    InsSchemeTotal
    DataChangesApplicationPendingTotal
  }
}
    `;

/**
 * __usePersonnelHomeInfoLinesQuery__
 *
 * To run a query within a React component, call `usePersonnelHomeInfoLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelHomeInfoLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelHomeInfoLinesQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePersonnelHomeInfoLinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelHomeInfoLinesQuery, PersonnelHomeInfoLinesQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelHomeInfoLinesQuery, PersonnelHomeInfoLinesQueryVariables>(PersonnelHomeInfoLinesDocument, baseOptions);
      }
export function usePersonnelHomeInfoLinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelHomeInfoLinesQuery, PersonnelHomeInfoLinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelHomeInfoLinesQuery, PersonnelHomeInfoLinesQueryVariables>(PersonnelHomeInfoLinesDocument, baseOptions);
        }
export type PersonnelHomeInfoLinesQueryHookResult = ReturnType<typeof usePersonnelHomeInfoLinesQuery>;
export type PersonnelHomeInfoLinesLazyQueryHookResult = ReturnType<typeof usePersonnelHomeInfoLinesLazyQuery>;
export type PersonnelHomeInfoLinesQueryResult = ApolloReactCommon.QueryResult<PersonnelHomeInfoLinesQuery, PersonnelHomeInfoLinesQueryVariables>;
export const CostCenterMappingListDocument = gql`
    query CostCenterMappingList($CompanyID: String!) {
  CostCenterMappingList(CompanyID: $CompanyID)
}
    `;

/**
 * __useCostCenterMappingListQuery__
 *
 * To run a query within a React component, call `useCostCenterMappingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCenterMappingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCenterMappingListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCostCenterMappingListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CostCenterMappingListQuery, CostCenterMappingListQueryVariables>) {
        return ApolloReactHooks.useQuery<CostCenterMappingListQuery, CostCenterMappingListQueryVariables>(CostCenterMappingListDocument, baseOptions);
      }
export function useCostCenterMappingListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CostCenterMappingListQuery, CostCenterMappingListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CostCenterMappingListQuery, CostCenterMappingListQueryVariables>(CostCenterMappingListDocument, baseOptions);
        }
export type CostCenterMappingListQueryHookResult = ReturnType<typeof useCostCenterMappingListQuery>;
export type CostCenterMappingListLazyQueryHookResult = ReturnType<typeof useCostCenterMappingListLazyQuery>;
export type CostCenterMappingListQueryResult = ApolloReactCommon.QueryResult<CostCenterMappingListQuery, CostCenterMappingListQueryVariables>;
export const CostMappingUpdateDocument = gql`
    mutation CostMappingUpdate($input: [CostCenterMappingInput!]!) {
  CostMappingUpdate(input: $input)
}
    `;
export type CostMappingUpdateMutationFn = ApolloReactCommon.MutationFunction<CostMappingUpdateMutation, CostMappingUpdateMutationVariables>;

/**
 * __useCostMappingUpdateMutation__
 *
 * To run a mutation, you first call `useCostMappingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCostMappingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [costMappingUpdateMutation, { data, loading, error }] = useCostMappingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCostMappingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CostMappingUpdateMutation, CostMappingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CostMappingUpdateMutation, CostMappingUpdateMutationVariables>(CostMappingUpdateDocument, baseOptions);
      }
export type CostMappingUpdateMutationHookResult = ReturnType<typeof useCostMappingUpdateMutation>;
export type CostMappingUpdateMutationResult = ApolloReactCommon.MutationResult<CostMappingUpdateMutation>;
export type CostMappingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CostMappingUpdateMutation, CostMappingUpdateMutationVariables>;
export const StaffInterCompanyTransferCopyTransferDataDocument = gql`
    query StaffInterCompanyTransferCopyTransferData($NewCompanyID: String!, $CurrentEmployeeID: String!, $CurrentCompanyID: String!, $EffectiveDate: DateTime!) {
  StaffInterCompanyTransferCopyTransferData(NewCompanyID: $NewCompanyID, CurrentEmployeeID: $CurrentEmployeeID, CurrentCompanyID: $CurrentCompanyID, EffectiveDate: $EffectiveDate)
}
    `;

/**
 * __useStaffInterCompanyTransferCopyTransferDataQuery__
 *
 * To run a query within a React component, call `useStaffInterCompanyTransferCopyTransferDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffInterCompanyTransferCopyTransferDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffInterCompanyTransferCopyTransferDataQuery({
 *   variables: {
 *      NewCompanyID: // value for 'NewCompanyID'
 *      CurrentEmployeeID: // value for 'CurrentEmployeeID'
 *      CurrentCompanyID: // value for 'CurrentCompanyID'
 *      EffectiveDate: // value for 'EffectiveDate'
 *   },
 * });
 */
export function useStaffInterCompanyTransferCopyTransferDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffInterCompanyTransferCopyTransferDataQuery, StaffInterCompanyTransferCopyTransferDataQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffInterCompanyTransferCopyTransferDataQuery, StaffInterCompanyTransferCopyTransferDataQueryVariables>(StaffInterCompanyTransferCopyTransferDataDocument, baseOptions);
      }
export function useStaffInterCompanyTransferCopyTransferDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffInterCompanyTransferCopyTransferDataQuery, StaffInterCompanyTransferCopyTransferDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffInterCompanyTransferCopyTransferDataQuery, StaffInterCompanyTransferCopyTransferDataQueryVariables>(StaffInterCompanyTransferCopyTransferDataDocument, baseOptions);
        }
export type StaffInterCompanyTransferCopyTransferDataQueryHookResult = ReturnType<typeof useStaffInterCompanyTransferCopyTransferDataQuery>;
export type StaffInterCompanyTransferCopyTransferDataLazyQueryHookResult = ReturnType<typeof useStaffInterCompanyTransferCopyTransferDataLazyQuery>;
export type StaffInterCompanyTransferCopyTransferDataQueryResult = ApolloReactCommon.QueryResult<StaffInterCompanyTransferCopyTransferDataQuery, StaffInterCompanyTransferCopyTransferDataQueryVariables>;
export const StaffInterCompanyTransferDetailsDocument = gql`
    query StaffInterCompanyTransferDetails($CareerLogID: String!) {
  StaffInterCompanyTransferDetails(CareerLogID: $CareerLogID) {
    ShiftGroupName
    ReportToSuperiorName
    CalendarStateTitle
    PrevCompReportToSuperiorName
  }
}
    `;

/**
 * __useStaffInterCompanyTransferDetailsQuery__
 *
 * To run a query within a React component, call `useStaffInterCompanyTransferDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffInterCompanyTransferDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffInterCompanyTransferDetailsQuery({
 *   variables: {
 *      CareerLogID: // value for 'CareerLogID'
 *   },
 * });
 */
export function useStaffInterCompanyTransferDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffInterCompanyTransferDetailsQuery, StaffInterCompanyTransferDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffInterCompanyTransferDetailsQuery, StaffInterCompanyTransferDetailsQueryVariables>(StaffInterCompanyTransferDetailsDocument, baseOptions);
      }
export function useStaffInterCompanyTransferDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffInterCompanyTransferDetailsQuery, StaffInterCompanyTransferDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffInterCompanyTransferDetailsQuery, StaffInterCompanyTransferDetailsQueryVariables>(StaffInterCompanyTransferDetailsDocument, baseOptions);
        }
export type StaffInterCompanyTransferDetailsQueryHookResult = ReturnType<typeof useStaffInterCompanyTransferDetailsQuery>;
export type StaffInterCompanyTransferDetailsLazyQueryHookResult = ReturnType<typeof useStaffInterCompanyTransferDetailsLazyQuery>;
export type StaffInterCompanyTransferDetailsQueryResult = ApolloReactCommon.QueryResult<StaffInterCompanyTransferDetailsQuery, StaffInterCompanyTransferDetailsQueryVariables>;
export const PersonnelCertificateListingDocument = gql`
    query PersonnelCertificateListing($PersonnelID: String!, $PersonnelCertificateID: String) {
  PersonnelCertificateListing(PersonnelID: $PersonnelID, PersonnelCertificateID: $PersonnelCertificateID) {
    PersonnelCertificateID
    Description
    IssueDate
    ExpiryDate
    RegNo
    CertificateUrl
    DocumentID
    Document {
      DocumentID
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
  }
}
    `;

/**
 * __usePersonnelCertificateListingQuery__
 *
 * To run a query within a React component, call `usePersonnelCertificateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelCertificateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelCertificateListingQuery({
 *   variables: {
 *      PersonnelID: // value for 'PersonnelID'
 *      PersonnelCertificateID: // value for 'PersonnelCertificateID'
 *   },
 * });
 */
export function usePersonnelCertificateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelCertificateListingQuery, PersonnelCertificateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelCertificateListingQuery, PersonnelCertificateListingQueryVariables>(PersonnelCertificateListingDocument, baseOptions);
      }
export function usePersonnelCertificateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelCertificateListingQuery, PersonnelCertificateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelCertificateListingQuery, PersonnelCertificateListingQueryVariables>(PersonnelCertificateListingDocument, baseOptions);
        }
export type PersonnelCertificateListingQueryHookResult = ReturnType<typeof usePersonnelCertificateListingQuery>;
export type PersonnelCertificateListingLazyQueryHookResult = ReturnType<typeof usePersonnelCertificateListingLazyQuery>;
export type PersonnelCertificateListingQueryResult = ApolloReactCommon.QueryResult<PersonnelCertificateListingQuery, PersonnelCertificateListingQueryVariables>;
export const IsMobileNoExistsDocument = gql`
    query IsMobileNoExists($MobileNo: String!) {
  IsMobileNoExists(MobileNo: $MobileNo)
}
    `;

/**
 * __useIsMobileNoExistsQuery__
 *
 * To run a query within a React component, call `useIsMobileNoExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsMobileNoExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsMobileNoExistsQuery({
 *   variables: {
 *      MobileNo: // value for 'MobileNo'
 *   },
 * });
 */
export function useIsMobileNoExistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsMobileNoExistsQuery, IsMobileNoExistsQueryVariables>) {
        return ApolloReactHooks.useQuery<IsMobileNoExistsQuery, IsMobileNoExistsQueryVariables>(IsMobileNoExistsDocument, baseOptions);
      }
export function useIsMobileNoExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsMobileNoExistsQuery, IsMobileNoExistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsMobileNoExistsQuery, IsMobileNoExistsQueryVariables>(IsMobileNoExistsDocument, baseOptions);
        }
export type IsMobileNoExistsQueryHookResult = ReturnType<typeof useIsMobileNoExistsQuery>;
export type IsMobileNoExistsLazyQueryHookResult = ReturnType<typeof useIsMobileNoExistsLazyQuery>;
export type IsMobileNoExistsQueryResult = ApolloReactCommon.QueryResult<IsMobileNoExistsQuery, IsMobileNoExistsQueryVariables>;
export const IsEmailExistsDocument = gql`
    query IsEmailExists($Email: String!) {
  IsEmailExists(Email: $Email)
}
    `;

/**
 * __useIsEmailExistsQuery__
 *
 * To run a query within a React component, call `useIsEmailExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailExistsQuery({
 *   variables: {
 *      Email: // value for 'Email'
 *   },
 * });
 */
export function useIsEmailExistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsEmailExistsQuery, IsEmailExistsQueryVariables>) {
        return ApolloReactHooks.useQuery<IsEmailExistsQuery, IsEmailExistsQueryVariables>(IsEmailExistsDocument, baseOptions);
      }
export function useIsEmailExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsEmailExistsQuery, IsEmailExistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsEmailExistsQuery, IsEmailExistsQueryVariables>(IsEmailExistsDocument, baseOptions);
        }
export type IsEmailExistsQueryHookResult = ReturnType<typeof useIsEmailExistsQuery>;
export type IsEmailExistsLazyQueryHookResult = ReturnType<typeof useIsEmailExistsLazyQuery>;
export type IsEmailExistsQueryResult = ApolloReactCommon.QueryResult<IsEmailExistsQuery, IsEmailExistsQueryVariables>;
export const IsEmployeeNoExistsDocument = gql`
    query IsEmployeeNoExists($EmployeeNo: String!) {
  IsEmployeeNoExists(EmployeeNo: $EmployeeNo)
}
    `;

/**
 * __useIsEmployeeNoExistsQuery__
 *
 * To run a query within a React component, call `useIsEmployeeNoExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmployeeNoExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmployeeNoExistsQuery({
 *   variables: {
 *      EmployeeNo: // value for 'EmployeeNo'
 *   },
 * });
 */
export function useIsEmployeeNoExistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsEmployeeNoExistsQuery, IsEmployeeNoExistsQueryVariables>) {
        return ApolloReactHooks.useQuery<IsEmployeeNoExistsQuery, IsEmployeeNoExistsQueryVariables>(IsEmployeeNoExistsDocument, baseOptions);
      }
export function useIsEmployeeNoExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsEmployeeNoExistsQuery, IsEmployeeNoExistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsEmployeeNoExistsQuery, IsEmployeeNoExistsQueryVariables>(IsEmployeeNoExistsDocument, baseOptions);
        }
export type IsEmployeeNoExistsQueryHookResult = ReturnType<typeof useIsEmployeeNoExistsQuery>;
export type IsEmployeeNoExistsLazyQueryHookResult = ReturnType<typeof useIsEmployeeNoExistsLazyQuery>;
export type IsEmployeeNoExistsQueryResult = ApolloReactCommon.QueryResult<IsEmployeeNoExistsQuery, IsEmployeeNoExistsQueryVariables>;
export const ContractInfoInsertDocument = gql`
    mutation ContractInfoInsert($ContractInfoInput: ContractInfoInput!) {
  ContractInfoInsert(ContractInfoInput: $ContractInfoInput)
}
    `;
export type ContractInfoInsertMutationFn = ApolloReactCommon.MutationFunction<ContractInfoInsertMutation, ContractInfoInsertMutationVariables>;

/**
 * __useContractInfoInsertMutation__
 *
 * To run a mutation, you first call `useContractInfoInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractInfoInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractInfoInsertMutation, { data, loading, error }] = useContractInfoInsertMutation({
 *   variables: {
 *      ContractInfoInput: // value for 'ContractInfoInput'
 *   },
 * });
 */
export function useContractInfoInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContractInfoInsertMutation, ContractInfoInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ContractInfoInsertMutation, ContractInfoInsertMutationVariables>(ContractInfoInsertDocument, baseOptions);
      }
export type ContractInfoInsertMutationHookResult = ReturnType<typeof useContractInfoInsertMutation>;
export type ContractInfoInsertMutationResult = ApolloReactCommon.MutationResult<ContractInfoInsertMutation>;
export type ContractInfoInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ContractInfoInsertMutation, ContractInfoInsertMutationVariables>;
export const ContractInfoUpdateDocument = gql`
    mutation ContractInfoUpdate($ContractInfoInput: ContractInfoInput!, $ContractInfoID: String!) {
  ContractInfoUpdate(ContractInfoInput: $ContractInfoInput, ContractInfoID: $ContractInfoID)
}
    `;
export type ContractInfoUpdateMutationFn = ApolloReactCommon.MutationFunction<ContractInfoUpdateMutation, ContractInfoUpdateMutationVariables>;

/**
 * __useContractInfoUpdateMutation__
 *
 * To run a mutation, you first call `useContractInfoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractInfoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractInfoUpdateMutation, { data, loading, error }] = useContractInfoUpdateMutation({
 *   variables: {
 *      ContractInfoInput: // value for 'ContractInfoInput'
 *      ContractInfoID: // value for 'ContractInfoID'
 *   },
 * });
 */
export function useContractInfoUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContractInfoUpdateMutation, ContractInfoUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ContractInfoUpdateMutation, ContractInfoUpdateMutationVariables>(ContractInfoUpdateDocument, baseOptions);
      }
export type ContractInfoUpdateMutationHookResult = ReturnType<typeof useContractInfoUpdateMutation>;
export type ContractInfoUpdateMutationResult = ApolloReactCommon.MutationResult<ContractInfoUpdateMutation>;
export type ContractInfoUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ContractInfoUpdateMutation, ContractInfoUpdateMutationVariables>;
export const ContractInfoDeleteDocument = gql`
    mutation ContractInfoDelete($ContractInfoID: String!) {
  ContractInfoDelete(ContractInfoID: $ContractInfoID)
}
    `;
export type ContractInfoDeleteMutationFn = ApolloReactCommon.MutationFunction<ContractInfoDeleteMutation, ContractInfoDeleteMutationVariables>;

/**
 * __useContractInfoDeleteMutation__
 *
 * To run a mutation, you first call `useContractInfoDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContractInfoDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contractInfoDeleteMutation, { data, loading, error }] = useContractInfoDeleteMutation({
 *   variables: {
 *      ContractInfoID: // value for 'ContractInfoID'
 *   },
 * });
 */
export function useContractInfoDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContractInfoDeleteMutation, ContractInfoDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ContractInfoDeleteMutation, ContractInfoDeleteMutationVariables>(ContractInfoDeleteDocument, baseOptions);
      }
export type ContractInfoDeleteMutationHookResult = ReturnType<typeof useContractInfoDeleteMutation>;
export type ContractInfoDeleteMutationResult = ApolloReactCommon.MutationResult<ContractInfoDeleteMutation>;
export type ContractInfoDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ContractInfoDeleteMutation, ContractInfoDeleteMutationVariables>;
export const ReportDocDeleteDocument = gql`
    mutation ReportDocDelete($DocumentID: String!) {
  ReportDocDelete(DocumentID: $DocumentID)
}
    `;
export type ReportDocDeleteMutationFn = ApolloReactCommon.MutationFunction<ReportDocDeleteMutation, ReportDocDeleteMutationVariables>;

/**
 * __useReportDocDeleteMutation__
 *
 * To run a mutation, you first call `useReportDocDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportDocDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportDocDeleteMutation, { data, loading, error }] = useReportDocDeleteMutation({
 *   variables: {
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useReportDocDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportDocDeleteMutation, ReportDocDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportDocDeleteMutation, ReportDocDeleteMutationVariables>(ReportDocDeleteDocument, baseOptions);
      }
export type ReportDocDeleteMutationHookResult = ReturnType<typeof useReportDocDeleteMutation>;
export type ReportDocDeleteMutationResult = ApolloReactCommon.MutationResult<ReportDocDeleteMutation>;
export type ReportDocDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportDocDeleteMutation, ReportDocDeleteMutationVariables>;
export const PersonnelSecurityPolicyInfoByRoleDocument = gql`
    query PersonnelSecurityPolicyInfoByRole {
  PersonnelSecurityPolicyInfoByRole {
    ID
    SecurityPolicy
  }
}
    `;

/**
 * __usePersonnelSecurityPolicyInfoByRoleQuery__
 *
 * To run a query within a React component, call `usePersonnelSecurityPolicyInfoByRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelSecurityPolicyInfoByRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelSecurityPolicyInfoByRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonnelSecurityPolicyInfoByRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelSecurityPolicyInfoByRoleQuery, PersonnelSecurityPolicyInfoByRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelSecurityPolicyInfoByRoleQuery, PersonnelSecurityPolicyInfoByRoleQueryVariables>(PersonnelSecurityPolicyInfoByRoleDocument, baseOptions);
      }
export function usePersonnelSecurityPolicyInfoByRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelSecurityPolicyInfoByRoleQuery, PersonnelSecurityPolicyInfoByRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelSecurityPolicyInfoByRoleQuery, PersonnelSecurityPolicyInfoByRoleQueryVariables>(PersonnelSecurityPolicyInfoByRoleDocument, baseOptions);
        }
export type PersonnelSecurityPolicyInfoByRoleQueryHookResult = ReturnType<typeof usePersonnelSecurityPolicyInfoByRoleQuery>;
export type PersonnelSecurityPolicyInfoByRoleLazyQueryHookResult = ReturnType<typeof usePersonnelSecurityPolicyInfoByRoleLazyQuery>;
export type PersonnelSecurityPolicyInfoByRoleQueryResult = ApolloReactCommon.QueryResult<PersonnelSecurityPolicyInfoByRoleQuery, PersonnelSecurityPolicyInfoByRoleQueryVariables>;
export const PersonnelSecurityPolicySubmitDocument = gql`
    mutation PersonnelSecurityPolicySubmit($input: PersonnelSecurityPolicyInput!) {
  PersonnelSecurityPolicySubmit(input: $input)
}
    `;
export type PersonnelSecurityPolicySubmitMutationFn = ApolloReactCommon.MutationFunction<PersonnelSecurityPolicySubmitMutation, PersonnelSecurityPolicySubmitMutationVariables>;

/**
 * __usePersonnelSecurityPolicySubmitMutation__
 *
 * To run a mutation, you first call `usePersonnelSecurityPolicySubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePersonnelSecurityPolicySubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [personnelSecurityPolicySubmitMutation, { data, loading, error }] = usePersonnelSecurityPolicySubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePersonnelSecurityPolicySubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PersonnelSecurityPolicySubmitMutation, PersonnelSecurityPolicySubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<PersonnelSecurityPolicySubmitMutation, PersonnelSecurityPolicySubmitMutationVariables>(PersonnelSecurityPolicySubmitDocument, baseOptions);
      }
export type PersonnelSecurityPolicySubmitMutationHookResult = ReturnType<typeof usePersonnelSecurityPolicySubmitMutation>;
export type PersonnelSecurityPolicySubmitMutationResult = ApolloReactCommon.MutationResult<PersonnelSecurityPolicySubmitMutation>;
export type PersonnelSecurityPolicySubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<PersonnelSecurityPolicySubmitMutation, PersonnelSecurityPolicySubmitMutationVariables>;
export const GetEmployeePoliciesDocument = gql`
    query GetEmployeePolicies($EmployeeID: String!, $CompanyID: String!) {
  GetEmployeePolicies(EmployeeID: $EmployeeID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetEmployeePoliciesQuery__
 *
 * To run a query within a React component, call `useGetEmployeePoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeePoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeePoliciesQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetEmployeePoliciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeePoliciesQuery, GetEmployeePoliciesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeePoliciesQuery, GetEmployeePoliciesQueryVariables>(GetEmployeePoliciesDocument, baseOptions);
      }
export function useGetEmployeePoliciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeePoliciesQuery, GetEmployeePoliciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeePoliciesQuery, GetEmployeePoliciesQueryVariables>(GetEmployeePoliciesDocument, baseOptions);
        }
export type GetEmployeePoliciesQueryHookResult = ReturnType<typeof useGetEmployeePoliciesQuery>;
export type GetEmployeePoliciesLazyQueryHookResult = ReturnType<typeof useGetEmployeePoliciesLazyQuery>;
export type GetEmployeePoliciesQueryResult = ApolloReactCommon.QueryResult<GetEmployeePoliciesQuery, GetEmployeePoliciesQueryVariables>;
export const GetEmployeeClaimPoliciesDocument = gql`
    query GetEmployeeClaimPolicies($EmployeeID: String!, $CompanyID: String!) {
  GetEmployeeClaimPolicies(EmployeeID: $EmployeeID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetEmployeeClaimPoliciesQuery__
 *
 * To run a query within a React component, call `useGetEmployeeClaimPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeClaimPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeClaimPoliciesQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetEmployeeClaimPoliciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeClaimPoliciesQuery, GetEmployeeClaimPoliciesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeClaimPoliciesQuery, GetEmployeeClaimPoliciesQueryVariables>(GetEmployeeClaimPoliciesDocument, baseOptions);
      }
export function useGetEmployeeClaimPoliciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeClaimPoliciesQuery, GetEmployeeClaimPoliciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeClaimPoliciesQuery, GetEmployeeClaimPoliciesQueryVariables>(GetEmployeeClaimPoliciesDocument, baseOptions);
        }
export type GetEmployeeClaimPoliciesQueryHookResult = ReturnType<typeof useGetEmployeeClaimPoliciesQuery>;
export type GetEmployeeClaimPoliciesLazyQueryHookResult = ReturnType<typeof useGetEmployeeClaimPoliciesLazyQuery>;
export type GetEmployeeClaimPoliciesQueryResult = ApolloReactCommon.QueryResult<GetEmployeeClaimPoliciesQuery, GetEmployeeClaimPoliciesQueryVariables>;
export const GetLeavePolicyByEmployeeDocument = gql`
    query GetLeavePolicyByEmployee($EmployeeID: String!, $CompanyID: String!) {
  GetLeavePolicyByEmployee(EmployeeID: $EmployeeID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetLeavePolicyByEmployeeQuery__
 *
 * To run a query within a React component, call `useGetLeavePolicyByEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeavePolicyByEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeavePolicyByEmployeeQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetLeavePolicyByEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeavePolicyByEmployeeQuery, GetLeavePolicyByEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeavePolicyByEmployeeQuery, GetLeavePolicyByEmployeeQueryVariables>(GetLeavePolicyByEmployeeDocument, baseOptions);
      }
export function useGetLeavePolicyByEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeavePolicyByEmployeeQuery, GetLeavePolicyByEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeavePolicyByEmployeeQuery, GetLeavePolicyByEmployeeQueryVariables>(GetLeavePolicyByEmployeeDocument, baseOptions);
        }
export type GetLeavePolicyByEmployeeQueryHookResult = ReturnType<typeof useGetLeavePolicyByEmployeeQuery>;
export type GetLeavePolicyByEmployeeLazyQueryHookResult = ReturnType<typeof useGetLeavePolicyByEmployeeLazyQuery>;
export type GetLeavePolicyByEmployeeQueryResult = ApolloReactCommon.QueryResult<GetLeavePolicyByEmployeeQuery, GetLeavePolicyByEmployeeQueryVariables>;
export const ProjecCommonSettingSummaryDocument = gql`
    query ProjecCommonSettingSummary {
  ProjecCommonSettingSummary
}
    `;

/**
 * __useProjecCommonSettingSummaryQuery__
 *
 * To run a query within a React component, call `useProjecCommonSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjecCommonSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjecCommonSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjecCommonSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjecCommonSettingSummaryQuery, ProjecCommonSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjecCommonSettingSummaryQuery, ProjecCommonSettingSummaryQueryVariables>(ProjecCommonSettingSummaryDocument, baseOptions);
      }
export function useProjecCommonSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjecCommonSettingSummaryQuery, ProjecCommonSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjecCommonSettingSummaryQuery, ProjecCommonSettingSummaryQueryVariables>(ProjecCommonSettingSummaryDocument, baseOptions);
        }
export type ProjecCommonSettingSummaryQueryHookResult = ReturnType<typeof useProjecCommonSettingSummaryQuery>;
export type ProjecCommonSettingSummaryLazyQueryHookResult = ReturnType<typeof useProjecCommonSettingSummaryLazyQuery>;
export type ProjecCommonSettingSummaryQueryResult = ApolloReactCommon.QueryResult<ProjecCommonSettingSummaryQuery, ProjecCommonSettingSummaryQueryVariables>;
export const ProjectMasterListingDocument = gql`
    query ProjectMasterListing {
  ProjectMasterListing {
    ID
    ProjectName
    CostCenter
    CostCenterDescription
    ValidUntil
    ProjectOwnerID
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ProjectCostAllocationDetail {
      ID
    }
    ProjectExpenseClaimHeader {
      ID
    }
  }
}
    `;

/**
 * __useProjectMasterListingQuery__
 *
 * To run a query within a React component, call `useProjectMasterListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMasterListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMasterListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectMasterListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectMasterListingQuery, ProjectMasterListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectMasterListingQuery, ProjectMasterListingQueryVariables>(ProjectMasterListingDocument, baseOptions);
      }
export function useProjectMasterListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectMasterListingQuery, ProjectMasterListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectMasterListingQuery, ProjectMasterListingQueryVariables>(ProjectMasterListingDocument, baseOptions);
        }
export type ProjectMasterListingQueryHookResult = ReturnType<typeof useProjectMasterListingQuery>;
export type ProjectMasterListingLazyQueryHookResult = ReturnType<typeof useProjectMasterListingLazyQuery>;
export type ProjectMasterListingQueryResult = ApolloReactCommon.QueryResult<ProjectMasterListingQuery, ProjectMasterListingQueryVariables>;
export const ProjectMasterSubmitDocument = gql`
    mutation ProjectMasterSubmit($input: ProjectMasterInput!) {
  ProjectMasterSubmit(input: $input) {
    ID
  }
}
    `;
export type ProjectMasterSubmitMutationFn = ApolloReactCommon.MutationFunction<ProjectMasterSubmitMutation, ProjectMasterSubmitMutationVariables>;

/**
 * __useProjectMasterSubmitMutation__
 *
 * To run a mutation, you first call `useProjectMasterSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectMasterSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectMasterSubmitMutation, { data, loading, error }] = useProjectMasterSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectMasterSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectMasterSubmitMutation, ProjectMasterSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectMasterSubmitMutation, ProjectMasterSubmitMutationVariables>(ProjectMasterSubmitDocument, baseOptions);
      }
export type ProjectMasterSubmitMutationHookResult = ReturnType<typeof useProjectMasterSubmitMutation>;
export type ProjectMasterSubmitMutationResult = ApolloReactCommon.MutationResult<ProjectMasterSubmitMutation>;
export type ProjectMasterSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectMasterSubmitMutation, ProjectMasterSubmitMutationVariables>;
export const ProjectMasterDeleteDocument = gql`
    mutation ProjectMasterDelete($ID: String!) {
  ProjectMasterDelete(ID: $ID)
}
    `;
export type ProjectMasterDeleteMutationFn = ApolloReactCommon.MutationFunction<ProjectMasterDeleteMutation, ProjectMasterDeleteMutationVariables>;

/**
 * __useProjectMasterDeleteMutation__
 *
 * To run a mutation, you first call `useProjectMasterDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectMasterDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectMasterDeleteMutation, { data, loading, error }] = useProjectMasterDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useProjectMasterDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectMasterDeleteMutation, ProjectMasterDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectMasterDeleteMutation, ProjectMasterDeleteMutationVariables>(ProjectMasterDeleteDocument, baseOptions);
      }
export type ProjectMasterDeleteMutationHookResult = ReturnType<typeof useProjectMasterDeleteMutation>;
export type ProjectMasterDeleteMutationResult = ApolloReactCommon.MutationResult<ProjectMasterDeleteMutation>;
export type ProjectMasterDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectMasterDeleteMutation, ProjectMasterDeleteMutationVariables>;
export const ProjectExpenseTypeListingDocument = gql`
    query ProjectExpenseTypeListing {
  ProjectExpenseTypeListing {
    ID
    ProjectExpenseType
    PaymentType
    PayItemID
    ValidUntil
    IsRemark
    IsAttachment
    IsReceiptNo
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ProjectExpenseClaimDetail {
      ID
    }
  }
}
    `;

/**
 * __useProjectExpenseTypeListingQuery__
 *
 * To run a query within a React component, call `useProjectExpenseTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectExpenseTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectExpenseTypeListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectExpenseTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectExpenseTypeListingQuery, ProjectExpenseTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectExpenseTypeListingQuery, ProjectExpenseTypeListingQueryVariables>(ProjectExpenseTypeListingDocument, baseOptions);
      }
export function useProjectExpenseTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectExpenseTypeListingQuery, ProjectExpenseTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectExpenseTypeListingQuery, ProjectExpenseTypeListingQueryVariables>(ProjectExpenseTypeListingDocument, baseOptions);
        }
export type ProjectExpenseTypeListingQueryHookResult = ReturnType<typeof useProjectExpenseTypeListingQuery>;
export type ProjectExpenseTypeListingLazyQueryHookResult = ReturnType<typeof useProjectExpenseTypeListingLazyQuery>;
export type ProjectExpenseTypeListingQueryResult = ApolloReactCommon.QueryResult<ProjectExpenseTypeListingQuery, ProjectExpenseTypeListingQueryVariables>;
export const ProjectExpenseTypeSubmitDocument = gql`
    mutation ProjectExpenseTypeSubmit($input: ProjectExpenseTypeInput!) {
  ProjectExpenseTypeSubmit(input: $input) {
    ID
  }
}
    `;
export type ProjectExpenseTypeSubmitMutationFn = ApolloReactCommon.MutationFunction<ProjectExpenseTypeSubmitMutation, ProjectExpenseTypeSubmitMutationVariables>;

/**
 * __useProjectExpenseTypeSubmitMutation__
 *
 * To run a mutation, you first call `useProjectExpenseTypeSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectExpenseTypeSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectExpenseTypeSubmitMutation, { data, loading, error }] = useProjectExpenseTypeSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectExpenseTypeSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectExpenseTypeSubmitMutation, ProjectExpenseTypeSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectExpenseTypeSubmitMutation, ProjectExpenseTypeSubmitMutationVariables>(ProjectExpenseTypeSubmitDocument, baseOptions);
      }
export type ProjectExpenseTypeSubmitMutationHookResult = ReturnType<typeof useProjectExpenseTypeSubmitMutation>;
export type ProjectExpenseTypeSubmitMutationResult = ApolloReactCommon.MutationResult<ProjectExpenseTypeSubmitMutation>;
export type ProjectExpenseTypeSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectExpenseTypeSubmitMutation, ProjectExpenseTypeSubmitMutationVariables>;
export const ProjectExpenseTypeDeleteDocument = gql`
    mutation ProjectExpenseTypeDelete($ID: String!) {
  ProjectExpenseTypeDelete(ID: $ID)
}
    `;
export type ProjectExpenseTypeDeleteMutationFn = ApolloReactCommon.MutationFunction<ProjectExpenseTypeDeleteMutation, ProjectExpenseTypeDeleteMutationVariables>;

/**
 * __useProjectExpenseTypeDeleteMutation__
 *
 * To run a mutation, you first call `useProjectExpenseTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectExpenseTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectExpenseTypeDeleteMutation, { data, loading, error }] = useProjectExpenseTypeDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useProjectExpenseTypeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectExpenseTypeDeleteMutation, ProjectExpenseTypeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectExpenseTypeDeleteMutation, ProjectExpenseTypeDeleteMutationVariables>(ProjectExpenseTypeDeleteDocument, baseOptions);
      }
export type ProjectExpenseTypeDeleteMutationHookResult = ReturnType<typeof useProjectExpenseTypeDeleteMutation>;
export type ProjectExpenseTypeDeleteMutationResult = ApolloReactCommon.MutationResult<ProjectExpenseTypeDeleteMutation>;
export type ProjectExpenseTypeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectExpenseTypeDeleteMutation, ProjectExpenseTypeDeleteMutationVariables>;
export const ProjectAccountMappingListDocument = gql`
    query ProjectAccountMappingList($ProjectAccountHeaderID: String) {
  ProjectAccountMappingList(ProjectAccountHeaderID: $ProjectAccountHeaderID)
}
    `;

/**
 * __useProjectAccountMappingListQuery__
 *
 * To run a query within a React component, call `useProjectAccountMappingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAccountMappingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAccountMappingListQuery({
 *   variables: {
 *      ProjectAccountHeaderID: // value for 'ProjectAccountHeaderID'
 *   },
 * });
 */
export function useProjectAccountMappingListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectAccountMappingListQuery, ProjectAccountMappingListQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectAccountMappingListQuery, ProjectAccountMappingListQueryVariables>(ProjectAccountMappingListDocument, baseOptions);
      }
export function useProjectAccountMappingListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectAccountMappingListQuery, ProjectAccountMappingListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectAccountMappingListQuery, ProjectAccountMappingListQueryVariables>(ProjectAccountMappingListDocument, baseOptions);
        }
export type ProjectAccountMappingListQueryHookResult = ReturnType<typeof useProjectAccountMappingListQuery>;
export type ProjectAccountMappingListLazyQueryHookResult = ReturnType<typeof useProjectAccountMappingListLazyQuery>;
export type ProjectAccountMappingListQueryResult = ApolloReactCommon.QueryResult<ProjectAccountMappingListQuery, ProjectAccountMappingListQueryVariables>;
export const IsGlProcessedDocument = gql`
    query IsGLProcessed($CompanyID: String!, $PayPeriodID: String, $PayrollCycleID: String, $Type: String) {
  IsGLProcessed(CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayrollCycleID: $PayrollCycleID, Type: $Type)
}
    `;

/**
 * __useIsGlProcessedQuery__
 *
 * To run a query within a React component, call `useIsGlProcessedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsGlProcessedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsGlProcessedQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useIsGlProcessedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsGlProcessedQuery, IsGlProcessedQueryVariables>) {
        return ApolloReactHooks.useQuery<IsGlProcessedQuery, IsGlProcessedQueryVariables>(IsGlProcessedDocument, baseOptions);
      }
export function useIsGlProcessedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsGlProcessedQuery, IsGlProcessedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsGlProcessedQuery, IsGlProcessedQueryVariables>(IsGlProcessedDocument, baseOptions);
        }
export type IsGlProcessedQueryHookResult = ReturnType<typeof useIsGlProcessedQuery>;
export type IsGlProcessedLazyQueryHookResult = ReturnType<typeof useIsGlProcessedLazyQuery>;
export type IsGlProcessedQueryResult = ApolloReactCommon.QueryResult<IsGlProcessedQuery, IsGlProcessedQueryVariables>;
export const DeleteProcessedGlDocument = gql`
    mutation DeleteProcessedGL($CompanyID: String!, $PayPeriodID: String, $PayrollCycleID: String, $Type: String) {
  DeleteProcessedGL(CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayrollCycleID: $PayrollCycleID, Type: $Type)
}
    `;
export type DeleteProcessedGlMutationFn = ApolloReactCommon.MutationFunction<DeleteProcessedGlMutation, DeleteProcessedGlMutationVariables>;

/**
 * __useDeleteProcessedGlMutation__
 *
 * To run a mutation, you first call `useDeleteProcessedGlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProcessedGlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProcessedGlMutation, { data, loading, error }] = useDeleteProcessedGlMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useDeleteProcessedGlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProcessedGlMutation, DeleteProcessedGlMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProcessedGlMutation, DeleteProcessedGlMutationVariables>(DeleteProcessedGlDocument, baseOptions);
      }
export type DeleteProcessedGlMutationHookResult = ReturnType<typeof useDeleteProcessedGlMutation>;
export type DeleteProcessedGlMutationResult = ApolloReactCommon.MutationResult<DeleteProcessedGlMutation>;
export type DeleteProcessedGlMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProcessedGlMutation, DeleteProcessedGlMutationVariables>;
export const ProjectCostCenterMappingListForGlExportDocument = gql`
    query ProjectCostCenterMappingListForGLExport($CompanyID: String!, $PayPeriodID: String, $PayrollCycleID: String, $AllPayCycles: Boolean) {
  ProjectCostCenterMappingListForGLExport(CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayrollCycleID: $PayrollCycleID, AllPayCycles: $AllPayCycles)
}
    `;

/**
 * __useProjectCostCenterMappingListForGlExportQuery__
 *
 * To run a query within a React component, call `useProjectCostCenterMappingListForGlExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCostCenterMappingListForGlExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCostCenterMappingListForGlExportQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      AllPayCycles: // value for 'AllPayCycles'
 *   },
 * });
 */
export function useProjectCostCenterMappingListForGlExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectCostCenterMappingListForGlExportQuery, ProjectCostCenterMappingListForGlExportQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectCostCenterMappingListForGlExportQuery, ProjectCostCenterMappingListForGlExportQueryVariables>(ProjectCostCenterMappingListForGlExportDocument, baseOptions);
      }
export function useProjectCostCenterMappingListForGlExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectCostCenterMappingListForGlExportQuery, ProjectCostCenterMappingListForGlExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectCostCenterMappingListForGlExportQuery, ProjectCostCenterMappingListForGlExportQueryVariables>(ProjectCostCenterMappingListForGlExportDocument, baseOptions);
        }
export type ProjectCostCenterMappingListForGlExportQueryHookResult = ReturnType<typeof useProjectCostCenterMappingListForGlExportQuery>;
export type ProjectCostCenterMappingListForGlExportLazyQueryHookResult = ReturnType<typeof useProjectCostCenterMappingListForGlExportLazyQuery>;
export type ProjectCostCenterMappingListForGlExportQueryResult = ApolloReactCommon.QueryResult<ProjectCostCenterMappingListForGlExportQuery, ProjectCostCenterMappingListForGlExportQueryVariables>;
export const ProjectGlAccountMappingListingDocument = gql`
    query ProjectGLAccountMappingListing {
  ProjectGLAccountMappingListing {
    ID
    Title
    IsStaffCategory
    ProjectGLAccountMappingDetail {
      ID
      ProjectGLAccountMappingID
      StaffCategoryID
      ExpenseTypeID
      IsCentralized
      GLName
      IsCreditCC
      CreditAccount
      CreditAccountDesc
      IsDebitCC
      DebitAccount
      DebitAccountDesc
      SubscriptionAccountID
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ProjectAccountAssignment {
      ID
    }
  }
}
    `;

/**
 * __useProjectGlAccountMappingListingQuery__
 *
 * To run a query within a React component, call `useProjectGlAccountMappingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectGlAccountMappingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectGlAccountMappingListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectGlAccountMappingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectGlAccountMappingListingQuery, ProjectGlAccountMappingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectGlAccountMappingListingQuery, ProjectGlAccountMappingListingQueryVariables>(ProjectGlAccountMappingListingDocument, baseOptions);
      }
export function useProjectGlAccountMappingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectGlAccountMappingListingQuery, ProjectGlAccountMappingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectGlAccountMappingListingQuery, ProjectGlAccountMappingListingQueryVariables>(ProjectGlAccountMappingListingDocument, baseOptions);
        }
export type ProjectGlAccountMappingListingQueryHookResult = ReturnType<typeof useProjectGlAccountMappingListingQuery>;
export type ProjectGlAccountMappingListingLazyQueryHookResult = ReturnType<typeof useProjectGlAccountMappingListingLazyQuery>;
export type ProjectGlAccountMappingListingQueryResult = ApolloReactCommon.QueryResult<ProjectGlAccountMappingListingQuery, ProjectGlAccountMappingListingQueryVariables>;
export const ProjectGlAccountMappingSubmitDocument = gql`
    mutation ProjectGLAccountMappingSubmit($HeaderInput: ProjectGLAccountMappingInput!, $DetailInput: [ProjectGLAccountMappingDetailInput!]!) {
  ProjectGLAccountMappingSubmit(HeaderInput: $HeaderInput, DetailInput: $DetailInput)
}
    `;
export type ProjectGlAccountMappingSubmitMutationFn = ApolloReactCommon.MutationFunction<ProjectGlAccountMappingSubmitMutation, ProjectGlAccountMappingSubmitMutationVariables>;

/**
 * __useProjectGlAccountMappingSubmitMutation__
 *
 * To run a mutation, you first call `useProjectGlAccountMappingSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectGlAccountMappingSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectGlAccountMappingSubmitMutation, { data, loading, error }] = useProjectGlAccountMappingSubmitMutation({
 *   variables: {
 *      HeaderInput: // value for 'HeaderInput'
 *      DetailInput: // value for 'DetailInput'
 *   },
 * });
 */
export function useProjectGlAccountMappingSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectGlAccountMappingSubmitMutation, ProjectGlAccountMappingSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectGlAccountMappingSubmitMutation, ProjectGlAccountMappingSubmitMutationVariables>(ProjectGlAccountMappingSubmitDocument, baseOptions);
      }
export type ProjectGlAccountMappingSubmitMutationHookResult = ReturnType<typeof useProjectGlAccountMappingSubmitMutation>;
export type ProjectGlAccountMappingSubmitMutationResult = ApolloReactCommon.MutationResult<ProjectGlAccountMappingSubmitMutation>;
export type ProjectGlAccountMappingSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectGlAccountMappingSubmitMutation, ProjectGlAccountMappingSubmitMutationVariables>;
export const ProjectGlAccountMappingDeleteDocument = gql`
    mutation ProjectGLAccountMappingDelete($ID: String!) {
  ProjectGLAccountMappingDelete(ID: $ID)
}
    `;
export type ProjectGlAccountMappingDeleteMutationFn = ApolloReactCommon.MutationFunction<ProjectGlAccountMappingDeleteMutation, ProjectGlAccountMappingDeleteMutationVariables>;

/**
 * __useProjectGlAccountMappingDeleteMutation__
 *
 * To run a mutation, you first call `useProjectGlAccountMappingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectGlAccountMappingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectGlAccountMappingDeleteMutation, { data, loading, error }] = useProjectGlAccountMappingDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useProjectGlAccountMappingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectGlAccountMappingDeleteMutation, ProjectGlAccountMappingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectGlAccountMappingDeleteMutation, ProjectGlAccountMappingDeleteMutationVariables>(ProjectGlAccountMappingDeleteDocument, baseOptions);
      }
export type ProjectGlAccountMappingDeleteMutationHookResult = ReturnType<typeof useProjectGlAccountMappingDeleteMutation>;
export type ProjectGlAccountMappingDeleteMutationResult = ApolloReactCommon.MutationResult<ProjectGlAccountMappingDeleteMutation>;
export type ProjectGlAccountMappingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectGlAccountMappingDeleteMutation, ProjectGlAccountMappingDeleteMutationVariables>;
export const CompanyProjectExpenseClaimSettingUpdateDocument = gql`
    mutation CompanyProjectExpenseClaimSettingUpdate($CompanyID: String!, $CompanyInput: CompanyInput!) {
  CompanyProjectExpenseClaimSettingUpdate(CompanyID: $CompanyID, CompanyInput: $CompanyInput)
}
    `;
export type CompanyProjectExpenseClaimSettingUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyProjectExpenseClaimSettingUpdateMutation, CompanyProjectExpenseClaimSettingUpdateMutationVariables>;

/**
 * __useCompanyProjectExpenseClaimSettingUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyProjectExpenseClaimSettingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyProjectExpenseClaimSettingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyProjectExpenseClaimSettingUpdateMutation, { data, loading, error }] = useCompanyProjectExpenseClaimSettingUpdateMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CompanyInput: // value for 'CompanyInput'
 *   },
 * });
 */
export function useCompanyProjectExpenseClaimSettingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyProjectExpenseClaimSettingUpdateMutation, CompanyProjectExpenseClaimSettingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyProjectExpenseClaimSettingUpdateMutation, CompanyProjectExpenseClaimSettingUpdateMutationVariables>(CompanyProjectExpenseClaimSettingUpdateDocument, baseOptions);
      }
export type CompanyProjectExpenseClaimSettingUpdateMutationHookResult = ReturnType<typeof useCompanyProjectExpenseClaimSettingUpdateMutation>;
export type CompanyProjectExpenseClaimSettingUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyProjectExpenseClaimSettingUpdateMutation>;
export type CompanyProjectExpenseClaimSettingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyProjectExpenseClaimSettingUpdateMutation, CompanyProjectExpenseClaimSettingUpdateMutationVariables>;
export const ProjectAccountAssignmentInsertDocument = gql`
    mutation ProjectAccountAssignmentInsert($HeaderInput: ProjectAccountAssignmentInput!) {
  ProjectAccountAssignmentInsert(HeaderInput: $HeaderInput)
}
    `;
export type ProjectAccountAssignmentInsertMutationFn = ApolloReactCommon.MutationFunction<ProjectAccountAssignmentInsertMutation, ProjectAccountAssignmentInsertMutationVariables>;

/**
 * __useProjectAccountAssignmentInsertMutation__
 *
 * To run a mutation, you first call `useProjectAccountAssignmentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectAccountAssignmentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectAccountAssignmentInsertMutation, { data, loading, error }] = useProjectAccountAssignmentInsertMutation({
 *   variables: {
 *      HeaderInput: // value for 'HeaderInput'
 *   },
 * });
 */
export function useProjectAccountAssignmentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectAccountAssignmentInsertMutation, ProjectAccountAssignmentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectAccountAssignmentInsertMutation, ProjectAccountAssignmentInsertMutationVariables>(ProjectAccountAssignmentInsertDocument, baseOptions);
      }
export type ProjectAccountAssignmentInsertMutationHookResult = ReturnType<typeof useProjectAccountAssignmentInsertMutation>;
export type ProjectAccountAssignmentInsertMutationResult = ApolloReactCommon.MutationResult<ProjectAccountAssignmentInsertMutation>;
export type ProjectAccountAssignmentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectAccountAssignmentInsertMutation, ProjectAccountAssignmentInsertMutationVariables>;
export const ProjectAccountAssignmentUpdateDocument = gql`
    mutation ProjectAccountAssignmentUpdate($HeaderInput: ProjectAccountAssignmentInput!) {
  ProjectAccountAssignmentUpdate(HeaderInput: $HeaderInput)
}
    `;
export type ProjectAccountAssignmentUpdateMutationFn = ApolloReactCommon.MutationFunction<ProjectAccountAssignmentUpdateMutation, ProjectAccountAssignmentUpdateMutationVariables>;

/**
 * __useProjectAccountAssignmentUpdateMutation__
 *
 * To run a mutation, you first call `useProjectAccountAssignmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectAccountAssignmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectAccountAssignmentUpdateMutation, { data, loading, error }] = useProjectAccountAssignmentUpdateMutation({
 *   variables: {
 *      HeaderInput: // value for 'HeaderInput'
 *   },
 * });
 */
export function useProjectAccountAssignmentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectAccountAssignmentUpdateMutation, ProjectAccountAssignmentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectAccountAssignmentUpdateMutation, ProjectAccountAssignmentUpdateMutationVariables>(ProjectAccountAssignmentUpdateDocument, baseOptions);
      }
export type ProjectAccountAssignmentUpdateMutationHookResult = ReturnType<typeof useProjectAccountAssignmentUpdateMutation>;
export type ProjectAccountAssignmentUpdateMutationResult = ApolloReactCommon.MutationResult<ProjectAccountAssignmentUpdateMutation>;
export type ProjectAccountAssignmentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectAccountAssignmentUpdateMutation, ProjectAccountAssignmentUpdateMutationVariables>;
export const GetProjectAccountAssignmentDocument = gql`
    query GetProjectAccountAssignment($CompanyID: String!) {
  GetProjectAccountAssignment(CompanyID: $CompanyID) {
    ID
    CompanyID
    ProjectAccountHeaderID
    ProjectAccountHeader {
      Title
    }
  }
}
    `;

/**
 * __useGetProjectAccountAssignmentQuery__
 *
 * To run a query within a React component, call `useGetProjectAccountAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAccountAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAccountAssignmentQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetProjectAccountAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectAccountAssignmentQuery, GetProjectAccountAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectAccountAssignmentQuery, GetProjectAccountAssignmentQueryVariables>(GetProjectAccountAssignmentDocument, baseOptions);
      }
export function useGetProjectAccountAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectAccountAssignmentQuery, GetProjectAccountAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectAccountAssignmentQuery, GetProjectAccountAssignmentQueryVariables>(GetProjectAccountAssignmentDocument, baseOptions);
        }
export type GetProjectAccountAssignmentQueryHookResult = ReturnType<typeof useGetProjectAccountAssignmentQuery>;
export type GetProjectAccountAssignmentLazyQueryHookResult = ReturnType<typeof useGetProjectAccountAssignmentLazyQuery>;
export type GetProjectAccountAssignmentQueryResult = ApolloReactCommon.QueryResult<GetProjectAccountAssignmentQuery, GetProjectAccountAssignmentQueryVariables>;
export const ProjectCostAllocationListingDocument = gql`
    query ProjectCostAllocationListing($CompanyID: String, $EmployeeID: String, $StartDate: DateTime, $EndDate: DateTime, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  ProjectCostAllocationListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, EmployeeID: $EmployeeID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    Status
    CompanyID
    EmployeeID
    StartDate
    EndDate
    Employee {
      Contact {
        FullName
      }
      Documents {
        DocumentFile
      }
      EmployeeNo
    }
    ProjectCostAllocationDetail {
      ID
      ProjectCostAllocationID
      ProjectID
      Allocation
    }
    ModifiedDT
  }
}
    `;

/**
 * __useProjectCostAllocationListingQuery__
 *
 * To run a query within a React component, call `useProjectCostAllocationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCostAllocationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCostAllocationListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useProjectCostAllocationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectCostAllocationListingQuery, ProjectCostAllocationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectCostAllocationListingQuery, ProjectCostAllocationListingQueryVariables>(ProjectCostAllocationListingDocument, baseOptions);
      }
export function useProjectCostAllocationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectCostAllocationListingQuery, ProjectCostAllocationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectCostAllocationListingQuery, ProjectCostAllocationListingQueryVariables>(ProjectCostAllocationListingDocument, baseOptions);
        }
export type ProjectCostAllocationListingQueryHookResult = ReturnType<typeof useProjectCostAllocationListingQuery>;
export type ProjectCostAllocationListingLazyQueryHookResult = ReturnType<typeof useProjectCostAllocationListingLazyQuery>;
export type ProjectCostAllocationListingQueryResult = ApolloReactCommon.QueryResult<ProjectCostAllocationListingQuery, ProjectCostAllocationListingQueryVariables>;
export const ProjectCostAllocationEmployeeListingDocument = gql`
    query ProjectCostAllocationEmployeeListing($CompanyID: String, $StartDate: DateTime, $EndDate: DateTime) {
  ProjectCostAllocationEmployeeListing(CompanyID: $CompanyID, StartDate: $StartDate, EndDate: $EndDate) {
    Contact {
      FullName
    }
    Documents {
      DocumentFile
    }
    EmployeeID
    EmployeeNo
  }
}
    `;

/**
 * __useProjectCostAllocationEmployeeListingQuery__
 *
 * To run a query within a React component, call `useProjectCostAllocationEmployeeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCostAllocationEmployeeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCostAllocationEmployeeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useProjectCostAllocationEmployeeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectCostAllocationEmployeeListingQuery, ProjectCostAllocationEmployeeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectCostAllocationEmployeeListingQuery, ProjectCostAllocationEmployeeListingQueryVariables>(ProjectCostAllocationEmployeeListingDocument, baseOptions);
      }
export function useProjectCostAllocationEmployeeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectCostAllocationEmployeeListingQuery, ProjectCostAllocationEmployeeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectCostAllocationEmployeeListingQuery, ProjectCostAllocationEmployeeListingQueryVariables>(ProjectCostAllocationEmployeeListingDocument, baseOptions);
        }
export type ProjectCostAllocationEmployeeListingQueryHookResult = ReturnType<typeof useProjectCostAllocationEmployeeListingQuery>;
export type ProjectCostAllocationEmployeeListingLazyQueryHookResult = ReturnType<typeof useProjectCostAllocationEmployeeListingLazyQuery>;
export type ProjectCostAllocationEmployeeListingQueryResult = ApolloReactCommon.QueryResult<ProjectCostAllocationEmployeeListingQuery, ProjectCostAllocationEmployeeListingQueryVariables>;
export const ProjectCostAllocationDetailListingDocument = gql`
    query ProjectCostAllocationDetailListing($ProjectCostAllocationID: String!) {
  ProjectCostAllocationDetailListing(ProjectCostAllocationID: $ProjectCostAllocationID) {
    ID
    ProjectCostAllocationID
    ProjectID
    Allocation
  }
}
    `;

/**
 * __useProjectCostAllocationDetailListingQuery__
 *
 * To run a query within a React component, call `useProjectCostAllocationDetailListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCostAllocationDetailListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCostAllocationDetailListingQuery({
 *   variables: {
 *      ProjectCostAllocationID: // value for 'ProjectCostAllocationID'
 *   },
 * });
 */
export function useProjectCostAllocationDetailListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectCostAllocationDetailListingQuery, ProjectCostAllocationDetailListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectCostAllocationDetailListingQuery, ProjectCostAllocationDetailListingQueryVariables>(ProjectCostAllocationDetailListingDocument, baseOptions);
      }
export function useProjectCostAllocationDetailListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectCostAllocationDetailListingQuery, ProjectCostAllocationDetailListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectCostAllocationDetailListingQuery, ProjectCostAllocationDetailListingQueryVariables>(ProjectCostAllocationDetailListingDocument, baseOptions);
        }
export type ProjectCostAllocationDetailListingQueryHookResult = ReturnType<typeof useProjectCostAllocationDetailListingQuery>;
export type ProjectCostAllocationDetailListingLazyQueryHookResult = ReturnType<typeof useProjectCostAllocationDetailListingLazyQuery>;
export type ProjectCostAllocationDetailListingQueryResult = ApolloReactCommon.QueryResult<ProjectCostAllocationDetailListingQuery, ProjectCostAllocationDetailListingQueryVariables>;
export const ProjectCostAllocationSubmitDocument = gql`
    mutation ProjectCostAllocationSubmit($ProjectCostAllocationInput: ProjectCostAllocationInput!, $ProjectCostAllocationDetailInput: [ProjectCostAllocationDetailInput!]!) {
  ProjectCostAllocationSubmit(ProjectCostAllocationInput: $ProjectCostAllocationInput, ProjectCostAllocationDetailInput: $ProjectCostAllocationDetailInput)
}
    `;
export type ProjectCostAllocationSubmitMutationFn = ApolloReactCommon.MutationFunction<ProjectCostAllocationSubmitMutation, ProjectCostAllocationSubmitMutationVariables>;

/**
 * __useProjectCostAllocationSubmitMutation__
 *
 * To run a mutation, you first call `useProjectCostAllocationSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectCostAllocationSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectCostAllocationSubmitMutation, { data, loading, error }] = useProjectCostAllocationSubmitMutation({
 *   variables: {
 *      ProjectCostAllocationInput: // value for 'ProjectCostAllocationInput'
 *      ProjectCostAllocationDetailInput: // value for 'ProjectCostAllocationDetailInput'
 *   },
 * });
 */
export function useProjectCostAllocationSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectCostAllocationSubmitMutation, ProjectCostAllocationSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectCostAllocationSubmitMutation, ProjectCostAllocationSubmitMutationVariables>(ProjectCostAllocationSubmitDocument, baseOptions);
      }
export type ProjectCostAllocationSubmitMutationHookResult = ReturnType<typeof useProjectCostAllocationSubmitMutation>;
export type ProjectCostAllocationSubmitMutationResult = ApolloReactCommon.MutationResult<ProjectCostAllocationSubmitMutation>;
export type ProjectCostAllocationSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectCostAllocationSubmitMutation, ProjectCostAllocationSubmitMutationVariables>;
export const ProjectCostAllocationDeleteDocument = gql`
    mutation ProjectCostAllocationDelete($ID: String!) {
  ProjectCostAllocationDelete(ID: $ID)
}
    `;
export type ProjectCostAllocationDeleteMutationFn = ApolloReactCommon.MutationFunction<ProjectCostAllocationDeleteMutation, ProjectCostAllocationDeleteMutationVariables>;

/**
 * __useProjectCostAllocationDeleteMutation__
 *
 * To run a mutation, you first call `useProjectCostAllocationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectCostAllocationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectCostAllocationDeleteMutation, { data, loading, error }] = useProjectCostAllocationDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useProjectCostAllocationDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectCostAllocationDeleteMutation, ProjectCostAllocationDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectCostAllocationDeleteMutation, ProjectCostAllocationDeleteMutationVariables>(ProjectCostAllocationDeleteDocument, baseOptions);
      }
export type ProjectCostAllocationDeleteMutationHookResult = ReturnType<typeof useProjectCostAllocationDeleteMutation>;
export type ProjectCostAllocationDeleteMutationResult = ApolloReactCommon.MutationResult<ProjectCostAllocationDeleteMutation>;
export type ProjectCostAllocationDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectCostAllocationDeleteMutation, ProjectCostAllocationDeleteMutationVariables>;
export const SubmitMyProjectClaimDocument = gql`
    mutation SubmitMyProjectClaim($input: SubmitProjectClaimRecordsInput!) {
  SubmitMyProjectClaim(input: $input) {
    ID
    EmployeeID
    CompanyID
    ApprovalStatus
  }
}
    `;
export type SubmitMyProjectClaimMutationFn = ApolloReactCommon.MutationFunction<SubmitMyProjectClaimMutation, SubmitMyProjectClaimMutationVariables>;

/**
 * __useSubmitMyProjectClaimMutation__
 *
 * To run a mutation, you first call `useSubmitMyProjectClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitMyProjectClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitMyProjectClaimMutation, { data, loading, error }] = useSubmitMyProjectClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitMyProjectClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitMyProjectClaimMutation, SubmitMyProjectClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitMyProjectClaimMutation, SubmitMyProjectClaimMutationVariables>(SubmitMyProjectClaimDocument, baseOptions);
      }
export type SubmitMyProjectClaimMutationHookResult = ReturnType<typeof useSubmitMyProjectClaimMutation>;
export type SubmitMyProjectClaimMutationResult = ApolloReactCommon.MutationResult<SubmitMyProjectClaimMutation>;
export type SubmitMyProjectClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitMyProjectClaimMutation, SubmitMyProjectClaimMutationVariables>;
export const ApproveProjectExpenseClaimRecordsDocument = gql`
    mutation ApproveProjectExpenseClaimRecords($input: ApproveProjectExpenseClaimRecordsInput!, $IsSupersede: Boolean) {
  ApproveProjectExpenseClaimRecords(input: $input, IsSupersede: $IsSupersede)
}
    `;
export type ApproveProjectExpenseClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<ApproveProjectExpenseClaimRecordsMutation, ApproveProjectExpenseClaimRecordsMutationVariables>;

/**
 * __useApproveProjectExpenseClaimRecordsMutation__
 *
 * To run a mutation, you first call `useApproveProjectExpenseClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveProjectExpenseClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveProjectExpenseClaimRecordsMutation, { data, loading, error }] = useApproveProjectExpenseClaimRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      IsSupersede: // value for 'IsSupersede'
 *   },
 * });
 */
export function useApproveProjectExpenseClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveProjectExpenseClaimRecordsMutation, ApproveProjectExpenseClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveProjectExpenseClaimRecordsMutation, ApproveProjectExpenseClaimRecordsMutationVariables>(ApproveProjectExpenseClaimRecordsDocument, baseOptions);
      }
export type ApproveProjectExpenseClaimRecordsMutationHookResult = ReturnType<typeof useApproveProjectExpenseClaimRecordsMutation>;
export type ApproveProjectExpenseClaimRecordsMutationResult = ApolloReactCommon.MutationResult<ApproveProjectExpenseClaimRecordsMutation>;
export type ApproveProjectExpenseClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveProjectExpenseClaimRecordsMutation, ApproveProjectExpenseClaimRecordsMutationVariables>;
export const DeleteApprovedProjectExpenseClaimRecordsDocument = gql`
    mutation DeleteApprovedProjectExpenseClaimRecords($input: ApproveProjectExpenseClaimRecordsInput!, $Remark: String!) {
  DeleteApprovedProjectExpenseClaimRecords(input: $input, Remark: $Remark)
}
    `;
export type DeleteApprovedProjectExpenseClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovedProjectExpenseClaimRecordsMutation, DeleteApprovedProjectExpenseClaimRecordsMutationVariables>;

/**
 * __useDeleteApprovedProjectExpenseClaimRecordsMutation__
 *
 * To run a mutation, you first call `useDeleteApprovedProjectExpenseClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovedProjectExpenseClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovedProjectExpenseClaimRecordsMutation, { data, loading, error }] = useDeleteApprovedProjectExpenseClaimRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      Remark: // value for 'Remark'
 *   },
 * });
 */
export function useDeleteApprovedProjectExpenseClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovedProjectExpenseClaimRecordsMutation, DeleteApprovedProjectExpenseClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovedProjectExpenseClaimRecordsMutation, DeleteApprovedProjectExpenseClaimRecordsMutationVariables>(DeleteApprovedProjectExpenseClaimRecordsDocument, baseOptions);
      }
export type DeleteApprovedProjectExpenseClaimRecordsMutationHookResult = ReturnType<typeof useDeleteApprovedProjectExpenseClaimRecordsMutation>;
export type DeleteApprovedProjectExpenseClaimRecordsMutationResult = ApolloReactCommon.MutationResult<DeleteApprovedProjectExpenseClaimRecordsMutation>;
export type DeleteApprovedProjectExpenseClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovedProjectExpenseClaimRecordsMutation, DeleteApprovedProjectExpenseClaimRecordsMutationVariables>;
export const GetProjectExpenseClaimRecordsListingDocument = gql`
    query GetProjectExpenseClaimRecordsListing($input: ProjectExpenseClaimRecordsInput!, $superiorRejectToDraft: Boolean, $offset: Float, $limit: Float) {
  GetProjectExpenseClaimRecordsListing(input: $input, superiorRejectToDraft: $superiorRejectToDraft, offset: $offset, limit: $limit) {
    ClaimHeaderID
    ProjectID
    ProjectName
    EmployeeID
    EmployeeNo
    EmployeeName
    SubmissionDate
    Subject
    ClaimNo
    JobGradeID
    OrgUnitID
    CountClaimDetails
    TotalClaimAmount
    HeaderStatus
    DetailStatus
    CountVerifiedClaim
    TotalVerifiedClaimAmount
    CountClaimHistory
    ApprovalDate
    CompletedVerificationDate
    CompletedVerificationBy
    ApprovalName
    SubmitterName
    Remark
    ApprovalStatus
    ApproverID
    IsRejected
    GLClaimRecordsDetail {
      ID
      ProjectExpenseClaimHeaderID
      ProjectExpenseTypeID
      ProjectExpenseType
      ClaimAmount
      ReceiptDate
      ReceiptNo
      ClaimRemarks
      VerifiedAmount
      VerifiedRemark
      FinanceApproveDate
      FinanceApproveBy
      FinanceRemarks
      FinanceApproveStatus
      ClaimNo
      PostedBy
      PostedDate
    }
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        NotifiedDT
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
    DocumentIDs {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      DocumentID
      DocumentType
      Description
      DocumentFile
      PayPeriodID
      ParentID
      BucketFileName
      FileSize
    }
  }
}
    `;

/**
 * __useGetProjectExpenseClaimRecordsListingQuery__
 *
 * To run a query within a React component, call `useGetProjectExpenseClaimRecordsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectExpenseClaimRecordsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectExpenseClaimRecordsListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      superiorRejectToDraft: // value for 'superiorRejectToDraft'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProjectExpenseClaimRecordsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectExpenseClaimRecordsListingQuery, GetProjectExpenseClaimRecordsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectExpenseClaimRecordsListingQuery, GetProjectExpenseClaimRecordsListingQueryVariables>(GetProjectExpenseClaimRecordsListingDocument, baseOptions);
      }
export function useGetProjectExpenseClaimRecordsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectExpenseClaimRecordsListingQuery, GetProjectExpenseClaimRecordsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectExpenseClaimRecordsListingQuery, GetProjectExpenseClaimRecordsListingQueryVariables>(GetProjectExpenseClaimRecordsListingDocument, baseOptions);
        }
export type GetProjectExpenseClaimRecordsListingQueryHookResult = ReturnType<typeof useGetProjectExpenseClaimRecordsListingQuery>;
export type GetProjectExpenseClaimRecordsListingLazyQueryHookResult = ReturnType<typeof useGetProjectExpenseClaimRecordsListingLazyQuery>;
export type GetProjectExpenseClaimRecordsListingQueryResult = ApolloReactCommon.QueryResult<GetProjectExpenseClaimRecordsListingQuery, GetProjectExpenseClaimRecordsListingQueryVariables>;
export const GetApprovedProjectExpenseClaimVerificationListingDocument = gql`
    query GetApprovedProjectExpenseClaimVerificationListing($input: ApprovalProjectExpenseClaimVerificationInput!, $offset: Float, $limit: Float, $Incomplete: Boolean) {
  GetApprovedProjectExpenseClaimVerificationListing(input: $input, offset: $offset, limit: $limit, Incomplete: $Incomplete) {
    ProjectID
    ProjectName
    ClaimHeaderID
    ClaimNo
    JobGradeID
    OrgUnitID
    FormStatus
    CreatedBy
    EmployeeID
    EmployeeName
    EmployeeNo
    SubmissionDate
    Subject
    CountClaimDetails
    TotalClaimAmount
    TotalVerifiedClaimAmount
    CountClaimHistory
    HeaderStatus
    DetailStatus
    ApprovalStatus
    ApprovalDate
    ApprovalName
    DocumentIDs {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      DocumentID
      DocumentType
      Description
      DocumentFile
      PayPeriodID
      ParentID
      BucketFileName
      FileSize
    }
    FinanceApprovalDate
    FinanceApprovalName
    CompletedVerificationBy
    CompletedVerificationDate
    Remark
    PostedBy
    ApproverID
    SubmitterName
    CountVerifiedClaim
    MyClaimDetails {
      ClaimDetailID
      ProjectExpenseType
      ProjectExpenseTypeID
      Remark
      ReceiptNo
      ReceiptDate
      ClaimAmount
      ClaimBalance
      FinanceApproveDate
      FinanceApproveBy
      FinanceRemarks
      FinanceApproveStatus
    }
    History {
      ClaimHeaderID
      EmployeeID
      EmployeeNo
      EmployeeName
      SubmissionDate
      Subject
      CountClaimDetails
      TotalClaimAmount
      HeaderStatus
      DetailStatus
      CountVerifiedClaim
      TotalVerifiedClaimAmount
      CountClaimHistory
      ApprovalDate
      ApprovalName
      ApprovalStatus
      CompletedVerificationDate
      CompletedVerificationBy
      Remark
      ClaimNo
    }
    GLClaimRecordsDetail {
      ID
      ProjectExpenseClaimHeaderID
      ProjectExpenseTypeID
      ProjectExpenseType
      ClaimAmount
      ReceiptDate
      ReceiptNo
      ClaimRemarks
      VerifiedAmount
      VerifiedRemark
      FinanceApproveDate
      FinanceApproveBy
      FinanceRemarks
      FinanceApproveStatus
      ClaimNo
      PostedBy
      PostedDate
    }
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        NotifiedDT
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetApprovedProjectExpenseClaimVerificationListingQuery__
 *
 * To run a query within a React component, call `useGetApprovedProjectExpenseClaimVerificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovedProjectExpenseClaimVerificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovedProjectExpenseClaimVerificationListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      Incomplete: // value for 'Incomplete'
 *   },
 * });
 */
export function useGetApprovedProjectExpenseClaimVerificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovedProjectExpenseClaimVerificationListingQuery, GetApprovedProjectExpenseClaimVerificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovedProjectExpenseClaimVerificationListingQuery, GetApprovedProjectExpenseClaimVerificationListingQueryVariables>(GetApprovedProjectExpenseClaimVerificationListingDocument, baseOptions);
      }
export function useGetApprovedProjectExpenseClaimVerificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovedProjectExpenseClaimVerificationListingQuery, GetApprovedProjectExpenseClaimVerificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovedProjectExpenseClaimVerificationListingQuery, GetApprovedProjectExpenseClaimVerificationListingQueryVariables>(GetApprovedProjectExpenseClaimVerificationListingDocument, baseOptions);
        }
export type GetApprovedProjectExpenseClaimVerificationListingQueryHookResult = ReturnType<typeof useGetApprovedProjectExpenseClaimVerificationListingQuery>;
export type GetApprovedProjectExpenseClaimVerificationListingLazyQueryHookResult = ReturnType<typeof useGetApprovedProjectExpenseClaimVerificationListingLazyQuery>;
export type GetApprovedProjectExpenseClaimVerificationListingQueryResult = ApolloReactCommon.QueryResult<GetApprovedProjectExpenseClaimVerificationListingQuery, GetApprovedProjectExpenseClaimVerificationListingQueryVariables>;
export const GetPeApprovedClaimCountDocument = gql`
    query GetPEApprovedClaimCount($input: ApprovalProjectExpenseClaimVerificationInput!) {
  GetPEApprovedClaimCount(input: $input)
}
    `;

/**
 * __useGetPeApprovedClaimCountQuery__
 *
 * To run a query within a React component, call `useGetPeApprovedClaimCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeApprovedClaimCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeApprovedClaimCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPeApprovedClaimCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPeApprovedClaimCountQuery, GetPeApprovedClaimCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPeApprovedClaimCountQuery, GetPeApprovedClaimCountQueryVariables>(GetPeApprovedClaimCountDocument, baseOptions);
      }
export function useGetPeApprovedClaimCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPeApprovedClaimCountQuery, GetPeApprovedClaimCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPeApprovedClaimCountQuery, GetPeApprovedClaimCountQueryVariables>(GetPeApprovedClaimCountDocument, baseOptions);
        }
export type GetPeApprovedClaimCountQueryHookResult = ReturnType<typeof useGetPeApprovedClaimCountQuery>;
export type GetPeApprovedClaimCountLazyQueryHookResult = ReturnType<typeof useGetPeApprovedClaimCountLazyQuery>;
export type GetPeApprovedClaimCountQueryResult = ApolloReactCommon.QueryResult<GetPeApprovedClaimCountQuery, GetPeApprovedClaimCountQueryVariables>;
export const VerifyApprovedProjectExpenseClaimDetailDocument = gql`
    mutation VerifyApprovedProjectExpenseClaimDetail($input: VerifyApprovedProjectExpenseClaimDetailInput!) {
  VerifyApprovedProjectExpenseClaimDetail(input: $input)
}
    `;
export type VerifyApprovedProjectExpenseClaimDetailMutationFn = ApolloReactCommon.MutationFunction<VerifyApprovedProjectExpenseClaimDetailMutation, VerifyApprovedProjectExpenseClaimDetailMutationVariables>;

/**
 * __useVerifyApprovedProjectExpenseClaimDetailMutation__
 *
 * To run a mutation, you first call `useVerifyApprovedProjectExpenseClaimDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyApprovedProjectExpenseClaimDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyApprovedProjectExpenseClaimDetailMutation, { data, loading, error }] = useVerifyApprovedProjectExpenseClaimDetailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyApprovedProjectExpenseClaimDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyApprovedProjectExpenseClaimDetailMutation, VerifyApprovedProjectExpenseClaimDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyApprovedProjectExpenseClaimDetailMutation, VerifyApprovedProjectExpenseClaimDetailMutationVariables>(VerifyApprovedProjectExpenseClaimDetailDocument, baseOptions);
      }
export type VerifyApprovedProjectExpenseClaimDetailMutationHookResult = ReturnType<typeof useVerifyApprovedProjectExpenseClaimDetailMutation>;
export type VerifyApprovedProjectExpenseClaimDetailMutationResult = ApolloReactCommon.MutationResult<VerifyApprovedProjectExpenseClaimDetailMutation>;
export type VerifyApprovedProjectExpenseClaimDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyApprovedProjectExpenseClaimDetailMutation, VerifyApprovedProjectExpenseClaimDetailMutationVariables>;
export const ReverseApprovedProjectExpenseClaimRecordsDocument = gql`
    mutation ReverseApprovedProjectExpenseClaimRecords($input: ApproveProjectExpenseClaimRecordsInput!) {
  ReverseApprovedProjectExpenseClaimRecords(input: $input)
}
    `;
export type ReverseApprovedProjectExpenseClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<ReverseApprovedProjectExpenseClaimRecordsMutation, ReverseApprovedProjectExpenseClaimRecordsMutationVariables>;

/**
 * __useReverseApprovedProjectExpenseClaimRecordsMutation__
 *
 * To run a mutation, you first call `useReverseApprovedProjectExpenseClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverseApprovedProjectExpenseClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverseApprovedProjectExpenseClaimRecordsMutation, { data, loading, error }] = useReverseApprovedProjectExpenseClaimRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReverseApprovedProjectExpenseClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReverseApprovedProjectExpenseClaimRecordsMutation, ReverseApprovedProjectExpenseClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<ReverseApprovedProjectExpenseClaimRecordsMutation, ReverseApprovedProjectExpenseClaimRecordsMutationVariables>(ReverseApprovedProjectExpenseClaimRecordsDocument, baseOptions);
      }
export type ReverseApprovedProjectExpenseClaimRecordsMutationHookResult = ReturnType<typeof useReverseApprovedProjectExpenseClaimRecordsMutation>;
export type ReverseApprovedProjectExpenseClaimRecordsMutationResult = ApolloReactCommon.MutationResult<ReverseApprovedProjectExpenseClaimRecordsMutation>;
export type ReverseApprovedProjectExpenseClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReverseApprovedProjectExpenseClaimRecordsMutation, ReverseApprovedProjectExpenseClaimRecordsMutationVariables>;
export const GetPeClaimRecordsDetailsDocument = gql`
    query GetPEClaimRecordsDetails($ClaimHeaderID: String!) {
  GetPEClaimRecordsDetails(ClaimHeaderID: $ClaimHeaderID) {
    EmployeeID
    EmployeeName
    EmployeeNo
    ClaimNo
    SubmissionDate
    Subject
    CountClaimDetails
    TotalClaimAmount
    ApprovalStatus
    Project {
      ID
      ProjectName
      CostCenter
      CostCenterDescription
      ValidUntil
      ProjectOwnerID
      SubscriptionAccountID
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      ProjectCostAllocationDetail {
        ID
      }
      ProjectExpenseClaimHeader {
        ID
      }
    }
    ClaimRecordsDetail {
      ID
      ProjectExpenseTypeID
      ProjectExpenseType
      ClaimAmount
      VerifiedAmount
      FinanceApproveStatus
      FinanceRemarks
      FinanceApproveDate
      FinanceApproveBy
      ReceiptDate
      ReceiptNo
      DocumentIDs {
        Status
        CreatedDT
        CreatedBy
        ModifiedDT
        ModifiedBy
        DocumentID
        DocumentType
        Description
        DocumentFile
        PayPeriodID
        ParentID
        BucketFileName
        FileSize
      }
      Document {
        Status
        CreatedDT
        CreatedBy
        ModifiedDT
        ModifiedBy
        DocumentID
        DocumentType
        Description
        DocumentFile
        PayPeriodID
        ParentID
        BucketFileName
        FileSize
      }
      ClaimRemarks
    }
  }
}
    `;

/**
 * __useGetPeClaimRecordsDetailsQuery__
 *
 * To run a query within a React component, call `useGetPeClaimRecordsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeClaimRecordsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeClaimRecordsDetailsQuery({
 *   variables: {
 *      ClaimHeaderID: // value for 'ClaimHeaderID'
 *   },
 * });
 */
export function useGetPeClaimRecordsDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPeClaimRecordsDetailsQuery, GetPeClaimRecordsDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPeClaimRecordsDetailsQuery, GetPeClaimRecordsDetailsQueryVariables>(GetPeClaimRecordsDetailsDocument, baseOptions);
      }
export function useGetPeClaimRecordsDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPeClaimRecordsDetailsQuery, GetPeClaimRecordsDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPeClaimRecordsDetailsQuery, GetPeClaimRecordsDetailsQueryVariables>(GetPeClaimRecordsDetailsDocument, baseOptions);
        }
export type GetPeClaimRecordsDetailsQueryHookResult = ReturnType<typeof useGetPeClaimRecordsDetailsQuery>;
export type GetPeClaimRecordsDetailsLazyQueryHookResult = ReturnType<typeof useGetPeClaimRecordsDetailsLazyQuery>;
export type GetPeClaimRecordsDetailsQueryResult = ApolloReactCommon.QueryResult<GetPeClaimRecordsDetailsQuery, GetPeClaimRecordsDetailsQueryVariables>;
export const VerifyMyPeClaimDocument = gql`
    query VerifyMyPEClaim($VerfiyPEClaimInput: VerfiyPEClaimInput!) {
  VerifyMyPEClaim(VerfiyPEClaimInput: $VerfiyPEClaimInput)
}
    `;

/**
 * __useVerifyMyPeClaimQuery__
 *
 * To run a query within a React component, call `useVerifyMyPeClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyMyPeClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyMyPeClaimQuery({
 *   variables: {
 *      VerfiyPEClaimInput: // value for 'VerfiyPEClaimInput'
 *   },
 * });
 */
export function useVerifyMyPeClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyMyPeClaimQuery, VerifyMyPeClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyMyPeClaimQuery, VerifyMyPeClaimQueryVariables>(VerifyMyPeClaimDocument, baseOptions);
      }
export function useVerifyMyPeClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyMyPeClaimQuery, VerifyMyPeClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyMyPeClaimQuery, VerifyMyPeClaimQueryVariables>(VerifyMyPeClaimDocument, baseOptions);
        }
export type VerifyMyPeClaimQueryHookResult = ReturnType<typeof useVerifyMyPeClaimQuery>;
export type VerifyMyPeClaimLazyQueryHookResult = ReturnType<typeof useVerifyMyPeClaimLazyQuery>;
export type VerifyMyPeClaimQueryResult = ApolloReactCommon.QueryResult<VerifyMyPeClaimQuery, VerifyMyPeClaimQueryVariables>;
export const ProjecExpenseSummaryDocument = gql`
    query ProjecExpenseSummary($CompanyID: String!, $SubscriptionAccountID: String!) {
  ProjecExpenseSummary(CompanyID: $CompanyID, SubscriptionAccountID: $SubscriptionAccountID)
}
    `;

/**
 * __useProjecExpenseSummaryQuery__
 *
 * To run a query within a React component, call `useProjecExpenseSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjecExpenseSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjecExpenseSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useProjecExpenseSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjecExpenseSummaryQuery, ProjecExpenseSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjecExpenseSummaryQuery, ProjecExpenseSummaryQueryVariables>(ProjecExpenseSummaryDocument, baseOptions);
      }
export function useProjecExpenseSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjecExpenseSummaryQuery, ProjecExpenseSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjecExpenseSummaryQuery, ProjecExpenseSummaryQueryVariables>(ProjecExpenseSummaryDocument, baseOptions);
        }
export type ProjecExpenseSummaryQueryHookResult = ReturnType<typeof useProjecExpenseSummaryQuery>;
export type ProjecExpenseSummaryLazyQueryHookResult = ReturnType<typeof useProjecExpenseSummaryLazyQuery>;
export type ProjecExpenseSummaryQueryResult = ApolloReactCommon.QueryResult<ProjecExpenseSummaryQuery, ProjecExpenseSummaryQueryVariables>;
export const PostPeClaimRecordsDocument = gql`
    mutation PostPEClaimRecords($input: PostPEClaimRecordsInput!, $PaymentMethod: String!) {
  PostPEClaimRecords(input: $input, PaymentMethod: $PaymentMethod)
}
    `;
export type PostPeClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<PostPeClaimRecordsMutation, PostPeClaimRecordsMutationVariables>;

/**
 * __usePostPeClaimRecordsMutation__
 *
 * To run a mutation, you first call `usePostPeClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostPeClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postPeClaimRecordsMutation, { data, loading, error }] = usePostPeClaimRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function usePostPeClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostPeClaimRecordsMutation, PostPeClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<PostPeClaimRecordsMutation, PostPeClaimRecordsMutationVariables>(PostPeClaimRecordsDocument, baseOptions);
      }
export type PostPeClaimRecordsMutationHookResult = ReturnType<typeof usePostPeClaimRecordsMutation>;
export type PostPeClaimRecordsMutationResult = ApolloReactCommon.MutationResult<PostPeClaimRecordsMutation>;
export type PostPeClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<PostPeClaimRecordsMutation, PostPeClaimRecordsMutationVariables>;
export const ReversePeClaimRecordsDocument = gql`
    mutation ReversePEClaimRecords($SubscriptionUserID: String!, $ClaimHeaderIDs: [String!]!, $PaymentMethod: String!) {
  ReversePEClaimRecords(SubscriptionUserID: $SubscriptionUserID, ClaimHeaderIDs: $ClaimHeaderIDs, PaymentMethod: $PaymentMethod)
}
    `;
export type ReversePeClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<ReversePeClaimRecordsMutation, ReversePeClaimRecordsMutationVariables>;

/**
 * __useReversePeClaimRecordsMutation__
 *
 * To run a mutation, you first call `useReversePeClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReversePeClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reversePeClaimRecordsMutation, { data, loading, error }] = useReversePeClaimRecordsMutation({
 *   variables: {
 *      SubscriptionUserID: // value for 'SubscriptionUserID'
 *      ClaimHeaderIDs: // value for 'ClaimHeaderIDs'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function useReversePeClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReversePeClaimRecordsMutation, ReversePeClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<ReversePeClaimRecordsMutation, ReversePeClaimRecordsMutationVariables>(ReversePeClaimRecordsDocument, baseOptions);
      }
export type ReversePeClaimRecordsMutationHookResult = ReturnType<typeof useReversePeClaimRecordsMutation>;
export type ReversePeClaimRecordsMutationResult = ApolloReactCommon.MutationResult<ReversePeClaimRecordsMutation>;
export type ReversePeClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReversePeClaimRecordsMutation, ReversePeClaimRecordsMutationVariables>;
export const GetPeClaimPostingPeriodListingV2Document = gql`
    query GetPEClaimPostingPeriodListingV2($input: PEClaimPostingPeriodListingInput!, $PaymentMethod: String) {
  GetPEClaimPostingPeriodListingV2(input: $input, PaymentMethod: $PaymentMethod) {
    PeriodYearMonth
    FromDate
    ToDate
    ListingStatus
    LatestPeriod
    ClaimPostingPeriodStatusList {
      ClaimPostingPeriodStatus
      CountClaimDetails
      TotalClaimAmount
    }
  }
}
    `;

/**
 * __useGetPeClaimPostingPeriodListingV2Query__
 *
 * To run a query within a React component, call `useGetPeClaimPostingPeriodListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetPeClaimPostingPeriodListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeClaimPostingPeriodListingV2Query({
 *   variables: {
 *      input: // value for 'input'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function useGetPeClaimPostingPeriodListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPeClaimPostingPeriodListingV2Query, GetPeClaimPostingPeriodListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<GetPeClaimPostingPeriodListingV2Query, GetPeClaimPostingPeriodListingV2QueryVariables>(GetPeClaimPostingPeriodListingV2Document, baseOptions);
      }
export function useGetPeClaimPostingPeriodListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPeClaimPostingPeriodListingV2Query, GetPeClaimPostingPeriodListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPeClaimPostingPeriodListingV2Query, GetPeClaimPostingPeriodListingV2QueryVariables>(GetPeClaimPostingPeriodListingV2Document, baseOptions);
        }
export type GetPeClaimPostingPeriodListingV2QueryHookResult = ReturnType<typeof useGetPeClaimPostingPeriodListingV2Query>;
export type GetPeClaimPostingPeriodListingV2LazyQueryHookResult = ReturnType<typeof useGetPeClaimPostingPeriodListingV2LazyQuery>;
export type GetPeClaimPostingPeriodListingV2QueryResult = ApolloReactCommon.QueryResult<GetPeClaimPostingPeriodListingV2Query, GetPeClaimPostingPeriodListingV2QueryVariables>;
export const GetPeClaimPaymentPeriodListingV2Document = gql`
    query GetPEClaimPaymentPeriodListingV2($input: PEClaimPostingPeriodListingInput!, $PaymentMethod: String) {
  GetPEClaimPostingPeriodListingV2(input: $input, PaymentMethod: $PaymentMethod) {
    PeriodYearMonth
    FromDate
    ToDate
    ListingStatus
    LatestPeriod
    ClaimPostingPeriodStatusList {
      ClaimPostingPeriodStatus
      CountClaimDetails
      TotalClaimAmount
    }
  }
}
    `;

/**
 * __useGetPeClaimPaymentPeriodListingV2Query__
 *
 * To run a query within a React component, call `useGetPeClaimPaymentPeriodListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetPeClaimPaymentPeriodListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeClaimPaymentPeriodListingV2Query({
 *   variables: {
 *      input: // value for 'input'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function useGetPeClaimPaymentPeriodListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPeClaimPaymentPeriodListingV2Query, GetPeClaimPaymentPeriodListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<GetPeClaimPaymentPeriodListingV2Query, GetPeClaimPaymentPeriodListingV2QueryVariables>(GetPeClaimPaymentPeriodListingV2Document, baseOptions);
      }
export function useGetPeClaimPaymentPeriodListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPeClaimPaymentPeriodListingV2Query, GetPeClaimPaymentPeriodListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPeClaimPaymentPeriodListingV2Query, GetPeClaimPaymentPeriodListingV2QueryVariables>(GetPeClaimPaymentPeriodListingV2Document, baseOptions);
        }
export type GetPeClaimPaymentPeriodListingV2QueryHookResult = ReturnType<typeof useGetPeClaimPaymentPeriodListingV2Query>;
export type GetPeClaimPaymentPeriodListingV2LazyQueryHookResult = ReturnType<typeof useGetPeClaimPaymentPeriodListingV2LazyQuery>;
export type GetPeClaimPaymentPeriodListingV2QueryResult = ApolloReactCommon.QueryResult<GetPeClaimPaymentPeriodListingV2Query, GetPeClaimPaymentPeriodListingV2QueryVariables>;
export const GetPeClaimPostingListingDocument = gql`
    query GetPEClaimPostingListing($input: ApprovalProjectExpenseClaimVerificationInput!, $PaymentMethod: String!) {
  GetPEClaimPostingListing(input: $input, PaymentMethod: $PaymentMethod) {
    ProjectID
    ProjectName
    JobGradeID
    OrgUnitID
    FormStatus
    ClaimHeaderID
    EmployeeID
    EmployeeName
    EmployeeNo
    Subject
    SubmissionDate
    ClaimNo
    ApprovalName
    ApprovalDate
    HeaderStatus
    CountClaimDetails
    TotalClaimAmount
    CountVerifiedClaim
    TotalVerifiedClaimAmount
    CompletedVerificationDate
    CompletedVerificationBy
    Remark
    IsOTPModified
    IsOTPDeleted
    OTPModifiedBy
    OTPDeletedBy
    MyClaimDetails {
      ClaimDetailID
      ProjectExpenseTypeID
      ProjectExpenseType
    }
    DetailStatus
    ClaimPostedListing {
      PayPeriodID
      PayrollMonth
      PayrollCycleID
      PayrollCycleName
      PostedDate
      PostedBy
      Reference1
      Reference2
    }
  }
}
    `;

/**
 * __useGetPeClaimPostingListingQuery__
 *
 * To run a query within a React component, call `useGetPeClaimPostingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeClaimPostingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeClaimPostingListingQuery({
 *   variables: {
 *      input: // value for 'input'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function useGetPeClaimPostingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPeClaimPostingListingQuery, GetPeClaimPostingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPeClaimPostingListingQuery, GetPeClaimPostingListingQueryVariables>(GetPeClaimPostingListingDocument, baseOptions);
      }
export function useGetPeClaimPostingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPeClaimPostingListingQuery, GetPeClaimPostingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPeClaimPostingListingQuery, GetPeClaimPostingListingQueryVariables>(GetPeClaimPostingListingDocument, baseOptions);
        }
export type GetPeClaimPostingListingQueryHookResult = ReturnType<typeof useGetPeClaimPostingListingQuery>;
export type GetPeClaimPostingListingLazyQueryHookResult = ReturnType<typeof useGetPeClaimPostingListingLazyQuery>;
export type GetPeClaimPostingListingQueryResult = ApolloReactCommon.QueryResult<GetPeClaimPostingListingQuery, GetPeClaimPostingListingQueryVariables>;
export const GetPeClaimPostingDetailDocument = gql`
    query GetPEClaimPostingDetail($ClaimHeaderID: String!, $PaymentMethod: String!) {
  GetPEClaimPostingDetail(ClaimHeaderID: $ClaimHeaderID, PaymentMethod: $PaymentMethod) {
    ProjectID
    ProjectName
    EmployeeID
    EmployeeNo
    ClaimNo
    EmployeeName
    DepartmentName
    ClaimHeaderID
    SubmissionDate
    Subject
    CountClaimDetails
    TotalClaimAmount
    PostingStatus
    ApprovalDate
    ApprovalName
    CompletedVerificationDate
    CompletedVerificationBy
    ClaimRecordsDetail {
      ID
      ProjectExpenseTypeID
      ProjectExpenseType
      ReceiptNo
      ReceiptDate
      ClaimRemarks
      ClaimAmount
      VerifiedAmount
      FinanceApproveDate
      FinanceApproveBy
      FinanceRemarks
      FinanceApproveStatus
      PostedBy
      PostedDate
      DocumentIDs {
        Status
        CreatedDT
        CreatedBy
        ModifiedDT
        ModifiedBy
        DocumentID
        DocumentType
        Description
        DocumentFile
        PayPeriodID
        ParentID
        BucketFileName
        FileSize
      }
    }
  }
}
    `;

/**
 * __useGetPeClaimPostingDetailQuery__
 *
 * To run a query within a React component, call `useGetPeClaimPostingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeClaimPostingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeClaimPostingDetailQuery({
 *   variables: {
 *      ClaimHeaderID: // value for 'ClaimHeaderID'
 *      PaymentMethod: // value for 'PaymentMethod'
 *   },
 * });
 */
export function useGetPeClaimPostingDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPeClaimPostingDetailQuery, GetPeClaimPostingDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPeClaimPostingDetailQuery, GetPeClaimPostingDetailQueryVariables>(GetPeClaimPostingDetailDocument, baseOptions);
      }
export function useGetPeClaimPostingDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPeClaimPostingDetailQuery, GetPeClaimPostingDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPeClaimPostingDetailQuery, GetPeClaimPostingDetailQueryVariables>(GetPeClaimPostingDetailDocument, baseOptions);
        }
export type GetPeClaimPostingDetailQueryHookResult = ReturnType<typeof useGetPeClaimPostingDetailQuery>;
export type GetPeClaimPostingDetailLazyQueryHookResult = ReturnType<typeof useGetPeClaimPostingDetailLazyQuery>;
export type GetPeClaimPostingDetailQueryResult = ApolloReactCommon.QueryResult<GetPeClaimPostingDetailQuery, GetPeClaimPostingDetailQueryVariables>;
export const DeleteProjectExpenseClaimRecordsDocument = gql`
    mutation DeleteProjectExpenseClaimRecords($SubscriptionUserID: String!, $ProjectExpenseClaimHeaderIDs: [String!]!) {
  DeleteProjectExpenseClaimRecords(SubscriptionUserID: $SubscriptionUserID, ProjectExpenseClaimHeaderIDs: $ProjectExpenseClaimHeaderIDs)
}
    `;
export type DeleteProjectExpenseClaimRecordsMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectExpenseClaimRecordsMutation, DeleteProjectExpenseClaimRecordsMutationVariables>;

/**
 * __useDeleteProjectExpenseClaimRecordsMutation__
 *
 * To run a mutation, you first call `useDeleteProjectExpenseClaimRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectExpenseClaimRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectExpenseClaimRecordsMutation, { data, loading, error }] = useDeleteProjectExpenseClaimRecordsMutation({
 *   variables: {
 *      SubscriptionUserID: // value for 'SubscriptionUserID'
 *      ProjectExpenseClaimHeaderIDs: // value for 'ProjectExpenseClaimHeaderIDs'
 *   },
 * });
 */
export function useDeleteProjectExpenseClaimRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectExpenseClaimRecordsMutation, DeleteProjectExpenseClaimRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectExpenseClaimRecordsMutation, DeleteProjectExpenseClaimRecordsMutationVariables>(DeleteProjectExpenseClaimRecordsDocument, baseOptions);
      }
export type DeleteProjectExpenseClaimRecordsMutationHookResult = ReturnType<typeof useDeleteProjectExpenseClaimRecordsMutation>;
export type DeleteProjectExpenseClaimRecordsMutationResult = ApolloReactCommon.MutationResult<DeleteProjectExpenseClaimRecordsMutation>;
export type DeleteProjectExpenseClaimRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectExpenseClaimRecordsMutation, DeleteProjectExpenseClaimRecordsMutationVariables>;
export const SubmitProjectExpenseClaimForApprovalDocument = gql`
    mutation SubmitProjectExpenseClaimForApproval($ProjectExpenseClaimHeaderIDs: [String!]!) {
  SubmitProjectExpenseClaimForApproval(ProjectExpenseClaimHeaderIDs: $ProjectExpenseClaimHeaderIDs)
}
    `;
export type SubmitProjectExpenseClaimForApprovalMutationFn = ApolloReactCommon.MutationFunction<SubmitProjectExpenseClaimForApprovalMutation, SubmitProjectExpenseClaimForApprovalMutationVariables>;

/**
 * __useSubmitProjectExpenseClaimForApprovalMutation__
 *
 * To run a mutation, you first call `useSubmitProjectExpenseClaimForApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitProjectExpenseClaimForApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitProjectExpenseClaimForApprovalMutation, { data, loading, error }] = useSubmitProjectExpenseClaimForApprovalMutation({
 *   variables: {
 *      ProjectExpenseClaimHeaderIDs: // value for 'ProjectExpenseClaimHeaderIDs'
 *   },
 * });
 */
export function useSubmitProjectExpenseClaimForApprovalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitProjectExpenseClaimForApprovalMutation, SubmitProjectExpenseClaimForApprovalMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitProjectExpenseClaimForApprovalMutation, SubmitProjectExpenseClaimForApprovalMutationVariables>(SubmitProjectExpenseClaimForApprovalDocument, baseOptions);
      }
export type SubmitProjectExpenseClaimForApprovalMutationHookResult = ReturnType<typeof useSubmitProjectExpenseClaimForApprovalMutation>;
export type SubmitProjectExpenseClaimForApprovalMutationResult = ApolloReactCommon.MutationResult<SubmitProjectExpenseClaimForApprovalMutation>;
export type SubmitProjectExpenseClaimForApprovalMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitProjectExpenseClaimForApprovalMutation, SubmitProjectExpenseClaimForApprovalMutationVariables>;
export const GetApprovedProjectExpenseClaimVerificationDetailsDocument = gql`
    query GetApprovedProjectExpenseClaimVerificationDetails($ClaimHeaderID: String!) {
  GetApprovedProjectExpenseClaimVerificationDetails(ClaimHeaderID: $ClaimHeaderID) {
    Subject
    ClaimNo
    ClaimHeaderID
    JobAccountID
    JobAccountName
    HeaderStatus
    EmployeeID
    EmployeeNo
    EmployeeName
    CountClaimDetails
    CountVerifiedClaim
    TotalClaimAmount
    SubmissionDate
    ApprovedClaimDetail {
      ApprovalStatus
      ApprovalRemark
      ApprovalByID
      ApprovalByName
      ApprovalDate
    }
    MyClaimDetails {
      ClaimDetailID
      ProjectExpenseTypeID
      ProjectExpenseType
      ClaimAmount
      ClaimBalance
      ReceiptDate
      ReceiptNo
      DocumentID
      DocumentName
      Document {
        DocumentID
        DocumentFile
      }
      DocumentIDs {
        Status
        CreatedDT
        CreatedBy
        ModifiedDT
        ModifiedBy
        DocumentID
        DocumentType
        Description
        DocumentFile
        PayPeriodID
        ParentID
        BucketFileName
        FileSize
      }
      Remark
      VerifiedClaimDetail {
        FinanceApprovalStatus
        FinanceAmount
        FinanceRemark
        FinanceApprovalByID
        FinanceApprovalByName
        FinanceApprovalDate
      }
      ClaimDuplicateList {
        EmployeeName
        EmployeeNo
        CompanyName
        ReceiptDate
        Remarks
        DocFile
      }
    }
  }
}
    `;

/**
 * __useGetApprovedProjectExpenseClaimVerificationDetailsQuery__
 *
 * To run a query within a React component, call `useGetApprovedProjectExpenseClaimVerificationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovedProjectExpenseClaimVerificationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovedProjectExpenseClaimVerificationDetailsQuery({
 *   variables: {
 *      ClaimHeaderID: // value for 'ClaimHeaderID'
 *   },
 * });
 */
export function useGetApprovedProjectExpenseClaimVerificationDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovedProjectExpenseClaimVerificationDetailsQuery, GetApprovedProjectExpenseClaimVerificationDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovedProjectExpenseClaimVerificationDetailsQuery, GetApprovedProjectExpenseClaimVerificationDetailsQueryVariables>(GetApprovedProjectExpenseClaimVerificationDetailsDocument, baseOptions);
      }
export function useGetApprovedProjectExpenseClaimVerificationDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovedProjectExpenseClaimVerificationDetailsQuery, GetApprovedProjectExpenseClaimVerificationDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovedProjectExpenseClaimVerificationDetailsQuery, GetApprovedProjectExpenseClaimVerificationDetailsQueryVariables>(GetApprovedProjectExpenseClaimVerificationDetailsDocument, baseOptions);
        }
export type GetApprovedProjectExpenseClaimVerificationDetailsQueryHookResult = ReturnType<typeof useGetApprovedProjectExpenseClaimVerificationDetailsQuery>;
export type GetApprovedProjectExpenseClaimVerificationDetailsLazyQueryHookResult = ReturnType<typeof useGetApprovedProjectExpenseClaimVerificationDetailsLazyQuery>;
export type GetApprovedProjectExpenseClaimVerificationDetailsQueryResult = ApolloReactCommon.QueryResult<GetApprovedProjectExpenseClaimVerificationDetailsQuery, GetApprovedProjectExpenseClaimVerificationDetailsQueryVariables>;
export const ProjectGlAccountHeaderListWithDetailsDocument = gql`
    query ProjectGLAccountHeaderListWithDetails($ProjectGLAccountHeaderID: String, $PeriodID: String, $FromDate: DateTime, $ToDate: DateTime, $CompanyID: String, $Source: String) {
  ProjectGLAccountHeaderListWithDetails(ProjectGLAccountHeaderID: $ProjectGLAccountHeaderID, PeriodID: $PeriodID, FromDate: $FromDate, ToDate: $ToDate, CompanyID: $CompanyID, Source: $Source)
}
    `;

/**
 * __useProjectGlAccountHeaderListWithDetailsQuery__
 *
 * To run a query within a React component, call `useProjectGlAccountHeaderListWithDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectGlAccountHeaderListWithDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectGlAccountHeaderListWithDetailsQuery({
 *   variables: {
 *      ProjectGLAccountHeaderID: // value for 'ProjectGLAccountHeaderID'
 *      PeriodID: // value for 'PeriodID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      CompanyID: // value for 'CompanyID'
 *      Source: // value for 'Source'
 *   },
 * });
 */
export function useProjectGlAccountHeaderListWithDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectGlAccountHeaderListWithDetailsQuery, ProjectGlAccountHeaderListWithDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectGlAccountHeaderListWithDetailsQuery, ProjectGlAccountHeaderListWithDetailsQueryVariables>(ProjectGlAccountHeaderListWithDetailsDocument, baseOptions);
      }
export function useProjectGlAccountHeaderListWithDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectGlAccountHeaderListWithDetailsQuery, ProjectGlAccountHeaderListWithDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectGlAccountHeaderListWithDetailsQuery, ProjectGlAccountHeaderListWithDetailsQueryVariables>(ProjectGlAccountHeaderListWithDetailsDocument, baseOptions);
        }
export type ProjectGlAccountHeaderListWithDetailsQueryHookResult = ReturnType<typeof useProjectGlAccountHeaderListWithDetailsQuery>;
export type ProjectGlAccountHeaderListWithDetailsLazyQueryHookResult = ReturnType<typeof useProjectGlAccountHeaderListWithDetailsLazyQuery>;
export type ProjectGlAccountHeaderListWithDetailsQueryResult = ApolloReactCommon.QueryResult<ProjectGlAccountHeaderListWithDetailsQuery, ProjectGlAccountHeaderListWithDetailsQueryVariables>;
export const ProjectPayItemAmountByOrgAndPayPeriodDocument = gql`
    query ProjectPayItemAmountByOrgAndPayPeriod($PayPeriodID: String!, $CompanyID: String!, $PayrollCycleID: String, $AllPayCycles: Boolean) {
  ProjectPayItemAmountByOrgAndPayPeriod(PayPeriodID: $PayPeriodID, CompanyID: $CompanyID, PayrollCycleID: $PayrollCycleID, AllPayCycles: $AllPayCycles)
}
    `;

/**
 * __useProjectPayItemAmountByOrgAndPayPeriodQuery__
 *
 * To run a query within a React component, call `useProjectPayItemAmountByOrgAndPayPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPayItemAmountByOrgAndPayPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPayItemAmountByOrgAndPayPeriodQuery({
 *   variables: {
 *      PayPeriodID: // value for 'PayPeriodID'
 *      CompanyID: // value for 'CompanyID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      AllPayCycles: // value for 'AllPayCycles'
 *   },
 * });
 */
export function useProjectPayItemAmountByOrgAndPayPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectPayItemAmountByOrgAndPayPeriodQuery, ProjectPayItemAmountByOrgAndPayPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectPayItemAmountByOrgAndPayPeriodQuery, ProjectPayItemAmountByOrgAndPayPeriodQueryVariables>(ProjectPayItemAmountByOrgAndPayPeriodDocument, baseOptions);
      }
export function useProjectPayItemAmountByOrgAndPayPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectPayItemAmountByOrgAndPayPeriodQuery, ProjectPayItemAmountByOrgAndPayPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectPayItemAmountByOrgAndPayPeriodQuery, ProjectPayItemAmountByOrgAndPayPeriodQueryVariables>(ProjectPayItemAmountByOrgAndPayPeriodDocument, baseOptions);
        }
export type ProjectPayItemAmountByOrgAndPayPeriodQueryHookResult = ReturnType<typeof useProjectPayItemAmountByOrgAndPayPeriodQuery>;
export type ProjectPayItemAmountByOrgAndPayPeriodLazyQueryHookResult = ReturnType<typeof useProjectPayItemAmountByOrgAndPayPeriodLazyQuery>;
export type ProjectPayItemAmountByOrgAndPayPeriodQueryResult = ApolloReactCommon.QueryResult<ProjectPayItemAmountByOrgAndPayPeriodQuery, ProjectPayItemAmountByOrgAndPayPeriodQueryVariables>;
export const ProjectPayrollGlExportDocument = gql`
    query ProjectPayrollGLExport($PayPeriodID: String!, $CompanyID: String!, $PayrollCycleID: String, $Type: String) {
  ProjectPayrollGLExport(PayPeriodID: $PayPeriodID, CompanyID: $CompanyID, PayrollCycleID: $PayrollCycleID, Type: $Type)
}
    `;

/**
 * __useProjectPayrollGlExportQuery__
 *
 * To run a query within a React component, call `useProjectPayrollGlExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPayrollGlExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPayrollGlExportQuery({
 *   variables: {
 *      PayPeriodID: // value for 'PayPeriodID'
 *      CompanyID: // value for 'CompanyID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useProjectPayrollGlExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectPayrollGlExportQuery, ProjectPayrollGlExportQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectPayrollGlExportQuery, ProjectPayrollGlExportQueryVariables>(ProjectPayrollGlExportDocument, baseOptions);
      }
export function useProjectPayrollGlExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectPayrollGlExportQuery, ProjectPayrollGlExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectPayrollGlExportQuery, ProjectPayrollGlExportQueryVariables>(ProjectPayrollGlExportDocument, baseOptions);
        }
export type ProjectPayrollGlExportQueryHookResult = ReturnType<typeof useProjectPayrollGlExportQuery>;
export type ProjectPayrollGlExportLazyQueryHookResult = ReturnType<typeof useProjectPayrollGlExportLazyQuery>;
export type ProjectPayrollGlExportQueryResult = ApolloReactCommon.QueryResult<ProjectPayrollGlExportQuery, ProjectPayrollGlExportQueryVariables>;
export const CostCenterMappingListForGlExportDocument = gql`
    query CostCenterMappingListForGLExport($CompanyID: String!, $PayPeriodID: String, $PayrollCycleID: String, $AllPayCycles: Boolean) {
  CostCenterMappingListForGLExport(CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayrollCycleID: $PayrollCycleID, AllPayCycles: $AllPayCycles)
}
    `;

/**
 * __useCostCenterMappingListForGlExportQuery__
 *
 * To run a query within a React component, call `useCostCenterMappingListForGlExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCenterMappingListForGlExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCenterMappingListForGlExportQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayrollCycleID: // value for 'PayrollCycleID'
 *      AllPayCycles: // value for 'AllPayCycles'
 *   },
 * });
 */
export function useCostCenterMappingListForGlExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CostCenterMappingListForGlExportQuery, CostCenterMappingListForGlExportQueryVariables>) {
        return ApolloReactHooks.useQuery<CostCenterMappingListForGlExportQuery, CostCenterMappingListForGlExportQueryVariables>(CostCenterMappingListForGlExportDocument, baseOptions);
      }
export function useCostCenterMappingListForGlExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CostCenterMappingListForGlExportQuery, CostCenterMappingListForGlExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CostCenterMappingListForGlExportQuery, CostCenterMappingListForGlExportQueryVariables>(CostCenterMappingListForGlExportDocument, baseOptions);
        }
export type CostCenterMappingListForGlExportQueryHookResult = ReturnType<typeof useCostCenterMappingListForGlExportQuery>;
export type CostCenterMappingListForGlExportLazyQueryHookResult = ReturnType<typeof useCostCenterMappingListForGlExportLazyQuery>;
export type CostCenterMappingListForGlExportQueryResult = ApolloReactCommon.QueryResult<CostCenterMappingListForGlExportQuery, CostCenterMappingListForGlExportQueryVariables>;
export const GetProjectExpenseApprovalHistoryDocument = gql`
    query GetProjectExpenseApprovalHistory($ApproverID: String!) {
  GetProjectExpenseApprovalHistory(ApproverID: $ApproverID) {
    ApprovalDate
    ApproverName
    ApprovalStatus
    ClaimHeaderID
    CreatedDT
    EmployeeID
    EmployeeName
    EmployeeNo
    Subject
    TotalClaim
    TotalClaimAmount
    IsRejected
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        NotifiedDT
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetProjectExpenseApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useGetProjectExpenseApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectExpenseApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectExpenseApprovalHistoryQuery({
 *   variables: {
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useGetProjectExpenseApprovalHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectExpenseApprovalHistoryQuery, GetProjectExpenseApprovalHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectExpenseApprovalHistoryQuery, GetProjectExpenseApprovalHistoryQueryVariables>(GetProjectExpenseApprovalHistoryDocument, baseOptions);
      }
export function useGetProjectExpenseApprovalHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectExpenseApprovalHistoryQuery, GetProjectExpenseApprovalHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectExpenseApprovalHistoryQuery, GetProjectExpenseApprovalHistoryQueryVariables>(GetProjectExpenseApprovalHistoryDocument, baseOptions);
        }
export type GetProjectExpenseApprovalHistoryQueryHookResult = ReturnType<typeof useGetProjectExpenseApprovalHistoryQuery>;
export type GetProjectExpenseApprovalHistoryLazyQueryHookResult = ReturnType<typeof useGetProjectExpenseApprovalHistoryLazyQuery>;
export type GetProjectExpenseApprovalHistoryQueryResult = ApolloReactCommon.QueryResult<GetProjectExpenseApprovalHistoryQuery, GetProjectExpenseApprovalHistoryQueryVariables>;
export const ProjectGlClaimCycleProcessDocument = gql`
    query ProjectGLClaimCycleProcess($CompanyID: String!, $FromDate: DateTime, $ToDate: DateTime, $Type: String!) {
  ProjectGLClaimCycleProcess(CompanyID: $CompanyID, FromDate: $FromDate, ToDate: $ToDate, Type: $Type)
}
    `;

/**
 * __useProjectGlClaimCycleProcessQuery__
 *
 * To run a query within a React component, call `useProjectGlClaimCycleProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectGlClaimCycleProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectGlClaimCycleProcessQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useProjectGlClaimCycleProcessQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectGlClaimCycleProcessQuery, ProjectGlClaimCycleProcessQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectGlClaimCycleProcessQuery, ProjectGlClaimCycleProcessQueryVariables>(ProjectGlClaimCycleProcessDocument, baseOptions);
      }
export function useProjectGlClaimCycleProcessLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectGlClaimCycleProcessQuery, ProjectGlClaimCycleProcessQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectGlClaimCycleProcessQuery, ProjectGlClaimCycleProcessQueryVariables>(ProjectGlClaimCycleProcessDocument, baseOptions);
        }
export type ProjectGlClaimCycleProcessQueryHookResult = ReturnType<typeof useProjectGlClaimCycleProcessQuery>;
export type ProjectGlClaimCycleProcessLazyQueryHookResult = ReturnType<typeof useProjectGlClaimCycleProcessLazyQuery>;
export type ProjectGlClaimCycleProcessQueryResult = ApolloReactCommon.QueryResult<ProjectGlClaimCycleProcessQuery, ProjectGlClaimCycleProcessQueryVariables>;
export const GetGlClaimCycleListDocument = gql`
    query GetGLClaimCycleList($CompanyID: String!, $FromDate: DateTime, $ToDate: DateTime, $AllClaimCycles: Boolean, $Type: String!) {
  GetGLClaimCycleList(CompanyID: $CompanyID, FromDate: $FromDate, ToDate: $ToDate, AllClaimCycles: $AllClaimCycles, Type: $Type)
}
    `;

/**
 * __useGetGlClaimCycleListQuery__
 *
 * To run a query within a React component, call `useGetGlClaimCycleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlClaimCycleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlClaimCycleListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      AllClaimCycles: // value for 'AllClaimCycles'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useGetGlClaimCycleListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGlClaimCycleListQuery, GetGlClaimCycleListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGlClaimCycleListQuery, GetGlClaimCycleListQueryVariables>(GetGlClaimCycleListDocument, baseOptions);
      }
export function useGetGlClaimCycleListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGlClaimCycleListQuery, GetGlClaimCycleListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGlClaimCycleListQuery, GetGlClaimCycleListQueryVariables>(GetGlClaimCycleListDocument, baseOptions);
        }
export type GetGlClaimCycleListQueryHookResult = ReturnType<typeof useGetGlClaimCycleListQuery>;
export type GetGlClaimCycleListLazyQueryHookResult = ReturnType<typeof useGetGlClaimCycleListLazyQuery>;
export type GetGlClaimCycleListQueryResult = ApolloReactCommon.QueryResult<GetGlClaimCycleListQuery, GetGlClaimCycleListQueryVariables>;
export const DeleteClaimProcessedGlDocument = gql`
    mutation DeleteClaimProcessedGL($CompanyID: String!, $PeriodID: String!, $Type: String!) {
  DeleteClaimProcessedGL(CompanyID: $CompanyID, PeriodID: $PeriodID, Type: $Type)
}
    `;
export type DeleteClaimProcessedGlMutationFn = ApolloReactCommon.MutationFunction<DeleteClaimProcessedGlMutation, DeleteClaimProcessedGlMutationVariables>;

/**
 * __useDeleteClaimProcessedGlMutation__
 *
 * To run a mutation, you first call `useDeleteClaimProcessedGlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClaimProcessedGlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClaimProcessedGlMutation, { data, loading, error }] = useDeleteClaimProcessedGlMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PeriodID: // value for 'PeriodID'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useDeleteClaimProcessedGlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteClaimProcessedGlMutation, DeleteClaimProcessedGlMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteClaimProcessedGlMutation, DeleteClaimProcessedGlMutationVariables>(DeleteClaimProcessedGlDocument, baseOptions);
      }
export type DeleteClaimProcessedGlMutationHookResult = ReturnType<typeof useDeleteClaimProcessedGlMutation>;
export type DeleteClaimProcessedGlMutationResult = ApolloReactCommon.MutationResult<DeleteClaimProcessedGlMutation>;
export type DeleteClaimProcessedGlMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteClaimProcessedGlMutation, DeleteClaimProcessedGlMutationVariables>;
export const PeClaimCompanySettingSummaryDocument = gql`
    query PEClaimCompanySettingSummary($CompanyID: String!) {
  PEClaimCompanySettingSummary(CompanyID: $CompanyID)
}
    `;

/**
 * __usePeClaimCompanySettingSummaryQuery__
 *
 * To run a query within a React component, call `usePeClaimCompanySettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeClaimCompanySettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeClaimCompanySettingSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function usePeClaimCompanySettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PeClaimCompanySettingSummaryQuery, PeClaimCompanySettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<PeClaimCompanySettingSummaryQuery, PeClaimCompanySettingSummaryQueryVariables>(PeClaimCompanySettingSummaryDocument, baseOptions);
      }
export function usePeClaimCompanySettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PeClaimCompanySettingSummaryQuery, PeClaimCompanySettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PeClaimCompanySettingSummaryQuery, PeClaimCompanySettingSummaryQueryVariables>(PeClaimCompanySettingSummaryDocument, baseOptions);
        }
export type PeClaimCompanySettingSummaryQueryHookResult = ReturnType<typeof usePeClaimCompanySettingSummaryQuery>;
export type PeClaimCompanySettingSummaryLazyQueryHookResult = ReturnType<typeof usePeClaimCompanySettingSummaryLazyQuery>;
export type PeClaimCompanySettingSummaryQueryResult = ApolloReactCommon.QueryResult<PeClaimCompanySettingSummaryQuery, PeClaimCompanySettingSummaryQueryVariables>;
export const GetPeClaimRecordsTotalCountDocument = gql`
    query GetPEClaimRecordsTotalCount($input: ProjectExpenseClaimRecordsInput!) {
  GetPEClaimRecordsTotalCount(input: $input)
}
    `;

/**
 * __useGetPeClaimRecordsTotalCountQuery__
 *
 * To run a query within a React component, call `useGetPeClaimRecordsTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeClaimRecordsTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeClaimRecordsTotalCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPeClaimRecordsTotalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPeClaimRecordsTotalCountQuery, GetPeClaimRecordsTotalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPeClaimRecordsTotalCountQuery, GetPeClaimRecordsTotalCountQueryVariables>(GetPeClaimRecordsTotalCountDocument, baseOptions);
      }
export function useGetPeClaimRecordsTotalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPeClaimRecordsTotalCountQuery, GetPeClaimRecordsTotalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPeClaimRecordsTotalCountQuery, GetPeClaimRecordsTotalCountQueryVariables>(GetPeClaimRecordsTotalCountDocument, baseOptions);
        }
export type GetPeClaimRecordsTotalCountQueryHookResult = ReturnType<typeof useGetPeClaimRecordsTotalCountQuery>;
export type GetPeClaimRecordsTotalCountLazyQueryHookResult = ReturnType<typeof useGetPeClaimRecordsTotalCountLazyQuery>;
export type GetPeClaimRecordsTotalCountQueryResult = ApolloReactCommon.QueryResult<GetPeClaimRecordsTotalCountQuery, GetPeClaimRecordsTotalCountQueryVariables>;
export const IsProjectExpenseSubjectExistsDocument = gql`
    query IsProjectExpenseSubjectExists($Subject: String!, $input: ProjectExpenseClaimRecordsInput!, $ProjectExpenseClaimHeaderID: String) {
  IsProjectExpenseSubjectExists(Subject: $Subject, input: $input, ProjectExpenseClaimHeaderID: $ProjectExpenseClaimHeaderID)
}
    `;

/**
 * __useIsProjectExpenseSubjectExistsQuery__
 *
 * To run a query within a React component, call `useIsProjectExpenseSubjectExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsProjectExpenseSubjectExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsProjectExpenseSubjectExistsQuery({
 *   variables: {
 *      Subject: // value for 'Subject'
 *      input: // value for 'input'
 *      ProjectExpenseClaimHeaderID: // value for 'ProjectExpenseClaimHeaderID'
 *   },
 * });
 */
export function useIsProjectExpenseSubjectExistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsProjectExpenseSubjectExistsQuery, IsProjectExpenseSubjectExistsQueryVariables>) {
        return ApolloReactHooks.useQuery<IsProjectExpenseSubjectExistsQuery, IsProjectExpenseSubjectExistsQueryVariables>(IsProjectExpenseSubjectExistsDocument, baseOptions);
      }
export function useIsProjectExpenseSubjectExistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsProjectExpenseSubjectExistsQuery, IsProjectExpenseSubjectExistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsProjectExpenseSubjectExistsQuery, IsProjectExpenseSubjectExistsQueryVariables>(IsProjectExpenseSubjectExistsDocument, baseOptions);
        }
export type IsProjectExpenseSubjectExistsQueryHookResult = ReturnType<typeof useIsProjectExpenseSubjectExistsQuery>;
export type IsProjectExpenseSubjectExistsLazyQueryHookResult = ReturnType<typeof useIsProjectExpenseSubjectExistsLazyQuery>;
export type IsProjectExpenseSubjectExistsQueryResult = ApolloReactCommon.QueryResult<IsProjectExpenseSubjectExistsQuery, IsProjectExpenseSubjectExistsQueryVariables>;
export const GetApproverMasterListingDocument = gql`
    query getApproverMasterListing {
  getApproverMasterListing {
    ID
    Name
    ClientName
    Department
    EmailAddress
    LastUpdatedDate
    ConfirmationDT
  }
}
    `;

/**
 * __useGetApproverMasterListingQuery__
 *
 * To run a query within a React component, call `useGetApproverMasterListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApproverMasterListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApproverMasterListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApproverMasterListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApproverMasterListingQuery, GetApproverMasterListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApproverMasterListingQuery, GetApproverMasterListingQueryVariables>(GetApproverMasterListingDocument, baseOptions);
      }
export function useGetApproverMasterListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApproverMasterListingQuery, GetApproverMasterListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApproverMasterListingQuery, GetApproverMasterListingQueryVariables>(GetApproverMasterListingDocument, baseOptions);
        }
export type GetApproverMasterListingQueryHookResult = ReturnType<typeof useGetApproverMasterListingQuery>;
export type GetApproverMasterListingLazyQueryHookResult = ReturnType<typeof useGetApproverMasterListingLazyQuery>;
export type GetApproverMasterListingQueryResult = ApolloReactCommon.QueryResult<GetApproverMasterListingQuery, GetApproverMasterListingQueryVariables>;
export const GetApproverAssignmentListingDocument = gql`
    query getApproverAssignmentListing($ProjectTimesheetApproverMasterID: String!) {
  getApproverAssignmentListing(ProjectTimesheetApproverMasterID: $ProjectTimesheetApproverMasterID) {
    ID
    PeriodFrom
    PeriodTo
    ProjectTimesheetApproverAssignment {
      ID
      EmployeeID
    }
    ApproverFilter
    InUse
  }
}
    `;

/**
 * __useGetApproverAssignmentListingQuery__
 *
 * To run a query within a React component, call `useGetApproverAssignmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApproverAssignmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApproverAssignmentListingQuery({
 *   variables: {
 *      ProjectTimesheetApproverMasterID: // value for 'ProjectTimesheetApproverMasterID'
 *   },
 * });
 */
export function useGetApproverAssignmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApproverAssignmentListingQuery, GetApproverAssignmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApproverAssignmentListingQuery, GetApproverAssignmentListingQueryVariables>(GetApproverAssignmentListingDocument, baseOptions);
      }
export function useGetApproverAssignmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApproverAssignmentListingQuery, GetApproverAssignmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApproverAssignmentListingQuery, GetApproverAssignmentListingQueryVariables>(GetApproverAssignmentListingDocument, baseOptions);
        }
export type GetApproverAssignmentListingQueryHookResult = ReturnType<typeof useGetApproverAssignmentListingQuery>;
export type GetApproverAssignmentListingLazyQueryHookResult = ReturnType<typeof useGetApproverAssignmentListingLazyQuery>;
export type GetApproverAssignmentListingQueryResult = ApolloReactCommon.QueryResult<GetApproverAssignmentListingQuery, GetApproverAssignmentListingQueryVariables>;
export const IsApproverEmailAlreadyExistDocument = gql`
    query IsApproverEmailAlreadyExist($EmailAddress: String!, $ProjectTimesheetApproverMasterID: String) {
  IsApproverEmailAlreadyExist(EmailAddress: $EmailAddress, ProjectTimesheetApproverMasterID: $ProjectTimesheetApproverMasterID)
}
    `;

/**
 * __useIsApproverEmailAlreadyExistQuery__
 *
 * To run a query within a React component, call `useIsApproverEmailAlreadyExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsApproverEmailAlreadyExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsApproverEmailAlreadyExistQuery({
 *   variables: {
 *      EmailAddress: // value for 'EmailAddress'
 *      ProjectTimesheetApproverMasterID: // value for 'ProjectTimesheetApproverMasterID'
 *   },
 * });
 */
export function useIsApproverEmailAlreadyExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsApproverEmailAlreadyExistQuery, IsApproverEmailAlreadyExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsApproverEmailAlreadyExistQuery, IsApproverEmailAlreadyExistQueryVariables>(IsApproverEmailAlreadyExistDocument, baseOptions);
      }
export function useIsApproverEmailAlreadyExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsApproverEmailAlreadyExistQuery, IsApproverEmailAlreadyExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsApproverEmailAlreadyExistQuery, IsApproverEmailAlreadyExistQueryVariables>(IsApproverEmailAlreadyExistDocument, baseOptions);
        }
export type IsApproverEmailAlreadyExistQueryHookResult = ReturnType<typeof useIsApproverEmailAlreadyExistQuery>;
export type IsApproverEmailAlreadyExistLazyQueryHookResult = ReturnType<typeof useIsApproverEmailAlreadyExistLazyQuery>;
export type IsApproverEmailAlreadyExistQueryResult = ApolloReactCommon.QueryResult<IsApproverEmailAlreadyExistQuery, IsApproverEmailAlreadyExistQueryVariables>;
export const ProjectTimesheetCheckPeriodOverlappingDocument = gql`
    query ProjectTimesheetCheckPeriodOverlapping($PeriodFrom: DateTime!, $PeriodTo: DateTime!, $ProjectTimesheetPeriodID: String, $ProjectTimesheetApproverMasterID: String, $SubscriptionAccountID: String, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $CompanyID: String, $SortBy: String, $isDraft: Boolean, $offset: Float, $limit: Float, $ExcludeFormStatus: String) {
  ProjectTimesheetCheckPeriodOverlapping(PeriodTo: $PeriodTo, PeriodFrom: $PeriodFrom, ProjectTimesheetPeriodID: $ProjectTimesheetPeriodID, ProjectTimesheetApproverMasterID: $ProjectTimesheetApproverMasterID, SubscriptionAccountID: $SubscriptionAccountID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID, SortBy: $SortBy, isDraft: $isDraft, offset: $offset, limit: $limit, ExcludeFormStatus: $ExcludeFormStatus) {
    ApproverPeriodRangeOverlap
    EmployeeListingBySubscriptionAccount {
      OrgUnitID
      EmployeeID
      EmployeeNo
      Status
      CompanyID
      OrgUnit {
        Description
      }
      Contact {
        ContactID
        FullName
      }
      Company {
        CompanyID
        CompanyName
      }
      isDailyEntryFilled
    }
  }
}
    `;

/**
 * __useProjectTimesheetCheckPeriodOverlappingQuery__
 *
 * To run a query within a React component, call `useProjectTimesheetCheckPeriodOverlappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheetCheckPeriodOverlappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimesheetCheckPeriodOverlappingQuery({
 *   variables: {
 *      PeriodFrom: // value for 'PeriodFrom'
 *      PeriodTo: // value for 'PeriodTo'
 *      ProjectTimesheetPeriodID: // value for 'ProjectTimesheetPeriodID'
 *      ProjectTimesheetApproverMasterID: // value for 'ProjectTimesheetApproverMasterID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      CompanyID: // value for 'CompanyID'
 *      SortBy: // value for 'SortBy'
 *      isDraft: // value for 'isDraft'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *   },
 * });
 */
export function useProjectTimesheetCheckPeriodOverlappingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTimesheetCheckPeriodOverlappingQuery, ProjectTimesheetCheckPeriodOverlappingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTimesheetCheckPeriodOverlappingQuery, ProjectTimesheetCheckPeriodOverlappingQueryVariables>(ProjectTimesheetCheckPeriodOverlappingDocument, baseOptions);
      }
export function useProjectTimesheetCheckPeriodOverlappingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTimesheetCheckPeriodOverlappingQuery, ProjectTimesheetCheckPeriodOverlappingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTimesheetCheckPeriodOverlappingQuery, ProjectTimesheetCheckPeriodOverlappingQueryVariables>(ProjectTimesheetCheckPeriodOverlappingDocument, baseOptions);
        }
export type ProjectTimesheetCheckPeriodOverlappingQueryHookResult = ReturnType<typeof useProjectTimesheetCheckPeriodOverlappingQuery>;
export type ProjectTimesheetCheckPeriodOverlappingLazyQueryHookResult = ReturnType<typeof useProjectTimesheetCheckPeriodOverlappingLazyQuery>;
export type ProjectTimesheetCheckPeriodOverlappingQueryResult = ApolloReactCommon.QueryResult<ProjectTimesheetCheckPeriodOverlappingQuery, ProjectTimesheetCheckPeriodOverlappingQueryVariables>;
export const ProjectTimesheetCheckPeriodOverlappingCountDocument = gql`
    query ProjectTimesheetCheckPeriodOverlappingCount($PeriodFrom: DateTime!, $PeriodTo: DateTime!, $ProjectTimesheetPeriodID: String, $ProjectTimesheetApproverMasterID: String, $DepartmentIDs: [String!]!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $CompanyID: String, $SortBy: String, $isDraft: Boolean, $ExcludeFormStatus: String) {
  ProjectTimesheetCheckPeriodOverlappingCount(PeriodTo: $PeriodTo, PeriodFrom: $PeriodFrom, ProjectTimesheetPeriodID: $ProjectTimesheetPeriodID, ProjectTimesheetApproverMasterID: $ProjectTimesheetApproverMasterID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID, SortBy: $SortBy, isDraft: $isDraft, ExcludeFormStatus: $ExcludeFormStatus)
}
    `;

/**
 * __useProjectTimesheetCheckPeriodOverlappingCountQuery__
 *
 * To run a query within a React component, call `useProjectTimesheetCheckPeriodOverlappingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheetCheckPeriodOverlappingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimesheetCheckPeriodOverlappingCountQuery({
 *   variables: {
 *      PeriodFrom: // value for 'PeriodFrom'
 *      PeriodTo: // value for 'PeriodTo'
 *      ProjectTimesheetPeriodID: // value for 'ProjectTimesheetPeriodID'
 *      ProjectTimesheetApproverMasterID: // value for 'ProjectTimesheetApproverMasterID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      CompanyID: // value for 'CompanyID'
 *      SortBy: // value for 'SortBy'
 *      isDraft: // value for 'isDraft'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *   },
 * });
 */
export function useProjectTimesheetCheckPeriodOverlappingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTimesheetCheckPeriodOverlappingCountQuery, ProjectTimesheetCheckPeriodOverlappingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTimesheetCheckPeriodOverlappingCountQuery, ProjectTimesheetCheckPeriodOverlappingCountQueryVariables>(ProjectTimesheetCheckPeriodOverlappingCountDocument, baseOptions);
      }
export function useProjectTimesheetCheckPeriodOverlappingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTimesheetCheckPeriodOverlappingCountQuery, ProjectTimesheetCheckPeriodOverlappingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTimesheetCheckPeriodOverlappingCountQuery, ProjectTimesheetCheckPeriodOverlappingCountQueryVariables>(ProjectTimesheetCheckPeriodOverlappingCountDocument, baseOptions);
        }
export type ProjectTimesheetCheckPeriodOverlappingCountQueryHookResult = ReturnType<typeof useProjectTimesheetCheckPeriodOverlappingCountQuery>;
export type ProjectTimesheetCheckPeriodOverlappingCountLazyQueryHookResult = ReturnType<typeof useProjectTimesheetCheckPeriodOverlappingCountLazyQuery>;
export type ProjectTimesheetCheckPeriodOverlappingCountQueryResult = ApolloReactCommon.QueryResult<ProjectTimesheetCheckPeriodOverlappingCountQuery, ProjectTimesheetCheckPeriodOverlappingCountQueryVariables>;
export const SubmitApproverMasterDocument = gql`
    mutation SubmitApproverMaster($mode: String!, $input: ProjectTimesheetApproverMasterInput!, $accountName: String!) {
  SubmitApproverMaster(mode: $mode, input: $input, accountName: $accountName)
}
    `;
export type SubmitApproverMasterMutationFn = ApolloReactCommon.MutationFunction<SubmitApproverMasterMutation, SubmitApproverMasterMutationVariables>;

/**
 * __useSubmitApproverMasterMutation__
 *
 * To run a mutation, you first call `useSubmitApproverMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitApproverMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitApproverMasterMutation, { data, loading, error }] = useSubmitApproverMasterMutation({
 *   variables: {
 *      mode: // value for 'mode'
 *      input: // value for 'input'
 *      accountName: // value for 'accountName'
 *   },
 * });
 */
export function useSubmitApproverMasterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitApproverMasterMutation, SubmitApproverMasterMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitApproverMasterMutation, SubmitApproverMasterMutationVariables>(SubmitApproverMasterDocument, baseOptions);
      }
export type SubmitApproverMasterMutationHookResult = ReturnType<typeof useSubmitApproverMasterMutation>;
export type SubmitApproverMasterMutationResult = ApolloReactCommon.MutationResult<SubmitApproverMasterMutation>;
export type SubmitApproverMasterMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitApproverMasterMutation, SubmitApproverMasterMutationVariables>;
export const DeleteApproverMasterDocument = gql`
    mutation DeleteApproverMaster($ProjectTimesheetApproverMasterID: String!) {
  DeleteApproverMaster(ProjectTimesheetApproverMasterID: $ProjectTimesheetApproverMasterID)
}
    `;
export type DeleteApproverMasterMutationFn = ApolloReactCommon.MutationFunction<DeleteApproverMasterMutation, DeleteApproverMasterMutationVariables>;

/**
 * __useDeleteApproverMasterMutation__
 *
 * To run a mutation, you first call `useDeleteApproverMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApproverMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApproverMasterMutation, { data, loading, error }] = useDeleteApproverMasterMutation({
 *   variables: {
 *      ProjectTimesheetApproverMasterID: // value for 'ProjectTimesheetApproverMasterID'
 *   },
 * });
 */
export function useDeleteApproverMasterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApproverMasterMutation, DeleteApproverMasterMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApproverMasterMutation, DeleteApproverMasterMutationVariables>(DeleteApproverMasterDocument, baseOptions);
      }
export type DeleteApproverMasterMutationHookResult = ReturnType<typeof useDeleteApproverMasterMutation>;
export type DeleteApproverMasterMutationResult = ApolloReactCommon.MutationResult<DeleteApproverMasterMutation>;
export type DeleteApproverMasterMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApproverMasterMutation, DeleteApproverMasterMutationVariables>;
export const SubmitApproverAssignmentDocument = gql`
    mutation SubmitApproverAssignment($mode: String!, $input: ProjectTimesheetApproverAssignmentInput!, $selectAllEmployee: Boolean!) {
  SubmitApproverAssignment(mode: $mode, input: $input, selectAllEmployee: $selectAllEmployee)
}
    `;
export type SubmitApproverAssignmentMutationFn = ApolloReactCommon.MutationFunction<SubmitApproverAssignmentMutation, SubmitApproverAssignmentMutationVariables>;

/**
 * __useSubmitApproverAssignmentMutation__
 *
 * To run a mutation, you first call `useSubmitApproverAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitApproverAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitApproverAssignmentMutation, { data, loading, error }] = useSubmitApproverAssignmentMutation({
 *   variables: {
 *      mode: // value for 'mode'
 *      input: // value for 'input'
 *      selectAllEmployee: // value for 'selectAllEmployee'
 *   },
 * });
 */
export function useSubmitApproverAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitApproverAssignmentMutation, SubmitApproverAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitApproverAssignmentMutation, SubmitApproverAssignmentMutationVariables>(SubmitApproverAssignmentDocument, baseOptions);
      }
export type SubmitApproverAssignmentMutationHookResult = ReturnType<typeof useSubmitApproverAssignmentMutation>;
export type SubmitApproverAssignmentMutationResult = ApolloReactCommon.MutationResult<SubmitApproverAssignmentMutation>;
export type SubmitApproverAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitApproverAssignmentMutation, SubmitApproverAssignmentMutationVariables>;
export const DeleteApproverEmployeeListingDocument = gql`
    mutation DeleteApproverEmployeeListing($ProjectTimesheetPeriodID: String!) {
  DeleteApproverEmployeeListing(ProjectTimesheetPeriodID: $ProjectTimesheetPeriodID)
}
    `;
export type DeleteApproverEmployeeListingMutationFn = ApolloReactCommon.MutationFunction<DeleteApproverEmployeeListingMutation, DeleteApproverEmployeeListingMutationVariables>;

/**
 * __useDeleteApproverEmployeeListingMutation__
 *
 * To run a mutation, you first call `useDeleteApproverEmployeeListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApproverEmployeeListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApproverEmployeeListingMutation, { data, loading, error }] = useDeleteApproverEmployeeListingMutation({
 *   variables: {
 *      ProjectTimesheetPeriodID: // value for 'ProjectTimesheetPeriodID'
 *   },
 * });
 */
export function useDeleteApproverEmployeeListingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApproverEmployeeListingMutation, DeleteApproverEmployeeListingMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApproverEmployeeListingMutation, DeleteApproverEmployeeListingMutationVariables>(DeleteApproverEmployeeListingDocument, baseOptions);
      }
export type DeleteApproverEmployeeListingMutationHookResult = ReturnType<typeof useDeleteApproverEmployeeListingMutation>;
export type DeleteApproverEmployeeListingMutationResult = ApolloReactCommon.MutationResult<DeleteApproverEmployeeListingMutation>;
export type DeleteApproverEmployeeListingMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApproverEmployeeListingMutation, DeleteApproverEmployeeListingMutationVariables>;
export const ResendConfirmationEmailDocument = gql`
    mutation ResendConfirmationEmail($ProjectTimesheetApproverMasterID: String!, $accountName: String!) {
  ResendConfirmationEmail(ProjectTimesheetApproverMasterID: $ProjectTimesheetApproverMasterID, accountName: $accountName)
}
    `;
export type ResendConfirmationEmailMutationFn = ApolloReactCommon.MutationFunction<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *      ProjectTimesheetApproverMasterID: // value for 'ProjectTimesheetApproverMasterID'
 *      accountName: // value for 'accountName'
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>(ResendConfirmationEmailDocument, baseOptions);
      }
export type ResendConfirmationEmailMutationHookResult = ReturnType<typeof useResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationResult = ApolloReactCommon.MutationResult<ResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;
export const ApproverConfirmedEmailActionDocument = gql`
    mutation ApproverConfirmedEmailAction($token: String!) {
  ApproverConfirmedEmailAction(token: $token)
}
    `;
export type ApproverConfirmedEmailActionMutationFn = ApolloReactCommon.MutationFunction<ApproverConfirmedEmailActionMutation, ApproverConfirmedEmailActionMutationVariables>;

/**
 * __useApproverConfirmedEmailActionMutation__
 *
 * To run a mutation, you first call `useApproverConfirmedEmailActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproverConfirmedEmailActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approverConfirmedEmailActionMutation, { data, loading, error }] = useApproverConfirmedEmailActionMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useApproverConfirmedEmailActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproverConfirmedEmailActionMutation, ApproverConfirmedEmailActionMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproverConfirmedEmailActionMutation, ApproverConfirmedEmailActionMutationVariables>(ApproverConfirmedEmailActionDocument, baseOptions);
      }
export type ApproverConfirmedEmailActionMutationHookResult = ReturnType<typeof useApproverConfirmedEmailActionMutation>;
export type ApproverConfirmedEmailActionMutationResult = ApolloReactCommon.MutationResult<ApproverConfirmedEmailActionMutation>;
export type ApproverConfirmedEmailActionMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproverConfirmedEmailActionMutation, ApproverConfirmedEmailActionMutationVariables>;
export const ProjectTimesheetPeriodListingDocument = gql`
    query ProjectTimesheetPeriodListing($Year: Float!, $EmployeeID: String!, $ContactID: String, $CompanyID: String!) {
  ProjectTimesheetPeriodListing(Year: $Year, EmployeeID: $EmployeeID, ContactID: $ContactID, CompanyID: $CompanyID) {
    ProjectTimesheetMonthlyRecordID
    PeriodYearMonth
    PeriodFromDate
    PeriodToDate
    PeriodStatus
    PeriodInfoline
    Remarks
    isAssignedToApprover
  }
}
    `;

/**
 * __useProjectTimesheetPeriodListingQuery__
 *
 * To run a query within a React component, call `useProjectTimesheetPeriodListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheetPeriodListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimesheetPeriodListingQuery({
 *   variables: {
 *      Year: // value for 'Year'
 *      EmployeeID: // value for 'EmployeeID'
 *      ContactID: // value for 'ContactID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useProjectTimesheetPeriodListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTimesheetPeriodListingQuery, ProjectTimesheetPeriodListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTimesheetPeriodListingQuery, ProjectTimesheetPeriodListingQueryVariables>(ProjectTimesheetPeriodListingDocument, baseOptions);
      }
export function useProjectTimesheetPeriodListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTimesheetPeriodListingQuery, ProjectTimesheetPeriodListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTimesheetPeriodListingQuery, ProjectTimesheetPeriodListingQueryVariables>(ProjectTimesheetPeriodListingDocument, baseOptions);
        }
export type ProjectTimesheetPeriodListingQueryHookResult = ReturnType<typeof useProjectTimesheetPeriodListingQuery>;
export type ProjectTimesheetPeriodListingLazyQueryHookResult = ReturnType<typeof useProjectTimesheetPeriodListingLazyQuery>;
export type ProjectTimesheetPeriodListingQueryResult = ApolloReactCommon.QueryResult<ProjectTimesheetPeriodListingQuery, ProjectTimesheetPeriodListingQueryVariables>;
export const DailyEntryListingDocument = gql`
    query DailyEntryListing($ProjectTimesheetMonthlyRecordID: String!) {
  DailyEntryListing(ProjectTimesheetMonthlyRecordID: $ProjectTimesheetMonthlyRecordID) {
    ID
    EntryDate
    StartTime
    EndTime
    BreakTime
    TotalHours
    Remarks
    ProjectTimesheetMonthlyRecord {
      ID
    }
  }
}
    `;

/**
 * __useDailyEntryListingQuery__
 *
 * To run a query within a React component, call `useDailyEntryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyEntryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyEntryListingQuery({
 *   variables: {
 *      ProjectTimesheetMonthlyRecordID: // value for 'ProjectTimesheetMonthlyRecordID'
 *   },
 * });
 */
export function useDailyEntryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DailyEntryListingQuery, DailyEntryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DailyEntryListingQuery, DailyEntryListingQueryVariables>(DailyEntryListingDocument, baseOptions);
      }
export function useDailyEntryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DailyEntryListingQuery, DailyEntryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DailyEntryListingQuery, DailyEntryListingQueryVariables>(DailyEntryListingDocument, baseOptions);
        }
export type DailyEntryListingQueryHookResult = ReturnType<typeof useDailyEntryListingQuery>;
export type DailyEntryListingLazyQueryHookResult = ReturnType<typeof useDailyEntryListingLazyQuery>;
export type DailyEntryListingQueryResult = ApolloReactCommon.QueryResult<DailyEntryListingQuery, DailyEntryListingQueryVariables>;
export const ProjectTimesheetSubmissionListingDocument = gql`
    query ProjectTimesheetSubmissionListing($EmployeeCalendar: Boolean!, $DutyRoster: Boolean!, $CompanyID: String!, $EmployeeID: String!, $ProjectTimesheetMonthlyRecordID: String!) {
  ProjectTimesheetSubmissionListing(EmployeeCalendar: $EmployeeCalendar, DutyRoster: $DutyRoster, CompanyID: $CompanyID, EmployeeID: $EmployeeID, ProjectTimesheetMonthlyRecordID: $ProjectTimesheetMonthlyRecordID)
}
    `;

/**
 * __useProjectTimesheetSubmissionListingQuery__
 *
 * To run a query within a React component, call `useProjectTimesheetSubmissionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheetSubmissionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimesheetSubmissionListingQuery({
 *   variables: {
 *      EmployeeCalendar: // value for 'EmployeeCalendar'
 *      DutyRoster: // value for 'DutyRoster'
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      ProjectTimesheetMonthlyRecordID: // value for 'ProjectTimesheetMonthlyRecordID'
 *   },
 * });
 */
export function useProjectTimesheetSubmissionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTimesheetSubmissionListingQuery, ProjectTimesheetSubmissionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTimesheetSubmissionListingQuery, ProjectTimesheetSubmissionListingQueryVariables>(ProjectTimesheetSubmissionListingDocument, baseOptions);
      }
export function useProjectTimesheetSubmissionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTimesheetSubmissionListingQuery, ProjectTimesheetSubmissionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTimesheetSubmissionListingQuery, ProjectTimesheetSubmissionListingQueryVariables>(ProjectTimesheetSubmissionListingDocument, baseOptions);
        }
export type ProjectTimesheetSubmissionListingQueryHookResult = ReturnType<typeof useProjectTimesheetSubmissionListingQuery>;
export type ProjectTimesheetSubmissionListingLazyQueryHookResult = ReturnType<typeof useProjectTimesheetSubmissionListingLazyQuery>;
export type ProjectTimesheetSubmissionListingQueryResult = ApolloReactCommon.QueryResult<ProjectTimesheetSubmissionListingQuery, ProjectTimesheetSubmissionListingQueryVariables>;
export const SubmitDailyEntryDocument = gql`
    mutation SubmitDailyEntry($mode: String!, $EmployeeID: String, $ContactID: String, $CompanyID: String, $input: ProjectTimesheetDailyEntryInput!) {
  SubmitDailyEntry(mode: $mode, EmployeeID: $EmployeeID, ContactID: $ContactID, CompanyID: $CompanyID, input: $input)
}
    `;
export type SubmitDailyEntryMutationFn = ApolloReactCommon.MutationFunction<SubmitDailyEntryMutation, SubmitDailyEntryMutationVariables>;

/**
 * __useSubmitDailyEntryMutation__
 *
 * To run a mutation, you first call `useSubmitDailyEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDailyEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDailyEntryMutation, { data, loading, error }] = useSubmitDailyEntryMutation({
 *   variables: {
 *      mode: // value for 'mode'
 *      EmployeeID: // value for 'EmployeeID'
 *      ContactID: // value for 'ContactID'
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitDailyEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitDailyEntryMutation, SubmitDailyEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitDailyEntryMutation, SubmitDailyEntryMutationVariables>(SubmitDailyEntryDocument, baseOptions);
      }
export type SubmitDailyEntryMutationHookResult = ReturnType<typeof useSubmitDailyEntryMutation>;
export type SubmitDailyEntryMutationResult = ApolloReactCommon.MutationResult<SubmitDailyEntryMutation>;
export type SubmitDailyEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitDailyEntryMutation, SubmitDailyEntryMutationVariables>;
export const DeleteDailyEntryDocument = gql`
    mutation DeleteDailyEntry($ProjectTimesheetDailyEntryID: String!) {
  DeleteDailyEntry(ProjectTimesheetDailyEntryID: $ProjectTimesheetDailyEntryID)
}
    `;
export type DeleteDailyEntryMutationFn = ApolloReactCommon.MutationFunction<DeleteDailyEntryMutation, DeleteDailyEntryMutationVariables>;

/**
 * __useDeleteDailyEntryMutation__
 *
 * To run a mutation, you first call `useDeleteDailyEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDailyEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDailyEntryMutation, { data, loading, error }] = useDeleteDailyEntryMutation({
 *   variables: {
 *      ProjectTimesheetDailyEntryID: // value for 'ProjectTimesheetDailyEntryID'
 *   },
 * });
 */
export function useDeleteDailyEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDailyEntryMutation, DeleteDailyEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDailyEntryMutation, DeleteDailyEntryMutationVariables>(DeleteDailyEntryDocument, baseOptions);
      }
export type DeleteDailyEntryMutationHookResult = ReturnType<typeof useDeleteDailyEntryMutation>;
export type DeleteDailyEntryMutationResult = ApolloReactCommon.MutationResult<DeleteDailyEntryMutation>;
export type DeleteDailyEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDailyEntryMutation, DeleteDailyEntryMutationVariables>;
export const SubmitProjectTimesheetDocument = gql`
    mutation SubmitProjectTimesheet($ProjectTimesheetMonthlyRecordID: String!, $input: [ProjectTimesheetDailyEntryInput!]!) {
  SubmitProjectTimesheet(ProjectTimesheetMonthlyRecordID: $ProjectTimesheetMonthlyRecordID, input: $input)
}
    `;
export type SubmitProjectTimesheetMutationFn = ApolloReactCommon.MutationFunction<SubmitProjectTimesheetMutation, SubmitProjectTimesheetMutationVariables>;

/**
 * __useSubmitProjectTimesheetMutation__
 *
 * To run a mutation, you first call `useSubmitProjectTimesheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitProjectTimesheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitProjectTimesheetMutation, { data, loading, error }] = useSubmitProjectTimesheetMutation({
 *   variables: {
 *      ProjectTimesheetMonthlyRecordID: // value for 'ProjectTimesheetMonthlyRecordID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitProjectTimesheetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitProjectTimesheetMutation, SubmitProjectTimesheetMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitProjectTimesheetMutation, SubmitProjectTimesheetMutationVariables>(SubmitProjectTimesheetDocument, baseOptions);
      }
export type SubmitProjectTimesheetMutationHookResult = ReturnType<typeof useSubmitProjectTimesheetMutation>;
export type SubmitProjectTimesheetMutationResult = ApolloReactCommon.MutationResult<SubmitProjectTimesheetMutation>;
export type SubmitProjectTimesheetMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitProjectTimesheetMutation, SubmitProjectTimesheetMutationVariables>;
export const ProjectTimesheetApprovalListingDocument = gql`
    query ProjectTimesheetApprovalListing($token: String!) {
  ProjectTimesheetApprovalListing(token: $token)
}
    `;

/**
 * __useProjectTimesheetApprovalListingQuery__
 *
 * To run a query within a React component, call `useProjectTimesheetApprovalListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheetApprovalListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimesheetApprovalListingQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useProjectTimesheetApprovalListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTimesheetApprovalListingQuery, ProjectTimesheetApprovalListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTimesheetApprovalListingQuery, ProjectTimesheetApprovalListingQueryVariables>(ProjectTimesheetApprovalListingDocument, baseOptions);
      }
export function useProjectTimesheetApprovalListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTimesheetApprovalListingQuery, ProjectTimesheetApprovalListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTimesheetApprovalListingQuery, ProjectTimesheetApprovalListingQueryVariables>(ProjectTimesheetApprovalListingDocument, baseOptions);
        }
export type ProjectTimesheetApprovalListingQueryHookResult = ReturnType<typeof useProjectTimesheetApprovalListingQuery>;
export type ProjectTimesheetApprovalListingLazyQueryHookResult = ReturnType<typeof useProjectTimesheetApprovalListingLazyQuery>;
export type ProjectTimesheetApprovalListingQueryResult = ApolloReactCommon.QueryResult<ProjectTimesheetApprovalListingQuery, ProjectTimesheetApprovalListingQueryVariables>;
export const SubmitProjectTimesheetApprovalDocument = gql`
    mutation SubmitProjectTimesheetApproval($token: String!, $StatusToChange: WorkflowLogStatus!, $Remarks: String) {
  SubmitProjectTimesheetApproval(token: $token, StatusToChange: $StatusToChange, Remarks: $Remarks)
}
    `;
export type SubmitProjectTimesheetApprovalMutationFn = ApolloReactCommon.MutationFunction<SubmitProjectTimesheetApprovalMutation, SubmitProjectTimesheetApprovalMutationVariables>;

/**
 * __useSubmitProjectTimesheetApprovalMutation__
 *
 * To run a mutation, you first call `useSubmitProjectTimesheetApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitProjectTimesheetApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitProjectTimesheetApprovalMutation, { data, loading, error }] = useSubmitProjectTimesheetApprovalMutation({
 *   variables: {
 *      token: // value for 'token'
 *      StatusToChange: // value for 'StatusToChange'
 *      Remarks: // value for 'Remarks'
 *   },
 * });
 */
export function useSubmitProjectTimesheetApprovalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitProjectTimesheetApprovalMutation, SubmitProjectTimesheetApprovalMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitProjectTimesheetApprovalMutation, SubmitProjectTimesheetApprovalMutationVariables>(SubmitProjectTimesheetApprovalDocument, baseOptions);
      }
export type SubmitProjectTimesheetApprovalMutationHookResult = ReturnType<typeof useSubmitProjectTimesheetApprovalMutation>;
export type SubmitProjectTimesheetApprovalMutationResult = ApolloReactCommon.MutationResult<SubmitProjectTimesheetApprovalMutation>;
export type SubmitProjectTimesheetApprovalMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitProjectTimesheetApprovalMutation, SubmitProjectTimesheetApprovalMutationVariables>;
export const ProjectTimesheetPeriodDocument = gql`
    query ProjectTimesheetPeriod($CompanyID: String!) {
  ProjectTimesheetPeriod(CompanyID: $CompanyID) {
    PeriodYearMonth
    CompanyID
    ID
  }
}
    `;

/**
 * __useProjectTimesheetPeriodQuery__
 *
 * To run a query within a React component, call `useProjectTimesheetPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheetPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimesheetPeriodQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useProjectTimesheetPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTimesheetPeriodQuery, ProjectTimesheetPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTimesheetPeriodQuery, ProjectTimesheetPeriodQueryVariables>(ProjectTimesheetPeriodDocument, baseOptions);
      }
export function useProjectTimesheetPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTimesheetPeriodQuery, ProjectTimesheetPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTimesheetPeriodQuery, ProjectTimesheetPeriodQueryVariables>(ProjectTimesheetPeriodDocument, baseOptions);
        }
export type ProjectTimesheetPeriodQueryHookResult = ReturnType<typeof useProjectTimesheetPeriodQuery>;
export type ProjectTimesheetPeriodLazyQueryHookResult = ReturnType<typeof useProjectTimesheetPeriodLazyQuery>;
export type ProjectTimesheetPeriodQueryResult = ApolloReactCommon.QueryResult<ProjectTimesheetPeriodQuery, ProjectTimesheetPeriodQueryVariables>;
export const ProjectTimesheetApproverDocument = gql`
    query ProjectTimesheetApprover {
  ProjectTimesheetApprover {
    Name
  }
}
    `;

/**
 * __useProjectTimesheetApproverQuery__
 *
 * To run a query within a React component, call `useProjectTimesheetApproverQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheetApproverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimesheetApproverQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectTimesheetApproverQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTimesheetApproverQuery, ProjectTimesheetApproverQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTimesheetApproverQuery, ProjectTimesheetApproverQueryVariables>(ProjectTimesheetApproverDocument, baseOptions);
      }
export function useProjectTimesheetApproverLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTimesheetApproverQuery, ProjectTimesheetApproverQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTimesheetApproverQuery, ProjectTimesheetApproverQueryVariables>(ProjectTimesheetApproverDocument, baseOptions);
        }
export type ProjectTimesheetApproverQueryHookResult = ReturnType<typeof useProjectTimesheetApproverQuery>;
export type ProjectTimesheetApproverLazyQueryHookResult = ReturnType<typeof useProjectTimesheetApproverLazyQuery>;
export type ProjectTimesheetApproverQueryResult = ApolloReactCommon.QueryResult<ProjectTimesheetApproverQuery, ProjectTimesheetApproverQueryVariables>;
export const GetEmployeesLeaveSummaryReportDocument = gql`
    query GetEmployeesLeaveSummaryReport($CompanyID: String!, $AsToDate: DateTime, $FromDate: DateTime, $Level: Float, $Sort: Float, $EmployeeIDs: [String!], $LeaveTypeIDs: [String!], $IsResigned: Boolean, $IsReport: Boolean) {
  GetEmployeesLeaveSummaryReport(CompanyID: $CompanyID, AsToDate: $AsToDate, FromDate: $FromDate, Level: $Level, Sort: $Sort, EmployeeIDs: $EmployeeIDs, LeaveTypeIDs: $LeaveTypeIDs, IsResigned: $IsResigned, IsReport: $IsReport) {
    Employee {
      EmployeeID
      EmployeeNo
      Status
      ResignedDate
      Contact {
        FullName
        Gender
        Marital
      }
    }
    EmployeeLeavePolicies {
      LeavePolicy {
        IsAdvanceLeave
        TotalAdvanceDay
        AdvanceStartMonth
        AdvanceEndMonth
        LeaveType {
          LeaveTypeID
          LeaveTypeCode
          Name
        }
        Title
        LeaveTypeID
        LeaveFrequency
      }
      LeaveBalanceSummary {
        Entitled
        BroughtForward
        Adjustment
        Earned
        Taken
        TakenYTD
        Forfeited
        Balance
        BalanceYTD
      }
    }
  }
}
    `;

/**
 * __useGetEmployeesLeaveSummaryReportQuery__
 *
 * To run a query within a React component, call `useGetEmployeesLeaveSummaryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesLeaveSummaryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesLeaveSummaryReportQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AsToDate: // value for 'AsToDate'
 *      FromDate: // value for 'FromDate'
 *      Level: // value for 'Level'
 *      Sort: // value for 'Sort'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      LeaveTypeIDs: // value for 'LeaveTypeIDs'
 *      IsResigned: // value for 'IsResigned'
 *      IsReport: // value for 'IsReport'
 *   },
 * });
 */
export function useGetEmployeesLeaveSummaryReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeesLeaveSummaryReportQuery, GetEmployeesLeaveSummaryReportQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeesLeaveSummaryReportQuery, GetEmployeesLeaveSummaryReportQueryVariables>(GetEmployeesLeaveSummaryReportDocument, baseOptions);
      }
export function useGetEmployeesLeaveSummaryReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeesLeaveSummaryReportQuery, GetEmployeesLeaveSummaryReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeesLeaveSummaryReportQuery, GetEmployeesLeaveSummaryReportQueryVariables>(GetEmployeesLeaveSummaryReportDocument, baseOptions);
        }
export type GetEmployeesLeaveSummaryReportQueryHookResult = ReturnType<typeof useGetEmployeesLeaveSummaryReportQuery>;
export type GetEmployeesLeaveSummaryReportLazyQueryHookResult = ReturnType<typeof useGetEmployeesLeaveSummaryReportLazyQuery>;
export type GetEmployeesLeaveSummaryReportQueryResult = ApolloReactCommon.QueryResult<GetEmployeesLeaveSummaryReportQuery, GetEmployeesLeaveSummaryReportQueryVariables>;
export const GetCompanyCyclePeriodListV2Document = gql`
    query GetCompanyCyclePeriodListV2($CompanyID: String) {
  GetCompanyCyclePeriodListV2(CompanyID: $CompanyID) {
    CompanyID
    CompanyName
    PayPeriodID
    PayrollCycleID
    PayrollStatus
    IsLastProcess
    PeriodYearMonth
    MMMYYYY
    PayCycleName
  }
}
    `;

/**
 * __useGetCompanyCyclePeriodListV2Query__
 *
 * To run a query within a React component, call `useGetCompanyCyclePeriodListV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCyclePeriodListV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCyclePeriodListV2Query({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCompanyCyclePeriodListV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyCyclePeriodListV2Query, GetCompanyCyclePeriodListV2QueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyCyclePeriodListV2Query, GetCompanyCyclePeriodListV2QueryVariables>(GetCompanyCyclePeriodListV2Document, baseOptions);
      }
export function useGetCompanyCyclePeriodListV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyCyclePeriodListV2Query, GetCompanyCyclePeriodListV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyCyclePeriodListV2Query, GetCompanyCyclePeriodListV2QueryVariables>(GetCompanyCyclePeriodListV2Document, baseOptions);
        }
export type GetCompanyCyclePeriodListV2QueryHookResult = ReturnType<typeof useGetCompanyCyclePeriodListV2Query>;
export type GetCompanyCyclePeriodListV2LazyQueryHookResult = ReturnType<typeof useGetCompanyCyclePeriodListV2LazyQuery>;
export type GetCompanyCyclePeriodListV2QueryResult = ApolloReactCommon.QueryResult<GetCompanyCyclePeriodListV2Query, GetCompanyCyclePeriodListV2QueryVariables>;
export const GetSignatoryListingDocument = gql`
    query GetSignatoryListing {
  GetSignatoryListing {
    EmployeeID
    CompanyID
    EmployeeNo
    EmployeeStatus
    FullName
    EmployeeNoName
  }
}
    `;

/**
 * __useGetSignatoryListingQuery__
 *
 * To run a query within a React component, call `useGetSignatoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignatoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignatoryListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSignatoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSignatoryListingQuery, GetSignatoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSignatoryListingQuery, GetSignatoryListingQueryVariables>(GetSignatoryListingDocument, baseOptions);
      }
export function useGetSignatoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSignatoryListingQuery, GetSignatoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSignatoryListingQuery, GetSignatoryListingQueryVariables>(GetSignatoryListingDocument, baseOptions);
        }
export type GetSignatoryListingQueryHookResult = ReturnType<typeof useGetSignatoryListingQuery>;
export type GetSignatoryListingLazyQueryHookResult = ReturnType<typeof useGetSignatoryListingLazyQuery>;
export type GetSignatoryListingQueryResult = ApolloReactCommon.QueryResult<GetSignatoryListingQuery, GetSignatoryListingQueryVariables>;
export const GetBankInfoListingDocument = gql`
    query GetBankInfoListing {
  GetBankInfoListing {
    BankInfoID
    CompanyID
    Name
    AccountName
    AccountNo
  }
}
    `;

/**
 * __useGetBankInfoListingQuery__
 *
 * To run a query within a React component, call `useGetBankInfoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankInfoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankInfoListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBankInfoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankInfoListingQuery, GetBankInfoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankInfoListingQuery, GetBankInfoListingQueryVariables>(GetBankInfoListingDocument, baseOptions);
      }
export function useGetBankInfoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankInfoListingQuery, GetBankInfoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankInfoListingQuery, GetBankInfoListingQueryVariables>(GetBankInfoListingDocument, baseOptions);
        }
export type GetBankInfoListingQueryHookResult = ReturnType<typeof useGetBankInfoListingQuery>;
export type GetBankInfoListingLazyQueryHookResult = ReturnType<typeof useGetBankInfoListingLazyQuery>;
export type GetBankInfoListingQueryResult = ApolloReactCommon.QueryResult<GetBankInfoListingQuery, GetBankInfoListingQueryVariables>;
export const GetBankInfoListingV2Document = gql`
    query GetBankInfoListingV2($CompanyID: String, $BankName: String) {
  GetBankInfoListingV2(CompanyID: $CompanyID, BankName: $BankName) {
    BankInfoID
    CompanyID
    Name
    AccountName
    AccountNo
  }
}
    `;

/**
 * __useGetBankInfoListingV2Query__
 *
 * To run a query within a React component, call `useGetBankInfoListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetBankInfoListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankInfoListingV2Query({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      BankName: // value for 'BankName'
 *   },
 * });
 */
export function useGetBankInfoListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankInfoListingV2Query, GetBankInfoListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankInfoListingV2Query, GetBankInfoListingV2QueryVariables>(GetBankInfoListingV2Document, baseOptions);
      }
export function useGetBankInfoListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankInfoListingV2Query, GetBankInfoListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankInfoListingV2Query, GetBankInfoListingV2QueryVariables>(GetBankInfoListingV2Document, baseOptions);
        }
export type GetBankInfoListingV2QueryHookResult = ReturnType<typeof useGetBankInfoListingV2Query>;
export type GetBankInfoListingV2LazyQueryHookResult = ReturnType<typeof useGetBankInfoListingV2LazyQuery>;
export type GetBankInfoListingV2QueryResult = ApolloReactCommon.QueryResult<GetBankInfoListingV2Query, GetBankInfoListingV2QueryVariables>;
export const GetPayrollReportListingDocument = gql`
    query GetPayrollReportListing($CompanyStatutoryAccountID: String, $CompanyID: String!, $PayPeriodID: String, $PayCycleIDs: [String!], $EmployeeIDs: [String!]!, $BankInfoID: String, $ReportType: String!, $Year: Float) {
  GetPayrollReportListing(CompanyStatutoryAccountID: $CompanyStatutoryAccountID, CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayCycleIDs: $PayCycleIDs, EmployeeIDs: $EmployeeIDs, BankInfoID: $BankInfoID, ReportType: $ReportType, Year: $Year) {
    CompanyID
    EmployeeID
  }
}
    `;

/**
 * __useGetPayrollReportListingQuery__
 *
 * To run a query within a React component, call `useGetPayrollReportListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollReportListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollReportListingQuery({
 *   variables: {
 *      CompanyStatutoryAccountID: // value for 'CompanyStatutoryAccountID'
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayCycleIDs: // value for 'PayCycleIDs'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      BankInfoID: // value for 'BankInfoID'
 *      ReportType: // value for 'ReportType'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetPayrollReportListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPayrollReportListingQuery, GetPayrollReportListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPayrollReportListingQuery, GetPayrollReportListingQueryVariables>(GetPayrollReportListingDocument, baseOptions);
      }
export function useGetPayrollReportListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPayrollReportListingQuery, GetPayrollReportListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPayrollReportListingQuery, GetPayrollReportListingQueryVariables>(GetPayrollReportListingDocument, baseOptions);
        }
export type GetPayrollReportListingQueryHookResult = ReturnType<typeof useGetPayrollReportListingQuery>;
export type GetPayrollReportListingLazyQueryHookResult = ReturnType<typeof useGetPayrollReportListingLazyQuery>;
export type GetPayrollReportListingQueryResult = ApolloReactCommon.QueryResult<GetPayrollReportListingQuery, GetPayrollReportListingQueryVariables>;
export const GetCompanyStatutoryListingDocument = gql`
    query GetCompanyStatutoryListing($Types: [String!]) {
  GetCompanyStatutoryListing(Types: $Types) {
    CompanyID
    CompanyStatutoryID
    CompanyStatutoryAccountID
    CompanyName
    Type
    Description
    AccountNo
    AccNoDesc
  }
}
    `;

/**
 * __useGetCompanyStatutoryListingQuery__
 *
 * To run a query within a React component, call `useGetCompanyStatutoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyStatutoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyStatutoryListingQuery({
 *   variables: {
 *      Types: // value for 'Types'
 *   },
 * });
 */
export function useGetCompanyStatutoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyStatutoryListingQuery, GetCompanyStatutoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyStatutoryListingQuery, GetCompanyStatutoryListingQueryVariables>(GetCompanyStatutoryListingDocument, baseOptions);
      }
export function useGetCompanyStatutoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyStatutoryListingQuery, GetCompanyStatutoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyStatutoryListingQuery, GetCompanyStatutoryListingQueryVariables>(GetCompanyStatutoryListingDocument, baseOptions);
        }
export type GetCompanyStatutoryListingQueryHookResult = ReturnType<typeof useGetCompanyStatutoryListingQuery>;
export type GetCompanyStatutoryListingLazyQueryHookResult = ReturnType<typeof useGetCompanyStatutoryListingLazyQuery>;
export type GetCompanyStatutoryListingQueryResult = ApolloReactCommon.QueryResult<GetCompanyStatutoryListingQuery, GetCompanyStatutoryListingQueryVariables>;
export const GetCompanyReportYearListingDocument = gql`
    query GetCompanyReportYearListing {
  GetCompanyReportYearListing {
    Year
  }
}
    `;

/**
 * __useGetCompanyReportYearListingQuery__
 *
 * To run a query within a React component, call `useGetCompanyReportYearListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyReportYearListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyReportYearListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyReportYearListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyReportYearListingQuery, GetCompanyReportYearListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyReportYearListingQuery, GetCompanyReportYearListingQueryVariables>(GetCompanyReportYearListingDocument, baseOptions);
      }
export function useGetCompanyReportYearListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyReportYearListingQuery, GetCompanyReportYearListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyReportYearListingQuery, GetCompanyReportYearListingQueryVariables>(GetCompanyReportYearListingDocument, baseOptions);
        }
export type GetCompanyReportYearListingQueryHookResult = ReturnType<typeof useGetCompanyReportYearListingQuery>;
export type GetCompanyReportYearListingLazyQueryHookResult = ReturnType<typeof useGetCompanyReportYearListingLazyQuery>;
export type GetCompanyReportYearListingQueryResult = ApolloReactCommon.QueryResult<GetCompanyReportYearListingQuery, GetCompanyReportYearListingQueryVariables>;
export const ClaimReportClaimTypeCompanyListingDocument = gql`
    query ClaimReportClaimTypeCompanyListing($SubscriptionAccountID: String!) {
  ClaimTypeListing(SubscriptionAccountID: $SubscriptionAccountID) {
    ClaimTypeID
    Name
  }
}
    `;

/**
 * __useClaimReportClaimTypeCompanyListingQuery__
 *
 * To run a query within a React component, call `useClaimReportClaimTypeCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimReportClaimTypeCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimReportClaimTypeCompanyListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useClaimReportClaimTypeCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimReportClaimTypeCompanyListingQuery, ClaimReportClaimTypeCompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimReportClaimTypeCompanyListingQuery, ClaimReportClaimTypeCompanyListingQueryVariables>(ClaimReportClaimTypeCompanyListingDocument, baseOptions);
      }
export function useClaimReportClaimTypeCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimReportClaimTypeCompanyListingQuery, ClaimReportClaimTypeCompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimReportClaimTypeCompanyListingQuery, ClaimReportClaimTypeCompanyListingQueryVariables>(ClaimReportClaimTypeCompanyListingDocument, baseOptions);
        }
export type ClaimReportClaimTypeCompanyListingQueryHookResult = ReturnType<typeof useClaimReportClaimTypeCompanyListingQuery>;
export type ClaimReportClaimTypeCompanyListingLazyQueryHookResult = ReturnType<typeof useClaimReportClaimTypeCompanyListingLazyQuery>;
export type ClaimReportClaimTypeCompanyListingQueryResult = ApolloReactCommon.QueryResult<ClaimReportClaimTypeCompanyListingQuery, ClaimReportClaimTypeCompanyListingQueryVariables>;
export const ClaimDetailReportListingDocument = gql`
    query ClaimDetailReportListing($Input: ClaimDetailListingReportInput!) {
  ClaimDetailReportListing(input: $Input)
}
    `;

/**
 * __useClaimDetailReportListingQuery__
 *
 * To run a query within a React component, call `useClaimDetailReportListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimDetailReportListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimDetailReportListingQuery({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useClaimDetailReportListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimDetailReportListingQuery, ClaimDetailReportListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimDetailReportListingQuery, ClaimDetailReportListingQueryVariables>(ClaimDetailReportListingDocument, baseOptions);
      }
export function useClaimDetailReportListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimDetailReportListingQuery, ClaimDetailReportListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimDetailReportListingQuery, ClaimDetailReportListingQueryVariables>(ClaimDetailReportListingDocument, baseOptions);
        }
export type ClaimDetailReportListingQueryHookResult = ReturnType<typeof useClaimDetailReportListingQuery>;
export type ClaimDetailReportListingLazyQueryHookResult = ReturnType<typeof useClaimDetailReportListingLazyQuery>;
export type ClaimDetailReportListingQueryResult = ApolloReactCommon.QueryResult<ClaimDetailReportListingQuery, ClaimDetailReportListingQueryVariables>;
export const GetPayItemListingDocument = gql`
    query GetPayItemListing($SubscriptionAccountID: String!) {
  GetPayItemListing(SubscriptionAccountID: $SubscriptionAccountID) {
    PayItemID
    SubscriptionAccountID
    PayName
    PayItemType
    PayItemTypeName
  }
}
    `;

/**
 * __useGetPayItemListingQuery__
 *
 * To run a query within a React component, call `useGetPayItemListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayItemListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayItemListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useGetPayItemListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPayItemListingQuery, GetPayItemListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPayItemListingQuery, GetPayItemListingQueryVariables>(GetPayItemListingDocument, baseOptions);
      }
export function useGetPayItemListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPayItemListingQuery, GetPayItemListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPayItemListingQuery, GetPayItemListingQueryVariables>(GetPayItemListingDocument, baseOptions);
        }
export type GetPayItemListingQueryHookResult = ReturnType<typeof useGetPayItemListingQuery>;
export type GetPayItemListingLazyQueryHookResult = ReturnType<typeof useGetPayItemListingLazyQuery>;
export type GetPayItemListingQueryResult = ApolloReactCommon.QueryResult<GetPayItemListingQuery, GetPayItemListingQueryVariables>;
export const PayrollDetailListingExportExcelDocument = gql`
    query PayrollDetailListingExportExcel($CompanyID: String!, $PayPeriodID: String!, $PayCycleIDs: [String!]!, $EmployeeIDs: [String!], $OrgUnitIDs: String!, $JobGradeIDs: String!, $StaffCategoryIDs: String!) {
  PayrollDetailListingExportExcel(CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayCycleIDs: $PayCycleIDs, EmployeeIDs: $EmployeeIDs, OrgUnitIDs: $OrgUnitIDs, JobGradeIDs: $JobGradeIDs, StaffCategoryIDs: $StaffCategoryIDs)
}
    `;

/**
 * __usePayrollDetailListingExportExcelQuery__
 *
 * To run a query within a React component, call `usePayrollDetailListingExportExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayrollDetailListingExportExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayrollDetailListingExportExcelQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayCycleIDs: // value for 'PayCycleIDs'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      StaffCategoryIDs: // value for 'StaffCategoryIDs'
 *   },
 * });
 */
export function usePayrollDetailListingExportExcelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PayrollDetailListingExportExcelQuery, PayrollDetailListingExportExcelQueryVariables>) {
        return ApolloReactHooks.useQuery<PayrollDetailListingExportExcelQuery, PayrollDetailListingExportExcelQueryVariables>(PayrollDetailListingExportExcelDocument, baseOptions);
      }
export function usePayrollDetailListingExportExcelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PayrollDetailListingExportExcelQuery, PayrollDetailListingExportExcelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PayrollDetailListingExportExcelQuery, PayrollDetailListingExportExcelQueryVariables>(PayrollDetailListingExportExcelDocument, baseOptions);
        }
export type PayrollDetailListingExportExcelQueryHookResult = ReturnType<typeof usePayrollDetailListingExportExcelQuery>;
export type PayrollDetailListingExportExcelLazyQueryHookResult = ReturnType<typeof usePayrollDetailListingExportExcelLazyQuery>;
export type PayrollDetailListingExportExcelQueryResult = ApolloReactCommon.QueryResult<PayrollDetailListingExportExcelQuery, PayrollDetailListingExportExcelQueryVariables>;
export const GetEmployeeListingWithPayrollInputDocument = gql`
    query GetEmployeeListingWithPayrollInput($CompanyID: String!, $PayPeriodID: String!, $PayCycleIDs: [String!]!) {
  GetEmployeeListingWithPayrollInput(CompanyID: $CompanyID, PayPeriodID: $PayPeriodID, PayCycleIDs: $PayCycleIDs) {
    EmployeeID
    EmployeeNoName
  }
}
    `;

/**
 * __useGetEmployeeListingWithPayrollInputQuery__
 *
 * To run a query within a React component, call `useGetEmployeeListingWithPayrollInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeListingWithPayrollInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeListingWithPayrollInputQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PayPeriodID: // value for 'PayPeriodID'
 *      PayCycleIDs: // value for 'PayCycleIDs'
 *   },
 * });
 */
export function useGetEmployeeListingWithPayrollInputQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeListingWithPayrollInputQuery, GetEmployeeListingWithPayrollInputQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeListingWithPayrollInputQuery, GetEmployeeListingWithPayrollInputQueryVariables>(GetEmployeeListingWithPayrollInputDocument, baseOptions);
      }
export function useGetEmployeeListingWithPayrollInputLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeListingWithPayrollInputQuery, GetEmployeeListingWithPayrollInputQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeListingWithPayrollInputQuery, GetEmployeeListingWithPayrollInputQueryVariables>(GetEmployeeListingWithPayrollInputDocument, baseOptions);
        }
export type GetEmployeeListingWithPayrollInputQueryHookResult = ReturnType<typeof useGetEmployeeListingWithPayrollInputQuery>;
export type GetEmployeeListingWithPayrollInputLazyQueryHookResult = ReturnType<typeof useGetEmployeeListingWithPayrollInputLazyQuery>;
export type GetEmployeeListingWithPayrollInputQueryResult = ApolloReactCommon.QueryResult<GetEmployeeListingWithPayrollInputQuery, GetEmployeeListingWithPayrollInputQueryVariables>;
export const RequisitionManagementApprovalUpdateDocument = gql`
    mutation RequisitionManagementApprovalUpdate($input: [RequisitionUpdateInput!]!, $IsSupersede: Boolean) {
  RequisitionManagementApprovalUpdate(input: $input, IsSupersede: $IsSupersede)
}
    `;
export type RequisitionManagementApprovalUpdateMutationFn = ApolloReactCommon.MutationFunction<RequisitionManagementApprovalUpdateMutation, RequisitionManagementApprovalUpdateMutationVariables>;

/**
 * __useRequisitionManagementApprovalUpdateMutation__
 *
 * To run a mutation, you first call `useRequisitionManagementApprovalUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequisitionManagementApprovalUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requisitionManagementApprovalUpdateMutation, { data, loading, error }] = useRequisitionManagementApprovalUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      IsSupersede: // value for 'IsSupersede'
 *   },
 * });
 */
export function useRequisitionManagementApprovalUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequisitionManagementApprovalUpdateMutation, RequisitionManagementApprovalUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RequisitionManagementApprovalUpdateMutation, RequisitionManagementApprovalUpdateMutationVariables>(RequisitionManagementApprovalUpdateDocument, baseOptions);
      }
export type RequisitionManagementApprovalUpdateMutationHookResult = ReturnType<typeof useRequisitionManagementApprovalUpdateMutation>;
export type RequisitionManagementApprovalUpdateMutationResult = ApolloReactCommon.MutationResult<RequisitionManagementApprovalUpdateMutation>;
export type RequisitionManagementApprovalUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RequisitionManagementApprovalUpdateMutation, RequisitionManagementApprovalUpdateMutationVariables>;
export const RequisitionApprovalPolicySummaryDocument = gql`
    query RequisitionApprovalPolicySummary($Category: [String!]) {
  ApprovalPolicySummary(Category: $Category)
}
    `;

/**
 * __useRequisitionApprovalPolicySummaryQuery__
 *
 * To run a query within a React component, call `useRequisitionApprovalPolicySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequisitionApprovalPolicySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequisitionApprovalPolicySummaryQuery({
 *   variables: {
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useRequisitionApprovalPolicySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RequisitionApprovalPolicySummaryQuery, RequisitionApprovalPolicySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<RequisitionApprovalPolicySummaryQuery, RequisitionApprovalPolicySummaryQueryVariables>(RequisitionApprovalPolicySummaryDocument, baseOptions);
      }
export function useRequisitionApprovalPolicySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RequisitionApprovalPolicySummaryQuery, RequisitionApprovalPolicySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RequisitionApprovalPolicySummaryQuery, RequisitionApprovalPolicySummaryQueryVariables>(RequisitionApprovalPolicySummaryDocument, baseOptions);
        }
export type RequisitionApprovalPolicySummaryQueryHookResult = ReturnType<typeof useRequisitionApprovalPolicySummaryQuery>;
export type RequisitionApprovalPolicySummaryLazyQueryHookResult = ReturnType<typeof useRequisitionApprovalPolicySummaryLazyQuery>;
export type RequisitionApprovalPolicySummaryQueryResult = ApolloReactCommon.QueryResult<RequisitionApprovalPolicySummaryQuery, RequisitionApprovalPolicySummaryQueryVariables>;
export const RequisitionMenuSummaryDocument = gql`
    query RequisitionMenuSummary($CompanyID: String!) {
  RequisitionMenuSummary(CompanyID: $CompanyID)
}
    `;

/**
 * __useRequisitionMenuSummaryQuery__
 *
 * To run a query within a React component, call `useRequisitionMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequisitionMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequisitionMenuSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useRequisitionMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RequisitionMenuSummaryQuery, RequisitionMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<RequisitionMenuSummaryQuery, RequisitionMenuSummaryQueryVariables>(RequisitionMenuSummaryDocument, baseOptions);
      }
export function useRequisitionMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RequisitionMenuSummaryQuery, RequisitionMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RequisitionMenuSummaryQuery, RequisitionMenuSummaryQueryVariables>(RequisitionMenuSummaryDocument, baseOptions);
        }
export type RequisitionMenuSummaryQueryHookResult = ReturnType<typeof useRequisitionMenuSummaryQuery>;
export type RequisitionMenuSummaryLazyQueryHookResult = ReturnType<typeof useRequisitionMenuSummaryLazyQuery>;
export type RequisitionMenuSummaryQueryResult = ApolloReactCommon.QueryResult<RequisitionMenuSummaryQuery, RequisitionMenuSummaryQueryVariables>;
export const RequisitionManagementListingDocument = gql`
    query RequisitionManagementListing($CompanyID: String!, $EndDate: String!, $StartDate: String!, $RequisitionType: [String!]!, $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!], $limit: Float, $offset: Float, $ApStatus: [String!]!, $filterText: String!) {
  RequisitionManagementListing(CompanyID: $CompanyID, EndDate: $EndDate, StartDate: $StartDate, RequisitionType: $RequisitionType, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, OrgUnitID: $OrgUnitID, limit: $limit, offset: $offset, ApStatus: $ApStatus, filterText: $filterText) {
    RequisitionHeaderID
    ApprovalStatus
    EmployeeID
    EmployeeName
    JobGradeID
    OrgUnitID
    FormStatus
    EmployeeNo
    RequisitionType
    TravelRequestID
    Subject
    Remark
    NumberOfItem
    CostPerItem
    Amount
    StartDate
    EndDate
    TravelFrom
    TravelTo
    TransportMode
    PurposeOfTravel
    DocumentID
    DocumentName
    DocumentFile
    ApprovalDT
    ApproverID
    ApprovedBy
    CreatedDT
    SubmitterName
    CreatedBy
    ModifiedDT
    ModifiedBy
    RejectedRemarks
    CancelledDate
    CancelledBy
    ReportTo
    OtherReqNo
    TravelReqNo
    AssetReqNo
    CashReqNo
    DocumentIDs {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      DocumentID
      DocumentType
      Description
      DocumentFile
      PayPeriodID
      ParentID
      BucketFileName
      FileSize
    }
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        NotifiedDT
        WorkFlowApprovedStep {
          ID
          ApproverID
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
          ApproverName
        }
      }
    }
  }
}
    `;

/**
 * __useRequisitionManagementListingQuery__
 *
 * To run a query within a React component, call `useRequisitionManagementListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequisitionManagementListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequisitionManagementListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      RequisitionType: // value for 'RequisitionType'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      ApStatus: // value for 'ApStatus'
 *      filterText: // value for 'filterText'
 *   },
 * });
 */
export function useRequisitionManagementListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RequisitionManagementListingQuery, RequisitionManagementListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RequisitionManagementListingQuery, RequisitionManagementListingQueryVariables>(RequisitionManagementListingDocument, baseOptions);
      }
export function useRequisitionManagementListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RequisitionManagementListingQuery, RequisitionManagementListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RequisitionManagementListingQuery, RequisitionManagementListingQueryVariables>(RequisitionManagementListingDocument, baseOptions);
        }
export type RequisitionManagementListingQueryHookResult = ReturnType<typeof useRequisitionManagementListingQuery>;
export type RequisitionManagementListingLazyQueryHookResult = ReturnType<typeof useRequisitionManagementListingLazyQuery>;
export type RequisitionManagementListingQueryResult = ApolloReactCommon.QueryResult<RequisitionManagementListingQuery, RequisitionManagementListingQueryVariables>;
export const ApprovalPolicyAssignmentSummaryDocument = gql`
    query ApprovalPolicyAssignmentSummary($Category: [String!]!, $CompanyID: String!) {
  ApprovalPolicyAssignmentSummary(Category: $Category, CompanyID: $CompanyID)
}
    `;

/**
 * __useApprovalPolicyAssignmentSummaryQuery__
 *
 * To run a query within a React component, call `useApprovalPolicyAssignmentSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalPolicyAssignmentSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalPolicyAssignmentSummaryQuery({
 *   variables: {
 *      Category: // value for 'Category'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useApprovalPolicyAssignmentSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovalPolicyAssignmentSummaryQuery, ApprovalPolicyAssignmentSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovalPolicyAssignmentSummaryQuery, ApprovalPolicyAssignmentSummaryQueryVariables>(ApprovalPolicyAssignmentSummaryDocument, baseOptions);
      }
export function useApprovalPolicyAssignmentSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovalPolicyAssignmentSummaryQuery, ApprovalPolicyAssignmentSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovalPolicyAssignmentSummaryQuery, ApprovalPolicyAssignmentSummaryQueryVariables>(ApprovalPolicyAssignmentSummaryDocument, baseOptions);
        }
export type ApprovalPolicyAssignmentSummaryQueryHookResult = ReturnType<typeof useApprovalPolicyAssignmentSummaryQuery>;
export type ApprovalPolicyAssignmentSummaryLazyQueryHookResult = ReturnType<typeof useApprovalPolicyAssignmentSummaryLazyQuery>;
export type ApprovalPolicyAssignmentSummaryQueryResult = ApolloReactCommon.QueryResult<ApprovalPolicyAssignmentSummaryQuery, ApprovalPolicyAssignmentSummaryQueryVariables>;
export const RequisitionManagementListingTotalCountDocument = gql`
    query RequisitionManagementListingTotalCount($CompanyID: String!, $EndDate: String!, $StartDate: String!, $RequisitionType: [String!]!, $EmployeeStatus: [String!]!, $DepartmentIDs: [String!]!, $ApStatus: [String!]!) {
  RequisitionManagementListingTotalCount(CompanyID: $CompanyID, EndDate: $EndDate, StartDate: $StartDate, RequisitionType: $RequisitionType, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, ApStatus: $ApStatus)
}
    `;

/**
 * __useRequisitionManagementListingTotalCountQuery__
 *
 * To run a query within a React component, call `useRequisitionManagementListingTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequisitionManagementListingTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequisitionManagementListingTotalCountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      RequisitionType: // value for 'RequisitionType'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      ApStatus: // value for 'ApStatus'
 *   },
 * });
 */
export function useRequisitionManagementListingTotalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RequisitionManagementListingTotalCountQuery, RequisitionManagementListingTotalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<RequisitionManagementListingTotalCountQuery, RequisitionManagementListingTotalCountQueryVariables>(RequisitionManagementListingTotalCountDocument, baseOptions);
      }
export function useRequisitionManagementListingTotalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RequisitionManagementListingTotalCountQuery, RequisitionManagementListingTotalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RequisitionManagementListingTotalCountQuery, RequisitionManagementListingTotalCountQueryVariables>(RequisitionManagementListingTotalCountDocument, baseOptions);
        }
export type RequisitionManagementListingTotalCountQueryHookResult = ReturnType<typeof useRequisitionManagementListingTotalCountQuery>;
export type RequisitionManagementListingTotalCountLazyQueryHookResult = ReturnType<typeof useRequisitionManagementListingTotalCountLazyQuery>;
export type RequisitionManagementListingTotalCountQueryResult = ApolloReactCommon.QueryResult<RequisitionManagementListingTotalCountQuery, RequisitionManagementListingTotalCountQueryVariables>;
export const ScaleRatingListingDocument = gql`
    query ScaleRatingListing {
  ScaleRatingListing {
    ScaleRatingHeaderID
    SubscriptionAccountID
    Title
    Scale
    isInUse
    ScaleRatingDetails {
      ScaleRatingDetailID
      Description
      ScaleSequence
    }
  }
}
    `;

/**
 * __useScaleRatingListingQuery__
 *
 * To run a query within a React component, call `useScaleRatingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleRatingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleRatingListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useScaleRatingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScaleRatingListingQuery, ScaleRatingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ScaleRatingListingQuery, ScaleRatingListingQueryVariables>(ScaleRatingListingDocument, baseOptions);
      }
export function useScaleRatingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScaleRatingListingQuery, ScaleRatingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ScaleRatingListingQuery, ScaleRatingListingQueryVariables>(ScaleRatingListingDocument, baseOptions);
        }
export type ScaleRatingListingQueryHookResult = ReturnType<typeof useScaleRatingListingQuery>;
export type ScaleRatingListingLazyQueryHookResult = ReturnType<typeof useScaleRatingListingLazyQuery>;
export type ScaleRatingListingQueryResult = ApolloReactCommon.QueryResult<ScaleRatingListingQuery, ScaleRatingListingQueryVariables>;
export const ScaleRatingSubmitDocument = gql`
    mutation ScaleRatingSubmit($input: ScaleRatingHeaderInput!) {
  ScaleRatingSubmit(input: $input) {
    ScaleRatingHeaderID
  }
}
    `;
export type ScaleRatingSubmitMutationFn = ApolloReactCommon.MutationFunction<ScaleRatingSubmitMutation, ScaleRatingSubmitMutationVariables>;

/**
 * __useScaleRatingSubmitMutation__
 *
 * To run a mutation, you first call `useScaleRatingSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScaleRatingSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scaleRatingSubmitMutation, { data, loading, error }] = useScaleRatingSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScaleRatingSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScaleRatingSubmitMutation, ScaleRatingSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ScaleRatingSubmitMutation, ScaleRatingSubmitMutationVariables>(ScaleRatingSubmitDocument, baseOptions);
      }
export type ScaleRatingSubmitMutationHookResult = ReturnType<typeof useScaleRatingSubmitMutation>;
export type ScaleRatingSubmitMutationResult = ApolloReactCommon.MutationResult<ScaleRatingSubmitMutation>;
export type ScaleRatingSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ScaleRatingSubmitMutation, ScaleRatingSubmitMutationVariables>;
export const ScaleRatingDeleteDocument = gql`
    mutation ScaleRatingDelete($ScaleRatingHeaderID: String!) {
  ScaleRatingDelete(ScaleRatingHeaderID: $ScaleRatingHeaderID)
}
    `;
export type ScaleRatingDeleteMutationFn = ApolloReactCommon.MutationFunction<ScaleRatingDeleteMutation, ScaleRatingDeleteMutationVariables>;

/**
 * __useScaleRatingDeleteMutation__
 *
 * To run a mutation, you first call `useScaleRatingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScaleRatingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scaleRatingDeleteMutation, { data, loading, error }] = useScaleRatingDeleteMutation({
 *   variables: {
 *      ScaleRatingHeaderID: // value for 'ScaleRatingHeaderID'
 *   },
 * });
 */
export function useScaleRatingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScaleRatingDeleteMutation, ScaleRatingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ScaleRatingDeleteMutation, ScaleRatingDeleteMutationVariables>(ScaleRatingDeleteDocument, baseOptions);
      }
export type ScaleRatingDeleteMutationHookResult = ReturnType<typeof useScaleRatingDeleteMutation>;
export type ScaleRatingDeleteMutationResult = ApolloReactCommon.MutationResult<ScaleRatingDeleteMutation>;
export type ScaleRatingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ScaleRatingDeleteMutation, ScaleRatingDeleteMutationVariables>;
export const ScheduleTableSubmitDocument = gql`
    mutation ScheduleTableSubmit($ScheduleTrainingID: String!, $input: [TrainingScheduleTableInput!]!) {
  ScheduleTableSubmit(ScheduleTrainingID: $ScheduleTrainingID, input: $input)
}
    `;
export type ScheduleTableSubmitMutationFn = ApolloReactCommon.MutationFunction<ScheduleTableSubmitMutation, ScheduleTableSubmitMutationVariables>;

/**
 * __useScheduleTableSubmitMutation__
 *
 * To run a mutation, you first call `useScheduleTableSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleTableSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleTableSubmitMutation, { data, loading, error }] = useScheduleTableSubmitMutation({
 *   variables: {
 *      ScheduleTrainingID: // value for 'ScheduleTrainingID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleTableSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleTableSubmitMutation, ScheduleTableSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ScheduleTableSubmitMutation, ScheduleTableSubmitMutationVariables>(ScheduleTableSubmitDocument, baseOptions);
      }
export type ScheduleTableSubmitMutationHookResult = ReturnType<typeof useScheduleTableSubmitMutation>;
export type ScheduleTableSubmitMutationResult = ApolloReactCommon.MutationResult<ScheduleTableSubmitMutation>;
export type ScheduleTableSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleTableSubmitMutation, ScheduleTableSubmitMutationVariables>;
export const ScheduleTrainingSubmitDocument = gql`
    mutation ScheduleTrainingSubmit($input: ScheduleTrainingInput!) {
  ScheduleTrainingSubmit(input: $input)
}
    `;
export type ScheduleTrainingSubmitMutationFn = ApolloReactCommon.MutationFunction<ScheduleTrainingSubmitMutation, ScheduleTrainingSubmitMutationVariables>;

/**
 * __useScheduleTrainingSubmitMutation__
 *
 * To run a mutation, you first call `useScheduleTrainingSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleTrainingSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleTrainingSubmitMutation, { data, loading, error }] = useScheduleTrainingSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleTrainingSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleTrainingSubmitMutation, ScheduleTrainingSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ScheduleTrainingSubmitMutation, ScheduleTrainingSubmitMutationVariables>(ScheduleTrainingSubmitDocument, baseOptions);
      }
export type ScheduleTrainingSubmitMutationHookResult = ReturnType<typeof useScheduleTrainingSubmitMutation>;
export type ScheduleTrainingSubmitMutationResult = ApolloReactCommon.MutationResult<ScheduleTrainingSubmitMutation>;
export type ScheduleTrainingSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleTrainingSubmitMutation, ScheduleTrainingSubmitMutationVariables>;
export const ScheduleTrainingDeleteDocument = gql`
    mutation ScheduleTrainingDelete($ScheduleTrainingID: String!) {
  ScheduleTrainingDelete(ScheduleTrainingID: $ScheduleTrainingID)
}
    `;
export type ScheduleTrainingDeleteMutationFn = ApolloReactCommon.MutationFunction<ScheduleTrainingDeleteMutation, ScheduleTrainingDeleteMutationVariables>;

/**
 * __useScheduleTrainingDeleteMutation__
 *
 * To run a mutation, you first call `useScheduleTrainingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleTrainingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleTrainingDeleteMutation, { data, loading, error }] = useScheduleTrainingDeleteMutation({
 *   variables: {
 *      ScheduleTrainingID: // value for 'ScheduleTrainingID'
 *   },
 * });
 */
export function useScheduleTrainingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleTrainingDeleteMutation, ScheduleTrainingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ScheduleTrainingDeleteMutation, ScheduleTrainingDeleteMutationVariables>(ScheduleTrainingDeleteDocument, baseOptions);
      }
export type ScheduleTrainingDeleteMutationHookResult = ReturnType<typeof useScheduleTrainingDeleteMutation>;
export type ScheduleTrainingDeleteMutationResult = ApolloReactCommon.MutationResult<ScheduleTrainingDeleteMutation>;
export type ScheduleTrainingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleTrainingDeleteMutation, ScheduleTrainingDeleteMutationVariables>;
export const ScheduleTrainingCancelDocument = gql`
    mutation ScheduleTrainingCancel($ScheduleTrainingID: String!) {
  ScheduleTrainingCancel(ScheduleTrainingID: $ScheduleTrainingID)
}
    `;
export type ScheduleTrainingCancelMutationFn = ApolloReactCommon.MutationFunction<ScheduleTrainingCancelMutation, ScheduleTrainingCancelMutationVariables>;

/**
 * __useScheduleTrainingCancelMutation__
 *
 * To run a mutation, you first call `useScheduleTrainingCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleTrainingCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleTrainingCancelMutation, { data, loading, error }] = useScheduleTrainingCancelMutation({
 *   variables: {
 *      ScheduleTrainingID: // value for 'ScheduleTrainingID'
 *   },
 * });
 */
export function useScheduleTrainingCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ScheduleTrainingCancelMutation, ScheduleTrainingCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<ScheduleTrainingCancelMutation, ScheduleTrainingCancelMutationVariables>(ScheduleTrainingCancelDocument, baseOptions);
      }
export type ScheduleTrainingCancelMutationHookResult = ReturnType<typeof useScheduleTrainingCancelMutation>;
export type ScheduleTrainingCancelMutationResult = ApolloReactCommon.MutationResult<ScheduleTrainingCancelMutation>;
export type ScheduleTrainingCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<ScheduleTrainingCancelMutation, ScheduleTrainingCancelMutationVariables>;
export const RegisteredTraineeListingDocument = gql`
    query RegisteredTraineeListing($ScheduleTrainingID: String!) {
  RegisteredTraineeListing(ScheduleTrainingID: $ScheduleTrainingID) {
    EmployeeID
    EmployeeNo
    DepartmentID
    DivisionID
    JobGradeID
    Contact {
      ContactID
      FullName
      MobileNo
      Email
    }
    Company {
      CompanyID
      CompanyName
    }
    EmployeeStatutory {
      EmployeeStatutoryID
    }
    EmployeePrevContribution {
      EmployeePrevContributionID
    }
    EmployeeDependents {
      EmployeeDependentsID
    }
    Department {
      DepartmentID
      DepartmentType
      ParentID
      Description
    }
    Designation {
      JobDesignationID
      Name
    }
    JobGrade {
      Description
    }
    OrgUnit {
      CompanyOrgUnitID
      Level
      Description
      ParentID
    }
    DocumentID
    Documents {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    TotalTaxRelief
    FormStatus
    EmploymentType
    CreatedDT
    ModifiedDT
  }
}
    `;

/**
 * __useRegisteredTraineeListingQuery__
 *
 * To run a query within a React component, call `useRegisteredTraineeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredTraineeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredTraineeListingQuery({
 *   variables: {
 *      ScheduleTrainingID: // value for 'ScheduleTrainingID'
 *   },
 * });
 */
export function useRegisteredTraineeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegisteredTraineeListingQuery, RegisteredTraineeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RegisteredTraineeListingQuery, RegisteredTraineeListingQueryVariables>(RegisteredTraineeListingDocument, baseOptions);
      }
export function useRegisteredTraineeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegisteredTraineeListingQuery, RegisteredTraineeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegisteredTraineeListingQuery, RegisteredTraineeListingQueryVariables>(RegisteredTraineeListingDocument, baseOptions);
        }
export type RegisteredTraineeListingQueryHookResult = ReturnType<typeof useRegisteredTraineeListingQuery>;
export type RegisteredTraineeListingLazyQueryHookResult = ReturnType<typeof useRegisteredTraineeListingLazyQuery>;
export type RegisteredTraineeListingQueryResult = ApolloReactCommon.QueryResult<RegisteredTraineeListingQuery, RegisteredTraineeListingQueryVariables>;
export const RegisteredTraineeDeleteDocument = gql`
    mutation RegisteredTraineeDelete($RegisteredTraineeID: String!, $ScheduleTrainingID: String!) {
  RegisteredTraineeDelete(RegisteredTraineeID: $RegisteredTraineeID, ScheduleTrainingID: $ScheduleTrainingID)
}
    `;
export type RegisteredTraineeDeleteMutationFn = ApolloReactCommon.MutationFunction<RegisteredTraineeDeleteMutation, RegisteredTraineeDeleteMutationVariables>;

/**
 * __useRegisteredTraineeDeleteMutation__
 *
 * To run a mutation, you first call `useRegisteredTraineeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisteredTraineeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registeredTraineeDeleteMutation, { data, loading, error }] = useRegisteredTraineeDeleteMutation({
 *   variables: {
 *      RegisteredTraineeID: // value for 'RegisteredTraineeID'
 *      ScheduleTrainingID: // value for 'ScheduleTrainingID'
 *   },
 * });
 */
export function useRegisteredTraineeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisteredTraineeDeleteMutation, RegisteredTraineeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisteredTraineeDeleteMutation, RegisteredTraineeDeleteMutationVariables>(RegisteredTraineeDeleteDocument, baseOptions);
      }
export type RegisteredTraineeDeleteMutationHookResult = ReturnType<typeof useRegisteredTraineeDeleteMutation>;
export type RegisteredTraineeDeleteMutationResult = ApolloReactCommon.MutationResult<RegisteredTraineeDeleteMutation>;
export type RegisteredTraineeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisteredTraineeDeleteMutation, RegisteredTraineeDeleteMutationVariables>;
export const GetLatestUpdateForMainMenuModuleDocument = gql`
    query GetLatestUpdateForMainMenuModule($CompanyID: String!) {
  GetLatestUpdateForMainMenuModule(CompanyID: $CompanyID) {
    CostBudget
    ScheduledTrainig
    ScheduledTrainingCount
    TraineeEval
    CourseEval
    TrainerEval
    SupervisorEval
    TrainingCost
    CostAllocation
    HRDFClaim
  }
}
    `;

/**
 * __useGetLatestUpdateForMainMenuModuleQuery__
 *
 * To run a query within a React component, call `useGetLatestUpdateForMainMenuModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestUpdateForMainMenuModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestUpdateForMainMenuModuleQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetLatestUpdateForMainMenuModuleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestUpdateForMainMenuModuleQuery, GetLatestUpdateForMainMenuModuleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestUpdateForMainMenuModuleQuery, GetLatestUpdateForMainMenuModuleQueryVariables>(GetLatestUpdateForMainMenuModuleDocument, baseOptions);
      }
export function useGetLatestUpdateForMainMenuModuleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestUpdateForMainMenuModuleQuery, GetLatestUpdateForMainMenuModuleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestUpdateForMainMenuModuleQuery, GetLatestUpdateForMainMenuModuleQueryVariables>(GetLatestUpdateForMainMenuModuleDocument, baseOptions);
        }
export type GetLatestUpdateForMainMenuModuleQueryHookResult = ReturnType<typeof useGetLatestUpdateForMainMenuModuleQuery>;
export type GetLatestUpdateForMainMenuModuleLazyQueryHookResult = ReturnType<typeof useGetLatestUpdateForMainMenuModuleLazyQuery>;
export type GetLatestUpdateForMainMenuModuleQueryResult = ApolloReactCommon.QueryResult<GetLatestUpdateForMainMenuModuleQuery, GetLatestUpdateForMainMenuModuleQueryVariables>;
export const GetEmployeeKpiPlanningByYearAppraisalManagementDocument = gql`
    query GetEmployeeKPIPlanningByYearAppraisalManagement($CompanyID: String!, $Year: Float!, $DepartmentIDs: [String!]!, $EmployeeStatus: [String!]!, $EmployeeType: [String!]!) {
  GetEmployeeKPIPlanningByYear(CompanyID: $CompanyID, Year: $Year, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType) {
    EmployeeID
    Name
    JobGradeID
    QuaterType
    OrgUnitID
    FormStatus
    PlanningList {
      EmployeeKPIHeaderID
      Year
      Version
      AppraisalTempleteID
      SubscriptionAccountID
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
  }
}
    `;

/**
 * __useGetEmployeeKpiPlanningByYearAppraisalManagementQuery__
 *
 * To run a query within a React component, call `useGetEmployeeKpiPlanningByYearAppraisalManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeKpiPlanningByYearAppraisalManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeKpiPlanningByYearAppraisalManagementQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *   },
 * });
 */
export function useGetEmployeeKpiPlanningByYearAppraisalManagementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeKpiPlanningByYearAppraisalManagementQuery, GetEmployeeKpiPlanningByYearAppraisalManagementQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeKpiPlanningByYearAppraisalManagementQuery, GetEmployeeKpiPlanningByYearAppraisalManagementQueryVariables>(GetEmployeeKpiPlanningByYearAppraisalManagementDocument, baseOptions);
      }
export function useGetEmployeeKpiPlanningByYearAppraisalManagementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeKpiPlanningByYearAppraisalManagementQuery, GetEmployeeKpiPlanningByYearAppraisalManagementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeKpiPlanningByYearAppraisalManagementQuery, GetEmployeeKpiPlanningByYearAppraisalManagementQueryVariables>(GetEmployeeKpiPlanningByYearAppraisalManagementDocument, baseOptions);
        }
export type GetEmployeeKpiPlanningByYearAppraisalManagementQueryHookResult = ReturnType<typeof useGetEmployeeKpiPlanningByYearAppraisalManagementQuery>;
export type GetEmployeeKpiPlanningByYearAppraisalManagementLazyQueryHookResult = ReturnType<typeof useGetEmployeeKpiPlanningByYearAppraisalManagementLazyQuery>;
export type GetEmployeeKpiPlanningByYearAppraisalManagementQueryResult = ApolloReactCommon.QueryResult<GetEmployeeKpiPlanningByYearAppraisalManagementQuery, GetEmployeeKpiPlanningByYearAppraisalManagementQueryVariables>;
export const GetEmployeeListingByscheduledAndQuaterDocument = gql`
    query GetEmployeeListingByscheduledAndQuater($QuaterID: String!, $ScheduledID: String!, $Year: Float!, $CompanyID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DepartmentIDs: [String!]!, $EmployeeStatus: [String!]!, $EmployeeType: [String!]!) {
  GetEmployeeListingByscheduledAndQuater(QuaterID: $QuaterID, ScheduledID: $ScheduledID, Year: $Year, CompanyID: $CompanyID, StartDate: $StartDate, EndDate: $EndDate, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType) {
    EmployeeID
    SubscriptionUserID
    EmployeeNo
    EmploymentType
    Status
    AppraisalStatus
    FormStatus
    OrgUnitID
    Contact {
      FullName
    }
    Company {
      CompanyID
      CompanyName
    }
    Designation {
      JobDesignationID
      Name
    }
    Documents {
      DocumentFile
    }
    ResignedDate
    JobGradeID
    Department {
      Description
    }
    Division {
      Description
    }
    JobGrade {
      Description
    }
    OrgUnit {
      CompanyOrgUnitID
      Level
      Description
      ParentID
    }
  }
}
    `;

/**
 * __useGetEmployeeListingByscheduledAndQuaterQuery__
 *
 * To run a query within a React component, call `useGetEmployeeListingByscheduledAndQuaterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeListingByscheduledAndQuaterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeListingByscheduledAndQuaterQuery({
 *   variables: {
 *      QuaterID: // value for 'QuaterID'
 *      ScheduledID: // value for 'ScheduledID'
 *      Year: // value for 'Year'
 *      CompanyID: // value for 'CompanyID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *   },
 * });
 */
export function useGetEmployeeListingByscheduledAndQuaterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeListingByscheduledAndQuaterQuery, GetEmployeeListingByscheduledAndQuaterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeListingByscheduledAndQuaterQuery, GetEmployeeListingByscheduledAndQuaterQueryVariables>(GetEmployeeListingByscheduledAndQuaterDocument, baseOptions);
      }
export function useGetEmployeeListingByscheduledAndQuaterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeListingByscheduledAndQuaterQuery, GetEmployeeListingByscheduledAndQuaterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeListingByscheduledAndQuaterQuery, GetEmployeeListingByscheduledAndQuaterQueryVariables>(GetEmployeeListingByscheduledAndQuaterDocument, baseOptions);
        }
export type GetEmployeeListingByscheduledAndQuaterQueryHookResult = ReturnType<typeof useGetEmployeeListingByscheduledAndQuaterQuery>;
export type GetEmployeeListingByscheduledAndQuaterLazyQueryHookResult = ReturnType<typeof useGetEmployeeListingByscheduledAndQuaterLazyQuery>;
export type GetEmployeeListingByscheduledAndQuaterQueryResult = ApolloReactCommon.QueryResult<GetEmployeeListingByscheduledAndQuaterQuery, GetEmployeeListingByscheduledAndQuaterQueryVariables>;
export const StaffPerformanceAnalysisSummaryListingDocument = gql`
    query StaffPerformanceAnalysisSummaryListing($QuaterID: String!, $Year: Float!, $CompanyID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DepartmentIDs: [String!]!, $EmployeeStatus: [String!]!, $EmployeeType: [String!]!) {
  StaffPerformanceAnalysisSummaryListing(QuaterID: $QuaterID, Year: $Year, CompanyID: $CompanyID, StartDate: $StartDate, EndDate: $EndDate, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType) {
    EmployeeID
    EmployeeName
    EmployeeNo
    EmployeeSalary
    EmployeeDetails {
      DepartmentID
      OrgUnitID
      DivisionID
      EmployeeCareerLogs {
        CareerLogID
        EffectiveDate
        SalaryType
        FromSalary
        ToSalary
      }
    }
    FinalScore
    FinalRating
    RatingAdjustment {
      StaffPerformanceAnalysisSummaryID
      Year
      RatingAdjustment
      QuaterID
    }
  }
}
    `;

/**
 * __useStaffPerformanceAnalysisSummaryListingQuery__
 *
 * To run a query within a React component, call `useStaffPerformanceAnalysisSummaryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffPerformanceAnalysisSummaryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffPerformanceAnalysisSummaryListingQuery({
 *   variables: {
 *      QuaterID: // value for 'QuaterID'
 *      Year: // value for 'Year'
 *      CompanyID: // value for 'CompanyID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *   },
 * });
 */
export function useStaffPerformanceAnalysisSummaryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffPerformanceAnalysisSummaryListingQuery, StaffPerformanceAnalysisSummaryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffPerformanceAnalysisSummaryListingQuery, StaffPerformanceAnalysisSummaryListingQueryVariables>(StaffPerformanceAnalysisSummaryListingDocument, baseOptions);
      }
export function useStaffPerformanceAnalysisSummaryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffPerformanceAnalysisSummaryListingQuery, StaffPerformanceAnalysisSummaryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffPerformanceAnalysisSummaryListingQuery, StaffPerformanceAnalysisSummaryListingQueryVariables>(StaffPerformanceAnalysisSummaryListingDocument, baseOptions);
        }
export type StaffPerformanceAnalysisSummaryListingQueryHookResult = ReturnType<typeof useStaffPerformanceAnalysisSummaryListingQuery>;
export type StaffPerformanceAnalysisSummaryListingLazyQueryHookResult = ReturnType<typeof useStaffPerformanceAnalysisSummaryListingLazyQuery>;
export type StaffPerformanceAnalysisSummaryListingQueryResult = ApolloReactCommon.QueryResult<StaffPerformanceAnalysisSummaryListingQuery, StaffPerformanceAnalysisSummaryListingQueryVariables>;
export const StaffPerformanceAnalysisSummarySubmitDocument = gql`
    mutation StaffPerformanceAnalysisSummarySubmit($input: [StaffPerformanceAnalysisSummaryInput!]!) {
  StaffPerformanceAnalysisSummarySubmit(input: $input)
}
    `;
export type StaffPerformanceAnalysisSummarySubmitMutationFn = ApolloReactCommon.MutationFunction<StaffPerformanceAnalysisSummarySubmitMutation, StaffPerformanceAnalysisSummarySubmitMutationVariables>;

/**
 * __useStaffPerformanceAnalysisSummarySubmitMutation__
 *
 * To run a mutation, you first call `useStaffPerformanceAnalysisSummarySubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffPerformanceAnalysisSummarySubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffPerformanceAnalysisSummarySubmitMutation, { data, loading, error }] = useStaffPerformanceAnalysisSummarySubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStaffPerformanceAnalysisSummarySubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffPerformanceAnalysisSummarySubmitMutation, StaffPerformanceAnalysisSummarySubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffPerformanceAnalysisSummarySubmitMutation, StaffPerformanceAnalysisSummarySubmitMutationVariables>(StaffPerformanceAnalysisSummarySubmitDocument, baseOptions);
      }
export type StaffPerformanceAnalysisSummarySubmitMutationHookResult = ReturnType<typeof useStaffPerformanceAnalysisSummarySubmitMutation>;
export type StaffPerformanceAnalysisSummarySubmitMutationResult = ApolloReactCommon.MutationResult<StaffPerformanceAnalysisSummarySubmitMutation>;
export type StaffPerformanceAnalysisSummarySubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffPerformanceAnalysisSummarySubmitMutation, StaffPerformanceAnalysisSummarySubmitMutationVariables>;
export const GetBonusRecordByCompanyOrgUnitListDocument = gql`
    query GetBonusRecordByCompanyOrgUnitList($CompanyID: String!, $QuaterID: String!, $Year: Float!) {
  GetBonusRecordByCompanyOrgUnitList(CompanyID: $CompanyID, QuaterID: $QuaterID, Year: $Year)
}
    `;

/**
 * __useGetBonusRecordByCompanyOrgUnitListQuery__
 *
 * To run a query within a React component, call `useGetBonusRecordByCompanyOrgUnitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBonusRecordByCompanyOrgUnitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBonusRecordByCompanyOrgUnitListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      QuaterID: // value for 'QuaterID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetBonusRecordByCompanyOrgUnitListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBonusRecordByCompanyOrgUnitListQuery, GetBonusRecordByCompanyOrgUnitListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBonusRecordByCompanyOrgUnitListQuery, GetBonusRecordByCompanyOrgUnitListQueryVariables>(GetBonusRecordByCompanyOrgUnitListDocument, baseOptions);
      }
export function useGetBonusRecordByCompanyOrgUnitListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBonusRecordByCompanyOrgUnitListQuery, GetBonusRecordByCompanyOrgUnitListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBonusRecordByCompanyOrgUnitListQuery, GetBonusRecordByCompanyOrgUnitListQueryVariables>(GetBonusRecordByCompanyOrgUnitListDocument, baseOptions);
        }
export type GetBonusRecordByCompanyOrgUnitListQueryHookResult = ReturnType<typeof useGetBonusRecordByCompanyOrgUnitListQuery>;
export type GetBonusRecordByCompanyOrgUnitListLazyQueryHookResult = ReturnType<typeof useGetBonusRecordByCompanyOrgUnitListLazyQuery>;
export type GetBonusRecordByCompanyOrgUnitListQueryResult = ApolloReactCommon.QueryResult<GetBonusRecordByCompanyOrgUnitListQuery, GetBonusRecordByCompanyOrgUnitListQueryVariables>;
export const BonusSimulationListingDocument = gql`
    query BonusSimulationListing($CompanyID: String!, $OrgUnitID: String!, $QuaterID: String!, $Year: Float!) {
  BonusSimulationListing(CompanyID: $CompanyID, OrgUnitID: $OrgUnitID, QuaterID: $QuaterID, Year: $Year) {
    BonusSimulationID
    OrgUnitID
    CompanyID
    QuaterID
    Year
    Version
    Rate
    QualifierID
    Months
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useBonusSimulationListingQuery__
 *
 * To run a query within a React component, call `useBonusSimulationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBonusSimulationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBonusSimulationListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      QuaterID: // value for 'QuaterID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useBonusSimulationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BonusSimulationListingQuery, BonusSimulationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BonusSimulationListingQuery, BonusSimulationListingQueryVariables>(BonusSimulationListingDocument, baseOptions);
      }
export function useBonusSimulationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BonusSimulationListingQuery, BonusSimulationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BonusSimulationListingQuery, BonusSimulationListingQueryVariables>(BonusSimulationListingDocument, baseOptions);
        }
export type BonusSimulationListingQueryHookResult = ReturnType<typeof useBonusSimulationListingQuery>;
export type BonusSimulationListingLazyQueryHookResult = ReturnType<typeof useBonusSimulationListingLazyQuery>;
export type BonusSimulationListingQueryResult = ApolloReactCommon.QueryResult<BonusSimulationListingQuery, BonusSimulationListingQueryVariables>;
export const BonusSimulationSubmitDocument = gql`
    mutation BonusSimulationSubmit($BonusSimulationInput: [BonusSimulationInput!]!) {
  BonusSimulationSubmit(BonusSimulationInput: $BonusSimulationInput)
}
    `;
export type BonusSimulationSubmitMutationFn = ApolloReactCommon.MutationFunction<BonusSimulationSubmitMutation, BonusSimulationSubmitMutationVariables>;

/**
 * __useBonusSimulationSubmitMutation__
 *
 * To run a mutation, you first call `useBonusSimulationSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBonusSimulationSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bonusSimulationSubmitMutation, { data, loading, error }] = useBonusSimulationSubmitMutation({
 *   variables: {
 *      BonusSimulationInput: // value for 'BonusSimulationInput'
 *   },
 * });
 */
export function useBonusSimulationSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BonusSimulationSubmitMutation, BonusSimulationSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<BonusSimulationSubmitMutation, BonusSimulationSubmitMutationVariables>(BonusSimulationSubmitDocument, baseOptions);
      }
export type BonusSimulationSubmitMutationHookResult = ReturnType<typeof useBonusSimulationSubmitMutation>;
export type BonusSimulationSubmitMutationResult = ApolloReactCommon.MutationResult<BonusSimulationSubmitMutation>;
export type BonusSimulationSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<BonusSimulationSubmitMutation, BonusSimulationSubmitMutationVariables>;
export const GetIncrementRecordByCompanyOrgUnitListDocument = gql`
    query GetIncrementRecordByCompanyOrgUnitList($CompanyID: String!, $QuaterID: String!, $Year: Float!) {
  GetIncrementRecordByCompanyOrgUnitList(CompanyID: $CompanyID, QuaterID: $QuaterID, Year: $Year)
}
    `;

/**
 * __useGetIncrementRecordByCompanyOrgUnitListQuery__
 *
 * To run a query within a React component, call `useGetIncrementRecordByCompanyOrgUnitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncrementRecordByCompanyOrgUnitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncrementRecordByCompanyOrgUnitListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      QuaterID: // value for 'QuaterID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetIncrementRecordByCompanyOrgUnitListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIncrementRecordByCompanyOrgUnitListQuery, GetIncrementRecordByCompanyOrgUnitListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIncrementRecordByCompanyOrgUnitListQuery, GetIncrementRecordByCompanyOrgUnitListQueryVariables>(GetIncrementRecordByCompanyOrgUnitListDocument, baseOptions);
      }
export function useGetIncrementRecordByCompanyOrgUnitListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIncrementRecordByCompanyOrgUnitListQuery, GetIncrementRecordByCompanyOrgUnitListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIncrementRecordByCompanyOrgUnitListQuery, GetIncrementRecordByCompanyOrgUnitListQueryVariables>(GetIncrementRecordByCompanyOrgUnitListDocument, baseOptions);
        }
export type GetIncrementRecordByCompanyOrgUnitListQueryHookResult = ReturnType<typeof useGetIncrementRecordByCompanyOrgUnitListQuery>;
export type GetIncrementRecordByCompanyOrgUnitListLazyQueryHookResult = ReturnType<typeof useGetIncrementRecordByCompanyOrgUnitListLazyQuery>;
export type GetIncrementRecordByCompanyOrgUnitListQueryResult = ApolloReactCommon.QueryResult<GetIncrementRecordByCompanyOrgUnitListQuery, GetIncrementRecordByCompanyOrgUnitListQueryVariables>;
export const IncrementSimulationListingDocument = gql`
    query IncrementSimulationListing($CompanyID: String!, $OrgUnitID: String!, $QuaterID: String!, $Year: Float!) {
  IncrementSimulationListing(CompanyID: $CompanyID, OrgUnitID: $OrgUnitID, QuaterID: $QuaterID, Year: $Year) {
    IncrementSimulationID
    OrgUnitID
    CompanyID
    QuaterID
    Year
    Version
    Rate
    QualifierID
    Percentage
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useIncrementSimulationListingQuery__
 *
 * To run a query within a React component, call `useIncrementSimulationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncrementSimulationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncrementSimulationListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      QuaterID: // value for 'QuaterID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useIncrementSimulationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IncrementSimulationListingQuery, IncrementSimulationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<IncrementSimulationListingQuery, IncrementSimulationListingQueryVariables>(IncrementSimulationListingDocument, baseOptions);
      }
export function useIncrementSimulationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IncrementSimulationListingQuery, IncrementSimulationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IncrementSimulationListingQuery, IncrementSimulationListingQueryVariables>(IncrementSimulationListingDocument, baseOptions);
        }
export type IncrementSimulationListingQueryHookResult = ReturnType<typeof useIncrementSimulationListingQuery>;
export type IncrementSimulationListingLazyQueryHookResult = ReturnType<typeof useIncrementSimulationListingLazyQuery>;
export type IncrementSimulationListingQueryResult = ApolloReactCommon.QueryResult<IncrementSimulationListingQuery, IncrementSimulationListingQueryVariables>;
export const IncrementSimulationSubmitDocument = gql`
    mutation IncrementSimulationSubmit($IncrementSimulationInput: [IncrementSimulationInput!]!) {
  IncrementSimulationSubmit(IncrementSimulationInput: $IncrementSimulationInput)
}
    `;
export type IncrementSimulationSubmitMutationFn = ApolloReactCommon.MutationFunction<IncrementSimulationSubmitMutation, IncrementSimulationSubmitMutationVariables>;

/**
 * __useIncrementSimulationSubmitMutation__
 *
 * To run a mutation, you first call `useIncrementSimulationSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementSimulationSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementSimulationSubmitMutation, { data, loading, error }] = useIncrementSimulationSubmitMutation({
 *   variables: {
 *      IncrementSimulationInput: // value for 'IncrementSimulationInput'
 *   },
 * });
 */
export function useIncrementSimulationSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IncrementSimulationSubmitMutation, IncrementSimulationSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<IncrementSimulationSubmitMutation, IncrementSimulationSubmitMutationVariables>(IncrementSimulationSubmitDocument, baseOptions);
      }
export type IncrementSimulationSubmitMutationHookResult = ReturnType<typeof useIncrementSimulationSubmitMutation>;
export type IncrementSimulationSubmitMutationResult = ApolloReactCommon.MutationResult<IncrementSimulationSubmitMutation>;
export type IncrementSimulationSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<IncrementSimulationSubmitMutation, IncrementSimulationSubmitMutationVariables>;
export const GetKpiPlanningDetailsDocument = gql`
    query GetKPIPlanningDetails($EmployeeKPIHeaderID: String) {
  GetKPIPlanningDetails(EmployeeKPIHeaderID: $EmployeeKPIHeaderID) {
    EmployeeKPICategoryID
    AppraisalCategoryID
    Weightage
    EmployeeKPIDetail {
      EmployeeKPIDetailID
      AppraisalCategoryItemID
      Weightage
      Title
      Description
      EmployeeKPIDetailRating {
        ScaleSequence
        Description
      }
    }
  }
}
    `;

/**
 * __useGetKpiPlanningDetailsQuery__
 *
 * To run a query within a React component, call `useGetKpiPlanningDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKpiPlanningDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpiPlanningDetailsQuery({
 *   variables: {
 *      EmployeeKPIHeaderID: // value for 'EmployeeKPIHeaderID'
 *   },
 * });
 */
export function useGetKpiPlanningDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetKpiPlanningDetailsQuery, GetKpiPlanningDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetKpiPlanningDetailsQuery, GetKpiPlanningDetailsQueryVariables>(GetKpiPlanningDetailsDocument, baseOptions);
      }
export function useGetKpiPlanningDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetKpiPlanningDetailsQuery, GetKpiPlanningDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetKpiPlanningDetailsQuery, GetKpiPlanningDetailsQueryVariables>(GetKpiPlanningDetailsDocument, baseOptions);
        }
export type GetKpiPlanningDetailsQueryHookResult = ReturnType<typeof useGetKpiPlanningDetailsQuery>;
export type GetKpiPlanningDetailsLazyQueryHookResult = ReturnType<typeof useGetKpiPlanningDetailsLazyQuery>;
export type GetKpiPlanningDetailsQueryResult = ApolloReactCommon.QueryResult<GetKpiPlanningDetailsQuery, GetKpiPlanningDetailsQueryVariables>;
export const GetEmployeeKpiPlanningRecordDocument = gql`
    query GetEmployeeKPIPlanningRecord($EmployeeID: String!, $Year: Float!) {
  GetEmployeeKPIPlanningRecord(EmployeeID: $EmployeeID, Year: $Year) {
    EmployeeID
    PlanningList {
      EmployeeKPIHeaderID
      Year
      Version
      AppraisalTempleteID
      SubscriptionAccountID
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
  }
}
    `;

/**
 * __useGetEmployeeKpiPlanningRecordQuery__
 *
 * To run a query within a React component, call `useGetEmployeeKpiPlanningRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeKpiPlanningRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeKpiPlanningRecordQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetEmployeeKpiPlanningRecordQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeKpiPlanningRecordQuery, GetEmployeeKpiPlanningRecordQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeKpiPlanningRecordQuery, GetEmployeeKpiPlanningRecordQueryVariables>(GetEmployeeKpiPlanningRecordDocument, baseOptions);
      }
export function useGetEmployeeKpiPlanningRecordLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeKpiPlanningRecordQuery, GetEmployeeKpiPlanningRecordQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeKpiPlanningRecordQuery, GetEmployeeKpiPlanningRecordQueryVariables>(GetEmployeeKpiPlanningRecordDocument, baseOptions);
        }
export type GetEmployeeKpiPlanningRecordQueryHookResult = ReturnType<typeof useGetEmployeeKpiPlanningRecordQuery>;
export type GetEmployeeKpiPlanningRecordLazyQueryHookResult = ReturnType<typeof useGetEmployeeKpiPlanningRecordLazyQuery>;
export type GetEmployeeKpiPlanningRecordQueryResult = ApolloReactCommon.QueryResult<GetEmployeeKpiPlanningRecordQuery, GetEmployeeKpiPlanningRecordQueryVariables>;
export const GetEmployeeKpiPlanningByYearDocument = gql`
    query GetEmployeeKPIPlanningByYear($CompanyID: String!, $Year: Float!) {
  GetEmployeeKPIPlanningByYear(CompanyID: $CompanyID, Year: $Year) {
    EmployeeID
    JobGradeID
    QuaterType
    OrgUnitID
    FormStatus
    PlanningList {
      EmployeeKPIHeaderID
      Year
      Version
      AppraisalTempleteID
      SubscriptionAccountID
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
    }
  }
}
    `;

/**
 * __useGetEmployeeKpiPlanningByYearQuery__
 *
 * To run a query within a React component, call `useGetEmployeeKpiPlanningByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeKpiPlanningByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeKpiPlanningByYearQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Year: // value for 'Year'
 *   },
 * });
 */
export function useGetEmployeeKpiPlanningByYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeKpiPlanningByYearQuery, GetEmployeeKpiPlanningByYearQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeKpiPlanningByYearQuery, GetEmployeeKpiPlanningByYearQueryVariables>(GetEmployeeKpiPlanningByYearDocument, baseOptions);
      }
export function useGetEmployeeKpiPlanningByYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeKpiPlanningByYearQuery, GetEmployeeKpiPlanningByYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeKpiPlanningByYearQuery, GetEmployeeKpiPlanningByYearQueryVariables>(GetEmployeeKpiPlanningByYearDocument, baseOptions);
        }
export type GetEmployeeKpiPlanningByYearQueryHookResult = ReturnType<typeof useGetEmployeeKpiPlanningByYearQuery>;
export type GetEmployeeKpiPlanningByYearLazyQueryHookResult = ReturnType<typeof useGetEmployeeKpiPlanningByYearLazyQuery>;
export type GetEmployeeKpiPlanningByYearQueryResult = ApolloReactCommon.QueryResult<GetEmployeeKpiPlanningByYearQuery, GetEmployeeKpiPlanningByYearQueryVariables>;
export const SubmitStaffKpiPlanningDocument = gql`
    mutation SubmitStaffKPIPlanning($Input: EmployeeKPIHeaderInput!, $Submmit_Type: String!, $LatestVersion: String) {
  SubmitStaffKPIPlanning(Input: $Input, Submmit_Type: $Submmit_Type, LatestVersion: $LatestVersion)
}
    `;
export type SubmitStaffKpiPlanningMutationFn = ApolloReactCommon.MutationFunction<SubmitStaffKpiPlanningMutation, SubmitStaffKpiPlanningMutationVariables>;

/**
 * __useSubmitStaffKpiPlanningMutation__
 *
 * To run a mutation, you first call `useSubmitStaffKpiPlanningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitStaffKpiPlanningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitStaffKpiPlanningMutation, { data, loading, error }] = useSubmitStaffKpiPlanningMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *      Submmit_Type: // value for 'Submmit_Type'
 *      LatestVersion: // value for 'LatestVersion'
 *   },
 * });
 */
export function useSubmitStaffKpiPlanningMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitStaffKpiPlanningMutation, SubmitStaffKpiPlanningMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitStaffKpiPlanningMutation, SubmitStaffKpiPlanningMutationVariables>(SubmitStaffKpiPlanningDocument, baseOptions);
      }
export type SubmitStaffKpiPlanningMutationHookResult = ReturnType<typeof useSubmitStaffKpiPlanningMutation>;
export type SubmitStaffKpiPlanningMutationResult = ApolloReactCommon.MutationResult<SubmitStaffKpiPlanningMutation>;
export type SubmitStaffKpiPlanningMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitStaffKpiPlanningMutation, SubmitStaffKpiPlanningMutationVariables>;
export const StaffKpiPlannigDeleteDocument = gql`
    mutation StaffKPIPlannigDelete($EmployeeKPIHeaderID: String!) {
  StaffKPIPlannigDelete(EmployeeKPIHeaderID: $EmployeeKPIHeaderID)
}
    `;
export type StaffKpiPlannigDeleteMutationFn = ApolloReactCommon.MutationFunction<StaffKpiPlannigDeleteMutation, StaffKpiPlannigDeleteMutationVariables>;

/**
 * __useStaffKpiPlannigDeleteMutation__
 *
 * To run a mutation, you first call `useStaffKpiPlannigDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffKpiPlannigDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffKpiPlannigDeleteMutation, { data, loading, error }] = useStaffKpiPlannigDeleteMutation({
 *   variables: {
 *      EmployeeKPIHeaderID: // value for 'EmployeeKPIHeaderID'
 *   },
 * });
 */
export function useStaffKpiPlannigDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffKpiPlannigDeleteMutation, StaffKpiPlannigDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffKpiPlannigDeleteMutation, StaffKpiPlannigDeleteMutationVariables>(StaffKpiPlannigDeleteDocument, baseOptions);
      }
export type StaffKpiPlannigDeleteMutationHookResult = ReturnType<typeof useStaffKpiPlannigDeleteMutation>;
export type StaffKpiPlannigDeleteMutationResult = ApolloReactCommon.MutationResult<StaffKpiPlannigDeleteMutation>;
export type StaffKpiPlannigDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffKpiPlannigDeleteMutation, StaffKpiPlannigDeleteMutationVariables>;
export const AppraisalCategoryListingDocument = gql`
    query AppraisalCategoryListing($AppraisalType: String!) {
  AppraisalCategoryListing(AppraisalType: $AppraisalType) {
    AppraisalCategoryID
    Name
    AppraisalType
    AppraisalCategoryItems {
      AppraisalCategoryItemID
      AppraisalCategoryID
      Name
      Description
      AppraisalItemType
      QuantitativeType
      SubscriptionAccountID
      Status
    }
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useAppraisalCategoryListingQuery__
 *
 * To run a query within a React component, call `useAppraisalCategoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppraisalCategoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppraisalCategoryListingQuery({
 *   variables: {
 *      AppraisalType: // value for 'AppraisalType'
 *   },
 * });
 */
export function useAppraisalCategoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AppraisalCategoryListingQuery, AppraisalCategoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AppraisalCategoryListingQuery, AppraisalCategoryListingQueryVariables>(AppraisalCategoryListingDocument, baseOptions);
      }
export function useAppraisalCategoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AppraisalCategoryListingQuery, AppraisalCategoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AppraisalCategoryListingQuery, AppraisalCategoryListingQueryVariables>(AppraisalCategoryListingDocument, baseOptions);
        }
export type AppraisalCategoryListingQueryHookResult = ReturnType<typeof useAppraisalCategoryListingQuery>;
export type AppraisalCategoryListingLazyQueryHookResult = ReturnType<typeof useAppraisalCategoryListingLazyQuery>;
export type AppraisalCategoryListingQueryResult = ApolloReactCommon.QueryResult<AppraisalCategoryListingQuery, AppraisalCategoryListingQueryVariables>;
export const SubordinateListingDocument = gql`
    query SubordinateListing($EmployeeID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!]) {
  SubordinateListing(EmployeeID: $EmployeeID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType) {
    OrgUnit {
      CompanyOrgUnitID
      Level
      Description
    }
    EmployeeID
    CompanyID
    EmployeeNo
    Contact {
      ContactID
      FullName
      MobileNo
      Email
    }
    Department {
      DepartmentID
      Description
    }
    Designation {
      JobDesignationID
      Name
    }
    Company {
      CompanyName
      CompanyID
    }
    EmploymentType
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    Documents {
      BucketFileName
      Description
      DocumentFile
      DocumentID
    }
  }
}
    `;

/**
 * __useSubordinateListingQuery__
 *
 * To run a query within a React component, call `useSubordinateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubordinateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubordinateListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *   },
 * });
 */
export function useSubordinateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubordinateListingQuery, SubordinateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SubordinateListingQuery, SubordinateListingQueryVariables>(SubordinateListingDocument, baseOptions);
      }
export function useSubordinateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubordinateListingQuery, SubordinateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubordinateListingQuery, SubordinateListingQueryVariables>(SubordinateListingDocument, baseOptions);
        }
export type SubordinateListingQueryHookResult = ReturnType<typeof useSubordinateListingQuery>;
export type SubordinateListingLazyQueryHookResult = ReturnType<typeof useSubordinateListingLazyQuery>;
export type SubordinateListingQueryResult = ApolloReactCommon.QueryResult<SubordinateListingQuery, SubordinateListingQueryVariables>;
export const CareerLogListingDocument = gql`
    query CareerLogListing($EmployeeID: String!) {
  CareerLogListing(EmployeeID: $EmployeeID) {
    CareerLogID
    EmployeeID
    FromCompany
    FromCompanyTable {
      CompanyName
    }
    ToCompany
    ToCompanyTable {
      CompanyName
    }
    FromDepartment
    ToDepartment
    FromDivision
    Employee {
      JoinedDate
    }
    ToDivision
    FromPosition
    ToPosition
    FromJobGrade
    ToJobGrade
    FromSalary
    ToSalary
    ToOrgUnit
    SalaryAdjustmentAmount
    EffectiveDate
    DocumentID
    ReportTo
    Remark
    MovementType
    Status
    SalaryType
    ExitReason
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ToPositionTable {
      JobDesignationID
      Name
    }
    ToDepartmentTable {
      DepartmentID
      Description
    }
    ToOrgUnitTable {
      Description
    }
    Document {
      DocumentID
      Description
      DocumentFile
      DocumentType
    }
    JoinedDate
    PrevConfirmedDate
  }
}
    `;

/**
 * __useCareerLogListingQuery__
 *
 * To run a query within a React component, call `useCareerLogListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerLogListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerLogListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useCareerLogListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CareerLogListingQuery, CareerLogListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CareerLogListingQuery, CareerLogListingQueryVariables>(CareerLogListingDocument, baseOptions);
      }
export function useCareerLogListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CareerLogListingQuery, CareerLogListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CareerLogListingQuery, CareerLogListingQueryVariables>(CareerLogListingDocument, baseOptions);
        }
export type CareerLogListingQueryHookResult = ReturnType<typeof useCareerLogListingQuery>;
export type CareerLogListingLazyQueryHookResult = ReturnType<typeof useCareerLogListingLazyQuery>;
export type CareerLogListingQueryResult = ApolloReactCommon.QueryResult<CareerLogListingQuery, CareerLogListingQueryVariables>;
export const GetOrgChartDocument = gql`
    query GetOrgChart($EmployeeID: String!) {
  GetOrgChart(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useGetOrgChartQuery__
 *
 * To run a query within a React component, call `useGetOrgChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrgChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrgChartQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetOrgChartQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOrgChartQuery, GetOrgChartQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOrgChartQuery, GetOrgChartQueryVariables>(GetOrgChartDocument, baseOptions);
      }
export function useGetOrgChartLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrgChartQuery, GetOrgChartQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOrgChartQuery, GetOrgChartQueryVariables>(GetOrgChartDocument, baseOptions);
        }
export type GetOrgChartQueryHookResult = ReturnType<typeof useGetOrgChartQuery>;
export type GetOrgChartLazyQueryHookResult = ReturnType<typeof useGetOrgChartLazyQuery>;
export type GetOrgChartQueryResult = ApolloReactCommon.QueryResult<GetOrgChartQuery, GetOrgChartQueryVariables>;
export const SubordinateMenuSummaryDocument = gql`
    query SubordinateMenuSummary($EmployeeIDs: [String!]!, $ApproverID: String!) {
  SubordinateMenuSummary(EmployeeIDs: $EmployeeIDs, ApproverID: $ApproverID)
}
    `;

/**
 * __useSubordinateMenuSummaryQuery__
 *
 * To run a query within a React component, call `useSubordinateMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubordinateMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubordinateMenuSummaryQuery({
 *   variables: {
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useSubordinateMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubordinateMenuSummaryQuery, SubordinateMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<SubordinateMenuSummaryQuery, SubordinateMenuSummaryQueryVariables>(SubordinateMenuSummaryDocument, baseOptions);
      }
export function useSubordinateMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubordinateMenuSummaryQuery, SubordinateMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubordinateMenuSummaryQuery, SubordinateMenuSummaryQueryVariables>(SubordinateMenuSummaryDocument, baseOptions);
        }
export type SubordinateMenuSummaryQueryHookResult = ReturnType<typeof useSubordinateMenuSummaryQuery>;
export type SubordinateMenuSummaryLazyQueryHookResult = ReturnType<typeof useSubordinateMenuSummaryLazyQuery>;
export type SubordinateMenuSummaryQueryResult = ApolloReactCommon.QueryResult<SubordinateMenuSummaryQuery, SubordinateMenuSummaryQueryVariables>;
export const SubordinateTreeDocument = gql`
    query SubordinateTree($EmployeeID: String!, $ModuleName: String, $level: Float) {
  SubordinateTree(EmployeeID: $EmployeeID, ModuleName: $ModuleName, level: $level)
}
    `;

/**
 * __useSubordinateTreeQuery__
 *
 * To run a query within a React component, call `useSubordinateTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubordinateTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubordinateTreeQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      ModuleName: // value for 'ModuleName'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useSubordinateTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubordinateTreeQuery, SubordinateTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<SubordinateTreeQuery, SubordinateTreeQueryVariables>(SubordinateTreeDocument, baseOptions);
      }
export function useSubordinateTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubordinateTreeQuery, SubordinateTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubordinateTreeQuery, SubordinateTreeQueryVariables>(SubordinateTreeDocument, baseOptions);
        }
export type SubordinateTreeQueryHookResult = ReturnType<typeof useSubordinateTreeQuery>;
export type SubordinateTreeLazyQueryHookResult = ReturnType<typeof useSubordinateTreeLazyQuery>;
export type SubordinateTreeQueryResult = ApolloReactCommon.QueryResult<SubordinateTreeQuery, SubordinateTreeQueryVariables>;
export const SubordinateTreeTimeAttendanceDocument = gql`
    query SubordinateTreeTimeAttendance($EmployeeID: String!, $ModuleName: String, $level: Float, $AccountID: String, $CompanyID: String) {
  SubordinateTreeTimeAttendance(EmployeeID: $EmployeeID, ModuleName: $ModuleName, level: $level, AccountID: $AccountID, CompanyID: $CompanyID)
}
    `;

/**
 * __useSubordinateTreeTimeAttendanceQuery__
 *
 * To run a query within a React component, call `useSubordinateTreeTimeAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubordinateTreeTimeAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubordinateTreeTimeAttendanceQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      ModuleName: // value for 'ModuleName'
 *      level: // value for 'level'
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useSubordinateTreeTimeAttendanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubordinateTreeTimeAttendanceQuery, SubordinateTreeTimeAttendanceQueryVariables>) {
        return ApolloReactHooks.useQuery<SubordinateTreeTimeAttendanceQuery, SubordinateTreeTimeAttendanceQueryVariables>(SubordinateTreeTimeAttendanceDocument, baseOptions);
      }
export function useSubordinateTreeTimeAttendanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubordinateTreeTimeAttendanceQuery, SubordinateTreeTimeAttendanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubordinateTreeTimeAttendanceQuery, SubordinateTreeTimeAttendanceQueryVariables>(SubordinateTreeTimeAttendanceDocument, baseOptions);
        }
export type SubordinateTreeTimeAttendanceQueryHookResult = ReturnType<typeof useSubordinateTreeTimeAttendanceQuery>;
export type SubordinateTreeTimeAttendanceLazyQueryHookResult = ReturnType<typeof useSubordinateTreeTimeAttendanceLazyQuery>;
export type SubordinateTreeTimeAttendanceQueryResult = ApolloReactCommon.QueryResult<SubordinateTreeTimeAttendanceQuery, SubordinateTreeTimeAttendanceQueryVariables>;
export const EmployeeAppSummaryDocument = gql`
    query EmployeeAppSummary($CompanyID: String, $EmployeeID: String!, $CompanyIDs: [String!]) {
  EmployeeAppSummary(CompanyID: $CompanyID, EmployeeID: $EmployeeID, CompanyIDs: $CompanyIDs)
}
    `;

/**
 * __useEmployeeAppSummaryQuery__
 *
 * To run a query within a React component, call `useEmployeeAppSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAppSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeAppSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyIDs: // value for 'CompanyIDs'
 *   },
 * });
 */
export function useEmployeeAppSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeAppSummaryQuery, EmployeeAppSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeAppSummaryQuery, EmployeeAppSummaryQueryVariables>(EmployeeAppSummaryDocument, baseOptions);
      }
export function useEmployeeAppSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeAppSummaryQuery, EmployeeAppSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeAppSummaryQuery, EmployeeAppSummaryQueryVariables>(EmployeeAppSummaryDocument, baseOptions);
        }
export type EmployeeAppSummaryQueryHookResult = ReturnType<typeof useEmployeeAppSummaryQuery>;
export type EmployeeAppSummaryLazyQueryHookResult = ReturnType<typeof useEmployeeAppSummaryLazyQuery>;
export type EmployeeAppSummaryQueryResult = ApolloReactCommon.QueryResult<EmployeeAppSummaryQuery, EmployeeAppSummaryQueryVariables>;
export const SystemAdminCompanyStatutoryDetailDocument = gql`
    query SystemAdminCompanyStatutoryDetail($CompanyID: String!) {
  CompanyStatutoryDetail(CompanyID: $CompanyID) {
    SignByCompany {
      CompanyID
      CompanyName
    }
    CompanyStatutoryID
    SignBy
    EPFNo
    SocsoNo
    PCBNo
    EISNo
    IsAngkasa
    AngkasaNo
    IsASB
    ASBNo
    IsKWAP
    KWAPNo
    IsTBH
    TBHNo
    IsZakat
    ZakatNo
    HRDFNo
    HRDFRate
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    EPFAccount {
      AccountNo
      Description
      IsDefault
    }
    SocsoAccount {
      AccountNo
      Description
      IsDefault
    }
    PCBAccount {
      AccountNo
      Description
      IsDefault
    }
    EISAccount {
      AccountNo
      Description
      IsDefault
    }
  }
}
    `;

/**
 * __useSystemAdminCompanyStatutoryDetailQuery__
 *
 * To run a query within a React component, call `useSystemAdminCompanyStatutoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemAdminCompanyStatutoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemAdminCompanyStatutoryDetailQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useSystemAdminCompanyStatutoryDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SystemAdminCompanyStatutoryDetailQuery, SystemAdminCompanyStatutoryDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<SystemAdminCompanyStatutoryDetailQuery, SystemAdminCompanyStatutoryDetailQueryVariables>(SystemAdminCompanyStatutoryDetailDocument, baseOptions);
      }
export function useSystemAdminCompanyStatutoryDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SystemAdminCompanyStatutoryDetailQuery, SystemAdminCompanyStatutoryDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SystemAdminCompanyStatutoryDetailQuery, SystemAdminCompanyStatutoryDetailQueryVariables>(SystemAdminCompanyStatutoryDetailDocument, baseOptions);
        }
export type SystemAdminCompanyStatutoryDetailQueryHookResult = ReturnType<typeof useSystemAdminCompanyStatutoryDetailQuery>;
export type SystemAdminCompanyStatutoryDetailLazyQueryHookResult = ReturnType<typeof useSystemAdminCompanyStatutoryDetailLazyQuery>;
export type SystemAdminCompanyStatutoryDetailQueryResult = ApolloReactCommon.QueryResult<SystemAdminCompanyStatutoryDetailQuery, SystemAdminCompanyStatutoryDetailQueryVariables>;
export const CreateCompanyAccessPermDocument = gql`
    mutation CreateCompanyAccessPerm($Input: [CompanyAccessPermInput!]!, $CompanyID: String!, $RoleID: String!) {
  CreateCompanyAccessPerm(input: $Input, CompanyID: $CompanyID, RoleID: $RoleID)
}
    `;
export type CreateCompanyAccessPermMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyAccessPermMutation, CreateCompanyAccessPermMutationVariables>;

/**
 * __useCreateCompanyAccessPermMutation__
 *
 * To run a mutation, you first call `useCreateCompanyAccessPermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyAccessPermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyAccessPermMutation, { data, loading, error }] = useCreateCompanyAccessPermMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *      CompanyID: // value for 'CompanyID'
 *      RoleID: // value for 'RoleID'
 *   },
 * });
 */
export function useCreateCompanyAccessPermMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyAccessPermMutation, CreateCompanyAccessPermMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyAccessPermMutation, CreateCompanyAccessPermMutationVariables>(CreateCompanyAccessPermDocument, baseOptions);
      }
export type CreateCompanyAccessPermMutationHookResult = ReturnType<typeof useCreateCompanyAccessPermMutation>;
export type CreateCompanyAccessPermMutationResult = ApolloReactCommon.MutationResult<CreateCompanyAccessPermMutation>;
export type CreateCompanyAccessPermMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyAccessPermMutation, CreateCompanyAccessPermMutationVariables>;
export const UserRoleIDsDocument = gql`
    query userRoleIDs($CompanyID: String!) {
  userRoleIDs(companyID: $CompanyID) {
    roleID
    userID
  }
}
    `;

/**
 * __useUserRoleIDsQuery__
 *
 * To run a query within a React component, call `useUserRoleIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleIDsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useUserRoleIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserRoleIDsQuery, UserRoleIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserRoleIDsQuery, UserRoleIDsQueryVariables>(UserRoleIDsDocument, baseOptions);
      }
export function useUserRoleIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRoleIDsQuery, UserRoleIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserRoleIDsQuery, UserRoleIDsQueryVariables>(UserRoleIDsDocument, baseOptions);
        }
export type UserRoleIDsQueryHookResult = ReturnType<typeof useUserRoleIDsQuery>;
export type UserRoleIDsLazyQueryHookResult = ReturnType<typeof useUserRoleIDsLazyQuery>;
export type UserRoleIDsQueryResult = ApolloReactCommon.QueryResult<UserRoleIDsQuery, UserRoleIDsQueryVariables>;
export const DocumentNumberAssignmentDocument = gql`
    mutation DocumentNumberAssignment($input: [DocumentNumberAssignmentInput!]!) {
  DocumentNumberAssignment(input: $input)
}
    `;
export type DocumentNumberAssignmentMutationFn = ApolloReactCommon.MutationFunction<DocumentNumberAssignmentMutation, DocumentNumberAssignmentMutationVariables>;

/**
 * __useDocumentNumberAssignmentMutation__
 *
 * To run a mutation, you first call `useDocumentNumberAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentNumberAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentNumberAssignmentMutation, { data, loading, error }] = useDocumentNumberAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentNumberAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentNumberAssignmentMutation, DocumentNumberAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentNumberAssignmentMutation, DocumentNumberAssignmentMutationVariables>(DocumentNumberAssignmentDocument, baseOptions);
      }
export type DocumentNumberAssignmentMutationHookResult = ReturnType<typeof useDocumentNumberAssignmentMutation>;
export type DocumentNumberAssignmentMutationResult = ApolloReactCommon.MutationResult<DocumentNumberAssignmentMutation>;
export type DocumentNumberAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentNumberAssignmentMutation, DocumentNumberAssignmentMutationVariables>;
export const NewDocumentNumberingInsertDocument = gql`
    mutation NewDocumentNumberingInsert($input: NewDocumentNumberingInsertInput!) {
  NewDocumentNumberingInsert(input: $input)
}
    `;
export type NewDocumentNumberingInsertMutationFn = ApolloReactCommon.MutationFunction<NewDocumentNumberingInsertMutation, NewDocumentNumberingInsertMutationVariables>;

/**
 * __useNewDocumentNumberingInsertMutation__
 *
 * To run a mutation, you first call `useNewDocumentNumberingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDocumentNumberingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDocumentNumberingInsertMutation, { data, loading, error }] = useNewDocumentNumberingInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNewDocumentNumberingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewDocumentNumberingInsertMutation, NewDocumentNumberingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<NewDocumentNumberingInsertMutation, NewDocumentNumberingInsertMutationVariables>(NewDocumentNumberingInsertDocument, baseOptions);
      }
export type NewDocumentNumberingInsertMutationHookResult = ReturnType<typeof useNewDocumentNumberingInsertMutation>;
export type NewDocumentNumberingInsertMutationResult = ApolloReactCommon.MutationResult<NewDocumentNumberingInsertMutation>;
export type NewDocumentNumberingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<NewDocumentNumberingInsertMutation, NewDocumentNumberingInsertMutationVariables>;
export const CompanyDocumentNumberingUpdateDocument = gql`
    mutation CompanyDocumentNumberingUpdate($input: NewDocumentNumberingInsertInput!) {
  CompanyDocumentNumberingUpdate(input: $input)
}
    `;
export type CompanyDocumentNumberingUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyDocumentNumberingUpdateMutation, CompanyDocumentNumberingUpdateMutationVariables>;

/**
 * __useCompanyDocumentNumberingUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyDocumentNumberingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyDocumentNumberingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyDocumentNumberingUpdateMutation, { data, loading, error }] = useCompanyDocumentNumberingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyDocumentNumberingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyDocumentNumberingUpdateMutation, CompanyDocumentNumberingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyDocumentNumberingUpdateMutation, CompanyDocumentNumberingUpdateMutationVariables>(CompanyDocumentNumberingUpdateDocument, baseOptions);
      }
export type CompanyDocumentNumberingUpdateMutationHookResult = ReturnType<typeof useCompanyDocumentNumberingUpdateMutation>;
export type CompanyDocumentNumberingUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyDocumentNumberingUpdateMutation>;
export type CompanyDocumentNumberingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyDocumentNumberingUpdateMutation, CompanyDocumentNumberingUpdateMutationVariables>;
export const DocumentNumberingAssignmentListDocument = gql`
    query DocumentNumberingAssignmentList($CompanyID: String!) {
  DocumentNumberingAssignmentList(CompanyID: $CompanyID)
}
    `;

/**
 * __useDocumentNumberingAssignmentListQuery__
 *
 * To run a query within a React component, call `useDocumentNumberingAssignmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentNumberingAssignmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentNumberingAssignmentListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDocumentNumberingAssignmentListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentNumberingAssignmentListQuery, DocumentNumberingAssignmentListQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentNumberingAssignmentListQuery, DocumentNumberingAssignmentListQueryVariables>(DocumentNumberingAssignmentListDocument, baseOptions);
      }
export function useDocumentNumberingAssignmentListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentNumberingAssignmentListQuery, DocumentNumberingAssignmentListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentNumberingAssignmentListQuery, DocumentNumberingAssignmentListQueryVariables>(DocumentNumberingAssignmentListDocument, baseOptions);
        }
export type DocumentNumberingAssignmentListQueryHookResult = ReturnType<typeof useDocumentNumberingAssignmentListQuery>;
export type DocumentNumberingAssignmentListLazyQueryHookResult = ReturnType<typeof useDocumentNumberingAssignmentListLazyQuery>;
export type DocumentNumberingAssignmentListQueryResult = ApolloReactCommon.QueryResult<DocumentNumberingAssignmentListQuery, DocumentNumberingAssignmentListQueryVariables>;
export const CompanyStructureInsertDocument = gql`
    mutation CompanyStructureInsert($Input: [CompanyHierarchyInput!]!) {
  CompanyStructureInsert(input: $Input)
}
    `;
export type CompanyStructureInsertMutationFn = ApolloReactCommon.MutationFunction<CompanyStructureInsertMutation, CompanyStructureInsertMutationVariables>;

/**
 * __useCompanyStructureInsertMutation__
 *
 * To run a mutation, you first call `useCompanyStructureInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyStructureInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyStructureInsertMutation, { data, loading, error }] = useCompanyStructureInsertMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useCompanyStructureInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyStructureInsertMutation, CompanyStructureInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyStructureInsertMutation, CompanyStructureInsertMutationVariables>(CompanyStructureInsertDocument, baseOptions);
      }
export type CompanyStructureInsertMutationHookResult = ReturnType<typeof useCompanyStructureInsertMutation>;
export type CompanyStructureInsertMutationResult = ApolloReactCommon.MutationResult<CompanyStructureInsertMutation>;
export type CompanyStructureInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyStructureInsertMutation, CompanyStructureInsertMutationVariables>;
export const EmployeeListingBySubscriptionDocument = gql`
    query EmployeeListingBySubscription($SubscriptionAccountID: String!, $DepartmentIDs: [String!]!, $EmployeeStatus: [String!]!, $EmployeeType: [String!]!) {
  CompanyListing(SubscriptionAccountID: $SubscriptionAccountID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType) {
    CompanyID
    CompanyName
    DocumentID
    Documents {
      DocumentID
      BucketFileName
      DocumentFile
      Description
    }
    Employees {
      EmployeeID
      EmployeeNo
      DepartmentID
      DivisionID
      JobGradeID
      Contact {
        ContactID
        FullName
        MobileNo
        Email
      }
      EmployeeStatutory {
        EmployeeStatutoryID
      }
      EmployeePrevContribution {
        EmployeePrevContributionID
      }
      EmployeeDependents {
        EmployeeDependentsID
      }
      Department {
        DepartmentID
        DepartmentType
        ParentID
        Description
      }
      Designation {
        JobDesignationID
        Name
      }
      DocumentID
      Documents {
        DocumentID
        BucketFileName
        DocumentFile
        Description
      }
      EmploymentType
      CreatedDT
      ModifiedDT
    }
  }
}
    `;

/**
 * __useEmployeeListingBySubscriptionQuery__
 *
 * To run a query within a React component, call `useEmployeeListingBySubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListingBySubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListingBySubscriptionQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *   },
 * });
 */
export function useEmployeeListingBySubscriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListingBySubscriptionQuery, EmployeeListingBySubscriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListingBySubscriptionQuery, EmployeeListingBySubscriptionQueryVariables>(EmployeeListingBySubscriptionDocument, baseOptions);
      }
export function useEmployeeListingBySubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListingBySubscriptionQuery, EmployeeListingBySubscriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListingBySubscriptionQuery, EmployeeListingBySubscriptionQueryVariables>(EmployeeListingBySubscriptionDocument, baseOptions);
        }
export type EmployeeListingBySubscriptionQueryHookResult = ReturnType<typeof useEmployeeListingBySubscriptionQuery>;
export type EmployeeListingBySubscriptionLazyQueryHookResult = ReturnType<typeof useEmployeeListingBySubscriptionLazyQuery>;
export type EmployeeListingBySubscriptionQueryResult = ApolloReactCommon.QueryResult<EmployeeListingBySubscriptionQuery, EmployeeListingBySubscriptionQueryVariables>;
export const AssignJobPositionDocument = gql`
    mutation AssignJobPosition($Input: [JobPositionAssignmentInput!]!, $CompanyID: String, $OrgUnitID: String) {
  AssignJobPosition(input: $Input, CompanyID: $CompanyID, OrgUnitID: $OrgUnitID)
}
    `;
export type AssignJobPositionMutationFn = ApolloReactCommon.MutationFunction<AssignJobPositionMutation, AssignJobPositionMutationVariables>;

/**
 * __useAssignJobPositionMutation__
 *
 * To run a mutation, you first call `useAssignJobPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignJobPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignJobPositionMutation, { data, loading, error }] = useAssignJobPositionMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *      CompanyID: // value for 'CompanyID'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useAssignJobPositionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignJobPositionMutation, AssignJobPositionMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignJobPositionMutation, AssignJobPositionMutationVariables>(AssignJobPositionDocument, baseOptions);
      }
export type AssignJobPositionMutationHookResult = ReturnType<typeof useAssignJobPositionMutation>;
export type AssignJobPositionMutationResult = ApolloReactCommon.MutationResult<AssignJobPositionMutation>;
export type AssignJobPositionMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignJobPositionMutation, AssignJobPositionMutationVariables>;
export const JobPositionByOrgUnitListDocument = gql`
    query JobPositionByOrgUnitList($CompanyID: String!, $OrgUnitID: String) {
  JobPositionAssignmentList(CompanyID: $CompanyID, OrgUnitID: $OrgUnitID)
}
    `;

/**
 * __useJobPositionByOrgUnitListQuery__
 *
 * To run a query within a React component, call `useJobPositionByOrgUnitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPositionByOrgUnitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPositionByOrgUnitListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useJobPositionByOrgUnitListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPositionByOrgUnitListQuery, JobPositionByOrgUnitListQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPositionByOrgUnitListQuery, JobPositionByOrgUnitListQueryVariables>(JobPositionByOrgUnitListDocument, baseOptions);
      }
export function useJobPositionByOrgUnitListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPositionByOrgUnitListQuery, JobPositionByOrgUnitListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPositionByOrgUnitListQuery, JobPositionByOrgUnitListQueryVariables>(JobPositionByOrgUnitListDocument, baseOptions);
        }
export type JobPositionByOrgUnitListQueryHookResult = ReturnType<typeof useJobPositionByOrgUnitListQuery>;
export type JobPositionByOrgUnitListLazyQueryHookResult = ReturnType<typeof useJobPositionByOrgUnitListLazyQuery>;
export type JobPositionByOrgUnitListQueryResult = ApolloReactCommon.QueryResult<JobPositionByOrgUnitListQuery, JobPositionByOrgUnitListQueryVariables>;
export const JobPositionByCompanyIdArrayDocument = gql`
    query JobPositionByCompanyIDArray($CompanyIDArray: [String!]) {
  JobPositionAssignmentList(CompanyIDArray: $CompanyIDArray)
}
    `;

/**
 * __useJobPositionByCompanyIdArrayQuery__
 *
 * To run a query within a React component, call `useJobPositionByCompanyIdArrayQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPositionByCompanyIdArrayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPositionByCompanyIdArrayQuery({
 *   variables: {
 *      CompanyIDArray: // value for 'CompanyIDArray'
 *   },
 * });
 */
export function useJobPositionByCompanyIdArrayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPositionByCompanyIdArrayQuery, JobPositionByCompanyIdArrayQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPositionByCompanyIdArrayQuery, JobPositionByCompanyIdArrayQueryVariables>(JobPositionByCompanyIdArrayDocument, baseOptions);
      }
export function useJobPositionByCompanyIdArrayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPositionByCompanyIdArrayQuery, JobPositionByCompanyIdArrayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPositionByCompanyIdArrayQuery, JobPositionByCompanyIdArrayQueryVariables>(JobPositionByCompanyIdArrayDocument, baseOptions);
        }
export type JobPositionByCompanyIdArrayQueryHookResult = ReturnType<typeof useJobPositionByCompanyIdArrayQuery>;
export type JobPositionByCompanyIdArrayLazyQueryHookResult = ReturnType<typeof useJobPositionByCompanyIdArrayLazyQuery>;
export type JobPositionByCompanyIdArrayQueryResult = ApolloReactCommon.QueryResult<JobPositionByCompanyIdArrayQuery, JobPositionByCompanyIdArrayQueryVariables>;
export const CompanyOrgUnitByCompanyIdArrayDocument = gql`
    query CompanyOrgUnitByCompanyIDArray($CompanyIDArray: [String!]) {
  CompanyOrgUnitList(CompanyIDArray: $CompanyIDArray)
}
    `;

/**
 * __useCompanyOrgUnitByCompanyIdArrayQuery__
 *
 * To run a query within a React component, call `useCompanyOrgUnitByCompanyIdArrayQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyOrgUnitByCompanyIdArrayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyOrgUnitByCompanyIdArrayQuery({
 *   variables: {
 *      CompanyIDArray: // value for 'CompanyIDArray'
 *   },
 * });
 */
export function useCompanyOrgUnitByCompanyIdArrayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>(CompanyOrgUnitByCompanyIdArrayDocument, baseOptions);
      }
export function useCompanyOrgUnitByCompanyIdArrayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>(CompanyOrgUnitByCompanyIdArrayDocument, baseOptions);
        }
export type CompanyOrgUnitByCompanyIdArrayQueryHookResult = ReturnType<typeof useCompanyOrgUnitByCompanyIdArrayQuery>;
export type CompanyOrgUnitByCompanyIdArrayLazyQueryHookResult = ReturnType<typeof useCompanyOrgUnitByCompanyIdArrayLazyQuery>;
export type CompanyOrgUnitByCompanyIdArrayQueryResult = ApolloReactCommon.QueryResult<CompanyOrgUnitByCompanyIdArrayQuery, CompanyOrgUnitByCompanyIdArrayQueryVariables>;
export const CompanyHierarchyByCompanyIdArrayDocument = gql`
    query CompanyHierarchyByCompanyIDArray($CompanyIDArray: [String!]) {
  CompanyHierarchyList(CompanyIDArray: $CompanyIDArray)
}
    `;

/**
 * __useCompanyHierarchyByCompanyIdArrayQuery__
 *
 * To run a query within a React component, call `useCompanyHierarchyByCompanyIdArrayQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyHierarchyByCompanyIdArrayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyHierarchyByCompanyIdArrayQuery({
 *   variables: {
 *      CompanyIDArray: // value for 'CompanyIDArray'
 *   },
 * });
 */
export function useCompanyHierarchyByCompanyIdArrayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyHierarchyByCompanyIdArrayQuery, CompanyHierarchyByCompanyIdArrayQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyHierarchyByCompanyIdArrayQuery, CompanyHierarchyByCompanyIdArrayQueryVariables>(CompanyHierarchyByCompanyIdArrayDocument, baseOptions);
      }
export function useCompanyHierarchyByCompanyIdArrayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyHierarchyByCompanyIdArrayQuery, CompanyHierarchyByCompanyIdArrayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyHierarchyByCompanyIdArrayQuery, CompanyHierarchyByCompanyIdArrayQueryVariables>(CompanyHierarchyByCompanyIdArrayDocument, baseOptions);
        }
export type CompanyHierarchyByCompanyIdArrayQueryHookResult = ReturnType<typeof useCompanyHierarchyByCompanyIdArrayQuery>;
export type CompanyHierarchyByCompanyIdArrayLazyQueryHookResult = ReturnType<typeof useCompanyHierarchyByCompanyIdArrayLazyQuery>;
export type CompanyHierarchyByCompanyIdArrayQueryResult = ApolloReactCommon.QueryResult<CompanyHierarchyByCompanyIdArrayQuery, CompanyHierarchyByCompanyIdArrayQueryVariables>;
export const CompanyOrgUnitInsertDocument = gql`
    mutation CompanyOrgUnitInsert($Input: [CompanyOrgUnitInput!]!) {
  CompanyOrgUnitInsert(input: $Input)
}
    `;
export type CompanyOrgUnitInsertMutationFn = ApolloReactCommon.MutationFunction<CompanyOrgUnitInsertMutation, CompanyOrgUnitInsertMutationVariables>;

/**
 * __useCompanyOrgUnitInsertMutation__
 *
 * To run a mutation, you first call `useCompanyOrgUnitInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyOrgUnitInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyOrgUnitInsertMutation, { data, loading, error }] = useCompanyOrgUnitInsertMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useCompanyOrgUnitInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyOrgUnitInsertMutation, CompanyOrgUnitInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyOrgUnitInsertMutation, CompanyOrgUnitInsertMutationVariables>(CompanyOrgUnitInsertDocument, baseOptions);
      }
export type CompanyOrgUnitInsertMutationHookResult = ReturnType<typeof useCompanyOrgUnitInsertMutation>;
export type CompanyOrgUnitInsertMutationResult = ApolloReactCommon.MutationResult<CompanyOrgUnitInsertMutation>;
export type CompanyOrgUnitInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyOrgUnitInsertMutation, CompanyOrgUnitInsertMutationVariables>;
export const CompanyOrgUnitUpdateDocument = gql`
    mutation CompanyOrgUnitUpdate($Inputs: [CompanyOrgUnitInput!]!, $OrgUnitIDs: [String!]!) {
  CompanyOrgUnitUpdate(Inputs: $Inputs, OrgUnitIDs: $OrgUnitIDs)
}
    `;
export type CompanyOrgUnitUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyOrgUnitUpdateMutation, CompanyOrgUnitUpdateMutationVariables>;

/**
 * __useCompanyOrgUnitUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyOrgUnitUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyOrgUnitUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyOrgUnitUpdateMutation, { data, loading, error }] = useCompanyOrgUnitUpdateMutation({
 *   variables: {
 *      Inputs: // value for 'Inputs'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *   },
 * });
 */
export function useCompanyOrgUnitUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyOrgUnitUpdateMutation, CompanyOrgUnitUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyOrgUnitUpdateMutation, CompanyOrgUnitUpdateMutationVariables>(CompanyOrgUnitUpdateDocument, baseOptions);
      }
export type CompanyOrgUnitUpdateMutationHookResult = ReturnType<typeof useCompanyOrgUnitUpdateMutation>;
export type CompanyOrgUnitUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyOrgUnitUpdateMutation>;
export type CompanyOrgUnitUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyOrgUnitUpdateMutation, CompanyOrgUnitUpdateMutationVariables>;
export const JobPositionByOrgUnitListingDocument = gql`
    query JobPositionByOrgUnitListing($CompanyID: String!) {
  JobPositionByOrgUnitListing(CompanyID: $CompanyID)
}
    `;

/**
 * __useJobPositionByOrgUnitListingQuery__
 *
 * To run a query within a React component, call `useJobPositionByOrgUnitListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPositionByOrgUnitListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPositionByOrgUnitListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useJobPositionByOrgUnitListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobPositionByOrgUnitListingQuery, JobPositionByOrgUnitListingQueryVariables>) {
        return ApolloReactHooks.useQuery<JobPositionByOrgUnitListingQuery, JobPositionByOrgUnitListingQueryVariables>(JobPositionByOrgUnitListingDocument, baseOptions);
      }
export function useJobPositionByOrgUnitListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobPositionByOrgUnitListingQuery, JobPositionByOrgUnitListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobPositionByOrgUnitListingQuery, JobPositionByOrgUnitListingQueryVariables>(JobPositionByOrgUnitListingDocument, baseOptions);
        }
export type JobPositionByOrgUnitListingQueryHookResult = ReturnType<typeof useJobPositionByOrgUnitListingQuery>;
export type JobPositionByOrgUnitListingLazyQueryHookResult = ReturnType<typeof useJobPositionByOrgUnitListingLazyQuery>;
export type JobPositionByOrgUnitListingQueryResult = ApolloReactCommon.QueryResult<JobPositionByOrgUnitListingQuery, JobPositionByOrgUnitListingQueryVariables>;
export const CreateEntityRoleUserDocument = gql`
    mutation CreateEntityRoleUser($input: EntityRoleUserAsgInput!) {
  CreateEntityRoleUser(input: $input)
}
    `;
export type CreateEntityRoleUserMutationFn = ApolloReactCommon.MutationFunction<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>;

/**
 * __useCreateEntityRoleUserMutation__
 *
 * To run a mutation, you first call `useCreateEntityRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityRoleUserMutation, { data, loading, error }] = useCreateEntityRoleUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntityRoleUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>(CreateEntityRoleUserDocument, baseOptions);
      }
export type CreateEntityRoleUserMutationHookResult = ReturnType<typeof useCreateEntityRoleUserMutation>;
export type CreateEntityRoleUserMutationResult = ApolloReactCommon.MutationResult<CreateEntityRoleUserMutation>;
export type CreateEntityRoleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>;
export const CompanyStatutoryInsertDocument = gql`
    mutation CompanyStatutoryInsert($CompanyStatutoryInput: CompanyStatutoryInput!) {
  CompanyStatutoryInsert(CompanyStatutoryInput: $CompanyStatutoryInput) {
    CompanyStatutoryID
    CompanyID
  }
}
    `;
export type CompanyStatutoryInsertMutationFn = ApolloReactCommon.MutationFunction<CompanyStatutoryInsertMutation, CompanyStatutoryInsertMutationVariables>;

/**
 * __useCompanyStatutoryInsertMutation__
 *
 * To run a mutation, you first call `useCompanyStatutoryInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyStatutoryInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyStatutoryInsertMutation, { data, loading, error }] = useCompanyStatutoryInsertMutation({
 *   variables: {
 *      CompanyStatutoryInput: // value for 'CompanyStatutoryInput'
 *   },
 * });
 */
export function useCompanyStatutoryInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyStatutoryInsertMutation, CompanyStatutoryInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyStatutoryInsertMutation, CompanyStatutoryInsertMutationVariables>(CompanyStatutoryInsertDocument, baseOptions);
      }
export type CompanyStatutoryInsertMutationHookResult = ReturnType<typeof useCompanyStatutoryInsertMutation>;
export type CompanyStatutoryInsertMutationResult = ApolloReactCommon.MutationResult<CompanyStatutoryInsertMutation>;
export type CompanyStatutoryInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyStatutoryInsertMutation, CompanyStatutoryInsertMutationVariables>;
export const ReportingGroupListingDocument = gql`
    query ReportingGroupListing {
  ReportingGroupListing {
    reporting_group_id
    name
    created_dt
    modified_dt
    GroupInUse
    CompanyInUse
  }
}
    `;

/**
 * __useReportingGroupListingQuery__
 *
 * To run a query within a React component, call `useReportingGroupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingGroupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingGroupListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportingGroupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportingGroupListingQuery, ReportingGroupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportingGroupListingQuery, ReportingGroupListingQueryVariables>(ReportingGroupListingDocument, baseOptions);
      }
export function useReportingGroupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportingGroupListingQuery, ReportingGroupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportingGroupListingQuery, ReportingGroupListingQueryVariables>(ReportingGroupListingDocument, baseOptions);
        }
export type ReportingGroupListingQueryHookResult = ReturnType<typeof useReportingGroupListingQuery>;
export type ReportingGroupListingLazyQueryHookResult = ReturnType<typeof useReportingGroupListingLazyQuery>;
export type ReportingGroupListingQueryResult = ApolloReactCommon.QueryResult<ReportingGroupListingQuery, ReportingGroupListingQueryVariables>;
export const ReportingGroupDeleteDocument = gql`
    mutation ReportingGroupDelete($ReportingGroupID: String!) {
  ReportingGroupDelete(ReportingGroupID: $ReportingGroupID)
}
    `;
export type ReportingGroupDeleteMutationFn = ApolloReactCommon.MutationFunction<ReportingGroupDeleteMutation, ReportingGroupDeleteMutationVariables>;

/**
 * __useReportingGroupDeleteMutation__
 *
 * To run a mutation, you first call `useReportingGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportingGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportingGroupDeleteMutation, { data, loading, error }] = useReportingGroupDeleteMutation({
 *   variables: {
 *      ReportingGroupID: // value for 'ReportingGroupID'
 *   },
 * });
 */
export function useReportingGroupDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportingGroupDeleteMutation, ReportingGroupDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportingGroupDeleteMutation, ReportingGroupDeleteMutationVariables>(ReportingGroupDeleteDocument, baseOptions);
      }
export type ReportingGroupDeleteMutationHookResult = ReturnType<typeof useReportingGroupDeleteMutation>;
export type ReportingGroupDeleteMutationResult = ApolloReactCommon.MutationResult<ReportingGroupDeleteMutation>;
export type ReportingGroupDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportingGroupDeleteMutation, ReportingGroupDeleteMutationVariables>;
export const ReportingGroupSubmitDocument = gql`
    mutation ReportingGroupSubmit($input: ReportingGroupInput!) {
  ReportingGroupSubmit(input: $input)
}
    `;
export type ReportingGroupSubmitMutationFn = ApolloReactCommon.MutationFunction<ReportingGroupSubmitMutation, ReportingGroupSubmitMutationVariables>;

/**
 * __useReportingGroupSubmitMutation__
 *
 * To run a mutation, you first call `useReportingGroupSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportingGroupSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportingGroupSubmitMutation, { data, loading, error }] = useReportingGroupSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportingGroupSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportingGroupSubmitMutation, ReportingGroupSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportingGroupSubmitMutation, ReportingGroupSubmitMutationVariables>(ReportingGroupSubmitDocument, baseOptions);
      }
export type ReportingGroupSubmitMutationHookResult = ReturnType<typeof useReportingGroupSubmitMutation>;
export type ReportingGroupSubmitMutationResult = ApolloReactCommon.MutationResult<ReportingGroupSubmitMutation>;
export type ReportingGroupSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportingGroupSubmitMutation, ReportingGroupSubmitMutationVariables>;
export const ReportingCompanyGroupListingDocument = gql`
    query ReportingCompanyGroupListing($CompanyID: String) {
  ReportingCompanyGroupListing(CompanyID: $CompanyID)
}
    `;

/**
 * __useReportingCompanyGroupListingQuery__
 *
 * To run a query within a React component, call `useReportingCompanyGroupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingCompanyGroupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingCompanyGroupListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useReportingCompanyGroupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportingCompanyGroupListingQuery, ReportingCompanyGroupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportingCompanyGroupListingQuery, ReportingCompanyGroupListingQueryVariables>(ReportingCompanyGroupListingDocument, baseOptions);
      }
export function useReportingCompanyGroupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportingCompanyGroupListingQuery, ReportingCompanyGroupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportingCompanyGroupListingQuery, ReportingCompanyGroupListingQueryVariables>(ReportingCompanyGroupListingDocument, baseOptions);
        }
export type ReportingCompanyGroupListingQueryHookResult = ReturnType<typeof useReportingCompanyGroupListingQuery>;
export type ReportingCompanyGroupListingLazyQueryHookResult = ReturnType<typeof useReportingCompanyGroupListingLazyQuery>;
export type ReportingCompanyGroupListingQueryResult = ApolloReactCommon.QueryResult<ReportingCompanyGroupListingQuery, ReportingCompanyGroupListingQueryVariables>;
export const ReportingCompanyGroupDeleteDocument = gql`
    mutation ReportingCompanyGroupDelete($ReportingGroupID: String!) {
  ReportingCompanyGroupDelete(ReportingGroupID: $ReportingGroupID)
}
    `;
export type ReportingCompanyGroupDeleteMutationFn = ApolloReactCommon.MutationFunction<ReportingCompanyGroupDeleteMutation, ReportingCompanyGroupDeleteMutationVariables>;

/**
 * __useReportingCompanyGroupDeleteMutation__
 *
 * To run a mutation, you first call `useReportingCompanyGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportingCompanyGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportingCompanyGroupDeleteMutation, { data, loading, error }] = useReportingCompanyGroupDeleteMutation({
 *   variables: {
 *      ReportingGroupID: // value for 'ReportingGroupID'
 *   },
 * });
 */
export function useReportingCompanyGroupDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportingCompanyGroupDeleteMutation, ReportingCompanyGroupDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportingCompanyGroupDeleteMutation, ReportingCompanyGroupDeleteMutationVariables>(ReportingCompanyGroupDeleteDocument, baseOptions);
      }
export type ReportingCompanyGroupDeleteMutationHookResult = ReturnType<typeof useReportingCompanyGroupDeleteMutation>;
export type ReportingCompanyGroupDeleteMutationResult = ApolloReactCommon.MutationResult<ReportingCompanyGroupDeleteMutation>;
export type ReportingCompanyGroupDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportingCompanyGroupDeleteMutation, ReportingCompanyGroupDeleteMutationVariables>;
export const ReportingGroupTitleExistDocument = gql`
    query ReportingGroupTitleExist($Title: String!, $ReportingGroupID: String) {
  ReportingGroupTitleExist(Title: $Title, ReportingGroupID: $ReportingGroupID)
}
    `;

/**
 * __useReportingGroupTitleExistQuery__
 *
 * To run a query within a React component, call `useReportingGroupTitleExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingGroupTitleExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingGroupTitleExistQuery({
 *   variables: {
 *      Title: // value for 'Title'
 *      ReportingGroupID: // value for 'ReportingGroupID'
 *   },
 * });
 */
export function useReportingGroupTitleExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportingGroupTitleExistQuery, ReportingGroupTitleExistQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportingGroupTitleExistQuery, ReportingGroupTitleExistQueryVariables>(ReportingGroupTitleExistDocument, baseOptions);
      }
export function useReportingGroupTitleExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportingGroupTitleExistQuery, ReportingGroupTitleExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportingGroupTitleExistQuery, ReportingGroupTitleExistQueryVariables>(ReportingGroupTitleExistDocument, baseOptions);
        }
export type ReportingGroupTitleExistQueryHookResult = ReturnType<typeof useReportingGroupTitleExistQuery>;
export type ReportingGroupTitleExistLazyQueryHookResult = ReturnType<typeof useReportingGroupTitleExistLazyQuery>;
export type ReportingGroupTitleExistQueryResult = ApolloReactCommon.QueryResult<ReportingGroupTitleExistQuery, ReportingGroupTitleExistQueryVariables>;
export const ReportingStructureGroupSubmitDocument = gql`
    mutation ReportingStructureGroupSubmit($input: ReportingStructureGroupInput!) {
  ReportingStructureGroupSubmit(input: $input)
}
    `;
export type ReportingStructureGroupSubmitMutationFn = ApolloReactCommon.MutationFunction<ReportingStructureGroupSubmitMutation, ReportingStructureGroupSubmitMutationVariables>;

/**
 * __useReportingStructureGroupSubmitMutation__
 *
 * To run a mutation, you first call `useReportingStructureGroupSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportingStructureGroupSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportingStructureGroupSubmitMutation, { data, loading, error }] = useReportingStructureGroupSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportingStructureGroupSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportingStructureGroupSubmitMutation, ReportingStructureGroupSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportingStructureGroupSubmitMutation, ReportingStructureGroupSubmitMutationVariables>(ReportingStructureGroupSubmitDocument, baseOptions);
      }
export type ReportingStructureGroupSubmitMutationHookResult = ReturnType<typeof useReportingStructureGroupSubmitMutation>;
export type ReportingStructureGroupSubmitMutationResult = ApolloReactCommon.MutationResult<ReportingStructureGroupSubmitMutation>;
export type ReportingStructureGroupSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportingStructureGroupSubmitMutation, ReportingStructureGroupSubmitMutationVariables>;
export const GetReportingStructureListingDocument = gql`
    query GetReportingStructureListing($ReportingGroupID: String!, $CompanyID: String!) {
  GetReportingStructureListing(ReportingGroupID: $ReportingGroupID, CompanyID: $CompanyID) {
    ReportingGroupID
    ItemActionType
    Title
    ReportingGroup {
      name
      reporting_group_id
    }
    JobPositionReporting {
      ReportingStructureID
      EffectiveDate
      ItemActionType
      ReportingType
      JobPositionList {
        job_position_reporting_id
        from_org_unit_id
        from_org_unit {
          Description
        }
        to_org_unit_id
        to_org_unit {
          Description
        }
        from_job_position_id
        from_job_position {
          Name
        }
        to_job_position_id
        to_job_position {
          Name
        }
        to_company_id
        to_company {
          CompanyName
        }
        created_dt
        created_by
        modified_dt
        modified_by
        ItemActionType
      }
    }
    EmployeeReporting {
      ReportingStructureID
      EffectiveDate
      ExpiryDate
      ReportingType
      ItemActionType
      SuperiorID
      Superior {
        EmployeeID
        EmployeeNo
        Designation {
          Name
        }
        Contact {
          FullName
        }
      }
      SubordinateID
      Subordinate {
        EmployeeID
        EmployeeNo
        Designation {
          Name
        }
        Contact {
          FullName
        }
      }
    }
  }
}
    `;

/**
 * __useGetReportingStructureListingQuery__
 *
 * To run a query within a React component, call `useGetReportingStructureListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportingStructureListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportingStructureListingQuery({
 *   variables: {
 *      ReportingGroupID: // value for 'ReportingGroupID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetReportingStructureListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReportingStructureListingQuery, GetReportingStructureListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReportingStructureListingQuery, GetReportingStructureListingQueryVariables>(GetReportingStructureListingDocument, baseOptions);
      }
export function useGetReportingStructureListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReportingStructureListingQuery, GetReportingStructureListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReportingStructureListingQuery, GetReportingStructureListingQueryVariables>(GetReportingStructureListingDocument, baseOptions);
        }
export type GetReportingStructureListingQueryHookResult = ReturnType<typeof useGetReportingStructureListingQuery>;
export type GetReportingStructureListingLazyQueryHookResult = ReturnType<typeof useGetReportingStructureListingLazyQuery>;
export type GetReportingStructureListingQueryResult = ApolloReactCommon.QueryResult<GetReportingStructureListingQuery, GetReportingStructureListingQueryVariables>;
export const ReportingStructureGroupOptionListDocument = gql`
    query ReportingStructureGroupOptionList($CompanyID: String!) {
  ReportingStructureGroupOptionList(CompanyID: $CompanyID)
}
    `;

/**
 * __useReportingStructureGroupOptionListQuery__
 *
 * To run a query within a React component, call `useReportingStructureGroupOptionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportingStructureGroupOptionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportingStructureGroupOptionListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useReportingStructureGroupOptionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportingStructureGroupOptionListQuery, ReportingStructureGroupOptionListQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportingStructureGroupOptionListQuery, ReportingStructureGroupOptionListQueryVariables>(ReportingStructureGroupOptionListDocument, baseOptions);
      }
export function useReportingStructureGroupOptionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportingStructureGroupOptionListQuery, ReportingStructureGroupOptionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportingStructureGroupOptionListQuery, ReportingStructureGroupOptionListQueryVariables>(ReportingStructureGroupOptionListDocument, baseOptions);
        }
export type ReportingStructureGroupOptionListQueryHookResult = ReturnType<typeof useReportingStructureGroupOptionListQuery>;
export type ReportingStructureGroupOptionListLazyQueryHookResult = ReturnType<typeof useReportingStructureGroupOptionListLazyQuery>;
export type ReportingStructureGroupOptionListQueryResult = ApolloReactCommon.QueryResult<ReportingStructureGroupOptionListQuery, ReportingStructureGroupOptionListQueryVariables>;
export const PersonnelTemplateLibraryAssignmentListingDocument = gql`
    query PersonnelTemplateLibraryAssignmentListing {
  PersonnelTemplateLibraryAssignmentListing {
    JobGrade {
      JobGradeID
      Description
    }
    SkillSet {
      Name
      SkillSetID
    }
    JobPosition {
      Name
      JobDesignationID
    }
    InsuranceScheme {
      Name
      InsuranceSchemeID
    }
    RecruitmentSource {
      Name
      RecruitmentSourceID
    }
    UserDefine {
      Name
      UserDefineID
    }
  }
}
    `;

/**
 * __usePersonnelTemplateLibraryAssignmentListingQuery__
 *
 * To run a query within a React component, call `usePersonnelTemplateLibraryAssignmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonnelTemplateLibraryAssignmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonnelTemplateLibraryAssignmentListingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePersonnelTemplateLibraryAssignmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PersonnelTemplateLibraryAssignmentListingQuery, PersonnelTemplateLibraryAssignmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PersonnelTemplateLibraryAssignmentListingQuery, PersonnelTemplateLibraryAssignmentListingQueryVariables>(PersonnelTemplateLibraryAssignmentListingDocument, baseOptions);
      }
export function usePersonnelTemplateLibraryAssignmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PersonnelTemplateLibraryAssignmentListingQuery, PersonnelTemplateLibraryAssignmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PersonnelTemplateLibraryAssignmentListingQuery, PersonnelTemplateLibraryAssignmentListingQueryVariables>(PersonnelTemplateLibraryAssignmentListingDocument, baseOptions);
        }
export type PersonnelTemplateLibraryAssignmentListingQueryHookResult = ReturnType<typeof usePersonnelTemplateLibraryAssignmentListingQuery>;
export type PersonnelTemplateLibraryAssignmentListingLazyQueryHookResult = ReturnType<typeof usePersonnelTemplateLibraryAssignmentListingLazyQuery>;
export type PersonnelTemplateLibraryAssignmentListingQueryResult = ApolloReactCommon.QueryResult<PersonnelTemplateLibraryAssignmentListingQuery, PersonnelTemplateLibraryAssignmentListingQueryVariables>;
export const GetTemplateLibraryAssignmentDocument = gql`
    query getTemplateLibraryAssignment($input: TemplateLibraryAssignmentInput, $skip: Float, $take: Float, $order: String) {
  getTemplateLibraryAssignment(input: $input, skip: $skip, take: $take, order: $order) {
    TemplateLibraryAssignmentID
    SubscriptionAccountID
    CompanyID
    SourceID
    SourceType
    TargetID
    TargetType
    ModifiedDT
  }
}
    `;

/**
 * __useGetTemplateLibraryAssignmentQuery__
 *
 * To run a query within a React component, call `useGetTemplateLibraryAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateLibraryAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateLibraryAssignmentQuery({
 *   variables: {
 *      input: // value for 'input'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetTemplateLibraryAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplateLibraryAssignmentQuery, GetTemplateLibraryAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplateLibraryAssignmentQuery, GetTemplateLibraryAssignmentQueryVariables>(GetTemplateLibraryAssignmentDocument, baseOptions);
      }
export function useGetTemplateLibraryAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplateLibraryAssignmentQuery, GetTemplateLibraryAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplateLibraryAssignmentQuery, GetTemplateLibraryAssignmentQueryVariables>(GetTemplateLibraryAssignmentDocument, baseOptions);
        }
export type GetTemplateLibraryAssignmentQueryHookResult = ReturnType<typeof useGetTemplateLibraryAssignmentQuery>;
export type GetTemplateLibraryAssignmentLazyQueryHookResult = ReturnType<typeof useGetTemplateLibraryAssignmentLazyQuery>;
export type GetTemplateLibraryAssignmentQueryResult = ApolloReactCommon.QueryResult<GetTemplateLibraryAssignmentQuery, GetTemplateLibraryAssignmentQueryVariables>;
export const ReplaceTemplateLibraryAssignmentForTmsShiftGroupDocument = gql`
    mutation replaceTemplateLibraryAssignmentForTMSShiftGroup($inputs: [TemplateLibraryAssignmentInput!]!) {
  replaceTemplateLibraryAssignmentForTMSShiftGroup(inputs: $inputs)
}
    `;
export type ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutationFn = ApolloReactCommon.MutationFunction<ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation, ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutationVariables>;

/**
 * __useReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation__
 *
 * To run a mutation, you first call `useReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceTemplateLibraryAssignmentForTmsShiftGroupMutation, { data, loading, error }] = useReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation, ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation, ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutationVariables>(ReplaceTemplateLibraryAssignmentForTmsShiftGroupDocument, baseOptions);
      }
export type ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutationHookResult = ReturnType<typeof useReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation>;
export type ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutationResult = ApolloReactCommon.MutationResult<ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation>;
export type ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutation, ReplaceTemplateLibraryAssignmentForTmsShiftGroupMutationVariables>;
export const TmsSummaryDocument = gql`
    query TMSSummary($CompanyID: String!) {
  TMSSummary(CompanyID: $CompanyID) {
    ShiftGroup
    AttendanceExclusion
    EarnLeave
    TimeOff
    Overtime
    ChangeShift
    AttendanceRecord
    LateIn
    Absent
    IncompleteClocking
    DutyRosterDate
    AttendanceProcessing
    AttendancePosting
    IncompleteClockingEmployees
    LateInEmployees
    AbsentEmployee
    TodayData
  }
}
    `;

/**
 * __useTmsSummaryQuery__
 *
 * To run a query within a React component, call `useTmsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTmsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTmsSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useTmsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TmsSummaryQuery, TmsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<TmsSummaryQuery, TmsSummaryQueryVariables>(TmsSummaryDocument, baseOptions);
      }
export function useTmsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TmsSummaryQuery, TmsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TmsSummaryQuery, TmsSummaryQueryVariables>(TmsSummaryDocument, baseOptions);
        }
export type TmsSummaryQueryHookResult = ReturnType<typeof useTmsSummaryQuery>;
export type TmsSummaryLazyQueryHookResult = ReturnType<typeof useTmsSummaryLazyQuery>;
export type TmsSummaryQueryResult = ApolloReactCommon.QueryResult<TmsSummaryQuery, TmsSummaryQueryVariables>;
export const GetShiftGroupListingPageDataDocument = gql`
    query GetShiftGroupListingPageData($input: TemplateLibraryAssignmentInput) {
  getTemplateLibraryAssignment(input: $input) {
    TargetID
    TargetType
    SourceID
    SourceType
    CompanyID
    Status
    Target {
      ... on ShiftGroupEntity {
        ShiftGroupID
        Name
        ShiftCount
        EmployeeCount
        Employee {
          EmployeeID
          EmployeeNo
          CompanyID
          Contact {
            FullName
          }
          Company {
            CompanyName
          }
          JobGrade {
            Description
          }
          ShiftGroupEmployeeAssignmentLogList {
            ShiftGroupID
            Status
            End
            Start
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetShiftGroupListingPageDataQuery__
 *
 * To run a query within a React component, call `useGetShiftGroupListingPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftGroupListingPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftGroupListingPageDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShiftGroupListingPageDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShiftGroupListingPageDataQuery, GetShiftGroupListingPageDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShiftGroupListingPageDataQuery, GetShiftGroupListingPageDataQueryVariables>(GetShiftGroupListingPageDataDocument, baseOptions);
      }
export function useGetShiftGroupListingPageDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShiftGroupListingPageDataQuery, GetShiftGroupListingPageDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShiftGroupListingPageDataQuery, GetShiftGroupListingPageDataQueryVariables>(GetShiftGroupListingPageDataDocument, baseOptions);
        }
export type GetShiftGroupListingPageDataQueryHookResult = ReturnType<typeof useGetShiftGroupListingPageDataQuery>;
export type GetShiftGroupListingPageDataLazyQueryHookResult = ReturnType<typeof useGetShiftGroupListingPageDataLazyQuery>;
export type GetShiftGroupListingPageDataQueryResult = ApolloReactCommon.QueryResult<GetShiftGroupListingPageDataQuery, GetShiftGroupListingPageDataQueryVariables>;
export const ShiftGroupEmployeeListDocument = gql`
    query ShiftGroupEmployeeList($CompanyID: String!, $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!], $OrgUnitID: [String!], $offset: Float, $limit: Float, $keyword: String, $ExcludeFormStatus: String, $ExcludeEmployeeStatus: [String!], $exitReason: [String!], $Page: String) {
  EmployeeListing(CompanyID: $CompanyID, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, OrgUnitID: $OrgUnitID, EmployeeStatus: $EmployeeStatus, ExcludeFormStatus: $ExcludeFormStatus, ExcludeEmployeeStatus: $ExcludeEmployeeStatus, offset: $offset, limit: $limit, keyword: $keyword, exitReason: $exitReason, Page: $Page) {
    JoinedDate
    EmployeeID
    CompanyID
    Contact {
      FullName
    }
    EmployeeNo
    Company {
      CompanyName
    }
    JobGrade {
      Description
    }
    Status
    ResignedDate
    FormStatus
    ShiftGroupEmployeeAssignmentLogList {
      ShiftGroupID
      ShiftGroup {
        Name
      }
      Start
      End
      Status
    }
  }
}
    `;

/**
 * __useShiftGroupEmployeeListQuery__
 *
 * To run a query within a React component, call `useShiftGroupEmployeeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftGroupEmployeeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftGroupEmployeeListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *      ExcludeEmployeeStatus: // value for 'ExcludeEmployeeStatus'
 *      exitReason: // value for 'exitReason'
 *      Page: // value for 'Page'
 *   },
 * });
 */
export function useShiftGroupEmployeeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShiftGroupEmployeeListQuery, ShiftGroupEmployeeListQueryVariables>) {
        return ApolloReactHooks.useQuery<ShiftGroupEmployeeListQuery, ShiftGroupEmployeeListQueryVariables>(ShiftGroupEmployeeListDocument, baseOptions);
      }
export function useShiftGroupEmployeeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShiftGroupEmployeeListQuery, ShiftGroupEmployeeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShiftGroupEmployeeListQuery, ShiftGroupEmployeeListQueryVariables>(ShiftGroupEmployeeListDocument, baseOptions);
        }
export type ShiftGroupEmployeeListQueryHookResult = ReturnType<typeof useShiftGroupEmployeeListQuery>;
export type ShiftGroupEmployeeListLazyQueryHookResult = ReturnType<typeof useShiftGroupEmployeeListLazyQuery>;
export type ShiftGroupEmployeeListQueryResult = ApolloReactCommon.QueryResult<ShiftGroupEmployeeListQuery, ShiftGroupEmployeeListQueryVariables>;
export const ShiftGroupEmployeeListCountDocument = gql`
    query ShiftGroupEmployeeListCount($CompanyID: String!, $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!], $OrgUnitID: [String!], $keyword: String, $ExcludeFormStatus: String, $ExcludeEmployeeStatus: [String!], $exitReason: [String!], $Page: String) {
  EmployeeListingCount(CompanyID: $CompanyID, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, OrgUnitID: $OrgUnitID, EmployeeStatus: $EmployeeStatus, ExcludeFormStatus: $ExcludeFormStatus, ExcludeEmployeeStatus: $ExcludeEmployeeStatus, keyword: $keyword, exitReason: $exitReason, Page: $Page)
}
    `;

/**
 * __useShiftGroupEmployeeListCountQuery__
 *
 * To run a query within a React component, call `useShiftGroupEmployeeListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftGroupEmployeeListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftGroupEmployeeListCountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      keyword: // value for 'keyword'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *      ExcludeEmployeeStatus: // value for 'ExcludeEmployeeStatus'
 *      exitReason: // value for 'exitReason'
 *      Page: // value for 'Page'
 *   },
 * });
 */
export function useShiftGroupEmployeeListCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShiftGroupEmployeeListCountQuery, ShiftGroupEmployeeListCountQueryVariables>) {
        return ApolloReactHooks.useQuery<ShiftGroupEmployeeListCountQuery, ShiftGroupEmployeeListCountQueryVariables>(ShiftGroupEmployeeListCountDocument, baseOptions);
      }
export function useShiftGroupEmployeeListCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShiftGroupEmployeeListCountQuery, ShiftGroupEmployeeListCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShiftGroupEmployeeListCountQuery, ShiftGroupEmployeeListCountQueryVariables>(ShiftGroupEmployeeListCountDocument, baseOptions);
        }
export type ShiftGroupEmployeeListCountQueryHookResult = ReturnType<typeof useShiftGroupEmployeeListCountQuery>;
export type ShiftGroupEmployeeListCountLazyQueryHookResult = ReturnType<typeof useShiftGroupEmployeeListCountLazyQuery>;
export type ShiftGroupEmployeeListCountQueryResult = ApolloReactCommon.QueryResult<ShiftGroupEmployeeListCountQuery, ShiftGroupEmployeeListCountQueryVariables>;
export const GetShiftGroupFormAssignedEmployeesDataDocument = gql`
    query GetShiftGroupFormAssignedEmployeesData($input: TemplateLibraryAssignmentInput) {
  getTemplateLibraryAssignment(input: $input) {
    TargetID
    SourceID
    SourceType
    CompanyID
    TargetType
  }
}
    `;

/**
 * __useGetShiftGroupFormAssignedEmployeesDataQuery__
 *
 * To run a query within a React component, call `useGetShiftGroupFormAssignedEmployeesDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftGroupFormAssignedEmployeesDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftGroupFormAssignedEmployeesDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetShiftGroupFormAssignedEmployeesDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShiftGroupFormAssignedEmployeesDataQuery, GetShiftGroupFormAssignedEmployeesDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShiftGroupFormAssignedEmployeesDataQuery, GetShiftGroupFormAssignedEmployeesDataQueryVariables>(GetShiftGroupFormAssignedEmployeesDataDocument, baseOptions);
      }
export function useGetShiftGroupFormAssignedEmployeesDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShiftGroupFormAssignedEmployeesDataQuery, GetShiftGroupFormAssignedEmployeesDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShiftGroupFormAssignedEmployeesDataQuery, GetShiftGroupFormAssignedEmployeesDataQueryVariables>(GetShiftGroupFormAssignedEmployeesDataDocument, baseOptions);
        }
export type GetShiftGroupFormAssignedEmployeesDataQueryHookResult = ReturnType<typeof useGetShiftGroupFormAssignedEmployeesDataQuery>;
export type GetShiftGroupFormAssignedEmployeesDataLazyQueryHookResult = ReturnType<typeof useGetShiftGroupFormAssignedEmployeesDataLazyQuery>;
export type GetShiftGroupFormAssignedEmployeesDataQueryResult = ApolloReactCommon.QueryResult<GetShiftGroupFormAssignedEmployeesDataQuery, GetShiftGroupFormAssignedEmployeesDataQueryVariables>;
export const ShiftGroupDataDocument = gql`
    query ShiftGroupData {
  getShiftGroup {
    ShiftGroupID
    Name
    ShiftGroupShift {
      ShiftGroupID
      ShiftGroupShiftID
      ShiftID
    }
  }
}
    `;

/**
 * __useShiftGroupDataQuery__
 *
 * To run a query within a React component, call `useShiftGroupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftGroupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftGroupDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useShiftGroupDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShiftGroupDataQuery, ShiftGroupDataQueryVariables>) {
        return ApolloReactHooks.useQuery<ShiftGroupDataQuery, ShiftGroupDataQueryVariables>(ShiftGroupDataDocument, baseOptions);
      }
export function useShiftGroupDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShiftGroupDataQuery, ShiftGroupDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShiftGroupDataQuery, ShiftGroupDataQueryVariables>(ShiftGroupDataDocument, baseOptions);
        }
export type ShiftGroupDataQueryHookResult = ReturnType<typeof useShiftGroupDataQuery>;
export type ShiftGroupDataLazyQueryHookResult = ReturnType<typeof useShiftGroupDataLazyQuery>;
export type ShiftGroupDataQueryResult = ApolloReactCommon.QueryResult<ShiftGroupDataQuery, ShiftGroupDataQueryVariables>;
export const ShiftGroupDataByIdDocument = gql`
    query ShiftGroupDataById($ShiftGroupID: String) {
  getShiftGroup(ShiftGroupID: $ShiftGroupID) {
    ShiftGroupID
    ShiftGroupShift {
      ShiftGroupID
      ShiftGroupShiftID
      ShiftID
    }
  }
}
    `;

/**
 * __useShiftGroupDataByIdQuery__
 *
 * To run a query within a React component, call `useShiftGroupDataByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftGroupDataByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftGroupDataByIdQuery({
 *   variables: {
 *      ShiftGroupID: // value for 'ShiftGroupID'
 *   },
 * });
 */
export function useShiftGroupDataByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShiftGroupDataByIdQuery, ShiftGroupDataByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ShiftGroupDataByIdQuery, ShiftGroupDataByIdQueryVariables>(ShiftGroupDataByIdDocument, baseOptions);
      }
export function useShiftGroupDataByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShiftGroupDataByIdQuery, ShiftGroupDataByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShiftGroupDataByIdQuery, ShiftGroupDataByIdQueryVariables>(ShiftGroupDataByIdDocument, baseOptions);
        }
export type ShiftGroupDataByIdQueryHookResult = ReturnType<typeof useShiftGroupDataByIdQuery>;
export type ShiftGroupDataByIdLazyQueryHookResult = ReturnType<typeof useShiftGroupDataByIdLazyQuery>;
export type ShiftGroupDataByIdQueryResult = ApolloReactCommon.QueryResult<ShiftGroupDataByIdQuery, ShiftGroupDataByIdQueryVariables>;
export const ShiftGroupEmployeeDocument = gql`
    query ShiftGroupEmployee($input: TemplateLibraryAssignmentInput) {
  getTemplateLibraryAssignment(input: $input) {
    TargetID
    SourceID
    SourceType
    CompanyID
    TargetType
  }
}
    `;

/**
 * __useShiftGroupEmployeeQuery__
 *
 * To run a query within a React component, call `useShiftGroupEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftGroupEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftGroupEmployeeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShiftGroupEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShiftGroupEmployeeQuery, ShiftGroupEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<ShiftGroupEmployeeQuery, ShiftGroupEmployeeQueryVariables>(ShiftGroupEmployeeDocument, baseOptions);
      }
export function useShiftGroupEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShiftGroupEmployeeQuery, ShiftGroupEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShiftGroupEmployeeQuery, ShiftGroupEmployeeQueryVariables>(ShiftGroupEmployeeDocument, baseOptions);
        }
export type ShiftGroupEmployeeQueryHookResult = ReturnType<typeof useShiftGroupEmployeeQuery>;
export type ShiftGroupEmployeeLazyQueryHookResult = ReturnType<typeof useShiftGroupEmployeeLazyQuery>;
export type ShiftGroupEmployeeQueryResult = ApolloReactCommon.QueryResult<ShiftGroupEmployeeQuery, ShiftGroupEmployeeQueryVariables>;
export const DutyRosterInfoLineDocument = gql`
    query DutyRosterInfoLine($CompanyID: String!) {
  DutyRosterInfoLine(CompanyID: $CompanyID)
}
    `;

/**
 * __useDutyRosterInfoLineQuery__
 *
 * To run a query within a React component, call `useDutyRosterInfoLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useDutyRosterInfoLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDutyRosterInfoLineQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDutyRosterInfoLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DutyRosterInfoLineQuery, DutyRosterInfoLineQueryVariables>) {
        return ApolloReactHooks.useQuery<DutyRosterInfoLineQuery, DutyRosterInfoLineQueryVariables>(DutyRosterInfoLineDocument, baseOptions);
      }
export function useDutyRosterInfoLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DutyRosterInfoLineQuery, DutyRosterInfoLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DutyRosterInfoLineQuery, DutyRosterInfoLineQueryVariables>(DutyRosterInfoLineDocument, baseOptions);
        }
export type DutyRosterInfoLineQueryHookResult = ReturnType<typeof useDutyRosterInfoLineQuery>;
export type DutyRosterInfoLineLazyQueryHookResult = ReturnType<typeof useDutyRosterInfoLineLazyQuery>;
export type DutyRosterInfoLineQueryResult = ApolloReactCommon.QueryResult<DutyRosterInfoLineQuery, DutyRosterInfoLineQueryVariables>;
export const DeleteDutyRosterByEmployeeDocument = gql`
    mutation DeleteDutyRosterByEmployee($EmployeeID: String!, $CompanyID: String!, $EndDate: DateTime!, $StartDate: DateTime!) {
  DeleteDutyRosterByEmployee(EmployeeID: $EmployeeID, CompanyID: $CompanyID, EndDate: $EndDate, StartDate: $StartDate)
}
    `;
export type DeleteDutyRosterByEmployeeMutationFn = ApolloReactCommon.MutationFunction<DeleteDutyRosterByEmployeeMutation, DeleteDutyRosterByEmployeeMutationVariables>;

/**
 * __useDeleteDutyRosterByEmployeeMutation__
 *
 * To run a mutation, you first call `useDeleteDutyRosterByEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDutyRosterByEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDutyRosterByEmployeeMutation, { data, loading, error }] = useDeleteDutyRosterByEmployeeMutation({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyID: // value for 'CompanyID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *   },
 * });
 */
export function useDeleteDutyRosterByEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDutyRosterByEmployeeMutation, DeleteDutyRosterByEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDutyRosterByEmployeeMutation, DeleteDutyRosterByEmployeeMutationVariables>(DeleteDutyRosterByEmployeeDocument, baseOptions);
      }
export type DeleteDutyRosterByEmployeeMutationHookResult = ReturnType<typeof useDeleteDutyRosterByEmployeeMutation>;
export type DeleteDutyRosterByEmployeeMutationResult = ApolloReactCommon.MutationResult<DeleteDutyRosterByEmployeeMutation>;
export type DeleteDutyRosterByEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDutyRosterByEmployeeMutation, DeleteDutyRosterByEmployeeMutationVariables>;
export const DutyRosterBatchDocument = gql`
    mutation DutyRosterBatch($input: [DutyRosterInput!], $removeList: [DutyRosterInput!]) {
  DutyRosterBatch(Input: $input, removeList: $removeList)
}
    `;
export type DutyRosterBatchMutationFn = ApolloReactCommon.MutationFunction<DutyRosterBatchMutation, DutyRosterBatchMutationVariables>;

/**
 * __useDutyRosterBatchMutation__
 *
 * To run a mutation, you first call `useDutyRosterBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDutyRosterBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dutyRosterBatchMutation, { data, loading, error }] = useDutyRosterBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *      removeList: // value for 'removeList'
 *   },
 * });
 */
export function useDutyRosterBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DutyRosterBatchMutation, DutyRosterBatchMutationVariables>) {
        return ApolloReactHooks.useMutation<DutyRosterBatchMutation, DutyRosterBatchMutationVariables>(DutyRosterBatchDocument, baseOptions);
      }
export type DutyRosterBatchMutationHookResult = ReturnType<typeof useDutyRosterBatchMutation>;
export type DutyRosterBatchMutationResult = ApolloReactCommon.MutationResult<DutyRosterBatchMutation>;
export type DutyRosterBatchMutationOptions = ApolloReactCommon.BaseMutationOptions<DutyRosterBatchMutation, DutyRosterBatchMutationVariables>;
export const EmployeeListingByCompanyTmsDocument = gql`
    query EmployeeListingByCompanyTMS($CompanyID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!], $OrgUnitIDs: [String!], $offset: Float, $limit: Float, $keyword: String, $ExcludeFormStatus: String, $ExcludeEmployeeStatus: [String!], $exitReason: [String!], $Page: String) {
  TMSEmployeeListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, OrgUnitIDs: $OrgUnitIDs, offset: $offset, limit: $limit, keyword: $keyword, ExcludeFormStatus: $ExcludeFormStatus, ExcludeEmployeeStatus: $ExcludeEmployeeStatus, exitReason: $exitReason, Page: $Page) {
    EmployeeID
    SubscriptionUserID
    EmployeeNo
    EmploymentType
    JoinedDate
    OrgUnitID
    DepartmentID
    DivisionID
    Contact {
      FullName
      ProfilePicture
    }
    Company {
      CompanyID
      CompanyName
    }
    Designation {
      JobDesignationID
      Name
    }
    Documents {
      DocumentFile
    }
    JobGrade {
      JobGradeID
      Description
    }
    Status
    ResignedDate
    FormStatus
  }
  TMSEmployeeListingCount(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, OrgUnitIDs: $OrgUnitIDs, keyword: $keyword, ExcludeFormStatus: $ExcludeFormStatus, ExcludeEmployeeStatus: $ExcludeEmployeeStatus, exitReason: $exitReason, Page: $Page)
}
    `;

/**
 * __useEmployeeListingByCompanyTmsQuery__
 *
 * To run a query within a React component, call `useEmployeeListingByCompanyTmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListingByCompanyTmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListingByCompanyTmsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *      ExcludeEmployeeStatus: // value for 'ExcludeEmployeeStatus'
 *      exitReason: // value for 'exitReason'
 *      Page: // value for 'Page'
 *   },
 * });
 */
export function useEmployeeListingByCompanyTmsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListingByCompanyTmsQuery, EmployeeListingByCompanyTmsQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListingByCompanyTmsQuery, EmployeeListingByCompanyTmsQueryVariables>(EmployeeListingByCompanyTmsDocument, baseOptions);
      }
export function useEmployeeListingByCompanyTmsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListingByCompanyTmsQuery, EmployeeListingByCompanyTmsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListingByCompanyTmsQuery, EmployeeListingByCompanyTmsQueryVariables>(EmployeeListingByCompanyTmsDocument, baseOptions);
        }
export type EmployeeListingByCompanyTmsQueryHookResult = ReturnType<typeof useEmployeeListingByCompanyTmsQuery>;
export type EmployeeListingByCompanyTmsLazyQueryHookResult = ReturnType<typeof useEmployeeListingByCompanyTmsLazyQuery>;
export type EmployeeListingByCompanyTmsQueryResult = ApolloReactCommon.QueryResult<EmployeeListingByCompanyTmsQuery, EmployeeListingByCompanyTmsQueryVariables>;
export const EmployeeListingByCompanyTmsCountDocument = gql`
    query EmployeeListingByCompanyTMSCount($CompanyID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!], $OrgUnitIDs: [String!], $keyword: String, $ExcludeFormStatus: String, $ExcludeEmployeeStatus: [String!], $exitReason: [String!], $Page: String) {
  TMSEmployeeListingCount(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, OrgUnitIDs: $OrgUnitIDs, keyword: $keyword, ExcludeFormStatus: $ExcludeFormStatus, ExcludeEmployeeStatus: $ExcludeEmployeeStatus, exitReason: $exitReason, Page: $Page)
}
    `;

/**
 * __useEmployeeListingByCompanyTmsCountQuery__
 *
 * To run a query within a React component, call `useEmployeeListingByCompanyTmsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListingByCompanyTmsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListingByCompanyTmsCountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      keyword: // value for 'keyword'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *      ExcludeEmployeeStatus: // value for 'ExcludeEmployeeStatus'
 *      exitReason: // value for 'exitReason'
 *      Page: // value for 'Page'
 *   },
 * });
 */
export function useEmployeeListingByCompanyTmsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListingByCompanyTmsCountQuery, EmployeeListingByCompanyTmsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListingByCompanyTmsCountQuery, EmployeeListingByCompanyTmsCountQueryVariables>(EmployeeListingByCompanyTmsCountDocument, baseOptions);
      }
export function useEmployeeListingByCompanyTmsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListingByCompanyTmsCountQuery, EmployeeListingByCompanyTmsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListingByCompanyTmsCountQuery, EmployeeListingByCompanyTmsCountQueryVariables>(EmployeeListingByCompanyTmsCountDocument, baseOptions);
        }
export type EmployeeListingByCompanyTmsCountQueryHookResult = ReturnType<typeof useEmployeeListingByCompanyTmsCountQuery>;
export type EmployeeListingByCompanyTmsCountLazyQueryHookResult = ReturnType<typeof useEmployeeListingByCompanyTmsCountLazyQuery>;
export type EmployeeListingByCompanyTmsCountQueryResult = ApolloReactCommon.QueryResult<EmployeeListingByCompanyTmsCountQuery, EmployeeListingByCompanyTmsCountQueryVariables>;
export const BatchEntryByShiftInsertDocument = gql`
    mutation BatchEntryByShiftInsert($input: DutyRosterInput!, $EmployeeIDs: [String!]!) {
  BatchEntryByShiftInsert(input: $input, EmployeeIDs: $EmployeeIDs)
}
    `;
export type BatchEntryByShiftInsertMutationFn = ApolloReactCommon.MutationFunction<BatchEntryByShiftInsertMutation, BatchEntryByShiftInsertMutationVariables>;

/**
 * __useBatchEntryByShiftInsertMutation__
 *
 * To run a mutation, you first call `useBatchEntryByShiftInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchEntryByShiftInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchEntryByShiftInsertMutation, { data, loading, error }] = useBatchEntryByShiftInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *   },
 * });
 */
export function useBatchEntryByShiftInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BatchEntryByShiftInsertMutation, BatchEntryByShiftInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<BatchEntryByShiftInsertMutation, BatchEntryByShiftInsertMutationVariables>(BatchEntryByShiftInsertDocument, baseOptions);
      }
export type BatchEntryByShiftInsertMutationHookResult = ReturnType<typeof useBatchEntryByShiftInsertMutation>;
export type BatchEntryByShiftInsertMutationResult = ApolloReactCommon.MutationResult<BatchEntryByShiftInsertMutation>;
export type BatchEntryByShiftInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<BatchEntryByShiftInsertMutation, BatchEntryByShiftInsertMutationVariables>;
export const GetEmployeesByShiftGroupDataDocument = gql`
    query GetEmployeesByShiftGroupData($input: TemplateLibraryAssignmentInput) {
  getTemplateLibraryAssignment(input: $input) {
    TargetID
    SourceID
    CompanyID
    Target {
      ... on EmployeeEntity {
        EmployeeID
        EmployeeNo
        OrgUnitID
        Contact {
          FullName
        }
        Company {
          CompanyID
          CompanyName
        }
        JobGrade {
          JobGradeID
          Description
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeesByShiftGroupDataQuery__
 *
 * To run a query within a React component, call `useGetEmployeesByShiftGroupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesByShiftGroupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesByShiftGroupDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEmployeesByShiftGroupDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeesByShiftGroupDataQuery, GetEmployeesByShiftGroupDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeesByShiftGroupDataQuery, GetEmployeesByShiftGroupDataQueryVariables>(GetEmployeesByShiftGroupDataDocument, baseOptions);
      }
export function useGetEmployeesByShiftGroupDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeesByShiftGroupDataQuery, GetEmployeesByShiftGroupDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeesByShiftGroupDataQuery, GetEmployeesByShiftGroupDataQueryVariables>(GetEmployeesByShiftGroupDataDocument, baseOptions);
        }
export type GetEmployeesByShiftGroupDataQueryHookResult = ReturnType<typeof useGetEmployeesByShiftGroupDataQuery>;
export type GetEmployeesByShiftGroupDataLazyQueryHookResult = ReturnType<typeof useGetEmployeesByShiftGroupDataLazyQuery>;
export type GetEmployeesByShiftGroupDataQueryResult = ApolloReactCommon.QueryResult<GetEmployeesByShiftGroupDataQuery, GetEmployeesByShiftGroupDataQueryVariables>;
export const GetShiftGroupByTlaDocument = gql`
    query getShiftGroupByTLA($CompanyID: String!, $ShiftID: String) {
  getShiftGroupByTLA(CompanyID: $CompanyID, ShiftID: $ShiftID) {
    ShiftGroupID
    SubscriptionAccountID
    Name
    Description
    Start
    End
    Status
  }
}
    `;

/**
 * __useGetShiftGroupByTlaQuery__
 *
 * To run a query within a React component, call `useGetShiftGroupByTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftGroupByTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftGroupByTlaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ShiftID: // value for 'ShiftID'
 *   },
 * });
 */
export function useGetShiftGroupByTlaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShiftGroupByTlaQuery, GetShiftGroupByTlaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShiftGroupByTlaQuery, GetShiftGroupByTlaQueryVariables>(GetShiftGroupByTlaDocument, baseOptions);
      }
export function useGetShiftGroupByTlaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShiftGroupByTlaQuery, GetShiftGroupByTlaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShiftGroupByTlaQuery, GetShiftGroupByTlaQueryVariables>(GetShiftGroupByTlaDocument, baseOptions);
        }
export type GetShiftGroupByTlaQueryHookResult = ReturnType<typeof useGetShiftGroupByTlaQuery>;
export type GetShiftGroupByTlaLazyQueryHookResult = ReturnType<typeof useGetShiftGroupByTlaLazyQuery>;
export type GetShiftGroupByTlaQueryResult = ApolloReactCommon.QueryResult<GetShiftGroupByTlaQuery, GetShiftGroupByTlaQueryVariables>;
export const ShiftPatternAssignmentToEmployeeDocument = gql`
    mutation ShiftPatternAssignmentToEmployee($CompanyID: String!, $EmployeeIDs: [String!]!, $ShiftPatternID: String!, $FromDate: String!, $ToDate: String!) {
  ShiftPatternAssignmentToEmployee(CompanyID: $CompanyID, EmployeeIDs: $EmployeeIDs, ShiftPatternID: $ShiftPatternID, FromDate: $FromDate, ToDate: $ToDate)
}
    `;
export type ShiftPatternAssignmentToEmployeeMutationFn = ApolloReactCommon.MutationFunction<ShiftPatternAssignmentToEmployeeMutation, ShiftPatternAssignmentToEmployeeMutationVariables>;

/**
 * __useShiftPatternAssignmentToEmployeeMutation__
 *
 * To run a mutation, you first call `useShiftPatternAssignmentToEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftPatternAssignmentToEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftPatternAssignmentToEmployeeMutation, { data, loading, error }] = useShiftPatternAssignmentToEmployeeMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      ShiftPatternID: // value for 'ShiftPatternID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useShiftPatternAssignmentToEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShiftPatternAssignmentToEmployeeMutation, ShiftPatternAssignmentToEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<ShiftPatternAssignmentToEmployeeMutation, ShiftPatternAssignmentToEmployeeMutationVariables>(ShiftPatternAssignmentToEmployeeDocument, baseOptions);
      }
export type ShiftPatternAssignmentToEmployeeMutationHookResult = ReturnType<typeof useShiftPatternAssignmentToEmployeeMutation>;
export type ShiftPatternAssignmentToEmployeeMutationResult = ApolloReactCommon.MutationResult<ShiftPatternAssignmentToEmployeeMutation>;
export type ShiftPatternAssignmentToEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<ShiftPatternAssignmentToEmployeeMutation, ShiftPatternAssignmentToEmployeeMutationVariables>;
export const CreateBatchEntryByShiftPatternDocument = gql`
    mutation createBatchEntryByShiftPattern($Input: DutyRosterInput!, $EmployeeIDs: [String!]!) {
  createBatchEntryByShiftPattern(Input: $Input, EmployeeIDs: $EmployeeIDs)
}
    `;
export type CreateBatchEntryByShiftPatternMutationFn = ApolloReactCommon.MutationFunction<CreateBatchEntryByShiftPatternMutation, CreateBatchEntryByShiftPatternMutationVariables>;

/**
 * __useCreateBatchEntryByShiftPatternMutation__
 *
 * To run a mutation, you first call `useCreateBatchEntryByShiftPatternMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchEntryByShiftPatternMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchEntryByShiftPatternMutation, { data, loading, error }] = useCreateBatchEntryByShiftPatternMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *   },
 * });
 */
export function useCreateBatchEntryByShiftPatternMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchEntryByShiftPatternMutation, CreateBatchEntryByShiftPatternMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchEntryByShiftPatternMutation, CreateBatchEntryByShiftPatternMutationVariables>(CreateBatchEntryByShiftPatternDocument, baseOptions);
      }
export type CreateBatchEntryByShiftPatternMutationHookResult = ReturnType<typeof useCreateBatchEntryByShiftPatternMutation>;
export type CreateBatchEntryByShiftPatternMutationResult = ApolloReactCommon.MutationResult<CreateBatchEntryByShiftPatternMutation>;
export type CreateBatchEntryByShiftPatternMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchEntryByShiftPatternMutation, CreateBatchEntryByShiftPatternMutationVariables>;
export const GetShiftGroupByShiftPatternDocument = gql`
    query getShiftGroupByShiftPattern($CompanyID: String!, $ShiftPatternID: String!) {
  getShiftGroupByShiftPattern(CompanyID: $CompanyID, ShiftPatternID: $ShiftPatternID) {
    ShiftGroupID
    SubscriptionAccountID
    Name
    Description
    Start
    End
    Status
  }
}
    `;

/**
 * __useGetShiftGroupByShiftPatternQuery__
 *
 * To run a query within a React component, call `useGetShiftGroupByShiftPatternQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftGroupByShiftPatternQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftGroupByShiftPatternQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ShiftPatternID: // value for 'ShiftPatternID'
 *   },
 * });
 */
export function useGetShiftGroupByShiftPatternQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShiftGroupByShiftPatternQuery, GetShiftGroupByShiftPatternQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShiftGroupByShiftPatternQuery, GetShiftGroupByShiftPatternQueryVariables>(GetShiftGroupByShiftPatternDocument, baseOptions);
      }
export function useGetShiftGroupByShiftPatternLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShiftGroupByShiftPatternQuery, GetShiftGroupByShiftPatternQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShiftGroupByShiftPatternQuery, GetShiftGroupByShiftPatternQueryVariables>(GetShiftGroupByShiftPatternDocument, baseOptions);
        }
export type GetShiftGroupByShiftPatternQueryHookResult = ReturnType<typeof useGetShiftGroupByShiftPatternQuery>;
export type GetShiftGroupByShiftPatternLazyQueryHookResult = ReturnType<typeof useGetShiftGroupByShiftPatternLazyQuery>;
export type GetShiftGroupByShiftPatternQueryResult = ApolloReactCommon.QueryResult<GetShiftGroupByShiftPatternQuery, GetShiftGroupByShiftPatternQueryVariables>;
export const GetShiftPatternByTlaDocument = gql`
    query getShiftPatternByTLA($CompanyID: String!) {
  getShiftPatternByTLA(CompanyID: $CompanyID) {
    ShiftPatternID
    Active
    Name
    Start
    End
    CompanyID
    SubscriptionAccountID
    Status
    Details {
      ShiftPatternDetailID
      Days
      ShiftTypeID
      shiftDay {
        ShiftID
        ShiftDayID
        DayType
      }
      ShiftDayID
    }
  }
}
    `;

/**
 * __useGetShiftPatternByTlaQuery__
 *
 * To run a query within a React component, call `useGetShiftPatternByTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftPatternByTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftPatternByTlaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetShiftPatternByTlaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShiftPatternByTlaQuery, GetShiftPatternByTlaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShiftPatternByTlaQuery, GetShiftPatternByTlaQueryVariables>(GetShiftPatternByTlaDocument, baseOptions);
      }
export function useGetShiftPatternByTlaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShiftPatternByTlaQuery, GetShiftPatternByTlaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShiftPatternByTlaQuery, GetShiftPatternByTlaQueryVariables>(GetShiftPatternByTlaDocument, baseOptions);
        }
export type GetShiftPatternByTlaQueryHookResult = ReturnType<typeof useGetShiftPatternByTlaQuery>;
export type GetShiftPatternByTlaLazyQueryHookResult = ReturnType<typeof useGetShiftPatternByTlaLazyQuery>;
export type GetShiftPatternByTlaQueryResult = ApolloReactCommon.QueryResult<GetShiftPatternByTlaQuery, GetShiftPatternByTlaQueryVariables>;
export const DutyRosterByExcelDocument = gql`
    query DutyRosterByExcel($CompanyID: String!) {
  DutyRosterByExcel(CompanyID: $CompanyID)
}
    `;

/**
 * __useDutyRosterByExcelQuery__
 *
 * To run a query within a React component, call `useDutyRosterByExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useDutyRosterByExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDutyRosterByExcelQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useDutyRosterByExcelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DutyRosterByExcelQuery, DutyRosterByExcelQueryVariables>) {
        return ApolloReactHooks.useQuery<DutyRosterByExcelQuery, DutyRosterByExcelQueryVariables>(DutyRosterByExcelDocument, baseOptions);
      }
export function useDutyRosterByExcelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DutyRosterByExcelQuery, DutyRosterByExcelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DutyRosterByExcelQuery, DutyRosterByExcelQueryVariables>(DutyRosterByExcelDocument, baseOptions);
        }
export type DutyRosterByExcelQueryHookResult = ReturnType<typeof useDutyRosterByExcelQuery>;
export type DutyRosterByExcelLazyQueryHookResult = ReturnType<typeof useDutyRosterByExcelLazyQuery>;
export type DutyRosterByExcelQueryResult = ApolloReactCommon.QueryResult<DutyRosterByExcelQuery, DutyRosterByExcelQueryVariables>;
export const DutyRosterEmployeeSumDocument = gql`
    query DutyRosterEmployeeSum($CompanyID: String!, $date: DateTime!) {
  DutyRosterEmployeeSum(CompanyID: $CompanyID, date: $date)
}
    `;

/**
 * __useDutyRosterEmployeeSumQuery__
 *
 * To run a query within a React component, call `useDutyRosterEmployeeSumQuery` and pass it any options that fit your needs.
 * When your component renders, `useDutyRosterEmployeeSumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDutyRosterEmployeeSumQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDutyRosterEmployeeSumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DutyRosterEmployeeSumQuery, DutyRosterEmployeeSumQueryVariables>) {
        return ApolloReactHooks.useQuery<DutyRosterEmployeeSumQuery, DutyRosterEmployeeSumQueryVariables>(DutyRosterEmployeeSumDocument, baseOptions);
      }
export function useDutyRosterEmployeeSumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DutyRosterEmployeeSumQuery, DutyRosterEmployeeSumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DutyRosterEmployeeSumQuery, DutyRosterEmployeeSumQueryVariables>(DutyRosterEmployeeSumDocument, baseOptions);
        }
export type DutyRosterEmployeeSumQueryHookResult = ReturnType<typeof useDutyRosterEmployeeSumQuery>;
export type DutyRosterEmployeeSumLazyQueryHookResult = ReturnType<typeof useDutyRosterEmployeeSumLazyQuery>;
export type DutyRosterEmployeeSumQueryResult = ApolloReactCommon.QueryResult<DutyRosterEmployeeSumQuery, DutyRosterEmployeeSumQueryVariables>;
export const DutyRosterSummaryDocument = gql`
    query DutyRosterSummary($CompanyID: String!, $date: DateTime!) {
  DutyRosterSummary(CompanyID: $CompanyID, date: $date)
}
    `;

/**
 * __useDutyRosterSummaryQuery__
 *
 * To run a query within a React component, call `useDutyRosterSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDutyRosterSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDutyRosterSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDutyRosterSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DutyRosterSummaryQuery, DutyRosterSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<DutyRosterSummaryQuery, DutyRosterSummaryQueryVariables>(DutyRosterSummaryDocument, baseOptions);
      }
export function useDutyRosterSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DutyRosterSummaryQuery, DutyRosterSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DutyRosterSummaryQuery, DutyRosterSummaryQueryVariables>(DutyRosterSummaryDocument, baseOptions);
        }
export type DutyRosterSummaryQueryHookResult = ReturnType<typeof useDutyRosterSummaryQuery>;
export type DutyRosterSummaryLazyQueryHookResult = ReturnType<typeof useDutyRosterSummaryLazyQuery>;
export type DutyRosterSummaryQueryResult = ApolloReactCommon.QueryResult<DutyRosterSummaryQuery, DutyRosterSummaryQueryVariables>;
export const OvertimeListingByPeriodDocument = gql`
    query OvertimeListingByPeriod($StartMonth: DateTime!, $EndMonth: DateTime!, $CompanyID: String!) {
  OvertimeListingByPeriod(StartMonth: $StartMonth, EndMonth: $EndMonth, CompanyID: $CompanyID) {
    OvertimeID
    EmployeeID
    WorkDate
    StartTime
    EndTime
    Remark
    OvertimeReasonID
    OvertimeStatus
    CreatedDT
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    otReason {
      OvertimeReasonID
      Name
    }
  }
}
    `;

/**
 * __useOvertimeListingByPeriodQuery__
 *
 * To run a query within a React component, call `useOvertimeListingByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useOvertimeListingByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOvertimeListingByPeriodQuery({
 *   variables: {
 *      StartMonth: // value for 'StartMonth'
 *      EndMonth: // value for 'EndMonth'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useOvertimeListingByPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OvertimeListingByPeriodQuery, OvertimeListingByPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<OvertimeListingByPeriodQuery, OvertimeListingByPeriodQueryVariables>(OvertimeListingByPeriodDocument, baseOptions);
      }
export function useOvertimeListingByPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OvertimeListingByPeriodQuery, OvertimeListingByPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OvertimeListingByPeriodQuery, OvertimeListingByPeriodQueryVariables>(OvertimeListingByPeriodDocument, baseOptions);
        }
export type OvertimeListingByPeriodQueryHookResult = ReturnType<typeof useOvertimeListingByPeriodQuery>;
export type OvertimeListingByPeriodLazyQueryHookResult = ReturnType<typeof useOvertimeListingByPeriodLazyQuery>;
export type OvertimeListingByPeriodQueryResult = ApolloReactCommon.QueryResult<OvertimeListingByPeriodQuery, OvertimeListingByPeriodQueryVariables>;
export const OvertimeMenuSummaryDocument = gql`
    query OvertimeMenuSummary($CompanyID: String!) {
  OvertimeMenuSummary(CompanyID: $CompanyID)
}
    `;

/**
 * __useOvertimeMenuSummaryQuery__
 *
 * To run a query within a React component, call `useOvertimeMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOvertimeMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOvertimeMenuSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useOvertimeMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OvertimeMenuSummaryQuery, OvertimeMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<OvertimeMenuSummaryQuery, OvertimeMenuSummaryQueryVariables>(OvertimeMenuSummaryDocument, baseOptions);
      }
export function useOvertimeMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OvertimeMenuSummaryQuery, OvertimeMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OvertimeMenuSummaryQuery, OvertimeMenuSummaryQueryVariables>(OvertimeMenuSummaryDocument, baseOptions);
        }
export type OvertimeMenuSummaryQueryHookResult = ReturnType<typeof useOvertimeMenuSummaryQuery>;
export type OvertimeMenuSummaryLazyQueryHookResult = ReturnType<typeof useOvertimeMenuSummaryLazyQuery>;
export type OvertimeMenuSummaryQueryResult = ApolloReactCommon.QueryResult<OvertimeMenuSummaryQuery, OvertimeMenuSummaryQueryVariables>;
export const TimeOffListingByPeriodDocument = gql`
    query TimeOffListingByPeriod($StartMonth: DateTime!, $EndMonth: DateTime!, $CompanyID: String!) {
  TimeOffListingByPeriod(StartMonth: $StartMonth, EndMonth: $EndMonth, CompanyID: $CompanyID) {
    TimeOffID
    EmployeeID
    WorkDate
    StartTime
    EndTime
    Duration
    VisitingPlace
    TravelFrom
    TravelTo
    ToReasonID
    TimeOffStatus
    Remark
    CreatedDT
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    ToReason {
      TimeOffReasonID
      Name
    }
  }
}
    `;

/**
 * __useTimeOffListingByPeriodQuery__
 *
 * To run a query within a React component, call `useTimeOffListingByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffListingByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffListingByPeriodQuery({
 *   variables: {
 *      StartMonth: // value for 'StartMonth'
 *      EndMonth: // value for 'EndMonth'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useTimeOffListingByPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimeOffListingByPeriodQuery, TimeOffListingByPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<TimeOffListingByPeriodQuery, TimeOffListingByPeriodQueryVariables>(TimeOffListingByPeriodDocument, baseOptions);
      }
export function useTimeOffListingByPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeOffListingByPeriodQuery, TimeOffListingByPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimeOffListingByPeriodQuery, TimeOffListingByPeriodQueryVariables>(TimeOffListingByPeriodDocument, baseOptions);
        }
export type TimeOffListingByPeriodQueryHookResult = ReturnType<typeof useTimeOffListingByPeriodQuery>;
export type TimeOffListingByPeriodLazyQueryHookResult = ReturnType<typeof useTimeOffListingByPeriodLazyQuery>;
export type TimeOffListingByPeriodQueryResult = ApolloReactCommon.QueryResult<TimeOffListingByPeriodQuery, TimeOffListingByPeriodQueryVariables>;
export const OvertimeListingByDateTimeDocument = gql`
    query OvertimeListingByDateTime($StartDate: DateTime!, $EndDate: DateTime!, $CompanyID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!], $sort: String, $orderBy: String) {
  OvertimeListingByDateTime(StartDate: $StartDate, EndDate: $EndDate, CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, sort: $sort, orderBy: $orderBy) {
    OvertimeID
    EmployeeID
    WorkDate
    StartTime
    EndTime
    Approver
    Duration
    Remark
    OvertimeStatus
    CreatedDT
    Employee {
      OrgUnitID
      JobGradeID
      JoinedDate
      CompanyID
      FormStatus
      EmployeeID
      DepartmentID
      DivisionID
      EmploymentType
      ReportTo
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    otReason {
      OvertimeReasonID
      Name
    }
    ModifiedBy
    ModifiedDT
    CreatedBy
    CreatedDT
    ApproverID
    RejectionRemarks
    ApproveDate
    CancelledBy
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        NotifiedDT
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
          ApproverName
        }
      }
    }
    Documents {
      DocumentID
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useOvertimeListingByDateTimeQuery__
 *
 * To run a query within a React component, call `useOvertimeListingByDateTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOvertimeListingByDateTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOvertimeListingByDateTimeQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      CompanyID: // value for 'CompanyID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOvertimeListingByDateTimeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OvertimeListingByDateTimeQuery, OvertimeListingByDateTimeQueryVariables>) {
        return ApolloReactHooks.useQuery<OvertimeListingByDateTimeQuery, OvertimeListingByDateTimeQueryVariables>(OvertimeListingByDateTimeDocument, baseOptions);
      }
export function useOvertimeListingByDateTimeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OvertimeListingByDateTimeQuery, OvertimeListingByDateTimeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OvertimeListingByDateTimeQuery, OvertimeListingByDateTimeQueryVariables>(OvertimeListingByDateTimeDocument, baseOptions);
        }
export type OvertimeListingByDateTimeQueryHookResult = ReturnType<typeof useOvertimeListingByDateTimeQuery>;
export type OvertimeListingByDateTimeLazyQueryHookResult = ReturnType<typeof useOvertimeListingByDateTimeLazyQuery>;
export type OvertimeListingByDateTimeQueryResult = ApolloReactCommon.QueryResult<OvertimeListingByDateTimeQuery, OvertimeListingByDateTimeQueryVariables>;
export const OvertimeListingByDateTimeV2Document = gql`
    query OvertimeListingByDateTimeV2($StartDate: DateTime!, $EndDate: DateTime!, $CompanyID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!], $sort: String, $orderBy: String) {
  OvertimeListingByDateTimeV2(StartDate: $StartDate, EndDate: $EndDate, CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, sort: $sort, orderBy: $orderBy) {
    OvertimeID
    EmployeeID
    WorkDate
    StartTime
    EndTime
    Approver
    Duration
    Remark
    OvertimeStatus
    CreatedDT
    Employee {
      OrgUnitID
      JobGradeID
      JoinedDate
      CompanyID
      FormStatus
      EmployeeID
      DepartmentID
      DivisionID
      EmploymentType
      ReportTo
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    otReason {
      OvertimeReasonID
      Name
    }
    ModifiedDT
    CreatedDT
    ApproverID
    RejectionRemarks
    ApproveDate
    CancelledBy
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        NotifiedDT
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
          ApproverName
        }
      }
    }
    Documents {
      DocumentID
      DocumentFile
      Description
    }
  }
}
    `;

/**
 * __useOvertimeListingByDateTimeV2Query__
 *
 * To run a query within a React component, call `useOvertimeListingByDateTimeV2Query` and pass it any options that fit your needs.
 * When your component renders, `useOvertimeListingByDateTimeV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOvertimeListingByDateTimeV2Query({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      CompanyID: // value for 'CompanyID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOvertimeListingByDateTimeV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<OvertimeListingByDateTimeV2Query, OvertimeListingByDateTimeV2QueryVariables>) {
        return ApolloReactHooks.useQuery<OvertimeListingByDateTimeV2Query, OvertimeListingByDateTimeV2QueryVariables>(OvertimeListingByDateTimeV2Document, baseOptions);
      }
export function useOvertimeListingByDateTimeV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OvertimeListingByDateTimeV2Query, OvertimeListingByDateTimeV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OvertimeListingByDateTimeV2Query, OvertimeListingByDateTimeV2QueryVariables>(OvertimeListingByDateTimeV2Document, baseOptions);
        }
export type OvertimeListingByDateTimeV2QueryHookResult = ReturnType<typeof useOvertimeListingByDateTimeV2Query>;
export type OvertimeListingByDateTimeV2LazyQueryHookResult = ReturnType<typeof useOvertimeListingByDateTimeV2LazyQuery>;
export type OvertimeListingByDateTimeV2QueryResult = ApolloReactCommon.QueryResult<OvertimeListingByDateTimeV2Query, OvertimeListingByDateTimeV2QueryVariables>;
export const OvertimeListingByYearDocument = gql`
    query OvertimeListingByYear($WorkDate: DateTime!, $CompanyID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!]) {
  OvertimeListingByYear(WorkDate: $WorkDate, CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs) {
    OvertimeID
    EmployeeID
    WorkDate
    StartTime
    EndTime
    Approver
    Duration
    Remark
    OvertimeStatus
    CreatedDT
    Employee {
      OrgUnitID
      JobGradeID
      JoinedDate
      CompanyID
      FormStatus
      EmployeeID
      DepartmentID
      DivisionID
      EmploymentType
      ReportTo
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    otReason {
      OvertimeReasonID
      Name
    }
    ModifiedBy
    ModifiedDT
    CreatedBy
    CreatedDT
    ApproverID
    RejectionRemarks
    ApproveDate
    CancelledBy
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        NotifiedDT
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
          ApproverName
        }
      }
    }
  }
}
    `;

/**
 * __useOvertimeListingByYearQuery__
 *
 * To run a query within a React component, call `useOvertimeListingByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useOvertimeListingByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOvertimeListingByYearQuery({
 *   variables: {
 *      WorkDate: // value for 'WorkDate'
 *      CompanyID: // value for 'CompanyID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *   },
 * });
 */
export function useOvertimeListingByYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OvertimeListingByYearQuery, OvertimeListingByYearQueryVariables>) {
        return ApolloReactHooks.useQuery<OvertimeListingByYearQuery, OvertimeListingByYearQueryVariables>(OvertimeListingByYearDocument, baseOptions);
      }
export function useOvertimeListingByYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OvertimeListingByYearQuery, OvertimeListingByYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OvertimeListingByYearQuery, OvertimeListingByYearQueryVariables>(OvertimeListingByYearDocument, baseOptions);
        }
export type OvertimeListingByYearQueryHookResult = ReturnType<typeof useOvertimeListingByYearQuery>;
export type OvertimeListingByYearLazyQueryHookResult = ReturnType<typeof useOvertimeListingByYearLazyQuery>;
export type OvertimeListingByYearQueryResult = ApolloReactCommon.QueryResult<OvertimeListingByYearQuery, OvertimeListingByYearQueryVariables>;
export const OvertimePeriodSummaryDocument = gql`
    query OvertimePeriodSummary($CreatedDT: DateTime, $CompanyID: String!) {
  OvertimePeriodSummary(CreatedDT: $CreatedDT, CompanyID: $CompanyID)
}
    `;

/**
 * __useOvertimePeriodSummaryQuery__
 *
 * To run a query within a React component, call `useOvertimePeriodSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOvertimePeriodSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOvertimePeriodSummaryQuery({
 *   variables: {
 *      CreatedDT: // value for 'CreatedDT'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useOvertimePeriodSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OvertimePeriodSummaryQuery, OvertimePeriodSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<OvertimePeriodSummaryQuery, OvertimePeriodSummaryQueryVariables>(OvertimePeriodSummaryDocument, baseOptions);
      }
export function useOvertimePeriodSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OvertimePeriodSummaryQuery, OvertimePeriodSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OvertimePeriodSummaryQuery, OvertimePeriodSummaryQueryVariables>(OvertimePeriodSummaryDocument, baseOptions);
        }
export type OvertimePeriodSummaryQueryHookResult = ReturnType<typeof useOvertimePeriodSummaryQuery>;
export type OvertimePeriodSummaryLazyQueryHookResult = ReturnType<typeof useOvertimePeriodSummaryLazyQuery>;
export type OvertimePeriodSummaryQueryResult = ApolloReactCommon.QueryResult<OvertimePeriodSummaryQuery, OvertimePeriodSummaryQueryVariables>;
export const GetAttendanceRecordManualEntryPageDataDocument = gql`
    query GetAttendanceRecordManualEntryPageData($input: AttendanceRecordInput, $timezoneOffset: Float, $DateTimeStart: DateTime, $DateTimeEnd: DateTime, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!], $DateTime: DateTime, $offset: Float, $limit: Float, $keyword: String) {
  getAttendanceRecord(input: $input, timezoneOffset: $timezoneOffset, DateTimeStart: $DateTimeStart, DateTimeEnd: $DateTimeEnd, DepartmentIDs: $DepartmentIDs, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, DateTime: $DateTime, offset: $offset, limit: $limit, keyword: $keyword) {
    CompanyID
    AttendanceRecordID
    DateTime
    ModifiedDT
    Remark
    IntegrationClockType
    CheckInMethod
    Location
    Lat
    Long
    Address
    Document {
      DocumentID
      DocumentFile
    }
    Employee {
      DepartmentID
      DivisionID
      EmployeeID
      Status
      EmployeeNo
      Contact {
        FullName
      }
    }
  }
  GetAttendanceRecordManualEntryPageTotalCount(input: $input, timezoneOffset: $timezoneOffset, DateTimeStart: $DateTimeStart, DateTimeEnd: $DateTimeEnd, DepartmentIDs: $DepartmentIDs, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, DateTime: $DateTime, keyword: $keyword)
}
    `;

/**
 * __useGetAttendanceRecordManualEntryPageDataQuery__
 *
 * To run a query within a React component, call `useGetAttendanceRecordManualEntryPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceRecordManualEntryPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceRecordManualEntryPageDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *      timezoneOffset: // value for 'timezoneOffset'
 *      DateTimeStart: // value for 'DateTimeStart'
 *      DateTimeEnd: // value for 'DateTimeEnd'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      DateTime: // value for 'DateTime'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGetAttendanceRecordManualEntryPageDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendanceRecordManualEntryPageDataQuery, GetAttendanceRecordManualEntryPageDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendanceRecordManualEntryPageDataQuery, GetAttendanceRecordManualEntryPageDataQueryVariables>(GetAttendanceRecordManualEntryPageDataDocument, baseOptions);
      }
export function useGetAttendanceRecordManualEntryPageDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendanceRecordManualEntryPageDataQuery, GetAttendanceRecordManualEntryPageDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendanceRecordManualEntryPageDataQuery, GetAttendanceRecordManualEntryPageDataQueryVariables>(GetAttendanceRecordManualEntryPageDataDocument, baseOptions);
        }
export type GetAttendanceRecordManualEntryPageDataQueryHookResult = ReturnType<typeof useGetAttendanceRecordManualEntryPageDataQuery>;
export type GetAttendanceRecordManualEntryPageDataLazyQueryHookResult = ReturnType<typeof useGetAttendanceRecordManualEntryPageDataLazyQuery>;
export type GetAttendanceRecordManualEntryPageDataQueryResult = ApolloReactCommon.QueryResult<GetAttendanceRecordManualEntryPageDataQuery, GetAttendanceRecordManualEntryPageDataQueryVariables>;
export const EmployeeSummaryTmsDocument = gql`
    query EmployeeSummaryTms($CompanyID: String!, $offset: Float, $limit: Float, $keyword: String, $ExcludeFormStatus: String, $ExcludeEmployeeStatus: [String!], $CheckForResignedDate: [String!], $Page: String) {
  EmployeeListing(CompanyID: $CompanyID, offset: $offset, limit: $limit, keyword: $keyword, ExcludeFormStatus: $ExcludeFormStatus, ExcludeEmployeeStatus: $ExcludeEmployeeStatus, CheckForResignedDate: $CheckForResignedDate, Page: $Page) {
    EmployeeID
    CompanyID
    Contact {
      ContactID
      FullName
      Email
      MobileNo
    }
    EmployeeNo
    Company {
      CompanyID
      CompanyName
    }
    OvertimeList {
      OvertimeID
      EmployeeID
      WorkDate
      StartTime
      EndTime
      Remark
      CreatedDT
      otReason {
        OvertimeReasonID
        Name
      }
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    Status
    FormStatus
    ResignedDate
    JobGradeID
    JobGrade {
      JobGradeID
      Description
    }
  }
  OTReasonByTLA(CompanyID: $CompanyID) {
    OvertimeReasonID
    Name
  }
}
    `;

/**
 * __useEmployeeSummaryTmsQuery__
 *
 * To run a query within a React component, call `useEmployeeSummaryTmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSummaryTmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeSummaryTmsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *      ExcludeEmployeeStatus: // value for 'ExcludeEmployeeStatus'
 *      CheckForResignedDate: // value for 'CheckForResignedDate'
 *      Page: // value for 'Page'
 *   },
 * });
 */
export function useEmployeeSummaryTmsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeSummaryTmsQuery, EmployeeSummaryTmsQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeSummaryTmsQuery, EmployeeSummaryTmsQueryVariables>(EmployeeSummaryTmsDocument, baseOptions);
      }
export function useEmployeeSummaryTmsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeSummaryTmsQuery, EmployeeSummaryTmsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeSummaryTmsQuery, EmployeeSummaryTmsQueryVariables>(EmployeeSummaryTmsDocument, baseOptions);
        }
export type EmployeeSummaryTmsQueryHookResult = ReturnType<typeof useEmployeeSummaryTmsQuery>;
export type EmployeeSummaryTmsLazyQueryHookResult = ReturnType<typeof useEmployeeSummaryTmsLazyQuery>;
export type EmployeeSummaryTmsQueryResult = ApolloReactCommon.QueryResult<EmployeeSummaryTmsQuery, EmployeeSummaryTmsQueryVariables>;
export const EmployeeSummaryTmsCountDocument = gql`
    query EmployeeSummaryTmsCount($CompanyID: String!, $keyword: String, $ExcludeFormStatus: String, $ExcludeEmployeeStatus: [String!], $CheckForResignedDate: [String!], $Page: String) {
  EmployeeListingCount(CompanyID: $CompanyID, keyword: $keyword, ExcludeFormStatus: $ExcludeFormStatus, ExcludeEmployeeStatus: $ExcludeEmployeeStatus, CheckForResignedDate: $CheckForResignedDate, Page: $Page)
}
    `;

/**
 * __useEmployeeSummaryTmsCountQuery__
 *
 * To run a query within a React component, call `useEmployeeSummaryTmsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSummaryTmsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeSummaryTmsCountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      keyword: // value for 'keyword'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *      ExcludeEmployeeStatus: // value for 'ExcludeEmployeeStatus'
 *      CheckForResignedDate: // value for 'CheckForResignedDate'
 *      Page: // value for 'Page'
 *   },
 * });
 */
export function useEmployeeSummaryTmsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeSummaryTmsCountQuery, EmployeeSummaryTmsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeSummaryTmsCountQuery, EmployeeSummaryTmsCountQueryVariables>(EmployeeSummaryTmsCountDocument, baseOptions);
      }
export function useEmployeeSummaryTmsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeSummaryTmsCountQuery, EmployeeSummaryTmsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeSummaryTmsCountQuery, EmployeeSummaryTmsCountQueryVariables>(EmployeeSummaryTmsCountDocument, baseOptions);
        }
export type EmployeeSummaryTmsCountQueryHookResult = ReturnType<typeof useEmployeeSummaryTmsCountQuery>;
export type EmployeeSummaryTmsCountLazyQueryHookResult = ReturnType<typeof useEmployeeSummaryTmsCountLazyQuery>;
export type EmployeeSummaryTmsCountQueryResult = ApolloReactCommon.QueryResult<EmployeeSummaryTmsCountQuery, EmployeeSummaryTmsCountQueryVariables>;
export const OvertimeListingByExcelDocument = gql`
    query OvertimeListingByExcel($CompanyID: String!) {
  OvertimeListingByExcel(CompanyID: $CompanyID)
}
    `;

/**
 * __useOvertimeListingByExcelQuery__
 *
 * To run a query within a React component, call `useOvertimeListingByExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useOvertimeListingByExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOvertimeListingByExcelQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useOvertimeListingByExcelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OvertimeListingByExcelQuery, OvertimeListingByExcelQueryVariables>) {
        return ApolloReactHooks.useQuery<OvertimeListingByExcelQuery, OvertimeListingByExcelQueryVariables>(OvertimeListingByExcelDocument, baseOptions);
      }
export function useOvertimeListingByExcelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OvertimeListingByExcelQuery, OvertimeListingByExcelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OvertimeListingByExcelQuery, OvertimeListingByExcelQueryVariables>(OvertimeListingByExcelDocument, baseOptions);
        }
export type OvertimeListingByExcelQueryHookResult = ReturnType<typeof useOvertimeListingByExcelQuery>;
export type OvertimeListingByExcelLazyQueryHookResult = ReturnType<typeof useOvertimeListingByExcelLazyQuery>;
export type OvertimeListingByExcelQueryResult = ApolloReactCommon.QueryResult<OvertimeListingByExcelQuery, OvertimeListingByExcelQueryVariables>;
export const GetOvertimeReasonDocument = gql`
    query getOvertimeReason {
  getOvertimeReason {
    OvertimeReasonID
    Name
  }
}
    `;

/**
 * __useGetOvertimeReasonQuery__
 *
 * To run a query within a React component, call `useGetOvertimeReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOvertimeReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOvertimeReasonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOvertimeReasonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOvertimeReasonQuery, GetOvertimeReasonQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOvertimeReasonQuery, GetOvertimeReasonQueryVariables>(GetOvertimeReasonDocument, baseOptions);
      }
export function useGetOvertimeReasonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOvertimeReasonQuery, GetOvertimeReasonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOvertimeReasonQuery, GetOvertimeReasonQueryVariables>(GetOvertimeReasonDocument, baseOptions);
        }
export type GetOvertimeReasonQueryHookResult = ReturnType<typeof useGetOvertimeReasonQuery>;
export type GetOvertimeReasonLazyQueryHookResult = ReturnType<typeof useGetOvertimeReasonLazyQuery>;
export type GetOvertimeReasonQueryResult = ApolloReactCommon.QueryResult<GetOvertimeReasonQuery, GetOvertimeReasonQueryVariables>;
export const TimeOffMenuSummaryDocument = gql`
    query TimeOffMenuSummary($CompanyID: String!) {
  TimeOffMenuSummary(CompanyID: $CompanyID)
}
    `;

/**
 * __useTimeOffMenuSummaryQuery__
 *
 * To run a query within a React component, call `useTimeOffMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffMenuSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useTimeOffMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimeOffMenuSummaryQuery, TimeOffMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<TimeOffMenuSummaryQuery, TimeOffMenuSummaryQueryVariables>(TimeOffMenuSummaryDocument, baseOptions);
      }
export function useTimeOffMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeOffMenuSummaryQuery, TimeOffMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimeOffMenuSummaryQuery, TimeOffMenuSummaryQueryVariables>(TimeOffMenuSummaryDocument, baseOptions);
        }
export type TimeOffMenuSummaryQueryHookResult = ReturnType<typeof useTimeOffMenuSummaryQuery>;
export type TimeOffMenuSummaryLazyQueryHookResult = ReturnType<typeof useTimeOffMenuSummaryLazyQuery>;
export type TimeOffMenuSummaryQueryResult = ApolloReactCommon.QueryResult<TimeOffMenuSummaryQuery, TimeOffMenuSummaryQueryVariables>;
export const TimeOffApplicationPeriodSummaryDocument = gql`
    query TimeOffApplicationPeriodSummary($CreatedDT: DateTime, $CompanyID: String!) {
  TimeOffApplicationPeriodSummary(CreatedDT: $CreatedDT, CompanyID: $CompanyID)
}
    `;

/**
 * __useTimeOffApplicationPeriodSummaryQuery__
 *
 * To run a query within a React component, call `useTimeOffApplicationPeriodSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffApplicationPeriodSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffApplicationPeriodSummaryQuery({
 *   variables: {
 *      CreatedDT: // value for 'CreatedDT'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useTimeOffApplicationPeriodSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimeOffApplicationPeriodSummaryQuery, TimeOffApplicationPeriodSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<TimeOffApplicationPeriodSummaryQuery, TimeOffApplicationPeriodSummaryQueryVariables>(TimeOffApplicationPeriodSummaryDocument, baseOptions);
      }
export function useTimeOffApplicationPeriodSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeOffApplicationPeriodSummaryQuery, TimeOffApplicationPeriodSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimeOffApplicationPeriodSummaryQuery, TimeOffApplicationPeriodSummaryQueryVariables>(TimeOffApplicationPeriodSummaryDocument, baseOptions);
        }
export type TimeOffApplicationPeriodSummaryQueryHookResult = ReturnType<typeof useTimeOffApplicationPeriodSummaryQuery>;
export type TimeOffApplicationPeriodSummaryLazyQueryHookResult = ReturnType<typeof useTimeOffApplicationPeriodSummaryLazyQuery>;
export type TimeOffApplicationPeriodSummaryQueryResult = ApolloReactCommon.QueryResult<TimeOffApplicationPeriodSummaryQuery, TimeOffApplicationPeriodSummaryQueryVariables>;
export const TimeOffListingByYearDocument = gql`
    query TimeOffListingByYear($StartDate: DateTime, $EndDate: DateTime, $CompanyID: String!, $EmployeeStatus: [String!], $DepartmentIDs: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!], $sort: String, $orderBy: String) {
  TimeOffListingByYear(StartDate: $StartDate, EndDate: $EndDate, CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, sort: $sort, orderBy: $orderBy) {
    TimeOffID
    EmployeeID
    Approver
    Employee {
      OrgUnitID
      JoinedDate
      FormStatus
      CompanyID
      EmployeeID
      EmployeeNo
      DepartmentID
      DivisionID
      EmploymentType
      JobGradeID
      ContactID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
    }
    ToReasonID
    ToReason {
      TimeOffReasonID
      Name
    }
    WorkDate
    StartTime
    EndTime
    Remark
    Reason
    VisitingPlace
    TravelFrom
    TravelTo
    TimeOffStatus
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ApproverID
    ApproveDate
    CancelledBy
    RejectionRemarks
    Duration
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        NotifiedDT
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
          ApproverName
        }
      }
    }
  }
}
    `;

/**
 * __useTimeOffListingByYearQuery__
 *
 * To run a query within a React component, call `useTimeOffListingByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffListingByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffListingByYearQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      sort: // value for 'sort'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTimeOffListingByYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimeOffListingByYearQuery, TimeOffListingByYearQueryVariables>) {
        return ApolloReactHooks.useQuery<TimeOffListingByYearQuery, TimeOffListingByYearQueryVariables>(TimeOffListingByYearDocument, baseOptions);
      }
export function useTimeOffListingByYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeOffListingByYearQuery, TimeOffListingByYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimeOffListingByYearQuery, TimeOffListingByYearQueryVariables>(TimeOffListingByYearDocument, baseOptions);
        }
export type TimeOffListingByYearQueryHookResult = ReturnType<typeof useTimeOffListingByYearQuery>;
export type TimeOffListingByYearLazyQueryHookResult = ReturnType<typeof useTimeOffListingByYearLazyQuery>;
export type TimeOffListingByYearQueryResult = ApolloReactCommon.QueryResult<TimeOffListingByYearQuery, TimeOffListingByYearQueryVariables>;
export const TimeOffEmployeeSummaryDocument = gql`
    query TimeOffEmployeeSummary($CompanyID: String!, $offset: Float, $limit: Float, $keyword: String) {
  EmployeeListing(CompanyID: $CompanyID, offset: $offset, limit: $limit, keyword: $keyword) {
    OrgUnitID
    JoinedDate
    JobGradeID
    CompanyID
    FormStatus
    EmployeeID
    Contact {
      ContactID
      FullName
      Email
      MobileNo
    }
    EmployeeNo
    Company {
      CompanyID
      CompanyName
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useTimeOffEmployeeSummaryQuery__
 *
 * To run a query within a React component, call `useTimeOffEmployeeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffEmployeeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffEmployeeSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useTimeOffEmployeeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimeOffEmployeeSummaryQuery, TimeOffEmployeeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<TimeOffEmployeeSummaryQuery, TimeOffEmployeeSummaryQueryVariables>(TimeOffEmployeeSummaryDocument, baseOptions);
      }
export function useTimeOffEmployeeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeOffEmployeeSummaryQuery, TimeOffEmployeeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimeOffEmployeeSummaryQuery, TimeOffEmployeeSummaryQueryVariables>(TimeOffEmployeeSummaryDocument, baseOptions);
        }
export type TimeOffEmployeeSummaryQueryHookResult = ReturnType<typeof useTimeOffEmployeeSummaryQuery>;
export type TimeOffEmployeeSummaryLazyQueryHookResult = ReturnType<typeof useTimeOffEmployeeSummaryLazyQuery>;
export type TimeOffEmployeeSummaryQueryResult = ApolloReactCommon.QueryResult<TimeOffEmployeeSummaryQuery, TimeOffEmployeeSummaryQueryVariables>;
export const TimeOffPolicyDetailsByEmployeeIdDocument = gql`
    query TimeOffPolicyDetailsByEmployeeID($EmployeeID: String, $CompanyID: String, $Date: DateTime) {
  TimeOffPolicyDetailsByEmployeeID(EmployeeID: $EmployeeID, CompanyID: $CompanyID, Date: $Date)
}
    `;

/**
 * __useTimeOffPolicyDetailsByEmployeeIdQuery__
 *
 * To run a query within a React component, call `useTimeOffPolicyDetailsByEmployeeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffPolicyDetailsByEmployeeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffPolicyDetailsByEmployeeIdQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      CompanyID: // value for 'CompanyID'
 *      Date: // value for 'Date'
 *   },
 * });
 */
export function useTimeOffPolicyDetailsByEmployeeIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimeOffPolicyDetailsByEmployeeIdQuery, TimeOffPolicyDetailsByEmployeeIdQueryVariables>) {
        return ApolloReactHooks.useQuery<TimeOffPolicyDetailsByEmployeeIdQuery, TimeOffPolicyDetailsByEmployeeIdQueryVariables>(TimeOffPolicyDetailsByEmployeeIdDocument, baseOptions);
      }
export function useTimeOffPolicyDetailsByEmployeeIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeOffPolicyDetailsByEmployeeIdQuery, TimeOffPolicyDetailsByEmployeeIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimeOffPolicyDetailsByEmployeeIdQuery, TimeOffPolicyDetailsByEmployeeIdQueryVariables>(TimeOffPolicyDetailsByEmployeeIdDocument, baseOptions);
        }
export type TimeOffPolicyDetailsByEmployeeIdQueryHookResult = ReturnType<typeof useTimeOffPolicyDetailsByEmployeeIdQuery>;
export type TimeOffPolicyDetailsByEmployeeIdLazyQueryHookResult = ReturnType<typeof useTimeOffPolicyDetailsByEmployeeIdLazyQuery>;
export type TimeOffPolicyDetailsByEmployeeIdQueryResult = ApolloReactCommon.QueryResult<TimeOffPolicyDetailsByEmployeeIdQuery, TimeOffPolicyDetailsByEmployeeIdQueryVariables>;
export const GetEmpByDutyDocument = gql`
    query getEmpByDuty($CompanyID: String!, $DutyDate: DateTime!) {
  getEmpByDuty(CompanyID: $CompanyID, DutyDate: $DutyDate)
}
    `;

/**
 * __useGetEmpByDutyQuery__
 *
 * To run a query within a React component, call `useGetEmpByDutyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmpByDutyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmpByDutyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DutyDate: // value for 'DutyDate'
 *   },
 * });
 */
export function useGetEmpByDutyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmpByDutyQuery, GetEmpByDutyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmpByDutyQuery, GetEmpByDutyQueryVariables>(GetEmpByDutyDocument, baseOptions);
      }
export function useGetEmpByDutyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmpByDutyQuery, GetEmpByDutyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmpByDutyQuery, GetEmpByDutyQueryVariables>(GetEmpByDutyDocument, baseOptions);
        }
export type GetEmpByDutyQueryHookResult = ReturnType<typeof useGetEmpByDutyQuery>;
export type GetEmpByDutyLazyQueryHookResult = ReturnType<typeof useGetEmpByDutyLazyQuery>;
export type GetEmpByDutyQueryResult = ApolloReactCommon.QueryResult<GetEmpByDutyQuery, GetEmpByDutyQueryVariables>;
export const GetAttendanceRecordLastModifiedDocument = gql`
    query GetAttendanceRecordLastModified($year: DateTime!, $timezoneOffset: Float!, $companyID: String!) {
  getAttendanceRecordLastModified(year: $year, timezoneOffset: $timezoneOffset, companyID: $companyID)
}
    `;

/**
 * __useGetAttendanceRecordLastModifiedQuery__
 *
 * To run a query within a React component, call `useGetAttendanceRecordLastModifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceRecordLastModifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceRecordLastModifiedQuery({
 *   variables: {
 *      year: // value for 'year'
 *      timezoneOffset: // value for 'timezoneOffset'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetAttendanceRecordLastModifiedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendanceRecordLastModifiedQuery, GetAttendanceRecordLastModifiedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendanceRecordLastModifiedQuery, GetAttendanceRecordLastModifiedQueryVariables>(GetAttendanceRecordLastModifiedDocument, baseOptions);
      }
export function useGetAttendanceRecordLastModifiedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendanceRecordLastModifiedQuery, GetAttendanceRecordLastModifiedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendanceRecordLastModifiedQuery, GetAttendanceRecordLastModifiedQueryVariables>(GetAttendanceRecordLastModifiedDocument, baseOptions);
        }
export type GetAttendanceRecordLastModifiedQueryHookResult = ReturnType<typeof useGetAttendanceRecordLastModifiedQuery>;
export type GetAttendanceRecordLastModifiedLazyQueryHookResult = ReturnType<typeof useGetAttendanceRecordLastModifiedLazyQuery>;
export type GetAttendanceRecordLastModifiedQueryResult = ApolloReactCommon.QueryResult<GetAttendanceRecordLastModifiedQuery, GetAttendanceRecordLastModifiedQueryVariables>;
export const AttendanceRecordByPeriodDocument = gql`
    query AttendanceRecordByPeriod($StartMonth: DateTime!, $EndMonth: DateTime!, $CompanyID: String!) {
  AttendanceRecordByPeriod(StartMonth: $StartMonth, EndMonth: $EndMonth, CompanyID: $CompanyID) {
    AttendanceRecordID
    EmployeeID
    DateTime
    CompanyID
    Location
    Address
    Lat
    Long
    Temperature
    WearMask
    Radius
    Remark
    IntegrationClockType
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
  }
}
    `;

/**
 * __useAttendanceRecordByPeriodQuery__
 *
 * To run a query within a React component, call `useAttendanceRecordByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceRecordByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceRecordByPeriodQuery({
 *   variables: {
 *      StartMonth: // value for 'StartMonth'
 *      EndMonth: // value for 'EndMonth'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useAttendanceRecordByPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttendanceRecordByPeriodQuery, AttendanceRecordByPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<AttendanceRecordByPeriodQuery, AttendanceRecordByPeriodQueryVariables>(AttendanceRecordByPeriodDocument, baseOptions);
      }
export function useAttendanceRecordByPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttendanceRecordByPeriodQuery, AttendanceRecordByPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttendanceRecordByPeriodQuery, AttendanceRecordByPeriodQueryVariables>(AttendanceRecordByPeriodDocument, baseOptions);
        }
export type AttendanceRecordByPeriodQueryHookResult = ReturnType<typeof useAttendanceRecordByPeriodQuery>;
export type AttendanceRecordByPeriodLazyQueryHookResult = ReturnType<typeof useAttendanceRecordByPeriodLazyQuery>;
export type AttendanceRecordByPeriodQueryResult = ApolloReactCommon.QueryResult<AttendanceRecordByPeriodQuery, AttendanceRecordByPeriodQueryVariables>;
export const AttendanceRecordSummaryDocument = gql`
    query AttendanceRecordSummary($CompanyID: String!) {
  AttendanceRecordSummary(CompanyID: $CompanyID)
}
    `;

/**
 * __useAttendanceRecordSummaryQuery__
 *
 * To run a query within a React component, call `useAttendanceRecordSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceRecordSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceRecordSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useAttendanceRecordSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttendanceRecordSummaryQuery, AttendanceRecordSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<AttendanceRecordSummaryQuery, AttendanceRecordSummaryQueryVariables>(AttendanceRecordSummaryDocument, baseOptions);
      }
export function useAttendanceRecordSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttendanceRecordSummaryQuery, AttendanceRecordSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttendanceRecordSummaryQuery, AttendanceRecordSummaryQueryVariables>(AttendanceRecordSummaryDocument, baseOptions);
        }
export type AttendanceRecordSummaryQueryHookResult = ReturnType<typeof useAttendanceRecordSummaryQuery>;
export type AttendanceRecordSummaryLazyQueryHookResult = ReturnType<typeof useAttendanceRecordSummaryLazyQuery>;
export type AttendanceRecordSummaryQueryResult = ApolloReactCommon.QueryResult<AttendanceRecordSummaryQuery, AttendanceRecordSummaryQueryVariables>;
export const DeleteTimeAttendanceRecordDocument = gql`
    mutation DeleteTimeAttendanceRecord($AttendanceRecordID: [String!]!) {
  DeleteTimeAttendanceRecord(AttendanceRecordID: $AttendanceRecordID)
}
    `;
export type DeleteTimeAttendanceRecordMutationFn = ApolloReactCommon.MutationFunction<DeleteTimeAttendanceRecordMutation, DeleteTimeAttendanceRecordMutationVariables>;

/**
 * __useDeleteTimeAttendanceRecordMutation__
 *
 * To run a mutation, you first call `useDeleteTimeAttendanceRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeAttendanceRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeAttendanceRecordMutation, { data, loading, error }] = useDeleteTimeAttendanceRecordMutation({
 *   variables: {
 *      AttendanceRecordID: // value for 'AttendanceRecordID'
 *   },
 * });
 */
export function useDeleteTimeAttendanceRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTimeAttendanceRecordMutation, DeleteTimeAttendanceRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTimeAttendanceRecordMutation, DeleteTimeAttendanceRecordMutationVariables>(DeleteTimeAttendanceRecordDocument, baseOptions);
      }
export type DeleteTimeAttendanceRecordMutationHookResult = ReturnType<typeof useDeleteTimeAttendanceRecordMutation>;
export type DeleteTimeAttendanceRecordMutationResult = ApolloReactCommon.MutationResult<DeleteTimeAttendanceRecordMutation>;
export type DeleteTimeAttendanceRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTimeAttendanceRecordMutation, DeleteTimeAttendanceRecordMutationVariables>;
export const GetManualEntryFormPageDataDocument = gql`
    query GetManualEntryFormPageData($CompanyID: String!, $EmployeeStatus: [String!], $offset: Float, $limit: Float, $keyword: String, $ExcludeFormStatus: String, $ExcludeEmployeeStatus: [String!], $exitReason: [String!], $Page: String) {
  EmployeeListing(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, offset: $offset, limit: $limit, keyword: $keyword, ExcludeFormStatus: $ExcludeFormStatus, ExcludeEmployeeStatus: $ExcludeEmployeeStatus, exitReason: $exitReason, Page: $Page) {
    EmployeeID
    Status
    ResignedDate
    FormStatus
    EmployeeNo
    Designation {
      Name
    }
    Contact {
      FullName
    }
    Company {
      CompanyID
      CompanyName
    }
  }
}
    `;

/**
 * __useGetManualEntryFormPageDataQuery__
 *
 * To run a query within a React component, call `useGetManualEntryFormPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManualEntryFormPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManualEntryFormPageDataQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *      ExcludeEmployeeStatus: // value for 'ExcludeEmployeeStatus'
 *      exitReason: // value for 'exitReason'
 *      Page: // value for 'Page'
 *   },
 * });
 */
export function useGetManualEntryFormPageDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetManualEntryFormPageDataQuery, GetManualEntryFormPageDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetManualEntryFormPageDataQuery, GetManualEntryFormPageDataQueryVariables>(GetManualEntryFormPageDataDocument, baseOptions);
      }
export function useGetManualEntryFormPageDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetManualEntryFormPageDataQuery, GetManualEntryFormPageDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetManualEntryFormPageDataQuery, GetManualEntryFormPageDataQueryVariables>(GetManualEntryFormPageDataDocument, baseOptions);
        }
export type GetManualEntryFormPageDataQueryHookResult = ReturnType<typeof useGetManualEntryFormPageDataQuery>;
export type GetManualEntryFormPageDataLazyQueryHookResult = ReturnType<typeof useGetManualEntryFormPageDataLazyQuery>;
export type GetManualEntryFormPageDataQueryResult = ApolloReactCommon.QueryResult<GetManualEntryFormPageDataQuery, GetManualEntryFormPageDataQueryVariables>;
export const UpdateAttendanceRecordDocument = gql`
    mutation UpdateAttendanceRecord($input: AttendanceRecordInput!) {
  updateAttendanceRecord(input: $input)
}
    `;
export type UpdateAttendanceRecordMutationFn = ApolloReactCommon.MutationFunction<UpdateAttendanceRecordMutation, UpdateAttendanceRecordMutationVariables>;

/**
 * __useUpdateAttendanceRecordMutation__
 *
 * To run a mutation, you first call `useUpdateAttendanceRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendanceRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttendanceRecordMutation, { data, loading, error }] = useUpdateAttendanceRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAttendanceRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAttendanceRecordMutation, UpdateAttendanceRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAttendanceRecordMutation, UpdateAttendanceRecordMutationVariables>(UpdateAttendanceRecordDocument, baseOptions);
      }
export type UpdateAttendanceRecordMutationHookResult = ReturnType<typeof useUpdateAttendanceRecordMutation>;
export type UpdateAttendanceRecordMutationResult = ApolloReactCommon.MutationResult<UpdateAttendanceRecordMutation>;
export type UpdateAttendanceRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAttendanceRecordMutation, UpdateAttendanceRecordMutationVariables>;
export const AttendanceRecordByExcelDocument = gql`
    query AttendanceRecordByExcel($CompanyID: String!, $DateTime: DateTime) {
  AttendanceRecordByExcel(CompanyID: $CompanyID, DateTime: $DateTime)
}
    `;

/**
 * __useAttendanceRecordByExcelQuery__
 *
 * To run a query within a React component, call `useAttendanceRecordByExcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceRecordByExcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceRecordByExcelQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DateTime: // value for 'DateTime'
 *   },
 * });
 */
export function useAttendanceRecordByExcelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttendanceRecordByExcelQuery, AttendanceRecordByExcelQueryVariables>) {
        return ApolloReactHooks.useQuery<AttendanceRecordByExcelQuery, AttendanceRecordByExcelQueryVariables>(AttendanceRecordByExcelDocument, baseOptions);
      }
export function useAttendanceRecordByExcelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttendanceRecordByExcelQuery, AttendanceRecordByExcelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttendanceRecordByExcelQuery, AttendanceRecordByExcelQueryVariables>(AttendanceRecordByExcelDocument, baseOptions);
        }
export type AttendanceRecordByExcelQueryHookResult = ReturnType<typeof useAttendanceRecordByExcelQuery>;
export type AttendanceRecordByExcelLazyQueryHookResult = ReturnType<typeof useAttendanceRecordByExcelLazyQuery>;
export type AttendanceRecordByExcelQueryResult = ApolloReactCommon.QueryResult<AttendanceRecordByExcelQuery, AttendanceRecordByExcelQueryVariables>;
export const AttendanceRecordBatchDeleteDocument = gql`
    mutation AttendanceRecordBatchDelete($input: [AttendanceRecordInput!]!) {
  AttendanceRecordBatchDelete(Input: $input)
}
    `;
export type AttendanceRecordBatchDeleteMutationFn = ApolloReactCommon.MutationFunction<AttendanceRecordBatchDeleteMutation, AttendanceRecordBatchDeleteMutationVariables>;

/**
 * __useAttendanceRecordBatchDeleteMutation__
 *
 * To run a mutation, you first call `useAttendanceRecordBatchDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendanceRecordBatchDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendanceRecordBatchDeleteMutation, { data, loading, error }] = useAttendanceRecordBatchDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttendanceRecordBatchDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttendanceRecordBatchDeleteMutation, AttendanceRecordBatchDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AttendanceRecordBatchDeleteMutation, AttendanceRecordBatchDeleteMutationVariables>(AttendanceRecordBatchDeleteDocument, baseOptions);
      }
export type AttendanceRecordBatchDeleteMutationHookResult = ReturnType<typeof useAttendanceRecordBatchDeleteMutation>;
export type AttendanceRecordBatchDeleteMutationResult = ApolloReactCommon.MutationResult<AttendanceRecordBatchDeleteMutation>;
export type AttendanceRecordBatchDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AttendanceRecordBatchDeleteMutation, AttendanceRecordBatchDeleteMutationVariables>;
export const AttendanceRecordBatchDocument = gql`
    mutation AttendanceRecordBatch($input: [AttendanceRecordInput!]!) {
  AttendanceRecordBatch(Input: $input)
}
    `;
export type AttendanceRecordBatchMutationFn = ApolloReactCommon.MutationFunction<AttendanceRecordBatchMutation, AttendanceRecordBatchMutationVariables>;

/**
 * __useAttendanceRecordBatchMutation__
 *
 * To run a mutation, you first call `useAttendanceRecordBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendanceRecordBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendanceRecordBatchMutation, { data, loading, error }] = useAttendanceRecordBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttendanceRecordBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttendanceRecordBatchMutation, AttendanceRecordBatchMutationVariables>) {
        return ApolloReactHooks.useMutation<AttendanceRecordBatchMutation, AttendanceRecordBatchMutationVariables>(AttendanceRecordBatchDocument, baseOptions);
      }
export type AttendanceRecordBatchMutationHookResult = ReturnType<typeof useAttendanceRecordBatchMutation>;
export type AttendanceRecordBatchMutationResult = ApolloReactCommon.MutationResult<AttendanceRecordBatchMutation>;
export type AttendanceRecordBatchMutationOptions = ApolloReactCommon.BaseMutationOptions<AttendanceRecordBatchMutation, AttendanceRecordBatchMutationVariables>;
export const GetEmpListingbyCompanyDocument = gql`
    query GetEmpListingbyCompany($CompanyID: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!], $OrgUnitIDs: [String!]) {
  GetEmpListingbyCompany(CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, OrgUnitIDs: $OrgUnitIDs) {
    EmployeeID
    EmployeeNo
    EmploymentType
    DepartmentID
    DivisionID
    JobGradeID
    Status
    ReportTo
    DivisionID
    DepartmentID
    Contact {
      FullName
    }
  }
}
    `;

/**
 * __useGetEmpListingbyCompanyQuery__
 *
 * To run a query within a React component, call `useGetEmpListingbyCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmpListingbyCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmpListingbyCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *   },
 * });
 */
export function useGetEmpListingbyCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmpListingbyCompanyQuery, GetEmpListingbyCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmpListingbyCompanyQuery, GetEmpListingbyCompanyQueryVariables>(GetEmpListingbyCompanyDocument, baseOptions);
      }
export function useGetEmpListingbyCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmpListingbyCompanyQuery, GetEmpListingbyCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmpListingbyCompanyQuery, GetEmpListingbyCompanyQueryVariables>(GetEmpListingbyCompanyDocument, baseOptions);
        }
export type GetEmpListingbyCompanyQueryHookResult = ReturnType<typeof useGetEmpListingbyCompanyQuery>;
export type GetEmpListingbyCompanyLazyQueryHookResult = ReturnType<typeof useGetEmpListingbyCompanyLazyQuery>;
export type GetEmpListingbyCompanyQueryResult = ApolloReactCommon.QueryResult<GetEmpListingbyCompanyQuery, GetEmpListingbyCompanyQueryVariables>;
export const AttendancePeriodEditDocument = gql`
    mutation AttendancePeriodEdit($Data: [JSON!]!) {
  AttendancePeriodEdit(Data: $Data)
}
    `;
export type AttendancePeriodEditMutationFn = ApolloReactCommon.MutationFunction<AttendancePeriodEditMutation, AttendancePeriodEditMutationVariables>;

/**
 * __useAttendancePeriodEditMutation__
 *
 * To run a mutation, you first call `useAttendancePeriodEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendancePeriodEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendancePeriodEditMutation, { data, loading, error }] = useAttendancePeriodEditMutation({
 *   variables: {
 *      Data: // value for 'Data'
 *   },
 * });
 */
export function useAttendancePeriodEditMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttendancePeriodEditMutation, AttendancePeriodEditMutationVariables>) {
        return ApolloReactHooks.useMutation<AttendancePeriodEditMutation, AttendancePeriodEditMutationVariables>(AttendancePeriodEditDocument, baseOptions);
      }
export type AttendancePeriodEditMutationHookResult = ReturnType<typeof useAttendancePeriodEditMutation>;
export type AttendancePeriodEditMutationResult = ApolloReactCommon.MutationResult<AttendancePeriodEditMutation>;
export type AttendancePeriodEditMutationOptions = ApolloReactCommon.BaseMutationOptions<AttendancePeriodEditMutation, AttendancePeriodEditMutationVariables>;
export const GetLastPostedAttendanceProccessDocument = gql`
    query getLastPostedAttendanceProccess($CompanyID: String!) {
  getLastPostedAttendanceProccess(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetLastPostedAttendanceProccessQuery__
 *
 * To run a query within a React component, call `useGetLastPostedAttendanceProccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastPostedAttendanceProccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastPostedAttendanceProccessQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetLastPostedAttendanceProccessQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLastPostedAttendanceProccessQuery, GetLastPostedAttendanceProccessQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLastPostedAttendanceProccessQuery, GetLastPostedAttendanceProccessQueryVariables>(GetLastPostedAttendanceProccessDocument, baseOptions);
      }
export function useGetLastPostedAttendanceProccessLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLastPostedAttendanceProccessQuery, GetLastPostedAttendanceProccessQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLastPostedAttendanceProccessQuery, GetLastPostedAttendanceProccessQueryVariables>(GetLastPostedAttendanceProccessDocument, baseOptions);
        }
export type GetLastPostedAttendanceProccessQueryHookResult = ReturnType<typeof useGetLastPostedAttendanceProccessQuery>;
export type GetLastPostedAttendanceProccessLazyQueryHookResult = ReturnType<typeof useGetLastPostedAttendanceProccessLazyQuery>;
export type GetLastPostedAttendanceProccessQueryResult = ApolloReactCommon.QueryResult<GetLastPostedAttendanceProccessQuery, GetLastPostedAttendanceProccessQueryVariables>;
export const AttendancePeriodSummaryDocument = gql`
    query AttendancePeriodSummary($CreatedDT: DateTime!, $CompanyID: String!) {
  AttendancePeriodSummary(CreatedDT: $CreatedDT, CompanyID: $CompanyID)
}
    `;

/**
 * __useAttendancePeriodSummaryQuery__
 *
 * To run a query within a React component, call `useAttendancePeriodSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendancePeriodSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendancePeriodSummaryQuery({
 *   variables: {
 *      CreatedDT: // value for 'CreatedDT'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useAttendancePeriodSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttendancePeriodSummaryQuery, AttendancePeriodSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<AttendancePeriodSummaryQuery, AttendancePeriodSummaryQueryVariables>(AttendancePeriodSummaryDocument, baseOptions);
      }
export function useAttendancePeriodSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttendancePeriodSummaryQuery, AttendancePeriodSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttendancePeriodSummaryQuery, AttendancePeriodSummaryQueryVariables>(AttendancePeriodSummaryDocument, baseOptions);
        }
export type AttendancePeriodSummaryQueryHookResult = ReturnType<typeof useAttendancePeriodSummaryQuery>;
export type AttendancePeriodSummaryLazyQueryHookResult = ReturnType<typeof useAttendancePeriodSummaryLazyQuery>;
export type AttendancePeriodSummaryQueryResult = ApolloReactCommon.QueryResult<AttendancePeriodSummaryQuery, AttendancePeriodSummaryQueryVariables>;
export const AttendanceProccessBulkDeleteDocument = gql`
    mutation AttendanceProccessBulkDelete($AttendanceCalculationID: [String!]!) {
  AttendanceProccessBulkDelete(AttendanceCalculationID: $AttendanceCalculationID)
}
    `;
export type AttendanceProccessBulkDeleteMutationFn = ApolloReactCommon.MutationFunction<AttendanceProccessBulkDeleteMutation, AttendanceProccessBulkDeleteMutationVariables>;

/**
 * __useAttendanceProccessBulkDeleteMutation__
 *
 * To run a mutation, you first call `useAttendanceProccessBulkDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendanceProccessBulkDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendanceProccessBulkDeleteMutation, { data, loading, error }] = useAttendanceProccessBulkDeleteMutation({
 *   variables: {
 *      AttendanceCalculationID: // value for 'AttendanceCalculationID'
 *   },
 * });
 */
export function useAttendanceProccessBulkDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttendanceProccessBulkDeleteMutation, AttendanceProccessBulkDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AttendanceProccessBulkDeleteMutation, AttendanceProccessBulkDeleteMutationVariables>(AttendanceProccessBulkDeleteDocument, baseOptions);
      }
export type AttendanceProccessBulkDeleteMutationHookResult = ReturnType<typeof useAttendanceProccessBulkDeleteMutation>;
export type AttendanceProccessBulkDeleteMutationResult = ApolloReactCommon.MutationResult<AttendanceProccessBulkDeleteMutation>;
export type AttendanceProccessBulkDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AttendanceProccessBulkDeleteMutation, AttendanceProccessBulkDeleteMutationVariables>;
export const AttendancePostingListingDocument = gql`
    query AttendancePostingListing($CompanyID: String!, $DateFrom: String!, $DateTo: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!], $OrgUnitIDs: [String!], $AttendanceType: AttendanceTransactionType, $PostStatus: [String!], $isAttendanceGrouped: Boolean, $keyword: String, $offset: Float, $limit: Float) {
  AttendancePostingListing(CompanyID: $CompanyID, DateFrom: $DateFrom, DateTo: $DateTo, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, OrgUnitIDs: $OrgUnitIDs, AttendanceType: $AttendanceType, PostStatus: $PostStatus, isAttendanceGrouped: $isAttendanceGrouped, keyword: $keyword, offset: $offset, limit: $limit) {
    AttendanceCalculationID
    PostStatus
    Date
    Value
    PayItemID
    EmployeeID
    AdjustedValue
    Department
    ShiftDayName
    Division
    PostedDate
    ModifiedDT
    Type
    PeriodicCycleStartDate
    PeriodicCycleEndDate
    Employee {
      OrgUnitID
      OrgUnit {
        CompanyOrgUnitID
        Level
        Description
        ParentID
      }
      JoinedDate
      JobGradeID
      CompanyID
      FormStatus
      EmployeeID
      EmployeeNo
      DepartmentID
      DivisionID
      Department {
        Description
      }
    }
    Contact {
      FullName
    }
    Shift {
      ShiftID
      Name
    }
    ShiftDay {
      ShiftDayID
      DayType
    }
    PayItem {
      PayItemID
      PayName
    }
    IsOTPModified
    OTPModifiedBy
    IsOTPDeleted
    OTPDeletedBy
  }
  AttendancePostingListingCount(CompanyID: $CompanyID, DateFrom: $DateFrom, DateTo: $DateTo, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, OrgUnitIDs: $OrgUnitIDs, isAttendanceGrouped: $isAttendanceGrouped, AttendanceType: $AttendanceType, keyword: $keyword, PostStatus: $PostStatus)
}
    `;

/**
 * __useAttendancePostingListingQuery__
 *
 * To run a query within a React component, call `useAttendancePostingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendancePostingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendancePostingListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DateFrom: // value for 'DateFrom'
 *      DateTo: // value for 'DateTo'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      OrgUnitIDs: // value for 'OrgUnitIDs'
 *      AttendanceType: // value for 'AttendanceType'
 *      PostStatus: // value for 'PostStatus'
 *      isAttendanceGrouped: // value for 'isAttendanceGrouped'
 *      keyword: // value for 'keyword'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAttendancePostingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttendancePostingListingQuery, AttendancePostingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AttendancePostingListingQuery, AttendancePostingListingQueryVariables>(AttendancePostingListingDocument, baseOptions);
      }
export function useAttendancePostingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttendancePostingListingQuery, AttendancePostingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttendancePostingListingQuery, AttendancePostingListingQueryVariables>(AttendancePostingListingDocument, baseOptions);
        }
export type AttendancePostingListingQueryHookResult = ReturnType<typeof useAttendancePostingListingQuery>;
export type AttendancePostingListingLazyQueryHookResult = ReturnType<typeof useAttendancePostingListingLazyQuery>;
export type AttendancePostingListingQueryResult = ApolloReactCommon.QueryResult<AttendancePostingListingQuery, AttendancePostingListingQueryVariables>;
export const AdjustedValueQuantityDetailsDocument = gql`
    query AdjustedValueQuantityDetails($CompanyID: String!, $EmployeeID: String!, $PayItemID: String!, $DateFrom: String!, $DateTo: String!) {
  AdjustedValueQuantityDetails(CompanyID: $CompanyID, EmployeeID: $EmployeeID, PayItemID: $PayItemID, DateFrom: $DateFrom, DateTo: $DateTo) {
    AttendanceCalculationID
    PostStatus
    Date
    Value
    PayItemID
    EmployeeID
    AdjustedValue
    Department
    ShiftDayName
    Division
    PostedDate
    ModifiedDT
    Type
    PeriodicCycleStartDate
    PeriodicCycleEndDate
    Employee {
      OrgUnitID
      OrgUnit {
        CompanyOrgUnitID
        Level
        Description
        ParentID
      }
      JoinedDate
      JobGradeID
      CompanyID
      FormStatus
      EmployeeID
      EmployeeNo
      DepartmentID
      DivisionID
      Department {
        Description
      }
    }
    Contact {
      FullName
    }
    Shift {
      ShiftID
      Name
    }
    ShiftDay {
      ShiftDayID
      DayType
    }
    PayItem {
      PayItemID
      PayName
    }
    IsOTPModified
    OTPModifiedBy
    IsOTPDeleted
    OTPDeletedBy
  }
}
    `;

/**
 * __useAdjustedValueQuantityDetailsQuery__
 *
 * To run a query within a React component, call `useAdjustedValueQuantityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdjustedValueQuantityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdjustedValueQuantityDetailsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      PayItemID: // value for 'PayItemID'
 *      DateFrom: // value for 'DateFrom'
 *      DateTo: // value for 'DateTo'
 *   },
 * });
 */
export function useAdjustedValueQuantityDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdjustedValueQuantityDetailsQuery, AdjustedValueQuantityDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdjustedValueQuantityDetailsQuery, AdjustedValueQuantityDetailsQueryVariables>(AdjustedValueQuantityDetailsDocument, baseOptions);
      }
export function useAdjustedValueQuantityDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdjustedValueQuantityDetailsQuery, AdjustedValueQuantityDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdjustedValueQuantityDetailsQuery, AdjustedValueQuantityDetailsQueryVariables>(AdjustedValueQuantityDetailsDocument, baseOptions);
        }
export type AdjustedValueQuantityDetailsQueryHookResult = ReturnType<typeof useAdjustedValueQuantityDetailsQuery>;
export type AdjustedValueQuantityDetailsLazyQueryHookResult = ReturnType<typeof useAdjustedValueQuantityDetailsLazyQuery>;
export type AdjustedValueQuantityDetailsQueryResult = ApolloReactCommon.QueryResult<AdjustedValueQuantityDetailsQuery, AdjustedValueQuantityDetailsQueryVariables>;
export const AttendanceProcessingDocument = gql`
    mutation AttendanceProcessing($EmployeeIDs: [String!]!, $CompanyID: String!, $FromDate: String!, $AccountID: String!, $ToDate: String!) {
  AttendanceProcessing(EmployeeIDs: $EmployeeIDs, CompanyID: $CompanyID, FromDate: $FromDate, AccountID: $AccountID, ToDate: $ToDate)
}
    `;
export type AttendanceProcessingMutationFn = ApolloReactCommon.MutationFunction<AttendanceProcessingMutation, AttendanceProcessingMutationVariables>;

/**
 * __useAttendanceProcessingMutation__
 *
 * To run a mutation, you first call `useAttendanceProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendanceProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendanceProcessingMutation, { data, loading, error }] = useAttendanceProcessingMutation({
 *   variables: {
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      CompanyID: // value for 'CompanyID'
 *      FromDate: // value for 'FromDate'
 *      AccountID: // value for 'AccountID'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useAttendanceProcessingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttendanceProcessingMutation, AttendanceProcessingMutationVariables>) {
        return ApolloReactHooks.useMutation<AttendanceProcessingMutation, AttendanceProcessingMutationVariables>(AttendanceProcessingDocument, baseOptions);
      }
export type AttendanceProcessingMutationHookResult = ReturnType<typeof useAttendanceProcessingMutation>;
export type AttendanceProcessingMutationResult = ApolloReactCommon.MutationResult<AttendanceProcessingMutation>;
export type AttendanceProcessingMutationOptions = ApolloReactCommon.BaseMutationOptions<AttendanceProcessingMutation, AttendanceProcessingMutationVariables>;
export const ProcessingListingDocument = gql`
    query ProcessingListing($CompanyID: String, $FromDate: DateTime, $ToDate: DateTime) {
  ProcessingListing(CompanyID: $CompanyID, FromDate: $FromDate, ToDate: $ToDate) {
    AttendanceProcessingBatchID
    CompanyID
    Date
    Status
    Errorlog
    CompletedTime
  }
}
    `;

/**
 * __useProcessingListingQuery__
 *
 * To run a query within a React component, call `useProcessingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessingListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useProcessingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProcessingListingQuery, ProcessingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProcessingListingQuery, ProcessingListingQueryVariables>(ProcessingListingDocument, baseOptions);
      }
export function useProcessingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProcessingListingQuery, ProcessingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProcessingListingQuery, ProcessingListingQueryVariables>(ProcessingListingDocument, baseOptions);
        }
export type ProcessingListingQueryHookResult = ReturnType<typeof useProcessingListingQuery>;
export type ProcessingListingLazyQueryHookResult = ReturnType<typeof useProcessingListingLazyQuery>;
export type ProcessingListingQueryResult = ApolloReactCommon.QueryResult<ProcessingListingQuery, ProcessingListingQueryVariables>;
export const UpdateAdjustedValueDocument = gql`
    mutation UpdateAdjustedValue($input: AttendanceCalculationInput!) {
  UpdateAdjustedValue(input: $input)
}
    `;
export type UpdateAdjustedValueMutationFn = ApolloReactCommon.MutationFunction<UpdateAdjustedValueMutation, UpdateAdjustedValueMutationVariables>;

/**
 * __useUpdateAdjustedValueMutation__
 *
 * To run a mutation, you first call `useUpdateAdjustedValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdjustedValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdjustedValueMutation, { data, loading, error }] = useUpdateAdjustedValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdjustedValueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAdjustedValueMutation, UpdateAdjustedValueMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAdjustedValueMutation, UpdateAdjustedValueMutationVariables>(UpdateAdjustedValueDocument, baseOptions);
      }
export type UpdateAdjustedValueMutationHookResult = ReturnType<typeof useUpdateAdjustedValueMutation>;
export type UpdateAdjustedValueMutationResult = ApolloReactCommon.MutationResult<UpdateAdjustedValueMutation>;
export type UpdateAdjustedValueMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAdjustedValueMutation, UpdateAdjustedValueMutationVariables>;
export const MonthlyAttendanceProcessingDocument = gql`
    mutation MonthlyAttendanceProcessing($EmployeeIDs: [String!]!, $CompanyID: String!, $FromDate: String!, $AccountID: String!, $ToDate: String!) {
  MonthlyAttendanceProcessing(EmployeeIDs: $EmployeeIDs, CompanyID: $CompanyID, FromDate: $FromDate, AccountID: $AccountID, ToDate: $ToDate)
}
    `;
export type MonthlyAttendanceProcessingMutationFn = ApolloReactCommon.MutationFunction<MonthlyAttendanceProcessingMutation, MonthlyAttendanceProcessingMutationVariables>;

/**
 * __useMonthlyAttendanceProcessingMutation__
 *
 * To run a mutation, you first call `useMonthlyAttendanceProcessingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonthlyAttendanceProcessingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monthlyAttendanceProcessingMutation, { data, loading, error }] = useMonthlyAttendanceProcessingMutation({
 *   variables: {
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      CompanyID: // value for 'CompanyID'
 *      FromDate: // value for 'FromDate'
 *      AccountID: // value for 'AccountID'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useMonthlyAttendanceProcessingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MonthlyAttendanceProcessingMutation, MonthlyAttendanceProcessingMutationVariables>) {
        return ApolloReactHooks.useMutation<MonthlyAttendanceProcessingMutation, MonthlyAttendanceProcessingMutationVariables>(MonthlyAttendanceProcessingDocument, baseOptions);
      }
export type MonthlyAttendanceProcessingMutationHookResult = ReturnType<typeof useMonthlyAttendanceProcessingMutation>;
export type MonthlyAttendanceProcessingMutationResult = ApolloReactCommon.MutationResult<MonthlyAttendanceProcessingMutation>;
export type MonthlyAttendanceProcessingMutationOptions = ApolloReactCommon.BaseMutationOptions<MonthlyAttendanceProcessingMutation, MonthlyAttendanceProcessingMutationVariables>;
export const GetPayrollCycleByCompanyDocument = gql`
    query GetPayrollCycleByCompany($CompanyID: String!) {
  PayrollCycleListing(CompanyID: $CompanyID) {
    PayrollCycleID
    CompanyID
    Description
    IsDefault
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useGetPayrollCycleByCompanyQuery__
 *
 * To run a query within a React component, call `useGetPayrollCycleByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayrollCycleByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayrollCycleByCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetPayrollCycleByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPayrollCycleByCompanyQuery, GetPayrollCycleByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPayrollCycleByCompanyQuery, GetPayrollCycleByCompanyQueryVariables>(GetPayrollCycleByCompanyDocument, baseOptions);
      }
export function useGetPayrollCycleByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPayrollCycleByCompanyQuery, GetPayrollCycleByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPayrollCycleByCompanyQuery, GetPayrollCycleByCompanyQueryVariables>(GetPayrollCycleByCompanyDocument, baseOptions);
        }
export type GetPayrollCycleByCompanyQueryHookResult = ReturnType<typeof useGetPayrollCycleByCompanyQuery>;
export type GetPayrollCycleByCompanyLazyQueryHookResult = ReturnType<typeof useGetPayrollCycleByCompanyLazyQuery>;
export type GetPayrollCycleByCompanyQueryResult = ApolloReactCommon.QueryResult<GetPayrollCycleByCompanyQuery, GetPayrollCycleByCompanyQueryVariables>;
export const PostAttendanceRecordDocument = gql`
    mutation PostAttendanceRecord($input: AttendancePostInput!) {
  PostAttendanceRecord(input: $input)
}
    `;
export type PostAttendanceRecordMutationFn = ApolloReactCommon.MutationFunction<PostAttendanceRecordMutation, PostAttendanceRecordMutationVariables>;

/**
 * __usePostAttendanceRecordMutation__
 *
 * To run a mutation, you first call `usePostAttendanceRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostAttendanceRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postAttendanceRecordMutation, { data, loading, error }] = usePostAttendanceRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostAttendanceRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostAttendanceRecordMutation, PostAttendanceRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<PostAttendanceRecordMutation, PostAttendanceRecordMutationVariables>(PostAttendanceRecordDocument, baseOptions);
      }
export type PostAttendanceRecordMutationHookResult = ReturnType<typeof usePostAttendanceRecordMutation>;
export type PostAttendanceRecordMutationResult = ApolloReactCommon.MutationResult<PostAttendanceRecordMutation>;
export type PostAttendanceRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<PostAttendanceRecordMutation, PostAttendanceRecordMutationVariables>;
export const ReverseAttendanceRecordDocument = gql`
    mutation ReverseAttendanceRecord($EmployeePayItemIDPairs: [AttendanceCalculationInput!]!, $FromDate: DateTime, $ToDate: DateTime) {
  ReverseAttendanceRecord(EmployeePayItemIDPairs: $EmployeePayItemIDPairs, FromDate: $FromDate, ToDate: $ToDate)
}
    `;
export type ReverseAttendanceRecordMutationFn = ApolloReactCommon.MutationFunction<ReverseAttendanceRecordMutation, ReverseAttendanceRecordMutationVariables>;

/**
 * __useReverseAttendanceRecordMutation__
 *
 * To run a mutation, you first call `useReverseAttendanceRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverseAttendanceRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverseAttendanceRecordMutation, { data, loading, error }] = useReverseAttendanceRecordMutation({
 *   variables: {
 *      EmployeePayItemIDPairs: // value for 'EmployeePayItemIDPairs'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useReverseAttendanceRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReverseAttendanceRecordMutation, ReverseAttendanceRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<ReverseAttendanceRecordMutation, ReverseAttendanceRecordMutationVariables>(ReverseAttendanceRecordDocument, baseOptions);
      }
export type ReverseAttendanceRecordMutationHookResult = ReturnType<typeof useReverseAttendanceRecordMutation>;
export type ReverseAttendanceRecordMutationResult = ApolloReactCommon.MutationResult<ReverseAttendanceRecordMutation>;
export type ReverseAttendanceRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<ReverseAttendanceRecordMutation, ReverseAttendanceRecordMutationVariables>;
export const PopulateAttendanceRecordDocument = gql`
    mutation PopulateAttendanceRecord($CompanyID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  PopulateAttendanceRecord(CompanyID: $CompanyID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;
export type PopulateAttendanceRecordMutationFn = ApolloReactCommon.MutationFunction<PopulateAttendanceRecordMutation, PopulateAttendanceRecordMutationVariables>;

/**
 * __usePopulateAttendanceRecordMutation__
 *
 * To run a mutation, you first call `usePopulateAttendanceRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePopulateAttendanceRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [populateAttendanceRecordMutation, { data, loading, error }] = usePopulateAttendanceRecordMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function usePopulateAttendanceRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PopulateAttendanceRecordMutation, PopulateAttendanceRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<PopulateAttendanceRecordMutation, PopulateAttendanceRecordMutationVariables>(PopulateAttendanceRecordDocument, baseOptions);
      }
export type PopulateAttendanceRecordMutationHookResult = ReturnType<typeof usePopulateAttendanceRecordMutation>;
export type PopulateAttendanceRecordMutationResult = ApolloReactCommon.MutationResult<PopulateAttendanceRecordMutation>;
export type PopulateAttendanceRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<PopulateAttendanceRecordMutation, PopulateAttendanceRecordMutationVariables>;
export const EarnLeavePeriodSummaryDocument = gql`
    query EarnLeavePeriodSummary($CreatedDT: DateTime!, $CompanyID: String!) {
  EarnLeavePeriodSummary(CreatedDT: $CreatedDT, CompanyID: $CompanyID)
}
    `;

/**
 * __useEarnLeavePeriodSummaryQuery__
 *
 * To run a query within a React component, call `useEarnLeavePeriodSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnLeavePeriodSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnLeavePeriodSummaryQuery({
 *   variables: {
 *      CreatedDT: // value for 'CreatedDT'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useEarnLeavePeriodSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EarnLeavePeriodSummaryQuery, EarnLeavePeriodSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EarnLeavePeriodSummaryQuery, EarnLeavePeriodSummaryQueryVariables>(EarnLeavePeriodSummaryDocument, baseOptions);
      }
export function useEarnLeavePeriodSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EarnLeavePeriodSummaryQuery, EarnLeavePeriodSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EarnLeavePeriodSummaryQuery, EarnLeavePeriodSummaryQueryVariables>(EarnLeavePeriodSummaryDocument, baseOptions);
        }
export type EarnLeavePeriodSummaryQueryHookResult = ReturnType<typeof useEarnLeavePeriodSummaryQuery>;
export type EarnLeavePeriodSummaryLazyQueryHookResult = ReturnType<typeof useEarnLeavePeriodSummaryLazyQuery>;
export type EarnLeavePeriodSummaryQueryResult = ApolloReactCommon.QueryResult<EarnLeavePeriodSummaryQuery, EarnLeavePeriodSummaryQueryVariables>;
export const EarnLeavePostingDocument = gql`
    query EarnLeavePosting($EmployeeIDs: [String!]!, $CompanyID: String!, $FromDate: String!, $UntilDate: String!, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!]) {
  EarnLeavePosting(EmployeeIDs: $EmployeeIDs, CompanyID: $CompanyID, FromDate: $FromDate, UntilDate: $UntilDate, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs)
}
    `;

/**
 * __useEarnLeavePostingQuery__
 *
 * To run a query within a React component, call `useEarnLeavePostingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarnLeavePostingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarnLeavePostingQuery({
 *   variables: {
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      CompanyID: // value for 'CompanyID'
 *      FromDate: // value for 'FromDate'
 *      UntilDate: // value for 'UntilDate'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *   },
 * });
 */
export function useEarnLeavePostingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EarnLeavePostingQuery, EarnLeavePostingQueryVariables>) {
        return ApolloReactHooks.useQuery<EarnLeavePostingQuery, EarnLeavePostingQueryVariables>(EarnLeavePostingDocument, baseOptions);
      }
export function useEarnLeavePostingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EarnLeavePostingQuery, EarnLeavePostingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EarnLeavePostingQuery, EarnLeavePostingQueryVariables>(EarnLeavePostingDocument, baseOptions);
        }
export type EarnLeavePostingQueryHookResult = ReturnType<typeof useEarnLeavePostingQuery>;
export type EarnLeavePostingLazyQueryHookResult = ReturnType<typeof useEarnLeavePostingLazyQuery>;
export type EarnLeavePostingQueryResult = ApolloReactCommon.QueryResult<EarnLeavePostingQuery, EarnLeavePostingQueryVariables>;
export const PostEarnedLeaveDocument = gql`
    mutation PostEarnedLeave($LeaveHeaderIDs: [String!]!, $FromDate: String!, $UntilDate: String!, $Remarks: String) {
  PostEarnedLeave(LeaveHeaderIDs: $LeaveHeaderIDs, FromDate: $FromDate, UntilDate: $UntilDate, Remarks: $Remarks)
}
    `;
export type PostEarnedLeaveMutationFn = ApolloReactCommon.MutationFunction<PostEarnedLeaveMutation, PostEarnedLeaveMutationVariables>;

/**
 * __usePostEarnedLeaveMutation__
 *
 * To run a mutation, you first call `usePostEarnedLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostEarnedLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postEarnedLeaveMutation, { data, loading, error }] = usePostEarnedLeaveMutation({
 *   variables: {
 *      LeaveHeaderIDs: // value for 'LeaveHeaderIDs'
 *      FromDate: // value for 'FromDate'
 *      UntilDate: // value for 'UntilDate'
 *      Remarks: // value for 'Remarks'
 *   },
 * });
 */
export function usePostEarnedLeaveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostEarnedLeaveMutation, PostEarnedLeaveMutationVariables>) {
        return ApolloReactHooks.useMutation<PostEarnedLeaveMutation, PostEarnedLeaveMutationVariables>(PostEarnedLeaveDocument, baseOptions);
      }
export type PostEarnedLeaveMutationHookResult = ReturnType<typeof usePostEarnedLeaveMutation>;
export type PostEarnedLeaveMutationResult = ApolloReactCommon.MutationResult<PostEarnedLeaveMutation>;
export type PostEarnedLeaveMutationOptions = ApolloReactCommon.BaseMutationOptions<PostEarnedLeaveMutation, PostEarnedLeaveMutationVariables>;
export const ReverseEarnedLeaveDocument = gql`
    mutation ReverseEarnedLeave($LeaveHeaderIDs: [String!]!) {
  ReverseEarnedLeave(LeaveHeaderIDs: $LeaveHeaderIDs)
}
    `;
export type ReverseEarnedLeaveMutationFn = ApolloReactCommon.MutationFunction<ReverseEarnedLeaveMutation, ReverseEarnedLeaveMutationVariables>;

/**
 * __useReverseEarnedLeaveMutation__
 *
 * To run a mutation, you first call `useReverseEarnedLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReverseEarnedLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reverseEarnedLeaveMutation, { data, loading, error }] = useReverseEarnedLeaveMutation({
 *   variables: {
 *      LeaveHeaderIDs: // value for 'LeaveHeaderIDs'
 *   },
 * });
 */
export function useReverseEarnedLeaveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReverseEarnedLeaveMutation, ReverseEarnedLeaveMutationVariables>) {
        return ApolloReactHooks.useMutation<ReverseEarnedLeaveMutation, ReverseEarnedLeaveMutationVariables>(ReverseEarnedLeaveDocument, baseOptions);
      }
export type ReverseEarnedLeaveMutationHookResult = ReturnType<typeof useReverseEarnedLeaveMutation>;
export type ReverseEarnedLeaveMutationResult = ApolloReactCommon.MutationResult<ReverseEarnedLeaveMutation>;
export type ReverseEarnedLeaveMutationOptions = ApolloReactCommon.BaseMutationOptions<ReverseEarnedLeaveMutation, ReverseEarnedLeaveMutationVariables>;
export const CreateTemplateLibraryAssignmentDocument = gql`
    mutation createTemplateLibraryAssignment($inputs: [TemplateLibraryAssignmentInput!]!) {
  createTemplateLibraryAssignment(inputs: $inputs)
}
    `;
export type CreateTemplateLibraryAssignmentMutationFn = ApolloReactCommon.MutationFunction<CreateTemplateLibraryAssignmentMutation, CreateTemplateLibraryAssignmentMutationVariables>;

/**
 * __useCreateTemplateLibraryAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateTemplateLibraryAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateLibraryAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateLibraryAssignmentMutation, { data, loading, error }] = useCreateTemplateLibraryAssignmentMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateTemplateLibraryAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTemplateLibraryAssignmentMutation, CreateTemplateLibraryAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTemplateLibraryAssignmentMutation, CreateTemplateLibraryAssignmentMutationVariables>(CreateTemplateLibraryAssignmentDocument, baseOptions);
      }
export type CreateTemplateLibraryAssignmentMutationHookResult = ReturnType<typeof useCreateTemplateLibraryAssignmentMutation>;
export type CreateTemplateLibraryAssignmentMutationResult = ApolloReactCommon.MutationResult<CreateTemplateLibraryAssignmentMutation>;
export type CreateTemplateLibraryAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTemplateLibraryAssignmentMutation, CreateTemplateLibraryAssignmentMutationVariables>;
export const DeleteTemplateLibraryAssignmentDocument = gql`
    mutation deleteTemplateLibraryAssignment($inputs: [TemplateLibraryAssignmentInput!]!) {
  deleteTemplateLibraryAssignment(inputs: $inputs)
}
    `;
export type DeleteTemplateLibraryAssignmentMutationFn = ApolloReactCommon.MutationFunction<DeleteTemplateLibraryAssignmentMutation, DeleteTemplateLibraryAssignmentMutationVariables>;

/**
 * __useDeleteTemplateLibraryAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateLibraryAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateLibraryAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateLibraryAssignmentMutation, { data, loading, error }] = useDeleteTemplateLibraryAssignmentMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useDeleteTemplateLibraryAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTemplateLibraryAssignmentMutation, DeleteTemplateLibraryAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTemplateLibraryAssignmentMutation, DeleteTemplateLibraryAssignmentMutationVariables>(DeleteTemplateLibraryAssignmentDocument, baseOptions);
      }
export type DeleteTemplateLibraryAssignmentMutationHookResult = ReturnType<typeof useDeleteTemplateLibraryAssignmentMutation>;
export type DeleteTemplateLibraryAssignmentMutationResult = ApolloReactCommon.MutationResult<DeleteTemplateLibraryAssignmentMutation>;
export type DeleteTemplateLibraryAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTemplateLibraryAssignmentMutation, DeleteTemplateLibraryAssignmentMutationVariables>;
export const GetAttendanceExclusionListingPageDataDocument = gql`
    query GetAttendanceExclusionListingPageData($CompanyID: String!, $offset: Float, $limit: Float, $keyword: String, $Page: String, $ExcludeFormStatus: String, $ExcludeEmployeeStatus: [String!], $TemplateLibraryAssignmentInput: TemplateLibraryAssignmentInput) {
  EmployeeListing(CompanyID: $CompanyID, offset: $offset, limit: $limit, ExcludeFormStatus: $ExcludeFormStatus, ExcludeEmployeeStatus: $ExcludeEmployeeStatus, keyword: $keyword, Page: $Page) {
    OrgUnitID
    JoinedDate
    JobGradeID
    CompanyID
    FormStatus
    EmployeeID
    EmployeeNo
    Contact {
      FullName
    }
  }
  getTemplateLibraryAssignment(input: $TemplateLibraryAssignmentInput) {
    TemplateLibraryAssignmentID
    Module
    SourceType
    SourceID
    TargetType
    TargetID
    Target {
      ... on EmployeeEntity {
        OrgUnitID
        JoinedDate
        CompanyID
        FormStatus
        EmployeeID
        EmployeeNo
        EmploymentType
        DocumentID
        Documents {
          DocumentID
          DocumentFile
        }
        DepartmentID
        DivisionID
        JobGradeID
        EmployeeDepartment {
          DepartmentID
          Description
        }
        Designation {
          JobDesignationID
          Name
        }
        Division {
          DepartmentID
          Description
        }
        Company {
          CompanyID
          CompanyName
        }
        Contact {
          FullName
        }
        Status
        JobGrade {
          JobGradeID
          Description
        }
      }
    }
  }
  EmployeeListingCount(CompanyID: $CompanyID, ExcludeFormStatus: $ExcludeFormStatus, ExcludeEmployeeStatus: $ExcludeEmployeeStatus, keyword: $keyword, Page: $Page)
}
    `;

/**
 * __useGetAttendanceExclusionListingPageDataQuery__
 *
 * To run a query within a React component, call `useGetAttendanceExclusionListingPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceExclusionListingPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceExclusionListingPageDataQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *      Page: // value for 'Page'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *      ExcludeEmployeeStatus: // value for 'ExcludeEmployeeStatus'
 *      TemplateLibraryAssignmentInput: // value for 'TemplateLibraryAssignmentInput'
 *   },
 * });
 */
export function useGetAttendanceExclusionListingPageDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendanceExclusionListingPageDataQuery, GetAttendanceExclusionListingPageDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendanceExclusionListingPageDataQuery, GetAttendanceExclusionListingPageDataQueryVariables>(GetAttendanceExclusionListingPageDataDocument, baseOptions);
      }
export function useGetAttendanceExclusionListingPageDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendanceExclusionListingPageDataQuery, GetAttendanceExclusionListingPageDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendanceExclusionListingPageDataQuery, GetAttendanceExclusionListingPageDataQueryVariables>(GetAttendanceExclusionListingPageDataDocument, baseOptions);
        }
export type GetAttendanceExclusionListingPageDataQueryHookResult = ReturnType<typeof useGetAttendanceExclusionListingPageDataQuery>;
export type GetAttendanceExclusionListingPageDataLazyQueryHookResult = ReturnType<typeof useGetAttendanceExclusionListingPageDataLazyQuery>;
export type GetAttendanceExclusionListingPageDataQueryResult = ApolloReactCommon.QueryResult<GetAttendanceExclusionListingPageDataQuery, GetAttendanceExclusionListingPageDataQueryVariables>;
export const SearchEmployeeDocument = gql`
    query SearchEmployee($EmployeeType: [String!], $DepartmentIDs: [String!], $EmployeeStatus: [String!], $CompanyID: String!) {
  EmployeeListing(EmployeeType: $EmployeeType, DepartmentIDs: $DepartmentIDs, EmployeeStatus: $EmployeeStatus, CompanyID: $CompanyID) {
    OrgUnitID
    JoinedDate
    JobGradeID
    CompanyID
    FormStatus
    EmployeeID
    EmployeeNo
    Contact {
      FullName
    }
  }
}
    `;

/**
 * __useSearchEmployeeQuery__
 *
 * To run a query within a React component, call `useSearchEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEmployeeQuery({
 *   variables: {
 *      EmployeeType: // value for 'EmployeeType'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useSearchEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchEmployeeQuery, SearchEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchEmployeeQuery, SearchEmployeeQueryVariables>(SearchEmployeeDocument, baseOptions);
      }
export function useSearchEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchEmployeeQuery, SearchEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchEmployeeQuery, SearchEmployeeQueryVariables>(SearchEmployeeDocument, baseOptions);
        }
export type SearchEmployeeQueryHookResult = ReturnType<typeof useSearchEmployeeQuery>;
export type SearchEmployeeLazyQueryHookResult = ReturnType<typeof useSearchEmployeeLazyQuery>;
export type SearchEmployeeQueryResult = ApolloReactCommon.QueryResult<SearchEmployeeQuery, SearchEmployeeQueryVariables>;
export const GetContactTracingDocument = gql`
    query getContactTracing($CompanyID: String!, $EmployeeID: String!, $EndDate: DateTime!, $StartDate: DateTime!) {
  getContactTracing(CompanyID: $CompanyID, EmployeeID: $EmployeeID, EndDate: $EndDate, StartDate: $StartDate)
}
    `;

/**
 * __useGetContactTracingQuery__
 *
 * To run a query within a React component, call `useGetContactTracingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactTracingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactTracingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *   },
 * });
 */
export function useGetContactTracingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactTracingQuery, GetContactTracingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContactTracingQuery, GetContactTracingQueryVariables>(GetContactTracingDocument, baseOptions);
      }
export function useGetContactTracingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactTracingQuery, GetContactTracingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContactTracingQuery, GetContactTracingQueryVariables>(GetContactTracingDocument, baseOptions);
        }
export type GetContactTracingQueryHookResult = ReturnType<typeof useGetContactTracingQuery>;
export type GetContactTracingLazyQueryHookResult = ReturnType<typeof useGetContactTracingLazyQuery>;
export type GetContactTracingQueryResult = ApolloReactCommon.QueryResult<GetContactTracingQuery, GetContactTracingQueryVariables>;
export const LogShiftGroupEmployeeAssignmentHistoryDocument = gql`
    mutation logShiftGroupEmployeeAssignmentHistory($inputs: [ShiftGroupEmployeeAssignmentLogInput!]!) {
  logShiftGroupEmployeeAssignmentHistory(inputs: $inputs)
}
    `;
export type LogShiftGroupEmployeeAssignmentHistoryMutationFn = ApolloReactCommon.MutationFunction<LogShiftGroupEmployeeAssignmentHistoryMutation, LogShiftGroupEmployeeAssignmentHistoryMutationVariables>;

/**
 * __useLogShiftGroupEmployeeAssignmentHistoryMutation__
 *
 * To run a mutation, you first call `useLogShiftGroupEmployeeAssignmentHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogShiftGroupEmployeeAssignmentHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logShiftGroupEmployeeAssignmentHistoryMutation, { data, loading, error }] = useLogShiftGroupEmployeeAssignmentHistoryMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useLogShiftGroupEmployeeAssignmentHistoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogShiftGroupEmployeeAssignmentHistoryMutation, LogShiftGroupEmployeeAssignmentHistoryMutationVariables>) {
        return ApolloReactHooks.useMutation<LogShiftGroupEmployeeAssignmentHistoryMutation, LogShiftGroupEmployeeAssignmentHistoryMutationVariables>(LogShiftGroupEmployeeAssignmentHistoryDocument, baseOptions);
      }
export type LogShiftGroupEmployeeAssignmentHistoryMutationHookResult = ReturnType<typeof useLogShiftGroupEmployeeAssignmentHistoryMutation>;
export type LogShiftGroupEmployeeAssignmentHistoryMutationResult = ApolloReactCommon.MutationResult<LogShiftGroupEmployeeAssignmentHistoryMutation>;
export type LogShiftGroupEmployeeAssignmentHistoryMutationOptions = ApolloReactCommon.BaseMutationOptions<LogShiftGroupEmployeeAssignmentHistoryMutation, LogShiftGroupEmployeeAssignmentHistoryMutationVariables>;
export const UpdateShiftGroupEmployeeAssignmentHistoryDocument = gql`
    mutation updateShiftGroupEmployeeAssignmentHistory($AssignmentLogID: String!, $StartDate: String!, $EndDate: String!) {
  updateShiftGroupEmployeeAssignmentHistory(AssignmentLogID: $AssignmentLogID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;
export type UpdateShiftGroupEmployeeAssignmentHistoryMutationFn = ApolloReactCommon.MutationFunction<UpdateShiftGroupEmployeeAssignmentHistoryMutation, UpdateShiftGroupEmployeeAssignmentHistoryMutationVariables>;

/**
 * __useUpdateShiftGroupEmployeeAssignmentHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateShiftGroupEmployeeAssignmentHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftGroupEmployeeAssignmentHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftGroupEmployeeAssignmentHistoryMutation, { data, loading, error }] = useUpdateShiftGroupEmployeeAssignmentHistoryMutation({
 *   variables: {
 *      AssignmentLogID: // value for 'AssignmentLogID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useUpdateShiftGroupEmployeeAssignmentHistoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShiftGroupEmployeeAssignmentHistoryMutation, UpdateShiftGroupEmployeeAssignmentHistoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateShiftGroupEmployeeAssignmentHistoryMutation, UpdateShiftGroupEmployeeAssignmentHistoryMutationVariables>(UpdateShiftGroupEmployeeAssignmentHistoryDocument, baseOptions);
      }
export type UpdateShiftGroupEmployeeAssignmentHistoryMutationHookResult = ReturnType<typeof useUpdateShiftGroupEmployeeAssignmentHistoryMutation>;
export type UpdateShiftGroupEmployeeAssignmentHistoryMutationResult = ApolloReactCommon.MutationResult<UpdateShiftGroupEmployeeAssignmentHistoryMutation>;
export type UpdateShiftGroupEmployeeAssignmentHistoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShiftGroupEmployeeAssignmentHistoryMutation, UpdateShiftGroupEmployeeAssignmentHistoryMutationVariables>;
export const DeleteShiftGroupEmployeeAssignmentHistoryDocument = gql`
    mutation deleteShiftGroupEmployeeAssignmentHistory($AssignmentLogID: String!) {
  deleteShiftGroupEmployeeAssignmentHistory(AssignmentLogID: $AssignmentLogID)
}
    `;
export type DeleteShiftGroupEmployeeAssignmentHistoryMutationFn = ApolloReactCommon.MutationFunction<DeleteShiftGroupEmployeeAssignmentHistoryMutation, DeleteShiftGroupEmployeeAssignmentHistoryMutationVariables>;

/**
 * __useDeleteShiftGroupEmployeeAssignmentHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteShiftGroupEmployeeAssignmentHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShiftGroupEmployeeAssignmentHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShiftGroupEmployeeAssignmentHistoryMutation, { data, loading, error }] = useDeleteShiftGroupEmployeeAssignmentHistoryMutation({
 *   variables: {
 *      AssignmentLogID: // value for 'AssignmentLogID'
 *   },
 * });
 */
export function useDeleteShiftGroupEmployeeAssignmentHistoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteShiftGroupEmployeeAssignmentHistoryMutation, DeleteShiftGroupEmployeeAssignmentHistoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteShiftGroupEmployeeAssignmentHistoryMutation, DeleteShiftGroupEmployeeAssignmentHistoryMutationVariables>(DeleteShiftGroupEmployeeAssignmentHistoryDocument, baseOptions);
      }
export type DeleteShiftGroupEmployeeAssignmentHistoryMutationHookResult = ReturnType<typeof useDeleteShiftGroupEmployeeAssignmentHistoryMutation>;
export type DeleteShiftGroupEmployeeAssignmentHistoryMutationResult = ApolloReactCommon.MutationResult<DeleteShiftGroupEmployeeAssignmentHistoryMutation>;
export type DeleteShiftGroupEmployeeAssignmentHistoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteShiftGroupEmployeeAssignmentHistoryMutation, DeleteShiftGroupEmployeeAssignmentHistoryMutationVariables>;
export const GetAttendanceRecordManualEntryPageTotalCountDocument = gql`
    query GetAttendanceRecordManualEntryPageTotalCount($input: AttendanceRecordInput, $timezoneOffset: Float, $DateTimeStart: DateTime, $DateTimeEnd: DateTime, $DepartmentIDs: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!], $DateTime: DateTime, $keyword: String) {
  GetAttendanceRecordManualEntryPageTotalCount(input: $input, timezoneOffset: $timezoneOffset, DateTimeStart: $DateTimeStart, DateTimeEnd: $DateTimeEnd, DepartmentIDs: $DepartmentIDs, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs, DateTime: $DateTime, keyword: $keyword)
}
    `;

/**
 * __useGetAttendanceRecordManualEntryPageTotalCountQuery__
 *
 * To run a query within a React component, call `useGetAttendanceRecordManualEntryPageTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceRecordManualEntryPageTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceRecordManualEntryPageTotalCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *      timezoneOffset: // value for 'timezoneOffset'
 *      DateTimeStart: // value for 'DateTimeStart'
 *      DateTimeEnd: // value for 'DateTimeEnd'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *      DateTime: // value for 'DateTime'
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGetAttendanceRecordManualEntryPageTotalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttendanceRecordManualEntryPageTotalCountQuery, GetAttendanceRecordManualEntryPageTotalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttendanceRecordManualEntryPageTotalCountQuery, GetAttendanceRecordManualEntryPageTotalCountQueryVariables>(GetAttendanceRecordManualEntryPageTotalCountDocument, baseOptions);
      }
export function useGetAttendanceRecordManualEntryPageTotalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttendanceRecordManualEntryPageTotalCountQuery, GetAttendanceRecordManualEntryPageTotalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttendanceRecordManualEntryPageTotalCountQuery, GetAttendanceRecordManualEntryPageTotalCountQueryVariables>(GetAttendanceRecordManualEntryPageTotalCountDocument, baseOptions);
        }
export type GetAttendanceRecordManualEntryPageTotalCountQueryHookResult = ReturnType<typeof useGetAttendanceRecordManualEntryPageTotalCountQuery>;
export type GetAttendanceRecordManualEntryPageTotalCountLazyQueryHookResult = ReturnType<typeof useGetAttendanceRecordManualEntryPageTotalCountLazyQuery>;
export type GetAttendanceRecordManualEntryPageTotalCountQueryResult = ApolloReactCommon.QueryResult<GetAttendanceRecordManualEntryPageTotalCountQuery, GetAttendanceRecordManualEntryPageTotalCountQueryVariables>;
export const SubmitShiftGroupPatternDocument = gql`
    mutation SubmitShiftGroupPattern($input: ShiftGroupPatternInput!) {
  SubmitShiftGroupPattern(input: $input)
}
    `;
export type SubmitShiftGroupPatternMutationFn = ApolloReactCommon.MutationFunction<SubmitShiftGroupPatternMutation, SubmitShiftGroupPatternMutationVariables>;

/**
 * __useSubmitShiftGroupPatternMutation__
 *
 * To run a mutation, you first call `useSubmitShiftGroupPatternMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitShiftGroupPatternMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitShiftGroupPatternMutation, { data, loading, error }] = useSubmitShiftGroupPatternMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitShiftGroupPatternMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitShiftGroupPatternMutation, SubmitShiftGroupPatternMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitShiftGroupPatternMutation, SubmitShiftGroupPatternMutationVariables>(SubmitShiftGroupPatternDocument, baseOptions);
      }
export type SubmitShiftGroupPatternMutationHookResult = ReturnType<typeof useSubmitShiftGroupPatternMutation>;
export type SubmitShiftGroupPatternMutationResult = ApolloReactCommon.MutationResult<SubmitShiftGroupPatternMutation>;
export type SubmitShiftGroupPatternMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitShiftGroupPatternMutation, SubmitShiftGroupPatternMutationVariables>;
export const DeleteShiftGroupPatternDocument = gql`
    mutation DeleteShiftGroupPattern($ShiftGroupPatternID: String!) {
  DeleteShiftGroupPattern(ShiftGroupPatternID: $ShiftGroupPatternID)
}
    `;
export type DeleteShiftGroupPatternMutationFn = ApolloReactCommon.MutationFunction<DeleteShiftGroupPatternMutation, DeleteShiftGroupPatternMutationVariables>;

/**
 * __useDeleteShiftGroupPatternMutation__
 *
 * To run a mutation, you first call `useDeleteShiftGroupPatternMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShiftGroupPatternMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShiftGroupPatternMutation, { data, loading, error }] = useDeleteShiftGroupPatternMutation({
 *   variables: {
 *      ShiftGroupPatternID: // value for 'ShiftGroupPatternID'
 *   },
 * });
 */
export function useDeleteShiftGroupPatternMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteShiftGroupPatternMutation, DeleteShiftGroupPatternMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteShiftGroupPatternMutation, DeleteShiftGroupPatternMutationVariables>(DeleteShiftGroupPatternDocument, baseOptions);
      }
export type DeleteShiftGroupPatternMutationHookResult = ReturnType<typeof useDeleteShiftGroupPatternMutation>;
export type DeleteShiftGroupPatternMutationResult = ApolloReactCommon.MutationResult<DeleteShiftGroupPatternMutation>;
export type DeleteShiftGroupPatternMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteShiftGroupPatternMutation, DeleteShiftGroupPatternMutationVariables>;
export const EmployeeShiftGroupAssignmentLogDocument = gql`
    query EmployeeShiftGroupAssignmentLog($EmployeeID: String!, $Today: Boolean) {
  EmployeeShiftGroupAssignmentLog(EmployeeID: $EmployeeID, Today: $Today) {
    ShiftGroupEmployeeAssignmentLogID
    ShiftGroupID
    ShiftGroup {
      Name
      ByQR
      QRSelfie
      ByLocation
      LocationSelfie
      ByBluetooth
      BluetoothSelfie
    }
    EmployeeID
    Start
    End
    Status
    hasDutyRoster
  }
}
    `;

/**
 * __useEmployeeShiftGroupAssignmentLogQuery__
 *
 * To run a query within a React component, call `useEmployeeShiftGroupAssignmentLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeShiftGroupAssignmentLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeShiftGroupAssignmentLogQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      Today: // value for 'Today'
 *   },
 * });
 */
export function useEmployeeShiftGroupAssignmentLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeShiftGroupAssignmentLogQuery, EmployeeShiftGroupAssignmentLogQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeShiftGroupAssignmentLogQuery, EmployeeShiftGroupAssignmentLogQueryVariables>(EmployeeShiftGroupAssignmentLogDocument, baseOptions);
      }
export function useEmployeeShiftGroupAssignmentLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeShiftGroupAssignmentLogQuery, EmployeeShiftGroupAssignmentLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeShiftGroupAssignmentLogQuery, EmployeeShiftGroupAssignmentLogQueryVariables>(EmployeeShiftGroupAssignmentLogDocument, baseOptions);
        }
export type EmployeeShiftGroupAssignmentLogQueryHookResult = ReturnType<typeof useEmployeeShiftGroupAssignmentLogQuery>;
export type EmployeeShiftGroupAssignmentLogLazyQueryHookResult = ReturnType<typeof useEmployeeShiftGroupAssignmentLogLazyQuery>;
export type EmployeeShiftGroupAssignmentLogQueryResult = ApolloReactCommon.QueryResult<EmployeeShiftGroupAssignmentLogQuery, EmployeeShiftGroupAssignmentLogQueryVariables>;
export const GetShiftGroupEployeeAssignmentDutyRosterDocument = gql`
    query GetShiftGroupEployeeAssignmentDutyRoster($CompanyID: String!, $EmployeeID: String, $StartDate: DateTime, $EndDate: DateTime) {
  getDutyRoster(CompanyID: $CompanyID, EmployeeID: $EmployeeID, StartDate: $StartDate, EndDate: $EndDate) {
    Date
  }
}
    `;

/**
 * __useGetShiftGroupEployeeAssignmentDutyRosterQuery__
 *
 * To run a query within a React component, call `useGetShiftGroupEployeeAssignmentDutyRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftGroupEployeeAssignmentDutyRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftGroupEployeeAssignmentDutyRosterQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useGetShiftGroupEployeeAssignmentDutyRosterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShiftGroupEployeeAssignmentDutyRosterQuery, GetShiftGroupEployeeAssignmentDutyRosterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShiftGroupEployeeAssignmentDutyRosterQuery, GetShiftGroupEployeeAssignmentDutyRosterQueryVariables>(GetShiftGroupEployeeAssignmentDutyRosterDocument, baseOptions);
      }
export function useGetShiftGroupEployeeAssignmentDutyRosterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShiftGroupEployeeAssignmentDutyRosterQuery, GetShiftGroupEployeeAssignmentDutyRosterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShiftGroupEployeeAssignmentDutyRosterQuery, GetShiftGroupEployeeAssignmentDutyRosterQueryVariables>(GetShiftGroupEployeeAssignmentDutyRosterDocument, baseOptions);
        }
export type GetShiftGroupEployeeAssignmentDutyRosterQueryHookResult = ReturnType<typeof useGetShiftGroupEployeeAssignmentDutyRosterQuery>;
export type GetShiftGroupEployeeAssignmentDutyRosterLazyQueryHookResult = ReturnType<typeof useGetShiftGroupEployeeAssignmentDutyRosterLazyQuery>;
export type GetShiftGroupEployeeAssignmentDutyRosterQueryResult = ApolloReactCommon.QueryResult<GetShiftGroupEployeeAssignmentDutyRosterQuery, GetShiftGroupEployeeAssignmentDutyRosterQueryVariables>;
export const OvertimeInsertDocument = gql`
    mutation OvertimeInsert($input: OvertimeInput!) {
  OvertimeInsert(input: $input) {
    OvertimeID
    OvertimeStatus
  }
}
    `;
export type OvertimeInsertMutationFn = ApolloReactCommon.MutationFunction<OvertimeInsertMutation, OvertimeInsertMutationVariables>;

/**
 * __useOvertimeInsertMutation__
 *
 * To run a mutation, you first call `useOvertimeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimeInsertMutation, { data, loading, error }] = useOvertimeInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOvertimeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimeInsertMutation, OvertimeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimeInsertMutation, OvertimeInsertMutationVariables>(OvertimeInsertDocument, baseOptions);
      }
export type OvertimeInsertMutationHookResult = ReturnType<typeof useOvertimeInsertMutation>;
export type OvertimeInsertMutationResult = ApolloReactCommon.MutationResult<OvertimeInsertMutation>;
export type OvertimeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimeInsertMutation, OvertimeInsertMutationVariables>;
export const OvertimeDraftDocument = gql`
    mutation OvertimeDraft($input: OvertimeInput!) {
  OvertimeDraft(input: $input)
}
    `;
export type OvertimeDraftMutationFn = ApolloReactCommon.MutationFunction<OvertimeDraftMutation, OvertimeDraftMutationVariables>;

/**
 * __useOvertimeDraftMutation__
 *
 * To run a mutation, you first call `useOvertimeDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimeDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimeDraftMutation, { data, loading, error }] = useOvertimeDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOvertimeDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimeDraftMutation, OvertimeDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimeDraftMutation, OvertimeDraftMutationVariables>(OvertimeDraftDocument, baseOptions);
      }
export type OvertimeDraftMutationHookResult = ReturnType<typeof useOvertimeDraftMutation>;
export type OvertimeDraftMutationResult = ApolloReactCommon.MutationResult<OvertimeDraftMutation>;
export type OvertimeDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimeDraftMutation, OvertimeDraftMutationVariables>;
export const OvertimeDeleteDocument = gql`
    mutation OvertimeDelete($OvertimeID: String!) {
  OvertimeDelete(OvertimeID: $OvertimeID)
}
    `;
export type OvertimeDeleteMutationFn = ApolloReactCommon.MutationFunction<OvertimeDeleteMutation, OvertimeDeleteMutationVariables>;

/**
 * __useOvertimeDeleteMutation__
 *
 * To run a mutation, you first call `useOvertimeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimeDeleteMutation, { data, loading, error }] = useOvertimeDeleteMutation({
 *   variables: {
 *      OvertimeID: // value for 'OvertimeID'
 *   },
 * });
 */
export function useOvertimeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimeDeleteMutation, OvertimeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimeDeleteMutation, OvertimeDeleteMutationVariables>(OvertimeDeleteDocument, baseOptions);
      }
export type OvertimeDeleteMutationHookResult = ReturnType<typeof useOvertimeDeleteMutation>;
export type OvertimeDeleteMutationResult = ApolloReactCommon.MutationResult<OvertimeDeleteMutation>;
export type OvertimeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimeDeleteMutation, OvertimeDeleteMutationVariables>;
export const OvertimeUpdateDocument = gql`
    mutation OvertimeUpdate($input: [OvertimeInput!]!, $ActionStatus: String, $IsSupersede: Boolean) {
  OvertimeUpdate(input: $input, ActionStatus: $ActionStatus, IsSupersede: $IsSupersede)
}
    `;
export type OvertimeUpdateMutationFn = ApolloReactCommon.MutationFunction<OvertimeUpdateMutation, OvertimeUpdateMutationVariables>;

/**
 * __useOvertimeUpdateMutation__
 *
 * To run a mutation, you first call `useOvertimeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimeUpdateMutation, { data, loading, error }] = useOvertimeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ActionStatus: // value for 'ActionStatus'
 *      IsSupersede: // value for 'IsSupersede'
 *   },
 * });
 */
export function useOvertimeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimeUpdateMutation, OvertimeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimeUpdateMutation, OvertimeUpdateMutationVariables>(OvertimeUpdateDocument, baseOptions);
      }
export type OvertimeUpdateMutationHookResult = ReturnType<typeof useOvertimeUpdateMutation>;
export type OvertimeUpdateMutationResult = ApolloReactCommon.MutationResult<OvertimeUpdateMutation>;
export type OvertimeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimeUpdateMutation, OvertimeUpdateMutationVariables>;
export const OvertimeBatchDocument = gql`
    mutation OvertimeBatch($input: [OvertimeInput!]!) {
  OvertimeBatch(Input: $input)
}
    `;
export type OvertimeBatchMutationFn = ApolloReactCommon.MutationFunction<OvertimeBatchMutation, OvertimeBatchMutationVariables>;

/**
 * __useOvertimeBatchMutation__
 *
 * To run a mutation, you first call `useOvertimeBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimeBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimeBatchMutation, { data, loading, error }] = useOvertimeBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOvertimeBatchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimeBatchMutation, OvertimeBatchMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimeBatchMutation, OvertimeBatchMutationVariables>(OvertimeBatchDocument, baseOptions);
      }
export type OvertimeBatchMutationHookResult = ReturnType<typeof useOvertimeBatchMutation>;
export type OvertimeBatchMutationResult = ApolloReactCommon.MutationResult<OvertimeBatchMutation>;
export type OvertimeBatchMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimeBatchMutation, OvertimeBatchMutationVariables>;
export const OvertimeWithdrawalCheckingDocument = gql`
    query OvertimeWithdrawalChecking($EmployeeID: String!, $WorkDate: DateTime!) {
  OvertimeWithdrawalChecking(EmployeeID: $EmployeeID, WorkDate: $WorkDate)
}
    `;

/**
 * __useOvertimeWithdrawalCheckingQuery__
 *
 * To run a query within a React component, call `useOvertimeWithdrawalCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOvertimeWithdrawalCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOvertimeWithdrawalCheckingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      WorkDate: // value for 'WorkDate'
 *   },
 * });
 */
export function useOvertimeWithdrawalCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OvertimeWithdrawalCheckingQuery, OvertimeWithdrawalCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<OvertimeWithdrawalCheckingQuery, OvertimeWithdrawalCheckingQueryVariables>(OvertimeWithdrawalCheckingDocument, baseOptions);
      }
export function useOvertimeWithdrawalCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OvertimeWithdrawalCheckingQuery, OvertimeWithdrawalCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OvertimeWithdrawalCheckingQuery, OvertimeWithdrawalCheckingQueryVariables>(OvertimeWithdrawalCheckingDocument, baseOptions);
        }
export type OvertimeWithdrawalCheckingQueryHookResult = ReturnType<typeof useOvertimeWithdrawalCheckingQuery>;
export type OvertimeWithdrawalCheckingLazyQueryHookResult = ReturnType<typeof useOvertimeWithdrawalCheckingLazyQuery>;
export type OvertimeWithdrawalCheckingQueryResult = ApolloReactCommon.QueryResult<OvertimeWithdrawalCheckingQuery, OvertimeWithdrawalCheckingQueryVariables>;
export const OtReasonByTlaDocument = gql`
    query OTReasonByTLA($CompanyID: String!) {
  OTReasonByTLA(CompanyID: $CompanyID) {
    OvertimeReasonID
    Name
  }
}
    `;

/**
 * __useOtReasonByTlaQuery__
 *
 * To run a query within a React component, call `useOtReasonByTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtReasonByTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtReasonByTlaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useOtReasonByTlaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OtReasonByTlaQuery, OtReasonByTlaQueryVariables>) {
        return ApolloReactHooks.useQuery<OtReasonByTlaQuery, OtReasonByTlaQueryVariables>(OtReasonByTlaDocument, baseOptions);
      }
export function useOtReasonByTlaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OtReasonByTlaQuery, OtReasonByTlaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OtReasonByTlaQuery, OtReasonByTlaQueryVariables>(OtReasonByTlaDocument, baseOptions);
        }
export type OtReasonByTlaQueryHookResult = ReturnType<typeof useOtReasonByTlaQuery>;
export type OtReasonByTlaLazyQueryHookResult = ReturnType<typeof useOtReasonByTlaLazyQuery>;
export type OtReasonByTlaQueryResult = ApolloReactCommon.QueryResult<OtReasonByTlaQuery, OtReasonByTlaQueryVariables>;
export const CreateAttendanceRecordDocument = gql`
    mutation createAttendanceRecord($input: AttendanceRecordInput!, $DocumentsInput: DocumentsInput) {
  createAttendanceRecord(input: $input, DocumentsInput: $DocumentsInput) {
    AttendanceRecordID
  }
}
    `;
export type CreateAttendanceRecordMutationFn = ApolloReactCommon.MutationFunction<CreateAttendanceRecordMutation, CreateAttendanceRecordMutationVariables>;

/**
 * __useCreateAttendanceRecordMutation__
 *
 * To run a mutation, you first call `useCreateAttendanceRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttendanceRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttendanceRecordMutation, { data, loading, error }] = useCreateAttendanceRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useCreateAttendanceRecordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAttendanceRecordMutation, CreateAttendanceRecordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAttendanceRecordMutation, CreateAttendanceRecordMutationVariables>(CreateAttendanceRecordDocument, baseOptions);
      }
export type CreateAttendanceRecordMutationHookResult = ReturnType<typeof useCreateAttendanceRecordMutation>;
export type CreateAttendanceRecordMutationResult = ApolloReactCommon.MutationResult<CreateAttendanceRecordMutation>;
export type CreateAttendanceRecordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAttendanceRecordMutation, CreateAttendanceRecordMutationVariables>;
export const VerifyScanDocument = gql`
    query VerifyScan($CompanyID: String!, $Location: String!) {
  VerifyScan(CompanyID: $CompanyID, Location: $Location)
}
    `;

/**
 * __useVerifyScanQuery__
 *
 * To run a query within a React component, call `useVerifyScanQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyScanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyScanQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Location: // value for 'Location'
 *   },
 * });
 */
export function useVerifyScanQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VerifyScanQuery, VerifyScanQueryVariables>) {
        return ApolloReactHooks.useQuery<VerifyScanQuery, VerifyScanQueryVariables>(VerifyScanDocument, baseOptions);
      }
export function useVerifyScanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VerifyScanQuery, VerifyScanQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VerifyScanQuery, VerifyScanQueryVariables>(VerifyScanDocument, baseOptions);
        }
export type VerifyScanQueryHookResult = ReturnType<typeof useVerifyScanQuery>;
export type VerifyScanLazyQueryHookResult = ReturnType<typeof useVerifyScanLazyQuery>;
export type VerifyScanQueryResult = ApolloReactCommon.QueryResult<VerifyScanQuery, VerifyScanQueryVariables>;
export const AttendanceHistoryDocument = gql`
    query AttendanceHistory($CompanyID: String!, $EmployeeID: String!, $FromDate: String!, $ToDate: String!) {
  AttendanceHistory(CompanyID: $CompanyID, EmployeeID: $EmployeeID, FromDate: $FromDate, ToDate: $ToDate)
}
    `;

/**
 * __useAttendanceHistoryQuery__
 *
 * To run a query within a React component, call `useAttendanceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceHistoryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useAttendanceHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttendanceHistoryQuery, AttendanceHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<AttendanceHistoryQuery, AttendanceHistoryQueryVariables>(AttendanceHistoryDocument, baseOptions);
      }
export function useAttendanceHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttendanceHistoryQuery, AttendanceHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttendanceHistoryQuery, AttendanceHistoryQueryVariables>(AttendanceHistoryDocument, baseOptions);
        }
export type AttendanceHistoryQueryHookResult = ReturnType<typeof useAttendanceHistoryQuery>;
export type AttendanceHistoryLazyQueryHookResult = ReturnType<typeof useAttendanceHistoryLazyQuery>;
export type AttendanceHistoryQueryResult = ApolloReactCommon.QueryResult<AttendanceHistoryQuery, AttendanceHistoryQueryVariables>;
export const EmpAttendanceHistoryDocument = gql`
    query EmpAttendanceHistory($CompanyID: String!, $EmployeeID: String!, $FromDate: String!, $ToDate: String!) {
  EmpAttendanceHistory(CompanyID: $CompanyID, EmployeeID: $EmployeeID, FromDate: $FromDate, ToDate: $ToDate)
}
    `;

/**
 * __useEmpAttendanceHistoryQuery__
 *
 * To run a query within a React component, call `useEmpAttendanceHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmpAttendanceHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmpAttendanceHistoryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *   },
 * });
 */
export function useEmpAttendanceHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmpAttendanceHistoryQuery, EmpAttendanceHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmpAttendanceHistoryQuery, EmpAttendanceHistoryQueryVariables>(EmpAttendanceHistoryDocument, baseOptions);
      }
export function useEmpAttendanceHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmpAttendanceHistoryQuery, EmpAttendanceHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmpAttendanceHistoryQuery, EmpAttendanceHistoryQueryVariables>(EmpAttendanceHistoryDocument, baseOptions);
        }
export type EmpAttendanceHistoryQueryHookResult = ReturnType<typeof useEmpAttendanceHistoryQuery>;
export type EmpAttendanceHistoryLazyQueryHookResult = ReturnType<typeof useEmpAttendanceHistoryLazyQuery>;
export type EmpAttendanceHistoryQueryResult = ApolloReactCommon.QueryResult<EmpAttendanceHistoryQuery, EmpAttendanceHistoryQueryVariables>;
export const AttendanceRecordListingDocument = gql`
    query AttendanceRecordListing($CompanyID: String!, $EmployeeID: String) {
  AttendanceRecordListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID) {
    AttendanceRecordID
    EmployeeID
    CompanyID
    DateTime
    IntegrationClockType
    ExcelFileName
    CreatedDT
    ModifiedDT
    Location
    Address
    Lat
    Long
    Radius
  }
}
    `;

/**
 * __useAttendanceRecordListingQuery__
 *
 * To run a query within a React component, call `useAttendanceRecordListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceRecordListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceRecordListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useAttendanceRecordListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttendanceRecordListingQuery, AttendanceRecordListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AttendanceRecordListingQuery, AttendanceRecordListingQueryVariables>(AttendanceRecordListingDocument, baseOptions);
      }
export function useAttendanceRecordListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttendanceRecordListingQuery, AttendanceRecordListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttendanceRecordListingQuery, AttendanceRecordListingQueryVariables>(AttendanceRecordListingDocument, baseOptions);
        }
export type AttendanceRecordListingQueryHookResult = ReturnType<typeof useAttendanceRecordListingQuery>;
export type AttendanceRecordListingLazyQueryHookResult = ReturnType<typeof useAttendanceRecordListingLazyQuery>;
export type AttendanceRecordListingQueryResult = ApolloReactCommon.QueryResult<AttendanceRecordListingQuery, AttendanceRecordListingQueryVariables>;
export const AttendanceTableDocument = gql`
    query AttendanceTable($CompanyID: String!, $FromDate: String!, $ToDate: String!, $EmployeeIDs: [String!]!) {
  AttendanceAnalysis(CompanyID: $CompanyID, FromDate: $FromDate, ToDate: $ToDate, EmployeeIDs: $EmployeeIDs) {
    OrgUnitID
    JobGradeID
    Date
    EmployeeID
    CompanyID
    EmployeeName
    OrgUnit
    EmployeeNo
    ShiftName
    DayType
    ShiftTimeIn
    ShiftTimeOut
    ActualIn
    ActualOut
    PreOT
    PostOT
    OrgUnit
    ShiftPlanDay
    ShiftHour
    WorkHour
    ActualWorkHour
    RawOTHours
    RawOTDay
    LateInDay
    LateIn
    EarlyOut
    Absenteeism
    TimeOff
    ApprovedOTHours
    Leave
    TravelTime
    UnproductiveTime
    Irregular
    IncompleteClocking
    FixedHours
    Location
    Address
    Lat
    Long
  }
}
    `;

/**
 * __useAttendanceTableQuery__
 *
 * To run a query within a React component, call `useAttendanceTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceTableQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *   },
 * });
 */
export function useAttendanceTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AttendanceTableQuery, AttendanceTableQueryVariables>) {
        return ApolloReactHooks.useQuery<AttendanceTableQuery, AttendanceTableQueryVariables>(AttendanceTableDocument, baseOptions);
      }
export function useAttendanceTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttendanceTableQuery, AttendanceTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AttendanceTableQuery, AttendanceTableQueryVariables>(AttendanceTableDocument, baseOptions);
        }
export type AttendanceTableQueryHookResult = ReturnType<typeof useAttendanceTableQuery>;
export type AttendanceTableLazyQueryHookResult = ReturnType<typeof useAttendanceTableLazyQuery>;
export type AttendanceTableQueryResult = ApolloReactCommon.QueryResult<AttendanceTableQuery, AttendanceTableQueryVariables>;
export const SimpleOvertimeListingDocument = gql`
    query SimpleOvertimeListing($CompanyID: String!, $EmployeeID: String, $IsExcelFileOnly: Boolean) {
  OvertimeListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID, IsExcelFileOnly: $IsExcelFileOnly) {
    OvertimeID
    ExcelFileName
  }
}
    `;

/**
 * __useSimpleOvertimeListingQuery__
 *
 * To run a query within a React component, call `useSimpleOvertimeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleOvertimeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleOvertimeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      IsExcelFileOnly: // value for 'IsExcelFileOnly'
 *   },
 * });
 */
export function useSimpleOvertimeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimpleOvertimeListingQuery, SimpleOvertimeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SimpleOvertimeListingQuery, SimpleOvertimeListingQueryVariables>(SimpleOvertimeListingDocument, baseOptions);
      }
export function useSimpleOvertimeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimpleOvertimeListingQuery, SimpleOvertimeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimpleOvertimeListingQuery, SimpleOvertimeListingQueryVariables>(SimpleOvertimeListingDocument, baseOptions);
        }
export type SimpleOvertimeListingQueryHookResult = ReturnType<typeof useSimpleOvertimeListingQuery>;
export type SimpleOvertimeListingLazyQueryHookResult = ReturnType<typeof useSimpleOvertimeListingLazyQuery>;
export type SimpleOvertimeListingQueryResult = ApolloReactCommon.QueryResult<SimpleOvertimeListingQuery, SimpleOvertimeListingQueryVariables>;
export const OvertimeListingDocument = gql`
    query OvertimeListing($CompanyID: String!, $EmployeeID: String) {
  OvertimeListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID) {
    OvertimeID
    EmployeeID
    CompanyID
    WorkDate
    StartTime
    EndTime
    Duration
    Remark
    OvertimeReasonID
    ExcelFileName
    OvertimeStatus
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    ApproverID
    Approver
    ApproveDate
    RejectionRemarks
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    otReason {
      OvertimeReasonID
      Name
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        NotifiedDT
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
          ApproverName
        }
      }
    }
  }
}
    `;

/**
 * __useOvertimeListingQuery__
 *
 * To run a query within a React component, call `useOvertimeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOvertimeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOvertimeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useOvertimeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OvertimeListingQuery, OvertimeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OvertimeListingQuery, OvertimeListingQueryVariables>(OvertimeListingDocument, baseOptions);
      }
export function useOvertimeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OvertimeListingQuery, OvertimeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OvertimeListingQuery, OvertimeListingQueryVariables>(OvertimeListingDocument, baseOptions);
        }
export type OvertimeListingQueryHookResult = ReturnType<typeof useOvertimeListingQuery>;
export type OvertimeListingLazyQueryHookResult = ReturnType<typeof useOvertimeListingLazyQuery>;
export type OvertimeListingQueryResult = ApolloReactCommon.QueryResult<OvertimeListingQuery, OvertimeListingQueryVariables>;
export const DutyRosterSummaryByEmployeeDocument = gql`
    query DutyRosterSummaryByEmployee($CompanyID: String!, $date: DateTime!) {
  DutyRosterSummaryByEmployee(CompanyID: $CompanyID, date: $date)
}
    `;

/**
 * __useDutyRosterSummaryByEmployeeQuery__
 *
 * To run a query within a React component, call `useDutyRosterSummaryByEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDutyRosterSummaryByEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDutyRosterSummaryByEmployeeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDutyRosterSummaryByEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DutyRosterSummaryByEmployeeQuery, DutyRosterSummaryByEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<DutyRosterSummaryByEmployeeQuery, DutyRosterSummaryByEmployeeQueryVariables>(DutyRosterSummaryByEmployeeDocument, baseOptions);
      }
export function useDutyRosterSummaryByEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DutyRosterSummaryByEmployeeQuery, DutyRosterSummaryByEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DutyRosterSummaryByEmployeeQuery, DutyRosterSummaryByEmployeeQueryVariables>(DutyRosterSummaryByEmployeeDocument, baseOptions);
        }
export type DutyRosterSummaryByEmployeeQueryHookResult = ReturnType<typeof useDutyRosterSummaryByEmployeeQuery>;
export type DutyRosterSummaryByEmployeeLazyQueryHookResult = ReturnType<typeof useDutyRosterSummaryByEmployeeLazyQuery>;
export type DutyRosterSummaryByEmployeeQueryResult = ApolloReactCommon.QueryResult<DutyRosterSummaryByEmployeeQuery, DutyRosterSummaryByEmployeeQueryVariables>;
export const DutyRosterDatesDocument = gql`
    query DutyRosterDates($CompanyID: String!, $date: DateTime!, $EmployeeID: String) {
  DutyRosterDates(CompanyID: $CompanyID, date: $date, EmployeeID: $EmployeeID)
}
    `;

/**
 * __useDutyRosterDatesQuery__
 *
 * To run a query within a React component, call `useDutyRosterDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDutyRosterDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDutyRosterDatesQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      date: // value for 'date'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useDutyRosterDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DutyRosterDatesQuery, DutyRosterDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<DutyRosterDatesQuery, DutyRosterDatesQueryVariables>(DutyRosterDatesDocument, baseOptions);
      }
export function useDutyRosterDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DutyRosterDatesQuery, DutyRosterDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DutyRosterDatesQuery, DutyRosterDatesQueryVariables>(DutyRosterDatesDocument, baseOptions);
        }
export type DutyRosterDatesQueryHookResult = ReturnType<typeof useDutyRosterDatesQuery>;
export type DutyRosterDatesLazyQueryHookResult = ReturnType<typeof useDutyRosterDatesLazyQuery>;
export type DutyRosterDatesQueryResult = ApolloReactCommon.QueryResult<DutyRosterDatesQuery, DutyRosterDatesQueryVariables>;
export const EmployeeListingByCompanyDocument = gql`
    query EmployeeListingByCompany($CompanyID: String!, $DepartmentIDs: [String!], $EmployeeType: [String!], $EmployeeStatus: [String!], $OrgUnitID: [String!], $ExcludeFormStatus: String, $ExcludeEmployeeStatus: [String!], $CheckForResignedDate: [String!], $offset: Float, $limit: Float, $keyword: String, $Page: String) {
  EmployeeListing(CompanyID: $CompanyID, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, EmployeeStatus: $EmployeeStatus, ExcludeFormStatus: $ExcludeFormStatus, ExcludeEmployeeStatus: $ExcludeEmployeeStatus, CheckForResignedDate: $CheckForResignedDate, OrgUnitID: $OrgUnitID, offset: $offset, limit: $limit, keyword: $keyword, Page: $Page) {
    EmployeeID
    EmployeeNo
    CalendarStateID
    CalendarPolicyID
    Contact {
      ContactID
      FullName
    }
    Designation {
      JobDesignationID
      Name
    }
    Company {
      CompanyID
      CompanyName
    }
    IsInterCompanyTransfer
    JoinedDate
    JoinedReferenceDate
    Probation
    ConfirmedDate
    EmployeeStatus
    Status
    FormStatus
    ResignedDate
    EmploymentType
    Documents {
      DocumentID
      DocumentFile
    }
    JobGradeID
    OrgUnitID
    OrgUnit {
      CompanyOrgUnitID
      Level
      Description
      ParentID
    }
  }
}
    `;

/**
 * __useEmployeeListingByCompanyQuery__
 *
 * To run a query within a React component, call `useEmployeeListingByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListingByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListingByCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeType: // value for 'EmployeeType'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      ExcludeFormStatus: // value for 'ExcludeFormStatus'
 *      ExcludeEmployeeStatus: // value for 'ExcludeEmployeeStatus'
 *      CheckForResignedDate: // value for 'CheckForResignedDate'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      keyword: // value for 'keyword'
 *      Page: // value for 'Page'
 *   },
 * });
 */
export function useEmployeeListingByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeListingByCompanyQuery, EmployeeListingByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeListingByCompanyQuery, EmployeeListingByCompanyQueryVariables>(EmployeeListingByCompanyDocument, baseOptions);
      }
export function useEmployeeListingByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeListingByCompanyQuery, EmployeeListingByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeListingByCompanyQuery, EmployeeListingByCompanyQueryVariables>(EmployeeListingByCompanyDocument, baseOptions);
        }
export type EmployeeListingByCompanyQueryHookResult = ReturnType<typeof useEmployeeListingByCompanyQuery>;
export type EmployeeListingByCompanyLazyQueryHookResult = ReturnType<typeof useEmployeeListingByCompanyLazyQuery>;
export type EmployeeListingByCompanyQueryResult = ApolloReactCommon.QueryResult<EmployeeListingByCompanyQuery, EmployeeListingByCompanyQueryVariables>;
export const EmployeeTmsSummaryDocument = gql`
    query EmployeeTMSSummary($CompanyID: String!, $EmployeeID: String) {
  EmployeeTMSSummary(CompanyID: $CompanyID, EmployeeID: $EmployeeID) {
    Roster
    DutyRosterDate
    OT
    TimeOff
    CheckInDate
    CheckOutDate
    ScanDate
    Shift
  }
}
    `;

/**
 * __useEmployeeTmsSummaryQuery__
 *
 * To run a query within a React component, call `useEmployeeTmsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeTmsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeTmsSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useEmployeeTmsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeTmsSummaryQuery, EmployeeTmsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeTmsSummaryQuery, EmployeeTmsSummaryQueryVariables>(EmployeeTmsSummaryDocument, baseOptions);
      }
export function useEmployeeTmsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeTmsSummaryQuery, EmployeeTmsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeTmsSummaryQuery, EmployeeTmsSummaryQueryVariables>(EmployeeTmsSummaryDocument, baseOptions);
        }
export type EmployeeTmsSummaryQueryHookResult = ReturnType<typeof useEmployeeTmsSummaryQuery>;
export type EmployeeTmsSummaryLazyQueryHookResult = ReturnType<typeof useEmployeeTmsSummaryLazyQuery>;
export type EmployeeTmsSummaryQueryResult = ApolloReactCommon.QueryResult<EmployeeTmsSummaryQuery, EmployeeTmsSummaryQueryVariables>;
export const GetSingleUserDocument = gql`
    query GetSingleUser($ID: [String!]!) {
  GetSingleUser(ID: $ID) {
    ID
    userName
  }
}
    `;

/**
 * __useGetSingleUserQuery__
 *
 * To run a query within a React component, call `useGetSingleUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSingleUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSingleUserQuery, GetSingleUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSingleUserQuery, GetSingleUserQueryVariables>(GetSingleUserDocument, baseOptions);
      }
export function useGetSingleUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSingleUserQuery, GetSingleUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSingleUserQuery, GetSingleUserQueryVariables>(GetSingleUserDocument, baseOptions);
        }
export type GetSingleUserQueryHookResult = ReturnType<typeof useGetSingleUserQuery>;
export type GetSingleUserLazyQueryHookResult = ReturnType<typeof useGetSingleUserLazyQuery>;
export type GetSingleUserQueryResult = ApolloReactCommon.QueryResult<GetSingleUserQuery, GetSingleUserQueryVariables>;
export const GetOtPolicyByJobGradeDocument = gql`
    query GetOTPolicyByJobGrade($Status: Status, $input: TemplateLibraryAssignmentInput) {
  getTemplateLibraryAssignment(input: $input) {
    TargetID
    TargetType
    SourceType
    SourceID
    Module
  }
  getOvertimePolicy {
    Status
    OvertimePolicyID
    Title
    LateIn
    EarlyOut
  }
  getOvertimeClaimTable(Status: $Status) {
    Status
    OvertimeClaimTableID
    Title
  }
}
    `;

/**
 * __useGetOtPolicyByJobGradeQuery__
 *
 * To run a query within a React component, call `useGetOtPolicyByJobGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtPolicyByJobGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtPolicyByJobGradeQuery({
 *   variables: {
 *      Status: // value for 'Status'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOtPolicyByJobGradeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOtPolicyByJobGradeQuery, GetOtPolicyByJobGradeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOtPolicyByJobGradeQuery, GetOtPolicyByJobGradeQueryVariables>(GetOtPolicyByJobGradeDocument, baseOptions);
      }
export function useGetOtPolicyByJobGradeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOtPolicyByJobGradeQuery, GetOtPolicyByJobGradeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOtPolicyByJobGradeQuery, GetOtPolicyByJobGradeQueryVariables>(GetOtPolicyByJobGradeDocument, baseOptions);
        }
export type GetOtPolicyByJobGradeQueryHookResult = ReturnType<typeof useGetOtPolicyByJobGradeQuery>;
export type GetOtPolicyByJobGradeLazyQueryHookResult = ReturnType<typeof useGetOtPolicyByJobGradeLazyQuery>;
export type GetOtPolicyByJobGradeQueryResult = ApolloReactCommon.QueryResult<GetOtPolicyByJobGradeQuery, GetOtPolicyByJobGradeQueryVariables>;
export const GetEmployeeOtPoliciesDocument = gql`
    query GetEmployeeOtPolicies($CompanyID: String!, $EmployeeID: String!, $WorkDate: DateTime!) {
  GetEmployeeOtPolicies(CompanyID: $CompanyID, EmployeeID: $EmployeeID, WorkDate: $WorkDate)
}
    `;

/**
 * __useGetEmployeeOtPoliciesQuery__
 *
 * To run a query within a React component, call `useGetEmployeeOtPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeOtPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeOtPoliciesQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      WorkDate: // value for 'WorkDate'
 *   },
 * });
 */
export function useGetEmployeeOtPoliciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeOtPoliciesQuery, GetEmployeeOtPoliciesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeOtPoliciesQuery, GetEmployeeOtPoliciesQueryVariables>(GetEmployeeOtPoliciesDocument, baseOptions);
      }
export function useGetEmployeeOtPoliciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeOtPoliciesQuery, GetEmployeeOtPoliciesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeOtPoliciesQuery, GetEmployeeOtPoliciesQueryVariables>(GetEmployeeOtPoliciesDocument, baseOptions);
        }
export type GetEmployeeOtPoliciesQueryHookResult = ReturnType<typeof useGetEmployeeOtPoliciesQuery>;
export type GetEmployeeOtPoliciesLazyQueryHookResult = ReturnType<typeof useGetEmployeeOtPoliciesLazyQuery>;
export type GetEmployeeOtPoliciesQueryResult = ApolloReactCommon.QueryResult<GetEmployeeOtPoliciesQuery, GetEmployeeOtPoliciesQueryVariables>;
export const GetAttenRecordsDocument = gql`
    query getAttenRecords($WorkDate: DateTime!, $CompanyID: String!, $EmployeeID: String!) {
  getAttenRecords(WorkDate: $WorkDate, CompanyID: $CompanyID, EmployeeID: $EmployeeID) {
    AttendanceRecordID
    EmployeeID
    CompanyID
    DateTime
    IntegrationClockType
  }
}
    `;

/**
 * __useGetAttenRecordsQuery__
 *
 * To run a query within a React component, call `useGetAttenRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttenRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttenRecordsQuery({
 *   variables: {
 *      WorkDate: // value for 'WorkDate'
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetAttenRecordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttenRecordsQuery, GetAttenRecordsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttenRecordsQuery, GetAttenRecordsQueryVariables>(GetAttenRecordsDocument, baseOptions);
      }
export function useGetAttenRecordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttenRecordsQuery, GetAttenRecordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttenRecordsQuery, GetAttenRecordsQueryVariables>(GetAttenRecordsDocument, baseOptions);
        }
export type GetAttenRecordsQueryHookResult = ReturnType<typeof useGetAttenRecordsQuery>;
export type GetAttenRecordsLazyQueryHookResult = ReturnType<typeof useGetAttenRecordsLazyQuery>;
export type GetAttenRecordsQueryResult = ApolloReactCommon.QueryResult<GetAttenRecordsQuery, GetAttenRecordsQueryVariables>;
export const GetServerDateTimeDocument = gql`
    query GetServerDateTime {
  GetServerDateTime
}
    `;

/**
 * __useGetServerDateTimeQuery__
 *
 * To run a query within a React component, call `useGetServerDateTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServerDateTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServerDateTimeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServerDateTimeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetServerDateTimeQuery, GetServerDateTimeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetServerDateTimeQuery, GetServerDateTimeQueryVariables>(GetServerDateTimeDocument, baseOptions);
      }
export function useGetServerDateTimeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetServerDateTimeQuery, GetServerDateTimeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetServerDateTimeQuery, GetServerDateTimeQueryVariables>(GetServerDateTimeDocument, baseOptions);
        }
export type GetServerDateTimeQueryHookResult = ReturnType<typeof useGetServerDateTimeQuery>;
export type GetServerDateTimeLazyQueryHookResult = ReturnType<typeof useGetServerDateTimeLazyQuery>;
export type GetServerDateTimeQueryResult = ApolloReactCommon.QueryResult<GetServerDateTimeQuery, GetServerDateTimeQueryVariables>;
export const SimpleEmployeeListingDocument = gql`
    query SimpleEmployeeListing($CompanyID: String!) {
  SimpleEmployeeListing(CompanyID: $CompanyID) {
    EmployeeID
    EmployeeNo
  }
}
    `;

/**
 * __useSimpleEmployeeListingQuery__
 *
 * To run a query within a React component, call `useSimpleEmployeeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleEmployeeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleEmployeeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useSimpleEmployeeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimpleEmployeeListingQuery, SimpleEmployeeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SimpleEmployeeListingQuery, SimpleEmployeeListingQueryVariables>(SimpleEmployeeListingDocument, baseOptions);
      }
export function useSimpleEmployeeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimpleEmployeeListingQuery, SimpleEmployeeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimpleEmployeeListingQuery, SimpleEmployeeListingQueryVariables>(SimpleEmployeeListingDocument, baseOptions);
        }
export type SimpleEmployeeListingQueryHookResult = ReturnType<typeof useSimpleEmployeeListingQuery>;
export type SimpleEmployeeListingLazyQueryHookResult = ReturnType<typeof useSimpleEmployeeListingLazyQuery>;
export type SimpleEmployeeListingQueryResult = ApolloReactCommon.QueryResult<SimpleEmployeeListingQuery, SimpleEmployeeListingQueryVariables>;
export const OvertimeExcelUploadDocument = gql`
    mutation OvertimeExcelUpload($File: Upload!, $FileName: String!, $CompanyID: String!) {
  OvertimeExcelUpload(File: $File, FileName: $FileName, CompanyID: $CompanyID) {
    ProcessOvertimeID
    CompanyID
    EmployeeID
    OvertimeReasonID
    WorkDate
    StartTime
    EndTime
    Remark
    Index
    OvertimeReason {
      OvertimeReasonID
      Name
    }
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    HasDutyRoster
  }
}
    `;
export type OvertimeExcelUploadMutationFn = ApolloReactCommon.MutationFunction<OvertimeExcelUploadMutation, OvertimeExcelUploadMutationVariables>;

/**
 * __useOvertimeExcelUploadMutation__
 *
 * To run a mutation, you first call `useOvertimeExcelUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimeExcelUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimeExcelUploadMutation, { data, loading, error }] = useOvertimeExcelUploadMutation({
 *   variables: {
 *      File: // value for 'File'
 *      FileName: // value for 'FileName'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useOvertimeExcelUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimeExcelUploadMutation, OvertimeExcelUploadMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimeExcelUploadMutation, OvertimeExcelUploadMutationVariables>(OvertimeExcelUploadDocument, baseOptions);
      }
export type OvertimeExcelUploadMutationHookResult = ReturnType<typeof useOvertimeExcelUploadMutation>;
export type OvertimeExcelUploadMutationResult = ApolloReactCommon.MutationResult<OvertimeExcelUploadMutation>;
export type OvertimeExcelUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimeExcelUploadMutation, OvertimeExcelUploadMutationVariables>;
export const OvertimeExcelDeleteDocument = gql`
    mutation OvertimeExcelDelete($FileName: String!) {
  OvertimeExcelDelete(FileName: $FileName)
}
    `;
export type OvertimeExcelDeleteMutationFn = ApolloReactCommon.MutationFunction<OvertimeExcelDeleteMutation, OvertimeExcelDeleteMutationVariables>;

/**
 * __useOvertimeExcelDeleteMutation__
 *
 * To run a mutation, you first call `useOvertimeExcelDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimeExcelDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimeExcelDeleteMutation, { data, loading, error }] = useOvertimeExcelDeleteMutation({
 *   variables: {
 *      FileName: // value for 'FileName'
 *   },
 * });
 */
export function useOvertimeExcelDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimeExcelDeleteMutation, OvertimeExcelDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimeExcelDeleteMutation, OvertimeExcelDeleteMutationVariables>(OvertimeExcelDeleteDocument, baseOptions);
      }
export type OvertimeExcelDeleteMutationHookResult = ReturnType<typeof useOvertimeExcelDeleteMutation>;
export type OvertimeExcelDeleteMutationResult = ApolloReactCommon.MutationResult<OvertimeExcelDeleteMutation>;
export type OvertimeExcelDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimeExcelDeleteMutation, OvertimeExcelDeleteMutationVariables>;
export const OvertimeExcelInsertActualDocument = gql`
    mutation OvertimeExcelInsertActual($FileName: String!, $CompanyID: String!) {
  OvertimeExcelInsertActual(FileName: $FileName, CompanyID: $CompanyID)
}
    `;
export type OvertimeExcelInsertActualMutationFn = ApolloReactCommon.MutationFunction<OvertimeExcelInsertActualMutation, OvertimeExcelInsertActualMutationVariables>;

/**
 * __useOvertimeExcelInsertActualMutation__
 *
 * To run a mutation, you first call `useOvertimeExcelInsertActualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimeExcelInsertActualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimeExcelInsertActualMutation, { data, loading, error }] = useOvertimeExcelInsertActualMutation({
 *   variables: {
 *      FileName: // value for 'FileName'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useOvertimeExcelInsertActualMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimeExcelInsertActualMutation, OvertimeExcelInsertActualMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimeExcelInsertActualMutation, OvertimeExcelInsertActualMutationVariables>(OvertimeExcelInsertActualDocument, baseOptions);
      }
export type OvertimeExcelInsertActualMutationHookResult = ReturnType<typeof useOvertimeExcelInsertActualMutation>;
export type OvertimeExcelInsertActualMutationResult = ApolloReactCommon.MutationResult<OvertimeExcelInsertActualMutation>;
export type OvertimeExcelInsertActualMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimeExcelInsertActualMutation, OvertimeExcelInsertActualMutationVariables>;
export const AttendanceExcelUploadDocument = gql`
    mutation AttendanceExcelUpload($File: Upload!, $FileName: String!, $CompanyID: String!) {
  AttendanceExcelUpload(File: $File, FileName: $FileName, CompanyID: $CompanyID) {
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    IntergrationClockType
    DateTime
    Remark
    ExcelFileName
    Index
    AttendanceRecordID
  }
}
    `;
export type AttendanceExcelUploadMutationFn = ApolloReactCommon.MutationFunction<AttendanceExcelUploadMutation, AttendanceExcelUploadMutationVariables>;

/**
 * __useAttendanceExcelUploadMutation__
 *
 * To run a mutation, you first call `useAttendanceExcelUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendanceExcelUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendanceExcelUploadMutation, { data, loading, error }] = useAttendanceExcelUploadMutation({
 *   variables: {
 *      File: // value for 'File'
 *      FileName: // value for 'FileName'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useAttendanceExcelUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttendanceExcelUploadMutation, AttendanceExcelUploadMutationVariables>) {
        return ApolloReactHooks.useMutation<AttendanceExcelUploadMutation, AttendanceExcelUploadMutationVariables>(AttendanceExcelUploadDocument, baseOptions);
      }
export type AttendanceExcelUploadMutationHookResult = ReturnType<typeof useAttendanceExcelUploadMutation>;
export type AttendanceExcelUploadMutationResult = ApolloReactCommon.MutationResult<AttendanceExcelUploadMutation>;
export type AttendanceExcelUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<AttendanceExcelUploadMutation, AttendanceExcelUploadMutationVariables>;
export const AttendanceExcelDeleteDocument = gql`
    mutation AttendanceExcelDelete($FileName: String!) {
  AttendanceExcelDelete(FileName: $FileName)
}
    `;
export type AttendanceExcelDeleteMutationFn = ApolloReactCommon.MutationFunction<AttendanceExcelDeleteMutation, AttendanceExcelDeleteMutationVariables>;

/**
 * __useAttendanceExcelDeleteMutation__
 *
 * To run a mutation, you first call `useAttendanceExcelDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendanceExcelDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendanceExcelDeleteMutation, { data, loading, error }] = useAttendanceExcelDeleteMutation({
 *   variables: {
 *      FileName: // value for 'FileName'
 *   },
 * });
 */
export function useAttendanceExcelDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttendanceExcelDeleteMutation, AttendanceExcelDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AttendanceExcelDeleteMutation, AttendanceExcelDeleteMutationVariables>(AttendanceExcelDeleteDocument, baseOptions);
      }
export type AttendanceExcelDeleteMutationHookResult = ReturnType<typeof useAttendanceExcelDeleteMutation>;
export type AttendanceExcelDeleteMutationResult = ApolloReactCommon.MutationResult<AttendanceExcelDeleteMutation>;
export type AttendanceExcelDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AttendanceExcelDeleteMutation, AttendanceExcelDeleteMutationVariables>;
export const AttendanceExcelInsertActualDocument = gql`
    mutation AttendanceExcelInsertActual($FileName: String!, $CompanyID: String!) {
  AttendanceExcelInsertActual(FileName: $FileName, CompanyID: $CompanyID)
}
    `;
export type AttendanceExcelInsertActualMutationFn = ApolloReactCommon.MutationFunction<AttendanceExcelInsertActualMutation, AttendanceExcelInsertActualMutationVariables>;

/**
 * __useAttendanceExcelInsertActualMutation__
 *
 * To run a mutation, you first call `useAttendanceExcelInsertActualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendanceExcelInsertActualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendanceExcelInsertActualMutation, { data, loading, error }] = useAttendanceExcelInsertActualMutation({
 *   variables: {
 *      FileName: // value for 'FileName'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useAttendanceExcelInsertActualMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttendanceExcelInsertActualMutation, AttendanceExcelInsertActualMutationVariables>) {
        return ApolloReactHooks.useMutation<AttendanceExcelInsertActualMutation, AttendanceExcelInsertActualMutationVariables>(AttendanceExcelInsertActualDocument, baseOptions);
      }
export type AttendanceExcelInsertActualMutationHookResult = ReturnType<typeof useAttendanceExcelInsertActualMutation>;
export type AttendanceExcelInsertActualMutationResult = ApolloReactCommon.MutationResult<AttendanceExcelInsertActualMutation>;
export type AttendanceExcelInsertActualMutationOptions = ApolloReactCommon.BaseMutationOptions<AttendanceExcelInsertActualMutation, AttendanceExcelInsertActualMutationVariables>;
export const GetChangeShiftByYearDocument = gql`
    query getChangeShiftByYear($ChangeShiftStatus: String, $Date: DateTime!, $CompanyID: String!) {
  getChangeShiftByYear(ChangeShiftStatus: $ChangeShiftStatus, Date: $Date, CompanyID: $CompanyID) {
    ChangeShiftID
    Date
    FromShift {
      ShiftID
      Name
    }
    ToShift {
      ShiftID
      Name
    }
    Reason
    ChangeShiftStatus
    CreatedDT
    ModifiedDT
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    DocumentID
    ReplacedEmployee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
  }
}
    `;

/**
 * __useGetChangeShiftByYearQuery__
 *
 * To run a query within a React component, call `useGetChangeShiftByYearQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeShiftByYearQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeShiftByYearQuery({
 *   variables: {
 *      ChangeShiftStatus: // value for 'ChangeShiftStatus'
 *      Date: // value for 'Date'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetChangeShiftByYearQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetChangeShiftByYearQuery, GetChangeShiftByYearQueryVariables>) {
        return ApolloReactHooks.useQuery<GetChangeShiftByYearQuery, GetChangeShiftByYearQueryVariables>(GetChangeShiftByYearDocument, baseOptions);
      }
export function useGetChangeShiftByYearLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetChangeShiftByYearQuery, GetChangeShiftByYearQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetChangeShiftByYearQuery, GetChangeShiftByYearQueryVariables>(GetChangeShiftByYearDocument, baseOptions);
        }
export type GetChangeShiftByYearQueryHookResult = ReturnType<typeof useGetChangeShiftByYearQuery>;
export type GetChangeShiftByYearLazyQueryHookResult = ReturnType<typeof useGetChangeShiftByYearLazyQuery>;
export type GetChangeShiftByYearQueryResult = ApolloReactCommon.QueryResult<GetChangeShiftByYearQuery, GetChangeShiftByYearQueryVariables>;
export const GetChangeShiftListingDocument = gql`
    query getChangeShiftListing($EmployeeID: String) {
  getChangeShiftListing(EmployeeID: $EmployeeID) {
    ChangeShiftID
    Date
    FromShift {
      ShiftID
      Name
    }
    ToShift {
      ShiftID
      Name
    }
    Reason
    RejectReason
    Approver
    ChangeShiftStatus
    CreatedDT
    ModifiedDT
    ApprovedDate
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    DocumentID
    ReplacedEmployee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        NotifiedDT
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
          ApproverName
        }
      }
    }
  }
}
    `;

/**
 * __useGetChangeShiftListingQuery__
 *
 * To run a query within a React component, call `useGetChangeShiftListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeShiftListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeShiftListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetChangeShiftListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetChangeShiftListingQuery, GetChangeShiftListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetChangeShiftListingQuery, GetChangeShiftListingQueryVariables>(GetChangeShiftListingDocument, baseOptions);
      }
export function useGetChangeShiftListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetChangeShiftListingQuery, GetChangeShiftListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetChangeShiftListingQuery, GetChangeShiftListingQueryVariables>(GetChangeShiftListingDocument, baseOptions);
        }
export type GetChangeShiftListingQueryHookResult = ReturnType<typeof useGetChangeShiftListingQuery>;
export type GetChangeShiftListingLazyQueryHookResult = ReturnType<typeof useGetChangeShiftListingLazyQuery>;
export type GetChangeShiftListingQueryResult = ApolloReactCommon.QueryResult<GetChangeShiftListingQuery, GetChangeShiftListingQueryVariables>;
export const GetChangeShiftDocument = gql`
    query getChangeShift($ChangeShiftID: String, $CompanyID: String!) {
  getChangeShift(ChangeShiftID: $ChangeShiftID, CompanyID: $CompanyID) {
    ChangeShiftID
    Date
    Reason
    ChangeShiftStatus
    RejectReason
    CreatedDT
    ModifiedBy
    ModifiedDT
    CreatedBy
    FromShift {
      ShiftID
      Name
    }
    Employee {
      EmployeeID
      Contact {
        FullName
      }
      EmployeeNo
    }
    ToShift {
      ShiftID
      Name
    }
    DocumentID
    ReplacedEmployee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
  }
}
    `;

/**
 * __useGetChangeShiftQuery__
 *
 * To run a query within a React component, call `useGetChangeShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeShiftQuery({
 *   variables: {
 *      ChangeShiftID: // value for 'ChangeShiftID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetChangeShiftQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetChangeShiftQuery, GetChangeShiftQueryVariables>) {
        return ApolloReactHooks.useQuery<GetChangeShiftQuery, GetChangeShiftQueryVariables>(GetChangeShiftDocument, baseOptions);
      }
export function useGetChangeShiftLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetChangeShiftQuery, GetChangeShiftQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetChangeShiftQuery, GetChangeShiftQueryVariables>(GetChangeShiftDocument, baseOptions);
        }
export type GetChangeShiftQueryHookResult = ReturnType<typeof useGetChangeShiftQuery>;
export type GetChangeShiftLazyQueryHookResult = ReturnType<typeof useGetChangeShiftLazyQuery>;
export type GetChangeShiftQueryResult = ApolloReactCommon.QueryResult<GetChangeShiftQuery, GetChangeShiftQueryVariables>;
export const CreateChangeShiftDocument = gql`
    mutation createChangeShift($ChangeShiftInput: ChangeShiftInput!, $DocumentsInput: DocumentsInput) {
  createChangeShift(ChangeShiftInput: $ChangeShiftInput, DocumentsInput: $DocumentsInput) {
    ChangeShiftID
    ChangeShiftStatus
  }
}
    `;
export type CreateChangeShiftMutationFn = ApolloReactCommon.MutationFunction<CreateChangeShiftMutation, CreateChangeShiftMutationVariables>;

/**
 * __useCreateChangeShiftMutation__
 *
 * To run a mutation, you first call `useCreateChangeShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChangeShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChangeShiftMutation, { data, loading, error }] = useCreateChangeShiftMutation({
 *   variables: {
 *      ChangeShiftInput: // value for 'ChangeShiftInput'
 *      DocumentsInput: // value for 'DocumentsInput'
 *   },
 * });
 */
export function useCreateChangeShiftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateChangeShiftMutation, CreateChangeShiftMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateChangeShiftMutation, CreateChangeShiftMutationVariables>(CreateChangeShiftDocument, baseOptions);
      }
export type CreateChangeShiftMutationHookResult = ReturnType<typeof useCreateChangeShiftMutation>;
export type CreateChangeShiftMutationResult = ApolloReactCommon.MutationResult<CreateChangeShiftMutation>;
export type CreateChangeShiftMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateChangeShiftMutation, CreateChangeShiftMutationVariables>;
export const UpdateChangeShiftDocument = gql`
    mutation updateChangeShift($RejectReason: String, $CSStatus: String!, $Input: [ChangeShiftInput!]!, $IsSupersede: Boolean) {
  updateChangeShift(RejectReason: $RejectReason, CSStatus: $CSStatus, Input: $Input, IsSupersede: $IsSupersede)
}
    `;
export type UpdateChangeShiftMutationFn = ApolloReactCommon.MutationFunction<UpdateChangeShiftMutation, UpdateChangeShiftMutationVariables>;

/**
 * __useUpdateChangeShiftMutation__
 *
 * To run a mutation, you first call `useUpdateChangeShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChangeShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChangeShiftMutation, { data, loading, error }] = useUpdateChangeShiftMutation({
 *   variables: {
 *      RejectReason: // value for 'RejectReason'
 *      CSStatus: // value for 'CSStatus'
 *      Input: // value for 'Input'
 *      IsSupersede: // value for 'IsSupersede'
 *   },
 * });
 */
export function useUpdateChangeShiftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateChangeShiftMutation, UpdateChangeShiftMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateChangeShiftMutation, UpdateChangeShiftMutationVariables>(UpdateChangeShiftDocument, baseOptions);
      }
export type UpdateChangeShiftMutationHookResult = ReturnType<typeof useUpdateChangeShiftMutation>;
export type UpdateChangeShiftMutationResult = ApolloReactCommon.MutationResult<UpdateChangeShiftMutation>;
export type UpdateChangeShiftMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateChangeShiftMutation, UpdateChangeShiftMutationVariables>;
export const DeleteChangeShiftDocument = gql`
    mutation deleteChangeShift($ChangeShiftID: String!) {
  deleteChangeShift(ChangeShiftID: $ChangeShiftID)
}
    `;
export type DeleteChangeShiftMutationFn = ApolloReactCommon.MutationFunction<DeleteChangeShiftMutation, DeleteChangeShiftMutationVariables>;

/**
 * __useDeleteChangeShiftMutation__
 *
 * To run a mutation, you first call `useDeleteChangeShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChangeShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChangeShiftMutation, { data, loading, error }] = useDeleteChangeShiftMutation({
 *   variables: {
 *      ChangeShiftID: // value for 'ChangeShiftID'
 *   },
 * });
 */
export function useDeleteChangeShiftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteChangeShiftMutation, DeleteChangeShiftMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteChangeShiftMutation, DeleteChangeShiftMutationVariables>(DeleteChangeShiftDocument, baseOptions);
      }
export type DeleteChangeShiftMutationHookResult = ReturnType<typeof useDeleteChangeShiftMutation>;
export type DeleteChangeShiftMutationResult = ApolloReactCommon.MutationResult<DeleteChangeShiftMutation>;
export type DeleteChangeShiftMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteChangeShiftMutation, DeleteChangeShiftMutationVariables>;
export const UpdateChangeShiftSubmitDocument = gql`
    mutation updateChangeShiftSubmit($input: ChangeShiftInput!) {
  updateChangeShiftSubmit(input: $input)
}
    `;
export type UpdateChangeShiftSubmitMutationFn = ApolloReactCommon.MutationFunction<UpdateChangeShiftSubmitMutation, UpdateChangeShiftSubmitMutationVariables>;

/**
 * __useUpdateChangeShiftSubmitMutation__
 *
 * To run a mutation, you first call `useUpdateChangeShiftSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChangeShiftSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChangeShiftSubmitMutation, { data, loading, error }] = useUpdateChangeShiftSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChangeShiftSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateChangeShiftSubmitMutation, UpdateChangeShiftSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateChangeShiftSubmitMutation, UpdateChangeShiftSubmitMutationVariables>(UpdateChangeShiftSubmitDocument, baseOptions);
      }
export type UpdateChangeShiftSubmitMutationHookResult = ReturnType<typeof useUpdateChangeShiftSubmitMutation>;
export type UpdateChangeShiftSubmitMutationResult = ApolloReactCommon.MutationResult<UpdateChangeShiftSubmitMutation>;
export type UpdateChangeShiftSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateChangeShiftSubmitMutation, UpdateChangeShiftSubmitMutationVariables>;
export const ChangeShiftMenuSummaryDocument = gql`
    query ChangeShiftMenuSummary($CreatedDT: DateTime, $CompanyID: String!) {
  ChangeShiftMenuSummary(CreatedDT: $CreatedDT, CompanyID: $CompanyID)
}
    `;

/**
 * __useChangeShiftMenuSummaryQuery__
 *
 * To run a query within a React component, call `useChangeShiftMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeShiftMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeShiftMenuSummaryQuery({
 *   variables: {
 *      CreatedDT: // value for 'CreatedDT'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useChangeShiftMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChangeShiftMenuSummaryQuery, ChangeShiftMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ChangeShiftMenuSummaryQuery, ChangeShiftMenuSummaryQueryVariables>(ChangeShiftMenuSummaryDocument, baseOptions);
      }
export function useChangeShiftMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChangeShiftMenuSummaryQuery, ChangeShiftMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChangeShiftMenuSummaryQuery, ChangeShiftMenuSummaryQueryVariables>(ChangeShiftMenuSummaryDocument, baseOptions);
        }
export type ChangeShiftMenuSummaryQueryHookResult = ReturnType<typeof useChangeShiftMenuSummaryQuery>;
export type ChangeShiftMenuSummaryLazyQueryHookResult = ReturnType<typeof useChangeShiftMenuSummaryLazyQuery>;
export type ChangeShiftMenuSummaryQueryResult = ApolloReactCommon.QueryResult<ChangeShiftMenuSummaryQuery, ChangeShiftMenuSummaryQueryVariables>;
export const GetShiftByTlaDocument = gql`
    query getShiftByTLA($CompanyID: String!) {
  getShiftByTLA(CompanyID: $CompanyID) {
    ShiftID
    Name
    DutyRosterDisplayColour
    shiftDayList {
      ShiftDayID
      DayType
    }
  }
}
    `;

/**
 * __useGetShiftByTlaQuery__
 *
 * To run a query within a React component, call `useGetShiftByTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftByTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftByTlaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetShiftByTlaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShiftByTlaQuery, GetShiftByTlaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShiftByTlaQuery, GetShiftByTlaQueryVariables>(GetShiftByTlaDocument, baseOptions);
      }
export function useGetShiftByTlaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShiftByTlaQuery, GetShiftByTlaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShiftByTlaQuery, GetShiftByTlaQueryVariables>(GetShiftByTlaDocument, baseOptions);
        }
export type GetShiftByTlaQueryHookResult = ReturnType<typeof useGetShiftByTlaQuery>;
export type GetShiftByTlaLazyQueryHookResult = ReturnType<typeof useGetShiftByTlaLazyQuery>;
export type GetShiftByTlaQueryResult = ApolloReactCommon.QueryResult<GetShiftByTlaQuery, GetShiftByTlaQueryVariables>;
export const GetSimpleDutyRosterDocument = gql`
    query getSimpleDutyRoster($ExcelFileName: String, $CompanyID: String!, $DutyDate: DateTime, $EmployeeID: String, $StartDate: DateTime, $EndDate: DateTime) {
  getDutyRoster(ExcelFileName: $ExcelFileName, CompanyID: $CompanyID, DutyDate: $DutyDate, EmployeeID: $EmployeeID, StartDate: $StartDate, EndDate: $EndDate) {
    Date
    Employee {
      EmployeeNo
    }
  }
}
    `;

/**
 * __useGetSimpleDutyRosterQuery__
 *
 * To run a query within a React component, call `useGetSimpleDutyRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimpleDutyRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimpleDutyRosterQuery({
 *   variables: {
 *      ExcelFileName: // value for 'ExcelFileName'
 *      CompanyID: // value for 'CompanyID'
 *      DutyDate: // value for 'DutyDate'
 *      EmployeeID: // value for 'EmployeeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useGetSimpleDutyRosterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSimpleDutyRosterQuery, GetSimpleDutyRosterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSimpleDutyRosterQuery, GetSimpleDutyRosterQueryVariables>(GetSimpleDutyRosterDocument, baseOptions);
      }
export function useGetSimpleDutyRosterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSimpleDutyRosterQuery, GetSimpleDutyRosterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSimpleDutyRosterQuery, GetSimpleDutyRosterQueryVariables>(GetSimpleDutyRosterDocument, baseOptions);
        }
export type GetSimpleDutyRosterQueryHookResult = ReturnType<typeof useGetSimpleDutyRosterQuery>;
export type GetSimpleDutyRosterLazyQueryHookResult = ReturnType<typeof useGetSimpleDutyRosterLazyQuery>;
export type GetSimpleDutyRosterQueryResult = ApolloReactCommon.QueryResult<GetSimpleDutyRosterQuery, GetSimpleDutyRosterQueryVariables>;
export const GetDutyRosterDocument = gql`
    query getDutyRoster($ExcelFileName: String, $CompanyID: String!, $DutyDate: DateTime, $EmployeeID: String, $StartDate: DateTime, $EndDate: DateTime) {
  getDutyRoster(ExcelFileName: $ExcelFileName, CompanyID: $CompanyID, DutyDate: $DutyDate, EmployeeID: $EmployeeID, StartDate: $StartDate, EndDate: $EndDate) {
    DutyRosterID
    Date
    ShiftDayID
    ShiftID
    EmployeeID
    ShiftPatternID
    ShiftGroupID
    CompanyID
    ExcelFileName
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    shiftDay {
      DayType
      ClockPairing
      CrossDay
      FlexiHour
      TotalWorkingHour
      ClockInTime
      ClockOutTime
      ShiftStartTime
      ShiftEndTime
      FirstHalfEndTime
      SecondHalfStartTime
      CoreHourStartTime
      CoreHourEndTime
      FlexiBreak
      ShiftBreakInterval
      CoreHourStartTime
      CoreHourEndTime
    }
    shift {
      Name
      DutyRosterDisplayColour
      IsPreOvertimeMin
      PreOvertimeMin
      IsPostOvertimeMin
      PostOvertimeMin
      IsFixedOvertimeMin
      FixedOvertimeTotal
      breakTimeList {
        BreakTimeID
        Type
        Start
        End
        ShiftID
      }
    }
  }
}
    `;

/**
 * __useGetDutyRosterQuery__
 *
 * To run a query within a React component, call `useGetDutyRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDutyRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDutyRosterQuery({
 *   variables: {
 *      ExcelFileName: // value for 'ExcelFileName'
 *      CompanyID: // value for 'CompanyID'
 *      DutyDate: // value for 'DutyDate'
 *      EmployeeID: // value for 'EmployeeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useGetDutyRosterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDutyRosterQuery, GetDutyRosterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDutyRosterQuery, GetDutyRosterQueryVariables>(GetDutyRosterDocument, baseOptions);
      }
export function useGetDutyRosterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDutyRosterQuery, GetDutyRosterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDutyRosterQuery, GetDutyRosterQueryVariables>(GetDutyRosterDocument, baseOptions);
        }
export type GetDutyRosterQueryHookResult = ReturnType<typeof useGetDutyRosterQuery>;
export type GetDutyRosterLazyQueryHookResult = ReturnType<typeof useGetDutyRosterLazyQuery>;
export type GetDutyRosterQueryResult = ApolloReactCommon.QueryResult<GetDutyRosterQuery, GetDutyRosterQueryVariables>;
export const GetChangeShiftByYearTmsDocument = gql`
    query getChangeShiftByYearTms($ChangeShiftStatus: String, $Date: DateTime!, $CompanyID: String!, $EmployeeStatus: [String!], $DepartmentIDs: [String!], $EmployeeType: [String!], $JobGradeIDs: [String!]) {
  getChangeShiftByYear(ChangeShiftStatus: $ChangeShiftStatus, Date: $Date, CompanyID: $CompanyID, EmployeeStatus: $EmployeeStatus, DepartmentIDs: $DepartmentIDs, EmployeeType: $EmployeeType, JobGradeIDs: $JobGradeIDs) {
    ChangeShiftID
    Date
    FromShift {
      ShiftID
      Name
    }
    ToShift {
      ShiftID
      Name
    }
    Reason
    ChangeShiftStatus
    CreatedDT
    ModifiedDT
    CreatedBy
    ModifiedBy
    RejectReason
    Approver
    ApprovedDate
    CancelledBy
    Employee {
      OrgUnitID
      JoinedDate
      JobGradeID
      CompanyID
      FormStatus
      EmployeeID
      DepartmentID
      DivisionID
      EmploymentType
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    DocumentID
    ReplacedEmployee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
      changeShiftList {
        EmployeeID
      }
    }
    WorkFlow {
      ID
      WorkFlowStatus
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        NotifiedDT
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
          ApproverName
        }
      }
    }
  }
}
    `;

/**
 * __useGetChangeShiftByYearTmsQuery__
 *
 * To run a query within a React component, call `useGetChangeShiftByYearTmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeShiftByYearTmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeShiftByYearTmsQuery({
 *   variables: {
 *      ChangeShiftStatus: // value for 'ChangeShiftStatus'
 *      Date: // value for 'Date'
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      DepartmentIDs: // value for 'DepartmentIDs'
 *      EmployeeType: // value for 'EmployeeType'
 *      JobGradeIDs: // value for 'JobGradeIDs'
 *   },
 * });
 */
export function useGetChangeShiftByYearTmsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetChangeShiftByYearTmsQuery, GetChangeShiftByYearTmsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetChangeShiftByYearTmsQuery, GetChangeShiftByYearTmsQueryVariables>(GetChangeShiftByYearTmsDocument, baseOptions);
      }
export function useGetChangeShiftByYearTmsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetChangeShiftByYearTmsQuery, GetChangeShiftByYearTmsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetChangeShiftByYearTmsQuery, GetChangeShiftByYearTmsQueryVariables>(GetChangeShiftByYearTmsDocument, baseOptions);
        }
export type GetChangeShiftByYearTmsQueryHookResult = ReturnType<typeof useGetChangeShiftByYearTmsQuery>;
export type GetChangeShiftByYearTmsLazyQueryHookResult = ReturnType<typeof useGetChangeShiftByYearTmsLazyQuery>;
export type GetChangeShiftByYearTmsQueryResult = ApolloReactCommon.QueryResult<GetChangeShiftByYearTmsQuery, GetChangeShiftByYearTmsQueryVariables>;
export const TemplateLibrarySummaryDocument = gql`
    query TemplateLibrarySummary($CompanyID: String) {
  TemplateLibrarySummary(CompanyID: $CompanyID) {
    ShiftSetup
    ShiftPattern
    ShiftGroup
    OvertimePolicy
    OvertimeClaimTable
    OvertimeReasonCode
    OvertimeRoundingPolicy
    DailyAllowancePolicy
    MonthlyAllowancePolicy
    PeriodicDeductionPolicy
    DeudctionPolicy
    DeductionRoundingPolicy
    TimeOff
    TimeOffReasonCode
    MobileCheckInLocation
    templateDate
    assignedJob
    JobGrade
    company
    OvertimeCutOffDay
    OTRoundingPolicy
    AutomatedClockDataImport
    PeriodicDate
  }
}
    `;

/**
 * __useTemplateLibrarySummaryQuery__
 *
 * To run a query within a React component, call `useTemplateLibrarySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateLibrarySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateLibrarySummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useTemplateLibrarySummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TemplateLibrarySummaryQuery, TemplateLibrarySummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<TemplateLibrarySummaryQuery, TemplateLibrarySummaryQueryVariables>(TemplateLibrarySummaryDocument, baseOptions);
      }
export function useTemplateLibrarySummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TemplateLibrarySummaryQuery, TemplateLibrarySummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TemplateLibrarySummaryQuery, TemplateLibrarySummaryQueryVariables>(TemplateLibrarySummaryDocument, baseOptions);
        }
export type TemplateLibrarySummaryQueryHookResult = ReturnType<typeof useTemplateLibrarySummaryQuery>;
export type TemplateLibrarySummaryLazyQueryHookResult = ReturnType<typeof useTemplateLibrarySummaryLazyQuery>;
export type TemplateLibrarySummaryQueryResult = ApolloReactCommon.QueryResult<TemplateLibrarySummaryQuery, TemplateLibrarySummaryQueryVariables>;
export const GetOtRoundingDocument = gql`
    query getOTRounding($orderByAsc: String) {
  getOTRounding(orderByAsc: $orderByAsc) {
    OTRoundingID
    Name
    Status
    ModifiedDT
    CreatedDT
    RoundingCriteria
  }
}
    `;

/**
 * __useGetOtRoundingQuery__
 *
 * To run a query within a React component, call `useGetOtRoundingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtRoundingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtRoundingQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetOtRoundingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOtRoundingQuery, GetOtRoundingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOtRoundingQuery, GetOtRoundingQueryVariables>(GetOtRoundingDocument, baseOptions);
      }
export function useGetOtRoundingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOtRoundingQuery, GetOtRoundingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOtRoundingQuery, GetOtRoundingQueryVariables>(GetOtRoundingDocument, baseOptions);
        }
export type GetOtRoundingQueryHookResult = ReturnType<typeof useGetOtRoundingQuery>;
export type GetOtRoundingLazyQueryHookResult = ReturnType<typeof useGetOtRoundingLazyQuery>;
export type GetOtRoundingQueryResult = ApolloReactCommon.QueryResult<GetOtRoundingQuery, GetOtRoundingQueryVariables>;
export const ShiftSettingInsertDocument = gql`
    mutation ShiftSettingInsert($ShiftInput: ShiftInput!, $BreakTimeInput: [BreakTimeInput!]!, $ShiftDayInput: [ShiftDayInput!]!) {
  ShiftSettingInsert(ShiftInput: $ShiftInput, BreakTimeInput: $BreakTimeInput, ShiftDayInput: $ShiftDayInput)
}
    `;
export type ShiftSettingInsertMutationFn = ApolloReactCommon.MutationFunction<ShiftSettingInsertMutation, ShiftSettingInsertMutationVariables>;

/**
 * __useShiftSettingInsertMutation__
 *
 * To run a mutation, you first call `useShiftSettingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftSettingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftSettingInsertMutation, { data, loading, error }] = useShiftSettingInsertMutation({
 *   variables: {
 *      ShiftInput: // value for 'ShiftInput'
 *      BreakTimeInput: // value for 'BreakTimeInput'
 *      ShiftDayInput: // value for 'ShiftDayInput'
 *   },
 * });
 */
export function useShiftSettingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShiftSettingInsertMutation, ShiftSettingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ShiftSettingInsertMutation, ShiftSettingInsertMutationVariables>(ShiftSettingInsertDocument, baseOptions);
      }
export type ShiftSettingInsertMutationHookResult = ReturnType<typeof useShiftSettingInsertMutation>;
export type ShiftSettingInsertMutationResult = ApolloReactCommon.MutationResult<ShiftSettingInsertMutation>;
export type ShiftSettingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ShiftSettingInsertMutation, ShiftSettingInsertMutationVariables>;
export const GetDailyAllowanceDocument = gql`
    query getDailyAllowance($SubscriptionAccountID: String!) {
  getDailyAllowance(SubscriptionAccountID: $SubscriptionAccountID, Status: Active) {
    Status
    CreatedDT
    ModifiedDT
    CreatedBy
    DailyAllowanceID
    Name
    Condition
    Value
    RoundingPolicyID
    RoundingPolicy {
      AllowanceDeductionID
      Name
      RoundingCriteria
    }
    PayItemID
    PayItem {
      PayItemID
      PayName
    }
  }
  DailyAllowanceInUseListing
}
    `;

/**
 * __useGetDailyAllowanceQuery__
 *
 * To run a query within a React component, call `useGetDailyAllowanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyAllowanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyAllowanceQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useGetDailyAllowanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDailyAllowanceQuery, GetDailyAllowanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDailyAllowanceQuery, GetDailyAllowanceQueryVariables>(GetDailyAllowanceDocument, baseOptions);
      }
export function useGetDailyAllowanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDailyAllowanceQuery, GetDailyAllowanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDailyAllowanceQuery, GetDailyAllowanceQueryVariables>(GetDailyAllowanceDocument, baseOptions);
        }
export type GetDailyAllowanceQueryHookResult = ReturnType<typeof useGetDailyAllowanceQuery>;
export type GetDailyAllowanceLazyQueryHookResult = ReturnType<typeof useGetDailyAllowanceLazyQuery>;
export type GetDailyAllowanceQueryResult = ApolloReactCommon.QueryResult<GetDailyAllowanceQuery, GetDailyAllowanceQueryVariables>;
export const CreateDailyAllowanceDocument = gql`
    mutation createDailyAllowance($input: DailyAllowanceInput!) {
  createDailyAllowance(input: $input)
}
    `;
export type CreateDailyAllowanceMutationFn = ApolloReactCommon.MutationFunction<CreateDailyAllowanceMutation, CreateDailyAllowanceMutationVariables>;

/**
 * __useCreateDailyAllowanceMutation__
 *
 * To run a mutation, you first call `useCreateDailyAllowanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDailyAllowanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDailyAllowanceMutation, { data, loading, error }] = useCreateDailyAllowanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDailyAllowanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDailyAllowanceMutation, CreateDailyAllowanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDailyAllowanceMutation, CreateDailyAllowanceMutationVariables>(CreateDailyAllowanceDocument, baseOptions);
      }
export type CreateDailyAllowanceMutationHookResult = ReturnType<typeof useCreateDailyAllowanceMutation>;
export type CreateDailyAllowanceMutationResult = ApolloReactCommon.MutationResult<CreateDailyAllowanceMutation>;
export type CreateDailyAllowanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDailyAllowanceMutation, CreateDailyAllowanceMutationVariables>;
export const ShiftSetupDeleteDocument = gql`
    mutation ShiftSetupDelete($ShiftID: String!) {
  ShiftSetupDelete(ShiftID: $ShiftID)
}
    `;
export type ShiftSetupDeleteMutationFn = ApolloReactCommon.MutationFunction<ShiftSetupDeleteMutation, ShiftSetupDeleteMutationVariables>;

/**
 * __useShiftSetupDeleteMutation__
 *
 * To run a mutation, you first call `useShiftSetupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftSetupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftSetupDeleteMutation, { data, loading, error }] = useShiftSetupDeleteMutation({
 *   variables: {
 *      ShiftID: // value for 'ShiftID'
 *   },
 * });
 */
export function useShiftSetupDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShiftSetupDeleteMutation, ShiftSetupDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ShiftSetupDeleteMutation, ShiftSetupDeleteMutationVariables>(ShiftSetupDeleteDocument, baseOptions);
      }
export type ShiftSetupDeleteMutationHookResult = ReturnType<typeof useShiftSetupDeleteMutation>;
export type ShiftSetupDeleteMutationResult = ApolloReactCommon.MutationResult<ShiftSetupDeleteMutation>;
export type ShiftSetupDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ShiftSetupDeleteMutation, ShiftSetupDeleteMutationVariables>;
export const GetAllowanceDeductionDocument = gql`
    query getAllowanceDeduction($orderByAsc: String) {
  getAllowanceDeduction(orderByAsc: $orderByAsc) {
    AllowanceDeductionID
    Name
    Status
    ModifiedDT
    CreatedDT
    Status
    RoundingCriteria
  }
}
    `;

/**
 * __useGetAllowanceDeductionQuery__
 *
 * To run a query within a React component, call `useGetAllowanceDeductionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllowanceDeductionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllowanceDeductionQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetAllowanceDeductionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllowanceDeductionQuery, GetAllowanceDeductionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllowanceDeductionQuery, GetAllowanceDeductionQueryVariables>(GetAllowanceDeductionDocument, baseOptions);
      }
export function useGetAllowanceDeductionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllowanceDeductionQuery, GetAllowanceDeductionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllowanceDeductionQuery, GetAllowanceDeductionQueryVariables>(GetAllowanceDeductionDocument, baseOptions);
        }
export type GetAllowanceDeductionQueryHookResult = ReturnType<typeof useGetAllowanceDeductionQuery>;
export type GetAllowanceDeductionLazyQueryHookResult = ReturnType<typeof useGetAllowanceDeductionLazyQuery>;
export type GetAllowanceDeductionQueryResult = ApolloReactCommon.QueryResult<GetAllowanceDeductionQuery, GetAllowanceDeductionQueryVariables>;
export const GetShiftListDocument = gql`
    query getShiftList {
  getShiftList {
    DutyRosterDisplayColour
    Name
    ModifiedDT
    IsPreOvertimeMin
    PreOvertimeMin
    IsPostOvertimeMin
    IsDefault
    PostOvertimeMin
    IsFixedOvertimeMin
    FixedOvertimeTotal
    ShiftID
    Status
    shiftDayList {
      ShiftDayID
      DayType
      ShiftID
      ClockPairing
      CrossDay
      FlexiHour
      TotalWorkingHour
      ClockInTime
      ClockOutTime
      ShiftStartTime
      ShiftEndTime
      FirstHalfEndTime
      SecondHalfStartTime
      FlexiBreak
      ShiftBreakInterval
      CoreHourStartTime
      CoreHourEndTime
    }
    breakTimeList {
      BreakTimeID
      Type
      Start
      End
    }
  }
}
    `;

/**
 * __useGetShiftListQuery__
 *
 * To run a query within a React component, call `useGetShiftListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShiftListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShiftListQuery, GetShiftListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShiftListQuery, GetShiftListQueryVariables>(GetShiftListDocument, baseOptions);
      }
export function useGetShiftListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShiftListQuery, GetShiftListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShiftListQuery, GetShiftListQueryVariables>(GetShiftListDocument, baseOptions);
        }
export type GetShiftListQueryHookResult = ReturnType<typeof useGetShiftListQuery>;
export type GetShiftListLazyQueryHookResult = ReturnType<typeof useGetShiftListLazyQuery>;
export type GetShiftListQueryResult = ApolloReactCommon.QueryResult<GetShiftListQuery, GetShiftListQueryVariables>;
export const GetShiftByIdDocument = gql`
    query getShiftByID($ShiftID: String!) {
  getShift(ShiftID: $ShiftID) {
    ShiftID
    Name
    DutyRosterDisplayColour
    CreatedBy
    IsPreOvertimeMin
    PreOvertimeMin
    IsPostOvertimeMin
    PostOvertimeMin
    IsFixedOvertimeMin
    FixedOvertimeTotal
    Status
    NewStructure
    breakTimeList {
      BreakTimeID
      Type
      Start
      End
    }
    shiftDayList {
      DayType
      ShiftDayID
      ClockPairing
      CrossDay
      FlexiHour
      TotalWorkingHour
      ClockInTime
      ClockOutTime
      ShiftStartTime
      ShiftEndTime
      FirstHalfEndTime
      SecondHalfStartTime
      FlexiBreak
      ShiftBreakInterval
      CoreHourStartTime
      CoreHourEndTime
      IsPreOvertime
      PreOvertimeMin
      IsPostOvertime
      PostOvertimeMin
      IsFixedOvertime
      FixedOvertimeStart
      FixedOvertimeEnd
      FixedOvertimeTotal
      breakTimeList {
        BreakTimeID
        Type
        Start
        End
      }
    }
  }
}
    `;

/**
 * __useGetShiftByIdQuery__
 *
 * To run a query within a React component, call `useGetShiftByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftByIdQuery({
 *   variables: {
 *      ShiftID: // value for 'ShiftID'
 *   },
 * });
 */
export function useGetShiftByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShiftByIdQuery, GetShiftByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShiftByIdQuery, GetShiftByIdQueryVariables>(GetShiftByIdDocument, baseOptions);
      }
export function useGetShiftByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShiftByIdQuery, GetShiftByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShiftByIdQuery, GetShiftByIdQueryVariables>(GetShiftByIdDocument, baseOptions);
        }
export type GetShiftByIdQueryHookResult = ReturnType<typeof useGetShiftByIdQuery>;
export type GetShiftByIdLazyQueryHookResult = ReturnType<typeof useGetShiftByIdLazyQuery>;
export type GetShiftByIdQueryResult = ApolloReactCommon.QueryResult<GetShiftByIdQuery, GetShiftByIdQueryVariables>;
export const ShiftSetupUpdateDocument = gql`
    mutation ShiftSetupUpdate($ShiftID: String!, $ShiftInput: ShiftInput!, $BreakTimeInput: [BreakTimeInput!]!, $ShiftDayInput: [ShiftDayInput!]!) {
  ShiftSetupUpdate(ShiftID: $ShiftID, ShiftInput: $ShiftInput, BreakTimeInput: $BreakTimeInput, ShiftDayInput: $ShiftDayInput)
}
    `;
export type ShiftSetupUpdateMutationFn = ApolloReactCommon.MutationFunction<ShiftSetupUpdateMutation, ShiftSetupUpdateMutationVariables>;

/**
 * __useShiftSetupUpdateMutation__
 *
 * To run a mutation, you first call `useShiftSetupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShiftSetupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shiftSetupUpdateMutation, { data, loading, error }] = useShiftSetupUpdateMutation({
 *   variables: {
 *      ShiftID: // value for 'ShiftID'
 *      ShiftInput: // value for 'ShiftInput'
 *      BreakTimeInput: // value for 'BreakTimeInput'
 *      ShiftDayInput: // value for 'ShiftDayInput'
 *   },
 * });
 */
export function useShiftSetupUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShiftSetupUpdateMutation, ShiftSetupUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ShiftSetupUpdateMutation, ShiftSetupUpdateMutationVariables>(ShiftSetupUpdateDocument, baseOptions);
      }
export type ShiftSetupUpdateMutationHookResult = ReturnType<typeof useShiftSetupUpdateMutation>;
export type ShiftSetupUpdateMutationResult = ApolloReactCommon.MutationResult<ShiftSetupUpdateMutation>;
export type ShiftSetupUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ShiftSetupUpdateMutation, ShiftSetupUpdateMutationVariables>;
export const DeleteShiftPatternDocument = gql`
    mutation deleteShiftPattern($ShiftPatternID: String!) {
  deleteShiftPattern(ShiftPatternID: $ShiftPatternID)
}
    `;
export type DeleteShiftPatternMutationFn = ApolloReactCommon.MutationFunction<DeleteShiftPatternMutation, DeleteShiftPatternMutationVariables>;

/**
 * __useDeleteShiftPatternMutation__
 *
 * To run a mutation, you first call `useDeleteShiftPatternMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShiftPatternMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShiftPatternMutation, { data, loading, error }] = useDeleteShiftPatternMutation({
 *   variables: {
 *      ShiftPatternID: // value for 'ShiftPatternID'
 *   },
 * });
 */
export function useDeleteShiftPatternMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteShiftPatternMutation, DeleteShiftPatternMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteShiftPatternMutation, DeleteShiftPatternMutationVariables>(DeleteShiftPatternDocument, baseOptions);
      }
export type DeleteShiftPatternMutationHookResult = ReturnType<typeof useDeleteShiftPatternMutation>;
export type DeleteShiftPatternMutationResult = ApolloReactCommon.MutationResult<DeleteShiftPatternMutation>;
export type DeleteShiftPatternMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteShiftPatternMutation, DeleteShiftPatternMutationVariables>;
export const ShiftPatternListingDocument = gql`
    query ShiftPatternListing($ShiftPatternID: String, $StartMonth: DateTime, $EndMonth: DateTime) {
  ShiftPatternListing(ShiftPatternID: $ShiftPatternID, StartMonth: $StartMonth, EndMonth: $EndMonth) {
    Name
    ShiftPatternID
    Start
    End
    Active
    ModifiedDT
    CreatedDT
    Status
    Details {
      ShiftPatternID
      ShiftPatternDetailID
      Days
      ShiftTypeID
      shiftDay {
        DayType
      }
      ShiftDayID
    }
  }
}
    `;

/**
 * __useShiftPatternListingQuery__
 *
 * To run a query within a React component, call `useShiftPatternListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftPatternListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftPatternListingQuery({
 *   variables: {
 *      ShiftPatternID: // value for 'ShiftPatternID'
 *      StartMonth: // value for 'StartMonth'
 *      EndMonth: // value for 'EndMonth'
 *   },
 * });
 */
export function useShiftPatternListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShiftPatternListingQuery, ShiftPatternListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ShiftPatternListingQuery, ShiftPatternListingQueryVariables>(ShiftPatternListingDocument, baseOptions);
      }
export function useShiftPatternListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShiftPatternListingQuery, ShiftPatternListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShiftPatternListingQuery, ShiftPatternListingQueryVariables>(ShiftPatternListingDocument, baseOptions);
        }
export type ShiftPatternListingQueryHookResult = ReturnType<typeof useShiftPatternListingQuery>;
export type ShiftPatternListingLazyQueryHookResult = ReturnType<typeof useShiftPatternListingLazyQuery>;
export type ShiftPatternListingQueryResult = ApolloReactCommon.QueryResult<ShiftPatternListingQuery, ShiftPatternListingQueryVariables>;
export const ValidShiftPatternListingDocument = gql`
    query ValidShiftPatternListing($EffectiveDate: DateTime, $ShiftGroupID: String) {
  ValidShiftPatternListing(EffectiveDate: $EffectiveDate, ShiftGroupID: $ShiftGroupID) {
    Name
    ShiftPatternID
    Start
    End
    Active
    ModifiedDT
    CreatedDT
    Status
    Details {
      ShiftPatternID
      ShiftPatternDetailID
      Days
      ShiftTypeID
      shiftDay {
        DayType
      }
      ShiftDayID
    }
  }
}
    `;

/**
 * __useValidShiftPatternListingQuery__
 *
 * To run a query within a React component, call `useValidShiftPatternListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidShiftPatternListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidShiftPatternListingQuery({
 *   variables: {
 *      EffectiveDate: // value for 'EffectiveDate'
 *      ShiftGroupID: // value for 'ShiftGroupID'
 *   },
 * });
 */
export function useValidShiftPatternListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidShiftPatternListingQuery, ValidShiftPatternListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidShiftPatternListingQuery, ValidShiftPatternListingQueryVariables>(ValidShiftPatternListingDocument, baseOptions);
      }
export function useValidShiftPatternListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidShiftPatternListingQuery, ValidShiftPatternListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidShiftPatternListingQuery, ValidShiftPatternListingQueryVariables>(ValidShiftPatternListingDocument, baseOptions);
        }
export type ValidShiftPatternListingQueryHookResult = ReturnType<typeof useValidShiftPatternListingQuery>;
export type ValidShiftPatternListingLazyQueryHookResult = ReturnType<typeof useValidShiftPatternListingLazyQuery>;
export type ValidShiftPatternListingQueryResult = ApolloReactCommon.QueryResult<ValidShiftPatternListingQuery, ValidShiftPatternListingQueryVariables>;
export const CreateShiftPatternDocument = gql`
    mutation createShiftPattern($ShiftPatternInput: ShiftPatternInput!, $ShiftPatternDetailInput: [ShiftPatternDetailInput!]!) {
  createShiftPattern(ShiftPatternInput: $ShiftPatternInput, ShiftPatternDetailInput: $ShiftPatternDetailInput)
}
    `;
export type CreateShiftPatternMutationFn = ApolloReactCommon.MutationFunction<CreateShiftPatternMutation, CreateShiftPatternMutationVariables>;

/**
 * __useCreateShiftPatternMutation__
 *
 * To run a mutation, you first call `useCreateShiftPatternMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShiftPatternMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShiftPatternMutation, { data, loading, error }] = useCreateShiftPatternMutation({
 *   variables: {
 *      ShiftPatternInput: // value for 'ShiftPatternInput'
 *      ShiftPatternDetailInput: // value for 'ShiftPatternDetailInput'
 *   },
 * });
 */
export function useCreateShiftPatternMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateShiftPatternMutation, CreateShiftPatternMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateShiftPatternMutation, CreateShiftPatternMutationVariables>(CreateShiftPatternDocument, baseOptions);
      }
export type CreateShiftPatternMutationHookResult = ReturnType<typeof useCreateShiftPatternMutation>;
export type CreateShiftPatternMutationResult = ApolloReactCommon.MutationResult<CreateShiftPatternMutation>;
export type CreateShiftPatternMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateShiftPatternMutation, CreateShiftPatternMutationVariables>;
export const ShiftSettingActiveListingDocument = gql`
    query shiftSettingActiveListing {
  getShift {
    DutyRosterDisplayColour
    Name
    ModifiedDT
    IsPreOvertimeMin
    PreOvertimeMin
    IsPostOvertimeMin
    PostOvertimeMin
    IsFixedOvertimeMin
    FixedOvertimeTotal
    ShiftID
    Status
    shiftDayList {
      ShiftDayID
      DayType
      ShiftID
      ClockPairing
      CrossDay
      FlexiHour
      TotalWorkingHour
      ClockInTime
      ClockOutTime
      ShiftStartTime
      ShiftEndTime
      FirstHalfEndTime
      SecondHalfStartTime
      FlexiBreak
      ShiftBreakInterval
      CoreHourStartTime
      CoreHourEndTime
    }
    breakTimeList {
      BreakTimeID
      Type
      Start
      End
    }
  }
}
    `;

/**
 * __useShiftSettingActiveListingQuery__
 *
 * To run a query within a React component, call `useShiftSettingActiveListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useShiftSettingActiveListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShiftSettingActiveListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useShiftSettingActiveListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShiftSettingActiveListingQuery, ShiftSettingActiveListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ShiftSettingActiveListingQuery, ShiftSettingActiveListingQueryVariables>(ShiftSettingActiveListingDocument, baseOptions);
      }
export function useShiftSettingActiveListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShiftSettingActiveListingQuery, ShiftSettingActiveListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShiftSettingActiveListingQuery, ShiftSettingActiveListingQueryVariables>(ShiftSettingActiveListingDocument, baseOptions);
        }
export type ShiftSettingActiveListingQueryHookResult = ReturnType<typeof useShiftSettingActiveListingQuery>;
export type ShiftSettingActiveListingLazyQueryHookResult = ReturnType<typeof useShiftSettingActiveListingLazyQuery>;
export type ShiftSettingActiveListingQueryResult = ApolloReactCommon.QueryResult<ShiftSettingActiveListingQuery, ShiftSettingActiveListingQueryVariables>;
export const UpdateShiftPatternDocument = gql`
    mutation updateShiftPattern($ShiftPatternID: String!, $ShiftPatternInput: ShiftPatternInput!, $ShiftPatternDetailInput: [ShiftPatternDetailInput!]!) {
  updateShiftPattern(ShiftPatternID: $ShiftPatternID, ShiftPatternInput: $ShiftPatternInput, ShiftPatternDetailInput: $ShiftPatternDetailInput)
}
    `;
export type UpdateShiftPatternMutationFn = ApolloReactCommon.MutationFunction<UpdateShiftPatternMutation, UpdateShiftPatternMutationVariables>;

/**
 * __useUpdateShiftPatternMutation__
 *
 * To run a mutation, you first call `useUpdateShiftPatternMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftPatternMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftPatternMutation, { data, loading, error }] = useUpdateShiftPatternMutation({
 *   variables: {
 *      ShiftPatternID: // value for 'ShiftPatternID'
 *      ShiftPatternInput: // value for 'ShiftPatternInput'
 *      ShiftPatternDetailInput: // value for 'ShiftPatternDetailInput'
 *   },
 * });
 */
export function useUpdateShiftPatternMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShiftPatternMutation, UpdateShiftPatternMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateShiftPatternMutation, UpdateShiftPatternMutationVariables>(UpdateShiftPatternDocument, baseOptions);
      }
export type UpdateShiftPatternMutationHookResult = ReturnType<typeof useUpdateShiftPatternMutation>;
export type UpdateShiftPatternMutationResult = ApolloReactCommon.MutationResult<UpdateShiftPatternMutation>;
export type UpdateShiftPatternMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShiftPatternMutation, UpdateShiftPatternMutationVariables>;
export const UpdateShiftGroupStatusDocument = gql`
    mutation updateShiftGroupStatus($ShiftGroupID: String!, $Status: Status!) {
  updateShiftGroupStatus(ShiftGroupID: $ShiftGroupID, Status: $Status)
}
    `;
export type UpdateShiftGroupStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateShiftGroupStatusMutation, UpdateShiftGroupStatusMutationVariables>;

/**
 * __useUpdateShiftGroupStatusMutation__
 *
 * To run a mutation, you first call `useUpdateShiftGroupStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftGroupStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftGroupStatusMutation, { data, loading, error }] = useUpdateShiftGroupStatusMutation({
 *   variables: {
 *      ShiftGroupID: // value for 'ShiftGroupID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useUpdateShiftGroupStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShiftGroupStatusMutation, UpdateShiftGroupStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateShiftGroupStatusMutation, UpdateShiftGroupStatusMutationVariables>(UpdateShiftGroupStatusDocument, baseOptions);
      }
export type UpdateShiftGroupStatusMutationHookResult = ReturnType<typeof useUpdateShiftGroupStatusMutation>;
export type UpdateShiftGroupStatusMutationResult = ApolloReactCommon.MutationResult<UpdateShiftGroupStatusMutation>;
export type UpdateShiftGroupStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShiftGroupStatusMutation, UpdateShiftGroupStatusMutationVariables>;
export const GetShiftGroupListingDocument = gql`
    query getShiftGroupListing($EndMonth: DateTime, $StartMonth: DateTime, $ShiftGroupID: String) {
  getShiftGroup(EndMonth: $EndMonth, StartMonth: $StartMonth, ShiftGroupID: $ShiftGroupID) {
    Start
    End
    Name
    Status
    Description
    DefaultShiftGroup
    ClockingOption
    MultipleInOutOption
    ShiftGroupID
    ModifiedDT
    ByQR
    QRSelfie
    ByLocation
    LocationSelfie
    ByBluetooth
    BluetoothSelfie
    ShiftGroupShift {
      ShiftGroupShiftID
      ShiftID
    }
    ShiftGroupPattern {
      ShiftGroupPatternID
      CompanyID
      ShiftGroupID
      ShiftPatternID
      EffectiveDate
    }
  }
}
    `;

/**
 * __useGetShiftGroupListingQuery__
 *
 * To run a query within a React component, call `useGetShiftGroupListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftGroupListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftGroupListingQuery({
 *   variables: {
 *      EndMonth: // value for 'EndMonth'
 *      StartMonth: // value for 'StartMonth'
 *      ShiftGroupID: // value for 'ShiftGroupID'
 *   },
 * });
 */
export function useGetShiftGroupListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShiftGroupListingQuery, GetShiftGroupListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShiftGroupListingQuery, GetShiftGroupListingQueryVariables>(GetShiftGroupListingDocument, baseOptions);
      }
export function useGetShiftGroupListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShiftGroupListingQuery, GetShiftGroupListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShiftGroupListingQuery, GetShiftGroupListingQueryVariables>(GetShiftGroupListingDocument, baseOptions);
        }
export type GetShiftGroupListingQueryHookResult = ReturnType<typeof useGetShiftGroupListingQuery>;
export type GetShiftGroupListingLazyQueryHookResult = ReturnType<typeof useGetShiftGroupListingLazyQuery>;
export type GetShiftGroupListingQueryResult = ApolloReactCommon.QueryResult<GetShiftGroupListingQuery, GetShiftGroupListingQueryVariables>;
export const GetAllShiftDocument = gql`
    query getAllShift {
  getShift {
    Name
    ShiftID
    Status
  }
}
    `;

/**
 * __useGetAllShiftQuery__
 *
 * To run a query within a React component, call `useGetAllShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllShiftQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllShiftQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllShiftQuery, GetAllShiftQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllShiftQuery, GetAllShiftQueryVariables>(GetAllShiftDocument, baseOptions);
      }
export function useGetAllShiftLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllShiftQuery, GetAllShiftQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllShiftQuery, GetAllShiftQueryVariables>(GetAllShiftDocument, baseOptions);
        }
export type GetAllShiftQueryHookResult = ReturnType<typeof useGetAllShiftQuery>;
export type GetAllShiftLazyQueryHookResult = ReturnType<typeof useGetAllShiftLazyQuery>;
export type GetAllShiftQueryResult = ApolloReactCommon.QueryResult<GetAllShiftQuery, GetAllShiftQueryVariables>;
export const CreateShiftGroupDocument = gql`
    mutation createShiftGroup($ShiftGroupInput: ShiftGroupInput!, $ShiftGroupShiftInput: [ShiftGroupShiftInput!]) {
  createShiftGroup(ShiftGroupInput: $ShiftGroupInput, ShiftGroupShiftInput: $ShiftGroupShiftInput)
}
    `;
export type CreateShiftGroupMutationFn = ApolloReactCommon.MutationFunction<CreateShiftGroupMutation, CreateShiftGroupMutationVariables>;

/**
 * __useCreateShiftGroupMutation__
 *
 * To run a mutation, you first call `useCreateShiftGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShiftGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShiftGroupMutation, { data, loading, error }] = useCreateShiftGroupMutation({
 *   variables: {
 *      ShiftGroupInput: // value for 'ShiftGroupInput'
 *      ShiftGroupShiftInput: // value for 'ShiftGroupShiftInput'
 *   },
 * });
 */
export function useCreateShiftGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateShiftGroupMutation, CreateShiftGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateShiftGroupMutation, CreateShiftGroupMutationVariables>(CreateShiftGroupDocument, baseOptions);
      }
export type CreateShiftGroupMutationHookResult = ReturnType<typeof useCreateShiftGroupMutation>;
export type CreateShiftGroupMutationResult = ApolloReactCommon.MutationResult<CreateShiftGroupMutation>;
export type CreateShiftGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateShiftGroupMutation, CreateShiftGroupMutationVariables>;
export const UpdateShiftGroupDocument = gql`
    mutation updateShiftGroup($ShiftGroupID: String!, $ShiftGroupInput: ShiftGroupInput!, $ShiftGroupShiftInput: [ShiftGroupShiftInput!]) {
  updateShiftGroup(ShiftGroupID: $ShiftGroupID, ShiftGroupInput: $ShiftGroupInput, ShiftGroupShiftInput: $ShiftGroupShiftInput)
}
    `;
export type UpdateShiftGroupMutationFn = ApolloReactCommon.MutationFunction<UpdateShiftGroupMutation, UpdateShiftGroupMutationVariables>;

/**
 * __useUpdateShiftGroupMutation__
 *
 * To run a mutation, you first call `useUpdateShiftGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShiftGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShiftGroupMutation, { data, loading, error }] = useUpdateShiftGroupMutation({
 *   variables: {
 *      ShiftGroupID: // value for 'ShiftGroupID'
 *      ShiftGroupInput: // value for 'ShiftGroupInput'
 *      ShiftGroupShiftInput: // value for 'ShiftGroupShiftInput'
 *   },
 * });
 */
export function useUpdateShiftGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateShiftGroupMutation, UpdateShiftGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateShiftGroupMutation, UpdateShiftGroupMutationVariables>(UpdateShiftGroupDocument, baseOptions);
      }
export type UpdateShiftGroupMutationHookResult = ReturnType<typeof useUpdateShiftGroupMutation>;
export type UpdateShiftGroupMutationResult = ApolloReactCommon.MutationResult<UpdateShiftGroupMutation>;
export type UpdateShiftGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateShiftGroupMutation, UpdateShiftGroupMutationVariables>;
export const GetTotalOverTimeDocument = gql`
    query GetTotalOverTime($CompanyID: String!, $CutOffDay: Float!, $EmployeeID: String) {
  GetTotalOverTime(CompanyID: $CompanyID, CutOffDay: $CutOffDay, EmployeeID: $EmployeeID)
}
    `;

/**
 * __useGetTotalOverTimeQuery__
 *
 * To run a query within a React component, call `useGetTotalOverTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalOverTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalOverTimeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CutOffDay: // value for 'CutOffDay'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetTotalOverTimeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTotalOverTimeQuery, GetTotalOverTimeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTotalOverTimeQuery, GetTotalOverTimeQueryVariables>(GetTotalOverTimeDocument, baseOptions);
      }
export function useGetTotalOverTimeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTotalOverTimeQuery, GetTotalOverTimeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTotalOverTimeQuery, GetTotalOverTimeQueryVariables>(GetTotalOverTimeDocument, baseOptions);
        }
export type GetTotalOverTimeQueryHookResult = ReturnType<typeof useGetTotalOverTimeQuery>;
export type GetTotalOverTimeLazyQueryHookResult = ReturnType<typeof useGetTotalOverTimeLazyQuery>;
export type GetTotalOverTimeQueryResult = ApolloReactCommon.QueryResult<GetTotalOverTimeQuery, GetTotalOverTimeQueryVariables>;
export const GetOvertimePolicyDocument = gql`
    query getOvertimePolicy($SubscriptionAccountID: String!) {
  getOvertimePolicy {
    OvertimePolicyID
    Title
    CutOffDate
    TimeOff
    HourlyLeave
    HalfDayLeave
    EnableAutoSubmission
    OvertimeRounding
    AllowBackdatedOvertime
    BackdatedOTMethod
    BackdatedOTDay
    EnableMaxOvertimeLimit
    MaxOTHour
    OvertimeClaimTable
    ExceedOvertimeClaimTable
    AllowExceedOvertimeLimit
    OTLimitDayType
    OTRoundingID
    otRounding {
      OTRoundingID
      Name
    }
    LateIn
    EarlyOut
    ModifiedDT
    CreatedDT
    ShiftBreakLateIn
    ShiftBreakEarlyOut
    ExceedOvertimeClaimTableObj {
      Title
      OTClaimTableType
      OvertimeClaimTableID
      OTClaimData
      ReplacementLeaveData
    }
    OvertimeClaimTableObj {
      Title
      OTClaimTableType
      OvertimeClaimTableID
      OTClaimData
      ReplacementLeaveData
    }
  }
  getOTRounding {
    OTRoundingID
    Name
  }
  PayItemListing(SubscriptionAccountID: $SubscriptionAccountID) {
    PayName
    PayItemID
    PayItemType
  }
  OvertimePolicyInUseListing
}
    `;

/**
 * __useGetOvertimePolicyQuery__
 *
 * To run a query within a React component, call `useGetOvertimePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOvertimePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOvertimePolicyQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useGetOvertimePolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOvertimePolicyQuery, GetOvertimePolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOvertimePolicyQuery, GetOvertimePolicyQueryVariables>(GetOvertimePolicyDocument, baseOptions);
      }
export function useGetOvertimePolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOvertimePolicyQuery, GetOvertimePolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOvertimePolicyQuery, GetOvertimePolicyQueryVariables>(GetOvertimePolicyDocument, baseOptions);
        }
export type GetOvertimePolicyQueryHookResult = ReturnType<typeof useGetOvertimePolicyQuery>;
export type GetOvertimePolicyLazyQueryHookResult = ReturnType<typeof useGetOvertimePolicyLazyQuery>;
export type GetOvertimePolicyQueryResult = ApolloReactCommon.QueryResult<GetOvertimePolicyQuery, GetOvertimePolicyQueryVariables>;
export const OvertimePolicyDeleteDocument = gql`
    mutation OvertimePolicyDelete($OvertimePolicyID: String!) {
  OvertimePolicyDelete(OvertimePolicyID: $OvertimePolicyID)
}
    `;
export type OvertimePolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<OvertimePolicyDeleteMutation, OvertimePolicyDeleteMutationVariables>;

/**
 * __useOvertimePolicyDeleteMutation__
 *
 * To run a mutation, you first call `useOvertimePolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimePolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimePolicyDeleteMutation, { data, loading, error }] = useOvertimePolicyDeleteMutation({
 *   variables: {
 *      OvertimePolicyID: // value for 'OvertimePolicyID'
 *   },
 * });
 */
export function useOvertimePolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimePolicyDeleteMutation, OvertimePolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimePolicyDeleteMutation, OvertimePolicyDeleteMutationVariables>(OvertimePolicyDeleteDocument, baseOptions);
      }
export type OvertimePolicyDeleteMutationHookResult = ReturnType<typeof useOvertimePolicyDeleteMutation>;
export type OvertimePolicyDeleteMutationResult = ApolloReactCommon.MutationResult<OvertimePolicyDeleteMutation>;
export type OvertimePolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimePolicyDeleteMutation, OvertimePolicyDeleteMutationVariables>;
export const OvertimePolicyInsertDocument = gql`
    mutation OvertimePolicyInsert($input: OvertimePolicyInput!) {
  OvertimePolicyInsert(input: $input)
}
    `;
export type OvertimePolicyInsertMutationFn = ApolloReactCommon.MutationFunction<OvertimePolicyInsertMutation, OvertimePolicyInsertMutationVariables>;

/**
 * __useOvertimePolicyInsertMutation__
 *
 * To run a mutation, you first call `useOvertimePolicyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimePolicyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimePolicyInsertMutation, { data, loading, error }] = useOvertimePolicyInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOvertimePolicyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimePolicyInsertMutation, OvertimePolicyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimePolicyInsertMutation, OvertimePolicyInsertMutationVariables>(OvertimePolicyInsertDocument, baseOptions);
      }
export type OvertimePolicyInsertMutationHookResult = ReturnType<typeof useOvertimePolicyInsertMutation>;
export type OvertimePolicyInsertMutationResult = ApolloReactCommon.MutationResult<OvertimePolicyInsertMutation>;
export type OvertimePolicyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimePolicyInsertMutation, OvertimePolicyInsertMutationVariables>;
export const OvertimePolicyUpdateDocument = gql`
    mutation OvertimePolicyUpdate($input: OvertimePolicyInput!) {
  OvertimePolicyUpdate(input: $input)
}
    `;
export type OvertimePolicyUpdateMutationFn = ApolloReactCommon.MutationFunction<OvertimePolicyUpdateMutation, OvertimePolicyUpdateMutationVariables>;

/**
 * __useOvertimePolicyUpdateMutation__
 *
 * To run a mutation, you first call `useOvertimePolicyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimePolicyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimePolicyUpdateMutation, { data, loading, error }] = useOvertimePolicyUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOvertimePolicyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimePolicyUpdateMutation, OvertimePolicyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimePolicyUpdateMutation, OvertimePolicyUpdateMutationVariables>(OvertimePolicyUpdateDocument, baseOptions);
      }
export type OvertimePolicyUpdateMutationHookResult = ReturnType<typeof useOvertimePolicyUpdateMutation>;
export type OvertimePolicyUpdateMutationResult = ApolloReactCommon.MutationResult<OvertimePolicyUpdateMutation>;
export type OvertimePolicyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimePolicyUpdateMutation, OvertimePolicyUpdateMutationVariables>;
export const GetOvertimeTableListingDocument = gql`
    query getOvertimeTableListing($SubscriptionAccountID: String!, $Status: Status!) {
  getOvertimeClaimTable(SubscriptionAccountID: $SubscriptionAccountID, Status: $Status) {
    Title
    OTClaimTableType
    OvertimeClaimTableID
    OTClaimData
    ReplacementLeaveData
  }
}
    `;

/**
 * __useGetOvertimeTableListingQuery__
 *
 * To run a query within a React component, call `useGetOvertimeTableListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOvertimeTableListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOvertimeTableListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useGetOvertimeTableListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOvertimeTableListingQuery, GetOvertimeTableListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOvertimeTableListingQuery, GetOvertimeTableListingQueryVariables>(GetOvertimeTableListingDocument, baseOptions);
      }
export function useGetOvertimeTableListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOvertimeTableListingQuery, GetOvertimeTableListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOvertimeTableListingQuery, GetOvertimeTableListingQueryVariables>(GetOvertimeTableListingDocument, baseOptions);
        }
export type GetOvertimeTableListingQueryHookResult = ReturnType<typeof useGetOvertimeTableListingQuery>;
export type GetOvertimeTableListingLazyQueryHookResult = ReturnType<typeof useGetOvertimeTableListingLazyQuery>;
export type GetOvertimeTableListingQueryResult = ApolloReactCommon.QueryResult<GetOvertimeTableListingQuery, GetOvertimeTableListingQueryVariables>;
export const UpdateOvertimeClaimTableStatusDocument = gql`
    mutation updateOvertimeClaimTableStatus($OvertimeClaimTableID: String!, $Status: Status!) {
  updateOvertimeClaimTableStatus(OvertimeClaimTableID: $OvertimeClaimTableID, Status: $Status)
}
    `;
export type UpdateOvertimeClaimTableStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateOvertimeClaimTableStatusMutation, UpdateOvertimeClaimTableStatusMutationVariables>;

/**
 * __useUpdateOvertimeClaimTableStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOvertimeClaimTableStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOvertimeClaimTableStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOvertimeClaimTableStatusMutation, { data, loading, error }] = useUpdateOvertimeClaimTableStatusMutation({
 *   variables: {
 *      OvertimeClaimTableID: // value for 'OvertimeClaimTableID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useUpdateOvertimeClaimTableStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOvertimeClaimTableStatusMutation, UpdateOvertimeClaimTableStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOvertimeClaimTableStatusMutation, UpdateOvertimeClaimTableStatusMutationVariables>(UpdateOvertimeClaimTableStatusDocument, baseOptions);
      }
export type UpdateOvertimeClaimTableStatusMutationHookResult = ReturnType<typeof useUpdateOvertimeClaimTableStatusMutation>;
export type UpdateOvertimeClaimTableStatusMutationResult = ApolloReactCommon.MutationResult<UpdateOvertimeClaimTableStatusMutation>;
export type UpdateOvertimeClaimTableStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOvertimeClaimTableStatusMutation, UpdateOvertimeClaimTableStatusMutationVariables>;
export const CreateOvertimeClaimTableDocument = gql`
    mutation createOvertimeClaimTable($Input: OvertimeClaimTableInput!) {
  createOvertimeClaimTable(input: $Input) {
    OvertimeClaimTableID
  }
}
    `;
export type CreateOvertimeClaimTableMutationFn = ApolloReactCommon.MutationFunction<CreateOvertimeClaimTableMutation, CreateOvertimeClaimTableMutationVariables>;

/**
 * __useCreateOvertimeClaimTableMutation__
 *
 * To run a mutation, you first call `useCreateOvertimeClaimTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOvertimeClaimTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOvertimeClaimTableMutation, { data, loading, error }] = useCreateOvertimeClaimTableMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useCreateOvertimeClaimTableMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOvertimeClaimTableMutation, CreateOvertimeClaimTableMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOvertimeClaimTableMutation, CreateOvertimeClaimTableMutationVariables>(CreateOvertimeClaimTableDocument, baseOptions);
      }
export type CreateOvertimeClaimTableMutationHookResult = ReturnType<typeof useCreateOvertimeClaimTableMutation>;
export type CreateOvertimeClaimTableMutationResult = ApolloReactCommon.MutationResult<CreateOvertimeClaimTableMutation>;
export type CreateOvertimeClaimTableMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOvertimeClaimTableMutation, CreateOvertimeClaimTableMutationVariables>;
export const GetPayItemListingsDocument = gql`
    query getPayItemListings($SubscriptionAccountID: String!) {
  PayItemListing(SubscriptionAccountID: $SubscriptionAccountID) {
    PayName
    PayItemID
    PayItemType
  }
}
    `;

/**
 * __useGetPayItemListingsQuery__
 *
 * To run a query within a React component, call `useGetPayItemListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPayItemListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPayItemListingsQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useGetPayItemListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPayItemListingsQuery, GetPayItemListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPayItemListingsQuery, GetPayItemListingsQueryVariables>(GetPayItemListingsDocument, baseOptions);
      }
export function useGetPayItemListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPayItemListingsQuery, GetPayItemListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPayItemListingsQuery, GetPayItemListingsQueryVariables>(GetPayItemListingsDocument, baseOptions);
        }
export type GetPayItemListingsQueryHookResult = ReturnType<typeof useGetPayItemListingsQuery>;
export type GetPayItemListingsLazyQueryHookResult = ReturnType<typeof useGetPayItemListingsLazyQuery>;
export type GetPayItemListingsQueryResult = ApolloReactCommon.QueryResult<GetPayItemListingsQuery, GetPayItemListingsQueryVariables>;
export const OtReasonInsertDocument = gql`
    mutation OTReasonInsert($Input: OvertimeReasonInput!) {
  OTReasonInsert(Input: $Input) {
    OvertimeReasonID
  }
}
    `;
export type OtReasonInsertMutationFn = ApolloReactCommon.MutationFunction<OtReasonInsertMutation, OtReasonInsertMutationVariables>;

/**
 * __useOtReasonInsertMutation__
 *
 * To run a mutation, you first call `useOtReasonInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOtReasonInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [otReasonInsertMutation, { data, loading, error }] = useOtReasonInsertMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useOtReasonInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OtReasonInsertMutation, OtReasonInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<OtReasonInsertMutation, OtReasonInsertMutationVariables>(OtReasonInsertDocument, baseOptions);
      }
export type OtReasonInsertMutationHookResult = ReturnType<typeof useOtReasonInsertMutation>;
export type OtReasonInsertMutationResult = ApolloReactCommon.MutationResult<OtReasonInsertMutation>;
export type OtReasonInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<OtReasonInsertMutation, OtReasonInsertMutationVariables>;
export const OtReasonListingDocument = gql`
    query OTReasonListing {
  OTReasonListing {
    OvertimeReasonID
    Name
    Status
    CreatedDT
    ModifiedDT
  }
}
    `;

/**
 * __useOtReasonListingQuery__
 *
 * To run a query within a React component, call `useOtReasonListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtReasonListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtReasonListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useOtReasonListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OtReasonListingQuery, OtReasonListingQueryVariables>) {
        return ApolloReactHooks.useQuery<OtReasonListingQuery, OtReasonListingQueryVariables>(OtReasonListingDocument, baseOptions);
      }
export function useOtReasonListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OtReasonListingQuery, OtReasonListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OtReasonListingQuery, OtReasonListingQueryVariables>(OtReasonListingDocument, baseOptions);
        }
export type OtReasonListingQueryHookResult = ReturnType<typeof useOtReasonListingQuery>;
export type OtReasonListingLazyQueryHookResult = ReturnType<typeof useOtReasonListingLazyQuery>;
export type OtReasonListingQueryResult = ApolloReactCommon.QueryResult<OtReasonListingQuery, OtReasonListingQueryVariables>;
export const OtReasonUpdateDocument = gql`
    mutation OTReasonUpdate($OvertimeReasonID: String!, $Input: OvertimeReasonInput!) {
  OTReasonUpdate(OvertimeReasonID: $OvertimeReasonID, Input: $Input)
}
    `;
export type OtReasonUpdateMutationFn = ApolloReactCommon.MutationFunction<OtReasonUpdateMutation, OtReasonUpdateMutationVariables>;

/**
 * __useOtReasonUpdateMutation__
 *
 * To run a mutation, you first call `useOtReasonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOtReasonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [otReasonUpdateMutation, { data, loading, error }] = useOtReasonUpdateMutation({
 *   variables: {
 *      OvertimeReasonID: // value for 'OvertimeReasonID'
 *      Input: // value for 'Input'
 *   },
 * });
 */
export function useOtReasonUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OtReasonUpdateMutation, OtReasonUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OtReasonUpdateMutation, OtReasonUpdateMutationVariables>(OtReasonUpdateDocument, baseOptions);
      }
export type OtReasonUpdateMutationHookResult = ReturnType<typeof useOtReasonUpdateMutation>;
export type OtReasonUpdateMutationResult = ApolloReactCommon.MutationResult<OtReasonUpdateMutation>;
export type OtReasonUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OtReasonUpdateMutation, OtReasonUpdateMutationVariables>;
export const OtReasonStatusDocument = gql`
    mutation OTReasonStatus($OvertimeReasonID: String!, $Status: String!) {
  OTReasonStatus(OvertimeReasonID: $OvertimeReasonID, Status: $Status)
}
    `;
export type OtReasonStatusMutationFn = ApolloReactCommon.MutationFunction<OtReasonStatusMutation, OtReasonStatusMutationVariables>;

/**
 * __useOtReasonStatusMutation__
 *
 * To run a mutation, you first call `useOtReasonStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOtReasonStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [otReasonStatusMutation, { data, loading, error }] = useOtReasonStatusMutation({
 *   variables: {
 *      OvertimeReasonID: // value for 'OvertimeReasonID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useOtReasonStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OtReasonStatusMutation, OtReasonStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<OtReasonStatusMutation, OtReasonStatusMutationVariables>(OtReasonStatusDocument, baseOptions);
      }
export type OtReasonStatusMutationHookResult = ReturnType<typeof useOtReasonStatusMutation>;
export type OtReasonStatusMutationResult = ApolloReactCommon.MutationResult<OtReasonStatusMutation>;
export type OtReasonStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<OtReasonStatusMutation, OtReasonStatusMutationVariables>;
export const OtReasonDeleteDocument = gql`
    mutation OTReasonDelete($OvertimeReasonID: String!) {
  OTReasonDelete(OvertimeReasonID: $OvertimeReasonID)
}
    `;
export type OtReasonDeleteMutationFn = ApolloReactCommon.MutationFunction<OtReasonDeleteMutation, OtReasonDeleteMutationVariables>;

/**
 * __useOtReasonDeleteMutation__
 *
 * To run a mutation, you first call `useOtReasonDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOtReasonDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [otReasonDeleteMutation, { data, loading, error }] = useOtReasonDeleteMutation({
 *   variables: {
 *      OvertimeReasonID: // value for 'OvertimeReasonID'
 *   },
 * });
 */
export function useOtReasonDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OtReasonDeleteMutation, OtReasonDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<OtReasonDeleteMutation, OtReasonDeleteMutationVariables>(OtReasonDeleteDocument, baseOptions);
      }
export type OtReasonDeleteMutationHookResult = ReturnType<typeof useOtReasonDeleteMutation>;
export type OtReasonDeleteMutationResult = ApolloReactCommon.MutationResult<OtReasonDeleteMutation>;
export type OtReasonDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<OtReasonDeleteMutation, OtReasonDeleteMutationVariables>;
export const DeleteOtRoundingDocument = gql`
    mutation deleteOTRounding($OTRoundingID: String!) {
  deleteOTRounding(OTRoundingID: $OTRoundingID)
}
    `;
export type DeleteOtRoundingMutationFn = ApolloReactCommon.MutationFunction<DeleteOtRoundingMutation, DeleteOtRoundingMutationVariables>;

/**
 * __useDeleteOtRoundingMutation__
 *
 * To run a mutation, you first call `useDeleteOtRoundingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOtRoundingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOtRoundingMutation, { data, loading, error }] = useDeleteOtRoundingMutation({
 *   variables: {
 *      OTRoundingID: // value for 'OTRoundingID'
 *   },
 * });
 */
export function useDeleteOtRoundingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOtRoundingMutation, DeleteOtRoundingMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOtRoundingMutation, DeleteOtRoundingMutationVariables>(DeleteOtRoundingDocument, baseOptions);
      }
export type DeleteOtRoundingMutationHookResult = ReturnType<typeof useDeleteOtRoundingMutation>;
export type DeleteOtRoundingMutationResult = ApolloReactCommon.MutationResult<DeleteOtRoundingMutation>;
export type DeleteOtRoundingMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOtRoundingMutation, DeleteOtRoundingMutationVariables>;
export const CreateOtRoundingDocument = gql`
    mutation createOTRounding($input: OTRoundingInput!) {
  createOTRounding(input: $input)
}
    `;
export type CreateOtRoundingMutationFn = ApolloReactCommon.MutationFunction<CreateOtRoundingMutation, CreateOtRoundingMutationVariables>;

/**
 * __useCreateOtRoundingMutation__
 *
 * To run a mutation, you first call `useCreateOtRoundingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOtRoundingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOtRoundingMutation, { data, loading, error }] = useCreateOtRoundingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOtRoundingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOtRoundingMutation, CreateOtRoundingMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOtRoundingMutation, CreateOtRoundingMutationVariables>(CreateOtRoundingDocument, baseOptions);
      }
export type CreateOtRoundingMutationHookResult = ReturnType<typeof useCreateOtRoundingMutation>;
export type CreateOtRoundingMutationResult = ApolloReactCommon.MutationResult<CreateOtRoundingMutation>;
export type CreateOtRoundingMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOtRoundingMutation, CreateOtRoundingMutationVariables>;
export const UpdateOtRoundingDocument = gql`
    mutation updateOTRounding($OTRoundingID: String!, $input: OTRoundingInput!) {
  updateOTRounding(OTRoundingID: $OTRoundingID, input: $input)
}
    `;
export type UpdateOtRoundingMutationFn = ApolloReactCommon.MutationFunction<UpdateOtRoundingMutation, UpdateOtRoundingMutationVariables>;

/**
 * __useUpdateOtRoundingMutation__
 *
 * To run a mutation, you first call `useUpdateOtRoundingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOtRoundingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOtRoundingMutation, { data, loading, error }] = useUpdateOtRoundingMutation({
 *   variables: {
 *      OTRoundingID: // value for 'OTRoundingID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOtRoundingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOtRoundingMutation, UpdateOtRoundingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOtRoundingMutation, UpdateOtRoundingMutationVariables>(UpdateOtRoundingDocument, baseOptions);
      }
export type UpdateOtRoundingMutationHookResult = ReturnType<typeof useUpdateOtRoundingMutation>;
export type UpdateOtRoundingMutationResult = ApolloReactCommon.MutationResult<UpdateOtRoundingMutation>;
export type UpdateOtRoundingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOtRoundingMutation, UpdateOtRoundingMutationVariables>;
export const DeleteAllowanceDeductionDocument = gql`
    mutation deleteAllowanceDeduction($AllowanceDeductionID: String!) {
  deleteAllowanceDeduction(AllowanceDeductionID: $AllowanceDeductionID)
}
    `;
export type DeleteAllowanceDeductionMutationFn = ApolloReactCommon.MutationFunction<DeleteAllowanceDeductionMutation, DeleteAllowanceDeductionMutationVariables>;

/**
 * __useDeleteAllowanceDeductionMutation__
 *
 * To run a mutation, you first call `useDeleteAllowanceDeductionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllowanceDeductionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllowanceDeductionMutation, { data, loading, error }] = useDeleteAllowanceDeductionMutation({
 *   variables: {
 *      AllowanceDeductionID: // value for 'AllowanceDeductionID'
 *   },
 * });
 */
export function useDeleteAllowanceDeductionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAllowanceDeductionMutation, DeleteAllowanceDeductionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAllowanceDeductionMutation, DeleteAllowanceDeductionMutationVariables>(DeleteAllowanceDeductionDocument, baseOptions);
      }
export type DeleteAllowanceDeductionMutationHookResult = ReturnType<typeof useDeleteAllowanceDeductionMutation>;
export type DeleteAllowanceDeductionMutationResult = ApolloReactCommon.MutationResult<DeleteAllowanceDeductionMutation>;
export type DeleteAllowanceDeductionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAllowanceDeductionMutation, DeleteAllowanceDeductionMutationVariables>;
export const CreateAllowanceDeductionDocument = gql`
    mutation createAllowanceDeduction($input: AllowanceDeductionInput!) {
  createAllowanceDeduction(input: $input)
}
    `;
export type CreateAllowanceDeductionMutationFn = ApolloReactCommon.MutationFunction<CreateAllowanceDeductionMutation, CreateAllowanceDeductionMutationVariables>;

/**
 * __useCreateAllowanceDeductionMutation__
 *
 * To run a mutation, you first call `useCreateAllowanceDeductionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAllowanceDeductionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAllowanceDeductionMutation, { data, loading, error }] = useCreateAllowanceDeductionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAllowanceDeductionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAllowanceDeductionMutation, CreateAllowanceDeductionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAllowanceDeductionMutation, CreateAllowanceDeductionMutationVariables>(CreateAllowanceDeductionDocument, baseOptions);
      }
export type CreateAllowanceDeductionMutationHookResult = ReturnType<typeof useCreateAllowanceDeductionMutation>;
export type CreateAllowanceDeductionMutationResult = ApolloReactCommon.MutationResult<CreateAllowanceDeductionMutation>;
export type CreateAllowanceDeductionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAllowanceDeductionMutation, CreateAllowanceDeductionMutationVariables>;
export const UpdateAllowanceDeductionDocument = gql`
    mutation updateAllowanceDeduction($AllowanceDeductionID: String!, $input: AllowanceDeductionInput!) {
  updateAllowanceDeduction(AllowanceDeductionID: $AllowanceDeductionID, input: $input)
}
    `;
export type UpdateAllowanceDeductionMutationFn = ApolloReactCommon.MutationFunction<UpdateAllowanceDeductionMutation, UpdateAllowanceDeductionMutationVariables>;

/**
 * __useUpdateAllowanceDeductionMutation__
 *
 * To run a mutation, you first call `useUpdateAllowanceDeductionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllowanceDeductionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllowanceDeductionMutation, { data, loading, error }] = useUpdateAllowanceDeductionMutation({
 *   variables: {
 *      AllowanceDeductionID: // value for 'AllowanceDeductionID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAllowanceDeductionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAllowanceDeductionMutation, UpdateAllowanceDeductionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAllowanceDeductionMutation, UpdateAllowanceDeductionMutationVariables>(UpdateAllowanceDeductionDocument, baseOptions);
      }
export type UpdateAllowanceDeductionMutationHookResult = ReturnType<typeof useUpdateAllowanceDeductionMutation>;
export type UpdateAllowanceDeductionMutationResult = ApolloReactCommon.MutationResult<UpdateAllowanceDeductionMutation>;
export type UpdateAllowanceDeductionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAllowanceDeductionMutation, UpdateAllowanceDeductionMutationVariables>;
export const DeleteDailyAllowanceDocument = gql`
    mutation deleteDailyAllowance($DailyAllowanceID: String!) {
  deleteDailyAllowance(DailyAllowanceID: $DailyAllowanceID)
}
    `;
export type DeleteDailyAllowanceMutationFn = ApolloReactCommon.MutationFunction<DeleteDailyAllowanceMutation, DeleteDailyAllowanceMutationVariables>;

/**
 * __useDeleteDailyAllowanceMutation__
 *
 * To run a mutation, you first call `useDeleteDailyAllowanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDailyAllowanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDailyAllowanceMutation, { data, loading, error }] = useDeleteDailyAllowanceMutation({
 *   variables: {
 *      DailyAllowanceID: // value for 'DailyAllowanceID'
 *   },
 * });
 */
export function useDeleteDailyAllowanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDailyAllowanceMutation, DeleteDailyAllowanceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDailyAllowanceMutation, DeleteDailyAllowanceMutationVariables>(DeleteDailyAllowanceDocument, baseOptions);
      }
export type DeleteDailyAllowanceMutationHookResult = ReturnType<typeof useDeleteDailyAllowanceMutation>;
export type DeleteDailyAllowanceMutationResult = ApolloReactCommon.MutationResult<DeleteDailyAllowanceMutation>;
export type DeleteDailyAllowanceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDailyAllowanceMutation, DeleteDailyAllowanceMutationVariables>;
export const GetShiftDocument = gql`
    query getShift($SubscriptionAccountID: String, $Status: Status) {
  getShift(SubscriptionAccountID: $SubscriptionAccountID, Status: $Status) {
    ShiftID
    Name
    Status
    DutyRosterDisplayColour
    shiftDayList {
      ShiftDayID
      ShiftID
      DayType
    }
  }
}
    `;

/**
 * __useGetShiftQuery__
 *
 * To run a query within a React component, call `useGetShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShiftQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useGetShiftQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetShiftQuery, GetShiftQueryVariables>) {
        return ApolloReactHooks.useQuery<GetShiftQuery, GetShiftQueryVariables>(GetShiftDocument, baseOptions);
      }
export function useGetShiftLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShiftQuery, GetShiftQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetShiftQuery, GetShiftQueryVariables>(GetShiftDocument, baseOptions);
        }
export type GetShiftQueryHookResult = ReturnType<typeof useGetShiftQuery>;
export type GetShiftLazyQueryHookResult = ReturnType<typeof useGetShiftLazyQuery>;
export type GetShiftQueryResult = ApolloReactCommon.QueryResult<GetShiftQuery, GetShiftQueryVariables>;
export const UpdateDailyAllowanceDocument = gql`
    mutation updateDailyAllowance($DailyAllowanceID: String!, $input: DailyAllowanceInput!) {
  updateDailyAllowance(DailyAllowanceID: $DailyAllowanceID, input: $input)
}
    `;
export type UpdateDailyAllowanceMutationFn = ApolloReactCommon.MutationFunction<UpdateDailyAllowanceMutation, UpdateDailyAllowanceMutationVariables>;

/**
 * __useUpdateDailyAllowanceMutation__
 *
 * To run a mutation, you first call `useUpdateDailyAllowanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDailyAllowanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDailyAllowanceMutation, { data, loading, error }] = useUpdateDailyAllowanceMutation({
 *   variables: {
 *      DailyAllowanceID: // value for 'DailyAllowanceID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDailyAllowanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDailyAllowanceMutation, UpdateDailyAllowanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDailyAllowanceMutation, UpdateDailyAllowanceMutationVariables>(UpdateDailyAllowanceDocument, baseOptions);
      }
export type UpdateDailyAllowanceMutationHookResult = ReturnType<typeof useUpdateDailyAllowanceMutation>;
export type UpdateDailyAllowanceMutationResult = ApolloReactCommon.MutationResult<UpdateDailyAllowanceMutation>;
export type UpdateDailyAllowanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDailyAllowanceMutation, UpdateDailyAllowanceMutationVariables>;
export const DeleteMonthlyAllowanceDocument = gql`
    mutation deleteMonthlyAllowance($MonthlyAllowanceID: String!) {
  deleteMonthlyAllowance(MonthlyAllowanceID: $MonthlyAllowanceID)
}
    `;
export type DeleteMonthlyAllowanceMutationFn = ApolloReactCommon.MutationFunction<DeleteMonthlyAllowanceMutation, DeleteMonthlyAllowanceMutationVariables>;

/**
 * __useDeleteMonthlyAllowanceMutation__
 *
 * To run a mutation, you first call `useDeleteMonthlyAllowanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMonthlyAllowanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMonthlyAllowanceMutation, { data, loading, error }] = useDeleteMonthlyAllowanceMutation({
 *   variables: {
 *      MonthlyAllowanceID: // value for 'MonthlyAllowanceID'
 *   },
 * });
 */
export function useDeleteMonthlyAllowanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMonthlyAllowanceMutation, DeleteMonthlyAllowanceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMonthlyAllowanceMutation, DeleteMonthlyAllowanceMutationVariables>(DeleteMonthlyAllowanceDocument, baseOptions);
      }
export type DeleteMonthlyAllowanceMutationHookResult = ReturnType<typeof useDeleteMonthlyAllowanceMutation>;
export type DeleteMonthlyAllowanceMutationResult = ApolloReactCommon.MutationResult<DeleteMonthlyAllowanceMutation>;
export type DeleteMonthlyAllowanceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMonthlyAllowanceMutation, DeleteMonthlyAllowanceMutationVariables>;
export const GetMonthlyAllowanceDocument = gql`
    query getMonthlyAllowance($SubscriptionAccountID: String!, $Status: Status) {
  getMonthlyAllowance(SubscriptionAccountID: $SubscriptionAccountID, Status: $Status) {
    PeriodicCycle
    PeriodicCutoff
    PeriodicEffectiveDate
    PeriodicAllowanceTypeID
    Status
    CreatedDT
    ModifiedDT
    CreatedBy
    MonthlyAllowanceID
    Name
    Condition
    Value
    RoundingPolicyID
    RoundingPolicy {
      AllowanceDeductionID
      Name
      RoundingCriteria
    }
    PayItemID
    PayItem {
      PayItemID
      PayName
    }
    PeriodicAllowancePolicyCondition {
      ID
      Value
      Condition
      Status
    }
  }
  MonthlyAllowanceInUseListing
}
    `;

/**
 * __useGetMonthlyAllowanceQuery__
 *
 * To run a query within a React component, call `useGetMonthlyAllowanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyAllowanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyAllowanceQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useGetMonthlyAllowanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMonthlyAllowanceQuery, GetMonthlyAllowanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMonthlyAllowanceQuery, GetMonthlyAllowanceQueryVariables>(GetMonthlyAllowanceDocument, baseOptions);
      }
export function useGetMonthlyAllowanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMonthlyAllowanceQuery, GetMonthlyAllowanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMonthlyAllowanceQuery, GetMonthlyAllowanceQueryVariables>(GetMonthlyAllowanceDocument, baseOptions);
        }
export type GetMonthlyAllowanceQueryHookResult = ReturnType<typeof useGetMonthlyAllowanceQuery>;
export type GetMonthlyAllowanceLazyQueryHookResult = ReturnType<typeof useGetMonthlyAllowanceLazyQuery>;
export type GetMonthlyAllowanceQueryResult = ApolloReactCommon.QueryResult<GetMonthlyAllowanceQuery, GetMonthlyAllowanceQueryVariables>;
export const CreateMonthlyAllowanceDocument = gql`
    mutation createMonthlyAllowance($input: MonthlyAllowanceInput!, $conditionList: [PeriodicAllowancePolicyConditionInput!]!) {
  createMonthlyAllowance(input: $input, conditionList: $conditionList)
}
    `;
export type CreateMonthlyAllowanceMutationFn = ApolloReactCommon.MutationFunction<CreateMonthlyAllowanceMutation, CreateMonthlyAllowanceMutationVariables>;

/**
 * __useCreateMonthlyAllowanceMutation__
 *
 * To run a mutation, you first call `useCreateMonthlyAllowanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMonthlyAllowanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMonthlyAllowanceMutation, { data, loading, error }] = useCreateMonthlyAllowanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      conditionList: // value for 'conditionList'
 *   },
 * });
 */
export function useCreateMonthlyAllowanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMonthlyAllowanceMutation, CreateMonthlyAllowanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMonthlyAllowanceMutation, CreateMonthlyAllowanceMutationVariables>(CreateMonthlyAllowanceDocument, baseOptions);
      }
export type CreateMonthlyAllowanceMutationHookResult = ReturnType<typeof useCreateMonthlyAllowanceMutation>;
export type CreateMonthlyAllowanceMutationResult = ApolloReactCommon.MutationResult<CreateMonthlyAllowanceMutation>;
export type CreateMonthlyAllowanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMonthlyAllowanceMutation, CreateMonthlyAllowanceMutationVariables>;
export const UpdateMonthlyAllowanceDocument = gql`
    mutation updateMonthlyAllowance($MonthlyAllowanceID: String!, $input: MonthlyAllowanceInput!, $conditionList: [PeriodicAllowancePolicyConditionInput!]!) {
  updateMonthlyAllowance(MonthlyAllowanceID: $MonthlyAllowanceID, input: $input, conditionList: $conditionList)
}
    `;
export type UpdateMonthlyAllowanceMutationFn = ApolloReactCommon.MutationFunction<UpdateMonthlyAllowanceMutation, UpdateMonthlyAllowanceMutationVariables>;

/**
 * __useUpdateMonthlyAllowanceMutation__
 *
 * To run a mutation, you first call `useUpdateMonthlyAllowanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonthlyAllowanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonthlyAllowanceMutation, { data, loading, error }] = useUpdateMonthlyAllowanceMutation({
 *   variables: {
 *      MonthlyAllowanceID: // value for 'MonthlyAllowanceID'
 *      input: // value for 'input'
 *      conditionList: // value for 'conditionList'
 *   },
 * });
 */
export function useUpdateMonthlyAllowanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMonthlyAllowanceMutation, UpdateMonthlyAllowanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMonthlyAllowanceMutation, UpdateMonthlyAllowanceMutationVariables>(UpdateMonthlyAllowanceDocument, baseOptions);
      }
export type UpdateMonthlyAllowanceMutationHookResult = ReturnType<typeof useUpdateMonthlyAllowanceMutation>;
export type UpdateMonthlyAllowanceMutationResult = ApolloReactCommon.MutationResult<UpdateMonthlyAllowanceMutation>;
export type UpdateMonthlyAllowanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMonthlyAllowanceMutation, UpdateMonthlyAllowanceMutationVariables>;
export const GetPeriodicDeductionDocument = gql`
    query getPeriodicDeduction($SubscriptionAccountID: String!) {
  getPeriodicDeduction(SubscriptionAccountID: $SubscriptionAccountID, Status: Active) {
    PeriodicCycle
    PeriodicCutoff
    PeriodicEffectiveDate
    PeriodicAllowanceTypeID
    Status
    CreatedDT
    ModifiedDT
    CreatedBy
    PeriodicDeductionID
    Name
    RoundingPolicyID
    RoundingPolicy {
      AllowanceDeductionID
      Name
      RoundingCriteria
    }
    PayItemID
    PayItem {
      PayItemID
      PayName
    }
    PeriodicDeductionPolicyCondition {
      ID
      Value
      Condition
      Status
    }
  }
  PeriodicDeductionInUseListing
}
    `;

/**
 * __useGetPeriodicDeductionQuery__
 *
 * To run a query within a React component, call `useGetPeriodicDeductionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeriodicDeductionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeriodicDeductionQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useGetPeriodicDeductionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPeriodicDeductionQuery, GetPeriodicDeductionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPeriodicDeductionQuery, GetPeriodicDeductionQueryVariables>(GetPeriodicDeductionDocument, baseOptions);
      }
export function useGetPeriodicDeductionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPeriodicDeductionQuery, GetPeriodicDeductionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPeriodicDeductionQuery, GetPeriodicDeductionQueryVariables>(GetPeriodicDeductionDocument, baseOptions);
        }
export type GetPeriodicDeductionQueryHookResult = ReturnType<typeof useGetPeriodicDeductionQuery>;
export type GetPeriodicDeductionLazyQueryHookResult = ReturnType<typeof useGetPeriodicDeductionLazyQuery>;
export type GetPeriodicDeductionQueryResult = ApolloReactCommon.QueryResult<GetPeriodicDeductionQuery, GetPeriodicDeductionQueryVariables>;
export const CreatePeriodicDeductionDocument = gql`
    mutation createPeriodicDeduction($input: PeriodicDeductionInput!, $conditionList: [PeriodicDeductionPolicyConditionInput!]!) {
  createPeriodicDeduction(input: $input, conditionList: $conditionList)
}
    `;
export type CreatePeriodicDeductionMutationFn = ApolloReactCommon.MutationFunction<CreatePeriodicDeductionMutation, CreatePeriodicDeductionMutationVariables>;

/**
 * __useCreatePeriodicDeductionMutation__
 *
 * To run a mutation, you first call `useCreatePeriodicDeductionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePeriodicDeductionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPeriodicDeductionMutation, { data, loading, error }] = useCreatePeriodicDeductionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      conditionList: // value for 'conditionList'
 *   },
 * });
 */
export function useCreatePeriodicDeductionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePeriodicDeductionMutation, CreatePeriodicDeductionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePeriodicDeductionMutation, CreatePeriodicDeductionMutationVariables>(CreatePeriodicDeductionDocument, baseOptions);
      }
export type CreatePeriodicDeductionMutationHookResult = ReturnType<typeof useCreatePeriodicDeductionMutation>;
export type CreatePeriodicDeductionMutationResult = ApolloReactCommon.MutationResult<CreatePeriodicDeductionMutation>;
export type CreatePeriodicDeductionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePeriodicDeductionMutation, CreatePeriodicDeductionMutationVariables>;
export const UpdatePeriodicDeductionDocument = gql`
    mutation updatePeriodicDeduction($PeriodicDeductionID: String!, $input: PeriodicDeductionInput!, $conditionList: [PeriodicDeductionPolicyConditionInput!]!) {
  updatePeriodicDeduction(PeriodicDeductionID: $PeriodicDeductionID, input: $input, conditionList: $conditionList)
}
    `;
export type UpdatePeriodicDeductionMutationFn = ApolloReactCommon.MutationFunction<UpdatePeriodicDeductionMutation, UpdatePeriodicDeductionMutationVariables>;

/**
 * __useUpdatePeriodicDeductionMutation__
 *
 * To run a mutation, you first call `useUpdatePeriodicDeductionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeriodicDeductionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeriodicDeductionMutation, { data, loading, error }] = useUpdatePeriodicDeductionMutation({
 *   variables: {
 *      PeriodicDeductionID: // value for 'PeriodicDeductionID'
 *      input: // value for 'input'
 *      conditionList: // value for 'conditionList'
 *   },
 * });
 */
export function useUpdatePeriodicDeductionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePeriodicDeductionMutation, UpdatePeriodicDeductionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePeriodicDeductionMutation, UpdatePeriodicDeductionMutationVariables>(UpdatePeriodicDeductionDocument, baseOptions);
      }
export type UpdatePeriodicDeductionMutationHookResult = ReturnType<typeof useUpdatePeriodicDeductionMutation>;
export type UpdatePeriodicDeductionMutationResult = ApolloReactCommon.MutationResult<UpdatePeriodicDeductionMutation>;
export type UpdatePeriodicDeductionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePeriodicDeductionMutation, UpdatePeriodicDeductionMutationVariables>;
export const DeletePeriodicDeductionDocument = gql`
    mutation deletePeriodicDeduction($PeriodicDeductionID: String!) {
  deletePeriodicDeduction(PeriodicDeductionID: $PeriodicDeductionID)
}
    `;
export type DeletePeriodicDeductionMutationFn = ApolloReactCommon.MutationFunction<DeletePeriodicDeductionMutation, DeletePeriodicDeductionMutationVariables>;

/**
 * __useDeletePeriodicDeductionMutation__
 *
 * To run a mutation, you first call `useDeletePeriodicDeductionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePeriodicDeductionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePeriodicDeductionMutation, { data, loading, error }] = useDeletePeriodicDeductionMutation({
 *   variables: {
 *      PeriodicDeductionID: // value for 'PeriodicDeductionID'
 *   },
 * });
 */
export function useDeletePeriodicDeductionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePeriodicDeductionMutation, DeletePeriodicDeductionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePeriodicDeductionMutation, DeletePeriodicDeductionMutationVariables>(DeletePeriodicDeductionDocument, baseOptions);
      }
export type DeletePeriodicDeductionMutationHookResult = ReturnType<typeof useDeletePeriodicDeductionMutation>;
export type DeletePeriodicDeductionMutationResult = ApolloReactCommon.MutationResult<DeletePeriodicDeductionMutation>;
export type DeletePeriodicDeductionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePeriodicDeductionMutation, DeletePeriodicDeductionMutationVariables>;
export const GetDeductionPolicyListingDocument = gql`
    query getDeductionPolicyListing($SubscriptionAccountID: String!, $Status: Status!) {
  getDeductionPolicy(SubscriptionAccountID: $SubscriptionAccountID, Status: $Status) {
    DeductionPolicyID
    Title
    Status
    Eligibility
    Value
    RoundingPolicyID
    PayItemID
    PayItem {
      PayName
    }
  }
  DeductionPolicyInUseListing
}
    `;

/**
 * __useGetDeductionPolicyListingQuery__
 *
 * To run a query within a React component, call `useGetDeductionPolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeductionPolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeductionPolicyListingQuery({
 *   variables: {
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useGetDeductionPolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeductionPolicyListingQuery, GetDeductionPolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDeductionPolicyListingQuery, GetDeductionPolicyListingQueryVariables>(GetDeductionPolicyListingDocument, baseOptions);
      }
export function useGetDeductionPolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeductionPolicyListingQuery, GetDeductionPolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDeductionPolicyListingQuery, GetDeductionPolicyListingQueryVariables>(GetDeductionPolicyListingDocument, baseOptions);
        }
export type GetDeductionPolicyListingQueryHookResult = ReturnType<typeof useGetDeductionPolicyListingQuery>;
export type GetDeductionPolicyListingLazyQueryHookResult = ReturnType<typeof useGetDeductionPolicyListingLazyQuery>;
export type GetDeductionPolicyListingQueryResult = ApolloReactCommon.QueryResult<GetDeductionPolicyListingQuery, GetDeductionPolicyListingQueryVariables>;
export const UpdateDeductionPolicyStatusDocument = gql`
    mutation updateDeductionPolicyStatus($DeductionPolicyID: String!, $Status: Status!) {
  updateDeductionPolicyStatus(DeductionPolicyID: $DeductionPolicyID, Status: $Status)
}
    `;
export type UpdateDeductionPolicyStatusMutationFn = ApolloReactCommon.MutationFunction<UpdateDeductionPolicyStatusMutation, UpdateDeductionPolicyStatusMutationVariables>;

/**
 * __useUpdateDeductionPolicyStatusMutation__
 *
 * To run a mutation, you first call `useUpdateDeductionPolicyStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeductionPolicyStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeductionPolicyStatusMutation, { data, loading, error }] = useUpdateDeductionPolicyStatusMutation({
 *   variables: {
 *      DeductionPolicyID: // value for 'DeductionPolicyID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useUpdateDeductionPolicyStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDeductionPolicyStatusMutation, UpdateDeductionPolicyStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDeductionPolicyStatusMutation, UpdateDeductionPolicyStatusMutationVariables>(UpdateDeductionPolicyStatusDocument, baseOptions);
      }
export type UpdateDeductionPolicyStatusMutationHookResult = ReturnType<typeof useUpdateDeductionPolicyStatusMutation>;
export type UpdateDeductionPolicyStatusMutationResult = ApolloReactCommon.MutationResult<UpdateDeductionPolicyStatusMutation>;
export type UpdateDeductionPolicyStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDeductionPolicyStatusMutation, UpdateDeductionPolicyStatusMutationVariables>;
export const CreateDeductionPolicyDocument = gql`
    mutation createDeductionPolicy($input: DeductionPolicyInput!) {
  createDeductionPolicy(input: $input) {
    DeductionPolicyID
  }
}
    `;
export type CreateDeductionPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateDeductionPolicyMutation, CreateDeductionPolicyMutationVariables>;

/**
 * __useCreateDeductionPolicyMutation__
 *
 * To run a mutation, you first call `useCreateDeductionPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeductionPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeductionPolicyMutation, { data, loading, error }] = useCreateDeductionPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeductionPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDeductionPolicyMutation, CreateDeductionPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDeductionPolicyMutation, CreateDeductionPolicyMutationVariables>(CreateDeductionPolicyDocument, baseOptions);
      }
export type CreateDeductionPolicyMutationHookResult = ReturnType<typeof useCreateDeductionPolicyMutation>;
export type CreateDeductionPolicyMutationResult = ApolloReactCommon.MutationResult<CreateDeductionPolicyMutation>;
export type CreateDeductionPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDeductionPolicyMutation, CreateDeductionPolicyMutationVariables>;
export const UpdateDeductionPolicyDocument = gql`
    mutation updateDeductionPolicy($input: DeductionPolicyInput!, $DeductionPolicyID: String!) {
  updateDeductionPolicy(input: $input, DeductionPolicyID: $DeductionPolicyID)
}
    `;
export type UpdateDeductionPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateDeductionPolicyMutation, UpdateDeductionPolicyMutationVariables>;

/**
 * __useUpdateDeductionPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateDeductionPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeductionPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeductionPolicyMutation, { data, loading, error }] = useUpdateDeductionPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      DeductionPolicyID: // value for 'DeductionPolicyID'
 *   },
 * });
 */
export function useUpdateDeductionPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDeductionPolicyMutation, UpdateDeductionPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDeductionPolicyMutation, UpdateDeductionPolicyMutationVariables>(UpdateDeductionPolicyDocument, baseOptions);
      }
export type UpdateDeductionPolicyMutationHookResult = ReturnType<typeof useUpdateDeductionPolicyMutation>;
export type UpdateDeductionPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateDeductionPolicyMutation>;
export type UpdateDeductionPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDeductionPolicyMutation, UpdateDeductionPolicyMutationVariables>;
export const TimeOffSettingInsertDocument = gql`
    mutation TimeOffSettingInsert($input: TimeOffSettingInput!) {
  TimeOffSettingInsert(input: $input) {
    TimeOffSettingID
  }
}
    `;
export type TimeOffSettingInsertMutationFn = ApolloReactCommon.MutationFunction<TimeOffSettingInsertMutation, TimeOffSettingInsertMutationVariables>;

/**
 * __useTimeOffSettingInsertMutation__
 *
 * To run a mutation, you first call `useTimeOffSettingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffSettingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffSettingInsertMutation, { data, loading, error }] = useTimeOffSettingInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffSettingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TimeOffSettingInsertMutation, TimeOffSettingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TimeOffSettingInsertMutation, TimeOffSettingInsertMutationVariables>(TimeOffSettingInsertDocument, baseOptions);
      }
export type TimeOffSettingInsertMutationHookResult = ReturnType<typeof useTimeOffSettingInsertMutation>;
export type TimeOffSettingInsertMutationResult = ApolloReactCommon.MutationResult<TimeOffSettingInsertMutation>;
export type TimeOffSettingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TimeOffSettingInsertMutation, TimeOffSettingInsertMutationVariables>;
export const TimeOffSettingUpdateDocument = gql`
    mutation TimeOffSettingUpdate($input: TimeOffSettingInput!) {
  TimeOffSettingUpdate(input: $input)
}
    `;
export type TimeOffSettingUpdateMutationFn = ApolloReactCommon.MutationFunction<TimeOffSettingUpdateMutation, TimeOffSettingUpdateMutationVariables>;

/**
 * __useTimeOffSettingUpdateMutation__
 *
 * To run a mutation, you first call `useTimeOffSettingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffSettingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffSettingUpdateMutation, { data, loading, error }] = useTimeOffSettingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffSettingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TimeOffSettingUpdateMutation, TimeOffSettingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TimeOffSettingUpdateMutation, TimeOffSettingUpdateMutationVariables>(TimeOffSettingUpdateDocument, baseOptions);
      }
export type TimeOffSettingUpdateMutationHookResult = ReturnType<typeof useTimeOffSettingUpdateMutation>;
export type TimeOffSettingUpdateMutationResult = ApolloReactCommon.MutationResult<TimeOffSettingUpdateMutation>;
export type TimeOffSettingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TimeOffSettingUpdateMutation, TimeOffSettingUpdateMutationVariables>;
export const TimeOffSettingDeleteDocument = gql`
    mutation TimeOffSettingDelete($TimeOffSettingID: String!) {
  TimeOffSettingDelete(TimeOffSettingID: $TimeOffSettingID)
}
    `;
export type TimeOffSettingDeleteMutationFn = ApolloReactCommon.MutationFunction<TimeOffSettingDeleteMutation, TimeOffSettingDeleteMutationVariables>;

/**
 * __useTimeOffSettingDeleteMutation__
 *
 * To run a mutation, you first call `useTimeOffSettingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffSettingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffSettingDeleteMutation, { data, loading, error }] = useTimeOffSettingDeleteMutation({
 *   variables: {
 *      TimeOffSettingID: // value for 'TimeOffSettingID'
 *   },
 * });
 */
export function useTimeOffSettingDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TimeOffSettingDeleteMutation, TimeOffSettingDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TimeOffSettingDeleteMutation, TimeOffSettingDeleteMutationVariables>(TimeOffSettingDeleteDocument, baseOptions);
      }
export type TimeOffSettingDeleteMutationHookResult = ReturnType<typeof useTimeOffSettingDeleteMutation>;
export type TimeOffSettingDeleteMutationResult = ApolloReactCommon.MutationResult<TimeOffSettingDeleteMutation>;
export type TimeOffSettingDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TimeOffSettingDeleteMutation, TimeOffSettingDeleteMutationVariables>;
export const TimeOffSettingListingDocument = gql`
    query TimeOffSettingListing {
  TimeOffSettingListing {
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    TimeOffSettingID
    SubscriptionAccountID
    Name
    EnableTimeOff
    TimesPerMonth
    MinIntervalPerRequest
    MaxIntervalPerRequest
    MaxTimeOffPerDay
    MaxTimeOffPerMonth
  }
}
    `;

/**
 * __useTimeOffSettingListingQuery__
 *
 * To run a query within a React component, call `useTimeOffSettingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffSettingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffSettingListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeOffSettingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimeOffSettingListingQuery, TimeOffSettingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TimeOffSettingListingQuery, TimeOffSettingListingQueryVariables>(TimeOffSettingListingDocument, baseOptions);
      }
export function useTimeOffSettingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeOffSettingListingQuery, TimeOffSettingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimeOffSettingListingQuery, TimeOffSettingListingQueryVariables>(TimeOffSettingListingDocument, baseOptions);
        }
export type TimeOffSettingListingQueryHookResult = ReturnType<typeof useTimeOffSettingListingQuery>;
export type TimeOffSettingListingLazyQueryHookResult = ReturnType<typeof useTimeOffSettingListingLazyQuery>;
export type TimeOffSettingListingQueryResult = ApolloReactCommon.QueryResult<TimeOffSettingListingQuery, TimeOffSettingListingQueryVariables>;
export const TimeOffReasonDeleteDocument = gql`
    mutation TimeOffReasonDelete($TimeOffReasonID: String!) {
  TimeOffReasonDelete(TimeOffReasonID: $TimeOffReasonID)
}
    `;
export type TimeOffReasonDeleteMutationFn = ApolloReactCommon.MutationFunction<TimeOffReasonDeleteMutation, TimeOffReasonDeleteMutationVariables>;

/**
 * __useTimeOffReasonDeleteMutation__
 *
 * To run a mutation, you first call `useTimeOffReasonDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffReasonDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffReasonDeleteMutation, { data, loading, error }] = useTimeOffReasonDeleteMutation({
 *   variables: {
 *      TimeOffReasonID: // value for 'TimeOffReasonID'
 *   },
 * });
 */
export function useTimeOffReasonDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TimeOffReasonDeleteMutation, TimeOffReasonDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TimeOffReasonDeleteMutation, TimeOffReasonDeleteMutationVariables>(TimeOffReasonDeleteDocument, baseOptions);
      }
export type TimeOffReasonDeleteMutationHookResult = ReturnType<typeof useTimeOffReasonDeleteMutation>;
export type TimeOffReasonDeleteMutationResult = ApolloReactCommon.MutationResult<TimeOffReasonDeleteMutation>;
export type TimeOffReasonDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TimeOffReasonDeleteMutation, TimeOffReasonDeleteMutationVariables>;
export const TimeOffReasonInputDocument = gql`
    mutation TimeOffReasonInput($input: TimeOffReasonInput!) {
  TimeOffReasonInput(input: $input) {
    TimeOffReasonID
    Name
  }
}
    `;
export type TimeOffReasonInputMutationFn = ApolloReactCommon.MutationFunction<TimeOffReasonInputMutation, TimeOffReasonInputMutationVariables>;

/**
 * __useTimeOffReasonInputMutation__
 *
 * To run a mutation, you first call `useTimeOffReasonInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffReasonInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffReasonInputMutation, { data, loading, error }] = useTimeOffReasonInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffReasonInputMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TimeOffReasonInputMutation, TimeOffReasonInputMutationVariables>) {
        return ApolloReactHooks.useMutation<TimeOffReasonInputMutation, TimeOffReasonInputMutationVariables>(TimeOffReasonInputDocument, baseOptions);
      }
export type TimeOffReasonInputMutationHookResult = ReturnType<typeof useTimeOffReasonInputMutation>;
export type TimeOffReasonInputMutationResult = ApolloReactCommon.MutationResult<TimeOffReasonInputMutation>;
export type TimeOffReasonInputMutationOptions = ApolloReactCommon.BaseMutationOptions<TimeOffReasonInputMutation, TimeOffReasonInputMutationVariables>;
export const TimeOffReasonUpdateDocument = gql`
    mutation TimeOffReasonUpdate($input: TimeOffReasonInput!) {
  TimeOffReasonUpdate(input: $input)
}
    `;
export type TimeOffReasonUpdateMutationFn = ApolloReactCommon.MutationFunction<TimeOffReasonUpdateMutation, TimeOffReasonUpdateMutationVariables>;

/**
 * __useTimeOffReasonUpdateMutation__
 *
 * To run a mutation, you first call `useTimeOffReasonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffReasonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffReasonUpdateMutation, { data, loading, error }] = useTimeOffReasonUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffReasonUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TimeOffReasonUpdateMutation, TimeOffReasonUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TimeOffReasonUpdateMutation, TimeOffReasonUpdateMutationVariables>(TimeOffReasonUpdateDocument, baseOptions);
      }
export type TimeOffReasonUpdateMutationHookResult = ReturnType<typeof useTimeOffReasonUpdateMutation>;
export type TimeOffReasonUpdateMutationResult = ApolloReactCommon.MutationResult<TimeOffReasonUpdateMutation>;
export type TimeOffReasonUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TimeOffReasonUpdateMutation, TimeOffReasonUpdateMutationVariables>;
export const DeleteMobileCheckInDocument = gql`
    mutation deleteMobileCheckIn($MobileCheckInID: String!) {
  deleteMobileCheckIn(MobileCheckInID: $MobileCheckInID)
}
    `;
export type DeleteMobileCheckInMutationFn = ApolloReactCommon.MutationFunction<DeleteMobileCheckInMutation, DeleteMobileCheckInMutationVariables>;

/**
 * __useDeleteMobileCheckInMutation__
 *
 * To run a mutation, you first call `useDeleteMobileCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMobileCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMobileCheckInMutation, { data, loading, error }] = useDeleteMobileCheckInMutation({
 *   variables: {
 *      MobileCheckInID: // value for 'MobileCheckInID'
 *   },
 * });
 */
export function useDeleteMobileCheckInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMobileCheckInMutation, DeleteMobileCheckInMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMobileCheckInMutation, DeleteMobileCheckInMutationVariables>(DeleteMobileCheckInDocument, baseOptions);
      }
export type DeleteMobileCheckInMutationHookResult = ReturnType<typeof useDeleteMobileCheckInMutation>;
export type DeleteMobileCheckInMutationResult = ApolloReactCommon.MutationResult<DeleteMobileCheckInMutation>;
export type DeleteMobileCheckInMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMobileCheckInMutation, DeleteMobileCheckInMutationVariables>;
export const GetMobileCheckInDocument = gql`
    query getMobileCheckIn($MobileCheckInID: String, $Status: Status) {
  getMobileCheckIn(MobileCheckInID: $MobileCheckInID, Status: $Status) {
    Name
    Lat
    Long
    Radius
    Address
    MobileCheckInID
    Company {
      CompanyName
    }
    CompanyID
    InUse
  }
}
    `;

/**
 * __useGetMobileCheckInQuery__
 *
 * To run a query within a React component, call `useGetMobileCheckInQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMobileCheckInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMobileCheckInQuery({
 *   variables: {
 *      MobileCheckInID: // value for 'MobileCheckInID'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useGetMobileCheckInQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMobileCheckInQuery, GetMobileCheckInQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMobileCheckInQuery, GetMobileCheckInQueryVariables>(GetMobileCheckInDocument, baseOptions);
      }
export function useGetMobileCheckInLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMobileCheckInQuery, GetMobileCheckInQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMobileCheckInQuery, GetMobileCheckInQueryVariables>(GetMobileCheckInDocument, baseOptions);
        }
export type GetMobileCheckInQueryHookResult = ReturnType<typeof useGetMobileCheckInQuery>;
export type GetMobileCheckInLazyQueryHookResult = ReturnType<typeof useGetMobileCheckInLazyQuery>;
export type GetMobileCheckInQueryResult = ApolloReactCommon.QueryResult<GetMobileCheckInQuery, GetMobileCheckInQueryVariables>;
export const InsertUpdateLocationRadiusSettingDocument = gql`
    mutation insertUpdateLocationRadiusSetting($Radius: Float!, $MobileCheckInID: String) {
  InsertUpdateLocationRadiusSetting(Radius: $Radius, MobileCheckInID: $MobileCheckInID)
}
    `;
export type InsertUpdateLocationRadiusSettingMutationFn = ApolloReactCommon.MutationFunction<InsertUpdateLocationRadiusSettingMutation, InsertUpdateLocationRadiusSettingMutationVariables>;

/**
 * __useInsertUpdateLocationRadiusSettingMutation__
 *
 * To run a mutation, you first call `useInsertUpdateLocationRadiusSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUpdateLocationRadiusSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUpdateLocationRadiusSettingMutation, { data, loading, error }] = useInsertUpdateLocationRadiusSettingMutation({
 *   variables: {
 *      Radius: // value for 'Radius'
 *      MobileCheckInID: // value for 'MobileCheckInID'
 *   },
 * });
 */
export function useInsertUpdateLocationRadiusSettingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUpdateLocationRadiusSettingMutation, InsertUpdateLocationRadiusSettingMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUpdateLocationRadiusSettingMutation, InsertUpdateLocationRadiusSettingMutationVariables>(InsertUpdateLocationRadiusSettingDocument, baseOptions);
      }
export type InsertUpdateLocationRadiusSettingMutationHookResult = ReturnType<typeof useInsertUpdateLocationRadiusSettingMutation>;
export type InsertUpdateLocationRadiusSettingMutationResult = ApolloReactCommon.MutationResult<InsertUpdateLocationRadiusSettingMutation>;
export type InsertUpdateLocationRadiusSettingMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUpdateLocationRadiusSettingMutation, InsertUpdateLocationRadiusSettingMutationVariables>;
export const GetLocationRadiusSettingDocument = gql`
    query getLocationRadiusSetting {
  GetLocationRadiusSetting {
    SubscriptionAccountID
    MobileCheckInID
    IsByLocation
    Radius
  }
}
    `;

/**
 * __useGetLocationRadiusSettingQuery__
 *
 * To run a query within a React component, call `useGetLocationRadiusSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationRadiusSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationRadiusSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationRadiusSettingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationRadiusSettingQuery, GetLocationRadiusSettingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLocationRadiusSettingQuery, GetLocationRadiusSettingQueryVariables>(GetLocationRadiusSettingDocument, baseOptions);
      }
export function useGetLocationRadiusSettingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationRadiusSettingQuery, GetLocationRadiusSettingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLocationRadiusSettingQuery, GetLocationRadiusSettingQueryVariables>(GetLocationRadiusSettingDocument, baseOptions);
        }
export type GetLocationRadiusSettingQueryHookResult = ReturnType<typeof useGetLocationRadiusSettingQuery>;
export type GetLocationRadiusSettingLazyQueryHookResult = ReturnType<typeof useGetLocationRadiusSettingLazyQuery>;
export type GetLocationRadiusSettingQueryResult = ApolloReactCommon.QueryResult<GetLocationRadiusSettingQuery, GetLocationRadiusSettingQueryVariables>;
export const CreateMobileCheckInDocument = gql`
    mutation createMobileCheckIn($input: MobileCheckInInput!) {
  createMobileCheckIn(input: $input)
}
    `;
export type CreateMobileCheckInMutationFn = ApolloReactCommon.MutationFunction<CreateMobileCheckInMutation, CreateMobileCheckInMutationVariables>;

/**
 * __useCreateMobileCheckInMutation__
 *
 * To run a mutation, you first call `useCreateMobileCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMobileCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMobileCheckInMutation, { data, loading, error }] = useCreateMobileCheckInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMobileCheckInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMobileCheckInMutation, CreateMobileCheckInMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMobileCheckInMutation, CreateMobileCheckInMutationVariables>(CreateMobileCheckInDocument, baseOptions);
      }
export type CreateMobileCheckInMutationHookResult = ReturnType<typeof useCreateMobileCheckInMutation>;
export type CreateMobileCheckInMutationResult = ApolloReactCommon.MutationResult<CreateMobileCheckInMutation>;
export type CreateMobileCheckInMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMobileCheckInMutation, CreateMobileCheckInMutationVariables>;
export const UpdateMobileCheckInDocument = gql`
    mutation updateMobileCheckIn($MobileCheckInID: String!, $input: MobileCheckInInput!) {
  updateMobileCheckIn(MobileCheckInID: $MobileCheckInID, input: $input)
}
    `;
export type UpdateMobileCheckInMutationFn = ApolloReactCommon.MutationFunction<UpdateMobileCheckInMutation, UpdateMobileCheckInMutationVariables>;

/**
 * __useUpdateMobileCheckInMutation__
 *
 * To run a mutation, you first call `useUpdateMobileCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMobileCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMobileCheckInMutation, { data, loading, error }] = useUpdateMobileCheckInMutation({
 *   variables: {
 *      MobileCheckInID: // value for 'MobileCheckInID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMobileCheckInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMobileCheckInMutation, UpdateMobileCheckInMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMobileCheckInMutation, UpdateMobileCheckInMutationVariables>(UpdateMobileCheckInDocument, baseOptions);
      }
export type UpdateMobileCheckInMutationHookResult = ReturnType<typeof useUpdateMobileCheckInMutation>;
export type UpdateMobileCheckInMutationResult = ApolloReactCommon.MutationResult<UpdateMobileCheckInMutation>;
export type UpdateMobileCheckInMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMobileCheckInMutation, UpdateMobileCheckInMutationVariables>;
export const QrListingDocument = gql`
    query QRListing($DocumentID: String!) {
  QRListing(DocumentID: $DocumentID) {
    DocumentID
    DocumentFile
  }
}
    `;

/**
 * __useQrListingQuery__
 *
 * To run a query within a React component, call `useQrListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useQrListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQrListingQuery({
 *   variables: {
 *      DocumentID: // value for 'DocumentID'
 *   },
 * });
 */
export function useQrListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QrListingQuery, QrListingQueryVariables>) {
        return ApolloReactHooks.useQuery<QrListingQuery, QrListingQueryVariables>(QrListingDocument, baseOptions);
      }
export function useQrListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QrListingQuery, QrListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QrListingQuery, QrListingQueryVariables>(QrListingDocument, baseOptions);
        }
export type QrListingQueryHookResult = ReturnType<typeof useQrListingQuery>;
export type QrListingLazyQueryHookResult = ReturnType<typeof useQrListingLazyQuery>;
export type QrListingQueryResult = ApolloReactCommon.QueryResult<QrListingQuery, QrListingQueryVariables>;
export const GetTemplateLibraryAssignmentInfolineDocument = gql`
    query GetTemplateLibraryAssignmentInfoline($CompanyID: String!) {
  GetTemplateLibraryAssignmentInfoline(CompanyID: $CompanyID) {
    ShiftAssignment
    ShiftPatternAssignment
    ShiftGroupAssignment
    DailyAllowanceAssignment
    MonthlyAllowanceAssignment
    DeductionAssignment
    TimeOffAssignment
    OvertimeReason
    TimeOffReason
    MobileCheckInSite
    MobileCheckInRadius
    BluetoothBeaconDevices
  }
}
    `;

/**
 * __useGetTemplateLibraryAssignmentInfolineQuery__
 *
 * To run a query within a React component, call `useGetTemplateLibraryAssignmentInfolineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateLibraryAssignmentInfolineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateLibraryAssignmentInfolineQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetTemplateLibraryAssignmentInfolineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplateLibraryAssignmentInfolineQuery, GetTemplateLibraryAssignmentInfolineQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplateLibraryAssignmentInfolineQuery, GetTemplateLibraryAssignmentInfolineQueryVariables>(GetTemplateLibraryAssignmentInfolineDocument, baseOptions);
      }
export function useGetTemplateLibraryAssignmentInfolineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplateLibraryAssignmentInfolineQuery, GetTemplateLibraryAssignmentInfolineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplateLibraryAssignmentInfolineQuery, GetTemplateLibraryAssignmentInfolineQueryVariables>(GetTemplateLibraryAssignmentInfolineDocument, baseOptions);
        }
export type GetTemplateLibraryAssignmentInfolineQueryHookResult = ReturnType<typeof useGetTemplateLibraryAssignmentInfolineQuery>;
export type GetTemplateLibraryAssignmentInfolineLazyQueryHookResult = ReturnType<typeof useGetTemplateLibraryAssignmentInfolineLazyQuery>;
export type GetTemplateLibraryAssignmentInfolineQueryResult = ApolloReactCommon.QueryResult<GetTemplateLibraryAssignmentInfolineQuery, GetTemplateLibraryAssignmentInfolineQueryVariables>;
export const ReplaceTemplateLibraryAssignmentv2Document = gql`
    mutation replaceTemplateLibraryAssignmentv2($inputs: [TemplateLibraryAssignmentInput!]!) {
  replaceTemplateLibraryAssignmentv2(inputs: $inputs)
}
    `;
export type ReplaceTemplateLibraryAssignmentv2MutationFn = ApolloReactCommon.MutationFunction<ReplaceTemplateLibraryAssignmentv2Mutation, ReplaceTemplateLibraryAssignmentv2MutationVariables>;

/**
 * __useReplaceTemplateLibraryAssignmentv2Mutation__
 *
 * To run a mutation, you first call `useReplaceTemplateLibraryAssignmentv2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplaceTemplateLibraryAssignmentv2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replaceTemplateLibraryAssignmentv2Mutation, { data, loading, error }] = useReplaceTemplateLibraryAssignmentv2Mutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useReplaceTemplateLibraryAssignmentv2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReplaceTemplateLibraryAssignmentv2Mutation, ReplaceTemplateLibraryAssignmentv2MutationVariables>) {
        return ApolloReactHooks.useMutation<ReplaceTemplateLibraryAssignmentv2Mutation, ReplaceTemplateLibraryAssignmentv2MutationVariables>(ReplaceTemplateLibraryAssignmentv2Document, baseOptions);
      }
export type ReplaceTemplateLibraryAssignmentv2MutationHookResult = ReturnType<typeof useReplaceTemplateLibraryAssignmentv2Mutation>;
export type ReplaceTemplateLibraryAssignmentv2MutationResult = ApolloReactCommon.MutationResult<ReplaceTemplateLibraryAssignmentv2Mutation>;
export type ReplaceTemplateLibraryAssignmentv2MutationOptions = ApolloReactCommon.BaseMutationOptions<ReplaceTemplateLibraryAssignmentv2Mutation, ReplaceTemplateLibraryAssignmentv2MutationVariables>;
export const GetTemplateLibraryAssignmentPageDataDocument = gql`
    query GetTemplateLibraryAssignmentPageData($CompanyID: String!, $Status: Status, $input: TemplateLibraryAssignmentInput) {
  CompanySummary(CompanyID: $CompanyID) {
    CompanyID
    CompanyName
  }
  getTemplateLibraryAssignment(input: $input) {
    TargetID
    TargetType
    EnableTarget
    CompanyID
    Status
  }
  getShift(Status: $Status) {
    ShiftID
    Status
    Name
  }
  getShiftPattern(Status: $Status) {
    ShiftPatternID
    Status
    Name
  }
  getShiftGroup(Status: $Status) {
    ShiftGroupID
    Status
    Name
  }
  getDailyAllowance(Status: $Status) {
    DailyAllowanceID
    Status
    Name
  }
  getMonthlyAllowance(Status: $Status) {
    MonthlyAllowanceID
    Status
    Name
  }
  getDeductionPolicy(Status: $Status) {
    DeductionPolicyID
    Status
    Title
  }
  getAllowanceDeduction(Status: $Status) {
    AllowanceDeductionID
    Status
    Name
  }
  TimeOffSettingListing(Status: $Status) {
    Status
    TimeOffSettingID
    Name
  }
  getOTRounding(Status: $Status) {
    OTRoundingID
    Status
    Name
  }
  OTReasonListing(Status: $Status) {
    OvertimeReasonID
    Status
    Name
  }
  TimeOffReasonListing(Status: $Status) {
    TimeOffReasonID
    Status
    Name
  }
  getMobileCheckIn(Status: $Status) {
    MobileCheckInID
    Status
    Name
  }
}
    `;

/**
 * __useGetTemplateLibraryAssignmentPageDataQuery__
 *
 * To run a query within a React component, call `useGetTemplateLibraryAssignmentPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateLibraryAssignmentPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateLibraryAssignmentPageDataQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Status: // value for 'Status'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTemplateLibraryAssignmentPageDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplateLibraryAssignmentPageDataQuery, GetTemplateLibraryAssignmentPageDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplateLibraryAssignmentPageDataQuery, GetTemplateLibraryAssignmentPageDataQueryVariables>(GetTemplateLibraryAssignmentPageDataDocument, baseOptions);
      }
export function useGetTemplateLibraryAssignmentPageDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplateLibraryAssignmentPageDataQuery, GetTemplateLibraryAssignmentPageDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplateLibraryAssignmentPageDataQuery, GetTemplateLibraryAssignmentPageDataQueryVariables>(GetTemplateLibraryAssignmentPageDataDocument, baseOptions);
        }
export type GetTemplateLibraryAssignmentPageDataQueryHookResult = ReturnType<typeof useGetTemplateLibraryAssignmentPageDataQuery>;
export type GetTemplateLibraryAssignmentPageDataLazyQueryHookResult = ReturnType<typeof useGetTemplateLibraryAssignmentPageDataLazyQuery>;
export type GetTemplateLibraryAssignmentPageDataQueryResult = ApolloReactCommon.QueryResult<GetTemplateLibraryAssignmentPageDataQuery, GetTemplateLibraryAssignmentPageDataQueryVariables>;
export const GetJobGradePolicyMatrixPageDataDocument = gql`
    query GetJobGradePolicyMatrixPageData($CompanyID: String!, $Status: Status, $input: TemplateLibraryAssignmentInput) {
  CompanySummary(CompanyID: $CompanyID) {
    CompanyName
  }
  getTemplateLibraryAssignment(input: $input) {
    TargetID
    TargetType
    SourceType
    SourceID
    Module
  }
  getJobGradeByTLA(CompanyID: $CompanyID)
  getDailyAllowanceByTLA(CompanyID: $CompanyID) {
    DailyAllowanceID
    Name
    Status
  }
  getMonthlyAllowanceByTLA(CompanyID: $CompanyID) {
    MonthlyAllowanceID
    Name
    Status
  }
  getDeductionPolicyByTLA(CompanyID: $CompanyID) {
    DeductionPolicyID
    Title
    Status
  }
  getOvertimeClaimTable(Status: $Status) {
    Status
    OvertimeClaimTableID
    Title
  }
  getOvertimePolicy {
    Status
    OvertimePolicyID
    Title
  }
  getDailyAllowance(Status: $Status) {
    Status
    DailyAllowanceID
    Name
  }
  getMonthlyAllowance(Status: $Status) {
    Status
    MonthlyAllowanceID
    Name
  }
  getDeductionPolicy(Status: $Status) {
    Status
    DeductionPolicyID
    Title
  }
}
    `;

/**
 * __useGetJobGradePolicyMatrixPageDataQuery__
 *
 * To run a query within a React component, call `useGetJobGradePolicyMatrixPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobGradePolicyMatrixPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobGradePolicyMatrixPageDataQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Status: // value for 'Status'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetJobGradePolicyMatrixPageDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetJobGradePolicyMatrixPageDataQuery, GetJobGradePolicyMatrixPageDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetJobGradePolicyMatrixPageDataQuery, GetJobGradePolicyMatrixPageDataQueryVariables>(GetJobGradePolicyMatrixPageDataDocument, baseOptions);
      }
export function useGetJobGradePolicyMatrixPageDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetJobGradePolicyMatrixPageDataQuery, GetJobGradePolicyMatrixPageDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetJobGradePolicyMatrixPageDataQuery, GetJobGradePolicyMatrixPageDataQueryVariables>(GetJobGradePolicyMatrixPageDataDocument, baseOptions);
        }
export type GetJobGradePolicyMatrixPageDataQueryHookResult = ReturnType<typeof useGetJobGradePolicyMatrixPageDataQuery>;
export type GetJobGradePolicyMatrixPageDataLazyQueryHookResult = ReturnType<typeof useGetJobGradePolicyMatrixPageDataLazyQuery>;
export type GetJobGradePolicyMatrixPageDataQueryResult = ApolloReactCommon.QueryResult<GetJobGradePolicyMatrixPageDataQuery, GetJobGradePolicyMatrixPageDataQueryVariables>;
export const JobGradeByTlaDocument = gql`
    query JobGradeByTLA($CompanyID: String!) {
  getJobGradeByTLA(CompanyID: $CompanyID)
}
    `;

/**
 * __useJobGradeByTlaQuery__
 *
 * To run a query within a React component, call `useJobGradeByTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobGradeByTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobGradeByTlaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useJobGradeByTlaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JobGradeByTlaQuery, JobGradeByTlaQueryVariables>) {
        return ApolloReactHooks.useQuery<JobGradeByTlaQuery, JobGradeByTlaQueryVariables>(JobGradeByTlaDocument, baseOptions);
      }
export function useJobGradeByTlaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JobGradeByTlaQuery, JobGradeByTlaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<JobGradeByTlaQuery, JobGradeByTlaQueryVariables>(JobGradeByTlaDocument, baseOptions);
        }
export type JobGradeByTlaQueryHookResult = ReturnType<typeof useJobGradeByTlaQuery>;
export type JobGradeByTlaLazyQueryHookResult = ReturnType<typeof useJobGradeByTlaLazyQuery>;
export type JobGradeByTlaQueryResult = ApolloReactCommon.QueryResult<JobGradeByTlaQuery, JobGradeByTlaQueryVariables>;
export const GetOvertimeSettingPageDataDocument = gql`
    query GetOvertimeSettingPageData($CompanyID: String!, $Status: Status, $input: TemplateLibraryAssignmentInput) {
  CompanySummary(CompanyID: $CompanyID) {
    CompanyID
    CompanyName
    OvertimeCutOffDay
  }
  getTemplateLibraryAssignment(input: $input) {
    TargetID
    TargetType
    EnableTarget
    CompanyID
    Status
  }
  getOTRounding(Status: $Status) {
    OTRoundingID
    Status
    Name
  }
}
    `;

/**
 * __useGetOvertimeSettingPageDataQuery__
 *
 * To run a query within a React component, call `useGetOvertimeSettingPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOvertimeSettingPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOvertimeSettingPageDataQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Status: // value for 'Status'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOvertimeSettingPageDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOvertimeSettingPageDataQuery, GetOvertimeSettingPageDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOvertimeSettingPageDataQuery, GetOvertimeSettingPageDataQueryVariables>(GetOvertimeSettingPageDataDocument, baseOptions);
      }
export function useGetOvertimeSettingPageDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOvertimeSettingPageDataQuery, GetOvertimeSettingPageDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOvertimeSettingPageDataQuery, GetOvertimeSettingPageDataQueryVariables>(GetOvertimeSettingPageDataDocument, baseOptions);
        }
export type GetOvertimeSettingPageDataQueryHookResult = ReturnType<typeof useGetOvertimeSettingPageDataQuery>;
export type GetOvertimeSettingPageDataLazyQueryHookResult = ReturnType<typeof useGetOvertimeSettingPageDataLazyQuery>;
export type GetOvertimeSettingPageDataQueryResult = ApolloReactCommon.QueryResult<GetOvertimeSettingPageDataQuery, GetOvertimeSettingPageDataQueryVariables>;
export const OvertimeSettingUpdateDocument = gql`
    mutation OvertimeSettingUpdate($inputs: [TemplateLibraryAssignmentInput!]!, $CompanyID: String, $CompanyInput: CompanyInput) {
  OvertimeSettingUpdate(inputs: $inputs, CompanyID: $CompanyID, CompanyInput: $CompanyInput)
}
    `;
export type OvertimeSettingUpdateMutationFn = ApolloReactCommon.MutationFunction<OvertimeSettingUpdateMutation, OvertimeSettingUpdateMutationVariables>;

/**
 * __useOvertimeSettingUpdateMutation__
 *
 * To run a mutation, you first call `useOvertimeSettingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOvertimeSettingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overtimeSettingUpdateMutation, { data, loading, error }] = useOvertimeSettingUpdateMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *      CompanyID: // value for 'CompanyID'
 *      CompanyInput: // value for 'CompanyInput'
 *   },
 * });
 */
export function useOvertimeSettingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OvertimeSettingUpdateMutation, OvertimeSettingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OvertimeSettingUpdateMutation, OvertimeSettingUpdateMutationVariables>(OvertimeSettingUpdateDocument, baseOptions);
      }
export type OvertimeSettingUpdateMutationHookResult = ReturnType<typeof useOvertimeSettingUpdateMutation>;
export type OvertimeSettingUpdateMutationResult = ApolloReactCommon.MutationResult<OvertimeSettingUpdateMutation>;
export type OvertimeSettingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OvertimeSettingUpdateMutation, OvertimeSettingUpdateMutationVariables>;
export const EmployeeShiftGroupDocument = gql`
    query EmployeeShiftGroup($EmployeeID: String!, $StartDate: String!, $EndDate: String!) {
  EmployeeShiftGroup(EmployeeID: $EmployeeID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useEmployeeShiftGroupQuery__
 *
 * To run a query within a React component, call `useEmployeeShiftGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeShiftGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeShiftGroupQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useEmployeeShiftGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmployeeShiftGroupQuery, EmployeeShiftGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<EmployeeShiftGroupQuery, EmployeeShiftGroupQueryVariables>(EmployeeShiftGroupDocument, baseOptions);
      }
export function useEmployeeShiftGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmployeeShiftGroupQuery, EmployeeShiftGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmployeeShiftGroupQuery, EmployeeShiftGroupQueryVariables>(EmployeeShiftGroupDocument, baseOptions);
        }
export type EmployeeShiftGroupQueryHookResult = ReturnType<typeof useEmployeeShiftGroupQuery>;
export type EmployeeShiftGroupLazyQueryHookResult = ReturnType<typeof useEmployeeShiftGroupLazyQuery>;
export type EmployeeShiftGroupQueryResult = ApolloReactCommon.QueryResult<EmployeeShiftGroupQuery, EmployeeShiftGroupQueryVariables>;
export const GetBeaconDevicesListingDocument = gql`
    query GetBeaconDevicesListing($BluetoothBeaconDevicesID: String) {
  GetBeaconDevicesListing(BluetoothBeaconDevicesID: $BluetoothBeaconDevicesID) {
    BluetoothBeaconDevicesID
    DeviceAddress
    DeviceName
    LocationName
    Status
    InUse
  }
}
    `;

/**
 * __useGetBeaconDevicesListingQuery__
 *
 * To run a query within a React component, call `useGetBeaconDevicesListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBeaconDevicesListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBeaconDevicesListingQuery({
 *   variables: {
 *      BluetoothBeaconDevicesID: // value for 'BluetoothBeaconDevicesID'
 *   },
 * });
 */
export function useGetBeaconDevicesListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBeaconDevicesListingQuery, GetBeaconDevicesListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBeaconDevicesListingQuery, GetBeaconDevicesListingQueryVariables>(GetBeaconDevicesListingDocument, baseOptions);
      }
export function useGetBeaconDevicesListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBeaconDevicesListingQuery, GetBeaconDevicesListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBeaconDevicesListingQuery, GetBeaconDevicesListingQueryVariables>(GetBeaconDevicesListingDocument, baseOptions);
        }
export type GetBeaconDevicesListingQueryHookResult = ReturnType<typeof useGetBeaconDevicesListingQuery>;
export type GetBeaconDevicesListingLazyQueryHookResult = ReturnType<typeof useGetBeaconDevicesListingLazyQuery>;
export type GetBeaconDevicesListingQueryResult = ApolloReactCommon.QueryResult<GetBeaconDevicesListingQuery, GetBeaconDevicesListingQueryVariables>;
export const CreateUpdateBeaconDeviceDocument = gql`
    mutation CreateUpdateBeaconDevice($input: BluetoothBeaconDevicesInput!) {
  CreateUpdateBeaconDevice(input: $input)
}
    `;
export type CreateUpdateBeaconDeviceMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateBeaconDeviceMutation, CreateUpdateBeaconDeviceMutationVariables>;

/**
 * __useCreateUpdateBeaconDeviceMutation__
 *
 * To run a mutation, you first call `useCreateUpdateBeaconDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateBeaconDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateBeaconDeviceMutation, { data, loading, error }] = useCreateUpdateBeaconDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateBeaconDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateBeaconDeviceMutation, CreateUpdateBeaconDeviceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateBeaconDeviceMutation, CreateUpdateBeaconDeviceMutationVariables>(CreateUpdateBeaconDeviceDocument, baseOptions);
      }
export type CreateUpdateBeaconDeviceMutationHookResult = ReturnType<typeof useCreateUpdateBeaconDeviceMutation>;
export type CreateUpdateBeaconDeviceMutationResult = ApolloReactCommon.MutationResult<CreateUpdateBeaconDeviceMutation>;
export type CreateUpdateBeaconDeviceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateBeaconDeviceMutation, CreateUpdateBeaconDeviceMutationVariables>;
export const GetAutomatedClockDataImportListingDocument = gql`
    query GetAutomatedClockDataImportListing($AutomatedClockDataImportID: String) {
  GetAutomatedClockDataImportListing(AutomatedClockDataImportID: $AutomatedClockDataImportID)
}
    `;

/**
 * __useGetAutomatedClockDataImportListingQuery__
 *
 * To run a query within a React component, call `useGetAutomatedClockDataImportListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutomatedClockDataImportListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutomatedClockDataImportListingQuery({
 *   variables: {
 *      AutomatedClockDataImportID: // value for 'AutomatedClockDataImportID'
 *   },
 * });
 */
export function useGetAutomatedClockDataImportListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAutomatedClockDataImportListingQuery, GetAutomatedClockDataImportListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAutomatedClockDataImportListingQuery, GetAutomatedClockDataImportListingQueryVariables>(GetAutomatedClockDataImportListingDocument, baseOptions);
      }
export function useGetAutomatedClockDataImportListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAutomatedClockDataImportListingQuery, GetAutomatedClockDataImportListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAutomatedClockDataImportListingQuery, GetAutomatedClockDataImportListingQueryVariables>(GetAutomatedClockDataImportListingDocument, baseOptions);
        }
export type GetAutomatedClockDataImportListingQueryHookResult = ReturnType<typeof useGetAutomatedClockDataImportListingQuery>;
export type GetAutomatedClockDataImportListingLazyQueryHookResult = ReturnType<typeof useGetAutomatedClockDataImportListingLazyQuery>;
export type GetAutomatedClockDataImportListingQueryResult = ApolloReactCommon.QueryResult<GetAutomatedClockDataImportListingQuery, GetAutomatedClockDataImportListingQueryVariables>;
export const DeleteAutomatedClockDataImportDocument = gql`
    mutation DeleteAutomatedClockDataImport($AutomatedClockDataImportID: String!) {
  DeleteAutomatedClockDataImport(AutomatedClockDataImportID: $AutomatedClockDataImportID)
}
    `;
export type DeleteAutomatedClockDataImportMutationFn = ApolloReactCommon.MutationFunction<DeleteAutomatedClockDataImportMutation, DeleteAutomatedClockDataImportMutationVariables>;

/**
 * __useDeleteAutomatedClockDataImportMutation__
 *
 * To run a mutation, you first call `useDeleteAutomatedClockDataImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAutomatedClockDataImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAutomatedClockDataImportMutation, { data, loading, error }] = useDeleteAutomatedClockDataImportMutation({
 *   variables: {
 *      AutomatedClockDataImportID: // value for 'AutomatedClockDataImportID'
 *   },
 * });
 */
export function useDeleteAutomatedClockDataImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAutomatedClockDataImportMutation, DeleteAutomatedClockDataImportMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAutomatedClockDataImportMutation, DeleteAutomatedClockDataImportMutationVariables>(DeleteAutomatedClockDataImportDocument, baseOptions);
      }
export type DeleteAutomatedClockDataImportMutationHookResult = ReturnType<typeof useDeleteAutomatedClockDataImportMutation>;
export type DeleteAutomatedClockDataImportMutationResult = ApolloReactCommon.MutationResult<DeleteAutomatedClockDataImportMutation>;
export type DeleteAutomatedClockDataImportMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAutomatedClockDataImportMutation, DeleteAutomatedClockDataImportMutationVariables>;
export const CreateUpdateAutomatedClockDataImportDocument = gql`
    mutation CreateUpdateAutomatedClockDataImport($input: AutomatedClockDataImportInput!) {
  CreateUpdateAutomatedClockDataImport(input: $input)
}
    `;
export type CreateUpdateAutomatedClockDataImportMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateAutomatedClockDataImportMutation, CreateUpdateAutomatedClockDataImportMutationVariables>;

/**
 * __useCreateUpdateAutomatedClockDataImportMutation__
 *
 * To run a mutation, you first call `useCreateUpdateAutomatedClockDataImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateAutomatedClockDataImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateAutomatedClockDataImportMutation, { data, loading, error }] = useCreateUpdateAutomatedClockDataImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUpdateAutomatedClockDataImportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateAutomatedClockDataImportMutation, CreateUpdateAutomatedClockDataImportMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateAutomatedClockDataImportMutation, CreateUpdateAutomatedClockDataImportMutationVariables>(CreateUpdateAutomatedClockDataImportDocument, baseOptions);
      }
export type CreateUpdateAutomatedClockDataImportMutationHookResult = ReturnType<typeof useCreateUpdateAutomatedClockDataImportMutation>;
export type CreateUpdateAutomatedClockDataImportMutationResult = ApolloReactCommon.MutationResult<CreateUpdateAutomatedClockDataImportMutation>;
export type CreateUpdateAutomatedClockDataImportMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateAutomatedClockDataImportMutation, CreateUpdateAutomatedClockDataImportMutationVariables>;
export const TimeOffListingDocument = gql`
    query TimeOffListing($CompanyID: String!, $EmployeeID: String) {
  TimeOffListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID) {
    TimeOffID
    SubscriptionAccountID
    CompanyID
    EmployeeID
    Employee {
      EmployeeID
      EmployeeNo
      ContactID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ToReasonID
    WorkDate
    Remark
    VisitingPlace
    TimeOffStatus
    EndTime
    StartTime
    TravelFrom
    TravelTo
    DocumentID
    Document {
      DocumentID
      DocumentFile
      Description
    }
    Reason
    Approver
    ApproveDate
    RejectionRemarks
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        NotifiedDT
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
          ApproverName
        }
      }
    }
  }
}
    `;

/**
 * __useTimeOffListingQuery__
 *
 * To run a query within a React component, call `useTimeOffListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useTimeOffListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimeOffListingQuery, TimeOffListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TimeOffListingQuery, TimeOffListingQueryVariables>(TimeOffListingDocument, baseOptions);
      }
export function useTimeOffListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeOffListingQuery, TimeOffListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimeOffListingQuery, TimeOffListingQueryVariables>(TimeOffListingDocument, baseOptions);
        }
export type TimeOffListingQueryHookResult = ReturnType<typeof useTimeOffListingQuery>;
export type TimeOffListingLazyQueryHookResult = ReturnType<typeof useTimeOffListingLazyQuery>;
export type TimeOffListingQueryResult = ApolloReactCommon.QueryResult<TimeOffListingQuery, TimeOffListingQueryVariables>;
export const TimeOffReasonListingDocument = gql`
    query TimeOffReasonListing {
  TimeOffReasonListing {
    TimeOffReasonID
    Name
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __useTimeOffReasonListingQuery__
 *
 * To run a query within a React component, call `useTimeOffReasonListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffReasonListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffReasonListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeOffReasonListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimeOffReasonListingQuery, TimeOffReasonListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TimeOffReasonListingQuery, TimeOffReasonListingQueryVariables>(TimeOffReasonListingDocument, baseOptions);
      }
export function useTimeOffReasonListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeOffReasonListingQuery, TimeOffReasonListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimeOffReasonListingQuery, TimeOffReasonListingQueryVariables>(TimeOffReasonListingDocument, baseOptions);
        }
export type TimeOffReasonListingQueryHookResult = ReturnType<typeof useTimeOffReasonListingQuery>;
export type TimeOffReasonListingLazyQueryHookResult = ReturnType<typeof useTimeOffReasonListingLazyQuery>;
export type TimeOffReasonListingQueryResult = ApolloReactCommon.QueryResult<TimeOffReasonListingQuery, TimeOffReasonListingQueryVariables>;
export const TimeOffReasonByTlaDocument = gql`
    query TimeOffReasonByTLA($CompanyID: String!) {
  TimeOffReasonByTLA(CompanyID: $CompanyID) {
    TimeOffReasonID
    Name
    ModifiedDT
    CreatedDT
  }
}
    `;

/**
 * __useTimeOffReasonByTlaQuery__
 *
 * To run a query within a React component, call `useTimeOffReasonByTlaQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeOffReasonByTlaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeOffReasonByTlaQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useTimeOffReasonByTlaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimeOffReasonByTlaQuery, TimeOffReasonByTlaQueryVariables>) {
        return ApolloReactHooks.useQuery<TimeOffReasonByTlaQuery, TimeOffReasonByTlaQueryVariables>(TimeOffReasonByTlaDocument, baseOptions);
      }
export function useTimeOffReasonByTlaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeOffReasonByTlaQuery, TimeOffReasonByTlaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimeOffReasonByTlaQuery, TimeOffReasonByTlaQueryVariables>(TimeOffReasonByTlaDocument, baseOptions);
        }
export type TimeOffReasonByTlaQueryHookResult = ReturnType<typeof useTimeOffReasonByTlaQuery>;
export type TimeOffReasonByTlaLazyQueryHookResult = ReturnType<typeof useTimeOffReasonByTlaLazyQuery>;
export type TimeOffReasonByTlaQueryResult = ApolloReactCommon.QueryResult<TimeOffReasonByTlaQuery, TimeOffReasonByTlaQueryVariables>;
export const TimeOffInsertDocument = gql`
    mutation TimeOffInsert($input: TimeOffInput!) {
  TimeOffInsert(input: $input) {
    TimeOffID
    TimeOffStatus
  }
}
    `;
export type TimeOffInsertMutationFn = ApolloReactCommon.MutationFunction<TimeOffInsertMutation, TimeOffInsertMutationVariables>;

/**
 * __useTimeOffInsertMutation__
 *
 * To run a mutation, you first call `useTimeOffInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffInsertMutation, { data, loading, error }] = useTimeOffInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeOffInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TimeOffInsertMutation, TimeOffInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TimeOffInsertMutation, TimeOffInsertMutationVariables>(TimeOffInsertDocument, baseOptions);
      }
export type TimeOffInsertMutationHookResult = ReturnType<typeof useTimeOffInsertMutation>;
export type TimeOffInsertMutationResult = ApolloReactCommon.MutationResult<TimeOffInsertMutation>;
export type TimeOffInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TimeOffInsertMutation, TimeOffInsertMutationVariables>;
export const TimeOffUpdateDocument = gql`
    mutation TimeOffUpdate($input: [TimeOffInput!]!, $ActionStatus: String, $IsSupersede: Boolean) {
  TimeOffUpdate(input: $input, ActionStatus: $ActionStatus, IsSupersede: $IsSupersede)
}
    `;
export type TimeOffUpdateMutationFn = ApolloReactCommon.MutationFunction<TimeOffUpdateMutation, TimeOffUpdateMutationVariables>;

/**
 * __useTimeOffUpdateMutation__
 *
 * To run a mutation, you first call `useTimeOffUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffUpdateMutation, { data, loading, error }] = useTimeOffUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ActionStatus: // value for 'ActionStatus'
 *      IsSupersede: // value for 'IsSupersede'
 *   },
 * });
 */
export function useTimeOffUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TimeOffUpdateMutation, TimeOffUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TimeOffUpdateMutation, TimeOffUpdateMutationVariables>(TimeOffUpdateDocument, baseOptions);
      }
export type TimeOffUpdateMutationHookResult = ReturnType<typeof useTimeOffUpdateMutation>;
export type TimeOffUpdateMutationResult = ApolloReactCommon.MutationResult<TimeOffUpdateMutation>;
export type TimeOffUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TimeOffUpdateMutation, TimeOffUpdateMutationVariables>;
export const TimeOffDeleteDocument = gql`
    mutation TimeOffDelete($TimeOffID: String!) {
  TimeOffDelete(TimeOffID: $TimeOffID)
}
    `;
export type TimeOffDeleteMutationFn = ApolloReactCommon.MutationFunction<TimeOffDeleteMutation, TimeOffDeleteMutationVariables>;

/**
 * __useTimeOffDeleteMutation__
 *
 * To run a mutation, you first call `useTimeOffDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimeOffDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timeOffDeleteMutation, { data, loading, error }] = useTimeOffDeleteMutation({
 *   variables: {
 *      TimeOffID: // value for 'TimeOffID'
 *   },
 * });
 */
export function useTimeOffDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TimeOffDeleteMutation, TimeOffDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TimeOffDeleteMutation, TimeOffDeleteMutationVariables>(TimeOffDeleteDocument, baseOptions);
      }
export type TimeOffDeleteMutationHookResult = ReturnType<typeof useTimeOffDeleteMutation>;
export type TimeOffDeleteMutationResult = ApolloReactCommon.MutationResult<TimeOffDeleteMutation>;
export type TimeOffDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TimeOffDeleteMutation, TimeOffDeleteMutationVariables>;
export const TsAccountTypeListDocument = gql`
    query TsAccountTypeList($CompanyID: String!, $SubscriptionAccountID: String!) {
  TsAccountTypeList(CompanyID: $CompanyID, SubscriptionAccountID: $SubscriptionAccountID) {
    ID
    Code
    Name
    ActivityList {
      Description
    }
    TsAccountCode {
      ID
      Name
    }
  }
}
    `;

/**
 * __useTsAccountTypeListQuery__
 *
 * To run a query within a React component, call `useTsAccountTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTsAccountTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTsAccountTypeListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useTsAccountTypeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TsAccountTypeListQuery, TsAccountTypeListQueryVariables>) {
        return ApolloReactHooks.useQuery<TsAccountTypeListQuery, TsAccountTypeListQueryVariables>(TsAccountTypeListDocument, baseOptions);
      }
export function useTsAccountTypeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TsAccountTypeListQuery, TsAccountTypeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TsAccountTypeListQuery, TsAccountTypeListQueryVariables>(TsAccountTypeListDocument, baseOptions);
        }
export type TsAccountTypeListQueryHookResult = ReturnType<typeof useTsAccountTypeListQuery>;
export type TsAccountTypeListLazyQueryHookResult = ReturnType<typeof useTsAccountTypeListLazyQuery>;
export type TsAccountTypeListQueryResult = ApolloReactCommon.QueryResult<TsAccountTypeListQuery, TsAccountTypeListQueryVariables>;
export const StaffTimeSheetInsertDocument = gql`
    mutation StaffTimeSheetInsert($StaffTimesheetInput: StaffTimesheetInput!) {
  StaffTimeSheetInsert(StaffTimesheetInput: $StaffTimesheetInput)
}
    `;
export type StaffTimeSheetInsertMutationFn = ApolloReactCommon.MutationFunction<StaffTimeSheetInsertMutation, StaffTimeSheetInsertMutationVariables>;

/**
 * __useStaffTimeSheetInsertMutation__
 *
 * To run a mutation, you first call `useStaffTimeSheetInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffTimeSheetInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffTimeSheetInsertMutation, { data, loading, error }] = useStaffTimeSheetInsertMutation({
 *   variables: {
 *      StaffTimesheetInput: // value for 'StaffTimesheetInput'
 *   },
 * });
 */
export function useStaffTimeSheetInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffTimeSheetInsertMutation, StaffTimeSheetInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffTimeSheetInsertMutation, StaffTimeSheetInsertMutationVariables>(StaffTimeSheetInsertDocument, baseOptions);
      }
export type StaffTimeSheetInsertMutationHookResult = ReturnType<typeof useStaffTimeSheetInsertMutation>;
export type StaffTimeSheetInsertMutationResult = ApolloReactCommon.MutationResult<StaffTimeSheetInsertMutation>;
export type StaffTimeSheetInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffTimeSheetInsertMutation, StaffTimeSheetInsertMutationVariables>;
export const StaffTimesheetDeleteDocument = gql`
    mutation StaffTimesheetDelete($ID: String!) {
  StaffTimesheetDelete(ID: $ID)
}
    `;
export type StaffTimesheetDeleteMutationFn = ApolloReactCommon.MutationFunction<StaffTimesheetDeleteMutation, StaffTimesheetDeleteMutationVariables>;

/**
 * __useStaffTimesheetDeleteMutation__
 *
 * To run a mutation, you first call `useStaffTimesheetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffTimesheetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffTimesheetDeleteMutation, { data, loading, error }] = useStaffTimesheetDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useStaffTimesheetDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StaffTimesheetDeleteMutation, StaffTimesheetDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<StaffTimesheetDeleteMutation, StaffTimesheetDeleteMutationVariables>(StaffTimesheetDeleteDocument, baseOptions);
      }
export type StaffTimesheetDeleteMutationHookResult = ReturnType<typeof useStaffTimesheetDeleteMutation>;
export type StaffTimesheetDeleteMutationResult = ApolloReactCommon.MutationResult<StaffTimesheetDeleteMutation>;
export type StaffTimesheetDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<StaffTimesheetDeleteMutation, StaffTimesheetDeleteMutationVariables>;
export const TimesheetDatesDocument = gql`
    query TimesheetDates($CompanyID: String!, $EmployeeID: String, $sheetDate: DateTime!) {
  TimesheetDates(CompanyID: $CompanyID, EmployeeID: $EmployeeID, sheetDate: $sheetDate)
}
    `;

/**
 * __useTimesheetDatesQuery__
 *
 * To run a query within a React component, call `useTimesheetDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetDatesQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      sheetDate: // value for 'sheetDate'
 *   },
 * });
 */
export function useTimesheetDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimesheetDatesQuery, TimesheetDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<TimesheetDatesQuery, TimesheetDatesQueryVariables>(TimesheetDatesDocument, baseOptions);
      }
export function useTimesheetDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimesheetDatesQuery, TimesheetDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimesheetDatesQuery, TimesheetDatesQueryVariables>(TimesheetDatesDocument, baseOptions);
        }
export type TimesheetDatesQueryHookResult = ReturnType<typeof useTimesheetDatesQuery>;
export type TimesheetDatesLazyQueryHookResult = ReturnType<typeof useTimesheetDatesLazyQuery>;
export type TimesheetDatesQueryResult = ApolloReactCommon.QueryResult<TimesheetDatesQuery, TimesheetDatesQueryVariables>;
export const StaffTimesheetListDocument = gql`
    query StaffTimesheetList($CompanyID: String!, $EmployeeID: String!, $Date: DateTime, $FromDate: DateTime) {
  StaffTimesheetList(CompanyID: $CompanyID, EmployeeID: $EmployeeID, Date: $Date, FromDate: $FromDate) {
    ID
    EmployeeID
    CompanyID
    Date
    TsAccountTypeID
    TsAccountCodeID
    Remark
    Status
    TsAccountCode {
      Name
    }
    TsAccountType {
      Name
      Code
    }
    StaffTimesheetDetail {
      Activity
      Hour
      Other
    }
  }
}
    `;

/**
 * __useStaffTimesheetListQuery__
 *
 * To run a query within a React component, call `useStaffTimesheetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffTimesheetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffTimesheetListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      Date: // value for 'Date'
 *      FromDate: // value for 'FromDate'
 *   },
 * });
 */
export function useStaffTimesheetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StaffTimesheetListQuery, StaffTimesheetListQueryVariables>) {
        return ApolloReactHooks.useQuery<StaffTimesheetListQuery, StaffTimesheetListQueryVariables>(StaffTimesheetListDocument, baseOptions);
      }
export function useStaffTimesheetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StaffTimesheetListQuery, StaffTimesheetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StaffTimesheetListQuery, StaffTimesheetListQueryVariables>(StaffTimesheetListDocument, baseOptions);
        }
export type StaffTimesheetListQueryHookResult = ReturnType<typeof useStaffTimesheetListQuery>;
export type StaffTimesheetListLazyQueryHookResult = ReturnType<typeof useStaffTimesheetListLazyQuery>;
export type StaffTimesheetListQueryResult = ApolloReactCommon.QueryResult<StaffTimesheetListQuery, StaffTimesheetListQueryVariables>;
export const TsAccountCodeListDocument = gql`
    query TsAccountCodeList($CompanyID: String!, $SubscriptionAccountID: String!, $AccountTypeID: String) {
  TsAccountCodeList(CompanyID: $CompanyID, SubscriptionAccountID: $SubscriptionAccountID, AccountTypeID: $AccountTypeID) {
    ID
    Name
    TsAccountTypeID
    Status
    TsAccountType {
      ID
      Code
      Name
    }
  }
}
    `;

/**
 * __useTsAccountCodeListQuery__
 *
 * To run a query within a React component, call `useTsAccountCodeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTsAccountCodeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTsAccountCodeListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *      AccountTypeID: // value for 'AccountTypeID'
 *   },
 * });
 */
export function useTsAccountCodeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TsAccountCodeListQuery, TsAccountCodeListQueryVariables>) {
        return ApolloReactHooks.useQuery<TsAccountCodeListQuery, TsAccountCodeListQueryVariables>(TsAccountCodeListDocument, baseOptions);
      }
export function useTsAccountCodeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TsAccountCodeListQuery, TsAccountCodeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TsAccountCodeListQuery, TsAccountCodeListQueryVariables>(TsAccountCodeListDocument, baseOptions);
        }
export type TsAccountCodeListQueryHookResult = ReturnType<typeof useTsAccountCodeListQuery>;
export type TsAccountCodeListLazyQueryHookResult = ReturnType<typeof useTsAccountCodeListLazyQuery>;
export type TsAccountCodeListQueryResult = ApolloReactCommon.QueryResult<TsAccountCodeListQuery, TsAccountCodeListQueryVariables>;
export const TimesheetSummaryDocument = gql`
    query TimesheetSummary($CompanyID: String!) {
  TimesheetSummary(CompanyID: $CompanyID)
}
    `;

/**
 * __useTimesheetSummaryQuery__
 *
 * To run a query within a React component, call `useTimesheetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useTimesheetSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimesheetSummaryQuery, TimesheetSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<TimesheetSummaryQuery, TimesheetSummaryQueryVariables>(TimesheetSummaryDocument, baseOptions);
      }
export function useTimesheetSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimesheetSummaryQuery, TimesheetSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimesheetSummaryQuery, TimesheetSummaryQueryVariables>(TimesheetSummaryDocument, baseOptions);
        }
export type TimesheetSummaryQueryHookResult = ReturnType<typeof useTimesheetSummaryQuery>;
export type TimesheetSummaryLazyQueryHookResult = ReturnType<typeof useTimesheetSummaryLazyQuery>;
export type TimesheetSummaryQueryResult = ApolloReactCommon.QueryResult<TimesheetSummaryQuery, TimesheetSummaryQueryVariables>;
export const TimesheetHoursDocument = gql`
    query TimesheetHours($CompanyID: String!, $EmployeeID: String, $SheetDate: DateTime!) {
  TimesheetHours(CompanyID: $CompanyID, EmployeeID: $EmployeeID, SheetDate: $SheetDate)
}
    `;

/**
 * __useTimesheetHoursQuery__
 *
 * To run a query within a React component, call `useTimesheetHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetHoursQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      SheetDate: // value for 'SheetDate'
 *   },
 * });
 */
export function useTimesheetHoursQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimesheetHoursQuery, TimesheetHoursQueryVariables>) {
        return ApolloReactHooks.useQuery<TimesheetHoursQuery, TimesheetHoursQueryVariables>(TimesheetHoursDocument, baseOptions);
      }
export function useTimesheetHoursLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimesheetHoursQuery, TimesheetHoursQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimesheetHoursQuery, TimesheetHoursQueryVariables>(TimesheetHoursDocument, baseOptions);
        }
export type TimesheetHoursQueryHookResult = ReturnType<typeof useTimesheetHoursQuery>;
export type TimesheetHoursLazyQueryHookResult = ReturnType<typeof useTimesheetHoursLazyQuery>;
export type TimesheetHoursQueryResult = ApolloReactCommon.QueryResult<TimesheetHoursQuery, TimesheetHoursQueryVariables>;
export const TimesheetMandayAnalysisByAccCodeDocument = gql`
    query TimesheetMandayAnalysisByAccCode($CompanyID: String!, $AccountCodeID: String!) {
  TimesheetMandayAnalysisByAccCode(CompanyID: $CompanyID, AccountCodeID: $AccountCodeID) {
    Activity
    MTD
    YTD
    TD
    AccountCodeID
    AccountCodeName
  }
}
    `;

/**
 * __useTimesheetMandayAnalysisByAccCodeQuery__
 *
 * To run a query within a React component, call `useTimesheetMandayAnalysisByAccCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetMandayAnalysisByAccCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetMandayAnalysisByAccCodeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountCodeID: // value for 'AccountCodeID'
 *   },
 * });
 */
export function useTimesheetMandayAnalysisByAccCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimesheetMandayAnalysisByAccCodeQuery, TimesheetMandayAnalysisByAccCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<TimesheetMandayAnalysisByAccCodeQuery, TimesheetMandayAnalysisByAccCodeQueryVariables>(TimesheetMandayAnalysisByAccCodeDocument, baseOptions);
      }
export function useTimesheetMandayAnalysisByAccCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimesheetMandayAnalysisByAccCodeQuery, TimesheetMandayAnalysisByAccCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimesheetMandayAnalysisByAccCodeQuery, TimesheetMandayAnalysisByAccCodeQueryVariables>(TimesheetMandayAnalysisByAccCodeDocument, baseOptions);
        }
export type TimesheetMandayAnalysisByAccCodeQueryHookResult = ReturnType<typeof useTimesheetMandayAnalysisByAccCodeQuery>;
export type TimesheetMandayAnalysisByAccCodeLazyQueryHookResult = ReturnType<typeof useTimesheetMandayAnalysisByAccCodeLazyQuery>;
export type TimesheetMandayAnalysisByAccCodeQueryResult = ApolloReactCommon.QueryResult<TimesheetMandayAnalysisByAccCodeQuery, TimesheetMandayAnalysisByAccCodeQueryVariables>;
export const TimesheetMandayAnalysisByActivityDocument = gql`
    query TimesheetMandayAnalysisByActivity($CompanyID: String!, $AccountCodeID: String!, $Activity: String!) {
  TimesheetMandayAnalysisByActivity(CompanyID: $CompanyID, AccountCodeID: $AccountCodeID, Activity: $Activity) {
    JobGradeID
    OrgUnitID
    FormStatus
    EmployeeName
    MTD
    YTD
    TD
    TotalMTD
    TotalYTD
    TotalTD
  }
}
    `;

/**
 * __useTimesheetMandayAnalysisByActivityQuery__
 *
 * To run a query within a React component, call `useTimesheetMandayAnalysisByActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetMandayAnalysisByActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetMandayAnalysisByActivityQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountCodeID: // value for 'AccountCodeID'
 *      Activity: // value for 'Activity'
 *   },
 * });
 */
export function useTimesheetMandayAnalysisByActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimesheetMandayAnalysisByActivityQuery, TimesheetMandayAnalysisByActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<TimesheetMandayAnalysisByActivityQuery, TimesheetMandayAnalysisByActivityQueryVariables>(TimesheetMandayAnalysisByActivityDocument, baseOptions);
      }
export function useTimesheetMandayAnalysisByActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimesheetMandayAnalysisByActivityQuery, TimesheetMandayAnalysisByActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimesheetMandayAnalysisByActivityQuery, TimesheetMandayAnalysisByActivityQueryVariables>(TimesheetMandayAnalysisByActivityDocument, baseOptions);
        }
export type TimesheetMandayAnalysisByActivityQueryHookResult = ReturnType<typeof useTimesheetMandayAnalysisByActivityQuery>;
export type TimesheetMandayAnalysisByActivityLazyQueryHookResult = ReturnType<typeof useTimesheetMandayAnalysisByActivityLazyQuery>;
export type TimesheetMandayAnalysisByActivityQueryResult = ApolloReactCommon.QueryResult<TimesheetMandayAnalysisByActivityQuery, TimesheetMandayAnalysisByActivityQueryVariables>;
export const TimesheetMandayAnalysisByAccTypeDocument = gql`
    query TimesheetMandayAnalysisByAccType($CompanyID: String!, $AccountTypeID: String!) {
  TimesheetMandayAnalysisByAccType(CompanyID: $CompanyID, AccountTypeID: $AccountTypeID) {
    Account
    YTD
    MTD
    TD
    TotalMTD
    TotalYTD
    TotalTD
    AccountTypeName
    AccountTypeCode
    AccountTypeID
  }
}
    `;

/**
 * __useTimesheetMandayAnalysisByAccTypeQuery__
 *
 * To run a query within a React component, call `useTimesheetMandayAnalysisByAccTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetMandayAnalysisByAccTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetMandayAnalysisByAccTypeQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountTypeID: // value for 'AccountTypeID'
 *   },
 * });
 */
export function useTimesheetMandayAnalysisByAccTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimesheetMandayAnalysisByAccTypeQuery, TimesheetMandayAnalysisByAccTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<TimesheetMandayAnalysisByAccTypeQuery, TimesheetMandayAnalysisByAccTypeQueryVariables>(TimesheetMandayAnalysisByAccTypeDocument, baseOptions);
      }
export function useTimesheetMandayAnalysisByAccTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimesheetMandayAnalysisByAccTypeQuery, TimesheetMandayAnalysisByAccTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimesheetMandayAnalysisByAccTypeQuery, TimesheetMandayAnalysisByAccTypeQueryVariables>(TimesheetMandayAnalysisByAccTypeDocument, baseOptions);
        }
export type TimesheetMandayAnalysisByAccTypeQueryHookResult = ReturnType<typeof useTimesheetMandayAnalysisByAccTypeQuery>;
export type TimesheetMandayAnalysisByAccTypeLazyQueryHookResult = ReturnType<typeof useTimesheetMandayAnalysisByAccTypeLazyQuery>;
export type TimesheetMandayAnalysisByAccTypeQueryResult = ApolloReactCommon.QueryResult<TimesheetMandayAnalysisByAccTypeQuery, TimesheetMandayAnalysisByAccTypeQueryVariables>;
export const TimesheetMandayAnalysisByAccountDocument = gql`
    query TimesheetMandayAnalysisByAccount($CompanyID: String!, $Account: String!, $AccountTypeID: String!) {
  TimesheetMandayAnalysisByAccount(CompanyID: $CompanyID, Account: $Account, AccountTypeID: $AccountTypeID) {
    JobGradeID
    OrgUnitID
    FormStatus
    EmployeeName
    YTD
    MTD
    TD
    TotalMTD
    TotalYTD
    TotalTD
  }
}
    `;

/**
 * __useTimesheetMandayAnalysisByAccountQuery__
 *
 * To run a query within a React component, call `useTimesheetMandayAnalysisByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimesheetMandayAnalysisByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimesheetMandayAnalysisByAccountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Account: // value for 'Account'
 *      AccountTypeID: // value for 'AccountTypeID'
 *   },
 * });
 */
export function useTimesheetMandayAnalysisByAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TimesheetMandayAnalysisByAccountQuery, TimesheetMandayAnalysisByAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<TimesheetMandayAnalysisByAccountQuery, TimesheetMandayAnalysisByAccountQueryVariables>(TimesheetMandayAnalysisByAccountDocument, baseOptions);
      }
export function useTimesheetMandayAnalysisByAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimesheetMandayAnalysisByAccountQuery, TimesheetMandayAnalysisByAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TimesheetMandayAnalysisByAccountQuery, TimesheetMandayAnalysisByAccountQueryVariables>(TimesheetMandayAnalysisByAccountDocument, baseOptions);
        }
export type TimesheetMandayAnalysisByAccountQueryHookResult = ReturnType<typeof useTimesheetMandayAnalysisByAccountQuery>;
export type TimesheetMandayAnalysisByAccountLazyQueryHookResult = ReturnType<typeof useTimesheetMandayAnalysisByAccountLazyQuery>;
export type TimesheetMandayAnalysisByAccountQueryResult = ApolloReactCommon.QueryResult<TimesheetMandayAnalysisByAccountQuery, TimesheetMandayAnalysisByAccountQueryVariables>;
export const IsActvitiyInUseDocument = gql`
    query IsActvitiyInUse($TsAccountTypeID: String!, $Activities: [String!]!, $CompanyID: String!) {
  IsActvitiyInUse(TsAccountTypeID: $TsAccountTypeID, Activities: $Activities, CompanyID: $CompanyID)
}
    `;

/**
 * __useIsActvitiyInUseQuery__
 *
 * To run a query within a React component, call `useIsActvitiyInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsActvitiyInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsActvitiyInUseQuery({
 *   variables: {
 *      TsAccountTypeID: // value for 'TsAccountTypeID'
 *      Activities: // value for 'Activities'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useIsActvitiyInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsActvitiyInUseQuery, IsActvitiyInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<IsActvitiyInUseQuery, IsActvitiyInUseQueryVariables>(IsActvitiyInUseDocument, baseOptions);
      }
export function useIsActvitiyInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsActvitiyInUseQuery, IsActvitiyInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsActvitiyInUseQuery, IsActvitiyInUseQueryVariables>(IsActvitiyInUseDocument, baseOptions);
        }
export type IsActvitiyInUseQueryHookResult = ReturnType<typeof useIsActvitiyInUseQuery>;
export type IsActvitiyInUseLazyQueryHookResult = ReturnType<typeof useIsActvitiyInUseLazyQuery>;
export type IsActvitiyInUseQueryResult = ApolloReactCommon.QueryResult<IsActvitiyInUseQuery, IsActvitiyInUseQueryVariables>;
export const TsAccountCodeInsertDocument = gql`
    mutation TsAccountCodeInsert($TsAccountCodeInput: TsAccountCodeInput!) {
  TsAccountCodeInsert(TsAccountCodeInput: $TsAccountCodeInput)
}
    `;
export type TsAccountCodeInsertMutationFn = ApolloReactCommon.MutationFunction<TsAccountCodeInsertMutation, TsAccountCodeInsertMutationVariables>;

/**
 * __useTsAccountCodeInsertMutation__
 *
 * To run a mutation, you first call `useTsAccountCodeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTsAccountCodeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tsAccountCodeInsertMutation, { data, loading, error }] = useTsAccountCodeInsertMutation({
 *   variables: {
 *      TsAccountCodeInput: // value for 'TsAccountCodeInput'
 *   },
 * });
 */
export function useTsAccountCodeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TsAccountCodeInsertMutation, TsAccountCodeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TsAccountCodeInsertMutation, TsAccountCodeInsertMutationVariables>(TsAccountCodeInsertDocument, baseOptions);
      }
export type TsAccountCodeInsertMutationHookResult = ReturnType<typeof useTsAccountCodeInsertMutation>;
export type TsAccountCodeInsertMutationResult = ApolloReactCommon.MutationResult<TsAccountCodeInsertMutation>;
export type TsAccountCodeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TsAccountCodeInsertMutation, TsAccountCodeInsertMutationVariables>;
export const TsAccountCodeUpdateDocument = gql`
    mutation TsAccountCodeUpdate($TsAccountCodeInput: TsAccountCodeInput!) {
  TsAccountCodeUpdate(TsAccountCodeInput: $TsAccountCodeInput)
}
    `;
export type TsAccountCodeUpdateMutationFn = ApolloReactCommon.MutationFunction<TsAccountCodeUpdateMutation, TsAccountCodeUpdateMutationVariables>;

/**
 * __useTsAccountCodeUpdateMutation__
 *
 * To run a mutation, you first call `useTsAccountCodeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTsAccountCodeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tsAccountCodeUpdateMutation, { data, loading, error }] = useTsAccountCodeUpdateMutation({
 *   variables: {
 *      TsAccountCodeInput: // value for 'TsAccountCodeInput'
 *   },
 * });
 */
export function useTsAccountCodeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TsAccountCodeUpdateMutation, TsAccountCodeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TsAccountCodeUpdateMutation, TsAccountCodeUpdateMutationVariables>(TsAccountCodeUpdateDocument, baseOptions);
      }
export type TsAccountCodeUpdateMutationHookResult = ReturnType<typeof useTsAccountCodeUpdateMutation>;
export type TsAccountCodeUpdateMutationResult = ApolloReactCommon.MutationResult<TsAccountCodeUpdateMutation>;
export type TsAccountCodeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TsAccountCodeUpdateMutation, TsAccountCodeUpdateMutationVariables>;
export const TsAccountCodeDeleteDocument = gql`
    mutation TsAccountCodeDelete($ID: String!, $SubscriptionAccountID: String!) {
  TsAccountCodeDelete(ID: $ID, SubscriptionAccountID: $SubscriptionAccountID)
}
    `;
export type TsAccountCodeDeleteMutationFn = ApolloReactCommon.MutationFunction<TsAccountCodeDeleteMutation, TsAccountCodeDeleteMutationVariables>;

/**
 * __useTsAccountCodeDeleteMutation__
 *
 * To run a mutation, you first call `useTsAccountCodeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTsAccountCodeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tsAccountCodeDeleteMutation, { data, loading, error }] = useTsAccountCodeDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useTsAccountCodeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TsAccountCodeDeleteMutation, TsAccountCodeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TsAccountCodeDeleteMutation, TsAccountCodeDeleteMutationVariables>(TsAccountCodeDeleteDocument, baseOptions);
      }
export type TsAccountCodeDeleteMutationHookResult = ReturnType<typeof useTsAccountCodeDeleteMutation>;
export type TsAccountCodeDeleteMutationResult = ApolloReactCommon.MutationResult<TsAccountCodeDeleteMutation>;
export type TsAccountCodeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TsAccountCodeDeleteMutation, TsAccountCodeDeleteMutationVariables>;
export const TsAccountTypeInsertDocument = gql`
    mutation TsAccountTypeInsert($TsAccountTypeInput: TsAccountTypeInput!) {
  TsAccountTypeInsert(TsAccountTypeInput: $TsAccountTypeInput)
}
    `;
export type TsAccountTypeInsertMutationFn = ApolloReactCommon.MutationFunction<TsAccountTypeInsertMutation, TsAccountTypeInsertMutationVariables>;

/**
 * __useTsAccountTypeInsertMutation__
 *
 * To run a mutation, you first call `useTsAccountTypeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTsAccountTypeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tsAccountTypeInsertMutation, { data, loading, error }] = useTsAccountTypeInsertMutation({
 *   variables: {
 *      TsAccountTypeInput: // value for 'TsAccountTypeInput'
 *   },
 * });
 */
export function useTsAccountTypeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TsAccountTypeInsertMutation, TsAccountTypeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TsAccountTypeInsertMutation, TsAccountTypeInsertMutationVariables>(TsAccountTypeInsertDocument, baseOptions);
      }
export type TsAccountTypeInsertMutationHookResult = ReturnType<typeof useTsAccountTypeInsertMutation>;
export type TsAccountTypeInsertMutationResult = ApolloReactCommon.MutationResult<TsAccountTypeInsertMutation>;
export type TsAccountTypeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TsAccountTypeInsertMutation, TsAccountTypeInsertMutationVariables>;
export const TsAccountTypeUpdateDocument = gql`
    mutation TsAccountTypeUpdate($TsAccountTypeInput: TsAccountTypeInput!) {
  TsAccountTypeUpdate(TsAccountTypeInput: $TsAccountTypeInput)
}
    `;
export type TsAccountTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<TsAccountTypeUpdateMutation, TsAccountTypeUpdateMutationVariables>;

/**
 * __useTsAccountTypeUpdateMutation__
 *
 * To run a mutation, you first call `useTsAccountTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTsAccountTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tsAccountTypeUpdateMutation, { data, loading, error }] = useTsAccountTypeUpdateMutation({
 *   variables: {
 *      TsAccountTypeInput: // value for 'TsAccountTypeInput'
 *   },
 * });
 */
export function useTsAccountTypeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TsAccountTypeUpdateMutation, TsAccountTypeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TsAccountTypeUpdateMutation, TsAccountTypeUpdateMutationVariables>(TsAccountTypeUpdateDocument, baseOptions);
      }
export type TsAccountTypeUpdateMutationHookResult = ReturnType<typeof useTsAccountTypeUpdateMutation>;
export type TsAccountTypeUpdateMutationResult = ApolloReactCommon.MutationResult<TsAccountTypeUpdateMutation>;
export type TsAccountTypeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TsAccountTypeUpdateMutation, TsAccountTypeUpdateMutationVariables>;
export const TsAccountTypeDeleteDocument = gql`
    mutation TsAccountTypeDelete($ID: String!, $SubscriptionAccountID: String!) {
  TsAccountTypeDelete(ID: $ID, SubscriptionAccountID: $SubscriptionAccountID)
}
    `;
export type TsAccountTypeDeleteMutationFn = ApolloReactCommon.MutationFunction<TsAccountTypeDeleteMutation, TsAccountTypeDeleteMutationVariables>;

/**
 * __useTsAccountTypeDeleteMutation__
 *
 * To run a mutation, you first call `useTsAccountTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTsAccountTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tsAccountTypeDeleteMutation, { data, loading, error }] = useTsAccountTypeDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      SubscriptionAccountID: // value for 'SubscriptionAccountID'
 *   },
 * });
 */
export function useTsAccountTypeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TsAccountTypeDeleteMutation, TsAccountTypeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TsAccountTypeDeleteMutation, TsAccountTypeDeleteMutationVariables>(TsAccountTypeDeleteDocument, baseOptions);
      }
export type TsAccountTypeDeleteMutationHookResult = ReturnType<typeof useTsAccountTypeDeleteMutation>;
export type TsAccountTypeDeleteMutationResult = ApolloReactCommon.MutationResult<TsAccountTypeDeleteMutation>;
export type TsAccountTypeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TsAccountTypeDeleteMutation, TsAccountTypeDeleteMutationVariables>;
export const ProjectTimesheetEmployeeListingDocument = gql`
    query ProjectTimesheetEmployeeListing($CompanyID: String!, $PeriodYearMonth: String!) {
  ProjectTimesheetEmployeeListing(CompanyID: $CompanyID, PeriodYearMonth: $PeriodYearMonth) {
    Employee {
      EmployeeID
      EmployeeNo
      Contact {
        ContactID
        FullName
      }
    }
  }
}
    `;

/**
 * __useProjectTimesheetEmployeeListingQuery__
 *
 * To run a query within a React component, call `useProjectTimesheetEmployeeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheetEmployeeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimesheetEmployeeListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PeriodYearMonth: // value for 'PeriodYearMonth'
 *   },
 * });
 */
export function useProjectTimesheetEmployeeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTimesheetEmployeeListingQuery, ProjectTimesheetEmployeeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTimesheetEmployeeListingQuery, ProjectTimesheetEmployeeListingQueryVariables>(ProjectTimesheetEmployeeListingDocument, baseOptions);
      }
export function useProjectTimesheetEmployeeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTimesheetEmployeeListingQuery, ProjectTimesheetEmployeeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTimesheetEmployeeListingQuery, ProjectTimesheetEmployeeListingQueryVariables>(ProjectTimesheetEmployeeListingDocument, baseOptions);
        }
export type ProjectTimesheetEmployeeListingQueryHookResult = ReturnType<typeof useProjectTimesheetEmployeeListingQuery>;
export type ProjectTimesheetEmployeeListingLazyQueryHookResult = ReturnType<typeof useProjectTimesheetEmployeeListingLazyQuery>;
export type ProjectTimesheetEmployeeListingQueryResult = ApolloReactCommon.QueryResult<ProjectTimesheetEmployeeListingQuery, ProjectTimesheetEmployeeListingQueryVariables>;
export const AdminDailyEntryListingDocument = gql`
    query AdminDailyEntryListing($CompanyID: String!, $EmployeeID: String!, $ContactID: String!, $PeriodYearMonth: String!) {
  AdminDailyEntryListing(CompanyID: $CompanyID, EmployeeID: $EmployeeID, ContactID: $ContactID, PeriodYearMonth: $PeriodYearMonth) {
    ID
    EntryDate
    StartTime
    EndTime
    BreakTime
    TotalHours
    Remarks
    ProjectTimesheetMonthlyRecord {
      ID
      ApprovalLog
    }
  }
}
    `;

/**
 * __useAdminDailyEntryListingQuery__
 *
 * To run a query within a React component, call `useAdminDailyEntryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDailyEntryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDailyEntryListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeID: // value for 'EmployeeID'
 *      ContactID: // value for 'ContactID'
 *      PeriodYearMonth: // value for 'PeriodYearMonth'
 *   },
 * });
 */
export function useAdminDailyEntryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdminDailyEntryListingQuery, AdminDailyEntryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AdminDailyEntryListingQuery, AdminDailyEntryListingQueryVariables>(AdminDailyEntryListingDocument, baseOptions);
      }
export function useAdminDailyEntryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminDailyEntryListingQuery, AdminDailyEntryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdminDailyEntryListingQuery, AdminDailyEntryListingQueryVariables>(AdminDailyEntryListingDocument, baseOptions);
        }
export type AdminDailyEntryListingQueryHookResult = ReturnType<typeof useAdminDailyEntryListingQuery>;
export type AdminDailyEntryListingLazyQueryHookResult = ReturnType<typeof useAdminDailyEntryListingLazyQuery>;
export type AdminDailyEntryListingQueryResult = ApolloReactCommon.QueryResult<AdminDailyEntryListingQuery, AdminDailyEntryListingQueryVariables>;
export const ProjectTimesheeTApproverReportListingDocument = gql`
    query ProjectTimesheeTApproverReportListing($PeriodYearMonth: String!, $CompanyID: String!, $OrgUnitID: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $SortBy: String) {
  ProjectTimesheeTApproverReportListing(PeriodYearMonth: $PeriodYearMonth, CompanyID: $CompanyID, OrgUnitID: $OrgUnitID, EmployeeStatus: $EmployeeStatus, EmployeeType: $EmployeeType, SortBy: $SortBy) {
    ID
    Name
    ClientName
    Department
    EmailAddress
    ProjectTimesheetPeriod {
      ID
      ProjectTimesheetMonthlyRecord {
        EmployeeID
        ApprovalLog
      }
      ProjectTimesheetApproverAssignment {
        ID
        EmployeeID
        Employee {
          EmployeeNo
          Status
          CompanyID
          OrgUnit {
            Description
          }
          Contact {
            ContactID
            FullName
          }
          Company {
            CompanyID
            CompanyName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useProjectTimesheeTApproverReportListingQuery__
 *
 * To run a query within a React component, call `useProjectTimesheeTApproverReportListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheeTApproverReportListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTimesheeTApproverReportListingQuery({
 *   variables: {
 *      PeriodYearMonth: // value for 'PeriodYearMonth'
 *      CompanyID: // value for 'CompanyID'
 *      OrgUnitID: // value for 'OrgUnitID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      SortBy: // value for 'SortBy'
 *   },
 * });
 */
export function useProjectTimesheeTApproverReportListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTimesheeTApproverReportListingQuery, ProjectTimesheeTApproverReportListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTimesheeTApproverReportListingQuery, ProjectTimesheeTApproverReportListingQueryVariables>(ProjectTimesheeTApproverReportListingDocument, baseOptions);
      }
export function useProjectTimesheeTApproverReportListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTimesheeTApproverReportListingQuery, ProjectTimesheeTApproverReportListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTimesheeTApproverReportListingQuery, ProjectTimesheeTApproverReportListingQueryVariables>(ProjectTimesheeTApproverReportListingDocument, baseOptions);
        }
export type ProjectTimesheeTApproverReportListingQueryHookResult = ReturnType<typeof useProjectTimesheeTApproverReportListingQuery>;
export type ProjectTimesheeTApproverReportListingLazyQueryHookResult = ReturnType<typeof useProjectTimesheeTApproverReportListingLazyQuery>;
export type ProjectTimesheeTApproverReportListingQueryResult = ApolloReactCommon.QueryResult<ProjectTimesheeTApproverReportListingQuery, ProjectTimesheeTApproverReportListingQueryVariables>;
export const ProjectTimesheetReportForApproverSendEmailDocument = gql`
    mutation ProjectTimesheetReportForApproverSendEmail($CompanyName: String!, $PeriodYearMonth: String!, $ReportURL: String!, $input: ProjectTimesheetReportForApproverInput!) {
  ProjectTimesheetReportForApproverSendEmail(CompanyName: $CompanyName, PeriodYearMonth: $PeriodYearMonth, ReportURL: $ReportURL, input: $input)
}
    `;
export type ProjectTimesheetReportForApproverSendEmailMutationFn = ApolloReactCommon.MutationFunction<ProjectTimesheetReportForApproverSendEmailMutation, ProjectTimesheetReportForApproverSendEmailMutationVariables>;

/**
 * __useProjectTimesheetReportForApproverSendEmailMutation__
 *
 * To run a mutation, you first call `useProjectTimesheetReportForApproverSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectTimesheetReportForApproverSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectTimesheetReportForApproverSendEmailMutation, { data, loading, error }] = useProjectTimesheetReportForApproverSendEmailMutation({
 *   variables: {
 *      CompanyName: // value for 'CompanyName'
 *      PeriodYearMonth: // value for 'PeriodYearMonth'
 *      ReportURL: // value for 'ReportURL'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProjectTimesheetReportForApproverSendEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProjectTimesheetReportForApproverSendEmailMutation, ProjectTimesheetReportForApproverSendEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<ProjectTimesheetReportForApproverSendEmailMutation, ProjectTimesheetReportForApproverSendEmailMutationVariables>(ProjectTimesheetReportForApproverSendEmailDocument, baseOptions);
      }
export type ProjectTimesheetReportForApproverSendEmailMutationHookResult = ReturnType<typeof useProjectTimesheetReportForApproverSendEmailMutation>;
export type ProjectTimesheetReportForApproverSendEmailMutationResult = ApolloReactCommon.MutationResult<ProjectTimesheetReportForApproverSendEmailMutation>;
export type ProjectTimesheetReportForApproverSendEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ProjectTimesheetReportForApproverSendEmailMutation, ProjectTimesheetReportForApproverSendEmailMutationVariables>;
export const GetLeaveToAproveDocument = gql`
    query GetLeaveToAprove($CompanyID: String, $ApproverID: String!) {
  GetLeaveToAprove(CompanyID: $CompanyID, ApproverID: $ApproverID) {
    LeaveHeaderID
    FromDate
    UntilDate
    EmployeeID
    IsEmergencyLeave
    ApprovalStatus
    LeaveHistory {
      ApprovalStatus
      FromDate
      UntilDate
      IsEmergencyLeave
      LeaveType {
        Name
      }
      TotalDay
      Document {
        DocumentID
        DocumentFile
      }
      CreatedDT
      Remarks
      LeaveDetails {
        SessionType
        LeaveDate
      }
    }
    LeaveType {
      Name
    }
    TotalDay
    Document {
      DocumentID
      DocumentFile
    }
    CreatedDT
    Remarks
    LeaveDetails {
      SessionType
      LeaveDate
    }
    Employee {
      EmployeeNo
      Contact {
        FullName
      }
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetLeaveToAproveQuery__
 *
 * To run a query within a React component, call `useGetLeaveToAproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveToAproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveToAproveQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useGetLeaveToAproveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveToAproveQuery, GetLeaveToAproveQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveToAproveQuery, GetLeaveToAproveQueryVariables>(GetLeaveToAproveDocument, baseOptions);
      }
export function useGetLeaveToAproveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveToAproveQuery, GetLeaveToAproveQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveToAproveQuery, GetLeaveToAproveQueryVariables>(GetLeaveToAproveDocument, baseOptions);
        }
export type GetLeaveToAproveQueryHookResult = ReturnType<typeof useGetLeaveToAproveQuery>;
export type GetLeaveToAproveLazyQueryHookResult = ReturnType<typeof useGetLeaveToAproveLazyQuery>;
export type GetLeaveToAproveQueryResult = ApolloReactCommon.QueryResult<GetLeaveToAproveQuery, GetLeaveToAproveQueryVariables>;
export const GetLeaveApproveToDoDocument = gql`
    query GetLeaveApproveToDo($EmployeeID: String!) {
  GetLeaveApproveToDo(EmployeeID: $EmployeeID) {
    LeaveHeaderID
    FromDate
    UntilDate
    EmployeeID
    IsEmergencyLeave
    Superior
    ApproverID
    ApprovalStatus
    LeaveHistory {
      ApprovalStatus
      FromDate
      UntilDate
      IsEmergencyLeave
      LeaveType {
        Name
      }
      TotalDay
      Document {
        DocumentID
        DocumentFile
      }
      CreatedDT
      Remarks
      LeaveDetails {
        SessionType
        LeaveDate
      }
    }
    LeaveType {
      Name
    }
    TotalDay
    Document {
      DocumentID
      DocumentFile
    }
    VoiceDocument {
      DocumentID
      DocumentFile
    }
    CreatedDT
    Remarks
    RejectedRemarks
    LeaveDetails {
      SessionType
      LeaveDate
    }
    Employee {
      EmployeeNo
      Contact {
        FullName
      }
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetLeaveApproveToDoQuery__
 *
 * To run a query within a React component, call `useGetLeaveApproveToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveApproveToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveApproveToDoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetLeaveApproveToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveApproveToDoQuery, GetLeaveApproveToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveApproveToDoQuery, GetLeaveApproveToDoQueryVariables>(GetLeaveApproveToDoDocument, baseOptions);
      }
export function useGetLeaveApproveToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveApproveToDoQuery, GetLeaveApproveToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveApproveToDoQuery, GetLeaveApproveToDoQueryVariables>(GetLeaveApproveToDoDocument, baseOptions);
        }
export type GetLeaveApproveToDoQueryHookResult = ReturnType<typeof useGetLeaveApproveToDoQuery>;
export type GetLeaveApproveToDoLazyQueryHookResult = ReturnType<typeof useGetLeaveApproveToDoLazyQuery>;
export type GetLeaveApproveToDoQueryResult = ApolloReactCommon.QueryResult<GetLeaveApproveToDoQuery, GetLeaveApproveToDoQueryVariables>;
export const GetLeaveListingDocument = gql`
    query GetLeaveListing($EmployeeID: String!, $FromDate: DateTime) {
  GetLeaveListing(EmployeeID: $EmployeeID, FromDate: $FromDate) {
    LeaveHeaderID
    FromDate
    UntilDate
    EmployeeID
    IsEmergencyLeave
    ApprovalStatus
    LeaveHistory {
      ApprovalStatus
      FromDate
      UntilDate
      IsEmergencyLeave
      LeaveType {
        Name
      }
      TotalDay
      Document {
        DocumentID
        DocumentFile
      }
      CreatedDT
      Remarks
      LeaveDetails {
        SessionType
        LeaveDate
      }
    }
    LeaveType {
      Name
    }
    TotalDay
    Document {
      DocumentID
      DocumentFile
    }
    VoiceDocument {
      DocumentID
      DocumentFile
    }
    CreatedDT
    Remarks
    RejectedRemarks
    LeaveDetails {
      SessionType
      LeaveDate
    }
    Employee {
      EmployeeNo
      Contact {
        FullName
      }
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetLeaveListingQuery__
 *
 * To run a query within a React component, call `useGetLeaveListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveListingQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      FromDate: // value for 'FromDate'
 *   },
 * });
 */
export function useGetLeaveListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLeaveListingQuery, GetLeaveListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLeaveListingQuery, GetLeaveListingQueryVariables>(GetLeaveListingDocument, baseOptions);
      }
export function useGetLeaveListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLeaveListingQuery, GetLeaveListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLeaveListingQuery, GetLeaveListingQueryVariables>(GetLeaveListingDocument, baseOptions);
        }
export type GetLeaveListingQueryHookResult = ReturnType<typeof useGetLeaveListingQuery>;
export type GetLeaveListingLazyQueryHookResult = ReturnType<typeof useGetLeaveListingLazyQuery>;
export type GetLeaveListingQueryResult = ApolloReactCommon.QueryResult<GetLeaveListingQuery, GetLeaveListingQueryVariables>;
export const ToDoSummaryDocument = gql`
    query ToDoSummary($EmployeeID: String!) {
  ToDoSummary(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useToDoSummaryQuery__
 *
 * To run a query within a React component, call `useToDoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useToDoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToDoSummaryQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useToDoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ToDoSummaryQuery, ToDoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<ToDoSummaryQuery, ToDoSummaryQueryVariables>(ToDoSummaryDocument, baseOptions);
      }
export function useToDoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ToDoSummaryQuery, ToDoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ToDoSummaryQuery, ToDoSummaryQueryVariables>(ToDoSummaryDocument, baseOptions);
        }
export type ToDoSummaryQueryHookResult = ReturnType<typeof useToDoSummaryQuery>;
export type ToDoSummaryLazyQueryHookResult = ReturnType<typeof useToDoSummaryLazyQuery>;
export type ToDoSummaryQueryResult = ApolloReactCommon.QueryResult<ToDoSummaryQuery, ToDoSummaryQueryVariables>;
export const GetSubordinateKpiPlanningDocument = gql`
    query GetSubordinateKPIPlanning($EmployeeID: [String!]!) {
  GetSubordinateKPIPlanning(EmployeeID: $EmployeeID)
}
    `;

/**
 * __useGetSubordinateKpiPlanningQuery__
 *
 * To run a query within a React component, call `useGetSubordinateKpiPlanningQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubordinateKpiPlanningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubordinateKpiPlanningQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetSubordinateKpiPlanningQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubordinateKpiPlanningQuery, GetSubordinateKpiPlanningQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubordinateKpiPlanningQuery, GetSubordinateKpiPlanningQueryVariables>(GetSubordinateKpiPlanningDocument, baseOptions);
      }
export function useGetSubordinateKpiPlanningLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubordinateKpiPlanningQuery, GetSubordinateKpiPlanningQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubordinateKpiPlanningQuery, GetSubordinateKpiPlanningQueryVariables>(GetSubordinateKpiPlanningDocument, baseOptions);
        }
export type GetSubordinateKpiPlanningQueryHookResult = ReturnType<typeof useGetSubordinateKpiPlanningQuery>;
export type GetSubordinateKpiPlanningLazyQueryHookResult = ReturnType<typeof useGetSubordinateKpiPlanningLazyQuery>;
export type GetSubordinateKpiPlanningQueryResult = ApolloReactCommon.QueryResult<GetSubordinateKpiPlanningQuery, GetSubordinateKpiPlanningQueryVariables>;
export const GetRequisitionToAproveDocument = gql`
    query GetRequisitionToAprove($CompanyID: String, $ApproverID: String!) {
  GetRequisitionToAprove(CompanyID: $CompanyID, ApproverID: $ApproverID) {
    RequisitionHeaderID
    Subject
    Status
    EmployeeID
    PurposeOfTravel
    RequisitionType
    ApprovalStatus
    TravelRequestID
    Documents {
      Description
      DocumentID
      DocumentFile
    }
    Employee {
      Contact {
        FullName
      }
      EmployeeNo
    }
    Documents {
      DocumentID
      DocumentFile
    }
    Amount
    Remark
    NumberOfItem
    CostPerItem
    CreatedDT
    TravelFrom
    TravelTo
    TransportMode
    StartDate
    EndDate
    RequisitionHistory {
      Subject
      Status
      PurposeOfTravel
      RequisitionType
      Documents {
        DocumentID
        DocumentFile
      }
      Amount
      Remark
      NumberOfItem
      CostPerItem
      ModifiedDT
      ApprovalStatus
      TravelFrom
      TravelTo
      TransportMode
      StartDate
      EndDate
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetRequisitionToAproveQuery__
 *
 * To run a query within a React component, call `useGetRequisitionToAproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionToAproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionToAproveQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useGetRequisitionToAproveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRequisitionToAproveQuery, GetRequisitionToAproveQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRequisitionToAproveQuery, GetRequisitionToAproveQueryVariables>(GetRequisitionToAproveDocument, baseOptions);
      }
export function useGetRequisitionToAproveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRequisitionToAproveQuery, GetRequisitionToAproveQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRequisitionToAproveQuery, GetRequisitionToAproveQueryVariables>(GetRequisitionToAproveDocument, baseOptions);
        }
export type GetRequisitionToAproveQueryHookResult = ReturnType<typeof useGetRequisitionToAproveQuery>;
export type GetRequisitionToAproveLazyQueryHookResult = ReturnType<typeof useGetRequisitionToAproveLazyQuery>;
export type GetRequisitionToAproveQueryResult = ApolloReactCommon.QueryResult<GetRequisitionToAproveQuery, GetRequisitionToAproveQueryVariables>;
export const GetRequisitionApproveToDoDocument = gql`
    query GetRequisitionApproveToDo($EmployeeID: String!) {
  GetRequisitionApproveToDo(EmployeeID: $EmployeeID) {
    RequisitionHeaderID
    Subject
    Status
    EmployeeID
    PurposeOfTravel
    RequisitionType
    ApprovalStatus
    OtherReqNo
    TravelReqNo
    AssetReqNo
    CashReqNo
    TravelRequestID
    Documents {
      Description
      DocumentID
      DocumentFile
    }
    Employee {
      Contact {
        FullName
      }
      EmployeeNo
    }
    Documents {
      DocumentID
      DocumentFile
    }
    MultipleDocuments {
      DocumentID
      DocumentFile
      DocumentType
      Description
    }
    Amount
    Remark
    NumberOfItem
    CostPerItem
    CreatedDT
    TravelFrom
    TravelTo
    TransportMode
    StartDate
    EndDate
    RequisitionHistory {
      Subject
      Status
      PurposeOfTravel
      RequisitionType
      Documents {
        DocumentID
        DocumentFile
      }
      Amount
      Remark
      NumberOfItem
      CostPerItem
      ModifiedDT
      CreatedDT
      ApprovalStatus
      TravelFrom
      TravelTo
      TransportMode
      StartDate
      EndDate
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetRequisitionApproveToDoQuery__
 *
 * To run a query within a React component, call `useGetRequisitionApproveToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequisitionApproveToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequisitionApproveToDoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetRequisitionApproveToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRequisitionApproveToDoQuery, GetRequisitionApproveToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRequisitionApproveToDoQuery, GetRequisitionApproveToDoQueryVariables>(GetRequisitionApproveToDoDocument, baseOptions);
      }
export function useGetRequisitionApproveToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRequisitionApproveToDoQuery, GetRequisitionApproveToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRequisitionApproveToDoQuery, GetRequisitionApproveToDoQueryVariables>(GetRequisitionApproveToDoDocument, baseOptions);
        }
export type GetRequisitionApproveToDoQueryHookResult = ReturnType<typeof useGetRequisitionApproveToDoQuery>;
export type GetRequisitionApproveToDoLazyQueryHookResult = ReturnType<typeof useGetRequisitionApproveToDoLazyQuery>;
export type GetRequisitionApproveToDoQueryResult = ApolloReactCommon.QueryResult<GetRequisitionApproveToDoQuery, GetRequisitionApproveToDoQueryVariables>;
export const GetClaimToApproveDocument = gql`
    query GetClaimToApprove($CompanyID: String, $ApproverID: String!) {
  GetClaimToApprove(CompanyID: $CompanyID, ApproverID: $ApproverID) {
    ApprovalStatus
    ClaimHeaderID
    CreatedDT
    EmployeeID
    EmployeeName
    EmployeeNo
    Subject
    ClaimNo
    TotalClaim
    TotalClaimAmount
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
    ClaimDetails {
      ClaimAmount
      ClaimRemarks
      ClaimDetailsID
      ReceiptDate
      ReceiptNo
      MultipleDocuments {
        DocumentFile
        DocumentID
      }
      Documents {
        DocumentFile
        DocumentID
      }
      ClaimType {
        Name
      }
    }
    ClaimHistory {
      ApprovalStatus
      ClaimHeaderID
      CreatedDT
      EmployeeID
      Subject
      TotalClaim
      TotalClaimAmount
      IsRejected
      ClaimDetails {
        ClaimAmount
        ClaimRemarks
        ClaimDetailsID
        Documents {
          DocumentFile
          DocumentID
        }
      }
    }
    DocumentIDs {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      DocumentID
      DocumentType
      Description
      DocumentFile
      PayPeriodID
      ParentID
      BucketFileName
      FileSize
    }
  }
}
    `;

/**
 * __useGetClaimToApproveQuery__
 *
 * To run a query within a React component, call `useGetClaimToApproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimToApproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimToApproveQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useGetClaimToApproveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClaimToApproveQuery, GetClaimToApproveQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClaimToApproveQuery, GetClaimToApproveQueryVariables>(GetClaimToApproveDocument, baseOptions);
      }
export function useGetClaimToApproveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClaimToApproveQuery, GetClaimToApproveQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClaimToApproveQuery, GetClaimToApproveQueryVariables>(GetClaimToApproveDocument, baseOptions);
        }
export type GetClaimToApproveQueryHookResult = ReturnType<typeof useGetClaimToApproveQuery>;
export type GetClaimToApproveLazyQueryHookResult = ReturnType<typeof useGetClaimToApproveLazyQuery>;
export type GetClaimToApproveQueryResult = ApolloReactCommon.QueryResult<GetClaimToApproveQuery, GetClaimToApproveQueryVariables>;
export const GetOvertimeApproveToDoDocument = gql`
    query GetOvertimeApproveToDo($EmployeeID: String!) {
  GetOvertimeApproveToDo(EmployeeID: $EmployeeID) {
    OvertimeID
    EmployeeID
    CompanyID
    WorkDate
    StartTime
    EndTime
    Remark
    OvertimeReasonID
    ExcelFileName
    OvertimeStatus
    CreatedDT
    ModifiedDT
    ModifiedBy
    ApproverID
    RejectionRemarks
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    otReason {
      OvertimeReasonID
      Name
    }
    OvertimeHistory {
      OvertimeID
      EmployeeID
      CompanyID
      WorkDate
      StartTime
      EndTime
      Remark
      OvertimeReasonID
      ExcelFileName
      OvertimeStatus
      CreatedDT
      ModifiedDT
      ModifiedBy
      ApproverID
      RejectionRemarks
      Employee {
        EmployeeID
        Contact {
          ContactID
          FullName
          Email
          MobileNo
        }
        EmployeeNo
      }
      otReason {
        OvertimeReasonID
        Name
      }
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetOvertimeApproveToDoQuery__
 *
 * To run a query within a React component, call `useGetOvertimeApproveToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOvertimeApproveToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOvertimeApproveToDoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetOvertimeApproveToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOvertimeApproveToDoQuery, GetOvertimeApproveToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOvertimeApproveToDoQuery, GetOvertimeApproveToDoQueryVariables>(GetOvertimeApproveToDoDocument, baseOptions);
      }
export function useGetOvertimeApproveToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOvertimeApproveToDoQuery, GetOvertimeApproveToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOvertimeApproveToDoQuery, GetOvertimeApproveToDoQueryVariables>(GetOvertimeApproveToDoDocument, baseOptions);
        }
export type GetOvertimeApproveToDoQueryHookResult = ReturnType<typeof useGetOvertimeApproveToDoQuery>;
export type GetOvertimeApproveToDoLazyQueryHookResult = ReturnType<typeof useGetOvertimeApproveToDoLazyQuery>;
export type GetOvertimeApproveToDoQueryResult = ApolloReactCommon.QueryResult<GetOvertimeApproveToDoQuery, GetOvertimeApproveToDoQueryVariables>;
export const ViewOvertimeApprovalHistoryDocument = gql`
    query ViewOvertimeApprovalHistory($ApproverID: String!) {
  ViewOvertimeApprovalHistory(ApproverID: $ApproverID) {
    OvertimeID
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    CreatedDT
    otReason {
      OvertimeReasonID
      Name
    }
    StartTime
    EndTime
    Remark
    OvertimeStatus
    Approver
    ApproveDate
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useViewOvertimeApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useViewOvertimeApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewOvertimeApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewOvertimeApprovalHistoryQuery({
 *   variables: {
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useViewOvertimeApprovalHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewOvertimeApprovalHistoryQuery, ViewOvertimeApprovalHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewOvertimeApprovalHistoryQuery, ViewOvertimeApprovalHistoryQueryVariables>(ViewOvertimeApprovalHistoryDocument, baseOptions);
      }
export function useViewOvertimeApprovalHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewOvertimeApprovalHistoryQuery, ViewOvertimeApprovalHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewOvertimeApprovalHistoryQuery, ViewOvertimeApprovalHistoryQueryVariables>(ViewOvertimeApprovalHistoryDocument, baseOptions);
        }
export type ViewOvertimeApprovalHistoryQueryHookResult = ReturnType<typeof useViewOvertimeApprovalHistoryQuery>;
export type ViewOvertimeApprovalHistoryLazyQueryHookResult = ReturnType<typeof useViewOvertimeApprovalHistoryLazyQuery>;
export type ViewOvertimeApprovalHistoryQueryResult = ApolloReactCommon.QueryResult<ViewOvertimeApprovalHistoryQuery, ViewOvertimeApprovalHistoryQueryVariables>;
export const GetOvertimeToApproveDocument = gql`
    query GetOvertimeToApprove($CompanyID: String, $ApproverID: String!) {
  GetOvertimeToApprove(CompanyID: $CompanyID, ApproverID: $ApproverID) {
    OvertimeID
    EmployeeID
    CompanyID
    WorkDate
    StartTime
    EndTime
    Remark
    OvertimeReasonID
    ExcelFileName
    OvertimeStatus
    CreatedDT
    ModifiedDT
    ModifiedBy
    ApproverID
    RejectionRemarks
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    otReason {
      OvertimeReasonID
      Name
    }
    OvertimeHistory {
      OvertimeID
      EmployeeID
      CompanyID
      WorkDate
      StartTime
      EndTime
      Remark
      OvertimeReasonID
      ExcelFileName
      OvertimeStatus
      CreatedDT
      ModifiedDT
      ModifiedBy
      ApproverID
      RejectionRemarks
      Employee {
        EmployeeID
        Contact {
          ContactID
          FullName
          Email
          MobileNo
        }
        EmployeeNo
      }
      otReason {
        OvertimeReasonID
        Name
      }
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetOvertimeToApproveQuery__
 *
 * To run a query within a React component, call `useGetOvertimeToApproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOvertimeToApproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOvertimeToApproveQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useGetOvertimeToApproveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOvertimeToApproveQuery, GetOvertimeToApproveQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOvertimeToApproveQuery, GetOvertimeToApproveQueryVariables>(GetOvertimeToApproveDocument, baseOptions);
      }
export function useGetOvertimeToApproveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOvertimeToApproveQuery, GetOvertimeToApproveQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOvertimeToApproveQuery, GetOvertimeToApproveQueryVariables>(GetOvertimeToApproveDocument, baseOptions);
        }
export type GetOvertimeToApproveQueryHookResult = ReturnType<typeof useGetOvertimeToApproveQuery>;
export type GetOvertimeToApproveLazyQueryHookResult = ReturnType<typeof useGetOvertimeToApproveLazyQuery>;
export type GetOvertimeToApproveQueryResult = ApolloReactCommon.QueryResult<GetOvertimeToApproveQuery, GetOvertimeToApproveQueryVariables>;
export const GetTimeOffToApproveDocument = gql`
    query GetTimeOffToApprove($CompanyID: String, $ApproverID: String!) {
  GetTimeOffToApprove(CompanyID: $CompanyID, ApproverID: $ApproverID) {
    TimeOffID
    SubscriptionAccountID
    CompanyID
    EmployeeID
    Employee {
      EmployeeID
      EmployeeNo
      ContactID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
    }
    ToReason {
      TimeOffReasonID
      Name
    }
    CreatedDT
    ModifiedDT
    ToReasonID
    WorkDate
    Remark
    VisitingPlace
    TimeOffStatus
    EndTime
    StartTime
    TravelFrom
    TravelTo
    Reason
    Approver
    RejectionRemarks
    Document {
      DocumentID
      DocumentFile
    }
    TimeOffHistory {
      TimeOffID
      SubscriptionAccountID
      CompanyID
      EmployeeID
      Employee {
        EmployeeID
        EmployeeNo
        ContactID
        Contact {
          ContactID
          FullName
          Email
          MobileNo
        }
      }
      ToReason {
        TimeOffReasonID
        Name
      }
      Document {
        DocumentID
        DocumentType
        Description
        DocumentFile
        BucketFileName
        FileSize
      }
      CreatedDT
      ModifiedDT
      ToReasonID
      WorkDate
      Remark
      VisitingPlace
      TimeOffStatus
      EndTime
      StartTime
      TravelFrom
      TravelTo
      Reason
      Approver
      RejectionRemarks
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetTimeOffToApproveQuery__
 *
 * To run a query within a React component, call `useGetTimeOffToApproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeOffToApproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeOffToApproveQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useGetTimeOffToApproveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTimeOffToApproveQuery, GetTimeOffToApproveQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTimeOffToApproveQuery, GetTimeOffToApproveQueryVariables>(GetTimeOffToApproveDocument, baseOptions);
      }
export function useGetTimeOffToApproveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTimeOffToApproveQuery, GetTimeOffToApproveQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTimeOffToApproveQuery, GetTimeOffToApproveQueryVariables>(GetTimeOffToApproveDocument, baseOptions);
        }
export type GetTimeOffToApproveQueryHookResult = ReturnType<typeof useGetTimeOffToApproveQuery>;
export type GetTimeOffToApproveLazyQueryHookResult = ReturnType<typeof useGetTimeOffToApproveLazyQuery>;
export type GetTimeOffToApproveQueryResult = ApolloReactCommon.QueryResult<GetTimeOffToApproveQuery, GetTimeOffToApproveQueryVariables>;
export const GetTimeOffAproveToDoDocument = gql`
    query GetTimeOffAproveToDo($EmployeeID: String!) {
  GetTimeOffAproveToDo(EmployeeID: $EmployeeID) {
    TimeOffID
    SubscriptionAccountID
    CompanyID
    EmployeeID
    Employee {
      EmployeeID
      EmployeeNo
      ContactID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
    }
    ToReason {
      TimeOffReasonID
      Name
    }
    CreatedDT
    ModifiedDT
    ToReasonID
    WorkDate
    Remark
    VisitingPlace
    TimeOffStatus
    EndTime
    StartTime
    TravelFrom
    TravelTo
    Reason
    Approver
    RejectionRemarks
    Document {
      DocumentID
      DocumentFile
    }
    TimeOffHistory {
      TimeOffID
      SubscriptionAccountID
      CompanyID
      EmployeeID
      Employee {
        EmployeeID
        EmployeeNo
        ContactID
        Contact {
          ContactID
          FullName
          Email
          MobileNo
        }
      }
      ToReason {
        TimeOffReasonID
        Name
      }
      Document {
        DocumentID
        DocumentType
        Description
        DocumentFile
        BucketFileName
        FileSize
      }
      CreatedDT
      ModifiedDT
      ToReasonID
      WorkDate
      Remark
      VisitingPlace
      TimeOffStatus
      EndTime
      StartTime
      TravelFrom
      TravelTo
      Reason
      Approver
      RejectionRemarks
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetTimeOffAproveToDoQuery__
 *
 * To run a query within a React component, call `useGetTimeOffAproveToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimeOffAproveToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimeOffAproveToDoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetTimeOffAproveToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTimeOffAproveToDoQuery, GetTimeOffAproveToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTimeOffAproveToDoQuery, GetTimeOffAproveToDoQueryVariables>(GetTimeOffAproveToDoDocument, baseOptions);
      }
export function useGetTimeOffAproveToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTimeOffAproveToDoQuery, GetTimeOffAproveToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTimeOffAproveToDoQuery, GetTimeOffAproveToDoQueryVariables>(GetTimeOffAproveToDoDocument, baseOptions);
        }
export type GetTimeOffAproveToDoQueryHookResult = ReturnType<typeof useGetTimeOffAproveToDoQuery>;
export type GetTimeOffAproveToDoLazyQueryHookResult = ReturnType<typeof useGetTimeOffAproveToDoLazyQuery>;
export type GetTimeOffAproveToDoQueryResult = ApolloReactCommon.QueryResult<GetTimeOffAproveToDoQuery, GetTimeOffAproveToDoQueryVariables>;
export const ViewTimeOffApprovalHistoryDocument = gql`
    query ViewTimeOffApprovalHistory($ApproverID: String!) {
  ViewTimeOffApprovalHistory(ApproverID: $ApproverID) {
    TimeOffID
    Employee {
      EmployeeID
      EmployeeNo
      ContactID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
    }
    ToReason {
      TimeOffReasonID
      Name
    }
    CreatedDT
    TimeOffStatus
    StartTime
    EndTime
    Remark
    Document {
      DocumentID
      DocumentFile
      DocumentType
      Description
      BucketFileName
      FileSize
    }
    Approver
    ApproveDate
    Document {
      DocumentID
      DocumentType
      Description
      DocumentFile
      BucketFileName
      FileSize
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useViewTimeOffApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useViewTimeOffApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewTimeOffApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewTimeOffApprovalHistoryQuery({
 *   variables: {
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useViewTimeOffApprovalHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewTimeOffApprovalHistoryQuery, ViewTimeOffApprovalHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewTimeOffApprovalHistoryQuery, ViewTimeOffApprovalHistoryQueryVariables>(ViewTimeOffApprovalHistoryDocument, baseOptions);
      }
export function useViewTimeOffApprovalHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewTimeOffApprovalHistoryQuery, ViewTimeOffApprovalHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewTimeOffApprovalHistoryQuery, ViewTimeOffApprovalHistoryQueryVariables>(ViewTimeOffApprovalHistoryDocument, baseOptions);
        }
export type ViewTimeOffApprovalHistoryQueryHookResult = ReturnType<typeof useViewTimeOffApprovalHistoryQuery>;
export type ViewTimeOffApprovalHistoryLazyQueryHookResult = ReturnType<typeof useViewTimeOffApprovalHistoryLazyQuery>;
export type ViewTimeOffApprovalHistoryQueryResult = ApolloReactCommon.QueryResult<ViewTimeOffApprovalHistoryQuery, ViewTimeOffApprovalHistoryQueryVariables>;
export const GetChangeShiftApproveToDoDocument = gql`
    query GetChangeShiftApproveToDo($EmployeeID: String!) {
  GetChangeShiftApproveToDo(EmployeeID: $EmployeeID) {
    ChangeShiftID
    Date
    FromShift {
      ShiftID
      Name
    }
    ToShift {
      ShiftID
      Name
    }
    Reason
    RejectReason
    Approver
    ChangeShiftStatus
    CreatedDT
    ModifiedDT
    EmployeeID
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    DocumentID
    ReplacedEmployee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    ChangeShiftHistory {
      ChangeShiftID
      Date
      FromShift {
        ShiftID
        Name
      }
      ToShift {
        ShiftID
        Name
      }
      Reason
      RejectReason
      Approver
      ChangeShiftStatus
      CreatedDT
      Employee {
        EmployeeID
        Contact {
          ContactID
          FullName
          Email
          MobileNo
        }
        EmployeeNo
      }
      DocumentID
      ReplacedEmployee {
        EmployeeID
        EmployeeNo
        Contact {
          FullName
        }
      }
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetChangeShiftApproveToDoQuery__
 *
 * To run a query within a React component, call `useGetChangeShiftApproveToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeShiftApproveToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeShiftApproveToDoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetChangeShiftApproveToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetChangeShiftApproveToDoQuery, GetChangeShiftApproveToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetChangeShiftApproveToDoQuery, GetChangeShiftApproveToDoQueryVariables>(GetChangeShiftApproveToDoDocument, baseOptions);
      }
export function useGetChangeShiftApproveToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetChangeShiftApproveToDoQuery, GetChangeShiftApproveToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetChangeShiftApproveToDoQuery, GetChangeShiftApproveToDoQueryVariables>(GetChangeShiftApproveToDoDocument, baseOptions);
        }
export type GetChangeShiftApproveToDoQueryHookResult = ReturnType<typeof useGetChangeShiftApproveToDoQuery>;
export type GetChangeShiftApproveToDoLazyQueryHookResult = ReturnType<typeof useGetChangeShiftApproveToDoLazyQuery>;
export type GetChangeShiftApproveToDoQueryResult = ApolloReactCommon.QueryResult<GetChangeShiftApproveToDoQuery, GetChangeShiftApproveToDoQueryVariables>;
export const ViewChangeShiftApprovalHistoryDocument = gql`
    query ViewChangeShiftApprovalHistory($ApproverID: String!) {
  ViewChangeShiftApprovalHistory(ApproverID: $ApproverID) {
    ChangeShiftID
    Date
    FromShift {
      ShiftID
      Name
    }
    ToShift {
      ShiftID
      Name
    }
    Reason
    RejectReason
    Approver
    ApprovedDate
    ChangeShiftStatus
    CreatedDT
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    ReplacedEmployee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    ChangeShiftHistory {
      ChangeShiftID
      Date
      FromShift {
        ShiftID
        Name
      }
      ToShift {
        ShiftID
        Name
      }
      Reason
      RejectReason
      Approver
      ChangeShiftStatus
      CreatedDT
      Employee {
        EmployeeID
        Contact {
          ContactID
          FullName
          Email
          MobileNo
        }
        EmployeeNo
      }
      DocumentID
      ReplacedEmployee {
        EmployeeID
        EmployeeNo
        Contact {
          FullName
        }
      }
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useViewChangeShiftApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useViewChangeShiftApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewChangeShiftApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewChangeShiftApprovalHistoryQuery({
 *   variables: {
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useViewChangeShiftApprovalHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewChangeShiftApprovalHistoryQuery, ViewChangeShiftApprovalHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewChangeShiftApprovalHistoryQuery, ViewChangeShiftApprovalHistoryQueryVariables>(ViewChangeShiftApprovalHistoryDocument, baseOptions);
      }
export function useViewChangeShiftApprovalHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewChangeShiftApprovalHistoryQuery, ViewChangeShiftApprovalHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewChangeShiftApprovalHistoryQuery, ViewChangeShiftApprovalHistoryQueryVariables>(ViewChangeShiftApprovalHistoryDocument, baseOptions);
        }
export type ViewChangeShiftApprovalHistoryQueryHookResult = ReturnType<typeof useViewChangeShiftApprovalHistoryQuery>;
export type ViewChangeShiftApprovalHistoryLazyQueryHookResult = ReturnType<typeof useViewChangeShiftApprovalHistoryLazyQuery>;
export type ViewChangeShiftApprovalHistoryQueryResult = ApolloReactCommon.QueryResult<ViewChangeShiftApprovalHistoryQuery, ViewChangeShiftApprovalHistoryQueryVariables>;
export const GetChangeShiftToApproveDocument = gql`
    query GetChangeShiftToApprove($CompanyID: String, $ApproverID: String!) {
  GetChangeShiftToApprove(CompanyID: $CompanyID, ApproverID: $ApproverID) {
    ChangeShiftID
    Date
    FromShift {
      ShiftID
      Name
    }
    ToShift {
      ShiftID
      Name
    }
    Reason
    RejectReason
    Approver
    ChangeShiftStatus
    CreatedDT
    ModifiedDT
    EmployeeID
    Employee {
      EmployeeID
      Contact {
        ContactID
        FullName
        Email
        MobileNo
      }
      EmployeeNo
    }
    DocumentID
    ReplacedEmployee {
      EmployeeID
      EmployeeNo
      Contact {
        FullName
      }
    }
    ChangeShiftHistory {
      ChangeShiftID
      Date
      FromShift {
        ShiftID
        Name
      }
      ToShift {
        ShiftID
        Name
      }
      Reason
      RejectReason
      Approver
      ChangeShiftStatus
      CreatedDT
      Employee {
        EmployeeID
        Contact {
          ContactID
          FullName
          Email
          MobileNo
        }
        EmployeeNo
      }
      DocumentID
      ReplacedEmployee {
        EmployeeID
        EmployeeNo
        Contact {
          FullName
        }
      }
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetChangeShiftToApproveQuery__
 *
 * To run a query within a React component, call `useGetChangeShiftToApproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeShiftToApproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeShiftToApproveQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useGetChangeShiftToApproveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetChangeShiftToApproveQuery, GetChangeShiftToApproveQueryVariables>) {
        return ApolloReactHooks.useQuery<GetChangeShiftToApproveQuery, GetChangeShiftToApproveQueryVariables>(GetChangeShiftToApproveDocument, baseOptions);
      }
export function useGetChangeShiftToApproveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetChangeShiftToApproveQuery, GetChangeShiftToApproveQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetChangeShiftToApproveQuery, GetChangeShiftToApproveQueryVariables>(GetChangeShiftToApproveDocument, baseOptions);
        }
export type GetChangeShiftToApproveQueryHookResult = ReturnType<typeof useGetChangeShiftToApproveQuery>;
export type GetChangeShiftToApproveLazyQueryHookResult = ReturnType<typeof useGetChangeShiftToApproveLazyQuery>;
export type GetChangeShiftToApproveQueryResult = ApolloReactCommon.QueryResult<GetChangeShiftToApproveQuery, GetChangeShiftToApproveQueryVariables>;
export const ToDoStatusUpdateDocument = gql`
    mutation ToDoStatusUpdate($ApprovalStatus: ApprovalStatus!, $ChangeShiftIDs: [TrxIDs!]!, $TimeOffIDs: [TrxIDs!]!, $OvertimeIDs: [TrxIDs!]!, $ClaimHeaderIDs: [TrxIDs!]!, $RequisitionIDs: [TrxIDs!]!, $LeaveHeaderIDs: [TrxIDs!]!, $ProjectExpenseClaimHeaderIDs: [TrxIDs!]!, $DataChangesIDs: [TrxIDs!], $EmployeeID: String, $RejectedRemarks: String) {
  ToDoStatusUpdate(RejectedRemarks: $RejectedRemarks, EmployeeID: $EmployeeID, LeaveHeaderIDs: $LeaveHeaderIDs, ApprovalStatus: $ApprovalStatus, RequisitionIDs: $RequisitionIDs, ClaimHeaderIDs: $ClaimHeaderIDs, OvertimeIDs: $OvertimeIDs, TimeOffIDs: $TimeOffIDs, ChangeShiftIDs: $ChangeShiftIDs, ProjectExpenseClaimHeaderIDs: $ProjectExpenseClaimHeaderIDs, DataChangesIDs: $DataChangesIDs)
}
    `;
export type ToDoStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<ToDoStatusUpdateMutation, ToDoStatusUpdateMutationVariables>;

/**
 * __useToDoStatusUpdateMutation__
 *
 * To run a mutation, you first call `useToDoStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToDoStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toDoStatusUpdateMutation, { data, loading, error }] = useToDoStatusUpdateMutation({
 *   variables: {
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      ChangeShiftIDs: // value for 'ChangeShiftIDs'
 *      TimeOffIDs: // value for 'TimeOffIDs'
 *      OvertimeIDs: // value for 'OvertimeIDs'
 *      ClaimHeaderIDs: // value for 'ClaimHeaderIDs'
 *      RequisitionIDs: // value for 'RequisitionIDs'
 *      LeaveHeaderIDs: // value for 'LeaveHeaderIDs'
 *      ProjectExpenseClaimHeaderIDs: // value for 'ProjectExpenseClaimHeaderIDs'
 *      DataChangesIDs: // value for 'DataChangesIDs'
 *      EmployeeID: // value for 'EmployeeID'
 *      RejectedRemarks: // value for 'RejectedRemarks'
 *   },
 * });
 */
export function useToDoStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToDoStatusUpdateMutation, ToDoStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ToDoStatusUpdateMutation, ToDoStatusUpdateMutationVariables>(ToDoStatusUpdateDocument, baseOptions);
      }
export type ToDoStatusUpdateMutationHookResult = ReturnType<typeof useToDoStatusUpdateMutation>;
export type ToDoStatusUpdateMutationResult = ApolloReactCommon.MutationResult<ToDoStatusUpdateMutation>;
export type ToDoStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ToDoStatusUpdateMutation, ToDoStatusUpdateMutationVariables>;
export const CompanyAccessPermListDocument = gql`
    query CompanyAccessPermList($CompanyID: String!, $RoleID: String, $usePayload: Boolean) {
  CompanyAccessPermList(CompanyID: $CompanyID, RoleID: $RoleID, usePayload: $usePayload)
}
    `;

/**
 * __useCompanyAccessPermListQuery__
 *
 * To run a query within a React component, call `useCompanyAccessPermListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAccessPermListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAccessPermListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      RoleID: // value for 'RoleID'
 *      usePayload: // value for 'usePayload'
 *   },
 * });
 */
export function useCompanyAccessPermListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyAccessPermListQuery, CompanyAccessPermListQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyAccessPermListQuery, CompanyAccessPermListQueryVariables>(CompanyAccessPermListDocument, baseOptions);
      }
export function useCompanyAccessPermListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyAccessPermListQuery, CompanyAccessPermListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyAccessPermListQuery, CompanyAccessPermListQueryVariables>(CompanyAccessPermListDocument, baseOptions);
        }
export type CompanyAccessPermListQueryHookResult = ReturnType<typeof useCompanyAccessPermListQuery>;
export type CompanyAccessPermListLazyQueryHookResult = ReturnType<typeof useCompanyAccessPermListLazyQuery>;
export type CompanyAccessPermListQueryResult = ApolloReactCommon.QueryResult<CompanyAccessPermListQuery, CompanyAccessPermListQueryVariables>;
export const GetProjectToApproveDocument = gql`
    query GetProjectToApprove($CompanyID: String, $ApproverID: String!) {
  GetProjectToApprove(CompanyID: $CompanyID, ApproverID: $ApproverID) {
    ApprovalStatus
    ApproverName
    ApprovalDate
    ClaimHeaderID
    CreatedDT
    EmployeeID
    EmployeeName
    EmployeeNo
    Subject
    TotalClaim
    TotalClaimAmount
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
    ClaimRecordsDetail {
      ClaimAmount
      ClaimRemarks
      ID
      ReceiptDate
      ReceiptNo
      ProjectExpenseTypeID
      ProjectExpenseType
      DocumentIDs {
        Status
        CreatedDT
        CreatedBy
        ModifiedDT
        ModifiedBy
        DocumentID
        DocumentType
        Description
        DocumentFile
        PayPeriodID
        ParentID
        BucketFileName
        FileSize
      }
    }
    ClaimHistory {
      ApprovalStatus
      ClaimHeaderID
      CreatedDT
      EmployeeID
      Subject
      TotalClaim
      TotalClaimAmount
      IsRejected
      ClaimDetails {
        ClaimAmount
        ClaimRemark
        ID
      }
    }
    DocumentIDs {
      Status
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      DocumentID
      DocumentType
      Description
      DocumentFile
      PayPeriodID
      ParentID
      BucketFileName
      FileSize
    }
  }
}
    `;

/**
 * __useGetProjectToApproveQuery__
 *
 * To run a query within a React component, call `useGetProjectToApproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectToApproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectToApproveQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useGetProjectToApproveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectToApproveQuery, GetProjectToApproveQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectToApproveQuery, GetProjectToApproveQueryVariables>(GetProjectToApproveDocument, baseOptions);
      }
export function useGetProjectToApproveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectToApproveQuery, GetProjectToApproveQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectToApproveQuery, GetProjectToApproveQueryVariables>(GetProjectToApproveDocument, baseOptions);
        }
export type GetProjectToApproveQueryHookResult = ReturnType<typeof useGetProjectToApproveQuery>;
export type GetProjectToApproveLazyQueryHookResult = ReturnType<typeof useGetProjectToApproveLazyQuery>;
export type GetProjectToApproveQueryResult = ApolloReactCommon.QueryResult<GetProjectToApproveQuery, GetProjectToApproveQueryVariables>;
export const GetDataChangesToDoDocument = gql`
    query GetDataChangesToDo($EmployeeID: String!) {
  GetDataChangesToDo(EmployeeID: $EmployeeID) {
    DataChangesID
    Contact {
      ContactID
      FullName
    }
    Employee {
      EmployeeID
      EmployeeNo
    }
    CompanyID
    Type
    ChangesData {
      DocumentID
      Nationality
      PassportNo
      NRIC
      Marital
      Race
      Religion
      IsOKU
      EmployeeBank
      EmployeeBankNo
      EmployeeEPFNo
      EmployeePCBNo
      PermanentAddress {
        Line1
        Line2
        City
        PostalCode
        State
        Country
      }
      Address {
        Line1
        Line2
        City
        PostalCode
        State
        Country
      }
      EmployeeDependentsID
      ContactID
      IsEmergencyContact
      MobileNo
      Occupation
      Education
      ChildEligibleRate
      PCBNo
      FullName
      BirthDate
    }
    OldData {
      DocumentID
      Nationality
      PassportNo
      NRIC
      Marital
      Race
      Religion
      IsOKU
      EmployeeBank
      EmployeeBankNo
      EmployeeEPFNo
      EmployeePCBNo
      PermanentAddress {
        Line1
        Line2
        City
        PostalCode
        State
        Country
      }
      Address {
        Line1
        Line2
        City
        PostalCode
        State
        Country
      }
      EmployeeDependentsID
      ContactID
      IsEmergencyContact
      MobileNo
      Occupation
      Education
      ChildEligibleRate
      PCBNo
      FullName
      BirthDate
    }
    ApprovalStatus
    CreatedDT
    ModifiedDT
    Remarks
    Description
    Action
    DataChangeHistory {
      ApprovalStatus
      Description
      DataChangesID
      CreatedDT
      Type
      Employee {
        EmployeeID
        EmployeeNo
      }
      ChangesData {
        DocumentID
        Nationality
        PassportNo
        NRIC
        Marital
        Race
        Religion
        IsOKU
        EmployeeBank
        EmployeeBankNo
        EmployeeEPFNo
        EmployeePCBNo
        PermanentAddress {
          Line1
          Line2
          City
          PostalCode
          State
          Country
        }
        Address {
          Line1
          Line2
          City
          PostalCode
          State
          Country
        }
        EmployeeDependentsID
        ContactID
        IsEmergencyContact
        MobileNo
        Occupation
        Education
        ChildEligibleRate
        PCBNo
        FullName
        BirthDate
      }
    }
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useGetDataChangesToDoQuery__
 *
 * To run a query within a React component, call `useGetDataChangesToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataChangesToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataChangesToDoQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *   },
 * });
 */
export function useGetDataChangesToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDataChangesToDoQuery, GetDataChangesToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDataChangesToDoQuery, GetDataChangesToDoQueryVariables>(GetDataChangesToDoDocument, baseOptions);
      }
export function useGetDataChangesToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDataChangesToDoQuery, GetDataChangesToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDataChangesToDoQuery, GetDataChangesToDoQueryVariables>(GetDataChangesToDoDocument, baseOptions);
        }
export type GetDataChangesToDoQueryHookResult = ReturnType<typeof useGetDataChangesToDoQuery>;
export type GetDataChangesToDoLazyQueryHookResult = ReturnType<typeof useGetDataChangesToDoLazyQuery>;
export type GetDataChangesToDoQueryResult = ApolloReactCommon.QueryResult<GetDataChangesToDoQuery, GetDataChangesToDoQueryVariables>;
export const ViewDataChangesApprovalHistoryDocument = gql`
    query ViewDataChangesApprovalHistory($ApproverID: String!) {
  ViewDataChangesApprovalHistory(ApproverID: $ApproverID) {
    DataChangesID
    Contact {
      ContactID
      FullName
    }
    ChangesData {
      DocumentID
    }
    Employee {
      EmployeeID
      EmployeeNo
    }
    ApprovalDT
    ApproverID
    Approver {
      EmployeeID
      Contact {
        FullName
      }
    }
    CompanyID
    Type
    ApprovalStatus
    CreatedDT
    Description
    Action
    WorkFlowCurrentApproversList
    WorkFlow {
      ID
      WorkFlowStatus
      SubmitterName
      SubmitDate
      WorkFlowStep {
        ID
        ApprovalStatus
        ApproverRoleID
        StepNo
        RoleName
        WorkFlowApprovedStep {
          ID
          ApproverID
          ApproverName
          NotifiedDT
          ApprovalStatus
          StatusRemarks
          ApprovalDT
        }
      }
    }
  }
}
    `;

/**
 * __useViewDataChangesApprovalHistoryQuery__
 *
 * To run a query within a React component, call `useViewDataChangesApprovalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDataChangesApprovalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDataChangesApprovalHistoryQuery({
 *   variables: {
 *      ApproverID: // value for 'ApproverID'
 *   },
 * });
 */
export function useViewDataChangesApprovalHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewDataChangesApprovalHistoryQuery, ViewDataChangesApprovalHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewDataChangesApprovalHistoryQuery, ViewDataChangesApprovalHistoryQueryVariables>(ViewDataChangesApprovalHistoryDocument, baseOptions);
      }
export function useViewDataChangesApprovalHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewDataChangesApprovalHistoryQuery, ViewDataChangesApprovalHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewDataChangesApprovalHistoryQuery, ViewDataChangesApprovalHistoryQueryVariables>(ViewDataChangesApprovalHistoryDocument, baseOptions);
        }
export type ViewDataChangesApprovalHistoryQueryHookResult = ReturnType<typeof useViewDataChangesApprovalHistoryQuery>;
export type ViewDataChangesApprovalHistoryLazyQueryHookResult = ReturnType<typeof useViewDataChangesApprovalHistoryLazyQuery>;
export type ViewDataChangesApprovalHistoryQueryResult = ApolloReactCommon.QueryResult<ViewDataChangesApprovalHistoryQuery, ViewDataChangesApprovalHistoryQueryVariables>;
export const TrainingCostListingDocument = gql`
    query TrainingCostListing($ScheduledTrainingID: String!) {
  TrainingCostListing(ScheduledTrainingID: $ScheduledTrainingID) {
    TrainingCostID
    ScheduledTrainingID
    TrainingCostItemID
    TrainingCostItem {
      Name
    }
    Cost
    Remarks
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useTrainingCostListingQuery__
 *
 * To run a query within a React component, call `useTrainingCostListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrainingCostListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrainingCostListingQuery({
 *   variables: {
 *      ScheduledTrainingID: // value for 'ScheduledTrainingID'
 *   },
 * });
 */
export function useTrainingCostListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrainingCostListingQuery, TrainingCostListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TrainingCostListingQuery, TrainingCostListingQueryVariables>(TrainingCostListingDocument, baseOptions);
      }
export function useTrainingCostListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrainingCostListingQuery, TrainingCostListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrainingCostListingQuery, TrainingCostListingQueryVariables>(TrainingCostListingDocument, baseOptions);
        }
export type TrainingCostListingQueryHookResult = ReturnType<typeof useTrainingCostListingQuery>;
export type TrainingCostListingLazyQueryHookResult = ReturnType<typeof useTrainingCostListingLazyQuery>;
export type TrainingCostListingQueryResult = ApolloReactCommon.QueryResult<TrainingCostListingQuery, TrainingCostListingQueryVariables>;
export const TrainingCostSubmitDocument = gql`
    mutation TrainingCostSubmit($ScheduleTrainingID: String!, $input: [TrainingCostInput!]!) {
  TrainingCostSubmit(ScheduleTrainingID: $ScheduleTrainingID, input: $input)
}
    `;
export type TrainingCostSubmitMutationFn = ApolloReactCommon.MutationFunction<TrainingCostSubmitMutation, TrainingCostSubmitMutationVariables>;

/**
 * __useTrainingCostSubmitMutation__
 *
 * To run a mutation, you first call `useTrainingCostSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrainingCostSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trainingCostSubmitMutation, { data, loading, error }] = useTrainingCostSubmitMutation({
 *   variables: {
 *      ScheduleTrainingID: // value for 'ScheduleTrainingID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrainingCostSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TrainingCostSubmitMutation, TrainingCostSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<TrainingCostSubmitMutation, TrainingCostSubmitMutationVariables>(TrainingCostSubmitDocument, baseOptions);
      }
export type TrainingCostSubmitMutationHookResult = ReturnType<typeof useTrainingCostSubmitMutation>;
export type TrainingCostSubmitMutationResult = ApolloReactCommon.MutationResult<TrainingCostSubmitMutation>;
export type TrainingCostSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<TrainingCostSubmitMutation, TrainingCostSubmitMutationVariables>;
export const CostAllocationListingDocument = gql`
    query CostAllocationListing($ScheduledTrainingID: String!) {
  CostAllocationListing(ScheduledTrainingID: $ScheduledTrainingID) {
    CostAllocationID
    CompanyID
    ScheduledTrainingID
    TrainingAttendeeID
    OrgUnitID
    AllocateAmount
    SubscriptionAccountID
    Status
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
  }
}
    `;

/**
 * __useCostAllocationListingQuery__
 *
 * To run a query within a React component, call `useCostAllocationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostAllocationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostAllocationListingQuery({
 *   variables: {
 *      ScheduledTrainingID: // value for 'ScheduledTrainingID'
 *   },
 * });
 */
export function useCostAllocationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CostAllocationListingQuery, CostAllocationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CostAllocationListingQuery, CostAllocationListingQueryVariables>(CostAllocationListingDocument, baseOptions);
      }
export function useCostAllocationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CostAllocationListingQuery, CostAllocationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CostAllocationListingQuery, CostAllocationListingQueryVariables>(CostAllocationListingDocument, baseOptions);
        }
export type CostAllocationListingQueryHookResult = ReturnType<typeof useCostAllocationListingQuery>;
export type CostAllocationListingLazyQueryHookResult = ReturnType<typeof useCostAllocationListingLazyQuery>;
export type CostAllocationListingQueryResult = ApolloReactCommon.QueryResult<CostAllocationListingQuery, CostAllocationListingQueryVariables>;
export const HrdfClaimListingDocument = gql`
    query HRDFClaimListing($CompanyID: String!) {
  HRDFClaimListing(CompanyID: $CompanyID) {
    ScheduledTrainingID
    LatestClaimAmount
    LatestClaimRefNo
  }
}
    `;

/**
 * __useHrdfClaimListingQuery__
 *
 * To run a query within a React component, call `useHrdfClaimListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHrdfClaimListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHrdfClaimListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useHrdfClaimListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HrdfClaimListingQuery, HrdfClaimListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HrdfClaimListingQuery, HrdfClaimListingQueryVariables>(HrdfClaimListingDocument, baseOptions);
      }
export function useHrdfClaimListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HrdfClaimListingQuery, HrdfClaimListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HrdfClaimListingQuery, HrdfClaimListingQueryVariables>(HrdfClaimListingDocument, baseOptions);
        }
export type HrdfClaimListingQueryHookResult = ReturnType<typeof useHrdfClaimListingQuery>;
export type HrdfClaimListingLazyQueryHookResult = ReturnType<typeof useHrdfClaimListingLazyQuery>;
export type HrdfClaimListingQueryResult = ApolloReactCommon.QueryResult<HrdfClaimListingQuery, HrdfClaimListingQueryVariables>;
export const CostAllocationSubmitDocument = gql`
    mutation CostAllocationSubmit($ScheduleTrainingID: String!, $input: [CostAllocationInput!]!) {
  CostAllocationSubmit(ScheduleTrainingID: $ScheduleTrainingID, input: $input)
}
    `;
export type CostAllocationSubmitMutationFn = ApolloReactCommon.MutationFunction<CostAllocationSubmitMutation, CostAllocationSubmitMutationVariables>;

/**
 * __useCostAllocationSubmitMutation__
 *
 * To run a mutation, you first call `useCostAllocationSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCostAllocationSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [costAllocationSubmitMutation, { data, loading, error }] = useCostAllocationSubmitMutation({
 *   variables: {
 *      ScheduleTrainingID: // value for 'ScheduleTrainingID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCostAllocationSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CostAllocationSubmitMutation, CostAllocationSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<CostAllocationSubmitMutation, CostAllocationSubmitMutationVariables>(CostAllocationSubmitDocument, baseOptions);
      }
export type CostAllocationSubmitMutationHookResult = ReturnType<typeof useCostAllocationSubmitMutation>;
export type CostAllocationSubmitMutationResult = ApolloReactCommon.MutationResult<CostAllocationSubmitMutation>;
export type CostAllocationSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<CostAllocationSubmitMutation, CostAllocationSubmitMutationVariables>;
export const HrdfClaimSubmitDocument = gql`
    mutation HRDFClaimSubmit($input: HRDFClaimInput!) {
  HRDFClaimSubmit(input: $input)
}
    `;
export type HrdfClaimSubmitMutationFn = ApolloReactCommon.MutationFunction<HrdfClaimSubmitMutation, HrdfClaimSubmitMutationVariables>;

/**
 * __useHrdfClaimSubmitMutation__
 *
 * To run a mutation, you first call `useHrdfClaimSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHrdfClaimSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hrdfClaimSubmitMutation, { data, loading, error }] = useHrdfClaimSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHrdfClaimSubmitMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HrdfClaimSubmitMutation, HrdfClaimSubmitMutationVariables>) {
        return ApolloReactHooks.useMutation<HrdfClaimSubmitMutation, HrdfClaimSubmitMutationVariables>(HrdfClaimSubmitDocument, baseOptions);
      }
export type HrdfClaimSubmitMutationHookResult = ReturnType<typeof useHrdfClaimSubmitMutation>;
export type HrdfClaimSubmitMutationResult = ApolloReactCommon.MutationResult<HrdfClaimSubmitMutation>;
export type HrdfClaimSubmitMutationOptions = ApolloReactCommon.BaseMutationOptions<HrdfClaimSubmitMutation, HrdfClaimSubmitMutationVariables>;
export const GetUserListingDocument = gql`
    query getUserListing($UserStatus: String, $IsSuperUser: Boolean) {
  getUserListing(UserStatus: $UserStatus, IsSuperUser: $IsSuperUser)
}
    `;

/**
 * __useGetUserListingQuery__
 *
 * To run a query within a React component, call `useGetUserListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListingQuery({
 *   variables: {
 *      UserStatus: // value for 'UserStatus'
 *      IsSuperUser: // value for 'IsSuperUser'
 *   },
 * });
 */
export function useGetUserListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserListingQuery, GetUserListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserListingQuery, GetUserListingQueryVariables>(GetUserListingDocument, baseOptions);
      }
export function useGetUserListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserListingQuery, GetUserListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserListingQuery, GetUserListingQueryVariables>(GetUserListingDocument, baseOptions);
        }
export type GetUserListingQueryHookResult = ReturnType<typeof useGetUserListingQuery>;
export type GetUserListingLazyQueryHookResult = ReturnType<typeof useGetUserListingLazyQuery>;
export type GetUserListingQueryResult = ApolloReactCommon.QueryResult<GetUserListingQuery, GetUserListingQueryVariables>;
export const GetEmployeeUserListingDocument = gql`
    query getEmployeeUserListing($CompanyID: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  getEmployeeUserListing(EmployeeType: $EmployeeType, EmployeeStatus: $EmployeeStatus, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetEmployeeUserListingQuery__
 *
 * To run a query within a React component, call `useGetEmployeeUserListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeUserListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeUserListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useGetEmployeeUserListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeUserListingQuery, GetEmployeeUserListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeUserListingQuery, GetEmployeeUserListingQueryVariables>(GetEmployeeUserListingDocument, baseOptions);
      }
export function useGetEmployeeUserListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeUserListingQuery, GetEmployeeUserListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeUserListingQuery, GetEmployeeUserListingQueryVariables>(GetEmployeeUserListingDocument, baseOptions);
        }
export type GetEmployeeUserListingQueryHookResult = ReturnType<typeof useGetEmployeeUserListingQuery>;
export type GetEmployeeUserListingLazyQueryHookResult = ReturnType<typeof useGetEmployeeUserListingLazyQuery>;
export type GetEmployeeUserListingQueryResult = ApolloReactCommon.QueryResult<GetEmployeeUserListingQuery, GetEmployeeUserListingQueryVariables>;
export const GetUsersByAccountDocument = gql`
    query getUsersByAccount($accountID: String!, $CompanyID: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  getUsersByAccount(accountID: $accountID, EmployeeType: $EmployeeType, EmployeeStatus: $EmployeeStatus, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetUsersByAccountQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useGetUsersByAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>(GetUsersByAccountDocument, baseOptions);
      }
export function useGetUsersByAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>(GetUsersByAccountDocument, baseOptions);
        }
export type GetUsersByAccountQueryHookResult = ReturnType<typeof useGetUsersByAccountQuery>;
export type GetUsersByAccountLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountLazyQuery>;
export type GetUsersByAccountQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>;
export const GetActiveUsersByAccountDocument = gql`
    query getActiveUsersByAccount($CompanyID: [String!], $EmployeeStatus: [String!], $EmployeeType: [String!], $OrgUnitID: [String!]) {
  getActiveUsersByAccount(EmployeeType: $EmployeeType, EmployeeStatus: $EmployeeStatus, OrgUnitID: $OrgUnitID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetActiveUsersByAccountQuery__
 *
 * To run a query within a React component, call `useGetActiveUsersByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveUsersByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveUsersByAccountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      EmployeeStatus: // value for 'EmployeeStatus'
 *      EmployeeType: // value for 'EmployeeType'
 *      OrgUnitID: // value for 'OrgUnitID'
 *   },
 * });
 */
export function useGetActiveUsersByAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveUsersByAccountQuery, GetActiveUsersByAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActiveUsersByAccountQuery, GetActiveUsersByAccountQueryVariables>(GetActiveUsersByAccountDocument, baseOptions);
      }
export function useGetActiveUsersByAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveUsersByAccountQuery, GetActiveUsersByAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActiveUsersByAccountQuery, GetActiveUsersByAccountQueryVariables>(GetActiveUsersByAccountDocument, baseOptions);
        }
export type GetActiveUsersByAccountQueryHookResult = ReturnType<typeof useGetActiveUsersByAccountQuery>;
export type GetActiveUsersByAccountLazyQueryHookResult = ReturnType<typeof useGetActiveUsersByAccountLazyQuery>;
export type GetActiveUsersByAccountQueryResult = ApolloReactCommon.QueryResult<GetActiveUsersByAccountQuery, GetActiveUsersByAccountQueryVariables>;
export const GetExceededUserWarningDocument = gql`
    query GetExceededUserWarning {
  GetExceededUserWarning
}
    `;

/**
 * __useGetExceededUserWarningQuery__
 *
 * To run a query within a React component, call `useGetExceededUserWarningQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExceededUserWarningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExceededUserWarningQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExceededUserWarningQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExceededUserWarningQuery, GetExceededUserWarningQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExceededUserWarningQuery, GetExceededUserWarningQueryVariables>(GetExceededUserWarningDocument, baseOptions);
      }
export function useGetExceededUserWarningLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExceededUserWarningQuery, GetExceededUserWarningQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExceededUserWarningQuery, GetExceededUserWarningQueryVariables>(GetExceededUserWarningDocument, baseOptions);
        }
export type GetExceededUserWarningQueryHookResult = ReturnType<typeof useGetExceededUserWarningQuery>;
export type GetExceededUserWarningLazyQueryHookResult = ReturnType<typeof useGetExceededUserWarningLazyQuery>;
export type GetExceededUserWarningQueryResult = ApolloReactCommon.QueryResult<GetExceededUserWarningQuery, GetExceededUserWarningQueryVariables>;
export const UserRoleIDbyUserDocument = gql`
    query userRoleIDbyUser($userID: String!, $roleID: String) {
  userRoleIDbyUser(userID: $userID, roleID: $roleID) {
    roleID
    userID
    entityID
  }
}
    `;

/**
 * __useUserRoleIDbyUserQuery__
 *
 * To run a query within a React component, call `useUserRoleIDbyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleIDbyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleIDbyUserQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useUserRoleIDbyUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserRoleIDbyUserQuery, UserRoleIDbyUserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserRoleIDbyUserQuery, UserRoleIDbyUserQueryVariables>(UserRoleIDbyUserDocument, baseOptions);
      }
export function useUserRoleIDbyUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRoleIDbyUserQuery, UserRoleIDbyUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserRoleIDbyUserQuery, UserRoleIDbyUserQueryVariables>(UserRoleIDbyUserDocument, baseOptions);
        }
export type UserRoleIDbyUserQueryHookResult = ReturnType<typeof useUserRoleIDbyUserQuery>;
export type UserRoleIDbyUserLazyQueryHookResult = ReturnType<typeof useUserRoleIDbyUserLazyQuery>;
export type UserRoleIDbyUserQueryResult = ApolloReactCommon.QueryResult<UserRoleIDbyUserQuery, UserRoleIDbyUserQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserInput!, $accountName: String!) {
  createUser(input: $input, accountName: $accountName) {
    ID
    email
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      accountName: // value for 'accountName'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const ResendInvitationDocument = gql`
    mutation resendInvitation($userID: String!, $accountName: String!, $accountID: String!) {
  resendInvitation(userID: $userID, accountName: $accountName, accountID: $accountID)
}
    `;
export type ResendInvitationMutationFn = ApolloReactCommon.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      accountName: // value for 'accountName'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, baseOptions);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = ApolloReactCommon.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserInput!, $companyID: String) {
  updateUser(input: $input, companyID: $companyID) {
    ID
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ChangeUserPasswordDocument = gql`
    mutation changeUserPassword($newPassword: String!, $currentPassword: String!, $accountID: String!, $userID: String!) {
  changeUserPassword(newPassword: $newPassword, currentPassword: $currentPassword, userID: $userID, accountID: $accountID)
}
    `;
export type ChangeUserPasswordMutationFn = ApolloReactCommon.MutationFunction<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *      accountID: // value for 'accountID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>(ChangeUserPasswordDocument, baseOptions);
      }
export type ChangeUserPasswordMutationHookResult = ReturnType<typeof useChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationResult = ApolloReactCommon.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;
export const UnblockUserDocument = gql`
    mutation unblockUser($input: passwordInfo!, $accountID: String!) {
  unblockUser(input: $input, accountID: $accountID)
}
    `;
export type UnblockUserMutationFn = ApolloReactCommon.MutationFunction<UnblockUserMutation, UnblockUserMutationVariables>;

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useUnblockUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnblockUserMutation, UnblockUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(UnblockUserDocument, baseOptions);
      }
export type UnblockUserMutationHookResult = ReturnType<typeof useUnblockUserMutation>;
export type UnblockUserMutationResult = ApolloReactCommon.MutationResult<UnblockUserMutation>;
export type UnblockUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UnblockUserMutation, UnblockUserMutationVariables>;
export const UserActionStatusDocument = gql`
    mutation userActionStatus($input: actionStatus!, $accountID: String!) {
  userActionStatus(input: $input, accountID: $accountID)
}
    `;
export type UserActionStatusMutationFn = ApolloReactCommon.MutationFunction<UserActionStatusMutation, UserActionStatusMutationVariables>;

/**
 * __useUserActionStatusMutation__
 *
 * To run a mutation, you first call `useUserActionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserActionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userActionStatusMutation, { data, loading, error }] = useUserActionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useUserActionStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserActionStatusMutation, UserActionStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UserActionStatusMutation, UserActionStatusMutationVariables>(UserActionStatusDocument, baseOptions);
      }
export type UserActionStatusMutationHookResult = ReturnType<typeof useUserActionStatusMutation>;
export type UserActionStatusMutationResult = ApolloReactCommon.MutationResult<UserActionStatusMutation>;
export type UserActionStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<UserActionStatusMutation, UserActionStatusMutationVariables>;
export const GetNonAccessUsersDocument = gql`
    query GetNonAccessUsers {
  GetNonAccessUsers
}
    `;

/**
 * __useGetNonAccessUsersQuery__
 *
 * To run a query within a React component, call `useGetNonAccessUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonAccessUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonAccessUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNonAccessUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNonAccessUsersQuery, GetNonAccessUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNonAccessUsersQuery, GetNonAccessUsersQueryVariables>(GetNonAccessUsersDocument, baseOptions);
      }
export function useGetNonAccessUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNonAccessUsersQuery, GetNonAccessUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNonAccessUsersQuery, GetNonAccessUsersQueryVariables>(GetNonAccessUsersDocument, baseOptions);
        }
export type GetNonAccessUsersQueryHookResult = ReturnType<typeof useGetNonAccessUsersQuery>;
export type GetNonAccessUsersLazyQueryHookResult = ReturnType<typeof useGetNonAccessUsersLazyQuery>;
export type GetNonAccessUsersQueryResult = ApolloReactCommon.QueryResult<GetNonAccessUsersQuery, GetNonAccessUsersQueryVariables>;
export const ResendInvitationByIdDocument = gql`
    mutation ResendInvitationById($data: [JSON!]!) {
  ResendInvitationById(data: $data)
}
    `;
export type ResendInvitationByIdMutationFn = ApolloReactCommon.MutationFunction<ResendInvitationByIdMutation, ResendInvitationByIdMutationVariables>;

/**
 * __useResendInvitationByIdMutation__
 *
 * To run a mutation, you first call `useResendInvitationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationByIdMutation, { data, loading, error }] = useResendInvitationByIdMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResendInvitationByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendInvitationByIdMutation, ResendInvitationByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendInvitationByIdMutation, ResendInvitationByIdMutationVariables>(ResendInvitationByIdDocument, baseOptions);
      }
export type ResendInvitationByIdMutationHookResult = ReturnType<typeof useResendInvitationByIdMutation>;
export type ResendInvitationByIdMutationResult = ApolloReactCommon.MutationResult<ResendInvitationByIdMutation>;
export type ResendInvitationByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendInvitationByIdMutation, ResendInvitationByIdMutationVariables>;
export const UpdateHelpdeskAssigneeDocument = gql`
    mutation updateHelpdeskAssignee($input: updateHelpdeskAssigneeInput!) {
  updateHelpdeskAssignee(input: $input)
}
    `;
export type UpdateHelpdeskAssigneeMutationFn = ApolloReactCommon.MutationFunction<UpdateHelpdeskAssigneeMutation, UpdateHelpdeskAssigneeMutationVariables>;

/**
 * __useUpdateHelpdeskAssigneeMutation__
 *
 * To run a mutation, you first call `useUpdateHelpdeskAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHelpdeskAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHelpdeskAssigneeMutation, { data, loading, error }] = useUpdateHelpdeskAssigneeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHelpdeskAssigneeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateHelpdeskAssigneeMutation, UpdateHelpdeskAssigneeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateHelpdeskAssigneeMutation, UpdateHelpdeskAssigneeMutationVariables>(UpdateHelpdeskAssigneeDocument, baseOptions);
      }
export type UpdateHelpdeskAssigneeMutationHookResult = ReturnType<typeof useUpdateHelpdeskAssigneeMutation>;
export type UpdateHelpdeskAssigneeMutationResult = ApolloReactCommon.MutationResult<UpdateHelpdeskAssigneeMutation>;
export type UpdateHelpdeskAssigneeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateHelpdeskAssigneeMutation, UpdateHelpdeskAssigneeMutationVariables>;
export const GetDutyRosterByEmployeeDocument = gql`
    query getDutyRosterByEmployee($EmployeeID: String!, $FromDate: String!, $ToDate: String!, $GetLeaveTimeOffOvertimeData: Boolean) {
  getDutyRosterByEmployee(EmployeeID: $EmployeeID, FromDate: $FromDate, ToDate: $ToDate, GetLeaveTimeOffOvertimeData: $GetLeaveTimeOffOvertimeData)
}
    `;

/**
 * __useGetDutyRosterByEmployeeQuery__
 *
 * To run a query within a React component, call `useGetDutyRosterByEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDutyRosterByEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDutyRosterByEmployeeQuery({
 *   variables: {
 *      EmployeeID: // value for 'EmployeeID'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      GetLeaveTimeOffOvertimeData: // value for 'GetLeaveTimeOffOvertimeData'
 *   },
 * });
 */
export function useGetDutyRosterByEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDutyRosterByEmployeeQuery, GetDutyRosterByEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDutyRosterByEmployeeQuery, GetDutyRosterByEmployeeQueryVariables>(GetDutyRosterByEmployeeDocument, baseOptions);
      }
export function useGetDutyRosterByEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDutyRosterByEmployeeQuery, GetDutyRosterByEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDutyRosterByEmployeeQuery, GetDutyRosterByEmployeeQueryVariables>(GetDutyRosterByEmployeeDocument, baseOptions);
        }
export type GetDutyRosterByEmployeeQueryHookResult = ReturnType<typeof useGetDutyRosterByEmployeeQuery>;
export type GetDutyRosterByEmployeeLazyQueryHookResult = ReturnType<typeof useGetDutyRosterByEmployeeLazyQuery>;
export type GetDutyRosterByEmployeeQueryResult = ApolloReactCommon.QueryResult<GetDutyRosterByEmployeeQuery, GetDutyRosterByEmployeeQueryVariables>;
export const GetDutyRosterByEmployeeListDocument = gql`
    query getDutyRosterByEmployeeList($EmployeeIDs: [String!], $FromDate: String!, $ToDate: String!, $CompanyID: String) {
  getDutyRosterByEmployeeList(EmployeeIDs: $EmployeeIDs, FromDate: $FromDate, ToDate: $ToDate, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetDutyRosterByEmployeeListQuery__
 *
 * To run a query within a React component, call `useGetDutyRosterByEmployeeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDutyRosterByEmployeeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDutyRosterByEmployeeListQuery({
 *   variables: {
 *      EmployeeIDs: // value for 'EmployeeIDs'
 *      FromDate: // value for 'FromDate'
 *      ToDate: // value for 'ToDate'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetDutyRosterByEmployeeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDutyRosterByEmployeeListQuery, GetDutyRosterByEmployeeListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDutyRosterByEmployeeListQuery, GetDutyRosterByEmployeeListQueryVariables>(GetDutyRosterByEmployeeListDocument, baseOptions);
      }
export function useGetDutyRosterByEmployeeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDutyRosterByEmployeeListQuery, GetDutyRosterByEmployeeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDutyRosterByEmployeeListQuery, GetDutyRosterByEmployeeListQueryVariables>(GetDutyRosterByEmployeeListDocument, baseOptions);
        }
export type GetDutyRosterByEmployeeListQueryHookResult = ReturnType<typeof useGetDutyRosterByEmployeeListQuery>;
export type GetDutyRosterByEmployeeListLazyQueryHookResult = ReturnType<typeof useGetDutyRosterByEmployeeListLazyQuery>;
export type GetDutyRosterByEmployeeListQueryResult = ApolloReactCommon.QueryResult<GetDutyRosterByEmployeeListQuery, GetDutyRosterByEmployeeListQueryVariables>;
export const namedOperations = {
  Query: {
    loggedInUserProfile: 'loggedInUserProfile',
    IsSuperUserEmployee: 'IsSuperUserEmployee',
    loggedInEmployeeProfile: 'loggedInEmployeeProfile',
    UserProfilePic: 'UserProfilePic',
    GetEmployeeKPITempleteLatestVersion: 'GetEmployeeKPITempleteLatestVersion',
    GetAppraisalDefaultSettingByJobPosition: 'GetAppraisalDefaultSettingByJobPosition',
    GetCompanyAppraisalPolicy: 'GetCompanyAppraisalPolicy',
    AppraisalCategoryItemListingAll: 'AppraisalCategoryItemListingAll',
    AppraisalPolicyListing: 'AppraisalPolicyListing',
    AppraisalCategoryListingAll: 'AppraisalCategoryListingAll',
    GetEmployeeAppraisalHeader: 'GetEmployeeAppraisalHeader',
    EmployeeListing: 'EmployeeListing',
    EmployeeListingCount: 'EmployeeListingCount',
    GetAppraisalCompanySetting: 'GetAppraisalCompanySetting',
    GetCompanyAppraisalTemplete: 'GetCompanyAppraisalTemplete',
    AppraisalTemplateByJobPositionListing: 'AppraisalTemplateByJobPositionListing',
    AppraisalScheduleListing: 'AppraisalScheduleListing',
    ApprovalPolicyList: 'ApprovalPolicyList',
    AppraisalTempleteListing: 'AppraisalTempleteListing',
    TrainingAttendanceListing: 'TrainingAttendanceListing',
    GetEmployeeTrainingRecord: 'GetEmployeeTrainingRecord',
    GetUserRolePermissionByRole: 'GetUserRolePermissionByRole',
    GetAttendanceRecordPairedData: 'GetAttendanceRecordPairedData',
    GetFinalizeAnalysisDataByEmployee: 'GetFinalizeAnalysisDataByEmployee',
    GetFinalizeAnalysisData: 'GetFinalizeAnalysisData',
    GetClockPairing: 'GetClockPairing',
    GetLatestAttandanceCalculationDateByCompany: 'GetLatestAttandanceCalculationDateByCompany',
    TrainingCostBudgetListing: 'TrainingCostBudgetListing',
    CompanyHierarchyList: 'CompanyHierarchyList',
    NewsReadListing: 'NewsReadListing',
    BulletinListing: 'BulletinListing',
    EmployeeBusinessInsightSummary: 'EmployeeBusinessInsightSummary',
    PayrollBusinessInsightSummary: 'PayrollBusinessInsightSummary',
    LeaveBILeaveTakenListing: 'LeaveBILeaveTakenListing',
    ClaimBIClaimTaken: 'ClaimBIClaimTaken',
    RequisitionTakenSummaryByType: 'RequisitionTakenSummaryByType',
    TopRequisitionTaker: 'TopRequisitionTaker',
    CompanyBusinessInsight: 'CompanyBusinessInsight',
    EmployeeBusinessInsightListing: 'EmployeeBusinessInsightListing',
    CalendarPolicyListing: 'CalendarPolicyListing',
    OffRestDayListing: 'OffRestDayListing',
    HolidayListing: 'HolidayListing',
    CompanyOffDaysListing: 'CompanyOffDaysListing',
    CompanyOffDaysListingWithFilters: 'CompanyOffDaysListingWithFilters',
    CompanyDeclaredHolidayListingByCalendarPolicyID: 'CompanyDeclaredHolidayListingByCalendarPolicyID',
    ClaimAccountHeaderList: 'ClaimAccountHeaderList',
    ClaimAccountMappingList: 'ClaimAccountMappingList',
    ClaimAccountHeaderListWithDetails: 'ClaimAccountHeaderListWithDetails',
    SpecificClaimAccountHeaderListWithDetails: 'SpecificClaimAccountHeaderListWithDetails',
    GetClaimAccountAssignment: 'GetClaimAccountAssignment',
    GetGLClaimRecordsListing: 'GetGLClaimRecordsListing',
    ClaimGLCycleProcess: 'ClaimGLCycleProcess',
    GetClaimEntitlementByClaimTypeListing: 'GetClaimEntitlementByClaimTypeListing',
    GetClaimEntitlementByEmployeeListing: 'GetClaimEntitlementByEmployeeListing',
    ClaimTypeListingAll: 'ClaimTypeListingAll',
    ClaimPolicyListingAll: 'ClaimPolicyListingAll',
    ClaimParameterListingAll: 'ClaimParameterListingAll',
    ClaimUserDefineFieldsListing: 'ClaimUserDefineFieldsListing',
    ClaimMileageLocationListing: 'ClaimMileageLocationListing',
    ClaimTypePayItemListing: 'ClaimTypePayItemListing',
    ClaimPolicyListing: 'ClaimPolicyListing',
    ClaimTypeCompanyListing: 'ClaimTypeCompanyListing',
    ClaimTypeTemplateListing: 'ClaimTypeTemplateListing',
    JobGradePolicyListingAll: 'JobGradePolicyListingAll',
    CompanySummarySimplified: 'CompanySummarySimplified',
    ClaimTemplateListing: 'ClaimTemplateListing',
    ClaimTypeConditionList: 'ClaimTypeConditionList',
    GetClaimPostingListing: 'GetClaimPostingListing',
    GetClaimPostingCount: 'GetClaimPostingCount',
    GetClaimPostingDetail: 'GetClaimPostingDetail',
    PayrollCycleListingByTLA: 'PayrollCycleListingByTLA',
    PayrollCycleByPayPeriod: 'PayrollCycleByPayPeriod',
    PayPeriodsByCompany: 'PayPeriodsByCompany',
    PayrollProcessPayrollCycleListingByTLA: 'PayrollProcessPayrollCycleListingByTLA',
    PayrollProcessPayrollCycleListing: 'PayrollProcessPayrollCycleListing',
    GetClaimPostingPeriodListingV2: 'GetClaimPostingPeriodListingV2',
    GetClaimPaymentPeriodListingV2: 'GetClaimPaymentPeriodListingV2',
    ClaimHomeMenuSummary: 'ClaimHomeMenuSummary',
    getCompanyStatutoryAccountListing: 'getCompanyStatutoryAccountListing',
    CalculateEPFRate: 'CalculateEPFRate',
    CompulsaryCourseByJobGradeListing: 'CompulsaryCourseByJobGradeListing',
    GetCompulsaryCourseByJobGradeListingWithCompany: 'GetCompulsaryCourseByJobGradeListingWithCompany',
    JobGradeTrainingCourseListingAll: 'JobGradeTrainingCourseListingAll',
    CourseClassificationListing: 'CourseClassificationListing',
    TrainingCourseListing: 'TrainingCourseListing',
    TrainingCourseListingAll: 'TrainingCourseListingAll',
    TrainingCourseListingByJobGrade: 'TrainingCourseListingByJobGrade',
    GetProviderListByCourse: 'GetProviderListByCourse',
    StatesListing: 'StatesListing',
    TrainingCostItemListing: 'TrainingCostItemListing',
    GetDataImportTemplate: 'GetDataImportTemplate',
    ShopeeImport: 'ShopeeImport',
    EmployeePendingActivateListing: 'EmployeePendingActivateListing',
    EmployeeListForSpecificSection: 'EmployeeListForSpecificSection',
    EmployeeListForReporting: 'EmployeeListForReporting',
    PersonnelEFileListing: 'PersonnelEFileListing',
    PersonnelEFileListingCount: 'PersonnelEFileListingCount',
    EmployeeListingBySubscriptionAccount: 'EmployeeListingBySubscriptionAccount',
    EmployeeListingBySubscriptionAccountCount: 'EmployeeListingBySubscriptionAccountCount',
    EmployeeListingByRoleID: 'EmployeeListingByRoleID',
    GetEmployeeTraining: 'GetEmployeeTraining',
    PersonnelEducationListing: 'PersonnelEducationListing',
    PersonnelParameterListing: 'PersonnelParameterListing',
    HasEmployeeDocNumber: 'HasEmployeeDocNumber',
    PersonnelReferenceListing: 'PersonnelReferenceListing',
    PersonnelHealthInfoSummary: 'PersonnelHealthInfoSummary',
    JobHistoryListing: 'JobHistoryListing',
    UserDefineDetailListing: 'UserDefineDetailListing',
    UserDefineListing: 'UserDefineListing',
    ContractInfoListing: 'ContractInfoListing',
    ZakatContributionListing: 'ZakatContributionListing',
    ZakatSummary: 'ZakatSummary',
    EmployeeListForJobInfo: 'EmployeeListForJobInfo',
    PersonnelEmployementInfoListing: 'PersonnelEmployementInfoListing',
    PersonnelEmployementInfoListingCount: 'PersonnelEmployementInfoListingCount',
    JobInfoEmployeeListingBySubscriptionAccount: 'JobInfoEmployeeListingBySubscriptionAccount',
    IsZakatPeriodOverlap: 'IsZakatPeriodOverlap',
    GetEmployeeAppSecurityListing: 'GetEmployeeAppSecurityListing',
    GetEmployeeAppSecurityAssignmentListing: 'GetEmployeeAppSecurityAssignmentListing',
    GetEmployeeAppSecurityPermissionsByContactID: 'GetEmployeeAppSecurityPermissionsByContactID',
    DepartmentDivisionListing: 'DepartmentDivisionListing',
    DepartmentDetail: 'DepartmentDetail',
    DivisionListing: 'DivisionListing',
    DepartmentListing: 'DepartmentListing',
    TraineeEvalListing: 'TraineeEvalListing',
    TraineeEvalListingAll: 'TraineeEvalListingAll',
    SupervisorEvalListing: 'SupervisorEvalListing',
    CourseEvalListing: 'CourseEvalListing',
    TrainerEvalListing: 'TrainerEvalListing',
    GetSupervisorEvalLuation: 'GetSupervisorEvalLuation',
    GetTrainerEvalLuation: 'GetTrainerEvalLuation',
    FinalRatingListing: 'FinalRatingListing',
    GetFulfilmentByJobGrade: 'GetFulfilmentByJobGrade',
    GetFulfilmentByCourse: 'GetFulfilmentByCourse',
    GetOSFulfilmentByJobGrade: 'GetOSFulfilmentByJobGrade',
    HelpdeskSubjectListing: 'HelpdeskSubjectListing',
    HelpdeskAssigneeListing: 'HelpdeskAssigneeListing',
    IsHelpdeskSubjectExist: 'IsHelpdeskSubjectExist',
    HelpdeskSubjectDetail: 'HelpdeskSubjectDetail',
    getHelpdeskTicketSummary: 'getHelpdeskTicketSummary',
    HelpdeskTicketDetail: 'HelpdeskTicketDetail',
    HelpdeskTicketListing: 'HelpdeskTicketListing',
    HelpdeskAssigneeApprovalHistory: 'HelpdeskAssigneeApprovalHistory',
    HelpdeskToDoListing: 'HelpdeskToDoListing',
    HelpdeskListingReport: 'HelpdeskListingReport',
    SubscriptionUserSummary: 'SubscriptionUserSummary',
    HomeSummary: 'HomeSummary',
    EmployeeMainMenuSummary: 'EmployeeMainMenuSummary',
    EmploymentInfoSummary: 'EmploymentInfoSummary',
    PersonnelMenuSummary: 'PersonnelMenuSummary',
    MyEmployeeInfo: 'MyEmployeeInfo',
    NotificationListing: 'NotificationListing',
    MyNewsList: 'MyNewsList',
    EmployeeCompanyListing: 'EmployeeCompanyListing',
    CompanyListingByEmail: 'CompanyListingByEmail',
    SimpleCompanyListing: 'SimpleCompanyListing',
    PayrollHomeInfoLinesOutput: 'PayrollHomeInfoLinesOutput',
    TmsPolicyMenuInfoLinesOutput: 'TmsPolicyMenuInfoLinesOutput',
    getCompanyPermission: 'getCompanyPermission',
    PersonnalInfo: 'PersonnalInfo',
    EmployeeInfo: 'EmployeeInfo',
    CompanyOrgUnitList: 'CompanyOrgUnitList',
    CompanyOrgUnitListWithFilter: 'CompanyOrgUnitListWithFilter',
    MyApplicationDetail: 'MyApplicationDetail',
    IsCompanyHierarchyExists: 'IsCompanyHierarchyExists',
    CompanySummary: 'CompanySummary',
    GetEmployeeInsuranceScheme: 'GetEmployeeInsuranceScheme',
    getRole: 'getRole',
    getRoleByUserID: 'getRoleByUserID',
    getRoleList: 'getRoleList',
    DocumentNumberingListing: 'DocumentNumberingListing',
    ViewGeneralSettingSummary: 'ViewGeneralSettingSummary',
    SystemAdminMenuSummary: 'SystemAdminMenuSummary',
    ViewCompanySummary: 'ViewCompanySummary',
    ViewNotificationPolicySummary: 'ViewNotificationPolicySummary',
    ReportTemplateListing: 'ReportTemplateListing',
    ELetterTempleteInfolines: 'ELetterTempleteInfolines',
    CommonSettingsInfoLines: 'CommonSettingsInfoLines',
    JobGradeListing: 'JobGradeListing',
    GetSkillSet: 'GetSkillSet',
    GetSkillSetDetail: 'GetSkillSetDetail',
    JobPositionListing: 'JobPositionListing',
    JobDesignationGradeListing: 'JobDesignationGradeListing',
    InsuranceSchemeListing: 'InsuranceSchemeListing',
    GetInsuranceScheme: 'GetInsuranceScheme',
    RecruitmentSourceListing: 'RecruitmentSourceListing',
    PersonnelTemplateLibraryAssignmentPageData: 'PersonnelTemplateLibraryAssignmentPageData',
    ReportTemplateAssignmentListing: 'ReportTemplateAssignmentListing',
    PayrollCycleListingWithAudit: 'PayrollCycleListingWithAudit',
    PayrollCycleListingV3: 'PayrollCycleListingV3',
    PayItemListing: 'PayItemListing',
    PayItemConditionList: 'PayItemConditionList',
    PayItemDetail: 'PayItemDetail',
    DailyTypeConditionList: 'DailyTypeConditionList',
    MonthlyTypeConditionList: 'MonthlyTypeConditionList',
    DeductionTypeConditionList: 'DeductionTypeConditionList',
    PayItemConditionListTemplateLibrary: 'PayItemConditionListTemplateLibrary',
    OTPayItemConditionList: 'OTPayItemConditionList',
    EAPartFListingByYear: 'EAPartFListingByYear',
    LHDNReportDropdownListing: 'LHDNReportDropdownListing',
    GetLHDNReportListing: 'GetLHDNReportListing',
    IsPayrollCycleInUse: 'IsPayrollCycleInUse',
    PayrollCycleDetail: 'PayrollCycleDetail',
    PayrollCycleListing: 'PayrollCycleListing',
    BankListing: 'BankListing',
    CompanyStatutoryDetail: 'CompanyStatutoryDetail',
    getPayrollTemplateLibrary: 'getPayrollTemplateLibrary',
    getPayCycleForPayCalendar: 'getPayCycleForPayCalendar',
    InterbankListingBulk: 'InterbankListingBulk',
    getRolePermissionByUser: 'getRolePermissionByUser',
    IsAccountNoExists: 'IsAccountNoExists',
    IsAccountNameExists: 'IsAccountNameExists',
    EmployeeDependentsListing: 'EmployeeDependentsListing',
    hasEmergencyContact: 'hasEmergencyContact',
    CompanyAltAddressListing: 'CompanyAltAddressListing',
    LoginUserPerms: 'LoginUserPerms',
    PayrollCommonSettingsInfoLine: 'PayrollCommonSettingsInfoLine',
    EmployeeCalendarLeaveDetails: 'EmployeeCalendarLeaveDetails',
    EmployeeHomeCalendar: 'EmployeeHomeCalendar',
    CompanyDeclaredHolidayListing: 'CompanyDeclaredHolidayListing',
    EmployeeCalendar: 'EmployeeCalendar',
    GetLeaveOffDay: 'GetLeaveOffDay',
    GetMyClaimListing: 'GetMyClaimListing',
    ClaimCompanySettingSummary: 'ClaimCompanySettingSummary',
    GetApprovedClaimVerificationDetails: 'GetApprovedClaimVerificationDetails',
    GetApprovedClaimVerificationListing: 'GetApprovedClaimVerificationListing',
    GetApprovedClaimCount: 'GetApprovedClaimCount',
    VerifyMyClaim: 'VerifyMyClaim',
    ClaimTypeListing: 'ClaimTypeListing',
    GetEmpClaimListing: 'GetEmpClaimListing',
    GetMainClaimListing: 'GetMainClaimListing',
    GetMyClaimEntitlement: 'GetMyClaimEntitlement',
    GetEmpsClaimBalance: 'GetEmpsClaimBalance',
    GetMyClaimPoolBalance: 'GetMyClaimPoolBalance',
    GetMyClaimBalance: 'GetMyClaimBalance',
    GetMyClaimDetails: 'GetMyClaimDetails',
    GetAllExpenseAccount: 'GetAllExpenseAccount',
    ClaimListing: 'ClaimListing',
    JobGradePolicyListing: 'JobGradePolicyListing',
    GetClaimRecordsListing: 'GetClaimRecordsListing',
    GetClaimRecordsTotalCount: 'GetClaimRecordsTotalCount',
    GetClaimRecordsDetails: 'GetClaimRecordsDetails',
    GetClaimApprovalHistory: 'GetClaimApprovalHistory',
    ClaimParameterListing: 'ClaimParameterListing',
    ApproverListByCompany: 'ApproverListByCompany',
    IsClaimSubjectExists: 'IsClaimSubjectExists',
    GetClaimReportDetails: 'GetClaimReportDetails',
    getDataChangesListing: 'getDataChangesListing',
    getDocument: 'getDocument',
    EmployeeDependentsSummary: 'EmployeeDependentsSummary',
    SelectedDataChangesApprovalPolicy: 'SelectedDataChangesApprovalPolicy',
    NewsListing: 'NewsListing',
    NewsCount: 'NewsCount',
    DocumentListing: 'DocumentListing',
    PublishPaySlipListingByEmployee: 'PublishPaySlipListingByEmployee',
    PublishPaySlipListing: 'PublishPaySlipListing',
    PublishEAListing: 'PublishEAListing',
    PublishEAListingByEmployee: 'PublishEAListingByEmployee',
    FilterEmployeeRequisitionListing: 'FilterEmployeeRequisitionListing',
    EmployeeRequisitionListing: 'EmployeeRequisitionListing',
    FilterRequisitionListing: 'FilterRequisitionListing',
    TravelRequsitionListing: 'TravelRequsitionListing',
    GetRequisitionApprovalHistory: 'GetRequisitionApprovalHistory',
    GetLeaveRecordsTotalCount: 'GetLeaveRecordsTotalCount',
    GetLeaveRecordsListing: 'GetLeaveRecordsListing',
    ViewLeaveApprovalHistory: 'ViewLeaveApprovalHistory',
    MyLeaveListing: 'MyLeaveListing',
    GetEmployeesLeaveSummary: 'GetEmployeesLeaveSummary',
    EnhanceGetEmployeesLeaveSummary: 'EnhanceGetEmployeesLeaveSummary',
    GetCompanyCyclePeriodList: 'GetCompanyCyclePeriodList',
    GetEmployeeListing: 'GetEmployeeListing',
    GetMyLeaveEntitlement: 'GetMyLeaveEntitlement',
    GetMyLeaveBalance: 'GetMyLeaveBalance',
    LeaveTypeListing: 'LeaveTypeListing',
    GetReplacementLeaveByEmployee: 'GetReplacementLeaveByEmployee',
    EmployeeSummary: 'EmployeeSummary',
    FilterEmployeeLeaveRecords: 'FilterEmployeeLeaveRecords',
    GetLeaveOffDayByAllStateWithCompany: 'GetLeaveOffDayByAllStateWithCompany',
    GetLeavePolicyByJobGradeID: 'GetLeavePolicyByJobGradeID',
    ApproverInfo: 'ApproverInfo',
    LeaveReasonList: 'LeaveReasonList',
    LeaveDiseaseList: 'LeaveDiseaseList',
    LeaveCompanySettingSummary: 'LeaveCompanySettingSummary',
    BlockLeaveEmpListing: 'BlockLeaveEmpListing',
    getEmployeeCalendar: 'getEmployeeCalendar',
    GetTakenLeaveByEmployeeID: 'GetTakenLeaveByEmployeeID',
    GetEmployeeEntitlementByYear: 'GetEmployeeEntitlementByYear',
    GetLeaveByMonthCalendar: 'GetLeaveByMonthCalendar',
    GetLeaveByMonthCalendarToDo: 'GetLeaveByMonthCalendarToDo',
    LeaveTypeList: 'LeaveTypeList',
    GetLeavePolicyListing: 'GetLeavePolicyListing',
    LeaveRoundingListing: 'LeaveRoundingListing',
    LeaveTypePayItemListing: 'LeaveTypePayItemListing',
    GetLeavePolicyByID: 'GetLeavePolicyByID',
    PayItemByPayType: 'PayItemByPayType',
    JobGradeListingByCompany: 'JobGradeListingByCompany',
    LeaveCompulsoryPolicyListing: 'LeaveCompulsoryPolicyListing',
    ApprovalPolicyAssignmentList: 'ApprovalPolicyAssignmentList',
    LeaveTypeConditionList: 'LeaveTypeConditionList',
    LeaveTemplateListing: 'LeaveTemplateListing',
    GetCompulsoryLeaveListing: 'GetCompulsoryLeaveListing',
    LeaveMenuSummary: 'LeaveMenuSummary',
    LeaveHomeSummary: 'LeaveHomeSummary',
    getEntityRoleUserAssignment: 'getEntityRoleUserAssignment',
    LeaveOutOfStateListing: 'LeaveOutOfStateListing',
    LeaveTakenListing: 'LeaveTakenListing',
    GetLeavePostingPeriodListing: 'GetLeavePostingPeriodListing',
    EmployeeLeaveListing: 'EmployeeLeaveListing',
    BlockLeaveListing: 'BlockLeaveListing',
    GetLeaveAdjustmentListing: 'GetLeaveAdjustmentListing',
    LeaveAdjustmentLeaveTypeListing: 'LeaveAdjustmentLeaveTypeListing',
    GetLeavePostingListing: 'GetLeavePostingListing',
    GetYECFPendingListing: 'GetYECFPendingListing',
    GetYECFPostedListing: 'GetYECFPostedListing',
    GetYECFPendingListingWithFilter: 'GetYECFPendingListingWithFilter',
    GetYECFPostedListingWithFilter: 'GetYECFPostedListingWithFilter',
    GetALBalanceForResignedEmployees: 'GetALBalanceForResignedEmployees',
    GetLeaveEntitlementByLeaveTypeListingTotalCount: 'GetLeaveEntitlementByLeaveTypeListingTotalCount',
    GetLeaveEntitlementByLeaveTypeListing: 'GetLeaveEntitlementByLeaveTypeListing',
    GetOffboardEncashPeriodListing: 'GetOffboardEncashPeriodListing',
    GetCarryForwardEncashmentPostedListing: 'GetCarryForwardEncashmentPostedListing',
    GetCarryForwardEncashmentPendingListing: 'GetCarryForwardEncashmentPendingListing',
    GetLeaveAdjustmentEmployeeListing: 'GetLeaveAdjustmentEmployeeListing',
    ModerationListing: 'ModerationListing',
    EmployeeScheduledTrainingListing: 'EmployeeScheduledTrainingListing',
    AvailableCoursesListing: 'AvailableCoursesListing',
    CompulsoryCourseListing: 'CompulsoryCourseListing',
    EmployeeTrainingAttendanceListing: 'EmployeeTrainingAttendanceListing',
    ScheduleTrainingListing: 'ScheduleTrainingListing',
    EmpTrainingAttendanceHistory: 'EmpTrainingAttendanceHistory',
    AuthorisedTrainingProviderListing: 'AuthorisedTrainingProviderListing',
    EmployeeNotificationListing: 'EmployeeNotificationListing',
    NotificationPolicyDetail: 'NotificationPolicyDetail',
    NotificationPolicyListing: 'NotificationPolicyListing',
    NotificationPolicyConditionListing: 'NotificationPolicyConditionListing',
    NotificationPolicyConditionDetail: 'NotificationPolicyConditionDetail',
    NotificationPolicySummaryDates: 'NotificationPolicySummaryDates',
    PayrollCompanySettingSummary: 'PayrollCompanySettingSummary',
    ViewPayrollHistorySummaryListing: 'ViewPayrollHistorySummaryListing',
    GetPayrollCyclePeriodSetting: 'GetPayrollCyclePeriodSetting',
    ViewPayrollHistorySummary: 'ViewPayrollHistorySummary',
    ViewPayrollHistorySummaryCount: 'ViewPayrollHistorySummaryCount',
    ViewPayrollHistoryTotal: 'ViewPayrollHistoryTotal',
    PayrollFixedAllowanceListing: 'PayrollFixedAllowanceListing',
    PayrollFixedAllowanceManualEntryListing: 'PayrollFixedAllowanceManualEntryListing',
    PayrollFixedAllowanceManualEntryListingCount: 'PayrollFixedAllowanceManualEntryListingCount',
    PayrollProcessPeriodListing: 'PayrollProcessPeriodListing',
    IsMonthlyTrxInUse: 'IsMonthlyTrxInUse',
    MonthlyTransactionByEmployee: 'MonthlyTransactionByEmployee',
    MonthlyTransactionByEmployeeCount: 'MonthlyTransactionByEmployeeCount',
    FreezepayrollListing: 'FreezepayrollListing',
    CP159Listing: 'CP159Listing',
    EmployeeOutstandingSalaryListing: 'EmployeeOutstandingSalaryListing',
    CP22Count: 'CP22Count',
    EmployeeCP22Listing: 'EmployeeCP22Listing',
    EmployeeBenefitInKindListing: 'EmployeeBenefitInKindListing',
    EmployeeTaxReliefListing: 'EmployeeTaxReliefListing',
    TaxReliefListingDefaultExcluded: 'TaxReliefListingDefaultExcluded',
    StartPayPeriodListingByYear: 'StartPayPeriodListingByYear',
    GetPayPeriodByYear: 'GetPayPeriodByYear',
    PayrollFixedAllowanceDetails: 'PayrollFixedAllowanceDetails',
    PayItemListingByTLA: 'PayItemListingByTLA',
    PayrollFixedAllowanceByExcel: 'PayrollFixedAllowanceByExcel',
    OneTimePayListing: 'OneTimePayListing',
    OneTimePayListingByPayPeriodAndPayCycle: 'OneTimePayListingByPayPeriodAndPayCycle',
    MonthlyTransactionByPayItem: 'MonthlyTransactionByPayItem',
    MonthlyTransactionByPayItemWithinPeriod: 'MonthlyTransactionByPayItemWithinPeriod',
    MonthlyTransactionByPayItemSelected: 'MonthlyTransactionByPayItemSelected',
    PayrollProcessingPayItemListing: 'PayrollProcessingPayItemListing',
    MonthlyTransactionByExcel: 'MonthlyTransactionByExcel',
    PayrollProcessPayItemListing: 'PayrollProcessPayItemListing',
    PayrollProcessingPayItemListing2: 'PayrollProcessingPayItemListing2',
    PayrollProcessEmployeeListing: 'PayrollProcessEmployeeListing',
    CalculatePayroll: 'CalculatePayroll',
    PayPeriodDetails: 'PayPeriodDetails',
    ViewPayrollHistoryDetails: 'ViewPayrollHistoryDetails',
    ViewPayrollHistoryDetailsMyPayrollInfo: 'ViewPayrollHistoryDetailsMyPayrollInfo',
    GLAccountMappingList: 'GLAccountMappingList',
    GLAccountHeaderList: 'GLAccountHeaderList',
    GLAccountHeaderListWithDetails: 'GLAccountHeaderListWithDetails',
    SpecificGLAccountHeaderListWithDetails: 'SpecificGLAccountHeaderListWithDetails',
    GetAccountMappingAssignment: 'GetAccountMappingAssignment',
    PayItemAmountByOrgAndPayPeriod: 'PayItemAmountByOrgAndPayPeriod',
    ViewPayrollHistoryByEmployee: 'ViewPayrollHistoryByEmployee',
    SalaryListingByExcel: 'SalaryListingByExcel',
    SalaryListingByPeriod: 'SalaryListingByPeriod',
    CheckOldDataForSalaryBatch: 'CheckOldDataForSalaryBatch',
    CheckOldDataForOneTimePayInsert: 'CheckOldDataForOneTimePayInsert',
    CheckOldDataForRecurringPayInsert: 'CheckOldDataForRecurringPayInsert',
    StatutoryAdjustmentListing: 'StatutoryAdjustmentListing',
    StatutoryAdjFormEmpListing: 'StatutoryAdjFormEmpListing',
    IsPayrollCycleLocked: 'IsPayrollCycleLocked',
    PerformanceMenuSummary: 'PerformanceMenuSummary',
    FormItemRatingListing: 'FormItemRatingListing',
    FormItemRatingDetails: 'FormItemRatingDetails',
    IsFormItemRatingTitleExist: 'IsFormItemRatingTitleExist',
    ReviewGradingTableListing: 'ReviewGradingTableListing',
    ReviewGradingTableDetails: 'ReviewGradingTableDetails',
    IsReviewGradingTitleExist: 'IsReviewGradingTitleExist',
    ReviewPolicyListing: 'ReviewPolicyListing',
    ReviewPolicyDetails: 'ReviewPolicyDetails',
    IsReviewPolicyTitleExist: 'IsReviewPolicyTitleExist',
    KpiCategoryListing: 'KpiCategoryListing',
    KpiCategoryDetails: 'KpiCategoryDetails',
    IsKpiCategoryTitleExist: 'IsKpiCategoryTitleExist',
    KpiCategoryListingWithItemCount: 'KpiCategoryListingWithItemCount',
    KpiPredefinedItemListing: 'KpiPredefinedItemListing',
    KpiPredefinedItemDetails: 'KpiPredefinedItemDetails',
    IsKpiPredefinedItemTitleExist: 'IsKpiPredefinedItemTitleExist',
    KpiPredefinedTemplateListing: 'KpiPredefinedTemplateListing',
    KpiPredefinedTemplateDetails: 'KpiPredefinedTemplateDetails',
    IsKpiPredefinedTemplateTitleExist: 'IsKpiPredefinedTemplateTitleExist',
    ReviewItemListing: 'ReviewItemListing',
    ReviewItemDetails: 'ReviewItemDetails',
    IsReviewItemCodeExist: 'IsReviewItemCodeExist',
    ReviewTemplateListing: 'ReviewTemplateListing',
    ReviewTemplateDetails: 'ReviewTemplateDetails',
    IsReviewTemplateTitleExist: 'IsReviewTemplateTitleExist',
    ReviewProcessPolicyListing: 'ReviewProcessPolicyListing',
    ReviewProcessPolicyDetails: 'ReviewProcessPolicyDetails',
    IsReviewProcessPolicyTitleExist: 'IsReviewProcessPolicyTitleExist',
    ReviewFormTemplateListing: 'ReviewFormTemplateListing',
    ReviewFormTemplateDetails: 'ReviewFormTemplateDetails',
    IsReviewFormTemplateTitleExist: 'IsReviewFormTemplateTitleExist',
    ReviewGroupListing: 'ReviewGroupListing',
    IsReviewGroupTitleExist: 'IsReviewGroupTitleExist',
    RewardGroupListing: 'RewardGroupListing',
    IsRewardGroupTitleExist: 'IsRewardGroupTitleExist',
    PeriodicAllowanceTypeListing: 'PeriodicAllowanceTypeListing',
    GetNextDocNumber: 'GetNextDocNumber',
    JobDesignationListing: 'JobDesignationListing',
    JobGradeByJobDesignationListing: 'JobGradeByJobDesignationListing',
    InsuranceSchemeInUse: 'InsuranceSchemeInUse',
    CompanyListWithAccessLevel: 'CompanyListWithAccessLevel',
    CompanyListing: 'CompanyListing',
    JobPositionAssignmentList: 'JobPositionAssignmentList',
    GetSuperior: 'GetSuperior',
    EmployeeAssetListing: 'EmployeeAssetListing',
    EmployeeAssetDetail: 'EmployeeAssetDetail',
    GetEmployeePrevContribution: 'GetEmployeePrevContribution',
    GetEmployeeAppraisal: 'GetEmployeeAppraisal',
    GetEmployeeDiary: 'GetEmployeeDiary',
    TemplateLibraryTargetListing: 'TemplateLibraryTargetListing',
    PersonnelInsuranceSchemePersonnelListing: 'PersonnelInsuranceSchemePersonnelListing',
    ProbationExtensionListing: 'ProbationExtensionListing',
    StaffConfirmationProbationListing: 'StaffConfirmationProbationListing',
    StaffConfirmationConfirmedCareerLogListing: 'StaffConfirmationConfirmedCareerLogListing',
    StaffConfirmationConfirmedListing: 'StaffConfirmationConfirmedListing',
    StaffPromotionDemotionListing: 'StaffPromotionDemotionListing',
    StaffTerminationListing: 'StaffTerminationListing',
    StaffTerminationListingBySubscription: 'StaffTerminationListingBySubscription',
    StaffRetirementDueListing: 'StaffRetirementDueListing',
    StaffRetirementRetiredListing: 'StaffRetirementRetiredListing',
    StaffExitCompanyListing: 'StaffExitCompanyListing',
    StaffInternalTransferListing: 'StaffInternalTransferListing',
    StaffSecondmentListing: 'StaffSecondmentListing',
    StaffInterCompanyTransferListing: 'StaffInterCompanyTransferListing',
    StaffRedesignationListing: 'StaffRedesignationListing',
    PersonnelHomeInfoLines: 'PersonnelHomeInfoLines',
    CostCenterMappingList: 'CostCenterMappingList',
    StaffInterCompanyTransferCopyTransferData: 'StaffInterCompanyTransferCopyTransferData',
    StaffInterCompanyTransferDetails: 'StaffInterCompanyTransferDetails',
    PersonnelCertificateListing: 'PersonnelCertificateListing',
    IsMobileNoExists: 'IsMobileNoExists',
    IsEmailExists: 'IsEmailExists',
    IsEmployeeNoExists: 'IsEmployeeNoExists',
    PersonnelSecurityPolicyInfoByRole: 'PersonnelSecurityPolicyInfoByRole',
    GetEmployeePolicies: 'GetEmployeePolicies',
    GetEmployeeClaimPolicies: 'GetEmployeeClaimPolicies',
    GetLeavePolicyByEmployee: 'GetLeavePolicyByEmployee',
    ProjecCommonSettingSummary: 'ProjecCommonSettingSummary',
    ProjectMasterListing: 'ProjectMasterListing',
    ProjectExpenseTypeListing: 'ProjectExpenseTypeListing',
    ProjectAccountMappingList: 'ProjectAccountMappingList',
    IsGLProcessed: 'IsGLProcessed',
    ProjectCostCenterMappingListForGLExport: 'ProjectCostCenterMappingListForGLExport',
    ProjectGLAccountMappingListing: 'ProjectGLAccountMappingListing',
    GetProjectAccountAssignment: 'GetProjectAccountAssignment',
    ProjectCostAllocationListing: 'ProjectCostAllocationListing',
    ProjectCostAllocationEmployeeListing: 'ProjectCostAllocationEmployeeListing',
    ProjectCostAllocationDetailListing: 'ProjectCostAllocationDetailListing',
    GetProjectExpenseClaimRecordsListing: 'GetProjectExpenseClaimRecordsListing',
    GetApprovedProjectExpenseClaimVerificationListing: 'GetApprovedProjectExpenseClaimVerificationListing',
    GetPEApprovedClaimCount: 'GetPEApprovedClaimCount',
    GetPEClaimRecordsDetails: 'GetPEClaimRecordsDetails',
    VerifyMyPEClaim: 'VerifyMyPEClaim',
    ProjecExpenseSummary: 'ProjecExpenseSummary',
    GetPEClaimPostingPeriodListingV2: 'GetPEClaimPostingPeriodListingV2',
    GetPEClaimPaymentPeriodListingV2: 'GetPEClaimPaymentPeriodListingV2',
    GetPEClaimPostingListing: 'GetPEClaimPostingListing',
    GetPEClaimPostingDetail: 'GetPEClaimPostingDetail',
    GetApprovedProjectExpenseClaimVerificationDetails: 'GetApprovedProjectExpenseClaimVerificationDetails',
    ProjectGLAccountHeaderListWithDetails: 'ProjectGLAccountHeaderListWithDetails',
    ProjectPayItemAmountByOrgAndPayPeriod: 'ProjectPayItemAmountByOrgAndPayPeriod',
    ProjectPayrollGLExport: 'ProjectPayrollGLExport',
    CostCenterMappingListForGLExport: 'CostCenterMappingListForGLExport',
    GetProjectExpenseApprovalHistory: 'GetProjectExpenseApprovalHistory',
    ProjectGLClaimCycleProcess: 'ProjectGLClaimCycleProcess',
    GetGLClaimCycleList: 'GetGLClaimCycleList',
    PEClaimCompanySettingSummary: 'PEClaimCompanySettingSummary',
    GetPEClaimRecordsTotalCount: 'GetPEClaimRecordsTotalCount',
    IsProjectExpenseSubjectExists: 'IsProjectExpenseSubjectExists',
    getApproverMasterListing: 'getApproverMasterListing',
    getApproverAssignmentListing: 'getApproverAssignmentListing',
    IsApproverEmailAlreadyExist: 'IsApproverEmailAlreadyExist',
    ProjectTimesheetCheckPeriodOverlapping: 'ProjectTimesheetCheckPeriodOverlapping',
    ProjectTimesheetCheckPeriodOverlappingCount: 'ProjectTimesheetCheckPeriodOverlappingCount',
    ProjectTimesheetPeriodListing: 'ProjectTimesheetPeriodListing',
    DailyEntryListing: 'DailyEntryListing',
    ProjectTimesheetSubmissionListing: 'ProjectTimesheetSubmissionListing',
    ProjectTimesheetApprovalListing: 'ProjectTimesheetApprovalListing',
    ProjectTimesheetPeriod: 'ProjectTimesheetPeriod',
    ProjectTimesheetApprover: 'ProjectTimesheetApprover',
    GetEmployeesLeaveSummaryReport: 'GetEmployeesLeaveSummaryReport',
    GetCompanyCyclePeriodListV2: 'GetCompanyCyclePeriodListV2',
    GetSignatoryListing: 'GetSignatoryListing',
    GetBankInfoListing: 'GetBankInfoListing',
    GetBankInfoListingV2: 'GetBankInfoListingV2',
    GetPayrollReportListing: 'GetPayrollReportListing',
    GetCompanyStatutoryListing: 'GetCompanyStatutoryListing',
    GetCompanyReportYearListing: 'GetCompanyReportYearListing',
    ClaimReportClaimTypeCompanyListing: 'ClaimReportClaimTypeCompanyListing',
    ClaimDetailReportListing: 'ClaimDetailReportListing',
    GetPayItemListing: 'GetPayItemListing',
    PayrollDetailListingExportExcel: 'PayrollDetailListingExportExcel',
    GetEmployeeListingWithPayrollInput: 'GetEmployeeListingWithPayrollInput',
    RequisitionApprovalPolicySummary: 'RequisitionApprovalPolicySummary',
    RequisitionMenuSummary: 'RequisitionMenuSummary',
    RequisitionManagementListing: 'RequisitionManagementListing',
    ApprovalPolicyAssignmentSummary: 'ApprovalPolicyAssignmentSummary',
    RequisitionManagementListingTotalCount: 'RequisitionManagementListingTotalCount',
    ScaleRatingListing: 'ScaleRatingListing',
    RegisteredTraineeListing: 'RegisteredTraineeListing',
    GetLatestUpdateForMainMenuModule: 'GetLatestUpdateForMainMenuModule',
    GetEmployeeKPIPlanningByYearAppraisalManagement: 'GetEmployeeKPIPlanningByYearAppraisalManagement',
    GetEmployeeListingByscheduledAndQuater: 'GetEmployeeListingByscheduledAndQuater',
    StaffPerformanceAnalysisSummaryListing: 'StaffPerformanceAnalysisSummaryListing',
    GetBonusRecordByCompanyOrgUnitList: 'GetBonusRecordByCompanyOrgUnitList',
    BonusSimulationListing: 'BonusSimulationListing',
    GetIncrementRecordByCompanyOrgUnitList: 'GetIncrementRecordByCompanyOrgUnitList',
    IncrementSimulationListing: 'IncrementSimulationListing',
    GetKPIPlanningDetails: 'GetKPIPlanningDetails',
    GetEmployeeKPIPlanningRecord: 'GetEmployeeKPIPlanningRecord',
    GetEmployeeKPIPlanningByYear: 'GetEmployeeKPIPlanningByYear',
    AppraisalCategoryListing: 'AppraisalCategoryListing',
    SubordinateListing: 'SubordinateListing',
    CareerLogListing: 'CareerLogListing',
    GetOrgChart: 'GetOrgChart',
    SubordinateMenuSummary: 'SubordinateMenuSummary',
    SubordinateTree: 'SubordinateTree',
    SubordinateTreeTimeAttendance: 'SubordinateTreeTimeAttendance',
    EmployeeAppSummary: 'EmployeeAppSummary',
    SystemAdminCompanyStatutoryDetail: 'SystemAdminCompanyStatutoryDetail',
    userRoleIDs: 'userRoleIDs',
    DocumentNumberingAssignmentList: 'DocumentNumberingAssignmentList',
    EmployeeListingBySubscription: 'EmployeeListingBySubscription',
    JobPositionByOrgUnitList: 'JobPositionByOrgUnitList',
    JobPositionByCompanyIDArray: 'JobPositionByCompanyIDArray',
    CompanyOrgUnitByCompanyIDArray: 'CompanyOrgUnitByCompanyIDArray',
    CompanyHierarchyByCompanyIDArray: 'CompanyHierarchyByCompanyIDArray',
    JobPositionByOrgUnitListing: 'JobPositionByOrgUnitListing',
    ReportingGroupListing: 'ReportingGroupListing',
    ReportingCompanyGroupListing: 'ReportingCompanyGroupListing',
    ReportingGroupTitleExist: 'ReportingGroupTitleExist',
    GetReportingStructureListing: 'GetReportingStructureListing',
    ReportingStructureGroupOptionList: 'ReportingStructureGroupOptionList',
    PersonnelTemplateLibraryAssignmentListing: 'PersonnelTemplateLibraryAssignmentListing',
    getTemplateLibraryAssignment: 'getTemplateLibraryAssignment',
    TMSSummary: 'TMSSummary',
    GetShiftGroupListingPageData: 'GetShiftGroupListingPageData',
    ShiftGroupEmployeeList: 'ShiftGroupEmployeeList',
    ShiftGroupEmployeeListCount: 'ShiftGroupEmployeeListCount',
    GetShiftGroupFormAssignedEmployeesData: 'GetShiftGroupFormAssignedEmployeesData',
    ShiftGroupData: 'ShiftGroupData',
    ShiftGroupDataById: 'ShiftGroupDataById',
    ShiftGroupEmployee: 'ShiftGroupEmployee',
    DutyRosterInfoLine: 'DutyRosterInfoLine',
    EmployeeListingByCompanyTMS: 'EmployeeListingByCompanyTMS',
    EmployeeListingByCompanyTMSCount: 'EmployeeListingByCompanyTMSCount',
    GetEmployeesByShiftGroupData: 'GetEmployeesByShiftGroupData',
    getShiftGroupByTLA: 'getShiftGroupByTLA',
    getShiftGroupByShiftPattern: 'getShiftGroupByShiftPattern',
    getShiftPatternByTLA: 'getShiftPatternByTLA',
    DutyRosterByExcel: 'DutyRosterByExcel',
    DutyRosterEmployeeSum: 'DutyRosterEmployeeSum',
    DutyRosterSummary: 'DutyRosterSummary',
    OvertimeListingByPeriod: 'OvertimeListingByPeriod',
    OvertimeMenuSummary: 'OvertimeMenuSummary',
    TimeOffListingByPeriod: 'TimeOffListingByPeriod',
    OvertimeListingByDateTime: 'OvertimeListingByDateTime',
    OvertimeListingByDateTimeV2: 'OvertimeListingByDateTimeV2',
    OvertimeListingByYear: 'OvertimeListingByYear',
    OvertimePeriodSummary: 'OvertimePeriodSummary',
    GetAttendanceRecordManualEntryPageData: 'GetAttendanceRecordManualEntryPageData',
    EmployeeSummaryTms: 'EmployeeSummaryTms',
    EmployeeSummaryTmsCount: 'EmployeeSummaryTmsCount',
    OvertimeListingByExcel: 'OvertimeListingByExcel',
    getOvertimeReason: 'getOvertimeReason',
    TimeOffMenuSummary: 'TimeOffMenuSummary',
    TimeOffApplicationPeriodSummary: 'TimeOffApplicationPeriodSummary',
    TimeOffListingByYear: 'TimeOffListingByYear',
    TimeOffEmployeeSummary: 'TimeOffEmployeeSummary',
    TimeOffPolicyDetailsByEmployeeID: 'TimeOffPolicyDetailsByEmployeeID',
    getEmpByDuty: 'getEmpByDuty',
    GetAttendanceRecordLastModified: 'GetAttendanceRecordLastModified',
    AttendanceRecordByPeriod: 'AttendanceRecordByPeriod',
    AttendanceRecordSummary: 'AttendanceRecordSummary',
    GetManualEntryFormPageData: 'GetManualEntryFormPageData',
    AttendanceRecordByExcel: 'AttendanceRecordByExcel',
    GetEmpListingbyCompany: 'GetEmpListingbyCompany',
    getLastPostedAttendanceProccess: 'getLastPostedAttendanceProccess',
    AttendancePeriodSummary: 'AttendancePeriodSummary',
    AttendancePostingListing: 'AttendancePostingListing',
    AdjustedValueQuantityDetails: 'AdjustedValueQuantityDetails',
    ProcessingListing: 'ProcessingListing',
    GetPayrollCycleByCompany: 'GetPayrollCycleByCompany',
    EarnLeavePeriodSummary: 'EarnLeavePeriodSummary',
    EarnLeavePosting: 'EarnLeavePosting',
    GetAttendanceExclusionListingPageData: 'GetAttendanceExclusionListingPageData',
    SearchEmployee: 'SearchEmployee',
    getContactTracing: 'getContactTracing',
    GetAttendanceRecordManualEntryPageTotalCount: 'GetAttendanceRecordManualEntryPageTotalCount',
    EmployeeShiftGroupAssignmentLog: 'EmployeeShiftGroupAssignmentLog',
    GetShiftGroupEployeeAssignmentDutyRoster: 'GetShiftGroupEployeeAssignmentDutyRoster',
    OvertimeWithdrawalChecking: 'OvertimeWithdrawalChecking',
    OTReasonByTLA: 'OTReasonByTLA',
    VerifyScan: 'VerifyScan',
    AttendanceHistory: 'AttendanceHistory',
    EmpAttendanceHistory: 'EmpAttendanceHistory',
    AttendanceRecordListing: 'AttendanceRecordListing',
    AttendanceTable: 'AttendanceTable',
    SimpleOvertimeListing: 'SimpleOvertimeListing',
    OvertimeListing: 'OvertimeListing',
    DutyRosterSummaryByEmployee: 'DutyRosterSummaryByEmployee',
    DutyRosterDates: 'DutyRosterDates',
    EmployeeListingByCompany: 'EmployeeListingByCompany',
    EmployeeTMSSummary: 'EmployeeTMSSummary',
    GetSingleUser: 'GetSingleUser',
    GetOTPolicyByJobGrade: 'GetOTPolicyByJobGrade',
    GetEmployeeOtPolicies: 'GetEmployeeOtPolicies',
    getAttenRecords: 'getAttenRecords',
    GetServerDateTime: 'GetServerDateTime',
    SimpleEmployeeListing: 'SimpleEmployeeListing',
    getChangeShiftByYear: 'getChangeShiftByYear',
    getChangeShiftListing: 'getChangeShiftListing',
    getChangeShift: 'getChangeShift',
    ChangeShiftMenuSummary: 'ChangeShiftMenuSummary',
    getShiftByTLA: 'getShiftByTLA',
    getSimpleDutyRoster: 'getSimpleDutyRoster',
    getDutyRoster: 'getDutyRoster',
    getChangeShiftByYearTms: 'getChangeShiftByYearTms',
    TemplateLibrarySummary: 'TemplateLibrarySummary',
    getOTRounding: 'getOTRounding',
    getDailyAllowance: 'getDailyAllowance',
    getAllowanceDeduction: 'getAllowanceDeduction',
    getShiftList: 'getShiftList',
    getShiftByID: 'getShiftByID',
    ShiftPatternListing: 'ShiftPatternListing',
    ValidShiftPatternListing: 'ValidShiftPatternListing',
    shiftSettingActiveListing: 'shiftSettingActiveListing',
    getShiftGroupListing: 'getShiftGroupListing',
    getAllShift: 'getAllShift',
    GetTotalOverTime: 'GetTotalOverTime',
    getOvertimePolicy: 'getOvertimePolicy',
    getOvertimeTableListing: 'getOvertimeTableListing',
    getPayItemListings: 'getPayItemListings',
    OTReasonListing: 'OTReasonListing',
    getShift: 'getShift',
    getMonthlyAllowance: 'getMonthlyAllowance',
    getPeriodicDeduction: 'getPeriodicDeduction',
    getDeductionPolicyListing: 'getDeductionPolicyListing',
    TimeOffSettingListing: 'TimeOffSettingListing',
    getMobileCheckIn: 'getMobileCheckIn',
    getLocationRadiusSetting: 'getLocationRadiusSetting',
    QRListing: 'QRListing',
    GetTemplateLibraryAssignmentInfoline: 'GetTemplateLibraryAssignmentInfoline',
    GetTemplateLibraryAssignmentPageData: 'GetTemplateLibraryAssignmentPageData',
    GetJobGradePolicyMatrixPageData: 'GetJobGradePolicyMatrixPageData',
    JobGradeByTLA: 'JobGradeByTLA',
    GetOvertimeSettingPageData: 'GetOvertimeSettingPageData',
    EmployeeShiftGroup: 'EmployeeShiftGroup',
    GetBeaconDevicesListing: 'GetBeaconDevicesListing',
    GetAutomatedClockDataImportListing: 'GetAutomatedClockDataImportListing',
    TimeOffListing: 'TimeOffListing',
    TimeOffReasonListing: 'TimeOffReasonListing',
    TimeOffReasonByTLA: 'TimeOffReasonByTLA',
    TsAccountTypeList: 'TsAccountTypeList',
    TimesheetDates: 'TimesheetDates',
    StaffTimesheetList: 'StaffTimesheetList',
    TsAccountCodeList: 'TsAccountCodeList',
    TimesheetSummary: 'TimesheetSummary',
    TimesheetHours: 'TimesheetHours',
    TimesheetMandayAnalysisByAccCode: 'TimesheetMandayAnalysisByAccCode',
    TimesheetMandayAnalysisByActivity: 'TimesheetMandayAnalysisByActivity',
    TimesheetMandayAnalysisByAccType: 'TimesheetMandayAnalysisByAccType',
    TimesheetMandayAnalysisByAccount: 'TimesheetMandayAnalysisByAccount',
    IsActvitiyInUse: 'IsActvitiyInUse',
    ProjectTimesheetEmployeeListing: 'ProjectTimesheetEmployeeListing',
    AdminDailyEntryListing: 'AdminDailyEntryListing',
    ProjectTimesheeTApproverReportListing: 'ProjectTimesheeTApproverReportListing',
    GetLeaveToAprove: 'GetLeaveToAprove',
    GetLeaveApproveToDo: 'GetLeaveApproveToDo',
    GetLeaveListing: 'GetLeaveListing',
    ToDoSummary: 'ToDoSummary',
    GetSubordinateKPIPlanning: 'GetSubordinateKPIPlanning',
    GetRequisitionToAprove: 'GetRequisitionToAprove',
    GetRequisitionApproveToDo: 'GetRequisitionApproveToDo',
    GetClaimToApprove: 'GetClaimToApprove',
    GetOvertimeApproveToDo: 'GetOvertimeApproveToDo',
    ViewOvertimeApprovalHistory: 'ViewOvertimeApprovalHistory',
    GetOvertimeToApprove: 'GetOvertimeToApprove',
    GetTimeOffToApprove: 'GetTimeOffToApprove',
    GetTimeOffAproveToDo: 'GetTimeOffAproveToDo',
    ViewTimeOffApprovalHistory: 'ViewTimeOffApprovalHistory',
    GetChangeShiftApproveToDo: 'GetChangeShiftApproveToDo',
    ViewChangeShiftApprovalHistory: 'ViewChangeShiftApprovalHistory',
    GetChangeShiftToApprove: 'GetChangeShiftToApprove',
    CompanyAccessPermList: 'CompanyAccessPermList',
    GetProjectToApprove: 'GetProjectToApprove',
    GetDataChangesToDo: 'GetDataChangesToDo',
    ViewDataChangesApprovalHistory: 'ViewDataChangesApprovalHistory',
    TrainingCostListing: 'TrainingCostListing',
    CostAllocationListing: 'CostAllocationListing',
    HRDFClaimListing: 'HRDFClaimListing',
    getUserListing: 'getUserListing',
    getEmployeeUserListing: 'getEmployeeUserListing',
    getUsersByAccount: 'getUsersByAccount',
    getActiveUsersByAccount: 'getActiveUsersByAccount',
    GetExceededUserWarning: 'GetExceededUserWarning',
    userRoleIDbyUser: 'userRoleIDbyUser',
    GetNonAccessUsers: 'GetNonAccessUsers',
    getDutyRosterByEmployee: 'getDutyRosterByEmployee',
    getDutyRosterByEmployeeList: 'getDutyRosterByEmployeeList'
  },
  Mutation: {
    EmployeeProfileInsertUpdate: 'EmployeeProfileInsertUpdate',
    login: 'login',
    employeeAppLogin: 'employeeAppLogin',
    changePassword: 'changePassword',
    changeEmployeePassword: 'changeEmployeePassword',
    forgotPassword: 'forgotPassword',
    forgotEmployeePassword: 'forgotEmployeePassword',
    resetPassword: 'resetPassword',
    resetEmployeePassword: 'resetEmployeePassword',
    logout: 'logout',
    employeeLogout: 'employeeLogout',
    activateUser: 'activateUser',
    SubmitAppraisal: 'SubmitAppraisal',
    AppraisalCompanySettingSubmit: 'AppraisalCompanySettingSubmit',
    AppraisalPolicyInsert: 'AppraisalPolicyInsert',
    AppraisalPolicyDelete: 'AppraisalPolicyDelete',
    AppraisalTemplateByJobPositionSubmit: 'AppraisalTemplateByJobPositionSubmit',
    AppraisalScheduleSubmit: 'AppraisalScheduleSubmit',
    ApprovalPolicyInsert: 'ApprovalPolicyInsert',
    ApprovalPolicyUpdate: 'ApprovalPolicyUpdate',
    ApprovalPolicyDelete: 'ApprovalPolicyDelete',
    ApprovalPolicyAssignmentUpdate: 'ApprovalPolicyAssignmentUpdate',
    AppraisalCategoryInsert: 'AppraisalCategoryInsert',
    AppraisalCategoryUpdate: 'AppraisalCategoryUpdate',
    AppraisalCategoryDelete: 'AppraisalCategoryDelete',
    AppraisalCategoryItemInsert: 'AppraisalCategoryItemInsert',
    AppraisalCategoryItemUpdate: 'AppraisalCategoryItemUpdate',
    AppraisalCategoryItemDelete: 'AppraisalCategoryItemDelete',
    SubmitAppraisalTemplete: 'SubmitAppraisalTemplete',
    submitPairedAttandanceRecord: 'submitPairedAttandanceRecord',
    TrainingBudgetInputBulkInsert: 'TrainingBudgetInputBulkInsert',
    NewsReadInsert: 'NewsReadInsert',
    CalanderPolicySubmit: 'CalanderPolicySubmit',
    CompanyHolidaysBulkInsert: 'CompanyHolidaysBulkInsert',
    CompanyOffDaysBulkInsert: 'CompanyOffDaysBulkInsert',
    CalendarPolicyStatusUpdate: 'CalendarPolicyStatusUpdate',
    CalendarPolicyDelete: 'CalendarPolicyDelete',
    CompanyOffDaysDelete: 'CompanyOffDaysDelete',
    OffRestDayBulkInsert: 'OffRestDayBulkInsert',
    updateRestOffDayStatus: 'updateRestOffDayStatus',
    updateCompanyOffDaysStatus: 'updateCompanyOffDaysStatus',
    OffRestDayDelete: 'OffRestDayDelete',
    CompanyOnlyUpdate: 'CompanyOnlyUpdate',
    ClaimAccountMappingInsert: 'ClaimAccountMappingInsert',
    ClaimAccountMappingUpdate: 'ClaimAccountMappingUpdate',
    ClaimAccountMappingDelete: 'ClaimAccountMappingDelete',
    ClaimAccountAssignmentInsert: 'ClaimAccountAssignmentInsert',
    ClaimAccountAssignmentUpdate: 'ClaimAccountAssignmentUpdate',
    ClaimParameterDelete: 'ClaimParameterDelete',
    ClaimParameterInsert: 'ClaimParameterInsert',
    ClaimParameterUpdate: 'ClaimParameterUpdate',
    MileageTableDelete: 'MileageTableDelete',
    MileageTableInsert: 'MileageTableInsert',
    MileageTableUpdate: 'MileageTableUpdate',
    ClaimUserDefineFieldsInsert: 'ClaimUserDefineFieldsInsert',
    ClaimUserDefineFieldsUpdate: 'ClaimUserDefineFieldsUpdate',
    ClaimUserDefineFieldsDuplicate: 'ClaimUserDefineFieldsDuplicate',
    ClaimTypeInsert: 'ClaimTypeInsert',
    ClaimTypeUpdate: 'ClaimTypeUpdate',
    ClaimTypeDelete: 'ClaimTypeDelete',
    ClaimTypeTemplateDelete: 'ClaimTypeTemplateDelete',
    ClaimTypeTemplateUpdateStatus: 'ClaimTypeTemplateUpdateStatus',
    ClaimTypeTemplateInsert: 'ClaimTypeTemplateInsert',
    ClaimTypeTemplateUpdate: 'ClaimTypeTemplateUpdate',
    ClaimJobGradePolicyInsert: 'ClaimJobGradePolicyInsert',
    ClaimJobGradePolicyUpdate: 'ClaimJobGradePolicyUpdate',
    CompanyClaimSettingUpdate: 'CompanyClaimSettingUpdate',
    ClaimTypeConditionUpdate: 'ClaimTypeConditionUpdate',
    ClaimTypeConditionDelete: 'ClaimTypeConditionDelete',
    PostClaimRecords: 'PostClaimRecords',
    ReverseClaimRecords: 'ReverseClaimRecords',
    CompanyStatutoryAccountUpdate: 'CompanyStatutoryAccountUpdate',
    CompulsaryCourseByJobGradeSubmit: 'CompulsaryCourseByJobGradeSubmit',
    CourseClassificationBulkInsert: 'CourseClassificationBulkInsert',
    CourseClassificationDelete: 'CourseClassificationDelete',
    TrainingCourseSubmit: 'TrainingCourseSubmit',
    TrainingCourseDelete: 'TrainingCourseDelete',
    AuthorisedTrainingProviderSubmit: 'AuthorisedTrainingProviderSubmit',
    TrainingCostItemBulkInsert: 'TrainingCostItemBulkInsert',
    TrainingCostItemDelete: 'TrainingCostItemDelete',
    DataImportVerify: 'DataImportVerify',
    ShopeeDataImportVerify: 'ShopeeDataImportVerify',
    RemoveTempData: 'RemoveTempData',
    ProceedForImport: 'ProceedForImport',
    ActivateEmployees: 'ActivateEmployees',
    NewsPublish: 'NewsPublish',
    NewsDuplicate: 'NewsDuplicate',
    updateEmployeeProfilePic: 'updateEmployeeProfilePic',
    EmployeeInfoUpdate: 'EmployeeInfoUpdate',
    CreateEmployeeDiary: 'CreateEmployeeDiary',
    UpdateEmployeeDiary: 'UpdateEmployeeDiary',
    DeleteEmployeeDiary: 'DeleteEmployeeDiary',
    CreateEmployeeAppraisal: 'CreateEmployeeAppraisal',
    UpdateEmployeeAppraisal: 'UpdateEmployeeAppraisal',
    DeleteEmployeeAppraisal: 'DeleteEmployeeAppraisal',
    CreateEmployeeTraining: 'CreateEmployeeTraining',
    UpdateEmployeeTraining: 'UpdateEmployeeTraining',
    DeleteEmployeeTraining: 'DeleteEmployeeTraining',
    PersonnelEducationInsert: 'PersonnelEducationInsert',
    PersonnelEducationUpdate: 'PersonnelEducationUpdate',
    PersonnelEducationDelete: 'PersonnelEducationDelete',
    PersonnelCertificateInsert: 'PersonnelCertificateInsert',
    PersonnelCertificateUpdate: 'PersonnelCertificateUpdate',
    PersonnelCertificateDelete: 'PersonnelCertificateDelete',
    PersonnelReferenceInsert: 'PersonnelReferenceInsert',
    PersonnelReferenceUpdate: 'PersonnelReferenceUpdate',
    PersonnelReferenceDelete: 'PersonnelReferenceDelete',
    PersonnelHealthInfoInsert: 'PersonnelHealthInfoInsert',
    PersonnelHealthInfoUpdate: 'PersonnelHealthInfoUpdate',
    MedicalRecordDelete: 'MedicalRecordDelete',
    PersonnelJobHistoryInsert: 'PersonnelJobHistoryInsert',
    PersonnelJobHistoryUpdate: 'PersonnelJobHistoryUpdate',
    PersonnelJobHistoryDelete: 'PersonnelJobHistoryDelete',
    UserDefineDetailBulkInsert: 'UserDefineDetailBulkInsert',
    ZakatContributionDelete: 'ZakatContributionDelete',
    ZakatContributionInsert: 'ZakatContributionInsert',
    ZakatContributionUpdate: 'ZakatContributionUpdate',
    CreateUpdateAppSecurity: 'CreateUpdateAppSecurity',
    CreateUpdateAppSecurityAssignment: 'CreateUpdateAppSecurityAssignment',
    DepartmentInsert: 'DepartmentInsert',
    DepartmentDelete: 'DepartmentDelete',
    DivisionInsert: 'DivisionInsert',
    DepartmentUpdate: 'DepartmentUpdate',
    DivisionUpdate: 'DivisionUpdate',
    DivisionDelete: 'DivisionDelete',
    TraineeEvalSubmit: 'TraineeEvalSubmit',
    SupervisorEvalSubmit: 'SupervisorEvalSubmit',
    CourseEvalSubmit: 'CourseEvalSubmit',
    TrainerEvalSubmit: 'TrainerEvalSubmit',
    FinalRatingSubmit: 'FinalRatingSubmit',
    FinalRatingDelete: 'FinalRatingDelete',
    HelpdeskSubjectSubmit: 'HelpdeskSubjectSubmit',
    HelpdeskSubjectAction: 'HelpdeskSubjectAction',
    UpdateOpenDT: 'UpdateOpenDT',
    ReassignAssignee: 'ReassignAssignee',
    SubmitHelpdeskTicket: 'SubmitHelpdeskTicket',
    WithdrawOpenTicket: 'WithdrawOpenTicket',
    EmployeeDependentDelete: 'EmployeeDependentDelete',
    UpdateEmployeeLastLoginCompany: 'UpdateEmployeeLastLoginCompany',
    CompanyTaxReliefUpdate: 'CompanyTaxReliefUpdate',
    InvoiceRPA: 'InvoiceRPA',
    DocumentNumberingUpdateStatus: 'DocumentNumberingUpdateStatus',
    DeleteRolePermission: 'DeleteRolePermission',
    CreateRolePermission: 'CreateRolePermission',
    UpdateRolePermission: 'UpdateRolePermission',
    CompanyInsert: 'CompanyInsert',
    CompanyUpdate: 'CompanyUpdate',
    DocumentNumberingInsert: 'DocumentNumberingInsert',
    DocumentNumberingUpdate: 'DocumentNumberingUpdate',
    ReportTemplateInsert: 'ReportTemplateInsert',
    ReportTemplateUpdate: 'ReportTemplateUpdate',
    ReportTemplateInactive: 'ReportTemplateInactive',
    PersonnelParameterInsert: 'PersonnelParameterInsert',
    PersonnelParameterUpdate: 'PersonnelParameterUpdate',
    PersonnelParameterDelete: 'PersonnelParameterDelete',
    JobGradeBulkInsert: 'JobGradeBulkInsert',
    JobGradeDelete: 'JobGradeDelete',
    DeleteSkillSet: 'DeleteSkillSet',
    CreateSkillSet: 'CreateSkillSet',
    UpdateSkillSet: 'UpdateSkillSet',
    JobPositionDelete: 'JobPositionDelete',
    JobPositionInsert: 'JobPositionInsert',
    JobDesignationSequenceUpdate: 'JobDesignationSequenceUpdate',
    JobPositionUpdate: 'JobPositionUpdate',
    InsuranceSchemeInsert: 'InsuranceSchemeInsert',
    InsuranceSchemeUpdate: 'InsuranceSchemeUpdate',
    InsuranceSchemeDelete: 'InsuranceSchemeDelete',
    RecruitmentSourceBulkInsert: 'RecruitmentSourceBulkInsert',
    RecruitmentSourceDelete: 'RecruitmentSourceDelete',
    UserDefineBulkInsert: 'UserDefineBulkInsert',
    replaceTemplateLibraryAssignment: 'replaceTemplateLibraryAssignment',
    ReportTemplateAssignment: 'ReportTemplateAssignment',
    PayItemDelete: 'PayItemDelete',
    PayItemInsert: 'PayItemInsert',
    PayItemUpdate: 'PayItemUpdate',
    PayItemConditionTemplateLibraryUpdate: 'PayItemConditionTemplateLibraryUpdate',
    TmsotConditionTemplateLibraryUpdate: 'TmsotConditionTemplateLibraryUpdate',
    DailyTypeConditionUpdate: 'DailyTypeConditionUpdate',
    DailyTypeConditionDelete: 'DailyTypeConditionDelete',
    MonthlyTypeConditionUpdate: 'MonthlyTypeConditionUpdate',
    MonthlyTypeConditionDelete: 'MonthlyTypeConditionDelete',
    DeductionTypeConditionUpdate: 'DeductionTypeConditionUpdate',
    DeductionTypeConditionDelete: 'DeductionTypeConditionDelete',
    LHDNReportUpdate: 'LHDNReportUpdate',
    LHDNReportInsert: 'LHDNReportInsert',
    LHDNReportCopyInsert: 'LHDNReportCopyInsert',
    PayrollCycleDelete: 'PayrollCycleDelete',
    UpdateLastLoginCompany: 'UpdateLastLoginCompany',
    PayrollCycleInsert: 'PayrollCycleInsert',
    PayrollCycleUpdate: 'PayrollCycleUpdate',
    InsertPayPeriodListing: 'InsertPayPeriodListing',
    CompanyStatutoryUpdate: 'CompanyStatutoryUpdate',
    BankDelete: 'BankDelete',
    BankInsert: 'BankInsert',
    BankUpdate: 'BankUpdate',
    InterbankDelete: 'InterbankDelete',
    InterbankInsert: 'InterbankInsert',
    InterbankSingleUpdate: 'InterbankSingleUpdate',
    CompanyAltAddressInsert: 'CompanyAltAddressInsert',
    CompanyAltAddressUpdate: 'CompanyAltAddressUpdate',
    CompanyAltAddressDelete: 'CompanyAltAddressDelete',
    VerifyApprovedClaimDetail: 'VerifyApprovedClaimDetail',
    ApproveClaimRecords: 'ApproveClaimRecords',
    ReverseApprovedClaimRecords: 'ReverseApprovedClaimRecords',
    SubmitMyClaim: 'SubmitMyClaim',
    DeleteClaimRecords: 'DeleteClaimRecords',
    SubmitClaimForApproval: 'SubmitClaimForApproval',
    DeleteApprovedClaimRecords: 'DeleteApprovedClaimRecords',
    DataChangesInsert: 'DataChangesInsert',
    SubmitDataChanges: 'SubmitDataChanges',
    DeleteDataChanges: 'DeleteDataChanges',
    CancelDataChanges: 'CancelDataChanges',
    ApproveDataChanges: 'ApproveDataChanges',
    RejectDataChanges: 'RejectDataChanges',
    DataChangesApproval: 'DataChangesApproval',
    DataChangesReject: 'DataChangesReject',
    NewsDelete: 'NewsDelete',
    NewsUpdate: 'NewsUpdate',
    NewsInsert: 'NewsInsert',
    DocumentDelete: 'DocumentDelete',
    CompanyDocumentDelete: 'CompanyDocumentDelete',
    CompanyDocumentInsert: 'CompanyDocumentInsert',
    DocumentInsert: 'DocumentInsert',
    PublishPaySlip: 'PublishPaySlip',
    PublishEA: 'PublishEA',
    RequisitionInsert: 'RequisitionInsert',
    RequisitionDelete: 'RequisitionDelete',
    RequisitionSubmit: 'RequisitionSubmit',
    RequisitionUpdate: 'RequisitionUpdate',
    DeleteApprovedRequisition: 'DeleteApprovedRequisition',
    DeleteLeaveApplication: 'DeleteLeaveApplication',
    SubmitLeaveApplication: 'SubmitLeaveApplication',
    SubmitLeaveApplicationV2: 'SubmitLeaveApplicationV2',
    LeaveRecordsInsert: 'LeaveRecordsInsert',
    LeaveRecordsUpdate: 'LeaveRecordsUpdate',
    PostLeaveApplication: 'PostLeaveApplication',
    LeaveStatusUpdate: 'LeaveStatusUpdate',
    DeleteApprovedLeaveApplication: 'DeleteApprovedLeaveApplication',
    LeaveReasonInsert: 'LeaveReasonInsert',
    LeaveReasonUpdate: 'LeaveReasonUpdate',
    LeaveReasonDelete: 'LeaveReasonDelete',
    LeaveDiseaseInsert: 'LeaveDiseaseInsert',
    LeaveDiseaseUpdate: 'LeaveDiseaseUpdate',
    LeaveDiseaseDelete: 'LeaveDiseaseDelete',
    LeaveTypeInsert: 'LeaveTypeInsert',
    LeaveTypeUpdate: 'LeaveTypeUpdate',
    LeaveTypeDelete: 'LeaveTypeDelete',
    LeavePolicyDelete: 'LeavePolicyDelete',
    LeavePolicyInsert: 'LeavePolicyInsert',
    LeavePolicyUpdate: 'LeavePolicyUpdate',
    LeaveRoundingDelete: 'LeaveRoundingDelete',
    LeaveRoundingInsert: 'LeaveRoundingInsert',
    UpdateLeaveRounding: 'UpdateLeaveRounding',
    LeaveJobGradePolicyInsert: 'LeaveJobGradePolicyInsert',
    LeaveJobGradePolicyUpdate: 'LeaveJobGradePolicyUpdate',
    LeaveTypeConditionUpdate: 'LeaveTypeConditionUpdate',
    LeaveTypeConditionDelete: 'LeaveTypeConditionDelete',
    CompanyLeaveSettingUpdate: 'CompanyLeaveSettingUpdate',
    LeaveCompulsoryPolicyBulkInsert: 'LeaveCompulsoryPolicyBulkInsert',
    LeaveCompulsoryPolicyUpdate: 'LeaveCompulsoryPolicyUpdate',
    LeaveOutOfStateDelete: 'LeaveOutOfStateDelete',
    LeaveOutOfStateInsert: 'LeaveOutOfStateInsert',
    LeaveOufOfStateUpdate: 'LeaveOufOfStateUpdate',
    LeaveRecordsApproval: 'LeaveRecordsApproval',
    DeletePendingLeaveApplication: 'DeletePendingLeaveApplication',
    LeaveTakenBulkInsert: 'LeaveTakenBulkInsert',
    LeaveAdjustmentDelete: 'LeaveAdjustmentDelete',
    LeaveAdjustmentInsert: 'LeaveAdjustmentInsert',
    LeaveAdjustmentUpdate: 'LeaveAdjustmentUpdate',
    DeleteCompulsoryLeave: 'DeleteCompulsoryLeave',
    SaveUpdateCompulsoryLeave: 'SaveUpdateCompulsoryLeave',
    BlockLeaveStatusUpdate: 'BlockLeaveStatusUpdate',
    BlockLeaveInsert: 'BlockLeaveInsert',
    BlockLeaveUpdate: 'BlockLeaveUpdate',
    InsertLeaveCutOffPeriods: 'InsertLeaveCutOffPeriods',
    PostLeaveRecords: 'PostLeaveRecords',
    RevertLeaveRecords: 'RevertLeaveRecords',
    SubmitYearEndCarryForward: 'SubmitYearEndCarryForward',
    ReversePostedYECFRecords: 'ReversePostedYECFRecords',
    ReversePostedLCFE: 'ReversePostedLCFE',
    PostOffboardingLeaveEncashment: 'PostOffboardingLeaveEncashment',
    RevertOffboardingLeaveEncashment: 'RevertOffboardingLeaveEncashment',
    PostCarryForwardEncashment: 'PostCarryForwardEncashment',
    ModerationSubmit: 'ModerationSubmit',
    RegisteredTraineeInsert: 'RegisteredTraineeInsert',
    createTrainingAttendanceRecord: 'createTrainingAttendanceRecord',
    updateTrainingAttendanceRecord: 'updateTrainingAttendanceRecord',
    UpdateNotificationReadStatus: 'UpdateNotificationReadStatus',
    UpdateAllNotificationReadStatus: 'UpdateAllNotificationReadStatus',
    NotificationUpdate: 'NotificationUpdate',
    NotificationReadStatusDelete: 'NotificationReadStatusDelete',
    UpdateBulkNotificationReadStatus: 'UpdateBulkNotificationReadStatus',
    NotificationPolicyInsert: 'NotificationPolicyInsert',
    NotificationPolicyUpdate: 'NotificationPolicyUpdate',
    NotificationPolicyConditionInsert: 'NotificationPolicyConditionInsert',
    NotificationPolicyConditionUpdate: 'NotificationPolicyConditionUpdate',
    NotificationPolicyDelete: 'NotificationPolicyDelete',
    UpdateMonthlyTransactionByFixedAllowance: 'UpdateMonthlyTransactionByFixedAllowance',
    UpdatePayrollCalendar: 'UpdatePayrollCalendar',
    GenerateDefaultOrderforAllPayPeriods: 'GenerateDefaultOrderforAllPayPeriods',
    EmployeeSalaryDelete: 'EmployeeSalaryDelete',
    EmployeeSalaryInsert: 'EmployeeSalaryInsert',
    EmployeeSalaryUpdate: 'EmployeeSalaryUpdate',
    EmployeeCP22Update: 'EmployeeCP22Update',
    EmployeeBenefitInKindInsert: 'EmployeeBenefitInKindInsert',
    EmployeeBenefitInKindUpdate: 'EmployeeBenefitInKindUpdate',
    EmployeeTaxReliefBulkInsert: 'EmployeeTaxReliefBulkInsert',
    EmployeeTaxReliefBulkUpdate: 'EmployeeTaxReliefBulkUpdate',
    RecurringPayDelete: 'RecurringPayDelete',
    RecurringPayInsert: 'RecurringPayInsert',
    RecurringPayUpdate: 'RecurringPayUpdate',
    DeleteMonthlyTrx: 'DeleteMonthlyTrx',
    OneTimePayInsert: 'OneTimePayInsert',
    OneTimePayUpdate: 'OneTimePayUpdate',
    FreezePayrollInsert: 'FreezePayrollInsert',
    EmployeeFreezePayrollDelete: 'EmployeeFreezePayrollDelete',
    DeletePayrollProcess: 'DeletePayrollProcess',
    DeletePayrollHistory: 'DeletePayrollHistory',
    UpdatePayrollProcessStatus: 'UpdatePayrollProcessStatus',
    UnPublishEA: 'UnPublishEA',
    CP159Update: 'CP159Update',
    AccountMappingInsert: 'AccountMappingInsert',
    AccountMappingUpdate: 'AccountMappingUpdate',
    AccountMappingDelete: 'AccountMappingDelete',
    AccountMappingAssignmentInsert: 'AccountMappingAssignmentInsert',
    AccountMappingAssignmentUpdate: 'AccountMappingAssignmentUpdate',
    SalaryBatch: 'SalaryBatch',
    UpdateStatutoryAdjustment: 'UpdateStatutoryAdjustment',
    DeleteStatutoryAdjustment: 'DeleteStatutoryAdjustment',
    FormItemRatingSubmit: 'FormItemRatingSubmit',
    FormItemRatingDelete: 'FormItemRatingDelete',
    ReviewGradingTableSubmit: 'ReviewGradingTableSubmit',
    ReviewGradingTableDelete: 'ReviewGradingTableDelete',
    ReviewPolicySubmit: 'ReviewPolicySubmit',
    ReviewPolicyDelete: 'ReviewPolicyDelete',
    KpiCategorySubmit: 'KpiCategorySubmit',
    KpiCategoryDelete: 'KpiCategoryDelete',
    KpiPredefinedItemSubmit: 'KpiPredefinedItemSubmit',
    KpiPredefinedItemUpdateStatus: 'KpiPredefinedItemUpdateStatus',
    KpiPredefinedItemDelete: 'KpiPredefinedItemDelete',
    KpiPredefinedTemplateSubmit: 'KpiPredefinedTemplateSubmit',
    KpiPredefinedTemplateDelete: 'KpiPredefinedTemplateDelete',
    ReviewItemSubmit: 'ReviewItemSubmit',
    ReviewItemDelete: 'ReviewItemDelete',
    ReviewTemplateSubmit: 'ReviewTemplateSubmit',
    ReviewTemplateDelete: 'ReviewTemplateDelete',
    ReviewProcessPolicySubmit: 'ReviewProcessPolicySubmit',
    ReviewProcessPolicyDelete: 'ReviewProcessPolicyDelete',
    ReviewFormTemplateSubmit: 'ReviewFormTemplateSubmit',
    ReviewFormTemplateDelete: 'ReviewFormTemplateDelete',
    ReviewGroupSubmit: 'ReviewGroupSubmit',
    ReviewGroupDelete: 'ReviewGroupDelete',
    RewardGroupSubmit: 'RewardGroupSubmit',
    RewardGroupDelete: 'RewardGroupDelete',
    PeriodicAllowanceTypeUpdate: 'PeriodicAllowanceTypeUpdate',
    PeriodicAllowanceTypeInput: 'PeriodicAllowanceTypeInput',
    PeriodicAllowanceTypeDelete: 'PeriodicAllowanceTypeDelete',
    EmployeeInfoInsert: 'EmployeeInfoInsert',
    EmployeeCareerLogUpdate: 'EmployeeCareerLogUpdate',
    EmployeeStatutoryInsert: 'EmployeeStatutoryInsert',
    EmployeeStatutoryUpdate: 'EmployeeStatutoryUpdate',
    EmployeePrevContributionInsert: 'EmployeePrevContributionInsert',
    EmployeePrevContributionUpdate: 'EmployeePrevContributionUpdate',
    EmployeeAssetDelete: 'EmployeeAssetDelete',
    EmployeeAssetUpdate: 'EmployeeAssetUpdate',
    EmployeeAssetInsert: 'EmployeeAssetInsert',
    CareerLogUpdate: 'CareerLogUpdate',
    CareerLogInsert: 'CareerLogInsert',
    EmployeeDependentInsert: 'EmployeeDependentInsert',
    EmployeeDependentUpdate: 'EmployeeDependentUpdate',
    EmployeeDependentInsertByEmployee: 'EmployeeDependentInsertByEmployee',
    EmployeeDependentUpdateByEmployee: 'EmployeeDependentUpdateByEmployee',
    CreateEmployeeInsuranceScheme: 'CreateEmployeeInsuranceScheme',
    UpdateEmployeeInsuranceScheme: 'UpdateEmployeeInsuranceScheme',
    DeleteEmployeeInsuranceScheme: 'DeleteEmployeeInsuranceScheme',
    PersonnelInsuranceSchemeBulkInsert: 'PersonnelInsuranceSchemeBulkInsert',
    PersonnelInsuranceSchemeUpdate: 'PersonnelInsuranceSchemeUpdate',
    StaffConfirmationInsert: 'StaffConfirmationInsert',
    StaffConfirmationUpdate: 'StaffConfirmationUpdate',
    StaffPromotionDemotionInsert: 'StaffPromotionDemotionInsert',
    StaffPromotionDemotionUpdate: 'StaffPromotionDemotionUpdate',
    StaffRetirementInsert: 'StaffRetirementInsert',
    StaffRetirementUpdate: 'StaffRetirementUpdate',
    PersonnelStaffExitCompanyInsert: 'PersonnelStaffExitCompanyInsert',
    PersonnelStaffExitCompanyUpdate: 'PersonnelStaffExitCompanyUpdate',
    StaffInternalTransferInsert: 'StaffInternalTransferInsert',
    StaffInternalTransferUpdate: 'StaffInternalTransferUpdate',
    StaffInternalTransferCancel: 'StaffInternalTransferCancel',
    StaffDemotionCancel: 'StaffDemotionCancel',
    StaffSecondmentInsert: 'StaffSecondmentInsert',
    StaffSecondmentUpdate: 'StaffSecondmentUpdate',
    StaffInterCompanyTransferInsert: 'StaffInterCompanyTransferInsert',
    StaffInterCompanyTransferUpdate: 'StaffInterCompanyTransferUpdate',
    StaffTransferInsert: 'StaffTransferInsert',
    StaffTransferUpdate: 'StaffTransferUpdate',
    StaffTransferCancel: 'StaffTransferCancel',
    StaffPromotionDemotionCancel: 'StaffPromotionDemotionCancel',
    StaffExitCompanyCancel: 'StaffExitCompanyCancel',
    StaffSecondmentCancel: 'StaffSecondmentCancel',
    StaffInterCompanyTransferCancel: 'StaffInterCompanyTransferCancel',
    StaffConfirmationCancelConfirmed: 'StaffConfirmationCancelConfirmed',
    StaffRetirementCancel: 'StaffRetirementCancel',
    CostMappingUpdate: 'CostMappingUpdate',
    ContractInfoInsert: 'ContractInfoInsert',
    ContractInfoUpdate: 'ContractInfoUpdate',
    ContractInfoDelete: 'ContractInfoDelete',
    ReportDocDelete: 'ReportDocDelete',
    PersonnelSecurityPolicySubmit: 'PersonnelSecurityPolicySubmit',
    ProjectMasterSubmit: 'ProjectMasterSubmit',
    ProjectMasterDelete: 'ProjectMasterDelete',
    ProjectExpenseTypeSubmit: 'ProjectExpenseTypeSubmit',
    ProjectExpenseTypeDelete: 'ProjectExpenseTypeDelete',
    DeleteProcessedGL: 'DeleteProcessedGL',
    ProjectGLAccountMappingSubmit: 'ProjectGLAccountMappingSubmit',
    ProjectGLAccountMappingDelete: 'ProjectGLAccountMappingDelete',
    CompanyProjectExpenseClaimSettingUpdate: 'CompanyProjectExpenseClaimSettingUpdate',
    ProjectAccountAssignmentInsert: 'ProjectAccountAssignmentInsert',
    ProjectAccountAssignmentUpdate: 'ProjectAccountAssignmentUpdate',
    ProjectCostAllocationSubmit: 'ProjectCostAllocationSubmit',
    ProjectCostAllocationDelete: 'ProjectCostAllocationDelete',
    SubmitMyProjectClaim: 'SubmitMyProjectClaim',
    ApproveProjectExpenseClaimRecords: 'ApproveProjectExpenseClaimRecords',
    DeleteApprovedProjectExpenseClaimRecords: 'DeleteApprovedProjectExpenseClaimRecords',
    VerifyApprovedProjectExpenseClaimDetail: 'VerifyApprovedProjectExpenseClaimDetail',
    ReverseApprovedProjectExpenseClaimRecords: 'ReverseApprovedProjectExpenseClaimRecords',
    PostPEClaimRecords: 'PostPEClaimRecords',
    ReversePEClaimRecords: 'ReversePEClaimRecords',
    DeleteProjectExpenseClaimRecords: 'DeleteProjectExpenseClaimRecords',
    SubmitProjectExpenseClaimForApproval: 'SubmitProjectExpenseClaimForApproval',
    DeleteClaimProcessedGL: 'DeleteClaimProcessedGL',
    SubmitApproverMaster: 'SubmitApproverMaster',
    DeleteApproverMaster: 'DeleteApproverMaster',
    SubmitApproverAssignment: 'SubmitApproverAssignment',
    DeleteApproverEmployeeListing: 'DeleteApproverEmployeeListing',
    ResendConfirmationEmail: 'ResendConfirmationEmail',
    ApproverConfirmedEmailAction: 'ApproverConfirmedEmailAction',
    SubmitDailyEntry: 'SubmitDailyEntry',
    DeleteDailyEntry: 'DeleteDailyEntry',
    SubmitProjectTimesheet: 'SubmitProjectTimesheet',
    SubmitProjectTimesheetApproval: 'SubmitProjectTimesheetApproval',
    RequisitionManagementApprovalUpdate: 'RequisitionManagementApprovalUpdate',
    ScaleRatingSubmit: 'ScaleRatingSubmit',
    ScaleRatingDelete: 'ScaleRatingDelete',
    ScheduleTableSubmit: 'ScheduleTableSubmit',
    ScheduleTrainingSubmit: 'ScheduleTrainingSubmit',
    ScheduleTrainingDelete: 'ScheduleTrainingDelete',
    ScheduleTrainingCancel: 'ScheduleTrainingCancel',
    RegisteredTraineeDelete: 'RegisteredTraineeDelete',
    StaffPerformanceAnalysisSummarySubmit: 'StaffPerformanceAnalysisSummarySubmit',
    BonusSimulationSubmit: 'BonusSimulationSubmit',
    IncrementSimulationSubmit: 'IncrementSimulationSubmit',
    SubmitStaffKPIPlanning: 'SubmitStaffKPIPlanning',
    StaffKPIPlannigDelete: 'StaffKPIPlannigDelete',
    CreateCompanyAccessPerm: 'CreateCompanyAccessPerm',
    DocumentNumberAssignment: 'DocumentNumberAssignment',
    NewDocumentNumberingInsert: 'NewDocumentNumberingInsert',
    CompanyDocumentNumberingUpdate: 'CompanyDocumentNumberingUpdate',
    CompanyStructureInsert: 'CompanyStructureInsert',
    AssignJobPosition: 'AssignJobPosition',
    CompanyOrgUnitInsert: 'CompanyOrgUnitInsert',
    CompanyOrgUnitUpdate: 'CompanyOrgUnitUpdate',
    CreateEntityRoleUser: 'CreateEntityRoleUser',
    CompanyStatutoryInsert: 'CompanyStatutoryInsert',
    ReportingGroupDelete: 'ReportingGroupDelete',
    ReportingGroupSubmit: 'ReportingGroupSubmit',
    ReportingCompanyGroupDelete: 'ReportingCompanyGroupDelete',
    ReportingStructureGroupSubmit: 'ReportingStructureGroupSubmit',
    replaceTemplateLibraryAssignmentForTMSShiftGroup: 'replaceTemplateLibraryAssignmentForTMSShiftGroup',
    DeleteDutyRosterByEmployee: 'DeleteDutyRosterByEmployee',
    DutyRosterBatch: 'DutyRosterBatch',
    BatchEntryByShiftInsert: 'BatchEntryByShiftInsert',
    ShiftPatternAssignmentToEmployee: 'ShiftPatternAssignmentToEmployee',
    createBatchEntryByShiftPattern: 'createBatchEntryByShiftPattern',
    DeleteTimeAttendanceRecord: 'DeleteTimeAttendanceRecord',
    UpdateAttendanceRecord: 'UpdateAttendanceRecord',
    AttendanceRecordBatchDelete: 'AttendanceRecordBatchDelete',
    AttendanceRecordBatch: 'AttendanceRecordBatch',
    AttendancePeriodEdit: 'AttendancePeriodEdit',
    AttendanceProccessBulkDelete: 'AttendanceProccessBulkDelete',
    AttendanceProcessing: 'AttendanceProcessing',
    UpdateAdjustedValue: 'UpdateAdjustedValue',
    MonthlyAttendanceProcessing: 'MonthlyAttendanceProcessing',
    PostAttendanceRecord: 'PostAttendanceRecord',
    ReverseAttendanceRecord: 'ReverseAttendanceRecord',
    PopulateAttendanceRecord: 'PopulateAttendanceRecord',
    PostEarnedLeave: 'PostEarnedLeave',
    ReverseEarnedLeave: 'ReverseEarnedLeave',
    createTemplateLibraryAssignment: 'createTemplateLibraryAssignment',
    deleteTemplateLibraryAssignment: 'deleteTemplateLibraryAssignment',
    logShiftGroupEmployeeAssignmentHistory: 'logShiftGroupEmployeeAssignmentHistory',
    updateShiftGroupEmployeeAssignmentHistory: 'updateShiftGroupEmployeeAssignmentHistory',
    deleteShiftGroupEmployeeAssignmentHistory: 'deleteShiftGroupEmployeeAssignmentHistory',
    SubmitShiftGroupPattern: 'SubmitShiftGroupPattern',
    DeleteShiftGroupPattern: 'DeleteShiftGroupPattern',
    OvertimeInsert: 'OvertimeInsert',
    OvertimeDraft: 'OvertimeDraft',
    OvertimeDelete: 'OvertimeDelete',
    OvertimeUpdate: 'OvertimeUpdate',
    OvertimeBatch: 'OvertimeBatch',
    createAttendanceRecord: 'createAttendanceRecord',
    OvertimeExcelUpload: 'OvertimeExcelUpload',
    OvertimeExcelDelete: 'OvertimeExcelDelete',
    OvertimeExcelInsertActual: 'OvertimeExcelInsertActual',
    AttendanceExcelUpload: 'AttendanceExcelUpload',
    AttendanceExcelDelete: 'AttendanceExcelDelete',
    AttendanceExcelInsertActual: 'AttendanceExcelInsertActual',
    createChangeShift: 'createChangeShift',
    updateChangeShift: 'updateChangeShift',
    deleteChangeShift: 'deleteChangeShift',
    updateChangeShiftSubmit: 'updateChangeShiftSubmit',
    ShiftSettingInsert: 'ShiftSettingInsert',
    createDailyAllowance: 'createDailyAllowance',
    ShiftSetupDelete: 'ShiftSetupDelete',
    ShiftSetupUpdate: 'ShiftSetupUpdate',
    deleteShiftPattern: 'deleteShiftPattern',
    createShiftPattern: 'createShiftPattern',
    updateShiftPattern: 'updateShiftPattern',
    updateShiftGroupStatus: 'updateShiftGroupStatus',
    createShiftGroup: 'createShiftGroup',
    updateShiftGroup: 'updateShiftGroup',
    OvertimePolicyDelete: 'OvertimePolicyDelete',
    OvertimePolicyInsert: 'OvertimePolicyInsert',
    OvertimePolicyUpdate: 'OvertimePolicyUpdate',
    updateOvertimeClaimTableStatus: 'updateOvertimeClaimTableStatus',
    createOvertimeClaimTable: 'createOvertimeClaimTable',
    OTReasonInsert: 'OTReasonInsert',
    OTReasonUpdate: 'OTReasonUpdate',
    OTReasonStatus: 'OTReasonStatus',
    OTReasonDelete: 'OTReasonDelete',
    deleteOTRounding: 'deleteOTRounding',
    createOTRounding: 'createOTRounding',
    updateOTRounding: 'updateOTRounding',
    deleteAllowanceDeduction: 'deleteAllowanceDeduction',
    createAllowanceDeduction: 'createAllowanceDeduction',
    updateAllowanceDeduction: 'updateAllowanceDeduction',
    deleteDailyAllowance: 'deleteDailyAllowance',
    updateDailyAllowance: 'updateDailyAllowance',
    deleteMonthlyAllowance: 'deleteMonthlyAllowance',
    createMonthlyAllowance: 'createMonthlyAllowance',
    updateMonthlyAllowance: 'updateMonthlyAllowance',
    createPeriodicDeduction: 'createPeriodicDeduction',
    updatePeriodicDeduction: 'updatePeriodicDeduction',
    deletePeriodicDeduction: 'deletePeriodicDeduction',
    updateDeductionPolicyStatus: 'updateDeductionPolicyStatus',
    createDeductionPolicy: 'createDeductionPolicy',
    updateDeductionPolicy: 'updateDeductionPolicy',
    TimeOffSettingInsert: 'TimeOffSettingInsert',
    TimeOffSettingUpdate: 'TimeOffSettingUpdate',
    TimeOffSettingDelete: 'TimeOffSettingDelete',
    TimeOffReasonDelete: 'TimeOffReasonDelete',
    TimeOffReasonInput: 'TimeOffReasonInput',
    TimeOffReasonUpdate: 'TimeOffReasonUpdate',
    deleteMobileCheckIn: 'deleteMobileCheckIn',
    insertUpdateLocationRadiusSetting: 'insertUpdateLocationRadiusSetting',
    createMobileCheckIn: 'createMobileCheckIn',
    updateMobileCheckIn: 'updateMobileCheckIn',
    replaceTemplateLibraryAssignmentv2: 'replaceTemplateLibraryAssignmentv2',
    OvertimeSettingUpdate: 'OvertimeSettingUpdate',
    CreateUpdateBeaconDevice: 'CreateUpdateBeaconDevice',
    DeleteAutomatedClockDataImport: 'DeleteAutomatedClockDataImport',
    CreateUpdateAutomatedClockDataImport: 'CreateUpdateAutomatedClockDataImport',
    TimeOffInsert: 'TimeOffInsert',
    TimeOffUpdate: 'TimeOffUpdate',
    TimeOffDelete: 'TimeOffDelete',
    StaffTimeSheetInsert: 'StaffTimeSheetInsert',
    StaffTimesheetDelete: 'StaffTimesheetDelete',
    TsAccountCodeInsert: 'TsAccountCodeInsert',
    TsAccountCodeUpdate: 'TsAccountCodeUpdate',
    TsAccountCodeDelete: 'TsAccountCodeDelete',
    TsAccountTypeInsert: 'TsAccountTypeInsert',
    TsAccountTypeUpdate: 'TsAccountTypeUpdate',
    TsAccountTypeDelete: 'TsAccountTypeDelete',
    ProjectTimesheetReportForApproverSendEmail: 'ProjectTimesheetReportForApproverSendEmail',
    ToDoStatusUpdate: 'ToDoStatusUpdate',
    TrainingCostSubmit: 'TrainingCostSubmit',
    CostAllocationSubmit: 'CostAllocationSubmit',
    HRDFClaimSubmit: 'HRDFClaimSubmit',
    createUser: 'createUser',
    resendInvitation: 'resendInvitation',
    updateUser: 'updateUser',
    changeUserPassword: 'changeUserPassword',
    unblockUser: 'unblockUser',
    userActionStatus: 'userActionStatus',
    ResendInvitationById: 'ResendInvitationById',
    updateHelpdeskAssignee: 'updateHelpdeskAssignee'
  },
  Subscription: {
    newNotification: 'newNotification'
  }
}